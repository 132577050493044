/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import G2W from 'images/integrations/g2w.svg';
import ZoomCamera from 'images/integrations/zoomCamera.svg';
import Link from 'images/Link';

import { type EventVirtualLocationType } from './EventVirtualLocationTypeSelect';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin: 1px 10px 1px 1px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  background: #fff;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const ICON_COMPONENTS: { [EventVirtualLocationType]: React.Node } = {
  virtual: <Link gradient={['#5e7bfe', '#977ffd']} />,
  zoom: <ZoomCamera />,
  g2w: <G2W />,
};

export default class EventVirtualLocationTypeIcon extends React.PureComponent<{
  locationType: EventVirtualLocationType,
  className?: string,
}> {
  render() {
    const { locationType, className } = this.props;

    return <Root className={className}>{ICON_COMPONENTS[locationType]}</Root>;
  }
}
