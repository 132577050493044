/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled from 'styled-components';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
} from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import ColumnFilter from 'components/material/ColumnFilters';
import { defaultTableComponents } from 'components/Opportunities/columns';
import getOpportunityFields from 'components/Opportunities/lib/getOpportunityFields';
import getShownColumns from 'components/Opportunities/lib/getShownColumns';
import OpportunitiesList from 'components/Opportunities/OpportunitiesList';
import colsConfig, {
  getColumnsShowConfig,
} from 'components/Opportunities/OpportunitiesTableColumnSettings';

import type { ContactOpportunities_org } from './__generated__/ContactOpportunities_org.graphql';
import type { ContactOpportunitiesQueryResponse } from './__generated__/ContactOpportunitiesQuery.graphql';

const Container = styled.div`
  margin: 25px 0;
`;

const ContactOpportunitiesHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
`;

const query = graphql`
  query ContactOpportunitiesQuery(
    $contactId: ID!
    $sort: OpportunitySort
    $direction: Direction!
    $customFieldSortId: String
    $includeAmount: Boolean!
    $includeCompanyId: Boolean!
    $includeStatus: Boolean!
    $includeDescription: Boolean!
    $includeSalesforceId: Boolean!
    $includeOwnerId: Boolean!
    $includeAssociatedContacts: Boolean!
    $includeName: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
  ) {
    me {
      tz
    }
    org {
      ...OpportunitiesList_org
    }
    contact: node(id: $contactId) {
      ... on Contact {
        opportunities(sort: $sort, direction: $direction, customFieldSortId: $customFieldSortId) {
          edges {
            node {
              ...OpportunitiesList_opportunities
            }
          }
        }
      }
    }
  }
`;

class ContactOpportunities extends React.Component<
  {
    contactId: string,
    org: ContactOpportunities_org,
    location: Location,
    history: History,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
  },
> {
  state = {
    shownColumns: getShownColumns('contactOpportunitiesShownColumns'),
  };

  opportunityFields: $ReadOnlyArray<FieldType> = getOpportunityFields(
    normalizeCustomFields(this.props.org.opportunityCustomFields.edges.map(edge => edge.node)),
  );

  handleColumnsChange = (shownColumns: $ReadOnlyArray<string>) => {
    storage.set('contactOpportunitiesShownColumns', shownColumns);
    this.setState({ shownColumns });
  };

  handleChangeSort = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort, 'opportunitySort');
  };

  sort = () => {
    return (
      parseTypedQueryString(this.props.location.search, {
        opportunitySort: stringParamToSort,
      }).opportunitySort || {
        key: 'NAME',
        asc: false,
      }
    );
  };

  renderList = (response?: ContactOpportunitiesQueryResponse) => {
    const opportunities =
      response && response.contact && response.contact.opportunities
        ? response.contact.opportunities.edges.map(edge => edge.node)
        : null;

    const shownColumns = this.state.shownColumns;
    const opportunityColumns = getColumnsShowConfig(this.opportunityFields);

    return (
      <>
        <ContactOpportunitiesHeader>
          {opportunities && opportunities.length > 0 && (
            <ColumnFilter
              filters={opportunityColumns}
              shownFilters={shownColumns}
              onFilterChange={this.handleColumnsChange}
            />
          )}
        </ContactOpportunitiesHeader>
        <OpportunitiesList
          org={response ? response.org : null}
          opportunities={opportunities}
          shownColumns={colsConfig(this.opportunityFields, this.state.shownColumns)}
          sort={this.sort()}
          onChangeSort={this.handleChangeSort}
          tz={response ? response.me.tz : 'UTC'}
          fromTab="contact_opportunities"
        />
      </>
    );
  };

  render() {
    const sort = this.sort();
    const shownColumns = this.state.shownColumns;

    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      this.opportunityFields,
      shownColumns,
    );

    const customSortField: ?FieldType = this.opportunityFields.find(field => field.id === sort.key);

    return (
      <Container>
        <DefaultQueryRenderer
          query={query}
          variables={{
            contactId: this.props.contactId,
            sort: customSortField ? 'CUSTOM' : sort.key,
            customFieldSortId: customSortField != null ? customSortField.id : null,
            direction: sort.asc ? 'ASC' : 'DESC',
            ...includedColumns,
          }}
          renderSuccess={this.renderList}
          renderLoading={this.renderList}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  ContactOpportunities,
  graphql`
    fragment ContactOpportunities_org on Org {
      id
      opportunityCustomFields: customFields(customizableType: [SALESFORCEOPPORTUNITY]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
);
