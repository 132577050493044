/**
 * @flow
 * @relayHash a7585428cc37fb000bf9bd8cd499ae64
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MarketoContent_org$ref = any;
export type MarketoQueryVariables = {||};
export type MarketoQueryResponse = {|
  +org: {|
    +$fragmentRefs: MarketoContent_org$ref
  |}
|};
export type MarketoQuery = {|
  variables: MarketoQueryVariables,
  response: MarketoQueryResponse,
|};
*/


/*
query MarketoQuery {
  org {
    ...MarketoContent_org
    id
  }
}

fragment MarketoContent_org on Org {
  ...MarketoAuth_org
  ...MarketoWindow_org
}

fragment MarketoAuth_org on Org {
  marketoAccount {
    username
    syncStatus {
      ...SyncStatusIndicator_status
    }
    id
  }
}

fragment MarketoWindow_org on Org {
  ...MarketoAuth_org
}

fragment SyncStatusIndicator_status on SyncStatus {
  lastSuccessAt
  state
  errorMessage
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MarketoQuery",
  "id": null,
  "text": "query MarketoQuery {\n  org {\n    ...MarketoContent_org\n    id\n  }\n}\n\nfragment MarketoContent_org on Org {\n  ...MarketoAuth_org\n  ...MarketoWindow_org\n}\n\nfragment MarketoAuth_org on Org {\n  marketoAccount {\n    username\n    syncStatus {\n      ...SyncStatusIndicator_status\n    }\n    id\n  }\n}\n\nfragment MarketoWindow_org on Org {\n  ...MarketoAuth_org\n}\n\nfragment SyncStatusIndicator_status on SyncStatus {\n  lastSuccessAt\n  state\n  errorMessage\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MarketoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "MarketoContent_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MarketoQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketoAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketoAccount",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "syncStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "SyncStatus",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastSuccessAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "state",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errorMessage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              v0
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3921a3dfefc7e93472360da217c32b14';
module.exports = node;
