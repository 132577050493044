/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import { type CategoryCellPropsType } from './index';

import { type CategoryPaidAmountCell_categoryEdge } from './__generated__/CategoryPaidAmountCell_categoryEdge.graphql';
import { type CategoryPaidAmountCell_org } from './__generated__/CategoryPaidAmountCell_org.graphql';

const CategoryPaidAmountCell = ({
  categoryEdge,
  categoryEdgeMock,
  org,
  adding,
}: CategoryCellPropsType<CategoryPaidAmountCell_categoryEdge, {}, CategoryPaidAmountCell_org>) => {
  if (adding) return '-';

  return (
    formatCost((categoryEdge || categoryEdgeMock || {}).paidAmount, org.settings.currency) || '-'
  );
};

export default createFragmentContainer(
  CategoryPaidAmountCell,
  graphql`
    fragment CategoryPaidAmountCell_categoryEdge on EventBudgetCategoryEdge {
      paidAmount
    }

    fragment CategoryPaidAmountCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
