/* @flow */
import { css } from 'styled-components';

const inputBoxStyled = (props: {
  error: ?string | boolean,
  theme: { [string]: string },
  focused?: boolean,
}) => {
  return css`
    height: 35px;
    margin-top: 0;
    padding: 0 8px;
    border-radius: 3px;
    border: 1px solid #d9ddf5;
    background: #fff;
    outline: none;
    font-size: 13px;
    transition: 0.2s border-color;
    color: #464d72;
    ~ i {
      top: 17px;
      right: 10px;
    }
    &:hover {
      border-color: #a5a9c2;
    }
    &:focus {
      border-color: #a5a9c2;
      & ~ i {
        color: #a5a9c2;
      }
    }
    ${props.error &&
      css`
        border-color: ${props.theme.negativeActionColor};
        &:hover:not(:focus) {
          border-color: ${props.theme.negativeActionColor};
        }
      `};
    ${props.focused &&
      css`
        border-color: #a5a9c2 !important;
      `};
  `;
};

export default inputBoxStyled;
