/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatPercentage from 'utils/number/formatPercentage';

import { type CategoryCellPropsType } from './index';

import { type CategoryActualOfBudgetedCell_categoryEdge } from './__generated__/CategoryActualOfBudgetedCell_categoryEdge.graphql';

const CategoryActualOfBudgetedCell = ({
  categoryEdge,
  categoryEdgeMock,
  adding,
}: CategoryCellPropsType<CategoryActualOfBudgetedCell_categoryEdge>) => {
  if (adding) return '-';

  const { actualAmount, budgetedAmount } = categoryEdge || categoryEdgeMock || {};

  return formatPercentage(actualAmount / budgetedAmount) || '-';
};

export default createFragmentContainer(
  CategoryActualOfBudgetedCell,
  graphql`
    fragment CategoryActualOfBudgetedCell_categoryEdge on EventBudgetCategoryEdge {
      budgetedAmount
      actualAmount
    }
  `,
);
