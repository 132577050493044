/* @flow */
import uniqueId from 'lodash/uniqueId';

import type { QuestionType } from './types';

const generateBlankQuestion = (order: number, sectionId: string): QuestionType => ({
  id: uniqueId('question_'),
  sectionId,
  order,
  name: '',
  description: '',
  mapping: null,
  mappingCustomField: null,
  required: false,
  textMinLength: null,
  textMaxLength: null,
  fileExtensions: [],
  expenseName: null,
  selectShowOtherOption: null,
  selectOptions: [],
  selectUsers: [],
  maxSelectionSize: null,
  budgetCategory: null,
  budgetCategories: [],
});

export default generateBlankQuestion;
