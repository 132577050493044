/* @flow */
import { graphql } from 'react-relay';

import checkImportStatus from 'utils/requests/checkImportStatus';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  importExpensesMutationResponse,
  importExpensesMutationVariables,
} from './__generated__/importExpensesMutation.graphql';

type InputType = $PropertyType<importExpensesMutationVariables, 'input'>;
export type ExpensesImportMappingsType = $PropertyType<InputType, 'mappings'>;

const mutation = graphql`
  mutation importExpensesMutation($input: ImportExpensesInput!) {
    importExpenses(input: $input) {
      uuid
    }
  }
`;

export default function importExpenses(input: InputType) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  }).then((response: importExpensesMutationResponse) => {
    if (!response.importExpenses) throw Error('Import Failed');

    return checkImportStatus(response.importExpenses.uuid);
  });
}
