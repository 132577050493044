/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';
import { type Field } from '../components/types';

import EventContactsBrief, { type ContactType, type CompanyType } from './EventContactsBrief';

import { type EventContactsBriefRootQueryResponse } from './__generated__/EventContactsBriefRootQuery.graphql';

const query = graphql`
  query EventContactsBriefRootQuery(
    $eventSlug: String!
    $contactFilters: ContactFilters!
    $companyFilters: CompanyFilters!
    $listToken: String
    $skipContacts: Boolean!
    $skipCompanies: Boolean!
    $sharedView: Boolean!
  ) {
    event(slug: $eventSlug, listToken: $listToken) {
      briefSettings @include(if: $sharedView) {
        contacts {
          edges {
            node {
              id
              firstName
              lastName
              email
              phone1
              phone2
              title
              company {
                id
                name
              }
              ...BriefUserAvatar_user
            }
          }
        }
        companies {
          edges {
            node {
              id
              name
              phone
            }
          }
        }
      }
      eventContacts(filters: $contactFilters) @skip(if: $skipContacts) {
        edges {
          node {
            id
            firstName
            lastName
            email
            phone1
            phone2
            title
            company {
              id
              name
            }
            ...BriefUserAvatar_user
          }
        }
      }
      companies(filters: $companyFilters) @skip(if: $skipCompanies) {
        edges {
          node {
            id
            name
            phone
          }
        }
      }
    }
  }
`;

export default class EventContactsBriefRoot extends React.Component<{
  customFields: $ReadOnlyArray<Field<'COMPANY | CONTACT'>>,
  contactIds: $ReadOnlyArray<string>,
  companyIds: $ReadOnlyArray<string>,
  listToken: ?string,
  eventSlug: string,
  sharedView: boolean,
  primaryColor: string,
}> {
  cachedContacts: $ReadOnlyArray<ContactType> = [];

  cachedCompanies: $ReadOnlyArray<CompanyType> = [];

  render() {
    const { listToken, eventSlug, contactIds, companyIds, sharedView, primaryColor } = this.props;
    const variables = {
      eventSlug,
      contactFilters: { contactIds },
      companyFilters: { companyIds },
      sharedView,
      skipContacts: sharedView || contactIds.length === 0,
      skipCompanies: sharedView || companyIds.length === 0,
      ...(listToken ? { listToken } : {}),
    };

    return (
      <DefaultQueryRenderer
        query={query}
        public={sharedView}
        variables={variables}
        renderSuccess={(dataProps: EventContactsBriefRootQueryResponse) => {
          if (!dataProps.event) {
            return <NotAuthorized />;
          }

          const sharedContacts = dataProps.event.briefSettings
            ? dataProps.event.briefSettings.contacts.edges.map(edge => edge.node)
            : [];
          const savedContacts = dataProps.event.eventContacts
            ? dataProps.event.eventContacts.edges.map(edge => edge.node)
            : [];
          this.cachedContacts = sharedView ? sharedContacts : savedContacts;

          const sharedCompanies = dataProps.event.briefSettings
            ? dataProps.event.briefSettings.companies.edges.map(edge => edge.node)
            : [];
          const savedCompanies = dataProps.event.companies
            ? dataProps.event.companies.edges.map(edge => edge.node)
            : [];
          this.cachedCompanies = sharedView ? sharedCompanies : savedCompanies;

          return (
            <EventContactsBrief
              customFields={this.props.customFields}
              contacts={this.cachedContacts}
              companies={this.cachedCompanies}
              primaryColor={primaryColor}
            />
          );
        }}
        renderLoading={() => (
          <EventContactsBrief
            customFields={this.props.customFields}
            contacts={this.cachedContacts}
            companies={this.cachedCompanies}
            primaryColor={primaryColor}
          />
        )}
      />
    );
  }
}
