/* @flow */

export type EventRequestFormStatusValue =
  | 'N_A'
  | 'NEW'
  | 'RETURNED'
  | 'RESUBMITTED'
  | 'APPROVED'
  | 'DECLINED'
  | 'ARCHIVED';

type EventRequestFormStatus = {
  label: string, // Label in dropdowns
  value: EventRequestFormStatusValue, // value for GraphQL API
};

const allStatuses: $ReadOnlyArray<EventRequestFormStatus> = [
  {
    label: 'N/A',
    value: 'N_A',
  },
  {
    label: 'New',
    value: 'NEW',
  },
  {
    label: 'Returned',
    value: 'RETURNED',
  },
  {
    label: 'Resubmitted',
    value: 'RESUBMITTED',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Declined',
    value: 'DECLINED',
  },
  {
    label: 'Archived',
    value: 'ARCHIVED',
  },
];

const eventRequestFormStatuses: $ReadOnlyArray<
  $Subtype<EventRequestFormStatus>,
> = allStatuses.filter(status => status.value !== 'ARCHIVED');

const submissionStatuses: $ReadOnlyArray<$Subtype<EventRequestFormStatus>> = allStatuses.filter(
  status => status.value !== 'N_A',
);

export function getEventRequestFormStatusLabel(graphValue: EventRequestFormStatusValue): ?string {
  const type = eventRequestFormStatuses.find(status => status.value === graphValue);
  return type != null ? type.label : null;
}

export { submissionStatuses, eventRequestFormStatuses };
