import PropTypes from 'prop-types';
import React from 'react';

import { Panel, PanelContent, PanelFooter } from './Panel';
import { Button } from 'components/form/Button';
import { Calendar } from 'components/form/Calendar';

import { createMoment } from 'utils/Date';

export class SingleCalendarPanel extends React.Component {
  static propTypes = {
    date: PropTypes.any,
    all_day: PropTypes.bool,
    tz: PropTypes.string,
    after: PropTypes.any,
    before: PropTypes.any,
    after_all_day: PropTypes.bool,
    before_all_day: PropTypes.bool,
    hiddenCheckbox: PropTypes.bool,
    usedDays: PropTypes.any,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    onRemove: PropTypes.func,
  };

  show = () => {
    this.panel.show();

    const { date, all_day, tz, after, before, after_all_day, before_all_day } = this.props;
    if (!date) {
      if (after) {
        const m_after = createMoment(tz)(after);
        this.props.onChange({
          date: after_all_day ? m_after.add(1, 'day').toDate() : m_after.add(30, 'minutes').toDate(),
          all_day: after_all_day,
        });
      } else if (before) {
        const m_before = createMoment(tz)(before);
        this.props.onChange({
          date: before_all_day ?
            m_before.subtract(1, 'day').toDate() : m_before.subtract(30, 'minutes').toDate(),
          all_day: before_all_day,
        });
      } else {
        const new_all_day = all_day !== null ? all_day : true;
        this.props.onChange({
          date: createMoment(tz)().startOf('day').toDate(),
          all_day: new_all_day,
        });
      }
    }
  };

  hide = () => {
    this.panel.hide();
  };

  handleRemove = () => {
    this.hide();
    this.props.onChange({ date: null });
  };

  handleSave = () => {
    this.hide();

    this.props.onSave({
      date: this.props.date,
      all_day: this.props.all_day,
    });
  };

  handleDateChange = date => {
    this.props.onChange({
      date,
    });
  };

  handleAllDayChange = all_day => {
    this.props.onChange({
      all_day,
    });
  };

  render() {
    const {
      date,
      all_day,
      tz,
      after,
      before,
      after_all_day,
      before_all_day,
      usedDays,
      hiddenCheckbox,
      error,
      onSave,
      onRemove,
    } = this.props;
    return (
      <Panel title="Set Date" {...this.props} ref={(panel) => { this.panel = panel; }}>
        <div className="due-date">
          <PanelContent>
            {error && <div className="calendar-error">{error}</div>}
            <div className="calendar-title">Date</div>
            <Calendar
              date={date}
              all_day={all_day}
              after={after}
              before={before}
              after_all_day={after_all_day}
              before_all_day={before_all_day}
              usedDays={usedDays}
              tz={tz}
              onDateChange={this.handleDateChange}
              onAllDayChange={this.handleAllDayChange}
              hiddenCheckbox={hiddenCheckbox}
            />
          </PanelContent>
          <PanelFooter>
            {(!onSave || (onSave && onRemove)) &&
              <div className="due-date-remove">
                <a onClick={this.handleRemove}>{onRemove ? 'Remove' : 'Clear'}</a>
              </div>
            }
            <div className="panel-buttons">
              {
                onSave ?
                  <Button text="Save" onClick={this.handleSave} primary />
                  :
                  <Button text="Set" onClick={this.hide} primary />
              }
            </div>
          </PanelFooter>
        </div>
      </Panel>
    );
  }
}
