/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
export type FormRuleAction = "HIDE" | "HIDE_BY_DEFAULT" | "MAKE_OPTIONAL" | "MAKE_REQUIRED" | "SHOW";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationFormFieldLogic_contactFormField$ref: FragmentReference;
export type RegistrationFormFieldLogic_contactFormField = {|
  +id: string,
  +label: string,
  +required: boolean,
  +order: number,
  +customField: ?{|
    +required: boolean
  |},
  +rules: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +action: FormRuleAction,
        +order: number,
        +customSavedTextFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +order: number,
              +fieldName: ?string,
              +values: ?$ReadOnlyArray<string>,
              +operator: Operator,
              +customField: ?{|
                +id: string,
                +customizableType: CustomizableType,
              |},
            |}
          |}>
        |},
        +customSavedTextareaFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +order: number,
              +fieldName: ?string,
              +values: ?$ReadOnlyArray<string>,
              +operator: Operator,
              +customField: ?{|
                +id: string,
                +customizableType: CustomizableType,
              |},
            |}
          |}>
        |},
        +customSavedLinkFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +order: number,
              +values: ?$ReadOnlyArray<string>,
              +operator: Operator,
              +customField: ?{|
                +id: string,
                +customizableType: CustomizableType,
              |},
            |}
          |}>
        |},
        +customSavedNumberFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +order: number,
              +minValue: ?number,
              +maxValue: ?number,
              +customField: {|
                +id: string,
                +customizableType: CustomizableType,
              |},
            |}
          |}>
        |},
        +customSavedCurrencyFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +order: number,
              +minValue: ?number,
              +maxValue: ?number,
              +customField: ?{|
                +id: string,
                +customizableType: CustomizableType,
              |},
            |}
          |}>
        |},
        +customSavedDateFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +order: number,
              +minValue: ?any,
              +maxValue: ?any,
              +customField: {|
                +id: string
              |},
            |}
          |}>
        |},
        +customSavedBooleanFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +value: boolean,
              +order: number,
              +customField: {|
                +id: string
              |},
            |}
          |}>
        |},
        +customSavedUserMultiselectFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +order: number,
              +operator: Operator,
              +customField: ?{|
                +id: string,
                +customizableType: CustomizableType,
              |},
              +options: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +user: {|
                      +id: string,
                      +email: string,
                      +firstName: string,
                      +lastName: string,
                      +$fragmentRefs: MaterialAvatar_user$ref,
                    |}
                  |}
                |}>
              |},
            |}
          |}>
        |},
        +customSavedMultiselectFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +order: number,
              +operator: Operator,
              +options: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +option: {|
                      +id: string,
                      +name: string,
                    |}
                  |}
                |}>
              |},
              +customField: {|
                +id: string,
                +customizableType: CustomizableType,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +__typename: "ContactFormField",
  +$refType: RegistrationFormFieldLogic_contactFormField$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "required",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "values",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "operator",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "customizableType",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = [
  v0,
  v2,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fieldName",
    "args": null,
    "storageKey": null
  },
  v3,
  v4,
  v5
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "minValue",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "maxValue",
  "args": null,
  "storageKey": null
},
v9 = [
  v0,
  v2,
  v7,
  v8,
  v5
],
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": [
    v0
  ]
};
return {
  "kind": "Fragment",
  "name": "RegistrationFormFieldLogic_contactFormField",
  "type": "ContactFormField",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "label",
      "args": null,
      "storageKey": null
    },
    v1,
    v2,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customField",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldType",
      "plural": false,
      "selections": [
        v1
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "rules",
      "storageKey": null,
      "args": null,
      "concreteType": "FormRuleRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "FormRuleRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "FormRule",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "action",
                  "args": null,
                  "storageKey": null
                },
                v2,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedTextFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedTextFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedTextFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedTextFilterType",
                          "plural": false,
                          "selections": v6
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedTextareaFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedTextareaFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedTextareaFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedTextareaFilterType",
                          "plural": false,
                          "selections": v6
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedLinkFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedLinkFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedLinkFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedLinkFilterType",
                          "plural": false,
                          "selections": [
                            v0,
                            v2,
                            v3,
                            v4,
                            v5
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedNumberFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedNumberFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedNumberFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedNumberFilterType",
                          "plural": false,
                          "selections": v9
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedCurrencyFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedCurrencyFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedCurrencyFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedCurrencyFilterType",
                          "plural": false,
                          "selections": v9
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedDateFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedDateFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedDateFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedDateFilterType",
                          "plural": false,
                          "selections": [
                            v0,
                            v2,
                            v7,
                            v8,
                            v10
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedBooleanFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedBooleanFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedBooleanFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedBooleanFilterType",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "value",
                              "args": null,
                              "storageKey": null
                            },
                            v2,
                            v10
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedUserMultiselectFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedUserMultiselectFilterType",
                          "plural": false,
                          "selections": [
                            v0,
                            v2,
                            v4,
                            v5,
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "options",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedUserMultiselectFilterOptionType",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "user",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "User",
                                          "plural": false,
                                          "selections": [
                                            v0,
                                            {
                                              "kind": "ScalarField",
                                              "alias": null,
                                              "name": "email",
                                              "args": null,
                                              "storageKey": null
                                            },
                                            {
                                              "kind": "ScalarField",
                                              "alias": null,
                                              "name": "firstName",
                                              "args": null,
                                              "storageKey": null
                                            },
                                            {
                                              "kind": "ScalarField",
                                              "alias": null,
                                              "name": "lastName",
                                              "args": null,
                                              "storageKey": null
                                            },
                                            {
                                              "kind": "FragmentSpread",
                                              "name": "MaterialAvatar_user",
                                              "args": null
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedMultiselectFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedMultiselectFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedMultiselectFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedMultiselectFilterType",
                          "plural": false,
                          "selections": [
                            v0,
                            v2,
                            v4,
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "options",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedMultiselectFilterOptionType",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "option",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "CustomFieldOptionType",
                                          "plural": false,
                                          "selections": [
                                            v0,
                                            {
                                              "kind": "ScalarField",
                                              "alias": null,
                                              "name": "name",
                                              "args": null,
                                              "storageKey": null
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            },
                            v5
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bb8c0feda93d659e3ffe817532227b76';
module.exports = node;
