/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventRequestFormBuilderLogic_org$ref = any;
type EventRequestFormBuilderPreview_org$ref = any;
type EventRequestFormBuilderQuestionnaire_org$ref = any;
type EventRequestFormBuilderSettings_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestFormBuilderBody_org$ref: FragmentReference;
export type EventRequestFormBuilderBody_org = {|
  +$fragmentRefs: EventRequestFormBuilderQuestionnaire_org$ref & EventRequestFormBuilderLogic_org$ref & EventRequestFormBuilderSettings_org$ref & EventRequestFormBuilderPreview_org$ref,
  +$refType: EventRequestFormBuilderBody_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestFormBuilderBody_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormBuilderQuestionnaire_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormBuilderLogic_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormBuilderSettings_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormBuilderPreview_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0287a4367c9397fe38cac9295e5fb47e';
module.exports = node;
