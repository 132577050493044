/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CustomizableBoolean_customizable$ref = any;
type CustomizableCurrency_customizable$ref = any;
type CustomizableCurrency_org$ref = any;
type CustomizableDate_customizable$ref = any;
type CustomizableLink_customizable$ref = any;
type CustomizableMultiselect_customizable$ref = any;
type CustomizableNumber_customizable$ref = any;
type CustomizableSelect_customizable$ref = any;
type CustomizableText_customizable$ref = any;
type CustomizableTextarea_customizable$ref = any;
type CustomizableUserMultiselect_customizable$ref = any;
type CustomizableUserSelect_customizable$ref = any;
type VendorCity_vendor$ref = any;
type VendorCompanyPhone_vendor$ref = any;
type VendorCountry_vendor$ref = any;
type VendorCreatedAt_vendor$ref = any;
type VendorCreator_vendor$ref = any;
type VendorDescription_vendor$ref = any;
type VendorEmail_vendor$ref = any;
type VendorFirstName_vendor$ref = any;
type VendorLastName_vendor$ref = any;
type VendorLinkedin_vendor$ref = any;
type VendorMobilePhone_vendor$ref = any;
type VendorName_vendor$ref = any;
type VendorOfficePhone_vendor$ref = any;
type VendorState_vendor$ref = any;
type VendorStreet_vendor$ref = any;
type VendorTitle_vendor$ref = any;
type VendorTwitter_vendor$ref = any;
type VendorUpdatedAt_vendor$ref = any;
type VendorUpdater_vendor$ref = any;
type VendorWebsite_vendor$ref = any;
type VendorZip_vendor$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorsTablePagination_org$ref: FragmentReference;
export type VendorsTablePagination_org = {|
  +vendors: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: VendorName_vendor$ref & VendorWebsite_vendor$ref & VendorCompanyPhone_vendor$ref & VendorStreet_vendor$ref & VendorCity_vendor$ref & VendorState_vendor$ref & VendorZip_vendor$ref & VendorCountry_vendor$ref & VendorTwitter_vendor$ref & VendorLinkedin_vendor$ref & VendorDescription_vendor$ref & VendorFirstName_vendor$ref & VendorLastName_vendor$ref & VendorTitle_vendor$ref & VendorOfficePhone_vendor$ref & VendorMobilePhone_vendor$ref & VendorEmail_vendor$ref & VendorCreator_vendor$ref & VendorCreatedAt_vendor$ref & VendorUpdater_vendor$ref & VendorUpdatedAt_vendor$ref & CustomizableText_customizable$ref & CustomizableTextarea_customizable$ref & CustomizableLink_customizable$ref & CustomizableDate_customizable$ref & CustomizableBoolean_customizable$ref & CustomizableNumber_customizable$ref & CustomizableCurrency_customizable$ref & CustomizableSelect_customizable$ref & CustomizableMultiselect_customizable$ref & CustomizableUserSelect_customizable$ref & CustomizableUserMultiselect_customizable$ref,
      |}
    |}>
  |},
  +$fragmentRefs: CustomizableCurrency_org$ref,
  +$refType: VendorsTablePagination_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VendorsTablePagination_org",
  "type": "Org",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "vendors"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeCustomizableCurrency",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "count",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "cursor",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "filters",
      "type": "VendorFilters"
    },
    {
      "kind": "RootArgument",
      "name": "includeName",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeWebsite",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCompanyPhone",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStreet",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCity",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeState",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeZip",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCountry",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTwitter",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeLinkedin",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeDescription",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeFirstName",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeLastName",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTitle",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includePhone1",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includePhone2",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEmail",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCreatedBy",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCreatedAt",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeUpdatedBy",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeUpdatedAt",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableText",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableTextarea",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableLink",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableDate",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableBoolean",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableNumber",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableMultiselect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserMultiselect",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "vendors",
      "name": "__OrgVendorsTablePagination_vendors_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "VendorRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "VendorRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Vendor",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeName",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorName_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeWebsite",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorWebsite_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCompanyPhone",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorCompanyPhone_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeStreet",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorStreet_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCity",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorCity_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeState",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorState_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeZip",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorZip_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCountry",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorCountry_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeTwitter",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorTwitter_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeLinkedin",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorLinkedin_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeDescription",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorDescription_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeFirstName",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorFirstName_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeLastName",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorLastName_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeTitle",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorTitle_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includePhone1",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorOfficePhone_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includePhone2",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorMobilePhone_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeEmail",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorEmail_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCreatedBy",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorCreator_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCreatedAt",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorCreatedAt_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeUpdatedBy",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorUpdater_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeUpdatedAt",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "VendorUpdatedAt_vendor",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableText",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableText_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableTextarea",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableTextarea_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableLink",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableLink_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableDate",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableDate_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableBoolean",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableBoolean_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableNumber",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableNumber_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableCurrency",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableCurrency_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableSelect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableSelect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableMultiselect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableMultiselect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableUserSelect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableUserSelect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableUserMultiselect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableUserMultiselect_customizable",
                      "args": null
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableCurrency",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableCurrency_org",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd741f84d311e08df471354417f697ee5';
module.exports = node;
