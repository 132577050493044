/**
 * @flow
 * @relayHash 143f5a5c8f71128b9b0183b5cdb474bb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
export type UpdateEventNoteInput = {
  clientMutationId?: ?string,
  noteId: string,
  name: string,
  content?: ?string,
};
export type updateEventNoteMutationVariables = {|
  input: UpdateEventNoteInput
|};
export type updateEventNoteMutationResponse = {|
  +updateEventNote: ?{|
    +eventNote: {|
      +id: string,
      +name: string,
      +content: string,
      +updatedAt: any,
      +createdAt: any,
      +updater: ?{|
        +firstName: string,
        +lastName: string,
        +email: string,
        +$fragmentRefs: MaterialAvatar_user$ref,
      |},
    |}
  |}
|};
export type updateEventNoteMutation = {|
  variables: updateEventNoteMutationVariables,
  response: updateEventNoteMutationResponse,
|};
*/


/*
mutation updateEventNoteMutation(
  $input: UpdateEventNoteInput!
) {
  updateEventNote(input: $input) {
    eventNote {
      id
      name
      content
      updatedAt
      createdAt
      updater {
        firstName
        lastName
        email
        ...MaterialAvatar_user
        id
      }
    }
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventNoteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateEventNoteInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventNoteMutation",
  "id": null,
  "text": "mutation updateEventNoteMutation(\n  $input: UpdateEventNoteInput!\n) {\n  updateEventNote(input: $input) {\n    eventNote {\n      id\n      name\n      content\n      updatedAt\n      createdAt\n      updater {\n        firstName\n        lastName\n        email\n        ...MaterialAvatar_user\n        id\n      }\n    }\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventNoteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateEventNote",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventNotePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventNote",
            "storageKey": null,
            "args": null,
            "concreteType": "EventNote",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "updater",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v7,
                  v8,
                  v9,
                  {
                    "kind": "FragmentSpread",
                    "name": "MaterialAvatar_user",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventNoteMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateEventNote",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventNotePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventNote",
            "storageKey": null,
            "args": null,
            "concreteType": "EventNote",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "updater",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v7,
                  v8,
                  v9,
                  v2,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "avatar",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'acb6d445e0241b0ef125c1d2cef991b2';
module.exports = node;
