/* @flow */
import { camelCase } from 'lodash';

import { type CompanyDefaultFields } from 'views/Main/AllContacts/Company/tabs/CompanyProfile/CompanyFormFields';

import isCompanyField from './isCompanyField';
import { type ExtendedContactDefaultFields } from './suggestedFields';
import { type QuestionType } from './types';

type DefaultValues = {
  defaultFieldValues: ExtendedContactDefaultFields,
  companyDefaultFieldValues: CompanyDefaultFields,
};

const getDefaultFieldValue = (
  { defaultFieldValues, companyDefaultFieldValues }: DefaultValues,
  question: QuestionType,
) => {
  const fieldName = question.fieldName || (question.customField && question.customField.fieldName);

  if (!fieldName) {
    return null;
  }

  if (fieldName === 'contact_type_id') {
    return defaultFieldValues[camelCase(fieldName)];
  }
  if (fieldName === 'company_name') {
    return companyDefaultFieldValues.name;
  }

  return isCompanyField(question)
    ? companyDefaultFieldValues[camelCase(fieldName)]
    : defaultFieldValues[camelCase(fieldName.replace(/_id$/, ''))];
};

export default getDefaultFieldValue;
