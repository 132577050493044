/* @flow */

import React from 'react';
import styled from 'styled-components';

import CompanyIcon from 'components/Participants/CompanyIcon';

import { type Field } from './types';
import { BriefUserPhone, BriefUserTag } from './BriefUser';
import BoldText from './BoldText';

const Content = styled.div`
  display: flex;
`;

const StyledCompanyIcon = styled(CompanyIcon)`
  border: 2px solid #828b93;
`;

type Company = {
  id: string,
  name: string,
  phone: ?string,
};

type Props = {
  customFields: $ReadOnlyArray<Field<'COMPANY'>>,
  company: $ReadOnly<Company>,
  className?: string,
  size?: number,
  iconSize?: number,
};

const CompanyField = ({ company, customFields, size, iconSize, className }: Props) => {
  const { name, phone } = company;

  const enabledFields = customFields.map(field => field.fieldName);

  return (
    <Content className={className}>
      <StyledCompanyIcon
        size={size != null ? size : 40}
        iconSize={iconSize != null ? iconSize : 12}
      />
      <div>
        <BoldText>{name}</BoldText>
        <BriefUserTag tag="company" />
        {enabledFields.includes('phone') && <BriefUserPhone phone={phone} />}
      </div>
    </Content>
  );
};

export default CompanyField;
