/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import eventStates, { type EventStateGraphValue } from 'config/eventStates';

import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import type { Location } from 'utils/locations/locationTypes';
import storage from 'utils/storage';
import isValidWebsite from 'utils/validators/isValidWebsite';

import createEvent from 'graph/mutations/event/createEvent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import DateTimeRangePicker from 'components/date/DateTimeRangePicker';
import CheckBox from 'components/EventRequestForm/form/CheckBox';
import OrgLeadSearch, { type UserType } from 'components/Lead/OrgLeadSearch';
import Button from 'components/material/Button';
import EditableLinkField from 'components/material/EditableLinkField';
import LocationAutocomplete from 'components/material/LocationPicker/LocationAutocomplete';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import TemplateSearch, { type TemplateType } from 'components/TemplateSearch';

import type { EventForm_me } from './__generated__/EventForm_me.graphql';
import type { EventForm_org } from './__generated__/EventForm_org.graphql';

export const EVENT_FORMAT_TYPES = {
  virtual: 'Virtual',
  hybrid: 'Hybrid',
  physical: 'In-person',
};

export type EventFormatType = $Keys<typeof EVENT_FORMAT_TYPES>;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.bottom || '10px'};
`;

const RowHalfItem = styled.div`
  width: calc(50% - 14px);
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
`;

const CancelButton = styled(Button)`
  padding: 6px 16px 6px 16px;
`;

const SubmitButton = styled(Button)`
  margin-left: 15px;
  &:disabled {
    color: ${props => props.theme.primaryActionTextColor};
    background: #5247a3;
    &:hover,
    &:focus {
      color: ${props => props.theme.primaryActionTextColor};
      background: #5247a3;
    }
  }
`;

const StyledOrgLeadSearch = styled(OrgLeadSearch)`
  margin-bottom: 0;
`;

const StyledTemplateSearch = styled(TemplateSearch)`
  margin-top: 11px;
`;

const TimelineWarning = styled.div`
  font-size: 11px;
  font-weight: 400;
  font-style: italic;
  color: ${props => props.theme.mutedTextColor};
  margin-top: 3px;
  line-height: 1.3;
`;

type EventFormFields = {
  +name: string,
  +location: ?Location,
  +website: ?string,
  +status: ?EventStateGraphValue,
  +teamId: string,
  +startDate: ?string,
  +endDate: ?string,
  +startDateAllDay: ?boolean,
  +endDateAllDay: ?boolean,
  +leadId: ?string,
  +template: ?TemplateType,
  +copyTimeLine: boolean,
};

export type CreatedEventType = {
  +id: string,
  +dbId: number,
  +name: string,
  +slug: string,
};

const blankEvent = {
  name: '',
  teamId: '',
  location: null,
  website: null,
  status: eventStates[0].graphValue,
  startDate: null,
  startDateAllDay: true,
  endDate: null,
  endDateAllDay: true,
  leadId: null,
  template: null,
  copyTimeLine: false,
};

const teamIdNameKey = (userId: string): string => `${userId}_lastSelectedTeam`;

const getStoredTeamId = (userId: string): ?string => {
  const lastSelectedTeamId: mixed = storage.get(teamIdNameKey(userId));
  return typeof lastSelectedTeamId === 'string' ? lastSelectedTeamId : null;
};

class EventForm extends React.PureComponent<
  {
    fromWindow: string,
    onHide: () => void,
    onCreate: (event: CreatedEventType) => void,
    me: EventForm_me,
    org: EventForm_org,
  },
  {
    errors: Object,
    event: EventFormFields,
    loading: boolean,
  },
> {
  static getSelectedTeamId = (
    teams: $PropertyType<EventForm_me, 'teams'>,
    userId: string,
  ): string => {
    const lastSelectedTeamId: ?string = getStoredTeamId(userId);
    const teamsNodes = teams.edges
      .map(({ node }) => node)
      .filter(({ viewerCanCreateEvents }) => viewerCanCreateEvents);
    const team =
      (lastSelectedTeamId != null && teamsNodes.find(t => t.id === lastSelectedTeamId)) ||
      teamsNodes[0];

    return team ? team.id : '';
  };

  state = {
    errors: {},
    event: {
      ...blankEvent,
      teamId: EventForm.getSelectedTeamId(this.props.me.teams, this.props.me.id),
    },
    loading: false,
  };

  datePickerShown: boolean = false;

  locationChanged: boolean = false;

  searchLocationQueryExists: boolean;

  handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    this.setState(state => ({
      event: {
        ...state.event,
        [name]: value,
      },
      errors: { ...state.errors, [name]: '' },
    }));
  };

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formEvent = this.state.event;

    const event = {
      name: (formEvent.name || '').trim(),
      status: formEvent.status,
      leadId: formEvent.leadId,
      startDate: formEvent.startDate,
      startDateAllDay: formEvent.startDateAllDay,
      endDate: formEvent.endDate,
      endDateAllDay: formEvent.endDateAllDay,
      templateId: formEvent.template && formEvent.template.id,
      copyTimeLine: formEvent.copyTimeLine,
      location: formEvent.location,
      website: formEvent.website,
    };

    const errors = {};
    if (!event.name) errors.name = 'Required';
    if (!formEvent.teamId) errors.team = 'Required';

    if (event.location && !event.location.city) {
      errors.location = 'Please specify city for the location';
    }

    if (event.website && !isValidWebsite(event.website)) {
      errors.website = 'Invalid url';
    }

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      const trackData = {
        fromWindow: this.props.fromWindow,
        templateType: event.templateId == null ? null : 'previous event',
      };

      createEvent(formEvent.teamId, event, trackData)
        .then(resp => {
          if (!resp.createEvent) {
            return Promise.reject();
          }
          const createdEvent = resp.createEvent.eventEdge.node;
          storage.set(teamIdNameKey(this.props.me.id), formEvent.teamId);
          this.props.onCreate(createdEvent);
          return Promise.resolve();
        })
        .catch(err => {
          this.setState({ loading: false });
          showModernMutationError(err);
        });
    }
  };

  handleEventTeamChange = teamId => {
    const team = this.props.me.teams.edges.map(t => t.node).find(t => t.id === teamId);
    if (team && team.dbId) {
      this.setState(state => ({
        event: { ...state.event, teamId: teamId || '' },
        errors: { ...state.errors, team: '' },
      }));
    }
  };

  handleEventStatusChange = (status: ?EventStateGraphValue) => {
    this.setState(state => ({
      event: { ...state.event, status: status || eventStates[0].graphValue },
    }));
  };

  handleDatesChange = datesConfig => {
    this.setState(state => ({
      event: {
        ...state.event,
        startDate: datesConfig.startDate,
        startDateAllDay: datesConfig.hideStartTime,
        endDate: datesConfig.endDate,
        endDateAllDay: datesConfig.hideEndTime,
        copyTimeLine: datesConfig.startDate == null ? false : state.event.copyTimeLine,
      },
    }));
  };

  handleChangeLeader = (lead: ?UserType) => {
    this.setState(state => ({
      event: { ...state.event, leadId: lead ? lead.id : null },
    }));
  };

  handlePhysicalLocationChange = (locationArg: Location) => {
    const { google_placeid, ...location } = locationArg;
    this.setState(state => ({
      event: {
        ...state.event,
        location,
      },
    }));
  };

  handleWebsiteBlur = e => {
    const value = enforceHttpPrefix(e.currentTarget.value.trim());
    e.currentTarget.value = value;
    this.setState(state => ({
      event: { ...state.event, website: value },
      errors: { ...state.errors, website: '' },
    }));
  };

  handleShowDatePicker = () => {
    this.datePickerShown = true;
  };

  handleHideDatePicker = () => {
    this.datePickerShown = false;
  };

  isAnyFieldEdited = () => {
    const { event } = this.state;
    return Object.keys(event).some(key => {
      if (typeof event[key] === 'boolean') {
        return false;
      }
      if (Array.isArray(event[key])) {
        return event[key].length > 0;
      }
      if (key === 'teamId') {
        return EventForm.getSelectedTeamId(this.props.me.teams, this.props.me.id) !== event[key];
      }
      return event[key];
    });
  };

  handleLocationBlur = (query: string) => {
    if (!query) {
      this.setState(state => ({
        event: {
          ...state.event,
          location: null,
        },
      }));
    }
  };

  handleToggleLocationQueryState = (present: boolean) => {
    this.searchLocationQueryExists = present;
  };

  handleTemplateChange = (template: ?TemplateType) => {
    this.setState(prevState => ({
      event: {
        ...prevState.event,
        template,
        copyTimeLine: false,
      },
    }));
  };

  handleToggleTimelineCopy = () => {
    this.setState(prevState => ({
      event: {
        ...prevState.event,
        copyTimeLine: !prevState.event.copyTimeLine,
      },
    }));
  };

  // Called from parent component
  isNotEdited() {
    return !(this.datePickerShown || this.locationChanged || this.isAnyFieldEdited());
  }

  render() {
    const { event, errors, loading } = this.state;
    const { org, me, onHide } = this.props;
    const teams = me.teams.edges.map(team => team.node).filter(team => team.viewerCanCreateEvents);
    const selectedTeam = teams.find(t => t.id === this.state.event.teamId) || teams[0];
    const dueDateDisabled =
      event.template == null || event.template.startDate == null || event.startDate == null;

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <TextField
              label="Event name"
              value={event.name}
              name="name"
              error={errors.name}
              onChange={this.handleInputChange}
              required
              autoFocus
            />
          </Row>
          <Row>
            <RowHalfItem>
              <SelectField
                label="Team"
                value={selectedTeam && selectedTeam.id}
                onChange={this.handleEventTeamChange}
                options={teams.map(t => ({
                  value: t.id,
                  label: t.name,
                  disabled: !t.viewerCanCreateEvents,
                }))}
              />
            </RowHalfItem>
            <RowHalfItem>
              <SelectField
                label="Status"
                value={event.status}
                onChange={this.handleEventStatusChange}
                options={eventStates.map(state => ({
                  label: state.label,
                  value: state.graphValue,
                }))}
              />
            </RowHalfItem>
          </Row>
          <Row>
            <StyledOrgLeadSearch onSelect={this.handleChangeLeader} />
          </Row>
          <Row>
            <DateTimeRangePicker
              startLabel="Start Date"
              endLabel="End Date"
              startDate={event.startDate}
              endDate={event.endDate}
              hideStartTime={event.startDateAllDay == null || event.startDateAllDay}
              hideEndTime={event.endDateAllDay == null || event.endDateAllDay}
              tz={org.settings.tz}
              onChange={this.handleDatesChange}
              onFocus={this.handleShowDatePicker}
              onBlur={this.handleHideDatePicker}
            />
          </Row>
          <Row>
            <RowHalfItem>
              <EditableLinkField
                defaultValue={event.website}
                onBlur={this.handleWebsiteBlur}
                label="Website"
                error={errors.website}
              />
            </RowHalfItem>
            <RowHalfItem>
              <LocationAutocomplete
                label="Location"
                onSelectLocation={this.handlePhysicalLocationChange}
                onBlur={this.handleLocationBlur}
                onToggleQueryState={this.handleToggleLocationQueryState}
              />
            </RowHalfItem>
          </Row>
          <Row>
            <StyledTemplateSearch
              placeholder="Checklist template"
              event={event.template}
              onSelect={this.handleTemplateChange}
              searchable
              clearable
            />
          </Row>
          {event.template != null && (
            <>
              <Row>
                <CheckBox
                  compact
                  checked={event.copyTimeLine}
                  label="Set due dates based on event start date"
                  onChange={this.handleToggleTimelineCopy}
                  disabled={dueDateDisabled}
                />
              </Row>
              {dueDateDisabled && (
                <Row>
                  <TimelineWarning>
                    {event.startDate == null
                      ? "The event you're creating doesn't have a start date, which is necessary to anchor the timeline."
                      : "The template you selected doesn't have a start date, which is necessary to anchor the timeline."}
                  </TimelineWarning>
                </Row>
              )}
            </>
          )}
          <Footer>
            <CancelButton label="Cancel" minimal onClick={onHide} />
            <SubmitButton label="Save" primary type="submit" loading={loading} disabled={loading} />
          </Footer>
        </form>
      </div>
    );
  }
}

export default createFragmentContainer(EventForm, {
  me: graphql`
    fragment EventForm_me on User {
      id
      tz
      firstName
      teams {
        edges {
          node {
            id
            dbId
            name
            viewerCanCreateEvents
          }
        }
      }
    }
  `,
  org: graphql`
    fragment EventForm_org on Org {
      settings {
        tz
      }
    }
  `,
});
