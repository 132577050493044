/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const SidebarContent = styled.div`
  flex: 0 0 230px;
  height: 100%;
  ${props =>
    !props.showMobileMenu &&
    css`
      margin-left: ${props.shown ? 0 : -230}px;
      opacity: ${props.shown ? 1 : 0.5};
    `}
  padding: 27px 15px;
  overflow-y: auto;
  transition: all 0.3s;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

type Props = {
  children: React.Node,
  disabled: boolean,
  shown: ?boolean,
  showMobileMenu: boolean,
};

const Sidebar = ({ children, disabled, shown, showMobileMenu }: Props) => {
  return (
    <SidebarContent shown={shown} disabled={disabled} showMobileMenu={showMobileMenu}>
      {children}
    </SidebarContent>
  );
};

export default Sidebar;
