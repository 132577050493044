/* @flow */

import React from 'react';
import { type Match, Route } from 'react-router';

import BudgetPage from './BudgetPage';
import ExpensePage from './ExpensePage';

export default class Budget extends React.Component<{
  match: Match,
  // fiscalYearStart: number,
}> {
  render() {
    return (
      <>
        <Route exact path={this.props.match.path} component={BudgetPage} />

        <Route exact path={`${this.props.match.path}/:expenseId`} component={ExpensePage} />
      </>
    );
  }
}
