/* @flow */
import React from 'react';
import styled from 'styled-components';

import DateTimeRangePicker, {
  type DatesConfig,
  InputWrapper,
} from 'components/date/DateTimeRangePicker';
import { Input } from 'components/material/TextField';

import inputBoxStyled from '../components/inputBoxStyled';
import Label from './Label';

const Container = styled.div`
  margin-bottom: 40px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  ${InputWrapper} {
    &:first-child {
      margin-right: 15px;
    }
    > label {
      margin: 0;
    }
    ${Input} {
      ${props => inputBoxStyled(props)};
    }
  }
`;

const OverlayHeightFiller = styled.div`
  height: 240px;
`;

export default class EventDates extends React.PureComponent<
  {
    start: ?string,
    end: ?string,
    startDateAllDay: boolean,
    endDateAllDay: boolean,
    tz: string,
    eventDates: {
      startDate: ?string,
      endDate: ?string,
      startDateAllDay: boolean,
      endDateAllDay: boolean,
    },
    onChange: ({
      eventStartDate: ?string,
      eventEndDate: ?string,
      startDateAllDay: ?boolean,
      endDateAllDay: ?boolean,
    }) => void,
  },
  {
    error: ?string,
    startDate: ?string,
    endDate: ?string,
    startDateAllDay: boolean,
    endDateAllDay: boolean,
    initialProperties: {
      startDate: ?string,
      endDate: ?string,
      startDateAllDay: boolean,
      endDateAllDay: boolean,
    },
    overlayShown: boolean,
  },
> {
  state = {
    error: null,
    startDate: this.props.start,
    endDate: this.props.end,
    startDateAllDay: this.props.startDateAllDay,
    endDateAllDay: this.props.endDateAllDay,
    initialProperties: {
      startDate: this.props.start,
      endDate: this.props.end,
      startDateAllDay: this.props.startDateAllDay,
      endDateAllDay: this.props.endDateAllDay,
    },
    overlayShown: false,
  };

  overlayHeightFillerRef = React.createRef();

  static getDerivedStateFromProps(
    props: $PropertyType<EventDates, 'props'>,
    state: $PropertyType<EventDates, 'state'>,
  ) {
    const changedProperties = {};
    if (props.start !== state.initialProperties.startDate) {
      changedProperties.startDate = props.start;
    }
    if (props.end !== state.initialProperties.endDate) {
      changedProperties.endDate = props.end;
    }
    if (props.startDateAllDay !== state.initialProperties.startDateAllDay) {
      changedProperties.startDateAllDay = props.startDateAllDay;
    }
    if (props.endDateAllDay !== state.initialProperties.endDateAllDay) {
      changedProperties.endDateAllDay = props.endDateAllDay;
    }
    if (Object.keys(changedProperties).length > 0) {
      return {
        ...state,
        ...changedProperties,
        initialProperties: { ...state.initialProperties, ...changedProperties },
      };
    }
    return null;
  }

  handleChange = (datesConfig: DatesConfig) => {
    const { startDate, hideStartTime, endDate, hideEndTime } = datesConfig;
    if (!startDate) {
      this.setState({
        error: 'Start Date is required',
        startDate,
        startDateAllDay: hideStartTime,
        endDate,
        endDateAllDay: hideEndTime,
      });
      return;
    }
    this.setState({
      error: null,
      startDate,
      startDateAllDay: hideStartTime,
      endDate,
      endDateAllDay: hideEndTime,
    });
  };

  handleResetToEvent = () => {
    const { eventDates, onChange } = this.props;
    if (eventDates.startDate == null) {
      return;
    }
    onChange({
      eventStartDate: eventDates.startDate,
      startDateAllDay: eventDates.startDateAllDay,
      eventEndDate: eventDates.endDate,
      endDateAllDay: eventDates.endDateAllDay,
    });
    this.setState({
      ...eventDates,
      error: null,
    });
  };

  handleSave = () => {
    this.setState({ overlayShown: false });

    const { startDate, endDate, error, startDateAllDay, endDateAllDay } = this.state;
    if (error || !startDate) {
      return;
    }
    if (
      this.props.start !== startDate ||
      this.props.end !== endDate ||
      this.props.startDateAllDay !== startDateAllDay ||
      this.props.endDateAllDay !== endDateAllDay
    ) {
      this.props.onChange({
        eventStartDate: startDate,
        startDateAllDay,
        eventEndDate: endDate,
        endDateAllDay,
      });
    }
  };

  handleShowOverlay = () => {
    this.setState({ overlayShown: true }, () => {
      if (!this.overlayHeightFillerRef.current) return;

      this.overlayHeightFillerRef.current.scrollIntoView({ behavior: 'smooth' });
    });
  };

  datesNotEqual = (): boolean => {
    const eventDates = this.props.eventDates;
    return Object.keys(eventDates).some(key => this.state[key] !== eventDates[key]);
  };

  render() {
    const { tz, eventDates } = this.props;
    const { error, startDate, endDate, startDateAllDay, endDateAllDay, overlayShown } = this.state;
    const showReset = eventDates.startDate != null && this.datesNotEqual();
    return (
      <>
        <Container>
          <Label
            text="Public Dates"
            required
            onReset={this.handleResetToEvent}
            showReset={showReset}
          />
          <Content>
            <DateTimeRangePicker
              tz={tz}
              startDate={startDate}
              endDate={endDate}
              hideStartTime={startDateAllDay}
              hideEndTime={endDateAllDay}
              startPlaceholder="Start Date"
              endPlaceholder="End Date"
              onChange={this.handleChange}
              onBlur={this.handleSave}
              error={error}
              overlayContainer={document.body}
              onFocus={this.handleShowOverlay}
            />
          </Content>
        </Container>
        {overlayShown && <OverlayHeightFiller ref={this.overlayHeightFillerRef} />}
      </>
    );
  }
}
