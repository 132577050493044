/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TextInput from 'components/material/TextInput';
import inputBoxStyled from '../inputBoxStyled';

const Container = styled.div`
  position: relative;
  &:hover {
    > i {
      display: inline;
    }
  }
`;

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

export default class LinkField extends React.PureComponent<{
  value: string,
  className?: string,
  onChange?: (SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (SyntheticEvent<HTMLInputElement>) => void,
  required?: boolean,
  error: boolean,
  disabled?: boolean,
}> {
  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  render() {
    return (
      <Container className={this.props.className}>
        <StyledTextInput
          value={this.props.value}
          onChange={this.props.onChange}
          error={this.props.error}
          onBlur={this.props.onBlur}
          onKeyDown={this.handleKeyDown}
          required={this.props.required}
          disabled={this.props.disabled}
        />
      </Container>
    );
  }
}
