/* @flow */
import './styles.styl';

import * as React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';
import NotFound from 'components/NotFound';

import EventContainer from './EventContainer';

import type { EventQueryResponse } from './__generated__/EventQuery.graphql';

const query = graphql`
  query EventQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      dbId
      ...EventContainer_event
    }
    org {
      ...EventContainer_org
    }
    me {
      ...EventContainer_user
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 10px 0;
`;

export default class Event extends React.Component<{
  location: Location,
  match: Match,
  onToggleSidebar: () => void,
}> {
  renderLoading = () => {
    return (
      <LoaderContainer>
        <Loader size={30} />
      </LoaderContainer>
    );
  };

  render() {
    const { match, onToggleSidebar, location } = this.props;

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ eventSlug: match.params.event_slug }}
        renderSuccess={(props: EventQueryResponse) => {
          if (!props.event) return <NotFound />;

          return (
            <EventContainer
              event={props.event}
              org={props.org}
              user={props.me}
              match={match}
              location={location}
              onToggleSidebar={onToggleSidebar}
            />
          );
        }}
        renderLoading={this.renderLoading}
      />
    );
  }
}
