/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import TextField from '../../../components/TextField';

import { type EventInfoCustomCurrency_event } from './__generated__/EventInfoCustomCurrency_event.graphql';
import { type EventInfoCustomCurrency_org } from './__generated__/EventInfoCustomCurrency_org.graphql';

const EventInfoCustomCurrency = ({
  name,
  event,
  org,
  customFieldId,
}: {
  name: string,
  event: EventInfoCustomCurrency_event,
  org: EventInfoCustomCurrency_org,
  customFieldId: string,
}) => {
  const fieldValue = event.customCurrencyFields.find(
    value => value.customField.id === customFieldId,
  );

  if (!fieldValue) return null;

  return (
    <TextField name={name} description={formatCost(fieldValue.value, org.settings.currency)} />
  );
};

export default createFragmentContainer(EventInfoCustomCurrency, {
  event: graphql`
    fragment EventInfoCustomCurrency_event on Event {
      customCurrencyFields(customFieldIds: $customFieldIds) {
        value
        customField {
          id
        }
      }
    }
  `,
  org: graphql`
    fragment EventInfoCustomCurrency_org on Org {
      settings {
        currency
      }
    }
  `,
});
