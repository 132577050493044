/* @flow */
import React from 'react';

const Link = ({ gradient }: { gradient?: [string, string] }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    {gradient && (
      <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor={gradient[0]} />
        <stop offset="100%" stopColor={gradient[1]} />
      </linearGradient>
    )}
    <defs>
      <path
        id="path-link-1"
        d="M5.51 7.492a.684.684 0 010 .998.71.71 0 01-.999 0 3.518 3.518 0 010-4.972l2.49-2.49a3.52 3.52 0 014.974 0 3.518 3.518 0 010 4.973l-1.048 1.048a4.857 4.857 0 00-.282-1.702l.33-.338c.83-.823.83-2.159 0-2.982a2.098 2.098 0 00-2.982 0L5.51 4.51a2.097 2.097 0 000 2.982zM7.494 4.51a.71.71 0 01.999 0 3.518 3.518 0 010 4.972l-2.49 2.49a3.52 3.52 0 01-4.974 0 3.518 3.518 0 010-4.973l1.048-1.048a4.922 4.922 0 00.281 1.71l-.33.33a2.097 2.097 0 000 2.982c.823.83 2.16.83 2.982 0L7.494 8.49c.83-.823.83-2.159 0-2.982a.684.684 0 010-.998z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-275 -249)">
        <g transform="translate(225 118)">
          <g transform="translate(50 131)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-link-1" />
            </mask>
            <use fill="#000" fillRule="nonzero" xlinkHref="#path-link-1" />
            <g
              fill={gradient ? 'url(#gradient)' : 'currentColor'}
              fillRule="evenodd"
              mask="url(#mask-2)"
            >
              <path d="M0 0H13V13H0z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Link;
