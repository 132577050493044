/* @flow */
import enqueueCall from './enqueueCall';

const identify = (userId: number) => {
  enqueueCall(() => {
    window.analytics.identify(userId);
  });
};

export default identify;
