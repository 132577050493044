/* @flow */
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const activeStyles = css`
  background-color: ${props => props.theme.infoMenuHoverBackgroundColor};
  color: ${props => props.theme.infoMenuHoverColor};
`;

export default styled(NavLink)`
  display: block;
  padding: 10px 25px;
  line-height: 1;
  margin-bottom: 1px;
  font-size: 14px;
  color: ${props => props.theme.infoMenuColor};
  &:hover,
  &.active {
    ${activeStyles}
  }

  ${props =>
    props.active &&
    css`
      ${activeStyles}
      font-weight: 500;
    `}

  &.active {
    font-weight: 500;
  }
`;
