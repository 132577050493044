/* @flow */
import React from 'react';
import styled from 'styled-components';

const Total = styled.div`
  position: absolute;
  z-index: 8;
  right: ${props => props.vScrollbarSize}px;
  bottom: ${props => props.hScrollbarSize}px;
  display: flex;
  align-items: center;
  height: 41px;
  padding: 0 15px;
  color: ${props => props.theme.secondaryActionDarkerColor};
  background: ${props => props.theme.secondaryRowColor};
  border-left: 1px solid ${props => props.theme.primaryRowColor};
  border-top: 1px solid ${props => props.theme.primaryRowColor};
`;

export default class StickyTotal extends React.Component<{
  total: number,
  vScrollbarSize: number,
  hScrollbarSize: number,
  loadedUsers?: number,
}> {
  render() {
    return (
      <Total vScrollbarSize={this.props.vScrollbarSize} hScrollbarSize={this.props.hScrollbarSize}>
        {`Total: ${this.props.loadedUsers != null ? `${this.props.loadedUsers} / ` : ''} 
          ${this.props.total}`}
      </Total>
    );
  }
}
