/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type DividerComponent_componentProps$ref = any;
type EmbedComponent_componentProps$ref = any;
type EventDatesComponent_componentProps$ref = any;
type EventNameComponent_componentProps$ref = any;
type FormComponent_componentProps$ref = any;
type ImageComponent_componentProps$ref = any;
type RegistrationPageComponents_selectedPageComponent$ref = any;
type RegistrationPageContent_registrationForm$ref = any;
type RegistrationPageProperties_registrationForm$ref = any;
type RowComponent_componentProps$ref = any;
type TextComponent_componentProps$ref = any;
type VideoComponent_componentProps$ref = any;
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
export type Measurement = "PERCENTAGE" | "PX";
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationCreateContent_registrationForm$ref: FragmentReference;
export type RegistrationCreateContent_registrationForm = {|
  +id: string,
  +width: number,
  +widthMeasurement: Measurement,
  +pageComponents: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +order: number,
        +kind: RegistrationPageComponentKind,
        +parent: ?{|
          +id: string
        |},
        +formComponent: ?{|
          +id: string,
          +fontFamily: ?string,
          +fontStyle: ?string,
          +color: ?string,
          +buttonCopy: ?string,
          +buttonColor: ?string,
          +buttonTextColor: ?string,
          +width: number,
          +widthMeasurement: Measurement,
          +alignment: Alignment,
        |},
        +childPageComponents: ?{|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +columnChild: ?{|
                +id: string
              |},
            |}
          |}>
        |},
        +$fragmentRefs: ImageComponent_componentProps$ref & EventNameComponent_componentProps$ref & EventDatesComponent_componentProps$ref & TextComponent_componentProps$ref & DividerComponent_componentProps$ref & EmbedComponent_componentProps$ref & FormComponent_componentProps$ref & VideoComponent_componentProps$ref & RowComponent_componentProps$ref & RegistrationPageComponents_selectedPageComponent$ref,
      |}
    |}>
  |},
  +$fragmentRefs: RegistrationPageContent_registrationForm$ref & RegistrationPageProperties_registrationForm$ref,
  +$refType: RegistrationCreateContent_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "width",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "widthMeasurement",
  "args": null,
  "storageKey": null
},
v3 = [
  v0
];
return {
  "kind": "Fragment",
  "name": "RegistrationCreateContent_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    v2,
    {
      "kind": "FragmentSpread",
      "name": "RegistrationPageContent_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationPageProperties_registrationForm",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pageComponents",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationPageComponentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RegistrationPageComponentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationPageComponent",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "parent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationPageComponent",
                  "plural": false,
                  "selections": v3
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "formComponent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationFormComponent",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fontFamily",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fontStyle",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "color",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "buttonCopy",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "buttonColor",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "buttonTextColor",
                      "args": null,
                      "storageKey": null
                    },
                    v1,
                    v2,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "alignment",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "childPageComponents",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationPageComponentRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "RegistrationPageComponentRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "RegistrationPageComponent",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "columnChild",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "RegistrationPageComponent",
                              "plural": false,
                              "selections": v3
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ImageComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "EventNameComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "EventDatesComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "TextComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "DividerComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "EmbedComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "FormComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "VideoComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "RowComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "RegistrationPageComponents_selectedPageComponent",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fe0cdb7ae506ae96a785b9550bccf6a8';
module.exports = node;
