/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

import importPeople, { type PeopleImportMappingsType } from 'graph/mutations/access/importPeople';
import showModernMutationError from 'graph/utils/showModernMutationError';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import ImportWindow, {
  type ConfigStepProps,
  type ImportProps,
  type MappingsType,
  type ValidatingStepProps,
} from 'components/ImportWindow';
import { type AccessType } from 'components/InviteWindow/AccessTypeSelector';
import { type Event } from 'components/InviteWindow/InvitationEventSearch';

import UsersImportLimitError from './UsersImportLimitError';
import UsersImportWindowConfig from './UsersImportWindowConfig';

import { type UsersImportWindowQueryResponse } from './__generated__/UsersImportWindowQuery.graphql';

const fields = {
  firstName: { label: 'First Name', guess: /^(first|full|user)?name/ },
  lastName: { label: 'Last Name', guess: /lastname/ },
  email: { label: 'Email', guess: /email/ },
  company: { label: 'Company', guess: /company/ },
  title: { label: 'Title', guess: /role|title|position/ },
  phone: { label: 'Mobile Phone', guess: /mobile/ },
  officePhone: { label: 'Office Phone', guess: /phone/ },
  tshirtSize: { label: 'T-Shirt Size', guess: /shirt/ },
  bio: { label: 'Bio', guess: /bio/ },
};

const query = graphql`
  query UsersImportWindowQuery {
    org {
      subscription {
        fatmLimit
        fatmCount
      }
      teamMembers: users(hasTeamAccess: true) {
        edges {
          node {
            email
          }
        }
      }
    }
  }
`;

type FieldType = $Keys<typeof fields>;

export default class UsersImportWindow extends React.PureComponent<
  {
    event?: Event,
    teamId?: string,
    onClose: () => void,
  },
  {
    accessType: AccessType,
    selectedTeamIds: $ReadOnlyArray<string>,
    selectedEvents: $ReadOnlyArray<Event>,
  },
> {
  state = {
    accessType: this.props.event ? 'FULL' : 'TEAM',
    selectedTeamIds: this.props.teamId ? [this.props.teamId] : [],
    selectedEvents: this.props.event ? [this.props.event] : [],
  };

  handleChangeAccessType = (accessType: AccessType) => {
    this.setState({ accessType, selectedTeamIds: [] });

    if (accessType === 'ADMIN' || accessType === 'TEAM') {
      this.setState({ selectedEvents: [] });
    }
  };

  handleChangeSelectedTeamIds = (selectedTeamIds: $ReadOnlyArray<string>) => {
    this.setState({ selectedTeamIds });
  };

  handleChangeSelectedEvents = (selectedEvents: $ReadOnlyArray<Event>) => {
    this.setState({ selectedEvents });
  };

  handleValidateMappings = (mappings: MappingsType<FieldType>) => {
    const mappingFields = Object.values(mappings);

    if (['firstName', 'lastName', 'email'].every(field => mappingFields.includes(field))) {
      return null;
    }

    return 'First name, Last name and Email are required.';
  };

  renderValidatingStep = (response: UsersImportWindowQueryResponse) => ({
    onStartOver,
    onNext,
    uploadedData,
    mappings,
  }: ValidatingStepProps<PeopleImportMappingsType>) => {
    const {
      subscription: { fatmLimit, fatmCount },
      teamMembers,
    } = response.org;
    const memberEmails = teamMembers.edges.map(memberEdge => memberEdge.node.email);
    const uploadedUserEmails = uniq(
      uploadedData
        .map(row => mappings.email != null && row[mappings.email].toLowerCase().trim())
        .filter(Boolean),
    );
    const nonFatmUsersCount = difference(uploadedUserEmails, memberEmails).length;

    if (
      ['ADMIN', 'TEAM'].includes(this.state.accessType) &&
      fatmLimit != null &&
      fatmCount + nonFatmUsersCount > fatmLimit
    ) {
      return (
        <UsersImportLimitError
          limit={fatmLimit}
          used={fatmCount}
          importing={nonFatmUsersCount}
          onReset={onStartOver}
        />
      );
    }
    onNext();
    return null;
  };

  handleImport = ({ mappings, fileName, fileContent }: ImportProps<PeopleImportMappingsType>) => {
    const selectedEventIds = [
      this.props.event ? this.props.event.id : null,
      ...this.state.selectedEvents.map(event => event.id),
    ].filter(Boolean);
    const staffInvites =
      this.state.accessType !== 'TEAM' && this.state.accessType !== 'ADMIN'
        ? {
            eventIds: selectedEventIds,
            accessLevel: this.state.accessType,
          }
        : null;

    return importPeople({
      mappings,
      staffInvites,
      teamIds: this.state.selectedTeamIds,
      fileName,
      fileContent,
    }).catch(showModernMutationError);
  };

  accessScope() {
    if (this.props.event) return 'EVENT';
    if (this.props.teamId) return 'TEAM';
    return 'ORG';
  }

  renderConfirmationMessage = (count: number) => {
    const { selectedTeamIds, selectedEvents } = this.state;
    const objectName = count > 1 ? 'users' : 'user';
    if (selectedTeamIds.length > 0) {
      return `You are about to import ${count} ${objectName} to ${selectedTeamIds.length} team${
        selectedTeamIds.length > 1 ? 's' : ''
      }.`;
    }
    if (selectedEvents.length > 0) {
      return `You are about to import ${count} ${objectName} to ${selectedEvents.length} event${
        selectedEvents.length > 1 ? 's' : ''
      }.`;
    }
    return `You are about to import ${count} ${objectName} as Workspace Admin.`;
  };

  renderConfigStep = ({ onNext }: ConfigStepProps<FieldType>) => (
    <UsersImportWindowConfig
      accessScope={this.accessScope()}
      accessType={this.state.accessType}
      selectedTeamIds={this.state.selectedTeamIds}
      selectedEvents={this.state.selectedEvents}
      onChangeAccessType={this.handleChangeAccessType}
      onChangeSelectedTeamIds={this.handleChangeSelectedTeamIds}
      onChangeSelectedEvents={this.handleChangeSelectedEvents}
      onCancel={this.props.onClose}
      onNext={onNext}
    />
  );

  renderImportWindow = (response: UsersImportWindowQueryResponse) => {
    const { onClose, teamId } = this.props;

    return (
      <ImportWindow
        title="Import Members"
        importResourceName="Member"
        fields={fields}
        renderValidatingStep={this.renderValidatingStep(response)}
        renderConfigStep={teamId ? null : this.renderConfigStep}
        renderConfirmationMessage={this.renderConfirmationMessage}
        onValidateMappings={this.handleValidateMappings}
        onClose={onClose}
        onImport={this.handleImport}
      />
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={this.renderImportWindow}
        renderLoading={() => null}
      />
    );
  }
}
