/* @flow */
import { getCookie } from 'utils/Cookies';

export default function generateExportable(
  url: string,
  authorizeBy: string = 'user',
): Promise<string> {
  return fetch(url, {
    credentials: 'same-origin',
    headers: {
      Authorization: getCookie(authorizeBy),
    },
  }).then(response => {
    if (response.status >= 400) {
      return response.json().then(json => Promise.reject(new Error(json.errors)));
    }
    return response.json().then(json => json.uuid);
  });
}
