/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { SalesforceLastSync_event } from './__generated__/SalesforceLastSync_event.graphql';

const SalesforceLastSync = (props: { event: SalesforceLastSync_event }) => {
  const { pullStatus, pushStatus, tz } = props.event;

  if (!pullStatus || !pushStatus || (!pullStatus.lastSuccessAt && !pushStatus.lastSuccessAt))
    return null;

  const pullDate = pullStatus.lastSuccessAt && moment.tz(pullStatus.lastSuccessAt, tz);
  const pushDate = pushStatus.lastSuccessAt && moment.tz(pushStatus.lastSuccessAt, tz);

  const date = pullDate && pushDate ? moment.max(pullDate, pushDate) : pullDate || pushDate;

  return <DateTimeTooltip date={date} placement="right" />;
};

export default createFragmentContainer(
  SalesforceLastSync,
  graphql`
    fragment SalesforceLastSync_event on Event {
      pullStatus {
        lastSuccessAt
      }
      pushStatus {
        lastSuccessAt
      }
      tz
    }
  `,
);
