/* @flow */
import UIActions from 'actions/UIActions';

/**
 * Show an error popup.  Handles Errors, Error-like objects, and values that can be passed
 * to .catch handlers.
 */
export default function showErrorPopup(error?: Error | { message: string } | string | null) {
  if (error === null || error === undefined) {
    // If no error value passed, assume the promise wanted to reject quietly.
    return;
  }

  let message: string;
  if (error && typeof error === 'string') {
    message = error;
  } else if (error && error.message) {
    message = error.message;
  } else {
    message = 'Sorry, we encountered a problem.';
  }
  UIActions.showErrorMessage([message]);
}
