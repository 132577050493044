import './modal.styl';

import React from 'react';
import classNames from 'classnames';

import UIActions from 'actions/UIActions';

import { Window, WindowFooter, WindowContent } from './Window';
import { Button } from 'components/form/Button';
import { TextField } from 'components/form/TextField';

export class Modal extends React.Component {
  render() {
    const modal = this.props.value;
    return (
      <Window
        shown
        onClose={this.handleWindowClose}
        noClose={modal.noClose}
        className={classNames(modal.className, {
          'window-centered': modal.centered,
          'window-noclose': modal.noClose,
        })}
        title={modal.title}
      >
        <WindowContent>
          <div className="modal-window-message">
            {modal.message}

            {modal.copy && <TextField value={modal.copy} copyAble />}
          </div>
        </WindowContent>
        <WindowFooter>
          {modal.confirmButton
            ? this.renderCustomButtons(modal.confirmButton, modal.cancelButton || {})
            : this.renderDefaultButton()}
        </WindowFooter>
      </Window>
    );
  }
  renderCustomButtons(button, secondary_button) {
    return (
      <div className="modal-window-buttons">
        <Button
          text={secondary_button.text || 'Cancel'}
          onClick={secondary_button.action || this.handleWindowClose}
        />
        <Button text={button.text} onClick={button.action} primary />
      </div>
    );
  }
  renderDefaultButton() {
    return (
      <div className="modal-window-buttons">
        <Button text="Ok" primary onClick={this.handleWindowClose} />
      </div>
    );
  }

  handleWindowClose() {
    UIActions.hideWindow('modal');
  }
}
