/**
 * @flow
 * @relayHash f6614b796bcc8ceede93575d2a31fd26
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CustomizableSelect_customizable$ref = any;
type CustomizableUserSelect_customizable$ref = any;
type EventLead_event$ref = any;
type EventStatus_event$ref = any;
type EventUpdatedAt_event$ref = any;
type EventUpdater_event$ref = any;
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type MassUpdateEventsInput = {
  clientMutationId?: ?string,
  eventIds: $ReadOnlyArray<string>,
  leadId?: ?string,
  status?: ?EventStatus,
  customFieldValues?: ?$ReadOnlyArray<CustomFieldValueInput>,
};
export type CustomFieldValueInput = {
  customFieldId: string,
  textValue?: ?string,
  textareaValue?: ?string,
  linkValue?: ?string,
  numberValue?: ?number,
  currencyValue?: ?number,
  dateValue?: ?any,
  booleanValue?: ?boolean,
  userValue?: ?string,
  userValues?: ?$ReadOnlyArray<string>,
  selectValue?: ?string,
  selectOtherValue?: ?string,
  selectValues?: ?$ReadOnlyArray<string>,
};
export type massUpdateEventsMutationVariables = {|
  input: MassUpdateEventsInput
|};
export type massUpdateEventsMutationResponse = {|
  +massUpdateEvents: ?{|
    +eventEdges: ?$ReadOnlyArray<?{|
      +node: {|
        +$fragmentRefs: EventLead_event$ref & EventStatus_event$ref & EventUpdater_event$ref & EventUpdatedAt_event$ref & CustomizableSelect_customizable$ref & CustomizableUserSelect_customizable$ref
      |}
    |}>
  |}
|};
export type massUpdateEventsMutation = {|
  variables: massUpdateEventsMutationVariables,
  response: massUpdateEventsMutationResponse,
|};
*/


/*
mutation massUpdateEventsMutation(
  $input: MassUpdateEventsInput!
) {
  massUpdateEvents(input: $input) {
    eventEdges {
      node {
        ...EventLead_event
        ...EventStatus_event
        ...EventUpdater_event
        ...EventUpdatedAt_event
        ...CustomizableSelect_customizable
        ...CustomizableUserSelect_customizable
        id
      }
    }
  }
}

fragment EventLead_event on Event {
  lead {
    id
    firstName
    lastName
    email
    avatar
    ...MaterialAvatar_user
  }
}

fragment EventStatus_event on Event {
  id
  commitmentLevel
  statusMappedToSalesforce: mappedToSalesforce(field: STATE)
}

fragment EventUpdater_event on Event {
  updater {
    firstName
    lastName
    avatar
    ...MaterialAvatar_user
    id
  }
}

fragment EventUpdatedAt_event on Event {
  updatedAt
  tz
}

fragment CustomizableSelect_customizable on CustomizableInterface {
  id
  __typename
  customSelectFields {
    customField {
      id
    }
    option {
      id
    }
    selectOtherValue
    id
  }
}

fragment CustomizableUserSelect_customizable on CustomizableInterface {
  id
  __typename
  customUserSelectFields {
    customField {
      id
    }
    user {
      id
      firstName
      lastName
      email
      avatar
      ...MaterialAvatar_user
    }
    id
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MassUpdateEventsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "MassUpdateEventsInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v7 = [
  v2,
  v3,
  v4,
  v5,
  v6
],
v8 = [
  v2
],
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": v8
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "massUpdateEventsMutation",
  "id": null,
  "text": "mutation massUpdateEventsMutation(\n  $input: MassUpdateEventsInput!\n) {\n  massUpdateEvents(input: $input) {\n    eventEdges {\n      node {\n        ...EventLead_event\n        ...EventStatus_event\n        ...EventUpdater_event\n        ...EventUpdatedAt_event\n        ...CustomizableSelect_customizable\n        ...CustomizableUserSelect_customizable\n        id\n      }\n    }\n  }\n}\n\nfragment EventLead_event on Event {\n  lead {\n    id\n    firstName\n    lastName\n    email\n    avatar\n    ...MaterialAvatar_user\n  }\n}\n\nfragment EventStatus_event on Event {\n  id\n  commitmentLevel\n  statusMappedToSalesforce: mappedToSalesforce(field: STATE)\n}\n\nfragment EventUpdater_event on Event {\n  updater {\n    firstName\n    lastName\n    avatar\n    ...MaterialAvatar_user\n    id\n  }\n}\n\nfragment EventUpdatedAt_event on Event {\n  updatedAt\n  tz\n}\n\nfragment CustomizableSelect_customizable on CustomizableInterface {\n  id\n  __typename\n  customSelectFields {\n    customField {\n      id\n    }\n    option {\n      id\n    }\n    selectOtherValue\n    id\n  }\n}\n\nfragment CustomizableUserSelect_customizable on CustomizableInterface {\n  id\n  __typename\n  customUserSelectFields {\n    customField {\n      id\n    }\n    user {\n      id\n      firstName\n      lastName\n      email\n      avatar\n      ...MaterialAvatar_user\n    }\n    id\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "massUpdateEventsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "massUpdateEvents",
        "storageKey": null,
        "args": v1,
        "concreteType": "MassUpdateEventsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "EventRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "EventLead_event",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventStatus_event",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventUpdater_event",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventUpdatedAt_event",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "CustomizableSelect_customizable",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "CustomizableUserSelect_customizable",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "massUpdateEventsMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "massUpdateEvents",
        "storageKey": null,
        "args": v1,
        "concreteType": "MassUpdateEventsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "EventRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "lead",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": v7
                  },
                  v2,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "commitmentLevel",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": "statusMappedToSalesforce",
                    "name": "mappedToSalesforce",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "field",
                        "value": "STATE",
                        "type": "EventIntegrationFields!"
                      }
                    ],
                    "storageKey": "mappedToSalesforce(field:\"STATE\")"
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updater",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v6,
                      v2,
                      v5
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "updatedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "tz",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customSelectFields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomSelectFieldType",
                    "plural": true,
                    "selections": [
                      v9,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "option",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionType",
                        "plural": false,
                        "selections": v8
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "selectOtherValue",
                        "args": null,
                        "storageKey": null
                      },
                      v2
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customUserSelectFields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomUserSelectFieldType",
                    "plural": true,
                    "selections": [
                      v9,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": v7
                      },
                      v2
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bf45ce1db72056388284963214db6a38';
module.exports = node;
