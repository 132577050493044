/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RequestSubmissionFormName_requestSubmission$ref: FragmentReference;
export type RequestSubmissionFormName_requestSubmission = {|
  +form: {|
    +name: string
  |},
  +$refType: RequestSubmissionFormName_requestSubmission$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RequestSubmissionFormName_requestSubmission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "form",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestForm",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3f8f6511c38b7dc98b8aabd2ff9ca4c5';
module.exports = node;
