/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateEventRequestSubmissionMutationResponse,
  updateEventRequestSubmissionMutationVariables,
  UpdateEventRequestSubmissionInput,
} from './__generated__/updateEventRequestSubmissionMutation.graphql';

const mutation = graphql`
  mutation updateEventRequestSubmissionMutation($input: UpdateEventRequestSubmissionInput!) {
    updateEventRequestSubmission(input: $input) {
      requestSubmission {
        id
      }
    }
  }
`;

export default function updateEventRequestSubmission(
  data: UpdateEventRequestSubmissionInput,
): Promise<updateEventRequestSubmissionMutationResponse> {
  const variables: updateEventRequestSubmissionMutationVariables = {
    input: data,
  };

  return commitModernMutation({
    mutation,
    variables,
    public: true,
  });
}
