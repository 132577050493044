/* @flow */
import React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import { type CustomizableType } from 'components/FieldSettings/types';

import { type Field } from './components/types';
import sectionsMap from './SectionsMap';

import { type BriefViewContainer_event } from './__generated__/BriefViewContainer_event.graphql';
import { type BriefViewContainer_org } from './__generated__/BriefViewContainer_org.graphql';

const SectionWrapper = styled.div`
  margin-bottom: 10px;
`;

export default class BriefSortableSections extends React.Component<{
  customFields: $ReadOnlyArray<Field<CustomizableType>>,
  event: BriefViewContainer_event,
  org: BriefViewContainer_org,
  possessiveUserName: string,
  primaryColor: string,
}> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { briefTemplate } = this.props.event.briefSettings;
    const sortedSections = sortBy(briefTemplate.sections, 'order')
      .filter(section => section.enabled)
      .map(section => sectionsMap[section.name]);

    return sortedSections.map(section => (
      <SectionWrapper key={section.name}>
        <section.component
          key={section.name}
          customFields={this.props.customFields}
          template={briefTemplate}
          event={this.props.event}
          name={section.name}
          org={this.props.org}
          possessiveUserName={this.props.possessiveUserName}
          primaryColor={this.props.primaryColor}
        />
      </SectionWrapper>
    ));
  }
}
