/**
 * @flow
 * @relayHash 50a5b61e710718503bbfa57703fe9bc8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportEventsInput = {
  clientMutationId?: ?string,
  fileName: string,
  fileContent: string,
  mappings: EventImportMappings,
};
export type EventImportMappings = {
  team?: ?string,
  name?: ?string,
  startDate?: ?string,
  startTime?: ?string,
  endDate?: ?string,
  endTime?: ?string,
  status?: ?string,
  description?: ?string,
  website?: ?string,
  lead?: ?string,
  locationName?: ?string,
  locationStreet?: ?string,
  locationCity?: ?string,
  locationState?: ?string,
  locationPostal?: ?string,
  locationCountry?: ?string,
  location?: ?string,
  virtualLocation?: ?string,
  booth?: ?string,
  boothDimensions?: ?string,
  floorPlan?: ?string,
  totalBudgetedAmount?: ?string,
  totalActualAmount?: ?string,
  notes?: ?$ReadOnlyArray<string>,
  salesforceId?: ?string,
  customFields?: ?$ReadOnlyArray<CustomFieldImportType>,
};
export type CustomFieldImportType = {
  customFieldId: string,
  columnName: string,
};
export type importEventsMutationVariables = {|
  input: ImportEventsInput
|};
export type importEventsMutationResponse = {|
  +importEvents: ?{|
    +uuid: string
  |}
|};
export type importEventsMutation = {|
  variables: importEventsMutationVariables,
  response: importEventsMutationResponse,
|};
*/


/*
mutation importEventsMutation(
  $input: ImportEventsInput!
) {
  importEvents(input: $input) {
    uuid
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportEventsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "importEvents",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ImportEventsInput!"
      }
    ],
    "concreteType": "ImportEventsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uuid",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "importEventsMutation",
  "id": null,
  "text": "mutation importEventsMutation(\n  $input: ImportEventsInput!\n) {\n  importEvents(input: $input) {\n    uuid\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "importEventsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "importEventsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3be7021c3d99d297219aa8b46abd848a';
module.exports = node;
