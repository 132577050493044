/* @flow */
import * as React from 'react';
import {
  type Disposable,
  type RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from 'react-relay';
import styled from 'styled-components';

import showErrorPopup from 'utils/showErrorPopup';

import LoadMoreRow from 'components/LoadMoreRow';
import NoResultsMessage from 'components/NoResultsMessage';
import ScrollbarSizes from 'components/ScrollbarSizes';

import columns from './columns';
import EventRequestFormsTableHeader from './EventRequestFormsTableHeader';
import EventRequestFormsTableRow from './EventRequestFormsTableRow';
import type { ParsedEventRequestFormsFilters } from './lib/parseEventRequestFormsFilters';

import type { EventRequestFormsTable_org } from './__generated__/EventRequestFormsTable_org.graphql';
import type { EventRequestFormsTable_totalCountOrg } from './__generated__/EventRequestFormsTable_totalCountOrg.graphql';

const List = styled.div`
  min-width: ${props => props.minWidth}px;
  padding-bottom: 40px;
`;

const StyledLoadMoreRow = styled(LoadMoreRow)`
  position: absolute;
  z-index: 8;
  right: ${props => props.vScrollbarSize}px;
  bottom: ${props => props.hScrollbarSize}px;
  padding: 0 15px;
  border-left: 1px solid ${props => props.theme.primaryRowColor};
  border-top: 1px solid ${props => props.theme.primaryRowColor};
`;

class EventRequestFormsTable extends React.PureComponent<
  {
    filters: ParsedEventRequestFormsFilters,
    relay: RelayPaginationProp,
    org: EventRequestFormsTable_org,
    totalCountOrg: EventRequestFormsTable_totalCountOrg,
    tz: string,
    scrollContainer: HTMLElement,
  },
  {
    vScrollbarSize: number,
    hScrollbarSize: number,
    refetching: boolean,
    customColumnWidths: { [string]: number },
  },
> {
  state = {
    vScrollbarSize: 0,
    hScrollbarSize: 0,
    refetching: false,
    customColumnWidths: {},
  };

  paginationDisposable: ?Disposable;

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  handleChangeScrollbarSizes = (sizes: { vScrollbarSize: number, hScrollbarSize: number }) => {
    this.setState(sizes);
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  handleChangeColumnSize = (value: string, width: number) => {
    this.setState(state => ({
      customColumnWidths: {
        ...state.customColumnWidths,
        [value]: Math.max(state.customColumnWidths[value] || 0, width),
      },
    }));
  };

  render() {
    const { org, totalCountOrg, tz, filters, scrollContainer } = this.props;
    const requestFormsConnection = org.eventRequestForms;
    const requestForms = requestFormsConnection.edges.map(edge => edge.node);
    const totalCount = totalCountOrg.requestsCount.totalCount;

    // Sum of column widths + 10px padding for each column + 50 for actions column
    const shownColumns = columns.map(col => ({
      ...col,
      width: this.state.customColumnWidths[col.value] || col.width,
    }));
    const minWidth = shownColumns.reduce((width, col) => width + col.width + 10, 0) + 50;

    return (
      <React.Fragment>
        <EventRequestFormsTableHeader
          columns={shownColumns}
          sort={filters.sort}
          minWidth={minWidth}
        />
        <List minWidth={minWidth}>
          {requestForms.map(requestForm => (
            <EventRequestFormsTableRow
              key={requestForm.id}
              tz={tz}
              orgId={org.id}
              requestForm={requestForm}
              columns={shownColumns}
              onChangeColumnSize={this.handleChangeColumnSize}
            />
          ))}

          {totalCount === 0 && (
            <NoResultsMessage iconName="file-alt" message="Create the first form" />
          )}

          <StyledLoadMoreRow
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            totalCount={totalCount}
            hasNextPage={requestFormsConnection.pageInfo.hasNextPage}
            vScrollbarSize={this.state.vScrollbarSize}
            hScrollbarSize={this.state.hScrollbarSize}
          />

          <ScrollbarSizes
            shouldUpdate={requestFormsConnection.edges.length}
            scrollContainer={scrollContainer}
            onScrollbarSizesChange={this.handleChangeScrollbarSizes}
            vScrollbarSize={this.state.vScrollbarSize}
            hScrollbarSize={this.state.hScrollbarSize}
          />
        </List>
      </React.Fragment>
    );
  }
}

export default createPaginationContainer(
  EventRequestFormsTable,
  {
    org: graphql`
      fragment EventRequestFormsTable_org on Org {
        id
        eventRequestForms(first: $count, after: $cursor, sort: $sort, direction: $direction)
          @connection(key: "EventRequestFormsTable_eventRequestForms", filters: []) {
          edges {
            node {
              id
              ...EventRequestFormsTableRow_requestForm
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `,
    totalCountOrg: graphql`
      fragment EventRequestFormsTable_totalCountOrg on Org {
        requestsCount: eventRequestForms {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.org && props.org.eventRequestForms;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
      };
    },
    query: graphql`
      query EventRequestFormsTablePaginationQuery(
        $count: Int!
        $cursor: String
        $sort: EventRequestFormSort
        $direction: Direction!
      ) {
        org {
          ...EventRequestFormsTable_org
        }
      }
    `,
  },
);
