/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import EventVendorsPage from './EventVendorsPage';

import type { VendorsQueryResponse } from './__generated__/VendorsQuery.graphql';

const query = graphql`
  query VendorsQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...EventVendorsPage_event
    }
    org {
      ...EventVendorsPage_org
    }
  }
`;

const Content = styled.div`
  padding: 15px 28px 0 28px;
`;

export default class Vendors extends React.PureComponent<{
  eventSlug: string,
  location: Location,
  history: RouterHistory,
  match: Match,
}> {
  render() {
    const { location, history, eventSlug, match } = this.props;
    return (
      <Content>
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug }}
          renderSuccess={(props: VendorsQueryResponse) => {
            return (
              <EventVendorsPage
                org={props.org}
                event={props.event}
                location={location}
                history={history}
                pathPrefix={match.url}
              />
            );
          }}
        />
      </Content>
    );
  }
}
