/* @flow */
import toNumber from 'lodash/toNumber';
import moment from 'moment-timezone';
import qs from 'qs';

import { getFiscalYear, getQuarter } from 'utils/fiscal';

export const ARRAY_DELIMETER = ',';
export const SORT_DELIMETER = '~';
export const FILTER_DATE_FORMAT = 'YYYY/MM/DD';

export function stringParamToString(stringValue: string): string {
  return stringValue;
}

export function stringParamToStringArray(stringValue: string): ?$ReadOnlyArray<string> {
  const values = stringValue.split(ARRAY_DELIMETER).filter(s => s.length > 0);
  return values.length > 0 ? values : null;
}

export function base64StringParamToStringArray(stringValue: string): ?$ReadOnlyArray<string> {
  const values = stringValue
    .split(ARRAY_DELIMETER)
    .filter(value => value.length > 0)
    .map(value => window.atob(value));
  return values.length > 0 ? values : null;
}

export function stringParamToEnumArray<A>(
  enumValues: $ReadOnlyArray<A>,
): string => ?$ReadOnlyArray<A> {
  return (stringValue: string): ?$ReadOnlyArray<A> => {
    const values: $ReadOnlyArray<A> = (stringValue
      .split(ARRAY_DELIMETER)
      .filter(s => enumValues.includes(s)): any);
    return values.length > 0 ? values : null;
  };
}

export function stringParamToEnum<A>(enumValues: $ReadOnlyArray<A>): string => ?A {
  return (stringValue: string): ?A => {
    return enumValues.includes(stringValue) ? ((stringValue: any): A) : null;
  };
}

export function stringParamToBoolean(stringValue: string): ?boolean {
  if (stringValue === 'true') return true;
  if (stringValue === 'false') return false;
  return null;
}

export function stringParamToNumber(stringValue: string): ?number {
  const num: number = toNumber(stringValue);
  return Number.isNaN(num) ? null : num;
}

export type NumberRangeParam = {
  min: ?number,
  max: ?number,
};

export function stringParamToNumberRange(stringValue: string): ?NumberRangeParam {
  const [, minStr] = stringValue.match(/min:(-?\d+)/) || [];
  const [, maxStr] = stringValue.match(/max:(-?\d+)/) || [];
  const min = stringParamToNumber(minStr);
  const max = stringParamToNumber(maxStr);
  if ((min == null && max == null) || (max != null && min != null && min > max)) {
    return null;
  }
  return { min, max };
}

export type SortParam = {
  key: string,
  asc: boolean,
};

export function stringParamToSort(stringValue: string): ?SortParam {
  const [key, rawDir] = stringValue.split(SORT_DELIMETER);
  if (key === '') return null;
  return {
    key,
    asc: rawDir !== 'desc', // default to ascending
  };
}

export type SearchParam = {
  search: string,
  exactMatch: boolean,
};

export function stringParamToSearch(stringValue: string): ?SearchParam {
  const search = stringValue.replace('~EXACT', '');
  const exactMatch = stringValue.includes('~EXACT');
  if (search === '') return null;
  return { search, exactMatch };
}

export type DateRangeParam = {
  key?: ?string,
  start: moment,
  end: moment,
};

export function stringParamToDateRange(
  stringValue: string,
  fiscalYearStart: number = 0,
): ?DateRangeParam {
  const now = moment().startOf('day');
  const shorthands = {
    past: () => [moment('2016-01-01'), now],
    thisYear: () => [moment().startOf('year'), moment().endOf('year')],
    nextYear: () => [
      moment().startOf('year').add(1, 'year'),
      moment().endOf('year').add(1, 'year'),
    ],
    next30days: () => [now, now.clone().add(30, 'day')],
    next60days: () => [now, now.clone().add(60, 'day')],
    next90days: () => [now, now.clone().add(90, 'day')],
    'FY-1': () => {
      const year = getFiscalYear(-1, fiscalYearStart);
      return [year.start, year.end];
    },
    FY0: () => {
      const year = getFiscalYear(0, fiscalYearStart);
      return [year.start, year.end];
    },
    FY1: () => {
      const year = getFiscalYear(1, fiscalYearStart);
      return [year.start, year.end];
    },
    'FQ-2': () => {
      const quarter = getQuarter(-2, fiscalYearStart);
      return [quarter.start, quarter.end];
    },
    'FQ-1': () => {
      const quarter = getQuarter(-1, fiscalYearStart);
      return [quarter.start, quarter.end];
    },
    FQ0: () => {
      const quarter = getQuarter(0, fiscalYearStart);
      return [quarter.start, quarter.end];
    },
    FQ1: () => {
      const quarter = getQuarter(1, fiscalYearStart);
      return [quarter.start, quarter.end];
    },
    FQ2: () => {
      const quarter = getQuarter(2, fiscalYearStart);
      return [quarter.start, quarter.end];
    },
  };
  if (shorthands[stringValue]) {
    const [start, end] = shorthands[stringValue]();
    return {
      key: stringValue,
      start,
      end,
    };
  }

  const dates = stringValue.split('-');
  if (dates.length === 2) {
    const start = moment(dates[0], FILTER_DATE_FORMAT);
    const end = moment(dates[1], FILTER_DATE_FORMAT).endOf('day');

    if (start.isValid() && end.isValid() && start.isSameOrBefore(end)) {
      return { key: stringValue, start, end };
    }
  }

  return null;
}

export function stringParamToDate(stringValue: string): ?moment {
  const date = moment(stringValue, FILTER_DATE_FORMAT, true);
  return date.isValid() ? date : null;
}

export type CustomFieldParam = {|
  booleanValue?: ?boolean,
  stringArrayValue?: ?$ReadOnlyArray<string>,
  enumArrayValue?: ?$ReadOnlyArray<string>,
  numberRangeParam?: ?NumberRangeParam,
  dateRangeValue?: ?DateRangeParam,
|};

export function stringParamToCustomFieldBoolean(stringValue: string): CustomFieldParam {
  return { booleanValue: stringParamToBoolean(stringValue) };
}

export function stringParamToCustomFieldStringArray(stringValue: string): CustomFieldParam {
  return { stringArrayValue: stringParamToStringArray(stringValue) };
}

export function stringParamToCustomFieldEnumArray<A>(
  enumValues: $ReadOnlyArray<A>,
): string => ?CustomFieldParam {
  return (stringValue: string): ?CustomFieldParam => {
    const values: $ReadOnlyArray<string> = (stringValue
      .split(ARRAY_DELIMETER)
      .filter(s => enumValues.includes(s)): any);
    return values.length > 0 ? { enumArrayValue: values } : null;
  };
}

export function stringParamToCustomFieldNumberRange(stringValue: string): CustomFieldParam {
  return { numberRangeParam: stringParamToNumberRange(stringValue) };
}

export function stringParamToCustomFieldDateRange(
  stringValue: string,
  fiscalYearStart: number = 0,
): CustomFieldParam {
  return { dateRangeValue: stringParamToDateRange(stringValue, fiscalYearStart) };
}

// This is a function that operates on flow types.
// Given a type for a function that returns a type V, return the type V
// https://flow.org/en/docs/types/utilities/#toc-objmap
type ExtractReturnType = <V>((string) => V) => ?V;

type ParserSpec = {
  [paramName: string]: (string) => any,
};

/**
 * Given a raw query string, and an object mapping query params to types via above functions,
 * return an object mapping query params to typed results.
 * @param rawQueryString with or without query prefix e.g. "?showall=true"
 * @param parsers object of param names to functions, e.g. { showall: stringParamToBoolean }
 * @return object of typed param names to function, e.g. { showall: true }
 */
export default function parseTypedQueryString<O: ParserSpec>(
  rawQueryString: string,
  parsers: O,
): $ObjMap<O, ExtractReturnType> {
  const params: { [paramName: string]: string } = qs.parse(rawQueryString, {
    ignoreQueryPrefix: true,
  });

  return Object.keys(parsers).reduce((acc, paramName) => {
    const value = params[paramName];
    const valid = value != null && value !== '' && typeof value === 'string';
    return Object.assign(acc, {
      [paramName]: valid ? parsers[paramName](value) : null,
    });
  }, {});
}
