/* @flow */
import type { ImportContactFromWindow } from 'graph/mutations/contact/importContacts';
import track from '../track';

type Props = {
  eventId: ?number,
  eventName: ?string,
  teamId: ?number,
  teamName: ?string,
  fromWindow: ImportContactFromWindow,
};

const importedContacts = (props: Props) => {
  track('imported_contacts', {
    event_id: props.eventId != null ? props.eventId : null,
    event_name: props.eventName || null,
    team_id: props.teamId != null ? props.teamId : null,
    team_name: props.teamName || null,
    from_window: props.fromWindow,
  });
};

export default importedContacts;
