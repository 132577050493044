/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationResults_org$ref: FragmentReference;
export type RegistrationResults_org = {|
  +registeredOption: {|
    +id: string
  |},
  +$refType: RegistrationResults_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationResults_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "registeredOption",
      "name": "fieldDefaultOption",
      "storageKey": "fieldDefaultOption(customizableType:\"EVENTCONTACT\",fieldName:\"registration_status_id\",optionName:\"Registered\")",
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": "EVENTCONTACT",
          "type": "CustomizableType!"
        },
        {
          "kind": "Literal",
          "name": "fieldName",
          "value": "registration_status_id",
          "type": "String!"
        },
        {
          "kind": "Literal",
          "name": "optionName",
          "value": "Registered",
          "type": "String!"
        }
      ],
      "concreteType": "CustomFieldOptionType",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bd9248c79cfbabfa6d8505b7644745f7';
module.exports = node;
