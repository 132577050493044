/**
 * @flow
 * @relayHash d5160a8f53a61494f526d0934aea10cb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventAccessLevel_staffedEvent$ref = any;
type EventActionsColumn_staffedEvent$ref = any;
type EventDateAdded_staffedEvent$ref = any;
type EventDate_staffedEvent$ref = any;
type EventLocation_staffedEvent$ref = any;
type EventName_staffedEvent$ref = any;
type EventOnsite_staffedEvent$ref = any;
export type StaffAccessLevel = "FULL" | "LIMITED" | "NONE";
export type AddUserToEventInput = {
  clientMutationId?: ?string,
  userId: string,
  staffInvites: StaffInviteInput,
};
export type StaffInviteInput = {
  eventIds: $ReadOnlyArray<string>,
  accessLevel: StaffAccessLevel,
};
export type addUserToEventMutationVariables = {|
  input: AddUserToEventInput
|};
export type addUserToEventMutationResponse = {|
  +addUserToEvent: ?{|
    +staffMembershipEdges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: EventAccessLevel_staffedEvent$ref & EventDate_staffedEvent$ref & EventDateAdded_staffedEvent$ref & EventLocation_staffedEvent$ref & EventName_staffedEvent$ref & EventOnsite_staffedEvent$ref & EventActionsColumn_staffedEvent$ref,
      |}
    |}>
  |}
|};
export type addUserToEventMutation = {|
  variables: addUserToEventMutationVariables,
  response: addUserToEventMutationResponse,
|};
*/


/*
mutation addUserToEventMutation(
  $input: AddUserToEventInput!
) {
  addUserToEvent(input: $input) {
    staffMembershipEdges {
      node {
        id
        ...EventAccessLevel_staffedEvent
        ...EventDate_staffedEvent
        ...EventDateAdded_staffedEvent
        ...EventLocation_staffedEvent
        ...EventName_staffedEvent
        ...EventOnsite_staffedEvent
        ...EventActionsColumn_staffedEvent
      }
    }
  }
}

fragment EventAccessLevel_staffedEvent on StaffMembership {
  accessLevel
  belongsToTeam
}

fragment EventDate_staffedEvent on StaffMembership {
  event {
    startDate
    endDate
    tz
    id
  }
}

fragment EventDateAdded_staffedEvent on StaffMembership {
  addedAt
  event {
    tz
    id
  }
}

fragment EventLocation_staffedEvent on StaffMembership {
  event {
    primaryLocation {
      city
      state
      name
      country
      id
    }
    id
  }
}

fragment EventName_staffedEvent on StaffMembership {
  event {
    slug
    name
    viewerCanView
    id
  }
}

fragment EventOnsite_staffedEvent on StaffMembership {
  id
  onsite
  viewerCanUpdate
}

fragment EventActionsColumn_staffedEvent on StaffMembership {
  id
  viewerCanUpdateAccessLevel
  viewerCanRemove
  accessLevel
  event {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddUserToEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddUserToEventInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addUserToEventMutation",
  "id": null,
  "text": "mutation addUserToEventMutation(\n  $input: AddUserToEventInput!\n) {\n  addUserToEvent(input: $input) {\n    staffMembershipEdges {\n      node {\n        id\n        ...EventAccessLevel_staffedEvent\n        ...EventDate_staffedEvent\n        ...EventDateAdded_staffedEvent\n        ...EventLocation_staffedEvent\n        ...EventName_staffedEvent\n        ...EventOnsite_staffedEvent\n        ...EventActionsColumn_staffedEvent\n      }\n    }\n  }\n}\n\nfragment EventAccessLevel_staffedEvent on StaffMembership {\n  accessLevel\n  belongsToTeam\n}\n\nfragment EventDate_staffedEvent on StaffMembership {\n  event {\n    startDate\n    endDate\n    tz\n    id\n  }\n}\n\nfragment EventDateAdded_staffedEvent on StaffMembership {\n  addedAt\n  event {\n    tz\n    id\n  }\n}\n\nfragment EventLocation_staffedEvent on StaffMembership {\n  event {\n    primaryLocation {\n      city\n      state\n      name\n      country\n      id\n    }\n    id\n  }\n}\n\nfragment EventName_staffedEvent on StaffMembership {\n  event {\n    slug\n    name\n    viewerCanView\n    id\n  }\n}\n\nfragment EventOnsite_staffedEvent on StaffMembership {\n  id\n  onsite\n  viewerCanUpdate\n}\n\nfragment EventActionsColumn_staffedEvent on StaffMembership {\n  id\n  viewerCanUpdateAccessLevel\n  viewerCanRemove\n  accessLevel\n  event {\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addUserToEventMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addUserToEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddUserToEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffMembershipEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffMembershipRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembership",
                "plural": false,
                "selections": [
                  v2,
                  {
                    "kind": "FragmentSpread",
                    "name": "EventAccessLevel_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventDate_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventDateAdded_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventLocation_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventName_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventOnsite_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventActionsColumn_staffedEvent",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "addUserToEventMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addUserToEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddUserToEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffMembershipEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffMembershipRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembership",
                "plural": false,
                "selections": [
                  v2,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "accessLevel",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "belongsToTeam",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "event",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "startDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "tz",
                        "args": null,
                        "storageKey": null
                      },
                      v2,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "primaryLocation",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Location",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "city",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "state",
                            "args": null,
                            "storageKey": null
                          },
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "country",
                            "args": null,
                            "storageKey": null
                          },
                          v2
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      },
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanView",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "addedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "onsite",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdateAccessLevel",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanRemove",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd49469d35d0a19fe5aab0e32e6f46334';
module.exports = node;
