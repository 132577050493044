/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import ConfirmationWindow from 'components/ConfirmationWindow';
import LockIcon from 'components/LockIcon';

import OrgSettingsPropsActions from './OrgSettingsPropsActions';
import OrgSettingsPropsTextField from './OrgSettingsPropsTextField';

export const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  max-width: 700px;
`;

export const Name = styled.div`
  flex: 1 2 272px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3e4859;
  ${props =>
    props.disabled &&
    css`
      cursor: default;
    `};
`;

const NameTextField = styled(OrgSettingsPropsTextField)`
  flex: 1 2 272px;
`;

const Description = styled.div`
  flex: 2 2 544px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
   ${props =>
     props.hasDescription
       ? css`
           color: #4a5665;
         `
       : css`
           opacity: 0;
           color: rgba(74, 86, 101, 0.54);
           &:hover {
             color: #4a5665;
           }
         `}
          ${props =>
            !props.disabled &&
            css`
              cursor: pointer;
              &:hover {
                opacity: 1;
              }
            `}
  }
`;

const DescriptionTextField = styled(OrgSettingsPropsTextField)`
  flex: 2 2 544px;
  margin-left: 10px;
`;

const StyledLockIcon = styled(LockIcon)`
  opacity: 0;
`;

const StyledOrgSettingsPropsActions = styled(OrgSettingsPropsActions)`
  align-self: center;
  opacity: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: baseline;
  width: 100%;
  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;
    `} &:hover {
    background: ${props => props.theme.teamSettingsRowHoverColor};
  }
  &:hover {
    ${Description}, ${StyledLockIcon}, ${StyledOrgSettingsPropsActions} {
      opacity: 1;
    }
  }
  ${props =>
    props.editing
      ? css`
          padding: 9px 10px 5px 17px;
        `
      : css`
          padding: 11px 10px 7px 17px;
        `};
`;

export default class OrgSettingsPropsRow extends React.PureComponent<
  {
    id: string,
    itemName: string,
    name: string,
    description: ?string,
    onSave: (id: string, name: string, description: ?string) => void,
    onDelete: (id: string) => void,
    disabledNameChange?: boolean,
    disabled?: ?boolean,
    isDefault?: ?boolean,
  },
  {
    editingName: boolean,
    editingDesc: boolean,
    confirmingDelete: boolean,
  },
> {
  state = {
    editingName: false,
    editingDesc: false,
    confirmingDelete: false,
  };

  handleDelete = () => {
    this.props.onDelete(this.props.id);
  };

  handleEditName = () => {
    if (!this.props.disabled) {
      this.setState({ editingName: true });
    }
  };

  handleSaveName = (name: string) => {
    if (name && name !== this.props.name) {
      this.props.onSave(this.props.id, name, this.props.description);
    }
    this.setState({ editingName: false });
  };

  handleEditDescription = () => {
    this.setState({ editingDesc: true });
  };

  handleSaveDescription = (description: string) => {
    if (description !== this.props.description) {
      this.props.onSave(this.props.id, this.props.name, description);
    }
    this.setState({ editingDesc: false });
  };

  handleConfirmationShow = () => {
    this.setState({ confirmingDelete: true });
  };

  handleConfirmationHide = () => {
    this.setState({ confirmingDelete: false });
  };

  render() {
    return (
      <Container>
        <Wrapper
          editing={this.state.editingDesc || this.state.editingName}
          disabled={this.props.disabled || this.props.disabledNameChange}
        >
          {this.state.editingName ? (
            <NameTextField name={this.props.name} onSave={this.handleSaveName} />
          ) : (
            <Name
              disabled={this.props.disabled || this.props.disabledNameChange}
              onClick={this.handleEditName}
            >
              {this.props.name}
            </Name>
          )}
          {this.state.editingDesc ? (
            <DescriptionTextField
              placeholder="Add description"
              name={this.props.description || ''}
              onSave={this.handleSaveDescription}
            />
          ) : (
            <Description
              hasDescription={this.props.description}
              onClick={
                this.props.disabled || this.props.isDefault ? undefined : this.handleEditDescription
              }
              disabled={this.props.disabled}
            >
              {this.props.description ||
                this.props.disabled ||
                this.props.isDefault ||
                'Add description'}
            </Description>
          )}
          {this.props.disabled || this.props.isDefault ? (
            <StyledLockIcon label={this.props.disabled ? 'From Salesforce' : 'Default'} />
          ) : (
            <StyledOrgSettingsPropsActions onRemove={this.handleConfirmationShow} />
          )}
          {this.state.confirmingDelete && (
            <ConfirmationWindow
              onHide={this.handleConfirmationHide}
              onConfirm={this.handleDelete}
              message={`Deleting this ${this.props.itemName} also removes it from Events.`}
            />
          )}
        </Wrapper>
      </Container>
    );
  }
}
