/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import TextField from '../../../components/TextField';

import { type EventInfoCustomTextarea_event } from './__generated__/EventInfoCustomTextarea_event.graphql';

const EventInfoCustomTextarea = ({
  name,
  event,
  customFieldId,
}: {
  name: string,
  event: EventInfoCustomTextarea_event,
  customFieldId: string,
}) => {
  const fieldValue = event.customTextareaFields.find(
    value => value.customField.id === customFieldId,
  );

  if (!fieldValue) return null;

  return <TextField name={name} description={fieldValue.value} />;
};

export default createFragmentContainer(
  EventInfoCustomTextarea,
  graphql`
    fragment EventInfoCustomTextarea_event on Event {
      customTextareaFields(customFieldIds: $customFieldIds) {
        value
        customField {
          id
        }
      }
    }
  `,
);
