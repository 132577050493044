/* @flow */
import React from 'react';
import { Link } from 'react-router-dom';

import parseGroupInfo from '../../lib/parseGroupInfo';
import { type ReportingCellPropsType } from './index';

const ReportingGroup2Cell = ({
  group2,
  customGroup2,
  childGroup,
  location,
}: ReportingCellPropsType) => {
  if (childGroup == null || (group2 == null && customGroup2 == null)) return '';
  const { groupName, slug } = parseGroupInfo(childGroup.name, group2);
  if (slug == null) {
    return groupName;
  }

  return (
    <Link
      to={{
        pathname: `/events/${slug}/budget`,
        state: {
          from: 'budget_reporting',
          fromUrl: location && location.pathname,
        },
      }}
    >
      {groupName}
    </Link>
  );
};

export default ReportingGroup2Cell;
