/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import capitalize from 'lodash/capitalize';

import SimpleBriefField from '../../components/SimpleBriefField';

import { type EventInfoDimensions_event } from './__generated__/EventInfoDimensions_event.graphql';

const EventInfoDimensions = (props: { name: string, event: EventInfoDimensions_event }) => {
  const {
    name,
    event: {
      eventFloorPlan: { boothDimensions },
    },
  } = props;
  return (
    <SimpleBriefField
      label={name}
      contentDataAvailable={boothDimensions}
      data={capitalize(boothDimensions)}
    />
  );
};

export default createFragmentContainer(
  EventInfoDimensions,
  graphql`
    fragment EventInfoDimensions_event on Event {
      eventFloorPlan {
        boothDimensions
      }
    }
  `,
);
