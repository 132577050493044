/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type { Location } from 'utils/locations/locationTypes';
import type { createLocationMutationResponse } from './__generated__/createLocationMutation.graphql';

const mutation = graphql`
  mutation createLocationMutation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      locationEdge {
        __typename
        node {
          id
          dbId
          name
          state
          city
          country
          postal
          note
          website
          address1
          address2
          lat
          lng
          tz
          event {
            dbId
            name
          }
        }
      }
    }
  }
`;

export default function createLocation(eventId: string, location: Location) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        eventId,
        name: location.name,
        state: location.state,
        city: location.city,
        country: location.country,
        postal: location.postal,
        note: location.note,
        website: location.website,
        address1: location.address1,
        address2: location.address2,
        lat: location.lat,
        lng: location.lng,
      },
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'locationEdge',
      connections: [{ field: 'locations' }],
    }),
  }).then((response: createLocationMutationResponse) => {
    if (!response.createLocation) throw Error('Invalid response');

    return response.createLocation.locationEdge.node;
  });
}
