import './styles.styl';

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { stringify } from 'qs';

import { Content } from 'components/page/Content';

import SchedulesMultiHeader from './SchedulesMultiHeader';
import SchedulesTimeline from './SchedulesTimeline';

const COLORS = ['#5a9dd2', '#e979b5', '#e9e261', '#9d69aa', '#f9a560', '#ef5962', '#cd6f5a'];

class SchedulesMulti extends React.Component {
  static propTypes = {
    eventId: PropTypes.number.isRequired,
    // teamId: PropTypes.number.isRequired,
    active: PropTypes.arrayOf(PropTypes.string).isRequired,
    filters: PropTypes.object.isRequired,
    event: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      tz: PropTypes.string.isRequired,
      schedules: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
              slug: PropTypes.string.isRequired,
              slugs: PropTypes.arrayOf(PropTypes.shape({ slug: PropTypes.string })).isRequired,
              order: PropTypes.number.isRequired,
            }).isRequired,
          }),
        ),
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  handleViewChange = () => {
    this.props.history.push(`/events/${this.props.event.slug}/schedules`);
  };

  handleScheduleToggle = slug => {
    const schedules = this.props.event.schedules.edges.map(x => x.node);
    const schedule = schedules.find(s => s.slugs.some(x => x.slug === slug));
    const isActive = this.props.active.some(s => schedule.slugs.some(x => x.slug === s));
    let active = this.props.active.slice();
    if (isActive) {
      active = active.filter(s => schedule.slugs.every(x => x.slug !== s));
    } else {
      active.push(schedule.slug);
    }
    this.props.history.push({
      pathname: `/events/${this.props.event.slug}/schedules`,
      search: stringify(Object.assign(this.props.filters, { active: active.join() })),
    });
  };

  /* Share start */

  handleMultiShareClick = scheduleTokens => {
    window.open(`/share/schedules/multi?tokens=${scheduleTokens}`);
  };

  handlePrintClick = scheduleTokens => {
    window.open(`/share/schedules/multi/embed/?tokens=${scheduleTokens}&print=1`);
  };

  render() {
    const { event, active, eventId } = this.props;

    const schedules = sortBy(
      event.schedules.edges.map(x => x.node),
      'order',
    );
    const activeSchedules = sortBy(
      active
        .map(slug => schedules.find(schedule => schedule.slugs.some(s => s.slug === slug)))
        .filter(x => x),
      'order',
    );

    return (
      <Content className="schedules-multi">
        <SchedulesMultiHeader
          event={event}
          activeSchedules={activeSchedules.map(x => ({ id: x.id, token: x.token }))}
          colors={COLORS}
          onViewChange={this.handleViewChange}
          onMultiShareClick={this.handleMultiShareClick}
          onPrintClick={this.handlePrintClick}
          onScheduleToggle={this.handleScheduleToggle}
        />
        <SchedulesTimeline
          schedules={activeSchedules}
          tz={event.tz}
          colors={activeSchedules.map(
            s => COLORS[schedules.findIndex(x => x.id === s.id) % COLORS.length],
          )}
          eventId={eventId}
        />
      </Content>
    );
  }
}

export default createFragmentContainer(
  SchedulesMulti,
  graphql`
    fragment SchedulesMulti_event on Event {
      slug
      tz
      ...SchedulesMultiHeader_event
      schedules {
        edges {
          node {
            id
            name
            slug
            slugs {
              slug
            }
            order
            shared
            token
            dbId
            ...SchedulesTimeline_schedules
          }
        }
      }
    }
  `,
);
