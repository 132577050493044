import { darken, transparentize } from 'polished';

export default {
  primaryActionColor: '#3BA9DA',
  primaryActionTextColor: '#fff',
  primaryActionDarkerColor: darken(0.1, '#3BA9DA'),
  highlightActionColor: '#29CC71',
  highlightActionTextColor: '#fff',
  highlightActionDarkerColor: darken(0.1, '#29CC71'),
  negativeActionColor: '#f38183',
  negativeActionTextColor: '#fff',
  negativeActionDarkerColor: '#f25d60',
  secondaryActionColor: transparentize(0.7, '#37404C'),
  secondaryActionTextColor: '#fff',
  secondaryActionDarkerColor: transparentize(0.3, '#37404C'),
  secondaryActionDarkestColor: transparentize(0.2, '#37404C'),
  disabledActionColor: transparentize(0.4, '#b6b6b6'),

  primaryRowColor: '#f8f8f9',
  secondaryRowColor: '#fff',
  hoverRowColor: '#f3fafd',
  selectedRowColor: '#fffaed',
  rowPrimaryTextColor: '#4a5665',
  rowSecondaryTextColor: transparentize(0.1, '#747F8D'),
  headerRowColor: '#3e4859',
  headerRowHoverColor: '#737373',

  highlightColor: '#29CC71',

  checkboxCheckColor: '#29CC71',
  checkboxUncheckedColor: '#e7e8e9',
  checkboxTextColor: '#fff',

  borderColor: '#e6e6e6',
  mutedTextColor: '#ccc',
  darkestTextColor: '#3e4859',

  labelColor: transparentize(0.46, '#4a5665'),

  tooltipColor: '#4a5664',
  tooltipTextColor: '#fff',

  tagTextColor: '#fff',

  contentBackgroundColor: '#fff',

  mobileOnly: 'max-width: 800px',

  overlayBackgroundColor: transparentize(0.5, '#000'),

  pageHorizontalPadding: '28px',

  ganttHeaderColor: '#5d6b76',
  ganttHeaderDarkerColor: '#4a5560',
  ganttTextColor: '#fff',
  ganttTextDarkerColor: transparentize(0.3, '#fff'),
  ganttBorderColor: transparentize(0.7, '#fff'),
  ganttHoverColor: transparentize(0.88, '#74b1cc'),
  ganttHoverDarkerColor: transparentize(0.6, '#74b1cc'),
  ganttStripeColor: transparentize(0.9, '#fff'),
  ganttStripeLightColor: transparentize(0.8, '#ccc'),
  ganttOpenColor: '#ccc',
  ganttCompletedColor: '#29CC71',
  ganttCompletedEarlyColor: transparentize(0.4, '#29CC71'),
  ganttOverdueColor: '#f48183',

  windowHeaderBackgroundColor: '#fff',
  windowBackgroundColor: '#fff',
  windowTitleColor: '#3e4859',
  windowSubtitleColor: '#5c5c5c',

  infoMenuColor: '#828b93',
  infoMenuHoverColor: '#4a5665',
  infoMenuHoverBackgroundColor: '#f4fafd',

  syncIconDefaultColor: '#f48183',
  syncIconPendingColor: '#979797',

  toolbarBorderColor: '#979797',

  teamSettingsRowHoverColor: '#f8f9f9',
  blackHaze: '#f8f9f9',
  osloGray: '#828b93',
  oxfordBlue: '#3e4859',
  shamrock: '#29cc71',
  iron: '#cdd1d4',
  riverBed: '#4a5665',

  minimalButtonColor: '#3baadd',
  outlineButtonColor: '#828b93',
  outlineButtonFocusColor: '#4a5665',
  silver: '#cdd1d4',

  twitterLogoColor: '#59adeb',
  linkedInLogoColor: '#1977a6',

  borderSeparatorColor: '#e0e0e0',
  infoTextColor: '#8d9aa3',
  failedMessageColor: 'rgba(244, 129, 131, 0.07)',
  neutralMessageColor: '#f8f9f9',
};
