/**
 * @flow
 * @relayHash 46b978eafb99830fc77753c242200494
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveEventListInput = {
  clientMutationId?: ?string,
  eventListId: string,
};
export type removeEventListMutationVariables = {|
  input: RemoveEventListInput
|};
export type removeEventListMutationResponse = {|
  +removeEventList: ?{|
    +removedEventListId: string,
    +user: {|
      +eventLists: {|
        +totalCount: number
      |}
    |},
  |}
|};
export type removeEventListMutation = {|
  variables: removeEventListMutationVariables,
  response: removeEventListMutationResponse,
|};
*/


/*
mutation removeEventListMutation(
  $input: RemoveEventListInput!
) {
  removeEventList(input: $input) {
    removedEventListId
    user {
      eventLists {
        totalCount
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveEventListInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveEventListInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedEventListId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "eventLists",
  "storageKey": null,
  "args": null,
  "concreteType": "EventListRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeEventListMutation",
  "id": null,
  "text": "mutation removeEventListMutation(\n  $input: RemoveEventListInput!\n) {\n  removeEventList(input: $input) {\n    removedEventListId\n    user {\n      eventLists {\n        totalCount\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeEventListMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeEventList",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveEventListPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeEventListMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeEventList",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveEventListPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd54f3d68959f1d07c9feb6d788178020';
module.exports = node;
