/* @flow */
import styled from 'styled-components';

const TasksCount = styled.span`
  margin-left: 3px;
  color: ${props =>
    props.completed ? props.theme.highlightColor : props.theme.rowSecondaryTextColor};
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }
`;
export default TasksCount;
