/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import { defaultTableComponents } from 'components/Contacts/columns';
import { type AvailableContactsType } from 'components/Contacts/ContactsMassUpdatePanel';
import colsConfig, { getColumnsShowConfig } from 'components/Contacts/contactsTableColumnSettings';
import getContactFields from 'components/Contacts/lib/getContactFields';
import parseContactsFilters from 'components/Contacts/lib/parseContactsFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { type ColumnConfiguration } from 'components/material/table';

import OrgContactsHeaderBar from './OrgContactsHeaderBar';
import OrgContactsTablePagination from './OrgContactsTablePagination';

import { type OrgContactsPage_org } from './__generated__/OrgContactsPage_org.graphql';
import { type OrgContactsPageQueryResponse } from './__generated__/OrgContactsPageQuery.graphql';

const query = graphql`
  query OrgContactsPageQuery(
    $count: Int!
    $cursor: String
    $filters: ContactFilters!
    $includeName: Boolean!
    $includeEmail: Boolean!
    $includeSalesforceLeadStatusId: Boolean!
    $includeSalesforceSyncAs: Boolean!
    $includeSalesforceId: Boolean!
    $includeMarketoId: Boolean!
    $includeLastSynced: Boolean!
    $includeSyncStatus: Boolean!
    $includeEvents: Boolean!
    $includeRequestSubmissions: Boolean!
    $includeContactTypeId: Boolean!
    $includeCompanyId: Boolean!
    $includeTitle: Boolean!
    $includeOwnerId: Boolean!
    $includeWebsite: Boolean!
    $includePhone1: Boolean!
    $includePhone2: Boolean!
    $includeStreet: Boolean!
    $includeCity: Boolean!
    $includeState: Boolean!
    $includeZip: Boolean!
    $includeCountry: Boolean!
    $includeTwitter: Boolean!
    $includeLinkedin: Boolean!
    $includeDescription: Boolean!
    $includeHotLead: Boolean!
    $includeEmailOptIn: Boolean!
    $includeUpdatedAt: Boolean!
    $includeUpdatedBy: Boolean!
    $includeUpdatedMethod: Boolean!
    $includeCreatedAt: Boolean!
    $includeCreatedBy: Boolean!
    $includeCreatedMethod: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
  ) {
    org {
      ...OrgContactsTablePagination_org
      ...OrgContactsTablePagination_totalCountOrg
    }
  }
`;

class OrgContactsPage extends React.Component<
  {
    org: OrgContactsPage_org,
    history: RouterHistory,
    location: Location,
    salesforceEnabled: boolean,
    marketoEnabled: boolean,
    tz: string,
    userEmail: string,
    pathPrefix: string,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
    selectedContacts: $ReadOnlyArray<string>,
    availableContacts: AvailableContactsType,
    allContactsSelected: boolean,
  },
> {
  contactFields: $ReadOnlyArray<FieldType> = getContactFields(
    normalizeCustomFields(this.props.org.customFields.edges.map(edge => edge.node)),
    { salesforceEnabled: this.props.salesforceEnabled, marketoEnabled: this.props.marketoEnabled },
  );

  updateTableColumnWidths: ?() => void = null;

  static getShownColumns(columns: $ReadOnlyArray<FieldType>): $ReadOnlyArray<string> {
    const shownColumns: any = (storage.get('orgContactsShownColumns'): ?mixed);
    return Array.isArray(shownColumns)
      ? shownColumns
      : getColumnsShowConfig(columns)
          .filter(column => column.default)
          .map(column => column.value);
  }

  state = {
    shownColumns: OrgContactsPage.getShownColumns(this.contactFields),
    selectedContacts: [],
    availableContacts: [],
    allContactsSelected: false,
  };

  handleContactsSearch = (search: string) => {
    replaceQueryParams(this.props.history, { search: search || null });
  };

  handleColumnsChange = (shownColumns: $ReadOnlyArray<string>) => {
    storage.set('orgContactsShownColumns', shownColumns);
    this.setState({ shownColumns });
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  handleContactsListUpdate = (availableContacts: AvailableContactsType) => {
    this.setState({ availableContacts });
  };

  handleSelectContacts = (selectedContacts: $ReadOnlyArray<string>) => {
    this.setState({ selectedContacts });
  };

  getCurrentSelection = (): AvailableContactsType => {
    return this.state.availableContacts.filter(item =>
      this.state.selectedContacts.includes(item.id),
    );
  };

  handleUpdateTableColumnWidths = () => {
    if (this.updateTableColumnWidths) this.updateTableColumnWidths();
  };

  handleAllContactsSelectedUpdate = (allContactsSelected: boolean) => {
    this.setState({
      allContactsSelected,
    });
  };

  setColumnWidthUpdater = (updateColumnWidths: () => void) => {
    this.updateTableColumnWidths = updateColumnWidths;
  };

  renderTable = (props: {|
    org: ?$PropertyType<OrgContactsPageQueryResponse, 'org'>,
    shownColumns: ColumnConfiguration,
    filtered: boolean,
    sort: SortParam,
  |}): React.Node => {
    return (
      <OrgContactsTablePagination
        org={props.org}
        shownColumns={props.shownColumns}
        filtered={props.filtered}
        totalCountOrg={props.org}
        onChangeSort={this.handleSortChange}
        onSelectContacts={this.handleSelectContacts}
        selectedContacts={this.state.selectedContacts}
        onContactsListUpdate={this.handleContactsListUpdate}
        setColumnWidthUpdater={this.setColumnWidthUpdater}
        handleAllContactsSelectedUpdate={this.handleAllContactsSelectedUpdate}
        sort={props.sort}
        tz={this.props.tz}
      />
    );
  };

  render() {
    const { org, history, location, userEmail, pathPrefix } = this.props;
    const shownColumns = this.state.shownColumns;
    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      this.contactFields,
      shownColumns,
    );
    const filters = parseContactsFilters(location.search, this.contactFields);
    const filtered = Object.values(filters).some(val => !!val);
    // Default sorting to name field
    const sortFilter = filters.sort || {
      key: 'NAME',
      asc: true,
    };

    const customSortField: ?FieldType = this.contactFields.find(
      field => field.id === sortFilter.key,
    );

    const shownColumnsConfig = colsConfig(this.contactFields, shownColumns);

    const variables = {
      count: 25,
      filters: {
        sort: customSortField ? 'CUSTOM' : sortFilter.key,
        customFieldSortId: customSortField != null ? customSortField.id : null,
        search: filters.search,
        direction: sortFilter.asc ? 'ASC' : 'DESC',
        createdAt: filters.created_at,
        creatorIds: filters.created_by,
        createdMethods: filters.created_method,
        updatedAt: filters.updated_at,
        updaterIds: filters.updated_by,
        updatedMethods: filters.updated_method,
        ownerIds: filters.owner_id,
        contactTypeIds: filters.contact_type_id,
        hotLead: filters.hot_lead,
        emailOptIn: filters.email_opt_in,
        cities: filters.city,
        states: filters.state,
        countries: filters.country,
        salesforceSyncOptions: filters.salesforce_sync_as,
        leadStatusIds: filters.salesforce_lead_status_id,
        syncStatuses: filters.sync_status,
        customFilters: this.contactFields
          .filter(option => option.fieldName == null && filters[option.id] != null)
          .map(option => getCustomFieldVariable(filters[option.id], option)),
      },
      ...includedColumns,
    };

    return (
      <div>
        <OrgContactsHeaderBar
          org={org}
          history={history}
          filters={filters}
          contactFields={this.contactFields}
          filterInputs={variables.filters}
          onSearch={this.handleContactsSearch}
          userEmail={userEmail}
          pathPrefix={pathPrefix}
          shownColumns={shownColumns}
          onColumnsChange={this.handleColumnsChange}
          orgContactsCount={this.state.availableContacts.length}
          currentSelectedContacts={this.getCurrentSelection()}
          onUpdateTableColumnWidths={this.handleUpdateTableColumnWidths}
          rawContactFields={normalizeCustomFields(org.customFields.edges.map(edge => edge.node))}
          allContactsSelected={this.state.allContactsSelected}
        />

        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: OrgContactsPageQueryResponse) =>
            this.renderTable({
              org: props.org,
              shownColumns: shownColumnsConfig,
              filtered,
              sort: sortFilter,
            })
          }
          renderLoading={() =>
            this.renderTable({
              org: null,
              shownColumns: shownColumnsConfig,
              filtered,
              sort: sortFilter,
            })
          }
        />
      </div>
    );
  }
}

export default createFragmentContainer(
  OrgContactsPage,
  graphql`
    fragment OrgContactsPage_org on Org {
      ...OrgContactsHeaderBar_org
      id
      customFields(customizableType: [CONTACT]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            customizableType
            restrictChangingValueForLead: mappedToSalesforce(pull: true, kind: LEAD)
            restrictChangingValueForContact: mappedToSalesforce(pull: true, kind: CONTACT)
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
);
