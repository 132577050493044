/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import type { QuestionType, QuestionValueType, QuestionValueInputType } from '../lib/types';
import CheckBox from '../form/CheckBox';
import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';

const Label = styled.div`
  color: rgba(74, 86, 101, 0.87);
  counter-increment: question-counter;
  &:before {
    content: counter(question-counter) '. ';
  }
`;

export default class QuestionBoolean extends React.PureComponent<{
  question: QuestionType,
  value: QuestionValueType,
  onChangeValue: QuestionValueInputType => void,
  readOnly: boolean,
  error: ?string,
}> {
  handleChange = () => {
    const checked = !this.props.value || !this.props.value.booleanValue;

    this.props.onChangeValue({ booleanValue: checked || null });
  };

  renderLabel = () => <Label>{this.props.question.name}</Label>;

  render() {
    const { question, readOnly, error, value } = this.props;

    return (
      <>
        <CheckBox
          label={question.name}
          labelRenderer={this.renderLabel}
          disabled={readOnly}
          onChange={this.handleChange}
          checked={!!(value && value.booleanValue)}
          error={!!error}
        />

        {this.props.question.description && (
          <HelperText>{this.props.question.description}</HelperText>
        )}

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  }
}
