/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { staffAccessLevelDetails } from 'config/staffAccessLevels';
import type { StaffAccessLevelCell_staffer } from './__generated__/StaffAccessLevelCell_staffer.graphql';

class StaffAccessLevelCell extends React.PureComponent<{
  staffer: StaffAccessLevelCell_staffer,
}> {
  render() {
    const { staffer } = this.props;

    const accessLevelInfo = staffAccessLevelDetails.find(
      info => info.value === staffer.accessLevel,
    );

    if (!accessLevelInfo) return null;

    if (staffer.belongsToTeam) {
      return `${accessLevelInfo.label} (via Team)`;
    }

    return accessLevelInfo.label;
  }
}

export default createFragmentContainer(
  StaffAccessLevelCell,
  graphql`
    fragment StaffAccessLevelCell_staffer on StaffMembership {
      accessLevel
      belongsToTeam
    }
  `,
);
