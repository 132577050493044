/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import NotesContainer from './NotesContainer';

import type { NotesQueryResponse } from './__generated__/NotesQuery.graphql';

const query = graphql`
  query NotesQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...NotesContainer_event
    }
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0px;
  padding: 0 0 0 ${props => props.theme.pageHorizontalPadding};
`;

export default class Notes extends React.Component<{
  match: Match,
  history: RouterHistory,
}> {
  render() {
    const { match, history } = this.props;
    return (
      <Body>
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug: match.params.event_slug }}
          renderSuccess={(props: NotesQueryResponse) => (
            <NotesContainer event={props.event} match={match} history={history} />
          )}
        />
      </Body>
    );
  }
}
