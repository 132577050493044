/* @flow */
import React from 'react';
import HighchartsReact from 'react-highcharts';
import styled from 'styled-components';
import Highcharts from 'highcharts';
import AnnotationsFactory from 'highcharts/modules/annotations';
import moment from 'moment';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 124px;
  margin-bottom: 36px;
  box-shadow: 0 11px 6px -10px #00000019;
  border: solid 1px #dadada;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
`;

const LeftColumn = styled.div`
  flex: 0 1 222px;
  min-width: 160px;
`;

const RightColumn = styled.div`
  flex: 1 1 0%;
`;

const RegistrationTotalLabel = styled.div`
  text-align: center;
  font-size: 17px;
  color: rgba(55, 57, 81, 0.7);
`;

const RegistrationTotal = styled.div`
  text-align: center;
  font-family: Roboto-Slab;
  font-size: 26px;
  font-weight: 500;
  color: #373951;
`;

// loading annotaions module as specified in design
AnnotationsFactory(Highcharts);

type Props = {
  totalRegistered: number,
  data: $ReadOnlyArray<{|
    +registrationCount: number,
    +registrationDate: ?string,
  |}>,
};

const RegistrationChart = ({ data, totalRegistered }: Props) => {
  const chartConfig = {
    chart: {
      type: 'column',
      height: 144,
      borderColor: '#ffffff',
      spacing: [0, 0, 0, 0],
    },
    annotations: [
      {
        draggable: '',
        zIndex: -1,
        shapes: [
          {
            fill: '#f2f4ff',
            strokeWidth: 0,
            point: {
              x: 0,
              y: 105,
            },
            type: 'rect',
            width: '100%',
            height: '38px',
          },
        ],
      },
    ],
    title: { text: null },
    subTitle: { text: null },
    legend: { enabled: false },
    xAxis: {
      title: { text: null },
      lineWidth: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      labels: { enabled: false },
    },
    yAxis: {
      title: { text: null },
      lineWidth: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      labels: { enabled: false },
    },
    tooltip: {
      headerFormat: '',
      backgroundColor: '#4a5664',
      borderWidth: 0,
      borderRadius: 0,
      pointFormatter() {
        /* eslint-disable react/no-this-in-sfc */
        return `${this.name ? moment(this.name).format(`MMM D`) : 'No Date'}: <b>${
          this.y
        } Registrations</b>`;
        /* eslint-enable react/no-this-in-sfc */
      },
      style: {
        fontFamily: 'Roboto',
        color: '#ffffff',
      },
      shadow: false,
    },
    series: [
      {
        data: data.map(d => ({
          name: d.registrationDate,
          y: d.registrationCount,
        })),
      },
    ],
    plotOptions: {
      series: {
        align: 'left',
        // color: 'linear-gradient(to bottom, #5b92f4, #3d9ad9)',
        borderRadius: 2,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#5b92f4'],
            [1, '#3d9ad9'],
          ],
        },
      },
      column: {
        maxPointWidth: 40,
        groupPadding: 0,
        pointPadding: 0,
      },
    },
    credits: { enabled: false },
  };

  return (
    <Container>
      <LeftColumn>
        <RegistrationTotalLabel>REGISTERED</RegistrationTotalLabel>
        <RegistrationTotal>{totalRegistered}</RegistrationTotal>
      </LeftColumn>
      <RightColumn>
        <HighchartsReact highcharts={Highcharts} config={chartConfig} allowChartUpdate={false} />
      </RightColumn>
    </Container>
  );
};

export default RegistrationChart;
