/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import type moment from 'moment-timezone';

import TasksCalendarTask from './TasksCalendarTask';

import type { TasksCalendarDay_tasks } from './__generated__/TasksCalendarDay_tasks.graphql';

const Container = styled.div`
  ${props =>
    props.moreShown &&
    `
      position: relative;
      z-index: 100;
      margin-bottom: 20px;
      padding-bottom: 20px;
      box-shadow: 0 10px 25px rgba(0, 0, 0, .25);
      background: ${props.theme.hoverRowColor};
  `};
  position: relative;
`;

const CalendarDayDate = styled.div`
  padding: 3px;
  font-size: 12px;
  color: ${props => props.theme.rowSecondaryTextColor};
  font-weight: 500;
`;

const CalendarMoreLink = styled.div`
  color: ${props => props.theme.primaryActionColor};
  font-size: 12px;
  margin: 3px 12px 0 12px;
`;

class TasksCalendarDay extends React.PureComponent<
  {
    date: moment,
    tz: string,
    tasks: TasksCalendarDay_tasks,
    onGetTaskLink: (taskSlug: string) => string,
  },
  { moreShown: boolean },
> {
  state = { moreShown: false };

  handleMouseEnter = () => {
    this.setState({ moreShown: true });
  };

  handleMouseLeave = () => {
    this.setState({ moreShown: false });
  };

  render() {
    const { tasks, tz, date, onGetTaskLink } = this.props;
    const needsCutoff = tasks.length > 3;
    const cutoff = this.state.moreShown ? Infinity : 3;
    const tasksShown = tasks.slice(0, cutoff);
    const tasksHidden = tasks.slice(cutoff);

    return (
      <Container
        onMouseEnter={needsCutoff ? this.handleMouseEnter : undefined}
        onMouseLeave={needsCutoff ? this.handleMouseLeave : undefined}
        moreShown={this.state.moreShown}
      >
        <CalendarDayDate>{date.date()}</CalendarDayDate>
        {tasksShown.map(task => (
          <TasksCalendarTask task={task} tz={tz} onGetTaskLink={onGetTaskLink} key={task.id} />
        ))}
        {tasksHidden.length > 0 && <CalendarMoreLink>+ {tasksHidden.length} more</CalendarMoreLink>}
      </Container>
    );
  }
}

export default createFragmentContainer(
  TasksCalendarDay,
  graphql`
    fragment TasksCalendarDay_tasks on Deliverable @relay(plural: true) {
      id
      ...TasksCalendarTask_task
    }
  `,
);
