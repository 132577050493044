/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import { type CellPropsType } from 'components/budget/Table';
import DateTimeTooltip from 'components/material/DateTimeTooltip';

import { type EventCellPropsType } from './index';

import type { EventLastSynced_contactEvent } from './__generated__/EventLastSynced_contactEvent.graphql';

const EventLastSynced = ({
  contactEvent: {
    syncStatus,
    node: { tz },
  },
}: CellPropsType<EventCellPropsType<EventLastSynced_contactEvent>>) => {
  if (!syncStatus.lastSuccessAt) return null;

  return <DateTimeTooltip date={moment.tz(syncStatus.lastSuccessAt, tz)} placement="right" />;
};

export default createFragmentContainer(
  EventLastSynced,
  graphql`
    fragment EventLastSynced_contactEvent on ContactEventEdge {
      syncStatus {
        lastSuccessAt
      }
      node {
        tz
      }
    }
  `,
);
