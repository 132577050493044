/**
 * @flow
 * @relayHash ff435b87d2f04eb62d8ef82469bd53ef
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveScheduleFromEventInput = {
  clientMutationId?: ?string,
  scheduleId: string,
};
export type removeScheduleMutationVariables = {|
  input: RemoveScheduleFromEventInput
|};
export type removeScheduleMutationResponse = {|
  +removeScheduleFromEvent: ?{|
    +removedScheduleId: string
  |}
|};
export type removeScheduleMutation = {|
  variables: removeScheduleMutationVariables,
  response: removeScheduleMutationResponse,
|};
*/


/*
mutation removeScheduleMutation(
  $input: RemoveScheduleFromEventInput!
) {
  removeScheduleFromEvent(input: $input) {
    removedScheduleId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveScheduleFromEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeScheduleFromEvent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveScheduleFromEventInput!"
      }
    ],
    "concreteType": "RemoveScheduleFromEventPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedScheduleId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeScheduleMutation",
  "id": null,
  "text": "mutation removeScheduleMutation(\n  $input: RemoveScheduleFromEventInput!\n) {\n  removeScheduleFromEvent(input: $input) {\n    removedScheduleId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeScheduleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeScheduleMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e0f830e25cf5e18fd00d82e29d617d09';
module.exports = node;
