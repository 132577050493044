/* @flow */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import UIContext from './UIContext';

const Border = styled.div`
  width: 100%;
  height: 5px;
  margin-top: 7px;
  border-radius: 2px;
  background-color: ${props => (props.bordercolor ? props.bordercolor : 'transparent')};
`;

const StyleNavLink = styled(NavLink)`
  flex: 1 0 auto;
  width: 110px;
  margin-right: 20px;
  padding-top: 7px;
  color: ${props => props.color};
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  transition: widt 0.2s, margin-right 0.2s;

  @media (max-width: 1050px) {
    width: 80px;
    margin-right: 5px;
  }

  &:hover {
    color: ${props => props.activestatecolor};
  }
  &.active {
    color: ${props => props.activestatecolor};
    ${Border} {
      background-color: ${props => props.borderactivecolor};
    }
  }

  ${props =>
    props.activestate &&
    css`
      color: ${props.activestatecolor};
      ${Border} {
        background-color: ${props.borderactivecolor};
      }
    `}
`;

const RegistrationTab = (props: { label: string, link: string, active: boolean }) => {
  const { label, link, active } = props;
  return (
    <UIContext.Consumer>
      {({ tabColor, tabUnderlineColor, activeTabColor, activeTabUnderlineColor }) => (
        <StyleNavLink
          to={link}
          activestate={active ? 1 : 0}
          color={tabColor}
          activestatecolor={activeTabColor}
          borderactivecolor={activeTabUnderlineColor}
        >
          {label}
          <Border bordercolor={tabUnderlineColor} />
        </StyleNavLink>
      )}
    </UIContext.Consumer>
  );
};

export default RegistrationTab;
