/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';

import type { FieldType } from 'utils/customization/types';

import type { VendorFieldInput } from 'graph/mutations/vendor/updateVendor';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import type { VendorName_vendor } from './__generated__/VendorName_vendor.graphql';

class VendorName extends React.Component<{
  vendor: VendorName_vendor,
  eventSlug?: string,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<VendorFieldInput>) => Promise<void>,
  pathPrefix: string,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?string) => {
    if (!this.getError(value) && value !== this.props.vendor.name) {
      this.props.onUpdate({ name: value });
      this.props.updateColumnWidth();
    }
  };

  handleValidate = (value: ?string): boolean => !!value && !!value.trim();

  getError = (value: ?string): ?string => {
    if (!value || !value.trim()) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { vendor, fieldSettings, updateColumnWidth, pathPrefix } = this.props;
    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={vendor.name || ''}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        onChange={this.handleUpdate}
      >
        <InlineExpandableText>
          <Link to={`${pathPrefix}/${vendor.id}`} onMouseDown={this.handleMouseDown}>
            {vendor.name}
          </Link>
        </InlineExpandableText>
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  VendorName,
  graphql`
    fragment VendorName_vendor on Vendor {
      id
      name
    }
  `,
);
