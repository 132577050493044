/* @flow */

import * as React from 'react';
import styled, { css } from 'styled-components';

const StatusBar = styled.div`
  height: 15px;
  padding-bottom: 3px;
  font-size: 10px;
  color: #3b97db;
  ${props =>
    props.isError &&
    css`
      color: ${props.theme.negativeActionColor};
    `};
`;

const Icon = styled.i`
  flex-shrink: 0;
  margin-left: 8px;
  color: #3b97db;
`;

export type Status = {
  isError: boolean,
  text: string,
};

const ImageUploadingStatus = (props: { status: Status }) => {
  return (
    <StatusBar isError={props.status.isError}>
      {props.status.text}
      {!props.status.isError &&
        props.status.text && <Icon className="fa fa-fw fa-circle-o-notch fa-spin" />}
    </StatusBar>
  );
};

export default ImageUploadingStatus;
