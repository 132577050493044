/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import formatPercentage from 'utils/number/formatPercentage';

import { type ReportingFooterCellPropsType } from './index';

const Container = styled.span`
  ${props =>
    props.exceeded &&
    css`
      color: #f25d60;
    `};
`;

const ReportingTotalActualPercentCell = ({ totals }: ReportingFooterCellPropsType) => {
  const ratio = totals.actualAmount / totals.plannedAmount;

  return <Container exceeded={ratio > 1}>{formatPercentage(ratio)}</Container>;
};

export default ReportingTotalActualPercentCell;
