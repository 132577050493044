/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { stringifyLocation } from 'utils/Location';
import isValidWebsite from 'utils/validators/isValidWebsite';

import PhysicalLocationIcon from 'images/physicalLocation.svg';
import { OutlineButton } from 'components/budget/Button';
import CopyLink from 'components/CopyLink';
import EventVirtualLocationTypeIcon from 'components/EventVirtualLocation/EventVirtualLocationTypeIcon';
import { ZOOM_LOCATION_KINDS } from 'components/integrations/ZoomLocationPicker';

import type { AllEventsCalendarEventWindowLocation_event } from './__generated__/AllEventsCalendarEventWindowLocation_event.graphql';

const LocationContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const LocationLabel = styled.div`
  align-self: center;
  word-break: break-word;
  font-size: 13px;
`;

const StyledPhysicalLocationIcon = styled(PhysicalLocationIcon)`
  flex-shrink: 0;
  margin: 0 8px 0 -3px;
`;

const ZoomLocationLabel = styled.div`
  align-self: center;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 500;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.primaryActionColor};

  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
    text-decoration: underline;
  }
`;

const StyledVirtualLocationTypeIcon = styled(EventVirtualLocationTypeIcon)`
  flex-shrink: 0;
  margin-top: 2px;
`;

const StyledOutlineButton = styled(OutlineButton)`
  padding: 7px 18px 8px;
`;

const StyledCopyLink = styled(CopyLink)`
  > div {
    margin-left: 15px;
    padding: 4px 10px;
    border: 1px solid #39a8da;
    border-radius: 4px;
    color: #39a8da;
    cursor: pointer;

    &:hover {
      border-color: #009dce;
      color: #009dce;
    }
  }
`;

class AllEventsCalendarEventWindowLocation extends React.PureComponent<{
  event: AllEventsCalendarEventWindowLocation_event,
  shownColumns: ?$ReadOnlyArray<string>,
}> {
  handleJoinZoomMeeting = () => {
    if (this.props.event.zoomLocation) {
      window.open(this.props.event.zoomLocation.joinUrl, '_blank');
    }
  };

  handleJoinG2Webinar = () => {
    if (this.props.event.g2wLocation) {
      window.open(this.props.event.g2wLocation.registrationUrl, '_blank');
    }
  };

  render() {
    const { event, shownColumns } = this.props;
    const showVirtualLocation =
      this.props.shownColumns == null || this.props.shownColumns.includes('virtual_location');

    return (
      <>
        {event.primaryLocation && (shownColumns == null || shownColumns.includes('venue_name')) && (
          <LocationContainer>
            <StyledPhysicalLocationIcon />
            <LocationLabel>{stringifyLocation(event.primaryLocation)}</LocationLabel>
          </LocationContainer>
        )}
        {event.virtualLocation && showVirtualLocation && (
          <LocationContainer>
            <StyledVirtualLocationTypeIcon locationType="virtual" />
            <LocationLabel>
              {isValidWebsite(event.virtualLocation) ? (
                <StyledLink href={event.virtualLocation}>{event.virtualLocation}</StyledLink>
              ) : (
                event.virtualLocation
              )}
            </LocationLabel>
          </LocationContainer>
        )}
        {event.zoomLocation && showVirtualLocation && (
          <LocationContainer>
            <StyledVirtualLocationTypeIcon locationType="zoom" />
            <ZoomLocationLabel>
              Zoom {ZOOM_LOCATION_KINDS[event.zoomLocation.kind]}
            </ZoomLocationLabel>
            <StyledOutlineButton variant="blue" label="Join" onClick={this.handleJoinZoomMeeting} />
            <StyledCopyLink link={event.zoomLocation.joinUrl} hideLink />
          </LocationContainer>
        )}
        {event.g2wLocation && showVirtualLocation && (
          <LocationContainer>
            <StyledVirtualLocationTypeIcon locationType="g2w" />
            <ZoomLocationLabel>GoToWebinar</ZoomLocationLabel>
            <StyledOutlineButton label="Join" onClick={this.handleJoinG2Webinar} />
            <StyledCopyLink link={event.g2wLocation.registrationUrl} hideLink />
          </LocationContainer>
        )}
      </>
    );
  }
}

export default createFragmentContainer(
  AllEventsCalendarEventWindowLocation,
  graphql`
    fragment AllEventsCalendarEventWindowLocation_event on Event {
      virtualLocation
      primaryLocation {
        city
        name
        country
        state
      }
      zoomLocation {
        kind
        joinUrl
      }
      g2wLocation {
        registrationUrl
      }
    }
  `,
);
