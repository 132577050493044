/* @flow */
import * as React from 'react';

import ContactUsLink from './ContactUsLink';

const ExpiredTrialMessage = () => (
  <React.Fragment>
    Your free trial has expired, but you can <ContactUsLink /> to request a trial extension or
    upgrade today.
  </React.Fragment>
);

export default ExpiredTrialMessage;
