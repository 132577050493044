/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import currencies from 'config/currencies.json';

import getCurrencyRates, { type RateType } from 'utils/getCurrencyRates';
import formatCost from 'utils/number/formatCost';

import saveTeamAllocation from 'graph/mutations/expense/saveTeamAllocation';

import LockIcon from 'components/LockIcon';
import CostField from 'components/material/CostField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';

import { status } from '../BudgetPage/ExpensesTable/columns/ExpensePaymentStatusCell';
import AddTeamAction from '../components/TeamAllocation/AddTeamAction';
import { type TeamAllocationType } from '../components/TeamAllocation/TeamSelectField';

import type { ExpenseFinanceField_expense } from './__generated__/ExpenseFinanceField_expense.graphql';
import type { ExpenseFinanceField_org } from './__generated__/ExpenseFinanceField_org.graphql';

const Row = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const AllocationRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 25px 0 0;
  background-color: #f7f8f9;
`;

const StyledCostField = styled(CostField)`
  flex-shrink: 0;
  width: 116px;
  margin-right: 28px;
`;

const StyledSelectField = styled(SelectField)`
  flex-shrink: 0;
  width: 89px;
  margin-right: 28px;
`;

const AmountContainer = styled.div`
  position: relative;
`;

const CalculatedAmount = styled.div`
  position: absolute;
  bottom: -15px;
  right: 30px;
  font-size: 12px;
  color: #2dd69c;
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  top: 16px;
  right: 30px;
`;

const InfoTooltip = styled(Tooltip)`
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(74, 86, 101, 0.54);
`;

const StyledAddTeamAction = styled(AddTeamAction)`
  width: 250px;
`;

const SectionHeader = styled.div`
  margin: 12px 0 0 12px;
  font-size: 14px;
  font-weight: 500;
  color: #3e4859;
  line-height: 14px;
`;

const StyledTextField = styled(TextField)`
  flex-shrink: 0;
  width: 111px;
  margin-right: 28px;
`;

class ExpenseFinanceField extends React.Component<
  {
    onActualAmountChange: (amount: ?number) => void,
    onAmountErrorSet: (amount: ?number) => void,
    errors: $ReadOnlyArray<string>,
    org: ExpenseFinanceField_org,
    expense: ExpenseFinanceField_expense,
    amount: number,
    paid: number,
    disabled: boolean,
  },
  {
    currency: string,
    exchangedAmount: number,
    exchangeRate: number,
    rates: RateType,
  },
> {
  mounted: boolean = false;

  state = {
    currency: this.props.org.settings.currency || '',
    exchangedAmount: this.props.amount || 0,
    exchangeRate: 1,
    rates: {},
  };

  componentDidMount() {
    this.mounted = true;
    getCurrencyRates().then(data => {
      if (this.mounted && data.rates) {
        this.setState({ rates: data.rates });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { exchangeRate, currency } = this.state;
    const { org, amount } = this.props;
    if (amount !== null && amount !== prevProps.amount) {
      const exchangedAmount: number = Math.round(amount * exchangeRate);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        exchangedAmount: org.settings.currency !== currency ? exchangedAmount : amount,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleAmountBlur = (e: SyntheticEvent<HTMLInputElement>, amount: ?number) => {
    if (this.props.org.settings.currency === this.state.currency) {
      this.props.onActualAmountChange(amount);
    } else {
      this.props.onActualAmountChange(amount == null ? null : amount / this.state.exchangeRate);
    }
  };

  handleAmountChange = (e: SyntheticEvent<HTMLInputElement>, amount: ?number) => {
    this.props.onAmountErrorSet(amount);
    if (this.props.org.settings.currency !== this.state.currency) {
      this.props.onActualAmountChange(amount == null ? null : amount / this.state.exchangeRate);
    }
  };

  handleCurrencyChange = (currency: ?string) => {
    if (currency) {
      this.setState({ currency });
      this.handleCurrencyExchange(currency);
    }
  };

  handleCurrencyExchange = (currency: string) => {
    const { rates } = this.state;
    if (this.props.org.settings.currency !== currency && rates != null) {
      const exchangeRate = rates[currency] / rates[this.props.org.settings.currency];
      const exchangedAmount: number = Math.round(exchangeRate * this.props.amount);
      this.setState({ exchangedAmount, exchangeRate });
    } else {
      this.setState({ exchangedAmount: this.props.amount });
    }
  };

  handleSetTeamAllocations = (updatedTeamAllocations: $ReadOnlyArray<TeamAllocationType>) => {
    const teamAllocations = updatedTeamAllocations.map(team => ({
      percent: team.percent,
      id: team.id,
    }));

    const teamAllocationsProps = this.props.expense.expenseTeamAllocations.edges.map(team => ({
      percent: team.node.percent,
      id: team.node.team.id,
    }));

    if (!isEqual(sortBy(teamAllocations, 'id'), sortBy(teamAllocationsProps, 'id'))) {
      saveTeamAllocation({ expenseId: this.props.expense.id, teamAllocations });
    }
  };

  render() {
    const { rates, exchangedAmount, currency } = this.state;
    const { errors, disabled, org, paid, amount } = this.props;
    const teamAllocations = this.props.expense.expenseTeamAllocations.edges.map(team => ({
      percent: team.node.percent,
      id: team.node.team.id,
      name: team.node.team.name,
      viewerCanUpdate: team.node.team.viewerCanUpdate,
    }));
    const expenseStatus = status(paid / amount);
    return (
      <React.Fragment>
        <Row>
          <AmountContainer>
            <StyledCostField
              label="Amount"
              defaultValue={exchangedAmount}
              onBlur={this.handleAmountBlur}
              onChange={this.handleAmountChange}
              error={errors.includes('cost') ? 'Required' : ''}
              currency={currency}
              disabled={disabled}
              required
            />
            {currency !== org.settings.currency && (
              <CalculatedAmount>
                <Tooltip
                  label={`${formatCost(100, org.settings.currency)} = ${formatCost(
                    Math.round(this.state.exchangeRate * 100),
                    currency,
                  )}\nWorkspace Currency: ${org.settings.currency}`}
                  placement="bottom"
                >
                  {formatCost(amount, org.settings.currency)}
                </Tooltip>
              </CalculatedAmount>
            )}
            {disabled && <StyledLockIcon label="Adjust Amount in Payments section below" />}
          </AmountContainer>
          <StyledSelectField
            value={currency}
            onChange={this.handleCurrencyChange}
            options={currencies.map(item => ({
              value: item.code,
              label: `${item.code} (${item.symbol})`,
            }))}
            searchable
            disabled={Object.keys(rates).length === 0}
          />
          <AmountContainer>
            <StyledTextField label="Status" value={expenseStatus} name="title" disabled />
            <InfoTooltip label="See payments below">
              <i className="fa fa-question-circle-o" />
            </InfoTooltip>
          </AmountContainer>
        </Row>
        <AllocationRow>
          <SectionHeader>Split by Team</SectionHeader>
          <StyledAddTeamAction
            onSetTeamAllocations={this.handleSetTeamAllocations}
            teamAllocations={teamAllocations}
          />
        </AllocationRow>
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(ExpenseFinanceField, {
  expense: graphql`
    fragment ExpenseFinanceField_expense on Expense {
      id
      paidAmount
      actualAmount
      expenseTeamAllocations {
        edges {
          node {
            percent
            team {
              id
              name
              viewerCanUpdate
            }
          }
        }
      }
    }
  `,
  org: graphql`
    fragment ExpenseFinanceField_org on Org {
      settings {
        currency
      }
    }
  `,
});
