/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import updateEventContact from 'graph/mutations/event_contact/updateEventContact';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import type { ContactAttendanceStatus_contact } from './__generated__/ContactAttendanceStatus_contact.graphql';
import type { ContactAttendanceStatus_event } from './__generated__/ContactAttendanceStatus_event.graphql';
import type { ContactAttendanceStatus_eventContact } from './__generated__/ContactAttendanceStatus_eventContact.graphql';

class ContactAttendanceStatus extends React.Component<{
  eventContact: ContactAttendanceStatus_eventContact,
  contact: ContactAttendanceStatus_contact,
  event: ContactAttendanceStatus_event,
  updateColumnWidth: () => void,
  fieldSettings?: FieldType,
}> {
  updateAttendanceStatus = attendanceStatusId => {
    updateEventContact({
      eventId: this.props.event.id,
      contactId: this.props.contact.id,
      attendanceStatusId,
    })
      .then(() => {
        this.props.updateColumnWidth();
      })
      .catch(showModernMutationError);
  };

  getError = (): ?string => {
    const { fieldSettings, eventContact } = this.props;
    if (fieldSettings && fieldSettings.required && eventContact.attendanceStatus == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { fieldSettings, eventContact, updateColumnWidth } = this.props;
    return (
      <InlineSelectField
        options={fieldSettings ? fieldSettings.options : []}
        value={eventContact.attendanceStatus && eventContact.attendanceStatus.id}
        placeholder="Select Attendance Status"
        onChange={this.updateAttendanceStatus}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
      />
    );
  }
}

export default createFragmentContainer(ContactAttendanceStatus, {
  eventContact: graphql`
    fragment ContactAttendanceStatus_eventContact on EventContactEdge {
      attendanceStatus {
        id
        name
      }
    }
  `,
  contact: graphql`
    fragment ContactAttendanceStatus_contact on Contact {
      id
    }
  `,
  event: graphql`
    fragment ContactAttendanceStatus_event on Event {
      id
    }
  `,
});
