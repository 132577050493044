/**
 * @flow
 * @relayHash 2241a1f91f11c2ec3912b21b524a6cd8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InviteToOrgContents_me$ref = any;
type InviteToOrgContents_org$ref = any;
export type UsersImportWindowConfigQueryVariables = {||};
export type UsersImportWindowConfigQueryResponse = {|
  +me: {|
    +$fragmentRefs: InviteToOrgContents_me$ref
  |},
  +org: {|
    +$fragmentRefs: InviteToOrgContents_org$ref
  |},
|};
export type UsersImportWindowConfigQuery = {|
  variables: UsersImportWindowConfigQueryVariables,
  response: UsersImportWindowConfigQueryResponse,
|};
*/


/*
query UsersImportWindowConfigQuery {
  me {
    ...InviteToOrgContents_me
    id
  }
  org {
    ...InviteToOrgContents_org
    id
  }
}

fragment InviteToOrgContents_me on User {
  admin
  memberships {
    edges {
      node {
        id
        name
      }
    }
  }
}

fragment InviteToOrgContents_org on Org {
  viewerCanManageTeamMembers
  viewerCanManageStaff
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UsersImportWindowConfigQuery",
  "id": null,
  "text": "query UsersImportWindowConfigQuery {\n  me {\n    ...InviteToOrgContents_me\n    id\n  }\n  org {\n    ...InviteToOrgContents_org\n    id\n  }\n}\n\nfragment InviteToOrgContents_me on User {\n  admin\n  memberships {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment InviteToOrgContents_org on Org {\n  viewerCanManageTeamMembers\n  viewerCanManageStaff\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UsersImportWindowConfigQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "InviteToOrgContents_me",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "InviteToOrgContents_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UsersImportWindowConfigQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "admin",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "memberships",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamMembershipConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "MembershipTeamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageTeamMembers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageStaff",
            "args": null,
            "storageKey": null
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e09d6121014490c8f0ea26ea25f4526';
module.exports = node;
