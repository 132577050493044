import { parse } from 'qs';
import storage from './storage';
import templates from 'config/event_templates.json';

/**
 * Sets a template_id in local storage on page load.
 */
export default function setDefaultTemplate() {
  const query = parse(window.location.search.slice(1));
  const template_name = query.template;
  if (template_name) {
    const template = templates.find(x => x[1] === template_name);

    storage.set('template_id', template && template[0]);
  }
}
