/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactsTablePagination_totalCountEvent$ref: FragmentReference;
export type ContactsTablePagination_totalCountEvent = {|
  +contactsCount: {|
    +totalCount: number
  |},
  +$refType: ContactsTablePagination_totalCountEvent$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactsTablePagination_totalCountEvent",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filters",
      "type": "ContactFilters"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "contactsCount",
      "name": "eventContacts",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters",
          "type": "ContactFilters"
        }
      ],
      "concreteType": "EventContactConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e20fdcb7d9b1866d63b40357557f3b03';
module.exports = node;
