/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactSyncStatus_contact$ref: FragmentReference;
export type ContactSyncStatus_contact = {|
  +syncStatus: {|
    +state: SyncState,
    +errorMessage: string,
  |},
  +$refType: ContactSyncStatus_contact$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactSyncStatus_contact",
  "type": "Contact",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "syncStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "errorMessage",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8003965d7c08f00646d973a1b97c32d0';
module.exports = node;
