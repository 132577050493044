/* @flow */
import React from 'react';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';

import AllEventsMapMarkerInfoBox from './AllEventsMapMarkerInfoBox';

import type { AllEventsMapMarkersInfo_event } from './__generated__/AllEventsMapMarkersInfoQuery.graphql';

const MarkersContainer = styled.div`
  border: solid 1px #e6e6e6;
  border-radius: 2px;
  width: 275px;
  overflow: hidden;
  & > div {
    border-radius: 0px;
    border-width: 0px;
    :not(:last-child) {
      border-bottom: solid 1px #e6e6e6;
    }
  }
`;

export default class AllEventsMapMarkersInfoBox extends React.PureComponent<{
  events: AllEventsMapMarkersInfo_event,
  linkEvent: boolean,
  match: Match,
  location: Location,
}> {
  render() {
    const { linkEvent, match, location, events } = this.props;
    return (
      <MarkersContainer>
        {events.map(event => {
          return (
            <AllEventsMapMarkerInfoBox
              key={event.id}
              event={event}
              linkEvent={linkEvent}
              match={match}
              location={location}
            />
          );
        })}
      </MarkersContainer>
    );
  }
}
