/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import NumberField from './components/NumberField';

import type { SalesforceOpportunitiesNumber_event } from './__generated__/SalesforceOpportunitiesNumber_event.graphql';

const SalesforceOpportunitiesNumber = (props: { event: SalesforceOpportunitiesNumber_event }) => {
  const salesforceCampaign = props.event.salesforceCampaign;
  return salesforceCampaign ? (
    <NumberField value={salesforceCampaign.numberOfOpportunities} />
  ) : null;
};

export default createFragmentContainer(
  SalesforceOpportunitiesNumber,
  graphql`
    fragment SalesforceOpportunitiesNumber_event on Event {
      salesforceCampaign {
        numberOfOpportunities
      }
    }
  `,
);
