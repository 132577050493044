/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type InviteToOrgContents_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type InviteWindowContents_org$ref: FragmentReference;
export type InviteWindowContents_org = {|
  +id: string,
  +viewerCanManageTeamMembers: boolean,
  +viewerCanManageUsers: boolean,
  +subscription: {|
    +fatmLimit: ?number,
    +fatmCount: number,
  |},
  +teamMembers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +email: string
      |}
    |}>
  |},
  +$fragmentRefs: InviteToOrgContents_org$ref,
  +$refType: InviteWindowContents_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "InviteWindowContents_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanManageTeamMembers",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanManageUsers",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subscription",
      "storageKey": null,
      "args": null,
      "concreteType": "Subscription",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fatmLimit",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fatmCount",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "teamMembers",
      "name": "users",
      "storageKey": "users(hasTeamAccess:true)",
      "args": [
        {
          "kind": "Literal",
          "name": "hasTeamAccess",
          "value": true,
          "type": "Boolean"
        }
      ],
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "email",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "InviteToOrgContents_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ae75464726b69c15b61dfec8ffa88a2e';
module.exports = node;
