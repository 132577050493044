/* @flow */
import React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import reorderCustomFields from 'graph/mutations/customFields/reorderCustomFields';
import createCustomFieldSection from 'graph/mutations/customFieldSections/createCustomFieldSection';
import reorderCustomFieldSections from 'graph/mutations/customFieldSections/reorderCustomFieldSections';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/budget/Button';

import CustomizableSection from './CustomizableSection';
import DraggableSection from './DraggableSection';
import {
  type CustomizableType,
  type Field,
  type FilterType,
  type Section,
  type SectionCustomizableType,
} from './types';

const Container = styled.div`
  max-width: 750px;
  margin-left: 30px;
  padding-bottom: 80px;
`;

const StyledButton = styled(Button)`
  margin: 15px 0 0 20px;
`;

export default class CustomizableSectionsList extends React.Component<
  {
    sections: $ReadOnlyArray<Section>,
    orgId: string,
    filters: FilterType,
    fields: $ReadOnlyArray<Field>,
    customizableType: CustomizableType,
    sectionCustomizableType: SectionCustomizableType,
  },
  {
    adding: boolean,
    draggingField: ?Field,
    orderOverride: ?$ReadOnlyArray<Section>,
    changedFields: ?$ReadOnlyArray<Field>,
    dragging: boolean,
  },
> {
  state = {
    adding: false,
    draggingField: null,
    changedFields: null,
    dragging: false,
    orderOverride: null,
  };

  getFields = (): $ReadOnlyArray<Field> => {
    return this.state.changedFields
      ? [
          ...this.state.changedFields,
          ...this.props.fields.filter(field => field.isDefault === true),
        ]
      : this.props.fields;
  };

  getSortedSections = (): Array<Section> => {
    return sortBy(this.state.orderOverride || this.props.sections, 'order');
  };

  handleAllStart = () => {
    if (!this.state.dragging) {
      this.setState({ dragging: true });
    }
  };

  handleMoveSection = (sourceOrder: number, targetOrder: number) => {
    const direction = sourceOrder > targetOrder ? 0 : 1;
    const sortedSection = this.getSortedSections();
    const source = sortedSection.find(field => field.order === sourceOrder);

    if (!source) {
      return;
    }

    const splitIndex = targetOrder + direction;
    const before = sortedSection
      .slice(0, splitIndex)
      .filter(section => section.order !== sourceOrder);
    const after = sortedSection.slice(splitIndex).filter(section => section.order !== sourceOrder);
    this.setState({
      orderOverride: [...before, source, ...after].map((section, index) => ({
        ...section,
        order: index,
      })),
    });
  };

  handleSectionMoveEnd = () => {
    if (this.state.orderOverride) {
      reorderCustomFieldSections(
        this.props.sectionCustomizableType,
        this.state.orderOverride.map(({ id, order }) => ({ id, order })),
      ).catch(showModernMutationError);
    }
    this.setState({ orderOverride: null, dragging: false });
  };

  setDraggingField = (draggingField: Field) => {
    if (this.state.draggingField == null) {
      this.setState({ draggingField });
    }
  };

  handleMoveFieldToSection = (sourceField: Field, targetSectionId: string) => {
    const sourceFieldSection = this.getSortedSections().find(
      section => sourceField.section != null && section.id === sourceField.section.id,
    );
    const targetFieldSection = this.getSortedSections().find(
      section => section.id === targetSectionId,
    );

    if (sourceFieldSection == null || targetFieldSection == null) {
      return;
    }

    this.setDraggingField(sourceField);
    const allFields = this.getFields();

    const source = allFields.find(field => field.id === sourceField.id);
    if (source == null) {
      return;
    }

    // -1 and 1000000 numbers used for order to send current dragging field to top or bottom
    // of the current host section. After changedSource is situated in host section fields array
    // map function gives new order numbers to fields in that section.
    const changedSource = {
      ...source,
      order: sourceFieldSection.order < targetFieldSection.order ? -1 : 1000000,
      section: {
        id: targetSectionId,
      },
    };

    const changeState = [
      // Section fields that were not part of drag and drop process
      ...allFields.filter(
        field =>
          field.section &&
          field.section.id !== sourceFieldSection.id &&
          field.section.id !== targetFieldSection.id,
      ),
      // Section fields from which the dragging field was moved
      ...allFields
        .filter(
          field =>
            field.section &&
            field.section.id === sourceFieldSection.id &&
            field.id !== sourceField.id,
        )
        .map((field, index) => ({
          ...field,
          order: index,
        })),
      // Destination section fields, where the dragging field was dropped
      ...sortBy(
        [
          ...allFields.filter(field => field.section && field.section.id === targetFieldSection.id),
          changedSource,
        ],
        'order',
      ).map((field, index) => ({
        ...field,
        order: index,
      })),
    ];

    this.setState({
      changedFields: changeState,
    });
  };

  handleMoveField = (sourceField: Field, targetField: Field) => {
    const sourceFieldSectionId = sourceField.section ? sourceField.section.id : null;
    const targetFieldSectionId = targetField.section ? targetField.section.id : null;

    if (sourceFieldSectionId == null || targetFieldSectionId == null) {
      return;
    }
    if (sourceFieldSectionId !== targetFieldSectionId) {
      this.handleMoveFieldToSection(sourceField, targetFieldSectionId);
      return;
    }
    this.setDraggingField(sourceField);

    const allFields = this.getFields();
    const source = allFields.find(field => field.id === sourceField.id);
    if (sourceField.section != null && source != null) {
      // Takes all fields that are in destination section
      const sectionSortedFields = sortBy(
        [
          ...allFields.filter(
            field =>
              field.section != null &&
              field.section.id === targetFieldSectionId &&
              field.id !== sourceField.id,
          ),
          {
            ...source,
            // -/+0.5 is used for better situating the dragging field near target field.
            // After this process new order will be given to all fields in the destination section
            order:
              sourceField.order > targetField.order
                ? targetField.order - 0.5
                : targetField.order + 0.5,
          },
        ],
        'order',
      );

      this.setState({
        changedFields: [
          ...allFields.filter(
            field => field.section != null && field.section.id !== targetFieldSectionId,
          ),
          // New orders are given to fields in destination folder
          ...sectionSortedFields.map((field, index) => ({
            ...field,
            order: index,
          })),
        ],
      });
    }
  };

  handleMoveFieldEnd = () => {
    if (this.state.changedFields) {
      reorderCustomFields(
        'EVENT',
        this.state.changedFields.map(({ id, order, section }) => ({
          id,
          order,
          sectionId: section ? section.id : null,
        })),
      ).catch(showModernMutationError);
    }
    this.setState({ changedFields: null, draggingField: null });
  };

  handleAddSectionClick = () => {
    this.setState({ adding: true });
  };

  handleAddSection = (sectionName: string) => {
    const sectionCustomizableType = this.props.sectionCustomizableType;

    if (sectionCustomizableType == null || sectionName === '') {
      this.setState({ adding: false });
      return;
    }

    createCustomFieldSection(
      this.props.orgId,
      {
        customizableType: sectionCustomizableType,
        name: sectionName,
      },
      { sectionCustomizableType: [sectionCustomizableType] },
    )
      .then(() => {
        this.setState({ adding: false });
      })
      .catch(showModernMutationError);
  };

  generateEmptySection = () => ({ id: '0', name: '', order: -1 });

  renderSection = (section: Section) => {
    const { orgId, filters, customizableType, sectionCustomizableType } = this.props;

    return (
      <CustomizableSection
        key={section.id}
        orgId={orgId}
        customizableType={customizableType}
        sectionCustomizableType={sectionCustomizableType}
        filters={filters}
        fields={this.getFields().filter(
          field =>
            (field.section && field.section.id === section.id) ||
            (section.order === 0 && field.isDefault === true),
        )}
        section={section}
        draggingField={this.state.draggingField}
        onAddSection={this.handleAddSection}
        onMoveEnd={this.handleMoveFieldEnd}
        onMoveField={this.handleMoveField}
        onMoveFieldToSection={this.handleMoveFieldToSection}
        dragging={this.state.dragging}
      />
    );
  };

  render() {
    const sortedSections = this.getSortedSections();

    return (
      <Container>
        {sortedSections.map(section =>
          section.order === 0 ? (
            this.renderSection(section)
          ) : (
            <DraggableSection
              key={section.id}
              section={{ id: section.id, order: section.order }}
              onMove={this.handleMoveSection}
              onMoveEnd={this.handleSectionMoveEnd}
              onAllStart={this.handleAllStart}
            >
              {this.renderSection(section)}
            </DraggableSection>
          ),
        )}
        {this.state.adding ? (
          this.renderSection(this.generateEmptySection())
        ) : (
          <StyledButton onClick={this.handleAddSectionClick}>Create New Section</StyledButton>
        )}
      </Container>
    );
  }
}
