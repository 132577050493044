/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import {
  type removeExpenseMutationResponse,
  type removeExpenseMutationVariables,
} from './__generated__/removeExpenseMutation.graphql';

type OptimisticResponse = {|
  +removeExpense: $Shape<{
    ...$NonMaybeType<$PropertyType<removeExpenseMutationResponse, 'removeExpense'>>,
  }>,
|};

export type MutationInput = $PropertyType<removeExpenseMutationVariables, 'input'> & {
  eventId: string,
  vendorId?: string,
  taskId?: string,
};

const mutation = graphql`
  mutation removeExpenseMutation($input: RemoveExpenseInput!) {
    removeExpense(input: $input) {
      removedExpenseId
      event {
        totalBudgetedAmount
        totalActualAmount
        totalPaidAmount

        budgetCategories {
          edges {
            ...CategoriesTable_categoryEdges
          }
        }

        expenses {
          totalCount
        }

        payments {
          totalCount
        }
      }
    }
  }
`;

export default function removeExpense(
  input: MutationInput,
): Promise<removeExpenseMutationResponse> {
  const optimisticResponse: OptimisticResponse = {
    removeExpense: {
      removedExpenseId: input.expenseId,
    },
  };

  return commitModernMutation({
    mutation,
    variables: {
      input: {
        expenseId: input.expenseId,
      },
    },
    optimisticResponse,
    updater: mergeUpdaters(
      ...[
        removeRecordFromConnection({
          deletedIdField: 'removedExpenseId',
          parentId: input.eventId,
          connections: [{ key: 'ExpensesTable_expenses' }, { field: 'expenses' }],
        }),
        ...(input.taskId
          ? [
              removeRecordFromConnection({
                deletedIdField: 'removedExpenseId',
                parentId: input.taskId,
                connections: [{ field: 'expenses' }],
              }),
            ]
          : []),
        ...(input.vendorId
          ? [
              removeRecordFromConnection({
                deletedIdField: 'removedExpenseId',
                parentId: input.vendorId,
                connections: [{ key: 'VendorExpenses_expenses' }],
              }),
            ]
          : []),
        removeRecordFromStore({ deletedIdField: 'removedExpenseId' }),
      ],
    ),
  });
}
