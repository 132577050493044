/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateSalesforceOpportunityStatusMutationResponse,
  updateSalesforceOpportunityStatusMutationVariables,
} from './__generated__/updateSalesforceOpportunityStatusMutation.graphql';

type InputType = $PropertyType<updateSalesforceOpportunityStatusMutationVariables, 'input'>;

const mutation = graphql`
  mutation updateSalesforceOpportunityStatusMutation(
    $input: UpdateSalesforceOpportunityStatusInput!
  ) {
    updateSalesforceOpportunityStatus(input: $input) {
      salesforceOpportunityStatusEdge {
        node {
          id
          enabled
        }
      }
    }
  }
`;

export default function updateSalesforceOpportunityStatus(
  input: InputType,
): Promise<updateSalesforceOpportunityStatusMutationResponse> {
  const variables: updateSalesforceOpportunityStatusMutationVariables = {
    input,
  };
  const optimisticResponse: updateSalesforceOpportunityStatusMutationResponse = {
    updateSalesforceOpportunityStatus: {
      salesforceOpportunityStatusEdge: {
        node: {
          id: input.id,
          enabled: input.enabled,
        },
      },
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
