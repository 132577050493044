/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { InputVariableFilters } from 'components/Companies/companiesTableColumnSettings';

import { type CreateContactFromWindow } from '../contact/createContact';

import type {
  createCompanyMutationResponse,
  createCompanyMutationVariables,
} from './__generated__/createCompanyMutation.graphql';

export type CreateCompanyFromWindow = 'event companies' | 'org companies';
export type ResponseCompany = $PropertyType<
  $PropertyType<
    $NonMaybeType<$PropertyType<createCompanyMutationResponse, 'createCompany'>>,
    'companyEdge',
  >,
  'node',
>;

type Input = $Diff<
  $PropertyType<createCompanyMutationVariables, 'input'>,
  { fromWindow: ?string },
> & { fromWindow?: ?CreateCompanyFromWindow | CreateContactFromWindow };

const mutation = graphql`
  mutation createCompanyMutation(
    $input: CreateCompanyInput!
    $filters: CompanyFilters
    $updateOrgTotalCount: Boolean!
  ) {
    createCompany(input: $input) {
      org {
        companies {
          totalCount
        }
      }
      ... @include(if: $updateOrgTotalCount) {
        org {
          ...OrgCompaniesTablePagination_totalCountOrg
        }
      }
      eventEdges {
        __typename
        node {
          id
          ...EventCompaniesTablePagination_totalCountEvent
        }
      }
      companyEdge {
        __typename
        node {
          id
          name
          salesforceId
          ...CompanyName_company
          ...CompanyPhone_company
          ...CompanyWebsite_company
          ...CompanyTwitter_company
          ...CompanyLinkedin_company
          ...CompanyUpdater_company
          ...CompanyUpdatedAt_company
          ...CompanyCreator_company
          ...CompanyContactsCount_company
          ...CompanyCreatedAt_company
          ...CompanySalesforceId_company
          ...CompanyStreet_company
          ...CompanyCity_company
          ...CompanyState_company
          ...CompanyZip_company
          ...CompanyCountry_company
          ...CompanyDescription_company
          ...CustomizableText_customizable
          ...CustomizableTextarea_customizable
          ...CustomizableLink_customizable
          ...CustomizableDate_customizable
          ...CustomizableBoolean_customizable
          ...CustomizableNumber_customizable
          ...CustomizableCurrency_customizable
          ...CustomizableSelect_customizable
          ...CustomizableMultiselect_customizable
          ...CustomizableUserSelect_customizable
          ...CustomizableUserMultiselect_customizable
        }
      }
    }
  }
`;

export default function createCompany(
  orgId: string,
  input: Input,
  filtersVariables?: ?InputVariableFilters,
): Promise<ResponseCompany> {
  return commitModernMutation({
    mutation,
    variables: {
      input,
      filters: filtersVariables,
      updateOrgTotalCount: filtersVariables != null,
    },
    updater: mergeUpdaters(
      addRecordToConnection({
        parentId: orgId,
        edgeName: 'companyEdge',
        connections: [{ key: 'OrgCompaniesTablePagination_companies' }],
      }),
      ...(input.eventIds || []).map(eventId =>
        addRecordToConnection({
          parentId: eventId,
          edgeName: 'companyEdge',
          connections: [{ key: 'EventCompaniesTablePagination_companies' }, { field: 'companies' }],
        }),
      ),
    ),
  }).then((response: createCompanyMutationResponse) => {
    if (!response.createCompany) throw Error('Invalid response');

    return response.createCompany.companyEdge.node;
  });
}
