/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RequestSubmissionFormName_requestSubmission$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestsItemFormName_eventRequestSubmission$ref: FragmentReference;
export type EventRequestsItemFormName_eventRequestSubmission = {|
  +form: {|
    +id: string,
    +name: string,
  |},
  +$fragmentRefs: RequestSubmissionFormName_requestSubmission$ref,
  +$refType: EventRequestsItemFormName_eventRequestSubmission$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestsItemFormName_eventRequestSubmission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "RequestSubmissionFormName_requestSubmission",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "form",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestForm",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ab8fcd5d7e3c59942890621e8464d407';
module.exports = node;
