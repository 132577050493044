/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';

import fullNameOfUser from 'utils/fullNameOfUser';

import AutocompleteInput from 'components/material/AutocompleteInput';

import type { Participant } from './index';
import UserSearchRow from './UserSearchRow';

const StyledAutocompleteInput = styled(AutocompleteInput)`
  flex: 1 1 auto;
`;

export default class UserSearchContent extends React.PureComponent<{
  onSelect: (user: ?Participant) => void,
  label?: string,
  className?: string,
  fillValueOnSelect?: boolean,
  defaultOption?: ?Participant,
  disabled?: boolean,
  clearable?: boolean,
  placeholder: string,
  options: $ReadOnlyArray<Participant>,
  onFilter: (query: string) => void,
  footerContent?: ?React.Node,
}> {
  handleFilter = throttle((str: string) => {
    this.props.onFilter(str);
  }, 800);

  handleSelect = (user: ?Participant) => {
    this.props.onSelect(user);
  };

  renderOption = (user: Participant) => <UserSearchRow user={user} />;

  render() {
    const {
      defaultOption,
      placeholder,
      className,
      label,
      options,
      onFilter,
      fillValueOnSelect,
      footerContent,
      disabled,
      clearable,
    } = this.props;
    return (
      <StyledAutocompleteInput
        defaultQuery={defaultOption ? fullNameOfUser(defaultOption) : ''}
        placeholder={placeholder}
        className={className}
        label={label}
        options={options}
        onFilterOptions={onFilter}
        onSelect={this.handleSelect}
        resetOnBlur={fillValueOnSelect}
        renderOption={this.renderOption}
        renderOptionString={fillValueOnSelect ? fullNameOfUser : null}
        footerContent={footerContent}
        disabled={disabled}
        clearable={clearable}
      />
    );
  }
}
