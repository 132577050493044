import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import formatTimeRange from 'utils/date/formatTimeRange';
import fullNameOfUser from 'utils/fullNameOfUser';

class SharedScheduleListRow extends React.Component {
  static propTypes = {
    scheduleItem: PropTypes.shape({
      all_day: PropTypes.bool.isRequired,
      start_time: PropTypes.string.isRequired,
      end_time: PropTypes.string,
      title: PropTypes.string.isRequired,
      location: PropTypes.string,
      description: PropTypes.string,
      scheduleParticipants: PropTypes.any,
    }).isRequired,
    time_zone: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  };

  render() {
    const { color, time_zone, scheduleItem } = this.props;
    const participants = scheduleItem.scheduleParticipants.edges.map(x => x.node.user);

    return (
      <tr>
        <td width="15%">
          <div>
            <div style={{ color }}>
              {scheduleItem.all_day ? (
                <span>All Day</span>
              ) : (
                <span>
                  {formatTimeRange(
                    scheduleItem.start_time && moment.tz(scheduleItem.start_time, time_zone),
                    scheduleItem.end_time && moment.tz(scheduleItem.end_time, time_zone),
                  )}
                </span>
              )}
            </div>
          </div>
        </td>
        <td width="48%">
          <div>
            <strong>{scheduleItem.title}</strong>
          </div>
          <div>{scheduleItem.description}</div>
        </td>
        <td width="17%">
          {scheduleItem.location && (
            <div>
              {scheduleItem.location.split('\n').map((str, i) => (
                <span key={i}>
                  {str}
                  <br />
                </span>
              ))}
            </div>
          )}
        </td>
        <td width="21%">
          {participants.length > 0 && (
            <div>
              {participants.map(participant => (
                <div style={{ whiteSpace: 'nowrap' }} key={participant.id}>
                  {fullNameOfUser(participant)}
                </div>
              ))}
            </div>
          )}
        </td>
      </tr>
    );
  }
}

export default createFragmentContainer(
  SharedScheduleListRow,
  graphql`
    fragment SharedScheduleListRow_scheduleItem on ScheduleItem {
      id
      title
      all_day
      start_time
      end_time
      description
      location
      scheduleParticipants {
        edges {
          node {
            user {
              id
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  `,
);
