/**
 * @flow
 * @relayHash 437f611e99c6fc2d5b21a773dbf90cc4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type G2WLocationPicker_integrable$ref = any;
export type G2WLocationPickerRefetchQueryVariables = {|
  integrableId: string
|};
export type G2WLocationPickerRefetchQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: G2WLocationPicker_integrable$ref
  |}
|};
export type G2WLocationPickerRefetchQuery = {|
  variables: G2WLocationPickerRefetchQueryVariables,
  response: G2WLocationPickerRefetchQueryResponse,
|};
*/


/*
query G2WLocationPickerRefetchQuery(
  $integrableId: ID!
) {
  node(id: $integrableId) {
    __typename
    ...G2WLocationPicker_integrable
    id
  }
}

fragment G2WLocationPicker_integrable on IntegrableInterface {
  id
  viewerCanConfigureIntegrations
  g2wSolutionInstances: traySolutionInstances(solutionName: G2W) {
    totalCount
  }
  g2wUsers {
    edges {
      node {
        id
        g2wKey
        firstName
        lastName
        email
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "integrableId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "integrableId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "G2WLocationPickerRefetchQuery",
  "id": null,
  "text": "query G2WLocationPickerRefetchQuery(\n  $integrableId: ID!\n) {\n  node(id: $integrableId) {\n    __typename\n    ...G2WLocationPicker_integrable\n    id\n  }\n}\n\nfragment G2WLocationPicker_integrable on IntegrableInterface {\n  id\n  viewerCanConfigureIntegrations\n  g2wSolutionInstances: traySolutionInstances(solutionName: G2W) {\n    totalCount\n  }\n  g2wUsers {\n    edges {\n      node {\n        id\n        g2wKey\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "G2WLocationPickerRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "G2WLocationPicker_integrable",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "G2WLocationPickerRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanConfigureIntegrations",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "g2wSolutionInstances",
            "name": "traySolutionInstances",
            "storageKey": "traySolutionInstances(solutionName:\"G2W\")",
            "args": [
              {
                "kind": "Literal",
                "name": "solutionName",
                "value": "G2W",
                "type": "SolutionName"
              }
            ],
            "concreteType": "TraySolutionInstanceRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "g2wUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "G2WUserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "G2WUserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "G2WUser",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "g2wKey",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ac18c08d86077adae043bfbeb06c346';
module.exports = node;
