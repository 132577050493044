/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import type { Participant } from './index';
import UserSearchContent from './UserSearchContent';

import type { OrgReviewerSearchQuery_response } from './__generated__/OrgReviewerSearchQuery.graphql';

const query = graphql`
  query OrgReviewerSearchQuery($query: String!, $hasTeamAccess: Boolean, $skipUserIds: [ID!]) {
    org {
      users(first: 3, query: $query, hasTeamAccess: $hasTeamAccess, skipUserIds: $skipUserIds) {
        edges {
          node {
            id
            firstName
            lastName
            avatar
            email
            ...UserSearchRow_user
          }
        }
      }
    }
  }
`;

export default class OrgReviewerSearch extends React.PureComponent<
  {
    onSelect: (user: Participant) => void,
    selecetedUserIds: $ReadOnlyArray<string>,
    label?: string,
    className?: string,
    fillValueOnSelect?: boolean,
    defaultOption?: ?Participant,
    disabled?: boolean,
    clearable?: boolean,
    hasTeamAccess?: boolean,
  },
  { query: string },
> {
  state = { query: '' };

  cachedUsers: ?Array<Participant>;

  handleFilter = (str: string) => {
    this.setState({ query: str });
  };

  handleSelect = (user: ?Participant) => {
    if (user == null) return;
    this.props.onSelect(user);
  };

  renderInput = (response?: OrgReviewerSearchQuery_response) => {
    this.cachedUsers = response
      ? response.org.users.edges.map(({ node }) => node)
      : this.cachedUsers;
    const { onSelect, ...propsToForward } = this.props;

    return (
      <UserSearchContent
        {...propsToForward}
        onSelect={this.handleSelect}
        placeholder="Search Members"
        options={this.cachedUsers || []}
        onFilter={this.handleFilter}
      />
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          query: this.state.query,
          hasTeamAccess: !!this.props.hasTeamAccess,
          skipUserIds: this.props.selecetedUserIds,
        }}
        renderSuccess={this.renderInput}
        renderLoading={this.renderInput}
      />
    );
  }
}
