/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { stringifyLocation } from 'utils/Location';

import Detail from '../Detail';
import PhysicalLocationOverlay from './PhysicalLocationOverlay';

import { type PhysicalLocation_event } from './__generated__/PhysicalLocation_event.graphql';

const StyledDetail = styled(Detail)`
  max-width: 300px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

class PhysicalLocation extends React.Component<
  {
    event: PhysicalLocation_event,
  },
  {
    showOverlay: boolean,
  },
> {
  state = { showOverlay: false };

  containerRef = React.createRef();

  handleShowOverlay = () => {
    if (this.props.event.viewerCanUpdate) {
      this.setState({ showOverlay: true });
    }
  };

  handleHideOverlay = () => {
    this.setState({ showOverlay: false });
  };

  render() {
    const { event } = this.props;
    const location = event.primaryLocation;
    const locationText = stringifyLocation(location);

    if (!event.viewerCanUpdate && !event.primaryLocation) {
      return null;
    }

    return (
      <div className="event-location-wrapper" ref={this.containerRef}>
        <StyledDetail
          text={locationText}
          placeholder="Add address"
          isLocation
          onClick={this.handleShowOverlay}
          icon="map-marker"
        />

        {this.state.showOverlay && (
          <PhysicalLocationOverlay
            event={event}
            show={this.state.showOverlay}
            target={this.containerRef.current}
            container={this.containerRef.current}
            onHide={this.handleHideOverlay}
          />
        )}
      </div>
    );
  }
}

export default createFragmentContainer(
  PhysicalLocation,
  graphql`
    fragment PhysicalLocation_event on Event {
      ...PhysicalLocationOverlay_event
      viewerCanUpdate
      virtualLocation
      primaryLocation {
        id
        city
        name
        country
        state
      }
      zoomLocation {
        id
        kind
        zoomUrl
      }
      g2wLocation {
        id
        g2wUrl
      }
    }
  `,
);
