/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import { getSubscriptionPlanLabel } from 'config/subscriptionPlans';

import billingVisited from 'utils/analytics/events/billingVisited';

import Button from 'components/budget/Button';

import BillingPlanMessage from './BillingPlanMessage';
import BillingTrialMessage from './BillingTrialMessage';

import type { BillingContents_me } from './__generated__/BillingContents_me.graphql';
import type { BillingContents_org } from './__generated__/BillingContents_org.graphql';

const Container = styled.div`
  position: relative;
  max-width: 790px;
  margin-top: 30px;
  padding: 30px 40px;
  border: solid 1px #dfe1e5;
  border-radius: 2px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  color: #3e4859;
  ${props =>
    props.expired &&
    css`
      padding-left: 48px;
      border: solid 1px #f38183;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 100%;
        background-color: #f38183;
      }
    `};
  a {
    color: #39a8da;
    &:hover {
      color: #009dce;
      text-decoration: underline;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 0.78;
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  margin-bottom: 15px;
  padding: 8px 45px;
`;

const SuccessLinkContainer = styled.div`
  margin: 16px 0 18px;
`;

class BillingContents extends React.PureComponent<{
  org: BillingContents_org,
  me: BillingContents_me,
}> {
  componentDidMount() {
    const user = this.props.me;
    billingVisited({ user_id: user.id, user_email: user.email });
  }

  handleContactUsClick = () => {
    window.open('mailto:success@circa.co', '_blank');
  };

  render() {
    const subscription = this.props.org.subscription;
    const planName = getSubscriptionPlanLabel(subscription.plan);

    return (
      <Container expired={subscription.upgradeRequired}>
        <Title>Circa {planName}</Title>
        {subscription.plan === 'TRIAL' ? (
          <React.Fragment>
            <BillingTrialMessage subscription={subscription} />
            <StyledButton onClick={this.handleContactUsClick}>Contact us</StyledButton>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <BillingPlanMessage subscription={subscription} />
            <SuccessLinkContainer>
              <a href="mailto:success@circa.co" target="_blank" rel="noopener noreferrer">
                Need more Team Members or have a question?
              </a>
            </SuccessLinkContainer>
            <StyledButton onClick={this.handleContactUsClick}>Contact us</StyledButton>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  BillingContents,
  graphql`
    fragment BillingContents_org on Org {
      subscription {
        expiresAt
        upgradeRequired
        plan
        ...BillingTrialMessage_subscription
        ...BillingPlanMessage_subscription
      }
    }
    fragment BillingContents_me on User {
      id
      email
    }
  `,
);
