/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const Root = styled.div`
  max-width: 350px;
  margin: -3px 0;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props =>
    props.expanded &&
    css`
      white-space: pre-wrap;
      max-height: 70px;
      overflow: hidden;
    `};
`;

export default class InlineExpandableText extends React.PureComponent<
  { children: React.Node },
  { expanded: boolean },
> {
  state = { expanded: false };

  mount = false;

  timeoutId: ?TimeoutID = null;

  componentDidMount() {
    this.mount = true;
  }

  componentWillUnmount() {
    this.mount = false;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handleMouseEnter = () => {
    this.timeoutId = setTimeout(() => {
      if (this.mount) {
        this.setState({ expanded: true });
      }
    }, 200);
  };

  handleMouseLeave = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.setState(state => (state.expanded ? { expanded: false } : null));
  };

  render() {
    return (
      <Root
        expanded={this.state.expanded}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.props.children}
      </Root>
    );
  }
}
