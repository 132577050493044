/* @flow */
import { type Field } from 'components/FieldSettings/types';

const personDefaultFields: $ReadOnlyArray<Field> = [
  {
    id: 'first_name',
    kind: 'TEXT',
    label: 'First Name',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
  {
    id: 'last_name',
    kind: 'TEXT',
    label: 'Last Name',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
  {
    id: 'email',
    kind: 'TEXT',
    label: 'Email',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
];

export default personDefaultFields;
