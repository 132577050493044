/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Redirect, Route, Switch } from 'react-router-dom';

import type {
  PageComponentKindType,
  ReorderedPageComponentConfigType,
} from '../pageComponentsConfig';
import type { SelectedComponent } from '../RegistrationCreateContent';
import Design from './Design';
import EventInfo from './EventInfo';

import type { PagePropertiesRoutes_event } from './__generated__/PagePropertiesRoutes_event.graphql';
import type { PagePropertiesRoutes_org } from './__generated__/PagePropertiesRoutes_org.graphql';
import type { PagePropertiesRoutes_registrationForm } from './__generated__/PagePropertiesRoutes_registrationForm.graphql';

const PagePropertiesRoutes = (props: {
  registrationForm: PagePropertiesRoutes_registrationForm,
  org: PagePropertiesRoutes_org,
  event: PagePropertiesRoutes_event,
  pathPrefix: string,
  location: Location,
  formComponentSelected: boolean,
  onMoveEnd: (reorderedPageComponentConfig?: ReorderedPageComponentConfigType) => void,
  onBeginDrag: (draggedNewComponentKind: PageComponentKindType) => void,
  onChangePageWidth: (width: number) => void,
  onChangeSelectedComponentProperty: (updatedProps: SelectedComponent) => void,
  onHoverPageComponent: (kind: PageComponentKindType) => void,
  onHoverOutPageComponent: () => void,
}) => {
  const {
    registrationForm,
    pathPrefix,
    event,
    org,
    location,
    onMoveEnd,
    onBeginDrag,
    onChangePageWidth,
    formComponentSelected,
    onChangeSelectedComponentProperty,
    onHoverPageComponent,
    onHoverOutPageComponent,
  } = props;
  const defaultPage = `${pathPrefix}/${org.syncedToIbmWm || !event ? 'design' : 'eventInfo'}${
    location.search
  }`;
  return (
    <Switch>
      <Redirect from={pathPrefix} exact to={defaultPage} />
      {!org.syncedToIbmWm && event && (
        <Route
          path={`${pathPrefix}/eventInfo`}
          exact
          render={pageProps => (
            <EventInfo {...pageProps} event={event} registrationForm={registrationForm} />
          )}
        />
      )}
      <Route
        path={`${pathPrefix}/design`}
        exact
        render={pageProps => (
          <Design
            {...pageProps}
            registrationForm={registrationForm}
            org={org}
            showPageStyling={formComponentSelected}
            onMoveEnd={onMoveEnd}
            onBeginDrag={onBeginDrag}
            onChangePageWidth={onChangePageWidth}
            onChangeSelectedComponentProperty={onChangeSelectedComponentProperty}
            onHoverPageComponent={onHoverPageComponent}
            onHoverOutPageComponent={onHoverOutPageComponent}
          />
        )}
      />
    </Switch>
  );
};

export default createFragmentContainer(PagePropertiesRoutes, {
  registrationForm: graphql`
    fragment PagePropertiesRoutes_registrationForm on RegistrationForm {
      ...EventInfo_registrationForm
      ...Design_registrationForm
    }
  `,
  org: graphql`
    fragment PagePropertiesRoutes_org on Org {
      ...Design_org
      syncedToIbmWm
    }
  `,
  event: graphql`
    fragment PagePropertiesRoutes_event on Event {
      ...EventInfo_event
    }
  `,
});
