/**
 * @flow
 * @relayHash fd95d6fef23c1263a30439b59cbbe7c3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddDeliverablesTagInput = {
  clientMutationId?: ?string,
  deliverableIds: $ReadOnlyArray<string>,
  tagId: string,
  fromWindow: string,
};
export type addTasksTagMutationVariables = {|
  input: AddDeliverablesTagInput
|};
export type addTasksTagMutationResponse = {|
  +addDeliverablesTag: ?{|
    +deliverables: $ReadOnlyArray<{|
      +id: string,
      +dbId: number,
      +name: string,
      +event: {|
        +dbId: number,
        +name: string,
      |},
      +tags: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +color: number,
            +name: string,
          |}
        |}>
      |},
    |}>
  |}
|};
export type addTasksTagMutation = {|
  variables: addTasksTagMutationVariables,
  response: addTasksTagMutationResponse,
|};
*/


/*
mutation addTasksTagMutation(
  $input: AddDeliverablesTagInput!
) {
  addDeliverablesTag(input: $input) {
    deliverables {
      id
      dbId
      name
      event {
        dbId
        name
        id
      }
      tags {
        edges {
          node {
            id
            color
            name
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddDeliverablesTagInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddDeliverablesTagInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tags",
  "storageKey": null,
  "args": null,
  "concreteType": "TagRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TagRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Tag",
          "plural": false,
          "selections": [
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "color",
              "args": null,
              "storageKey": null
            },
            v4
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addTasksTagMutation",
  "id": null,
  "text": "mutation addTasksTagMutation(\n  $input: AddDeliverablesTagInput!\n) {\n  addDeliverablesTag(input: $input) {\n    deliverables {\n      id\n      dbId\n      name\n      event {\n        dbId\n        name\n        id\n      }\n      tags {\n        edges {\n          node {\n            id\n            color\n            name\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addTasksTagMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDeliverablesTag",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddDeliverablesTagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": true,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v3,
                  v4
                ]
              },
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "addTasksTagMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDeliverablesTag",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddDeliverablesTagPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": true,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v2
                ]
              },
              v5
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ed10cc3364d6023f3ab9135852bbdfb6';
module.exports = node;
