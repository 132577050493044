/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextInput from 'components/material/TextInput';

import inputBoxStyled from '../../components/inputBoxStyled';

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};

  padding: 0 2px;
  text-align: center;
`;

type SizeSymbol = 'px' | '%' | '';

export default class SizeField extends React.PureComponent<
  {
    value: ?number,
    className?: string,
    onZeroValue?: number,
    sizeSymbol: SizeSymbol,
    onSave: (value: number) => void,
    onChange?: (value: number) => void,
    maxLength: number,
    maxValue?: number,
    forceFocus?: boolean,
  },
  {
    value: number,
    initialValue: ?number,
    focused: boolean,
    onModifying: boolean,
  },
> {
  inputRef = React.createRef();

  static defaultProps = {
    sizeSymbol: 'px',
    maxLength: 3,
  };

  state = {
    initialValue: this.props.value,
    value: (this.props.value != null ? this.props.value : this.props.onZeroValue) || 0,
    focused: false,
    onModifying: false,
  };

  static getDerivedStateFromProps(
    nextProps: $PropertyType<SizeField, 'props'>,
    prevState: $PropertyType<SizeField, 'state'>,
  ) {
    if (nextProps.value !== prevState.initialValue) {
      return { value: nextProps.value, initialValue: nextProps.value };
    }
    return null;
  }

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const filteredNumber = e.currentTarget.value.replace(/\D/g, '');
    const value = filteredNumber === '' ? 0 : Number(filteredNumber);
    this.setState({ value });
    this.handleSendModifyingValue(value);
  };

  handleSendModifyingValue = (value: number) => {
    const { onChange, forceFocus } = this.props;
    if (onChange) {
      onChange(value);
    }

    if (forceFocus) {
      this.setState({ onModifying: true });
      setTimeout(() => {
        if (this.inputRef && this.inputRef.current) {
          this.inputRef.current.focus();
        }
      }, 10);
    }
  };

  handleBlur = () => {
    const { onZeroValue, onSave, maxValue, forceFocus } = this.props;
    const { value } = this.state;
    const selectedValue = onZeroValue != null && value === 0 ? onZeroValue : value;
    const finalValue = maxValue == null ? selectedValue : Math.min(selectedValue, maxValue);
    this.setState({ focused: false, value: finalValue });
    if (!forceFocus) {
      onSave(finalValue);
      return;
    }
    setTimeout(() => {
      const { value: valueChanged, focused } = this.state;
      if (!focused) {
        const selectedValueFinal =
          onZeroValue != null && valueChanged === 0 ? onZeroValue : valueChanged;
        const savingValue =
          maxValue == null ? selectedValueFinal : Math.min(selectedValueFinal, maxValue);
        this.setState({ onModifying: false, value: savingValue });
        onSave(savingValue);
      }
    }, 100);
  };

  handleKeydown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const maxValue = this.props.maxValue;
    switch (e.key) {
      case 'Enter':
        e.currentTarget.blur();
        break;
      case 'ArrowUp':
        this.setState(prevState => {
          const increment = prevState.value + 1;
          const value = maxValue == null ? increment : Math.min(increment, maxValue);
          this.handleSendModifyingValue(value);
          return { value };
        });
        break;
      case 'ArrowDown':
        this.setState(prevState => {
          const value = prevState.value > 0 ? prevState.value - 1 : prevState.value;
          this.handleSendModifyingValue(value);
          return { value };
        });
        break;
      default:
      // add other functional keys;
    }
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  render() {
    const { value, focused, onModifying } = this.state;
    const { className, sizeSymbol, maxLength } = this.props;
    const inputValue = value.toString();

    return (
      <StyledTextInput
        inputRef={this.inputRef}
        className={className}
        value={focused ? inputValue : `${inputValue}${onModifying ? '' : sizeSymbol}`}
        onChange={this.handleChange}
        onKeyDown={this.handleKeydown}
        maxLength={maxLength}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    );
  }
}
