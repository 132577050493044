/* @flow */
import track from '../../track';

export type ViewedBriefProps = {
  eventId: number,
  eventName: string,
  teamId: number,
  teamName: string,
  fromWindow: string,
};

const viewedBrief = (props: ViewedBriefProps) => {
  track('viewed_brief', {
    event_id: props.eventId,
    event_name: props.eventName,
    team_id: props.teamId,
    team_name: props.teamName,
    from_window: props.fromWindow,
  });
};

export default viewedBrief;
