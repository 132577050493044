/* @flow */
import React from 'react';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';

import ConfirmationWindow from 'components/ConfirmationWindow';
import EditableQuestion from 'components/EventRequestForm/EditableQuestion';
import generateBlankQuestion from 'components/EventRequestForm/lib/generateBlankQuestion';
import generateBlankSection from 'components/EventRequestForm/lib/generateBlankSection';
import type {
  FormDetailsType,
  OrderRestrictionType,
  QuestionType,
  RelayQuestionType,
  RelayRulesType,
  RelaySectionType,
  ReorderedQuestionType,
  SectionType,
} from 'components/EventRequestForm/lib/types';
import type { EventRequestFormBuilderBody_requestForm } from 'views/EventRequestFormBuilder/__generated__/EventRequestFormBuilderBody_requestForm.graphql';
import type { EventRequestFormBuilderQuestionnaire_org } from 'views/EventRequestFormBuilder/__generated__/EventRequestFormBuilderQuestionnaire_org.graphql';
import EventRequestFormContainer from 'views/EventRequestFormBuilder/components/EventRequestFormContainer';

import EditableSectionDropable from './EditableSectionDropable';
import EditableSectionHeader from './EditableSectionHeader';
import EditableSectionLeftPanel from './EditableSectionLeftPanel';

const RemoveSection = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 36px;
  height: 35px;
  background-color: #f1f9fc;
  text-align: center;
  color: #868f96;
  font-size: 17px;
  line-height: 35px;
  cursor: pointer;
  &:hover {
    color: #7d8286;
  }
`;

const Container = styled(EventRequestFormContainer)`
  position: relative;
  margin-bottom: 20px;
  padding: 20px 48px;
  overflow: hidden;
  &:hover {
    box-shadow: 0 0 0 1px #9ad9f2, 0 0 8px rgba(0, 0, 0, 0.12);
  }
  &:hover ${RemoveSection} {
    display: block;
  }
`;

const NewQuestionButtonContainer = styled.div`
  margin: 0 0 50px 0;
  text-align: center;
`;

const NewQuestionButton = styled.button`
  height: 45px;
  padding: 0 30px;
  border: 2px solid #29cc71;
  border-radius: 4px;
  background: #29cc71;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: 0.2s;
  &:hover {
    background: transparent;
    color: #29cc71;
  }
`;

const AddSectionButton = styled.div`
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  border-style: dashed;
  background-color: #fff;
  color: #00aadf;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  &:hover {
    border-color: #60b9e1;
  }
`;

export default class EditableSection extends React.PureComponent<
  {
    org: EventRequestFormBuilderQuestionnaire_org,
    requestForm: EventRequestFormBuilderBody_requestForm,
    editingRequestForm: FormDetailsType,
    isSubmitted: boolean,
    section: SectionType,
    draggingQuestion: ?QuestionType,
    onHasChanges: (hasChanges: boolean) => void,
    usedMappings: $ReadOnlyArray<string>,
    errors: $ReadOnlyArray<string>,
    onChangeFormDetails: (details: $Shape<FormDetailsType>) => void,
    onAutoSave: () => void,
    onRemoveSection: (sectionId: string) => void,
    onMoveSection: (currentOrder: number, targetOrder: number) => void,
    onSetDraggingQuestion: (draggingQuestion: ?QuestionType) => void,
    onRemoveQuestion: (sectionId: string, questionId: string) => void,
    onChangeEditingQuestion: (questionId: string, editing: boolean) => void,
    restrictions: OrderRestrictionType,
    onSetRestriction: (restrictions: OrderRestrictionType) => void,
    restrictedDruggingZone: boolean,
    onSetRestrictedDruggingZone: (restrictedDruggingZone: boolean) => void,
    saving: boolean,
  },
  { showModal: boolean },
> {
  state = { showModal: false };

  ruleDependencyChecker = (customFieldId: ?string, fieldName: ?string, rules?: RelayRulesType) => {
    return (
      rules != null &&
      rules.edges.some(({ node: rule }) => {
        return [
          ...rule.customSavedTextFilters.edges,
          ...rule.customSavedTextareaFilters.edges,
          ...rule.customSavedLinkFilters.edges,
          ...rule.customSavedNumberFilters.edges,
          ...rule.customSavedCurrencyFilters.edges,
          ...rule.customSavedBooleanFilters.edges,
          ...rule.customSavedMultiselectFilters.edges,
          ...rule.customSavedDateFilters.edges,
          ...rule.customSavedUserMultiselectFilters.edges,
        ].some(({ node: field }) => {
          if (field == null) return false;

          if (field.customField != null) {
            return field.customField.id === customFieldId;
          }

          return field.fieldName && field.fieldName === fieldName;
        });
      })
    );
  };

  sectionIsDependent = (
    section: { +node: RelaySectionType },
    nextSection: ?{ +node: RelaySectionType } = null,
  ) => {
    const { requestForm } = this.props;
    const nextSections = nextSection
      ? [nextSection].filter(Boolean)
      : requestForm.sections.edges.filter(edge => edge.node.order > section.node.order);

    return section.node.questions.edges.some(({ node: question }) => {
      const customFieldId = question.mappingCustomField ? question.mappingCustomField.id : '';
      const fieldName = question.mapping || null;

      return nextSections.some(({ node: s }) => {
        return (
          this.ruleDependencyChecker(customFieldId, fieldName, s.rules) ||
          s.questions.edges.some(
            ({ node: q }) =>
              q.rules && this.ruleDependencyChecker(customFieldId, fieldName, q.rules),
          )
        );
      });
    });
  };

  questionIsDependent = (question: QuestionType) => {
    const { requestForm, section } = this.props;

    const checkSections = requestForm.sections.edges.filter(
      ({ node: s }) => s.order >= section.order,
    );
    const customFieldId = question.mappingCustomField ? question.mappingCustomField.id : '';
    const fieldName = question.mapping || null;

    return checkSections.some(({ node: s }) => {
      return (
        this.ruleDependencyChecker(customFieldId, fieldName, s.rules) ||
        (s.id === question.sectionId
          ? s.questions.edges.filter(({ node: q }) => q.order > question.order)
          : s.questions.edges
        ).some(
          edge =>
            edge.node.rules &&
            this.ruleDependencyChecker(customFieldId, fieldName, edge.node.rules),
        )
      );
    });
  };

  // Used for getting latest up to date order of section
  getStateSection = (section: RelaySectionType) => {
    return this.props.editingRequestForm.sections.find(s => s.id === section.id) || section;
  };

  // Used for getting latest up to date order of question
  getStateQuestion = (question: RelayQuestionType) => {
    return (
      this.props.editingRequestForm.sections.reduce((tq, section) => {
        if (tq) return tq;
        return section.questions.find(q => q.id === question.id);
      }, null) || question
    );
  };

  restrictMoveToNewPosition = (reorderedQuestion: ReorderedQuestionType): boolean => {
    const { restrictions, editingRequestForm } = this.props;

    const targetQuestion = reorderedQuestion.targetQuestion;

    const targetSection =
      reorderedQuestion.targetSection ||
      editingRequestForm.sections.find(s => targetQuestion && s.id === targetQuestion.sectionId);

    if (restrictions.min) {
      if (
        reorderedQuestion.targetSection &&
        ((restrictions.min.questionOrder == null &&
          restrictions.min.sectionOrder >= reorderedQuestion.targetSection.order) ||
          restrictions.min.sectionOrder > reorderedQuestion.targetSection.order)
      ) {
        return true;
      }

      if (targetQuestion) {
        if (
          targetSection &&
          targetQuestion &&
          (restrictions.min.sectionOrder > targetSection.order ||
            (restrictions.min.sectionOrder === targetSection.order &&
              restrictions.min.questionOrder >= targetQuestion.order))
        ) {
          return true;
        }
      }
    }

    if (restrictions.max) {
      // Check section dependencies from dragged question
      if (
        restrictions.max.questionOrder == null &&
        targetSection &&
        restrictions.max.sectionOrder <= targetSection.order
      ) {
        return true;
      }

      if (targetQuestion) {
        const direction =
          targetQuestion &&
          reorderedQuestion.question.sectionId === targetQuestion.sectionId &&
          reorderedQuestion.question.order < targetQuestion.order
            ? 'down'
            : null;

        if (
          targetSection &&
          targetQuestion &&
          restrictions.max.questionOrder != null &&
          (restrictions.max.sectionOrder < targetSection.order ||
            (restrictions.max.sectionOrder === targetSection.order &&
              ((direction === 'down' && restrictions.max.questionOrder <= targetQuestion.order) ||
                (!direction && restrictions.max.questionOrder < targetQuestion.order))))
        ) {
          return true;
        }
      }

      if (!targetQuestion) {
        if (targetSection && restrictions.max.sectionOrder <= targetSection.order) {
          return true;
        }
      }
    }

    return false;
  };

  handleCreateQuestion = () => {
    const sectionId: string = this.props.section.id;
    this.props.onChangeFormDetails({
      sections: this.props.editingRequestForm.sections.map(section => {
        if (section.id === sectionId) {
          const lastQuestion = sortBy(section.questions, 'order').pop();
          return {
            ...section,
            questions: [
              ...(section.questions || []),
              generateBlankQuestion(lastQuestion ? lastQuestion.order + 1 : 1, sectionId),
            ],
          };
        }
        return section;
      }),
    });
  };

  handleCreateSection = () => {
    const order: number = this.props.section.order + 1;
    this.props.onChangeFormDetails({
      sections: [
        ...this.props.editingRequestForm.sections.map(section => {
          if (section.order >= order) {
            return { ...section, order: section.order + 1 };
          }
          return section;
        }),
        generateBlankSection(order),
      ],
    });
  };

  handleUpdateSection = (changedSection: SectionType) => {
    this.props.onChangeFormDetails({
      sections: this.props.editingRequestForm.sections.map(section =>
        section.id === changedSection.id ? changedSection : section,
      ),
    });
  };

  handleChangeEditingSection = (editing: boolean) => {
    if (this.props.errors.length === 0 && !editing) {
      this.props.onAutoSave();
    }
  };

  handleChangeQuestion = (changedQuestion: QuestionType) => {
    this.props.onChangeFormDetails({
      sections: this.props.editingRequestForm.sections.map(section => {
        return {
          ...section,
          questions: section.questions.map(question =>
            question.id === changedQuestion.id ? changedQuestion : question,
          ),
        };
      }),
    });
  };

  handleBeginDragQuestion = (question: QuestionType) => {
    const { section, requestForm } = this.props;
    this.props.onSetDraggingQuestion(question);
    const currentSection = requestForm.sections.edges.find(
      ({ node: s }) => s.order === section.order,
    );

    const currentQuestion = currentSection
      ? currentSection.node.questions.edges.find(({ node: q }) => q.id === question.id)
      : null;

    const min = orderBy(requestForm.sections.edges, 'node.order', 'desc')
      .filter(({ node: s }) => s.order <= section.order)
      .reduce((m, { node: s }) => {
        if (m && m.sectionOrder) {
          return m;
        }

        const questionOrder = orderBy(s.questions.edges, 'node.order', 'desc').reduce(
          (minPosition, { node: q }) => {
            if (minPosition && minPosition.questionOrder) {
              return minPosition;
            }
            const customFieldId = q.mappingCustomField ? q.mappingCustomField.id : '';
            const fieldName = q.mapping || null;
            if (
              currentQuestion &&
              this.ruleDependencyChecker(customFieldId, fieldName, currentQuestion.node.rules)
            ) {
              return {
                sectionOrder: this.getStateSection(s).order,
                questionOrder: this.getStateQuestion(q).order,
              };
            }
            return null;
          },
          null,
        );

        return questionOrder;
      }, null);

    const customFieldId =
      currentQuestion && currentQuestion.node.mappingCustomField
        ? currentQuestion.node.mappingCustomField.id
        : '';
    const fieldName = (currentQuestion ? currentQuestion.node.mapping : null) || null;

    const max = orderBy(requestForm.sections.edges, 'node.order', 'asc')
      .filter(({ node: s }) => s.order >= section.order)
      .reduce((sectionOrder, { node: s }) => {
        if (sectionOrder && sectionOrder.sectionOrder) {
          return sectionOrder;
        }

        if (this.ruleDependencyChecker(customFieldId, fieldName, s.rules)) {
          return { sectionOrder: this.getStateSection(s).order };
        }

        const questionOrder = orderBy(s.questions.edges, 'node.order', 'asc').reduce(
          (maxPosition, { node: q }) => {
            if (maxPosition && maxPosition.questionOrder) {
              return maxPosition;
            }
            if (currentQuestion && this.ruleDependencyChecker(customFieldId, fieldName, q.rules)) {
              return {
                sectionOrder: this.getStateSection(s).order,
                questionOrder: this.getStateQuestion(q).order,
              };
            }
            return null;
          },
          null,
        );

        return questionOrder;
      }, null);

    this.props.onSetRestriction({ min, max });
  };

  handleMoveQuestion = (reorderedQuestion: ReorderedQuestionType) => {
    const restrictedDruggingZone = this.restrictMoveToNewPosition(reorderedQuestion);
    if (restrictedDruggingZone !== this.props.restrictedDruggingZone) {
      this.props.onSetRestrictedDruggingZone(restrictedDruggingZone);
    }
  };

  handleMoveEnd = (reorderedQuestion: ?ReorderedQuestionType) => {
    if (this.props.restrictedDruggingZone) {
      return;
    }

    if (
      !reorderedQuestion ||
      (reorderedQuestion.targetSection &&
        reorderedQuestion.question.sectionId === reorderedQuestion.targetSection.id)
    ) {
      return;
    }

    const editingRequestForm = this.props.editingRequestForm;
    const targetQuestion = reorderedQuestion.targetQuestion;
    const question = reorderedQuestion.question;
    const sourceSectionId = question.sectionId;

    // Do not allow to move question mapped with event name to another section
    if (
      question.mapping === 'NAME' &&
      (!targetQuestion || question.sectionId !== targetQuestion.sectionId)
    ) {
      return;
    }

    if (
      this.props.restrictedDruggingZone ||
      (targetQuestion &&
        question.order === targetQuestion.order &&
        question.sectionId === targetQuestion.sectionId)
    ) {
      this.props.onSetRestrictedDruggingZone(false);
      return;
    }

    const changedSectionsPart = {
      sections: editingRequestForm.sections.map(section => {
        const changedSection = section;

        if (
          sourceSectionId === section.id &&
          targetQuestion &&
          targetQuestion.sectionId === section.id
        ) {
          const direction = question.order > targetQuestion.order ? 'up' : 'down';

          changedSection.questions = sortBy(section.questions, 'order').map(
            (field: QuestionType, index: number) => {
              // Field is out of reorder range. Keep existing order and return
              if (
                (direction === 'up' &&
                  (field.order < targetQuestion.order || field.order > question.order)) ||
                (direction === 'down' &&
                  (field.order < question.order || field.order > targetQuestion.order))
              ) {
                return { ...field, order: index + 1 };
              }

              // Apply the new order to the field that is being dragged
              if (field.id === question.id) {
                return {
                  ...field,
                  order:
                    sortBy(section.questions, 'order').findIndex(q => q.id === targetQuestion.id) +
                    1,
                };
              }

              // Reorder questions in between initial and final positions
              return { ...field, order: direction === 'up' ? index + 2 : index };
            },
          );

          return changedSection;
        }

        if (
          reorderedQuestion &&
          reorderedQuestion.targetSection &&
          section.id === reorderedQuestion.targetSection.id &&
          question.sectionId !== reorderedQuestion.targetSection.id
        ) {
          changedSection.questions = [
            ...sortBy(section.questions, 'order').map((field: QuestionType, index: number) => ({
              ...field,
              order: index + 1,
            })),
            { ...question, order: section.questions.length + 1, sectionId: section.id },
          ];

          return changedSection;
        }

        if (targetQuestion && targetQuestion.sectionId === section.id) {
          changedSection.questions = sortBy(section.questions, 'order').reduce(
            (arr: $ReadOnlyArray<QuestionType>, field: QuestionType, index: number) => {
              // Field is out of reorder range. Keep existing order and return
              if (field.order < targetQuestion.order) {
                return [...arr, { ...field, order: index + 1 }];
              }

              // Apply the new order to the field that is being dragged
              if (field.id === targetQuestion.id) {
                return [
                  ...arr,
                  { ...question, order: index + 1, sectionId: section.id },
                  { ...field, order: index + 2 },
                ];
              }

              // Reorder questions in between initial and final positions
              return [...arr, { ...field, order: index + 2 }];
            },
            [],
          );

          return changedSection;
        }

        return section;
      }),
    };

    if (
      (reorderedQuestion.targetSection && sourceSectionId !== reorderedQuestion.targetSection.id) ||
      (targetQuestion && targetQuestion.sectionId !== sourceSectionId)
    ) {
      changedSectionsPart.sections = changedSectionsPart.sections.map(section => {
        const changedSection = section;
        if (section.id === sourceSectionId) {
          changedSection.questions = section.questions
            .map(q => {
              if (q.order < question.order) {
                return q;
              }
              return { ...q, order: q.order - 1 };
            })
            .filter(q => q.id !== question.id);
        }
        return changedSection;
      });
    }

    this.props.onChangeFormDetails(changedSectionsPart);
    this.props.onSetDraggingQuestion(null);
    if (!reorderedQuestion.question.id.startsWith('question_')) {
      this.props.onHasChanges(true);
      this.props.onAutoSave();
    }
  };

  handleRemoveSection = () => {
    this.props.onRemoveSection(this.props.section.id);
  };

  handleRemoveQuestion = (questionId: string) => {
    this.props.onRemoveQuestion(this.props.section.id, questionId);
  };

  handleModalHide = () => {
    this.setState({ showModal: false });
  };

  handleModalShow = () => {
    this.setState({ showModal: true });
  };

  render() {
    const {
      editingRequestForm,
      draggingQuestion,
      restrictedDruggingZone,
      requestForm,
      section,
    } = this.props;

    const currentSection = requestForm
      ? requestForm.sections.edges.find(({ node: s }) => s.order === section.order)
      : null;
    const nextSection = requestForm
      ? requestForm.sections.edges.find(({ node: s }) => s.order === section.order + 1)
      : null;
    const nextStateSection = editingRequestForm.sections.find(s => s.order === section.order + 1);
    const prevSection = requestForm
      ? requestForm.sections.edges.find(({ node: s }) => s.order === section.order - 1)
      : null;

    const hasDependecyFromPrev =
      prevSection && !section.id.startsWith('section_')
        ? this.sectionIsDependent(prevSection, currentSection)
        : false;
    const hasDependencyWithNext =
      currentSection &&
      nextStateSection &&
      !nextStateSection.id.startsWith('section_') &&
      nextSection
        ? this.sectionIsDependent(currentSection, nextSection)
        : false;

    return (
      <>
        <Container className={`section_${section.id.replace(/=/g, '')}`}>
          <EditableSectionLeftPanel
            section={section}
            sectionsCount={editingRequestForm.sections.length}
            onMoveSection={this.props.onMoveSection}
            hasDependecyFromPrev={hasDependecyFromPrev}
            hasDependencyWithNext={hasDependencyWithNext}
            saving={this.props.saving}
          />
          {section.order !== 1 && (
            <RemoveSection onClick={this.handleModalShow} title="Remove section">
              <i className="fa fa-fw fa-trash" />
            </RemoveSection>
          )}
          <EditableSectionHeader
            section={section}
            onChangeSection={this.handleUpdateSection}
            onChangeEditing={this.handleChangeEditingSection}
            onHasChanges={this.props.onHasChanges}
          />
          {section.questions &&
            sortBy(section.questions, 'order').map(question => (
              <EditableQuestion
                key={question.id}
                org={this.props.org}
                question={question}
                isSubmitted={this.props.isSubmitted}
                usedMappings={this.props.usedMappings}
                onChangeQuestion={this.handleChangeQuestion}
                onCheckDependencies={this.questionIsDependent}
                onRemoveQuestion={this.handleRemoveQuestion}
                onChangeEditing={this.props.onChangeEditingQuestion}
                onMoveQuestion={this.handleMoveQuestion}
                onDropQuestion={this.handleMoveEnd}
                onHasChanges={this.props.onHasChanges}
                onBeginDrag={this.handleBeginDragQuestion}
                draggingQuestion={this.props.draggingQuestion}
                restrictedDruggingZone={this.props.restrictedDruggingZone}
              />
            ))}
          <NewQuestionButtonContainer>
            <EditableSectionDropable
              section={section}
              onMove={this.handleMoveQuestion}
              draggingQuestion={draggingQuestion}
              restrictedDruggingZone={restrictedDruggingZone}
            />
            <NewQuestionButton onClick={this.handleCreateQuestion}>
              + Add New Question
            </NewQuestionButton>
          </NewQuestionButtonContainer>
        </Container>
        <AddSectionButton onClick={this.handleCreateSection}>
          <i className="fa fa-plus fa-fw" />
          Add Section
        </AddSectionButton>
        {this.state.showModal && (
          <ConfirmationWindow
            onHide={this.handleModalHide}
            onConfirm={this.handleRemoveSection}
            title="Are you sure you want to remove this section?"
            message={
              currentSection && this.sectionIsDependent(currentSection)
                ? 'One or more questions in this section have logical dependencies with other questions and/or sections.'
                : `Once ${section.title || 'section'} is deleted, it's gone for good.`
            }
            //
          />
        )}
      </>
    );
  }
}
