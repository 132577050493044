/* @flow */
import { graphql } from 'react-relay/compat';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  addUserToEventMutationResponse,
  addUserToEventMutationVariables,
} from './__generated__/addUserToEventMutation.graphql';

const mutation = graphql`
  mutation addUserToEventMutation($input: AddUserToEventInput!) {
    addUserToEvent(input: $input) {
      staffMembershipEdges {
        node {
          id
          ...EventAccessLevel_staffedEvent
          ...EventDate_staffedEvent
          ...EventDateAdded_staffedEvent
          ...EventLocation_staffedEvent
          ...EventName_staffedEvent
          ...EventOnsite_staffedEvent
          ...EventActionsColumn_staffedEvent
        }
      }
    }
  }
`;

type InputType = $PropertyType<addUserToEventMutationVariables, 'input'>;
type StaffInvites = $PropertyType<InputType, 'staffInvites'>;

export default function addUserToEvent(
  userId: string,
  staffInvites: StaffInvites,
): Promise<addUserToEventMutationResponse> {
  const variables: addUserToEventMutationVariables = {
    input: {
      userId,
      staffInvites,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: userId,
      connections: [{ field: 'staffedEvents' }, { key: 'UserEvents_staffedEvents' }],
      edgeName: 'staffMembershipEdges',
    }),
  });
}
