/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type LiveActions_event$ref: FragmentReference;
export type LiveActions_event = {|
  +slug: string,
  +registrationForm: ?{|
    +id: string,
    +publishedAt: ?any,
  |},
  +editableRegistrationForm: ?{|
    +hasChanges: boolean
  |},
  +$refType: LiveActions_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "LiveActions_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "registrationForm",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationForm",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "publishedAt",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "editableRegistrationForm",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationForm",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hasChanges",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ab3602d37c9d6e7a5ec5af242eb54af9';
module.exports = node;
