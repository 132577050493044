/* @flow */
import * as React from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';
import Overlay from 'components/material/Overlay';
import { type User } from 'components/UserSelect';

import type { UserSelectInputContainer_query } from './__generated__/UserSelectInputContainer_query.graphql';

export const Container = styled.div`
  height: 100%;
  background-color: #fff;
`;

const TextWrapper = styled.div`
  position: relative;
`;

const StyledTextInput = styled.input`
  width: 100%;
  outline: none;
  padding: 8px;
  &::placeholder {
    color: ${props => props.theme.mutedTextColor};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Main = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  margin-left: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
`;

const UserRow = styled.div`
  display: flex;
  align-items: center;
  padding: 7px;
  border-top: 1px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.rowPrimaryTextColor};
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

const UserName = styled.div`
  flex: 1 1 auto;
  width: 0;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
`;

const ActiveIcon = styled.i`
  margin-left: 5px;
  font-size: 16px;
  color: ${props => props.theme.primaryActionColor};
`;

const StyledOverlay = styled(Overlay)`
  width: auto;
  min-width: 100%;
  height: auto;
  max-height: 305px;
  overflow-y: auto;
  margin-top: 3px;
  z-index: 999;
`;

const OverlayFocus = styled.div`
  outline: none;
`;

const Label = styled.div`
  flex: 1 1 auto;
  width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => (props.error ? props.theme.negativeActionColor : props.theme.labelColor)};
`;

const InviteOption = styled(UserRow)`
  color: ${props => props.theme.primaryActionColor};
  border-bottom: 0;
  border-top: 1px solid #e6e6e6;
  i {
    margin-right: 5px;
  }
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

export type UserType = $Shape<{
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  $fragmentRefs: any,
}>;

class UserSelectInputContainer extends React.Component<
  {
    activeUsers: ?$ReadOnlyArray<User>,
    onChange: (activeUser: UserType) => void,
    onHideOverlay: () => void,
    header?: React.Node,
    label?: string,
    query: UserSelectInputContainer_query,
    relay: RelayRefetchProp,
    isMultiselect?: boolean,
    onInviteClick?: () => void,
    hideSelected?: boolean,
  },
  { search: string, showOverlay: boolean },
> {
  state = { search: '', showOverlay: true };

  fieldRef = React.createRef();

  overlayRef = React.createRef();

  inputRef = React.createRef();

  cachedUsers: $ReadOnlyArray<UserType> = [];

  activeUsers: $ReadOnlyArray<UserType> = [];

  containerRef = React.createRef();

  fetchUsers = debounce((query: string) => {
    this.props.relay.refetch(
      {
        query,
        userIds: this.activeValues(),
      },
      null,
    );
  }, 800);

  handleQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ search: e.currentTarget.value });
    this.fetchUsers(e.currentTarget.value);
  };

  getCorrespondingUsers = (): Array<UserType> => {
    const { searchUsers, event } = this.props.query;
    if (searchUsers) {
      return searchUsers.edges.map(({ node }) => node);
    }
    if (!event) {
      return [];
    }
    return uniqBy(
      [
        ...(event.staffers ? event.staffers.edges.map(({ node }) => node.user) : []),
        ...(event.team ? event.team.users.edges.map(({ node }) => node) : []),
      ],
      'id',
    );
  };

  getUsers = (): $ReadOnlyArray<UserType> => {
    this.activeUsers =
      this.props.query && this.props.query.activeUsers
        ? this.props.query.activeUsers
        : this.activeUsers;
    this.cachedUsers = this.props.query
      ? sortBy(
          uniqBy([...this.activeUsers, ...this.getCorrespondingUsers()], 'id').filter(Boolean),
          user => (user.firstName || user.lastName || user.email || '').toLowerCase(),
        )
      : this.cachedUsers;
    return this.props.hideSelected
      ? this.cachedUsers.filter(user =>
          this.activeUsers.every(activeUser => activeUser.id !== user.id),
        )
      : this.cachedUsers;
  };

  renderUserRow = (user: UserType) => {
    return (
      <Row>
        <MaterialAvatar user={user} radius={11} />
        <Main>{fullNameOfUser(user)}</Main>
      </Row>
    );
  };

  handleFocusOverlay = (e: SyntheticEvent<HTMLDivElement>) => {
    if (this.inputRef.current && !(e.target instanceof HTMLInputElement)) {
      this.inputRef.current.focus();
    }
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const container = this.containerRef.current;
    const overlay = this.overlayRef.current;
    const relatedTarget: ?Node = (e: any).relatedTarget || document.activeElement;

    if (
      container &&
      relatedTarget &&
      overlay &&
      (container.contains(relatedTarget) || overlay.contains(relatedTarget))
    ) {
      return;
    }

    this.handleHideOverlay();
  };

  handleInviteClick = () => {
    if (this.props.onInviteClick) {
      this.props.onInviteClick();
    }
    this.handleHideOverlay();
  };

  handleTextWrapperClick = () => {
    this.setState({ showOverlay: true });
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (e.key === 'Escape') {
      this.handleHideOverlay();
    }
  };

  handleHideOverlay = () => {
    this.props.onHideOverlay();
    this.setState({ showOverlay: false });
  };

  handleScroll = (e: SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  getInputField = (): React.Node => (
    <TextWrapper>
      <StyledTextInput
        ref={this.inputRef}
        onKeyDown={this.handleKeyDown}
        onScroll={this.handleScroll}
        placeholder="Search..."
        autoFocus
        value={this.state.search}
        onChange={this.handleQueryChange}
        autoComplete="new-password"
      />
    </TextWrapper>
  );

  activeValues(): $ReadOnlyArray<string> {
    return (this.props.activeUsers || []).map(user => user.id);
  }

  renderHeader = (): React.Node => {
    if (
      !this.props.label ||
      (this.props.activeUsers != null && this.props.activeUsers.length > 0)
    ) {
      return this.props.header;
    }
    return <Label>{this.props.label != null ? this.props.label : ''}</Label>;
  };

  render() {
    const users = this.getUsers();

    return (
      <Container ref={this.containerRef} onBlur={this.handleBlur}>
        <Header ref={this.fieldRef} onClick={this.handleTextWrapperClick}>
          {this.props.header == null ? this.getInputField() : this.renderHeader()}
        </Header>
        <StyledOverlay
          show={this.state.showOverlay}
          container={document.body}
          target={this.fieldRef.current}
          overlayRef={this.overlayRef}
        >
          <OverlayFocus tabIndex={-1} onFocus={this.handleFocusOverlay}>
            {this.props.header != null ? this.getInputField() : this.props.header}
            {users.map(option => (
              <UserRow onClick={() => this.props.onChange(option)} key={option.id}>
                <MaterialAvatar user={option} radius={13} />
                <UserName>{fullNameOfUser(option)}</UserName>
                {this.props.isMultiselect && this.activeValues().includes(option.id) && (
                  <ActiveIcon className="fa fa-fw fa-check" />
                )}
              </UserRow>
            ))}
            {this.props.onInviteClick && (
              <InviteOption onClick={this.handleInviteClick}>
                <i className="fa fa-fw fa-plus" /> Add Event Staff
              </InviteOption>
            )}
          </OverlayFocus>
        </StyledOverlay>
      </Container>
    );
  }
}

// TODO: Use org.users connection to fetch users so support users don't get members of other orgs in user select list

export default createRefetchContainer(
  UserSelectInputContainer,
  graphql`
    fragment UserSelectInputContainer_query on Query {
      searchUsers(first: 6, query: $query, hasTeamAccess: $hasTeamAccess) @skip(if: $includeEvent) {
        edges {
          node {
            id
            email
            firstName
            lastName
            ...MaterialAvatar_user
            ...UsersGroup_users
          }
        }
      }
      event: node(id: $eventId) @include(if: $includeEvent) {
        ... on Event {
          viewerCanAddStaff
          staffers(first: 3, query: $query) {
            edges {
              node {
                user {
                  id
                  firstName
                  lastName
                  email
                  ...MaterialAvatar_user
                  ...UsersGroup_users
                }
              }
            }
          }
          team {
            users(first: 3, query: $query) {
              edges {
                node {
                  id
                  firstName
                  lastName
                  email
                  ...MaterialAvatar_user
                  ...UsersGroup_users
                }
              }
            }
          }
        }
      }
      activeUsers: nodes(ids: $userIds) {
        id
        ... on User {
          id
          email
          firstName
          lastName
          ...MaterialAvatar_user
        }
      }
    }
  `,
  graphql`
    query UserSelectInputContainerRefetchQuery(
      $query: String!
      $userIds: [ID!]!
      $hasTeamAccess: Boolean!
      $eventId: ID!
      $includeEvent: Boolean!
    ) {
      query {
        ...UserSelectInputContainer_query
      }
    }
  `,
);
