/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import currentOrigin from 'utils/currentOrigin';
import getHomepageUrl from 'utils/getHomepageUrl';

import duplicateEventList from 'graph/mutations/eventList/duplicateEventList';
import removeEventList from 'graph/mutations/eventList/removeEventList';
import updateEventList from 'graph/mutations/eventList/updateEventList';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ArrowIcon from 'images/arrow.svg';
import DeleteIcon from 'images/delete.svg';
import DuplicateIcon from 'images/duplicate.svg';
import EditIcon from 'images/edit.svg';
import SharedView from 'images/events/shared_view.svg';
import ExternalLinkIcon from 'images/external.svg';
import ConfirmationWindow from 'components/ConfirmationWindow';
import Overlay from 'components/material/Overlay';
import Tooltip from 'components/material/Tooltip';
import ExportedSavedViewTooltipContent from 'components/ScheduledReporting/ExportedSavedViewTooltipContent';
import MenuItem from 'components/Sidebar/MenuItem';
import type { SchedulerType } from 'views/Main/Dashboard/AllEvents/components/AllEventsViewActions';

import SavedEventListEditWindow from './AllEvents/components/SavedEventListEditWindow';

import { type DashboardMenuSavedViewRow_eventList } from './__generated__/DashboardMenuSavedViewRow_eventList.graphql';
import { type DashboardMenuSavedViewRow_org } from './__generated__/DashboardMenuSavedViewRow_org.graphql';

const MenuItemLabel = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MenuItemActions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  margin-left: auto;
  padding-left: 4px;
`;

const StyledMenuItem = styled(MenuItem)`
  flex: 1;
  min-width: 0;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  flex: 0 0 6px;
  margin: 0 6px 0 10px;
  color: #9ca0af;
  transform: rotate(90deg);
  cursor: pointer;

  &:hover {
    color: #3e4859;
  }
`;

const Root = styled.div`
  display: flex;

  &:not(:hover) {
    ${StyledArrowIcon} {
      visibility: hidden;
    }
  }
`;

const StyledOverlay = styled(Overlay)`
  width: 161px;
  padding: 5px 0;
  border-radius: 5px;
  border: 1px solid #dadde1;
  box-shadow: none;
`;

const StyledTooltip = styled(Tooltip)`
  line-height: 0;
  margin-right: 3px;
`;

const OverlayButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 15px;
  font-size: 13px;
  text-align: left;
  color: #3e4859;
  cursor: pointer;

  &:hover {
    background: #f3f3f3;
  }

  svg {
    width: 14px;
    margin-right: 10px;
  }
`;

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  width: 16px !important;
  path:not(:first-of-type) {
    fill: #000;
  }
`;

class DashboardMenuSavedViewRow extends React.PureComponent<
  {
    userId: string,
    homepage: string,
    homepageListId: ?string,
    eventList: ?DashboardMenuSavedViewRow_eventList,
    org: DashboardMenuSavedViewRow_org,
    pathPrefix: string,
    history: RouterHistory,
    eventListsCount: number,
    onRemoveSavedView: () => void,
    viewerCanUpdate: boolean,
  },
  { showOverlay: boolean, showEditViewWindow: boolean, showConfirmationWindow: boolean },
> {
  state = { showOverlay: false, showEditViewWindow: false, showConfirmationWindow: false };

  overlayContainerRef = React.createRef();

  overlayTargetRef = React.createRef();

  handleShowOverlay = (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ showOverlay: true });
  };

  handleHideOverlay = () => {
    this.setState({ showOverlay: false });
  };

  handleEditViewClick = () => {
    this.setState({ showEditViewWindow: true });

    this.handleHideOverlay();
  };

  handleClickDelete = () => {
    this.setState({ showConfirmationWindow: true });

    this.handleHideOverlay();
  };

  handleClickDuplicate = () => {
    if (!this.props.eventList) return;

    duplicateEventList({ eventListId: this.props.eventList.id }, { userId: this.props.userId })
      .then(response => {
        if (response.duplicateEventList) {
          this.props.history.push(
            `/dashboard/${response.duplicateEventList.eventListsEdge.node.id}`,
          );
        }
      })
      .catch(showModernMutationError);

    this.handleHideOverlay();
  };

  handleHideConfirmationWindow = () => {
    this.setState({ showConfirmationWindow: false });
  };

  handleHideEditViewWindow = () => {
    this.setState({ showEditViewWindow: false });
  };

  handleDeleteEventList = () => {
    const {
      userId,
      eventList,
      eventListsCount,
      history,
      homepage,
      homepageListId,
      onRemoveSavedView,
    } = this.props;

    if (!eventList) return;
    removeEventList(userId, eventList.id, eventListsCount)
      .then(() => {
        onRemoveSavedView();
      })
      .catch(showModernMutationError);
    // Removing the list user is currently viewing
    if (history.location.pathname.endsWith(eventList.id)) {
      const homepageOption =
        !homepageListId || homepageListId === eventList.id ? homepage : homepageListId;
      history.push(getHomepageUrl(homepageOption, userId));
    }
  };

  baseUrl = () =>
    `${currentOrigin()}/${this.props.org.settings.subdomain ? '' : `${this.props.org.slug}/`}`;

  handleOpenPreview = () => {
    const { eventList } = this.props;
    const publicResourceToken = (eventList && eventList.publicResourceToken.token) || '';
    window.open(`${this.baseUrl()}${publicResourceToken}`, '_blank');
  };

  handleSavedEventListUpdate = (name: string, scheduler: ?SchedulerType, hasChanges: boolean) => {
    const { eventList } = this.props;

    if (!eventList) return;

    updateEventList({
      id: eventList.id,
      name,
      ...(hasChanges ? { scheduler } : {}),
    }).catch(showModernMutationError);
    this.handleHideEditViewWindow();
  };

  render() {
    const { eventList, pathPrefix, history, viewerCanUpdate } = this.props;
    const { showOverlay, showEditViewWindow, showConfirmationWindow } = this.state;

    if (!eventList) return null;

    return (
      <Root ref={this.overlayContainerRef}>
        <StyledMenuItem
          to={{
            pathname: `${pathPrefix}/${eventList.id}`,
            state: { key: history.location.key, fromSavedViewMenu: true },
          }}
        >
          <MenuItemLabel title={eventList.name}>{eventList.name}</MenuItemLabel>
        </StyledMenuItem>

        <MenuItemActions ref={this.overlayTargetRef}>
          {viewerCanUpdate && eventList.exportScheduler != null && (
            <ExportedSavedViewTooltipContent
              frequency={eventList.exportScheduler.frequency}
              scheduledOn={eventList.exportScheduler.scheduledOn}
              recipients={eventList.exportScheduler.recipients.edges.map(({ node }) => node)}
              iconColor="#9ca0af"
            />
          )}
          {eventList.shared !== 'PRIVATE' && (
            <StyledTooltip label="Shared view">
              <SharedView />
            </StyledTooltip>
          )}
          <StyledArrowIcon onClick={this.handleShowOverlay} />
        </MenuItemActions>

        <StyledOverlay
          show={showOverlay}
          container={this.overlayContainerRef.current}
          target={this.overlayTargetRef.current}
          forceRightEdge
          onHide={this.handleHideOverlay}
        >
          {eventList.shared !== 'PRIVATE' && (
            <OverlayButton onClick={this.handleOpenPreview}>
              <StyledExternalLinkIcon /> Open shared view
            </OverlayButton>
          )}
          {eventList.viewerCanUpdate && (
            <OverlayButton onClick={this.handleEditViewClick}>
              <EditIcon /> Edit view
            </OverlayButton>
          )}
          <OverlayButton onClick={this.handleClickDuplicate}>
            <DuplicateIcon /> Duplicate view
          </OverlayButton>
          {eventList.viewerCanUpdate && (
            <OverlayButton onClick={this.handleClickDelete}>
              <DeleteIcon /> Delete view
            </OverlayButton>
          )}
        </StyledOverlay>

        {showConfirmationWindow && (
          <ConfirmationWindow
            onHide={this.handleHideConfirmationWindow}
            onConfirm={this.handleDeleteEventList}
            message={
              eventList.shared === 'PRIVATE'
                ? `Once ${eventList.name} is deleted, it's gone for good.`
                : 'This view has been shared. Once it’s deleted, others can’t access it anymore.'
            }
          />
        )}
        {showEditViewWindow && (
          <SavedEventListEditWindow
            editedViewId={eventList.id}
            onUpdate={this.handleSavedEventListUpdate}
            onHide={this.handleHideEditViewWindow}
            viewerCanUpdate={viewerCanUpdate}
          />
        )}
      </Root>
    );
  }
}

export default createFragmentContainer(withRouter(DashboardMenuSavedViewRow), {
  eventList: graphql`
    fragment DashboardMenuSavedViewRow_eventList on EventList {
      id
      name
      shared
      viewerCanUpdate
      publicResourceToken {
        token
      }
      exportScheduler {
        frequency
        scheduledOn
        recipients {
          edges {
            node {
              id
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  `,
  org: graphql`
    fragment DashboardMenuSavedViewRow_org on Org {
      slug
      settings {
        subdomain
      }
    }
  `,
});
