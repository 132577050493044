/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

export const BUTTON_VARIANTS = {
  primary: { base: '#5e5bff', hover: '#403ec9', text: '#fff' },
  danger: { base: '#f25d60', hover: '#f38183', text: '#fff' },
  disabled: { base: '#ccd0d6', hover: '#ccd0d6', text: '#fff' },
  cancel: { base: '#868f96', hover: '#4a5665', text: '#f5f5f5' },
};

export type ButtonVariantType = $Keys<$Diff<typeof BUTTON_VARIANTS, { disabled: any }>>;

const Loader = styled.i`
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  font-size: 17px;
`;

const StyledButton = styled.button`
  position: relative;
  padding: 8px 14px;
  border-radius: ${props => (props.radius == null ? 3 : props.radius)}px;
  background-color: ${props => BUTTON_VARIANTS[props.variant].base};
  cursor: pointer;
  font-size: 13px;
  line-height: 1;
  outline: none;
  color: ${props => BUTTON_VARIANTS[props.variant].text};
  &:hover,
  &:focus {
    background: ${props => BUTTON_VARIANTS[props.variant].hover};
  }
  &:disabled {
    color: ${BUTTON_VARIANTS.disabled.text};
    background: ${BUTTON_VARIANTS.disabled.base};
    cursor: initial;
    &:hover,
    &:focus {
      background: ${BUTTON_VARIANTS.disabled.hover};
    }
  }

  > svg,
  > i:not(${Loader}) {
    margin: 0 5px 0 -4px;
  }

  ${props =>
    props.loading &&
    css`
      > *:not(${Loader}) {
        visibility: hidden;
      }
    `};
`;

export default class DefaultButton extends React.Component<{
  disabled?: boolean,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  children?: React.Node,
  radius?: number,
  svgIcon?: React.ElementType,
  loading?: boolean,
  variant: ButtonVariantType,
  className?: string,
  onMouseLeave?: () => void,
}> {
  static defaultProps = { variant: 'primary' };

  handleClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (this.props.onClick && !this.props.disabled && !this.props.loading) {
      this.props.onClick(e);
      e.currentTarget.blur();
    }
  };

  render() {
    const {
      svgIcon: SvgIcon,
      disabled,
      radius,
      className,
      loading,
      children,
      variant,
      onMouseLeave,
    } = this.props;
    return (
      <StyledButton
        disabled={disabled || loading}
        onClick={this.handleClick}
        radius={radius}
        variant={variant}
        className={className}
        onMouseLeave={onMouseLeave}
        loading={loading ? 1 : 0}
      >
        {loading && <Loader className="fa fa-circle-o-notch fa-spin" />}
        {SvgIcon && <SvgIcon fill={BUTTON_VARIANTS[disabled ? 'disabled' : variant].text} />}
        <span>{children}</span>
      </StyledButton>
    );
  }
}
