/* @flow */
import {
  type EventRequestFormStatusValue,
  submissionStatuses,
} from 'config/eventRequestFormStatuses';

import parseTypedQueryString, {
  type DateRangeParam,
  type SortParam,
  stringParamToDateRange,
  stringParamToEnumArray,
  stringParamToSort,
  stringParamToStringArray,
} from 'utils/routing/parseTypedQueryString';

export type ParsedEventRequestsItemFilters = {|
  sort: ?SortParam,
  requesterUserIds: ?$ReadOnlyArray<string>,
  requesterContactIds: ?$ReadOnlyArray<string>,
  requestedDate: ?DateRangeParam,
  statuses: ?$ReadOnlyArray<$Subtype<EventRequestFormStatusValue>>,
  requestFormIds: ?$ReadOnlyArray<string>,
  requestReviewers: ?$ReadOnlyArray<string>,
|};

export default function parseEventRequestsItemFilters(
  queryString: string,
): ParsedEventRequestsItemFilters {
  return parseTypedQueryString(queryString, {
    sort: stringParamToSort,
    requesterUserIds: stringParamToStringArray,
    requesterContactIds: stringParamToStringArray,
    requestedDate: stringParamToDateRange,
    statuses: stringParamToEnumArray(submissionStatuses.map(s => s.value)),
    requestFormIds: stringParamToStringArray,
    requestReviewers: stringParamToStringArray,
  });
}
