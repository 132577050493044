/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  saveEventBriefMutationVariables,
  saveEventBriefMutationResponse,
} from './__generated__/saveEventBriefMutation.graphql';

const mutation = graphql`
  mutation saveEventBriefMutation($input: SaveEventBriefInput!) {
    saveEventBrief(input: $input) {
      briefSettings {
        id
        accessibility
      }
    }
  }
`;

type Fields = $PropertyType<saveEventBriefMutationVariables, 'input'>;

export default function saveEventBrief(
  briefFields: Fields,
  eventId: string,
): Promise<saveEventBriefMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        ...briefFields,
        eventId,
      },
    },
    optimisticResponse: {
      saveEventBrief: {
        briefSettings: briefFields,
      },
    },
  });
}
