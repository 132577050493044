/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';

import ContactSettingsRoutes from './ContactSettingsRoutes';
import ContactSettingsSubmenu from './ContactSettingsSubmenu';

import { type ContactSettings_org } from './__generated__/ContactSettings_org.graphql';

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  padding-bottom: 100px;
`;

const Title = styled.div`
  margin: 25px 0 21px 25px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const headerTitles = {
  people: 'Contacts',
  attendance: 'Attendance',
  companies: 'Companies',
  vendors: 'Vendors',
  opportunities: 'Opportunities',
};

type Props = {
  org: ContactSettings_org,
  match: Match,
  location: Location,
};

const ContactSettings = ({ org, location, match }: Props) => {
  const title = headerTitles[location.pathname.split('/').pop()] || headerTitles.people;
  return (
    <DocumentTitle title={`${title} - ${org.name}`}>
      <>
        <Title>Contacts Settings</Title>
        <ContactSettingsSubmenu rootPath={match.path} />
        <Container>
          <ContactSettingsRoutes pathPrefix={match.path} rootUrl={match.url} org={org} />
        </Container>
      </>
    </DocumentTitle>
  );
};

export default createFragmentContainer(
  ContactSettings,
  graphql`
    fragment ContactSettings_org on Org {
      name
      ...PeopleSettings_org
      ...OpportunitySettings_org
    }
  `,
);
