/**
 * @flow
 * @relayHash 37e1d119ce6a3dfee5110086f8eefae1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AllTasksPagination_totalCountDeliverables$ref = any;
type MyTasksPagination_totalCountDeliverables$ref = any;
export type ContactSaveMethod = "api" | "g2w" | "import" | "manual" | "past_g2w" | "past_zoom" | "registration" | "zapier" | "zoom";
export type Direction = "ASC" | "DESC";
export type EventFormat = "HYBRID" | "PHYSICAL" | "VIRTUAL";
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventRegistrationFormStatus = "DRAFT" | "NOT_AVAILABLE" | "PUBLISHED";
export type EventRequestSubmissionStatusFilter = "APPROVED" | "DECLINED" | "NEW" | "N_A" | "RESUBMITTED" | "RETURNED";
export type EventSort = "ATTENDED_CONTACTS_TOTAL" | "BOOTH" | "BOOTH_DIMENSIONS" | "CITY" | "COMMITMENT_LEVEL" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATOR" | "CUSTOM" | "END_DATE" | "EVENT_FORMAT" | "EVENT_LEAD" | "LAST_SYNCED" | "LOCATION" | "MARKETO_ID" | "NAME" | "OPPORTUNITIES_AMOUNT" | "OPPORTUNITIES_NUMBER" | "PROGRESS" | "REGISTERED_CONTACTS_TOTAL" | "REQUESTED_BY" | "REQUESTED_DATE" | "REQUESTED_FORM_NAME" | "REQUESTED_STATUS" | "SALESFORCE_ID" | "STAFF_COUNT" | "START_DATE" | "STATE" | "STREET" | "SYNC_STATUS" | "TEAM_NAME" | "TEMPLATES_FIRST" | "TOTAL_ACTUAL_COST" | "TOTAL_PAID_COST" | "TOTAL_PLANNED_COST" | "UPDATED_AT" | "UPDATER" | "VENUE_NAME" | "VIRTUAL_LOCATION" | "WEBSITE" | "ZIP_CODE";
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type TaskSort = "DUE_DATE" | "EVENT_NAME" | "SECTION" | "STATUS" | "TITLE";
export type TaskStatus = "COMPLETED" | "OPEN";
export type RemoveDeliverablesInput = {
  clientMutationId?: ?string,
  deliverableIds: $ReadOnlyArray<string>,
  fromWindow?: ?string,
};
export type DeliverableFilters = {
  query?: ?string,
  eventIds?: ?$ReadOnlyArray<string>,
  tagIds?: ?$ReadOnlyArray<string>,
  assigneeIds?: ?$ReadOnlyArray<string>,
  teamIds?: ?$ReadOnlyArray<string>,
  statuses?: ?$ReadOnlyArray<TaskStatus>,
  createdMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  dueDate?: ?DateTimeRange,
  eventFilters?: ?EventFilters,
  sort?: ?TaskSort,
  direction?: ?Direction,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type EventFilters = {
  sort?: ?EventSort,
  direction?: ?Direction,
  customFieldSortId?: ?string,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  queries?: ?$ReadOnlyArray<string>,
  search?: ?Search,
  listType?: ?EventListEnum,
  afterDate?: ?any,
  beforeDate?: ?any,
  includeUndated?: ?boolean,
  excludeTemplates?: ?boolean,
  statuses?: ?$ReadOnlyArray<EventStatus>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  opportunitiesNumber?: ?NumberRangeInput,
  opportunitiesAmount?: ?NumberRangeInput,
  teamIds?: ?$ReadOnlyArray<string>,
  leadIds?: ?$ReadOnlyArray<string>,
  staffIds?: ?$ReadOnlyArray<string>,
  budgetCategoryIds?: ?$ReadOnlyArray<string>,
  fullAccessEvents?: ?boolean,
  onlyWithTasks?: ?boolean,
  venueNames?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  eventFormats?: ?$ReadOnlyArray<EventFormat>,
  eventIds?: ?$ReadOnlyArray<string>,
  excludeEventIds?: ?$ReadOnlyArray<string>,
  updatedAt?: ?DateTimeRange,
  updaterIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  creatorIds?: ?$ReadOnlyArray<string>,
  requesterUserIds?: ?$ReadOnlyArray<string>,
  requesterContactIds?: ?$ReadOnlyArray<string>,
  requestedDate?: ?DateTimeRange,
  requestStatuses?: ?$ReadOnlyArray<EventRequestSubmissionStatusFilter>,
  requestFormIds?: ?$ReadOnlyArray<string>,
  requestReviewers?: ?$ReadOnlyArray<string>,
  registeredContactsTotal?: ?NumberRangeInput,
  attendedContactsTotal?: ?NumberRangeInput,
  registrationFormStatuses?: ?$ReadOnlyArray<EventRegistrationFormStatus>,
  contactsCount?: ?NumberRangeInput,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type removeTasksMutationVariables = {|
  input: RemoveDeliverablesInput,
  hasFilters: boolean,
  filters?: ?DeliverableFilters,
|};
export type removeTasksMutationResponse = {|
  +removeDeliverables: ?{|
    +org: {|
      +$fragmentRefs: AllTasksPagination_totalCountDeliverables$ref
    |},
    +user: {|
      +$fragmentRefs: MyTasksPagination_totalCountDeliverables$ref
    |},
    +event: {|
      +progress: ?number
    |},
    +removedDeliverableIds: $ReadOnlyArray<string>,
    +removedDeliverables: $ReadOnlyArray<{|
      +dbId: number,
      +name: string,
      +parent: ?{|
        +subtasksCount: number,
        +completedSubtasksCount: number,
      |},
    |}>,
  |}
|};
export type removeTasksMutation = {|
  variables: removeTasksMutationVariables,
  response: removeTasksMutationResponse,
|};
*/


/*
mutation removeTasksMutation(
  $input: RemoveDeliverablesInput!
  $hasFilters: Boolean!
  $filters: DeliverableFilters
) {
  removeDeliverables(input: $input) {
    org {
      ...AllTasksPagination_totalCountDeliverables @include(if: $hasFilters)
      id
    }
    user {
      ...MyTasksPagination_totalCountDeliverables @include(if: $hasFilters)
      id
    }
    event {
      progress
      id
    }
    removedDeliverableIds
    removedDeliverables {
      dbId
      name
      parent {
        subtasksCount
        completedSubtasksCount
        id
      }
      id
    }
  }
}

fragment AllTasksPagination_totalCountDeliverables on Org {
  deliverablesCount: deliverables(filters: $filters) {
    totalCount
  }
}

fragment MyTasksPagination_totalCountDeliverables on User {
  deliverablesCount: deliverables(filters: $filters) {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveDeliverablesInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hasFilters",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "DeliverableFilters",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveDeliverablesInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "progress",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedDeliverableIds",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtasksCount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completedSubtasksCount",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = [
  v8,
  {
    "kind": "Condition",
    "passingValue": true,
    "condition": "hasFilters",
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "deliverablesCount",
        "name": "deliverables",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filters",
            "variableName": "filters",
            "type": "DeliverableFilters"
          }
        ],
        "concreteType": "DeliverableRequiredConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeTasksMutation",
  "id": null,
  "text": "mutation removeTasksMutation(\n  $input: RemoveDeliverablesInput!\n  $hasFilters: Boolean!\n  $filters: DeliverableFilters\n) {\n  removeDeliverables(input: $input) {\n    org {\n      ...AllTasksPagination_totalCountDeliverables @include(if: $hasFilters)\n      id\n    }\n    user {\n      ...MyTasksPagination_totalCountDeliverables @include(if: $hasFilters)\n      id\n    }\n    event {\n      progress\n      id\n    }\n    removedDeliverableIds\n    removedDeliverables {\n      dbId\n      name\n      parent {\n        subtasksCount\n        completedSubtasksCount\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment AllTasksPagination_totalCountDeliverables on Org {\n  deliverablesCount: deliverables(filters: $filters) {\n    totalCount\n  }\n}\n\nfragment MyTasksPagination_totalCountDeliverables on User {\n  deliverablesCount: deliverables(filters: $filters) {\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeTasksMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeDeliverables",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveDeliverablesPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "hasFilters",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "AllTasksPagination_totalCountDeliverables",
                    "args": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "hasFilters",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "MyTasksPagination_totalCountDeliverables",
                    "args": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v2
            ]
          },
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "removedDeliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": true,
            "selections": [
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "parent",
                "storageKey": null,
                "args": null,
                "concreteType": "Deliverable",
                "plural": false,
                "selections": [
                  v6,
                  v7
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeTasksMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeDeliverables",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveDeliverablesPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": v9
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": v9
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v2,
              v8
            ]
          },
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "removedDeliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": true,
            "selections": [
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "parent",
                "storageKey": null,
                "args": null,
                "concreteType": "Deliverable",
                "plural": false,
                "selections": [
                  v6,
                  v7,
                  v8
                ]
              },
              v8
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f5b19903316b81b01614a4fa5ca6ab6c';
module.exports = node;
