/**
 * @flow
 * @relayHash b0349329a6927f0cc5ef9d4584d47bf0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CategoriesTable_categoryEdges$ref = any;
export type CreateEventBudgetCategoryInput = {
  clientMutationId?: ?string,
  eventId: string,
  categoryId: string,
  budgetedAmount?: ?number,
};
export type createEventBudgetCategoryMutationVariables = {|
  input: CreateEventBudgetCategoryInput
|};
export type createEventBudgetCategoryMutationResponse = {|
  +createEventBudgetCategory: ?{|
    +eventBudgetCategoryEdge: {|
      +$fragmentRefs: CategoriesTable_categoryEdges$ref
    |},
    +event: {|
      +totalBudgetedAmount: number,
      +budgetCategories: {|
        +totalCount: number
      |},
    |},
  |}
|};
export type createEventBudgetCategoryMutation = {|
  variables: createEventBudgetCategoryMutationVariables,
  response: createEventBudgetCategoryMutationResponse,
|};
*/


/*
mutation createEventBudgetCategoryMutation(
  $input: CreateEventBudgetCategoryInput!
) {
  createEventBudgetCategory(input: $input) {
    eventBudgetCategoryEdge {
      ...CategoriesTable_categoryEdges
    }
    event {
      totalBudgetedAmount
      budgetCategories {
        totalCount
      }
      id
    }
  }
}

fragment CategoriesTable_categoryEdges on EventBudgetCategoryEdge {
  budgetedAmount
  actualAmount
  paidAmount
  ...CategoryNameCell_categoryEdge
  ...CategoryBudgetedAmountCell_categoryEdge
  ...CategoryActualAmountCell_categoryEdge
  ...CategoryActualOfBudgetedCell_categoryEdge
  ...CategoryPaidAmountCell_categoryEdge
  ...CategoryPaidOfActualCell_categoryEdge
  ...CategoryActionCell_categoryEdge
  node {
    id
    name
  }
}

fragment CategoryNameCell_categoryEdge on EventBudgetCategoryEdge {
  budgetedAmount
  node {
    id
    name
  }
}

fragment CategoryBudgetedAmountCell_categoryEdge on EventBudgetCategoryEdge {
  budgetedAmount
}

fragment CategoryActualAmountCell_categoryEdge on EventBudgetCategoryEdge {
  actualAmount
}

fragment CategoryActualOfBudgetedCell_categoryEdge on EventBudgetCategoryEdge {
  budgetedAmount
  actualAmount
}

fragment CategoryPaidAmountCell_categoryEdge on EventBudgetCategoryEdge {
  paidAmount
}

fragment CategoryPaidOfActualCell_categoryEdge on EventBudgetCategoryEdge {
  actualAmount
  paidAmount
}

fragment CategoryActionCell_categoryEdge on EventBudgetCategoryEdge {
  node {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateEventBudgetCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateEventBudgetCategoryInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalBudgetedAmount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "budgetCategories",
  "storageKey": null,
  "args": null,
  "concreteType": "EventBudgetCategoryConnection",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createEventBudgetCategoryMutation",
  "id": null,
  "text": "mutation createEventBudgetCategoryMutation(\n  $input: CreateEventBudgetCategoryInput!\n) {\n  createEventBudgetCategory(input: $input) {\n    eventBudgetCategoryEdge {\n      ...CategoriesTable_categoryEdges\n    }\n    event {\n      totalBudgetedAmount\n      budgetCategories {\n        totalCount\n      }\n      id\n    }\n  }\n}\n\nfragment CategoriesTable_categoryEdges on EventBudgetCategoryEdge {\n  budgetedAmount\n  actualAmount\n  paidAmount\n  ...CategoryNameCell_categoryEdge\n  ...CategoryBudgetedAmountCell_categoryEdge\n  ...CategoryActualAmountCell_categoryEdge\n  ...CategoryActualOfBudgetedCell_categoryEdge\n  ...CategoryPaidAmountCell_categoryEdge\n  ...CategoryPaidOfActualCell_categoryEdge\n  ...CategoryActionCell_categoryEdge\n  node {\n    id\n    name\n  }\n}\n\nfragment CategoryNameCell_categoryEdge on EventBudgetCategoryEdge {\n  budgetedAmount\n  node {\n    id\n    name\n  }\n}\n\nfragment CategoryBudgetedAmountCell_categoryEdge on EventBudgetCategoryEdge {\n  budgetedAmount\n}\n\nfragment CategoryActualAmountCell_categoryEdge on EventBudgetCategoryEdge {\n  actualAmount\n}\n\nfragment CategoryActualOfBudgetedCell_categoryEdge on EventBudgetCategoryEdge {\n  budgetedAmount\n  actualAmount\n}\n\nfragment CategoryPaidAmountCell_categoryEdge on EventBudgetCategoryEdge {\n  paidAmount\n}\n\nfragment CategoryPaidOfActualCell_categoryEdge on EventBudgetCategoryEdge {\n  actualAmount\n  paidAmount\n}\n\nfragment CategoryActionCell_categoryEdge on EventBudgetCategoryEdge {\n  node {\n    id\n    name\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createEventBudgetCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createEventBudgetCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateEventBudgetCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventBudgetCategoryEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "EventBudgetCategoryEdge",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "CategoriesTable_categoryEdges",
                "args": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createEventBudgetCategoryMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createEventBudgetCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateEventBudgetCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventBudgetCategoryEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "EventBudgetCategoryEdge",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "budgetedAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "actualAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "paidAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "BudgetCategory",
                "plural": false,
                "selections": [
                  v4,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '15d33924ff9ebe01fcaf9db55e15bbb4';
module.exports = node;
