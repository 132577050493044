/* @flow */
import * as React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import Button from 'components/material/Button';
import TextField from 'components/material/TextField';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';

const Buttons = styled.div`
  padding: 5px 15px 15px 15px;
  text-align: right;
  > button {
    margin-left: 6px;
  }
`;

export default class TextareaModal extends React.PureComponent<{|
  value: string,
  onChange: string => any,
  placeholder?: string,
  disabled?: boolean,
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  onSave: () => void,
  onHide: () => void,
  error: ?string,
|}> {
  render() {
    const { onChange, onSave, onHide, placeholder, value, disabled, error } = this.props;

    return (
      document.body &&
      createPortal(
        <Window size="medium" onHide={onHide} onClick={e => e.stopPropagation()}>
          <WindowHeader>
            <WindowTitle>{placeholder}</WindowTitle>
            <WindowClose onClick={onHide} />
          </WindowHeader>
          <WindowContent>
            <TextField
              autoFocus
              multiline
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              error={error}
            />
          </WindowContent>
          {!disabled && (
            <Buttons>
              <Button label="Cancel" outline onClick={onHide} />
              <Button label="Save" primary onClick={onSave} />
            </Buttons>
          )}
        </Window>,
        document.body,
      )
    );
  }
}
