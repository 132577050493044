/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import Content from '../layout';
import ReportingRoutes from './ReportingRoutes';

import { type ReportingQueryResponse } from './__generated__/ReportingQuery.graphql';

const query = graphql`
  query ReportingQuery {
    org {
      ...ReportingRoutes_org
    }
  }
`;

const headerTitles = {
  budget_reporting: 'Budget Reporting',
  budget_imports: 'Expense imports',
  budget_settings: 'Budget settings',
};

type Props = { location: Location, match: Match };

const Reporting = ({ match, location }: Props) => {
  const title = headerTitles[location.pathname.split('/').pop()] || headerTitles.budget_reporting;

  return (
    <DocumentTitle title={title}>
      <Content>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(response: ReportingQueryResponse) => (
            <ReportingRoutes org={response.org} pathPrefix={match.path} />
          )}
        />
      </Content>
    </DocumentTitle>
  );
};

export default Reporting;
