/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import eventStates, { type EventStateGraphValue, getEventStateLabel } from 'config/eventStates';

import { type Event } from 'graph/mutations/event/updateEvent';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineSelectField from 'components/budget/Table/InlineSelectField';
import LockIcon from 'components/LockIcon';

import type { EventStatus_event } from './__generated__/EventStatus_event.graphql';

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 12px;
`;

type Option = {|
  label: string,
  value: EventStateGraphValue,
|};

class EventStatus extends React.Component<
  {
    event: EventStatus_event,
    readOnly?: boolean,
    updateColumnWidth: () => void,
    onUpdate?: (changedProps: $Shape<Event>) => Promise<void>,
  },
  {
    shown: boolean,
  },
> {
  handleChangeStatus = status => {
    const { updateColumnWidth, onUpdate } = this.props;
    if (onUpdate) {
      onUpdate({ status });
      updateColumnWidth();
    }
  };

  render() {
    const {
      readOnly,
      event: { commitmentLevel, statusMappedToSalesforce },
    } = this.props;

    if (readOnly || statusMappedToSalesforce) {
      return (
        <InlineExpandableText>
          {getEventStateLabel(commitmentLevel)}
          {!readOnly && statusMappedToSalesforce && <StyledLockIcon label="Salesforce" />}
        </InlineExpandableText>
      );
    }

    const options: $ReadOnlyArray<Option> = eventStates.map(state => ({
      label: state.label,
      value: state.graphValue,
    }));

    return (
      <InlineSelectField
        options={options}
        value={commitmentLevel}
        onChange={this.handleChangeStatus}
        clearable={false}
      />
    );
  }
}

export default createFragmentContainer(
  EventStatus,
  graphql`
    fragment EventStatus_event on Event {
      id
      commitmentLevel
      statusMappedToSalesforce: mappedToSalesforce(field: STATE)
    }
  `,
);
