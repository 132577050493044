/* @flow */
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';
import pickBy from 'lodash/pickBy';

import eventTabs from 'config/eventTabs';

import FeatureAccessContext from 'contexts/FeatureAccess';

import ErrorBoundary from 'components/ErrorBoundary';
import { Container } from 'components/page/Container';

import EventHeader from './EventHeader';
import EventMenu from './EventMenu';
import EventRoutes from './EventRoutes';

import type { EventContainer_event } from './__generated__/EventContainer_event.graphql';
import type { EventContainer_org } from './__generated__/EventContainer_org.graphql';
import type { EventContainer_user } from './__generated__/EventContainer_user.graphql';

const EventHeaderContainer = styled.div`
  background-color: rgba(246, 242, 255, 0.53);
`;

class EventContainer extends React.Component<{
  event: EventContainer_event,
  org: EventContainer_org,
  user: EventContainer_user,
  match: Match,
  location: Location,
  onToggleSidebar: () => void,
}> {
  static contextType = FeatureAccessContext;

  render() {
    const { org, user, event, match, location, onToggleSidebar } = this.props;
    const savedShownTabs = event.shownTabs;
    const shownTabs: $ReadOnlyArray<string> =
      savedShownTabs.length === 0
        ? eventTabs
            .filter(tab => {
              if (tab.disabled) return false;

              if (tab.hideByDefault) return tab.value === 'VENDORS' && !this.context.legacyFeatures;

              return !(tab.value === 'SCHEDULE' && !this.context.legacyFeatures);
            })
            .map(tab => tab.value)
        : savedShownTabs;
    const disabledTabs = Object.keys(
      pickBy(
        {
          BUDGET: event.viewerCanViewBudget,
          CONTACTS: event.viewerCanViewContacts && this.context.legacyFeatures,
          ATTACHMENTS: event.viewerCanViewAttachments,
          REGISTRATION: event.viewerCanViewRegistration && this.context.legacyFeatures,
          ROI: this.context.legacyFeatures,
        },
        x => !x,
      ),
    );

    return (
      <DocumentTitle title={event.name}>
        <Container>
          <EventHeaderContainer>
            <EventHeader event={event} user={user} onToggleSidebar={onToggleSidebar} />
            <EventMenu
              event={event}
              location={location} // necessary for NavLinks to update
              shownTabs={shownTabs}
              disabledTabs={disabledTabs}
            />
          </EventHeaderContainer>

          <ErrorBoundary location={location}>
            <EventRoutes
              pathPrefix={match.path}
              rootUrl={match.url}
              eventSlug={match.params.event_slug || ''}
              fiscalYearStart={org.settings.fiscalYearStart}
              disabledTabs={disabledTabs}
            />
          </ErrorBoundary>
        </Container>
      </DocumentTitle>
    );
  }
}

export default createFragmentContainer(EventContainer, {
  event: graphql`
    fragment EventContainer_event on Event {
      name
      viewerCanUpdate
      shownTabs
      viewerCanViewBudget
      viewerCanViewContacts
      viewerCanViewAttachments
      viewerCanViewRegistration
      ...EventHeader_event
      ...EventMenu_event
    }
  `,
  org: graphql`
    fragment EventContainer_org on Org {
      settings {
        fiscalYearStart
      }
    }
  `,
  user: graphql`
    fragment EventContainer_user on User {
      ...EventHeader_user
    }
  `,
});
