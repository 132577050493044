/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  addCampaignToEventMutationResponse,
  addCampaignToEventMutationVariables,
} from './__generated__/addCampaignToEventMutation.graphql';

const mutation = graphql`
  mutation addCampaignToEventMutation($input: AddCampaignToEventInput!) {
    addCampaignToEvent(input: $input) {
      event {
        salesforceCampaign {
          id
          name
          campaignSfdcid
          campaignUrl
        }
        pullStatus {
          state
          errorMessage
          lastSuccessAt
        }
        pushStatus {
          state
          errorMessage
          lastSuccessAt
        }
        syncedContacts: eventContacts(filters: { membershipSyncStatuses: [OK] }) {
          totalCount
        }
        disabledContacts: eventContacts(filters: { membershipSyncStatuses: [DISABLED] }) {
          totalCount
        }
        failedContacts: eventContacts(filters: { membershipSyncStatuses: [FAILING] }) {
          totalCount
        }
        pendingContacts: eventContacts(filters: { membershipSyncStatuses: [PENDING] }) {
          totalCount
        }
      }
    }
  }
`;

export default function addCampaignToEvent(args: {
  eventId: string,
  salesforceCampaignId: string,
}): Promise<addCampaignToEventMutationResponse> {
  const variables: addCampaignToEventMutationVariables = {
    input: {
      ...args,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
