/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 30px 0 40px;
  text-align: center;
  font-size: 11px;
`;

const Title = styled.div`
  margin-bottom: 3px;
  font-weight: 500;
  color: black;
`;

type Props = { query: string };

const NoSearchResultsMessage = ({ query }: Props) => {
  return (
    <Container>
      <Title>NO RESULTS</Title>
      Sorry, we couldn&apos;t find any data for &ldquo;{query}&rdquo;
    </Container>
  );
};

export default NoSearchResultsMessage;
