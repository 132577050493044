/**
 * @flow
 * @relayHash 6299571246e4127490b142620d7f9470
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RegisterEventContactInput = {
  clientMutationId?: ?string,
  publicRuleName: string,
  resourceToken: string,
  wmSSOToken?: ?string,
  orgSlug?: ?string,
  publishedAt: any,
  contact: ContactFormInput,
  company?: ?CompanyForm,
  customFieldValues?: ?$ReadOnlyArray<CustomFieldValueInput>,
};
export type ContactFormInput = {
  firstName?: ?string,
  lastName?: ?string,
  ownerId?: ?string,
  contactTypeId?: ?string,
  companyName?: ?string,
  title?: ?string,
  email?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  website?: ?string,
  twitter?: ?string,
  linkedin?: ?string,
  description?: ?string,
  country?: ?string,
  state?: ?string,
  city?: ?string,
  zip?: ?string,
  street?: ?string,
  hotLead?: ?boolean,
  emailOptIn?: ?boolean,
  contactTypeOther?: ?string,
};
export type CompanyForm = {
  name: string,
  phone?: ?string,
  website?: ?string,
  twitter?: ?string,
  linkedin?: ?string,
  description?: ?string,
  country?: ?string,
  state?: ?string,
  city?: ?string,
  zip?: ?string,
  street?: ?string,
};
export type CustomFieldValueInput = {
  customFieldId: string,
  textValue?: ?string,
  textareaValue?: ?string,
  linkValue?: ?string,
  numberValue?: ?number,
  currencyValue?: ?number,
  dateValue?: ?any,
  booleanValue?: ?boolean,
  userValue?: ?string,
  userValues?: ?$ReadOnlyArray<string>,
  selectValue?: ?string,
  selectOtherValue?: ?string,
  selectValues?: ?$ReadOnlyArray<string>,
};
export type registerEventContactMutationVariables = {|
  input: RegisterEventContactInput
|};
export type registerEventContactMutationResponse = {|
  +registerEventContact: ?{|
    +clientMutationId: ?string
  |}
|};
export type registerEventContactMutation = {|
  variables: registerEventContactMutationVariables,
  response: registerEventContactMutationResponse,
|};
*/


/*
mutation registerEventContactMutation(
  $input: RegisterEventContactInput!
) {
  registerEventContact(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RegisterEventContactInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "registerEventContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RegisterEventContactInput!"
      }
    ],
    "concreteType": "RegisterEventContactPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "registerEventContactMutation",
  "id": null,
  "text": "mutation registerEventContactMutation(\n  $input: RegisterEventContactInput!\n) {\n  registerEventContact(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "registerEventContactMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "registerEventContactMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa998f03edf57800fc673b94fe728ea8';
module.exports = node;
