/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';
import isValidWebsite from 'utils/validators/isValidWebsite';

import { type Event } from 'graph/mutations/event/updateEvent';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import StyledAnchor from './components/StyledAnchor';

import type { EventVirtualLocation_event } from './__generated__/EventVirtualLocation_event.graphql';

class EventVirtualLocation extends React.PureComponent<{
  event: EventVirtualLocation_event,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdate?: (changedProps: $Shape<Event>) => Promise<void>,
}> {
  handleUpdate = (value: ?string) => {
    const { updateColumnWidth, onUpdate } = this.props;
    if (value !== this.value() && onUpdate) {
      onUpdate({
        virtualLocation: value,
        zoomLocation: null,
        g2wLocation: null,
      });
      updateColumnWidth();
    }
  };

  value = () => {
    const { zoomLocation, g2wLocation, virtualLocation } = this.props.event;

    if (zoomLocation) return zoomLocation.joinUrl;
    if (g2wLocation) return g2wLocation.registrationUrl;
    return virtualLocation || '';
  };

  render() {
    const {
      fieldSettings,
      readOnly,
      event: { virtualLocation, zoomLocation, g2wLocation },
    } = this.props;

    const value = this.value();
    const component = isValidWebsite(value) ? <StyledAnchor href={value} title={value} /> : value;

    if (readOnly || zoomLocation || g2wLocation) {
      return <InlineExpandableText>{component || null}</InlineExpandableText>;
    }

    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={virtualLocation || ''}
        onChange={this.handleUpdate}
      >
        <InlineExpandableText>{component}</InlineExpandableText>
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  EventVirtualLocation,
  graphql`
    fragment EventVirtualLocation_event on Event {
      virtualLocation
      zoomLocation {
        joinUrl
      }
      g2wLocation {
        registrationUrl
      }
    }
  `,
);
