/* @flow */
import React from 'react';

import COUNTRIES from 'config/countries.json';
import US_STATES from 'config/us_states.json';

import { type FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';

import { type CreateCompanyFromWindow } from 'graph/mutations/company/createCompany';
import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';

import CheckBox from 'components/EventRequestForm/form/CheckBox';
import EditableLinkField from 'components/material/EditableLinkField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import CompanySearch from 'components/Participants/CompanySearch';
import UserSelect from 'components/UserSelect';

import { type ContactDefaultFields } from './ContactFormFields';

import { type CustomFieldKindType } from './__generated__/ContactProfile_contact.graphql';

type Props = {
  contact: ContactDefaultFields,
  // eslint-disable-next-line
  viewerCanUpdate: boolean,
  handleUpdate: (changes: $Shape<ContactDefaultFields>) => void,
  // eslint-disable-next-line
  onCompanyLinkClick: () => void,
  field: FieldType,
  // eslint-disable-next-line
  currency?: string,
  // eslint-disable-next-line
  fromWindow: CreateContactFromWindow | CreateCompanyFromWindow,
  errors: { [string]: string },
};

const countries = COUNTRIES.map(country => ({ label: country, value: country }));
const usStates = Object.keys(US_STATES).map(k => ({
  label: US_STATES[k],
  value: US_STATES[k],
}));
// "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "USER_MULTISELECT" | "USER_SELECT";
export const suggestedFieldsDataType: { [string]: CustomFieldKindType } = {
  title: 'TEXT',
  owner_id: 'USER_SELECT',
  contact_type_id: 'SELECT',
  street: 'TEXT',
  city: 'TEXT',
  description: 'TEXT',
  company_id: 'SELECT',
  zip: 'TEXT',
  hot_lead: 'BOOLEAN',
  email_opt_in: 'BOOLEAN',
  website: 'LINK',
  twitter: 'LINK',
  linkedin: 'LINK',
  phone1: 'LINK',
  phone2: 'LINK',
  state: 'TEXT', // TODO: when used also should be considered united states case when type is SELECT
  country: 'SELECT',
  registration_status_id: 'SELECT',
  attendance_status_id: 'SELECT',
};

const suggestedFields = {
  title: ({ contact, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={contact.title}
      onBlur={e => {
        if (contact.title !== e.currentTarget.value) {
          handleUpdate({ title: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.title}
      disabled={!viewerCanUpdate}
    />
  ),
  owner_id: ({ contact, handleUpdate, field, viewerCanUpdate, errors }: Props) => {
    return (
      <UserSelect
        label={field.label}
        user={contact.owner}
        onSelect={owner => {
          if (
            (owner && !contact.owner) ||
            (!owner && contact.owner) ||
            (owner && contact.owner && contact.owner.id !== owner.id)
          ) {
            handleUpdate({ owner });
          }
        }}
        disabled={!viewerCanUpdate}
        required={field.required}
        error={errors.owner_id || errors.owner}
        onHideOptions={ownerId => {
          if (!contact.owner && !ownerId) {
            handleUpdate({ owner: null });
          }
        }}
        clearable
      />
    );
  },
  contact_type_id: ({ contact, handleUpdate, field, viewerCanUpdate, errors }: Props) => {
    return (
      <SelectField
        label={field.label}
        value={contact.contactTypeId}
        onChange={contactTypeId => {
          if (contact.contactTypeId !== contactTypeId) {
            handleUpdate({ contactTypeId });
          }
        }}
        options={field.options}
        searchable
        clearable={!field.required}
        disabled={!viewerCanUpdate}
        required={field.required}
        error={errors.contact_type_id || errors.contactTypeId}
        onHideOptions={contactTypeId => {
          if (!contact.contactTypeId && contactTypeId == null) {
            handleUpdate({ contactTypeId: null });
          }
        }}
      />
    );
  },
  street: ({ contact, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={contact.street}
      onBlur={e => {
        if (contact.street !== e.currentTarget.value) {
          handleUpdate({ street: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.street}
      disabled={!viewerCanUpdate}
    />
  ),
  city: ({ contact, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={contact.city}
      onBlur={e => {
        if (contact.city !== e.currentTarget.value) {
          handleUpdate({ city: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.city}
      disabled={!viewerCanUpdate}
    />
  ),
  description: ({ contact, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      multiline
      defaultValue={contact.description}
      onBlur={e => {
        if (contact.description !== e.currentTarget.value) {
          handleUpdate({ description: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.description}
      disabled={!viewerCanUpdate}
    />
  ),
  company_id: ({
    contact,
    handleUpdate,
    field,
    viewerCanUpdate,
    fromWindow,
    errors,
    onCompanyLinkClick,
  }: Props) => (
    <CompanySearch
      label={field.label}
      name={field.fieldName || ''}
      company={contact.company}
      onSelect={(company, contactType) => {
        if (
          contactType === 'companies' &&
          ((company && !contact.company) ||
            (!company && contact.company) ||
            (company && contact.company && contact.company.id !== company.id))
        ) {
          handleUpdate({ company });
        }
      }}
      disabled={!viewerCanUpdate}
      required={field.required}
      fromWindow={fromWindow}
      clearable
      stateful
      salesforceId={contact.company && contact.company.salesforceId}
      error={errors.company_id || errors.company}
      onCompanyLinkClick={onCompanyLinkClick}
    />
  ),
  zip: ({ contact, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={contact.zip}
      onBlur={e => {
        if (contact.zip !== e.currentTarget.value) {
          handleUpdate({ zip: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.zip}
      disabled={!viewerCanUpdate}
    />
  ),
  hot_lead: ({ contact, handleUpdate, field, viewerCanUpdate, errors }: Props) => (
    <CheckBox
      compact
      checked={!!contact.hotLead}
      label={field.label}
      onChange={() => handleUpdate({ hotLead: !contact.hotLead })}
      required={field.required}
      disabled={!viewerCanUpdate}
      error={errors.hot_lead}
    />
  ),
  email_opt_in: ({ contact, handleUpdate, field, viewerCanUpdate, errors }: Props) => (
    <CheckBox
      compact
      checked={!!contact.emailOptIn}
      label={field.label}
      onChange={() => handleUpdate({ emailOptIn: !contact.emailOptIn })}
      disabled={!viewerCanUpdate}
      error={errors.email_opt_in}
      required={field.required}
    />
  ),
  website: ({ contact, handleUpdate, field, viewerCanUpdate, errors }: Props) => (
    <EditableLinkField
      defaultValue={contact.website}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== contact.website) {
          e.currentTarget.value = value;
          handleUpdate({ website: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.website}
    />
  ),
  twitter: ({ contact, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={contact.twitter}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== contact.twitter) {
          e.currentTarget.value = value;
          handleUpdate({ twitter: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.twitter}
    />
  ),
  linkedin: ({ contact, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={contact.linkedin}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== contact.linkedin) {
          e.currentTarget.value = value;
          handleUpdate({ linkedin: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.linkedin}
    />
  ),
  phone1: ({ contact, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={contact.phone1}
      onBlur={e => {
        const value = e.currentTarget.value.trim();
        if (contact.phone1 !== value) {
          handleUpdate({ phone1: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.phone1}
      to={`tel:${contact.phone1 || ''}`}
    />
  ),
  phone2: ({ contact, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={contact.phone2}
      onBlur={e => {
        const value = e.currentTarget.value.trim();
        if (contact.phone2 !== value) {
          handleUpdate({ phone2: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.phone2}
      to={`tel:${contact.phone2 || ''}`}
    />
  ),
  state: ({ contact, handleUpdate, viewerCanUpdate, field, errors }: Props) =>
    contact.country === 'United States' ? (
      <SelectField
        onChange={state => {
          if (state !== contact.state) {
            handleUpdate({ state });
          }
        }}
        label={field.label}
        value={contact.state}
        options={usStates}
        searchable
        clearable
        disabled={!viewerCanUpdate}
        required={field.required}
        error={errors.state}
      />
    ) : (
      <TextField
        defaultValue={contact.state}
        onBlur={e => {
          if (contact.state !== e.currentTarget.value) {
            handleUpdate({ state: e.currentTarget.value });
          }
        }}
        label={field.label}
        name={field.fieldName || ''}
        required={field.required}
        error={errors.state}
        disabled={!viewerCanUpdate}
      />
    ),
  country: ({ contact, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <SelectField
      label={field.label}
      value={contact.country}
      onChange={country => {
        if (country !== contact.country) {
          handleUpdate({ country });
        }
      }}
      options={countries}
      searchable
      clearable
      disabled={!viewerCanUpdate}
      required={field.required}
      error={errors.country}
    />
  ),
  registration_status_id: ({ contact, handleUpdate, field, viewerCanUpdate, errors }: Props) => {
    return (
      <SelectField
        label={field.label}
        value={contact.registrationStatusId}
        onChange={registrationStatusId => {
          if (contact.registrationStatusId !== registrationStatusId) {
            handleUpdate({ registrationStatusId });
          }
        }}
        options={field.options}
        searchable
        clearable={!field.required}
        disabled={!viewerCanUpdate}
        required={field.required}
        error={errors.registration_status_id}
      />
    );
  },
  attendance_status_id: ({ contact, handleUpdate, field, viewerCanUpdate, errors }: Props) => {
    return (
      <SelectField
        label={field.label}
        value={contact.attendanceStatusId}
        onChange={attendanceStatusId => {
          if (contact.attendanceStatusId !== attendanceStatusId) {
            handleUpdate({ attendanceStatusId });
          }
        }}
        options={field.options}
        searchable
        clearable={!field.required}
        disabled={!viewerCanUpdate}
        required={field.required}
        error={errors.attendance_status_id}
      />
    );
  },
};

export default suggestedFields;
