/* @flow */

import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import { defaultTableComponents } from 'components/Contacts/columns';
import { type AvailableContactsType } from 'components/Contacts/ContactsMassUpdatePanel';
import colsConfig, {
  type InputVariableFilters,
  getColumnsShowConfig,
} from 'components/Contacts/contactsTableColumnSettings';
import getContactFields from 'components/Contacts/lib/getContactFields';
import parseContactsFilters from 'components/Contacts/lib/parseContactsFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { type ColumnConfiguration } from 'components/material/table';

import ContactsHeaderBar from './components/ContactsHeaderBar';
import ContactsTablePagination from './ContactsTablePagination';

import type { ContactsPage_event } from './__generated__/ContactsPage_event.graphql';
import type { ContactsPage_org } from './__generated__/ContactsPage_org.graphql';
import type { ContactsPageQueryResponse } from './__generated__/ContactsPageQuery.graphql';

const query = graphql`
  query ContactsPageQuery(
    $eventSlug: String!
    $count: Int!
    $cursor: String
    $filters: ContactFilters!
    $includeName: Boolean!
    $includeEmail: Boolean!
    $includeSalesforceSyncAs: Boolean!
    $includeSalesforceLeadStatusId: Boolean!
    $includeSalesforceId: Boolean!
    $includeMarketoId: Boolean!
    $includeLastSynced: Boolean!
    $includeSyncStatus: Boolean!
    $includeEvents: Boolean!
    $includeRequestSubmissions: Boolean!
    $includeContactTypeId: Boolean!
    $includeCompanyId: Boolean!
    $includeTitle: Boolean!
    $includeOwnerId: Boolean!
    $includeWebsite: Boolean!
    $includePhone1: Boolean!
    $includePhone2: Boolean!
    $includeStreet: Boolean!
    $includeCity: Boolean!
    $includeState: Boolean!
    $includeZip: Boolean!
    $includeCountry: Boolean!
    $includeTwitter: Boolean!
    $includeLinkedin: Boolean!
    $includeDescription: Boolean!
    $includeHotLead: Boolean!
    $includeEmailOptIn: Boolean!
    $includeUpdatedAt: Boolean!
    $includeUpdatedBy: Boolean!
    $includeUpdatedMethod: Boolean!
    $includeCreatedAt: Boolean!
    $includeCreatedBy: Boolean!
    $includeCreatedMethod: Boolean!
    $includeRegistrationStatusId: Boolean!
    $includeRegisteredAt: Boolean!
    $includeAttendanceStatusId: Boolean!
    $includeAttendanceJoinTime: Boolean!
    $includeAttendanceLeaveTime: Boolean!
    $includeAttendanceDuration: Boolean!
    $includeMembershipSyncStatus: Boolean!
    $includeMembershipLastSynced: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
  ) {
    event(slug: $eventSlug) {
      ...ContactsTablePagination_event
      ...ContactsTablePagination_totalCountEvent
    }
  }
`;

class ContactsPage extends React.Component<
  {
    event: ContactsPage_event,
    org: ContactsPage_org,
    location: Location,
    history: RouterHistory,
    tz: string,
    userEmail: string,
    salesforceEnabled: boolean,
    marketoEnabled: boolean,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
    selectedContacts: $ReadOnlyArray<string>,
    availableContacts: AvailableContactsType,
    allContactsSelected: boolean,
  },
> {
  contactFields: $ReadOnlyArray<FieldType> = getContactFields(
    normalizeCustomFields(this.props.org.customFields.edges.map(edge => edge.node)),
    { salesforceEnabled: this.props.salesforceEnabled, marketoEnabled: this.props.marketoEnabled },
    true,
  );

  updateTableColumnWidths: ?() => void = null;

  state = {
    shownColumns: ContactsPage.getShownColumns(this.contactFields),
    selectedContacts: [],
    availableContacts: [],
    allContactsSelected: false,
  };

  static getShownColumns(columns: $ReadOnlyArray<FieldType>): $ReadOnlyArray<string> {
    const shownColumns: any = (storage.get('eventContactsShownColumns'): ?mixed);
    return Array.isArray(shownColumns)
      ? shownColumns
      : getColumnsShowConfig(columns)
          .filter(column => column.default)
          .map(column => column.value);
  }

  handleContactsSearch = (search: string) => {
    replaceQueryParams(this.props.history, { search: search || null });
  };

  handleColumnsChange = (shownColumns: $ReadOnlyArray<string>) => {
    storage.set('eventContactsShownColumns', shownColumns);
    this.setState({ shownColumns });
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  handleContactsListUpdate = (availableContacts: AvailableContactsType) => {
    this.setState({ availableContacts });
  };

  handleSelectContacts = (selectedContacts: $ReadOnlyArray<string>) => {
    this.setState({ selectedContacts });
  };

  getCurrentSelection = (): AvailableContactsType => {
    return this.state.availableContacts.filter(item =>
      this.state.selectedContacts.includes(item.id),
    );
  };

  handleUpdateTableColumnWidths = () => {
    if (this.updateTableColumnWidths) this.updateTableColumnWidths();
  };

  handleAllContactsSelectedUpdate = (allContactsSelected: boolean) => {
    this.setState({
      allContactsSelected,
    });
  };

  setColumnWidthUpdater = (updateColumnWidths: () => void) => {
    this.updateTableColumnWidths = updateColumnWidths;
  };

  renderTable = (props: {
    org: ?ContactsPage_org,
    event: ?$PropertyType<ContactsPageQueryResponse, 'event'>,
    shownColumns: ColumnConfiguration,
    filtered: boolean,
    inputFilters: InputVariableFilters,
    sort: SortParam,
  }): React.Node => {
    return (
      <ContactsTablePagination
        org={props.org}
        event={props.event}
        shownColumns={props.shownColumns}
        filtered={props.filtered}
        inputFilters={props.inputFilters}
        totalCountEvent={props.event}
        onChangeSort={this.handleSortChange}
        onSelectContacts={this.handleSelectContacts}
        selectedContacts={this.state.selectedContacts}
        onContactsListUpdate={this.handleContactsListUpdate}
        setColumnWidthUpdater={this.setColumnWidthUpdater}
        handleAllContactsSelectedUpdate={this.handleAllContactsSelectedUpdate}
        sort={props.sort}
        tz={this.props.tz}
      />
    );
  };

  render() {
    const { org, event, history, location } = this.props;
    const shownColumns = this.state.shownColumns;
    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      this.contactFields,
      shownColumns,
    );
    const filters = parseContactsFilters(location.search, this.contactFields);
    const filtered = Object.values(filters).some(val => !!val);
    // Default sorting to name field
    const sortFilter = filters.sort || {
      key: 'NAME',
      asc: true,
    };
    const customSortField: ?FieldType = this.contactFields.find(
      field => field.id === sortFilter.key,
    );
    const shownColumnsConfig = colsConfig(this.contactFields, shownColumns);

    const variables = {
      eventSlug: this.props.event.slug,
      count: 25,
      filters: {
        sort: customSortField ? 'CUSTOM' : sortFilter.key,
        customFieldSortId: customSortField != null ? customSortField.id : null,
        search: filters.search,
        direction: sortFilter.asc ? 'ASC' : 'DESC',
        createdAt: filters.created_at,
        registeredAt: filters.registered_at,
        creatorIds: filters.created_by,
        createdMethods: filters.created_method,
        updatedAt: filters.updated_at,
        updaterIds: filters.updated_by,
        updatedMethods: filters.updated_method,
        ownerIds: filters.owner_id,
        contactTypeIds: filters.contact_type_id,
        hotLead: filters.hot_lead,
        emailOptIn: filters.email_opt_in,
        cities: filters.city,
        states: filters.state,
        countries: filters.country,
        salesforceSyncOptions: filters.salesforce_sync_as,
        leadStatusIds: filters.salesforce_lead_status_id,
        syncStatuses: filters.sync_status,
        membershipSyncStatuses: filters.membership_sync_status,
        registrationStatusIds: filters.registration_status_id,
        attendanceStatusIds: filters.attendance_status_id,
        customFilters: this.contactFields
          .filter(option => option.fieldName == null && filters[option.id] != null)
          .map(option => getCustomFieldVariable(filters[option.id], option)),
      },
      ...includedColumns,
    };
    const currentSelectedContacts = this.getCurrentSelection();
    return (
      <>
        <ContactsHeaderBar
          org={org}
          event={event}
          history={history}
          filters={filters}
          contactFields={this.contactFields}
          inputFilters={variables.filters}
          contactsCount={this.state.availableContacts.length}
          onSearch={this.handleContactsSearch}
          userEmail={this.props.userEmail}
          shownColumns={shownColumns}
          onColumnsChange={this.handleColumnsChange}
          currentSelectedContacts={currentSelectedContacts}
          onUpdateTableColumnWidths={this.handleUpdateTableColumnWidths}
          rawContactFields={normalizeCustomFields(org.customFields.edges.map(edge => edge.node))}
          allContactsSelected={this.state.allContactsSelected}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: ContactsPageQueryResponse) =>
            this.renderTable({
              org,
              event: props.event,
              shownColumns: shownColumnsConfig,
              filtered,
              inputFilters: variables.filters,
              sort: sortFilter,
            })
          }
          renderLoading={() =>
            this.renderTable({
              org: null,
              event: null,
              shownColumns: shownColumnsConfig,
              filtered,
              inputFilters: variables.filters,
              totalCountOrg: null,
              sort: sortFilter,
            })
          }
        />
      </>
    );
  }
}

export default createFragmentContainer(ContactsPage, {
  event: graphql`
    fragment ContactsPage_event on Event {
      id
      slug
      ...ContactsHeaderBar_event
    }
  `,
  org: graphql`
    fragment ContactsPage_org on Org {
      id
      ...ContactsHeaderBar_org
      ...ContactsTablePagination_org
      customFields(customizableType: [CONTACT, EVENTCONTACT]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            customizableType
            restrictChangingValueForLead: mappedToSalesforce(pull: true, kind: LEAD)
            restrictChangingValueForContact: mappedToSalesforce(pull: true, kind: CONTACT)
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
});
