/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventSyncStatus_contact$ref: FragmentReference;
export type EventSyncStatus_contact = {|
  +syncStatus: {|
    +state: SyncState
  |},
  +$refType: EventSyncStatus_contact$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventSyncStatus_contact",
  "type": "Contact",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "syncStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '93557900b16ebcdc5a56d3f6ba38d387';
module.exports = node;
