/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/material/Button';

import ComponentStack from './ComponentStack';

const Container = styled.div`
  flex: 1 1 auto;
  padding: 40px 30px 30px 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Icon = styled.i`
  color: ${props => props.theme.mutedTextColor};
  font-size: 80px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${props => props.theme.rowPrimaryTextColor};
  margin-bottom: 10px;
`;

const Message = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 14px;
  margin-bottom: 10px;
`;

type Props = {
  title: string,
  error: {
    message: string,
  },
  componentStack?: ?string,
  errorStack?: ?string,
  retry?: () => void,
  icon: string,
};

type State = {
  retrying: boolean,
  error: {
    message: string,
  },
};

export default class ErrorView extends React.PureComponent<Props, State> {
  static defaultProps = {
    title: 'Sorry! We had a problem with that request.',
    icon: 'question',
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    // HACK: Relay doesn't tell us when its finished retrying, so we're assuming that if a new error
    // object is passed then the retry failed.
    if (prevState.error !== nextProps.error) {
      return {
        retrying: false,
        error: nextProps.error,
      };
    }
    return {
      error: nextProps.error,
    };
  }

  state = {
    retrying: false,
    // eslint-disable-next-line react/no-unused-state
    error: this.props.error,
  };

  handleRetry = () => {
    if (this.props.retry) {
      const retry = this.props.retry;
      console.log('retrying');
      this.setState({
        retrying: true,
      });
      retry();
    }
  };

  render() {
    const notFound =
      this.props.error.message.includes("Couldn't find ") ||
      this.props.error.message.includes("can't find record") ||
      this.props.error.message.includes("Couldn't access ");

    if (notFound) {
      return (
        <Container>
          <Icon className="fa fa-search" />
          <Title>Sorry, we could not find the page that you requested.</Title>
          <Message>It does not exist or you may not have access.</Message>
        </Container>
      );
    }

    return (
      <Container>
        <Icon className={`fa fa-${this.props.icon}`} />
        <Title>{this.props.title}</Title>
        <Message>{this.props.error.message}</Message>
        {this.props.componentStack && (
          <ComponentStack
            componentStack={this.props.componentStack}
            errorStack={this.props.errorStack}
          />
        )}
        {this.props.retry && (
          <Button label="Retry" primary loading={this.state.retrying} onClick={this.handleRetry} />
        )}
      </Container>
    );
  }
}
