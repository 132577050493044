/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { type BriefTemplateMutationVariables } from 'graph/mutations/briefTemplate/updateBriefTemplate';

import CheckBox from 'components/material/CheckBox';

import { ExpandStateIcon, SectionContent, SectionHeader, SectionLabel } from './Section';

import type { HeaderSection_template } from './__generated__/HeaderSection_template.graphql';

const StyledCheckBox = styled(CheckBox)`
  margin-right: 15px;
  &:not(:first-child) {
    margin-top: 20px;
  }
  > div:first-child {
    border-radius: 2px;
  }
`;

class HeaderSection extends React.PureComponent<
  {
    template: HeaderSection_template,
    onTemplateAttributeChange: (input: BriefTemplateMutationVariables) => void,
  },
  {
    expanded: boolean,
  },
> {
  state = {
    expanded: false,
  };

  handleToggleExpanded = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  };

  handleToggleFieldEnabled = (field: string, enabled: boolean) => {
    this.props.onTemplateAttributeChange({
      headerSettings: { ...this.props.template.headerSettings, [field]: enabled },
    });
  };

  render() {
    const { includeDates, includeWebsite, includeLocation } = this.props.template.headerSettings;
    return (
      <React.Fragment>
        <SectionHeader>
          <ExpandStateIcon
            onClick={this.handleToggleExpanded}
            className={`fa fa-fw fa-angle-${this.state.expanded ? 'down' : 'right'}`}
          />
          <SectionLabel onClick={this.handleToggleExpanded}>Brief Header</SectionLabel>
        </SectionHeader>
        {this.state.expanded && (
          <SectionContent>
            <StyledCheckBox checked disabled label="Event Name" onChange={() => {}} />
            <StyledCheckBox
              checked={includeDates}
              label="Dates & Times"
              onChange={(enabled: boolean) =>
                this.handleToggleFieldEnabled('includeDates', enabled)
              }
            />
            <StyledCheckBox
              checked={includeLocation}
              label="Location"
              onChange={(enabled: boolean) =>
                this.handleToggleFieldEnabled('includeLocation', enabled)
              }
            />
            <StyledCheckBox
              checked={includeWebsite}
              label="Website"
              onChange={(enabled: boolean) =>
                this.handleToggleFieldEnabled('includeWebsite', enabled)
              }
            />
          </SectionContent>
        )}
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  HeaderSection,
  graphql`
    fragment HeaderSection_template on BriefTemplate {
      headerSettings {
        includeDates
        includeLocation
        includeWebsite
      }
    }
  `,
);
