/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import isValidWebsite from 'utils/validators/isValidWebsite';
import isValidEmail from 'utils/validators/isValidEmail';
import isValidPhone from 'utils/validators/isValidPhone';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';

import Overlay from 'components/material/Overlay';
import TextField from 'components/material/TextField';
import Button from 'components/material/Button';

const StyledOverlay = styled(Overlay)`
  padding: 10px 15px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin: 15px 0 5px 0;
`;

export default class RichTextLinkOverlay extends React.PureComponent<
  {
    target: any,
    container: any,
    onHide: () => void,
    onAdd: (url: string, label: string) => void,
    selectedText: boolean,
  },
  {
    link: string,
    linkName: string,
    error: ?string,
  },
> {
  state = {
    link: '',
    linkName: '',
    error: null,
  };

  getValidLink = (): string => {
    const link = this.state.link.trim();

    const webLink = enforceHttpPrefix(link);
    if (isValidWebsite(webLink)) {
      return webLink;
    }
    if (isValidEmail(link)) {
      return `mailto:${link}`;
    }
    const mailtoLink = link.split(':');
    if (mailtoLink[0].toLowerCase() === 'mailto' && isValidEmail(mailtoLink[1])) {
      return `mailto:${mailtoLink[1]}`;
    }
    if (isValidPhone(link)) {
      return `tel:${link}`;
    }
    const phoneLink = link.split(':');
    if (phoneLink[0].toLowerCase() === 'tel' && isValidPhone(phoneLink[1])) {
      return `tel:${phoneLink[1]}`;
    }

    return '';
  };

  handleLinkChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ link: e.currentTarget.value });
  };

  handleLinkNameChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ linkName: e.currentTarget.value });
  };

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const link = this.getValidLink();
    if (!link) {
      this.setState({ error: 'Link is not valid' });
      return;
    }

    const linkName = this.state.linkName.trim() || link;
    this.props.onAdd(link, linkName);
    this.props.onHide();
  };

  handleInputClick = (e: SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.currentTarget.focus();
  };

  render() {
    return (
      <StyledOverlay
        target={this.props.target}
        container={this.props.container}
        show
        onHide={this.props.onHide}
      >
        <form onSubmit={this.handleSubmit}>
          <TextField
            label="Enter a link"
            icon="link"
            name="link"
            error={this.state.error}
            onChange={this.handleLinkChange}
            onClick={this.handleInputClick}
            value={this.state.link}
            autoFocus
          />
          {!this.props.selectedText && (
            <TextField
              label="Link name (optional)"
              name="linkName"
              value={this.state.linkName}
              onChange={this.handleLinkNameChange}
              onClick={this.handleInputClick}
            />
          )}

          <StyledButton label="Add" primary disabled={this.state.link.length === 0} type="submit" />
        </form>
      </StyledOverlay>
    );
  }
}
