/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskContentHeader_task$ref: FragmentReference;
export type TaskContentHeader_task = {|
  +viewerCanUpdateDetails: boolean,
  +name: string,
  +folder: ?{|
    +name: string
  |},
  +$refType: TaskContentHeader_task$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TaskContentHeader_task",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateDetails",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "folder",
      "storageKey": null,
      "args": null,
      "concreteType": "Folder",
      "plural": false,
      "selections": [
        v0
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '72d9e48a835b2a0d7b4c740db5d8f623';
module.exports = node;
