/**
 * @flow
 * @relayHash 4613c4a8510163212c1f3c20fa01a3ba
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventRequestFormsIsDraftColumn_requestForm$ref = any;
type EventRequestFormsShareLinkColumn_requestForm$ref = any;
type EventRequestFormsUpdatedColumn_requestForm$ref = any;
export type UpdateEventRequestFormStatusInput = {
  clientMutationId?: ?string,
  requestFormId: string,
  isDraft: boolean,
};
export type updateEventRequestFormStatusMutationVariables = {|
  input: UpdateEventRequestFormStatusInput
|};
export type updateEventRequestFormStatusMutationResponse = {|
  +updateEventRequestFormStatus: ?{|
    +requestForm: {|
      +id: string,
      +$fragmentRefs: EventRequestFormsIsDraftColumn_requestForm$ref & EventRequestFormsUpdatedColumn_requestForm$ref & EventRequestFormsShareLinkColumn_requestForm$ref,
    |}
  |}
|};
export type updateEventRequestFormStatusMutation = {|
  variables: updateEventRequestFormStatusMutationVariables,
  response: updateEventRequestFormStatusMutationResponse,
|};
*/


/*
mutation updateEventRequestFormStatusMutation(
  $input: UpdateEventRequestFormStatusInput!
) {
  updateEventRequestFormStatus(input: $input) {
    requestForm {
      id
      ...EventRequestFormsIsDraftColumn_requestForm
      ...EventRequestFormsUpdatedColumn_requestForm
      ...EventRequestFormsShareLinkColumn_requestForm
    }
  }
}

fragment EventRequestFormsIsDraftColumn_requestForm on EventRequestForm {
  isDraft
}

fragment EventRequestFormsUpdatedColumn_requestForm on EventRequestForm {
  updatedAt
}

fragment EventRequestFormsShareLinkColumn_requestForm on EventRequestForm {
  shareLink
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventRequestFormStatusInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateEventRequestFormStatusInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventRequestFormStatusMutation",
  "id": null,
  "text": "mutation updateEventRequestFormStatusMutation(\n  $input: UpdateEventRequestFormStatusInput!\n) {\n  updateEventRequestFormStatus(input: $input) {\n    requestForm {\n      id\n      ...EventRequestFormsIsDraftColumn_requestForm\n      ...EventRequestFormsUpdatedColumn_requestForm\n      ...EventRequestFormsShareLinkColumn_requestForm\n    }\n  }\n}\n\nfragment EventRequestFormsIsDraftColumn_requestForm on EventRequestForm {\n  isDraft\n}\n\nfragment EventRequestFormsUpdatedColumn_requestForm on EventRequestForm {\n  updatedAt\n}\n\nfragment EventRequestFormsShareLinkColumn_requestForm on EventRequestForm {\n  shareLink\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventRequestFormStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateEventRequestFormStatus",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventRequestFormStatusPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "requestForm",
            "storageKey": null,
            "args": null,
            "concreteType": "EventRequestForm",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "FragmentSpread",
                "name": "EventRequestFormsIsDraftColumn_requestForm",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "EventRequestFormsUpdatedColumn_requestForm",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "EventRequestFormsShareLinkColumn_requestForm",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventRequestFormStatusMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateEventRequestFormStatus",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventRequestFormStatusPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "requestForm",
            "storageKey": null,
            "args": null,
            "concreteType": "EventRequestForm",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isDraft",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shareLink",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '96f040f31ba09405202960f651709cb6';
module.exports = node;
