/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import Tooltip from 'components/material/Tooltip';

import type { ConnectCampaignPanelRow_campaign } from './__generated__/ConnectCampaignPanelRow_campaign.graphql';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  color: ${props => props.theme.rowSecondaryTextColor};
  cursor: default;
  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
      color: ${props.theme.rowPrimaryTextColor};
      &:hover {
        background: ${props.theme.hoverRowColor};
      }
    `};
`;

const Name = styled.div`
  flex: 1 1 auto;
  margin: 0 10px;
`;

const Connected = styled.div`
  padding: 1px 5px;
  flex-shrink: 0;
  font-size: 11px;
  background: ${props => props.theme.highlightColor};
  color: white;
  border-radius: 2px;
`;

class ConnectCampaignPanelRow extends React.PureComponent<{
  campaign: ConnectCampaignPanelRow_campaign,
  onAdd: (campaignId: string) => void,
}> {
  handleClick = () => {
    if (this.props.campaign.event) return;

    this.props.onAdd(this.props.campaign.id);
  };

  render() {
    const { campaign } = this.props;

    return (
      <Row clickable={!campaign.event} onClick={this.handleClick}>
        <Name>{campaign.name}</Name>
        {campaign.event && (
          <Tooltip label={campaign.event.name} placement="top">
            <Connected>Connected</Connected>
          </Tooltip>
        )}
      </Row>
    );
  }
}

export default createFragmentContainer(
  ConnectCampaignPanelRow,
  graphql`
    fragment ConnectCampaignPanelRow_campaign on SalesforceCampaign {
      id
      name
      event {
        name
      }
    }
  `,
);
