import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import Loader from 'components/Loader';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.overlayBackgroundColor};
`;

const Mask = (props: { loaderColor?: ?string }) => {
  return (
    document.body &&
    createPortal(
      <Overlay>
        <Loader color={props.loaderColor} />
      </Overlay>,
      document.body,
    )
  );
};

export default Mask;
