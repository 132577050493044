/**
 * @flow
 * @relayHash 7839a89d9623e9700bd87d28d3c36faf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MembersPagination_totalCountUsers$ref = any;
export type UserRemovalWindowQueryVariables = {|
  userId: string,
  query?: ?string,
|};
export type UserRemovalWindowQueryResponse = {|
  +org: {|
    +id: string,
    +orgMemberExists: boolean,
    +subscription: {|
      +fatmLimit: ?number,
      +fatmCount: number,
    |},
    +$fragmentRefs: MembersPagination_totalCountUsers$ref,
  |}
|};
export type UserRemovalWindowQuery = {|
  variables: UserRemovalWindowQueryVariables,
  response: UserRemovalWindowQueryResponse,
|};
*/


/*
query UserRemovalWindowQuery(
  $userId: ID!
  $query: String
) {
  org {
    id
    orgMemberExists(userId: $userId)
    subscription {
      fatmLimit
      fatmCount
      id
    }
    ...MembersPagination_totalCountUsers
  }
}

fragment MembersPagination_totalCountUsers on Org {
  orgUsers: users(query: $query) {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orgMemberExists",
  "args": [
    {
      "kind": "Variable",
      "name": "userId",
      "variableName": "userId",
      "type": "ID!"
    }
  ],
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fatmLimit",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fatmCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserRemovalWindowQuery",
  "id": null,
  "text": "query UserRemovalWindowQuery(\n  $userId: ID!\n  $query: String\n) {\n  org {\n    id\n    orgMemberExists(userId: $userId)\n    subscription {\n      fatmLimit\n      fatmCount\n      id\n    }\n    ...MembersPagination_totalCountUsers\n  }\n}\n\nfragment MembersPagination_totalCountUsers on Org {\n  orgUsers: users(query: $query) {\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserRemovalWindowQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v3,
              v4
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "MembersPagination_totalCountUsers",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserRemovalWindowQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v3,
              v4,
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "orgUsers",
            "name": "users",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "query",
                "variableName": "query",
                "type": "String"
              }
            ],
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '610cc52f4f17d808b237aad7a6c9c59d';
module.exports = node;
