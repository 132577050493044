/**
 * @flow
 * @relayHash c5fd4d47f1c507ba1d6f736f51b538fb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AllEventsMapMarkerInfoBox_event$ref = any;
export type AllEventsMapMarkerInfoQueryVariables = {|
  eventSlug: string,
  publicRuleName: string,
  resourceToken: string,
  orgSlug?: ?string,
  isShared: boolean,
|};
export type AllEventsMapMarkerInfoQueryResponse = {|
  +event?: ?{|
    +$fragmentRefs: AllEventsMapMarkerInfoBox_event$ref
  |},
  +publicRule?: boolean,
  +eventList?: ?{|
    +event: {|
      +$fragmentRefs: AllEventsMapMarkerInfoBox_event$ref
    |}
  |},
|};
export type AllEventsMapMarkerInfoQuery = {|
  variables: AllEventsMapMarkerInfoQueryVariables,
  response: AllEventsMapMarkerInfoQueryResponse,
|};
*/


/*
query AllEventsMapMarkerInfoQuery(
  $eventSlug: String!
  $publicRuleName: String!
  $resourceToken: String!
  $orgSlug: String
  $isShared: Boolean!
) {
  event(slug: $eventSlug) @skip(if: $isShared) {
    ...AllEventsMapMarkerInfoBox_event
    id
  }
  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug) @include(if: $isShared)
  eventList(resourceToken: $resourceToken) @include(if: $isShared) {
    event(slug: $eventSlug) {
      ...AllEventsMapMarkerInfoBox_event
      id
    }
    id
  }
}

fragment AllEventsMapMarkerInfoBox_event on Event {
  id
  slug
  name
  startDate
  endDate
  startDateAllDay
  endDateAllDay
  tz
  primaryLocation {
    name
    city
    state
    address1
    address2
    country
    postal
    note
    website
    lat
    lng
    id
  }
  lead {
    ...UsersGroup_users
    id
  }
  staffers {
    edges {
      node {
        user {
          ...UsersGroup_users
          id
        }
        id
      }
    }
  }
}

fragment UsersGroup_users on Assignable {
  id
  email
  firstName
  lastName
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "publicRuleName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "resourceToken",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgSlug",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isShared",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "FragmentSpread",
    "name": "AllEventsMapMarkerInfoBox_event",
    "args": null
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicRule",
  "args": [
    {
      "kind": "Variable",
      "name": "orgSlug",
      "variableName": "orgSlug",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "publicRuleName",
      "variableName": "publicRuleName",
      "type": "String!"
    },
    {
      "kind": "Variable",
      "name": "resourceToken",
      "variableName": "resourceToken",
      "type": "String"
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "resourceToken",
    "variableName": "resourceToken",
    "type": "String!"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String!"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = [
  v6,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "email",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "firstName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "avatar",
    "args": null,
    "storageKey": null
  }
],
v9 = [
  v6,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "slug",
    "args": null,
    "storageKey": null
  },
  v7,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "startDate",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "endDate",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "startDateAllDay",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "endDateAllDay",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "tz",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "primaryLocation",
    "storageKey": null,
    "args": null,
    "concreteType": "Location",
    "plural": false,
    "selections": [
      v7,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "city",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "state",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "address1",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "address2",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "country",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "postal",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "note",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "website",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lat",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lng",
        "args": null,
        "storageKey": null
      },
      v6
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "lead",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": v8
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "staffers",
    "storageKey": null,
    "args": null,
    "concreteType": "StaffMembershipRequiredConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "StaffMembershipRequiredEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffMembership",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": v8
              },
              v6
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AllEventsMapMarkerInfoQuery",
  "id": null,
  "text": "query AllEventsMapMarkerInfoQuery(\n  $eventSlug: String!\n  $publicRuleName: String!\n  $resourceToken: String!\n  $orgSlug: String\n  $isShared: Boolean!\n) {\n  event(slug: $eventSlug) @skip(if: $isShared) {\n    ...AllEventsMapMarkerInfoBox_event\n    id\n  }\n  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug) @include(if: $isShared)\n  eventList(resourceToken: $resourceToken) @include(if: $isShared) {\n    event(slug: $eventSlug) {\n      ...AllEventsMapMarkerInfoBox_event\n      id\n    }\n    id\n  }\n}\n\nfragment AllEventsMapMarkerInfoBox_event on Event {\n  id\n  slug\n  name\n  startDate\n  endDate\n  startDateAllDay\n  endDateAllDay\n  tz\n  primaryLocation {\n    name\n    city\n    state\n    address1\n    address2\n    country\n    postal\n    note\n    website\n    lat\n    lng\n    id\n  }\n  lead {\n    ...UsersGroup_users\n    id\n  }\n  staffers {\n    edges {\n      node {\n        user {\n          ...UsersGroup_users\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment UsersGroup_users on Assignable {\n  id\n  email\n  firstName\n  lastName\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AllEventsMapMarkerInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "isShared",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": v1,
            "concreteType": "Event",
            "plural": false,
            "selections": v2
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isShared",
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventList",
            "storageKey": null,
            "args": v4,
            "concreteType": "EventList",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": v5,
                "concreteType": "Event",
                "plural": false,
                "selections": v2
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllEventsMapMarkerInfoQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "isShared",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": v1,
            "concreteType": "Event",
            "plural": false,
            "selections": v9
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isShared",
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventList",
            "storageKey": null,
            "args": v4,
            "concreteType": "EventList",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": v5,
                "concreteType": "Event",
                "plural": false,
                "selections": v9
              },
              v6
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1b6f787fc69e1944aeccd6d00b697e0';
module.exports = node;
