/* @flow */

type UserType = $Supertype<{
  email?: string,
  firstName: string,
  lastName: string,
  first_name: string, // Deprecated use uppercase version
  last_name: string, // Deprecated use uppercase version
}>;

const fullNameOfUser = ({ first_name, last_name, email, firstName, lastName }: UserType) => {
  if (firstName || lastName) {
    return [firstName, lastName].filter(Boolean).join(' ');
  }
  // Deprecated will be removed later when not used
  if (first_name || last_name) {
    return [first_name, last_name].filter(Boolean).join(' ');
  }
  return email || '';
};

export default fullNameOfUser;
