/**
 * @flow
 * @relayHash 45d707fbccfc90c1ca633165ee13c535
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateEventRequestSubmissionInput = {
  clientMutationId?: ?string,
  token: string,
  submitToken: string,
  values: $ReadOnlyArray<EventRequestQuestionValueInput>,
};
export type EventRequestQuestionValueInput = {
  questionId: string,
  budgetCategoryId?: ?string,
  textValue?: ?string,
  textareaValue?: ?string,
  numberValue?: ?number,
  dateValue?: ?QuestionValueDateInput,
  booleanValue?: ?boolean,
  selectIdsValue: $ReadOnlyArray<string>,
  filesValue: $ReadOnlyArray<QuestionValueFileInput>,
  locationValue?: ?QuestionValueLocationInput,
};
export type QuestionValueDateInput = {
  startDate: string,
  endDate?: ?string,
  startDateAllDay?: ?boolean,
  endDateAllDay?: ?boolean,
};
export type QuestionValueFileInput = {
  id: string,
  filename: string,
  filetype: string,
  fileurl: string,
  filesize?: ?number,
};
export type QuestionValueLocationInput = {
  name: string,
  country?: ?string,
  city: string,
  state?: ?string,
  address1?: ?string,
  address2?: ?string,
  postal?: ?string,
  lat: number,
  lng: number,
};
export type updateEventRequestSubmissionMutationVariables = {|
  input: UpdateEventRequestSubmissionInput
|};
export type updateEventRequestSubmissionMutationResponse = {|
  +updateEventRequestSubmission: ?{|
    +requestSubmission: {|
      +id: string
    |}
  |}
|};
export type updateEventRequestSubmissionMutation = {|
  variables: updateEventRequestSubmissionMutationVariables,
  response: updateEventRequestSubmissionMutationResponse,
|};
*/


/*
mutation updateEventRequestSubmissionMutation(
  $input: UpdateEventRequestSubmissionInput!
) {
  updateEventRequestSubmission(input: $input) {
    requestSubmission {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventRequestSubmissionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateEventRequestSubmission",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateEventRequestSubmissionInput!"
      }
    ],
    "concreteType": "UpdateEventRequestSubmissionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "requestSubmission",
        "storageKey": null,
        "args": null,
        "concreteType": "EventRequestSubmission",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventRequestSubmissionMutation",
  "id": null,
  "text": "mutation updateEventRequestSubmissionMutation(\n  $input: UpdateEventRequestSubmissionInput!\n) {\n  updateEventRequestSubmission(input: $input) {\n    requestSubmission {\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventRequestSubmissionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventRequestSubmissionMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ce5e63bcbc0372d011d8daefa59f7df';
module.exports = node;
