/**
 * @flow
 * @relayHash b42c3a3ee72d9caf1297c6ca1009c807
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveDeliverableDependencyInput = {
  clientMutationId?: ?string,
  dependentId: string,
  dependencyId: string,
};
export type removeTaskDependencyMutationVariables = {|
  input: RemoveDeliverableDependencyInput
|};
export type removeTaskDependencyMutationResponse = {|
  +removeDeliverableDependency: ?{|
    +dependent: {|
      +id: string
    |},
    +dependency: {|
      +id: string
    |},
    +removedDependencyId: string,
    +removedDependentId: string,
  |}
|};
export type removeTaskDependencyMutation = {|
  variables: removeTaskDependencyMutationVariables,
  response: removeTaskDependencyMutationResponse,
|};
*/


/*
mutation removeTaskDependencyMutation(
  $input: RemoveDeliverableDependencyInput!
) {
  removeDeliverableDependency(input: $input) {
    dependent {
      id
    }
    dependency {
      id
    }
    removedDependencyId
    removedDependentId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveDeliverableDependencyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
],
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeDeliverableDependency",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveDeliverableDependencyInput!"
      }
    ],
    "concreteType": "RemoveDeliverableDependencyPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dependent",
        "storageKey": null,
        "args": null,
        "concreteType": "Deliverable",
        "plural": false,
        "selections": v1
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "dependency",
        "storageKey": null,
        "args": null,
        "concreteType": "Deliverable",
        "plural": false,
        "selections": v1
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedDependencyId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedDependentId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeTaskDependencyMutation",
  "id": null,
  "text": "mutation removeTaskDependencyMutation(\n  $input: RemoveDeliverableDependencyInput!\n) {\n  removeDeliverableDependency(input: $input) {\n    dependent {\n      id\n    }\n    dependency {\n      id\n    }\n    removedDependencyId\n    removedDependentId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeTaskDependencyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "removeTaskDependencyMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '310cf0f3cea0eb4ba1471f4ddd5fa50b';
module.exports = node;
