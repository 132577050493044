/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import xor from 'lodash/xor';

import Button, { MinimalButton } from 'components/budget/Button';
import Overlay from 'components/material/Overlay';

import ColumnsFiltersContent from './ColumnFiltersContent';
import ColumnsFiltersHeader from './ColumnFiltersHeader';
import type { Filter, FilterGroup } from './columnFilterTypes';

const StyledOverlay = styled(Overlay)`
  width: 348px;
  padding-bottom: 20px;
  font-size: 13px;
  color: ${props => props.theme.rowPrimaryTextColor};
  @media (max-width: 460px) {
    position: fixed !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: none !important;
    width: 100% !important;
    height: 100%;
    z-index: 999;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  padding-top: 15px;
`;

const StyledMinimalButton = styled(MinimalButton)`
  margin-right: 40px;
`;

const StyledButton = styled(Button)`
  padding: 8px 25px;
`;

export const FiltersContainer = styled.div`
  position: relative;
  max-height: calc(70vh - 200px); /* 200px is considered to be header and buttons */
  overflow: auto;
  @media (max-width: 460px) {
    max-height: unset;
    height: 100%;
  }
`;

export default class ColumnFiltersOverlay extends React.PureComponent<
  {
    target: ?React.ElementRef<React.ElementType>,
    container: ?React.ElementRef<React.ElementType>,
    show: boolean,
    onHide: () => void,
    filters: $ReadOnlyArray<Filter>,
    groups?: $ReadOnlyArray<FilterGroup>,
    shownColumns: $ReadOnlyArray<string>,
    onChange: (columns: $ReadOnlyArray<string>) => void,
    onSetDefaults: () => void,
  },
  { shownColumns: ?$ReadOnlyArray<string> },
> {
  state = { shownColumns: null };

  clearChangedShownColumns = () => {
    this.setState({ shownColumns: null });
  };

  handleSave = () => {
    if (this.state.shownColumns) {
      this.props.onChange(this.state.shownColumns);
      this.clearChangedShownColumns();
      this.props.onHide();
    }
  };

  handleChange = (shownColumns: $ReadOnlyArray<string>) => {
    this.setState({ shownColumns });
  };

  handleSetDefaults = () => {
    this.props.onSetDefaults();
    this.clearChangedShownColumns();
    this.props.onHide();
  };

  handleHide = () => {
    this.props.onHide();
    this.clearChangedShownColumns();
  };

  render() {
    const { target, show, container, filters, groups, shownColumns } = this.props;
    const changedShownColumns = this.state.shownColumns;

    return (
      <StyledOverlay
        target={target}
        show={show}
        container={container}
        onHide={this.handleHide}
        forceRightEdge
      >
        <ColumnsFiltersHeader onSetDefaults={this.handleSetDefaults} />
        <FiltersContainer>
          <ColumnsFiltersContent
            filters={filters}
            groups={groups}
            shownFilters={changedShownColumns || shownColumns}
            onChange={this.handleChange}
          />
        </FiltersContainer>
        <ButtonContainer>
          <StyledMinimalButton label="Cancel" onClick={this.handleHide} />
          <StyledButton
            disabled={
              changedShownColumns === null ||
              xor(changedShownColumns ? [...changedShownColumns] : [], [...shownColumns]).length ===
                0
            }
            onClick={this.handleSave}
          >
            Apply
          </StyledButton>
        </ButtonContainer>
      </StyledOverlay>
    );
  }
}
