/* flow */
import * as React from 'react';
import styled from 'styled-components';

import ErrorMessage from 'components/EventRequestForm/form/ErrorMessage';
import TextInput from 'components/material/TextInput';

import inputBoxStyled from '../components/inputBoxStyled';
import Label from './Label';

const Container = styled.div`
  margin-bottom: 40px;
`;

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

export default class EventName extends React.PureComponent<
  {
    value: string,
    eventName: string,
    onChange: ({ eventName: string }) => void,
  },
  {
    error: ?string,
    value: string,
    initialValue: string,
  },
> {
  state = { error: null, value: this.props.value, initialValue: this.props.value };

  static getDerivedStateFromProps(
    props: $PropertyType<EventName, 'props'>,
    state: $PropertyType<EventName, 'state'>,
  ) {
    if (props.value !== state.initialValue) {
      return { value: props.value, initialValue: props.value };
    }
    return null;
  }

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ value: e.currentTarget.value });
  };

  handleResetToEvent = () => {
    const eventName = this.props.eventName;
    this.setState({ error: null, value: eventName });
    this.props.onChange({ eventName });
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const eventName = e.currentTarget.value.trim() || null;
    if (!eventName) {
      this.setState({ error: 'Event Name is required' });
      return;
    }
    if (this.props.value === eventName) {
      return;
    }
    this.setState({ error: null, value: eventName });
    this.props.onChange({ eventName });
  };

  render() {
    const { error, value } = this.state;
    const eventName = this.props.eventName;
    return (
      <Container>
        <Label
          text="Public Event Name"
          required
          onReset={this.handleResetToEvent}
          showReset={value !== eventName}
        />
        <StyledTextInput
          value={value || ''}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={!!this.state.error}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    );
  }
}
