/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type G2WLocationPicker_integrable$ref: FragmentReference;
export type G2WLocationPicker_integrable = {|
  +id: string,
  +viewerCanConfigureIntegrations: boolean,
  +g2wSolutionInstances: {|
    +totalCount: number
  |},
  +g2wUsers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +g2wKey: string,
        +firstName: string,
        +lastName: string,
        +email: string,
      |}
    |}>
  |},
  +$refType: G2WLocationPicker_integrable$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "G2WLocationPicker_integrable",
  "type": "IntegrableInterface",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanConfigureIntegrations",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "g2wSolutionInstances",
      "name": "traySolutionInstances",
      "storageKey": "traySolutionInstances(solutionName:\"G2W\")",
      "args": [
        {
          "kind": "Literal",
          "name": "solutionName",
          "value": "G2W",
          "type": "SolutionName"
        }
      ],
      "concreteType": "TraySolutionInstanceRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "g2wUsers",
      "storageKey": null,
      "args": null,
      "concreteType": "G2WUserRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "G2WUserRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "G2WUser",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "g2wKey",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "firstName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "lastName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "email",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '28d139251b21cdfb2e7902d79a825069';
module.exports = node;
