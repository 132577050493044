/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import MyTasksPage from './MyTasksPage';

import type { MyTasksQueryResponse } from './__generated__/MyTasksQuery.graphql';

const query = graphql`
  query MyTasksQuery {
    org {
      ...MyTasksPage_org
    }
    me {
      ...MyTasksPage_me
    }
  }
`;

export default class MyTasks extends React.PureComponent<{
  location: Location,
  history: RouterHistory,
  match: Match,
}> {
  render() {
    const { location, history } = this.props;
    const pathPrefix = this.props.match.url;

    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(props: MyTasksQueryResponse) => {
          return (
            <MyTasksPage
              org={props.org}
              me={props.me}
              history={history}
              location={location}
              pathPrefix={pathPrefix}
            />
          );
        }}
      />
    );
  }
}
