/* @flow */
import type { RecordSourceSelectorProxy, SelectorData, RecordProxy } from 'react-relay';

import getMutationRoot from './lib/getMutationRoot';
import getConnection, { type Connection } from './lib/getConnection';

type Config = {
  // Parent record relay id
  parentId: string,
  connections: $ReadOnlyArray<Connection>,
  // Edge name in response
  edgeName: string,
};

function updateEdgeInConnection({ parentId, connections, edgeName }: Config) {
  return (store: RecordSourceSelectorProxy, data: SelectorData) => {
    const parentRecord: RecordProxy = store.get(parentId);

    const root = getMutationRoot(store, data);
    if (!root) throw new Error('Root field missing in mutation');

    let responseEdges: Array<RecordProxy> = [];

    try {
      responseEdges = [root.getLinkedRecord(edgeName)];
    } catch (e) {
      responseEdges = root.getLinkedRecords(edgeName);
    }

    if (!responseEdges.length === 0) throw new Error('Edge missing in response');
    if (responseEdges.some(edge => !edge.getLinkedRecord('node')))
      throw new Error('Node missing in response edge');

    connections.forEach(conConfig => {
      const connection = getConnection(parentRecord, conConfig);

      if (connection) {
        const edges: Array<RecordProxy> = connection.getLinkedRecords('edges');

        responseEdges.forEach(responseEdge => {
          const recordEdge: ?RecordProxy = edges.find(
            edge =>
              edge.getLinkedRecord('node').getValue('id') ===
              responseEdge.getLinkedRecord('node').getValue('id'),
          );

          if (!recordEdge) return;

          recordEdge.copyFieldsFrom(responseEdge);
        });
      }
    });
  };
}

export default updateEdgeInConnection;
