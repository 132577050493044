/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import TextInput from 'components/material/TextInput';

import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';
import inputBoxStyled from '../form/inputBoxStyled';
import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../lib/types';

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
  min-height: 40px;
  max-height: 80px;
  padding-top: 6px;
`;

const LengthIndicator = styled.div`
  flex: 1 1 auto;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: #cdd1d4;
  ${props =>
    props.hasError &&
    css`
      position: absolute;
      right: 80px;
    `};
`;

const FooterContainer = styled.div`
  display: flex;
`;

export default class QuestionTextArea extends React.PureComponent<{
  question: QuestionType,
  value: QuestionValueType,
  onChangeValue: QuestionValueInputType => void,
  readOnly: boolean,
  error: ?string,
}> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeValue({
      textareaValue: e.currentTarget.value || null,
    });
  };

  render() {
    const { question } = this.props;
    const min = question.textMinLength != null ? question.textMinLength : 0;
    const max = question.textMaxLength != null ? question.textMaxLength : Infinity;
    const value = this.props.value.textareaValue || '';

    return (
      <>
        {question.description && <HelperText>{question.description}</HelperText>}
        <StyledTextInput
          multiline
          maxLength={max}
          value={value}
          onChange={this.handleChange}
          error={this.props.error}
          disabled={this.props.readOnly}
        />
        <FooterContainer>
          <>
            {(min !== 0 || max !== Infinity) && (
              <LengthIndicator hasError={this.props.error}>
                {value.length}
                {max !== Infinity && <>\{max}</>}
              </LengthIndicator>
            )}
            {this.props.error && <ErrorMessage>{this.props.error}</ErrorMessage>}
          </>
        </FooterContainer>
      </>
    );
  }
}
