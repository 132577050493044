/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Search from 'components/Search';
import CompanyContainer from './CompaniesContainer';

import { type BriefCompanies_event } from './__generated__/BriefCompanies_event.graphql';
import { type BriefCompaniesQueryResponse } from './__generated__/BriefCompaniesQuery.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const StyledSearch = styled(Search)`
  max-width: 100%;
  margin-bottom: 20px;
`;

const query = graphql`
  query BriefCompaniesQuery($eventSlug: String!, $companyFilters: CompanyFilters!) {
    event(slug: $eventSlug) {
      ...CompaniesContainer_event
    }
  }
`;

class BriefCompanies extends React.PureComponent<
  {
    event: BriefCompanies_event,
    selectedCompanies: $ReadOnlyArray<string>,
    containerRef: (el: CompanyContainer) => void,
    onAddCompany: (contactId: string) => void,
    onRemoveCompany: (contactId: string) => void,
    onAllCompanyCheckToggle: (
      isPrevAllCheck: boolean,
      searchedCompanyIds: $ReadOnlyArray<string>,
    ) => void,
  },
  {
    searchQuery: string,
  },
> {
  state = { searchQuery: '' };

  handleCompanySearch = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  handleCompanyToggle = (contactId: string, isPrevChecked: boolean) => {
    if (isPrevChecked) {
      this.props.onRemoveCompany(contactId);
    } else {
      this.props.onAddCompany(contactId);
    }
  };

  render() {
    const {
      event: { slug },
      selectedCompanies,
      onAllCompanyCheckToggle,
      containerRef,
    } = this.props;
    return (
      <Container>
        <StyledSearch
          onSearch={this.handleCompanySearch}
          search={this.state.searchQuery}
          placeholder="Search Companies"
        />
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug: slug, companyFilters: { query: this.state.searchQuery } }}
          renderSuccess={(props: BriefCompaniesQueryResponse) => {
            if (props.event) {
              return (
                <CompanyContainer
                  componentRef={containerRef}
                  event={props.event}
                  selectedCompanies={selectedCompanies}
                  searchQuery={this.state.searchQuery}
                  onAllCompanyCheckToggle={onAllCompanyCheckToggle}
                  handleCompanyToggle={this.handleCompanyToggle}
                />
              );
            }
            return null;
          }}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  BriefCompanies,
  graphql`
    fragment BriefCompanies_event on Event {
      slug
      id
    }
  `,
);
