/* @flow */
import * as React from 'react';

import currencies from 'config/currencies.json';

import formatNumber from 'utils/number/formatNumber';
import parseCost from 'utils/number/parseCost';

import TextField from 'components/material/TextField';

type Props = {
  currency: string,
  label?: string,
  defaultValue?: ?number,
  value?: ?number,
  onChange?: (SyntheticEvent<HTMLInputElement>, ?number) => void,
  className?: string,
  autoFocus?: boolean,
  required?: boolean,
  name?: string,
  onClick?: (SyntheticEvent<HTMLInputElement>) => void,
  onFocus?: (SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (SyntheticEvent<HTMLInputElement>, ?number) => void,
  onKeyDown?: (SyntheticKeyboardEvent<HTMLInputElement>, ?number) => void,
  inputRef?: HTMLInputElement => void,
  readOnly?: boolean,
  disabled?: boolean,
  error?: ?string,
  placeholder?: string,
  maxValue: number,
};

export default class CostField extends React.PureComponent<
  Props,
  {
    value: string,
    error: boolean,
    focus: boolean,
  },
> {
  static defaultProps = {
    maxValue: 100000000,
  };

  state = {
    error: false,
    value: this.getValue(),
    focus: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (
      !this.state.focus &&
      (prevProps.defaultValue !== this.props.defaultValue || this.props.value != null)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.getValue() });
    }
  }

  getValue() {
    const value = this.props.value != null ? this.props.value : this.props.defaultValue;
    if (value != null && Number.isInteger(value)) {
      return formatNumber((parseCost((value / 100).toString()) || 0) / 100);
    }
    return '';
  }

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const cleanValue = e.currentTarget.value.replace(/[^0-9]+/, '');
    const testValue = /^[0-9,.]*$/.test(cleanValue);
    const parsedCost = parseCost(cleanValue.toString());

    if (+parsedCost > this.props.maxValue) return;

    this.setState({
      value: cleanValue,
      error: !testValue,
    });
    if (this.props.onChange) {
      this.props.onChange(e, parsedCost);
    }
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const parsed = parseCost(this.state.value.toString());

    const filteredCost = parsed != null && parsed >= 0 ? parsed : null;
    this.setState({
      error: false,
      value: filteredCost != null ? formatNumber(filteredCost / 100) : '',
      focus: false,
    });

    if (parsed !== filteredCost && this.props.onChange) {
      this.props.onChange(e, filteredCost);
    }
    if (this.props.onBlur) {
      this.props.onBlur(e, filteredCost);
    }
  };

  handleFocus = (e: SyntheticEvent<HTMLInputElement>) => {
    const parsed = parseCost(this.state.value);
    this.setState(state => ({
      value: parsed === 0 ? '' : state.value.toString().replace(/,/g, ''),
      focus: true,
    }));

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const parsedCost = parseCost(this.state.value.toString());
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e, parsedCost);
    }
  };

  render() {
    const currencyObj = currencies.find(obj => obj.code === this.props.currency);
    const currencyValue = `${currencyObj.symbol}${this.state.value}`;
    return (
      <TextField
        name={this.props.name}
        label={this.props.label}
        value={this.state.value ? currencyValue : ''}
        onClick={this.props.onClick}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
        autoFocus={this.props.autoFocus}
        error={this.props.error || this.state.error}
        inputRef={this.props.inputRef}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        className={this.props.className}
        placeholder={this.props.placeholder}
        required={this.props.required}
      />
    );
  }
}
