/* @flow */
import { type Field } from 'components/FieldSettings/types';

const opportunityFields: $ReadOnlyArray<Field> = [
  {
    id: 'name',
    kind: 'TEXT',
    label: 'Opportunity Name',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
  {
    id: 'value',
    kind: 'TEXT',
    label: 'Value',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
  {
    id: 'description',
    kind: 'TEXT',
    label: 'Description',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
  {
    id: 'company',
    kind: 'TEXT',
    label: 'Company',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
  {
    id: 'owner_id',
    kind: 'TEXT',
    label: 'Owner',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
];

export default opportunityFields;
