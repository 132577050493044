/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CategoryActionCell_categoryEdge$ref = any;
type CategoryActualAmountCell_categoryEdge$ref = any;
type CategoryActualOfBudgetedCell_categoryEdge$ref = any;
type CategoryBudgetedAmountCell_categoryEdge$ref = any;
type CategoryNameCell_categoryEdge$ref = any;
type CategoryPaidAmountCell_categoryEdge$ref = any;
type CategoryPaidOfActualCell_categoryEdge$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoriesTable_categoryEdges$ref: FragmentReference;
export type CategoriesTable_categoryEdges = $ReadOnlyArray<{|
  +budgetedAmount: number,
  +actualAmount: number,
  +paidAmount: number,
  +node: {|
    +id: string,
    +name: string,
  |},
  +$fragmentRefs: CategoryNameCell_categoryEdge$ref & CategoryBudgetedAmountCell_categoryEdge$ref & CategoryActualAmountCell_categoryEdge$ref & CategoryActualOfBudgetedCell_categoryEdge$ref & CategoryPaidAmountCell_categoryEdge$ref & CategoryPaidOfActualCell_categoryEdge$ref & CategoryActionCell_categoryEdge$ref,
  +$refType: CategoriesTable_categoryEdges$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CategoriesTable_categoryEdges",
  "type": "EventBudgetCategoryEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "budgetedAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "actualAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paidAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryNameCell_categoryEdge",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryBudgetedAmountCell_categoryEdge",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryActualAmountCell_categoryEdge",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryActualOfBudgetedCell_categoryEdge",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryPaidAmountCell_categoryEdge",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryPaidOfActualCell_categoryEdge",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryActionCell_categoryEdge",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetCategory",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6c49802aeab7eb1bdd63d19ef4e67a05';
module.exports = node;
