/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ScheduledEmailFilters_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduledEmailTemplate_org$ref: FragmentReference;
export type ScheduledEmailTemplate_org = {|
  +$fragmentRefs: ScheduledEmailFilters_org$ref,
  +$refType: ScheduledEmailTemplate_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ScheduledEmailTemplate_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ScheduledEmailFilters_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8cfa5593da0e28080785f87029b28f3f';
module.exports = node;
