/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import VideoIcon from 'images/registration/video.svg';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import convertVideoEmbedUrl from '../convertVideoEmbedUrl';
import generatePaddingProps from '../generatePaddingProps';

import type { VideoComponent_componentProps } from './__generated__/VideoComponent_componentProps.graphql';

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${props => props.componentProps.backgroundColor || 'initial'};
  text-align: ${props => props.componentProps.alignment};
`;

const StyledIframe = styled.iframe`
  max-width: 100%;
  width: ${props => `${props.componentProps.width}px`};
  height: ${props => `${props.componentProps.height}px`};
  margin: ${props => generatePaddingProps(props.componentProps.padding)};
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
`;

const StyledVideoIcon = styled(VideoIcon)`
  margin-right: 15px;
  color: #3ba9da;
`;

const Text = styled.div`
  color: #3ba9da;
  cursor: pointer;
`;

class VideoComponent extends React.Component<{
  componentProps: VideoComponent_componentProps,
  viewMode?: boolean,
  readOnly?: boolean,
  preview?: boolean,
  selectedComponent: SelectedComponent,
}> {
  render() {
    const {
      viewMode,
      readOnly,
      preview,
      selectedComponent,
      componentProps: { videoComponent },
    } = this.props;

    if (!videoComponent) {
      return null;
    }
    if (!videoComponent.url && readOnly) {
      return null;
    }
    const embeddedUrl = videoComponent.url && convertVideoEmbedUrl(videoComponent.url);
    const allowAutoPlay = readOnly && (!viewMode || preview);
    const componentProps = selectedComponent
      ? {
          ...videoComponent,
          padding: selectedComponent.selectedComponentPadding || videoComponent.padding,
          width:
            selectedComponent.selectedComponentWidth != null
              ? selectedComponent.selectedComponentWidth
              : videoComponent.width,
          height:
            selectedComponent.selectedComponentHeight != null
              ? selectedComponent.selectedComponentHeight
              : videoComponent.height,
        }
      : videoComponent;
    return (
      <>
        {embeddedUrl ? (
          <Container componentProps={videoComponent}>
            <StyledIframe
              src={`${embeddedUrl}${
                videoComponent.autoplay && allowAutoPlay ? '?autoplay=1&mute=1' : ''
              }`}
              componentProps={componentProps}
              allow="autoplay; fullscreen"
            />
          </Container>
        ) : (
          <EmptyContainer>
            <StyledVideoIcon />
            <Text>Add Video</Text>
          </EmptyContainer>
        )}
      </>
    );
  }
}

export default createFragmentContainer(VideoComponent, {
  componentProps: graphql`
    fragment VideoComponent_componentProps on RegistrationPageComponent {
      videoComponent {
        padding
        height
        width
        url
        autoplay
        alignment
        backgroundColor
      }
    }
  `,
});
