/* @flow */

const getLocation = () => {
  try {
    return window.location;
  } catch (err) {
    return undefined;
  }
};

export default function currentOrigin(): string {
  const location = getLocation();
  if (typeof location === 'undefined') {
    // Tests
    return '';
  }
  if (!location.origin) {
    // <= IE 10
    return `${location.protocol}//${location.host}`;
  }
  return location.origin;
}
