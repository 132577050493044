/**
 * @flow
 * @relayHash 85afb57fddd64a0fa1cf59e962f02e8a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SalesforceContent_org$ref = any;
export type SalesforceQueryVariables = {||};
export type SalesforceQueryResponse = {|
  +org: {|
    +salesforceAccount: ?{|
      +id: string
    |},
    +$fragmentRefs: SalesforceContent_org$ref,
  |}
|};
export type SalesforceQuery = {|
  variables: SalesforceQueryVariables,
  response: SalesforceQueryResponse,
|};
*/


/*
query SalesforceQuery {
  org {
    salesforceAccount {
      id
    }
    ...SalesforceContent_org
    id
  }
}

fragment SalesforceContent_org on Org {
  ...SalesforceWindow_org
  ...SalesforceAuth_org
  ...SalesforceSettings_org
  salesforceAccount {
    contactsSyncEnabled
    opportunitiesSyncEnabled
    id
  }
}

fragment SalesforceWindow_org on Org {
  ...SalesforceAuth_org
  ...SalesforceSettings_org
  salesforceAccount {
    contactsSyncEnabled
    opportunitiesSyncEnabled
    id
  }
}

fragment SalesforceAuth_org on Org {
  salesforceAccount {
    id
    ...CampaignSyncStatus_account
  }
  ...AccountConnector_org
  ...SyncOptions_org
}

fragment SalesforceSettings_org on Org {
  settings {
    id
    defaultTag
  }
}

fragment CampaignSyncStatus_account on SalesforceAccount {
  campaignSyncStatus {
    ...SyncStatusIndicator_status
  }
}

fragment AccountConnector_org on Org {
  salesforceAccount {
    username
    id
  }
}

fragment SyncOptions_org on Org {
  salesforceAccount {
    id
    host
    contactsSyncEnabled
    opportunitiesSyncEnabled
    opportunitySyncStatus {
      ...SyncStatusIndicator_status
    }
  }
  contactCustomFields: customFields(customizableType: [CONTACT], fieldNames: ["company_id"]) {
    totalCount
  }
}

fragment SyncStatusIndicator_status on SyncStatus {
  lastSuccessAt
  state
  errorMessage
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastSuccessAt",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "state",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "errorMessage",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SalesforceQuery",
  "id": null,
  "text": "query SalesforceQuery {\n  org {\n    salesforceAccount {\n      id\n    }\n    ...SalesforceContent_org\n    id\n  }\n}\n\nfragment SalesforceContent_org on Org {\n  ...SalesforceWindow_org\n  ...SalesforceAuth_org\n  ...SalesforceSettings_org\n  salesforceAccount {\n    contactsSyncEnabled\n    opportunitiesSyncEnabled\n    id\n  }\n}\n\nfragment SalesforceWindow_org on Org {\n  ...SalesforceAuth_org\n  ...SalesforceSettings_org\n  salesforceAccount {\n    contactsSyncEnabled\n    opportunitiesSyncEnabled\n    id\n  }\n}\n\nfragment SalesforceAuth_org on Org {\n  salesforceAccount {\n    id\n    ...CampaignSyncStatus_account\n  }\n  ...AccountConnector_org\n  ...SyncOptions_org\n}\n\nfragment SalesforceSettings_org on Org {\n  settings {\n    id\n    defaultTag\n  }\n}\n\nfragment CampaignSyncStatus_account on SalesforceAccount {\n  campaignSyncStatus {\n    ...SyncStatusIndicator_status\n  }\n}\n\nfragment AccountConnector_org on Org {\n  salesforceAccount {\n    username\n    id\n  }\n}\n\nfragment SyncOptions_org on Org {\n  salesforceAccount {\n    id\n    host\n    contactsSyncEnabled\n    opportunitiesSyncEnabled\n    opportunitySyncStatus {\n      ...SyncStatusIndicator_status\n    }\n  }\n  contactCustomFields: customFields(customizableType: [CONTACT], fieldNames: [\"company_id\"]) {\n    totalCount\n  }\n}\n\nfragment SyncStatusIndicator_status on SyncStatus {\n  lastSuccessAt\n  state\n  errorMessage\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SalesforceQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v0
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "SalesforceContent_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SalesforceQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "campaignSyncStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "SyncStatus",
                "plural": false,
                "selections": v1
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "host",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "contactsSyncEnabled",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "opportunitiesSyncEnabled",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "opportunitySyncStatus",
                "storageKey": null,
                "args": null,
                "concreteType": "SyncStatus",
                "plural": false,
                "selections": v1
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "contactCustomFields",
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"CONTACT\"],fieldNames:[\"company_id\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "customizableType",
                "value": [
                  "CONTACT"
                ],
                "type": "[CustomizableType!]!"
              },
              {
                "kind": "Literal",
                "name": "fieldNames",
                "value": [
                  "company_id"
                ],
                "type": "[String!]"
              }
            ],
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "defaultTag",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc7a9ae1fafc9472d98023e5d2310f14';
module.exports = node;
