/**
 * @flow
 * @relayHash ea5929039e2eadb2805377b666bb4377
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InformationSubmenu_event$ref = any;
type InformationSubmenu_org$ref = any;
export type InformationQueryVariables = {|
  eventSlug: string
|};
export type InformationQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: InformationSubmenu_event$ref
  |},
  +org: {|
    +$fragmentRefs: InformationSubmenu_org$ref
  |},
|};
export type InformationQuery = {|
  variables: InformationQueryVariables,
  response: InformationQueryResponse,
|};
*/


/*
query InformationQuery(
  $eventSlug: String!
) {
  event(slug: $eventSlug) {
    ...InformationSubmenu_event
    id
  }
  org {
    ...InformationSubmenu_org
    id
  }
}

fragment InformationSubmenu_event on Event {
  slug
  viewerCanViewROI
}

fragment InformationSubmenu_org on Org {
  viewerCanUpdate
  customFieldSections(sectionCustomizableType: EVENT) {
    edges {
      node {
        id
        name
        order
        numberOfActiveFields
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "InformationQuery",
  "id": null,
  "text": "query InformationQuery(\n  $eventSlug: String!\n) {\n  event(slug: $eventSlug) {\n    ...InformationSubmenu_event\n    id\n  }\n  org {\n    ...InformationSubmenu_org\n    id\n  }\n}\n\nfragment InformationSubmenu_event on Event {\n  slug\n  viewerCanViewROI\n}\n\nfragment InformationSubmenu_org on Org {\n  viewerCanUpdate\n  customFieldSections(sectionCustomizableType: EVENT) {\n    edges {\n      node {\n        id\n        name\n        order\n        numberOfActiveFields\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "InformationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "InformationSubmenu_event",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "InformationSubmenu_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "InformationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanViewROI",
            "args": null,
            "storageKey": null
          },
          v2
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanUpdate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFieldSections",
            "storageKey": "customFieldSections(sectionCustomizableType:\"EVENT\")",
            "args": [
              {
                "kind": "Literal",
                "name": "sectionCustomizableType",
                "value": "EVENT",
                "type": "[SectionCustomizableType!]!"
              }
            ],
            "concreteType": "CustomFieldSectionTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldSectionTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldSectionType",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "numberOfActiveFields",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fe104aaa62842cdca64d066852106ab7';
module.exports = node;
