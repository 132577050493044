/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';
import styled, { css } from 'styled-components';

import NavContacts from 'images/navigation/nav_contacts.svg';
import NavDashboard from 'images/navigation/nav_dashboard.svg';
import HelpIcon from 'images/navigation/nav_help.svg';
import NavInvite from 'images/navigation/nav_invite_members.svg';
import NavPreferences from 'images/navigation/nav_preferences.svg';
import NavReporting from 'images/navigation/nav_reporting.svg';
import NavWorkspace from 'images/navigation/nav_workspace.svg';
import InviteWindow from 'components/InviteWindow';
import Tooltip from 'components/material/Tooltip';

import ContactsMenu from '../../AllContacts/ContactsMenu';
import DashboardMenu from '../../Dashboard/DashboardMenu';
import ReportingMenu from '../../Reporting/ReportingMenu';
import SettingsMenu from '../../Settings/SettingsMenu';
import VendorsMenu from '../../Vendors/VendorsMenu';
import WorkspaceMenu from '../../Workspace/WorkspaceMenu';

import type { Navbar_org } from './__generated__/Navbar_org.graphql';

const Container = styled.div`
  z-index: 886;
  flex: 0 0 59px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 19px;
  border-right: 1px solid #e8e7ea;
  background: #fff;
`;

const StyledNavDashboard = styled(NavDashboard)`
  margin: 8px 0 9px;
  ${props =>
    props.active &&
    css`
      background: #252239;
      rect {
        stroke: #fff;
      }
      rect:nth-of-type(1),
      rect:nth-of-type(2),
      path {
        fill: #fff;
      }
    `}
`;

const StyledNavContacts = styled(NavContacts)`
  ${props =>
    props.active &&
    css`
      rect,
      circle {
        stroke: #fff;
      }
      path {
        fill: #fff;
      }
    `}
`;

const StyledNavWorkspace = styled(NavWorkspace)`
  margin: 2px 0 4px;
  ${props =>
    props.active &&
    css`
      rect:first-of-type {
        stroke: #fff;
      }
      rect:not(:first-of-type) {
        fill: #fff;
      }
      path {
        fill: #fff;
      }
    `}
`;

const StyledNavReporting = styled(NavReporting)`
  ${props =>
    props.active &&
    css`
      rect:nth-of-type(2) {
        stroke: #fff;
      }
      rect:not(:nth-of-type(2)) {
        fill: #fff;
      }
    `}
`;

const StyledNavPreferences = styled(NavPreferences)`
  ${props =>
    props.active &&
    css`
      path,
      circle {
        stroke: #fff;
      }
    `}
`;

const StyledInviteIcon = styled(NavInvite)`
  fill: none;
  width: 25px !important;
`;

const NavItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 40px;
  margin: 0 auto 20px;
  cursor: pointer;
  border-radius: 6px;

  svg {
    width: 20px;
  }

  ${props =>
    props.isActive &&
    css`
      background-color: #252239;
    `}
`;

const BottomActions = styled.div`
  ${NavItem} {
    height: 20px;
  }
`;

type Props = {
  location: Location,
  org: Navbar_org,
  onToggleSidebar: (shownMenu: string) => void,
  selectedMenu: ?string,
  accessToLegacyFeatures: boolean,
};

const isActive = (navItemName: string, selectedMenu: ?string, location: Location): ?boolean => {
  const navigationRoot = location.pathname.split('/')[1];
  if (selectedMenu) {
    if (selectedMenu === navItemName) {
      return true;
    }
    return undefined;
  }
  if (navigationRoot === navItemName) {
    return true;
  }
  return undefined;
};

export const menuConfig = (
  access: {
    +viewerCanCreateContacts: boolean,
    +viewerCanCreateVendors: boolean,
    +viewerCanViewWorkspace: boolean,
    +viewerCanViewReporting: boolean,
    +viewerCanUpdate: boolean,
  },
  accessToLegacyFeatures: boolean,
) => {
  const contactsMenuConfig = accessToLegacyFeatures
    ? {
        contacts: {
          pathPrefix: 'contacts',
          name: 'Contacts',
          component: ContactsMenu,
          svg: StyledNavContacts,
          access: access.viewerCanCreateContacts,
        },
      }
    : {
        vendors: {
          pathPrefix: 'vendors',
          name: 'Vendors',
          component: VendorsMenu,
          svg: StyledNavContacts,
          access: access.viewerCanCreateVendors,
        },
      };
  return {
    dashboard: {
      pathPrefix: 'dashboard',
      name: 'Dashboard',
      component: DashboardMenu,
      svg: StyledNavDashboard,
      access: true,
    },
    ...contactsMenuConfig,
    workspace: {
      pathPrefix: 'workspace',
      name: 'Workspace',
      component: WorkspaceMenu,
      svg: StyledNavWorkspace,
      access: access.viewerCanViewWorkspace,
    },
    reporting: {
      pathPrefix: 'reporting',
      name: 'Reporting',
      component: ReportingMenu,
      svg: StyledNavReporting,
      access: access.viewerCanViewReporting,
    },
    settings: {
      pathPrefix: 'settings',
      name: 'Settings',
      component: SettingsMenu,
      svg: StyledNavPreferences,
      access: access.viewerCanUpdate,
    },
  };
};

class Navbar extends React.PureComponent<Props, { showInviteWindow: boolean }> {
  state = { showInviteWindow: false };

  handleShowInviteWindow = () => {
    this.setState({ showInviteWindow: true });
  };

  handleHideInviteWindow = () => {
    this.setState({ showInviteWindow: false });
  };

  render() {
    const { location, org, onToggleSidebar, selectedMenu, accessToLegacyFeatures } = this.props;
    const menu = menuConfig(org, accessToLegacyFeatures);
    return (
      <Container>
        <div>
          {Object.keys(menu).map(menuKey => {
            const item = menu[menuKey];
            const active = isActive(item.pathPrefix, selectedMenu, location);
            const SvgIcon = item.svg;
            return (
              item.access && (
                <NavItem
                  key={item.pathPrefix}
                  isActive={active}
                  onClick={() => {
                    onToggleSidebar(item.pathPrefix);
                  }}
                >
                  <Tooltip label={active ? '' : item.name} placement="right">
                    <SvgIcon active={active ? 1 : 0} />
                  </Tooltip>
                </NavItem>
              )
            );
          })}
        </div>
        <BottomActions>
          {org.viewerCanManageStaff && (
            <>
              <NavItem key="easy_invite_button" onClick={this.handleShowInviteWindow}>
                <Tooltip label="Add member" placement="right">
                  <StyledInviteIcon />
                </Tooltip>
              </NavItem>
              {this.state.showInviteWindow &&
                document.body &&
                createPortal(
                  <InviteWindow onHide={this.handleHideInviteWindow} fromWindow="easy_button" />,
                  document.body,
                )}
            </>
          )}
          <NavItem key="easy_invite_button">
            <Tooltip label="Help" placement="right">
              <a href="https://help.circa.co" target="_blank" rel="noopener noreferrer">
                <HelpIcon />
              </a>
            </Tooltip>
          </NavItem>
        </BottomActions>
      </Container>
    );
  }
}

export default createFragmentContainer(Navbar, {
  org: graphql`
    fragment Navbar_org on Org {
      viewerCanUpdate
      viewerCanCreateContacts
      viewerCanCreateVendors
      viewerCanViewWorkspace
      viewerCanViewReporting
      viewerCanManageStaff
    }
  `,
});
