/* @flow */
import { getCookie } from 'utils/Cookies';

export default function readHTML(url: string): Promise<string> {
  return fetch(url, {
    headers: {
      Authorization: getCookie('user'),
    },
  }).then(response => {
    if (response.status >= 400) {
      return response.json().then(json => Promise.reject(new Error(json.errors)));
    }
    return response.text();
  });
}
