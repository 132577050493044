/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css, keyframes } from 'styled-components';

import type { TaskStatuses } from 'config/taskStatuses';

import Tags from 'components/material/Tags';

import TaskActivities from './TaskActivities';
import TaskAssignees from './TaskAssignees';
import TaskAttachments from './TaskAttachments';
import TaskContacts from './TaskContacts';
import TaskContentHeader from './TaskContentHeader';
import TaskDate from './TaskDate';
import TaskDependencies from './TaskDependencies';
import TaskDescription from './TaskDescription';
import TaskExpenses from './TaskExpenses';
import TaskReminder from './TaskReminder';
import TaskStatus from './TaskStatus';
import TaskSubtasks from './TaskSubtasks';
import TaskVendors from './TaskVendors';

import type { TaskContent_org } from './__generated__/TaskContent_org.graphql';
import type { TaskContent_task } from './__generated__/TaskContent_task.graphql';

const Content = styled.div`
  flex: 1 1 auto;
  padding: 15px 20px;
  min-width: 0;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  @media (${props => props.theme.mobileOnly}) {
    display: block;
  }
`;

const Column = styled.div`
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  @media (${props => props.theme.mobileOnly}) {
    margin: 0;
  }
`;

const StyledTags = styled(Tags)`
  margin-bottom: 20px;
  flex-shrink: 0;
  ${props =>
    props.withMargin
      ? css`
          width: 50%;
          margin-left: 40px;
          @media (${props.theme.mobileOnly}) {
            width: 100%;
            margin-left: 0;
          }
        `
      : css`
          max-width: 100%;
        `};
  > label {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.theme.rowPrimaryTextColor};
  }
`;

const taskCompleted = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const StatusContainer = styled.div`
  ${props =>
    props.subtasksCompleted &&
    css`
      animation: ${taskCompleted} 0.3s ease-out;
    `};
`;

class TaskContent extends React.PureComponent<
  {
    task: TaskContent_task,
    org: TaskContent_org,
    onUpdate: ({}) => void,
    onUpdateStatus: (status: TaskStatuses) => void,
    onAssign: string => void,
    onUnassign: string => void,
    onAddTag: string => void,
    onRemoveTag: string => void,
    onAddContact: string => void,
    onAddVendor: string => void,
    onAddCompany: string => void,
    onRemoveContact: string => void,
    onRemoveVendor: string => void,
    onRemoveCompany: string => void,
    onAddAttachment: ({}) => void,
    onRemoveAttachment: string => void,
    onRemoveExpense: string => void,
    onToggleExpenseModal: (expenseModalShown: boolean) => void,
    onToggleAddExpenseForm: (addExpenseForm: boolean) => void,
    onAddDependency: string => void,
    onAddDependent: string => void,
    onRemoveDependency: string => void,
    onRemoveDependent: string => void,
    sectionsShown: Array<string>,
    uploadingAttachments: Array<{}>,
    onSectionRemove: string => void,
    onInvite: () => void,
  },
  {
    subtasksCompleted: boolean,
    prevStatus: TaskStatuses,
  },
> {
  // Need prevStatus to keep in state, to determine just changed status
  // eslint-disable-next-line react/no-unused-state
  state = { prevStatus: this.props.task.status, subtasksCompleted: true };

  static getDerivedStateFromProps(
    props: $PropertyType<TaskContent, 'props'>,
    state: $PropertyType<TaskContent, 'state'>,
  ) {
    if (state.prevStatus === 'OPEN' && props.task.status === 'COMPLETED') {
      return { subtasksCompleted: true, prevStatus: 'COMPLETED' };
    }
    return { subtasksCompleted: false, prevStatus: props.task.status };
  }

  handleStatusToggle = () => {
    this.props.onUpdateStatus(this.props.task.status === 'OPEN' ? 'COMPLETED' : 'OPEN');
  };

  handleDateChange = ({ allDay, date }) => {
    this.props.onUpdate({ dueDate: date, dueDateAllDay: allDay });
  };

  handleDescriptionSave = (description: ?string) => {
    this.props.onUpdate({ description });
  };

  handleRemindAtChange = (remindAt: ?string) => {
    this.props.onUpdate({ remindAt });
  };

  render() {
    const {
      task,
      org,
      sectionsShown,
      onSectionRemove,
      onAssign,
      onUnassign,
      onUpdate,
      onAddTag,
      onRemoveTag,
      onAddContact,
      onAddVendor,
      onAddCompany,
      onRemoveContact,
      onRemoveVendor,
      onRemoveCompany,
      onAddAttachment,
      onRemoveAttachment,
      onRemoveExpense,
      onToggleExpenseModal,
      onToggleAddExpenseForm,
      onAddDependency,
      onAddDependent,
      onRemoveDependency,
      onRemoveDependent,
      uploadingAttachments,
      onInvite,
    } = this.props;

    const assignees = task.assignees.edges.map(edge => edge.node);

    const tags = task.tags.edges.map(edge => edge.node.id);
    const orgTags = org.tags.edges.map(edge => edge.node);

    const dateShown = task.dueDate || sectionsShown.includes('date');
    const reminderShown = task.remindAt || sectionsShown.includes('reminder');
    const assigneesShown = assignees.length > 0 || sectionsShown.includes('assignees');
    const tagsShown = tags.length > 0 || sectionsShown.includes('tags');
    const contactShown =
      task.contacts.edges.length > 0 ||
      task.companies.edges.length > 0 ||
      sectionsShown.includes('contact');
    const vendorShown = task.vendors.edges.length > 0 || sectionsShown.includes('vendor');
    const attachmentsShown =
      task.attachments.edges.length > 0 || sectionsShown.includes('attachments');
    const expensesShown =
      task.event.viewerCanViewBudget &&
      (task.expenses.edges.length > 0 || sectionsShown.includes('expenses'));
    const subtasksShown = task.subtasks.edges.length > 0 || sectionsShown.includes('subtasks');
    const dependenciesShown =
      task.dependencies.edges.length > 0 ||
      task.dependents.edges.length > 0 ||
      sectionsShown.includes('dependencies');

    const briefAttachmentIds = task.event.briefAttachments.edges.map(({ node }) => node.id);

    const briefData = {
      briefAttachmentIds,
      canUpdateBrief: task.event.viewerCanUpdate,
      eventId: task.event.id,
    };

    const completed = task.status === 'COMPLETED';

    return (
      <Content>
        <Row>
          <TaskContentHeader task={task} onUpdateName={onUpdate} />
        </Row>
        <Row>
          <StatusContainer subtasksCompleted={this.state.subtasksCompleted}>
            <TaskStatus
              completed={completed}
              onToggle={this.handleStatusToggle}
              disabled={!task.viewerCanUpdateStatus}
            />
          </StatusContainer>
          {(dateShown || reminderShown) && (
            <Column>
              {dateShown && (
                <TaskDate
                  date={task.dueDate}
                  allDay={task.dueDate ? task.dueDateAllDay : null}
                  onChange={this.handleDateChange}
                  timezone={task.event.tz}
                  disabled={!task.viewerCanUpdateDueDate}
                  onSectionRemove={onSectionRemove}
                  completed={completed}
                />
              )}
              {reminderShown && (
                <TaskReminder
                  tz={task.event.tz}
                  remindAt={task.remindAt}
                  disabled={!task.viewerCanUpdateDetails}
                  completed={completed}
                  onChange={this.handleRemindAtChange}
                  onSectionRemove={onSectionRemove}
                />
              )}
            </Column>
          )}
        </Row>

        <Row>
          {assigneesShown && (
            <TaskAssignees
              eventId={task.event.id}
              assignees={assignees}
              onAssign={onAssign}
              onUnassign={onUnassign}
              disabled={!task.viewerCanAssign}
              onInviteClick={onInvite}
            />
          )}
          {tagsShown && (
            <StyledTags
              tags={tags}
              orgTags={orgTags}
              onAddTag={onAddTag}
              onRemoveTag={onRemoveTag}
              disabled={!task.viewerCanUpdateTags}
              withMargin={assigneesShown}
            />
          )}
        </Row>

        <TaskDescription
          description={task.description}
          onSave={this.handleDescriptionSave}
          disabled={!task.viewerCanUpdateDetails}
        />
        {subtasksShown && <TaskSubtasks task={task} />}
        {dependenciesShown && (
          <TaskDependencies
            task={task}
            onAddDependency={onAddDependency}
            onAddDependent={onAddDependent}
            onRemoveDependency={onRemoveDependency}
            onRemoveDependent={onRemoveDependent}
          />
        )}
        {contactShown && (
          <TaskContacts
            task={task}
            onAddContact={onAddContact}
            onAddCompany={onAddCompany}
            onRemoveContact={onRemoveContact}
            onRemoveCompany={onRemoveCompany}
          />
        )}
        {vendorShown && (
          <TaskVendors task={task} onAddVendor={onAddVendor} onRemoveVendor={onRemoveVendor} />
        )}
        {expensesShown && (
          <TaskExpenses
            task={task}
            onRemove={onRemoveExpense}
            onToggleExpenseModal={onToggleExpenseModal}
            onToggleAddExpenseForm={onToggleAddExpenseForm}
          />
        )}
        {attachmentsShown && (
          <TaskAttachments
            task={task}
            onAddAttachment={onAddAttachment}
            onRemoveAttachment={onRemoveAttachment}
            uploadingAttachments={uploadingAttachments}
            briefAttachmentIds={briefAttachmentIds}
            briefData={briefData}
          />
        )}
        <TaskActivities taskSlug={task.slug} />
      </Content>
    );
  }
}

export default createFragmentContainer(TaskContent, {
  task: graphql`
    fragment TaskContent_task on Deliverable {
      status
      dueDate
      dueDateAllDay
      remindAt
      description
      slug
      ...TaskContacts_task
      ...TaskVendors_task
      ...TaskAttachments_task
      ...TaskExpenses_task
      ...TaskSubtasks_task
      ...TaskDependencies_task
      ...TaskContentHeader_task
      viewerCanUpdateStatus
      viewerCanUpdateTags
      viewerCanUpdateDueDate
      viewerCanUpdateDetails
      viewerCanAssign
      contacts {
        edges {
          node {
            id
          }
        }
      }
      companies {
        edges {
          node {
            id
          }
        }
      }
      assignees {
        edges {
          node {
            ...TaskAssignees_assignees
          }
        }
      }
      vendors {
        edges {
          node {
            id
          }
        }
      }
      tags {
        edges {
          node {
            id
          }
        }
      }
      attachments {
        edges {
          node {
            id
          }
        }
      }
      expenses {
        edges {
          node {
            id
          }
        }
      }
      subtasks {
        edges {
          node {
            id
          }
        }
      }
      dependencies {
        edges {
          node {
            id
          }
        }
      }
      dependents {
        edges {
          node {
            id
          }
        }
      }
      event {
        id
        tz
        viewerCanViewBudget
        viewerCanUpdate
        briefAttachments {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `,
  org: graphql`
    fragment TaskContent_org on Org {
      tags {
        edges {
          node {
            ...Tags_orgTags
          }
        }
      }
    }
  `,
});
