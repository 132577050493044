/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CustomizableBoolean_customizable$ref = any;
type CustomizableCurrency_customizable$ref = any;
type CustomizableDate_customizable$ref = any;
type CustomizableLink_customizable$ref = any;
type CustomizableMultiselect_customizable$ref = any;
type CustomizableNumber_customizable$ref = any;
type CustomizableSelect_customizable$ref = any;
type CustomizableText_customizable$ref = any;
type CustomizableTextarea_customizable$ref = any;
type CustomizableUserMultiselect_customizable$ref = any;
type CustomizableUserSelect_customizable$ref = any;
type OpportunityAmount_opportunity$ref = any;
type OpportunityAssociatedContacts_opportunity$ref = any;
type OpportunityCompany_opportunity$ref = any;
type OpportunityDescription_opportunity$ref = any;
type OpportunityName_opportunity$ref = any;
type OpportunityOwner_opportunity$ref = any;
type OpportunitySalesforceId_opportunity$ref = any;
type OpportunityStatus_opportunity$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OpportunitiesList_opportunities$ref: FragmentReference;
export type OpportunitiesList_opportunities = $ReadOnlyArray<{|
  +id: string,
  +$fragmentRefs: OpportunityAmount_opportunity$ref & OpportunityCompany_opportunity$ref & OpportunityStatus_opportunity$ref & OpportunityDescription_opportunity$ref & OpportunitySalesforceId_opportunity$ref & OpportunityName_opportunity$ref & OpportunityOwner_opportunity$ref & OpportunityAssociatedContacts_opportunity$ref & CustomizableText_customizable$ref & CustomizableTextarea_customizable$ref & CustomizableLink_customizable$ref & CustomizableDate_customizable$ref & CustomizableBoolean_customizable$ref & CustomizableNumber_customizable$ref & CustomizableCurrency_customizable$ref & CustomizableSelect_customizable$ref & CustomizableMultiselect_customizable$ref & CustomizableUserSelect_customizable$ref & CustomizableUserMultiselect_customizable$ref,
  +$refType: OpportunitiesList_opportunities$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "OpportunitiesList_opportunities",
  "type": "SalesforceOpportunityType",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeAmount",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCompanyId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStatus",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeDescription",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeSalesforceId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeName",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeOwnerId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeAssociatedContacts",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableText",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableTextarea",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableLink",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableDate",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableBoolean",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableNumber",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableCurrency",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableMultiselect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserMultiselect",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeAmount",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunityAmount_opportunity",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCompanyId",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunityCompany_opportunity",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeStatus",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunityStatus_opportunity",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeDescription",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunityDescription_opportunity",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeSalesforceId",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunitySalesforceId_opportunity",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeName",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunityName_opportunity",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeOwnerId",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunityOwner_opportunity",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeAssociatedContacts",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunityAssociatedContacts_opportunity",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableText",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableText_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableTextarea",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableTextarea_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableLink",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableLink_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableDate",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableDate_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableBoolean",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableBoolean_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableNumber",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableNumber_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableCurrency",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableCurrency_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableSelect",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableSelect_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableMultiselect",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableMultiselect_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableUserSelect",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableUserSelect_customizable",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableUserMultiselect",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableUserMultiselect_customizable",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '09d44c8bcc4b2cc194e8869d094735c4';
module.exports = node;
