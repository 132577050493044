/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type PageComponents_org$ref = any;
type PageStyling_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Design_org$ref: FragmentReference;
export type Design_org = {|
  +syncedToIbmWm: boolean,
  +$fragmentRefs: PageComponents_org$ref & PageStyling_org$ref,
  +$refType: Design_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Design_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "syncedToIbmWm",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PageComponents_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PageStyling_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '92513e0d54eb8ce66b1385edccbcc3e9';
module.exports = node;
