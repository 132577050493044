/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateOrgMutationResponse,
  updateOrgMutationVariables,
} from './__generated__/updateOrgMutation.graphql';

const mutation = graphql`
  mutation updateOrgMutation($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      org {
        id
        name
      }
    }
  }
`;

type Variables = {
  name?: string,
};

export default function updateOrg(org: Variables): Promise<updateOrgMutationResponse> {
  const variables: updateOrgMutationVariables = {
    input: { ...org },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateOrg: {
        org,
      },
    },
  });
}
