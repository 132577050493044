/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { EventRequestFormsSubmissionsColumn_requestForm } from './__generated__/EventRequestFormsSubmissionsColumn_requestForm.graphql';

const Link = styled.a`
  color: #4a5665;
  &:hover {
    text-decoration: underline;
  }
`;
class EventRequestFormsSubmissionsColumn extends React.PureComponent<{
  requestForm: EventRequestFormsSubmissionsColumn_requestForm,
}> {
  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  render() {
    return (
      <Link
        href={`/dashboard/event_requests?requestFormIds=${this.props.requestForm.id}`}
        onClick={this.stopPropagation}
      >
        {this.props.requestForm.submissions.totalCount}
      </Link>
    );
  }
}

export default createFragmentContainer(
  EventRequestFormsSubmissionsColumn,
  graphql`
    fragment EventRequestFormsSubmissionsColumn_requestForm on EventRequestForm {
      id
      submissions {
        totalCount
      }
    }
  `,
);
