/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation updateEventTabsMutation($input: UpdateEventTabsInput!) {
    updateEventTabs(input: $input) {
      event {
        id
        shownTabs
      }
    }
  }
`;

export default function updateEventTabs(eventId: string, tabs: $ReadOnlyArray<string>) {
  commitModernMutation({
    mutation,
    variables: {
      input: {
        eventId,
        tabs,
      },
    },
    optimisticResponse: {
      updateEventTabs: {
        event: {
          id: eventId,
          shownTabs: tabs,
        },
      },
    },
  });
}
