/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import type { TasksCalendarTask_task } from './__generated__/TasksCalendarTask_task.graphql';

const TaskRow = styled.div`
  margin: 3px 6px 0 6px;
  padding: 0 12px;
  font-size: 12px;
  color: ${props => props.theme.rowPrimaryTextColor};
  border: 1px solid ${props => props.theme.secondaryActionColor};
  background: ${props => props.theme.contentBackgroundColor};
  border-radius: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props =>
    props.completed &&
    css`
      background: ${props.theme.highlightColor};
      color: ${props.theme.checkboxTextColor};
      border-color: transparent;
      i {
        margin-right: 5px;
      }
    `};
  ${props =>
    props.isOverdue &&
    props.completed &&
    css`
      &:not(:hover) {
        color: ${props.theme.secondaryActionTextColor};
        background: ${props.theme.disabledActionColor};
        border-color: transparent;
      }
    `};
  ${props =>
    props.isOverdue &&
    !props.completed &&
    css`
      color: ${props.theme.negativeActionColor};
      border-color: currentColor;
    `};
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 6px;
    i {
      margin-right: 1px;
    }
  }
`;

class TasksCalendarTask extends React.Component<{
  task: TasksCalendarTask_task,
  onGetTaskLink: (taskSlug: string) => string,
  tz: string,
}> {
  render() {
    const { task, onGetTaskLink, tz } = this.props;

    const isCompleted = task.status === 'COMPLETED';
    const isOverdue =
      !isCompleted &&
      task.dueDate &&
      moment()
        .tz(tz)
        .isAfter(task.dueDate, task.dueDateAllDay ? 'day' : null);

    return (
      <Link to={onGetTaskLink(task.parent ? task.parent.slug : task.slug)} key={task.id}>
        <TaskRow completed={isCompleted} isOverdue={isOverdue}>
          {isCompleted && <i className="fa fa-fw fa-check" />}
          {task.name}
        </TaskRow>
      </Link>
    );
  }
}

export default createFragmentContainer(
  TasksCalendarTask,
  graphql`
    fragment TasksCalendarTask_task on Deliverable {
      id
      status
      name
      slug
      dueDate
      dueDateAllDay
      parent {
        slug
      }
    }
  `,
);
