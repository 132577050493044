/* @flow */
/* global google */
import type { GooglePlacePrediction } from './locationTypes';

declare var google: any;

export type MapsAutocompleteResults = Array<GooglePlacePrediction>;

/**
 * The Google Maps API library must already be loaded to call this.
 */
export default function requestMapsAutocomplete(query: string): Promise<MapsAutocompleteResults> {
  return new Promise((resolve, reject) => {
    if (query === '') {
      resolve([]);
    }

    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({
      input: query,
    }, (predictions, status) => {
      if (status !== 'OK' && status !== 'ZERO_RESULTS') {
        reject({
          message: `Bad google maps status: ${status}`,
        });
      }

      resolve(predictions);
    });
  });
}
