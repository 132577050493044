/* @flow */
import contactFields from './contactFields';
import eventContactFields from './eventContactFields';
import eventFields from './eventFields';
import opportunityFields from './opportunityFields';
import type { GroupField } from './types';

import {
  type SalesforceKindType,
  type SalesforceMapping_org,
} from '../__generated__/SalesforceMapping_org.graphql';

type Fields = {
  [SalesforceKindType]: $ReadOnlyArray<GroupField>,
};

export default (org: SalesforceMapping_org) =>
  ({
    EVENT: eventFields(org),
    CONTACT: contactFields(org, 'CONTACT'),
    LEAD: contactFields(org, 'LEAD'),
    EVENT_CONTACT: eventContactFields(org),
    OPPORTUNITY: opportunityFields(org),
  }: Fields);
