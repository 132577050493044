/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CompanyContactActionsColumn_contact$ref = any;
type CompanyContactEmail_contact$ref = any;
type CompanyContactName_contact$ref = any;
type CompanyContactTitle_contact$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompanyContactsList_contacts$ref: FragmentReference;
export type CompanyContactsList_contacts = $ReadOnlyArray<{|
  +id: string,
  +$fragmentRefs: CompanyContactEmail_contact$ref & CompanyContactName_contact$ref & CompanyContactTitle_contact$ref & CompanyContactActionsColumn_contact$ref,
  +$refType: CompanyContactsList_contacts$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CompanyContactsList_contacts",
  "type": "Contact",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompanyContactEmail_contact",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompanyContactName_contact",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompanyContactTitle_contact",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompanyContactActionsColumn_contact",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '844d5ee6eb419f6e50d3f5f01ed93c54';
module.exports = node;
