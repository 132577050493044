/* @flow */
import * as React from 'react';

import StatelessTooltip from './portal/StatelessTooltip';

export type TooltipPlacement = 'top' | 'bottom' | 'right' | 'left';

export default class ToolTip extends React.Component<
  {
    className?: string,
    style?: {},
    children?: React.Node,
    label: ?any,
    innerRef?: (?HTMLDivElement) => void,
    placement?: TooltipPlacement,
    shouldShowTooltip?: (element: HTMLElement) => boolean,
    additionalOffset?: number,
  },
  {
    tooltipVisible: boolean,
  },
> {
  state = {
    tooltipVisible: false,
  };

  parent: ?HTMLDivElement;

  onMouseEnter = (event: SyntheticEvent<HTMLElement>) => {
    if (!this.props.shouldShowTooltip || this.props.shouldShowTooltip(event.currentTarget)) {
      this.setState({ tooltipVisible: true });
    }
  };

  onMouseLeave = () => {
    this.setState(state => (state.tooltipVisible ? { tooltipVisible: false } : null));
  };

  renderContainer = (): React.Node => {
    return <div>this.props.children</div>;
  };

  render() {
    const { children, className, style, label, innerRef, shouldShowTooltip, ...props } = this.props;

    return [
      <div
        style={style}
        className={className}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={p => {
          this.parent = p;
          if (innerRef) {
            innerRef(p);
          }
        }}
        key="parent"
      >
        {children}
      </div>,
      this.parent && label ? (
        <StatelessTooltip
          {...props}
          active={this.state.tooltipVisible}
          parent={this.parent}
          key="tooltip"
        >
          {label}
        </StatelessTooltip>
      ) : null,
    ];
  }
}
