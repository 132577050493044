/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import intersection from 'lodash/intersection';
import moment from 'moment';

import downloadedContacts from 'utils/analytics/downloadedContacts';
import type { FieldType } from 'utils/customization/types';

import ExportIcon from 'images/events/export.svg';
import Button, { MinimalButton } from 'components/budget/Button';
import ContactsFilters from 'components/Contacts/ContactsFilters';
import ContactsImportWindow from 'components/Contacts/ContactsImportWindow';
import ContactsMassUpdatePanel, {
  type AvailableContactsType,
} from 'components/Contacts/ContactsMassUpdatePanel';
import {
  type InputVariableFilters,
  getColumnsShowConfig,
} from 'components/Contacts/contactsTableColumnSettings';
import ContactWindow from 'components/Contacts/ContactWindow';
import { type ParsedContactsFilters } from 'components/Contacts/lib/parseContactsFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ColumnFilter from 'components/material/ColumnFilters';
import Search from 'components/Search';
import ShareDropdown from 'components/ShareDropdown';

import headerTitles from '../lib/headerTitles';
import OrgContactsSearchSuggestion from './OrgContactsSearchSuggestion';

import type { OrgContactsHeaderBar_org } from './__generated__/OrgContactsHeaderBar_org.graphql';
import type { OrgContactsHeaderBarQueryResponse } from './__generated__/OrgContactsHeaderBarQuery.graphql';

const Container = styled.div`
  padding-top: 25px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &:first-of-type {
    margin-bottom: 21px;
  }
  @media (min-width: 800px) and (max-width: 1085px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const RowItem = styled.div`
  flex: 1;
  min-height: 31px;
  margin-bottom: 13px;

  &:first-of-type {
    display: flex;
  }
`;

const TableActions = styled.div`
  display: flex;
  flex: 0 auto;
  margin-right: 5px;
`;

const StyledButton = styled(Button)`
  flex: 0 auto;
  min-width: 116px;
  margin-right: 20px;
  padding-top: 9px;
`;

const StyledSearch = styled(Search)`
  margin-right: 10px;
`;

const ActionsDropdown = styled(ShareDropdown)`
  float: right;
  padding-left: 0;
  border-left: none;
  > i {
    font-size: 20px;
  }
`;

const StyledExportIcon = styled(ExportIcon)`
  margin-right: 10px;
`;

const ExportButton = styled(MinimalButton)`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 5px;
`;

const query = graphql`
  query OrgContactsHeaderBarQuery($filters: ContactFilters!, $columns: [String!]) {
    org {
      contactReportCSVProcessId(filters: $filters, columns: $columns)
    }
    me {
      email
    }
  }
`;

class OrgContactsHeaderBar extends React.PureComponent<
  {
    org: OrgContactsHeaderBar_org,
    history: RouterHistory,
    filters: ParsedContactsFilters,
    contactFields: $ReadOnlyArray<FieldType>,
    filterInputs: InputVariableFilters,
    shownColumns: $ReadOnlyArray<string>,
    pathPrefix: string,
    onSearch: (query: string) => void,
    onColumnsChange: (shownColumns: $ReadOnlyArray<string>) => void,
    onUpdateTableColumnWidths: () => void,
    currentSelectedContacts: AvailableContactsType,
    rawContactFields: $ReadOnlyArray<FieldType>,
    allContactsSelected: boolean,
    orgContactsCount: number,
  },
  {
    adding: boolean,
    importing: boolean,
    showCsvDownloadOverlay: boolean,
  },
> {
  state = {
    adding: false,
    importing: false,
    showCsvDownloadOverlay: false,
  };

  handleContactAddStart = () => {
    this.setState({ adding: true });
  };

  handleContactAddEnd = () => {
    this.setState({ adding: false });
  };

  handleCsvExport = () => {
    this.setState({ showCsvDownloadOverlay: true });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCsvDownloadOverlay: false });

    downloadedContacts({
      eventId: null,
      eventName: null,
      teamId: null,
      teamName: null,
      fromWindow: 'org',
    });
  };

  handleImport = () => {
    this.setState({ importing: true });
  };

  handleImportEnd = () => {
    this.setState({ importing: false });
  };

  renderDownloadOverlay = () => {
    const columns = intersection(
      this.props.contactFields.map(field => field.fieldName || field.id),
      this.props.shownColumns,
    );
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ filters: this.props.filterInputs, columns }}
        renderSuccess={(response: OrgContactsHeaderBarQueryResponse) => {
          return (
            <DownloadOverlayWithEmail
              processUUID={response.org.contactReportCSVProcessId}
              fileName={`Workspace Contacts Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleCsvDownloadEnd}
              email={response.me.email}
              exportable="contacts_csv"
              onDownload={this.handleCsvExport}
            />
          );
        }}
        renderLoading={() => null}
      />
    );
  };

  render() {
    const {
      org,
      org: { viewerCanCreateContacts, salesforceAccount },
      history,
      shownColumns,
      onColumnsChange,
      filters,
      contactFields,
      onSearch,
      currentSelectedContacts,
      filterInputs,
      onUpdateTableColumnWidths,
      rawContactFields,
      pathPrefix,
      allContactsSelected,
    } = this.props;
    const searchObject = filters.search;

    const contactColumns = getColumnsShowConfig(contactFields);

    const leadStatuses = salesforceAccount
      ? salesforceAccount.leadStatuses.edges.map(({ node }) => ({
          value: node.id,
          label: node.name,
        }))
      : [];

    const title = `${org.name}'s ${headerTitles[pathPrefix.split('/').pop()]}`;
    return (
      <Container>
        <Row>
          <Title>{title}</Title>
          <ExportButton
            onClick={this.handleCsvExport}
            label={
              <>
                <StyledExportIcon />
                Export
              </>
            }
          />
          {this.state.showCsvDownloadOverlay && this.renderDownloadOverlay()}
        </Row>
        <Row>
          <RowItem>
            {currentSelectedContacts.length === 0 && (
              <>
                {viewerCanCreateContacts && (
                  <StyledButton onClick={this.handleContactAddStart} primary>
                    + Add Contact
                  </StyledButton>
                )}

                <StyledSearch
                  onSearch={onSearch}
                  search={searchObject && searchObject.search}
                  exactSearch={searchObject && searchObject.exactMatch}
                  placeholder="Search All Contacts"
                  suggestionComponent={props => {
                    return this.props.orgContactsCount === 0 ? (
                      <OrgContactsSearchSuggestion
                        {...props}
                        handleContactAddStart={this.handleContactAddStart}
                      />
                    ) : null;
                  }}
                />
                {this.state.adding && (
                  <ContactWindow
                    onHide={this.handleContactAddEnd}
                    fromWindow="org contacts"
                    filterVariables={filterInputs}
                  />
                )}
              </>
            )}
            {currentSelectedContacts.length > 0 && (
              <ContactsMassUpdatePanel
                selectedContacts={currentSelectedContacts}
                orgId={org.id}
                allContactsSelected={allContactsSelected}
                contactFields={contactFields}
                filters={filterInputs}
                viewerCanRemoveContacts={org.viewerCanRemoveContacts}
                onUpdateTableColumnWidths={onUpdateTableColumnWidths}
                fromWindow="org contacts"
              />
            )}
          </RowItem>
          <RowItem>
            <TableActions>
              <ContactsFilters
                history={history}
                filters={filters}
                filterOptions={contactFields}
                leadStatuses={leadStatuses}
              />
              <ColumnFilter
                filters={contactColumns}
                shownFilters={shownColumns}
                onFilterChange={onColumnsChange}
              />
              <ActionsDropdown
                options={[
                  {
                    label: 'Import Contacts (CSV)',
                    icon: 'cloud-upload',
                    onClick: this.handleImport,
                  },
                ]}
              />
            </TableActions>
          </RowItem>
        </Row>
        {this.state.importing && (
          <ContactsImportWindow
            onClose={this.handleImportEnd}
            fromWindow="org contacts"
            rawContactFields={rawContactFields}
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  OrgContactsHeaderBar,
  graphql`
    fragment OrgContactsHeaderBar_org on Org {
      id
      name
      viewerCanCreateContacts
      viewerCanRemoveContacts
      salesforceAccount {
        leadStatuses {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  `,
);
