/* @flow */

import React from 'react';
import { type Location, Redirect, Route, Switch } from 'react-router-dom';

import reservedPathVariables from 'config/reservedPathVariables';

import FeatureAccessContext from 'contexts/FeatureAccess';

import AuthedOnlyRoute from 'utils/routing/AuthedOnlyRoute';
import PublicOnlyRoute from 'utils/routing/PublicOnlyRoute';
import appSubdomain from 'utils/url/appSubdomain';

import NotFound from 'components/NotFound';

import Container from '.';
import Auth from './Auth';
import AutoLogin from './Auth/AutoLogin';
import AutoLogout from './Auth/AutoLogout';
import EmailConfirm from './Emails/Confirm';
import EventListPreview from './EventListPreview';
import EventRequestFormBuilder from './EventRequestFormBuilder';
import EventRequestSharedForm from './EventRequestSharedForm';
import Main from './Main';
import Brief from './Main/Event/Brief';
import BriefShared from './Main/Event/BriefShared';
import Registration from './Main/Event/Registration';
import PublicResourceRoutes from './PublicResourceRoutes';
import SharedEventList from './SharedEventList';

export default class AppRoutes extends React.PureComponent<{ location: Location }> {
  static contextType = FeatureAccessContext;

  renderLegacyRoute = (path: string, component: React$ComponentType<any>) => {
    if (this.context.legacyFeatures) {
      return <AuthedOnlyRoute path={path} component={component} />;
    }

    return null;
  };

  render() {
    const subdomain = appSubdomain();
    const keyword = this.props.location.pathname.split('/')[1];
    const filteredReservedVariables = reservedPathVariables.filter(
      pathVariable => pathVariable !== 'contacts' || this.context.legacyFeatures,
    );
    const showPublicRoute = !filteredReservedVariables.includes(keyword);

    return (
      <Container>
        <Switch>
          <Redirect from="/upgrade" to="/workspace/billing" />
          <Route exact path="/logout" component={AutoLogout} />
          <Route exact path="/login/:token" component={AutoLogin} />
          <PublicOnlyRoute exact path={Auth.path} component={Auth} />
          <Route path="/emails/confirm/:token" exact component={EmailConfirm} />
          <Route path="/events/:eventSlug/share/brief/:listToken?" component={BriefShared} />
          <Route path="/request/:token/:submitToken?" exact component={EventRequestSharedForm} />
          <Route path="/not_found" exact component={NotFound} />
          <AuthedOnlyRoute
            path="/dashboard/saved_view_preview"
            exact
            component={EventListPreview}
          />
          <AuthedOnlyRoute path="/dashboard/saved_view_preview/share" component={SharedEventList} />
          <AuthedOnlyRoute path="/events/:eventSlug/brief" component={Brief} />
          {this.renderLegacyRoute('/registration_builder', Registration)}
          {this.renderLegacyRoute('/events/:eventSlug/registration_builder', Registration)}
          <AuthedOnlyRoute
            path="/settings/requests/:requestFormId"
            component={EventRequestFormBuilder}
          />
          {showPublicRoute && (
            <Route
              path={`${subdomain ? '' : '/:orgName'}/:resourceToken?`}
              component={PublicResourceRoutes}
            />
          )}
          <AuthedOnlyRoute component={Main} />
          <Route component={NotFound} />
        </Switch>
      </Container>
    );
  }
}
