/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';

import { type Event } from 'graph/mutations/event/updateEvent';

import InlineDateField from 'components/budget/Table/InlineDateField';
import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { EventEndDate_event } from './__generated__/EventEndDate_event.graphql';

const Date = styled.div`
  ${props =>
    props.isOverdue &&
    css`
      color: ${props.theme.negativeActionColor};
    `};
`;

class EventEndDate extends React.PureComponent<{
  event: EventEndDate_event,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdate?: (changedProps: $Shape<Event>) => Promise<void>,
}> {
  handleSave = ({ date, hideTime }) => {
    const { onUpdate, updateColumnWidth } = this.props;
    if (onUpdate) {
      onUpdate({ endDate: date, endDateAllDay: hideTime });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    if (!value) {
      return null;
    }
    const {
      event: { startDate },
    } = this.props;
    const isValid = !!startDate && moment(value).isAfter(startDate);
    if (!isValid) {
      return 'Should be after start date';
    }
    return null;
  };

  renderDate() {
    const {
      event: { endDate, endDateAllDay, tz },
    } = this.props;
    const hideTime = !!endDateAllDay;

    return hideTime ? (
      <Date>{formatDate(moment(endDate).tz(tz))}</Date>
    ) : (
      <DateTimeTooltip date={moment.tz(endDate, tz)} />
    );
  }

  render() {
    const {
      updateColumnWidth,
      event: { endDate, endDateAllDay, tz },
      readOnly,
    } = this.props;
    const date = endDate && moment.tz(endDate, tz);

    if (readOnly) {
      return date ? this.renderDate() : null;
    }

    return (
      <InlineDateField
        date={endDate}
        tz={tz}
        placeholder="Add End Date"
        hideTime={!!endDateAllDay}
        getError={this.getError}
        onChange={this.handleSave}
        updateColumnWidth={updateColumnWidth}
      />
    );
  }
}

export default createFragmentContainer(
  EventEndDate,
  graphql`
    fragment EventEndDate_event on Event {
      startDate
      endDate
      endDateAllDay
      tz
    }
  `,
);
