/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CategoryPill_category$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpenseBudgetCategoryCell_expense$ref: FragmentReference;
export type ExpenseBudgetCategoryCell_expense = {|
  +budgetCategory: ?{|
    +id: string,
    +$fragmentRefs: CategoryPill_category$ref,
  |},
  +$refType: ExpenseBudgetCategoryCell_expense$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExpenseBudgetCategoryCell_expense",
  "type": "Expense",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetCategory",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetCategory",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "CategoryPill_category",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '71ca60c790fd13c9c2b7034121066d3a';
module.exports = node;
