/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import getTextSize from 'utils/getTextSize';

import { type ColumnConfiguration } from 'components/material/table';

import EventRequestsTableRow from './EventRequestsTableRow';

import type { EventRequestsTable_eventRequestSubmissions } from './__generated__/EventRequestsTable_eventRequestSubmissions.graphql';

const TableContainer = styled.div`
  height: calc(100% - 40px); // Subtract 40px for totals and pagination
  overflow-x: hidden;
  background: #fff;
  > div:first-child {
    border-top: none;
  }
`;

class EventRequestsTable extends React.Component<{
  eventRequestSubmissions: EventRequestsTable_eventRequestSubmissions,
  viewerCanManageRequestForms: boolean,
  tz: string,
  history: RouterHistory,
  location: Location,
  shownColumns: ColumnConfiguration,
  onColumnSizeChange: (value: string, width: number) => void,
}> {
  handleColumnLoad = (value: string, str: string, extraSpace = 0) => {
    // 15 is the column padding, 500 is max width
    const columnSize = Math.min(getTextSize(str) + 15 + extraSpace, 500);

    if (columnSize > this.props.shownColumns.find(c => c.value === value).width) {
      this.props.onColumnSizeChange(value, columnSize);
    }
  };

  render() {
    return (
      <TableContainer>
        {this.props.eventRequestSubmissions.map(eventRequestSubmission => (
          <EventRequestsTableRow
            key={eventRequestSubmission.id}
            eventRequestSubmission={eventRequestSubmission}
            tz={this.props.tz}
            history={this.props.history}
            location={this.props.location}
            shownColumns={this.props.shownColumns}
            onColumnLoad={this.handleColumnLoad}
            viewerCanManageRequestForms={this.props.viewerCanManageRequestForms}
          />
        ))}
      </TableContainer>
    );
  }
}

export default createFragmentContainer(
  EventRequestsTable,
  graphql`
    fragment EventRequestsTable_eventRequestSubmissions on EventRequestSubmission
    @relay(plural: true) {
      id
      ...EventRequestsTableRow_eventRequestSubmission
    }
  `,
);
