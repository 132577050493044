/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ExpenseActionCell_expense$ref = any;
type ExpenseActualAmountCell_expense$ref = any;
type ExpenseBudgetCategoryCell_expense$ref = any;
type ExpenseNameCell_expense$ref = any;
type ExpenseNoteCell_expense$ref = any;
type ExpensePaymentStatusCell_expense$ref = any;
type ExpenseTeamSplitCell_expense$ref = any;
type ExpenseVendorCell_expense$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpensesTableRenderer_expenses$ref: FragmentReference;
export type ExpensesTableRenderer_expenses = $ReadOnlyArray<{|
  +id: string,
  +$fragmentRefs: ExpenseNameCell_expense$ref & ExpenseVendorCell_expense$ref & ExpenseBudgetCategoryCell_expense$ref & ExpenseActualAmountCell_expense$ref & ExpensePaymentStatusCell_expense$ref & ExpenseNoteCell_expense$ref & ExpenseActionCell_expense$ref & ExpenseTeamSplitCell_expense$ref,
  +$refType: ExpensesTableRenderer_expenses$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExpensesTableRenderer_expenses",
  "type": "Expense",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseNameCell_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseVendorCell_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseBudgetCategoryCell_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseActualAmountCell_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpensePaymentStatusCell_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseNoteCell_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseActionCell_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseTeamSplitCell_expense",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f757a8624ac2a5504e5e3a59210c4f6c';
module.exports = node;
