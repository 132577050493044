/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import throttle from 'lodash/throttle';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import AutocompleteInput from 'components/material/AutocompleteInput';
import UsersImportWindow from 'components/UsersImportWindow';

import InviteWindow, { type User } from '../index';
import NewMemberRow from './NewMemberRow';
import OrgUserSearchRow from './OrgUserSearchRow';

import type { OrgUserSearchQuery_response } from './__generated__/OrgUserSearchQuery.graphql';

const StyledAutocompleteInput = styled(AutocompleteInput)`
  flex: 1 1 auto;
`;

const query = graphql`
  query OrgUserSearchQuery(
    $query: String!
    $hasTeamAccess: Boolean
    $limitedAccessOrMore: Boolean
  ) {
    org {
      viewerCanManageStaff
      users(
        first: 3
        query: $query
        hasTeamAccess: $hasTeamAccess
        limitedAccessOrMore: $limitedAccessOrMore
      ) {
        edges {
          node {
            id
            firstName
            lastName
            email
            ...MaterialAvatar_user
            ...OrgUserSearchRow_user
          }
        }
      }
    }
  }
`;

export default class OrgUserSearch extends React.PureComponent<
  {
    label?: string,
    onSelect: (user: User) => void,
    onAddNewMember?: () => void,
    disabled?: boolean,
    onToggleQueryState?: (present: boolean) => void,
    required?: boolean,
    error?: ?string,
    withInvite?: boolean,
    placeholder?: string,
    hasTeamAccess?: boolean,
    limitedAccessOrMore?: boolean,
  },
  {
    query: string,
    showInviteWindow: boolean,
    showImportUsersWindow: boolean,
  },
> {
  state = {
    query: '',
    showInviteWindow: false,
    showImportUsersWindow: false,
  };

  handleFilter = throttle((str: string) => {
    this.setState({ query: str });
    this.checkSearchQueryState(str);
  }, 800);

  queryExists: boolean;

  cachedUsers: ?Array<User>;

  checkSearchQueryState = (str?: string = '') => {
    if (str.trim() && !this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(true);
      this.queryExists = true;
    }
    if (!str.trim() && this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(false);
      this.queryExists = false;
    }
  };

  handleSelect = (user: ?User) => {
    if (user) {
      this.props.onSelect(user);
    }
  };

  handleAddNewMember = () => {
    if (this.props.withInvite) {
      this.setState({ showInviteWindow: true });
    } else if (this.props.onAddNewMember) {
      this.props.onAddNewMember();
    }
  };

  handleShowInviteWindow = () => {
    this.setState({ showInviteWindow: true });
  };

  handleHideInviteWindow = () => {
    this.setState({ showInviteWindow: false });
  };

  handleShowImportUsersWindow = () => {
    this.handleHideInviteWindow();

    this.setState({ showImportUsersWindow: true });
  };

  handleHideImportUsersWindow = () => {
    this.setState({ showImportUsersWindow: false });
  };

  renderOption = (user: User) => <OrgUserSearchRow user={user} />;

  renderOptionToString = () => '';

  renderInvite = () => {
    if (!this.props.withInvite && !this.props.onAddNewMember) {
      return <></>;
    }
    return <NewMemberRow label="Add New Member" onClick={this.handleAddNewMember} />;
  };

  renderInput = (response?: OrgUserSearchQuery_response) => {
    this.cachedUsers = response
      ? response.org.users.edges.map(edge => edge.node)
      : this.cachedUsers;

    return (
      <StyledAutocompleteInput
        label={this.props.label}
        placeholder={this.props.placeholder != null ? this.props.placeholder : 'Search for Members'}
        options={this.cachedUsers || []}
        onFilterOptions={this.handleFilter}
        onSelect={this.handleSelect}
        renderOption={this.renderOption}
        renderOptionString={this.renderOptionToString}
        footerContent={this.renderInvite()}
        disabled={this.props.disabled}
        required={this.props.required}
        error={this.props.error}
      />
    );
  };

  render() {
    return (
      <>
        {this.state.showInviteWindow && (
          <InviteWindow
            onHide={this.handleHideInviteWindow}
            onClickImport={this.handleShowImportUsersWindow}
            fromWindow="easy_button"
          />
        )}
        {this.state.showImportUsersWindow && (
          <UsersImportWindow onClose={this.handleHideImportUsersWindow} />
        )}
        <DefaultQueryRenderer
          query={query}
          variables={{
            query: this.state.query,
            hasTeamAccess: !!this.props.hasTeamAccess,
            limitedAccessOrMore: !!this.props.limitedAccessOrMore,
          }}
          renderSuccess={this.renderInput}
          renderLoading={this.renderInput}
        />
      </>
    );
  }
}
