/* @flow */
import sortBy from 'lodash/sortBy';

import { excludedFilters } from 'components/ContactForm/ScheduledEmailTemplate/ScheduledEmailFilters';
import staticFields from 'components/FormLogic/lib/staticFields';
import { type ConditionField } from 'components/FormLogic/lib/types';

import type { RegistrationFormFieldLogic_contactFormField } from './__generated__/RegistrationFormFieldLogic_contactFormField.graphql';
import type { RegistrationFormFieldLogic_contactFormFields } from './__generated__/RegistrationFormFieldLogic_contactFormFields.graphql';

const getConditionFields = (
  contactFormField: RegistrationFormFieldLogic_contactFormField,
  contactFormFields: RegistrationFormFieldLogic_contactFormFields,
): $ReadOnlyArray<ConditionField> => {
  const staticConditionFields = Object.keys(staticFields)
    .filter(key => key !== 'company_name')
    .map(key => ({
      fieldName: key,
      label: staticFields[key],
    }));

  const conditionFields = sortBy(
    contactFormFields
      .filter(filterField => {
        if (filterField.order >= contactFormField.order) {
          return false;
        }
        if (filterField.fieldName && excludedFilters.includes(filterField.fieldName)) {
          return false;
        }

        return true;
      })
      .map(field => {
        if (field.fieldName === 'company_name') {
          return {
            fieldName: 'company_name',
            label: field.label,
            order: field.order,
          };
        }
        return { ...field.customField, label: field.label, order: field.order };
      }),
    'order',
  );

  return [...staticConditionFields, ...conditionFields];
};

export default getConditionFields;
