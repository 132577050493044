/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import downloadedBrief from 'utils/analytics/events/briefs/downloadedBrief';

import DocDownloadOverlay from 'components/Briefs/DocDownloadOverlay';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import Overlay from 'components/material/Overlay';
import SearchableStringList from 'components/material/SearchableStringList';

import type { BriefDownloadOptionSelectOverlay_event } from './__generated__/BriefDownloadOptionSelectOverlay_event.graphql';
import type { BriefDownloadOptionSelectOverlay_me } from './__generated__/BriefDownloadOptionSelectOverlay_me.graphql';

const StyledOverlay = styled(Overlay)`
  width: 115px;
  max-height: 300px;
  overflow-y: auto;
`;

class BriefDownloadOptionSelectOverlay extends React.PureComponent<
  {
    show: boolean,
    event: BriefDownloadOptionSelectOverlay_event,
    me: BriefDownloadOptionSelectOverlay_me,
    target: ?HTMLElement,
    container: ?HTMLElement,
    onHide: () => void,
  },
  { showDocDownloadOverlay: boolean, showPdfDownloadOverlay: boolean },
> {
  state = {
    showDocDownloadOverlay: false,
    showPdfDownloadOverlay: false,
  };

  handleSelect = (value: string) => {
    this.props.onHide();
    this.setState({
      ...(value === 'pdf' ? { showPdfDownloadOverlay: true } : { showDocDownloadOverlay: true }),
    });
  };

  handlePdfDownload = () => {
    const { event } = this.props;
    downloadedBrief({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'pdf',
      fromWindow: 'event brief',
    });
  };

  handlePdfDownloadEnd = () => {
    this.setState({ showPdfDownloadOverlay: false });
  };

  handleDocDownload = () => {
    const { event } = this.props;
    downloadedBrief({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'doc',
      fromWindow: 'event brief',
    });
  };

  handleDocExportEnd = () => {
    this.setState({ showDocDownloadOverlay: false });
  };

  render() {
    const { event, me, show, target, onHide, container } = this.props;

    return (
      <>
        <StyledOverlay show={show} target={target} onHide={onHide} container={container}>
          <SearchableStringList
            value={null}
            options={[
              {
                label: 'Download PDF',
                value: 'pdf',
              },
              {
                label: 'Download G Doc',
                value: 'doc',
              },
            ]}
            onSelect={this.handleSelect}
          />
        </StyledOverlay>
        {this.state.showDocDownloadOverlay && (
          <DocDownloadOverlay
            exportDocUrl={event.exportDocUrl}
            fileName={event.name}
            onHide={this.handleDocExportEnd}
            onDownload={this.handleDocDownload}
          />
        )}
        {this.state.showPdfDownloadOverlay && (
          <DownloadOverlayWithEmail
            downloadLink={event.exportPdfUrl}
            fileName={`${event.name}.pdf`}
            onHide={this.handlePdfDownloadEnd}
            email={me.email}
            exportable="brief"
            onDownload={this.handlePdfDownload}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(
  BriefDownloadOptionSelectOverlay,
  graphql`
    fragment BriefDownloadOptionSelectOverlay_event on Event {
      dbId
      name
      exportDocUrl
      exportPdfUrl
      team {
        dbId
        name
      }
    }
    fragment BriefDownloadOptionSelectOverlay_me on User {
      email
    }
  `,
);
