/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactFilterContainer_query$ref: FragmentReference;
export type ContactFilterContainer_query = {|
  +org: {|
    +contacts: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +email: string,
          +firstName: string,
          +lastName: string,
          +$fragmentRefs: MaterialAvatar_user$ref,
        |}
      |}>
    |}
  |},
  +selectedContacts: $ReadOnlyArray<?{|
    +id?: string,
    +email?: string,
    +firstName?: string,
    +lastName?: string,
    +$fragmentRefs: MaterialAvatar_user$ref,
  |}>,
  +$refType: ContactFilterContainer_query$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "email",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "firstName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "FragmentSpread",
    "name": "MaterialAvatar_user",
    "args": null
  }
];
return {
  "kind": "Fragment",
  "name": "ContactFilterContainer_query",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "contactFilters",
      "type": "ContactFilters"
    },
    {
      "kind": "RootArgument",
      "name": "contactIds",
      "type": "[ID!]!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "org",
      "storageKey": null,
      "args": null,
      "concreteType": "Org",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contacts",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filters",
              "variableName": "contactFilters",
              "type": "ContactFilters"
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 15,
              "type": "Int"
            }
          ],
          "concreteType": "ContactRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ContactRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Contact",
                  "plural": false,
                  "selections": v0
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "selectedContacts",
      "name": "nodes",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "contactIds",
          "type": "[ID!]!"
        }
      ],
      "concreteType": null,
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "type": "Contact",
          "selections": v0
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6a2510c2e7b091236842380b54e84a1d';
module.exports = node;
