/* @flow */
import { graphql } from 'react-relay';

import type { TaskStatuses } from 'config/taskStatuses';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type { TasksFromWindow } from './createTasks';

const mutation = graphql`
  mutation updateTasksStatusMutation($input: UpdateDeliverablesStatusInput!) {
    updateDeliverablesStatus(input: $input) {
      event {
        progress
      }
      deliverables {
        id
        status
        name
        dbId
        dependents {
          edges {
            node {
              blockedByOtherTasks
            }
          }
        }
        subtasksCount
        completedSubtasksCount
      }
    }
  }
`;

export default function updateTasksStatus(
  taskIds: $ReadOnlyArray<string>,
  status: TaskStatuses,
  fromWindow: TasksFromWindow,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableIds: taskIds,
        status,
        fromWindow,
      },
    },
    optimisticResponse: {
      updateDeliverablesStatus: {
        deliverables: taskIds.map(taskId => ({
          id: taskId,
          status,
        })),
      },
    },
  });
}
