/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  saveTeamAllocationMutationVariables,
  saveTeamAllocationMutationResponse,
} from './__generated__/saveTeamAllocationMutation.graphql';

const mutation = graphql`
  mutation saveTeamAllocationMutation($input: SaveTeamAllocationInput!) {
    saveTeamAllocation(input: $input) {
      expense {
        expenseTeamAllocations {
          edges {
            node {
              percent
              team {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default function saveTeamAllocation(
  input: $PropertyType<saveTeamAllocationMutationVariables, 'input'>,
): Promise<saveTeamAllocationMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  });
}
