/* @flow */
import styled, { css } from 'styled-components';
/**
 * Use this for columns that use a modal.
 */
const BaseColumnAllowOverflow = styled.div`
  min-width: 0;
  padding-right: 10px;
  line-height: 21px;
  ${props =>
    props.width &&
    css`
      flex: ${props.grow || 0} 0 ${props.width}px;
    `};
`;

export default BaseColumnAllowOverflow;
