/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';

import createComment from 'graph/mutations/comment/createComment';
import removeComment from 'graph/mutations/comment/removeComment';
import updateComment from 'graph/mutations/comment/updateComment';

import UserStore from 'stores/UserStore';

import CommentInput from 'components/Comments/CommentInput';
import CommentItem from 'components/Comments/CommentItem';
import Button from 'components/material/Button';

import type { TaskComments_task } from './__generated__/TaskComments_task.graphql';

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
  color: ${props => props.theme.primaryActionColor};
`;

class TaskComments extends React.PureComponent<
  {
    task: TaskComments_task,
    relay: {
      hasMore: () => boolean,
      isLoading: () => boolean,
      loadMore: (number, cb: (?Error) => void) => void,
    },
  },
  { loading: boolean, replyingTo: ?string },
> {
  state = { loading: false, replyingTo: null };

  handleLoadMore = () => {
    if (this.props.relay.isLoading()) {
      return;
    }

    this.setState({ loading: true });
    this.props.relay.loadMore(10, () => {
      this.setState({ loading: false });
    });
  };

  handleCreate = (content: string) => {
    return createComment(this.props.task.id, content);
  };

  handleRemove = (commentId: string) => {
    removeComment(this.props.task.id, commentId);
  };

  handleUpdate = (commentId: string, content: string) => {
    updateComment(commentId, content);
  };

  handleReply = (userSlug?: string) => {
    this.setState({ replyingTo: userSlug || null });
  };

  render() {
    const currentUserId = UserStore.getState().user.relay_id;
    const comments = this.props.task.comments.edges.map(edge => edge.node);
    const users = uniqBy(
      [
        ...this.props.task.event.staffers.edges.map(edge => edge.node.user),
        ...this.props.task.event.team.users.edges.map(edge => edge.node),
      ],
      'id',
    );

    return (
      <div>
        <CommentInput
          users={users}
          currentUserId={currentUserId}
          onSave={this.handleCreate}
          replyingTo={this.state.replyingTo}
          onReplyHandled={this.handleReply}
        />

        {comments.map(comment => (
          <CommentItem
            comment={comment}
            key={comment.id}
            users={users}
            currentUserId={currentUserId}
            onRemove={this.handleRemove}
            onUpdate={this.handleUpdate}
            onReply={this.handleReply}
            tz={this.props.task.event.tz}
          />
        ))}

        {this.props.relay.hasMore() && (
          <StyledButton
            label="See More"
            minimal
            onClick={this.handleLoadMore}
            loading={this.state.loading}
          />
        )}
      </div>
    );
  }
}

export default createPaginationContainer(
  TaskComments,
  {
    task: graphql`
      fragment TaskComments_task on Deliverable {
        id
        slug
        comments(first: $count, after: $cursor) @connection(key: "TaskComments_comments") {
          edges {
            node {
              id
              ...CommentItem_comment
            }
          }
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
        }
        event {
          tz
          team {
            users {
              edges {
                node {
                  id
                  ...CommentInput_users
                  ...CommentItem_users
                }
              }
            }
          }
          staffers {
            edges {
              node {
                id
                user {
                  id
                  ...CommentInput_users
                  ...CommentItem_users
                }
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.task && props.task.comments;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
        taskSlug: props.task.slug,
      };
    },
    query: graphql`
      query TaskCommentsPaginationQuery($count: Int!, $cursor: String, $taskSlug: String!) {
        deliverable(slug: $taskSlug) {
          ...TaskComments_task
        }
      }
    `,
  },
);
