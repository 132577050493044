/**
 * @flow
 * @relayHash 6f91a61d80d1352b1a81e9ff1748b616
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventCompaniesTablePagination_totalCountEvent$ref = any;
type OrgCompaniesTablePagination_totalCountOrg$ref = any;
export type CompanySort = "CITY" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CUSTOM" | "DESCRIPTION" | "LINKEDIN" | "NAME" | "PHONE" | "SALESFORCE_ID" | "STATE" | "STREET" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "WEBSITE" | "ZIP";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type CompanyFilters = {
  query?: ?string,
  companyIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactsCount?: ?NumberRangeInput,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?CompanySort,
  direction?: ?Direction,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type RemoveCompaniesInput = {
  clientMutationId?: ?string,
  companyIds: $ReadOnlyArray<string>,
  eventId?: ?string,
  fromWindow?: ?string,
};
export type removeCompaniesMutationVariables = {|
  filters?: ?CompanyFilters,
  input: RemoveCompaniesInput,
  hasFilters: boolean,
|};
export type removeCompaniesMutationResponse = {|
  +removeCompanies: ?{|
    +org: {|
      +companies?: {|
        +totalCount: number
      |},
      +$fragmentRefs: OrgCompaniesTablePagination_totalCountOrg$ref,
    |},
    +event?: ?{|
      +companies?: {|
        +totalCount: number
      |},
      +$fragmentRefs: EventCompaniesTablePagination_totalCountEvent$ref,
    |},
    +removedCompanyIds: $ReadOnlyArray<string>,
  |}
|};
export type removeCompaniesMutation = {|
  variables: removeCompaniesMutationVariables,
  response: removeCompaniesMutationResponse,
|};
*/


/*
mutation removeCompaniesMutation(
  $filters: CompanyFilters
  $input: RemoveCompaniesInput!
  $hasFilters: Boolean!
) {
  removeCompanies(input: $input) {
    org {
      companies @include(if: $hasFilters) {
        totalCount
      }
  ...OrgCompaniesTablePagination_totalCountOrg @include(if: $hasFilters)
      id
    }
    event @include(if: $hasFilters) {
      ...EventCompaniesTablePagination_totalCountEvent
      companies @include(if: $hasFilters) {
        totalCount
      }
      id
    }
    removedCompanyIds
  }
}

fragment OrgCompaniesTablePagination_totalCountOrg on Org {
  companiesCount: companies(filters: $filters) {
    totalCount
  }
}

fragment EventCompaniesTablePagination_totalCountEvent on Event {
  companiesCount: companies(filters: $filters) {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "CompanyFilters",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveCompaniesInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hasFilters",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveCompaniesInput!"
  }
],
v2 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "companies",
  "storageKey": null,
  "args": null,
  "concreteType": "CompanyRequiredConnection",
  "plural": false,
  "selections": v2
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedCompanyIds",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "hasFilters",
  "selections": [
    v3
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": "companiesCount",
  "name": "companies",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filters",
      "variableName": "filters",
      "type": "CompanyFilters"
    }
  ],
  "concreteType": "CompanyRequiredConnection",
  "plural": false,
  "selections": v2
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeCompaniesMutation",
  "id": null,
  "text": "mutation removeCompaniesMutation(\n  $filters: CompanyFilters\n  $input: RemoveCompaniesInput!\n  $hasFilters: Boolean!\n) {\n  removeCompanies(input: $input) {\n    org {\n      companies @include(if: $hasFilters) {\n        totalCount\n      }\n  ...OrgCompaniesTablePagination_totalCountOrg @include(if: $hasFilters)\n      id\n    }\n    event @include(if: $hasFilters) {\n      ...EventCompaniesTablePagination_totalCountEvent\n      companies @include(if: $hasFilters) {\n        totalCount\n      }\n      id\n    }\n    removedCompanyIds\n  }\n}\n\nfragment OrgCompaniesTablePagination_totalCountOrg on Org {\n  companiesCount: companies(filters: $filters) {\n    totalCount\n  }\n}\n\nfragment EventCompaniesTablePagination_totalCountEvent on Event {\n  companiesCount: companies(filters: $filters) {\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeCompaniesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeCompanies",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveCompaniesPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "hasFilters",
                "selections": [
                  v3,
                  {
                    "kind": "FragmentSpread",
                    "name": "OrgCompaniesTablePagination_totalCountOrg",
                    "args": null
                  }
                ]
              }
            ]
          },
          v4,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "hasFilters",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "EventCompaniesTablePagination_totalCountEvent",
                    "args": null
                  },
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeCompaniesMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeCompanies",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveCompaniesPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              v6,
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "hasFilters",
                "selections": [
                  v3,
                  v7
                ]
              }
            ]
          },
          v4,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "hasFilters",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v7,
                  v6,
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1613ae40ca4165ef61b916e0e7b76661';
module.exports = node;
