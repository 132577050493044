/**
 * @flow
 * @relayHash de5b82786ea7448843e385d75f0d9753
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EmailSubscriptions_user$ref = any;
export type EmailSubscriptionsQueryVariables = {||};
export type EmailSubscriptionsQueryResponse = {|
  +user: {|
    +$fragmentRefs: EmailSubscriptions_user$ref
  |}
|};
export type EmailSubscriptionsQuery = {|
  variables: EmailSubscriptionsQueryVariables,
  response: EmailSubscriptionsQueryResponse,
|};
*/


/*
query EmailSubscriptionsQuery {
  user: me {
    ...EmailSubscriptions_user
    id
  }
}

fragment EmailSubscriptions_user on User {
  id
  tz
  emailSubscription {
    activitySummary
    eventScope
    assignmentNotifications
    activityNotifications
    mentionNotifications
    reminders
    postEventEngagementReport
    contactRegistrationNotifications
    contactAttendanceNotifications
    addEventStaffNotifications
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EmailSubscriptionsQuery",
  "id": null,
  "text": "query EmailSubscriptionsQuery {\n  user: me {\n    ...EmailSubscriptions_user\n    id\n  }\n}\n\nfragment EmailSubscriptions_user on User {\n  id\n  tz\n  emailSubscription {\n    activitySummary\n    eventScope\n    assignmentNotifications\n    activityNotifications\n    mentionNotifications\n    reminders\n    postEventEngagementReport\n    contactRegistrationNotifications\n    contactAttendanceNotifications\n    addEventStaffNotifications\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EmailSubscriptionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EmailSubscriptions_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EmailSubscriptionsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tz",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "emailSubscription",
            "storageKey": null,
            "args": null,
            "concreteType": "EmailSubscription",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "activitySummary",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "eventScope",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "assignmentNotifications",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "activityNotifications",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "mentionNotifications",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "reminders",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "postEventEngagementReport",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "contactRegistrationNotifications",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "contactAttendanceNotifications",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "addEventStaffNotifications",
                "args": null,
                "storageKey": null
              },
              v0
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5ca997dba5279f897df83f00fd025bc';
module.exports = node;
