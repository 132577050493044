/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AllTasksHeaderBar_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllTasksPage_org$ref: FragmentReference;
export type AllTasksPage_org = {|
  +settings: {|
    +fiscalYearStart: number
  |},
  +$fragmentRefs: AllTasksHeaderBar_org$ref,
  +$refType: AllTasksPage_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "AllTasksPage_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AllTasksHeaderBar_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fiscalYearStart",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '448519411a575b10a37439e3d255b0ba';
module.exports = node;
