/* @flow */
import * as React from 'react';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';

import IntegrationSettingsCard from './IntegrationSettingsCard';

import { type SolutionName } from './__generated__/IntegrationSettingsPage_integrable.graphql';

const SectionTitle = styled.div`
  margin: 15px 0;
  font-size: 15px;
  font-weight: 500;
  color: #595a5d;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
`;

export type IntegrationSettings = {|
  sectionName?: string,
  icon: React.Node,
  largeIcon?: React.Node,
  iconWidth?: number,
  name: string,
  headerName?: string,
  cardWidth?: number,
  route?: string,
  component?: React.ComponentType<any>,
  color: string,
  solutionName?: SolutionName,
  disconnectConfirmation?: string,
  connected?: boolean,
  pending?: boolean,
  disabledInfo?: ?string,
  expired?: boolean,
|};

export default class IntegrationsSettings extends React.Component<{
  integrations: $ReadOnlyArray<IntegrationSettings>,
  history: RouterHistory,
}> {
  hideConnectCalendarHelper = () => {
    this.props.history.replace('/settings/integrations');
  };

  render() {
    const integrations = this.props.integrations;
    const sectionIntegrations: { [string]: IntegrationSettings[] } = groupBy(
      integrations,
      integration => integration.sectionName || '',
    );

    return Object.keys(sectionIntegrations).map(sectionName => (
      <React.Fragment key={sectionName}>
        <SectionTitle>{sectionName}&nbsp;</SectionTitle>

        <Section>
          {sectionIntegrations[sectionName].map(integration => (
            <IntegrationSettingsCard key={integration.name} integration={integration} />
          ))}
        </Section>
      </React.Fragment>
    ));
  }
}
