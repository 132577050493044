/* @flow */
import * as React from 'react';
import { Overlay as OverlayComponent } from 'react-overlays';
import styled from 'styled-components';

export const OverlayContext = React.createContext<{ scheduleUpdate: () => void }>({
  scheduleUpdate: () => {},
});

// don't add overflow-y: auto for fixing IE11 dropdown issue
const OverlayContents = styled.div`
  background: ${props => props.theme.contentBackgroundColor};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
  position: absolute;
  width: 200px;
  z-index: 130;
  cursor: initial;
`;

type RenderProps = {
  props: { style: {}, ref: () => void },
  outOfBoundaries: boolean,
  scheduleUpdate: () => void,
};

type DataProps = {
  instance: { popper: HTMLElement },
  styles: { [string]: mixed },
  offsets: { reference: { width: number } },
  popper: { top: number, height: number },
};

type Props = {
  children: React.Node,
  show: boolean,
  keepInViewport?: boolean,
  target: any,
  container: any,
  onHide?: ?() => void,
  className?: string,
  forceRightEdge?: boolean,
  placement?: string,
  overlayRef?: $Call<React.createRef<any>>,
  flagClass?: string,
};

export default class Overlay extends React.PureComponent<Props> {
  containerElement: ?HTMLElement = null;

  fillerContainer: ?HTMLElement = null;

  fillerElement: HTMLElement = document.createElement('div');

  overlayRef = React.createRef();

  componentDidUpdate(prevProps: Props) {
    if (prevProps.show && !this.props.show) {
      this.removeFillerElement();
    }
  }

  componentWillUnmount() {
    this.removeFillerElement();
  }

  overlayContentsRef = (popperRef: ?$Call<React.createRef<any>>) => (element: ?HTMLElement) => {
    this.overlayRef.current = element;

    if (popperRef) popperRef(element);

    if (this.props.overlayRef && element) {
      if (typeof this.props.overlayRef === 'function') {
        this.props.overlayRef(element);
      } else {
        this.props.overlayRef.current = element;
      }
    }
  };

  autoSizing = (data: DataProps) => {
    const computedStyles = window.getComputedStyle(data.instance.popper);
    const growToTargetSize =
      data.instance.popper.style.minWidth || computedStyles.minWidth === '100%';

    if (!this.props.keepInViewport) this.addFillerElement(data);
    if (!growToTargetSize) return data;

    return {
      ...data,
      styles: { ...data.styles, minWidth: Math.ceil(data.offsets.reference.width) },
    };
  };

  renderOverlay = (renderProps: RenderProps) => {
    const { className, flagClass } = this.props;
    return (
      <OverlayContents
        className={`${className || ''} ${flagClass || ''}`}
        style={{
          ...renderProps.props.style,
          visibility: renderProps.outOfBoundaries ? 'hidden' : 'visible',
        }}
        ref={this.overlayContentsRef(renderProps.props.ref)}
      >
        <OverlayContext.Provider value={{ scheduleUpdate: renderProps.scheduleUpdate }}>
          {this.props.children}
        </OverlayContext.Provider>
      </OverlayContents>
    );
  };

  addFillerElement = (data: DataProps) => {
    const { containerElement, fillerContainer } = this.getContainerElements(
      this.overlayRef.current,
    );
    if (
      containerElement &&
      this.fillerElement.parentElement == null &&
      containerElement.parentElement instanceof HTMLElement
    ) {
      if (containerElement.scrollHeight === containerElement.parentElement.offsetHeight) {
        containerElement.parentElement.style.alignItems = 'start';
      }

      this.fillerElement.style.height = `${
        (containerElement.scrollHeight -
          containerElement.scrollTop -
          data.popper.top -
          data.popper.height) *
          -1 -
        50
      }px`;
      this.fillerElement.style.flexShrink = '0';

      if (containerElement.parentElement.style.alignItems === 'start') {
        containerElement.parentElement.style.alignItems = '';
      }
      if (fillerContainer) {
        fillerContainer.appendChild(this.fillerElement);
      } else {
        containerElement.appendChild(this.fillerElement);
      }
    }
  };

  getContainerElements = (
    node: ?HTMLElement,
  ): { containerElement: ?HTMLElement, fillerContainer?: ?HTMLElement } => {
    if (this.containerElement)
      return { containerElement: this.containerElement, fillerContainer: this.fillerContainer };

    const parent: HTMLElement = (node && node.parentElement: any);
    if (!parent) return { containerElement: null };

    if (parent === document.body) {
      // general case for table dropdown logic.
      this.containerElement = document.querySelector('.site-content');
      this.fillerContainer = document.querySelector('.overlay-filler-container');
      return { containerElement: this.containerElement, fillerContainer: this.fillerContainer };
    }

    if (['auto', 'scroll'].includes(window.getComputedStyle(parent).overflowY)) {
      this.containerElement = parent;

      return { containerElement: parent };
    }

    return this.getContainerElements(parent);
  };

  removeFillerElement = () => {
    if (this.fillerElement.parentElement != null) {
      this.fillerElement.parentElement.removeChild(this.fillerElement);
    }
  };

  getPlacement = () => {
    if (this.props.placement) {
      return this.props.placement;
    }

    return `bottom-${this.props.forceRightEdge ? 'end' : 'start'}`;
  };

  render() {
    return (
      <OverlayComponent
        show={this.props.show}
        onHide={this.props.onHide}
        placement={this.getPlacement()}
        container={this.props.container}
        target={this.props.target}
        rootClose={!!this.props.onHide}
        popperConfig={{
          modifiers: {
            preventOverflow: {
              boundariesElement: 'viewport',
              priority: [...(this.props.keepInViewport ? ['bottom'] : []), 'top'],
            },
            autoSizing: {
              enabled: true,
              fn: this.autoSizing,
              order: 840,
            },
          },
        }}
      >
        {this.renderOverlay}
      </OverlayComponent>
    );
  }
}
