/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone';

import { type CustomFieldsType, type FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import isValidWebsite from 'utils/validators/isValidWebsite';

import { type UpdateCustomFieldValueInput } from 'graph/mutations/custom_field/updateCustomFieldValue';

import DateTimePicker from 'components/date/DateTimePicker';
import CheckBox from 'components/EventRequestForm/form/CheckBox';
import OrgUserSearch from 'components/InviteWindow/OrgUserSearch';
import UserSelectionRow from 'components/InviteWindow/SelectionRow/UserSelectionRow';
import CostField from 'components/material/CostField';
import EditableLinkField from 'components/material/EditableLinkField';
import MultiSelectField from 'components/material/MultiSelectField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import { type Participant } from 'components/Participants';
import UserSearch from 'components/Participants/UserSearch';
import UserSelect, { type User } from 'components/UserSelect';

import AddNewRow from '../material/MultiSelectField/AddNewRow';
import NumberField from './fields/NumberField';

const StyledMultiSelectField = styled(MultiSelectField)`
  width: 100%;
`;

const SelectionList = styled.div`
  margin: 15px 0;
`;

const UserMultiselectContainer = styled.div`
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  ${props =>
    props.disabled &&
    css`
      input,
      textarea {
        padding-right: 15px;
      }
    `}
`;

export type InexactUser = $ReadOnly<{ ...User }>;

export type Props = {
  customizable: CustomFieldsType,
  handleUpdate: (
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => void,
  // eslint-disable-next-line
  viewerCanUpdateValue: boolean,
  field: FieldType,
  // eslint-disable-next-line
  currency: string,
  // eslint-disable-next-line
  tz: string,
  // eslint-disable-next-line
  errors: { [string]: string },
  // eslint-disable-next-line react/no-unused-prop-types
  eventId?: string,
};

const customFields = {
  LINK: ({ customizable, handleUpdate, viewerCanUpdateValue, field, errors }: Props) => {
    const linkField = customizable.customLinkFields.find(edge => edge.customField.id === field.id);
    const linkValue = linkField ? linkField.value : '';
    return (
      <EditableLinkField
        defaultValue={linkValue}
        onBlur={e => {
          const fieldValue = e.currentTarget.value.trim();
          const value = isValidWebsite(enforceHttpPrefix(fieldValue))
            ? enforceHttpPrefix(fieldValue)
            : fieldValue;
          if (value !== linkValue) {
            e.currentTarget.value = value;
            handleUpdate({ linkValue: value });
          }
        }}
        label={field.label}
        name={field.id}
        readOnly={!viewerCanUpdateValue}
        required={field.required}
        error={errors[field.id]}
      />
    );
  },
  TEXT: ({ customizable, handleUpdate, field, errors, viewerCanUpdateValue }: Props) => {
    const textField = customizable.customTextFields.find(edge => edge.customField.id === field.id);
    const textValue = textField ? textField.value : '';
    return (
      <StyledTextField
        defaultValue={textValue}
        onBlur={e => {
          if (e.currentTarget.value !== textValue) {
            handleUpdate({ textValue: e.currentTarget.value });
          }
        }}
        label={field.label}
        name={field.id}
        required={field.required}
        error={errors[field.id]}
        disabled={!viewerCanUpdateValue}
      />
    );
  },
  TEXTAREA: ({ customizable, handleUpdate, field, errors, viewerCanUpdateValue }: Props) => {
    const textareaField = customizable.customTextareaFields.find(
      edge => edge.customField.id === field.id,
    );
    const textareaValue = textareaField ? textareaField.value : '';
    return (
      <StyledTextField
        multiline
        defaultValue={textareaValue}
        onBlur={e => {
          if (e.currentTarget.value !== textareaValue) {
            handleUpdate({ textareaValue: e.currentTarget.value });
          }
        }}
        label={field.label}
        name={field.id}
        required={field.required}
        error={errors[field.id]}
        disabled={!viewerCanUpdateValue}
      />
    );
  },
  NUMBER: ({ customizable, handleUpdate, field, errors, viewerCanUpdateValue }: Props) => {
    const numberField = customizable.customNumberFields.find(
      edge => edge.customField.id === field.id,
    );
    const numberValue = numberField ? numberField.value : null;
    return (
      <NumberField
        label={field.label}
        required={field.required}
        defaultValue={numberValue}
        onUpdate={handleUpdate}
        error={errors[field.id]}
        disabled={!viewerCanUpdateValue}
      />
    );
  },
  BOOLEAN: ({ customizable, handleUpdate, field, viewerCanUpdateValue, errors }: Props) => {
    const booleanField = customizable.customBooleanFields.find(
      edge => edge.customField.id === field.id,
    );
    return (
      <CheckBox
        compact
        checked={booleanField ? booleanField.value : false}
        name={field.id}
        label={field.label}
        onChange={() => handleUpdate({ booleanValue: booleanField ? !booleanField.value : true })}
        disabled={!viewerCanUpdateValue}
        error={errors[field.id]}
        required={field.required}
      />
    );
  },
  CURRENCY: ({
    customizable,
    handleUpdate,
    field,
    currency,
    errors,
    viewerCanUpdateValue,
  }: Props) => {
    const currencyField = customizable.customCurrencyFields.find(
      edge => edge.customField.id === field.id,
    );
    const currencyValue = currencyField ? currencyField.value : null;
    return (
      <CostField
        defaultValue={currencyValue}
        name={field.id}
        label={field.label}
        currency={currency}
        onBlur={(e, value) => {
          if (currencyValue !== value) {
            handleUpdate({ currencyValue: value });
          }
        }}
        required={field.required}
        error={errors[field.id]}
        disabled={!viewerCanUpdateValue}
      />
    );
  },
  DATE: ({ customizable, handleUpdate, field, viewerCanUpdateValue, tz, errors }: Props) => {
    const dateField = customizable.customDateFields.find(edge => edge.customField.id === field.id);
    return (
      <DateTimePicker
        label={field.label}
        tz={tz}
        date={dateField ? moment.tz(dateField.value, tz).format() : ''}
        hideTime
        onChange={time => {
          if (
            (dateField && !time.date) ||
            (!dateField && time.date) ||
            (dateField &&
              time.date &&
              !moment.tz(dateField.value, tz).isSame(moment.tz(time.date, tz), 'day'))
          ) {
            handleUpdate({ dateValue: time.date });
          }
        }}
        disabled={!viewerCanUpdateValue}
        dateOnly
        required={field.required}
        error={errors[field.id]}
      />
    );
  },
  SELECT: ({ customizable, handleUpdate, viewerCanUpdateValue, field, errors }: Props) => {
    const selectField = customizable.customSelectFields.find(
      edge => edge.customField.id === field.id,
    );
    const selectedValue =
      selectField && field.options.find(edge => edge.value === selectField.option.id);
    const selectOptions = [
      ...field.options,
      ...(viewerCanUpdateValue && field.viewerCanUpdate && !field.mappedToSalesforce
        ? [
            {
              label: '',
              value: '',
              render: ({ onHide }) => (
                <AddNewRow
                  label={`Add New ${field.label}`}
                  key={field.id}
                  onSave={(value: string) => {
                    handleUpdate({ selectOtherValue: value });
                    onHide();
                  }}
                />
              ),
            },
          ]
        : []),
    ];
    return (
      <SelectField
        label={field.label}
        value={selectedValue && selectedValue.value}
        onChange={value => {
          if (
            (selectedValue && !value) ||
            (!selectedValue && value) ||
            (selectedValue && value && selectedValue.value !== value)
          ) {
            handleUpdate({ selectValue: value });
          }
        }}
        options={selectOptions}
        searchable
        disabled={!viewerCanUpdateValue}
        required={field.required}
        clearable={!field.required}
        error={errors[field.id]}
      />
    );
  },
  MULTISELECT: ({ customizable, handleUpdate, viewerCanUpdateValue, field, errors }: Props) => {
    const multiselectValues = customizable.customMultiselectFields
      .filter(item => item.customField.id === field.id)
      .map(item => item.option.id);
    return (
      <StyledMultiSelectField
        label={field.label}
        searchable
        values={multiselectValues}
        addActionLabel={`Add New ${field.label}`}
        onUnselect={value =>
          handleUpdate({ selectValues: multiselectValues.filter(val => val !== value) })
        }
        onCreate={value =>
          handleUpdate({ selectOtherValue: value, selectValues: [...multiselectValues] })
        }
        extensible={viewerCanUpdateValue && field.viewerCanUpdate && !field.mappedToSalesforce}
        options={field.options}
        disabled={!viewerCanUpdateValue}
        onSelect={value => handleUpdate({ selectValues: [...multiselectValues, value] })}
        required={field.required}
        error={errors[field.id]}
      />
    );
  },
  USER_SELECT: ({
    customizable,
    handleUpdate,
    viewerCanUpdateValue,
    field,
    errors,
    eventId,
  }: Props) => {
    const userField = customizable.customUserSelectFields.find(
      edge => edge.customField.id === field.id,
    );
    return (
      <UserSelect
        label={field.label}
        searchPlaceholder="Search Members"
        user={userField && userField.user}
        onSelect={selectUser => {
          if (
            (userField && !selectUser) ||
            (!userField && selectUser) ||
            (userField && selectUser && userField.user.id !== selectUser.id)
          ) {
            handleUpdate(
              { userValue: selectUser ? selectUser.id : null },
              selectUser ? [selectUser] : [],
            );
          }
        }}
        disabled={!viewerCanUpdateValue}
        required={field.required}
        clearable={!field.required}
        error={errors[field.id]}
        eventId={eventId}
        fromWindow={eventId ? 'custom event settings' : undefined}
      />
    );
  },
  USER_MULTISELECT: ({
    customizable,
    handleUpdate,
    viewerCanUpdateValue,
    field,
    errors,
    eventId,
  }: Props) => {
    const selectedUsers = customizable.customUserMultiselectFields
      .filter(option => option.customField.id === field.id)
      .map(option => ({
        ...option.user,
        uid: option.user.id,
      }));
    const selectedUsersIds = selectedUsers.map(user => user.id);
    const accumulatedUsers: Array<Participant> = [];
    const handleUserSelect = debounce(() => {
      if (accumulatedUsers.length === 0) return;

      handleUpdate(
        { userValues: [...selectedUsersIds, ...accumulatedUsers.map(user => user.id)] },
        [
          ...customizable.customUserMultiselectFields.map(node => node.user),
          ...accumulatedUsers.map(user => ({
            id: user.id,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            avatar: user.avatar,
          })),
          // Above done to flow not complain, there are
          // unresolvable inconsistencies in types, Participant and InexactUser
        ],
      );

      // Empty the array
      accumulatedUsers.length = 0;
    }, 50);
    return (
      <UserMultiselectContainer>
        {eventId ? (
          <UserSearch
            label={field.label}
            eventId={eventId}
            onSelect={value => {
              if (value == null || selectedUsersIds.includes(value.id)) return;
              accumulatedUsers.push(value);
              handleUserSelect();
            }}
            disabled={!viewerCanUpdateValue}
            fromWindow="custom event settings"
          />
        ) : (
          <OrgUserSearch
            label={field.label}
            onSelect={value => {
              if (selectedUsersIds.includes(value.id)) return;

              handleUpdate({ userValues: [...selectedUsersIds, value.id] }, [
                ...customizable.customUserMultiselectFields.map(node => node.user),
                value,
              ]);
            }}
            disabled={!viewerCanUpdateValue}
            required={field.required}
            error={errors[field.id]}
            withInvite
          />
        )}

        {selectedUsers.length > 0 && (
          <SelectionList>
            {selectedUsers.map(user => (
              <UserSelectionRow
                key={user.uid}
                invite={user}
                disabled={!viewerCanUpdateValue}
                onRemove={value =>
                  handleUpdate(
                    { userValues: selectedUsersIds.filter(val => val !== value) },
                    customizable.customUserMultiselectFields
                      .map(node => node.user)
                      .filter(userOption => userOption.id !== value),
                  )
                }
              />
            ))}
          </SelectionList>
        )}
      </UserMultiselectContainer>
    );
  },
};

export default customFields;
