/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import intersection from 'lodash/intersection';
import moment from 'moment';

import downloadedOpportunities from 'utils/analytics/downloadedOpportunities';
import type { FieldType } from 'utils/customization/types';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ColumnFilter from 'components/material/ColumnFilters';
import AdvancedFilter from 'components/material/Filters/Advanced/AdvancedFilter';
import getOpportunityFilterConverters from 'components/Opportunities/lib/getOpportunityFilterConverters';
import { type ParsedOpportunitiesFilters } from 'components/Opportunities/lib/parseOpportunitiesFilters';
import renderOpportunityFilter from 'components/Opportunities/lib/renderOpportunityFilter';
import {
  type InputVariableFilters,
  getColumnsShowConfig,
} from 'components/Opportunities/OpportunitiesTableColumnSettings';
import Search from 'components/Search';
import ShareDropdown from 'components/ShareDropdown';

import type { OpportunitiesHeaderBar_event } from './__generated__/OpportunitiesHeaderBar_event.graphql';
import type { OpportunitiesHeaderBarQueryResponse } from './__generated__/OpportunitiesHeaderBarQuery.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px 15px;
    margin-bottom: 0;
  }
`;

const ActionsDropdown = styled(ShareDropdown)`
  padding-left: 0;
  border-left: none;
`;

const query = graphql`
  query OpportunitiesHeaderBarQuery(
    $eventSlug: String!
    $filters: OpportunityFilters!
    $columns: [String!]
  ) {
    event(slug: $eventSlug) {
      opportunityReportCSVProcessId(filters: $filters, columns: $columns)
    }
    me {
      email
    }
  }
`;

class OpportunitiesHeaderBar extends React.PureComponent<
  {
    event: OpportunitiesHeaderBar_event,
    history: RouterHistory,
    filters: ParsedOpportunitiesFilters,
    filterInputs: InputVariableFilters,
    opportunityFields: $ReadOnlyArray<FieldType>,
    shownColumns: $ReadOnlyArray<string>,
    onSearch: (query: string) => void,
    onColumnsChange: (shownColumns: $ReadOnlyArray<string>) => void,
  },
  {
    showCsvDownloadOverlay: boolean,
  },
> {
  state = {
    showCsvDownloadOverlay: false,
  };

  handleCsvExport = () => {
    this.setState({ showCsvDownloadOverlay: true });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCsvDownloadOverlay: false });

    downloadedOpportunities({
      eventId: this.props.event.dbId,
      eventName: this.props.event.name,
      teamId: this.props.event.team.dbId,
      teamName: this.props.event.team.name,
    });
  };

  renderDownloadOverlay = () => {
    const columns = intersection(
      this.props.opportunityFields.map(field => field.fieldName || field.id),
      this.props.shownColumns,
    );
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ filters: this.props.filterInputs, columns, eventSlug: this.props.event.slug }}
        renderSuccess={(response: OpportunitiesHeaderBarQueryResponse) => {
          return response.event ? (
            <DownloadOverlayWithEmail
              processUUID={response.event.opportunityReportCSVProcessId}
              fileName={`Opportunities Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleCsvDownloadEnd}
              email={response.me.email}
              exportable="opportunities_csv"
              onDownload={this.handleCsvExport}
            />
          ) : null;
        }}
        renderLoading={() => null}
      />
    );
  };

  handleSearch = (queryString: string) => {
    this.props.onSearch(queryString);
  };

  render() {
    const { history, shownColumns, onColumnsChange, filters, opportunityFields } = this.props;
    const searchObject = filters.search;
    const opportunityColumns = getColumnsShowConfig(opportunityFields);

    return (
      <Container>
        <Search
          onSearch={this.handleSearch}
          search={searchObject && searchObject.search}
          exactSearch={searchObject && searchObject.exactMatch}
          placeholder="Search Opportunities"
        />
        <AdvancedFilter
          history={history}
          filters={filters}
          filterOptions={opportunityFields}
          converterFn={getOpportunityFilterConverters}
          filterRenderer={renderOpportunityFilter}
        />
        <ColumnFilter
          filters={opportunityColumns}
          shownFilters={shownColumns}
          onFilterChange={onColumnsChange}
        />
        <ActionsDropdown
          options={[
            {
              label: 'Download current view (CSV)',
              icon: 'file-excel-o',
              onClick: this.handleCsvExport,
            },
          ]}
        />
        {this.state.showCsvDownloadOverlay && this.renderDownloadOverlay()}
      </Container>
    );
  }
}

export default createFragmentContainer(OpportunitiesHeaderBar, {
  event: graphql`
    fragment OpportunitiesHeaderBar_event on Event {
      dbId
      name
      slug
      team {
        dbId
        name
      }
    }
  `,
});
