/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomizableUserSelect_customizable$ref: FragmentReference;
export type CustomizableUserSelect_customizable = {|
  +id: string,
  +__typename: string,
  +customUserSelectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +user: {|
      +id: string,
      +firstName: string,
      +lastName: string,
      +email: string,
      +avatar: ?string,
      +$fragmentRefs: MaterialAvatar_user$ref,
    |},
  |}>,
  +$refType: CustomizableUserSelect_customizable$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CustomizableUserSelect_customizable",
  "type": "CustomizableInterface",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customUserSelectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomUserSelectFieldType",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customField",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "plural": false,
          "selections": [
            v0
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "avatar",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "FragmentSpread",
              "name": "MaterialAvatar_user",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cea7d4433b112f7e31d7d8e0d0ad0540';
module.exports = node;
