/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CompanyActionCell_company$ref = any;
type CompanyActionCell_event$ref = any;
type CompanyCity_company$ref = any;
type CompanyContactsCount_company$ref = any;
type CompanyCountry_company$ref = any;
type CompanyCreatedAt_company$ref = any;
type CompanyCreator_company$ref = any;
type CompanyDescription_company$ref = any;
type CompanyLinkedin_company$ref = any;
type CompanyName_company$ref = any;
type CompanyName_event$ref = any;
type CompanyPhone_company$ref = any;
type CompanySalesforceId_company$ref = any;
type CompanyState_company$ref = any;
type CompanyStreet_company$ref = any;
type CompanyTwitter_company$ref = any;
type CompanyUpdatedAt_company$ref = any;
type CompanyUpdater_company$ref = any;
type CompanyWebsite_company$ref = any;
type CompanyZip_company$ref = any;
type CustomizableBoolean_customizable$ref = any;
type CustomizableCurrency_customizable$ref = any;
type CustomizableDate_customizable$ref = any;
type CustomizableLink_customizable$ref = any;
type CustomizableMultiselect_customizable$ref = any;
type CustomizableNumber_customizable$ref = any;
type CustomizableSelect_customizable$ref = any;
type CustomizableText_customizable$ref = any;
type CustomizableTextarea_customizable$ref = any;
type CustomizableUserMultiselect_customizable$ref = any;
type CustomizableUserSelect_customizable$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventCompaniesTablePagination_event$ref: FragmentReference;
export type EventCompaniesTablePagination_event = {|
  +id: string,
  +slug: string,
  +companies: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: CompanyName_company$ref & CompanyPhone_company$ref & CompanyWebsite_company$ref & CompanyTwitter_company$ref & CompanyLinkedin_company$ref & CompanyUpdater_company$ref & CompanyUpdatedAt_company$ref & CompanyCreator_company$ref & CompanyContactsCount_company$ref & CompanyCreatedAt_company$ref & CompanySalesforceId_company$ref & CompanyStreet_company$ref & CompanyCity_company$ref & CompanyState_company$ref & CompanyZip_company$ref & CompanyCountry_company$ref & CompanyDescription_company$ref & CustomizableText_customizable$ref & CustomizableTextarea_customizable$ref & CustomizableLink_customizable$ref & CustomizableDate_customizable$ref & CustomizableBoolean_customizable$ref & CustomizableNumber_customizable$ref & CustomizableCurrency_customizable$ref & CustomizableSelect_customizable$ref & CustomizableMultiselect_customizable$ref & CustomizableUserSelect_customizable$ref & CustomizableUserMultiselect_customizable$ref & CompanyActionCell_company$ref,
      |}
    |}>
  |},
  +$fragmentRefs: CompanyActionCell_event$ref & CompanyName_event$ref,
  +$refType: EventCompaniesTablePagination_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventCompaniesTablePagination_event",
  "type": "Event",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "companies"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "cursor",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "filters",
      "type": "CompanyFilters"
    },
    {
      "kind": "RootArgument",
      "name": "includeName",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includePhone",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeWebsite",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTwitter",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeLinkedin",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeUpdatedBy",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeUpdatedAt",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCreatedBy",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeContactsCount",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCreatedAt",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeSalesforceId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStreet",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCity",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeState",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeZip",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCountry",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeDescription",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableText",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableTextarea",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableLink",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableDate",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableBoolean",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableNumber",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableCurrency",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableMultiselect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserMultiselect",
      "type": "Boolean"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompanyActionCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompanyName_event",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": "companies",
      "name": "__EventCompaniesTablePagination_companies_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "CompanyRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CompanyRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Company",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "FragmentSpread",
                  "name": "CompanyActionCell_company",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeName",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyName_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includePhone",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyPhone_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeWebsite",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyWebsite_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeTwitter",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyTwitter_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeLinkedin",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyLinkedin_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeUpdatedBy",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyUpdater_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeUpdatedAt",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyUpdatedAt_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCreatedBy",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyCreator_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeContactsCount",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyContactsCount_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCreatedAt",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyCreatedAt_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeSalesforceId",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanySalesforceId_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeStreet",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyStreet_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCity",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyCity_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeState",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyState_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeZip",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyZip_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCountry",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyCountry_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeDescription",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CompanyDescription_company",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableText",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableText_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableTextarea",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableTextarea_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableLink",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableLink_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableDate",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableDate_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableBoolean",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableBoolean_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableNumber",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableNumber_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableCurrency",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableCurrency_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableSelect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableSelect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableMultiselect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableMultiselect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableUserSelect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableUserSelect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableUserMultiselect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableUserMultiselect_customizable",
                      "args": null
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '75cd0a44c2d74ee05b49d42d7cfdcf2b';
module.exports = node;
