/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type PaymentActionCell_event$ref = any;
type PaymentActualDateCell_event$ref = any;
type PaymentAmountFooterCell_event$ref = any;
type PaymentDueDateCell_event$ref = any;
type PaymentExpenseNameCell_event$ref = any;
type PaymentNoteCell_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentsTableRenderer_event$ref: FragmentReference;
export type PaymentsTableRenderer_event = {|
  +$fragmentRefs: PaymentExpenseNameCell_event$ref & PaymentAmountFooterCell_event$ref & PaymentDueDateCell_event$ref & PaymentActualDateCell_event$ref & PaymentNoteCell_event$ref & PaymentActionCell_event$ref,
  +$refType: PaymentsTableRenderer_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PaymentsTableRenderer_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "PaymentExpenseNameCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentAmountFooterCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentDueDateCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentActualDateCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentNoteCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentActionCell_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '93af1b88b1c43efa803287c13a9636e5';
module.exports = node;
