/* @flow */
import React from 'react';
import { type RelayProp, createRefetchContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';

import showModernMutationError from 'graph/utils/showModernMutationError';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import SelectField from 'components/material/SelectField';

import IntegrationMessage from '../IntegrationsSettings/IntegrationMessage';
import G2WLocationCard from './G2WLocationCard';
import G2WLocationForm, { type G2WLocationInputType } from './G2WLocationForm';

import { type G2WLocationPicker_integrable } from './__generated__/G2WLocationPicker_integrable.graphql';
import { type G2WLocationPickerQueryResponse } from './__generated__/G2WLocationPickerQuery.graphql';

type G2WLocationConnectionType = $NonMaybeType<
  $PropertyType<
    $NonMaybeType<$PropertyType<G2WLocationPickerQueryResponse, 'integrable'>>,
    'g2wLocations',
  >,
>;
type G2WLocationResponseType = $PropertyType<
  $ElementType<$PropertyType<G2WLocationConnectionType, 'edges'>, 0>,
  'node',
>;
export type G2WLocationType = $ReadOnly<{
  ...{ ...G2WLocationInputType },
  ...$Diff<G2WLocationResponseType, { event: any }>,
}>;

const AddNewLabel = styled.span`
  font-size: 13px;
  color: #4eb1dd;
`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const G2WLocationOption = styled.span`
  a {
    color: #4eb1dd;
  }
  small {
    display: block;
    margin-top: -2px;
    font-size: 11px;
  }
`;

const query = graphql`
  query G2WLocationPickerQuery($integrableId: ID!, $g2wUserId: ID!, $list: G2WLocationList!) {
    integrable: node(id: $integrableId) {
      ... on IntegrableInterface {
        g2wLocations(g2wUserId: $g2wUserId, list: $list) {
          edges {
            node {
              id
              g2wKey
              subject
              times {
                startTime
                endTime
              }
              timezone
              registrationUrl
              g2wUrl
              g2wUser {
                id
                g2wKey
                firstName
                lastName
                email
              }
              event {
                id
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`;

class G2WLocationPicker extends React.PureComponent<
  {
    relay: RelayProp,
    integrable: G2WLocationPicker_integrable,
    g2wLocation: ?G2WLocationType,
    isUpcomingEvent: boolean,
    defaultSubject: string,
    defaultTimezone: string,
    defaultDate: ?string,
    onPick: G2WLocationType => Promise<?{ +g2wLocation: ?{ +g2wKey: string } }>,
    onShowCreateForm?: () => void,
    onHideCreateForm?: () => void,
    eventId?: string,
  },
  {
    fetching: boolean,
    creating: boolean,
    g2wUserId: ?string,
    g2wKey: ?string,
  },
> {
  state = {
    fetching: false,
    creating: false,
    g2wUserId:
      (this.props.g2wLocation &&
        this.props.g2wLocation.g2wUser &&
        this.props.g2wLocation.g2wUser.id) ||
      null,
    g2wKey: this.props.g2wLocation ? this.props.g2wLocation.g2wKey : null,
  };

  componentDidMount() {
    window.addEventListener('focus', this.handleFocusWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.handleFocusWindow);
  }

  handleFocusWindow = () => {
    const connected = this.props.integrable.g2wSolutionInstances.totalCount > 0;

    if (connected || this.state.fetching || !this.props.integrable.viewerCanConfigureIntegrations) {
      return;
    }

    this.refetch();
  };

  refetch = () => {
    this.setState({ fetching: true });

    this.props.relay.refetch({ integrableId: this.props.integrable.id }, null, error => {
      if (error) {
        showModernMutationError(error);
      }

      this.setState({ fetching: false });
    });
  };

  handleCancelCreating = () => {
    this.setState({
      creating: false,
      fetching: false,
      g2wUserId:
        (this.props.g2wLocation &&
          this.props.g2wLocation.g2wUser &&
          this.props.g2wLocation.g2wUser.id) ||
        null,
    });

    if (this.props.onHideCreateForm) {
      this.props.onHideCreateForm();
    }
  };

  handleChangeLocation = (g2wLocation: ?G2WLocationResponseType) => {
    if (g2wLocation) {
      const { event, ...pickLocation } = { ...g2wLocation };
      this.props.onPick(pickLocation).then(response => {
        if (response) {
          this.setState({
            g2wKey: response.g2wLocation && response.g2wLocation.g2wKey,
          });
        }
      });
    } else {
      this.setState({ creating: true });

      if (this.props.onShowCreateForm) {
        this.props.onShowCreateForm();
      }
    }
  };

  handleCreate = (locationInput: G2WLocationInputType) => {
    const g2wUser = this.props.integrable.g2wUsers.edges
      .map(edge => edge.node)
      .find(user => user.id === this.state.g2wUserId);

    if (!g2wUser) return Promise.resolve();

    return this.props
      .onPick({
        id: '',
        g2wKey: '',
        registrationUrl: '',
        g2wUrl: '',
        g2wUser,
        ...locationInput,
      })
      .then(response => {
        if (response) {
          this.handleCancelCreating();
          this.setState({ g2wKey: (response.g2wLocation && response.g2wLocation.g2wKey) || null });
        }
      });
  };

  handleChangeG2WUserId = (g2wUserId: ?string) => {
    this.setState({ g2wUserId });
  };

  renderLocationsSelect = (response?: G2WLocationPickerQueryResponse) => {
    const { g2wLocation, isUpcomingEvent } = this.props;
    const { g2wKey } = this.state;

    const g2wLocations =
      response && response.integrable && response.integrable.g2wLocations
        ? orderBy(
            response.integrable.g2wLocations.edges.map(edge => edge.node),
            // [location => location.startTime],
            [isUpcomingEvent ? 'asc' : 'desc'],
          )
        : [];

    const options = response
      ? [
          {
            value: '',
            label: 'Create New GoToWebinar',
            displayLabel: <AddNewLabel>Create New GoToWebinar</AddNewLabel>,
          },
          ...g2wLocations.map(location => {
            const event = location.event;
            const disabled = !!event && event.id !== this.props.eventId;

            return {
              value: location.id,
              label: location.subject,
              displayLabel: (
                <G2WLocationOption>
                  {location.subject}
                  {disabled && event && (
                    <small>
                      Associated with: <Link to={`/events/${event.slug}`}>{event.name}</Link>
                    </small>
                  )}
                </G2WLocationOption>
              ),
              disabled,
            };
          }),
        ]
      : [];

    return (
      <SelectField
        label="Select Webinar"
        value={null}
        options={options}
        onChange={locationId =>
          this.handleChangeLocation(g2wLocations.find(location => location.id === locationId))
        }
        loading={!response || g2wKey !== ((g2wLocation && g2wLocation.g2wKey) || null)}
        searchable
      />
    );
  };

  renderConnectMessage = () => {
    if (this.props.integrable.viewerCanConfigureIntegrations) {
      return (
        <IntegrationMessage>
          Connect your GoToWebinar Admin Account.{' '}
          <Link to="/settings/integrations/g2w" target="_blank">
            View settings
          </Link>
        </IntegrationMessage>
      );
    }

    return (
      <IntegrationMessage>
        Please ask you Admin to configure GoToWebinar integration in Workspace Settings.
      </IntegrationMessage>
    );
  };

  render() {
    const {
      integrable,
      g2wLocation,
      defaultSubject,
      defaultTimezone,
      defaultDate,
      isUpcomingEvent,
    } = this.props;
    const { creating, g2wUserId, g2wKey } = this.state;

    if (creating) {
      return (
        <G2WLocationForm
          defaultSubject={defaultSubject}
          defaultTimezone={defaultTimezone}
          defaultDate={defaultDate}
          onCancel={this.handleCancelCreating}
          onCreate={this.handleCreate}
        />
      );
    }

    const connectMessage =
      this.props.integrable.g2wSolutionInstances.totalCount === 0
        ? this.renderConnectMessage()
        : null;

    const g2wUsers = integrable.g2wUsers.edges.map(edge => edge.node);
    const g2wUser = g2wUsers.find(user => user.id === g2wUserId);
    const userOptions = orderBy(
      g2wUsers.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName} (${user.email})`,
      })),
      [option => option.label.toLowerCase()],
    );

    return (
      <>
        {connectMessage || (
          <>
            <Row>
              <SelectField
                label="Select User"
                value={g2wUserId}
                options={userOptions}
                onChange={this.handleChangeG2WUserId}
                searchable
              />
            </Row>

            {g2wUser && (
              <Row>
                <DefaultQueryRenderer
                  key={g2wKey}
                  query={query}
                  variables={{
                    integrableId: integrable.id,
                    g2wUserId,
                    list: isUpcomingEvent ? 'UPCOMING' : 'PAST',
                  }}
                  renderSuccess={this.renderLocationsSelect}
                  renderLoading={this.renderLocationsSelect}
                  renderError={this.renderConnectMessage}
                />
              </Row>
            )}
          </>
        )}

        {g2wLocation && <G2WLocationCard g2wLocation={g2wLocation} />}
      </>
    );
  }
}

export default createRefetchContainer(
  G2WLocationPicker,
  graphql`
    fragment G2WLocationPicker_integrable on IntegrableInterface {
      id
      viewerCanConfigureIntegrations

      g2wSolutionInstances: traySolutionInstances(solutionName: G2W) {
        totalCount
      }

      g2wUsers {
        edges {
          node {
            id
            g2wKey
            firstName
            lastName
            email
          }
        }
      }
    }
  `,
  graphql`
    query G2WLocationPickerRefetchQuery($integrableId: ID!) {
      node(id: $integrableId) {
        ...G2WLocationPicker_integrable
      }
    }
  `,
);
