/**
 * @flow
 * @relayHash 81c43dc5f665a61a68232ff7e90d9268
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactMultiselectQueryVariables = {|
  contactIds: $ReadOnlyArray<string>
|};
export type ContactMultiselectQueryResponse = {|
  +selectedContacts: $ReadOnlyArray<?{|
    +id: string,
    +firstName?: string,
    +lastName?: string,
    +email?: string,
  |}>
|};
export type ContactMultiselectQuery = {|
  variables: ContactMultiselectQueryVariables,
  response: ContactMultiselectQueryResponse,
|};
*/


/*
query ContactMultiselectQuery(
  $contactIds: [ID!]!
) {
  selectedContacts: nodes(ids: $contactIds) {
    __typename
    id
    ... on Contact {
      id
      firstName
      lastName
      email
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "contactIds",
    "type": "[ID!]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "contactIds",
    "type": "[ID!]!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "type": "Contact",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ContactMultiselectQuery",
  "id": null,
  "text": "query ContactMultiselectQuery(\n  $contactIds: [ID!]!\n) {\n  selectedContacts: nodes(ids: $contactIds) {\n    __typename\n    id\n    ... on Contact {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ContactMultiselectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "selectedContacts",
        "name": "nodes",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": true,
        "selections": [
          v2,
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContactMultiselectQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "selectedContacts",
        "name": "nodes",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v2,
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ff516f22b92004257bb852e225d4e77';
module.exports = node;
