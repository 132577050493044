/**
 * @flow
 * @relayHash c6ff6aeb84d6a81134c0d78f59be803a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DividerComponent_componentProps$ref = any;
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
export type CreateDividerComponentInput = {
  clientMutationId?: ?string,
  formId: string,
  newComponentOrder: number,
  pageComponentsOrder: $ReadOnlyArray<RegistrationFormPageComponentOrderInput>,
  parentId?: ?string,
};
export type RegistrationFormPageComponentOrderInput = {
  id: string,
  order: number,
};
export type createDividerComponentFieldsMutationVariables = {|
  input: CreateDividerComponentInput
|};
export type createDividerComponentFieldsMutationResponse = {|
  +createRegistrationFormDividerComponent: ?{|
    +pageComponentEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +order: number,
        +kind: RegistrationPageComponentKind,
        +$fragmentRefs: DividerComponent_componentProps$ref,
      |},
    |},
    +updatedPageComponents: $ReadOnlyArray<{|
      +id: string,
      +order: number,
      +parent: ?{|
        +id: string
      |},
    |}>,
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type createDividerComponentFieldsMutation = {|
  variables: createDividerComponentFieldsMutationVariables,
  response: createDividerComponentFieldsMutationResponse,
|};
*/


/*
mutation createDividerComponentFieldsMutation(
  $input: CreateDividerComponentInput!
) {
  createRegistrationFormDividerComponent(input: $input) {
    pageComponentEdge {
      __typename
      node {
        id
        order
        kind
        ...DividerComponent_componentProps
      }
    }
    updatedPageComponents {
      id
      order
      parent {
        id
      }
    }
    registrationForm {
      id
      hasChanges
    }
  }
}

fragment DividerComponent_componentProps on RegistrationPageComponent {
  dividerComponent {
    padding
    height
    color
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDividerComponentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateDividerComponentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "updatedPageComponents",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationPageComponent",
  "plural": true,
  "selections": [
    v3,
    v4,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationPageComponent",
      "plural": false,
      "selections": [
        v3
      ]
    }
  ]
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createDividerComponentFieldsMutation",
  "id": null,
  "text": "mutation createDividerComponentFieldsMutation(\n  $input: CreateDividerComponentInput!\n) {\n  createRegistrationFormDividerComponent(input: $input) {\n    pageComponentEdge {\n      __typename\n      node {\n        id\n        order\n        kind\n        ...DividerComponent_componentProps\n      }\n    }\n    updatedPageComponents {\n      id\n      order\n      parent {\n        id\n      }\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n\nfragment DividerComponent_componentProps on RegistrationPageComponent {\n  dividerComponent {\n    padding\n    height\n    color\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createDividerComponentFieldsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createRegistrationFormDividerComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateDividerComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponentEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponentRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "RegistrationPageComponent",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "FragmentSpread",
                    "name": "DividerComponent_componentProps",
                    "args": null
                  }
                ]
              }
            ]
          },
          v6,
          v7
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createDividerComponentFieldsMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createRegistrationFormDividerComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateDividerComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponentEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponentRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "RegistrationPageComponent",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "dividerComponent",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "RegistrationDividerComponent",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "padding",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "height",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "color",
                        "args": null,
                        "storageKey": null
                      },
                      v3
                    ]
                  }
                ]
              }
            ]
          },
          v6,
          v7
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '649d3a361690ad8496147eaf9bc7619d';
module.exports = node;
