/* @flow */
import formatPercentage from 'utils/number/formatPercentage';

import { type ReportingCellPropsType } from './index';

const ReportingPaidPercentCell = ({ group, childGroup }: ReportingCellPropsType) => {
  if (childGroup == null) {
    return formatPercentage(group.paidAmount / group.actualAmount);
  }
  return formatPercentage(childGroup.paidAmount / childGroup.actualAmount);
};

export default ReportingPaidPercentCell;
