/* @flow */

import * as React from 'react';
import styled from 'styled-components';

import currencies from 'config/currencies.json';
import paymentMethods from 'config/paymentMethods';
import paymentStatuses, { type PaymentStatus } from 'config/paymentStatuses';
import paymentTypes from 'config/paymentTypes';

import getCurrencyRates, { type RateType } from 'utils/getCurrencyRates';
import formatCost from 'utils/number/formatCost';
import formatPercentage from 'utils/number/formatPercentage';
import type { CircaFile } from 'utils/uploading/types';

import type { PaymentType } from 'graph/mutations/payment/createPayment';

import DateTimePicker, { type DateConfig } from 'components/date/DateTimePicker';
import { DropZone } from 'components/DropZone';
import SimpleAttachments from 'components/material/Attachments/SimpleAttachments';
import CostField from 'components/material/CostField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import TextInput from 'components/material/TextInput';
import Tooltip from 'components/material/Tooltip';

const paymentStatusColor = (status: string) => {
  return status === 'PAID' ? '#f2fff3' : '#fff2f2';
};

const Container = styled.div`
  position: relative;
  width: 1104px;
  background-color: #ffffff;
  border-bottom: 1px solid #d8d8d8;
  color: #4a5665;
  font-size: 13px;
  &:last-child {
    border-bottom: none;
  }
`;

const PaymentBody = styled.div`
  display: flex;
`;

const PaymentNumberBlock = styled.div`
  width: 25px;
  padding: 15px 0 0 8px;
  border-right: 1px solid #d8d8d8;
  background-color: ${props => paymentStatusColor(props.status)};
`;

const PaymentInputContainer = styled.div`
  width: 100%;
  padding: 0 10px;
`;

const StyledTextField = styled(TextField)`
  width: ${props => props.width}px;
  margin: 0 15px;
  input {
    padding-top: 5px;
    text-align: ${props => (props.alignRight ? 'right' : 'left')};
  }
  &:last-child {
    margin-right: 0;
  }
`;

const StyledNotesField = styled(TextField)`
  width: 700px;
  margin: 5px 0 16px;
`;

const StyledSelectField = styled(SelectField)`
  width: ${props => props.width}px;
  margin: 0 15px;
  padding: 0;
  text-transform: ${props => (props.capitalize ? 'capitalize' : 'inherit')};
  > div:first-child {
    min-width: 0;
    padding: 7px 0 2px;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const DateTimePickerContainer = styled.div`
  width: ${props => props.width}px;
  margin: 0 15px;
  label {
    margin: 0;
    input {
      padding-top: 5px;
    }
  }
`;

const PaymentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const PaymentReferenceContainer = styled.div`
  margin: 18px 0 10px;
  padding: 10px 20px 15px;
  background-color: #f7f8f9;
`;

const PaymentLabel = styled.div`
  margin: 7px 16px 0 0;
  &:nth-of-type(2) {
    margin-left: 30px;
  }
`;

const RemoveBlock = styled.div`
  width: 22px;
  padding-top: 8px;
  text-align: center;
  font-size: 15px;
  border-left: 1px solid ${props => (props.newForm ? 'transparent' : '#d8d8d8')};
`;

const RemoveButton = styled.i`
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const AmountContainer = styled.div`
  position: relative;
  margin: 0 15px;
  label {
    margin: 0;
  }
`;

const CalculatedAmount = styled.div`
  position: absolute;
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #2dd69c;

  > div {
    display: inline-block;
  }
`;

const StyledCostField = styled(CostField)`
  width: ${props => props.width}px;
  input {
    padding-top: 5px;
    text-align: ${props => (props.alignRight ? 'right' : 'left')};
  }
  margin-right: 28px;
`;

const SelectOtherRow = styled.div`
  margin: -5px -10px;

  input {
    padding: 4px 10px;
    border-bottom: 1px solid transparent;
  }
`;

const StyledDropZone = styled(DropZone)`
  .attachment-drop {
    padding-top: 45px;
    i {
      font-size: 40px !important;
    }
  }
`;

export type DerivedPaymentType = {
  ...$Exact<
    $Diff<
      PaymentType,
      { amount: number, attachments?: $ReadOnlyArray<CircaFile>, expenseId: string },
    >,
  >,
  amount: ?number,
  attachments: $ReadOnlyArray<CircaFile>,
  viewerCanDelete: boolean,
};

type Props = {
  orgCurrency: string,
  payment: DerivedPaymentType,
  index: number,
  tz: string,
  expenseActualAmount: number,
  onRemovePayment: () => void,
  onAddAttachment: (attachment: CircaFile) => void,
  onRemoveAttachment: (attachmentId: string) => void,
  onFilesDrop: (files: Array<File>) => void,
  onStatusChange: (status: PaymentStatus) => void,
  onDueDateChange: (dueDate: ?string) => void,
  onActualDateChange: (actualDate: ?string) => void,
  onPaymentTypeChange: (paymentType: string) => void,
  onPaymentMethodChange: (paymentMethod: ?string) => void,
  onAmountChange: (amount: ?number) => void,
  onInputChange: (e: SyntheticEvent<HTMLInputElement>) => void,
};

type State = {
  amount: number,
  validateAmount: boolean,
  actualAmount: number,
  currency: string,
  rate: number,
  rates: RateType,
  showPaymentMethodOtherInput: boolean,
};

class PaymentForm extends React.Component<Props, State> {
  state = {
    actualAmount: this.props.payment.amount != null ? this.props.payment.amount : 0,
    amount: this.props.payment.amount != null ? this.props.payment.amount : 0,
    currency: this.props.orgCurrency,
    rate: 1,
    rates: {},
    validateAmount: false,
    showPaymentMethodOtherInput: false,
  };

  mounted: boolean = false;

  componentDidMount() {
    this.mounted = true;
    getCurrencyRates().then(data => {
      if (this.mounted && data.rates) {
        this.setState({ rates: data.rates });
      }
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { rate, currency } = this.state;
    const { orgCurrency } = this.props;

    if (
      this.props.payment.amount != null &&
      this.props.payment.amount !== prevProps.payment.amount
    ) {
      const amount = this.props.payment.amount;
      const exchangedAmount = Math.round(this.props.payment.amount * rate);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        actualAmount: orgCurrency !== currency ? exchangedAmount : amount,
        amount,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleCurrencyChange = (currency: ?string) => {
    if (currency) {
      this.setState({ currency });
      this.handleCurrencyExchange(currency);
    }
  };

  handleCurrencyExchange = (currency: string) => {
    const { rates, amount } = this.state;
    const { orgCurrency } = this.props;
    if (orgCurrency !== currency && amount != null && rates != null) {
      const exchangeRate = rates[currency] / rates[orgCurrency];
      const exchangedAmount: number = Math.round(exchangeRate * amount);
      this.setState({
        actualAmount: exchangedAmount,
        rate: exchangeRate,
      });
    } else {
      this.setState({
        actualAmount: amount,
        rate: 1,
      });
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  handlePaymentMethodKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = event.currentTarget.value.trim();

      if (value) {
        this.props.onPaymentMethodChange(value);

        event.currentTarget.blur();
      }
    } else if (event.key === 'Escape') {
      event.currentTarget.blur();
    }
  };

  handlePaymentMethodBlur = () => {
    this.setState({ showPaymentMethodOtherInput: false });
  };

  handleAmountBlur = () => {
    this.props.onAmountChange(this.state.amount);
  };

  handleAmountChange = (e: SyntheticEvent<HTMLInputElement>, amount: ?number) => {
    const { rate, currency } = this.state;
    const { orgCurrency } = this.props;
    if (amount == null) {
      if (!this.state.validateAmount) {
        this.setState({
          amount: amount || 0,
          validateAmount: true,
        });
      }
    } else {
      if (orgCurrency === currency) {
        this.setState({ amount });
      } else {
        this.setState({
          amount: Math.round(amount / rate),
          actualAmount: amount,
        });
      }
      if (this.state.validateAmount) {
        this.setState({ validateAmount: false });
      }
    }
  };

  handleDueDateChange = ({ date }: DateConfig) => {
    this.props.onDueDateChange(date);
  };

  handleActualDateChange = ({ date }: DateConfig) => {
    this.props.onActualDateChange(date);
  };

  handleStatusChange = (status: ?PaymentStatus) => {
    if (status) {
      this.props.onStatusChange(status);
    }
  };

  handlePaymentTypeChange = (paymentType: ?string) => {
    if (paymentType) {
      this.props.onPaymentTypeChange(paymentType);
    }
  };

  render() {
    const { currency, amount, rates, showPaymentMethodOtherInput } = this.state;
    const {
      payment,
      index,
      expenseActualAmount,
      orgCurrency,
      tz,
      onFilesDrop,
      onInputChange,
      onPaymentMethodChange,
      onRemovePayment,
      onAddAttachment,
      onRemoveAttachment,
    } = this.props;
    const orgCurrencyObj = orgCurrency && currencies.find(item => item.code === orgCurrency);
    return (
      <Container>
        <StyledDropZone onDrop={onFilesDrop}>
          <PaymentBody>
            <PaymentNumberBlock status={payment.status}>
              {typeof index !== 'undefined' ? `${index + 1}.` : ''}
            </PaymentNumberBlock>
            <PaymentInputContainer>
              <PaymentRow>
                <StyledSelectField
                  width="98"
                  value={payment.paymentType}
                  onChange={this.handlePaymentTypeChange}
                  options={Object.keys(paymentTypes).map(key => ({
                    value: key,
                    label: paymentTypes[key],
                  }))}
                />
                <AmountContainer>
                  <StyledCostField
                    placeholder="Amount"
                    width="108"
                    defaultValue={this.state.actualAmount}
                    onBlur={this.handleAmountBlur}
                    onChange={this.handleAmountChange}
                    onKeyDown={this.handleKeyDown}
                    error={this.state.validateAmount ? 'Required' : ''}
                    currency={currency}
                    alignRight
                  />
                  {currency !== orgCurrency && (
                    <CalculatedAmount>
                      <Tooltip
                        label={`${formatCost(100, orgCurrency)} = ${formatCost(
                          Math.round(this.state.rate * 100),
                          currency,
                        )}\nWorkspace Currency: ${
                          orgCurrencyObj ? orgCurrencyObj.symbol : ''
                        }${orgCurrency}`}
                        placement="bottom"
                      >
                        {formatCost(amount, orgCurrency)}
                      </Tooltip>
                    </CalculatedAmount>
                  )}
                </AmountContainer>
                <StyledSelectField
                  width="80"
                  value={currency}
                  onChange={this.handleCurrencyChange}
                  options={currencies.map(item => ({
                    value: item.code,
                    label: `${item.code} (${item.symbol})`,
                  }))}
                  searchable
                  disabled={Object.keys(rates).length === 0}
                  overlayContainer={document.body}
                />
                <StyledTextField
                  width="83"
                  alignRight
                  placeholder="% of Total"
                  value={formatPercentage(+this.state.amount / expenseActualAmount)}
                  readOnly
                />
                <DateTimePickerContainer width="115">
                  <DateTimePicker
                    placeholder="Due Date"
                    date={payment.dueDate}
                    onChange={this.handleDueDateChange}
                    hideTime
                    dateOnly
                    tz={tz}
                    overlayContainer={document.body}
                  />
                </DateTimePickerContainer>
                <DateTimePickerContainer width="115">
                  <DateTimePicker
                    placeholder="Actual Payment Date"
                    date={payment.actualDate}
                    onChange={this.handleActualDateChange}
                    hideTime
                    dateOnly
                    tz={tz}
                    overlayContainer={document.body}
                  />
                </DateTimePickerContainer>
                <StyledSelectField
                  width="80"
                  capitalize
                  value={payment.status}
                  onChange={this.handleStatusChange}
                  options={Object.keys(paymentStatuses).map(key => ({
                    value: key,
                    label: paymentStatuses[key],
                  }))}
                />
                <StyledSelectField
                  width="130"
                  placeholder="Payment Method"
                  clearable
                  value={payment.paymentMethod}
                  onChange={onPaymentMethodChange}
                  options={[
                    ...paymentMethods.map(method => ({ label: method.label, value: method.value })),
                    ...(!payment.paymentMethod ||
                    paymentMethods.some(method => method.value === payment.paymentMethod)
                      ? []
                      : [
                          {
                            label: payment.paymentMethod,
                            value: payment.paymentMethod,
                          },
                        ]),
                    {
                      label: 'Other',
                      value: '__other',
                      displayLabel: showPaymentMethodOtherInput ? (
                        <SelectOtherRow>
                          <TextInput
                            autoFocus
                            placeholder="Custom Payment Method"
                            onKeyDown={this.handlePaymentMethodKeyDown}
                            onBlur={this.handlePaymentMethodBlur}
                          />
                        </SelectOtherRow>
                      ) : null,
                      onSelect: () => this.setState({ showPaymentMethodOtherInput: true }),
                    },
                  ]}
                  overlayContainer={document.body}
                />
              </PaymentRow>
              <PaymentReferenceContainer>
                <PaymentRow>
                  <PaymentLabel>PO#:</PaymentLabel>
                  <StyledTextField
                    width="98"
                    placeholder="PO#"
                    name="poNumber"
                    defaultValue={payment.poNumber || ''}
                    onBlur={onInputChange}
                    onKeyDown={this.handleKeyDown}
                  />
                  <PaymentLabel>Ref. Code:</PaymentLabel>
                  <StyledTextField
                    width="98"
                    placeholder="Ref. Code"
                    name="reference"
                    defaultValue={payment.reference || ''}
                    onBlur={onInputChange}
                    onKeyDown={this.handleKeyDown}
                  />
                </PaymentRow>
                <PaymentRow>
                  <StyledNotesField
                    placeholder="Note"
                    name="note"
                    multiline
                    defaultValue={payment.note}
                    onBlur={onInputChange}
                    onKeyDown={this.handleKeyDown}
                  />
                </PaymentRow>
                <PaymentRow>
                  <SimpleAttachments
                    attachments={payment.attachments || []}
                    onAdd={onAddAttachment}
                    onRemove={onRemoveAttachment}
                    overlayContainer={document.body}
                  />
                </PaymentRow>
              </PaymentReferenceContainer>
            </PaymentInputContainer>
            <RemoveBlock>
              {payment.viewerCanDelete && (
                <RemoveButton
                  className="fa fa-fw fa-trash"
                  title="Remove"
                  onClick={onRemovePayment}
                />
              )}
            </RemoveBlock>
          </PaymentBody>
        </StyledDropZone>
      </Container>
    );
  }
}

export default PaymentForm;
