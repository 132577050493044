/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation discardRegistrationFormChangesMutation(
    $input: DiscardRegistrationFormChangesInput!
    $expectingResponse: Boolean!
  ) {
    discardRegistrationFormChanges(input: $input) {
      event @include(if: $expectingResponse) {
        id
        editableRegistrationForm {
          id
          hasChanges
        }
      }
    }
  }
`;

export default function discardRegistrationFormChanges(
  formId: string,
  expectingResponse: ?boolean = true,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: { formId },
      expectingResponse,
    },
  });
}
