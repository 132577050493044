/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  RegisterEventContactInput,
  registerEventContactMutationResponse,
} from './__generated__/registerEventContactMutation.graphql';

const mutation = graphql`
  mutation registerEventContactMutation($input: RegisterEventContactInput!) {
    registerEventContact(input: $input) {
      clientMutationId
    }
  }
`;

export default function registerEventContact(
  input: RegisterEventContactInput,
): Promise<registerEventContactMutationResponse> {
  return commitModernMutation({
    public: true,
    mutation,
    variables: { input },
  });
}
