/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatDuration from 'utils/number/formatDuration';

import type { ContactAttendanceDuration_eventContact } from './__generated__/ContactAttendanceDuration_eventContact.graphql';

const ContactAttendanceDuration = (props: {
  eventContact: ContactAttendanceDuration_eventContact,
}) => {
  const duration =
    (props.eventContact.zoomRegistrant && props.eventContact.zoomRegistrant.duration) ||
    (props.eventContact.g2wRegistrant && props.eventContact.g2wRegistrant.duration) ||
    (props.eventContact.ibmWmRegistrant && props.eventContact.ibmWmRegistrant.duration);

  if (duration == null) return null;

  return formatDuration(duration);
};

export default createFragmentContainer(
  ContactAttendanceDuration,
  graphql`
    fragment ContactAttendanceDuration_eventContact on EventContactEdge {
      zoomRegistrant {
        duration
      }
      g2wRegistrant {
        duration
      }
      ibmWmRegistrant {
        duration
      }
    }
  `,
);
