/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Link = styled.span`
  font-size: 13px;
  font-style: italic;
  color: ${props =>
    props.highlighted ? props.theme.primaryActionColor : props.theme.secondaryActionColor};
  cursor: pointer;
  &:hover {
    color: ${props =>
      props.highlighted
        ? props.theme.primaryActionDarkerColor
        : props.theme.secondaryActionDarkerColor};
  }
  @media (${props => props.theme.mobileOnly}) {
    display: none;
  }
`;

const Icon = styled.i`
  margin-right: 4px;
`;

export const styledComponent = Link;

export default class EmptyLink extends React.PureComponent<{
  label: string,
  icon: string,
  highlighted?: boolean,
  onClick: (e: Event) => void,
}> {
  render() {
    return (
      <Link highlighted={this.props.highlighted} onClick={this.props.onClick}>
        <Icon className={`fa fa-${this.props.icon}`} /> {this.props.label}
      </Link>
    );
  }
}
