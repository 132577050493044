/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import formatCost from 'utils/number/formatCost';

import saveTeamBudgetPeriod from 'graph/mutations/budget_period/saveTeamBudgetPeriod';
import showModernMutationError from 'graph/utils/showModernMutationError';

import TeamRow, { Column } from './TeamRow';

import { type TeamBudgeting_budgetPeriod } from './__generated__/TeamBudgeting_budgetPeriod.graphql';
import { type TeamBudgeting_org } from './__generated__/TeamBudgeting_org.graphql';
import { type TeamBudgeting_teams } from './__generated__/TeamBudgeting_teams.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const Content = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  max-height: 340px;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  ${Column} {
    padding: 11px 19px;
    font-weight: bold;
  }
`;

const Footer = styled.div`
  display: flex;
  background-color: #f2fbff;
  border-top: 1px solid #3baadd;
  border-bottom: 1px solid #3baadd;
  ${Column} {
    padding: 20px 19px 18px;
    font-size: 14px;
    font-weight: 500;
    color: #3e4859;
    &:last-child {
      padding-left: 28px;
    }
  }
`;

type TeamType = {|
  id: string,
  name: string,
  amount: number,
|};

class TeamBudgeting extends React.Component<{
  org: TeamBudgeting_org,
  budgetPeriod: TeamBudgeting_budgetPeriod,
  teams: TeamBudgeting_teams,
  period: string,
}> {
  getTeamsBudgeted = (
    teams: TeamBudgeting_teams,
    budgetPeriod: TeamBudgeting_budgetPeriod,
  ): $ReadOnlyArray<TeamType> => {
    return sortBy(
      budgetPeriod
        ? budgetPeriod.teams.edges.map(({ node, amount }) => ({ ...node, amount }))
        : teams.map(team => ({ id: team.id, name: team.name, amount: 0 })),
      ['name', 'amount'],
    );
  };

  handleBudgetChange = (teamId: string, amount: number) => {
    const {
      org: { id: orgId },
      budgetPeriod,
      period,
    } = this.props;
    const periodId = budgetPeriod && budgetPeriod.id;
    const year = parseInt(period.split('_')[1], 10);
    const quarter = parseInt(period[1], 10);
    saveTeamBudgetPeriod({ orgId, periodId, year, quarter, teamId, amount }).catch(
      showModernMutationError,
    );
  };

  render() {
    const { org, budgetPeriod, teams, period } = this.props;
    const teamsBudgeted = this.getTeamsBudgeted(teams, budgetPeriod);
    const totalBudget = teamsBudgeted.reduce((total, team) => total + team.amount, 0);
    return (
      <Container>
        <Header>
          <Column>Team</Column>
          <Column>Budget</Column>
        </Header>
        <Content>
          {teamsBudgeted.map(t => (
            <TeamRow
              key={t.id}
              id={t.id}
              settings={org.settings}
              onBudgetChange={this.handleBudgetChange}
              period={period}
              name={t.name}
              amount={t.amount}
            />
          ))}
        </Content>
        <Footer>
          <Column>Workspace Total</Column>
          <Column>{formatCost(totalBudget, org.settings.currency)}</Column>
        </Footer>
      </Container>
    );
  }
}

export default createFragmentContainer(TeamBudgeting, {
  budgetPeriod: graphql`
    fragment TeamBudgeting_budgetPeriod on BudgetPeriod {
      id
      period
      startDate
      endDate
      teams {
        edges {
          amount
          node {
            id
            name
          }
        }
      }
    }
  `,
  org: graphql`
    fragment TeamBudgeting_org on Org {
      id
      settings {
        currency
        ...TeamRow_settings
      }
    }
  `,
  teams: graphql`
    fragment TeamBudgeting_teams on Team @relay(plural: true) {
      id
      name
    }
  `,
});
