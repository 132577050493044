import './styles.styl';

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

import { fitInViewPort } from 'utils/ViewPort';

export class Panel extends React.Component {
  static propTypes = {
    hideHeader: PropTypes.bool,
    startShown: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    onClose: PropTypes.func,
    className: PropTypes.string,
    clickArea: PropTypes.func,
    modalOnMobile: PropTypes.bool,
    children: PropTypes.node,
    title: PropTypes.string,
    align: PropTypes.string,
    size: PropTypes.string,
    minHeight: PropTypes.number,
    minWidth: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = {
      shown: this.props.startShown,
    };

    this.closePanelListener = (e) => {
      const area = (this.props.clickArea && this.props.clickArea()) || this.refs.panel;
      if (! this.refs.panel) {
        document.body.removeEventListener('click', this.closePanelListener);
      } else {
        if (! area.contains(e.target) && e.target !== document.body) {
          this.hide();
          if (this.props.onClose) {
            this.props.onClose();
          }
        }
      }
    };
  }

  componentDidMount() {
    this.reposition();
  }

  componentDidUpdate() {
    this.reposition();
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.closePanelListener);
  }

  show() {
    this.setState({
      shown: true,
    });
    if (this.props.onShow) {
      this.props.onShow();
    }
  }

  hide() {
    document.body.removeEventListener('click', this.closePanelListener);
    this.setState({
      shown: false,
    });
    if (this.props.onHide) {
      this.props.onHide();
    }
  }

  toggle() {
    this.setState({
      shown: ! this.state.shown,
    });
    if (this.props.onHide && this.state.shown) this.props.onHide();
  }

  reposition() {
    if (this.refs.panel) {
      const element = this.refs.panel;
      const options = {};
      if (this.props.minHeight) options.height = this.props.minHeight;
      if (this.props.minWidth) options.width = this.props.minWidth;
      fitInViewPort(element, options);
      document.body.addEventListener('click', this.closePanelListener);
    }
  }

  render() {
    if (!this.state.shown) {
      return <div key="panel-placeholder" style={{ display: 'none' }} />;
    }

    const props = Object.assign({}, this.props, {
      close: this.props.onClose || this.hide.bind(this),
      title: '',
    });

    const panelClasses = classNames(
      'panel',
      this.props.align,
      this.props.size,
      this.props.className,
      {
        'panel-hidden': !this.state.shown,
        'panel-mobile': !this.props.modalOnMobile,
        'panel-modal': this.props.modalOnMobile,
      }
    );

    let panel = (
      <div
        ref="panel"
        className={panelClasses}
        onClick={(e) => e.stopPropagation()}
      >
        {!this.props.hideHeader &&
          <header className="panel-header">
            <span className="panel-title">{this.props.title}</span>
            <div className="panel-close" onClick={props.close}><i className="fa fa-times"></i></div>
          </header>
        }
        <div className="panel-scroll">
          {this.props.children}
        </div>
      </div>
    );

    if (this.props.modalOnMobile) {
      const mobilePanelClasses = classNames('panel-wrapper', {
        shown: this.state.shown,
      });

      return (
        <div className={mobilePanelClasses}>
          <div className="panel-background" onClick={props.close}></div>
          {panel}
        </div>
      );
    }

    return panel;
  }
}

export class PanelContent extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    return (
      <div className="panel-content">
        {this.props.children}
      </div>
    );
  }
}

export class PanelFooter extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    return (
      <footer className="panel-footer">
        {this.props.children}
      </footer>
    );
  }
}
