/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineUserSelectField from 'components/budget/Table/InlineUserSelectField';
import { type User } from 'components/UserSelect';

import type { ContactOwner_contact } from './__generated__/ContactOwner_contact.graphql';

class ContactOwner extends React.PureComponent<{
  contact: ContactOwner_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  handleSelect = (owner: ?User): void => {
    // since there is no way to remove value from inlineUserSelect from column
    // if there will be the way to remove inlineUserSelect the this.getError should be changed accordingly
    if (this.getError(owner)) {
      return;
    }
    this.props.onUpdate({ ownerId: owner ? owner.id : null }).then(() => {
      this.props.updateColumnWidth();
    });
  };

  getError = (user: ?User): ?string => {
    const { fieldSettings, contact } = this.props;
    if (fieldSettings.required && user == null && contact.owner == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { updateColumnWidth, fieldSettings, contact } = this.props;
    return (
      <InlineUserSelectField
        placeholder={fieldSettings.label}
        activeUsers={contact.owner ? [contact.owner] : []}
        onSelect={this.handleSelect}
        clearable={!fieldSettings.required}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        disabled={fieldSettings.restrictChangingValue}
      />
    );
  }
}

export default createFragmentContainer(
  ContactOwner,
  graphql`
    fragment ContactOwner_contact on Contact {
      owner {
        id
        firstName
        lastName
        email
        avatar
        ...MaterialAvatar_user
      }
    }
  `,
);
