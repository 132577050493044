/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextField from 'components/material/TextField';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 8px;
`;

const NameText = styled(TextField)`
  flex: 1 2 272px;
  margin: 0;
`;

const DescriptionText = styled(TextField)`
  flex: 2 2 544px;
  margin: 0 0 0 10px;
`;

export default class OrgSettingsPropsForm extends React.Component<
  {
    itemName: string,
    name?: string,
    description?: string,
    onSave: (name: string, description: string) => void,
    className?: string,
    firstFocus: boolean,
  },
  { finished: boolean, name: string, description: string },
> {
  state = {
    name: '',
    description: '',
    finished: false,
  };

  name: HTMLInputElement;

  description: HTMLInputElement;

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.handleSave();
    }
  };

  handleSave = () => {
    this.props.onSave(this.name.value, this.description.value);
  };

  handleFinish = () => {
    this.setState({ finished: true, name: this.name.value, description: this.description.value });
    setTimeout(() => {
      if (this.state.finished) {
        this.handleSave();
      }
    }, 10);
  };

  handleFocus = () => {
    this.setState({ finished: false });
  };

  render() {
    const itemName = `${this.props.itemName.charAt(0).toUpperCase()}${this.props.itemName.slice(
      1,
    )}`;
    return (
      <Container className={this.props.className}>
        <NameText
          label={itemName}
          defaultValue={this.props.name || this.state.name || ''}
          inputRef={t => {
            this.name = t;
          }}
          autoFocus={this.props.firstFocus}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleFinish}
          onFocus={this.handleFocus}
        />
        <DescriptionText
          label="Description"
          defaultValue={this.props.description || this.state.description || ''}
          inputRef={t => {
            this.description = t;
          }}
          autoFocus={!this.props.firstFocus}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleFinish}
          onFocus={this.handleFocus}
        />
      </Container>
    );
  }
}
