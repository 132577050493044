/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  SetEventMarketoProgramInput,
  setEventMarketoProgramMutationResponse,
} from './__generated__/setEventMarketoProgramMutation.graphql';

const mutation = graphql`
  mutation setEventMarketoProgramMutation($input: SetEventMarketoProgramInput!) {
    setEventMarketoProgram(input: $input) {
      event {
        id
        marketoProgram {
          marketoId
          name
          url
        }
        syncedContacts: eventContacts(filters: { membershipSyncStatuses: [OK] }) {
          totalCount
        }
        disabledContacts: eventContacts(filters: { membershipSyncStatuses: [DISABLED] }) {
          totalCount
        }
        failedContacts: eventContacts(filters: { membershipSyncStatuses: [FAILING] }) {
          totalCount
        }
        pendingContacts: eventContacts(filters: { membershipSyncStatuses: [PENDING] }) {
          totalCount
        }
      }
    }
  }
`;

const setEventMarketoProgram = (
  input: SetEventMarketoProgramInput,
): Promise<setEventMarketoProgramMutationResponse> => {
  const optimisticResponse: ?setEventMarketoProgramMutationResponse = {
    setEventMarketoProgram: {
      event: {
        id: input.eventId,
        marketoProgram: input.marketoProgram ? { ...input.marketoProgram } : null,
        syncedContacts: { totalCount: 0 },
        disabledContacts: { totalCount: 0 },
        failedContacts: { totalCount: 0 },
        pendingContacts: { totalCount: 0 },
      },
    },
  };
  return commitModernMutation({
    mutation,
    variables: { input },
    optimisticResponse,
  });
};

export default setEventMarketoProgram;
