/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type StaffHeader_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffPage_event$ref: FragmentReference;
export type StaffPage_event = {|
  +slug: string,
  +$fragmentRefs: StaffHeader_event$ref,
  +$refType: StaffPage_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "StaffPage_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "StaffHeader_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '69d978127b90a621d28d77055ffa8742';
module.exports = node;
