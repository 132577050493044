/* @flow */
import React from 'react';
import styled from 'styled-components';

import Filter from 'images/filter.svg';

const BubbleIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  background-color: #4a5665;
  color: #fff;
`;

const Label = styled.span`
  vertical-align: middle;
  margin-right: 8px;
`;

const StyledFilterIcon = styled(Filter)`
  vertical-align: middle;
  margin-right: 10px;
  g {
    fill: #4069e1;
  }
`;

// $FlowFixMe
const FilterButton = React.forwardRef((props: { count: number, label?: string }, ref) => (
  <div ref={ref}>
    <StyledFilterIcon />
    <Label>{props.label || 'Filters'}</Label>
    {props.count !== 0 && <BubbleIcon>{props.count}</BubbleIcon>}
  </div>
));

export default FilterButton;
