/* @flow */
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import removeVendorAssignment from 'graph/mutations/event/removeVendorAssignment';
import addVendorToBrief from 'graph/mutations/event_brief/addVendorToBrief';
import removeVendorFromBrief from 'graph/mutations/event_brief/removeVendorFromBrief';
import removeVendor from 'graph/mutations/vendor/removeVendor';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Breadcrumb from 'components/Breadcrumb';
import ConfirmationWindow from 'components/ConfirmationWindow';
import Tag from 'components/Contacts/Tag';
import CompanyIcon from 'components/Participants/CompanyIcon';
import ShareDropdown from 'components/ShareDropdown';

import VendorPageMenu from './VendorPageMenu';
import VendorPageRoutes from './VendorPageRoutes';

import type { VendorPage_event } from './__generated__/VendorPage_event.graphql';
import type { VendorPage_org } from './__generated__/VendorPage_org.graphql';
import type { VendorPage_user } from './__generated__/VendorPage_user.graphql';
import type { VendorPage_vendor } from './__generated__/VendorPage_vendor.graphql';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 33px 0 33px;
`;

const VendorInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 33px 15px 33px;
`;

const VendorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 30px;
`;

const VendorName = styled.div`
  font-size: 17px;
  color: #4a5665;
`;

const VendorTitle = styled.div`
  margin-top: 3px;
  font-size: 14px;
  color: #4a5665;
`;

const VendorTag = styled.div`
  display: block;
  margin-top: 8px;
`;

const Container = styled.div`
  padding-top: 20px;
`;

const StyledVendorIcon = styled(CompanyIcon)`
  width: 85px;
  height: 85px;
  border: 0;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom,
    #705ec2,
    #705ec2 13%,
    #6078c5 34%,
    #3d7dcb 65%,
    #40bdde 100%,
    #017a84,
    #146173
  );
  > svg {
    width: 35px;
    height: 35px;
    color: #fff;
  }
`;

class VendorPage extends React.PureComponent<
  {
    org: VendorPage_org,
    vendor: VendorPage_vendor,
    user: VendorPage_user,
    event?: VendorPage_event,
    location: Location,
    history: History,
    match: Match,
  },
  {
    confirmingDelete: boolean,
  },
> {
  state = {
    confirmingDelete: false,
  };

  getReturnUrl = () => {
    const { state } = this.props.location;
    if (state && state.prevPage === true && state.prevPath) {
      return state.prevPath + state.search;
    }

    const { params, path } = this.props.match;
    switch (params[0]) {
      case 'events':
        return `/events/${params.event_slug}/vendors`;
      case 'tasks':
        return `/events/${params.event_slug}/tasks/${params.task_slug}`;
      default:
        return path.startsWith('/contacts') ? '/contacts/vendors' : '/vendors';
    }
  };

  getBreadCrumbLabel = () => {
    const { state } = this.props.location;
    if (state && state.prevPageLabel) {
      return state.prevPageLabel;
    }

    switch (this.props.match.params[0]) {
      case 'events':
        return 'Event Vendors';
      case 'tasks':
        return 'Task Vendors';
      default:
        return 'All Vendors';
    }
  };

  handleGoBack = () => {
    this.props.history.replace(this.getReturnUrl());
  };

  handleBreadCrumbClick = () => {
    this.props.history.push(this.getReturnUrl());
  };

  handleRemove = () => {
    const vendor = this.props.vendor;
    this.handleGoBack();
    removeVendor({
      vendorId: vendor.id,
      ...(this.props.event ? { eventId: this.props.event.id } : null),
    }).catch(showModernMutationError);
  };

  handleRemoveContactAssignment = () => {
    this.handleGoBack();
    if (this.props.event != null) {
      removeVendorAssignment({
        eventId: this.props.event.id,
        fromWindow: 'vendor profile',
        vendorId: this.props.vendor.id,
      }).catch(showModernMutationError);
    }
  };

  handleConfirmationShow = () => {
    this.setState({ confirmingDelete: true });
  };

  handleConfirmationHide = () => {
    this.setState({ confirmingDelete: false });
  };

  handleAddContactToBrief = () => {
    if (this.props.event) {
      addVendorToBrief(this.props.vendor.id, this.props.event.id).catch(showModernMutationError);
    }
  };

  handleRemoveContactFromBrief = () => {
    if (this.props.event) {
      removeVendorFromBrief(this.props.vendor.id, this.props.event.id).catch(
        showModernMutationError,
      );
    }
  };

  getBriefActions = () => {
    const { vendor, event } = this.props;
    if (event != null && event.viewerCanUpdate) {
      const eventBriefVendorIds = event.briefVendors.edges.map(edge => edge.node.id);
      if (eventBriefVendorIds.includes(vendor.id)) {
        return {
          label: 'Remove from Brief',
          icon: 'minus',
          onClick: this.handleRemoveContactFromBrief,
        };
      }
      return {
        label: 'Add to Brief',
        icon: 'plus',
        onClick: this.handleAddContactToBrief,
      };
    }
    return null;
  };

  render() {
    const { org, vendor, event, user, match } = this.props;

    if (!vendor) return null;

    const actionOptions = [
      event != null
        ? { label: 'Remove from event', icon: 'minus', onClick: this.handleRemoveContactAssignment }
        : null,
      this.getBriefActions(),
      {
        label: 'Delete',
        icon: 'trash',
        onClick: this.handleConfirmationShow,
        disabled: !vendor.viewerCanRemove,
      },
    ].filter(Boolean);
    return (
      <DocumentTitle title={`${vendor.name}`}>
        <div>
          <HeaderContainer>
            <Breadcrumb
              path={[
                {
                  label: this.getBreadCrumbLabel(),
                  onClick: this.handleBreadCrumbClick,
                },
                { label: vendor.name },
              ]}
            />
            <ShareDropdown noBorder options={actionOptions} />
            {this.state.confirmingDelete && (
              <ConfirmationWindow
                onHide={this.handleConfirmationHide}
                onConfirm={this.handleRemove}
                message="Once you delete a vendor, it's gone from all of your Events and Workspace."
              />
            )}
          </HeaderContainer>
          <Container>
            <VendorInfoContainer>
              <StyledVendorIcon />
              <VendorInfo>
                <VendorName>{vendor.name}</VendorName>
                {org.customFields.edges[0].node.enabled && (
                  <VendorTitle>{vendor.title}</VendorTitle>
                )}
                <VendorTag>
                  <Tag color="#c14f93" active readOnly>
                    Vendor
                  </Tag>
                </VendorTag>
              </VendorInfo>
            </VendorInfoContainer>
            <VendorPageMenu pathPrefix={match.url} />
            <VendorPageRoutes
              vendor={vendor}
              event={event}
              user={user}
              org={org}
              pathPrefix={match.path}
              rootUrl={match.url}
            />
          </Container>
        </div>
      </DocumentTitle>
    );
  }
}

export default createFragmentContainer(VendorPage, {
  org: graphql`
    fragment VendorPage_org on Org {
      id
      ...VendorPageRoutes_org
      customFields(customizableType: [VENDOR], fieldNames: ["title"], includeDisabled: true) {
        edges {
          node {
            enabled
          }
        }
      }
    }
  `,
  vendor: graphql`
    fragment VendorPage_vendor on Vendor {
      ...VendorPageRoutes_vendor
      name
      id
      title
      org {
        id
      }
      viewerCanRemove
      viewerCanUpdate
      events {
        totalCount
      }
    }
  `,
  user: graphql`
    fragment VendorPage_user on User {
      ...VendorPageRoutes_user
    }
  `,
  event: graphql`
    fragment VendorPage_event on Event {
      ...VendorPageRoutes_event
      id
      viewerCanUpdate
      briefVendors {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
});
