/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { type ColumnConfiguration, TableRow } from 'components/material/table';

import EventRequestsActionsColumn from './EventRequestsActionsColumn';

import type { EventRequestsTableRow_eventRequestSubmission } from './__generated__/EventRequestsTableRow_eventRequestSubmission.graphql';

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  padding-right: 50px;
  &:last-child {
    border-bottom: 1px solid ${props => props.theme.primaryRowColor};
  }
`;

class EventRequestsTableRow extends React.PureComponent<{
  eventRequestSubmission: EventRequestsTableRow_eventRequestSubmission,
  viewerCanManageRequestForms: boolean,
  tz: string,
  shownColumns: ColumnConfiguration,
  onColumnLoad: (value: string, str: string, extraSpace?: number) => void,
  selected: boolean,
  onSelect: (inboxItemId: string, shiftPressed: boolean) => void,
  history: RouterHistory,
  location: Location,
}> {
  handleSelect = (checked, e: SyntheticMouseEvent<HTMLElement>) => {
    this.props.onSelect(this.props.eventRequestSubmission.id, e.shiftKey);
  };

  handleRowClick = () => {
    this.props.history.push(
      `/dashboard/event_requests/${this.props.eventRequestSubmission.id}${this.props.location.search}`,
    );
  };

  render() {
    const { eventRequestSubmission, tz } = this.props;

    return (
      <StyledTableRow selected={this.props.selected} onClick={this.handleRowClick}>
        {this.props.shownColumns.map(column => (
          <column.component
            width={column.width}
            key={column.value}
            grow={column.grow}
            rowSelected={this.props.selected}
          >
            <column.contentComponent
              eventRequestSubmission={eventRequestSubmission}
              viewerCanManageRequestForms={this.props.viewerCanManageRequestForms}
              tz={tz}
              rowSelected={this.props.selected}
              onColumnLoad={this.props.onColumnLoad}
              name={column.value}
            />
          </column.component>
        ))}
        {!this.props.selected && (
          <EventRequestsActionsColumn eventRequestSubmission={eventRequestSubmission} />
        )}
      </StyledTableRow>
    );
  }
}

export default createFragmentContainer(
  withRouter(EventRequestsTableRow),
  graphql`
    fragment EventRequestsTableRow_eventRequestSubmission on EventRequestSubmission {
      id
      ...EventRequestsItemEventName_eventRequestSubmission
      ...EventRequestsItemStatus_eventRequestSubmission
      ...EventRequestsItemFormName_eventRequestSubmission
      ...EventRequestsItemDeclineReason_eventRequestSubmission
      ...EventRequestsItemRequestedDate_eventRequestSubmission
      ...EventRequestsItemRequestedBy_eventRequestSubmission
      ...EventRequestsActionsColumn_eventRequestSubmission
      ...EventRequestsItemFormReviewers_eventRequestSubmission
    }
  `,
);
