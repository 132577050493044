import styled from 'styled-components';

import Remove from 'images/remove.svg';

export default styled(Remove)`
  position: absolute;
  top: 15px;
  right: 8px;
  display: block;
  width: 12px;
  height: 12px;
  font-size: 12px;
  cursor: pointer;
  color: ${props =>
    props.error ? props.theme.negativeActionColor : props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.primaryActionColor};
  }
`;
