/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';

import AddExpenseForm from '../../../views/Main/Event/Budget/BudgetPage/AddExpenseForm';

import type { ExpenseWindow_event } from './__generated__/ExpenseWindow_event.graphql';
import type { ExpenseWindow_org } from './__generated__/ExpenseWindow_org.graphql';

class ExpenseWindow extends React.PureComponent<
  {
    onHide: () => void,
    event: ExpenseWindow_event,
    org: ExpenseWindow_org,
    taskId: string,
  },
  {
    loading: boolean,
  },
> {
  render() {
    return (
      <Window onHide={this.props.onHide} size={430}>
        <WindowHeader>
          <WindowTitle>Add an Expense</WindowTitle>
          <WindowClose onClick={this.props.onHide} />
        </WindowHeader>
        <WindowContent>
          <AddExpenseForm
            org={this.props.org}
            event={this.props.event}
            popup
            onCloseButtonClick={this.props.onHide}
            taskId={this.props.taskId}
          />
        </WindowContent>
      </Window>
    );
  }
}

export default createFragmentContainer(ExpenseWindow, {
  org: graphql`
    fragment ExpenseWindow_org on Org {
      ...AddExpenseForm_org
    }
  `,
  event: graphql`
    fragment ExpenseWindow_event on Event {
      id
      ...AddExpenseForm_event
    }
  `,
});
