/**
 * @flow
 * @relayHash bda7f29fa6d7748ca203b93f379b8a47
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveContactFormFieldInput = {
  clientMutationId?: ?string,
  contactFormId: string,
  contactFormFieldIds?: ?$ReadOnlyArray<string>,
  enableCompanyFields?: ?boolean,
};
export type removeContactFormFieldMutationVariables = {|
  input: RemoveContactFormFieldInput
|};
export type removeContactFormFieldMutationResponse = {|
  +removeContactFormField: ?{|
    +contactForm: {|
      +id: string,
      +enableCompanyFields: boolean,
    |},
    +registrationForm: ?{|
      +id: string,
      +hasChanges: boolean,
    |},
    +removedContactFormFieldIds: ?$ReadOnlyArray<string>,
    +removedTextFilterConnections: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedLinkFilterConnections: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedNumberFilterConnections: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedCurrencyFilterConnections: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedBooleanFilterConnections: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedMultiselectFilterConnections: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedDateFilterConnections: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedUserMultiselectFilterConnections: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedRuleConnections: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
  |}
|};
export type removeContactFormFieldMutation = {|
  variables: removeContactFormFieldMutationVariables,
  response: removeContactFormFieldMutationResponse,
|};
*/


/*
mutation removeContactFormFieldMutation(
  $input: RemoveContactFormFieldInput!
) {
  removeContactFormField(input: $input) {
    contactForm {
      id
      enableCompanyFields
    }
    registrationForm {
      id
      hasChanges
    }
    removedContactFormFieldIds
    removedTextFilterConnections {
      parentId
      childId
    }
    removedLinkFilterConnections {
      parentId
      childId
    }
    removedNumberFilterConnections {
      parentId
      childId
    }
    removedCurrencyFilterConnections {
      parentId
      childId
    }
    removedBooleanFilterConnections {
      parentId
      childId
    }
    removedMultiselectFilterConnections {
      parentId
      childId
    }
    removedDateFilterConnections {
      parentId
      childId
    }
    removedUserMultiselectFilterConnections {
      parentId
      childId
    }
    removedRuleConnections {
      parentId
      childId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveContactFormFieldInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "parentId",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "childId",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeContactFormField",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveContactFormFieldInput!"
      }
    ],
    "concreteType": "RemoveContactFormFieldPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contactForm",
        "storageKey": null,
        "args": null,
        "concreteType": "ContactForm",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "enableCompanyFields",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "registrationForm",
        "storageKey": null,
        "args": null,
        "concreteType": "RegistrationForm",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasChanges",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedContactFormFieldIds",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removedTextFilterConnections",
        "storageKey": null,
        "args": null,
        "concreteType": "RemovedConnection",
        "plural": true,
        "selections": v2
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removedLinkFilterConnections",
        "storageKey": null,
        "args": null,
        "concreteType": "RemovedConnection",
        "plural": true,
        "selections": v2
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removedNumberFilterConnections",
        "storageKey": null,
        "args": null,
        "concreteType": "RemovedConnection",
        "plural": true,
        "selections": v2
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removedCurrencyFilterConnections",
        "storageKey": null,
        "args": null,
        "concreteType": "RemovedConnection",
        "plural": true,
        "selections": v2
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removedBooleanFilterConnections",
        "storageKey": null,
        "args": null,
        "concreteType": "RemovedConnection",
        "plural": true,
        "selections": v2
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removedMultiselectFilterConnections",
        "storageKey": null,
        "args": null,
        "concreteType": "RemovedConnection",
        "plural": true,
        "selections": v2
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removedDateFilterConnections",
        "storageKey": null,
        "args": null,
        "concreteType": "RemovedConnection",
        "plural": true,
        "selections": v2
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removedUserMultiselectFilterConnections",
        "storageKey": null,
        "args": null,
        "concreteType": "RemovedConnection",
        "plural": true,
        "selections": v2
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removedRuleConnections",
        "storageKey": null,
        "args": null,
        "concreteType": "RemovedConnection",
        "plural": true,
        "selections": v2
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeContactFormFieldMutation",
  "id": null,
  "text": "mutation removeContactFormFieldMutation(\n  $input: RemoveContactFormFieldInput!\n) {\n  removeContactFormField(input: $input) {\n    contactForm {\n      id\n      enableCompanyFields\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n    removedContactFormFieldIds\n    removedTextFilterConnections {\n      parentId\n      childId\n    }\n    removedLinkFilterConnections {\n      parentId\n      childId\n    }\n    removedNumberFilterConnections {\n      parentId\n      childId\n    }\n    removedCurrencyFilterConnections {\n      parentId\n      childId\n    }\n    removedBooleanFilterConnections {\n      parentId\n      childId\n    }\n    removedMultiselectFilterConnections {\n      parentId\n      childId\n    }\n    removedDateFilterConnections {\n      parentId\n      childId\n    }\n    removedUserMultiselectFilterConnections {\n      parentId\n      childId\n    }\n    removedRuleConnections {\n      parentId\n      childId\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeContactFormFieldMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v3
  },
  "operation": {
    "kind": "Operation",
    "name": "removeContactFormFieldMutation",
    "argumentDefinitions": v0,
    "selections": v3
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b7bade1cefa41564975b3cc9c6995ac1';
module.exports = node;
