/* @flow */

import React from 'react';
import { graphql } from 'react-relay';
import { type Match } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';

import BriefContainer from './BriefContainer';

import type { BriefQueryResponse } from './__generated__/BriefQuery.graphql';

const BriefPage = styled.div`
  display: flex;
  flex-direction: column;
`;

const query = graphql`
  query BriefQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...BriefContainer_event
      viewerCanUpdate
    }
    org {
      ...BriefContainer_org
    }
    me {
      ...BriefContainer_me
    }
  }
`;

export default class Brief extends React.Component<{
  match: Match,
}> {
  render() {
    return (
      <BriefPage>
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug: this.props.match.params.eventSlug }}
          renderSuccess={(props: BriefQueryResponse) => {
            return props.event ? (
              <BriefContainer event={props.event} org={props.org} me={props.me} />
            ) : (
              <NotAuthorized />
            );
          }}
        />
      </BriefPage>
    );
  }
}
