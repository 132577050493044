/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ImportLogsActionsColumn_importLog$ref = any;
type ImportLogsDateColumn_importLog$ref = any;
type ImportLogsErrorCountColumn_importLog$ref = any;
type ImportLogsFilenameColumn_importLog$ref = any;
type ImportLogsSuccessCountColumn_importLog$ref = any;
type ImportLogsUserColumn_importLog$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ImportLogsTableRow_importLog$ref: FragmentReference;
export type ImportLogsTableRow_importLog = {|
  +id: string,
  +$fragmentRefs: ImportLogsFilenameColumn_importLog$ref & ImportLogsDateColumn_importLog$ref & ImportLogsUserColumn_importLog$ref & ImportLogsSuccessCountColumn_importLog$ref & ImportLogsErrorCountColumn_importLog$ref & ImportLogsActionsColumn_importLog$ref,
  +$refType: ImportLogsTableRow_importLog$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ImportLogsTableRow_importLog",
  "type": "ImportLog",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ImportLogsFilenameColumn_importLog",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ImportLogsDateColumn_importLog",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ImportLogsUserColumn_importLog",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ImportLogsSuccessCountColumn_importLog",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ImportLogsErrorCountColumn_importLog",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ImportLogsActionsColumn_importLog",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ae11b050d24acccd97fef2e761c3a1a2';
module.exports = node;
