/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import type {
  connectSalesforceAccountMutationVariables,
  connectSalesforceAccountMutationResponse,
} from './__generated__/connectSalesforceAccountMutation.graphql';

const mutation = graphql`
  mutation connectSalesforceAccountMutation($input: ConnectSalesforceAccountInput!) {
    connectSalesforceAccount(input: $input) {
      updatedOrg {
        salesforceAccount {
          id
          username
        }
        ...SalesforceAuth_org
      }
    }
  }
`;

export default function connectSalesforceAccount(args: {
  code: string,
  host: string,
  sandbox: boolean,
}): Promise<connectSalesforceAccountMutationResponse> {
  const variables: connectSalesforceAccountMutationVariables = {
    input: {
      ...args,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
