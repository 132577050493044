/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventInfoCustomCurrency_event$ref: FragmentReference;
export type EventInfoCustomCurrency_event = {|
  +customCurrencyFields: $ReadOnlyArray<{|
    +value: number,
    +customField: {|
      +id: string
    |},
  |}>,
  +$refType: EventInfoCustomCurrency_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventInfoCustomCurrency_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "customFieldIds",
      "type": "[ID!]"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customCurrencyFields",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "customFieldIds",
          "variableName": "customFieldIds",
          "type": "[ID!]"
        }
      ],
      "concreteType": "CustomCurrencyFieldType",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customField",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0d2a00ce1ca32ffcedad67f4a195261e';
module.exports = node;
