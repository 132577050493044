/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type History, type Location } from 'react-router';
import styled from 'styled-components';

import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
} from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import Button from 'components/budget/Button';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import AddExpenseForm from '../../../Event/Budget/BudgetPage/AddExpenseForm';
import VendorExpensesTableRenderer from './VendorExpensesTableRenderer';

import type { VendorExpenses_org } from './__generated__/VendorExpenses_org.graphql';
import type { VendorExpenses_vendor } from './__generated__/VendorExpenses_vendor.graphql';
import { type VendorExpensesEventQueryResponse } from './__generated__/VendorExpensesEventQuery.graphql';
import { type VendorExpensesQueryResponse } from './__generated__/VendorExpensesQuery.graphql';

const query = graphql`
  query VendorExpensesQuery($vendorId: ID!, $sort: ExpenseSort, $direction: Direction!) {
    vendor: node(id: $vendorId) {
      ... on Vendor {
        expenses(first: 1000, sort: $sort, direction: $direction)
          @connection(key: "VendorExpenses_expenses", filters: []) {
          totalCount
          edges {
            node {
              ...VendorExpensesTableRenderer_expenses
            }
          }
        }
      }
    }
  }
`;

const eventQuery = graphql`
  query VendorExpensesEventQuery($eventId: ID!) {
    event: node(id: $eventId) {
      ...AddExpenseForm_event
    }
  }
`;

const Container = styled.div`
  margin: 25px 0;
`;

const StyledButton = styled(Button)`
  margin-bottom: 20px;
`;

class VendorExpenses extends React.Component<
  {
    vendor: VendorExpenses_vendor,
    org: VendorExpenses_org,
    location: Location,
    history: History,
  },
  {
    showExpenseForm: boolean,
    eventId: ?string,
    event: ?$PropertyType<VendorExpensesEventQueryResponse, 'event'>,
  },
> {
  state = {
    showExpenseForm: false,
    eventId: null,
    event: null,
  };

  static defaultProps = {
    sort: { key: 'NAME', asc: true },
  };

  handleExpenseFormShow = () => {
    this.setState({ showExpenseForm: true });
  };

  handleExpenseFormClose = () => {
    this.setState({
      showExpenseForm: false,
      eventId: '',
      event: null,
    });
  };

  handleChangeSort = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort, 'expenseSort');
  };

  handleSelectEvent = (eventId: ?string) => {
    this.setState(previousState => ({
      eventId,
      event: eventId ? previousState.event : null,
    }));
  };

  renderTable = (response?: VendorExpensesQueryResponse) => {
    const {
      org,
      location,
      vendor: { id },
    } = this.props;

    const { vendor } = response || {};

    const expenses =
      vendor && vendor.expenses ? vendor.expenses.edges.map(edge => edge.node) : null;

    const filters = parseTypedQueryString(location.search, {
      expenseSort: stringParamToSort,
    });

    const sort = filters.expenseSort || {
      key: 'NAME',
      asc: true,
    };
    return (
      <VendorExpensesTableRenderer
        sort={sort}
        onChangeSort={this.handleChangeSort}
        org={org || null}
        vendorId={id}
        expenses={expenses}
        location={location}
      />
    );
  };

  queryAddExpenseFormEvent = (response?: VendorExpensesEventQueryResponse) => {
    const { event } = response || {};

    this.setState({ event, eventId: null });

    return null;
  };

  render() {
    const { vendor, location } = this.props;

    const filters = parseTypedQueryString(location.search, {
      expenseSort: stringParamToSort,
    });

    const sort = filters.expenseSort || {
      key: 'NAME',
      asc: true,
    };

    return (
      <Container>
        <StyledButton onClick={this.handleExpenseFormShow}>Add Expense</StyledButton>
        {this.state.showExpenseForm && (
          <>
            {this.state.eventId && (
              <DefaultQueryRenderer
                query={eventQuery}
                variables={{ eventId: this.state.eventId || '' }}
                renderSuccess={this.queryAddExpenseFormEvent}
                renderLoading={() => null}
              />
            )}
            <AddExpenseForm
              org={this.props.org}
              vendorId={this.props.vendor.id}
              event={this.state.event || null}
              onEventSelect={this.handleSelectEvent}
              onCloseButtonClick={this.handleExpenseFormClose}
            />
          </>
        )}
        <DefaultQueryRenderer
          query={query}
          variables={{
            vendorId: vendor.id,
            sort: sort.key,
            direction: sort.asc ? 'ASC' : 'DESC',
          }}
          renderSuccess={this.renderTable}
          renderLoading={this.renderTable}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(VendorExpenses, {
  vendor: graphql`
    fragment VendorExpenses_vendor on Vendor {
      id
    }
  `,
  org: graphql`
    fragment VendorExpenses_org on Org {
      ...AddExpenseForm_org
      ...VendorExpensesTableRenderer_org
    }
  `,
});
