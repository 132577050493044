/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { type Location, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import TabNavigation from 'components/TabNavigation';

import EventCompaniesPage from './companies/EventCompaniesPage';
import ContactsPage from './ContactsPage';
import OpportunitiesPage from './opportunities/OpportunitiesPage';

import type { ContactsQueryResponse } from './__generated__/ContactsQuery.graphql';

const query = graphql`
  query ContactsQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...ContactsPage_event
      ...EventCompaniesPage_event
      ...OpportunitiesPage_event
      eventContacts {
        totalCount
      }
      companies {
        totalCount
      }
      contactRegistrationsTotal
      opportunities {
        totalCount
      }
    }
    org {
      salesforceAccount {
        contactsSyncEnabled
        opportunitiesSyncEnabled
      }
      marketoAccount {
        id
      }
      ...ContactsPage_org
      ...EventCompaniesPage_org
      ...OpportunitiesPage_org
    }
    me {
      email
      tz
    }
  }
`;

const Content = styled.div`
  padding: 15px 28px 0 28px;
`;

export default class ContactsRoot extends React.Component<{
  eventSlug: string,
  location: Location,
}> {
  render() {
    const variables = {
      eventSlug: this.props.eventSlug,
    };
    return (
      <Content>
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={({ org, me, event }: ContactsQueryResponse) => {
            const salesForceEnabled = org.salesforceAccount
              ? org.salesforceAccount.contactsSyncEnabled
              : false;
            const opportunitiesSyncEnabled = org.salesforceAccount
              ? org.salesforceAccount.opportunitiesSyncEnabled
              : false;
            const marketoEnabled = !!org.marketoAccount;
            const navOptions = [
              {
                to: `/events/${this.props.eventSlug}/contacts/people`,
                count: event != null ? event.eventContacts.totalCount : 0,
                label: 'Total Contact',
                labelPlural: 'Total Contacts',
              },
              {
                to: `/events/${this.props.eventSlug}/contacts/companies`,
                count: event != null ? event.companies.totalCount : 0,
                label: 'Total Company',
                labelPlural: 'Total Companies',
              },
              ...(opportunitiesSyncEnabled
                ? [
                    {
                      to: `/events/${this.props.eventSlug}/contacts/opportunities`,
                      count: event != null ? event.opportunities.totalCount : 0,
                      label: 'Opportunity',
                      labelPlural: 'Opportunities',
                    },
                  ]
                : []),
            ];
            return (
              <React.Fragment>
                <TabNavigation options={navOptions} />
                <Switch>
                  <Route
                    path={`/events/${this.props.eventSlug}/contacts/people`}
                    render={renderProps => (
                      <ContactsPage
                        {...renderProps}
                        event={event}
                        org={org}
                        tz={me.tz}
                        key="people_contacts"
                        userEmail={me.email}
                        salesforceEnabled={salesForceEnabled}
                        marketoEnabled={marketoEnabled}
                      />
                    )}
                  />
                  <Route
                    path={`/events/${this.props.eventSlug}/contacts/companies`}
                    render={renderProps => (
                      <EventCompaniesPage
                        {...renderProps}
                        org={org}
                        event={event}
                        tz={me.tz}
                        salesforceEnabled={salesForceEnabled}
                      />
                    )}
                  />
                  {opportunitiesSyncEnabled && (
                    <Route
                      path={`/events/${this.props.eventSlug}/contacts/opportunities`}
                      render={renderProps => (
                        <OpportunitiesPage {...renderProps} org={org} event={event} tz={me.tz} />
                      )}
                    />
                  )}
                  <Redirect to={`/events/${this.props.eventSlug}/contacts/people`} />
                </Switch>
              </React.Fragment>
            );
          }}
        />
      </Content>
    );
  }
}
