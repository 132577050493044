/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventRequestsActionsColumn_eventRequestSubmission$ref = any;
type EventRequestsItemDeclineReason_eventRequestSubmission$ref = any;
type EventRequestsItemEventName_eventRequestSubmission$ref = any;
type EventRequestsItemFormName_eventRequestSubmission$ref = any;
type EventRequestsItemFormReviewers_eventRequestSubmission$ref = any;
type EventRequestsItemRequestedBy_eventRequestSubmission$ref = any;
type EventRequestsItemRequestedDate_eventRequestSubmission$ref = any;
type EventRequestsItemStatus_eventRequestSubmission$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestsTableRow_eventRequestSubmission$ref: FragmentReference;
export type EventRequestsTableRow_eventRequestSubmission = {|
  +id: string,
  +$fragmentRefs: EventRequestsItemEventName_eventRequestSubmission$ref & EventRequestsItemStatus_eventRequestSubmission$ref & EventRequestsItemFormName_eventRequestSubmission$ref & EventRequestsItemDeclineReason_eventRequestSubmission$ref & EventRequestsItemRequestedDate_eventRequestSubmission$ref & EventRequestsItemRequestedBy_eventRequestSubmission$ref & EventRequestsActionsColumn_eventRequestSubmission$ref & EventRequestsItemFormReviewers_eventRequestSubmission$ref,
  +$refType: EventRequestsTableRow_eventRequestSubmission$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestsTableRow_eventRequestSubmission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestsItemEventName_eventRequestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestsItemStatus_eventRequestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestsItemFormName_eventRequestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestsItemDeclineReason_eventRequestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestsItemRequestedDate_eventRequestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestsItemRequestedBy_eventRequestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestsActionsColumn_eventRequestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestsItemFormReviewers_eventRequestSubmission",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3d28935d6c8fcd6d9080811a6bd83b5e';
module.exports = node;
