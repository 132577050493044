/* @flow */
import React from 'react';
import { DragDropContext as dragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import MainContainer from './MainContainer';
import MainDragLayer from './MainDragLayer';

import type { MainQueryResponse } from './__generated__/MainQuery.graphql';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const query = graphql`
  query MainQuery {
    me {
      ...MainContainer_me
    }
    org {
      ...MainContainer_org
    }
  }
`;

class Main extends React.Component<{
  history: RouterHistory,
  location: Location,
}> {
  render() {
    return (
      <Container>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(props: MainQueryResponse) => (
            <MainContainer
              me={props.me}
              org={props.org}
              history={this.props.history}
              location={this.props.location}
            />
          )}
          renderLoading={() => null}
        />
        <MainDragLayer />
      </Container>
    );
  }
}

export default dragDropContext(HTML5Backend)(Main);
