/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import Window, { WindowHeader, WindowTitle } from 'components/material/Window';
import TransferEventForm from './TransferEventForm';

import { type TransferTeamSection_me } from '../../__generated__/TransferTeamSection_me.graphql';
import { type TransferTeamSection_event } from '../../__generated__/TransferTeamSection_event.graphql';

const StyledWindow = styled(Window)`
  min-height: 100px;
  max-width: 460px;
`;

export default class TransferEventModal extends React.Component<{
  onHide: () => void,
  event: TransferTeamSection_event,
  teams: $PropertyType<TransferTeamSection_me, 'teams'>,
}> {
  render() {
    return (
      <StyledWindow onHide={this.props.onHide}>
        <WindowHeader>
          <WindowTitle>Transfer Event To Team</WindowTitle>
        </WindowHeader>
        <TransferEventForm teams={this.props.teams} event={this.props.event} />
      </StyledWindow>
    );
  }
}
