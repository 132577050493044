/**
 * @flow
 * @relayHash abdb27fe28186bb07bea4c500c2242c5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReorderDeliverablesInput = {
  clientMutationId?: ?string,
  deliverableIds: $ReadOnlyArray<?string>,
  newPosition: number,
  folderId?: ?string,
};
export type reorderTasksMutationVariables = {|
  input: ReorderDeliverablesInput
|};
export type reorderTasksMutationResponse = {|
  +reorderDeliverables: ?{|
    +updatedDeliverables: $ReadOnlyArray<?{|
      +id: string,
      +order: number,
      +folderId: ?string,
    |}>
  |}
|};
export type reorderTasksMutation = {|
  variables: reorderTasksMutationVariables,
  response: reorderTasksMutationResponse,
|};
*/


/*
mutation reorderTasksMutation(
  $input: ReorderDeliverablesInput!
) {
  reorderDeliverables(input: $input) {
    updatedDeliverables {
      id
      order
      folderId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReorderDeliverablesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reorderDeliverables",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ReorderDeliverablesInput!"
      }
    ],
    "concreteType": "ReorderDeliverablesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedDeliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "Deliverable",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "order",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "folderId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "reorderTasksMutation",
  "id": null,
  "text": "mutation reorderTasksMutation(\n  $input: ReorderDeliverablesInput!\n) {\n  reorderDeliverables(input: $input) {\n    updatedDeliverables {\n      id\n      order\n      folderId\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "reorderTasksMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "reorderTasksMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '42b31633d32e8a8819534465561a9be3';
module.exports = node;
