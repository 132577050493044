/* @flow */

export type TimeZone = {
  label: string,
  name: string,
  offset: number,
};

export default function stringifyTimeZone(zone: TimeZone) {
  const ensure2Digits = num => (num > 9 ? `${num}` : `0${num}`);

  return `(GMT${zone.offset < 0 ? '-' : '+'}${ensure2Digits(
    Math.floor(Math.abs(zone.offset)),
  )}:${ensure2Digits(Math.abs((zone.offset % 1) * 60))}) ${zone.label}`;
}
