/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type UserField_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventInfoCustomUserMultiselect_event$ref: FragmentReference;
export type EventInfoCustomUserMultiselect_event = {|
  +customUserMultiselectFields: $ReadOnlyArray<{|
    +user: {|
      +id: string,
      +firstName: string,
      +lastName: string,
      +email: string,
      +phone: ?string,
      +officePhone: ?string,
      +title: ?string,
      +company: ?string,
    |},
    +customField: {|
      +id: string
    |},
  |}>,
  +$fragmentRefs: UserField_event$ref,
  +$refType: EventInfoCustomUserMultiselect_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventInfoCustomUserMultiselect_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "customFieldIds",
      "type": "[ID!]"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "UserField_event",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customUserMultiselectFields",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "customFieldIds",
          "variableName": "customFieldIds",
          "type": "[ID!]"
        }
      ],
      "concreteType": "CustomUserMultiselectFieldType",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "phone",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "officePhone",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "company",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customField",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "plural": false,
          "selections": [
            v0
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f0fdc963b579da95dc7e3f8e1efc5b1';
module.exports = node;
