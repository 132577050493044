/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefDownloadOptionSelectOverlay_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefHeaderActionsBar_event$ref: FragmentReference;
export type BriefHeaderActionsBar_event = {|
  +slug: string,
  +viewerCanUpdate: boolean,
  +$fragmentRefs: BriefDownloadOptionSelectOverlay_event$ref,
  +$refType: BriefHeaderActionsBar_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BriefHeaderActionsBar_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "BriefDownloadOptionSelectOverlay_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b13fd62c842150a7b3ebe143327394aa';
module.exports = node;
