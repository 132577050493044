/* @flow */
import React from 'react';
import { type RelayProp, createRefetchContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';

import showModernMutationError from 'graph/utils/showModernMutationError';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Radio from 'components/material/Radio';
import SelectField from 'components/material/SelectField';

import IntegrationMessage from '../IntegrationsSettings/IntegrationMessage';
import ZoomLocationCard from './ZoomLocationCard';
import ZoomLocationForm, { type ZoomLocationInputType } from './ZoomLocationForm';

import { type ZoomLocationPicker_integrable } from './__generated__/ZoomLocationPicker_integrable.graphql';
import { type ZoomLocationPickerQueryResponse } from './__generated__/ZoomLocationPickerQuery.graphql';

type ZoomLocationConnectionType = $NonMaybeType<
  $PropertyType<
    $NonMaybeType<$PropertyType<ZoomLocationPickerQueryResponse, 'integrable'>>,
    'zoomLocations',
  >,
>;
type ZoomLocationResponseType = $PropertyType<
  $ElementType<$PropertyType<ZoomLocationConnectionType, 'edges'>, 0>,
  'node',
>;
export type ZoomLocationType = $ReadOnly<$Diff<ZoomLocationResponseType, { event: any }>>;

export type ZoomLocationKindType = $PropertyType<ZoomLocationType, 'kind'>;

const RadiosRow = styled.div`
  display: flex;
  margin: 20px 0;
`;

const RadioWrapper = styled.div`
  font-weight: 600;
  color: ${props => (props.checked ? '#4b4b4b' : 'rgba(75, 75, 75, 0.48)')};

  &:not(:last-child) {
    margin-right: 42px;
  }
`;

const AddNewLabel = styled.span`
  font-size: 13px;
  color: #4eb1dd;
`;

const ZoomLocationOption = styled.span`
  a {
    color: #4eb1dd;
  }
  small {
    display: block;
    margin-top: -2px;
    font-size: 11px;
  }
`;

const query = graphql`
  query ZoomLocationPickerQuery(
    $integrableId: ID!
    $kind: ZoomLocationKind!
    $zoomUserId: ID!
    $list: ZoomLocationList!
  ) {
    integrable: node(id: $integrableId) {
      ... on IntegrableInterface {
        zoomLocations(kind: $kind, zoomUserId: $zoomUserId, list: $list) {
          edges {
            node {
              id
              kind
              zoomId
              zoomUser {
                id
                zoomId
                firstName
                lastName
                email
                freePlan
              }
              joinUrl
              zoomUrl
              topic
              startTime
              duration
              timezone
              event {
                id
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const ZOOM_LOCATION_KINDS = {
  MEETING: 'Meeting',
  WEBINAR: 'Webinar',
};

class ZoomLocationPicker extends React.PureComponent<
  {
    relay: RelayProp,
    integrable: ZoomLocationPicker_integrable,
    zoomLocation: ?ZoomLocationType,
    isUpcomingEvent: boolean,
    defaultTopic: string,
    defaultTimezone: string,
    defaultDate: ?string,
    onPick: ZoomLocationType => Promise<?{ +zoomLocation: ?{ +zoomId: string } }>,
    onShowCreateForm?: () => void,
    onHideCreateForm?: () => void,
    eventId?: string,
  },
  {
    kind: ZoomLocationKindType,
    fetching: boolean,
    creating: boolean,
    zoomUserId: ?string,
    zoomId: ?string,
  },
> {
  state = {
    kind: this.props.zoomLocation ? this.props.zoomLocation.kind : 'MEETING',
    fetching: false,
    creating: false,
    zoomUserId:
      (this.props.zoomLocation &&
        this.props.zoomLocation.zoomUser &&
        this.props.zoomLocation.zoomUser.id) ||
      null,
    zoomId: this.props.zoomLocation ? this.props.zoomLocation.zoomId : null,
  };

  componentDidMount() {
    window.addEventListener('focus', this.handleFocusWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.handleFocusWindow);
  }

  handleFocusWindow = () => {
    const connected = this.props.integrable.zoomSolutionInstances.totalCount > 0;

    if (connected || this.state.fetching || !this.props.integrable.viewerCanConfigureIntegrations) {
      return;
    }

    this.refetch();
  };

  refetch = () => {
    this.setState({ fetching: true });

    this.props.relay.refetch({ integrableId: this.props.integrable.id }, null, error => {
      if (error) {
        showModernMutationError(error);
      }

      this.setState({ fetching: false });
    });
  };

  handleChangeKind = (kind: ZoomLocationKindType) => {
    this.setState({ kind });
  };

  handleCancelCreating = () => {
    this.setState({
      creating: false,
      fetching: false,
      zoomUserId:
        (this.props.zoomLocation &&
          this.props.zoomLocation.zoomUser &&
          this.props.zoomLocation.zoomUser.id) ||
        null,
    });

    if (this.props.onHideCreateForm) {
      this.props.onHideCreateForm();
    }
  };

  handleChangeLocation = (zoomLocation: ?ZoomLocationResponseType) => {
    if (zoomLocation) {
      const { event, ...pickLocation } = { ...zoomLocation };
      this.props.onPick(pickLocation).then(response => {
        if (response) {
          this.setState({ zoomId: response.zoomLocation && response.zoomLocation.zoomId });
        }
      });
    } else {
      this.setState({ creating: true });

      if (this.props.onShowCreateForm) {
        this.props.onShowCreateForm();
      }
    }
  };

  handleCreate = (locationInput: ZoomLocationInputType) => {
    const zoomUser = this.props.integrable.zoomUsers.edges
      .map(edge => edge.node)
      .find(user => user.id === this.state.zoomUserId);

    if (!zoomUser) return Promise.resolve();

    return this.props
      .onPick({
        id: '',
        zoomId: '',
        joinUrl: '',
        zoomUrl: '',
        kind: this.state.kind,
        zoomUser,
        ...locationInput,
      })
      .then(response => {
        if (response) {
          this.handleCancelCreating();
          this.setState({
            zoomId: (response.zoomLocation && response.zoomLocation.zoomId) || null,
          });
        }
      });
  };

  handleChangeZoomUserId = (zoomUserId: ?string) => {
    this.setState({ zoomUserId });
  };

  renderLocationsSelect = (response?: ZoomLocationPickerQueryResponse) => {
    const { zoomLocation, isUpcomingEvent } = this.props;
    const { kind, zoomId } = this.state;

    const zoomLocations =
      response && response.integrable && response.integrable.zoomLocations
        ? orderBy(
            response.integrable.zoomLocations.edges.map(edge => edge.node),
            [location => location.startTime],
            [isUpcomingEvent ? 'asc' : 'desc'],
          )
        : [];

    const options = response
      ? [
          {
            value: '',
            label: `Create New Zoom ${ZOOM_LOCATION_KINDS[kind]}`,
            displayLabel: <AddNewLabel>Create New Zoom {ZOOM_LOCATION_KINDS[kind]}</AddNewLabel>,
          },
          ...zoomLocations.map(location => {
            const event = location.event;
            const disabled = !!event && event.id !== this.props.eventId;

            return {
              value: location.id,
              label: location.topic,
              displayLabel: (
                <ZoomLocationOption>
                  {location.topic}
                  {disabled && event && (
                    <small>
                      Associated with: <Link to={`/events/${event.slug}`}>{event.name}</Link>
                    </small>
                  )}
                </ZoomLocationOption>
              ),
              disabled,
            };
          }),
        ]
      : [];

    return (
      <SelectField
        label={`Select Zoom ${ZOOM_LOCATION_KINDS[kind]}`}
        value={null}
        options={options}
        onChange={locationId =>
          this.handleChangeLocation(zoomLocations.find(location => location.id === locationId))
        }
        loading={!response || zoomId !== ((zoomLocation && zoomLocation.zoomId) || null)}
        searchable
      />
    );
  };

  renderLocationsSelectError = error => {
    const webinarPlanMissing = error.message.includes('Webinar plan is missing');

    if (webinarPlanMissing) {
      return (
        <IntegrationMessage>
          This Zoom account does not have Webinars enabled. Please add the Webinar subscription to
          this account to proceed.
        </IntegrationMessage>
      );
    }

    return this.renderConnectMessage();
  };

  renderConnectMessage = () => {
    if (this.props.integrable.viewerCanConfigureIntegrations) {
      return (
        <IntegrationMessage>
          Connect your Zoom Admin Account.{' '}
          <Link to="/settings/integrations/zoom" target="_blank">
            View settings
          </Link>
        </IntegrationMessage>
      );
    }

    return (
      <IntegrationMessage>
        Please ask you Admin to configure Zoom integration in Workspace Settings.
      </IntegrationMessage>
    );
  };

  render() {
    const {
      integrable,
      zoomLocation,
      defaultTopic,
      defaultTimezone,
      defaultDate,
      isUpcomingEvent,
    } = this.props;
    const { kind, creating, zoomUserId, zoomId } = this.state;

    if (creating) {
      return (
        <ZoomLocationForm
          kind={kind}
          defaultTopic={defaultTopic}
          defaultTimezone={defaultTimezone}
          defaultDate={defaultDate}
          onCancel={this.handleCancelCreating}
          onCreate={this.handleCreate}
        />
      );
    }

    const connectMessage =
      this.props.integrable.zoomSolutionInstances.totalCount === 0
        ? this.renderConnectMessage()
        : null;

    const zoomUsers = integrable.zoomUsers.edges.map(edge => edge.node);
    const zoomUser = zoomUsers.find(user => user.id === zoomUserId);
    const userOptions = orderBy(
      zoomUsers.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName} (${user.email})`,
      })),
      [option => option.label.toLowerCase()],
    );

    return (
      <>
        {connectMessage || (
          <>
            <SelectField
              label="Select User"
              value={zoomUserId}
              options={userOptions}
              onChange={this.handleChangeZoomUserId}
              searchable
            />

            {zoomUser && (
              <>
                {zoomUser.freePlan && (
                  <IntegrationMessage variant="warning">
                    This user is on a free Zoom plan.
                    <br />
                    Registrants cannot be synced to Zoom with the free plan.
                  </IntegrationMessage>
                )}

                <RadiosRow>
                  {Object.keys(ZOOM_LOCATION_KINDS).map(key => (
                    <RadioWrapper key={key} checked={kind === key}>
                      <Radio
                        label={ZOOM_LOCATION_KINDS[key]}
                        value={key}
                        checked={kind === key}
                        onChange={this.handleChangeKind}
                        radioColor={kind === key ? '#29cc71' : '#cdd1d4'}
                      />
                    </RadioWrapper>
                  ))}
                </RadiosRow>

                <DefaultQueryRenderer
                  key={zoomId}
                  query={query}
                  variables={{
                    integrableId: integrable.id,
                    kind,
                    zoomUserId,
                    list: isUpcomingEvent ? 'UPCOMING' : 'PAST',
                  }}
                  renderSuccess={this.renderLocationsSelect}
                  renderLoading={this.renderLocationsSelect}
                  renderError={this.renderLocationsSelectError}
                />
              </>
            )}
          </>
        )}

        {zoomLocation && <ZoomLocationCard zoomLocation={zoomLocation} />}
      </>
    );
  }
}

export default createRefetchContainer(
  ZoomLocationPicker,
  graphql`
    fragment ZoomLocationPicker_integrable on IntegrableInterface {
      id
      viewerCanConfigureIntegrations

      zoomSolutionInstances: traySolutionInstances(solutionName: ZOOM) {
        totalCount
      }

      zoomUsers {
        edges {
          node {
            id
            zoomId
            firstName
            lastName
            email
            freePlan
          }
        }
      }
    }
  `,
  graphql`
    query ZoomLocationPickerRefetchQuery($integrableId: ID!) {
      node(id: $integrableId) {
        ...ZoomLocationPicker_integrable
      }
    }
  `,
);
