/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import RequestSubmissionFormReviewers from 'components/EventRequestForm/RequestSubmissionList/columns/RequestSubmissionFormReviewers';

import type { EventRequestsItemFormReviewers_eventRequestSubmission } from './__generated__/EventRequestsItemFormReviewers_eventRequestSubmission.graphql';

const Container = styled.div`
  padding: 6px 0;
`;

type Props = {
  eventRequestSubmission: EventRequestsItemFormReviewers_eventRequestSubmission,
  className?: boolean,
};

const EventRequestsItemFormReviewers = ({ eventRequestSubmission, className }: Props) => {
  return (
    <Container className={className}>
      <RequestSubmissionFormReviewers requestSubmission={eventRequestSubmission} />
    </Container>
  );
};

export default createFragmentContainer(
  EventRequestsItemFormReviewers,
  graphql`
    fragment EventRequestsItemFormReviewers_eventRequestSubmission on EventRequestSubmission {
      ...RequestSubmissionFormReviewers_requestSubmission
    }
  `,
);
