/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Location, type Match, type RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';
import NotFound from 'components/NotFound';
import { Container } from 'components/page/Container';

import TeamPage from './TeamPage';

import type { TeamQueryResponse } from './__generated__/TeamQuery.graphql';

type Props = { location: Location, match: Match, history: RouterHistory };

const query = graphql`
  query TeamQuery($teamSlug: String!) {
    team(slug: $teamSlug) {
      id
      name
      viewerCanUpdate
      ...TeamPage_team
    }
  }
`;

const Team = (props: Props) => {
  const teamSlug: ?string = props.match.params.teamSlug;
  if (!teamSlug) {
    return <NotFound />;
  }
  return (
    <Container>
      <DefaultQueryRenderer
        query={query}
        variables={{ teamSlug }}
        renderSuccess={(reaponse: TeamQueryResponse) =>
          reaponse.team.viewerCanUpdate ? (
            <TeamPage
              team={reaponse.team}
              history={props.history}
              location={props.location}
              pathPrefix={props.match.path}
              rootUrl={props.match.url}
            />
          ) : (
            <NotAuthorized />
          )
        }
      />
    </Container>
  );
};

export default Team;
