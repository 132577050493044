/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import TextInput from 'components/material/TextInput';

import inputBoxStyled from './components/inputBoxStyled';
import Label, { Error, Header } from './FieldComponents';

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
  ${props =>
    props.multiline &&
    css`
      padding-top: 5px !important; /* Need to override inputBoxStyled object*/
    `};
`;

export default class TextField extends React.Component<{
  label: string,
  onBlur?: (SyntheticFocusEvent<HTMLInputElement>) => void,
  disabled?: boolean,
  readOnly?: boolean,
  defaultValue?: ?string,
  required?: boolean,
  multiline?: boolean,
  error?: string,
  autoFocus?: boolean,
  className?: string,
}> {
  render() {
    const {
      error,
      required,
      onBlur,
      multiline,
      defaultValue,
      disabled,
      readOnly,
      label,
      autoFocus,
      className,
    } = this.props;

    return (
      <Label required={required} className={className}>
        <Header>{label}</Header>
        <StyledTextInput
          onBlur={onBlur}
          multiline={multiline}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          error={error}
          autoFocus={autoFocus}
        />
        {error && <Error>{error}</Error>}
      </Label>
    );
  }
}
