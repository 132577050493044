/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import BriefField from '../../components/BriefField';
import UserField from '../../components/UserField';

import { type EventInfoLeader_event } from './__generated__/EventInfoLeader_event.graphql';

const StyledUserField = styled(UserField)`
  margin-top: 27px;
`;

const EventInfoLeader = (props: { name: string, event: EventInfoLeader_event }) => {
  const {
    name,
    event: { lead },
    event,
  } = props;

  return (
    <BriefField label={name} contentDataAvailable={lead && lead.id}>
      <StyledUserField event={event} user={lead} avatarRadius={27.5} />
    </BriefField>
  );
};

export default createFragmentContainer(
  EventInfoLeader,
  graphql`
    fragment EventInfoLeader_event on Event {
      ...UserField_event
      lead {
        id
        firstName
        lastName
        email
        phone
        officePhone
        title
        company
        ...BriefUserAvatar_user
      }
    }
  `,
);
