/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory } from 'react-router-dom';
import UIStore from 'stores/UIStore';

import FeatureAccessContext from 'contexts/FeatureAccess';

import connectToStores from 'utils/connectToStores';

import Windows from 'components/windows';

import Analytics from './Analytics';
import MainContent from './MainContent';

import type { MainContainer_me } from './__generated__/MainContainer_me.graphql';
import type { MainContainer_org } from './__generated__/MainContainer_org.graphql';

class MainContainer extends React.Component<
  {
    me: MainContainer_me,
    org: MainContainer_org,
    history: RouterHistory,
    location: Location,
    errorPageShown?: ?boolean,
  },
  {
    sidebarShown: boolean,
    sidebarShownOnMobile: boolean,
  },
> {
  static getStores() {
    return [UIStore];
  }

  static getPropsFromStores() {
    return {
      errorPageShown: UIStore.getState().errorPageShown,
    };
  }

  componentDidMount() {
    if (this.shouldRedirectToBilling()) {
      this.props.history.replace('/workspace/billing');
    }
  }

  componentDidUpdate() {
    if (this.shouldRedirectToBilling()) {
      this.props.history.replace('/workspace/billing');
    }
  }

  shouldRedirectToBilling = () => {
    return (
      !this.props.location.pathname.startsWith('/workspace/billing') &&
      this.props.org.subscription.upgradeRequired
    );
  };

  static contextType = FeatureAccessContext;

  render() {
    const { org, me, errorPageShown, location, history } = this.props;
    return (
      <>
        <MainContent
          org={org}
          me={me}
          location={location}
          history={history}
          errorPageShown={errorPageShown}
          accessToLegacyFeatures={this.context.legacyFeatures}
        />
        <Windows userId={me.id} />
        <Analytics org={org} user={me} />
      </>
    );
  }
}

export default createFragmentContainer(connectToStores(MainContainer), {
  me: graphql`
    fragment MainContainer_me on User {
      id
      ...Analytics_user
      ...MainContent_me
    }
  `,
  org: graphql`
    fragment MainContainer_org on Org {
      subscription {
        upgradeRequired
      }
      ...MainContent_org
      ...Analytics_org
    }
  `,
});
