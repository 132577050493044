/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TextInput from 'components/material/TextInput';

import inputBoxStyled from './components/inputBoxStyled';
import Label, { Error, Header } from './FieldComponents';

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

export default class LinkField extends React.PureComponent<{
  label: string,
  defaultValue: ?string,
  onBlur?: (SyntheticEvent<HTMLInputElement>) => void,
  required?: boolean,
  error?: string,
  disabled?: boolean,
}> {
  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  render() {
    const { error, required, onBlur, defaultValue, disabled, label } = this.props;
    return (
      <Label required={required}>
        <Header>{label}</Header>
        <StyledTextInput
          onBlur={onBlur}
          onKeyDown={this.handleKeyDown}
          defaultValue={defaultValue}
          disabled={disabled}
          error={error}
        />
        {error && <Error>{error}</Error>}
      </Label>
    );
  }
}
