/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import timezones from 'config/timezones';

import stringifyTimeZone from 'utils/date/stringifyTimeZone';
import { type HomepageGraphQLValue, homepages } from 'utils/getHomepageUrl';

import updateUser from 'graph/mutations/user/updateUser';
import showModernMutationError from 'graph/utils/showModernMutationError';

import SelectField from 'components/material/SelectField';
import {
  SettingContainer,
  SettingContent,
  SettingItemDetails,
  SettingItemLabel,
  SettingSection,
  SettingSelectFieldContainer,
} from 'components/Settings';

import type { AccountPreferencesForm_me } from './__generated__/AccountPreferencesForm_me.graphql';

const unitOptions = [
  {
    label: 'Imperial',
    value: false,
  },
  {
    label: 'Metric',
    value: true,
  },
];

const Container = styled.div`
  padding: 0 33px;
`;

const StyledSettingItemLabel = styled(SettingItemLabel)`
  flex: 0 0 170px;
`;

class AccountPreferencesForm extends React.Component<{
  me: AccountPreferencesForm_me,
}> {
  handleChangeHomepage = (homepage: ?(HomepageGraphQLValue | string)) => {
    const userId = this.props.me.id;
    if (homepage != null) {
      const selectedSystemOption = homepages(userId).find(option => option.key === homepage);
      const input = selectedSystemOption
        ? { homepage: selectedSystemOption.key, homepageListId: null }
        : { homepageListId: homepage };
      updateUser(userId, input, 'profile').catch(showModernMutationError);
    }
  };

  handleChangeUnits = (metricUnits: ?boolean) => {
    if (metricUnits != null) {
      updateUser(
        this.props.me.id,
        {
          metricUnits,
        },
        'profile',
      ).catch(showModernMutationError);
    }
  };

  handleChangeTz = (tz: ?string) => {
    if (!tz) return;

    updateUser(
      this.props.me.id,
      {
        tz,
      },
      'profile',
    ).catch(showModernMutationError);
  };

  render() {
    const { id: useId, homepage, homepageListId, eventLists, metricUnits, tz } = this.props.me;
    const homepageSystemOptions = homepages(useId).map(homepageOption => ({
      label: homepageOption.label,
      value: homepageOption.key,
      groupId: 'system',
    }));
    const homepageSavedViewOptions = eventLists.edges.map(({ node: list }) => ({
      label: list.name,
      value: list.id,
      groupId: 'saved',
    }));
    const homepageOptionGroups =
      homepageSavedViewOptions.length === 0
        ? []
        : [
            { label: 'SYSTEM VIEWS', value: 'system' },
            { label: 'SAVED VIEWS', value: 'saved' },
          ];
    const homepageOptions = [...homepageSystemOptions, ...homepageSavedViewOptions];

    const timezonesOptions = timezones.map(timezone => ({
      value: timezone.name,
      label: stringifyTimeZone(timezone),
    }));

    return (
      <Container>
        <SettingContainer>
          <SettingSection>
            <SettingContent>
              <StyledSettingItemLabel>Default Dashboard View</StyledSettingItemLabel>
              <SettingItemDetails>
                <SettingSelectFieldContainer>
                  <SelectField
                    optionGroups={homepageOptionGroups}
                    options={homepageOptions}
                    onChange={this.handleChangeHomepage}
                    value={homepageListId || homepage}
                  />
                </SettingSelectFieldContainer>
              </SettingItemDetails>
            </SettingContent>
            <SettingContent>
              <StyledSettingItemLabel>Units</StyledSettingItemLabel>
              <SettingItemDetails>
                <SettingSelectFieldContainer>
                  <SelectField
                    options={unitOptions}
                    onChange={this.handleChangeUnits}
                    value={metricUnits}
                  />
                </SettingSelectFieldContainer>
              </SettingItemDetails>
            </SettingContent>
            <SettingContent>
              <StyledSettingItemLabel>Time Zone</StyledSettingItemLabel>
              <SettingItemDetails>
                <SettingSelectFieldContainer>
                  <SelectField
                    options={timezonesOptions}
                    onChange={this.handleChangeTz}
                    value={tz}
                    searchable
                  />
                </SettingSelectFieldContainer>
              </SettingItemDetails>
            </SettingContent>
          </SettingSection>
        </SettingContainer>
      </Container>
    );
  }
}

export default createFragmentContainer(
  AccountPreferencesForm,
  graphql`
    fragment AccountPreferencesForm_me on User {
      id
      homepage
      homepageListId
      eventLists {
        edges {
          node {
            id
            name
          }
        }
      }
      metricUnits
      tz
    }
  `,
);
