/* @flow */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AttendanceSettings from './AttendanceSettings';
import CompanySettings from './CompanySettings';
import OpportunitySettings from './OpportunitySettings';
import PeopleSettings from './PeopleSettings';
import VendorSettings from './VendorSettings';

import { type ContactSettings_org } from './__generated__/ContactSettings_org.graphql';

type Props = {
  org: ContactSettings_org,
  pathPrefix: string,
  rootUrl: string,
};

const ContactSettingsRoutes = ({ org, pathPrefix, rootUrl }: Props) => {
  return (
    <Switch>
      <Redirect from={pathPrefix} exact to={`${rootUrl}/people`} />
      <Route
        path={`${pathPrefix}/people`}
        exact
        render={props => <PeopleSettings org={org} {...props} />}
      />
      <Route
        path={`${pathPrefix}/companies`}
        exact
        render={props => <CompanySettings org={org} {...props} />}
      />
      <Route path={`${pathPrefix}/vendors`} exact component={VendorSettings} />
      <Route path={`${pathPrefix}/attendance`} component={AttendanceSettings} />
      <Route
        path={`${pathPrefix}/opportunities`}
        exact
        render={props => <OpportunitySettings org={org} {...props} />}
      />
    </Switch>
  );
};

export default ContactSettingsRoutes;
