/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { RequiredFieldsWarning_salesforceFields } from './__generated__/RequiredFieldsWarning_salesforceFields.graphql';
import { type SalesforceKindType } from './__generated__/SalesforceMapping_org.graphql';

const Message = styled.div`
  background: ${props => props.theme.failedMessageColor};
  padding: 10px 20px;
  margin-bottom: 30px;
  color: ${props => props.theme.rowPrimaryTextColor};
  display: flex;
  align-items: center;
  strong {
    margin-left: 5px;
  }
`;

const Icon = styled.i`
  color: ${props => props.theme.negativeActionColor};
  font-size: 20px;
  margin-right: 10px;
`;

class RequiredFieldsWarning extends React.PureComponent<{
  salesforceFields: RequiredFieldsWarning_salesforceFields,
  activeFields: $ReadOnlyArray<string>,
  kinds: $ReadOnlyArray<SalesforceKindType>,
}> {
  render() {
    const { kinds, activeFields, salesforceFields } = this.props;
    if (['EVENT', 'OPPORTUNITY'].includes(kinds[0])) return null;

    const requiredFields = salesforceFields
      .filter(field => {
        return (
          !field.nillable &&
          field.updateable &&
          !field.defaultedOnCreate &&
          field.type !== 'picklist' &&
          !activeFields.includes(field.name)
        );
      })
      .map(field => field.label);
    if (requiredFields.length === 0) return null;

    return (
      <Message>
        <Icon className="fa fa-fw fa-exclamation-circle" />
        These fields are required and will fail the sync if not configured:
        <strong>{requiredFields.join(', ')}</strong>.
      </Message>
    );
  }
}

export default createFragmentContainer(
  RequiredFieldsWarning,
  graphql`
    fragment RequiredFieldsWarning_salesforceFields on SalesforceField @relay(plural: true) {
      nillable
      name
      label
      updateable
      type
      defaultedOnCreate
    }
  `,
);
