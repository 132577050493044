/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationFormComponent_org$ref = any;
type SharedRowComponent_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationFormSubmissionContent_org$ref: FragmentReference;
export type RegistrationFormSubmissionContent_org = {|
  +id: string,
  +name: string,
  +analyticsEnabled: boolean,
  +settings: {|
    +font: string
  |},
  +$fragmentRefs: RegistrationFormComponent_org$ref & SharedRowComponent_org$ref,
  +$refType: RegistrationFormSubmissionContent_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationFormSubmissionContent_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "analyticsEnabled",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "font",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationFormComponent_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SharedRowComponent_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7f0238abddac6330776e2f687c60c65e';
module.exports = node;
