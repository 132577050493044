/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type ParsedContactsFilters } from 'components/Contacts/lib/parseContactsFilters';

import { type CreateCompanyFromWindow } from '../company/createCompany';

import type {
  createContactMutationResponse,
  createContactMutationVariables,
} from './__generated__/createContactMutation.graphql';

export type CreateContactFromWindow = 'event contacts' | 'org contacts';
export type ResponseContact = $PropertyType<
  $PropertyType<
    $NonMaybeType<$PropertyType<createContactMutationResponse, 'createContact'>>,
    'contactEdge',
  >,
  'node',
>;

type Input = $Diff<
  $PropertyType<createContactMutationVariables, 'input'>,
  {
    fromWindow: ?string,
    company: ?$PropertyType<$PropertyType<createContactMutationVariables, 'input'>, 'company'>,
  },
> & {
  company?: ?$ReadOnly<
    $NonMaybeType<$PropertyType<$PropertyType<createContactMutationVariables, 'input'>, 'company'>>,
  >,
  fromWindow?: ?CreateContactFromWindow | CreateCompanyFromWindow,
};

const mutation = graphql`
  mutation createContactMutation(
    $input: CreateContactInput!
    $filters: ContactFilters
    $updateOrgTotalCount: Boolean!
  ) {
    createContact(input: $input) {
      org {
        contacts {
          totalCount
        }
      }
      ... @include(if: $updateOrgTotalCount) {
        org {
          ...OrgContactsTablePagination_totalCountOrg
        }
      }
      eventEdges {
        __typename
        node {
          id
          ...ContactsTablePagination_totalCountEvent
        }
      }
      eventContactEdges {
        ...ContactRegistrationStatus_eventContact
        ...ContactAttendanceStatus_eventContact
        ...ContactAttendanceJoinTime_eventContact
        ...ContactAttendanceLeaveTime_eventContact
        ...ContactAttendanceDuration_eventContact
        ...ContactMembershipSyncStatus_eventContact
        ...ContactMembershipLastSynced_eventContact
        node {
          id
        }
      }
      contactEdge {
        __typename
        node {
          id
          avatar
          email
          firstName
          lastName
          salesforceSyncAs
          ...ContactTitle_contact
          ...ContactPhone_contact
          ...ContactPersonType_contact
          ...ContactName_contact
          ...ContactSalesforceSyncAs_contact
          ...ContactCompany_contact
          ...ContactEmail_contact
          ...ContactWebsite_contact
          ...ContactTwitter_contact
          ...ContactLinkedin_contact
          ...ContactOwner_contact
          ...ContactUpdater_contact
          ...ContactUpdatedAt_contact
          ...ContactUpdatedMethod_contact
          ...ContactCreator_contact
          ...ContactCreatedAt_contact
          ...ContactCreatedMethod_contact
          ...ContactLastSynced_contact
          ...ContactSyncStatus_contact
          ...ContactSalesforceId_contact
          ...ContactMarketoId_contact
          ...ContactStreet_contact
          ...ContactCity_contact
          ...ContactState_contact
          ...ContactZip_contact
          ...ContactCountry_contact
          ...ContactDescription_contact
          ...ContactEmailOptIn_contact
          ...ContactHotLead_contact
          ...CustomizableText_customizable
          ...CustomizableTextarea_customizable
          ...CustomizableLink_customizable
          ...CustomizableDate_customizable
          ...CustomizableBoolean_customizable
          ...CustomizableNumber_customizable
          ...CustomizableCurrency_customizable
          ...CustomizableSelect_customizable
          ...CustomizableMultiselect_customizable
          ...CustomizableUserSelect_customizable
          ...CustomizableUserMultiselect_customizable
          ...ContactActionCell_contact
          ...ContactRequestSubmissionsCount_contact
          ...ContactEventsCount_contact
          ...ContactRegistrationStatus_contact
          ...ContactAttendanceStatus_contact
        }
      }
    }
  }
`;

export default function createContact(
  orgId: string,
  input: Input,
  // TODO: this type is incorrect right one should be defined
  filtersVariables?: ?$Shape<ParsedContactsFilters>,
): Promise<ResponseContact> {
  return commitModernMutation({
    mutation,
    variables: {
      input,
      filters: filtersVariables,
      updateOrgTotalCount: filtersVariables != null,
    },
    updater: mergeUpdaters(
      addRecordToConnection({
        parentId: orgId,
        edgeName: 'contactEdge',
        connections: [{ key: 'OrgContactsTablePagination_contacts' }],
      }),
      ...(input.eventIds || []).map(eventId =>
        addRecordToConnection({
          parentId: eventId,
          edgeName: 'eventContactEdges',
          connections: [
            { key: 'ContactsTablePagination_eventContacts' },
            { field: 'eventContacts' },
          ],
        }),
      ),
    ),
  }).then((response: createContactMutationResponse) => {
    if (!response.createContact) {
      throw Error('Incomplete response');
    }

    return response.createContact.contactEdge.node;
  });
}
