/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RequestForm_requestForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestSharedFormContainer_requestForm$ref: FragmentReference;
export type EventRequestSharedFormContainer_requestForm = {|
  +id: string,
  +name: string,
  +logo: ?string,
  +submitMessage: string,
  +$fragmentRefs: RequestForm_requestForm$ref,
  +$refType: EventRequestSharedFormContainer_requestForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestSharedFormContainer_requestForm",
  "type": "EventRequestForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "submitMessage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RequestForm_requestForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '583b834c29227e7faf7deac2cc699a03';
module.exports = node;
