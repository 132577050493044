/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import FeatureAccessContext from 'contexts/FeatureAccess';

import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import stringifyQueryParamValues from 'utils/routing/stringifyQueryParamValues';
import storage from 'utils/storage';

import getEventFields from 'components/AllEvents/lib/getEventFields';
import getEventFilterConverters from 'components/AllEvents/lib/getEventFilterConverters';
import type { OptionGroup } from 'components/material/SelectField';

import BudgetReportingGroupContainer from './BudgetReportingGroupContainer';
import getCustomFieldOptions from './lib/getCustomFieldOptions';
import parseBudgetReportingFilters from './lib/parseBudgetReportingFilters';
import savedViewToFilterObject, { dateFilterToDateParam } from './lib/savedViewToFilterObject';

import type { BudgetReportingPage_me } from './__generated__/BudgetReportingPage_me.graphql';
import type { BudgetReportingPage_org } from './__generated__/BudgetReportingPage_org.graphql';

export type CustomGroupFieldSettingsType = $PropertyType<BudgetReportingPage_org, 'settings'>;
export type CustomFields = $PropertyType<BudgetReportingPage_org, 'customFields'>;
export type BudgetReportingSavedViewsType = $PropertyType<
  BudgetReportingPage_org,
  'budgetReportingViews',
>;

const Title = styled.div`
  margin: 25px 0 21px 0;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

class BudgetReportingPage extends React.PureComponent<{
  me: BudgetReportingPage_me,
  org: BudgetReportingPage_org,
  history: RouterHistory,
  location: Location,
}> {
  eventFields: $ReadOnlyArray<FieldType> = getEventFields(
    normalizeCustomFields(this.props.org.customFields.edges.map(({ node }) => node)),
    this.props.org,
    this.context.legacyFeatures,
  );

  customFieldOptions: $ReadOnlyArray<OptionGroup> = getCustomFieldOptions(
    this.props.org.customFields,
  );

  converters = getEventFilterConverters(this.eventFields, true);

  componentDidMount() {
    const { location, org } = this.props;
    const filters = parseBudgetReportingFilters(
      location.search,
      org.settings.fiscalYearStart,
      this.eventFields,
    );
    const filtered = Object.values(filters).some(val => val != null);

    if (!filtered || (filters.group1 == null && filters.customGroup1 == null)) {
      this.setView();
    }
  }

  componentDidUpdate() {
    if (this.props.location.search === '') this.setView();
  }

  getDefaultSavedViewId(): ?string {
    const lastViewId = storage.get(`${this.props.me.id}_budgetReportingLastView`);
    if (
      typeof lastViewId === 'string' &&
      this.props.org.budgetReportingViews.edges.some(edge => edge.node.id === lastViewId)
    ) {
      return lastViewId;
    }
    const savedViewEdges = this.props.org.budgetReportingViews.edges;
    if (savedViewEdges.length > 0) return savedViewEdges[0].node.id;
    return null;
  }

  setView = () => {
    const savedViewId = this.getDefaultSavedViewId();
    if (savedViewId == null) {
      this.setDefaultView();
    } else {
      this.setSavedView(savedViewId, true);
    }
  };

  customFieldDisabled = (customFieldId: ?string) => {
    if (customFieldId == null) {
      return false;
    }
    return !this.eventFields.find(field => field.id === customFieldId);
  };

  setSavedView = (viewId: string, setDefaultDate?: boolean) => {
    const { org, me } = this.props;
    const view = org.budgetReportingViews.edges
      .map(edge => edge.node)
      .find(savedView => savedView.id === viewId);
    if (view == null) {
      this.setDefaultView();
    } else {
      storage.set(`${this.props.me.id}_budgetReportingLastView`, viewId);
      const filters = savedViewToFilterObject(
        view,
        org.settings.fiscalYearStart,
        this.eventFields,
        me.tz,
      );
      const {
        date,
        updatedAt,
        createdAt,
        requestedDate,
        group1,
        group2,
        customGroup1,
        customGroup2,
        ...otherFilters
      } = filters;
      this.replaceParams({
        ...otherFilters,
        ...((group1 == null && customGroup1 == null) ||
        this.customFieldDisabled(customGroup1) ||
        this.customFieldDisabled(customGroup2)
          ? this.defaultViewFields()
          : { group1, group2, customGroup1, customGroup2 }),
        date: dateFilterToDateParam(date) || (setDefaultDate ? 'FQ0' : null),
        updatedAt: dateFilterToDateParam(updatedAt),
        createdAt: dateFilterToDateParam(createdAt),
        requestedDate: dateFilterToDateParam(requestedDate),
      });
    }
  };

  defaultViewFields = () => {
    return { group1: 'TEAM', group2: 'EVENT', customGroup1: null, customGroup2: null };
  };

  setDefaultView = () => {
    const defaultFilters = {
      ...this.defaultViewFields(),
      date: 'FQ0',
      statuses: ['COMMITTED'],
      requestStatuses: ['N_A', 'APPROVED'],
    };
    this.replaceParams(defaultFilters);
  };

  handleFilterChange = (filterParams: { [string]: ?(string | ?$ReadOnlyArray<string>) }) => {
    this.replaceParams(filterParams);
  };

  replaceParams = (params: {}) => {
    replaceQueryParams(this.props.history, stringifyQueryParamValues(params, this.converters));
  };

  static contextType = FeatureAccessContext;

  render() {
    const { location, history, org } = this.props;
    return (
      <>
        <Title>Budget Reporting</Title>
        <BudgetReportingGroupContainer
          location={location}
          history={history}
          org={org}
          savedViewId={this.getDefaultSavedViewId()}
          savedViews={org.budgetReportingViews}
          onFilterChange={this.handleFilterChange}
          onSetSavedView={this.setSavedView}
          eventFields={this.eventFields}
          customFieldOptions={this.customFieldOptions}
        />
      </>
    );
  }
}

export default createFragmentContainer(
  BudgetReportingPage,
  graphql`
    fragment BudgetReportingPage_me on User {
      id
      tz
    }

    fragment BudgetReportingPage_org on Org {
      id
      ...BudgetReportingGroupContainer_org
      settings {
        currency
        fiscalYearStart
      }
      salesforceAccount {
        id
      }
      marketoAccount {
        id
      }
      customFieldSections(sectionCustomizableType: [EVENT]) {
        edges {
          node {
            id
            name
            order
          }
        }
      }
      customFields(customizableType: [EVENT]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            restrictChangingValue: mappedToSalesforce(pull: true)
            section {
              id
              order
            }
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      budgetReportingViews {
        edges {
          node {
            id
            name
            group1
            group2
            customGroup1 {
              id
            }
            customGroup2 {
              id
            }
            eventIds
            eventName
            eventDate
            eventStatuses
            syncStatuses
            opportunitiesNumber {
              min
              max
            }
            opportunitiesAmount {
              min
              max
            }
            contactsCount {
              min
              max
            }
            registeredContactsTotal {
              min
              max
            }
            attendedContactsTotal {
              min
              max
            }
            registrationFormStatuses
            sort
            direction
            customSavedNumberFilters {
              edges {
                node {
                  id
                  minValue
                  maxValue
                  customField {
                    id
                  }
                }
              }
            }
            customSavedCurrencyFilters {
              edges {
                node {
                  id
                  minValue
                  maxValue
                  fieldName
                  customField {
                    id
                  }
                }
              }
            }
            customSavedDateFilters {
              edges {
                node {
                  id
                  minValue
                  maxValue
                  customField {
                    id
                  }
                }
              }
            }
            customSavedBooleanFilters {
              edges {
                node {
                  id
                  value
                  customField {
                    id
                  }
                }
              }
            }
            customSavedUserMultiselectFilters {
              edges {
                node {
                  id
                  fieldName
                  customField {
                    id
                  }
                  options {
                    edges {
                      node {
                        user {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
            customSavedMultiselectFilters {
              edges {
                node {
                  id
                  customField {
                    id
                  }
                  options {
                    edges {
                      node {
                        option {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
            teams {
              edges {
                node {
                  id
                }
              }
            }
            leads {
              edges {
                node {
                  id
                }
              }
            }
            staffers {
              edges {
                node {
                  id
                }
              }
            }
            budgetCategories {
              edges {
                node {
                  id
                }
              }
            }
            venueNames
            cities
            states
            countries
            eventFormats
            creators {
              edges {
                node {
                  id
                }
              }
            }
            createdAt
            updaters {
              edges {
                node {
                  id
                }
              }
            }
            updatedAt
            requestedDate
            requestStatuses
            userRequesters {
              edges {
                node {
                  id
                }
              }
            }
            contactRequesters {
              edges {
                node {
                  id
                }
              }
            }
            eventRequestForms {
              edges {
                node {
                  id
                }
              }
            }
            eventRequestReviewers {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `,
);
