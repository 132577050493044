/* @flow */

import * as React from 'react';
import styled from 'styled-components';

import { type AttachmentType } from './BriefImage';

const Link = styled.div`
  flex: 1 1 auto;
  margin-bottom: 19px;
  font-size: 16px;
  line-height: 1;
  color: ${props => props.theme.primaryActionColor};
  text-decoration: underline;
  cursor: pointer;
`;

const ManualLink = styled.a`
  margin-bottom: 19px;
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 1;
  color: ${props => props.theme.primaryActionColor};
  text-decoration: underline;
`;

const BriefLink = (props: {
  attachment: AttachmentType,
  onPreview: (attachmentId: string) => void,
}) => {
  const {
    onPreview,
    attachment: { id, fileurl, filename, filetype },
  } = props;
  const isLink = filetype && filetype.startsWith('link/');
  return isLink ? (
    <ManualLink href={fileurl} rel="noopener noreferrer" target="_blank">
      {filename || fileurl}
    </ManualLink>
  ) : (
    <Link onClick={() => onPreview(id)}>{filename || fileurl}</Link>
  );
};

export default BriefLink;
