/* @flow */
import { SALESFORCE_ID } from 'config/variables';

import appOrigin from 'utils/url/appOrigin';

import parseCallbackUrl from './parseCallbackUrl';
import authPopup from './authPopup';

/**
 * Opens an auth popup to salesforce
 *
 * @return {Promise} Resolves if params are returned (successful or not)
 *  rejects if the window is closed before completing.
 */
export default function salesforceAuthPopup(
  sandbox?: boolean,
): Promise<{
  host: string,
  [string]: string,
}> {
  const callbackUrl = `${appOrigin()}/oauth2_popup_callback.html`;
  const callbackUrlParam = callbackUrl.replace(/^https/, 'http');
  const clientId = SALESFORCE_ID;
  const salesforceHost = sandbox ? 'test.salesforce.com' : 'login.salesforce.com';
  const connectUrl = `https://${salesforceHost}/services/oauth2/authorize?response_type=code&scope=api+refresh_token&client_id=${clientId}&redirect_uri=${callbackUrlParam}&display=popup`;

  return authPopup(connectUrl, callbackUrl).then(successUrl =>
    Object.assign({}, parseCallbackUrl(successUrl), {
      host: salesforceHost,
    }),
  );
}
