/* @flow */
import React from 'react';
import uniq from 'lodash/uniq';
import styled from 'styled-components';
import { graphql } from 'react-relay';

import Button from 'components/material/Button';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import InviteToOrgContents from 'components/InviteWindow/InviteToOrgContents';
import { type AccessType, type AccessScope } from 'components/InviteWindow/AccessTypeSelector';
import { type Event } from 'components/InviteWindow/InvitationEventSearch';

import { type UsersImportWindowConfigQueryResponse } from './__generated__/UsersImportWindowConfigQuery.graphql';

const Container = styled.div`
  flex: 1 1 auto;
  max-width: 385px;
  margin: 0 auto;
`;

const InfoMessage = styled.div`
  margin: 50px 0 30px;
  font-size: 16px;
  font-weight: 500;
  color: #3e4859;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

const query = graphql`
  query UsersImportWindowConfigQuery {
    me {
      ...InviteToOrgContents_me
    }
    org {
      ...InviteToOrgContents_org
    }
  }
`;

export default class UsersImportWindowConfig extends React.PureComponent<{
  accessScope: AccessScope,
  accessType: AccessType,
  selectedTeamIds: $ReadOnlyArray<string>,
  selectedEvents: $ReadOnlyArray<Event>,
  onChangeAccessType: (accessType: AccessType) => void,
  onChangeSelectedTeamIds: (selectedTeamIds: $ReadOnlyArray<string>) => void,
  onChangeSelectedEvents: (selectedEvents: $ReadOnlyArray<Event>) => void,
  onCancel: () => void,
  onNext: () => void,
}> {
  handleChangeAccessType = (accessType: ?AccessType) => {
    if (accessType) {
      this.props.onChangeAccessType(accessType);
    }
  };

  handleSelectTeam = (teamId: string) => {
    this.props.onChangeSelectedTeamIds(uniq([teamId, ...this.props.selectedTeamIds]));
  };

  handleUnselectTeam = (teamId: string) => {
    this.props.onChangeSelectedTeamIds(this.props.selectedTeamIds.filter(id => id !== teamId));
  };

  handleSelectEvent = (event: Event) => {
    this.props.onChangeSelectedEvents([event, ...this.props.selectedEvents]);
  };

  handleUnselectEvent = (eventId: string) => {
    this.props.onChangeSelectedEvents(this.props.selectedEvents.filter(event => event.id !== eventId));
  };

  nextEnabled = () =>
    this.props.accessType === 'ADMIN' ||
    (this.props.accessType === 'TEAM'
      ? this.props.selectedTeamIds.length > 0
      : this.props.selectedEvents.length > 0);

  renderInviteConfig = (response: UsersImportWindowConfigQueryResponse) => (
    <InviteToOrgContents
      me={response.me}
      org={response.org}
      accessType={this.props.accessType}
      accessScope={this.props.accessScope}
      onAccessTypeChange={this.handleChangeAccessType}
      selectedTeamIds={this.props.selectedTeamIds}
      onSelectTeam={this.handleSelectTeam}
      onUnselectTeam={this.handleUnselectTeam}
      selectedEvents={this.props.selectedEvents}
      onSelectEvent={this.handleSelectEvent}
      onUnselectEvent={this.handleUnselectEvent}
      wrapSelects
      canChangeEvents={this.props.accessScope === 'ORG'}
      canChangeTeams={this.props.accessScope === 'ORG'}
    />
  );

  render() {
    const variables = { filters: { fullAccessEvents: true, requestStatuses: ['N_A', 'APPROVED'] } };

    return (
      <React.Fragment>
        <Container>
          <InfoMessage>Please select a role for the members you’re importing</InfoMessage>

          <DefaultQueryRenderer
            query={query}
            variables={variables}
            renderSuccess={this.renderInviteConfig}
          />
        </Container>

        <Buttons>
          <Button minimal label="Cancel" onClick={this.props.onCancel} />
          <SubmitButton
            primary
            label="Next"
            onClick={this.props.onNext}
            disabled={!this.nextEnabled()}
          />
        </Buttons>
      </React.Fragment>
    );
  }
}
