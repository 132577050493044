/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import fullNameOfUser from 'utils/fullNameOfUser';

import type { Event } from 'graph/mutations/event/updateEvent';

import InlineUserSelectField from 'components/budget/Table/InlineUserSelectField';
import MaterialAvatar from 'components/material/MaterialAvatar';
import { type User } from 'components/UserSelect';

import type { EventLead_event } from './__generated__/EventLead_event.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 17px;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

class EventLead extends React.PureComponent<{
  event: EventLead_event,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdate?: (changedProps: $Shape<Event>) => Promise<void>,
}> {
  handleSelect = (lead: ?User): void => {
    const { onUpdate, updateColumnWidth } = this.props;
    if (onUpdate) {
      onUpdate({ lead }).then(updateColumnWidth);
    }
  };

  render() {
    const { fieldSettings, event, readOnly } = this.props;

    if (readOnly) {
      return event.lead ? (
        <Container>
          <MaterialAvatar radius={12} user={event.lead} />
          <TextWrapper>{fullNameOfUser(event.lead)}</TextWrapper>
        </Container>
      ) : null;
    }

    return (
      <InlineUserSelectField
        placeholder={fieldSettings.label}
        activeUsers={event.lead ? [event.lead] : []}
        onSelect={this.handleSelect}
        clearable={!fieldSettings.required}
      />
    );
  }
}

export default createFragmentContainer(EventLead, {
  event: graphql`
    fragment EventLead_event on Event {
      lead {
        id
        firstName
        lastName
        email
        avatar
        ...MaterialAvatar_user
      }
    }
  `,
});
