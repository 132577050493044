/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import TextField from '../../../components/TextField';

import { type EventInfoCustomText_event } from './__generated__/EventInfoCustomText_event.graphql';

const EventInfoCustomText = ({
  name,
  event,
  customFieldId,
}: {
  name: string,
  event: EventInfoCustomText_event,
  customFieldId: string,
}) => {
  const fieldValue = event.customTextFields.find(value => value.customField.id === customFieldId);

  if (!fieldValue) return null;

  return <TextField name={name} description={fieldValue.value} />;
};

export default createFragmentContainer(
  EventInfoCustomText,
  graphql`
    fragment EventInfoCustomText_event on Event {
      customTextFields(customFieldIds: $customFieldIds) {
        value
        customField {
          id
        }
      }
    }
  `,
);
