/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import NumberField from 'components/material/NumberField';

import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';
import inputBoxStyled from '../form/inputBoxStyled';
import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../lib/types';

const StyledNumberField = styled(NumberField)`
  margin: 0;
  input {
    ${props => inputBoxStyled(props)};
  }
`;

export default class QuestionNumber extends React.PureComponent<{
  question: QuestionType,
  value: QuestionValueType,
  onChangeValue: QuestionValueInputType => void,
  readOnly: boolean,
  error: ?string,
}> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    this.props.onChangeValue({ numberValue: value.trim() !== '' ? +value : null });
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>, value: ?number) => {
    this.props.onChangeValue({ numberValue: value });
  };

  render() {
    return (
      <>
        {this.props.question.description && (
          <HelperText>{this.props.question.description}</HelperText>
        )}
        <StyledNumberField
          defaultValue={this.props.value.numberValue}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          error={this.props.error ? ' ' : null}
          disabled={this.props.readOnly}
        />
        {this.props.error && <ErrorMessage>{this.props.error}</ErrorMessage>}
      </>
    );
  }
}
