/* @flow */

import React from 'react';

import type { IconProps } from './types';

export default function Company({
  gradient,
  ...props
}: {|
  gradient?: [string, string],
  ...IconProps,
|}) {
  return (
    <svg viewBox="0 0 35 35" {...props}>
      {gradient && (
        <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor={gradient[0]} />
          <stop offset="100%" stopColor={gradient[1]} />
        </linearGradient>
      )}
      <g fillRule="nonzero" fill={gradient ? 'url(#gradient)' : 'currentColor'}>
        <path d="M27.2 15.6L27.2 0 7.8 0 7.8 7.8 0 7.8 0 35 15.6 35 15.6 27.2 19.4 27.2 19.4 35 35 35 35 15.6 27.2 15.6ZM7.8 31.1L3.9 31.1 3.9 27.2 7.8 27.2 7.8 31.1ZM7.8 23.3L3.9 23.3 3.9 19.4 7.8 19.4 7.8 23.3ZM7.8 15.6L3.9 15.6 3.9 11.7 7.8 11.7 7.8 15.6ZM15.6 23.3L11.7 23.3 11.7 19.4 15.6 19.4 15.6 23.3ZM15.6 15.6L11.7 15.6 11.7 11.7 15.6 11.7 15.6 15.6ZM15.6 7.8L11.7 7.8 11.7 3.9 15.6 3.9 15.6 7.8ZM23.3 23.3L19.4 23.3 19.4 19.4 23.3 19.4 23.3 23.3ZM23.3 15.6L19.4 15.6 19.4 11.7 23.3 11.7 23.3 15.6ZM23.3 7.8L19.4 7.8 19.4 3.9 23.3 3.9 23.3 7.8ZM31.1 31.1L27.2 31.1 27.2 27.2 31.1 27.2 31.1 31.1ZM31.1 23.3L27.2 23.3 27.2 19.4 31.1 19.4 31.1 23.3Z" />
      </g>
    </svg>
  );
}
