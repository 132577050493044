/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import syncOptions from 'config/salesforceSyncOptions';

import updateOrgSettings from 'graph/mutations/org/updateOrgSettings';
import showModernMutationError from 'graph/utils/showModernMutationError';

import SelectField from 'components/material/SelectField';
import { SettingContent, SettingSection } from 'components/Settings';

import type { SalesforceSettings_org } from './__generated__/SalesforceSettings_org.graphql';

const SettingItem = styled.div`
  flex: 0 1 200px;
`;

class SalesforceSettings extends React.PureComponent<{
  org: SalesforceSettings_org,
}> {
  handleOptionChange = (defaultTag: ?string) => {
    updateOrgSettings(this.props.org.settings.id, { defaultTag }).catch(showModernMutationError);
  };

  render() {
    const options = syncOptions.map(option => ({ label: option.name, value: option.value }));

    return (
      <SettingSection>
        <SettingContent>
          <SettingItem>
            <SelectField
              label="Default Salesforce Sync Object"
              value={this.props.org.settings.defaultTag}
              onChange={this.handleOptionChange}
              options={options}
            />
          </SettingItem>
        </SettingContent>
      </SettingSection>
    );
  }
}

export default createFragmentContainer(
  SalesforceSettings,
  graphql`
    fragment SalesforceSettings_org on Org {
      settings {
        id
        defaultTag
      }
    }
  `,
);
