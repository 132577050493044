import { reset } from 'utils/analytics';
import { expireCookie } from 'utils/Cookies';
import { clearErrorReportingUser } from 'utils/errorReporting';

import UserActions from '../actions/UserActions';
import alt from '../alt';
import UserSource from '../sources/UserSource';
import * as Auth from '../utils/Auth';

class UserStore {
  constructor() {
    this.user = {};

    this.bindActions(UserActions);
    this.registerAsync(UserSource);
  }

  onUpdateUser(user) {
    this.user = Object.assign({}, this.user, user);
    if (!Auth.isLoggedIn()) {
      Auth.setUser(user);
    }
  }

  onRemoveUser = () => {
    Auth.setUser(null);
    expireCookie('disableAnalytics');
    clearErrorReportingUser();
    reset();
    window.location.href = '/';
  };
}

export default alt.createStore(UserStore, 'UserStore');
