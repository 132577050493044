/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Redirect, Route, Switch } from 'react-router-dom';

import PublicOnlyRoute from 'utils/routing/PublicOnlyRoute';

import AuthBackground from 'components/Auth/AuthBackground';

import ConfirmationResend from './ConfirmationResend';
import Login from './Login';
import NewPassword from './NewPassword';
import PasswordReset from './PasswordReset';
import SignUp from './SignUp';

const RedirectToSignIn = () => (
  <Route render={routeProps => <Redirect to={`/signin${routeProps.location.search}`} />} />
);

const routes = {
  '/': RedirectToSignIn,
  '/login': RedirectToSignIn,
  '/signin': Login,
  '/signup': SignUp,
  '/reset': PasswordReset,
  '/resend': ConfirmationResend,
  '/reset-via-email': NewPassword,
};

export default class Auth extends React.Component<{}> {
  // Generates a path with all possible routes
  static path = `/(${Object.keys(routes)
    .map(path => path.slice(1))
    .filter(Boolean)
    .join('|')})?`;

  render() {
    return (
      <DocumentTitle title="Circa">
        <AuthBackground>
          <Switch>
            {Object.entries(routes).map(([path, component]) => (
              <PublicOnlyRoute path={path} exact component={component} key={path} />
            ))}
          </Switch>
        </AuthBackground>
      </DocumentTitle>
    );
  }
}
