/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import getShownColumns from 'components/Vendors/lib/getShownColumns';
import getVendorFields from 'components/Vendors/lib/getVendorFields';
import parseVendorsFilters from 'components/Vendors/lib/parseVendorsFilters';
import { defaultTableComponents } from 'components/Vendors/table/columns';
import columnSettings from 'components/Vendors/table/columns/columnSettings';

import EventVendorsHeaderBar from './EventVendorsHeaderBar';
import EventVendorsTablePagination from './EventVendorsTablePagination';

import { type EventVendorsPage_event } from './__generated__/EventVendorsPage_event.graphql';
import { type EventVendorsPage_org } from './__generated__/EventVendorsPage_org.graphql';
import { type EventVendorsPageQueryResponse } from './__generated__/EventVendorsPageQuery.graphql';

const query = graphql`
  query EventVendorsPageQuery(
    $eventId: ID!
    $count: Int!
    $cursor: String
    $filters: VendorFilters!
    $includeName: Boolean!
    $includeWebsite: Boolean!
    $includeCompanyPhone: Boolean!
    $includeStreet: Boolean!
    $includeCity: Boolean!
    $includeState: Boolean!
    $includeZip: Boolean!
    $includeCountry: Boolean!
    $includeTwitter: Boolean!
    $includeLinkedin: Boolean!
    $includeDescription: Boolean!
    $includeFirstName: Boolean!
    $includeLastName: Boolean!
    $includeTitle: Boolean!
    $includePhone1: Boolean!
    $includePhone2: Boolean!
    $includeEmail: Boolean!
    $includeCreatedAt: Boolean!
    $includeCreatedBy: Boolean!
    $includeUpdatedAt: Boolean!
    $includeUpdatedBy: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
  ) {
    event(relayId: $eventId) {
      ...EventVendorsTablePagination_event
      ...EventVendorsTablePagination_totalCountEvent
    }
  }
`;

class EventVendorsPage extends React.Component<
  {
    org: EventVendorsPage_org,
    event: EventVendorsPage_event,
    history: RouterHistory,
    location: Location,
    pathPrefix: string,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
    availableVendors: $ReadOnlyArray<string>,
  },
> {
  vendorFields: $ReadOnlyArray<FieldType> = getVendorFields(
    normalizeCustomFields(this.props.org.customFields.edges.map(edge => edge.node)),
  );

  state = {
    shownColumns: getShownColumns('eventVendorsShownColumns'),
    availableVendors: [],
  };

  handleVendorsListUpdate = (availableVendors: $ReadOnlyArray<string>) => {
    this.setState({ availableVendors });
  };

  handleSearch = (search: string) => {
    replaceQueryParams(this.props.history, { query: search || null });
  };

  handleColumnsChange = (shownColumns: $ReadOnlyArray<string>) => {
    storage.set('eventVendorsShownColumns', shownColumns);
    this.setState({ shownColumns });
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  renderTable = (props: {|
    event: ?$PropertyType<EventVendorsPageQueryResponse, 'event'>,
    filtered: boolean,
    sort: SortParam,
    includedColumns: { [string]: boolean },
  |}): React.Node => (
    <EventVendorsTablePagination
      org={this.props.org}
      event={props.event}
      totalCountEvent={props.event}
      shownColumns={columnSettings(this.vendorFields, this.state.shownColumns)}
      filtered={props.filtered}
      onChangeSort={this.handleSortChange}
      onVendorsListUpdate={this.handleVendorsListUpdate}
      sort={props.sort}
      includedColumns={props.includedColumns}
      pathPrefix={this.props.pathPrefix}
    />
  );

  render() {
    const { event, location, history } = this.props;
    const shownColumns = this.state.shownColumns;
    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      this.vendorFields,
      shownColumns,
    );

    const filters = parseVendorsFilters(location.search, this.vendorFields);
    const filtered = Object.values(filters).some(val => !!val);
    // Default sorting
    const sortFilter = filters.sort || {
      key: 'NAME',
      asc: true,
    };

    const customSortField: ?FieldType = this.vendorFields.find(
      field => field.id === sortFilter.key,
    );

    const variables = {
      eventId: event.id,
      count: 25,
      filters: {
        sort: customSortField ? 'CUSTOM' : sortFilter.key,
        customFieldSortId: customSortField != null ? customSortField.id : null,
        direction: sortFilter.asc ? 'ASC' : 'DESC',
        query: filters.query,
        createdAt: filters.created_at,
        creatorIds: filters.created_by,
        updatedAt: filters.updated_at,
        updaterIds: filters.updated_by,
        cities: filters.city,
        states: filters.state,
        countries: filters.country,
        customFilters: this.vendorFields
          .filter(option => option.fieldName == null && filters[option.id] != null)
          .map(option => getCustomFieldVariable(filters[option.id], option)),
      },
      ...includedColumns,
    };

    return (
      <React.Fragment>
        <EventVendorsHeaderBar
          event={event}
          history={history}
          filters={filters}
          filterInputs={variables.filters}
          vendorFields={this.vendorFields}
          onSearch={this.handleSearch}
          shownColumns={shownColumns}
          onColumnsChange={this.handleColumnsChange}
          vendorsCount={this.state.availableVendors.length}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: EventVendorsPageQueryResponse) =>
            this.renderTable({
              event: props.event,
              filtered,
              sort: sortFilter,
              includedColumns,
            })
          }
          renderLoading={() =>
            this.renderTable({
              event: null,
              filtered,
              sort: sortFilter,
              includedColumns,
            })
          }
        />
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  EventVendorsPage,
  graphql`
    fragment EventVendorsPage_org on Org {
      id
      customFields(customizableType: [VENDOR]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ...EventVendorsTablePagination_org
    }

    fragment EventVendorsPage_event on Event {
      id
      ...EventVendorsHeaderBar_event
    }
  `,
);
