/* @flow */
import React from 'react';
import throttle from 'lodash/throttle';

import requestMapsAutocomplete, {
  type MapsAutocompleteResults,
} from 'utils/locations/requestMapsAutocomplete';
import type { GooglePlacePrediction, Location } from 'utils/locations/locationTypes';
import requestMapsPlace from 'utils/locations/requestMapsPlace';
import googlePlaceToLocation from 'utils/locations/googlePlaceToLocation';
import GoogleMapsScriptWrapper from 'components/GoogleMapsScriptWrapper';
import AutocompleteInput from 'components/material/AutocompleteInput';

import LocationAutocompleteRow from './LocationAutocompleteRow';

export default class LocationAutocomplete extends React.PureComponent<
  {
    defaultValue?: string,
    className?: ?string,
    label?: string,
    placeholder?: string,
    onSelectLocation: (location: Location) => any,
    autoFocus?: boolean,
    clearable?: boolean,
    onBlur?: (query: string) => void,
    onToggleQueryState?: (present: boolean) => void,
    error?: ?string,
  },
  {
    predictions: MapsAutocompleteResults,
  },
> {
  state = {
    predictions: [],
  };

  queryExists: boolean;

  fetchAutocomplete = throttle((query: string) => {
    if (!query.trim()) return;

    requestMapsAutocomplete(query)
      .then(predictions => {
        this.setState({
          predictions,
        });
      })
      .catch(error => {
        console.log('request error', error);
      });
    this.checkSearchQueryState(query);
  }, 400);

  checkSearchQueryState = (query?: string = '') => {
    if (query.trim() && !this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(true);
      this.queryExists = true;
    }
    if (!query.trim() && this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(false);
      this.queryExists = false;
    }
  };

  handleSelectRow = (prediction: ?GooglePlacePrediction) => {
    if (prediction != null) {
      requestMapsPlace(prediction.place_id).then(place => {
        const location = googlePlaceToLocation(place);

        this.props.onSelectLocation(location);
      });
    } else {
      this.props.onSelectLocation(null);
    }
  };

  handleRenderOptionString = (prediction: GooglePlacePrediction): string => {
    return prediction.structured_formatting.main_text;
  };

  renderRow = (prediction: GooglePlacePrediction) => {
    return <LocationAutocompleteRow prediction={prediction} />;
  };

  render() {
    return (
      <GoogleMapsScriptWrapper>
        <div className={this.props.className}>
          <AutocompleteInput
            options={this.state.predictions}
            icon="map-marker"
            label={this.props.label || 'Location'}
            placeholder={this.props.placeholder}
            onFilterOptions={this.fetchAutocomplete}
            onSelect={this.handleSelectRow}
            renderOption={this.renderRow}
            renderOptionString={this.handleRenderOptionString}
            autoFocus={this.props.autoFocus}
            onBlur={this.props.onBlur}
            defaultQuery={this.props.defaultValue}
            clearable={this.props.clearable}
            error={this.props.error}
          />
        </div>
      </GoogleMapsScriptWrapper>
    );
  }
}
