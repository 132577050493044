/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import type { FieldType } from 'utils/customization/types';
import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import { defaultTableComponents } from 'components/AllEvents/columns';
import colsConfig, { getColumnsShowConfig } from 'components/AllEvents/eventsTableColumnSettings';
import { type ColumnGroupConfiguration } from 'components/AllEvents/lib/getEventFields';
import { type ParsedAllEventsFilters } from 'components/AllEvents/lib/parseAllEventsFilters';
import { type ColumnType } from 'components/budget/Table';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import AllEventsHeaderBar from '../components/AllEventsHeaderBar';
import AllEventsTablePagination from './AllEventsTablePagination';

import type { AllEventsListPage_eventList } from './__generated__/AllEventsListPage_eventList.graphql';
import type { AllEventsListPage_org } from './__generated__/AllEventsListPage_org.graphql';
import type { AllEventsListPage_user } from './__generated__/AllEventsListPage_user.graphql';
import type { AllEventsListPageQueryResponse } from './__generated__/AllEventsListPageQuery.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const query = graphql`
  query AllEventsListPageQuery(
    $count: Int!
    $readOnly: Boolean!
    $cursor: String
    $filters: EventFilters!
    $includeName: Boolean!
    $includeTeam: Boolean!
    $includeStartDate: Boolean!
    $includeEndDate: Boolean!
    $includeVirtualLocation: Boolean!
    $includeEventFormat: Boolean!
    $includeVenueCity: Boolean!
    $includeVenueCountry: Boolean!
    $includeVenueName: Boolean!
    $includeVenueState: Boolean!
    $includeVenueStreet: Boolean!
    $includeVenueZip: Boolean!
    $includeStatus: Boolean!
    $includeProgress: Boolean!
    $includeWebsite: Boolean!
    $includeLead: Boolean!
    $includeEventStaff: Boolean!
    $includeBooth: Boolean!
    $includeBoothDimensions: Boolean!
    $includeActualTotalCost: Boolean!
    $includePaidTotalCost: Boolean!
    $includePlannedTotalCost: Boolean!
    $includeSalesforceId: Boolean!
    $includeOpportunitiesNumber: Boolean!
    $includeOpportunitiesAmount: Boolean!
    $includeMarketoId: Boolean!
    $includeSyncStatus: Boolean!
    $includeLastSync: Boolean!
    $includeRequestForm: Boolean!
    $includeRequestReviewers: Boolean!
    $includeRequestStatus: Boolean!
    $includeRequestedBy: Boolean!
    $includeRequestedDate: Boolean!
    $includeUpdatedAt: Boolean!
    $includeUpdater: Boolean!
    $includeCreatedAt: Boolean!
    $includeCreator: Boolean!
    $includeId: Boolean!
    $includeBriefWeb: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
    $includeContactsCount: Boolean!
    $includeRegisteredContactsTotal: Boolean!
    $includeAttendedContactsTotal: Boolean!
  ) {
    org {
      ...AllEventsTablePagination_org
    }
    me {
      ...AllEventsTablePagination_me
      ...AllEventsTablePagination_totalCountMe
    }
  }
`;

class AllEventsListPage extends React.PureComponent<
  {
    org: AllEventsListPage_org,
    user: AllEventsListPage_user,
    eventList: ?AllEventsListPage_eventList,
    history: RouterHistory,
    eventFields: $ReadOnlyArray<FieldType>,
    shownColumns: $ReadOnlyArray<string>,
    onChangeShownColumns: (shownColumns: $ReadOnlyArray<string>) => void,
    pathPrefix: string,
    filters: ParsedAllEventsFilters,
    filtered: boolean,
    filterVariables: {},
    groups: ColumnGroupConfiguration,
  },
  {
    selectedEvents: $ReadOnlyArray<string>,
    availableEvents: $ReadOnlyArray<string>,
  },
> {
  state = {
    selectedEvents: [],
    availableEvents: [],
  };

  columnsConfig = getColumnsShowConfig(this.props.eventFields);

  updateTableColumnWidths: ?() => void = null;

  handleUpdateTableColumnWidths = () => {
    if (this.updateTableColumnWidths) this.updateTableColumnWidths();
  };

  setColumnWidthUpdater = (updateColumnWidths: () => void) => {
    this.updateTableColumnWidths = updateColumnWidths;
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  handleEventsListUpdate = (availableEvents: $ReadOnlyArray<string>) => {
    this.setState({ availableEvents });
  };

  handleSelectEvents = (selectedEvents: $ReadOnlyArray<string>): void => {
    this.setState({ selectedEvents });
  };

  getCurrentSelection = (): $ReadOnlyArray<string> => {
    return this.state.availableEvents.filter(item => this.state.selectedEvents.includes(item));
  };

  renderTable = (props: {|
    org: ?$PropertyType<AllEventsListPageQueryResponse, 'org'>,
    me: ?$PropertyType<AllEventsListPageQueryResponse, 'me'>,
    shownColumns: $ReadOnlyArray<ColumnType<any, any>>,
    sort: SortParam,
    includedColumns: { [string]: boolean },
  |}): React.Node => {
    return (
      <AllEventsTablePagination
        org={props.org}
        me={props.me}
        shownColumns={props.shownColumns}
        filtered={this.props.filtered}
        sort={props.sort}
        totalCountMe={props.me}
        onChangeSort={this.handleSortChange}
        includedColumns={props.includedColumns}
        onSelectEvents={this.handleSelectEvents}
        selectedEvents={this.state.selectedEvents}
        setColumnWidthUpdater={this.setColumnWidthUpdater}
        onEventsListUpdate={this.handleEventsListUpdate}
      />
    );
  };

  render() {
    const {
      org,
      user,
      eventList,
      eventFields,
      shownColumns,
      pathPrefix,
      filters,
      filterVariables,
      groups,
    } = this.props;

    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      eventFields,
      shownColumns,
    );

    const shownColumnsConfig = colsConfig({ eventFields, shownColumns });

    const sortFilter = filters.sort || { key: 'START_DATE', asc: true };
    const variables = {
      count: 25,
      readOnly: false,
      ...includedColumns,
      contactFilters: { salesforceSyncOptions: ['lead'] },
      filters: {
        ...filterVariables,
      },
    };

    const currentSelectedEvents = this.getCurrentSelection();

    return (
      <Container>
        <AllEventsHeaderBar
          org={org}
          user={user}
          eventList={eventList}
          pathPrefix={pathPrefix}
          filters={filters}
          eventFields={eventFields}
          fromWindow="list"
          csvExportVariables={variables.filters}
          shownColumns={shownColumns}
          includeDateRange
          viewMode="table"
          allColumns={this.columnsConfig}
          groups={groups}
          currentSelectedEvents={currentSelectedEvents}
          onColumnsChange={this.props.onChangeShownColumns}
          eventsCount={this.state.availableEvents.length}
          onUpdateTableColumnWidths={this.handleUpdateTableColumnWidths}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: AllEventsListPageQueryResponse) =>
            this.renderTable({
              org: props.org,
              me: props.me,
              shownColumns: shownColumnsConfig,
              sort: sortFilter,
              includedColumns,
            })
          }
          renderLoading={() =>
            this.renderTable({
              org: null,
              me: null,
              shownColumns: shownColumnsConfig,
              sort: sortFilter,
              includedColumns,
            })
          }
        />
      </Container>
    );
  }
}

export default createFragmentContainer(AllEventsListPage, {
  org: graphql`
    fragment AllEventsListPage_org on Org {
      ...AllEventsHeaderBar_org
    }
  `,
  user: graphql`
    fragment AllEventsListPage_user on User {
      ...AllEventsHeaderBar_user
    }
  `,
  eventList: graphql`
    fragment AllEventsListPage_eventList on EventList {
      ...AllEventsHeaderBar_eventList
    }
  `,
});
