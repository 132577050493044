/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';

import DateTimePicker, { type DateConfig } from 'components/date/DateTimePicker';
import DateTimeTooltip from 'components/material/DateTimeTooltip';

import InlineEditableCell from './InlineEditableCell';

const Date = styled.div`
  ${props =>
    props.isOverdue &&
    css`
      color: ${props.theme.negativeActionColor};
    `};
`;

export default class InlineDateField extends React.PureComponent<
  {|
    ...$Exact<$PropertyType<DateTimePicker, 'props'>>,
    getError?: ?(value: ?string) => ?string,
    isOverdue?: boolean,
    updateColumnWidth?: () => void,
  |},
  { value: ?string, hideTime: boolean, error: ?string },
> {
  state = { value: this.props.date, hideTime: this.props.hideTime, error: null };

  handleValidate = (): boolean => {
    const { getError, updateColumnWidth } = this.props;
    const error: ?string = getError != null ? getError(this.state.value) : null;
    this.setState(state => (state.error === error ? null : { error }));
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  handleSave = () => {
    this.props.onChange({ date: this.state.value, hideTime: this.state.hideTime });
  };

  handleChange = (dateConfig: DateConfig) => {
    this.setState({ value: dateConfig.date, hideTime: dateConfig.hideTime });
  };

  renderDate() {
    const { date, tz, hideTime, isOverdue } = this.props;
    return hideTime ? (
      <Date isOverdue={isOverdue}>{formatDate(moment(date).tz(tz))}</Date>
    ) : (
      <DateTimeTooltip isOverdue={isOverdue} date={moment.tz(date, tz)} />
    );
  }

  render() {
    const { date, tz } = this.props;
    return (
      <InlineEditableCell
        onSave={this.handleSave}
        error={this.state.error}
        onValidate={this.handleValidate}
      >
        {({ onBlur, editing }) => {
          if (!editing) return date ? this.renderDate() : null;

          return (
            <DateTimePicker
              autoFocus
              onBlur={onBlur}
              overlayContainer={document.body}
              {...this.props}
              onChange={this.handleChange}
              date={this.state.value}
              tz={tz}
              clearable
            />
          );
        }}
      </InlineEditableCell>
    );
  }
}
