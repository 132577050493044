/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';

import TextField from '../../../components/TextField';

import { type EventInfoCustomDate_event } from './__generated__/EventInfoCustomDate_event.graphql';

const EventInfoCustomDate = ({
  name,
  event,
  customFieldId,
}: {
  name: string,
  event: EventInfoCustomDate_event,
  customFieldId: string,
}) => {
  const fieldValue = event.customDateFields.find(value => value.customField.id === customFieldId);

  if (!fieldValue) return null;

  return <TextField name={name} description={formatDate(moment.tz(fieldValue.value, event.tz))} />;
};

export default createFragmentContainer(
  EventInfoCustomDate,
  graphql`
    fragment EventInfoCustomDate_event on Event {
      tz
      customDateFields(customFieldIds: $customFieldIds) {
        value
        customField {
          id
        }
      }
    }
  `,
);
