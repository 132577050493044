/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import SelectField from 'components/material/SelectField';
import TextInput from 'components/material/TextInput';

import type { FolderSelect_folders } from './__generated__/FolderSelect_folders.graphql';

const NewRow = styled.div`
  color: #3baadd;
  border-bottom: 0;
  padding: 5px 10px;
  cursor: pointer;
  i {
    margin-right: 5px;
  }
  ${props =>
    props.focused &&
    `
    background: ${props.theme.hoverRowColor};
  `};
`;

const NewTextInput = styled(TextInput)`
  width: 100%;
  padding: 5px 10px;
`;

class FolderSelect extends React.PureComponent<
  {
    folders: FolderSelect_folders,
    folderId: string,
    onChange: (folderId: ?string) => void,
    onCreate: (name: string) => Promise<string>,
    className?: string,
  },
  { adding: boolean },
> {
  state = { adding: false };

  handleFolderChange = (folderId: ?string) => {
    this.props.onChange(folderId);
  };

  handleNewSelect = () => {
    setTimeout(() => this.setState({ adding: true }), 0);
  };

  handleKeyDown = (e, { onHide, onSelect }) => {
    if (e.key === 'Enter') {
      const name = e.currentTarget.value.trim();
      if (name) {
        onHide();
        this.setState({ adding: false });
        this.props.onCreate(name).then(folderId => onSelect(folderId));
      }
    }
  };

  handleOptionsHide = () => {
    if (this.state.adding) {
      this.setState({ adding: false });
    }
  };

  renderNewRow = options => {
    if (this.state.adding) {
      return (
        <NewTextInput
          key={options.value}
          autoFocus
          name="folder_name"
          placeholder="Create New Section"
          onKeyDown={e =>
            this.handleKeyDown(e, { onHide: options.onHide, onSelect: options.onSelect })
          }
        />
      );
    }
    return (
      <NewRow
        focused={options.focused}
        key={options.value}
        onMouseEnter={() => options.onHover(options.index)}
        onMouseDown={() => options.onSelect(options.value)}
      >
        <i className="fa fa-fw fa-plus" />
        Create New Section
      </NewRow>
    );
  };

  render() {
    const folders = sortBy(this.props.folders.filter(Boolean).slice(), folder => folder.order);
    const options = [
      ...folders.map(folder => ({ value: folder.id, label: folder.name })),
      {
        value: '__new',
        label: '+ Create New Section',
        render: this.renderNewRow,
        onSelect: this.handleNewSelect,
      },
    ];

    return (
      <SelectField
        options={options}
        value={this.props.folderId}
        onChange={this.handleFolderChange}
        placeholder="Add to section"
        className={this.props.className}
        onHideOptions={this.handleOptionsHide}
        clearable
      />
    );
  }
}

export default createFragmentContainer(
  FolderSelect,
  graphql`
    fragment FolderSelect_folders on Folder @relay(plural: true) {
      id
      name
      order
    }
  `,
);
