/* @flow */

import { type AccessibilityOptionType } from './types';

const accessibilityOptions: {
  [AccessibilityOptionType]: { label: string, description: string },
} = {
  PUBLIC: { label: 'Public', description: 'Anyone with the URL can access the form.' },
  SSO: { label: 'SSO', description: 'Only employees at your company can access the form.' },
  USER: { label: 'Members', description: 'Only Members of your Workspace can access the form.' },
};

export default accessibilityOptions;
