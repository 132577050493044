/**
 * @flow
 * @relayHash e47104f8dda145dcd71fd3521eddb1af
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveCompanyFromBriefInput = {
  clientMutationId?: ?string,
  eventId: string,
  companyId: string,
};
export type removeCompanyFromBriefMutationVariables = {|
  input: RemoveCompanyFromBriefInput
|};
export type removeCompanyFromBriefMutationResponse = {|
  +removeCompanyFromBrief: ?{|
    +removedId: string
  |}
|};
export type removeCompanyFromBriefMutation = {|
  variables: removeCompanyFromBriefMutationVariables,
  response: removeCompanyFromBriefMutationResponse,
|};
*/


/*
mutation removeCompanyFromBriefMutation(
  $input: RemoveCompanyFromBriefInput!
) {
  removeCompanyFromBrief(input: $input) {
    removedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveCompanyFromBriefInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeCompanyFromBrief",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveCompanyFromBriefInput!"
      }
    ],
    "concreteType": "RemoveCompanyFromBriefPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeCompanyFromBriefMutation",
  "id": null,
  "text": "mutation removeCompanyFromBriefMutation(\n  $input: RemoveCompanyFromBriefInput!\n) {\n  removeCompanyFromBrief(input: $input) {\n    removedId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeCompanyFromBriefMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeCompanyFromBriefMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '943544269ba9791e945a2d8dd3275cdf';
module.exports = node;
