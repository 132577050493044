/**
 * @flow
 * @relayHash 8ed23811b35526d681f156bfadb52c9b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveDeliverableContactInput = {
  clientMutationId?: ?string,
  deliverableId: string,
  contactId: string,
};
export type removeTaskContactMutationVariables = {|
  input: RemoveDeliverableContactInput
|};
export type removeTaskContactMutationResponse = {|
  +removeDeliverableContact: ?{|
    +removedContactId: string
  |}
|};
export type removeTaskContactMutation = {|
  variables: removeTaskContactMutationVariables,
  response: removeTaskContactMutationResponse,
|};
*/


/*
mutation removeTaskContactMutation(
  $input: RemoveDeliverableContactInput!
) {
  removeDeliverableContact(input: $input) {
    removedContactId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveDeliverableContactInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeDeliverableContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveDeliverableContactInput!"
      }
    ],
    "concreteType": "RemoveDeliverableContactPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedContactId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeTaskContactMutation",
  "id": null,
  "text": "mutation removeTaskContactMutation(\n  $input: RemoveDeliverableContactInput!\n) {\n  removeDeliverableContact(input: $input) {\n    removedContactId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeTaskContactMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeTaskContactMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f7b2d067e813b04ab5209db4837d116';
module.exports = node;
