/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

export const Check = styled.div`
  color: ${props => props.color || props.theme.checkboxCheckColor};
  border: 1px solid currentColor;
  border-radius: 1px;
  width: 15px;
  height: 15px;
  line-height: 13px;
  text-align: center;
  font-size: 10px;
  position: relative;
  top: -1px;
  z-index: 1;
  box-sizing: border-box;
  ${props =>
    !props.checked &&
    css`
      i {
        opacity: 0;
      }
    `};
`;

const Container = styled.div`
  display: flex;
  align-items: baseline;
  user-select: none;
  ${props => (props.disabled ? 'cursor: not-allowed;' : 'cursor: pointer;')} &:hover {
    ${Check} {
      box-shadow: 0 0 0 1px currentColor;
    }
  }
`;

export const Label = styled.div`
  margin-left: 7px;
`;

const CheckIcon = styled.i`
  // Normalizes alignment for Firefox and Chrome
  vertical-align: top;
`;

export const styledComponent = Container;

export default class Checkbox extends React.Component<{
  className?: any,
  label?: ?React.Node,
  checked: boolean,
  disabled?: boolean,
  labelRenderer?: () => React.Node,
  onChange: (checked: boolean, e: SyntheticMouseEvent<HTMLInputElement>) => void,
  checkColor?: ?string,
  indeterminate?: boolean,
}> {
  handleChange = (e: SyntheticMouseEvent<HTMLInputElement>) => {
    if (!this.props.disabled) {
      this.props.onChange(!this.props.checked, e);
    }
  };

  render() {
    const { labelRenderer, checked, checkColor, label } = this.props;
    return (
      <Container
        className={this.props.className}
        onClick={this.handleChange}
        disabled={this.props.disabled}
      >
        <Check checked={checked} color={checkColor}>
          <CheckIcon className={`fa fa-fw fa-${this.props.indeterminate ? 'minus' : 'check'}`} />
        </Check>
        {labelRenderer ? labelRenderer() : label != null && <Label>{label}</Label>}
      </Container>
    );
  }
}
