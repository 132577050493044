/**
 * @flow
 * @relayHash a595a473c4de4543ee50f6765da2272a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamStylesContent_team$ref = any;
export type TeamStylesQueryVariables = {|
  id: string
|};
export type TeamStylesQueryResponse = {|
  +team: ?{|
    +id?: string,
    +$fragmentRefs: TeamStylesContent_team$ref,
  |}
|};
export type TeamStylesQuery = {|
  variables: TeamStylesQueryVariables,
  response: TeamStylesQueryResponse,
|};
*/


/*
query TeamStylesQuery(
  $id: ID!
) {
  team: node(id: $id) {
    __typename
    ... on Team {
      id
      ...TeamStylesContent_team
    }
    id
  }
}

fragment TeamStylesContent_team on Team {
  id
  dbId
  logo
  primaryColor
  secondaryColor
  scheduleName
  font
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamStylesQuery",
  "id": null,
  "text": "query TeamStylesQuery(\n  $id: ID!\n) {\n  team: node(id: $id) {\n    __typename\n    ... on Team {\n      id\n      ...TeamStylesContent_team\n    }\n    id\n  }\n}\n\nfragment TeamStylesContent_team on Team {\n  id\n  dbId\n  logo\n  primaryColor\n  secondaryColor\n  scheduleName\n  font\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamStylesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "team",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Team",
            "selections": [
              v2,
              {
                "kind": "FragmentSpread",
                "name": "TeamStylesContent_team",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamStylesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "team",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "InlineFragment",
            "type": "Team",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dbId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "logo",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "primaryColor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "secondaryColor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "scheduleName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "font",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fe5347e9f754e659e45172acf8c6667e';
module.exports = node;
