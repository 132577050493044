/* @flow */
import styled from 'styled-components';

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 5px;
  box-shadow: 0 -12px 23px #f6f2ff;
  background: #f6f2ff;
  padding-top: 5px;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 27px;
    background: #f6f2ff;
  }
`;

export default Footer;
