/* @flow */
import track from './track';

type Props = {
  eventId: ?number,
  eventName: ?string,
  teamId: ?number,
  teamName: ?string,
  fromWindow: 'event budget' | 'org budget',
};

const downloadedBudget = (props: Props) => {
  track('downloaded_budget', {
    event_id: props.eventId,
    event_name: props.eventName,
    team_id: props.teamId,
    team_name: props.teamName,
    from_window: props.fromWindow,
  });
};

export default downloadedBudget;
