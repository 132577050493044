/**
 * @flow
 * @relayHash ade26198ed9b0b75377894c3899268e1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSelectInputContainer_query$ref = any;
export type UserSelectInputContainerRefetchQueryVariables = {|
  query: string,
  userIds: $ReadOnlyArray<string>,
  hasTeamAccess: boolean,
  eventId: string,
  includeEvent: boolean,
|};
export type UserSelectInputContainerRefetchQueryResponse = {|
  +query: {|
    +$fragmentRefs: UserSelectInputContainer_query$ref
  |}
|};
export type UserSelectInputContainerRefetchQuery = {|
  variables: UserSelectInputContainerRefetchQueryVariables,
  response: UserSelectInputContainerRefetchQueryResponse,
|};
*/


/*
query UserSelectInputContainerRefetchQuery(
  $query: String!
  $userIds: [ID!]!
  $hasTeamAccess: Boolean!
  $eventId: ID!
  $includeEvent: Boolean!
) {
  query {
    ...UserSelectInputContainer_query
  }
}

fragment UserSelectInputContainer_query on Query {
  searchUsers(first: 6, query: $query, hasTeamAccess: $hasTeamAccess) @skip(if: $includeEvent) {
    edges {
      node {
        id
        email
        firstName
        lastName
        ...MaterialAvatar_user
        ...UsersGroup_users
      }
    }
  }
  event: node(id: $eventId) @include(if: $includeEvent) {
    __typename
    ... on Event {
      viewerCanAddStaff
      staffers(first: 3, query: $query) {
        edges {
          node {
            user {
              id
              firstName
              lastName
              email
              ...MaterialAvatar_user
              ...UsersGroup_users
            }
            id
          }
        }
      }
      team {
        users(first: 3, query: $query) {
          edges {
            node {
              id
              firstName
              lastName
              email
              ...MaterialAvatar_user
              ...UsersGroup_users
            }
          }
        }
        id
      }
    }
    id
  }
  activeUsers: nodes(ids: $userIds) {
    __typename
    id
    ... on User {
      id
      email
      firstName
      lastName
      ...MaterialAvatar_user
    }
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}

fragment UsersGroup_users on Assignable {
  id
  email
  firstName
  lastName
  ...MaterialAvatar_user
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userIds",
    "type": "[ID!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hasTeamAccess",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeEvent",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query",
  "type": "String"
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  v7
],
v9 = [
  v2,
  v4,
  v5,
  v3,
  v6
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserSelectInputContainerRefetchQuery",
  "id": null,
  "text": "query UserSelectInputContainerRefetchQuery(\n  $query: String!\n  $userIds: [ID!]!\n  $hasTeamAccess: Boolean!\n  $eventId: ID!\n  $includeEvent: Boolean!\n) {\n  query {\n    ...UserSelectInputContainer_query\n  }\n}\n\nfragment UserSelectInputContainer_query on Query {\n  searchUsers(first: 6, query: $query, hasTeamAccess: $hasTeamAccess) @skip(if: $includeEvent) {\n    edges {\n      node {\n        id\n        email\n        firstName\n        lastName\n        ...MaterialAvatar_user\n        ...UsersGroup_users\n      }\n    }\n  }\n  event: node(id: $eventId) @include(if: $includeEvent) {\n    __typename\n    ... on Event {\n      viewerCanAddStaff\n      staffers(first: 3, query: $query) {\n        edges {\n          node {\n            user {\n              id\n              firstName\n              lastName\n              email\n              ...MaterialAvatar_user\n              ...UsersGroup_users\n            }\n            id\n          }\n        }\n      }\n      team {\n        users(first: 3, query: $query) {\n          edges {\n            node {\n              id\n              firstName\n              lastName\n              email\n              ...MaterialAvatar_user\n              ...UsersGroup_users\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n  activeUsers: nodes(ids: $userIds) {\n    __typename\n    id\n    ... on User {\n      id\n      email\n      firstName\n      lastName\n      ...MaterialAvatar_user\n    }\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n\nfragment UsersGroup_users on Assignable {\n  id\n  email\n  firstName\n  lastName\n  ...MaterialAvatar_user\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserSelectInputContainerRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserSelectInputContainer_query",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSelectInputContainerRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "activeUsers",
            "name": "nodes",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "userIds",
                "type": "[ID!]!"
              }
            ],
            "concreteType": null,
            "plural": true,
            "selections": [
              v1,
              v2,
              {
                "kind": "InlineFragment",
                "type": "User",
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6
                ]
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": false,
            "condition": "includeEvent",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "searchUsers",
                "storageKey": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 6,
                    "type": "Int"
                  },
                  {
                    "kind": "Variable",
                    "name": "hasTeamAccess",
                    "variableName": "hasTeamAccess",
                    "type": "Boolean"
                  },
                  v7
                ],
                "concreteType": "UserRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v2,
                          v3,
                          v4,
                          v5,
                          v6
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "includeEvent",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "event",
                "name": "node",
                "storageKey": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "eventId",
                    "type": "ID!"
                  }
                ],
                "concreteType": null,
                "plural": false,
                "selections": [
                  v1,
                  v2,
                  {
                    "kind": "InlineFragment",
                    "type": "Event",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanAddStaff",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "staffers",
                        "storageKey": null,
                        "args": v8,
                        "concreteType": "StaffMembershipRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffMembershipRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "StaffMembership",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "user",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "plural": false,
                                    "selections": v9
                                  },
                                  v2
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "team",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Team",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "users",
                            "storageKey": null,
                            "args": v8,
                            "concreteType": "UserRequiredConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserRequiredEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "plural": false,
                                    "selections": v9
                                  }
                                ]
                              }
                            ]
                          },
                          v2
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4bf1bed0291bb0ebfb927028dfecd29c';
module.exports = node;
