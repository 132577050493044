/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type { TasksFromWindow } from './createTasks';

type Fields = {|
  dueDate?: ?string,
  dueDateAllDay?: ?boolean,
  name?: string,
  description?: ?string,
  remindAt?: ?string,
|};

const mutation = graphql`
  mutation updateTasksMutation($input: UpdateDeliverablesInput!) {
    updateDeliverables(input: $input) {
      deliverables {
        id
        name
        dueDate
        dueDateAllDay
        description
        remindAt
      }
    }
  }
`;

export default function updateTasks(taskIds: string[], obj: Fields, fromWindow: TasksFromWindow) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        fromWindow,
        deliverableIds: taskIds,
        ...obj,
      },
    },
    optimisticResponse: {
      updateDeliverables: {
        deliverables: taskIds.map(taskId => ({ id: taskId, ...obj })),
      },
    },
  });
}
