/* @flow */
import React from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment-timezone';

import syncStatuses from 'config/syncStatuses.json';

import formatDateTime from 'utils/date/formatDateTime';

import forcePullCampaign from 'graph/mutations/salesforce/forcePullCampaign';
import showModernMutationError from 'graph/utils/showModernMutationError';

import FailedIcon from 'images/syncIcons/failedIcon.svg';
import PendingIcon from 'images/syncIcons/pendingIcon.svg';
import SyncedIcon from 'images/syncIcons/syncedIcon.svg';
import Button from 'components/material/Button';

import type { SalesforceSyncStatusInfo_event } from './__generated__/SalesforceSyncStatusInfo_event.graphql';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px;
  background: #f3fafd;
  color: #4a5665;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 4px 0;
  svg {
    width: 14px;
    height: 14px;
    margin: 0 5px;
  }
`;

const Col = styled.div`
  min-width: ${props => props.minWidth || 0}px;
  margin-right: 30px;
  word-break: break-word;
`;

const Main = styled.div`
  flex: 1 1 335px;
`;

const Sidebar = styled.div`
  flex: 0 0 110px;
  margin-left: 20px;
  @media (max-width: 1060px) {
    margin-left: 0px;
    margin-top: 20px;
  }
`;

class SalesforceSyncStatusInfo extends React.PureComponent<{
  event: SalesforceSyncStatusInfo_event,
  relay: RelayRefetchProp,
}> {
  refetchTimeout: TimeoutID;

  componentDidMount() {
    this.checkEventSynced();
  }

  componentDidUpdate() {
    this.checkEventSynced();
  }

  componentWillUnmount() {
    clearTimeout(this.refetchTimeout);
  }

  handleForcePull = () => {
    forcePullCampaign({ eventId: this.props.event.id }).catch(showModernMutationError);
  };

  checkEventSynced = () => {
    const { pullStatus, pushStatus } = this.props.event;
    if (!pullStatus || !pushStatus) return;
    clearTimeout(this.refetchTimeout);
    if (pullStatus.state === 'PENDING' || pushStatus.state === 'PENDING') {
      this.refetchTimeout = setTimeout(() => {
        this.props.relay.refetch();
        this.checkEventSynced();
      }, 2000);
    }
  };

  renderStatusIcon = (status: string) => {
    switch (status) {
      case 'OK':
        return <SyncedIcon />;
      case 'PENDING':
        return <PendingIcon />;
      // case 'FAILING':
      default:
        return <FailedIcon />;
    }
  };

  render() {
    const { pullStatus, pushStatus, tz } = this.props.event;

    if (!pullStatus || !pushStatus) return null;

    return (
      <Container>
        <Main>
          <Row>
            <Col minWidth={130}>
              Push:{' '}
              <strong>
                {this.renderStatusIcon(pushStatus.state)} {syncStatuses[pushStatus.state]}
              </strong>
            </Col>
            {pushStatus.lastSuccessAt && (
              <Col>
                Last Push:{' '}
                <strong>{formatDateTime(moment.tz(pushStatus.lastSuccessAt, tz))}</strong>
              </Col>
            )}
          </Row>
          {pushStatus.errorMessage && (
            <Row>
              <Col>
                Push Error: <strong>{pushStatus.errorMessage}</strong>
              </Col>
            </Row>
          )}
          <Row>
            <Col minWidth={130}>
              Pull:{' '}
              <strong style={{ marginLeft: '9px' }}>
                {this.renderStatusIcon(pullStatus.state)} {syncStatuses[pullStatus.state]}
              </strong>
            </Col>
            {pullStatus.lastSuccessAt && (
              <Col>
                Last Pull:{' '}
                <strong>{formatDateTime(moment.tz(pullStatus.lastSuccessAt, tz))}</strong>
              </Col>
            )}
          </Row>
          {pullStatus.errorMessage && (
            <Row>
              <Col>
                Pull Error: <strong>{pullStatus.errorMessage}</strong>
              </Col>
            </Row>
          )}
        </Main>
        <Sidebar>
          <Button
            label="Pull Now"
            primary
            outline
            disabled={pullStatus.state === 'PENDING'}
            onClick={this.handleForcePull}
          />
        </Sidebar>
      </Container>
    );
  }
}

export default createRefetchContainer(
  SalesforceSyncStatusInfo,
  graphql`
    fragment SalesforceSyncStatusInfo_event on Event {
      id
      tz
      pullStatus {
        lastSuccessAt
        state
        errorMessage
      }
      pushStatus {
        lastSuccessAt
        state
        errorMessage
      }
    }
  `,
  graphql`
    query SalesforceSyncStatusInfoQuery($eventSlug: String!) {
      event(slug: $eventSlug) {
        ...CRM_event
      }
    }
  `,
);
