/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import TextInput from 'components/material/TextInput';

import ErrorMessage from '../../form/ErrorMessage';
import HelperText from '../../form/HelperText';
import inputBoxStyled from '../../form/inputBoxStyled';
import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../../lib/types';
import QuestionSelectOptionRow from '../QuestionSelectOptions/components/QuestionSelectOptionRow';
import QuestionUserSelectOptionRow from './QuestionUserSelectOptionRow';

const Component = styled.div`
  margin: 10px 0 0 0;
  max-height: 280px;
  min-height: 50px;
  overflow-y: scroll;
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(
      to bottom,
      ${props => (props.error ? '#fff5f5' : '#fff')},
      rgba(255, 255, 255, 0.001)
    );
    pointer-events: none;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(
      to top,
      ${props => (props.error ? '#fff5f5' : '#fff')},
      rgba(255, 255, 255, 0.001)
    );
    pointer-events: none;
  }
`;

const ComponentWrapper = styled.div`
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

export default class QuestionUserSelectOptions extends React.Component<
  {
    question: QuestionType,
    value: QuestionValueType,
    onChangeValue: QuestionValueInputType => void,
    readOnly: boolean,
    error: ?string,
  },
  {
    keyword: string,
  },
> {
  state = {
    keyword: '',
  };

  isMultiselect = () =>
    this.props.question.mappingCustomField &&
    (this.props.question.mappingCustomField.kind === 'MULTISELECT' ||
      this.props.question.mappingCustomField.kind === 'USER_MULTISELECT');

  filteredOptions = () =>
    this.props.question.selectUsers.filter(
      user =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(this.state.keyword) ||
        (this.props.value && this.props.value.selectIdsValue.includes(user.id)),
    );

  handleOptionChange = (optionId: string, checked: boolean) => {
    if (this.props.readOnly) return;

    const selectIdsValue = this.props.value ? this.props.value.selectIdsValue : [];

    if (this.isMultiselect()) {
      this.props.onChangeValue({
        selectIdsValue: checked
          ? [...selectIdsValue, optionId]
          : selectIdsValue.filter(id => id !== optionId),
      });
    } else {
      this.props.onChangeValue({ selectIdsValue: optionId !== 'none' ? [optionId] : [] });
    }
  };

  handleSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { keyword } = this.state;

    if (keyword !== e.currentTarget.value.trim()) {
      this.setState({ keyword: e.currentTarget.value.trim().toLowerCase() });
    }
  };

  render() {
    const { question, value, error } = this.props;
    const filteredOptions = this.filteredOptions();
    const selectIdsValue = value ? value.selectIdsValue : [];

    return (
      <React.Fragment>
        {question.selectUsers.length > 10 && (
          <StyledTextInput placeholder="Search..." onChange={this.handleSearchChange} />
        )}
        {question.description && <HelperText>{question.description}</HelperText>}
        <ComponentWrapper>
          <Component error={error}>
            {question.required === false && !this.isMultiselect() && (
              <QuestionSelectOptionRow
                type="radio"
                checked={
                  selectIdsValue.length === 0 && (!value || value.selectIdsValue.length === 0)
                }
                option={{ id: 'none', name: 'None' }}
                onChange={this.handleOptionChange}
              />
            )}
            {sortBy(filteredOptions, user =>
              `${user.firstName} ${user.lastName}`.toLowerCase(),
            ).map(user => (
              <QuestionUserSelectOptionRow
                key={user.id}
                type={this.isMultiselect() ? 'checkbox' : 'radio'}
                checked={selectIdsValue.includes(user.id)}
                user={user}
                onChange={this.handleOptionChange}
              />
            ))}
          </Component>
        </ComponentWrapper>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </React.Fragment>
    );
  }
}
