/* @flow */
import { graphql } from 'react-relay/compat';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  addUserToTeamMutationResponse,
  addUserToTeamMutationVariables,
} from './__generated__/addUserToTeamMutation.graphql';

const mutation = graphql`
  mutation addUserToTeamMutation($input: AddUserToTeamInput!) {
    addUserToTeam(input: $input) {
      user {
        hasTeamAccess
      }
      org {
        subscription {
          fatmLimit
          fatmCount
        }
      }
      userTeamMembershipEdges {
        node {
          id
        }
        ...TeamName_membershipEdge
        ...TeamDateAdded_membershipEdge
        ...TeamMembershipActionsColumn_membershipEdge
      }
      userEventMembershipEdges {
        node {
          id
          ...EventAccessLevel_staffedEvent
          ...EventDate_staffedEvent
          ...EventDateAdded_staffedEvent
          ...EventLocation_staffedEvent
          ...EventName_staffedEvent
          ...EventOnsite_staffedEvent
          ...EventActionsColumn_staffedEvent
        }
      }
    }
  }
`;

export default function addUserToTeam(
  userId: string,
  teamIds: $ReadOnlyArray<string>,
): Promise<addUserToTeamMutationResponse> {
  const variables: addUserToTeamMutationVariables = {
    input: {
      userId,
      teamIds,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: userId,
      connections: [{ field: 'memberships' }, { key: 'UserTeams_memberships' }],
      edgeName: 'userTeamMembershipEdges',
    }),
  });
}
