/* @flow */
import React from 'react';
import { type RouterHistory, withRouter } from 'react-router';
import styled from 'styled-components';

import type { SortParam } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import SortableHeader from 'components/material/SortableHeader';
import type { ColumnConfiguration } from 'components/material/table';

const TableHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  min-width: ${props => props.minWidth}px;
  padding-right: 35px;
  background: ${props => props.theme.secondaryRowColor};
  border-bottom: 1px solid ${props => props.theme.primaryRowColor};
`;

class ImportLogsTableHeader extends React.PureComponent<{
  sort: SortParam,
  history: RouterHistory,
  columns: ColumnConfiguration,
  minWidth: number,
}> {
  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  render() {
    return (
      <TableHeader minWidth={this.props.minWidth}>
        <SortableHeader
          cols={this.props.columns}
          light
          sort={this.props.sort}
          onChange={this.handleSortChange}
        />
      </TableHeader>
    );
  }
}

export default withRouter(ImportLogsTableHeader);
