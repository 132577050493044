/* @flow */
import * as React from 'react';
import {
  type ConnectDragPreview,
  type ConnectDragSource,
  type ConnectDropTarget,
  DragSource as dragSource,
  DropTarget as dropTarget,
} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { findDOMNode } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import theme from 'config/theme';

import assignTasks from 'graph/mutations/task/assignTasks';
import removeTasks from 'graph/mutations/task/removeTasks';
import unassignTasks from 'graph/mutations/task/unassignTasks';
import updateTasks from 'graph/mutations/task/updateTasks';
import updateTasksStatus from 'graph/mutations/task/updateTasksStatus';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import Checkbox from 'components/material/CheckBox';
import { styledComponent as emptyLinkComponent } from 'components/material/table/EmptyLink';
import TaskRowAssignees from 'components/Tasks/TaskRowAssignees';
import TaskRowDate from 'components/Tasks/TaskRowDate';

import SubtaskInput from './SubtaskInput';

import type { SubtaskRow_subtask } from './__generated__/SubtaskRow_subtask.graphql';

const Remove = styled.div`
  font-size: 14px;
  margin-left: 10px;
  color: ${props => props.theme.secondaryActionColor};
  cursor: pointer;
  visibility: hidden;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const CheckboxStyled = styled(Checkbox)`
  height: inherit;
  display: flex;
  flex-basis: 40px;
  align-self: flex-start;
  margin-top: 8px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  padding-right: 5px;
  min-height: 30px;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
  ${emptyLinkComponent} {
    visibility: hidden;
    font-size: 12px;
  }
  &:hover {
    background: ${props => props.theme.hoverRowColor};
    ${emptyLinkComponent}, ${Remove} {
      visibility: visible;
    }
  }
  ${props =>
    props.dragging &&
    `
    background: ${props.theme.primaryRowColor};
    * {
      display: none;
    }
  `};
`;

const Name = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  ${props =>
    props.completed &&
    `
    color: ${props.theme.rowSecondaryTextColor};
    text-decoration: line-through;

  `};
  &:hover {
    white-space: normal;
    text-overflow: inherit;
    overflow: auto;
  }
`;

const AssigneesRow = styled(TaskRowAssignees)`
  flex-basis: 60px;
  @media (${props => props.theme.mobileOnly}) {
    flex-basis: auto;
  }
`;

class SubtaskRow extends React.PureComponent<
  {
    subtask: SubtaskRow_subtask,
    taskId: string,
    eventId: string,
    connectDragPreview: ConnectDragPreview,
    connectDragSource: ConnectDragSource,
    connectDropTarget: ConnectDropTarget,
    isDragging: boolean,
    tz: string,
    disabled?: boolean,
  },
  {
    editing: boolean,
    showConfirmation: boolean,
  },
> {
  state = {
    editing: false,
    showConfirmation: false,
  };

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  handleToggle = (complete: boolean) => {
    updateTasksStatus([this.props.subtask.id], complete ? 'COMPLETED' : 'OPEN', 'task').catch(
      showModernMutationError,
    );
  };

  handleRemove = () => {
    removeTasks([this.props.subtask.id], {
      parentId: this.props.taskId,
      eventId: this.props.eventId,
      fromWindow: 'task',
    }).catch(showModernMutationError);
  };

  handleNameChange = name => {
    updateTasks([this.props.subtask.id], { name }, 'task').catch(showModernMutationError);
    this.handleToggleAdding();
  };

  handleToggleAdding = () => {
    if (!this.props.disabled && this.props.subtask.viewerCanUpdateDetails) {
      this.setState(state => ({
        editing: !state.editing,
      }));
    }
  };

  handleAssign = userId => {
    assignTasks([this.props.subtask.id], userId, 'task').catch(showModernMutationError);
  };

  handleUnassign = userId => {
    unassignTasks([this.props.subtask.id], userId, 'task').catch(showModernMutationError);
  };

  handleDateChange = ({ date, allDay }) => {
    updateTasks([this.props.subtask.id], { dueDate: date, dueDateAllDay: allDay }, 'task').catch(
      showModernMutationError,
    );
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    const { isDragging, connectDragSource, connectDropTarget } = this.props;

    const complete = this.props.subtask.status === 'COMPLETED';

    if (this.state.editing) {
      return (
        <SubtaskInput
          defaultValue={this.props.subtask.name}
          onCancel={this.handleToggleAdding}
          onSave={this.handleNameChange}
        />
      );
    }

    const component = (
      <Container dragging={isDragging}>
        <CheckboxStyled
          checkColor={complete ? theme.highlightColor : null}
          checked={complete}
          onChange={this.handleToggle}
          disabled={!this.props.subtask.viewerCanUpdateStatus}
        />
        <Name completed={complete} onClick={this.handleToggleAdding}>
          {this.props.subtask.name}
        </Name>
        <TaskRowDate
          date={this.props.subtask.dueDate}
          allDay={this.props.subtask.dueDate ? this.props.subtask.dueDateAllDay : null}
          tz={this.props.tz}
          onChange={this.handleDateChange}
          completed={complete}
          disabled={!this.props.subtask.viewerCanUpdateDueDate}
        />
        <AssigneesRow
          eventId={this.props.eventId}
          assignees={this.props.subtask.assignees.edges.map(edge => edge.node)}
          onAssign={this.handleAssign}
          onUnassign={this.handleUnassign}
          maxCount={2}
          avatarRadius={11}
          disabled={!this.props.subtask.viewerCanAssign}
        />
        {this.props.subtask.viewerCanDelete && (
          <Remove onClick={this.handleShowConfirmation}>
            <i className="fa fa-fw fa-trash" />
          </Remove>
        )}

        {this.state.showConfirmation && (
          <ConfirmationWindow
            onHide={this.handleHideConfirmation}
            onConfirm={this.handleRemove}
            title="Are you sure?"
            message="Once you delete this Subtask, it's gone for good."
          />
        )}
      </Container>
    );

    const connectedTarget = connectDropTarget(<div>{component}</div>) || <div />;

    return connectDragSource(connectedTarget);
  }
}

const subtaskSource = {
  beginDrag(props, monitor, component) {
    props.onMoveStart(props.subtask.id, props.subtask.order);
    // eslint-disable-next-line react/no-find-dom-node
    const el = findDOMNode(component);
    return {
      subtaskId: props.subtask.id,
      previewHTML: el && typeof el.innerHTML === 'string' && el.innerHTML,
      width: el && typeof el.clientWidth === 'number' && el.clientWidth,
    };
  },
  endDrag(props) {
    props.onMoveEnd();
  },
};

const subtaskTarget = {
  hover(props, monitor) {
    const hoverSubtask = props.subtask;
    const dragId = monitor.getItem().subtaskId;
    const differentSubtask = dragId !== hoverSubtask.id;

    if (differentSubtask) {
      props.onMove(dragId, hoverSubtask.order);
    }
  },
};

export default createFragmentContainer(
  dropTarget('SUBTASK', subtaskTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  }))(
    dragSource('SUBTASK', subtaskSource, (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
      connectDragPreview: connect.dragPreview(),
    }))(SubtaskRow),
  ),
  {
    subtask: graphql`
      fragment SubtaskRow_subtask on Deliverable {
        id
        name
        status
        order
        dueDate
        dueDateAllDay
        viewerCanUpdateDetails
        viewerCanUpdateStatus
        viewerCanUpdateDueDate
        viewerCanAssign
        viewerCanDelete
        assignees {
          edges {
            node {
              ...TaskRowAssignees_assignees
            }
          }
        }
      }
    `,
  },
);
