/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SubmissionReviewComments_submission$ref = any;
type SubmissionReviewStatus_submission$ref = any;
export type EventRequestSubmissionStatus = "APPROVED" | "ARCHIVED" | "DECLINED" | "NEW" | "RESUBMITTED" | "RETURNED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubmissionReviewSidebar_submission$ref: FragmentReference;
export type SubmissionReviewSidebar_submission = {|
  +id: string,
  +status: EventRequestSubmissionStatus,
  +viewerCanUpdate: boolean,
  +declineReason: ?{|
    +id: string
  |},
  +event: {|
    +startDate: ?any,
    +team: {|
      +id: string
    |},
    +lead: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
      +email: string,
      +avatar: ?string,
    |},
  |},
  +form: {|
    +eventTemplate: ?{|
      +id: string,
      +name: string,
      +startDate: ?any,
      +team: {|
        +name: string,
        +id: string,
      |},
    |},
    +copyTimeline: boolean,
  |},
  +$fragmentRefs: SubmissionReviewStatus_submission$ref & SubmissionReviewComments_submission$ref,
  +$refType: SubmissionReviewSidebar_submission$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startDate",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SubmissionReviewSidebar_submission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "declineReason",
      "storageKey": null,
      "args": null,
      "concreteType": "DeclineReason",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        v2,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "team",
          "storageKey": null,
          "args": null,
          "concreteType": "Team",
          "plural": false,
          "selections": v1
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "lead",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "avatar",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "form",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestForm",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "eventTemplate",
          "storageKey": null,
          "args": null,
          "concreteType": "Event",
          "plural": false,
          "selections": [
            v0,
            v3,
            v2,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "team",
              "storageKey": null,
              "args": null,
              "concreteType": "Team",
              "plural": false,
              "selections": [
                v3,
                v0
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "copyTimeline",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "SubmissionReviewStatus_submission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SubmissionReviewComments_submission",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '84569100ca610793b6db20d832ff4835';
module.exports = node;
