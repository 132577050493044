/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import formatDateRange from 'utils/date/formatDateRange';
import formatLocation from 'utils/locations/formatLocation';
import type { Event } from './index';

const Row = styled.div`
  width: 100%;
  padding: 8px 15px;
  color: ${props => props.theme.rowPrimaryTextColor};
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  line-height: 1.2;
  ${props =>
    props.focused &&
    css`
      background: ${props.theme.hoverRowColor};
    `};
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

const RowLabel = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #54606d;
  &:first-child:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const RowInfo = styled(RowLabel)`
  color: #8b96a0;
`;

export default class EventOptionRow extends React.PureComponent<{
  event: Event,
  onSelect: (value: string) => void,
  focused: boolean,
  index: number,
  onHover: number => void,
}> {
  handleClick = () => {
    this.props.onSelect(this.props.event.id);
  };

  handleHover = () => {
    this.props.onHover(this.props.index);
  };

  render() {
    const event = this.props.event;
    return (
      <Row onClick={this.handleClick} focused={this.props.focused} onMouseEnter={this.handleHover}>
        <RowLabel>{event.name}</RowLabel>
        {event.startDate != null && (
          <RowInfo>
            {formatDateRange(
              moment.tz(event.startDate, event.tz),
              event.endDate && moment.tz(event.endDate, event.tz),
            )}
          </RowInfo>
        )}
        {event.primaryLocation != null && (
          <RowInfo>{formatLocation(event.primaryLocation)}</RowInfo>
        )}
      </Row>
    );
  }
}
