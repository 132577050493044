/**
 * @flow
 * @relayHash c4faac84c32360014615fafe3d1a0c58
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserContactFilterContainer_query$ref = any;
export type ContactSaveMethod = "api" | "g2w" | "import" | "manual" | "past_g2w" | "past_zoom" | "registration" | "zapier" | "zoom";
export type ContactSort = "ATTENDANCE_DURATION" | "ATTENDANCE_JOIN_TIME" | "ATTENDANCE_LEAVE_TIME" | "ATTENDANCE_STATUS_ID" | "CITY" | "COMPANY_ID" | "CONTACT_TYPE_ID" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CREATED_METHOD" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "EMAIL_OPT_IN" | "EVENTS" | "HOT_LEAD" | "LAST_SYNCED" | "LINKEDIN" | "MARKETO_ID" | "MEMBERSHIP_LAST_SYNCED" | "MEMBERSHIP_SYNC_STATUS" | "NAME" | "OWNER_ID" | "PHONE1" | "PHONE2" | "REGISTERED_AT" | "REGISTRATION_STATUS_ID" | "REQUEST_SUBMISSIONS" | "SALESFORCE_ID" | "SALESFORCE_LEAD_STATUS_ID" | "SALESFORCE_SYNC_AS" | "STATE" | "STREET" | "SYNC_STATUS" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "UPDATED_METHOD" | "WEBSITE" | "ZIP";
export type ContactSyncOption = "contact" | "lead";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type ContactFilters = {
  query?: ?string,
  search?: ?Search,
  contactIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  updatedMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactTypeIds?: ?$ReadOnlyArray<string>,
  ownerIds?: ?$ReadOnlyArray<string>,
  senderIds?: ?$ReadOnlyArray<string>,
  salesforceSyncOptions?: ?$ReadOnlyArray<ContactSyncOption>,
  leadStatusIds?: ?$ReadOnlyArray<string>,
  sentAt?: ?DateTimeRange,
  emailOptIn?: ?boolean,
  hotLead?: ?boolean,
  registeredAt?: ?DateTimeRange,
  attendanceStatusIds?: ?$ReadOnlyArray<string>,
  registrationStatusIds?: ?$ReadOnlyArray<string>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  membershipSyncStatuses?: ?$ReadOnlyArray<SyncState>,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?ContactSort,
  direction?: ?Direction,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type UserContactFilterContainerRefetchQueryVariables = {|
  query: string,
  userIds: $ReadOnlyArray<string>,
  contactIds: $ReadOnlyArray<string>,
  contactFilters: ContactFilters,
|};
export type UserContactFilterContainerRefetchQueryResponse = {|
  +query: {|
    +$fragmentRefs: UserContactFilterContainer_query$ref
  |}
|};
export type UserContactFilterContainerRefetchQuery = {|
  variables: UserContactFilterContainerRefetchQueryVariables,
  response: UserContactFilterContainerRefetchQueryResponse,
|};
*/


/*
query UserContactFilterContainerRefetchQuery(
  $query: String!
  $userIds: [ID!]!
  $contactIds: [ID!]!
  $contactFilters: ContactFilters!
) {
  query {
    ...UserContactFilterContainer_query
  }
}

fragment UserContactFilterContainer_query on Query {
  searchUsers(first: 15, query: $query) {
    edges {
      node {
        id
        email
        firstName
        lastName
        ...MaterialAvatar_user
      }
    }
  }
  selectedUsers: nodes(ids: $userIds) {
    __typename
    id
    ... on User {
      id
      email
      firstName
      lastName
      ...MaterialAvatar_user
    }
  }
  org {
    contacts(first: 15, filters: $contactFilters) {
      edges {
        node {
          id
          email
          firstName
          lastName
          ...MaterialAvatar_user
        }
      }
    }
    id
  }
  selectedContacts: nodes(ids: $contactIds) {
    __typename
    id
    ... on Contact {
      id
      email
      firstName
      lastName
      ...MaterialAvatar_user
    }
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userIds",
    "type": "[ID!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contactIds",
    "type": "[ID!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contactFilters",
    "type": "ContactFilters!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 15,
  "type": "Int"
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v7 = [
  v2,
  v3,
  v4,
  v5,
  v6
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v9 = [
  v3,
  v4,
  v5,
  v6
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserContactFilterContainerRefetchQuery",
  "id": null,
  "text": "query UserContactFilterContainerRefetchQuery(\n  $query: String!\n  $userIds: [ID!]!\n  $contactIds: [ID!]!\n  $contactFilters: ContactFilters!\n) {\n  query {\n    ...UserContactFilterContainer_query\n  }\n}\n\nfragment UserContactFilterContainer_query on Query {\n  searchUsers(first: 15, query: $query) {\n    edges {\n      node {\n        id\n        email\n        firstName\n        lastName\n        ...MaterialAvatar_user\n      }\n    }\n  }\n  selectedUsers: nodes(ids: $userIds) {\n    __typename\n    id\n    ... on User {\n      id\n      email\n      firstName\n      lastName\n      ...MaterialAvatar_user\n    }\n  }\n  org {\n    contacts(first: 15, filters: $contactFilters) {\n      edges {\n        node {\n          id\n          email\n          firstName\n          lastName\n          ...MaterialAvatar_user\n        }\n      }\n    }\n    id\n  }\n  selectedContacts: nodes(ids: $contactIds) {\n    __typename\n    id\n    ... on Contact {\n      id\n      email\n      firstName\n      lastName\n      ...MaterialAvatar_user\n    }\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserContactFilterContainerRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserContactFilterContainer_query",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserContactFilterContainerRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "searchUsers",
            "storageKey": null,
            "args": [
              v1,
              {
                "kind": "Variable",
                "name": "query",
                "variableName": "query",
                "type": "String"
              }
            ],
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": v7
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "selectedUsers",
            "name": "nodes",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "userIds",
                "type": "[ID!]!"
              }
            ],
            "concreteType": null,
            "plural": true,
            "selections": [
              v8,
              v2,
              {
                "kind": "InlineFragment",
                "type": "User",
                "selections": v9
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contacts",
                "storageKey": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "filters",
                    "variableName": "contactFilters",
                    "type": "ContactFilters"
                  },
                  v1
                ],
                "concreteType": "ContactRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Contact",
                        "plural": false,
                        "selections": v7
                      }
                    ]
                  }
                ]
              },
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "selectedContacts",
            "name": "nodes",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "contactIds",
                "type": "[ID!]!"
              }
            ],
            "concreteType": null,
            "plural": true,
            "selections": [
              v8,
              v2,
              {
                "kind": "InlineFragment",
                "type": "Contact",
                "selections": v9
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '362fc34ec065282ca7b295403c72e8fc';
module.exports = node;
