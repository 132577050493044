/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { OpportunityOwner_opportunity } from './__generated__/OpportunityOwner_opportunity.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 17px;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

class OpportunityOwner extends React.Component<{
  opportunity: OpportunityOwner_opportunity,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  render() {
    const owner = this.props.opportunity.owner;
    return (
      <Container>
        <MaterialAvatar radius={13} user={owner} />
        <TextWrapper>{fullNameOfUser(owner)}</TextWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(
  OpportunityOwner,
  graphql`
    fragment OpportunityOwner_opportunity on SalesforceOpportunityType {
      owner {
        firstName
        lastName
        email
        ...MaterialAvatar_user
      }
    }
  `,
);
