/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const WindowContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 888;
  cursor: initial;
`;

export const WindowScroll = styled.div`
  flex: 1 1 auto;
  display: flex;
  overflow-y: auto;
  padding: 50px 0;
  @media (${props => props.theme.mobileOnly}) {
    display: block;
    padding: 10px;
  }
`;

const WindowOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.overlayBackgroundColor};
`;

const WindowContents = styled.div`
  margin: auto;
  background: ${props => props.theme.windowBackgroundColor};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
  max-width: ${props => props.width}px;
  width: 100%;
  z-index: 1000;
  transition: 0.3s max-width;
  line-height: 1.6;
`;

const WINDOW_SIZES = {
  xxxlarge: 990,
  xxlarge: 850,
  xlarge: 740,
  large: 690,
  medium: 514,
  small: 400,
};

export type WindowSizes = $Keys<typeof WINDOW_SIZES>;

export default class Window extends React.PureComponent<{
  children: React.Node,
  onHide: ?() => void,
  className?: string,
  size: WindowSizes | number,
  onClick?: (SyntheticEvent<HTMLDivElement>) => void,
  flagClass?: string,
}> {
  static defaultProps = { size: 'large' };

  render() {
    const { flagClass, onHide, onClick, className, size, children } = this.props;
    return (
      <WindowContainer onClick={onClick} className={flagClass}>
        {onHide && <WindowOverlay onClick={onHide} />}
        <WindowScroll className="popupWindow">
          <WindowContents className={className} width={WINDOW_SIZES[size] || size}>
            {children}
          </WindowContents>
        </WindowScroll>
      </WindowContainer>
    );
  }
}

export { default as WindowClose } from './WindowClose';
export { default as WindowContent } from './WindowContent';
export { default as WindowHeader } from './WindowHeader';
export { default as WindowSubtitle } from './WindowSubtitle';
export { default as WindowTitle } from './WindowTitle';
