/* @flow */

import type { Location } from 'react-router-dom';

import { type DateRangeParam } from 'utils/routing/parseTypedQueryString';

import { type ColumnType } from 'components/budget/Table';

import DeliverableActionCell from './DeliverableActionCell';
import DeliverableAssignees from './DeliverableAssignees';
import DeliverableDueDate from './DeliverableDueDate';
import DeliverableEventName from './DeliverableEventName';
import DeliverableStatus from './DeliverableStatus';
import DeliverableTags from './DeliverableTags';
import DeliverableTitle from './DeliverableTitle';

export type TasksFiltersInputType = {
  tagIds: ?$ReadOnlyArray<string>,
  statuses: ?$ReadOnlyArray<string>,
  assigneeIds: ?$ReadOnlyArray<string>,
  dueDate: ?DateRangeParam,
  teamIds: ?$ReadOnlyArray<string>,
  eventIds: ?$ReadOnlyArray<string>,
  sort: string,
  direction: 'ASC' | 'DESC',
  query: ?string,
};

export type DeliverablesCellPropsType<Deliverable: {}, Me: ?{} = {}, Org: ?{} = {}> = {|
  deliverable: Deliverable,
  org?: ?Org,
  me?: ?Me,
  location: Location,
  filters: TasksFiltersInputType,
|};

const colsConfig: $ReadOnlyArray<ColumnType<any, any>> = [
  {
    title: 'Status',
    sortKey: 'STATUS',
    component: DeliverableStatus,
    minWidth: 100,
    grow: 0,
  },
  {
    title: 'Task',
    sortKey: 'TITLE',
    component: DeliverableTitle,
    grow: 1,
  },
  {
    title: 'Event Name',
    sortKey: 'EVENT_NAME',
    component: DeliverableEventName,
    minWidth: 200,
  },
  {
    title: 'Due Date',
    sortKey: 'DUE_DATE',
    component: DeliverableDueDate,
    minWidth: 100,
    grow: 0,
  },
  {
    title: 'Tags',
    component: DeliverableTags,
    width: 154,
    grow: 0,
  },
  {
    title: 'Assignees',
    component: DeliverableAssignees,
    minWidth: 150,
    grow: 0,
  },
  {
    title: '',
    component: DeliverableActionCell,
    type: 'action',
  },
];

export default colsConfig;
