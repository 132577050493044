/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import generateFontFromProps from 'views/Main/Event/Registration/RegistrationCreate/RegistrationPageContent/generateFontFromProps';

import { withSharedFormTheme } from './lib/SharedFormThemeContext';

const Circle = styled.div`
  position: relative;
  width: ${props => (props.compact ? 15 : 17)}px;
  height: ${props => (props.compact ? 15 : 17)}px;
  border-radius: 50%;
  border: 1px solid;
  color: #cdd1d4;
  cursor: pointer;
  text-align: center;
  line-height: 13px;
  background: #fff;
  &:after {
    content: '';
    position: absolute;
    top: ${props => (props.compact ? 3 : 4)}px;
    left: ${props => (props.compact ? 3 : 4)}px;
    right: ${props => (props.compact ? 3 : 4)}px;
    bottom: ${props => (props.compact ? 3 : 4)}px;
    background: #29cc71;
    border-radius: 50%;
    opacity: 0;
    transition: 0.2s;
    transform: scale(0.5);
  }
  & ~ span,
  & ~ div {
    margin-left: 15px;
  }
`;

const Label = withSharedFormTheme(styled.label`
  display: inline-flex;
  align-items: center;
  color: ${props => props.sharedFormTheme.textColor || 'rgba(74, 86, 101, 0.87)'};
  font: ${props =>
    props.sharedFormTheme
      ? generateFontFromProps({
          fontFamily: props.sharedFormTheme.textFont,
          fontStyle: props.sharedFormTheme.textStyle,
        })
      : ''};
  input {
    display: none;
    &:checked {
      & ~ ${Circle} {
        color: #29cc71;
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
      & ~ span,
      & ~ div {
        color: #3e4859;
      }
    }
    &:disabled {
      & ~ ${Circle} {
        cursor: default;
        background: #f7f7f7;
      }
      & ~ span,
      & ~ div {
        color: rgba(74, 86, 101, 0.54);
      }
    }
  }
`);

export default class CheckBox extends React.PureComponent<{
  label?: string,
  name?: string,
  checked: boolean,
  disabled?: boolean,
  labelRenderer?: ?(disabled?: boolean) => React.Node,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => void,
  compact?: boolean,
  value?: string,
}> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.props.onChange && !this.props.disabled) {
      this.props.onChange(e);
    }
  };

  render() {
    const { name, checked, disabled, value, compact, onClick, labelRenderer, label } = this.props;
    return (
      <Label>
        <input
          type="radio"
          name={name}
          checked={checked}
          onChange={this.handleChange}
          disabled={disabled}
          value={value}
        />
        <Circle compact={compact} onClick={onClick} />
        {labelRenderer ? (
          <div onClick={onClick}>{labelRenderer(disabled)}</div>
        ) : (
          label && <span onClick={onClick}>{label}</span>
        )}
      </Label>
    );
  }
}
