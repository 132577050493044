/**
 * @flow
 * @relayHash 0d0b6574fa93ca9e6eed8f32af3c6c4a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrgStyle_org$ref = any;
export type OrgStyleQueryVariables = {||};
export type OrgStyleQueryResponse = {|
  +org: {|
    +$fragmentRefs: OrgStyle_org$ref
  |}
|};
export type OrgStyleQuery = {|
  variables: OrgStyleQueryVariables,
  response: OrgStyleQueryResponse,
|};
*/


/*
query OrgStyleQuery {
  org {
    ...OrgStyle_org
    id
  }
}

fragment OrgStyle_org on Org {
  name
  settings {
    id
    logo
    primaryColor
    secondaryColor
    font
    scheduleName
    tz
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "OrgStyleQuery",
  "id": null,
  "text": "query OrgStyleQuery {\n  org {\n    ...OrgStyle_org\n    id\n  }\n}\n\nfragment OrgStyle_org on Org {\n  name\n  settings {\n    id\n    logo\n    primaryColor\n    secondaryColor\n    font\n    scheduleName\n    tz\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OrgStyleQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OrgStyle_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrgStyleQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "logo",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "primaryColor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "secondaryColor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "font",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "scheduleName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tz",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fed324a508e834aba581cf6ebbd67a65';
module.exports = node;
