/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import RichText from 'components/RichText';

const Container = styled.div`
  font-size: 13px;
  margin-bottom: 20px;
`;

const EmptyDescription = styled.div`
  cursor: pointer;
  font-size: 13px;
  padding: 4px 0;
  margin-bottom: 10px;
  position: relative;
  padding-right: 55px;
  box-shadow: 0 1px 0 ${props => props.theme.secondaryActionColor};
  transition: box-shadow 0.3s;
  color: ${props => props.theme.mutedTextColor};
  &:hover {
    box-shadow: 0 1px 0 ${props => props.theme.secondaryActionDarkerColor};
  }
`;

export default class TaskDescription extends React.PureComponent<
  {
    description: ?string,
    onSave: (description: ?string) => void,
    disabled?: boolean,
  },
  {
    editing: boolean,
  },
> {
  state = {
    editing: false,
  };

  handleEdit = () => {
    if (!this.props.disabled) {
      this.setState({ editing: true });
    }
  };

  handleCancel = () => {
    this.setState({ editing: false });
  };

  handleSave = (description: ?string) => {
    this.props.onSave(description && description.trim());
  };

  render() {
    const description = this.props.description && this.props.description.trim();

    return (
      <Container>
        {(description || this.state.editing) && (
          <RichText
            defaultValue={this.props.description}
            autoFocus
            onSave={this.handleSave}
            onCancel={this.handleCancel}
            onStartEditing={this.props.disabled ? null : this.handleEdit}
            viewMode={!this.state.editing}
          />
        )}
        {!this.state.editing &&
          !description &&
          !this.props.disabled && (
            <EmptyDescription empty={!this.props.description} onClick={this.handleEdit}>
              Add description...
            </EmptyDescription>
          )}
      </Container>
    );
  }
}
