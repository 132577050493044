/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateImageComponentMutationVariables,
  UpdateImageComponentInput,
} from './__generated__/updateImageComponentMutation.graphql';

export type updateImageComponentPropertyType = $Shape<UpdateImageComponentInput>;

const mutation = graphql`
  mutation updateImageComponentMutation($input: UpdateImageComponentInput!) {
    updateRegistrationFormImageComponent(input: $input) {
      pageComponent {
        imageComponent {
          id
          fileurl
          filename
          filesize
          filetype
          width
          widthMeasurement
          padding
          alignment
          backgroundColor
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateImageComponent(properties: updateImageComponentPropertyType) {
  const {
    id,
    fileurl,
    filename,
    filesize,
    filetype,
    width,
    widthMeasurement,
    padding,
    alignment,
    backgroundColor,
  } = properties;
  const variables: updateImageComponentMutationVariables = {
    input: {
      ...properties,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateRegistrationFormImageComponent: {
        pageComponent: {
          imageComponent: {
            id,
            fileurl,
            filename,
            filesize,
            filetype,
            width,
            widthMeasurement,
            padding,
            alignment,
            backgroundColor,
          },
        },
      },
    },
  });
}
