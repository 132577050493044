/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { ColumnConfiguration } from 'components/material/table';
import { TableRow } from 'components/material/table';

import ImportLogsActionsColumn, { Icon } from './columns/ImportLogsActionsColumn';

import type { ImportLogsTableRow_importLog } from './__generated__/ImportLogsTableRow_importLog.graphql';

const StyledRow = styled(TableRow)`
  padding-right: 50px;
  &:first-of-type {
    border-top: 0;
  }
  /* Actions column. Only shown on hover */
  > *:last-of-type ${Icon} {
    visibility: hidden;
  }
  &:hover {
    > *:last-of-type ${Icon} {
      visibility: visible;
    }
  }
`;

class ImportLogsTableRow extends React.PureComponent<{
  importLog: ImportLogsTableRow_importLog,
  columns: ColumnConfiguration,
  tz: string,
  orgId: string,
  revertConfirmationMessage: string,
}> {
  render() {
    const { importLog, columns, revertConfirmationMessage } = this.props;

    return (
      <StyledRow>
        {columns.map(column => (
          <column.component width={column.width} key={column.value} grow={column.grow}>
            <column.componentContent importLog={importLog} tz={this.props.tz} />
          </column.component>
        ))}

        <ImportLogsActionsColumn
          importLog={importLog}
          orgId={this.props.orgId}
          revertConfirmationMessage={revertConfirmationMessage}
        />
      </StyledRow>
    );
  }
}

export default createFragmentContainer(
  ImportLogsTableRow,
  graphql`
    fragment ImportLogsTableRow_importLog on ImportLog {
      id
      ...ImportLogsFilenameColumn_importLog
      ...ImportLogsDateColumn_importLog
      ...ImportLogsUserColumn_importLog
      ...ImportLogsSuccessCountColumn_importLog
      ...ImportLogsErrorCountColumn_importLog
      ...ImportLogsActionsColumn_importLog
    }
  `,
);
