/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Match, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import qs from 'qs';

import publicRuleNames from 'config/publicRuleNames';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import TextInput from 'components/material/TextInput';
import type { User } from 'components/UserSelect';

import inputBoxStyled from '../components/inputBoxStyled';
import Label, { Error, Header } from '../FieldComponents';
import { withSharedFormTheme } from '../lib/SharedFormThemeContext';
import UserSelectOptionRow from './UserSelectOptionRow';

import type { UserSelectFieldQuery_response } from './__generated__/UserSelectFieldQuery.graphql';

const Component = withSharedFormTheme(styled.div`
  max-height: 280px;
  min-height: 50px;
  overflow-y: auto;
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 15px;
    /* Safari has issues with property transparent */
    background: linear-gradient(
      to bottom,
      ${props => props.sharedFormTheme.backgroundColor},
      rgba(255, 255, 255, 0.001)
    );
    pointer-events: none;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: -15px;
    width: 100%;
    height: 15px;
    /* Safari has issues with property transparent */
    background: linear-gradient(
      to top,
      ${props => props.sharedFormTheme.backgroundColor},
      rgba(255, 255, 255, 0.001)
    );
    pointer-events: none;
  }
`);

const ComponentWrapper = styled.div`
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

const query = graphql`
  query UserSelectFieldQuery(
    $skipEvent: Boolean!
    $eventSlug: String
    $query: String!
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
    $skipUserIds: [ID!]
    $wmSSOToken: String
  ) {
    ... @skip(if: $skipEvent) {
      event(slug: $eventSlug) {
        id
      }
    }
    publicRule(
      publicRuleName: $publicRuleName
      resourceToken: $resourceToken
      orgSlug: $orgSlug
      wmSSOToken: $wmSSOToken
    )
    org {
      users(first: 3, query: $query, skipUserIds: $skipUserIds) {
        edges {
          node {
            id
            firstName
            lastName
            email
            ...UserSelectOptionRow_user
          }
        }
      }
    }
  }
`;

class UserSelectField extends React.PureComponent<
  {
    match: Match,
    location: Location,
    label: string,
    required: boolean,
    disabled: boolean,
    error: ?string,
    kind: 'SELECT' | 'MULTISELECT',
    skipUserIds: $ReadOnlyArray<string>,
    values: Array<string>,
    selectedUsers: $ReadOnlyArray<User>,
    onChange: (user: User, checked: boolean) => void,
    dragStart?: boolean,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleSearch = debounce((search: string) => {
    this.setState({ query: search });
    this.handleUserSearch(search);
  }, 800);

  cachedUsers = this.props.selectedUsers || [];

  userQueryExists: ?boolean;

  handleSearchUsers = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.toLowerCase();
    this.handleSearch(value);
  };

  handleUserSearch = (searchQuery?: string = '') => {
    if (searchQuery.trim() && !this.userQueryExists) {
      this.userQueryExists = true;
    }
    if (!searchQuery.trim() && this.userQueryExists) {
      this.userQueryExists = false;
    }
  };

  renderSelect = (response?: UserSelectFieldQuery_response) => {
    const users: Array<User> = response ? response.org.users.edges.map(edge => edge.node) : [];
    const dragStart = this.props.dragStart;
    this.cachedUsers = [
      ...this.cachedUsers,
      ...users.filter(user => !this.cachedUsers.find(cachedUser => cachedUser.id === user.id)),
    ];

    const { label, required, error, kind, values, onChange, disabled } = this.props;

    const shownUsers = this.state.query
      ? [
          ...users.filter(user => !values.includes(user.id)),
          ...this.cachedUsers.filter(user => values.includes(user.id)),
        ]
      : this.cachedUsers;

    const sortedUsers = sortBy(shownUsers, ['firstName', 'lastName', 'id']).slice(
      0,
      dragStart ? 5 : undefined,
    );

    return (
      <Label required={required}>
        <Header>{label}</Header>
        <StyledTextInput
          placeholder="Search..."
          onChange={this.handleSearchUsers}
          disabled={disabled}
        />
        <ComponentWrapper>
          <Component error={error}>
            {sortedUsers.map(user => (
              <UserSelectOptionRow
                key={user.id}
                type={kind === 'MULTISELECT' ? 'checkbox' : 'radio'}
                checked={values.includes(user.id)}
                user={user}
                onChange={onChange}
                disabled={disabled}
              />
            ))}
          </Component>
        </ComponentWrapper>
        {error && <Error>{error}</Error>}
      </Label>
    );
  };

  render() {
    const { match, location, skipUserIds, disabled } = this.props;

    const wmSSOToken = qs.parse(location.search, { ignoreQueryPrefix: true }).wmSSOToken;

    return (
      <DefaultQueryRenderer
        query={query}
        public={!disabled}
        variables={{
          eventSlug: match.params.eventSlug || '',
          orgSlug: match.params.orgName,
          skipEvent: disabled || !match.params.eventSlug,
          query: this.state.query || '',
          skipUserIds,
          publicRuleName: publicRuleNames.REGISTRATION_FORM,
          resourceToken: match.params.resourceToken || '',
          wmSSOToken,
        }}
        renderSuccess={this.renderSelect}
        renderLoading={this.renderSelect}
      />
    );
  }
}

export default withRouter(UserSelectField);
