import './styles.styl';

import PropTypes from 'prop-types';

import React from 'react';
import connectToStores from 'utils/connectToStores';

import UIStore from 'stores/UIStore';
import UIActions from 'actions/UIActions';

import GeneralErrorMessage from './GeneralErrorMessage';

@connectToStores
export default class GeneralError extends React.Component {
  static propTypes = {
    errorMessages: PropTypes.array.isRequired,
  };
  static getStores() {
    return [UIStore];
  }
  static getPropsFromStores() {
    return { errorMessages: UIStore.getState().errorMessages };
  }

  handleDismiss = (index) => {
    setTimeout(() => {
      UIActions.hideErrorMessage(index);
    }, 1000);
  };

  render() {
    return (
      <div className="general-error-messages">
        {this.props.errorMessages.map((message, index) =>
          (<GeneralErrorMessage
            key={index}
            {...message}
            index={index}
            onDismiss={this.handleDismiss}
          />),
        )}
      </div>
    );
  }
}
