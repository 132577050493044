/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import type { Match, RouterHistory } from 'react-router-dom';
import { type Location } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import Content from '../../layout';
import OrgContactsPage from './OrgContactsPage';

import type { OrgContactsQueryResponse } from './__generated__/OrgContactsQuery.graphql';

const query = graphql`
  query OrgContactsQuery {
    org {
      id
      ...OrgContactsPage_org
      salesforceAccount {
        contactsSyncEnabled
      }
      marketoAccount {
        id
      }
    }
    me {
      tz
      email
    }
  }
`;

export default class OrgContacts extends React.PureComponent<{
  location: Location,
  history: RouterHistory,
  match: Match,
}> {
  render() {
    const { location, history, match } = this.props;
    const pathPrefix = match.url;
    return (
      <Content>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(props: OrgContactsQueryResponse) => {
            const salesForceEnabled = props.org.salesforceAccount
              ? props.org.salesforceAccount.contactsSyncEnabled
              : false;
            const marketoEnabled = !!props.org.marketoAccount;
            return (
              <OrgContactsPage
                location={location}
                history={history}
                tz={props.me.tz}
                userEmail={props.me.email}
                org={props.org}
                salesforceEnabled={salesForceEnabled}
                marketoEnabled={marketoEnabled}
                pathPrefix={pathPrefix}
              />
            );
          }}
        />
      </Content>
    );
  }
}
