/* @flow */
import type { FieldType } from 'utils/customization/types';

import type { OptionGroup } from 'components/material/SelectField';

import { type CustomGroupFieldSettingsType } from '../BudgetReportingPage';

const GENERAL_OPTIONS = {
  TEAM: 'Team',
  EVENT: 'Event',
  BUDGET_CATEGORY: 'Expense Category',
};

const EVENT_INFO_OPTIONS = {
  EVENT_STATUS: 'Status',
  EVENT_LEADER: 'Leader',
  LOCATION_STATE: 'State',
  LOCATION_COUNTRY: 'Country',
  EVENT_REQUEST_FORM: 'Request Form',
  EVENT_REQUEST_SUBMISSION_STATUS: 'Request Status',
  EVENT_REQUEST_REVIEWER: 'Reviewer',
  EVENT_CREATOR: 'Created by',
};

export const rawGroupingOptions = { ...GENERAL_OPTIONS, ...EVENT_INFO_OPTIONS };

export const getCustomFieldsGroupingOptions = (filterOptions: $ReadOnlyArray<FieldType>) => {
  return {
    ...filterOptions.reduce(
      (customFilters, filter) =>
        filter.kind !== 'DEFAULT'
          ? { ...customFilters, ...{ [filter.id]: filter.label } }
          : customFilters,
      {},
    ),
  };
};

type EventInfoOptionType = $Keys<typeof EVENT_INFO_OPTIONS>;

type GeneralOptionType = $Keys<typeof GENERAL_OPTIONS> | 'EVENT_INFO_FIELDS';

export type GroupingOptionType = EventInfoOptionType | GeneralOptionType;

type NestedOptionType = {|
  label: string,
  value: GroupingOptionType | string,
|};

type OptionType = {|
  ...NestedOptionType,
  options?: $ReadOnlyArray<NestedOptionType>,
|};

const filteredEventInfoOptions = (): { [EventInfoOptionType]: string } => {
  return Object.keys(EVENT_INFO_OPTIONS).reduce((acc, key) => {
    return { ...acc, [key]: EVENT_INFO_OPTIONS[key] };
  }, {});
};

export default function options(
  orgSettings: CustomGroupFieldSettingsType,
  customFieldOptions: $ReadOnlyArray<OptionGroup>,
  skipOption?: string,
): $ReadOnlyArray<OptionType> {
  const levelFirstOptions = Object.keys(GENERAL_OPTIONS)
    .map(key => ({
      label: GENERAL_OPTIONS[key],
      value: key,
    }))
    .filter(option => option.value !== skipOption);
  const filteredInfoFields = filteredEventInfoOptions();
  return skipOption === 'EVENT'
    ? [
        {
          label: 'Expense Category',
          value: 'BUDGET_CATEGORY',
        },
      ]
    : [
        ...levelFirstOptions,
        {
          label: 'Event Info',
          value: 'EVENT_INFO_FIELDS',
          options: [
            ...Object.keys(filteredInfoFields)
              .map(key => ({
                label: filteredInfoFields[key],
                value: key,
              }))
              .filter(option => option.value !== skipOption),
            ...customFieldOptions.filter(option => option.value !== skipOption),
          ],
        },
      ];
}

export function getGroupOptionLabel(key: ?string, eventFields: $ReadOnlyArray<FieldType>): string {
  return {
    ...GENERAL_OPTIONS,
    ...filteredEventInfoOptions(),
    ...eventFields.reduce(
      (customFilters, filter) =>
        filter.kind !== 'DEFAULT'
          ? { ...customFilters, ...{ [filter.id]: filter.label } }
          : customFilters,
      {},
    ),
  }[key];
}
