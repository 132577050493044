/**
 * @flow
 * @relayHash 9e6d1a36d7999f906796f99d6339bc97
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateScheduleInEventInput = {
  clientMutationId?: ?string,
  scheduleId: string,
  name?: ?string,
  order?: ?number,
  shared?: ?boolean,
};
export type updateScheduleMutationVariables = {|
  input: UpdateScheduleInEventInput
|};
export type updateScheduleMutationResponse = {|
  +updateScheduleInEvent: ?{|
    +schedule: {|
      +id: string,
      +name: string,
      +order: number,
      +slug: string,
      +slugs: $ReadOnlyArray<{|
        +slug: string
      |}>,
    |}
  |}
|};
export type updateScheduleMutation = {|
  variables: updateScheduleMutationVariables,
  response: updateScheduleMutationResponse,
|};
*/


/*
mutation updateScheduleMutation(
  $input: UpdateScheduleInEventInput!
) {
  updateScheduleInEvent(input: $input) {
    schedule {
      id
      name
      order
      slug
      slugs {
        slug
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateScheduleInEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateScheduleInEventInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateScheduleMutation",
  "id": null,
  "text": "mutation updateScheduleMutation(\n  $input: UpdateScheduleInEventInput!\n) {\n  updateScheduleInEvent(input: $input) {\n    schedule {\n      id\n      name\n      order\n      slug\n      slugs {\n        slug\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateScheduleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateScheduleInEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateScheduleInEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "schedule",
            "storageKey": null,
            "args": null,
            "concreteType": "Schedule",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "slugs",
                "storageKey": null,
                "args": null,
                "concreteType": "Slug",
                "plural": true,
                "selections": [
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateScheduleMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateScheduleInEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateScheduleInEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "schedule",
            "storageKey": null,
            "args": null,
            "concreteType": "Schedule",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "slugs",
                "storageKey": null,
                "args": null,
                "concreteType": "Slug",
                "plural": true,
                "selections": [
                  v5,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e162f1cfd8a6ca8cd7e3a74498ccb2d5';
module.exports = node;
