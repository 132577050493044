/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';

import createTasks from 'graph/mutations/task/createTasks';
import reorderTasks from 'graph/mutations/task/reorderTasks';
import showModernMutationError from 'graph/utils/showModernMutationError';

import SubtaskInput from './SubtaskInput';
import SubtaskRow from './SubtaskRow';

import type { Subtasks_subtasks } from './__generated__/Subtasks_subtasks.graphql';

class Subtasks extends React.PureComponent<
  {
    taskId: string,
    eventId: string,
    subtasks: Subtasks_subtasks,
    tz: string,
    adding: boolean,
    onEndAdding: () => void,
  },
  {
    draggingItemId: ?string,
    draggingItemOrderOverride: ?number,
  },
> {
  state = {
    draggingItemId: null,
    draggingItemOrderOverride: null,
  };

  handleCreate = (name: string) => {
    createTasks(this.props.eventId, { names: [name], parentId: this.props.taskId }).catch(
      showModernMutationError,
    );
  };

  handleMoveStart = (draggingItemId, draggingItemOrder) => {
    this.setState({
      draggingItemId,
      draggingItemOrderOverride: draggingItemOrder,
    });
  };

  handleMoveItem = (draggingItemId, hoverItemOrder) => {
    this.setState(state => {
      const adjustment = state.draggingItemOrderOverride < hoverItemOrder ? 1 : -1;

      return {
        draggingItemId,
        draggingItemOrderOverride: hoverItemOrder + adjustment,
      };
    });
  };

  handleMoveEnd = () => {
    const newIndex = this.sortedSubtasks().findIndex(item => item.id === this.state.draggingItemId);

    if (this.state.draggingItemId && this.state.draggingItemOrderOverride != null) {
      reorderTasks([this.state.draggingItemId], null, newIndex, [
        this.state.draggingItemOrderOverride,
      ]);
    }

    this.setState({
      draggingItemId: null,
      draggingItemOrderOverride: null,
    });
  };

  sortedSubtasks(): Subtasks_subtasks {
    const subtasks: Array<{|
      +id: string,
      +order: number,
      +$fragmentRefs: any,
      +$refType: any,
    |}> = this.props.subtasks.slice();
    return sortBy(subtasks, item =>
      item.id === this.state.draggingItemId ? this.state.draggingItemOrderOverride : item.order,
    );
  }

  render() {
    const { eventId, taskId, tz, adding, onEndAdding } = this.props;
    return (
      <div>
        {adding && <SubtaskInput onCancel={onEndAdding} onSave={this.handleCreate} />}
        {this.sortedSubtasks().map(subtask => (
          <SubtaskRow
            subtask={subtask}
            eventId={eventId}
            taskId={taskId}
            key={subtask.id}
            tz={tz}
            onMoveStart={this.handleMoveStart}
            onMoveEnd={this.handleMoveEnd}
            onMove={this.handleMoveItem}
          />
        ))}
      </div>
    );
  }
}

export default createFragmentContainer(Subtasks, {
  subtasks: graphql`
    fragment Subtasks_subtasks on Deliverable @relay(plural: true) {
      id
      order
      ...SubtaskRow_subtask
    }
  `,
});
