/* @flow */
import React from 'react';
import styled from 'styled-components';

import BriefField from './BriefField';

const Description = styled.div`
  font-size: 14px;
  color: ${props => props.theme.darkestTextColor};
  white-space: pre-wrap;
`;

const TextField = (props: { description: ?string, name: string }) => {
  const { name, description } = props;
  return (
    <BriefField label={name} contentDataAvailable={description}>
      <Description>{description}</Description>
    </BriefField>
  );
};
export default TextField;
