/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import type { EditableDeclineReasons_org } from './__generated__/EditableDeclineReasons_org.graphql';

import EditableDeclineReasonsRow from './EditableDeclineReasonsRow';
import EditableDeclineReasonsAdd from './EditableDeclineReasonsAdd';

import createDeclineReason from 'graph/mutations/declineReason/createDeclineReason';
import updateDeclineReason from 'graph/mutations/declineReason/updateDeclineReason';
import showModernMutationError from 'graph/utils/showModernMutationError';

const Container = styled.div`
  margin: 30px 0;
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  color: rgb(74, 86, 101, 0.87);
  font-weight: 500;
`;

class EditableDeclineReasons extends React.PureComponent<{
  org: EditableDeclineReasons_org,
  selected: $ReadOnlyArray<string>,
  onToggleDeclineReason: (declineReasonId: string) => void,
}> {
  handleUpdateDeclineReason = (declineReasonId: string, name: string) => {
    const declineReasons = this.props.org.declineReasons.edges.map(edge => edge.node);

    if (declineReasons.some(reason => reason.name === name)) return;

    updateDeclineReason({ declineReasonId, name }).catch(showModernMutationError);
  };

  handleCreateDeclineReason = (name: string) => {
    const declineReasons = this.props.org.declineReasons.edges.map(edge => edge.node);

    if (declineReasons.some(reason => reason.name === name)) return;

    createDeclineReason(this.props.org.id, { name })
      .then(declineReasonId => {
        this.props.onToggleDeclineReason(declineReasonId);
      })
      .catch(showModernMutationError);
  };

  render() {
    const declineReasons = this.props.org.declineReasons.edges.map(edge => edge.node);

    return (
      <Container>
        <Title>Declined Reasons</Title>

        {declineReasons.map(declineReason => (
          <EditableDeclineReasonsRow
            key={declineReason.id}
            declineReason={declineReason}
            active={this.props.selected.includes(declineReason.id)}
            onToggleChecked={this.props.onToggleDeclineReason}
            onUpdate={this.handleUpdateDeclineReason}
          />
        ))}

        <EditableDeclineReasonsAdd onCreate={this.handleCreateDeclineReason} />
      </Container>
    );
  }
}

export default createFragmentContainer(
  EditableDeclineReasons,
  graphql`
    fragment EditableDeclineReasons_org on Org {
      id
      declineReasons {
        edges {
          node {
            id
            name
            ...EditableDeclineReasonsRow_declineReason
          }
        }
      }
    }
  `,
);
