/* @flow */
import { setCookie, expireCookie } from './Cookies';
import moment from 'moment';

export default function setSupportAccessToken(token: ?string) {
  if (token) {
    setCookie('supportAccessToken', token, {
      expires: moment().add(1, 'month').toDate(),
    });
    return;
  }
  expireCookie('supportAccessToken');
}
