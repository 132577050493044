/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import RelayAttachments, { type BriefDataType } from 'components/RelayAttachments';
import AttachmentOverlay from 'components/RelayAttachments/AttachmentOverlay';

import TaskSection from './TaskSection';

import type { TaskAttachments_task } from './__generated__/TaskAttachments_task.graphql';

class TaskAttachments extends React.PureComponent<
  {
    task: TaskAttachments_task,
    uploadingAttachments: Array<{}>,
    onAddAttachment: ({}) => void,
    onRemoveAttachment: string => void,
    briefData: BriefDataType,
  },
  {
    overlayShown: boolean,
  },
> {
  state = { overlayShown: false };

  addButton: ?TaskSection;

  componentDidMount() {
    if (
      this.props.task.attachments.edges.length === 0 &&
      this.props.uploadingAttachments.length === 0
    ) {
      this.handleOverlayShow();
    }
  }

  handleOverlayShow = () => this.setState({ overlayShown: true });

  handleOverlayHide = () => this.setState({ overlayShown: false });

  handleRemoveAttachment = attachmentId => {
    this.props.onRemoveAttachment(attachmentId);
  };

  render() {
    const attachments = this.props.task.attachments.edges.map(edge => edge.node);

    return (
      <TaskSection
        title="Attachments"
        onAdd={this.handleOverlayShow}
        addButtonRef={el => {
          this.addButton = el;
        }}
        disableAdd={!this.props.task.viewerCanUpdateAttachment}
      >
        <AttachmentOverlay
          target={this.addButton}
          container={this}
          onHide={this.handleOverlayHide}
          show={this.state.overlayShown}
          onUploaded={this.props.onAddAttachment}
        />
        <RelayAttachments
          attachments={attachments}
          onRemove={this.handleRemoveAttachment}
          uploadingAttachments={this.props.uploadingAttachments}
          readOnly={!this.props.task.viewerCanUpdateAttachment}
          briefData={this.props.briefData}
        />
      </TaskSection>
    );
  }
}

export default createFragmentContainer(
  TaskAttachments,
  graphql`
    fragment TaskAttachments_task on Deliverable {
      viewerCanUpdateAttachment
      attachments {
        edges {
          node {
            id
            ...RelayAttachments_attachments
          }
        }
      }
    }
  `,
);
