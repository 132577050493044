/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';

import { TINYMCE_KEY } from 'config/variables';

import fs from 'utils/fs';
import sanitizeHtml from 'utils/string/sanitizeHtml';

import Loader from 'components/Loader';

const defaultPlugins = 'autoresize autolink lists link paste emoticons';
const defaultToolbar =
  '| fontselect fontsizeselect formatselect | forecolor backcolor link | bold italic underline | alignleft aligncenter alignright | bullist numlist | removeformat emoticons';
export const defaultFonts =
  'Roboto=Roboto; IBM Plex Sans=IBM Plex Sans; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings';

const EditorWrapper = styled.div`
  position: relative;
`;

const ErrorMessage = styled.div`
  color: #dd3b3b;
`;

export const Label = styled.div`
  position: absolute;
  top: 0;
  max-width: 100%;
  pointer-events: none;
  color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.error
      ? props.theme.negativeActionColor
      : props.focused
      ? props.theme.primaryActionColor
      : props.theme.labelColor};
  font-size: ${props => (props.fontSize ? props.fontSize : 14)}px;
  transition: 0.2s;
  line-height: 1.8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props =>
    props.collapsed &&
    css`
      font-size: 12px;
      top: -15px;
      line-height: 1.1;
    `};
`;

const Required = styled.span`
  font-size: 11px;
  vertical-align: top;
  margin-left: 3px;
`;

export const StyledContent = styled.div`
  font-size: 13px;
  color: #4a5665;

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 13px;
  }

  h5 {
    font-size: 11px;
  }

  h6 {
    font-size: 9px;
  }

  ul,
  ol {
    list-style-position: inside;
  }

  a {
    text-decoration: underline;
    color: #3ba9da;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

export type CustomButtonType = { key: string, tooltip: string, label: string };

export default class TinyRichText<InitOptions = {}> extends React.PureComponent<
  {
    value: ?string,
    label?: string,
    imageEnabled?: boolean,
    className?: string,
    customToolbar?: $ReadOnlyArray<CustomButtonType>,
    onSave?: (content: string) => void,
    onChange?: (content: string) => void,
    loaderColor?: string,
    required?: boolean,
    error?: ?string | ?boolean,
    labelFontSize?: string,
    initOptions?: InitOptions,
  },
  {
    editorFocused: boolean,
    content: string,
    initialized: boolean,
  },
> {
  state = { editorFocused: false, content: this.props.value || '', initialized: false };

  editorRef = React.createRef();

  componentDidUpdate(prevProps: $PropertyType<TinyRichText<InitOptions>, 'props'>) {
    const editor = this.editorRef.current && this.editorRef.current.editor;
    const currentContentStyle = ((this.props.initOptions: any) || {}).content_style;
    const prevContentStyle = ((prevProps.initOptions: any) || {}).content_style;

    if (!editor || currentContentStyle === prevContentStyle) return;

    const styleElement = editor.iframeElement.contentDocument.querySelector('style');
    styleElement.innerHTML = currentContentStyle;

    editor.execCommand('mceAutoResize');
  }

  getToolbars = () => {
    const { customToolbar, imageEnabled } = this.props;
    const toolbar = imageEnabled ? `${defaultToolbar} image` : defaultToolbar;

    return {
      toolbar: [
        ...(customToolbar ? [customToolbar.map(button => button.key).join(' ')] : []),
        toolbar,
      ],
    };
  };

  getSetup = () => {
    const customToolbar = this.props.customToolbar;
    if (!customToolbar) {
      return {};
    }
    return {
      setup(editor: any) {
        customToolbar.forEach(button => {
          editor.ui.registry.addButton(button.key, {
            tooltip: button.tooltip,
            text: button.label,
            onAction() {
              editor.insertContent(` {{${button.label}}} `);
            },
          });
        });
      },
    };
  };

  getImageSettings = () => {
    const imageEnabled = this.props.imageEnabled;
    if (!imageEnabled) {
      return {};
    }
    return {
      automatic_uploads: true,
      file_picker_types: 'image',
      imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
      images_upload_handler(
        blobInfo: { blob: () => void },
        success: (url: string) => void,
        failure: (errorMessage: string) => void,
        progress: (progressPercentage: number) => void,
      ) {
        const onProgress = evt => {
          progress(evt.totalPercent);
        };

        fs.upload(blobInfo.blob(), { onProgress })
          .then(res => {
            // TODO: add here functionality to save uploaded image to our database, if needed
            success(res.url);
          })
          .catch(err => {
            failure(err);
          });
      },
    };
  };

  handleEditorChange = (content: string) => {
    this.setState({ content });
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(content);
    }
  };

  handleRichEditorSave = () => {
    const onSave = this.props.onSave;
    this.setState({ editorFocused: false });
    if (onSave) {
      onSave(this.state.content);
    }
  };

  handleRichEditorFocused = () => {
    this.setState({ editorFocused: true });
  };

  handleFinishInit = () => {
    this.setState({ initialized: true });
  };

  render() {
    const {
      imageEnabled,
      className,
      label,
      required,
      error,
      labelFontSize,
      initOptions,
      loaderColor,
    } = this.props;
    const { editorFocused, content, initialized } = this.state;
    const labelCollapsed = editorFocused || content;
    const plugins = imageEnabled ? `${defaultPlugins} image imagetools` : defaultPlugins;
    const toolbars = this.getToolbars();
    const setup = this.getSetup();
    const imageSettings = this.getImageSettings();

    return (
      <EditorWrapper focused={editorFocused} className={className}>
        {!initialized && (
          <LoaderContainer>
            <Loader size={20} color={loaderColor || '#6f6cd8'} />
          </LoaderContainer>
        )}
        <Editor
          apiKey={TINYMCE_KEY}
          value={content}
          init={{
            skin_url: '/tinymce/circa',
            menubar: false,
            statusbar: false,
            autoresize_bottom_margin: 15,
            toolbar_location: 'bottom',
            toolbar_mode: 'wrap',
            plugins,
            ...toolbars,
            font_formats: defaultFonts,
            paste_preprocess: (editor, args) => {
              // eslint-disable-next-line no-param-reassign
              args.content = sanitizeHtml(args.content, { disallowedTagsMode: 'discard' });
            },
            fontsize_formats: '8px 10px 12px 13px 14px 18px 24px 36px',
            content_style: '.mce-content-body { font-size: 14px; }',
            ...imageSettings,
            ...setup,
            ...initOptions,
            init_instance_callback: () => {
              this.handleFinishInit();
            },
          }}
          onBlur={this.handleRichEditorSave}
          onEditorChange={this.handleEditorChange}
          onFocus={this.handleRichEditorFocused}
          ref={this.editorRef}
        />
        {label && (
          <Label
            collapsed={labelCollapsed}
            error={error}
            fontSize={labelFontSize}
            focused={editorFocused}
          >
            {label}
            {required && <Required>*</Required>}
          </Label>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </EditorWrapper>
    );
  }
}
