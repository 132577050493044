/* @flow */
import type { RecordSourceSelectorProxy, SelectorData, RecordProxy } from 'react-relay';

function getMutationRoot(store: RecordSourceSelectorProxy, data: SelectorData) {
  const rootFieldName: string = Object.keys(data)[0];
  const root: ?RecordProxy = store.getRootField(rootFieldName);

  return root;
}

export default getMutationRoot;
