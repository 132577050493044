/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import salesforceAuthPopup from 'utils/oauth/salesforceAuthPopup';

import connectSalesforceAccountMutation from 'graph/mutations/salesforce/connectSalesforceAccount';
import disconnectSalesforceAccount from 'graph/mutations/salesforce/disconnectSalesforceAccount';
import showModernMutationError from 'graph/utils/showModernMutationError';

import IntegrationConnectionBar from './IntegrationConnectionBar';

import type { AccountConnector_org } from './__generated__/AccountConnector_org.graphql';

class AccountConnector extends React.Component<
  {
    org: AccountConnector_org,
  },
  {
    error: ?string,
    submitting: boolean,
    editing: boolean,
    sandbox: boolean,
  },
> {
  state = {
    error: null,
    submitting: false,
    editing: false,
    sandbox: false,
  };

  handleClickConnectProd = () => {
    this.connectSalesforce(false);
  };

  handleClickConnectSandbox = () => {
    this.connectSalesforce(true);
  };

  handleClickDisconnect = () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const result = confirm(
      'WARNING: Proceeding will disconnect all Salesforce Campaigns and reset Event Info mappings. Click Cancel and then Update Account to keep Campaigns and mappings, but connect through a new Salesfoce Account.',
    );
    if (result) {
      this.setState({
        submitting: true,
      });
      disconnectSalesforceAccount()
        .then(() => {
          this.setState({
            submitting: false,
            editing: false,
          });
        })
        .catch(err => {
          this.setState({ submitting: false });
          showModernMutationError(err);
        });
    }
  };

  handleCancelEditing = () => {
    this.setState({ editing: false });
  };

  handleStartEditing = () => {
    this.setState({ editing: true });
  };

  connectSalesforce(sandbox: boolean) {
    this.setState({
      error: null,
      submitting: true,
      sandbox,
    });
    salesforceAuthPopup(sandbox)
      .then(params => {
        if (params.code) {
          connectSalesforceAccountMutation({
            code: params.code,
            host: params.host,
            sandbox,
          })
            .then(() => {
              this.setState({
                submitting: false,
                editing: false,
              });
            })
            .catch(err => {
              this.setState({ submitting: false });
              showModernMutationError(err);
            });
        } else if (params.error_description) {
          this.setState({
            error: params.error_description,
            submitting: false,
          });
          console.warn('oauth error', params);
        }
      })
      .catch(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    const account = this.props.org.salesforceAccount;
    const isConnected = !!account;

    return (
      <IntegrationConnectionBar
        onClickConnectProd={this.handleClickConnectProd}
        onClickConnectSandbox={this.handleClickConnectSandbox}
        onClickDisconnect={this.handleClickDisconnect}
        error={this.state.error}
        isConnected={isConnected}
        accountIdentifier={account ? account.username : null}
        serviceName="Salesforce"
        submitting={this.state.submitting}
        editing={this.state.editing}
        sandbox={this.state.sandbox}
        onStartEditing={this.handleStartEditing}
        onCancelEditing={this.handleCancelEditing}
      />
    );
  }
}

export default createFragmentContainer(
  AccountConnector,
  graphql`
    fragment AccountConnector_org on Org {
      salesforceAccount {
        username
      }
    }
  `,
);
