/* @flow */
import * as React from 'react';
import { type ConnectDropTarget, DropTarget } from 'react-dnd';
import styled from 'styled-components';

const Target = styled.div`
  ${props =>
    props.active && `box-shadow: 0 0 0 2px ${props.theme.primaryActionColor};`} min-height: 35px;
`;

class DroppableFolder extends React.PureComponent<{
  children: React.Node,
  connectDropTarget: ConnectDropTarget,
  isOver: boolean,
  // Used in DnD handler only
  // eslint-disable-next-line react/no-unused-prop-types
  folderId: ?string,
  // eslint-disable-next-line react/no-unused-prop-types
  onMove: (?string) => void,
}> {
  render() {
    const { connectDropTarget, isOver } = this.props;

    return connectDropTarget(
      <div>
        <Target active={isOver}>{this.props.children}</Target>
      </div>,
    );
  }
}

function collect(
  connect,
  monitor,
): {
  connectDropTarget: ConnectDropTarget,
  isOver: boolean,
} {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

const Droppable = DropTarget(
  'TASK',
  {
    hover(props, monitor) {
      if (monitor.isOver({ shallow: true })) {
        props.onMove(props.folderId);
      }
    },
  },
  collect,
)(DroppableFolder);

export default Droppable;
