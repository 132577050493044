/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SyncStatusIndicator_status$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SyncOptions_org$ref: FragmentReference;
export type SyncOptions_org = {|
  +salesforceAccount: ?{|
    +id: string,
    +host: string,
    +contactsSyncEnabled: boolean,
    +opportunitiesSyncEnabled: boolean,
    +opportunitySyncStatus: {|
      +$fragmentRefs: SyncStatusIndicator_status$ref
    |},
  |},
  +contactCustomFields: {|
    +totalCount: number
  |},
  +$refType: SyncOptions_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SyncOptions_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "host",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "contactsSyncEnabled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "opportunitiesSyncEnabled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "opportunitySyncStatus",
          "storageKey": null,
          "args": null,
          "concreteType": "SyncStatus",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "SyncStatusIndicator_status",
              "args": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "contactCustomFields",
      "name": "customFields",
      "storageKey": "customFields(customizableType:[\"CONTACT\"],fieldNames:[\"company_id\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": [
            "CONTACT"
          ],
          "type": "[CustomizableType!]!"
        },
        {
          "kind": "Literal",
          "name": "fieldNames",
          "value": [
            "company_id"
          ],
          "type": "[String!]"
        }
      ],
      "concreteType": "CustomFieldTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6237e1dd0a243e84436ac5bf5f28ac22';
module.exports = node;
