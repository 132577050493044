/* @flow */
import React from 'react';

import COUNTRIES from 'config/countries.json';
import US_STATES from 'config/us_states.json';

import enforceHttpPrefix from 'utils/enforceHttpPrefix';

import { type QuestionType } from 'components/ContactForm/lib/types';
import LinkField from 'components/SharedForm/LinkField';
import SelectField from 'components/SharedForm/SelectField';
import TextField from 'components/SharedForm/TextField';
import { type CompanyDefaultFields } from 'views/Main/AllContacts/Company/tabs/CompanyProfile/CompanyFormFields';

type Props = {
  company: $Shape<CompanyDefaultFields>,
  // eslint-disable-next-line
  viewerCanUpdate: boolean,
  handleUpdate: (changes: $Shape<CompanyDefaultFields>) => void,
  question: QuestionType,
  errors: { [string]: string },
  // eslint-disable-next-line react/no-unused-prop-types
  dragStartId?: string,
};

const countries = COUNTRIES.map(country => ({ label: country, value: country }));
const usStates = Object.keys(US_STATES).map(k => ({
  label: US_STATES[k],
  value: US_STATES[k],
}));

const companySuggestedFields = {
  company_name: ({ company, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={company.name}
      onBlur={e => {
        handleUpdate({ name: e.currentTarget.value });
      }}
      label={question.label}
      required={question.required}
      error={errors.company_name}
      disabled={!viewerCanUpdate}
    />
  ),
  street: ({ company, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={company.street}
      onBlur={e => {
        if (company.street !== e.currentTarget.value) {
          handleUpdate({ street: e.currentTarget.value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      required={question.required}
      error={errors.street}
      disabled={!viewerCanUpdate}
    />
  ),
  city: ({ company, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={company.city}
      onBlur={e => {
        if (company.city !== e.currentTarget.value) {
          handleUpdate({ city: e.currentTarget.value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      required={question.required}
      error={errors.city}
      disabled={!viewerCanUpdate}
    />
  ),
  description: ({ company, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      multiline
      defaultValue={company.description}
      onBlur={e => {
        if (company.description !== e.currentTarget.value) {
          handleUpdate({ description: e.currentTarget.value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      required={question.required}
      error={errors.description}
      disabled={!viewerCanUpdate}
    />
  ),
  zip: ({ company, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={company.zip}
      onBlur={e => {
        if (company.zip !== e.currentTarget.value) {
          handleUpdate({ zip: e.currentTarget.value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      required={question.required}
      error={errors.zip}
      disabled={!viewerCanUpdate}
    />
  ),
  website: ({ company, handleUpdate, question, viewerCanUpdate, errors }: Props) => (
    <LinkField
      defaultValue={company.website}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== company.website) {
          e.currentTarget.value = value;
          handleUpdate({ website: value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.website}
    />
  ),
  twitter: ({ company, handleUpdate, viewerCanUpdate, question, errors }: Props) => (
    <LinkField
      defaultValue={company.twitter}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== company.twitter) {
          e.currentTarget.value = value;
          handleUpdate({ twitter: value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.twitter}
    />
  ),
  linkedin: ({ company, handleUpdate, viewerCanUpdate, question, errors }: Props) => (
    <LinkField
      defaultValue={company.linkedin}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== company.linkedin) {
          e.currentTarget.value = value;
          handleUpdate({ linkedin: value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.linkedin}
    />
  ),
  phone: ({ company, handleUpdate, viewerCanUpdate, question, errors }: Props) => (
    <LinkField
      defaultValue={company.phone}
      onBlur={e => {
        const value = e.currentTarget.value.trim();
        if (company.phone !== value) {
          handleUpdate({ phone: value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.phone}
      to={`tel:${company.phone || ''}`}
    />
  ),
  state: ({ company, handleUpdate, viewerCanUpdate, question, errors, dragStartId }: Props) =>
    company.country === 'United States' ? (
      <SelectField
        onChange={state => {
          if (state !== company.state) {
            handleUpdate({ state });
          }
        }}
        label={question.label}
        values={company.state ? [company.state] : []}
        options={usStates.slice(0, dragStartId === question.id ? 10 : undefined)}
        searchable
        clearable
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors.state}
      />
    ) : (
      <TextField
        defaultValue={company.state}
        onBlur={e => {
          if (company.state !== e.currentTarget.value) {
            handleUpdate({ state: e.currentTarget.value });
          }
        }}
        label={question.label}
        name={question.fieldName || ''}
        required={question.required}
        error={errors.state}
        disabled={!viewerCanUpdate}
      />
    ),
  country: ({ company, handleUpdate, viewerCanUpdate, question, errors, dragStartId }: Props) => {
    return (
      <SelectField
        label={question.label}
        values={company.country ? [company.country] : []}
        onChange={country => {
          if (country !== company.country) {
            handleUpdate({ country });
          }
        }}
        options={countries.slice(0, dragStartId === question.id ? 10 : undefined)}
        searchable
        clearable
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors.country}
      />
    );
  },
};

export default companySuggestedFields;
