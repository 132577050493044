/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateRegistrationForm, {
  type updateRegistrationPropertyType,
} from 'graph/mutations/registration/updateRegistrationForm';
import showModernMutationError from 'graph/utils/showModernMutationError';

import EventDates from './EventDates';
import EventName from './EventName';
import EventTimeZone from './EventTimeZone';

import type { EventInfo_event } from './__generated__/EventInfo_event.graphql';
import type { EventInfo_registrationForm } from './__generated__/EventInfo_registrationForm.graphql';

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: 10px;
  background-color: #eef0ff;
`;

const EventInfoContainer = styled.div`
  padding: 25px 10px;
`;

class EventInfo extends React.PureComponent<{
  registrationForm: EventInfo_registrationForm,
  event: EventInfo_event,
}> {
  updateRegistrationForm = (properties: updateRegistrationPropertyType) => {
    updateRegistrationForm({ ...properties, formId: this.props.registrationForm.id }).catch(
      showModernMutationError,
    );
  };

  render() {
    const { registrationForm, event } = this.props;
    const eventDates = {
      startDate: event.startDate,
      endDate: event.endDate,
      startDateAllDay: !!event.startDateAllDay,
      endDateAllDay: !!event.endDateAllDay,
    };
    return (
      <Container>
        <EventInfoContainer>
          <EventName
            value={registrationForm.eventName}
            eventName={event.name}
            onChange={this.updateRegistrationForm}
          />
          <EventDates
            start={registrationForm.eventStartDate}
            end={registrationForm.eventEndDate}
            startDateAllDay={!!registrationForm.startDateAllDay}
            endDateAllDay={!!registrationForm.endDateAllDay}
            eventDates={eventDates}
            tz={registrationForm.tz}
            onChange={this.updateRegistrationForm}
          />
          <EventTimeZone
            value={registrationForm.tz}
            eventTz={event.tz}
            onChange={this.updateRegistrationForm}
          />
        </EventInfoContainer>
      </Container>
    );
  }
}

export default createFragmentContainer(EventInfo, {
  registrationForm: graphql`
    fragment EventInfo_registrationForm on RegistrationForm {
      id
      eventName
      eventStartDate
      eventEndDate
      startDateAllDay
      endDateAllDay
      tz
    }
  `,
  event: graphql`
    fragment EventInfo_event on Event {
      id
      slug
      name
      startDate
      endDate
      startDateAllDay
      endDateAllDay
      tz
    }
  `,
});
