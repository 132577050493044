/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateStaffer from 'graph/mutations/staff/updateStaffer';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import type { EventOnsite_staffedEvent } from './__generated__/EventOnsite_staffedEvent.graphql';

const EditContainer = styled.div`
  display: flex;
`;

class EventOnsite extends React.PureComponent<
  {
    staffedEvent: EventOnsite_staffedEvent,
  },
  {
    editing: boolean,
  },
> {
  stopPropagation = (event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  handleOnSiteChange = (onsite: ?boolean): void => {
    if (onsite !== this.props.staffedEvent.onsite) {
      updateStaffer({
        membershipId: this.props.staffedEvent.id,
        onsite: onsite || false,
      }).catch(showModernMutationError);
    }
  };

  render() {
    const { onsite, viewerCanUpdate } = this.props.staffedEvent;
    return (
      <EditContainer onClick={this.stopPropagation}>
        <InlineSelectField
          onChange={this.handleOnSiteChange}
          options={[
            {
              label: 'On-site',
              value: true,
            },
            {
              label: 'Remote',
              value: false,
            },
          ]}
          value={onsite}
          disabled={!viewerCanUpdate}
          hideLockIcon
        />
      </EditContainer>
    );
  }
}

export default createFragmentContainer(
  EventOnsite,
  graphql`
    fragment EventOnsite_staffedEvent on StaffMembership {
      id
      onsite
      viewerCanUpdate
    }
  `,
);
