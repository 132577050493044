/**
 * @flow
 * @relayHash fcc545cc53ec5b879fbec96c9e0e1d64
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveVendorInput = {
  clientMutationId?: ?string,
  vendorId: string,
  eventId?: ?string,
};
export type removeVendorMutationVariables = {|
  input: RemoveVendorInput
|};
export type removeVendorMutationResponse = {|
  +removeVendor: ?{|
    +removedVendorId: string
  |}
|};
export type removeVendorMutation = {|
  variables: removeVendorMutationVariables,
  response: removeVendorMutationResponse,
|};
*/


/*
mutation removeVendorMutation(
  $input: RemoveVendorInput!
) {
  removeVendor(input: $input) {
    removedVendorId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveVendorInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeVendor",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveVendorInput!"
      }
    ],
    "concreteType": "RemoveVendorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedVendorId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeVendorMutation",
  "id": null,
  "text": "mutation removeVendorMutation(\n  $input: RemoveVendorInput!\n) {\n  removeVendor(input: $input) {\n    removedVendorId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeVendorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeVendorMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d8e79b44d684655b1acb74405d5e76f';
module.exports = node;
