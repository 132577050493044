/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactEmailOptIn_contact$ref: FragmentReference;
export type ContactEmailOptIn_contact = {|
  +emailOptIn: boolean,
  +$refType: ContactEmailOptIn_contact$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactEmailOptIn_contact",
  "type": "Contact",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "emailOptIn",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '28c9e7b4c90a963a66d4fade113293c1';
module.exports = node;
