/* @flow */
import * as React from 'react';

import QuestionAmount from '../Question/QuestionAmount';
import QuestionDate from '../Question/QuestionDate';
import QuestionExpense from '../Question/QuestionExpense';
import QuestionFile from '../Question/QuestionFile';
import QuestionInput from '../Question/QuestionInput';
import QuestionLink from '../Question/QuestionLink';
import QuestionLocation from '../Question/QuestionLocation';
import QuestionPlannedBudgetAmount from '../Question/QuestionPlannedBudgetAmount';
import QuestionTextArea from '../Question/QuestionTextArea';
import QuestionUserSelectOptions from '../Question/QuestionUserSelectOptions';
import mappingKinds from './mappingKinds';
import { type QuestionType, type QuestionValueInputType, type QuestionValueType } from './types';

const mappingComponents: $ObjMap<
  typeof mappingKinds,
  () => React.ComponentType<
    $Shape<{
      question: QuestionType,
      value: QuestionValueType,
      onChangeValue: QuestionValueInputType => void,
      handleShouldValidate?: (shouldValidate: boolean) => void,
      usedBudgetCategoeryIds?: $ReadOnlyArray<string>,
      readOnly: boolean,
      tz: string,
      currency: string,
      error: ?string,
      onChangeShowError: (showError: boolean) => void,
    }>,
  >,
> = {
  NAME: QuestionInput,
  LOCATION: QuestionLocation,
  DATE: QuestionDate,
  WEBSITE: QuestionLink,
  DESCRIPTION: QuestionTextArea,
  NOTE: QuestionTextArea,
  ATTACHMENTS: QuestionFile,
  BOOTH: QuestionInput,
  BOOTH_DIMENSIONS: QuestionInput,
  FLOOR_PLAN: QuestionTextArea,
  LEADER: QuestionUserSelectOptions,
  EXPENSE: QuestionExpense,
  BUDGETED_AMOUNT: QuestionAmount,
  PLANNED_BUDGET: QuestionPlannedBudgetAmount,
};

export default mappingComponents;
