/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import Subtasks from 'components/Subtasks';

import TaskSection from './TaskSection';

import type { TaskSubtasks_task } from './__generated__/TaskSubtasks_task.graphql';

class TaskSubtasks extends React.PureComponent<
  {
    task: TaskSubtasks_task,
  },
  {
    adding: boolean,
  },
> {
  state = { adding: this.props.task.subtasks.edges.length === 0 };

  handleStartAdding = () => {
    this.setState({ adding: true });
  };

  handleEndAdding = () => {
    this.setState({ adding: false });
  };

  render() {
    const subtasks = this.props.task.subtasks.edges.map(edge => edge.node);

    return (
      <TaskSection
        title="Subtasks"
        onAdd={this.handleStartAdding}
        disableAdd={!this.props.task.viewerCanUpdateSubtasks}
      >
        <Subtasks
          eventId={this.props.task.event.id}
          taskId={this.props.task.id}
          subtasks={subtasks}
          tz={this.props.task.event.tz}
          adding={this.state.adding}
          onEndAdding={this.handleEndAdding}
        />
      </TaskSection>
    );
  }
}

export default createFragmentContainer(
  TaskSubtasks,
  graphql`
    fragment TaskSubtasks_task on Deliverable {
      id
      viewerCanUpdateSubtasks
      subtasks {
        edges {
          node {
            ...Subtasks_subtasks
          }
        }
      }
      event {
        id
        tz
      }
    }
  `,
);
