/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import ProgressBar from 'components/material/ProgressBar';

import type { EventProgress_event } from './__generated__/EventProgress_event.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 84px;
  height: 7px;
  margin-right: 8px;
  background-color: #c5f4eb !important;
  border-radius: 10px;
  > div {
    background-color: #54d4bb;
  }
`;

const Text = styled.div`
  font-weight: bold;
`;

class EventProgress extends React.Component<{
  event: EventProgress_event,
}> {
  render() {
    if (this.props.event.progress == null) return null;
    return (
      <Container>
        <StyledProgressBar value={this.props.event.progress} />
        <Text>{`${this.props.event.progress}%`}</Text>
      </Container>
    );
  }
}

export default createFragmentContainer(
  EventProgress,
  graphql`
    fragment EventProgress_event on Event {
      progress
    }
  `,
);
