/* @flow */
import React from 'react';
import styled from 'styled-components';
import uniqueId from 'lodash/uniqueId';

import isValidEmail from 'utils/validators/isValidEmail';

import createContactFormBccEmail from 'graph/mutations/contactFormBccEmail/createContactFormBccEmail';
import removeContactFormBccEmail from 'graph/mutations/contactFormBccEmail/removeContactFormBccEmail';
import updateContactFormBccEmail from 'graph/mutations/contactFormBccEmail/updateContactFormBccEmail';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { Plus, Remove } from 'images';
import { MinimalButton } from 'components/budget/Button';
import TextInput from 'components/material/TextInput';
import inputBoxStyled from 'components/SharedForm/components/inputBoxStyled';
import { Error } from 'components/SharedForm/FieldComponents';

const Label = styled.div`
  margin-top: 33px;
  font-size: 16px;
  color: #828b93;
`;

const RowWrapper = styled.div`
  position: relative;
  margin-bottom: 22px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const TextField = styled(TextInput)`
  ${props => inputBoxStyled(props)};
  max-width: 479px;
  height: 36px;
  margin-top: 0 !important;
`;

const RemoveIcon = styled(Remove)`
  width: 15px;
  height: 15px;
  margin-left: 15px;
  cursor: pointer;
  color: #868f96;

  &:hover {
    color: #4db1dd;
  }
`;

const AddNewBccButton = styled(MinimalButton)`
  margin-bottom: 42px;
  font-size: 14px;
  font-weight: normal;
  color: #3ba9da;
`;

const StyledPlus = styled(Plus)`
  margin-right: 10px;
  margin-top: -3px;
`;

const generateBlankBccEmail = () => ({ id: uniqueId('question_'), email: '' });

type BccEmailType = {
  id: string,
  relayId?: string,
  email: string,
};

export default class BccEmails extends React.Component<
  {
    formId: string,
    bccEmails: $ReadOnlyArray<{ +id: string, +email: string }>,
    onUpdateErrors: (errors: { [string]: ?string | ?boolean }) => void,
    errors: { [string]: ?string | ?boolean },
  },
  {
    bccEmails: $ReadOnlyArray<BccEmailType>,
    autofocus: boolean,
  },
> {
  state = (() => {
    const bccEmails = this.props.bccEmails;

    if (bccEmails.length === 0) {
      return { bccEmails: [generateBlankBccEmail()], autofocus: false };
    }
    return {
      autofocus: false,
      bccEmails: bccEmails.map(bccEmail => ({
        id: bccEmail.id,
        relayId: bccEmail.id,
        email: bccEmail.email,
      })),
    };
  })();

  handleAddNewBccEmail = () => {
    this.setState(prevState => ({
      bccEmails: [...prevState.bccEmails, generateBlankBccEmail()],
      autofocus: true,
    }));
  };

  handleBccEmailSave = (bccEmail: BccEmailType, e: SyntheticEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value;
    const bccEmails = this.state.bccEmails;

    if (email && bccEmails.some(bccItem => bccItem.id !== bccEmail.id && bccItem.email === email)) {
      this.props.onUpdateErrors({ ...this.props.errors, [bccEmail.id]: 'Email Exists' });
      return;
    }

    if (!bccEmail.relayId) {
      if (isValidEmail(email)) {
        this.props.onUpdateErrors({ ...this.props.errors, UNSAVED: null });
        createContactFormBccEmail({
          formId: this.props.formId,
          email,
        })
          .then(resp => {
            if (resp.createContactFormBccEmail) {
              const relayId = resp.createContactFormBccEmail.contactFormBccEmailsEdge.node.id;
              this.setState(prevState => ({
                bccEmails: prevState.bccEmails.map(bccItem =>
                  bccItem.id === bccEmail.id ? { ...bccItem, relayId } : bccItem,
                ),
              }));
            }
          })
          .catch(showModernMutationError);
      }
      this.props.onUpdateErrors({
        ...this.props.errors,
        [bccEmail.id]: email.trim() && !isValidEmail(email) ? 'Invalid email address' : null,
      });
      return;
    }
    if (bccEmail.relayId && email.trim() && bccEmail.email !== email && isValidEmail(email)) {
      updateContactFormBccEmail({
        contactFormBccEmailId: bccEmail.relayId,
        email: e.currentTarget.value,
      }).catch(showModernMutationError);
    }
    if (bccEmail.relayId && bccEmails.length === 1 && !email.trim()) {
      removeContactFormBccEmail(this.props.formId, {
        contactFormBccEmailId: bccEmail.relayId,
      })
        .then(() => {
          this.setState(prevState => ({
            bccEmails: prevState.bccEmails.map(bccItem =>
              bccItem.id === bccEmail.id ? generateBlankBccEmail() : bccItem,
            ),
          }));
        })
        .catch(showModernMutationError);
    } else if (!isValidEmail(email)) {
      this.props.onUpdateErrors({
        ...this.props.errors,
        [bccEmail.id]: 'Invalid email address',
      });
      return;
    }

    // when email is same just removing error
    this.props.onUpdateErrors({ ...this.props.errors, [bccEmail.id]: null });
  };

  handleRemoveBccEmail = (bccEmailId: string) => {
    const bccEmail = this.state.bccEmails.find(bccItem => bccItem.id === bccEmailId);

    if (!bccEmail) {
      return;
    }

    this.props.onUpdateErrors({ ...this.props.errors, [bccEmailId]: null });
    this.setState(prevState => ({
      bccEmails: prevState.bccEmails.filter(bccItem => bccItem.id !== bccEmailId),
    }));

    if (bccEmail.relayId) {
      removeContactFormBccEmail(this.props.formId, {
        contactFormBccEmailId: bccEmail.relayId,
      }).catch(showModernMutationError);
    }
  };

  render() {
    const { bccEmails, autofocus } = this.state;
    const errors = this.props.errors;
    return (
      <>
        <Label>BCC</Label>
        {bccEmails.map((bccEmail, index) => (
          <RowWrapper key={bccEmail.id} error={errors[bccEmail.id]}>
            <Row key={bccEmail.id}>
              <TextField
                onBlur={e => this.handleBccEmailSave(bccEmail, e)}
                defaultValue={bccEmail.email}
                error={errors[bccEmail.id]}
                autoFocus={autofocus && index === bccEmails.length - 1}
              />
              {bccEmails.length > 1 && (
                <RemoveIcon onClick={() => this.handleRemoveBccEmail(bccEmail.id)} />
              )}
            </Row>
            {errors[bccEmail.id] && <Error>{errors[bccEmail.id]}</Error>}
          </RowWrapper>
        ))}
        <AddNewBccButton
          label={
            <div>
              <StyledPlus />
              Add BCC Address
            </div>
          }
          onClick={this.handleAddNewBccEmail}
        />
      </>
    );
  }
}
