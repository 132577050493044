/* @flow */
import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  & > div {
    position: absolute;
    box-sizing: border-box;
    width: ${props => (props.size * 80) / 100}px;
    height: ${props => (props.size * 80) / 100}px;
    // margin = size - border to move the spinner to center
    margin: ${props => Math.max((props.size * 20) / 100 - props.size / 20, 0)}px;
    border-width: ${props => props.size / 20}px;
    border-style: solid;
    border-radius: 50%;
    border-color: ${props => `${props.color || '#6f6cd8'} transparent transparent transparent;`};
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  & > div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & > div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & > div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Loader = (props: { className?: ?string, size?: ?number, color?: ?string }) => {
  return (
    <Spinner
      className={props.className}
      size={props.size == null ? 60 : props.size}
      color={props.color}
    >
      <div />
      <div />
      <div />
      <div />
    </Spinner>
  );
};

export default Loader;
