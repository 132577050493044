/* @flow */
import moment from 'moment-timezone';

import formatTime from './formatTime';

export default function formatTimeRange(
  startDate: ?moment,
  endDate: ?moment,
  { showPrefix }: { showPrefix: boolean } = { showPrefix: false },
): string {
  if (startDate && endDate) {
    const bothSamePeriod = startDate.format('a') === endDate.format('a');

    return `${showPrefix ? 'from ' : ''}${formatTime(startDate, {
      hideTz: true,
      hidePeriod: bothSamePeriod,
    })} - ${formatTime(endDate)}`;
  }
  if (startDate) return `${showPrefix ? 'at ' : ''}${formatTime(startDate)}`;
  if (endDate) return `to ${formatTime(endDate)}`;
  return '';
}
