/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import moment from 'moment';

import type { FieldType } from 'utils/customization/types';

import { type ColumnGroupConfiguration } from 'components/AllEvents/lib/getEventFields';
import { type ParsedAllEventsFilters } from 'components/AllEvents/lib/parseAllEventsFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import AllEventsHeaderBar from '../components/AllEventsHeaderBar';
import AllEventsCalendarView from './AllEventsCalendarView';

import { type AllEventsCalendar_eventList } from './__generated__/AllEventsCalendar_eventList.graphql';
import { type AllEventsCalendar_org } from './__generated__/AllEventsCalendar_org.graphql';
import { type AllEventsCalendar_user } from './__generated__/AllEventsCalendar_user.graphql';
import { type AllEventsCalendarQueryResponse } from './__generated__/AllEventsCalendarQuery.graphql';

const query = graphql`
  query AllEventsCalendarQuery($filters: EventFilters!) {
    me {
      events(filters: $filters) {
        edges {
          node {
            ...AllEventsCalendarView_events
          }
        }
      }
    }
  }
`;

class AllEventsCalendar extends React.Component<
  {
    org: AllEventsCalendar_org,
    user: AllEventsCalendar_user,
    eventList: ?AllEventsCalendar_eventList,
    history: RouterHistory,
    eventFields: $ReadOnlyArray<FieldType>,
    shownColumns: $ReadOnlyArray<string>,
    pathPrefix: string,
    filters: ParsedAllEventsFilters,
    filterVariables: {},
    groups: ColumnGroupConfiguration,
  },
  { eventsCount: number },
> {
  state = { eventsCount: 0 };

  setEventsCount = (eventsCount: number) => {
    if (this.state.eventsCount !== eventsCount) {
      this.setState({ eventsCount });
    }
  };

  renderCalendar = (month: string, week: ?string) => (
    response?: AllEventsCalendarQueryResponse,
  ) => {
    if (response) this.setEventsCount(response.me.events.edges.length);

    return (
      <AllEventsCalendarView
        events={response ? response.me.events.edges.map(({ node }) => node) : null}
        month={month}
        week={week}
        history={this.props.history}
      />
    );
  };

  render() {
    const {
      org,
      user,
      eventList,
      eventFields,
      shownColumns,
      pathPrefix,
      filters,
      filterVariables,
      groups,
    } = this.props;

    const month =
      filters.month != null ? filters.month.format() : moment().startOf('month').format();
    const week = filters.week != null ? filters.week.format() : null;
    const afterDate = moment(week || month)
      .subtract(week ? 1 : 6, 'days')
      .format();
    const beforeDate = moment(week || month)
      .endOf(week ? 'week' : 'month')
      .add(week ? 1 : 6, 'days')
      .format();

    const variables = {
      filters: {
        ...filterVariables,
        listType: undefined,
        includeUndated: undefined,
        afterDate,
        beforeDate,
      },
    };

    return (
      <div>
        <AllEventsHeaderBar
          org={org}
          user={user}
          eventList={eventList}
          pathPrefix={pathPrefix}
          filters={filters}
          eventFields={eventFields}
          shownColumns={shownColumns}
          groups={groups}
          fromWindow="calendar"
          csvExportVariables={variables.filters}
          viewMode="calendar"
          eventsCount={this.state.eventsCount}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={this.renderCalendar(month, week)}
          renderLoading={this.renderCalendar(month, week)}
        />
      </div>
    );
  }
}

export default createFragmentContainer(AllEventsCalendar, {
  org: graphql`
    fragment AllEventsCalendar_org on Org {
      ...AllEventsHeaderBar_org
    }
  `,
  user: graphql`
    fragment AllEventsCalendar_user on User {
      ...AllEventsHeaderBar_user
    }
  `,
  eventList: graphql`
    fragment AllEventsCalendar_eventList on EventList {
      ...AllEventsHeaderBar_eventList
    }
  `,
});
