/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import NumberField from './components/NumberField';

import type { EventContactsCount_event } from './__generated__/EventContactsCount_event.graphql';

const EventContactsCount = (props: { event: EventContactsCount_event }) => {
  const eventContacts = props.event.eventContacts;
  return eventContacts ? <NumberField value={eventContacts.totalCount} /> : null;
};

export default createFragmentContainer(
  EventContactsCount,
  graphql`
    fragment EventContactsCount_event on Event {
      eventContacts {
        totalCount
      }
    }
  `,
);
