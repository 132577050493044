/* @flow */
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 35px;
  padding: 8px 10px;
  width: 100%;
  font-size: 13px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 9;
`;

const StyledLink = styled.div`
  color: #3aa8da;
  cursor: pointer;
`;

const StyledText = styled.div`
  color: #3e4859;
`;

class ExpenseSearchSuggestion extends React.Component<{
  onAddExpenseClick: () => void,
}> {
  render() {
    return (
      <Container>
        <StyledText>Don&apos;t see Expense you&apos;re looking for?</StyledText>
        <div>
          <StyledLink onMouseDown={this.props.onAddExpenseClick}>Add Expense</StyledLink>
        </div>
      </Container>
    );
  }
}

export default ExpenseSearchSuggestion;
