/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Remove from 'images/remove.svg';

import pageComponentsConfig, { type PageComponentConfigType } from '../../pageComponentsConfig';
import type { SelectedComponent } from '../../RegistrationCreateContent';

import RegistrationPageComponents_org from './__generated__/RegistrationPageComponents_org.graphql';
import RegistrationPageComponents_selectedPageComponent from './__generated__/RegistrationPageComponents_selectedPageComponent.graphql';

export const Section = styled.div`
  margin-bottom: 25px;
  padding: 0 20px 5px;
  border-bottom: 1px solid #d2d9f5;

  &:last-child {
    border-bottom: 0;
  }
`;

const Container = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 72px;
  padding-left: 15px;
  border-bottom: 1px solid #d2d9f5;
  text-transform: uppercase;
  font-family: Roboto-Slab;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  color: #383e56;
`;

const Content = styled.div`
  flex: 1 1 auto;
  padding: 20px 0;
  overflow-y: auto;
`;

const RemoveIcon = styled(Remove)`
  flex-shrink: 0;
  width: 30px;
  height: 20px;
  margin: 0 25px 0 auto;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionDarkerColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkestColor};
  }
`;

const IconWrapper = styled.div`
  margin: 0 15px 0 10px;
`;

class RegistrationPageComponents extends React.PureComponent<{
  org: RegistrationPageComponents_org,
  selectedPageComponent: RegistrationPageComponents_selectedPageComponent,
  onClose: () => void,
  onChangeSelectedComponentProperty: (updatedProps: SelectedComponent) => void,
}> {
  getSelectedComponentConfig = (): ?PageComponentConfigType => {
    const kind = this.props.selectedPageComponent.kind;

    return pageComponentsConfig.find(config => config.kind === kind);
  };

  render() {
    const { org, selectedPageComponent, onClose, onChangeSelectedComponentProperty } = this.props;
    const selectedComponentConfig = this.getSelectedComponentConfig();
    if (!selectedComponentConfig) {
      console.log(
        'Component type is not defined. Specify new components in pageComponentsConfig.js',
      );
      return null;
    }
    const { styleComponent: Component, icon, label } = selectedComponentConfig;
    return (
      <Container className="component-styling">
        <Header>
          <IconWrapper>{icon}</IconWrapper>
          {`${label} styles`} <RemoveIcon onClick={onClose} />
        </Header>
        <Content>
          <Component
            key={selectedPageComponent.id}
            org={org}
            selectedPageComponent={selectedPageComponent}
            onChangeComponentProperty={onChangeSelectedComponentProperty}
          />
        </Content>
      </Container>
    );
  }
}

export default createFragmentContainer(RegistrationPageComponents, {
  org: graphql`
    fragment RegistrationPageComponents_org on Org {
      id
      ...ImageComponent_org
      ...EventNameComponent_org
      ...EventDatesComponent_org
      ...TextComponent_org
      ...DividerComponent_org
      ...VideoComponent_org
      ...EmbedComponent_org
      ...RowComponent_org
    }
  `,
  selectedPageComponent: graphql`
    fragment RegistrationPageComponents_selectedPageComponent on RegistrationPageComponent {
      id
      kind
      ...ImageComponent_selectedPageComponent
      ...EventNameComponent_selectedPageComponent
      ...EventDatesComponent_selectedPageComponent
      ...TextComponent_selectedPageComponent
      ...DividerComponent_selectedPageComponent
      ...EmbedComponent_selectedPageComponent
      ...VideoComponent_selectedPageComponent
      ...RowComponent_selectedPageComponent
    }
  `,
});
