import './basic-dropdown.styl';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import classNames from 'classnames';

export default class BasicDropdown extends Component {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func,
      }),
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    itemComponent: PropTypes.func.isRequired,
    selectedComponent: PropTypes.func.isRequired,
    listComponent: PropTypes.func.isRequired,
    searchable: PropTypes.bool,
    disabled: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
  };
  static defaultProps = {
    itemComponent: item => (
      <li>
        {item.text}
      </li>
    ),
    selectedComponent: text => (
      <span>
        {text}
      </span>
    ),
    listComponent: options => options.map(o => o.component),
  };
  state = {
    open: false,
    search: '',
  };
  handleHide = () => {
    this.setState({ open: false, search: '' });
  };
  handleShow = () => {
    if (!this.props.disabled) {
      this.setState({ open: true });
    }
  };
  handleSelect = value => {
    this.handleHide();
    this.props.onChange(value);
  };
  handleSearchChange = e => {
    this.setState({ search: e.target.value });
  };
  filterItems = items => {
    const query = this.state.search.trim();
    if (!query) return items;

    const queryRegExp = new RegExp(query, 'i');
    return items.filter(item => queryRegExp.test(item.text));
  };
  render() {
    const {
      label,
      className,
      items,
      value,
      placeholder,
      itemComponent,
      selectedComponent,
      listComponent,
      searchable,
      disabled,
      alwaysOpen,
    } = this.props;
    const options = value != null ? items.filter(item => item.value !== value) : items;
    const selected = value != null ? items.find(item => item.value === value) : null;

    return (
      <div
        className={classNames('basic-dropdown', className)}
        onClick={this.handleShow}
        onMouseLeave={this.handleHide}
      >
        {label && <div className="basic-dropdown-label">{label}</div>}
        <div
          className={classNames('basic-dropdown-selected', {
            placeholder: !selected && placeholder,
          })}
        >
          {selectedComponent(selected ? selected.text : value || placeholder)}
          {(options.length > 0 || alwaysOpen) && !disabled && <i className="fa fa-angle-down" />}
        </div>
        {this.state.open &&
          (options.length > 0 || alwaysOpen) &&
          <ul className="basic-dropdown-items">
            {searchable &&
              options.length > 5 &&
              <input
                type="text"
                className="basic-dropdown-search"
                placeholder="Search..."
                autoFocus
                onChange={this.handleSearchChange}
                value={this.state.search}
              />}
            {listComponent(
              (searchable
                ? this.filterItems(options).slice(0, 5)
                : this.filterItems(options)).map(item => ({
                item,
                component: React.cloneElement(itemComponent(item), {
                  key: item.value,
                  onClick: e => {
                    e.stopPropagation();

                    if (item.onClick) item.onClick(e);

                    if (!e.defaultPrevented) {
                      this.handleSelect(item.value);
                    }
                  },
                }),
              })),
            )}
          </ul>}
      </div>
    );
  }
}
