/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type EmailActivityEventScope = "MY_EVENTS" | "MY_TEAMS";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EmailSubscriptions_user$ref: FragmentReference;
export type EmailSubscriptions_user = {|
  +id: string,
  +tz: string,
  +emailSubscription: {|
    +activitySummary: number,
    +eventScope: EmailActivityEventScope,
    +assignmentNotifications: number,
    +activityNotifications: boolean,
    +mentionNotifications: boolean,
    +reminders: boolean,
    +postEventEngagementReport: boolean,
    +contactRegistrationNotifications: boolean,
    +contactAttendanceNotifications: boolean,
    +addEventStaffNotifications: boolean,
  |},
  +$refType: EmailSubscriptions_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EmailSubscriptions_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "emailSubscription",
      "storageKey": null,
      "args": null,
      "concreteType": "EmailSubscription",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "activitySummary",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "eventScope",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "assignmentNotifications",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "activityNotifications",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "mentionNotifications",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "reminders",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "postEventEngagementReport",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "contactRegistrationNotifications",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "contactAttendanceNotifications",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "addEventStaffNotifications",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '87a6d4da269b073246d55d895ae0b146';
module.exports = node;
