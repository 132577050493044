/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';
import formatTime from 'utils/date/formatTime';

import Tooltip from 'components/material/Tooltip';

const ClockTooltip = styled(Tooltip)`
  display: inline-block;
  font-size: 12px;
  margin-left: 5px;
  color: ${props => props.theme.secondaryActionColor};
`;

const Container = styled.div`
  ${props =>
    props.isOverdue &&
    css`
      color: ${props.theme.negativeActionColor};
    `};
`;

export default class DateTimeTooltip extends React.Component<{
  date: moment,
  hideTime?: boolean,
  isOverdue?: boolean,
  placement?: 'top' | 'bottom' | 'right' | 'left',
}> {
  static defaultProps = {
    placement: 'top',
  };

  render() {
    return (
      <Container isOverdue={this.props.isOverdue}>
        {formatDate(this.props.date)}
        {!this.props.hideTime && (
          <ClockTooltip label={formatTime(this.props.date)} placement={this.props.placement}>
            <i className="fa fa-fw fa-clock-o" />
          </ClockTooltip>
        )}
      </Container>
    );
  }
}
