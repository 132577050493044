/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import SalesforceMapping from './SalesforceMapping';

import type {
  SalesforceKindType,
  SalesforceMappingQueryResponse,
} from './__generated__/SalesforceMappingQuery.graphql';

const query = graphql`
  query SalesforceMappingQuery(
    $kinds: [SalesforceKindType!]!
    $isContact: Boolean!
    $isEventContact: Boolean!
    $isEvent: Boolean!
    $isOpportunity: Boolean!
  ) {
    org {
      ...SalesforceMapping_org
    }
  }
`;

export default class SalesforceMappingRoot extends React.PureComponent<{
  kinds: $ReadOnlyArray<SalesforceKindType>,
}> {
  render() {
    const kinds = this.props.kinds;
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          kinds,
          isContact: kinds.some(kind => ['CONTACT', 'LEAD'].includes(kind)),
          isEventContact: kinds.includes('EVENT_CONTACT'),
          isEvent: kinds.includes('EVENT'),
          isOpportunity: kinds.includes('OPPORTUNITY'),
        }}
        renderSuccess={(props: SalesforceMappingQueryResponse) => (
          <SalesforceMapping org={props.org} kinds={kinds} />
        )}
      />
    );
  }
}
