/* @flow */
import React from 'react';
import styled from 'styled-components';
import showErrorPopup from 'utils/showErrorPopup';
import type { Location, RouterHistory } from 'react-router-dom';
import Button from 'components/material/Button';

const Container = styled.div`
  padding: 10px 20px;
`;

const ErrorButton = styled(Button)`
  margin-right: 10px;
`;

const Row = styled.div`
  padding: 8px 0;
`;

export default class ErrorTester extends React.Component<{
  history: RouterHistory,
  location: Location,
}> {
  handleRendererError = () => {
    this.props.history.push({
      search: '?throwerror=true',
    });
  }

  handleHandlerError = () => {
    throw new Error('Ignore. This error was thrown to test EG callback error handling.');
  }

  handlePopup = () => {
    showErrorPopup(new Error('Testing the popup.'));
  }

  render() {
    if (this.props.location.search.includes('throwerror')) {
      throw new Error('Ignore. This error was thrown to test EG render error handling.');
    }

    return (
      <Container>
        <h1>This page is for testing our error handling.</h1>
        <Row>
          <ErrorButton
            onClick={this.handleRendererError}
            label="Render Error"
            negative
          />
          Causes an error in render() which should be handled by an ErrorBoundary.
        </Row>
        <Row>
          <ErrorButton
            onClick={this.handleHandlerError}
            label="Handler Error"
            negative
          />
          Causes an async error in a callback, which only shows up in a console.
        </Row>
        <Row>
          <ErrorButton
            onClick={this.handlePopup}
            label="Popup"
            negative
          />
          Show the error popup window.
        </Row>
      </Container>
    );
  }
}
