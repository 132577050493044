/* @flow */
import checkEnabled from './checkEnabled';

let queue: Array<() => void> = [];
let loaded = false;

/**
 * Enqueues function to be called when analytics is loaded or immediately if already loaded
 *
 * @param {function} fn to be called
 */
const enqueueCall = (fn: () => void) => {
  if (checkEnabled()) {
    if (loaded) {
      fn();
    } else {
      queue.push(fn);
    }
  }
};

/**
 * Internal method to be called when analytics in initialized.
 * Window analytics doesn't exist before this.
 */
const initQueue = () => {
  if (typeof window !== 'undefined' && typeof window.analytics !== 'undefined') {
    window.analytics.ready(() => {
      loaded = true;

      if (checkEnabled()) {
        queue.forEach(fn => fn());
      }

      queue = [];
    });
  }
};

export { enqueueCall as default, initQueue };
