/* @flow */
import type { RecordSourceSelectorProxy, SelectorData, RecordProxy } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import getMutationRoot from './lib/getMutationRoot';
import getConnection, { type Connection } from './lib/getConnection';

type Config = {
  // Parent record relay id
  parentId: string,
  connections: $ReadOnlyArray<Connection>,
  // Edge name in response
  edgeName: string,
  rangeBehavior?: 'append' | 'prepend',
};

function addRecordToConnection({ parentId, connections, edgeName, rangeBehavior }: Config) {
  return (store: RecordSourceSelectorProxy, data: SelectorData) => {
    const parentRecord: RecordProxy = store.get(parentId);

    if (!parentRecord) return;

    const root = getMutationRoot(store, data);
    if (!root) throw new Error('Root field missing in mutation');

    let edges: Array<RecordProxy> = [];

    try {
      edges = [root.getLinkedRecord(edgeName)];
    } catch (e) {
      edges = root.getLinkedRecords(edgeName);
    }

    if (!edges.length === 0) throw new Error('Edge missing in response');

    connections.forEach(conConfig => {
      const connection = getConnection(parentRecord, conConfig);

      if (connection) {
        edges.forEach(edge => {
          // TODO: Remove this temporary fix after https://github.com/facebook/relay/pull/2349 merge
          const node = edge.getLinkedRecord('node');
          const newEdge = ConnectionHandler.createEdge(store, connection, node, edgeName);
          newEdge.copyFieldsFrom(edge);

          if (rangeBehavior === 'append') {
            ConnectionHandler.insertEdgeAfter(connection, newEdge);
          } else {
            ConnectionHandler.insertEdgeBefore(connection, newEdge);
          }
        });
      }
    });
  };
}

export default addRecordToConnection;
