/**
 * @flow
 * @relayHash 5a67a9e470519e5fe5525ac27db987a8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RequestSubmissionList_requestSubmissions$ref = any;
export type Direction = "ASC" | "DESC";
export type EventRequestFormSubmissionSort = "DECLINE_REASON" | "EVENT_NAME" | "NAME" | "REQUESTED_BY" | "REQUESTED_DATE" | "STATUS";
export type ContactRequestsQueryVariables = {|
  contactId: string,
  sort?: ?EventRequestFormSubmissionSort,
  direction: Direction,
|};
export type ContactRequestsQueryResponse = {|
  +me: {|
    +tz: string
  |},
  +contact: ?{|
    +eventRequestSubmissions?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +$fragmentRefs: RequestSubmissionList_requestSubmissions$ref
        |}
      |}>
    |}
  |},
|};
export type ContactRequestsQuery = {|
  variables: ContactRequestsQueryVariables,
  response: ContactRequestsQueryResponse,
|};
*/


/*
query ContactRequestsQuery(
  $contactId: ID!
  $sort: EventRequestFormSubmissionSort
  $direction: Direction!
) {
  me {
    tz
    id
  }
  contact: node(id: $contactId) {
    __typename
    ... on Contact {
      eventRequestSubmissions(sort: $sort, direction: $direction) {
        edges {
          node {
            ...RequestSubmissionList_requestSubmissions
            id
          }
        }
      }
    }
    id
  }
}

fragment RequestSubmissionList_requestSubmissions on EventRequestSubmission {
  id
  ...RequestSubmissionStatus_requestSubmission
  ...RequestSubmissionFormName_requestSubmission
  ...RequestSubmissionRequestedDate_requestSubmission
  ...RequestSubmissionEventName_requestSubmission
  ...RequestSubmissionFormReviewers_requestSubmission
}

fragment RequestSubmissionStatus_requestSubmission on EventRequestSubmission {
  id
  status
}

fragment RequestSubmissionFormName_requestSubmission on EventRequestSubmission {
  form {
    name
    id
  }
}

fragment RequestSubmissionRequestedDate_requestSubmission on EventRequestSubmission {
  createdAt
}

fragment RequestSubmissionEventName_requestSubmission on EventRequestSubmission {
  id
  event {
    name
    id
  }
}

fragment RequestSubmissionFormReviewers_requestSubmission on EventRequestSubmission {
  reviewers {
    edges {
      node {
        ...UsersGroup_users
        id
      }
    }
  }
}

fragment UsersGroup_users on Assignable {
  id
  email
  firstName
  lastName
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "contactId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "EventRequestFormSubmissionSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tz",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "contactId",
    "type": "ID!"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction",
    "type": "Direction"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "EventRequestFormSubmissionSort"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  v4
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ContactRequestsQuery",
  "id": null,
  "text": "query ContactRequestsQuery(\n  $contactId: ID!\n  $sort: EventRequestFormSubmissionSort\n  $direction: Direction!\n) {\n  me {\n    tz\n    id\n  }\n  contact: node(id: $contactId) {\n    __typename\n    ... on Contact {\n      eventRequestSubmissions(sort: $sort, direction: $direction) {\n        edges {\n          node {\n            ...RequestSubmissionList_requestSubmissions\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment RequestSubmissionList_requestSubmissions on EventRequestSubmission {\n  id\n  ...RequestSubmissionStatus_requestSubmission\n  ...RequestSubmissionFormName_requestSubmission\n  ...RequestSubmissionRequestedDate_requestSubmission\n  ...RequestSubmissionEventName_requestSubmission\n  ...RequestSubmissionFormReviewers_requestSubmission\n}\n\nfragment RequestSubmissionStatus_requestSubmission on EventRequestSubmission {\n  id\n  status\n}\n\nfragment RequestSubmissionFormName_requestSubmission on EventRequestSubmission {\n  form {\n    name\n    id\n  }\n}\n\nfragment RequestSubmissionRequestedDate_requestSubmission on EventRequestSubmission {\n  createdAt\n}\n\nfragment RequestSubmissionEventName_requestSubmission on EventRequestSubmission {\n  id\n  event {\n    name\n    id\n  }\n}\n\nfragment RequestSubmissionFormReviewers_requestSubmission on EventRequestSubmission {\n  reviewers {\n    edges {\n      node {\n        ...UsersGroup_users\n        id\n      }\n    }\n  }\n}\n\nfragment UsersGroup_users on Assignable {\n  id\n  email\n  firstName\n  lastName\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ContactRequestsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "contact",
        "name": "node",
        "storageKey": null,
        "args": v2,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Contact",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventRequestSubmissions",
                "storageKey": null,
                "args": v3,
                "concreteType": "EventRequestSubmissionRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventRequestSubmissionRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EventRequestSubmission",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "RequestSubmissionList_requestSubmissions",
                            "args": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContactRequestsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          v4
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "contact",
        "name": "node",
        "storageKey": null,
        "args": v2,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v4,
          {
            "kind": "InlineFragment",
            "type": "Contact",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventRequestSubmissions",
                "storageKey": null,
                "args": v3,
                "concreteType": "EventRequestSubmissionRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventRequestSubmissionRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EventRequestSubmission",
                        "plural": false,
                        "selections": [
                          v4,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "form",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "EventRequestForm",
                            "plural": false,
                            "selections": v5
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "event",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Event",
                            "plural": false,
                            "selections": v5
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "reviewers",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserRequiredConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserRequiredEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "plural": false,
                                    "selections": [
                                      v4,
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "email",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "firstName",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "lastName",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "avatar",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d04114b92fa8ff84a78c373acbe5a4c';
module.exports = node;
