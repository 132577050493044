/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation followTaskMutation($input: FollowDeliverableInput!) {
    followDeliverable(input: $input) {
      deliverable {
        id
        following
      }
    }
  }
`;

export default function followTask(taskId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableId: taskId,
      },
    },
    optimisticResponse: {
      followDeliverable: {
        deliverable: { id: taskId, following: true },
      },
    },
  });
}
