/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import getBudgetQuarterString from 'utils/fiscal/getBudgetQuarterString';

import CategoryPill from 'components/budget/CategoryPill';
import { type CellPropsType } from 'components/budget/Table';
import InlineSelectField from 'components/budget/Table/InlineSelectField';

import { type ExpenseCellPropsType } from './index';

import { type ExpenseBudgetCategoryCell_expense } from './__generated__/ExpenseBudgetCategoryCell_expense.graphql';
import { type ExpenseBudgetCategoryCell_org } from './__generated__/ExpenseBudgetCategoryCell_org.graphql';

const ManageLink = styled.a`
  color: #3ba9da;
`;

const preventDefault = (event: SyntheticEvent<HTMLElement>) => {
  event.preventDefault();
};

const ExpenseBudgetCategoryCell = ({
  expense,
  org,
  updateExpense,
  updateColumnWidth,
}: CellPropsType<
  ExpenseCellPropsType<ExpenseBudgetCategoryCell_expense, {}, ExpenseBudgetCategoryCell_org>,
>) => (
  <InlineSelectField
    options={[
      ...sortBy(
        org.budgetCategories.edges.map(edge => edge.node),
        'name',
      ).map(category => ({
        label: category.name,
        value: category.id,
        info: category.description,
      })),
      org.viewerCanUpdate
        ? {
            label: 'Manage Categories',
            value: 'manage',
            displayLabel: (
              <ManageLink
                href={`/settings/budgets/${getBudgetQuarterString(org.settings.fiscalYearStart)}`}
                target="_blank"
                onClick={preventDefault}
              >
                Manage Categories
              </ManageLink>
            ),
          }
        : null,
    ].filter(Boolean)}
    value={expense.budgetCategory && expense.budgetCategory.id}
    searchable
    placeholder="Select Category"
    onChange={categoryId => {
      if (categoryId === 'manage') {
        window.open(`/settings/budgets/${getBudgetQuarterString(org.settings.fiscalYearStart)}`);

        return;
      }

      updateExpense({ categoryId }).then(() => {
        updateColumnWidth();
      });
    }}
    clearable
  >
    {expense.budgetCategory && <CategoryPill category={expense.budgetCategory} />}
  </InlineSelectField>
);

export default createFragmentContainer(
  ExpenseBudgetCategoryCell,
  graphql`
    fragment ExpenseBudgetCategoryCell_expense on Expense {
      budgetCategory {
        id
        ...CategoryPill_category
      }
    }

    fragment ExpenseBudgetCategoryCell_org on Org {
      viewerCanUpdate

      settings {
        fiscalYearStart
      }
      budgetCategories {
        edges {
          node {
            id
            name
            description
          }
        }
      }
    }
  `,
);
