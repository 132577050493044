/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';

import currentOrigin from 'utils/currentOrigin';

import { OutlineButton } from 'components/budget/Button';
import CopyLink, { Icon } from 'components/CopyLink';
import EmbedButton from 'components/EmbedButton';

import RegistrationFormSubmissionContent from '../RegistrationFormSubmission/RegistrationFormSubmissionContent';
import RegistrationResults from '../RegistrationResults';
import RegistrationFormActionsBar from './RegistrationFormActionsBar';

import type { ExistingRegistrationForm_event } from './__generated__/ExistingRegistrationForm_event.graphql';
import type { ExistingRegistrationForm_org } from './__generated__/ExistingRegistrationForm_org.graphql';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const EmbedWrapper = styled.div`
  margin-left: 5px;
`;

const StyledEmbedButton = styled(EmbedButton)`
  border: none;
  border-radius: 0;
  color: #868f96;
  font-size: 18px;
  font-weight: 500;
  &:hover,
  &:focus {
    background: transparent;
    color: ${props => props.theme.primaryActionColor};
  }
`;

const StyledCopyLink = styled(CopyLink)`
  cursor: pointer;
  color: #868f96;
  &:hover {
    color: ${props => props.theme.primaryActionColor};
  }
  ${Icon} {
    flex: 1 auto;
  }
`;

const LeftSide = styled.div`
  position: relative;
  flex: 1 1 auto;
  min-width: 450px;
  max-width: 40%;
  cursor: pointer;
  margin-right: 55px;
  margin-bottom: 30px;
  overflow-x: hidden;
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1em;
  margin-bottom: 20px;
`;

const Highlight = styled.div`
  flex: 0 0 auto;
  font-weight: 500;
  color: #000000;
  padding-right: 5px;
`;

const Date = styled.div`
  flex: 1 1 auto;
  color: #000000;
`;

const StyledLink = styled.a`
  flex: 0 1 auto;
  margin-right: 14px;
  font-size: 14px;
  line-height: 14px;
  color: #5e5bff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Actions = styled.div`
  display: flex;
  flex: 1 auto;
  align-items: center;
  margin-left: 5px;
`;

const StyledRegistrationFormSubmissionContent = styled(RegistrationFormSubmissionContent)`
  min-height: auto;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  border: solid 1px #dadada;
`;

const RightSide = styled.div`
  flex: 1 1 auto;
`;

const RegistrationSummary = styled.div`
  margin-top: 68px;
`;

class ExistingRegistrationForm extends React.PureComponent<{
  event: ExistingRegistrationForm_event,
  org: ExistingRegistrationForm_org,
  history: RouterHistory,
  navigateToFormBuilder: (e: SyntheticEvent<HTMLElement>) => void,
}> {
  navigateToRegisteredContacts = () => {
    const { org, event, history } = this.props;
    history.push(
      `/events/${event.slug || ''}/contacts/people?registration_status_id=${
        org.registeredOption ? org.registeredOption.id : ''
      }`,
    );
  };

  render() {
    const { org, event, navigateToFormBuilder } = this.props;
    const host = currentOrigin();

    if (!event.registrationForm) {
      return null;
    }
    const link = `${host}/${org.settings.subdomain ? '' : `${org.slug}/`}${
      event.registrationForm.publicResourceToken.token
    }`;

    return (
      <Container>
        <LeftSide onClick={navigateToFormBuilder}>
          <StyledRegistrationFormSubmissionContent
            registrationForm={event.registrationForm}
            org={org}
            viewMode
            preview
          />
          <Cover />
        </LeftSide>
        <RightSide>
          <RegistrationFormActionsBar event={event} onRegFormEdit={navigateToFormBuilder} />
          {event.registrationForm.publishedAt && (
            <>
              <InfoRow>
                <Highlight>Publish date: </Highlight>
                <Date>
                  {moment.tz(event.registrationForm.publishedAt, event.tz).format('MM.DD.YYYY')}
                </Date>
              </InfoRow>
              <InfoRow>
                <Highlight>Form Link: </Highlight>
                <StyledLink href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </StyledLink>
                <Actions>
                  <StyledCopyLink link={link} hideLink />
                  <EmbedWrapper>
                    <StyledEmbedButton link={link} size={27} tooltipPlacement="top" />
                  </EmbedWrapper>
                </Actions>
              </InfoRow>
              <RegistrationSummary>
                <OutlineButton
                  label="View Registered Contacts"
                  onClick={this.navigateToRegisteredContacts}
                />
                <RegistrationResults event={event} org={org} />
              </RegistrationSummary>
            </>
          )}
        </RightSide>
      </Container>
    );
  }
}

export default createFragmentContainer(ExistingRegistrationForm, {
  event: graphql`
    fragment ExistingRegistrationForm_event on Event {
      slug
      tz
      registrationForm {
        id
        publishedAt
        publicResourceToken {
          token
        }
        ...RegistrationFormSubmissionContent_registrationForm
      }
      ...RegistrationFormActionsBar_event
      ...RegistrationResults_event
    }
  `,
  org: graphql`
    fragment ExistingRegistrationForm_org on Org {
      id
      slug
      settings {
        subdomain
      }
      registeredOption: fieldDefaultOption(
        customizableType: EVENTCONTACT
        fieldName: "registration_status_id"
        optionName: "Registered"
      ) {
        id
      }
      ...RegistrationFormSubmissionContent_org
      ...RegistrationResults_org
    }
  `,
});
