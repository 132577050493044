/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import range from 'lodash/range';
import moment from 'moment-timezone';

import formatDateRange from 'utils/date/formatDateRange';
import formatLocation from 'utils/locations/formatLocation';

import { Remove } from 'images';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import EventSearch, { type Event } from 'components/EventSearch';

import { type EventMultiselectQueryResponse } from './__generated__/EventMultiselectQuery.graphql';

const RemoveIcon = styled(Remove)`
  visibility: hidden;
  width: 19px;
  height: 18px;
  margin-right: 15px;
  color: ${props => props.theme.secondaryActionColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const EventsContainer = styled.div`
  margin: 5px auto;
`;

const EventRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    background-color: ${props => props.theme.hoverRowColor};
    ${RemoveIcon} {
      visibility: visible;
    }
  }
`;

const Row = styled.div`
  width: 100%;
  padding: 8px 15px;
  color: ${props => props.theme.rowPrimaryTextColor};
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  line-height: 1.2;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

const RowLabel = styled.div`
  width: 100%;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #54606d;
  &:first-child:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const RowInfo = styled(RowLabel)`
  color: #8b96a0;
`;

const query = graphql`
  query EventMultiselectQuery($eventIds: [ID!]!) {
    selectedEvents: nodes(ids: $eventIds) {
      id
      ... on Event {
        id
        name
        startDate
        endDate
        tz
        primaryLocation {
          name
          city
          state
          country
        }
      }
    }
  }
`;

type EventType = $ElementType<$PropertyType<EventMultiselectQueryResponse, 'selectedEvents'>, 0>;

export default class EventMultiselect extends React.PureComponent<{
  selectedEvents: $ReadOnlyArray<string>,
  updateSelectedEvents: (eventIds: $ReadOnlyArray<string>) => void,
  label: string,
  required?: boolean,
  includeSuggested?: boolean,
  restrictedEventsCount?: number,
  onToggleEventQuery?: (present: boolean) => void,
}> {
  cachedEvents = [];

  eventQueryExists: ?boolean;

  handleEventSelect = (event: ?Event): void => {
    if (event && !this.props.selectedEvents.some(id => event && id === event.id)) {
      this.props.updateSelectedEvents([event.id, ...this.props.selectedEvents]);
    }
  };

  handleRemoveEvent = (event: EventType): void => {
    this.props.updateSelectedEvents(
      this.props.selectedEvents.filter(id => event && event.id !== id),
    );
  };

  handleEventSearch = (searchQuery?: string = '') => {
    if (searchQuery.trim() && !this.eventQueryExists && this.props.onToggleEventQuery) {
      this.props.onToggleEventQuery(true);
      this.eventQueryExists = true;
    }
    if (!searchQuery.trim() && this.eventQueryExists && this.props.onToggleEventQuery) {
      this.props.onToggleEventQuery(false);
      this.eventQueryExists = false;
    }
  };

  renderSuccess = (response: ?EventMultiselectQueryResponse) => {
    this.cachedEvents = response ? response.selectedEvents : this.cachedEvents;
    return (
      <React.Fragment>
        <EventSearch
          onSelect={this.handleEventSelect}
          label={this.props.label || 'Select Events'}
          required={this.props.required}
          clearable
          includeSuggested={this.props.includeSuggested}
          onSearch={this.handleEventSearch}
          onHideOptions={this.handleEventSearch}
        />
        <EventsContainer>
          {this.cachedEvents.map(
            (event: EventType) =>
              event && (
                <EventRow key={event.id}>
                  <Row>
                    <RowLabel>{event.name}</RowLabel>
                    {event.startDate != null && (
                      <RowInfo>
                        {formatDateRange(
                          moment.tz(event.startDate, event.tz),
                          event.endDate && moment.tz(event.endDate, event.tz),
                        )}
                      </RowInfo>
                    )}
                    {event.primaryLocation != null && (
                      <RowInfo>{formatLocation(event.primaryLocation)}</RowInfo>
                    )}
                  </Row>
                  <RemoveIcon onClick={() => this.handleRemoveEvent(event)} />
                </EventRow>
              ),
          )}
          {this.props.restrictedEventsCount != null &&
            range(this.props.restrictedEventsCount).map(i => (
              <EventRow key={i}>Restricted</EventRow>
            ))}
        </EventsContainer>
      </React.Fragment>
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        variables={{ eventIds: this.props.selectedEvents }}
        query={query}
        renderSuccess={this.renderSuccess}
        renderLoading={this.renderSuccess}
      />
    );
  }
}
