/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type HeaderSection_template$ref: FragmentReference;
export type HeaderSection_template = {|
  +headerSettings: {|
    +includeDates: boolean,
    +includeLocation: boolean,
    +includeWebsite: boolean,
  |},
  +$refType: HeaderSection_template$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "HeaderSection_template",
  "type": "BriefTemplate",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "headerSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "BriefTemplateHeader",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "includeDates",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "includeLocation",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "includeWebsite",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '05f4f2c82d039cad84302a8cf8f4065c';
module.exports = node;
