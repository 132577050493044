/**
 * @flow
 * @relayHash 0a629167356e1514ea5728ce0fa6ec6d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RowComponent_selectedPageComponent$ref = any;
export type UpdateColumnComponentInput = {
  clientMutationId?: ?string,
  id: string,
  width?: ?number,
  padding?: ?string,
};
export type updateColumnComponentMutationVariables = {|
  input: UpdateColumnComponentInput
|};
export type updateColumnComponentMutationResponse = {|
  +updateRegistrationFormColumnComponent: ?{|
    +pageComponent: {|
      +id: string,
      +$fragmentRefs: RowComponent_selectedPageComponent$ref,
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type updateColumnComponentMutation = {|
  variables: updateColumnComponentMutationVariables,
  response: updateColumnComponentMutationResponse,
|};
*/


/*
mutation updateColumnComponentMutation(
  $input: UpdateColumnComponentInput!
) {
  updateRegistrationFormColumnComponent(input: $input) {
    pageComponent {
      id
      ...RowComponent_selectedPageComponent
    }
    registrationForm {
      id
      hasChanges
    }
  }
}

fragment RowComponent_selectedPageComponent on RegistrationPageComponent {
  id
  kind
  childPageComponents {
    edges {
      node {
        id
        order
        kind
        columnComponent {
          id
          width
          padding
        }
      }
    }
  }
  rowComponent {
    id
    padding
    cellSpacing
    backgroundColor
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateColumnComponentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateColumnComponentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "padding",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateColumnComponentMutation",
  "id": null,
  "text": "mutation updateColumnComponentMutation(\n  $input: UpdateColumnComponentInput!\n) {\n  updateRegistrationFormColumnComponent(input: $input) {\n    pageComponent {\n      id\n      ...RowComponent_selectedPageComponent\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n\nfragment RowComponent_selectedPageComponent on RegistrationPageComponent {\n  id\n  kind\n  childPageComponents {\n    edges {\n      node {\n        id\n        order\n        kind\n        columnComponent {\n          id\n          width\n          padding\n        }\n      }\n    }\n  }\n  rowComponent {\n    id\n    padding\n    cellSpacing\n    backgroundColor\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateColumnComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormColumnComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateColumnComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "FragmentSpread",
                "name": "RowComponent_selectedPageComponent",
                "args": null
              }
            ]
          },
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateColumnComponentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormColumnComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateColumnComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v2,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "childPageComponents",
                "storageKey": null,
                "args": null,
                "concreteType": "RegistrationPageComponentRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "RegistrationPageComponentRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "RegistrationPageComponent",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "order",
                            "args": null,
                            "storageKey": null
                          },
                          v4,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "columnComponent",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RegistrationColumnComponent",
                            "plural": false,
                            "selections": [
                              v2,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "width",
                                "args": null,
                                "storageKey": null
                              },
                              v5
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "rowComponent",
                "storageKey": null,
                "args": null,
                "concreteType": "RegistrationRowComponent",
                "plural": false,
                "selections": [
                  v2,
                  v5,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cellSpacing",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "backgroundColor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c609698a152ee49b005f31bae1fa58db';
module.exports = node;
