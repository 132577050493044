/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { TableLink } from 'components/material/table';

import type { EventRequestFormsNameColumn_requestForm } from './__generated__/EventRequestFormsNameColumn_requestForm.graphql';

class EventRequestFormsNameColumn extends React.PureComponent<{
  requestForm: EventRequestFormsNameColumn_requestForm,
  onColumnLoad: string => void,
}> {
  componentDidMount() {
    this.props.onColumnLoad(this.props.requestForm.name);
  }

  stopPropagation = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  render() {
    return (
      <TableLink
        to={`/settings/requests/${this.props.requestForm.id}`}
        onClick={this.stopPropagation}
      >
        {this.props.requestForm.name}
      </TableLink>
    );
  }
}

export default createFragmentContainer(
  EventRequestFormsNameColumn,
  graphql`
    fragment EventRequestFormsNameColumn_requestForm on EventRequestForm {
      id
      name
    }
  `,
);
