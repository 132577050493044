/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import US_STATES from 'config/us_states.json';

import type { FieldType } from 'utils/customization/types';

import type { CompanyFieldInput } from 'graph/mutations/company/updateCompany';

import InlineSelectField from 'components/budget/Table/InlineSelectField';
import InlineTextField from 'components/budget/Table/InlineTextField';

import CompanyState_company from './__generated__/CompanyState_company.graphql';

const stateOptions = Object.keys(US_STATES).map(key => ({
  label: US_STATES[key],
  value: US_STATES[key],
}));

class CompanyState extends React.PureComponent<{
  company: CompanyState_company,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<CompanyFieldInput>) => Promise<void>,
}> {
  handleUpdate = (value: ?string) => {
    const { company, onUpdate, updateColumnWidth } = this.props;
    if (!this.getError(value) && value !== company.state) {
      onUpdate({ state: value });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    const { company, fieldSettings } = this.props;
    if (fieldSettings.required && !company.state && (!value || !value.trim())) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { company, fieldSettings, updateColumnWidth } = this.props;

    if (!company) {
      return null;
    }

    return company.country === 'United States' ? (
      <InlineSelectField
        placeholder={fieldSettings.label}
        options={stateOptions}
        value={stateOptions.find(option => option.value === company.state) ? company.state : null}
        clearable={!fieldSettings.required}
        getError={this.getError}
        onChange={this.handleUpdate}
        searchable
      />
    ) : (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={company.state || ''}
        getError={this.getError}
        onChange={this.handleUpdate}
        updateColumnWidth={updateColumnWidth}
      />
    );
  }
}

export default createFragmentContainer(
  CompanyState,
  graphql`
    fragment CompanyState_company on Company {
      country
      state
    }
  `,
);
