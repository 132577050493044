/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Button = styled.button`
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  outline: 0;
  font-size: 16px;
  height: 100%;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

export default class TaskAddButton extends React.PureComponent<{
  onClick: () => void,
  className?: string,
}> {
  render() {
    return (
      <Container className={this.props.className}>
        <Button onClick={this.props.onClick}>
          <i className="fa fa-fw fa-plus" />
        </Button>
      </Container>
    );
  }
}
