/**
 * @flow
 * @relayHash f04938cae996a3aba543881c677eb866
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Direction = "ASC" | "DESC";
export type EventFormat = "HYBRID" | "PHYSICAL" | "VIRTUAL";
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventListShare = "PRIVATE" | "PUBLIC" | "SSO";
export type EventRegistrationFormStatus = "DRAFT" | "NOT_AVAILABLE" | "PUBLISHED";
export type EventRequestSubmissionStatusFilter = "APPROVED" | "DECLINED" | "NEW" | "N_A" | "RESUBMITTED" | "RETURNED";
export type EventSort = "ATTENDED_CONTACTS_TOTAL" | "BOOTH" | "BOOTH_DIMENSIONS" | "CITY" | "COMMITMENT_LEVEL" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATOR" | "CUSTOM" | "END_DATE" | "EVENT_FORMAT" | "EVENT_LEAD" | "LAST_SYNCED" | "LOCATION" | "MARKETO_ID" | "NAME" | "OPPORTUNITIES_AMOUNT" | "OPPORTUNITIES_NUMBER" | "PROGRESS" | "REGISTERED_CONTACTS_TOTAL" | "REQUESTED_BY" | "REQUESTED_DATE" | "REQUESTED_FORM_NAME" | "REQUESTED_STATUS" | "SALESFORCE_ID" | "STAFF_COUNT" | "START_DATE" | "STATE" | "STREET" | "SYNC_STATUS" | "TEAM_NAME" | "TEMPLATES_FIRST" | "TOTAL_ACTUAL_COST" | "TOTAL_PAID_COST" | "TOTAL_PLANNED_COST" | "UPDATED_AT" | "UPDATER" | "VENUE_NAME" | "VIRTUAL_LOCATION" | "WEBSITE" | "ZIP_CODE";
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type Frequency = "monthly" | "weekly";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type ScheduledTime = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "end_of_month" | "start_of_month";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type UpdateEventListInput = {
  clientMutationId?: ?string,
  id: string,
  name?: ?string,
  search?: ?string,
  queries?: ?$ReadOnlyArray<string>,
  teamIds?: ?$ReadOnlyArray<string>,
  shared?: ?EventListShare,
  logo?: ?string,
  font?: ?string,
  backgroundColor?: ?string,
  titleColor?: ?string,
  backgroundUrl?: ?string,
  beforeDate?: ?any,
  afterDate?: ?any,
  listType?: ?EventListEnum,
  venueNames?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  eventFormats?: ?$ReadOnlyArray<EventFormat>,
  statuses?: ?$ReadOnlyArray<EventStatus>,
  leadIds?: ?$ReadOnlyArray<string>,
  staffIds?: ?$ReadOnlyArray<string>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  shownColumns?: ?$ReadOnlyArray<string>,
  customFieldSortId?: ?string,
  sort?: ?EventSort,
  direction?: ?Direction,
  requestedDate?: ?DateTimeRange,
  requesterUserIds?: ?$ReadOnlyArray<string>,
  requesterContactIds?: ?$ReadOnlyArray<string>,
  requestStatuses?: ?$ReadOnlyArray<EventRequestSubmissionStatusFilter>,
  requestFormIds?: ?$ReadOnlyArray<string>,
  eventIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  updaterIds?: ?$ReadOnlyArray<string>,
  updatedAt?: ?DateTimeRange,
  linkEventNameToBrief?: ?boolean,
  requestReviewers?: ?$ReadOnlyArray<string>,
  actionsRequestFormIds?: ?$ReadOnlyArray<string>,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  contactsCount?: ?NumberRangeInput,
  opportunitiesNumber?: ?NumberRangeInput,
  opportunitiesAmount?: ?NumberRangeInput,
  registeredContactsTotal?: ?NumberRangeInput,
  attendedContactsTotal?: ?NumberRangeInput,
  registrationFormStatuses?: ?$ReadOnlyArray<EventRegistrationFormStatus>,
  publicResourceToken?: ?string,
  scheduler?: ?EventListExportScheduler,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type EventListExportScheduler = {
  frequency: Frequency,
  scheduledOn: ScheduledTime,
  recipientIds: $ReadOnlyArray<string>,
};
export type updateEventListMutationVariables = {|
  input: UpdateEventListInput
|};
export type updateEventListMutationResponse = {|
  +updateEventList: ?{|
    +eventList: {|
      +id: string,
      +name: string,
      +shared: EventListShare,
      +linkEventNameToBrief: boolean,
      +publicResourceToken: {|
        +token: string
      |},
      +exportScheduler: ?{|
        +frequency: Frequency,
        +scheduledOn: ScheduledTime,
        +recipients: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +firstName: string,
              +lastName: string,
              +email: string,
            |}
          |}>
        |},
      |},
      +actionsRequestForms: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string
          |}
        |}>
      |},
    |}
  |}
|};
export type updateEventListMutation = {|
  variables: updateEventListMutationVariables,
  response: updateEventListMutationResponse,
|};
*/


/*
mutation updateEventListMutation(
  $input: UpdateEventListInput!
) {
  updateEventList(input: $input) {
    eventList {
      id
      name
      shared
      linkEventNameToBrief
      publicResourceToken {
        token
        id
      }
      exportScheduler {
        frequency
        scheduledOn
        recipients {
          edges {
            node {
              id
              firstName
              lastName
              email
            }
          }
        }
        id
      }
      actionsRequestForms {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventListInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateEventListInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shared",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "linkEventNameToBrief",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "token",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "frequency",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scheduledOn",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "recipients",
  "storageKey": null,
  "args": null,
  "concreteType": "UserRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "actionsRequestForms",
  "storageKey": null,
  "args": null,
  "concreteType": "EventRequestFormRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestFormRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "EventRequestForm",
          "plural": false,
          "selections": [
            v2
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventListMutation",
  "id": null,
  "text": "mutation updateEventListMutation(\n  $input: UpdateEventListInput!\n) {\n  updateEventList(input: $input) {\n    eventList {\n      id\n      name\n      shared\n      linkEventNameToBrief\n      publicResourceToken {\n        token\n        id\n      }\n      exportScheduler {\n        frequency\n        scheduledOn\n        recipients {\n          edges {\n            node {\n              id\n              firstName\n              lastName\n              email\n            }\n          }\n        }\n        id\n      }\n      actionsRequestForms {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventListMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateEventList",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventListPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventList",
            "storageKey": null,
            "args": null,
            "concreteType": "EventList",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "publicResourceToken",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicResourceToken",
                "plural": false,
                "selections": [
                  v6
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "exportScheduler",
                "storageKey": null,
                "args": null,
                "concreteType": "EventListScheduledExportView",
                "plural": false,
                "selections": [
                  v7,
                  v8,
                  v9
                ]
              },
              v10
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventListMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateEventList",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventListPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventList",
            "storageKey": null,
            "args": null,
            "concreteType": "EventList",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "publicResourceToken",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicResourceToken",
                "plural": false,
                "selections": [
                  v6,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "exportScheduler",
                "storageKey": null,
                "args": null,
                "concreteType": "EventListScheduledExportView",
                "plural": false,
                "selections": [
                  v7,
                  v8,
                  v9,
                  v2
                ]
              },
              v10
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6fe4dd9a499600e29443b8bc2c0b852d';
module.exports = node;
