/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EmbedComponent_componentProps$ref: FragmentReference;
export type EmbedComponent_componentProps = {|
  +embedComponent: ?{|
    +padding: string,
    +code: ?string,
    +alignment: Alignment,
    +backgroundColor: ?string,
  |},
  +$refType: EmbedComponent_componentProps$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EmbedComponent_componentProps",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "embedComponent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationEmbedComponent",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "padding",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "code",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "alignment",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "backgroundColor",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3ea0e298db5e047d8457ee465289d7ae';
module.exports = node;
