/* @flow */
import React from 'react';
import styled from 'styled-components';

import Tooltip from 'components/material/Tooltip';

const Icon = styled.i`
  color: #ffb278;
`;

const StyledTooltip = styled(Tooltip)`
  display: inline-block;
  margin-left: 10px;
`;

export default class ErrorIcon extends React.PureComponent<{
  message: string,
}> {
  render() {
    return (
      <StyledTooltip label={this.props.message} placement="right">
        <Icon className="fa fa-warning" />
      </StyledTooltip>
    );
  }
}
