/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CustomizableCurrency_org$ref = any;
type OpportunityAmount_org$ref = any;
type OpportunitySalesforceId_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OpportunitiesTablePagination_org$ref: FragmentReference;
export type OpportunitiesTablePagination_org = {|
  +$fragmentRefs: OpportunitySalesforceId_org$ref & OpportunityAmount_org$ref & CustomizableCurrency_org$ref,
  +$refType: OpportunitiesTablePagination_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "OpportunitiesTablePagination_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeAmount",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableCurrency",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "OpportunitySalesforceId_org",
      "args": null
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeAmount",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunityAmount_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableCurrency",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableCurrency_org",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'acebb3d8887e4b2d8a863367b183b7e4';
module.exports = node;
