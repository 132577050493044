/* @flow */
import React from 'react';

import SelectField, { type SelectItem } from 'components/material/SelectField';

export default class RegistrationSelectField<P> extends React.PureComponent<{
  className?: string,
  options: $ReadOnlyArray<SelectItem<P>>,
  onChange: (value: ?P) => void,
  onShowOptions: () => void,
  onHideOptions: () => void,
  value?: ?P,
}> {
  render() {
    const { options, value, onChange, className, onShowOptions, onHideOptions } = this.props;
    return (
      <SelectField
        className={className}
        options={options}
        value={value}
        onChange={onChange}
        onShowOptions={onShowOptions}
        onHideOptions={onHideOptions}
        flagClass="component-styling"
      />
    );
  }
}
