/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import TextInput from 'components/material/TextInput';

import type { TaskContentHeader_task } from './__generated__/TaskContentHeader_task.graphql';

const Container = styled.div`
  flex: 1 1 auto;
  margin-bottom: 20px;
`;

const Folder = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  margin-right: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  padding-right: 12px;
  font-weight: 300;
  font-size: 13px;
`;

const Name = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  flex: 1 1 auto;
  word-break: break-word;
  ${props => !props.disabled && 'cursor: pointer'};
`;

const NameInput = styled(TextInput)`
  padding: 0;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
`;

const NameInputContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
  margin: -4px 0 -11px;
`;

class TaskContentHeader extends React.PureComponent<
  {
    task: TaskContentHeader_task,
    onUpdateName: ({ name: string }) => void,
  },
  { nameEditing: boolean },
> {
  state = {
    nameEditing: false,
  };

  handleNameClick = () => {
    if (this.props.task.viewerCanUpdateDetails) {
      this.setState({ nameEditing: true });
    }
  };

  handleCancelNameEditing = () => {
    this.setState({ nameEditing: false });
  };

  handleNameKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      this.handleCancelNameEditing();
    } else if (e.key === 'Enter') {
      const value = e.currentTarget.value.trim();

      if (value) {
        this.props.onUpdateName({ name: value });
      }

      this.handleCancelNameEditing();
    }
  };

  render() {
    const task = this.props.task;
    return (
      <Container>
        {task.folder && <Folder>{task.folder.name}</Folder>}
        {this.state.nameEditing ? (
          <NameInputContainer>
            <NameInput
              defaultValue={task.name}
              multiline
              autoFocus
              onKeyDown={this.handleNameKeyDown}
              onBlur={this.handleCancelNameEditing}
            />
          </NameInputContainer>
        ) : (
          <Name onClick={this.handleNameClick} disabled={!task.viewerCanUpdateDetails}>
            {' '}
            {task.name}
          </Name>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  TaskContentHeader,
  graphql`
    fragment TaskContentHeader_task on Deliverable {
      viewerCanUpdateDetails
      name
      folder {
        name
      }
    }
  `,
);
