/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import inviteStatuses from 'config/userInviteStatuses';

import type { TeamMemberInviteStatus_member } from './__generated__/TeamMemberInviteStatus_member.graphql';

class TeamMemberInviteStatus extends React.PureComponent<{
  member: TeamMemberInviteStatus_member,
}> {
  render() {
    return <div>{inviteStatuses[this.props.member.node.inviteStatus]}</div>;
  }
}

export default createFragmentContainer(
  TeamMemberInviteStatus,
  graphql`
    fragment TeamMemberInviteStatus_member on TeamMemberEdge {
      node {
        inviteStatus
      }
    }
  `,
);
