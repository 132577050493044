/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation removeNoteFromBriefMutation($input: RemoveNoteFromBriefInput!) {
    removeNoteFromBrief(input: $input) {
      eventNote {
        includedInBrief
      }
    }
  }
`;

export default function removeNoteFromBrief(noteId: string, eventId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        noteId,
        eventId,
      },
    },
    optimisticResponse: {
      removeNoteFromBrief: {
        eventNote: {
          includedInBrief: false,
        },
      },
    },
  });
}
