/* @flow */
import * as React from 'react';
import { CompositeDecorator, ContentState } from 'draft-js';

export type Props = {
  contentState: ContentState,
  entityKey: string,
  children: React.Node,
};

const Link = (props: Props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

const Emoji = (props: { children: React.Node }) => {
  return (
    <span style={{ color: '#000', fontSize: '16px', verticalAlign: 'middle' }}>
      {props.children}
    </span>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
      }, callback);
    },
    component: Link,
  },
  {
    strategy: (contentBlock, callback) => {
      const reg = /([\uD800-\uDBFF][\uDC00-\uDFFF])/g;
      const str = contentBlock.getText();
      let emoji;
      // eslint-disable-next-line no-cond-assign
      while ((emoji = reg.exec(str)) !== null) {
        if (emoji) callback(emoji.index, emoji.index + emoji.length);
      }
    },
    component: Emoji,
  },
]);

export default decorator;
