/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  ConfigureMarketoFieldMappingInput,
  configureMarketoFieldMappingMutationResponse,
  MarketoMappingResourceType,
} from './__generated__/configureMarketoFieldMappingMutation.graphql';

export type ResourceType = MarketoMappingResourceType;

const mutation = graphql`
  mutation configureMarketoFieldMappingMutation($input: ConfigureMarketoFieldMappingInput!) {
    configureMarketoFieldMapping(input: $input) {
      marketoFieldMappingEdge {
        node {
          id
          marketoField
          resourceType
          resourceFieldName
          resourceCustomField {
            id
          }
        }
      }
      removedMarketoFieldMappingId
    }
  }
`;

const configureMarketoFieldMapping = (
  input: ConfigureMarketoFieldMappingInput,
  marketoAccountId: string,
  marketoFieldMappingId?: string,
): Promise<configureMarketoFieldMappingMutationResponse> => {
  const removing = !input.marketoField;
  const optimisticResponse: ?configureMarketoFieldMappingMutationResponse = marketoFieldMappingId
    ? {
        configureMarketoFieldMapping: {
          marketoFieldMappingEdge: input.marketoField
            ? {
                node: {
                  id: marketoFieldMappingId,
                  marketoField: input.marketoField,
                  resourceType: input.resourceType,
                  resourceFieldName: input.resourceFieldName,
                  resourceCustomField: input.resourceCustomFieldId
                    ? { id: input.resourceCustomFieldId }
                    : null,
                },
              }
            : null,
          removedMarketoFieldMappingId: removing ? marketoFieldMappingId : null,
        },
      }
    : null;

  return commitModernMutation({
    mutation,
    variables: { input },
    optimisticResponse,
    updater: removing
      ? removeRecordFromConnection({
          parentId: marketoAccountId,
          connections: [
            { field: 'marketoFieldMappings', args: { resourceType: input.resourceType } },
          ],
          deletedIdField: 'removedMarketoFieldMappingId',
        })
      : !marketoFieldMappingId &&
        addRecordToConnection({
          parentId: marketoAccountId,
          edgeName: 'marketoFieldMappingEdge',
          connections: [
            { field: 'marketoFieldMappings', args: { resourceType: input.resourceType } },
          ],
        }),
  });
};

export default configureMarketoFieldMapping;
