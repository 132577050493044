/* @flow */
import type { FieldType } from 'utils/customization/types';

import type { Filter } from 'components/material/ColumnFilters/columnFilterTypes';

export const defaultColumns = [
  'name',
  'website',
  'company_phone',
  'street',
  'city',
  'state',
  'country',
];

export default function getColumnConfiguration(
  fields: $ReadOnlyArray<FieldType>,
): $ReadOnlyArray<Filter> {
  return fields.map(field => {
    return {
      value: field.fieldName || field.id,
      label: field.label,
      default: defaultColumns.includes(field.fieldName),
      sticky: field.fieldName === 'name',
    };
  });
}
