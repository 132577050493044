/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import camelCase from 'lodash/camelCase';
import omit from 'lodash/omit';

import FeatureAccessContext from 'contexts/FeatureAccess';

import convertCustomFieldValuesToInput from 'utils/customization/convertCustomFieldValuesToInput';
import getCustomFieldPlainValue from 'utils/customization/getCustomFieldPlainValue';
import getOptionKeysByKind from 'utils/customization/getOptionKeysByKind';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import { type CustomFieldsType, type FieldType } from 'utils/customization/types';
import isValidEmail from 'utils/validators/isValidEmail';
import isValidWebsite from 'utils/validators/isValidWebsite';

import { type UpdateCustomFieldValueInput } from 'graph/mutations/custom_field/updateCustomFieldValue';
import createVendor, {
  type CreateVendorFromWindow,
  type ResponseVendor,
} from 'graph/mutations/vendor/createVendor';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button, { MinimalButton } from 'components/budget/Button';
import customFields, { type InexactUser } from 'components/Customizable/customFields';
import TextField from 'components/material/TextField';
import { type VendorFilterInputs } from 'components/Vendors/lib/renderVendorFilter';
import suggestedFields from 'views/Main/AllContacts/Vendor/VendorProfile/suggestedFields';
import { type VendorDefaultFields } from 'views/Main/AllContacts/Vendor/VendorProfile/VendorFormFields';

import { type VendorForm_org } from './__generated__/VendorForm_org.graphql';

const Row = styled.div`
  display: flex;
  margin: 20px 0;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  a {
    font-size: 13px;
    color: #3aa8da;
    text-decoration: underline;

    &:hover {
      color: #009dce;
    }
  }

  button {
    margin-left: 30px;
  }
`;

const LinkContainer = styled.div`
  flex: 1 1 auto;
`;

class VendorForm extends React.PureComponent<
  {
    org: VendorForm_org,
    tz: string,
    eventId?: ?string,
    filterVariables?: VendorFilterInputs,
    onHide: () => void,
    onSave: ?(ResponseVendor) => void,
    setOnVendorFormEdited: (onHide: () => boolean) => void,
    fromWindow: CreateVendorFromWindow,
  },
  {
    defaultFieldValues: VendorDefaultFields,
    customFieldValues: CustomFieldsType,
    errors: { [string]: string },
    loading: boolean,
    formEdited: boolean,
  },
> {
  state = {
    defaultFieldValues: {
      name: '',
      firstName: null,
      lastName: null,
      email: null,
      title: null,
      phone1: null,
      phone2: null,
      companyPhone: null,
      website: null,
      twitter: null,
      linkedin: null,
      description: null,
      country: 'United States',
      state: null,
      city: null,
      zip: null,
      street: null,
    },
    customFieldValues: {
      customTextFields: [],
      customTextareaFields: [],
      customLinkFields: [],
      customDateFields: [],
      customNumberFields: [],
      customCurrencyFields: [],
      customBooleanFields: [],
      customUserSelectFields: [],
      customUserMultiselectFields: [],
      customSelectFields: [],
      customMultiselectFields: [],
    },
    errors: {},
    loading: false,
    formEdited: false,
  };

  componentDidMount() {
    this.props.setOnVendorFormEdited(this.checkVendorFormEdited);
  }

  checkVendorFormEdited = (): boolean => {
    return this.state.formEdited;
  };

  handleChangeName = (event: SyntheticEvent<HTMLInputElement>) => {
    const name = event.currentTarget.value;

    this.setState(prevState => ({
      defaultFieldValues: { ...prevState.defaultFieldValues, name },
      formEdited: true,
    }));

    this.validateDefaultField(name);
  };

  handleUpdateCustomField = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    const { arrayKey, optionKey } = getOptionKeysByKind(field.kind);
    const value = Object.values(input)[0];
    const valuesArray = (Array.isArray(value)
      ? value
      : [value].filter(val => val != null)
    ).map(optionId => (users == null ? optionId : users.find(user => user.id === optionId)));

    this.setState(prevState => ({
      customFieldValues: {
        ...prevState.customFieldValues,
        [arrayKey]: [
          ...prevState.customFieldValues[arrayKey].filter(
            option => option.customField.id !== field.id,
          ),
          ...valuesArray.map(option => ({
            customField: { id: field.id },
            [optionKey]: optionKey === 'option' ? { id: option } : option,
          })),
        ],
      },
      formEdited: true,
    }));

    this.validateField(field, Object.values(input)[0]);
  };

  handleUpdateSuggestedField = (field: FieldType, changes: VendorDefaultFields) => {
    this.setState(prevState => ({
      defaultFieldValues: { ...prevState.defaultFieldValues, ...changes },
      formEdited: true,
    }));

    this.validateField(field, Object.values(changes)[0]);
  };

  validateDefaultField = (value: ?string) =>
    new Promise(resolve => {
      return this.setState(prevState => {
        if (!(value || '').trim()) {
          return { errors: { ...prevState.errors, name: 'Required' } };
        }

        return {
          errors: omit(prevState.errors, 'name'),
        };
      }, resolve);
    });

  validateField = (field: FieldType, value: mixed) => {
    return new Promise(resolve =>
      this.setState(prevState => {
        const key = field.fieldName || field.id;

        if (
          field.required &&
          (value == null ||
            value === false ||
            (typeof value === 'string' && !value.trim()) ||
            (Array.isArray(value) && value.length === 0))
        ) {
          return { errors: { ...prevState.errors, [key]: 'Required' } };
        }

        if (key === 'email' && value != null && value !== '' && !isValidEmail(String(value))) {
          return { errors: { ...prevState.errors, [key]: 'Invalid email' } };
        }

        if (
          (field.kind === 'LINK' || ['website', 'twitter', 'linkedin'].includes(key)) &&
          value != null &&
          value !== '' &&
          !isValidWebsite(String(value))
        ) {
          return { errors: { ...prevState.errors, [key]: 'Invalid url' } };
        }

        return { errors: omit(prevState.errors, key) };
      }, resolve),
    );
  };

  validateForm = () => {
    const { customFieldValues } = this.state;

    return Promise.all([
      this.validateDefaultField(this.state.defaultFieldValues.name),
      ...this.normalizedCustomFields().map(field =>
        this.validateField(
          field,
          field.fieldName
            ? this.getDefaultFieldValue(field.fieldName)
            : getCustomFieldPlainValue(field, customFieldValues),
        ),
      ),
    ]);
  };

  getDefaultFieldValue = (fieldName: string) => this.state.defaultFieldValues[camelCase(fieldName)];

  handleSubmit = () => {
    const { org, fromWindow, filterVariables, onHide, onSave, eventId } = this.props;
    const { defaultFieldValues, customFieldValues } = this.state;

    this.validateForm().then(() => {
      if (Object.keys(this.state.errors).length > 0) {
        const windows = document.getElementsByClassName('popupWindow');
        const popup = windows[windows.length - 1];
        if (popup) {
          popup.scrollTop = 0;
        }
        return;
      }

      this.setState({ loading: true });

      createVendor(
        org.id,
        {
          eventIds: eventId ? [eventId] : null,
          fromWindow,
          ...defaultFieldValues,
          customFieldValues: convertCustomFieldValuesToInput(customFieldValues),
        },
        filterVariables,
      )
        .then((responseVendor: ResponseVendor) => {
          if (onSave) {
            onSave(responseVendor);
          }

          onHide();
        })
        .catch(error => {
          showModernMutationError(error);

          this.setState({ loading: false });
        });
    });
  };

  normalizedCustomFields = () =>
    normalizeCustomFields(this.props.org.vendorCustomFields.edges.map(edge => edge.node));

  static contextType = FeatureAccessContext;

  render() {
    const { org, tz, onHide } = this.props;
    const { defaultFieldValues, customFieldValues, errors, loading } = this.state;
    const settingsUrl = this.context.legacyFeatures
      ? '/settings/contacts/vendors'
      : '/settings/vendors';

    return (
      <div>
        <Row>
          <TextField
            label="Vendor Name"
            name="name"
            value={defaultFieldValues.name || ''}
            onChange={this.handleChangeName}
            error={errors.name}
            autoFocus
            required
          />
        </Row>

        {this.normalizedCustomFields().map(field => {
          if (field.kind === 'DEFAULT') {
            const renderer = field.fieldName && suggestedFields[field.fieldName];

            if (!renderer) return null;

            return (
              <Row key={field.id}>
                {renderer({
                  vendor: defaultFieldValues,
                  field,
                  errors,
                  viewerCanUpdate: true,
                  handleUpdate: changes => this.handleUpdateSuggestedField(field, changes),
                })}
              </Row>
            );
          }

          return (
            <Row key={field.id}>
              {customFields[field.kind]({
                customizable: customFieldValues,
                field,
                errors,
                viewerCanUpdateValue: true,
                tz,
                currency: org.settings.currency,
                handleUpdate: (input, users) => this.handleUpdateCustomField(field, input, users),
              })}
            </Row>
          );
        })}

        <Footer>
          <LinkContainer>
            {org.viewerCanUpdate && (
              <a href={settingsUrl} target="_blank" rel="noreferrer noopener">
                Manage Form Settings
              </a>
            )}
          </LinkContainer>

          <MinimalButton label="Cancel" onClick={onHide} />

          <Button onClick={this.handleSubmit} loading={loading}>
            Create
          </Button>
        </Footer>
      </div>
    );
  }
}

export default createFragmentContainer(
  VendorForm,
  graphql`
    fragment VendorForm_org on Org {
      id
      viewerCanUpdate
      settings {
        currency
      }

      vendorCustomFields: customFields(customizableType: [VENDOR], onlyCreateForm: true) {
        edges {
          node {
            id
            kind
            label
            order
            required
            fieldName
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
);
