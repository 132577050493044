/* @flow */
import React from 'react';
import styled from 'styled-components';

import Menu from 'components/page/Menu';
import MenuItem from 'components/page/MenuItem';

const Container = styled.div`
  height: 48px;
  border-bottom: 1px solid #eaebec;
  overflow: hidden;
`;

type Props = { rootPath: string };

const ContactSettingsSubmenu = ({ rootPath }: Props) => {
  return (
    <Container>
      <Menu>
        <MenuItem to={`${rootPath}/people`} text="Contacts" />
        <MenuItem to={`${rootPath}/opportunities`} text="Opportunities" />
        <MenuItem to={`${rootPath}/attendance`} text="Attendance" />
        <MenuItem to={`${rootPath}/companies`} text="Companies" />
        <MenuItem to={`${rootPath}/vendors`} text="Vendors" />
      </Menu>
    </Container>
  );
};

export default ContactSettingsSubmenu;
