/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';

import type {
  removeSalesforceMappingMutationResponse,
  removeSalesforceMappingMutationVariables,
} from './__generated__/removeSalesforceMappingMutation.graphql';
import { type SalesforceKindType } from './__generated__/createSalesforceMappingMutation.graphql';

const mutation = graphql`
  mutation removeSalesforceMappingMutation($input: RemoveSalesforceMappingInput!) {
    removeSalesforceMapping(input: $input) {
      removedMappingId
    }
  }
`;

export default function removeSalesforceMapping(
  salesforceAccountId: string,
  kinds: $ReadOnlyArray<SalesforceKindType>,
  input: {
    mappingId: string,
  },
): Promise<removeSalesforceMappingMutationResponse> {
  const variables: removeSalesforceMappingMutationVariables = {
    input: { ...input },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      removeSalesforceMapping: {
        removedMappingId: input.mappingId,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedMappingId',
        parentId: salesforceAccountId,
        connections: [{ field: 'salesforceMappings', args: { kinds } }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedMappingId' }),
    ),
  });
}
