import moment from 'moment-timezone';
import timezones from 'config/timezones';

import updateUser from 'graph/mutations/user/updateUser';
import { Get, Patch, Post } from '../utils/RequestPromise';

import UserActions from '../actions/UserActions';

export default {
  fetch: {
    remote(state, callback) {
      return Get('users/info').then(response => {
        if (response) {
          if (callback) callback();
          if (timezones.every(tz => tz.name !== response.time_zone)) {
            updateUser(response.relay_id, { tz: moment.tz.guess() }, 'profile');
          }
          return response;
        }
        return UserActions.removeUser();
      });
    },
    local(state, callback) {
      if (state.user.id) {
        if (callback) callback();
        return state.user;
      }
      return null;
    },
    success: UserActions.updateUser,
  },
  login: {
    remote(state, email, password, callback) {
      const user = { email, password };
      return Post('users/sign_in', { user }, callback).then(response => {
        if (!response) return null;
        return response;
      });
    },
    success: UserActions.updateUser,
  },
  signup: {
    remote(state, email, password, callback, eCallback) {
      const user = { email, password, time_zone: moment.tz.guess() };
      return Post('users', { user }, callback, eCallback);
    },
    success: UserActions.doNothing,
  },
  changePassword: {
    remote(state, current_password, password, password_confirmation, callback) {
      const user = { current_password, password, password_confirmation };
      return Patch('users/password', { user }, callback);
    },
    success: UserActions.doNothing,
  },
  validateToken: {
    remote(state, token, callback, errorCallback) {
      return Get(`users/password/edit?reset_password_token=${token}`, callback, errorCallback);
    },
    success: UserActions.doNothing,
  },
  resetPassword: {
    remote(state, token, password, callback) {
      const user = { password };
      return Post(`users/password_reset?reset_password_token=${token}`, { user }, callback);
    },
    success: UserActions.doNothing,
  },
  resendEmailConfirmation: {
    remote(state, email, callback) {
      const confirmation = { email };
      return Post('users/confirmation', { confirmation }, callback);
    },
    success: UserActions.doNothing,
  },
  resendInviteConfirmation: {
    remote(state, email, callback) {
      const user = { email };
      return Post('users/invitation_resend', user, callback);
    },
    success: UserActions.doNothing,
  },
  confirm: {
    remote(state, token, user) {
      return Post(`users/complete?confirmation_token=${token}`, { user }).then(response => {
        if (response && response.access_token) {
          UserActions.updateUser(response);
          return response;
        }
        return null;
      });
    },
    success: UserActions.doNothing,
  },
};
