/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import { type StaffAccessLevel } from 'config/staffAccessLevels';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Window, {
  type WindowSizes,
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import { type ParsedMembersFilters } from 'views/Main/Workspace/Members/lib/parseMembersFilters';

import InviteWindowContents from './InviteWindowContents';

import type { InviteWindowQueryResponse } from './__generated__/InviteWindowQuery.graphql';

const query = graphql`
  query InviteWindowQuery {
    me {
      ...InviteWindowContents_me
    }
    org {
      ...InviteWindowContents_org
    }
  }
`;

const StyledWindowContent = styled(WindowContent)`
  padding: 0 25px 25px;
`;

export type InviteFromWindow =
  | 'custom event settings'
  | 'leader event settings'
  | 'task'
  | 'team'
  | 'event staff'
  | 'org members'
  | 'easy_button'
  | 'create contact'
  | 'create event';

export type User = {
  +id: string,
  +dbId: number,
  +slug: string,
  +firstName: string,
  +lastName: string,
  +avatar: ?string,
  +email: string,
};

export default class InviteWindow extends React.Component<{
  teamId?: string,
  teamName?: string,
  eventId?: ?string,
  onHide: (users?: ?$ReadOnlyArray<User>) => void,
  size: WindowSizes,
  skipStaffAccessLevel?: StaffAccessLevel,
  defaultUser?: ?$Exact<$ReadOnly<{ ...User }>>,
  onClickImport?: () => void,
  filters?: ParsedMembersFilters,
  fromWindow: InviteFromWindow,
}> {
  inviteWindow: ?InviteWindowContents;

  static defaultProps = { size: 'large' };

  getTitle() {
    if (this.props.teamId != null) {
      return `Add members${this.props.teamName ? ` to ${this.props.teamName}` : ''}`;
    }
    if (this.props.eventId != null) {
      return 'Add Event Staff';
    }
    return 'Add members';
  }

  // Do not send mouse click event as an attribute to onHide
  // It expects array of users and not an event
  handleCloseClick = () => {
    this.props.onHide();
  };

  handleOverlayHide = () => {
    if (this.inviteWindow && this.inviteWindow.isNotEdited()) {
      this.props.onHide();
    }
  };

  render() {
    return (
      <Window size={this.props.size} onHide={this.handleOverlayHide}>
        <WindowHeader>
          <WindowTitle>{this.getTitle()}</WindowTitle>
          <WindowClose onClick={this.handleCloseClick} />
        </WindowHeader>
        <StyledWindowContent>
          <DefaultQueryRenderer
            query={query}
            renderSuccess={(props: InviteWindowQueryResponse) => (
              <InviteWindowContents
                componentRef={el => {
                  this.inviteWindow = el;
                }}
                teamId={this.props.teamId}
                eventId={this.props.eventId}
                onHide={this.props.onHide}
                me={props.me}
                org={props.org}
                skipStaffAccessLevel={this.props.skipStaffAccessLevel}
                onClickImport={this.props.onClickImport}
                filters={this.props.filters}
                fromWindow={this.props.fromWindow}
                defaultUser={this.props.defaultUser}
              />
            )}
          />
        </StyledWindowContent>
      </Window>
    );
  }
}
