/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Overlay from 'components/material/Overlay';

import formatCost from 'utils/number/formatCost';

import type { ExpensesOverlay_expenses } from './__generated__/ExpensesOverlay_expenses.graphql';

const ExpenseItem = styled.div`
  padding: 6px 10px;
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 13px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  cursor: pointer;
  p:first-child {
    color: ${props => props.theme.rowPrimaryTextColor};
    font-weight: 500;
  }
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

const ExpenseAdd = styled.div`
  padding: 5px 10px;
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  font-size: 13px;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

const StyledOverlay = styled(Overlay)`
  max-height: 300px;
  overflow-y: auto;
`;

class ExpensesOverlay extends React.PureComponent<{
  show: boolean,
  target: any,
  container: any,
  onHide: () => void,
  currency: string,
  expenses: ExpensesOverlay_expenses,
  onSelect: (expenseId: string) => void,
  onAdd: () => void,
}> {
  render() {
    return (
      <StyledOverlay
        show={this.props.show}
        target={this.props.target}
        container={this.props.container}
        onHide={this.props.onHide}
      >
        {this.props.expenses.map(expense => (
          <ExpenseItem key={expense.id} onClick={() => this.props.onSelect(expense.id)}>
            <p>{expense.name}</p>
            <p>{formatCost(expense.actualAmount, this.props.currency)}</p>
          </ExpenseItem>
        ))}
        <ExpenseAdd onClick={this.props.onAdd}>
          <i className="fa fa-fw fa-plus" /> Add New Expense
        </ExpenseAdd>
      </StyledOverlay>
    );
  }
}

export default createFragmentContainer(
  ExpensesOverlay,
  graphql`
    fragment ExpensesOverlay_expenses on Expense @relay(plural: true) {
      id
      name
      actualAmount
    }
  `,
);
