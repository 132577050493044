/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type { createEventRequestFormMutationResponse } from './__generated__/createEventRequestFormMutation.graphql';

const mutation = graphql`
  mutation createEventRequestFormMutation {
    createEventRequestForm(input: { create: true }) {
      requestFormEdge {
        node {
          ...EventRequestFormBuilderBody_requestForm @relay(mask: false)
        }
      }
    }
  }
`;

export default function createEventRequestForm(): Promise<createEventRequestFormMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: {},
  });
}
