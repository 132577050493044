/* @flow */
import * as React from 'react';

import SelectFieldWrapper, {
  Component,
  ComponentWrapper,
  StyledTextInput,
} from './components/SelectFieldWrapper';
import QuestionSelectOptionRow from './SelectOptionRow';

export default class MultiSelectField extends React.Component<
  {
    label: string,
    required: boolean,
    options: $ReadOnlyArray<{ label: string, value: string }>,
    onChange: (optionId: string, checked: boolean) => void,
    onChangeOtherInput?: (value: ?string) => void,
    values: $ReadOnlyArray<string>,
    disabled?: boolean,
    error: ?string,
  },
  {
    selectedOptions: $ReadOnlyArray<string>,
    keyword: string,
  },
> {
  state = {
    selectedOptions: [],
    keyword: '',
  };

  filteredOptions = (): Array<{ label: string, value: string }> =>
    this.props.options.filter(
      option =>
        option.label.toLowerCase().includes(this.state.keyword) ||
        this.state.selectedOptions.includes(option.value),
    );

  handleOptionChange = (optionId: string, checked: boolean) => {
    if (this.props.disabled) return;

    this.props.onChange(optionId, checked);

    this.setState(state => {
      return {
        selectedOptions: checked
          ? [...state.selectedOptions, optionId]
          : state.selectedOptions.filter(label => label !== optionId),
      };
    });
  };

  handleSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.state.keyword !== e.currentTarget.value.trim()) {
      this.setState({
        keyword: e.currentTarget.value.trim().toLowerCase(),
      });
    }
  };

  render() {
    const { options, values, required, label, error, disabled, onChangeOtherInput } = this.props;
    const filteredOptions = this.filteredOptions();

    return (
      <SelectFieldWrapper
        label={label}
        required={required}
        error={error}
        values={values}
        onChangeOtherInput={onChangeOtherInput}
      >
        {options.length > 10 && (
          <StyledTextInput
            disabled={disabled}
            placeholder="Search..."
            onChange={this.handleSearchChange}
          />
        )}
        <ComponentWrapper>
          <Component isScrollable={filteredOptions.length > 8}>
            {filteredOptions.map(option => (
              <QuestionSelectOptionRow
                key={option.value || option.label}
                type="checkbox"
                checked={values.includes(option.value)}
                option={option}
                onChange={this.handleOptionChange}
              />
            ))}
          </Component>
        </ComponentWrapper>
      </SelectFieldWrapper>
    );
  }
}
