/* @flow */

const briefTemplateSections: { [key: string]: string } = {
  event_info: 'Event Info',
  event_staff: 'Event Staff',
  schedules: 'Schedules',
  tasks: 'Tasks',
  attachments: 'Attachments',
};

export default briefTemplateSections;
