/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';
import Window from 'components/material/Window';

import TaskWindowContent from './TaskWindowContent';

import { type TaskWindowPopup_org } from './__generated__/TaskWindowPopup_org.graphql';
import type { TaskWindowPopupQueryResponse } from './__generated__/TaskWindowPopupQuery.graphql';

const query = graphql`
  query TaskWindowPopupQuery($taskSlug: String!) {
    deliverable(slug: $taskSlug) {
      ...TaskWindowContent_task
    }
    me {
      id
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

class TaskWindowPopup extends React.PureComponent<{
  taskSlug: string,
  org: TaskWindowPopup_org,
  onHide: () => void,
  updateColumnWidth?: () => void,
}> {
  handleHide = () => {
    this.props.onHide();
  };

  render() {
    const { org, taskSlug } = this.props;
    return (
      <Window size="xlarge" onHide={this.handleHide}>
        <DefaultQueryRenderer
          query={query}
          variables={{ taskSlug }}
          renderSuccess={(response: TaskWindowPopupQueryResponse) => {
            return (
              <TaskWindowContent
                task={response.deliverable}
                userId={response.me.id}
                org={org}
                onWindowHide={this.handleHide}
                updateColumnWidth={this.props.updateColumnWidth}
              />
            );
          }}
          renderLoading={() => (
            <LoaderContainer>
              <Loader size={30} />
            </LoaderContainer>
          )}
        />
      </Window>
    );
  }
}

export default createFragmentContainer(
  TaskWindowPopup,
  graphql`
    fragment TaskWindowPopup_org on Org {
      ...TaskWindowContent_org
    }
  `,
);
