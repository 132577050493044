/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import CheckBox from 'components/EventRequestForm/form/CheckBox';
import TemplateSearch, { type TemplateType } from 'components/TemplateSearch';

const SectionWrapper = styled.div`
  margin: 0 16px 15px 16px;
`;

const Row = styled.div`
  margin-top: 13px;
`;

const TimelineWarning = styled.div`
  font-size: 11px;
  font-weight: 400;
  font-style: italic;
  color: ${props => props.theme.mutedTextColor};
  margin-top: 3px;
  line-height: 1.3;
`;

const SubmissionReviewChecklistTemplate = (props: {
  eventTemplate: ?TemplateType,
  copyTimeline: boolean,
  eventHasStartDate: boolean,
  onChangeTemplate: (template: ?TemplateType) => void,
  onToggleCopyTimeline: () => void,
}) => {
  const {
    eventTemplate,
    copyTimeline,
    eventHasStartDate,
    onChangeTemplate,
    onToggleCopyTimeline,
  } = props;
  const dueDateDisabled =
    eventTemplate == null || eventTemplate.startDate == null || !eventHasStartDate;

  return (
    <SectionWrapper>
      <Row>
        <TemplateSearch
          placeholder="Checklist template"
          event={eventTemplate}
          onSelect={onChangeTemplate}
          searchable
          clearable
        />
      </Row>
      {eventTemplate != null && (
        <>
          <Row>
            <CheckBox
              compact
              checked={copyTimeline}
              label="Set due dates based on event start date"
              onChange={onToggleCopyTimeline}
              disabled={dueDateDisabled}
            />
          </Row>
          {dueDateDisabled && (
            <Row>
              <TimelineWarning>
                {eventHasStartDate
                  ? "The template you selected doesn't have a start date, which is necessary to anchor the timeline."
                  : "The event you're approving doesn't have a start date, which is necessary to anchor the timeline."}
              </TimelineWarning>
            </Row>
          )}
        </>
      )}
    </SectionWrapper>
  );
};

export default SubmissionReviewChecklistTemplate;
