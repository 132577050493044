/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';

import type {
  removeEventRequestFormMutationVariables,
  removeEventRequestFormMutationResponse,
} from './__generated__/removeEventRequestFormMutation.graphql';

const mutation = graphql`
  mutation removeEventRequestFormMutation($input: RemoveEventRequestFormInput!) {
    removeEventRequestForm(input: $input) {
      removedRequestFormId
      org {
        ...EventRequestFormsTable_totalCountOrg
      }
    }
  }
`;

export default function removeEventRequestForm({
  orgId,
  requestFormId,
}: {
  orgId: string,
  requestFormId: string,
}): Promise<removeEventRequestFormMutationResponse> {
  const variables: removeEventRequestFormMutationVariables = {
    input: {
      requestFormId,
    },
  };
  const optimisticResponse = {
    removeEventRequestForm: {
      removedRequestFormId: requestFormId,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedRequestFormId',
        parentId: orgId,
        connections: [{ key: 'EventRequestFormsTable_eventRequestForms' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedRequestFormId' }),
    ),
  });
}
