/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RowComponent_selectedPageComponent$ref: FragmentReference;
export type RowComponent_selectedPageComponent = {|
  +id: string,
  +kind: RegistrationPageComponentKind,
  +childPageComponents: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +order: number,
        +kind: RegistrationPageComponentKind,
        +columnComponent: ?{|
          +id: string,
          +width: number,
          +padding: string,
        |},
      |}
    |}>
  |},
  +rowComponent: ?{|
    +id: string,
    +padding: string,
    +cellSpacing: ?number,
    +backgroundColor: ?string,
  |},
  +$refType: RowComponent_selectedPageComponent$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "padding",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RowComponent_selectedPageComponent",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "childPageComponents",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationPageComponentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RegistrationPageComponentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationPageComponent",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                v1,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "columnComponent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationColumnComponent",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "width",
                      "args": null,
                      "storageKey": null
                    },
                    v2
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "rowComponent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationRowComponent",
      "plural": false,
      "selections": [
        v0,
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cellSpacing",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "backgroundColor",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ebb53fb30c2a1988e1589d29f452504';
module.exports = node;
