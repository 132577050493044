/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefAttachments_event$ref = any;
type BriefCompanies_event$ref = any;
type BriefContacts_event$ref = any;
type BriefVendors_event$ref = any;
type Notes_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefBuilder_event$ref: FragmentReference;
export type BriefBuilder_event = {|
  +id: string,
  +$fragmentRefs: Notes_event$ref & BriefAttachments_event$ref & BriefContacts_event$ref & BriefVendors_event$ref & BriefCompanies_event$ref,
  +$refType: BriefBuilder_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BriefBuilder_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Notes_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "BriefAttachments_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "BriefContacts_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "BriefVendors_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "BriefCompanies_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9b5fe2b48e79605974cfa4df52486643';
module.exports = node;
