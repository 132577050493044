/* @flow */

export default function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result !== 'string') {
        reject(new Error('Blob not interpreted as a string.'));
      } else {
        const resultWithoutPrefix = reader.result.split(',')[1];
        resolve(resultWithoutPrefix);
      }
    };
    reader.readAsDataURL(blob);
  });
}
