/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import { type CategoryFooterCellPropsType } from './index';

import { type CategoryActualAmountFooterCell_event } from './__generated__/CategoryActualAmountFooterCell_event.graphql';
import { type CategoryActualAmountFooterCell_org } from './__generated__/CategoryActualAmountFooterCell_org.graphql';

const CategoryActualAmountFooterCell = ({
  event,
  org,
}: CategoryFooterCellPropsType<
  CategoryActualAmountFooterCell_event,
  CategoryActualAmountFooterCell_org,
>) => formatCost(event.totalActualAmount, org.settings.currency);

export default createFragmentContainer(
  CategoryActualAmountFooterCell,
  graphql`
    fragment CategoryActualAmountFooterCell_event on Event {
      totalActualAmount
    }

    fragment CategoryActualAmountFooterCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
