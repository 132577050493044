/* @flow */
import React from 'react';
import styled from 'styled-components';

import RichText, { EditorContainer } from 'components/RichText';

const Container = styled.div`
  word-break: break-word;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 20px;
  }
`;

const Title = styled.div`
  opacity: ${props => (props.text ? 1 : 0.55)};
  font-size: ${props => (props.fontSize ? props.fontSize : 18)}px;
  font-weight: 500;
  color: #3e4859;
  text-transform: uppercase;
`;

const HelpText = styled.div`
  opacity: ${props => (props.text ? 1 : 0.55)};
  font-style: italic;
  color: #3e4859;
`;

const Description = styled.div`
  opacity: ${props => (props.text ? 1 : 0.55)};
  margin-top: 5px;
  white-space: pre-line;
  color: #616c79;
  a {
    color: #3ba9da;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledRichText = styled(RichText)`
  margin-top: 5px;
  white-space: pre-line;
  text-align: center;
  ${EditorContainer} {
    margin-left: 0;
    padding: 0;
    box-shadow: none;
    font-size: 14px;
    color: #616c79;
    &:hover {
      background: none;
    }
  }
`;

export default class FormSection extends React.PureComponent<{
  title: ?string,
  helpText: ?string,
  description: ?string,
  className?: string,
  fontSize?: number,
  isPreview?: boolean,
}> {
  render() {
    const { title, helpText, description, fontSize, isPreview } = this.props;

    return (
      <Container className={this.props.className}>
        {(!isPreview || title) && (
          <Title text={title} fontSize={fontSize}>
            {title || 'Section Title'}
          </Title>
        )}
        {(!isPreview || helpText) && (
          <HelpText text={helpText}>{helpText || 'Section Help Text'}</HelpText>
        )}

        {(!isPreview || (description && description.trim())) && (
          <Description text={description && description.trim()}>
            {(description &&
              description.trim() && (
                <StyledRichText
                  defaultValue={description && description.trim()}
                  disabled
                  viewMode
                />
              )) ||
              'Section Description'}
          </Description>
        )}
      </Container>
    );
  }
}
