/* @flow */
import React from 'react';
import styled from 'styled-components';

const FontStyleAppliedLabel = styled.div`
  font-style: ${props => (props.children.toLowerCase().includes('italic') ? 'italic' : 'normal')};
  font-weight: ${props => (props.children.toLowerCase().includes('bold') ? 'bold' : 'normal')};
`;

export const fontStyles = [
  {
    label: 'Normal',
    value: 'normal',
    displayLabel: <FontStyleAppliedLabel>Normal</FontStyleAppliedLabel>,
  },
  {
    label: 'Bold',
    value: 'bold',
    displayLabel: <FontStyleAppliedLabel>Bold</FontStyleAppliedLabel>,
  },
  {
    label: 'Italic',
    value: 'italic',
    displayLabel: <FontStyleAppliedLabel>Italic</FontStyleAppliedLabel>,
  },
  {
    label: 'Bold Italic',
    value: 'bold_italic',
    displayLabel: <FontStyleAppliedLabel>Bold Italic</FontStyleAppliedLabel>,
  },
];

export const measurements = [
  { label: 'px', value: 'PX' },
  { label: '%', value: 'PERCENTAGE' },
];

export const registerButtonCopy = 'Register';

export const defaultButtonTextColor = '#ffffff';

export const defaultBackgroundColor = 'transparent';

export const defaultTextColor = '#3e4859';
