/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import DisabledIcon from 'images/syncIcons/disabledIcon.svg';
import FailedIcon from 'images/syncIcons/failedIcon.svg';
import PendingIcon from 'images/syncIcons/pendingIcon.svg';
import SyncedIcon from 'images/syncIcons/syncedIcon.svg';
import Tooltip from 'components/material/Tooltip';

const TooltipContainer = styled(Tooltip)`
  display: inline-block;
`;

const Container = styled.div`
  color: ${props => props.theme.primaryActionColor};
  font-size: 16px;
  position: relative;
  display: inline-block;
  line-height: 1;
  svg {
    display: block;
    width: 14px;
    height: 14px;
  }
`;

const Status = styled.div`
  position: absolute;
  right: -8px;
  bottom: -9px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
`;

export default class SyncEnabledIcon extends React.PureComponent<{
  syncStatus?: 'OK' | 'FAILING' | 'PENDING' | 'DISABLED',
}> {
  syncStatus() {
    if (this.props.syncStatus === 'OK') {
      return 'Synced';
    }
    if (this.props.syncStatus === 'FAILING') {
      return 'Error';
    }
    if (this.props.syncStatus === 'PENDING') {
      return 'Pending';
    }
    return 'Missing Fields';
  }

  render() {
    return (
      <TooltipContainer label={this.syncStatus()} placement="top">
        <Container>
          <i className="fa fa-refresh fa-fw" />
          <Status>
            {this.props.syncStatus === 'OK' && <SyncedIcon />}
            {this.props.syncStatus === 'FAILING' && <FailedIcon />}
            {this.props.syncStatus === 'PENDING' && <PendingIcon />}
            {this.props.syncStatus === 'DISABLED' && <DisabledIcon />}
          </Status>
        </Container>
      </TooltipContainer>
    );
  }
}
