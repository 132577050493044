/* @flow */
import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';

import showErrorPopup from 'utils/showErrorPopup';

import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import { SearchResultPagination, SectionContainer, SectionTitle } from './components';
import EventNoteSearchResult from './EventNoteSearchResult';

import { type EventNotesSearchPagination_searchResults } from './__generated__/EventNotesSearchPagination_searchResults.graphql';

class EventNotesSearchPagination extends React.Component<
  {
    searchResults: EventNotesSearchPagination_searchResults,
    totalCount: number,
    relay: RelayPaginationProp,
    isolated?: boolean,
    mobileView?: boolean,
  },
  {
    refetching: boolean,
  },
> {
  state = { refetching: false };

  paginationDisposable: ?Disposable;

  handleLoadMore = () => {
    const { isolated, relay } = this.props;
    this.setState({ refetching: true });

    this.paginationDisposable = relay.loadMore(isolated ? 10 : 5, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  render() {
    const { searchResults, totalCount, isolated, mobileView } = this.props;

    return (
      <SectionContainer scrollable={isolated} mobileView={mobileView}>
        <SectionTitle>{isolated ? 'RESULTS' : 'NOTES'}</SectionTitle>
        {searchResults.eventNotes.edges.map(({ node }) => (
          <EventNoteSearchResult key={node.noteId} note={node} />
        ))}
        <SearchResultPagination
          onLoadMore={this.handleLoadMore}
          stepSize={isolated ? 10 : 5}
          currentCount={searchResults.eventNotes.edges.length}
          totalCount={totalCount}
          loading={this.state.refetching}
        />
      </SectionContainer>
    );
  }
}

export default createPaginationContainer(
  EventNotesSearchPagination,
  {
    searchResults: graphql`
      fragment EventNotesSearchPagination_searchResults on Query {
        eventNotes: searchEventNotes(search: $search, after: $cursor, first: $count)
          @connection(key: "EventNotesTablePagination_eventNotes", filters: []) {
          edges {
            node {
              noteId
              ...EventNoteSearchResult_note
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.searchResults && props.searchResults.eventNotes;
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
      };
    },
    query: graphql`
      query EventNotesSearchPaginationQuery($search: Search!, $count: Int!, $cursor: String) {
        ...EventNotesSearchPagination_searchResults
      }
    `,
  },
);
