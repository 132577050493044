/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import TextField from '../../../components/TextField';

import { type EventInfoCustomSelect_event } from './__generated__/EventInfoCustomSelect_event.graphql';

const EventInfoCustomSelect = ({
  name,
  event,
  customFieldId,
}: {
  name: string,
  event: EventInfoCustomSelect_event,
  customFieldId: string,
}) => {
  const fieldValue = event.customSelectFields.find(value => value.customField.id === customFieldId);

  if (!fieldValue) return null;

  return <TextField name={name} description={fieldValue.option.name} />;
};

export default createFragmentContainer(
  EventInfoCustomSelect,
  graphql`
    fragment EventInfoCustomSelect_event on Event {
      customSelectFields(customFieldIds: $customFieldIds) {
        option {
          id
          name
        }
        customField {
          id
        }
      }
    }
  `,
);
