/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import updateTeam from 'graph/mutations/team/updateTeam';
import showModernMutationError from 'graph/utils/showModernMutationError';

import StyleSettings, { type Styles } from 'components/StyleSettings';

import type { TeamStylesContent_team } from './__generated__/TeamStylesContent_team.graphql';

class TeamStylesContent extends React.Component<{
  team: TeamStylesContent_team,
}> {
  handleChange = ({ tz, ...data }: $Shape<Styles>) => {
    updateTeam(this.props.team.dbId, this.props.team.id, { ...data }).catch(
      showModernMutationError,
    );
  };

  render() {
    const { team } = this.props;

    return (
      <StyleSettings
        styles={{
          logo: team.logo,
          primaryColor: team.primaryColor,
          secondaryColor: team.secondaryColor,
          font: team.font,
          scheduleName: team.scheduleName,
        }}
        onChange={this.handleChange}
      />
    );
  }
}

export default createFragmentContainer(
  TeamStylesContent,
  graphql`
    fragment TeamStylesContent_team on Team {
      id
      dbId
      logo
      primaryColor
      secondaryColor
      scheduleName
      font
    }
  `,
);
