/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type InputVariableFilters } from 'components/Companies/companiesTableColumnSettings';

const mutation = graphql`
  mutation addCompanyAssignmentsMutation(
    $input: AddCompanyAssignmentsInput!
    $filters: CompanyFilters
  ) {
    addCompanyAssignments(input: $input) {
      event {
        companies {
          totalCount
        }
        ...EventCompaniesTablePagination_totalCountEvent
      }
      companyEdges {
        node {
          id
          name
          ...CompanyName_company
          ...CompanyPhone_company
          ...CompanyWebsite_company
          ...CompanyTwitter_company
          ...CompanyLinkedin_company
          ...CompanyUpdater_company
          ...CompanyUpdatedAt_company
          ...CompanyCreator_company
          ...CompanyContactsCount_company
          ...CompanyCreatedAt_company
          ...CompanySalesforceId_company
          ...CompanyStreet_company
          ...CompanyCity_company
          ...CompanyState_company
          ...CompanyZip_company
          ...CompanyCountry_company
          ...CompanyDescription_company
          ...CustomizableText_customizable
          ...CustomizableTextarea_customizable
          ...CustomizableLink_customizable
          ...CustomizableDate_customizable
          ...CustomizableBoolean_customizable
          ...CustomizableNumber_customizable
          ...CustomizableCurrency_customizable
          ...CustomizableSelect_customizable
          ...CustomizableMultiselect_customizable
          ...CustomizableUserSelect_customizable
          ...CustomizableUserMultiselect_customizable
          ...CompanyActionCell_company
        }
      }
    }
  }
`;

export default function addCompanyAssignments(
  eventId: string,
  companyIds: $ReadOnlyArray<string>,
  filtersVariables?: ?InputVariableFilters,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        eventId,
        companyIds,
      },
      filters: filtersVariables,
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'companyEdges',
      connections: [{ key: 'EventCompaniesTablePagination_companies' }],
    }),
  });
}
