/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import BillingContents from './BillingContents';

import type { BillingQueryResponse } from './__generated__/BillingQuery.graphql';

const query = graphql`
  query BillingQuery {
    org {
      ...BillingContents_org
    }
    me {
      ...BillingContents_me
    }
  }
`;

const Container = styled.div`
  padding: 0 25px 24px;
`;

const Billing = () => (
  <Container>
    <DefaultQueryRenderer
      query={query}
      renderSuccess={(props: BillingQueryResponse) => (
        <BillingContents org={props.org} me={props.me} />
      )}
    />
  </Container>
);

export default Billing;
