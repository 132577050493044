/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';

import AttachmentsBrief from './AttachmentsBrief';

import { type AttachmentsBriefRootQueryResponse } from './__generated__/AttachmentsBriefRootQuery.graphql';

const query = graphql`
  query AttachmentsBriefRootQuery(
    $eventSlug: String!
    $attachmentIds: [ID!]!
    $listToken: String
    $sharedView: Boolean!
  ) {
    event(slug: $eventSlug, listToken: $listToken) {
      briefSettings @include(if: $sharedView) {
        attachments {
          edges {
            node {
              id
              filename
              fileurl
              filetype
            }
          }
        }
      }
      allAttachments(attachmentIds: $attachmentIds) @skip(if: $sharedView) {
        edges {
          node {
            id
            filename
            fileurl
            filetype
          }
        }
      }
    }
  }
`;

export default class AttachmentsBriefRoot extends React.Component<{
  eventSlug: string,
  attachmentIds: $ReadOnlyArray<string>,
  listToken: ?string,
  sharedView: boolean,
  primaryColor: string,
}> {
  cachedAttachments = [];

  render() {
    const { listToken, eventSlug, attachmentIds, sharedView, primaryColor } = this.props;
    const variables = {
      eventSlug,
      attachmentIds,
      sharedView,
      ...(listToken ? { listToken } : {}),
    };

    return (
      <DefaultQueryRenderer
        query={query}
        public={sharedView}
        variables={variables}
        renderSuccess={(dataProps: AttachmentsBriefRootQueryResponse) => {
          if (!dataProps.event) {
            return <NotAuthorized />;
          }

          const sharedAttachments = dataProps.event.briefSettings
            ? dataProps.event.briefSettings.attachments.edges.map(edge => edge.node)
            : [];
          const savedAttachments = dataProps.event.allAttachments
            ? dataProps.event.allAttachments.edges.map(edge => edge.node)
            : [];
          this.cachedAttachments = sharedView ? sharedAttachments : savedAttachments;
          return (
            <AttachmentsBrief attachments={this.cachedAttachments} primaryColor={primaryColor} />
          );
        }}
        renderLoading={() => (
          <AttachmentsBrief attachments={this.cachedAttachments} primaryColor={primaryColor} />
        )}
      />
    );
  }
}
