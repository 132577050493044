/* @flow */

type PaymentMethod = {|
  +label: string,
  +value: string,
|};

const paymentMethods: $ReadOnlyArray<PaymentMethod> = [
  {
    label: 'Cash',
    value: 'Cash',
  },
  {
    label: 'Check',
    value: 'Check',
  },
  {
    label: 'Credit Card',
    value: 'Credit Card',
  },
  {
    label: 'Purchase Order',
    value: 'Purchase Order',
  },
];

export default paymentMethods;
