/* @flow */
import sortBy from 'lodash/sortBy';

import type { OptionGroup } from 'components/material/SelectField';

import { type CustomFields } from '../BudgetReportingPage';

export default function getCustomFieldOptions(
  customFields: CustomFields,
): $ReadOnlyArray<OptionGroup> {
  return sortBy(
    customFields.edges.map(({ node }) => node),
    'section.order',
    'order',
  ).reduce(
    (acc, field) =>
      ['LINK', 'NUMBER', 'CURRENCY', 'DATE', 'BOOLEAN'].includes(field.kind)
        ? acc
        : [...acc, { value: field.id, label: field.label }],
    [],
  );
}
