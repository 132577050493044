/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BudgetActual_org$ref = any;
type BudgetPaid_org$ref = any;
type BudgetPlanned_org$ref = any;
type CustomizableCurrency_org$ref = any;
type EventRequestsRequestedBy_org$ref = any;
type SalesforceOpportunitiesAmount_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SharedEventsTablePagination_org$ref: FragmentReference;
export type SharedEventsTablePagination_org = {|
  +salesforceAccount: ?{|
    +id: string,
    +host: string,
  |},
  +$fragmentRefs: CustomizableCurrency_org$ref & BudgetPlanned_org$ref & BudgetActual_org$ref & BudgetPaid_org$ref & SalesforceOpportunitiesAmount_org$ref & EventRequestsRequestedBy_org$ref,
  +$refType: SharedEventsTablePagination_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SharedEventsTablePagination_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeCustomizableCurrency",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includePlannedTotalCost",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeActualTotalCost",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includePaidTotalCost",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeOpportunitiesAmount",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeRequestedBy",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "host",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableCurrency",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableCurrency_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includePlannedTotalCost",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "BudgetPlanned_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeActualTotalCost",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "BudgetActual_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includePaidTotalCost",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "BudgetPaid_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeOpportunitiesAmount",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "SalesforceOpportunitiesAmount_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeRequestedBy",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventRequestsRequestedBy_org",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '258f5fff037a459343818d5f2d97e755';
module.exports = node;
