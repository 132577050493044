/* @flow */
import React from 'react';
import styled from 'styled-components';

import CheckBox from '../../../form/CheckBox';

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
  cursor: default;
`;

export default class QuestionSelectOptionRow extends React.PureComponent<{
  checked: boolean,
  disabled?: boolean,
  option: {
    +id: string,
    +name: string,
  },
  onChange: (id: string, checked: boolean) => void,
}> {
  handleChange = () => {
    this.props.onChange(this.props.option.id || '', !this.props.checked);
  };

  render() {
    const { checked, option, disabled } = this.props;
    return (
      <OptionRow>
        <CheckBox
          checked={checked}
          disabled={disabled}
          onClick={this.handleChange}
          label={option.name}
          compact
        />
      </OptionRow>
    );
  }
}
