/**
 * @flow
 * @relayHash d77c06137b610977c2071855c58fda99
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveCustomFieldSectionInput = {
  clientMutationId?: ?string,
  customFieldSectionId: string,
};
export type removeCustomFieldSectionMutationVariables = {|
  input: RemoveCustomFieldSectionInput
|};
export type removeCustomFieldSectionMutationResponse = {|
  +removeCustomFieldSection: ?{|
    +removedCustomFieldSectionId: string
  |}
|};
export type removeCustomFieldSectionMutation = {|
  variables: removeCustomFieldSectionMutationVariables,
  response: removeCustomFieldSectionMutationResponse,
|};
*/


/*
mutation removeCustomFieldSectionMutation(
  $input: RemoveCustomFieldSectionInput!
) {
  removeCustomFieldSection(input: $input) {
    removedCustomFieldSectionId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveCustomFieldSectionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeCustomFieldSection",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveCustomFieldSectionInput!"
      }
    ],
    "concreteType": "RemoveCustomFieldSectionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedCustomFieldSectionId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeCustomFieldSectionMutation",
  "id": null,
  "text": "mutation removeCustomFieldSectionMutation(\n  $input: RemoveCustomFieldSectionInput!\n) {\n  removeCustomFieldSection(input: $input) {\n    removedCustomFieldSectionId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeCustomFieldSectionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeCustomFieldSectionMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0317b15dfcff37cc4a57e7eb041a36b';
module.exports = node;
