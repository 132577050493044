/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';

import { type CellPropsType } from 'components/budget/Table';

import { type VendorExpenseCellPropsType } from './index';

import type { EventName_expense } from './__generated__/EventName_expense.graphql';

const preventDefault = (e: SyntheticEvent<HTMLElement>) => {
  e.preventDefault();
};

const EventName = ({
  expense,
  location,
}: CellPropsType<VendorExpenseCellPropsType<EventName_expense>>) => {
  const { slug, name } = expense.event;
  return (
    <Link
      to={{
        pathname: `/events/${slug}`,
        state: {
          from: 'vendor_expenses',
          fromUrl: location.pathname,
        },
      }}
      onMouseDown={preventDefault}
    >
      {name}
    </Link>
  );
};

export default createFragmentContainer(
  EventName,
  graphql`
    fragment EventName_expense on Expense {
      event {
        slug
        name
      }
    }
  `,
);
