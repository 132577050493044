/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation reorderTasksMutation($input: ReorderDeliverablesInput!) {
    reorderDeliverables(input: $input) {
      updatedDeliverables {
        id
        order
        folderId
      }
    }
  }
`;

export default function reorderTasks(
  taskIds: Array<string>,
  folderId: ?string,
  newPosition: number,
  optimisticOrders: Array<number>,
) {
  const folder = folderId ? { id: folderId } : null;
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableIds: taskIds,
        folderId,
        newPosition,
      },
    },
    optimisticResponse: {
      reorderDeliverables: {
        updatedDeliverables: taskIds.map((taskId, index) => ({
          id: taskId,
          folder,
          order: optimisticOrders[index],
        })),
      },
    },
  });
}
