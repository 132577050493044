/**
 * @flow
 * @relayHash 1d8ffe8e415a9ce01251eba4a4bbbc56
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type VendorSort = "CITY" | "COMPANY_PHONE" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "FIRST_NAME" | "LAST_NAME" | "LINKEDIN" | "NAME" | "PHONE1" | "PHONE2" | "STATE" | "STREET" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "WEBSITE" | "ZIP";
export type VendorFilters = {
  query?: ?string,
  vendorIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?VendorSort,
  direction?: ?Direction,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type EventVendorsBriefRootQueryVariables = {|
  eventSlug: string,
  vendorFilters: VendorFilters,
  listToken?: ?string,
  sharedView: boolean,
|};
export type EventVendorsBriefRootQueryResponse = {|
  +event: ?{|
    +briefSettings?: {|
      +vendors: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +email: ?string,
            +phone1: ?string,
            +phone2: ?string,
            +title: ?string,
            +name: string,
          |}
        |}>
      |}
    |},
    +vendors?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +email: ?string,
          +phone1: ?string,
          +phone2: ?string,
          +title: ?string,
          +name: string,
        |}
      |}>
    |},
  |}
|};
export type EventVendorsBriefRootQuery = {|
  variables: EventVendorsBriefRootQueryVariables,
  response: EventVendorsBriefRootQueryResponse,
|};
*/


/*
query EventVendorsBriefRootQuery(
  $eventSlug: String!
  $vendorFilters: VendorFilters!
  $listToken: String
  $sharedView: Boolean!
) {
  event(slug: $eventSlug, listToken: $listToken) {
    briefSettings @include(if: $sharedView) {
      vendors {
        edges {
          node {
            id
            firstName
            lastName
            email
            phone1
            phone2
            title
            name
          }
        }
      }
      id
    }
    vendors(filters: $vendorFilters) @skip(if: $sharedView) {
      edges {
        node {
          id
          firstName
          lastName
          email
          phone1
          phone2
          title
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "vendorFilters",
    "type": "VendorFilters!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "listToken",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sharedView",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "listToken",
    "variableName": "listToken",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "VendorRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Vendor",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone1",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "vendors",
  "storageKey": null,
  "args": null,
  "concreteType": "VendorRequiredConnection",
  "plural": false,
  "selections": v3
},
v5 = {
  "kind": "Condition",
  "passingValue": false,
  "condition": "sharedView",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vendors",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "vendorFilters",
          "type": "VendorFilters"
        }
      ],
      "concreteType": "VendorRequiredConnection",
      "plural": false,
      "selections": v3
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventVendorsBriefRootQuery",
  "id": null,
  "text": "query EventVendorsBriefRootQuery(\n  $eventSlug: String!\n  $vendorFilters: VendorFilters!\n  $listToken: String\n  $sharedView: Boolean!\n) {\n  event(slug: $eventSlug, listToken: $listToken) {\n    briefSettings @include(if: $sharedView) {\n      vendors {\n        edges {\n          node {\n            id\n            firstName\n            lastName\n            email\n            phone1\n            phone2\n            title\n            name\n          }\n        }\n      }\n      id\n    }\n    vendors(filters: $vendorFilters) @skip(if: $sharedView) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          email\n          phone1\n          phone2\n          title\n          name\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventVendorsBriefRootQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBriefTemplate",
                "plural": false,
                "selections": [
                  v4
                ]
              }
            ]
          },
          v5
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventVendorsBriefRootQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBriefTemplate",
                "plural": false,
                "selections": [
                  v4,
                  v2
                ]
              }
            ]
          },
          v5
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '259c5a20c919dd90d371cea8e1ad625a';
module.exports = node;
