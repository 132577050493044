/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventDatesComponent_registrationForm$ref = any;
type EventNameComponent_registrationForm$ref = any;
type RowComponent_registrationForm$ref = any;
export type Measurement = "PERCENTAGE" | "PX";
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationPageContent_registrationForm$ref: FragmentReference;
export type RegistrationPageContent_registrationForm = {|
  +id: string,
  +backgroundUrl: ?string,
  +backgroundColor: ?string,
  +width: number,
  +widthMeasurement: Measurement,
  +pageComponents: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +order: number,
        +kind: RegistrationPageComponentKind,
        +parent: ?{|
          +id: string
        |},
        +formComponent: ?{|
          +id: string
        |},
        +childPageComponents: ?{|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +columnChild: ?{|
                +id: string
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$fragmentRefs: EventNameComponent_registrationForm$ref & EventDatesComponent_registrationForm$ref & RowComponent_registrationForm$ref,
  +$refType: RegistrationPageContent_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
];
return {
  "kind": "Fragment",
  "name": "RegistrationPageContent_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundColor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "width",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "widthMeasurement",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pageComponents",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationPageComponentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RegistrationPageComponentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationPageComponent",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "parent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationPageComponent",
                  "plural": false,
                  "selections": v1
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "formComponent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationFormComponent",
                  "plural": false,
                  "selections": v1
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "childPageComponents",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationPageComponentRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "RegistrationPageComponentRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "RegistrationPageComponent",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "columnChild",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "RegistrationPageComponent",
                              "plural": false,
                              "selections": v1
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "EventNameComponent_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventDatesComponent_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RowComponent_registrationForm",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ebd28c5ca154d5e4dd015deb5bd06563';
module.exports = node;
