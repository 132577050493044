/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TeamBudgeting_teams$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetSetup_user$ref: FragmentReference;
export type BudgetSetup_user = {|
  +teams: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: TeamBudgeting_teams$ref
      |}
    |}>
  |},
  +$refType: BudgetSetup_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BudgetSetup_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "teams",
      "storageKey": null,
      "args": null,
      "concreteType": "TeamRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TeamRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Team",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "TeamBudgeting_teams",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '81759efb29f55096abba6474b36b7d2f';
module.exports = node;
