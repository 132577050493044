/* @flow */
import React from 'react';
import styled from 'styled-components';
import Tooltip from 'components/material/Tooltip';

const Icon = styled.i`
  color: #cdd1d4;
  &:hover {
    color: #96a2ab;
  }
`;

export default class LockIcon extends React.PureComponent<{
  className?: string,
  label: string,
}> {
  render() {
    return (
      <Tooltip label={this.props.label} placement="top" className={this.props.className}>
        <Icon className="fa fa-fw fa-lock" />
      </Tooltip>
    );
  }
}
