/**
 * @flow
 * @relayHash 916d7270578c8a7cb1cae549b73e3a76
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CommentItem_comment$ref = any;
export type CreateCommentInput = {
  clientMutationId?: ?string,
  commentableId: string,
  content: string,
};
export type createCommentMutationVariables = {|
  input: CreateCommentInput
|};
export type createCommentMutationResponse = {|
  +createComment: ?{|
    +commentEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +$fragmentRefs: CommentItem_comment$ref,
      |},
    |}
  |}
|};
export type createCommentMutation = {|
  variables: createCommentMutationVariables,
  response: createCommentMutationResponse,
|};
*/


/*
mutation createCommentMutation(
  $input: CreateCommentInput!
) {
  createComment(input: $input) {
    commentEdge {
      __typename
      node {
        id
        ...CommentItem_comment
      }
    }
  }
}

fragment CommentItem_comment on Comment {
  id
  content
  postedAt
  systemGenerated
  user {
    id
    slug
    firstName
    lastName
    avatar
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateCommentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateCommentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createCommentMutation",
  "id": null,
  "text": "mutation createCommentMutation(\n  $input: CreateCommentInput!\n) {\n  createComment(input: $input) {\n    commentEdge {\n      __typename\n      node {\n        id\n        ...CommentItem_comment\n      }\n    }\n  }\n}\n\nfragment CommentItem_comment on Comment {\n  id\n  content\n  postedAt\n  systemGenerated\n  user {\n    id\n    slug\n    firstName\n    lastName\n    avatar\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createCommentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createComment",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateCommentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "commentEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "CommentRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Comment",
                "plural": false,
                "selections": [
                  v3,
                  {
                    "kind": "FragmentSpread",
                    "name": "CommentItem_comment",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createCommentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createComment",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateCommentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "commentEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "CommentRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Comment",
                "plural": false,
                "selections": [
                  v3,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "content",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "postedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "systemGenerated",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e56de315a72f1604abae38b3cab65520';
module.exports = node;
