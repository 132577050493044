/* @flow */
import type { RecordProxy, RecordSourceSelectorProxy, SelectorData } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import getConnection, { type Connection } from './lib/getConnection';
import getMutationRoot from './lib/getMutationRoot';

function removeRecordsFromConnections(deletedConnectionsKey: string, connectionConfig: Connection) {
  return (store: RecordSourceSelectorProxy, data: SelectorData) => {
    const root: ?RecordProxy = getMutationRoot(store, data);

    if (!root) throw new Error('Root field missing in mutation');

    const deletedConnections = root.getLinkedRecords(deletedConnectionsKey);

    if (!deletedConnections) {
      throw new Error(`"${deletedConnections}" is not present in mutation response`);
    }

    deletedConnections.forEach(removedConnection => {
      const parentId = removedConnection.getValue('parentId');
      const childId = removedConnection.getValue('childId');
      const parentRecord = store.get(parentId);
      const connection = getConnection(parentRecord, connectionConfig);
      ConnectionHandler.deleteNode(connection, childId);
      store.delete(childId);
    });
  };
}

export default removeRecordsFromConnections;
