/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import { Input } from 'components/material/TextField';
import DateTimePicker, {
  InputWrapper as DateTimeInputWrapper,
  type DateConfig,
} from 'components/date/DateTimePicker';
import DateTimeRangePicker, {
  InputWrapper as DateTimeRangeInputWrapper,
  type DatesConfig,
} from 'components/date/DateTimeRangePicker';

import type { QuestionType, QuestionValueType, QuestionValueInputType } from '../lib/types';
import ErrorMessage from '../form/ErrorMessage';
import inputBoxStyled from '../form/inputBoxStyled';
import HelperText from '../form/HelperText';

const Container = styled.div`
  display: flex;
  ${DateTimeRangeInputWrapper}, ${DateTimeInputWrapper} {
    > label {
      margin: 0;
    }
    ${Input} {
      ${props => inputBoxStyled(props)};
    }
  }
`;

export default class QuestionDate extends React.PureComponent<{
  question: QuestionType,
  value: QuestionValueType,
  onChangeValue: QuestionValueInputType => void,
  readOnly: boolean,
  tz: string,
  error: ?string,
}> {
  handleChangeDate = (dateConfig: DateConfig) => {
    this.props.onChangeValue({
      dateValue: dateConfig.date
        ? {
            startDate: dateConfig.date,
            endDate: null,
            startDateAllDay: null,
            endDateAllDay: null,
          }
        : null,
    });
  };

  handleChangeDates = (datesConfig: DatesConfig) => {
    this.props.onChangeValue({
      dateValue: datesConfig.startDate
        ? {
            startDate: datesConfig.startDate,
            startDateAllDay: datesConfig.hideStartTime,
            endDate: datesConfig.endDate,
            endDateAllDay: datesConfig.hideEndTime,
          }
        : null,
    });
  };

  render() {
    const { question, tz, readOnly, error, value } = this.props;
    const { startDate, startDateAllDay, endDate, endDateAllDay } = value.dateValue || {};
    const isDateRange = question.mapping === 'DATE';

    return (
      <React.Fragment>
        {this.props.question.description && (
          <HelperText>{this.props.question.description}</HelperText>
        )}

        <Container>
          {isDateRange ? (
            <DateTimeRangePicker
              tz={tz}
              startDate={startDate}
              endDate={endDate}
              hideStartTime={startDateAllDay == null || startDateAllDay}
              hideEndTime={endDateAllDay == null || endDateAllDay}
              disabled={readOnly}
              startPlaceholder="Start Date"
              endPlaceholder="End Date"
              onChange={this.handleChangeDates}
            />
          ) : (
            <DateTimePicker
              tz={tz}
              date={startDate}
              hideTime
              disabled={readOnly}
              onChange={this.handleChangeDate}
              dateOnly
            />
          )}
        </Container>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </React.Fragment>
    );
  }
}
