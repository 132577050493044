/* @flow */

import React from 'react';
import { createRefetchContainer, graphql, type RelayRefetchProp } from 'react-relay';
import styled from 'styled-components';
import theme from 'config/theme';

import NoResultsMessage from 'components/NoResultsMessage';

import Checkbox, { Check } from 'components/material/CheckBox';
import AttachmentPreview from 'components/RelayAttachments/AttachmentPreview';

import AddNewLink from '../AddNewLink';

import AttachmentsContainer_event from './__generated__/AttachmentsContainer_event.graphql';

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 25px;

  ${Check} {
    border-radius: 2px;
  }
`;

const Label = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  margin-left: 10px;
  font-size: 14px;
  color: ${props => props.theme.rowPrimaryTextColor};
  word-break: break-all;
  > span {
    flex: 1 1 auto;
  }
  > i {
    color: #dadada;
  }
`;

class AttachmentsContainer extends React.Component<
  {
    relay: RelayRefetchProp,
    event: AttachmentsContainer_event,
    selectedAttachments: $ReadOnlyArray<string>,
    searchQuery: string,
    onAllAttachmentCheckToggle: (
      isPrevAllCheck: boolean,
      searchedAttachmentIds: $ReadOnlyArray<string>,
    ) => void,
    handleAttachmentToggle: (attachmentId: string, isPrevChecked: boolean) => void,
  },
  {
    previewId: string,
  },
> {
  state = { previewId: '' };

  componentWillUnmount() {
    this.removeTabChangeListener();
  }

  removeTabChangeListener = () => {
    this.attachmentWindow = null;
    document.removeEventListener('visibilitychange', this.documentFocus);
  };

  documentFocus = () => {
    if (document.visibilityState === 'visible') {
      const {
        relay,
        event: { slug: eventSlug },
        searchQuery: query,
      } = this.props;
      relay.refetch({ eventSlug, query });
    }
  };

  handleAddNewClick = () => {
    const needToAddListener = !this.attachmentWindow;
    this.attachmentWindow = window.open(`/events/${this.props.event.slug}/attachments`, '_blank');
    if (needToAddListener) {
      this.attachmentWindow.addEventListener('beforeunload', this.removeTabChangeListener);
      document.addEventListener('visibilitychange', this.documentFocus);
    }
  };

  handlePreview = (previewId: string) => {
    this.setState({ previewId });
  };

  attachmentWindow: ?EventTarget;

  renderAttachmentLabel = (attachment: { id: string, filename: string, fileurl: string }) => {
    return (
      <Label>
        <span>{attachment.filename || attachment.fileurl}</span>
        <i
          className="fa fa-fw fa-eye"
          onClick={(e: SyntheticEvent<HTMLElement>) => {
            e.stopPropagation();
            this.handlePreview(attachment.id);
          }}
        />
      </Label>
    );
  };

  render() {
    const {
      event: { allAttachments },
      selectedAttachments,
      onAllAttachmentCheckToggle,
      handleAttachmentToggle,
    } = this.props;
    const allSearchedAttachments = allAttachments.edges.map(({ node }) => node);
    const allAttachmentsIds = allSearchedAttachments.map(at => at.id);
    const checkAll = allSearchedAttachments.every(at => selectedAttachments.includes(at.id));

    return (
      <React.Fragment>
        <AddNewLink label="Add new attachment" onClick={this.handleAddNewClick} />
        {allSearchedAttachments.length > 0 ? (
          <React.Fragment>
            <StyledCheckbox
              label="All"
              key={0}
              checked={checkAll}
              checkColor={checkAll ? theme.highlightColor : theme.silver}
              onChange={() => onAllAttachmentCheckToggle(checkAll, allAttachmentsIds)}
            />
            {allSearchedAttachments.map(a => {
              const checked = selectedAttachments.includes(a.id);
              return (
                <StyledCheckbox
                  key={a.id}
                  checked={checked}
                  checkColor={checked ? theme.highlightColor : theme.silver}
                  labelRenderer={() => this.renderAttachmentLabel(a)}
                  onChange={() => handleAttachmentToggle(a.id, checked)}
                />
              );
            })}
            {this.state.previewId && (
              <AttachmentPreview
                attachments={[]}
                mockAttachments={allSearchedAttachments}
                previewId={this.state.previewId}
                onPreview={this.handlePreview}
                onRemove={null}
              />
            )}
          </React.Fragment>
        ) : (
          <NoResultsMessage iconName="picture-o" message="Attachment not found." />
        )}
      </React.Fragment>
    );
  }
}

export default createRefetchContainer(
  AttachmentsContainer,
  graphql`
    fragment AttachmentsContainer_event on Event {
      slug
      allAttachments(query: $query) {
        edges {
          node {
            id
            filename
            fileurl
            filetype
          }
        }
      }
    }
  `,
  graphql`
    query AttachmentsContainerRefetchQuery($eventSlug: String!, $query: String) {
      event(slug: $eventSlug) {
        id
        ...AttachmentsContainer_event
      }
    }
  `,
);
