/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventInfoLastSynced_event$ref: FragmentReference;
export type EventInfoLastSynced_event = {|
  +tz: string,
  +pullStatus: ?{|
    +lastSuccessAt: ?any
  |},
  +pushStatus: ?{|
    +lastSuccessAt: ?any
  |},
  +$refType: EventInfoLastSynced_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastSuccessAt",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "EventInfoLastSynced_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pullStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pushStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": v0
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b7942fc9140e084f8324266472e0b791';
module.exports = node;
