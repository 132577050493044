/**
 * @flow
 * @relayHash b8fde13fab2866c3162ca2c30a768bc0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCompanyFieldsInput = {
  clientMutationId?: ?string,
  contactFormId: string,
};
export type addCompanyFieldsMutationVariables = {|
  input: AddCompanyFieldsInput
|};
export type addCompanyFieldsMutationResponse = {|
  +addCompanyFields: ?{|
    +contactForm: {|
      +enableCompanyFields: boolean
    |},
    +contactFormFieldEdges: ?$ReadOnlyArray<{|
      +node: {|
        +id: string,
        +label: string,
        +fieldName: ?string,
        +required: boolean,
        +order: number,
        +customField: ?{|
          +id: string
        |},
        +contactFormFieldOptions: ?{|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +customFieldOption: {|
                +id: string
              |}
            |}
          |}>
        |},
        +contactFormFieldUserOptions: ?{|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +user: {|
                +id: string
              |}
            |}
          |}>
        |},
      |}
    |}>,
    +registrationForm: ?{|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type addCompanyFieldsMutation = {|
  variables: addCompanyFieldsMutationVariables,
  response: addCompanyFieldsMutationResponse,
|};
*/


/*
mutation addCompanyFieldsMutation(
  $input: AddCompanyFieldsInput!
) {
  addCompanyFields(input: $input) {
    contactForm {
      enableCompanyFields
      id
    }
    contactFormFieldEdges {
      node {
        id
        label
        fieldName
        required
        order
        customField {
          id
        }
        contactFormFieldOptions {
          edges {
            node {
              customFieldOption {
                id
              }
              id
            }
          }
        }
        contactFormFieldUserOptions {
          edges {
            node {
              user {
                id
              }
              id
            }
          }
        }
      }
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddCompanyFieldsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddCompanyFieldsInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enableCompanyFields",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "required",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v8 = [
  v3
],
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": v8
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customFieldOption",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldOptionType",
  "plural": false,
  "selections": v8
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": v8
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addCompanyFieldsMutation",
  "id": null,
  "text": "mutation addCompanyFieldsMutation(\n  $input: AddCompanyFieldsInput!\n) {\n  addCompanyFields(input: $input) {\n    contactForm {\n      enableCompanyFields\n      id\n    }\n    contactFormFieldEdges {\n      node {\n        id\n        label\n        fieldName\n        required\n        order\n        customField {\n          id\n        }\n        contactFormFieldOptions {\n          edges {\n            node {\n              customFieldOption {\n                id\n              }\n              id\n            }\n          }\n        }\n        contactFormFieldUserOptions {\n          edges {\n            node {\n              user {\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addCompanyFieldsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addCompanyFields",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddCompanyFieldsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactForm",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactForm",
            "plural": false,
            "selections": [
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactFormFieldEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactFormFieldRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactFormField",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v9,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contactFormFieldOptions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldOptionRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldOptionRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContactFormFieldOption",
                            "plural": false,
                            "selections": [
                              v10
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contactFormFieldUserOptions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldUserOptionRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldUserOptionRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContactFormFieldUserOption",
                            "plural": false,
                            "selections": [
                              v11
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "addCompanyFieldsMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addCompanyFields",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddCompanyFieldsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactForm",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactForm",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactFormFieldEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactFormFieldRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactFormField",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v9,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contactFormFieldOptions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldOptionRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldOptionRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContactFormFieldOption",
                            "plural": false,
                            "selections": [
                              v10,
                              v3
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contactFormFieldUserOptions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldUserOptionRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldUserOptionRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContactFormFieldUserOption",
                            "plural": false,
                            "selections": [
                              v11,
                              v3
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '214b876d9d68c6d04902a8c22843ce96';
module.exports = node;
