/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineEditableCell from 'components/budget/Table/InlineEditableCell';
import { type ContactType } from 'components/Contacts/ContactWindow/ContactTypeSelector';
import { Field } from 'components/material/SelectField';
import { type Participant } from 'components/Participants';
import CompanySearch, { StyledAutocompleteInput } from 'components/Participants/CompanySearch';

import type { ContactCompany_contact } from './__generated__/ContactCompany_contact.graphql';
import type { ContactCompany_event } from './__generated__/ContactCompany_event.graphql';

const Root = styled.div`
  ${Field} {
    padding: 0 10px 0 20px;
  }
`;

const SyncIcon = styled.i`
  position: absolute;
  right: -10px;
  top: 2px;
  font-size: 12px;
  color: ${props => props.theme.primaryActionColor};
`;

const StyledCompanySearch = styled(CompanySearch)`
  ${StyledAutocompleteInput} {
    height: 100%;
  }
`;

class ContactCompany extends React.Component<
  {
    contact: ContactCompany_contact,
    event?: ContactCompany_event,
    fieldSettings: FieldType,
    updateColumnWidth: () => void,
    onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
  },
  {
    error: ?string,
  },
> {
  state = { error: null };

  validated: boolean = false;

  forceBlur: boolean = false;

  componentDidUpdate(prevProps: $PropertyType<ContactCompany, 'props'>) {
    const { fieldSettings, contact } = this.props;
    if (
      this.state.error &&
      fieldSettings.required &&
      contact.company != null &&
      prevProps.contact.company == null
    ) {
      this.forceBlur = true;
    }
  }

  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?Participant, contactType?: ContactType = 'companies') => {
    const company = this.props.contact.company;

    if (
      contactType !== 'companies' ||
      (!value && !company) ||
      (value && company && value.id === company.id)
    ) {
      return;
    }

    this.props.onUpdate({
      company:
        value != null
          ? { id: value.id, name: value.name ? value.name : '', salesforceId: value.salesforceId }
          : null,
    });
    this.props.updateColumnWidth();

    this.validated = true;
    this.forceBlur = false;
  };

  companyPath = (): string => {
    const { contact, event } = this.props;
    if (contact.company == null) {
      return '';
    }

    const companyId = contact.company.id;

    return event != null
      ? `/events/${event.slug}/contacts/companies/${companyId}`
      : `/contacts/companies/${companyId}`;
  };

  getError = (): ?string => {
    const { contact, fieldSettings } = this.props;

    if (!this.validated && fieldSettings.required && contact.company == null) {
      return 'Required';
    }

    return null;
  };

  handleValidate = (): boolean => {
    const updateColumnWidth = this.props.updateColumnWidth;
    const error: ?string = this.getError();
    this.setState(state => (state.error === error ? null : { error }));
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  render() {
    const { fieldSettings, contact, event } = this.props;

    return (
      <Root>
        <InlineEditableCell
          onClear={!fieldSettings.required && contact.company != null ? this.handleUpdate : null}
          onValidate={this.handleValidate}
          error={this.state.error}
        >
          {({ onBlur, editing }) => {
            if (!editing) {
              return contact.company ? (
                <Link to={this.companyPath()} onMouseDown={this.handleMouseDown}>
                  {contact.company.name}
                  {contact.company.salesforceId && <SyncIcon className="fa fa-fw fa-refresh" />}
                </Link>
              ) : null;
            }
            if (this.forceBlur && onBlur) {
              onBlur();
              this.forceBlur = false;
            }
            return (
              <StyledCompanySearch
                onSelect={this.handleUpdate}
                onBlur={() => {
                  if (onBlur && this.handleValidate()) {
                    onBlur();
                  }
                }}
                fromWindow={event ? 'event contacts' : 'org contacts'}
                company={contact.company}
                overlayContainer={document.body}
                noBoxShadow
                noLabel
                autoFocus
              />
            );
          }}
        </InlineEditableCell>
      </Root>
    );
  }
}

export default createFragmentContainer(ContactCompany, {
  contact: graphql`
    fragment ContactCompany_contact on Contact {
      company {
        id
        name
        salesforceId
      }
    }
  `,
  event: graphql`
    fragment ContactCompany_event on Event {
      slug
    }
  `,
});
