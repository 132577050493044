/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SchedulesMultiHeader_event$ref = any;
type SchedulesTimeline_schedules$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchedulesMulti_event$ref: FragmentReference;
export type SchedulesMulti_event = {|
  +slug: string,
  +tz: string,
  +schedules: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
        +slug: string,
        +slugs: $ReadOnlyArray<{|
          +slug: string
        |}>,
        +order: number,
        +shared: boolean,
        +token: string,
        +dbId: number,
        +$fragmentRefs: SchedulesTimeline_schedules$ref,
      |}
    |}>
  |},
  +$fragmentRefs: SchedulesMultiHeader_event$ref,
  +$refType: SchedulesMulti_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SchedulesMulti_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SchedulesMultiHeader_event",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "schedules",
      "storageKey": null,
      "args": null,
      "concreteType": "ScheduleRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ScheduleRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Schedule",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                v0,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "slugs",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Slug",
                  "plural": true,
                  "selections": [
                    v0
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "shared",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "token",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "dbId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "SchedulesTimeline_schedules",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '54773ee30e81f4110fdf610e7f7fd31e';
module.exports = node;
