/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TeamMembersTableRow_member$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeamMembersTable_members$ref: FragmentReference;
export type TeamMembersTable_members = {|
  +edges: $ReadOnlyArray<{|
    +node: {|
      +id: string
    |},
    +$fragmentRefs: TeamMembersTableRow_member$ref,
  |}>,
  +$refType: TeamMembersTable_members$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TeamMembersTable_members",
  "type": "TeamMemberConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TeamMemberEdge",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "TeamMembersTableRow_member",
          "args": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'cd1ddbf9fe5032b27c20741f9abec1da';
module.exports = node;
