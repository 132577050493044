/* @flow */
import { graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type InputVariableFilters } from 'components/Contacts/contactsTableColumnSettings';

const mutation = graphql`
  mutation removeContactsMutation(
    $filters: ContactFilters
    $input: RemoveContactsInput!
    $hasFilters: Boolean!
  ) {
    removeContacts(input: $input) {
      org {
        contacts @include(if: $hasFilters) {
          totalCount
        }
        ...OrgContactsTablePagination_totalCountOrg @include(if: $hasFilters)
      }
      event @include(if: $hasFilters) {
        ...ContactsTablePagination_totalCountEvent
        eventContacts @include(if: $hasFilters) {
          totalCount
        }
        contactRegistrationsTotal
      }
      removedContactIds
    }
  }
`;

type Args = {
  orgId?: string,
  eventId?: ?string,
  contactIds: $ReadOnlyArray<string>,
  filters?: InputVariableFilters,
  contactFields?: $ReadOnlyArray<FieldType>,
  fromWindow: string,
};

export default function removeContacts({ eventId, orgId, contactIds, filters, fromWindow }: Args) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        contactIds,
        eventId,
        fromWindow,
      },
      filters,
      hasFilters: !!filters,
    },
    optimisticResponse: {
      removeContacts: {
        removedContactIds: contactIds,
      },
    },
    updater: mergeUpdaters(
      ...[
        eventId != null
          ? removeRecordFromConnection({
              deletedIdField: 'removedContactIds',
              parentId: eventId,
              connections: [{ key: 'ContactsTablePagination_eventContacts' }],
            })
          : null,
        orgId != null
          ? removeRecordFromConnection({
              deletedIdField: 'removedContactIds',
              parentId: orgId,
              connections: [{ key: 'OrgContactsTablePagination_contacts' }],
            })
          : null,
      ].filter(Boolean),
      removeRecordFromStore({ deletedIdField: 'removedContactIds' }),
    ),
  });
}
