/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateRegistrationForm, {
  type updateRegistrationPropertyType,
} from 'graph/mutations/registration/updateRegistrationForm';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Plus from 'images/plus.svg';
import Button from 'components/budget/Button';
import BgImageSelectWindow from 'components/material/BgImageSelectWindow';

import PropertyRow from '../../components/PropertyRow';
import RegistrationColorSelector from '../../components/RegistrationColorSelector';

import type { BackgroundStyling_orgSettings } from './__generated__/BackgroundStyling_orgSettings.graphql';
import type { BackgroundStyling_registrationForm } from './__generated__/BackgroundStyling_registrationForm.graphql';

const StyledButton = styled(Button)`
  width: 55px;
  height: 25px;
  padding: 0 0;
`;

const Remover = styled.div`
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: -7px;
  right: -7px;
  width: 15px;
  height: 15px;
  color: #868f96;
  background: #f7f8f9;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #4a5665;
  }
  > svg {
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 15px;
  &:hover {
    ${Remover} {
      display: flex;
    }
  }
`;

const Image = styled.img`
  width: 40px;
  cursor: pointer;
`;

class BackgroundStyling extends React.PureComponent<
  {
    orgSettings: BackgroundStyling_orgSettings,
    registrationForm: BackgroundStyling_registrationForm,
  },
  {
    showBgImageSelectWindow: boolean,
  },
> {
  state = { showBgImageSelectWindow: false };

  updateRegistrationForm = (properties: updateRegistrationPropertyType) => {
    updateRegistrationForm({ ...properties, formId: this.props.registrationForm.id }).catch(
      showModernMutationError,
    );
  };

  handleChangeBackgroundColor = (backgroundColor: string) => {
    this.updateRegistrationForm({ backgroundColor });
  };

  handleClickUploadBackground = () => {
    this.setState({ showBgImageSelectWindow: true });
  };

  handleBgImageWindowHide = () => {
    this.setState({ showBgImageSelectWindow: false });
  };

  handleSaveBgImage = (backgroundUrl: ?string) => {
    this.updateRegistrationForm({ backgroundUrl });
  };

  handleRemoveBgImage = () => {
    this.handleSaveBgImage(null);
  };

  render() {
    const {
      orgSettings,
      registrationForm: { backgroundColor, backgroundUrl },
    } = this.props;
    return (
      <>
        <PropertyRow label="Background color">
          <RegistrationColorSelector
            orgSettings={orgSettings}
            currentColor={backgroundColor || '#ffffff'}
            onChangeColor={this.handleChangeBackgroundColor}
            includeTransparent
          />
        </PropertyRow>
        <PropertyRow label="Background image">
          <div>
            {backgroundUrl && (
              <ImageWrapper>
                <Image src={backgroundUrl} />
                <Remover onClick={this.handleRemoveBgImage}>
                  <Plus />
                </Remover>
              </ImageWrapper>
            )}
            <StyledButton onClick={this.handleClickUploadBackground}>Add</StyledButton>
          </div>
        </PropertyRow>
        {this.state.showBgImageSelectWindow && (
          <BgImageSelectWindow
            value={backgroundUrl}
            onHide={this.handleBgImageWindowHide}
            onSave={this.handleSaveBgImage}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(BackgroundStyling, {
  orgSettings: graphql`
    fragment BackgroundStyling_orgSettings on OrgSettings {
      id
      ...RegistrationColorSelector_orgSettings
    }
  `,
  registrationForm: graphql`
    fragment BackgroundStyling_registrationForm on RegistrationForm {
      id
      backgroundColor
      backgroundUrl
    }
  `,
});
