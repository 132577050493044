/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';
import DateTimeTooltip from 'components/material/DateTimeTooltip';

import CompanyCreatedAt_company from './__generated__/CompanyCreatedAt_company.graphql';

type Props = {
  company: CompanyCreatedAt_company,
  tz: string,
};

const CompanyCreatedAt = ({ company, tz }: Props) => {
  if (!company) {
    return null;
  }

  return company.createdAt && <DateTimeTooltip date={moment.tz(company.createdAt, tz)} />;
};

export default createFragmentContainer(
  CompanyCreatedAt,
  graphql`
    fragment CompanyCreatedAt_company on Company {
      createdAt
    }
  `,
);
