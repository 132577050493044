/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import ConfirmationWindow from 'components/ConfirmationWindow';
import TextInput from 'components/material/TextInput';

import TaskRow from './TaskRow';
import TasksCount from './TasksCount';

import type { TasksFolder_folder } from './__generated__/TasksFolder_folder.graphql';
import type { TasksFolder_tasks } from './__generated__/TasksFolder_tasks.graphql';

const Container = styled.div`
  border: 1px solid ${props => props.theme.primaryRowColor};
  background: ${props => props.theme.primaryRowColor};
  margin-bottom: 10px;
`;

const Actions = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
  @media (${props => props.theme.mobileOnly}) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 35px;
  &:hover {
    background: ${props => props.theme.rowHoverColor};
    cursor: pointer;
    > ${Actions} {
      visibility: visible;
    }
  }
`;

const Arrow = styled.div`
  color: ${props => props.theme.secondaryActionColor};
  margin-left: -7px;
  font-size: 22px;
`;

const List = styled.div`
  display: ${props => (props.shown ? 'block' : 'none')};
`;

const Name = styled.div`
  margin-left: 10px;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Title = styled.span`
  color: ${props => props.theme.rowPrimaryTextColor};
  font-weight: 500;
`;

const Action = styled.div`
  color: ${props => props.theme.secondaryActionColor};
  font-size: 16px;
  margin-left: 10px;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const NameInput = styled(TextInput)`
  padding: 0;
  width: 250px;
  font-weight: 500;
  margin-left: 10px;
`;

type Props = {
  folder: TasksFolder_folder,
  tasks: TasksFolder_tasks,
  children: Array<TaskRow>,
  onRemove: string => void,
  onUpdate: (string, Object) => void,
  new?: boolean,
  open: boolean,
  onToggleOpen: (folderId: string) => void,
};

class TasksFolder extends React.PureComponent<
  Props,
  {
    renaming: boolean,
    confirmingDelete: boolean,
  },
> {
  state = {
    renaming: false,
    confirmingDelete: false,
  };

  componentDidMount() {
    if (this.props.new && !this.props.open) {
      this.handleToggleOpen();
    }
  }

  componentDidUpdate() {
    if (this.props.new && !this.props.open) {
      this.handleToggleOpen();
    }
  }

  handleToggleOpen = () => {
    this.props.onToggleOpen(this.props.folder.id);
  };

  handleRename = e => {
    e.stopPropagation();
    this.setState({ renaming: true });
  };

  handleKeyDown = e => {
    if (e.key === 'Escape') {
      this.setState({ renaming: false });
    } else if (e.key === 'Enter') {
      this.setState({ renaming: false });

      const name = e.currentTarget.value.trim();

      if (name && name !== this.props.folder.name) {
        this.props.onUpdate(this.props.folder.id, { name });
      }
    }
  };

  handleInputClick = e => {
    e.stopPropagation();
  };

  handleBlur = () => {
    this.setState({ renaming: false });
  };

  handleConfirmationShow = e => {
    e.stopPropagation();

    this.setState({ confirmingDelete: true });
  };

  handleConfirmationHide = () => {
    this.setState({ confirmingDelete: false });
  };

  handleRemove = () => {
    this.props.onRemove(this.props.folder.id);
  };

  render() {
    const tasks = this.props.tasks.filter(t => t);
    const tasksCount = tasks.length;
    const completedTasksCount = tasks.filter(task => task.status === 'COMPLETED').length;
    const { viewerCanUpdate, viewerCanDelete } = this.props.folder;

    return (
      <Container>
        <Row onClick={this.handleToggleOpen}>
          <Arrow>
            <i className={`fa fa-fw fa-angle-${this.props.open ? 'down' : 'right'}`} />
          </Arrow>
          {this.state.renaming ? (
            <NameInput
              autoFocus
              defaultValue={this.props.folder.name}
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
              onClick={this.handleInputClick}
            />
          ) : (
            <Name onDoubleClick={this.handleRename}>
              <Title>{this.props.folder.name}</Title>
              {tasksCount === 0 ? (
                <TasksCount>0</TasksCount>
              ) : (
                <TasksCount completed={completedTasksCount === tasksCount}>
                  {completedTasksCount}/{tasksCount}
                </TasksCount>
              )}
            </Name>
          )}
          <Actions>
            {viewerCanUpdate && !this.state.renaming && (
              <Action onClick={this.handleRename}>
                <i className="fa fa-fw fa-pencil" />
              </Action>
            )}
            {viewerCanDelete && (
              <Action onClick={this.handleConfirmationShow}>
                <i className="fa fa-fw fa-trash" />
              </Action>
            )}
          </Actions>
        </Row>
        <List shown={this.props.open}>{this.props.children}</List>
        {this.state.confirmingDelete && (
          <ConfirmationWindow
            onHide={this.handleConfirmationHide}
            onConfirm={this.handleRemove}
            message="This will delete all of the Tasks in this Section."
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(TasksFolder, {
  folder: graphql`
    fragment TasksFolder_folder on Folder {
      id
      name
      viewerCanUpdate
      viewerCanDelete
    }
  `,
  tasks: graphql`
    fragment TasksFolder_tasks on Deliverable @relay(plural: true) {
      status
    }
  `,
});
