/**
 * @flow
 * @relayHash f1d102e508d7dcc41b22582d5cc85eb0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SaveRegistrationFormChangesInput = {
  clientMutationId?: ?string,
  formId: string,
};
export type saveRegistrationFormChangesMutationVariables = {|
  input: SaveRegistrationFormChangesInput
|};
export type saveRegistrationFormChangesMutationResponse = {|
  +saveRegistrationFormChanges: ?{|
    +event: {|
      +id: string,
      +registrationForm: ?{|
        +id: string,
        +publishedAt: ?any,
      |},
      +editableRegistrationForm: ?{|
        +id: string,
        +hasChanges: boolean,
      |},
    |}
  |}
|};
export type saveRegistrationFormChangesMutation = {|
  variables: saveRegistrationFormChangesMutationVariables,
  response: saveRegistrationFormChangesMutationResponse,
|};
*/


/*
mutation saveRegistrationFormChangesMutation(
  $input: SaveRegistrationFormChangesInput!
) {
  saveRegistrationFormChanges(input: $input) {
    event {
      id
      registrationForm {
        id
        publishedAt
      }
      editableRegistrationForm {
        id
        hasChanges
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SaveRegistrationFormChangesInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "saveRegistrationFormChanges",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SaveRegistrationFormChangesInput!"
      }
    ],
    "concreteType": "SaveRegistrationFormChangesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "registrationForm",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationForm",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "publishedAt",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "editableRegistrationForm",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationForm",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasChanges",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "saveRegistrationFormChangesMutation",
  "id": null,
  "text": "mutation saveRegistrationFormChangesMutation(\n  $input: SaveRegistrationFormChangesInput!\n) {\n  saveRegistrationFormChanges(input: $input) {\n    event {\n      id\n      registrationForm {\n        id\n        publishedAt\n      }\n      editableRegistrationForm {\n        id\n        hasChanges\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "saveRegistrationFormChangesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "saveRegistrationFormChangesMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76ae35a6ccfac63251c0a2da5b000176';
module.exports = node;
