/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import type { MemberEventCount_user } from './__generated__/MemberEventCount_user.graphql';

type Props = {
  user: MemberEventCount_user,
};

function MemberEventCount({ user }: Props) {
  return user.staffedEvents.totalCount;
}

export default createFragmentContainer(
  MemberEventCount,
  graphql`
    fragment MemberEventCount_user on User {
      staffedEvents {
        totalCount
      }
    }
  `,
);
