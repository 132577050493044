/* @flow */

import React from 'react';
import classNames from 'classnames';
import GMaps from 'gmaps';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import { locationAddressString } from 'utils/Location';

import type { Location } from 'utils/locations/locationTypes';

export type ControlSettings = {|
  zoomControl?: boolean,
  mapTypeControl?: boolean,
  scaleControl?: boolean,
  streetViewControl?: boolean,
  fullscreenControl?: boolean,
|};

export default class Map extends React.Component<{
  location: Location,
  className?: string,
  controlSettings?: ControlSettings,
}> {
  geocode = debounce(location => {
    GMaps.geocode({
      address: locationAddressString(location),
      callback: (results, status) => {
        if (status === 'OK') {
          const latlng = results[0].geometry.location;
          const map = this.map;
          if (map) {
            map.setCenter(latlng.lat(), latlng.lng());
            map.addMarker({
              lat: latlng.lat(),
              lng: latlng.lng(),
            });
          }
        }
      },
    });
  }, 500);

  componentDidMount() {
    this.handleMapCreate();
  }

  componentDidUpdate(prevProps: $PropertyType<Map, 'props'>) {
    const { location } = this.props;
    if (!isEqual(prevProps.location, location)) {
      if (prevProps.location) {
        this.geocode(location);
      } else {
        this.handleMapCreate();
      }
    }
  }

  handleMapCreate = () => {
    if (this.mapContainer) {
      this.map = new GMaps({
        el: this.mapContainer,
        lat: 0,
        lng: 0,
        ...this.props.controlSettings,
      });

      this.geocode(this.props.location);
    }
  };

  map: ?GMaps;

  mapContainer: ?HTMLElement;

  render() {
    const { location } = this.props;
    if (!location) return <div />;

    return (
      <div className={classNames('map-view', this.props.className)}>
        <div
          ref={el => {
            this.mapContainer = el;
          }}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    );
  }
}
