/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CustomizableCurrency_org$ref = any;
type OpportunityAmount_org$ref = any;
type OpportunitySalesforceId_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OpportunitiesList_org$ref: FragmentReference;
export type OpportunitiesList_org = {|
  +$fragmentRefs: OpportunityAmount_org$ref & CustomizableCurrency_org$ref & OpportunitySalesforceId_org$ref,
  +$refType: OpportunitiesList_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "OpportunitiesList_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeAmount",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableCurrency",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "OpportunitySalesforceId_org",
      "args": null
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeAmount",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OpportunityAmount_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeCustomizableCurrency",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "CustomizableCurrency_org",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '768397d057fb21d0e50e4ad0658d542b';
module.exports = node;
