/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactEventActionsColumn_contactEvent$ref = any;
type EventAttendanceDuration_contactEvent$ref = any;
type EventAttendanceJoinTime_contactEvent$ref = any;
type EventAttendanceLeaveTime_contactEvent$ref = any;
type EventAttendanceStatus_contactEvent$ref = any;
type EventDate_contactEvent$ref = any;
type EventLastSynced_contactEvent$ref = any;
type EventLocation_contactEvent$ref = any;
type EventName_contactEvent$ref = any;
type EventRegistrationStatus_contactEvent$ref = any;
type EventStatus_contactEvent$ref = any;
type EventSyncStatus_contactEvent$ref = any;
type EventVirtualLocation_contactEvent$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactEventsList_contactEvents$ref: FragmentReference;
export type ContactEventsList_contactEvents = $ReadOnlyArray<{|
  +node: {|
    +id: string
  |},
  +$fragmentRefs: EventDate_contactEvent$ref & EventLocation_contactEvent$ref & EventVirtualLocation_contactEvent$ref & EventName_contactEvent$ref & EventStatus_contactEvent$ref & EventRegistrationStatus_contactEvent$ref & EventAttendanceStatus_contactEvent$ref & EventAttendanceJoinTime_contactEvent$ref & EventAttendanceLeaveTime_contactEvent$ref & EventAttendanceDuration_contactEvent$ref & EventSyncStatus_contactEvent$ref & EventLastSynced_contactEvent$ref & ContactEventActionsColumn_contactEvent$ref,
  +$refType: ContactEventsList_contactEvents$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactEventsList_contactEvents",
  "type": "ContactEventEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EventDate_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventLocation_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventVirtualLocation_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventName_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventStatus_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRegistrationStatus_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventAttendanceStatus_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventAttendanceJoinTime_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventAttendanceLeaveTime_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventAttendanceDuration_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventSyncStatus_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventLastSynced_contactEvent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactEventActionsColumn_contactEvent",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8020fee36f33d32f7fa5df4e7dd7a905';
module.exports = node;
