/* @flow */
import React from 'react';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { ContactUpdater_contact } from './__generated__/ContactUpdater_contact.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

class ContactUpdater extends React.PureComponent<{
  contact: ContactUpdater_contact,
}> {
  render() {
    if (this.props.contact.updatedBy == null) {
      return null;
    }
    return (
      <Container>
        <MaterialAvatar radius={13} user={this.props.contact.updatedBy} />
        <TextWrapper>{fullNameOfUser(this.props.contact.updatedBy)}</TextWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(
  ContactUpdater,
  graphql`
    fragment ContactUpdater_contact on Contact {
      updatedBy {
        firstName
        lastName
        email
        ...MaterialAvatar_user
      }
    }
  `,
);
