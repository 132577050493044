/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ExpenseForm_org$ref = any;
type Payments_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpensePageWrapper_org$ref: FragmentReference;
export type ExpensePageWrapper_org = {|
  +$fragmentRefs: ExpenseForm_org$ref & Payments_org$ref,
  +$refType: ExpensePageWrapper_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExpensePageWrapper_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ExpenseForm_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Payments_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b6b62a44448c7c20d85cc980d4a9d78c';
module.exports = node;
