/**
 * @flow
 * @relayHash 630f9c85d59357f48bd5e8389dec785e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompanySearchRow_company$ref = any;
export type CompanySort = "CITY" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CUSTOM" | "DESCRIPTION" | "LINKEDIN" | "NAME" | "PHONE" | "SALESFORCE_ID" | "STATE" | "STREET" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "WEBSITE" | "ZIP";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type CompanyFilters = {
  query?: ?string,
  companyIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactsCount?: ?NumberRangeInput,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?CompanySort,
  direction?: ?Direction,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type CompanySearchQueryVariables = {|
  filters: CompanyFilters,
  includeEvent: boolean,
  eventId: string,
|};
export type CompanySearchQueryResponse = {|
  +org: {|
    +id: string,
    +viewerCanCreateContacts?: boolean,
    +companies: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +salesforceId: ?string,
          +$fragmentRefs: CompanySearchRow_company$ref,
        |}
      |}>
    |},
  |},
  +event?: ?{|
    +viewerCanCreateContacts?: boolean
  |},
|};
export type CompanySearchQuery = {|
  variables: CompanySearchQueryVariables,
  response: CompanySearchQueryResponse,
|};
*/


/*
query CompanySearchQuery(
  $filters: CompanyFilters!
  $includeEvent: Boolean!
  $eventId: ID!
) {
  org {
    id
    viewerCanCreateContacts @skip(if: $includeEvent)
    companies(first: 3, filters: $filters, includeSalesforceCompanies: true) {
      edges {
        node {
          id
          name
          salesforceId
          ...CompanySearchRow_company
        }
      }
    }
  }
  event: node(id: $eventId) @include(if: $includeEvent) {
    __typename
    ... on Event {
      viewerCanCreateContacts
    }
    id
  }
}

fragment CompanySearchRow_company on Company {
  name
  salesforceId
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "CompanyFilters!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeEvent",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters",
    "type": "CompanyFilters"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  {
    "kind": "Literal",
    "name": "includeSalesforceCompanies",
    "value": true,
    "type": "Boolean"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "salesforceId",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "viewerCanCreateContacts",
    "args": null,
    "storageKey": null
  }
],
v6 = {
  "kind": "Condition",
  "passingValue": false,
  "condition": "includeEvent",
  "selections": v5
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId",
    "type": "ID!"
  }
],
v8 = {
  "kind": "InlineFragment",
  "type": "Event",
  "selections": v5
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CompanySearchQuery",
  "id": null,
  "text": "query CompanySearchQuery(\n  $filters: CompanyFilters!\n  $includeEvent: Boolean!\n  $eventId: ID!\n) {\n  org {\n    id\n    viewerCanCreateContacts @skip(if: $includeEvent)\n    companies(first: 3, filters: $filters, includeSalesforceCompanies: true) {\n      edges {\n        node {\n          id\n          name\n          salesforceId\n          ...CompanySearchRow_company\n        }\n      }\n    }\n  }\n  event: node(id: $eventId) @include(if: $includeEvent) {\n    __typename\n    ... on Event {\n      viewerCanCreateContacts\n    }\n    id\n  }\n}\n\nfragment CompanySearchRow_company on Company {\n  name\n  salesforceId\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CompanySearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "companies",
            "storageKey": null,
            "args": v2,
            "concreteType": "CompanyRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      v1,
                      v3,
                      v4,
                      {
                        "kind": "FragmentSpread",
                        "name": "CompanySearchRow_company",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v6
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "event",
            "name": "node",
            "storageKey": null,
            "args": v7,
            "concreteType": null,
            "plural": false,
            "selections": [
              v8
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CompanySearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "companies",
            "storageKey": null,
            "args": v2,
            "concreteType": "CompanyRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      v1,
                      v3,
                      v4
                    ]
                  }
                ]
              }
            ]
          },
          v6
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "event",
            "name": "node",
            "storageKey": null,
            "args": v7,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              v1,
              v8
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4ef304a1e34ea8e9b82964c2c190dd9';
module.exports = node;
