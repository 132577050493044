/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import Button from 'components/budget/Button';
import InviteWindow from 'components/InviteWindow';
import ColumnFilter from 'components/material/ColumnFilters';
import ShareDropdown from 'components/ShareDropdown';
import UsersImportWindow from 'components/UsersImportWindow';

import { getColumnsShowConfig } from './columns';

import type { StaffHeader_event } from './__generated__/StaffHeader_event.graphql';
import type { StaffHeader_org } from './__generated__/StaffHeader_org.graphql';

const Container = styled.div`
  display: flex;
  margin-bottom: 13px;
  align-items: center;
  justify-content: space-between;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px 15px;
  }
`;

const HeaderRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

class StaffHeader extends React.PureComponent<
  {
    org: StaffHeader_org,
    event: StaffHeader_event,
    staffFields: $ReadOnlyArray<FieldType>,
    shownColumns: $ReadOnlyArray<string>,
    onColumnsChange: (shownColumns: $ReadOnlyArray<string>) => void,
  },
  { showInviteWindow: boolean, showImportWindow: boolean },
> {
  state = {
    showInviteWindow: false,
    showImportWindow: false,
  };

  handleShowInviteWindow = () => {
    this.setState({ showInviteWindow: true });
  };

  handleHideInviteWindow = () => {
    this.setState({ showInviteWindow: false });
  };

  handleShowImportWindow = () => {
    this.handleHideInviteWindow();
    this.setState({ showImportWindow: true });
  };

  handleHideImportWindow = () => {
    this.setState({ showImportWindow: false });
  };

  render() {
    const { org, event, staffFields, shownColumns, onColumnsChange } = this.props;
    const { showInviteWindow, showImportWindow } = this.state;
    const staffColumns = getColumnsShowConfig(staffFields);

    const options = [
      org.viewerCanManageUsers
        ? {
            label: 'Import Members (CSV)',
            icon: 'cloud-upload',
            onClick: this.handleShowImportWindow,
          }
        : null,
    ].filter(Boolean);

    return (
      <Container>
        {event.viewerCanAddStaff && (
          <Button primary onClick={this.handleShowInviteWindow}>
            Add Event Staff
          </Button>
        )}
        {showInviteWindow && (
          <InviteWindow
            onHide={this.handleHideInviteWindow}
            eventId={event.id}
            onClickImport={this.handleShowImportWindow}
            fromWindow="event staff"
          />
        )}
        <HeaderRight>
          <ColumnFilter
            filters={staffColumns}
            shownFilters={shownColumns}
            onFilterChange={onColumnsChange}
          />
          {options.length > 0 && <ShareDropdown noBorder options={options} />}
        </HeaderRight>
        {showImportWindow && (
          <UsersImportWindow onClose={this.handleHideImportWindow} event={event} />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(StaffHeader, {
  org: graphql`
    fragment StaffHeader_org on Org {
      viewerCanManageUsers
    }
  `,
  event: graphql`
    fragment StaffHeader_event on Event {
      id
      name
      startDate
      endDate
      tz
      primaryLocation {
        name
        city
        state
        country
      }
      viewerCanAddStaff
    }
  `,
});
