/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateVideoComponent, {
  type updateVideoComponentPropertyType,
} from 'graph/mutations/registration/updateVideoComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultBackgroundColor } from '../../registrationFormDefaults';
import PropertyRow from '../components/PropertyRow';
import RegistrationColorSelector from '../components/RegistrationColorSelector';
import { Section } from '.';
import {
  AlignmentToggle,
  Autoplay,
  BlockingLayer,
  PaddingComponent,
  VideoLink,
} from './components';
import type { AlignmentType } from './components/AlignmentToggle';
import SizeField from './components/SizeField';

import VideoComponent_org from './__generated__/ImageComponent_org.graphql';
import VideoComponent_selectedPageComponent from './__generated__/VideoComponent_selectedPageComponent.graphql';

const StyledSizeField = styled(SizeField)`
  width: 75px;
`;

const Container = styled.div`
  position: relative;
`;

class VideoComponent extends React.PureComponent<{
  org: VideoComponent_org,
  selectedPageComponent: VideoComponent_selectedPageComponent,
  onChangeComponentProperty: (updatedProps: SelectedComponent) => void,
}> {
  updateVideoComponent = (properties: updateVideoComponentPropertyType) => {
    updateVideoComponent({
      ...properties,
      id: this.props.selectedPageComponent.videoComponent.id,
    }).catch(showModernMutationError);
  };

  handleSavePadding = (padding: string) => {
    this.updateVideoComponent({ padding });
  };

  handleSaveHeight = (height: number) => {
    this.updateVideoComponent({ height });
  };

  handleSaveWidth = (width: number) => {
    this.updateVideoComponent({ width });
  };

  handleChangeBackgroundColor = (backgroundColor: string): void => {
    this.updateVideoComponent({ backgroundColor });
  };

  handleChangeAlignment = (alignment: AlignmentType) => {
    this.updateVideoComponent({ alignment });
  };

  handleChangeAutoplay = (autoplay: boolean) => {
    this.updateVideoComponent({ autoplay });
  };

  handleChangeLink = (url: string) => {
    this.updateVideoComponent({ url });
  };

  handleChangeHeight = (height: number) => {
    this.props.onChangeComponentProperty({ selectedComponentHeight: height });
  };

  handleChangeWidth = (width: number) => {
    this.props.onChangeComponentProperty({ selectedComponentWidth: width });
  };

  render() {
    const {
      org,
      selectedPageComponent: { id, videoComponent },
      onChangeComponentProperty,
    } = this.props;
    return (
      <Container>
        {id === 'new' && <BlockingLayer />}
        <Section>
          <PropertyRow label="Video link">
            <VideoLink value={videoComponent.url} onChange={this.handleChangeLink} />
          </PropertyRow>
        </Section>
        <Section>
          <PropertyRow label="Alignment">
            <AlignmentToggle
              selectedAlignment={videoComponent.alignment}
              onChange={this.handleChangeAlignment}
            />
          </PropertyRow>
          <PropertyRow label="Padding">
            <PaddingComponent
              paddingString={videoComponent.padding}
              onSave={this.handleSavePadding}
              onChange={onChangeComponentProperty}
            />
          </PropertyRow>
          <PropertyRow label="Background color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={videoComponent.backgroundColor || defaultBackgroundColor}
              onChangeColor={this.handleChangeBackgroundColor}
              alignTo="bottom-right"
              includeTransparent
            />
          </PropertyRow>
        </Section>
        <Section>
          <PropertyRow label="Autoplay">
            <Autoplay value={videoComponent.autoplay} onChange={this.handleChangeAutoplay} />
          </PropertyRow>
        </Section>
        {videoComponent.url && (
          <Section>
            <PropertyRow label="Height">
              <StyledSizeField
                value={videoComponent.height}
                onSave={this.handleSaveHeight}
                onChange={this.handleChangeHeight}
                onZeroValue={14}
                maxLength={4}
              />
            </PropertyRow>
            <PropertyRow label="Width">
              <StyledSizeField
                value={videoComponent.width}
                onSave={this.handleSaveWidth}
                onChange={this.handleChangeWidth}
                onZeroValue={14}
                maxLength={4}
              />
            </PropertyRow>
          </Section>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(VideoComponent, {
  org: graphql`
    fragment VideoComponent_org on Org {
      id
      settings {
        ...RegistrationColorSelector_orgSettings
      }
    }
  `,
  selectedPageComponent: graphql`
    fragment VideoComponent_selectedPageComponent on RegistrationPageComponent {
      id
      kind
      videoComponent {
        id
        url
        autoplay
        padding
        height
        width
        alignment
        backgroundColor
      }
    }
  `,
});
