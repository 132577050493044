/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import omit from 'lodash/omit';
import throttle from 'lodash/throttle';

import { type CreateCompanyFromWindow } from 'graph/mutations/company/createCompany';
import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';

import ContactWindow from 'components/Contacts/ContactWindow';
import { type ContactType } from 'components/Contacts/ContactWindow/ContactTypeSelector';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import AutocompleteInput from 'components/material/AutocompleteInput';

import CompanySearchRow from './CompanySearchRow';
import NewParticipantRow from './NewParticipantRow';

import type { CompanySearchQueryResponse } from './__generated__/CompanySearchQuery.graphql';

type ResponseOrg = $PropertyType<CompanySearchQueryResponse, 'org'>;
type ResponseCompaniesEdges = $PropertyType<$PropertyType<ResponseOrg, 'companies'>, 'edges'>;
type ResponseCompany = $Diff<
  $PropertyType<$ElementType<ResponseCompaniesEdges, 0>, 'node'>,
  { +$fragmentRefs: any },
>;

const query = graphql`
  query CompanySearchQuery($filters: CompanyFilters!, $includeEvent: Boolean!, $eventId: ID!) {
    org {
      id
      viewerCanCreateContacts @skip(if: $includeEvent)
      companies(first: 3, filters: $filters, includeSalesforceCompanies: true) {
        edges {
          node {
            id
            name
            salesforceId
            ...CompanySearchRow_company
          }
        }
      }
    }
    event: node(id: $eventId) @include(if: $includeEvent) {
      ... on Event {
        viewerCanCreateContacts
      }
    }
  }
`;

export const StyledAutocompleteInput = styled(AutocompleteInput)`
  label {
    i {
      color: ${props => props.theme.primaryActionColor};
    }
  }
`;

const StyledLink = styled.div`
  position: absolute;
  right: ${props => (props.icon ? '42px' : '21px')};
  top: 18px;
  display: none;
  cursor: pointer;
  font-size: 12px;
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  &:hover {
    ${StyledLink} {
      display: block;
    }
    label {
      input {
        padding-right: ${props => (props.icon ? '125px' : '20px')};
      }
    }
  }
`;

export default class CompanySearch extends React.PureComponent<
  {
    eventId?: ?string,
    onSelect: (company: ?ResponseCompany, contactType: ContactType) => void,
    stateful?: boolean,
    company?: ?{ +id: string, +name: string },
    label?: string,
    placeholder?: string,
    clearable?: boolean,
    autoFocus?: boolean,
    required?: boolean,
    error?: ?string,
    disabled?: boolean,
    onToggleQueryState?: (present: boolean) => void,
    onShowCreateCompany?: () => void,
    onHideCreateCompany?: () => void,
    onBlur?: () => void,
    onCompanyLinkClick?: () => void,
    overlayContainer?: ?HTMLElement,
    resetOnBlur?: boolean,
    salesforceId?: ?string,
    renderOptionToString?: (contact: ResponseCompany) => string,
    fromWindow: CreateContactFromWindow | CreateCompanyFromWindow,
    className?: string,
  },
  {
    query: string,
    addingCompany: boolean,
  },
> {
  state = {
    query:
      this.props.stateful && this.props.company && this.props.company.name != null
        ? this.props.company.name
        : '',
    addingCompany: false,
  };

  cachedCompanies: ?$ReadOnlyArray<ResponseCompany>;

  orgId: string;

  queryExists: boolean;

  handleFilter = throttle((str: string) => {
    this.setState({ query: str });
    this.checkSearchQueryState(str);
  }, 800);

  handleWindowShow = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    this.setState({ addingCompany: true });
    if (this.props.onShowCreateCompany) {
      this.props.onShowCreateCompany();
    }
  };

  handleWindowClose = () => {
    this.setState({ addingCompany: false });
    if (this.props.onHideCreateCompany) {
      this.props.onHideCreateCompany();
    }
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleSave = (contactType: ContactType, company: ResponseCompany) => {
    this.props.onSelect(
      {
        id: company.id,
        name: company.name,
        salesforceId: company.salesforceId,
      },
      contactType,
    );
  };

  handleBlur = (autocompleteQuery: string) => {
    if (
      !this.props.resetOnBlur &&
      this.props.stateful &&
      !autocompleteQuery &&
      this.props.company != null &&
      this.props.company.name
    ) {
      this.props.onSelect(null, 'companies');
    }
    if (this.props.onBlur && !this.state.addingCompany) {
      this.props.onBlur();
    }
  };

  handleSelect = (company: ?ResponseCompany) => {
    this.props.onSelect(
      company && {
        id: company.id,
        name: company.name,
        salesforceId: company.salesforceId,
      },
      'companies',
    );
    if (!company) {
      this.checkSearchQueryState();
    }
  };

  checkSearchQueryState = (str?: string = '') => {
    if (str.trim() && !this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(true);
      this.queryExists = true;
    }
    if (!str.trim() && this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(false);
      this.queryExists = false;
    }
  };

  renderOption = (company: ResponseCompany) => <CompanySearchRow company={company} />;

  renderOptionToString = (company: ResponseCompany) => {
    if (this.props.renderOptionToString) {
      return this.props.renderOptionToString(company);
    }

    if (this.props.stateful) {
      return company.name;
    }
    return '';
  };

  handleCompanyLinkClick = () => {
    if (this.props.onCompanyLinkClick) {
      this.props.onCompanyLinkClick();
    }
  };

  renderCreate = () => <NewParticipantRow label="Add Company" onClick={this.handleWindowShow} />;

  renderInput = (response?: CompanySearchQueryResponse) => {
    if (response && response.org) {
      this.cachedCompanies = response.org.companies.edges.map(edge =>
        omit(edge.node, '$fragmentRefs'),
      );
      this.orgId = response.org.id;
    }
    const canCreateCompany =
      response &&
      ((response.event && response.event.viewerCanCreateContacts) ||
        (response.org && response.org.viewerCanCreateContacts));
    const companyId = this.props.company != null && this.props.company.id;

    return (
      <Container
        icon={!!this.props.salesforceId && !this.props.disabled}
        className={this.props.className}
      >
        <StyledAutocompleteInput
          defaultQuery={this.props.company && this.props.company.name}
          placeholder={this.props.placeholder || 'Search Companies'}
          label={this.props.label}
          options={this.cachedCompanies || []}
          onFilterOptions={this.handleFilter}
          onSelect={this.handleSelect}
          onBlur={this.handleBlur}
          renderOption={this.renderOption}
          renderOptionString={this.renderOptionToString}
          clearable={this.props.clearable}
          autoFocus={this.props.autoFocus}
          required={this.props.required}
          error={this.props.error}
          disabled={this.props.disabled}
          footerContent={canCreateCompany ? this.renderCreate() : null}
          overlayContainer={this.props.overlayContainer}
          resetOnBlur={this.props.resetOnBlur}
          icon={!!this.props.salesforceId && !this.props.disabled ? 'refresh' : null}
        />
        {this.props.onCompanyLinkClick && companyId && (
          <StyledLink
            icon={!!this.props.salesforceId && !this.props.disabled}
            onClick={this.handleCompanyLinkClick}
          >
            View Company
          </StyledLink>
        )}
        {this.state.addingCompany &&
          document.body &&
          createPortal(
            <ContactWindow
              defaultActiveType="companies"
              eventId={this.props.eventId}
              onHide={this.handleWindowClose}
              onSave={this.handleSave}
              fromWindow={this.props.fromWindow}
            />,
            document.body,
          )}
      </Container>
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          eventId: this.props.eventId || '',
          filters: { query: this.state.query },
          includeEvent: !!this.props.eventId,
        }}
        renderSuccess={this.renderInput}
        renderLoading={this.renderInput}
      />
    );
  }
}
