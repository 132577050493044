/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import reviewEventRequestSubmission from 'graph/mutations/eventRequest/reviewEventRequestSubmission';
import showModernMutationError from 'graph/utils/showModernMutationError';

import submissionStatuses, {
  type SubmissionStatus,
} from 'components/EventRequestForm/lib/submissionStatuses';
import OrgLeadSearch, { type UserType } from 'components/Lead/OrgLeadSearch';
import Button from 'components/material/Button';
import { type TemplateType } from 'components/TemplateSearch';

import SubmissionReviewChecklistTemplate from './SubmissionReviewChecklistTemplate';
import SubmissionReviewComments from './SubmissionReviewComments';
import SubmissionReviewStatus from './SubmissionReviewStatus';
import SubmissionReviewTeam from './SubmissionReviewTeam';

import type { SubmissionReviewSidebar_me } from './__generated__/SubmissionReviewSidebar_me.graphql';
import type { SubmissionReviewSidebar_org } from './__generated__/SubmissionReviewSidebar_org.graphql';
import type { SubmissionReviewSidebar_submission } from './__generated__/SubmissionReviewSidebar_submission.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentsContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 350px;
  padding: 8px 8px 0;
  border-radius: 0 4px 4px 0;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

const SectionHeader = styled.div`
  padding: 9px 20px;
  background: #f8f9f9;
  font-size: 14px;
  font-weight: 500;
  color: #3e4859;
  ${props =>
    props.required &&
    css`
      &:before {
        content: '*';
        right: 100%;
        margin-right: 4px;
        color: #3e4859;
      }
    `};
`;

const Status = styled.div`
  width: 140px;
  height: 35px;
  padding: 0 20px;
  margin: 15px;
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.color};
  border: 1px solid;
  border-radius: 4px;
  text-align: center;
  line-height: 35px;
  vertical-align: middle;
`;

const Info = styled.div`
  margin: 15px;
  color: rgba(74, 86, 101, 0.54);
  font-size: 11px;
  line-height: 15px;
`;

const Footer = styled.div`
  background: #ffffff;
  padding: 12px 12px 24px;
  z-index: 1;
  text-align: right;
  button {
    padding: 4px 14px;
    margin-left: 8px;
  }
`;

const StyledOrgLeadSearch = styled(OrgLeadSearch)`
  width: initial;
  margin: 0 16px 15px 16px;
`;

class SubmissionReviewSidebar extends React.PureComponent<
  {
    submission: SubmissionReviewSidebar_submission,
    org: SubmissionReviewSidebar_org,
    me: SubmissionReviewSidebar_me,
    onCancel: () => void,
  },
  {
    loading: boolean,
    status: SubmissionStatus,
    declineReasonId: ?string,
    reviewerNotes: string,
    teamId: ?string,
    lead: ?UserType,
    eventTemplate: ?TemplateType,
    copyTimeline: boolean,
  },
> {
  state = {
    loading: false,
    status: this.props.submission.status,
    declineReasonId: this.props.submission.declineReason && this.props.submission.declineReason.id,
    reviewerNotes: '',
    lead: { ...this.props.submission.event.lead },
    teamId:
      this.props.submission.event.team.id === this.props.org.requestsTeam.id
        ? null
        : this.props.submission.event.team.id,
    eventTemplate: this.props.submission.form.eventTemplate,
    copyTimeline:
      this.props.submission.form.copyTimeline && this.props.submission.event.startDate != null,
  };

  handleChangeStatus = (status: SubmissionStatus) => {
    this.setState({ status, reviewerNotes: '' });
  };

  handleChangeDeclineReasonId = (declineReasonId: string) => {
    this.setState({ declineReasonId });
  };

  handleChangeReviewerNotes = (reviewerNotes: string) => {
    this.setState({ reviewerNotes });
  };

  handleChangeTeamId = (teamId: string) => {
    this.setState({ teamId });
  };

  handleChangeLead = (lead: ?UserType) => {
    this.setState({ lead });
  };

  handleChangeEventTemplate = (eventTemplate: ?TemplateType) => {
    this.setState({ eventTemplate, copyTimeline: false });
  };

  handleToggleCopyTimeline = () => {
    this.setState(prevState => ({ copyTimeline: !prevState.copyTimeline }));
  };

  handleSubmit = () => {
    const {
      status,
      lead,
      teamId,
      reviewerNotes,
      declineReasonId,
      eventTemplate,
      copyTimeline,
    } = this.state;
    this.setState({ loading: true });

    reviewEventRequestSubmission({
      requestSubmissionId: this.props.submission.id,
      status,
      reviewerNotes,
      leadId: lead && lead.id,
      teamId,
      declineReasonId,
      eventTemplateId: eventTemplate && eventTemplate.id,
      copyTimeline,
    })
      .catch(showModernMutationError)
      .then(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { submission, org, me, onCancel } = this.props;
    const {
      status: selectedStatus,
      declineReasonId,
      teamId,
      reviewerNotes,
      lead,
      eventTemplate,
      copyTimeline,
      loading,
    } = this.state;
    const submitEnabled =
      submission.status !== selectedStatus &&
      (selectedStatus !== 'DECLINED' || declineReasonId) &&
      (selectedStatus !== 'APPROVED' || teamId);
    const status = submissionStatuses.find(o => o.value === selectedStatus);

    return (
      <Container>
        <CommentsContainer>
          <Content>
            {submission.viewerCanUpdate ? (
              <>
                <SectionHeader>Update Status</SectionHeader>
                <SubmissionReviewStatus
                  submission={submission}
                  status={selectedStatus}
                  declineReasonId={declineReasonId}
                  reviewerNotes={reviewerNotes}
                  onChangeStatus={this.handleChangeStatus}
                  onChangeDeclineReasonId={this.handleChangeDeclineReasonId}
                  onChangeReviewerNotes={this.handleChangeReviewerNotes}
                />

                {selectedStatus === 'APPROVED' && (
                  <>
                    <SectionHeader required>Team</SectionHeader>
                    <SubmissionReviewTeam
                      onChangeTeamId={this.handleChangeTeamId}
                      teamId={teamId}
                      org={org}
                      me={me}
                      disabled={submission.status === selectedStatus}
                    />
                    {submission.status !== 'APPROVED' && (
                      <>
                        <SectionHeader>Leader</SectionHeader>
                        <StyledOrgLeadSearch
                          defaultValue={lead}
                          onSelect={this.handleChangeLead}
                          clearable
                          skipLabel
                        />
                        <SectionHeader>Checklist Template</SectionHeader>
                        <SubmissionReviewChecklistTemplate
                          eventTemplate={eventTemplate}
                          copyTimeline={copyTimeline}
                          eventHasStartDate={submission.event.startDate != null}
                          onChangeTemplate={this.handleChangeEventTemplate}
                          onToggleCopyTimeline={this.handleToggleCopyTimeline}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              status && (
                <>
                  <SectionHeader>Request Status</SectionHeader>
                  <Status color={status.color}>{status.label}</Status>
                  <Info>
                    You do not have permission to change status. <br />
                    Only admins and reviewers of request can change the status
                  </Info>
                </>
              )
            )}
            <SectionHeader>Internal Comments</SectionHeader>
            <SubmissionReviewComments
              submission={submission}
              org={org}
              tz={me.tz}
              currentUserId={me.id}
            />
          </Content>
        </CommentsContainer>
        <Footer>
          {!loading && <Button label="Cancel" minimal onClick={onCancel} />}
          <Button
            label="Submit"
            primary
            onClick={this.handleSubmit}
            disabled={!submitEnabled}
            loading={loading}
          />
        </Footer>
      </Container>
    );
  }
}

export default createFragmentContainer(
  SubmissionReviewSidebar,
  graphql`
    fragment SubmissionReviewSidebar_submission on EventRequestSubmission {
      id
      status
      viewerCanUpdate
      declineReason {
        id
      }
      event {
        startDate
        team {
          id
        }
        lead {
          id
          firstName
          lastName
          email
          avatar
        }
      }
      form {
        eventTemplate {
          id
          name
          startDate
          team {
            name
            id
          }
        }
        copyTimeline
      }
      ...SubmissionReviewStatus_submission
      ...SubmissionReviewComments_submission
    }

    fragment SubmissionReviewSidebar_org on Org {
      ...SubmissionReviewTeam_org
      ...SubmissionReviewComments_org
      requestsTeam {
        id
      }
    }

    fragment SubmissionReviewSidebar_me on User {
      id
      tz
      ...SubmissionReviewTeam_me
    }
  `,
);
