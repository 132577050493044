/* @flow */

import type { Field } from './types';

const isUserType = (customField: ?Field): boolean => {
  if (!customField) {
    return false;
  }

  if (['USER_SELECT', 'USER_MULTISELECT'].includes(customField.kind)) {
    return true;
  }

  if (customField.fieldName === 'owner_id') {
    return true;
  }

  return false;
};

export default isUserType;
