/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactActionCell_contact$ref = any;
type ContactActionCell_event$ref = any;
type ContactAttendanceDuration_eventContact$ref = any;
type ContactAttendanceJoinTime_event$ref = any;
type ContactAttendanceJoinTime_eventContact$ref = any;
type ContactAttendanceLeaveTime_event$ref = any;
type ContactAttendanceLeaveTime_eventContact$ref = any;
type ContactAttendanceStatus_contact$ref = any;
type ContactAttendanceStatus_event$ref = any;
type ContactAttendanceStatus_eventContact$ref = any;
type ContactCity_contact$ref = any;
type ContactCompany_contact$ref = any;
type ContactCompany_event$ref = any;
type ContactCountry_contact$ref = any;
type ContactCreatedAt_contact$ref = any;
type ContactCreatedMethod_contact$ref = any;
type ContactCreator_contact$ref = any;
type ContactDescription_contact$ref = any;
type ContactEmailOptIn_contact$ref = any;
type ContactEmail_contact$ref = any;
type ContactEventsCount_contact$ref = any;
type ContactHotLead_contact$ref = any;
type ContactLastSynced_contact$ref = any;
type ContactLinkedin_contact$ref = any;
type ContactMarketoId_contact$ref = any;
type ContactMembershipLastSynced_eventContact$ref = any;
type ContactMembershipSyncStatus_event$ref = any;
type ContactMembershipSyncStatus_eventContact$ref = any;
type ContactName_contact$ref = any;
type ContactName_event$ref = any;
type ContactOwner_contact$ref = any;
type ContactPersonType_contact$ref = any;
type ContactPhone_contact$ref = any;
type ContactRegistrationDate_event$ref = any;
type ContactRegistrationDate_eventContact$ref = any;
type ContactRegistrationStatus_contact$ref = any;
type ContactRegistrationStatus_event$ref = any;
type ContactRegistrationStatus_eventContact$ref = any;
type ContactRequestSubmissionsCount_contact$ref = any;
type ContactSalesforceId_contact$ref = any;
type ContactSalesforceLeadStatus_contact$ref = any;
type ContactSalesforceSyncAs_contact$ref = any;
type ContactState_contact$ref = any;
type ContactStreet_contact$ref = any;
type ContactSyncStatus_contact$ref = any;
type ContactTitle_contact$ref = any;
type ContactTwitter_contact$ref = any;
type ContactUpdatedAt_contact$ref = any;
type ContactUpdatedMethod_contact$ref = any;
type ContactUpdater_contact$ref = any;
type ContactWebsite_contact$ref = any;
type ContactZip_contact$ref = any;
type CustomizableBoolean_customizable$ref = any;
type CustomizableCurrency_customizable$ref = any;
type CustomizableDate_customizable$ref = any;
type CustomizableLink_customizable$ref = any;
type CustomizableMultiselect_customizable$ref = any;
type CustomizableNumber_customizable$ref = any;
type CustomizableSelect_customizable$ref = any;
type CustomizableText_customizable$ref = any;
type CustomizableTextarea_customizable$ref = any;
type CustomizableUserMultiselect_customizable$ref = any;
type CustomizableUserSelect_customizable$ref = any;
export type ContactSyncOption = "contact" | "lead";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactsTablePagination_event$ref: FragmentReference;
export type ContactsTablePagination_event = {|
  +id: string,
  +slug: string,
  +eventContacts: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +salesforceSyncAs: ?ContactSyncOption,
        +$fragmentRefs: ContactName_contact$ref & ContactTitle_contact$ref & ContactPhone_contact$ref & ContactPersonType_contact$ref & ContactSalesforceSyncAs_contact$ref & ContactSalesforceLeadStatus_contact$ref & ContactCompany_contact$ref & ContactEmail_contact$ref & ContactWebsite_contact$ref & ContactTwitter_contact$ref & ContactLinkedin_contact$ref & ContactOwner_contact$ref & ContactUpdater_contact$ref & ContactUpdatedAt_contact$ref & ContactUpdatedMethod_contact$ref & ContactCreator_contact$ref & ContactCreatedAt_contact$ref & ContactCreatedMethod_contact$ref & ContactSalesforceId_contact$ref & ContactMarketoId_contact$ref & ContactLastSynced_contact$ref & ContactSyncStatus_contact$ref & ContactStreet_contact$ref & ContactCity_contact$ref & ContactState_contact$ref & ContactZip_contact$ref & ContactCountry_contact$ref & ContactDescription_contact$ref & ContactEmailOptIn_contact$ref & ContactHotLead_contact$ref & ContactRequestSubmissionsCount_contact$ref & ContactEventsCount_contact$ref & ContactRegistrationStatus_contact$ref & ContactAttendanceStatus_contact$ref & CustomizableText_customizable$ref & CustomizableTextarea_customizable$ref & CustomizableLink_customizable$ref & CustomizableDate_customizable$ref & CustomizableBoolean_customizable$ref & CustomizableNumber_customizable$ref & CustomizableCurrency_customizable$ref & CustomizableSelect_customizable$ref & CustomizableMultiselect_customizable$ref & CustomizableUserSelect_customizable$ref & CustomizableUserMultiselect_customizable$ref & ContactActionCell_contact$ref,
      |},
      +$fragmentRefs: ContactRegistrationStatus_eventContact$ref & ContactAttendanceStatus_eventContact$ref & ContactAttendanceJoinTime_eventContact$ref & ContactRegistrationDate_eventContact$ref & ContactAttendanceLeaveTime_eventContact$ref & ContactAttendanceDuration_eventContact$ref & ContactMembershipSyncStatus_eventContact$ref & ContactMembershipLastSynced_eventContact$ref,
    |}>
  |},
  +$fragmentRefs: ContactName_event$ref & ContactActionCell_event$ref & ContactRegistrationStatus_event$ref & ContactAttendanceStatus_event$ref & ContactCompany_event$ref & ContactRegistrationDate_event$ref & ContactMembershipSyncStatus_event$ref & ContactAttendanceJoinTime_event$ref & ContactAttendanceLeaveTime_event$ref,
  +$refType: ContactsTablePagination_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "FragmentSpread",
    "name": "ContactPhone_contact",
    "args": null
  }
];
return {
  "kind": "Fragment",
  "name": "ContactsTablePagination_event",
  "type": "Event",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "eventContacts"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeMembershipSyncStatus",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeAttendanceJoinTime",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeAttendanceLeaveTime",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "count",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "cursor",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "filters",
      "type": "ContactFilters"
    },
    {
      "kind": "RootArgument",
      "name": "includeRegistrationStatusId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeAttendanceStatusId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeRegisteredAt",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeAttendanceDuration",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeMembershipLastSynced",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeName",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTitle",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includePhone1",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includePhone2",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeContactTypeId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeSalesforceSyncAs",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeSalesforceLeadStatusId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCompanyId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEmail",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeWebsite",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTwitter",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeLinkedin",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeOwnerId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeUpdatedBy",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeUpdatedAt",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeUpdatedMethod",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCreatedBy",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCreatedAt",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCreatedMethod",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeSalesforceId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeMarketoId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeLastSynced",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeSyncStatus",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStreet",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCity",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeState",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeZip",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCountry",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeDescription",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEmailOptIn",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeHotLead",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeRequestSubmissions",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEvents",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableText",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableTextarea",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableLink",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableDate",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableBoolean",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableNumber",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableCurrency",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableMultiselect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserMultiselect",
      "type": "Boolean"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactName_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactActionCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactRegistrationStatus_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactAttendanceStatus_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactCompany_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactRegistrationDate_event",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": "eventContacts",
      "name": "__ContactsTablePagination_eventContacts_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "EventContactConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "EventContactEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Contact",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "salesforceSyncAs",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ContactActionCell_contact",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeName",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactName_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeTitle",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactTitle_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includePhone1",
                  "selections": v1
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includePhone2",
                  "selections": v1
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeContactTypeId",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactPersonType_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeSalesforceSyncAs",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactSalesforceSyncAs_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeSalesforceLeadStatusId",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactSalesforceLeadStatus_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCompanyId",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactCompany_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeEmail",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactEmail_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeWebsite",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactWebsite_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeTwitter",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactTwitter_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeLinkedin",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactLinkedin_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeOwnerId",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactOwner_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeUpdatedBy",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactUpdater_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeUpdatedAt",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactUpdatedAt_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeUpdatedMethod",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactUpdatedMethod_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCreatedBy",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactCreator_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCreatedAt",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactCreatedAt_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCreatedMethod",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactCreatedMethod_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeSalesforceId",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactSalesforceId_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeMarketoId",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactMarketoId_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeLastSynced",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactLastSynced_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeSyncStatus",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactSyncStatus_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeStreet",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactStreet_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCity",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactCity_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeState",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactState_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeZip",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactZip_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCountry",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactCountry_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeDescription",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactDescription_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeEmailOptIn",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactEmailOptIn_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeHotLead",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactHotLead_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeRequestSubmissions",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactRequestSubmissionsCount_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeEvents",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactEventsCount_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeRegistrationStatusId",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactRegistrationStatus_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeAttendanceStatusId",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ContactAttendanceStatus_contact",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableText",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableText_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableTextarea",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableTextarea_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableLink",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableLink_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableDate",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableDate_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableBoolean",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableBoolean_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableNumber",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableNumber_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableCurrency",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableCurrency_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableSelect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableSelect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableMultiselect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableMultiselect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableUserSelect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableUserSelect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableUserMultiselect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableUserMultiselect_customizable",
                      "args": null
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "includeRegistrationStatusId",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ContactRegistrationStatus_eventContact",
                  "args": null
                }
              ]
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "includeAttendanceStatusId",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ContactAttendanceStatus_eventContact",
                  "args": null
                }
              ]
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "includeAttendanceJoinTime",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ContactAttendanceJoinTime_eventContact",
                  "args": null
                }
              ]
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "includeRegisteredAt",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ContactRegistrationDate_eventContact",
                  "args": null
                }
              ]
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "includeAttendanceLeaveTime",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ContactAttendanceLeaveTime_eventContact",
                  "args": null
                }
              ]
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "includeAttendanceDuration",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ContactAttendanceDuration_eventContact",
                  "args": null
                }
              ]
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "includeMembershipSyncStatus",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ContactMembershipSyncStatus_eventContact",
                  "args": null
                }
              ]
            },
            {
              "kind": "Condition",
              "passingValue": true,
              "condition": "includeMembershipLastSynced",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ContactMembershipLastSynced_eventContact",
                  "args": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeMembershipSyncStatus",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ContactMembershipSyncStatus_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeAttendanceJoinTime",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ContactAttendanceJoinTime_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeAttendanceLeaveTime",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ContactAttendanceLeaveTime_event",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6339a7306dbb318d31a0a23ea50e8198';
module.exports = node;
