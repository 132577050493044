/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import formatCost from 'utils/number/formatCost';

import type { SalesforceCampaignData_campaign } from './__generated__/SalesforceCampaignData_campaign.graphql';
import type { SalesforceCampaignData_org } from './__generated__/SalesforceCampaignData_org.graphql';

const SalesforceCampaignInfoRow = styled.div`
  display: flex;
  width: 590px;
  margin-top: 10px;
`;

const SalesforceCampaignInfoBox = styled.div`
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  border-radius: 5px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  border: solid 1px #dadada;
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const CampaignTitleBox = styled.div`
  margin: 0 40px;
  text-align: center;
`;

const InfoTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #3e4859;
`;

const InfoText = styled.div`
  margin-top: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #3e4859;
`;

const OpportunitiesDataSection = styled.div`
  margin: 10px 0;
`;

const TitleSection = styled.div`
  margin: 37px 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: #3e4859;
`;

const DataSection = styled.div`
  display: flex;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 180px;
  padding: 20px 15px;
  border-radius: 5px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  border: solid 1px #dadada;
  background-color: #fbf9ff;
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const SectionBoxStat = styled.div`
  color: #3e4859;
  text-align: center;
`;

const SectionBoxTitle = styled.div`
  font-size: 13px;
`;

const SectionBoxValue = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

class SalesforceCampaignData extends React.PureComponent<{
  campaign: SalesforceCampaignData_campaign,
  org: SalesforceCampaignData_org,
}> {
  render() {
    const { campaign, org } = this.props;
    const currency = org.settings.currency;

    if (!campaign) return null;
    return (
      <>
        <SalesforceCampaignInfoRow>
          <SalesforceCampaignInfoBox>
            <CampaignTitleBox>
              <InfoTitle>Contacts</InfoTitle>
              <InfoText>{campaign.numberOfContacts}</InfoText>
            </CampaignTitleBox>
            <CampaignTitleBox>
              <InfoTitle>Leads</InfoTitle>
              <InfoText>{campaign.numberOfLeads}</InfoText>
            </CampaignTitleBox>
            <CampaignTitleBox>
              <InfoTitle>Converted Leads</InfoTitle>
              <InfoText>{campaign.numberOfConvertedLeads}</InfoText>
            </CampaignTitleBox>
          </SalesforceCampaignInfoBox>
        </SalesforceCampaignInfoRow>
        <SalesforceCampaignInfoRow>
          <SalesforceCampaignInfoBox>
            <CampaignTitleBox>
              <InfoTitle>Budgeted Cost</InfoTitle>
              <InfoText>
                {campaign.budgetedCost != null
                  ? `${formatCost(campaign.budgetedCost, currency)}`
                  : 'N/A'}
              </InfoText>
            </CampaignTitleBox>
          </SalesforceCampaignInfoBox>
          <SalesforceCampaignInfoBox>
            <CampaignTitleBox>
              <InfoTitle>Actual Cost</InfoTitle>
              <InfoText>
                {campaign.actualCost != null
                  ? `${formatCost(campaign.actualCost, currency)}`
                  : 'N/A'}
              </InfoText>
            </CampaignTitleBox>
          </SalesforceCampaignInfoBox>
        </SalesforceCampaignInfoRow>
        <OpportunitiesDataSection>
          <TitleSection>Opportunities</TitleSection>
          <DataSection>
            <SectionBox>
              <SectionBoxStat>
                <SectionBoxValue>{campaign.numberOfOpportunities}</SectionBoxValue>
                <SectionBoxTitle>Opportunities</SectionBoxTitle>
              </SectionBoxStat>
              <SectionBoxStat>
                <SectionBoxValue>
                  {formatCost(campaign.amountAllOpportunities, currency)}
                </SectionBoxValue>
                <SectionBoxTitle>Amount</SectionBoxTitle>
              </SectionBoxStat>
            </SectionBox>
            <SectionBox>
              <SectionBoxStat>
                <SectionBoxValue>{campaign.numberOfWonOpportunities}</SectionBoxValue>
                <SectionBoxTitle>Won Opportunities</SectionBoxTitle>
              </SectionBoxStat>
              <SectionBoxStat>
                <SectionBoxValue>
                  {formatCost(campaign.amountWonOpportunities, currency)}
                </SectionBoxValue>
                <SectionBoxTitle>Amount</SectionBoxTitle>
              </SectionBoxStat>
            </SectionBox>
            <SectionBox>
              <SectionBoxStat>
                <SectionBoxValue>
                  {campaign.totalNumberOfOpportunities === null
                    ? 'N/A'
                    : campaign.totalNumberOfOpportunities}
                </SectionBoxValue>
                <SectionBoxTitle>Opportunities in Hierarchy</SectionBoxTitle>
              </SectionBoxStat>
              <SectionBoxStat>
                <SectionBoxValue>
                  {campaign.totalAmountAllOpportunities === null
                    ? 'N/A'
                    : formatCost(campaign.totalAmountAllOpportunities, currency)}
                </SectionBoxValue>
                <SectionBoxTitle>Amount</SectionBoxTitle>
              </SectionBoxStat>
            </SectionBox>
            <SectionBox>
              <SectionBoxStat>
                <SectionBoxValue>
                  {campaign.totalNumberOfWonOpportunities === null
                    ? 'N/A'
                    : campaign.totalNumberOfWonOpportunities}
                </SectionBoxValue>
                <SectionBoxTitle>Won Opportunities in Hierarchy</SectionBoxTitle>
              </SectionBoxStat>
              <SectionBoxStat>
                <SectionBoxValue>
                  {campaign.totalAmountAllWonOpportunities === null
                    ? 'N/A'
                    : formatCost(campaign.totalAmountAllWonOpportunities, currency)}
                </SectionBoxValue>
                <SectionBoxTitle>Amount</SectionBoxTitle>
              </SectionBoxStat>
            </SectionBox>
          </DataSection>
        </OpportunitiesDataSection>
      </>
    );
  }
}

export default createFragmentContainer(SalesforceCampaignData, {
  org: graphql`
    fragment SalesforceCampaignData_org on Org {
      salesforceAccount {
        opportunitiesSyncEnabled
      }
      settings {
        currency
      }
    }
  `,
  campaign: graphql`
    fragment SalesforceCampaignData_campaign on SalesforceCampaign {
      budgetedCost
      actualCost
      numberOfContacts
      numberOfLeads
      numberOfConvertedLeads
      numberOfOpportunities
      numberOfWonOpportunities
      amountAllOpportunities
      amountWonOpportunities
      totalAmountAllOpportunities
      totalAmountAllWonOpportunities
      totalNumberOfOpportunities
      totalNumberOfWonOpportunities
    }
  `,
});
