/* @flow */
import {
  type ColumnConfiguration,
  BaseColumn,
  BaseColumnAllowOverflow,
} from 'components/material/table';

import EventRequestsItemDeclineReason from './EventRequestsItemDeclineReason';
import EventRequestsItemEventName from './EventRequestsItemEventName';
import EventRequestsItemFormName from './EventRequestsItemFormName';
import EventRequestsItemFormReviewers from './EventRequestsItemFormReviewers';
import EventRequestsItemRequestedBy from './EventRequestsItemRequestedBy';
import EventRequestsItemRequestedDate from './EventRequestsItemRequestedDate';
import EventRequestsItemStatus from './EventRequestsItemStatus';

const columns: ColumnConfiguration = [
  {
    label: 'Request Status',
    key: 'STATUS',
    contentComponent: EventRequestsItemStatus,
    component: BaseColumnAllowOverflow,
    value: 'status',
    grow: 0.5,
    width: 120,
  },
  {
    label: 'Event',
    key: 'EVENT_NAME',
    contentComponent: EventRequestsItemEventName,
    component: BaseColumn,
    value: 'event_name',
    width: 100,
    grow: 1.5,
  },
  {
    label: 'Submitted At',
    key: 'REQUESTED_DATE',
    contentComponent: EventRequestsItemRequestedDate,
    component: BaseColumn,
    value: 'requested_at',
    width: 140,
    grow: 1,
  },
  {
    label: 'Submitted By',
    key: 'REQUESTED_BY',
    contentComponent: EventRequestsItemRequestedBy,
    component: BaseColumn,
    value: 'requested_by',
    width: 120,
    grow: 1,
  },
  {
    label: 'Request Form',
    key: 'NAME',
    contentComponent: EventRequestsItemFormName,
    component: BaseColumn,
    value: 'name',
    width: 100,
    grow: 1.5,
  },
  {
    label: 'Reviewers',
    contentComponent: EventRequestsItemFormReviewers,
    component: BaseColumn,
    value: 'reviewers',
    width: 100,
    grow: 0.5,
  },
  {
    label: 'Declined Reason',
    key: 'DECLINE_REASON',
    contentComponent: EventRequestsItemDeclineReason,
    component: BaseColumn,
    value: 'decline_reason',
    width: 130,
    grow: 1,
  },
];

export default columns;
