/* @flow */
import { setCookie } from 'utils/Cookies';

const disableAnalytics = () => {
  setCookie('disableAnalytics', true);
  if (window.FS) {
    window.FS.shutdown();
  }
};
export default disableAnalytics;
