/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import FeatureAccessContext from 'contexts/FeatureAccess';

import SidebarButton from './TaskSidebarButton';

import type { TaskSidebar_task } from './__generated__/TaskSidebar_task.graphql';

const SidebarToggler = styled.div`
  background: ${props => props.theme.primaryActionColor};
  color: ${props => props.theme.primaryActionTextColor};
  border-radius: 3px 0 0 3px;
  position: absolute;
  right: 100%;
  top: 15px;
  cursor: pointer;
  font-size: 18px;
  padding: 0 6px;
  line-height: 1.3;
  &:hover {
    background: ${props => props.theme.primaryActionDarkerColor};
  }
  @media (${props => props.theme.mobileOnly}) {
    display: none;
  }
`;

const Sidebar = styled.div`
  position: relative;
  flex-shrink: 0;
  width: ${props => (props.shown ? '170px' : '0')};
  padding-bottom: 10px;
  background: ${props => props.theme.primaryRowColor};
  ${props =>
    props.shown
      ? css`
          border-left: 1px solid ${props.theme.borderColor};
        `
      : css`
          > *:not(${SidebarToggler}) {
            visibility: hidden;
          }
        `};
  @media (${props => props.theme.mobileOnly}) {
    width: 100%;
    padding: 5px 0;
    border-left: 0;
    border-top: 1px solid ${props => props.theme.borderColor};
  }
`;

class TaskSidebar extends React.PureComponent<
  {
    task: TaskSidebar_task,
    sectionsShown: Array<string>,
    onSectionAdd: string => void,
  },
  {
    sidebarShown: boolean,
  },
> {
  state = {
    sidebarShown: true,
  };

  handleToggleSidebarShown = () => this.setState(state => ({ sidebarShown: !state.sidebarShown }));

  static contextType = FeatureAccessContext;

  render() {
    const dateShown = this.props.task.dueDate || this.props.sectionsShown.includes('date');
    const reminderShown = this.props.task.remindAt || this.props.sectionsShown.includes('reminder');
    const assigneesShown =
      this.props.task.assignees.edges.length > 0 || this.props.sectionsShown.includes('assignees');
    const tagsShown =
      this.props.task.tags.edges.length > 0 || this.props.sectionsShown.includes('tags');
    const contactShown =
      this.props.task.contacts.edges.length > 0 ||
      this.props.task.companies.edges.length > 0 ||
      this.props.sectionsShown.includes('contact');
    const vendorShown =
      this.props.task.vendors.edges.length > 0 || this.props.sectionsShown.includes('vendor');
    const attachmentsShown =
      this.props.task.attachments.edges.length > 0 ||
      this.props.sectionsShown.includes('attachments');
    const expensesShown =
      this.props.task.event.viewerCanViewBudget &&
      (this.props.task.expenses.edges.length > 0 || this.props.sectionsShown.includes('expenses'));
    const subtasksShown =
      this.props.task.subtasks.edges.length > 0 || this.props.sectionsShown.includes('subtasks');
    const dependenciesShown =
      this.props.task.dependencies.edges.length > 0 ||
      this.props.task.dependents.edges.length > 0 ||
      this.props.sectionsShown.includes('dependencies');

    return (
      <Sidebar shown={this.state.sidebarShown}>
        <SidebarToggler onClick={this.handleToggleSidebarShown}>
          <i className={`fa fa-angle-${this.state.sidebarShown ? 'right' : 'left'}`} />
        </SidebarToggler>

        <SidebarButton
          label="Due Date"
          active={dateShown}
          onClick={() => this.props.onSectionAdd('date')}
          disabled={!this.props.task.viewerCanUpdateDueDate}
        />
        <SidebarButton
          label="Reminder to start"
          active={reminderShown}
          onClick={() => this.props.onSectionAdd('reminder')}
          disabled={!this.props.task.viewerCanUpdateDetails}
        />
        <SidebarButton
          label="Assignees"
          active={assigneesShown}
          onClick={() => this.props.onSectionAdd('assignees')}
          disabled={!this.props.task.viewerCanAssign}
        />
        <SidebarButton
          label="Tags"
          active={tagsShown}
          onClick={() => this.props.onSectionAdd('tags')}
          disabled={!this.props.task.viewerCanUpdateTags}
        />
        <SidebarButton
          label="Subtasks"
          active={subtasksShown}
          onClick={() => this.props.onSectionAdd('subtasks')}
          disabled={!this.props.task.viewerCanUpdateSubtasks}
        />
        <SidebarButton
          label="Dependencies"
          active={dependenciesShown}
          onClick={() => this.props.onSectionAdd('dependencies')}
          disabled={!this.props.task.viewerCanUpdateDependencies}
        />
        {this.context.legacyFeatures && (
          <SidebarButton
            label="Contact"
            active={contactShown}
            onClick={() => this.props.onSectionAdd('contact')}
            disabled={!this.props.task.viewerCanUpdateContact}
          />
        )}
        <SidebarButton
          label="Vendor"
          active={vendorShown}
          onClick={() => this.props.onSectionAdd('vendor')}
          disabled={!this.props.task.viewerCanUpdateContact}
        />
        <SidebarButton
          label="Expenses"
          active={expensesShown}
          onClick={() => this.props.onSectionAdd('expenses')}
          disabled={!this.props.task.event.viewerCanViewBudget}
        />
        <SidebarButton
          label="Attachments"
          active={attachmentsShown}
          onClick={() => this.props.onSectionAdd('attachments')}
          disabled={!this.props.task.viewerCanUpdateAttachment}
        />
      </Sidebar>
    );
  }
}

export default createFragmentContainer(
  TaskSidebar,
  graphql`
    fragment TaskSidebar_task on Deliverable {
      dueDate
      remindAt
      viewerCanUpdateDetails
      viewerCanUpdateDueDate
      viewerCanUpdateTags
      viewerCanAssign
      viewerCanUpdateAttachment
      viewerCanUpdateContact
      viewerCanUpdateSubtasks
      viewerCanUpdateDependencies
      event {
        viewerCanViewBudget
      }
      contacts {
        edges {
          node {
            id
          }
        }
      }
      companies {
        edges {
          node {
            id
          }
        }
      }
      vendors {
        edges {
          node {
            id
          }
        }
      }
      assignees {
        edges {
          node {
            id
          }
        }
      }
      tags {
        edges {
          node {
            id
          }
        }
      }
      attachments {
        edges {
          node {
            id
          }
        }
      }
      subtasks {
        edges {
          node {
            id
          }
        }
      }
      dependencies {
        edges {
          node {
            id
          }
        }
      }
      dependents {
        edges {
          node {
            id
          }
        }
      }
      expenses {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
);
