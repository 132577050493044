/**
 * @flow
 * @relayHash 84fb8e29d9c988c419d2d4cb6ae21b28
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamMembersTablePagination_team$ref = any;
export type Direction = "ASC" | "DESC";
export type TeamMembershipSort = "ACCOUNT_STATUS" | "EMAIL" | "FULL_NAME" | "LAST_ACTIVITY" | "TEAM_DATE_ADDED" | "TEAM_NAME";
export type TeamMembersTablePaginationQueryVariables = {|
  teamSlug: string,
  count: number,
  cursor?: ?string,
  sort: TeamMembershipSort,
  direction: Direction,
|};
export type TeamMembersTablePaginationQueryResponse = {|
  +team: {|
    +$fragmentRefs: TeamMembersTablePagination_team$ref
  |}
|};
export type TeamMembersTablePaginationQuery = {|
  variables: TeamMembersTablePaginationQueryVariables,
  response: TeamMembersTablePaginationQueryResponse,
|};
*/


/*
query TeamMembersTablePaginationQuery(
  $teamSlug: String!
  $count: Int!
  $cursor: String
  $sort: TeamMembershipSort!
  $direction: Direction!
) {
  team(slug: $teamSlug) {
    ...TeamMembersTablePagination_team
    id
  }
}

fragment TeamMembersTablePagination_team on Team {
  id
  slug
  ...TeamMembersTable_team
  members(first: $count, after: $cursor, sort: $sort, direction: $direction) {
    edges {
      node {
        id
        __typename
      }
      cursor
    }
    ...TeamMembersTable_members
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment TeamMembersTable_team on Team {
  ...TeamMembersTableRow_team
}

fragment TeamMembersTable_members on TeamMemberConnection {
  edges {
    ...TeamMembersTableRow_member
    node {
      id
    }
  }
}

fragment TeamMembersTableRow_member on TeamMemberEdge {
  node {
    id
  }
  ...TeamMemberFullName_member
  ...TeamMemberEmail_member
  ...TeamMembersActionsColumn_member
  ...TeamMemberLastActivity_member
  ...TeamMemberInviteStatus_member
}

fragment TeamMemberFullName_member on TeamMemberEdge {
  node {
    id
    firstName
    lastName
    email
    admin
    ...MaterialAvatar_user
  }
}

fragment TeamMemberEmail_member on TeamMemberEdge {
  node {
    email
    id
  }
}

fragment TeamMembersActionsColumn_member on TeamMemberEdge {
  viewerCanRemove
  node {
    id
    inviteStatus
  }
}

fragment TeamMemberLastActivity_member on TeamMemberEdge {
  node {
    lastSeenAt
    id
  }
}

fragment TeamMemberInviteStatus_member on TeamMemberEdge {
  node {
    inviteStatus
    id
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}

fragment TeamMembersTableRow_team on Team {
  slug
  ...TeamMembersActionsColumn_team
}

fragment TeamMembersActionsColumn_team on Team {
  id
  dbId
  slug
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "TeamMembershipSort!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction",
    "type": "Direction"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "TeamMembershipSort"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamMembersTablePaginationQuery",
  "id": null,
  "text": "query TeamMembersTablePaginationQuery(\n  $teamSlug: String!\n  $count: Int!\n  $cursor: String\n  $sort: TeamMembershipSort!\n  $direction: Direction!\n) {\n  team(slug: $teamSlug) {\n    ...TeamMembersTablePagination_team\n    id\n  }\n}\n\nfragment TeamMembersTablePagination_team on Team {\n  id\n  slug\n  ...TeamMembersTable_team\n  members(first: $count, after: $cursor, sort: $sort, direction: $direction) {\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    ...TeamMembersTable_members\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment TeamMembersTable_team on Team {\n  ...TeamMembersTableRow_team\n}\n\nfragment TeamMembersTable_members on TeamMemberConnection {\n  edges {\n    ...TeamMembersTableRow_member\n    node {\n      id\n    }\n  }\n}\n\nfragment TeamMembersTableRow_member on TeamMemberEdge {\n  node {\n    id\n  }\n  ...TeamMemberFullName_member\n  ...TeamMemberEmail_member\n  ...TeamMembersActionsColumn_member\n  ...TeamMemberLastActivity_member\n  ...TeamMemberInviteStatus_member\n}\n\nfragment TeamMemberFullName_member on TeamMemberEdge {\n  node {\n    id\n    firstName\n    lastName\n    email\n    admin\n    ...MaterialAvatar_user\n  }\n}\n\nfragment TeamMemberEmail_member on TeamMemberEdge {\n  node {\n    email\n    id\n  }\n}\n\nfragment TeamMembersActionsColumn_member on TeamMemberEdge {\n  viewerCanRemove\n  node {\n    id\n    inviteStatus\n  }\n}\n\nfragment TeamMemberLastActivity_member on TeamMemberEdge {\n  node {\n    lastSeenAt\n    id\n  }\n}\n\nfragment TeamMemberInviteStatus_member on TeamMemberEdge {\n  node {\n    inviteStatus\n    id\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n\nfragment TeamMembersTableRow_team on Team {\n  slug\n  ...TeamMembersActionsColumn_team\n}\n\nfragment TeamMembersActionsColumn_team on Team {\n  id\n  dbId\n  slug\n  name\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamMembersTablePaginationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TeamMembersTablePagination_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamMembersTablePaginationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dbId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "members",
            "storageKey": null,
            "args": v3,
            "concreteType": "TeamMemberConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamMemberEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "admin",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "inviteStatus",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastSeenAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanRemove",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "members",
            "args": v3,
            "handle": "connection",
            "key": "TeamMembersTablePagination_members",
            "filters": []
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6f4c4a946d0dfb23d6cee605b0af668';
module.exports = node;
