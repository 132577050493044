/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactsHeaderBar_org$ref = any;
type ContactsTablePagination_org$ref = any;
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactsPage_org$ref: FragmentReference;
export type ContactsPage_org = {|
  +id: string,
  +customFields: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +label: string,
        +fieldName: ?string,
        +order: number,
        +kind: CustomFieldKindType,
        +required: boolean,
        +customizableType: CustomizableType,
        +restrictChangingValueForLead: boolean,
        +restrictChangingValueForContact: boolean,
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +name: string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$fragmentRefs: ContactsHeaderBar_org$ref & ContactsTablePagination_org$ref,
  +$refType: ContactsPage_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "pull",
  "value": true,
  "type": "Boolean"
};
return {
  "kind": "Fragment",
  "name": "ContactsPage_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "FragmentSpread",
      "name": "ContactsHeaderBar_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactsTablePagination_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFields",
      "storageKey": "customFields(customizableType:[\"CONTACT\",\"EVENTCONTACT\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": [
            "CONTACT",
            "EVENTCONTACT"
          ],
          "type": "[CustomizableType!]!"
        }
      ],
      "concreteType": "CustomFieldTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldType",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "label",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fieldName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "required",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "customizableType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": "restrictChangingValueForLead",
                  "name": "mappedToSalesforce",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "kind",
                      "value": "LEAD",
                      "type": "SalesforceKindType"
                    },
                    v1
                  ],
                  "storageKey": "mappedToSalesforce(kind:\"LEAD\",pull:true)"
                },
                {
                  "kind": "ScalarField",
                  "alias": "restrictChangingValueForContact",
                  "name": "mappedToSalesforce",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "kind",
                      "value": "CONTACT",
                      "type": "SalesforceKindType"
                    },
                    v1
                  ],
                  "storageKey": "mappedToSalesforce(kind:\"CONTACT\",pull:true)"
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "options",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldOptionTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomFieldOptionTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomFieldOptionType",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "name",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6b25e60ee44e2cc672f26b679c6d91ba';
module.exports = node;
