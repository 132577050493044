/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect, Switch } from 'react-router-dom';

import AuthorizableRoute from 'utils/routing/AuthorizableRoute';

import BudgetReporting from './BudgetReporting';
import ExpensesImports from './ExpensesImports';

import { type ReportingRoutes_org } from './__generated__/ReportingRoutes_org.graphql';

type Props = { org: ReportingRoutes_org, pathPrefix: string };

const ReportingRoutes = ({ org, pathPrefix }: Props) => {
  return (
    <Switch>
      <AuthorizableRoute
        authorized={org.viewerCanViewReporting}
        path={`${pathPrefix}/budget`}
        component={BudgetReporting}
      />
      <AuthorizableRoute
        authorized={org.viewerCanImport}
        path={`${pathPrefix}/expense_imports`}
        component={ExpensesImports}
      />
      <Redirect from={pathPrefix} to={`${pathPrefix}/budget`} />
    </Switch>
  );
};

export default createFragmentContainer(
  ReportingRoutes,
  graphql`
    fragment ReportingRoutes_org on Org {
      viewerCanViewReporting
      viewerCanImport
    }
  `,
);
