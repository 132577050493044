/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type {
  createDeclineReasonMutationVariables,
  createDeclineReasonMutationResponse,
} from './__generated__/createDeclineReasonMutation.graphql';

const mutation = graphql`
  mutation createDeclineReasonMutation($input: CreateDeclineReasonInput!) {
    createDeclineReason(input: $input) {
      declineReasonEdge {
        node {
          id
          name
        }
      }
    }
  }
`;

export default function createDeclineReason(
  orgId: string,
  input: $PropertyType<createDeclineReasonMutationVariables, 'input'>,
): Promise<string> {
  const variables: createDeclineReasonMutationVariables = {
    input,
  };

  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: orgId,
      edgeName: 'declineReasonEdge',
      connections: [{ field: 'declineReasons' }],
      rangeBehavior: 'append',
    }),
  }).then((response: createDeclineReasonMutationResponse) => {
    if (!response.createDeclineReason) throw Error('Incomplete Mutation Response');

    return response.createDeclineReason.declineReasonEdge.node.id;
  });
}
