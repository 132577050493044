/* @flow */
import { getCookie } from 'utils/Cookies';

export default function getProcessingStatus(
  uuid: string,
  key: string,
  authorizeBy: string,
): Promise<{ status: string, fileUrl: ?string }> {
  return fetch(`/v1/exportable_generators/${uuid}?key=${key}`, {
    credentials: 'same-origin',
    headers: {
      Authorization: getCookie(authorizeBy),
    },
  }).then(response => {
    if (response.status >= 400) {
      return response.json().then(json => Promise.reject(new Error(json.errors)));
    }
    return response.json().then(json => json);
  });
}
