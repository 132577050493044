/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import syncStatuses from 'config/syncStatuses.json';

import eventContactSyncDisabledInfo from 'components/Contacts/lib/eventContactSyncDisabledInfo';
import ErrorIcon from 'components/ErrorIcon';

import type { ContactMembershipSyncStatus_event } from './__generated__/ContactMembershipSyncStatus_event.graphql';
import type { ContactMembershipSyncStatus_eventContact } from './__generated__/ContactMembershipSyncStatus_eventContact.graphql';

const ContactMembershipSyncStatus = ({
  eventContact,
  event,
  salesforceHost,
  marketoHost,
}: {
  eventContact: ContactMembershipSyncStatus_eventContact,
  event: ContactMembershipSyncStatus_event,
  salesforceHost: ?string,
  marketoHost: ?string,
}) => {
  const disabledInfo =
    eventContact.syncStatus.state === 'DISABLED'
      ? eventContactSyncDisabledInfo({
          salesforceSyncEnabled: !!salesforceHost,
          marketoSyncEnabled: !!marketoHost,
          event,
          contact: eventContact.node,
        })
      : null;

  return (
    <>
      {syncStatuses[eventContact.syncStatus.state]}

      {(disabledInfo || eventContact.syncStatus.errorMessage) && (
        <ErrorIcon message={disabledInfo || eventContact.syncStatus.errorMessage} />
      )}
    </>
  );
};

export default createFragmentContainer(ContactMembershipSyncStatus, {
  eventContact: graphql`
    fragment ContactMembershipSyncStatus_eventContact on EventContactEdge {
      syncStatus {
        state
        errorMessage
      }
      node {
        syncStatus {
          state
        }
      }
    }
  `,
  event: graphql`
    fragment ContactMembershipSyncStatus_event on Event {
      salesforceCampaign {
        id
      }
      marketoProgram {
        id
      }
    }
  `,
});
