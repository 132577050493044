/* @flow */

import * as React from 'react';
import styled, { css } from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';
import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';
import ConfirmationWindow from 'components/ConfirmationWindow';
import Tooltip from 'components/material/Tooltip';

import { type NoteItem_note } from './__generated__/NoteItem_note.graphql';

const Container = styled.div`
  flex: 0 1 auto;
  padding: 14px;
  border-left: 3px solid transparent;
  border-top: 1px solid #d8d8d8;
  cursor: pointer;
  transition: background-color 1s;
  &:first-child {
    border-top-color: transparent;
  }
  &:last-child {
    border-bottom: 1px solid #d8d8d8;
  }
  &:hover {
    background: #f7f7f7;
  }
  ${props =>
    props.active &&
    css`
      background: #f7f7f7;
      border-left-color: ${props.theme.primaryActionColor};
      border-top-color: transparent;
      & + div {
        border-top-color: transparent;
      }
      &:last-child {
        border-bottom-color: transparent;
      }
    `};
  ${props =>
    props.isNew &&
    css`
      background: #fffbf1;
    `};
`;

const Info = styled.div`
  display: flex;
  flex-flow: row wrap;
  div {
    flex: 0 1 auto;
    margin-right: 10px;
  }
`;

const Header = styled.div`
  display: flex;
`;

const Text = styled.div`
  flex: 1 auto;
  margin-bottom: 10px;
  color: ${props => props.theme.darkestTextColor};
  font-size: 14px;
  font-weight: 500;
  word-break: break-all;
  ${Info} > & {
    font-size: 13px;
  }
`;

const ActionButtons = styled.div`
  flex: 0 auto;
`;

const Icon = styled.i`
  color: rgba(116, 127, 141, 0.9);
`;

const DateInfo = styled.div`
  margin-left: ${props => (props.alignWithUser ? '36px' : '0')};
  color: ${props => props.theme.osloGray};
  font-size: 13px;
  @media (max-width: 460px) {
    margin-top: 5px;
  }
`;

class NoteItem extends React.Component<
  {
    note: NoteItem_note,
    timezone: string,
    handleEditing: (noteId: string) => void,
    active: boolean,
    newNoteId: string,
    onRemove: (noteId: string) => void,
  },
  {
    showRemoveModal: boolean,
  },
> {
  state = { showRemoveModal: false };

  handleEdit = () => {
    this.props.handleEditing(this.props.note.id);
  };

  handleShowRemoveModal = () => {
    this.setState({ showRemoveModal: true });
  };

  handleHideRemoveModal = () => {
    this.setState({ showRemoveModal: false });
  };

  handleDeleteNote = () => {
    this.props.onRemove(this.props.note.id);
  };

  render() {
    const {
      note: { id, name, updatedAt, viewerCanDelete, updater },
      newNoteId,
      timezone,
      active,
    } = this.props;
    const updatedDate = formatDateTime(moment(updatedAt).tz(timezone));
    return (
      <Container onClick={this.handleEdit} active={active} isNew={id === newNoteId}>
        <Header>
          <Text>{name}</Text>
          {viewerCanDelete && (
            <ActionButtons>
              <Tooltip label="Delete Note" placement="top">
                <Icon className="fa fa-fw fa-trash" onClick={this.handleShowRemoveModal} />
              </Tooltip>
            </ActionButtons>
          )}
        </Header>
        <Info>
          {updater && (
            <React.Fragment>
              <MaterialAvatar user={updater} radius={13} />
              <Text>{fullNameOfUser(updater)}</Text>
            </React.Fragment>
          )}
          <DateInfo alignWithUser={!!updater}>{updatedDate}</DateInfo>
        </Info>
        {this.state.showRemoveModal && (
          <ConfirmationWindow
            onHide={this.handleHideRemoveModal}
            onConfirm={this.handleDeleteNote}
            title="Are you sure?"
            message="Once you delete a Note, it's gone for good."
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(NoteItem, {
  note: graphql`
    fragment NoteItem_note on EventNote {
      id
      name
      updatedAt
      viewerCanDelete
      updater {
        firstName
        lastName
        ...MaterialAvatar_user
      }
    }
  `,
});
