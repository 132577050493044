/**
 * @flow
 * @relayHash 16ded76688f3f078700bdfb17ac1d867
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateBriefTemplateEventInfoFieldInput = {
  clientMutationId?: ?string,
  eventInfoFieldId: string,
  enabled: boolean,
};
export type updateBriefTemplateEventInfoFieldMutationVariables = {|
  input: UpdateBriefTemplateEventInfoFieldInput
|};
export type updateBriefTemplateEventInfoFieldMutationResponse = {|
  +updateBriefTemplateEventInfoField: ?{|
    +eventInfoField: {|
      +id: string,
      +enabled: boolean,
    |}
  |}
|};
export type updateBriefTemplateEventInfoFieldMutation = {|
  variables: updateBriefTemplateEventInfoFieldMutationVariables,
  response: updateBriefTemplateEventInfoFieldMutationResponse,
|};
*/


/*
mutation updateBriefTemplateEventInfoFieldMutation(
  $input: UpdateBriefTemplateEventInfoFieldInput!
) {
  updateBriefTemplateEventInfoField(input: $input) {
    eventInfoField {
      id
      enabled
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateBriefTemplateEventInfoFieldInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateBriefTemplateEventInfoField",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateBriefTemplateEventInfoFieldInput!"
      }
    ],
    "concreteType": "UpdateBriefTemplateEventInfoFieldPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventInfoField",
        "storageKey": null,
        "args": null,
        "concreteType": "BriefTemplateEventInfoField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "enabled",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateBriefTemplateEventInfoFieldMutation",
  "id": null,
  "text": "mutation updateBriefTemplateEventInfoFieldMutation(\n  $input: UpdateBriefTemplateEventInfoFieldInput!\n) {\n  updateBriefTemplateEventInfoField(input: $input) {\n    eventInfoField {\n      id\n      enabled\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateBriefTemplateEventInfoFieldMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateBriefTemplateEventInfoFieldMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b89a97c0bc28a7311830c868441f84bf';
module.exports = node;
