/**
 * @flow
 * @relayHash 54ac75897b0e24569560b7df697e7953
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type WorkspaceRoutes_org$ref = any;
export type WorkspaceQueryVariables = {||};
export type WorkspaceQueryResponse = {|
  +org: {|
    +id: string,
    +$fragmentRefs: WorkspaceRoutes_org$ref,
  |}
|};
export type WorkspaceQuery = {|
  variables: WorkspaceQueryVariables,
  response: WorkspaceQueryResponse,
|};
*/


/*
query WorkspaceQuery {
  org {
    id
    ...WorkspaceRoutes_org
  }
}

fragment WorkspaceRoutes_org on Org {
  viewerCanImport
  viewerCanViewOrgMembers
  viewerCanViewTeams
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "WorkspaceQuery",
  "id": null,
  "text": "query WorkspaceQuery {\n  org {\n    id\n    ...WorkspaceRoutes_org\n  }\n}\n\nfragment WorkspaceRoutes_org on Org {\n  viewerCanImport\n  viewerCanViewOrgMembers\n  viewerCanViewTeams\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WorkspaceQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "FragmentSpread",
            "name": "WorkspaceRoutes_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WorkspaceQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanImport",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanViewOrgMembers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanViewTeams",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c5bf86b267a1ed1720355ea398a3951';
module.exports = node;
