/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const SiteHeader = styled.header`
  padding: 0 28px;
  height: 72px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media only screen and (max-width: 800px) {
    height: auto;
    align-items: flex-start;
    padding: 10px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
`;

export default class PageHeader extends React.Component<{
  className?: string,
  children?: React.Node,
  onToggleSidebar: () => void,
}> {
  handleHamburgerClick = () => {
    this.props.onToggleSidebar();
  };

  render() {
    return (
      <SiteHeader className={this.props.className}>
        <div className="hamburger" onClick={this.handleHamburgerClick}>
          <div className="hamburger-clicker" />
          <i className="fa fa-fw fa-bars" />
        </div>
        <HeaderContent>{this.props.children}</HeaderContent>
      </SiteHeader>
    );
  }
}
