/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import sanitizeHtml from 'utils/string/sanitizeHtml';

import updateTextComponent from 'graph/mutations/registration/updateTextComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import TextIcon from 'images/registration/text.svg';
import TinyRichText, { StyledContent } from 'components/TinyRichText';

import UIContext from '../../../UIContext';
import type { SelectedComponent } from '../../RegistrationCreateContent';
import generatePaddingProps from '../generatePaddingProps';

import { type TextComponent_componentProps } from './__generated__/TextComponent_componentProps.graphql';

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  background-color: #fff;
`;

const AddTextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
`;

const StyledTextIcon = styled(TextIcon)`
  margin-right: 15px;
  rect {
    stroke: #3ba9da;
  }
  path {
    fill: #3ba9da;
  }
`;

const Text = styled.div`
  color: #3ba9da;
`;

const StyledTinyRichText = styled(TinyRichText)`
  margin-top: 10px;
`;

class TextComponent extends React.PureComponent<
  {
    componentProps: TextComponent_componentProps,
    active: boolean,
    readOnly?: boolean,
    selectedComponent: SelectedComponent,
    defaultFont: string,
  },
  {
    value: string,
  },
> {
  state = {
    value:
      (this.props.componentProps.textComponent && this.props.componentProps.textComponent.value) ||
      '',
  };

  componentDidMount() {
    const {
      defaultFont,
      readOnly,
      componentProps: { textComponent },
    } = this.props;
    const currentDefaultFont = this.getCurrentDefaultFont();
    if (
      !readOnly &&
      textComponent &&
      this.state.value &&
      (!currentDefaultFont || currentDefaultFont.toLowerCase() !== defaultFont.toLowerCase())
    ) {
      updateTextComponent({
        value: this.state.value,
        id: textComponent.id,
        defaultFont,
      })
        .then(value => {
          this.setState({
            value,
          });
        })
        .catch(showModernMutationError);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      active,
      defaultFont,
      componentProps: { textComponent },
    } = this.props;

    if (!textComponent || textComponent.value === this.state.value || active === prevProps.active) {
      return;
    }
    updateTextComponent({
      value: this.state.value,
      id: textComponent.id,
      defaultFont,
    }).catch(showModernMutationError);
  }

  getCurrentDefaultFont = () => {
    const value = this.state.value;
    if (!value) {
      return null;
    }
    const fontStart = value.substring(value.indexOf("'") + 1);
    return fontStart.substring(0, fontStart.indexOf("'"));
  };

  handleChange = value => this.setState({ value: value.replace('\\"', '"') });

  isBlankText = (): boolean => {
    const stripedText = this.state.value.replace(/<p>&nbsp;<\/p>/g, '');
    return !stripedText.trim();
  };

  renderViewContent = (): React.Node => {
    const {
      readOnly,
      defaultFont,
      componentProps: { id, textComponent },
    } = this.props;

    const value = this.state.value;
    const finalValue =
      value.substring(0, 4) === '<div'
        ? value
        : `<div style="font-family: '${defaultFont || 'Roboto'}'; font-size: 14px">${
            value || ''
          }</div>`;
    const content = (
      <StyledContent
        className="component-styling"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(finalValue),
        }}
        style={
          textComponent
            ? {
                padding: generatePaddingProps(textComponent.padding),
                backgroundColor: textComponent.backgroundColor || 'none',
              }
            : {}
        }
      />
    );
    if (readOnly) {
      return content;
    }

    if (this.isBlankText()) {
      return (
        <EmptyContainer id={id}>
          <AddTextButton>
            <StyledTextIcon />
            <Text>Add Text</Text>
          </AddTextButton>
        </EmptyContainer>
      );
    }
    return content;
  };

  render() {
    const {
      componentProps: { textComponent },
      active,
      selectedComponent,
      defaultFont,
    } = this.props;
    const value = this.state.value;

    const viewContent = this.renderViewContent();
    return active ? (
      <UIContext.Consumer>
        {({ loaderColor }) => (
          <StyledTinyRichText
            required
            loaderColor={loaderColor}
            value={value}
            initOptions={{
              custom_ui_selector: 'body',
              auto_focus: true,
              toolbar:
                'formatselect | fontselect fontsizeselect | forecolor link | bold italic underline |  bullist numlist | alignleft aligncenter alignright',
              content_style:
                textComponent &&
                `@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans');
                .mce-content-body {
                      margin: ${generatePaddingProps(
                        (selectedComponent && selectedComponent.selectedComponentPadding) ||
                          textComponent.padding,
                      )};
                      background-color: ${textComponent.backgroundColor || 'none'};
                      font-size: 14px;
                      font-family: ${defaultFont};
                    }`,
              min_height: 125,
            }}
            onChange={this.handleChange}
          />
        )}
      </UIContext.Consumer>
    ) : (
      viewContent
    );
  }
}

export default createFragmentContainer(TextComponent, {
  componentProps: graphql`
    fragment TextComponent_componentProps on RegistrationPageComponent {
      id
      textComponent {
        id
        value
        padding
        backgroundColor
      }
    }
  `,
});
