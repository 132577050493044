/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type UserEvents_me$ref = any;
type UserTeams_me$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserView_me$ref: FragmentReference;
export type UserView_me = {|
  +id: string,
  +tz: string,
  +admin: boolean,
  +$fragmentRefs: UserEvents_me$ref & UserTeams_me$ref,
  +$refType: UserView_me$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "UserView_me",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "admin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UserEvents_me",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UserTeams_me",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'cdde6347d9fac5cf920efb44f1b765b8';
module.exports = node;
