/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import ConfirmationWindow from 'components/ConfirmationWindow';
import ShareDropdown, { Icon } from 'components/ShareDropdown';

import RevertImportWindow from '../RevertImportWindow';

import type { ImportLogsActionsColumn_importLog } from './__generated__/ImportLogsActionsColumn_importLog.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export { Icon };

class ImportLogsActionsColumn extends React.PureComponent<
  {
    orgId: string,
    importLog: ImportLogsActionsColumn_importLog,
    revertConfirmationMessage: string,
  },
  {
    showConfirmation: boolean,
    showRevertWindow: boolean,
  },
> {
  state = {
    showConfirmation: false,
    showRevertWindow: false,
  };

  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  handleDownloadFile = () => {
    window.open(this.props.importLog.originalFileUrl);
  };

  handleRevert = () => {
    this.setState({ showRevertWindow: true });
  };

  handleFinishRevert = () => {
    window.location.reload();
  };

  render() {
    const { importLog, orgId, revertConfirmationMessage } = this.props;
    const actions = [
      {
        label: 'Download original file',
        icon: 'download',
        onClick: this.handleDownloadFile,
      },
      importLog.viewerCanRevert
        ? {
            label: 'Revert import',
            icon: 'times',
            onClick: this.handleShowConfirmation,
          }
        : null,
    ].filter(Boolean);

    return (
      <Container onClick={this.stopPropagation}>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={actions}
          overlayContainer={document.body}
        />
        {this.state.showConfirmation && (
          <ConfirmationWindow
            actionLabel="Revert"
            onHide={this.handleHideConfirmation}
            onConfirm={this.handleRevert}
            message={revertConfirmationMessage}
          />
        )}
        {this.state.showRevertWindow && (
          <RevertImportWindow
            orgId={orgId}
            importLogId={importLog.id}
            onFinish={this.handleFinishRevert}
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  ImportLogsActionsColumn,
  graphql`
    fragment ImportLogsActionsColumn_importLog on ImportLog {
      id
      originalFileUrl
      viewerCanRevert
    }
  `,
);
