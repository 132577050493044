/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { renderDateRange } from 'utils/Date';
import { getFiscalYear, getQuarter } from 'utils/fiscal';
import { FILTER_DATE_FORMAT } from 'utils/parseFilters';

import { type DatesConfig } from 'components/date/DateTimeRangePicker';
import DateTimeRangeOverlay from 'components/date/DateTimeRangePicker/DateTimeRangeOverlay';

import FilterItemHeader from './FilterItemHeader';
import RadioFilterBody from './RadioFilterItem/RadioFilterBody';

const OPTIONS = [
  { value: 'past', label: 'Past' },
  { value: 'upcoming', label: 'Upcoming' },
  { value: 'recent', label: 'Recent' },
  { value: 'next30days', label: 'Next 30 days' },
  { value: 'next60days', label: 'Next 60 days' },
  { value: 'next90days', label: 'Next 90 days' },
];

type DateRangeParam = {
  key: ?string,
  start: moment,
  end: moment,
};

const StyledRadioFilterBody = styled(RadioFilterBody)`
  width: 100%;
`;

export default class DateFilterItem extends React.PureComponent<
  {
    label: string,
    name: string,
    activeValue: ?DateRangeParam,
    onChange: (string, ?string) => void,
    expanded?: boolean,
    showFiscalOptions?: boolean,
    showUpcomingYears?: boolean,
    hideShortcutOptions?: boolean,
    fiscalYearStart?: number,
  },
  {
    calendarOverlayShown: boolean,
  },
> {
  state = {
    calendarOverlayShown: false,
  };

  containerRef = React.createRef();

  targetRef = React.createRef();

  getDefaultOptions() {
    const fiscalYearStart = this.props.fiscalYearStart || 0;
    let generalOptions;
    if (this.props.hideShortcutOptions) {
      generalOptions = [];
    } else if (this.props.showUpcomingYears) {
      generalOptions = [
        ...OPTIONS.filter(option => option.value !== 'upcoming' && option.value !== 'recent'),
        { value: 'thisYear', label: 'This year' },
        { value: 'nextYear', label: 'Next year' },
      ];
    } else {
      generalOptions = OPTIONS;
    }
    const fiscalOptions = this.props.showFiscalOptions
      ? [
          ...[-2, -1, 0, 1, 2].map(quarterIndex => {
            const quarter = getQuarter(quarterIndex, fiscalYearStart);
            return { value: quarter.key, label: quarter.label };
          }),
          ...[-1, 0, 1].map(quarterIndex => {
            const year = getFiscalYear(quarterIndex, fiscalYearStart);
            return { value: year.key, label: year.label };
          }),
        ]
      : [];

    return [...generalOptions, ...fiscalOptions, { value: 'custom', label: 'Custom' }];
  }

  handleCalendarOverlayShow = () => this.setState({ calendarOverlayShown: true });

  handleCalendarOverlayHide = () => this.setState({ calendarOverlayShown: false });

  handleFilterSelect = (value: string) => {
    if (this.getDefaultOptions().some(o => value !== 'custom' && o.value === value)) {
      this.props.onChange(this.props.name, value);
    } else {
      this.handleCalendarOverlayShow();
    }
  };

  handleFilterClear = () => {
    this.props.onChange(this.props.name, null);
  };

  handleChangeDates = (datesConfig: DatesConfig) => {
    if (!datesConfig.startDate && !datesConfig.endDate) {
      this.handleFilterClear();
    } else {
      this.props.onChange(
        this.props.name,
        `${moment(datesConfig.startDate).format(FILTER_DATE_FORMAT)}-${moment(
          datesConfig.endDate || datesConfig.startDate,
        ).format(FILTER_DATE_FORMAT)}`,
      );
    }
  };

  formatSelected = (date: DateRangeParam) => {
    return renderDateRange({
      startDate: date.start,
      endDate: date.end,
      startDateAllDay: true,
      endDateAllDay: true,
      tz: moment.tz.guess(),
    });
  };

  handleClose = () => {
    this.forceUpdate();
  };

  render() {
    const activeValue = this.props.activeValue;
    const defaultOptions = this.getDefaultOptions();
    const useDefaultOptions = activeValue && defaultOptions.some(o => o.value === activeValue.key);

    const startDate = activeValue && !useDefaultOptions ? activeValue.start : null;
    const endDate = activeValue && !useDefaultOptions ? activeValue.end : null;

    const options =
      useDefaultOptions || !activeValue
        ? defaultOptions
        : defaultOptions.map(option =>
            option.value === 'custom'
              ? {
                  value: String(activeValue.key),
                  label: this.formatSelected(activeValue),
                }
              : option,
          );
    return (
      <FilterItemHeader
        label={this.props.label}
        count={this.props.activeValue ? 1 : 0}
        onClear={this.handleFilterClear}
        expanded={this.props.expanded}
        onClose={this.handleClose}
        ref={this.containerRef}
      >
        <StyledRadioFilterBody
          name={this.props.name}
          options={options}
          activeValue={this.props.activeValue != null ? this.props.activeValue.key : undefined}
          onChange={this.handleFilterSelect}
          ref={this.targetRef}
        />

        <DateTimeRangeOverlay
          tz={moment.tz.guess()}
          container={this.containerRef.current}
          target={this.targetRef.current}
          show={this.state.calendarOverlayShown}
          onHide={this.handleCalendarOverlayHide}
          startDate={startDate}
          endDate={!endDate || moment(endDate).isSame(startDate, 'date') ? null : endDate}
          hideStartTime
          hideEndTime
          datesOnly
          onChange={this.handleChangeDates}
          forceRightEdge
          keepInViewport
        />
      </FilterItemHeader>
    );
  }
}
