/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import showErrorPopup from 'utils/showErrorPopup';
import pickFiles from 'utils/uploading/pickFiles';
import type { CircaFile } from 'utils/uploading/types';

import updateImageComponent, {
  type updateImageComponentPropertyType,
} from 'graph/mutations/registration/updateImageComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/budget/Button';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultBackgroundColor } from '../../registrationFormDefaults';
import PropertyRow from '../components/PropertyRow';
import RegistrationColorSelector from '../components/RegistrationColorSelector';
import WidthModifier, { type WidthMeasurementType } from '../components/WidthModifier';
import { Section } from '.';
import { AlignmentToggle, BlockingLayer, PaddingComponent } from './components';
import { type AlignmentType } from './components/AlignmentToggle';

import ImageComponent_org from './__generated__/ImageComponent_org.graphql';
import ImageComponent_selectedPageComponent from './__generated__/ImageComponent_selectedPageComponent.graphql';

const Container = styled.div`
  position: relative;
`;

const StyledButton = styled(Button)`
  width: 90px;
`;

const Hint = styled.div`
  margin: -18px 0 20px;
  line-height: 16px;
  font-size: 12px;
  font-style: italic;
  color: #949ba4;
`;

class ImageComponent extends React.PureComponent<{
  org: ImageComponent_org,
  selectedPageComponent: ImageComponent_selectedPageComponent,
  onChangeComponentProperty: (updatedProps: SelectedComponent) => void,
}> {
  image: ?Image;

  updateImageComponent = (properties: updateImageComponentPropertyType) => {
    updateImageComponent({
      ...properties,
      id: this.props.selectedPageComponent.imageComponent.id,
    }).catch(showModernMutationError);
  };

  handleChangeAlignment = (alignment: AlignmentType) => {
    this.updateImageComponent({ alignment });
  };

  handleSavePadding = (padding: string) => {
    this.updateImageComponent({ padding });
  };

  handleChangeBackgroundColor = (backgroundColor: string): void => {
    this.updateImageComponent({ backgroundColor });
  };

  handleSaveWidthOrMeasurement = (
    width?: number,
    widthMeasurement?: WidthMeasurementType,
  ): void => {
    this.updateImageComponent({ width, widthMeasurement });
  };

  showImageUploadDialog = () => {
    const accept = { accept: ['image/*'] };
    const maxFiles = 1;
    const concurrency = 1;
    const options = {
      maxFiles,
      concurrency,
      ...accept,
      exposeOriginalFile: true,
      onFileSelected: file =>
        new Promise(resolve => {
          const url = URL.createObjectURL(file.originalFile);
          this.image = new Image();
          this.image.src = url;
          this.image.onload = () => {
            URL.revokeObjectURL(url);

            resolve();
          };
        }),
    };
    pickFiles(options)
      .then((files: $ReadOnlyArray<CircaFile>) => {
        // $FlowFixMe flow complaining that filename and other props doesn't exists in CircaFile which is not the case
        this.updateImageComponent({
          ...files[0],
          width: this.image && this.image.naturalWidth,
          widthMeasurement: 'PX',
        });
        if (this.image) {
          this.handleChangeWidth(this.image.naturalWidth);
        }
      })
      .catch(showErrorPopup);
  };

  handleChangeWidth = (width: number) => {
    this.props.onChangeComponentProperty({ selectedComponentWidth: width });
  };

  render() {
    const {
      org,
      selectedPageComponent: { id, imageComponent },
      onChangeComponentProperty,
    } = this.props;
    return (
      <Container>
        {id === 'new' && <BlockingLayer />}
        <Section>
          <PropertyRow label="Image">
            <StyledButton onClick={this.showImageUploadDialog}>
              {imageComponent.fileurl ? 'Replace' : 'Upload'}
            </StyledButton>
          </PropertyRow>

          <Hint>
            Image should be in png/jpg/gif format.
            <br />
            The size cannot exceed 10M.
          </Hint>

          {imageComponent.fileurl && (
            <PropertyRow label="Width">
              <WidthModifier
                width={imageComponent.width}
                widthMeasurement={imageComponent.widthMeasurement}
                onSaveWidthOrMeasurement={this.handleSaveWidthOrMeasurement}
                onChangeWidth={this.handleChangeWidth}
              />
            </PropertyRow>
          )}
        </Section>
        <Section>
          <PropertyRow label="Alignment">
            <AlignmentToggle
              selectedAlignment={imageComponent.alignment}
              onChange={this.handleChangeAlignment}
            />
          </PropertyRow>
          <PropertyRow label="Padding">
            <PaddingComponent
              paddingString={imageComponent.padding}
              onSave={this.handleSavePadding}
              onChange={onChangeComponentProperty}
            />
          </PropertyRow>
          <PropertyRow label="Background color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={imageComponent.backgroundColor || defaultBackgroundColor}
              onChangeColor={this.handleChangeBackgroundColor}
              includeTransparent
            />
          </PropertyRow>
        </Section>
      </Container>
    );
  }
}

export default createFragmentContainer(ImageComponent, {
  org: graphql`
    fragment ImageComponent_org on Org {
      id
      settings {
        ...RegistrationColorSelector_orgSettings
      }
    }
  `,
  selectedPageComponent: graphql`
    fragment ImageComponent_selectedPageComponent on RegistrationPageComponent {
      id
      kind
      imageComponent {
        id
        width
        widthMeasurement
        fileurl
        padding
        alignment
        backgroundColor
      }
    }
  `,
});
