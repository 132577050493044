/* @flow */
import { defaultSelectFields } from './suggestedFields';
import type { QuestionType } from './types';

const validateQuestion = (question: QuestionType): $ReadOnlyArray<string> => {
  const errors = [];

  if (!question.label.trim()) {
    errors.push('Question is required');
  }

  const customField = question.customField;

  if (question.id.startsWith('question_') && !customField) {
    errors.push('Please map the question with person or company fields');
  }

  if (
    customField &&
    (['SELECT', 'MULTISELECT'].includes(customField.kind) ||
      defaultSelectFields.includes(customField.fieldName)) &&
    customField &&
    customField.options.length - (question.contactFormFieldOptions || []).length === 0 &&
    !question.showOtherOption
  ) {
    errors.push('Please select at least one option');
  }

  return errors;
};

export default validateQuestion;
