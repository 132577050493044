/**
 * @flow
 * @relayHash 1a1551a848863380b4a995fa16579423
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserView_me$ref = any;
type UserView_org$ref = any;
type UserView_user$ref = any;
export type ProfileSettingsQueryVariables = {||};
export type ProfileSettingsQueryResponse = {|
  +me: {|
    +$fragmentRefs: UserView_me$ref & UserView_user$ref
  |},
  +org: {|
    +$fragmentRefs: UserView_org$ref
  |},
|};
export type ProfileSettingsQuery = {|
  variables: ProfileSettingsQueryVariables,
  response: ProfileSettingsQueryResponse,
|};
*/


/*
query ProfileSettingsQuery {
  me {
    ...UserView_me
    ...UserView_user
    id
  }
  org {
    ...UserView_org
    id
  }
}

fragment UserView_me on User {
  id
  tz
  admin
  ...UserEvents_me
  ...UserTeams_me
}

fragment UserView_user on User {
  id
  firstName
  lastName
  company
  title
  email
  phone
  officePhone
  tshirtSize
  bio
  avatar
  admin
  hasTeamAccess
  inviteStatus
  lastSeenAt
  viewerCanUpdate
  viewerCanUpdateAdmin
  viewerCanUpdateEmail
  viewerCanRemove
  ...UserAvatar_user
  ...UserEvents_user
  ...UserTeams_user
  memberships {
    edges {
      node {
        slug
        id
      }
    }
  }
  staffedEvents {
    edges {
      node {
        event {
          slug
          id
        }
        id
      }
    }
  }
}

fragment UserView_org on Org {
  id
  subscription {
    fatmLimit
    fatmCount
    id
  }
  ...UserEvents_org
  ...UserTeams_org
}

fragment UserEvents_org on Org {
  viewerCanManageStaff
  viewerCanManageTeamMembers
}

fragment UserTeams_org on Org {
  viewerCanManageTeamMembers
  subscription {
    fatmLimit
    fatmCount
    id
  }
}

fragment UserAvatar_user on User {
  avatar
  firstName
  lastName
  email
}

fragment UserEvents_user on User {
  id
  inviteStatus
}

fragment UserTeams_user on User {
  id
  admin
  hasTeamAccess
}

fragment UserEvents_me on User {
  id
}

fragment UserTeams_me on User {
  id
  ...AddUserToTeamsWindow_me
}

fragment AddUserToTeamsWindow_me on User {
  memberships {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ProfileSettingsQuery",
  "id": null,
  "text": "query ProfileSettingsQuery {\n  me {\n    ...UserView_me\n    ...UserView_user\n    id\n  }\n  org {\n    ...UserView_org\n    id\n  }\n}\n\nfragment UserView_me on User {\n  id\n  tz\n  admin\n  ...UserEvents_me\n  ...UserTeams_me\n}\n\nfragment UserView_user on User {\n  id\n  firstName\n  lastName\n  company\n  title\n  email\n  phone\n  officePhone\n  tshirtSize\n  bio\n  avatar\n  admin\n  hasTeamAccess\n  inviteStatus\n  lastSeenAt\n  viewerCanUpdate\n  viewerCanUpdateAdmin\n  viewerCanUpdateEmail\n  viewerCanRemove\n  ...UserAvatar_user\n  ...UserEvents_user\n  ...UserTeams_user\n  memberships {\n    edges {\n      node {\n        slug\n        id\n      }\n    }\n  }\n  staffedEvents {\n    edges {\n      node {\n        event {\n          slug\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment UserView_org on Org {\n  id\n  subscription {\n    fatmLimit\n    fatmCount\n    id\n  }\n  ...UserEvents_org\n  ...UserTeams_org\n}\n\nfragment UserEvents_org on Org {\n  viewerCanManageStaff\n  viewerCanManageTeamMembers\n}\n\nfragment UserTeams_org on Org {\n  viewerCanManageTeamMembers\n  subscription {\n    fatmLimit\n    fatmCount\n    id\n  }\n}\n\nfragment UserAvatar_user on User {\n  avatar\n  firstName\n  lastName\n  email\n}\n\nfragment UserEvents_user on User {\n  id\n  inviteStatus\n}\n\nfragment UserTeams_user on User {\n  id\n  admin\n  hasTeamAccess\n}\n\nfragment UserEvents_me on User {\n  id\n}\n\nfragment UserTeams_me on User {\n  id\n  ...AddUserToTeamsWindow_me\n}\n\nfragment AddUserToTeamsWindow_me on User {\n  memberships {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProfileSettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserView_me",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "UserView_user",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserView_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProfileSettingsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tz",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "admin",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "memberships",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamMembershipConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "MembershipTeamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      v1
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "company",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "officePhone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tshirtSize",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "bio",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avatar",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasTeamAccess",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "inviteStatus",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastSeenAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanUpdate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanUpdateAdmin",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanUpdateEmail",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanRemove",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "staffedEvents",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffMembershipRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembershipRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffMembership",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "event",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Event",
                        "plural": false,
                        "selections": [
                          v1,
                          v0
                        ]
                      },
                      v0
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fatmLimit",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fatmCount",
                "args": null,
                "storageKey": null
              },
              v0
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageStaff",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageTeamMembers",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c321c715a78c33d8741a53b7d9396343';
module.exports = node;
