/* @flow */
import fonts from 'config/fonts';

export default function generateFontFromProps(props: {
  fontFamily: ?string,
  fontStyle?: ?string,
  fontSize?: ?number,
  color?: ?string,
}): string {
  const selectedFont = fonts.find(
    font =>
      font.value === props.fontFamily ||
      font.label.toLowerCase() === (props.fontFamily != null && props.fontFamily.toLowerCase()),
  );

  return `${props.fontStyle && props.fontStyle.includes('italic') ? 'italic' : 'normal'} ${
    props.fontStyle && props.fontStyle.includes('bold') ? 'bold' : 'normal'
  } ${props.fontSize ?? 14}px ${
    selectedFont ? `"${selectedFont.label.replace(/ /g, ' ')}"` : 'Roboto'
  }`;
}
