/* @flow */
import React from 'react';
import throttle from 'lodash/throttle';

import requestMapsAutocomplete, {
  type MapsAutocompleteResults,
} from 'utils/locations/requestMapsAutocomplete';
import type {
  GooglePlacePrediction,
  Location,
} from 'utils/locations/locationTypes';
import requestMapsPlace from 'utils/locations/requestMapsPlace';
import googlePlaceToLocation from 'utils/locations/googlePlaceToLocation';
import LocationAutocompleteRow from 'components/material/LocationPicker/LocationAutocompleteRow';
import GoogleMapsScriptWrapper from 'components/GoogleMapsScriptWrapper';
import AutocompleteInput from './AutocompleteInput';

export default class LocationField extends React.PureComponent<
  {
    className?: ?string,
    defaultQuery: string,
    onSelectLocation: (location: Location) => void,
    autoFocus?: boolean,
    onBlur?: (e: SyntheticEvent<HTMLElement>) => void,
    disabled?: boolean,
  },
  {
    predictions: MapsAutocompleteResults,
  },
> {
  state = {
    predictions: [],
  };

  fetchAutocomplete = throttle((query: string) => {
    requestMapsAutocomplete(query)
      .then(predictions => {
        this.setState({
          predictions,
        });
      })
      .catch(error => {
        console.log('request error', error);
      });
  }, 400);

  handleSelectRow = (prediction: GooglePlacePrediction) => {
    requestMapsPlace(prediction.place_id).then(place => {
      const location = googlePlaceToLocation(place);
      this.props.onSelectLocation(location);
    });
  };

  handleRenderOptionString = (prediction: GooglePlacePrediction): string => {
    return prediction.structured_formatting.main_text;
  };

  renderRow = (prediction: GooglePlacePrediction) => {
    return <LocationAutocompleteRow prediction={prediction} />;
  };

  render() {
    return (
      <GoogleMapsScriptWrapper>
        <div className={this.props.className}>
          <AutocompleteInput
            defaultQuery={this.props.defaultQuery}
            options={this.state.predictions}
            onFilterOptions={this.fetchAutocomplete}
            onSelect={this.handleSelectRow}
            renderOption={this.renderRow}
            renderOptionString={this.handleRenderOptionString}
            autoFocus={this.props.autoFocus}
            onBlur={this.props.onBlur}
            disabled={this.props.disabled}
          />
        </div>
      </GoogleMapsScriptWrapper>
    );
  }
}
