/* @flow */
import styled from 'styled-components';

import Remove from 'images/remove.svg';

const WindowClose = styled(Remove)`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

export default WindowClose;
