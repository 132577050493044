/* @flow */
import type { FieldType } from 'utils/customization/types';

export default function getTaskFields(viewMode: string): $ReadOnlyArray<FieldType> {
  return [
    ...(viewMode === 'table'
      ? [
          {
            id: 'teamIds',
            label: 'Teams',
            fieldName: 'teamIds',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
          {
            id: 'eventIds',
            label: 'Events',
            fieldName: 'eventIds',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
          {
            id: 'eventDate',
            label: 'Event Date',
            fieldName: 'eventDate',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
        ]
      : []),
    {
      id: 'statuses',
      label: 'Status',
      fieldName: 'statuses',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'tagIds',
      label: 'Tags',
      fieldName: 'tagIds',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      minWidth: 215,
    },
    {
      id: 'assigneeIds',
      label: 'Assignees',
      fieldName: 'assigneeIds',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    viewMode !== 'calendar'
      ? {
          id: 'dueDate',
          label: 'Due Date',
          fieldName: 'dueDate',
          order: 0,
          kind: 'DEFAULT',
          required: false,
          options: [],
        }
      : null,
  ].filter(Boolean);
}
