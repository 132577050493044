/* @flow */
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import FilterItemHeader from '../FilterItemHeader';
import EventsFilterContainer from './EventsFilterContainer';

import type { EventFilterItemQueryResponse } from './__generated__/EventFilterItemQuery.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 0 0 10px;
`;

const query = graphql`
  query EventFilterItemQuery($query: String!, $eventIds: [ID!]!, $listType: EventListEnum) {
    query {
      ...EventsFilterContainer_query
    }
  }
`;

export default class EventFilterItem extends React.Component<
  {
    activeValues: ?$ReadOnlyArray<string>,
    label: string,
    name: string,
    onChange: (name: string, activeValues: ?$ReadOnlyArray<string>) => void,
    expanded?: boolean,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleFilterClear = () => {
    this.props.onChange(this.props.name, []);
  };

  handleChange = (name: string, activeValues: ?$ReadOnlyArray<string>, queryVal: string) => {
    this.setState({ query: queryVal });
    this.props.onChange(name, activeValues);
  };

  render() {
    return (
      <FilterItemHeader
        label={this.props.label}
        count={this.props.activeValues ? this.props.activeValues.length : 0}
        onClear={this.handleFilterClear}
        expanded={this.props.expanded}
      >
        <Container>
          <DefaultQueryRenderer
            query={query}
            variables={{
              query: this.state.query,
              eventIds: this.props.activeValues || [],
              listType: !query ? 'UPCOMING' : null,
            }}
            renderSuccess={(response: EventFilterItemQueryResponse) => (
              <EventsFilterContainer
                query={response.query}
                activeValues={this.props.activeValues}
                name={this.props.name}
                onChange={this.handleChange}
              />
            )}
            renderLoading={() => (
              <EventsFilterContainer
                query={null}
                activeValues={this.props.activeValues}
                name={this.props.name}
                onChange={this.handleChange}
              />
            )}
          />
        </Container>
      </FilterItemHeader>
    );
  }
}
