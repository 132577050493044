/* @flow */
import { getCookie } from 'utils/Cookies';
import isInIframe from 'utils/isInIframe';
import { ENABLE_ANALYTICS } from 'config/variables';

const analyticsEnabled = (ignoreCookieSettings?: boolean) => {
  const enabledInEnvironment = ENABLE_ANALYTICS;
  const disableAnalytics = !ignoreCookieSettings && getCookie('disableAnalytics');
  const inIframe = isInIframe();
  return enabledInEnvironment && disableAnalytics !== 'true' && !inIframe;
};

export default analyticsEnabled;
