/**
 * @flow
 * @relayHash 3e658e6ce8849de2622d7d1f83528e71
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TransferEventInput = {
  clientMutationId?: ?string,
  eventId: string,
  teamId: string,
};
export type transferEventMutationVariables = {|
  input: TransferEventInput
|};
export type transferEventMutationResponse = {|
  +transferEvent: ?{|
    +event: ?{|
      +id: string
    |}
  |}
|};
export type transferEventMutation = {|
  variables: transferEventMutationVariables,
  response: transferEventMutationResponse,
|};
*/


/*
mutation transferEventMutation(
  $input: TransferEventInput!
) {
  transferEvent(input: $input) {
    event {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TransferEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "transferEvent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "TransferEventInput!"
      }
    ],
    "concreteType": "TransferEventPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "transferEventMutation",
  "id": null,
  "text": "mutation transferEventMutation(\n  $input: TransferEventInput!\n) {\n  transferEvent(input: $input) {\n    event {\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "transferEventMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "transferEventMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7004ac5232308789071541b5cd326148';
module.exports = node;
