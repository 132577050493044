/* @flow */
import styled, { css } from 'styled-components';

const Container = styled.div`
  margin: 15px 0 5px;
  ${props =>
    props.scrollable &&
    css`
      max-height: 50vh;
      overflow: auto;
    `};

  @media (max-height: 580px) {
    max-height: calc(100vh - 120px); // 120px is the bottom position of the search box
  }

  ${props =>
    props.mobileView &&
    css`
      @media (max-width: 460px) {
        max-height: unset !important;
        height: 100%;
        padding-bottom: 50px;
      }
    `};
`;

export default Container;
