/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AllEventsHeaderBar_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsMap_org$ref: FragmentReference;
export type AllEventsMap_org = {|
  +$fragmentRefs: AllEventsHeaderBar_org$ref,
  +$refType: AllEventsMap_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "AllEventsMap_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AllEventsHeaderBar_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b3482aecbc005770f111900c957a5cf1';
module.exports = node;
