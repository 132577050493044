/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  forcePullCampaignMutationResponse,
  forcePullCampaignMutationVariables,
} from './__generated__/forcePullCampaignMutation.graphql';

const mutation = graphql`
  mutation forcePullCampaignMutation($input: ForcePullCampaignInput!) {
    forcePullCampaign(input: $input) {
      event {
        ...CRM_event
      }
    }
  }
`;

export default function syncCampaigns(input: {
  +eventId: string,
}): Promise<forcePullCampaignMutationResponse> {
  const variables: forcePullCampaignMutationVariables = {
    input: {
      eventId: input.eventId,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
  });
}
