/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import orderBy from 'lodash/orderBy';

import CostField from 'components/material/CostField';
import { StyledSelectField } from 'components/SharedForm/SelectField';

import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';
import inputBoxStyled from '../form/inputBoxStyled';
import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../lib/types';
import QuestionHeader from './QuestionHeader';

const StyledCostField = styled(CostField)`
  input {
    ${props => inputBoxStyled(props)};
  }
`;

const BudgetCategorySelectField = styled(StyledSelectField)`
  margin-top: 5px;
`;

const Row = styled.div`
  display: flex;
  > {
    flex: 1 1 auto;
    ${props =>
      props.hasTwoChildren &&
      css`
        &:first-child {
          margin-right: 40px;
        }
      `};
  }
  @media (${props => props.theme.mobileOnly}) {
    display: block;
    > :first-child {
      margin-right: 0;
    }
  }
`;

const StyledQuestionHeader = styled(QuestionHeader)`
  > span {
    font-size: 12px;
  }
  label {
    margin-top: -5px;
  }
`;

export default class QuestionPlannedBudgetAmount extends React.PureComponent<{
  question: QuestionType,
  value: QuestionValueType,
  onChangeValue: QuestionValueInputType => void,
  usedBudgetCategoeryIds?: $ReadOnlyArray<string>,
  readOnly: boolean,
  currency: string,
  error: ?string,
}> {
  handleSelectBudgetCategory = (budgetCategoryId: ?string) => {
    this.props.onChangeValue({ budgetCategoryId });
  };

  handleAmountChange = (e: SyntheticEvent<HTMLInputElement>, parsedCost: ?number) => {
    const { question, value } = this.props;
    this.props.onChangeValue({
      numberValue: parsedCost,
      budgetCategoryId: question.budgetCategory
        ? question.budgetCategory.id
        : value.budgetCategoryId,
    });
  };

  render() {
    const { question, value, currency, readOnly, error, usedBudgetCategoeryIds } = this.props;

    const budgetCategoryOptions = (readOnly
      ? question.budgetCategories
      : question.budgetCategories.filter(
          budgetCategory =>
            budgetCategory.id === value.budgetCategoryId ||
            !(usedBudgetCategoeryIds || []).includes(budgetCategory.id),
        )
    ).map(budgetCategory => ({
      value: budgetCategory.id,
      label: budgetCategory.name,
    }));

    return (
      <>
        {question.description && <HelperText>{question.description}</HelperText>}
        <Row hasTwoChildren={!question.budgetCategory}>
          {!question.budgetCategory && (
            <StyledQuestionHeader label="Budget Category">
              <BudgetCategorySelectField
                value={
                  value.budgetCategoryId || (question.budgetCategory && question.budgetCategory.id)
                }
                onChange={this.handleSelectBudgetCategory}
                options={orderBy(budgetCategoryOptions, [option => option.label.toLowerCase()])}
                error={error}
                clearable
                searchable
              />
            </StyledQuestionHeader>
          )}

          <StyledQuestionHeader
            label={question.budgetCategory ? question.budgetCategory.name : 'Amount'}
          >
            <StyledCostField
              currency={currency}
              defaultValue={value.numberValue}
              onChange={this.handleAmountChange}
              error={error ? ' ' : null}
              disabled={readOnly}
            />
          </StyledQuestionHeader>
        </Row>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  }
}
