import React from 'react';

import UserStore from 'stores/UserStore';

import { Panel, PanelContent, PanelFooter } from './Panel';
import { Button } from 'components/form/Button';
import { TextField } from 'components/form/TextField';

import Validator from 'utils/Validator';

export class ChangePasswordPanel extends React.Component {
  state = { errors: {}, loading: false }

  validator = new Validator({
    current_password: {
      required: true,
      min: 8,
      message: 'Can\'t be shorter than 8 symbols',
    },
    password: {
      required: true,
      min: 8,
      message: 'Minimal length for a password is 8 symbols',
    },
    password_confirmation: {
      required: true,
      matches: 'password',
      message: 'Password confirmation must match the password',
    },
  });

  show = () => {
    this.refs.panel.show();
  }
  hide = () => {
    this.refs.panel.hide();
  }
  handleHide = () => {
    this.setState({ errors: {}, loading: false });
  }
  handleSave = e => {
    e.preventDefault();

    const current_password = this.refs.current_password.value();
    const password = this.refs.password.value();
    const password_confirmation = this.refs.password_confirmation.value();

    const [valid, errors] = this.validator.isValid({
      current_password,
      password,
      password_confirmation,
    });

    this.setState({ errors });
    if (valid) {
      this.setState({ loading: true });
      UserStore.changePassword(current_password, password, password_confirmation, (response) => {
        if (response.error) {
          this.setState({ errors: { current_password: response.error }, loading: false });
        } else {
          this.hide();
        }
      });
    }
  }
  render() {
    const { errors, loading } = this.state;
    return (
      <Panel title="Change Password" {...this.props} ref="panel" onHide={this.handleHide}>
        <div className="change-password">
          <form>
            <PanelContent>
              <TextField
                invalid={errors.current_password}
                ref="current_password"
                type="password"
                label="Old password"
                autoFocus
              />
              <TextField
                invalid={errors.password}
                ref="password"
                type="password"
                label="New password"
              />
              <TextField
                invalid={errors.password_confirmation}
                ref="password_confirmation"
                type="password"
                label="Repeat new password"
              />
            </PanelContent>
            <PanelFooter>
              <div className="panel-buttons">
                <Button text="Cancel" type="button" minimal onClick={this.hide} />
                <Button text="Save" onClick={this.handleSave} primary loading={loading} />
              </div>
            </PanelFooter>
          </form>
        </div>
      </Panel>
    );
  }
}
