/* @flow */
import { graphql } from 'react-relay';
import omit from 'lodash/omit';

import { type EventStateGraphValue } from 'config/eventStates';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  G2WLocationInput,
  updateEventMutationResponse,
  updateEventMutationVariables,
  ZoomLocationInput,
} from './__generated__/updateEventMutation.graphql';

const mutation = graphql`
  mutation updateEventMutation(
    $input: UpdateEventInput!
    $changingName: Boolean!
    $changingDescription: Boolean!
    $changingLeadId: Boolean!
    $changingVirtualLocation: Boolean!
    $changingPrimaryLocationId: Boolean!
    $changingZoomLocation: Boolean!
    $changingG2wLocation: Boolean!
    $changingStatus: Boolean!
    $changingWebsite: Boolean!
    $changingBudgetedAmount: Boolean!
    $changingDates: Boolean!
    $changingTimeZone: Boolean!
  ) {
    updateEvent(input: $input) {
      event {
        id
        dbId
        name
        slug @include(if: $changingName)
        description @include(if: $changingDescription)
        lead @include(if: $changingLeadId) {
          id
          firstName
          lastName
        }
        tz @include(if: $changingTimeZone)
        startDate @include(if: $changingDates)
        endDate @include(if: $changingDates)
        startDateAllDay @include(if: $changingDates)
        endDateAllDay @include(if: $changingDates)
        updatedAt
        website @include(if: $changingWebsite)
        virtualLocation @include(if: $changingVirtualLocation)
        primaryLocation @include(if: $changingPrimaryLocationId) {
          name
          city
          state
          address1
          address2
          lat
          lng
          country
          postal
          timezone
          note
          website
        }
        zoomLocation @include(if: $changingZoomLocation) {
          id
          kind
          zoomUser {
            id
            zoomId
            firstName
            lastName
            email
            freePlan
          }
          zoomId
          joinUrl
          zoomUrl
          topic
          startTime
          duration
          timezone
        }
        g2wLocation @include(if: $changingG2wLocation) {
          id
          g2wKey
          subject
          times {
            startTime
            endTime
          }
          timezone
          registrationUrl
          g2wUrl
          g2wUser {
            id
            g2wKey
            firstName
            lastName
            email
          }
        }
        commitmentLevel @include(if: $changingStatus)
        totalBudgetedAmount @include(if: $changingBudgetedAmount)
        deliverables @include(if: $changingTimeZone) {
          edges {
            node {
              dbId
              due_date
            }
          }
        }
        team @include(if: $changingDates) {
          id
        }
        staffers @include(if: $changingLeadId) {
          edges {
            node {
              id
            }
          }
        }
        ...EventInfo_event
        registrationForm {
          ...RegistrationPageContent_registrationForm
        }
        pushStatus {
          lastSuccessAt
          state
          errorMessage
        }
      }
    }
  }
`;

export type Event = {|
  name?: string,
  description?: ?string,
  lead?: ?{ +id: string, +firstName: string, +lastName: string, +email: string },
  startDate?: ?string,
  endDate?: ?string,
  startDateAllDay?: ?boolean,
  endDateAllDay?: ?boolean,
  primaryLocationId?: ?string,
  zoomLocation?: ?ZoomLocationInput,
  g2wLocation?: ?G2WLocationInput,
  status?: ?EventStateGraphValue,
  virtualLocation?: ?string,
  website?: ?string,
  note?: ?string,
  budgetedAmount?: number,
|};

export default function updateEvent(
  eventId: string,
  event: Event,
  fromWindow: 'header' | 'event list' | 'info' = 'info',
): Promise<updateEventMutationResponse> {
  const eventProps = {
    ...omit(event, 'lead'),
    ...(event.lead === undefined ? {} : { leadId: event.lead && event.lead.id }),
  };
  const variables: $Shape<updateEventMutationVariables> = {
    input: {
      eventId,
      fromWindow,
      ...eventProps,
    },
    ...[
      'name',
      'description',
      'lead',
      'primaryLocationId',
      'zoomLocation',
      'g2wLocation',
      'virtualLocation',
      'status',
      'website',
      'budgetedAmount',
    ]
      .map(key => {
        const changingKey = key === 'lead' ? 'LeadId' : `${key[0].toUpperCase()}${key.slice(1)}`;
        return {
          [`changing${changingKey}`]: event[key] !== undefined,
        };
      })
      .reduce((acc, item) => ({ ...acc, ...item }), {}),
    changingDates: [
      event.zoomLocation,
      event.g2wLocation,
      event.primaryLocationId,
      event.startDate,
      event.startDateAllDay,
      event.endDate,
      event.endDateAllDay,
    ].some(value => value !== undefined),
    changingTimeZone: ['primaryLocationId', 'zoomLocation', 'g2wLocation'].some(key =>
      ({}.hasOwnProperty.call(event, key)),
    ),
  };
  const optimisticResponse = {
    updateEvent: {
      event: {
        id: eventId,
        ...(event.status ? { state: event.status } : null),
        ...(event.lead ? { lead: event.lead } : null),
        ...event,
        ...({}.hasOwnProperty.call(event, 'primaryLocationId')
          ? { primaryLocation: event.primaryLocationId ? { id: event.primaryLocationId } : null }
          : {}),
        ...({}.hasOwnProperty.call(event, 'zoomLocation')
          ? {
              zoomLocation: event.zoomLocation
                ? { id: event.zoomLocation.id, kind: event.zoomLocation.kind }
                : null,
            }
          : {}),
        ...({}.hasOwnProperty.call(event, 'g2wLocation')
          ? {
              g2wLocation: event.g2wLocation ? { id: event.g2wLocation.id } : null,
            }
          : {}),
        totalBudgetedAmount: event.budgetedAmount,
      },
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
