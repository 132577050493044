/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { identifyFullStoryUser } from 'utils/analytics/fullstory';
import identify from 'utils/analytics/identify';
import reset from 'utils/analytics/reset';
import { setErrorReportingUser } from 'utils/errorReporting';

import disableAnalytics from '../../utils/analytics/disableAnalytics';

import type { Analytics_org } from './__generated__/Analytics_org.graphql';
import type { Analytics_user } from './__generated__/Analytics_user.graphql';

class Analytics extends React.Component<{
  org: Analytics_org,
  user: Analytics_user,
}> {
  componentDidMount() {
    const { user, org } = this.props;
    setErrorReportingUser({
      id: user.dbId,
      email: user.email,
    });

    if (this.props.org.analyticsEnabled) {
      identify(user.dbId);
      identifyFullStoryUser({ ...user, org_id: org.dbId, org_name: org.name });
    } else {
      reset();
      disableAnalytics();
    }
  }

  render() {
    return null;
  }
}

export default createFragmentContainer(
  Analytics,
  graphql`
    fragment Analytics_user on User {
      dbId
      email
      firstName
      lastName
    }

    fragment Analytics_org on Org {
      id
      dbId
      name
      analyticsEnabled
    }
  `,
);
