/* @flow */

import determineCustomFieldMinWidth from 'utils/customization/determineCustomFieldMinWidth';
import type { FieldType } from 'utils/customization/types';
import type {
  CustomFieldParam,
  DateRangeParam,
  NumberRangeParam,
} from 'utils/routing/parseTypedQueryString';

import { type ContactFieldInput } from 'graph/mutations/contact/updateContact';
import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import { type ColumnType } from 'components/budget/Table';
import kindToTableComponent from 'components/Customizable/kindToTableComponent';
import type { Filter } from 'components/material/ColumnFilters/columnFilterTypes';

import { ContactActionCell, defaultTableComponents } from './columns';

// Flow will provide this type later as an input variable
export type InputVariableFilters = {
  [key: string]: CustomFieldParam,
  createdAt: ?DateRangeParam,
  creatorIds: ?$ReadOnlyArray<string>,
  createdMethods: ?$ReadOnlyArray<string>,
  updatedAt: ?DateRangeParam,
  updaterIds: ?$ReadOnlyArray<string>,
  updatedMethods: ?$ReadOnlyArray<string>,
  ownerIds: ?$ReadOnlyArray<string>,
  contactTypeIds: ?$ReadOnlyArray<string>,
  hotLead: ?boolean,
  emailOptIn: ?boolean,
  cities: ?$ReadOnlyArray<string>,
  states: ?$ReadOnlyArray<string>,
  countries: ?$ReadOnlyArray<string>,
  registrationStatusIds: ?$ReadOnlyArray<string>,
  attendanceStatusIds: ?$ReadOnlyArray<string>,
  salesforceSyncOptions: ?$ReadOnlyArray<string>,
  syncStatuses: ?$ReadOnlyArray<string>,
  sort: string,
  direction: 'ASC' | 'DESC',
  query: ?string,
  customFilters: $ReadOnlyArray<?{
    customFieldId: string,
    dateParam?: ?DateRangeParam,
    numberParam?: ?NumberRangeParam,
    booleanParam?: ?boolean,
    optionIds?: ?$ReadOnlyArray<string>,
  }>,
  customFieldSortId: ?string,
};

export type ContactCellPropsType<Org: ?{} = {}, Contact: {} = {}, Event: ?{} = {}> = {|
  org: Org,
  contact: Contact,
  customizable: Contact,
  eventContact?: { +node: Contact },
  event?: ?Event,
  inputFilters?: InputVariableFilters,
  fieldSettings: ?FieldType,
  salesforceHost: ?string,
  marketoHost: ?string,
  tz: string,
  isLast?: boolean,
  onUpdate: (args: $Shape<ContactFieldInput>) => Promise<void>,
  onUpdateCustomField: (
    customizable: CustomizableResponse,
    args: UpdateCustomFieldValueInput,
  ) => Promise<void>,
|};

export const defaultColumns = ['name', 'salesforce_sync_as', 'title', 'company_id', 'email'];

export default (
  contactFields: $ReadOnlyArray<FieldType>,
  shownColumns: $ReadOnlyArray<string>,
): $ReadOnlyArray<ColumnType<any, any>> => {
  return [
    ...contactFields
      .filter(({ fieldName, id }) => shownColumns.includes(fieldName || id))
      .map(contactField => {
        if (contactField.fieldName != null) {
          return {
            title: contactField.label,
            fieldSettings: contactField,
            component: defaultTableComponents[contactField.fieldName],
            sortKey: contactField.fieldName.toUpperCase(),
            align: contactField.fieldName === 'attendance_duration' ? 'right' : 'left',
            minWidth: [
              'website',
              'linkedin',
              'twitter',
              'owner_id',
              'registration_status_id',
              'attendance_status_id',
            ].includes(contactField.fieldName)
              ? 215
              : contactField.minWidth,
            ...(contactField.fieldName === 'name' ? { grow: 1, fixedLeft: 41 } : null),
          };
        }
        return {
          title: contactField.label,
          fieldSettings: contactField,
          sortKey: contactField.id,
          align: ['CURRENCY', 'NUMBER'].includes(contactField.kind) ? 'right' : 'left',
          component: kindToTableComponent[contactField.kind],
          minWidth: determineCustomFieldMinWidth(contactField.kind),
        };
      }),
    {
      title: '',
      type: 'action',
      component: ContactActionCell,
    },
  ];
};

export function getColumnsShowConfig(
  contactFields: $ReadOnlyArray<FieldType>,
): $ReadOnlyArray<Filter> {
  return contactFields.map(contactField => {
    return {
      value: contactField.fieldName || contactField.id,
      label: contactField.label,
      default: defaultColumns.includes(contactField.fieldName),
      sticky: contactField.fieldName === 'name',
    };
  });
}
