/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateDeclineReasonMutationVariables,
  updateDeclineReasonMutationResponse,
} from './__generated__/updateDeclineReasonMutation.graphql';

const mutation = graphql`
  mutation updateDeclineReasonMutation($input: UpdateDeclineReasonInput!) {
    updateDeclineReason(input: $input) {
      declineReason {
        id
        name
      }
    }
  }
`;

export default function updateDeclineReason(
  input: $PropertyType<updateDeclineReasonMutationVariables, 'input'>,
): Promise<updateDeclineReasonMutationResponse> {
  const variables: updateDeclineReasonMutationVariables = {
    input,
  };

  const optimisticResponse: updateDeclineReasonMutationResponse = {
    updateDeclineReason: {
      declineReason: {
        id: input.declineReasonId,
        name: input.name,
      },
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
