/* @flow */
import qs from 'qs';
import pickBy from 'lodash/pickBy';

// Remove dashes and colons from a RFC 8601 UTC string
function normalizeDate(utc: string): string {
  return utc.replace(/[-.:]/g, '');
}

export default function addToGoogleCalendarLink(fields: {
  start: string,
  end: string,
  summary?: ?string,
  details?: ?string,
  location?: ?string,
  description?: ?string,
}): string {
  const params = qs.stringify(
    pickBy({
      action: 'TEMPLATE',
      output: 'xml',
      sf: true,
      text: fields.summary,
      dates: `${normalizeDate(fields.start)}/${normalizeDate(fields.end)}`,
      location: fields.location,
      details: fields.description,
    }),
  );
  return `https://calendar.google.com/calendar/r/eventedit?${params}`;
}
