/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';

import SimpleBriefField from '../../components/SimpleBriefField';

import { type EventInfoLastSynced_event } from './__generated__/EventInfoLastSynced_event.graphql';

const EventInfoLastSynced = (props: { event: EventInfoLastSynced_event, name: string }) => {
  const {
    name,
    event: { tz, pullStatus, pushStatus },
  } = props;
  if (!pullStatus || !pushStatus || (!pullStatus.lastSuccessAt && !pushStatus.lastSuccessAt)) {
    return null;
  }
  const pullDate = pullStatus.lastSuccessAt && moment.tz(pullStatus.lastSuccessAt, tz);
  const pushDate = pushStatus.lastSuccessAt && moment.tz(pushStatus.lastSuccessAt, tz);

  const date = pullDate && pushDate ? moment.max(pullDate, pushDate) : pullDate || pushDate;

  const value = formatDateTime(moment(date).tz(tz));
  return <SimpleBriefField label={name} contentDataAvailable={date} data={value} />;
};

export default createFragmentContainer(
  EventInfoLastSynced,
  graphql`
    fragment EventInfoLastSynced_event on Event {
      tz
      pullStatus {
        lastSuccessAt
      }
      pushStatus {
        lastSuccessAt
      }
    }
  `,
);
