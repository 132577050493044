/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomizableNumber_customizable$ref: FragmentReference;
export type CustomizableNumber_customizable = {|
  +id: string,
  +__typename: string,
  +customNumberFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: number,
  |}>,
  +$refType: CustomizableNumber_customizable$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CustomizableNumber_customizable",
  "type": "CustomizableInterface",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customNumberFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomNumberFieldType",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customField",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "plural": false,
          "selections": [
            v0
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f523dd8fc02edb825dac510f1953217';
module.exports = node;
