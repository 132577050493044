/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EditableDeclineReasons_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestFormBuilderSettings_org$ref: FragmentReference;
export type EventRequestFormBuilderSettings_org = {|
  +hasSamlProvider: boolean,
  +$fragmentRefs: EditableDeclineReasons_org$ref,
  +$refType: EventRequestFormBuilderSettings_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestFormBuilderSettings_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasSamlProvider",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EditableDeclineReasons_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c1ca573917f8446de5c91e346d0abaf3';
module.exports = node;
