/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type DividerComponent_componentProps$ref = any;
type EmbedComponent_componentProps$ref = any;
type EventDatesComponent_componentProps$ref = any;
type EventDatesComponent_registrationForm$ref = any;
type EventNameComponent_componentProps$ref = any;
type EventNameComponent_registrationForm$ref = any;
type ImageComponent_componentProps$ref = any;
type RegistrationFormComponent_componentProps$ref = any;
type RegistrationFormComponent_registrationForm$ref = any;
type SharedRowComponent_componentProps$ref = any;
type SharedRowComponent_registrationForm$ref = any;
type TextComponent_componentProps$ref = any;
type VideoComponent_componentProps$ref = any;
export type Measurement = "PERCENTAGE" | "PX";
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationFormSubmissionContent_registrationForm$ref: FragmentReference;
export type RegistrationFormSubmissionContent_registrationForm = {|
  +id: string,
  +publishedAt: ?any,
  +submitMessage: ?string,
  +backgroundUrl: ?string,
  +backgroundColor: ?string,
  +width: number,
  +widthMeasurement: Measurement,
  +event: ?{|
    +id: string,
    +name: string,
    +team: {|
      +id: string,
      +name: string,
    |},
  |},
  +pageComponents: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +order: number,
        +kind: RegistrationPageComponentKind,
        +parent: ?{|
          +id: string
        |},
        +formComponent: ?{|
          +fontFamily: ?string
        |},
        +$fragmentRefs: ImageComponent_componentProps$ref & EventNameComponent_componentProps$ref & EventDatesComponent_componentProps$ref & TextComponent_componentProps$ref & DividerComponent_componentProps$ref & RegistrationFormComponent_componentProps$ref & VideoComponent_componentProps$ref & SharedRowComponent_componentProps$ref & EmbedComponent_componentProps$ref,
      |}
    |}>
  |},
  +$fragmentRefs: EventNameComponent_registrationForm$ref & EventDatesComponent_registrationForm$ref & RegistrationFormComponent_registrationForm$ref & SharedRowComponent_registrationForm$ref,
  +$refType: RegistrationFormSubmissionContent_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RegistrationFormSubmissionContent_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "publishedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "submitMessage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundColor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "width",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "widthMeasurement",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        v0,
        v1,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "team",
          "storageKey": null,
          "args": null,
          "concreteType": "Team",
          "plural": false,
          "selections": [
            v0,
            v1
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pageComponents",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationPageComponentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RegistrationPageComponentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationPageComponent",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "parent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationPageComponent",
                  "plural": false,
                  "selections": [
                    v0
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "formComponent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationFormComponent",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fontFamily",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ImageComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "EventNameComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "EventDatesComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "TextComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "DividerComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "RegistrationFormComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "VideoComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "SharedRowComponent_componentProps",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "EmbedComponent_componentProps",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "EventNameComponent_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventDatesComponent_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationFormComponent_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SharedRowComponent_registrationForm",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '65f2f9548bf38ce295b00c4dce50ce79';
module.exports = node;
