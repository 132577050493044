/* @flow */
import React from 'react';
import flattenDeep from 'lodash/flattenDeep';
import sortBy from 'lodash/sortBy';

import importedEvents from 'utils/analytics/events/importedEvents';
import isValidEmail from 'utils/validators/isValidEmail';

import importEvents, { type EventsImportMappingsType } from 'graph/mutations/import/importEvents';
import showModernMutationError from 'graph/utils/showModernMutationError';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import ImportWindow, {
  type ConfigStepProps,
  type ImportProps,
  type MappingsType,
} from 'components/ImportWindow';

import EventsImportWindowConfig from './EventsImportWindowConfig';
import fields, { regExpFromLabel } from './fields';
import query from './query';

import { type queryEventsImportWindowQueryResponse } from './__generated__/queryEventsImportWindowQuery.graphql';

type FieldType = $Keys<$Call<typeof fields, any>>;

export default class EventsImportWindow extends React.PureComponent<{
  onClose: () => void,
}> {
  handleValidateMappings = (mappings: MappingsType<string>) => {
    const mappingFields = Object.values(mappings);

    if (['name', 'team'].some(field => !mappingFields.includes(field))) {
      return 'Team Name and Event Name are required.';
    }

    const locationFields: $ReadOnlyArray<FieldType> = [
      'locationStreet',
      'locationCity',
      'locationState',
      'locationPostal',
      'locationCountry',
    ];

    if (
      mappingFields.includes('location') &&
      locationFields.some(field => mappingFields.includes(field))
    ) {
      return "Can't map both Location and separate address columns. Use either of those, but not both.";
    }

    if (
      !mappingFields.includes('locationCity') &&
      locationFields.some(field => mappingFields.includes(field))
    ) {
      return 'Address City is required with any of the Address columns.';
    }

    if (
      mappingFields.includes('locationName') &&
      !mappingFields.includes('location') &&
      locationFields.every(field => !mappingFields.includes(field))
    ) {
      return "Can't map Venue Name without location mapping.";
    }

    if (
      (mappingFields.includes('startTime') && !mappingFields.includes('startDate')) ||
      (mappingFields.includes('endTime') && !mappingFields.includes('endDate'))
    ) {
      return 'Date columns are required when mapping with time column.';
    }

    return null;
  };

  handleImport = ({ mappings, fileName, fileContent }: ImportProps<EventsImportMappingsType>) => {
    return importEvents({ mappings, fileName, fileContent })
      .then(importResults => {
        if (importResults && importResults.importedCount > 0) {
          importedEvents();
        }

        return importResults;
      })
      .catch(showModernMutationError);
  };

  renderConfirmationMessage = (orgName: string) => (count: number) =>
    `You are about to import ${count} event${count > 1 ? 's' : ''} to ${orgName}`;

  renderConfigStep = (response: queryEventsImportWindowQueryResponse) => ({
    onNext,
    importData,
  }: ConfigStepProps<string>) => {
    const orgUsers = response ? response.org.users.edges.map(edge => edge.node) : [];
    const importUsers = flattenDeep(
      importData.map(row =>
        [
          row.lead,
          ...response.org.customFields.edges
            .filter(({ node }) => node.kind === 'USER_SELECT' || node.kind === 'USER_MULTISELECT')
            .map(({ node }) => row[node.id]),
        ]
          .filter(Boolean)
          .map(str => str.split(/\s*[,;\n]\s*/)),
      ),
    )
      .map(str => {
        const isEmail = isValidEmail(str);
        const nameParts = isEmail ? [] : str.split(/\s+/);
        const email = isEmail ? str.toLowerCase() : '';
        const [firstName, lastName] = [nameParts[0] || '', nameParts.slice(1).join(' ') || ''];

        if (!email && (!firstName || !lastName)) return null;

        return { email, firstName, lastName };
      })
      .filter(Boolean);

    return (
      <EventsImportWindowConfig
        orgId={response.org.id}
        orgUsers={orgUsers}
        importUsers={importUsers}
        onNext={onNext}
      />
    );
  };

  renderImportWindow = (response?: queryEventsImportWindowQueryResponse) => {
    const onClose = this.props.onClose;
    const org = response ? response.org : null;

    const eventFields = org
      ? sortBy(
          org.customFields.edges,
          ({ node }) => node.section && node.section.order,
          ({ node }) => node.order,
        ).reduce(
          (fieldsObject, { node }) => {
            return {
              ...fieldsObject,
              [node.id]: {
                label: node.label,
                guess: regExpFromLabel(node.label),
                custom: true,
              },
            };
          },
          org ? { ...fields(org) } : {},
        )
      : {};

    return (
      <ImportWindow
        title="Import Events"
        importResourceName="Event"
        fields={eventFields}
        renderConfigStep={response ? this.renderConfigStep(response) : null}
        renderConfirmationMessage={this.renderConfirmationMessage(org ? org.name : '')}
        onValidateMappings={this.handleValidateMappings}
        onClose={onClose}
        onImport={this.handleImport}
      />
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={this.renderImportWindow}
        renderLoading={() => null}
      />
    );
  }
}
