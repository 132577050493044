/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Remove as RemoveIcon } from 'images';

const RemoveAction = styled.div`
  position: absolute;
  top: 50%;
  right: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  cursor: pointer;
  color: #868f96;

  &:hover {
    color: #4db1dd;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Container = styled.span`
  position: relative;
  display: inline-block;
  margin: 2px 0;
  &:not(:last-child) {
    margin-right: 7px;
  }
  padding: 3px 20px 3px 10px;
  ${props =>
    props.disabled &&
    css`
      padding-right: 10px;
    `};
  line-height: 14px;
  border-radius: 2px;
  background-color: #f5f5f5;
  color: ${props => (!props.disabled ? props.theme.rowPrimaryTextColor : props.theme.labelColor)};
`;

export default class RemovablePill<V> extends React.PureComponent<{
  value: V,
  children: React.Node,
  onRemove: (value: V) => void,
  disabled?: boolean,
  onClick?: (e: SyntheticEvent<>) => void,
}> {
  handleRemove = (e: SyntheticEvent<>) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.onRemove(this.props.value);
  };

  render() {
    return (
      <Container onClick={this.props.onClick} disabled={this.props.disabled}>
        {this.props.children}
        {!this.props.disabled && (
          <RemoveAction onClick={this.handleRemove}>
            <RemoveIcon />
          </RemoveAction>
        )}
      </Container>
    );
  }
}
