/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import type { Match } from 'react-router-dom';

import { Content } from 'components/page/Content';

import BriefRoutes from './BriefRoutes';

export default class Briefs extends React.Component<{
  match: Match,
  title: string,
}> {
  render() {
    return (
      <DocumentTitle title={this.props.title}>
        <Content>
          <BriefRoutes pathPrefix={this.props.match.path} />
        </Content>
      </DocumentTitle>
    );
  }
}
