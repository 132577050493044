import PropTypes from 'prop-types';
import React from 'react';
import { isLoggedIn } from 'utils/Auth';
import { Route, Redirect } from 'react-router-dom';
import { stringify } from 'qs';

export default class AuthedOnlyRoute extends React.Component {
  static propTypes = {
    component: PropTypes.func,
    render: PropTypes.func,
  }

  render() {
    const { component: Component, render, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isLoggedIn()) {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  search: stringify({
                    next: props.location.pathname,
                  }),
                }}
              />
            );
          } else if (Component) {
            return <Component {...props} />;
          }

          return render(props);
        }}
      />
    );
  }
}
