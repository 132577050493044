/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { TableLink } from 'components/material/table';

import type { TeamName_event } from './__generated__/TeamName_event.graphql';

const TeamName = (props: { event: TeamName_event, readOnly?: boolean }) => {
  const { event, readOnly } = props;

  if (readOnly || event.team.isRequestsTeam) {
    return event.team.name;
  }

  return <TableLink to={`/workspace/teams/${event.team.slug}`}>{event.team.name}</TableLink>;
};

export default createFragmentContainer(
  TeamName,
  graphql`
    fragment TeamName_event on Event {
      team {
        name
        slug
        isRequestsTeam
      }
    }
  `,
);
