/* @flow */

import OpportunityAmount from './OpportunityAmount';
import OpportunityAssociatedContacts from './OpportunityAssociatedContacts';
import OpportunityCompany from './OpportunityCompany';
import OpportunityDescription from './OpportunityDescription';
import OpportunityName from './OpportunityName';
import OpportunityOwner from './OpportunityOwner';
import OpportunitySalesforceId from './OpportunitySalesforceId';
import OpportunityStatus from './OpportunityStatus';

export {
  OpportunityAmount,
  OpportunityAssociatedContacts,
  OpportunityCompany,
  OpportunityDescription,
  OpportunityName,
  OpportunityOwner,
  OpportunitySalesforceId,
  OpportunityStatus,
};

export const defaultTableComponents = {
  salesforce_id: OpportunitySalesforceId,
  name: OpportunityName,
  amount: OpportunityAmount,
  status: OpportunityStatus,
  description: OpportunityDescription,
  company_id: OpportunityCompany,
  owner_id: OpportunityOwner,
  associated_contacts: OpportunityAssociatedContacts,
};
