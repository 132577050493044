/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import SelectField from 'components/material/SelectField';
import Switch from 'components/material/Switch';

import type { QuestionSelectOptionType, QuestionType } from '../lib/types';
import EditableQuestionSelectOptions from './EditableQuestionSelectOptions/EditableQuestionSelectOptions';

import type { EditableQuestionPlannedBudgetTotalOptionsQueryResponse } from './__generated__/EditableQuestionPlannedBudgetTotalOptionsQuery.graphql';

const Row = styled.div`
  display: flex;
  margin-top: 20px;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SwitchLabel = styled.div`
  margin-right: 15px;
  font-size: 13px;
  font-weight: 500;
  color: #4a5665;
`;

const StyledSelectField = styled(SelectField)`
  margin-top: 0;
`;

const query = graphql`
  query EditableQuestionPlannedBudgetTotalOptionsQuery {
    org {
      budgetCategories {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export default class EditableQuestionPlannedBudgetTotalOptions extends React.PureComponent<{
  question: QuestionType,
  onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
}> {
  handleTogglDefaultBudgetCategory = (
    allowRequesterToChooseBudgetCategory: boolean,
    budgetCategories: $ReadOnlyArray<QuestionSelectOptionType>,
  ) => {
    this.props.onChangeQuestion({
      budgetCategory: null,
      budgetCategories: allowRequesterToChooseBudgetCategory ? budgetCategories : [],
      allowRequesterToChooseBudgetCategory,
    });
  };

  handleChangeBudgetCategory = (
    budgetCategoryId: ?string,
    budgetCategories: $ReadOnlyArray<QuestionSelectOptionType>,
  ) => {
    const budgetCategory = budgetCategoryId
      ? budgetCategories.find(category => category.id === budgetCategoryId)
      : null;
    this.props.onChangeQuestion({ budgetCategory });
  };

  handleChangeQuestion = (changes: $Exact<{ ...QuestionType }>) => {
    this.props.onChangeQuestion({
      budgetCategories: changes.selectOptions,
      budgetCategory: null,
    });
  };

  render() {
    const question = this.props.question;

    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(response: EditableQuestionPlannedBudgetTotalOptionsQueryResponse) => {
          const budgetCategories = sortBy(
            response.org.budgetCategories.edges.map(edge => edge.node),
            'name',
          );
          const budgetCategoryOptions = budgetCategories.map(budgetCategory => ({
            value: budgetCategory.id,
            label: budgetCategory.name,
          }));

          return (
            <>
              <Row>
                <SwitchWrapper>
                  <SwitchLabel>Allow requester to choose a budget category</SwitchLabel>
                  <Switch
                    enabled={question.allowRequesterToChooseBudgetCategory || false}
                    onChange={enabled =>
                      this.handleTogglDefaultBudgetCategory(enabled, budgetCategories)
                    }
                    size="small"
                  />
                </SwitchWrapper>
              </Row>
              {question.allowRequesterToChooseBudgetCategory ? (
                <EditableQuestionSelectOptions
                  question={{ ...question, selectOptions: question.budgetCategories || [] }}
                  onChangeQuestion={this.handleChangeQuestion}
                  options={response.org.budgetCategories.edges.map(edge => edge.node)}
                  mappingLabel="budget category"
                  hideOtherOption
                  emptyMessage={
                    <>
                      Add categories in{' '}
                      <a href="/settings/budgets" target="_blank" rel="noopener noreferrer">
                        Workspace Settings
                      </a>
                    </>
                  }
                />
              ) : (
                <StyledSelectField
                  label="Select budget category"
                  value={question.budgetCategory ? question.budgetCategory.id : null}
                  options={budgetCategoryOptions}
                  onChange={value => this.handleChangeBudgetCategory(value, budgetCategories)}
                  searchable
                  required
                />
              )}
            </>
          );
        }}
      />
    );
  }
}
