/**
 * @flow
 * @relayHash 7eb03ea42019d157a571f43d0a0c1689
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveContactFormBccEmailInput = {
  clientMutationId?: ?string,
  contactFormBccEmailId: string,
};
export type removeContactFormBccEmailMutationVariables = {|
  input: RemoveContactFormBccEmailInput
|};
export type removeContactFormBccEmailMutationResponse = {|
  +removeContactFormBccEmail: ?{|
    +removedContactFormBccEmailId: string,
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type removeContactFormBccEmailMutation = {|
  variables: removeContactFormBccEmailMutationVariables,
  response: removeContactFormBccEmailMutationResponse,
|};
*/


/*
mutation removeContactFormBccEmailMutation(
  $input: RemoveContactFormBccEmailInput!
) {
  removeContactFormBccEmail(input: $input) {
    removedContactFormBccEmailId
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveContactFormBccEmailInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeContactFormBccEmail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveContactFormBccEmailInput!"
      }
    ],
    "concreteType": "RemoveContactFormBccEmailPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedContactFormBccEmailId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "registrationForm",
        "storageKey": null,
        "args": null,
        "concreteType": "RegistrationForm",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasChanges",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeContactFormBccEmailMutation",
  "id": null,
  "text": "mutation removeContactFormBccEmailMutation(\n  $input: RemoveContactFormBccEmailInput!\n) {\n  removeContactFormBccEmail(input: $input) {\n    removedContactFormBccEmailId\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeContactFormBccEmailMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeContactFormBccEmailMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f7a65201a08ea3f7df7d3f42bb828596';
module.exports = node;
