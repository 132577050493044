/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  reorderBriefTemplateEventInfoSectionsMutationResponse,
  SortableSectionInput,
} from './__generated__/reorderBriefTemplateEventInfoSectionsMutation.graphql';

const mutation = graphql`
  mutation reorderBriefTemplateEventInfoSectionsMutation(
    $input: ReorderBriefTemplateEventInfoSectionsInput!
  ) {
    reorderBriefTemplateEventInfoSections(input: $input) {
      eventInfoSections {
        id
        order
      }
    }
  }
`;

export default function reorderBriefTemplateEventInfoSections(
  eventInfoSections: $ReadOnlyArray<SortableSectionInput>,
): Promise<reorderBriefTemplateEventInfoSectionsMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input: { eventInfoSections } },
    optimisticResponse: {
      reorderBriefTemplateEventInfoSections: {
        eventInfoSections,
      },
    },
  });
}
