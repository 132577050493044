/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type GoogleDocsSchedule_event$ref = any;
type GoogleDocsSchedule_schedule$ref = any;
type SchedulesHeader_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchedulesPage_event$ref: FragmentReference;
export type SchedulesPage_event = {|
  +id: string,
  +dbId: number,
  +slug: string,
  +name: string,
  +team: {|
    +dbId: number,
    +name: string,
  |},
  +schedules: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +slug: string,
        +slugs: $ReadOnlyArray<{|
          +slug: string
        |}>,
        +order: number,
        +dbId: number,
        +name: string,
        +token: string,
        +$fragmentRefs: GoogleDocsSchedule_schedule$ref,
      |}
    |}>
  |},
  +$fragmentRefs: SchedulesHeader_event$ref & GoogleDocsSchedule_event$ref,
  +$refType: SchedulesPage_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SchedulesPage_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    v2,
    v3,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        v1,
        v3
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "schedules",
      "storageKey": null,
      "args": null,
      "concreteType": "ScheduleRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ScheduleRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Schedule",
              "plural": false,
              "selections": [
                v0,
                v2,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "slugs",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Slug",
                  "plural": true,
                  "selections": [
                    v2
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                v1,
                v3,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "token",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "GoogleDocsSchedule_schedule",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "SchedulesHeader_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "GoogleDocsSchedule_event",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e39e3ec7ccac5f3222dfb7c41f1f1043';
module.exports = node;
