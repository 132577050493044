/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import UserView, { MembershipsContainer, UserViewContainer } from 'components/UserView';

import type { ProfileSettingsQueryResponse } from './__generated__/ProfileSettingsQuery.graphql';

const query = graphql`
  query ProfileSettingsQuery {
    me {
      ...UserView_me
      ...UserView_user
    }
    org {
      ...UserView_org
    }
  }
`;

const StyledUserView = styled(UserView)`
  ${MembershipsContainer} {
    padding-left: 33px;
  }
  ${UserViewContainer} {
    padding-left: 33px;
  }
`;

export default class ProfileSettings extends React.Component<{
  history: History,
  location: Location,
}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(props: ProfileSettingsQueryResponse) =>
          props.me && (
            <StyledUserView
              org={props.org}
              user={props.me}
              me={props.me}
              standalonePage={false}
              history={this.props.history}
              location={this.props.location}
            />
          )
        }
      />
    );
  }
}
