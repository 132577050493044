/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import formatDateTimeISO from 'utils/date/formatDateTimeISO';
import fullNameOfUser from 'utils/fullNameOfUser';
import { urlRegexOptionalProtocol } from 'utils/validators/isValidWebsite';

import ConfirmationWindow from 'components/ConfirmationWindow';
import Avatar from 'components/material/Avatar';
import { PureCommentInput } from 'components/Comments/CommentInput';
import SimpleCommentInput from 'components/Comments/SimpleCommentInput';

import Mention from './Mention';

import type { CommentItem_comment } from './__generated__/CommentItem_comment.graphql';
import type { CommentItem_users } from './__generated__/CommentItem_users.graphql';

const Actions = styled.div`
  visibility: hidden;
  position: absolute;
  right: 10px;
  top: 8px;
`;

const Reply = styled.a`
  margin-left: 15px;
  visibility: hidden;
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

const Container = styled.div`
  padding: 10px;
  display: flex;
  position: relative;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.primaryRowColor};
  }
  &:hover {
    ${Actions} {
      visibility: visible;
    }
    ${Reply} {
      visibility: visible;
    }
    ${props =>
      !props.systemGenerated &&
      css`
        background: ${props.theme.hoverRowColor};
      `};
  }
`;

export const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;
  margin-right: 10px;
`;

const Content = styled.div`
  font-size: 13px;
  color: ${props => props.theme.rowPrimaryTextColor};
  word-break: break-word;
  a {
    color: ${props => props.theme.primaryActionColor};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Info = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: ${props => props.theme.rowSecondaryTextColor};
`;

const Action = styled.i`
  color: ${props => props.theme.secondaryActionColor};
  font-size: 14px;
  margin-left: 3px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

export const User = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
`;

export const CommentText = styled.div`
  font-weight: ${props => (props.systemGenerated ? 'bold' : 'inherit')};
`;

class PureCommentItem extends React.PureComponent<
  {
    comment: CommentItem_comment,
    users: CommentItem_users,
    currentUserId: string,
    onRemove: (commentId: string) => void,
    onUpdate: (commentId: string, content: string) => void,
    tz: string,
    onReply?: (userSlug: string) => void,
    className?: ?string,
    simple?: boolean,
    hideEmoji?: boolean,
    disabled?: boolean,
    avatarSize?: number,
    styledInput?: boolean,
  },
  {
    editing: boolean,
    showRemoveModal: boolean,
  },
> {
  state = {
    editing: false,
    showRemoveModal: false,
  };

  handleEdit = () => {
    this.setState({ editing: true });
  };

  handleEditEnd = () => {
    this.setState({ editing: false });
  };

  handleShowRemoveModal = () => {
    this.setState({ showRemoveModal: true });
  };

  handleHideRemoveModal = () => {
    this.setState({ showRemoveModal: false });
  };

  handleRemove = () => {
    this.props.onRemove(this.props.comment.id);
  };

  handleUpdate = (content: string) => {
    this.props.onUpdate(this.props.comment.id, content);

    this.handleEditEnd();
  };

  handleReply = () => {
    if (this.props.onReply) {
      this.props.onReply(this.props.comment.user.slug);
    }
  };

  linkify = (str: string): Array<React.Node> => {
    return str.split(urlRegexOptionalProtocol).map(s => {
      if (urlRegexOptionalProtocol.test(s)) {
        if (/^https?/.test(s)) {
          return (
            <a href={s} target="_blank" rel="noreferrer noopener">
              {s}
            </a>
          );
        }
        return (
          <a href={`http://${s}`} target="_blank" rel="noreferrer noopener">
            {s}
          </a>
        );
      }
      return s;
    });
  };

  renderContent() {
    /* eslint-disable react/no-array-index-key */
    return this.props.comment.content.split(/(^|\s)(@\S+)/g).map((str, index) => {
      if (str.includes('\n')) {
        return str.split('\n').reduce((lines, line, lineIndex) => {
          if (lines.length === 0) return [line];
          return [...lines, <br key={lineIndex} />, this.linkify(line)];
        }, []);
      }
      if (str.startsWith('@')) {
        const user = this.props.users.find(u => u.slug === str.slice(1));

        if (user) {
          return <Mention key={index}>{user.firstName}</Mention>;
        }
      }
      return this.linkify(str);
    });
    /* eslint-enable */
  }

  render() {
    const {
      simple,
      disabled,
      className,
      styledInput,
      currentUserId,
      users,
      hideEmoji,
      tz,
      onReply,
      avatarSize,
      comment: { user, content, postedAt, systemGenerated },
    } = this.props;

    if (this.state.editing) {
      if (simple) {
        return (
          <Container systemGenerated={systemGenerated} className={className}>
            <SimpleCommentInput
              defaultValue={content}
              onCancel={this.handleEditEnd}
              autoFocus
              placeholder="Edit comment"
              onSave={this.handleUpdate}
            />
          </Container>
        );
      }
      return (
        <PureCommentInput
          defaultValue={content}
          currentUserId={currentUserId}
          users={users}
          onCancel={this.handleEditEnd}
          autoFocus
          placeholder="Edit comment"
          onSave={this.handleUpdate}
          hideEmoji={hideEmoji}
          className={styledInput ? className : ''}
          avatarSize={avatarSize}
        />
      );
    }

    const date = moment.tz(postedAt, tz);
    const isCurrentUser = currentUserId === user.id;
    const userName = fullNameOfUser(user);

    return (
      <Container systemGenerated={systemGenerated} className={className}>
        <StyledAvatar profile={user} size={avatarSize != null ? avatarSize : 13} />
        <Content>
          {!systemGenerated && <User>{userName}</User>}
          <CommentText systemGenerated={systemGenerated}>
            {systemGenerated ? userName : ''} {this.renderContent()}
          </CommentText>
          <Info>
            {formatDateTimeISO(date)}
            {!isCurrentUser && onReply && <Reply onClick={this.handleReply}>Reply</Reply>}
          </Info>
        </Content>
        {isCurrentUser &&
          !disabled &&
          !systemGenerated && (
            <Actions>
              <Action className="fa fa-fw fa-pencil" onClick={this.handleEdit} />
              <Action className="fa fa-fw fa-trash" onClick={this.handleShowRemoveModal} />
            </Actions>
          )}
        {this.state.showRemoveModal && (
          <ConfirmationWindow
            onHide={this.handleHideRemoveModal}
            onConfirm={this.handleRemove}
            title="Are you sure?"
            message="Once you delete a comment, it's gone for good."
          />
        )}
      </Container>
    );
  }
}

const RelayCommentItem = createFragmentContainer(PureCommentItem, {
  comment: graphql`
    fragment CommentItem_comment on Comment {
      id
      content
      postedAt
      systemGenerated
      user {
        id
        slug
        firstName
        lastName
        avatar
      }
    }
  `,
  users: graphql`
    fragment CommentItem_users on User @relay(plural: true) {
      id
      slug
      firstName
      lastName
      avatar
    }
  `,
});

export { PureCommentItem, RelayCommentItem as default };
