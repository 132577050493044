/**
 * @flow
 * @relayHash 45eb1bafb36247bc032fd8bb0e51e6ea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSalesForceSettingsInput = {
  clientMutationId?: ?string,
  host?: ?string,
  defaultLeadSource?: ?string,
  contactsSyncEnabled?: ?boolean,
  opportunitiesSyncEnabled?: ?boolean,
};
export type updateSalesforceSettingsMutationVariables = {|
  input: UpdateSalesForceSettingsInput
|};
export type updateSalesforceSettingsMutationResponse = {|
  +updateSalesforceSettings: ?{|
    +salesforceAccount: {|
      +host: string,
      +defaultLeadSource: ?string,
      +contactsSyncEnabled: boolean,
      +opportunitiesSyncEnabled: boolean,
    |}
  |}
|};
export type updateSalesforceSettingsMutation = {|
  variables: updateSalesforceSettingsMutationVariables,
  response: updateSalesforceSettingsMutationResponse,
|};
*/


/*
mutation updateSalesforceSettingsMutation(
  $input: UpdateSalesForceSettingsInput!
) {
  updateSalesforceSettings(input: $input) {
    salesforceAccount {
      host
      defaultLeadSource
      contactsSyncEnabled
      opportunitiesSyncEnabled
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSalesForceSettingsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateSalesForceSettingsInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "host",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "defaultLeadSource",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactsSyncEnabled",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "opportunitiesSyncEnabled",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateSalesforceSettingsMutation",
  "id": null,
  "text": "mutation updateSalesforceSettingsMutation(\n  $input: UpdateSalesForceSettingsInput!\n) {\n  updateSalesforceSettings(input: $input) {\n    salesforceAccount {\n      host\n      defaultLeadSource\n      contactsSyncEnabled\n      opportunitiesSyncEnabled\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateSalesforceSettingsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSalesforceSettings",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSalesForceSettingsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateSalesforceSettingsMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSalesforceSettings",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSalesForceSettingsPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cdb5ae26532b9e6e5f4fa9bc97fdc696';
module.exports = node;
