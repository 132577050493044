/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import US_STATES from 'config/us_states.json';

import type { FieldType } from 'utils/customization/types';

import type { VendorFieldInput } from 'graph/mutations/vendor/updateVendor';

import InlineSelectField from 'components/budget/Table/InlineSelectField';
import InlineTextField from 'components/budget/Table/InlineTextField';

import VendorState_vendor from './__generated__/VendorState_vendor.graphql';

const stateOptions = Object.keys(US_STATES).map(key => ({
  label: US_STATES[key],
  value: US_STATES[key],
}));

class VendorState extends React.Component<{
  vendor: VendorState_vendor,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<VendorFieldInput>) => Promise<void>,
}> {
  handleUpdate = (value: ?string) => {
    const { vendor, onUpdate, updateColumnWidth } = this.props;
    if (!this.getError(value) && value !== vendor.state) {
      onUpdate({ state: value });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    const { vendor, fieldSettings } = this.props;
    if (fieldSettings.required && !vendor.state && (!value || !value.trim())) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { vendor, fieldSettings, updateColumnWidth } = this.props;

    return vendor.country === 'United States' ? (
      <InlineSelectField
        placeholder={fieldSettings.label}
        options={stateOptions}
        value={stateOptions.find(option => option.value === vendor.state) ? vendor.state : null}
        clearable={!fieldSettings.required}
        onChange={this.handleUpdate}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        searchable
      />
    ) : (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={vendor.state || ''}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        onChange={this.handleUpdate}
      />
    );
  }
}

export default createFragmentContainer(
  VendorState,
  graphql`
    fragment VendorState_vendor on Vendor {
      country
      state
    }
  `,
);
