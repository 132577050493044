/* @flow */
import React from 'react';

import importedExpenses from 'utils/analytics/events/importedExpenses';

import importExpenses, {
  type ExpensesImportMappingsType,
} from 'graph/mutations/import/importExpenses';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ImportWindow, { type ImportProps, type MappingsType } from 'components/ImportWindow';

const fields = {
  eventId: { label: 'Event ID', guess: /event/ },
  name: { label: 'Expense Name', guess: /name/ },
  amount: { label: 'Amount', guess: /amount/ },
  category: { label: 'Expense Category', guess: /category/ },
  vendor: { label: 'Vendor', guess: /vendor/ },
  currency: { label: 'Currency', guess: /currency/ },
  type: { label: 'Payment Type', guess: /type/ },
  status: { label: 'Payment Status', guess: /status/ },
  dueDate: { label: 'Due Date', guess: /due/ },
  actualDate: { label: 'Paid Date', guess: /date|paid|actual/ },
  method: { label: 'Payment Method', guess: /method/ },
  poNumber: { label: 'PO#', guess: /po/ },
  reference: { label: 'Ref. Code', guess: /ref/ },
  note: { label: 'Note', guess: /note/ },
};

type FieldType = $Keys<typeof fields>;

export default class ExpensesImportWindow extends React.PureComponent<{
  onClose: () => void,
}> {
  handleValidateMappings = (mappings: MappingsType<FieldType>) => {
    const mappingFields = Object.values(mappings);

    if (['eventId', 'name', 'amount'].every(field => mappingFields.includes(field))) {
      return null;
    }

    return 'Event ID, Expense Name and Amount are required.';
  };

  handleImport = ({ mappings, fileName, fileContent }: ImportProps<ExpensesImportMappingsType>) => {
    return importExpenses({ mappings, fileName, fileContent })
      .then(importResults => {
        if (importResults && importResults.importedCount > 0) {
          importedExpenses();
        }

        return importResults;
      })
      .catch(showModernMutationError);
  };

  renderConfirmationMessage = (count: number) =>
    `You are about to import ${count} expense${count > 1 ? 's' : ''}.`;

  render() {
    return (
      <ImportWindow
        title="Import expenses"
        importResourceName="Expense"
        fields={fields}
        renderConfirmationMessage={this.renderConfirmationMessage}
        onValidateMappings={this.handleValidateMappings}
        onClose={this.props.onClose}
        onImport={this.handleImport}
      />
    );
  }
}
