import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import theme from 'config/theme';

const executeFunctionIfExist = (object, key) => {
  if (Object.prototype.hasOwnProperty.call(object, key)) {
    object[key]();
  }
};

const TooltipWrapper = styled.div`
  position: absolute;
  padding: 2px 7px;
  opacity: ${props => props.opacity};
  visibility: ${props => props.visibility};
  font-size: 12px;
  white-space: pre-line;
  background: ${props => props.theme.tooltipColor};
  color: ${props => props.theme.tooltipTextColor};
  border-radius: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  transition: ${props => props.transition} 0.3s;
  z-index: 1000;
  &:before {
    ${props => props.placement === 'bottom' && 'bottom: 100%; left: 50%; margin-left: -4px;'}
    ${props => props.placement === 'top' && 'top: 100%; left: 50%; margin-left: -4px;'}
    ${props => props.placement === 'right' && 'right: 100%; top: 50%; margin-top: -4px;'}
    ${props => props.placement === 'left' && 'left: 100%; top: 50%; margin-top: -4px;'}
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    ${props => props.placement === 'bottom' && `border-bottom-color: ${props.theme.tooltipColor};`}
    ${props => props.placement === 'top' && `border-top-color: ${props.theme.tooltipColor};`}
    ${props => props.placement === 'right' && `border-right-color: ${props.theme.tooltipColor};`}
    ${props => props.placement === 'left' && `border-left-color: ${props.theme.tooltipColor};`}
    border-width: 4px;
  }
`;

type Props = {
  active?: boolean,
  placement?: 'top' | 'bottom' | 'right' | 'left',
  align?: 'center' | 'right' | 'left' | null,
  useHover?: boolean,
  parentEl: React.Node,
  children: React.Node,
  additionalOffset?: number,
};

type State = {
  hover: boolean,
  transition: 'string',
  width: number,
  height: number,
  updatedOnPropsChange: boolean,
};

export default class Card extends React.Component<Props, State> {
  margin = 10;

  static defaultProps = {
    active: false,
    placement: 'top',
    align: null,
    useHover: true,
    updatedOnPropsChange: true,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const transition = prevState.hover || nextProps.active ? 'all' : 'opacity';
    if (transition === prevState.transition) {
      return { transition, updatedOnPropsChange: false };
    }
    return null;
  }

  state = {
    hover: false,
    transition: 'opacity',
    width: 0,
    height: 0,
  };

  componentDidMount() {
    this.updateSize();
  }

  componentDidUpdate(nextProps: Props, nextState: State) {
    // Call updateSize only after props change. Otherwise it gets into infinite loop
    if (!nextState.updatedOnPropsChange || nextProps.children !== this.props.children) {
      // eslint-disable-next-line react/no-did-update-set-state, react/no-unused-state
      this.setState({ updatedOnPropsChange: true });
      this.updateSize();
    }
  }

  getGlobalStyle() {
    if (!this.props.parentEl) {
      return { display: 'none' };
    }

    return this.getStyle(this.props.placement);
  }

  getStyle = placement => {
    let alignOffset = 0;
    const parent = this.props.parentEl;
    const align = this.props.align;
    const tooltipPosition = parent.getBoundingClientRect();
    const scrollY = window.scrollY !== undefined ? window.scrollY : window.pageYOffset;
    const scrollX = window.scrollX !== undefined ? window.scrollX : window.pageXOffset;
    const top = scrollY + tooltipPosition.top;
    const left = scrollX + tooltipPosition.left;
    const style = {};

    const parentSize = {
      width: parent.offsetWidth,
      height: parent.offsetHeight,
    };

    // fix for svg
    if (!parent.offsetHeight && parent.getBoundingClientRect) {
      parentSize.width = parent.getBoundingClientRect().width;
      parentSize.height = parent.getBoundingClientRect().height;
    }

    if (align === 'left') {
      alignOffset = -parentSize.width / 2 + 8;
    } else if (align === 'right') {
      alignOffset = parentSize.width / 2 - 8;
    }

    const stylesFromPosition = {
      left: () => {
        style.top = top + parentSize.height / 2 - this.state.height / 2;
        style.left = left - this.state.width - this.margin;
      },
      right: () => {
        style.top = top + parentSize.height / 2 - this.state.height / 2;
        style.left = left + parentSize.width + this.margin;
      },
      top: () => {
        style.left = left - this.state.width / 2 + parentSize.width / 2 + alignOffset;
        style.top = top - this.state.height - this.margin + (this.props.additionalOffset || 0);
      },
      bottom: () => {
        style.left = left - this.state.width / 2 + parentSize.width / 2 + alignOffset;
        style.top = top + parentSize.height + this.margin;
      },
    };

    executeFunctionIfExist(stylesFromPosition, placement);

    return style;
  };

  handleMouseEnter = () => {
    if (this.props.active && this.props.useHover) {
      this.setState({ hover: true });
    }
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  checkWindowPosition(style) {
    let styleNew = null;

    if (this.props.placement === 'top' || this.props.placement === 'bottom') {
      if (style.left < 0) {
        styleNew = { ...style, left: this.margin };
      } else {
        const rightOffset = style.left + this.state.width - window.innerWidth;
        if (rightOffset > 0) {
          styleNew = { ...style, left: window.innerWidth - this.state.width - this.margin };
        }
      }
    }
    if (!styleNew) {
      styleNew = style;
    }

    return styleNew;
  }

  updateSize() {
    // eslint-disable-next-line react/no-find-dom-node
    const self = ReactDOM.findDOMNode(this);
    this.setState({
      width: self.offsetWidth,
      height: self.offsetHeight,
    });
  }

  render() {
    const styleNew = this.checkWindowPosition(this.getGlobalStyle());

    return (
      <TooltipWrapper
        theme={theme}
        transition={this.state.transition}
        placement={this.props.placement}
        opacity={this.state.hover || this.props.active ? 1 : 0}
        visibility={this.state.hover || this.props.active ? 'visible' : 'hidden'}
        style={styleNew}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.props.children}
      </TooltipWrapper>
    );
  }
}
