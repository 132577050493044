/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import type { ContactPersonType_contact } from './__generated__/ContactPersonType_contact.graphql';

class ContactPersonType extends React.PureComponent<{
  contact: ContactPersonType_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  getError = (): ?string => {
    const { fieldSettings, contact } = this.props;

    if (fieldSettings.required && contact.contactType == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { fieldSettings, contact, updateColumnWidth } = this.props;

    return (
      <InlineSelectField
        placeholder={fieldSettings.label}
        options={fieldSettings.options}
        value={contact.contactType != null ? contact.contactType.id : null}
        onChange={(contactType: ?string) => {
          this.props.onUpdate({ contactTypeId: contactType }).then(() => updateColumnWidth());
        }}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        disabled={fieldSettings.restrictChangingValue}
      />
    );
  }
}

export default createFragmentContainer(
  ContactPersonType,
  graphql`
    fragment ContactPersonType_contact on Contact {
      contactType {
        id
      }
    }
  `,
);
