/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { TeamMemberEmail_member } from './__generated__/TeamMemberEmail_member.graphql';

const EmailLink = styled.a`
  color: ${props => props.theme.primaryActionColor};

  &:hover {
    text-decoration: underline;
  }
`;

class TeamMemberEmail extends React.PureComponent<{
  member: TeamMemberEmail_member,
  onColumnLoad: string => void,
}> {
  componentDidMount() {
    this.props.onColumnLoad(this.props.member.node.email || '');
  }

  stopPropagation = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation();
  };

  render() {
    const email = this.props.member.node.email;
    if (!email) {
      return null;
    }

    return (
      <EmailLink
        href={`mailto:${email}`}
        target="_blank"
        onClick={this.stopPropagation}
        rel="noopener noreferrer"
      >
        {email}
      </EmailLink>
    );
  }
}

export default createFragmentContainer(
  TeamMemberEmail,
  graphql`
    fragment TeamMemberEmail_member on TeamMemberEdge {
      node {
        email
      }
    }
  `,
);
