/* @flow */
import * as React from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDateTimeRange from 'utils/date/formatDateTimeRange';
import formatLocation from 'utils/locations/formatLocation';
import formatCost from 'utils/number/formatCost';

import RelayAttachments from 'components/RelayAttachments';

import { linkRenderer, userSelectFormatter } from './mappingCustomValueFormatters';
import mappingKinds from './mappingKinds';
import { type QuestionType, type QuestionValueType } from './types';

const StyledAttachments = styled(RelayAttachments)`
  margin-left: -10px;
`;

const mappingValueFormatters: $ObjMap<
  typeof mappingKinds,
  () => (QuestionType, QuestionValueType, { currency: string, tz: string }) => React.Node,
> = {
  NAME: (question, { textValue }) => textValue,
  LOCATION: (question, questionValue) =>
    questionValue.locationValue
      ? formatLocation({
          name: questionValue.locationValue.name,
          city: questionValue.locationValue.city,
          country: questionValue.locationValue.country,
          state: questionValue.locationValue.state,
        })
      : linkRenderer(question, questionValue),
  DATE: (question, { dateValue }, { tz }) =>
    dateValue &&
    formatDateTimeRange({
      startDate: moment.tz(dateValue.startDate, tz),
      endDate: dateValue.endDate ? moment.tz(dateValue.endDate, tz) : null,
      startDateAllDay: dateValue.startDateAllDay,
      endDateAllDay: dateValue.endDateAllDay,
    }),
  WEBSITE: linkRenderer,
  DESCRIPTION: (question, { textValue }) => (
    <Linkify properties={{ target: '_blank' }}>{textValue}</Linkify>
  ),
  NOTE: (question, { textValue }) => (
    <Linkify properties={{ target: '_blank' }}>{textValue}</Linkify>
  ),
  ATTACHMENTS: (question, { filesValue }) =>
    filesValue.length > 0 ? (
      <StyledAttachments attachments={[]} mockAttachments={filesValue} />
    ) : null,
  BOOTH: (question, { textValue }) => textValue,
  BOOTH_DIMENSIONS: (question, { textValue }) => textValue,
  FLOOR_PLAN: (question, { textValue }) => (
    <Linkify properties={{ target: '_blank' }}>{textValue}</Linkify>
  ),
  LEADER: userSelectFormatter,
  EXPENSE: (question, { textValue, numberValue }, { currency }) =>
    numberValue == null ? null : `${textValue || ''}: ${formatCost(numberValue, currency)}`,
  BUDGETED_AMOUNT: (question, { numberValue }, { currency }) => formatCost(numberValue, currency),
  PLANNED_BUDGET: (question, { numberValue, budgetCategoryId }, { currency }) => {
    const budgetCategory =
      question.budgetCategory ||
      question.budgetCategories.find(({ id }) => id === budgetCategoryId);

    if (!budgetCategory || numberValue == null) {
      return null;
    }

    return `${budgetCategory.name}: ${formatCost(numberValue, currency)}`;
  },
};

export default mappingValueFormatters;
