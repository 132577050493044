/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateRegistrationForm, {
  type updateRegistrationPropertyType,
} from 'graph/mutations/registration/updateRegistrationForm';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { SelectedComponent } from '../../../RegistrationCreateContent';
import WidthModifier, { type WidthMeasurementType } from '../../components/WidthModifier';
import BackgroundStyling from './BackgroundStyling';
import FormFieldsStyling from './FormFieldsStyling';

import type { PageStyling_org } from './__generated__/PageStyling_org.graphql';
import type { PageStyling_registrationForm } from './__generated__/PageStyling_registrationForm.graphql';

const Section = styled.div`
  padding: 20px 20px 30px 25px;
  &:last-child {
    border-top: 1px solid #d2d9f5;
  }
`;

const Label = styled.div`
  line-height: 1em;
  font-weight: bold;
  color: #29305d;
  margin-bottom: 25px;
`;

const StyledWidthModifier = styled(WidthModifier)`
  margin-bottom: 30px;
`;

class PageStyling extends React.PureComponent<{
  org: PageStyling_org,
  registrationForm: PageStyling_registrationForm,
  onChangeWidth: (width: number) => void,
  onChangeSelectedComponentProperty: (updatedProps: SelectedComponent) => void,
}> {
  updateRegistrationForm = (properties: updateRegistrationPropertyType) => {
    updateRegistrationForm({ ...properties, formId: this.props.registrationForm.id }).catch(
      showModernMutationError,
    );
  };

  handleSaveWidthOrMeasurement = (
    width?: number,
    widthMeasurement?: WidthMeasurementType,
  ): void => {
    this.updateRegistrationForm({ width, widthMeasurement });
  };

  render() {
    const { registrationForm, org, onChangeWidth, onChangeSelectedComponentProperty } = this.props;
    const formEdge = registrationForm.pageComponents.edges.find(({ node }) => node.kind === 'FORM');
    return (
      <div className="component-styling">
        <Section>
          <Label>Page Width</Label>
          <StyledWidthModifier
            width={registrationForm.width}
            widthMeasurement={registrationForm.widthMeasurement}
            onSaveWidthOrMeasurement={this.handleSaveWidthOrMeasurement}
            onChangeWidth={onChangeWidth}
          />
          <Label>Background</Label>
          <BackgroundStyling orgSettings={org.settings} registrationForm={registrationForm} />
        </Section>
        {formEdge && (
          <Section>
            <Label>Form Styling</Label>
            <FormFieldsStyling
              orgSettings={org.settings}
              formComponent={formEdge.node.formComponent}
              onChangeSelectedComponentProperty={onChangeSelectedComponentProperty}
            />
          </Section>
        )}
      </div>
    );
  }
}

export default createFragmentContainer(PageStyling, {
  org: graphql`
    fragment PageStyling_org on Org {
      settings {
        ...BackgroundStyling_orgSettings
        ...FormFieldsStyling_orgSettings
      }
    }
  `,
  registrationForm: graphql`
    fragment PageStyling_registrationForm on RegistrationForm {
      id
      width
      widthMeasurement
      ...BackgroundStyling_registrationForm
      pageComponents {
        edges {
          node {
            kind
            formComponent {
              id
              ...FormFieldsStyling_formComponent
            }
          }
        }
      }
    }
  `,
});
