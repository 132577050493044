/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import TextInput from 'components/material/TextInput';

import Label, { Error, Header } from '../FieldComponents';
import { withSharedFormTheme } from '../lib/SharedFormThemeContext';
import inputBoxStyled from './inputBoxStyled';

export const Component = withSharedFormTheme(styled.div`
  max-height: 285px;
  margin-top: 5px;
  overflow-y: auto;
  ${props =>
    props.isScrollable &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 15px;
        /* Safari has issues with property transparent */
        background: linear-gradient(
          to bottom,
          ${props.sharedFormTheme.backgroundColor},
          rgba(255, 255, 255, 0.001)
        );
        pointer-events: none;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 15px;
        /* Safari has issues with property transparent */
        background: linear-gradient(
          to top,
          ${props.sharedFormTheme.backgroundColor},
          rgba(255, 255, 255, 0.001)
        );
        pointer-events: none;
      }
    `}
`);

export const ComponentWrapper = styled.div`
  position: relative;
`;

export const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

export default class SelectFieldWrapper extends React.Component<
  {
    label: string,
    required: boolean,
    error: ?string,
    values: $ReadOnlyArray<string>,
    children?: React.Node,
    onChangeOtherInput?: (value: ?string) => void,
  },
  {
    otherOption: string,
  },
> {
  state = {
    otherOption: '',
  };

  handleChangeOtherOption = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ otherOption: e.currentTarget.value });
  };

  handleBlurOtherOptionInput = () => {
    if (this.props.onChangeOtherInput) {
      this.props.onChangeOtherInput(this.state.otherOption || '');
    }
  };

  render() {
    const { label, required, error, values, children } = this.props;

    return (
      <Label required={required}>
        <Header>{label}</Header>
        {children}
        {values.includes('other') && (
          <StyledTextInput
            autoFocus={!this.state.otherOption}
            value={this.state.otherOption}
            onChange={this.handleChangeOtherOption}
            onBlur={this.handleBlurOtherOptionInput}
          />
        )}

        {error && <Error>{error}</Error>}
      </Label>
    );
  }
}
