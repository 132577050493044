/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import ActiveSubscriptionMessage from './ActiveSubscriptionMessage';
import ExpiredSubscriptionMessage from './ExpiredSubscriptionMessage';

import type { BillingPlanMessage_subscription } from './__generated__/BillingPlanMessage_subscription.graphql';

type Props = { subscription: BillingPlanMessage_subscription };

const BillingPlanMessage = ({ subscription }: Props) => (
  <div>
    {subscription.upgradeRequired ? (
      <ExpiredSubscriptionMessage />
    ) : (
      <ActiveSubscriptionMessage subscription={subscription} />
    )}
  </div>
);

export default createFragmentContainer(
  BillingPlanMessage,
  graphql`
    fragment BillingPlanMessage_subscription on Subscription {
      upgradeRequired
      ...ActiveSubscriptionMessage_subscription
    }
  `,
);
