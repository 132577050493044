/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const RemoveIcon = styled.span`
  position: absolute;
  top: -${props => props.size / 2}px;
  right: -${props => props.size / 2}px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  font-size: ${props => props.size}px;
  line-height: ${props => props.size}px;
  visibility: hidden;
  transform: rotate(45deg);
  border-radius: 50%;
  background: ${props => props.theme.tooltipColor};
  color: ${props => props.theme.tooltipTextColor};
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.negativeActionColor};
    color: ${props => props.theme.negativeActionTextColor};
  }
`;

const Container = styled.div`
  position: relative;

  &:hover {
    ${RemoveIcon} {
      visibility: visible;
    }
  }
`;

export default class RemovablePill extends React.PureComponent<{
  children: React.Node,
  onRemove: () => void,
  className?: string,
  disabled?: boolean,
  size?: number,
  onClick?: (e: SyntheticEvent<>) => void,
}> {
  static defaultProps = {
    size: 14,
  };

  handleRemove = (e: SyntheticEvent<>) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.onRemove();
  };

  render() {
    return (
      <Container className={this.props.className} onClick={this.props.onClick}>
        {this.props.children}
        {!this.props.disabled && (
          <RemoveIcon onClick={this.handleRemove} size={this.props.size}>
            +
          </RemoveIcon>
        )}
      </Container>
    );
  }
}
