/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import xorBy from 'lodash/xorBy';

import getOptionKeysByKind from 'utils/customization/getOptionKeysByKind';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import { type CustomFieldsType, type FieldType } from 'utils/customization/types';
import isValidEmail from 'utils/validators/isValidEmail';
import isValidWebsite from 'utils/validators/isValidWebsite';

import updateCustomFieldValue, {
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import updateVendor from 'graph/mutations/vendor/updateVendor';
import showModernMutationError from 'graph/utils/showModernMutationError';

import customFields, { type InexactUser } from 'components/Customizable/customFields';
import TextField from 'components/material/TextField';

import suggestedFields from './suggestedFields';
import { type VendorDefaultFields } from './VendorFormFields';

import type { VendorProfile_event } from './__generated__/VendorProfile_event.graphql';
import type { VendorProfile_user } from './__generated__/VendorProfile_user.graphql';
import type { VendorProfile_vendor } from './__generated__/VendorProfile_vendor.graphql';

const ContactViewContent = styled.div`
  display: flex;
  margin-top: 30px;
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 495px;
`;

const Row = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 15px;
  padding-left: 8px;
`;

const FieldColumn = styled.div`
  flex: 1 1 auto;
  &:not(:last-child) {
    margin-right: 35px;
  }
`;

type Props = {
  vendor: VendorProfile_vendor,
  event?: VendorProfile_event,
  user: VendorProfile_user,
};

type State = {
  errors: { [string]: string },
  vendorDefaultFields: VendorDefaultFields,
  vendorCustomFields: CustomFieldsType,
};

class VendorProfile extends React.Component<Props, State> {
  state = {
    errors: {},
    vendorDefaultFields: {
      firstName: this.props.vendor.firstName,
      lastName: this.props.vendor.lastName,
      email: this.props.vendor.email,
      title: this.props.vendor.title,
      phone1: this.props.vendor.phone1,
      phone2: this.props.vendor.phone2,
      website: this.props.vendor.website,
      twitter: this.props.vendor.twitter,
      linkedin: this.props.vendor.linkedin,
      description: this.props.vendor.description,
      country: this.props.vendor.country,
      state: this.props.vendor.state,
      city: this.props.vendor.city,
      zip: this.props.vendor.zip,
      street: this.props.vendor.street,
      companyPhone: this.props.vendor.companyPhone,
      name: this.props.vendor.name,
    },
    vendorCustomFields: {
      customTextFields: this.props.vendor.customTextFields,
      customTextareaFields: this.props.vendor.customTextareaFields,
      customLinkFields: this.props.vendor.customLinkFields,
      customDateFields: this.props.vendor.customDateFields,
      customNumberFields: this.props.vendor.customNumberFields,
      customCurrencyFields: this.props.vendor.customCurrencyFields,
      customBooleanFields: this.props.vendor.customBooleanFields,
      customUserSelectFields: this.props.vendor.customUserSelectFields,
      customUserMultiselectFields: this.props.vendor.customUserMultiselectFields,
      customSelectFields: this.props.vendor.customSelectFields,
      customMultiselectFields: this.props.vendor.customMultiselectFields,
    },
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      xorBy(
        [
          ...prevState.vendorCustomFields.customMultiselectFields,
          ...prevState.vendorCustomFields.customSelectFields,
        ],
        [...nextProps.vendor.customMultiselectFields, ...nextProps.vendor.customSelectFields],
        filed => filed.option.id,
      ).length > 0
    ) {
      return {
        vendorCustomFields: {
          ...prevState.vendorCustomFields,
          customMultiselectFields: nextProps.vendor.customMultiselectFields,
          customSelectFields: nextProps.vendor.customSelectFields,
        },
      };
    }
    return null;
  }

  handleFixedFieldsBlur = (name: string, value: string) => {
    const {
      vendor: { id: vendorId },
      event,
    } = this.props;
    const parsedValue = value.trim();
    if (parsedValue === this.state.vendorDefaultFields[name]) return;
    this.setState(
      prevState => ({
        vendorDefaultFields: { ...prevState.vendorDefaultFields, [name]: parsedValue },
      }),
      () => {
        const { name: vendorName } = this.state.vendorDefaultFields;
        if (!vendorName) {
          this.setState(prevState => ({
            errors: { ...prevState.errors, [name]: 'is required' },
          }));
          return;
        }

        this.setState(prevState => ({
          errors: Object.keys(prevState.errors).reduce((obj, key) => {
            return key === 'name' ? obj : { ...obj, [key]: prevState.errors[key] };
          }, {}),
        }));
        updateVendor({
          vendorId,
          name: vendorName,
          eventId: event ? event.id : null,
          fromWindow: event ? 'event vendors' : 'org vendors',
        }).catch(showModernMutationError);
      },
    );
  };

  handleTextFieldBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    this.handleFixedFieldsBlur(e.currentTarget.name, e.currentTarget.value);
  };

  updateCustomFieldState = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    const { arrayKey, optionKey } = getOptionKeysByKind(field.kind);
    const value = Object.values(input)[0];
    const valuesArray = Array.isArray(value) ? value : [value].filter(Boolean);
    const optionsArray = valuesArray.map(optionId =>
      users == null ? optionId : users.find(user => user.id === optionId),
    );
    this.setState(prevState => {
      const fieldOptions = prevState.vendorCustomFields[arrayKey];
      return {
        vendorCustomFields: {
          ...prevState.vendorCustomFields,
          [arrayKey]: [
            ...fieldOptions.filter(option => option.customField.id !== field.id),
            ...optionsArray.map(option => ({
              customField: { id: field.id },
              [optionKey]: optionKey === 'option' ? { id: option } : option,
            })),
          ],
        },
      };
    });
  };

  handleUpdateSuggestedField = (field: FieldType, changes: VendorDefaultFields) => {
    const {
      vendor: { id: vendorId },
      event,
    } = this.props;
    const value = Object.values(changes)[0];
    const fieldName = Object.keys(changes)[0];

    this.setState(prevState => ({
      vendorDefaultFields: { ...prevState.vendorDefaultFields, ...changes },
    }));
    if (field.required && (value === '' || value == null)) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [fieldName]: 'Required' },
      }));
      return;
    }
    if (
      ['website', 'twitter', 'linkedin'].includes(fieldName) &&
      typeof value === 'string' &&
      value !== '' &&
      !isValidWebsite(value)
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [fieldName]: 'Invalid url' },
      }));
      return;
    }

    if (field.fieldName === 'email' && typeof value === 'string' && !isValidEmail(value)) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [fieldName]: 'Invalid email' },
      }));
      return;
    }
    updateVendor({
      vendorId,
      eventId: event ? event.id : null,
      fromWindow: event ? 'event vendors' : 'org vendors',
      ...changes,
    }).catch(showModernMutationError);
    this.setState(prevState => ({
      errors: Object.keys(prevState.errors).reduce((obj, key) => {
        return key === fieldName ? obj : { ...obj, [key]: prevState.errors[key] };
      }, {}),
    }));
  };

  handleUpdateCustomField = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    this.updateCustomFieldState(field, input, users);
    const { event, vendor } = this.props;
    if (field.required && !Object.values(input)[0]) {
      this.setState(prevState => ({ errors: { ...prevState.errors, [field.id]: 'Required' } }));
      return;
    }
    if (field.kind === 'LINK' && !!input.linkValue && !isValidWebsite(input.linkValue)) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Invalid url' },
      }));
      return;
    }
    if (field.kind === 'LINK' && field.required && !input.linkValue) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    if (
      field.required &&
      field.kind === 'MULTISELECT' &&
      input.selectValues != null &&
      input.selectValues.length === 0
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    if (
      field.required &&
      field.kind === 'USER_MULTISELECT' &&
      input.userValues != null &&
      input.userValues.length === 0
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    updateCustomFieldValue(
      { customFieldId: field.id, ...input },
      vendor,
      event ? event.id : null,
      event ? 'event contacts' : 'org contacts',
    ).catch(showModernMutationError);
    this.setState(prevState => ({
      errors: Object.keys(prevState.errors).reduce((obj, key) => {
        return key === field.id ? obj : { ...obj, [key]: prevState.errors[key] };
      }, {}),
    }));
  };

  render() {
    const vendor = this.props.vendor;
    const { vendorDefaultFields, vendorCustomFields, errors } = this.state;
    const sortedCustomFields = normalizeCustomFields(
      vendor.org.customFields.edges.map(edge => edge.node),
    );
    return (
      <ContactViewContent>
        <DetailsColumn>
          <Row>
            <FieldColumn>
              <TextField
                label="Vendor Name"
                defaultValue={vendorDefaultFields.name}
                autoFocus
                name="name"
                disabled={!vendor.viewerCanUpdate}
                onBlur={this.handleTextFieldBlur}
                error={errors.name}
                required
              />
            </FieldColumn>
            <FieldColumn />
          </Row>
          {sortedCustomFields.map(field => {
            if (field.kind === 'DEFAULT') {
              const renderer = field.fieldName && suggestedFields[field.fieldName];
              if (!renderer) return null;
              return (
                <Row key={field.id}>
                  {renderer({
                    vendor: vendorDefaultFields,
                    viewerCanUpdate: vendor.viewerCanUpdate,
                    handleUpdate: changes => this.handleUpdateSuggestedField(field, changes),
                    field,
                    currency: vendor.org.settings.currency,
                    errors: this.state.errors,
                  })}
                </Row>
              );
            }
            return (
              <Row key={field.id}>
                {customFields[field.kind]({
                  customizable: vendorCustomFields,
                  viewerCanUpdateValue: vendor.viewerCanUpdate,
                  handleUpdate: (input, users) => this.handleUpdateCustomField(field, input, users),
                  field,
                  currency: vendor.org.settings.currency,
                  tz: this.props.user.tz,
                  errors: this.state.errors,
                })}
              </Row>
            );
          })}
        </DetailsColumn>
      </ContactViewContent>
    );
  }
}

export default createFragmentContainer(VendorProfile, {
  vendor: graphql`
    fragment VendorProfile_vendor on Vendor {
      id
      __typename
      firstName
      lastName
      email
      title
      phone1
      phone2
      website
      twitter
      linkedin
      description
      country
      state
      city
      zip
      street
      companyPhone
      name
      viewerCanRemove
      viewerCanUpdate
      org {
        id
        dbId
        settings {
          currency
        }
        customFields(customizableType: [VENDOR]) {
          edges {
            node {
              id
              kind
              fieldName
              label
              required
              order
              viewerCanUpdate
              options {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      customTextFields {
        customField {
          id
        }
        value
      }
      customTextareaFields {
        customField {
          id
        }
        value
      }
      customLinkFields {
        customField {
          id
        }
        value
      }
      customDateFields {
        customField {
          id
        }
        value
      }
      customNumberFields {
        customField {
          id
        }
        value
      }
      customCurrencyFields {
        customField {
          id
        }
        value
      }
      customBooleanFields {
        customField {
          id
        }
        value
      }
      customUserSelectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...MaterialAvatar_user
        }
      }
      customUserMultiselectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...MaterialAvatar_user
        }
      }
      customSelectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
      customMultiselectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
    }
  `,
  event: graphql`
    fragment VendorProfile_event on Event {
      id
    }
  `,
  user: graphql`
    fragment VendorProfile_user on User {
      tz
    }
  `,
});
