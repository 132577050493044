/**
 * @flow
 * @relayHash 278f00bdfb21bfc63d4611c5aaa59a75
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReorderFolderInput = {
  clientMutationId?: ?string,
  folderId: string,
  newPosition: number,
};
export type reorderFolderMutationVariables = {|
  input: ReorderFolderInput
|};
export type reorderFolderMutationResponse = {|
  +reorderFolder: ?{|
    +updatedFolders: $ReadOnlyArray<?{|
      +id: string,
      +order: number,
    |}>
  |}
|};
export type reorderFolderMutation = {|
  variables: reorderFolderMutationVariables,
  response: reorderFolderMutationResponse,
|};
*/


/*
mutation reorderFolderMutation(
  $input: ReorderFolderInput!
) {
  reorderFolder(input: $input) {
    updatedFolders {
      id
      order
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReorderFolderInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reorderFolder",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ReorderFolderInput!"
      }
    ],
    "concreteType": "ReorderFolderPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedFolders",
        "storageKey": null,
        "args": null,
        "concreteType": "Folder",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "order",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "reorderFolderMutation",
  "id": null,
  "text": "mutation reorderFolderMutation(\n  $input: ReorderFolderInput!\n) {\n  reorderFolder(input: $input) {\n    updatedFolders {\n      id\n      order\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "reorderFolderMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "reorderFolderMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b77cdfb0b8d636a0bce08d5cbd20af61';
module.exports = node;
