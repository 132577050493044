/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import TextField from '../../../components/TextField';

import { type EventInfoCustomMultiselect_event } from './__generated__/EventInfoCustomMultiselect_event.graphql';

const EventInfoCustomMultiselect = ({
  name,
  event,
  customFieldId,
}: {
  name: string,
  event: EventInfoCustomMultiselect_event,
  customFieldId: string,
}) => {
  const options = event.customMultiselectFields
    .filter(value => value.customField.id === customFieldId)
    .map(value => value.option);

  if (options.length === 0) return null;

  return <TextField name={name} description={options.map(option => option.name).join(', ')} />;
};

export default createFragmentContainer(
  EventInfoCustomMultiselect,
  graphql`
    fragment EventInfoCustomMultiselect_event on Event {
      customMultiselectFields(customFieldIds: $customFieldIds) {
        option {
          id
          name
        }
        customField {
          id
        }
      }
    }
  `,
);
