/* @flow */
import React from 'react';

import Switch from 'components/material/Switch';

export default class Autoplay extends React.PureComponent<{
  value: boolean,
  onChange: (value: ?boolean) => void,
}> {
  handleAutoplayToggle = () => {
    this.props.onChange(!this.props.value);
  };

  render() {
    return <Switch enabled={this.props.value} onChange={this.handleAutoplayToggle} />;
  }
}
