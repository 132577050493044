/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefDownload_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefMenu_event$ref: FragmentReference;
export type BriefMenu_event = {|
  +team: {|
    +primaryColor: string
  |},
  +deliverables: {|
    +totalCount: number
  |},
  +schedules: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +scheduleDays: {|
          +totalCount: number,
          +edges: $ReadOnlyArray<{|
            +node: {|
              +scheduleItemsCount: {|
                +totalCount: number
              |}
            |}
          |}>,
        |},
      |}
    |}>
  |},
  +staffers: {|
    +totalCount: number
  |},
  +briefSettings: {|
    +briefTemplate: {|
      +sections: $ReadOnlyArray<{|
        +name: string,
        +order: number,
        +enabled: boolean,
      |}>
    |},
    +notes: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +attachments: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +contacts: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +companies: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +vendors: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
  |},
  +$fragmentRefs: BriefDownload_event$ref,
  +$refType: BriefMenu_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
],
v2 = [
  {
    "kind": "Variable",
    "name": "isPersonalized",
    "variableName": "personalizeSchedules",
    "type": "Boolean"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  v3
];
return {
  "kind": "Fragment",
  "name": "BriefMenu_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "personalizeSchedules",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BriefDownload_event",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "primaryColor",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "deliverables",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableRequiredConnection",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "schedules",
      "storageKey": null,
      "args": v2,
      "concreteType": "ScheduleRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ScheduleRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Schedule",
              "plural": false,
              "selections": [
                v3,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "scheduleDays",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ScheduleDayRequiredConnection",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ScheduleDayRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ScheduleDay",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": "scheduleItemsCount",
                              "name": "scheduleItems",
                              "storageKey": null,
                              "args": v2,
                              "concreteType": "ScheduleItemRequiredConnection",
                              "plural": false,
                              "selections": v1
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffers",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffMembershipRequiredConnection",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "briefSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "EventBriefTemplate",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "briefTemplate",
          "storageKey": null,
          "args": null,
          "concreteType": "BriefTemplate",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "sections",
              "storageKey": null,
              "args": null,
              "concreteType": "SortableSection",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "enabled",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "notes",
          "storageKey": null,
          "args": null,
          "concreteType": "EventNoteRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "EventNoteRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "EventNote",
                  "plural": false,
                  "selections": v4
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "attachments",
          "storageKey": null,
          "args": null,
          "concreteType": "AttachmentRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "AttachmentRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Attachment",
                  "plural": false,
                  "selections": v4
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contacts",
          "storageKey": null,
          "args": null,
          "concreteType": "ContactRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ContactRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Contact",
                  "plural": false,
                  "selections": v4
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "companies",
          "storageKey": null,
          "args": null,
          "concreteType": "CompanyRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "CompanyRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Company",
                  "plural": false,
                  "selections": v4
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "vendors",
          "storageKey": null,
          "args": null,
          "concreteType": "VendorRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "VendorRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Vendor",
                  "plural": false,
                  "selections": v4
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '357916179b23e2f1fc631c2d9bf28c75';
module.exports = node;
