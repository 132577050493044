/* @flow */
import 'draft-js/dist/Draft.css';

import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  getVisibleSelectionRect,
  Modifier,
} from 'draft-js';

import Avatar from 'components/material/Avatar';
import Button from 'components/material/Button';
import RichTextEmojiOverlay from 'components/RichText/RichTextEmojiOverlay';

import compositeDecorator from './compositeDecorator';
import convertFromString from './convertFromString';
import convertToString from './convertToString';
import MentionsOverlay from './MentionsOverlay';

import type { CommentInput_users } from './__generated__/CommentInput_users.graphql';

const Buttons = styled.div`
  display: flex;
  margin: 10px 0;
  &:empty {
    margin-bottom: 0;
  }
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
`;

const Container = styled.div`
  position: relative;
  margin: 10px 0;
  font-size: 13px;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
  &:last-child {
    padding-bottom: 0;
    ${Buttons} {
      margin-bottom: 0;
    }
  }
`;

export const StyledAvatar = styled(Avatar)`
  position: absolute;
  left: 10px;
  top: 2px;
  pointer-events: none;
`;

export const EditorContainer = styled.div`
  box-shadow: 0 1px 0 ${props => props.theme.secondaryActionColor};
  transition: box-shadow 0.3s;
  color: ${props => props.theme.rowPrimaryTextColor};
  line-height: 1.8;
  ${props =>
    props.focused
      ? css`
          box-shadow: 0 1px 0 ${props.theme.primaryActionColor};
        `
      : css`
          &:hover {
            box-shadow: 0 1px 0 ${props.theme.secondaryActionDarkerColor};
          }
        `};
  padding: 6px 0 6px 45px;
`;

const PrimaryButton = styled(Button)`
  margin-left: 15px;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const ActionIcon = styled.div`
  margin-right: 0;
  padding: 2px 5px;
  color: ${props => props.theme.secondaryActionDarkerColor};
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

class PureCommentInput extends React.PureComponent<
  {
    users: CommentInput_users,
    currentUserId: string,
    onSave: string => ?Promise<any>,
    onCancel?: () => void,
    defaultValue?: string,
    autoFocus?: boolean,
    placeholder?: string,
    replyingTo?: ?string,
    onReplyHandled?: () => void,
    className?: ?string,
    hideEmoji?: boolean,
    avatarSize?: number,
  },
  {
    editorState: any,
    focused: boolean,
    mentionsOverlayShown: boolean,
    emojiOverlayShown: boolean,
    emojiQuery: string,
    mentionsQuery: string,
    overlayFocus: number,
    loading: boolean,
  },
> {
  state = {
    editorState: EditorState.createWithContent(this.initialContentState(), compositeDecorator),
    focused: false,
    mentionsOverlayShown: false,
    emojiOverlayShown: false,
    emojiQuery: '',
    mentionsQuery: '',
    overlayFocus: 0,
    loading: false,
  };

  editor: Editor;

  range: ?{ startOffset: number, endOffset: number };

  overlayPosition: ?{ top: number, left: number };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.editor.focus();
    }
    if (this.props.replyingTo) {
      this.handleReply(this.props.replyingTo);
    }
  }

  componentDidUpdate(prevProps: $PropertyType<PureCommentInput, 'props'>) {
    if (this.props.replyingTo !== prevProps.replyingTo) {
      this.handleReply(this.props.replyingTo);
    }
  }

  handleChange = (editorState: EditorState) => {
    this.handleMentionsOverlayShow(editorState);
    this.handleEmojiOverlayShow(editorState);

    this.setState({ editorState });
  };

  handleFocus = () => this.setState({ focused: true });

  handleBlur = () => this.setState({ focused: false });

  handleClick = () => {
    this.editor.focus();
  };

  handleMentionsOverlayShow = (editorState: EditorState) => {
    const word = this.currentWord(editorState);

    if (word && word.startsWith('@')) {
      const query = word.slice(1);

      this.overlayPosition = getVisibleSelectionRect(window);

      this.setState({ mentionsOverlayShown: true, mentionsQuery: query, overlayFocus: 0 });
    } else if (this.state.mentionsOverlayShown) {
      this.setState({ mentionsOverlayShown: false });
    }
  };

  handleEmojiOverlayShow = (editorState: EditorState) => {
    const word = this.currentWord(editorState);

    if (word && word.startsWith(':')) {
      const query = word.slice(1);

      this.overlayPosition = getVisibleSelectionRect(window);

      this.setState({ emojiOverlayShown: true, emojiQuery: query });
    } else if (this.state.emojiOverlayShown) {
      this.setState({ emojiOverlayShown: false });
    }
  };

  handleSelect = (user: { slug: string, name: string }) => {
    const contentState = this.state.editorState.getCurrentContent();
    let selection = this.state.editorState.getSelection();
    selection = selection.set('anchorOffset', this.range && this.range.startOffset);
    selection = selection.set('focusOffset', this.range && this.range.endOffset);

    const contentStateWithEntity = contentState.createEntity('MENTION', 'IMMUTABLE', {
      slug: user.slug,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newContentState = Modifier.replaceText(
      contentState,
      selection,
      user.name,
      null,
      entityKey,
    );
    this.handleChange(
      EditorState.push(this.state.editorState, newContentState, 'insert-characters'),
    );
  };

  handleSelectEmoji = (emoji: string) => {
    const contentState = this.state.editorState.getCurrentContent();
    let selection = this.state.editorState.getSelection();
    selection = selection.set('anchorOffset', this.range && this.range.startOffset);
    selection = selection.set('focusOffset', this.range && this.range.endOffset);

    const newContentState = Modifier.replaceText(contentState, selection, emoji);
    this.handleChange(
      EditorState.push(this.state.editorState, newContentState, 'insert-characters'),
    );
  };

  handleInsertChar = (char: string, e: SyntheticEvent<>) => {
    e.preventDefault();
    this.editor.focus();

    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const newContentState = Modifier.insertText(contentState, selection, ` ${char}`);

    this.handleChange(EditorState.push(editorState, newContentState, 'insert-characters'));
  };

  handleReply = (userSlug: ?string) => {
    if (!userSlug) return;
    const user = this.props.users.find(u => u.slug === userSlug);
    if (!user) return;

    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const contentStateWithEntity = contentState.createEntity('MENTION', 'IMMUTABLE', {
      slug: user.slug,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const hasText = contentState.hasText();

    let newContentState = Modifier.insertText(contentState, selection, ' ');
    newContentState = Modifier.insertText(
      newContentState,
      selection,
      user.firstName,
      null,
      entityKey,
    );
    if (hasText) newContentState = Modifier.insertText(newContentState, selection, ' ');

    const targetOffset = selection.getAnchorOffset() + user.firstName.length + (hasText ? 2 : 1);

    this.handleChange(
      EditorState.forceSelection(
        EditorState.push(editorState, newContentState, 'insert-characters'),
        selection.merge({
          anchorOffset: targetOffset,
          focusOffset: targetOffset,
        }),
      ),
    );

    setTimeout(() => {
      this.editor.blur();
      this.editor.focus();
    }, 0);

    if (this.props.onReplyHandled) this.props.onReplyHandled();
  };

  handleOverlayFocusChange = (index: number) => {
    this.setState({ overlayFocus: index });
  };

  myKeyBindingFn = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (this.state.mentionsOverlayShown && this.filteredUsers().length > 0) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.setState(state => ({ overlayFocus: state.overlayFocus - 1 }));
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.setState(state => ({ overlayFocus: state.overlayFocus + 1 }));
      }
    }
  };

  handleReturn = (e: SyntheticEvent<HTMLElement>) => {
    const filteredUsers = this.filteredUsers();
    if (this.state.mentionsOverlayShown && filteredUsers.length > 0) {
      const focusedUser = filteredUsers[this.focusedIndex(filteredUsers.length)];

      this.handleSelect({ slug: focusedUser.slug, name: focusedUser.firstName });

      if (e) {
        e.preventDefault();
      }

      return 'handled';
    }

    return 'not-handled';
  };

  handleClear = () => {
    this.handleChange(EditorState.push(this.state.editorState, ContentState.createFromText('')));
  };

  handleSave = () => {
    const string = convertToString(convertToRaw(this.state.editorState.getCurrentContent()));
    if (string.trim()) {
      const saveResult = this.props.onSave(string.trim());

      if (saveResult instanceof Promise) {
        this.setState({ loading: true });
        saveResult.then(() => this.setState({ loading: false }));
      }
    }

    this.handleClear();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }

    this.handleClear();
  };

  filteredUsers = () => {
    if (!this.state.mentionsOverlayShown) return [];

    const queryRegex = new RegExp(`^${this.state.mentionsQuery}`, 'i');

    return this.props.users
      .filter(
        user =>
          queryRegex.test(user.firstName.replace(/\s/g, '')) ||
          queryRegex.test(user.lastName.replace(/\s/g, '')),
      )
      .slice(0, 3);
  };

  initialContentState() {
    return convertFromRaw(
      convertFromString(
        this.props.defaultValue || '',
        this.props.users.map(user => ({ slug: user.slug, name: user.firstName })),
      ),
    );
  }

  currentWord(editorState: EditorState) {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) return null;

    const contentBlock = contentState.getBlockForKey(selection.getStartKey());
    const selectionOffset = selection.getStartOffset();
    const text = contentBlock.getText();

    let startIndex = selectionOffset - 1;
    let endIndex = selectionOffset;
    for (let offset = selectionOffset - 1; ; offset -= 1) {
      const prevChar = text[offset];
      if (!prevChar || !prevChar.trim()) break;
      startIndex = offset;
    }
    for (let offset = selectionOffset; ; offset += 1) {
      const nextChar = text[offset];
      if (!nextChar || !nextChar.trim()) break;
      endIndex = offset;
    }

    const word = text.slice(startIndex, endIndex);
    this.range = { startOffset: startIndex, endOffset: endIndex };

    return word;
  }

  focusedIndex(length: number): number {
    const index = this.state.overlayFocus % length;
    if (this.state.overlayFocus >= 0 || index === 0) {
      return index;
    }
    return length + index;
  }

  render() {
    const currentUser = this.props.users.find(user => this.props.currentUserId === user.id);
    const isFilled = this.state.editorState.getCurrentContent().hasText();

    if (!currentUser) return null;

    const filteredUsers = this.filteredUsers();
    const focus = this.focusedIndex(filteredUsers.length);

    return (
      <Container className={this.props.className}>
        <EditorContainer focused={this.state.focused} onClick={this.handleClick}>
          <StyledAvatar
            profile={currentUser}
            size={this.props.avatarSize != null ? this.props.avatarSize : 13}
          />
          <Editor
            editorState={this.state.editorState}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            keyBindingFn={this.myKeyBindingFn}
            handleReturn={this.handleReturn}
            ref={el => {
              this.editor = el;
            }}
            stripPastedStyles
            placeholder={this.props.placeholder || 'Add a comment'}
          />
        </EditorContainer>

        {this.state.mentionsOverlayShown && this.overlayPosition && (
          <MentionsOverlay
            position={this.overlayPosition}
            users={filteredUsers}
            onFocusChange={this.handleOverlayFocusChange}
            focus={focus}
            query={this.state.mentionsQuery}
            onSelect={this.handleReturn}
          />
        )}
        {!this.props.hideEmoji && this.state.emojiOverlayShown && this.overlayPosition && (
          <RichTextEmojiOverlay
            show
            container={this}
            target={this.editor}
            onHide={() => {}}
            query={this.state.emojiQuery}
            onInsert={this.handleSelectEmoji}
          />
        )}

        <Controls>
          {this.state.focused && (
            <ActionIcon onMouseDown={e => this.handleInsertChar('@', e)}>
              <i className="fa fa-fw fa-at" />
            </ActionIcon>
          )}
          {!this.props.hideEmoji && this.state.focused && (
            <ActionIcon onMouseDown={e => this.handleInsertChar(':', e)}>
              <i className="fa fa-fw fa-smile-o" />
            </ActionIcon>
          )}

          <Buttons>
            {(isFilled || this.props.onCancel) && (
              <Button label="Cancel" minimal onClick={this.handleCancel} />
            )}
            {(this.state.focused || isFilled) && (
              <PrimaryButton
                label={this.props.onCancel ? 'Save' : 'Send'}
                primary
                disabled={!isFilled}
                onClick={this.handleSave}
                loading={this.state.loading}
              />
            )}
          </Buttons>
        </Controls>
      </Container>
    );
  }
}

const RelayCommentInput = createFragmentContainer(
  PureCommentInput,
  graphql`
    fragment CommentInput_users on User @relay(plural: true) {
      id
      slug
      firstName
      lastName
      avatar
    }
  `,
);

export { RelayCommentInput as default, PureCommentInput };
