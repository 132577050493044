/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type UserSelectionOverlay_users$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduleListRow_event$ref: FragmentReference;
export type ScheduleListRow_event = {|
  +id: string,
  +tz: string,
  +slug: string,
  +staffers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +user: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +avatar: ?string,
          +email: string,
          +$fragmentRefs: UserSelectionOverlay_users$ref,
        |}
      |}
    |}>
  |},
  +team: {|
    +users: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +avatar: ?string,
          +email: string,
          +$fragmentRefs: UserSelectionOverlay_users$ref,
        |}
      |}>
    |}
  |},
  +$refType: ScheduleListRow_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "firstName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "avatar",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "email",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "FragmentSpread",
    "name": "UserSelectionOverlay_users",
    "args": null
  }
];
return {
  "kind": "Fragment",
  "name": "ScheduleListRow_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffers",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffMembershipRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffMembershipRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StaffMembership",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": v1
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "users",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "UserRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": v1
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a9c84cb9247ce07b0f473f7a56c09112';
module.exports = node;
