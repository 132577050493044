/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import removeStaffer from 'graph/mutations/access/removeStaffer';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import ManageStaffWindow from 'components/ManageStaffWindow';
import ShareDropdown from 'components/ShareDropdown';

import type { EventActionsColumn_staffedEvent } from './__generated__/EventActionsColumn_staffedEvent.graphql';

const Container = styled.div`
  margin: 0 -5px 0 -24px;
`;

class EventActionsColumn extends React.PureComponent<
  {
    staffedEvent: EventActionsColumn_staffedEvent,
    userId: string,
    currentUserId: string,
    invitedUser: boolean,
  },
  {
    showConfirmation: boolean,
    showManage: boolean,
    loading: boolean,
  },
> {
  state = {
    showConfirmation: false,
    showManage: false,
    loading: false,
  };

  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  handleShowManage = () => {
    this.setState({ showManage: true });
  };

  handleHideManage = () => {
    this.setState({ showManage: false });
  };

  handleRemove = () => {
    const {
      userId,
      staffedEvent: {
        event: { id: eventId },
      },
    } = this.props;
    this.setState({ loading: true });
    removeStaffer({ eventId, userId })
      .catch(error => {
        showModernMutationError(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const currentUser = this.props.userId === this.props.currentUserId;
    const actions = [
      this.props.staffedEvent.viewerCanUpdateAccessLevel
        ? {
            label: 'Manage Access Level',
            icon: 'lock',
            onClick: this.handleShowManage,
          }
        : null,
      this.props.staffedEvent.viewerCanRemove
        ? {
            label: currentUser ? 'Leave from Staff' : 'Remove from Staff',
            icon: currentUser ? 'sign-out' : 'times',
            onClick: this.handleShowConfirmation,
          }
        : null,
    ].filter(Boolean);

    if (actions.length === 0) {
      return null;
    }

    return (
      <Container onClick={this.stopPropagation}>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={actions}
          overlayContainer={document.body}
        />

        {this.state.showConfirmation &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              actionLabel="Remove"
              onHide={this.handleHideConfirmation}
              onConfirm={this.handleRemove}
              message={`${
                currentUser ? 'You' : 'They'
              } will be removed from Tasks, Schedules and other parts of this Event.`}
              loading={this.state.loading}
            />,
            document.body,
          )}
        {this.state.showManage &&
          document.body &&
          createPortal(
            <ManageStaffWindow
              onHide={this.handleHideManage}
              accessLevel={this.props.staffedEvent.accessLevel}
              userId={this.props.userId}
              eventId={this.props.staffedEvent.event.id}
              invitedUser={this.props.invitedUser}
            />,
            document.body,
          )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  EventActionsColumn,
  graphql`
    fragment EventActionsColumn_staffedEvent on StaffMembership {
      id
      viewerCanUpdateAccessLevel
      viewerCanRemove
      accessLevel
      event {
        id
      }
    }
  `,
);
