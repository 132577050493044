/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';

import { type CellPropsType } from 'components/budget/Table';
import InlineTextField from 'components/budget/Table/InlineTextField';

import { type VendorExpenseCellPropsType } from './index';

import { type VendorExpenseName_expense } from './__generated__/VendorExpenseName_expense.graphql';

const preventDefault = (e: SyntheticEvent<HTMLElement>) => {
  e.preventDefault();
};

const VendorExpenseName = ({
  expense,
  updateExpense,
  updateColumnWidth,
  location,
}: CellPropsType<VendorExpenseCellPropsType<VendorExpenseName_expense>>) => {
  return (
    <InlineTextField
      value={expense.name}
      onChange={name => {
        updateExpense({ name });

        updateColumnWidth();
      }}
    >
      <Link
        to={{
          pathname: `/events/${expense.event.slug}/budget/${expense.id}`,
          state: {
            from: 'vendor_expenses',
            fromUrl: location.pathname,
          },
        }}
        onMouseDown={preventDefault}
      >
        {expense.name}
      </Link>
    </InlineTextField>
  );
};

export default createFragmentContainer(
  VendorExpenseName,
  graphql`
    fragment VendorExpenseName_expense on Expense {
      id
      name
      event {
        slug
      }
    }
  `,
);
