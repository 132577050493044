/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import COUNTRIES from 'config/countries.json';

import type { FieldType } from 'utils/customization/types';

import type { CompanyFieldInput } from 'graph/mutations/company/updateCompany';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import CompanyCountry_company from './__generated__/CompanyCountry_company.graphql';

const countryOptions = COUNTRIES.map(country => ({ label: country, value: country }));

class CompanyCountry extends React.PureComponent<{
  company: CompanyCountry_company,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<CompanyFieldInput>) => void,
}> {
  handleCountryChange = (country: ?string) => {
    this.props.onUpdate({ country });
    this.props.updateColumnWidth();
  };

  getError = (): ?string => {
    const { fieldSettings, company } = this.props;
    if (fieldSettings.required && company.country == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { fieldSettings, company, updateColumnWidth } = this.props;

    if (!company) {
      return null;
    }

    return (
      <InlineSelectField
        placeholder={fieldSettings.label}
        options={countryOptions}
        value={company.country}
        onChange={this.handleCountryChange}
        getError={this.getError}
        clearable={!fieldSettings.required}
        updateColumnWidth={updateColumnWidth}
        searchable
      />
    );
  }
}

export default createFragmentContainer(
  CompanyCountry,
  graphql`
    fragment CompanyCountry_company on Company {
      country
    }
  `,
);
