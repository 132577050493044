/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import formatPercentage from 'utils/number/formatPercentage';

import { type ReportingCellPropsType } from './index';

const Container = styled.span`
  ${props =>
    props.exceeded &&
    css`
      color: #f25d60;
    `};
`;

const ReportingPlannedPercentCell = ({ group, childGroup }: ReportingCellPropsType) => {
  const currentGroup = childGroup == null ? group : childGroup;

  if (group.budgetedAmount == null) return null;

  const ratio = currentGroup.actualAmount / group.budgetedAmount;

  return <Container exceeded={ratio > 1}>{formatPercentage(ratio)}</Container>;
};

export default ReportingPlannedPercentCell;
