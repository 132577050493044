/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type Match, type RouterHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import removeExpense from 'graph/mutations/expense/removeExpense';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Breadcrumb from 'components/Breadcrumb';
import ConfirmationWindow from 'components/ConfirmationWindow';
import ShareDropdown from 'components/ShareDropdown';

import type { ExpenseFormHeader_expense } from './__generated__/ExpenseFormHeader_expense.graphql';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const DropdownContainer = styled.div`
  height: 24px;
`;

class ExpenseFormHeader extends React.Component<
  {
    expense: ExpenseFormHeader_expense,
    match: Match,
    history: RouterHistory,
    location: Location,
  },
  {
    confirmation: boolean,
  },
> {
  state = {
    confirmation: false,
  };

  handleRemove = () => {
    this.props.history.push(`/events/${this.props.match.params.event_slug || ''}/budget`);
  };

  handleExpenseRemove = () => {
    this.handleRemove();
    removeExpense({ expenseId: this.props.expense.id, eventId: this.props.expense.event.id }).catch(
      showModernMutationError,
    );
  };

  handleConfirmationHide = () => {
    this.setState({ confirmation: false });
  };

  handleConfirmationShow = () => {
    this.setState({ confirmation: true });
  };

  render() {
    return (
      <Header>
        <Breadcrumb
          path={[
            {
              label: 'Budget',
              path:
                ((this.props.location.state && this.props.location.state.referrer) || '').replace(
                  /^(.*?)\/[^/]+/,
                  '',
                ) || `/events/${this.props.match.params.event_slug || ''}/budget`,
            },
            { label: this.props.expense.name },
          ]}
        />
        <DropdownContainer>
          <ShareDropdown
            noBorder
            options={[{ label: 'Delete', icon: 'trash', onClick: this.handleConfirmationShow }]}
          />
          {this.state.confirmation && (
            <ConfirmationWindow
              onHide={this.handleConfirmationHide}
              onConfirm={this.handleExpenseRemove}
              title="Are you sure?"
              message="Once you delete this expense, it's gone for good."
            />
          )}
        </DropdownContainer>
      </Header>
    );
  }
}

export default createFragmentContainer(withRouter(ExpenseFormHeader), {
  expense: graphql`
    fragment ExpenseFormHeader_expense on Expense {
      id
      name
      event {
        id
      }
    }
  `,
});
