/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import moment from 'moment';

import currentOrigin from 'utils/currentOrigin';

import AddToCalendarIcon from 'images/addToCalendar.svg';
import ExportIcon from 'images/events/export.svg';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ExternalCalendarExport from 'components/ExternalCalendarExport';
import Search from 'components/Search';
import type { ParsedTaskFilters } from 'views/Main/Event/Tasks/components/parseTaskFilters';
import TaskFilters from 'views/Main/Event/Tasks/components/TaskFilters';

import headerTitles from '../../lib/headerTitles';

import type { MyTasksHeaderBar_me } from './__generated__/MyTasksHeaderBar_me.graphql';
import type { MyTasksHeaderBar_org } from './__generated__/MyTasksHeaderBar_org.graphql';
import type { MyTasksHeaderBarQueryResponse } from './__generated__/MyTasksHeaderBarQuery.graphql';

const Container = styled.div`
  padding-top: 25px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &:first-of-type {
    margin-bottom: 21px;
  }
  @media (min-width: 800px) and (max-width: 1085px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  margin-right: auto;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const RowItem = styled.div`
  flex: 1;
  min-height: 31px;
  margin-bottom: 13px;

  &:first-of-type {
    display: flex;
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 13px;
  line-height: 13px;
  color: #4a5665;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  svg {
    margin-right: 10px;
  }

  ${props =>
    props.active
      ? css`
          background: rgba(255, 223, 109, 0.38);

          &:hover,
          &:focus {
            background: rgba(255, 223, 109, 0.48);
          }
        `
      : css`
          &:hover,
          &:focus {
            background: #f3f3f3;
          }
        `}
`;

const TableActions = styled.div`
  display: flex;
  flex: 0 auto;
  margin-right: 5px;
`;

const StyledSearch = styled(Search)`
  max-width: 250px;
  margin-right: 10px;
`;

const query = graphql`
  query MyTasksHeaderBarQuery($filters: DeliverableFilters!) {
    me {
      email
      myTasksReportCSVProcessId(filters: $filters)
    }
  }
`;

class MyTasksHeaderBar extends React.PureComponent<
  {
    search: ?string,
    pathPrefix: string,
    filterInputs: ParsedTaskFilters,
    onSearch: (query: string) => void,
    org: MyTasksHeaderBar_org,
    me: MyTasksHeaderBar_me,
    filters: ParsedTaskFilters,
    history: RouterHistory,
  },
  {
    showCsvDownloadOverlay: boolean,
    showCalendarExportWindow: boolean,
  },
> {
  state = {
    showCsvDownloadOverlay: false,
    showCalendarExportWindow: false,
  };

  handleCsvExport = () => {
    this.setState({ showCsvDownloadOverlay: true });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCsvDownloadOverlay: false });
  };

  handleShowAddCalendarWindow = () => {
    this.setState({ showCalendarExportWindow: true });
  };

  handleHideAddCalendarWindow = () => {
    this.setState({ showCalendarExportWindow: false });
  };

  renderCalendarExportWindow = () => {
    const icsUrl = `${currentOrigin()}/v1/export/user_tasks/${this.props.me.icsToken}`;
    return (
      <ExternalCalendarExport
        listTitle="My tasks"
        icsUrl={icsUrl}
        onHide={this.handleHideAddCalendarWindow}
      />
    );
  };

  renderDownloadOverlay = () => {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ filters: this.props.filterInputs }}
        renderSuccess={(response: MyTasksHeaderBarQueryResponse) => {
          return (
            <DownloadOverlayWithEmail
              processUUID={response.me.myTasksReportCSVProcessId}
              fileName={`My Tasks Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleCsvDownloadEnd}
              email={response.me.email}
              exportable="my_tasks_csv"
              onDownload={this.handleCsvExport}
            />
          );
        }}
        renderLoading={() => null}
      />
    );
  };

  render() {
    const { search, onSearch, pathPrefix, org, me, filters, history } = this.props;
    const { showCalendarExportWindow, showCsvDownloadOverlay } = this.state;

    const title = `${headerTitles[pathPrefix.split('/').pop()]}`;
    return (
      <Container>
        <Row>
          <Title>{title}</Title>
          <ActionButton onClick={this.handleShowAddCalendarWindow}>
            <AddToCalendarIcon />
            Add to calendar
          </ActionButton>
          <ActionButton onClick={this.handleCsvExport}>
            <ExportIcon />
            Export
          </ActionButton>
          {showCalendarExportWindow && this.renderCalendarExportWindow()}
          {showCsvDownloadOverlay && this.renderDownloadOverlay()}
        </Row>
        <Row>
          <RowItem>
            <StyledSearch onSearch={onSearch} search={search} placeholder="Search tasks" />
          </RowItem>
          <RowItem>
            <TableActions>
              <TaskFilters
                viewMode="table"
                filters={filters}
                tags={org.tags.edges.map(edge => edge.node)}
                teams={me.teams.edges.map(edge => edge.node)}
                history={history}
              />
            </TableActions>
          </RowItem>
        </Row>
      </Container>
    );
  }
}

export default createFragmentContainer(MyTasksHeaderBar, {
  org: graphql`
    fragment MyTasksHeaderBar_org on Org {
      tags {
        edges {
          node {
            ...TaskFilters_tags
          }
        }
      }
    }
  `,
  me: graphql`
    fragment MyTasksHeaderBar_me on User {
      icsToken
      teams {
        edges {
          node {
            ...TaskFilters_teams
          }
        }
      }
    }
  `,
});
