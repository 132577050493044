/* @flow */
import moment from 'moment-timezone';

const FILTER_DATE_FORMAT = 'YYYY/MM/DD';

function parseDate(str) {
  const now = moment().startOf('day');
  const shorthands = {
    next30days: () => [now, now.clone().add(30, 'day')],
    next60days: () => [now, now.clone().add(60, 'day')],
    next90days: () => [now, now.clone().add(90, 'day')],
  };

  if (shorthands[str]) {
    const [start, end] = shorthands[str]();
    return { key: str, start, end };
  }

  const dates = str.split('-');
  if (dates.length === 2) {
    const startDate = moment(dates[0], FILTER_DATE_FORMAT);
    const endDate = moment(dates[1], FILTER_DATE_FORMAT);

    if (startDate.isValid() && endDate.isValid() && startDate.isSameOrBefore(endDate)) {
      return { key: str, start: startDate, end: endDate };
    }
  }

  return null;
}

function parseValue(rule, str) {
  if (Array.isArray(rule)) {
    return rule.includes(str) ? str : null;
  }
  if (rule === Number) {
    return +str;
  }
  if (rule === String) {
    return str;
  }
  if (rule === Boolean) {
    return str === 'true';
  }
  if (rule === Date) {
    return parseDate(str);
  }
  return null;
}

function filterFunc(result) {
  if (typeof result === 'string' && result.length === 0) return false;
  if (Number.isNaN(result)) return false;
  return result != null;
}

// NOTE: flow types cannot improved any further because types are cast at runtime
export default function parseFilters(params: {}, schema: {}) {
  const filters = {};

  Object.keys(schema).forEach(key => {
    const rule = schema[key];
    const value = params[key] && params[key].replace(/\s/g, '');

    if (!value) return;

    if (Array.isArray(rule) && rule.length < 2) {
      // Array of filters
      const values = value.split(',');

      filters[key] = values.map(str => parseValue(rule[0], str)).filter(filterFunc);
    } else {
      const parsedValue = parseValue(rule, value);

      if (filterFunc(parsedValue)) {
        filters[key] = parsedValue;
      }
    }
  });

  return filters;
}

export { FILTER_DATE_FORMAT };
