/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { EditContent } from 'components/budget/Table/InlineEditableCell';
import InlineUserSelectField from 'components/budget/Table/InlineUserSelectField';
import EmptyLink from 'components/material/table/EmptyLink';
import { Container as UserSelectInputContainer, Header } from 'components/material/UserSelectInput';
import UsersGroup from 'components/material/UsersGroup';

import type { TaskRowAssignees_assignees } from './__generated__/TaskRowAssignees_assignees.graphql';

const Container = styled.div`
  position: relative;
  flex-basis: 100px;
  min-height: 20px;
  @media (${props => props.theme.mobileOnly}) {
    flex-basis: auto;
    flex-shrink: 0;
    margin-left: 10px;
  }

  ${Header} {
    padding: 0 1px;
  }
  ${UserSelectInputContainer}, ${EditContent} {
    background-color: transparent;
  }

  > div > div:first-child {
    border: 0;
  }
`;

class TaskRowAssignees extends React.PureComponent<
  {
    eventId: string,
    assignees: TaskRowAssignees_assignees,
    onAssign: string => void,
    onUnassign: string => void,
    disabled?: boolean,
    onInviteClick: () => void,
    className?: string,
    maxCount?: number,
    avatarRadius?: number,
  },
  { overlayShown: boolean },
> {
  handleClick = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
  };

  render() {
    const {
      eventId,
      assignees,
      disabled,
      className,
      avatarRadius,
      maxCount,
      onInviteClick,
      onAssign,
      onUnassign,
    } = this.props;

    return (
      <Container onClick={this.handleClick} className={className}>
        <InlineUserSelectField
          activeUsers={assignees}
          onSelect={user => {
            if (user) onAssign(user.id);
          }}
          onUnselect={user => onUnassign(user.id)}
          disabled={disabled}
          hideLockIcon
          isMultiselect
          eventId={eventId}
          onInviteClick={onInviteClick}
        >
          {assignees.length > 0 ? (
            <UsersGroup
              users={assignees}
              maxCount={maxCount}
              avatarRadius={avatarRadius != null ? avatarRadius : 13}
            />
          ) : (
            !this.props.disabled && <EmptyLink label="Assign" icon="user" onClick={() => {}} />
          )}
        </InlineUserSelectField>
      </Container>
    );
  }
}

export default createFragmentContainer(TaskRowAssignees, {
  assignees: graphql`
    fragment TaskRowAssignees_assignees on User @relay(plural: true) {
      id
      firstName
      lastName
      email
      ...UsersGroup_users
    }
  `,
});
