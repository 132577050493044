/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';

import type { FieldType } from 'utils/customization/types';

import getEventFilterConverters from 'components/AllEvents/lib/getEventFilterConverters';
import renderEventFilter from 'components/AllEvents/lib/renderEventFilter';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type { FilterGroup } from 'components/material/ColumnFilters/columnFilterTypes';
import AdvancedFilterWrapper from 'components/material/Filters/Advanced/AdvancedFilterWrapper';
import { type FilterChangeType } from 'components/material/Filters/Advanced/FilterItems';
import FiltersContent from 'components/material/Filters/Advanced/FiltersContent';

import type { ParsedBudgetReportingFilters } from '../lib/parseBudgetReportingFilters';

import type { BudgetReportingFiltersQueryResponse } from './__generated__/BudgetReportingFiltersQuery.graphql';

const query = graphql`
  query BudgetReportingFiltersQuery {
    me {
      teams {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    org {
      settings {
        fiscalYearStart
      }
      eventRequestForms {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export default class BudgetReportingFilters extends React.Component<{
  history: RouterHistory,
  filters: ParsedBudgetReportingFilters,
  filterOptions: $ReadOnlyArray<FieldType>,
  groups: $ReadOnlyArray<FilterGroup>,
  onFiltersChanged: () => void,
  mobileBreakpoint?: number,
}> {
  filterRenderer = (
    props: BudgetReportingFiltersQueryResponse,
    field: FieldType,
    filters: ParsedBudgetReportingFilters,
    onFilterChange: FilterChangeType,
  ) => {
    const fiscalYearStart = props.org.settings.fiscalYearStart;

    const {
      group1,
      group2,
      customGroup1,
      customGroup2,
      budgetCategoryIds,
      ...eventFilters
    } = filters;

    return renderEventFilter(field, eventFilters, onFilterChange, {
      teams: props.me.teams.edges,
      eventRequestForms: props.org.eventRequestForms.edges,
      fiscalYearStart,
      showNameFilter: true,
      isBudgetFilters: true,
    });
  };

  render() {
    const {
      filters,
      history,
      groups,
      filterOptions,
      mobileBreakpoint,
      onFiltersChanged,
    } = this.props;

    const { requesterUserIds, requesterContactIds, ...otherFilters } = filters;
    const requesterFilters = [...(requesterUserIds || []), ...(requesterContactIds || [])];
    const count =
      Object.keys(otherFilters).filter(
        x =>
          !['sort', 'group1', 'group2', 'customGroup1', 'customGroup2'].includes(x) &&
          otherFilters[x] != null,
      ).length + (requesterFilters.length > 0 ? 1 : 0);

    return (
      <AdvancedFilterWrapper count={count} filters={filters} mobileBreakpoint={mobileBreakpoint}>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(props: BudgetReportingFiltersQueryResponse) => (
            <FiltersContent
              history={history}
              filters={filters}
              filterOptions={filterOptions}
              groups={groups}
              filterRenderer={(...args) => this.filterRenderer(props, ...args)}
              converterFn={getEventFilterConverters}
              onFiltersChanged={onFiltersChanged}
            />
          )}
        />
      </AdvancedFilterWrapper>
    );
  }
}
