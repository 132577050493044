/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import ActiveTrialMessage from './ActiveTrialMessage';
import ExpiredTrialMessage from './ExpiredTrialMessage';

import type { BillingTrialMessage_subscription } from './__generated__/BillingTrialMessage_subscription.graphql';

type Props = { subscription: BillingTrialMessage_subscription };

const BillingTrialMessage = ({ subscription }: Props) => (
  <div>
    {subscription.upgradeRequired ? (
      <ExpiredTrialMessage />
    ) : (
      <ActiveTrialMessage expiresAt={subscription.expiresAt} />
    )}
  </div>
);

export default createFragmentContainer(
  BillingTrialMessage,
  graphql`
    fragment BillingTrialMessage_subscription on Subscription {
      expiresAt
      upgradeRequired
    }
  `,
);
