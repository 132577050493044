/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation updateCommentMutation($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      comment {
        id
        content
      }
    }
  }
`;

export default function updateComment(commentId: string, content: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        commentId,
        content,
      },
    },
    optimisticResponse: {
      updateComment: {
        comment: {
          id: commentId,
          content,
        },
      },
    },
  });
}
