/**
 * @flow
 * @relayHash f9b621f12b774270ed1559ca9036232c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NoteEdit_note$ref = any;
export type EventNoteQueryVariables = {|
  eventNoteId: string
|};
export type EventNoteQueryResponse = {|
  +eventNote: ?{|
    +id?: string,
    +name?: string,
    +$fragmentRefs: NoteEdit_note$ref,
  |}
|};
export type EventNoteQuery = {|
  variables: EventNoteQueryVariables,
  response: EventNoteQueryResponse,
|};
*/


/*
query EventNoteQuery(
  $eventNoteId: ID!
) {
  eventNote: node(id: $eventNoteId) {
    __typename
    ... on EventNote {
      id
      name
      ...NoteEdit_note
    }
    id
  }
}

fragment NoteEdit_note on EventNote {
  id
  name
  content
  includedInBrief
  viewerCanDelete
  viewerCanUpdate
  attachments {
    edges {
      node {
        id
        fileurl
        filename
        filetype
        filesize
      }
    }
  }
  ...NoteForm_eventNote
}

fragment NoteForm_eventNote on EventNote {
  id
  createdAt
  updatedAt
  creator {
    firstName
    lastName
    email
    id
  }
  updater {
    firstName
    lastName
    email
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventNoteId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventNoteId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "firstName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "email",
    "args": null,
    "storageKey": null
  },
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventNoteQuery",
  "id": null,
  "text": "query EventNoteQuery(\n  $eventNoteId: ID!\n) {\n  eventNote: node(id: $eventNoteId) {\n    __typename\n    ... on EventNote {\n      id\n      name\n      ...NoteEdit_note\n    }\n    id\n  }\n}\n\nfragment NoteEdit_note on EventNote {\n  id\n  name\n  content\n  includedInBrief\n  viewerCanDelete\n  viewerCanUpdate\n  attachments {\n    edges {\n      node {\n        id\n        fileurl\n        filename\n        filetype\n        filesize\n      }\n    }\n  }\n  ...NoteForm_eventNote\n}\n\nfragment NoteForm_eventNote on EventNote {\n  id\n  createdAt\n  updatedAt\n  creator {\n    firstName\n    lastName\n    email\n    id\n  }\n  updater {\n    firstName\n    lastName\n    email\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventNoteQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "eventNote",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "EventNote",
            "selections": [
              v2,
              v3,
              {
                "kind": "FragmentSpread",
                "name": "NoteEdit_note",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventNoteQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "eventNote",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "InlineFragment",
            "type": "EventNote",
            "selections": [
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "content",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "includedInBrief",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "viewerCanDelete",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "viewerCanUpdate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "attachments",
                "storageKey": null,
                "args": null,
                "concreteType": "AttachmentRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AttachmentRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Attachment",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fileurl",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "filename",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "filetype",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "filesize",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "creator",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": v4
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "updater",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": v4
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d9fdf3a9d641927ccf444fa2e563663';
module.exports = node;
