/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefDownloadOptionSelectOverlay_me$ref: FragmentReference;
export type BriefDownloadOptionSelectOverlay_me = {|
  +email: string,
  +$refType: BriefDownloadOptionSelectOverlay_me$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BriefDownloadOptionSelectOverlay_me",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '76bd1618395dc9ac3c64eed901bf7483';
module.exports = node;
