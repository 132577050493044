/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDateRange from 'utils/date/formatDateRange';
import { getFiscalYear, getQuarter } from 'utils/fiscal';
import { FILTER_DATE_FORMAT } from 'utils/parseFilters';
import { type DateRangeParam, type NumberRangeParam } from 'utils/routing/parseTypedQueryString';

import Chart from 'components/budget/Chart';
import Dropdown from 'components/budget/Dropdown';
import { type DatesConfig } from 'components/date/DateTimeRangePicker';
import DateTimeRangeOverlay from 'components/date/DateTimeRangePicker/DateTimeRangeOverlay';

import { type BudgetReportType } from './BudgetReportingGroupContainer';
import parseGroupInfo from './lib/parseGroupInfo';
import type { GroupingOptionType } from './lib/reportGroupingOptions';

const Root = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05);
`;

const DropdownContainer = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
`;

const DropdownLabel = styled.div`
  margin-right: 10px;
  font-size: 13px;
  color: #888888;
`;

export default class BudgetReportingChart extends React.PureComponent<
  {
    container: ?HTMLElement,
    currency: string,
    group1: ?GroupingOptionType,
    group2: ?GroupingOptionType,
    customGroup1: ?string,
    customGroup2: ?string,
    budgetReport: BudgetReportType,
    fiscalYearStart: number,
    dateFilter: ?DateRangeParam,
    onFilterChange: (filterParams: {
      [string]: ?(string | ?$ReadOnlyArray<string> | boolean | NumberRangeParam | DateRangeParam),
    }) => void,
  },
  {
    customStartDate: ?string,
    customEndDate: ?string,
    showCustomDateOverlay: boolean,
  },
> {
  state = {
    customStartDate: null,
    customEndDate: null,
    showCustomDateOverlay: false,
  };

  dropdownRef = React.createRef();

  // To prevent too much jumping when loading
  componentDidMount() {
    if (this.props.container) {
      this.props.container.style.height = '';
    }
  }

  componentWillUnmount() {
    if (this.props.container) {
      const height = this.props.container.offsetHeight + 20; // 20px is the margin
      this.props.container.style.height = `${height}px`;
    }
  }

  generateBudgetPeriodOptions = () => [
    ...[-2, -1, 0, 1, 2].map(quarterIndex => {
      const quarter = getQuarter(quarterIndex, this.props.fiscalYearStart);
      return { value: quarter.key, label: quarter.label };
    }),
    ...[-1, 0, 1].map(quarterIndex => {
      const year = getFiscalYear(quarterIndex, this.props.fiscalYearStart);
      return { value: year.key, label: year.label };
    }),
  ];

  handleChangeDateFilter = (date: ?string) => {
    if (date === 'custom') {
      this.setState({ showCustomDateOverlay: true });
    } else {
      this.props.onFilterChange({ date });
    }
  };

  handleHideCustomDateOverlay = () => {
    this.setState({ showCustomDateOverlay: false, customStartDate: null, customEndDate: null });

    const { customStartDate, customEndDate } = this.state;

    if (!customStartDate) return;

    this.props.onFilterChange({
      date: `${moment(customStartDate).format(FILTER_DATE_FORMAT)}-${moment(
        customEndDate || customStartDate,
      ).format(FILTER_DATE_FORMAT)}`,
    });
  };

  handleChangeCustomPeriodDates = (datesConfig: DatesConfig) => {
    this.setState(
      { customStartDate: datesConfig.startDate, customEndDate: datesConfig.endDate },
      () => {
        if (datesConfig.endDate) {
          this.handleHideCustomDateOverlay();
        }
      },
    );
  };

  render() {
    const {
      container,
      currency,
      budgetReport,
      dateFilter,
      group1,
      group2,
      customGroup1,
      customGroup2,
    } = this.props;
    const { customStartDate, customEndDate } = this.state;

    if (!container) return null;

    const budgetPeriodOptions = this.generateBudgetPeriodOptions();
    const activeCustomBudgetPeriod =
      !!dateFilter && budgetPeriodOptions.every(period => period.value !== dateFilter.key);
    const data = budgetReport.groups.map(group => ({
      label: parseGroupInfo(group.name, group1 || customGroup1).groupName,
      budgetedAmount: group.budgetedAmount,
      plannedAmount: group.plannedAmount,
      actualAmount: group.actualAmount,
      paidAmount: group.paidAmount,
      data:
        group.childGroups && (group2 != null || customGroup2 != null)
          ? group.childGroups.map(childGroup => ({
              label: parseGroupInfo(childGroup.name, group2 || customGroup2).groupName,
              plannedAmount: childGroup.plannedAmount,
              actualAmount: childGroup.actualAmount,
              paidAmount: childGroup.paidAmount,
            }))
          : undefined,
    }));

    return createPortal(
      <Root>
        <DropdownContainer ref={this.dropdownRef}>
          <DropdownLabel>Budget Period</DropdownLabel>

          <Dropdown
            value={dateFilter && dateFilter.key}
            options={[
              ...budgetPeriodOptions,
              ...(activeCustomBudgetPeriod && dateFilter
                ? [
                    {
                      value: dateFilter.key || 'custom',
                      label: formatDateRange(
                        dateFilter.start,
                        moment(dateFilter.end).isSame(dateFilter.start, 'date')
                          ? null
                          : dateFilter.end,
                      ),
                    },
                  ]
                : []),
              { value: 'custom', label: 'Custom' },
            ]}
            onChange={this.handleChangeDateFilter}
            clearable
          />
        </DropdownContainer>

        <DateTimeRangeOverlay
          tz={moment.tz.guess()}
          container={this.dropdownRef.current}
          target={this.dropdownRef.current}
          show={this.state.showCustomDateOverlay}
          onHide={this.handleHideCustomDateOverlay}
          startDate={customStartDate}
          endDate={customEndDate}
          hideStartTime
          hideEndTime
          datesOnly
          onChange={this.handleChangeCustomPeriodDates}
        />

        <Chart currency={currency} data={data} />
      </Root>,
      container,
    );
  }
}
