/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OpportunityOwner_opportunity$ref: FragmentReference;
export type OpportunityOwner_opportunity = {|
  +owner: {|
    +firstName: string,
    +lastName: string,
    +email: string,
    +$fragmentRefs: MaterialAvatar_user$ref,
  |},
  +$refType: OpportunityOwner_opportunity$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "OpportunityOwner_opportunity",
  "type": "SalesforceOpportunityType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "owner",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "email",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "MaterialAvatar_user",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '671ad4521ac07f738dbbf80c1d728aa8';
module.exports = node;
