/* @flow */
import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';

const Input = styled.input`
  border: 0 solid;
  border-bottom: 1px solid;
  border-color: ${props => props.theme.borderColor};
  ${props =>
    props.disabled &&
    css`
      border-style: dashed;
    `};
  transition: border 0.3s;
  color: ${props => (props.disabled ? props.theme.labelColor : props.theme.rowPrimaryTextColor)};
  padding: 5px 0;
  width: 100%;
  resize: none;
  line-height: 1.8;
  ${props =>
    !props.disabled &&
    css`
      &:hover {
        border-color: ${props.theme.secondaryActionDarkerColor};
      }
    `};
  &:focus {
    outline: 0;
    border-color: ${props => props.theme.primaryActionColor};
  }
  &::placeholder {
    font-size: 13px;
    font-weight: normal;
    color: ${props => (props.error ? props.theme.negativeActionColor : 'rgba(62, 72, 89, 0.59)')};
  }
`;

export default class TextInput extends React.PureComponent<{
  id?: string,
  defaultValue?: ?string | ?number,
  value?: string,
  onChange?: (SyntheticInputEvent<HTMLInputElement>) => void,
  onFocus?: (SyntheticFocusEvent<HTMLInputElement>) => void,
  onBlur?: (SyntheticFocusEvent<HTMLInputElement>) => void,
  onClick?: (SyntheticEvent<HTMLInputElement>) => void,
  onKeyDown?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  className?: string,
  autoFocus?: boolean,
  placeholder?: string,
  multiline?: boolean,
  maxLength?: number,
  name?: string,
  inputRef?: $Call<React.createRef<any>>,
  readOnly?: boolean,
  disabled?: boolean,
  style?: ?{},
  type?: string,
  prefix?: string,
  error?: ?string | ?boolean,
}> {
  static defaultProps = {
    type: 'text',
  };

  handleScroll = (e: SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  getValue = () => {
    const { prefix, value } = this.props;
    if (prefix) {
      return value && value === `${prefix}` ? `${value} ` : `${prefix} ${value || ''}`;
    }
    return value;
  };

  render() {
    const value = this.getValue();

    return (
      <Input
        as={this.props.multiline ? TextareaAutosize : undefined}
        id={this.props.id}
        type={this.props.type}
        defaultValue={this.props.defaultValue}
        value={value}
        onScroll={this.handleScroll}
        onChange={this.props.onChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onKeyDown={this.props.onKeyDown}
        maxLength={this.props.maxLength}
        onClick={this.props.onClick}
        className={this.props.className}
        autoFocus={this.props.autoFocus}
        placeholder={this.props.placeholder}
        name={this.props.name}
        autoComplete="off"
        ref={this.props.multiline ? undefined : this.props.inputRef}
        inputRef={this.props.inputRef}
        readOnly={this.props.readOnly}
        style={this.props.style}
        disabled={this.props.disabled}
        error={this.props.error}
      />
    );
  }
}
