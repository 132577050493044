/* @flow */

import React from 'react';
import styled from 'styled-components';

const Phone = styled.a`
  display: block;
  margin-top: 3px;
  color: #3eaada;
`;

const BriefUserPhone = (props: { phone: ?string }) => {
  const { phone } = props;
  return phone ? <Phone href={`tel:${phone}`}>{phone}</Phone> : null;
};

export default BriefUserPhone;
