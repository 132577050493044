/* @flow */
import React from 'react';
import styled from 'styled-components';
import difference from 'lodash/difference';

import AutocompleteInput from 'components/material/AutocompleteInput';

const FiscalRow = styled.div`
  padding: 5px 10px;
  font-size: 13px;
  color: #828b93;
`;

const prefix = 'FY';
const defaultOptionsCount = 2;

const digits = [...Array(10).keys()].map(i => i);
const hundreds = [...Array(100).keys()].map(i => i);
const thousands = [...Array(1000).keys()].map(i => i);
const ninetees = hundreds.map(i => i + 1900);
const milleniumYears = thousands.map(i => i + 2000);

const formatYearOption = (year: ?number) => {
  return year != null ? `${prefix} ${year}` : '';
};

const renderYearOption = (year: ?number) => {
  return <FiscalRow>{year != null ? `${prefix} ${year}` : ''}</FiscalRow>;
};

// getting missing years if any for [2011, 2013, 2014] missing year 2012
const getMissingYearsSuggestions = (usedYears: $ReadOnlyArray<number>): $ReadOnlyArray<number> => {
  return usedYears.reduce((options, current, index) => {
    if (options.length === defaultOptionsCount) {
      return options;
    }
    if (index + 1 !== usedYears.length && current + 1 !== usedYears[index + 1]) {
      return [...options, current + 1];
    }
    return options;
  }, []);
};

const getPastYearsSuggestions = (earliestYear: number, filledCount: number) => {
  return [...Array(defaultOptionsCount - filledCount).keys()].map(k => earliestYear - k - 1);
};

const getFutureYearsSuggestions = (mostFutureYear: number) => {
  return [...Array(defaultOptionsCount).keys()].map(k => mostFutureYear + k + 1);
};

const getFilterOptions = (usedYears: $ReadOnlyArray<number>): $ReadOnlyArray<number> => {
  const yearsLength = usedYears.length;
  const missingYearsSuggestions = getMissingYearsSuggestions(usedYears);
  return [
    ...getPastYearsSuggestions(usedYears[0], missingYearsSuggestions.length),
    ...missingYearsSuggestions,
    ...getFutureYearsSuggestions(usedYears[yearsLength - 1]),
  ].sort();
};

/*
 * getting autocomplete for 1 | 19 ..
 * at this point query length is in range [1-4]
 */
const getThousands = (query: string) => {
  const { length } = query;
  if (length === 1 || length === 2) {
    return ninetees;
  }

  if (query[1] !== '9') {
    return [];
  }

  if (length === 3) {
    return digits.map(d => parseInt(`${query}${d}`, 10));
  }

  return [parseInt(query, 10)];
};

// query length is 2 add this point
const getMilleniumHundreds = (query: string) => {
  const century = parseInt(query, 10) * 100;
  return hundreds.map(i => century + i);
};

const getTwoThousands = (query: string) => {
  switch (query.length) {
    case 2:
      return getMilleniumHundreds(query);
    case 3:
      return digits.map(d => parseInt(`${query}${d}`, 10));
    case 4:
      return [parseInt(query, 10)];
    default:
      return milleniumYears;
  }
};

const FiscalYearPicker = (props: {
  usedYears: $ReadOnlyArray<number>,
  onAddYear: (year: number) => void,
  className?: string,
  onBlur?: (query: string) => void,
}) => {
  const { usedYears, onAddYear, className } = props;
  const filterOptions = getFilterOptions(usedYears);

  const handleSelectYear = (year: ?number) => {
    if (year != null && !usedYears.find(item => item === year)) {
      onAddYear(year);
    }
  };

  const handleFilterYearOptions = (query: string) => {
    if (!query) {
      return filterOptions.filter(option => String(option));
    }

    const fiscalYearPattern = /^[1-2]\d{0,3}$/;

    if (!fiscalYearPattern.test(query)) {
      return [];
    }

    if (query[0] === '1') {
      return getThousands(query);
    }

    if (query[0] === '2') {
      return difference(getTwoThousands(query), usedYears);
    }
    return [];
  };

  return (
    <AutocompleteInput
      className={className}
      placeholder="Fiscal Year"
      overlayMaxHeight={135}
      onFilterOptions={handleFilterYearOptions}
      onSelect={handleSelectYear}
      renderOption={renderYearOption}
      renderOptionString={formatYearOption}
      onBlur={props.onBlur}
      prefix={prefix}
      showOptionsOnEmpty
      resetOnBlur
      clearable
      autoFocus
    />
  );
};

export default FiscalYearPicker;
