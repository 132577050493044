/* @flow */
import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
  color: ${props => props.theme.mutedTextColor};
  font-size: 13px;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  margin: 18px 0;
`;

export default class AuthOptionDivider extends React.PureComponent<{}> {
  render() {
    return <Divider>or</Divider>;
  }
}
