/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import theme from 'config/theme';

import ClearIcon from 'components/ClearIcon';
import Radio from 'components/material/Radio';
import TextInput from 'components/material/TextInput';

const Container = styled.div`
  padding: 0 0 0 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  flex: 1 1 auto;
  margin-top: 10px;
  padding: 0;
`;

const InfoText = styled.div`
  font-style: italic;
  color: ${props => props.theme.mutedTextColor};
  padding: 3px 7px;
  font-size: 12px;
  text-align: ${props => (props.centered ? 'center' : 'right')};
`;

const StyledRadio = styled(Radio)`
  margin-top: 15px;
  &:last-of-type {
    margin-bottom: 3px;
  }
  font-size: 14px;
  line-height: 1;
  color: ${props => props.theme.infoMenuHoverColor};
  > div:first-of-type {
    ${props =>
      props.checked
        ? css`
            color: ${props.theme.highlightColor};
          `
        : css`
            color: ${props.theme.iron};
          `};
  }
  > div:last-of-type {
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ShowAll = styled.div`
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

export type Option<Value> = {
  value: Value,
  label: string,
};

const defMaxOptions = 15;

export default class RadioFilterBody<Value> extends React.Component<
  {
    options: $ReadOnlyArray<Option<Value>>,
    activeValue: ?Value,
    maxOptions?: number,
    onChange: Value => void,
    className?: string,
  },
  {
    query: string,
    showAll: boolean,
  },
> {
  state = {
    query: '',
    showAll: false,
  };

  handleOptionChange: (value: Value) => void = value => {
    this.props.onChange(value);
  };

  handleQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ query: e.currentTarget.value });
  };

  handleClear = () => {
    this.setState({ query: '' });
  };

  handleShowAll = () => {
    this.setState({ showAll: true });
  };

  handleOptionsFilter(): $ReadOnlyArray<Option<Value>> {
    const query = this.state.query.replace(/\s/g, '');
    const queryRegexp = new RegExp(query, 'i');
    return this.props.options.filter(option => {
      const optionQuery = option.label.replace(/\s/g, '');
      return option.value === this.props.activeValue || queryRegexp.test(optionQuery);
    });
  }

  render() {
    const maxOptions = this.props.maxOptions != null ? this.props.maxOptions : defMaxOptions;

    const options = this.handleOptionsFilter();
    const slicedOptions = this.state.showAll ? options : options.slice(0, maxOptions);

    const showInput = maxOptions != null && maxOptions < this.props.options.length;
    const shouldShowMore = !this.state.showAll && maxOptions != null && maxOptions < options.length;
    return (
      <Container className={this.props.className}>
        {showInput && (
          <TextWrapper>
            <StyledTextInput
              placeholder="Search..."
              autoFocus
              value={this.state.query}
              onChange={this.handleQueryChange}
            />
            {this.state.query && <ClearIcon onClick={this.handleClear} />}
          </TextWrapper>
        )}
        {slicedOptions.map((option: Option<Value>) => (
          <StyledRadio
            key={option.label}
            label={option.label}
            value={option.value}
            checkColor={this.props.activeValue != null ? theme.highlightColor : null}
            checked={this.props.activeValue != null && this.props.activeValue === option.value}
            onChange={this.handleOptionChange}
          />
        ))}
        {shouldShowMore && <ShowAll onClick={this.handleShowAll}>see all {options.length}</ShowAll>}
        {options.length === 0 && <InfoText>No Results</InfoText>}
      </Container>
    );
  }
}
