/* @flow */
import React from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';

import Loader from 'components/Loader';
import Button from 'components/material/Button';
import TextField from 'components/material/TextField';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import ScheduledReportingForm, {
  type FrequencyType,
  type ScheduledOnType,
  type SchedulerStateType,
} from 'components/ScheduledReporting/ScheduledReportingForm';
import { type User } from 'components/UserSelect';

import BudgetReportingSchedulerFields from '../BudgetReportingSchedulerFields';
import type { SchedulerType } from '../index';

import type { EditViewWindowQueryResponse } from './__generated__/EditViewWindowQuery.graphql';

export type ExportSchedulerType = $PropertyType<
  $NonMaybeType<$PropertyType<EditViewWindowQueryResponse, 'budgetReportingView'>>,
  'exportScheduler',
>;

const WindowTitleContainer = styled.div`
  flex: 1 1 auto;
  text-align: center;
`;

const StyledWindowContent = styled(WindowContent)`
  padding: 0 23px 20px 34px;
`;

const StyledWindowHeader = styled(WindowHeader)`
  padding: 20px 30px 14px;
`;

const StyledWindowTitle = styled(WindowTitle)`
  font-size: 14px;
`;

const TextFieldWrapper = styled.div`
  padding: 0 16px 0 5px;
`;

const EmailFieldsContainer = styled.div`
  padding: 0 20px 0 5px;
`;

const StyledTextField = styled(TextField)`
  input {
    padding: 7px 14px 6px 14px;
    border: solid 1px #c3e0ec;
    border-radius: 4px;
    font-size: 13px;
    transition: border 0.3s;
    &:hover {
      border: solid 1px #c3e0ec;
    }
    &:focus {
      border-color: #3ba9da;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 49px;
`;

const DeleteButton = styled(Button)`
  padding: 4px 16px 4px 0;
  font-weight: 400;
  color: #f25d60;
`;

const CancelButton = styled(DeleteButton)`
  padding: 4px 16px 4px 0;
  margin-left: auto;
  color: #828b93;
`;

const SaveNewButton = styled(Button)`
  padding: 4px 22px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
`;

export default class EditViewWindowContent extends React.PureComponent<
  {
    exportScheduler: ExportSchedulerType,
    name: string,
    editedViewId: string,
    onHide: () => void,
    onRemove: (editedViewId: string) => void,
    onUpdate: (name: string, scheduler: ?SchedulerType, budgetReportingViewId: string) => void,
  },
  {
    name: string,
    scheduler: ?SchedulerStateType,
    budgetReportingSchedulerFields: ?{ +includePayments: boolean, +includeExpenses: boolean },
    errors: $ReadOnlyArray<string>,
  },
> {
  state = {
    name: this.props.name,
    scheduler: this.props.exportScheduler
      ? {
          frequency: this.props.exportScheduler.frequency,
          scheduledOn: this.props.exportScheduler.scheduledOn,
          recipients: this.props.exportScheduler.recipients.edges.map(({ node }) => node),
        }
      : null,
    budgetReportingSchedulerFields: this.props.exportScheduler
      ? {
          includePayments: this.props.exportScheduler.includePayments,
          includeExpenses: this.props.exportScheduler.includeExpenses,
        }
      : null,
    errors: [],
  };

  handleNameChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    if (this.state.errors.includes('name') && name.trim()) {
      this.setState(prevState => ({
        errors: prevState.errors.filter(error => error !== 'name'),
      }));
    }
    this.setState({ name });
  };

  handleUpdate = () => {
    const { name, errors, scheduler, budgetReportingSchedulerFields } = this.state;

    if (!name) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'name'] }));
    }

    if (scheduler && scheduler.recipients.length === 0) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'recipients'] }));
    }

    if (errors.length !== 0 || (scheduler && scheduler.recipients.length === 0) || !name) return;

    const newScheduler =
      scheduler && budgetReportingSchedulerFields
        ? {
            frequency: scheduler.frequency,
            scheduledOn: scheduler.scheduledOn,
            includeExpenses: budgetReportingSchedulerFields.includeExpenses,
            includePayments: budgetReportingSchedulerFields.includePayments,
            recipientIds: scheduler.recipients.map(({ id }) => id),
          }
        : null;

    const savedScheduler = this.props.exportScheduler
      ? {
          frequency: this.props.exportScheduler.frequency,
          scheduledOn: this.props.exportScheduler.scheduledOn,
          includePayments: this.props.exportScheduler.includePayments,
          includeExpenses: this.props.exportScheduler.includeExpenses,
          recipientIds: this.props.exportScheduler.recipients.edges.map(({ node }) => node.id),
        }
      : null;

    this.props.onUpdate(
      name,
      !isEqual(savedScheduler, newScheduler) ? newScheduler : undefined,
      this.props.editedViewId,
    );
  };

  handleDeleteView = () => {
    if (this.props.editedViewId) {
      this.props.onRemove(this.props.editedViewId);
    }
  };

  handleFrequencyChange = (frequency: ?FrequencyType | 'never') => {
    if (this.state.scheduler && this.state.scheduler.frequency === frequency) return;

    if (frequency === 'never' || !frequency) {
      this.setState({ scheduler: null, budgetReportingSchedulerFields: null });

      if (this.state.errors.includes('recipients')) {
        this.setState(prevState => ({
          errors: prevState.errors.filter(error => error !== 'recipients'),
        }));
      }

      return;
    }

    const scheduledOnDefaultValue = frequency === 'monthly' ? 'start_of_month' : 'Monday';

    this.setState(previousState => ({
      scheduler: {
        ...previousState.scheduler,
        frequency: ((frequency: any): FrequencyType),
        scheduledOn: scheduledOnDefaultValue,
        recipients: previousState.scheduler ? previousState.scheduler.recipients : [],
      },
      budgetReportingSchedulerFields: {
        includeExpenses: previousState.budgetReportingSchedulerFields
          ? previousState.budgetReportingSchedulerFields.includeExpenses
          : false,
        includePayments: previousState.budgetReportingSchedulerFields
          ? previousState.budgetReportingSchedulerFields.includePayments
          : false,
      },
    }));
  };

  handleScheduledOnChange = (scheduledOn: ?ScheduledOnType) => {
    if (this.state.scheduler && this.state.scheduler.scheduledOn === scheduledOn) return;

    this.setState(previousState => ({
      scheduler: {
        ...previousState.scheduler,
        scheduledOn: ((scheduledOn: any): ScheduledOnType),
      },
    }));
  };

  handleChangeUserMultiSelectFilterValue = (users: $ReadOnlyArray<User>) => {
    if (this.state.errors.includes('recipients') && users.length > 0) {
      this.setState(prevState => ({
        errors: prevState.errors.filter(error => error !== 'recipients'),
      }));
    }
    this.setState(previousState => ({
      scheduler: {
        ...previousState.scheduler,
        recipients: users,
      },
    }));
  };

  handleIncludeExpensesChange = () => {
    this.setState(previousState => ({
      budgetReportingSchedulerFields: {
        ...previousState.budgetReportingSchedulerFields,
        includeExpenses: previousState.budgetReportingSchedulerFields
          ? !previousState.budgetReportingSchedulerFields.includeExpenses
          : true,
      },
    }));
  };

  handleIncludePaymentsChange = () => {
    this.setState(previousState => ({
      budgetReportingSchedulerFields: {
        ...previousState.budgetReportingSchedulerFields,
        includePayments: previousState.budgetReportingSchedulerFields
          ? !previousState.budgetReportingSchedulerFields.includePayments
          : true,
      },
    }));
  };

  renderLoading = () => {
    return (
      <LoaderContainer>
        <Loader size={30} />
      </LoaderContainer>
    );
  };

  render() {
    const { scheduler, budgetReportingSchedulerFields, name, errors } = this.state;
    return (
      <Window size={436} onHide={this.props.onHide}>
        <StyledWindowHeader>
          <WindowTitleContainer>
            <StyledWindowTitle>Edit saved view</StyledWindowTitle>
          </WindowTitleContainer>
          <WindowClose onClick={this.props.onHide} />
        </StyledWindowHeader>
        <StyledWindowContent>
          <TextFieldWrapper>
            <StyledTextField
              placeholder="Budget View Name"
              value={name}
              onChange={this.handleNameChange}
              autoFocus
              error={errors.includes('name') ? 'Required' : ''}
            />
          </TextFieldWrapper>
          <EmailFieldsContainer>
            <ScheduledReportingForm
              scheduler={scheduler}
              errors={errors}
              handleFrequencyChange={this.handleFrequencyChange}
              handleScheduledOnChange={this.handleScheduledOnChange}
              handleChangeUserMultiSelectFilterValue={this.handleChangeUserMultiSelectFilterValue}
              hasTeamAccess
            />
            <BudgetReportingSchedulerFields
              budgetReportingSchedulerFields={budgetReportingSchedulerFields}
              handleIncludeExpensesChange={this.handleIncludeExpensesChange}
              handleIncludePaymentsChange={this.handleIncludePaymentsChange}
            />
          </EmailFieldsContainer>
          <ActionContainer>
            <DeleteButton label="Delete View" minimal onClick={this.handleDeleteView} />
            <CancelButton label="Cancel" minimal onClick={this.props.onHide} />
            <SaveNewButton primary label="Save" onClick={this.handleUpdate} />
          </ActionContainer>
        </StyledWindowContent>
      </Window>
    );
  }
}
