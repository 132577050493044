/* @flow */
import React from 'react';
import { createRefetchContainer, graphql, type RelayRefetchProp } from 'react-relay';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';
import theme from 'config/theme';
import difference from 'lodash/difference';

import fullNameOfUser from 'utils/fullNameOfUser';
import formatDateTime from 'utils/date/formatDateTime';

import Checkbox, { Label as CheckboxLabel, Check } from 'components/material/CheckBox';
import MaterialAvatar from 'components/material/MaterialAvatar';

import AddNewLink from '../AddNewLink';

import { type Notes_event } from './__generated__/Notes_event.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 25px;
  align-items: initial;

  ${CheckboxLabel} {
    margin-left: 10px;
    line-height: 1.29;
    font-weight: 500;
    color: #4a5665;
    word-break: break-all;
  }

  ${Check} {
    margin-top: 2px;
    border-radius: 2px;
  }
`;

const NoteTitle = styled.div`
  margin-left: 10px;
  font-weight: 500;
  line-height: 1.29;
  color: #4a5665;
  word-break: break-all;
`;

const NoteInfo = styled.div`
  display: flex;
  margin-top: 15px;
  font-size: 13px;
  line-height: 1.08;
  > div:last-child {
    flex: 1 1 auto;
  }
`;

const StyledAvatar = styled(MaterialAvatar)`
  margin: 0 10px;
`;

const UserName = styled.div`
  margin-top: 5px;
  font-weight: 500;
  color: #3e4859;
`;

const UpdateDate = styled.div`
  margin-top: 10px;
  color: #828b93;
  ${props =>
    props.alignWithName &&
    css`
      margin-top: 0;
      margin-left: 10px;
    `};
`;

class Notes extends React.PureComponent<{
  relay: RelayRefetchProp,
  event: Notes_event,
  selectedNotes: $ReadOnlyArray<string>,
  onAddNote: (noteId: string) => void,
  onRemoveNote: (noteId: string) => void,
  onAllNoteCheckToggle: (isPrevAllCheck: boolean) => void,
}> {
  componentDidUpdate(prevProps: $PropertyType<Notes, 'props'>) {
    const currentNotesIds = this.props.event.eventNotes.edges.map(({ node }) => node.id);
    const prevNotesIds = prevProps.event.eventNotes.edges.map(({ node }) => node.id);
    const addedNotesIds = difference(currentNotesIds, prevNotesIds);
    const removedNotesIds = difference(prevNotesIds, currentNotesIds);
    addedNotesIds.forEach(id => this.props.onAddNote(id));
    removedNotesIds.forEach(id => this.props.onRemoveNote(id));
  }

  componentWillUnmount() {
    this.removeTabChangeListener();
  }

  removeTabChangeListener = () => {
    this.noteWindow = null;
    document.removeEventListener('visibilitychange', this.documentFocus);
  };

  documentFocus = () => {
    if (document.visibilityState === 'visible') {
      const {
        relay,
        event: { slug: eventSlug },
      } = this.props;
      relay.refetch({ eventSlug });
    }
  };

  handleAddNewClick = () => {
    const needToAddListener = !this.noteWindow;
    this.noteWindow = window.open(`/events/${this.props.event.slug}/notes`, '_blank');
    if (needToAddListener) {
      this.noteWindow.addEventListener('beforeunload', this.removeTabChangeListener);
      document.addEventListener('visibilitychange', this.documentFocus);
    }
  };

  handleNoteToggle = (noteId: string, isPrevChecked: boolean) => {
    const { onRemoveNote, onAddNote } = this.props;
    if (isPrevChecked) {
      onRemoveNote(noteId);
    } else {
      onAddNote(noteId);
    }
  };

  noteWindow: ?EventTarget;

  renderNoteLabel = (
    note: {
      +id: string,
      +name: string,
      +updatedAt: string,
      +updater?: ?{
        +id: string,
        +email: string,
        +firstName: string,
        +lastName: string,
      },
    },
    tz,
  ) => {
    const updatedDate = formatDateTime(moment(note.updatedAt).tz(tz));
    const { name, updater } = note;
    return (
      <div>
        <NoteTitle>{name}</NoteTitle>
        <NoteInfo>
          {updater && <StyledAvatar user={updater} radius={13} />}
          <div>
            {updater && <UserName>{fullNameOfUser(note.updater)}</UserName>}
            <UpdateDate alignWithName={!updater}>{updatedDate}</UpdateDate>
          </div>
        </NoteInfo>
      </div>
    );
  };

  render() {
    const {
      event: { eventNotes, tz },
      onAllNoteCheckToggle,
      selectedNotes,
    } = this.props;

    const allNotes = eventNotes.edges.map(({ node }) => node);
    const checkAll = selectedNotes.length === allNotes.length;

    return (
      <Container>
        <AddNewLink label="Add new note" onClick={this.handleAddNewClick} />
        {allNotes.length > 0 && (
          <StyledCheckbox
            label="All"
            key={0}
            checked={checkAll}
            checkColor={checkAll ? theme.highlightColor : theme.silver}
            onChange={() => onAllNoteCheckToggle(checkAll)}
          />
        )}
        {allNotes.map(n => {
          const checked = selectedNotes.includes(n.id);
          return (
            <StyledCheckbox
              labelRenderer={() => this.renderNoteLabel(n, tz)}
              key={n.id}
              checked={checked}
              checkColor={checked ? theme.highlightColor : theme.silver}
              onChange={() => this.handleNoteToggle(n.id, checked)}
            />
          );
        })}
      </Container>
    );
  }
}

export default createRefetchContainer(
  Notes,
  graphql`
    fragment Notes_event on Event {
      id
      slug
      tz
      eventNotes {
        edges {
          node {
            id
            name
            content
            updatedAt
            updater {
              id
              email
              firstName
              lastName
              ...MaterialAvatar_user
            }
          }
        }
      }
    }
  `,
  graphql`
    query NotesRefetchQuery($eventSlug: String!) {
      event(slug: $eventSlug) {
        id
        ...Notes_event
      }
    }
  `,
);
