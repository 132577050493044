/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import NavContext from './Navbar/NavContext';

const Container = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 25px 24px;
  ${props =>
    props.showSidebar &&
    css`
      border-top-left-radius: 20px;
    `}
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14);
`;

type Props = {
  children: React.Node,
  className?: string,
};

const Content = ({ children, className }: Props) => {
  return (
    <NavContext.Consumer>
      {({ showSidebar }) => (
        <Container className={`site-content ${className || ''}`} showSidebar={showSidebar}>
          {children}
        </Container>
      )}
    </NavContext.Consumer>
  );
};

export default Content;
