/**
 * @flow
 * @relayHash fc7db04d44db369f6d95a88575e977b2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveEventBudgetCategoryInput = {
  clientMutationId?: ?string,
  categoryId: string,
  eventId: string,
};
export type removeEventBudgetCategoryMutationVariables = {|
  input: RemoveEventBudgetCategoryInput
|};
export type removeEventBudgetCategoryMutationResponse = {|
  +removeEventBudgetCategory: ?{|
    +removedCategoryId: string,
    +event: {|
      +totalBudgetedAmount: number,
      +budgetCategories: {|
        +totalCount: number
      |},
    |},
  |}
|};
export type removeEventBudgetCategoryMutation = {|
  variables: removeEventBudgetCategoryMutationVariables,
  response: removeEventBudgetCategoryMutationResponse,
|};
*/


/*
mutation removeEventBudgetCategoryMutation(
  $input: RemoveEventBudgetCategoryInput!
) {
  removeEventBudgetCategory(input: $input) {
    removedCategoryId
    event {
      totalBudgetedAmount
      budgetCategories {
        totalCount
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveEventBudgetCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveEventBudgetCategoryInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedCategoryId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalBudgetedAmount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "budgetCategories",
  "storageKey": null,
  "args": null,
  "concreteType": "EventBudgetCategoryConnection",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeEventBudgetCategoryMutation",
  "id": null,
  "text": "mutation removeEventBudgetCategoryMutation(\n  $input: RemoveEventBudgetCategoryInput!\n) {\n  removeEventBudgetCategory(input: $input) {\n    removedCategoryId\n    event {\n      totalBudgetedAmount\n      budgetCategories {\n        totalCount\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeEventBudgetCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeEventBudgetCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveEventBudgetCategoryPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeEventBudgetCategoryMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeEventBudgetCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveEventBudgetCategoryPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v4,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f71c71b001845a8e5ad2ce7ec6a5fe6b';
module.exports = node;
