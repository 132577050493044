/* @flow */

import { type ResourceFieldType } from '../MarketoLeadMappings';

import { type MarketoLeadMappingsQueryResponse } from '../__generated__/MarketoLeadMappingsQuery.graphql';

const eventContactFields = (
  // eslint-disable-next-line no-unused-vars
  org: $PropertyType<MarketoLeadMappingsQueryResponse, 'org'>,
): $ReadOnlyArray<ResourceFieldType> => {
  return [
    {
      label: 'Join Time',
      name: 'join_time',
      types: ['datetime'],
    },
    {
      label: 'Leave Time',
      name: 'leave_time',
      types: ['datetime'],
    },
    {
      label: 'Time in Session',
      name: 'duration',
      types: ['integer', 'float'],
    },
    org.syncedToIbmWm
      ? {
          label: 'Total Live View Time',
          name: 'live_duration',
          types: ['integer', 'float'],
        }
      : null,
    org.syncedToIbmWm
      ? {
          label: 'Total VOD View Time',
          name: 'recorded_duration',
          types: ['integer', 'float'],
        }
      : null,
  ].filter(Boolean);
};

export default eventContactFields;
