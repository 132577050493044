/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BillingTrialMessage_subscription$ref: FragmentReference;
export type BillingTrialMessage_subscription = {|
  +expiresAt: ?any,
  +upgradeRequired: boolean,
  +$refType: BillingTrialMessage_subscription$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BillingTrialMessage_subscription",
  "type": "Subscription",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "expiresAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "upgradeRequired",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '33fdead006a38df658adc4cee74bf258';
module.exports = node;
