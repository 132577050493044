/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import SelectField from 'components/material/SelectField';

import type { TemplateSearchQuery_response } from './__generated__/TemplateSearchQuery.graphql';

type TemplatesType = $PropertyType<$PropertyType<TemplateSearchQuery_response, 'me'>, 'events'>;
type TemplateEdgeType = $ElementType<TemplatesType, 0>;
export type TemplateType = $PropertyType<TemplateEdgeType, 'node'>;

const query = graphql`
  query TemplateSearchQuery($filters: EventFilters!, $includeFolders: Boolean!) {
    me {
      events(first: 15, filters: $filters) {
        edges {
          node {
            id
            dbId
            name
            startDate
            team {
              name
            }
            folders @include(if: $includeFolders) {
              edges {
                node {
                  dbId
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const TemplateLabelSecondary = styled.span`
  font-size: 13px;
  color: ${props => props.theme.mutedTextColor};
`;

export default class TemplateSearch extends React.PureComponent<
  {
    event?: ?TemplateType,
    onSelect: (event: ?TemplateType) => void,
    className?: string,
    label?: string,
    placeholder?: string,
    disabled?: boolean,
    required?: boolean,
    clearable?: boolean,
    autoFocus?: boolean,
    includeFolders?: boolean,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleSearch = debounce((search: string) => {
    this.setState({ query: search });
  }, 800);

  handleChange = (event: ?TemplateType) => {
    this.props.onSelect(event);
  };

  findEvent = (eventId: ?string, eventsList: $ReadOnlyArray<TemplateType>) => {
    return eventsList.find(event => event.id === eventId) || null;
  };

  getFullEventsList = (
    loadedEvents: $ReadOnlyArray<TemplateType>,
  ): $ReadOnlyArray<TemplateType> => {
    const selectedEvent = this.props.event;
    if (selectedEvent && this.findEvent(selectedEvent.id, loadedEvents) == null) {
      return [selectedEvent, ...loadedEvents];
    }
    return loadedEvents;
  };

  renderSelect = (response?: TemplateSearchQuery_response) => {
    const {
      event: selectedEvent,
      className,
      label,
      placeholder,
      disabled,
      required,
      clearable,
      autoFocus,
    } = this.props;
    const loadedEvents: $ReadOnlyArray<TemplateType> =
      response && response.me.events ? response.me.events.edges.map(edge => edge.node) : [];
    const events = this.getFullEventsList(loadedEvents);

    const options = events.map(event => ({
      label: event.name,
      value: event.id,
      displayLabel: (
        <React.Fragment>
          {event.name} <TemplateLabelSecondary>in {event.team.name}</TemplateLabelSecondary>
        </React.Fragment>
      ),
    }));

    return (
      <SelectField
        className={className}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        searchable
        options={options}
        onSearch={this.handleSearch}
        onChange={(eventId: ?string) => this.handleChange(this.findEvent(eventId, events))}
        value={selectedEvent && selectedEvent.id}
        loading={!response}
        clearable={clearable}
        autoFocus={autoFocus}
      />
    );
  };

  render() {
    const variables = {
      filters: {
        queries: [this.state.query],
        requestStatuses: ['N_A', 'APPROVED'],
        onlyWithTasks: true,
        sort: 'TEMPLATES_FIRST',
        direction: 'ASC',
      },
      includeFolders: !!this.props.includeFolders,
    };
    return (
      <DefaultQueryRenderer
        query={query}
        variables={variables}
        renderSuccess={this.renderSelect}
        renderLoading={this.renderSelect}
      />
    );
  }
}
