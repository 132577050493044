/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import removeUserFromTeam from 'graph/mutations/access/removeUserFromTeam';
import resendInvite from 'graph/mutations/user/resendInvite';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import ShareDropdown, { Icon } from 'components/ShareDropdown';

import type { TeamMembersActionsColumn_me } from './__generated__/TeamMembersActionsColumn_me.graphql';
import type { TeamMembersActionsColumn_member } from './__generated__/TeamMembersActionsColumn_member.graphql';
import type { TeamMembersActionsColumn_team } from './__generated__/TeamMembersActionsColumn_team.graphql';

const Column = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  & > div:first-child {
    margin-left: 0;
    padding-left: 0;
  }
`;

const StyledShareDropdown = styled(ShareDropdown)`
  position: absolute;
  right: 20px;
`;

export { Icon };

class TeamMembersActionsColumn extends React.Component<
  {
    member: TeamMembersActionsColumn_member,
    team: TeamMembersActionsColumn_team,
    me: TeamMembersActionsColumn_me,
  },
  {
    showLeaveModal: boolean,
    reinvited: boolean,
  },
> {
  state = {
    showLeaveModal: false,
    reinvited: false,
  };

  stopPropagation = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation();
  };

  handleLeaveModalShow = () => {
    this.setState({ showLeaveModal: true });
  };

  handleLeaveModalHide = () => {
    this.setState({ showLeaveModal: false });
  };

  handleResendInvite = () => {
    this.setState({ reinvited: true });
    resendInvite(this.props.member.node.id, this.props.team.id).catch(err => {
      this.setState({ reinvited: false });
      showModernMutationError(err);
    });
  };

  handleRemove = () => {
    removeUserFromTeam(this.props.team.id, this.props.member.node.id)
      .then(() => {
        if (this.props.member.node.id === this.props.me.id) {
          // Do a hard refresh if current user leaves the team
          window.location = '/';
        }
      })
      .catch(showModernMutationError);
  };

  render() {
    const isCurrentUser: boolean = this.props.member.node.id === this.props.me.id;
    const actions = [
      this.props.member.node.inviteStatus === 'INVITED' && !this.state.reinvited
        ? {
            label: 'Resend invite',
            icon: 'paper-plane',
            onClick: this.handleResendInvite,
          }
        : null,
      this.props.member.viewerCanRemove
        ? {
            label: isCurrentUser ? 'Leave from Team' : 'Remove from Team',
            icon: isCurrentUser ? 'sign-out' : 'times',
            onClick: this.handleLeaveModalShow,
          }
        : null,
    ].filter(Boolean);

    if (actions.length === 0) {
      return null;
    }

    return (
      <Column onClick={this.stopPropagation}>
        <StyledShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={actions}
          overlayContainer={document.body}
        />
        {this.state.showLeaveModal && (
          <ConfirmationWindow
            onHide={this.handleLeaveModalHide}
            onConfirm={this.handleRemove}
            actionLabel={isCurrentUser ? 'Leave' : 'Remove'}
            message={`Note that ${
              isCurrentUser ? 'you' : 'this user'
            } may still have access to Events in this Team as an Event Staff Member.`}
          />
        )}
      </Column>
    );
  }
}

export default createFragmentContainer(TeamMembersActionsColumn, {
  member: graphql`
    fragment TeamMembersActionsColumn_member on TeamMemberEdge {
      viewerCanRemove
      node {
        id
        inviteStatus
      }
    }
  `,
  team: graphql`
    fragment TeamMembersActionsColumn_team on Team {
      id
      dbId
      slug
      name
    }
  `,
  me: graphql`
    fragment TeamMembersActionsColumn_me on User {
      id
    }
  `,
});
