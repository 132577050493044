/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type InlineFormFields_org$ref = any;
type PopupFormFields_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddExpenseForm_org$ref: FragmentReference;
export type AddExpenseForm_org = {|
  +settings: {|
    +currency: string,
    +fiscalYearStart: number,
  |},
  +budgetCategories: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +name: string,
        +description: ?string,
        +id: string,
      |}
    |}>
  |},
  +$fragmentRefs: PopupFormFields_org$ref & InlineFormFields_org$ref,
  +$refType: AddExpenseForm_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "AddExpenseForm_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "PopupFormFields_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "InlineFormFields_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currency",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fiscalYearStart",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetCategories",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetCategoryRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BudgetCategoryRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BudgetCategory",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "description",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'aeb54e8287a7ddfbb3032419cb3a22d0';
module.exports = node;
