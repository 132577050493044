/**
 * @flow
 * @relayHash 8abc364c85a8c4f758b87ea949e14d77
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveCustomFieldInput = {
  clientMutationId?: ?string,
  customFieldId: string,
};
export type removeCustomFieldMutationVariables = {|
  input: RemoveCustomFieldInput
|};
export type removeCustomFieldMutationResponse = {|
  +removeCustomField: ?{|
    +removedCustomFieldId: string
  |}
|};
export type removeCustomFieldMutation = {|
  variables: removeCustomFieldMutationVariables,
  response: removeCustomFieldMutationResponse,
|};
*/


/*
mutation removeCustomFieldMutation(
  $input: RemoveCustomFieldInput!
) {
  removeCustomField(input: $input) {
    removedCustomFieldId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveCustomFieldInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeCustomField",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveCustomFieldInput!"
      }
    ],
    "concreteType": "RemoveCustomFieldPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedCustomFieldId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeCustomFieldMutation",
  "id": null,
  "text": "mutation removeCustomFieldMutation(\n  $input: RemoveCustomFieldInput!\n) {\n  removeCustomField(input: $input) {\n    removedCustomFieldId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeCustomFieldMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeCustomFieldMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f0847d21d1d4d5e9600118d47a92ddd';
module.exports = node;
