/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type VideoComponent_componentProps$ref: FragmentReference;
export type VideoComponent_componentProps = {|
  +videoComponent: ?{|
    +padding: string,
    +height: number,
    +width: number,
    +url: ?string,
    +autoplay: boolean,
    +alignment: Alignment,
    +backgroundColor: ?string,
  |},
  +$refType: VideoComponent_componentProps$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VideoComponent_componentProps",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "videoComponent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationVideoComponent",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "padding",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "height",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "width",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "autoplay",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "alignment",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "backgroundColor",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1c25f81b5038da883a1e139c7bf3105a';
module.exports = node;
