/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextField from 'components/material/TextField';

import type { QuestionType } from '../lib/types';

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  > :first-child {
    margin-right: 25px;
  }
`;

export default class EditableQuestionExpense extends React.PureComponent<{
  question: QuestionType,
  onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
}> {
  handleChange = (event: SyntheticEvent<HTMLInputElement>) =>
    this.props.onChangeQuestion({
      expenseName: event.currentTarget.value || null,
    });

  render() {
    return (
      <Row>
        <TextField
          label="Expense Name"
          value={this.props.question.expenseName || ''}
          onChange={this.handleChange}
        />
      </Row>
    );
  }
}
