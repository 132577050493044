import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';

import addToGoogleCalendarLink from 'utils/calendar/addToGoogleCalendarLink';

import ShareDropdown from 'components/ShareDropdown';

import Description from './form/Description';
import Location from './form/Location';
import Name from './form/Name';
import Participants from './form/Participants';
import Time from './form/Time';

class ScheduleListRow extends React.Component {
  static propTypes = {
    scheduleItem: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      all_day: PropTypes.bool.isRequired,
      start_time: PropTypes.string.isRequired,
      end_time: PropTypes.string,
      description: PropTypes.string,
      location: PropTypes.string,
      viewerCanUpdate: PropTypes.bool.isRequired,
      viewerCanRemove: PropTypes.bool.isRequired,
      scheduleParticipants: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              user: PropTypes.shape({
                id: PropTypes.string.isRequired,
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
              }),
            }),
          }),
        ).isRequired,
      }).isRequired,
      schedule: PropTypes.shape({
        date: PropTypes.string.isRequired,
      }),
    }).isRequired,
    event: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tz: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      staffers: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              user: PropTypes.shape({
                id: PropTypes.string.isRequired,
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                avatar: PropTypes.string,
                email: PropTypes.string.isRequired,
              }),
            }),
          }),
        ).isRequired,
      }).isRequired,
      team: PropTypes.shape({
        users: PropTypes.shape({
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape({
                user: PropTypes.shape({
                  id: PropTypes.string.isRequired,
                  firstName: PropTypes.string.isRequired,
                  lastName: PropTypes.string.isRequired,
                  avatar: PropTypes.string,
                  email: PropTypes.string.isRequired,
                }),
              }),
            }),
          ).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    date: PropTypes.string.isRequired,
    onItemUpdate: PropTypes.func.isRequired,
    onItemRemove: PropTypes.func.isRequired,
    onParticipantAdd: PropTypes.func.isRequired,
    onParticipantRemove: PropTypes.func.isRequired,
    eventId: PropTypes.number.isRequired,
    teamId: PropTypes.number.isRequired,
  };

  handleNameChange = title => {
    this.props.onItemUpdate(this.props.scheduleItem.id, { title });
  };

  handleDurationChange = ({ startTime, endTime, allDay }) => {
    this.props.onItemUpdate(this.props.scheduleItem.id, { startTime, endTime, allDay });
  };

  handleLocationChange = location => {
    this.props.onItemUpdate(this.props.scheduleItem.id, { location });
  };

  handleDescriptionChange = description => {
    this.props.onItemUpdate(this.props.scheduleItem.id, { description });
  };

  handleRemove = () => {
    this.props.onItemRemove(this.props.scheduleItem.id);
  };

  handleAddToCalendar = () => {
    const scheduleItem = this.props.scheduleItem;
    window.open(
      addToGoogleCalendarLink({
        start: scheduleItem.start_time,
        end: scheduleItem.end_time || scheduleItem.start_time,
        summary: scheduleItem.title,
        description: scheduleItem.description,
        location: scheduleItem.location,
      }),
    );
  };

  render() {
    const {
      scheduleItem,
      event,
      date,
      eventId,
      teamId,
      onParticipantAdd,
      onParticipantRemove,
    } = this.props;
    const { tz, slug: eventSlug } = event;
    const users = uniqBy(
      [
        ...event.staffers.edges.map(edge => edge.node.user),
        ...event.team.users.edges.map(edge => edge.node),
      ],
      'id',
    );

    const participants = scheduleItem.scheduleParticipants.edges.map(x => x.node.user);

    const dropdownOptions = [
      {
        label: 'Send to Google Calendar',
        icon: 'calendar',
        onClick: this.handleAddToCalendar,
      },
      this.props.scheduleItem.viewerCanRemove
        ? {
            label: 'Delete',
            icon: 'trash',
            disabled: !this.props.scheduleItem.viewerCanRemove,
            onClick: this.handleRemove,
          }
        : undefined,
    ].filter(Boolean);

    return (
      <div className="schedule-list-row">
        <Time
          tz={tz}
          startTime={scheduleItem.start_time}
          endTime={scheduleItem.end_time}
          allDay={scheduleItem.all_day}
          date={date}
          onChange={this.handleDurationChange}
          disabled={!scheduleItem.viewerCanUpdate}
        />
        <div className="schedule-list-row-main">
          <div className="schedule-list-row-body">
            <Name
              value={scheduleItem.title}
              onChange={this.handleNameChange}
              disabled={!scheduleItem.viewerCanUpdate}
            />
            <Description
              value={scheduleItem.description}
              onChange={this.handleDescriptionChange}
              eventId={eventId}
              teamId={teamId}
              eventSlug={eventSlug}
              disabled={!scheduleItem.viewerCanUpdate}
            />
          </div>
          <Location
            value={scheduleItem.location}
            onChange={this.handleLocationChange}
            disabled={!scheduleItem.viewerCanUpdate}
          />
          <Participants
            value={participants}
            users={users}
            eventId={event.id}
            onAdd={id => onParticipantAdd(scheduleItem.id, id)}
            onRemove={id => onParticipantRemove(scheduleItem.id, id)}
            disabled={!scheduleItem.viewerCanUpdate}
          />
        </div>
        <ShareDropdown options={dropdownOptions} noBorder />
      </div>
    );
  }
}

export default createFragmentContainer(ScheduleListRow, {
  scheduleItem: graphql`
    fragment ScheduleListRow_scheduleItem on ScheduleItem {
      id
      title
      all_day
      start_time
      end_time
      description
      location
      viewerCanUpdate
      viewerCanRemove
      scheduleParticipants {
        edges {
          node {
            user {
              id
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  `,
  event: graphql`
    fragment ScheduleListRow_event on Event {
      id
      tz
      slug
      staffers {
        edges {
          node {
            user {
              id
              firstName
              lastName
              avatar
              email
              ...UserSelectionOverlay_users
            }
          }
        }
      }
      team {
        users {
          edges {
            node {
              id
              firstName
              lastName
              avatar
              email
              ...UserSelectionOverlay_users
            }
          }
        }
      }
    }
  `,
});
