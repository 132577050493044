import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { isBright } from 'utils/color';

import type { ScrollTopButton_event } from './__generated__/ScrollTopButton_event.graphql';

const Container = styled.div`
  position: fixed;
  bottom: 29px;
  right: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.color};
  opacity: 0;
  text-align: center;
  line-height: 34px;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const SctollButton = styled.i`
  font-size: 24px;
  color: ${props => (isBright(props.color) ? '#4a5665' : '#fff')};
`;

class ScrollTopButton extends React.Component<{ event: ScrollTopButton_event, isHidden: boolean }> {
  componentDidMount() {
    this.briefPageElement = document.getElementById('briefPage');

    if (this.briefPageElement) this.briefPageElement.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    if (this.briefPageElement) {
      this.briefPageElement.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    if (!this.scrollButton) {
      return;
    }
    const scrollTrigger = 300;
    if (this.briefPageElement && this.briefPageElement.scrollTop > scrollTrigger) {
      this.scrollButton.style.opacity = 1;
      this.scrollButton.style.pointerEvents = 'all';
    } else {
      this.scrollButton.style.opacity = 0;
      // Turnaround for fixed elements doesn't allow scroll parent elements when hover
      this.scrollButton.style.pointerEvents = 'none';
    }
  };

  handleClick = () => {
    if (this.briefPageElement) {
      try {
        this.briefPageElement.scroll({ top: 0, behavior: 'smooth' });
      } catch (ex) {
        this.briefPageElement.scrollTop = 0;
      }
    }
  };

  briefPageElement: ?HTMLElement;

  scrollButton: ?HTMLElement;

  render() {
    const {
      event: {
        team: { primaryColor },
      },
      isHidden,
    } = this.props;

    return !isHidden ? (
      <Container
        ref={el => {
          this.scrollButton = el;
        }}
        onClick={this.handleClick}
        color={primaryColor}
      >
        <SctollButton color={primaryColor} className="fa fa-fw fa-chevron-up" />
      </Container>
    ) : null;
  }
}

export default createFragmentContainer(
  ScrollTopButton,
  graphql`
    fragment ScrollTopButton_event on Event {
      id
      team {
        primaryColor
      }
    }
  `,
);
