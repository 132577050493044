/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import BriefField from '../../../components/BriefField';
import UserField from '../../../components/UserField';

import { type EventInfoCustomUserSelect_event } from './__generated__/EventInfoCustomUserSelect_event.graphql';

const StyledUserField = styled(UserField)`
  margin-top: 27px;
`;

const EventInfoCustomUserSelect = ({
  name,
  event,
  customFieldId,
}: {
  name: string,
  event: EventInfoCustomUserSelect_event,
  customFieldId: string,
}) => {
  const fieldValue = event.customUserSelectFields.find(
    value => value.customField.id === customFieldId,
  );

  if (!fieldValue) return null;

  return (
    <BriefField label={name} contentDataAvailable={fieldValue.user && fieldValue.user.id}>
      <StyledUserField event={event} user={fieldValue.user} avatarRadius={27.5} />
    </BriefField>
  );
};

export default createFragmentContainer(
  EventInfoCustomUserSelect,
  graphql`
    fragment EventInfoCustomUserSelect_event on Event {
      ...UserField_event
      customUserSelectFields(customFieldIds: $customFieldIds) {
        user {
          id
          firstName
          lastName
          email
          phone
          officePhone
          title
          company
        }
        customField {
          id
        }
      }
    }
  `,
);
