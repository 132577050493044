/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoryActualAmountFooterCell_event$ref: FragmentReference;
export type CategoryActualAmountFooterCell_event = {|
  +totalActualAmount: number,
  +$refType: CategoryActualAmountFooterCell_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CategoryActualAmountFooterCell_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalActualAmount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3815e37eed83158b96a87d3c4dc51344';
module.exports = node;
