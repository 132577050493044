/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';

import NumberField from './components/NumberField';
import type { RegisteredContactsTotal_event } from './__generated__/RegisteredContactsTotal_event.graphql';

const RegisteredContactsTotal = (props: { event: RegisteredContactsTotal_event }) => {
  const { contactRegistrationsTotal } = props.event;

  return <NumberField value={contactRegistrationsTotal} />;
};

export default createFragmentContainer(
  RegisteredContactsTotal,
  graphql`
    fragment RegisteredContactsTotal_event on Event {
      contactRegistrationsTotal
    }
  `,
);
