/**
 * @flow
 * @relayHash cb1c974c7b730cac4fff9a4cd2ec34b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
export type CustomFieldSettingsQueryVariables = {|
  customizableType: $ReadOnlyArray<CustomizableType>,
  includeDisabled?: ?boolean,
  includeRestrictManagingOptions: boolean,
|};
export type CustomFieldSettingsQueryResponse = {|
  +org: {|
    +id: string,
    +customFields: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +kind: CustomFieldKindType,
          +label: string,
          +fieldName: ?string,
          +required: boolean,
          +enabled: boolean,
          +order: number,
          +showInCreateForm: boolean,
          +showInMobileForm: boolean,
          +restrictManagingOptions?: boolean,
          +options: {|
            +edges: $ReadOnlyArray<{|
              +node: {|
                +id: string,
                +name: string,
                +editable: boolean,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |}
|};
export type CustomFieldSettingsQuery = {|
  variables: CustomFieldSettingsQueryVariables,
  response: CustomFieldSettingsQueryResponse,
|};
*/


/*
query CustomFieldSettingsQuery(
  $customizableType: [CustomizableType!]!
  $includeDisabled: Boolean
  $includeRestrictManagingOptions: Boolean!
) {
  org {
    id
    customFields(customizableType: $customizableType, includeDisabled: $includeDisabled) {
      edges {
        node {
          id
          kind
          label
          fieldName
          required
          enabled
          order
          showInCreateForm
          showInMobileForm
          restrictManagingOptions: mappedToSalesforce @skip(if: $includeRestrictManagingOptions)
          options {
            edges {
              node {
                id
                name
                editable
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "customizableType",
    "type": "[CustomizableType!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeDisabled",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeRestrictManagingOptions",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "org",
    "storageKey": null,
    "args": null,
    "concreteType": "Org",
    "plural": false,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customFields",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "customizableType",
            "variableName": "customizableType",
            "type": "[CustomizableType!]!"
          },
          {
            "kind": "Variable",
            "name": "includeDisabled",
            "variableName": "includeDisabled",
            "type": "Boolean"
          }
        ],
        "concreteType": "CustomFieldTypeRequiredConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomFieldTypeRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldType",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "kind",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "label",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fieldName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "required",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "enabled",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "order",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "showInCreateForm",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "showInMobileForm",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "options",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldOptionTypeRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionTypeRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldOptionType",
                            "plural": false,
                            "selections": [
                              v1,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "editable",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "Condition",
                    "passingValue": false,
                    "condition": "includeRestrictManagingOptions",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": "restrictManagingOptions",
                        "name": "mappedToSalesforce",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CustomFieldSettingsQuery",
  "id": null,
  "text": "query CustomFieldSettingsQuery(\n  $customizableType: [CustomizableType!]!\n  $includeDisabled: Boolean\n  $includeRestrictManagingOptions: Boolean!\n) {\n  org {\n    id\n    customFields(customizableType: $customizableType, includeDisabled: $includeDisabled) {\n      edges {\n        node {\n          id\n          kind\n          label\n          fieldName\n          required\n          enabled\n          order\n          showInCreateForm\n          showInMobileForm\n          restrictManagingOptions: mappedToSalesforce @skip(if: $includeRestrictManagingOptions)\n          options {\n            edges {\n              node {\n                id\n                name\n                editable\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CustomFieldSettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "CustomFieldSettingsQuery",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5bae48f9d29e6b9822af212bbc860d6a';
module.exports = node;
