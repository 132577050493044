/* @flow */
import type { FieldType } from 'utils/customization/types';

export default function getStaffFields(
  customFields: $ReadOnlyArray<FieldType>,
): $ReadOnlyArray<FieldType> {
  return [
    {
      id: 'name',
      label: 'Name',
      fieldName: 'name',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'email',
      label: 'Email',
      fieldName: 'email',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'event_access_level',
      label: 'Access Level',
      fieldName: 'event_access_level',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'onsite',
      label: 'Based',
      fieldName: 'onsite',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    ...customFields,
    {
      id: 'last_activity',
      label: 'Last Activity',
      fieldName: 'last_activity',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
  ];
}
