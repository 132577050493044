import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router';
import { stringify } from 'qs';
import uniqBy from 'lodash/uniqBy';

import { Filters, FiltersItem } from 'components/Filters';

import fullNameOfUser from 'utils/fullNameOfUser';

class ScheduleFilters extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      staffers: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              user: PropTypes.shape({
                id: PropTypes.string.isRequired,
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                avatar: PropTypes.string,
                slug: PropTypes.string.isRequired,
              }).isRequired
            }).isRequired,
          }),
        ).isRequired,
      }).isRequired,
      team: PropTypes.shape({
        users: PropTypes.shape({
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape({
                id: PropTypes.string.isRequired,
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                avatar: PropTypes.string,
                slug: PropTypes.string.isRequired,
              }).isRequired,
            }),
          ).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    filters: PropTypes.object.isRequired,
    memberFilterShown: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.any,
  };

  handleFilterUpdate = (key, value) => {
    const filters = Object.assign({}, this.props.filters);

    if (value) {
      filters[key] = value;
    } else {
      delete filters[key];
    }

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: stringify(filters),
    });
  };

  render() {
    const { filters, event, memberFilterShown } = this.props;
    const users = uniqBy(
      [
        ...event.staffers.edges.map(edge => edge.node.user),
        ...event.team.users.edges.map(edge => edge.node),
      ],
      'id',
    );

    if (!memberFilterShown) return <div />;

    return (
      <div className="schedule-filters">
        <Filters
          onFilter={this.handleFilterUpdate}
          onReset={this.handleFilterUpdate}
          filters={filters}
          name="schedule"
        >
          {memberFilterShown && (
            <FiltersItem
              name="member"
              text="Participant"
              searchable
              items={users.map(user => ({
                value: user.slug,
                text: fullNameOfUser(user),
                user,
              }))}
            />
          )}
        </Filters>
      </div>
    );
  }
}

export default createFragmentContainer(
  withRouter(ScheduleFilters),
  graphql`
    fragment ScheduleFilters_event on Event {
      staffers {
        edges {
          node {
            user {
              id
              firstName
              lastName
              avatar
              email
              slug
            }
          }
        }
      }
      team {
        users {
          edges {
            node {
              id
              firstName
              lastName
              email
              avatar
              slug
            }
          }
        }
      }
    }
  `,
);
