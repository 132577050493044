/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import TextInput from 'components/material/TextInput';

const Container = styled.div`
  ${props =>
    props.editing
      ? css`
          margin-bottom: 2px;
        `
      : css`
          padding-bottom: 1px;
        `};
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  padding: 3px 10px;
`;

const OtherRow = styled.div`
  padding: 5px 10px;
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
`;

export default class AddNewRow extends React.PureComponent<
  {
    label?: string,
    placeholder?: string,
    onSave: string => void,
    onAddNew?: () => void,
  },
  {
    editing: boolean,
  },
> {
  state = {
    editing: false,
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.currentTarget.value) {
      e.preventDefault();
      this.props.onSave(e.currentTarget.value);
      this.setState({ editing: false });
    }
  };

  handleAddClick = () => {
    this.setState({ editing: true });
    if (this.props.onAddNew) {
      this.props.onAddNew();
    }
  };

  handleBlur = () => {
    this.setState({ editing: false });
  };

  render() {
    return (
      <Container editing={this.state.editing}>
        {this.state.editing ? (
          <form>
            <StyledTextInput
              autoFocus
              placeholder={this.props.placeholder || ''}
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
            />
          </form>
        ) : (
          <OtherRow onClick={this.handleAddClick}>{this.props.label || '+ Add'}</OtherRow>
        )}
      </Container>
    );
  }
}
