/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  ConnectMarketoAccountInput,
  connectMarketoAccountMutationResponse,
  connectMarketoAccountMutationVariables,
} from './__generated__/connectMarketoAccountMutation.graphql';

const mutation = graphql`
  mutation connectMarketoAccountMutation($input: ConnectMarketoAccountInput!) {
    connectMarketoAccount(input: $input) {
      updatedOrg {
        marketoAccount {
          id
          username
          syncStatus {
            ...SyncStatusIndicator_status
          }
        }
      }
    }
  }
`;

export type connectMarketoAccountMutationInput = $Diff<
  ConnectMarketoAccountInput,
  { clientMutationId?: ?string },
>;

export default function connectMarketoAccount(
  args: ConnectMarketoAccountInput,
): Promise<connectMarketoAccountMutationResponse> {
  const variables: connectMarketoAccountMutationVariables = {
    input: {
      ...args,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
