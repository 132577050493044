/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import { type SectionFilterType } from 'components/FieldSettings/types';

import type {
  createCustomFieldSectionMutationVariables,
  createCustomFieldSectionMutationResponse,
} from './__generated__/createCustomFieldSectionMutation.graphql';

const mutation = graphql`
  mutation createCustomFieldSectionMutation($input: CreateCustomFieldSectionInput!) {
    createCustomFieldSection(input: $input) {
      customFieldSectionEdge {
        __typename
        node {
          id
          name
          order
        }
      }
    }
  }
`;

export default function createCustomFieldSection(
  orgId: string,
  inputVariables: {
    ...$PropertyType<createCustomFieldSectionMutationVariables, 'input'>,
  },
  filters: SectionFilterType,
): Promise<
  $PropertyType<
    $PropertyType<
      $NonMaybeType<
        $PropertyType<createCustomFieldSectionMutationResponse, 'createCustomFieldSection'>,
      >,
      'customFieldSectionEdge',
    >,
    'node',
  >,
> {
  return commitModernMutation({
    mutation,
    variables: { input: inputVariables },
    updater: addRecordToConnection({
      parentId: orgId,
      edgeName: 'customFieldSectionEdge',
      connections: [
        {
          field: 'customFieldSections',
          args: filters,
        },
      ],
    }),
  }).then((response: createCustomFieldSectionMutationResponse) => {
    if (
      response.createCustomFieldSection &&
      response.createCustomFieldSection.customFieldSectionEdge.node
    ) {
      return response.createCustomFieldSection.customFieldSectionEdge.node;
    }
    throw new Error('could not create a custom field section');
  });
}
