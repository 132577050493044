/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactAttendanceJoinTime_eventContact$ref: FragmentReference;
export type ContactAttendanceJoinTime_eventContact = {|
  +zoomRegistrant: ?{|
    +times: $ReadOnlyArray<{|
      +startTime: any,
      +endTime: any,
    |}>
  |},
  +g2wRegistrant: ?{|
    +times: $ReadOnlyArray<{|
      +startTime: any,
      +endTime: any,
    |}>
  |},
  +$refType: ContactAttendanceJoinTime_eventContact$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "times",
    "storageKey": null,
    "args": null,
    "concreteType": "TimeRangeType",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "startTime",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endTime",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "ContactAttendanceJoinTime_eventContact",
  "type": "EventContactEdge",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "zoomRegistrant",
      "storageKey": null,
      "args": null,
      "concreteType": "ZoomRegistrant",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "g2wRegistrant",
      "storageKey": null,
      "args": null,
      "concreteType": "G2wRegistrant",
      "plural": false,
      "selections": v0
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '74351ff53e5a5a7d1c9ea76df96088e5';
module.exports = node;
