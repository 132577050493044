/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import DividerIcon from 'images/registration/divider.svg';
import EmbedIcon from 'images/registration/embed.svg';
import EventDatesIcon from 'images/registration/eventDates.svg';
import EventNameIcon from 'images/registration/eventName.svg';
import ImageIcon from 'images/registration/image.svg';
import RowIcon from 'images/registration/row.svg';
import TextIcon from 'images/registration/text.svg';
import VideoIcon from 'images/registration/video.svg';

import {
  DividerComponentContent,
  EmbedComponentContent,
  EventDatesComponentContent,
  EventNameComponentContent,
  FormComponentContent,
  ImageComponentContent,
  RowComponentContent,
  TextComponentContent,
  VideoComponentContent,
} from './RegistrationPageContent/components';
import DividerComponentStyles from './RegistrationPageProperties/RegistrationPageComponents/DividerComponent';
import EmbedComponentStyles from './RegistrationPageProperties/RegistrationPageComponents/EmbedComponent';
import EventDatesComponentStyles from './RegistrationPageProperties/RegistrationPageComponents/EventDatesComponent';
import EventNameComponentStyles from './RegistrationPageProperties/RegistrationPageComponents/EventNameComponent';
import ImageComponentStyles from './RegistrationPageProperties/RegistrationPageComponents/ImageComponent';
import RowComponentStyles from './RegistrationPageProperties/RegistrationPageComponents/RowComponent';
import TextComponentStyles from './RegistrationPageProperties/RegistrationPageComponents/TextComponent';
import VideoComponentStyles from './RegistrationPageProperties/RegistrationPageComponents/VideoComponent';

const StyledVideoIcon = styled(VideoIcon)`
  color: #8a8fa6;
`;

const StyledEmbedIcon = styled(EmbedIcon)`
  color: #8a8fa6;
`;

type StyleComponentType =
  | typeof EventNameComponentStyles
  | typeof EventDatesComponentStyles
  | typeof ImageComponentStyles
  | typeof TextComponentStyles
  | typeof DividerComponentStyles
  | typeof RowComponentStyles
  | typeof EmbedComponentStyles
  | typeof VideoComponentStyles;

export type ContentComponentType =
  | typeof EventNameComponentContent
  | typeof EventDatesComponentContent
  | typeof ImageComponentContent
  | typeof VideoComponentContent
  | typeof TextComponentContent
  | typeof DividerComponentContent
  | typeof EmbedComponentContent
  | typeof RowComponentContent
  | typeof FormComponentContent;

export type PageComponentKindType =
  | 'EVENT_NAME'
  | 'EVENT_DATES'
  | 'IMAGE'
  | 'TEXT'
  | 'DIVIDER'
  | 'ROW'
  | 'FORM'
  | 'COLUMN'
  | 'EMBED'
  | 'VIDEO';

export type PageComponentConfigType = {
  label: string,
  kind: PageComponentKindType,
  icon: React.Node,
  styleComponent: StyleComponentType,
  contentComponent: ContentComponentType,
};

export type ReorderedPageComponentConfigType = {
  parentId: ?string,
  order: number,
  kind: PageComponentKindType,
  id?: string,
};

export type DragableComponentType = {
  id?: string,
  order?: number,
  kind: PageComponentKindType,
};

export type DragToColumnPropsType = {
  id: string,
  columnId: string,
  rowId: string,
};

export type PageComponentType = {
  +id: string,
  +order: number,
  +kind: PageComponentKindType,
  +childPageComponents: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +columnChild: ?{|
          +id: string,
        |},
      |},
    |}>,
  |},
};

const pageComponentsConfig: $ReadOnlyArray<PageComponentConfigType> = [
  {
    label: 'Event Name',
    kind: 'EVENT_NAME',
    icon: <EventNameIcon />,
    styleComponent: EventNameComponentStyles,
    contentComponent: EventNameComponentContent,
  },
  {
    label: 'Event Dates',
    kind: 'EVENT_DATES',
    icon: <EventDatesIcon />,
    styleComponent: EventDatesComponentStyles,
    contentComponent: EventDatesComponentContent,
  },
  {
    label: 'Image',
    kind: 'IMAGE',
    icon: <ImageIcon />,
    styleComponent: ImageComponentStyles,
    contentComponent: ImageComponentContent,
  },
  {
    label: 'Text',
    kind: 'TEXT',
    icon: <TextIcon />,
    styleComponent: TextComponentStyles,
    contentComponent: TextComponentContent,
  },
  {
    label: 'Column',
    kind: 'ROW',
    icon: <RowIcon />,
    styleComponent: RowComponentStyles,
    contentComponent: RowComponentContent,
  },
  {
    label: 'Video',
    kind: 'VIDEO',
    icon: <StyledVideoIcon />,
    styleComponent: VideoComponentStyles,
    contentComponent: VideoComponentContent,
  },
  {
    label: 'Embed',
    kind: 'EMBED',
    icon: <StyledEmbedIcon />,
    styleComponent: EmbedComponentStyles,
    contentComponent: EmbedComponentContent,
  },
  {
    label: 'Divider',
    kind: 'DIVIDER',
    icon: <DividerIcon />,
    styleComponent: DividerComponentStyles,
    contentComponent: DividerComponentContent,
  },
];

export default pageComponentsConfig;
