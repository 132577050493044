/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import AccessibilityOptions from 'components/EventRequestForm/AccessibilityOptions';
import EditableDeclineReasons from 'components/EventRequestForm/EditableDeclineReasons';
import EditableEmailTemplate from 'components/EventRequestForm/EditableEmailTemplate';
import EditableRequestReviewers from 'components/EventRequestForm/EditableRequestReviewers';
import EditableSubmitMessage from 'components/EventRequestForm/EditableSubmitMessage';
import type {
  AccessibilityOptionType,
  FormDetailsType,
} from 'components/EventRequestForm/lib/types';
import PublishingOptions from 'components/EventRequestForm/PublishingOptions';
import type { Participant } from 'components/Participants';
import { type TemplateType } from 'components/TemplateSearch';

import ChecklistTemplateSettings from './components/ChecklistTemplateSettings';
import EventRequestFormContainer from './components/EventRequestFormContainer';
import EventRequestFormBuilderFooter from './EventRequestFormBuilderFooter';

import type { EventRequestFormBuilderSettings_org } from './__generated__/EventRequestFormBuilderSettings_org.graphql';

const Container = styled(EventRequestFormContainer)`
  padding: 30px 80px;
`;

const Divider = styled.div`
  margin: 0 -80px;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.48);
`;

const EmailsHeader = styled.div`
  margin: 0 -80px;
  padding: 11px 80px;
  background: #f1f9fc;
  color: rgba(74, 86, 101, 0.87);
  font-size: 18px;
`;

const ErrorMessage = styled.div`
  margin-top: -20px;
  margin-bottom: 10px;
  color: #dd3b3b;
`;

class EventRequestFormBuilderSettings extends React.PureComponent<
  {
    org: EventRequestFormBuilderSettings_org,
    requestForm: FormDetailsType,
    reviewers: $ReadOnlyArray<Participant>,
    onChangeFormDetails: (details: $Shape<FormDetailsType>, cb?: () => void) => void,
    onReviewersChange: (reviewers: $ReadOnlyArray<Participant>) => void,
    onNextStep: () => void,
    onPrevStep: () => void,
  },
  {
    errors: { [string]: boolean },
  },
> {
  state = {
    errors: {},
  };

  handleChangeAccessibility = (accessibility: AccessibilityOptionType) => {
    this.props.onChangeFormDetails({ accessibility });
  };

  handleChangePublishingOptions = (options: { showInEventsDashboard: boolean }) => {
    this.props.onChangeFormDetails(options);
  };

  handleChangeSubmitMessage = (submitMessage: string) => {
    this.props.onChangeFormDetails({ submitMessage });
    this.setState(state => ({
      errors: {
        ...state.errors,
        submitMessage: !submitMessage.trim(),
      },
    }));
  };

  handleToggleDeclineReason = (declineReasonId: string) => {
    const requestForm = this.props.requestForm;
    const declineReasonIds = requestForm.declineReasonIds.includes(declineReasonId)
      ? requestForm.declineReasonIds.filter(reasonId => reasonId !== declineReasonId)
      : [...requestForm.declineReasonIds, declineReasonId];

    this.props.onChangeFormDetails({
      declineReasonIds,
    });
    this.setState(state => ({
      errors: {
        ...state.errors,
        declineReason: declineReasonIds.length === 0,
      },
    }));
  };

  emailTemplateChangeHandler = (name: string) => (template: string) => {
    this.props.onChangeFormDetails({ [name]: template });
    this.setState(state => ({ errors: { ...state.errors, [name]: !template.trim() } }));
  };

  hasError = errors => Object.keys(errors).some(key => errors[key]);

  handleReviewerAdd = (user: Participant) => {
    this.props.onChangeFormDetails({
      reviewerIds: [...this.props.requestForm.reviewerIds, user.id],
    });
    this.props.onReviewersChange([...this.props.reviewers, user]);
  };

  handleReviewerRemove = (user: Participant) => {
    this.props.onChangeFormDetails({
      reviewerIds: this.props.requestForm.reviewerIds.filter(id => id !== user.id),
    });
    this.props.onReviewersChange(this.props.reviewers.filter(r => r.id !== user.id));
  };

  handleToggleNotifiyReviewers = () => {
    this.props.onChangeFormDetails({ notifyReviewers: !this.props.requestForm.notifyReviewers });
  };

  handleChangeEventTemplate = (eventTemplate: ?TemplateType) => {
    this.props.onChangeFormDetails({ eventTemplate, copyTimeline: false });
  };

  handleToggleCopyTimeline = () => {
    this.props.onChangeFormDetails({ copyTimeline: !this.props.requestForm.copyTimeline });
  };

  handleNext = () => {
    const { requestForm } = this.props;
    const errors = {
      submitMessage: !requestForm.submitMessage.trim(),
      declineReason: requestForm.declineReasonIds.length === 0,
      submitEmailTemplate: !requestForm.submitEmailTemplate.trim(),
      approveEmailTemplate: !requestForm.approveEmailTemplate.trim(),
      declineEmailTemplate: !requestForm.declineEmailTemplate.trim(),
      returnEmailTemplate: !requestForm.returnEmailTemplate.trim(),
    };

    if (!this.hasError(errors)) {
      this.props.onNextStep();
    } else {
      this.setState({ errors });
    }
  };

  handleBack = () => {
    const { requestForm } = this.props;
    const errors = {
      submitMessage: !requestForm.submitMessage.trim(),
      declineReason: requestForm.declineReasonIds.length === 0,
      submitEmailTemplate: !requestForm.submitEmailTemplate.trim(),
      approveEmailTemplate: !requestForm.approveEmailTemplate.trim(),
      declineEmailTemplate: !requestForm.declineEmailTemplate.trim(),
      returnEmailTemplate: !requestForm.returnEmailTemplate.trim(),
    };

    if (!this.hasError(errors)) {
      this.props.onPrevStep();
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const {
      org,
      reviewers,
      requestForm: {
        accessibility,
        submitMessage,
        declineReasonIds,
        reviewerIds,
        submitEmailTemplate,
        approveEmailTemplate,
        declineEmailTemplate,
        returnEmailTemplate,
        notifyReviewers,
        showInEventsDashboard,
      },
      requestForm,
    } = this.props;
    return (
      <React.Fragment>
        <Container>
          <AccessibilityOptions
            accessibility={accessibility}
            onChange={this.handleChangeAccessibility}
            ssoEnabled={this.props.org.hasSamlProvider}
          />
          <Divider />
          <PublishingOptions
            showInEventsDashboard={showInEventsDashboard}
            onChange={this.handleChangePublishingOptions}
          />
          <Divider />
          <EditableSubmitMessage
            submitMessage={submitMessage}
            onChange={this.handleChangeSubmitMessage}
          />
          {this.state.errors.submitMessage && (
            <ErrorMessage>Submit message is required</ErrorMessage>
          )}
          <Divider />
          <EditableDeclineReasons
            org={org}
            selected={declineReasonIds}
            onToggleDeclineReason={this.handleToggleDeclineReason}
          />
          {this.state.errors.declineReason && <ErrorMessage>Required</ErrorMessage>}
          <Divider />
          <EditableRequestReviewers
            onSelectReviewer={this.handleReviewerAdd}
            selecetedReviewerIds={reviewerIds}
            reviewers={reviewers}
            onRemoveReviewer={this.handleReviewerRemove}
            notifyReviewers={notifyReviewers}
            onToggleNotifyReviewers={this.handleToggleNotifiyReviewers}
          />
          <Divider />
          <ChecklistTemplateSettings
            onToggleCopyTimeline={this.handleToggleCopyTimeline}
            onChangeTemplate={this.handleChangeEventTemplate}
            requestForm={requestForm}
          />
          <Divider />
          <EmailsHeader>Email Settings</EmailsHeader>
          <EditableEmailTemplate
            title="Post-Submit Confirmation Email"
            template={submitEmailTemplate}
            onChange={this.emailTemplateChangeHandler('submitEmailTemplate')}
            tags={['First Name', 'Last Name', 'Event Name', 'Form Name']}
          />
          {this.state.errors.submitEmailTemplate && (
            <ErrorMessage>
              Cannot be blank (contact support if you don&apos;t want to send email notifications)
            </ErrorMessage>
          )}
          <Divider />
          <EditableEmailTemplate
            title="Request Approved Email"
            template={approveEmailTemplate}
            onChange={this.emailTemplateChangeHandler('approveEmailTemplate')}
            tags={['First Name', 'Last Name', 'Event Name', 'Form Name', 'Reviewer Notes']}
          />
          {this.state.errors.approveEmailTemplate && (
            <ErrorMessage>
              Cannot be blank (contact support if you don&apos;t want to send email notifications)
            </ErrorMessage>
          )}
          <Divider />
          <EditableEmailTemplate
            title="Request Declined Email"
            template={declineEmailTemplate}
            onChange={this.emailTemplateChangeHandler('declineEmailTemplate')}
            tags={[
              'First Name',
              'Last Name',
              'Event Name',
              'Form Name',
              'Declined Reason',
              'Reviewer Notes',
            ]}
          />
          {this.state.errors.declineEmailTemplate && (
            <ErrorMessage>
              Cannot be blank (contact support if you don&apos;t want to send email notifications)
            </ErrorMessage>
          )}
          <Divider />
          <EditableEmailTemplate
            title="Request Returned Email"
            template={returnEmailTemplate}
            onChange={this.emailTemplateChangeHandler('returnEmailTemplate')}
            tags={['First Name', 'Last Name', 'Event Name', 'Form Name', 'Reviewer Notes']}
          />
          {this.state.errors.returnEmailTemplate && (
            <ErrorMessage>
              Cannot be blank (contact support if you don&apos;t want to send email notifications)
            </ErrorMessage>
          )}
        </Container>

        <EventRequestFormBuilderFooter
          activeStepIndex={2}
          onNextStep={this.handleNext}
          onPrevStep={this.handleBack}
          errors={this.hasError(this.state.errors) ? ['There are some errors in settings'] : []}
          loading={false}
        />
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  EventRequestFormBuilderSettings,
  graphql`
    fragment EventRequestFormBuilderSettings_org on Org {
      hasSamlProvider
      ...EditableDeclineReasons_org
    }
  `,
);
