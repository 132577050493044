/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import type { Location, Match } from 'react-router-dom';

import ContactsRoutes from './ContactsRoutes';
import headerTitles from './lib/headerTitles';

type Props = { location: Location, match: Match };

const AllContacts = ({ match, location }: Props) => {
  const title = headerTitles[location.pathname.split('/').pop()] || headerTitles.people;

  return (
    <DocumentTitle title={title}>
      <ContactsRoutes pathPrefix={match.path} />
    </DocumentTitle>
  );
};

export default AllContacts;
