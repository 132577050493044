/* @flow */
import React from 'react';
import { type Location, type Match, type RouterHistory } from 'react-router-dom';

import EventRequestsContainer from './EventRequestsContainer';
import SubmissionReview from './SubmissionReview';

export default class EventRequests extends React.Component<{
  location: Location,
  history: RouterHistory,
  match: Match,
  viewerCanUpdate: boolean,
}> {
  render() {
    const { location, history, match, viewerCanUpdate } = this.props;
    return (
      <>
        <EventRequestsContainer
          location={location}
          history={history}
          viewerCanUpdate={viewerCanUpdate}
        />

        <SubmissionReview location={location} history={history} match={match} />
      </>
    );
  }
}
