/**
 * @flow
 * @relayHash 96f87dead57ec7304da070e90db4467d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
export type UpdateEmbedComponentInput = {
  clientMutationId?: ?string,
  id: string,
  code?: ?string,
  padding?: ?string,
  alignment?: ?Alignment,
  backgroundColor?: ?string,
};
export type updateEmbedComponentMutationVariables = {|
  input: UpdateEmbedComponentInput
|};
export type updateEmbedComponentMutationResponse = {|
  +updateRegistrationFormEmbedComponent: ?{|
    +pageComponent: {|
      +embedComponent: ?{|
        +id: string,
        +code: ?string,
        +padding: string,
        +alignment: Alignment,
        +backgroundColor: ?string,
      |}
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type updateEmbedComponentMutation = {|
  variables: updateEmbedComponentMutationVariables,
  response: updateEmbedComponentMutationResponse,
|};
*/


/*
mutation updateEmbedComponentMutation(
  $input: UpdateEmbedComponentInput!
) {
  updateRegistrationFormEmbedComponent(input: $input) {
    pageComponent {
      embedComponent {
        id
        code
        padding
        alignment
        backgroundColor
      }
      id
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEmbedComponentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateEmbedComponentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "embedComponent",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationEmbedComponent",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "padding",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "alignment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundColor",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEmbedComponentMutation",
  "id": null,
  "text": "mutation updateEmbedComponentMutation(\n  $input: UpdateEmbedComponentInput!\n) {\n  updateRegistrationFormEmbedComponent(input: $input) {\n    pageComponent {\n      embedComponent {\n        id\n        code\n        padding\n        alignment\n        backgroundColor\n      }\n      id\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEmbedComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormEmbedComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEmbedComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3
            ]
          },
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEmbedComponentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormEmbedComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEmbedComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3,
              v2
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4bf5fe779c8e0f87c89dae0e76b2f62';
module.exports = node;
