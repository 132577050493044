/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Tooltip from 'components/material/Tooltip';
import type { OpportunityTableSourceTab } from 'components/Opportunities/OpportunitiesTableColumnSettings';

import type { OpportunityCompany_opportunity } from './__generated__/OpportunityCompany_opportunity.graphql';

const SyncIcon = styled.i`
  font-size: 12px;
  color: ${props => props.theme.primaryActionColor};
`;

const IconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 13px;
`;

class OpportunityCompany extends React.Component<{
  eventSlug?: string,
  opportunity: OpportunityCompany_opportunity,
  location: Location,
  fromTab: OpportunityTableSourceTab,
}> {
  companyPath = (): string => {
    const { opportunity, eventSlug, fromTab } = this.props;
    // for flow to not complain, at this moment company exists for sure
    if (!opportunity.company) {
      return '';
    }

    if (fromTab === 'event_contacts_opportunities' && eventSlug != null) {
      return `/events/${eventSlug}/contacts/companies/${opportunity.company.id}/profile`;
    }
    return `/contacts/companies/${opportunity.company.id}/profile`;
  };

  render() {
    const { fromTab, opportunity, location } = this.props;
    return opportunity.company ? (
      <Link
        to={{
          pathname: this.companyPath(),
          state: {
            from: fromTab,
            fromUrl: `${location && location.pathname ? location.pathname : ''}${
              location && location.search ? location.search : ''
            }`,
          },
        }}
      >
        {opportunity.company.name}
        <IconContainer>
          <Tooltip label="Salesforce account" placement="top">
            {opportunity.company.salesforceId && <SyncIcon className="fa fa-fw fa-refresh" />}
          </Tooltip>
        </IconContainer>
      </Link>
    ) : null;
  }
}

export default createFragmentContainer(
  withRouter(OpportunityCompany),
  graphql`
    fragment OpportunityCompany_opportunity on SalesforceOpportunityType {
      company {
        id
        salesforceId
        name
      }
    }
  `,
);
