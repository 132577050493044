/* @flow */
import { graphql } from 'react-relay';

import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  RemoveSolutionInstanceInput,
  removeSolutionInstanceMutationResponse,
} from './__generated__/removeSolutionInstanceMutation.graphql';

const mutation = graphql`
  mutation removeSolutionInstanceMutation($input: RemoveSolutionInstanceInput!) {
    removeSolutionInstance(input: $input) {
      removedTraySolutionInstanceId
    }
  }
`;

const removeSolutionInstance = (
  input: RemoveSolutionInstanceInput,
): Promise<removeSolutionInstanceMutationResponse> =>
  commitModernMutation({
    mutation,
    variables: {
      input,
    },
    optimisticResponse: {
      removeSolutionInstance: {
        removedTraySolutionInstanceId: input.traySolutionInstanceId,
      },
    },
    updater: removeRecordFromConnection({
      parentId: input.integrableId,
      connections: [
        { field: 'traySolutionInstances' },
        { field: 'traySolutionInstances', args: { includeExpired: true } },
      ],
      deletedIdField: 'removedTraySolutionInstanceId',
    }),
  });

export default removeSolutionInstance;
