import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';

import fullNameOfUser from 'utils/fullNameOfUser';

import ScheduleViewMultiple from 'images/schedule-multiple.svg';
import ScheduleViewSingle from 'images/schedule-single.svg';
import HintLink from 'components/Hint';
import ShareDropdown from 'components/ShareDropdown';
import ViewModes from 'components/ViewModes';

import SchedulesHeaderItem from './SchedulesHeaderItem';

class SchedulesHeader extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      viewerCanCreateSchedules: PropTypes.bool.isRequired,
      team: PropTypes.shape({
        scheduleName: PropTypes.string,
        users: PropTypes.shape({
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape({
                slug: PropTypes.string.isRequired,
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
              }).isRequired,
            }),
          ).isRequired,
        }).isRequired,
      }).isRequired,
      schedules: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string.isRequired,
              order: PropTypes.number.isRequired,
              token: PropTypes.string.isRequired,
            }).isRequired,
          }),
        ).isRequired,
      }).isRequired,
    }).isRequired,
    activeScheduleId: PropTypes.string,
    onScheduleChange: PropTypes.func.isRequired,
    onPrintClick: PropTypes.func.isRequired,
    onICalClick: PropTypes.func.isRequired,
    onWordClick: PropTypes.func.isRequired,
    onShareClick: PropTypes.func.isRequired,
    onScheduleCreate: PropTypes.func.isRequired,
    onScheduleUpdate: PropTypes.func.isRequired,
    onScheduleCopy: PropTypes.func.isRequired,
    onScheduleRemove: PropTypes.func.isRequired,
    onViewChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({
      member: PropTypes.string,
    }),
  };

  state = {
    dragPreview: null,
  };

  getNewOrder = (scheduleId, targetId) => {
    const schedules = this.renderingSchedules.slice();

    const scheduleIndex = schedules.findIndex(x => x.id === scheduleId);
    const originalTargetIndex = schedules.findIndex(x => x.id === targetId);
    schedules.splice(scheduleIndex, 1);
    const targetIndex = schedules.findIndex(x => x.id === targetId);

    const target = schedules[targetIndex];

    let order = 0;
    if (scheduleIndex < originalTargetIndex) {
      // moving down
      const next = schedules[targetIndex + 1];
      if (next) {
        order = (target.order + next.order) / 2;
      } else {
        order = target.order + 10000;
      }
    } else {
      // moving up
      const prev = schedules[targetIndex - 1];
      if (prev) {
        order = (target.order + prev.order) / 2;
      } else {
        order = target.order - 10000;
      }
    }

    return order;
  };

  getNextOrderNumber = () => {
    const schedules = this.renderingSchedules;
    const lastSchedule = schedules[schedules.length - 1];

    return lastSchedule ? lastSchedule.order + 10000 : 0;
  };

  handleScheduleCreate = () => {
    const order = this.getNextOrderNumber();

    this.props.onScheduleCreate({
      name: this.props.event.team.scheduleName,
      order,
    });
  };

  handleScheduleCopy = scheduleId => {
    const order = this.getNextOrderNumber();

    this.props.onScheduleCopy(scheduleId, order);
  };

  handleDragPreview = (scheduleId, targetId) => {
    this.setState({
      dragPreview: { id: scheduleId, order: this.getNewOrder(scheduleId, targetId) },
    });
  };

  handleDragEnd = () => {
    if (this.state.dragPreview) {
      this.props.onScheduleUpdate(this.state.dragPreview.id, {
        order: this.state.dragPreview.order,
      });
      this.setState({ dragPreview: null });
    }
  };

  render() {
    const {
      activeScheduleId,
      onScheduleChange,
      onPrintClick,
      onScheduleUpdate,
      onScheduleRemove,
      onICalClick,
      filters,
      event,
      onViewChange,
      onShareClick,
    } = this.props;
    let schedules = event.schedules.edges.map(x => x.node);
    const { dragPreview } = this.state;

    const activeSchedule = schedules.find(x => x.id === activeScheduleId);

    const canAddSchedule = this.props.event.viewerCanCreateSchedules && schedules.length < 5;

    if (dragPreview) {
      const clonedSchedules = this.renderingSchedules.slice();
      const draggingScheduleIndex = clonedSchedules.findIndex(x => x.id === dragPreview.id);

      clonedSchedules[draggingScheduleIndex] = Object.assign(
        {},
        clonedSchedules[draggingScheduleIndex],
        {
          order: dragPreview.order,
        },
      );

      schedules = clonedSchedules;
    }
    this.renderingSchedules = sortBy(schedules, 'order');

    const filteredUser = filters.member
      ? event.team.users.edges.map(x => x.node).find(x => x.slug === filters.member)
      : null;
    const filteredUserName = filteredUser ? fullNameOfUser(filteredUser) : '';

    return (
      <div className="schedules-header">
        <div className="schedules-header-items">
          {this.renderingSchedules.map((schedule, index) => {
            const prev = this.renderingSchedules[index - 1];
            const next = this.renderingSchedules[index + 1];

            return (
              <SchedulesHeaderItem
                key={schedule.id}
                schedule={schedule}
                active={activeScheduleId === schedule.id}
                onSelect={onScheduleChange}
                onUpdate={onScheduleUpdate}
                onCopy={this.handleScheduleCopy}
                onRemove={onScheduleRemove}
                onPrint={onPrintClick}
                onICalClick={onICalClick}
                onPublish={onShareClick}
                onDragPreview={this.handleDragPreview}
                onDrop={this.handleDragEnd}
                nextId={next && next.id}
                prevId={prev && prev.id}
                getNewOrder={this.getNewOrder}
                viewerCanCreateSchedules={this.props.event.viewerCanCreateSchedules}
              />
            );
          })}
          {canAddSchedule && (
            <a onClick={this.handleScheduleCreate} data-tooltip="Add Schedule">
              +
            </a>
          )}
        </div>
        <HintLink to="https://help.circa.co/102298-schedules/how-do-i-create-share-and-manage-event-schedules" />
        <div className="schedules-actions">
          {schedules.length > 0 && (
            <ViewModes
              modes={[
                { label: 'Single', value: 'single', svg: ScheduleViewSingle },
                { label: 'Grid', value: 'multi', svg: ScheduleViewMultiple },
              ]}
              onViewModeChange={value => value === 'multi' && onViewChange()}
              viewMode="single"
            />
          )}
          {activeSchedule && (
            <ShareDropdown
              options={[
                this.props.event.viewerCanCreateSchedules
                  ? {
                      label: 'Publish to Web',
                      icon: 'link',
                      onClick: () => this.props.onShareClick(activeSchedule.token),
                    }
                  : null,
                {
                  label: filteredUserName
                    ? 'Send current view to external calendar'
                    : 'Send to external calendar',
                  icon: 'calendar',
                  onClick: () => this.props.onICalClick(activeSchedule.token),
                },
                {
                  label: filteredUserName ? 'Print current view (PDF)' : 'Print (PDF)',
                  icon: 'file-pdf-o',
                  onClick: () => this.props.onPrintClick(activeSchedule.id),
                },
                {
                  label: filteredUserName
                    ? 'Export current view (Google Doc)'
                    : 'Export (Google Doc)',
                  icon: 'file-text-o',
                  onClick: () => this.props.onWordClick(activeSchedule.token),
                },
              ].filter(Boolean)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(
  SchedulesHeader,
  graphql`
    fragment SchedulesHeader_event on Event {
      viewerCanCreateSchedules
      team {
        scheduleName
        users {
          edges {
            node {
              firstName
              lastName
              avatar
              slug
              email
            }
          }
        }
      }
      schedules {
        edges {
          node {
            id
            order
            token
            shared
            ...SchedulesHeaderItem_schedule
          }
        }
      }
    }
  `,
);
