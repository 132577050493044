/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateStafferMutationResponse,
  updateStafferMutationVariables,
} from './__generated__/updateStafferMutation.graphql';

const mutation = graphql`
  mutation updateStafferMutation($input: UpdateStafferInput!) {
    updateStaffer(input: $input) {
      staffMembership {
        id
        onsite
      }
    }
  }
`;

export type StafferInput = $PropertyType<updateStafferMutationVariables, 'input'>;

export default function updateStaffer(input: StafferInput): Promise<updateStafferMutationResponse> {
  const { membershipId, onsite } = input;

  return commitModernMutation({
    mutation,
    variables: { input },
    optimisticResponse: {
      updateStaffer: {
        staffMembership: {
          id: membershipId,
          onsite,
        },
      },
    },
  });
}
