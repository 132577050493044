/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import intersection from 'lodash/intersection';
import moment from 'moment';

import downloadedCompanies from 'utils/analytics/downloadedCompanies';
import type { FieldType } from 'utils/customization/types';

import addCompanyAssignments from 'graph/mutations/event/addCompanyAssignments';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/budget/Button';
import CompaniesMassUpdatePanel from 'components/Companies/CompaniesMassUpdatePanel';
import {
  type InputVariableFilters,
  getColumnsShowConfig,
} from 'components/Companies/companiesTableColumnSettings';
import getCompanyFilterConverters from 'components/Companies/lib/getCompanyFilterConverters';
import { type ParsedCompaniesFilters } from 'components/Companies/lib/parseCompaniesFilters';
import renderCompanyFilter from 'components/Companies/lib/renderCompanyFilter';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ColumnFilter from 'components/material/ColumnFilters';
import AdvancedFilter from 'components/material/Filters/Advanced/AdvancedFilter';
import Search from 'components/Search';
import ShareDropdown from 'components/ShareDropdown';

import SearchSuggestionBar from '../components/SearchSuggestionBar';
import AddCompanyWindow from './AddCompanyWindow';

import type { EventCompaniesHeaderBar_event } from './__generated__/EventCompaniesHeaderBar_event.graphql';
import type { EventCompaniesHeaderBar_org } from './__generated__/EventCompaniesHeaderBar_org.graphql';
import type { EventCompaniesHeaderBarQueryResponse } from './__generated__/EventCompaniesHeaderBarQuery.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px 15px;
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 30px;
  padding-top: 9px;
`;

const StyledSearch = styled(Search)`
  max-width: 300px;
`;

const ActionsDropdown = styled(ShareDropdown)`
  padding-left: 0;
  border-left: none;
`;

const query = graphql`
  query EventCompaniesHeaderBarQuery(
    $eventSlug: String!
    $filters: CompanyFilters!
    $columns: [String!]
  ) {
    event(slug: $eventSlug) {
      companyReportCSVProcessId(filters: $filters, columns: $columns)
    }
    me {
      email
    }
  }
`;

class EventCompaniesHeaderBar extends React.PureComponent<
  {
    org: EventCompaniesHeaderBar_org,
    event: EventCompaniesHeaderBar_event,
    history: RouterHistory,
    filters: ParsedCompaniesFilters,
    filterInputs: InputVariableFilters,
    companyFields: $ReadOnlyArray<FieldType>,
    companiesCount: number,
    shownColumns: $ReadOnlyArray<string>,
    onUpdateTableColumnWidths: () => void,
    currentSelectedCompanies: $ReadOnlyArray<string>,
    search: ?string,
    onSearch: (query: string) => void,
    onColumnsChange: (shownColumns: $ReadOnlyArray<string>) => void,
    allCompaniesSelected: boolean,
  },
  {
    adding: boolean,
    showSearchSuggestionBar: boolean,
    showCsvDownloadOverlay: boolean,
  },
> {
  state = {
    adding: false,
    showSearchSuggestionBar: false,
    showCsvDownloadOverlay: false,
  };

  handleCompanyAddStart = () => {
    this.setState({ adding: true });
  };

  handleCompanyAddEnd = () => {
    this.setState({ adding: false });
  };

  handleAddCompany = (companyIds: $ReadOnlyArray<string>) => {
    const { event, filterInputs } = this.props;
    addCompanyAssignments(event.id, companyIds, filterInputs).catch(showModernMutationError);
  };

  handleSearch = (queryString: string) => {
    this.setState({ showSearchSuggestionBar: true });
    this.props.onSearch(queryString);
  };

  handleSuggestionBarHide = () => {
    this.setState({ showSearchSuggestionBar: false });
  };

  handleSuggestionBarShow = () => {
    this.setState({ showSearchSuggestionBar: true });
  };

  handleCsvExport = () => {
    this.setState({ showCsvDownloadOverlay: true });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCsvDownloadOverlay: false });

    downloadedCompanies({
      eventId: this.props.event.dbId,
      eventName: this.props.event.name,
      teamId: this.props.event.team.dbId,
      teamName: this.props.event.team.name,
      fromWindow: 'event',
    });
  };

  renderDownloadOverlay = () => {
    const columns = intersection(
      this.props.companyFields.map(field => field.fieldName || field.id),
      this.props.shownColumns,
    );
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ filters: this.props.filterInputs, columns, eventSlug: this.props.event.slug }}
        renderSuccess={(response: EventCompaniesHeaderBarQueryResponse) => {
          return response.event ? (
            <DownloadOverlayWithEmail
              processUUID={response.event.companyReportCSVProcessId}
              fileName={`Workspace Companies Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleCsvDownloadEnd}
              email={response.me.email}
              exportable="companies_csv"
              onDownload={this.handleCsvExport}
            />
          ) : null;
        }}
        renderLoading={() => null}
      />
    );
  };

  render() {
    const {
      org,
      event,
      search,
      history,
      shownColumns,
      onColumnsChange,
      filters,
      filterInputs,
      companyFields,
      companiesCount,
      currentSelectedCompanies,
      onUpdateTableColumnWidths,
      allCompaniesSelected,
    } = this.props;
    const canCreate = event.viewerCanCreateCompanies;

    const companyColumns = getColumnsShowConfig(companyFields);

    return (
      <Container>
        {currentSelectedCompanies.length === 0 ? (
          <>
            {canCreate && (
              <>
                <StyledButton onClick={this.handleCompanyAddStart}>Add Company</StyledButton>
                {this.state.adding && (
                  <AddCompanyWindow
                    eventName={event.name}
                    onSave={this.handleAddCompany}
                    onCancel={this.handleCompanyAddEnd}
                    fromWindow="event companies"
                  />
                )}
              </>
            )}
            <StyledSearch
              onSearch={this.handleSearch}
              search={search}
              placeholder="Search All Companies"
              onBlur={this.handleSuggestionBarHide}
              onFocus={this.handleSuggestionBarShow}
              suggestionComponent={props => {
                return companiesCount === 0 ? (
                  <SearchSuggestionBar
                    {...props}
                    showSearchSuggestionBar={this.state.showSearchSuggestionBar}
                    type="companies"
                    eventId={event.id}
                  />
                ) : null;
              }}
            />
          </>
        ) : (
          <CompaniesMassUpdatePanel
            selectedCompanies={currentSelectedCompanies}
            eventId={event.id}
            companyFields={companyFields}
            filters={filterInputs}
            viewerCanRemoveCompanies={org.viewerCanRemoveCompanies}
            onUpdateTableColumnWidths={onUpdateTableColumnWidths}
            allCompaniesSelected={allCompaniesSelected}
            fromWindow="event companies"
          />
        )}
        <AdvancedFilter
          history={history}
          filters={filters}
          filterOptions={companyFields}
          converterFn={getCompanyFilterConverters}
          filterRenderer={renderCompanyFilter}
        />
        <ColumnFilter
          filters={companyColumns}
          shownFilters={shownColumns}
          onFilterChange={onColumnsChange}
        />
        <ActionsDropdown
          options={[
            {
              label: 'Download current view (CSV)',
              icon: 'file-excel-o',
              onClick: this.handleCsvExport,
            },
          ]}
        />
        {this.state.showCsvDownloadOverlay && this.renderDownloadOverlay()}
      </Container>
    );
  }
}

export default createFragmentContainer(EventCompaniesHeaderBar, {
  event: graphql`
    fragment EventCompaniesHeaderBar_event on Event {
      id
      dbId
      name
      slug
      viewerCanCreateCompanies
      team {
        dbId
        name
      }
    }
  `,
  org: graphql`
    fragment EventCompaniesHeaderBar_org on Org {
      viewerCanRemoveCompanies
    }
  `,
});
