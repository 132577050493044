/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';

import updateTasksStatus from 'graph/mutations/task/updateTasksStatus';

import ConfirmationWindow from 'components/ConfirmationWindow';
import TaskRowStatus from 'components/Tasks/TaskRowStatus';

import type { DeliverableStatus_deliverable } from './__generated__/DeliverableDueDate_deliverable.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -6px 0px -6px -2px;
`;

const StyledTaskRowStatus = styled(TaskRowStatus)`
  width: 26px;
  height: 26px;
  line-height: 26px;
  font-size: 18px;
`;

class DeliverableStatus extends React.PureComponent<
  {
    deliverable: DeliverableStatus_deliverable,
    location: Location,
  },
  {
    warningTaskId: ?string,
  },
> {
  state = {
    warningTaskId: null,
  };

  handleStatusChange = checked => {
    const task = this.props.deliverable;
    if (task.blockedByOtherTasks && checked) {
      this.setState({ warningTaskId: task.id });
      return;
    }

    updateTasksStatus(
      [this.props.deliverable.id],
      checked ? 'COMPLETED' : 'OPEN',
      this.props.location.pathname.includes('all_tasks') ? 'all tasks' : 'my tasks',
    );
  };

  handleConfirmTaskStatusUpdate = () => {
    if (this.state.warningTaskId) {
      updateTasksStatus(
        [this.state.warningTaskId],
        'COMPLETED',
        this.props.location.pathname.includes('all_tasks') ? 'all tasks' : 'my tasks',
      );
      this.setState({ warningTaskId: null });
    }
  };

  handleConfirmationHide = () => {
    this.setState({ warningTaskId: null });
  };

  render() {
    return (
      <Container>
        <StyledTaskRowStatus
          checked={this.props.deliverable.status === 'COMPLETED'}
          onChange={this.handleStatusChange}
          disabled={!this.props.deliverable.viewerCanUpdateStatus}
        />

        {this.state.warningTaskId &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              actionNegative={false}
              message={
                <>
                  This Task has dependencies that are still open.
                  <br />
                  Are you sure you want to mark this Task as completed?
                </>
              }
              actionLabel="Update"
              onConfirm={this.handleConfirmTaskStatusUpdate}
              onHide={this.handleConfirmationHide}
            />,
            document.body,
          )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  DeliverableStatus,
  graphql`
    fragment DeliverableStatus_deliverable on Deliverable {
      id
      status
      blockedByOtherTasks
      viewerCanUpdateStatus
    }
  `,
);
