/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import AllEventsCalendarControls from './AllEventsCalendarControls';
import AllEventsCalendarMonthView from './AllEventsCalendarMonthView';
import AllEventsCalendarWeekView from './AllEventsCalendarWeekView';

import type { AllEventsCalendarView_events } from './__generated__/AllEventsCalendarView_events.graphql';

export type AllEventsCalendarEvent = $ElementType<AllEventsCalendarView_events, 0>;

const Container = styled.div`
  margin-bottom: 15px;
  padding: 16px 29px;
  border-radius: 5px;
  background: #f2fbff;
`;

class AllEventsCalendarView extends React.Component<{
  className?: string,
  events: ?AllEventsCalendarView_events,
  month: string,
  week: ?string,
  history: RouterHistory,
  linkEventNameToBrief?: boolean,
}> {
  render() {
    const { className, history, month, week, events, linkEventNameToBrief } = this.props;

    const key = `${events ? '' : 'loading-'}${week || month}`;
    const Component = week ? AllEventsCalendarWeekView : AllEventsCalendarMonthView;

    return (
      <Container className={className}>
        <AllEventsCalendarControls history={history} month={month} week={week} loading={!events} />

        <Component
          key={key}
          week={week || ''}
          month={month}
          events={events || []}
          linkEventNameToBrief={linkEventNameToBrief}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  AllEventsCalendarView,
  graphql`
    fragment AllEventsCalendarView_events on Event @relay(plural: true) {
      id
      slug
      name
      startDate
      endDate
      tz
      team {
        primaryColor
      }
    }
  `,
);
