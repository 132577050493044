import React from 'react';
import PropTypes from 'prop-types';

export default class AttachmentsFolder extends React.Component {
  static propTypes = {
    folder: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.arrayOf(PropTypes.node),
  };

  state = {
    open: false,
  };

  handleOpenToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { folder, children } = this.props;
    const { open } = this.state;

    return (
      <div className="attachments-folder">
        <div className="attachment-item" onClick={this.handleOpenToggle}>
          <i className={`fa fa-fw fa-folder${open ? '-open' : ''}`} />
          <div className="attachment-item-main">
            <a>{folder.name}</a>
          </div>
        </div>
        {open && <div className="attachments-folder-items">{children}</div>}
      </div>
    );
  }
}
