/* @flow */
import * as React from 'react';
import { Marker } from 'react-google-maps/lib/components/Marker';

import markerIcon from '../GoogleMaps/markerIcon';
import type { InfoBoxOptionsType } from './googleMapsWrapperTypes';
import InfoBoxWrapper from './InfoBoxWrapper';

export default class MarkerWrapper extends React.Component<{
  lat: number,
  lng: number,
  slug: string,
  keyId: string,
  handleOnToggle: (markerId: string) => void,
  infoBoxOptions: ?{|
    infoBoxView?: React.ComponentType<any>,
    infoBoxOffset?: number,
    isOpen: boolean,
    customProp?: InfoBoxOptionsType,
  |},
}> {
  render() {
    const infoBoxOpened = this.props.infoBoxOptions && this.props.infoBoxOptions.isOpen;
    markerIcon.anchor = new window.google.maps.Point(12, 22);
    return (
      <Marker
        key={this.props.keyId}
        position={{ lat: this.props.lat, lng: this.props.lng }}
        onClick={() => {
          this.props.handleOnToggle(infoBoxOpened ? '' : this.props.keyId);
        }}
        icon={markerIcon}
        options={{ keyId: this.props.keyId }}
      >
        {infoBoxOpened && <InfoBoxWrapper slug={this.props.slug} {...this.props.infoBoxOptions} />}
      </Marker>
    );
  }
}
