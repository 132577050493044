/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { TasksFiltersInputType } from 'views/Main/Dashboard/Tasks/columns';

import type { TasksFromWindow } from './createTasks';

const mutation = graphql`
  mutation removeTasksMutation(
    $input: RemoveDeliverablesInput!
    $hasFilters: Boolean!
    $filters: DeliverableFilters
  ) {
    removeDeliverables(input: $input) {
      org {
        ...AllTasksPagination_totalCountDeliverables @include(if: $hasFilters)
      }
      user {
        ...MyTasksPagination_totalCountDeliverables @include(if: $hasFilters)
      }
      event {
        progress
      }
      removedDeliverableIds
      removedDeliverables {
        dbId
        name
        parent {
          subtasksCount
          completedSubtasksCount
        }
      }
    }
  }
`;

export default function removeTasks(
  taskIds: Array<string>,
  clientInput: {
    eventId?: string,
    orgId?: string,
    userId?: string,
    parentId?: string,
    filters?: TasksFiltersInputType,
    fromWindow: TasksFromWindow,
  },
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableIds: taskIds,
        fromWindow: clientInput.fromWindow,
      },
      filters: clientInput.filters,
      hasFilters: !!clientInput.filters,
    },
    updater: mergeUpdaters(
      ...[
        clientInput.eventId
          ? removeRecordFromConnection({
              deletedIdField: 'removedDeliverableIds',
              parentId: clientInput.eventId,
              connections: [
                { field: 'deliverables' },
                { key: 'TasksSectionViewContent_deliverables' },
                { key: 'TasksDueDateViewContent_deliverables' },
                { key: 'TasksCalendarView_deliverables' },
              ],
            })
          : null,
        clientInput.parentId
          ? removeRecordFromConnection({
              deletedIdField: 'removedDeliverableIds',
              parentId: clientInput.parentId,
              connections: [{ field: 'subtasks' }],
            })
          : null,
        clientInput.orgId
          ? removeRecordFromConnection({
              deletedIdField: 'removedDeliverableIds',
              parentId: clientInput.orgId,
              connections: [{ field: 'deliverables' }, { key: 'AllTasksPagination_deliverables' }],
            })
          : null,
        clientInput.userId
          ? removeRecordFromConnection({
              deletedIdField: 'removedDeliverableIds',
              parentId: clientInput.userId,
              connections: [{ field: 'deliverables' }, { key: 'MyTasksPagination_deliverables' }],
            })
          : null,
      ].filter(Boolean),
    ),
  });
}
