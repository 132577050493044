/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Button from 'components/material/Button';
import Overlay from 'components/material/Overlay';

import FolderSelect from '../FolderSelect';

import type { FolderOverlay_folders } from './__generated__/FolderOverlay_folders.graphql';

const StyledOverlay = styled(Overlay)`
  padding: 10px;
  width: 250px;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

class FolderOverlay extends React.PureComponent<
  {
    show: boolean,
    target: any,
    container: any,
    onHide: () => void,
    onMove: (folderId: ?string) => void,
    onCreate: string => Promise<string>,
    folders: Array<FolderOverlay_folders>,
  },
  {
    folderId: ?string,
  },
> {
  state = { folderId: null };

  handleMove = () => {
    this.props.onMove(this.state.folderId);
    this.props.onHide();
  };

  handleFolderChange = folderId => {
    this.setState({ folderId });
  };

  render() {
    return (
      <StyledOverlay
        show={this.props.show}
        target={this.props.target}
        onHide={this.props.onHide}
        container={this.props.container}
      >
        <FolderSelect
          folders={this.props.folders}
          folderId={this.state.folderId}
          onChange={this.handleFolderChange}
          onCreate={this.props.onCreate}
        />
        <FullWidthButton label="Move" primary onClick={this.handleMove} />
      </StyledOverlay>
    );
  }
}

export default createFragmentContainer(
  FolderOverlay,
  graphql`
    fragment FolderOverlay_folders on Folder @relay(plural: true) {
      id
      ...FolderSelect_folders
    }
  `,
);
