/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';
import qs from 'qs';

import type { TaskStatuses } from 'config/taskStatuses';

import replaceQueryParams from 'utils/routing/replaceQueryParams';
import storage from 'utils/storage';

import importEventTemplate from 'graph/mutations/event/importEventTemplate';
import createFolder from 'graph/mutations/folder/createFolder';
import reorderFolder from 'graph/mutations/folder/reorderFolder';
import updateFolder from 'graph/mutations/folder/updateFolder';
import addTasksTag from 'graph/mutations/task/addTasksTag';
import assignTasks from 'graph/mutations/task/assignTasks';
import createTasks from 'graph/mutations/task/createTasks';
import removeTasks from 'graph/mutations/task/removeTasks';
import removeTasksTag from 'graph/mutations/task/removeTasksTag';
import reorderTasks from 'graph/mutations/task/reorderTasks';
import unassignTasks from 'graph/mutations/task/unassignTasks';
import updateTasks from 'graph/mutations/task/updateTasks';
import updateTasksStatus from 'graph/mutations/task/updateTasksStatus';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import InviteWindow, { type User } from 'components/InviteWindow';
import { type TaskRow_task } from 'components/Tasks/__generated__/TaskRow_task.graphql';
import TaskAdd from 'components/Tasks/TaskAdd';

import { type ParsedTaskFilters } from './components/parseTaskFilters';
import TasksCalendarView from './TasksCalendarView';
import TasksDueDateView from './TasksDueDateView';
import TasksHeader from './TasksHeader';
import TasksSectionView from './TasksSectionView';

import type { Tasks_event } from './__generated__/Tasks_event.graphql';
import type { Tasks_org } from './__generated__/Tasks_org.graphql';

export const VIEW_MODES = ['due-date', 'section', 'calendar'];

const VIEW_MODE_STORAGE_KEY = 'checklistViewMode';

class Tasks extends React.Component<
  {
    org: Tasks_org,
    event: Tasks_event,
    filters: ParsedTaskFilters,
    history: RouterHistory,
    match: Match,
    queryParams: { view?: ?string },
    userEmail: string,
  },
  {
    showInviteWindow: boolean,
    currentTaskId: ?string,
    adding: boolean,
    selectedTasks: Array<TaskRow_task>,
    newTasks: Array<string>,
    warningTaskId: ?string,
    viewMode: string,
  },
> {
  state = {
    adding:
      this.props.event.deliverables.totalCount === 0 && this.props.event.viewerCanCreateDeliverable,
    selectedTasks: [],
    newTasks: [],
    showInviteWindow: false,
    currentTaskId: null,
    warningTaskId: null,
    viewMode:
      this.props.match.params.taskSlug == null ||
      !VIEW_MODES.includes(this.props.match.params.taskSlug)
        ? 'section'
        : String(this.props.match.params.taskSlug),
  };

  componentDidMount() {
    const taskSlug = this.props.match.params.taskSlug;
    const defaultViewMode = storage.get(VIEW_MODE_STORAGE_KEY);
    if (taskSlug && !VIEW_MODES.includes(taskSlug)) {
      return;
    }

    if (
      defaultViewMode != null &&
      VIEW_MODES.includes(defaultViewMode) &&
      defaultViewMode !== this.state.viewMode
    ) {
      this.handleViewModeChange(String(defaultViewMode));
    }
  }

  handleInviteClick = (taskId: string) => {
    this.setState({ showInviteWindow: true, currentTaskId: taskId });
  };

  handleHideInviteWindow = (users?: ?$ReadOnlyArray<User>) => {
    if (users != null) {
      users.forEach(user => {
        if (this.state.currentTaskId != null) {
          assignTasks([this.state.currentTaskId], user.id, 'checklist');
        }
      });
    }
    this.setState({ showInviteWindow: false, currentTaskId: null });
  };

  handleAddFormShow = () => {
    this.setState({ adding: true });
  };

  handleAddFormHide = () => {
    this.setState({ adding: false });
  };

  handleSelectedTasksChange = (selectedTasks: Array<TaskRow_task>) => {
    this.setState({ selectedTasks });
  };

  handleSelectedTasksCleanup = (tasksId: Array<string>) => {
    this.setState(state => ({
      selectedTasks: state.selectedTasks.filter(taskId => !tasksId.includes(taskId)),
    }));
  };

  handleTasksCreate = tasks => {
    return createTasks(this.props.event.id, tasks).then(tasksIds => {
      this.setState({ newTasks: tasksIds }, () => {
        setTimeout(() => this.setState({ newTasks: [] }), 500);
      });
      return tasksIds;
    });
  };

  handleTasksUpdate = (tasksIds: string | Array<string>, obj) => {
    updateTasks(
      Array.isArray(tasksIds) ? tasksIds : [tasksIds],
      obj,
      Array.isArray(tasksIds) ? 'multiselect' : 'checklist',
    );
  };

  handleTasksStatusUpdate = (item: TaskRow_task | Array<string>, status: TaskStatuses) => {
    const taskArrayIds = Array.isArray(item) ? item : [item.id];

    if (!Array.isArray(item)) {
      const task = item;
      if (task && task.blockedByOtherTasks && status === 'COMPLETED') {
        this.setState({ warningTaskId: task.id });
        return;
      }
    }

    updateTasksStatus(taskArrayIds, status, Array.isArray(item) ? 'multiselect' : 'checklist');
  };

  handleConfirmTaskStatusUpdate = () => {
    if (this.state.warningTaskId) {
      updateTasksStatus([this.state.warningTaskId], 'COMPLETED', 'checklist');
      this.setState({ warningTaskId: null });
    }
  };

  handleTasksRemove = (tasksIds: string | Array<string>) => {
    const idsArray = Array.isArray(tasksIds) ? tasksIds : [tasksIds];
    this.handleSelectedTasksCleanup(idsArray);
    removeTasks(idsArray, {
      eventId: this.props.event.id,
      orgId: this.props.org.id,
      fromWindow: Array.isArray(tasksIds) ? 'multiselect' : 'checklist',
    });
  };

  handleTasksAssign = (tasksIds: string | Array<string>, userId) => {
    assignTasks(
      Array.isArray(tasksIds) ? tasksIds : [tasksIds],
      userId,
      Array.isArray(tasksIds) ? 'multiselect' : 'checklist',
    );
  };

  handleTasksUnassign = (tasksIds: string | Array<string>, userId) => {
    unassignTasks(
      Array.isArray(tasksIds) ? tasksIds : [tasksIds],
      userId,
      Array.isArray(tasksIds) ? 'multiselect' : 'checklist',
    );
  };

  handleTasksAddTag = (tasksIds: string | Array<string>, tagId) => {
    addTasksTag(
      Array.isArray(tasksIds) ? tasksIds : [tasksIds],
      tagId,
      Array.isArray(tasksIds) ? 'multiselect' : 'checklist',
    );
  };

  handleTasksRemoveTag = (tasksIds: string | Array<string>, tagId) => {
    removeTasksTag(
      Array.isArray(tasksIds) ? tasksIds : [tasksIds],
      tagId,
      Array.isArray(tasksIds) ? 'multiselect' : 'checklist',
    );
  };

  handleTasksReorder = (tasksId, folderId, newPosition, optimisticOrders) => {
    reorderTasks(tasksId, folderId, newPosition, optimisticOrders);
  };

  handleFolderReorder = (folderId, newPosition, sortedFolders) => {
    reorderFolder(folderId, newPosition, sortedFolders);
  };

  handleFolderCreate = name => {
    return createFolder(this.props.event.id, name);
  };

  handleFolderUpdate = (folderId, obj) => {
    updateFolder(folderId, obj);
  };

  handleGetTaskLink = (taskSlug: string) => {
    const query = qs.stringify(this.props.queryParams);

    return `/events/${this.props.event.slug}/tasks/${taskSlug}${query ? `?${query}` : ''}`;
  };

  handleImport = ({ sourceEvent, copyDueDates, folders }) => {
    return importEventTemplate({
      sourceEvent,
      eventId: this.props.event.id,
      targetEvent: this.props.event.dbId,
      copyDueDates,
      folders,
      fromWindow: this.state.viewMode,
    }).catch(err => {
      showModernMutationError(err);
    });
  };

  handleViewModeChange = (mode: string) => {
    this.handleSelectedTasksChange([]);
    this.props.history.push({
      pathname: `/events/${this.props.event.slug}/tasks${mode === 'section' ? '' : `/${mode}`}`,
      search: this.props.history.location.search,
    });
    this.setState({ viewMode: mode });
    storage.set(VIEW_MODE_STORAGE_KEY, mode);
  };

  handleChangeSearchQuery = (searchQuery: string) => {
    const filters = this.props.filters;
    if (searchQuery === filters.query) return;

    replaceQueryParams(this.props.history, { query: searchQuery });
  };

  render() {
    const eventSlug = this.props.match.params.event_slug || '';

    const { sort, ...restFilters } = this.props.filters;
    const filtered = Object.values(restFilters).some(val => !!val);
    return (
      <>
        <TasksHeader
          userEmail={this.props.userEmail}
          history={this.props.history}
          eventSlug={eventSlug}
          event={this.props.event}
          org={this.props.org}
          onAddFormShow={this.handleAddFormShow}
          selectedTasks={this.state.selectedTasks}
          onTasksUpdate={this.handleTasksUpdate}
          onTasksStatusUpdate={this.handleTasksStatusUpdate}
          onTasksRemove={this.handleTasksRemove}
          onTasksAssign={this.handleTasksAssign}
          onTasksUnassign={this.handleTasksUnassign}
          onTasksAddTag={this.handleTasksAddTag}
          onTasksRemoveTag={this.handleTasksRemoveTag}
          onFolderCreate={this.handleFolderCreate}
          onImport={this.handleImport}
          filters={this.props.filters}
          viewMode={this.state.viewMode}
          onViewModeChange={this.handleViewModeChange}
          searchQuery={this.props.filters.query || ''}
          onChangeSearchQuery={this.handleChangeSearchQuery}
        />
        {this.state.adding && (
          <TaskAdd
            event={this.props.event}
            onHide={this.handleAddFormHide}
            onSave={this.handleTasksCreate}
            onFolderCreate={this.handleFolderCreate}
          />
        )}
        {this.state.showInviteWindow && (
          <InviteWindow
            onHide={this.handleHideInviteWindow}
            eventId={this.props.event.id}
            fromWindow="task"
          />
        )}

        {this.state.viewMode === 'calendar' && (
          <TasksCalendarView
            filters={this.props.filters}
            eventSlug={eventSlug}
            tz={this.props.event.tz}
            onGetTaskLink={this.handleGetTaskLink}
          />
        )}
        {this.state.viewMode === 'section' && (
          <TasksSectionView
            filters={this.props.filters}
            filtered={filtered}
            eventSlug={eventSlug}
            org={this.props.org}
            history={this.props.history}
            onSelectedTasksChange={this.handleSelectedTasksChange}
            selectedTasks={this.state.selectedTasks}
            onTaskUpdate={this.handleTasksUpdate}
            onTaskStatusUpdate={this.handleTasksStatusUpdate}
            onTaskRemove={this.handleTasksRemove}
            onTaskAssign={this.handleTasksAssign}
            onTaskUnassign={this.handleTasksUnassign}
            onGetTaskLink={this.handleGetTaskLink}
            onTaskAddTag={this.handleTasksAddTag}
            onTaskRemoveTag={this.handleTasksRemoveTag}
            onTasksReorder={this.handleTasksReorder}
            onFolderUpdate={this.handleFolderUpdate}
            onFolderReorder={this.handleFolderReorder}
            onChangeViewMode={this.handleViewModeChange}
            onInviteClick={this.handleInviteClick}
            newTasks={this.state.newTasks}
            tasksCount={this.props.event.deliverables.totalCount}
          />
        )}
        {this.state.viewMode === 'due-date' && (
          <TasksDueDateView
            filters={this.props.filters}
            filtered={filtered}
            history={this.props.history}
            eventSlug={eventSlug}
            org={this.props.org}
            onSelectedTasksChange={this.handleSelectedTasksChange}
            selectedTasks={this.state.selectedTasks}
            onTaskUpdate={this.handleTasksUpdate}
            onTaskStatusUpdate={this.handleTasksStatusUpdate}
            onTaskRemove={this.handleTasksRemove}
            onTaskAssign={this.handleTasksAssign}
            onTaskUnassign={this.handleTasksUnassign}
            onGetTaskLink={this.handleGetTaskLink}
            onTaskAddTag={this.handleTasksAddTag}
            onTaskRemoveTag={this.handleTasksRemoveTag}
            onInviteClick={this.handleInviteClick}
            newTasks={this.state.newTasks}
            tasksCount={this.props.event.deliverables.totalCount}
          />
        )}
        {this.state.warningTaskId && (
          <ConfirmationWindow
            onHide={() => this.setState({ warningTaskId: null })}
            onConfirm={this.handleConfirmTaskStatusUpdate}
            actionLabel="Update"
            actionNegative={false}
            message={
              <>
                This Task has dependencies that are still open.
                <br />
                Are you sure you want to mark this Task as completed?
              </>
            }
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(Tasks, {
  event: graphql`
    fragment Tasks_event on Event {
      id
      slug
      dbId
      tz
      viewerCanCreateDeliverable
      ...TaskAdd_event
      ...TasksHeader_event
      deliverables {
        totalCount
      }
    }
  `,
  org: graphql`
    fragment Tasks_org on Org {
      id
      ...TasksSectionView_org
      ...TasksDueDateView_org
      ...TasksHeader_org
    }
  `,
});
