/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import RegistrationFormMain from './RegistrationFormMain';

import type { EventRegistrationQueryResponse } from './__generated__/EventRegistrationQuery.graphql';

const query = graphql`
  query EventRegistrationQuery($eventSlug: String!, $includeRules: Boolean!) {
    query {
      ...RegistrationFormMain_query
    }
  }
`;

type Props = { history: RouterHistory, eventSlug: string };

const EventRegistration = ({ history, eventSlug }: Props) => {
  return (
    <DefaultQueryRenderer
      query={query}
      variables={{ eventSlug, includeRules: false }}
      renderSuccess={({ query: responseQuery }: EventRegistrationQueryResponse) => {
        if (responseQuery == null) {
          return null;
        }
        return <RegistrationFormMain history={history} query={responseQuery} />;
      }}
    />
  );
};

export default EventRegistration;
