/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 15px 0;
`;

const Wrapper = styled.div`
  padding-left: 10px;
  border-left: 5px solid ${props => props.borderColor};
`;

const Row = styled.div`
  display: flex;
`;

const ChildRow = styled(Row)`
  ${props =>
    props.isExpanded
      ? css`
          display: flex;
          flex-direction: column;
        `
      : css`
          display: none;
        `};
`;

const Header = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding-left: 10px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: #828b93;
  cursor: pointer;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 18px;
  margin-left: 8px;
  color: ${props => props.theme.primaryActionTextColor};
  background: ${props => props.theme.primaryActionColor};
  border-radius: 50%;
  font-size: 10px;
  font-weight: 500;
`;

export default class FiltersGroup extends React.Component<
  {
    name?: string,
    children: React.Node,
    borderColor: string,
    expanded?: boolean,
    forceExpanded?: boolean,
    count?: number,
  },
  {
    isExpanded: boolean,
  },
> {
  state = {
    isExpanded: this.props.expanded || false,
  };

  handleToggleExpand = () => {
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  render() {
    return (
      <Container>
        <Wrapper borderColor={this.props.borderColor}>
          {this.props.name && (
            <Row onClick={this.handleToggleExpand}>
              <Header>
                {this.props.name}
                {this.props.count != null && this.props.count > 0 && (
                  <Circle>{this.props.count}</Circle>
                )}
              </Header>
            </Row>
          )}
          <ChildRow
            isExpanded={this.props.forceExpanded || this.state.isExpanded || !this.props.name}
          >
            {this.props.children}
          </ChildRow>
        </Wrapper>
      </Container>
    );
  }
}
