/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';

import { upload } from 'utils/Attachments';
import type { CircaFile } from 'utils/uploading/types';

import createEventAttachment from 'graph/mutations/createEventAttachment';
import removeAttachment from 'graph/mutations/removeAttachment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import AttachmentsList from 'components/Attachments';
import { DropZone } from 'components/DropZone';
import Button from 'components/material/Button';
import NoResultsMessage from 'components/NoResultsMessage';
import { Content } from 'components/page/Content';
import AttachmentOverlay from 'components/RelayAttachments/AttachmentOverlay';

import type { Attachments_event } from './__generated__/Attachments_event.graphql';

class Attachments extends React.Component<
  {
    event: Attachments_event,
  },
  {
    uploading: $ReadOnlyArray<{
      id: string,
      fileurl: string,
      filename: string,
      filetype: string,
      filesize: ?number,
      loading: boolean,
    }>,
    showPickerMenu: boolean,
  },
> {
  state = {
    uploading: [],
    showPickerMenu: false,
  };

  AttachmentMenuContainer: ?HTMLDivElement;

  AddAttachmentButton: React.Node;

  handleAttachmentRemove = attachment => {
    removeAttachment(this.props.event.id, attachment.id);
  };

  handleCreateTemporaryAttachment = file => {
    const id = `uploading-${Math.random()}`;

    this.setState(state => ({
      uploading: [
        ...state.uploading,
        {
          ...file,
          id,
          loading: true,
        },
      ],
    }));

    return () => {
      this.setState(state => ({ uploading: state.uploading.filter(x => x.id !== id) }));
    };
  };

  handleAttachmentAdd = (file: CircaFile) => {
    const stopUploading = this.handleCreateTemporaryAttachment(file);

    createEventAttachment(this.props.event.id, file)
      .then(stopUploading)
      .catch(err => {
        stopUploading();
        showModernMutationError(err);
      });
  };

  handleFileDrop = files => {
    [].slice.call(files).forEach(file => {
      const stopUploading = this.handleCreateTemporaryAttachment(file);
      upload(file).then(uploadedFile => {
        createEventAttachment(this.props.event.id, uploadedFile)
          .then(() => {
            stopUploading();
          })
          .catch(err => {
            stopUploading();
            showModernMutationError(err);
          });
      });
    });
  };

  handleShowPickerMenu = () => {
    this.setState({
      showPickerMenu: true,
    });
  };

  handleHidePickerMenu = () => {
    this.setState({
      showPickerMenu: false,
    });
  };

  render() {
    const { event } = this.props;

    const { viewerCanUpdate } = event;
    const briefAttachmentIds = event.briefAttachments.edges.map(x => x.node.id);
    const eventAttachments = event.attachments.edges.map(x => x.node);
    const deliverables = event.deliverables.edges.map(x => x.node);
    const expenses = event.expenses.edges.map(x => x.node);
    const payments = flatten(event.expenses.edges.map(x => x.node.payments.edges.map(y => y.node)));
    const eventNotes = event.eventNotes.edges.map(x => x.node);
    const deliverableAttachments = flatten(
      deliverables.map(del =>
        del.attachments.edges.reduce((acc, x) => {
          if (x.node) {
            acc.push(Object.assign({}, x.node, { deliverable: del }));
          }
          return acc;
        }, []),
      ),
    );
    const expenseAttachments = flatten(
      expenses.map(exp =>
        exp.attachments.edges.map(x => Object.assign({}, x.node, { expense: exp })),
      ),
    );
    const paymentAttachments = flatten(
      payments.map(item =>
        item.attachments.edges.map(x => Object.assign({}, x.node, { payment: item })),
      ),
    );
    const eventNoteAttachments = flatten(
      eventNotes.map(note =>
        note.attachments.edges.map(x => Object.assign({}, x.node, { eventNote: note })),
      ),
    );
    const eventFloorPlanAttachments = event.eventFloorPlan.attachments.edges.map(x => ({
      ...x.node,
      eventFloorPlan: event.eventFloorPlan,
    }));
    const allAttachments = uniqBy(
      [
        ...deliverableAttachments,
        ...expenseAttachments,
        ...paymentAttachments,
        ...eventNoteAttachments,
        ...eventFloorPlanAttachments,
        ...eventAttachments,
        ...this.state.uploading,
      ],
      'id',
    ).filter(item => item.id);

    return (
      <DropZone
        onDrop={this.handleFileDrop}
        className="event-dropzone"
        disabled={!event.viewerCanUpdateAttachments}
      >
        <Content>
          <div
            ref={ref => {
              this.AttachmentMenuContainer = ref;
            }}
          >
            {event.viewerCanUpdateAttachments && (
              <Button
                onClick={this.handleShowPickerMenu}
                label="Add Attachment"
                ref={(ref: any) => {
                  this.AddAttachmentButton = ref;
                }}
                primary
              />
            )}
            <AttachmentOverlay
              target={this.AddAttachmentButton}
              container={this.AttachmentMenuContainer}
              onHide={this.handleHidePickerMenu}
              show={this.state.showPickerMenu}
              onUploaded={this.handleAttachmentAdd}
            />
          </div>

          {allAttachments.length === 0 && (
            <NoResultsMessage
              iconName="paperclip"
              message="Add the first attachment for this event"
            />
          )}

          <div style={{ marginTop: '20px' }}>
            <AttachmentsList
              attachments={allAttachments}
              onRemove={this.handleAttachmentRemove}
              event_id={event.dbId}
              event_relay_id={event.id}
              event_slug={event.slug}
              disableDelete={!event.viewerCanUpdateAttachments}
              briefAttachmentIds={briefAttachmentIds}
              canUpdateBrief={viewerCanUpdate}
            />
          </div>
        </Content>
      </DropZone>
    );
  }
}

export default createFragmentContainer(
  Attachments,
  graphql`
    fragment Attachments_event on Event {
      id
      dbId
      slug
      name
      viewerCanUpdateAttachments
      team {
        dbId
      }
      attachments {
        edges {
          node {
            id
            fileurl
            filetype
            filename
          }
        }
      }
      viewerCanUpdate
      briefAttachments {
        edges {
          node {
            id
          }
        }
      }
      deliverables {
        edges {
          node {
            dbId
            name
            slug
            folder {
              id
              name
            }
            attachments {
              edges {
                node {
                  dbId
                  id
                  fileurl
                  filetype
                  filename
                }
              }
            }
          }
        }
      }
      expenses {
        edges {
          node {
            id
            attachments {
              edges {
                node {
                  dbId
                  id
                  fileurl
                  filetype
                  filename
                }
              }
            }
            payments {
              edges {
                node {
                  id
                  attachments {
                    edges {
                      node {
                        id
                        fileurl
                        filetype
                        filename
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      eventNotes {
        edges {
          node {
            id
            name
            attachments {
              edges {
                node {
                  id
                  fileurl
                  filetype
                  filename
                }
              }
            }
          }
        }
      }
      eventFloorPlan {
        attachments {
          edges {
            node {
              id
              fileurl
              filetype
              filename
            }
          }
        }
      }
    }
  `,
);
