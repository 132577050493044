/**
 * @flow
 * @relayHash e3b7b5031b01e052f2f9e83e9b353a43
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Direction = "ASC" | "DESC";
export type UserSort = "ACCOUNT_STATUS" | "ADMIN" | "CONFIRMED_AT" | "CREATED_AT" | "EMAIL" | "EVENT_COUNT" | "FULL_NAME" | "ID" | "LAST_ACTIVITY" | "ORG_ADMINS_COUNT" | "ORG_ANALYTICS_ENABLED" | "ORG_CONTACTS_COUNT" | "ORG_CRM_CONNECTED_EVENTS" | "ORG_CRM_ENABLED" | "ORG_CRM_LAST_SYNC" | "ORG_DOMAIN" | "ORG_EVENTS_COUNT" | "ORG_EVENTS_UPCOMING" | "ORG_ID" | "ORG_MEMBERS_COUNT" | "ORG_NAME" | "ORG_SSO_ENABLED" | "ORG_SUBDOMAIN" | "ORG_SUBSCRIPTION" | "ORG_SUBSCRIPTION_TERM" | "ORG_SYNCED_TO_IBM" | "ORG_TEAM_MEMBERS_COUNT" | "ROLE" | "STAFFED_EVENTS_UPCOMING" | "SUBSCRIPTION_DAYS_LEFT" | "SUBSCRIPTION_EXPIRE_AT" | "SUBSCRIPTION_MEMBERS_LEFT" | "SUBSCRIPTION_MEMBERS_LIMIT" | "TEAM_COUNT" | "TIMEZONE";
export type MembersHeaderQueryVariables = {|
  sort: UserSort,
  direction: Direction,
  query?: ?string,
|};
export type MembersHeaderQueryResponse = {|
  +org: {|
    +memberReportCSVProcessId: string
  |},
  +me: {|
    +email: string
  |},
|};
export type MembersHeaderQuery = {|
  variables: MembersHeaderQueryVariables,
  response: MembersHeaderQueryResponse,
|};
*/


/*
query MembersHeaderQuery(
  $sort: UserSort!
  $direction: Direction!
  $query: String
) {
  org {
    memberReportCSVProcessId(sort: $sort, direction: $direction, query: $query)
    id
  }
  me {
    email
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "UserSort!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "memberReportCSVProcessId",
  "args": [
    {
      "kind": "Variable",
      "name": "direction",
      "variableName": "direction",
      "type": "Direction"
    },
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "query",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "sort",
      "variableName": "sort",
      "type": "UserSort"
    }
  ],
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MembersHeaderQuery",
  "id": null,
  "text": "query MembersHeaderQuery(\n  $sort: UserSort!\n  $direction: Direction!\n  $query: String\n) {\n  org {\n    memberReportCSVProcessId(sort: $sort, direction: $direction, query: $query)\n    id\n  }\n  me {\n    email\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MembersHeaderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MembersHeaderQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          v3
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v2,
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '895698d329314fd37a1b902b1c4e9bec';
module.exports = node;
