/**
 * @flow
 * @relayHash bd454430fb804ab953e481689c1a59bb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateEventFloorPlanInput = {
  clientMutationId?: ?string,
  floorPlanId: string,
  booth?: ?string,
  boothDimensions?: ?string,
  floorPlan?: ?string,
};
export type updateEventFloorPlanMutationVariables = {|
  input: UpdateEventFloorPlanInput
|};
export type updateEventFloorPlanMutationResponse = {|
  +updateEventFloorPlan: ?{|
    +eventFloorPlan: {|
      +id: string,
      +booth: ?string,
      +boothDimensions: ?string,
      +floorPlan: string,
    |}
  |}
|};
export type updateEventFloorPlanMutation = {|
  variables: updateEventFloorPlanMutationVariables,
  response: updateEventFloorPlanMutationResponse,
|};
*/


/*
mutation updateEventFloorPlanMutation(
  $input: UpdateEventFloorPlanInput!
) {
  updateEventFloorPlan(input: $input) {
    eventFloorPlan {
      id
      booth
      boothDimensions
      floorPlan
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventFloorPlanInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateEventFloorPlan",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateEventFloorPlanInput!"
      }
    ],
    "concreteType": "UpdateEventFloorPlanPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventFloorPlan",
        "storageKey": null,
        "args": null,
        "concreteType": "EventFloorPlan",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "booth",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "boothDimensions",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "floorPlan",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventFloorPlanMutation",
  "id": null,
  "text": "mutation updateEventFloorPlanMutation(\n  $input: UpdateEventFloorPlanInput!\n) {\n  updateEventFloorPlan(input: $input) {\n    eventFloorPlan {\n      id\n      booth\n      boothDimensions\n      floorPlan\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventFloorPlanMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventFloorPlanMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '295408673402f3c8879d00b0cfd90e52';
module.exports = node;
