/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { TeamDateAdded_membershipEdge } from './__generated__/TeamDateAdded_membershipEdge.graphql';

type Props = {
  membershipEdge: TeamDateAdded_membershipEdge,
  tz: string,
};

const TeamDateAdded = ({ membershipEdge, tz }: Props) => (
  <DateTimeTooltip date={moment.tz(membershipEdge.addedAt, tz)} />
);

export default createFragmentContainer(
  TeamDateAdded,
  graphql`
    fragment TeamDateAdded_membershipEdge on MembershipTeamEdge {
      addedAt
    }
  `,
);
