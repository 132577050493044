/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Design_registrationForm$ref = any;
type EventInfo_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PagePropertiesRoutes_registrationForm$ref: FragmentReference;
export type PagePropertiesRoutes_registrationForm = {|
  +$fragmentRefs: EventInfo_registrationForm$ref & Design_registrationForm$ref,
  +$refType: PagePropertiesRoutes_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PagePropertiesRoutes_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EventInfo_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Design_registrationForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f5a9a015b4c32593d44c972e5d86ed6d';
module.exports = node;
