/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TaskAssignees_assignees$ref = any;
type TaskAttachments_task$ref = any;
type TaskContacts_task$ref = any;
type TaskContentHeader_task$ref = any;
type TaskDependencies_task$ref = any;
type TaskExpenses_task$ref = any;
type TaskSubtasks_task$ref = any;
type TaskVendors_task$ref = any;
export type TaskStatus = "COMPLETED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskContent_task$ref: FragmentReference;
export type TaskContent_task = {|
  +status: TaskStatus,
  +dueDate: ?any,
  +dueDateAllDay: ?boolean,
  +remindAt: ?any,
  +description: ?string,
  +slug: string,
  +viewerCanUpdateStatus: boolean,
  +viewerCanUpdateTags: boolean,
  +viewerCanUpdateDueDate: boolean,
  +viewerCanUpdateDetails: boolean,
  +viewerCanAssign: boolean,
  +contacts: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +companies: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +assignees: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: TaskAssignees_assignees$ref
      |}
    |}>
  |},
  +vendors: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +tags: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +attachments: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +expenses: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +subtasks: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +dependencies: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +dependents: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +event: {|
    +id: string,
    +tz: string,
    +viewerCanViewBudget: boolean,
    +viewerCanUpdate: boolean,
    +briefAttachments: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
  |},
  +$fragmentRefs: TaskContacts_task$ref & TaskVendors_task$ref & TaskAttachments_task$ref & TaskExpenses_task$ref & TaskSubtasks_task$ref & TaskDependencies_task$ref & TaskContentHeader_task$ref,
  +$refType: TaskContent_task$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
],
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "AttachmentRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Attachment",
        "plural": false,
        "selections": v1
      }
    ]
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "DeliverableRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Deliverable",
        "plural": false,
        "selections": v1
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "TaskContent_task",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueDateAllDay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "remindAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TaskContacts_task",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TaskVendors_task",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TaskAttachments_task",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TaskExpenses_task",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TaskSubtasks_task",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TaskDependencies_task",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TaskContentHeader_task",
      "args": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateTags",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateDueDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateDetails",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanAssign",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contacts",
      "storageKey": null,
      "args": null,
      "concreteType": "ContactRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ContactRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Contact",
              "plural": false,
              "selections": v1
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "companies",
      "storageKey": null,
      "args": null,
      "concreteType": "CompanyRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CompanyRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Company",
              "plural": false,
              "selections": v1
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "assignees",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "TaskAssignees_assignees",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vendors",
      "storageKey": null,
      "args": null,
      "concreteType": "VendorRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "VendorRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Vendor",
              "plural": false,
              "selections": v1
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tags",
      "storageKey": null,
      "args": null,
      "concreteType": "TagRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TagRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Tag",
              "plural": false,
              "selections": v1
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "attachments",
      "storageKey": null,
      "args": null,
      "concreteType": "AttachmentRequiredConnection",
      "plural": false,
      "selections": v2
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "expenses",
      "storageKey": null,
      "args": null,
      "concreteType": "ExpenseRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ExpenseRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Expense",
              "plural": false,
              "selections": v1
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subtasks",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableRequiredConnection",
      "plural": false,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dependencies",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableRequiredConnection",
      "plural": false,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dependents",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableRequiredConnection",
      "plural": false,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tz",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "viewerCanViewBudget",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "viewerCanUpdate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "briefAttachments",
          "storageKey": null,
          "args": null,
          "concreteType": "AttachmentRequiredConnection",
          "plural": false,
          "selections": v2
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f086917407d3a218ddd2b8930c752c92';
module.exports = node;
