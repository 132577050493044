import './styles.styl';

import React from 'react';

import { TextField } from 'components/form/TextField';

export class LinkField extends React.Component {
  state = {
    error: null,
  };

  render() {
    const { invalid } = this.props;
    const { error } = this.state;
    return (
      <TextField
        ref="field"
        invalid={error || invalid}
        onBlur={this.handleBlur.bind(this)}
        {...this.props}
      />
    );
  }
  handleBlur() {
    if (this.isValid()) {
      this.setState({ error: null });
    } else {
      this.setState({ error: 'Please enter valid URL' });
    }
  }
  value() {
    this.handleBlur();
    return this.refs.field.value();
  }
  isValid() {
    const value = this.refs.field.value();
    const isValid = value.trim().length === 0 ? true : /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(value);
    if (!isValid) return false;

    const shouldPrefix = value.trim() && !value.startsWith('http://') && !value.startsWith('https://');
    if (shouldPrefix) {
      this.refs.field.input().value = 'http://' + value;
    }
    return true;
  }
  input() {
    return this.refs.field.input();
  }
}
