/* @flow */
import fs from 'utils/fs';
import fileUploadDefaults from 'config/fileUploadDefaults';
import type { FilePickerFile, CircaFile, PickFileOptions } from './types';
import convertFileStackFile from './convertFileStackFile';

export default function pickFiles(initialOptions: PickFileOptions): Promise<Array<CircaFile>> {
  return new Promise((resolve, reject) => {
    const options = {
      ...fileUploadDefaults,
      ...initialOptions,
      onUploadDone: ({ filesUploaded }) => {
        const convertedFiles = filesUploaded.map((file: FilePickerFile) =>
          convertFileStackFile(file),
        );
        return resolve(convertedFiles);
      },
      onOpen: () => {
        const loadingCover = document.getElementById('loadingCover');
        setTimeout(() => {
          if (document.body && loadingCover) {
            document.body.removeChild(loadingCover);
          }
        }, 1000);
      },
      onFileUploadFailed: ({ error }) =>
        error.code === 101 // 101 is closing the window
          ? resolve([])
          : reject(new Error('Sorry, we had a problem uploading that file.')),
    };
    fs.picker(options).open();

    // show background to filepicker loading
    const loadingCover = document.createElement('div');
    loadingCover.id = 'loadingCover';
    loadingCover.style.cssText =
      'position:absolute;inset:0;z-index:101;background-color:rgba(47,53,62,0.86)';
    if (document.body) {
      document.body.appendChild(loadingCover);
    }
  });
}
