/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import { staffAccessLevelDetails } from 'config/staffAccessLevels';

import type { EventAccessLevel_staffedEvent } from './__generated__/EventAccessLevel_staffedEvent.graphql';

type Props = {
  staffedEvent: EventAccessLevel_staffedEvent,
};

const EventAccessLevel = ({ staffedEvent }: Props) => {
  const accessLevelInfo = staffAccessLevelDetails.find(
    info => info.value === staffedEvent.accessLevel,
  );

  if (!accessLevelInfo) return null;

  if (staffedEvent.belongsToTeam) {
    return `${accessLevelInfo.label} (via Team)`;
  }

  return accessLevelInfo.label;
};

export default createFragmentContainer(
  EventAccessLevel,
  graphql`
    fragment EventAccessLevel_staffedEvent on StaffMembership {
      accessLevel
      belongsToTeam
    }
  `,
);
