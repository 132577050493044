/* @flow */
export const CONFIG_ENV: string = process.env.CONFIG_ENV || '';
export const FILEPICKER_API: string = process.env.FILEPICKER_API || '';
export const GOOGLE_OAUTH2_ID: string = process.env.GOOGLE_OAUTH2_ID || '';
export const GOOGLE_MAPS_API_KEY: string = process.env.GOOGLE_MAPS_API_KEY || '';
export const SALESFORCE_ID: string = process.env.SALESFORCE_ID || '';
export const SEGMENT_WRITE_KEY: string = process.env.SEGMENT_WRITE_KEY || '';
export const SENTRY_PUBLIC_DSN: string = process.env.SENTRY_PUBLIC_DSN || '';
export const ENABLE_ANALYTICS: boolean = !!process.env.ENABLE_ANALYTICS;
export const TINYMCE_KEY: string = process.env.TINYMCE_KEY || '';
