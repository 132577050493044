/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Attachments_event$ref: FragmentReference;
export type Attachments_event = {|
  +id: string,
  +dbId: number,
  +slug: string,
  +name: string,
  +viewerCanUpdateAttachments: boolean,
  +team: {|
    +dbId: number
  |},
  +attachments: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +fileurl: ?string,
        +filetype: ?string,
        +filename: ?string,
      |}
    |}>
  |},
  +viewerCanUpdate: boolean,
  +briefAttachments: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +deliverables: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +dbId: number,
        +name: string,
        +slug: string,
        +folder: ?{|
          +id: string,
          +name: string,
        |},
        +attachments: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +dbId: number,
              +id: string,
              +fileurl: ?string,
              +filetype: ?string,
              +filename: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +expenses: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +attachments: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +dbId: number,
              +id: string,
              +fileurl: ?string,
              +filetype: ?string,
              +filename: ?string,
            |}
          |}>
        |},
        +payments: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +attachments: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +fileurl: ?string,
                    +filetype: ?string,
                    +filename: ?string,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +eventNotes: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
        +attachments: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +fileurl: ?string,
              +filetype: ?string,
              +filename: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +eventFloorPlan: {|
    +attachments: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +fileurl: ?string,
          +filetype: ?string,
          +filename: ?string,
        |}
      |}>
    |}
  |},
  +$refType: Attachments_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileurl",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "filetype",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "filename",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attachments",
  "storageKey": null,
  "args": null,
  "concreteType": "AttachmentRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AttachmentRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Attachment",
          "plural": false,
          "selections": [
            v0,
            v4,
            v5,
            v6
          ]
        }
      ]
    }
  ]
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attachments",
  "storageKey": null,
  "args": null,
  "concreteType": "AttachmentRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AttachmentRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Attachment",
          "plural": false,
          "selections": [
            v1,
            v0,
            v4,
            v5,
            v6
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "Attachments_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    v2,
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateAttachments",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        v1
      ]
    },
    v7,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "briefAttachments",
      "storageKey": null,
      "args": null,
      "concreteType": "AttachmentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AttachmentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Attachment",
              "plural": false,
              "selections": [
                v0
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "deliverables",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "DeliverableRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Deliverable",
              "plural": false,
              "selections": [
                v1,
                v3,
                v2,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "folder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Folder",
                  "plural": false,
                  "selections": [
                    v0,
                    v3
                  ]
                },
                v8
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "expenses",
      "storageKey": null,
      "args": null,
      "concreteType": "ExpenseRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ExpenseRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Expense",
              "plural": false,
              "selections": [
                v0,
                v8,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "payments",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PaymentRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "PaymentRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Payment",
                          "plural": false,
                          "selections": [
                            v0,
                            v7
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventNotes",
      "storageKey": null,
      "args": null,
      "concreteType": "EventNoteRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "EventNoteRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "EventNote",
              "plural": false,
              "selections": [
                v0,
                v3,
                v7
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventFloorPlan",
      "storageKey": null,
      "args": null,
      "concreteType": "EventFloorPlan",
      "plural": false,
      "selections": [
        v7
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '58613bd52db423ff553356c57bfbe02c';
module.exports = node;
