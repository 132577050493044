/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import theme from 'config/theme';
import type { Tab } from 'config/eventTabs';
import Overlay from 'components/material/Overlay';
import Checkbox from 'components/material/CheckBox';

const StyledOverlay = styled(Overlay)`
  font-size: 13px;
  padding: 10px;
  display: flex;
  width: 280px;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const StyledCheckbox = styled(Checkbox)`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Column = styled.div`
  flex: 1 1 auto;
`;

export default class TabsSettingsOverlay extends React.PureComponent<{
  target: ?HTMLElement,
  container: ?HTMLElement,
  show: boolean,
  onHide: () => void,
  tabs: $ReadOnlyArray<Tab>,
  shownTabs: $ReadOnlyArray<string>,
  onChange: (tabs: $ReadOnlyArray<string>) => void,
}> {
  handleTabToggle = (tab: Tab, checked: boolean) => {
    if (tab.sticky) {
      return;
    }
    if (checked) {
      this.props.onChange(this.props.shownTabs.filter(t => t !== tab.value));
    } else {
      this.props.onChange(
        sortBy([...this.props.shownTabs, tab.value], s =>
          this.props.tabs.findIndex(t => t.value === s),
        ),
      );
    }
  };

  renderTabs = (tabs: $ReadOnlyArray<Tab>) => {
    return tabs.map(tab => {
      const checked = this.props.shownTabs.includes(tab.value);

      return (
        <StyledCheckbox
          label={tab.label}
          key={tab.value}
          checked={checked}
          checkColor={checked ? theme.highlightColor : null}
          disabled={tab.disabled === true || (this.props.shownTabs.length === 1 && checked)}
          onChange={() => this.handleTabToggle(tab, checked)}
        />
      );
    });
  };

  render() {
    const tabsLength = this.props.tabs.length;
    const halfIndex = Math.ceil(tabsLength / 2);

    return (
      <StyledOverlay
        target={this.props.target}
        show={this.props.show}
        container={this.props.container}
        onHide={this.props.onHide}
        forceRightEdge
      >
        <Column>{this.renderTabs(this.props.tabs.slice(0, halfIndex))}</Column>
        <Column>{this.renderTabs(this.props.tabs.slice(halfIndex))}</Column>
      </StyledOverlay>
    );
  }
}
