/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Main = styled.main`
  position: relative;
  flex: 1 1 0%; /* percentage is needed for IE11 */
  padding: 30px 34px 0 30px;
  background-color: #fff;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0;
  }
`;

// eslint-disable-next-line
export class Content extends React.Component<{
  className?: string,
  children: React.Node,
}> {
  render() {
    return <Main className={this.props.className}>{this.props.children}</Main>;
  }
}
