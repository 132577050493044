/* @flow */

import React from 'react';
import styled from 'styled-components';

const FounderCompany = styled.div`
  color: ${props => props.theme.osloGray};
`;

type Company = {|
  +id: string,
  +name: string,
|};

const BriefUserTitleCompany = (props: { title: ?string, company: ?string | ?Company }) => {
  const { company, title } = props;
  const companyName: ?string =
    company != null && typeof company === 'object' ? company.name : company;
  return title || company ? (
    <FounderCompany>{`${title || ''} ${company ? `@${companyName || ''}` : ''}`}</FounderCompany>
  ) : null;
};

export default BriefUserTitleCompany;
