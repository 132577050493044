/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import Button from 'components/budget/Button';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import APISettings from '../CircaAPI/APISettings';

import { type ZapierQueryResponse } from './__generated__/ZapierQuery.graphql';

const query = graphql`
  query ZapierQuery {
    org {
      ...APISettings_org
    }
  }
`;

const Paragraph = styled.div`
  max-width: 500px;
  margin-bottom: 30px;
  line-height: 24px;
  font-size: 14px;
  color: #3e4859;

  a {
    color: #3aa8da;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Link = props => (
  <a target="_blank" rel="noreferrer noopener" {...props}>
    {props.children}
  </a>
);

export default class CircaGeekAPI extends React.Component<{}> {
  handleClickStart = () => {
    window.open('https://zapier.com/apps/circa/');
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(props: ZapierQueryResponse) => (
          <>
            <APISettings org={props.org} hideNotifications />

            <Paragraph>
              Integrate Circa with anything using Zapier! Zapier is trusted by over 3000
              participating applications and their users to connect their workflows together simply.
              Circa is available on the Zapier marketplace to integrate the Circa Event Marketing
              platform with the tools you use everyday. Whether it’s{' '}
              <Link href="https://zapier.com/apps/categories/team-chat">Team Chat</Link>,{' '}
              <Link href="https://zapier.com/apps/categories/crm">CRM</Link>,{' '}
              <Link href="https://zapier.com/apps/categories/event-management">
                Event Management
              </Link>
              , or{' '}
              <Link href="https://zapier.com/apps/categories/project-management">
                Project Management
              </Link>
              , Zapier allows you to bring it all together with your Circa account.
            </Paragraph>

            <Paragraph>
              To get started, you’ll need a <Link href="https://zapier.com/">Zapier account</Link>.
              Click below to get started with Zapier and be one of the first to take advantage of
              this new feature.
            </Paragraph>

            <Button onClick={this.handleClickStart}>Circa on Zapier</Button>
          </>
        )}
      />
    );
  }
}
