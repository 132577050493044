/* @flow */
import formatCost from 'utils/number/formatCost';

import { type ReportingCellPropsType } from './index';

const ReportingPlannedAmountCell = ({ group, childGroup, orgCurrency }: ReportingCellPropsType) => {
  if (childGroup == null) {
    return formatCost(group.plannedAmount, orgCurrency);
  }
  return formatCost(childGroup.plannedAmount, orgCurrency);
};

export default ReportingPlannedAmountCell;
