/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import TAG_COLORS from 'config/tag_colors.json';

import RemovablePill from 'components/material/RemovablePill';

import TagsOverlay from './TagsOverlay';

import type { Tags_orgTags } from './__generated__/Tags_orgTags.graphql';

const Container = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 11px;
  color: ${props => props.theme.labelColor};
`;

const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagPill = styled(RemovablePill)`
  background: ${props => TAG_COLORS[props.color]};
  border-radius: 2px;
  color: ${props => props.theme.tagTextColor};
  font-size: 13px;
  padding: 5px 10px;
  margin-right: 6px;
  margin-bottom: 6px;
`;

const AddButton = styled.button`
  height: 100%;
  margin-bottom: 6px;
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  outline: 0;
  font-size: 16px;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

class Tags extends React.PureComponent<
  {
    tags: $ReadOnlyArray<string>,
    orgTags: Tags_orgTags,
    onAddTag: string => void,
    onRemoveTag: string => void,
    canManageTags?: boolean,
    disabled?: boolean,
    className?: string,
    onTagPickerShown?: (shown?: boolean) => void,
  },
  {
    overlayShown: boolean,
  },
> {
  state = { overlayShown: false };

  button: ?HTMLElement;

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
    if (this.props.onTagPickerShown) {
      this.props.onTagPickerShown();
    }
  };

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });
    if (this.props.onTagPickerShown) {
      this.props.onTagPickerShown(true);
    }
  };

  handleTagSelect = tagId => {
    this.props.onAddTag(tagId);
  };

  render() {
    const activeTags = this.props.orgTags.filter(tag => this.props.tags.includes(tag.id));
    return (
      <Container className={this.props.className}>
        <Label>Tags</Label>

        <TagsList>
          {activeTags.map(tag => (
            <TagPill
              color={tag.color}
              onRemove={() => this.props.onRemoveTag(tag.id)}
              key={tag.id}
              disabled={this.props.disabled}
            >
              {tag.name}
            </TagPill>
          ))}
          {!this.props.disabled && (
            <AddButton
              onClick={this.handleOverlayShow}
              ref={el => {
                this.button = el;
              }}
            >
              <i className="fa fa-fw fa-plus" />
            </AddButton>
          )}
        </TagsList>

        <TagsOverlay
          show={this.state.overlayShown}
          container={this}
          target={this.button}
          tags={this.props.orgTags}
          activeTags={this.props.tags}
          onHide={this.handleOverlayHide}
          onSelect={this.handleTagSelect}
          canManageTags={this.props.canManageTags}
          hideActive
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  Tags,
  graphql`
    fragment Tags_orgTags on Tag @relay(plural: true) {
      id
      color
      name
      ...TagsOverlay_tags
    }
  `,
);
