/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import ErrorView from 'components/ErrorView';

export default class NotAuthorized extends React.Component<{}> {
  render() {
    const error = {
      message: "You don't have access to this page.",
    };
    return (
      <DocumentTitle title="Not Authorized">
        <ErrorView title="Not Authorized" error={error} icon="lock" />
      </DocumentTitle>
    );
  }
}
