/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { EventDateAdded_staffedEvent } from './__generated__/EventDateAdded_staffedEvent.graphql';

type Props = {
  staffedEvent: EventDateAdded_staffedEvent,
};

const EventDateAdded = ({ staffedEvent }: Props) => {
  return <DateTimeTooltip date={moment(staffedEvent.addedAt).tz(staffedEvent.event.tz)} />;
};

export default createFragmentContainer(
  EventDateAdded,
  graphql`
    fragment EventDateAdded_staffedEvent on StaffMembership {
      addedAt
      event {
        tz
      }
    }
  `,
);
