/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import type { CompanyFieldInput } from 'graph/mutations/company/updateCompany';

import InlineTextField from 'components/budget/Table/InlineTextField';

import CompanyStreet_company from './__generated__/CompanyStreet_company.graphql';

class CompanyStreet extends React.PureComponent<{
  company: CompanyStreet_company,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<CompanyFieldInput>) => Promise<void>,
}> {
  handleUpdate = (value: ?string) => {
    if (!this.getError(value) && value !== this.props.company.street) {
      this.props.onUpdate({ street: value });
      this.props.updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    if (this.props.fieldSettings.required && (!value || !value.trim())) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { company, fieldSettings, updateColumnWidth } = this.props;

    if (!company) {
      return null;
    }

    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={company.street || ''}
        getError={this.getError}
        onChange={this.handleUpdate}
        updateColumnWidth={updateColumnWidth}
      />
    );
  }
}

export default createFragmentContainer(
  CompanyStreet,
  graphql`
    fragment CompanyStreet_company on Company {
      street
    }
  `,
);
