/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { MemberLastActivity_user } from './__generated__/MemberLastActivity_user.graphql';

type Props = {
  user: MemberLastActivity_user,
  tz: string,
};

function MemberLastActivity({ user, tz }: Props) {
  return <div>{user.lastSeenAt && <DateTimeTooltip date={moment.tz(user.lastSeenAt, tz)} />}</div>;
}

export default createFragmentContainer(
  MemberLastActivity,
  graphql`
    fragment MemberLastActivity_user on User {
      lastSeenAt
    }
  `,
);
