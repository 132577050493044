/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link } from 'react-router-dom';

import type { DeliverableEventName_deliverable } from './__generated__/DeliverableEventName_deliverable.graphql';

type Props = {
  deliverable: DeliverableEventName_deliverable,
  location: Location,
};

const stopPropagation = (e: SyntheticMouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

const DeliverableEventName = ({ deliverable, location }: Props) => {
  return (
    <Link
      to={{
        pathname: `/events/${deliverable.event.slug}/tasks`,
        state: {
          from: location.pathname.includes('all_tasks') ? 'all_tasks' : 'my_tasks',
          fromUrl: location.pathname,
        },
      }}
      onClick={stopPropagation}
    >
      {deliverable.event.name}
    </Link>
  );
};

export default createFragmentContainer(
  DeliverableEventName,
  graphql`
    fragment DeliverableEventName_deliverable on Deliverable {
      event {
        name
        slug
      }
    }
  `,
);
