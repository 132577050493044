/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';

import ContactEvents from './components/ContactEvents';
import ContactOpportunities from './components/ContactOpportunities';
import ContactRequests from './components/ContactRequests';
import ContactProfile from './ContactProfile';

import type { ContactPageRoutes_event } from './__generated__/ContactPage_event.graphql';
import type { ContactPageRoutes_user } from './__generated__/ContactPage_user.graphql';
import type { ContactPageRoutes_contact } from './__generated__/ContactPageRoutes_contact.graphql';
import type { ContactPageRoutes_org } from './__generated__/ContactPageRoutes_org.graphql';

const Container = styled.div`
  padding: 0 33px;
`;

class ContactPageRoutes extends React.Component<{
  contact: ContactPageRoutes_contact,
  org: ContactPageRoutes_org,
  event: ContactPageRoutes_event,
  user: ContactPageRoutes_user,
  fromWindow: CreateContactFromWindow,
  eventsCount: number,
  opportunitiesSyncEnabled: boolean,
  pathPrefix: string,
  rootUrl: string,
}> {
  render() {
    const {
      user,
      contact,
      org,
      event,
      eventsCount,
      opportunitiesSyncEnabled,
      pathPrefix,
      rootUrl,
      fromWindow,
    } = this.props;
    return (
      <Container>
        <Switch>
          <Redirect from={pathPrefix} exact to={`${rootUrl}/profile`} />
          <Route
            path={`${pathPrefix}/profile`}
            render={props => (
              <ContactProfile user={user} event={event} contact={contact} {...props} />
            )}
          />
          <Route
            path={`${this.props.pathPrefix}/events`}
            render={props => (
              <ContactEvents
                {...props}
                contact={this.props.contact}
                eventsCount={eventsCount}
                fromWindow={fromWindow}
              />
            )}
          />
          {opportunitiesSyncEnabled && (
            <Route
              path={`${pathPrefix}/opportunities`}
              render={props => <ContactOpportunities {...props} org={org} contactId={contact.id} />}
            />
          )}
          <Route
            path={`${pathPrefix}/requests`}
            render={props => <ContactRequests {...props} contactId={contact.id} />}
          />
        </Switch>
      </Container>
    );
  }
}

export default createFragmentContainer(ContactPageRoutes, {
  org: graphql`
    fragment ContactPageRoutes_org on Org {
      ...ContactOpportunities_org
    }
  `,
  contact: graphql`
    fragment ContactPageRoutes_contact on Contact {
      id
      ...ContactProfile_contact
      ...ContactEvents_contact
    }
  `,
  user: graphql`
    fragment ContactPageRoutes_user on User {
      ...ContactProfile_user
    }
  `,
  event: graphql`
    fragment ContactPageRoutes_event on Event {
      ...ContactProfile_event
    }
  `,
});
