/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import ProgressBar from 'components/material/ProgressBar';

const Container = styled.div`
  width: 203px;
`;

const StyledProgressBar = styled(ProgressBar)`
  height: 6px;
  margin-bottom: 1px;
  border-radius: 3px;
  & > div {
    background-color: #29cc71 !important;
    ${props =>
      props.value >= 90 &&
      css`
        background-color: #f2a75d !important;
      `};
    ${props =>
      props.value >= 100 &&
      css`
        background-color: #f25d60 !important;
      `};
  }
`;

const Label = styled.div`
  ${props =>
    props.labelPlacement === 'before'
      ? css`
          margin-bottom: 10px;
        `
      : css`
          margin-top: 10px;
        `};
  font-size: 13px;
  font-weight: 500;
  line-height: 1.08;
  color: #3e4859;
  text-align: ${props => props.alignment};
`;

export default class BillingProgressBar extends React.PureComponent<{
  limit: number,
  used: number,
  label: string,
  labelAlignment?: 'left' | 'right' | 'center',
  labelPlacement?: 'before' | 'after',
}> {
  static defaultProps = { labelAlignment: 'center', labelPlacement: 'after' };

  renderLabel() {
    const { used, limit, label, labelAlignment, labelPlacement } = this.props;
    return (
      <Label alignment={labelAlignment} labelPlacement={labelPlacement}>
        {used}/{limit} {label}
      </Label>
    );
  }

  render() {
    const { used, limit, labelPlacement } = this.props;
    const load = (used / limit) * 100;
    return (
      <Container>
        {labelPlacement === 'before' && this.renderLabel()}
        <StyledProgressBar value={load} />
        {labelPlacement === 'after' && this.renderLabel()}
      </Container>
    );
  }
}
