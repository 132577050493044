/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import updateRegistrationForm, {
  type updateRegistrationPropertyType,
} from 'graph/mutations/registration/updateRegistrationForm';
import showModernMutationError from 'graph/utils/showModernMutationError';

import G2WIcon from 'images/integrations/g2w.svg';
import ZoomIcon from 'images/integrations/zoom.svg';
import G2WLocationPicker, { type G2WLocationType } from 'components/integrations/G2WLocationPicker';
import ZoomLocationPicker, {
  type ZoomLocationType,
} from 'components/integrations/ZoomLocationPicker';
import {
  Field as SelectFieldComponent,
  Label as SelectLabelComponent,
} from 'components/material/SelectField';
import Switch from 'components/material/Switch';
import {
  Label as TextFieldLabelComponent,
  StyledTextInput as TextFieldInputComponent,
} from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';

import { type RegistrationSettingsIntegrations_event } from './__generated__/RegistrationSettingsIntegrations_event.graphql';
import { type RegistrationSettingsIntegrations_org } from './__generated__/RegistrationSettingsIntegrations_org.graphql';

const Root = styled.div`
  padding: 20px 0;
`;

const CardColumns = styled.div`
  display: flex;
  align-items: center;
  ${props =>
    props.enabled &&
    css`
      margin-bottom: 25px;
    `}
`;

const Card = styled.div`
  max-width: 755px;
  margin-top: 40px;
  padding: 25px 45px 25px 40px;
  border-radius: 4px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  border: solid 1px #dadada;

  *:not(.DayPicker) {
    ${SelectFieldComponent} {
      max-width: 400px;
    }

    ${SelectFieldComponent}, ${TextFieldInputComponent} {
      padding: 5px 10px;
      border-width: 1px !important;
      border-radius: 3px;
    }

    ${SelectLabelComponent} {
      margin-left: 10px;
      background: #fff;

      &:not([data-collapsed]) {
        top: 5px;
      }
    }

    ${TextFieldLabelComponent} {
      margin-left: 10px;
      background: #fff;

      &:not([data-collapsed]) {
        top: 6px;
      }
    }
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}
`;

const CardIcon = styled.div`
  width: 40px;
  height: 40px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const CardTitle = styled.div`
  flex: 1;
  margin: 0 18px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(74, 86, 101, 0.87);
`;

class RegistrationSettingsIntegrations extends React.PureComponent<
  {
    event: RegistrationSettingsIntegrations_event,
    org: RegistrationSettingsIntegrations_org,
  },
  {
    enableZoom: boolean,
    enableG2W: boolean,
    defaultEnableZoom: boolean,
    defaultEnableG2W: boolean,
  },
> {
  state = {
    enableZoom: Boolean(this.props.event.zoomLocation),
    enableG2W: Boolean(this.props.event.g2wLocation),
    defaultEnableZoom: Boolean(this.props.event.zoomLocation),
    defaultEnableG2W: Boolean(this.props.event.g2wLocation),
  };

  static getDerivedStateFromProps(
    nextProps: $PropertyType<RegistrationSettingsIntegrations, 'props'>,
    prevState: $PropertyType<RegistrationSettingsIntegrations, 'state'>,
  ) {
    const enableZoom = Boolean(nextProps.event.zoomLocation);
    const enableG2W = Boolean(nextProps.event.g2wLocation);

    if (enableZoom === prevState.defaultEnableZoom && enableG2W === prevState.defaultEnableG2W) {
      return null;
    }

    return {
      enableZoom,
      enableG2W,
      defaultEnableZoom: enableZoom,
      defaultEnableG2W: enableG2W,
    };
  }

  updateRegistrationForm = (properties: updateRegistrationPropertyType) => {
    const registrationForm = this.props.event.registrationForm;

    if (!registrationForm) return Promise.resolve();

    return updateRegistrationForm({ ...properties, formId: registrationForm.id }).catch(
      showModernMutationError,
    );
  };

  handleToggleZoom = () => {
    this.setState(state => ({ enableZoom: !state.enableZoom }));

    const zoomLocation = this.props.event.registrationForm && this.props.event.zoomLocation;

    if (!zoomLocation) return;

    this.updateRegistrationForm({ zoomLocation: null });
  };

  handleToggleG2W = () => {
    this.setState(state => ({ enableG2W: !state.enableG2W }));

    const g2wLocation = this.props.event.registrationForm && this.props.event.g2wLocation;

    if (!g2wLocation) return;

    this.updateRegistrationForm({ g2wLocation: null });
  };

  handlePickZoomLocation = ({ zoomUser, ...zoomLocation }: ZoomLocationType) =>
    this.updateRegistrationForm({
      zoomLocation: { ...zoomLocation, zoomUser: { ...zoomUser } },
    });

  handlePickG2WLocation = ({ g2wUser, times, ...g2wLocation }: G2WLocationType) =>
    this.updateRegistrationForm({
      g2wLocation: {
        ...g2wLocation,
        times: times.map(time => ({ ...time })),
        g2wUser: { ...g2wUser },
      },
    });

  getG2wLocation = () => {
    if (this.props.event.g2wLocation) {
      const { g2wUser, times, ...g2wLocation } = this.props.event.g2wLocation;
      return { g2wUser: { ...g2wUser }, times: times.map(time => ({ ...time })), ...g2wLocation };
    }
    return null;
  };

  getZoomLocation = () => {
    if (this.props.event.zoomLocation) {
      const { zoomUser, ...zoomLocation } = this.props.event.zoomLocation;
      return { zoomUser: { ...zoomUser }, ...zoomLocation };
    }
    return null;
  };

  resetLocation = () => {
    const event = this.props.event;
    this.updateRegistrationForm({
      g2wLocation: this.getG2wLocation(),
      zoomLocation: this.getZoomLocation(),
    });
    this.setState({ enableG2W: event.g2wLocation != null, enableZoom: event.zoomLocation != null });
  };

  render() {
    const { event, org } = this.props;
    const { enableZoom, enableG2W } = this.state;
    const registrationForm = event.registrationForm;

    if (!registrationForm) return null;

    const isUpcomingEvent =
      !registrationForm.eventStartDate ||
      moment
        .tz(registrationForm.eventEndDate || registrationForm.eventStartDate, registrationForm.tz)
        .isSameOrAfter(
          undefined,
          (registrationForm.eventEndDate
            ? registrationForm.endDateAllDay
            : registrationForm.startDateAllDay) && 'date',
        );

    return (
      <Root>
        <Card disabled={enableG2W}>
          <CardColumns enabled={enableZoom}>
            <CardIcon>
              <ZoomIcon />
            </CardIcon>

            <CardTitle>Zoom</CardTitle>

            <Tooltip label={enableG2W && 'Only one integration can be enabled at once'}>
              <Switch
                enabled={enableZoom}
                onChange={this.handleToggleZoom}
                size="small"
                disabled={enableG2W}
              />
            </Tooltip>
          </CardColumns>

          {enableZoom && (
            <ZoomLocationPicker
              eventId={event.id}
              integrable={org}
              zoomLocation={event.zoomLocation}
              isUpcomingEvent={isUpcomingEvent}
              defaultTopic={registrationForm.eventName}
              defaultTimezone={registrationForm.tz}
              defaultDate={registrationForm.eventStartDate || null}
              onPick={this.handlePickZoomLocation}
            />
          )}
        </Card>

        <Card disabled={enableZoom}>
          <CardColumns enabled={enableG2W}>
            <CardIcon>
              <G2WIcon />
            </CardIcon>

            <CardTitle>GoToWebinar</CardTitle>

            <Tooltip label={enableZoom && 'Only one integration can be enabled at once'}>
              <Switch
                enabled={enableG2W}
                onChange={this.handleToggleG2W}
                size="small"
                disabled={enableZoom}
              />
            </Tooltip>
          </CardColumns>

          {enableG2W && (
            <G2WLocationPicker
              eventId={event.id}
              integrable={org}
              g2wLocation={event.g2wLocation}
              isUpcomingEvent={isUpcomingEvent}
              defaultSubject={registrationForm.eventName}
              defaultTimezone={registrationForm.tz}
              defaultDate={registrationForm.eventStartDate || null}
              onPick={this.handlePickG2WLocation}
            />
          )}
        </Card>
      </Root>
    );
  }
}

export default createFragmentContainer(RegistrationSettingsIntegrations, {
  event: graphql`
    fragment RegistrationSettingsIntegrations_event on Event {
      id
      zoomLocation {
        id
        kind
        zoomUser {
          id
          zoomId
          firstName
          lastName
          email
          freePlan
        }
        zoomId
        joinUrl
        zoomUrl
        topic
        startTime
        duration
        timezone
      }
      g2wLocation {
        id
        g2wKey
        subject
        times {
          startTime
          endTime
        }
        timezone
        registrationUrl
        g2wUrl
        g2wUser {
          id
          g2wKey
          firstName
          lastName
          email
        }
      }
      registrationForm {
        id
        eventName
        eventStartDate
        eventEndDate
        startDateAllDay
        endDateAllDay
        tz
      }
    }
  `,
  org: graphql`
    fragment RegistrationSettingsIntegrations_org on Org {
      ...ZoomLocationPicker_integrable
      ...G2WLocationPicker_integrable
    }
  `,
});
