/* @flow */
import { graphql } from 'react-relay';
import omit from 'lodash/omit';

import type { CircaFile } from 'utils/uploading/types';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import commitModernMutation from 'graph/utils/commitModernMutation';

import addExpenseAttachment from './addExpenseAttachment';

import type { createExpenseMutationResponse } from './__generated__/createExpenseMutation.graphql';

export type Expense = {
  name: string,
  actualAmount: ?number,
  categoryId: ?string,
  vendorId: ?string,
  deliverableId?: ?string,
  attachments: $ReadOnlyArray<CircaFile>,
  teamAllocations: $ReadOnlyArray<{ id: string, percent: number }>,
};

const mutation = graphql`
  mutation createExpenseMutation($input: CreateExpenseInput!) {
    createExpense(input: $input) {
      event {
        id
        dbId
        name
        totalActualAmount
        budgetCategories {
          edges {
            ...CategoriesTable_categoryEdges
          }
        }
        expenses {
          totalCount
        }
        payments {
          totalCount
        }
      }
      expenseEdge {
        __typename
        node {
          id
          dbId
          name
          note
          actualAmount
          paidAmount
          budgetCategory {
            id
            name
          }
          event {
            id
            name
            slug
          }
          deliverable {
            assignees {
              edges {
                node {
                  id
                  firstName
                  lastName
                  avatar
                }
              }
            }
          }
          expenseTeamAllocations {
            edges {
              node {
                percent
                team {
                  id
                  name
                }
              }
            }
          }
          ...ExpenseItem_expense
          ...ExpensesTableRenderer_expenses
        }
      }
      deliverable {
        id
      }
    }
  }
`;

export default function createExpense(
  eventId: string,
  expense: Expense,
  fromWindow: 'vendor_tab' | 'budget_tab',
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        fromWindow,
        eventId,
        ...omit(expense, ['attachments']),
      },
    },
    updater: mergeUpdaters(
      ...[
        addRecordToConnection({
          parentId: eventId,
          edgeName: 'expenseEdge',
          connections: [{ key: 'ExpensesTable_expenses' }],
          rangeBehavior: 'prepend',
        }),
        expense.vendorId
          ? addRecordToConnection({
              parentId: expense.vendorId,
              edgeName: 'expenseEdge',
              connections: [{ key: 'VendorExpenses_expenses' }],
              rangeBehavior: 'prepend',
            })
          : null,
        expense.deliverableId
          ? addRecordToConnection({
              parentId: expense.deliverableId,
              edgeName: 'expenseEdge',
              connections: [{ field: 'expenses' }],
            })
          : null,
      ].filter(Boolean),
    ),
  }).then((response: createExpenseMutationResponse) => {
    if (response.createExpense) {
      const expenseNode = response.createExpense.expenseEdge.node;

      expense.attachments.forEach(attachment => {
        addExpenseAttachment(expenseNode.id, attachment);
      });
      return expenseNode.id;
    }
    throw new Error('No Expense Id');
  });
}
