/**
 * @flow
 * @relayHash 9fce86b393f53d036bb1d47b8c4c4260
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DependencyInputOption_task$ref = any;
export type DependencyInputQueryVariables = {|
  eventSlug: string
|};
export type DependencyInputQueryResponse = {|
  +event: ?{|
    +deliverables: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +$fragmentRefs: DependencyInputOption_task$ref,
        |}
      |}>
    |}
  |}
|};
export type DependencyInputQuery = {|
  variables: DependencyInputQueryVariables,
  response: DependencyInputQueryResponse,
|};
*/


/*
query DependencyInputQuery(
  $eventSlug: String!
) {
  event(slug: $eventSlug) {
    deliverables {
      edges {
        node {
          id
          name
          ...DependencyInputOption_task
        }
      }
    }
    id
  }
}

fragment DependencyInputOption_task on Deliverable {
  id
  name
  dueDate
  folder {
    name
    id
  }
  assignees {
    edges {
      node {
        ...UsersGroup_users
        id
      }
    }
  }
}

fragment UsersGroup_users on Assignable {
  id
  email
  firstName
  lastName
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DependencyInputQuery",
  "id": null,
  "text": "query DependencyInputQuery(\n  $eventSlug: String!\n) {\n  event(slug: $eventSlug) {\n    deliverables {\n      edges {\n        node {\n          id\n          name\n          ...DependencyInputOption_task\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment DependencyInputOption_task on Deliverable {\n  id\n  name\n  dueDate\n  folder {\n    name\n    id\n  }\n  assignees {\n    edges {\n      node {\n        ...UsersGroup_users\n        id\n      }\n    }\n  }\n}\n\nfragment UsersGroup_users on Assignable {\n  id\n  email\n  firstName\n  lastName\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DependencyInputQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Deliverable",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      {
                        "kind": "FragmentSpread",
                        "name": "DependencyInputOption_task",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DependencyInputQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Deliverable",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dueDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "folder",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Folder",
                        "plural": false,
                        "selections": [
                          v3,
                          v2
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "assignees",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "User",
                                "plural": false,
                                "selections": [
                                  v2,
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "email",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "firstName",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "lastName",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "avatar",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd6cfa638e3adbcfc9528c971b1cca367';
module.exports = node;
