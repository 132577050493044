/* @flow */
import type { FieldType } from 'utils/customization/types';
import getCustomFieldFilterConverterByKind from 'utils/filters/getCustomFieldFilterConverterByKind';
import {
  numberRangeToStringParam,
  stringArrayToStringParam,
} from 'utils/routing/stringifyQueryParamValues';

export default function getOpportunityFilterConverters(customFields: $ReadOnlyArray<FieldType>) {
  return customFields.reduce((converter, customField) => {
    if (customField.fieldName != null) {
      switch (customField.fieldName) {
        case 'amount':
          return { ...converter, [customField.fieldName]: numberRangeToStringParam };
        default:
          return { ...converter, [customField.fieldName]: stringArrayToStringParam };
      }
    }
    return { ...converter, ...getCustomFieldFilterConverterByKind(customField) };
  }, {});
}
