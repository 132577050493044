/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffLastActivityCell_staffer$ref: FragmentReference;
export type StaffLastActivityCell_staffer = {|
  +user: {|
    +lastSeenAt: ?any
  |},
  +$refType: StaffLastActivityCell_staffer$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "StaffLastActivityCell_staffer",
  "type": "StaffMembership",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastSeenAt",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3f8054b365952024105b5ba583f53d2a';
module.exports = node;
