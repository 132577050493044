/* @flow */
import type { FieldType } from 'utils/customization/types';
import {
  type CustomFieldParam,
  stringParamToCustomFieldBoolean,
  stringParamToCustomFieldDateRange,
  stringParamToCustomFieldEnumArray,
  stringParamToCustomFieldNumberRange,
  stringParamToCustomFieldStringArray,
} from 'utils/routing/parseTypedQueryString';

export default function getCustomFieldQueryParamsByKind(customField: FieldType): CustomFieldParam {
  switch (customField.kind) {
    case 'BOOLEAN':
      return { [customField.id]: stringParamToCustomFieldBoolean };
    case 'DATE':
      return { [customField.id]: stringParamToCustomFieldDateRange };
    case 'NUMBER':
    case 'CURRENCY':
      return { [customField.id]: stringParamToCustomFieldNumberRange };
    case 'SELECT':
    case 'MULTISELECT':
      return {
        [customField.id]: stringParamToCustomFieldEnumArray(
          customField.options.map(option => option.value),
        ),
      };
    default:
      return { [customField.id]: stringParamToCustomFieldStringArray };
  }
}
