/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { Remove as RemoveIcon } from 'images';
import ConfirmationWindow from 'components/ConfirmationWindow';

import { type CategoryCellPropsType } from './index';

import { type CategoryActionCell_categoryEdge } from './__generated__/CategoryActionCell_categoryEdge.graphql';
import { type CategoryActionCell_event } from './__generated__/CategoryActionCell_event.graphql';

const Action = styled(RemoveIcon)`
  width: 15px;
  height: 15px;
  margin-top: 5px;
  cursor: pointer;
  color: #868f96;

  &:hover {
    color: #4db1dd;
  }
`;

class CategoryActionCell extends React.PureComponent<
  CategoryCellPropsType<CategoryActionCell_categoryEdge, CategoryActionCell_event>,
  { showConfirmation: boolean },
> {
  state = { showConfirmation: false };

  handleRemoveCategory = () => {
    const categoryEdge = this.props.categoryEdge || this.props.categoryEdgeMock;

    if (!categoryEdge) return;

    this.props.removeCategoryEdge();
  };

  handleClick = () => {
    if (this.props.adding) {
      this.props.removeAdding();
    } else {
      this.setState({ showConfirmation: true });
    }
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    const { categoryEdge } = this.props;

    return (
      <React.Fragment>
        <Action onClick={this.handleClick} />

        {this.state.showConfirmation &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              message={`This will remove the ${
                categoryEdge ? categoryEdge.node.name : ''
              } expense category from the event. It will not remove the actual expenses.`}
              onConfirm={this.handleRemoveCategory}
              onHide={this.handleHideConfirmation}
            />,
            document.body,
          )}
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  CategoryActionCell,
  graphql`
    fragment CategoryActionCell_categoryEdge on EventBudgetCategoryEdge {
      node {
        id
        name
      }
    }

    fragment CategoryActionCell_event on Event {
      id
    }
  `,
);
