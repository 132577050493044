/**
 * @flow
 * @relayHash 4844f09decb792ff3f4080200732ffa7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFolderInput = {
  clientMutationId?: ?string,
  folderId: string,
  name: string,
};
export type updateFolderMutationVariables = {|
  input: UpdateFolderInput
|};
export type updateFolderMutationResponse = {|
  +updateFolder: ?{|
    +folder: {|
      +id: string,
      +name: string,
    |}
  |}
|};
export type updateFolderMutation = {|
  variables: updateFolderMutationVariables,
  response: updateFolderMutationResponse,
|};
*/


/*
mutation updateFolderMutation(
  $input: UpdateFolderInput!
) {
  updateFolder(input: $input) {
    folder {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFolderInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateFolder",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateFolderInput!"
      }
    ],
    "concreteType": "UpdateFolderPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "folder",
        "storageKey": null,
        "args": null,
        "concreteType": "Folder",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateFolderMutation",
  "id": null,
  "text": "mutation updateFolderMutation(\n  $input: UpdateFolderInput!\n) {\n  updateFolder(input: $input) {\n    folder {\n      id\n      name\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateFolderMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateFolderMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40c2aaef57dbae65fc39475a9a52427f';
module.exports = node;
