import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { download } from 'utils/Attachments';

import addAttachmentToBrief from 'graph/mutations/event_brief/addAttachmentToBrief';
import removeAttachmentFromBrief from 'graph/mutations/event_brief/removeAttachmentFromBrief';
import showModernMutationError from 'graph/utils/showModernMutationError';

export default class AttachmentPreview extends React.Component {
  static propTypes = {
    disableDelete: PropTypes.bool,
    onSet: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    event_relay_id: PropTypes.string,
    current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        filename: PropTypes.string.isRequired,
        fileurl: PropTypes.string.isRequired,
        filetype: PropTypes.string.isRequired,
      }),
    ).isRequired,
    canUpdateBrief: PropTypes.bool,
    includedInBrief: PropTypes.bool,
  };

  handleKeyPress = e => {
    // next -> 39, 40
    // prev -> 37, 38
    // esc -> 27
    if ((e.which === 39 || e.which === 40) && this.next) {
      this.props.onSet(this.next);
    } else if ((e.which === 37 || e.which === 38) && this.prev) {
      this.props.onSet(this.prev);
    } else if (e.which === 27) {
      this.handleClose();
    }
  };

  handleRemove = attachment => {
    if (this.prev) {
      this.props.onSet(this.prev);
    } else if (this.next) {
      this.props.onSet(this.next);
    } else {
      this.handleClose();
    }

    this.props.onRemove(attachment);
  };

  handleClose = () => {
    this.props.onSet(null);
  };

  handleDownload = attachment => {
    const { fileurl, filename } = attachment;

    download(fileurl, filename);
  };

  handleSaveBrief = attachment => {
    if (this.props.includedInBrief) {
      removeAttachmentFromBrief(attachment.id, this.props.event_relay_id).catch(
        showModernMutationError,
      );
    } else {
      addAttachmentToBrief(attachment.id, this.props.event_relay_id).catch(showModernMutationError);
    }
  };

  render() {
    const { attachments, current, onSet, onRemove, canUpdateBrief, includedInBrief } = this.props;

    if (!current) return <div />;
    const current_attachment_index = attachments.findIndex(x => x.id === current);
    const attachment = attachments[current_attachment_index];
    const next = attachments[current_attachment_index + 1];
    this.next = next;
    const prev = attachments[current_attachment_index - 1];
    this.prev = prev;

    const briefClassName = includedInBrief ? 'fa-minus' : 'fa-plus';
    const briefTooltipName = includedInBrief ? 'Remove from Brief' : 'Add to Brief';

    const isImage = attachment.filetype.startsWith('image/');
    const isPdf = attachment.filetype === 'application/pdf';
    const isOfficeDoc = [
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ].includes(attachment.filetype);

    return (
      <div
        className="attachment-preview"
        onKeyDown={this.handleKeyPress}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        ref={el => el && el.focus()}
      >
        <div className="attachment-preview-overlay" onClick={this.handleClose} />
        <a className="attachment-preview-close" onClick={this.handleClose}>
          <i className="fa fa-times fa-fw" />
        </a>
        {prev && (
          <a className="attachment-preview-previous" onClick={() => onSet(prev)}>
            <i className="fa fa-fw fa-chevron-left" />
          </a>
        )}
        {next && (
          <a className="attachment-preview-next" onClick={() => onSet(next)}>
            <i className="fa fa-fw fa-chevron-right" />
          </a>
        )}

        <div className="attachment-preview-content">
          {isImage && (
            <img src={attachment.fileurl} alt={attachment.filename} key={attachment.fileurl} />
          )}
          {isPdf && (
            <div className="attachment-preview-pdf">
              <iframe
                title="google docs"
                src={`https://docs.google.com/gview?url=${attachment.fileurl}&embedded=true`}
                key={attachment.fileurl}
                frameBorder="0"
              />
            </div>
          )}
          {isOfficeDoc && (
            <div className="attachment-preview-pdf">
              <iframe
                title="office docs"
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${attachment.fileurl}`}
                key={attachment.fileurl}
                frameBorder="0"
              />
            </div>
          )}
          {!isImage &&
            !isPdf &&
            !isOfficeDoc && (
              <div className="attachment-preview-unavailable">
                <p>No Preview Available.</p>
                <p>Download the file to view locally.</p>

                <a href={attachment.fileurl} download={attachment.filename}>
                  <i className="fa fa-download" /> Download
                </a>
              </div>
            )}
        </div>

        <div className="attachment-preview-meta">
          <h4>{attachment.filename}</h4>

          <ul className="attachment-preview-actions">
            {canUpdateBrief && (
              <li>
                <a onClick={() => this.handleSaveBrief(attachment)}>
                  <i className={classNames('fa fa-fw', briefClassName)} />
                  {briefTooltipName}
                </a>
              </li>
            )}
            <li>
              <a href={attachment.fileurl} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-external-link" /> Open
              </a>
            </li>
            <li>
              <a onClick={() => this.handleDownload(attachment)}>
                <i className="fa fa-download" /> Download
              </a>
            </li>
            {onRemove &&
              !attachment.email &&
              !this.props.disableDelete && (
                <li>
                  <a onClick={() => this.handleRemove(attachment)}>
                    <i className="fa fa-trash" /> Delete
                  </a>
                </li>
              )}
          </ul>
        </div>
      </div>
    );
  }
}
