/**
 * @flow
 * @relayHash a56afe5ada89b4e5ec4cf79dae4a6e74
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveAttachmentInput = {
  clientMutationId?: ?string,
  attachmentId: string,
  eventId: string,
};
export type removeAttachmentMutationVariables = {|
  input: RemoveAttachmentInput
|};
export type removeAttachmentMutationResponse = {|
  +removeAttachment: ?{|
    +removedAttachmentId: string
  |}
|};
export type removeAttachmentMutation = {|
  variables: removeAttachmentMutationVariables,
  response: removeAttachmentMutationResponse,
|};
*/


/*
mutation removeAttachmentMutation(
  $input: RemoveAttachmentInput!
) {
  removeAttachment(input: $input) {
    removedAttachmentId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveAttachmentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeAttachment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveAttachmentInput!"
      }
    ],
    "concreteType": "RemoveAttachmentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedAttachmentId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeAttachmentMutation",
  "id": null,
  "text": "mutation removeAttachmentMutation(\n  $input: RemoveAttachmentInput!\n) {\n  removeAttachment(input: $input) {\n    removedAttachmentId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeAttachmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeAttachmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7fcff702578f0e99a900b31714a775f9';
module.exports = node;
