/* @flow */
import BaseColumn from './BaseColumn';
import styled, { css } from 'styled-components';

const LinkColumn = styled(BaseColumn)`
  ${props =>
    !props.header &&
    css`
      color: ${props.theme.primaryActionColor};
    `};
`;

export default LinkColumn;
