/**
 * @flow
 * @relayHash 1b864032835e0763f4c334706c1b81a5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserItemsContainer_query$ref = any;
export type UserFilterItemQueryVariables = {|
  query: string,
  userIds: $ReadOnlyArray<string>,
  hasTeamAccess: boolean,
  eventIds?: ?$ReadOnlyArray<string>,
  publicRuleName: string,
  orgSlug?: ?string,
  resourceToken: string,
|};
export type UserFilterItemQueryResponse = {|
  +query: {|
    +publicRule: boolean,
    +$fragmentRefs: UserItemsContainer_query$ref,
  |}
|};
export type UserFilterItemQuery = {|
  variables: UserFilterItemQueryVariables,
  response: UserFilterItemQueryResponse,
|};
*/


/*
query UserFilterItemQuery(
  $query: String!
  $userIds: [ID!]!
  $hasTeamAccess: Boolean!
  $eventIds: [ID!]
  $publicRuleName: String!
  $orgSlug: String
  $resourceToken: String!
) {
  query {
    publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
    ...UserItemsContainer_query
  }
}

fragment UserItemsContainer_query on Query {
  searchUsers(first: 15, query: $query, hasTeamAccess: $hasTeamAccess, staffedEventIds: $eventIds) {
    edges {
      node {
        id
        email
        firstName
        lastName
        ...MaterialAvatar_user
      }
    }
  }
  selectedUsers: nodes(ids: $userIds) {
    __typename
    id
    ... on User {
      id
      email
      firstName
      lastName
      ...MaterialAvatar_user
    }
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userIds",
    "type": "[ID!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hasTeamAccess",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "eventIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "publicRuleName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgSlug",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "resourceToken",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicRule",
  "args": [
    {
      "kind": "Variable",
      "name": "orgSlug",
      "variableName": "orgSlug",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "publicRuleName",
      "variableName": "publicRuleName",
      "type": "String!"
    },
    {
      "kind": "Variable",
      "name": "resourceToken",
      "variableName": "resourceToken",
      "type": "String"
    }
  ],
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserFilterItemQuery",
  "id": null,
  "text": "query UserFilterItemQuery(\n  $query: String!\n  $userIds: [ID!]!\n  $hasTeamAccess: Boolean!\n  $eventIds: [ID!]\n  $publicRuleName: String!\n  $orgSlug: String\n  $resourceToken: String!\n) {\n  query {\n    publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)\n    ...UserItemsContainer_query\n  }\n}\n\nfragment UserItemsContainer_query on Query {\n  searchUsers(first: 15, query: $query, hasTeamAccess: $hasTeamAccess, staffedEventIds: $eventIds) {\n    edges {\n      node {\n        id\n        email\n        firstName\n        lastName\n        ...MaterialAvatar_user\n      }\n    }\n  }\n  selectedUsers: nodes(ids: $userIds) {\n    __typename\n    id\n    ... on User {\n      id\n      email\n      firstName\n      lastName\n      ...MaterialAvatar_user\n    }\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserFilterItemQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "FragmentSpread",
            "name": "UserItemsContainer_query",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserFilterItemQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "searchUsers",
            "storageKey": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 15,
                "type": "Int"
              },
              {
                "kind": "Variable",
                "name": "hasTeamAccess",
                "variableName": "hasTeamAccess",
                "type": "Boolean"
              },
              {
                "kind": "Variable",
                "name": "query",
                "variableName": "query",
                "type": "String"
              },
              {
                "kind": "Variable",
                "name": "staffedEventIds",
                "variableName": "eventIds",
                "type": "[ID!]"
              }
            ],
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v4,
                      v5,
                      v6
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "selectedUsers",
            "name": "nodes",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "userIds",
                "type": "[ID!]!"
              }
            ],
            "concreteType": null,
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              v2,
              {
                "kind": "InlineFragment",
                "type": "User",
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '52c01e3e735b5738f20eb922e9ac0e54';
module.exports = node;
