/* @flow */
import React from 'react';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';
import UserStore from 'stores/UserStore';

import getHomepageUrl from 'utils/getHomepageUrl';

import AuthButton from 'components/Auth/AuthButton';
import TextField, { Label } from 'components/material/TextField';

import AcceptAgreementsCheckBoxes, {
  type AgreementValuesReturn,
} from '../../Auth/components/AcceptAgreementsCheckBoxes';

const StyledTextField = styled(TextField)`
  margin: 2px 0 24px 0;

  input {
    &:focus {
      border-bottom-color: #5554c3;

      ~ ${Label} {
        color: #5554c3;
      }
    }
  }
`;

const StyledButton = styled(AuthButton)`
  margin-top: 40px;
  padding: 10px 14px;
  border-radius: 52px;
  background-color: #5e5bff;

  &:hover {
    background-color: #403ec9;
  }
`;

export default class Form extends React.PureComponent<
  {
    token: string,
    history: RouterHistory,
  },
  {
    passwordError: boolean,
    loading: boolean,
    acceptedTOS: boolean,
    acceptedPrivacyPolicy: boolean,
    termsError: boolean,
  },
> {
  state = {
    passwordError: false,
    loading: false,
    acceptedTOS: false,
    acceptedPrivacyPolicy: false,
    termsError: false,
  };

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token = this.props.token;
    const elements = e.currentTarget.elements;
    const passwordEl = elements.namedItem('password');

    const user = {
      password: passwordEl instanceof HTMLInputElement ? passwordEl.value.trim() : '',
    };

    const errors = {
      passwordError: user.password.length < 8,
      termsError: !this.state.acceptedTOS || !this.state.acceptedPrivacyPolicy,
    };

    this.setState(errors);

    const noError = Object.values(errors).every(error => !error);

    if (noError) {
      const search = this.props.history.location.search;
      const searchFormatted = qs.parse(search, { ignoreQueryPrefix: true });
      this.setState({ loading: true });
      UserStore.confirm(token, user)
        .then(response => {
          // Redirect user to custom org subdomain when logging in from app.circa.co
          if (response.domain_to_redirect != null) {
            window.location = `${response.domain_to_redirect}${searchFormatted.next ? search : ''}`;
            return;
          }

          if (response.onboarding_step >= 0 && response.onboarding_step < 5) {
            window.location = `/onboarding${searchFormatted.next ? search : ''}`;
            return;
          }
          const newUser = UserStore.getState().user;
          if (searchFormatted.next) {
            this.props.history.push(searchFormatted.next);
          } else {
            this.props.history.push(
              getHomepageUrl(
                newUser.homepage_list_id == null ? newUser.homepage : newUser.homepage_list_id,
                newUser.relay_id,
              ),
            );
          }
        })
        .catch(() => {
          this.props.history.replace('/');
        });
    }
  };

  handleChangeAgreements = (values: AgreementValuesReturn) => {
    this.setState(values);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <StyledTextField
          type="password"
          label="Set your password"
          name="password"
          error={this.state.passwordError ? 'Password must be at least 8 characters long' : null}
          autoFocus
        />
        <AcceptAgreementsCheckBoxes
          values={{
            acceptedTOS: this.state.acceptedTOS,
            acceptedPrivacyPolicy: this.state.acceptedPrivacyPolicy,
          }}
          onChange={this.handleChangeAgreements}
          error={this.state.termsError}
        />
        <StyledButton
          type="submit"
          label="Accept invitation"
          primary
          loading={this.state.loading}
        />
      </form>
    );
  }
}
