/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactFormSubmission_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationFormComponent_org$ref: FragmentReference;
export type RegistrationFormComponent_org = {|
  +settings: {|
    +font: string,
    +primaryColor: string,
  |},
  +$fragmentRefs: ContactFormSubmission_org$ref,
  +$refType: RegistrationFormComponent_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationFormComponent_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "font",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "primaryColor",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactFormSubmission_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '12b0d003ed42a82f6ff1174e45d77965';
module.exports = node;
