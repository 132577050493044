/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Overlay from 'components/material/Overlay';

import ImportOverlay from './ImportOverlay';

const StyledOverlay = styled(Overlay)`
  padding: 10px;
  width: 250px;
`;

type ImportOptions = { sourceEvent: number, copyDueDates: boolean, folders: Array<number> };

export default class ImportOverlayContainer extends React.PureComponent<{
  show: boolean,
  target: any,
  container: any,
  onHide: () => void,
  onImport: ImportOptions => Promise<any>,
  hasStartDate: boolean,
}> {
  render() {
    const { show, target, container, onHide, onImport, hasStartDate } = this.props;
    return (
      <StyledOverlay show={show} target={target} onHide={onHide} container={container}>
        <ImportOverlay hasStartDate={hasStartDate} onImport={onImport} onHide={onHide} />
      </StyledOverlay>
    );
  }
}
