import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import GoogleMapsScriptWrapper from 'components/GoogleMapsScriptWrapper';
import Map from './Map';

function LoadingMap(props) {
  return (
    <div className={classNames('map-view', props.className)}>
      <div style={{ width: '100%', height: '100%' }} />
    </div>
  );
}

LoadingMap.propTypes = { className: PropTypes.string };

function WrappedMap(props) {
  return (
    <GoogleMapsScriptWrapper
      loadingComponent={<LoadingMap className={props.className} />}
    >
      <Map {...props} />
    </GoogleMapsScriptWrapper>
  );
}

WrappedMap.propTypes = { className: PropTypes.string };

export default WrappedMap;
