/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link } from 'react-router-dom';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { MemberFullName_user } from './__generated__/MemberFullName_user.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: -6px -18px -6px -10px;
`;

const StyledAvatar = styled(MaterialAvatar)`
  margin-right: 12px;
`;

const Tag = styled.div`
  margin-left: 15px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 1.6;
  border-radius: 20px;
  background: ${props => props.theme.primaryActionColor};
  color: ${props => props.theme.primaryActionTextColor};
`;

type Props = {
  user: MemberFullName_user,
  location: Location,
};

const stopPropagation = (e: SyntheticMouseEvent<>) => {
  e.stopPropagation();
};

function MemberFullName({ user, location }: Props) {
  const fullName = fullNameOfUser(user);
  return (
    <Container>
      <StyledAvatar user={user} radius={11.5} />
      {user.viewerCanSeeProfile ? (
        <Link
          to={{
            pathname: `/workspace/members/${user.id}`,
            state: {
              prevPage: true,
              search: location.search,
            },
          }}
          onClick={stopPropagation}
        >
          {fullName}
        </Link>
      ) : (
        <div>{fullName}</div>
      )}
      {user.admin && <Tag>Admin</Tag>}
    </Container>
  );
}

export default createFragmentContainer(
  MemberFullName,
  graphql`
    fragment MemberFullName_user on User {
      viewerCanSeeProfile
      id
      firstName
      lastName
      email
      admin
      ...MaterialAvatar_user
    }
  `,
);
