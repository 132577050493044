/**
 * @flow
 * @relayHash 8d1b382b777c1bc53061c85288172e4f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EventListShare = "PRIVATE" | "PUBLIC" | "SSO";
export type DuplicateEventListInput = {
  clientMutationId?: ?string,
  eventListId: string,
};
export type duplicateEventListMutationVariables = {|
  input: DuplicateEventListInput
|};
export type duplicateEventListMutationResponse = {|
  +duplicateEventList: ?{|
    +eventListsEdge: {|
      +node: {|
        +id: string,
        +name: string,
        +shared: EventListShare,
      |}
    |},
    +user: {|
      +eventLists: {|
        +totalCount: number
      |},
      +allEventLists: {|
        +totalCount: number
      |},
    |},
  |}
|};
export type duplicateEventListMutation = {|
  variables: duplicateEventListMutationVariables,
  response: duplicateEventListMutationResponse,
|};
*/


/*
mutation duplicateEventListMutation(
  $input: DuplicateEventListInput!
) {
  duplicateEventList(input: $input) {
    eventListsEdge {
      node {
        id
        name
        shared
      }
    }
    user {
      eventLists(first: 5) {
        totalCount
      }
      allEventLists: eventLists {
        totalCount
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DuplicateEventListInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DuplicateEventListInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "eventListsEdge",
  "storageKey": null,
  "args": null,
  "concreteType": "EventListRequiredEdge",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "EventList",
      "plural": false,
      "selections": [
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "shared",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "eventLists",
  "storageKey": "eventLists(first:5)",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 5,
      "type": "Int"
    }
  ],
  "concreteType": "EventListRequiredConnection",
  "plural": false,
  "selections": v4
},
v6 = {
  "kind": "LinkedField",
  "alias": "allEventLists",
  "name": "eventLists",
  "storageKey": null,
  "args": null,
  "concreteType": "EventListRequiredConnection",
  "plural": false,
  "selections": v4
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "duplicateEventListMutation",
  "id": null,
  "text": "mutation duplicateEventListMutation(\n  $input: DuplicateEventListInput!\n) {\n  duplicateEventList(input: $input) {\n    eventListsEdge {\n      node {\n        id\n        name\n        shared\n      }\n    }\n    user {\n      eventLists(first: 5) {\n        totalCount\n      }\n      allEventLists: eventLists {\n        totalCount\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "duplicateEventListMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "duplicateEventList",
        "storageKey": null,
        "args": v1,
        "concreteType": "DuplicateEventListPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v5,
              v6
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "duplicateEventListMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "duplicateEventList",
        "storageKey": null,
        "args": v1,
        "concreteType": "DuplicateEventListPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v5,
              v6,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a02afc13591d4ff073e7ce6513e03fc';
module.exports = node;
