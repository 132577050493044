/* @flow */
import React from 'react';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';

import Menu from 'components/page/Menu';
import MenuItem from 'components/page/MenuItem';

const Container = styled.div`
  height: 48px;
  border-bottom: 1px solid #eaebec;
  overflow: hidden;
`;

export default class TeamMenu extends React.PureComponent<{
  urlPrefix: string,
  location: Location,
}> {
  render() {
    const urlPrefix = this.props.urlPrefix;
    return (
      <Container>
        <Menu>
          <MenuItem
            isActive={() => this.props.location.pathname.endsWith(`/members`)}
            text="Members"
            to={`${urlPrefix}/members`}
            desktopOnly
          />
          <MenuItem
            isActive={() => this.props.location.pathname.endsWith(`/style`)}
            text="Style"
            to={`${urlPrefix}/style`}
          />
        </Menu>
      </Container>
    );
  }
}
