/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type FormDetailsType } from 'components/EventRequestForm/lib/types';

import type {
  updateEventRequestFormMutationResponse,
  updateEventRequestFormMutationVariables,
} from './__generated__/updateEventRequestFormMutation.graphql';

const mutation = graphql`
  mutation updateEventRequestFormMutation($input: UpdateEventRequestFormInput!) {
    updateEventRequestForm(input: $input) {
      requestForm {
        ...EventRequestFormBuilderBody_requestForm @relay(mask: false)
      }
    }
  }
`;

export default function updateEventRequestForm(
  requestFormId: string,
  { sections, eventTemplate, ...requestForm }: FormDetailsType,
): Promise<updateEventRequestFormMutationResponse> {
  const variables: updateEventRequestFormMutationVariables = {
    input: {
      requestFormId,
      requestForm: {
        ...requestForm,
        eventTemplateId: eventTemplate && eventTemplate.id,
        sections: sections.map(({ id, questions, ...section }) => ({
          ...section,
          ...(id.startsWith('section_') ? {} : { id }),
          questions: questions.map(
            ({
              id: questionId,
              sectionId,
              selectOptions,
              mappingCustomField,
              budgetCategories,
              budgetCategory,
              allowRequesterToChooseBudgetCategory,
              selectUsers,
              ...question
            }) => ({
              ...question,
              ...(questionId.startsWith('question_') ? {} : { id: questionId }),
              mappingCustomFieldId: mappingCustomField && mappingCustomField.id,
              selectOptionIds: selectOptions.map(option => option.id),
              selectUserIds: selectUsers.map(user => user.id),
              budgetCategoryIds: budgetCategories.map(category => category.id),
              budgetCategoryId: budgetCategory ? budgetCategory.id : null,
            }),
          ),
        })),
      },
    },
  };

  return commitModernMutation({
    mutation,
    variables,
  });
}
