/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import CommentItem from 'components/Comments/CommentItem'; // eslint-disable-line

import type { createCommentMutationResponse } from './__generated__/createCommentMutation.graphql';

const mutation = graphql`
  mutation createCommentMutation($input: CreateCommentInput!) {
    createComment(input: $input) {
      commentEdge {
        __typename
        node {
          id
          ...CommentItem_comment
        }
      }
    }
  }
`;

export default function createComment(commentableId: string, content: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        commentableId,
        content,
      },
    },
    updater: addRecordToConnection({
      parentId: commentableId,
      connections: [{ key: 'TaskComments_comments' }, { key: 'SubmissionReviewComments_comments' }],
      edgeName: 'commentEdge',
    }),
  }).then((response: createCommentMutationResponse) => {
    if (response.createComment) {
      return response.createComment.commentEdge.node.id;
    }
    return null;
  });
}
