/* @flow */
import React from 'react';
import styled from 'styled-components';

import showErrorPopup from 'utils/showErrorPopup';
import readHTML from 'utils/requests/readHTML';
import insertDriveFile from 'utils/export/insertDriveFile';

import Window, { WindowClose, WindowContent } from 'components/material/Window';
import ProgressBar from 'components/material/ProgressBar';
import Button from 'components/material/Button';

const StyledWindowContent = styled(WindowContent)`
  padding: 20px 25px 15px 25px;
`;

const StyledWindowClose = styled(WindowClose)`
  padding: 0;
  font-size: 36px;
  line-height: 20px;
`;

const Message = styled.div`
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: #4a5665;
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: 10px;
  > div {
    background-color: #26d17c;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

export default class DocDownloadOverlay extends React.Component<
  {
    exportDocUrl: string,
    fileName: string,
    onHide: () => void,
    onDownload?: () => void,
  },
  {
    progress: number,
    progressMessage: string,
    externalLink: ?string,
  },
> {
  constructor(props: $PropertyType<DocDownloadOverlay, 'props'>) {
    super(props);
    this.state = {
      progress: 0,
      progressMessage: 'Generating file',
      externalLink: null,
    };
    readHTML(this.props.exportDocUrl)
      .then((html: string) => {
        const blob = new Blob([html], { type: 'text/html' });
        this.setState({ progressMessage: 'Sending file to Google Drive' });
        return insertDriveFile(blob, this.props.fileName);
      })
      .then(externalLink => {
        if (this.props.onDownload) {
          this.props.onDownload();
        }
        this.setState({ externalLink, progress: 100 });
        this.stopTimer();
      })
      .catch(showErrorPopup);
  }

  componentDidMount() {
    this.timer = setInterval(this.progress, 700);
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  stopTimer = () => {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  };

  progress = () => {
    const progress = this.state.progress;
    if (progress != null) {
      if (progress < 50) {
        const diff = Math.random() * 15;
        this.setState({ progress: progress + diff });
      } else if (this.state.progress !== 95) {
        const diff = Math.random() * 10;
        this.setState({ progress: Math.min(progress + diff, 95) });
      } else {
        this.stopTimer();
      }
    }
  };

  handleOpen = () => {
    this.props.onHide();
    window.open(this.state.externalLink);
  };

  timer: ?IntervalID;

  render() {
    return (
      <Window size="small" onHide={() => {}}>
        <StyledWindowContent>
          <StyledWindowClose onClick={this.props.onHide} />
          <Message>{this.state.progressMessage}</Message>
          <StyledProgressBar value={this.state.progress} />
          <ActionContainer>
            <Button
              primary
              label="Open"
              disabled={this.state.externalLink == null}
              onClick={this.handleOpen}
            />
          </ActionContainer>
        </StyledWindowContent>
      </Window>
    );
  }
}
