/* @flow */
import React from 'react';

import TextField from 'components/material/TextField';

export default class NumberField extends React.PureComponent<
  {
    label?: string,
    placeholder?: string,
    fractionDigits?: number,
    defaultValue?: ?number,
    onChange?: (SyntheticEvent<HTMLInputElement>, ?string | number) => void,
    className?: string,
    autoFocus?: boolean,
    required?: boolean,
    name?: string,
    onClick?: (SyntheticEvent<HTMLInputElement>) => void,
    onFocus?: (SyntheticEvent<HTMLInputElement>) => void,
    onBlur?: (SyntheticEvent<HTMLInputElement>, value: ?number, name: string) => void,
    readOnly?: boolean,
    disabled?: boolean,
    maxLength?: number,
    error?: ?string | ?boolean,
    inputRef?: HTMLInputElement => void,
  },
  {
    value: ?string | number,
    error: boolean,
  },
> {
  state = {
    error: false,
    value: this.props.defaultValue,
  };

  numberRef: HTMLInputElement;

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const testValue = /^[0-9,.]*$/.test(e.currentTarget.value);
    this.setState({
      value: e.currentTarget.value,
      error: !testValue,
    });

    if (this.props.onChange) {
      this.props.onChange(e, e.currentTarget.value);
    }
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const floatValue: number = parseFloat(e.currentTarget.value);
    const value = Number.isFinite(floatValue) ? floatValue : null;
    this.setState({
      error: false,
      value:
        value != null ? parseFloat(value.toFixed(this.props.fractionDigits || 0)).toString() : null,
    });

    if (this.props.onBlur) {
      this.props.onBlur(e, value, this.props.name || '');
    }
  };

  handleFocus = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.numberRef.blur();
    }
  };

  render() {
    return (
      <TextField
        name={this.props.name}
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={`${this.state.value != null ? this.state.value : ''}`}
        onClick={this.props.onClick}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        maxLength={this.props.maxLength}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
        autoFocus={this.props.autoFocus}
        required={this.props.required}
        error={this.props.error || this.state.error}
        inputRef={n => {
          if (this.props.inputRef != null) {
            this.props.inputRef(n);
          }
          this.numberRef = n;
        }}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        className={this.props.className}
      />
    );
  }
}
