/* @flow */
import * as React from 'react';

import ContactsSearchContent from './ContactsSearchContent';
import EventsSearchContent from './EventsSearchContent';
import GeneralSearchContent from './GeneralSearchContent';
import NotesSearchContent from './NotesSearchContent';
import SearchMenu, { type SearchMenuItemTypes } from './SearchMenu';

export type ContentComponentProps = {
  query: string,
  exactMatch: boolean,
  includeContacts: boolean,
};

const searchTabComponentMapping: {
  [SearchMenuItemTypes]: React.ComponentType<ContentComponentProps>,
} = {
  ALL: GeneralSearchContent,
  EVENTS: EventsSearchContent,
  CONTACTS: ContactsSearchContent,
  NOTES: NotesSearchContent,
};

class SearchOverlay extends React.PureComponent<
  { query: string, includeContacts: boolean, onClose: () => void },
  { exactMatch: boolean, active: SearchMenuItemTypes },
> {
  state = {
    exactMatch: false,
    active: 'ALL',
  };

  handleToggleExact = (exactMatch: boolean) => {
    this.setState({ exactMatch });
  };

  handleActiveMenuChange = (active: SearchMenuItemTypes) => {
    this.setState({ active });
  };

  render() {
    const { query, includeContacts, onClose } = this.props;
    const { active, exactMatch } = this.state;
    const SearchContentComponent = searchTabComponentMapping[active];
    return (
      <>
        <SearchMenu
          onClose={onClose}
          active={active}
          exactMatch={exactMatch}
          onToggleExact={this.handleToggleExact}
          onActiveMenuChange={this.handleActiveMenuChange}
          includeContacts={includeContacts}
        />
        {query && (
          <SearchContentComponent
            query={query}
            exactMatch={exactMatch}
            includeContacts={includeContacts}
          />
        )}
      </>
    );
  }
}

export default SearchOverlay;
