/* @flow */
import { graphql } from 'react-relay/compat';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type InviteFromWindow } from 'components/InviteWindow';
import { type ParsedMembersFilters } from 'views/Main/Workspace/Members/lib/parseMembersFilters';

import type {
  invitePeopleMutationResponse,
  invitePeopleMutationVariables,
} from './__generated__/invitePeopleMutation.graphql';

const mutation = graphql`
  mutation invitePeopleMutation($input: InvitePeopleInput!, $query: String) {
    invitePeople(input: $input) {
      orgUserEdges {
        node {
          id
          dbId
          slug
          firstName
          lastName
          email
          avatar
          hasTeamAccess
          ...MemberFullName_user
          ...MemberEmail_user
          ...MemberEventCount_user
          ...MemberTeamCount_user
          ...MemberLastActivity_user
          ...MemberInviteStatus_user
          ...MembersActionsColumn_user
        }
      }
      staffMembershipEdges {
        node {
          id
          user {
            id
            dbId
            slug
            firstName
            lastName
            email
            avatar
            viewerCanSeeProfile
          }
          event {
            id
            ...StaffTableRenderer_totalCountEvent
          }
          ...StaffNameCell_staffer
          ...StaffMemberEmail_staffer
          ...StaffAccessLevelCell_staffer
          ...StaffLastActivityCell_staffer
          ...StaffActionsCell_staffer
          ...StaffOnsiteCell_staffer
          ...CustomizableText_customizable
          ...CustomizableTextarea_customizable
          ...CustomizableLink_customizable
          ...CustomizableDate_customizable
          ...CustomizableBoolean_customizable
          ...CustomizableNumber_customizable
          ...CustomizableCurrency_customizable
          ...CustomizableSelect_customizable
          ...CustomizableMultiselect_customizable
          ...CustomizableUserSelect_customizable
          ...CustomizableUserMultiselect_customizable
        }
      }
      teamMembershipEdges {
        node {
          id
          dbId
          slug
          firstName
          lastName
          email
          avatar
          memberships {
            edges {
              node {
                id
                ...TeamMembersTablePagination_totalCountTeam
              }
            }
          }
        }
        ...TeamMembersTableRow_member
      }
      org {
        id
        subscription {
          fatmLimit
          fatmCount
        }
        ...MembersPagination_totalCountUsers
      }
    }
  }
`;

type InputType = $PropertyType<invitePeopleMutationVariables, 'input'>;
type PersonInvites = $PropertyType<InputType, 'personInvites'>;
type StaffInvites = $PropertyType<InputType, 'staffInvites'>;

export default function invitePeople(
  orgId: string,
  invitePeopleInput: {
    personInvites: PersonInvites,
    teamIds?: ?$ReadOnlyArray<string>,
    staffInvites?: StaffInvites,
    fromWindow: InviteFromWindow | 'onboarding',
  },
  filters?: ParsedMembersFilters,
): Promise<invitePeopleMutationResponse> {
  const eventUpdaters =
    invitePeopleInput.staffInvites == null
      ? []
      : invitePeopleInput.staffInvites.eventIds.map(eventId =>
          addRecordToConnection({
            parentId: eventId,
            connections: [{ field: 'staffers' }, { key: 'StaffTableRenderer_staffers' }],
            edgeName: 'staffMembershipEdges',
          }),
        );
  const teamUpdaters =
    invitePeopleInput.teamIds == null
      ? []
      : invitePeopleInput.teamIds.map(teamId =>
          addRecordToConnection({
            parentId: teamId,
            connections: [{ field: 'members' }, { key: 'TeamMembersTablePagination_members' }],
            edgeName: 'teamMembershipEdges',
          }),
        );
  const orgUpdater = addRecordToConnection({
    parentId: orgId,
    connections: [{ field: 'users' }, { key: 'MembersPagination_users' }],
    edgeName: 'orgUserEdges',
  });
  const updaters = [...eventUpdaters, ...teamUpdaters, orgUpdater];
  const variables: invitePeopleMutationVariables = {
    input: {
      ...invitePeopleInput,
    },
    query: filters ? filters.search : null,
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(...updaters),
  });
}
