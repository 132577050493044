/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type BriefTemplateEventInfoFieldName = "ACTUAL_COST" | "AMOUNT_ALL_OPPORTUNITIES" | "BOOTH_NUMBER" | "DESCRIPTION" | "DIMENSIONS" | "FLOOR_PLAN" | "LAST_SYNCED" | "LEADER" | "MARKETO_ID" | "NUMBER_OF_OPPORTUNITIES" | "PLANNED_COST" | "PROGRESS" | "SALESFORCE_ID" | "STATUS" | "SYNC_STATUS";
export type BriefTemplateEventInfoSectionName = "BASICS" | "BOOTH" | "BUDGET" | "CRM";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventInfoSection_template$ref: FragmentReference;
export type EventInfoSection_template = {|
  +id: string,
  +eventInfoSections: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +sectionName: ?BriefTemplateEventInfoSectionName,
        +enabled: boolean,
        +order: number,
        +customFieldSection: ?{|
          +id: string,
          +name: string,
        |},
        +fields: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +fieldName: ?BriefTemplateEventInfoFieldName,
              +order: number,
              +enabled: boolean,
              +customField: ?{|
                +id: string,
                +label: string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: EventInfoSection_template$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enabled",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventInfoSection_template",
  "type": "BriefTemplate",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventInfoSections",
      "storageKey": null,
      "args": null,
      "concreteType": "BriefTemplateEventInfoSectionRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BriefTemplateEventInfoSectionRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BriefTemplateEventInfoSection",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "sectionName",
                  "args": null,
                  "storageKey": null
                },
                v1,
                v2,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customFieldSection",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldSectionType",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "fields",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BriefTemplateEventInfoFieldRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "BriefTemplateEventInfoFieldRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "BriefTemplateEventInfoField",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "fieldName",
                              "args": null,
                              "storageKey": null
                            },
                            v2,
                            v1,
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customField",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomFieldType",
                              "plural": false,
                              "selections": [
                                v0,
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "label",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a5d4d3034c48af12dcbdd0a0935c9106';
module.exports = node;
