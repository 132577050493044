/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SyncStatusIndicator_status$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CampaignSyncStatus_account$ref: FragmentReference;
export type CampaignSyncStatus_account = {|
  +campaignSyncStatus: {|
    +$fragmentRefs: SyncStatusIndicator_status$ref
  |},
  +$refType: CampaignSyncStatus_account$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CampaignSyncStatus_account",
  "type": "SalesforceAccount",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "campaignSyncStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "SyncStatusIndicator_status",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '745263535dbbbd080145f369fab2b584';
module.exports = node;
