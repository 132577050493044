/**
 * @flow
 * @relayHash 47df5206f525a1cfed4bdb4b5098c9de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveVendorAssignmentInput = {
  clientMutationId?: ?string,
  vendorId: string,
  eventId: string,
  fromWindow: string,
};
export type removeVendorAssignmentMutationVariables = {|
  input: RemoveVendorAssignmentInput
|};
export type removeVendorAssignmentMutationResponse = {|
  +removeVendorAssignment: ?{|
    +removedVendorId: string,
    +removedEventId: string,
  |}
|};
export type removeVendorAssignmentMutation = {|
  variables: removeVendorAssignmentMutationVariables,
  response: removeVendorAssignmentMutationResponse,
|};
*/


/*
mutation removeVendorAssignmentMutation(
  $input: RemoveVendorAssignmentInput!
) {
  removeVendorAssignment(input: $input) {
    removedVendorId
    removedEventId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveVendorAssignmentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeVendorAssignment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveVendorAssignmentInput!"
      }
    ],
    "concreteType": "RemoveVendorAssignmentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedVendorId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedEventId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeVendorAssignmentMutation",
  "id": null,
  "text": "mutation removeVendorAssignmentMutation(\n  $input: RemoveVendorAssignmentInput!\n) {\n  removeVendorAssignment(input: $input) {\n    removedVendorId\n    removedEventId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeVendorAssignmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeVendorAssignmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dff1d8796c629880ebeb21a3c5bce386';
module.exports = node;
