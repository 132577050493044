/* @flow */
import styled from 'styled-components';

const DayLabel = styled.div`
  flex: 1 auto;
  max-width: 250px;
  margin-bottom: 30px;
  padding: 7px 30px;
  background: #f2fbff;
  border: 2px solid #d6eef4;
  border-radius: 23px;
  font-size: 15px;
  font-weight: 500;
  color: #337fb2;
  text-align: center;
`;

export default DayLabel;
