/* @flow */
import * as React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

import NotAuthorized from 'components/NotAuthorized';

const Container = styled.div`
  display: flex;
  flex: 1 1 100%;
  border-top-left-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 14%);
`;

export default class AuthorizableRoute extends React.Component<{
  authorized: boolean,
  ...React.ElementProps<typeof Route>,
}> {
  render() {
    const { authorized, ...rest } = this.props;

    if (authorized === false) {
      return (
        <Container>
          <NotAuthorized />
        </Container>
      );
    }

    return <Route {...rest} />;
  }
}
