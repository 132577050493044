import PropTypes from 'prop-types';
import React from 'react';

export default class Name extends React.Component {
  static propTypes = {
    defaultEditing: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    autoFocus: true,
  };
  state = {
    editing: !!this.props.defaultEditing,
  };

  handleBlur = e => {
    const value = e.target.value;

    if (!this.props.defaultEditing) {
      this.setState({ editing: false });
    }

    if (value !== this.props.value) {
      this.props.onChange(value);
    }
  };
  handleKeyDown = e => {
    if (e.which === 13) {
      e.target.blur();
    } else if (e.which === 27 && this.props.value) {
      this.setState({ editing: false });
    }
  };
  focus() {
    if (this.input) {
      this.input.focus();
    }
  }
  render() {
    const { value, autoFocus, disabled } = this.props;
    const { editing } = this.state;

    if (editing) {
      return (
        <div className="schedule-list-row-title">
          <input
            type="text"
            ref={input => {
              this.input = input;
            }}
            defaultValue={value}
            placeholder="Add a title..."
            autoFocus={autoFocus}
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown}
          />
        </div>
      );
    }

    if (value) {
      return (
        <div className="schedule-list-row-title">
          <p title={value} onClick={!disabled ? () => this.setState({ editing: true }) : undefined}>
            {value}
          </p>
        </div>
      );
    }

    if (!disabled) {
      return (
        <div className="schedule-list-row-title">
          <div className="schedule-list-row-empty">
            <a onClick={() => this.setState({ editing: true })}>
              <i className="fa fa-fw fa-align-left" /> Title
            </a>
          </div>
        </div>
      );
    }

    return <div className="schedule-list-row-title" />;
  }
}
