/* @flow */
export default {
  set: (key: string, item: mixed) => {
    try {
      localStorage.setItem(key, JSON.stringify(item));
    } catch (e) {
      console.error(e);
    }
  },
  get: (key: string): ?mixed => {
    let result = null;
    try {
      result = JSON.parse(localStorage.getItem(key) || 'null');
    } catch (e) {
      console.error(e);
    }
    return result;
  },
  remove: (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  },
};
