/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import { type CellPropsType } from 'components/budget/Table';
import InlineEditableCell from 'components/budget/Table/InlineEditableCell';
import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import type { VendorExpenseCellPropsType } from './index';

import { type VendorExpenseNote_expense } from './__generated__/VendorExpenseNote_expense.graphql';

const VendorExpenseNote = ({
  expense,
  updateExpense,
  updateColumnWidth,
}: CellPropsType<VendorExpenseCellPropsType<VendorExpenseNote_expense>>) => {
  const isMultiline = (expense.note || '').includes('\n');

  if (!isMultiline) {
    return (
      <InlineTextField
        placeholder="Add Note"
        value={expense.note || ''}
        onChange={note => {
          updateExpense({ note });

          updateColumnWidth();
        }}
      >
        {expense.note && <InlineExpandableText>{expense.note || ''}</InlineExpandableText>}
      </InlineTextField>
    );
  }

  return (
    <InlineEditableCell>
      {({ editing }) => {
        if (editing) {
          return (
            <Redirect
              push
              to={{
                pathname: `/events/${expense.event.slug}/budget/${expense.id}`,
                hash: 'note',
              }}
            />
          );
        }

        return <InlineExpandableText>{expense.note || ''}</InlineExpandableText>;
      }}
    </InlineEditableCell>
  );
};

export default createFragmentContainer(
  VendorExpenseNote,
  graphql`
    fragment VendorExpenseNote_expense on Expense {
      id
      note
      event {
        slug
      }
    }
  `,
);
