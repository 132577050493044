/* @flow */

import RequestSubmissionEventName from './RequestSubmissionEventName';
import RequestSubmissionFormName from './RequestSubmissionFormName';
import RequestSubmissionFormReviewers from './RequestSubmissionFormReviewers';
import RequestSubmissionRequestedDate from './RequestSubmissionRequestedDate';
import RequestSubmissionStatus from './RequestSubmissionStatus';

export type RequestSubmissionCellPropsType<RequestSubmission: {}> = {|
  requestSubmission: RequestSubmission,
  tz: string,
|};

const columns = [
  {
    title: 'Form',
    sortKey: 'NAME',
    component: RequestSubmissionFormName,
    grow: 1,
  },
  {
    title: 'Request Status',
    sortKey: 'STATUS',
    component: RequestSubmissionStatus,
    width: 150,
  },
  {
    title: 'Event',
    sortKey: 'EVENT_NAME',
    component: RequestSubmissionEventName,
  },
  {
    title: 'Submitted At',
    sortKey: 'REQUESTED_DATE',
    component: RequestSubmissionRequestedDate,
    width: 150,
  },
  {
    title: 'Reviewers',
    component: RequestSubmissionFormReviewers,
    width: 140,
  },
];

export default columns;
