/* @flow */

import * as React from 'react';
import styled from 'styled-components';

import currencies from 'config/currencies.json';
import paymentMethods from 'config/paymentMethods';
import paymentStatuses, { type PaymentStatus } from 'config/paymentStatuses';
import paymentTypes from 'config/paymentTypes';

import getCurrencyRates, { type RateType } from 'utils/getCurrencyRates';
import formatCost from 'utils/number/formatCost';
import type { CircaFile } from 'utils/uploading/types';

import type { PaymentType } from 'graph/mutations/payment/createPayment';

import DateTimePicker, { type DateConfig } from 'components/date/DateTimePicker';
import SimpleAttachments from 'components/material/Attachments/SimpleAttachments';
import Button from 'components/material/Button';
import CostField from 'components/material/CostField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import TextInput from 'components/material/TextInput';
import Tooltip from 'components/material/Tooltip';

const Container = styled.div`
  position: relative;
  border-bottom: 1px solid #d8d8d8;
  color: #4a5665;
  font-size: 13px;
  &:last-child {
    border-bottom: none;
  }
`;

const StyledTextField = styled(TextField)`
  width: 208px;
`;

const StyledNotesField = styled(TextField)`
  width: 700px;
  margin: 5px 0 16px;
`;

const StyledSelectField = styled(SelectField)`
  width: ${props => props.width}px;
  padding: 0;
  &:last-child {
    margin-right: 0;
  }
`;

const DateTimePickerContainer = styled.div`
  width: 208px;
`;

const AmountContainer = styled.div`
  position: relative;
`;

const CalculatedAmount = styled.div`
  position: absolute;
  width: 100%;
  font-size: 12px;
  color: #2dd69c;

  > div {
    display: inline-block;
  }
`;

const StyledCostField = styled(CostField)`
  width: 90px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const SelectOtherRow = styled.div`
  margin: -5px -10px;

  input {
    padding: 4px 10px;
    border-bottom: 1px solid transparent;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

const CancelButton = styled(Button)`
  padding: 6px 16px 6px 16px;
`;

const SubmitButton = styled(Button)`
  margin-left: 15px;
`;

export type DerivedPaymentType = {
  ...$Exact<
    $Diff<
      PaymentType,
      { amount: number, attachments?: $ReadOnlyArray<CircaFile>, expenseId: string },
    >,
  >,
  amount: ?number,
  attachments: $ReadOnlyArray<CircaFile>,
  viewerCanDelete: boolean,
};

type Props = {
  orgCurrency: string,
  payment: DerivedPaymentType,
  tz: string,
  onPaymentClose: () => void,
  onPaymentSave: () => void,
  onAddAttachment: (attachment: CircaFile) => void,
  onRemoveAttachment: (attachmentId: string) => void,
  onStatusChange: (status: PaymentStatus) => void,
  onDueDateChange: (dueDate: ?string) => void,
  onActualDateChange: (actualDate: ?string) => void,
  onPaymentTypeChange: (paymentType: string) => void,
  onPaymentMethodChange: (paymentMethod: ?string) => void,
  onAmountChange: (amount: ?number) => void,
  onInputChange: (e: SyntheticEvent<HTMLInputElement>) => void,
};

type State = {
  amount: number,
  paymentType: string,
  validateAmount: boolean,
  actualAmount: number,
  currency: string,
  rate: number,
  rates: RateType,
  showPaymentMethodOtherInput: boolean,
};

export default class CreatePaymentForm extends React.Component<Props, State> {
  state = {
    actualAmount: this.props.payment.amount != null ? this.props.payment.amount : 0,
    amount: this.props.payment.amount != null ? this.props.payment.amount : 0,
    currency: this.props.orgCurrency,
    rate: 1,
    rates: {},
    paymentType: this.props.payment.paymentType,
    validateAmount: false,
    showPaymentMethodOtherInput: false,
  };

  mounted: boolean = false;

  componentDidMount() {
    this.mounted = true;
    getCurrencyRates().then(data => {
      if (this.mounted && data.rates) {
        this.setState({ rates: data.rates });
      }
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { rate, currency } = this.state;
    const { orgCurrency } = this.props;

    if (
      this.props.payment.amount != null &&
      this.props.payment.amount !== prevProps.payment.amount
    ) {
      const amount = this.props.payment.amount;
      const exchangedAmount = Math.round(this.props.payment.amount * rate);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        actualAmount: orgCurrency !== currency ? exchangedAmount : amount,
        amount,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleCurrencyChange = (currency: ?string) => {
    if (currency) {
      this.setState({ currency });
      this.handleCurrencyExchange(currency);
    }
  };

  handleCurrencyExchange = (currency: string) => {
    const { rates, amount } = this.state;
    const { orgCurrency } = this.props;
    if (orgCurrency !== currency && amount != null && rates != null) {
      const exchangeRate = rates[currency] / rates[orgCurrency];
      const exchangedAmount: number = Math.round(exchangeRate * amount);
      this.setState({
        actualAmount: exchangedAmount,
        rate: exchangeRate,
      });
    } else {
      this.setState({
        actualAmount: amount,
        rate: 1,
      });
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.onPaymentSave();
    }
  };

  handlePaymentMethodKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = event.currentTarget.value.trim();

      if (value) {
        this.props.onPaymentMethodChange(value);

        event.currentTarget.blur();
      }
    } else if (event.key === 'Escape') {
      event.currentTarget.blur();
    }
  };

  handlePaymentMethodBlur = () => {
    this.setState({ showPaymentMethodOtherInput: false });
  };

  handleAmountBlur = () => {
    this.props.onAmountChange(this.state.amount);
  };

  handleAmountChange = (e: SyntheticEvent<HTMLInputElement>, amount: ?number) => {
    const { rate, currency } = this.state;
    const { orgCurrency } = this.props;
    if (amount == null) {
      if (!this.state.validateAmount) {
        this.setState({
          amount: amount || 0,
          validateAmount: true,
        });
        this.props.onAmountChange(amount);
      }
    } else {
      if (orgCurrency === currency) {
        this.setState({ amount });
        this.props.onAmountChange(amount);
      } else {
        this.setState({
          amount: Math.round(amount / rate),
          actualAmount: amount,
        });
        this.props.onAmountChange(Math.round(amount / rate));
      }
      if (this.state.validateAmount) {
        this.setState({ validateAmount: false });
      }
    }
  };

  handleDueDateChange = ({ date }: DateConfig) => {
    this.props.onDueDateChange(date);
  };

  handleActualDateChange = ({ date }: DateConfig) => {
    this.props.onActualDateChange(date);
  };

  handleStatusChange = (status: ?PaymentStatus) => {
    if (status) {
      this.props.onStatusChange(status);
    }
  };

  handlePaymentTypeChange = (paymentType: ?string) => {
    if (paymentType) {
      this.props.onPaymentTypeChange(paymentType);
    }
  };

  onPaymentSave = () => {
    const invalidAmount: boolean = this.state.amount === null;
    if (invalidAmount || this.state.paymentType.trim() === '') {
      this.setState({ validateAmount: true });
    } else {
      this.props.onPaymentSave();
      this.props.onPaymentClose();
    }
  };

  render() {
    const { currency, amount, rates, showPaymentMethodOtherInput } = this.state;
    const { payment, orgCurrency } = this.props;
    const orgCurrencyObj = orgCurrency && currencies.find(item => item.code === orgCurrency);
    return (
      <Container>
        <StyledSelectField
          label="Payment Type"
          value={this.props.payment.paymentType}
          onChange={this.handlePaymentTypeChange}
          options={Object.keys(paymentTypes).map(key => ({
            value: key,
            label: paymentTypes[key],
          }))}
        />
        <Row>
          <AmountContainer>
            <StyledCostField
              label="Amount"
              defaultValue={this.state.actualAmount}
              onBlur={this.handleAmountBlur}
              onChange={this.handleAmountChange}
              onKeyDown={this.handleKeyDown}
              error={this.state.validateAmount ? 'Required' : ''}
              currency={currency}
              autoFocus
            />
            {currency !== orgCurrency && (
              <CalculatedAmount>
                <Tooltip
                  label={`${formatCost(100, orgCurrency)} = ${formatCost(
                    Math.round(this.state.rate * 100),
                    currency,
                  )}\nWorkspace Currency: ${
                    orgCurrencyObj ? orgCurrencyObj.symbol : ''
                  }${orgCurrency}`}
                  placement="bottom"
                >
                  {formatCost(amount, orgCurrency)}
                </Tooltip>
              </CalculatedAmount>
            )}
          </AmountContainer>
          <StyledSelectField
            label=" "
            width="60"
            value={currency}
            onChange={this.handleCurrencyChange}
            options={currencies.map(item => ({
              value: item.code,
              label: `${item.code} (${item.symbol})`,
            }))}
            searchable
            disabled={Object.keys(rates).length === 0}
          />
          <StyledSelectField
            label="Status"
            width="82"
            value={this.props.payment.status}
            onChange={this.handleStatusChange}
            options={Object.keys(paymentStatuses).map(key => ({
              value: key,
              label: paymentStatuses[key],
            }))}
          />
          <StyledSelectField
            label="Method"
            width="122"
            placeholder="Payment Method"
            clearable
            value={this.props.payment.paymentMethod}
            onChange={this.props.onPaymentMethodChange}
            options={[
              ...paymentMethods.map(method => ({ label: method.label, value: method.value })),
              ...(!payment.paymentMethod ||
              paymentMethods.some(method => method.value === payment.paymentMethod)
                ? []
                : [
                    {
                      label: payment.paymentMethod,
                      value: payment.paymentMethod,
                    },
                  ]),
              {
                label: 'Other',
                value: '__other',
                displayLabel: showPaymentMethodOtherInput ? (
                  <SelectOtherRow>
                    <TextInput
                      autoFocus
                      placeholder="Custom Payment Method"
                      onKeyDown={this.handlePaymentMethodKeyDown}
                      onBlur={this.handlePaymentMethodBlur}
                    />
                  </SelectOtherRow>
                ) : null,
                onSelect: () => this.setState({ showPaymentMethodOtherInput: true }),
              },
            ]}
          />
        </Row>
        <Row>
          <DateTimePickerContainer>
            <DateTimePicker
              label="Due Date"
              date={this.props.payment.dueDate}
              onChange={this.handleDueDateChange}
              hideTime
              dateOnly
              showIcon
              tz={this.props.tz}
            />
          </DateTimePickerContainer>
          <DateTimePickerContainer>
            <DateTimePicker
              label="Actual Payment Date"
              date={this.props.payment.actualDate}
              onChange={this.handleActualDateChange}
              hideTime
              dateOnly
              showIcon
              tz={this.props.tz}
            />
          </DateTimePickerContainer>
        </Row>
        <Row>
          <StyledTextField
            label="PO#"
            name="poNumber"
            defaultValue={this.props.payment.poNumber || ''}
            onBlur={this.props.onInputChange}
            onKeyDown={this.handleKeyDown}
          />
          <StyledTextField
            label="Ref. Code"
            name="reference"
            defaultValue={this.props.payment.reference || ''}
            onBlur={this.props.onInputChange}
            onKeyDown={this.handleKeyDown}
          />
        </Row>
        <Row>
          <StyledNotesField
            label="Note"
            name="note"
            multiline
            defaultValue={this.props.payment.note}
            onBlur={this.props.onInputChange}
            onKeyDown={this.handleKeyDown}
          />
        </Row>
        <Row>
          <SimpleAttachments
            attachments={payment.attachments || []}
            onAdd={this.props.onAddAttachment}
            onRemove={this.props.onRemoveAttachment}
          />
        </Row>
        <Footer>
          <CancelButton label="Cancel" minimal onClick={this.props.onPaymentClose} />
          <SubmitButton label="Save" primary onClick={this.onPaymentSave} />
        </Footer>
      </Container>
    );
  }
}
