/* @flow */
import sortBy from 'lodash/sortBy';

import { type ResourceFieldType } from '../MarketoLeadMappings';
import customFieldsTypes from './customFieldsTypes';

import { type MarketoLeadMappingsQueryResponse } from '../__generated__/MarketoLeadMappingsQuery.graphql';

const fieldTypes = field => {
  if (field.fieldName == null) {
    return customFieldsTypes[field.kind];
  }

  return {
    website: ['url'],
    phone: ['phone', 'string'],
    street: ['string', 'text'],
    city: ['string'],
    state: ['string'],
    zip: ['string'],
    country: ['string'],
    twitter: ['url'],
    linkedin: ['url'],
    description: ['text'],
  }[field.fieldName];
};

const companyFields = (
  org: $PropertyType<MarketoLeadMappingsQueryResponse, 'org'>,
): $ReadOnlyArray<ResourceFieldType> => {
  const companyCustomFields = sortBy(
    org.customFields.edges.map(edge => edge.node),
    field => field.order,
  );

  return [
    {
      label: 'Company',
      name: 'company',
      types: ['string'],
      fixedMappingField: 'company',
    },
    ...companyCustomFields.map(field => ({
      label: field.label,
      name: field.fieldName,
      customFieldId: field.id,
      types: fieldTypes(field),
    })),
  ];
};

export default companyFields;
