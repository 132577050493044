/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import type { SalesforceSyncTypes } from 'config/salesforceSyncOptions';

import { type ContactType } from 'components/Contacts/ContactWindow/ContactTypeSelector';
import SelectField from 'components/material/SelectField';
import type { syncStatus } from 'components/Salesforce/types/syncStatus';

import CompanySearch from './CompanySearch';
import ContactSearch from './ContactSearch';
import ParticipantRow from './ParticipantRow';
import UserSearch from './UserSearch';
import VendorSearch from './VendorSearch';

export const Row = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const StyledSelectField = styled(SelectField)`
  width: 111px;
  margin-right: 28px;
  flex-shrink: 0;
`;

export const List = styled.div`
  &:not(:empty) {
    margin-top: 20px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  flex: 0 0 ${props => (props.width ? `${props.width}px` : '100%')};
`;

export type Participant = {
  +id: string,
  +firstName?: ?string,
  +lastName?: ?string,
  +salesforceId?: ?string,
  +name?: ?string,
  +avatar?: ?string,
  +salesforceSyncAs?: ?SalesforceSyncTypes,
  +email?: ?string,
  +participationStatus?: 'INDEFINITE' | 'ACCEPTED' | 'DECLINED' | 'TENTATIVE',
  +syncStatus?: syncStatus,
  +profileLink?:
    | ?string
    | ?{
        pathname?: string,
        search?: string,
        hash?: string,
        state?: Object,
      },
};

type ParticipantType = 'USER' | 'CONTACT' | 'COMPANY' | 'VENDOR';

export type ParticipantLabel = 'Member' | 'Contact' | 'Company' | 'Vendor';

type ParticipantKey = 'assignees' | 'contacts' | 'companies' | 'vendors';

type ParticipantOptionValue = {
  label: ParticipantLabel,
  value: ParticipantType,
};

type ParticipantOption = {
  [ParticipantKey]: ParticipantOptionValue,
};

const participantOptions: ParticipantOption = {
  assignees: { label: 'Member', value: 'USER' },
  contacts: { label: 'Contact', value: 'CONTACT' },
  companies: { label: 'Company', value: 'COMPANY' },
  vendors: { label: 'Vendor', value: 'VENDOR' },
};

export const participantTags = {
  member: '#37B2FF',
  contact: '#3749FF',
  company: '#4FC186',
  vendor: '#C14F93',
};

export default class Participants extends React.PureComponent<
  {
    eventId?: ?string,
    assignees?: $ReadOnlyArray<Participant>,
    contacts?: $ReadOnlyArray<Participant>,
    companies?: $ReadOnlyArray<Participant>,
    vendors?: $ReadOnlyArray<Participant>,
    fromWindow?: string,
    salesforceEnabled?: boolean,
    defaultSearch: ParticipantKey,
    searchBarWidth?: number,
    onAddAssignee?: (participant: Participant) => void,
    onAddContact?: (participant: Participant) => void,
    onAddCompany?: (participant: Participant) => void,
    onAddVendor?: (participant: Participant) => void,
    onRemoveMember?: (participant: Participant) => void, // eslint-disable-line react/no-unused-prop-types
    onRemoveContact?: (participant: Participant) => void, // eslint-disable-line react/no-unused-prop-types
    onRemoveCompany?: (participant: Participant) => void, // eslint-disable-line react/no-unused-prop-types
    onRemoveVendor?: (participant: Participant) => void, // eslint-disable-line react/no-unused-prop-types
    onParticipantFieldUsed?: (used: boolean) => void,
    disabled?: boolean,
    autoFocus?: boolean,
    selectLabel?: string,
    className?: string,
  },
  { type: ParticipantType },
> {
  state = { type: this.getDefaultType() };

  getDefaultType() {
    return participantOptions[this.props.defaultSearch].value;
  }

  handleTypeChange = (type: ?ParticipantType) => {
    if (!type) return;

    this.setState({ type });
    const used = this.hasDefaultTypeChanged(type);
    if (this.props.onParticipantFieldUsed) {
      this.props.onParticipantFieldUsed(used);
    }
  };

  handleMemberSelect = (user: ?Participant) => {
    if (user == null) return;

    if (
      this.props.assignees &&
      this.props.onAddAssignee &&
      !this.props.assignees.some(u => user && u.id === user.id)
    ) {
      this.props.onAddAssignee(user);
    }
  };

  handleContactSelect = (contact: ?Participant, contactType: ContactType) => {
    if (!contact) {
      return;
    }

    if (
      contactType === 'contacts' &&
      this.props.contacts &&
      this.props.onAddContact &&
      !this.props.contacts.some(c => contact && c.id === contact.id)
    ) {
      this.props.onAddContact(contact);
    }
    if (
      contactType === 'companies' &&
      this.props.onAddCompany &&
      this.props.companies &&
      !this.props.companies.some(c => contact && c.id === contact.id)
    ) {
      this.props.onAddCompany(contact);
    }
  };

  handleVendorSelect = (vendor: ?Participant) => {
    if (
      vendor &&
      this.props.vendors &&
      this.props.onAddVendor &&
      !this.props.vendors.some(c => vendor && c.id === vendor.id)
    ) {
      this.props.onAddVendor(vendor);
    }
  };

  handleParticipantRemove = (participant: Participant, participantKey: ParticipantKey) => {
    const removeMethod = `onRemove${participantOptions[participantKey].label}`;
    if (this.props[removeMethod]) {
      this.props[removeMethod](participant);
    }
  };

  hasDefaultTypeChanged = (type: ParticipantType = this.state.type) => {
    return type !== this.getDefaultType();
  };

  handleToggleUserQueryState = (present: boolean) => {
    if (!this.hasDefaultTypeChanged() && this.props.onParticipantFieldUsed) {
      this.props.onParticipantFieldUsed(present);
    }
  };

  wrapSearchBar = () => {
    return (
      <SearchWrapper width={this.props.searchBarWidth}>{this.renderSearchBox()}</SearchWrapper>
    );
  };

  renderSearchBox = () => {
    switch (this.state.type) {
      case 'USER':
        return (
          <UserSearch
            eventId={this.props.eventId || ''}
            onSelect={this.handleMemberSelect}
            disabled={this.props.disabled}
            onToggleQueryState={this.handleToggleUserQueryState}
            autoFocus={this.props.autoFocus}
            fromWindow="custom event settings"
          />
        );
      case 'CONTACT':
        return (
          <ContactSearch
            eventId={this.props.eventId}
            onSelect={this.handleContactSelect}
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
            placeholder="Search Contacts"
            fromWindow="event contacts"
          />
        );
      case 'COMPANY':
        return (
          <CompanySearch
            eventId={this.props.eventId}
            onSelect={this.handleContactSelect}
            autoFocus={this.props.autoFocus}
            disabled={this.props.disabled}
            fromWindow={this.props.eventId ? 'event companies' : 'org companies'}
          />
        );
      case 'VENDOR':
        return (
          <VendorSearch
            eventId={this.props.eventId}
            onSelect={this.handleVendorSelect}
            autoFocus={this.props.autoFocus}
            disabled={this.props.disabled}
            fromWindow="event vendors"
          />
        );
      default:
        console.log('add new type of participant');
        return <React.Fragment />;
    }
  };

  render() {
    const { fromWindow, disabled, salesforceEnabled, selectLabel } = this.props;
    const actualParticipantOptions = Object.keys(participantOptions).filter(
      option => this.props[option],
    );
    const multipleOptions = actualParticipantOptions.length > 1;
    const options = actualParticipantOptions.map(option => ({ ...participantOptions[option] }));
    const searchBox = this.wrapSearchBar();
    return (
      <div className={this.props.className}>
        <Row>
          {multipleOptions && (
            <StyledSelectField
              options={options}
              onChange={this.handleTypeChange}
              value={this.state.type}
              label={
                selectLabel || (!fromWindow || fromWindow !== 'task' ? 'Participants' : undefined)
              }
              disabled={disabled}
            />
          )}
          {searchBox}
        </Row>
        <List>
          {actualParticipantOptions.map(
            option =>
              this.props[option] &&
              this.props[option].map(participant => (
                <ParticipantRow
                  key={participant.id}
                  participant={participant}
                  onRemove={(removedParticipant: Participant) =>
                    this.handleParticipantRemove(removedParticipant, option)
                  }
                  salesforceEnabled={salesforceEnabled}
                  disabled={disabled}
                  type={participantOptions[option].label}
                  multipleOptions={multipleOptions}
                />
              )),
          )}
        </List>
      </div>
    );
  }
}
