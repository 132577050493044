/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Radio from 'components/SharedForm/Radio';

import type { SubmissionReviewDeclineReasons_submission } from './__generated__/SubmissionReviewDeclineReasons_submission.graphql';

const Container = styled.div`
  margin: 25px 0;
`;

const Row = styled.div`
  margin: 10px 0;
`;

class SubmissionReviewDeclineReasons extends React.PureComponent<{
  submission: SubmissionReviewDeclineReasons_submission,
  declineReasonId: ?string,
  onChangeDeclineReasonId: (declineReasonId: string) => void,
}> {
  render() {
    const { submission } = this.props;
    const declineReasons = submission.form.declineReasons.edges.map(edge => edge.node);

    return (
      <Container>
        {declineReasons.map(declineReason => (
          <Row key={declineReason.id}>
            <Radio
              compact
              onChange={() => this.props.onChangeDeclineReasonId(declineReason.id)}
              label={declineReason.name}
              checked={this.props.declineReasonId === declineReason.id}
              disabled={submission.status === 'DECLINED'}
            />
          </Row>
        ))}
      </Container>
    );
  }
}

export default createFragmentContainer(
  SubmissionReviewDeclineReasons,
  graphql`
    fragment SubmissionReviewDeclineReasons_submission on EventRequestSubmission {
      status
      form {
        declineReasons {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  `,
);
