/* @flow */
import React from 'react';

import { type NumberRangeParam } from 'utils/routing/parseTypedQueryString';

import FilterItemHeader from '../FilterItemHeader';
import RangeFilterBody from './RangeFilterBody';

function parseToFilterValue(values: NumberRangeParam, isCurrency): NumberRangeParam {
  if (isCurrency) {
    return {
      min: values.min != null ? values.min * 100 : null,
      max: values.max != null ? values.max * 100 : null,
    };
  }
  return values;
}

function parseFromFilterValue(values: ?NumberRangeParam, isCurrency): NumberRangeParam {
  if (values == null) {
    return { min: null, max: null };
  }
  if (isCurrency) {
    return {
      min: values.min != null ? values.min / 100 : null,
      max: values.max != null ? values.max / 100 : null,
    };
  }
  return values;
}

type PropsType<Name> = {
  activeValue: ?NumberRangeParam,
  label?: string,
  name: Name,
  onChange: (Name, ?NumberRangeParam) => void,
  isCurrency?: boolean,
  skipHeader?: boolean,
  minPlaceholder?: string,
  maxPlaceholder?: string,
  className?: string,
  autoFocus?: boolean,
  error?: boolean,
};

export default class RangeFilterItem<Name> extends React.Component<
  PropsType<Name>,
  NumberRangeParam,
> {
  static getDerivedStateFromProps(nextProps: PropsType<Name>, prevState: NumberRangeParam) {
    // Update input fields on query param change
    if (
      (nextProps.activeValue != null &&
        (prevState.min !== nextProps.activeValue.min ||
          prevState.max !== nextProps.activeValue.max)) ||
      (nextProps.activeValue == null && (prevState.min !== 0 || prevState.max != null))
    ) {
      return nextProps.activeValue
        ? parseFromFilterValue(nextProps.activeValue, nextProps.isCurrency)
        : { min: null, max: null };
    }
    return null;
  }

  state = parseFromFilterValue(this.props.activeValue, this.props.isCurrency);

  handleMinBlur = (e: SyntheticEvent<HTMLInputElement>, value: ?number) => {
    const max =
      this.state.max != null && ((value != null && value < this.state.max) || value == null)
        ? this.state.max
        : null;
    this.props.onChange(
      this.props.name,
      parseToFilterValue({ min: value, max }, this.props.isCurrency),
    );
  };

  handleMaxBlur = (e: SyntheticEvent<HTMLInputElement>, value: ?number) => {
    const min =
      this.state.min != null && ((value != null && this.state.min < value) || value === null)
        ? this.state.min
        : null;
    this.props.onChange(
      this.props.name,
      parseToFilterValue({ min, max: value }, this.props.isCurrency),
    );
  };

  handleFilterClear = () => {
    this.props.onChange(this.props.name, null);
  };

  render() {
    if (this.props.skipHeader) {
      return (
        <RangeFilterBody
          range={this.state}
          onMinBlur={this.handleMinBlur}
          onMaxBlur={this.handleMaxBlur}
          minPlaceholder={this.props.minPlaceholder}
          maxPlaceholder={this.props.maxPlaceholder}
          className={this.props.className}
          autoFocus={this.props.autoFocus}
          error={this.props.error}
        />
      );
    }

    return (
      <FilterItemHeader
        label={this.props.label || ''}
        count={this.props.activeValue ? 1 : 0}
        onClear={this.handleFilterClear}
      >
        <RangeFilterBody
          range={this.state}
          onMinBlur={this.handleMinBlur}
          onMaxBlur={this.handleMaxBlur}
          minPlaceholder={this.props.minPlaceholder}
          maxPlaceholder={this.props.maxPlaceholder}
          className={this.props.className}
          autoFocus={this.props.autoFocus}
          error={this.props.error}
        />
      </FilterItemHeader>
    );
  }
}
