/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type SortParam } from 'utils/routing/parseTypedQueryString';

import updatePayment from 'graph/mutations/payment/updatePayment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';

import columns, { type PaymentCellPropsType, type PaymentFooterCellPropsType } from './columns';

import { type PaymentsTableRenderer_event } from './__generated__/PaymentsTableRenderer_event.graphql';
import { type PaymentsTableRenderer_org } from './__generated__/PaymentsTableRenderer_org.graphql';
import { type PaymentsTableRenderer_payments } from './__generated__/PaymentsTableRenderer_payments.graphql';

type PaymentType = $ElementType<PaymentsTableRenderer_payments, 0>;

class PaymentsTableRenderer extends React.Component<{
  payments: PaymentsTableRenderer_payments,
  event: PaymentsTableRenderer_event,
  org: PaymentsTableRenderer_org,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  onChangeDataCount: (count: number) => void,
}> {
  componentDidMount() {
    if (this.props.payments) {
      this.props.onChangeDataCount(this.props.payments.length);
    }
  }

  componentDidUpdate() {
    if (this.props.payments) {
      this.props.onChangeDataCount(this.props.payments.length);
    }
  }

  cellProps = (
    payment: PaymentType,
  ): PaymentCellPropsType<PaymentType, PaymentsTableRenderer_event, PaymentsTableRenderer_org> => ({
    payment,
    event: this.props.event,
    org: this.props.org,
    updatePayment: updates => updatePayment(payment.id, updates).catch(showModernMutationError),
  });

  footerCellProps = (): PaymentFooterCellPropsType<
    PaymentsTableRenderer_event,
    PaymentsTableRenderer_org,
  > => ({
    event: this.props.event,
    org: this.props.org,
  });

  keyExtractor = (payment: PaymentType) => payment.id;

  render() {
    const { payments } = this.props;

    if (payments && payments.length === 0) {
      return <EmptyView message="No results at this time." icon={<NoResult />} />;
    }

    return (
      <Table
        data={payments}
        columns={columns}
        sort={this.props.sort}
        onChangeSort={this.props.onChangeSort}
        stickyFooter
        cellProps={this.cellProps}
        footerCellProps={this.footerCellProps}
        keyExtractor={this.keyExtractor}
      />
    );
  }
}

export default createFragmentContainer(
  PaymentsTableRenderer,
  graphql`
    fragment PaymentsTableRenderer_payments on Payment @relay(plural: true) {
      id
      ...PaymentExpenseNameCell_payment
      ...PaymentTypeCell_payment
      ...PaymentAmountCell_payment
      ...PaymentAmountOfExpenseCell_payment
      ...PaymentStatusCell_payment
      ...PaymentDueDateCell_payment
      ...PaymentActualDateCell_payment
      ...PaymentMethodCell_payment
      ...PaymentPONumberCell_payment
      ...PaymentReferenceCell_payment
      ...PaymentNoteCell_payment
      ...PaymentActionCell_payment
    }

    fragment PaymentsTableRenderer_event on Event {
      ...PaymentExpenseNameCell_event
      ...PaymentAmountFooterCell_event
      ...PaymentDueDateCell_event
      ...PaymentActualDateCell_event
      ...PaymentNoteCell_event
      ...PaymentActionCell_event
    }

    fragment PaymentsTableRenderer_org on Org {
      ...PaymentAmountCell_org
      ...PaymentAmountFooterCell_org
    }
  `,
);
