/* @flow */

export type EventListSharedGraphValue = 'PRIVATE' | 'PUBLIC' | 'SSO';

export type SharedType = {|
  +label: string,
  +description: string,
  +value: EventListSharedGraphValue,
|};

const eventListSharedOptions: $ReadOnlyArray<SharedType> = [
  {
    label: 'Public',
    description: 'Anyone with the link can view.',
    value: 'PUBLIC',
  },
  {
    label: 'Anyone at %s',
    description:
      'Anyone at %s can view the calendar. <a href="https://help.circa.co/102309-single-sign-on-sso/how-do-i-set-up-single-sign-on-sso" target="_blank" rel="noreferrer noopener">Single sign-on</a> (SSO/SAML) required. <a href="mailto:success@circa.co" target="_blank" rel="noreferrer noopener">Contact us</a> to enable.',
    value: 'SSO',
  },
];

export function getEventListSharedOptionLabel(
  value: EventListSharedGraphValue,
  orgName: string,
): ?string {
  const type = eventListSharedOptions.find(option => option.value === value);
  return type != null ? type.label.replace(/%s/g, orgName) : null;
}

export default eventListSharedOptions;
