/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  margin: 15px;
  display: block;
  outline: none;
  font-size: 13px;
  white-space: nowrap;
  i {
    font-size: 15px;
    margin-right: 5px;
  }
  ${props =>
    props.active
      ? `
    color: ${props.theme.rowSecondaryTextColor};
    `
      : `
    color: ${props.theme.primaryActionColor};
    i {
      visibility: hidden;
    }
    &:disabled {
      color: ${props.theme.mutedTextColor};
    }
    &:not(:disabled){
      cursor: pointer;
      &:hover {
        color: ${props.theme.primaryActionDarkerColor};
      }
    }
    `};
`;

export default class TaskSidebarButton extends React.PureComponent<{
  active: boolean,
  label: string,
  onClick: () => void,
  disabled?: boolean,
}> {
  render() {
    return (
      <Button
        active={this.props.active}
        onClick={!this.props.active ? this.props.onClick : undefined}
        disabled={this.props.disabled}
      >
        <i className="fa fa-fw fa-check" /> {this.props.label}
      </Button>
    );
  }
}
