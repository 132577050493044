/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Match, type RouterHistory, Redirect, Route, Switch } from 'react-router';
import styled from 'styled-components';

import { type IntegrationSettings } from 'components/integrations/IntegrationsSettings';
import IntegrationSettingsSubmenu from 'components/integrations/IntegrationsSettings/IntegrationSettingsSubmenu';

import getSalesforceComponentsConfig from './lib/getSalesforceComponentsConfig';
import SalesforceWindow from './SalesforceWindow';

import { type SalesforceContent_org } from './__generated__/SalesforceContent_org.graphql';

const Content = styled.div`
  max-width: 710px;
`;

class SalesforceContent extends React.PureComponent<
  {
    integration: IntegrationSettings,
    org: SalesforceContent_org,
    match: Match,
    history: RouterHistory,
  },
  { showWindow: boolean },
> {
  state = { showWindow: !this.props.integration.connected };

  componentDidUpdate() {
    if (this.state.showWindow || this.props.integration.connected) return;

    this.props.history.push('/settings/integrations');
  }

  handleCloseWindow = () => {
    if (this.props.integration.connected) {
      this.setState({ showWindow: false });
    } else {
      this.props.history.push('/settings/integrations');
    }
  };

  render() {
    const {
      integration,
      org,
      org: { salesforceAccount },
      match,
    } = this.props;

    const contactsSyncEnabled = !!salesforceAccount && salesforceAccount.contactsSyncEnabled;
    const opportunitiesSyncEnabled =
      !!salesforceAccount && salesforceAccount.opportunitiesSyncEnabled;

    const filteredComponents = getSalesforceComponentsConfig(
      contactsSyncEnabled,
      opportunitiesSyncEnabled,
    );

    const tabs =
      integration.connected && !integration.expired
        ? filteredComponents
        : filteredComponents.slice(0, 1);

    if (this.state.showWindow) {
      return (
        <SalesforceWindow integration={integration} org={org} onClose={this.handleCloseWindow} />
      );
    }

    return (
      <>
        <IntegrationSettingsSubmenu
          items={tabs.map(tab => ({ label: tab.label, to: `${match.url}/${tab.path}` }))}
        />

        <Content>
          <Switch>
            {tabs.map(tab => (
              <Route
                key={tab.path}
                path={`${match.path}/${tab.path}`}
                exact
                render={props => <tab.component {...props} org={org} />}
              />
            ))}

            <Redirect from={match.path} to={`${match.path}/${tabs[0].path}`} />
          </Switch>
        </Content>
      </>
    );
  }
}

export default createFragmentContainer(
  SalesforceContent,
  graphql`
    fragment SalesforceContent_org on Org {
      ...SalesforceWindow_org
      ...SalesforceAuth_org
      ...SalesforceSettings_org
      salesforceAccount {
        contactsSyncEnabled
        opportunitiesSyncEnabled
      }
    }
  `,
);
