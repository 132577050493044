/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import TextField from '../../components/TextField';

import { type EventInfoAmountOfOpportunities_event } from './__generated__/EventInfoAmountOfOpportunities_event.graphql';
import { type EventInfoAmountOfOpportunities_org } from './__generated__/EventInfoAmountOfOpportunities_org.graphql';

const EventInfoAmountOfOpportunities = (props: {
  name: string,
  event: EventInfoAmountOfOpportunities_event,
  org: EventInfoAmountOfOpportunities_org,
}) => {
  const {
    name,
    org,
    event: { salesforceCampaign },
  } = props;
  return (
    <TextField
      name={name}
      description={formatCost(
        salesforceCampaign && salesforceCampaign.amountAllOpportunities,
        org.settings.currency,
      )}
    />
  );
};

export default createFragmentContainer(EventInfoAmountOfOpportunities, {
  event: graphql`
    fragment EventInfoAmountOfOpportunities_event on Event {
      salesforceCampaign {
        amountAllOpportunities
      }
    }
  `,
  org: graphql`
    fragment EventInfoAmountOfOpportunities_org on Org {
      settings {
        currency
      }
    }
  `,
});
