/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import { type SchedulerType } from '../index';
import EditViewWindowContent from './EditViewWindowContent';

import type { EditViewWindowQueryResponse } from './__generated__/EditViewWindowQuery.graphql';

const query = graphql`
  query EditViewWindowQuery($budgetReportingViewId: ID!) {
    budgetReportingView: node(id: $budgetReportingViewId) {
      ... on BudgetReportingView {
        name
        exportScheduler {
          frequency
          scheduledOn
          includePayments
          includeExpenses
          recipients {
            edges {
              node {
                id
                firstName
                lastName
                avatar
                email
              }
            }
          }
        }
      }
    }
  }
`;

export default class EditViewWindow extends React.PureComponent<{
  editedViewId: string,
  handleHideEditViewWindow: () => void,
  handleRemoveView: (editedViewId: string) => void,
  handleSaveView: (name: string, scheduler: ?SchedulerType, budgetReportingViewId: string) => void,
}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ budgetReportingViewId: this.props.editedViewId }}
        renderSuccess={(response: EditViewWindowQueryResponse) => {
          if (!response.budgetReportingView || !response.budgetReportingView.name) return null;
          return (
            <EditViewWindowContent
              exportScheduler={response.budgetReportingView.exportScheduler}
              name={response.budgetReportingView.name}
              editedViewId={this.props.editedViewId}
              onHide={this.props.handleHideEditViewWindow}
              onRemove={this.props.handleRemoveView}
              onUpdate={this.props.handleSaveView}
            />
          );
        }}
        renderLoading={() => null}
      />
    );
  }
}
