/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventCRMSyncedContacts_event$ref: FragmentReference;
export type EventCRMSyncedContacts_event = {|
  +id: string,
  +slug: string,
  +allContacts: {|
    +totalCount: number
  |},
  +syncedContacts: {|
    +totalCount: number
  |},
  +disabledContacts: {|
    +totalCount: number
  |},
  +failedContacts: {|
    +totalCount: number
  |},
  +pendingContacts: {|
    +totalCount: number
  |},
  +$refType: EventCRMSyncedContacts_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "EventCRMSyncedContacts_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "allContacts",
      "name": "eventContacts",
      "storageKey": null,
      "args": null,
      "concreteType": "EventContactConnection",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": "syncedContacts",
      "name": "eventContacts",
      "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"OK\"]})",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "membershipSyncStatuses": [
              "OK"
            ]
          },
          "type": "ContactFilters"
        }
      ],
      "concreteType": "EventContactConnection",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": "disabledContacts",
      "name": "eventContacts",
      "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"DISABLED\"]})",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "membershipSyncStatuses": [
              "DISABLED"
            ]
          },
          "type": "ContactFilters"
        }
      ],
      "concreteType": "EventContactConnection",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": "failedContacts",
      "name": "eventContacts",
      "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"FAILING\"]})",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "membershipSyncStatuses": [
              "FAILING"
            ]
          },
          "type": "ContactFilters"
        }
      ],
      "concreteType": "EventContactConnection",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": "pendingContacts",
      "name": "eventContacts",
      "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"PENDING\"]})",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "membershipSyncStatuses": [
              "PENDING"
            ]
          },
          "type": "ContactFilters"
        }
      ],
      "concreteType": "EventContactConnection",
      "plural": false,
      "selections": v0
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ed0ba1484ef279eca72c2624f97db8ce';
module.exports = node;
