/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import getBudgetQuarterString from 'utils/fiscal/getBudgetQuarterString';

import ExternalLinkIcon from 'images/external.svg';
import Divider from 'components/Sidebar/Divider';
import Footer from 'components/Sidebar/Footer';
import Header from 'components/Sidebar/Header';
import MenuItem from 'components/Sidebar/MenuItem';

import type { ReportingMenu_org } from './__generated__/ReportingMenu_org.graphql';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledMenuItem = styled(MenuItem)`
  svg {
    margin-left: 8px;
  }
  &:hover {
    svg {
      path:not(:first-of-type) {
        fill: #000;
      }
    }
  }
`;

const Title = styled.div`
  margin-bottom: 25px;
  margin-left: 9px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
`;

type Props = { org: ReportingMenu_org, pathPrefix: string };

const ReportingMenu = ({ org, pathPrefix }: Props) => {
  const { viewerCanImport, viewerCanUpdate } = org;
  const showFooter = viewerCanImport || viewerCanUpdate;

  return (
    <Menu>
      <Header>
        <Title>Reporting</Title>
        <MenuItem to={`${pathPrefix}/budget`}>Budget</MenuItem>
      </Header>
      {showFooter && (
        <>
          <Divider />
          <Footer>
            {viewerCanImport && (
              <MenuItem to={`${pathPrefix}/expense_imports`}>Expense imports</MenuItem>
            )}
            {viewerCanUpdate && (
              <StyledMenuItem
                to={`/settings/budgets/${getBudgetQuarterString(org.settings.fiscalYearStart)}`}
                isActive={() => false}
              >
                Budget settings
                <ExternalLinkIcon />
              </StyledMenuItem>
            )}
          </Footer>
        </>
      )}
    </Menu>
  );
};

export default createFragmentContainer(
  ReportingMenu,
  graphql`
    fragment ReportingMenu_org on Org {
      viewerCanImport
      viewerCanUpdate
      settings {
        fiscalYearStart
      }
    }
  `,
);
