import fs from 'utils/fs';

export function download(url: string, name: string) {
  const a = document.createElement('a');
  a.download = name;
  a.href = `${url}?dl=true`;
  if (!document.body) {
    return;
  }
  document.body.appendChild(a);
  a.click();
  if (!a.parentNode) {
    return;
  }
  a.parentNode.removeChild(a);
}

export function upload(file) {
  return fs.upload(file).then(storedFile => ({
    filename: storedFile.filename,
    filetype: storedFile.mimetype,
    fileurl: storedFile.url,
    filesize: storedFile.size,
  }));
}

export function getFileTypeClassName(type: ?string) {
  if (!type) return 'fa-file-o';
  if (type.split('/')[0] === 'image') return 'fa-file-image-o';
  if (type.split('/')[0] === 'audio') return 'fa-file-audio-o';
  if (type.split('/')[0] === 'video') return 'fa-file-video-o';
  if (type.split('/')[0] === 'text') return 'fa-file-text-o';
  if (type.split('/')[1] === 'pdf') return 'fa-file-pdf-o';
  if (type.split('/')[1] === 'msword') return 'fa-file-word-o';
  if (type.split('/')[1] === 'mspowerpoint') return 'fa-file-powerpoint-o';
  if (type.split('/')[1] === 'excel') return 'fa-file-excel-o';
  if (type.split('/')[0] === 'link') return 'fa-link';
  return 'fa-file-o';
}
