/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { createMoment } from 'utils/Date';

import UsersGroup from 'components/material/UsersGroup';

import type { DependencyInputOption_task } from './__generated__/DependencyInputOption_task.graphql';

const Container = styled.div`
  display: flex;
  height: 50px;
  padding: 0 10px;
  align-items: center;
  font-size: 13px;
`;

const Main = styled.div`
  width: 0;
  flex: 1 1 auto;
  padding-right: 10px;
`;

const Name = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Date = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  width: 60px;
`;

const StyledUsersGroup = styled(UsersGroup)`
  width: 60px;
`;

const Folder = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
`;

class DependencyInputOption extends React.PureComponent<{
  task: DependencyInputOption_task,
  timezone: string,
}> {
  render() {
    const date = this.props.task.dueDate
      ? createMoment(this.props.timezone)(this.props.task.dueDate)
      : null;

    return (
      <Container>
        <Main>
          <Name>{this.props.task.name}</Name>
          {this.props.task.folder && <Folder>{this.props.task.folder.name}</Folder>}
        </Main>
        <Date>{date && date.renderDateTime({ noTime: true })}</Date>
        <StyledUsersGroup
          maxCount={2}
          avatarRadius={12}
          users={this.props.task.assignees.edges.map(edge => edge.node)}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  DependencyInputOption,
  graphql`
    fragment DependencyInputOption_task on Deliverable {
      id
      name
      dueDate
      folder {
        name
      }
      assignees {
        edges {
          node {
            ...UsersGroup_users
          }
        }
      }
    }
  `,
);
