/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type CellPropsType } from 'components/budget/Table';
import InlineEditableCell from 'components/budget/Table/InlineEditableCell';
import VendorField from 'components/budget/VendorField';

import { type ExpenseCellPropsType } from './index';

import { type ExpenseVendorCell_event } from './__generated__/ExpenseVendorCell_event.graphql';
import { type ExpenseVendorCell_expense } from './__generated__/ExpenseVendorCell_expense.graphql';

const ExpenseVendorCell = ({
  expense,
  event,
  updateExpense,
  updateColumnWidth,
}: CellPropsType<ExpenseCellPropsType<ExpenseVendorCell_expense, ExpenseVendorCell_event>>) => {
  const updateVendorId = (vendorId = null) => {
    updateExpense({ vendorId }).then(() => {
      updateColumnWidth();
    });
  };

  return (
    <InlineEditableCell onClear={expense.vendor && updateVendorId}>
      {({ editing, onBlur }) => {
        if (!editing) return expense.vendor && expense.vendor.name;

        return (
          <VendorField
            eventId={event.id}
            vendorId={expense.vendor && expense.vendor.id}
            placeholder="Select Vendor"
            onBlur={onBlur}
            onSelect={updateVendorId}
            overlayContainer={document.body}
            autoFocus
          />
        );
      }}
    </InlineEditableCell>
  );
};

export default createFragmentContainer(
  ExpenseVendorCell,
  graphql`
    fragment ExpenseVendorCell_expense on Expense {
      vendor {
        id
        firstName
        lastName
        email
        name
      }
    }

    fragment ExpenseVendorCell_event on Event {
      id
    }
  `,
);
