/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestsItemDeclineReason_eventRequestSubmission$ref: FragmentReference;
export type EventRequestsItemDeclineReason_eventRequestSubmission = {|
  +declineReason: ?{|
    +name: string
  |},
  +$refType: EventRequestsItemDeclineReason_eventRequestSubmission$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestsItemDeclineReason_eventRequestSubmission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "declineReason",
      "storageKey": null,
      "args": null,
      "concreteType": "DeclineReason",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ca1a5db9c7fc453d67e1ce5d2618684c';
module.exports = node;
