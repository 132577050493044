/* @flow */
import styled from 'styled-components';

import { BaseColumn } from 'components/material/table';

const EventColumn = styled(BaseColumn)`
  width: 180px;
  margin-right: 10px;
`;

export default EventColumn;
