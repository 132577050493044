/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

type BreadcrumbItemType = {
  +label: string,
  +path?: string,
  +onClick?: (e: Event) => void,
};

const BreadcrumbLink = styled(Link)`
  color: ${props => props.theme.primaryActionColor};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

const BreadcrumbItem = styled.span`
  color: ${props => props.theme.darkestTextColor};
  font-size: 15px;
  font-weight: 500;
  ${props =>
    props.onClick &&
    css`
      font-weight: 400;
      color: ${props.theme.primaryActionColor};
      cursor: pointer;
      &:hover {
        color: ${props.theme.primaryActionDarkerColor};
      }
    `};
`;

const Separator = styled.span`
  margin: 0 10px;
  color: #dfdfdf;
`;

export default class Breadcrumb extends React.PureComponent<{
  path: $ReadOnlyArray<BreadcrumbItemType>,
  separator?: string,
  className?: string,
}> {
  render() {
    const separator = this.props.separator || <i className="fa fa-angle-right" />;
    const itemsCount = this.props.path.length;
    return (
      <div className={this.props.className}>
        {this.props.path.map((item, index) => (
          <span key={item.label}>
            {item.path ? (
              <BreadcrumbLink to={item.path}>{item.label}</BreadcrumbLink>
            ) : (
              <BreadcrumbItem onClick={item.onClick}>{item.label}</BreadcrumbItem>
            )}
            {itemsCount - 1 > index && <Separator>{separator}</Separator>}
          </span>
        ))}
      </div>
    );
  }
}
