/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type FieldInput } from 'components/FieldSettings/types';

import type {
  updateCustomFieldMutationResponse,
  updateCustomFieldMutationVariables,
} from './__generated__/updateCustomFieldMutation.graphql';

const mutation = graphql`
  mutation updateCustomFieldMutation($input: UpdateCustomFieldInput!) {
    updateCustomField(input: $input) {
      customField {
        id
        kind
        label
        required
        enabled
        showInCreateForm
        showInMobileForm
        restrictManagingOptions: mappedToSalesforce
        options {
          edges {
            node {
              id
              name
              editable
            }
          }
        }
      }
    }
  }
`;

export default function updateSalesforceMapping(
  id: string,
  input: FieldInput,
): Promise<updateCustomFieldMutationResponse> {
  const variables: updateCustomFieldMutationVariables = {
    input: { id, ...input },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateCustomField: {
        customField: {
          id,
          ...input,
        },
      },
    },
  });
}
