/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { EventCreator_event } from './__generated__/EventCreator_event.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 17px;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

const EventCreator = (props: { event: EventCreator_event }) => {
  const creator = props.event.creator;
  return creator ? (
    <Container>
      <MaterialAvatar radius={13} user={creator} />
      <TextWrapper>{fullNameOfUser(creator)}</TextWrapper>
    </Container>
  ) : null;
};

export default createFragmentContainer(
  EventCreator,
  graphql`
    fragment EventCreator_event on Event {
      creator {
        firstName
        lastName
        avatar
        ...MaterialAvatar_user
      }
    }
  `,
);
