/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import parseDateFilters from 'components/AllEvents/lib/parseDateFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import parseTaskFilters from 'views/Main/Event/Tasks/components/parseTaskFilters';

import colsConfig, { type TasksFiltersInputType } from '../columns';
import MyTasksHeaderBar from './MyTasksHeaderBar';
import MyTasksPagination from './MyTasksPagination';

import type { MyTasksPage_me } from './__generated__/MyTasksPage_me.graphql';
import type { MyTasksPage_org } from './__generated__/MyTasksPage_org.graphql';
import type { MyTasksPageQueryResponse } from './__generated__/MyTasksPageQuery.graphql';

const query = graphql`
  query MyTasksPageQuery($count: Int!, $cursor: String, $filters: DeliverableFilters!) {
    me {
      ...MyTasksPagination_me
      ...MyTasksPagination_totalCountDeliverables
    }
    org {
      ...MyTasksPagination_org
    }
  }
`;

const Container = styled.div`
  @media (${props => props.theme.mobileOnly}) {
    padding-top: 15px;
  }
`;

class MyTasksPage extends React.PureComponent<{
  org: MyTasksPage_org,
  me: MyTasksPage_me,
  history: RouterHistory,
  location: Location,
  pathPrefix: string,
}> {
  updateTableColumnWidths: ?() => void = null;

  componentDidMount() {
    const filters = parseTaskFilters(
      this.props.location.search,
      this.props.org.settings.fiscalYearStart,
    );
    const filtered = Object.keys(filters).some(key => key !== 'sort' && !!filters[key]);
    if (!filtered) {
      replaceQueryParams(this.props.history, { statuses: 'OPEN' });
    }
  }

  setColumnWidthUpdater = (updateColumnWidths: () => void) => {
    this.updateTableColumnWidths = updateColumnWidths;
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  handleContactsSearch = (search: string) => {
    replaceQueryParams(this.props.history, { query: search || null });
  };

  renderTable = (props: {
    org: ?$PropertyType<MyTasksPageQueryResponse, 'org'>,
    me: ?$PropertyType<MyTasksPageQueryResponse, 'me'>,
    sort: SortParam,
    filtered: boolean,
    filters: TasksFiltersInputType,
  }): React.Node => (
    <MyTasksPagination
      org={props.org}
      me={props.me}
      totalCountDeliverables={props.me}
      history={this.props.history}
      location={this.props.location}
      sort={props.sort}
      onChangeSort={this.handleSortChange}
      shownColumns={colsConfig}
      filters={props.filters}
      filtered={props.filtered}
      setColumnWidthUpdater={this.setColumnWidthUpdater}
    />
  );

  render() {
    const { location, pathPrefix } = this.props;
    const filters = parseTaskFilters(location.search, this.props.org.settings.fiscalYearStart);

    const sortFilter = filters.sort || {
      key: 'DUE_DATE',
      asc: true,
    };
    const dateParams = parseDateFilters(filters.eventDate);
    const variables = {
      count: 25,
      filters: {
        query: filters.query,
        sort: sortFilter.key,
        direction: sortFilter.asc ? 'ASC' : 'DESC',
        statuses: filters.statuses,
        dueDate: filters.dueDate,
        eventFilters: {
          ...dateParams,
        },
        assigneeIds: filters.assigneeIds,
        tagIds: filters.tagIds,
        teamIds: filters.teamIds,
        eventIds: filters.eventIds,
      },
    };

    const filtered = Object.keys(filters).some(key => key !== 'sort' && !!filters[key]);

    return (
      <Container>
        <MyTasksHeaderBar
          filters={filters}
          filterInputs={variables.filters}
          shownColumns={colsConfig}
          search={filters.query}
          pathPrefix={pathPrefix}
          onSearch={this.handleContactsSearch}
          org={this.props.org}
          me={this.props.me}
          history={this.props.history}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={({ org, me }: MyTasksPageQueryResponse) =>
            this.renderTable({
              org,
              me,
              sort: sortFilter,
              filtered,
              filters: variables.filters,
            })
          }
          renderLoading={() =>
            this.renderTable({
              org: null,
              me: null,
              sort: sortFilter,
              filtered,
              filters: variables.filters,
            })
          }
        />
      </Container>
    );
  }
}

export default createFragmentContainer(MyTasksPage, {
  org: graphql`
    fragment MyTasksPage_org on Org {
      ...MyTasksHeaderBar_org
      settings {
        fiscalYearStart
      }
    }
  `,
  me: graphql`
    fragment MyTasksPage_me on User {
      ...MyTasksHeaderBar_me
    }
  `,
});
