/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import US_STATES from 'config/us_states.json';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineSelectField from 'components/budget/Table/InlineSelectField';
import InlineTextField from 'components/budget/Table/InlineTextField';

import ContactState_contact from './__generated__/ContactState_contact.graphql';

const stateOptions = Object.keys(US_STATES).map(key => ({
  label: US_STATES[key],
  value: US_STATES[key],
}));

class ContactState extends React.PureComponent<{
  contact: ContactState_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  handleUpdate = (value: ?string) => {
    const { contact, onUpdate, updateColumnWidth } = this.props;
    if (!this.getError(value) && value !== contact.state) {
      onUpdate({ state: value });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    const { fieldSettings, contact } = this.props;
    if (fieldSettings.required && !contact.state && (!value || !value.trim())) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { contact, fieldSettings, updateColumnWidth } = this.props;

    if (contact.country === 'United States') {
      const tempStateOptions = stateOptions.find(option => option.value === contact.state)
        ? stateOptions
        : [...stateOptions, { label: contact.state, value: contact.state }];

      return (
        <InlineSelectField
          placeholder={fieldSettings.label}
          options={tempStateOptions}
          value={contact.state}
          onChange={this.handleUpdate}
          getError={this.getError}
          updateColumnWidth={updateColumnWidth}
          disabled={fieldSettings.restrictChangingValue}
          searchable
        />
      );
    }

    return (
      <InlineTextField
        key={contact.state} // this is needed for clearing state when country changes
        placeholder={fieldSettings.label}
        value={contact.state || ''}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        onChange={this.handleUpdate}
        disabled={fieldSettings.restrictChangingValue}
      />
    );
  }
}

export default createFragmentContainer(
  ContactState,
  graphql`
    fragment ContactState_contact on Contact {
      country
      state
    }
  `,
);
