/* @flow */

import * as React from 'react';
import styled from 'styled-components';

import type { CircaFile } from 'utils/uploading/types';

import { Remove as RemoveIcon } from 'images';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 auto;
  margin: 0 10px 10px 0;
  padding: 2px 10px;
  border: 1px solid #c3e0ec;
  border-radius: 13.5px;
  background-color: #ffffff;
  &:hover {
    border-color: #80b6cd;
  }
`;

const CancelAction = styled.div`
  width: 13px;
  height: 13px;
  margin-left: 10px;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Link = styled.a`
  flex: 1 1 auto;
  color: ${props => props.theme.primaryActionColor};
  text-decoration: underline;
`;

const LinkAttachment = (props: {
  attachment: CircaFile,
  onRemove?: (attachmentId: string) => void,
}) => {
  const {
    onRemove,
    attachment: { id, fileurl, filename },
  } = props;

  return (
    <Container>
      <Link href={fileurl} rel="noopener noreferrer" target="_blank">
        {filename || fileurl}
      </Link>
      {onRemove && (
        <CancelAction onClick={() => id != null && onRemove(id)}>
          <RemoveIcon />
        </CancelAction>
      )}
    </Container>
  );
};

export default LinkAttachment;
