/* @flow */

import * as React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';

import { upload } from 'utils/Attachments';
import { convertNativeFile } from 'utils/uploading/convertFileStackFile';
import type { CircaFile } from 'utils/uploading/types';

import addAttachmentToPayment from 'graph/mutations/payment/addAttachmentToPayment';
import removeAttachmentFromPayment from 'graph/mutations/payment/removeAttachmentFromPayment';
import removePayment from 'graph/mutations/payment/removePayment';
import updatePayment from 'graph/mutations/payment/updatePayment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';

import PaymentForm, { type DerivedPaymentType } from './PaymentForm';

import Payment_org from './__generated__/Payment_org.graphql';
import Payment_payment from './__generated__/Payment_payment.graphql';

class Payment extends React.Component<
  {
    expenseId: string,
    org: Payment_org,
    payment: Payment_payment,
    index: number,
    tz: string,
  },
  {
    amount: ?number,
    showConfirmation: boolean,
    attachments: $ReadOnlyArray<CircaFile>,
  },
> {
  state = {
    amount: this.props.payment.amount !== null ? this.props.payment.amount : null,
    showConfirmation: false,
    attachments: [],
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.payment.amount !== null &&
      this.props.payment.amount !== prevProps.payment.amount
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        amount: this.props.payment.amount,
      });
    }
  }

  handlePaymentTypeSave = (paymentType: string) => {
    updatePayment(this.props.payment.id, { paymentType }).catch(showModernMutationError);
  };

  handlePaymentMethodSave = (paymentMethod: ?string) => {
    updatePayment(this.props.payment.id, { paymentMethod }).catch(showModernMutationError);
  };

  handleAmountSave = (amount: ?number) => {
    if (amount != null) {
      updatePayment(this.props.payment.id, { amount }).catch(showModernMutationError);
    }
    this.setState({ amount });
  };

  handleStatusSave = (status: string) => {
    updatePayment(this.props.payment.id, { status }).catch(showModernMutationError);
  };

  handleDueDateSave = (dueDate: ?string) => {
    updatePayment(this.props.payment.id, { dueDate }).catch(showModernMutationError);
  };

  handleActualDateSave = (actualDate: ?string) => {
    updatePayment(this.props.payment.id, { actualDate }).catch(showModernMutationError);
  };

  handleInputSave = (e: SyntheticEvent<HTMLInputElement>) => {
    updatePayment(this.props.payment.id, { [e.currentTarget.name]: e.currentTarget.value }).catch(
      showModernMutationError,
    );
  };

  onAddAttachment = (attachment: CircaFile) => {
    addAttachmentToPayment(this.props.payment.id, attachment).catch(showModernMutationError);
  };

  onRemoveAttachment = (attachmentId: string) => {
    if (attachmentId != null) {
      removeAttachmentFromPayment(attachmentId, this.props.payment.id);
    }
  };

  handleRemovePayment = () => {
    removePayment({ paymentId: this.props.payment.id, expenseId: this.props.expenseId });
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  handleFilesDrop = (files: Array<File>) => {
    files.forEach((nativeFile: File) => {
      const temporaryId = String(Math.random());
      const convertedFile = convertNativeFile(nativeFile);

      this.setState(prevState => ({
        attachments: [...prevState.attachments, { id: temporaryId, ...convertedFile }],
      }));
      upload(nativeFile)
        .then((file: CircaFile) => addAttachmentToPayment(this.props.payment.id, file))
        .then(() => {
          this.setState(state => ({
            attachments: state.attachments.filter(attachment => attachment.id !== temporaryId),
          }));
        });
    });
  };

  render() {
    const { payment, index, tz } = this.props;

    const { amount, attachments, ...paymentDetails } = this.props.payment;
    const propsAttachments = attachments.edges.map(({ node }) => node);
    const currentPayment: DerivedPaymentType = {
      ...paymentDetails,
      amount: this.state.amount,
      attachments: [...this.state.attachments, ...propsAttachments],
    };

    return (
      <React.Fragment>
        <PaymentForm
          expenseActualAmount={payment.expense.actualAmount}
          orgCurrency={this.props.org.settings.currency}
          payment={currentPayment}
          index={index}
          tz={tz}
          onFilesDrop={this.handleFilesDrop}
          onInputChange={this.handleInputSave}
          onAmountChange={this.handleAmountSave}
          onPaymentTypeChange={this.handlePaymentTypeSave}
          onPaymentMethodChange={this.handlePaymentMethodSave}
          onStatusChange={this.handleStatusSave}
          onDueDateChange={this.handleDueDateSave}
          onActualDateChange={this.handleActualDateSave}
          onRemovePayment={this.handleShowConfirmation}
          onAddAttachment={this.onAddAttachment}
          onRemoveAttachment={this.onRemoveAttachment}
        />

        {this.state.showConfirmation &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              message="Deleting this Payment is irreversible."
              onConfirm={this.handleRemovePayment}
              onHide={this.handleHideConfirmation}
            />,
            document.body,
          )}
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(Payment, {
  payment: graphql`
    fragment Payment_payment on Payment {
      id
      actualDate
      dueDate
      amount
      paymentMethod
      paymentType
      poNumber
      reference
      status
      note
      viewerCanDelete
      attachments {
        edges {
          node {
            id
            fileurl
            filetype
            filename
            ...AttachmentPreview_attachments
          }
        }
      }
      expense {
        actualAmount
      }
    }
  `,
  org: graphql`
    fragment Payment_org on Org {
      id
      settings {
        id
        currency
      }
    }
  `,
});
