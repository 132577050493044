/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation addContactsToCompanyMutation($input: AddContactsToCompanyInput!) {
    addContactsToCompany(input: $input) {
      company {
        ...CompanyPage_company
      }
      contactEdges {
        node {
          ...CompanyContactsList_contacts
        }
      }
    }
  }
`;

export default function addContactsToCompany(
  orgId: string,
  companyId: string,
  contactIds: $ReadOnlyArray<string>,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        companyId,
        contactIds,
      },
    },
    updater: addRecordToConnection({
      parentId: companyId,
      edgeName: 'contactEdges',
      connections: [{ key: 'CompanyContactsList_contacts' }],
    }),
  });
}
