/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';
import BriefViewSharedContext from '../BriefViewSharedContext';
import { BriefUserAvatar, BriefUserName } from './BriefUser';

import NotesUpdater_user from './__generated__/NotesUpdater_user.graphql';

const Container = styled.div`
  display: flex;
  flex: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px 45px 18px 0;
`;

const UpdateText = styled.div`
  display: inline-block;
  margin-right: 10px;
  color: #828b93;
  font-size: 13px;
`;

const UpdateDate = styled.div`
  display: inline-block;
  margin-right: 40px;
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;
`;

const UserNameDeleted = styled.div`
  flex: 0 auto;
  font-size: 13px;
  color: #828b93;
  font-weight: 500;
`;

const NotesUpdater = (props: { user: NotesUpdater_user, updatedAt: Date, tz: string }) => {
  const { user, updatedAt, tz } = props;
  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <Container isShared={sharedView}>
          <UpdateText>UPDATED AT</UpdateText>
          <UpdateDate>{formatDateTime(moment(updatedAt).tz(tz))}</UpdateDate>
          {user ? (
            <AvatarWrapper>
              <BriefUserAvatar user={user} radius={19} />
              <BriefUserName firstName={user.firstName} lastName={user.lastName} />
            </AvatarWrapper>
          ) : (
            <UserNameDeleted>by Deleted user</UserNameDeleted>
          )}
        </Container>
      )}
    </BriefViewSharedContext.Consumer>
  );
};

export default createFragmentContainer(
  NotesUpdater,
  graphql`
    fragment NotesUpdater_user on Assignable {
      firstName
      lastName
      ...BriefUserAvatar_user
    }
  `,
);
