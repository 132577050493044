/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AllEventsHeaderBar_eventList$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsCalendar_eventList$ref: FragmentReference;
export type AllEventsCalendar_eventList = {|
  +$fragmentRefs: AllEventsHeaderBar_eventList$ref,
  +$refType: AllEventsCalendar_eventList$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "AllEventsCalendar_eventList",
  "type": "EventList",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AllEventsHeaderBar_eventList",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'cc772b16b1c23c5453053452fca9d295';
module.exports = node;
