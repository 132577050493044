/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AllEventsHeaderBar_eventList$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsMap_eventList$ref: FragmentReference;
export type AllEventsMap_eventList = {|
  +$fragmentRefs: AllEventsHeaderBar_eventList$ref,
  +$refType: AllEventsMap_eventList$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "AllEventsMap_eventList",
  "type": "EventList",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AllEventsHeaderBar_eventList",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '184f846cc0c33879bd05f5811897ff69';
module.exports = node;
