/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import sortCustomFieldOptions from 'utils/customization/sortCustomFieldOptions';

import Arrow from 'images/arrow.svg';
import ConfirmationWindow from 'components/ConfirmationWindow';
import CheckBox from 'components/EventRequestForm/form/CheckBox';
import LockIcon from 'components/LockIcon';
import Switch from 'components/material/Switch';

import CustomFieldForm from './CustomFieldForm';
import {
  // CreateFormColumn,
  // MobileFormColumn,
  ActionsColumn,
  FieldNameColumn,
  HeaderBar,
  RequiredColumn,
} from './fieldFragments';
import type { CustomizableType, Field, FieldInput, SectionCustomizableType } from './types';

const StyledArrow = styled(Arrow)`
  transition: 0.3s;
  margin: -2px 12px 0 2px;
  color: #868f96;
`;

const RemoveIcon = styled.i`
  visibility: hidden;
  margin-left: 22px;
  font-size: 16px;
  color: #868f96;
  cursor: pointer;
`;

const StyledHeaderBar = styled(HeaderBar)`
  height: 40px;
  margin-top: 10px;
  background-color: #f4fbff;

  ${props =>
    !props.defaultField &&
    css`
      &:hover {
        background-color: #fff8e5;
        ${RemoveIcon} {
          visibility: visible;
        }
      }
    `};

  ${props =>
    (props.disabled || props.defaultField) &&
    css`
      background-color: #f8f9f9;
    `};

  ${props =>
    props.expanded &&
    css`
      ${StyledArrow} {
        transform: rotate(90deg);
      }
    `};
`;

const StyledFieldNameColumn = styled(FieldNameColumn)`
  user-select: none;
`;

const LeftShift = styled.span`
  display: inline-block;
  width: 20px;
`;

const RightShift = styled.div`
  width: 43px;
`;

const StyledLockIcon = styled(LockIcon)`
  position: relative;
  left: 30px;
  margin: 0;
`;

export default class CustomizableField extends React.Component<
  {
    sectionCustomizableType?: SectionCustomizableType,
    customizableType: CustomizableType,
    field: Field,
    onUpdate: (id: string, props: FieldInput) => Promise<any>,
    onRemove: (id: string) => void,
  },
  {
    removing: boolean,
    expanded: boolean,
  },
> {
  state = {
    expanded: false,
    removing: false,
  };

  handleExpandToggle = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }));
  };

  handleRequiredToggle = () => {
    const { id, required } = this.props.field;
    const newValue = !required;
    this.props.onUpdate(id, {
      required: newValue,
      ...(newValue ? { showInCreateForm: true, showInMobileForm: true } : {}),
    });
  };

  // handleCreateFormToggle = () => {
  //   const { id, showInCreateForm, required } = this.props.field;
  //   const newValue = !showInCreateForm;
  //   this.props.onUpdate(id, {
  //     showInCreateForm: newValue,
  //     ...(!newValue && required ? { required: false } : {}),
  //   });
  // };

  // handleMobileFormToggle = () => {
  //   const { id, showInMobileForm, required } = this.props.field;
  //   const newValue = !showInMobileForm;
  //   this.props.onUpdate(id, {
  //     showInMobileForm: newValue,
  //     ...(!newValue && required ? { required: false } : {}),
  //   });
  // };

  handleEnabledToggle = () => {
    const { id, enabled } = this.props.field;
    this.props.onUpdate(id, { enabled: !enabled });
    if (enabled && this.state.expanded) {
      this.handleExpandToggle();
    }
  };

  handleUpdate = (props: FieldInput): Promise<void> => {
    return this.props.onUpdate(this.props.field.id, props);
  };

  handleRemoveClick = () => {
    this.setState({ removing: true });
  };

  handleHideRemoveModal = () => {
    this.setState({ removing: false });
  };

  handleRemoveConfirm = () => {
    this.props.onRemove(this.props.field.id);
  };

  render() {
    const field = this.props.field;
    const { expanded, removing } = this.state;
    return (
      <>
        <StyledHeaderBar
          disabled={!field.enabled}
          defaultField={field.isDefault}
          expanded={expanded}
        >
          <StyledFieldNameColumn
            onClick={field.isDefault ? undefined : this.handleExpandToggle}
            title={field.label}
          >
            {field.isDefault ? <LeftShift /> : <StyledArrow />}
            {field.label}
          </StyledFieldNameColumn>
          {!['EVENT', 'SALESFORCEOPPORTUNITY'].includes(
            this.props.sectionCustomizableType || this.props.customizableType,
          ) && (
            <>
              <RequiredColumn restrictManagingOptions={field.restrictManagingOptions}>
                {field.enabled && (
                  <CheckBox
                    checked={field.required}
                    disabled={field.isDefault}
                    onChange={this.handleRequiredToggle}
                    compact
                  />
                )}
              </RequiredColumn>
              {/* <CreateFormColumn>
                {field.enabled && (
                  <CheckBox
                    checked={field.showInCreateForm}
                    disabled={field.isDefault}
                    onChange={this.handleCreateFormToggle}
                    compact
                  />
                )}
              </CreateFormColumn> */}
              {/* <MobileFormColumn>
                {field.enabled && (
                  <CheckBox
                    checked={field.showInMobileForm}
                    disabled={field.isDefault}
                    onChange={this.handleMobileFormToggle}
                    compact
                  />
                )}
              </MobileFormColumn> */}
            </>
          )}
          {field.restrictManagingOptions && <StyledLockIcon label="Salesforce" />}
          <ActionsColumn>
            {!field.isDefault && (
              <>
                {field.canDisable !== false && (
                  <Switch
                    enabled={field.enabled}
                    onChange={this.handleEnabledToggle}
                    size="small"
                  />
                )}
                {field.isSuggested ? (
                  <RightShift />
                ) : (
                  <RemoveIcon className="fa fa-fw fa-trash" onClick={this.handleRemoveClick} />
                )}
                {removing && (
                  <ConfirmationWindow
                    onHide={this.handleHideRemoveModal}
                    onConfirm={this.handleRemoveConfirm}
                    title="Are you sure?"
                    message="Are you sure you want to remove the selected Field? Once you delete it, this action can't be undone."
                  />
                )}
              </>
            )}
          </ActionsColumn>
        </StyledHeaderBar>
        {expanded && (
          <CustomFieldForm
            field={{ ...field, options: sortCustomFieldOptions(field.options) }}
            onSave={this.handleUpdate}
            customizableType={this.props.customizableType}
            sectionCustomizableType={this.props.sectionCustomizableType}
          />
        )}
      </>
    );
  }
}
