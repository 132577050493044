/**
 * @flow
 * @relayHash 4fbe58d35af0ebb798fc3fb5923b46cf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type IntegrationSettingsPage_integrable$ref = any;
export type IntegrationsIntegrableQueryVariables = {||};
export type IntegrationsIntegrableQueryResponse = {|
  +org: {|
    +$fragmentRefs: IntegrationSettingsPage_integrable$ref
  |}
|};
export type IntegrationsIntegrableQuery = {|
  variables: IntegrationsIntegrableQueryVariables,
  response: IntegrationsIntegrableQueryResponse,
|};
*/


/*
query IntegrationsIntegrableQuery {
  org {
    ...IntegrationSettingsPage_integrable
    id
  }
}

fragment IntegrationSettingsPage_integrable on IntegrableInterface {
  ...IntegrationSettingsAuth_integrable
}

fragment IntegrationSettingsAuth_integrable on IntegrableInterface {
  id
  traySolutionInstances(includeExpired: true) {
    edges {
      node {
        id
        solutionName
        authAccountId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "IntegrationsIntegrableQuery",
  "id": null,
  "text": "query IntegrationsIntegrableQuery {\n  org {\n    ...IntegrationSettingsPage_integrable\n    id\n  }\n}\n\nfragment IntegrationSettingsPage_integrable on IntegrableInterface {\n  ...IntegrationSettingsAuth_integrable\n}\n\nfragment IntegrationSettingsAuth_integrable on IntegrableInterface {\n  id\n  traySolutionInstances(includeExpired: true) {\n    edges {\n      node {\n        id\n        solutionName\n        authAccountId\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "IntegrationsIntegrableQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "IntegrationSettingsPage_integrable",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "IntegrationsIntegrableQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "traySolutionInstances",
            "storageKey": "traySolutionInstances(includeExpired:true)",
            "args": [
              {
                "kind": "Literal",
                "name": "includeExpired",
                "value": true,
                "type": "Boolean"
              }
            ],
            "concreteType": "TraySolutionInstanceRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TraySolutionInstanceRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TraySolutionInstance",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "solutionName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "authAccountId",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e13f7fb2d435f6048ec92ef3584e170a';
module.exports = node;
