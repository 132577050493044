/* @flow */
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 35px;
  width: 100%;
  padding: 8px 10px;
  font-size: 13px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
`;

const StyledText = styled.div`
  color: #3e4859;
`;

export default class AllEventsSearchSuggestion extends React.Component<{}> {
  render() {
    return (
      <Container>
        <StyledText>No Events found</StyledText>
      </Container>
    );
  }
}
