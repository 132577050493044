/* @flow */

import * as React from 'react';
import styled, { css } from 'styled-components';

import determineImage from '../utils/determineImage';

import ResizeToolbar from './ResizeToolbar';

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => props.width}px;
  outline: none;
`;

export const ImagePreview = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: none;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.69);
  border-radius: 2px;
  cursor: pointer;
  i {
    flex: 1 1 auto;
    color: #96a2ab;
  }
`;

export const ImageContainer = styled.div`
  ${props =>
    props.imageLoaded &&
    css`
      :hover {
        ${ImagePreview} {
          display: flex;
          align-items: center;
        }
      }
    `};
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(83, 83, 83, 0.3);
  cursor: pointer;
`;

const StyledImage = styled.img`
  display: block;
  width: 100%;
  padding: 0px;
  cursor: pointer;
  ${props =>
    props.focused &&
    css`
      border: 2px solid ${props.theme.primaryActionColor};
    `};
`;

export const PREVIEW_ICON = 'previewIcon';
export const INLINE_IMAGE_CONTAINER = 'imageContainer';

type Data = {
  width: number,
  fileName: string,
  url: string,
};

type BlockProps = {
  viewMode: boolean,
  disabled: boolean,
  imageData: Data,
  focused: boolean,
  handleImageClick: () => void,
  onImagePreview: (url: string) => void,
  onResize: (width: number) => void,
  onImageClick: () => void,
  onImageBlured: () => void,
};

export default class Image extends React.Component<
  {
    blockProps: BlockProps,
  },
  {
    loaded: boolean,
  },
> {
  state = { loaded: false };

  toggleImagePreview = () => {
    this.props.blockProps.onImagePreview(this.props.blockProps.imageData.url);
  };

  handleImageClick = () => {
    this.props.blockProps.onImageClick();
  };

  imageLoaded = () => {
    this.setState({ loaded: true });
  };

  handleBlur = (e: SyntheticFocusEvent<HTMLElement>) => {
    const clickedElement = e.relatedTarget;
    if (!determineImage(clickedElement)) {
      this.props.blockProps.onImageBlured();
    }
  };

  renderImage = () => {
    const {
      blockProps: {
        focused,
        imageData: { url, fileName },
        viewMode,
        disabled,
      },
    } = this.props;
    const imageComponent = (
      <StyledImage src={url} alt={fileName} focused={focused} onLoad={this.imageLoaded} />
    );
    const showOverlay: boolean = focused && this.state.loaded;

    if (disabled || !viewMode) {
      return (
        <ImageWrapper onClick={disabled ? this.toggleImagePreview : this.handleImageClick}>
          {imageComponent}
          {showOverlay && <Overlay />}
        </ImageWrapper>
      );
    }
    return imageComponent;
  };

  render() {
    const {
      blockProps: {
        focused,
        imageData: { width },
      },
    } = this.props;
    const showOverlay: boolean = focused && this.state.loaded;
    return (
      <Container id={INLINE_IMAGE_CONTAINER} width={width} tabIndex={0} onBlur={this.handleBlur}>
        <ImageContainer imageLoaded={this.state.loaded}>
          {this.renderImage()}
          <ImagePreview onClick={this.toggleImagePreview}>
            <i id={PREVIEW_ICON} className="fa fa-fw fa-search" />
          </ImagePreview>
        </ImageContainer>
        {showOverlay && (
          <ResizeToolbar onSetWidth={this.props.blockProps.onResize} initialWidth={width} />
        )}
      </Container>
    );
  }
}
