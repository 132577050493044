/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { CreateComponentPropertiesType } from './createEventDatesComponent';

import type {
  createDividerComponentFieldsMutationResponse,
  createDividerComponentFieldsMutationVariables,
} from './__generated__/createDividerComponentFieldsMutation.graphql';

const mutation = graphql`
  mutation createDividerComponentFieldsMutation($input: CreateDividerComponentInput!) {
    createRegistrationFormDividerComponent(input: $input) {
      pageComponentEdge {
        __typename
        node {
          id
          order
          kind
          ...DividerComponent_componentProps
        }
      }
      updatedPageComponents {
        id
        order
        parent {
          id
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function createDividerComponent(
  properties: CreateComponentPropertiesType,
): Promise<string> {
  const { formId, newComponentOrder, pageComponentsOrder, parentProps } = properties;
  const variables: createDividerComponentFieldsMutationVariables = {
    input: {
      formId,
      newComponentOrder,
      pageComponentsOrder,
      parentId: parentProps ? parentProps.parentId : undefined,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      createRegistrationFormDividerComponent: {
        updatedPageComponents: pageComponentsOrder.map(pageComponent => ({
          id: pageComponent.id,
          order: pageComponent.order,
          parent: undefined,
        })),
        pageComponentEdge: {
          node: {
            id: 'new',
            order: newComponentOrder,
            kind: 'DIVIDER',
            dividerComponent: {
              height: 4,
              padding: '20 0 20 0',
            },
          },
        },
      },
    },
    updater: addRecordToConnection({
      parentId: formId,
      edgeName: 'pageComponentEdge',
      connections: [{ field: 'pageComponents' }],
    }),
  }).then((response: createDividerComponentFieldsMutationResponse) => {
    if (
      response.createRegistrationFormDividerComponent &&
      response.createRegistrationFormDividerComponent.pageComponentEdge.node
    ) {
      return response.createRegistrationFormDividerComponent.pageComponentEdge.node.id;
    }
    throw new Error('No item id');
  });
}
