/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';
import Overlay from 'components/material/Overlay';
import SearchableList from 'components/material/SearchableList';

import type { UserSelectionOverlay_users } from './__generated__/UserSelectionOverlay_users.graphql';

const UserRow = styled.div`
  padding: 7px;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.rowPrimaryTextColor};
  align-items: center;
  cursor: pointer;
  ${props => props.focused && `background: ${props.theme.hoverRowColor};`};
`;

const UserName = styled.div`
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  flex: 1 1 auto;
  font-size: 13px;
`;

const InviteOption = styled(UserRow)`
  color: ${props => props.theme.primaryActionColor};
  border-bottom: 0;
  border-top: 1px solid #e6e6e6;
  i {
    margin-right: 5px;
  }
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

const ActiveIcon = styled.i`
  margin-left: 5px;
  font-size: 16px;
  color: ${props => props.theme.primaryActionColor};
`;

class UserSelectionOverlay extends React.PureComponent<{
  show: boolean,
  target: any,
  container: any,
  onHide: () => void,
  forceRightEdge?: boolean,
  className?: string,
  users: UserSelectionOverlay_users,
  selectedUserIds: $ReadOnlyArray<string>,
  // Hide selected users instead of showing them with a check mark
  hideSelected?: boolean,
  onSelect: (userId: string, isCurrentlySelected: boolean) => void,
  onInviteClick?: () => void,
}> {
  static defaultProps = {
    selectedUserIds: [],
  };

  handleRenderItem = (user: $ElementType<UserSelectionOverlay_users, 0>, focused) => {
    const isActive = this.props.selectedUserIds.includes(user.id);
    return (
      <UserRow key={user.id} focused={focused}>
        <MaterialAvatar user={user} radius={13} />
        <UserName>{fullNameOfUser(user)}</UserName>
        {isActive && <ActiveIcon className="fa fa-fw fa-check" />}
      </UserRow>
    );
  };

  handleSelect = (user: $ElementType<UserSelectionOverlay_users, 0>) => {
    const isActive = this.props.selectedUserIds.includes(user.id);
    this.props.onSelect(user.id, isActive);
  };

  handleInviteClick = () => {
    this.props.onHide();
    if (this.props.onInviteClick) this.props.onInviteClick();
  };

  render() {
    const users = this.props.hideSelected
      ? this.props.users.filter(user => !this.props.selectedUserIds.includes(user.id))
      : this.props.users;

    return (
      <Overlay
        show={this.props.show}
        target={this.props.target}
        onHide={this.props.onHide}
        container={this.props.container}
        className={this.props.className}
        forceRightEdge={this.props.forceRightEdge}
      >
        <SearchableList
          options={users}
          queryAttrs={['firstName', 'lastName', 'email']}
          renderItem={this.handleRenderItem}
          onSelect={this.handleSelect}
        />
        {this.props.onInviteClick && (
          <InviteOption onClick={this.handleInviteClick}>
            <i className="fa fa-fw fa-plus" /> Add Event Staff
          </InviteOption>
        )}
      </Overlay>
    );
  }
}

export default createFragmentContainer(
  UserSelectionOverlay,
  graphql`
    fragment UserSelectionOverlay_users on User @relay(plural: true) {
      id
      firstName
      lastName
      email
      ...MaterialAvatar_user
    }
  `,
);
