/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import TextInput from 'components/material/TextInput';
import Overlay from 'components/material/Overlay';

import inputBoxStyled from '../../inputBoxStyled';

const Container = styled.div`
  position: relative;
  ${props =>
    (props.hasIcon || props.clearable) &&
    css`
      input {
        padding-right: ${props.hasIcon && props.clearable ? '35px' : '20px'};
      }
    `};
`;

const Option = styled.div`
  cursor: pointer;
  ${props =>
    props.focused &&
    css`
      background: ${props.theme.hoverRowColor};
    `};
`;

const StyledOverlay = styled(Overlay)`
  min-width: 100%;
  width: auto;
  margin-top: -4px;
`;

const Empty = styled.div`
  font-size: 13px;
  font-style: italic;
  margin: 10px;
  color: ${props => props.theme.rowSecondaryTextColor};
`;

const ClearIcon = styled.i`
  position: absolute;
  right: ${props => (props.hasIcon ? '18px' : '0')};
  top: 18px;
  cursor: pointer;
  font-size: 12px;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.negativeActionColor};
  }
`;

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

export default class AutocompleteInput<OptionType> extends React.PureComponent<
  {
    defaultQuery?: string,
    options?: $ReadOnlyArray<OptionType>,
    onFilterOptions: (query: string) => $ReadOnlyArray<OptionType> | Promise<any> | void,
    renderOption: (option: OptionType) => React.Node,
    renderOptionString?: OptionType => string,
    onSelect: (option: ?OptionType) => void,
    onBlur?: (SyntheticEvent<HTMLElement>) => void,
    onKeyDown?: (SyntheticEvent<HTMLInputElement>) => void,
    autoFocus?: boolean,
    placeholder?: string,
    emptyText?: string,
    footerContent?: ?React.Node,
    className?: string,
    icon?: string,
    label?: string,
    showOptionsOnEmpty?: boolean,
    clearable?: boolean,
    readOnly?: boolean,
    required?: boolean,
    resetOnBlur?: boolean,
    disabled?: boolean,
  },
  { query: string, focus: number, overlayShown: boolean },
> {
  state = {
    query: this.props.defaultQuery || '',
    focus: 0,
    overlayShown: false,
  };

  newQuery: ?string;

  focusedIndex(length: number): number {
    const index = this.state.focus % length;
    if (this.state.focus >= 0 || index === 0) {
      return index;
    }
    return length + index;
  }

  options(): $ReadOnlyArray<OptionType> {
    if (this.props.options) return this.props.options;
    const filterResponse = this.props.onFilterOptions(this.state.query);

    return Array.isArray(filterResponse) ? filterResponse : [];
  }

  handleQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ query: e.currentTarget.value }, () => {
      this.props.onFilterOptions(this.state.query);
    });
  };

  handleInputKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.setState({ focus: this.state.focus + 1 });
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.setState({ focus: this.state.focus - 1 });
    } else if (e.key === 'Enter') {
      e.preventDefault();
      this.handleOptionSelect();
    }
    if (this.props.onKeyDown) this.props.onKeyDown(e);
  };

  handleOptionHover = (index: number) => {
    this.setState({ focus: index });
  };

  handleOptionSelect = () => {
    const options = this.options();
    const focusedIndex = this.focusedIndex(options.length);

    const option = options[focusedIndex];

    if (option != null) {
      this.props.onSelect(option);
      const newQuery = this.props.renderOptionString ? this.props.renderOptionString(option) : '';
      this.newQuery = newQuery;
      this.setState({ focus: 0, query: newQuery });
    }
  };

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });
  };

  handleOverlayHide = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ overlayShown: false });

    if (this.props.resetOnBlur && !this.newQuery && this.props.defaultQuery) {
      this.setState({ query: this.props.defaultQuery });
    }
    this.newQuery = null;

    if (this.props.onBlur) this.props.onBlur(e);
  };

  handleClear = (e: MouseEvent) => {
    e.stopPropagation();
    this.setState({ query: '' });
    if (this.props.onSelect) {
      this.props.onSelect(null);
    }
  };

  render() {
    const options = this.options();
    const focusedIndex = this.focusedIndex(options.length);

    return (
      <Container
        className={this.props.className}
        hasIcon={this.props.icon != null}
        clearable={this.props.clearable}
      >
        <StyledTextInput
          value={this.state.query}
          onChange={this.handleQueryChange}
          onKeyDown={this.handleInputKeyDown}
          onFocus={this.handleOverlayShow}
          onBlur={this.handleOverlayHide}
          autoFocus={this.props.autoFocus}
          placeholder={this.props.placeholder}
          icon={this.props.icon}
          label={this.props.label}
          readOnly={this.props.readOnly}
          required={this.props.required}
          disabled={this.props.disabled}
        />
        {this.props.clearable &&
          !this.props.disabled &&
          this.state.query && (
            <ClearIcon
              className="fa fa-fw fa-times"
              onClick={this.handleClear}
              hasIcon={this.props.icon != null}
            />
          )}
        <StyledOverlay
          show={
            this.state.overlayShown &&
            ((this.props.showOptionsOnEmpty && options.length > 0) ||
              this.props.footerContent != null ||
              (this.state.query.length > 0 &&
                ((this.props.emptyText && this.state.query.length > 2) || options.length > 0)))
          }
          target={this}
          container={this}
        >
          {options.length === 0 && this.props.emptyText && <Empty>{this.props.emptyText}</Empty>}
          {options.map((option, index) => (
            <Option
              key={index} // eslint-disable-line react/no-array-index-key
              focused={focusedIndex === index}
              onMouseEnter={() => this.handleOptionHover(index)}
              onMouseDown={this.handleOptionSelect}
            >
              {this.props.renderOption(option)}
            </Option>
          ))}
          {this.props.footerContent}
        </StyledOverlay>
      </Container>
    );
  }
}
