/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BillingPlanMessage_subscription$ref = any;
type BillingTrialMessage_subscription$ref = any;
export type SubscriptionPlan = "BASIC" | "ENTERPRISE" | "INTERNAL" | "PREMIUM" | "TRIAL";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BillingContents_org$ref: FragmentReference;
export type BillingContents_org = {|
  +subscription: {|
    +expiresAt: ?any,
    +upgradeRequired: boolean,
    +plan: SubscriptionPlan,
    +$fragmentRefs: BillingTrialMessage_subscription$ref & BillingPlanMessage_subscription$ref,
  |},
  +$refType: BillingContents_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BillingContents_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subscription",
      "storageKey": null,
      "args": null,
      "concreteType": "Subscription",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "expiresAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "upgradeRequired",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "plan",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "BillingTrialMessage_subscription",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "BillingPlanMessage_subscription",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9d69acb483a1d031872dcd83c64b9f98';
module.exports = node;
