/**
 * @flow
 * @relayHash ba80a57fad06f75e1723ec99af73da5e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SaveTeamAllocationInput = {
  clientMutationId?: ?string,
  expenseId: string,
  teamAllocations: $ReadOnlyArray<TeamAllocationsInput>,
};
export type TeamAllocationsInput = {
  id: string,
  percent: number,
};
export type saveTeamAllocationMutationVariables = {|
  input: SaveTeamAllocationInput
|};
export type saveTeamAllocationMutationResponse = {|
  +saveTeamAllocation: ?{|
    +expense: {|
      +expenseTeamAllocations: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +percent: number,
            +team: {|
              +id: string,
              +name: string,
            |},
          |}
        |}>
      |}
    |}
  |}
|};
export type saveTeamAllocationMutation = {|
  variables: saveTeamAllocationMutationVariables,
  response: saveTeamAllocationMutationResponse,
|};
*/


/*
mutation saveTeamAllocationMutation(
  $input: SaveTeamAllocationInput!
) {
  saveTeamAllocation(input: $input) {
    expense {
      expenseTeamAllocations {
        edges {
          node {
            percent
            team {
              id
              name
            }
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SaveTeamAllocationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "SaveTeamAllocationInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "percent",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team",
  "storageKey": null,
  "args": null,
  "concreteType": "Team",
  "plural": false,
  "selections": [
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "saveTeamAllocationMutation",
  "id": null,
  "text": "mutation saveTeamAllocationMutation(\n  $input: SaveTeamAllocationInput!\n) {\n  saveTeamAllocation(input: $input) {\n    expense {\n      expenseTeamAllocations {\n        edges {\n          node {\n            percent\n            team {\n              id\n              name\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "saveTeamAllocationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveTeamAllocation",
        "storageKey": null,
        "args": v1,
        "concreteType": "SaveTeamAllocationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "expense",
            "storageKey": null,
            "args": null,
            "concreteType": "Expense",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "expenseTeamAllocations",
                "storageKey": null,
                "args": null,
                "concreteType": "ExpenseTeamAllocationRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ExpenseTeamAllocationRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ExpenseTeamAllocation",
                        "plural": false,
                        "selections": [
                          v2,
                          v4
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "saveTeamAllocationMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveTeamAllocation",
        "storageKey": null,
        "args": v1,
        "concreteType": "SaveTeamAllocationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "expense",
            "storageKey": null,
            "args": null,
            "concreteType": "Expense",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "expenseTeamAllocations",
                "storageKey": null,
                "args": null,
                "concreteType": "ExpenseTeamAllocationRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ExpenseTeamAllocationRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ExpenseTeamAllocation",
                        "plural": false,
                        "selections": [
                          v2,
                          v4,
                          v3
                        ]
                      }
                    ]
                  }
                ]
              },
              v3
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0994bc68455340887226249bc8215066';
module.exports = node;
