/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import paymentStatuses from 'config/paymentStatuses';

import { type CellPropsType } from 'components/budget/Table';
import InlineSelectField from 'components/budget/Table/InlineSelectField';

import { type PaymentCellPropsType } from './index';

import { type PaymentStatusCell_payment } from './__generated__/PaymentStatusCell_payment.graphql';

const PaymentStatusCell = ({
  payment,
  updatePayment,
  updateColumnWidth,
}: CellPropsType<PaymentCellPropsType<PaymentStatusCell_payment>>) => (
  <InlineSelectField
    options={Object.keys(paymentStatuses).map(key => ({ value: key, label: paymentStatuses[key] }))}
    value={payment.status}
    onChange={status => {
      updatePayment({ status });

      updateColumnWidth();
    }}
  />
);

export default createFragmentContainer(
  PaymentStatusCell,
  graphql`
    fragment PaymentStatusCell_payment on Payment {
      status
    }
  `,
);
