/* @flow */

import * as React from 'react';
import styled from 'styled-components';

export const StyledLink = styled.a`
  color: #3ba9da;
  cursor: pointer;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 12px;
  margin-bottom: 10px;
  background-color: #f7f8f9;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  color: #677386;
`;

export const Helper = styled.div`
  margin-top: 20px;
  margin-bottom: 45px;
`;

export const Info = styled.div`
  padding-top: 5px;
  color: #3e4859;
  text-align: center;
`;

const EmptyMessage = (props: {
  className?: string,
  icon?: React.Node,
  text?: string,
  onLinkClick?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  linkText?: string,
}) => {
  const { className, icon, onLinkClick, text, linkText } = props;
  return (
    <EmptyContainer className={className}>
      {icon}
      <Helper>
        <Info>{text}</Info>
        <StyledLink onClick={onLinkClick}>{linkText}</StyledLink>
      </Helper>
    </EmptyContainer>
  );
};

export default EmptyMessage;
