/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefUserAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefScheduleItem_scheduleItem$ref: FragmentReference;
export type BriefScheduleItem_scheduleItem = {|
  +title: ?string,
  +location?: ?string,
  +allDay: boolean,
  +startTime: any,
  +endTime: ?any,
  +description?: ?string,
  +participants?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +firstName: string,
        +lastName: string,
        +email: string,
        +phone: ?string,
        +officePhone: ?string,
        +title: ?string,
        +company: ?string,
        +$fragmentRefs: BriefUserAvatar_user$ref,
      |}
    |}>
  |},
  +$refType: BriefScheduleItem_scheduleItem$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "BriefScheduleItem_scheduleItem",
  "type": "ScheduleItem",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeScheduleLocation",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeScheduleDescription",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeScheduleParticipants",
      "type": "Boolean"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "allDay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "startTime",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endTime",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeScheduleLocation",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "location",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeScheduleDescription",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeScheduleParticipants",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "participants",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "UserRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "id",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "firstName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "lastName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "email",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "phone",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "officePhone",
                      "args": null,
                      "storageKey": null
                    },
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "company",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "BriefUserAvatar_user",
                      "args": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '499ab53add8adb24b2d9f6002eaad4e8';
module.exports = node;
