/**
 * @flow
 * @relayHash f1cd1bd6d47dacaf67ad5573b220e12b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventBriefRoutes_org$ref = any;
export type EventBriefTemplateQueryVariables = {||};
export type EventBriefTemplateQueryResponse = {|
  +org: {|
    +$fragmentRefs: EventBriefRoutes_org$ref
  |}
|};
export type EventBriefTemplateQuery = {|
  variables: EventBriefTemplateQueryVariables,
  response: EventBriefTemplateQueryResponse,
|};
*/


/*
query EventBriefTemplateQuery {
  org {
    ...EventBriefRoutes_org
    id
  }
}

fragment EventBriefRoutes_org on Org {
  ...TemplateContent_org
  ...TemplateSharing_org
}

fragment TemplateContent_org on Org {
  briefTemplate {
    id
  }
  ...Sections_org
}

fragment TemplateSharing_org on Org {
  name
  hasSamlProvider
  briefTemplate {
    id
    accessibility
  }
}

fragment Sections_org on Org {
  briefTemplate {
    sections {
      name
      order
      enabled
    }
    ...HeaderSection_template
    id
  }
  ...Section_org
}

fragment HeaderSection_template on BriefTemplate {
  headerSettings {
    includeDates
    includeLocation
    includeWebsite
  }
}

fragment Section_org on Org {
  ...EventInfoSection_org
  briefTemplate {
    ...EventInfoSection_template
    ...EventStaff_template
    ...Schedules_template
    ...Tasks_template
    id
  }
}

fragment EventInfoSection_org on Org {
  salesforceAccount {
    id
  }
  marketoAccount {
    id
  }
}

fragment EventInfoSection_template on BriefTemplate {
  id
  eventInfoSections {
    edges {
      node {
        id
        sectionName
        enabled
        order
        customFieldSection {
          id
          name
        }
        fields {
          edges {
            node {
              id
              fieldName
              order
              enabled
              customField {
                id
                label
              }
            }
          }
        }
      }
    }
  }
}

fragment EventStaff_template on BriefTemplate {
  eventStaffFields {
    name
    enabled
  }
}

fragment Schedules_template on BriefTemplate {
  personalizeSchedules
  scheduleFields {
    name
    enabled
  }
}

fragment Tasks_template on BriefTemplate {
  taskFields {
    name
    enabled
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enabled",
  "args": null,
  "storageKey": null
},
v4 = [
  v1,
  v3
],
v5 = [
  v0
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventBriefTemplateQuery",
  "id": null,
  "text": "query EventBriefTemplateQuery {\n  org {\n    ...EventBriefRoutes_org\n    id\n  }\n}\n\nfragment EventBriefRoutes_org on Org {\n  ...TemplateContent_org\n  ...TemplateSharing_org\n}\n\nfragment TemplateContent_org on Org {\n  briefTemplate {\n    id\n  }\n  ...Sections_org\n}\n\nfragment TemplateSharing_org on Org {\n  name\n  hasSamlProvider\n  briefTemplate {\n    id\n    accessibility\n  }\n}\n\nfragment Sections_org on Org {\n  briefTemplate {\n    sections {\n      name\n      order\n      enabled\n    }\n    ...HeaderSection_template\n    id\n  }\n  ...Section_org\n}\n\nfragment HeaderSection_template on BriefTemplate {\n  headerSettings {\n    includeDates\n    includeLocation\n    includeWebsite\n  }\n}\n\nfragment Section_org on Org {\n  ...EventInfoSection_org\n  briefTemplate {\n    ...EventInfoSection_template\n    ...EventStaff_template\n    ...Schedules_template\n    ...Tasks_template\n    id\n  }\n}\n\nfragment EventInfoSection_org on Org {\n  salesforceAccount {\n    id\n  }\n  marketoAccount {\n    id\n  }\n}\n\nfragment EventInfoSection_template on BriefTemplate {\n  id\n  eventInfoSections {\n    edges {\n      node {\n        id\n        sectionName\n        enabled\n        order\n        customFieldSection {\n          id\n          name\n        }\n        fields {\n          edges {\n            node {\n              id\n              fieldName\n              order\n              enabled\n              customField {\n                id\n                label\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EventStaff_template on BriefTemplate {\n  eventStaffFields {\n    name\n    enabled\n  }\n}\n\nfragment Schedules_template on BriefTemplate {\n  personalizeSchedules\n  scheduleFields {\n    name\n    enabled\n  }\n}\n\nfragment Tasks_template on BriefTemplate {\n  taskFields {\n    name\n    enabled\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventBriefTemplateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EventBriefRoutes_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventBriefTemplateQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "briefTemplate",
            "storageKey": null,
            "args": null,
            "concreteType": "BriefTemplate",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "sections",
                "storageKey": null,
                "args": null,
                "concreteType": "SortableSection",
                "plural": true,
                "selections": [
                  v1,
                  v2,
                  v3
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "headerSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "BriefTemplateHeader",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "includeDates",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "includeLocation",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "includeWebsite",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventInfoSections",
                "storageKey": null,
                "args": null,
                "concreteType": "BriefTemplateEventInfoSectionRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BriefTemplateEventInfoSectionRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BriefTemplateEventInfoSection",
                        "plural": false,
                        "selections": [
                          v0,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sectionName",
                            "args": null,
                            "storageKey": null
                          },
                          v3,
                          v2,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "customFieldSection",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldSectionType",
                            "plural": false,
                            "selections": [
                              v0,
                              v1
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "fields",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BriefTemplateEventInfoFieldRequiredConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BriefTemplateEventInfoFieldRequiredEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BriefTemplateEventInfoField",
                                    "plural": false,
                                    "selections": [
                                      v0,
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "fieldName",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      v2,
                                      v3,
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "customField",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "CustomFieldType",
                                        "plural": false,
                                        "selections": [
                                          v0,
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "label",
                                            "args": null,
                                            "storageKey": null
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventStaffFields",
                "storageKey": null,
                "args": null,
                "concreteType": "SimpleSection",
                "plural": true,
                "selections": v4
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "personalizeSchedules",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "scheduleFields",
                "storageKey": null,
                "args": null,
                "concreteType": "SimpleSection",
                "plural": true,
                "selections": v4
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "taskFields",
                "storageKey": null,
                "args": null,
                "concreteType": "SimpleSection",
                "plural": true,
                "selections": v4
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "accessibility",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": v5
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketoAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketoAccount",
            "plural": false,
            "selections": v5
          },
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasSamlProvider",
            "args": null,
            "storageKey": null
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04d62a61b1bf7c8893316fa9fe710215';
module.exports = node;
