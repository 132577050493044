/* @flow */
import React from 'react';
import { type RouterHistory, withRouter } from 'react-router';
import styled from 'styled-components';

import loginWithGoogle from 'utils/authentication/loginWithGoogle';
import getHomepageUrl from 'utils/getHomepageUrl';

import GoogleIcon from 'images/googleIcon.svg';

import AuthButton from './AuthButton';

const StyledButton = styled(AuthButton)`
  color: #4f5f79;
  font-size: 15px;
  font-weight: 500;
  border-color: transparent;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.18);
  transition: 0.1s;
  &:hover {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.18);
    color: #4f5f79;
  }
  &:focus {
    background: #eeeeee;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.18);
  }
  &:disabled {
    background: #eeeeee;
    color: #4f5f79;
  }
`;

const StyledGoogleIcon = styled(GoogleIcon)`
  margin: -15px -7px -15px 0;
  position: relative;
  top: -2px;
`;

class AuthGoogleButton extends React.Component<
  {
    history: RouterHistory,
    label: string,
    next: ?string,
    onError: (error: string) => void,
    disallowSignup?: boolean,
  },
  {
    loading: boolean,
  },
> {
  state = { loading: false };

  handleGoogleAuth = () => {
    this.setState({ loading: true });
    loginWithGoogle(!!this.props.disallowSignup)
      .then(user => {
        // Redirect user to custom org subdomain when logging in from app.circa.co
        if (user.domain_to_redirect != null) {
          window.location = `${user.domain_to_redirect}${this.props.next || ''}`;
          return;
        }

        if (user.onboarding_step >= 0 && user.onboarding_step < 6) {
          window.location = '/onboarding';
        } else if (this.props.next) {
          this.props.history.push(this.props.next);
        } else {
          this.props.history.push(
            getHomepageUrl(
              user.homepage_list_id == null ? user.homepage : user.homepage_list_id,
              user.relay_id,
            ),
          );
        }
      })
      .catch(err => {
        if (err) {
          console.warn(err);
          this.props.onError(err.message);
        }
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <StyledButton outline onClick={this.handleGoogleAuth} disabled={this.state.loading}>
        <StyledGoogleIcon /> {this.props.label}
      </StyledButton>
    );
  }
}

export default withRouter(AuthGoogleButton);
