/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { EventRequestFormsLastSubmittedColumn_requestForm } from './__generated__/EventRequestFormsLastSubmittedColumn_requestForm.graphql';

class EventRequestFormsLastSubmittedColumn extends React.PureComponent<{
  tz: string,
  requestForm: EventRequestFormsLastSubmittedColumn_requestForm,
}> {
  render() {
    if (!this.props.requestForm.lastSubmittedAt) return null;

    return (
      <DateTimeTooltip date={moment.tz(this.props.requestForm.lastSubmittedAt, this.props.tz)} />
    );
  }
}

export default createFragmentContainer(
  EventRequestFormsLastSubmittedColumn,
  graphql`
    fragment EventRequestFormsLastSubmittedColumn_requestForm on EventRequestForm {
      lastSubmittedAt
    }
  `,
);
