import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import UIActions from 'actions/UIActions';
import PropTypes from 'prop-types';

import addParticipant from 'graph/mutations/schedule/addParticipant';
import createScheduleItem from 'graph/mutations/schedule/createScheduleItem';
import removeParticipant from 'graph/mutations/schedule/removeParticipant';
import removeScheduleDay from 'graph/mutations/schedule/removeScheduleDay';
import removeScheduleItem from 'graph/mutations/schedule/removeScheduleItem';
import updateScheduleDay from 'graph/mutations/schedule/updateScheduleDay';
import updateScheduleItem from 'graph/mutations/schedule/updateScheduleItem';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ScheduleDayAdd from './ScheduleDayAdd';
import ScheduleDayHeader from './ScheduleDayHeader';
import ScheduleList from './ScheduleList';

class ScheduleDay extends React.Component {
  static propTypes = {
    scheduleDay: PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string,
      title: PropTypes.string,
      scheduleItems: PropTypes.shape({
        edges: PropTypes.array.isRequired,
      }).isRequired,
      schedule: PropTypes.shape({
        id: PropTypes.string.isRequired,
        dbId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        viewerCanUpdate: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    event: PropTypes.shape({
      tz: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    filters: PropTypes.object.isRequired,
    teamId: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
    onDayCreate: PropTypes.func.isRequired,
    usedDays: PropTypes.array,
    scheduleId: PropTypes.string.isRequired,
  };

  state = {
    addFormIndex: null,
  };

  handleItemAddShow = index => {
    this.setState({ addFormIndex: typeof index === 'number' ? index : 0 });
  };

  handleItemAddCancel = () => {
    this.setState({ addFormIndex: null });
  };

  handleDayUpdate = obj => {
    updateScheduleDay(this.props.scheduleDay.id, obj).catch(showModernMutationError);
  };

  handleDayRemove = () => {
    UIActions.showWindow('modal', {
      centered: true,
      noClose: true,
      className: 'event-remove-window',
      title: 'Are you sure?',
      message: 'Once you delete a Day, all the agenda items scheduled for that day will be lost.',
      cancelButton: {
        text: 'Delete',
        action: () => {
          removeScheduleDay(this.props.scheduleId, this.props.scheduleDay.id).catch(
            showModernMutationError,
          );

          UIActions.hideWindow('modal');
        },
      },
      confirmButton: {
        text: 'Cancel',
        action() {
          UIActions.hideWindow('modal');
        },
      },
    });
  };

  handleItemCreate = item => {
    createScheduleItem(this.props.scheduleDay.id, item)
      .then(() => {
        this.setState({ addFormIndex: null });
      })
      .catch(showModernMutationError);
  };

  handleItemRemove = scheduleItemId => {
    removeScheduleItem(this.props.scheduleDay.id, scheduleItemId).catch(showModernMutationError);
  };

  handleItemUpdate = (scheduleItemId, obj) => {
    updateScheduleItem(scheduleItemId, obj).catch(showModernMutationError);
  };

  handleParticipantAdd = (scheduleItemId, userId) => {
    addParticipant(scheduleItemId, userId).catch(showModernMutationError);
  };

  handleParticipantRemove = (scheduleItemId, userId) => {
    removeParticipant(scheduleItemId, userId).catch(showModernMutationError);
  };

  render() {
    const { scheduleDay, usedDays, teamId, eventId, filters, event } = this.props;
    const { addFormIndex } = this.state;

    return (
      <div className="schedule-day">
        <ScheduleDayHeader
          scheduleDay={scheduleDay}
          onDateSave={date => this.handleDayUpdate({ date })}
          onTitleSave={title => this.handleDayUpdate({ title })}
          onDayRemove={this.handleDayRemove}
          teamId={teamId}
        />
        <ScheduleList
          scheduleDay={scheduleDay}
          event={event}
          onItemCreate={this.handleItemCreate}
          onItemUpdate={this.handleItemUpdate}
          onItemRemove={this.handleItemRemove}
          onParticipantAdd={this.handleParticipantAdd}
          onParticipantRemove={this.handleParticipantRemove}
          onAddFormShow={this.handleItemAddShow}
          onAddFormHide={this.handleItemAddCancel}
          eventId={eventId}
          teamId={teamId}
          filters={filters}
          addFormIndex={addFormIndex}
        />
        {this.props.scheduleDay.schedule.viewerCanUpdate && (
          <ScheduleDayAdd tz={event.tz} onCreate={this.props.onDayCreate} usedDays={usedDays} />
        )}
      </div>
    );
  }
}

export default createFragmentContainer(ScheduleDay, {
  scheduleDay: graphql`
    fragment ScheduleDay_scheduleDay on ScheduleDay {
      id
      date
      title
      ...ScheduleDayHeader_scheduleDay
      ...ScheduleList_scheduleDay
      scheduleItems {
        edges {
          node {
            id
          }
        }
      }
      schedule {
        id
        dbId
        name
        viewerCanUpdate
      }
    }
  `,
  event: graphql`
    fragment ScheduleDay_event on Event {
      tz
      name
      ...ScheduleList_event
    }
  `,
});
