/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import { type ColumnConfiguration, TableRow } from 'components/material/table';

import TeamMembersActionsColumn, { Icon } from './TeamMembersActionsColumn';

import type { TeamMembersTableRow_me } from './__generated__/TeamMembersTableRow_me.graphql';
import type { TeamMembersTableRow_member } from './__generated__/TeamMembersTableRow_member.graphql';
import type { TeamMembersTableRow_team } from './__generated__/TeamMembersTableRow_team.graphql';

const StyledTableRow = styled(TableRow)`
  padding-right: 50px;
  cursor: pointer;
  /* Actions column. Only shown on hover */
  > *:last-of-type ${Icon} {
    visibility: hidden;
  }
  &:hover {
    > *:last-of-type ${Icon} {
      visibility: visible;
    }
  }
`;

class TeamMembersTableRow extends React.PureComponent<{
  member: TeamMembersTableRow_member,
  team: TeamMembersTableRow_team,
  me: TeamMembersTableRow_me,
  history: RouterHistory,
  location: Location,
  shownColumns: ColumnConfiguration,
  onColumnLoad: (value: string, str: string, extraSpace?: number) => void,
}> {
  handleRowClick = () => {
    this.props.history.push(`/workspace/members/${this.props.member.node.id}`, {
      prevPage: true,
      teamSlug: this.props.team.slug,
      search: this.props.location.search,
    });
  };

  render() {
    return (
      <StyledTableRow onClick={this.handleRowClick}>
        {this.props.shownColumns.map(column => (
          <column.component key={column.value} width={column.width} grow={column.grow}>
            <column.contentComponent
              member={this.props.member}
              location={this.props.location}
              tz={this.props.me.tz}
              teamSlug={this.props.team.slug}
              onColumnLoad={(str, extraSpace) =>
                this.props.onColumnLoad(column.value, str, extraSpace)
              }
            />
          </column.component>
        ))}
        <TeamMembersActionsColumn
          member={this.props.member}
          team={this.props.team}
          me={this.props.me}
        />
      </StyledTableRow>
    );
  }
}

export default createFragmentContainer(TeamMembersTableRow, {
  member: graphql`
    fragment TeamMembersTableRow_member on TeamMemberEdge {
      node {
        id
      }
      ...TeamMemberFullName_member
      ...TeamMemberEmail_member
      ...TeamMembersActionsColumn_member
      ...TeamMemberLastActivity_member
      ...TeamMemberInviteStatus_member
    }
  `,
  team: graphql`
    fragment TeamMembersTableRow_team on Team {
      slug
      ...TeamMembersActionsColumn_team
    }
  `,
  me: graphql`
    fragment TeamMembersTableRow_me on User {
      tz
      ...TeamMembersActionsColumn_me
    }
  `,
});
