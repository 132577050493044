/* @flow */
import type { Location, RouterHistory } from 'react-router-dom';

import determineCustomFieldMinWidth from 'utils/customization/determineCustomFieldMinWidth';
import type { FieldType } from 'utils/customization/types';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import { type ColumnType } from 'components/budget/Table';
import kindToTableComponent from 'components/Customizable/kindToTableComponent';
import type { Filter } from 'components/material/ColumnFilters/columnFilterTypes';

import StaffAccessLevelCell from './StaffAccessLevelCell';
import StaffActionsCell from './StaffActionsCell';
import StaffLastActivityCell from './StaffLastActivityCell';
import StaffMemberEmail from './StaffMemberEmail';
import StaffNameCell from './StaffNameCell';
import StaffOnsiteCell from './StaffOnsiteCell';

export type StaffCellPropsType<StaffType: {}, Org: ?{} = {}> = {|
  staffer: StaffType,
  org: Org,
  tz: string,
  eventId: string,
  userId: ?string,
  location: Location,
  history: RouterHistory,
  readOnly: boolean,
  customizable: StaffType,
  fieldSettings: ?FieldType,
  onUpdateCustomField: (
    customizable: CustomizableResponse,
    args: UpdateCustomFieldValueInput,
  ) => Promise<void>,
|};

const defaultColumns = ['name', 'email', 'event_access_level', 'onsite', 'last_activity'];

export function getColumnsShowConfig(
  staffFields: $ReadOnlyArray<FieldType>,
): $ReadOnlyArray<Filter> {
  return staffFields.map(staffField => {
    return {
      value: staffField.fieldName || staffField.id,
      label: staffField.label,
      default: defaultColumns.includes(staffField.fieldName),
      sticky: staffField.fieldName === 'name',
    };
  });
}

export const defaultTableComponents = {
  name: StaffNameCell,
  email: StaffMemberEmail,
  event_access_level: StaffAccessLevelCell,
  onsite: StaffOnsiteCell,
  last_activity: StaffLastActivityCell,
};

export default (
  staffFields: $ReadOnlyArray<FieldType>,
  shownColumns: $ReadOnlyArray<string>,
): $ReadOnlyArray<ColumnType<any, any>> => {
  return [
    ...staffFields
      .filter(({ fieldName, id }) => shownColumns.includes(fieldName || id))
      .map(staffField => {
        if (staffField.fieldName != null) {
          return {
            title: staffField.label,
            fieldSettings: staffField,
            component: defaultTableComponents[staffField.fieldName],
            sortKey: staffField.fieldName.toUpperCase(),
            ...(staffField.fieldName === 'name' ? { grow: 1, fixedLeft: 41 } : null),
          };
        }
        return {
          title: staffField.label,
          fieldSettings: staffField,
          sortKey: staffField.id,
          align: ['CURRENCY', 'NUMBER'].includes(staffField.kind) ? 'right' : 'left',
          component: kindToTableComponent[staffField.kind],
          minWidth: determineCustomFieldMinWidth(staffField.kind),
        };
      }),
    {
      title: '',
      type: 'action',
      component: StaffActionsCell,
    },
  ];
};
