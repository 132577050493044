/* @flow */
import React from 'react';
import { type Location, type Match, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import FeatureAccessContext from 'contexts/FeatureAccess';

import BackLink from 'components/BackLink';
import Menu from 'components/page/Menu';
import MenuItem from 'components/page/MenuItem';

import Briefs from './Briefs';
import EventFieldsSettings from './EventFieldsSettings';
import StaffFieldsSettings from './StaffFieldsSettings';
import EventRequestForms from './EventRequestForms';
import RegistrationFormTemplates from './RegistrationFormTemplates';

import type { SettingsRoutes_org } from '../__generated__/SettingsRoutes_org.graphql';

const Title = styled.div`
  margin: ${props => (props.backLinkShown ? 12 : 25)}px 0 21px 25px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const Container = styled.div`
  height: 48px;
  border-bottom: 1px solid #eaebec;
  overflow: hidden;
`;

export default class EventSettings extends React.PureComponent<{
  org: SettingsRoutes_org,
  match: Match,
  location: Location,
}> {
  static contextType = FeatureAccessContext;

  render() {
    const { org, match, location } = this.props;
    const pathPrefix = match.path;

    return (
      <>
        {location.state && location.state.from && (
          <BackLink
            label={location.state.from === 'eventInfo' ? 'Event Info' : 'Event Requests'}
            path={location.state.fromUrl}
          />
        )}
        <Title backLinkShown={location.state && location.state.from}>Event Settings</Title>
        <Container>
          <Menu scrollable>
            <MenuItem to={`${pathPrefix}/event_fields`} index text="Event Fields" />
            <MenuItem to={`${pathPrefix}/staff_fields`} index text="Staff Fields" />
            <MenuItem to={`${pathPrefix}/requests`} index text="Request Forms" />
            {this.context.legacyFeatures && (
              <MenuItem to={`${pathPrefix}/reg_forms_template`} text="Reg Form Template" />
            )}
            <MenuItem to={`${pathPrefix}/briefs`} text="Brief Template" />
          </Menu>
        </Container>
        <Switch>
          <Route
            path={`${pathPrefix}/event_fields`}
            exact
            render={props => (
              <EventFieldsSettings org={org} {...props} title={`Event Fields - ${org.name}`} />
            )}
          />
          <Route
            path={`${pathPrefix}/staff_fields`}
            exact
            render={props => (
              <StaffFieldsSettings org={org} {...props} title={`Staff Fields - ${org.name}`} />
            )}
          />
          <Route
            path={`${pathPrefix}/requests`}
            render={props => <EventRequestForms {...props} title={`Request Forms - ${org.name}`} />}
            exact
          />
          <Route
            path={`${pathPrefix}/briefs`}
            render={props => <Briefs {...props} title={`Brief Template - ${org.name}`} />}
          />
          {this.context.legacyFeatures && (
            <Route
              path={`${pathPrefix}/reg_forms_template`}
              render={props => (
                <RegistrationFormTemplates {...props} title={`Reg Form Template - ${org.name}`} />
              )}
            />
          )}
          <Redirect to={`${pathPrefix}/event_fields`} />
        </Switch>
      </>
    );
  }
}
