/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  FieldOrderInput,
  reorderContactFormMutationResponse,
  reorderContactFormMutationVariables,
} from './__generated__/reorderContactFormMutation.graphql';

const mutation = graphql`
  mutation reorderContactFormMutation($input: ReorderContactFormInput!) {
    reorderContactForm(input: $input) {
      updatedFields {
        id
        label
        order
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function reorderContactForm(
  contactFormId: string,
  fieldsOrder: $ReadOnlyArray<FieldOrderInput>,
): Promise<reorderContactFormMutationResponse> {
  const variables: reorderContactFormMutationVariables = {
    input: { contactFormId, fieldsOrder },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      reorderContactForm: {
        updatedFields: fieldsOrder,
      },
    },
  });
}
