/* @flow */
import type { FieldType } from 'utils/customization/types';
import getCustomFieldFilterConverterByKind from 'utils/filters/getCustomFieldFilterConverterByKind';
import {
  stringArrayToStringParam,
  stringToStringParam,
} from 'utils/routing/stringifyQueryParamValues';

export default function getTaskFilterConverters(customFields: $ReadOnlyArray<FieldType>) {
  return customFields.reduce((converter, customField) => {
    if (customField.fieldName != null) {
      switch (customField.fieldName) {
        case 'dueDate':
        case 'eventDate':
          return { ...converter, [customField.fieldName]: stringToStringParam };
        default:
          return { ...converter, [customField.fieldName]: stringArrayToStringParam };
      }
    }
    return { ...converter, ...getCustomFieldFilterConverterByKind(customField) };
  }, {});
}
