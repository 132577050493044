/**
 * @flow
 * @relayHash f6290c74ad753b1d0844ee3edd6f5b0c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportExpensesInput = {
  clientMutationId?: ?string,
  fileName: string,
  fileContent: string,
  mappings: ExpenseImportMappings,
};
export type ExpenseImportMappings = {
  eventId?: ?string,
  name?: ?string,
  amount?: ?string,
  category?: ?string,
  vendor?: ?string,
  currency?: ?string,
  type?: ?string,
  status?: ?string,
  dueDate?: ?string,
  actualDate?: ?string,
  method?: ?string,
  poNumber?: ?string,
  reference?: ?string,
  note?: ?string,
};
export type importExpensesMutationVariables = {|
  input: ImportExpensesInput
|};
export type importExpensesMutationResponse = {|
  +importExpenses: ?{|
    +uuid: string
  |}
|};
export type importExpensesMutation = {|
  variables: importExpensesMutationVariables,
  response: importExpensesMutationResponse,
|};
*/


/*
mutation importExpensesMutation(
  $input: ImportExpensesInput!
) {
  importExpenses(input: $input) {
    uuid
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportExpensesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "importExpenses",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ImportExpensesInput!"
      }
    ],
    "concreteType": "ImportExpensesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uuid",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "importExpensesMutation",
  "id": null,
  "text": "mutation importExpensesMutation(\n  $input: ImportExpensesInput!\n) {\n  importExpenses(input: $input) {\n    uuid\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "importExpensesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "importExpensesMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b0b468e48ffab2f3df91573700a61040';
module.exports = node;
