import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';

import ScheduleViewMultiple from 'images/schedule-multiple.svg';
import ScheduleViewSingle from 'images/schedule-single.svg';
import HintLink from 'components/Hint';
import ShareDropdown from 'components/ShareDropdown';
import ViewModes from 'components/ViewModes';

class SchedulesMultiHeader extends React.Component {
  static propTypes = {
    activeSchedules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        token: PropTypes.string,
      }),
    ).isRequired,
    event: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      viewerCanCreateSchedules: PropTypes.bool.isRequired,
      schedules: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
              slug: PropTypes.string.isRequired,
              order: PropTypes.number.isRequired,
            }).isRequired,
          }),
        ),
      }).isRequired,
    }).isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    onViewChange: PropTypes.func.isRequired,
    onMultiShareClick: PropTypes.func.isRequired,
    onPrintClick: PropTypes.func.isRequired,
    onScheduleToggle: PropTypes.func.isRequired,
  };

  render() {
    const { event, activeSchedules, colors, onViewChange, onScheduleToggle } = this.props;
    const schedules = sortBy(
      event.schedules.edges.map(x => x.node),
      'order',
    );

    const scheduleTokens = activeSchedules.reduce((cum, schedule) => {
      return `${cum && `${cum},`}${schedule.token}`;
    }, '');

    return (
      <div className="schedules-header schedules-multi-header">
        <div className="schedules-header-items">
          {schedules.map((schedule, index) => {
            const active = activeSchedules.some(s => s.id === schedule.id);
            return (
              <div
                key={schedule.id}
                className={classNames('schedule-header-item', { active })}
                style={{ color: active ? colors[index % colors.length] : undefined }}
                onClick={() => onScheduleToggle(schedule.slug)}
              >
                <div className="schedule-header-item-name">{schedule.name}</div>
              </div>
            );
          })}
        </div>
        <HintLink to="https://help.circa.co/102298-schedules/how-do-i-create-share-and-manage-event-schedules" />
        <div className="schedules-actions">
          <ViewModes
            modes={[
              { label: 'Single', value: 'single', svg: ScheduleViewSingle },
              { label: 'Grid', value: 'multi', svg: ScheduleViewMultiple },
            ]}
            onViewModeChange={value => value === 'single' && onViewChange()}
            viewMode="multi"
          />

          {event.viewerCanCreateSchedules && (
            <ShareDropdown
              options={[
                {
                  label: 'Publish to web',
                  icon: 'link',
                  onClick: () => this.props.onMultiShareClick(scheduleTokens),
                  disabled: activeSchedules.length > 5,
                },

                {
                  label: 'Export (PDF)',
                  icon: 'file-pdf-o',
                  onClick: () => this.props.onPrintClick(scheduleTokens),
                  disabled: activeSchedules.length > 5,
                },
              ].filter(Boolean)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(
  SchedulesMultiHeader,
  graphql`
    fragment SchedulesMultiHeader_event on Event {
      slug
      viewerCanCreateSchedules
      schedules {
        edges {
          node {
            id
            name
            slug
            order
          }
        }
      }
    }
  `,
);
