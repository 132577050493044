/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Loader from 'components/Loader';

const BaseRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  background: ${props => props.theme.secondaryRowColor};
`;

const LoadingRow = styled(BaseRow)`
  justify-content: center;
`;

const LoadAllRow = styled(BaseRow)`
  justify-content: flex-end;
`;

const LoadAllSection = styled.div`
  color: ${props => props.theme.secondaryActionDarkerColor};
  a {
    cursor: pointer;
  }
`;

const LoadExtraLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

const TotalMessage = styled.span``;

export default class LoadMoreRow extends React.PureComponent<{
  className?: ?string,
  totalCount?: ?number,
  hasNextPage: boolean,
  loading: boolean,
  onLoadMore: number => void,
}> {
  loadMore = (amount: number) => {
    if (!this.props.loading) {
      this.props.onLoadMore(amount);
    }
  };

  handleLoad25More = () => {
    this.loadMore(25);
  };

  handleLoad50More = () => {
    this.loadMore(50);
  };

  handleLoad100More = () => {
    this.loadMore(100);
  };

  render() {
    const className = this.props.className;

    if (this.props.loading) {
      return (
        <LoadingRow className={className}>
          <Loader size={20} />
        </LoadingRow>
      );
    }
    const { totalCount, hasNextPage } = this.props;

    return (
      <LoadAllRow className={className}>
        <LoadAllSection disabled={this.props.loading}>
          {hasNextPage && (
            <span>
              <LoadExtraLink onClick={this.handleLoad25More}>Next 25</LoadExtraLink>
              {(totalCount == null || totalCount > 25) && (
                <span>
                  {' '}
                  | <LoadExtraLink onClick={this.handleLoad50More}>50</LoadExtraLink>
                </span>
              )}
              {(totalCount == null || totalCount > 50) && (
                <span>
                  {' '}
                  | <LoadExtraLink onClick={this.handleLoad100More}>100</LoadExtraLink>
                </span>
              )}
              {totalCount !== null && <span>&nbsp;|&nbsp;</span>}
            </span>
          )}
          {totalCount != null && <TotalMessage>Total: {totalCount}</TotalMessage>}
        </LoadAllSection>
      </LoadAllRow>
    );
  }
}
