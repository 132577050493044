/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';

import type { FieldType } from 'utils/customization/types';

import { type ColumnGroupConfiguration } from 'components/AllEvents/lib/getEventFields';
import { type ParsedAllEventsFilters } from 'components/AllEvents/lib/parseAllEventsFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import AllEventsHeaderBar from '../components/AllEventsHeaderBar';
import AllEventsMapContainer from './AllEventsMapContainer';

import { type AllEventsMap_eventList } from './__generated__/AllEventsMap_eventList.graphql';
import { type AllEventsMap_org } from './__generated__/AllEventsMap_org.graphql';
import { type AllEventsMap_user } from './__generated__/AllEventsMap_user.graphql';
import { type AllEventsMapQueryResponse } from './__generated__/AllEventsMapQuery.graphql';

const query = graphql`
  query AllEventsMapQuery($count: Int!, $filters: EventFilters!) {
    me {
      events(first: $count, filters: $filters) {
        edges {
          node {
            ...AllEventsMapContainer_events
          }
        }
      }
    }
  }
`;

class AllEventsMap extends React.Component<
  {
    org: AllEventsMap_org,
    user: AllEventsMap_user,
    eventList: ?AllEventsMap_eventList,
    eventFields: $ReadOnlyArray<FieldType>,
    shownColumns: $ReadOnlyArray<string>,
    pathPrefix: string,
    filters: ParsedAllEventsFilters,
    filterVariables: {},
    groups: ColumnGroupConfiguration,
    match: Match,
    location: Location,
  },
  { eventsCount: number },
> {
  state = { eventsCount: 0 };

  setEventsCount = (eventsCount: number) => {
    if (this.state.eventsCount !== eventsCount) {
      this.setState({ eventsCount });
    }
  };

  render() {
    const {
      org,
      user,
      eventList,
      filters,
      filterVariables,
      eventFields,
      shownColumns,
      pathPrefix,
      groups,
      match,
      location,
    } = this.props;

    const variables = {
      count: 500,
      filters: {
        ...filterVariables,
      },
    };

    return (
      <>
        <AllEventsHeaderBar
          org={org}
          user={user}
          eventList={eventList}
          pathPrefix={pathPrefix}
          filters={filters}
          fromWindow="map"
          eventFields={eventFields}
          shownColumns={shownColumns}
          groups={groups}
          csvExportVariables={variables.filters}
          viewMode="map"
          eventsCount={this.state.eventsCount}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: AllEventsMapQueryResponse) => {
            this.setEventsCount(props.me.events.edges.length);
            return (
              <AllEventsMapContainer
                events={props.me.events.edges.map(({ node }) => node)}
                match={match}
                location={location}
              />
            );
          }}
        />
      </>
    );
  }
}

export default createFragmentContainer(AllEventsMap, {
  org: graphql`
    fragment AllEventsMap_org on Org {
      ...AllEventsHeaderBar_org
    }
  `,
  user: graphql`
    fragment AllEventsMap_user on User {
      ...AllEventsHeaderBar_user
    }
  `,
  eventList: graphql`
    fragment AllEventsMap_eventList on EventList {
      ...AllEventsHeaderBar_eventList
    }
  `,
});
