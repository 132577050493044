/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import { upload } from 'utils/Attachments';
import showErrorPopup from 'utils/showErrorPopup';
import pickFiles from 'utils/uploading/pickFiles';
import type { CircaFile } from 'utils/uploading/types';

import updateImageComponent, {
  type updateImageComponentPropertyType,
} from 'graph/mutations/registration/updateImageComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ImageIcon from 'images/registration/image.svg';
import { DropZone } from 'components/DropZone';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import generateFontFromProps from '../generateFontFromProps';
import generatePaddingProps from '../generatePaddingProps';

import type { ImageComponent_componentProps } from './__generated__/ImageComponent_componentProps.graphql';

const Container = styled.div`
  padding: ${props => generatePaddingProps(props.componentProps.padding)};
  background-color: ${props => props.componentProps.backgroundColor || 'initial'};
  font: ${props => generateFontFromProps(props.componentProps)};
  text-align: ${props => props.componentProps.alignment};
  color: ${props => props.componentProps.color || 'initial'};
  img {
    max-width: 100%;
    width: ${props =>
      `${props.componentProps.width}${
        props.componentProps.widthMeasurement === 'PERCENTAGE' ? '%' : 'px'
      }`};
    height: auto;
  }
  ${props =>
    props.active &&
    css`
      cursor: pointer;
    `}
`;

const Text = styled.div`
  color: #3ba9da;
`;

const StyledImageIcon = styled(ImageIcon)`
  margin-right: 15px;
  stroke: #3ba9da;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 125px;
  background-color: #fff;
  color: #7e7e7e;
`;

const AddImageButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
`;

const DropZoneContainer = styled.div`
  position: relative;
`;

const StyledDropZone = styled(DropZone)`
  position: relative;

  .attachment-drop {
    padding-top: 15px;
    i {
      font-size: 40px !important;
    }
  }
`;

const ImageError = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.negativeActionColor};
`;

const Hint = styled.div`
  height: 0;
  margin: 10px 0 0;
  font-size: 12px;
  color: #949ba4;
`;

const LoadingIcon = styled.i`
  color: #949ba4;
`;

class ImageComponent extends React.Component<
  {
    componentProps: ImageComponent_componentProps,
    active: boolean,
    readOnly?: boolean,
    onSelectPageComponent: (id: string) => void,
    selectedComponent: SelectedComponent,
  },
  {
    showImageError: boolean,
    uploading: boolean,
  },
> {
  state = { showImageError: false, uploading: false };

  image: ?Image;

  componentDidUpdate(prevProps: $PropertyType<ImageComponent, 'props'>) {
    const selectedComponent = this.props.selectedComponent;
    const selectedImageWidth = selectedComponent && selectedComponent.selectedComponentWidth;
    if (
      this.image &&
      this.image.naturalWidth !== selectedImageWidth &&
      prevProps.selectedComponent &&
      prevProps.selectedComponent.selectedComponentWidth !== selectedImageWidth
    ) {
      this.image = null;
    }
  }

  calculateImageSize = (file: File) =>
    new Promise(resolve => {
      const url = URL.createObjectURL(file);
      this.image = new Image();
      this.image.src = url;
      this.image.onload = () => {
        URL.revokeObjectURL(url);

        resolve();
      };
    });

  handleFileDrop = (files: File[]) => {
    const file = files.find(f => f.type && f.type.startsWith('image'));
    if (file) {
      this.setState({ uploading: true });
      this.calculateImageSize(file)
        .then(() => upload(file))
        .then(uploadedFile => {
          this.updateImageComponent({
            ...uploadedFile,
            width: this.image && this.image.naturalWidth,
            widthMeasurement: 'PX',
          });
          this.setState({ showImageError: false, uploading: false });

          if (!this.props.active) {
            this.props.onSelectPageComponent(this.props.componentProps.id);
          }
        });
    } else {
      this.setState({ showImageError: true, uploading: false });
    }
  };

  updateImageComponent = (properties: updateImageComponentPropertyType) => {
    const imageComponent = this.props.componentProps.imageComponent;
    if (!imageComponent) {
      return;
    }
    updateImageComponent({
      ...properties,
      id: imageComponent.id,
    }).catch(showModernMutationError);
  };

  showImageUploadDialog = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    const accept = { accept: ['image/*'] };
    const maxFiles = 1;
    const concurrency = 1;
    const options = {
      maxFiles,
      concurrency,
      ...accept,
      exposeOriginalFile: true,
      onFileSelected: file => this.calculateImageSize(file.originalFile),
    };
    pickFiles(options)
      .then((files: $ReadOnlyArray<CircaFile>) => {
        // $FlowFixMe flow complaining that filename and other props doesn't exists in CircaFile which is not the case
        this.updateImageComponent({
          ...files[0],
          width: this.image && this.image.naturalWidth,
          widthMeasurement: 'PX',
        });
      })
      .catch(showErrorPopup);
  };

  handleImageClick = (e: SyntheticEvent<HTMLElement>) => {
    if (this.props.active) {
      this.showImageUploadDialog(e);
    }
  };

  getCurrentWidth = (): number => {
    const {
      selectedComponent,
      componentProps: { imageComponent },
    } = this.props;
    if (this.image) {
      return this.image.naturalWidth;
    }
    const defaultWidth = 100; // for flow to not complain
    if (!selectedComponent) {
      return imageComponent ? imageComponent.width : defaultWidth;
    }

    if (selectedComponent.selectedComponentWidth != null) {
      return selectedComponent.selectedComponentWidth;
    }
    return imageComponent ? imageComponent.width : defaultWidth;
  };

  renderImage() {
    const {
      active,
      selectedComponent,
      componentProps: { imageComponent },
    } = this.props;

    if (!imageComponent || !imageComponent.fileurl) {
      return null;
    }
    const componentProps = selectedComponent
      ? {
          ...imageComponent,
          width: this.getCurrentWidth(),
          padding: selectedComponent.selectedComponentPadding || imageComponent.padding,
        }
      : imageComponent;
    return (
      <Container active={active} componentProps={componentProps} onClick={this.handleImageClick}>
        <img src={imageComponent.fileurl} alt={imageComponent.filename} />
      </Container>
    );
  }

  render() {
    const { showImageError, uploading } = this.state;
    const {
      componentProps: { imageComponent },
      readOnly,
    } = this.props;

    if (!imageComponent) {
      return null;
    }
    if (uploading) {
      return (
        <EmptyContainer>
          <LoadingIcon className="fa fa-fw fa-circle-o-notch fa-spin" />
          Uploading image ...
        </EmptyContainer>
      );
    }

    if (readOnly) {
      return this.renderImage();
    }

    return (
      <DropZoneContainer>
        <StyledDropZone onDrop={this.handleFileDrop}>
          {imageComponent.fileurl ? (
            this.renderImage()
          ) : (
            <EmptyContainer>
              <AddImageButton onClick={this.showImageUploadDialog}>
                <StyledImageIcon />
                <Text>Add Image</Text>
              </AddImageButton>

              <Hint>Image should be in png/jpg/gif format. The size cannot exceed 10M.</Hint>
            </EmptyContainer>
          )}
          {showImageError && <ImageError>The uploaded file should be an image</ImageError>}
        </StyledDropZone>
      </DropZoneContainer>
    );
  }
}

export default createFragmentContainer(ImageComponent, {
  componentProps: graphql`
    fragment ImageComponent_componentProps on RegistrationPageComponent {
      id
      imageComponent {
        id
        fileurl
        filename
        filesize
        filetype
        padding
        alignment
        width
        widthMeasurement
        backgroundColor
      }
    }
  `,
});
