/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import formatCost from 'utils/number/formatCost';

import CostField from 'components/material/CostField';

import InlineEditableCell from './InlineEditableCell';

const Root = styled.div`
  label {
    input {
      padding-right: 35px;
      text-align: right;
    }
  }
`;

export default class InlineCostField extends React.PureComponent<
  {|
    currency: string,
    value: ?number,
    onChange: (value: ?number) => void,
    placeholder?: string,
    getError?: ?(value: ?number) => ?string,
    updateColumnWidth?: () => void,
  |},
  { value: ?number, error: ?string },
> {
  state = { value: this.props.value, error: null };

  handleSave = () => {
    if (this.state.value !== this.props.value) {
      this.props.onChange(this.state.value);
    }
  };

  handleCancel = () => {
    this.setState(state => (state.value !== this.props.value ? { value: this.props.value } : null));
  };

  handleChange = (event: SyntheticEvent<HTMLElement>, value: ?number) => {
    this.setState({ value });
    const { getError, updateColumnWidth } = this.props;
    if (!getError) {
      return;
    }
    const error = getError(value);

    this.setState({ error });
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
  };

  handleValidate = () => {
    const value = this.state.value;
    const { getError, updateColumnWidth } = this.props;

    const error = getError ? getError(value) : null;

    this.setState({ error });
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  render() {
    return (
      <Root>
        <InlineEditableCell
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          onValidate={this.handleValidate}
          error={this.state.error}
          rightAligned
        >
          {({ onBlur, onKeyDown, editing }) => {
            if (!editing) return formatCost(this.props.value, this.props.currency) || '-';

            return (
              <CostField
                currency={this.props.currency}
                value={this.state.value}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={this.handleChange}
                placeholder={this.props.placeholder || 'Ex: 999.98'}
                autoFocus
              />
            );
          }}
        </InlineEditableCell>
      </Root>
    );
  }
}
