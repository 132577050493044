/**
 * @flow
 * @relayHash 4a767ef0ea663d138857ba62dfa74dbe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RightSidebar_org$ref = any;
export type EventListPreviewQueryVariables = {||};
export type EventListPreviewQueryResponse = {|
  +org: {|
    +slug: string,
    +settings: {|
      +subdomain: ?string
    |},
    +$fragmentRefs: RightSidebar_org$ref,
  |}
|};
export type EventListPreviewQuery = {|
  variables: EventListPreviewQueryVariables,
  response: EventListPreviewQueryResponse,
|};
*/


/*
query EventListPreviewQuery {
  org {
    slug
    settings {
      subdomain
      id
    }
    ...RightSidebar_org
    id
  }
}

fragment RightSidebar_org on Org {
  settings {
    ...EventListDesign_orgSettings
    id
  }
}

fragment EventListDesign_orgSettings on OrgSettings {
  logo
  font
  primaryColor
  ...ColorSelector_orgSettings
}

fragment ColorSelector_orgSettings on OrgSettings {
  primaryColor
  secondaryColor
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subdomain",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventListPreviewQuery",
  "id": null,
  "text": "query EventListPreviewQuery {\n  org {\n    slug\n    settings {\n      subdomain\n      id\n    }\n    ...RightSidebar_org\n    id\n  }\n}\n\nfragment RightSidebar_org on Org {\n  settings {\n    ...EventListDesign_orgSettings\n    id\n  }\n}\n\nfragment EventListDesign_orgSettings on OrgSettings {\n  logo\n  font\n  primaryColor\n  ...ColorSelector_orgSettings\n}\n\nfragment ColorSelector_orgSettings on OrgSettings {\n  primaryColor\n  secondaryColor\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventListPreviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              v1
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "RightSidebar_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventListPreviewQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              v1,
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "logo",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "font",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "primaryColor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "secondaryColor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5233884e28b9fed11f461262b8526e5f';
module.exports = node;
