/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TaskWindowContent_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskWindow_org$ref: FragmentReference;
export type TaskWindow_org = {|
  +$fragmentRefs: TaskWindowContent_org$ref,
  +$refType: TaskWindow_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TaskWindow_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "TaskWindowContent_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9d6fd07880465d1c213141f1813fa800';
module.exports = node;
