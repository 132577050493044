/* @flow */
import * as React from 'react';
import ClickOut from 'react-onclickout';
import styled, { css } from 'styled-components';

const EditIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 1;
  font-size: 16px;
  color: #71c1e5;
  opacity: 0;
`;

const Container = styled.div`
  position: relative;
  margin: 0 12px 5px;
  padding: 10px 60px 10px 75px;
  border-radius: 6px;
  border: 1px dashed transparent;
  ${props =>
    props.previewing
      ? css`
          padding: 10px 23px 10px 40px;
          cursor: pointer;
          &:hover {
            border-color: #60b9e1;
            ${EditIcon} {
              opacity: 1;
            }
          }
        `
      : css`
          border-color: #60b9e1;
        `};
`;

const Number = styled.div`
  position: absolute;
  top: 25px;
  left: 0;
  width: 60px;
  padding: 2px 12px;
  background: #f1f9fc;
  text-align: right;
  color: rgba(74, 86, 101, 0.87);
  font-size: 16px;
  font-weight: 500;
  counter-increment: question-counter;
  &:before {
    content: counter(question-counter);
  }
`;

export default class EditableContainer extends React.PureComponent<
  {
    previewContent: React.Node,
    editContent: React.Node,
    onSave: () => boolean,
    onChangeEditing?: (editing: boolean) => void,
    showIteration?: boolean,
    defaultPreviewing: boolean,
    className?: string,
  },
  {
    previewing: boolean,
  },
> {
  state = {
    previewing: this.props.defaultPreviewing,
  };

  container: React.createRef<typeof Container> = React.createRef();

  componentDidMount() {
    if (this.props.onChangeEditing) {
      this.props.onChangeEditing(!this.state.previewing);
    }
  }

  handleClickOut = () => {
    if (this.state.previewing) return;
    if (
      !window.getSelection().isCollapsed &&
      this.container.current &&
      this.container.current.contains(window.getSelection().anchorNode)
    )
      return;
    if (this.props.onSave()) {
      this.setState({ previewing: true }, () => {
        if (this.props.onChangeEditing) {
          this.props.onChangeEditing(false);
        }
      });
    }
  };

  handleEdit = () => {
    this.setState({ previewing: false }, () => {
      if (this.props.onChangeEditing) {
        this.props.onChangeEditing(true);
      }
    });
  };

  render() {
    return (
      <ClickOut onClickOut={this.handleClickOut}>
        <Container
          ref={this.container}
          previewing={this.state.previewing}
          onClick={this.state.previewing ? this.handleEdit : undefined}
          className={this.props.className}
        >
          {this.state.previewing && (
            <EditIcon>
              <i className="fa fa-fw fa-pencil" />
            </EditIcon>
          )}
          {this.props.showIteration && !this.state.previewing && <Number />}
          {this.state.previewing ? this.props.previewContent : this.props.editContent}
        </Container>
      </ClickOut>
    );
  }
}
