/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineCheckBoxField from 'components/budget/Table/InlineCheckBoxField';

import type { ContactEmailOptIn_contact } from './__generated__/ContactEmailOptIn_contact.graphql';

class ContactEmailOptIn extends React.Component<{
  contact: ContactEmailOptIn_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  handleUpdate = (value: ?boolean) => {
    if (value !== this.props.contact.emailOptIn) {
      this.props.onUpdate({ emailOptIn: value });
      this.props.updateColumnWidth();
    }
  };

  render() {
    const { contact, fieldSettings } = this.props;
    return (
      <InlineCheckBoxField
        value={contact.emailOptIn}
        onChange={this.handleUpdate}
        disabled={fieldSettings.required || fieldSettings.restrictChangingValue}
      />
    );
  }
}

export default createFragmentContainer(
  ContactEmailOptIn,
  graphql`
    fragment ContactEmailOptIn_contact on Contact {
      emailOptIn
    }
  `,
);
