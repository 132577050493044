/* @flow */
import React from 'react';
import styled from 'styled-components';

import Button from 'components/budget/Button';
import Overlay from 'components/material/Overlay';

import { type SharedEventListType } from '.';

const Root = styled.div`
  margin-right: 10px;
`;

const StyledOverlay = styled(Overlay)`
  width: auto;
  margin-top: 5px;
  padding: 5px 0;
`;

const Row = styled.div`
  padding: 6px 15px;
  font-size: 14px;
  color: rgba(74, 86, 101, 0.87);
  cursor: pointer;

  &:hover {
    background: rgba(59, 169, 218, 0.07);
  }
`;

export default class RequestEventButton extends React.PureComponent<
  { eventList: SharedEventListType },
  { showOverlay: boolean },
> {
  state = {
    showOverlay: false,
  };

  containerRef = React.createRef();

  targetRef = React.createRef();

  handleShowOverlay = () => {
    if (this.props.eventList.actionsRequestForms.length > 1) {
      this.setState({ showOverlay: true });
    } else {
      window.open(this.props.eventList.actionsRequestForms[0].shareLink);
    }
  };

  handleHideOverlay = () => {
    this.setState({ showOverlay: false });
  };

  render() {
    const forms = this.props.eventList.actionsRequestForms;

    if (forms.length === 0) return null;

    return (
      <Root ref={this.containerRef.current}>
        <Button primary ref={this.targetRef} onClick={this.handleShowOverlay}>
          Request an Event
        </Button>

        <StyledOverlay
          container={this.containerRef.current}
          target={this.targetRef.current}
          show={this.state.showOverlay}
          onHide={this.handleHideOverlay}
        >
          {forms.map(form => (
            <Row key={form.shareLink} onClick={() => window.open(form.shareLink)}>
              {form.name}
            </Row>
          ))}
        </StyledOverlay>
      </Root>
    );
  }
}
