/* flow */
import { BUTTON_VARIANTS } from 'components/budget/Button/DefaultButton';

export type RegistrationUIPropsType = {
  headerBackground?: string,
  leftPanelBackground?: string,
  pageComponentHoverBorder?: string,
  publishButtonBackground?: string,
  publishButtonColor?: string,
  publishButtonCopy?: string,
  liveButtonBackground?: string,
  liveButtonColor?: string,
  liveButtonCopy?: string,
  saveChangesButtonBackground?: string,
  saveChangesButtonColor?: string,
  saveChangesButtonCopy?: string,
  discardChangesButtonBackground?: string,
  discardChangesButtonColor?: string,
  discardChangesButtonCopy?: string,
  createTabLabel?: string,
  settingsTabLabel?: string,
  tabColor?: string,
  tabUnderlineColor?: string,
  activeTabColor?: string,
  activeTabUnderlineColor?: string,
  pageWidth?: number,
  pageWidthMeasurement?: string,
  pageBackgroundColor?: string,
  formWidth?: number,
  formWidthMeasurement?: string,
  formAlignment?: string,
  fontFamily?: string,
  formFontFamily?: string,
  formFontStyle?: string,
  formFontColor?: string,
  formButtonBackground?: string,
  formButtonColor?: string,
  formButtonCopy?: string,
  loaderColor?: string,
};

const registrationUIDefaultProps: RegistrationUIPropsType = {
  headerBackground: '#373951',
  leftPanelBackground: '#eef0ff',
  pageComponentHoverBorder: '#6b80f9',
  publishButtonBackground: '#929292',
  publishButtonColor: '#ffffff',
  publishButtonCopy: 'Draft',
  liveButtonBackground: '#29cc9a',
  liveButtonColor: '#ffffff',
  liveButtonCopy: 'Live',
  saveChangesButtonBackground: BUTTON_VARIANTS.primary.base,
  saveChangesButtonColor: BUTTON_VARIANTS.primary.text,
  saveChangesButtonCopy: 'Save',
  discardChangesButtonBackground: BUTTON_VARIANTS.cancel.base,
  discardChangesButtonColor: BUTTON_VARIANTS.cancel.text,
  discardChangesButtonCopy: 'Discard',
  createTabCopy: 'Create',
  settingsTabCopy: 'Settings',
  logicTabCopy: 'Logic',
  tabColor: '#c2c2c2',
  tabUnderlineColor: 'transparent',
  activeTabColor: '#ffffff',
  activeTabUnderlineColor: '#3ba9da',
  pageWidth: 100,
  pageWidthMeasurement: 'PERCENTAGE',
  pageBackgroundColor: 'transparent',
  formWidth: 700,
  formWidthMeasurement: 'PX',
  formAlignment: 'CENTER',
  fontFamily: 'Roboto',
  formFontFamily: 'Roboto',
  formFontStyle: 'normal',
  formFontColor: '#3e4859',
  formButtonBackground: '#5e5cb7',
  formButtonColor: '#ffffff',
  formButtonCopy: 'Register',
  loaderColor: '#6f6cd8',
};

export const registrationFormPropsMapping = {
  pageBackgroundColor: 'backgroundColor',
  pageWidth: 'width',
  pageWidthMeasurement: 'widthMeasurement',
};

export const formComponentPropsMapping = {
  formWidth: 'width',
  formWidthMeasurement: 'widthMeasurement',
  formAlignment: 'alignment',
  formFontFamily: 'fontFamily',
  formFontStyle: 'fontStyle',
  formFontColor: 'color',
  formButtonBackground: 'buttonColor',
  formButtonColor: 'buttonTextColor',
  formButtonCopy: 'buttonCopy',
};

export default registrationUIDefaultProps;
