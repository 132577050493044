/* @flow */

export type BriefSharingGraphValue = 'DISABLED' | 'MEMBERS' | 'PUBLIC' | 'SSO';

export type BriefSharingOptionType = {|
  +label: string,
  +description: string,
  +value: BriefSharingGraphValue,
|};

const eventBriefSharingOptions: $ReadOnlyArray<BriefSharingOptionType> = [
  {
    label: 'Public',
    description: 'Anyone with the link can view the brief.',
    value: 'PUBLIC',
  },
  {
    label: 'Anyone at %s',
    description:
      'Anyone at %s can view the brief. <a href="https://help.circa.co/102309-single-sign-on-sso/how-do-i-set-up-single-sign-on-sso" target="_blank" rel="noreferrer noopener">Single sign-on</a> (SSO/SAML) required. <a href="mailto:success@circa.co" target="_blank" rel="noreferrer noopener">Contact us</a> to enable.',
    value: 'SSO',
  },
  {
    label: 'Workspace Members',
    description: 'Only signed in Workspace Members can view the brief.',
    value: 'MEMBERS',
  },
  {
    label: 'Disabled',
    description: 'The brief is not shared.',
    value: 'DISABLED',
  },
];

export default eventBriefSharingOptions;
