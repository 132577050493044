/* @flow */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';

import { type DateConfig } from 'components/date/DateTimePicker';
import DateTimeOverlay from 'components/date/DateTimePicker/DateTimeOverlay';
import RemovablePill from 'components/material/RemovablePill';

const Container = styled.div`
  margin: 0 10px 20px 10px;
`;

const Pill = styled.div`
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 13px;
  box-shadow: ${props => (props.highlight ? 'inset 0 0 0 1px #fbc781, 0 0 0 2px #ffddaf' : 'none')};
  color: ${props => (props.highlight ? '#63605c' : '#4a5665')};
  background: transparent;
  cursor: pointer;

  i {
    margin-right: 6px;
    font-size: 12px;
    color: #ffc376;
  }

  &:hover {
    background: ${props => (props.highlight ? '#fffaf1' : '#f8f8f9')};
  }
`;

export default class TaskReminder extends React.PureComponent<
  {
    tz: string,
    remindAt: ?string,
    disabled: boolean,
    completed: boolean,
    onChange: (remindAt: ?string) => void,
    onSectionRemove: 'reminder' => void,
  },
  {
    showOverlay: boolean,
  },
> {
  state = {
    showOverlay: false,
  };

  containerRef = React.createRef();

  componentDidMount() {
    if (!this.props.remindAt) {
      this.handleShowOverlay();
    }
  }

  handleRemove = () => {
    this.props.onChange(null);
    this.props.onSectionRemove('reminder');
  };

  handleShowOverlay = () => {
    if (!this.props.disabled) {
      this.setState({ showOverlay: true });
    }
  };

  handleHideOverlay = () => {
    this.setState({ showOverlay: false });

    if (!this.props.remindAt) {
      this.props.onSectionRemove('reminder');
    }
  };

  handleChangeDate = ({ date }: DateConfig) => {
    if (date) {
      this.props.onChange(moment.tz(date, this.props.tz).format(moment.HTML5_FMT.DATE));
    } else {
      this.handleRemove();
    }
  };

  render() {
    const remindAt = this.props.remindAt && moment.tz(this.props.remindAt, this.props.tz);
    const highlight =
      !this.props.completed && (!remindAt || remindAt.isSameOrAfter(moment(), 'date'));

    return (
      <Container ref={this.containerRef}>
        <RemovablePill disabled={this.props.disabled} onRemove={this.handleRemove}>
          <Pill onClick={this.handleShowOverlay} highlight={highlight}>
            <i className="fa fa-bell" />
            {remindAt ? formatDate(remindAt) : 'Add Reminder'}
          </Pill>
        </RemovablePill>

        <DateTimeOverlay
          show={this.state.showOverlay}
          target={this.containerRef.current}
          container={this.containerRef.current}
          onHide={this.handleHideOverlay}
          tz={this.props.tz}
          onChange={this.handleChangeDate}
          date={remindAt && remindAt.format()}
          hideTime
          dateOnly
        />
      </Container>
    );
  }
}
