/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import ClearIcon from 'components/ClearIcon';
import TextInput from 'components/material/TextInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 0 0 10px;
  margin-bottom: 5px;
`;

const TextWrapper = styled.div`
  display: flex;
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  flex: 1 1 auto;
  margin-top: 10px;
  padding: 0 27px 0 0;
`;

const ItemsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.primaryRowColor};
  margin-right: 10px;
  padding: 6px 10px 6px 17px;
  margin-top: 10px;
`;

const Item = styled.div`
  flex: auto;
  font-size: 14px;
  color: ${props => props.theme.infoMenuHoverColor};
`;

const RemoveItemIcon = styled.i`
  margin-left: 5px;
  color: #96a2ab;
  cursor: pointer;
`;

export default class TextFilterBody extends React.Component<
  {
    name: string,
    activeValues: ?$ReadOnlyArray<string>,
    onChange: (string, ?$ReadOnlyArray<string>) => void,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ query: e.currentTarget.value });
  };

  handleClear = () => {
    this.setState({ query: '' });
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      e.currentTarget.value &&
      !this.activeValues().includes(e.currentTarget.value)
    ) {
      this.props.onChange(this.props.name, [...this.activeValues(), e.currentTarget.value]);
      this.handleClear();
    }
  };

  handleClearItem = (value: string) => {
    this.props.onChange(
      this.props.name,
      this.activeValues().filter(v => v !== value),
    );
  };

  activeValues(): $ReadOnlyArray<string> {
    return this.props.activeValues || [];
  }

  render() {
    return (
      <Container>
        <TextWrapper>
          <StyledTextInput
            placeholder="Search..."
            autoFocus
            value={this.state.query}
            onChange={this.handleQueryChange}
            onKeyDown={this.handleKeyDown}
          />
          {this.state.query && <ClearIcon className="fa fa-times" onClick={this.handleClear} />}
        </TextWrapper>
        <ItemsWrapper>
          {sortBy(this.activeValues()).map(value => (
            <ItemContainer key={value}>
              <Item>{value}</Item>
              <RemoveItemIcon className="fa fa-times" onClick={() => this.handleClearItem(value)} />
            </ItemContainer>
          ))}
        </ItemsWrapper>
      </Container>
    );
  }
}
