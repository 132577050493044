/* @flow */
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const progress = keyframes`
  0% {
    margin-left: 0;
    margin-right: 100%;
  }
  50% {
    margin-left: 25%;
    margin-right: 0;
  }
  100% {
    margin-left: 100%;
    margin-right: 0;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 4px;
  overflow: hidden;
  border-radius: 2px;
  background-color: rgba(205, 209, 212, 0.6) !important;
`;

const Progress = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.4s linear;
  transform-origin: left;
  transform: scaleX(${props => props.value});
  border-radius: 2px;
  background-color: #3ba9da;
  ${props =>
    props.indeterminate &&
    css`
      position: static;
      transform: none;
      animation: ${progress} 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    `};
`;

export default class ProgressBar extends React.PureComponent<{
  className?: string,
  value?: number,
  indeterminate?: boolean,
}> {
  render() {
    return (
      <Container className={this.props.className}>
        <Progress value={(this.props.value || 0) / 100} indeterminate={this.props.indeterminate} />
      </Container>
    );
  }
}
