/* @flow */
import sortBy from 'lodash/sortBy';

import { type ResourceFieldType } from '../MarketoLeadMappings';
import customFieldsTypes from './customFieldsTypes';

import { type MarketoLeadMappingsQueryResponse } from '../__generated__/MarketoLeadMappingsQuery.graphql';

const fieldTypes = field => {
  if (field.fieldName == null) {
    return customFieldsTypes[field.kind];
  }

  return {
    contact_type_id: ['string'],
    title: ['string'],
    owner_id: ['email'],
    website: ['url'],
    phone1: ['phone'],
    phone2: ['phone'],
    street: ['string', 'text'],
    city: ['string'],
    state: ['string'],
    zip: ['string'],
    country: ['string'],
    twitter: ['url'],
    linkedin: ['url'],
    description: ['text'],
    hot_lead: ['boolean'],
    email_opt_in: ['boolean'],
  }[field.fieldName];
};

const contactFields = (
  org: $PropertyType<MarketoLeadMappingsQueryResponse, 'org'>,
): $ReadOnlyArray<ResourceFieldType> => {
  const contactCustomFields = sortBy(
    org.customFields.edges.map(edge => edge.node),
    field => field.order,
  );

  return [
    {
      label: 'Email',
      name: 'email',
      types: ['email'],
      fixedMappingField: 'email',
    },
    {
      label: 'First Name',
      name: 'first_name',
      types: ['string'],
      fixedMappingField: 'firstName',
    },
    {
      label: 'Last Name',
      name: 'last_name',
      types: ['string'],
      fixedMappingField: 'lastName',
    },
    {
      label: 'Company',
      name: 'company',
      types: ['string'],
      fixedMappingField: 'company',
    },
    {
      label: 'Lead Source',
      name: 'lead_source',
      types: ['string'],
      fixedMappingField: 'leadSource',
      fixedMappingCanDisable: true,
    },
    ...contactCustomFields
      .filter(field => field.fieldName !== 'company_id')
      .map(field => ({
        label: field.label,
        name: field.fieldName,
        customFieldId: field.id,
        types: fieldTypes(field),
      })),
  ];
};

export default contactFields;
