/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import {
  BriefUserAvatar,
  BriefUserEmail,
  BriefUserName,
  BriefUserPhone,
  BriefUserTag,
  BriefUserTitleCompany,
} from './BriefUser';
import BriefUserOnsite from './BriefUserOnsite';

import { type UserField_event } from './__generated__/UserField_event.graphql';

type User = {
  id: string,
  firstName: string,
  lastName: string,
  email: ?string,
  phone: ?string,
  officePhone: ?string,
  title: ?string,
  company: ?string | Object,
};

type Staffer = ?{|
  +onsite?: boolean,
  +user: {|
    +id: string,
  |},
|};

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const UserField = (props: {
  event: UserField_event,
  user: User,
  avatarRadius: number,
  className?: string,
  organizer?: boolean,
}) => {
  const {
    user,
    className,
    organizer,
    user: { firstName, lastName, title, company, email, phone, officePhone },
    avatarRadius,
    event,
  } = props;
  const lead = event && event.lead;
  const staffers = event && event.staffers;

  const staffer: Staffer =
    staffers && staffers.edges.map(({ node }) => node).find(node => user.id === node.user.id);

  return (
    <Content className={className}>
      <BriefUserAvatar user={user} radius={avatarRadius} />
      <div>
        <BriefUserName firstName={firstName} lastName={lastName} />
        {organizer && <BriefUserTag tag="organizer" />}
        {lead && lead.id === user.id && <BriefUserTag tag="leader" />}
        {staffer && <BriefUserOnsite onsite={staffer.onsite} />}
        <BriefUserTitleCompany title={title} company={company} />
        <BriefUserEmail email={email} />
        <BriefUserPhone phone={phone} />
        <BriefUserPhone phone={officePhone} />
      </div>
    </Content>
  );
};

export default createFragmentContainer(
  UserField,
  graphql`
    fragment UserField_event on Event {
      lead {
        id
      }
      staffers {
        edges {
          node {
            onsite @include(if: $includeStaffOnsite)
            user {
              id
            }
          }
        }
      }
    }
  `,
);
