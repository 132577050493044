/* @flow */
/* eslint-disable react/no-danger */
import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  a {
    cursor: pointer;
    color: ${props => props.theme.primaryActionColor};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default class RichTextRenderer extends React.PureComponent<{
  children?: ?any,
}> {
  render() {
    return (
      <Wrapper>
        {this.props.children}
      </Wrapper>
    );
  }
}
