/**
 * @flow
 * @relayHash 27b008bf62f8d1571fd10691b9f0ba55
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditableQuestionPlannedBudgetTotalOptionsQueryVariables = {||};
export type EditableQuestionPlannedBudgetTotalOptionsQueryResponse = {|
  +org: {|
    +budgetCategories: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
        |}
      |}>
    |}
  |}
|};
export type EditableQuestionPlannedBudgetTotalOptionsQuery = {|
  variables: EditableQuestionPlannedBudgetTotalOptionsQueryVariables,
  response: EditableQuestionPlannedBudgetTotalOptionsQueryResponse,
|};
*/


/*
query EditableQuestionPlannedBudgetTotalOptionsQuery {
  org {
    budgetCategories {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "budgetCategories",
  "storageKey": null,
  "args": null,
  "concreteType": "BudgetCategoryRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetCategoryRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "BudgetCategory",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditableQuestionPlannedBudgetTotalOptionsQuery",
  "id": null,
  "text": "query EditableQuestionPlannedBudgetTotalOptionsQuery {\n  org {\n    budgetCategories {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditableQuestionPlannedBudgetTotalOptionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditableQuestionPlannedBudgetTotalOptionsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0349da767fe23e6f132f71bf96887a89';
module.exports = node;
