/* @flow */

const budgetCategoryColors = [
  '#00c853',
  '#0092dd',
  '#e65100',
  '#6a1b9a',
  '#c2185b',
  '#536dfe',
  '#ba68c8',
  '#00bcd4',
  '#880e4f',
  '#2e7d32',
  '#db5b52',
  '#8170e4',
  '#afb42b',
  '#03819b',
  '#f57f17',
  '#0d0a4f',
  '#00bfa5',
  '#f06292',
  '#607d8b',
  '#ffb300',
];

export default budgetCategoryColors;
