/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';

import NotificationsList from 'components/NotificationsList';

import type { TaskActivities_task } from './__generated__/TaskActivities_task.graphql';

class TaskActivities extends React.PureComponent<{
  task: TaskActivities_task,
  relay: {
    hasMore: () => boolean,
    isLoading: () => boolean,
    loadMore: (number, cb: (?Error) => void) => void,
  },
}> {
  handleLoadMore = () => {
    if (this.props.relay.isLoading()) {
      return Promise.resolve();
    }
    return new Promise(resolve =>
      this.props.relay.loadMore(10, () => {
        resolve();
      }),
    );
  };

  render() {
    const activities = this.props.task.activities.edges.map(edge => edge.node);

    return (
      <div>
        <NotificationsList
          timezone={this.props.task.event.tz}
          notifications={activities}
          hasMore={this.props.relay.hasMore()}
          loadMore={this.handleLoadMore}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  TaskActivities,
  {
    task: graphql`
      fragment TaskActivities_task on Deliverable {
        slug
        activities(first: $count, after: $cursor) @connection(key: "TaskActivities_activities") {
          edges {
            node {
              id
              ...NotificationsList_notifications
            }
          }
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
        }
        event {
          tz
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.task && props.task.activities;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
        taskSlug: props.task.slug,
      };
    },
    query: graphql`
      query TaskActivitiesPaginationQuery($count: Int!, $cursor: String, $taskSlug: String!) {
        deliverable(slug: $taskSlug) {
          ...TaskActivities_task
        }
      }
    `,
  },
);
