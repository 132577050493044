/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import sanitizeHtml from 'utils/string/sanitizeHtml';

import EmbedIcon from 'images/registration/embed.svg';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import generatePaddingProps from '../generatePaddingProps';

import type { EmbedComponent_componentProps } from './__generated__/EmbedComponent_componentProps.graphql';

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${props => props.componentProps.backgroundColor || 'initial'};
  text-align: ${props => props.componentProps.alignment};
  overflow-x: auto;
`;

const StyledIHtml = styled.div`
  max-width: 100%;
  margin: ${props => generatePaddingProps(props.componentProps.padding)};
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
`;

const AddEmbedButton = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledEmbedIcon = styled(EmbedIcon)`
  margin-right: 15px;
  color: #3ba9da;
`;

const Text = styled.div`
  color: #3ba9da;
`;

class EmbedComponent extends React.Component<{
  componentProps: EmbedComponent_componentProps,
  selectedComponent: SelectedComponent,
  readOnly?: boolean,
}> {
  render() {
    const {
      selectedComponent,
      readOnly,
      componentProps: { embedComponent },
    } = this.props;

    if (!embedComponent) {
      return null;
    }

    const componentProps = selectedComponent
      ? {
          ...embedComponent,
          padding: selectedComponent.selectedComponentPadding,
        }
      : embedComponent;
    const content = (
      <Container componentProps={embedComponent}>
        <StyledIHtml
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(embedComponent.code, {
              extendedTags: ['img', 'video', 'audio', 'source', 'iframe'],
              extendedAttrs: [
                'title',
                'autoplay',
                'controls',
                'frameborder',
                'allow',
                'allowfullscreen',
              ],
              disallowedTagsMode: 'discard',
            }),
          }}
          componentProps={componentProps}
        />
      </Container>
    );
    if (readOnly) {
      return content;
    }
    return (
      <>
        {embedComponent.code ? (
          content
        ) : (
          <EmptyContainer>
            <AddEmbedButton>
              <StyledEmbedIcon />
              <Text>Add Embed Code</Text>
            </AddEmbedButton>
          </EmptyContainer>
        )}
      </>
    );
  }
}

export default createFragmentContainer(EmbedComponent, {
  componentProps: graphql`
    fragment EmbedComponent_componentProps on RegistrationPageComponent {
      embedComponent {
        padding
        code
        alignment
        backgroundColor
      }
    }
  `,
});
