/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ExpenseForm_expense$ref = any;
type Payments_expense$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpensePageWrapper_expense$ref: FragmentReference;
export type ExpensePageWrapper_expense = {|
  +$fragmentRefs: ExpenseForm_expense$ref & Payments_expense$ref,
  +$refType: ExpensePageWrapper_expense$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExpensePageWrapper_expense",
  "type": "Expense",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ExpenseForm_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Payments_expense",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '087d790e118b763d0ddc7e917a09f799';
module.exports = node;
