/* @flow */
import * as React from 'react';
import { createPortal } from 'react-dom';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import theme from 'config/theme';

/* eslint-disable import/no-webpack-loader-syntax */
// $FlowFixMe
import fontStyles from '!!to-string-loader!css-loader!postcss-loader!sass-loader!../../styles/fonts.scss';
// $FlowFixMe
import appStyles from '!!to-string-loader!css-loader!postcss-loader!stylus-loader!../../app.styl';
/* eslint-enable import/no-webpack-loader-syntax */

const globalStyles = `
  ${appStyles}
  ${fontStyles}

  :root {
    /* Enable scroll in iframe */
    overflow: auto;
  }
`;

export type WindowType = $Call<window.open>;

class Frame extends React.PureComponent<{
  window: WindowType,
  children: React.Node,
}> {
  componentDidMount() {
    const metas = document.getElementsByTagName('meta');

    // IE11 and old Edge doesn't allow appending parent window element, cloning doesn't work either
    // you should create elements in window. If IE11 and old Edge deprecated append child logic
    // with innerHTML should be changed
    const styles = this.props.window.document.createElement('style');
    styles.innerHTML = globalStyles;
    this.props.window.document.head.appendChild(styles);

    [...metas].forEach(meta => {
      const childWindowMeta = this.props.window.document.createElement('meta');
      [...meta.attributes].forEach(item => {
        childWindowMeta.setAttribute(item.name, item.value);
      });
      this.props.window.document.head.appendChild(childWindowMeta);
    });
  }

  render() {
    return createPortal(
      <StyleSheetManager target={this.props.window.document.head}>
        <ThemeProvider theme={theme}>{this.props.children}</ThemeProvider>
      </StyleSheetManager>,
      this.props.window.document.body,
    );
  }
}

export default Frame;
