/* @flow */

import updateExpense, {
  type Expense as MutationExpense,
} from 'graph/mutations/expense/updateExpense';

import ExpenseActionCell from './ExpenseActionCell';
import ExpenseActualAmountCell from './ExpenseActualAmountCell';
import ExpenseActualAmountFooterCell from './ExpenseActualAmountFooterCell';
import ExpenseBudgetCategoryCell from './ExpenseBudgetCategoryCell';
import ExpenseNameCell from './ExpenseNameCell';
import ExpenseNoteCell from './ExpenseNoteCell';
import ExpensePaymentStatusCell from './ExpensePaymentStatusCell';
import ExpenseTeamSplitCell from './ExpenseTeamSplitCell';
import ExpenseVendorCell from './ExpenseVendorCell';

export type ExpenseFooterCellPropsType<Event: {} = {}, Org: {} = {}> = {|
  org: Org,
  event: Event,
|};

export type ExpenseCellPropsType<Expense: {}, Event: {} = {}, Org: {} = {}> = {|
  org: Org,
  event: Event,
  expense: Expense,
  updateExpense: MutationExpense => $Call<typeof updateExpense, string, MutationExpense>,
|};

const columns = [
  {
    title: 'Expense',
    sortKey: 'NAME',
    component: ExpenseNameCell,
    grow: 1,
  },
  {
    title: 'Category',
    sortKey: 'BUDGET_CATEGORY',
    component: ExpenseBudgetCategoryCell,
    footerComponent: () => 'Total',
  },
  {
    title: 'Vendor',
    sortKey: 'VENDOR_COMPANY',
    component: ExpenseVendorCell,
  },
  {
    title: 'Amount',
    sortKey: 'ACTUAL_AMOUNT',
    component: ExpenseActualAmountCell,
    footerComponent: ExpenseActualAmountFooterCell,
    align: 'right',
  },
  {
    title: 'Payment Status',
    sortKey: 'PAYMENT_STATUS',
    component: ExpensePaymentStatusCell,
  },
  {
    title: 'Split by Team',
    component: ExpenseTeamSplitCell,
  },
  {
    title: 'Note',
    sortKey: 'NOTE',
    component: ExpenseNoteCell,
  },
  {
    title: '',
    component: ExpenseActionCell,
    type: 'action',
  },
];

export default columns;
