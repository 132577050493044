/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled from 'styled-components';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
} from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { defaultTableComponents } from 'components/Opportunities/columns';
import getOpportunityFields from 'components/Opportunities/lib/getOpportunityFields';
import getShownColumns from 'components/Opportunities/lib/getShownColumns';
import parseOpportunitiesFilters from 'components/Opportunities/lib/parseOpportunitiesFilters';
import colsConfig, {
  type InputVariableFilters,
} from 'components/Opportunities/OpportunitiesTableColumnSettings';

import CompanyOpportunitiesHeaderBar from './CompanyOpportunitiesHeaderBar';
import CompanyOpportunitiesTablePagination from './CompanyOpportunitiesTablePagination';

import type { CompanyOpportunities_org } from './__generated__/CompanyOpportunities_org.graphql';
import type { CompanyOpportunitiesQueryResponse } from './__generated__/CompanyOpportunitiesQuery.graphql';

const Container = styled.div`
  margin: 25px 0;
`;

const query = graphql`
  query CompanyOpportunitiesQuery(
    $companyId: ID!
    $count: Int!
    $cursor: String
    $filters: OpportunityFilters!
    $includeAmount: Boolean!
    $includeCompanyId: Boolean!
    $includeStatus: Boolean!
    $includeDescription: Boolean!
    $includeSalesforceId: Boolean!
    $includeOwnerId: Boolean!
    $includeAssociatedContacts: Boolean!
    $includeName: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
  ) {
    me {
      tz
    }
    org {
      id
      ...CompanyOpportunitiesTablePagination_org
    }
    company: node(id: $companyId) {
      id
      ...CompanyOpportunitiesTablePagination_company
      ...CompanyOpportunitiesTablePagination_totalCountCompany
    }
  }
`;

class CompanyOpportunities extends React.Component<
  {
    companyId: string,
    org: CompanyOpportunities_org,
    location: Location,
    history: History,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
  },
> {
  state = {
    shownColumns: getShownColumns('companyOpportunitiesShownColumns'),
  };

  opportunityFields: $ReadOnlyArray<FieldType> = getOpportunityFields(
    normalizeCustomFields(this.props.org.opportunityCustomFields.edges.map(edge => edge.node)),
    this.props.org.salesforceAccount
      ? this.props.org.salesforceAccount.opportunityRoles.edges.map(({ node }) => ({
          value: node.id,
          label: node.name,
        }))
      : [],
    this.props.org.salesforceAccount
      ? this.props.org.salesforceAccount.opportunityStatuses.edges.map(({ node }) => ({
          value: node.id,
          label: node.name,
        }))
      : [],
  ).filter(field => field.fieldName !== 'company_id');

  handleColumnsChange = (shownColumns: $ReadOnlyArray<string>) => {
    storage.set('companyOpportunitiesShownColumns', shownColumns);
    this.setState({ shownColumns });
  };

  handleChangeSort = (sort: SortParam) =>
    replaceSortQueryParam(this.props.history, sort, 'opportunitySort');

  sort = () =>
    parseTypedQueryString(this.props.location.search, {
      opportunitySort: stringParamToSort,
    }).opportunitySort || {
      key: 'NAME',
      asc: true,
    };

  renderTable = (props: {|
    me: ?$PropertyType<CompanyOpportunitiesQueryResponse, 'me'>,
    org: ?$PropertyType<CompanyOpportunitiesQueryResponse, 'org'>,
    company: ?$PropertyType<CompanyOpportunitiesQueryResponse, 'company'>,
    filtered: boolean,
    filters: InputVariableFilters,
    includedColumns: { [string]: boolean },
  |}): React.Node => (
    <CompanyOpportunitiesTablePagination
      org={props.org}
      company={props.company}
      shownColumns={colsConfig(this.opportunityFields, this.state.shownColumns)}
      filtered={props.filtered}
      totalCountCompany={props.company}
      sort={this.sort()}
      onChangeSort={this.handleChangeSort}
      tz={props.me ? props.me.tz : 'UTC'}
      includedColumns={props.includedColumns}
    />
  );

  render() {
    const { location, history } = this.props;
    const shownColumns = this.state.shownColumns;
    const sort = this.sort();

    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      this.opportunityFields,
      shownColumns,
    );

    const filters = parseOpportunitiesFilters(location.search, this.opportunityFields);
    const filtered = Object.keys(filters).some(key => key !== 'sort' && !!filters[key]);
    const customSortField: ?FieldType = this.opportunityFields.find(field => field.id === sort.key);

    const variables = {
      count: 25,
      companyId: this.props.companyId,
      ...includedColumns,
      filters: {
        sort: customSortField ? 'CUSTOM' : sort.key,
        customFieldSortId: customSortField != null ? customSortField.id : null,
        direction: sort.asc ? 'ASC' : 'DESC',
        names: filters.name,
        amountRange: filters.amount,
        salesforceIds: filters.salesforce_id,
        statusIds: filters.status,
        ownerIds: filters.owner_id,
        search: filters.search,
        associatedContactsIds: filters.associated_contacts,
        associatedContactsRoleIds: filters.associated_contacts_roles,
        customFilters: this.opportunityFields
          .filter(option => option.fieldName == null && filters[option.id] != null)
          .map(option => getCustomFieldVariable(filters[option.id], option)),
      },
    };

    return (
      <Container>
        <CompanyOpportunitiesHeaderBar
          history={history}
          shownColumns={shownColumns}
          filters={filters}
          opportunityFields={this.opportunityFields}
          onColumnsChange={this.handleColumnsChange}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: CompanyOpportunitiesQueryResponse) =>
            this.renderTable({
              me: props.me,
              org: props.org,
              company: props.company,
              filtered,
              filters: variables.filters,
              includedColumns,
            })
          }
          renderLoading={() =>
            this.renderTable({
              me: null,
              org: null,
              company: null,
              filtered,
              filters: variables.filters,
              includedColumns,
            })
          }
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  CompanyOpportunities,
  graphql`
    fragment CompanyOpportunities_org on Org {
      id
      salesforceAccount {
        opportunityRoles {
          edges {
            node {
              id
              name
            }
          }
        }
        opportunityStatuses(onlyEnabled: true) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      opportunityCustomFields: customFields(customizableType: [SALESFORCEOPPORTUNITY]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
);
