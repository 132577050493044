/* @flow */
import * as React from 'react';
import moment from 'moment-timezone';

type Props = {
  expiresAt: ?string,
};

const ActiveTrialMessage = ({ expiresAt }: Props) => {
  const expireDays = expiresAt ? moment(expiresAt).diff(moment(), 'days') + 1 : null;

  return (
    <React.Fragment>
      Your free trial{' '}
      {expireDays !== null ? (
        <React.Fragment>
          expires in{' '}
          <strong>
            {expireDays < 0 ? 0 : expireDays} day
            {expireDays !== 1 ? 's' : ''}
          </strong>
          . Upgrade today so you don&#39;t lose access.
        </React.Fragment>
      ) : (
        'is active indefinitely.'
      )}
    </React.Fragment>
  );
};

export default ActiveTrialMessage;
