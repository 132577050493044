/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Match } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';

import UIContext from '../UIContext';
import RegistrationLogicRefetchContainer from './RegistrationLogicRefetchContainer';

import type { RegistrationLogicQueryResponse } from './__generated__/RegistrationLogicQuery.graphql';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 10px 0;
`;

const query = graphql`
  query RegistrationLogicQuery($eventSlug: String, $isEventRegForm: Boolean!) {
    me {
      tz
    }
    ...RegistrationLogicRefetchContainer_query
  }
`;

export default class RegistrationLogic extends React.Component<{
  match: Match,
  discardCounter?: number,
}> {
  render() {
    const { match, discardCounter } = this.props;
    const eventSlug = match.params.eventSlug;
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ eventSlug, isEventRegForm: !!eventSlug }}
        renderSuccess={(response: RegistrationLogicQueryResponse) => {
          return (
            <RegistrationLogicRefetchContainer
              query={response}
              match={match}
              discardCounter={discardCounter}
              userTz={response.me.tz}
            />
          );
        }}
        renderLoading={() => {
          return (
            <UIContext.Consumer>
              {({ loaderColor }) => (
                <LoaderContainer>
                  <Loader color={loaderColor} size={30} />
                </LoaderContainer>
              )}
            </UIContext.Consumer>
          );
        }}
      />
    );
  }
}
