/* @flow */
import React from 'react';

import { type UpdateCustomFieldValueInput } from 'graph/mutations/custom_field/updateCustomFieldValue';

import DefaultNumberFIeld from 'components/material/NumberField';

export default class NumberField extends React.Component<
  {
    className?: string,
    label?: string,
    defaultValue: ?number,
    onUpdate?: (input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>) => void,
    error?: ?string,
    required?: boolean,
    disabled?: boolean,
  },
  {
    error: ?string,
  },
> {
  state = {
    error: null,
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>, value: ?number) => {
    if (value != null && value.toString().length > 9) {
      this.setState({ error: 'Number fields cannot be more than 9 digits' });
      return;
    }

    if (this.props.defaultValue !== value && this.props.onUpdate) {
      this.props.onUpdate({ numberValue: value });
    }
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > 9) {
      this.setState({ error: 'Number fields cannot be more than 9 digits' });
      return;
    }
    this.setState({ error: null });
  };

  render() {
    const { label, className, required, defaultValue, error, disabled } = this.props;
    return (
      <DefaultNumberFIeld
        className={className}
        defaultValue={defaultValue}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        label={label}
        required={required}
        error={this.state.error || error}
        disabled={disabled}
      />
    );
  }
}
