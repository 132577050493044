/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { BriefSharingGraphValue } from 'config/eventBriefSharingOptions';

import FeatureAccessContext from 'contexts/FeatureAccess';

import SharingOptions from 'components/Briefs/SharingOptions';

import ActionBar from './ActionBar';
import BriefAttachments from './BriefAttachments';
import AttachmentsContainer from './BriefAttachments/AttachmentsContainer';
import BriefCompanies from './BriefCompanies';
import CompaniesContainer from './BriefCompanies/CompaniesContainer';
import BriefContacts from './BriefContacts';
import ContactsContainer from './BriefContacts/ContactsContainer';
import BriefVendors from './BriefVendors';
import VendorsContainer from './BriefVendors/VendorsContainer';
import BuilderSection from './BuilderSection';
import Notes from './Notes';

import { type BriefBuilder_event } from './__generated__/BriefBuilder_event.graphql';
import { type BriefBuilder_org } from './__generated__/BriefBuilder_org.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => (props.editing ? 350 : 0)}px;
  overflow: ${props => (props.editing ? 'visible' : 'hidden')};
  transition: 0.3s;
`;

const BuilderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 350px;
  padding-bottom: 10px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const BuilderItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  overflow: auto;
`;

const StyledSharingOptions = styled(SharingOptions)`
  padding: 0 20px;
`;

class BriefBuilder extends React.PureComponent<{
  org: BriefBuilder_org,
  event: BriefBuilder_event,
  editing: boolean,
  disableSave: boolean,
  selectedNotes: $ReadOnlyArray<string>,
  onAddNote: (id: string) => void,
  onRemoveNote: (id: string) => void,
  onAllNoteCheckToggle: (isPrevAllCheck: boolean) => void,
  selectedAttachments: $ReadOnlyArray<string>,
  onAddAttachment: (id: string) => void,
  onRemoveAttachment: (id: string) => void,
  onAllAttachmentCheckToggle: (
    isPrevAllCheck: boolean,
    searchedAttachmentIds: $ReadOnlyArray<string>,
  ) => void,
  selectedContacts: $ReadOnlyArray<string>,
  onAddContact: (id: string) => void,
  onRemoveContact: (id: string) => void,
  onAllContactCheckToggle: (
    isPrevAllCheck: boolean,
    searchedContactIds: $ReadOnlyArray<string>,
  ) => void,
  selectedCompanies: $ReadOnlyArray<string>,
  onAddCompany: (id: string) => void,
  onRemoveCompany: (id: string) => void,
  onAllCompanyCheckToggle: (
    isPrevAllCheck: boolean,
    searchedCompanyIds: $ReadOnlyArray<string>,
  ) => void,
  selectedVendors: $ReadOnlyArray<string>,
  onAddVendor: (id: string) => void,
  onRemoveVendor: (id: string) => void,
  onAllVendorCheckToggle: (
    isPrevAllCheck: boolean,
    searchedVendorIds: $ReadOnlyArray<string>,
  ) => void,
  accessibility: BriefSharingGraphValue,
  onAccessibilityChange: (accessibility: BriefSharingGraphValue) => void,
  onBriefSave: () => void,
  onBriefCancel: () => void,
}> {
  notes: ?Notes;

  contacts: ?ContactsContainer;

  vendors: ?VendorsContainer;

  companies: ?CompaniesContainer;

  attachments: ?AttachmentsContainer;

  handleAddNewNote = () => {
    if (this.notes) {
      this.notes.handleAddNewClick();
    }
  };

  handleAddNewContact = () => {
    if (this.contacts) {
      this.contacts.handleAddNewClick();
    }
  };

  handleAddNewCompany = () => {
    if (this.companies) {
      this.companies.handleAddNewClick();
    }
  };

  handleAddNewAttachment = () => {
    if (this.attachments) {
      this.attachments.handleAddNewClick();
    }
  };

  handleAddNewVendor = () => {
    if (this.vendors) {
      this.vendors.handleAddNewClick();
    }
  };

  static contextType = FeatureAccessContext;

  render() {
    const {
      event,
      editing,
      disableSave,
      selectedNotes,
      onAddNote,
      onRemoveNote,
      onAllNoteCheckToggle,
      selectedAttachments,
      onAddAttachment,
      onRemoveAttachment,
      onAllAttachmentCheckToggle,
      selectedContacts,
      onAddContact,
      onRemoveContact,
      onAllContactCheckToggle,
      selectedVendors,
      onAddVendor,
      onRemoveVendor,
      onAllVendorCheckToggle,
      selectedCompanies,
      onAddCompany,
      onRemoveCompany,
      onAllCompanyCheckToggle,
      accessibility,
      onAccessibilityChange,
      onBriefSave,
      onBriefCancel,
      org: { name, hasSamlProvider },
    } = this.props;
    return (
      <Container editing={editing}>
        <BuilderWrapper>
          <BuilderItemsContainer>
            <BuilderSection
              defaultExpanded
              label="Select Notes"
              addNewTooltip="Add new note"
              onAddNewClick={this.handleAddNewNote}
            >
              <Notes
                componentRef={el => {
                  this.notes = el;
                }}
                event={event}
                selectedNotes={selectedNotes}
                onAddNote={onAddNote}
                onRemoveNote={onRemoveNote}
                onAllNoteCheckToggle={onAllNoteCheckToggle}
              />
            </BuilderSection>
            {this.context.legacyFeatures && (
              <>
                <BuilderSection
                  label="Select Contacts"
                  addNewTooltip="Add new contact"
                  onAddNewClick={this.handleAddNewContact}
                >
                  <BriefContacts
                    containerRef={el => {
                      this.contacts = el;
                    }}
                    event={event}
                    selectedContacts={selectedContacts}
                    onAddContact={onAddContact}
                    onRemoveContact={onRemoveContact}
                    onAllContactCheckToggle={onAllContactCheckToggle}
                  />
                </BuilderSection>
                <BuilderSection
                  label="Select Companies"
                  addNewTooltip="Add new company"
                  onAddNewClick={this.handleAddNewCompany}
                >
                  <BriefCompanies
                    containerRef={el => {
                      this.companies = el;
                    }}
                    event={event}
                    selectedCompanies={selectedCompanies}
                    onAddCompany={onAddCompany}
                    onRemoveCompany={onRemoveCompany}
                    onAllCompanyCheckToggle={onAllCompanyCheckToggle}
                  />
                </BuilderSection>
              </>
            )}
            <BuilderSection
              label="Select Vendors"
              addNewTooltip="Add new vendor"
              onAddNewClick={this.handleAddNewVendor}
            >
              <BriefVendors
                containerRef={el => {
                  this.vendors = el;
                }}
                event={event}
                selectedVendors={selectedVendors}
                onAddVendor={onAddVendor}
                onRemoveVendor={onRemoveVendor}
                onAllVendorCheckToggle={onAllVendorCheckToggle}
              />
            </BuilderSection>
            <BuilderSection
              label="Select Attachments"
              addNewTooltip="Add new attachment"
              onAddNewClick={this.handleAddNewAttachment}
            >
              <BriefAttachments
                containerRef={el => {
                  this.attachments = el;
                }}
                event={event}
                selectedAttachments={selectedAttachments}
                onAddAttachment={onAddAttachment}
                onRemoveAttachment={onRemoveAttachment}
                onAllAttachmentCheckToggle={onAllAttachmentCheckToggle}
              />
            </BuilderSection>
            <BuilderSection label="Permissions">
              <StyledSharingOptions
                orgName={name}
                value={accessibility}
                samlEnabled={hasSamlProvider}
                onChange={onAccessibilityChange}
              />
            </BuilderSection>
          </BuilderItemsContainer>
          <ActionBar
            disableSave={disableSave}
            onBriefSave={onBriefSave}
            onBriefCancel={onBriefCancel}
          />
        </BuilderWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(
  BriefBuilder,
  graphql`
    fragment BriefBuilder_event on Event {
      id
      ...Notes_event
      ...BriefAttachments_event
      ...BriefContacts_event
      ...BriefVendors_event
      ...BriefCompanies_event
    }
    fragment BriefBuilder_org on Org {
      id
      name
      hasSamlProvider
    }
  `,
);
