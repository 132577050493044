/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import TeamsPage from './TeamsPage';

import type { TeamsQueryResponse } from './__generated__/TeamsQuery.graphql';

const query = graphql`
  query TeamsQuery {
    org {
      ...TeamsPage_org
    }
    me {
      ...TeamsPage_me
    }
  }
`;

export default class Teams extends React.PureComponent<{
  location: Location,
  history: RouterHistory,
}> {
  render() {
    const { location, history } = this.props;
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(props: TeamsQueryResponse) => (
          <TeamsPage me={props.me} org={props.org} history={history} location={location} />
        )}
      />
    );
  }
}
