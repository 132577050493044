/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation updateEventFloorPlanMutation($input: UpdateEventFloorPlanInput!) {
    updateEventFloorPlan(input: $input) {
      eventFloorPlan {
        id
        booth
        boothDimensions
        floorPlan
      }
    }
  }
`;

type Fields = { booth?: ?string, boothDimensions?: ?string, floorPlan?: string };

export default function updateEventFloorPlan(
  floorPlanId: string,
  { booth, boothDimensions, floorPlan = '' }: Fields,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        floorPlanId,
        booth,
        boothDimensions,
        floorPlan,
      },
    },
    optimisticResponse: {
      updateEventFloorPlan: {
        eventFloorPlan: { id: floorPlanId, booth, boothDimensions, floorPlan },
      },
    },
  });
}
