/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Button from 'components/budget/Button';
import TeamWindow from 'components/TeamWindow';

import headerTitles from '../lib/headerTitles';

import type { TeamsHeader_me } from './__generated__/TeamsHeader_me.graphql';
import type { TeamsHeader_org } from './__generated__/TeamsHeader_org.graphql';

const General = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  margin: 22px 0 18px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px 15px;
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const DeleteInfo = styled.div`
  font-size: 13px;
  color: #3e4859;
`;

const Support = styled.a`
  color: #39a8da;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 18px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
  padding: 8px 14px;
  border-radius: 5px;
`;

class TeamsHeader extends React.PureComponent<
  {
    me: TeamsHeader_me,
    org: TeamsHeader_org,
  },
  {
    showNewTeamWindow: boolean,
  },
> {
  state = {
    showNewTeamWindow: false,
  };

  handleClickAddTeam = () => {
    this.setState({ showNewTeamWindow: true });
  };

  handleHideNewTeamWindow = () => {
    this.setState({ showNewTeamWindow: false });
  };

  render() {
    const { me, org } = this.props;
    const title = headerTitles.teams;

    return (
      <Container>
        <General>
          <Title>{title}</Title>
          <DeleteInfo>
            To delete a team, please <Support href="mailto:success@circa.co">contact us</Support>
          </DeleteInfo>
        </General>
        <Actions>
          {org.viewerCanCreateTeams && (
            <>
              <StyledButton onClick={this.handleClickAddTeam}>Create new team</StyledButton>
              {this.state.showNewTeamWindow && (
                <TeamWindow onHide={this.handleHideNewTeamWindow} userId={me.id} />
              )}
            </>
          )}
        </Actions>
      </Container>
    );
  }
}

export default createFragmentContainer(TeamsHeader, {
  me: graphql`
    fragment TeamsHeader_me on User {
      id
    }
  `,
  org: graphql`
    fragment TeamsHeader_org on Org {
      viewerCanCreateTeams
    }
  `,
});
