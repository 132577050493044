/**
 * @flow
 * @relayHash c7558c617f80a21a1a80cf84f737a6d8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventCompaniesTablePagination_totalCountEvent$ref = any;
export type CompanySort = "CITY" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CUSTOM" | "DESCRIPTION" | "LINKEDIN" | "NAME" | "PHONE" | "SALESFORCE_ID" | "STATE" | "STREET" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "WEBSITE" | "ZIP";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type RemoveCompanyAssignmentInput = {
  clientMutationId?: ?string,
  companyId: string,
  eventId: string,
  fromWindow: string,
};
export type CompanyFilters = {
  query?: ?string,
  companyIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactsCount?: ?NumberRangeInput,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?CompanySort,
  direction?: ?Direction,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type removeCompanyAssignmentMutationVariables = {|
  input: RemoveCompanyAssignmentInput,
  filters?: ?CompanyFilters,
|};
export type removeCompanyAssignmentMutationResponse = {|
  +removeCompanyAssignment: ?{|
    +removedCompanyId: string,
    +event: {|
      +id: string,
      +companies: {|
        +totalCount: number
      |},
      +$fragmentRefs: EventCompaniesTablePagination_totalCountEvent$ref,
    |},
  |}
|};
export type removeCompanyAssignmentMutation = {|
  variables: removeCompanyAssignmentMutationVariables,
  response: removeCompanyAssignmentMutationResponse,
|};
*/


/*
mutation removeCompanyAssignmentMutation(
  $input: RemoveCompanyAssignmentInput!
  $filters: CompanyFilters
) {
  removeCompanyAssignment(input: $input) {
    removedCompanyId
    event {
      id
      companies {
        totalCount
      }
      ...EventCompaniesTablePagination_totalCountEvent
    }
  }
}

fragment EventCompaniesTablePagination_totalCountEvent on Event {
  companiesCount: companies(filters: $filters) {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveCompanyAssignmentInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "CompanyFilters",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveCompanyAssignmentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedCompanyId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "companies",
  "storageKey": null,
  "args": null,
  "concreteType": "CompanyRequiredConnection",
  "plural": false,
  "selections": v4
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeCompanyAssignmentMutation",
  "id": null,
  "text": "mutation removeCompanyAssignmentMutation(\n  $input: RemoveCompanyAssignmentInput!\n  $filters: CompanyFilters\n) {\n  removeCompanyAssignment(input: $input) {\n    removedCompanyId\n    event {\n      id\n      companies {\n        totalCount\n      }\n      ...EventCompaniesTablePagination_totalCountEvent\n    }\n  }\n}\n\nfragment EventCompaniesTablePagination_totalCountEvent on Event {\n  companiesCount: companies(filters: $filters) {\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeCompanyAssignmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeCompanyAssignment",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveCompanyAssignmentPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v5,
              {
                "kind": "FragmentSpread",
                "name": "EventCompaniesTablePagination_totalCountEvent",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeCompanyAssignmentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeCompanyAssignment",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveCompanyAssignmentPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v5,
              {
                "kind": "LinkedField",
                "alias": "companiesCount",
                "name": "companies",
                "storageKey": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "filters",
                    "variableName": "filters",
                    "type": "CompanyFilters"
                  }
                ],
                "concreteType": "CompanyRequiredConnection",
                "plural": false,
                "selections": v4
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '915f6ca76e1f6994d28e586b73b1437f';
module.exports = node;
