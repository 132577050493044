/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation removeEventNoteMutation($input: RemoveEventNoteInput!) {
    removeEventNote(input: $input) {
      removedNoteId
    }
  }
`;

export default function removeEventNote(eventId: string, noteId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        noteId,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedNoteId',
        parentId: eventId,
        connections: [{ field: 'eventNotes' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedNoteId' }),
    ),
  });
}
