/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Checkbox = styled.div`
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  color: ${props => props.theme.checkboxTextColor};
  flex-shrink: 0;
  background: ${props =>
    props.checked ? props.theme.highlightColor : props.theme.checkboxUncheckedColor};

  ${props =>
    props.disabled &&
    `
    cursor: default;
    i:first-child {
      position: absolute;
      transform: scale(2);
      opacity: 0;
      visibility: hidden;
      transition: .2s;
      pointer-events: none;
    }
    i:last-child {
      transition: .3s;
    }
    &:hover {
      i:first-child {
        visibility: visible;
        transform: scale(1);
        opacity: 1;
      }
      i:last-child {
        opacity: 0;
      }
    }`} @media (${props => props.theme.mobileOnly}) {
    margin-left: 0;
  }
`;

export default class TaskRowStatus extends React.PureComponent<{
  checked: boolean,
  onChange: boolean => void,
  disabled?: boolean,
  className?: string,
}> {
  handleChange = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.props.disabled) {
      this.props.onChange(!this.props.checked);
    }
  };

  render() {
    return (
      <Checkbox
        checked={this.props.checked}
        onClick={this.handleChange}
        disabled={this.props.disabled}
        className={this.props.className}
      >
        {this.props.disabled && <i className="fa fa-fw fa-lock" />}
        <i className="fa fa-fw fa-check" />
      </Checkbox>
    );
  }
}
