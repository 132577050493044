/* @flow */

import * as React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';

import Tooltip from 'components/material/Tooltip';

const Container = styled.div`
  margin-bottom: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: #f4f5f7;
  cursor: pointer;
  ${props =>
    props.sectionOpened &&
    css`
      margin-bottom: 25px;
    `};
`;

const Label = styled.div`
  flex: 1 auto;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.headerRowColor};
`;

const AddNewButton = styled.div`
  flex: 1 1 auto;
  padding-right: 14px;
  font-size: 22px;
  color: ${props => props.theme.minimalButtonColor};
  &:hover {
    color: ${props => darken(0.1, props.theme.minimalButtonColor)};
  }
`;

const Content = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
`;

export default class BuilderSection extends React.Component<
  {
    label: string,
    children: React.Node,
    defaultExpanded?: boolean,
    addNewTooltip?: string,
    onAddNewClick?: () => void,
  },
  {
    showContent: boolean,
  },
> {
  state = { showContent: !!this.props.defaultExpanded };

  toggleContent = () => {
    this.setState(prevState => ({ showContent: !prevState.showContent }));
  };

  handleAddNewClick = (e: SyntheticFocusEvent<HTMLElement>) => {
    e.stopPropagation();
    if (this.props.onAddNewClick) {
      this.props.onAddNewClick();
    }
  };

  render() {
    const { label, children, addNewTooltip, onAddNewClick } = this.props;
    const { showContent } = this.state;
    return (
      <Container>
        <Header onClick={this.toggleContent} sectionOpened={showContent}>
          <Label>{label}</Label>
          {onAddNewClick && (
            <Tooltip label={addNewTooltip} placement="left" compact>
              <AddNewButton onClick={this.handleAddNewClick}>+</AddNewButton>
            </Tooltip>
          )}
        </Header>
        <Content show={showContent}>{children}</Content>
      </Container>
    );
  }
}
