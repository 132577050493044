/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { TasksFiltersInputType } from 'views/Main/Dashboard/Tasks/columns';

import type { TasksFromWindow } from './createTasks';

const mutation = graphql`
  mutation unassignTasksMutation(
    $input: UnassignDeliverablesInput!
    $hasFilters: Boolean!
    $filters: DeliverableFilters
  ) {
    unassignDeliverables(input: $input) {
      unassignedDeliverableIds
      user {
        ...MyTasksPagination_totalCountDeliverables @include(if: $hasFilters)
        deliverables @include(if: $hasFilters) {
          totalCount
        }
      }
      deliverables {
        id
        following
        assignees {
          edges {
            node {
              id
            }
          }
        }
        subtasks {
          edges {
            node {
              assignees {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function unassignTasks(
  taskIds: Array<string>,
  userId: string,
  fromWindow: TasksFromWindow,
  filters?: TasksFiltersInputType,
  currentUserId?: ?string,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableIds: taskIds,
        userId,
        fromWindow,
      },
      filters,
      hasFilters: !!filters,
    },
    updater: mergeUpdaters(
      ...[
        currentUserId != null && currentUserId === userId && fromWindow === 'my tasks'
          ? removeRecordFromConnection({
              deletedIdField: 'unassignedDeliverableIds',
              parentId: userId,
              connections: [{ field: 'deliverables' }, { key: 'MyTasksPagination_deliverables' }],
            })
          : null,
      ].filter(Boolean),
    ),
  });
}
