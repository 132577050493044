/**
 * @flow
 * @relayHash e51d982649c40629ef8d65f8fec86ba4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveBudgetPeriodsInput = {
  clientMutationId?: ?string,
  fiscalYear: number,
};
export type removeBudgetPeriodsMutationVariables = {|
  input: RemoveBudgetPeriodsInput
|};
export type removeBudgetPeriodsMutationResponse = {|
  +removeBudgetPeriods: ?{|
    +removedPeriodIds: ?$ReadOnlyArray<string>
  |}
|};
export type removeBudgetPeriodsMutation = {|
  variables: removeBudgetPeriodsMutationVariables,
  response: removeBudgetPeriodsMutationResponse,
|};
*/


/*
mutation removeBudgetPeriodsMutation(
  $input: RemoveBudgetPeriodsInput!
) {
  removeBudgetPeriods(input: $input) {
    removedPeriodIds
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveBudgetPeriodsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeBudgetPeriods",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveBudgetPeriodsInput!"
      }
    ],
    "concreteType": "RemoveBudgetPeriodsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedPeriodIds",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeBudgetPeriodsMutation",
  "id": null,
  "text": "mutation removeBudgetPeriodsMutation(\n  $input: RemoveBudgetPeriodsInput!\n) {\n  removeBudgetPeriods(input: $input) {\n    removedPeriodIds\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeBudgetPeriodsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeBudgetPeriodsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d97fba5cbc1ac0a5527c038582bdedf';
module.exports = node;
