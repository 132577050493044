/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash';

import { type FilterItem } from 'components/material/Filters/FilterSelectorRow';

import EmptyFilters from './EmptyFilters';
import getSavedFiltersForView from './lib/getSavedFiltersForView';
import type { ConditionField, RuleType } from './lib/types';

const RULE_VIEW_ACTIONS = {
  HIDE_BY_DEFAULT: 'Hidden by default',
  HIDE: 'Hidden',
  SHOW: 'Visible',
  MAKE_REQUIRED: 'Required',
  MAKE_OPTIONAL: 'Not required',
};

const RulesContainer = styled.div`
  padding-top: 10px;
  padding-left: 20px;
`;

const RuleViewWrap = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
export default class ViewMode extends React.Component<{
  conditionFields: $ReadOnlyArray<ConditionField>,
  emptyMessage?: string,
  rules: any,
  tz: string,
  onStartEdit: () => void,
}> {
  getQuestionName = (filterItem: FilterItem, conditionFields: $ReadOnlyArray<ConditionField>) => {
    if (!filterItem.customField) {
      const question = conditionFields.find(field => field.fieldName === filterItem.fieldName);
      return question ? question.label : '';
    }
    if (filterItem.customField) {
      const question = conditionFields.find(field => {
        if (!field.id || !filterItem.customField) {
          return false;
        }
        return field.id === filterItem.customField.id;
      });
      return question ? question.label : '';
    }

    return '';
  };

  renderViewModeRule = (rule: RuleType, index: number) => {
    const { conditionFields, tz } = this.props;
    const conditionViews = getSavedFiltersForView(rule, tz, filterItem =>
      this.getQuestionName(filterItem, conditionFields),
    );

    return (
      <React.Fragment key={index}>
        {index > 0 && <>OR</>}
        <RuleViewWrap>
          {rule.action && RULE_VIEW_ACTIONS[rule.action]} {conditionViews.length > 0 && <> if </>}
          {conditionViews.map((conditionView, conditionViewIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={conditionViewIndex}>
              {conditionViewIndex > 0 ? ' and ' : ''}
              <b>{conditionView.fieldName}</b> <i>{conditionView.operator}</i>{' '}
              <b>{conditionView.value}</b>
            </React.Fragment>
          ))}
        </RuleViewWrap>
      </React.Fragment>
    );
  };

  render() {
    const { rules, emptyMessage, onStartEdit } = this.props;

    return (
      <>
        {rules.edges.length === 0 ? (
          <EmptyFilters onClick={onStartEdit}>
            {emptyMessage || 'This question does not have any logical dependencies. Click to add.'}
          </EmptyFilters>
        ) : (
          <RulesContainer>
            {sortBy(
              rules.edges.map(edge => edge.node),
              'order',
            ).map((rule, index) => this.renderViewModeRule(rule, index))}
          </RulesContainer>
        )}
      </>
    );
  }
}
