/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  UpdateTextComponentInput,
  updateTextComponentMutationResponse,
  updateTextComponentMutationVariables,
} from './__generated__/updateTextComponentMutation.graphql';

export type updateTextComponentPropertyType = $Shape<UpdateTextComponentInput>;

const mutation = graphql`
  mutation updateTextComponentMutation($input: UpdateTextComponentInput!) {
    updateRegistrationFormTextComponent(input: $input) {
      pageComponent {
        textComponent {
          id
          value
          padding
          backgroundColor
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateTextComponent(properties: updateTextComponentPropertyType) {
  const { id, padding, backgroundColor, value } = properties;
  const variables: updateTextComponentMutationVariables = {
    input: {
      ...properties,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateRegistrationFormTextComponent: {
        pageComponent: {
          textComponent: {
            id,
            padding,
            backgroundColor,
            value,
          },
        },
      },
    },
  }).then((response: updateTextComponentMutationResponse) => {
    if (
      response.updateRegistrationFormTextComponent &&
      response.updateRegistrationFormTextComponent.pageComponent.textComponent
    ) {
      return response.updateRegistrationFormTextComponent.pageComponent.textComponent.value;
    }
    throw new Error('No item id');
  });
}
