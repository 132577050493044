/* @flow */

import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 160px;
  margin-right: 50px;
  padding-bottom: 25px;
  @media (max-width: 480px) {
    padding-bottom: 20px;
  }
`;

const Label = styled.div`
  margin-bottom: 1px;
  font-size: 13px;
  color: ${props => props.theme.osloGray};
  text-transform: uppercase;
`;

const BriefField = (props: {
  label: string,
  contentDataAvailable: ?(string | number),
  children: React.Node,
  className?: ?string,
}) => {
  const { label, contentDataAvailable, children, className } = props;
  return (
    contentDataAvailable != null &&
    contentDataAvailable !== '' && (
      <Container className={className}>
        <Label>{label}</Label>
        {children}
      </Container>
    )
  );
};
export default BriefField;
