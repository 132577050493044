/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import isValidEmail from 'utils/validators/isValidEmail';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineTextField from 'components/budget/Table/InlineTextField';

import type { ContactEmail_contact } from './__generated__/ContactEmail_contact.graphql';

class ContactEmail extends React.Component<{
  contact: ContactEmail_contact,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?string) => {
    const { contact, onUpdate, updateColumnWidth } = this.props;
    if (!this.getError(value) && value !== contact.email) {
      onUpdate({ email: value });
      updateColumnWidth();
    }
  };

  handleValidate = (value: ?string): boolean => !!value && isValidEmail(value);

  getError = (value: ?string): ?string => {
    if (!value) {
      return 'Required';
    }
    if (!isValidEmail(value)) {
      return 'Invalid email';
    }
    return null;
  };

  render() {
    const { contact, updateColumnWidth } = this.props;
    return (
      <InlineTextField
        placeholder="Ex: example@company.com"
        value={contact.email || ''}
        onChange={this.handleUpdate}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
      >
        {contact.email && (
          <a
            onMouseDown={this.handleMouseDown}
            href={`mailto:${contact.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contact.email}
          </a>
        )}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  ContactEmail,
  graphql`
    fragment ContactEmail_contact on Contact {
      firstName
      email
    }
  `,
);
