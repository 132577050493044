/* @flow */
import React from 'react';
import { createRefetchContainer, graphql, type RelayRefetchProp } from 'react-relay';
import styled from 'styled-components';

import addCampaignToEvent from 'graph/mutations/salesforce/addCampaignToEvent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import TextInput from 'components/material/TextInput';
import ConnectCampaignPanelRow from './ConnectCampaignPanelRow';

import type { ConnectCampaignPanelSearch_event } from './__generated__/ConnectCampaignPanelSearch_event.graphql';

const StyledTextInput = styled(TextInput)`
  padding-left: 15px;
  padding-right: 15px;
`;

class ConnectCampaignPanelSearch extends React.PureComponent<{
  event: ConnectCampaignPanelSearch_event,
  onClose: () => void,
  relay: RelayRefetchProp,
}> {
  handleChangeQuery = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.relay.refetch({
      query: e.currentTarget.value,
    });
  };

  handleAddCampaign = campaignId => {
    addCampaignToEvent({
      salesforceCampaignId: campaignId,
      eventId: this.props.event.id,
    }).catch(showModernMutationError);

    this.props.onClose();
  };

  render() {
    const salesforceAccount = this.props.event.team.org.salesforceAccount;
    const campaigns = salesforceAccount
      ? salesforceAccount.salesforceCampaigns.edges.map(edge => edge.node)
      : [];

    return (
      <div>
        <StyledTextInput onChange={this.handleChangeQuery} autoFocus placeholder="Search..." />
        {campaigns.map(campaign => (
          <ConnectCampaignPanelRow
            key={campaign.id}
            campaign={campaign}
            onAdd={this.handleAddCampaign}
          />
        ))}
      </div>
    );
  }
}

export default createRefetchContainer(
  ConnectCampaignPanelSearch,
  graphql`
    fragment ConnectCampaignPanelSearch_event on Event
      @argumentDefinitions(query: { type: "String!", defaultValue: "" }) {
      id
      salesforceCampaign {
        id
        name
      }
      team {
        org {
          salesforceAccount {
            salesforceCampaigns(first: 10, query: $query) {
              edges {
                node {
                  id
                  ...ConnectCampaignPanelRow_campaign
                }
              }
            }
          }
        }
      }
    }
  `,
  graphql`
    query ConnectCampaignPanelSearchRefetchQuery($query: String!, $eventId: ID!) {
      node(id: $eventId) {
        ...ConnectCampaignPanelSearch_event @arguments(query: $query)
      }
    }
  `,
);
