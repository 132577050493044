/* @flow */
import formatCost from 'utils/number/formatCost';

import { type ReportingFooterCellPropsType } from './index';

const ReportingTotalActualAmountCell = ({ totals, orgCurrency }: ReportingFooterCellPropsType) => {
  return formatCost(totals.actualAmount, orgCurrency);
};

export default ReportingTotalActualAmountCell;
