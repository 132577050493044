/* @flow */
import track from '../track';

type BillingAnalyticsDetails = {|
  +user_email: ?string,
  +user_id: ?string,
|};

const billingVisited = (details: BillingAnalyticsDetails) => {
  track('visited_billing_page', details, { Slack: true });
};

export default billingVisited;
