/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';

import { createMoment } from 'utils/Date';

import ScheduleAdd from './ScheduleAdd';
import ScheduleListRow from './ScheduleListRow';

class ScheduleList extends React.Component {
  static propTypes = {
    scheduleDay: PropTypes.shape({
      date: PropTypes.string,
      viewerCanUpdate: PropTypes.bool.isRequired,
      scheduleItems: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string.isRequired,
              start_time: PropTypes.string.isRequired,
              end_time: PropTypes.string,
              all_day: PropTypes.bool.isRequired,
              scheduleParticipants: PropTypes.shape({
                edges: PropTypes.arrayOf(
                  PropTypes.shape({
                    node: PropTypes.shape({
                      user: PropTypes.shape({
                        slug: PropTypes.string.isRequired,
                      }),
                    }),
                  }),
                ).isRequired,
              }).isRequired,
            }).isRequired,
          }).isRequired,
        ).isRequired,
      }).isRequired,
    }).isRequired,
    event: PropTypes.shape({
      tz: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
    eventId: PropTypes.number.isRequired,
    teamId: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
    onItemCreate: PropTypes.func.isRequired,
    onItemUpdate: PropTypes.func.isRequired,
    onItemRemove: PropTypes.func.isRequired,
    onParticipantAdd: PropTypes.func.isRequired,
    onParticipantRemove: PropTypes.func.isRequired,
    addFormIndex: PropTypes.number,
    onAddFormShow: PropTypes.func.isRequired,
    onAddFormHide: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  filterItems = (items, filters) => {
    return items.filter(item =>
      Object.keys(filters).every(key => {
        const value = filters[key];
        if (key === 'member') {
          return item.scheduleParticipants.edges.some(x => x.node.user.slug === value);
        }
        return true;
      }),
    );
  };

  render() {
    const {
      onItemCreate,
      onItemUpdate,
      eventId,
      teamId,
      scheduleDay,
      event,
      onParticipantAdd,
      onParticipantRemove,
      filters,
      onItemRemove,
      addFormIndex,
      onAddFormShow,
      onAddFormHide,
    } = this.props;
    const { date } = scheduleDay;
    const { tz } = event;
    const moment = createMoment(tz);

    const items = sortBy(
      this.filterItems(
        scheduleDay.scheduleItems.edges.map(x => x.node),
        filters,
      ),
      [s => moment(s.start_time).getTime(), s => (s.end_time ? moment(s.end_time).getTime() : 0)],
    );

    const canAdd = this.props.scheduleDay.viewerCanUpdate;

    const list = [];

    for (let i = 0; i < items.length; ) {
      const item = items[i];
      i += 1;
      const next = items[i];

      list.push(
        <ScheduleListRow
          key={item.id}
          scheduleItem={item}
          onItemUpdate={onItemUpdate}
          onItemRemove={onItemRemove}
          eventId={eventId}
          teamId={teamId}
          event={event}
          date={date}
          onParticipantAdd={onParticipantAdd}
          onParticipantRemove={onParticipantRemove}
        />,
      );

      if (next) {
        if (canAdd && !next.all_day) {
          list.push(
            <ScheduleAdd
              key={-i}
              hidden
              active={-i === addFormIndex}
              index={-i}
              onShow={onAddFormShow}
              tz={tz}
              onCreate={onItemCreate}
              onCancel={onAddFormHide}
              defaultStart={item.end_time || item.start_time}
              date={date}
            />,
          );
        }
      }
    }

    return (
      <div className="schedule-list">
        {list}

        {canAdd && (
          <ScheduleAdd
            active={items.length === addFormIndex}
            index={items.length}
            onShow={onAddFormShow}
            onCancel={onAddFormHide}
            tz={tz}
            onCreate={onItemCreate}
            defaultStart={
              items.length > 0
                ? items[items.length - 1].end_time || items[items.length - 1].start_time
                : null
            }
            date={date}
          />
        )}
      </div>
    );
  }
}

export default createFragmentContainer(withRouter(ScheduleList), {
  scheduleDay: graphql`
    fragment ScheduleList_scheduleDay on ScheduleDay {
      date
      viewerCanUpdate
      scheduleItems {
        edges {
          node {
            id
            start_time
            end_time
            all_day
            scheduleParticipants {
              edges {
                node {
                  user {
                    slug
                  }
                }
              }
            }
            ...ScheduleListRow_scheduleItem
          }
        }
      }
    }
  `,
  event: graphql`
    fragment ScheduleList_event on Event {
      tz
      slug
      ...ScheduleListRow_event
      team {
        org {
          settings {
            currency
          }
        }
      }
    }
  `,
});
