/* @flow */
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import blockedByUpgradePrompt from 'utils/analytics/blockedByUpgradePrompt';

import ProgressBar from 'components/billing/BillingProgressBar';
import Button from 'components/budget/Button';

const Container = styled.div`
  margin-top: 55px;
  text-align: center;
  color: #3e4859;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const InfoLine = styled.div`
  margin-top: 10px;
  font-size: 13px;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 45px 0;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  min-width: 154px;
  margin: 0;
  &:first-child {
    margin-right: 15px;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 45px;
  color: #3ba9da;
  line-height: 1.08;
  text-decoration: underline;
`;

export default class UsersImportLimitError extends React.PureComponent<{
  used: number,
  limit: number,
  importing: number,
  onReset: () => void,
}> {
  componentDidMount() {
    blockedByUpgradePrompt('import users');
  }

  handleReset = () => {
    this.props.onReset();
  };

  handleContactUs = () => {
    window.open('mailto:success@circa.co', '_blank');
  };

  render() {
    const { used, limit, importing } = this.props;
    const availableSlots = limit - used;
    return (
      <Container>
        <Title>Import Failed!</Title>
        <InfoLine>
          You tried to import {importing} new Team Member{importing > 1 ? 's' : ''} but your
          Subscription {availableSlots > 0 ? `only has ${availableSlots}` : 'has no'} Team members
          available.
        </InfoLine>
        <ProgressBarContainer>
          <ProgressBar limit={limit} used={used} label="Team Members Available" />
        </ProgressBarContainer>
        <InfoLine>Need more Team Members or have a question?</InfoLine>
        <ActionsContainer>
          <StyledButton onClick={this.handleReset} radius={4}>
            Upload another File
          </StyledButton>
          <StyledButton onClick={this.handleContactUs} radius={4}>
            Contact Us
          </StyledButton>
        </ActionsContainer>
        <StyledLink to="/workspace/billing">Visit Org Billing</StyledLink>
      </Container>
    );
  }
}
