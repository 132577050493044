/* @flow */

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/material/Button';

const Container = styled.div`
  padding: 5px 26px 0;
`;

const Helper = styled.div`
  margin-bottom: 20px;
  padding: 14px;
  font-size: 13px;
  line-height: 1.31;
  background-color: #f2fbff;
  color: #3e4859;
`;

const Title = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
`;

const StyledLink = styled(Link)`
  color: #00abdf;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Cancel = styled.a`
  margin-right: 23px;
  align-self: center;
  color: #828b93;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

type Props = {
  disableSave: boolean,
  onBriefSave: () => void,
  onBriefCancel: () => void,
};

const ActionBar = (props: Props) => {
  return (
    <Container>
      <Helper>
        <Title>Advanced Settings</Title>
        To update other content and layout, go to the{' '}
        <StyledLink to="/settings/events/briefs/event_brief/content" target="_blank">
          Brief Template
        </StyledLink>{' '}
        in your Workspace Settings.
      </Helper>
      <ActionsContainer>
        <Cancel onClick={props.onBriefCancel}>Cancel</Cancel>
        <Button label="Save" primary onClick={props.onBriefSave} disabled={props.disableSave} />
      </ActionsContainer>
    </Container>
  );
};

export default ActionBar;
