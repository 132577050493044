/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment';

import Button from 'components/budget/Button';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import InviteWindow from 'components/InviteWindow';
import Search from 'components/Search';
import ShareDropdown from 'components/ShareDropdown';
import UsersImportWindow from 'components/UsersImportWindow';

import headerTitles from '../lib/headerTitles';
import { type ParsedMembersFilters } from './lib/parseMembersFilters';
import MemberSearchSuggestion from './MemberSearchSuggestion';

import type { MembersHeader_org } from './__generated__/MembersHeader_org.graphql';
import type { MembersHeaderQueryResponse } from './__generated__/MembersHeaderQuery.graphql';

const General = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  margin: 14px 0;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px 15px;
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 18px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
  padding: 8px 14px;
  border-radius: 5px;
`;

const StyledSearch = styled(Search)`
  max-width: 250px;
  margin: 0 10px;
`;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 258px;
  min-width: 175px;
`;

const query = graphql`
  query MembersHeaderQuery($sort: UserSort!, $direction: Direction!, $query: String) {
    org {
      memberReportCSVProcessId(sort: $sort, direction: $direction, query: $query)
    }
    me {
      email
    }
  }
`;

class MembersHeader extends React.PureComponent<
  {
    org: MembersHeader_org,
    onSearch: (query: string) => void,
    filters: ParsedMembersFilters,
    orgMembersCount: number,
  },
  {
    showInviteWindow: boolean,
    showImportWindow: boolean,
    showCsvDownloadOverlay: boolean,
  },
> {
  state = {
    showInviteWindow: false,
    showImportWindow: false,
    showCsvDownloadOverlay: false,
  };

  handleShowInviteWindow = () => {
    this.setState({ showInviteWindow: true });
  };

  handleHideInviteWindow = () => {
    this.setState({ showInviteWindow: false });
  };

  handleShowImportWindow = () => {
    this.handleHideInviteWindow();
    this.setState({ showImportWindow: true });
  };

  handleHideImportWindow = () => {
    this.setState({ showImportWindow: false });
  };

  handleCsvExport = () => {
    this.setState({ showCsvDownloadOverlay: true });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCsvDownloadOverlay: false });
  };

  renderDownloadOverlay = () => {
    const filters = this.props.filters;

    const sortFilter = filters.sort || {
      key: 'FULL_NAME',
      asc: true,
    };

    const variables = {
      sort: sortFilter.key,
      direction: sortFilter.asc ? 'ASC' : 'DESC',
      query: filters.search,
    };

    return (
      <DefaultQueryRenderer
        query={query}
        variables={variables}
        renderSuccess={(response: MembersHeaderQueryResponse) => {
          return (
            <DownloadOverlayWithEmail
              processUUID={response.org.memberReportCSVProcessId}
              fileName={`Circa Workspace Members Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleCsvDownloadEnd}
              email={response.me.email}
              exportable="members_csv"
              onDownload={this.handleCsvExport}
            />
          );
        }}
        renderLoading={() => null}
      />
    );
  };

  render() {
    const { org, filters, onSearch, orgMembersCount } = this.props;
    const { showCsvDownloadOverlay, showInviteWindow, showImportWindow } = this.state;
    const title = headerTitles.members;

    return (
      <Container>
        <General>
          <Title>{title}</Title>
          {org.viewerCanManageUsers && (
            <ShareDropdown
              noBorder
              options={[
                {
                  label: 'Download Members List (CSV)',
                  icon: 'file-excel-o',
                  onClick: this.handleCsvExport,
                },
                {
                  label: 'Import Members (CSV)',
                  icon: 'cloud-upload',
                  onClick: this.handleShowImportWindow,
                },
              ]}
            />
          )}
          {showCsvDownloadOverlay && this.renderDownloadOverlay()}
          {org.viewerCanManageUsers && showImportWindow && (
            <UsersImportWindow onClose={this.handleHideImportWindow} />
          )}
        </General>
        <Actions>
          {org.viewerCanManageStaff && (
            <StyledButton onClick={this.handleShowInviteWindow}>Add member</StyledButton>
          )}
          {org.viewerCanManageStaff && showInviteWindow && (
            <InviteWindow
              onHide={this.handleHideInviteWindow}
              onClickImport={this.handleShowImportWindow}
              filters={filters}
              fromWindow="org members"
            />
          )}
          <SearchWrapper>
            <StyledSearch
              onSearch={onSearch}
              search={filters.search}
              placeholder="Search members"
              suggestionComponent={props => {
                return orgMembersCount === 0 ? (
                  <MemberSearchSuggestion
                    {...props}
                    onShowInviteWindow={this.handleShowInviteWindow}
                  />
                ) : null;
              }}
            />
          </SearchWrapper>
        </Actions>
      </Container>
    );
  }
}

export default createFragmentContainer(
  MembersHeader,
  graphql`
    fragment MembersHeader_org on Org {
      id
      viewerCanManageUsers
      viewerCanManageStaff
    }
  `,
);
