/* @flow */
import moment from 'moment-timezone';

import { type DateRangeParam, FILTER_DATE_FORMAT } from 'utils/routing/parseTypedQueryString';

type ListType = 'UPCOMING' | 'PAST' | 'RECENT' | 'NEXT30DAYS' | 'NEXT60DAYS' | 'NEXT90DAYS';

const getEventCustomDateOption = (key: ?string): ?ListType =>
  ['UPCOMING', 'PAST', 'RECENT', 'NEXT30DAYS', 'NEXT60DAYS', 'NEXT90DAYS'].includes(key)
    ? (key: any)
    : null;

export const parseDateFilterParams = (props: {
  beforeDate: ?string,
  afterDate: ?string,
  listType?: ?string,
  tz: string,
}): ?{
  key: ?string,
  start?: moment,
  end?: moment,
} => {
  if (props.listType != null) {
    return { key: props.listType.toLowerCase() };
  }

  if (props.beforeDate != null && props.afterDate != null) {
    const start = moment.tz(props.afterDate, props.tz);
    const end = moment.tz(props.beforeDate, props.tz);
    const key = `${start.format(FILTER_DATE_FORMAT)}-${end.format(FILTER_DATE_FORMAT)}`;
    return {
      key,
      start,
      end,
    };
  }
  return null;
};

export default function parseDateFilters(
  date: ?DateRangeParam,
): {| listType?: ?ListType, includeUndated?: ?boolean, afterDate?: ?string, beforeDate?: string |} {
  if (date == null) {
    return Object.freeze({});
  }
  const listType = getEventCustomDateOption(date && date.key && date.key.toUpperCase());

  return date && listType
    ? {
        listType,
        includeUndated: listType === 'UPCOMING',
      }
    : {
        afterDate: date.start.format(),
        beforeDate: date.end.format(),
        includeUndated: null,
      };
}
