/* @flow */
import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
  stringParamToString,
} from 'utils/routing/parseTypedQueryString';

export type ParsedMembersFilters = {
  sort: ?SortParam,
  search: ?string,
};

export default function parseMembersFilters(queryString: string): ParsedMembersFilters {
  return parseTypedQueryString(queryString, {
    sort: stringParamToSort,
    search: stringParamToString,
  });
}
