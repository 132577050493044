/* @flow */
import React from 'react';
import { type RelayRefetchProp, createRefetchContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import throttle from 'lodash/throttle';

import fonts from 'config/fonts';

import { loadFonts } from 'utils/fonts';

import NoEventStartDate from 'images/noEventStartDate.svg';
import EmptyMessage from 'components/EmptyMessage';
import { Content } from 'components/page/Content';

import ExistingRegistrationForm from './ExistingRegistrationForm';
import UncreatedRegistrationForm from './UncreatedRegistrationForm';

import type { RegistrationFormMain_query } from './__generated__/RegistrationFormMain_query.graphql';

const StyledEmptyMessage = styled(EmptyMessage)`
  margin-top: 120px;
  background-color: transparent;
`;

class RegistrationFormMain extends React.PureComponent<{
  query: RegistrationFormMain_query,
  relay: RelayRefetchProp,
  history: RouterHistory,
}> {
  builderWindow: any = null;

  documentFocus = throttle(
    () =>
      this.props.relay.refetch(
        {
          eventSlug: this.props.query.event ? this.props.query.event.slug : null,
          includeRules: false,
        },
        null,
      ),
    1000,
    { trailing: false },
  );

  componentDidMount() {
    loadFonts(fonts.map(font => font.value));
  }

  handleNavigateToFormBuilder = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    if (!this.props.query.event) {
      return;
    }
    this.builderWindow = window.open(
      `/events/${this.props.query.event.slug}/registration_builder`,
      '_blank',
    );
    this.builderWindow.onbeforeunload = this.removeTabChangeListener;
    document.addEventListener('visibilitychange', this.documentFocus);
  };

  removeTabChangeListener = () => {
    this.documentFocus();
    document.removeEventListener('visibilitychange', this.documentFocus);
  };

  render() {
    const { query, history } = this.props;
    if (!query.event) {
      return null;
    }
    if (query.event.registrationForm) {
      return (
        <Content>
          <ExistingRegistrationForm
            event={query.event}
            org={query.org}
            history={history}
            navigateToFormBuilder={this.handleNavigateToFormBuilder}
          />
        </Content>
      );
    }

    return (
      <Content>
        {query.event.startDate ? (
          <UncreatedRegistrationForm
            event={query.event}
            navigateToFormBuilder={this.handleNavigateToFormBuilder}
          />
        ) : (
          <StyledEmptyMessage
            icon={<NoEventStartDate />}
            text="Add Event Dates to manage and share the Contact Form"
          />
        )}
      </Content>
    );
  }
}

export default createRefetchContainer(
  RegistrationFormMain,
  graphql`
    fragment RegistrationFormMain_query on Query {
      event(slug: $eventSlug) {
        id
        slug
        startDate
        registrationForm {
          id
        }
        ...UncreatedRegistrationForm_event
        ...ExistingRegistrationForm_event
      }
      org {
        id
        ...ExistingRegistrationForm_org
      }
    }
  `,
  graphql`
    query RegistrationFormMainRefetchQuery($eventSlug: String!, $includeRules: Boolean!) {
      query {
        ...RegistrationFormMain_query
      }
    }
  `,
);
