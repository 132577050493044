/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ExpenseActualAmountCell_org$ref = any;
type ExpenseActualAmountFooterCell_org$ref = any;
type ExpenseBudgetCategoryCell_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpensesTableRenderer_org$ref: FragmentReference;
export type ExpensesTableRenderer_org = {|
  +$fragmentRefs: ExpenseBudgetCategoryCell_org$ref & ExpenseActualAmountCell_org$ref & ExpenseActualAmountFooterCell_org$ref,
  +$refType: ExpensesTableRenderer_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExpensesTableRenderer_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ExpenseBudgetCategoryCell_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseActualAmountCell_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseActualAmountFooterCell_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8d13bb3d2bf2dd0db822b20ed09e6bda';
module.exports = node;
