/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
export type Measurement = "PERCENTAGE" | "PX";
import type { FragmentReference } from "relay-runtime";
declare export opaque type FormFieldsStyling_formComponent$ref: FragmentReference;
export type FormFieldsStyling_formComponent = {|
  +id: string,
  +fontFamily: ?string,
  +fontStyle: ?string,
  +color: ?string,
  +buttonCopy: ?string,
  +buttonColor: ?string,
  +buttonTextColor: ?string,
  +width: number,
  +widthMeasurement: Measurement,
  +alignment: Alignment,
  +$refType: FormFieldsStyling_formComponent$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "FormFieldsStyling_formComponent",
  "type": "RegistrationFormComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fontFamily",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fontStyle",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "buttonCopy",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "buttonColor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "buttonTextColor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "width",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "widthMeasurement",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "alignment",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f7f16845d1a1e4043a4a6f61bb4cdb88';
module.exports = node;
