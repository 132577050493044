/* @flow */
import React from 'react';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';

import budgetCategoryColors from 'config/budgetCategoryColors';

import { type CategoryPill_category } from './__generated__/CategoryPill_category.graphql';

const Pill = styled.div`
  display: inline-block;
  margin: -6px -11px -6px 0;
  padding: 5px 10px;
  border-radius: 2px;
  border: 1px solid ${props => props.color};
  font-size: 13px;
  line-height: 1;
  background: #fff;
  color: ${props => props.color};
  cursor: default;
`;

class CategoryPill extends React.PureComponent<{ category: CategoryPill_category }> {
  render() {
    const { category } = this.props;

    return <Pill color={budgetCategoryColors[category.color % 20]}>{category.name}</Pill>;
  }
}

export default createFragmentContainer(
  CategoryPill,
  graphql`
    fragment CategoryPill_category on BudgetCategory {
      name
      color
    }
  `,
);
