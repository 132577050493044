/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import range from 'lodash/range';
import moment from 'moment-timezone';

import AllEventsCalendarDay, { CARD_HORIZONTAL_SPACING } from './AllEventsCalendarDay';
import { type AllEventsCalendarEvent } from './AllEventsCalendarView';
import eventCalendarSlots from './lib/eventCalendarSlots';

const Root = styled.div`
  display: flex;
  border-radius: 7px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.06);
  background: #fff;
`;

const Column = styled.div`
  flex: 1;

  &:not(:last-child) {
    box-shadow: inset -1px 0 #c5c5c5;
  }
`;

const Header = styled.div`
  flex: 1;
  padding: 14px 0;
  border-bottom: 1px solid #c5c5c5;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #3e4859;
`;

const Day = styled.div`
  align-self: flex-end;
  width: 30px;
  height: 30px;
  margin: -2px auto -2px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;

  ${props =>
    props.today &&
    css`
      font-weight: 500;
      color: #005eff;
      background: #f1fafe;
    `}
`;

const Content = styled.div`
  min-height: 400px;
  padding: ${CARD_HORIZONTAL_SPACING}px;
`;

export default class AllEventsCalendarWeekView extends React.PureComponent<
  {
    week: string,
    events: $ReadOnlyArray<AllEventsCalendarEvent>,
    linkEventNameToBrief?: boolean,
  },
  { hoveringId: ?string },
> {
  state = { hoveringId: null };

  slots = eventCalendarSlots(this.props.events, moment(this.props.week).startOf('week'), 7);

  handleHover = (hoveringId: ?string) => this.setState({ hoveringId });

  render() {
    const { week, linkEventNameToBrief } = this.props;
    const { hoveringId } = this.state;
    const weekStart = moment(week).startOf('week').format();

    return (
      <Root>
        {range(7).map(index => {
          const day = moment(weekStart).add(index, 'day');

          return (
            <Column key={index}>
              <Header>
                {day.format('ddd')} <Day today={day.isSame(moment(), 'date')}>{day.date()}</Day>
              </Header>

              <Content>
                <AllEventsCalendarDay
                  day={day.format()}
                  eventSpots={this.slots[index]}
                  hoveringId={hoveringId}
                  onHover={this.handleHover}
                  linkEventNameToBrief={linkEventNameToBrief}
                />
              </Content>
            </Column>
          );
        })}
      </Root>
    );
  }
}
