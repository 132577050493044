/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';

import { type SortParam } from 'utils/routing/parseTypedQueryString';

import updateExpense from 'graph/mutations/expense/updateExpense';
import showModernMutationError from 'graph/utils/showModernMutationError';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';

import columns, { type VendorExpenseCellPropsType } from './columns';

import { type VendorExpensesTableRenderer_expenses } from './__generated__/VendorExpensesTableRenderer_expenses.graphql';
import { type VendorExpensesTableRenderer_org } from './__generated__/VendorExpensesTableRenderer_org.graphql';

type ExpenseType = $ElementType<VendorExpensesTableRenderer_expenses, 0>;

class VendorExpensesTableRenderer extends React.Component<{
  expenses: ?VendorExpensesTableRenderer_expenses,
  org: VendorExpensesTableRenderer_org,
  sort: SortParam,
  vendorId: string,
  onChangeSort: (sort: SortParam) => void,
  location: Location,
}> {
  cellProps = (
    expense: ExpenseType,
  ): VendorExpenseCellPropsType<ExpenseType, VendorExpensesTableRenderer_org> => {
    const { org, vendorId, location } = this.props;

    return {
      expense,
      vendorId,
      org,
      updateExpense: updates => updateExpense(expense.id, updates).catch(showModernMutationError),
      location,
    };
  };

  keyExtractor = (expense: ExpenseType) => expense.id;

  render() {
    const { expenses, sort, onChangeSort } = this.props;

    if (expenses && expenses.length === 0) {
      return <EmptyView message="Add the first expense for this vendor." icon={<NoResult />} />;
    }

    return (
      <Table
        data={expenses}
        columns={columns}
        sort={sort}
        onChangeSort={onChangeSort}
        cellProps={this.cellProps}
        keyExtractor={this.keyExtractor}
      />
    );
  }
}

export default createFragmentContainer(
  VendorExpensesTableRenderer,
  graphql`
    fragment VendorExpensesTableRenderer_expenses on Expense @relay(plural: true) {
      id
      ...VendorExpenseName_expense
      ...VendorExpenseAmount_expense
      ...VendorExpenseNote_expense
      ...VendorExpenseCategory_expense
      ...VendorExpensePaymentStatus_expense
      ...VendorExpenseAction_expense
      ...EventName_expense
    }

    fragment VendorExpensesTableRenderer_org on Org {
      ...VendorExpenseCategory_org
      ...VendorExpenseAmount_org
    }
  `,
);
