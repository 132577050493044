/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

export type Expense = {|
  +name?: string,
  +plannedCost?: ?number,
  +actualAmount?: ?number,
  +note?: string,
  +paymentMethod?: ?string,
  +paymentDate?: ?string,
  +paid?: ?boolean,
  +reference?: ?string,
  +paymentDueDate?: ?string,
  +paymentStatus?: ?'PAID' | 'UNPAID',
  +categoryId?: ?string,
  +vendorId?: ?string,
  +deliverableId?: ?string,
|};

const mutation = graphql`
  mutation updateExpenseMutation($input: UpdateExpenseInput!) {
    updateExpense(input: $input) {
      event {
        id
        totalActualAmount
        totalPaidAmount
        ...ExpensesTableRenderer_event
        budgetCategories {
          edges {
            ...CategoriesTable_categoryEdges
          }
        }
      }
      expense {
        id
        name
        actualAmount
        note
        payments {
          edges {
            node {
              amount
            }
          }
        }
        attachments {
          edges {
            node {
              id
              ...RelayAttachments_attachments
            }
          }
        }
        deliverable {
          id
          slug
          expenses {
            edges {
              node {
                id
              }
            }
          }
        }
        budgetCategory {
          id
        }
        vendor {
          id
        }
        ...ExpensesTableRenderer_expenses
      }
      deliverable {
        id
        expenses {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

const OPTIMISTIC_MAPPINGS = {
  categoryId: 'budgetCategory',
  vendorId: 'vendor',
  deliverableId: 'deliverable',
};

export default function updateExpense(expenseId: string, expense: Expense) {
  const optimisticExpense = Object.keys(expense).reduce(
    (obj, key) => {
      if (Object.keys(OPTIMISTIC_MAPPINGS).includes(key)) {
        return {
          ...obj,
          [OPTIMISTIC_MAPPINGS[((key: any): $Keys<typeof OPTIMISTIC_MAPPINGS>)]]:
            expense[key] == null ? null : { id: expense[key] },
        };
      }

      if (key === 'paymentStatus') {
        if (expense.paymentStatus === 'UNPAID') {
          return {
            ...obj,
            paidAmount: 0,
          };
        }
        if (expense.actualAmount != null) {
          return {
            ...obj,
            paidAmount: expense.actualAmount,
          };
        }
      }

      return obj;
    },
    {
      id: expenseId,
      ...expense,
    },
  );

  return commitModernMutation({
    mutation,
    variables: {
      input: {
        expenseId,
        ...expense,
      },
    },
    optimisticResponse: {
      updateExpense: {
        expense: optimisticExpense,
      },
    },
  });
}
