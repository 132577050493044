/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import intersection from 'lodash/intersection';
import moment from 'moment';

import downloadedVendors from 'utils/analytics/downloadedVendors';
import type { FieldType } from 'utils/customization/types';

import ExportIcon from 'images/events/export.svg';
import Button, { MinimalButton } from 'components/budget/Button';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ColumnFilter from 'components/material/ColumnFilters';
import AdvancedFilter from 'components/material/Filters/Advanced/AdvancedFilter';
import Search from 'components/Search';
import CreateVendorWindow from 'components/Vendors/CreateVendorWindow';
import getColumnConfiguration from 'components/Vendors/lib/getColumnConfiguration';
import getVendorFilterConverters from 'components/Vendors/lib/getVendorFilterConverters';
import { type ParsedVendorsFilters } from 'components/Vendors/lib/parseVendorsFilters';
import renderVendorFilter, {
  type VendorFilterInputs,
} from 'components/Vendors/lib/renderVendorFilter';

import headerTitles from '../lib/headerTitles';
import VendorSearchSuggestion from './VendorSearchSuggestion';

import type { VendorsHeaderBar_org } from './__generated__/VendorsHeaderBar_org.graphql';
import type { VendorsHeaderBarQueryResponse } from './__generated__/VendorsHeaderBarQuery.graphql';

const Container = styled.div`
  padding-top: 25px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &:first-of-type {
    margin-bottom: 21px;
  }
  @media (min-width: 800px) and (max-width: 1085px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const RowItem = styled.div`
  flex: 1;
  min-height: 31px;
  margin-bottom: 13px;

  &:first-of-type {
    display: flex;
  }
`;

const TableActions = styled.div`
  display: flex;
  flex: 0 auto;
  margin-right: 5px;
`;

const StyledButton = styled(Button)`
  flex: 0 auto;
  min-width: 116px;
  margin-right: 20px;
  padding-top: 9px;
`;

const StyledSearch = styled(Search)`
  max-width: 240px;
  margin-right: 10px;
`;

const StyledExportIcon = styled(ExportIcon)`
  margin-right: 10px;
`;

const ExportButton = styled(MinimalButton)`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 5px;
`;

const query = graphql`
  query VendorsHeaderBarQuery($filters: VendorFilters!, $columns: [String!]) {
    org {
      vendorReportCSVProcessId(filters: $filters, columns: $columns)
    }
    me {
      email
    }
  }
`;

class VendorsHeaderBar extends React.Component<
  {
    org: VendorsHeaderBar_org,
    history: RouterHistory,
    filters: ParsedVendorsFilters,
    filterInputs: VendorFilterInputs,
    vendorFields: $ReadOnlyArray<FieldType>,
    shownColumns: $ReadOnlyArray<string>,
    onSearch: (query: string) => void,
    onColumnsChange: (shownColumns: $ReadOnlyArray<string>) => void,
    orgVendorsCount: number,
    pathPrefix: string,
  },
  {
    adding: boolean,
    showCsvDownloadOverlay: boolean,
  },
> {
  state = {
    adding: false,
    showCsvDownloadOverlay: false,
  };

  handleVendorAddStart = () => {
    this.setState({ adding: true });
  };

  handleVendorAddEnd = () => {
    this.setState({ adding: false });
  };

  handleCsvExport = () => {
    this.setState({ showCsvDownloadOverlay: true });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCsvDownloadOverlay: false });

    downloadedVendors({
      eventId: null,
      eventName: null,
      teamId: null,
      teamName: null,
      fromWindow: 'org',
    });
  };

  renderDownloadOverlay = () => {
    const columns = intersection(
      this.props.vendorFields.map(field => field.fieldName || field.id),
      this.props.shownColumns,
    );
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ filters: this.props.filterInputs, columns }}
        renderSuccess={(response: VendorsHeaderBarQueryResponse) => {
          return (
            <DownloadOverlayWithEmail
              processUUID={response.org.vendorReportCSVProcessId}
              fileName={`Workspace Vendors Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleCsvDownloadEnd}
              email={response.me.email}
              exportable="vendors_csv"
              onDownload={this.handleCsvExport}
            />
          );
        }}
        renderLoading={() => null}
      />
    );
  };

  render() {
    const {
      org,
      org: { viewerCanCreateVendors },
      history,
      shownColumns,
      onColumnsChange,
      filters,
      filterInputs,
      vendorFields,
      onSearch,
      pathPrefix,
    } = this.props;
    const title = `${org.name}'s ${headerTitles[pathPrefix.split('/').pop()]}`;
    return (
      <Container>
        <Row>
          <Title>{title}</Title>
          <ExportButton
            onClick={this.handleCsvExport}
            label={
              <>
                <StyledExportIcon />
                Export
              </>
            }
          />
          {this.state.showCsvDownloadOverlay && this.renderDownloadOverlay()}
        </Row>
        <Row>
          <RowItem>
            {viewerCanCreateVendors && (
              <>
                <StyledButton onClick={this.handleVendorAddStart}>Add vendor</StyledButton>
                {this.state.adding && (
                  <CreateVendorWindow
                    filterVariables={filterInputs}
                    onHide={this.handleVendorAddEnd}
                    fromWindow="org vendors"
                  />
                )}
              </>
            )}
            <StyledSearch
              onSearch={onSearch}
              search={filters.query}
              placeholder="Search vendors"
              suggestionComponent={props => {
                return this.props.orgVendorsCount === 0 ? (
                  <VendorSearchSuggestion
                    {...props}
                    handleVendorAddStart={this.handleVendorAddStart}
                  />
                ) : null;
              }}
            />
          </RowItem>
          <RowItem>
            <TableActions>
              <AdvancedFilter
                history={history}
                filters={filters}
                filterOptions={vendorFields}
                converterFn={getVendorFilterConverters}
                filterRenderer={renderVendorFilter}
              />
              <ColumnFilter
                filters={getColumnConfiguration(vendorFields)}
                shownFilters={shownColumns}
                onFilterChange={onColumnsChange}
              />
            </TableActions>
          </RowItem>
        </Row>
      </Container>
    );
  }
}

export default createFragmentContainer(
  VendorsHeaderBar,
  graphql`
    fragment VendorsHeaderBar_org on Org {
      name
      viewerCanCreateVendors
    }
  `,
);
