/* @flow */
import type { Location } from 'react-router-dom';

import { type CellConfiguration } from 'components/material/table';

import EventAccessLevel from './EventAccessLevel';
import EventActionsColumn from './EventActionsColumn';
import EventDate from './EventDate';
import EventDateAdded from './EventDateAdded';
import EventLocation from './EventLocation';
import EventName from './EventName';
import EventOnsite from './EventOnsite';

export type StaffedEventCellPropsType<StaffedEvent: {}> = {|
  staffedEvent: StaffedEvent,
  userId: string,
  currentUserId: string,
  invitedUser: boolean,
  location: Location,
|};

const colsConfig: CellConfiguration = [
  {
    title: 'Event Name',
    sortKey: 'EVENT_NAME',
    component: EventName,
    grow: 1,
  },
  {
    title: 'Event Location',
    sortKey: 'EVENT_LOCATION',
    component: EventLocation,
    grow: 1,
  },
  {
    title: 'Event Dates',
    sortKey: 'EVENT_DATE',
    component: EventDate,
    width: 205,
  },
  {
    title: 'Access Level',
    sortKey: 'EVENT_ACCESS_LEVEL',
    component: EventAccessLevel,
    width: 140,
  },
  {
    title: 'Based',
    sortKey: 'ONSITE',
    component: EventOnsite,
    width: 120,
  },
  {
    title: 'Added to Event Staff',
    sortKey: 'EVENT_DATE_ADDED',
    component: EventDateAdded,
    width: 180,
  },
  {
    title: '',
    component: EventActionsColumn,
    type: 'action',
  },
];

export default colsConfig;
