/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactOpportunities_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactPageRoutes_org$ref: FragmentReference;
export type ContactPageRoutes_org = {|
  +$fragmentRefs: ContactOpportunities_org$ref,
  +$refType: ContactPageRoutes_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactPageRoutes_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ContactOpportunities_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '17c09b777f598b9b2ccaff00bec99eb2';
module.exports = node;
