/**
 * @flow
 * @relayHash 3db6017b5fa6d5ebaf0c5b355b93991c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportContactsInput = {
  clientMutationId?: ?string,
  eventId?: ?string,
  fileName: string,
  fileContent: string,
  mappings: ContactImportMappings,
};
export type ContactImportMappings = {
  firstName?: ?string,
  lastName?: ?string,
  owner?: ?string,
  contactType?: ?string,
  companyName?: ?string,
  title?: ?string,
  email?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  website?: ?string,
  twitter?: ?string,
  linkedin?: ?string,
  description?: ?string,
  country?: ?string,
  state?: ?string,
  city?: ?string,
  zip?: ?string,
  street?: ?string,
  emailOptIn?: ?string,
  hotLead?: ?string,
  registrationStatus?: ?string,
  attendanceStatus?: ?string,
  customFields?: ?$ReadOnlyArray<CustomFieldImportType>,
};
export type CustomFieldImportType = {
  customFieldId: string,
  columnName: string,
};
export type importContactsMutationVariables = {|
  input: ImportContactsInput
|};
export type importContactsMutationResponse = {|
  +importContacts: ?{|
    +uuid: string
  |}
|};
export type importContactsMutation = {|
  variables: importContactsMutationVariables,
  response: importContactsMutationResponse,
|};
*/


/*
mutation importContactsMutation(
  $input: ImportContactsInput!
) {
  importContacts(input: $input) {
    uuid
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportContactsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "importContacts",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ImportContactsInput!"
      }
    ],
    "concreteType": "ImportContactsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uuid",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "importContactsMutation",
  "id": null,
  "text": "mutation importContactsMutation(\n  $input: ImportContactsInput!\n) {\n  importContacts(input: $input) {\n    uuid\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "importContactsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "importContactsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '478ef033c620a50369762abfd2977aff';
module.exports = node;
