/**
 * @flow
 * @relayHash b910635fed871484be0d955605bbb531
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRowComponentInput = {
  clientMutationId?: ?string,
  id: string,
  cellSpacing?: ?number,
  padding?: ?string,
  backgroundColor?: ?string,
};
export type updateRowComponentMutationVariables = {|
  input: UpdateRowComponentInput
|};
export type updateRowComponentMutationResponse = {|
  +updateRegistrationFormRowComponent: ?{|
    +pageComponent: {|
      +rowComponent: ?{|
        +id: string,
        +padding: string,
        +cellSpacing: ?number,
        +backgroundColor: ?string,
      |}
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type updateRowComponentMutation = {|
  variables: updateRowComponentMutationVariables,
  response: updateRowComponentMutationResponse,
|};
*/


/*
mutation updateRowComponentMutation(
  $input: UpdateRowComponentInput!
) {
  updateRegistrationFormRowComponent(input: $input) {
    pageComponent {
      rowComponent {
        id
        padding
        cellSpacing
        backgroundColor
      }
      id
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateRowComponentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateRowComponentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "rowComponent",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationRowComponent",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "padding",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cellSpacing",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundColor",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateRowComponentMutation",
  "id": null,
  "text": "mutation updateRowComponentMutation(\n  $input: UpdateRowComponentInput!\n) {\n  updateRegistrationFormRowComponent(input: $input) {\n    pageComponent {\n      rowComponent {\n        id\n        padding\n        cellSpacing\n        backgroundColor\n      }\n      id\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateRowComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormRowComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateRowComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3
            ]
          },
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateRowComponentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormRowComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateRowComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3,
              v2
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae083701ec8a98ee389ea7600edec844';
module.exports = node;
