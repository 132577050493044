/* @flow */
import React from 'react';
import { type Location, type Match, type RouterHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import UserActions from 'actions/UserActions';
import qs from 'qs';

import getHomepageUrl from 'utils/getHomepageUrl';

import AuthBackground from 'components/Auth/AuthBackground';
import AuthButton from 'components/Auth/AuthButton';
import AuthFooter from 'components/Auth/AuthFooter';
import AuthWindow from 'components/Auth/AuthWindow';

import checkSAMLEnabled from '../../Auth/lib/checkSAMLEnabled';
import Form from './Form';

const SAMLButton = styled(AuthButton)`
  margin-top: 40px;
  padding: 10px 14px;
  border-radius: 52px;
  background-color: #5e5bff;

  &:hover {
    background-color: #403ec9;
  }
`;

const StyledParagraph = styled.p`
  a {
    color: #5554c3;
  }
`;

export default class Confirm extends React.PureComponent<
  {
    match: Match,
    history: RouterHistory,
    location: Location,
  },
  {
    data: ?{
      first_name: string,
      last_name: string,
      email: string,
    },
    samlEnabled: ?boolean,
    orgName: ?string,
    orgPrimaryColor: ?string,
  },
> {
  state = {
    data: null,
    samlEnabled: null,
    orgName: null,
    orgPrimaryColor: null,
  };

  componentDidMount() {
    const token = this.props.match.params.token || '';

    fetch(`/users/confirmation?confirmation_token=${token}`)
      .then(response => {
        if (!response.ok) {
          return response.json().then(body => {
            if (body.error) {
              const search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
              // redirect to next page when user clicks see in Circa button second time
              // in task assignee notification email
              if (search.next || body.redirect) {
                this.props.history.replace(search.next || body.redirect);
                return null;
              }
              throw new Error(body.error);
            }

            throw new Error('An unexpected error has occurred.');
          });
        }

        return response.json().then(user => {
          if (user.access_token != null) {
            UserActions.updateUser(user);

            if (user.onboarding_step >= 0 && user.onboarding_step < 6) {
              window.location = '/onboarding';
            } else {
              this.props.history.replace(
                getHomepageUrl(
                  user.homepage_list_id == null ? user.homepage : user.homepage_list_id,
                  user.user,
                ),
              );
            }
          } else {
            this.setState({ data: user });

            return user.email;
          }
          return null;
        });
      })
      .catch(() => {
        this.props.history.replace('/');
      })
      .then(email => {
        if (email) {
          return checkSAMLEnabled(email);
        }
        return { enabled: false, orgName: null, orgPrimaryColor: null };
      })
      .then(({ enabled, orgName, orgPrimaryColor }) =>
        this.setState({
          samlEnabled: enabled,
          orgName,
          orgPrimaryColor,
        }),
      )
      .catch(() => {
        this.setState({ samlEnabled: false });
      });
  }

  handleSAMLLogin = () => {
    if (this.state.data) {
      const domain = this.state.data.email.split('@').pop();
      window.location.href = `/saml/init?domain=${domain}`;
    }
  };

  render() {
    const token = this.props.match.params.token || '';
    const search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { data, samlEnabled } = this.state;

    if (!data) return null;

    const title = (
      <span>
        Welcome to Circa
        <br />
        <span>
          You&apos;ve been invited to join your company&apos;s workspace.
          <br />
          {samlEnabled ? 'Use your SSO account to sign in.' : 'Please set a password to continue.'}
        </span>
      </span>
    );

    return (
      <AuthBackground>
        <AuthWindow title={title}>
          {samlEnabled && (
            <SAMLButton
              label={`Sign In with ${this.state.orgName || ''}`}
              onClick={this.handleSAMLLogin}
              color={this.state.orgPrimaryColor}
            />
          )}
          {!samlEnabled && <Form token={token} history={this.props.history} />}
          <AuthFooter>
            {!samlEnabled ? (
              <StyledParagraph>
                Already have an account? <Link to={search.next || '/'}>Sign In</Link>
              </StyledParagraph>
            ) : null}
          </AuthFooter>
        </AuthWindow>
      </AuthBackground>
    );
  }
}
