/* @flow */
import * as React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';
import styled from 'styled-components';

import formatDateRange from 'utils/date/formatDateRange';
import formatLocation from 'utils/locations/formatLocation';

import SelectionRow from './index';

import type { EventSelectionRow_event } from './__generated__/EventSelectionRow_event.graphql';

const Container = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;

const EventName = styled.div`
  line-height: 1.08;
`;

const EventDetails = styled.div`
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.08;
  color: rgba(74, 86, 101, 0.54);
`;

class EventSelectionRow extends React.Component<{
  event: EventSelectionRow_event,
  onRemove: ?(id: string) => void,
}> {
  render() {
    const event = this.props.event;
    const details = [
      event.startDate != null
        ? formatDateRange(
            moment.tz(event.startDate, event.tz),
            event.endDate && moment.tz(event.endDate, event.tz),
          )
        : false,
      event.primaryLocation != null ? formatLocation(event.primaryLocation) : false,
    ].filter(Boolean);
    return (
      <SelectionRow value={this.props.event.id} onRemove={this.props.onRemove}>
        <Container>
          <EventName>{this.props.event.name}</EventName>
          {details.length > 0 && <EventDetails>{details.join(', ')}</EventDetails>}
        </Container>
      </SelectionRow>
    );
  }
}

export default createFragmentContainer(
  EventSelectionRow,
  graphql`
    fragment EventSelectionRow_event on Event {
      id
      name
      startDate
      endDate
      tz
      primaryLocation {
        name
        city
        state
        country
      }
    }
  `,
);
