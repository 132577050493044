/* @flow */
import * as React from 'react';
import {
  type ConnectDragPreview,
  type ConnectDragSource,
  type ConnectDropTarget,
  type DragSourceConnector,
  type DragSourceMonitor,
  type DropTargetConnector,
  type DropTargetMonitor,
  DragSource,
  DropTarget,
} from 'react-dnd';
import styled, { css } from 'styled-components';

import DragIcon from 'images/drag.svg';

import type { QuestionType, ReorderedQuestionType } from '../lib/types';

const DragIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 45px;
  z-index: 1;
  cursor: grab;
  visibility: hidden;
  opacity: 0.42;
  &:hover {
    visibility: visible;
    opacity: 1;
  }
  svg {
    width: 8px;
    height: 14px;
    color: #4a5665;
    &:hover {
      color: #3e4858;
    }
  }
`;

const DropAreaMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  word-break: break-word;
  color: #3e4859;
  visibility: hidden;
  text-align: center;
`;

const Container = styled.div`
  position: relative;
  &:hover {
    ${DragIconWrapper} {
      visibility: visible;
    }
  }

  ${props =>
    props.hovered &&
    !props.dragged &&
    css`
      background-color: ${props.restrictedDruggingZone ? '#ffffff' : '#f1f9fc'};
      border: 1px dashed #5db5d5;
      border-image: url('/images/component_hover_border.png') 1 round;
      transition: 0.2s;
      color: #3ba9da;

      ${props.restrictedDruggingZone &&
      css`
        > * {
          visibility: hidden;
        }
        ${DropAreaMessage} {
          visibility: visible;
        }
      `};
    `};
`;
const DragLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

class EditableQuestionDraggable extends React.PureComponent<{
  children: React.Node,
  connectDragSource?: ConnectDragSource,
  connectDragPreview?: ConnectDragPreview,
  connectDropTarget?: ConnectDropTarget,
  hovered?: boolean,
  dragged?: boolean,
  restrictedDruggingZone: boolean,
  /* eslint-disable react/no-unused-prop-types */
  // Used in Hover handler
  question: QuestionType,
  onBeginDrag?: (questionId: string) => void,
  onMove: (reorderedQuestion: ReorderedQuestionType) => void,
  onMoveEnd: (reorderedQuestion: ReorderedQuestionType) => void,
}> {
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      restrictedDruggingZone,
      hovered,
      dragged,
      children,
    } = this.props;

    if (!connectDragSource || !connectDropTarget || !connectDragPreview) return null;

    return connectDropTarget(
      <div>
        {connectDragPreview(
          <div>
            <Container
              hovered={hovered}
              dragged={dragged}
              restrictedDruggingZone={restrictedDruggingZone}
            >
              {hovered && <DragLayer />}
              {children}
              {connectDragSource(
                <div>
                  <DragIconWrapper>
                    <DragIcon />
                  </DragIconWrapper>
                  <DropAreaMessage>
                    This question cannot be moved here because of logical dependencies it has.
                  </DropAreaMessage>
                </div>,
              )}
            </Container>
          </div>,
        )}
      </div>,
    );
  }
}

const ConnectedDraggableComponent = DragSource(
  'QUESTION',
  {
    beginDrag(props: $PropertyType<EditableQuestionDraggable, 'props'>) {
      const { onBeginDrag, question } = props;
      if (onBeginDrag) {
        onBeginDrag(question.id);
      }
      return { ...question };
    },
    endDrag(props: $PropertyType<EditableQuestionDraggable, 'props'>, monitor: DragSourceMonitor) {
      const dropAreaConfig = monitor.getDropResult();
      props.onMoveEnd(
        dropAreaConfig && {
          question: props.question,
          order: dropAreaConfig.order,
        },
      );
    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    dragged: monitor.isDragging(),
  }),
)(EditableQuestionDraggable);

const ConnectedDroppableComponent = DropTarget(
  'QUESTION',
  {
    drop(props) {
      return { order: props.question.order };
    },
    hover(props: $PropertyType<EditableQuestionDraggable, 'props'>, monitor: DropTargetMonitor) {
      const draggingQuestion: QuestionType = monitor.getItem();
      // lastHoverQuestionId is used for stopping infinite loop of questions changing places
      if (this.lastHoverQuestionId === props.question.id) {
        return;
      }
      this.lastHoverQuestionId = props.question.id;
      props.onMove({
        question: draggingQuestion,
        order: props.question.order,
      });
    },
  },
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
  }),
)(ConnectedDraggableComponent);

export default ConnectedDroppableComponent;
