import PropTypes from 'prop-types';
import React from 'react';
import TextArea from 'react-textarea-autosize';

export default class Name extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };
  state = {
    editing: false,
  };

  handleBlur = e => {
    const value = e.target.value;

    this.setState({ editing: false });

    if (value !== this.props.value) {
      this.props.onChange(value);
    }
  };
  handleKeyDown = e => {
    if (e.which === 13 && !e.shiftKey) {
      e.target.blur();
    } else if (e.which === 27 && this.props.value) {
      this.setState({ editing: false });
    }
  };
  render() {
    const { value, disabled } = this.props;
    const { editing } = this.state;

    if (editing) {
      return (
        <div className="schedule-list-row-location">
          <TextArea
            defaultValue={value}
            placeholder="Location"
            autoFocus
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown}
          />
        </div>
      );
    }

    return (
      <div className="schedule-list-row-location">
        {value ? (
          <div>
            <i className="fa fa-fw fa-map-marker" />
            <p
              title={value}
              onClick={!disabled ? () => this.setState({ editing: true }) : undefined}
            >
              {value.split('\n').map((str, i) => (
                <span key={i}>
                  {str}
                  <br />
                </span>
              ))}
            </p>
          </div>
        ) : (
          (!disabled && (
            <div className="schedule-list-row-empty">
              <a onClick={() => this.setState({ editing: true })}>
                <i className="fa fa-fw fa-map-marker" /> Location
              </a>
            </div>
          )) ||
          undefined
        )}
      </div>
    );
  }
}
