/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link } from 'react-router-dom';

import type { EventName_staffedEvent } from './__generated__/EventName_staffedEvent.graphql';

type Props = {
  staffedEvent: EventName_staffedEvent,
  location: Location,
};

const stopPropagation = (e: SyntheticMouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

const EventName = ({ staffedEvent, location }: Props) => {
  if (staffedEvent.event.viewerCanView) {
    return (
      <Link
        to={{
          pathname: `/events/${staffedEvent.event.slug}`,
          state: {
            from: location.pathname === '/settings' ? 'settings' : 'member',
            fromUrl: location.pathname,
          },
        }}
        onClick={stopPropagation}
      >
        {staffedEvent.event.name}
      </Link>
    );
  }

  return staffedEvent.event.name;
};

export default createFragmentContainer(
  EventName,
  graphql`
    fragment EventName_staffedEvent on StaffMembership {
      event {
        slug
        name
        viewerCanView
      }
    }
  `,
);
