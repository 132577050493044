/* @flow */

import React from 'react';
import styled from 'styled-components';

import CompanyIcon from 'components/Participants/CompanyIcon';
import BoldText from './BoldText';
import { type Field } from './types';

import { BriefUserName, BriefUserEmail, BriefUserPhone, BriefUserTag } from './BriefUser';

const Content = styled.div`
  display: flex;
`;

const Title = styled.div`
  color: #828b93;
`;

const StyledCompanyIcon = styled(CompanyIcon)`
  border: 2px solid #828b93;
`;

type Vendor = {
  id: string,
  firstName: ?string,
  lastName: ?string,
  email: ?string,
  phone1: ?string,
  phone2: ?string,
  title: ?string,
  name: string,
};

type Props = {
  customFields: $ReadOnlyArray<Field<'VENDOR'>>,
  vendor: $ReadOnly<Vendor>,
  showTag?: boolean,
  className?: string,
  size?: number,
  iconSize?: number,
};

const VendorField = ({ vendor, customFields, size, iconSize, className, showTag }: Props) => {
  const { firstName, lastName, title, name, email, phone1, phone2 } = vendor;

  const enabledFields = customFields.map(field => field.fieldName);

  return (
    <Content className={className}>
      <StyledCompanyIcon
        size={size != null ? size : 40}
        iconSize={iconSize != null ? iconSize : 12}
      />
      <div>
        <BoldText>{name}</BoldText>
        {showTag && <BriefUserTag tag="vendor" />}
        {(enabledFields.includes('firstName') || enabledFields.includes('lastName')) && (
          <BriefUserName firstName={firstName} lastName={lastName} />
        )}
        {enabledFields.includes('title') && <Title>{title}</Title>}
        {enabledFields.includes('email') && <BriefUserEmail email={email} />}
        {enabledFields.includes('phone1') && <BriefUserPhone phone={phone1} />}
        {enabledFields.includes('phone2') && <BriefUserPhone phone={phone2} />}
      </div>
    </Content>
  );
};

export default VendorField;
