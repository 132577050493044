/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventInfoBrief_org$ref = any;
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefViewContainer_org$ref: FragmentReference;
export type BriefViewContainer_org = {|
  +customFields: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +customizableType: CustomizableType,
        +fieldName: ?string,
      |}
    |}>
  |},
  +$fragmentRefs: EventInfoBrief_org$ref,
  +$refType: BriefViewContainer_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BriefViewContainer_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EventInfoBrief_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFields",
      "storageKey": "customFields(customizableType:[\"CONTACT\",\"COMPANY\",\"VENDOR\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": [
            "CONTACT",
            "COMPANY",
            "VENDOR"
          ],
          "type": "[CustomizableType!]!"
        }
      ],
      "concreteType": "CustomFieldTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldType",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "customizableType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fieldName",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '16f23e3c9542d0ca1605c3c4505b83be';
module.exports = node;
