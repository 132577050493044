/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  removeBudgetReportingViewMutationVariables,
  removeBudgetReportingViewMutationResponse,
} from './__generated__/removeBudgetReportingViewMutation.graphql';

const mutation = graphql`
  mutation removeBudgetReportingViewMutation($input: RemoveBudgetReportingViewInput!) {
    removeBudgetReportingView(input: $input) {
      removedBudgetReportingViewId
    }
  }
`;

export default function removeBudgetReportingView(
  orgId: string,
  budgetReportingViewId: string,
): Promise<removeBudgetReportingViewMutationResponse> {
  const variables: removeBudgetReportingViewMutationVariables = {
    input: {
      budgetReportingViewId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedBudgetReportingViewId',
        parentId: orgId,
        connections: [{ field: 'budgetReportingViews' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedBudgetReportingViewId' }),
    ),
  });
}
