/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import type {
  DragToColumnPropsType,
  PageComponentKindType,
  ReorderedPageComponentConfigType,
} from '../../pageComponentsConfig';
import type { SelectedComponent } from '../../RegistrationCreateContent';
import generatePaddingProps from '../generatePaddingProps';
import ColumnComponent from './ColumnComponent';

import type { RegistrationPageContent_org } from '../__generated__/RegistrationPageContent_org.graphql';
import { type RowComponent_componentProps } from './__generated__/RowComponent_componentProps.graphql';
import { type RowComponent_registrationForm } from './__generated__/RowComponent_registrationForm.graphql';

const Container = styled.div`
  display: flex;
  padding: ${props => generatePaddingProps(props.componentProps.padding)};
  background-color: ${props => props.componentProps.backgroundColor || 'initial'};
`;

const LeftColumnComponent = styled(ColumnComponent)`
  margin-right: ${props => props.rightMargin}px;
`;

class RowComponent extends React.PureComponent<{
  org: RegistrationPageContent_org,
  registrationForm: RowComponent_registrationForm,
  componentProps: RowComponent_componentProps,
  selectedComponent: SelectedComponent,
  selectedComponentId: ?string,
  draggedComponentId: ?string,
  draggedComponentKind: ?PageComponentKindType,
  hoveredPageComponent: ?string,
  draggedToColumnProps?: DragToColumnPropsType,
  onSelectPageComponent: (id: string) => void,
  onUnselectComponent: () => void,
  onMoveEnd: (reorderedPageComponentConfig?: ReorderedPageComponentConfigType) => void,
  onBeginDrag: (id: string) => void,
  onShowPageComponents: () => void,
  onResetDraggedToColumnProps: () => void,
  readOnly?: boolean,
  defaultFont: string,
}> {
  render() {
    const {
      org,
      registrationForm,
      selectedComponent,
      selectedComponentId,
      draggedComponentId,
      onSelectPageComponent,
      onUnselectComponent,
      onBeginDrag,
      onShowPageComponents,
      onMoveEnd,
      onResetDraggedToColumnProps,
      draggedComponentKind,
      hoveredPageComponent,
      draggedToColumnProps,
      componentProps: { id, rowComponent, childPageComponents },
      readOnly,
      defaultFont,
    } = this.props;

    if (!rowComponent || !childPageComponents) {
      return null;
    }
    const columnPageComponentNodes = sortBy(
      childPageComponents.edges
        .map(({ node }) => node)
        .filter(component => component.kind === 'COLUMN'),
      'order',
    );
    const rowSelected = id === selectedComponentId;
    const componentProps =
      rowSelected && selectedComponent && selectedComponent.selectedComponentPadding != null
        ? {
            ...rowComponent,
            padding: selectedComponent.selectedComponentPadding,
          }
        : rowComponent;
    return (
      <Container componentProps={componentProps}>
        <LeftColumnComponent
          key={columnPageComponentNodes[0].id}
          org={org}
          defaultFont={defaultFont}
          registrationForm={registrationForm}
          componentProps={columnPageComponentNodes[0]}
          rightMargin={
            rowSelected && selectedComponent && selectedComponent.selectedRowCellSpacing != null
              ? selectedComponent.selectedRowCellSpacing
              : rowComponent.cellSpacing
          }
          selectedComponentId={selectedComponentId}
          draggedComponentId={draggedComponentId}
          draggedComponentKind={draggedComponentKind}
          hoveredPageComponent={hoveredPageComponent}
          onSelectPageComponent={onSelectPageComponent}
          onUnselectComponent={onUnselectComponent}
          onMoveEnd={onMoveEnd}
          onBeginDrag={onBeginDrag}
          onShowPageComponents={onShowPageComponents}
          resetOptimisticChildId={onResetDraggedToColumnProps}
          readOnly={readOnly}
          optimisticChildId={
            draggedToColumnProps && draggedToColumnProps.columnId === columnPageComponentNodes[0].id
              ? draggedToColumnProps.id
              : undefined
          }
          {...(!rowSelected &&
          columnPageComponentNodes[0].columnChild &&
          selectedComponent &&
          columnPageComponentNodes[0].columnChild.id === selectedComponent.selectedComponentId
            ? { selectedComponent }
            : {})}
        />
        <ColumnComponent
          key={columnPageComponentNodes[1].id}
          org={org}
          defaultFont={defaultFont}
          registrationForm={registrationForm}
          componentProps={columnPageComponentNodes[1]}
          selectedComponentId={selectedComponentId}
          draggedComponentId={draggedComponentId}
          draggedComponentKind={draggedComponentKind}
          hoveredPageComponent={hoveredPageComponent}
          optimisticChildId={
            draggedToColumnProps && draggedToColumnProps.columnId === columnPageComponentNodes[1].id
              ? draggedToColumnProps.id
              : undefined
          }
          onSelectPageComponent={onSelectPageComponent}
          onUnselectComponent={onUnselectComponent}
          onMoveEnd={onMoveEnd}
          onBeginDrag={onBeginDrag}
          onShowPageComponents={onShowPageComponents}
          resetOptimisticChildId={onResetDraggedToColumnProps}
          readOnly={readOnly}
          {...(!rowSelected &&
          columnPageComponentNodes[1].columnChild &&
          selectedComponent &&
          columnPageComponentNodes[1].columnChild.id === selectedComponent.selectedComponentId
            ? { selectedComponent }
            : {})}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(RowComponent, {
  registrationForm: graphql`
    fragment RowComponent_registrationForm on RegistrationForm {
      id
      ...ColumnComponent_registrationForm
    }
  `,
  componentProps: graphql`
    fragment RowComponent_componentProps on RegistrationPageComponent {
      id
      childPageComponents {
        edges {
          node {
            id
            kind
            order
            columnChild {
              id
            }
            ...ColumnComponent_componentProps
          }
        }
      }
      rowComponent {
        id
        cellSpacing
        padding
        backgroundColor
      }
    }
  `,
});
