/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import createSalesforceMapping, {
  type SalesforceMappingInputType,
} from 'graph/mutations/salesforce/createSalesforceMapping';
import removeSalesforceMapping from 'graph/mutations/salesforce/removeSalesforceMapping';
import updateSalesforceMapping from 'graph/mutations/salesforce/updateSalesforceMapping';
import updateSalesforceOpportunityStatus from 'graph/mutations/salesforce/updateSalesforceOpportunityStatus';
import updateSalesforceSettings from 'graph/mutations/salesforce/updateSalesforceSettings';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InfoIcon from 'images/infoIcon.svg';
import CheckBox from 'components/material/CheckBox';
import Switch from 'components/material/Switch';
import Tooltip from 'components/material/Tooltip';

import DefaultLeadSource from './DefaultLeadSource';
import fields from './lib/fields';
import salesforceKindLabel from './lib/salesforceKindLabel';
import { type GroupField } from './lib/types';
import RequiredFieldsWarning from './RequiredFieldsWarning';
import SalesforceMappingRow from './SalesforceMappingRow';

import type {
  SalesforceKindType,
  SalesforceMapping_org,
} from './__generated__/SalesforceMapping_org.graphql';

const Section = styled.div`
  &:not(:empty) {
    margin-bottom: 50px;
  }
`;

const SystemFieldToggle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const Label = styled.div`
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 1em;
  color: #3e4859;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin: 0 9px 0 19px;
`;

const StatusSection = styled.div`
  margin: 40px 0 60px;
`;

const StatusesTitle = styled.div`
  margin-bottom: 40px;
  color: #4a5665;
  font-size: 14px;
  line-height: 1em;
`;

export const StyledCheckBox = styled(CheckBox)`
  padding: 7px 0 7px 20px;
  font-size: 14px;
  line-height: 1;
  color: ${props => props.theme.oxfordBlue};
  > div:first-of-type {
    color: ${props => (props.checked ? props.theme.highlightColor : '#cdd1d4')};
  }
  &:last-of-type {
    margin-bottom: 1px;
  }
  &:hover {
    background-color: ${props => props.theme.hoverRowColor};
  }
`;

class SalesforceMapping extends React.PureComponent<
  {
    org: SalesforceMapping_org,
    kinds: $ReadOnlyArray<SalesforceKindType>,
  },
  {
    systemFieldsShown: boolean,
  },
> {
  state = { systemFieldsShown: true };

  handleCreateMapping = (input: SalesforceMappingInputType) => {
    if (!this.props.org.salesforceAccount) return;

    createSalesforceMapping(this.props.org.salesforceAccount.id, this.props.kinds, input).catch(
      showModernMutationError,
    );
  };

  handleUpdateMapping = (input: SalesforceMappingInputType) => {
    updateSalesforceMapping(input).catch(showModernMutationError);
  };

  handleRemoveMapping = (mappingId: string) => {
    if (!this.props.org.salesforceAccount) return;

    removeSalesforceMapping(this.props.org.salesforceAccount.id, this.props.kinds, {
      mappingId,
    }).catch(showModernMutationError);
  };

  handleUpdateSetting = (setting: string, value: boolean) => {
    updateSalesforceSettings({ [setting]: value });
  };

  toggleSystemFields = () => {
    this.setState(prevState => ({ systemFieldsShown: !prevState.systemFieldsShown }));
  };

  onStatusToggle = (id: string, enabled: boolean) => {
    updateSalesforceOpportunityStatus({ id, enabled }).catch(showModernMutationError);
  };

  render() {
    const { org, kinds } = this.props;
    const salesforceAccount = org.salesforceAccount;
    const systemFieldsShown = this.state.systemFieldsShown;

    if (!salesforceAccount) return null;

    const mappingFields: {
      [SalesforceKindType]: $ReadOnlyArray<GroupField>,
    } = kinds.reduce((obj, kind) => ({ ...obj, [kind]: fields(org)[kind] }), {});
    const salesforceFields = salesforceAccount.salesforceFields.edges.map(edge => edge.node);
    const salesforceMappings = salesforceAccount.salesforceMappings.edges.map(edge => edge.node);
    const activeFieldPairs = [
      ...salesforceMappings.map(mapping => [mapping.circaField, mapping.salesforceField]),
      ...Object.keys(mappingFields)
        .reduce((array, kind) => [...array, ...mappingFields[kind]], [])
        .reduce(
          (arr, group) => [
            ...arr,
            ...group.fields.map(f => (f.fixedMappingField ? [f.name, f.fixedMappingField] : null)),
          ],
          [],
        )
        .filter(Boolean),
    ];
    const activeFields = activeFieldPairs.map(arr => arr[1]);

    return (
      <div>
        <RequiredFieldsWarning
          salesforceFields={salesforceFields}
          activeFields={activeFields}
          kinds={kinds}
        />

        {kinds.includes('LEAD') && (
          <Section>
            <DefaultLeadSource org={org} />
          </Section>
        )}
        {org.salesforceAccount && org.salesforceAccount.opportunityStatuses && (
          <StatusSection>
            <StatusesTitle>
              Select which opportunity statuses you would like to include or exclude in tracking and
              reporting.
            </StatusesTitle>
            {org.salesforceAccount.opportunityStatuses.edges.map(({ node }) => (
              <StyledCheckBox
                label={node.name}
                key={node.id}
                checked={node.enabled}
                onChange={(checked: boolean) => this.onStatusToggle(node.id, checked)}
                disabled={
                  node.enabled &&
                  org.salesforceAccount != null &&
                  org.salesforceAccount.opportunityStatuses != null &&
                  org.salesforceAccount.opportunityStatuses.edges.filter(edge => edge.node.enabled)
                    .length === 1
                }
              />
            ))}
          </StatusSection>
        )}
        {Object.keys(mappingFields).map(kind => {
          const salesforceObjectLabel = salesforceKindLabel(kind);

          return mappingFields[kind].map(group => (
            <Section key={group.title}>
              {kinds.includes('OPPORTUNITY') && (
                <SystemFieldToggle>
                  <Label>Show system fields</Label>
                  <Tooltip label="Toogle System Fields.">
                    <StyledInfoIcon />
                  </Tooltip>

                  <Switch
                    enabled={this.state.systemFieldsShown}
                    onChange={this.toggleSystemFields}
                  />
                </SystemFieldToggle>
              )}
              {group.fields
                .map(field => {
                  const salesforceMapping =
                    salesforceMappings.find(mapping =>
                      mapping.customField
                        ? mapping.customField.id === field.customFieldId
                        : mapping.circaField === field.name,
                    ) || null;
                  const kindSalesforceFields = salesforceFields.filter(
                    salesforceField => salesforceField.kind === kind,
                  );

                  return field.customFieldId || systemFieldsShown ? (
                    <SalesforceMappingRow
                      key={field.customFieldId || field.name}
                      salesforceObjectLabel={salesforceObjectLabel}
                      field={field}
                      salesforceFields={kindSalesforceFields}
                      salesforceMapping={salesforceMapping}
                      activeFields={activeFields}
                      kind={kind}
                      onCreate={this.handleCreateMapping}
                      onUpdate={this.handleUpdateMapping}
                      onRemove={this.handleRemoveMapping}
                      onUpdateSetting={this.handleUpdateSetting}
                    />
                  ) : null;
                })
                .filter(Boolean)}
            </Section>
          ));
        })}
      </div>
    );
  }
}

export default createFragmentContainer(
  SalesforceMapping,
  graphql`
    fragment SalesforceMapping_org on Org {
      syncedToIbmWm
      salesforceAccount {
        id
        salesforceFields(kinds: $kinds) {
          edges {
            node {
              kind
              ...RequiredFieldsWarning_salesforceFields
              ...SalesforceMappingRow_salesforceFields
            }
          }
        }
        salesforceMappings(kinds: $kinds) {
          edges {
            node {
              kind
              circaField
              salesforceField
              customField {
                id
              }
              ...SalesforceMappingRow_salesforceMapping
            }
          }
        }
        ... @include(if: $isOpportunity) {
          opportunityStatuses {
            edges {
              node {
                id
                name
                enabled
              }
            }
          }
        }
      }

      ... @include(if: $isContact) {
        ...DefaultLeadSource_org

        contactCustomFields: customFields(customizableType: [CONTACT]) {
          edges {
            node {
              id
              kind
              order
              label
              required
              fieldName
            }
          }
        }
      }

      ... @include(if: $isEventContact) {
        eventContactCustomFields: customFields(customizableType: [EVENTCONTACT]) {
          edges {
            node {
              id
              kind
              order
              label
              required
              fieldName
            }
          }
        }
      }

      ... @include(if: $isEvent) {
        eventCustomFields: customFields(customizableType: [EVENT]) {
          edges {
            node {
              id
              kind
              order
              label
              required
              fieldName
              section {
                order
              }
            }
          }
        }
      }
      ... @include(if: $isOpportunity) {
        opportunityCustomFields: customFields(customizableType: [SALESFORCEOPPORTUNITY]) {
          edges {
            node {
              id
              kind
              order
              label
              required
              fieldName
            }
          }
        }
      }
    }
  `,
);
