/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';

import SectionContent from '../components/SectionContent';
import SectionHeader from '../components/SectionHeader';
import { customFieldsMap, fieldLabelMap, fieldsMap } from './EventInfoFields/fieldsMap';

import { type EventInfoBrief_event } from './__generated__/EventInfoBrief_event.graphql';
import { type EventInfoBrief_org } from './__generated__/EventInfoBrief_org.graphql';
import { type EventInfoBrief_template } from './__generated__/EventInfoBrief_template.graphql';

const EventInfoBrief = (props: {
  name: string,
  org: EventInfoBrief_org,
  event: EventInfoBrief_event,
  template: EventInfoBrief_template,
  primaryColor: string,
}) => {
  const {
    name,
    event,
    org,
    primaryColor,
    template: { eventInfoSections: sections },
  } = props;
  const sortedSectionsFields = sortBy(
    sections.edges.map(edge => edge.node).filter(section => section.enabled),
    'order',
  ).reduce(
    (array, section) => [
      ...array,
      ...sortBy(
        section.fields.edges.map(edge => edge.node).filter(field => field.enabled),
        'order',
      ),
    ],
    [],
  );
  return sortedSectionsFields.length > 0 ? (
    <React.Fragment>
      <SectionHeader primaryColor={primaryColor} name={name} anchor="event_info" />
      <SectionContent>
        {sortedSectionsFields.map(field => {
          const Component = field.customField
            ? customFieldsMap[field.customField.kind]
            : field.fieldName && fieldsMap[field.fieldName];

          if (!Component) return null;

          const fieldLabel = field.customField
            ? field.customField.label
            : field.fieldName && fieldLabelMap[field.fieldName];

          return (
            <Component
              key={field.id}
              name={fieldLabel}
              event={event}
              org={org}
              primaryColor={primaryColor}
              fieldName={field.fieldName}
              customFieldId={field.customField && field.customField.id}
            />
          );
        })}
      </SectionContent>
    </React.Fragment>
  ) : null;
};

export default createFragmentContainer(
  EventInfoBrief,
  graphql`
    fragment EventInfoBrief_event on Event {
      ... @include(if: $includeEventStatus) {
        ...EventInfoStatus_event
      }
      ... @include(if: $includeEventProgress) {
        ...EventInfoProgress_event
      }
      ... @include(if: $includeEventDescription) {
        ...EventInfoDescription_event
      }
      ... @include(if: $includeEventFloorPlan) {
        ...EventInfoFloorPlan_event
      }
      ... @include(if: $includeEventLeader) {
        ...EventInfoLeader_event
      }
      ... @include(if: $includeEventDimensions) {
        ...EventInfoDimensions_event
      }
      ... @include(if: $includeEventBoothNumber) {
        ...EventInfoBooth_event
      }
      ... @include(if: $includeEventLastSynced) {
        ...EventInfoLastSynced_event
      }
      ... @include(if: $includeEventSalesforceId) {
        ...EventInfoSalesforceId_event
      }
      ... @include(if: $includeEventMarketoId) {
        ...EventInfoMarketoId_event
      }
      ... @include(if: $includeEventAmountAllOpportunities) {
        ...EventInfoAmountOfOpportunities_event
      }
      ... @include(if: $includeEventNumberOfOpportunities) {
        ...EventInfoNumberOfOpportunities_event
      }
      ... @include(if: $includeEventSyncStatus) {
        ...EventInfoSyncStatus_event
      }
      ... @include(if: $includeEventPlannedCost) {
        ...EventInfoPlannedBudget_event
      }
      ... @include(if: $includeEventActualCost) {
        ...EventInfoActualBudget_event
      }
      ...EventInfoCustomText_event
      ...EventInfoCustomTextarea_event
      ...EventInfoCustomLink_event
      ...EventInfoCustomNumber_event
      ...EventInfoCustomCurrency_event
      ...EventInfoCustomDate_event
      ...EventInfoCustomBoolean_event
      ...EventInfoCustomUserSelect_event
      ...EventInfoCustomUserMultiselect_event
      ...EventInfoCustomSelect_event
      ...EventInfoCustomMultiselect_event
    }
    fragment EventInfoBrief_template on BriefTemplate {
      eventInfoSections {
        edges {
          node {
            order
            enabled
            sectionName
            fields {
              edges {
                node {
                  id
                  order
                  enabled
                  fieldName
                  customField {
                    id
                    label
                    kind
                  }
                }
              }
            }
          }
        }
      }
    }
    fragment EventInfoBrief_org on Org {
      ... @include(if: $includeEventPlannedCost) {
        ...EventInfoPlannedBudget_org
      }
      ... @include(if: $includeEventActualCost) {
        ...EventInfoActualBudget_org
      }
      ... @include(if: $includeEventSyncStatus) {
        ...EventInfoSyncStatus_org
      }
      ... @include(if: $includeEventAmountAllOpportunities) {
        ...EventInfoAmountOfOpportunities_org
      }
      ...EventInfoCustomCurrency_org
    }
  `,
);
