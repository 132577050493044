/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpenseActualAmountCell_expense$ref: FragmentReference;
export type ExpenseActualAmountCell_expense = {|
  +id: string,
  +actualAmount: number,
  +viewerCanUpdateAmount: boolean,
  +$refType: ExpenseActualAmountCell_expense$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExpenseActualAmountCell_expense",
  "type": "Expense",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "actualAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateAmount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'aa73bde6af0b5db938d78ba1013f8226';
module.exports = node;
