/* @flow */
import { CONFIG_ENV } from 'config/variables';

const serverEnvironments = Object.freeze({
  development: 'development',
  test: 'test',
  staging: 'staging',
  production: 'production',
});

type ServerEnvironment = $Keys<typeof serverEnvironments>;

/**
 * Return 'development', 'test', 'staging', 'production', etc.
 * Use with caution.  Consider using a feature flag instead.
 */
export default function getEnvironment(): ServerEnvironment {
  // In tests, this `process.env.CONFIG_ENV` exists from node.  In webpack, this
  // value is injected with DefinePlugin.
  const envString: ?string = CONFIG_ENV;
  if (!envString || serverEnvironments[envString] === undefined) {
    throw new Error('CONFIG_ENV was not set during webpack compilation.');
  }

  return serverEnvironments[envString];
}
