/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { graphql } from 'react-relay';
import throttle from 'lodash/throttle';

import {
  type CreateVendorFromWindow,
  type ResponseVendor,
} from 'graph/mutations/vendor/createVendor';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import AutocompleteInput from 'components/material/AutocompleteInput';
import CreateVendorWindow from 'components/Vendors/CreateVendorWindow';

import NewParticipantRow from './NewParticipantRow';
import VendorSearchRow from './VendorSearchRow';

import type { VendorSearchQueryResponse } from './__generated__/VendorSearchQuery.graphql';

const query = graphql`
  query VendorSearchQuery($eventId: ID!, $includeEvent: Boolean!, $filters: VendorFilters!) {
    org {
      id
      viewerCanCreateVendors @skip(if: $includeEvent)
      vendors(first: 3, filters: $filters) {
        edges {
          node {
            id
            name
            ...VendorSearchRow_vendor
          }
        }
      }
    }
    event: node(id: $eventId) @include(if: $includeEvent) {
      ... on Event {
        viewerCanCreateVendors
      }
    }
  }
`;

export default class VendorSearch extends React.PureComponent<
  {
    eventId?: ?string,
    onSelect: (vendor: ?ResponseVendor) => void,
    stateful?: boolean,
    defaultValue?: string,
    label?: string,
    placeholder?: string,
    clearable?: boolean,
    autoFocus?: boolean,
    onToggleQueryState?: (present: boolean) => void,
    onShowCreateVendor?: () => void,
    onHideCreateVendor?: () => void,
    onBlur?: () => void,
    overlayContainer?: ?HTMLElement,
    resetOnBlur?: boolean,
    fromWindow: CreateVendorFromWindow,
    renderOptionToString?: (contact: ResponseVendor) => string,
  },
  {
    query: string,
    addingVendor: boolean,
  },
> {
  state = {
    query: this.props.stateful && this.props.defaultValue ? this.props.defaultValue : '',
    addingVendor: false,
  };

  cachedVendors: ?$ReadOnlyArray<ResponseVendor>;

  orgId: string;

  queryExists: boolean;

  handleFilter = throttle((str: string) => {
    this.setState({ query: str });
    this.checkSearchQueryState(str);
  }, 800);

  checkSearchQueryState = (str?: string = '') => {
    if (str.trim() && !this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(true);
      this.queryExists = true;
    }
    if (!str.trim() && this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(false);
      this.queryExists = false;
    }
  };

  handleWindowShow = () => {
    this.setState({ addingVendor: true });
    if (this.props.onShowCreateVendor) {
      this.props.onShowCreateVendor();
    }
  };

  handleWindowClose = () => {
    this.setState({ addingVendor: false });
    if (this.props.onHideCreateVendor) {
      this.props.onHideCreateVendor();
    }
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleCreate = (vendor: ResponseVendor) => this.props.onSelect(vendor);

  handleBlur = (autocompleteQuery: string) => {
    if (
      !this.props.resetOnBlur &&
      this.props.stateful &&
      !autocompleteQuery &&
      this.props.defaultValue != null
    ) {
      this.props.onSelect(null);
    }
    if (this.props.onBlur && !this.state.addingVendor) {
      this.props.onBlur();
    }
  };

  handleSelect = (vendor: ?ResponseVendor) => {
    this.props.onSelect(vendor);
    if (!vendor) {
      this.checkSearchQueryState();
    }
  };

  renderOption = (vendor: ResponseVendor) => <VendorSearchRow vendor={vendor} />;

  renderOptionToString = (vendor: ResponseVendor) => {
    if (this.props.renderOptionToString) {
      return this.props.renderOptionToString(vendor);
    }

    if (this.props.stateful) {
      return vendor.name;
    }
    return '';
  };

  renderCreate = () => <NewParticipantRow label="Add Vendor" onClick={this.handleWindowShow} />;

  renderInput = (response?: VendorSearchQueryResponse) => {
    if (response && response.org) {
      this.cachedVendors = response.org.vendors.edges.map(edge => edge.node);
      this.orgId = response.org.id;
    }
    const canCreateVendor =
      response &&
      ((response.event && response.event.viewerCanCreateVendors) ||
        (response.org && response.org.viewerCanCreateVendors));
    return (
      <React.Fragment>
        <AutocompleteInput
          defaultQuery={this.props.defaultValue}
          placeholder={this.props.placeholder || 'Search vendors'}
          label={this.props.label}
          options={this.cachedVendors || []}
          onFilterOptions={this.handleFilter}
          onSelect={this.handleSelect}
          onBlur={this.handleBlur}
          renderOption={this.renderOption}
          renderOptionString={this.renderOptionToString}
          clearable={this.props.clearable}
          autoFocus={this.props.autoFocus}
          footerContent={canCreateVendor ? this.renderCreate() : null}
          overlayContainer={this.props.overlayContainer}
          resetOnBlur={this.props.resetOnBlur}
        />

        {this.state.addingVendor &&
          document.body &&
          createPortal(
            <CreateVendorWindow
              onHide={this.handleWindowClose}
              onSave={this.handleCreate}
              fromWindow={this.props.fromWindow}
            />,
            document.body,
          )}
      </React.Fragment>
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          eventId: this.props.eventId || '',
          filters: { query: this.state.query },
          includeEvent: !!this.props.eventId,
        }}
        renderSuccess={this.renderInput}
        renderLoading={this.renderInput}
      />
    );
  }
}
