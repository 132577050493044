/* @flow */
import styled from 'styled-components';

import Button from 'components/material/Button';

export default styled(Button)`
  font-size: 18px;
  padding: 7px;
  width: 100%;
`;
