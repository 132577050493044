/**
 * @flow
 * @relayHash 6c7a294d46191029ccb91a5e84641e55
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSearchRow_user$ref = any;
export type OrgReviewerSearchQueryVariables = {|
  query: string,
  hasTeamAccess?: ?boolean,
  skipUserIds?: ?$ReadOnlyArray<string>,
|};
export type OrgReviewerSearchQueryResponse = {|
  +org: {|
    +users: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +avatar: ?string,
          +email: string,
          +$fragmentRefs: UserSearchRow_user$ref,
        |}
      |}>
    |}
  |}
|};
export type OrgReviewerSearchQuery = {|
  variables: OrgReviewerSearchQueryVariables,
  response: OrgReviewerSearchQueryResponse,
|};
*/


/*
query OrgReviewerSearchQuery(
  $query: String!
  $hasTeamAccess: Boolean
  $skipUserIds: [ID!]
) {
  org {
    users(first: 3, query: $query, hasTeamAccess: $hasTeamAccess, skipUserIds: $skipUserIds) {
      edges {
        node {
          id
          firstName
          lastName
          avatar
          email
          ...UserSearchRow_user
        }
      }
    }
    id
  }
}

fragment UserSearchRow_user on User {
  firstName
  lastName
  email
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hasTeamAccess",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipUserIds",
    "type": "[ID!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "hasTeamAccess",
    "variableName": "hasTeamAccess",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "skipUserIds",
    "variableName": "skipUserIds",
    "type": "[ID!]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "OrgReviewerSearchQuery",
  "id": null,
  "text": "query OrgReviewerSearchQuery(\n  $query: String!\n  $hasTeamAccess: Boolean\n  $skipUserIds: [ID!]\n) {\n  org {\n    users(first: 3, query: $query, hasTeamAccess: $hasTeamAccess, skipUserIds: $skipUserIds) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          avatar\n          email\n          ...UserSearchRow_user\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment UserSearchRow_user on User {\n  firstName\n  lastName\n  email\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OrgReviewerSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": v1,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v4,
                      v5,
                      v6,
                      {
                        "kind": "FragmentSpread",
                        "name": "UserSearchRow_user",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrgReviewerSearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": v1,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v4,
                      v5,
                      v6
                    ]
                  }
                ]
              }
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f3f98695a12ed2e7253497edde389a5';
module.exports = node;
