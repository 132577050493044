/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CompanyProfile_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompanyPageRoutes_user$ref: FragmentReference;
export type CompanyPageRoutes_user = {|
  +$fragmentRefs: CompanyProfile_user$ref,
  +$refType: CompanyPageRoutes_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CompanyPageRoutes_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "CompanyProfile_user",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '78cfe825b1997bc491251d798b33ab9e';
module.exports = node;
