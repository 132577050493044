/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefDownloadOptionSelectOverlay_event$ref: FragmentReference;
export type BriefDownloadOptionSelectOverlay_event = {|
  +dbId: number,
  +name: string,
  +exportDocUrl: string,
  +exportPdfUrl: string,
  +team: {|
    +dbId: number,
    +name: string,
  |},
  +$refType: BriefDownloadOptionSelectOverlay_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "BriefDownloadOptionSelectOverlay_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "exportDocUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "exportPdfUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        v0,
        v1
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fbe69a08c48c0b315f0d1d02c82b9720';
module.exports = node;
