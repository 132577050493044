/**
 * @flow
 * @relayHash 584149dd2d658d19333b1151ae112cf6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventAccessLevel_staffedEvent$ref = any;
type EventActionsColumn_staffedEvent$ref = any;
type EventDateAdded_staffedEvent$ref = any;
type EventDate_staffedEvent$ref = any;
type EventLocation_staffedEvent$ref = any;
type EventName_staffedEvent$ref = any;
type EventOnsite_staffedEvent$ref = any;
type TeamMembersTablePagination_totalCountTeam$ref = any;
export type RemoveUserFromTeamInput = {
  clientMutationId?: ?string,
  userId: string,
  teamId: string,
};
export type removeUserFromTeamMutationVariables = {|
  input: RemoveUserFromTeamInput
|};
export type removeUserFromTeamMutationResponse = {|
  +removeUserFromTeam: ?{|
    +clientMutationId: ?string,
    +removedUserId: string,
    +removedTeamId: string,
    +team: {|
      +$fragmentRefs: TeamMembersTablePagination_totalCountTeam$ref
    |},
    +userEventMembershipEdges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: EventAccessLevel_staffedEvent$ref & EventDate_staffedEvent$ref & EventDateAdded_staffedEvent$ref & EventLocation_staffedEvent$ref & EventName_staffedEvent$ref & EventOnsite_staffedEvent$ref & EventActionsColumn_staffedEvent$ref,
      |}
    |}>,
    +user: {|
      +hasTeamAccess: boolean
    |},
    +org: {|
      +subscription: {|
        +fatmLimit: ?number,
        +fatmCount: number,
      |}
    |},
  |}
|};
export type removeUserFromTeamMutation = {|
  variables: removeUserFromTeamMutationVariables,
  response: removeUserFromTeamMutationResponse,
|};
*/


/*
mutation removeUserFromTeamMutation(
  $input: RemoveUserFromTeamInput!
) {
  removeUserFromTeam(input: $input) {
    clientMutationId
    removedUserId
    removedTeamId
    team {
      ...TeamMembersTablePagination_totalCountTeam
      id
    }
    userEventMembershipEdges {
      node {
        id
        ...EventAccessLevel_staffedEvent
        ...EventDate_staffedEvent
        ...EventDateAdded_staffedEvent
        ...EventLocation_staffedEvent
        ...EventName_staffedEvent
        ...EventOnsite_staffedEvent
        ...EventActionsColumn_staffedEvent
      }
    }
    user {
      hasTeamAccess
      id
    }
    org {
      subscription {
        fatmLimit
        fatmCount
        id
      }
      id
    }
  }
}

fragment TeamMembersTablePagination_totalCountTeam on Team {
  membersCount: members {
    totalCount
  }
}

fragment EventAccessLevel_staffedEvent on StaffMembership {
  accessLevel
  belongsToTeam
}

fragment EventDate_staffedEvent on StaffMembership {
  event {
    startDate
    endDate
    tz
    id
  }
}

fragment EventDateAdded_staffedEvent on StaffMembership {
  addedAt
  event {
    tz
    id
  }
}

fragment EventLocation_staffedEvent on StaffMembership {
  event {
    primaryLocation {
      city
      state
      name
      country
      id
    }
    id
  }
}

fragment EventName_staffedEvent on StaffMembership {
  event {
    slug
    name
    viewerCanView
    id
  }
}

fragment EventOnsite_staffedEvent on StaffMembership {
  id
  onsite
  viewerCanUpdate
}

fragment EventActionsColumn_staffedEvent on StaffMembership {
  id
  viewerCanUpdateAccessLevel
  viewerCanRemove
  accessLevel
  event {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveUserFromTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveUserFromTeamInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedUserId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedTeamId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasTeamAccess",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fatmLimit",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fatmCount",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeUserFromTeamMutation",
  "id": null,
  "text": "mutation removeUserFromTeamMutation(\n  $input: RemoveUserFromTeamInput!\n) {\n  removeUserFromTeam(input: $input) {\n    clientMutationId\n    removedUserId\n    removedTeamId\n    team {\n      ...TeamMembersTablePagination_totalCountTeam\n      id\n    }\n    userEventMembershipEdges {\n      node {\n        id\n        ...EventAccessLevel_staffedEvent\n        ...EventDate_staffedEvent\n        ...EventDateAdded_staffedEvent\n        ...EventLocation_staffedEvent\n        ...EventName_staffedEvent\n        ...EventOnsite_staffedEvent\n        ...EventActionsColumn_staffedEvent\n      }\n    }\n    user {\n      hasTeamAccess\n      id\n    }\n    org {\n      subscription {\n        fatmLimit\n        fatmCount\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment TeamMembersTablePagination_totalCountTeam on Team {\n  membersCount: members {\n    totalCount\n  }\n}\n\nfragment EventAccessLevel_staffedEvent on StaffMembership {\n  accessLevel\n  belongsToTeam\n}\n\nfragment EventDate_staffedEvent on StaffMembership {\n  event {\n    startDate\n    endDate\n    tz\n    id\n  }\n}\n\nfragment EventDateAdded_staffedEvent on StaffMembership {\n  addedAt\n  event {\n    tz\n    id\n  }\n}\n\nfragment EventLocation_staffedEvent on StaffMembership {\n  event {\n    primaryLocation {\n      city\n      state\n      name\n      country\n      id\n    }\n    id\n  }\n}\n\nfragment EventName_staffedEvent on StaffMembership {\n  event {\n    slug\n    name\n    viewerCanView\n    id\n  }\n}\n\nfragment EventOnsite_staffedEvent on StaffMembership {\n  id\n  onsite\n  viewerCanUpdate\n}\n\nfragment EventActionsColumn_staffedEvent on StaffMembership {\n  id\n  viewerCanUpdateAccessLevel\n  viewerCanRemove\n  accessLevel\n  event {\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeUserFromTeamMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeUserFromTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveUserFromTeamPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "TeamMembersTablePagination_totalCountTeam",
                "args": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userEventMembershipEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffMembershipRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembership",
                "plural": false,
                "selections": [
                  v5,
                  {
                    "kind": "FragmentSpread",
                    "name": "EventAccessLevel_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventDate_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventDateAdded_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventLocation_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventName_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventOnsite_staffedEvent",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "EventActionsColumn_staffedEvent",
                    "args": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v6
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subscription",
                "storageKey": null,
                "args": null,
                "concreteType": "Subscription",
                "plural": false,
                "selections": [
                  v7,
                  v8
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeUserFromTeamMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeUserFromTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveUserFromTeamPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "membersCount",
                "name": "members",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamMemberConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalCount",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              v5
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userEventMembershipEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "StaffMembershipRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembership",
                "plural": false,
                "selections": [
                  v5,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "accessLevel",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "belongsToTeam",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "event",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "startDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "tz",
                        "args": null,
                        "storageKey": null
                      },
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "primaryLocation",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Location",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "city",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "state",
                            "args": null,
                            "storageKey": null
                          },
                          v9,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "country",
                            "args": null,
                            "storageKey": null
                          },
                          v5
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      },
                      v9,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanView",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "addedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "onsite",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdateAccessLevel",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanRemove",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v6,
              v5
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subscription",
                "storageKey": null,
                "args": null,
                "concreteType": "Subscription",
                "plural": false,
                "selections": [
                  v7,
                  v8,
                  v5
                ]
              },
              v5
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2fec0d927e75a767da11f1e8ab019d24';
module.exports = node;
