import './styles.styl';

import React from 'react';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';

import { getFilters } from 'utils/filters';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import ScheduleItem from './ScheduleItem';

const query = graphql`
  query ScheduleItemQuery($scheduleItemId: ID!) {
    schedule: node(id: $scheduleItemId) {
      ...ScheduleItem_schedule
    }
  }
`;

export default class ScheduleItemContainer extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    eventId: PropTypes.number,
    teamId: PropTypes.number,
    queryParams: PropTypes.object.isRequired,
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        key={this.props.id}
        variables={{ scheduleItemId: this.props.id }}
        renderSuccess={response => {
          return (
            <ScheduleItem
              schedule={response.schedule}
              eventId={this.props.eventId}
              teamId={this.props.teamId}
              filters={getFilters(
                {
                  member: 'string',
                  attendee: 'string',
                },
                this.props.queryParams,
              )}
            />
          );
        }}
      />
    );
  }
}
