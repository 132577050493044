/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import NumberField from './components/NumberField';

import type { BudgetPlanned_event } from './__generated__/BudgetPlanned_event.graphql';
import type { BudgetPlanned_org } from './__generated__/BudgetPlanned_org.graphql';

const BudgetPlanned = (props: { event: BudgetPlanned_event, org: BudgetPlanned_org }) => {
  const { event, org } = props;
  if (!event.viewerCanViewBudget) return <i>Restricted</i>;

  const currency = org.settings.currency;
  const totalBudgetedAmount = event.totalBudgetedAmount;

  return totalBudgetedAmount !== null ? (
    <NumberField value={formatCost(totalBudgetedAmount, currency)} formatted />
  ) : null;
};

export default createFragmentContainer(BudgetPlanned, {
  event: graphql`
    fragment BudgetPlanned_event on Event {
      viewerCanViewBudget
      totalBudgetedAmount
    }
  `,
  org: graphql`
    fragment BudgetPlanned_org on Org {
      settings {
        currency
      }
    }
  `,
});
