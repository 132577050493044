/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Header from 'components/Sidebar/Header';
import MenuItem from 'components/Sidebar/MenuItem';

import headerTitles from './lib/headerTitles';

import type { WorkspaceMenu_org } from './__generated__/WorkspaceMenu_org.graphql';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Title = styled.div`
  margin-bottom: 25px;
  margin-left: 9px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
`;

type Props = { org: WorkspaceMenu_org, pathPrefix: string };

const WorkspaceMenu = ({ org, pathPrefix }: Props) => {
  return (
    <Menu>
      <Header>
        <Title>Workspace</Title>
        {org.viewerCanViewOrgMembers && (
          <MenuItem to={`${pathPrefix}/members`}>{headerTitles.members}</MenuItem>
        )}
        {org.viewerCanViewTeams && (
          <MenuItem to={`${pathPrefix}/teams`}>{headerTitles.teams}</MenuItem>
        )}
        <MenuItem to={`${pathPrefix}/billing`}>{headerTitles.billing}</MenuItem>
      </Header>
    </Menu>
  );
};

export default createFragmentContainer(
  WorkspaceMenu,
  graphql`
    fragment WorkspaceMenu_org on Org {
      viewerCanViewOrgMembers
      viewerCanViewTeams
    }
  `,
);
