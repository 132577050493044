/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import difference from 'lodash/difference';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import updateContact, { type ContactFieldInput } from 'graph/mutations/contact/updateContact';
import updateCustomFieldValue, {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';
import showModernMutationError from 'graph/utils/showModernMutationError';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table, { type ColumnType } from 'components/budget/Table';
import TablePagination from 'components/budget/Table/TablePagination';
import { type AvailableContactsType } from 'components/Contacts/ContactsMassUpdatePanel';
import { type ContactCellPropsType } from 'components/Contacts/contactsTableColumnSettings';
import { type ColumnConfiguration } from 'components/material/table';
import NoResultsMessage from 'components/NoResultsMessage';

import type { OrgContactsTablePagination_org } from './__generated__/OrgContactsTablePagination_org.graphql';
import type { OrgContactsTablePagination_totalCountOrg } from './__generated__/OrgContactsTablePagination_totalCountOrg.graphql';

const ContactsContentContainer = styled.div`
  width: 100%;
`;

type Props = {
  org: ?OrgContactsTablePagination_org,
  totalCountOrg: ?OrgContactsTablePagination_totalCountOrg,
  relay: RelayPaginationProp,
  shownColumns: ColumnConfiguration,
  filtered: boolean,
  sort: SortParam,
  onSelectContacts: (items: $ReadOnlyArray<string | number>) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onContactsListUpdate: (contacts: AvailableContactsType) => void,
  selectedContacts: $ReadOnlyArray<string>,
  onChangeSort: (sort: SortParam) => void,
  setColumnWidthUpdater: (updateColumnWidths: () => void) => void,
  handleAllContactsSelectedUpdate: (allContactsSelected: boolean) => void,
  tz: string,
};

type State = {
  refetching: boolean,
  availableContacts: $ReadOnlyArray<string>,
};

class OrgContactsTablePagination extends React.Component<Props, State> {
  state = {
    refetching: false,
    availableContacts: [],
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const prevItems = prevState.availableContacts;
    const nextItems = nextProps.org
      ? nextProps.org.contacts.edges.filter(Boolean).map(({ node }) => node.id)
      : [];
    if (
      nextProps.org &&
      (difference(prevItems, nextItems).length > 0 || difference(nextItems, prevItems).length > 0)
    ) {
      nextProps.onContactsListUpdate(
        nextProps.org.contacts.edges
          .filter(Boolean)
          .map(({ node }) => ({ id: node.id, salesforceSyncAs: node.salesforceSyncAs })),
      );
      return { availableContacts: nextItems };
    }
    return null;
  }

  paginationDisposable: ?Disposable;

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  cellProps = (
    contact,
    _group,
    column?: ColumnType<any, any>,
  ): ContactCellPropsType<
    ?OrgContactsTablePagination_org,
    {|
      +id: string,
      +salesforceSyncAs: ?string,
      +$fragmentRefs: any,
    |},
  > => {
    const { org, tz, shownColumns } = this.props;
    const columnsLength = shownColumns.length;
    const contactsSyncEnabled =
      org != null && org.salesforceAccount != null && org.salesforceAccount.contactsSyncEnabled;
    const restrictChangingValue =
      contactsSyncEnabled &&
      (contact.salesforceSyncAs === 'contact'
        ? column && column.fieldSettings && column.fieldSettings.restrictChangingValueForContact
        : column && column.fieldSettings && column.fieldSettings.restrictChangingValueForLead);
    return {
      contact,
      customizable: contact,
      org,
      event: null,
      fieldSettings: column != null ? { ...column.fieldSettings, restrictChangingValue } : null,
      isLast:
        column != null && column.fieldSettings != null
          ? shownColumns[columnsLength - 2].fieldSettings &&
            shownColumns[columnsLength - 2].fieldSettings.id === column.fieldSettings.id
          : false,
      onUpdate: (args: $Shape<ContactFieldInput>): Promise<void> => {
        return updateContact({ contactId: contact.id, ...args, fromWindow: 'org contacts' }).catch(
          showModernMutationError,
        );
      },
      onUpdateCustomField: (
        customizable: CustomizableResponse,
        args: UpdateCustomFieldValueInput,
      ): Promise<void> => {
        return updateCustomFieldValue(args, customizable, undefined, 'org contacts')
          .catch(showModernMutationError)
          .then(() => undefined);
      },
      salesforceHost: org != null && org.salesforceAccount ? org.salesforceAccount.host : null,
      marketoHost: org != null && org.marketoAccount ? org.marketoAccount.host : null,
      tz,
    };
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  onSelectContacts = (selectedContacts: $ReadOnlyArray<string | number>) => {
    const shownContactsEdges = this.props.org ? this.props.org.contacts.edges.filter(Boolean) : [];
    const totalCount =
      this.props.totalCountOrg && this.props.totalCountOrg.contactsCount
        ? this.props.totalCountOrg.contactsCount.totalCount
        : null;

    this.props.onSelectContacts(selectedContacts);
    if (totalCount !== selectedContacts.length) {
      this.props.handleAllContactsSelectedUpdate(
        selectedContacts.length === shownContactsEdges.length,
      );
    }
  };

  keyExtractor = (contact: {|
    +id: string,
    +salesforceSyncAs: ?string,
    +$fragmentRefs: any,
  |}): string => {
    return contact.id;
  };

  render() {
    const {
      org,
      shownColumns,
      filtered,
      selectedContacts,
      sort,
      onChangeSort,
      setColumnWidthUpdater,
    } = this.props;

    const optimisticContactsEdges = org ? org.contacts.edges.filter(Boolean) : [];
    return (
      <ContactsContentContainer>
        {(!org || optimisticContactsEdges.length !== 0) && (
          <Table
            data={org != null ? optimisticContactsEdges.map(({ node }) => node) : null}
            columns={shownColumns}
            sort={sort}
            onChangeSort={onChangeSort}
            cellProps={this.cellProps}
            keyExtractor={this.keyExtractor}
            selectedItems={selectedContacts}
            onSelectItems={this.onSelectContacts}
            setColumnWidthUpdater={setColumnWidthUpdater}
          />
        )}

        {org &&
          optimisticContactsEdges.length === 0 &&
          (filtered ? (
            <EmptyView message="No contacts match filters" icon={<NoResult />} />
          ) : (
            <NoResultsMessage iconName="user" message="Add the first contact" />
          ))}
        {optimisticContactsEdges.length !== 0 && (
          <TablePagination
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            currentCount={org ? optimisticContactsEdges.length : null}
            totalCount={
              this.props.totalCountOrg && this.props.totalCountOrg.contactsCount
                ? this.props.totalCountOrg.contactsCount.totalCount
                : null
            }
            entityName="Contacts"
          />
        )}
      </ContactsContentContainer>
    );
  }
}
export default createPaginationContainer(
  OrgContactsTablePagination,
  {
    org: graphql`
      fragment OrgContactsTablePagination_org on Org {
        salesforceAccount {
          host
          contactsSyncEnabled
        }
        marketoAccount {
          host
        }
        ...CustomizableCurrency_org @include(if: $includeCustomizableCurrency)
        contacts(first: $count, after: $cursor, filters: $filters)
          @connection(key: "OrgContactsTablePagination_contacts", filters: []) {
          edges {
            node {
              id
              salesforceSyncAs
              ...ContactName_contact @include(if: $includeName)
              ...ContactTitle_contact @include(if: $includeTitle)
              ...ContactPhone_contact @include(if: $includePhone1)
              ...ContactPhone_contact @include(if: $includePhone2)
              ...ContactPersonType_contact @include(if: $includeContactTypeId)
              ...ContactCompany_contact @include(if: $includeCompanyId)
              ...ContactEmail_contact @include(if: $includeEmail)
              ...ContactWebsite_contact @include(if: $includeWebsite)
              ...ContactTwitter_contact @include(if: $includeTwitter)
              ...ContactLinkedin_contact @include(if: $includeLinkedin)
              ...ContactOwner_contact @include(if: $includeOwnerId)
              ...ContactUpdater_contact @include(if: $includeUpdatedBy)
              ...ContactUpdatedAt_contact @include(if: $includeUpdatedAt)
              ...ContactUpdatedMethod_contact @include(if: $includeUpdatedMethod)
              ...ContactCreator_contact @include(if: $includeCreatedBy)
              ...ContactCreatedAt_contact @include(if: $includeCreatedAt)
              ...ContactCreatedMethod_contact @include(if: $includeCreatedMethod)
              ...ContactSalesforceId_contact @include(if: $includeSalesforceId)
              ...ContactMarketoId_contact @include(if: $includeMarketoId)
              ...ContactSalesforceSyncAs_contact @include(if: $includeSalesforceSyncAs)
              ...ContactSalesforceLeadStatus_contact @include(if: $includeSalesforceLeadStatusId)
              ...ContactLastSynced_contact @include(if: $includeLastSynced)
              ...ContactSyncStatus_contact @include(if: $includeSyncStatus)
              ...ContactStreet_contact @include(if: $includeStreet)
              ...ContactCity_contact @include(if: $includeCity)
              ...ContactState_contact @include(if: $includeState)
              ...ContactZip_contact @include(if: $includeZip)
              ...ContactCountry_contact @include(if: $includeCountry)
              ...ContactDescription_contact @include(if: $includeDescription)
              ...ContactEmailOptIn_contact @include(if: $includeEmailOptIn)
              ...ContactHotLead_contact @include(if: $includeHotLead)
              ...ContactRequestSubmissionsCount_contact @include(if: $includeRequestSubmissions)
              ...ContactEventsCount_contact @include(if: $includeEvents)
              ...CustomizableText_customizable @include(if: $includeCustomizableText)
              ...CustomizableTextarea_customizable @include(if: $includeCustomizableTextarea)
              ...CustomizableLink_customizable @include(if: $includeCustomizableLink)
              ...CustomizableDate_customizable @include(if: $includeCustomizableDate)
              ...CustomizableBoolean_customizable @include(if: $includeCustomizableBoolean)
              ...CustomizableNumber_customizable @include(if: $includeCustomizableNumber)
              ...CustomizableCurrency_customizable @include(if: $includeCustomizableCurrency)
              ...CustomizableSelect_customizable @include(if: $includeCustomizableSelect)
              ...CustomizableMultiselect_customizable @include(if: $includeCustomizableMultiselect)
              ...CustomizableUserSelect_customizable @include(if: $includeCustomizableUserSelect)
              ...CustomizableUserMultiselect_customizable
                @include(if: $includeCustomizableUserMultiselect)
              ...ContactActionCell_contact
            }
          }
        }
      }
    `,
    totalCountOrg: graphql`
      fragment OrgContactsTablePagination_totalCountOrg on Org {
        contactsCount: contacts(filters: $filters) {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.org && props.org.contacts;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
      };
    },
    query: graphql`
      query OrgContactsTablePaginationQuery(
        $count: Int!
        $cursor: String
        $filters: ContactFilters!
        $includeName: Boolean!
        $includeEmail: Boolean!
        $includeSalesforceSyncAs: Boolean!
        $includeSalesforceLeadStatusId: Boolean!
        $includeSalesforceId: Boolean!
        $includeMarketoId: Boolean!
        $includeLastSynced: Boolean!
        $includeSyncStatus: Boolean!
        $includeEvents: Boolean!
        $includeRequestSubmissions: Boolean!
        $includeContactTypeId: Boolean!
        $includeCompanyId: Boolean!
        $includeTitle: Boolean!
        $includeOwnerId: Boolean!
        $includeWebsite: Boolean!
        $includePhone1: Boolean!
        $includePhone2: Boolean!
        $includeStreet: Boolean!
        $includeCity: Boolean!
        $includeState: Boolean!
        $includeZip: Boolean!
        $includeCountry: Boolean!
        $includeTwitter: Boolean!
        $includeLinkedin: Boolean!
        $includeDescription: Boolean!
        $includeHotLead: Boolean!
        $includeEmailOptIn: Boolean!
        $includeUpdatedAt: Boolean!
        $includeUpdatedBy: Boolean!
        $includeUpdatedMethod: Boolean!
        $includeCreatedAt: Boolean!
        $includeCreatedBy: Boolean!
        $includeCreatedMethod: Boolean!
        $includeCustomizableText: Boolean!
        $includeCustomizableTextarea: Boolean!
        $includeCustomizableLink: Boolean!
        $includeCustomizableDate: Boolean!
        $includeCustomizableBoolean: Boolean!
        $includeCustomizableNumber: Boolean!
        $includeCustomizableCurrency: Boolean!
        $includeCustomizableSelect: Boolean!
        $includeCustomizableMultiselect: Boolean!
        $includeCustomizableUserSelect: Boolean!
        $includeCustomizableUserMultiselect: Boolean!
      ) {
        org {
          ...OrgContactsTablePagination_org
        }
      }
    `,
  },
);
