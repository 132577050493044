/* @flow */
const sortCustomFieldOptions = (
  options: $ReadOnlyArray<{ +id: string, +name: string, +editable?: boolean }>,
): $ReadOnlyArray<{ +id: string, +name: string, +editable: boolean }> => {
  const regexp = /^((\d|,)+)/;
  return options
    .map(option => ({ ...option, editable: option.editable || false }))
    .sort((a, b) => {
      // Remove "," separator from numbers (e.g. change 1,001 to 1001)
      const aName = regexp.test(a.name) ? a.name.replace(',', '') : a.name;
      const bName = regexp.test(b.name) ? b.name.replace(',', '') : b.name;

      // Take into account numeric order so 10 comes after 2
      return aName.localeCompare(bName, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
};

export default sortCustomFieldOptions;
