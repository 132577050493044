/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import APIIcon from 'images/integrations/api.svg';
import G2WIcon from 'images/integrations/g2w.svg';
import MarketoIcon from 'images/integrations/marketo.svg';
import SalesforceIcon from 'images/integrations/salesforce.svg';
import ZapierIcon from 'images/integrations/zapier.svg';
import ZoomIcon from 'images/integrations/zoom.svg';
import ZoomLargeIcon from 'images/integrations/zoomCamera.svg';
import { type IntegrationSettings } from 'components/integrations/IntegrationsSettings';

import CircaAPI from '../CircaAPI';
import Marketo from '../Marketo';
import Salesforce from '../Salesforce';
import Zapier from '../Zapier';

import { type IntegrationsQueryResponse } from '../__generated__/IntegrationsQuery.graphql';

const StyledZoomLargeIcon = styled(ZoomLargeIcon)`
  path {
    fill: #fff;
  }
`;

const integrations = (
  response: IntegrationsQueryResponse,
  enableLegacyFeatures: boolean,
): $ReadOnlyArray<IntegrationSettings> => {
  const solutionInstances = response
    ? // There's a bug in relay that doesn't restore the edge on error in connections with args
      response.org.traySolutionInstances.edges.map(edge => edge && edge.node).filter(Boolean)
    : [];
  const zoomSolutionInstance = solutionInstances.find(instance => instance.solutionName === 'ZOOM');
  const g2wSolutionInstance = solutionInstances.find(instance => instance.solutionName === 'G2W');

  const actualIntegrations = [
    {
      icon: <APIIcon />,
      largeIcon: <APIIcon />,
      iconWidth: 117,
      name: 'API Integration',
      route: 'api',
      component: CircaAPI,
      color: '#192D4A',
    },
    {
      icon: <ZapierIcon />,
      iconWidth: 80,
      name: 'Zapier',
      route: 'zapier',
      component: Zapier,
      color: '#ff4a00',
    },
  ];

  if (!enableLegacyFeatures) return actualIntegrations;

  return [
    {
      sectionName: 'CRM',
      icon: <SalesforceIcon />,
      iconWidth: 117,
      name: 'Salesforce',
      route: 'salesforce',
      component: Salesforce,
      color: '#039be5',
      connected: !!response.org.salesforceAccount,
      disabledInfo: response.org.marketoAccount
        ? 'You can only integrate with\none CRM at a time'
        : null,
    },
    {
      sectionName: 'CRM',
      icon: <MarketoIcon />,
      iconWidth: 73,
      name: 'Marketo',
      route: 'marketo',
      component: Marketo,
      color: '#563E94',
      connected: !!response.org.marketoAccount,
      disconnectConfirmation:
        'Disconnecting this Marketo account will disconnect all Marketo Programs and Leads.',
      disabledInfo: response.org.salesforceAccount
        ? 'You can only integrate with\none CRM at a time'
        : null,
    },
    {
      sectionName: 'Video Conferencing',
      icon: <ZoomIcon />,
      largeIcon: (
        <div style={{ padding: 7 }}>
          <StyledZoomLargeIcon />
        </div>
      ),
      name: 'Zoom',
      route: 'zoom',
      color: '#4A8CFF',
      solutionName: 'ZOOM',
      disconnectConfirmation:
        "Disconnecting this Zoom account will remove the Zoom Meeting or Webinar (as well as registrant information) from any Events that haven't occurred yet.",
      connected: !!zoomSolutionInstance,
      expired: zoomSolutionInstance && zoomSolutionInstance.upgradeNeeded,
    },
    {
      sectionName: 'Video Conferencing',
      icon: <G2WIcon />,
      iconWidth: 73,
      name: 'GoToWebinar',
      route: 'g2w',
      color: '#00C0F3',
      solutionName: 'G2W',
      disconnectConfirmation:
        "Disconnecting this GoToWebinar account will remove the GoToWebinar Webinar (as well as registrant information) from any Events that haven't occurred yet.",
      connected: !!g2wSolutionInstance,
      expired: g2wSolutionInstance && g2wSolutionInstance.upgradeNeeded,
    },
    ...actualIntegrations,
  ];
};

export default integrations;
