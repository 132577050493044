/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Location, type RouterHistory, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';

import currentOrigin from 'utils/currentOrigin';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import { type EventListDesignFields } from './EventListDesign';
import Header from './Header';
import RightSidebar from './RightSidebar';

import { type EventListPreviewQueryResponse } from './__generated__/EventListPreviewQuery.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  min-height: 0;
`;

const Sidebar = styled.div`
  position: relative;
  max-width: ${props => (props.shown ? 346 : 0)}px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
`;

const Iframe = styled.iframe`
  flex: 1 1 auto;
  height: 100%;
`;

const query = graphql`
  query EventListPreviewQuery {
    org {
      slug
      settings {
        subdomain
      }
      ...RightSidebar_org
    }
  }
`;

class EventListPreview extends React.Component<
  {
    location: Location,
    history: RouterHistory,
    org: $PropertyType<EventListPreviewQueryResponse, 'org'>,
  },
  { showSidebar: boolean },
> {
  state = { showSidebar: false };

  iframeRef = React.createRef();

  hash = this.props.location.hash;

  componentDidMount() {
    if (!this.iframeRef.current) return;

    this.iframeRef.current.src = `/dashboard/saved_view_preview/share${this.props.location.search}${this.hash}`;
  }

  componentDidUpdate() {
    if (!this.iframeRef.current) return;

    this.iframeRef.current.contentWindow.location.hash = this.props.location.hash;
  }

  handleToggleSidebar = () => {
    this.setState(state => ({ showSidebar: !state.showSidebar }));
  };

  handleUpdateEventList = (eventList: $Exact<{ ...EventListDesignFields }>) => {
    const params = qs.parse(this.props.location.hash.slice(1));

    this.props.history.replace({
      hash: qs.stringify({ ...params, ...eventList }, { skipNulls: true }),
    });
  };

  parseEventList(): EventListDesignFields & { publicResourceToken: string } {
    const params = qs.parse(this.props.location.hash.slice(1));

    return {
      logo: params.logo,
      font: params.font,
      backgroundUrl: params.backgroundUrl,
      backgroundColor: params.backgroundColor,
      titleColor: params.titleColor,
      publicResourceToken: params.publicResourceToken,
    };
  }

  render() {
    const { org, location } = this.props;
    const eventList = this.parseEventList();
    const params = qs.parse(this.props.location.hash.slice(1));
    const isShared = params.isShared === 'true';

    const url = `${currentOrigin()}/${org.settings.subdomain ? '' : `${org.slug}/`}${
      eventList.publicResourceToken
    }`;

    if (!window.opener) {
      return <Redirect to="/" />;
    }

    return (
      <Container>
        <Header url={url} onToggleSidebar={this.handleToggleSidebar} isShared={isShared} />
        <Content>
          <Iframe ref={this.iframeRef} />

          <Sidebar shown={this.state.showSidebar}>
            <RightSidebar
              org={org}
              eventList={eventList}
              onUpdateEventList={this.handleUpdateEventList}
              location={location}
              onToggleSidebar={this.handleToggleSidebar}
            />
          </Sidebar>
        </Content>
      </Container>
    );
  }
}

export default ({ location, history }: { location: Location, history: RouterHistory }) => (
  <DefaultQueryRenderer
    query={query}
    renderSuccess={response => (
      <EventListPreview org={response.org} location={location} history={history} />
    )}
  />
);
