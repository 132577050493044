/* @flow */
import formatPercentage from 'utils/number/formatPercentage';

import { type ReportingFooterCellPropsType } from './index';

const ReportingTotalPaidPercentCell = ({ totals }: ReportingFooterCellPropsType) => {
  return formatPercentage(totals.paidAmount / totals.actualAmount);
};

export default ReportingTotalPaidPercentCell;
