/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { type Location, type Match, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import NotFound from 'components/NotFound';
import Menu from 'components/page/Menu';
import MenuItem from 'components/page/MenuItem';

import Content from '../layout';
import AccountPreferences from './AccountPreferences';
import ChangePassword from './ChangePassword';
import EmailSubscriptions from './EmailSubscriptions';
import headerTitles from './lib/headerTitles';
import ProfileSettings from './ProfileSettings';

const StyledContent = styled(Content)`
  padding: 0;
`;

const Title = styled.div`
  padding: 30px 33px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const MenuWrapper = styled.div`
  flex: 1 1 auto;
  height: 48px;
  border-bottom: 1px solid #eaebec;
  overflow: hidden;
`;

const StyledMenu = styled(Menu)`
  padding-left: 33px;
`;

const ContentWrapper = styled.div`
  padding-top: 20px;
`;

const Container = styled.div`
  padding-top: 20px;
`;

export default class Account extends React.Component<{
  match: Match,
  location: Location,
}> {
  render() {
    const { location, match } = this.props;
    const pathPrefix = match.path;
    const title = headerTitles[location.pathname.split('/').pop()] || headerTitles.profile;
    return (
      <StyledContent>
        <Title>Account Settings</Title>
        <Container>
          <MenuWrapper>
            <StyledMenu>
              <MenuItem text="Profile" icon="gear" to="/account" index />
              <MenuItem text="Preferences" icon="eyedropper" to="/account/preferences" />
              <MenuItem text="Password Reset" icon="key" to="/account/password-reset" />
              <MenuItem
                text="Email Subscriptions"
                icon="envelope"
                to="/account/email-subscriptions"
              />
            </StyledMenu>
          </MenuWrapper>
          <ContentWrapper>
            <DocumentTitle title={title}>
              <Switch>
                <Route path={pathPrefix} exact component={ProfileSettings} />
                <Route
                  path={`${pathPrefix}/email-subscriptions`}
                  exact
                  component={EmailSubscriptions}
                />
                <Route path={`${pathPrefix}/password-reset`} exact component={ChangePassword} />
                <Route path={`${pathPrefix}/preferences`} exact component={AccountPreferences} />
                <Route component={NotFound} />
              </Switch>
            </DocumentTitle>
          </ContentWrapper>
        </Container>
      </StyledContent>
    );
  }
}
