/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import EventRequestsItemFormName from 'views/Main/Dashboard/EventRequests/columns/EventRequestsItemFormName';

import type { EventRequestsFormName_event } from './__generated__/EventRequestsFormName_event.graphql';

const EventRequestsFormName = (props: { event: EventRequestsFormName_event }) => {
  const eventRequestSubmission = props.event.eventRequestSubmission;
  if (!eventRequestSubmission) {
    return null;
  }

  return <EventRequestsItemFormName eventRequestSubmission={eventRequestSubmission} />;
};

export default createFragmentContainer(
  EventRequestsFormName,
  graphql`
    fragment EventRequestsFormName_event on Event {
      eventRequestSubmission {
        ...EventRequestsItemFormName_eventRequestSubmission
      }
    }
  `,
);
