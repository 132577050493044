/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationFormFieldLogic_contactFormField$ref = any;
type RegistrationFormFieldLogic_contactFormFields$ref = any;
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationLogicContent_registrationForm$ref: FragmentReference;
export type RegistrationLogicContent_registrationForm = {|
  +pageComponents: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +kind: RegistrationPageComponentKind,
        +formComponent: ?{|
          +contactForm: {|
            +contactFormFields: {|
              +edges: $ReadOnlyArray<{|
                +node: {|
                  +id: string,
                  +order: number,
                  +$fragmentRefs: RegistrationFormFieldLogic_contactFormFields$ref & RegistrationFormFieldLogic_contactFormField$ref,
                |}
              |}>
            |}
          |}
        |},
      |}
    |}>
  |},
  +$refType: RegistrationLogicContent_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationLogicContent_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pageComponents",
      "storageKey": "pageComponents(kind:[\"FORM\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": [
            "FORM"
          ],
          "type": "[RegistrationPageComponentKind]"
        }
      ],
      "concreteType": "RegistrationPageComponentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RegistrationPageComponentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationPageComponent",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "formComponent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationFormComponent",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "contactForm",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ContactForm",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "contactFormFields",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ContactFormFieldRequiredConnection",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "edges",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ContactFormFieldRequiredEdge",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "node",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "ContactFormField",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "ScalarField",
                                      "alias": null,
                                      "name": "id",
                                      "args": null,
                                      "storageKey": null
                                    },
                                    {
                                      "kind": "ScalarField",
                                      "alias": null,
                                      "name": "order",
                                      "args": null,
                                      "storageKey": null
                                    },
                                    {
                                      "kind": "FragmentSpread",
                                      "name": "RegistrationFormFieldLogic_contactFormFields",
                                      "args": null
                                    },
                                    {
                                      "kind": "FragmentSpread",
                                      "name": "RegistrationFormFieldLogic_contactFormField",
                                      "args": null
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e07c577fda76eb5c53aeb22a42f07485';
module.exports = node;
