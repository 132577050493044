/* @flow */
import type { QuestionType, QuestionValueType } from 'components/EventRequestForm/lib/types';

export const filteredOptions = (
  question: QuestionType,
  value: QuestionValueType,
  keyword: string,
): $ReadOnlyArray<{| +id: string, +name: string |}> => {
  const options = [];

  question.selectOptions.forEach(option => {
    if (
      option.name.toLowerCase().includes(keyword) ||
      (value && value.selectIdsValue.includes(option.id))
    ) {
      options.push(option);
    }
  });

  return options;
};

export const mapOptions = (
  options: $ReadOnlyArray<{ +id: string, +name: string }>,
): $ReadOnlyArray<{ label: string, value: string }> => {
  return options.map(option => {
    return {
      label: option.name,
      value: option.id,
    };
  });
};
