/* @flow */
import React from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';

import Loader from 'components/Loader';
import Button from 'components/material/Button';
import TextField from 'components/material/TextField';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import type {
  FrequencyType,
  ScheduledOnType,
} from 'components/ScheduledReporting/ScheduledReportingForm';
import ScheduledReportingForm from 'components/ScheduledReporting/ScheduledReportingForm';
import { type User } from 'components/UserSelect';
import type { SchedulerType } from 'views/Main/Dashboard/AllEvents/components/AllEventsViewActions';

import type { SavedEventListEditWindowQueryResponse } from './__generated__/SavedEventListEditWindowQuery.graphql';

export type ExportSchedulerType = $PropertyType<
  $NonMaybeType<$PropertyType<SavedEventListEditWindowQueryResponse, 'eventList'>>,
  'exportScheduler',
>;

const WindowTitleContainer = styled.div`
  flex: 1 1 auto;
  text-align: center;
`;

const WindowSubTitle = styled.div`
  margin-top: 9px;
  font-size: 13px;
  color: #4a5665;
`;

const StyledWindowContent = styled(WindowContent)`
  padding: 0 23px 20px 34px;
`;

const StyledWindowHeader = styled(WindowHeader)`
  padding: 20px 30px 14px;
  @media (${props => props.theme.mobileOnly}) {
    height: auto;
  }
`;

const StyledWindowTitle = styled(WindowTitle)`
  font-size: 14px;
`;

const TextFieldWrapper = styled.div`
  padding: 0 16px 0 5px;
`;

const EmailFieldsContainer = styled.div`
  padding: 0 20px 0 5px;
`;

const StyledTextField = styled(TextField)`
  input {
    padding: 7px 14px 6px 14px;
    border: solid 1px #c3e0ec;
    border-radius: 4px;
    font-size: 13px;
    transition: border 0.3s;
    &:hover {
      border: solid 1px #c3e0ec;
    }
    &:focus {
      border-color: #3ba9da;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 49px;
`;

const SaveNewButton = styled(Button)`
  margin-left: 15px;
  padding: 4px 22px;
`;

const OverwriteButton = styled(Button)`
  margin-left: auto;
  padding: 4px 22px;
`;

const CancelButton = styled(Button)`
  padding: 4px 16px 4px 0;
  font-weight: 400;
  color: #828b93;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
`;

export default class EditViewWindowContent extends React.PureComponent<
  {
    exportScheduler: ExportSchedulerType,
    name: string,
    onHide: () => void,
    onUpdate: (name: string, scheduler: ?SchedulerType, hasChanges: boolean) => void,
    onSaveNew?: (name: string, scheduler: ?SchedulerType) => void,
    viewerCanUpdateEventList?: ?boolean,
    viewerCanUpdate: boolean,
  },
  {
    name: string,
    scheduler: any,
    errors: $ReadOnlyArray<string>,
  },
> {
  state = {
    name: this.props.name,
    scheduler: this.props.exportScheduler
      ? {
          frequency: this.props.exportScheduler.frequency,
          scheduledOn: this.props.exportScheduler.scheduledOn,
          recipients: this.props.exportScheduler.recipients.edges.map(({ node }) => node),
        }
      : null,
    errors: [],
  };

  handleNameChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    if (this.state.errors.includes('name') && name.trim()) {
      this.setState(prevState => ({
        errors: prevState.errors.filter(error => error !== 'name'),
      }));
    }
    this.setState({ name });
  };

  handleUpdate = () => {
    const { name, errors, scheduler } = this.state;

    if (!name) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'name'] }));
    }

    if (scheduler && scheduler.recipients.length === 0) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'recipients'] }));
    }

    if (errors.length !== 0 || (scheduler && scheduler.recipients.length === 0) || !name) return;

    const newScheduler = scheduler
      ? {
          frequency: scheduler.frequency,
          scheduledOn: scheduler.scheduledOn,
          recipientIds: scheduler.recipients.map(({ id }) => id),
        }
      : null;

    const savedScheduler = this.props.exportScheduler
      ? {
          frequency: this.props.exportScheduler.frequency,
          scheduledOn: this.props.exportScheduler.scheduledOn,
          recipientIds: this.props.exportScheduler.recipients.edges.map(({ node }) => node.id),
        }
      : null;

    const hasChanges = !isEqual(savedScheduler, newScheduler);
    this.props.onUpdate(name, hasChanges ? newScheduler : undefined, hasChanges);
  };

  handleSaveNew = () => {
    const { scheduler, name } = this.state;

    const error = this.handleValidate();
    if (!error) {
      const newScheduler = scheduler
        ? {
            frequency: scheduler.frequency,
            scheduledOn: scheduler.scheduledOn,
            recipientIds: scheduler.recipients.map(({ id }) => id),
          }
        : null;

      if (this.props.onSaveNew) {
        this.props.onSaveNew(name, newScheduler);
      }
    }
  };

  handleValidate = (): boolean => {
    const { scheduler, name, errors } = this.state;

    if (!name) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'name'] }));
    }

    if (scheduler && scheduler.recipients && scheduler.recipients.length === 0) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'recipients'] }));
    }

    return (
      errors.length !== 0 ||
      (scheduler && scheduler.recipients && scheduler.recipients.length === 0) ||
      !name
    );
  };

  handleFrequencyChange = (frequency: ?FrequencyType | 'never') => {
    if (this.state.scheduler && this.state.scheduler.frequency === frequency) return;

    if (frequency === 'never' || !frequency) {
      this.setState({ scheduler: null });

      if (this.state.errors.includes('recipients')) {
        this.setState(prevState => ({
          errors: prevState.errors.filter(error => error !== 'recipients'),
        }));
      }

      return;
    }

    const scheduledOnDefaultValue = frequency === 'monthly' ? 'start_of_month' : 'Monday';

    this.setState(previousState => ({
      scheduler: {
        ...previousState.scheduler,
        frequency: ((frequency: any): FrequencyType),
        scheduledOn: scheduledOnDefaultValue,
        recipients: previousState.scheduler ? previousState.scheduler.recipients : [],
      },
    }));
  };

  handleScheduledOnChange = (scheduledOn: ?ScheduledOnType) => {
    if (this.state.scheduler && this.state.scheduler.scheduledOn === scheduledOn) return;

    this.setState(previousState => ({
      scheduler: {
        ...previousState.scheduler,
        scheduledOn: ((scheduledOn: any): ScheduledOnType),
      },
    }));
  };

  handleChangeUserMultiSelectFilterValue = (users: $ReadOnlyArray<User>) => {
    if (this.state.errors.includes('recipients') && users.length > 0) {
      this.setState(prevState => ({
        errors: prevState.errors.filter(error => error !== 'recipients'),
      }));
    }
    this.setState(previousState => ({
      scheduler: {
        ...previousState.scheduler,
        recipients: users,
      },
    }));
  };

  renderLoading = () => {
    return (
      <LoaderContainer>
        <Loader size={30} />
      </LoaderContainer>
    );
  };

  render() {
    const { scheduler, name, errors } = this.state;
    return (
      <Window size={436} onHide={this.props.onHide}>
        <StyledWindowHeader>
          <WindowTitleContainer>
            <StyledWindowTitle>
              {this.props.onSaveNew != null ? 'Save view' : 'Edit view'}
            </StyledWindowTitle>
            {this.props.onSaveNew != null && (
              <WindowSubTitle>Save this view with the current columns and filters</WindowSubTitle>
            )}
          </WindowTitleContainer>
          <WindowClose onClick={this.props.onHide} />
        </StyledWindowHeader>
        <StyledWindowContent>
          <TextFieldWrapper>
            <StyledTextField
              placeholder="Event View Name"
              value={name}
              onChange={this.handleNameChange}
              autoFocus
              error={errors.includes('name') ? 'Required' : ''}
            />
          </TextFieldWrapper>
          {this.props.viewerCanUpdate && (
            <EmailFieldsContainer>
              <ScheduledReportingForm
                scheduler={scheduler}
                errors={errors}
                handleFrequencyChange={this.handleFrequencyChange}
                handleScheduledOnChange={this.handleScheduledOnChange}
                handleChangeUserMultiSelectFilterValue={this.handleChangeUserMultiSelectFilterValue}
                limitedAccessOrMore
              />
            </EmailFieldsContainer>
          )}
          <ActionContainer>
            <CancelButton label="Cancel" minimal onClick={this.props.onHide} />
            {this.props.viewerCanUpdateEventList && (
              <OverwriteButton
                primary
                label={this.props.onSaveNew != null ? 'Overwrite' : 'Update'}
                onClick={this.handleUpdate}
              />
            )}
            {this.props.onSaveNew != null && (
              <SaveNewButton primary label="Save as new" onClick={this.handleSaveNew} />
            )}
          </ActionContainer>
        </StyledWindowContent>
      </Window>
    );
  }
}
