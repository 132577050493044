/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentActionCell_payment$ref: FragmentReference;
export type PaymentActionCell_payment = {|
  +id: string,
  +viewerCanDelete: boolean,
  +expense: {|
    +id: string
  |},
  +$refType: PaymentActionCell_payment$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PaymentActionCell_payment",
  "type": "Payment",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanDelete",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "expense",
      "storageKey": null,
      "args": null,
      "concreteType": "Expense",
      "plural": false,
      "selections": [
        v0
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '264742eac6efa14cd7a7076cc916a2a9';
module.exports = node;
