/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateEventNameComponent, {
  type updateEventNameComponentPropertyType,
} from 'graph/mutations/registration/updateEventNameComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultBackgroundColor, defaultTextColor } from '../../registrationFormDefaults';
import PropertyRow from '../components/PropertyRow';
import RegistrationColorSelector from '../components/RegistrationColorSelector';
import { Section } from '.';
import { AlignmentToggle, BlockingLayer, Font, PaddingComponent } from './components';
import { type AlignmentType } from './components/AlignmentToggle';

import EventNameComponent_org from './__generated__/EventNameComponent_org.graphql';
import EventNameComponent_selectedPageComponent from './__generated__/EventNameComponent_selectedPageComponent.graphql';

const Container = styled.div`
  position: relative;
`;

class EventNameComponent extends React.PureComponent<{
  org: EventNameComponent_org,
  selectedPageComponent: EventNameComponent_selectedPageComponent,
  onChangeComponentProperty: (updatedProps: SelectedComponent) => void,
}> {
  updateEventNameComponent = (properties: updateEventNameComponentPropertyType) => {
    updateEventNameComponent({
      ...properties,
      id: this.props.selectedPageComponent.eventNameComponent.id,
    }).catch(showModernMutationError);
  };

  handleChangeAlignment = (alignment: AlignmentType) => {
    this.updateEventNameComponent({ alignment });
  };

  handleSavePadding = (padding: string) => {
    this.updateEventNameComponent({ padding });
  };

  handleChangeFontColor = (color: string) => {
    this.updateEventNameComponent({ color });
  };

  handleChangeBackgroundColor = (backgroundColor: string) => {
    this.updateEventNameComponent({ backgroundColor });
  };

  render() {
    const {
      org,
      onChangeComponentProperty,
      selectedPageComponent: { id, eventNameComponent },
    } = this.props;

    return (
      <Container>
        {id === 'new' && <BlockingLayer />}
        <Section>
          <Font
            fontFamily={eventNameComponent.fontFamily}
            fontStyle={eventNameComponent.fontStyle}
            fontSize={eventNameComponent.fontSize}
            onChangeComponentProperty={onChangeComponentProperty}
            onSaveFontProperties={this.updateEventNameComponent}
          />
          <PropertyRow label="Alignment">
            <AlignmentToggle
              selectedAlignment={eventNameComponent.alignment}
              onChange={this.handleChangeAlignment}
            />
          </PropertyRow>
          <PropertyRow label="Font color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={eventNameComponent.color || defaultTextColor}
              onChangeColor={this.handleChangeFontColor}
              alignTo="bottom-right"
            />
          </PropertyRow>
        </Section>
        <Section>
          <PropertyRow label="Background color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={eventNameComponent.backgroundColor || defaultBackgroundColor}
              onChangeColor={this.handleChangeBackgroundColor}
              includeTransparent
            />
          </PropertyRow>
          <PropertyRow label="Text Padding">
            <PaddingComponent
              paddingString={eventNameComponent.padding}
              onSave={this.handleSavePadding}
              onChange={onChangeComponentProperty}
            />
          </PropertyRow>
        </Section>
      </Container>
    );
  }
}

export default createFragmentContainer(EventNameComponent, {
  org: graphql`
    fragment EventNameComponent_org on Org {
      id
      settings {
        ...RegistrationColorSelector_orgSettings
      }
    }
  `,
  selectedPageComponent: graphql`
    fragment EventNameComponent_selectedPageComponent on RegistrationPageComponent {
      id
      kind
      eventNameComponent {
        id
        padding
        alignment
        fontFamily
        fontStyle
        fontSize
        color
        backgroundColor
      }
    }
  `,
});
