/**
 * @flow
 * @relayHash b6c89ed17de9b470d6e59f8e0767aa16
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SectionCustomizableType = "EVENT";
export type ReorderCustomFieldSectionsInput = {
  clientMutationId?: ?string,
  customizableType: SectionCustomizableType,
  sectionsOrder: $ReadOnlyArray<SectionOrderInput>,
};
export type SectionOrderInput = {
  id: string,
  order: number,
};
export type reorderCustomFieldSectionsMutationVariables = {|
  input: ReorderCustomFieldSectionsInput
|};
export type reorderCustomFieldSectionsMutationResponse = {|
  +reorderCustomFieldSections: ?{|
    +updatedSections: $ReadOnlyArray<{|
      +id: string,
      +order: number,
    |}>
  |}
|};
export type reorderCustomFieldSectionsMutation = {|
  variables: reorderCustomFieldSectionsMutationVariables,
  response: reorderCustomFieldSectionsMutationResponse,
|};
*/


/*
mutation reorderCustomFieldSectionsMutation(
  $input: ReorderCustomFieldSectionsInput!
) {
  reorderCustomFieldSections(input: $input) {
    updatedSections {
      id
      order
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReorderCustomFieldSectionsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reorderCustomFieldSections",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ReorderCustomFieldSectionsInput!"
      }
    ],
    "concreteType": "ReorderCustomFieldSectionsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedSections",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomFieldSectionType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "order",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "reorderCustomFieldSectionsMutation",
  "id": null,
  "text": "mutation reorderCustomFieldSectionsMutation(\n  $input: ReorderCustomFieldSectionsInput!\n) {\n  reorderCustomFieldSections(input: $input) {\n    updatedSections {\n      id\n      order\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "reorderCustomFieldSectionsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "reorderCustomFieldSectionsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '781fa6753069db13b11ae46fe4af2ea2';
module.exports = node;
