/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationSettingsContent_event$ref = any;
type RegistrationSettingsContent_me$ref = any;
type RegistrationSettingsContent_org$ref = any;
type RegistrationSettingsContent_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationSettingsRefetchContainer_query$ref: FragmentReference;
export type RegistrationSettingsRefetchContainer_query = {|
  +event?: ?{|
    +editableRegistrationForm: ?{|
      +$fragmentRefs: RegistrationSettingsContent_registrationForm$ref
    |},
    +$fragmentRefs: RegistrationSettingsContent_event$ref,
  |},
  +org: {|
    +registrationForm?: {|
      +$fragmentRefs: RegistrationSettingsContent_registrationForm$ref
    |},
    +$fragmentRefs: RegistrationSettingsContent_org$ref,
  |},
  +me: {|
    +$fragmentRefs: RegistrationSettingsContent_me$ref
  |},
  +$refType: RegistrationSettingsRefetchContainer_query$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "FragmentSpread",
    "name": "RegistrationSettingsContent_registrationForm",
    "args": null
  }
];
return {
  "kind": "Fragment",
  "name": "RegistrationSettingsRefetchContainer_query",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventSlug",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "isEventRegForm",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "org",
      "storageKey": null,
      "args": null,
      "concreteType": "Org",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "RegistrationSettingsContent_org",
          "args": null
        },
        {
          "kind": "Condition",
          "passingValue": false,
          "condition": "isEventRegForm",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "registrationForm",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationForm",
              "plural": false,
              "selections": v0
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "me",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "RegistrationSettingsContent_me",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "isEventRegForm",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "event",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "slug",
              "variableName": "eventSlug",
              "type": "String"
            }
          ],
          "concreteType": "Event",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "RegistrationSettingsContent_event",
              "args": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "editableRegistrationForm",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationForm",
              "plural": false,
              "selections": v0
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '23949a659d6bfac7c028e3670e449f55';
module.exports = node;
