/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import type { EventVenueZip_event } from './__generated__/EventVenueZip_event.graphql';

const EventVenueZip = (props: { event: EventVenueZip_event }) => {
  const primaryLocation = props.event.primaryLocation;
  return primaryLocation ? primaryLocation.postal : null;
};

export default createFragmentContainer(
  EventVenueZip,
  graphql`
    fragment EventVenueZip_event on Event {
      primaryLocation {
        postal
      }
    }
  `,
);
