/* @flow */
import React from 'react';
import styled from 'styled-components';

import { type IntegrationSettings } from 'components/integrations/IntegrationsSettings';

const IntegrationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  padding: ${props => (props.size - 56) / 2}px;
  border-radius: 5px;
  border: ${props => (props.border ? 1 : 0)}px solid ${props => props.color};
  color: ${props => props.color};
  background: ${props => (props.fillBackground ? props.color : 'transparent')};
`;

const IntegrationIconContainer = styled.div`
  flex-shrink: 0;

  svg {
    width: 100%;
    height: auto;
  }
`;

export default class IntegrationSettingsIcon extends React.PureComponent<{
  integration: IntegrationSettings,
  size: number,
  className?: string,
}> {
  render() {
    const { integration, size, className } = this.props;
    const mini = size < 50;

    return (
      <IntegrationIcon
        color={integration.color}
        fillBackground={!mini && !!integration.largeIcon}
        size={size}
        border={!mini}
        className={className}
      >
        <IntegrationIconContainer style={{ width: `${integration.iconWidth ?? 100}%` }}>
          {(mini ? undefined : integration.largeIcon) ?? integration.icon}
        </IntegrationIconContainer>
      </IntegrationIcon>
    );
  }
}
