/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import removeRecordsFromConnections from 'graph/updaters/removeRecordsFromConnections';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { getRemovedFilterUpdaters } from './updateContactFormField';

import type {
  removeContactFormFieldMutationResponse,
  removeContactFormFieldMutationVariables,
} from './__generated__/removeContactFormFieldMutation.graphql';

const mutation = graphql`
  mutation removeContactFormFieldMutation($input: RemoveContactFormFieldInput!) {
    removeContactFormField(input: $input) {
      contactForm {
        id
        enableCompanyFields
      }
      registrationForm {
        id
        hasChanges
      }
      removedContactFormFieldIds
      removedTextFilterConnections {
        parentId
        childId
      }
      removedLinkFilterConnections {
        parentId
        childId
      }
      removedNumberFilterConnections {
        parentId
        childId
      }
      removedCurrencyFilterConnections {
        parentId
        childId
      }
      removedBooleanFilterConnections {
        parentId
        childId
      }
      removedMultiselectFilterConnections {
        parentId
        childId
      }
      removedDateFilterConnections {
        parentId
        childId
      }
      removedUserMultiselectFilterConnections {
        parentId
        childId
      }
      removedRuleConnections {
        parentId
        childId
      }
    }
  }
`;

export default function removeContactFormField(
  input: $PropertyType<removeContactFormFieldMutationVariables, 'input'>,
): Promise<removeContactFormFieldMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input },
    updater: mergeUpdaters(
      ...getRemovedFilterUpdaters(),
      removeRecordsFromConnections('removedRuleConnections', {
        field: 'rules',
      }),
      removeRecordFromConnection({
        deletedIdField: 'removedContactFormFieldIds',
        parentId: input.contactFormId,
        connections: [
          {
            field: 'contactFormFields',
          },
        ],
      }),
      removeRecordFromStore({ deletedIdField: 'removedContactFormFieldIds' }),
    ),
  });
}
