/* @flow */
import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import styled, { keyframes } from 'styled-components';
import difference from 'lodash/difference';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import updateCustomFieldValue, {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import updateEvent, { type Event } from 'graph/mutations/event/updateEvent';
import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { type EventCellPropsType } from 'components/AllEvents/eventsTableColumnSettings';
import Table, { type ColumnType } from 'components/budget/Table';
import TablePagination from 'components/budget/Table/TablePagination';
import { type ColumnConfiguration } from 'components/material/table';
import NoResultsMessage from 'components/NoResultsMessage';

import type { AllEventsTablePagination_me } from './__generated__/AllEventsTablePagination_me.graphql';
import type { AllEventsTablePagination_org } from './__generated__/AllEventsTablePagination_org.graphql';
import type { AllEventsTablePagination_totalCountMe } from './__generated__/AllEventsTablePagination_totalCountMe.graphql';

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const Container = styled.div`
  min-width: ${props => props.minWidth}px;
  animation: ${fadeIn} 0.15s;
`;

type EventType = {|
  +id: string,
  +$fragmentRefs: any,
  +viewerCanUpdate: boolean,
  +tz: string,
|};

type Props = {
  org: AllEventsTablePagination_org,
  me: AllEventsTablePagination_me,
  totalCountMe: AllEventsTablePagination_totalCountMe,
  shownColumns: ColumnConfiguration,
  filtered: boolean,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  relay: RelayPaginationProp,
  includedColumns: { [string]: boolean },
  className?: string,
  onSelectEvents: (items: $ReadOnlyArray<string | number>) => void,
  selectedEvents: $ReadOnlyArray<string>,
  setColumnWidthUpdater: (updateColumnWidths: () => void) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onEventsListUpdate: (contacts: $ReadOnlyArray<string>) => void,
};

type State = {
  refetching: boolean,
  availableEvents: $ReadOnlyArray<string>,
};

class AllEventsTablePagination extends React.PureComponent<Props, State> {
  state = {
    refetching: false,
    availableEvents: [],
  };

  paginationDisposable: ?Disposable;

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const prevItems = prevState.availableEvents;
    const nextItems = nextProps.me ? nextProps.me.events.edges.map(({ node }) => node.id) : [];
    if (
      nextProps.me &&
      (difference(prevItems, nextItems).length > 0 || difference(nextItems, prevItems).length > 0)
    ) {
      nextProps.onEventsListUpdate(nextItems);
      return { availableEvents: nextItems };
    }
    return null;
  }

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  cellProps = (
    event,
    _group,
    column?: ColumnType<any, any>,
  ): EventCellPropsType<
    AllEventsTablePagination_org,
    AllEventsTablePagination_me,
    {|
      +id: string,
      +$fragmentRefs: any,
      +viewerCanUpdate: boolean,
      +tz: string,
    |},
  > => {
    const { org, me, shownColumns } = this.props;
    const columnsLength = shownColumns.length;
    return {
      org,
      event,
      user: me,
      customizable: event,
      fieldSettings: column != null ? column.fieldSettings : null,
      linkEventNameToBrief: undefined, // used for shared events list
      readOnly: !event.viewerCanUpdate,
      userEmail: this.props.me.email,
      tz: event.tz,
      isLast:
        column != null && column.fieldSettings != null
          ? shownColumns[columnsLength - 2].fieldSettings &&
            shownColumns[columnsLength - 2].fieldSettings.id === column.fieldSettings.id
          : false,
      onUpdate: (args: $Shape<Event>): Promise<void> => {
        return updateEvent(event.id, args, 'event list')
          .catch(showModernMutationError)
          .then(() => undefined);
      },
      onUpdateCustomField: (
        customizable: CustomizableResponse,
        args: UpdateCustomFieldValueInput,
      ): Promise<void> => {
        return updateCustomFieldValue(args, customizable, event.id, 'event list')
          .catch(showModernMutationError)
          .then(() => undefined);
      },
      salesforceHost: org != null && org.salesforceAccount ? org.salesforceAccount.host : null,
    };
  };

  keyExtractor = (event: EventType): string => {
    return event.id;
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  handleCheckUserCanUpdateItem = (event: EventType): string => {
    return event.viewerCanUpdate ? '' : "You don't have permission to change that Event";
  };

  getViewerCanUpdateAllEvents = (events: ?$ReadOnlyArray<EventType>) => {
    if (this.props.org && this.props.org.viewerCanUpdate) {
      return true;
    }
    return events ? events.every(event => event.viewerCanUpdate) : false;
  };

  render() {
    const {
      me,
      className,
      totalCountMe,
      shownColumns,
      sort,
      onChangeSort,
      filtered,
      selectedEvents,
      onSelectEvents,
      setColumnWidthUpdater,
    } = this.props;

    const eventNodes = me != null ? me.events.edges.map(({ node }) => node).filter(Boolean) : null;
    return (
      <Container className={className}>
        {(!eventNodes || eventNodes.length > 0) && (
          <Table
            data={eventNodes}
            columns={shownColumns}
            sort={sort}
            onChangeSort={onChangeSort}
            cellProps={this.cellProps}
            keyExtractor={this.keyExtractor}
            selectedItems={selectedEvents}
            onSelectItems={onSelectEvents}
            setColumnWidthUpdater={setColumnWidthUpdater}
            viewerCanUpdateAllItems={this.getViewerCanUpdateAllEvents(eventNodes)}
            checkUserCanUpdateItem={this.handleCheckUserCanUpdateItem}
          />
        )}

        {eventNodes && eventNodes.length === 0 && (
          <NoResultsMessage
            iconName="calendar-o"
            message={filtered ? 'No events match filters' : 'No events here yet'}
          />
        )}
        {eventNodes && eventNodes.length > 0 && (
          <TablePagination
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            currentCount={eventNodes ? eventNodes.length : null}
            totalCount={
              totalCountMe && totalCountMe.eventsCount ? totalCountMe.eventsCount.totalCount : null
            }
            entityName="Events"
          />
        )}
      </Container>
    );
  }
}

export default createPaginationContainer(
  AllEventsTablePagination,
  {
    org: graphql`
      fragment AllEventsTablePagination_org on Org {
        id
        name
        viewerCanUpdate
        salesforceAccount {
          host
        }
        ...CustomizableCurrency_org @include(if: $includeCustomizableCurrency)
        ...BudgetPlanned_org @include(if: $includePlannedTotalCost)
        ...BudgetActual_org @include(if: $includeActualTotalCost)
        ...BudgetPaid_org @include(if: $includePaidTotalCost)
        ...SalesforceOpportunitiesAmount_org @include(if: $includeOpportunitiesAmount)
        ...EventRequestsRequestedBy_org @include(if: $includeRequestedBy)
      }
    `,
    me: graphql`
      fragment AllEventsTablePagination_me on User {
        id
        firstName
        email
        ...EventActions_user
        events(first: $count, after: $cursor, filters: $filters)
          @connection(key: "AllEventsTablePagination_events", filters: []) {
          edges {
            node {
              id
              viewerCanUpdate
              tz
              ...EventName_event @include(if: $includeName)
              ...TeamName_event @include(if: $includeTeam)
              ...EventStartDate_event @include(if: $includeStartDate)
              ...EventEndDate_event @include(if: $includeEndDate)
              ...EventVirtualLocation_event @include(if: $includeVirtualLocation)
              ...EventFormat_event @include(if: $includeEventFormat)
              ...EventVenueZip_event @include(if: $includeVenueZip)
              ...EventVenueStreet_event @include(if: $includeVenueStreet)
              ...EventVenueState_event @include(if: $includeVenueState)
              ...EventVenueName_event @include(if: $includeVenueName)
              ...EventVenueCountry_event @include(if: $includeVenueCountry)
              ...EventVenueCity_event @include(if: $includeVenueCity)
              ...EventStatus_event @include(if: $includeStatus)
              ...EventProgress_event @include(if: $includeProgress)
              ...EventWebsite_event @include(if: $includeWebsite)
              ...EventLead_event @include(if: $includeLead)
              ...EventStaff_event @include(if: $includeEventStaff)
              ...BoothNumber_event @include(if: $includeBooth)
              ...BoothDimensions_event @include(if: $includeBoothDimensions)
              ...BudgetPlanned_event @include(if: $includePlannedTotalCost)
              ...BudgetActual_event @include(if: $includeActualTotalCost)
              ...BudgetPaid_event @include(if: $includePaidTotalCost)
              ...SalesforceId_event @include(if: $includeSalesforceId)
              ...SalesforceOpportunitiesNumber_event @include(if: $includeOpportunitiesNumber)
              ...SalesforceOpportunitiesAmount_event @include(if: $includeOpportunitiesAmount)
              ...MarketoId_event @include(if: $includeMarketoId)
              ...SalesforceLastSync_event @include(if: $includeLastSync)
              ...SalesforceSyncStatus_event @include(if: $includeSyncStatus)
              ...EventRequestsStatus_event @include(if: $includeRequestStatus)
              ...EventRequestsReviewers_event @include(if: $includeRequestReviewers)
              ...EventRequestsRequestedDate_event @include(if: $includeRequestedDate)
              ...EventRequestsRequestedBy_event @include(if: $includeRequestedBy)
              ...EventRequestsFormName_event @include(if: $includeRequestForm)
              ...EventUpdater_event @include(if: $includeUpdater)
              ...EventUpdatedAt_event @include(if: $includeUpdatedAt)
              ...EventCreator_event @include(if: $includeCreator)
              ...EventCreatedAt_event @include(if: $includeCreatedAt)
              ...EventId_event @include(if: $includeId)
              ...CustomizableText_customizable @include(if: $includeCustomizableText)
              ...CustomizableTextarea_customizable @include(if: $includeCustomizableTextarea)
              ...CustomizableLink_customizable @include(if: $includeCustomizableLink)
              ...CustomizableDate_customizable @include(if: $includeCustomizableDate)
              ...CustomizableBoolean_customizable @include(if: $includeCustomizableBoolean)
              ...CustomizableNumber_customizable @include(if: $includeCustomizableNumber)
              ...CustomizableCurrency_customizable @include(if: $includeCustomizableCurrency)
              ...CustomizableSelect_customizable @include(if: $includeCustomizableSelect)
              ...CustomizableMultiselect_customizable @include(if: $includeCustomizableMultiselect)
              ...CustomizableUserSelect_customizable @include(if: $includeCustomizableUserSelect)
              ...CustomizableUserMultiselect_customizable
                @include(if: $includeCustomizableUserMultiselect)
              ...EventContactsCount_event @include(if: $includeContactsCount)
              ...RegisteredContactsTotal_event @include(if: $includeRegisteredContactsTotal)
              ...AttendedContactsTotal_event @include(if: $includeAttendedContactsTotal)
              ...BriefWeb_event @include(if: $includeBriefWeb)
              ...EventActions_event
            }
          }
        }
      }
    `,
    totalCountMe: graphql`
      fragment AllEventsTablePagination_totalCountMe on User {
        eventsCount: events(filters: $filters) {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.me && props.me.events;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        includeActions: true,
        readOnly: !!props.readOnly,
        count,
        cursor,
      };
    },
    query: graphql`
      query AllEventsTablePaginationQuery(
        $count: Int!
        $readOnly: Boolean!
        $cursor: String
        $filters: EventFilters!
        $includeName: Boolean!
        $includeTeam: Boolean!
        $includeStartDate: Boolean!
        $includeEndDate: Boolean!
        $includeVirtualLocation: Boolean!
        $includeEventFormat: Boolean!
        $includeVenueCity: Boolean!
        $includeVenueCountry: Boolean!
        $includeVenueName: Boolean!
        $includeVenueState: Boolean!
        $includeVenueStreet: Boolean!
        $includeVenueZip: Boolean!
        $includeStatus: Boolean!
        $includeProgress: Boolean!
        $includeWebsite: Boolean!
        $includeLead: Boolean!
        $includeEventStaff: Boolean!
        $includeBooth: Boolean!
        $includeBoothDimensions: Boolean!
        $includeActualTotalCost: Boolean!
        $includePaidTotalCost: Boolean!
        $includePlannedTotalCost: Boolean!
        $includeSalesforceId: Boolean!
        $includeMarketoId: Boolean!
        $includeSyncStatus: Boolean!
        $includeOpportunitiesNumber: Boolean!
        $includeOpportunitiesAmount: Boolean!
        $includeLastSync: Boolean!
        $includeRequestForm: Boolean!
        $includeRequestReviewers: Boolean!
        $includeRequestStatus: Boolean!
        $includeRequestedBy: Boolean!
        $includeRequestedDate: Boolean!
        $includeUpdatedAt: Boolean!
        $includeUpdater: Boolean!
        $includeCreatedAt: Boolean!
        $includeCreator: Boolean!
        $includeId: Boolean!
        $includeBriefWeb: Boolean!
        $includeCustomizableBoolean: Boolean!
        $includeCustomizableNumber: Boolean!
        $includeCustomizableCurrency: Boolean!
        $includeCustomizableText: Boolean!
        $includeCustomizableTextarea: Boolean!
        $includeCustomizableLink: Boolean!
        $includeCustomizableDate: Boolean!
        $includeCustomizableSelect: Boolean!
        $includeCustomizableMultiselect: Boolean!
        $includeCustomizableUserSelect: Boolean!
        $includeCustomizableUserMultiselect: Boolean!
        $includeContactsCount: Boolean!
        $includeRegisteredContactsTotal: Boolean!
        $includeAttendedContactsTotal: Boolean!
      ) {
        me {
          ...AllEventsTablePagination_me
        }
      }
    `,
  },
);
