/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  reviewEventRequestSubmissionMutationVariables,
  reviewEventRequestSubmissionMutationResponse,
} from './__generated__/reviewEventRequestSubmissionMutation.graphql';

const mutation = graphql`
  mutation reviewEventRequestSubmissionMutation($input: ReviewEventRequestSubmissionInput!) {
    reviewEventRequestSubmission(input: $input) {
      requestSubmission {
        ...SubmissionReviewContent_submission
      }
      org {
        eventRequestSubmissions(statuses: [NEW, RESUBMITTED]) {
          totalCount
        }
      }
    }
  }
`;

export default function reviewEventRequestSubmission(
  input: $PropertyType<reviewEventRequestSubmissionMutationVariables, 'input'>,
): Promise<reviewEventRequestSubmissionMutationResponse> {
  const variables: reviewEventRequestSubmissionMutationVariables = {
    input,
  };

  return commitModernMutation({
    mutation,
    variables,
  });
}
