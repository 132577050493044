/* @flow */

import { graphql, createFragmentContainer } from 'react-relay';

import type { RequestSubmissionFormName_requestSubmission } from './__generated__/RequestSubmissionFormName_requestSubmission.graphql';

type Props = {
  requestSubmission: RequestSubmissionFormName_requestSubmission,
};

const RequestSubmissionFormName = ({ requestSubmission }: Props) => {
  return requestSubmission.form.name;
};

export default createFragmentContainer(
  RequestSubmissionFormName,
  graphql`
    fragment RequestSubmissionFormName_requestSubmission on EventRequestSubmission {
      form {
        name
      }
    }
  `,
);
