/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { Content } from 'components/page/Content';

import RegistrationFormTemplate from './RegistrationFormTemplate';

import type { RegistrationFormTemplatesQueryResponse } from './__generated__/RegistrationFormTemplatesQuery.graphql';

const query = graphql`
  query RegistrationFormTemplatesQuery {
    query {
      ...RegistrationFormTemplate_query
    }
  }
`;

class RegistrationFormTemplates extends React.PureComponent<{
  title: string,
  history: RouterHistory,
  location: Location,
}> {
  render() {
    const { history, title, location } = this.props;
    return (
      <DocumentTitle title={title}>
        <Content>
          <DefaultQueryRenderer
            query={query}
            renderSuccess={({ query: responseQuery }: RegistrationFormTemplatesQueryResponse) => {
              if (responseQuery == null) {
                return null;
              }
              return (
                <RegistrationFormTemplate
                  history={history}
                  query={responseQuery}
                  location={location}
                />
              );
            }}
          />
        </Content>
      </DocumentTitle>
    );
  }
}

export default RegistrationFormTemplates;
