/* @flow */
import React from 'react';
import styled from 'styled-components';
import Clipboard from 'clipboard';
import moment from 'moment-timezone';

import formatDateTimeRange from 'utils/date/formatDateTimeRange';

import ZoomCamera from 'images/integrations/zoomCamera.svg';

import { type ZoomLocationType } from '.';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px;
  background: rgba(0, 0, 0, 0.7);
  transform: translateX(70%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
`;

const Root = styled.div`
  position: relative;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    ${Overlay} {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
`;

const CardLink = styled.div`
  display: block;
  padding: 15px;
  color: #fff;
  background: linear-gradient(to bottom, #5b92f4, #3d9ad9);

  [href] {
    &:hover {
      background: linear-gradient(to top, #5b92f4, #3d9ad9);
    }
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const Date = styled.div`
  margin-top: 3px;
  font-size: 13px;
`;

const OverlayLink = styled.a`
  margin: 3px 0;
  font-size: 12px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }

  svg,
  i {
    margin-right: 3px;
  }

  svg {
    path {
      fill: currentColor;
    }
  }
`;

export default class ZoomLocationCard extends React.PureComponent<{
  zoomLocation: ZoomLocationType,
}> {
  copyLinkRef = React.createRef();

  clipboard = null;

  componentDidMount() {
    this.setupClipboard();
  }

  componentDidUpdate() {
    if (this.copyLinkRef.current == null) {
      this.destroyClipboard();
    } else {
      this.setupClipboard();
    }
  }

  componentWillUnmount() {
    this.destroyClipboard();
  }

  setupClipboard = () => {
    if (this.copyLinkRef.current != null) {
      this.clipboard = new Clipboard(this.copyLinkRef.current, {
        text: () => this.props.zoomLocation.joinUrl,
      });
    }
  };

  destroyClipboard = () => {
    if (this.clipboard) {
      this.clipboard.destroy();
      this.clipboard = null;
    }
  };

  render() {
    const zoomLocation = this.props.zoomLocation;

    return (
      <Root>
        <CardLink
          href={zoomLocation.zoomUrl || undefined}
          target="_blank"
          rel="noreferrer noopener"
          as={zoomLocation.zoomUrl ? 'a' : 'div'}
        >
          <Title>{zoomLocation.topic}</Title>

          <Date>
            {zoomLocation.startTime
              ? formatDateTimeRange({
                  startDate: moment(zoomLocation.startTime).tz(zoomLocation.timezone),
                  endDate: moment(zoomLocation.startTime)
                    .tz(zoomLocation.timezone)
                    .add(zoomLocation.duration, 'minutes'),
                })
              : 'Recurring'}
          </Date>
        </CardLink>

        {zoomLocation.joinUrl && (
          <Overlay>
            <OverlayLink ref={this.copyLinkRef}>
              <i className="fa fa-fw fa-clone" /> Copy Join Link
            </OverlayLink>

            <OverlayLink href={zoomLocation.joinUrl} target="_blank" rel="noreferrer noopener">
              <ZoomCamera /> Join Meeting
            </OverlayLink>
          </Overlay>
        )}
      </Root>
    );
  }
}
