/* @flow */
import React from 'react';
import styled from 'styled-components';

import Switch from 'components/material/Switch';

import type { QuestionType } from '../lib/types';

const Bar = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 25px -15px 0 -20px;
  padding: 0 15px 0 20px;
  background: #f1f9fc;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

const SwitchLabel = styled.div`
  margin-right: 15px;
  font-size: 13px;
  font-weight: 500;
  color: #4a5665;
`;

const Right = styled.div`
  display: flex;
`;

const Action = styled.div`
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
  color: #96a2ab;
  &:hover {
    color: #4a5665;
  }
`;

export default class EditableQuestionActions extends React.PureComponent<{
  question: QuestionType,
  onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
  onRemoveQuestion: (questionId: string) => void,
}> {
  handleChangeRequired = (required: boolean) => {
    this.props.onChangeQuestion({ required });
  };

  handleRemove = () => {
    this.props.onRemoveQuestion(this.props.question.id);
  };

  render() {
    const { mapping, required } = this.props.question;
    const requiredQuestion = mapping === 'NAME';

    return (
      <Bar>
        <Left>
          <SwitchLabel>Required</SwitchLabel>
          <Switch
            enabled={required}
            size="small"
            onChange={this.handleChangeRequired}
            disabled={requiredQuestion}
          />
        </Left>
        <Right>
          {!requiredQuestion && (
            <Action onClick={this.handleRemove}>
              <i className="fa fa-fw fa-trash" />
            </Action>
          )}
        </Right>
      </Bar>
    );
  }
}
