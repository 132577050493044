/* @flow */
import React from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';

import sanitizeHtml from 'utils/string/sanitizeHtml';

import ConfirmationWindow from 'components/ConfirmationWindow';
import { StyledContent } from 'components/TinyRichText';

import EditableContainer from '../EditableContainer';
import companySuggestedFields from '../lib/companySuggestedFields';
import {
  companyDefaultFields,
  contactDefaultFields,
  defaultCustomFieldValues,
} from '../lib/contactDefaultValues';
import customFields from '../lib/customFields';
import isCompanyField from '../lib/isCompanyField';
import suggestedFields from '../lib/suggestedFields';
import type { Field, QuestionType, ReorderedQuestionType } from '../lib/types';
import validateQuestion from '../lib/validateQuestion';
import EditableQuestionDraggable from './EditableQuestionDraggable';
import EditableQuestionView from './EditableQuestionView';

const Row = styled.div`
  margin: 14px 0 10px 0;
`;

export default class EditableQuestion extends React.PureComponent<
  {
    currency: string,
    question: QuestionType,
    editing: boolean,
    onChangeQuestion: (question: QuestionType) => void,
    onRemoveQuestion: (id: string) => void,
    onMoveQuestion: (reorderedQuestion: ReorderedQuestionType) => void,
    onMoveEnd: (reorderedQuestion: ReorderedQuestionType) => void,
    availableFields: $ReadOnlyArray<Field>,
    handleCheckMappingDependency: (fieldId: ?string, fieldName: ?string) => boolean,
    defaultFont?: string,
    restrictedDruggingZone: boolean,
  },
  {
    question: QuestionType,
    errors: $ReadOnlyArray<string>,
    dragStartId?: string,
    dependantFieldId: string,
    newMappingField: ?Field,
    newMappingFieldName: string,
  },
> {
  static getDerivedStateFromProps(
    nextProps: $PropertyType<EditableQuestion, 'props'>,
    prevState: $PropertyType<EditableQuestion, 'state'>,
  ) {
    if (nextProps.question.order === prevState.question.order) return null;

    return {
      question: {
        ...prevState.question,
        order: nextProps.question.order,
      },
    };
  }

  state = {
    errors: [],
    question: this.props.question,
    dragStartId: undefined,
    dependantFieldId: '',
    newMappingField: null,
    newMappingFieldName: '',
  };

  getFieldDefaultLabel = (field: ?Field) => {
    if (!field) {
      return 'Salesforce Sync as';
    }
    if (field.customizableType === 'COMPANY') {
      return `Company ${field.label}`;
    }
    return field.label;
  };

  handleSave = () => {
    this.setState(state => ({ errors: validateQuestion(state.question) }));

    const errors = validateQuestion(this.state.question);

    if (errors.length === 0) {
      const oldQuestion = this.props.question;
      const newQuestion = this.state.question;

      if (
        oldQuestion.fieldName !== newQuestion.fieldName ||
        (oldQuestion.customField &&
          newQuestion.customField &&
          oldQuestion.customField.id !== newQuestion.customField.id) ||
        oldQuestion.label !== newQuestion.label ||
        oldQuestion.required !== newQuestion.required ||
        oldQuestion.helpText !== newQuestion.helpText ||
        oldQuestion.showOtherOption !== newQuestion.showOtherOption ||
        !isEqual(oldQuestion.contactFormFieldOptions, newQuestion.contactFormFieldOptions)
      )
        this.props.onChangeQuestion(this.state.question);
    }

    return errors.length === 0;
  };

  changeMapping = (customField: ?Field, fieldName?: string) => {
    this.setState(state => {
      const customFieldName = customField ? customField.fieldName : null;
      const newQuestion: QuestionType = {
        ...state.question,
        label: state.question.label || this.getFieldDefaultLabel(customField),
        fieldName: fieldName || customFieldName,
        customField,
        showOtherOption: true,
        contactFormFieldOptions: [],
      };

      return {
        dependantFieldId: '',
        question: newQuestion,
        errors: validateQuestion(newQuestion),
      };
    });
  };

  handleChangeMapping = (customField: ?Field, fieldName?: string) => {
    const { handleCheckMappingDependency, question } = this.props;

    const hasDependency = handleCheckMappingDependency(
      question.id || null,
      question.fieldName || null,
    );
    if (hasDependency) {
      this.setState({
        dependantFieldId: question.id,
        newMappingField: customField,
        newMappingFieldName: fieldName || '',
      });
    } else {
      this.changeMapping(customField, fieldName);
    }
  };

  handleConfirmationHide = () => {
    this.setState({ dependantFieldId: '' });
  };

  handleChangeName = (label: string) => {
    this.setState(state => {
      const newQuestion: QuestionType = {
        ...state.question,
        label,
      };

      return {
        question: newQuestion,
        errors: validateQuestion(newQuestion),
      };
    });
  };

  handleChangeRequired = (required: boolean) => {
    this.setState(state => ({
      question: {
        ...state.question,
        required,
      },
    }));
  };

  handleChangeHelpText = (helpText: ?string) => {
    this.setState(state => ({
      question: {
        ...state.question,
        helpText,
      },
    }));
  };

  handleSetOptions = (contactFormFieldOptions: $ReadOnlyArray<string>) => {
    this.setState(state => ({
      question: {
        ...state.question,
        contactFormFieldOptions,
      },
    }));
  };

  handleSetOther = (showOtherOption: boolean) => {
    this.setState(state => ({
      question: {
        ...state.question,
        showOtherOption,
      },
    }));
  };

  checkPreview = (): boolean => {
    return !!this.props.question.label.trim() && !this.props.editing;
  };

  getSuggestedRenderer = (question: QuestionType) => {
    const coreRenderer = question.fieldName ? suggestedFields[question.fieldName] : null;
    const suggestedRenderer =
      question.customField &&
      question.customField.fieldName &&
      suggestedFields[question.customField.fieldName];

    return coreRenderer || suggestedRenderer;
  };

  renderPreview = () => {
    const { question, currency } = this.props;
    const dragStartId = this.state.dragStartId;
    const viewQuestion = question.customField
      ? {
          ...question,
          customField: {
            ...question.customField,
            options: [
              ...question.customField.options.filter(
                option => !(question.contactFormFieldOptions || []).includes(option.value),
              ),
              question.showOtherOption ? { label: 'Other', value: 'other' } : null,
            ]
              .filter(Boolean)
              .slice(0, dragStartId ? 10 : undefined),
          },
        }
      : question;
    const helpText = question.helpText && (
      <StyledContent
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(question.helpText) }}
        style={{ marginTop: '12px' }}
      />
    );
    if (
      !viewQuestion.customField ||
      (viewQuestion.customField && viewQuestion.customField.kind === 'DEFAULT')
    ) {
      if (isCompanyField(viewQuestion)) {
        return (
          <Row>
            {companySuggestedFields[
              (viewQuestion.customField && viewQuestion.customField.fieldName) ||
                viewQuestion.fieldName ||
                'company_name'
            ]({
              company: companyDefaultFields,
              question: viewQuestion,
              errors: {},
              viewerCanUpdate: false,
              handleUpdate: () => {},
              dragStartId,
            })}
            {helpText}
          </Row>
        );
      }

      const renderer = this.getSuggestedRenderer(viewQuestion);

      if (!renderer) return null;

      return (
        <Row key={viewQuestion.id}>
          {renderer({
            contact: contactDefaultFields,
            question: viewQuestion,
            errors: {},
            viewerCanUpdate: false,
            handleUpdate: () => {},
            dragStartId,
          })}
          {helpText}
        </Row>
      );
    }

    if (viewQuestion.customField.kind === 'DEFAULT') {
      return null;
    }

    return (
      <Row>
        {customFields[viewQuestion.customField.kind]({
          customizable: defaultCustomFieldValues,
          question: viewQuestion,
          errors: {},
          viewerCanUpdate: false,
          tz: '',
          currency,
          handleUpdate: () => {},
          dragStartId,
        })}
        {helpText}
      </Row>
    );
  };

  handleBeginDrag = (questionId: string) => {
    this.setState({ dragStartId: questionId });
    setTimeout(() => {
      this.setState({ dragStartId: undefined });
    }, 0);
  };

  render() {
    const {
      defaultFont,
      onRemoveQuestion,
      onMoveQuestion,
      onMoveEnd,
      editing,
      availableFields,
      restrictedDruggingZone,
    } = this.props;
    return (
      <EditableQuestionDraggable
        question={this.state.question}
        onMove={onMoveQuestion}
        onMoveEnd={onMoveEnd}
        onBeginDrag={this.handleBeginDrag}
        restrictedDruggingZone={restrictedDruggingZone}
      >
        <EditableContainer
          defaultPreviewing={this.checkPreview()}
          editing={editing}
          previewContent={this.renderPreview()}
          editContent={
            <>
              <EditableQuestionView
                question={this.state.question}
                onChangeMapping={this.handleChangeMapping}
                onChangeName={this.handleChangeName}
                onChangeRequired={this.handleChangeRequired}
                onChangeHelpText={this.handleChangeHelpText}
                onRemoveQuestion={onRemoveQuestion}
                onSetOptions={this.handleSetOptions}
                onSetOther={this.handleSetOther}
                availableFields={availableFields}
                errors={this.state.errors}
                dragStartId={this.state.dragStartId}
                defaultFont={defaultFont}
              />
              {this.state.dependantFieldId && (
                <ConfirmationWindow
                  onHide={this.handleConfirmationHide}
                  onConfirm={() =>
                    this.changeMapping(this.state.newMappingField, this.state.newMappingFieldName)
                  }
                  title="Are you sure you want to change the mapping?"
                  message="Changing the mapping on this question will remove all logical dependencies that other questions have with this one."
                  actionLabel="Confirm"
                />
              )}
            </>
          }
          onSave={this.handleSave}
        />
      </EditableQuestionDraggable>
    );
  }
}
