/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AttachmentPreview_attachments$ref = any;
type ScheduledEmailFilters_eventEmailTemplate$ref = any;
type ScheduledEmailTemplateFilterTooltip_eventEmailTemplate$ref = any;
export type EventEmailTemplateBaseDate = "end_date" | "start_date";
export type EventEmailTemplateDirection = "minus" | "plus";
export type EventEmailTemplateIntervalUnit = "days" | "hours" | "minutes" | "months";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduledEmailTemplate_eventEmailTemplate$ref: FragmentReference;
export type ScheduledEmailTemplate_eventEmailTemplate = {|
  +id: string,
  +baseDate: EventEmailTemplateBaseDate,
  +direction: EventEmailTemplateDirection,
  +interval: number,
  +intervalUnit: EventEmailTemplateIntervalUnit,
  +emailSubject: string,
  +emailTemplate: string,
  +attachments: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +fileurl: ?string,
        +filetype: ?string,
        +filesize: ?number,
        +filename: ?string,
        +$fragmentRefs: AttachmentPreview_attachments$ref,
      |}
    |}>
  |},
  +$fragmentRefs: ScheduledEmailFilters_eventEmailTemplate$ref & ScheduledEmailTemplateFilterTooltip_eventEmailTemplate$ref,
  +$refType: ScheduledEmailTemplate_eventEmailTemplate$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ScheduledEmailTemplate_eventEmailTemplate",
  "type": "EventEmailTemplate",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "baseDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "direction",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "interval",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "intervalUnit",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "emailSubject",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "emailTemplate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "attachments",
      "storageKey": null,
      "args": null,
      "concreteType": "AttachmentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AttachmentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Attachment",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fileurl",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "filetype",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "filesize",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "filename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "AttachmentPreview_attachments",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ScheduledEmailFilters_eventEmailTemplate",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ScheduledEmailTemplateFilterTooltip_eventEmailTemplate",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2cbfb4370c8521c2093583c919ae7a18';
module.exports = node;
