/* @flow */
import * as React from 'react';
import DayPicker from 'react-day-picker';
import ClickOut from 'react-onclickout';
import styled, { css } from 'styled-components';
import range from 'lodash/range';
import moment from 'moment-timezone';

import AllEventsCalendarDay, {
  CARD_HEIGHT,
  CARD_HORIZONTAL_SPACING,
  CARD_VERTICAL_SPACING,
  MAX_EVENTS,
  MAX_SCROLL_EVENTS,
} from './AllEventsCalendarDay';
import { type AllEventsCalendarEvent } from './AllEventsCalendarView';
import eventCalendarSlots from './lib/eventCalendarSlots';

const Day = styled.div`
  width: 30px;
  height: 30px;
  margin: -8px 0 -2px auto;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
`;

const DayContainer = styled.div`
  position: relative;
  min-width: 0;
  min-height: 100px;
  padding: ${CARD_HORIZONTAL_SPACING}px;
  font-size: 13px;
  color: #3e4859;
  transition: 0.2s;

  ${props =>
    props.expanded &&
    css`
      z-index: 1;
      overflow-y: auto;
      max-height: ${MAX_SCROLL_EVENTS * (CARD_HEIGHT + CARD_VERTICAL_SPACING) +
      CARD_HORIZONTAL_SPACING * 3 +
      20}px;
      margin: -10px -20px -20px;
      padding: ${2 * CARD_HORIZONTAL_SPACING}px ${CARD_HORIZONTAL_SPACING}px
        ${CARD_HORIZONTAL_SPACING}px;
      background: #fff;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.36), inset 0 0 0 1px #c5c5c5;
    `}
`;

const Root = styled.div`
  .DayPicker {
    border-radius: 7px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.06);
    background: #fff;

    .DayPicker-wrapper,
    .DayPicker-Day {
      outline: none;
    }

    .DayPicker-WeekdaysRow,
    .DayPicker-Week {
      display: flex;
    }

    .DayPicker-Weekday,
    .DayPicker-Day {
      flex: 1;
      min-width: 0;
    }

    .DayPicker-Weekday {
      padding: 14px 0;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #3e4859;

      &:not(:last-child) {
        box-shadow: inset -1px 0 #c5c5c5;
      }

      abbr {
        text-decoration: none;
      }
    }

    .DayPicker-Day {
      max-height: ${MAX_EVENTS * (CARD_HEIGHT + CARD_VERTICAL_SPACING) +
      CARD_HORIZONTAL_SPACING * 2 +
      48}px;
      box-shadow: inset 0 1px #c5c5c5;

      &:not(:last-child) {
        box-shadow: inset 0 1px #c5c5c5, inset -1px 0 #c5c5c5;
      }

      &.DayPicker-Day--outside {
        background: #f9f9f9;
      }

      &.DayPicker-Day--today {
        ${Day} {
          font-weight: 500;
          color: #005eff;
          background: #f1fafe;
        }
      }
    }
  }
`;

const weekdays = range(7).map(i => moment().day(i).format('ddd'));

const noop = () => null;

export default class AllEventsCalendarMonthView extends React.PureComponent<
  {
    month: string,
    events: $ReadOnlyArray<AllEventsCalendarEvent>,
    linkEventNameToBrief?: boolean,
  },
  { hoveringId: ?string, expandedDate: ?string },
> {
  state = { hoveringId: null, expandedDate: null };

  slots = eventCalendarSlots(
    this.props.events,
    this.calendarStart(),
    this.calendarEnd().diff(this.calendarStart(), 'day'),
  );

  handleExpand = (expandedDate: ?string) => this.setState({ expandedDate });

  handleHover = (hoveringId: ?string) => this.setState({ hoveringId });

  calendarStart() {
    const monthStart = moment(this.props.month).startOf('month');
    return moment(monthStart).subtract(monthStart.day(), 'day');
  }

  calendarEnd() {
    const monthEnd = moment(this.props.month).endOf('month');
    return moment(monthEnd).add(7 - monthEnd.day(), 'day');
  }

  render() {
    const { month, linkEventNameToBrief } = this.props;
    const { hoveringId, expandedDate } = this.state;
    const monthMoment = moment(month);

    let dayIndex = -1;

    return (
      <Root>
        <DayPicker
          month={monthMoment.toDate()}
          showOutsideDays
          canChangeMonth={false}
          captionElement={noop}
          navbarElement={noop}
          weekdaysShort={weekdays}
          renderDay={(day: Date) => {
            dayIndex += 1;

            const dayFormatted = moment(day).format();
            const daySlots = this.slots[dayIndex % this.slots.length];
            const expanded = expandedDate === dayFormatted;

            return (
              <ClickOut
                key={dayFormatted}
                onClickOut={expanded ? () => this.setState({ expandedDate: null }) : noop}
              >
                <DayContainer expanded={expanded}>
                  <Day>{day.getDate()}</Day>

                  <AllEventsCalendarDay
                    day={dayFormatted}
                    eventSpots={daySlots}
                    expanded={expanded}
                    onExpand={this.handleExpand}
                    hoveringId={hoveringId}
                    onHover={this.handleHover}
                    linkEventNameToBrief={linkEventNameToBrief}
                  />
                </DayContainer>
              </ClickOut>
            );
          }}
        />
      </Root>
    );
  }
}
