/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/budget/Button';
import Overlay from 'components/material/Overlay';
import { type CreatedEventType } from 'views/Main/Event/window/EventForm';
import EventWindow, { type EventCreateSource } from 'views/Main/Event/window/EventWindow';

import { type AllEventsActionButtons_org } from './__generated__/AllEventsActionButtons_org.graphql';

const Root = styled.div`
  > * {
    margin-right: 10px;
  }
`;

const StyledButton = styled(Button)`
  flex: 0 auto;
  min-width: 130px;
  margin-right: 10px;
`;

const StyledOverlay = styled(Overlay)`
  width: auto;
  margin-top: 5px;
  padding: 5px 0;
`;

const RequestFormRow = styled.div`
  padding: 6px 15px;
  font-size: 14px;
  color: rgba(74, 86, 101, 0.87);
  cursor: pointer;

  &:hover {
    background: rgba(59, 169, 218, 0.07);
  }
`;

class AllEventsActionButtons extends React.PureComponent<
  {
    org: AllEventsActionButtons_org,
    history: RouterHistory,
    fromWindow: EventCreateSource,
    className?: string,
  },
  {
    showEventWindow: boolean,
    showRequestEventOverlay: boolean,
  },
> {
  state = {
    showEventWindow: false,
    showRequestEventOverlay: false,
  };

  rootRef = React.createRef();

  requestEventButtonRef = React.createRef();

  handleShowEventWindow = () => {
    this.setState({ showEventWindow: true });
  };

  handleHideEventWindow = () => {
    this.setState({ showEventWindow: false });
  };

  handleShowRequestEventOverlay = () => {
    if (this.props.org.eventRequestForms.edges.length > 1) {
      this.setState({ showRequestEventOverlay: true });
    } else {
      window.open(this.props.org.eventRequestForms.edges[0].node.shareLink);
    }
  };

  handleHideRequestEventOverlay = () => {
    this.setState({ showRequestEventOverlay: false });
  };

  handleCreateEvent = (createdEvent: CreatedEventType) => {
    this.handleHideEventWindow();

    this.props.history.push(`/events/${createdEvent.slug}/info`);
  };

  render() {
    const {
      org: { viewerCanCreateEvents, eventRequestForms: requestForms },
      fromWindow,
      className,
    } = this.props;
    const eventRequestForms = requestForms.edges.map(edge => edge.node);

    return (
      <Root ref={this.rootRef} className={className}>
        {viewerCanCreateEvents && (
          <StyledButton primary onClick={this.handleShowEventWindow}>
            Create new event
          </StyledButton>
        )}

        {!viewerCanCreateEvents && eventRequestForms.length > 0 && (
          <StyledButton
            ref={this.requestEventButtonRef}
            primary
            onClick={this.handleShowRequestEventOverlay}
          >
            Request an Event
          </StyledButton>
        )}

        {this.state.showEventWindow && (
          <EventWindow
            onHide={this.handleHideEventWindow}
            onCreate={this.handleCreateEvent}
            fromWindow={fromWindow}
          />
        )}

        <StyledOverlay
          container={this.rootRef.current}
          target={this.requestEventButtonRef.current}
          show={this.state.showRequestEventOverlay}
          onHide={this.handleHideRequestEventOverlay}
        >
          {eventRequestForms.map(form => (
            <RequestFormRow key={form.id} onClick={() => window.open(form.shareLink)}>
              {form.name}
            </RequestFormRow>
          ))}
        </StyledOverlay>
      </Root>
    );
  }
}

export default createFragmentContainer(
  AllEventsActionButtons,
  graphql`
    fragment AllEventsActionButtons_org on Org {
      viewerCanCreateEvents
      eventRequestForms(eventsDashboard: true) {
        edges {
          node {
            id
            name
            shareLink
          }
        }
      }
    }
  `,
);
