/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateEvent from 'graph/mutations/event/updateEvent';
import changeEventBudgetCategory from 'graph/mutations/eventBudgetCategory/changeEventBudgetCategory';
import createEventBudgetCategory from 'graph/mutations/eventBudgetCategory/createEventBudgetCategory';
import removeEventBudgetCategory from 'graph/mutations/eventBudgetCategory/removeEventBudgetCategory';
import updateEventBudgetCategory from 'graph/mutations/eventBudgetCategory/updateEventBudgetCategory';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Table from 'components/budget/Table';

import getColumnsConfig, {
  type CategoryCellPropsType,
  type CategoryFooterCellPropsType,
} from './columns';

import { type CategoriesTable_categoryEdges } from './__generated__/CategoriesTable_categoryEdges.graphql';
import { type CategoriesTable_event } from './__generated__/CategoriesTable_event.graphql';
import { type CategoriesTable_org } from './__generated__/CategoriesTable_org.graphql';

const Root = styled.div`
  padding: 25px 20px;
`;

const BudgetCategoryLinkContainer = styled.div`
  padding: 0 10px;

  &:first-child:not(:only-child) {
    padding-bottom: 25px;
  }

  a {
    text-decoration: underline;
    color: #3ba9da;
    cursor: pointer;
    &:hover,
    &:focus {
      outline: none;
      text-decoration: none;
      color: #248fbe;
    }
  }
`;

type CategoryEdgeType = $Exact<
  $ReadOnly<{
    ...$Diff<
      $ElementType<CategoriesTable_categoryEdges, 0>,
      { +$fragmentRefs: any, +$refType: any },
    >,
    mock?: boolean,
  }>,
>;

export const UNCATEGORIZED_ID = 'UNCATEGORIZED';
const ADDING_NAME = 'ADDING';

class CategoriesTable extends React.PureComponent<
  {
    categoryEdges: $ReadOnlyArray<
      $Diff<
        $ElementType<CategoriesTable_categoryEdges, 0>,
        { +$fragmentRefs: any, +$refType: any },
      >,
    >,
    event: CategoriesTable_event,
    org: CategoriesTable_org,
  },
  {
    adding: boolean,
    categoryId: string,
    budgetedAmount: number,
  },
> {
  state = {
    adding: false,
    categoryId: '',
    budgetedAmount: 0,
  };

  tableRef = React.createRef();

  handleChangeAdding = (changes: { categoryId?: string, budgetedAmount?: number }) => {
    this.setState(changes, () => {
      if (!this.state.categoryId || !this.state.budgetedAmount) return;

      if (this.state.categoryId === UNCATEGORIZED_ID) {
        this.handleRemoveAdding();

        updateEvent(this.props.event.id, {
          budgetedAmount: this.props.event.totalBudgetedAmount + this.state.budgetedAmount,
        }).catch(showModernMutationError);
      } else {
        createEventBudgetCategory({
          eventId: this.props.event.id,
          categoryId: this.state.categoryId,
          budgetedAmount: this.state.budgetedAmount,
        })
          .then(this.handleRemoveAdding)
          .catch(showModernMutationError);
      }
    });
  };

  handleRemoveAdding = () => {
    this.setState({ adding: false, categoryId: '', budgetedAmount: 0 });
  };

  handleStartAdding = () => {
    this.setState({ adding: true });
  };

  handleRemoveCategoryEdge = (categoryId: string) => () => {
    if (categoryId === UNCATEGORIZED_ID) {
      const budgetedAmount = this.props.categoryEdges.reduce(
        (sum, edge) => sum + edge.budgetedAmount,
        0,
      );

      return updateEvent(this.props.event.id, { budgetedAmount }).catch(showModernMutationError);
    }

    return removeEventBudgetCategory({ eventId: this.props.event.id, categoryId }).catch(
      showModernMutationError,
    );
  };

  handleUpdateCategoryEdge = (categoryId: string) => updates => {
    if (categoryId === UNCATEGORIZED_ID) {
      const budgetedAmount =
        updates.budgetedAmount != null
          ? updates.budgetedAmount +
            this.props.categoryEdges.reduce((sum, edge) => sum + edge.budgetedAmount, 0)
          : this.props.event.totalBudgetedAmount;

      return updateEvent(this.props.event.id, { budgetedAmount }).catch(showModernMutationError);
    }

    const categoryEdge = this.props.categoryEdges.find(edge => edge.node.id === categoryId);
    const budgetedAmountDiff =
      categoryEdge && updates.budgetedAmount != null
        ? updates.budgetedAmount - categoryEdge.budgetedAmount
        : 0;

    return updateEventBudgetCategory(
      {
        categoryId,
        eventId: this.props.event.id,
        ...updates,
      },
      {
        eventTotalBudgetedAmount: this.props.event.totalBudgetedAmount + budgetedAmountDiff,
      },
    ).catch(showModernMutationError);
  };

  handleChangeCategoryEdge = (categoryId: string) => newCategoryId => {
    return changeEventBudgetCategory({
      categoryId,
      eventId: this.props.event.id,
      newCategoryId,
    }).catch(showModernMutationError);
  };

  cellProps = (hasUncategorized: boolean) => (
    categoryEdge: CategoryEdgeType,
  ): CategoryCellPropsType<CategoryEdgeType, CategoriesTable_event, CategoriesTable_org> => ({
    adding: !!categoryEdge.mock && categoryEdge.node.name === ADDING_NAME,
    isAddingActive: this.state.adding,
    categoryEdgeMock: categoryEdge.mock ? categoryEdge : null,
    categoryEdge: categoryEdge.mock ? null : categoryEdge,
    event: this.props.event,
    org: this.props.org,
    rootElementRef: this.tableRef,
    hasUncategorized: hasUncategorized || this.props.categoryEdges.length === 0,
    changeAdding: this.handleChangeAdding,
    removeAdding: this.handleRemoveAdding,
    updateCategoryEdge: this.handleUpdateCategoryEdge(categoryEdge.node.id),
    removeCategoryEdge: this.handleRemoveCategoryEdge(categoryEdge.node.id),
    changeCategoryEdge: this.handleChangeCategoryEdge(categoryEdge.node.id),
  });

  footerCellProps = (): CategoryFooterCellPropsType<
    CategoriesTable_event,
    CategoriesTable_org,
  > => ({
    event: this.props.event,
    org: this.props.org,
  });

  keyExtractor = (categoryEdge: CategoryEdgeType) =>
    !!categoryEdge.mock && categoryEdge.node.name === ADDING_NAME
      ? ADDING_NAME
      : categoryEdge.node.id;

  render() {
    const { categoryEdges, event, org } = this.props;
    const { adding } = this.state;

    const uncategorized: CategoryEdgeType = {
      budgetedAmount:
        event.totalBudgetedAmount -
        categoryEdges.reduce((sum, edge) => sum + edge.budgetedAmount, 0),
      actualAmount:
        event.totalActualAmount - categoryEdges.reduce((sum, edge) => sum + edge.actualAmount, 0),
      paidAmount:
        event.totalPaidAmount - categoryEdges.reduce((sum, edge) => sum + edge.paidAmount, 0),
      node: { id: UNCATEGORIZED_ID, name: 'Uncategorized' },
      mock: true,
    };

    const showTable = categoryEdges.length > 0 || adding;
    const hasUncategorized = uncategorized.budgetedAmount > 0;

    return (
      <Root>
        {org.budgetCategories.totalCount > 0 &&
          (categoryEdges.length !== org.budgetCategories.totalCount || !hasUncategorized) && (
            <BudgetCategoryLinkContainer>
              <a onClick={this.handleStartAdding} tabIndex={0} role="button">
                {categoryEdges.length > 0
                  ? 'Add another Category to Planned...'
                  : 'Split Planned total into Categories...'}
              </a>
            </BudgetCategoryLinkContainer>
          )}

        {showTable && (
          <Table
            data={[
              ...(adding
                ? [
                    {
                      mock: true,
                      budgetedAmount: this.state.budgetedAmount,
                      actualAmount: 0,
                      paidAmount: 0,
                      node: { id: this.state.categoryId, name: ADDING_NAME },
                    },
                  ]
                : []),
              ...categoryEdges,
              ...(hasUncategorized ? [uncategorized] : []),
            ]}
            columns={getColumnsConfig({ adding })}
            cellProps={this.cellProps(hasUncategorized)}
            footerCellProps={this.footerCellProps}
            keyExtractor={this.keyExtractor}
            innerRef={this.tableRef}
          />
        )}
      </Root>
    );
  }
}

export default createFragmentContainer(
  CategoriesTable,
  graphql`
    fragment CategoriesTable_categoryEdges on EventBudgetCategoryEdge @relay(plural: true) {
      budgetedAmount
      actualAmount
      paidAmount

      ...CategoryNameCell_categoryEdge
      ...CategoryBudgetedAmountCell_categoryEdge
      ...CategoryActualAmountCell_categoryEdge
      ...CategoryActualOfBudgetedCell_categoryEdge
      ...CategoryPaidAmountCell_categoryEdge
      ...CategoryPaidOfActualCell_categoryEdge
      ...CategoryActionCell_categoryEdge

      node {
        id
        name
      }
    }

    fragment CategoriesTable_event on Event {
      id
      totalBudgetedAmount
      totalActualAmount
      totalPaidAmount

      ...CategoryNameCell_event
      ...CategoryActionCell_event
      ...CategoryBudgetedAmountFooterCell_event
      ...CategoryActualAmountFooterCell_event
      ...CategoryActualOfBudgetedFooterCell_event
      ...CategoryPaidAmountFooterCell_event
      ...CategoryPaidOfActualFooterCell_event
    }

    fragment CategoriesTable_org on Org {
      ...CategoryNameCell_org
      ...CategoryBudgetedAmountCell_org
      ...CategoryActualAmountCell_org
      ...CategoryPaidAmountCell_org
      ...CategoryBudgetedAmountFooterCell_org
      ...CategoryActualAmountFooterCell_org
      ...CategoryPaidAmountFooterCell_org

      budgetCategories {
        totalCount
      }
    }
  `,
);
