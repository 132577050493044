/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import FeatureAccessContext from 'contexts/FeatureAccess';

import { type BriefTemplateMutationVariables } from 'graph/mutations/briefTemplate/updateBriefTemplate';

import DraggableSection from './DraggableSection';
import DummySection from './DummySection';
import HeaderSection from './HeaderSection';
import Section from './Section';

import type { Sections_org } from './__generated__/Sections_org.graphql';

const Label = styled.div`
  font-weight: 500;
  margin: 35px 0;
`;

export type SectionType = {
  +id?: ?string,
  +name?: ?string,
  +order: number,
  +enabled: boolean,
};

class Sections extends React.PureComponent<
  {
    org: Sections_org,
    onTemplateAttributeChange: (input: BriefTemplateMutationVariables) => void,
  },
  {
    sectionsOrderOverride: ?$ReadOnlyArray<SectionType>,
    expandedSections: $ReadOnlyArray<string>,
  },
> {
  state = {
    sectionsOrderOverride: null,
    expandedSections: [],
  };

  getSortedSections = (): $ReadOnlyArray<SectionType> =>
    sortBy(this.state.sectionsOrderOverride || this.props.org.briefTemplate.sections, 'order');

  handleMoveSection = (sourceOrder: number, targetOrder: number) => {
    const sortedSections = this.getSortedSections();
    const sourceIndex = sortedSections.findIndex(section => section.order === sourceOrder);
    const targetIndex = sortedSections.findIndex(section => section.order === targetOrder);
    const direction = sourceIndex > targetIndex ? 0 : 1;
    const source = sortedSections[sourceIndex];

    const splitIndex = targetOrder + direction;
    const before = sortedSections
      .slice(0, splitIndex)
      .filter(section => section.order !== sourceOrder);
    const after = sortedSections.slice(splitIndex).filter(section => section.order !== sourceOrder);

    this.setState({
      sectionsOrderOverride: [...before, source, ...after].map((section, index) => ({
        ...section,
        order: index,
      })),
    });
  };

  handleMoveStart = () => {
    setTimeout(() => this.setState({ expandedSections: [] }), 50);
  };

  handleMoveEnd = () => {
    if (this.state.sectionsOrderOverride) {
      this.props.onTemplateAttributeChange({
        sections: this.state.sectionsOrderOverride.map(section => ({ ...section })),
      });
    }
    this.setState({ sectionsOrderOverride: null });
  };

  handleToggleSectionExpanded = (sectionName: string, expanded: boolean) => {
    this.setState(prevState => ({
      expandedSections: expanded
        ? [...prevState.expandedSections, sectionName]
        : prevState.expandedSections.filter(section => section !== sectionName),
    }));
  };

  handleToggleSectionEnabled = (sectionName: string, enabled: boolean) => {
    this.props.onTemplateAttributeChange({
      sections: this.props.org.briefTemplate.sections.map(section =>
        section.name === sectionName ? { ...section, enabled } : { ...section },
      ),
    });
  };

  static contextType = FeatureAccessContext;

  render() {
    return (
      <>
        <Label>Select contents and rearrange layout</Label>
        <HeaderSection
          template={this.props.org.briefTemplate}
          onTemplateAttributeChange={this.props.onTemplateAttributeChange}
        />
        {this.getSortedSections().map(section => (
          <DraggableSection
            section={section}
            draggableGroup="briefSections"
            onMoveStart={this.handleMoveStart}
            onMove={this.handleMoveSection}
            onMoveEnd={this.handleMoveEnd}
            key={section.name}
          >
            <Section
              section={section}
              org={this.props.org}
              expanded={this.state.expandedSections.includes(section.name)}
              onToggleEnabled={this.handleToggleSectionEnabled}
              onToggleExpanded={this.handleToggleSectionExpanded}
              onTemplateAttributeChange={this.props.onTemplateAttributeChange}
            />
          </DraggableSection>
        ))}
        <DummySection title="Notes" message="Add Notes to each Event individually" />
        {this.context.legacyFeatures && (
          <DummySection
            title="Contacts"
            message="Add Contacts, Companies or Vendors to each Event individually"
          />
        )}
        <DummySection title="Attachments" message="Add Attachments to each Event individually" />
      </>
    );
  }
}

export default createFragmentContainer(
  Sections,
  graphql`
    fragment Sections_org on Org {
      briefTemplate {
        sections {
          name
          order
          enabled
        }
        ...HeaderSection_template
      }
      ...Section_org
    }
  `,
);
