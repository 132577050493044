/* @flow */

import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type CreateEventEmailTemplateInput } from './__generated__/createEventEmailTemplateMutation.graphql';

const mutation = graphql`
  mutation createEventEmailTemplateMutation($input: CreateEventEmailTemplateInput!) {
    createEventEmailTemplate(input: $input) {
      eventEmailTemplatesEdge {
        __typename
        node {
          id
          createdAt
          interval
          intervalUnit
          baseDate
          direction
          emailSubject
          emailTemplate
          attachments {
            edges {
              node {
                id
                fileurl
                filename
                filetype
                filesize
                ...AttachmentPreview_attachments
              }
            }
          }
          ...ScheduledEmailFilters_eventEmailTemplate
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function createEventEmailTemplate(input: CreateEventEmailTemplateInput) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    updater: addRecordToConnection({
      parentId: input.formId,
      edgeName: 'eventEmailTemplatesEdge',
      connections: [{ field: 'eventEmailTemplates' }],
    }),
  });
}
