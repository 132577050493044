/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import ContactRegistrationDate_event from './__generated__/ContactRegistrationDate_event.graphql';
import ContactRegistrationDate_eventContact from './__generated__/ContactRegistrationDate_eventContact.graphql';

type Props = {
  eventContact: ContactRegistrationDate_eventContact,
  event: ContactRegistrationDate_event,
};

const ContactRegistrationDate = ({ eventContact, event }: Props) => {
  if (!eventContact.registrationDate) return null;
  return <DateTimeTooltip date={moment.tz(eventContact.registrationDate, event.tz)} />;
};

export default createFragmentContainer(
  ContactRegistrationDate,
  graphql`
    fragment ContactRegistrationDate_eventContact on EventContactEdge {
      registrationDate
    }

    fragment ContactRegistrationDate_event on Event {
      tz
    }
  `,
);
