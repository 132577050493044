/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type UsersGroup_users$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomizableUserMultiselect_customizable$ref: FragmentReference;
export type CustomizableUserMultiselect_customizable = {|
  +id: string,
  +__typename: string,
  +customUserMultiselectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +user: {|
      +id: string,
      +firstName: string,
      +lastName: string,
      +email: string,
      +avatar: ?string,
      +$fragmentRefs: UsersGroup_users$ref,
    |},
  |}>,
  +$refType: CustomizableUserMultiselect_customizable$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CustomizableUserMultiselect_customizable",
  "type": "CustomizableInterface",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customUserMultiselectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomUserMultiselectFieldType",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customField",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "plural": false,
          "selections": [
            v0
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "avatar",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "FragmentSpread",
              "name": "UsersGroup_users",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '10eef6094d97fcc9abf555505217effc';
module.exports = node;
