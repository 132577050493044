/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import budgetCategoryColors from 'config/budgetCategoryColors';

import type { TeamItem_team } from './__generated__/TeamItem_team.graphql';

const teamContainerCss = css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 91px;
  margin-right: 30px;
  margin-bottom: 20px;
  padding: 12px 25px;
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 4px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const ViewContainer = styled.div`
  ${teamContainerCss}
`;

const Container = styled(Link)`
  ${teamContainerCss}
  cursor: pointer;
  &:hover {
    background-color: #f2fbff;
    border-color: #c7eafa;
  }
`;

const ColoredBorder = styled.div`
  position: absolute;
  top: 5px;
  left: 4px;
  width: 4px;
  height: 79px;
  border-radius: 4px;
  background-color: ${props => props.color};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
`;

const Name = styled.div`
  margin-bottom: 6px;
  font-weight: 500;
  color: #4a5665;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Detail = styled.div`
  display: flex;
  margin-top: 10px;
  color: #4a5665;
  font-size: 13px;
  line-height: 1em;
  font-weight: 500;
`;

const Count = styled.div`
  margin-left: 4px;
  font-weight: normal;
`;

type Props = { team: TeamItem_team, indexInList: number };

const TeamItem = (props: Props) => {
  const { team, indexInList } = props;
  const ProperContainer = team.viewerCanUpdate ? Container : ViewContainer;
  return (
    <ProperContainer to={`/workspace/teams/${team.slug}/members`}>
      <ColoredBorder color={budgetCategoryColors[indexInList % 20]} />
      <Header>
        <Name>{team.name}</Name>
      </Header>
      <Detail>
        Events: <Count>{team.events ? team.events.totalCount : 0}</Count>
      </Detail>
      <Detail>
        Members: <Count>{team.members ? team.members.totalCount : 0}</Count>
      </Detail>
    </ProperContainer>
  );
};

export default createFragmentContainer(TeamItem, {
  team: graphql`
    fragment TeamItem_team on Team {
      id
      name
      slug
      viewerCanUpdate
      events {
        totalCount
      }
      members {
        totalCount
      }
    }
  `,
});
