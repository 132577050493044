/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Match, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import publicRuleNames from 'config/publicRuleNames';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';

import AllEventsCalendarEventWindowContent from './AllEventsCalendarEventWindowContent';

import type { AllEventsCalendarEventWindowPublicQueryResponse } from './__generated__/AllEventsCalendarEventWindowPublicQuery.graphql';

const query = graphql`
  query AllEventsCalendarEventWindowPublicQuery(
    $eventSlug: String!
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
  ) {
    publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
    eventList(resourceToken: $resourceToken) {
      savedListColumns {
        edges {
          node {
            columnName
          }
        }
      }
    }
    event(slug: $eventSlug) {
      ...AllEventsCalendarEventWindowContent_event
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

class AllEventsCalendarEventWindowPublicQuery extends React.PureComponent<{
  eventSlug: string,
  linkEventNameToBrief?: boolean,
  onHide: () => void,
  match: Match,
}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        public
        variables={{
          eventSlug: this.props.eventSlug,
          publicRuleName: publicRuleNames.PUBLIC_EVENT_LIST,
          resourceToken: this.props.match.params.resourceToken || '',
          orgSlug: this.props.match.params.orgName,
        }}
        renderSuccess={(response: AllEventsCalendarEventWindowPublicQueryResponse) => {
          if (!response.event) {
            return null;
          }

          return (
            <AllEventsCalendarEventWindowContent
              slug={this.props.eventSlug}
              event={response.event}
              onHide={this.props.onHide}
              linkEventNameToBrief={this.props.linkEventNameToBrief}
              resourceToken={this.props.match.params.resourceToken}
              shownColumns={
                response.eventList
                  ? response.eventList.savedListColumns.edges.map(({ node }) => node.columnName)
                  : []
              }
            />
          );
        }}
        renderLoading={() => (
          <LoaderContainer>
            <Loader size={30} />
          </LoaderContainer>
        )}
      />
    );
  }
}

export default withRouter(AllEventsCalendarEventWindowPublicQuery);
