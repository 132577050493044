/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventRequestFormsTableRow_requestForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestFormsTable_org$ref: FragmentReference;
export type EventRequestFormsTable_org = {|
  +id: string,
  +eventRequestForms: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: EventRequestFormsTableRow_requestForm$ref,
      |}
    |}>,
    +pageInfo: {|
      +hasNextPage: boolean
    |},
  |},
  +$refType: EventRequestFormsTable_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventRequestFormsTable_org",
  "type": "Org",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "eventRequestForms"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "cursor",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "sort",
      "type": "EventRequestFormSort"
    },
    {
      "kind": "RootArgument",
      "name": "direction",
      "type": "Direction"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": "eventRequestForms",
      "name": "__EventRequestFormsTable_eventRequestForms_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestFormRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "EventRequestFormRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "EventRequestForm",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "FragmentSpread",
                  "name": "EventRequestFormsTableRow_requestForm",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '089bcb5325526e610a41563a20e82e2e';
module.exports = node;
