/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import eventStates, { type EventStateGraphValue } from 'config/eventStates';

import Overlay from 'components/material/Overlay';
import SearchableStringList from 'components/material/SearchableStringList';

const StyledOverlay = styled(Overlay)`
  width: 120px;
  max-height: 300px;
  overflow-y: auto;
`;

type Option = {
  label: string,
  value: EventStateGraphValue,
};

export default class EventStatusSelectorOverlay extends React.PureComponent<{
  show: boolean,
  target: ?HTMLElement,
  container: ?HTMLElement,
  onHide: () => void,
  status: EventStateGraphValue,
  onChange: EventStateGraphValue => void,
}> {
  handleSelect: EventStateGraphValue => void = value => {
    this.props.onHide();
    this.props.onChange(value);
  };

  render() {
    const options: $ReadOnlyArray<Option> = eventStates.map(state => ({
      label: state.label,
      value: state.graphValue,
    }));

    return (
      <StyledOverlay
        show={this.props.show}
        target={this.props.target}
        onHide={this.props.onHide}
        container={this.props.container}
      >
        <SearchableStringList
          value={this.props.status}
          options={options}
          onSelect={this.handleSelect}
        />
      </StyledOverlay>
    );
  }
}
