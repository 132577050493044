/* @flow */
import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';

import AutocompleteInput from 'components/material/AutocompleteInput';
import SelectField from 'components/material/SelectField';

import getRelayEnvironment from 'graph/utils/getRelayEnvironment';

import type { DependencyInputQueryResponse } from './__generated__/DependencyInputQuery.graphql';

import DependencyInputOption from './DependencyInputOption';

const Container = styled.div`
  margin: 0 15px 10px 15px;
`;

const Row = styled.div`
  margin-top: -4px;
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(SelectField)`
  width: 100px;
  margin-right: 10px;
`;

const StyledAutocomplete = styled(AutocompleteInput)`
  flex: 1 1 auto;
`;

type Task = {|
  +id: string,
  +name: string,
  +$fragmentRefs: any,
|};

export default class DependencyInput extends React.PureComponent<
  {
    excludeTasks: Array<string>,
    eventSlug: string,
    timezone: string,
    onSelect: (type: 'dependent' | 'dependency', taskId: string) => void,
    onCancel: () => void,
  },
  { type: 'dependent' | 'dependency' },
> {
  state = { type: 'dependency' };

  handleFilterOptions = (tasks: Array<Task>, query: string) => {
    if (!query.trim()) return [];

    const excludedTasks = tasks.filter(task => !this.props.excludeTasks.includes(task.id));
    const startsWithRegex = new RegExp(`^${query.replace(/\s/g, '')}`, 'i');
    const includesRegex = new RegExp(`${query.replace(/\s/g, '')}`, 'i');

    return uniqBy(
      [
        ...excludedTasks.filter(task => startsWithRegex.test(task.name.replace(/\s/g, ''))),
        ...excludedTasks.filter(task => includesRegex.test(task.name.replace(/\s/g, ''))),
      ],
      t => t.id,
    ).slice(0, 3);
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<>) => {
    if (e.key === 'Escape') this.props.onCancel();
  };

  handleTypeChange = (type: ?'dependent' | 'dependency') => {
    if (type) {
      this.setState({ type });
    }
  };

  handleSelect = (task: ?Task) => {
    if (task) {
      this.props.onSelect(this.state.type, task.id);
    }
  };

  renderOption = (task: Task) => {
    return <DependencyInputOption task={task} timezone={this.props.timezone} />;
  };

  renderAutocomplete = (event?: $PropertyType<DependencyInputQueryResponse, 'event'>) => {
    const tasks: Array<Task> = event ? event.deliverables.edges.map(edge => edge.node).slice() : [];

    return (
      <Row>
        <StyledSelect
          options={[
            { label: 'blocked by', value: 'dependency' },
            { label: 'blocks', value: 'dependent' },
          ]}
          value={this.state.type}
          onChange={this.handleTypeChange}
        />
        <StyledAutocomplete
          onFilterOptions={query => this.handleFilterOptions(tasks, query)}
          renderOption={this.renderOption}
          onSelect={this.handleSelect}
          onKeyDown={this.handleKeyDown}
          autoFocus
          placeholder="Search tasks from this event"
          emptyText="No tasks found."
        />
      </Row>
    );
  };

  render() {
    return (
      <Container>
        <QueryRenderer
          environment={getRelayEnvironment()}
          query={graphql`
            query DependencyInputQuery($eventSlug: String!) {
              event(slug: $eventSlug) {
                deliverables {
                  edges {
                    node {
                      id
                      name
                      ...DependencyInputOption_task
                    }
                  }
                }
              }
            }
          `}
          variables={{ eventSlug: this.props.eventSlug }}
          render={response => {
            if (response.props) {
              const props: DependencyInputQueryResponse = response.props;

              if (props.event) {
                return this.renderAutocomplete(props.event);
              }
            }
            return this.renderAutocomplete();
          }}
        />
      </Container>
    );
  }
}
