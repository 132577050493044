import React from 'react';
import ClickOutside from 'react-onclickout';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Name from './form/Name';
import Time from './form/Time';

export default class ScheduleAdd extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    hidden: PropTypes.bool,
    onShow: PropTypes.func,
    index: PropTypes.number.isRequired,
    tz: PropTypes.string.isRequired,
    onCreate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    defaultStart: PropTypes.string,
    date: PropTypes.string.isRequired,
  };

  state = {
    start_time: this.props.defaultStart ? this.props.defaultStart : null,
    end_time: null,
    title: '',
    all_day: false,
  };

  componentDidUpdate(prevProps) {
    if (
      (!this.props.active && prevProps.active) ||
      this.props.defaultStart !== prevProps.defaultStart
    ) {
      this.saving = false;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        start_time: this.props.defaultStart ? this.props.defaultStart : null,
        end_time: null,
        title: '',
        all_day: false,
      });
    }
  }

  handleShowClick = () => {
    this.props.onShow(this.props.index);
  };

  handleNameChange = title => {
    this.setState({ title }, this.checkIfReadyToSave);
  };

  handleTimeChange = ({ startTime, endTime, allDay }) => {
    this.setState({ start_time: startTime, end_time: endTime, all_day: allDay }, () => {
      this.checkIfReadyToSave();

      if (this.nameComponent) {
        this.nameComponent.focus();
      }
    });
  };

  handleClickOutside = () => {
    const { start_time, end_time, title, all_day } = this.state;

    if (start_time && !this.saving) {
      this.saving = true;
      this.props.onCreate({
        startTime: start_time,
        endTime: end_time,
        title,
        allDay: all_day,
      });
    } else {
      this.props.onCancel();
    }
  };

  checkIfReadyToSave = () => {
    const { start_time, end_time, title, all_day } = this.state;

    if (start_time && title && !this.saving) {
      this.saving = true;
      this.props.onCreate({
        startTime: start_time,
        endTime: end_time,
        title,
        allDay: all_day,
      });
    }
  };

  render() {
    const { active, hidden, tz, date } = this.props;
    const { start_time, end_time, title, all_day } = this.state;

    if (!active) {
      return (
        <div className={classNames('schedule-list-add', { hidden })} onClick={this.handleShowClick}>
          <span className="schedule-list-add-icon">+</span>
          {hidden ? <hr /> : <a>ADD ITEM</a>}
        </div>
      );
    }
    return (
      <ClickOutside onClickOut={this.handleClickOutside}>
        <div
          onKeyDown={e => e.which === 27 && this.props.onCancel()}
          className="schedule-list-row adding"
        >
          <Time
            tz={tz}
            startTime={start_time}
            endTime={end_time}
            allDay={all_day}
            onChange={this.handleTimeChange}
            defaultEditing
            date={date}
          />
          <div className="schedule-list-row-main adding">
            <div className="schedule-list-row-body">
              <Name
                ref={nameComponent => {
                  this.nameComponent = nameComponent;
                }}
                value={title}
                defaultEditing
                autoFocus={!!title}
                onChange={this.handleNameChange}
              />
            </div>
          </div>
        </div>
      </ClickOutside>
    );
  }
}
