import React from 'react';

import { Panel, PanelFooter } from './Panel';
import { Button } from 'components/form/Button';
import { LinkField } from 'components/form/LinkField';

export class LinkPanel extends React.Component {
  render() {
    return (
      <Panel {...this.props} ref="panel">
        <div className="edit-name-panel">
          <form>
            <LinkField ref="field" label="Link" defaultValue={this.props.value} autoFocus placeholder="www.example.com" />
            <PanelFooter>
              {this.props.value && <a className="panel-remove-link" onClick={this.handleRemove.bind(this)}>Remove</a>}
              <div className="panel-buttons">
                <Button text="Cancel" minimal type="button" onClick={this.hide.bind(this)} />
                <Button text="Save" primary onClick={this.handleSubmit.bind(this)} />
              </div>
            </PanelFooter>
          </form>
        </div>
      </Panel>
    );
  }
  show() {
    this.refs.panel.show();
  }
  hide() {
    this.refs.panel.hide();
  }
  toggle() {
    this.refs.panel.toggle();
  }
  handleSubmit(e) {
    e.preventDefault();

    const isValid = this.refs.field.isValid();
    const value = this.refs.field.value();

    if (!isValid) return;

    if (value !== this.props.value) {
      this.props.onSave(value);
    }
    this.hide();
  }
  handleRemove() {
    this.props.onSave(null);
    this.hide();
  }
}
