/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import throttle from 'lodash/throttle';
import styled from 'styled-components';

import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';
import { type CreateCompanyFromWindow } from 'graph/mutations/company/createCompany';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import AutocompleteInput from 'components/material/AutocompleteInput';
import ContactWindow from 'components/Contacts/ContactWindow';
import { type EventPersonDataType } from 'components/Contacts/ContactWindow/ContactForm';
import { type ContactType } from 'components/Contacts/ContactWindow/ContactTypeSelector';

import ContactSearchRow from './ContactSearchRow';
import NewParticipantRow from './NewParticipantRow';
import type { Participant } from './index';

import type { ContactSearchQuery_response } from './__generated__/ContactSearchQuery.graphql';

const Container = styled.div`
  flex: 1 1 auto;
`;

const query = graphql`
  query OrgContactSearchQuery($filters: ContactFilters!) {
    org {
      id
      salesforceAccount {
        contactsSyncEnabled
      }
      contacts(first: 3, filters: $filters) {
        edges {
          node {
            id
            firstName
            lastName
            avatar
            email
            salesforceSyncAs
            ...ContactSearchRow_contact
          }
        }
      }
    }
  }
`;

export default class OrgContactSearch extends React.PureComponent<
  {
    onSelect: (contact: ?Participant, eventContactData?: EventPersonDataType) => void,
    stateful?: boolean,
    defaultValue?: string,
    label?: string,
    placeholder?: string,
    clearable?: boolean,
    onToggleQueryState?: (present: boolean) => void,
    includeEventDataInNewWindow?: boolean,
    fromWindow: CreateContactFromWindow | CreateCompanyFromWindow,
  },
  {
    query: string,
    addingContact: boolean,
  },
> {
  state = {
    query: this.props.stateful && this.props.defaultValue ? this.props.defaultValue : '',
    addingContact: false,
  };

  handleFilter = throttle((str: string) => {
    this.setState({ query: str });
    this.checkSearchQueryState(str);
  }, 800);

  checkSearchQueryState = (str: string) => {
    if (str.trim() && !this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(true);
      this.queryExists = true;
    }
    if (!str.trim() && this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(false);
      this.queryExists = false;
    }
  };

  handleWindowShow = () => {
    this.setState({ addingContact: true });
  };

  handleWindowClose = () => {
    this.setState({ addingContact: false });
  };

  handleSave = (
    contactType: ContactType,
    contact: Participant,
    eventContactData: EventPersonDataType,
  ) => {
    if (contactType === 'contacts') {
      this.props.onSelect(contact, eventContactData);
    }
  };

  handleBlur = (autocompleteQuery: string) => {
    if (this.props.stateful && !autocompleteQuery && this.props.defaultValue != null) {
      this.props.onSelect(null);
    }
  };

  cachedContacts: ?Array<Participant>;

  salesforceEnabled: boolean;

  queryExists: boolean;

  renderOption = (contact: Participant) => (
    <ContactSearchRow contact={contact} salesforceEnabled={this.salesforceEnabled} />
  );

  renderOptionToString = (contact: Participant) => {
    if (!this.props.stateful) return '';
    if (contact.firstName) {
      return [contact.firstName, contact.lastName].filter(Boolean).join(' ');
    }
    if (contact.email != null) {
      return contact.email;
    }
    return ''; // just for flow in real env it will not get to this point
  };

  renderCreate = () => <NewParticipantRow label="Add Contact" onClick={this.handleWindowShow} />;

  renderInput = (response?: ContactSearchQuery_response) => {
    if (response && response.org) {
      this.cachedContacts = response.org.contacts.edges.map(edge => edge.node);
      this.salesforceEnabled = response.org.salesforceAccount
        ? response.org.salesforceAccount.contactsSyncEnabled
        : false;
    }

    return (
      <Container>
        <AutocompleteInput
          defaultQuery={this.props.defaultValue}
          placeholder={this.props.placeholder || 'Search All Contacts'}
          label={this.props.label}
          options={this.cachedContacts || []}
          onFilterOptions={this.handleFilter}
          onSelect={this.props.onSelect}
          onBlur={this.handleBlur}
          renderOption={this.renderOption}
          renderOptionString={this.renderOptionToString}
          clearable={this.props.clearable}
          footerContent={this.renderCreate()}
        />
        {this.state.addingContact && (
          <ContactWindow
            onHide={this.handleWindowClose}
            onSave={this.handleSave}
            includeEventData={this.props.includeEventDataInNewWindow}
            fromWindow={this.props.fromWindow}
          />
        )}
      </Container>
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ filters: { query: this.state.query } }}
        renderSuccess={this.renderInput}
        renderLoading={this.renderInput}
      />
    );
  }
}
