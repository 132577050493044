/* @flow */
import React from 'react';
import styled from 'styled-components';

const FontAppliedLabel = styled.div`
  font-family: '${props => props.children.replace(/ /g, ' ')}';
`;

const fonts = [
  {
    label: 'Open Sans',
    value: 'Open+Sans',
    displayLabel: <FontAppliedLabel>Open Sans</FontAppliedLabel>,
  },
  {
    label: 'Roboto',
    value: 'Roboto',
    displayLabel: <FontAppliedLabel>Roboto</FontAppliedLabel>,
  },
  {
    label: 'Slabo 27px',
    value: 'Slabo+27px',
    displayLabel: <FontAppliedLabel>Slabo 27px</FontAppliedLabel>,
  },
  {
    label: 'Lato',
    value: 'Lato',
    displayLabel: <FontAppliedLabel>Lato</FontAppliedLabel>,
  },
  {
    label: 'Oswald',
    value: 'Oswald',
    displayLabel: <FontAppliedLabel>Oswald</FontAppliedLabel>,
  },
  {
    label: 'Roboto Condensed',
    value: 'Roboto+Condensed',
    displayLabel: <FontAppliedLabel>Roboto Condensed</FontAppliedLabel>,
  },
  {
    label: 'Raleway',
    value: 'Raleway',
    displayLabel: <FontAppliedLabel>Raleway</FontAppliedLabel>,
  },
  {
    label: 'IBM Plex Sans',
    value: 'IBM+Plex+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap',
    displayLabel: <FontAppliedLabel>IBM Plex Sans</FontAppliedLabel>,
  },
];

export default fonts;
