/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import uniqBy from 'lodash/uniqBy';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import InviteWindow, { type User } from 'components/InviteWindow';

import type { Participant } from './index';
import NewParticipantRow from './NewParticipantRow';
import UserSearchContent from './UserSearchContent';

import type { UserSearchQuery_response } from './__generated__/UserSearchQuery.graphql';

const query = graphql`
  query UserSearchQuery($eventId: ID!, $query: String!) {
    event: node(id: $eventId) {
      ... on Event {
        viewerCanAddStaff
        staffers(first: 3, query: $query) {
          edges {
            node {
              user {
                id
                firstName
                lastName
                avatar
                email
                ...UserSearchRow_user
                ...MaterialAvatar_user
              }
            }
          }
        }
        team {
          users(first: 3, query: $query) {
            edges {
              node {
                id
                firstName
                lastName
                avatar
                email
                ...UserSearchRow_user
                ...MaterialAvatar_user
              }
            }
          }
        }
      }
    }
  }
`;

export default class UserSearch extends React.PureComponent<
  {
    eventId: string,
    onSelect: (user: ?Participant) => void,
    label?: string,
    className?: string,
    fromWindow: 'custom event settings' | 'leader event settings',
    fillValueOnSelect?: boolean,
    defaultOption?: ?Participant,
    disabled?: boolean,
    clearable?: boolean,
    onToggleQueryState?: (present: boolean) => void,
  },
  { query: string, showInviteWindow: boolean },
> {
  state = { query: '', showInviteWindow: false };

  cachedUsers: ?Array<Participant>;

  queryExists: boolean;

  handleFilter = (str: string) => {
    this.setState({ query: str });
    this.checkSearchQueryState(str);
  };

  checkSearchQueryState = (str: string) => {
    if (str.trim() && !this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(true);
      this.queryExists = true;
    }
    if (!str.trim() && this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(false);
      this.queryExists = false;
    }
  };

  handleInvite = () => {
    this.setState({ showInviteWindow: true });
  };

  handleHideInviteWindow = (users?: ?$ReadOnlyArray<User>) => {
    if (users != null && users.length > 0) {
      if (this.props.fillValueOnSelect) {
        this.props.onSelect({ ...users[0], syncStatus: undefined, profileLink: undefined });
      } else {
        users.forEach(user => {
          this.props.onSelect({ ...user });
        });
      }
    }
    this.setState({ showInviteWindow: false });
  };

  renderInvite = (canAdd?: boolean) => {
    if (canAdd) {
      return <NewParticipantRow label="Add Event Staff" onClick={this.handleInvite} />;
    }
    return null;
  };

  renderInput = (response?: UserSearchQuery_response) => {
    const viewerCanAddStaff = response && response.event && response.event.viewerCanAddStaff;
    const footerContent = this.renderInvite(viewerCanAddStaff);
    this.cachedUsers =
      response && response.event
        ? uniqBy(
            [
              ...response.event.staffers.edges.map(edge => edge.node.user),
              ...response.event.team.users.edges.map(edge => edge.node),
            ],
            'id',
          )
        : this.cachedUsers;

    return (
      <UserSearchContent
        {...this.props}
        placeholder="Search Members"
        options={this.cachedUsers || []}
        onFilter={this.handleFilter}
        footerContent={footerContent}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <DefaultQueryRenderer
          query={query}
          variables={{ eventId: this.props.eventId, query: this.state.query }}
          renderSuccess={this.renderInput}
          renderLoading={this.renderInput}
        />
        {this.state.showInviteWindow && (
          <InviteWindow
            onHide={this.handleHideInviteWindow}
            eventId={this.props.eventId}
            fromWindow={this.props.fromWindow}
          />
        )}
      </React.Fragment>
    );
  }
}
