/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import Content from '../../layout';
import OrgCompaniesPage from './OrgCompaniesPage';

import type { OrgCompaniesQueryResponse } from './__generated__/OrgCompaniesQuery.graphql';

const query = graphql`
  query OrgCompaniesQuery {
    org {
      salesforceAccount {
        contactsSyncEnabled
      }
      ...OrgCompaniesPage_org
    }
    me {
      tz
    }
  }
`;

export default class OrgCompanies extends React.PureComponent<{
  location: Location,
  history: RouterHistory,
  match: Match,
}> {
  render() {
    const { location, history } = this.props;
    const pathPrefix = this.props.match.url;
    return (
      <Content>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(props: OrgCompaniesQueryResponse) => {
            const salesForceEnabled = props.org.salesforceAccount
              ? props.org.salesforceAccount.contactsSyncEnabled
              : false;
            return (
              <OrgCompaniesPage
                location={location}
                history={history}
                tz={props.me.tz}
                org={props.org}
                salesforceEnabled={salesForceEnabled}
                pathPrefix={pathPrefix}
              />
            );
          }}
        />
      </Content>
    );
  }
}
