/**
 * @flow
 * @relayHash 08a6e8a101a7b23206f17ee79d080d08
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SyncCampaignsInput = {
  clientMutationId?: ?string
};
export type syncCampaignsMutationVariables = {|
  input: SyncCampaignsInput
|};
export type syncCampaignsMutationResponse = {|
  +syncCampaigns: ?{|
    +clientMutationId: ?string
  |}
|};
export type syncCampaignsMutation = {|
  variables: syncCampaignsMutationVariables,
  response: syncCampaignsMutationResponse,
|};
*/


/*
mutation syncCampaignsMutation(
  $input: SyncCampaignsInput!
) {
  syncCampaigns(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SyncCampaignsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "syncCampaigns",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SyncCampaignsInput!"
      }
    ],
    "concreteType": "SyncCampaignsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "syncCampaignsMutation",
  "id": null,
  "text": "mutation syncCampaignsMutation(\n  $input: SyncCampaignsInput!\n) {\n  syncCampaigns(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "syncCampaignsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "syncCampaignsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d5a97d11d1951eb3e0f3f56b2270428';
module.exports = node;
