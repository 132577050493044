import './styles.styl';

import PropTypes from 'prop-types';

import React from 'react';
import { stringify } from 'qs';
import { withRouter } from 'react-router';
import isEqual from 'lodash/isEqual';

import { FiltersItem } from './FiltersItem';
import { FiltersDateItem } from './FiltersDateItem';

import { saveFilters, getSavedFilters, normalizeFilters } from 'utils/filters';

class Filters extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    onFilter: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    queryParams: PropTypes.object,
    name: PropTypes.string,
    defaultFilters: PropTypes.object,
    title: PropTypes.string,
    noReplace: PropTypes.bool,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    if (this.props.name && !this.props.noReplace) {
      const queryParams = this.props.queryParams || {};
      const filters = normalizeFilters(
        Object.assign(
          {},
          getSavedFilters(this.props.name, this.props.defaultFilters, this.filtersShown),
          this.props.filters,
          queryParams,
        ),
      );

      this.props.history.replace({
        pathname: window.location.pathname,
        search: stringify(filters),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name && !prevProps.noReplace) {
      if (!isEqual(prevProps.filters, this.props.filters)) {
        saveFilters(this.props.name, this.props.filters);
      }

      if (this.props.name !== prevProps.name) {
        const filters = normalizeFilters(
          Object.assign(
            {},
            getSavedFilters(this.props.name, this.props.defaultFilters, this.filtersShown),
            this.props.filters,
          ),
        );

        this.props.history.replace({
          pathname: window.location.pathname,
          search: stringify(filters),
        });
      }
    }
  }

  render() {
    const filterItems = React.Children.toArray(this.props.children).filter(Boolean);

    this.filtersShown = filterItems.map(i => i.props.name);

    return (
      <div className="filters">
        <div className="filters-title">{this.props.title || 'Filter by'}</div>

        {filterItems.map(FiltersItemInstance => {
          return React.cloneElement(FiltersItemInstance, {
            onSelect: this.props.onFilter,
            onReset: this.props.onReset,
            filters: this.props.filters,
            key: FiltersItemInstance.props.name,
          });
        })}
      </div>
    );
  }
}

const RoutedFilters = withRouter(Filters);
export { RoutedFilters as Filters, FiltersItem, FiltersDateItem };
