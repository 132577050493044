/* @flow */
import type { FieldType } from 'utils/customization/types';
import getCustomFieldQueryParamsByKind from 'utils/filters/getCustomFieldQueryParamsByKind';
import parseTypedQueryString, {
  type CustomFieldParam,
  type NumberRangeParam,
  type SearchParam,
  type SortParam,
  stringParamToNumberRange,
  stringParamToSearch,
  stringParamToSort,
  stringParamToStringArray,
} from 'utils/routing/parseTypedQueryString';

export type ParsedOpportunitiesFilters = {
  [key: string]: CustomFieldParam,

  sort: ?SortParam,
  name: ?$ReadOnlyArray<string>,
  associated_contacts: ?$ReadOnlyArray<string>,
  associated_contacts_roles: ?$ReadOnlyArray<string>,
  amount: ?NumberRangeParam,
  status: ?$ReadOnlyArray<string>,
  description: ?$ReadOnlyArray<string>,
  owner_id: ?$ReadOnlyArray<string>,
  salesforce_id: ?$ReadOnlyArray<string>,
  search: ?SearchParam,
};

export default function parseOpportunitiesFilters(
  queryString: string,
  filterOptions: $ReadOnlyArray<FieldType>,
): ParsedOpportunitiesFilters {
  return parseTypedQueryString(queryString, {
    ...filterOptions.reduce(
      (customFilters, filter) =>
        filter.kind !== 'DEFAULT'
          ? { ...customFilters, ...getCustomFieldQueryParamsByKind(filter) }
          : customFilters,
      {},
    ),

    sort: stringParamToSort,
    name: stringParamToStringArray,
    associated_contacts: stringParamToStringArray,
    associated_contacts_roles: stringParamToStringArray,
    amount: stringParamToNumberRange,
    status: stringParamToStringArray,
    description: stringParamToStringArray,
    salesforce_id: stringParamToStringArray,
    owner_id: stringParamToStringArray,
    search: stringParamToSearch,
  });
}
