/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import ScheduleDay from 'views/Main/Event/Schedules/ScheduleItem/ScheduleDay'; // eslint-disable-line

import type {
  createScheduleDayMutationResponse,
  createScheduleDayMutationVariables,
} from './__generated__/createScheduleDayMutation.graphql';

const mutation = graphql`
  mutation createScheduleDayMutation($input: AddDayToScheduleInput!) {
    addDayToSchedule(input: $input) {
      scheduleDayEdge {
        __typename
        node {
          id
          date
          title
          ...ScheduleDay_scheduleDay
        }
      }
    }
  }
`;

export default function createScheduleDay(
  scheduleId: string,
  args: {| date: string |},
): Promise<{|
  +id: string,
  +date: any,
  +title: ?string,
  +$fragmentRefs: any,
|}> {
  const variables: createScheduleDayMutationVariables = {
    input: {
      scheduleId,
      ...args,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: scheduleId,
      edgeName: 'scheduleDayEdge',
      connections: [{ field: 'scheduleDays' }],
    }),
  }).then((response: createScheduleDayMutationResponse) => {
    if (response.addDayToSchedule) {
      return response.addDayToSchedule.scheduleDayEdge.node;
    }
    return Promise.reject(Error('Missing response'));
  });
}
