/**
 * @flow
 * @relayHash 38b7efc5ec7b331cd0d43a3df8d09473
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventVendorsPage_event$ref = any;
type EventVendorsPage_org$ref = any;
export type VendorsQueryVariables = {|
  eventSlug: string
|};
export type VendorsQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: EventVendorsPage_event$ref
  |},
  +org: {|
    +$fragmentRefs: EventVendorsPage_org$ref
  |},
|};
export type VendorsQuery = {|
  variables: VendorsQueryVariables,
  response: VendorsQueryResponse,
|};
*/


/*
query VendorsQuery(
  $eventSlug: String!
) {
  event(slug: $eventSlug) {
    ...EventVendorsPage_event
    id
  }
  org {
    ...EventVendorsPage_org
    id
  }
}

fragment EventVendorsPage_event on Event {
  id
  ...EventVendorsHeaderBar_event
}

fragment EventVendorsPage_org on Org {
  id
  customFields(customizableType: [VENDOR]) {
    edges {
      node {
        id
        label
        fieldName
        order
        kind
        required
        options {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
  ...EventVendorsTablePagination_org
}

fragment EventVendorsTablePagination_org on Org {
  ...CustomizableCurrency_org
}

fragment CustomizableCurrency_org on Org {
  settings {
    currency
    id
  }
}

fragment EventVendorsHeaderBar_event on Event {
  id
  dbId
  name
  slug
  viewerCanCreateVendors
  team {
    dbId
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "VendorsQuery",
  "id": null,
  "text": "query VendorsQuery(\n  $eventSlug: String!\n) {\n  event(slug: $eventSlug) {\n    ...EventVendorsPage_event\n    id\n  }\n  org {\n    ...EventVendorsPage_org\n    id\n  }\n}\n\nfragment EventVendorsPage_event on Event {\n  id\n  ...EventVendorsHeaderBar_event\n}\n\nfragment EventVendorsPage_org on Org {\n  id\n  customFields(customizableType: [VENDOR]) {\n    edges {\n      node {\n        id\n        label\n        fieldName\n        order\n        kind\n        required\n        options {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n  ...EventVendorsTablePagination_org\n}\n\nfragment EventVendorsTablePagination_org on Org {\n  ...CustomizableCurrency_org\n}\n\nfragment CustomizableCurrency_org on Org {\n  settings {\n    currency\n    id\n  }\n}\n\nfragment EventVendorsHeaderBar_event on Event {\n  id\n  dbId\n  name\n  slug\n  viewerCanCreateVendors\n  team {\n    dbId\n    name\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "VendorsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EventVendorsPage_event",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EventVendorsPage_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VendorsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanCreateVendors",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v3,
              v4,
              v2
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"VENDOR\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "customizableType",
                "value": [
                  "VENDOR"
                ],
                "type": "[CustomizableType!]!"
              }
            ],
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fieldName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "kind",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "required",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "options",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionTypeRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldOptionTypeRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CustomFieldOptionType",
                                "plural": false,
                                "selections": [
                                  v2,
                                  v4
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currency",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0714f3da47ceba85ee629a90a97b47be';
module.exports = node;
