import './styles.styl';
import React from 'react';

import PropTypes from 'prop-types';

export default class HintLink extends React.Component {
  static propTypes = {
    to: PropTypes.string,
  };

  render() {
    return (
      <div className="hint-container">
        <a className="hint-help" href={this.props.to} target="_blank" rel="noopener noreferrer">
          <i className="fa fa-question-circle" />
        </a>
      </div>
    );
  }
}
