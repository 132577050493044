/* @flow */
import * as React from 'react';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import xor from 'lodash/xor';

import type { FieldType } from 'utils/customization/types';
import getCustomFieldValue from 'utils/filters/getCustomFieldValue';
import { type CustomFieldParam, stringParamToDateRange } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import stringifyQueryParamValues, {
  type ExtractParamValueType,
} from 'utils/routing/stringifyQueryParamValues';

import stringParamToFiscalDateRange from 'components/AllEvents/lib/stringParamToFiscalDateRange';
import Button, { MinimalButton } from 'components/budget/Button';
import type { FilterGroup } from 'components/material/ColumnFilters/columnFilterTypes';

import { FilterContext } from './AdvancedFilterWrapper';
import FilterItems, { type FilterChangeType, type FilterValueChangeParam } from './FilterItems';
import FiltersHeader from './FiltersHeader';

export type ConverterFnType = (
  $ReadOnlyArray<FieldType>,
) => $ObjMap<{ [string]: mixed }, ExtractParamValueType>;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  margin: 0 20px 20px;
`;

const StyledMinimalButton = styled(MinimalButton)`
  margin-right: 40px;
`;

const StyledButton = styled(Button)`
  padding: 8px 25px;
`;

const dateRangeProps = [
  'createdAt',
  'updatedAt',
  'created_at',
  'updated_at',
  'registered_at',
  'sent_at',
  'startsAt',
  'lastActivityAt',
  'confirmedAt',
  'subscriptionExpiresAt',
  'date',
];

export default class FiltersContent<F: {}> extends React.Component<
  {
    history: RouterHistory,
    filters: F,
    filterOptions: $ReadOnlyArray<FieldType>,
    groups?: $ReadOnlyArray<FilterGroup>,
    converterFn: ConverterFnType,
    fiscalYearStart?: number,
    onFiltersChanged?: () => void,
    onEmptyFilterApply?: () => void,
    filterRenderer: (FieldType, F, FilterChangeType) => React.Node,
  },
  {
    changedFilters: {
      [string]: FilterValueChangeParam,
    },
    changedParsedFilters: {
      [string]: FilterValueChangeParam | CustomFieldParam,
    },
  },
> {
  state = { changedFilters: {}, changedParsedFilters: {} };

  converters = this.props.converterFn(this.props.filterOptions);

  getParsedFilterValue = (name: string, value: FilterValueChangeParam) => {
    const field = this.props.filterOptions.find(filterOption => filterOption.id === name);

    if (['date', 'dueDate', 'eventDate'].includes(name) && value) {
      return stringParamToFiscalDateRange(value, this.props.fiscalYearStart);
    }

    if (typeof value === 'string' && new RegExp(`${dateRangeProps.join('|')}`, 'i').test(name)) {
      return stringParamToDateRange(value);
    }

    if (value && value.max === null && value.min === null) {
      return null;
    }

    if (!field || value == null || field.kind === 'DEFAULT') {
      return Array.isArray(value) && value.length === 0 ? null : value;
    }

    return getCustomFieldValue(field, value, '');
  };

  filterHasChanged = (name: string, value: FilterValueChangeParam) => {
    const parsedValue = this.getParsedFilterValue(name, value);
    if (Array.isArray(parsedValue)) {
      return xor([...parsedValue], this.props.filters[name]).length > 0;
    }

    if (parsedValue != null) {
      if (parsedValue.stringArrayValue != null && Array.isArray(parsedValue.stringArrayValue)) {
        return this.props.filters[name]
          ? xor([...parsedValue.stringArrayValue], this.props.filters[name].stringArrayValue)
              .length > 0
          : true;
      }

      if (parsedValue.enumArrayValue != null && Array.isArray(parsedValue.enumArrayValue)) {
        return this.props.filters[name]
          ? xor([...parsedValue.enumArrayValue], this.props.filters[name].enumArrayValue).length > 0
          : true;
      }

      if (parsedValue.numberRangeParam != null) {
        return this.props.filters[name]
          ? !isEqual(this.props.filters[name].numberRangeParam, parsedValue.numberRangeParam)
          : true;
      }

      if (parsedValue.dateRangeValue != null && parsedValue.dateRangeValue.key != null) {
        return this.props.filters[name]
          ? !isEqual(this.props.filters[name].dateRangeValue.key, parsedValue.dateRangeValue.key)
          : true;
      }
    }
    return !isEqual(this.props.filters[name], parsedValue);
  };

  handleFilterChange = (name: string, value: FilterValueChangeParam) => {
    const parsedValue = this.getParsedFilterValue(name, value);

    if (this.filterHasChanged((name: string), (value: FilterValueChangeParam))) {
      this.setState(prevState => ({
        changedFilters: {
          ...prevState.changedFilters,
          [name]: value,
        },
        changedParsedFilters: {
          ...prevState.changedParsedFilters,
          [name]: parsedValue,
        },
      }));
    } else {
      this.setState(prevState => ({
        changedFilters: omit(prevState.changedFilters, name),
        changedParsedFilters: omit(prevState.changedParsedFilters, name),
      }));
    }
  };

  handleResetFilters = (hideOverlay: () => void) => {
    const emptyFilters = Object.keys(this.converters).reduce(
      (y, x) => Object.assign(y, { [x]: null }),
      {},
    );

    this.setState({ changedFilters: {}, changedParsedFilters: {} });
    this.replaceParams(emptyFilters);
    hideOverlay();
  };

  replaceParams = (params: {}) => {
    const stringified = stringifyQueryParamValues(params, this.converters);
    replaceQueryParams(this.props.history, stringified);

    if (
      this.props.onEmptyFilterApply &&
      Object.values(params).every(val => val === null || val === '')
    ) {
      this.props.onEmptyFilterApply();
    }
    if (this.props.onFiltersChanged) {
      this.props.onFiltersChanged();
    }
  };

  handleSave = (hideOverlay: () => void) => {
    this.replaceParams(this.state.changedFilters);
    this.setState({ changedFilters: {}, changedParsedFilters: {} });
    hideOverlay();
  };

  render() {
    const { filters, filterOptions, groups, filterRenderer } = this.props;
    const combinedFilters = { ...filters, ...this.state.changedParsedFilters };

    return (
      <FilterContext.Consumer>
        {({ hideOverlay }) => (
          <>
            <FiltersHeader onResetFilters={() => this.handleResetFilters(hideOverlay)} />
            <FilterItems
              filterRenderer={filterRenderer}
              filters={combinedFilters}
              filterOptions={filterOptions}
              groups={groups}
              onFilterChange={this.handleFilterChange}
            />
            <ButtonContainer>
              <StyledMinimalButton label="Cancel" onClick={hideOverlay} />
              <StyledButton
                onClick={() => this.handleSave(hideOverlay)}
                disabled={Object.keys(this.state.changedFilters).length === 0}
              >
                Apply
              </StyledButton>
            </ButtonContainer>
          </>
        )}
      </FilterContext.Consumer>
    );
  }
}
