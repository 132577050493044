/**
 * @flow
 * @relayHash e890a92dd28ee96285a141b1fd5e0f43
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSalesforceOpportunityStatusInput = {
  clientMutationId?: ?string,
  id: string,
  enabled: boolean,
};
export type updateSalesforceOpportunityStatusMutationVariables = {|
  input: UpdateSalesforceOpportunityStatusInput
|};
export type updateSalesforceOpportunityStatusMutationResponse = {|
  +updateSalesforceOpportunityStatus: ?{|
    +salesforceOpportunityStatusEdge: {|
      +node: {|
        +id: string,
        +enabled: boolean,
      |}
    |}
  |}
|};
export type updateSalesforceOpportunityStatusMutation = {|
  variables: updateSalesforceOpportunityStatusMutationVariables,
  response: updateSalesforceOpportunityStatusMutationResponse,
|};
*/


/*
mutation updateSalesforceOpportunityStatusMutation(
  $input: UpdateSalesforceOpportunityStatusInput!
) {
  updateSalesforceOpportunityStatus(input: $input) {
    salesforceOpportunityStatusEdge {
      node {
        id
        enabled
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSalesforceOpportunityStatusInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateSalesforceOpportunityStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateSalesforceOpportunityStatusInput!"
      }
    ],
    "concreteType": "UpdateSalesforceOpportunityStatusPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "salesforceOpportunityStatusEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "SalesforceOpportunityStatusTypeRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceOpportunityStatusType",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "enabled",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateSalesforceOpportunityStatusMutation",
  "id": null,
  "text": "mutation updateSalesforceOpportunityStatusMutation(\n  $input: UpdateSalesforceOpportunityStatusInput!\n) {\n  updateSalesforceOpportunityStatus(input: $input) {\n    salesforceOpportunityStatusEdge {\n      node {\n        id\n        enabled\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateSalesforceOpportunityStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateSalesforceOpportunityStatusMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '63e5090835dcb7f3c71f57d30aa7207f';
module.exports = node;
