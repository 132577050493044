/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';

import syncStatuses from 'config/syncStatuses.json';
import ErrorIcon from 'components/ErrorIcon';

import type { SalesforceSyncStatus_event } from './__generated__/SalesforceSyncStatus_event.graphql';

class SalesforceSyncStatus extends React.PureComponent<{
  event: SalesforceSyncStatus_event,
}> {
  syncState() {
    const { pullStatus, pushStatus } = this.props.event;

    if (!pullStatus || !pushStatus) return 'DISABLED';

    if (pullStatus.state === 'PENDING' || pushStatus.state === 'PENDING') {
      return 'PENDING';
    }
    if (pullStatus.state === 'FAILING' || pushStatus.state === 'FAILING') {
      return 'FAILING';
    }
    return 'OK';
  }

  render() {
    const { pullStatus, pushStatus } = this.props.event;

    if (!pullStatus || !pushStatus) return 'Not Connected';

    const errorMessage = pullStatus.errorMessage || pushStatus.errorMessage;

    return (
      <div>
        {syncStatuses[this.syncState()]}
        {errorMessage && <ErrorIcon message={errorMessage} />}
      </div>
    );
  }
}

export default createFragmentContainer(
  SalesforceSyncStatus,
  graphql`
    fragment SalesforceSyncStatus_event on Event {
      pullStatus {
        state
        errorMessage
      }
      pushStatus {
        state
        errorMessage
      }
    }
  `,
);
