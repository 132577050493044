/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type {
  createScheduleMutationVariables,
  createScheduleMutationResponse,
} from './__generated__/createScheduleMutation.graphql';

const mutation = graphql`
  mutation createScheduleMutation($input: AddScheduleToEventInput!) {
    addScheduleToEvent(input: $input) {
      scheduleEdge {
        __typename
        node {
          id
          name
          order
          slug
          dbId
          token
          slugs {
            slug
          }
          ...SchedulesHeaderItem_schedule
        }
      }
    }
  }
`;

export default function createSchedule(
  eventId: string,
  args: {| name: string, order: number |},
): Promise<{|
  +id: string,
  +name: string,
  +order: number,
  +slug: string,
  +dbId: number,
  +token: string,
  +slugs: $ReadOnlyArray<{| +slug: string |}>,
  +$fragmentRefs: any,
|}> {
  const variables: createScheduleMutationVariables = {
    input: {
      eventId,
      ...args,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'scheduleEdge',
      connections: [{ field: 'schedules' }],
    }),
  }).then((response: createScheduleMutationResponse) => {
    if (response.addScheduleToEvent) {
      return response.addScheduleToEvent.scheduleEdge.node;
    }
    return Promise.reject(Error('Missing response'));
  });
}
