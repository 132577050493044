/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import type { EventVenueCity_event } from './__generated__/EventVenueCity_event.graphql';

const EventVenueCity = (props: { event: EventVenueCity_event }) => {
  const primaryLocation = props.event.primaryLocation;
  return primaryLocation ? primaryLocation.city : null;
};

export default createFragmentContainer(
  EventVenueCity,
  graphql`
    fragment EventVenueCity_event on Event {
      primaryLocation {
        city
      }
    }
  `,
);
