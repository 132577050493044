/**
 * @flow
 * @relayHash 0ecc8f3ee17b0a605d96e18b3d88ad3b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CategoriesTable_categoryEdges$ref = any;
export type RemoveExpenseInput = {
  clientMutationId?: ?string,
  expenseId: string,
};
export type removeExpenseMutationVariables = {|
  input: RemoveExpenseInput
|};
export type removeExpenseMutationResponse = {|
  +removeExpense: ?{|
    +removedExpenseId: string,
    +event: {|
      +totalBudgetedAmount: number,
      +totalActualAmount: number,
      +totalPaidAmount: number,
      +budgetCategories: {|
        +edges: $ReadOnlyArray<{|
          +$fragmentRefs: CategoriesTable_categoryEdges$ref
        |}>
      |},
      +expenses: {|
        +totalCount: number
      |},
      +payments: {|
        +totalCount: number
      |},
    |},
  |}
|};
export type removeExpenseMutation = {|
  variables: removeExpenseMutationVariables,
  response: removeExpenseMutationResponse,
|};
*/


/*
mutation removeExpenseMutation(
  $input: RemoveExpenseInput!
) {
  removeExpense(input: $input) {
    removedExpenseId
    event {
      totalBudgetedAmount
      totalActualAmount
      totalPaidAmount
      budgetCategories {
        edges {
          ...CategoriesTable_categoryEdges
        }
      }
      expenses {
        totalCount
      }
      payments {
        totalCount
      }
      id
    }
  }
}

fragment CategoriesTable_categoryEdges on EventBudgetCategoryEdge {
  budgetedAmount
  actualAmount
  paidAmount
  ...CategoryNameCell_categoryEdge
  ...CategoryBudgetedAmountCell_categoryEdge
  ...CategoryActualAmountCell_categoryEdge
  ...CategoryActualOfBudgetedCell_categoryEdge
  ...CategoryPaidAmountCell_categoryEdge
  ...CategoryPaidOfActualCell_categoryEdge
  ...CategoryActionCell_categoryEdge
  node {
    id
    name
  }
}

fragment CategoryNameCell_categoryEdge on EventBudgetCategoryEdge {
  budgetedAmount
  node {
    id
    name
  }
}

fragment CategoryBudgetedAmountCell_categoryEdge on EventBudgetCategoryEdge {
  budgetedAmount
}

fragment CategoryActualAmountCell_categoryEdge on EventBudgetCategoryEdge {
  actualAmount
}

fragment CategoryActualOfBudgetedCell_categoryEdge on EventBudgetCategoryEdge {
  budgetedAmount
  actualAmount
}

fragment CategoryPaidAmountCell_categoryEdge on EventBudgetCategoryEdge {
  paidAmount
}

fragment CategoryPaidOfActualCell_categoryEdge on EventBudgetCategoryEdge {
  actualAmount
  paidAmount
}

fragment CategoryActionCell_categoryEdge on EventBudgetCategoryEdge {
  node {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveExpenseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveExpenseInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedExpenseId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalBudgetedAmount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalActualAmount",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalPaidAmount",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "expenses",
  "storageKey": null,
  "args": null,
  "concreteType": "ExpenseRequiredConnection",
  "plural": false,
  "selections": v6
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "payments",
  "storageKey": null,
  "args": null,
  "concreteType": "PaymentRequiredConnection",
  "plural": false,
  "selections": v6
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeExpenseMutation",
  "id": null,
  "text": "mutation removeExpenseMutation(\n  $input: RemoveExpenseInput!\n) {\n  removeExpense(input: $input) {\n    removedExpenseId\n    event {\n      totalBudgetedAmount\n      totalActualAmount\n      totalPaidAmount\n      budgetCategories {\n        edges {\n          ...CategoriesTable_categoryEdges\n        }\n      }\n      expenses {\n        totalCount\n      }\n      payments {\n        totalCount\n      }\n      id\n    }\n  }\n}\n\nfragment CategoriesTable_categoryEdges on EventBudgetCategoryEdge {\n  budgetedAmount\n  actualAmount\n  paidAmount\n  ...CategoryNameCell_categoryEdge\n  ...CategoryBudgetedAmountCell_categoryEdge\n  ...CategoryActualAmountCell_categoryEdge\n  ...CategoryActualOfBudgetedCell_categoryEdge\n  ...CategoryPaidAmountCell_categoryEdge\n  ...CategoryPaidOfActualCell_categoryEdge\n  ...CategoryActionCell_categoryEdge\n  node {\n    id\n    name\n  }\n}\n\nfragment CategoryNameCell_categoryEdge on EventBudgetCategoryEdge {\n  budgetedAmount\n  node {\n    id\n    name\n  }\n}\n\nfragment CategoryBudgetedAmountCell_categoryEdge on EventBudgetCategoryEdge {\n  budgetedAmount\n}\n\nfragment CategoryActualAmountCell_categoryEdge on EventBudgetCategoryEdge {\n  actualAmount\n}\n\nfragment CategoryActualOfBudgetedCell_categoryEdge on EventBudgetCategoryEdge {\n  budgetedAmount\n  actualAmount\n}\n\nfragment CategoryPaidAmountCell_categoryEdge on EventBudgetCategoryEdge {\n  paidAmount\n}\n\nfragment CategoryPaidOfActualCell_categoryEdge on EventBudgetCategoryEdge {\n  actualAmount\n  paidAmount\n}\n\nfragment CategoryActionCell_categoryEdge on EventBudgetCategoryEdge {\n  node {\n    id\n    name\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeExpenseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeExpense",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveExpensePayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "budgetCategories",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBudgetCategoryConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventBudgetCategoryEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "CategoriesTable_categoryEdges",
                        "args": null
                      }
                    ]
                  }
                ]
              },
              v7,
              v8
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeExpenseMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeExpense",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveExpensePayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "budgetCategories",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBudgetCategoryConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventBudgetCategoryEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "budgetedAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actualAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "paidAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BudgetCategory",
                        "plural": false,
                        "selections": [
                          v9,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              v7,
              v8,
              v9
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c2d168e2c828fe88a5ecb7e3a11d55fc';
module.exports = node;
