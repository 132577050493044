/* @flow */

import * as React from 'react';
import styled from 'styled-components';

import { download } from 'utils/Attachments';

const AcionContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  display: none;
  justify-content: flex-end;
  > div {
    display: flex;
    flex: 0 auto;
    align-items: center;
    width: 24px;
    height: 24px;
    background: rgba(218, 213, 213, 0.4);
    border-radius: 2px;
    cursor: pointer;
    i {
      flex: 1 1 auto;
      color: #96a2ab;
    }
  }
`;

const ImagePreview = styled.div`
  margin-right: 5px;
`;

const ImageDownload = styled.div`
  margin-right: 5px;
`;

const Container = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 20px 30px 0;
  &:hover {
    ${AcionContainer} {
      display: flex;
    }
  }
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 auto;
  width: 100%;
  height: 100%;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

const Attachment = styled.img`
  max-width: 180px;
  max-height: 180px;
  cursor: pointer;
`;

export type AttachmentType = {
  +id: string,
  +filename: ?string,
  +fileurl: ?string,
  +filetype: ?string,
};

const BriefImage = (props: {
  attachment: AttachmentType,
  onPreview: (attachmentId: string) => void,
}) => {
  const {
    attachment: { id, fileurl, filename, filetype },
    onPreview,
  } = props;
  const urlParts = fileurl ? fileurl.split('/') : [];
  const thumbnailUrl =
    filetype === 'application/pdf'
      ? `https://process.filestackapi.com/output=format:jpg/resize=height:180,width:180/${
          urlParts[urlParts.length - 1]
        }`
      : fileurl;
  return (
    <Container>
      <AcionContainer>
        <ImageDownload onClick={() => download(fileurl, filename)}>
          <i className="fa fa-fw fa-download" />
        </ImageDownload>
        <ImagePreview onClick={() => onPreview(id)}>
          <i className="fa fa-fw fa-search" />
        </ImagePreview>
      </AcionContainer>
      <ThumbnailContainer>
        <Attachment src={thumbnailUrl} alt={filename || fileurl} onClick={() => onPreview(id)} />
      </ThumbnailContainer>
    </Container>
  );
};

export default BriefImage;
