/* @flow */

import isValidEmail from 'utils/validators/isValidEmail';

export type MarketoContactType = {
  +firstName: ?string,
  +lastName: ?string,
  +email: ?string,
};

const canSyncToMarketo = (
  contact: MarketoContactType,
): {
  syncEnabled: boolean,
  missingFields: $ReadOnlyArray<string>,
  invalidFields: $ReadOnlyArray<string>,
} => {
  const firstNameValid = contact.firstName && contact.firstName.trim();
  const lastNameValid = contact.lastName && contact.lastName.trim();
  const emailValid = contact.email && isValidEmail(contact.email);

  const syncEnabled = !!(firstNameValid && lastNameValid && emailValid);

  const missingFields = [
    firstNameValid ? '' : 'First Name',
    lastNameValid ? '' : 'Last Name',
    emailValid ? '' : 'Email',
  ].filter(Boolean);

  return { syncEnabled, missingFields, invalidFields: [] };
};

export default canSyncToMarketo;
