/* @flow */
import * as React from 'react';
import GoogleMapsScriptWrapper from 'components/GoogleMapsScriptWrapper';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import markerIcon from './markerIcon';
import SimpleInfoBox from './simpleInfoBox';

const mapOptions = {
  streetViewControl: false,
  scrollwheel: false,
  mapTypeControl: false,
  fullscreenControl: true,
};

const MapWithAMarker = withGoogleMap(props => {
  markerIcon.anchor = new window.google.maps.Point(12, 22);
  return (
    <GoogleMap
      options={mapOptions}
      defaultZoom={14}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
      {...props}
    >
      {props.children}
    </GoogleMap>
  );
});

export default class GoogleMaps extends React.Component<
  {
    className?: string,
    lat: number,
    lng: number,
    infoBoxOffset?: number,
    children?: React.Node,
  },
  {
    isInfoBoxOpened: boolean,
  },
> {
  state = { isInfoBoxOpened: false };

  handleInfoBoxToggle = (isInfoBoxOpened: boolean) => {
    this.setState({ isInfoBoxOpened });
  };

  render() {
    return (
      <GoogleMapsScriptWrapper>
        <MapWithAMarker
          onClick={() => this.handleInfoBoxToggle(false)}
          containerElement={<div className={this.props.className} />}
          mapElement={<div style={{ height: `100%` }} />}
          lat={this.props.lat}
          lng={this.props.lng}
        >
          <Marker
            icon={markerIcon}
            position={{ lat: this.props.lat, lng: this.props.lng }}
            onClick={() => this.handleInfoBoxToggle(true)}
          >
            {this.props.children != null &&
              this.state.isInfoBoxOpened && (
                <SimpleInfoBox infoBoxOffset={this.props.infoBoxOffset}>
                  {this.props.children}
                </SimpleInfoBox>
              )}
          </Marker>
        </MapWithAMarker>
      </GoogleMapsScriptWrapper>
    );
  }
}
