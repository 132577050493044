/* @flow */

import React from 'react';
import BoldText from './BoldText';

type Props = { firstName: ?string, lastName: ?string };

const BriefUserName = ({ firstName, lastName }: Props) => {
  return firstName || lastName ? (
    <BoldText>{`${firstName || ''} ${lastName || ''}`}</BoldText>
  ) : null;
};

export default BriefUserName;
