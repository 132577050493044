/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import omit from 'lodash/omit';

import { type PaymentStatus } from 'config/paymentStatuses';

import { upload } from 'utils/Attachments';
import { convertNativeFile } from 'utils/uploading/convertFileStackFile';
import type { CircaFile } from 'utils/uploading/types';

import addAttachmentToPayment from 'graph/mutations/payment/addAttachmentToPayment';
import type { PaymentType } from 'graph/mutations/payment/createPayment';
import createPayment from 'graph/mutations/payment/createPayment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { DropZone } from 'components/DropZone';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';

import CreatePaymentForm, { type DerivedPaymentType } from './CreatePaymentForm';

import CreatePaymentWindow_org from './__generated__/CreatePaymentWindow_org.graphql';

const emptyPayment = {
  paymentType: 'Full',
  amount: null,
  status: 'UNPAID',
  dueDate: '',
  actualDate: '',
  paymentMethod: '',
  poNumber: '',
  reference: '',
  note: '',
  attachments: [],
  viewerCanDelete: true,
};

const StyledDropZone = styled(DropZone)`
  .attachment-drop {
    padding-top: 45px;
    i {
      font-size: 40px !important;
    }
  }
`;

class CreatePaymentWindow extends React.Component<
  {
    org: CreatePaymentWindow_org,
    expenseId: string,
    expenseActualAmount: number,
    tz: string,
    onNewPaymentClose: () => void,
  },
  {
    payment: DerivedPaymentType,
    key: number,
  },
> {
  state = {
    payment: emptyPayment,
    key: 1,
  };

  handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const key = e.currentTarget.name;
    const value = e.currentTarget.value;
    this.setState(previousState => ({
      payment: {
        ...previousState.payment,
        [key]: value,
      },
    }));
  };

  handlePaymentTypeChange = (paymentType: string) => {
    this.setState(previousState => ({
      payment: {
        ...previousState.payment,
        paymentType,
      },
    }));
  };

  handlePaymentMethodChange = (paymentMethod: ?string) => {
    this.setState(previousState => ({
      payment: {
        ...previousState.payment,
        paymentMethod,
      },
    }));
  };

  handleAmountChange = (amount: ?number) => {
    this.setState(previousState => ({
      payment: {
        ...previousState.payment,
        amount,
      },
    }));
  };

  handleStatusChange = (status: PaymentStatus) => {
    this.setState(previousState => ({
      payment: {
        ...previousState.payment,
        status,
      },
    }));
  };

  handleDueDateChange = (dueDate: ?string) => {
    this.setState(previousState => ({
      payment: {
        ...previousState.payment,
        dueDate,
      },
    }));
  };

  handleActualDateChange = (actualDate: ?string) => {
    this.setState(previousState => ({
      payment: {
        ...previousState.payment,
        actualDate,
      },
    }));
  };

  onAddAttachment = (attachment: CircaFile) => {
    const temporaryId = String(Math.random());
    this.setState(previousState => ({
      payment: {
        ...previousState.payment,
        attachments: [...previousState.payment.attachments, { id: temporaryId, ...attachment }],
      },
    }));
  };

  handleFilesDrop = (files: Array<File>) => {
    files.forEach((nativeFile: File) => {
      const temporaryId = String(Math.random());
      const convertedFile = convertNativeFile(nativeFile);
      this.setState(previousState => ({
        payment: {
          ...previousState.payment,
          attachments: [
            ...previousState.payment.attachments,
            { id: temporaryId, ...convertedFile },
          ],
        },
      }));
      upload(nativeFile).then((file: CircaFile) => {
        this.setState(state => {
          const attachments = [...state.payment.attachments];
          const attachmentIndex = attachments.findIndex(
            attachment => attachment.id === temporaryId,
          );
          attachments.splice(attachmentIndex, 1, { id: temporaryId, ...file });
          return { payment: { ...state.payment, attachments } };
        });
      });
    });
  };

  onRemoveAttachment = (attachmentId: ?string) => {
    this.setState(previousState => ({
      payment: {
        ...previousState.payment,
        attachments: previousState.payment.attachments.filter(a => a.id !== attachmentId),
      },
    }));
  };

  handlePaymentSave = () => {
    const { amount, viewerCanDelete, ...paymentDetails } = this.state.payment;
    if (amount == null) return;
    const payment: PaymentType = {
      ...paymentDetails,
      amount,
      expenseId: this.props.expenseId,
    };
    createPayment(this.props.expenseId, payment)
      .then((paymentId: string) => {
        this.state.payment.attachments.forEach(attachment => {
          addAttachmentToPayment(paymentId, omit(attachment, ['id']));
        });
        this.setState(previousState => ({
          payment: emptyPayment,
          key: previousState.key + 1,
        }));
      })
      .catch(err => {
        showModernMutationError(err);
      });
  };

  render() {
    const { tz, expenseActualAmount, onNewPaymentClose, org } = this.props;

    const payment: DerivedPaymentType = this.state.payment;

    return (
      <Window onHide={onNewPaymentClose} size="medium">
        <StyledDropZone onDrop={this.handleFilesDrop}>
          <WindowHeader>
            <WindowTitle>Add Payment</WindowTitle>
            <WindowClose onClick={onNewPaymentClose} />
          </WindowHeader>
          <WindowContent>
            <CreatePaymentForm
              key={this.state.key}
              expenseActualAmount={expenseActualAmount}
              orgCurrency={org.settings.currency}
              payment={payment}
              tz={tz}
              onPaymentClose={onNewPaymentClose}
              onAmountChange={this.handleAmountChange}
              onPaymentTypeChange={this.handlePaymentTypeChange}
              onStatusChange={this.handleStatusChange}
              onDueDateChange={this.handleDueDateChange}
              onActualDateChange={this.handleActualDateChange}
              onPaymentSave={this.handlePaymentSave}
              onAddAttachment={this.onAddAttachment}
              onRemoveAttachment={this.onRemoveAttachment}
              onInputChange={this.handleInputChange}
              onPaymentMethodChange={this.handlePaymentMethodChange}
            />
          </WindowContent>
        </StyledDropZone>
      </Window>
    );
  }
}

export default createFragmentContainer(CreatePaymentWindow, {
  org: graphql`
    fragment CreatePaymentWindow_org on Org {
      id
      settings {
        id
        currency
      }
    }
  `,
});
