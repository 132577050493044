/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TAG_COLORS from 'config/tag_colors.json';
import taskStatuses from 'config/taskStatuses';

import type { FieldType } from 'utils/customization/types';
import renderFilterByKind from 'utils/filters/renderFilterByKind';

import CheckFilterItem from 'components/material/Filters/Advanced/CheckFilterItem';
import DateFilterItem from 'components/material/Filters/Advanced/DateFilterItem';
import EventFilterItem from 'components/material/Filters/Advanced/EventFilterItem';
import { type FilterValueChangeParam } from 'components/material/Filters/Advanced/FilterItems';
import UserFilterItem from 'components/material/Filters/Advanced/UserFilterItem';

import type { ParsedTaskFilters } from './parseTaskFilters';

const TagRow = styled.div`
  overflow: visible !important;
  padding: 0 10px;
  cursor: pointer;
  &:first-of-type {
    padding-top: 10px;
  }
  ${props => props.focused && `background: ${props.theme.hoverRowColor};`};
`;

const Tag = styled.div`
  color: ${props => props.theme.tagTextColor};
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  padding: 6px 12px;
  display: flex;
  background: ${props => TAG_COLORS[props.color]};
`;

const TagName = styled.div`
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function renderTaskFilter(
  customField: FieldType,
  filters: ParsedTaskFilters,
  tags: $ReadOnlyArray<{ +id: string, +color: number, +name: string }>,
  teams: $ReadOnlyArray<{ +id: string, +name: string }>,
  onFilterChange: (name: string, value: FilterValueChangeParam) => void,
): React.Node {
  switch (customField.fieldName) {
    case 'teamIds':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          maxOptions={15}
          options={teams.map(team => ({
            value: team.id,
            label: team.name,
          }))}
          onChange={onFilterChange}
          // fixing flow issue
          activeValues={customField.fieldName ? filters[customField.fieldName] : []}
        />
      );
    case 'eventIds':
      return (
        <EventFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
        />
      );
    case 'assigneeIds':
      return (
        <UserFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
        />
      );
    case 'statuses':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          activeValues={filters[customField.fieldName]}
          options={Object.keys(taskStatuses).map(key => ({ value: key, label: taskStatuses[key] }))}
          onChange={onFilterChange}
          unsorted
        />
      );
    case 'tagIds':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          activeValues={filters[customField.fieldName]}
          options={tags.map(tag => ({
            label: tag.name,
            labelRenderer: () => (
              <TagRow key={tag.id}>
                <Tag color={tag.color}>
                  <TagName>{tag.name}</TagName>
                </Tag>
              </TagRow>
            ),
            value: tag.id,
          }))}
          onChange={onFilterChange}
          unsorted
        />
      );
    case 'dueDate':
    case 'eventDate':
      return (
        <DateFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValue={filters[customField.fieldName]}
          showUpcomingYears={customField.fieldName === 'dueDate'}
          showFiscalOptions={customField.fieldName === 'eventDate'}
        />
      );
    default:
      return renderFilterByKind(customField, filters[customField.id], onFilterChange);
  }
}
