/* @flow */
import googleTokenAuthPopup from './googleTokenAuthPopup';
import validateGoogleToken from './validateGoogleToken';

type CachedCredentials = ?{
  accessToken: string,
  expiresAt: Date,
  scopes: $ReadOnlyArray<string>,
};

let creds: CachedCredentials = null;

function hasScopes(currentScopes: $ReadOnlyArray<string>, neededScopes: $ReadOnlyArray<string>) {
  return neededScopes.every(scope => currentScopes.includes(scope));
}

function getNewCreds(scopes): Promise<string> {
  return googleTokenAuthPopup(scopes).then(newCreds => {
    creds = {
      accessToken: newCreds.accessToken,
      expiresAt: newCreds.expiresAt,
      scopes,
    };
    return creds.accessToken;
  });
}

/**
 * Either returns a still-valid google access token or launches a popup to request a new one.
 */
export default function requireGoogleAccessToken(scopes: $ReadOnlyArray<string>): Promise<string> {
  if (!creds || creds.expiresAt < new Date() || !hasScopes(creds.scopes, scopes)) {
    return getNewCreds(scopes);
  }

  const accessToken = creds.accessToken;
  return validateGoogleToken(accessToken).then(tokenInfo => {
    if (!tokenInfo) {
      return getNewCreds(scopes);
    }
    return accessToken;
  });
}

export function clearGoogleCredentials(): void {
  creds = null;
}
