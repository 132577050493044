/* @flow */
import sortBy from 'lodash/sortBy';

import sortCustomFieldOptions from 'utils/customization/sortCustomFieldOptions';

import { type CustomFieldNodeType, type FieldType } from './types';

const normalizeCustomFields = (
  customFields: $ReadOnlyArray<CustomFieldNodeType>,
): $ReadOnlyArray<FieldType> =>
  // Don't use objects as arguments when using sortBy on integer fields. Somehow it parses integer
  // to string and does string ordering instead of integer, I assume this is lodash issue, though I
  // couldn't find in the github other users with this problem
  sortBy(customFields, ['section.order', 'order']).map(({ options, ...rest }) => {
    const rawOptions = options.edges.map(({ node }) => node);
    return {
      ...rest,
      groupId: rest.section ? rest.section.id : undefined,
      options: sortCustomFieldOptions(rawOptions).map(option => ({
        value: option.id,
        label: option.name,
      })),
    };
  });

export default normalizeCustomFields;
