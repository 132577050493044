/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventDatesComponent_componentProps$ref: FragmentReference;
export type EventDatesComponent_componentProps = {|
  +eventDatesComponent: ?{|
    +padding: string,
    +alignment: Alignment,
    +fontFamily: ?string,
    +fontStyle: ?string,
    +fontSize: ?number,
    +color: ?string,
    +backgroundColor: ?string,
  |},
  +$refType: EventDatesComponent_componentProps$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventDatesComponent_componentProps",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventDatesComponent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationEventDatesComponent",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "padding",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "alignment",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fontFamily",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fontStyle",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fontSize",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "color",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "backgroundColor",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f023713874d237c5b310d4b8f7399481';
module.exports = node;
