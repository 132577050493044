/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type BriefTemplateEventInfoFieldName = "ACTUAL_COST" | "AMOUNT_ALL_OPPORTUNITIES" | "BOOTH_NUMBER" | "DESCRIPTION" | "DIMENSIONS" | "FLOOR_PLAN" | "LAST_SYNCED" | "LEADER" | "MARKETO_ID" | "NUMBER_OF_OPPORTUNITIES" | "PLANNED_COST" | "PROGRESS" | "SALESFORCE_ID" | "STATUS" | "SYNC_STATUS";
export type BriefTemplateEventInfoSectionName = "BASICS" | "BOOTH" | "BUDGET" | "CRM";
export type EventBriefAccessibility = "DISABLED" | "MEMBERS" | "PUBLIC" | "SSO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefView_event$ref: FragmentReference;
export type BriefView_event = {|
  +id: string,
  +slug: string,
  +team: {|
    +font: string
  |},
  +briefSettings: {|
    +accessibility: EventBriefAccessibility,
    +briefTemplate: {|
      +headerSettings: {|
        +includeDates: boolean,
        +includeWebsite: boolean,
        +includeLocation: boolean,
      |},
      +sections: $ReadOnlyArray<{|
        +name: string,
        +enabled: boolean,
      |}>,
      +eventInfoSections: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +order: number,
            +enabled: boolean,
            +sectionName: ?BriefTemplateEventInfoSectionName,
            +fields: {|
              +edges: $ReadOnlyArray<{|
                +node: {|
                  +order: number,
                  +enabled: boolean,
                  +fieldName: ?BriefTemplateEventInfoFieldName,
                  +customField: ?{|
                    +id: string
                  |},
                |}
              |}>
            |},
          |}
        |}>
      |},
      +eventStaffFields: $ReadOnlyArray<{|
        +name: string,
        +enabled: boolean,
      |}>,
      +taskFields: $ReadOnlyArray<{|
        +name: string,
        +enabled: boolean,
      |}>,
      +scheduleFields: $ReadOnlyArray<{|
        +name: string,
        +enabled: boolean,
      |}>,
      +personalizeSchedules: boolean,
    |},
  |},
  +$refType: BriefView_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enabled",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  v1
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "BriefView_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "font",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "briefSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "EventBriefTemplate",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "accessibility",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "briefTemplate",
          "storageKey": null,
          "args": null,
          "concreteType": "BriefTemplate",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "headerSettings",
              "storageKey": null,
              "args": null,
              "concreteType": "BriefTemplateHeader",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "includeDates",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "includeWebsite",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "includeLocation",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "sections",
              "storageKey": null,
              "args": null,
              "concreteType": "SortableSection",
              "plural": true,
              "selections": v2
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "eventInfoSections",
              "storageKey": null,
              "args": null,
              "concreteType": "BriefTemplateEventInfoSectionRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BriefTemplateEventInfoSectionRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "BriefTemplateEventInfoSection",
                      "plural": false,
                      "selections": [
                        v3,
                        v1,
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "sectionName",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "fields",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "BriefTemplateEventInfoFieldRequiredConnection",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "edges",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "BriefTemplateEventInfoFieldRequiredEdge",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "node",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "BriefTemplateEventInfoField",
                                  "plural": false,
                                  "selections": [
                                    v3,
                                    v1,
                                    {
                                      "kind": "ScalarField",
                                      "alias": null,
                                      "name": "fieldName",
                                      "args": null,
                                      "storageKey": null
                                    },
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "customField",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomFieldType",
                                      "plural": false,
                                      "selections": [
                                        v0
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "eventStaffFields",
              "storageKey": null,
              "args": null,
              "concreteType": "SimpleSection",
              "plural": true,
              "selections": v2
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "taskFields",
              "storageKey": null,
              "args": null,
              "concreteType": "SimpleSection",
              "plural": true,
              "selections": v2
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "scheduleFields",
              "storageKey": null,
              "args": null,
              "concreteType": "SimpleSection",
              "plural": true,
              "selections": v2
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "personalizeSchedules",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '35bd533ee2b43f39cb77f7d658706f71';
module.exports = node;
