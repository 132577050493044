/* @flow */
import React from 'react';
import styled from 'styled-components';

import MaterialAvatar from 'components/material/MaterialAvatar';

import { type InviteFields } from '../InviteList';
import SelectionRow from './index';

const UserName = styled.div`
  line-height: 1.08;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledAvatar = styled(MaterialAvatar)`
  margin-right: 10px;
`;

const UserDetails = styled.div`
  margin-top: 4px;
  opacity: 0.54;
  line-height: 1.08;
  color: #4a5665;
  word-break: break-word;
`;

export default class UserSelectionRow extends React.Component<{
  invite: InviteFields,
  disabled?: boolean,
  onRemove: (id: string) => void,
  noAvatar?: boolean,
}> {
  render() {
    const { invite, disabled, onRemove, noAvatar } = this.props;
    const fullName = [invite.firstName, invite.lastName].filter(Boolean).join(' ');

    return (
      <SelectionRow value={invite.uid} disabled={disabled} onRemove={onRemove}>
        <UserInfo>
          {!noAvatar && <StyledAvatar user={invite} radius={15} />}
          <div>
            <UserName>{fullName || invite.email}</UserName>
            {fullName && <UserDetails>{invite.email}</UserDetails>}
          </div>
        </UserInfo>
      </SelectionRow>
    );
  }
}
