/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TagsOverlay_tags$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Tags_orgTags$ref: FragmentReference;
export type Tags_orgTags = $ReadOnlyArray<{|
  +id: string,
  +color: number,
  +name: string,
  +$fragmentRefs: TagsOverlay_tags$ref,
  +$refType: Tags_orgTags$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Tags_orgTags",
  "type": "Tag",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TagsOverlay_tags",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6000004ab77649d10d15201bc9e50ed8';
module.exports = node;
