/**
 * @flow
 * @relayHash 2a6705b6320c227f9c117a27d4cbebf7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DisconnectMarketoAccountInput = {
  clientMutationId?: ?string
};
export type disconnectMarketoAccountMutationVariables = {|
  input: DisconnectMarketoAccountInput
|};
export type disconnectMarketoAccountMutationResponse = {|
  +disconnectMarketoAccount: ?{|
    +updatedOrg: {|
      +id: string,
      +marketoAccount: ?{|
        +id: string
      |},
    |}
  |}
|};
export type disconnectMarketoAccountMutation = {|
  variables: disconnectMarketoAccountMutationVariables,
  response: disconnectMarketoAccountMutationResponse,
|};
*/


/*
mutation disconnectMarketoAccountMutation(
  $input: DisconnectMarketoAccountInput!
) {
  disconnectMarketoAccount(input: $input) {
    updatedOrg {
      id
      marketoAccount {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DisconnectMarketoAccountInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "disconnectMarketoAccount",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DisconnectMarketoAccountInput!"
      }
    ],
    "concreteType": "DisconnectMarketoAccountPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedOrg",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketoAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketoAccount",
            "plural": false,
            "selections": [
              v1
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "disconnectMarketoAccountMutation",
  "id": null,
  "text": "mutation disconnectMarketoAccountMutation(\n  $input: DisconnectMarketoAccountInput!\n) {\n  disconnectMarketoAccount(input: $input) {\n    updatedOrg {\n      id\n      marketoAccount {\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "disconnectMarketoAccountMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "disconnectMarketoAccountMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c15fb326769e3ef77d3592fe7e19f49';
module.exports = node;
