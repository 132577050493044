/* @flow */
import React from 'react';
import type { Location, RouterHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import EventRequestsFilters from './EventRequestsFilters';
import type { ParsedEventRequestsItemFilters } from './lib/parseEventRequestsItemFilters';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 47px;
  margin-bottom: 15px;
  padding-top: 15px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px 15px;
    margin-bottom: 0;
  }
`;

const StyledLink = styled(Link)`
  flex: 1 1 100%;
  margin-left: 6px;
  color: #3ba9da;
  font-style: normal;
  white-space: nowrap;
  text-align: right;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
    text-decoration: underline;
  }
`;

export default class EventRequestsHeaderBar extends React.PureComponent<{
  location: Location,
  history: RouterHistory,
  filters: ParsedEventRequestsItemFilters,
  viewerCanUpdate: boolean,
}> {
  render() {
    return (
      <Container>
        {this.props.viewerCanUpdate && (
          <StyledLink
            to={{
              pathname: '/settings/events/requests',
              state: {
                from: 'eventRequests',
                fromUrl: `${this.props.location.pathname}${this.props.location.search}`,
              },
            }}
          >
            Manage Event Request forms
          </StyledLink>
        )}
        <EventRequestsFilters
          location={this.props.location}
          history={this.props.history}
          filters={this.props.filters}
        />
      </Container>
    );
  }
}
