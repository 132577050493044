/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { OrgLeadSearchRow_user } from './__generated__/OrgLeadSearchRow_user.graphql';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  line-height: 1.4;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
`;

const Wrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

const Main = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const Secondary = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 13px;
`;

const StyledAvatar = styled(MaterialAvatar)`
  flex: 0 0 auto;
  margin-right: 10px;
`;

const OrgLeadSearchRow = (props: { user: OrgLeadSearchRow_user }) => {
  const userName = fullNameOfUser(props.user);
  return (
    <Row>
      <StyledAvatar user={props.user} radius={12} />
      <Wrapper title={userName}>
        <Main>{userName}</Main>
        <Secondary>{props.user.email}</Secondary>
      </Wrapper>
    </Row>
  );
};

export default createFragmentContainer(
  OrgLeadSearchRow,
  graphql`
    fragment OrgLeadSearchRow_user on User {
      firstName
      lastName
      email
      ...MaterialAvatar_user
    }
  `,
);
