/**
 * @flow
 * @relayHash d5df67f215b6d3b0fcf10f74d5800a12
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveParticipantFromScheduleItemInput = {
  clientMutationId?: ?string,
  userId: string,
  scheduleItemId: string,
};
export type removeParticipantMutationVariables = {|
  input: RemoveParticipantFromScheduleItemInput
|};
export type removeParticipantMutationResponse = {|
  +removeParticipantFromScheduleItem: ?{|
    +removedParticipantId: string
  |}
|};
export type removeParticipantMutation = {|
  variables: removeParticipantMutationVariables,
  response: removeParticipantMutationResponse,
|};
*/


/*
mutation removeParticipantMutation(
  $input: RemoveParticipantFromScheduleItemInput!
) {
  removeParticipantFromScheduleItem(input: $input) {
    removedParticipantId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveParticipantFromScheduleItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeParticipantFromScheduleItem",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveParticipantFromScheduleItemInput!"
      }
    ],
    "concreteType": "RemoveParticipantFromScheduleItemPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedParticipantId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeParticipantMutation",
  "id": null,
  "text": "mutation removeParticipantMutation(\n  $input: RemoveParticipantFromScheduleItemInput!\n) {\n  removeParticipantFromScheduleItem(input: $input) {\n    removedParticipantId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeParticipantMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeParticipantMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ce196551b1eafe719d169ca0179be2d';
module.exports = node;
