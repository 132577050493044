/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import createCustomField from 'graph/mutations/customFields/createCustomField';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/budget/Button';

import CustomFieldForm from './CustomFieldForm';
import CustomizableFieldsList from './CustomizableFieldsList';
import CustomizableSectionHeader from './CustomizableSectionHeader';
import {
  type CustomizableType,
  type Field,
  type FieldInput,
  type FilterType,
  type Section,
  type SectionCustomizableType,
} from './types';

const FieldsContainer = styled.div`
  padding-left: 52px;

  ${props =>
    (!props.expanded || props.dragging) &&
    css`
      > div,
      > button {
        display: none;
      }
    `};
`;

const StyledCustomFieldForm = styled(CustomFieldForm)`
  margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
  display: ${props => (props.expanded ? 'flex' : 'none')};
  margin: 10px 0 20px 0;
`;

export default class CustomizableSection extends React.Component<
  {
    section: Section,
    orgId: string,
    filters: FilterType,
    fields: $ReadOnlyArray<Field>,
    customizableType: CustomizableType,
    sectionCustomizableType: SectionCustomizableType,
    dragging: boolean,
    draggingField?: ?Field,
    onAddSection: (sectionName: string) => void,
    onMoveEnd?: () => void,
    onMoveField?: (sourceField: Field, targetField: Field) => void,
    onMoveFieldToSection: (dragField: Field, sectionId: string) => void,
  },
  { expanded: boolean, adding: boolean },
> {
  state = { expanded: this.props.section.order === 0, adding: false };

  handlePlusClick = () => {
    this.setState({ adding: true, expanded: true });
  };

  handleCancelCreate = () => {
    this.setState({ adding: false });
  };

  handleFieldCreate = (props: FieldInput): Promise<void> => {
    const { orgId, customizableType, filters, section } = this.props;
    return createCustomField(
      orgId,
      { ...props, customizableType, ...(section != null ? { sectionId: section.id } : null) },
      { customizableType: filters.customizableType, includeDisabled: filters.includeDisabled },
    )
      .then(() => {
        this.setState({ adding: false });
      })
      .catch(showModernMutationError);
  };

  handleExpandToggle = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }));
  };

  render() {
    const {
      orgId,
      filters,
      fields,
      section,
      dragging,
      draggingField,
      onMoveField,
      onMoveEnd,
      onMoveFieldToSection,
      customizableType,
      sectionCustomizableType,
    } = this.props;
    const { expanded, adding } = this.state;

    return (
      <React.Fragment>
        <CustomizableSectionHeader
          orgId={orgId}
          section={section}
          expanded={expanded}
          sectionCustomizableType={sectionCustomizableType}
          onPlusClick={this.handlePlusClick}
          onAddSection={this.props.onAddSection}
          onExpandToggle={this.handleExpandToggle}
          onMoveFieldToSection={onMoveFieldToSection}
        />
        <FieldsContainer expanded={expanded} dragging={dragging}>
          <CustomizableFieldsList
            orgId={orgId}
            customizableType={customizableType}
            sectionCustomizableType={sectionCustomizableType}
            filters={filters}
            fields={fields}
            section={section}
            draggingField={draggingField}
            onMoveEnd={onMoveEnd}
            onMoveField={onMoveField}
          />
          {adding ? (
            <StyledCustomFieldForm
              adding
              onSave={this.handleFieldCreate}
              onCancel={this.handleCancelCreate}
              sectionCustomizableType={sectionCustomizableType}
              customizableType={customizableType}
            />
          ) : (
            <StyledButton expanded={expanded} onClick={this.handlePlusClick}>
              Add New Field
            </StyledButton>
          )}
        </FieldsContainer>
      </React.Fragment>
    );
  }
}
