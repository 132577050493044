/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TextInput from 'components/material/TextInput';

const StyledTextInput = styled(TextInput)`
  padding-left: 7px;
  padding-right: 7px;
  ${props =>
    props.disableSearch &&
    `
    position: fixed;
    top: -30px;
    left: -30px;
    width: 0;
    height: 0;
  `};
`;

const InfoText = styled.div`
  font-style: italic;
  color: ${props => props.theme.mutedTextColor};
  padding: 3px 7px;
  font-size: 12px;
  text-align: right;
`;

export default class SearchableList<Option: {}> extends React.PureComponent<
  {
    options: $ReadOnlyArray<Option>,
    queryAttrs: Array<$Keys<Option>>,
    renderItem: (Option, boolean) => React.Element<React.ElementType>,
    maxOptions: number,
    onSelect: Option => void,
    disableSearch?: boolean,
  },
  { query: string, focus: number },
> {
  static defaultProps = {
    maxOptions: 5,
  };

  state = { query: '', focus: 0 };

  handleQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ query: e.currentTarget.value });
  };

  handleKeyDown = (e: SyntheticEvent<> & { key: string }) => {
    e.stopPropagation();
    if (this.props.disableSearch) {
      e.preventDefault();
    }

    if (e.key === 'ArrowDown') {
      this.setState(state => ({ focus: state.focus + 1 }));
      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      this.setState(state => ({ focus: state.focus - 1 }));
      e.preventDefault();
    } else if (e.key === 'Enter') {
      const options = this.handleOptionsFilter();
      const focusedIndex = this.focusedIndex(Math.min(options.length, this.props.maxOptions));
      if (options[focusedIndex]) {
        this.props.onSelect(options[focusedIndex]);
      }
      e.preventDefault();
    }
  };

  handleMouseEnter = (index: number) => {
    this.setState({ focus: index });
  };

  handleOptionsFilter(): $ReadOnlyArray<Option> {
    const query = this.state.query.replace(/\s/g, '');
    const queryRegexp = new RegExp(query, 'i');
    return this.props.options.filter(option => {
      const optionQuery = this.props.queryAttrs
        .reduce((str, key) => str + (option[key] || ''), '')
        .replace(/\s/g, '');
      return queryRegexp.test(optionQuery);
    });
  }

  focusedIndex(length: number): number {
    const index = this.state.focus % length;
    if (this.state.focus >= 0 || index === 0) {
      return index;
    }
    return length + index;
  }

  render() {
    const options = this.handleOptionsFilter();
    const focusedIndex = this.focusedIndex(Math.min(options.length, this.props.maxOptions));
    const slicedOptions = options.slice(0, this.props.maxOptions);
    const optionRows = slicedOptions.map((option: Option, index) =>
      React.cloneElement(this.props.renderItem(option, focusedIndex === index), {
        onMouseDown: e => {
          e.preventDefault();
          this.props.onSelect(option);
        },
        onMouseEnter: () => this.handleMouseEnter(index),
      }),
    );

    return (
      <div>
        <StyledTextInput
          placeholder="Search..."
          autoFocus
          value={this.state.query}
          onChange={this.handleQueryChange}
          onKeyDown={this.handleKeyDown}
          disableSearch={this.props.disableSearch}
        />
        {optionRows}
        {this.props.maxOptions < options.length && (
          <InfoText>...and {options.length - this.props.maxOptions} more</InfoText>
        )}
        {options.length === 0 && <InfoText centered>No Results</InfoText>}
      </div>
    );
  }
}
