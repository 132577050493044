/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateEmbedComponent, {
  type updateEmbedComponentPropertyType,
} from 'graph/mutations/registration/updateEmbedComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultBackgroundColor } from '../../registrationFormDefaults';
import PropertyRow from '../components/PropertyRow';
import RegistrationColorSelector from '../components/RegistrationColorSelector';
import { Section } from '.';
import { AlignmentToggle, BlockingLayer, EmbedCode, PaddingComponent } from './components';
import type { AlignmentType } from './components/AlignmentToggle';

import EmbedComponent_selectedPageComponent from './__generated__/EmbedComponent_selectedPageComponent.graphql';
import EmbedComponent_org from './__generated__/ImageComponent_org.graphql';

const Container = styled.div`
  position: relative;
`;

class EmbedComponent extends React.PureComponent<{
  org: EmbedComponent_org,
  selectedPageComponent: EmbedComponent_selectedPageComponent,
  onChangeComponentProperty: (updatedProps: SelectedComponent) => void,
}> {
  updateEmbedComponent = (properties: updateEmbedComponentPropertyType) => {
    updateEmbedComponent({
      ...properties,
      id: this.props.selectedPageComponent.embedComponent.id,
    }).catch(showModernMutationError);
  };

  handleSavePadding = (padding: string) => {
    this.updateEmbedComponent({ padding });
  };

  handleChangeBackgroundColor = (backgroundColor: string): void => {
    this.updateEmbedComponent({ backgroundColor });
  };

  handleChangeAlignment = (alignment: AlignmentType) => {
    this.updateEmbedComponent({ alignment });
  };

  handleChangeCode = (code: string) => {
    this.updateEmbedComponent({ code });
  };

  render() {
    const {
      org,
      onChangeComponentProperty,
      selectedPageComponent: { id, embedComponent },
    } = this.props;
    return (
      <Container>
        {id === 'new' && <BlockingLayer />}
        <Section>
          <PropertyRow label="Embed Code">
            <EmbedCode value={embedComponent.code} onChange={this.handleChangeCode} />
          </PropertyRow>
        </Section>
        <Section>
          <PropertyRow label="Alignment">
            <AlignmentToggle
              selectedAlignment={embedComponent.alignment}
              onChange={this.handleChangeAlignment}
            />
          </PropertyRow>
          <PropertyRow label="Padding">
            <PaddingComponent
              paddingString={embedComponent.padding}
              onSave={this.handleSavePadding}
              onChange={onChangeComponentProperty}
            />
          </PropertyRow>
          <PropertyRow label="Background color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={embedComponent.backgroundColor || defaultBackgroundColor}
              onChangeColor={this.handleChangeBackgroundColor}
              includeTransparent
            />
          </PropertyRow>
        </Section>
      </Container>
    );
  }
}

export default createFragmentContainer(EmbedComponent, {
  org: graphql`
    fragment EmbedComponent_org on Org {
      id
      settings {
        ...RegistrationColorSelector_orgSettings
      }
    }
  `,
  selectedPageComponent: graphql`
    fragment EmbedComponent_selectedPageComponent on RegistrationPageComponent {
      id
      kind
      embedComponent {
        id
        code
        padding
        alignment
        backgroundColor
      }
    }
  `,
});
