/* @flow */
import React from 'react';
import styled from 'styled-components';
import Clipboard from 'clipboard';

import Tooltip, { type TooltipPlacement } from 'components/material/Tooltip';

export const Button = styled.button`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  line-height: ${props => props.size - 2}px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.primaryActionColor};
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  outline: none;
  transition: 0.2s;
  &:hover,
  &:focus {
    background: ${props => props.theme.primaryActionColor};
    color: ${props => props.theme.primaryActionTextColor};
  }
  &:focus {
    background: ${props => props.theme.primaryActionDarkerColor};
  }
`;

export default class EmbedButton extends React.PureComponent<
  {
    link: string,
    size: number,
    tooltipPlacement: TooltipPlacement,
    className?: string,
  },
  { copied: boolean },
> {
  button: ?HTMLButtonElement;

  static defaultProps = {
    tooltipPlacement: 'top',
  };

  state = { copied: false };

  componentDidMount() {
    new Clipboard(this.button, {
      text: this.embedCode,
    }).on('success', () => {
      this.setState({ copied: true });
    });
  }

  embedCode = () =>
    `<div style="position: relative;padding-top: 56.25%;overflow: hidden;">\
<iframe style="position: absolute;top: 0;left: 0; width: 100%;height: 100%;border: 0;" src="${this.props.link}"></iframe>\
</div>`;

  handleClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.blur();
  };

  handleMouseLeave = () => {
    if (this.state.copied) {
      // Tooltip animation
      setTimeout(() => {
        this.setState({ copied: false });
      }, 300);
    }
  };

  render() {
    return (
      <Tooltip
        label={this.state.copied ? 'Copied' : 'Copy Embed Code'}
        placement={this.props.tooltipPlacement}
      >
        <Button
          className={this.props.className}
          onClick={this.handleClick}
          onMouseLeave={this.handleMouseLeave}
          ref={button => {
            this.button = button;
          }}
          size={this.props.size}
        >
          <i className="fa fa-fw fa-code" />
        </Button>
      </Tooltip>
    );
  }
}
