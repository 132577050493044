/* @flow */
import { graphql } from 'react-relay';
import UserActions from 'actions/UserActions';
import pickBy from 'lodash/pickBy';

import { type TshirtSizeType } from 'config/tshirtSizes';

import { type HomepageGraphQLValue } from 'utils/getHomepageUrl';

import commitModernMutation from 'graph/utils/commitModernMutation';
import commitSupportMutation from 'graph/utils/commitSupportMutation';

import type {
  updateUserMutationResponse,
  updateUserMutationVariables,
} from './__generated__/updateUserMutation.graphql';

export type UpdateUserFromWindow = 'Team Members' | 'Event Staff' | 'Workspace Members';

const mutation = graphql`
  mutation updateUserMutation(
    $input: UpdateUserInput!
    $changingFirstName: Boolean!
    $changingLastName: Boolean!
    $changingAvatar: Boolean!
    $changingCompany: Boolean!
    $changingTitle: Boolean!
    $changingEmail: Boolean!
    $changingPhone: Boolean!
    $changingOfficePhone: Boolean!
    $changingTshirtSize: Boolean!
    $changingBio: Boolean!
    $changingOnboardingStepNumber: Boolean!
    $changingHomepage: Boolean!
    $changingHomepageListId: Boolean!
    $changingMetricUnits: Boolean!
    $changingTz: Boolean!
    $changingRole: Boolean!
    $changingGoals: Boolean!
    $changingEstimatedNumberOfEvents: Boolean!
  ) {
    updateUser(input: $input) {
      user {
        id
        firstName @include(if: $changingFirstName)
        lastName @include(if: $changingLastName)
        avatar @include(if: $changingAvatar)
        company @include(if: $changingCompany)
        title @include(if: $changingTitle)
        email @include(if: $changingEmail)
        phone @include(if: $changingPhone)
        officePhone @include(if: $changingOfficePhone)
        tshirtSize @include(if: $changingTshirtSize)
        bio @include(if: $changingBio)
        onboardingStepNumber @include(if: $changingOnboardingStepNumber)
        homepage @include(if: $changingHomepage)
        homepageListId @include(if: $changingHomepageListId)
        metricUnits @include(if: $changingMetricUnits)
        tz @include(if: $changingTz)
        role @include(if: $changingRole)
        goals @include(if: $changingGoals)
        estimatedNumberOfEvents @include(if: $changingEstimatedNumberOfEvents)
      }
    }
  }
`;

type Attrs = {|
  +firstName?: string,
  +lastName?: string,
  +avatar?: ?string,
  +company?: string,
  +title?: string,
  +email?: string,
  +phone?: string,
  +officePhone?: string,
  +tshirtSize?: ?TshirtSizeType,
  +bio?: string,
  +role?: ?string,
  +goals?: ?string,
  +estimatedNumberOfEvents?: ?string,
  +onboardingStepNumber?: ?number,
  +homepage?: HomepageGraphQLValue,
  +homepageListId?: ?string,
  +metricUnits?: boolean,
  +tz?: string,
|};

export default function updateUser(
  userId: string,
  attrs: Attrs,
  fromWindow: 'onboarding' | 'profile' | 'admin panel' | UpdateUserFromWindow,
): Promise<updateUserMutationResponse> {
  const variables: updateUserMutationVariables = {
    input: {
      userId,
      fromWindow,
      ...attrs,
    },
    changingFirstName: attrs.firstName !== undefined,
    changingLastName: attrs.lastName !== undefined,
    changingAvatar: attrs.avatar !== undefined,
    changingCompany: attrs.company !== undefined,
    changingTitle: attrs.title !== undefined,
    changingEmail: attrs.email !== undefined,
    changingPhone: attrs.phone !== undefined,
    changingOfficePhone: attrs.officePhone !== undefined,
    changingTshirtSize: attrs.tshirtSize !== undefined,
    changingBio: attrs.bio !== undefined,
    changingOnboardingStepNumber: attrs.onboardingStepNumber !== undefined,
    changingHomepage: attrs.homepage !== undefined,
    changingHomepageListId: attrs.homepageListId !== undefined,
    changingMetricUnits: attrs.metricUnits !== undefined,
    changingTz: attrs.tz !== undefined,
    changingRole: attrs.role !== undefined,
    changingGoals: attrs.goals !== undefined,
    changingEstimatedNumberOfEvents: attrs.estimatedNumberOfEvents !== undefined,
  };
  const optimisticResponse: updateUserMutationResponse = {
    updateUser: {
      user: {
        id: userId,
        ...attrs,
      },
    },
  };
  const mutationCommitter =
    fromWindow === 'admin panel' ? commitSupportMutation : commitModernMutation;
  return mutationCommitter({
    mutation,
    variables,
    optimisticResponse,
  }).then((response: updateUserMutationResponse) => {
    if (!response.updateUser) {
      return response;
    }
    const user = response.updateUser.user;
    const altAttrs = pickBy(
      {
        first_name: user.firstName,
        last_name: user.lastName,
        avatar: user.avatar,
        company: user.company,
        title: user.title,
        onboarding_step: user.onboardingStepNumber === null ? -1 : user.onboardingStepNumber,
      },
      v => v !== undefined,
    );
    UserActions.updateUser(altAttrs);

    return response;
  });
}
