/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Button from 'components/material/Button';

import EventListDesign, { type EventListDesignFields } from './EventListDesign';

import { type RightSidebar_org } from './__generated__/RightSidebar_org.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 346px;
  height: 100%;
`;

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  overflow-y: auto;
`;

const ActionButtonsWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

const ActionRightButtons = styled.div`
  display: flex;

  button {
    margin-left: 23px;
  }
`;

class RightSidebar extends React.Component<
  {
    org: RightSidebar_org,
    eventList: EventListDesignFields & { publicResourceToken: string },
    onUpdateEventList: (eventList: $Exact<{ ...EventListDesignFields }>) => void,
    onToggleSidebar: () => void,
  },
  { disableApply: boolean },
> {
  state = { disableApply: true };

  savedEventList: EventListDesignFields & { publicResourceToken: string } = this.props.eventList;

  handleCancel = () => {
    const { publicResourceToken, ...eventList } = this.savedEventList;

    this.props.onUpdateEventList({ ...eventList });
    this.props.onToggleSidebar();

    this.setState({ disableApply: true });
  };

  handleSave = () => {
    this.savedEventList = this.props.eventList;

    if (typeof window.opener.onChangeEventListStyles !== 'function') return;

    const { publicResourceToken, ...eventList } = this.savedEventList;
    window.opener.onChangeEventListStyles(eventList);

    this.setState({ disableApply: true });
  };

  handleUpdateEventList = (eventList: $Exact<{ ...EventListDesignFields }>) => {
    this.props.onUpdateEventList(eventList);

    this.setState(state => (state.disableApply ? { disableApply: false } : null));
  };

  render() {
    const { org, eventList } = this.props;
    const { disableApply } = this.state;

    return (
      <Container>
        <Content>
          <EventListDesign
            orgSettings={org.settings}
            eventList={eventList}
            onEventListChange={this.handleUpdateEventList}
          />
        </Content>

        <ActionButtonsWrapper>
          <ActionRightButtons>
            <Button label="Cancel" minimal onClick={this.handleCancel} />

            <Button label="Apply" primary onClick={this.handleSave} disabled={disableApply} />
          </ActionRightButtons>
        </ActionButtonsWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(RightSidebar, {
  org: graphql`
    fragment RightSidebar_org on Org {
      settings {
        ...EventListDesign_orgSettings
      }
    }
  `,
});
