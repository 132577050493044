/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import staffAccessLevels, { type StaffAccessLevel } from 'config/staffAccessLevels';

import isValidEmail from 'utils/validators/isValidEmail';

import type { invitePeopleMutationResponse } from 'graph/mutations/access/__generated__/invitePeopleMutation.graphql';
import invitePeople from 'graph/mutations/access/invitePeople';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/material/Button';
import TagMultiselect from 'components/TagMultiselect';
import { type ParsedMembersFilters } from 'views/Main/Workspace/Members/lib/parseMembersFilters';

import { type AccessType } from './AccessTypeSelector';
import { type InviteFromWindow, type User } from './index';
import { type Event } from './InvitationEventSearch';
import { type InviteFields } from './InviteList';
import InviteToEventContents from './InviteToEventContents';
import InviteToOrgContents from './InviteToOrgContents';
import InviteToTeamContents from './InviteToTeamContents';
import InviteWindowLimitError from './InviteWindowLimitError';

import type { InviteWindowContents_me } from './__generated__/InviteWindowContents_me.graphql';
import type { InviteWindowContents_org } from './__generated__/InviteWindowContents_org.graphql';

const MarginButton = styled(Button)`
  margin-left: 30px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const CancelButton = styled(Button)`
  margin-left: auto;
`;

type Props = {
  me: InviteWindowContents_me,
  org: InviteWindowContents_org,
  teamId?: string,
  eventId?: string,
  filters?: ParsedMembersFilters,
  onHide: (userIds?: ?$ReadOnlyArray<User>) => void,
  skipStaffAccessLevel?: StaffAccessLevel,
  onClickImport?: () => void,
  fromWindow: InviteFromWindow,
};

type State = {
  selectedTeamIds: $ReadOnlyArray<string>,
  selectedEvents: $ReadOnlyArray<Event>,
  inviteEmails: $ReadOnlyArray<string>,
  inviteEmailsErrors: $ReadOnlyArray<string>,
  loading: boolean,
  existingUserInvites: $ReadOnlyArray<InviteFields>,
  accessType: AccessType,
};

class InviteWindowContents extends React.Component<Props, State> {
  static getFirstStaffAccessLevel(skipStaffAccessLevel?: StaffAccessLevel) {
    return skipStaffAccessLevel == null
      ? staffAccessLevels[0]
      : staffAccessLevels.filter(accessLevel => accessLevel !== skipStaffAccessLevel)[0];
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (!nextProps.teamId || prevState.selectedTeamIds.includes(nextProps.teamId)) {
      return null;
    }

    return {
      selectedTeamIds: nextProps.teamId == null ? [] : [nextProps.teamId],
    };
  }

  state = {
    accessType: this.getInitialAccessType(),
    selectedTeamIds: this.props.teamId == null ? [] : [this.props.teamId],
    selectedEvents: [],
    loading: false,
    inviteEmails: [],
    inviteEmailsErrors: [],
    existingUserInvites: [],
  };

  initialAccessType: ?AccessType = this.getInitialAccessType();

  searchUserQueryExists: ?boolean;

  searchTeamQueryExists: ?boolean;

  searchEventQueryExists: ?boolean;

  getInitialAccessType() {
    return this.props.eventId != null || !this.props.org.viewerCanManageTeamMembers
      ? InviteWindowContents.getFirstStaffAccessLevel(this.props.skipStaffAccessLevel)
      : 'TEAM';
  }

  handleAddNewEmail = (emails: $ReadOnlyArray<string>) => {
    if (emails.length > 1) {
      // Filter out emails that are valid and already in inviteEmails
      const unmatchedValidEmails = emails.filter(
        email => isValidEmail(email) && !this.state.inviteEmails.includes(email),
      );
      const updatedInviteEmailsErrors = unmatchedValidEmails.map(() => '');

      this.setState(previousState => ({
        inviteEmails: [...previousState.inviteEmails, ...unmatchedValidEmails],
        inviteEmailsErrors: [...previousState.inviteEmailsErrors, ...updatedInviteEmailsErrors],
      }));
    } else {
      this.setState(previousState => {
        const updatedInviteEmails = [...previousState.inviteEmails, ...emails];
        const updatedInviteEmailsErrors = updatedInviteEmails.map(email => {
          return isValidEmail(email) ? '' : 'error';
        });
        return {
          inviteEmails: updatedInviteEmails,
          inviteEmailsErrors: updatedInviteEmailsErrors,
        };
      });
    }
  };

  handleUpdateNewEmail = (email: string, index: number) => {
    this.setState(previousState => {
      const updatedInviteEmails = [...previousState.inviteEmails];
      updatedInviteEmails[index] = email;
      const updatedInviteEmailsErrors = [...previousState.inviteEmailsErrors];
      updatedInviteEmailsErrors[index] = isValidEmail(email) ? '' : 'error';
      return {
        inviteEmails: updatedInviteEmails,
        inviteEmailsErrors: updatedInviteEmailsErrors,
      };
    });
  };

  handleRemoveEmail = (itemIndex: number) => {
    this.setState(previousState => ({
      inviteEmails: previousState.inviteEmails.filter((_, index) => index !== itemIndex),
      inviteEmailsErrors: previousState.inviteEmailsErrors.filter(
        (_, index) => index !== itemIndex,
      ),
    }));
  };

  handleSelectUser = (user: InviteFields) => {
    this.setState(({ existingUserInvites }) => ({
      existingUserInvites: uniqBy([user, ...existingUserInvites], 'uid'),
    }));
  };

  handleUnselectUser = (userId: string) => {
    this.setState(({ existingUserInvites }) => ({
      existingUserInvites: existingUserInvites.filter(invite => invite.uid !== userId),
    }));
  };

  handleSelectTeam = (teamId: string) => {
    this.setState(({ selectedTeamIds }) => ({
      selectedTeamIds: uniq([teamId, ...selectedTeamIds]),
    }));
  };

  handleUnselectTeam = (teamId: string) => {
    this.setState(({ selectedTeamIds }) => ({
      selectedTeamIds: selectedTeamIds.filter(id => id !== teamId),
    }));
  };

  handleSelectEvent = (event: Event) => {
    this.setState(({ selectedEvents }) => ({
      selectedEvents: uniq([event, ...selectedEvents]),
    }));
  };

  handleUnselectEvent = (eventId: string) => {
    this.setState(({ selectedEvents }) => ({
      selectedEvents: selectedEvents.filter(event => event.id !== eventId),
    }));
  };

  hasInviteEmailsErrors = () => {
    const { inviteEmailsErrors, inviteEmails } = this.state;
    return inviteEmails.length > 0 && inviteEmailsErrors.some(error => error === 'error');
  };

  hasInvitees = () => {
    const { existingUserInvites, inviteEmails } = this.state;
    return existingUserInvites.length > 0 || inviteEmails.length > 0;
  };

  handleCancel = () => {
    this.props.onHide();
  };

  handleSubmit = () => {
    const {
      existingUserInvites,
      inviteEmails,
      accessType,
      selectedEvents,
      selectedTeamIds,
    } = this.state;
    const hasInviteEmailsErrors = this.hasInviteEmailsErrors();
    if (hasInviteEmailsErrors || !this.hasInvitees()) {
      return;
    }
    const teamIds = accessType === 'TEAM' ? selectedTeamIds : undefined;
    const accessLevel: ?StaffAccessLevel = staffAccessLevels.find(level => level === accessType);
    const staffInvites = accessLevel
      ? {
          eventIds: [this.props.eventId, ...selectedEvents.map(event => event.id)].filter(Boolean),
          accessLevel,
        }
      : undefined;

    const existingUserEmails = existingUserInvites.map(item => item.email);
    /* Remove duplicates form inviteEmails and filter the array to
    include only emails that are not in the existingUserInvites
    * Array.from used to avoid flow error  */
    const newInviteEmails = uniqBy(Array.from(inviteEmails)).filter(
      email => !existingUserEmails.includes(email),
    );
    const personInvites = [
      ...existingUserInvites,
      ...newInviteEmails.map(email => ({ email })),
    ].map(person => ({
      ...(person.firstName ? { firstName: person.firstName } : null),
      ...(person.lastName ? { lastName: person.firstName } : null),
      email: person.email,
      admin: accessType === 'ADMIN',
    }));

    this.setState({ loading: true });
    invitePeople(
      this.props.org.id,
      { personInvites, teamIds, staffInvites, fromWindow: this.props.fromWindow },
      this.props.filters,
    )
      .then((result: invitePeopleMutationResponse) => {
        this.setState({ loading: false });
        if (result.invitePeople == null) {
          return this.props.onHide();
        }
        if (this.props.eventId) {
          return this.props.onHide(
            uniqBy(
              [
                ...result.invitePeople.staffMembershipEdges.map(membership => membership.node.user),
                ...existingUserInvites.map(invite => ({ ...invite, id: invite.uid })),
              ],
              'id',
            ),
          );
        }
        if (this.props.teamId) {
          return this.props.onHide(
            uniqBy(
              [
                ...result.invitePeople.staffMembershipEdges.map(membership => membership.node.user),
                ...existingUserInvites.map(invite => ({ ...invite, id: invite.uid })),
              ],
              'id',
            ),
          );
        }
        return this.props.onHide(
          uniqBy(
            [
              ...result.invitePeople.orgUserEdges.map(edge => edge.node),
              ...existingUserInvites.map(invite => ({ ...invite, id: invite.uid })),
            ],
            'id',
          ),
        );
      })
      .catch(err => {
        this.setState({ loading: false });
        showModernMutationError(err);
      });
  };

  handleChangeAccessType = (accessType: ?AccessType) => {
    if (accessType) {
      this.setState({
        accessType,
      });
    }
  };

  handleToggleUserQueryState = (present: boolean) => {
    this.searchUserQueryExists = present;
  };

  handleToggleTeamQueryState = (present: boolean) => {
    this.searchTeamQueryExists = present;
  };

  handleToggleEventQueryState = (present: boolean) => {
    this.searchEventQueryExists = present;
  };

  isInviteesEdited = (): boolean => {
    return this.state.inviteEmails.length > 0;
  };

  isExistingInvitesEdited = () => {
    return this.state.existingUserInvites.length > 0 || this.searchUserQueryExists;
  };

  isTeamsEdited = () => {
    return (
      !this.props.teamId && (this.state.selectedTeamIds.length > 0 || this.searchTeamQueryExists)
    );
  };

  isEventsEdited = () => {
    return this.state.selectedEvents.length > 0 || this.searchEventQueryExists;
  };

  isAccessTypeChanged = () => {
    return this.initialAccessType && this.state.accessType !== this.initialAccessType;
  };

  // Returns full access members quota left. Takes into account pending invites. Can be negative
  currentBillingLimit = (): ?number => {
    const { inviteEmails, existingUserInvites, accessType } = this.state;
    const { subscription, teamMembers } = this.props.org;

    if (subscription.fatmLimit == null || !['ADMIN', 'TEAM'].includes(accessType)) {
      return null;
    }

    const memberEmails = teamMembers.edges.map(memberEdge => memberEdge.node.email);
    const existingUserEmails = existingUserInvites.map(user => user.email);
    const newInviteEmails = uniqBy(Array.from(inviteEmails)).filter(
      email => isValidEmail(email) && !existingUserEmails.includes(email),
    );
    const processingUserEmails = uniq([...newInviteEmails, ...existingUserEmails]);
    const nonFatmInvitesCount = difference(processingUserEmails, memberEmails).length;

    // $FlowFixMe subscription.fatmLimit is checked not to be null at this point
    return subscription.fatmLimit - subscription.fatmCount - nonFatmInvitesCount;
  };

  /* isNotEdited used from the parent component to check if there are
  changes and not to close the window when user accidentally clicks out. */
  isNotEdited() {
    return !(
      this.isExistingInvitesEdited() ||
      this.isTeamsEdited() ||
      this.isEventsEdited() ||
      this.isAccessTypeChanged() ||
      this.isInviteesEdited()
    );
  }

  render() {
    const {
      existingUserInvites,
      accessType,
      selectedTeamIds,
      selectedEvents,
      loading,
      inviteEmails,
      inviteEmailsErrors,
    } = this.state;
    const fromWindow = this.props.fromWindow;
    const org = this.props.org;
    const billingLimit = this.currentBillingLimit();
    const billingLimitExceeded = billingLimit != null && billingLimit < 0;
    const hasInviteEmailsErrors = this.hasInviteEmailsErrors();
    const isScopeSelected =
      accessType === 'ADMIN' ||
      (accessType === 'TEAM' && selectedTeamIds.length > 0) ||
      (staffAccessLevels.includes(accessType) &&
        (selectedEvents.length > 0 || this.props.eventId != null));
    return (
      <>
        {org.subscription.fatmLimit != null && billingLimit != null && billingLimit <= 0 && (
          <InviteWindowLimitError
            limit={org.subscription.fatmLimit}
            fromWindow={
              fromWindow === 'team' || fromWindow === 'org members' || fromWindow === 'easy_button'
                ? fromWindow
                : 'org members'
            }
          />
        )}
        {this.props.teamId && (
          <InviteToTeamContents
            invites={existingUserInvites}
            onSelectUser={this.handleSelectUser}
            onUnselectUser={this.handleUnselectUser}
            onToggleQueryState={this.handleToggleUserQueryState}
          />
        )}
        {this.props.eventId && (
          <InviteToEventContents
            accessType={accessType}
            onAccessTypeChange={this.handleChangeAccessType}
            skipStaffAccessLevel={this.props.skipStaffAccessLevel}
            invites={existingUserInvites}
            onSelectUser={this.handleSelectUser}
            onUnselectUser={this.handleUnselectUser}
            onToggleQueryState={this.handleToggleUserQueryState}
          />
        )}
        {!this.props.teamId && !this.props.eventId && (
          <InviteToOrgContents
            me={this.props.me}
            org={org}
            accessType={accessType}
            onAccessTypeChange={this.handleChangeAccessType}
            skipStaffAccessLevel={this.props.skipStaffAccessLevel}
            selectedTeamIds={selectedTeamIds}
            onSelectTeam={this.handleSelectTeam}
            onUnselectTeam={this.handleUnselectTeam}
            selectedEvents={selectedEvents}
            onSelectEvent={this.handleSelectEvent}
            onUnselectEvent={this.handleUnselectEvent}
            onToggleTeamQueryState={this.handleToggleTeamQueryState}
            onToggleEventQueryState={this.handleToggleEventQueryState}
          />
        )}
        <TagMultiselect
          onAdd={this.handleAddNewEmail}
          onRemove={this.handleRemoveEmail}
          onUpdate={this.handleUpdateNewEmail}
          errors={inviteEmailsErrors}
          tags={inviteEmails}
          placeholder="name@company.com, name@company.com, …"
          label="Email addresses"
        />
        <Footer>
          {org.viewerCanManageUsers && this.props.onClickImport && (
            <Button label="Import CSV File" minimal primary onClick={this.props.onClickImport} />
          )}
          <CancelButton label="Cancel" minimal onClick={this.handleCancel} />
          <MarginButton
            label="Add"
            primary
            onClick={this.handleSubmit}
            loading={loading}
            disabled={
              !this.hasInvitees() ||
              hasInviteEmailsErrors ||
              !isScopeSelected ||
              billingLimitExceeded
            }
          />
        </Footer>
      </>
    );
  }
}

export default createFragmentContainer(InviteWindowContents, {
  me: graphql`
    fragment InviteWindowContents_me on User {
      ...InviteToOrgContents_me
    }
  `,
  org: graphql`
    fragment InviteWindowContents_org on Org {
      id
      viewerCanManageTeamMembers
      viewerCanManageUsers
      subscription {
        fatmLimit
        fatmCount
      }
      teamMembers: users(hasTeamAccess: true) {
        edges {
          node {
            email
          }
        }
      }
      ...InviteToOrgContents_org
    }
  `,
});
