/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactRequestSubmissionsCount_contact$ref: FragmentReference;
export type ContactRequestSubmissionsCount_contact = {|
  +eventRequestSubmissions: {|
    +totalCount: number
  |},
  +$refType: ContactRequestSubmissionsCount_contact$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactRequestSubmissionsCount_contact",
  "type": "Contact",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventRequestSubmissions",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestSubmissionRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '5b1e627a811c0c52962345797f76e7e6';
module.exports = node;
