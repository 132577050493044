/* @flow */
import React from 'react';
import styled from 'styled-components';

import blockedByUpgradePrompt, {
  type UpgradePromptBlockedFrom,
} from 'utils/analytics/blockedByUpgradePrompt';

import Button from 'components/budget/Button';
import Window, { WindowContent, WindowHeader, WindowClose } from 'components/material/Window';
import BillingProgressBar from './BillingProgressBar';

const Title = styled.div`
  padding: 0 45px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #3e4859;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  text-align: center;
`;

export default class BillingLimitWindow extends React.PureComponent<{
  limit: number,
  used: number,
  onHide: () => void,
  fromWindow: UpgradePromptBlockedFrom,
}> {
  componentDidMount() {
    blockedByUpgradePrompt(this.props.fromWindow);
  }

  handleClick = () => {
    window.open('mailto:success@circa.co', '_blank');
  };

  render() {
    const { limit, used, onHide } = this.props;
    return (
      <Window onHide={onHide} size="medium">
        <WindowHeader>
          <WindowClose onClick={onHide} />
        </WindowHeader>
        <WindowContent>
          <Title>You&#39;ve reached the Team Member limit included in your subscription</Title>
          <ProgressBarContainer>
            <BillingProgressBar limit={limit} used={used} label="Team Members" />
          </ProgressBarContainer>
          <ButtonContainer>
            <Button onClick={this.handleClick}>Contact Us to Add Team Members</Button>
          </ButtonContainer>
        </WindowContent>
      </Window>
    );
  }
}
