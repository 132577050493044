import styled from 'styled-components';

const EmptyFilters = styled.div`
  margin-top: 20px;
  padding: 15px 10px 15px 20px;
  border: 1px dashed
    ${props => (props.muted ? props.theme.mutedTextColor : props.theme.mutedTextColor)};
  border-radius: 6px;
  text-align: center;
  color: ${props => (props.muted ? props.theme.mutedTextColor : props.theme.mutedTextColor)};
  cursor: ${props => (props.muted ? 'default' : 'pointer')};
`;

export default EmptyFilters;
