/* @flow */
import * as React from 'react';
import ClickOutside from 'react-onclickout';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { createMoment } from 'utils/Date';

import { EditContent } from 'components/budget/Table/InlineEditableCell';
import InlineUserSelectField from 'components/budget/Table/InlineUserSelectField';
import DateTimeOverlay from 'components/date/DateTimePicker/DateTimeOverlay';
import Loader from 'components/Loader';
import RemovablePill from 'components/material/RemovablePill';
import EmptyLink from 'components/material/table/EmptyLink';
import TextField from 'components/material/TextField';
import { Container as UserSelectInputContainer, Header } from 'components/material/UserSelectInput';
import UsersGroup from 'components/material/UsersGroup';
import { type User } from 'components/UserSelect';

import FolderSelect from './FolderSelect';

import type { TaskAdd_event } from './__generated__/TaskAdd_event.graphql';

const Container = styled.div`
  background: ${props => props.theme.hoverRowColor};
  padding: 12px 28px 20px 28px;
  position: relative;
  outline: 0;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px;
  }
`;

const FieldsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media (${props => props.theme.mobileOnly}) {
    flex-direction: column;
  }
`;

const NameInput = styled(TextField)`
  margin-right: 15px;
  flex: 3;
`;

const StyledFolderSelect = styled(FolderSelect)`
  flex: 1 0;
  width: 300px;
  margin: 11px 0;
  @media (${props => props.theme.mobileOnly}) {
    width: 100%;
    margin-top: 10px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  margin-top: 3px;
`;

const StyledAction = styled.div`
  position: relative;
  margin-right: 60px;
  @media (${props => props.theme.mobileOnly}) {
    display: none;
  }
`;

const AssigneeContainer = styled(StyledAction)`
  min-width: 56px;
  ${Header} {
    margin-top: -2px;
    padding: 0 0 0 1px;
  }
  ${UserSelectInputContainer}, ${EditContent} {
    background-color: transparent;
  }

  > div > div:first-child {
    border: 0;
  }
`;

const IconButton = styled.i`
  ${props =>
    props.primary
      ? `color: ${props.theme.primaryActionColor};`
      : `color: ${
          props.active ? props.theme.secondaryActionDarkerColor : props.theme.secondaryActionColor
        };`};
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: ${props =>
      props.primary
        ? props.theme.primaryActionDarkerColor
        : props.theme.secondaryActionDarkerColor};
  }
`;

const RightActions = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  ${IconButton} {
    margin-left: 10px;
  }
`;

const Removable = styled(RemovablePill)`
  padding-right: 5px;
`;

type NewTaskType = {
  names: Array<string>,
  folderId: ?string,
  assignees: Array<string>,
  dueDate: ?string,
  dueDateAllDay: ?boolean,
};

class TaskAdd extends React.PureComponent<
  {
    event: TaskAdd_event,
    onHide: () => void,
    onSave: NewTaskType => Promise<string>,
    onFolderCreate: string => Promise<string>,
  },
  {
    dateOverlayShown: boolean,
    loading: boolean,
    name: string,
    error: boolean,
    folderId: ?string,
    assignees: Array<User>,
    dueDate: ?string,
    dueDateAllDay: ?boolean,
  },
> {
  state = {
    folderId: null,
    name: '',
    dateOverlayShown: false,
    assignees: [],
    dueDate: null,
    dueDateAllDay: null,
    loading: false,
    error: false,
  };

  nameInput: ?HTMLInputElement;

  dateActionRef = React.createRef();

  handleFolderChange = folderId => {
    this.setState({ folderId });
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleKeyDown = e => {
    if (e.key === 'Escape') {
      this.props.onHide();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (!e.target || e.target.name !== 'folder_name') {
        this.handleSave();
      }
    }
  };

  handleClickOutside = () => {
    if (
      this.state.name.trim().length === 0 &&
      this.state.assignees.length === 0 &&
      !this.state.dueDate
    ) {
      this.props.onHide();
    }
  };

  handleSave = () => {
    if (!this.state.loading) {
      const names = this.state.name
        .split('\n')
        .map(str => str.trim())
        .filter(str => str);
      if (names.length > 0) {
        this.setState({
          error: false,
          name: '',
          dueDate: null,
          dueDateAllDay: null,
          assignees: [],
          loading: true,
        });

        this.props
          .onSave({
            names,
            folderId: this.state.folderId,
            dueDate: this.state.dueDate,
            dueDateAllDay: this.state.dueDate ? this.state.dueDateAllDay : null,
            assignees: this.state.assignees.map(assignee => assignee.id),
            fromWindow: 'checklist',
          })
          .then(() => {
            this.setState({ loading: false });
            if (this.nameInput) this.nameInput.focus();
          });
      } else {
        this.setState({ error: true });
      }
    }
  };

  handleDateOverlayHide = () => this.setState({ dateOverlayShown: false });

  handleDateOverlayShow = () => this.setState({ dateOverlayShown: true });

  handleAssigneeSelect = (user, isActive) => {
    if (!user) {
      return;
    }
    if (isActive) {
      this.setState(state => ({
        assignees: state.assignees.filter(assignee => assignee.id !== user.id),
      }));
    } else {
      if (!user) {
        return;
      }
      this.setState(state => ({ assignees: [...state.assignees, user] }));
    }
  };

  handleDateChange = ({ date, hideTime }) =>
    this.setState({ dueDate: date, dueDateAllDay: hideTime });

  handleDateRemove = () => {
    this.setState({ dueDate: null, dueDateAllDay: null });
  };

  render() {
    const { event } = this.props;
    const folders = event.folders.edges.map(edge => edge.node);

    return (
      <ClickOutside onClickOut={this.handleClickOutside}>
        <Container onKeyDown={this.handleKeyDown} tabIndex={0}>
          <FieldsContainer>
            <NameInput
              autoFocus
              placeholder="Add new task"
              value={this.state.name}
              onChange={this.handleNameChange}
              multiline
              error={this.state.error ? 'Please add a name to create a task' : null}
              inputRef={el => {
                this.nameInput = el;
              }}
            />
            <StyledFolderSelect
              folders={folders}
              folderId={this.state.folderId}
              onChange={this.handleFolderChange}
              onCreate={this.props.onFolderCreate}
            />
          </FieldsContainer>
          <ActionsContainer>
            <StyledAction ref={this.dateActionRef}>
              <Removable disabled={!this.state.dueDate} size={12} onRemove={this.handleDateRemove}>
                <EmptyLink
                  onClick={this.handleDateOverlayShow}
                  label={
                    this.state.dueDate
                      ? createMoment(event.tz)(this.state.dueDate).renderDateTime(
                          this.state.dueDateAllDay,
                        )
                      : 'Add Due Date'
                  }
                  icon="calendar"
                  highlighted
                />
              </Removable>

              <DateTimeOverlay
                show={this.state.dateOverlayShown}
                target={this.dateActionRef.current}
                container={this.dateActionRef.current}
                onHide={this.handleDateOverlayHide}
                tz={event.tz}
                onChange={this.handleDateChange}
                date={this.state.dueDate}
                hideTime={this.state.dueDateAllDay == null || this.state.dueDateAllDay}
              />
            </StyledAction>
            <AssigneeContainer>
              <InlineUserSelectField
                activeUsers={this.state.assignees}
                onSelect={user => this.handleAssigneeSelect(user, false)}
                onUnselect={user => this.handleAssigneeSelect(user, true)}
                isMultiselect
                eventId={this.props.event.id}
              >
                {this.state.assignees.length === 0 ? (
                  <EmptyLink onClick={() => {}} label="Assign" icon="user" highlighted />
                ) : (
                  <UsersGroup users={this.state.assignees} />
                )}
              </InlineUserSelectField>
            </AssigneeContainer>
            {this.state.loading ? (
              <RightActions>
                <Loader size={16} />
              </RightActions>
            ) : (
              <RightActions>
                <IconButton className="fa fa-fw fa-times" onClick={this.props.onHide} />
                <IconButton className="fa fa-fw fa-check" onClick={this.handleSave} primary />
              </RightActions>
            )}
          </ActionsContainer>
        </Container>
      </ClickOutside>
    );
  }
}

export default createFragmentContainer(
  TaskAdd,
  graphql`
    fragment TaskAdd_event on Event {
      id
      tz
      folders {
        edges {
          node {
            id
            name
            ...FolderSelect_folders
          }
        }
      }
    }
  `,
);
