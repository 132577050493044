/**
 * @flow
 * @relayHash ac3c52c47f1e329b2ac2086c12cd6097
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveNoteFromBriefInput = {
  clientMutationId?: ?string,
  eventId: string,
  noteId: string,
};
export type removeNoteFromBriefMutationVariables = {|
  input: RemoveNoteFromBriefInput
|};
export type removeNoteFromBriefMutationResponse = {|
  +removeNoteFromBrief: ?{|
    +eventNote: {|
      +includedInBrief: boolean
    |}
  |}
|};
export type removeNoteFromBriefMutation = {|
  variables: removeNoteFromBriefMutationVariables,
  response: removeNoteFromBriefMutationResponse,
|};
*/


/*
mutation removeNoteFromBriefMutation(
  $input: RemoveNoteFromBriefInput!
) {
  removeNoteFromBrief(input: $input) {
    eventNote {
      includedInBrief
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveNoteFromBriefInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveNoteFromBriefInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "includedInBrief",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeNoteFromBriefMutation",
  "id": null,
  "text": "mutation removeNoteFromBriefMutation(\n  $input: RemoveNoteFromBriefInput!\n) {\n  removeNoteFromBrief(input: $input) {\n    eventNote {\n      includedInBrief\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeNoteFromBriefMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeNoteFromBrief",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveNoteFromBriefPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventNote",
            "storageKey": null,
            "args": null,
            "concreteType": "EventNote",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeNoteFromBriefMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeNoteFromBrief",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveNoteFromBriefPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventNote",
            "storageKey": null,
            "args": null,
            "concreteType": "EventNote",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ea638615b88659486a1028b7dca10815';
module.exports = node;
