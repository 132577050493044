/* @flow */
import React from 'react';
import styled from 'styled-components';

import SizeField from './SizeField';

const TextContainer = styled.div`
  position: relative;
  width: 45px;
`;

const Border = styled.div`
  position: absolute;
  background-color: #6c80fa;
  z-index: 1;
`;

const BorderTop = styled(Border)`
  left: 2px;
  width: calc(100% - 4px);
  height: 2px;
  border-radius: 30px;
`;

const BorderRight = styled(Border)`
  right: 0px;
  top: 2px;
  height: calc(100% - 4px);
  width: 2px;
  border-radius: 30px;
`;

const BorderBottom = styled(Border)`
  bottom: 0px;
  left: 2px;
  width: calc(100% - 4px);
  height: 2px;
  border-radius: 30px;
`;

const BorderLeft = styled(Border)`
  left: 0px;
  top: 2px;
  height: calc(100% - 4px);
  width: 2px;
  border-radius: 30px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 204px;
`;

const StyledSizeField = styled(SizeField)`
  position: relative;
`;

export default class PaddingComponent extends React.PureComponent<{
  paddingString: string,
  onSave: (padding: string) => void,
  onChange: ({ selectedComponentPadding: string }) => void,
  forceFocus?: boolean,
}> {
  static defaultProps = {
    paddingString: '0 0 0 0',
  };

  getPaddingProps = () => {
    return this.props.paddingString.split(' ');
  };

  savePadding = (padding: { top?: string, right?: string, bottom?: string, left?: string }) => {
    const [top, right, bottom, left] = this.props.paddingString.split(' ');

    this.props.onSave(
      `${padding.top || top} ${padding.right || right} ${padding.bottom || bottom} ${padding.left ||
        left}`,
    );
  };

  handleChangePadding = (padding: {
    top?: string,
    right?: string,
    bottom?: string,
    left?: string,
  }) => {
    const { onChange, paddingString } = this.props;
    const [top, right, bottom, left] = paddingString.split(' ');
    onChange({
      selectedComponentPadding: `${padding.top || top} ${padding.right || right} ${padding.bottom ||
        bottom} ${padding.left || left}`,
    });
  };

  handleSaveTop = (top: number) => {
    this.savePadding({ top: String(top) });
  };

  handleSaveRight = (right: number) => {
    this.savePadding({ right: String(right) });
  };

  handleSaveBottom = (bottom: number) => {
    this.savePadding({ bottom: String(bottom) });
  };

  handleSaveLeft = (left: number) => {
    this.savePadding({ left: String(left) });
  };

  handleChangeTop = (top: number) => {
    this.handleChangePadding({ top: String(top) });
  };

  handleChangeRight = (right: number) => {
    this.handleChangePadding({ right: String(right) });
  };

  handleChangeBottom = (bottom: number) => {
    this.handleChangePadding({ bottom: String(bottom) });
  };

  handleChangeLeft = (left: number) => {
    this.handleChangePadding({ left: String(left) });
  };

  render() {
    const [top, right, bottom, left] = this.props.paddingString.split(' ');
    const forceFocus = this.props.forceFocus;
    return (
      <Container>
        <TextContainer>
          <BorderTop />
          <StyledSizeField
            value={Number(top)}
            onSave={this.handleSaveTop}
            onChange={this.handleChangeTop}
            forceFocus={forceFocus}
          />
        </TextContainer>
        <TextContainer>
          <BorderRight />
          <StyledSizeField
            value={Number(right)}
            onSave={this.handleSaveRight}
            onChange={this.handleChangeRight}
            forceFocus={forceFocus}
          />
        </TextContainer>
        <TextContainer>
          <StyledSizeField
            value={Number(bottom)}
            onSave={this.handleSaveBottom}
            onChange={this.handleChangeBottom}
            forceFocus={forceFocus}
          />
          <BorderBottom />
        </TextContainer>
        <TextContainer>
          <BorderLeft />
          <StyledSizeField
            value={Number(left)}
            onSave={this.handleSaveLeft}
            onChange={this.handleChangeLeft}
            forceFocus={forceFocus}
          />
        </TextContainer>
      </Container>
    );
  }
}
