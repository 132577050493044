/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import fonts from 'config/fonts';
import personalizationDefaults from 'config/personalizationDefaults';

import updateFormComponent, {
  type updateFormComponentPropertyType,
} from 'graph/mutations/registration/updateFormComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { Field } from 'components/material/SelectField';
import TextInput from 'components/material/TextInput';

import getFontLabel from '../../../../lib/getFontLabel';
import type { SelectedComponent } from '../../../RegistrationCreateContent';
import {
  defaultButtonTextColor,
  defaultTextColor,
  fontStyles,
  registerButtonCopy,
} from '../../../registrationFormDefaults';
import inputBoxStyled from '../../components/inputBoxStyled';
import PropertyRow from '../../components/PropertyRow';
import RegistrationColorSelector from '../../components/RegistrationColorSelector';
import RegistrationSelectField from '../../components/RegistrationSelectField';
import type { WidthMeasurementType } from '../../components/WidthModifier';
import WidthModifier from '../../components/WidthModifier';
import { AlignmentToggle } from '../../RegistrationPageComponents/components';
import type { AlignmentType } from '../../RegistrationPageComponents/components/AlignmentToggle';

import type { FormFieldsStyling_formComponent } from './__generated__/FormFieldsStyling_formComponent.graphql';
import type { FormFieldsStyling_orgSettings } from './__generated__/FormFieldsStyling_orgSettings.graphql';

const StyledRegistrationSelectField = styled(RegistrationSelectField)`
  width: auto;
  > div:first-child {
    overflow: hidden;
    width: 130px;
    height: 35px;
    padding: 0 10px;
    border-radius: 3px;
    border: 1px solid #d9ddf5;
    font-size: 13px;
    line-height: 1em;
    background: #fff;
    color: #464d72;
    cursor: pointer;

    > i {
      font-size: 18px;
      color: #3e4858;
    }
  }
  ${Field} {
    ${props => inputBoxStyled(props)};
  }
`;

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

class FormFieldsStyling extends React.PureComponent<
  {
    orgSettings: FormFieldsStyling_orgSettings,
    formComponent: FormFieldsStyling_formComponent,
    onChangeSelectedComponentProperty: (updatedProps: SelectedComponent) => void,
  },
  {
    buttonCopy: string,
    fontFamilyFocused: boolean,
    fontStyleFocused: boolean,
  },
> {
  state = {
    buttonCopy: this.props.formComponent.buttonCopy || '',
    fontFamilyFocused: false,
    fontStyleFocused: false,
  };

  updateFormComponent = (properties: updateFormComponentPropertyType) => {
    updateFormComponent({ ...properties, id: this.props.formComponent.id }).catch(
      showModernMutationError,
    );
  };

  handleChangeFont = (fontFamily: ?string) => {
    if (!fontFamily) {
      return;
    }
    this.updateFormComponent({
      fontFamily: { label: getFontLabel(fontFamily), value: fontFamily },
    });
  };

  handleChangeFontStyle = (fontStyle: ?string) => {
    this.updateFormComponent({ fontStyle });
  };

  handleChangeFontColor = (color: ?string) => {
    this.updateFormComponent({ color });
  };

  handleChangeButtonColor = (buttonColor: ?string) => {
    this.updateFormComponent({ buttonColor });
  };

  handleChangeButtonTextColor = (buttonTextColor: ?string) => {
    this.updateFormComponent({ buttonTextColor });
  };

  handleChangeButtonCopy = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ buttonCopy: e.currentTarget.value });
  };

  handleBlurButtonCopy = () => {
    const buttonCopy = this.state.buttonCopy;

    this.updateFormComponent({ buttonCopy });
  };

  handleFontFamilyFocus = () => {
    this.setState({ fontFamilyFocused: true });
  };

  handleFontFamilyUnfocus = () => {
    this.setState({ fontFamilyFocused: false });
  };

  handleFontStyleFocus = () => {
    this.setState({ fontStyleFocused: true });
  };

  handleFontStyleUnfocus = () => {
    this.setState({ fontStyleFocused: false });
  };

  handleSaveWidthOrMeasurement = (
    width?: number,
    widthMeasurement?: WidthMeasurementType,
  ): void => {
    this.updateFormComponent({ width, widthMeasurement });
  };

  handleChangeAlignment = (alignment: AlignmentType) => {
    this.updateFormComponent({ alignment });
  };

  handleChangeWidth = (width: number) => {
    this.props.onChangeSelectedComponentProperty({ selectedComponentWidth: width });
  };

  render() {
    const {
      formComponent: {
        fontFamily,
        fontStyle,
        color,
        buttonColor,
        buttonTextColor,
        width,
        widthMeasurement,
        alignment,
      },
      orgSettings,
    } = this.props;
    const { fontFamilyFocused, fontStyleFocused } = this.state;
    const buttonCopy = this.state.buttonCopy;
    return (
      <div>
        <PropertyRow label="Width">
          <WidthModifier
            width={width}
            widthMeasurement={widthMeasurement}
            onSaveWidthOrMeasurement={this.handleSaveWidthOrMeasurement}
            onChangeWidth={this.handleChangeWidth}
          />
        </PropertyRow>
        <PropertyRow label="Alignment">
          <AlignmentToggle selectedAlignment={alignment} onChange={this.handleChangeAlignment} />
        </PropertyRow>
        <PropertyRow label="Font">
          <StyledRegistrationSelectField
            options={fonts}
            value={fontFamily || orgSettings.font || personalizationDefaults.font}
            focused={fontFamilyFocused}
            onChange={this.handleChangeFont}
            onShowOptions={this.handleFontFamilyFocus}
            onHideOptions={this.handleFontFamilyUnfocus}
          />
        </PropertyRow>
        <PropertyRow label="Font style">
          <StyledRegistrationSelectField
            options={fontStyles}
            value={fontStyle || fontStyles[0].value}
            focused={fontStyleFocused}
            onChange={this.handleChangeFontStyle}
            onShowOptions={this.handleFontStyleFocus}
            onHideOptions={this.handleFontStyleUnfocus}
          />
        </PropertyRow>
        <PropertyRow label="Font color">
          <RegistrationColorSelector
            orgSettings={orgSettings}
            currentColor={color || defaultTextColor}
            onChangeColor={this.handleChangeFontColor}
          />
        </PropertyRow>
        <PropertyRow label="Button color">
          <RegistrationColorSelector
            orgSettings={orgSettings}
            currentColor={buttonColor || orgSettings.primaryColor}
            onChangeColor={this.handleChangeButtonColor}
          />
        </PropertyRow>
        <PropertyRow label="Button text color">
          <RegistrationColorSelector
            orgSettings={orgSettings}
            currentColor={buttonTextColor || defaultButtonTextColor}
            onChangeColor={this.handleChangeButtonTextColor}
          />
        </PropertyRow>
        <PropertyRow label="Button copy" multiline>
          <StyledTextInput
            value={buttonCopy || registerButtonCopy}
            onChange={this.handleChangeButtonCopy}
            onBlur={this.handleBlurButtonCopy}
          />
        </PropertyRow>
      </div>
    );
  }
}

export default createFragmentContainer(FormFieldsStyling, {
  orgSettings: graphql`
    fragment FormFieldsStyling_orgSettings on OrgSettings {
      id
      font
      primaryColor
      ...RegistrationColorSelector_orgSettings
    }
  `,
  formComponent: graphql`
    fragment FormFieldsStyling_formComponent on RegistrationFormComponent {
      id
      fontFamily
      fontStyle
      color
      buttonCopy
      buttonColor
      buttonTextColor
      width
      widthMeasurement
      alignment
    }
  `,
});
