/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import qs from 'qs';

import downloadedBudget from 'utils/analytics/downloadedBudget';
import { type SortParam } from 'utils/routing/parseTypedQueryString';

import CsvDownloadWindow from 'components/budget/CsvDownloadWindow';
import Dropdown from 'components/budget/Dropdown';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import Button from 'components/material/Button';
import Search from 'components/Search';
import ShareDropdown from 'components/ShareDropdown';

import ExpenseSearchSuggestion from './ExpenseSearchSuggestion';

import { type TableHeader_event } from './__generated__/TableHeader_event.graphql';
import { type TableHeader_me } from './__generated__/TableHeader_me.graphql';

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin: -1px 0;
`;

const SearchWrapper = styled.div`
  flex: 1 1 auto;
  padding: 0 30px;
  font-size: 13px;

  > * {
    max-width: 270px;
  }

  &:first-child {
    padding-left: 0;
  }
`;

const DropdownWrapper = styled.div`
  width: 188px;
`;

class TableHeader extends React.PureComponent<
  {|
    event: TableHeader_event,
    me: TableHeader_me,
    view: 'payment' | 'expense',
    onChangeView: (view: 'payment' | 'expense') => void,
    search: string,
    onChangeSearch: (search: string) => void,
    sort: SortParam,
    onAddExpenseClick: () => void,
    tableDataExists: boolean,
    filtered: boolean,
    expensesCount: number,
  |},
  {
    showDownloadOverlay: boolean,
    showDownloadOptions: boolean,
    includePayments: boolean,
  },
> {
  state = {
    showDownloadOverlay: false,
    showDownloadOptions: false,
    includePayments: false,
  };

  handleClickDownload = () => {
    if (this.props.view === 'payment') {
      this.setState({ showDownloadOverlay: true });
    } else {
      this.setState({ showDownloadOptions: true, includePayments: false });
    }
  };

  handleShowDownloadOverlay = () => {
    this.setState({ showDownloadOverlay: true, showDownloadOptions: false });
  };

  handleCsvDownload = () => {
    const { event } = this.props;
    downloadedBudget({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      fromWindow: 'event budget',
    });
  };

  handleHideDownloadOverlay = () => {
    this.setState({ showDownloadOverlay: false });
  };

  handleHideDownloadOptions = () => {
    this.setState({ showDownloadOptions: false });
  };

  handleChangeDownloadOptions = (value: 'includePayments', checked: boolean) => {
    this.setState({ [value]: checked });
  };

  handleChangeView = (view: ?'payment' | 'expense') => {
    if (!view) return;

    this.props.onChangeView(view);
  };

  renderDownloadOverlay = () => {
    const { me, event, view, search, sort } = this.props;
    const urlSearch = qs.stringify(
      {
        query: search,
        sort: sort && sort.key,
        direction: sort && (sort.asc ? 'ASC' : 'DESC'),
        include_payments: this.state.includePayments,
      },
      { addQueryPrefix: true, skipNulls: true },
    );

    if (view === 'payment') {
      return (
        <DownloadOverlayWithEmail
          downloadLink={`/v1/export/${event.slug}/payments${urlSearch}`}
          fileName={`“${event.name}” Payments.csv`}
          onHide={this.handleHideDownloadOverlay}
          email={me.email}
          exportable="payments_csv"
          onDownload={this.handleCsvDownload}
        />
      );
    }

    return (
      <DownloadOverlayWithEmail
        downloadLink={`/v1/export/${event.slug}/expenses${urlSearch}`}
        fileName={`“${event.name}” Expenses.csv`}
        onHide={this.handleHideDownloadOverlay}
        email={me.email}
        exportable="expenses_csv"
        onDownload={this.handleCsvDownload}
      />
    );
  };

  render() {
    const {
      event,
      view,
      search,
      onChangeSearch,
      onAddExpenseClick,
      tableDataExists,
      filtered,
      expensesCount,
    } = this.props;
    const { showDownloadOverlay, showDownloadOptions, includePayments } = this.state;

    const actionOptions = [
      tableDataExists
        ? {
            label: 'Download current view (CSV)',
            icon: 'file-excel-o',
            onClick: this.handleClickDownload,
          }
        : null,
    ].filter(Boolean);

    return (
      <Header>
        {view === 'expense' && (
          <ButtonWrapper>
            <Button label="Add Expense" primary onClick={onAddExpenseClick} />
          </ButtonWrapper>
        )}

        <SearchWrapper>
          {(filtered || tableDataExists) && (
            <Search
              placeholder={`Search ${view === 'expense' ? 'Expenses' : 'Payments'}`}
              onSearch={onChangeSearch}
              search={search}
              key={view}
              suggestionComponent={props => {
                return expensesCount === 0 ? (
                  <ExpenseSearchSuggestion {...props} onAddExpenseClick={onAddExpenseClick} />
                ) : null;
              }}
            />
          )}
        </SearchWrapper>

        {(tableDataExists || view === 'payment' || filtered) && (
          <DropdownWrapper>
            <Dropdown
              value={this.props.view}
              options={[
                { label: 'Expense view', value: 'expense' },
                { label: 'Payment view', value: 'payment' },
              ]}
              onChange={this.handleChangeView}
            />
          </DropdownWrapper>
        )}

        {actionOptions.length > 0 && (
          <ShareDropdown noBorder hoverColor="#4db1dd" options={actionOptions} />
        )}

        {showDownloadOverlay && this.renderDownloadOverlay()}

        {showDownloadOptions && (
          <CsvDownloadWindow
            title={`Download "${event.name}" Expenses`}
            subtitle="Download expenses from this event as a csv spreadsheet."
            options={[
              { label: 'Include Payments', value: 'includePayments', checked: includePayments },
            ]}
            onChange={this.handleChangeDownloadOptions}
            onCancel={this.handleHideDownloadOptions}
            onDownload={this.handleShowDownloadOverlay}
          />
        )}
      </Header>
    );
  }
}

export default createFragmentContainer(
  TableHeader,
  graphql`
    fragment TableHeader_event on Event {
      dbId
      slug
      tz
      name
      team {
        dbId
        name
      }
    }

    fragment TableHeader_me on User {
      email
    }
  `,
);
