/* @flow */
import React from 'react';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { VendorUpdater_vendor } from './__generated__/VendorUpdater_vendor.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

class VendorUpdater extends React.PureComponent<{
  vendor: VendorUpdater_vendor,
}> {
  render() {
    if (this.props.vendor.updatedBy == null) {
      return null;
    }
    return (
      <Container>
        <MaterialAvatar radius={13} user={this.props.vendor.updatedBy} />
        <TextWrapper>{fullNameOfUser(this.props.vendor.updatedBy)}</TextWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(
  VendorUpdater,
  graphql`
    fragment VendorUpdater_vendor on Vendor {
      updatedBy {
        firstName
        lastName
        email
        ...MaterialAvatar_user
      }
    }
  `,
);
