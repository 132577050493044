/* @flow */

import logo from 'images/logo.png';
import logoExpanded from 'images/logo_expanded.png';

const personalizationDefaults = Object.freeze({
  font: 'Roboto',
  logo,
  logoExpanded,
  schedule_name: 'Schedule',
  eventListBgColor: '#5f636a',
});

export default personalizationDefaults;
