/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import Remove from 'images/remove.svg';
import Overlay from 'components/material/Overlay';
import type { OpportunityTableSourceTab } from 'components/Opportunities/OpportunitiesTableColumnSettings';

import type { OpportunityAssociatedContacts_opportunity } from './__generated__/OpportunityAssociatedContacts_opportunity.graphql';

const Container = styled.div`
  width: 100%;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const ContactRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 13px;
`;

const ContactsContainer = styled.div`
  max-height: 255px;
  padding-right: 25px;
  overflow: auto;

  > div {
    padding: 5px 0;
  }
`;

const Role = styled.span`
  color: #3e4859;
`;

const ContactName = styled.div`
  margin-right: 5px;
  color: #3baadd;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-wrap: none;
  text-decoration: none !important;
`;

const ContactsCount = styled.div`
  width: 27px;
  height: 18px;
  margin-left: 5px;
  border-radius: 9px;
  background-color: #ececec;
  text-align: center;
  line-height: 18px;
  color: #3baadd;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledOverlay = styled(Overlay)`
  top: -45px !important;
  left: -1px !important;
  width: auto;
  min-width: 100%;
  padding-left: 20px;
  overflow: auto;
`;

const CloseIcon = styled(Remove)`
  position: absolute;
  top: 10px;
  right: 5px;
  width: 14px;
  height: 14px;
  color: #868f96;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type OpportunityContact = $ElementType<
  $PropertyType<
    $PropertyType<OpportunityAssociatedContacts_opportunity, 'opportunityContacts'>,
    'edges',
  >,
  0,
>;

class OpportunityAssociatedContacts extends React.Component<
  {
    eventSlug?: string,
    opportunity: OpportunityAssociatedContacts_opportunity,
    location: Location,
    fromTab: OpportunityTableSourceTab,
  },
  {
    overlayShown: boolean,
  },
> {
  state = { overlayShown: false };

  containerRef = React.createRef();

  contactPath = (opportunityContact: OpportunityContact) => {
    const { eventSlug, fromTab } = this.props;

    if (fromTab === 'event_contacts_opportunities') {
      return `/events/${eventSlug || ''}/contacts/opportunities/${
        opportunityContact.node.slug
      }/profile`;
    }
    return `/contacts/people/${opportunityContact.node.slug}/profile`;
  };

  handleShowOverlay = () => {
    this.setState({ overlayShown: true });
  };

  handleHideOverlay = () => {
    this.setState({ overlayShown: false });
  };

  renderContact = (opportunityContact: OpportunityContact) => {
    const { location, fromTab } = this.props;

    return (
      <>
        <StyledLink
          to={{
            pathname: this.contactPath(opportunityContact),
            state: {
              from: fromTab,
              fromUrl: `${location && location.pathname ? location.pathname : ''}${
                location && location.search ? location.search : ''
              }`,
            },
          }}
        >
          <ContactName>{fullNameOfUser(opportunityContact.node)}</ContactName>
        </StyledLink>
        {opportunityContact.role && <Role>({opportunityContact.role})</Role>}
      </>
    );
  };

  render() {
    const { opportunity } = this.props;

    if (!opportunity || opportunity.opportunityContacts.edges.length === 0) {
      return null;
    }

    const opportunityContacts = opportunity.opportunityContacts.edges;

    return (
      <Container ref={this.containerRef}>
        <ContactInfo>
          {this.renderContact(opportunityContacts[0])}
          {opportunityContacts.length > 1 && (
            <>
              ,
              <ContactsCount onClick={this.handleShowOverlay}>
                +{opportunityContacts.length - 1}
              </ContactsCount>
            </>
          )}
        </ContactInfo>
        <StyledOverlay
          show={this.state.overlayShown}
          container={document.body}
          target={this.containerRef.current ? this.containerRef.current.parentElement : null}
          onHide={this.handleHideOverlay}
        >
          <CloseIcon onClick={this.handleHideOverlay} />
          <ContactsContainer>
            {opportunityContacts.map(contact => (
              <ContactRow key={contact.node.id}>{this.renderContact(contact)}</ContactRow>
            ))}
          </ContactsContainer>
        </StyledOverlay>
      </Container>
    );
  }
}

export default createFragmentContainer(
  withRouter(OpportunityAssociatedContacts),
  graphql`
    fragment OpportunityAssociatedContacts_opportunity on SalesforceOpportunityType {
      opportunityContacts {
        edges {
          role
          node {
            id
            slug
            firstName
            lastName
          }
        }
      }
    }
  `,
);
