/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatDuration from 'utils/number/formatDuration';

import { type CellPropsType } from 'components/budget/Table';

import { type EventCellPropsType } from './index';

import type { EventAttendanceDuration_contactEvent } from './__generated__/EventAttendanceDuration_contactEvent.graphql';

const EventAttendanceDuration = ({
  contactEvent,
}: CellPropsType<EventCellPropsType<EventAttendanceDuration_contactEvent>>) => {
  const duration =
    (contactEvent.zoomRegistrant && contactEvent.zoomRegistrant.duration) ||
    (contactEvent.g2wRegistrant && contactEvent.g2wRegistrant.duration) ||
    (contactEvent.ibmWmRegistrant && contactEvent.ibmWmRegistrant.duration);

  if (duration == null) return null;

  return formatDuration(duration);
};

export default createFragmentContainer(
  EventAttendanceDuration,
  graphql`
    fragment EventAttendanceDuration_contactEvent on ContactEventEdge {
      zoomRegistrant {
        duration
      }
      g2wRegistrant {
        duration
      }
      ibmWmRegistrant {
        duration
      }
    }
  `,
);
