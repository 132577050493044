/* @flow */
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: baseline;
  margin: 20px 0;
  color: #dd3b3b;
  i {
    margin-right: 10px;
  }
`;

export default class EditableQuestionErrors extends React.PureComponent<{
  errors: $ReadOnlyArray<string>,
}> {
  render() {
    if (this.props.errors.length === 0) return null;

    return (
      <Container>
        <i className="fa fa-fw fa-warning" />
        <div>
          {this.props.errors.map(error => (
            <div key={error}>{error}</div>
          ))}
        </div>
      </Container>
    );
  }
}
