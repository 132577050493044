/**
 * @flow
 * @relayHash aa57b81757cedc5475ba384b17ff9d1e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RegistrationFormTemplate_query$ref = any;
export type RegistrationFormTemplatesQueryVariables = {||};
export type RegistrationFormTemplatesQueryResponse = {|
  +query: {|
    +$fragmentRefs: RegistrationFormTemplate_query$ref
  |}
|};
export type RegistrationFormTemplatesQuery = {|
  variables: RegistrationFormTemplatesQueryVariables,
  response: RegistrationFormTemplatesQueryResponse,
|};
*/


/*
query RegistrationFormTemplatesQuery {
  query {
    ...RegistrationFormTemplate_query
  }
}

fragment RegistrationFormTemplate_query on Query {
  org {
    id
    registrationForm {
      eventName
      updatedAt
      id
    }
    settings {
      tz
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RegistrationFormTemplatesQuery",
  "id": null,
  "text": "query RegistrationFormTemplatesQuery {\n  query {\n    ...RegistrationFormTemplate_query\n  }\n}\n\nfragment RegistrationFormTemplate_query on Query {\n  org {\n    id\n    registrationForm {\n      eventName\n      updatedAt\n      id\n    }\n    settings {\n      tz\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RegistrationFormTemplatesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RegistrationFormTemplate_query",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RegistrationFormTemplatesQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "registrationForm",
                "storageKey": null,
                "args": null,
                "concreteType": "RegistrationForm",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "eventName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "updatedAt",
                    "args": null,
                    "storageKey": null
                  },
                  v0
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "OrgSettings",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "tz",
                    "args": null,
                    "storageKey": null
                  },
                  v0
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1b27d728d2e8198f734e91d18d450186';
module.exports = node;
