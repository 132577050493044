/* @flow */
const getContainer = () => {
  const containerId = 'text-size-calculator';

  const existingEl = document.getElementById(containerId);
  if (existingEl) {
    return existingEl;
  }

  const el = document.createElement('div');
  el.id = containerId;
  el.style.display = 'inline-block';
  el.style.visibility = 'hidden';
  el.style.position = 'absolute';
  el.style.top = '0';
  el.style.pointerEvents = 'none';
  if (document.body) document.body.appendChild(el);

  return el;
};

export default function(str: string) {
  if (!str.trim()) return 0;

  const container = getContainer();
  container.textContent = str;
  return container.clientWidth;
}
