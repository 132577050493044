/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import SearchResultContainer from './components/SearchResultContainer';

import type { EventNoteSearchResult_note } from './__generated__/EventNoteSearchResult_note.graphql';

const NoteTitle = styled.div`
  color: #3e4859;
`;

const EventName = styled.div`
  font-size: 11px;
  color: rgba(62, 72, 89, 0.76);
`;

type Props = { note: EventNoteSearchResult_note };

const EventNoteSearchResult = ({ note: { noteId, name, eventName, eventSlug } }: Props) => {
  return (
    <SearchResultContainer path={`/events/${eventSlug}/notes/${noteId}`}>
      <NoteTitle>{name}</NoteTitle>
      <EventName>{eventName}</EventName>
    </SearchResultContainer>
  );
};

export default createFragmentContainer(
  EventNoteSearchResult,
  graphql`
    fragment EventNoteSearchResult_note on EventNoteSearchResult {
      noteId
      name
      eventName
      eventSlug
    }
  `,
);
