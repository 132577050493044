/* @flow */
import flatten from 'lodash/flatten';

import determineCustomFieldMinWidth from 'utils/customization/determineCustomFieldMinWidth';
import type { FieldType } from 'utils/customization/types';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import { type Event as EventType } from 'graph/mutations/event/updateEvent';

import { fieldFilterMap } from 'components/AllEvents/lib/renderEventFilter';
import { type ColumnType } from 'components/budget/Table';
import kindToTableComponent from 'components/Customizable/kindToTableComponent';
import type { Filter } from 'components/material/ColumnFilters/columnFilterTypes';

import { defaultTableComponents, EventActions } from './columns';

export const getDefaultFilters = (filters: {}) => {
  const defaultFilterKeys = flatten(
    Object.entries(fieldFilterMap).map(filterMap =>
      filterMap[1] != null &&
      typeof filterMap[1] === 'object' &&
      Object.prototype.hasOwnProperty.call(filterMap[1], 'filter')
        ? [
            filterMap[1].filter,
            Object.prototype.hasOwnProperty.call(filterMap[1], 'secondFilter')
              ? // $FlowFixMe
                filterMap[1].secondFilter
              : null,
          ]
        : null,
    ),
  ).filter(Boolean);
  return Object.entries(filters).reduce((defaultFilters, filter) => {
    if (defaultFilterKeys.includes(filter[0])) {
      return { ...defaultFilters, [filter[0]]: filter[1] };
    }
    return defaultFilters;
  }, {});
};

export type EventCellPropsType<Org: {} = {}, User: ?{} = {}, Event: {} = {}> = {|
  org: Org,
  user: User,
  event: Event,
  customizable: Event,
  fieldSettings: ?FieldType,
  salesforceHost: ?string,
  linkEventNameToBrief?: boolean,
  readOnly?: boolean,
  userEmail?: ?string,
  isLast?: boolean,
  tz: string,
  onUpdate?: (args: $Shape<EventType>) => Promise<void>,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    args: UpdateCustomFieldValueInput,
  ) => Promise<void>,
|};

export const suggestedFields = ['types', 'roles'];

export const defaultColumns = [
  'name',
  'team',
  'event_staff',
  'start_date',
  'status',
  'progress',
  'website',
];

export default (props: {
  eventFields: $ReadOnlyArray<FieldType>,
  shownColumns: $ReadOnlyArray<string>,
  skipActions?: boolean,
}): $ReadOnlyArray<ColumnType<any, any>> => {
  const { eventFields, shownColumns, skipActions } = props;
  return [
    ...eventFields
      .filter(({ fieldName, id }) =>
        suggestedFields.includes(fieldName)
          ? shownColumns.includes(id)
          : shownColumns.includes(fieldName || id),
      )
      .map(eventField => {
        if (eventField.fieldName != null && !suggestedFields.includes(eventField.fieldName)) {
          return {
            title: eventField.label,
            fieldSettings: eventField,
            component: defaultTableComponents[eventField.fieldName],
            sortKey: !eventField.unsortable ? eventField.id.toUpperCase() : undefined,
            align: eventField.align,
            minWidth: eventField.minWidth,
            ...(eventField.fieldName === 'name' ? { grow: 1, fixedLeft: 41 } : null),
          };
        }

        return {
          title: eventField.label,
          fieldSettings: eventField,
          sortKey: eventField.id,
          align: ['CURRENCY', 'NUMBER'].includes(eventField.kind) ? 'right' : 'left',
          component: kindToTableComponent[eventField.kind],
          minWidth: determineCustomFieldMinWidth(eventField.kind),
        };
      }),
    !skipActions
      ? {
          title: '',
          type: 'action',
          component: EventActions,
        }
      : null,
  ].filter(Boolean);
};

export function getColumnsShowConfig(
  eventFields: $ReadOnlyArray<FieldType>,
): $ReadOnlyArray<Filter> {
  return eventFields.map(eventField => {
    const value =
      suggestedFields.includes(eventField.fieldName) || !eventField.fieldName
        ? eventField.id
        : eventField.fieldName;
    return {
      value,
      label: eventField.label,
      default: defaultColumns.includes(eventField.fieldName),
      groupId: eventField.groupId,
      sticky: eventField.fieldName === 'name',
    };
  });
}
