/* @flow */
import * as React from 'react';

import type { FieldType } from 'utils/customization/types';
import renderFilterByKind from 'utils/filters/renderFilterByKind';

import CheckFilterItem from 'components/material/Filters/Advanced/CheckFilterItem';
import ContactFilterItem from 'components/material/Filters/Advanced/ContactFilterItem';
import { type FilterValueChangeParam } from 'components/material/Filters/Advanced/FilterItems';
import RangeFilterItem from 'components/material/Filters/Advanced/RangeFilterItem';
import TextFilterItem from 'components/material/Filters/Advanced/TextFilterItem';
import UserFilterItem from 'components/material/Filters/Advanced/UserFilterItem';
import type { ParsedOpportunitiesFilters } from 'components/Opportunities/lib/parseOpportunitiesFilters';

export default function renderOpportunityFilter(
  customField: FieldType,
  filters: ParsedOpportunitiesFilters,
  onFilterChange: (name: string, value: FilterValueChangeParam) => void,
): React.Node {
  switch (customField.fieldName) {
    case 'amount':
      return (
        <RangeFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValue={filters[customField.fieldName]}
          autoFocus
          isCurrency
        />
      );
    case 'name':
    case 'salesforce_id':
      return (
        <TextFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
        />
      );
    case 'owner_id':
      return (
        <UserFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
        />
      );
    case 'associated_contacts':
      return (
        <ContactFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
        />
      );
    case 'associated_contacts_roles':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          options={customField.options}
          onChange={onFilterChange}
          activeValues={filters.associated_contacts_roles}
          unsorted
        />
      );
    case 'status':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          options={customField.options}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
          unsorted
        />
      );
    default:
      return renderFilterByKind(customField, filters[customField.id], onFilterChange);
  }
}
