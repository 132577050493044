/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

const mutation = graphql`
  mutation addTaskCompanyMutation($input: AddDeliverableCompanyInput!) {
    addDeliverableCompany(input: $input) {
      companyEdge {
        __typename
        node {
          id
          name
        }
      }
    }
  }
`;

export default function addTaskCompany(taskId: string, companyId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableId: taskId,
        companyId,
      },
    },
    updater: addRecordToConnection({
      parentId: taskId,
      edgeName: 'companyEdge',
      connections: [{ field: 'companies' }],
    }),
  });
}
