/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import submissionStatuses from '../../lib/submissionStatuses';

import type { RequestSubmissionStatus_requestSubmission } from './__generated__/RequestSubmissionStatus_requestSubmission.graphql';

const StatusItem = styled.div`
  display: flex;
  align-items: center;
`;

const StatusIndicator = styled.div`
  width: 9px;
  height: 9px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;

const StyledLink = styled(Link)`
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

type Props = {
  requestSubmission: RequestSubmissionStatus_requestSubmission,
  location: Location,
  readOnly?: boolean,
};

const stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
  e.stopPropagation();
};

const RequestSubmissionStatus = ({ requestSubmission, location, readOnly }: Props) => {
  const { status } = requestSubmission;
  const statusConfig = submissionStatuses.find(s => s.value === status);

  if (!statusConfig) return null;

  return (
    <StatusItem>
      {statusConfig.notify && <StatusIndicator color={statusConfig.color} />}
      {readOnly ? (
        statusConfig.label
      ) : (
        <StyledLink
          to={`/dashboard/event_requests/${requestSubmission.id}${location.search}`}
          onClick={stopPropagation}
        >
          {statusConfig.label}
        </StyledLink>
      )}
    </StatusItem>
  );
};

export default createFragmentContainer(
  withRouter(RequestSubmissionStatus),
  graphql`
    fragment RequestSubmissionStatus_requestSubmission on EventRequestSubmission {
      id
      status
    }
  `,
);
