/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';

import { type FormSubmissionFn } from 'components/ContactForm/ContactFormSubmission';
import type { RegistrationFormDefaultValues } from 'components/ContactForm/lib/types';

import pageComponentsConfig, {
  type ContentComponentType,
  type PageComponentKindType,
} from '../../RegistrationCreate/pageComponentsConfig';
import generatePaddingProps from '../../RegistrationCreate/RegistrationPageContent/generatePaddingProps';
import RegistrationFormComponent from './RegistrationFormComponent';

import { type SharedColumnComponent_componentProps } from './__generated__/SharedColumnComponent_componentProps.graphql';
import { type SharedColumnComponent_org } from './__generated__/SharedColumnComponent_org.graphql';
import { type SharedColumnComponent_registrationForm } from './__generated__/SharedColumnComponent_registrationForm.graphql';

const Container = styled.div`
  flex: 1 0 auto;
  width: calc(${props => props.componentProps.width}% - ${props => props.cellSpacing / 2}px);
  padding: ${props => generatePaddingProps(props.componentProps.padding)};

  @media (max-width: 480px) {
    width: 100%;
  }
`;

class SharedColumnComponent extends React.PureComponent<
  {
    org: SharedColumnComponent_org,
    registrationForm: SharedColumnComponent_registrationForm,
    componentProps: SharedColumnComponent_componentProps,
    onSubmit: FormSubmissionFn,
    onFinishSubmit: () => void,
    cellSpacing: number,
    className?: string,
    viewMode?: boolean,
    preview?: boolean,
    email?: string,
    lockEmail?: boolean,
    defaultFormValues: RegistrationFormDefaultValues,
    location: Location,
  },
  {
    draggedComponentId: ?string,
  },
> {
  getContentComponent = (kind: PageComponentKindType): ?ContentComponentType => {
    if (kind === 'COLUMN') {
      return null;
    }
    const currentComponent = pageComponentsConfig.find(
      pageComponent => pageComponent.kind === kind,
    );
    if (!currentComponent) {
      return RegistrationFormComponent;
    }
    return currentComponent.contentComponent;
  };

  render() {
    const {
      org,
      registrationForm,
      componentProps: { columnComponent, columnChild, order },
      className,
      viewMode,
      onSubmit,
      cellSpacing,
      onFinishSubmit,
      preview,
      email,
      lockEmail,
      defaultFormValues,
      location,
    } = this.props;

    if (!columnComponent) {
      return null;
    }

    const childComponent = columnChild || {
      id: `child${order}`,
      order,
      kind: 'COLUMN',
    };

    const Component = this.getContentComponent(childComponent.kind);
    return (
      <Container componentProps={columnComponent} cellSpacing={cellSpacing} className={className}>
        {childComponent.kind === 'FORM' ? (
          <RegistrationFormComponent
            registrationForm={registrationForm}
            componentProps={childComponent}
            org={org}
            onSubmit={onSubmit}
            onFinishSubmit={onFinishSubmit}
            viewMode={viewMode}
            email={email}
            lockEmail={lockEmail}
            defaultFormValues={defaultFormValues}
            location={location}
          />
        ) : (
          Component && (
            <Component
              readOnly
              registrationForm={registrationForm}
              componentProps={childComponent}
              viewMode={viewMode}
              preview={preview}
            />
          )
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(SharedColumnComponent, {
  org: graphql`
    fragment SharedColumnComponent_org on Org {
      ...RegistrationFormComponent_org
    }
  `,
  registrationForm: graphql`
    fragment SharedColumnComponent_registrationForm on RegistrationForm {
      ...EventNameComponent_registrationForm
      ...EventDatesComponent_registrationForm
      ...RegistrationFormComponent_registrationForm
    }
  `,
  componentProps: graphql`
    fragment SharedColumnComponent_componentProps on RegistrationPageComponent {
      id
      kind
      order
      parent {
        id
        order
        childPageComponents {
          edges {
            node {
              id
              order
              columnChild {
                id
              }
            }
          }
        }
      }
      columnChild {
        id
        order
        kind
        ...ImageComponent_componentProps
        ...EventNameComponent_componentProps
        ...EventDatesComponent_componentProps
        ...TextComponent_componentProps
        ...DividerComponent_componentProps
        ...EmbedComponent_componentProps
        ...VideoComponent_componentProps
        ...RegistrationFormComponent_componentProps
      }
      columnComponent {
        id
        width
        padding
      }
    }
  `,
});
