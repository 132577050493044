/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Window, { WindowContent } from 'components/material/Window';
import Button from 'components/material/Button';
import EventMultiselect from 'components/EventMultiselect';

const Title = styled.div`
  margin: 40px 0 36px 0;
  font-size: 17px;
  color: #96a2ab;
  font-weight: 500;
  text-align: left;

  span {
    color: ${props => props.theme.headerRowColor};
  }
`;

const TitleInfo = styled.div`
  margin-top: 3px;
  margin-bottom: 30px;
  font-size: 12px;
  color: #96a2ab;
  text-align: center;
`;

const Buttons = styled.div`
  margin-top: 38px;
  text-align: right;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

export default class AddToEventsWindow extends React.Component<
  {
    contactName?: string,
    contactsCount?: number,
    onCancel: () => void,
    onSave: (eventIds: $ReadOnlyArray<string>) => void,
  },
  {
    selectedEvents: $ReadOnlyArray<string>,
  },
> {
  state = {
    selectedEvents: [],
  };

  handleClickPropagation = (e: SyntheticEvent<>) => {
    e.stopPropagation();
  };

  handleAddButtonClick = () => {
    if (this.state.selectedEvents.length > 0) {
      this.props.onSave(this.state.selectedEvents);
    }
    this.props.onCancel();
  };

  handleSelectedEventsUpdate = (selectedEvents: $ReadOnlyArray<string>) => {
    this.setState({ selectedEvents });
  };

  render() {
    const { contactName, contactsCount = 0, onCancel } = this.props;
    return (
      <Window size="medium" onHide={onCancel} onClick={this.handleClickPropagation}>
        <WindowContent>
          <Title>
            {contactName && (
              <React.Fragment>
                Add <span>{contactName}</span> to Events
              </React.Fragment>
            )}
            {contactsCount > 0 && (
              <React.Fragment>
                Add contacts to <span>Event</span>
                <TitleInfo>
                  {contactsCount} contact
                  {contactsCount > 1 && 's'} selected
                </TitleInfo>
              </React.Fragment>
            )}
          </Title>
          <EventMultiselect
            updateSelectedEvents={this.handleSelectedEventsUpdate}
            selectedEvents={this.state.selectedEvents}
            label=""
          />
          <Buttons>
            <Button minimal label="Cancel" onClick={onCancel} />
            <SubmitButton
              primary
              disabled={this.state.selectedEvents.length === 0}
              label={
                this.state.selectedEvents.length > 1
                  ? `Add to ${this.state.selectedEvents.length} Events`
                  : 'Add to Event'
              }
              onClick={this.handleAddButtonClick}
            />
          </Buttons>
        </WindowContent>
      </Window>
    );
  }
}
