import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: flex;
  margin: 25px 0 0 15px;
  cursor: pointer;
  color: #39a8da;

  &:hover {
    color: #009dce;
    text-decoration: underline;
  }
`;

const LeftButton = styled.div`
  text-align: center;
  font-size: 22px;
  line-height: 22px;
  cursor: pointer;
`;

export default function BackLink(props: { path: string, label: string, className?: string }) {
  return (
    <StyledLink className={props.className} to={props.path}>
      <LeftButton>
        <i className="fa fa-fw fa-angle-left" />
      </LeftButton>
      {props.label}
    </StyledLink>
  );
}
