/* @flow */

export const eventInfoSectionLabels = {
  BASICS: 'Basics',
  BOOTH: 'Floor Plan',
  BUDGET: 'Budget',
  CRM: 'CRM',
};

export const eventInfoFieldLabels = {
  STATUS: 'Status',
  PROGRESS: 'Progress',
  DESCRIPTION: 'Description',
  LEADER: 'Leader',
  BOOTH_NUMBER: 'Booth #',
  DIMENSIONS: 'Dimensions',
  FLOOR_PLAN: 'Floor Plan',
  PLANNED_COST: 'Planned Total',
  ACTUAL_COST: 'Actual Total',
  SYNC_STATUS: 'CRM Sync Status',
  LAST_SYNCED: 'CRM Last Sync',
  SALESFORCE_ID: 'CRM Campaign ID',
  MARKETO_ID: 'Marketo Program ID',
  NUMBER_OF_OPPORTUNITIES: 'Opportunities',
  AMOUNT_ALL_OPPORTUNITIES: 'Amount of Opportunities',
};
