/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Location, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import columns from './columns';
import ImportLogsTableHeader from './ImportLogsTableHeader';
import ImportLogsTableList from './ImportLogsTableList';
import parseImportLogsFilters from './lib/parseImportLogsFilters';

import type { ImportLogsTableQueryResponse } from './__generated__/ImportLogsTableQuery.graphql';

const Table = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  margin-top: 15px;
  border: 1px solid ${props => props.theme.primaryRowColor};
`;

const TableScroll = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
`;

const query = graphql`
  query ImportLogsTableQuery(
    $count: Int!
    $cursor: String
    $type: ImportType!
    $sort: ImportLogSort
    $direction: Direction!
  ) {
    org {
      ...ImportLogsTableList_org
      ...ImportLogsTableList_totalCountOrg
    }
    me {
      tz
    }
  }
`;

class ImportLogsTable extends React.PureComponent<
  {
    location: Location,
    type: $Keys<typeof ImportLogsTable.CONFIG>,
  },
  {
    vScrollbarSize: number,
    hScrollbarSize: number,
  },
> {
  state = {
    vScrollbarSize: 0,
    hScrollbarSize: 0,
  };

  scrollContainer: ?HTMLElement;

  handleScrollbarSizesChange = (sizes: { vScrollbarSize: number, hScrollbarSize: number }) => {
    this.setState(sizes);
  };

  static CONFIG = {
    EVENTS: {
      successCountMessage: 'Imported events',
      errorsCountMessage: 'Events with errors',
      revertConfirmationMessage:
        'This will permanently delete all the Events that were imported from this file.',
    },
    EXPENSES: {
      successCountMessage: 'Imported expenses',
      errorsCountMessage: 'Expenses with errors',
      revertConfirmationMessage:
        'This will permanently delete all the Expenses that were imported from this file.',
    },
  };

  render() {
    const { type, location } = this.props;
    const filters = parseImportLogsFilters(location.search);
    const sortFilter = filters.sort || {
      key: 'CREATED_AT',
      asc: false,
    };

    const variables = {
      count: 50,
      type,
      sort: sortFilter.key,
      direction: sortFilter.asc ? 'ASC' : 'DESC',
    };

    // Sum of column widths + 10px padding for each column + 50 for actions column
    const shownColumns = columns.map(column => {
      if (column.key === 'SUCCESS_COUNT') {
        return { ...column, label: ImportLogsTable.CONFIG[type].successCountMessage };
      }

      if (column.key === 'ERRORS_COUNT') {
        return { ...column, label: ImportLogsTable.CONFIG[type].errorsCountMessage };
      }

      return column;
    });
    const minWidth = shownColumns.reduce((width, col) => width + col.width + 10, 0) + 50;

    return (
      <Table>
        <TableScroll
          ref={el => {
            this.scrollContainer = el;
          }}
        >
          <ImportLogsTableHeader columns={shownColumns} sort={sortFilter} minWidth={minWidth} />

          <DefaultQueryRenderer
            query={query}
            variables={variables}
            renderSuccess={(props: ImportLogsTableQueryResponse) => (
              <ImportLogsTableList
                org={props.org}
                totalCountOrg={props.org}
                tz={props.me.tz}
                columns={shownColumns}
                minWidth={minWidth}
                vScrollbarSize={this.state.vScrollbarSize}
                hScrollbarSize={this.state.hScrollbarSize}
                onScrollbarSizesChange={this.handleScrollbarSizesChange}
                scrollContainer={this.scrollContainer}
                revertConfirmationMessage={ImportLogsTable.CONFIG[type].revertConfirmationMessage}
              />
            )}
          />
        </TableScroll>
      </Table>
    );
  }
}

export default withRouter(ImportLogsTable);
