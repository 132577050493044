/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import { isBright } from 'utils/color';
import personalizationDefaults from 'config/personalizationDefaults';

import type { SuccessWindow_org } from './__generated__/SuccessWindow_org.graphql';

const Container = styled.div`
  padding-top: 40px;
  text-align: center;
`;

const Logo = styled.img`
  max-width: 220px;
  max-height: 110px;
  margin-bottom: 30px;
`;

const Body = styled.div`
  padding-top: 97px;
  padding-bottom: 167px;
  background-color: ${props => props.color};
  color: ${props => (isBright(props.color) ? '#4a5665' : '#fff')};
`;

const CheckIcon = styled.i`
  margin-bottom: 50px;
  padding: 0 12px;
  border: 3px solid;
  border-radius: 50%;
  font-size: 40px;
`;

const Text = styled.div`
  padding: 0 30px;
  font-size: 18px;
  line-height: 1.45;
  text-align: center;
`;

class SuccessWindow extends React.Component<{
  org: SuccessWindow_org,
  formLogo: ?string,
  submitMessage: string,
}> {
  render() {
    const {
      formLogo,
      org: { settings },
      submitMessage,
    } = this.props;
    return (
      <Container>
        <Logo src={formLogo || settings.logo || personalizationDefaults.logoExpanded} />
        <Body color={settings.primaryColor}>
          <CheckIcon className="fa fa-check" />
          <Text>{submitMessage}</Text>
        </Body>
      </Container>
    );
  }
}

export default createFragmentContainer(
  SuccessWindow,
  graphql`
    fragment SuccessWindow_org on Org {
      settings {
        primaryColor
        logo
      }
    }
  `,
);
