/* @flow */
import moment from 'moment-timezone';

export default function formatTime(
  date: moment,
  { hideTz, hidePeriod }: { hideTz?: boolean, hidePeriod?: boolean } = {
    hideTz: false,
    hidePeriod: false,
  },
): string {
  return date.format(`h:mm${hidePeriod ? '' : ' a'}${hideTz ? '' : ' z'}`);
}
