/**
 * @flow
 * @relayHash 98d7779f33128379e91870bfe567cbd2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamsPage_me$ref = any;
type TeamsPage_org$ref = any;
export type TeamsQueryVariables = {||};
export type TeamsQueryResponse = {|
  +org: {|
    +$fragmentRefs: TeamsPage_org$ref
  |},
  +me: {|
    +$fragmentRefs: TeamsPage_me$ref
  |},
|};
export type TeamsQuery = {|
  variables: TeamsQueryVariables,
  response: TeamsQueryResponse,
|};
*/


/*
query TeamsQuery {
  org {
    ...TeamsPage_org
    id
  }
  me {
    ...TeamsPage_me
    id
  }
}

fragment TeamsPage_org on Org {
  ...TeamsHeader_org
}

fragment TeamsPage_me on User {
  ...TeamsHeader_me
}

fragment TeamsHeader_me on User {
  id
}

fragment TeamsHeader_org on Org {
  viewerCanCreateTeams
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamsQuery",
  "id": null,
  "text": "query TeamsQuery {\n  org {\n    ...TeamsPage_org\n    id\n  }\n  me {\n    ...TeamsPage_me\n    id\n  }\n}\n\nfragment TeamsPage_org on Org {\n  ...TeamsHeader_org\n}\n\nfragment TeamsPage_me on User {\n  ...TeamsHeader_me\n}\n\nfragment TeamsHeader_me on User {\n  id\n}\n\nfragment TeamsHeader_org on Org {\n  viewerCanCreateTeams\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TeamsPage_org",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TeamsPage_me",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanCreateTeams",
            "args": null,
            "storageKey": null
          },
          v0
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e9ebca169f391ae20a8314414f1fb36d';
module.exports = node;
