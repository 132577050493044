/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { type CircaFile } from 'utils/uploading/types';

import TextField from 'components/material/TextField';
import AttachmentPreview from 'components/RelayAttachments/AttachmentPreview';
import RichText, {
  type SupportInlineImage,
  Container as RichTextContainer,
  ContentWrapper,
  EditorContainer,
  EditorPanel,
} from 'components/RichText';
import ImageUploadingStatus, { type Status } from 'components/RichText/Image/ImageUploadingStatus';

import EditInfo from './components/EditInfo';

import NoteForm_eventNote from './__generated__/NoteForm_eventNote.graphql';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
  padding: 55px 0 0 7px;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  ${RichTextContainer} {
    display: flex;
    position: static;
    height: 100%;
    flex-direction: column;
    > div {
      flex: 1 1 auto;
    }
  }
  ${ContentWrapper} {
    height: 100%;
    overflow-y: auto;
    padding-right: 56px;
    padding-bottom: 30px;
    > div {
      max-width: 900px;
    }
  }
  ${EditorContainer} {
    box-shadow: none;
    :hover {
      background: none;
    }
  }
  ${EditorPanel} {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Title = styled.div`
  margin: 0 0 5px;
  padding: 0;
  color: ${props => props.theme.darkestTextColor};
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
`;

const StyledTextField = styled(TextField)`
  margin: 0 0 5px;
  > textarea {
    padding: 0;
    color: ${props => props.theme.darkestTextColor};
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    border-bottom-width: 0;
    :hover,
    :focus {
      border-bottom-width: 0;
    }
  }
`;

const EditInfoContainer = styled.div`
  position: absolute;
  bottom: -20px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  background-color: #fff;
`;

type Errors = {
  name?: ?string,
  description?: ?string,
};

const TITLE_FIELD_ID = 'NoteTitle';
const DESCRIPTION_FIELD_ID = 'NoteDescription';
const DESCRIPTION_EDITOR_CLASS = 'public-DraftEditor-content';

class NoteForm extends React.Component<
  {
    eventNote: NoteForm_eventNote,
    onSave: (name: string, description: string, attachments?: $ReadOnlyArray<CircaFile>) => void,
    defaultName?: string,
    defaultDescription?: string,
    attachments?: $ReadOnlyArray<CircaFile>,
    onImageAdd?: CircaFile => void,
    onRemoveImages?: ($ReadOnlyArray<CircaFile>) => void,
    canUpdate: boolean,
    tz: string,
  },
  {
    errors: Errors,
    description: string,
    attachments: $ReadOnlyArray<CircaFile>,
    previewId: string,
    imageUploadingStatus: Status,
    descriptionShouldGetFocus: boolean,
  },
> {
  name: HTMLInputElement;

  supportInlineImage: SupportInlineImage;

  constructor(props: $PropertyType<NoteForm, 'props'>) {
    super(props);

    this.state = {
      errors: {},
      description: this.props.defaultDescription || '',
      attachments: this.props.attachments || [],
      previewId: '',
      imageUploadingStatus: { text: '', isError: false },
      descriptionShouldGetFocus: false,
    };
    this.supportInlineImage = this.constructRichSupportInlineImage();
  }

  handleNameChange = () => {
    if (this.state.errors.name && this.name.value) {
      this.setState(prevState => ({ errors: { ...prevState.errors, name: null } }));
    }
  };

  handleSave = (content: ?string, editorImages: ?$ReadOnlyArray<string>) => {
    const description = content != null ? content : this.state.description;
    const name = this.name.value.trim();

    const attachments = this.state.attachments.filter(
      attachment => !editorImages || editorImages.includes(attachment.fileurl),
    );
    const removableAttachments = this.state.attachments.filter(
      attachment => editorImages && !editorImages.includes(attachment.fileurl),
    );

    if (this.props.onRemoveImages != null) {
      this.props.onRemoveImages(removableAttachments);
    }

    const errors = {};
    if (!name) {
      errors.name = 'Required';
      this.setState({ description, errors });
      return;
    }
    this.setState({ descriptionShouldGetFocus: false });
    this.props.onSave(name, description, attachments);
  };

  handleTitleBlur = (e: SyntheticFocusEvent<HTMLElement>) => {
    const clickedElement = e.relatedTarget;
    if (
      clickedElement != null &&
      clickedElement instanceof HTMLElement &&
      // $FlowFixMe have to disable flow for this line, since getting 'indexer property is missing in DomTokenList'
      (clickedElement.classList[1] === DESCRIPTION_EDITOR_CLASS ||
        clickedElement.id === DESCRIPTION_FIELD_ID)
    ) {
      return;
    }
    this.handleSave();
  };

  handleDescriptionBlur = (description: string) => {
    this.setState({ description, descriptionShouldGetFocus: false });
  };

  handleDescriptionChange = (description: string) => {
    if (this.state.errors.description && description.length >= 5) {
      this.setState(prevState => ({ errors: { ...prevState.errors, description: null } }));
    }
  };

  handleImageAdd = (file: CircaFile) => {
    this.setState(prevState => ({
      attachments: [...prevState.attachments, { id: file.fileurl, ...file }],
    }));

    if (this.props.onImageAdd != null) {
      this.props.onImageAdd(file);
    }
  };

  handlePreview = (previewId: string) => {
    this.setState({ previewId });
  };

  handleStatusChange = (text: string, isError: boolean = false) => {
    const imageUploadingStatus = { text, isError };
    this.setState({ imageUploadingStatus });
  };

  constructRichSupportInlineImage = (): SupportInlineImage => {
    return {
      onImageAdd: this.handleImageAdd,
      onImagePreview: this.handlePreview,
      setImageUploadingStatus: this.handleStatusChange,
    };
  };

  handleTitleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.setState({ descriptionShouldGetFocus: true });
    }
  };

  render() {
    const {
      tz,
      eventNote: { updatedAt, createdAt, creator, updater },
      defaultName,
      canUpdate,
    } = this.props;
    return (
      <Container>
        {this.props.canUpdate ? (
          <StyledTextField
            autoFocus
            inputRef={n => {
              this.name = n;
            }}
            id={TITLE_FIELD_ID}
            defaultValue={defaultName}
            onChange={this.handleNameChange}
            onKeyDown={this.handleTitleKeyDown}
            error={this.state.errors.name}
            onBlur={this.handleTitleBlur}
            placeholder="Note Title..."
            multiline
          />
        ) : (
          <Title>{defaultName}</Title>
        )}
        <DescriptionContainer>
          {canUpdate && <ImageUploadingStatus status={this.state.imageUploadingStatus} />}
          <RichText
            defaultValue={this.state.description}
            onSave={this.handleSave}
            onChange={this.handleDescriptionChange}
            onBlur={this.handleDescriptionBlur}
            formRelatedField={TITLE_FIELD_ID}
            onImageAdd={this.handleImageAdd}
            onImagePreview={this.handlePreview}
            placeholder="Note Description..."
            viewMode={!canUpdate}
            hideActionButtons
            supportInlineImage={this.supportInlineImage}
            setImageUploadingStatus={this.handleStatusChange}
            shouldGetFocus={this.state.descriptionShouldGetFocus}
          />
        </DescriptionContainer>
        <EditInfoContainer>
          <EditInfo label="Updated" date={updatedAt} user={updater} timezone={tz} />
          <EditInfo label="Created" date={createdAt} user={creator} timezone={tz} />
        </EditInfoContainer>
        {this.state.previewId && (
          <AttachmentPreview
            attachments={[]}
            mockAttachments={this.state.attachments}
            previewId={this.state.previewId}
            onPreview={this.handlePreview}
            onRemove={null}
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  NoteForm,
  graphql`
    fragment NoteForm_eventNote on EventNote {
      id
      createdAt
      updatedAt
      creator {
        firstName
        lastName
        email
      }
      updater {
        firstName
        lastName
        email
      }
    }
  `,
);
