/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import { type CategoryFooterCellPropsType } from './index';

import { type CategoryPaidAmountFooterCell_event } from './__generated__/CategoryPaidAmountFooterCell_event.graphql';
import { type CategoryPaidAmountFooterCell_org } from './__generated__/CategoryPaidAmountFooterCell_org.graphql';

const CategoryPaidAmountFooterCell = ({
  event,
  org,
}: CategoryFooterCellPropsType<
  CategoryPaidAmountFooterCell_event,
  CategoryPaidAmountFooterCell_org,
>) => formatCost(event.totalPaidAmount, org.settings.currency);

export default createFragmentContainer(
  CategoryPaidAmountFooterCell,
  graphql`
    fragment CategoryPaidAmountFooterCell_event on Event {
      totalPaidAmount
    }

    fragment CategoryPaidAmountFooterCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
