/* @flow */
import React from 'react';
import styled from 'styled-components';

import Button from 'components/material/Button';

import OrgSettingsPropsForm from './OrgSettingsPropsForm';
import OrgSettingsPropsRow from './OrgSettingsPropsRow';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 700px;
  margin-left: -15px;
`;

const Row = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export const AddRow = styled(Row)`
  padding-left: 7px;
`;

const StyledOrgSettingsPropsForm = styled(OrgSettingsPropsForm)`
  padding-left: 8px;
  margin: 0 0 4px 0;
  input {
    padding-top: 8px;
    padding-left: 2px;
  }
`;

export const AddButton = styled(Button)`
  padding-top: 14px;
  color: ${props => props.theme.minimalButtonColor};
  &:active {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

const ShowAllButton = styled(AddButton)`
  padding: 9px 10px;
`;

type Item = {|
  +id: string,
  +name: string,
  +description: ?string,
  +isDefault?: ?boolean,
|};

type Props = {
  onCreate: (name: string, description: ?string) => void,
  onSave: (id: string, name: string, description: ?string) => void,
  onDelete: (id: string) => void,
  itemName: string,
  itemType?: string,
  items: $ReadOnlyArray<Item>,
  showAllItems?: boolean,
  disabled?: boolean,
  className?: string,
};

type State = {
  adding: boolean,
  showAll: boolean,
};

const showLimit = 6;

export default class OrgSettingsProps extends React.Component<Props, State> {
  state = {
    adding: false,
    showAll: false,
  };

  handleInputShow = () => {
    this.setState({ adding: true });
  };

  handleShowAll = () => {
    this.setState({ showAll: true });
  };

  handleAddItem = (name: string, description: ?string) => {
    if (name) {
      if (this.props.items.every(item => item.name.toLowerCase() !== name.toLowerCase())) {
        this.props.onCreate(name, description);
      }
    }
    this.setState({ adding: false });
  };

  render() {
    const isShowAllVisible =
      !this.props.showAllItems && !this.state.showAll && this.props.items.length > showLimit;

    const sortedItems = this.props.items.slice().sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });

    const items = isShowAllVisible ? sortedItems.slice(0, showLimit) : sortedItems;

    return (
      <Container className={this.props.className}>
        {!this.props.disabled && (
          <AddRow>
            {this.state.adding ? (
              <StyledOrgSettingsPropsForm
                itemName={this.props.itemName}
                onSave={this.handleAddItem}
                firstFocus
              />
            ) : (
              <AddButton
                icon="plus"
                label={`Add ${this.props.itemType || 'new'}`}
                minimal
                onClick={this.handleInputShow}
              />
            )}
          </AddRow>
        )}
        {items.map(item => (
          <OrgSettingsPropsRow
            key={item.id}
            id={item.id}
            itemName={this.props.itemName}
            name={item.name}
            isDefault={item.isDefault}
            description={item.description}
            onSave={this.props.onSave}
            onDelete={this.props.onDelete}
            disabled={this.props.disabled}
            disabledNameChange={item.isDefault || false}
          />
        ))}
        {isShowAllVisible && (
          <Row>
            <ShowAllButton label="Show all" minimal onClick={this.handleShowAll} />
          </Row>
        )}
      </Container>
    );
  }
}
