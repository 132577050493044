/**
 * @flow
 * @relayHash 770735c79fd93db1c60db50488bfd29f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type APISettings_org$ref = any;
export type CircaAPIQueryVariables = {||};
export type CircaAPIQueryResponse = {|
  +org: {|
    +$fragmentRefs: APISettings_org$ref
  |}
|};
export type CircaAPIQuery = {|
  variables: CircaAPIQueryVariables,
  response: CircaAPIQueryResponse,
|};
*/


/*
query CircaAPIQuery {
  org {
    ...APISettings_org
    id
  }
}

fragment APISettings_org on Org {
  settings {
    id
    apiKey
    apiPushNotificationsUrl
    apiPushNotificationsBlocked
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CircaAPIQuery",
  "id": null,
  "text": "query CircaAPIQuery {\n  org {\n    ...APISettings_org\n    id\n  }\n}\n\nfragment APISettings_org on Org {\n  settings {\n    id\n    apiKey\n    apiPushNotificationsUrl\n    apiPushNotificationsBlocked\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CircaAPIQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "APISettings_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CircaAPIQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "apiKey",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "apiPushNotificationsUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "apiPushNotificationsBlocked",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f1d1c50348b12524c05eb81201680ff';
module.exports = node;
