/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import { sortBy } from 'lodash';

import saveFormLogic from 'graph/mutations/saveFormLogic';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button, { MinimalButton } from 'components/budget/Button';
import getFilterItemValueForSave from 'components/ContactForm/lib/getFilterItemValueForSave';
import validateFilterItem from 'components/ContactForm/lib/validateFilterItem';
import EmptyFilters from 'components/FormLogic/EmptyFilters';
import type { RuleType } from 'components/FormLogic/lib/types';
import RuleEditor from 'components/FormLogic/RuleEditor';
import ViewMode from 'components/FormLogic/ViewMode';
import { getFieldName } from 'components/material/Filters/FilterSelectorRow';

import getConditionFields from './getConditionFields';

import type { RegistrationFormFieldLogic_contactFormField } from './__generated__/RegistrationFormFieldLogic_contactFormField.graphql';
import type { RegistrationFormFieldLogic_contactFormFields } from './__generated__/RegistrationFormFieldLogic_contactFormFields.graphql';
import type { RegistrationFormFieldLogic_org } from './__generated__/RegistrationFormFieldLogic_org.graphql';

const EditPenIcon = styled.i`
  display: none;
  color: #71c1e5;
`;

export const Block = styled.div`
  padding: 30px;
  border: solid 1px #dadada;
  border-radius: 4px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  color: ${props => props.theme.rowPrimaryTextColor};
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  ${props =>
    !props.editing &&
    !props.readOnly &&
    css`
      cursor: pointer;
      &:hover {
        ${EditPenIcon} {
          display: block;
        }
      }
    `}
`;

const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
`;

const BlockHeaderLeft = styled.div``;
const BlockHeaderRight = styled.div`
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;
`;

const RulesWrapper = styled.div`
  margin: 20px 0 20px 0;
`;

const CancelButton = styled(MinimalButton)`
  margin-top: 24px;
  margin-left: auto;
  font-size: 14px;
  font-weight: 400;
`;

const SaveButton = styled(Button)`
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
`;

class RegistrationFormFieldLogic extends React.Component<
  {
    contactFormField: RegistrationFormFieldLogic_contactFormField,
    contactFormFields: RegistrationFormFieldLogic_contactFormFields,
    org: RegistrationFormFieldLogic_org,
    tz: string,
  },
  { rules: $ReadOnlyArray<RuleType>, editing: boolean, saving: boolean },
> {
  state = {
    rules: this.getSavedRules(),
    editing: false,
    saving: false,
  };

  getSavedRules() {
    return sortBy(
      this.props.contactFormField.rules.edges.map(edge => ({ ...edge.node })),
      'order',
    ).map(rule => {
      return {
        id: rule.id,
        order: rule.order,
        action: rule.action,
        conditions: this.getSavedConditions(rule),
        hiddenConditions: [],
      };
    });
  }

  getSavedConditions(rule) {
    const { org } = this.props;

    if (!rule) {
      return [];
    }

    return sortBy(
      [
        ...rule.customSavedTextFilters.edges,
        ...rule.customSavedTextareaFilters.edges,
        ...rule.customSavedLinkFilters.edges,
        ...rule.customSavedNumberFilters.edges,
        ...rule.customSavedCurrencyFilters.edges,
        ...rule.customSavedBooleanFilters.edges,
        ...rule.customSavedMultiselectFilters.edges,
        ...rule.customSavedDateFilters.edges,
        ...rule.customSavedUserMultiselectFilters.edges,
      ].map((filterEdge: any) => {
        const filter = filterEdge.node;
        const customFieldNode = filter.customField
          ? org.customFields.edges.find(
              ({ node: customField }) =>
                filter.customField && customField.id === filter.customField.id,
            )
          : null;
        return {
          ...filter,
          errors: {},
          customField: customFieldNode ? customFieldNode.node : null,
          optionIds: filter.options
            ? filter.options.edges
                .map(edge => (edge.node && edge.node.option ? edge.node.option.id : null))
                .filter(Boolean)
            : [],
          users: filter.options
            ? filter.options.edges.map(edge => edge.node.user).filter(Boolean)
            : [],
        };
      }),
      'order',
    );
  }

  handleStartEditing = () => {
    this.setState(prevState => ({
      editing: true,
      rules: [
        ...prevState.rules,
        prevState.rules.length === 0
          ? {
              order: prevState.rules.length,
              conditions: [],
              hiddenConditions: [],
            }
          : null,
      ].filter(Boolean),
    }));
  };

  handleAddNewRule = () => {
    this.setState(prevState => ({
      rules: [
        ...prevState.rules,
        {
          order: prevState.rules.length,
          conditions: [],
          hiddenConditions: [],
        },
      ],
    }));
  };

  handleUpdateRule = (rule, index) => {
    this.setState(prevState => {
      return {
        rules: [...prevState.rules.slice(0, index), rule, ...prevState.rules.slice(index + 1)],
      };
    });
  };

  handleRemoveRule = (index: number) => {
    this.setState(prevState => {
      const remainingRules = prevState.rules.filter((_, i) => i !== index);
      return {
        rules:
          remainingRules.length === 0
            ? [
                {
                  order: prevState.rules.length,
                  conditions: [],
                  hiddenConditions: [],
                },
              ]
            : remainingRules,
      };
    });
  };

  handleCancel = () => {
    const rules = this.getSavedRules();
    this.setState({
      editing: false,
      rules:
        rules.length === 0
          ? [
              {
                order: rules.length,
                conditions: [],
                hiddenConditions: [],
              },
            ]
          : rules,
    });
  };

  handleSave = () => {
    const rules = this.state.rules;

    const validatedRules = rules.map(rule => ({
      ...rule,
      conditions: rule.conditions.map(condition => ({
        ...condition,
        errors: validateFilterItem(condition, { field: 'Question is required' }),
      })),
    }));

    if (
      validatedRules.some(
        rule =>
          rule.action !== 'HIDE_BY_DEFAULT' &&
          rule.conditions.filter(item => item.errors.field || item.errors.value).length > 0,
      )
    ) {
      this.setState({ rules: validatedRules });
      return;
    }

    const rulesWithActions = rules.filter(rule => rule.action);

    const rulesToSave = rulesWithActions.map((rule, index) => {
      const filtersToSave = rule.conditions.map((filterItem, orderIndex) => {
        return {
          order: orderIndex + 1,
          operator: filterItem.operator,
          customFieldId: filterItem.customField ? filterItem.customField.id : null,
          fieldName: getFieldName(filterItem),
          ...getFilterItemValueForSave(filterItem),
        };
      });

      const filtersToRemove = rule.hiddenConditions.map(filterItem => ({
        customFieldId: filterItem.customField ? filterItem.customField.id : null,
        fieldName: getFieldName(filterItem),
        textParam: null,
        textareaParam: null,
        linkParam: null,
        numberParam: null,
        dateParam: null,
        booleanParam: null,
        optionIds: null,
      }));

      return {
        ruleId: rule.id || null,
        order: index,
        action: rule.action,
        conditions: [...filtersToSave, ...filtersToRemove],
      };
    });

    this.setState({ saving: true, rules: rulesWithActions });
    saveFormLogic({ ruleableId: this.props.contactFormField.id, rules: rulesToSave })
      .then(() => {
        this.setState({ editing: false, saving: false });
      })
      .catch(showModernMutationError);
  };

  render() {
    const { contactFormField, contactFormFields, org, tz } = this.props;
    const { rules, editing, saving } = this.state;
    const customField = contactFormField.customField;
    const conditionFields = getConditionFields(contactFormField, contactFormFields);

    if (!customField || customField.required) {
      return (
        <Block key={contactFormField.id} readOnly>
          <BlockHeader>
            {contactFormField.required && '*'} {contactFormField.label}
          </BlockHeader>
          <EmptyFilters muted>
            This question is mapped with a required field and can’t have any logical dependencies.
          </EmptyFilters>
        </Block>
      );
    }

    return (
      <Block
        key={contactFormField.id}
        editing={editing}
        onClick={!editing ? this.handleStartEditing : undefined}
      >
        <BlockHeader>
          <BlockHeaderLeft>
            {contactFormField.required && '* '}
            {contactFormField.label}
          </BlockHeaderLeft>
          <BlockHeaderRight>
            <EditPenIcon className="fa fa-fw fa-pencil" />
          </BlockHeaderRight>
        </BlockHeader>
        {!editing && (
          <ViewMode
            conditionFields={conditionFields}
            tz={tz}
            rules={contactFormField.rules}
            onStartEdit={this.handleStartEditing}
          />
        )}
        {editing && (
          <>
            <RulesWrapper>
              {rules.map((rule, index) => {
                return (
                  <React.Fragment
                    key={`${rule.order}-${rule.id || 'UNSAVED'}-${rule.action || 'default'}`}
                  >
                    {index > 0 && <>Or</>}
                    <RuleEditor
                      tz={tz}
                      rule={rule}
                      org={org}
                      onUpdate={updatedRule => this.handleUpdateRule(updatedRule, index)}
                      onRemove={() => this.handleRemoveRule(index)}
                      conditionFields={conditionFields}
                    />
                  </React.Fragment>
                );
              })}
            </RulesWrapper>
            <MinimalButton onClick={this.handleAddNewRule} label="+ Add rule" />
            <Row>
              <CancelButton label="Cancel" onClick={this.handleCancel} disabled={saving} />
              <SaveButton onClick={this.handleSave} loading={saving}>
                Save
              </SaveButton>
            </Row>
          </>
        )}
      </Block>
    );
  }
}

export default createFragmentContainer(RegistrationFormFieldLogic, {
  org: graphql`
    fragment RegistrationFormFieldLogic_org on Org {
      customFields(customizableType: [CONTACT, COMPANY, EVENTCONTACT]) {
        edges {
          node {
            id
            label
            fieldName
            customizableType
            order
            kind
            options {
              edges {
                node {
                  id
                  name
                  editable
                }
              }
            }
          }
        }
      }
    }
  `,
  contactFormFields: graphql`
    fragment RegistrationFormFieldLogic_contactFormFields on ContactFormField @relay(plural: true) {
      id
      label
      order
      fieldName
      customField {
        id
        label
        fieldName
        customizableType
        order
        kind
        options {
          edges {
            node {
              id
              name
              editable
            }
          }
        }
      }
    }
  `,
  contactFormField: graphql`
    fragment RegistrationFormFieldLogic_contactFormField on ContactFormField {
      id
      __typename
      label
      required
      order
      customField {
        required
      }
      rules {
        edges {
          node {
            id
            action
            order
            customSavedTextFilters {
              edges {
                node {
                  id
                  order
                  fieldName
                  values
                  operator
                  customField {
                    id
                    customizableType
                  }
                }
              }
            }
            customSavedTextareaFilters {
              edges {
                node {
                  id
                  order
                  fieldName
                  values
                  operator
                  customField {
                    id
                    customizableType
                  }
                }
              }
            }
            customSavedLinkFilters {
              edges {
                node {
                  id
                  order
                  values
                  operator
                  customField {
                    id
                    customizableType
                  }
                }
              }
            }
            customSavedNumberFilters {
              edges {
                node {
                  id
                  order
                  minValue
                  maxValue
                  customField {
                    id
                    customizableType
                  }
                }
              }
            }
            customSavedCurrencyFilters {
              edges {
                node {
                  id
                  order
                  minValue
                  maxValue
                  customField {
                    id
                    customizableType
                  }
                }
              }
            }
            customSavedDateFilters {
              edges {
                node {
                  id
                  order
                  minValue
                  maxValue
                  customField {
                    id
                  }
                }
              }
            }
            customSavedBooleanFilters {
              edges {
                node {
                  id
                  value
                  order
                  customField {
                    id
                  }
                }
              }
            }
            customSavedUserMultiselectFilters {
              edges {
                node {
                  id
                  order
                  operator
                  customField {
                    id
                    customizableType
                  }
                  options {
                    edges {
                      node {
                        user {
                          id
                          email
                          firstName
                          lastName
                          ...MaterialAvatar_user
                        }
                      }
                    }
                  }
                }
              }
            }
            customSavedMultiselectFilters {
              edges {
                node {
                  id
                  order
                  operator
                  options {
                    edges {
                      node {
                        option {
                          id
                          name
                        }
                      }
                    }
                  }
                  customField {
                    id
                    customizableType
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
