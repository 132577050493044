/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Match, type RouterHistory, Route } from 'react-router-dom';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';

import createEventNote from 'graph/mutations/event_note/createEventNote';
import removeEventNote from 'graph/mutations/event_note/removeEventNote';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/material/Button';

import EventNote from './EventNote';
import NoteItem from './NoteItem';

import { type NotesContainer_event } from './__generated__/NotesContainer_event.graphql';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
`;

const StyledButton = styled(Button)`
  flex: 1 1 auto;
  padding: 5px 12px;
  margin-bottom: 22px;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 320px;
  padding: 20px 0 50px;
  border-right: 1px solid #eaebec;
`;

const NotesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const NotesEditor = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
`;

class NotesContainer extends React.Component<
  {
    event: NotesContainer_event,
    match: Match,
    history: RouterHistory,
  },
  {
    editingNoteId: string,
    newNoteId: string,
  },
> {
  state = {
    editingNoteId: this.props.match.params.eventNoteId || '',
    newNoteId: '',
  };

  timeout: TimeoutID;

  componentDidMount() {
    const notes = this.props.event.eventNotes.edges.map(({ node }) => node);
    if (!this.state.editingNoteId && notes.length > 0) {
      const firstNoteId = notes[0].id;
      this.loadEventNote(firstNoteId);
    }
  }

  componentDidUpdate(prevProps) {
    const eventNotes = this.props.event.eventNotes.edges.map(({ node }) => node);
    const currentNoteIndex = eventNotes.findIndex(note => note.id === this.state.editingNoteId);

    const prevEventNotes = prevProps.event.eventNotes.edges.map(({ node }) => node);
    const prevNoteIndex = prevEventNotes.findIndex(note => note.id === this.state.editingNoteId);
    if (currentNoteIndex === -1 && prevNoteIndex !== -1) {
      const eventNoteId = this.pickNextNote(prevEventNotes, prevNoteIndex);
      if (eventNoteId) {
        this.loadEventNote(eventNoteId);
      }
    }

    if (
      this.props.match.params &&
      this.props.match.params.event_slug &&
      !this.props.match.params.eventNoteId &&
      eventNotes.length > 0
    ) {
      const newNotePage = `/events/${this.props.match.params.event_slug}/notes/${eventNotes[0].id}`;
      this.props.history.push(newNotePage);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  pickNextNote = (eventNotes: $ReadOnlyArray<{ +id: string }>, prevNoteIndex: number): string => {
    if (eventNotes.length <= 1) {
      return '';
    }
    if (eventNotes.length - 1 > prevNoteIndex) {
      return eventNotes[prevNoteIndex + 1].id;
    }
    return eventNotes[prevNoteIndex - 1].id;
  };

  loadEventNote = (editingNoteId: string) => {
    this.setState({ editingNoteId }, () => {
      if (this.props.match.params && this.props.match.params.event_slug) {
        const newNotePage = `/events/${this.props.match.params.event_slug}/notes/${editingNoteId}`;
        this.props.history.push(newNotePage);
      }
    });
  };

  handleCreate = () => {
    const {
      event: { id: eventId },
    } = this.props;
    createEventNote(eventId, 'New Note', '', [])
      .then(({ id: eventNoteId }) => {
        this.handleItemEditing(eventNoteId);
        this.handleCreateNewNote(eventNoteId);
      })
      .catch(showModernMutationError);
  };

  handleRemove = (noteId: string) => {
    removeEventNote(this.props.event.id, noteId).catch(showModernMutationError);
  };

  handleItemEditing = (noteId: string) => {
    this.loadEventNote(noteId);
  };

  handleCreateNewNote = (newNoteId?: string = '') => {
    this.setState({ newNoteId }, () => {
      this.timeout = setTimeout(() => this.setState({ newNoteId: '' }), 1000);
    });
  };

  render() {
    const {
      event,
      event: { viewerCanCreateNote, tz },
    } = this.props;
    const eventNotes = orderBy(
      event.eventNotes.edges.map(({ node }) => node),
      ['updatedAt'],
      ['desc'],
    );
    const { editingNoteId, newNoteId } = this.state;
    const requestedNote = eventNotes.find(note => note.id === this.state.editingNoteId);
    return (
      <Container>
        <Sidebar>
          {viewerCanCreateNote && (
            <div>
              <StyledButton label="Add new note" primary onClick={this.handleCreate} />
            </div>
          )}
          <NotesListContainer>
            {eventNotes.map(note => (
              <NoteItem
                key={note.id}
                note={note}
                newNoteId={newNoteId}
                onRemove={this.handleRemove}
                timezone={tz}
                handleEditing={this.handleItemEditing}
                active={editingNoteId === note.id}
                match={this.props.match}
              />
            ))}
          </NotesListContainer>
        </Sidebar>
        <NotesEditor>
          {requestedNote && (
            <Route
              exact
              path={`${this.props.match.path}/:eventNoteId`}
              render={props => (
                <EventNote
                  event={event}
                  match={props.match}
                  history={props.history}
                  onCreateNewNote={this.handleCreateNewNote}
                />
              )}
            />
          )}
        </NotesEditor>
      </Container>
    );
  }
}

export default createFragmentContainer(NotesContainer, {
  event: graphql`
    fragment NotesContainer_event on Event {
      id
      dbId
      name
      tz
      viewerCanCreateNote
      ...EventNote_event
      eventNotes {
        edges {
          node {
            id
            updatedAt
            ...NoteItem_note
          }
        }
      }
    }
  `,
});
