/* @flow */
import React from 'react';
import styled from 'styled-components';

import Radio from 'components/SharedForm/Radio';

import accessibilityOptions from '../lib/accessibilityOptions';
import type { AccessibilityOptionType } from '../lib/types';

const Container = styled.div`
  margin: 15px 0 25px;
  font-size: 16px;
`;

const Title = styled.div`
  color: rgb(74, 86, 101, 0.87);
  font-weight: 500;
`;

const Option = styled.div`
  margin: 15px 0;
`;

export default class AccessibilityOption extends React.PureComponent<{
  accessibility: AccessibilityOptionType,
  onChange: (accessibility: AccessibilityOptionType) => void,
  ssoEnabled: boolean,
}> {
  changeRadioHandler = (accessibility: AccessibilityOptionType) => () => {
    this.props.onChange(accessibility);
  };

  render() {
    const { accessibility } = this.props;

    return (
      <Container>
        <Title>Permissions</Title>

        {Object.keys(accessibilityOptions).map(key => (
          <Option key={key}>
            <Radio
              name="accessibility"
              checked={accessibility === key}
              onChange={this.changeRadioHandler(key)}
              label={`${accessibilityOptions[key].label}. ${accessibilityOptions[key].description}`}
              compact
              disabled={key === 'SSO' && !this.props.ssoEnabled}
            />
          </Option>
        ))}
      </Container>
    );
  }
}
