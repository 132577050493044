/* @flow */

import type { QuestionValueType } from './types';

const generateBlankQuestionValue = (questionId: string): QuestionValueType => ({
  questionId,
  textValue: null,
  textareaValue: null,
  numberValue: null,
  dateValue: null,
  booleanValue: null,
  selectIdsValue: [],
  filesValue: [],
  locationValue: null,
  budgetCategoryId: null,
});

export default generateBlankQuestionValue;
