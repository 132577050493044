/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';

import { type InputVariableFilters } from 'components/Companies/companiesTableColumnSettings';

const mutation = graphql`
  mutation removeCompanyAssignmentMutation(
    $input: RemoveCompanyAssignmentInput!
    $filters: CompanyFilters
  ) {
    removeCompanyAssignment(input: $input) {
      removedCompanyId
      event {
        id
        companies {
          totalCount
        }
        ...EventCompaniesTablePagination_totalCountEvent
      }
    }
  }
`;

type Args = {
  eventId: string,
  companyId: string,
  fromWindow: 'company profile' | 'event companies',
  filters?: InputVariableFilters,
};

export default function removeCompanyAssignment({ eventId, companyId, fromWindow, filters }: Args) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        companyId,
        eventId,
        fromWindow,
      },
      filters,
    },
    optimisticResponse: {
      removeCompanyAssignment: {
        removedCompanyId: companyId,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedCompanyId',
        parentId: eventId,
        connections: [{ key: 'EventCompaniesTablePagination_companies' }],
      }),
    ),
  });
}
