/**
 * @flow
 * @relayHash 567ad094f2d2573abbee6e261fe1680f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrgCompaniesPage_org$ref = any;
export type OrgCompaniesQueryVariables = {||};
export type OrgCompaniesQueryResponse = {|
  +org: {|
    +salesforceAccount: ?{|
      +contactsSyncEnabled: boolean
    |},
    +$fragmentRefs: OrgCompaniesPage_org$ref,
  |},
  +me: {|
    +tz: string
  |},
|};
export type OrgCompaniesQuery = {|
  variables: OrgCompaniesQueryVariables,
  response: OrgCompaniesQueryResponse,
|};
*/


/*
query OrgCompaniesQuery {
  org {
    salesforceAccount {
      contactsSyncEnabled
      id
    }
    ...OrgCompaniesPage_org
    id
  }
  me {
    tz
    id
  }
}

fragment OrgCompaniesPage_org on Org {
  ...OrgCompaniesHeaderBar_org
  id
  name
  companyCustomFields: customFields(customizableType: [COMPANY]) {
    edges {
      node {
        id
        label
        fieldName
        order
        kind
        required
        options {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}

fragment OrgCompaniesHeaderBar_org on Org {
  id
  name
  viewerCanCreateCompanies
  viewerCanRemoveCompanies
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactsSyncEnabled",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tz",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "OrgCompaniesQuery",
  "id": null,
  "text": "query OrgCompaniesQuery {\n  org {\n    salesforceAccount {\n      contactsSyncEnabled\n      id\n    }\n    ...OrgCompaniesPage_org\n    id\n  }\n  me {\n    tz\n    id\n  }\n}\n\nfragment OrgCompaniesPage_org on Org {\n  ...OrgCompaniesHeaderBar_org\n  id\n  name\n  companyCustomFields: customFields(customizableType: [COMPANY]) {\n    edges {\n      node {\n        id\n        label\n        fieldName\n        order\n        kind\n        required\n        options {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment OrgCompaniesHeaderBar_org on Org {\n  id\n  name\n  viewerCanCreateCompanies\n  viewerCanRemoveCompanies\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OrgCompaniesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v0
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "OrgCompaniesPage_org",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrgCompaniesQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v0,
              v2
            ]
          },
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanCreateCompanies",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanRemoveCompanies",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "companyCustomFields",
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"COMPANY\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "customizableType",
                "value": [
                  "COMPANY"
                ],
                "type": "[CustomizableType!]!"
              }
            ],
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fieldName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "kind",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "required",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "options",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionTypeRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldOptionTypeRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CustomFieldOptionType",
                                "plural": false,
                                "selections": [
                                  v2,
                                  v3
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ad77010f9daa5013e034897289db157';
module.exports = node;
