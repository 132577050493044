/**
 * @flow
 * @relayHash 0caef798756eead1f7e4df1fedce5dc2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddDeliverableAttachmentInput = {
  clientMutationId?: ?string,
  deliverableId: string,
  filename: string,
  fileurl: string,
  filetype: string,
  filesize?: ?number,
};
export type addTaskAttachmentMutationVariables = {|
  input: AddDeliverableAttachmentInput
|};
export type addTaskAttachmentMutationResponse = {|
  +addDeliverableAttachment: ?{|
    +deliverable: {|
      +id: string,
      +dbId: number,
      +name: string,
      +event: {|
        +dbId: number,
        +name: string,
      |},
    |},
    +attachmentEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +fileurl: ?string,
        +filename: ?string,
        +filetype: ?string,
        +filesize: ?number,
        +viewerCanDelete: boolean,
      |},
    |},
  |}
|};
export type addTaskAttachmentMutation = {|
  variables: addTaskAttachmentMutationVariables,
  response: addTaskAttachmentMutationResponse,
|};
*/


/*
mutation addTaskAttachmentMutation(
  $input: AddDeliverableAttachmentInput!
) {
  addDeliverableAttachment(input: $input) {
    deliverable {
      id
      dbId
      name
      event {
        dbId
        name
        id
      }
    }
    attachmentEdge {
      __typename
      node {
        id
        fileurl
        filename
        filetype
        filesize
        viewerCanDelete
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddDeliverableAttachmentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddDeliverableAttachmentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attachmentEdge",
  "storageKey": null,
  "args": null,
  "concreteType": "AttachmentRequiredEdge",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Attachment",
      "plural": false,
      "selections": [
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fileurl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "filename",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "filetype",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "filesize",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "viewerCanDelete",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addTaskAttachmentMutation",
  "id": null,
  "text": "mutation addTaskAttachmentMutation(\n  $input: AddDeliverableAttachmentInput!\n) {\n  addDeliverableAttachment(input: $input) {\n    deliverable {\n      id\n      dbId\n      name\n      event {\n        dbId\n        name\n        id\n      }\n    }\n    attachmentEdge {\n      __typename\n      node {\n        id\n        fileurl\n        filename\n        filetype\n        filesize\n        viewerCanDelete\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addTaskAttachmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDeliverableAttachment",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddDeliverableAttachmentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v3,
                  v4
                ]
              }
            ]
          },
          v5
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "addTaskAttachmentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDeliverableAttachment",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddDeliverableAttachmentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverable",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v2
                ]
              }
            ]
          },
          v5
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fff5dc7de3e625431a82987134f5294b';
module.exports = node;
