/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  createBudgetReportingViewMutationResponse,
  createBudgetReportingViewMutationVariables,
} from './__generated__/createBudgetReportingViewMutation.graphql';

const mutation = graphql`
  mutation createBudgetReportingViewMutation($input: CreateBudgetReportingViewInput!) {
    createBudgetReportingView(input: $input) {
      budgetReportingViewsEdge {
        __typename
        node {
          exportScheduler {
            frequency
            scheduledOn
            includePayments
            includeExpenses
            recipients {
              edges {
                node {
                  id
                  firstName
                  lastName
                  avatar
                  email
                }
              }
            }
          }
          id
          name
          group1
          group2
          customGroup1 {
            id
          }
          customGroup2 {
            id
          }
          eventIds
          eventName
          eventDate
          eventStatuses
          syncStatuses
          sort
          direction
          customSavedNumberFilters {
            edges {
              node {
                id
                minValue
                maxValue
                customField {
                  id
                }
              }
            }
          }
          customSavedCurrencyFilters {
            edges {
              node {
                id
                minValue
                maxValue
                customField {
                  id
                }
              }
            }
          }
          customSavedDateFilters {
            edges {
              node {
                id
                minValue
                maxValue
                customField {
                  id
                }
              }
            }
          }
          customSavedBooleanFilters {
            edges {
              node {
                id
                value
                customField {
                  id
                }
              }
            }
          }
          customSavedUserMultiselectFilters {
            edges {
              node {
                id
                customField {
                  id
                }
                options {
                  edges {
                    node {
                      user {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
          customSavedMultiselectFilters {
            edges {
              node {
                id
                customField {
                  id
                }
                options {
                  edges {
                    node {
                      option {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
          teams {
            edges {
              node {
                id
              }
            }
          }
          leads {
            edges {
              node {
                id
              }
            }
          }
          staffers {
            edges {
              node {
                id
              }
            }
          }
          budgetCategories {
            edges {
              node {
                id
              }
            }
          }
          venueNames
          cities
          states
          countries
          opportunitiesNumber {
            min
            max
          }
          opportunitiesAmount {
            min
            max
          }
          contactsCount {
            min
            max
          }
          registeredContactsTotal {
            min
            max
          }
          attendedContactsTotal {
            min
            max
          }
          registrationFormStatuses
          eventFormats
          creators {
            edges {
              node {
                id
              }
            }
          }
          createdAt
          updaters {
            edges {
              node {
                id
              }
            }
          }
          updatedAt
          requestedDate
          requestStatuses
          userRequesters {
            edges {
              node {
                id
              }
            }
          }
          contactRequesters {
            edges {
              node {
                id
              }
            }
          }
          eventRequestForms {
            edges {
              node {
                id
              }
            }
          }
          eventRequestReviewers {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default function createBudgetReportingView(
  orgId: string,
  inputVariables: {
    ...$PropertyType<createBudgetReportingViewMutationVariables, 'input'>,
    sort?: string,
  },
): Promise<
  $PropertyType<
    $PropertyType<
      $NonMaybeType<
        $PropertyType<createBudgetReportingViewMutationResponse, 'createBudgetReportingView'>,
      >,
      'budgetReportingViewsEdge',
    >,
    'node',
  >,
> {
  return commitModernMutation({
    mutation,
    variables: { input: inputVariables },
    updater: addRecordToConnection({
      parentId: orgId,
      edgeName: 'budgetReportingViewsEdge',
      connections: [{ field: 'budgetReportingViews' }],
    }),
  }).then((response: createBudgetReportingViewMutationResponse) => {
    if (
      response.createBudgetReportingView &&
      response.createBudgetReportingView.budgetReportingViewsEdge.node
    ) {
      return response.createBudgetReportingView.budgetReportingViewsEdge.node;
    }
    throw new Error('No Budget Reporting View Created');
  });
}
