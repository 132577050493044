/**
 * @flow
 * @relayHash bad190de438b40a734418d069eade658
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MarketoMappingResourceType = "COMPANY" | "CONTACT" | "EVENTCONTACT";
export type ConfigureMarketoFieldMappingInput = {
  clientMutationId?: ?string,
  marketoField?: ?string,
  resourceType: MarketoMappingResourceType,
  resourceFieldName?: ?string,
  resourceCustomFieldId?: ?string,
};
export type configureMarketoFieldMappingMutationVariables = {|
  input: ConfigureMarketoFieldMappingInput
|};
export type configureMarketoFieldMappingMutationResponse = {|
  +configureMarketoFieldMapping: ?{|
    +marketoFieldMappingEdge: ?{|
      +node: {|
        +id: string,
        +marketoField: string,
        +resourceType: MarketoMappingResourceType,
        +resourceFieldName: ?string,
        +resourceCustomField: ?{|
          +id: string
        |},
      |}
    |},
    +removedMarketoFieldMappingId: ?string,
  |}
|};
export type configureMarketoFieldMappingMutation = {|
  variables: configureMarketoFieldMappingMutationVariables,
  response: configureMarketoFieldMappingMutationResponse,
|};
*/


/*
mutation configureMarketoFieldMappingMutation(
  $input: ConfigureMarketoFieldMappingInput!
) {
  configureMarketoFieldMapping(input: $input) {
    marketoFieldMappingEdge {
      node {
        id
        marketoField
        resourceType
        resourceFieldName
        resourceCustomField {
          id
        }
      }
    }
    removedMarketoFieldMappingId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ConfigureMarketoFieldMappingInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "configureMarketoFieldMapping",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ConfigureMarketoFieldMappingInput!"
      }
    ],
    "concreteType": "ConfigureMarketoFieldMappingPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "marketoFieldMappingEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "MarketoFieldMappingRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketoFieldMapping",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "marketoField",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "resourceType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "resourceFieldName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "resourceCustomField",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldType",
                "plural": false,
                "selections": [
                  v1
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedMarketoFieldMappingId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "configureMarketoFieldMappingMutation",
  "id": null,
  "text": "mutation configureMarketoFieldMappingMutation(\n  $input: ConfigureMarketoFieldMappingInput!\n) {\n  configureMarketoFieldMapping(input: $input) {\n    marketoFieldMappingEdge {\n      node {\n        id\n        marketoField\n        resourceType\n        resourceFieldName\n        resourceCustomField {\n          id\n        }\n      }\n    }\n    removedMarketoFieldMappingId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "configureMarketoFieldMappingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "configureMarketoFieldMappingMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9a4d4f304967ce6eaa23f3eea08117af';
module.exports = node;
