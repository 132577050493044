/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import MultiselectField, { Field } from 'components/material/MultiSelectField';
import RemovablePill from 'components/material/MultiSelectField/RemovablePill';
import Tooltip from 'components/material/Tooltip';

import InlineEditableCell from './InlineEditableCell';

const Root = styled.div`
  ${Field} {
    padding: 0 10px 0 16px;
  }
`;

const SelectionsList = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTooltip = styled(Tooltip)`
  display: inline-block;
  margin-right: 7px;
  padding: 3px 8px 3px 6px;
  background-color: #f5f5f5;
  color: #4a5665;
  line-height: 14px;
`;

const StyledSelectionsList = styled(SelectionsList)`
  margin: -5px;
`;

export default class InlineMultiSelectField<T: any> extends React.PureComponent<
  {
    children?: React.Node,
    defaultEditing?: boolean,
    clearable: boolean,
    getError?: (value?: T) => ?string,
    updateColumnWidth?: () => void,
  } & $PropertyType<MultiselectField<T>, 'props'>,
  {
    error: ?string,
  },
> {
  state = {
    error: null,
  };

  handleValidate = (value?: T): boolean => {
    const { getError, updateColumnWidth } = this.props;
    const error: ?string = getError != null ? getError(value) : null;
    this.setState(state => (state.error === error ? null : { error }));
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  handleUnselect = (value: T) => {
    if (this.handleValidate(value)) {
      this.props.onUnselect(value);
    }
  };

  handleSelect(value: T) {
    this.props.onSelect(value);
  }

  renderSelectedOptions() {
    const selectedOptions = (this.props.values || [])
      .map(val => this.props.options.find(option => option.value === val))
      .filter(Boolean);
    const showableCount =
      this.props.maxShowableItems === Infinity
        ? selectedOptions.length
        : this.props.maxShowableItems;
    const shownOptions = selectedOptions.slice(0, showableCount);
    const hiddenOptions = selectedOptions.slice(showableCount, selectedOptions.length);

    const label = hiddenOptions.map(option => <div key={String(option.value)}>{option.label}</div>);

    return (
      <StyledSelectionsList onMouseDown={e => e.preventDefault()}>
        {shownOptions.map(option => (
          <RemovablePill
            value={option.value}
            key={String(option.value)}
            onRemove={this.props.onUnselect}
            disabled={shownOptions.length === 1 && !this.props.clearable}
          >
            {option.label}
          </RemovablePill>
        ))}
        {this.props.maxShowableItems !== Infinity && hiddenOptions.length > 0 ? (
          <StyledTooltip placement="top" label={label}>
            +{hiddenOptions.length}
          </StyledTooltip>
        ) : null}
      </StyledSelectionsList>
    );
  }

  render() {
    return (
      <Root>
        <InlineEditableCell
          defaultEditing={this.props.defaultEditing}
          onValidate={this.handleValidate}
          error={this.state.error}
        >
          {({ onBlur, editing }) => {
            if (!editing) return this.props.values ? this.renderSelectedOptions() : null;

            return (
              <MultiselectField
                {...this.props}
                maxShowableItems={this.props.maxShowableItems}
                overlayContainer={document.body}
                searchable
                noBorder
                autoFocus
                isStretched
                onSelect={(value: T) => this.handleSelect(value)}
                onUnselect={this.handleUnselect}
                disabled={
                  this.props.values != null &&
                  this.props.values.length === 1 &&
                  !this.props.clearable
                }
                onHideOptions={() => {
                  if (this.handleValidate()) {
                    if (onBlur) onBlur();
                    if (this.props.onHideOptions) this.props.onHideOptions();
                  }
                }}
              />
            );
          }}
        </InlineEditableCell>
      </Root>
    );
  }
}
