/* @flow */
import track from '../track';

export type OrgEventViewWindow = 'list' | 'calendar' | 'map';

const updatedOrgEventView = (fromWindow: OrgEventViewWindow) => {
  track('updated_org_event_view', { from_window: fromWindow });
};

export default updatedOrgEventView;
