/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import getSavedFiltersForView from 'components/FormLogic/lib/getSavedFiltersForView';
import Tooltip from 'components/material/Tooltip';

import type { ScheduledEmailTemplateFilterTooltip_eventEmailTemplate } from './__generated__/ScheduledEmailTemplateFilterTooltip_eventEmailTemplate.graphql';

const Container = styled.div`
  position: relative;
`;

const Icon = styled.i`
  font-size: 16px;
  color: #96a2ab;
  cursor: pointer;
`;

const ToolTipLabel = styled.div`
  font-family: ${props => props.fontFamily};
`;

const Description = styled.div`
  font-weight: 500;
`;

const Circle = styled.div`
  position: absolute;
  top: -4px;
  right: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: ${props => props.theme.primaryActionTextColor};
  background: ${props => props.theme.primaryActionColor};
  border-radius: 50%;
  font-size: 10px;
`;

class ScheduledEmailTemplateFilterTooltip extends React.Component<
  {
    eventEmailTemplate: ?ScheduledEmailTemplateFilterTooltip_eventEmailTemplate,
    fontFamily: ?string,
    tz: string,
  },
  {},
> {
  tooltipLabel = savedFiltersForView => {
    return (
      <ToolTipLabel fontFamily={this.props.fontFamily}>
        <Description key="Filter description">
          {savedFiltersForView.length > 0 ? 'Filtered by:' : 'There are no filters applied'}
        </Description>
        {savedFiltersForView.map((savedFilter, index) => (
          <span key={`${savedFilter.fieldName} ${savedFilter.operator} ${savedFilter.value}`}>
            {index > 0 && '\n'}
            {savedFilter.fieldName} {savedFilter.operator} {savedFilter.value}
          </span>
        ))}
      </ToolTipLabel>
    );
  };

  render() {
    const { eventEmailTemplate, tz } = this.props;
    const savedFiltersForView = getSavedFiltersForView(eventEmailTemplate, tz);

    return (
      <Container>
        <Tooltip label={this.tooltipLabel(savedFiltersForView)} placement="top">
          <Icon className="fa fa-fw fa-filter" />
          {savedFiltersForView.length > 0 && <Circle>{savedFiltersForView.length}</Circle>}
        </Tooltip>
      </Container>
    );
  }
}

export default createFragmentContainer(
  ScheduledEmailTemplateFilterTooltip,
  graphql`
    fragment ScheduledEmailTemplateFilterTooltip_eventEmailTemplate on EventEmailTemplate {
      id
      customSavedTextFilters {
        edges {
          node {
            values
            operator
            order
            fieldName
            customField {
              label
              customizableType
            }
          }
        }
      }
      customSavedTextareaFilters {
        edges {
          node {
            values
            operator
            order
            fieldName
            customField {
              label
              customizableType
            }
          }
        }
      }
      customSavedLinkFilters {
        edges {
          node {
            values
            operator
            order
            customField {
              label
              customizableType
            }
          }
        }
      }
      customSavedNumberFilters {
        edges {
          node {
            minValue
            maxValue
            order
            customField {
              label
              customizableType
            }
          }
        }
      }
      customSavedCurrencyFilters {
        edges {
          node {
            minValue
            maxValue
            order
            customField {
              label
              customizableType
            }
          }
        }
      }
      customSavedDateFilters {
        edges {
          node {
            minValue
            maxValue
            order
            customField {
              label
              customizableType
            }
          }
        }
      }
      customSavedBooleanFilters {
        edges {
          node {
            value
            order
            customField {
              label
              customizableType
            }
          }
        }
      }
      customSavedUserMultiselectFilters {
        edges {
          node {
            operator
            order
            customField {
              id
              label
              customizableType
            }
            options {
              edges {
                node {
                  user {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
      customSavedMultiselectFilters {
        edges {
          node {
            operator
            order
            options {
              edges {
                node {
                  option {
                    name
                  }
                }
              }
            }
            customField {
              label
              customizableType
            }
          }
        }
      }
    }
  `,
);
