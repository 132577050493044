/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateRowComponentMutationVariables,
  UpdateRowComponentInput,
} from './__generated__/updateRowComponentMutation.graphql';

export type updateRowComponentPropertyType = $Shape<UpdateRowComponentInput>;

const mutation = graphql`
  mutation updateRowComponentMutation($input: UpdateRowComponentInput!) {
    updateRegistrationFormRowComponent(input: $input) {
      pageComponent {
        rowComponent {
          id
          padding
          cellSpacing
          backgroundColor
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateEventNameComponent(properties: updateRowComponentPropertyType) {
  const { id, padding, cellSpacing, backgroundColor } = properties;
  const variables: updateRowComponentMutationVariables = {
    input: {
      ...properties,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateRegistrationFormRowComponent: {
        pageComponent: {
          rowComponent: {
            id,
            padding,
            cellSpacing,
            backgroundColor,
          },
        },
      },
    },
  });
}
