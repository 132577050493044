/* @flow */
import React from 'react';
import styled from 'styled-components';

import { Plus } from 'images';
import Overlay from 'components/material/Overlay';

import AddTeamAction from './AddTeamAction';
import { type TeamAllocationType } from './TeamSelectField';

const Container = styled.div`
  margin-top: 21px;
`;

const Action = styled.div`
  font-size: 13px;
  color: #3ba9da;
  cursor: pointer;
`;

const PlusIcon = styled(Plus)`
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-top: -4px;
  cursor: pointer;
  color: ${props => props.theme.primaryActionColor};
`;

const Teams = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Team = styled.div`
  margin-top: 8px;
  padding: 1px 5px 1px 3px;
  &:not(:last-child) {
    margin-right: 10px;
  }
  border: solid 1px #bac2c9;
  border-radius: 2px;
  font-size: 13px;
  color: #3e4859;
`;

const StyledOverlay = styled(Overlay)`
  width: 249px;
`;

export default class TeamAllocation extends React.Component<
  {
    currentTeamId: string,
    teams: $ReadOnlyArray<TeamAllocationType>,
    onSetTeamAllocations: (teams: $ReadOnlyArray<TeamAllocationType>) => void,
    className?: string,
  },
  {
    showOverlay: boolean,
  },
> {
  state = {
    showOverlay: false,
  };

  targetRef = React.createRef();

  handleShowOverlay = () => {
    this.setState({ showOverlay: true });
  };

  handleHideOverlay = () => {
    this.setState({ showOverlay: false });
  };

  isOwnTeamPercent100 = () => {
    return (
      this.props.currentTeamId &&
      this.props.teams.length === 1 &&
      this.props.teams[0].id === this.props.currentTeamId &&
      this.props.teams[0].percent === 100
    );
  };

  render() {
    return (
      <Container className={this.props.className}>
        <Action ref={this.targetRef} onClick={this.handleShowOverlay}>
          <PlusIcon /> Split by Team
        </Action>
        <Teams>
          {!this.isOwnTeamPercent100() &&
            this.props.teams.map(team => (
              <Team key={team.id}>
                {team.name} ({team.percent} %)
              </Team>
            ))}
        </Teams>
        <StyledOverlay
          container={this}
          target={this.targetRef.current}
          show={this.state.showOverlay}
          onHide={this.handleHideOverlay}
        >
          <AddTeamAction
            onSetTeamAllocations={this.props.onSetTeamAllocations}
            teamAllocations={this.props.teams}
          />
        </StyledOverlay>
      </Container>
    );
  }
}
