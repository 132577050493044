/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  UpdateEventListInput,
  updateEventListMutationResponse,
} from './__generated__/updateEventListMutation.graphql';

const mutation = graphql`
  mutation updateEventListMutation($input: UpdateEventListInput!) {
    updateEventList(input: $input) {
      eventList {
        id
        name
        shared
        linkEventNameToBrief
        publicResourceToken {
          token
        }
        exportScheduler {
          frequency
          scheduledOn
          recipients {
            edges {
              node {
                id
                firstName
                lastName
                email
              }
            }
          }
        }
        actionsRequestForms {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export default function updateEventList(
  input: UpdateEventListInput,
): Promise<updateEventListMutationResponse> {
  const { publicResourceToken, shared, ...optimisticEventList } = input;

  return commitModernMutation({
    mutation,
    variables: { input },
    optimisticResponse: {
      updateEventList: {
        eventList: optimisticEventList,
      },
    },
  });
}
