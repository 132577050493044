/* @flow */
import React from 'react';
import { Link } from 'react-router-dom';

import parseGroupInfo from '../../lib/parseGroupInfo';
import { type ReportingCellPropsType } from './index';

const ReportingGroup1Cell = ({ group1, group, childGroup, location }: ReportingCellPropsType) => {
  if (childGroup != null) return '';
  const { groupName, slug } = parseGroupInfo(group.name, group1);
  if (slug == null) {
    return groupName;
  }
  return (
    <Link
      to={{
        pathname: `/events/${slug}/budget`,
        state: {
          from: 'budget_reporting',
          fromUrl: location && location.pathname,
        },
      }}
    >
      {groupName}
    </Link>
  );
};

export default ReportingGroup1Cell;
