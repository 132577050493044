/* @flow */
import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
} from 'utils/routing/parseTypedQueryString';

export type ParsedEventRequestFormsFilters = {
  sort: SortParam,
};

export default function parseEventRequestFormsFilters(
  queryString: string,
): ParsedEventRequestFormsFilters {
  const filters = parseTypedQueryString(queryString, {
    sort: stringParamToSort,
  });

  filters.sort = filters.sort || {
    key: 'CREATED_AT',
    asc: false,
  };

  // $FlowFixMe
  return filters;
}
