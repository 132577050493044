/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
export type Measurement = "PERCENTAGE" | "PX";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ImageComponent_componentProps$ref: FragmentReference;
export type ImageComponent_componentProps = {|
  +id: string,
  +imageComponent: ?{|
    +id: string,
    +fileurl: ?string,
    +filename: ?string,
    +filesize: ?string,
    +filetype: ?string,
    +padding: string,
    +alignment: Alignment,
    +width: number,
    +widthMeasurement: Measurement,
    +backgroundColor: ?string,
  |},
  +$refType: ImageComponent_componentProps$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ImageComponent_componentProps",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "imageComponent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationImageComponent",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fileurl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "filename",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "filesize",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "filetype",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "padding",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "alignment",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "width",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "widthMeasurement",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "backgroundColor",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '41732f0b8a7df6449f2b026720319625';
module.exports = node;
