/**
 * @flow
 * @relayHash aef68c355aff51babc889d76f733519c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Frequency = "monthly" | "weekly";
export type ScheduledTime = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "end_of_month" | "start_of_month";
export type SavedEventListEditWindowQueryVariables = {|
  eventListId: string
|};
export type SavedEventListEditWindowQueryResponse = {|
  +eventList: ?{|
    +name?: string,
    +viewerCanUpdate?: boolean,
    +exportScheduler?: ?{|
      +frequency: Frequency,
      +scheduledOn: ScheduledTime,
      +recipients: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +avatar: ?string,
            +email: string,
          |}
        |}>
      |},
    |},
  |}
|};
export type SavedEventListEditWindowQuery = {|
  variables: SavedEventListEditWindowQueryVariables,
  response: SavedEventListEditWindowQueryResponse,
|};
*/


/*
query SavedEventListEditWindowQuery(
  $eventListId: ID!
) {
  eventList: node(id: $eventListId) {
    __typename
    ... on EventList {
      name
      viewerCanUpdate
      exportScheduler {
        frequency
        scheduledOn
        recipients {
          edges {
            node {
              id
              firstName
              lastName
              avatar
              email
            }
          }
        }
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventListId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventListId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "frequency",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scheduledOn",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "recipients",
  "storageKey": null,
  "args": null,
  "concreteType": "UserRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            v6,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "avatar",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SavedEventListEditWindowQuery",
  "id": null,
  "text": "query SavedEventListEditWindowQuery(\n  $eventListId: ID!\n) {\n  eventList: node(id: $eventListId) {\n    __typename\n    ... on EventList {\n      name\n      viewerCanUpdate\n      exportScheduler {\n        frequency\n        scheduledOn\n        recipients {\n          edges {\n            node {\n              id\n              firstName\n              lastName\n              avatar\n              email\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SavedEventListEditWindowQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "eventList",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "EventList",
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "exportScheduler",
                "storageKey": null,
                "args": null,
                "concreteType": "EventListScheduledExportView",
                "plural": false,
                "selections": [
                  v4,
                  v5,
                  v7
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SavedEventListEditWindowQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "eventList",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v6,
          {
            "kind": "InlineFragment",
            "type": "EventList",
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "exportScheduler",
                "storageKey": null,
                "args": null,
                "concreteType": "EventListScheduledExportView",
                "plural": false,
                "selections": [
                  v4,
                  v5,
                  v7,
                  v6
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c1e82625ed83cc8dc37a29594fa9faf1';
module.exports = node;
