/**
 * @flow
 * @relayHash ca3b950c9e86f31daa538b6ba89a5aca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompanyContactsList_company$ref = any;
type CompanyContactsList_contacts$ref = any;
type CompanyContactsList_org$ref = any;
export type ContactSort = "ATTENDANCE_DURATION" | "ATTENDANCE_JOIN_TIME" | "ATTENDANCE_LEAVE_TIME" | "ATTENDANCE_STATUS_ID" | "CITY" | "COMPANY_ID" | "CONTACT_TYPE_ID" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CREATED_METHOD" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "EMAIL_OPT_IN" | "EVENTS" | "HOT_LEAD" | "LAST_SYNCED" | "LINKEDIN" | "MARKETO_ID" | "MEMBERSHIP_LAST_SYNCED" | "MEMBERSHIP_SYNC_STATUS" | "NAME" | "OWNER_ID" | "PHONE1" | "PHONE2" | "REGISTERED_AT" | "REGISTRATION_STATUS_ID" | "REQUEST_SUBMISSIONS" | "SALESFORCE_ID" | "SALESFORCE_LEAD_STATUS_ID" | "SALESFORCE_SYNC_AS" | "STATE" | "STREET" | "SYNC_STATUS" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "UPDATED_METHOD" | "WEBSITE" | "ZIP";
export type Direction = "ASC" | "DESC";
export type CompanyContactsQueryVariables = {|
  companyId: string,
  sort?: ?ContactSort,
  direction: Direction,
|};
export type CompanyContactsQueryResponse = {|
  +company: ?{|
    +contacts?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +$fragmentRefs: CompanyContactsList_contacts$ref,
        |}
      |}>
    |},
    +$fragmentRefs: CompanyContactsList_company$ref,
  |},
  +org: {|
    +id: string,
    +$fragmentRefs: CompanyContactsList_org$ref,
  |},
|};
export type CompanyContactsQuery = {|
  variables: CompanyContactsQueryVariables,
  response: CompanyContactsQueryResponse,
|};
*/


/*
query CompanyContactsQuery(
  $companyId: ID!
  $sort: ContactSort
  $direction: Direction!
) {
  company: node(id: $companyId) {
    __typename
    ... on Company {
      ...CompanyContactsList_company
      contacts(first: 1000, sort: $sort, direction: $direction) {
        edges {
          node {
            id
            ...CompanyContactsList_contacts
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    id
  }
  org {
    id
    ...CompanyContactsList_org
  }
}

fragment CompanyContactsList_company on Company {
  id
  ...CompanyContactActionsColumn_company
}

fragment CompanyContactsList_contacts on Contact {
  id
  ...CompanyContactEmail_contact
  ...CompanyContactName_contact
  ...CompanyContactTitle_contact
  ...CompanyContactActionsColumn_contact
}

fragment CompanyContactsList_org on Org {
  id
  dbId
  customFields(customizableType: [EVENTCONTACT]) {
    edges {
      node {
        id
        fieldName
        label
        required
        order
        kind
        options {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}

fragment CompanyContactEmail_contact on Contact {
  email
}

fragment CompanyContactName_contact on Contact {
  slug
  email
  firstName
  lastName
}

fragment CompanyContactTitle_contact on Contact {
  title
}

fragment CompanyContactActionsColumn_contact on Contact {
  id
}

fragment CompanyContactActionsColumn_company on Company {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "companyId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "ContactSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = [
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction",
    "type": "Direction"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "ContactSort"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CompanyContactsQuery",
  "id": null,
  "text": "query CompanyContactsQuery(\n  $companyId: ID!\n  $sort: ContactSort\n  $direction: Direction!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      ...CompanyContactsList_company\n      contacts(first: 1000, sort: $sort, direction: $direction) {\n        edges {\n          node {\n            id\n            ...CompanyContactsList_contacts\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n  org {\n    id\n    ...CompanyContactsList_org\n  }\n}\n\nfragment CompanyContactsList_company on Company {\n  id\n  ...CompanyContactActionsColumn_company\n}\n\nfragment CompanyContactsList_contacts on Contact {\n  id\n  ...CompanyContactEmail_contact\n  ...CompanyContactName_contact\n  ...CompanyContactTitle_contact\n  ...CompanyContactActionsColumn_contact\n}\n\nfragment CompanyContactsList_org on Org {\n  id\n  dbId\n  customFields(customizableType: [EVENTCONTACT]) {\n    edges {\n      node {\n        id\n        fieldName\n        label\n        required\n        order\n        kind\n        options {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CompanyContactEmail_contact on Contact {\n  email\n}\n\nfragment CompanyContactName_contact on Contact {\n  slug\n  email\n  firstName\n  lastName\n}\n\nfragment CompanyContactTitle_contact on Contact {\n  title\n}\n\nfragment CompanyContactActionsColumn_contact on Contact {\n  id\n}\n\nfragment CompanyContactActionsColumn_company on Company {\n  id\n}\n",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "contacts"
        ]
      }
    ]
  },
  "fragment": {
    "kind": "Fragment",
    "name": "CompanyContactsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "company",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Company",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "CompanyContactsList_company",
                "args": null
              },
              {
                "kind": "LinkedField",
                "alias": "contacts",
                "name": "__CompanyContactsList_contacts_connection",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Contact",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "FragmentSpread",
                            "name": "CompanyContactsList_contacts",
                            "args": null
                          },
                          v3
                        ]
                      },
                      v4
                    ]
                  },
                  v5
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "FragmentSpread",
            "name": "CompanyContactsList_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CompanyContactsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "company",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          v3,
          v2,
          {
            "kind": "InlineFragment",
            "type": "Company",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contacts",
                "storageKey": null,
                "args": v6,
                "concreteType": "ContactRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Contact",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "slug",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          v3
                        ]
                      },
                      v4
                    ]
                  },
                  v5
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "contacts",
                "args": v6,
                "handle": "connection",
                "key": "CompanyContactsList_contacts",
                "filters": []
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dbId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"EVENTCONTACT\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "customizableType",
                "value": [
                  "EVENTCONTACT"
                ],
                "type": "[CustomizableType!]!"
              }
            ],
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fieldName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "required",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "kind",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "options",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionTypeRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldOptionTypeRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CustomFieldOptionType",
                                "plural": false,
                                "selections": [
                                  v2,
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0eb34e1b86bcf78da47e9bf5467e256';
module.exports = node;
