/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import paymentTypes from 'config/paymentTypes';

import { type CellPropsType } from 'components/budget/Table';
import InlineSelectField from 'components/budget/Table/InlineSelectField';

import { type PaymentCellPropsType } from './index';

import { type PaymentTypeCell_payment } from './__generated__/PaymentTypeCell_payment.graphql';

const PaymentTypeCell = ({
  payment,
  updatePayment,
  updateColumnWidth,
}: CellPropsType<PaymentCellPropsType<PaymentTypeCell_payment>>) => (
  <InlineSelectField
    value={payment.paymentType}
    options={Object.keys(paymentTypes).map(key => ({ value: key, label: paymentTypes[key] }))}
    onChange={paymentType => {
      if (!paymentType) return;

      updatePayment({ paymentType });

      updateColumnWidth();
    }}
  />
);

export default createFragmentContainer(
  PaymentTypeCell,
  graphql`
    fragment PaymentTypeCell_payment on Payment {
      paymentType
    }
  `,
);
