/* @flow */

const tshirtSizes = {
  XS: 'X-Small',
  S: 'Small',
  M: 'Medium',
  L: 'Large',
  XL: 'X-Large',
};

export type TshirtSizeType = $Keys<typeof tshirtSizes>;

export default tshirtSizes;
