/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { type Match, type RouterHistory, Route } from 'react-router-dom';
import styled from 'styled-components';
import omit from 'lodash/omit';

import withQueryParams from 'utils/routing/withQueryParams';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import TaskWindow from 'components/Tasks/TaskWindow';

import parseTaskFilters from './components/parseTaskFilters';
import Tasks, { VIEW_MODES } from './Tasks';

import type { TasksQueryResponse } from './__generated__/TasksQuery.graphql';

const query = graphql`
  query TasksQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...Tasks_event
    }
    org {
      ...TaskWindow_org
      ...Tasks_org
    }
    me {
      email
    }
  }
`;

const Container = styled.div`
  flex: 1 1 auto;
`;

class TasksRoot extends React.Component<{
  match: Match,
  history: RouterHistory,
  queryParams: string,
}> {
  render() {
    const taskWindowDisabled =
      !this.props.match.params.taskSlug || VIEW_MODES.includes(this.props.match.params.taskSlug);

    const filters = parseTaskFilters(this.props.queryParams);
    return (
      <Container>
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug: this.props.match.params.event_slug }}
          renderSuccess={(props: TasksQueryResponse) => (
            <>
              <Tasks
                org={props.org}
                event={props.event}
                history={this.props.history}
                match={this.props.match}
                queryParams={this.props.queryParams}
                userEmail={props.me.email}
                filters={omit(filters, 'eventDate')}
              />
              {!taskWindowDisabled && (
                <Route
                  exact
                  path={`${this.props.match.path}/:action?`}
                  render={routeProps => <TaskWindow org={props.org} {...routeProps} />}
                />
              )}
            </>
          )}
        />
      </Container>
    );
  }
}

export default withQueryParams(TasksRoot);
