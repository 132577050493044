/**
 * @flow
 * @relayHash 639dbcf1dba8b4f5bc43c49c672718f8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MarketoProgramPicker_org$ref = any;
export type MarketoProgramPickerRefetchQueryVariables = {|
  loadPrograms: boolean
|};
export type MarketoProgramPickerRefetchQueryResponse = {|
  +org: {|
    +$fragmentRefs: MarketoProgramPicker_org$ref
  |}
|};
export type MarketoProgramPickerRefetchQuery = {|
  variables: MarketoProgramPickerRefetchQueryVariables,
  response: MarketoProgramPickerRefetchQueryResponse,
|};
*/


/*
query MarketoProgramPickerRefetchQuery(
  $loadPrograms: Boolean!
) {
  org {
    ...MarketoProgramPicker_org_2Sboz3
    id
  }
}

fragment MarketoProgramPicker_org_2Sboz3 on Org {
  marketoAccount {
    id
    marketoPrograms @include(if: $loadPrograms) {
      edges {
        node {
          marketoId
          name
          url
          event {
            name
            slug
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "loadPrograms",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MarketoProgramPickerRefetchQuery",
  "id": null,
  "text": "query MarketoProgramPickerRefetchQuery(\n  $loadPrograms: Boolean!\n) {\n  org {\n    ...MarketoProgramPicker_org_2Sboz3\n    id\n  }\n}\n\nfragment MarketoProgramPicker_org_2Sboz3 on Org {\n  marketoAccount {\n    id\n    marketoPrograms @include(if: $loadPrograms) {\n      edges {\n        node {\n          marketoId\n          name\n          url\n          event {\n            name\n            slug\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MarketoProgramPickerRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "MarketoProgramPicker_org",
            "args": [
              {
                "kind": "Variable",
                "name": "loadPrograms",
                "variableName": "loadPrograms",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MarketoProgramPickerRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketoAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketoAccount",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "loadPrograms",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "marketoPrograms",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MarketoProgramRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "MarketoProgramRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MarketoProgram",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "marketoId",
                                "args": null,
                                "storageKey": null
                              },
                              v2,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "url",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "event",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Event",
                                "plural": false,
                                "selections": [
                                  v2,
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "slug",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  v1
                                ]
                              },
                              v1
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v1
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc8b2a38c1484c2adc326831f75a8685';
module.exports = node;
