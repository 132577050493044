/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';

const mutation = graphql`
  mutation removeAttachmentFromBriefMutation($input: RemoveAttachmentFromBriefInput!) {
    removeAttachmentFromBrief(input: $input) {
      removedId
    }
  }
`;

export default function removeAttachmentFromBrief(attachmentId: string, eventId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        attachmentId,
        eventId,
      },
    },
    updater: removeRecordFromConnection({
      deletedIdField: 'removedId',
      parentId: eventId,
      connections: [{ field: 'briefAttachments' }],
    }),
  });
}
