/**
 * @flow
 * @relayHash 2918788887c1b53b7ec13cce6ef0a7cf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Homepage = "ALL_TASKS" | "EVENTS" | "MY_EVENTS" | "MY_TASKS" | "UPCOMING_EVENTS";
export type TshirtSize = "L" | "M" | "S" | "XL" | "XS";
export type UpdateUserInput = {
  clientMutationId?: ?string,
  userId: string,
  firstName?: ?string,
  lastName?: ?string,
  title?: ?string,
  company?: ?string,
  email?: ?string,
  phone?: ?string,
  officePhone?: ?string,
  tshirtSize?: ?string,
  bio?: ?string,
  avatar?: ?string,
  onboardingStepNumber?: ?number,
  metricUnits?: ?boolean,
  homepage?: ?Homepage,
  homepageListId?: ?string,
  role?: ?string,
  goals?: ?string,
  estimatedNumberOfEvents?: ?string,
  tz?: ?string,
  fromWindow?: ?string,
};
export type updateUserMutationVariables = {|
  input: UpdateUserInput,
  changingFirstName: boolean,
  changingLastName: boolean,
  changingAvatar: boolean,
  changingCompany: boolean,
  changingTitle: boolean,
  changingEmail: boolean,
  changingPhone: boolean,
  changingOfficePhone: boolean,
  changingTshirtSize: boolean,
  changingBio: boolean,
  changingOnboardingStepNumber: boolean,
  changingHomepage: boolean,
  changingHomepageListId: boolean,
  changingMetricUnits: boolean,
  changingTz: boolean,
  changingRole: boolean,
  changingGoals: boolean,
  changingEstimatedNumberOfEvents: boolean,
|};
export type updateUserMutationResponse = {|
  +updateUser: ?{|
    +user: {|
      +id: string,
      +firstName?: string,
      +lastName?: string,
      +avatar?: ?string,
      +company?: ?string,
      +title?: ?string,
      +email?: string,
      +phone?: ?string,
      +officePhone?: ?string,
      +tshirtSize?: ?TshirtSize,
      +bio?: ?string,
      +onboardingStepNumber?: ?number,
      +homepage?: Homepage,
      +homepageListId?: ?string,
      +metricUnits?: boolean,
      +tz?: string,
      +role?: ?string,
      +goals?: ?string,
      +estimatedNumberOfEvents?: ?string,
    |}
  |}
|};
export type updateUserMutation = {|
  variables: updateUserMutationVariables,
  response: updateUserMutationResponse,
|};
*/


/*
mutation updateUserMutation(
  $input: UpdateUserInput!
  $changingFirstName: Boolean!
  $changingLastName: Boolean!
  $changingAvatar: Boolean!
  $changingCompany: Boolean!
  $changingTitle: Boolean!
  $changingEmail: Boolean!
  $changingPhone: Boolean!
  $changingOfficePhone: Boolean!
  $changingTshirtSize: Boolean!
  $changingBio: Boolean!
  $changingOnboardingStepNumber: Boolean!
  $changingHomepage: Boolean!
  $changingHomepageListId: Boolean!
  $changingMetricUnits: Boolean!
  $changingTz: Boolean!
  $changingRole: Boolean!
  $changingGoals: Boolean!
  $changingEstimatedNumberOfEvents: Boolean!
) {
  updateUser(input: $input) {
    user {
      id
      firstName @include(if: $changingFirstName)
      lastName @include(if: $changingLastName)
      avatar @include(if: $changingAvatar)
      company @include(if: $changingCompany)
      title @include(if: $changingTitle)
      email @include(if: $changingEmail)
      phone @include(if: $changingPhone)
      officePhone @include(if: $changingOfficePhone)
      tshirtSize @include(if: $changingTshirtSize)
      bio @include(if: $changingBio)
      onboardingStepNumber @include(if: $changingOnboardingStepNumber)
      homepage @include(if: $changingHomepage)
      homepageListId @include(if: $changingHomepageListId)
      metricUnits @include(if: $changingMetricUnits)
      tz @include(if: $changingTz)
      role @include(if: $changingRole)
      goals @include(if: $changingGoals)
      estimatedNumberOfEvents @include(if: $changingEstimatedNumberOfEvents)
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingFirstName",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingLastName",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingAvatar",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingCompany",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingTitle",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingEmail",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingPhone",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingOfficePhone",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingTshirtSize",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingBio",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingOnboardingStepNumber",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingHomepage",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingHomepageListId",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingMetricUnits",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingTz",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingRole",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingGoals",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingEstimatedNumberOfEvents",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateUser",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateUserInput!"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingFirstName",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingLastName",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingAvatar",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "avatar",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingCompany",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "company",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingTitle",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingEmail",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingPhone",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "phone",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingOfficePhone",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "officePhone",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingTshirtSize",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tshirtSize",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingBio",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "bio",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingOnboardingStepNumber",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "onboardingStepNumber",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingHomepage",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "homepage",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingHomepageListId",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "homepageListId",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingMetricUnits",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "metricUnits",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingTz",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tz",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingRole",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "role",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingGoals",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "goals",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "changingEstimatedNumberOfEvents",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "estimatedNumberOfEvents",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateUserMutation",
  "id": null,
  "text": "mutation updateUserMutation(\n  $input: UpdateUserInput!\n  $changingFirstName: Boolean!\n  $changingLastName: Boolean!\n  $changingAvatar: Boolean!\n  $changingCompany: Boolean!\n  $changingTitle: Boolean!\n  $changingEmail: Boolean!\n  $changingPhone: Boolean!\n  $changingOfficePhone: Boolean!\n  $changingTshirtSize: Boolean!\n  $changingBio: Boolean!\n  $changingOnboardingStepNumber: Boolean!\n  $changingHomepage: Boolean!\n  $changingHomepageListId: Boolean!\n  $changingMetricUnits: Boolean!\n  $changingTz: Boolean!\n  $changingRole: Boolean!\n  $changingGoals: Boolean!\n  $changingEstimatedNumberOfEvents: Boolean!\n) {\n  updateUser(input: $input) {\n    user {\n      id\n      firstName @include(if: $changingFirstName)\n      lastName @include(if: $changingLastName)\n      avatar @include(if: $changingAvatar)\n      company @include(if: $changingCompany)\n      title @include(if: $changingTitle)\n      email @include(if: $changingEmail)\n      phone @include(if: $changingPhone)\n      officePhone @include(if: $changingOfficePhone)\n      tshirtSize @include(if: $changingTshirtSize)\n      bio @include(if: $changingBio)\n      onboardingStepNumber @include(if: $changingOnboardingStepNumber)\n      homepage @include(if: $changingHomepage)\n      homepageListId @include(if: $changingHomepageListId)\n      metricUnits @include(if: $changingMetricUnits)\n      tz @include(if: $changingTz)\n      role @include(if: $changingRole)\n      goals @include(if: $changingGoals)\n      estimatedNumberOfEvents @include(if: $changingEstimatedNumberOfEvents)\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateUserMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9f2f46f4e67f4b7b476d827cbc99ab9';
module.exports = node;
