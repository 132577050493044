import './styles.styl';

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import Clipboard from 'clipboard';
import pick from 'lodash/pick';
import uniqueId from 'lodash/uniqueId';

export class TextField extends React.Component {
  inputId = uniqueId('input');

  static propTypes = {
    copyAble: PropTypes.bool,
    onCopy: PropTypes.func,
    value: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    caption: PropTypes.string,
    invalid: PropTypes.string,
    autoSelect: PropTypes.bool,
    className: PropTypes.string,

    autoFocus: PropTypes.bool,
    required: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    autoComplete: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    placeholder: PropTypes.string,
    emailDisabled: PropTypes.bool,
  };

  componentDidMount() {
    if (this.props.copyAble) {
      new Clipboard(this.field, {
        text: () => this.props.value,
      }).on('success', () => {
        if (this.props.onCopy) {
          this.props.onCopy();
        }

        clearTimeout(this.timeout);

        if (this.copyMessage) {
          this.copyMessage.classList.add('shown');
          this.timeout = setTimeout(() => {
            this.copyMessage.classList.remove('shown');
          }, 2000);
        }
      });
    }
    if (this.props.autoSelect) {
      this.field.select();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  value() {
    return this.field.value;
  }

  input() {
    return this.field;
  }

  render() {
    const classes = classNames(
      'form-text-field',
      {
        invalid: !!this.props.invalid,
        copyable: this.props.copyAble,
        'with-icon': this.props.icon,
      },
      this.props.className,
    );

    const inputAttrs = pick(this.props, [
      'onChange',
      'onSelect',
      'onBlur',
      'onFocus',
      'onKeyDown',
      'placeholder',
      'autoFocus',
      'name',
      'autoComplete',
      'defaultValue',
      'type',
      'value',
      'maxLength',
    ]);

    return (
      <div className={classes}>
        <label htmlFor={this.inputId}>
          {this.props.label ? (
            <div className="form-label">
              {this.props.label}
              {this.props.required && <span className="required-indicator">*</span>}
            </div>
          ) : null}
          <div className="form-input">
            {this.props.icon && (
              <i className={`fa fa-fw fa-${this.props.icon} form-text-field-icon`} />
            )}
            <input
              type="text"
              ref={field => {
                this.field = field;
              }}
              id={this.inputId}
              {...inputAttrs}
              readOnly={this.props.copyAble}
              disabled={this.props.emailDisabled}
            />
            {this.props.caption && <small>{this.props.caption}</small>}
            {this.props.invalid && (
              <div className="form-text-field-error">{this.props.invalid}</div>
            )}
          </div>
          {this.props.copyAble && (
            <span
              className="copy-sucessful-message"
              ref={copyMessage => {
                this.copyMessage = copyMessage;
              }}
            >
              Copied to clipboard!
            </span>
          )}
        </label>
      </div>
    );
  }
}
