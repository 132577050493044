/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineCheckBoxField from 'components/budget/Table/InlineCheckBoxField';

import type { CustomizableBoolean_customizable } from './__generated__/CustomizableBoolean_customizable.graphql';

class CustomizableBoolean extends React.Component<{
  customizable: CustomizableBoolean_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => Promise<void>,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
}> {
  getValue = (): boolean => {
    const { customizable, fieldSettings } = this.props;
    const customBooleanField = customizable.customBooleanFields.find(
      booleanField => booleanField.customField.id === fieldSettings.id,
    );
    return customBooleanField ? customBooleanField.value : false;
  };

  handleSave = (value: boolean): void => {
    const { onUpdateCustomField, updateColumnWidth, customizable, fieldSettings } = this.props;
    if (value !== this.getValue() && onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        booleanValue: value,
      });
      updateColumnWidth();
    }
  };

  render() {
    const { fieldSettings, readOnly, customizable } = this.props;

    if (!customizable) {
      return null;
    }

    return (
      <InlineCheckBoxField
        value={this.getValue()}
        onChange={this.handleSave}
        disabled={fieldSettings.required || readOnly || fieldSettings.restrictChangingValue}
        showLockIcon={!readOnly && fieldSettings.restrictChangingValue}
      />
    );
  }
}

export default createFragmentContainer(
  CustomizableBoolean,
  graphql`
    fragment CustomizableBoolean_customizable on CustomizableInterface {
      id
      __typename
      customBooleanFields {
        customField {
          id
        }
        value
      }
    }
  `,
);
