/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { sortBy } from 'lodash';

import NoResult from 'images/noRegistrationForm.svg';
import EmptyView from 'components/budget/EmptyView';

import generateFontFromProps from '../RegistrationCreate/RegistrationPageContent/generateFontFromProps';
import UIContext from '../UIContext';
import RegistrationFormFieldLogic, { Block } from './RegistrationFormFieldLogic';

import type { RegistrationLogicContent_org } from './__generated__/RegistrationLogicContent_org.graphql';
import type { RegistrationLogicContent_registrationForm } from './__generated__/RegistrationLogicContent_registrationForm.graphql';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  font: ${props => generateFontFromProps({ fontFamily: props.fontFamily })};
`;

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 40px 30px 0;
  overflow-y: auto;
  scroll-behavior: smooth;
  @media (${props => props.theme.mobileOnly}) {
    padding-left: 25px;
  }
`;

const Content = styled.div`
  max-width: 755px;
  width: 100%;
`;

const StyledEmptyView = styled(EmptyView)`
  margin-top: 50px;
  margin-bottom: 50px;
  font: ${props => generateFontFromProps({ fontFamily: props.fontFamily })};
`;

class RegistrationLogicContent extends React.PureComponent<{
  registrationForm: RegistrationLogicContent_registrationForm,
  org: RegistrationLogicContent_org,
  tz: string,
}> {
  getFormComponent = () => {
    const registrationForm = this.props.registrationForm;

    const formComponentEdge = registrationForm.pageComponents.edges[0];
    if (!formComponentEdge || !formComponentEdge.node.formComponent) {
      return null;
    }
    return formComponentEdge.node.formComponent;
  };

  renderInner = fontFamily => {
    const formComponent = this.getFormComponent();
    if (!formComponent || formComponent.contactForm.contactFormFields.edges.length === 0) {
      return (
        <Block readOnly>
          <StyledEmptyView
            fontFamily={fontFamily}
            message="To setup logic, add some questions to the form."
            icon={<NoResult />}
          />
        </Block>
      );
    }

    const { org, tz } = this.props;
    const contactFormFields = formComponent.contactForm.contactFormFields.edges.map(
      edge => edge.node,
    );

    return sortBy(contactFormFields, 'order').map(contactFormField => (
      <RegistrationFormFieldLogic
        key={contactFormField.id}
        contactFormField={contactFormField}
        contactFormFields={contactFormFields}
        org={org}
        tz={tz}
      />
    ));
  };

  render() {
    return (
      <UIContext.Consumer>
        {({ fontFamily }) => (
          <Container fontFamily={fontFamily}>
            <ContentWrapper>
              <Content>{this.renderInner(fontFamily)}</Content>
            </ContentWrapper>
          </Container>
        )}
      </UIContext.Consumer>
    );
  }
}

export default createFragmentContainer(RegistrationLogicContent, {
  org: graphql`
    fragment RegistrationLogicContent_org on Org {
      ...RegistrationFormFieldLogic_org
    }
  `,
  registrationForm: graphql`
    fragment RegistrationLogicContent_registrationForm on RegistrationForm {
      pageComponents(kind: [FORM]) {
        edges {
          node {
            kind
            formComponent {
              contactForm {
                contactFormFields {
                  edges {
                    node {
                      id
                      order
                      ...RegistrationFormFieldLogic_contactFormFields
                      ...RegistrationFormFieldLogic_contactFormField
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
