/* @flow */
import * as React from 'react';

import QuestionAmount from '../Question/QuestionAmount';
import QuestionBoolean from '../Question/QuestionBoolean';
import QuestionDate from '../Question/QuestionDate';
import QuestionInput from '../Question/QuestionInput';
import QuestionLink from '../Question/QuestionLink';
import QuestionNumber from '../Question/QuestionNumber';
import QuestionMultiSelectField from '../Question/QuestionSelectOptions/QuestionMultiSelectField';
import QuestionSelectField from '../Question/QuestionSelectOptions/QuestionSelectField';
import QuestionTextArea from '../Question/QuestionTextArea';
import QuestionUserSelectOptions from '../Question/QuestionUserSelectOptions';
import mappingCustomKinds from './mappingCustomKinds';
import { type QuestionType, type QuestionValueInputType, type QuestionValueType } from './types';

const mappingCustomComponents: $ObjMapi<
  typeof mappingCustomKinds,
  () => React.ComponentType<
    $Shape<{
      question: QuestionType,
      value: QuestionValueType,
      onChangeValue: QuestionValueInputType => void,
      handleShouldValidate?: (shouldValidate: boolean) => void,
      usedBudgetCategoeryIds?: $ReadOnlyArray<string>,
      readOnly: boolean,
      currency: string,
      tz: string,
      error: ?string,
      onChangeShowError: (showError: boolean) => void,
    }>,
  >,
> = {
  DEFAULT: () => null,
  TEXT: QuestionInput,
  TEXTAREA: QuestionTextArea,
  LINK: QuestionLink,
  NUMBER: QuestionNumber,
  CURRENCY: QuestionAmount,
  DATE: QuestionDate,
  BOOLEAN: QuestionBoolean,
  USER_SELECT: QuestionUserSelectOptions,
  USER_MULTISELECT: QuestionUserSelectOptions,
  SELECT: QuestionSelectField,
  MULTISELECT: QuestionMultiSelectField,
};

export default mappingCustomComponents;
