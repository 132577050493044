/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import EventRequestsItemRequestedBy from 'views/Main/Dashboard/EventRequests/columns/EventRequestsItemRequestedBy';

import type { EventRequestsRequestedBy_event } from './__generated__/EventRequestsRequestedBy_event.graphql';
import type { EventRequestsRequestedBy_org } from './__generated__/EventRequestsRequestedBy_org.graphql';

const StyledEventRequestsItemRequestedBy = styled(EventRequestsItemRequestedBy)`
  padding-right: 17px;
  margin: -6px -18px -6px -10px;
`;

const EventRequestsRequestedBy = (props: {
  org: EventRequestsRequestedBy_org,
  event: EventRequestsRequestedBy_event,
  readOnly: boolean,
}) => {
  const { event, org, readOnly } = props;
  if (!event.eventRequestSubmission) {
    return null;
  }

  return (
    <StyledEventRequestsItemRequestedBy
      eventRequestSubmission={event.eventRequestSubmission}
      orgName={org.name}
      readOnly={readOnly}
    />
  );
};

export default createFragmentContainer(EventRequestsRequestedBy, {
  event: graphql`
    fragment EventRequestsRequestedBy_event on Event {
      eventRequestSubmission {
        ...EventRequestsItemRequestedBy_eventRequestSubmission
      }
    }
  `,
  org: graphql`
    fragment EventRequestsRequestedBy_org on Org {
      name
    }
  `,
});
