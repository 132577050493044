/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

const mutation = graphql`
  mutation addContactToBriefMutation($input: AddContactToBriefInput!) {
    addContactToBrief(input: $input) {
      contactEdge {
        __typename
        node {
          id
        }
      }
    }
  }
`;

export default function addContactToBrief(contactId: string, eventId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        contactId,
        eventId,
      },
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'contactEdge',
      connections: [{ field: 'briefContacts' }],
    }),
  });
}
