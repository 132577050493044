/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: ${props => props.theme.hoverRowColor};
  border-radius: 3px;
`;

export default class DraggingSubtaskItem extends React.PureComponent<{
  html: string,
  width: number,
}> {
  render() {
    return (
      <Container
        dangerouslySetInnerHTML={{ __html: this.props.html }}
        style={{ width: this.props.width }}
      />
    );
  }
}
