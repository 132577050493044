/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import Dropdown from 'components/budget/Dropdown';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import { NoSearchResultsMessage } from './components';
import EventsSearchPagination from './EventsSearchPagination';
import type { ContentComponentProps } from './SearchOverlay';

import type { EventsSearchContentQueryResponse } from './__generated__/EventsSearchContentQuery.graphql';

const FiltersContainer = styled.div`
  margin: 15px 0 0 25px;

  > div {
    width: 110px;
  }
`;

export type DateFilterValue = 'UPCOMING' | 'ALL' | 'RECENT' | 'PAST';

const searchQuery = graphql`
  query EventsSearchContentQuery(
    $search: Search!
    $eventFilters: EventFilters!
    $count: Int!
    $cursor: String
  ) {
    eventsTotalCount: searchEvents(search: $search, filters: $eventFilters) {
      totalCount
    }
    ...EventsSearchPagination_searchResults
  }
`;

export default class EventsSearchContent extends React.PureComponent<
  ContentComponentProps,
  {
    dateFilter: DateFilterValue,
  },
> {
  state = { dateFilter: 'ALL' };

  handleShowAllResults = () => {
    const { query, exactMatch } = this.props;
    const searchString = `${query}${exactMatch ? '~EXACT' : ''}`;
    const listType = this.state.dateFilter.toLowerCase();
    const dateFilter = listType === 'all' ? '' : `&date=${listType}`;
    window.open(
      `/dashboard/${listType}_events?requestStatuses=N_A,APPROVE&search=${searchString}${dateFilter}`,
      '_blank',
    );
  };

  handleChangeDateFilter = (dateFilter: ?DateFilterValue) => {
    if (dateFilter != null) {
      this.setState({ dateFilter });
    }
  };

  render() {
    const { query, exactMatch } = this.props;
    const dateFilter = this.state.dateFilter;

    const variables = {
      search: {
        search: query,
        exactMatch,
      },
      eventFilters: {
        requestStatuses: ['N_A', 'APPROVED'],
        listType: dateFilter === 'ALL' ? undefined : dateFilter,
        includeUndated: dateFilter === 'UPCOMING',
      },
      count: 10,
    };

    return (
      <>
        <FiltersContainer>
          <Dropdown
            value={dateFilter}
            options={[
              {
                value: 'UPCOMING',
                label: 'Upcoming',
              },
              {
                value: 'ALL',
                label: 'All',
              },
              {
                value: 'RECENT',
                label: 'Recent',
              },
              {
                value: 'PAST',
                label: 'Past',
              },
            ]}
            onChange={this.handleChangeDateFilter}
          />
        </FiltersContainer>
        <DefaultQueryRenderer
          query={searchQuery}
          variables={variables}
          renderSuccess={(response: ?EventsSearchContentQueryResponse) => {
            if (response == null) {
              return null;
            }

            if (response.eventsTotalCount.totalCount === 0) {
              return <NoSearchResultsMessage query={query} />;
            }

            return (
              <EventsSearchPagination
                mobileView
                query={query}
                searchResults={response}
                totalCount={response.eventsTotalCount.totalCount}
                onShowAllResults={this.handleShowAllResults}
                isolated
              />
            );
          }}
        />
      </>
    );
  }
}
