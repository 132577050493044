/* @flow */
import React from 'react';
import { type RelayRefetchProp, createRefetchContainer, graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import Loader from 'components/Loader';

import UIContext from '../UIContext';
import RegistrationCreateContent from './RegistrationCreateContent';

import type { RegistrationCreateRefetchContainer_query } from './__generated__/RegistrationCreateRefetchContainer_query.graphql';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

class RegistrationCreateRefetchContainer extends React.PureComponent<
  {
    match: Match,
    history: History,
    location: Location,
    discardCounter?: number,
    query: RegistrationCreateRefetchContainer_query,
    relay: RelayRefetchProp,
  },
  {
    loading: boolean,
  },
> {
  state = { loading: false };

  componentDidUpdate(prevProps: $PropertyType<RegistrationCreateRefetchContainer, 'props'>) {
    const { discardCounter, match, relay } = this.props;
    if (discardCounter !== prevProps.discardCounter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: true });
      const eventSlug = match.params.eventSlug;
      relay.refetch(
        { eventSlug, isEventRegForm: !!eventSlug },
        null,
        () => {
          this.setState({ loading: false });
        },
        { force: true },
      );
    }
  }

  render() {
    const {
      match,
      history,
      location,
      query: { event, org },
    } = this.props;
    if (this.state.loading) {
      return (
        <UIContext.Consumer>
          {({ loaderColor }) => (
            <LoaderContainer>
              <Loader color={loaderColor} size={30} />
            </LoaderContainer>
          )}
        </UIContext.Consumer>
      );
    }

    return (
      <RegistrationCreateContent
        registrationForm={event != null ? event.editableRegistrationForm : org.registrationForm}
        event={event || null}
        org={org}
        match={match}
        history={history}
        location={location}
      />
    );
  }
}

export default createRefetchContainer(
  RegistrationCreateRefetchContainer,
  graphql`
    fragment RegistrationCreateRefetchContainer_query on Query {
      event(slug: $eventSlug) @include(if: $isEventRegForm) {
        id
        ...RegistrationCreateContent_event
        editableRegistrationForm {
          ...RegistrationCreateContent_registrationForm
        }
      }
      org {
        id
        ...RegistrationCreateContent_org
        registrationForm @skip(if: $isEventRegForm) {
          ...RegistrationCreateContent_registrationForm
        }
      }
    }
  `,
  graphql`
    query RegistrationCreateRefetchContainerQuery($eventSlug: String, $isEventRegForm: Boolean!) {
      ...RegistrationCreateRefetchContainer_query
    }
  `,
);
