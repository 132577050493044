/**
 * @flow
 * @relayHash 2c4a79ebd0b71267c223ee0b6022f7e6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDeliverablesInput = {
  clientMutationId?: ?string,
  deliverableIds: $ReadOnlyArray<string>,
  name?: ?string,
  description?: ?string,
  dueDate?: ?any,
  dueDateAllDay?: ?boolean,
  remindAt?: ?any,
  fromWindow: string,
};
export type updateTasksMutationVariables = {|
  input: UpdateDeliverablesInput
|};
export type updateTasksMutationResponse = {|
  +updateDeliverables: ?{|
    +deliverables: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +dueDate: ?any,
      +dueDateAllDay: ?boolean,
      +description: ?string,
      +remindAt: ?any,
    |}>
  |}
|};
export type updateTasksMutation = {|
  variables: updateTasksMutationVariables,
  response: updateTasksMutationResponse,
|};
*/


/*
mutation updateTasksMutation(
  $input: UpdateDeliverablesInput!
) {
  updateDeliverables(input: $input) {
    deliverables {
      id
      name
      dueDate
      dueDateAllDay
      description
      remindAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDeliverablesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateDeliverables",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateDeliverablesInput!"
      }
    ],
    "concreteType": "UpdateDeliverablesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "Deliverable",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dueDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dueDateAllDay",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "remindAt",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateTasksMutation",
  "id": null,
  "text": "mutation updateTasksMutation(\n  $input: UpdateDeliverablesInput!\n) {\n  updateDeliverables(input: $input) {\n    deliverables {\n      id\n      name\n      dueDate\n      dueDateAllDay\n      description\n      remindAt\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateTasksMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateTasksMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ebb64aafce47da707eb800ce1c559701';
module.exports = node;
