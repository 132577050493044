/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import ExternalLinkIcon from 'images/external.svg';
import Divider from 'components/Sidebar/Divider';
import Footer from 'components/Sidebar/Footer';
import Header from 'components/Sidebar/Header';
import MenuItem from 'components/Sidebar/MenuItem';

import DashboardMenuSavedViews from './DashboardMenuSavedViews';

import type { DashboardMenu_org } from './__generated__/DashboardMenu_org.graphql';
import type { DashboardMenu_user } from './__generated__/DashboardMenu_user.graphql';
import { type DashboardMenuSavedViewsQueryResponse } from './__generated__/DashboardMenuSavedViewsQuery.graphql';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledMenuItem = styled(MenuItem)`
  svg {
    margin-left: 8px;
  }
  &:hover {
    svg {
      path:not(:first-of-type) {
        fill: #000;
      }
    }
  }
`;

const Title = styled.div`
  margin-bottom: 25px;
  margin-left: 9px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
`;

const MenuItemBadge = styled.span`
  height: 19px;
  margin-left: 10px;
  padding: 1px 6px;
  border-radius: 9.5px;
  font-size: 12px;
  color: #fff;
  background-color: #54d4bb;
`;

const SectionTitle = styled.div`
  margin-bottom: 10px;
  padding: 0 10px 5px 13px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.42);
`;

type Props = {
  org: DashboardMenu_org,
  user: DashboardMenu_user,
  pathPrefix: string,
  cachedResponse: ?DashboardMenuSavedViewsQueryResponse,
  onChangeCachedResponse: DashboardMenuSavedViewsQueryResponse => void,
};

const DashboardMenu = ({
  org,
  user,
  pathPrefix,
  cachedResponse,
  onChangeCachedResponse,
}: Props) => {
  const { viewerCanImport, viewerCanUpdate, viewerCanViewEventRequestSubmissions } = org;
  const pendingRequestSubmissionsCount = org.eventRequestSubmissions.totalCount;
  const showFooter = viewerCanViewEventRequestSubmissions || viewerCanUpdate || viewerCanImport;
  return (
    <Menu>
      <Header>
        <Title>Dashboard</Title>
        <SectionTitle>TASKS</SectionTitle>
        <MenuItem to={`${pathPrefix}/all_tasks`}>All tasks</MenuItem>
        <MenuItem to={`${pathPrefix}/my_tasks`}>My tasks</MenuItem>
        <Divider />
        <SectionTitle>EVENTS</SectionTitle>
        <MenuItem to={`${pathPrefix}/upcoming_events?date=upcoming&requestStatuses=N_A,APPROVED`}>
          Upcoming events
        </MenuItem>
        <MenuItem to={`${pathPrefix}/all_events?requestStatuses=N_A,APPROVED`}>All events</MenuItem>
        <MenuItem
          to={`${pathPrefix}/my_events?date=upcoming&requestStatuses=N_A,APPROVED&staffIds=${user.id}`}
        >
          My events
        </MenuItem>
        <MenuItem to={`${pathPrefix}/recent_events?requestStatuses=N_A,APPROVED&date=recent`}>
          Recent events
        </MenuItem>
        <MenuItem
          to={`${pathPrefix}/requested_events?requestStatuses=APPROVED,NEW,RETURNED,SUBMITTED,DECLINED,RESUBMITTED&date=upcoming`}
        >
          Requested events
        </MenuItem>
        <MenuItem to={`${pathPrefix}/past_events?requestStatuses=N_A,APPROVED&date=past`}>
          Past events
        </MenuItem>
        <DashboardMenuSavedViews
          pathPrefix={pathPrefix}
          user={user}
          cachedResponse={cachedResponse}
          onChangeCachedResponse={onChangeCachedResponse}
          viewerCanUpdate={viewerCanUpdate}
        />
      </Header>

      {showFooter && (
        <Footer>
          <Divider />
          {viewerCanViewEventRequestSubmissions && (
            <MenuItem to={`${pathPrefix}/event_requests`}>
              Event requests{' '}
              {pendingRequestSubmissionsCount > 0 && (
                <MenuItemBadge>{pendingRequestSubmissionsCount}</MenuItemBadge>
              )}
            </MenuItem>
          )}
          {viewerCanImport && <MenuItem to={`${pathPrefix}/event_imports`}>Event imports</MenuItem>}
          {viewerCanUpdate && (
            <StyledMenuItem to="/settings/events/event_fields" isActive={() => false}>
              Event settings
              <ExternalLinkIcon />
            </StyledMenuItem>
          )}
        </Footer>
      )}
    </Menu>
  );
};

export default createFragmentContainer(DashboardMenu, {
  org: graphql`
    fragment DashboardMenu_org on Org {
      viewerCanImport
      viewerCanUpdate
      viewerCanViewEventRequestSubmissions
      eventRequestSubmissions(statuses: [NEW, RESUBMITTED]) {
        totalCount
      }
    }
  `,
  user: graphql`
    fragment DashboardMenu_user on User {
      id
      ...DashboardMenuSavedViews_user
    }
  `,
});
