/**
 * @flow
 * @relayHash 7f061d0572c6e1b84bc35bb342a1d32a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AllEventsCalendarView_events$ref = any;
export type Direction = "ASC" | "DESC";
export type EventFormat = "HYBRID" | "PHYSICAL" | "VIRTUAL";
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventRegistrationFormStatus = "DRAFT" | "NOT_AVAILABLE" | "PUBLISHED";
export type EventRequestSubmissionStatusFilter = "APPROVED" | "DECLINED" | "NEW" | "N_A" | "RESUBMITTED" | "RETURNED";
export type EventSort = "ATTENDED_CONTACTS_TOTAL" | "BOOTH" | "BOOTH_DIMENSIONS" | "CITY" | "COMMITMENT_LEVEL" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATOR" | "CUSTOM" | "END_DATE" | "EVENT_FORMAT" | "EVENT_LEAD" | "LAST_SYNCED" | "LOCATION" | "MARKETO_ID" | "NAME" | "OPPORTUNITIES_AMOUNT" | "OPPORTUNITIES_NUMBER" | "PROGRESS" | "REGISTERED_CONTACTS_TOTAL" | "REQUESTED_BY" | "REQUESTED_DATE" | "REQUESTED_FORM_NAME" | "REQUESTED_STATUS" | "SALESFORCE_ID" | "STAFF_COUNT" | "START_DATE" | "STATE" | "STREET" | "SYNC_STATUS" | "TEAM_NAME" | "TEMPLATES_FIRST" | "TOTAL_ACTUAL_COST" | "TOTAL_PAID_COST" | "TOTAL_PLANNED_COST" | "UPDATED_AT" | "UPDATER" | "VENUE_NAME" | "VIRTUAL_LOCATION" | "WEBSITE" | "ZIP_CODE";
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type EventFilters = {
  sort?: ?EventSort,
  direction?: ?Direction,
  customFieldSortId?: ?string,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  queries?: ?$ReadOnlyArray<string>,
  search?: ?Search,
  listType?: ?EventListEnum,
  afterDate?: ?any,
  beforeDate?: ?any,
  includeUndated?: ?boolean,
  excludeTemplates?: ?boolean,
  statuses?: ?$ReadOnlyArray<EventStatus>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  opportunitiesNumber?: ?NumberRangeInput,
  opportunitiesAmount?: ?NumberRangeInput,
  teamIds?: ?$ReadOnlyArray<string>,
  leadIds?: ?$ReadOnlyArray<string>,
  staffIds?: ?$ReadOnlyArray<string>,
  budgetCategoryIds?: ?$ReadOnlyArray<string>,
  fullAccessEvents?: ?boolean,
  onlyWithTasks?: ?boolean,
  venueNames?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  eventFormats?: ?$ReadOnlyArray<EventFormat>,
  eventIds?: ?$ReadOnlyArray<string>,
  excludeEventIds?: ?$ReadOnlyArray<string>,
  updatedAt?: ?DateTimeRange,
  updaterIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  creatorIds?: ?$ReadOnlyArray<string>,
  requesterUserIds?: ?$ReadOnlyArray<string>,
  requesterContactIds?: ?$ReadOnlyArray<string>,
  requestedDate?: ?DateTimeRange,
  requestStatuses?: ?$ReadOnlyArray<EventRequestSubmissionStatusFilter>,
  requestFormIds?: ?$ReadOnlyArray<string>,
  requestReviewers?: ?$ReadOnlyArray<string>,
  registeredContactsTotal?: ?NumberRangeInput,
  attendedContactsTotal?: ?NumberRangeInput,
  registrationFormStatuses?: ?$ReadOnlyArray<EventRegistrationFormStatus>,
  contactsCount?: ?NumberRangeInput,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type AllEventsCalendarSharePageContainerQueryVariables = {|
  isShared: boolean,
  publicRuleName: string,
  resourceToken: string,
  orgSlug?: ?string,
  filters: EventFilters,
|};
export type AllEventsCalendarSharePageContainerQueryResponse = {|
  +publicRule?: boolean,
  +eventList?: ?{|
    +events: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +$fragmentRefs: AllEventsCalendarView_events$ref
        |}
      |}>
    |}
  |},
  +me?: {|
    +events: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +$fragmentRefs: AllEventsCalendarView_events$ref
        |}
      |}>
    |}
  |},
|};
export type AllEventsCalendarSharePageContainerQuery = {|
  variables: AllEventsCalendarSharePageContainerQueryVariables,
  response: AllEventsCalendarSharePageContainerQueryResponse,
|};
*/


/*
query AllEventsCalendarSharePageContainerQuery(
  $isShared: Boolean!
  $publicRuleName: String!
  $resourceToken: String!
  $orgSlug: String
  $filters: EventFilters!
) {
  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug) @include(if: $isShared)
  eventList(resourceToken: $resourceToken) @include(if: $isShared) {
    events(filters: $filters) {
      edges {
        node {
          ...AllEventsCalendarView_events
          id
        }
      }
    }
    id
  }
  me @skip(if: $isShared) {
    events(filters: $filters) {
      edges {
        node {
          ...AllEventsCalendarView_events
          id
        }
      }
    }
    id
  }
}

fragment AllEventsCalendarView_events on Event {
  id
  slug
  name
  startDate
  endDate
  tz
  team {
    primaryColor
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "isShared",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "publicRuleName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "resourceToken",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgSlug",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "EventFilters!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicRule",
  "args": [
    {
      "kind": "Variable",
      "name": "orgSlug",
      "variableName": "orgSlug",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "publicRuleName",
      "variableName": "publicRuleName",
      "type": "String!"
    },
    {
      "kind": "Variable",
      "name": "resourceToken",
      "variableName": "resourceToken",
      "type": "String"
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "resourceToken",
    "variableName": "resourceToken",
    "type": "String!"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters",
    "type": "EventFilters"
  }
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "events",
    "storageKey": null,
    "args": v3,
    "concreteType": "EventRequiredConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "EventRequiredEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "AllEventsCalendarView_events",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "events",
    "storageKey": null,
    "args": v3,
    "concreteType": "EventRequiredConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "EventRequiredEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "slug",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tz",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "primaryColor",
                    "args": null,
                    "storageKey": null
                  },
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  v5
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AllEventsCalendarSharePageContainerQuery",
  "id": null,
  "text": "query AllEventsCalendarSharePageContainerQuery(\n  $isShared: Boolean!\n  $publicRuleName: String!\n  $resourceToken: String!\n  $orgSlug: String\n  $filters: EventFilters!\n) {\n  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug) @include(if: $isShared)\n  eventList(resourceToken: $resourceToken) @include(if: $isShared) {\n    events(filters: $filters) {\n      edges {\n        node {\n          ...AllEventsCalendarView_events\n          id\n        }\n      }\n    }\n    id\n  }\n  me @skip(if: $isShared) {\n    events(filters: $filters) {\n      edges {\n        node {\n          ...AllEventsCalendarView_events\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment AllEventsCalendarView_events on Event {\n  id\n  slug\n  name\n  startDate\n  endDate\n  tz\n  team {\n    primaryColor\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AllEventsCalendarSharePageContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isShared",
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventList",
            "storageKey": null,
            "args": v2,
            "concreteType": "EventList",
            "plural": false,
            "selections": v4
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "isShared",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": v4
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllEventsCalendarSharePageContainerQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isShared",
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventList",
            "storageKey": null,
            "args": v2,
            "concreteType": "EventList",
            "plural": false,
            "selections": v6
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "isShared",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": v6
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b02cc685e3d4919d96462c4d48ec344';
module.exports = node;
