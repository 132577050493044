/**
 * @flow
 * @relayHash f6f7686133d1acab0ee82fda598e1018
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateVendorInput = {
  clientMutationId?: ?string,
  vendorId: string,
  eventId?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  name?: ?string,
  title?: ?string,
  email?: ?string,
  companyPhone?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  website?: ?string,
  twitter?: ?string,
  linkedin?: ?string,
  description?: ?string,
  country?: ?string,
  state?: ?string,
  city?: ?string,
  zip?: ?string,
  street?: ?string,
  fromWindow?: ?string,
};
export type updateVendorMutationVariables = {|
  input: UpdateVendorInput
|};
export type updateVendorMutationResponse = {|
  +updateVendor: ?{|
    +vendor: ?{|
      +id: string,
      +name: string,
      +firstName: ?string,
      +lastName: ?string,
      +website: ?string,
      +email: ?string,
      +phone1: ?string,
      +phone2: ?string,
      +companyPhone: ?string,
      +title: ?string,
      +twitter: ?string,
      +linkedin: ?string,
      +description: ?string,
      +country: ?string,
      +state: ?string,
      +city: ?string,
      +zip: ?string,
      +street: ?string,
    |}
  |}
|};
export type updateVendorMutation = {|
  variables: updateVendorMutationVariables,
  response: updateVendorMutationResponse,
|};
*/


/*
mutation updateVendorMutation(
  $input: UpdateVendorInput!
) {
  updateVendor(input: $input) {
    vendor {
      id
      name
      firstName
      lastName
      website
      email
      phone1
      phone2
      companyPhone
      title
      twitter
      linkedin
      description
      country
      state
      city
      zip
      street
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateVendorInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateVendor",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateVendorInput!"
      }
    ],
    "concreteType": "UpdateVendorPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vendor",
        "storageKey": null,
        "args": null,
        "concreteType": "Vendor",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "website",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone1",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "companyPhone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "twitter",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "linkedin",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "country",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "city",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "zip",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "street",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateVendorMutation",
  "id": null,
  "text": "mutation updateVendorMutation(\n  $input: UpdateVendorInput!\n) {\n  updateVendor(input: $input) {\n    vendor {\n      id\n      name\n      firstName\n      lastName\n      website\n      email\n      phone1\n      phone2\n      companyPhone\n      title\n      twitter\n      linkedin\n      description\n      country\n      state\n      city\n      zip\n      street\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateVendorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateVendorMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '29e21eacd60348849335ef680bb6c114';
module.exports = node;
