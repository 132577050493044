/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ScheduleItemContainer_event$ref = any;
type ScheduleItemContainer_schedule$ref = any;
type ScheduleNavigationItem_schedule$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduleBrief_event$ref: FragmentReference;
export type ScheduleBrief_event = {|
  +briefSettings: {|
    +briefTemplate: {|
      +personalizeSchedules: boolean
    |}
  |},
  +schedules: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +order: number,
        +scheduleDays: {|
          +totalCount: number,
          +edges: $ReadOnlyArray<{|
            +node: {|
              +scheduleItems: {|
                +totalCount: number
              |}
            |}
          |}>,
        |},
        +$fragmentRefs: ScheduleItemContainer_schedule$ref & ScheduleNavigationItem_schedule$ref,
      |}
    |}>
  |},
  +$fragmentRefs: ScheduleItemContainer_event$ref,
  +$refType: ScheduleBrief_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "isPersonalized",
    "variableName": "personalizeSchedules",
    "type": "Boolean"
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ScheduleBrief_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "personalizeSchedules",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ScheduleItemContainer_event",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "briefSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "EventBriefTemplate",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "briefTemplate",
          "storageKey": null,
          "args": null,
          "concreteType": "BriefTemplate",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "personalizeSchedules",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "schedules",
      "storageKey": null,
      "args": v0,
      "concreteType": "ScheduleRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ScheduleRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Schedule",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "scheduleDays",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ScheduleDayRequiredConnection",
                  "plural": false,
                  "selections": [
                    v1,
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ScheduleDayRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ScheduleDay",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "scheduleItems",
                              "storageKey": null,
                              "args": v0,
                              "concreteType": "ScheduleItemRequiredConnection",
                              "plural": false,
                              "selections": [
                                v1
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ScheduleItemContainer_schedule",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ScheduleNavigationItem_schedule",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc42f3b4194d8d3cff6012060e12145d';
module.exports = node;
