/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';
import Tooltip from 'components/material/Tooltip';

import type { UsersGroup_users } from './__generated__/UsersGroup_users.graphql';

const Container = styled.div`
  display: flex;
`;

const StyledTooltip = styled(Tooltip)`
  margin-right: ${props => (props.compact ? '4px' : '5px')};
`;

const HiddenCount = styled.div`
  width: ${props => props.radius * 2}px;
  height: ${props => props.radius * 2}px;
  border-radius: 50%;
  background: ${props => props.theme.checkboxUncheckedColor};
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 12px;
  text-align: center;
  line-height: ${props => props.radius * 2}px;
  font-weight: 500;
`;

class UsersGroup extends React.PureComponent<{
  users: UsersGroup_users,
  onClick?: () => void,
  maxCount: number,
  avatarRadius: number,
  tooltipPlacement: 'top' | 'bottom' | 'right' | 'left',
  className?: string,
  compact?: boolean,
}> {
  static defaultProps = {
    maxCount: 3,
    avatarRadius: 13,
    tooltipPlacement: 'top',
  };

  render() {
    const usersShown =
      this.props.users.length > this.props.maxCount
        ? this.props.users.slice(0, this.props.maxCount - 1)
        : this.props.users;
    const hiddenUsers =
      this.props.users.length > this.props.maxCount
        ? this.props.users.slice(this.props.maxCount - 1)
        : [];

    return (
      <Container {...this.props} onClick={this.props.onClick} className={this.props.className}>
        {usersShown.map(user => (
          <StyledTooltip
            label={fullNameOfUser(user)}
            key={user.id}
            placement={this.props.tooltipPlacement}
            compact={this.props.compact}
          >
            <MaterialAvatar radius={this.props.avatarRadius} user={user} />
          </StyledTooltip>
        ))}
        {hiddenUsers.length > 0 && (
          <Tooltip
            label={hiddenUsers.map(u => (
              <div key={u.id}>{fullNameOfUser(u)}</div>
            ))}
            placement={this.props.tooltipPlacement}
          >
            <HiddenCount radius={this.props.avatarRadius}>+{hiddenUsers.length}</HiddenCount>
          </Tooltip>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  UsersGroup,
  graphql`
    fragment UsersGroup_users on Assignable @relay(plural: true) {
      id
      email
      firstName
      lastName
      ...MaterialAvatar_user
    }
  `,
);
