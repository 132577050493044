/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EmailSettings_registrationForm$ref = any;
type GeneralSettings_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationSettingsContent_registrationForm$ref: FragmentReference;
export type RegistrationSettingsContent_registrationForm = {|
  +$fragmentRefs: GeneralSettings_registrationForm$ref & EmailSettings_registrationForm$ref,
  +$refType: RegistrationSettingsContent_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationSettingsContent_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "GeneralSettings_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EmailSettings_registrationForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0a938487265413a0f0e37977f394e474';
module.exports = node;
