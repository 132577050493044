/**
 * @flow
 * @relayHash 135ea9418a52a8698042749dfa49964f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AllEventsShareViewWindow_org$ref = any;
export type AllEventsShareViewWindowRefetchQueryVariables = {|
  loadRequestForms: boolean
|};
export type AllEventsShareViewWindowRefetchQueryResponse = {|
  +org: {|
    +$fragmentRefs: AllEventsShareViewWindow_org$ref
  |}
|};
export type AllEventsShareViewWindowRefetchQuery = {|
  variables: AllEventsShareViewWindowRefetchQueryVariables,
  response: AllEventsShareViewWindowRefetchQueryResponse,
|};
*/


/*
query AllEventsShareViewWindowRefetchQuery(
  $loadRequestForms: Boolean!
) {
  org {
    ...AllEventsShareViewWindow_org_3HgtPX
    id
  }
}

fragment AllEventsShareViewWindow_org_3HgtPX on Org {
  name
  slug
  hasSamlProvider
  settings {
    subdomain
    id
  }
  publishedRequestFormsCount: eventRequestForms(publishedOnly: true) {
    totalCount
  }
  publishedRequestForms: eventRequestForms(publishedOnly: true) @include(if: $loadRequestForms) {
    edges {
      node {
        id
        name
        accessibility
        shareLink
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "loadRequestForms",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "publishedOnly",
    "value": true,
    "type": "Boolean"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AllEventsShareViewWindowRefetchQuery",
  "id": null,
  "text": "query AllEventsShareViewWindowRefetchQuery(\n  $loadRequestForms: Boolean!\n) {\n  org {\n    ...AllEventsShareViewWindow_org_3HgtPX\n    id\n  }\n}\n\nfragment AllEventsShareViewWindow_org_3HgtPX on Org {\n  name\n  slug\n  hasSamlProvider\n  settings {\n    subdomain\n    id\n  }\n  publishedRequestFormsCount: eventRequestForms(publishedOnly: true) {\n    totalCount\n  }\n  publishedRequestForms: eventRequestForms(publishedOnly: true) @include(if: $loadRequestForms) {\n    edges {\n      node {\n        id\n        name\n        accessibility\n        shareLink\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AllEventsShareViewWindowRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AllEventsShareViewWindow_org",
            "args": [
              {
                "kind": "Variable",
                "name": "loadRequestForms",
                "variableName": "loadRequestForms",
                "type": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllEventsShareViewWindowRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasSamlProvider",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "subdomain",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "publishedRequestFormsCount",
            "name": "eventRequestForms",
            "storageKey": "eventRequestForms(publishedOnly:true)",
            "args": v3,
            "concreteType": "EventRequestFormRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v2,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "loadRequestForms",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "publishedRequestForms",
                "name": "eventRequestForms",
                "storageKey": "eventRequestForms(publishedOnly:true)",
                "args": v3,
                "concreteType": "EventRequestFormRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventRequestFormRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EventRequestForm",
                        "plural": false,
                        "selections": [
                          v2,
                          v1,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "accessibility",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "shareLink",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '558562e7e88e2da4d5778abeca8c6252';
module.exports = node;
