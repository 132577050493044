/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import Clipboard from 'clipboard';

import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import isValidWebsite from 'utils/validators/isValidWebsite';

import updateOrgSettings from 'graph/mutations/org/updateOrgSettings';
import showModernMutationError from 'graph/utils/showModernMutationError';

import WarningIcon from 'images/warning.svg';
import TextField from 'components/material/TextField';
import TextInput from 'components/material/TextInput';
import Tooltip from 'components/material/Tooltip';
import { SettingContent, SettingSection } from 'components/Settings';

import type { APISettings_org } from './__generated__/APISettings_org.graphql';

const APIKeyField = styled.div`
  flex: 0 1 295px;
`;

const APIKeyLabel = styled.div`
  font-size: 12px;
  color: ${props => props.theme.labelColor};
`;

const APIKeyValue = styled.div`
  display: flex;
  align-items: center;
  > input {
    border-bottom: 0 !important;
  }
  i {
    margin-left: 10px;
    font-size: 16px;
    color: rgba(74, 86, 101, 0.54);
    cursor: pointer;
  }
`;

const StyledTextField = styled(TextField)`
  flex: 0 1 350px;
`;

const NotificationBlockedWarning = styled.div`
  flex: 0 1 350px;
  display: flex;
  padding: 5px 15px 6px 10px;
  background-color: #fff7e8;
  font-size: 12px;
  font-style: italic;
  color: #677386;
`;

const StyledWarningIcon = styled(WarningIcon)`
  width: 21px;
  height: 15px;
  margin-top: 2px;
  margin-right: 10px;
  color: #ffb634;
`;

const NotificationRefreshButton = styled.a`
  margin-left: 10px;
  cursor: pointer;
  color: #39a8da;
  &:hover {
    color: #009dce;
    text-decoration: underline;
  }
`;

class APISettings extends React.PureComponent<
  {
    org: APISettings_org,
    hideNotifications?: boolean,
  },
  {
    apiPushNotificationsUrl: ?string,
    error: ?string,
    showAPIKey: boolean,
    copied: boolean,
  },
> {
  state = {
    apiPushNotificationsUrl: this.props.org.settings.apiPushNotificationsUrl,
    error: null,
    showAPIKey: false,
    copied: false,
  };

  timeoutId: ?TimeoutID = null;

  copyIcon: ?HTMLElement;

  componentDidMount() {
    new Clipboard(this.copyIcon, {
      text: () => this.props.org.settings.apiKey,
    }).on('success', () => {
      this.setState({ copied: true }, () => {
        this.timeoutId = setTimeout(() => {
          this.setState({ copied: false });
        }, 1000);
      });
    });
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handleNotificationUrlChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const url = e.currentTarget.value.trim();
    this.setState({
      apiPushNotificationsUrl: url,
      error: this.isUrlValid(url) ? null : 'Invalid url',
    });
  };

  handleNotificationUrlBlur = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    const url = e.currentTarget.value.trim();
    if (this.isUrlValid(url)) {
      const apiPushNotificationsUrl = url === '' ? null : enforceHttpPrefix(url);
      if (apiPushNotificationsUrl !== this.props.org.settings.apiPushNotificationsUrl) {
        updateOrgSettings(this.props.org.settings.id, {
          apiPushNotificationsUrl,
          apiPushNotificationsBlocked: false,
        }).catch(showModernMutationError);
      }
      this.setState({ apiPushNotificationsUrl });
    } else {
      this.setState({ error: 'Invalid url' });
    }
  };

  handleNotificationUrlReset = () => {
    updateOrgSettings(this.props.org.settings.id, {
      apiPushNotificationsBlocked: false,
    }).catch(showModernMutationError);
  };

  // Is blank or valid URL
  isUrlValid = (value: string): boolean => value === '' || isValidWebsite(enforceHttpPrefix(value));

  handleShowButtonClick = () => this.setState(prevState => ({ showAPIKey: !prevState.showAPIKey }));

  render() {
    const { showAPIKey, apiPushNotificationsUrl, error } = this.state;
    const { apiKey, apiPushNotificationsBlocked } = this.props.org.settings;
    return (
      <SettingSection>
        <SettingContent>
          <APIKeyField>
            <APIKeyLabel>API secret key</APIKeyLabel>
            <APIKeyValue>
              <TextInput type={showAPIKey ? 'text' : 'password'} defaultValue={apiKey} readOnly />
              <Tooltip
                innerRef={el => {
                  this.copyIcon = el;
                }}
                label={this.state.copied ? 'Copied' : 'Copy to clipboard'}
                placement="top"
              >
                <i className="fa fa-fw fa-clone" />
              </Tooltip>
              <i
                className={`fa fa-fw fa-eye${showAPIKey ? '-slash' : ''}`}
                onClick={this.handleShowButtonClick}
              />
            </APIKeyValue>
          </APIKeyField>
        </SettingContent>
        {!this.props.hideNotifications && (
          <>
            <SettingContent>
              <StyledTextField
                label="Notification Url"
                value={apiPushNotificationsUrl || ''}
                onBlur={this.handleNotificationUrlBlur}
                onChange={this.handleNotificationUrlChange}
                error={error}
              />
            </SettingContent>
            {apiPushNotificationsBlocked && (
              <SettingContent>
                <NotificationBlockedWarning>
                  <StyledWarningIcon />
                  <span>
                    Processing error: Please refer to your integration error log for more details.
                    <NotificationRefreshButton onClick={this.handleNotificationUrlReset}>
                      Refresh
                    </NotificationRefreshButton>
                  </span>
                </NotificationBlockedWarning>
              </SettingContent>
            )}
          </>
        )}
      </SettingSection>
    );
  }
}

export default createFragmentContainer(
  APISettings,
  graphql`
    fragment APISettings_org on Org {
      settings {
        id
        apiKey
        apiPushNotificationsUrl
        apiPushNotificationsBlocked
      }
    }
  `,
);
