/* @flow */
import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #f6f2ff;
`;

export default Header;
