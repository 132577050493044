/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type EventRequestSubmissionStatus = "APPROVED" | "ARCHIVED" | "DECLINED" | "NEW" | "RESUBMITTED" | "RETURNED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubmissionReviewDeclineReasons_submission$ref: FragmentReference;
export type SubmissionReviewDeclineReasons_submission = {|
  +status: EventRequestSubmissionStatus,
  +form: {|
    +declineReasons: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
        |}
      |}>
    |}
  |},
  +$refType: SubmissionReviewDeclineReasons_submission$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SubmissionReviewDeclineReasons_submission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "form",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestForm",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "declineReasons",
          "storageKey": null,
          "args": null,
          "concreteType": "DeclineReasonRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "DeclineReasonRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "DeclineReason",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "id",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b9fc33a47e2c7d3bb8cab2eb606541b0';
module.exports = node;
