/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { type DateConfig } from 'components/date/DateTimePicker';
import DateTimeOverlay from 'components/date/DateTimePicker/DateTimeOverlay';
import DateTimeTooltip from 'components/material/DateTimeTooltip';
import RemovablePill from 'components/material/RemovablePill';
import EmptyLink from 'components/material/table/EmptyLink';

const Container = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  flex-basis: 120px;
  position: relative;
  @media (${props => props.theme.mobileOnly}) {
    flex-basis: auto;
    flex-shrink: 0;
    font-size: 13px;
  }
`;

const Removable = styled(RemovablePill)`
  display: inline-block;
  padding-right: 5px;
  ${props => props.overdue && `color: ${props.theme.negativeActionColor}`};
`;

type DateType = {
  date: ?string,
  allDay: ?boolean,
};

type Props = {
  date: ?string,
  allDay: ?boolean,
  tz: string,
  onChange: DateType => void,
  disabled?: boolean,
  completed?: boolean,
};

export default class TaskRowDate extends React.PureComponent<
  Props,
  {
    overlayShown: boolean,
  },
> {
  state = {
    overlayShown: false,
  };

  containerRef = React.createRef();

  handleClick = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleOverlayShow = () => {
    if (!this.props.disabled) {
      this.setState({ overlayShown: true });
    }
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  handleDateRemove = () => {
    this.props.onChange({ date: null, allDay: false });
  };

  handleChangeDate = (dateConfig: DateConfig) => {
    this.props.onChange({ date: dateConfig.date, allDay: dateConfig.hideTime });
  };

  render() {
    const date = this.props.date && moment.tz(this.props.date, this.props.tz);

    const now = moment().tz(this.props.tz);
    const isOverdue =
      !this.props.completed && date && now.isAfter(date, this.props.allDay ? 'day' : null);

    return (
      <Container onClick={this.handleClick} ref={this.containerRef}>
        {date ? (
          <Removable
            onClick={this.handleOverlayShow}
            size={12}
            disabled={this.props.disabled}
            onRemove={this.handleDateRemove}
            overdue={isOverdue}
          >
            <DateTimeTooltip date={date} hideTime={this.props.allDay || false} />
          </Removable>
        ) : (
          !this.props.disabled && (
            <EmptyLink
              label="Add Due Date"
              icon="calendar"
              onClick={this.handleOverlayShow}
              highlighted={this.state.overlayShown}
            />
          )
        )}

        <DateTimeOverlay
          show={this.state.overlayShown}
          target={this.containerRef.current}
          container={this.containerRef.current}
          onHide={this.handleOverlayHide}
          tz={this.props.tz}
          onChange={this.handleChangeDate}
          date={this.props.date}
          hideTime={this.props.allDay == null || this.props.allDay}
        />
      </Container>
    );
  }
}
