/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserField_event$ref: FragmentReference;
export type UserField_event = {|
  +lead: ?{|
    +id: string
  |},
  +staffers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +onsite?: boolean,
        +user: {|
          +id: string
        |},
      |}
    |}>
  |},
  +$refType: UserField_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "UserField_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeStaffOnsite",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "lead",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffers",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffMembershipRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffMembershipRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StaffMembership",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": v0
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeStaffOnsite",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "onsite",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '79ab59f9715101d4c38b8fd9d87acd11';
module.exports = node;
