/* @flow */
import type { FieldType } from 'utils/customization/types';
import {
  type CustomFieldParam,
  type DateRangeParam,
  type NumberRangeParam,
} from 'utils/routing/parseTypedQueryString';

export default function getCustomFieldVariable(
  parsedFilter: CustomFieldParam,
  filterOption: FieldType,
): ?{
  customFieldId: ?string,
  fieldName?: ?string,
  dateParam?: ?DateRangeParam,
  order?: ?number,
  operator?:
    | ?'equal'
    | 'in'
    | 'is_not_null'
    | 'is_null'
    | 'not_equal'
    | 'not_in'
    | 'text_in'
    | 'text_not_in'
    | 'text_ends_with'
    | 'text_starts_with',
  numberParam?: ?NumberRangeParam,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  optionIds?: ?$ReadOnlyArray<string>,
} {
  switch (filterOption.kind) {
    case 'USER_SELECT':
    case 'USER_MULTISELECT':
    case 'SELECT':
    case 'MULTISELECT':
      return {
        customFieldId: filterOption.id,
        optionIds: parsedFilter
          ? parsedFilter.stringArrayValue || parsedFilter.enumArrayValue
          : null,
      };
    case 'NUMBER':
    case 'CURRENCY':
      return {
        customFieldId: filterOption.id,
        numberParam: parsedFilter ? parsedFilter.numberRangeParam : null,
      };
    case 'TEXT':
      return {
        customFieldId: filterOption.id,
        textParam: parsedFilter ? parsedFilter.stringArrayValue : null,
      };
    case 'TEXTAREA':
      return {
        customFieldId: filterOption.id,
        textareaParam: parsedFilter ? parsedFilter.stringArrayValue : null,
      };
    case 'DATE':
      return {
        customFieldId: filterOption.id,
        dateParam: parsedFilter ? parsedFilter.dateRangeValue : null,
      };
    case 'BOOLEAN':
      return {
        customFieldId: filterOption.id,
        booleanParam: parsedFilter ? parsedFilter.booleanValue : null,
      };
    default:
      return null;
  }
}
