/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';

import { type removeRegistrationPageComponentMutationResponse } from './__generated__/removeRegistrationPageComponentMutation.graphql';

const mutation = graphql`
  mutation removeRegistrationPageComponentMutation($input: RemovePageComponentInput!) {
    removeRegistrationPageComponent(input: $input) {
      removedPageComponentId
      updatedPageComponents {
        id
        order
        parent {
          id
        }
        childPageComponents {
          edges {
            node {
              id
              columnChild {
                id
              }
            }
          }
        }
        ...ImageComponent_componentProps
        ...EventNameComponent_componentProps
        ...EventDatesComponent_componentProps
        ...TextComponent_componentProps
        ...DividerComponent_componentProps
        ...EmbedComponent_componentProps
        ...RowComponent_componentProps
        ...VideoComponent_componentProps
        ...RegistrationPageComponents_selectedPageComponent
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function removeRegistrationPageComponent(
  registrationFormId: string,
  pageComponentId: string,
): Promise<removeRegistrationPageComponentMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        pageComponentId,
      },
    },
    optimisticResponse: {
      removeRegistrationPageComponent: {
        removedPageComponentId: pageComponentId,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        parentId: registrationFormId,
        connections: [{ field: 'pageComponents' }],
        deletedIdField: 'removedPageComponentId',
      }),
      removeRecordFromStore({ deletedIdField: 'removedPageComponentId' }),
    ),
  });
}
