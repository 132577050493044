/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import formatPercentage from 'utils/number/formatPercentage';

import InlineEditableCell from 'components/budget/Table/InlineEditableCell';
import InlineSelectField from 'components/budget/Table/InlineSelectField';

import { type ExpenseCellPropsType } from './index';

import { type ExpensePaymentStatusCell_event } from './__generated__/ExpensePaymentStatusCell_event.graphql';
import { type ExpensePaymentStatusCell_expense } from './__generated__/ExpensePaymentStatusCell_expense.graphql';

export const status = (ratio: number) => {
  if (!Number.isFinite(ratio) || ratio <= 0) return 'Unpaid';
  if (ratio >= 1) return 'Paid';

  return `Partial (${formatPercentage(ratio)})`;
};

const ExpensePaymentStatusCell = ({
  expense,
  event,
  updateExpense,
}: ExpenseCellPropsType<ExpensePaymentStatusCell_expense, ExpensePaymentStatusCell_event>) => {
  const expenseStatus = status(expense.paidAmount / expense.actualAmount);

  if (expense.actualAmount === 0) return expenseStatus;

  if (!expense.viewerCanUpdatePaymentStatus) {
    return (
      <InlineEditableCell>
        {({ editing }) => {
          if (editing) {
            return (
              <Redirect
                push
                to={{
                  pathname: `/events/${event.slug}/budget/${expense.id}`,
                  hash: 'payments',
                }}
              />
            );
          }

          return expenseStatus;
        }}
      </InlineEditableCell>
    );
  }

  return (
    <InlineSelectField
      value={status === 'Paid' ? 'PAID' : 'UNPAID'}
      options={[
        { label: 'Unpaid', value: 'UNPAID' },
        { label: 'Paid', value: 'PAID' },
      ]}
      onChange={paymentStatus => {
        if (!paymentStatus) return;

        updateExpense({ paymentStatus, actualAmount: expense.actualAmount });
      }}
    >
      {expenseStatus}
    </InlineSelectField>
  );
};

export default createFragmentContainer(
  ExpensePaymentStatusCell,
  graphql`
    fragment ExpensePaymentStatusCell_expense on Expense {
      id
      actualAmount
      paidAmount
      viewerCanUpdatePaymentStatus
    }

    fragment ExpensePaymentStatusCell_event on Event {
      slug
    }
  `,
);
