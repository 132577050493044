/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import TextField from 'components/material/TextField';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type { Participant } from 'components/Participants';
import VendorSearch from 'components/Participants/VendorSearch';

import type { VendorFieldSearchQuery_response } from './__generated__/VendorFieldSearchQuery.graphql';

const query = graphql`
  query VendorFieldSearchQuery($vendorId: ID!) {
    selectedVendor: node(id: $vendorId) {
      id
      ... on Vendor {
        id
        firstName
        lastName
        email
        name
      }
    }
  }
`;

export default class VendorField extends React.PureComponent<
  {
    eventId: string,
    vendorId: ?string,
    onSelect: (vendorId: ?string) => void,
    label?: string,
    placeholder?: string,
    onBlur?: () => void,
    overlayContainer?: ?HTMLElement,
    autoFocus?: boolean,
    clearable?: boolean,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleVendorSelect = (vendor: ?Participant) => {
    if (vendor != null) {
      this.props.onSelect(vendor.id);
      this.setState({ query: vendor.name || '' });
    } else {
      this.props.onSelect(null);
    }
  };

  renderSearchField = (name?: string) => {
    return (
      <VendorSearch
        label={this.props.label}
        placeholder={this.props.placeholder}
        eventId={this.props.eventId}
        stateful
        defaultValue={name}
        onSelect={this.handleVendorSelect}
        clearable={this.props.clearable}
        autoFocus={this.props.autoFocus}
        onBlur={this.props.onBlur}
        overlayContainer={this.props.overlayContainer}
        showSyncStatus={false}
        fromWindow="event vendors"
        resetOnBlur
      />
    );
  };

  render() {
    if (this.props.vendorId == null) return this.renderSearchField();

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ vendorId: this.props.vendorId }}
        renderSuccess={(response: VendorFieldSearchQuery_response) => {
          if (response && response.selectedVendor) {
            const vendor = response.selectedVendor;

            return this.renderSearchField(vendor.name);
          }
          return this.renderSearchField();
        }}
        renderLoading={() => (
          <TextField
            label={this.props.label}
            placeholder={this.props.placeholder}
            contactId={this.state.query}
            value={this.state.query}
            readOnly
          />
        )}
      />
    );
  }
}
