import './styles.styl';

import React from 'react';

import classNames from 'classnames';

export class Window extends React.Component {
  render() {
    return (
      <div className={classNames('window', this.props.className)}>
        <div className="window-overlay" onClick={this.props.onClose} />
        <div className="window-main">
          {!this.props.noHeader &&
            <WindowHeader onClose={this.props.onClose} noClose={this.props.noClose}>
              {this.props.title && <WindowTitle text={this.props.title} />}
              {this.props.headerContent}
            </WindowHeader>}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export class WindowContent extends React.Component {
  render() {
    return (
      <div className="window-inner">
        <div className="window-inner-content">
          {this.props.children}
        </div>
        {this.props.sidebar}
      </div>
    );
  }
}

export class WindowFooter extends React.Component {
  render() {
    return (
      <div className={classNames('window-footer', this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}

class WindowHeader extends React.Component {
  render() {
    return (
      <header className="window-header">
        {this.props.children}
        {!this.props.noClose &&
          <div className="window-close" onClick={this.props.onClose}>
            <i className="fa-fw fa fa-times" />
          </div>}
      </header>
    );
  }
}

class WindowTitle extends React.Component {
  render() {
    return <div className="window-title">{this.props.text}</div>;
  }
}
