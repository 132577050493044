/**
 * @flow
 * @relayHash ba09624bd8f6a2937a20c21782fd4d48
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamSelectField_teams$ref = any;
export type AddTeamActionQueryVariables = {||};
export type AddTeamActionQueryResponse = {|
  +me: {|
    +memberships: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +viewerCanUpdate: boolean,
          +$fragmentRefs: TeamSelectField_teams$ref,
        |}
      |}>
    |}
  |}
|};
export type AddTeamActionQuery = {|
  variables: AddTeamActionQueryVariables,
  response: AddTeamActionQueryResponse,
|};
*/


/*
query AddTeamActionQuery {
  me {
    memberships {
      edges {
        node {
          id
          viewerCanUpdate
          ...TeamSelectField_teams
        }
      }
    }
    id
  }
}

fragment TeamSelectField_teams on Team {
  id
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AddTeamActionQuery",
  "id": null,
  "text": "query AddTeamActionQuery {\n  me {\n    memberships {\n      edges {\n        node {\n          id\n          viewerCanUpdate\n          ...TeamSelectField_teams\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment TeamSelectField_teams on Team {\n  id\n  name\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AddTeamActionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "memberships",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamMembershipConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "MembershipTeamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      {
                        "kind": "FragmentSpread",
                        "name": "TeamSelectField_teams",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddTeamActionQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "memberships",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamMembershipConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "MembershipTeamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae3171d4276755192744aba4f1649a5e';
module.exports = node;
