/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { graphql } from 'react-relay';
import throttle from 'lodash/throttle';

import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import AutocompleteInput from 'components/material/AutocompleteInput';
import ContactWindow from 'components/Contacts/ContactWindow';
import { type ContactType } from 'components/Contacts/ContactWindow/ContactTypeSelector';

import ContactSearchRow from './ContactSearchRow';
import NewParticipantRow from './NewParticipantRow';

import type { ContactSearchQueryResponse } from './__generated__/ContactSearchQuery.graphql';
import type { Participant } from './index';

type ResponseOrg = $PropertyType<ContactSearchQueryResponse, 'org'>;
type ResponseContactsEdges = $PropertyType<$PropertyType<ResponseOrg, 'contacts'>, 'edges'>;
type ResponseContact = $PropertyType<$ElementType<ResponseContactsEdges, 0>, 'node'>;

const query = graphql`
  query ContactSearchQuery($eventId: ID!, $includeEvent: Boolean!, $filters: ContactFilters!) {
    org {
      id
      salesforceAccount {
        contactsSyncEnabled
      }
      viewerCanCreateContacts @skip(if: $includeEvent)
      contacts(first: 3, filters: $filters) {
        edges {
          node {
            id
            firstName
            lastName
            avatar
            email
            salesforceSyncAs
            syncStatus {
              state
              lastSuccessAt
              errorMessage
            }
            company {
              name
            }
            ...ContactSearchRow_contact
          }
        }
      }
    }
    event: node(id: $eventId) @include(if: $includeEvent) {
      ... on Event {
        viewerCanCreateContacts
      }
    }
  }
`;

export default class ContactSearch extends React.PureComponent<
  {
    eventId?: ?string,
    onSelect: (contact: ?Participant, contactType: ContactType) => void,
    stateful?: boolean,
    defaultValue?: string,
    label?: string,
    placeholder?: string,
    clearable?: boolean,
    autoFocus?: boolean,
    disabled?: boolean,
    onToggleQueryState?: (present: boolean) => void,
    onShowCreateContact?: () => void,
    onHideCreateContact?: () => void,
    onBlur?: () => void,
    overlayContainer?: ?HTMLElement,
    showSyncStatus: boolean,
    resetOnBlur?: boolean,
    renderOptionToString?: (contact: ResponseContact) => string,
    fromWindow: CreateContactFromWindow,
  },
  {
    query: string,
    addingContact: boolean,
  },
> {
  static defaultProps = {
    showSyncStatus: true,
  };

  state = {
    query: this.props.stateful && this.props.defaultValue ? this.props.defaultValue : '',
    addingContact: false,
  };

  handleFilter = throttle((str: string) => {
    this.setState({ query: str });
    this.checkSearchQueryState(str);
  }, 800);

  checkSearchQueryState = (str?: string = '') => {
    if (str.trim() && !this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(true);
      this.queryExists = true;
    }
    if (!str.trim() && this.queryExists && this.props.onToggleQueryState) {
      this.props.onToggleQueryState(false);
      this.queryExists = false;
    }
  };

  handleWindowShow = () => {
    this.setState({ addingContact: true });
    if (this.props.onShowCreateContact) {
      this.props.onShowCreateContact();
    }
  };

  handleWindowClose = () => {
    this.setState({ addingContact: false });
    if (this.props.onHideCreateContact) {
      this.props.onHideCreateContact();
    }
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleSave = (contactType: ContactType, contact: Participant) => {
    this.props.onSelect(contact, contactType);
  };

  handleBlur = (autocompleteQuery: string) => {
    if (
      !this.props.resetOnBlur &&
      this.props.stateful &&
      !autocompleteQuery &&
      this.props.defaultValue != null
    ) {
      this.props.onSelect(null, 'contacts');
    }
    if (this.props.onBlur && !this.state.addingContact) this.props.onBlur();
  };

  handleSelect = (contact: ?ResponseContact) => {
    this.props.onSelect(contact, 'contacts');
    if (!contact) {
      this.checkSearchQueryState();
    }
  };

  cachedContacts: ?Array<ResponseContact>;

  salesforceEnabled: boolean;

  queryExists: boolean;

  renderOption = (contact: ResponseContact) => (
    <ContactSearchRow contact={contact} salesforceEnabled={this.salesforceEnabled} />
  );

  renderOptionToString = (contact: ResponseContact) => {
    if (this.props.renderOptionToString) {
      return this.props.renderOptionToString(contact);
    }

    if (this.props.stateful) {
      if (contact.firstName) {
        return [contact.firstName, contact.lastName].filter(Boolean).join(' ');
      }
      if (contact.email != null) {
        return contact.email;
      }
    }
    return '';
  };

  renderCreate = () => <NewParticipantRow label="Add Contact" onClick={this.handleWindowShow} />;

  renderInput = (response?: ContactSearchQueryResponse) => {
    if (response && response.org) {
      this.cachedContacts = response.org.contacts.edges.map(edge => edge.node);
      this.salesforceEnabled =
        this.props.showSyncStatus && response.org.salesforceAccount
          ? response.org.salesforceAccount.contactsSyncEnabled
          : false;
    }
    const canCreateContact =
      response &&
      ((response.event && response.event.viewerCanCreateContacts) ||
        (response.org && response.org.viewerCanCreateContacts));
    return (
      <React.Fragment>
        <AutocompleteInput
          defaultQuery={this.props.defaultValue}
          placeholder={this.props.placeholder || 'Search Contacts'}
          label={this.props.label}
          options={this.cachedContacts || []}
          onFilterOptions={this.handleFilter}
          onSelect={this.handleSelect}
          onBlur={this.handleBlur}
          renderOption={this.renderOption}
          renderOptionString={this.renderOptionToString}
          clearable={this.props.clearable}
          autoFocus={this.props.autoFocus}
          disabled={this.props.disabled}
          footerContent={canCreateContact ? this.renderCreate() : null}
          overlayContainer={this.props.overlayContainer}
          resetOnBlur={this.props.resetOnBlur}
        />

        {this.state.addingContact &&
          document.body &&
          createPortal(
            <ContactWindow
              eventId={this.props.eventId}
              onHide={this.handleWindowClose}
              onSave={this.handleSave}
              fromWindow={this.props.fromWindow}
            />,
            document.body,
          )}
      </React.Fragment>
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          eventId: this.props.eventId || '',
          filters: { query: this.state.query },
          includeEvent: !!this.props.eventId,
        }}
        renderSuccess={this.renderInput}
        renderLoading={this.renderInput}
      />
    );
  }
}
