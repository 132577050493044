/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import sortCustomFieldOptions from 'utils/customization/sortCustomFieldOptions';

import TextInput from 'components/material/TextInput';

import type { QuestionSelectOptionType, QuestionType } from '../../lib/types';
import EditableQuestionSelectOptionRow from './EditableQuestionSelectOptionRow';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 18px;
  position: relative;
  ${props =>
    props.showShadow &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 15px;
        background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.001));
        pointer-events: none;
      }
    `};
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0.001));
    pointer-events: none;
  }
`;

const Options = styled.div`
  flex: 1 1 auto;
  max-height: 235px;
  min-height: 50px;
  overflow: auto;
  padding: 0 15px 0 0;
`;

const EmptyMessage = styled.div`
  text-align: center;
  color: #3e4859;
  a {
    color: #3ba9da;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const MultiselectContainer = styled.div`
  margin: 5px 5px -20px;
  text-align: right;
  a {
    color: #3ba9da;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default class EditableQuestionSelectOptions extends React.PureComponent<
  {
    question: QuestionType,
    onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
    options: $ReadOnlyArray<QuestionSelectOptionType>,
    mappingLabel: string,
    hideOtherOption?: boolean,
    emptyMessage?: React.Node,
  },
  {
    keyword: string,
  },
> {
  state = {
    keyword: '',
  };

  componentDidMount() {
    if (this.props.question.selectOptions.length === 0) {
      this.props.onChangeQuestion({
        selectOptions: this.props.options,
        selectShowOtherOption: true,
      });
    }
  }

  isMultiselect = () =>
    this.props.question.mappingCustomField &&
    this.props.question.mappingCustomField.kind === 'MULTISELECT';

  filteredOptions = () => {
    const { keyword } = this.state;

    return this.props.options.filter(option => option.name.toLowerCase().includes(keyword));
  };

  handleShowOption = (id: string) => {
    const orgOption = this.props.options.find(option => option.id === id);

    if (!orgOption) return;

    this.props.onChangeQuestion({
      selectOptions: [...this.props.question.selectOptions, orgOption],
    });
  };

  handleHideOption = (id: string) => {
    this.props.onChangeQuestion({
      selectOptions: this.props.question.selectOptions.filter(opt => opt.id !== id),
    });
  };

  handleShowOtherOption = () => {
    this.props.onChangeQuestion({ selectShowOtherOption: true });
  };

  handleHideOtherOption = () => {
    this.props.onChangeQuestion({ selectShowOtherOption: false });
  };

  handleShowAllOptions = () => {
    this.props.onChangeQuestion({ selectOptions: this.props.options });
  };

  handleHideAllOptions = () => {
    this.props.onChangeQuestion({ selectOptions: [] });
  };

  handleSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { keyword } = this.state;

    if (keyword !== e.currentTarget.value.trim()) {
      this.setState({
        keyword: e.currentTarget.value.trim().toLowerCase(),
      });
    }
  };

  render() {
    const { options, question, hideOtherOption, emptyMessage } = this.props;

    const filteredOptions = this.filteredOptions();
    const showLinkToSettings = filteredOptions.length === 0;

    return (
      <React.Fragment>
        {options.length > 5 && (
          <React.Fragment>
            <TextInput
              placeholder={`Search for ${this.props.mappingLabel || ''}`}
              onChange={this.handleSearchChange}
            />

            {options.length === filteredOptions.length && (
              <MultiselectContainer>
                {question.selectOptions.length === options.length ? (
                  <a onClick={this.handleHideAllOptions}>hide all</a>
                ) : (
                  <a onClick={this.handleShowAllOptions}>show all</a>
                )}
              </MultiselectContainer>
            )}
          </React.Fragment>
        )}
        <Container showShadow={!showLinkToSettings}>
          <Options question={question}>
            {showLinkToSettings && (
              <EmptyMessage>
                {emptyMessage != null ? (
                  emptyMessage
                ) : (
                  <>
                    Add options in{' '}
                    <a
                      href="/settings/events/event_fields"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Workspace Settings
                    </a>
                  </>
                )}
              </EmptyMessage>
            )}

            {sortCustomFieldOptions(filteredOptions).map(option => (
              <EditableQuestionSelectOptionRow
                key={option.id}
                type={this.isMultiselect() ? 'checkbox' : 'radio'}
                active={question.selectOptions.some(opt => opt.id === option.id)}
                option={option}
                onShow={this.handleShowOption}
                onHide={this.handleHideOption}
              />
            ))}

            {!hideOtherOption && (
              <EditableQuestionSelectOptionRow
                type={this.isMultiselect() ? 'checkbox' : 'radio'}
                active={question.selectShowOtherOption || false}
                option={{ id: 'OTHER', name: 'Other' }}
                onShow={this.handleShowOtherOption}
                onHide={this.handleHideOtherOption}
              />
            )}
          </Options>
        </Container>
      </React.Fragment>
    );
  }
}
