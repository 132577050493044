/* @flow */
import * as React from 'react';

import countries from 'config/countries.json';
import usStates from 'config/us_states.json';

import type { FieldType } from 'utils/customization/types';
import renderFilterByKind from 'utils/filters/renderFilterByKind';

import contactCreateMethods from 'components/Contacts/enums/contactCreateMethods';
import salesforceSyncAsOptions from 'components/Contacts/enums/salesforceSyncAsOptions';
import salesforceSyncStatuses from 'components/Contacts/enums/salesforceSyncStatuses';
import type { ParsedContactsFilters } from 'components/Contacts/lib/parseContactsFilters';
import AutocompleteFilterItem from 'components/material/Filters/Advanced/AutocompleteFilterItem';
import CheckFilterItem from 'components/material/Filters/Advanced/CheckFilterItem';
import DateFilterItem from 'components/material/Filters/Advanced/DateFilterItem';
import { type FilterValueChangeParam } from 'components/material/Filters/Advanced/FilterItems';
import RadioFilterItem from 'components/material/Filters/Advanced/RadioFilterItem';
import TextFilterItem from 'components/material/Filters/Advanced/TextFilterItem';
import UserFilterItem from 'components/material/Filters/Advanced/UserFilterItem';

export default function renderContactFilter(
  customField: FieldType,
  filters: ParsedContactsFilters,
  salesforceLeadStatuses?: $ReadOnlyArray<{|
    value: string,
    label: string,
  |}>,
  onFilterChange: (name: string, value: FilterValueChangeParam) => void,
): React.Node {
  switch (customField.fieldName) {
    case 'city':
      return (
        <TextFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
        />
      );
    case 'state':
      return (
        <AutocompleteFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
          options={Object.entries(usStates).map(([key, value]) => [String(value), String(key)])}
          allowCustomValue
          autoFocus
        />
      );
    case 'country':
      return (
        <AutocompleteFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
          options={['United States', ...countries.filter(c => c !== 'United States')]}
          autoFocus
        />
      );
    case 'owner_id':
    case 'created_by':
    case 'updated_by':
      return (
        <UserFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
        />
      );
    case 'contact_type_id':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          activeValues={filters[customField.fieldName]}
          options={customField.options}
          onChange={onFilterChange}
          unsorted
        />
      );
    case 'salesforce_lead_status_id':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          activeValues={filters[customField.fieldName]}
          options={salesforceLeadStatuses || []}
          onChange={onFilterChange}
          unsorted
        />
      );
    case 'salesforce_sync_as':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          activeValues={filters[customField.fieldName]}
          options={salesforceSyncAsOptions}
          onChange={onFilterChange}
          unsorted
        />
      );
    case 'sync_status':
    case 'membership_sync_status':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          activeValues={filters[customField.fieldName]}
          options={salesforceSyncStatuses}
          onChange={onFilterChange}
          unsorted
        />
      );
    case 'attendance_status_id':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          activeValues={filters[customField.fieldName]}
          options={customField.options}
          onChange={onFilterChange}
          unsorted
        />
      );
    case 'registration_status_id':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          activeValues={filters[customField.fieldName]}
          options={customField.options}
          onChange={onFilterChange}
          unsorted
        />
      );
    case 'created_method':
    case 'updated_method':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          activeValues={filters[customField.fieldName]}
          options={contactCreateMethods}
          onChange={onFilterChange}
          unsorted
        />
      );
    case 'created_at':
    case 'updated_at':
    case 'registered_at':
      return (
        <DateFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValue={filters[customField.fieldName]}
          hideShortcutOptions
        />
      );
    case 'hot_lead':
    case 'email_opt_in':
      return (
        <RadioFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]}
          activeValue={
            filters[customField.fieldName] == null ? null : filters[customField.fieldName]
          }
        />
      );
    default:
      return renderFilterByKind(customField, filters[customField.id], onFilterChange);
  }
}
