/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoryPaidAmountFooterCell_event$ref: FragmentReference;
export type CategoryPaidAmountFooterCell_event = {|
  +totalPaidAmount: number,
  +$refType: CategoryPaidAmountFooterCell_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CategoryPaidAmountFooterCell_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalPaidAmount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '139d32cd8822b3273e003468c57971a0';
module.exports = node;
