/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type EventRequestSubmissionStatus = "APPROVED" | "ARCHIVED" | "DECLINED" | "NEW" | "RESUBMITTED" | "RETURNED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RequestSubmissionStatus_requestSubmission$ref: FragmentReference;
export type RequestSubmissionStatus_requestSubmission = {|
  +id: string,
  +status: EventRequestSubmissionStatus,
  +$refType: RequestSubmissionStatus_requestSubmission$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RequestSubmissionStatus_requestSubmission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0b830dbb1933f923d32fe0b2c5b89c59';
module.exports = node;
