/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { CompanyContactTitle_contact } from './__generated__/CompanyContactTitle_contact.graphql';

class CompanyContactTitle extends React.Component<{
  contact: CompanyContactTitle_contact,
}> {
  render() {
    return this.props.contact.title;
  }
}

export default createFragmentContainer(
  CompanyContactTitle,
  graphql`
    fragment CompanyContactTitle_contact on Contact {
      title
    }
  `,
);
