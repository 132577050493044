/**
 * @flow
 * @relayHash a2502372f829a07ff00d104482647f83
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateContactFormBccEmailInput = {
  clientMutationId?: ?string,
  contactFormBccEmailId: string,
  email: string,
};
export type updateContactFormBccEmailMutationVariables = {|
  input: UpdateContactFormBccEmailInput
|};
export type updateContactFormBccEmailMutationResponse = {|
  +updateContactFormBccEmail: ?{|
    +contactFormBccEmail: {|
      +id: string,
      +email: string,
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type updateContactFormBccEmailMutation = {|
  variables: updateContactFormBccEmailMutationVariables,
  response: updateContactFormBccEmailMutationResponse,
|};
*/


/*
mutation updateContactFormBccEmailMutation(
  $input: UpdateContactFormBccEmailInput!
) {
  updateContactFormBccEmail(input: $input) {
    contactFormBccEmail {
      id
      email
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateContactFormBccEmailInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateContactFormBccEmail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateContactFormBccEmailInput!"
      }
    ],
    "concreteType": "UpdateContactFormBccEmailPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contactFormBccEmail",
        "storageKey": null,
        "args": null,
        "concreteType": "ContactFormBccEmail",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "registrationForm",
        "storageKey": null,
        "args": null,
        "concreteType": "RegistrationForm",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasChanges",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateContactFormBccEmailMutation",
  "id": null,
  "text": "mutation updateContactFormBccEmailMutation(\n  $input: UpdateContactFormBccEmailInput!\n) {\n  updateContactFormBccEmail(input: $input) {\n    contactFormBccEmail {\n      id\n      email\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateContactFormBccEmailMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "updateContactFormBccEmailMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bb2e21e5790907a8a5a3111273c17d12';
module.exports = node;
