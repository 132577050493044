/* @flow */
import { graphql } from 'react-relay';

import { type EventStateGraphValue } from 'config/eventStates';

import type { Location } from 'utils/locations/locationTypes';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  createEventMutationResponse,
  G2WLocationInput,
  ZoomLocationInput,
} from './__generated__/createEventMutation.graphql';

type EventDetails = {|
  +name: string,
  +website?: ?string,
  +virtualLocation?: ?string,
  +startDate?: ?string,
  +status?: ?EventStateGraphValue,
  +startDateAllDay?: ?boolean,
  +leadId?: ?string,
  +endDate?: ?string,
  +endDateAllDay?: ?boolean,
  +templateId?: ?number,
  +copyTimeLine?: boolean,
  +location: ?Location,
  +zoomLocation?: ?ZoomLocationInput,
  +g2wLocation?: ?G2WLocationInput,
|};

export type trackDataType = {|
  +fromWindow: string,
  +templateType: ?string,
|};

const mutation = graphql`
  mutation createEventMutation($input: CreateEventInput!) {
    createEvent(input: $input) {
      eventEdge {
        __typename
        node {
          id
          dbId
          name
          commitmentLevel
          slug
        }
      }
    }
  }
`;

export default function createEvent(
  teamId: string,
  event: EventDetails,
  trackData: trackDataType,
): Promise<createEventMutationResponse> {
  const variables = {
    input: {
      teamId,
      ...event,
      ...trackData,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: teamId,
      edgeName: 'eventEdge',
      connections: [
        { field: 'events' },
        { key: 'AllEventsTablePagination_events' },
        { field: 'events', args: { listType: 'UPCOMING' } },
      ],
    }),
  });
}
