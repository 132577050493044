/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { type Match, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import publicRuleNames from 'config/publicRuleNames';

import downloadedOrgEventView from 'utils/analytics/events/downloadedOrgEventView';
import currentOrigin from 'utils/currentOrigin';

import { type filtersForExportType } from 'components/AllEvents/lib/parseAllEventsFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ExternalCalendarExport from 'components/ExternalCalendarExport';
import ShareDropdown from 'components/ShareDropdown';

import type { AllEventsShareActionMenuQueryResponse } from './__generated__/AllEventsShareActionMenuQuery.graphql';

const query = graphql`
  query AllEventsShareActionMenuQuery(
    $resourceToken: String
    $filters: EventFilters!
    $columns: [String!]
    $orgSlug: String
    $publicRuleName: String!
  ) {
    publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
    org {
      id
      eventReportCSVProcessId(token: $resourceToken, filters: $filters, columns: $columns)
    }
  }
`;

const StyledShareDropdown = styled(ShareDropdown)`
  padding-left: 0;
`;

class AllEventsShareActionMenu extends React.PureComponent<
  {
    csvExportVariables: filtersForExportType,
    shownColumns: $ReadOnlyArray<string>,
    userEmail: ?string,
    match: Match,
    orgSlug: string,
    listName: string,
    listToken: string,
  },
  {
    showCSVDownloadOverlay: boolean,
    showCalendarExportWindow: boolean,
  },
> {
  state = {
    showCSVDownloadOverlay: false,
    showCalendarExportWindow: false,
  };

  handleCsvExport = () => {
    this.setState({ showCSVDownloadOverlay: true });
  };

  handleCsvDownload = () => {
    downloadedOrgEventView();
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCSVDownloadOverlay: false });
  };

  handleShowAddCalendarWindow = () => {
    this.setState({ showCalendarExportWindow: true });
  };

  handleHideAddCalendarWindow = () => {
    this.setState({ showCalendarExportWindow: false });
  };

  renderDownloadOverlay = () => {
    const { match, shownColumns, csvExportVariables, userEmail, orgSlug } = this.props;
    return (
      <DefaultQueryRenderer
        query={query}
        public={!!match.params.resourceToken}
        variables={{
          filters: csvExportVariables,
          columns: shownColumns,
          resourceToken: match.params.resourceToken,
          publicRuleName: publicRuleNames.PUBLIC_EVENT_LIST,
          orgSlug,
        }}
        renderSuccess={(response: AllEventsShareActionMenuQueryResponse) => {
          return response.org ? (
            <DownloadOverlayWithEmail
              processUUID={response.org.eventReportCSVProcessId}
              fileName={`Circa Events Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleCsvDownloadEnd}
              email={userEmail}
              exportable="all_events_csv"
              onDownload={this.handleCsvDownload}
            />
          ) : null;
        }}
        renderLoading={() => null}
      />
    );
  };

  renderCalendarExportWindow = () => {
    const { listName, listToken } = this.props;
    // Preview and Stylize case
    if (listToken === '') return null;

    const icsUrl = `${currentOrigin()}/v1/events_calendar/${listToken}`;
    return (
      <ExternalCalendarExport
        listTitle={`${listName} events`}
        icsUrl={icsUrl}
        onHide={this.handleHideAddCalendarWindow}
      />
    );
  };

  render() {
    const { showCSVDownloadOverlay, showCalendarExportWindow } = this.state;
    const shareOptions = [
      {
        icon: 'file-excel-o',
        onClick: this.handleCsvExport,
        label: 'Download current view (CSV)',
      },
      {
        icon: 'calendar-plus-o',
        onClick: this.handleShowAddCalendarWindow,
        label: 'Sync with external calendar',
        disabled: this.props.listToken === '',
      },
    ];

    return (
      <>
        <StyledShareDropdown options={shareOptions} noBorder />
        {showCSVDownloadOverlay && this.renderDownloadOverlay()}
        {showCalendarExportWindow && this.renderCalendarExportWindow()}
      </>
    );
  }
}

export default withRouter(AllEventsShareActionMenu);
