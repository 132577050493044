/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ScheduleDayForm_schedule$ref = any;
type ScheduleDayTemp_event$ref = any;
type ScheduleDay_event$ref = any;
type ScheduleDay_scheduleDay$ref = any;
type ScheduleFilters_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduleItem_schedule$ref: FragmentReference;
export type ScheduleItem_schedule = {|
  +id: string,
  +dbId: number,
  +name: string,
  +scheduleDays: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +date: any,
        +scheduleItems: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +scheduleParticipants: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string
                  |}
                |}>
              |}
            |}
          |}>
        |},
        +$fragmentRefs: ScheduleDay_scheduleDay$ref,
      |}
    |}>
  |},
  +event: {|
    +name: string,
    +tz: string,
    +startDate: ?any,
    +viewerCanCreateSchedules: boolean,
    +schedules: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +$fragmentRefs: ScheduleFilters_event$ref & ScheduleDay_event$ref & ScheduleDayTemp_event$ref,
  |},
  +$fragmentRefs: ScheduleDayForm_schedule$ref,
  +$refType: ScheduleItem_schedule$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  v0
];
return {
  "kind": "Fragment",
  "name": "ScheduleItem_schedule",
  "type": "Schedule",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dbId",
      "args": null,
      "storageKey": null
    },
    v1,
    {
      "kind": "FragmentSpread",
      "name": "ScheduleDayForm_schedule",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "scheduleDays",
      "storageKey": null,
      "args": null,
      "concreteType": "ScheduleDayRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ScheduleDayRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ScheduleDay",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "date",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ScheduleDay_scheduleDay",
                  "args": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "scheduleItems",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ScheduleItemRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ScheduleItemRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ScheduleItem",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "scheduleParticipants",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ScheduleParticipantRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "ScheduleParticipantRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "ScheduleParticipant",
                                      "plural": false,
                                      "selections": v2
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tz",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "startDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "viewerCanCreateSchedules",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ScheduleFilters_event",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ScheduleDay_event",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ScheduleDayTemp_event",
          "args": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "schedules",
          "storageKey": "schedules(first:2)",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 2,
              "type": "Int"
            }
          ],
          "concreteType": "ScheduleRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ScheduleRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Schedule",
                  "plural": false,
                  "selections": v2
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ab6c861695bcd3a6002103ba9ede5c1';
module.exports = node;
