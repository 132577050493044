/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import EditableFormHeader from 'components/EventRequestForm/EditableFormHeader';
import EditableSection from 'components/EventRequestForm/EditableSection';
import type {
  FormDetailsType,
  OrderRestrictionType,
  QuestionType,
  SectionType,
} from 'components/EventRequestForm/lib/types';
import RequesterInfo from 'components/EventRequestForm/RequesterInfo';

import type { createEventRequestFormMutationResponse } from '../../graph/mutations/eventRequest/__generated__/createEventRequestFormMutation.graphql';
import type { updateEventRequestFormMutationResponse } from '../../graph/mutations/eventRequest/__generated__/updateEventRequestFormMutation.graphql';
import EventRequestFormContainer from './components/EventRequestFormContainer';
import EventRequestFormBuilderFooter from './EventRequestFormBuilderFooter';

import type { EventRequestFormBuilderBody_requestForm } from './__generated__/EventRequestFormBuilderBody_requestForm.graphql';
import type { EventRequestFormBuilderQuestionnaire_org } from './__generated__/EventRequestFormBuilderQuestionnaire_org.graphql';

const HeaderContainer = styled(EventRequestFormContainer)`
  margin-bottom: 20px;
  padding: 20px 26px;
`;

const HorizontalLine = styled.hr`
  display: block;
  height: 1px;
  margin: 25px 0;
  border-top: 1px solid #d8d8d8;
`;

class EventRequestFormBuilderQuestionnaire extends React.PureComponent<
  {
    org: EventRequestFormBuilderQuestionnaire_org,
    editingRequestForm: FormDetailsType,
    requestForm: EventRequestFormBuilderBody_requestForm,
    isSubmitted: boolean,
    onChangeFormDetails: (details: $Shape<FormDetailsType>, callback?: () => void) => void,
    hasChanges: boolean,
    scrollElement: ?HTMLDivElement,
    onHasChanges: (hasChanges: boolean) => void,
    onNextStep: () => void,
    saveForm: () => Promise<
      updateEventRequestFormMutationResponse | createEventRequestFormMutationResponse,
    >,
  },
  {
    errors: $ReadOnlyArray<string>,
    saved: boolean,
    saving: boolean,
    draggingQuestion: ?QuestionType,
    restrictions: OrderRestrictionType,
    restrictedDruggingZone: boolean,
  },
> {
  state = {
    errors: [],
    saved: false,
    saving: false,
    draggingQuestion: null,
    restrictions: { min: null, max: null },
    restrictedDruggingZone: false,
  };

  editingQuestionIds: $ReadOnlyArray<string> = [];

  movedSection: ?SectionType = null;

  saveOnChanges: boolean = false;

  editingHeader: boolean = false;

  timeout: ?TimeoutID = null;

  componentDidUpdate() {
    if (this.saveOnChanges && this.props.hasChanges) {
      this.autoSave();
      this.saveOnChanges = false;
    }
    // movedSection used for scrolling body to that section when you change section order
    // Bunch of condition checks bellow because of flow
    if (this.movedSection != null) {
      const { id, order } = this.movedSection;
      const section = this.props.editingRequestForm.sections.find(item => item.id === id);
      if (section != null && section.order !== order) {
        // Scroll to section after order change
        const sectionElement = document.querySelector(`.section_${section.id.replace(/=/g, '')}`);
        const { scrollElement } = this.props;
        if (scrollElement != null && sectionElement != null) {
          scrollElement.scrollTop = sectionElement.offsetTop - 100;
          this.movedSection = null;
        }
      }
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  handleRemoveSection = (sectionId: string) => {
    this.props.onChangeFormDetails({
      sections: sortBy(
        this.props.editingRequestForm.sections.filter(section => section.id !== sectionId),
        'order',
      ).map((section, index) => ({ ...section, order: index + 1 })),
    });
    this.props.onHasChanges(true);
    this.saveOnChanges = true;
  };

  handleRemoveQuestion = (sectionId: string, questionId: string) => {
    this.props.onChangeFormDetails(
      {
        sections: this.props.editingRequestForm.sections.map(section => {
          if (section.questions != null) {
            const changedSection = { ...section };
            changedSection.questions = sortBy(section.questions, 'order')
              .filter(question => question.id !== questionId)
              .map((question, index) => ({ ...question, order: index + 1 }));
            return changedSection;
          }
          return section;
        }),
      },
      () => {
        this.handleChangeEditingQuestion(questionId, false);
        if (!questionId.startsWith('question_')) {
          this.props.onHasChanges(true);
          this.saveOnChanges = true;
        }
      },
    );
  };

  handleMoveSection = (currentOrder: number, targetOrder: number) => {
    this.props.onChangeFormDetails({
      sections: this.props.editingRequestForm.sections.map(section => {
        if (section.order === currentOrder || section.order === targetOrder) {
          // Is used for scrolling to section after reorder
          if (section.order === currentOrder) {
            this.movedSection = section;
          }
          return {
            ...section,
            order: section.order === currentOrder ? targetOrder : currentOrder,
          };
        }
        return section;
      }),
    });
    this.props.onHasChanges(true);
    this.saveOnChanges = true;
  };

  handleChangeEditingHeader = (editing: boolean) => {
    this.editingHeader = editing;
    if (this.state.errors.length > 0) {
      this.setState({ errors: this.errors() });
    } else {
      this.autoSave();
    }
  };

  handleChangeEditingQuestion = (questionId: string, editing: boolean) => {
    if (editing) {
      this.editingQuestionIds = [...this.editingQuestionIds, questionId];
    } else {
      this.editingQuestionIds = this.editingQuestionIds.filter(qId => qId !== questionId);
      this.autoSave();
    }

    if (this.state.errors.length > 0) {
      this.setState({ errors: this.errors() });
    }
  };

  errors = () => {
    const errors = [];

    if (this.editingHeader) {
      errors.push('Form title is required');
    }

    if (this.editingQuestionIds.length !== 0) {
      errors.push('Please correct the errors in questions');
    }

    return errors;
  };

  handleNext = () => {
    window.requestAnimationFrame(() => {
      const errors = this.errors();
      if (errors.length === 0) {
        this.props.onNextStep();
      } else {
        this.setState({ errors });
      }
    });
  };

  autoSave = () => {
    if (this.errors().length === 0 && this.props.hasChanges) {
      this.setState({ saving: true });
      this.props.saveForm().then(() => {
        this.setState({ saved: true, saving: false }, () => {
          this.props.onHasChanges(false);
          this.timeout = setTimeout(() => this.setState({ saved: false }), 1000);
        });
      });
    }
  };

  handleSetDraggingQuestion = (question: ?QuestionType) => {
    this.setState({ draggingQuestion: question });
  };

  render() {
    const { editingRequestForm, org } = this.props;
    const usedMappings = editingRequestForm.sections
      .reduce(
        (acc, section) => [
          ...acc,
          ...section.questions.map(question =>
            question.mappingCustomField ? question.mappingCustomField.id : question.mapping,
          ),
        ],
        [],
      )
      .filter(Boolean);

    return (
      <>
        <HeaderContainer>
          <EditableFormHeader
            orgName={org.name}
            name={editingRequestForm.name}
            description={editingRequestForm.description}
            logo={editingRequestForm.logo}
            orgLogo={org.settings.logo}
            onChangeFormDetails={this.props.onChangeFormDetails}
            onChangeEditing={this.handleChangeEditingHeader}
            onHasChanges={this.props.onHasChanges}
          />
          <HorizontalLine />
          <RequesterInfo
            firstName=""
            lastName=""
            email=""
            errors={{}}
            onChange={() => {}}
            readOnly
          />
        </HeaderContainer>

        {sortBy(this.props.editingRequestForm.sections, 'order').map(section => (
          <EditableSection
            key={section.id}
            org={this.props.org}
            requestForm={this.props.requestForm}
            editingRequestForm={this.props.editingRequestForm}
            section={section}
            usedMappings={usedMappings}
            isSubmitted={this.props.isSubmitted}
            errors={this.state.errors}
            onHasChanges={this.props.onHasChanges}
            draggingQuestion={this.state.draggingQuestion}
            onChangeEditingQuestion={this.handleChangeEditingQuestion}
            onChangeFormDetails={this.props.onChangeFormDetails}
            onMoveSection={this.handleMoveSection}
            onRemoveQuestion={this.handleRemoveQuestion}
            onRemoveSection={this.handleRemoveSection}
            onSetDraggingQuestion={this.handleSetDraggingQuestion}
            onAutoSave={this.autoSave}
            saving={this.state.saving}
            restrictions={this.state.restrictions}
            onSetRestriction={restrictions => {
              this.setState({ restrictions });
            }}
            restrictedDruggingZone={this.state.restrictedDruggingZone}
            onSetRestrictedDruggingZone={restrictedDruggingZone => {
              this.setState({ restrictedDruggingZone });
            }}
          />
        ))}

        <EventRequestFormBuilderFooter
          activeStepIndex={0}
          onNextStep={this.handleNext}
          errors={this.state.errors}
          saved={this.state.saved}
          saving={this.state.saving}
          loading={false}
        />
      </>
    );
  }
}

export default createFragmentContainer(
  EventRequestFormBuilderQuestionnaire,
  graphql`
    fragment EventRequestFormBuilderQuestionnaire_org on Org {
      id
      name
      settings {
        logo
      }
      ...EditableQuestionContainer_org
    }
  `,
);
