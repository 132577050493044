/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import Overlay from 'components/material/Overlay';

import AllEventsCalendarEventWindowPublicQuery from './AllEventsCalendarEventWindowPublicQuery';
import AllEventsCalendarEventWindowQuery from './AllEventsCalendarEventWindowQuery';

export type ClickCoordinates = { clientX: number, clientY: number };

const StyledOverlay = styled(Overlay)`
  width: 374px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  color: #3e4859;
`;

class AllEventsCalendarEventWindow extends React.PureComponent<{
  eventSlug: string,
  isShared: boolean,
  clickCoordinates: ?ClickCoordinates,
  linkEventNameToBrief?: boolean,
  onHide: () => void,
}> {
  targetRef = React.createRef();

  componentDidMount() {
    this.forceUpdate();
  }

  render() {
    const { eventSlug, isShared, clickCoordinates, linkEventNameToBrief, onHide } = this.props;

    return (
      <>
        {clickCoordinates &&
          document.body &&
          createPortal(
            <div
              style={{
                position: 'absolute',
                top: clickCoordinates.clientY,
                left: clickCoordinates.clientX,
              }}
              ref={this.targetRef}
            />,
            document.body,
          )}

        {this.targetRef.current && (
          <StyledOverlay
            show
            key={eventSlug}
            target={this.targetRef.current}
            container={document.body}
            onHide={onHide}
            placement="auto"
            keepInViewport
          >
            {isShared ? (
              <AllEventsCalendarEventWindowPublicQuery
                eventSlug={eventSlug}
                onHide={onHide}
                linkEventNameToBrief={linkEventNameToBrief}
              />
            ) : (
              <AllEventsCalendarEventWindowQuery
                eventSlug={eventSlug}
                onHide={onHide}
                linkEventNameToBrief={linkEventNameToBrief}
              />
            )}
          </StyledOverlay>
        )}
      </>
    );
  }
}

export default AllEventsCalendarEventWindow;
