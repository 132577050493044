/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventHeaderActions_event$ref = any;
type EventHeaderStatus_event$ref = any;
type Location_event$ref = any;
type PhysicalLocation_event$ref = any;
export type EventRequestSubmissionStatus = "APPROVED" | "ARCHIVED" | "DECLINED" | "NEW" | "RESUBMITTED" | "RETURNED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventHeader_event$ref: FragmentReference;
export type EventHeader_event = {|
  +id: string,
  +eventRequestSubmission: ?{|
    +status: EventRequestSubmissionStatus
  |},
  +dbId: number,
  +slug: string,
  +name: string,
  +startDate: ?any,
  +endDate: ?any,
  +startDateAllDay: ?boolean,
  +endDateAllDay: ?boolean,
  +website: ?string,
  +progress: ?number,
  +mappedToSalesforce: boolean,
  +viewerCanUpdate: boolean,
  +tz: string,
  +virtualLocation: ?string,
  +primaryLocation: ?{|
    +id: string
  |},
  +team: {|
    +id: string,
    +name: string,
  |},
  +__typename: "Event",
  +$fragmentRefs: EventHeaderStatus_event$ref & EventHeaderActions_event$ref & Location_event$ref & PhysicalLocation_event$ref,
  +$refType: EventHeader_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventHeader_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventRequestSubmission",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestSubmission",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dbId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "startDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "startDateAllDay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endDateAllDay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "website",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "progress",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mappedToSalesforce",
      "args": [
        {
          "kind": "Literal",
          "name": "field",
          "value": "WEBSITE",
          "type": "EventIntegrationFields!"
        }
      ],
      "storageKey": "mappedToSalesforce(field:\"WEBSITE\")"
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventHeaderStatus_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventHeaderActions_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Location_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PhysicalLocation_event",
      "args": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "virtualLocation",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "primaryLocation",
      "storageKey": null,
      "args": null,
      "concreteType": "Location",
      "plural": false,
      "selections": [
        v0
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        v0,
        v1
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '0fc149d55c8a7e482dc526ebf74c05c4';
module.exports = node;
