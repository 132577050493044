/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import capitalize from 'lodash/capitalize';
import { getEventStateLabel } from 'config/eventStates';

import SimpleBriefField from '../../components/SimpleBriefField';

import { type EventInfoStatus_event } from './__generated__/EventInfoStatus_event.graphql';

const EventInfoStatus = (props: { name: string, event: EventInfoStatus_event }) => {
  const {
    name,
    event: { commitmentLevel },
  } = props;
  return (
    <SimpleBriefField
      label={name}
      contentDataAvailable={commitmentLevel}
      data={capitalize(getEventStateLabel(commitmentLevel))}
    />
  );
};

export default createFragmentContainer(
  EventInfoStatus,
  graphql`
    fragment EventInfoStatus_event on Event {
      commitmentLevel
    }
  `,
);
