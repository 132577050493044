/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';

import publicRuleNames from 'config/publicRuleNames';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import AllEventsMapMarkerInfoBox from './AllEventsMapMarkerInfoBox';

import type { AllEventsMapMarkerInfoQueryResponse } from './__generated__/AllEventsMapMarkerInfoQuery.graphql';

const Container = styled.div`
  width: 273px;
`;

const query = graphql`
  query AllEventsMapMarkerInfoQuery(
    $eventSlug: String!
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
    $isShared: Boolean!
  ) {
    ... @skip(if: $isShared) {
      event(slug: $eventSlug) {
        ...AllEventsMapMarkerInfoBox_event
      }
    }
    ... @include(if: $isShared) {
      publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
      eventList(resourceToken: $resourceToken) {
        event(slug: $eventSlug) {
          ...AllEventsMapMarkerInfoBox_event
        }
      }
    }
  }
`;

export default class AllEventsMapMarkerInfo extends React.PureComponent<{
  slug: string,
  resourceToken: string,
  orgSlug: ?string,
  match: Match,
  location: Location,
}> {
  render() {
    const { slug, resourceToken, orgSlug, match, location } = this.props;
    const isShared = !!resourceToken;

    return (
      <Container>
        <DefaultQueryRenderer
          public={isShared}
          query={query}
          variables={{
            eventSlug: slug,
            publicRuleName: publicRuleNames.PUBLIC_EVENT_LIST,
            resourceToken: resourceToken || '',
            orgSlug,
            isShared,
          }}
          renderSuccess={(props: AllEventsMapMarkerInfoQueryResponse) => (
            <AllEventsMapMarkerInfoBox
              linkEvent={!isShared}
              event={props.eventList ? props.eventList.event : props.event}
              match={match}
              location={location}
            />
          )}
          renderLoading={() => null}
        />
      </Container>
    );
  }
}
