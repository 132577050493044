/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';

import type {
  PageComponentKindType,
  ReorderedPageComponentConfigType,
} from '../pageComponentsConfig';
import type { SelectedComponent } from '../RegistrationCreateContent';
import PagePropertiesHeader from './PagePropertiesHeader';
import PagePropertiesRoutes from './PagePropertiesRoutes';

import type { RegistrationPageProperties_event } from './__generated__/RegistrationPageProperties_event.graphql';
import type { RegistrationPageProperties_org } from './__generated__/RegistrationPageProperties_org.graphql';
import type { RegistrationPageProperties_registrationForm } from './__generated__/RegistrationPageProperties_registrationForm.graphql';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`;

const RegistrationPageProperties = (props: {
  registrationForm: RegistrationPageProperties_registrationForm,
  org: RegistrationPageProperties_org,
  event?: RegistrationPageProperties_event,
  match: Match,
  location: Location,
  onMoveEnd: (reorderedPageComponentConfig?: ReorderedPageComponentConfigType) => void,
  onBeginDrag: (draggedNewComponentKind: PageComponentKindType) => void,
  onChangePageWidth: (width: number) => void,
  formComponentSelected: boolean,
  onChangeSelectedComponentProperty: (updatedProps: SelectedComponent) => void,
  onHoverPageComponent: (kind: PageComponentKindType) => void,
  onHoverOutPageComponent: () => void,
}) => {
  const {
    registrationForm,
    org,
    event,
    match,
    location,
    onMoveEnd,
    onBeginDrag,
    onChangePageWidth,
    formComponentSelected,
    onChangeSelectedComponentProperty,
    onHoverPageComponent,
    onHoverOutPageComponent,
  } = props;
  return (
    <Container>
      {!org.syncedToIbmWm && event && (
        <PagePropertiesHeader eventSlug={event.slug} location={location} />
      )}
      <PagePropertiesRoutes
        registrationForm={registrationForm}
        org={org}
        event={event}
        pathPrefix={match.path}
        location={location}
        formComponentSelected={formComponentSelected}
        onMoveEnd={onMoveEnd}
        onBeginDrag={onBeginDrag}
        onChangePageWidth={onChangePageWidth}
        onChangeSelectedComponentProperty={onChangeSelectedComponentProperty}
        onHoverPageComponent={onHoverPageComponent}
        onHoverOutPageComponent={onHoverOutPageComponent}
      />
    </Container>
  );
};

export default createFragmentContainer(RegistrationPageProperties, {
  registrationForm: graphql`
    fragment RegistrationPageProperties_registrationForm on RegistrationForm {
      ...PagePropertiesRoutes_registrationForm
    }
  `,
  org: graphql`
    fragment RegistrationPageProperties_org on Org {
      ...PagePropertiesRoutes_org
      syncedToIbmWm
    }
  `,
  event: graphql`
    fragment RegistrationPageProperties_event on Event {
      id
      slug
      ...PagePropertiesRoutes_event
    }
  `,
});
