/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import Papa from 'papaparse';

import downloadFile from 'utils/downloadFile';

import Cancel from 'images/cancel.svg';
import Check from 'images/check.svg';
import ImportUpload from 'images/importUpload.svg';
import Merge from 'images/merge.svg';
import Button from 'components/material/Button';
import ProgressBar from 'components/material/ProgressBar';

import type { ErrorType, ImportResults, MappingsType, ProcessingResults } from './index';

const Container = styled.div`
  min-height: 300px;
  padding-top: 50px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    margin: 20px 0 40px 0;
  }
`;

const InfoMessage = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #3e4859;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 182px;
  margin-top: 15px;
`;

const ImportResultsContainer = styled.div`
  flex: 1 1 auto;
  margin: 40px 0 50px 0;
`;

const Result = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 450px;
  height: 40px;
  margin-bottom: 10px;
  background-color: #f8f9f9;
  text-align: center;
  > img {
    display: inline-block;
  }
`;

const ResultLabel = styled.span`
  display: inline-block;
  width: 60px;
  margin: 0 14px;
`;

const Score = styled.span`
  display: inline-block;
  width: 35px;
  font-size: 13px;
  color: ${props => (props.error ? '#f48183' : '#3e4859')};
`;

const DownloadLink = styled.a`
  position: absolute;
  right: 22px;
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default class Importing<F: string> extends React.Component<
  {
    data: ProcessingResults,
    mappings: MappingsType<F>,
    importResourceName: string,
    fileName: string,
    onClose: () => void,
    onPrev: () => void,
    onImport: () => Promise<?ImportResults>,
  },
  {
    importResults: ?ImportResults,
  },
> {
  state = {
    importResults: null,
  };

  componentDidMount() {
    this.props.onImport().then((importResults: ?ImportResults) => {
      if (importResults) {
        this.setState({ importResults });
      } else {
        this.props.onPrev();
      }
    });
  }

  handleFinish = () => {
    const results = this.state.importResults;
    if (results != null && (results.importedCount || results.mergedCount)) {
      window.location.reload();
    }
    this.props.onClose();
  };

  handleErrorsDownload = () => {
    const mappingData = this.props.data.map(row =>
      Object.keys(row)
        .filter(column => Object.keys(this.props.mappings).includes(column))
        .reduce((obj, column) => ({ ...obj, [column]: row[column] }), {}),
    );
    if (this.state.importResults != null) {
      const errorData = this.state.importResults.errors.map((error: ErrorType) => ({
        Errors: error.error,
        ...mappingData[error.rowIndex],
      }));
      const csv = Papa.unparse(errorData, {
        header: true,
      });
      downloadFile(csv, `Import Errors for ${this.props.fileName}`, 'text/csv');
    }
  };

  render() {
    const { importResourceName } = this.props;
    const { importResults } = this.state;

    return (
      <Container>
        {importResults == null ? (
          <>
            <Row>
              <ImportUpload />
            </Row>
            <Row>
              <InfoMessage>Importing</InfoMessage>
            </Row>
            <Row>
              <StyledProgressBar indeterminate />
            </Row>
          </>
        ) : (
          <>
            <Row>
              <InfoMessage>{importResourceName}s import finished!</InfoMessage>
            </Row>
            <ImportResultsContainer>
              <Row>
                <Result>
                  <Check />
                  <ResultLabel>Imported</ResultLabel>
                  <Score>{importResults.importedCount}</Score>
                </Result>
              </Row>
              {importResults.mergedCount != null && (
                <Row>
                  <Result>
                    <Merge />
                    <ResultLabel>Merged</ResultLabel>
                    <Score>{importResults.mergedCount}</Score>
                  </Result>
                </Row>
              )}
              <Row>
                <Result>
                  <Cancel />
                  <ResultLabel>Errors</ResultLabel>
                  <Score error={importResults.errors.length > 0}>
                    {importResults.errors.length}
                  </Score>
                  {importResults.errors.length > 0 && (
                    <DownloadLink onClick={this.handleErrorsDownload}>
                      Download .csv file
                    </DownloadLink>
                  )}
                </Result>
              </Row>
            </ImportResultsContainer>
            <Buttons>
              <Button primary label="Finish" onClick={this.handleFinish} />
            </Buttons>
          </>
        )}
      </Container>
    );
  }
}
