/* @flow */
import React from 'react';

import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';

import type { CreatedEventType } from './EventForm';
import EventForm from './EventForm';
import EventFormQuery from './EventFormQuery';

export type EventCreateSource = 'upcoming events' | 'all events' | 'easy_button' | 'sidebar';

export default class EventWindow extends React.PureComponent<
  {
    onHide: () => void,
    onCreate: (event: CreatedEventType) => void,
    fromWindow: EventCreateSource,
  },
  Event,
> {
  eventForm: ?EventForm;

  handleOverlayHide = () => {
    if (this.eventForm && this.eventForm.isNotEdited()) {
      this.props.onHide();
    }
  };

  render() {
    return (
      <Window size="medium" onHide={this.handleOverlayHide}>
        <WindowHeader>
          <WindowTitle>Create New Event</WindowTitle>
          <WindowClose onClick={this.props.onHide} />
        </WindowHeader>
        <WindowContent>
          <EventFormQuery
            formRef={el => {
              this.eventForm = el;
            }}
            onHide={this.props.onHide}
            onCreate={this.props.onCreate}
            fromWindow={this.props.fromWindow}
          />
        </WindowContent>
      </Window>
    );
  }
}
