/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import { EditContent } from 'components/budget/Table/InlineEditableCell';
import InlineUserSelectField from 'components/budget/Table/InlineUserSelectField';
import MaterialAvatar from 'components/material/MaterialAvatar';
import RemovablePill from 'components/material/RemovablePill';
import { Container as UserSelectInputContainer, Header } from 'components/material/UserSelectInput';

import type { TaskAssignees_assignees } from './__generated__/TaskAssignees_assignees.graphql';

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-weight: 500;
  color: ${props => props.theme.rowPrimaryTextColor};
  font-size: 13px;
  margin-bottom: 10px;
`;

const Assignees = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AddAsssigneeContainer = styled.div`
  position: relative;
  min-width: 22px;
  height: 30px;
  ${Header} {
    margin-top: 1px;
    padding: 0 1px;
  }
  ${UserSelectInputContainer}, ${EditContent} {
    background-color: transparent;
  }

  > div > div:first-child {
    border: 0;
  }
`;

const UserPill = styled(RemovablePill)`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${props => props.theme.rowSecondaryTextColor};
  border-radius: 2px;
  background: ${props => props.theme.primaryRowColor};
  padding: 3px 6px;
  margin-right: 6px;
  margin-bottom: 6px;
`;

const StyledAvatar = styled(MaterialAvatar)`
  margin-right: 5px;
`;

const PlusWrapper = styled.div`
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  outline: 0;
  font-size: 16px;
  height: 100%;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

class TaskAssignees extends React.PureComponent<{
  eventId: string,
  assignees: TaskAssignees_assignees,
  onAssign: string => void,
  onUnassign: string => void,
  onInviteClick: () => void,
  disabled?: boolean,
}> {
  handleUserSelect = userId => {
    this.props.onAssign(userId);
  };

  render() {
    const { assignees, disabled, eventId, onAssign, onUnassign, onInviteClick } = this.props;
    return (
      <Container>
        <Label>Assignees</Label>
        <Assignees>
          {assignees.map(user => (
            <UserPill onRemove={() => onUnassign(user.id)} key={user.id} disabled={disabled}>
              <StyledAvatar user={user} radius={12} />
              {fullNameOfUser(user)}
            </UserPill>
          ))}
          {!disabled && (
            <AddAsssigneeContainer>
              <InlineUserSelectField
                activeUsers={assignees}
                onSelect={user => {
                  if (user) onAssign(user.id);
                }}
                disabled={disabled}
                eventId={eventId}
                onInviteClick={onInviteClick}
                hideSelected
                hideLockIcon
              >
                <PlusWrapper>
                  <i className="fa fa-fw fa-plus" />
                </PlusWrapper>
              </InlineUserSelectField>
            </AddAsssigneeContainer>
          )}
        </Assignees>
      </Container>
    );
  }
}

export default createFragmentContainer(TaskAssignees, {
  assignees: graphql`
    fragment TaskAssignees_assignees on User @relay(plural: true) {
      id
      email
      firstName
      lastName
      avatar
      ...MaterialAvatar_user
    }
  `,
});
