/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';

import { type PaymentCellPropsType } from './index';

import { type PaymentExpenseNameCell_event } from './__generated__/PaymentExpenseNameCell_event.graphql';
import { type PaymentExpenseNameCell_payment } from './__generated__/PaymentExpenseNameCell_payment.graphql';

const PaymentExpenseNameCell = ({
  payment,
  event,
}: PaymentCellPropsType<PaymentExpenseNameCell_payment, PaymentExpenseNameCell_event>) => (
  <Link to={`/events/${event.slug}/budget/${payment.expense.id}`}>{payment.expense.name}</Link>
);

export default createFragmentContainer(
  PaymentExpenseNameCell,
  graphql`
    fragment PaymentExpenseNameCell_payment on Payment {
      expense {
        id
        name
      }
    }

    fragment PaymentExpenseNameCell_event on Event {
      slug
    }
  `,
);
