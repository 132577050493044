/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import omit from 'lodash/omit';
import xorBy from 'lodash/xorBy';

import getOptionKeysByKind from 'utils/customization/getOptionKeysByKind';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import { type CustomFieldsType, type FieldType } from 'utils/customization/types';
import isValidWebsite from 'utils/validators/isValidWebsite';

import updateCustomFieldValue, {
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import showModernMutationError from 'graph/utils/showModernMutationError';

import customFields, { type InexactUser } from 'components/Customizable/customFields';
import LockIcon from 'components/LockIcon';

import General from './General';

import type { CustomSection_event } from './__generated__/CustomSection_event.graphql';
import type { CustomSection_org } from './__generated__/CustomSection_org.graphql';
import type { CustomSection_user } from './__generated__/CustomSection_user.graphql';

const Row = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  max-width: 420px;
  margin-bottom: 20px;

  @media (${props => props.theme.mobileOnly}) {
    flex-direction: column;
  }
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: ${props => (props.kind === 'BOOLEAN' ? 0 : 16)}px;
`;

type Props = {
  event: CustomSection_event,
  org: CustomSection_org,
  user: CustomSection_user,
  sectionId: string,
};

type State = {
  errors: Object,
  eventCustomFields: CustomFieldsType,
};

class CustomSection extends React.Component<Props, State> {
  state = {
    errors: {},
    eventCustomFields: {
      customTextFields: this.props.event.customTextFields,
      customTextareaFields: this.props.event.customTextareaFields,
      customLinkFields: this.props.event.customLinkFields,
      customDateFields: this.props.event.customDateFields,
      customNumberFields: this.props.event.customNumberFields,
      customCurrencyFields: this.props.event.customCurrencyFields,
      customBooleanFields: this.props.event.customBooleanFields,
      customUserSelectFields: this.props.event.customUserSelectFields,
      customUserMultiselectFields: this.props.event.customUserMultiselectFields,
      customSelectFields: this.props.event.customSelectFields,
      customMultiselectFields: this.props.event.customMultiselectFields,
    },
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      xorBy(
        [
          ...prevState.eventCustomFields.customMultiselectFields,
          ...prevState.eventCustomFields.customSelectFields,
        ],
        [...nextProps.event.customMultiselectFields, ...nextProps.event.customSelectFields],
        filed => filed.option.id,
      ).length > 0
    ) {
      return {
        eventCustomFields: {
          ...prevState.eventCustomFields,
          customMultiselectFields: nextProps.event.customMultiselectFields,
          customSelectFields: nextProps.event.customSelectFields,
        },
      };
    }
    return null;
  }

  getCurrentSection = () => {
    const { org, sectionId } = this.props;
    return org.customFieldSections.edges.find(
      ({ node }) => node.id === sectionId || (sectionId == null && node.order === 0),
    );
  };

  getCurrentSectionFields = (sectionId: string) => {
    return this.props.org.customFields.edges.filter(
      ({ node }) => node.section && node.section.id === sectionId,
    );
  };

  updateCustomFieldState = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    const { arrayKey, optionKey } = getOptionKeysByKind(field.kind);
    const value = Object.values(input)[0];
    const valuesArray = Array.isArray(value) ? value : [value].filter(Boolean);
    const optionsArray = valuesArray.map(optionId =>
      users == null ? optionId : users.find(user => user.id === optionId),
    );
    this.setState(prevState => {
      const fieldOptions = prevState.eventCustomFields[arrayKey];
      return {
        eventCustomFields: {
          ...prevState.eventCustomFields,
          [arrayKey]: [
            ...fieldOptions.filter(option => option.customField.id !== field.id),
            ...optionsArray.map(option => ({
              customField: { id: field.id },
              [optionKey]: optionKey === 'option' ? { id: option } : option,
            })),
          ],
        },
      };
    });
  };

  handleUpdateCustomField = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    this.updateCustomFieldState(field, input, users);
    const event = this.props.event;
    if (field.required && !Object.values(input)[0]) {
      this.setState(prevState => ({ errors: { ...prevState.errors, [field.id]: 'Required' } }));
      return;
    }
    if (field.kind === 'LINK' && !!input.linkValue && !isValidWebsite(input.linkValue)) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Invalid url' },
      }));
      return;
    }
    if (field.kind === 'LINK' && field.required && !input.linkValue) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    if (
      field.required &&
      ['MULTISELECT', 'USER_MULTISELECT'].includes(field.kind) &&
      input.selectValues != null &&
      input.selectValues.length === 0
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    updateCustomFieldValue(
      { customFieldId: field.id, ...input },
      event,
      event.id,
      'event info',
    ).catch(showModernMutationError);
    this.setState(prevState => ({
      errors: Object.keys(prevState.errors).reduce((obj, key) => {
        return key === field.id ? obj : { ...obj, [key]: prevState.errors[key] };
      }, {}),
    }));
  };

  render() {
    const { event, org, user } = this.props;

    const currentSection = this.getCurrentSection();
    if (currentSection == null) {
      return null;
    }

    const sortedSectionFields = normalizeCustomFields(
      this.getCurrentSectionFields(currentSection.node.id).map(({ node }) => node),
    );

    return (
      <>
        {currentSection.node.order === 0 && <General event={event} />}
        {sortedSectionFields.map(field => {
          const kind = field.kind;
          if (kind === 'DEFAULT') {
            return null;
          }

          return (
            <Row key={field.id}>
              {customFields[kind]({
                customizable: this.state.eventCustomFields,
                viewerCanUpdateValue: event.viewerCanUpdate && !field.restrictChangingValue,
                handleUpdate: (input, users) => this.handleUpdateCustomField(field, input, users),
                field: omit(field, ['section']),
                currency: org.settings.currency,
                tz: user.tz,
                errors: this.state.errors,
                eventId: event.id,
              })}
              {event.viewerCanUpdate && field.restrictChangingValue && (
                <StyledLockIcon kind={kind} label="Salesforce" />
              )}
            </Row>
          );
        })}
      </>
    );
  }
}

export default createFragmentContainer(CustomSection, {
  event: graphql`
    fragment CustomSection_event on Event {
      id
      __typename
      ...General_event
      viewerCanUpdate
      customTextFields {
        customField {
          id
        }
        value
      }
      customTextareaFields {
        customField {
          id
        }
        value
      }
      customLinkFields {
        customField {
          id
        }
        value
      }
      customDateFields {
        customField {
          id
        }
        value
      }
      customNumberFields {
        customField {
          id
        }
        value
      }
      customCurrencyFields {
        customField {
          id
        }
        value
      }
      customBooleanFields {
        customField {
          id
        }
        value
      }
      customUserSelectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...MaterialAvatar_user
        }
      }
      customUserMultiselectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...MaterialAvatar_user
        }
      }
      customSelectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
      customMultiselectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
    }
  `,
  org: graphql`
    fragment CustomSection_org on Org {
      id
      settings {
        id
        currency
      }
      customFieldSections(sectionCustomizableType: [EVENT]) {
        edges {
          node {
            id
            order
          }
        }
      }
      customFields(customizableType: [EVENT], sectionId: $sectionId) {
        edges {
          node {
            id
            kind
            order
            label
            required
            fieldName
            section {
              id
              order
            }
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
            restrictChangingValue: mappedToSalesforce(pull: true)
            mappedToSalesforce
            viewerCanUpdate
          }
        }
      }
    }
  `,
  user: graphql`
    fragment CustomSection_user on User {
      tz
    }
  `,
});
