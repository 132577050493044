/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type PaymentAmountCell_org$ref = any;
type PaymentAmountFooterCell_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentsTableRenderer_org$ref: FragmentReference;
export type PaymentsTableRenderer_org = {|
  +$fragmentRefs: PaymentAmountCell_org$ref & PaymentAmountFooterCell_org$ref,
  +$refType: PaymentsTableRenderer_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PaymentsTableRenderer_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "PaymentAmountCell_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentAmountFooterCell_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '33818117d825046053a9aec1f34fb6a3';
module.exports = node;
