/**
 * @flow
 * @relayHash 62d6839829a23f95e3d05467affab830
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FollowDeliverableInput = {
  clientMutationId?: ?string,
  deliverableId: string,
};
export type followTaskMutationVariables = {|
  input: FollowDeliverableInput
|};
export type followTaskMutationResponse = {|
  +followDeliverable: ?{|
    +deliverable: ?{|
      +id: string,
      +following: ?boolean,
    |}
  |}
|};
export type followTaskMutation = {|
  variables: followTaskMutationVariables,
  response: followTaskMutationResponse,
|};
*/


/*
mutation followTaskMutation(
  $input: FollowDeliverableInput!
) {
  followDeliverable(input: $input) {
    deliverable {
      id
      following
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "FollowDeliverableInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "followDeliverable",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "FollowDeliverableInput!"
      }
    ],
    "concreteType": "FollowDeliverablePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": null,
        "concreteType": "Deliverable",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "following",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "followTaskMutation",
  "id": null,
  "text": "mutation followTaskMutation(\n  $input: FollowDeliverableInput!\n) {\n  followDeliverable(input: $input) {\n    deliverable {\n      id\n      following\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "followTaskMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "followTaskMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aef90df2dc825b15945fe1550fdb280e';
module.exports = node;
