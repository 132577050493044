/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type History, type Location, type Match } from 'react-router';
import styled from 'styled-components';
import omit from 'lodash/omit';
import qs from 'qs';

import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
  stringParamToString,
} from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import withQueryParams from 'utils/routing/withQueryParams';

import Empty from 'images/empty.svg';
import EmptyView from 'components/budget/EmptyView';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import AddExpenseForm from './AddExpenseForm';
import BudgetStats from './BudgetStats';
import ExpensesTable from './ExpensesTable';
import PaymentsTable from './PaymentsTable';
import TableHeader from './TableHeader';

import { type BudgetPageQueryResponse } from './__generated__/BudgetPageQuery.graphql';

const TableWrapper = styled.div`
  padding: 25px 25px 50px;
`;

const query = graphql`
  query BudgetPageQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...BudgetStats_event
      ...TableHeader_event
      ...AddExpenseForm_event

      expenses {
        totalCount
      }

      payments {
        totalCount
      }

      totalBudgetedAmount
      totalActualAmount
      totalPaidAmount

      budgetCategories {
        edges {
          node {
            name
          }
        }
      }
    }

    org {
      ...BudgetStats_org
      ...AddExpenseForm_org
    }

    me {
      ...TableHeader_me
    }
  }
`;

class BudgetPage extends React.Component<
  {
    match: Match,
    history: History,
    location: Location,
    queryParams: { view?: string, chart?: string },
  },
  {
    tableDataCount: ?number,
    showExpenseForm: boolean,
  },
> {
  state = {
    tableDataCount: null,
    showExpenseForm: false,
  };

  handleChangeView = (view: 'payment' | 'expense') => {
    this.props.history.replace({
      search: qs.stringify(omit({ ...this.props.queryParams, view }, ['sort', 'search'])),
    });

    this.setState({ tableDataCount: null });
  };

  handleChangeChart = (chart: 'total' | 'category') => {
    this.props.history.replace({
      search: qs.stringify(omit({ ...this.props.queryParams, chart }, 'sort')),
    });
  };

  handleExpenseFormShow = () => {
    this.setState({ showExpenseForm: true });
  };

  handleExpenseFormClose = () => {
    this.setState({ showExpenseForm: false });
  };

  handleChangeSearch = (search: string) => {
    replaceQueryParams(this.props.history, { search });
  };

  handleChangeSort = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  handleChangeTableDataCount = (tableDataCount: number) => {
    this.setState(state => (state.tableDataCount === tableDataCount ? null : { tableDataCount }));
  };

  renderSuccess = (response: BudgetPageQueryResponse) => {
    const { match, location, queryParams } = this.props;
    const { showExpenseForm, tableDataCount } = this.state;
    const { event, org, me } = response;
    const view = queryParams.view === 'payment' ? 'payment' : 'expense';
    const chart = queryParams.chart === 'category' ? 'category' : 'total';
    const filters = parseTypedQueryString(location.search, {
      search: stringParamToString,
      sort: stringParamToSort,
      chart: stringParamToSort,
    });

    const TableComponent = { expense: ExpensesTable, payment: PaymentsTable }[view];
    const dataTotalCount =
      (event && (view === 'expense' ? event.expenses.totalCount : event.payments.totalCount)) || 0;
    const dataCount = tableDataCount == null ? dataTotalCount : tableDataCount;
    return (
      <React.Fragment>
        <BudgetStats event={event} org={org} chart={chart} onChangeChart={this.handleChangeChart} />

        <TableWrapper>
          <TableHeader
            event={event}
            me={me}
            view={view}
            onChangeView={this.handleChangeView}
            search={filters.search}
            sort={filters.sort}
            onChangeSearch={this.handleChangeSearch}
            onAddExpenseClick={this.handleExpenseFormShow}
            tableDataExists={dataCount > 0}
            filtered={!!filters.search}
            expensesCount={tableDataCount}
          />
          {showExpenseForm && (
            <AddExpenseForm
              org={org}
              event={event}
              onCloseButtonClick={this.handleExpenseFormClose}
            />
          )}
          {dataTotalCount > 0 ? (
            <TableComponent
              eventSlug={match.params.event_slug}
              search={filters.search || ''}
              sort={filters.sort || undefined}
              onChangeSort={this.handleChangeSort}
              onChangeDataCount={this.handleChangeTableDataCount}
            />
          ) : (
            <EmptyView message="Add the first expense to this event..." icon={<Empty />} />
          )}
        </TableWrapper>
      </React.Fragment>
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ eventSlug: this.props.match.params.event_slug }}
        renderSuccess={this.renderSuccess}
      />
    );
  }
}

export default withQueryParams(BudgetPage);
