/* @flow */
import type { FieldType } from 'utils/customization/types';

import type { OptionGroup } from 'components/material/SelectField';

export default function getOpportunityFields(
  customFields: $ReadOnlyArray<FieldType>,
  roleOptions?: $ReadOnlyArray<OptionGroup>,
  statusOptions?: $ReadOnlyArray<OptionGroup>,
): $ReadOnlyArray<FieldType> {
  return [
    {
      id: 'salesforce_id',
      label: 'Opportunity ID',
      fieldName: 'salesforce_id',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'name',
      label: 'Opportunity Name',
      fieldName: 'name',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'associated_contacts',
      label: 'Associated Contacts',
      fieldName: 'associated_contacts',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      unsortable: true,
    },
    {
      fieldName: 'associated_contacts_roles',
      id: 'associated_contacts_roles',
      kind: 'DEFAULT',
      label: 'Associated Contacts Roles',
      options: roleOptions || [],
      order: 0,
      required: false,
    },
    {
      id: 'amount',
      label: 'Value',
      fieldName: 'amount',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'status',
      label: 'Status',
      fieldName: 'status',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: statusOptions || [],
    },
    {
      id: 'description',
      label: 'Description',
      fieldName: 'description',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'company_id',
      label: 'Company',
      fieldName: 'company_id',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'owner_id',
      label: 'Owner',
      fieldName: 'owner_id',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    ...customFields,
  ];
}
