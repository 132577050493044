/* @flow */
import styled from 'styled-components';

import RichText, { EditorContainer } from 'components/RichText';
import { ImagePreview } from 'components/RichText/Image';

const BriefRichText = styled(RichText)`
  margin-top: 7px;
  ${EditorContainer} {
    margin-left: 0;
    padding: 0;
    font-size: 14px;
    color: ${props => props.theme.darkestTextColor};
    box-shadow: none;
    &:hover {
      background: none;
    }
    ${ImagePreview} {
      width: 24px;
      height: 24px;
      background: rgba(218, 213, 213, 0.8);
      border-radius: 2px;
      cursor: pointer;
    }
  }
`;

export default BriefRichText;
