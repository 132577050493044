/* @flow */
import React from 'react';

import COUNTRIES from 'config/countries.json';
import US_STATES from 'config/us_states.json';

import enforceHttpPrefix from 'utils/enforceHttpPrefix';

import type { QuestionType } from 'components/ContactForm/lib/types';
import CheckBox from 'components/SharedForm/CheckBox';
import LinkField from 'components/SharedForm/LinkField';
import SelectField from 'components/SharedForm/SelectField';
import TextField from 'components/SharedForm/TextField';
import UserSelectField from 'components/SharedForm/UserSelectField';
import { type ContactDefaultFields } from 'views/Main/AllContacts/Contact/ContactProfile/ContactFormFields';

export type ExtendedContactDefaultFields = {|
  ...ContactDefaultFields,
  contactTypeOther: ?string,
|};

type Props = {
  contact: ContactDefaultFields,
  viewerCanUpdate: boolean,
  handleUpdate: (changes: $Shape<ExtendedContactDefaultFields>) => void,
  question: QuestionType,
  errors: { [string]: string },
  // eslint-disable-next-line react/no-unused-prop-types
  dragStartId?: string,
};

const countries = COUNTRIES.map(country => ({ label: country, value: country }));
const usStates = Object.keys(US_STATES).map(k => ({
  label: US_STATES[k],
  value: US_STATES[k],
}));

export const defaultSelectFields = [
  'contact_type_id',
  'registration_status_id',
  'attendance_status_id',
];

const suggestedFields = {
  title: ({ contact, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={contact.title}
      onBlur={e => {
        handleUpdate({ title: e.currentTarget.value });
      }}
      label={question.label}
      name={question.fieldName || ''}
      required={question.required}
      error={errors.title}
      disabled={!viewerCanUpdate}
    />
  ),
  owner_id: ({ contact, handleUpdate, question, viewerCanUpdate, errors, dragStartId }: Props) => {
    return (
      <UserSelectField
        kind="SELECT"
        skipUserIds={question.contactFormFieldOptions || []}
        label={question.label}
        selectedUsers={contact.owner ? [contact.owner] : []}
        values={contact.owner ? [contact.owner.id] : []}
        onChange={owner => {
          if (
            (owner && !contact.owner) ||
            (!owner && contact.owner) ||
            (owner && contact.owner && contact.owner.id !== owner.id)
          ) {
            handleUpdate({ owner });
          }
        }}
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors.owner_id}
        clearable
        dragStart={dragStartId === question.id}
      />
    );
  },
  contact_type_id: ({
    contact,
    handleUpdate,
    question,
    viewerCanUpdate,
    errors,
    dragStartId,
  }: Props) => {
    return (
      <SelectField
        question={question}
        label={question.label}
        values={contact.contactTypeId ? [contact.contactTypeId] : []}
        onChange={contactTypeId => {
          if (contact.contactTypeId !== contactTypeId) {
            handleUpdate({ contactTypeId, contactTypeOther: null });
          }
        }}
        onChangeOtherInput={value => {
          handleUpdate({ contactTypeId: 'other', contactTypeOther: value });
        }}
        options={
          question.customField
            ? question.customField.options.slice(0, dragStartId === question.id ? 10 : undefined)
            : []
        }
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors.contact_type_id}
      />
    );
  },
  street: ({ contact, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={contact.street}
      onBlur={e => {
        handleUpdate({ street: e.currentTarget.value });
      }}
      label={question.label}
      name={question.fieldName || ''}
      required={question.required}
      error={errors.street}
      disabled={!viewerCanUpdate}
    />
  ),
  city: ({ contact, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={contact.city}
      onBlur={e => {
        handleUpdate({ city: e.currentTarget.value });
      }}
      label={question.label}
      name={question.fieldName || ''}
      required={question.required}
      error={errors.city}
      disabled={!viewerCanUpdate}
    />
  ),
  description: ({ contact, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      multiline
      defaultValue={contact.description}
      onBlur={e => {
        handleUpdate({ description: e.currentTarget.value });
      }}
      label={question.label}
      name={question.fieldName || ''}
      required={question.required}
      error={errors.description}
      disabled={!viewerCanUpdate}
    />
  ),
  zip: ({ contact, handleUpdate, question, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={contact.zip}
      onBlur={e => {
        handleUpdate({ zip: e.currentTarget.value });
      }}
      label={question.label}
      name={question.fieldName || ''}
      required={question.required}
      error={errors.zip}
      disabled={!viewerCanUpdate}
    />
  ),
  hot_lead: ({ contact, handleUpdate, question, viewerCanUpdate, errors }: Props) => (
    <CheckBox
      compact
      checked={!!contact.hotLead}
      label={question.label}
      onChange={() => handleUpdate({ hotLead: !contact.hotLead })}
      required={question.required}
      disabled={!viewerCanUpdate}
      error={errors.hot_lead}
    />
  ),
  email_opt_in: ({ contact, handleUpdate, question, viewerCanUpdate, errors }: Props) => (
    <CheckBox
      compact
      checked={!!contact.emailOptIn}
      label={question.label}
      onChange={() => handleUpdate({ emailOptIn: !contact.emailOptIn })}
      disabled={!viewerCanUpdate}
      error={errors.email_opt_in}
      required={question.required}
    />
  ),
  website: ({ contact, handleUpdate, question, viewerCanUpdate, errors }: Props) => (
    <LinkField
      defaultValue={contact.website}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== contact.website) {
          e.currentTarget.value = value;
          handleUpdate({ website: value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.website}
    />
  ),
  twitter: ({ contact, handleUpdate, viewerCanUpdate, question, errors }: Props) => (
    <LinkField
      defaultValue={contact.twitter}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== contact.twitter) {
          e.currentTarget.value = value;
          handleUpdate({ twitter: value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.twitter}
    />
  ),
  linkedin: ({ contact, handleUpdate, viewerCanUpdate, question, errors }: Props) => (
    <LinkField
      defaultValue={contact.linkedin}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== contact.linkedin) {
          e.currentTarget.value = value;
          handleUpdate({ linkedin: value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.linkedin}
    />
  ),
  phone1: ({ contact, handleUpdate, viewerCanUpdate, question, errors }: Props) => (
    <LinkField
      defaultValue={contact.phone1}
      onBlur={e => {
        const value = e.currentTarget.value.trim();
        if (contact.phone1 !== value) {
          handleUpdate({ phone1: value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.phone1}
      to={`tel:${contact.phone1 || ''}`}
    />
  ),
  phone2: ({ contact, handleUpdate, viewerCanUpdate, question, errors }: Props) => (
    <LinkField
      defaultValue={contact.phone2}
      onBlur={e => {
        const value = e.currentTarget.value.trim();
        if (contact.phone2 !== value) {
          handleUpdate({ phone2: value });
        }
      }}
      label={question.label}
      name={question.fieldName || ''}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.phone2}
      to={`tel:${contact.phone2 || ''}`}
    />
  ),
  state: ({ contact, handleUpdate, viewerCanUpdate, question, errors, dragStartId }: Props) =>
    contact.country === 'United States' ? (
      <SelectField
        question={question}
        label={question.label}
        values={contact.state ? [contact.state] : []}
        onChange={state => {
          if (contact.state !== state) {
            handleUpdate({ state });
          }
        }}
        options={usStates.slice(0, dragStartId === question.id ? 10 : undefined)}
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors.state}
      />
    ) : (
      <TextField
        defaultValue={contact.state}
        onBlur={e => {
          if (contact.state !== e.currentTarget.value) {
            handleUpdate({ state: e.currentTarget.value });
          }
        }}
        label={question.label}
        name={question.fieldName || ''}
        required={question.required}
        error={errors.state}
        disabled={!viewerCanUpdate}
      />
    ),
  country: ({ contact, handleUpdate, viewerCanUpdate, question, errors, dragStartId }: Props) => (
    <SelectField
      question={question}
      label={question.label}
      values={contact.country ? [contact.country] : []}
      onChange={country => {
        if (country !== contact.country) {
          handleUpdate({ country });
        }
      }}
      options={[
        countries.find(country => country.value === 'United States'),
        ...countries.filter(country => country.value !== 'United States'),
      ].slice(0, dragStartId === question.id ? 10 : undefined)}
      disabled={!viewerCanUpdate}
      required={question.required}
      error={errors.country}
    />
  ),
};

export default suggestedFields;
