/**
 * @flow
 * @relayHash d494e53704c13252f75b80bdea6f12fd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventsSearchPagination_searchResults$ref = any;
export type Direction = "ASC" | "DESC";
export type EventFormat = "HYBRID" | "PHYSICAL" | "VIRTUAL";
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventRegistrationFormStatus = "DRAFT" | "NOT_AVAILABLE" | "PUBLISHED";
export type EventRequestSubmissionStatusFilter = "APPROVED" | "DECLINED" | "NEW" | "N_A" | "RESUBMITTED" | "RETURNED";
export type EventSort = "ATTENDED_CONTACTS_TOTAL" | "BOOTH" | "BOOTH_DIMENSIONS" | "CITY" | "COMMITMENT_LEVEL" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATOR" | "CUSTOM" | "END_DATE" | "EVENT_FORMAT" | "EVENT_LEAD" | "LAST_SYNCED" | "LOCATION" | "MARKETO_ID" | "NAME" | "OPPORTUNITIES_AMOUNT" | "OPPORTUNITIES_NUMBER" | "PROGRESS" | "REGISTERED_CONTACTS_TOTAL" | "REQUESTED_BY" | "REQUESTED_DATE" | "REQUESTED_FORM_NAME" | "REQUESTED_STATUS" | "SALESFORCE_ID" | "STAFF_COUNT" | "START_DATE" | "STATE" | "STREET" | "SYNC_STATUS" | "TEAM_NAME" | "TEMPLATES_FIRST" | "TOTAL_ACTUAL_COST" | "TOTAL_PAID_COST" | "TOTAL_PLANNED_COST" | "UPDATED_AT" | "UPDATER" | "VENUE_NAME" | "VIRTUAL_LOCATION" | "WEBSITE" | "ZIP_CODE";
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type EventFilters = {
  sort?: ?EventSort,
  direction?: ?Direction,
  customFieldSortId?: ?string,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  queries?: ?$ReadOnlyArray<string>,
  search?: ?Search,
  listType?: ?EventListEnum,
  afterDate?: ?any,
  beforeDate?: ?any,
  includeUndated?: ?boolean,
  excludeTemplates?: ?boolean,
  statuses?: ?$ReadOnlyArray<EventStatus>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  opportunitiesNumber?: ?NumberRangeInput,
  opportunitiesAmount?: ?NumberRangeInput,
  teamIds?: ?$ReadOnlyArray<string>,
  leadIds?: ?$ReadOnlyArray<string>,
  staffIds?: ?$ReadOnlyArray<string>,
  budgetCategoryIds?: ?$ReadOnlyArray<string>,
  fullAccessEvents?: ?boolean,
  onlyWithTasks?: ?boolean,
  venueNames?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  eventFormats?: ?$ReadOnlyArray<EventFormat>,
  eventIds?: ?$ReadOnlyArray<string>,
  excludeEventIds?: ?$ReadOnlyArray<string>,
  updatedAt?: ?DateTimeRange,
  updaterIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  creatorIds?: ?$ReadOnlyArray<string>,
  requesterUserIds?: ?$ReadOnlyArray<string>,
  requesterContactIds?: ?$ReadOnlyArray<string>,
  requestedDate?: ?DateTimeRange,
  requestStatuses?: ?$ReadOnlyArray<EventRequestSubmissionStatusFilter>,
  requestFormIds?: ?$ReadOnlyArray<string>,
  requestReviewers?: ?$ReadOnlyArray<string>,
  registeredContactsTotal?: ?NumberRangeInput,
  attendedContactsTotal?: ?NumberRangeInput,
  registrationFormStatuses?: ?$ReadOnlyArray<EventRegistrationFormStatus>,
  contactsCount?: ?NumberRangeInput,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type EventsSearchPaginationQueryVariables = {|
  search: Search,
  eventFilters: EventFilters,
  count: number,
  cursor?: ?string,
|};
export type EventsSearchPaginationQueryResponse = {|
  +$fragmentRefs: EventsSearchPagination_searchResults$ref
|};
export type EventsSearchPaginationQuery = {|
  variables: EventsSearchPaginationQueryVariables,
  response: EventsSearchPaginationQueryResponse,
|};
*/


/*
query EventsSearchPaginationQuery(
  $search: Search!
  $eventFilters: EventFilters!
  $count: Int!
  $cursor: String
) {
  ...EventsSearchPagination_searchResults
}

fragment EventsSearchPagination_searchResults on Query {
  events: searchEvents(search: $search, filters: $eventFilters, after: $cursor, first: $count) {
    edges {
      node {
        id
        ...EventSearchResult_event
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment EventSearchResult_event on EventSearchResult {
  id
  name
  slug
  startDate
  startDateAllDay
  endDate
  endDateAllDay
  tz
  location
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "Search!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "eventFilters",
    "type": "EventFilters!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "eventFilters",
    "type": "EventFilters!"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search",
    "type": "Search!"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventsSearchPaginationQuery",
  "id": null,
  "text": "query EventsSearchPaginationQuery(\n  $search: Search!\n  $eventFilters: EventFilters!\n  $count: Int!\n  $cursor: String\n) {\n  ...EventsSearchPagination_searchResults\n}\n\nfragment EventsSearchPagination_searchResults on Query {\n  events: searchEvents(search: $search, filters: $eventFilters, after: $cursor, first: $count) {\n    edges {\n      node {\n        id\n        ...EventSearchResult_event\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EventSearchResult_event on EventSearchResult {\n  id\n  name\n  slug\n  startDate\n  startDateAllDay\n  endDate\n  endDateAllDay\n  tz\n  location\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventsSearchPaginationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventsSearchPagination_searchResults",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventsSearchPaginationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "events",
        "name": "searchEvents",
        "storageKey": null,
        "args": v1,
        "concreteType": "EventSearchResultRequiredConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "EventSearchResultRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "EventSearchResult",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "slug",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startDateAllDay",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endDateAllDay",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "tz",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "location",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": "events",
        "name": "searchEvents",
        "args": v1,
        "handle": "connection",
        "key": "EventsSearchPagination_events",
        "filters": []
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1bc127687a31b2dd5a2864c1384348e9';
module.exports = node;
