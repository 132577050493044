/* @flow */
import capitalize from 'lodash/capitalize';

export type SubscriptionPlanGraphValue =
  | 'TRIAL'
  | 'BASIC'
  | 'PREMIUM'
  | 'ENTERPRISE'
  | 'INTERNAL';

type SubscriptionPlan = {|
  +label: string, // Label in dropdowns
  +graphValue: SubscriptionPlanGraphValue, // value for GraphQL API
|};

const graphValues: $ReadOnlyArray<SubscriptionPlanGraphValue> = [
  'TRIAL',
  'BASIC',
  'PREMIUM',
  'ENTERPRISE',
  'INTERNAL',
];

const subscriptionPlans: $ReadOnlyArray<SubscriptionPlan> = graphValues.map(value => ({
  label: capitalize(value),
  graphValue: value,
}));

export function getSubscriptionPlanLabel(graphValue: SubscriptionPlanGraphValue): ?string {
  const type = subscriptionPlans.find(status => status.graphValue === graphValue);
  return type != null ? type.label : null;
}

export default subscriptionPlans;
