/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import camelCase from 'lodash/camelCase';
import omit from 'lodash/omit';

import { type SalesforceSyncTypes } from 'config/salesforceSyncOptions';

import convertCustomFieldValuesToInput from 'utils/customization/convertCustomFieldValuesToInput';
import getCustomFieldPlainValue from 'utils/customization/getCustomFieldPlainValue';
import getOptionKeysByKind from 'utils/customization/getOptionKeysByKind';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import { type CustomFieldsType, type FieldType } from 'utils/customization/types';
import isValidEmail from 'utils/validators/isValidEmail';
import isValidWebsite from 'utils/validators/isValidWebsite';

import { type CreateCompanyFromWindow } from 'graph/mutations/company/createCompany';
import createContact, {
  type CreateContactFromWindow,
  type ResponseContact,
} from 'graph/mutations/contact/createContact';
import { type UpdateCustomFieldValueInput } from 'graph/mutations/custom_field/updateCustomFieldValue';
import showModernMutationError from 'graph/utils/showModernMutationError';

import customFields, { type InexactUser } from 'components/Customizable/customFields';
import { type User } from 'components/InviteWindow';
import TextField from 'components/material/TextField';
import { type ContactDefaultFields } from 'views/Main/AllContacts/Contact/ContactProfile/ContactFormFields';
import suggestedFields from 'views/Main/AllContacts/Contact/ContactProfile/suggestedFields';

import ContactSyncMessage from '../ContactSyncMessage';
import { type ContactType } from './ContactTypeSelector';
import ContactWindowFooter from './ContactWindowFooter';

import { type ContactForm_org } from './__generated__/ContactForm_org.graphql';

const Row = styled.div`
  display: flex;
  margin: 20px 0;
`;

const Column = styled.div`
  flex: 1 1 auto;

  &:not(:first-child) {
    margin-left: 30px;
  }
`;

const StyledContactSyncMessage = styled(ContactSyncMessage)`
  margin: 20px 0 30px;
`;

const EmailWarning = styled.div`
  margin: -24px 0 -15px;
  font-size: 12px;
  font-style: italic;
  color: #eb3ca0;

  a {
    cursor: pointer;
    font-style: initial;
    text-decoration: underline;
    color: #3aa8da;

    &:hover {
      color: #009dce;
    }
  }
`;

export type EventPersonDataType = {| registrationStatusId: ?string, attendanceStatusId: ?string |};

class ContactForm<FilterVariables: {}> extends React.PureComponent<
  {
    org: ContactForm_org,
    tz: string,
    fromWindow: ?CreateContactFromWindow | CreateCompanyFromWindow,
    eventId: ?string,
    defaultActiveType?: ContactType,
    filterVariables: ?FilterVariables,
    onHide: () => void,
    onSave: ?('contacts', ResponseContact, EventPersonDataType) => void,
    onShowInviteWindow: (user: $Exact<$ReadOnly<{ ...User }>>) => void,
    setOnContactFormEdited: (onHide: () => boolean) => void,
  },
  {
    defaultFieldValues: {| ...ContactDefaultFields, salesforceSyncAs?: ?SalesforceSyncTypes |},
    customFieldValues: CustomFieldsType,
    errors: { [string]: string },
    loading: boolean,
    formEdited: boolean,
  },
> {
  state = {
    defaultFieldValues: {
      firstName: '',
      lastName: '',
      email: '',
      company: null,
      title: null,
      phone1: null,
      phone2: null,
      website: null,
      twitter: null,
      linkedin: null,
      avatar: null,
      description: null,
      country: 'United States',
      state: null,
      city: null,
      zip: null,
      street: null,
      hotLead: false,
      emailOptIn: false,
      contactTypeId: null,
      owner: null,
      attendanceStatusId: null,
      registrationStatusId: null,
    },
    customFieldValues: {
      customTextFields: [],
      customTextareaFields: [],
      customLinkFields: [],
      customDateFields: [],
      customNumberFields: [],
      customCurrencyFields: [],
      customBooleanFields: [],
      customUserSelectFields: [],
      customUserMultiselectFields: [],
      customSelectFields: [],
      customMultiselectFields: [],
    },
    errors: {},
    loading: false,
    formEdited: false,
  };

  componentDidMount() {
    this.props.setOnContactFormEdited(this.checkContactFormEdited);
  }

  checkContactFormEdited = (): boolean => {
    return this.state.formEdited;
  };

  handleChangeFirstName = (event: SyntheticEvent<HTMLInputElement>) => {
    const firstName = event.currentTarget.value;

    this.setState(prevState => ({
      defaultFieldValues: { ...prevState.defaultFieldValues, firstName },
      formEdited: true,
    }));

    this.validateDefaultField('firstName', firstName);
  };

  handleChangeLastName = (event: SyntheticEvent<HTMLInputElement>) => {
    const lastName = event.currentTarget.value;

    this.setState(prevState => ({
      defaultFieldValues: { ...prevState.defaultFieldValues, lastName },
      formEdited: true,
    }));

    this.validateDefaultField('lastName', lastName);
  };

  handleChangeEmail = (event: SyntheticEvent<HTMLInputElement>) => {
    const email = event.currentTarget.value;

    this.setState(prevState => ({
      defaultFieldValues: { ...prevState.defaultFieldValues, email },
      formEdited: true,
    }));

    this.validateDefaultField('email', email);
  };

  handleUpdateCustomField = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    const { arrayKey, optionKey } = getOptionKeysByKind(field.kind);
    const value = Object.values(input)[0];
    const valuesArray = (Array.isArray(value)
      ? value
      : [value].filter(val => val != null)
    ).map(optionId => (users == null ? optionId : users.find(user => user.id === optionId)));

    this.setState(prevState => ({
      customFieldValues: {
        ...prevState.customFieldValues,
        [arrayKey]: [
          ...prevState.customFieldValues[arrayKey].filter(
            option => option.customField.id !== field.id,
          ),
          ...valuesArray.map(option => ({
            customField: { id: field.id },
            [optionKey]: optionKey === 'option' ? { id: option } : option,
          })),
        ],
      },
      formEdited: true,
    }));

    this.validateField(field, Object.values(input)[0]);
  };

  handleUpdateSuggestedField = (field: FieldType, changes: ContactDefaultFields) => {
    this.setState(prevState => ({
      defaultFieldValues: { ...prevState.defaultFieldValues, ...changes },
      formEdited: true,
    }));

    this.validateField(field, Object.values(changes)[0]);
  };

  validateDefaultField = (name: 'firstName' | 'lastName' | 'email', value: ?string) =>
    new Promise(resolve =>
      this.setState(prevState => {
        if (value == null || !value.trim()) {
          return { errors: { ...prevState.errors, [name]: 'Required' } };
        }

        if (name === 'email' && value != null && value.trim() && !isValidEmail(value)) {
          return { errors: { ...prevState.errors, email: 'Invalid email' } };
        }

        return {
          errors: omit(prevState.errors, name),
        };
      }, resolve),
    );

  validateField = (field: FieldType, value: mixed) =>
    new Promise(resolve =>
      this.setState(prevState => {
        const key = field.fieldName || field.id;

        if (
          field.required &&
          (value == null ||
            value === false ||
            (typeof value === 'string' && !value.trim()) ||
            (Array.isArray(value) && value.length === 0))
        ) {
          return { errors: { ...prevState.errors, [key]: 'Required' } };
        }

        if (
          (field.kind === 'LINK' || ['website', 'twitter', 'linkedin'].includes(key)) &&
          value != null &&
          value !== '' &&
          !isValidWebsite(String(value))
        ) {
          return { errors: { ...prevState.errors, [key]: 'Invalid url' } };
        }

        return { errors: omit(prevState.errors, key) };
      }, resolve),
    );

  validateForm = () => {
    const { customFieldValues } = this.state;

    return Promise.all([
      this.validateDefaultField('firstName', this.state.defaultFieldValues.firstName),
      this.validateDefaultField('lastName', this.state.defaultFieldValues.lastName),
      this.validateDefaultField('email', this.state.defaultFieldValues.email),
      ...this.normalizedCustomFields().map(field =>
        this.validateField(
          field,
          field.fieldName
            ? this.getDefaultFieldValue(field.fieldName)
            : getCustomFieldPlainValue(field, customFieldValues),
        ),
      ),
    ]);
  };

  getDefaultFieldValue = (fieldName: string) => {
    const { defaultFieldValues } = this.state;

    if (['contact_type_id', 'registration_status_id', 'attendance_status_id'].includes(fieldName)) {
      return defaultFieldValues[camelCase(fieldName)];
    }

    return defaultFieldValues[camelCase(fieldName.replace(/_id$/, ''))];
  };

  handleSubmit = () => {
    const {
      org,
      fromWindow,
      eventId,
      filterVariables,
      onHide,
      onSave,
      defaultActiveType,
    } = this.props;
    const { defaultFieldValues, customFieldValues } = this.state;
    const { owner, ...restOfDefaultFieldValues } = defaultFieldValues;

    this.validateForm().then(() => {
      if (Object.keys(this.state.errors).length > 0) {
        const windows = document.getElementsByClassName('popupWindow');
        const popup = windows[windows.length - 1];
        if (popup) {
          popup.scrollTop = 0;
        }
        return;
      }

      this.setState({ loading: true });

      createContact(
        org.id,
        {
          eventIds: eventId ? [eventId] : null,
          fromWindow,
          ownerId: owner ? owner.id : null,
          ...restOfDefaultFieldValues,
          customFieldValues: convertCustomFieldValuesToInput(customFieldValues),
        },
        defaultActiveType === 'contacts' ? filterVariables : null,
      )
        .then((responseContact: ResponseContact) => {
          if (onSave) {
            onSave('contacts', responseContact, {
              registrationStatusId: restOfDefaultFieldValues.registrationStatusId,
              attendanceStatusId: restOfDefaultFieldValues.attendanceStatusId,
            });
          }

          onHide();
        })
        .catch(error => {
          if (error.message.includes('Email has already been taken')) {
            this.setState(prevState => ({
              errors: {
                ...prevState.errors,
                email: 'Email address already taken',
              },
            }));
          } else {
            showModernMutationError(error);
          }
          this.setState({ loading: false });
        });
    });
  };

  normalizedCustomFields = () =>
    normalizeCustomFields(this.props.org.customFields.edges.map(edge => edge.node));

  handleShowInviteWindow = () => {
    const { defaultFieldValues } = this.state;

    this.props.onShowInviteWindow({
      firstName: defaultFieldValues.firstName || '',
      lastName: defaultFieldValues.lastName || '',
      email: defaultFieldValues.email || '',
    });
  };

  render() {
    const { org, tz, onHide, fromWindow } = this.props;
    const { defaultFieldValues, customFieldValues, errors, loading } = this.state;
    const salesforceSyncEnabled = !!(
      org.salesforceAccount && org.salesforceAccount.contactsSyncEnabled
    );
    const marketoSyncEnabled = !!org.marketoAccount;
    const emailDomain = (defaultFieldValues.email || '').split('@').slice(-1)[0].toLowerCase();
    const emailHasOrgDomain = org.settings.domain && emailDomain.includes(org.settings.domain);

    return (
      <div>
        <StyledContactSyncMessage
          contact={defaultFieldValues}
          salesforceSyncEnabled={salesforceSyncEnabled}
          marketoSyncEnabled={marketoSyncEnabled}
        />
        <Row>
          <Column>
            <TextField
              required
              name="firstName"
              label="First Name"
              autoFocus
              value={defaultFieldValues.firstName || ''}
              onChange={this.handleChangeFirstName}
              error={errors.firstName}
            />
          </Column>

          <Column>
            <TextField
              required
              name="lastName"
              label="Last Name"
              value={defaultFieldValues.lastName || ''}
              onChange={this.handleChangeLastName}
              error={errors.lastName}
            />
          </Column>
        </Row>

        <Row>
          <TextField
            required
            type="email"
            name="email"
            label="Email"
            value={defaultFieldValues.email || ''}
            onChange={this.handleChangeEmail}
            error={errors.email}
          />
        </Row>

        {!errors.email && emailHasOrgDomain && (
          <EmailWarning>
            Seems like you’re trying to add contact with Org domain.{' '}
            <a onClick={this.handleShowInviteWindow} tabIndex={0} role="button">
              Add as member
            </a>
          </EmailWarning>
        )}

        {this.normalizedCustomFields().map(field => {
          if (field.kind === 'DEFAULT') {
            const renderer = field.fieldName && suggestedFields[field.fieldName];

            if (!renderer) return null;

            return (
              <Row key={field.id}>
                {renderer({
                  contact: defaultFieldValues,
                  field,
                  errors,
                  viewerCanUpdate: true,
                  fromWindow,
                  handleUpdate: changes => this.handleUpdateSuggestedField(field, changes),
                })}
              </Row>
            );
          }

          return (
            <Row key={field.id}>
              {customFields[field.kind]({
                customizable: customFieldValues,
                field,
                errors,
                viewerCanUpdateValue: true,
                tz,
                currency: org.settings.currency,
                handleUpdate: (input, users) => this.handleUpdateCustomField(field, input, users),
              })}
            </Row>
          );
        })}

        <ContactWindowFooter
          showManageLink={org.viewerCanUpdate}
          contactType="contacts"
          loading={loading}
          onCancel={onHide}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(
  ContactForm,
  graphql`
    fragment ContactForm_org on Org {
      id
      viewerCanUpdate

      salesforceAccount {
        contactsSyncEnabled
      }

      marketoAccount {
        id
      }

      settings {
        currency
        domain
      }

      customFields(
        customizableType: $customizableType
        onlyCreateForm: true
        excludeSalesforcePullOnlyFields: $excludeSalesforcePullOnlyFields
      ) {
        edges {
          node {
            id
            kind
            label
            order
            required
            fieldName
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
);
