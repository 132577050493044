/* @flow */
import downloadFile from '../downloadFile';

export default function downloadCSV(url: string, filename: string): Promise<void> {
  return fetch(url).then(response => {
    if (response.status >= 400) {
      return response.json().then(json => Promise.reject(new Error(json.errors)));
    }
    return response.text().then(contents => {
      // A hack to force Excel to read the file in UTF-8 encoding
      const BOM = '\uFEFF';
      downloadFile(`${BOM}${contents}`, filename, 'text/csv');
    });
  });
}
