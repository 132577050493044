/* @flow */
import React from 'react';

import { type StaffAccessLevel } from 'config/staffAccessLevels';

import SelectField from 'components/material/SelectField';

export type AccessType = 'ADMIN' | 'TEAM' | StaffAccessLevel;

export type AccessScope = 'ORG' | 'TEAM' | 'EVENT';

export default class AccessTypeSelector extends React.Component<{
  value: AccessType,
  onChange: (value: ?AccessType) => void,
  scope?: AccessScope,
  skipStaffAccessLevel?: StaffAccessLevel,
}> {
  static defaultProps = {
    scope: 'ORG',
  };

  getOptions(): typeof AccessTypeSelector.options {
    switch (this.props.scope) {
      case 'TEAM':
        return AccessTypeSelector.options.slice(1);
      case 'EVENT':
        return AccessTypeSelector.options.slice(2);
      default:
        return AccessTypeSelector.options;
    }
  }

  getFilteredOptions(): typeof AccessTypeSelector.options {
    const options = this.getOptions();
    return this.props.skipStaffAccessLevel == null
      ? options
      : options.filter(option => option.value !== this.props.skipStaffAccessLevel);
  }

  static options = [
    {
      label: 'Workspace Admin',
      info: 'Full access to all Teams and Events in the Workspace',
      value: 'ADMIN',
    },
    {
      label: 'Team Member (Full access)',
      info: 'Full access to all Events in their Team',
      value: 'TEAM',
    },
    {
      label: 'Event Staff (Full access)',
      info: 'Full access to specific Event',
      value: 'FULL',
    },
    {
      label: 'Event Staff (Limited access)',
      info: 'Limited access to specific Event',
      value: 'LIMITED',
    },
    {
      label: 'Event Staff (No access)',
      info: 'No access or email',
      value: 'NONE',
    },
  ];

  render() {
    return (
      <SelectField
        label="Role"
        options={this.getFilteredOptions()}
        onChange={this.props.onChange}
        value={this.props.value}
      />
    );
  }
}
