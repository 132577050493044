/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import updateEventContact from 'graph/mutations/event_contact/updateEventContact';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import type { EventAttendanceStatus_contact } from './__generated__/EventAttendanceStatus_contact.graphql';
import type { EventAttendanceStatus_contactEvent } from './__generated__/EventAttendanceStatus_contactEvent.graphql';

class EventAttendanceStatus extends React.Component<{
  contactEvent: EventAttendanceStatus_contactEvent,
  contact: EventAttendanceStatus_contact,
  updateColumnWidth: () => void,
  fieldSettings?: FieldType,
}> {
  updateAttendanceStatus = attendanceStatusId => {
    updateEventContact({
      eventId: this.props.contactEvent.node.id,
      contactId: this.props.contact.id,
      attendanceStatusId,
    })
      .then(() => {
        this.props.updateColumnWidth();
      })
      .catch(showModernMutationError);
  };

  render() {
    const { fieldSettings, contactEvent } = this.props;
    return (
      <InlineSelectField
        options={fieldSettings ? fieldSettings.options : []}
        value={contactEvent.attendanceStatus && contactEvent.attendanceStatus.id}
        placeholder="Select Attendance Status"
        onChange={this.updateAttendanceStatus}
        clearable={!!fieldSettings && !fieldSettings.required}
      />
    );
  }
}

export default createFragmentContainer(EventAttendanceStatus, {
  contactEvent: graphql`
    fragment EventAttendanceStatus_contactEvent on ContactEventEdge {
      attendanceStatus {
        id
        name
      }
      node {
        id
        name
      }
    }
  `,
  contact: graphql`
    fragment EventAttendanceStatus_contact on Contact {
      id
    }
  `,
});
