/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import RichText, { EditorContainer } from 'components/RichText';

const Logo = styled.img`
  max-width: 160px;
  max-height: 80px;
`;

const Title = styled.div`
  margin: 20px 8px 0;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  color: #3e4859;
`;

const StyledRichText = styled(RichText)`
  margin: 10px 8px;
  white-space: pre-line;
  text-align: center;
  ${EditorContainer} {
    margin-left: 0;
    padding: 0;
    font-size: 14px;
    color: #616c79;
    box-shadow: none;
    &:hover {
      background: none;
    }
  }
`;

export default class SubmissionReviewFormHeader extends React.PureComponent<{
  logo: string,
  name: string,
  description: ?string,
}> {
  render() {
    return (
      <React.Fragment>
        <Logo src={this.props.logo} />
        <Title>{this.props.name}</Title>
        <StyledRichText defaultValue={this.props.description} disabled viewMode />
      </React.Fragment>
    );
  }
}
