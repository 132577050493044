/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventName_expense$ref = any;
type VendorExpenseAction_expense$ref = any;
type VendorExpenseAmount_expense$ref = any;
type VendorExpenseCategory_expense$ref = any;
type VendorExpenseName_expense$ref = any;
type VendorExpenseNote_expense$ref = any;
type VendorExpensePaymentStatus_expense$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorExpensesTableRenderer_expenses$ref: FragmentReference;
export type VendorExpensesTableRenderer_expenses = $ReadOnlyArray<{|
  +id: string,
  +$fragmentRefs: VendorExpenseName_expense$ref & VendorExpenseAmount_expense$ref & VendorExpenseNote_expense$ref & VendorExpenseCategory_expense$ref & VendorExpensePaymentStatus_expense$ref & VendorExpenseAction_expense$ref & EventName_expense$ref,
  +$refType: VendorExpensesTableRenderer_expenses$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VendorExpensesTableRenderer_expenses",
  "type": "Expense",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VendorExpenseName_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VendorExpenseAmount_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VendorExpenseNote_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VendorExpenseCategory_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VendorExpensePaymentStatus_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VendorExpenseAction_expense",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventName_expense",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd06123d2bbe7ab0b74e1bf503b3a162e';
module.exports = node;
