/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

const StyledLink = styled.a`
  padding-right: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  color: ${props => props.theme.primaryActionColor};

  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
    text-decoration: underline;
  }
`;

const EditIcon = styled.div`
  margin-left: 5px;
  opacity: 0;
  line-height: 1;
  color: #868f96;
  font-size: 14px;
`;

const Container = styled.div`
  font-size: 14px;

  &:hover {
    ${EditIcon} {
      opacity: 1;
    }
  }
`;

const Label = styled.div`
  padding-right: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;

  ${props =>
    props.empty &&
    css`
      font-weight: normal;
      font-style: italic;
      color: rgba(103, 103, 103, 0.6);
    `};
`;

const StyledIcon = styled.i`
  margin-right: 5px;
  color: #868f96;
  font-size: 15px;
`;

export default class Detail extends React.Component<{
  className?: ?string,
  link?: ?string,
  isLocation?: boolean,
  icon: string | React.Node,
  text: ?string,
  placeholder: string,
  onClick: () => void,
  hideIconOnText?: boolean,
  id?: string,
}> {
  renderIcon = (isEmpty: boolean) => {
    if (this.props.hideIconOnText && !isEmpty) {
      return null;
    }

    if (typeof this.props.icon === 'string') {
      return <StyledIcon className={`fa fa-${this.props.icon}`} />;
    }

    return this.props.icon;
  };

  render() {
    const classes = classNames('event-detail', this.props.className);
    const isEmpty = this.props.text == null || this.props.text.trim() === '';
    const detailText = isEmpty ? this.props.placeholder : this.props.text;

    return (
      <Container onClick={this.props.onClick} className={classes} id={this.props.id}>
        {this.renderIcon(isEmpty)}
        {this.props.link && !isEmpty ? (
          <StyledLink
            title={this.props.text && this.props.text.length > 20 ? this.props.text : ''}
            href={this.props.link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            {this.props.text}
          </StyledLink>
        ) : (
          <Label empty={isEmpty}>{detailText}</Label>
        )}
        {(this.props.link || this.props.isLocation) && !isEmpty && (
          <EditIcon>
            <i className="fa fa-fw fa-pencil" />
          </EditIcon>
        )}
      </Container>
    );
  }
}
