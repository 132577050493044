/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDateTimeRange from 'utils/date/formatDateTimeRange';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import generateFontFromProps from '../generateFontFromProps';
import generatePaddingProps from '../generatePaddingProps';

import type { EventDatesComponent_componentProps } from './__generated__/EventDatesComponent_componentProps.graphql';
import type { EventDatesComponent_registrationForm } from './__generated__/EventDatesComponent_registrationForm.graphql';

const Container = styled.div`
  padding: ${props => generatePaddingProps(props.componentProps.padding)};
  background-color: ${props => props.componentProps.backgroundColor || 'initial'};
  font: ${props => generateFontFromProps(props.componentProps)};
  text-align: ${props => props.componentProps.alignment};
  color: ${props => props.componentProps.color || 'initial'};
`;

class EventDatesComponent extends React.PureComponent<{
  registrationForm: EventDatesComponent_registrationForm,
  componentProps: EventDatesComponent_componentProps,
  selectedComponent: SelectedComponent,
}> {
  getDateConfig = () => {
    const {
      registrationForm: { eventStartDate, eventEndDate, startDateAllDay, endDateAllDay, tz, org },
    } = this.props;
    if (org) {
      const rounding = 60 * 60 * 1000; // To Round up until 1 hour in ms
      const currentTime = moment().tz(org.settings.tz);
      // duplication here, because moment.add mutating variable itself
      const roundedTimeStart = moment(Math.ceil(+currentTime / rounding) * rounding).tz(
        org.settings.tz,
      );
      const roundedTimeEnd = moment(Math.ceil(+currentTime / rounding) * rounding).tz(
        org.settings.tz,
      );
      return {
        startDate: roundedTimeStart.add(1, 'hours'),
        endDate: roundedTimeEnd.add(2, 'hours'),
        startDateAllDay: false,
        endDateAllDay: false,
      };
    }
    return eventStartDate != null
      ? {
          startDate: moment.tz(eventStartDate, tz),
          endDate: eventEndDate ? moment.tz(eventEndDate, tz) : null,
          startDateAllDay,
          endDateAllDay,
        }
      : null;
  };

  render() {
    const {
      selectedComponent,
      componentProps: { eventDatesComponent },
    } = this.props;

    if (!eventDatesComponent) {
      return null;
    }

    const dateConfig = this.getDateConfig();

    const componentProps = selectedComponent
      ? {
          ...eventDatesComponent,
          padding: selectedComponent.selectedComponentPadding || eventDatesComponent.padding,
          fontSize:
            selectedComponent.selectedComponentFontSize == null
              ? eventDatesComponent.fontSize
              : selectedComponent.selectedComponentFontSize,
        }
      : eventDatesComponent;

    return (
      <Container componentProps={componentProps}>
        {dateConfig && formatDateTimeRange(dateConfig)}
      </Container>
    );
  }
}

export default createFragmentContainer(EventDatesComponent, {
  registrationForm: graphql`
    fragment EventDatesComponent_registrationForm on RegistrationForm {
      eventStartDate
      eventEndDate
      startDateAllDay
      endDateAllDay
      tz
      org {
        settings {
          tz
        }
      }
    }
  `,
  componentProps: graphql`
    fragment EventDatesComponent_componentProps on RegistrationPageComponent {
      eventDatesComponent {
        padding
        alignment
        fontFamily
        fontStyle
        fontSize
        color
        backgroundColor
      }
    }
  `,
});
