/* @flow */
import type { RecordSourceSelectorProxy, SelectorData } from 'react-relay';
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordsFromConnections from 'graph/updaters/removeRecordsFromConnections';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateContactFormFieldMutationResponse,
  updateContactFormFieldMutationVariables,
} from './__generated__/updateContactFormFieldMutation.graphql';

export function getRemovedFilterUpdaters(): $ReadOnlyArray<
  (RecordSourceSelectorProxy, SelectorData) => void,
> {
  return [
    'Text',
    'Link',
    'Date',
    'Number',
    'Currency',
    'Boolean',
    'Multiselect',
    'UserMultiselect',
  ].map(filterKind =>
    removeRecordsFromConnections(`removed${filterKind}FilterConnections`, {
      field: `customSaved${filterKind}Filters`,
    }),
  );
}

const mutation = graphql`
  mutation updateContactFormFieldMutation(
    $input: UpdateContactFormFieldInput!
    $changingMapping: Boolean!
  ) {
    updateContactFormField(input: $input) {
      contactFormField {
        label
        fieldName
        required
        helpText
        showOtherOption
        customField {
          id
          kind
          label
          required
          options {
            edges {
              node {
                id
                name
              }
            }
          }
        }
        contactFormFieldOptions {
          edges {
            node {
              customFieldOption {
                id
              }
            }
          }
        }
        contactFormFieldUserOptions {
          edges {
            node {
              user {
                id
              }
            }
          }
        }
      }
      registrationForm {
        id
        hasChanges
      }
      removedTextFilterConnections @include(if: $changingMapping) {
        parentId
        childId
      }
      removedLinkFilterConnections @include(if: $changingMapping) {
        parentId
        childId
      }
      removedNumberFilterConnections @include(if: $changingMapping) {
        parentId
        childId
      }
      removedCurrencyFilterConnections @include(if: $changingMapping) {
        parentId
        childId
      }
      removedBooleanFilterConnections @include(if: $changingMapping) {
        parentId
        childId
      }
      removedMultiselectFilterConnections @include(if: $changingMapping) {
        parentId
        childId
      }
      removedDateFilterConnections @include(if: $changingMapping) {
        parentId
        childId
      }
      removedUserMultiselectFilterConnections @include(if: $changingMapping) {
        parentId
        childId
      }
      removedRuleConnections @include(if: $changingMapping) {
        parentId
        childId
      }
    }
  }
`;

export default function updateContactFormField(
  input: $PropertyType<updateContactFormFieldMutationVariables, 'input'>,
  changingMapping: boolean,
): Promise<updateContactFormFieldMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input, changingMapping },
    optimisticResponse: {
      updateContactFormField: {
        contactFormField: input,
        removedTextFilterConnections: [],
        removedLinkFilterConnections: [],
        removedDateFilterConnections: [],
        removedNumberFilterConnections: [],
        removedCurrencyFilterConnections: [],
        removedBooleanFilterConnections: [],
        removedMultiselectFilterConnections: [],
        removedUserMultiselectFilterConnections: [],
        removedRuleConnections: [],
      },
    },
    updater: mergeUpdaters(
      ...(changingMapping
        ? [
            ...getRemovedFilterUpdaters(),
            removeRecordsFromConnections('removedRuleConnections', {
              field: 'rules',
            }),
          ]
        : []),
    ),
  });
}
