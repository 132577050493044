import us_states from 'config/us_states.json';

export function locationAddressString({ city, state, postal, address1, country }) {
  return [address1, city, state, postal, country].filter(x => x).join(',');
}

export function getStateAbbr(state) {
  if (!state || state.length === 2) return state;

  return (
    us_states[Object.keys(us_states).find(x => x.toLowerCase() === state.toLowerCase())] || state
  );
}

export function stringifyLocation(location, omitVenueNameBy) {
  if (!location) return '';
  return [
    omitVenueNameBy != null ? omitVenueNameBy && location[omitVenueNameBy] : location.name,
    location.city === location.name && omitVenueNameBy == null ? null : location.city,
    location.country === 'United States' ? getStateAbbr(location.state) : location.country,
  ]
    .filter(Boolean)
    .join(', ');
}
