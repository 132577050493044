/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  padding: 20px 16px;
  z-index: 300;
`;

const Content = styled.div`
  position: relative;
  width: 26px;
  height: 14px;
  cursor: pointer;
  overflow: hidden;
  & > div:nth-child(1) {
    top: 0;
    -webkit-transition-property: top, -webkit-transform;
    transition-property: top, transform;
    -webkit-transition-delay: 0.3s, 0s;
    transition-delay: 0.3s, 0s;
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    ${props =>
      props.open &&
      css`
        top: 6px;
        transform: rotate(45deg);
        -webkit-transition-delay: 0s, 0.3s;
        transition-delay: 0s, 0.3s;
      `};
  }
  & > div:nth-child(2) {
    top: 6px;
    -webkit-transition-property: -webkit-transform, -webkit-transform;
    transition-property: transform, transform;
    -webkit-transition-delay: 0s, 0.3s;
    transition-delay: 0s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    -webkit-transition-duration: 0.3s, 0.3s;
    ${props =>
      props.open &&
      css`
        transform: translateX(-100%);
        -webkit-transition-delay: 0.3s, 0s;
        transition-delay: 0.3s, 0s;
      `};
  }
  & > div:nth-child(3) {
    bottom: 0;
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    -webkit-transition-delay: 0.3s, 0s;
    transition-delay: 0.3s, 0s;
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    ${props =>
      props.open &&
      css`
        bottom: 6px;
        transform: rotate(-45deg);
        -webkit-transition-delay: 0s, 0.3s;
        transition-delay: 0s, 0.3s;
      `};
  }
`;

const Item = styled.div`
  display: block;
  width: 100%;
  background-color: #fff;
  height: 2px;
  position: absolute;
`;

type Props = {
  open: boolean,
  className?: ?string,
  onChange: () => void,
};

const HamburgerMenu = ({ open, onChange, className }: Props) => {
  return (
    <Container className={className} onClick={onChange}>
      <Content open={open}>
        <Item />
        <Item />
        <Item />
      </Content>
    </Container>
  );
};

export default HamburgerMenu;
