/* @flow */
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import { getAccessToken } from 'utils/Auth';

type GraphQLServerJSONResponse = {
  data?: ?{},
  errors?: $ReadOnlyArray<{
    message: string,
    locations?: $ReadOnlyArray<{
      line: number,
      column: number,
    }>,
    path?: $ReadOnlyArray<string>,
  }>,
};

function fetchQuery(operation, variables) {
  return fetch('/graphql/query', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: getAccessToken(),
    },
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  })
    .then(rawResponse => {
      return rawResponse
        .json()
        .catch(() => Promise.reject(new Error('There was a problem on our end.')));
    })
    .then((response: GraphQLServerJSONResponse) =>
      response.errors ? Promise.reject(new Error(response.errors[0].message)) : response,
    );
}

const source = new RecordSource();
const store = new Store(source);
const network = Network.create(fetchQuery); // see note below
const handlerProvider = null;

const environment = new Environment({
  handlerProvider, // Can omit.
  network,
  store,
});

export default () => environment;
