/* @flow */
import formatCost from 'utils/number/formatCost';

import { type ReportingCellPropsType } from './index';

const ReportingBudgetedAmountCell = ({
  group,
  childGroup,
  orgCurrency,
}: ReportingCellPropsType) => {
  if (childGroup == null) {
    return group.budgetedAmount == null ? null : formatCost(group.budgetedAmount, orgCurrency);
  }
  return childGroup.budgetedAmount == null
    ? null
    : formatCost(childGroup.budgetedAmount, orgCurrency);
};

export default ReportingBudgetedAmountCell;
