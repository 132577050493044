/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import getLinkError from 'utils/getLinkError';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';
import LockIcon from 'components/LockIcon';

import type { CustomizableLink_customizable } from './__generated__/CustomizableLink_customizable.graphql';

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
`;

class CustomizableLink extends React.Component<{
  customizable: CustomizableLink_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  getValue = (): string => {
    const { customizable, fieldSettings } = this.props;
    const customLinkField = customizable.customLinkFields.find(
      linkField => linkField.customField.id === fieldSettings.id,
    );
    return customLinkField ? customLinkField.value : '';
  };

  handleSave = (value: ?string): void => {
    const { customizable, fieldSettings, onUpdateCustomField, updateColumnWidth } = this.props;
    const linkValue = value ? enforceHttpPrefix(value.trim()) : null;
    if (!this.getError(linkValue) && linkValue !== this.getValue() && onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        linkValue,
      });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    return getLinkError(value, this.props.fieldSettings.required);
  };

  render() {
    const { readOnly, fieldSettings, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    const value = this.getValue();
    if (readOnly || fieldSettings.restrictChangingValue) {
      return (
        <InlineExpandableText>
          <a
            onMouseDown={this.handleMouseDown}
            href={value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
          {!readOnly && fieldSettings.restrictChangingValue && (
            <StyledLockIcon label="Salesforce" />
          )}
        </InlineExpandableText>
      );
    }

    return (
      <InlineTextField
        value={value}
        onChange={this.handleSave}
        placeholder="Ex: https://www.example.com"
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
      >
        {value && (
          <InlineExpandableText>
            <a
              onMouseDown={this.handleMouseDown}
              href={value}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value}
            </a>
          </InlineExpandableText>
        )}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  CustomizableLink,
  graphql`
    fragment CustomizableLink_customizable on CustomizableInterface {
      id
      __typename
      customLinkFields {
        customField {
          id
        }
        value
      }
    }
  `,
);
