/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import publishRegistrationForm from 'graph/mutations/registration/publishRegistrationForm';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Arrow from 'images/arrow.svg';
import Button from 'components/budget/Button';
import ConfirmationWindow from 'components/ConfirmationWindow';
import Mask from 'components/Mask';
import Overlay from 'components/material/Overlay';

import type { LiveActions_event } from './__generated__/LiveActions_event.graphql';

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 115px;
  height: 30px;
  padding: 5px 9px 4px 4px;
  border-radius: 4px;
  background-color: ${props => (props.background ? props.background : '#29cc9a')};
  color: ${props => (props.color ? props.color : '#ffffff')};
  cursor: pointer;
  user-select: none;
  &:hover,
  &:focus {
    background-color: ${props => (props.background ? props.background : '#29cc9a')};
  }
`;

const Label = styled.div`
  flex: 1;
  border-right: 1px solid #fff;
  margin-right: 9px;
  padding: 0 9px;
  text-align: center;
`;

const StyledArrow = styled(Arrow)`
  width: 7px;
  transform: rotate(${props => (props.open ? 270 : 90)}deg);
`;

const StyledOverlay = styled(Overlay)`
  width: 115px;
  margin-top: 6px;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
`;

const ActionContainer = styled.div`
  width: 100%;
`;

const ActionButton = styled(Button)`
  width: 100%;
  padding: 9px 10px 11px;
  text-align: left;
  background-color: #ffffff;
  font-size: 13px;
  color: #4a5665;
  &:hover,
  &:focus {
    background-color: rgba(59, 169, 218, 0.07);
  }
`;

class LiveActions extends React.Component<
  {
    event: LiveActions_event,
    liveCopy?: string,
    liveColor: string,
    liveBackground: string,
    draftCopy?: string,
    draftColor: string,
    draftBackground: string,
    loaderColor?: string,
    syncedToIbmWm: boolean,
    reloadFormAfterSave?: boolean,
  },
  {
    showLiveActionsOverlay: boolean,
    showPublishConfirmationWindow: boolean,
    saveAndPublish: boolean,
    showMask: boolean,
  },
> {
  state = {
    showLiveActionsOverlay: false,
    showPublishConfirmationWindow: false,
    saveAndPublish: false,
    showMask: false,
  };

  containerRef = React.createRef();

  overlayRef = React.createRef();

  handleToggleLiveActionsOverlay = () => {
    this.setState(({ showLiveActionsOverlay }) => ({
      showLiveActionsOverlay: !showLiveActionsOverlay,
    }));
  };

  handleShowPublishConfirmation = (save: boolean) => {
    if (this.props.syncedToIbmWm) {
      this.handlePublish(save);
      return;
    }
    this.setState({ showPublishConfirmationWindow: true, saveAndPublish: save });
  };

  handleHidePublishConfirmation = () => {
    this.setState({ showPublishConfirmationWindow: false });
  };

  handleConfirmPublishing = () => {
    this.handlePublish(this.state.saveAndPublish);
  };

  handleUnpublish = () => {
    const registrationForm = this.props.event.registrationForm;
    if (!registrationForm) return;

    publishRegistrationForm({
      formId: registrationForm.id,
      published: false,
    }).catch(showModernMutationError);
  };

  handlePublish = (save: boolean) => {
    const {
      event: { registrationForm },
      reloadFormAfterSave,
    } = this.props;
    if (!registrationForm) return;

    if (save) {
      this.setState({ showMask: true });
    }
    publishRegistrationForm(
      { formId: registrationForm.id, published: true, save },
      reloadFormAfterSave,
    )
      .then(() => {
        this.setState({ showMask: false });
      })
      .catch(showModernMutationError);
    this.handleHidePublishConfirmation();
  };

  handlePublishWithoutSaving = () => this.handleShowPublishConfirmation(false);

  handleSaveAndPublish = () => this.handleShowPublishConfirmation(true);

  renderPublishOptions = (): React.Node => {
    const { registrationForm, editableRegistrationForm } = this.props.event;

    if (!registrationForm || !editableRegistrationForm) return null;

    if (registrationForm.publishedAt) {
      return (
        <ActionContainer>
          <ActionButton onClick={this.handleUnpublish}>Unpublish</ActionButton>
        </ActionContainer>
      );
    }

    return (
      <ActionContainer>
        <ActionButton onClick={this.handlePublishWithoutSaving}>Publish</ActionButton>
        {editableRegistrationForm.hasChanges && (
          <ActionButton onClick={this.handleSaveAndPublish}>Save & publish</ActionButton>
        )}
      </ActionContainer>
    );
  };

  getDropdownProps = (): { copy: string, backgroundColor: string, color: string } => {
    const {
      liveCopy,
      liveColor,
      liveBackground,
      draftCopy,
      draftColor,
      draftBackground,
      event: { registrationForm },
    } = this.props;
    // Null check is for flow
    if (registrationForm == null || registrationForm.publishedAt) {
      return { copy: liveCopy || 'Live', backgroundColor: liveBackground, color: liveColor };
    }

    return { copy: draftCopy || 'Draft', backgroundColor: draftBackground, color: draftColor };
  };

  render() {
    const {
      event: { editableRegistrationForm },
      loaderColor,
    } = this.props;
    const { copy, backgroundColor, color } = this.getDropdownProps();
    const {
      showLiveActionsOverlay,
      showPublishConfirmationWindow,
      saveAndPublish,
      showMask,
    } = this.state;
    return (
      <>
        <DropdownContainer
          ref={this.containerRef}
          onClick={this.handleToggleLiveActionsOverlay}
          color={color}
          background={backgroundColor}
        >
          <Label>{copy}</Label>
          <StyledArrow open={showLiveActionsOverlay} />
          <StyledOverlay
            container={this}
            target={this.containerRef.current}
            show={showLiveActionsOverlay}
            onHide={this.handleToggleLiveActionsOverlay}
            overlayRef={this.overlayRef}
          >
            {this.renderPublishOptions()}
          </StyledOverlay>
        </DropdownContainer>
        {showMask && <Mask loaderColor={loaderColor} />}
        {showPublishConfirmationWindow && (
          <ConfirmationWindow
            actionNegative={false}
            actionLabel="Confirm"
            onHide={this.handleHidePublishConfirmation}
            onConfirm={this.handleConfirmPublishing}
            title="Are you sure?"
            message={`Once published, the page will be live on the associated URL until it is Unpublished.${
              editableRegistrationForm && editableRegistrationForm.hasChanges && !saveAndPublish
                ? ' This will not apply unsaved changes to the public form.'
                : ''
            }`}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(LiveActions, {
  event: graphql`
    fragment LiveActions_event on Event {
      slug
      registrationForm {
        id
        publishedAt
      }
      editableRegistrationForm {
        hasChanges
      }
    }
  `,
});
