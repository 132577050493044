/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
type UsersGroup_users$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserSelectInputContainer_query$ref: FragmentReference;
export type UserSelectInputContainer_query = {|
  +searchUsers?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +email: string,
        +firstName: string,
        +lastName: string,
        +$fragmentRefs: MaterialAvatar_user$ref & UsersGroup_users$ref,
      |}
    |}>
  |},
  +event?: ?{|
    +viewerCanAddStaff?: boolean,
    +staffers?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +user: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +email: string,
            +$fragmentRefs: MaterialAvatar_user$ref & UsersGroup_users$ref,
          |}
        |}
      |}>
    |},
    +team?: {|
      +users: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +email: string,
            +$fragmentRefs: MaterialAvatar_user$ref & UsersGroup_users$ref,
          |}
        |}>
      |}
    |},
  |},
  +activeUsers: $ReadOnlyArray<?{|
    +id: string,
    +email?: string,
    +firstName?: string,
    +lastName?: string,
    +$fragmentRefs: MaterialAvatar_user$ref,
  |}>,
  +$refType: UserSelectInputContainer_query$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "FragmentSpread",
  "name": "MaterialAvatar_user",
  "args": null
},
v5 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query",
  "type": "String"
},
v6 = {
  "kind": "FragmentSpread",
  "name": "UsersGroup_users",
  "args": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  v5
],
v8 = [
  v0,
  v2,
  v3,
  v1,
  v4,
  v6
];
return {
  "kind": "Fragment",
  "name": "UserSelectInputContainer_query",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "query",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "hasTeamAccess",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEvent",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "eventId",
      "type": "ID!"
    },
    {
      "kind": "RootArgument",
      "name": "userIds",
      "type": "[ID!]!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "activeUsers",
      "name": "nodes",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "userIds",
          "type": "[ID!]!"
        }
      ],
      "concreteType": null,
      "plural": true,
      "selections": [
        v0,
        {
          "kind": "InlineFragment",
          "type": "User",
          "selections": [
            v1,
            v2,
            v3,
            v4
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": false,
      "condition": "includeEvent",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "searchUsers",
          "storageKey": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 6,
              "type": "Int"
            },
            {
              "kind": "Variable",
              "name": "hasTeamAccess",
              "variableName": "hasTeamAccess",
              "type": "Boolean"
            },
            v5
          ],
          "concreteType": "UserRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "UserRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": [
                    v0,
                    v1,
                    v2,
                    v3,
                    v4,
                    v6
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEvent",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": "event",
          "name": "node",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "eventId",
              "type": "ID!"
            }
          ],
          "concreteType": null,
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "type": "Event",
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "viewerCanAddStaff",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "staffers",
                  "storageKey": null,
                  "args": v7,
                  "concreteType": "StaffMembershipRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "StaffMembershipRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "StaffMembership",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "user",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "User",
                              "plural": false,
                              "selections": v8
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "team",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Team",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "users",
                      "storageKey": null,
                      "args": v7,
                      "concreteType": "UserRequiredConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "UserRequiredEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "User",
                              "plural": false,
                              "selections": v8
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b25bbcff2ce161434d323be48408c03';
module.exports = node;
