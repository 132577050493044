/* @flow */
import taskStatuses from 'config/taskStatuses';

import parseTypedQueryString, {
  type DateRangeParam,
  type SortParam,
  stringParamToDateRange,
  stringParamToEnumArray,
  stringParamToSort,
  stringParamToString,
  stringParamToStringArray,
} from 'utils/routing/parseTypedQueryString';

import stringParamToFiscalDateRange from 'components/AllEvents/lib/stringParamToFiscalDateRange';

export type ParsedTaskFilters = {
  sort: ?SortParam,
  query: string,
  tagIds: $ReadOnlyArray<string>,
  statuses: $ReadOnlyArray<string>,
  assigneeIds: $ReadOnlyArray<string>,
  dueDate: ?DateRangeParam, // Section and Due Date views
  eventDate?: ?DateRangeParam,
  teamIds?: $ReadOnlyArray<string>,
  eventIds?: $ReadOnlyArray<string>,
};

export default function parseTaskFilters(queryString: string, fiscalYearStart?: number) {
  const stringParamToFiscalDateRangeInner = (stringValue: string) => {
    return stringParamToFiscalDateRange(stringValue, fiscalYearStart);
  };

  return parseTypedQueryString(queryString, {
    sort: stringParamToSort,
    query: stringParamToString,
    tagIds: stringParamToStringArray,
    assigneeIds: stringParamToStringArray,
    statuses: stringParamToEnumArray(Object.keys(taskStatuses)),
    dueDate: stringParamToDateRange,
    eventDate: stringParamToFiscalDateRangeInner,
    teamIds: stringParamToStringArray,
    eventIds: stringParamToStringArray,
  });
}
