/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import LockIcon from 'components/LockIcon';
import CheckBox from 'components/material/CheckBox';

const StyledCheckBox = styled(CheckBox)`
  > div {
    margin: auto;
    color: ${props =>
      props.checked && !props.disabled ? props.theme.highlightColor : props.theme.iron};
  }
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
`;

export default class InlineCheckBoxField extends React.PureComponent<{
  label?: string,
  value: ?boolean,
  disabled?: boolean,
  onChange: boolean => void,
  showLockIcon?: boolean,
}> {
  render() {
    const { value, label, onChange, disabled, showLockIcon } = this.props;

    return (
      <>
        <StyledCheckBox
          label={label}
          checked={value != null ? value : false}
          onChange={onChange}
          disabled={disabled}
        />
        {showLockIcon && <StyledLockIcon label="Salesforce" />}
      </>
    );
  }
}
