/* @flow */
import uniqueId from 'lodash/uniqueId';

import type { SectionType } from './types';

const generateBlankSection = (order: number): SectionType => ({
  id: uniqueId('section_'),
  order,
  title: '',
  helpText: '',
  description: '',
  questions: [],
});

export default generateBlankSection;
