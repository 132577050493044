/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import debounce from 'lodash/debounce';

import eventPartition from 'utils/eventPartition';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import SelectField from 'components/material/SelectField';
import EventOptionRow from 'components/EventSearch/EventOptionRow';

import type { InvitationEventSearchQuery_response } from './__generated__/InvitationEventSearchQuery.graphql';

const query = graphql`
  query InvitationEventSearchQuery($filters: EventFilters!) {
    me {
      events(first: 10, filters: $filters) {
        edges {
          node {
            id
            name
            startDate
            endDate
            tz
            primaryLocation {
              name
              city
              state
              country
            }
          }
        }
      }
    }
  }
`;

export type Event = {
  +id: string,
  +name: string,
  +startDate: any,
  +endDate: any,
  +tz: string,
  +primaryLocation: ?{
    +name: string,
    +city: string,
    +state: ?string,
    +country: ?string,
  },
};

export default class InvitationEventSearch extends React.PureComponent<
  {
    selectedEventIds?: $ReadOnlyArray<string>,
    onSelect: (event: ?Event) => void,
    label?: string,
    onHideOptions?: () => void,
    onSearch?: (query: string) => void,
    className?: string,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleSearch = debounce((search: string) => {
    this.setState({ query: search });
    if (this.props.onSearch) {
      this.props.onSearch(search);
    }
  }, 800);

  handleQueryChange = (search: string) => {
    this.handleSearch(search);
  };

  renderSelect = (response?: InvitationEventSearchQuery_response) => {
    const events: Array<Event> =
      response && response.me.events ? response.me.events.edges.map(edge => edge.node) : [];

    const findEvent = (eventId: ?string) => events.find(c => c.id === eventId) || null;

    const { upcoming, past } = eventPartition(events);

    const optionGroups =
      upcoming.length === 0 || past.length === 0
        ? []
        : [
            { label: 'UPCOMING EVENTS', value: 'upcoming' },
            { label: 'PAST EVENTS', value: 'past' },
          ];

    const options = [
      ...upcoming.map(event => ({
        label: event.name,
        value: event.id,
        groupId: 'upcoming',
        render: props => <EventOptionRow key={event.id} event={event} {...props} />,
      })),
      ...past.map(event => ({
        label: event.name,
        value: event.id,
        groupId: 'past',
        render: props => <EventOptionRow key={event.id} event={event} {...props} />,
      })),
    ];

    return (
      <SelectField
        className={this.props.className}
        label={this.props.label}
        searchable
        optionGroups={optionGroups}
        options={options}
        onSearch={this.handleQueryChange}
        onChange={(eventId: ?string) => this.props.onSelect(findEvent(eventId))}
        onHideOptions={this.props.onHideOptions}
      />
    );
  };

  render() {
    const variables = {
      filters: {
        fullAccessEvents: true,
        requestStatuses: ['N_A', 'APPROVED'],
        queries: [this.state.query],
        excludeEventIds: this.props.selectedEventIds,
      },
    };
    return (
      <DefaultQueryRenderer
        query={query}
        variables={variables}
        renderSuccess={this.renderSelect}
        renderLoading={this.renderSelect}
      />
    );
  }
}
