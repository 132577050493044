/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActiveSubscriptionMessage_subscription$ref: FragmentReference;
export type ActiveSubscriptionMessage_subscription = {|
  +expiresAt: ?any,
  +fatmLimit: ?number,
  +fatmCount: number,
  +$refType: ActiveSubscriptionMessage_subscription$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ActiveSubscriptionMessage_subscription",
  "type": "Subscription",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "expiresAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fatmLimit",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fatmCount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '4def57fd4481c539bdbd5b8a8926f58b';
module.exports = node;
