/* @flow */
import qs from 'qs';
import blobToBase64 from 'utils/blobToBase64';
import requireGoogleAccessToken from 'utils/oauth/requireGoogleAccessToken';

export default async function insertDriveFile(fileData: Blob, name: string): Promise<string> {
  const base64Data = await blobToBase64(fileData);
  const accessToken = await requireGoogleAccessToken([
    'https://www.googleapis.com/auth/drive.file',
  ]);

  const boundary = '-------314159265358979323846';
  const delimiter = `\r\n--${boundary}\r\n`;
  const close_delim = `\r\n--${boundary}--`;
  const contentType = fileData.type || 'application/octet-stream';
  const metadata = {
    title: name,
    mimeType: contentType,
  };
  const multipartRequestBody =
    `${delimiter}Content-Type: application/json\r\n\r\n${JSON.stringify(
      metadata,
    )}${delimiter}Content-Type: ${contentType}\r\n` +
    'Content-Transfer-Encoding: base64\r\n' +
    `\r\n${base64Data}${close_delim}`;

  const params = qs.stringify({
    uploadType: 'multipart',
    convert: true,
    access_token: accessToken,
  });

  return fetch(`https://www.googleapis.com/upload/drive/v2/files?${params}`, {
    method: 'POST',
    headers: {
      'Content-Type': `multipart/mixed; boundary="${boundary}"`,
    },
    body: multipartRequestBody,
  })
    .then(response => {
      if (response.ok) {
        return response;
      }
      return response.json().then(body => {
        return Promise.reject(new Error(body.error.message));
      });
    })
    .then(response => response.json())
    .then(data => data.alternateLink);
}
