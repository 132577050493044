/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled from 'styled-components';

import parseTypedQueryString, { stringParamToSort } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import NoResult from 'images/noResult.svg';
import BillingLimitWindow from 'components/billing/BillingLimitWindow';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Button from 'components/material/Button';
import type { Sort } from 'components/material/SortableHeader';

import AddUserToTeamsWindow from './AddUserToTeamsWindow';
import columns, { type MembershipCellPropsType } from './columns';

import type { UserTeams_me } from './__generated__/UserTeams_me.graphql';
import type { UserTeams_org } from './__generated__/UserTeams_org.graphql';
import type { UserTeams_user } from './__generated__/UserTeams_user.graphql';
import type { UserTeamsQueryResponse } from './__generated__/UserTeamsQuery.graphql';

const Container = styled.div`
  margin-top: 20px;
`;

const Header = styled.div`
  margin-bottom: 17px;
  padding: 0 0 15px 5px;
  border-bottom: 1px solid #eaebec;
  font-size: 16px;
  font-weight: 500;
  line-height: 0.875;
`;

const StyledButton = styled(Button)`
  margin-bottom: 20px;
`;

type MembershipEdge = $ElementType<
  $NonMaybeType<
    $PropertyType<$NonMaybeType<$PropertyType<UserTeamsQueryResponse, 'user'>>, 'memberships'>,
  >,
  0,
>;

const query = graphql`
  query UserTeamsQuery($userId: ID!, $sort: TeamMembershipSort, $direction: Direction!) {
    user: node(id: $userId) {
      ... on User {
        memberships(first: 1000, sort: $sort, direction: $direction)
          @connection(key: "UserTeams_memberships", filters: []) {
          edges {
            ...TeamName_membershipEdge
            ...TeamDateAdded_membershipEdge
            ...TeamMembershipActionsColumn_membershipEdge
            node {
              id
              slug
              viewerCanUpdate
            }
          }
        }
      }
    }
  }
`;

class UserTeams extends React.Component<
  {
    org: UserTeams_org,
    user: UserTeams_user,
    me: UserTeams_me,
    location: Location,
    history: History,
  },
  {
    adding: boolean,
    billingError: boolean,
  },
> {
  state = {
    adding: false,
    billingError: false,
  };

  handleAddStart = () => {
    const {
      org: { subscription },
      user,
    } = this.props;
    // Subscription has no limits or has free slots or user already has team access
    if (
      subscription.fatmLimit == null ||
      subscription.fatmLimit - subscription.fatmCount > 0 ||
      user.hasTeamAccess
    ) {
      this.setState({ adding: true });
    } else {
      this.setState({ billingError: true });
    }
  };

  handleAddEnd = () => {
    this.setState({ adding: false });
  };

  handleHideBillingError = () => {
    this.setState({ billingError: false });
  };

  handleChangeSort = (sort: Sort) => {
    replaceSortQueryParam(this.props.history, sort, 'teamSort');
  };

  cellProps = (membershipEdge: MembershipEdge): MembershipCellPropsType<MembershipEdge> => {
    const { user, me, location } = this.props;
    return {
      membershipEdge,
      userId: user.id,
      currentUserId: me.id,
      location,
    };
  };

  keyExtractor = (membershipEdge: MembershipEdge) => membershipEdge.node.id;

  sort = () =>
    parseTypedQueryString(this.props.location.search, {
      teamSort: stringParamToSort,
    }).teamSort || {
      key: 'TEAM_DATE_ADDED',
      asc: true,
    };

  rowClickHandler = (membershipEdge: MembershipEdge) => {
    if (!membershipEdge.node.viewerCanUpdate) {
      return null;
    }

    return () => this.props.history.push(`/workspace/teams/${membershipEdge.node.slug}`);
  };

  renderTable = (response?: UserTeamsQueryResponse) => {
    const membershipEdges =
      response && response.user && response.user.memberships
        ? response.user.memberships.edges
        : null;

    if (membershipEdges && membershipEdges.length === 0) {
      return (
        <EmptyView message="Workspace Member does not belong to any Teams" icon={<NoResult />} />
      );
    }

    return (
      <Table
        columns={columns}
        cellProps={this.cellProps}
        keyExtractor={this.keyExtractor}
        data={membershipEdges}
        sort={this.sort()}
        onChangeSort={this.handleChangeSort}
        rowClickHandler={this.rowClickHandler}
      />
    );
  };

  render() {
    const sort = this.sort();
    const {
      org: { viewerCanManageTeamMembers, subscription },
      user,
      me,
    } = this.props;

    // Admins are already members in all teams.
    // Other team members can't add themselves to new teams
    const canAddToTeam = !user.admin && viewerCanManageTeamMembers && user.id !== me.id;

    return (
      <Container>
        <Header>Teams</Header>
        {canAddToTeam && (
          <StyledButton primary outline onClick={this.handleAddStart}>
            Add to Team
          </StyledButton>
        )}
        {this.state.adding && (
          <AddUserToTeamsWindow
            userId={this.props.user.id}
            me={this.props.me}
            onHide={this.handleAddEnd}
          />
        )}
        {this.state.billingError && subscription.fatmLimit != null && (
          <BillingLimitWindow
            limit={subscription.fatmLimit}
            used={subscription.fatmCount}
            onHide={this.handleHideBillingError}
            fromWindow="user profile page"
          />
        )}

        <DefaultQueryRenderer
          query={query}
          variables={{
            userId: this.props.user.id,
            sort: sort.key,
            direction: sort.asc ? 'ASC' : 'DESC',
          }}
          renderSuccess={this.renderTable}
          renderLoading={this.renderTable}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(UserTeams, {
  user: graphql`
    fragment UserTeams_user on User {
      id
      admin
      hasTeamAccess
    }
  `,
  me: graphql`
    fragment UserTeams_me on User {
      id
      ...AddUserToTeamsWindow_me
    }
  `,
  org: graphql`
    fragment UserTeams_org on Org {
      viewerCanManageTeamMembers
      subscription {
        fatmLimit
        fatmCount
      }
    }
  `,
});
