/**
 * @flow
 * @relayHash d2b01bb356fc8f0b8115eaf834cfdcf4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CategoriesTable_categoryEdges$ref = any;
export type ChangeEventBudgetCategoryInput = {
  clientMutationId?: ?string,
  categoryId: string,
  eventId: string,
  newCategoryId: string,
};
export type changeEventBudgetCategoryMutationVariables = {|
  input: ChangeEventBudgetCategoryInput
|};
export type changeEventBudgetCategoryMutationResponse = {|
  +changeEventBudgetCategory: ?{|
    +event: {|
      +budgetCategories: {|
        +edges: $ReadOnlyArray<{|
          +$fragmentRefs: CategoriesTable_categoryEdges$ref
        |}>
      |}
    |}
  |}
|};
export type changeEventBudgetCategoryMutation = {|
  variables: changeEventBudgetCategoryMutationVariables,
  response: changeEventBudgetCategoryMutationResponse,
|};
*/


/*
mutation changeEventBudgetCategoryMutation(
  $input: ChangeEventBudgetCategoryInput!
) {
  changeEventBudgetCategory(input: $input) {
    event {
      budgetCategories {
        edges {
          ...CategoriesTable_categoryEdges
        }
      }
      id
    }
  }
}

fragment CategoriesTable_categoryEdges on EventBudgetCategoryEdge {
  budgetedAmount
  actualAmount
  paidAmount
  ...CategoryNameCell_categoryEdge
  ...CategoryBudgetedAmountCell_categoryEdge
  ...CategoryActualAmountCell_categoryEdge
  ...CategoryActualOfBudgetedCell_categoryEdge
  ...CategoryPaidAmountCell_categoryEdge
  ...CategoryPaidOfActualCell_categoryEdge
  ...CategoryActionCell_categoryEdge
  node {
    id
    name
  }
}

fragment CategoryNameCell_categoryEdge on EventBudgetCategoryEdge {
  budgetedAmount
  node {
    id
    name
  }
}

fragment CategoryBudgetedAmountCell_categoryEdge on EventBudgetCategoryEdge {
  budgetedAmount
}

fragment CategoryActualAmountCell_categoryEdge on EventBudgetCategoryEdge {
  actualAmount
}

fragment CategoryActualOfBudgetedCell_categoryEdge on EventBudgetCategoryEdge {
  budgetedAmount
  actualAmount
}

fragment CategoryPaidAmountCell_categoryEdge on EventBudgetCategoryEdge {
  paidAmount
}

fragment CategoryPaidOfActualCell_categoryEdge on EventBudgetCategoryEdge {
  actualAmount
  paidAmount
}

fragment CategoryActionCell_categoryEdge on EventBudgetCategoryEdge {
  node {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ChangeEventBudgetCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ChangeEventBudgetCategoryInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "changeEventBudgetCategoryMutation",
  "id": null,
  "text": "mutation changeEventBudgetCategoryMutation(\n  $input: ChangeEventBudgetCategoryInput!\n) {\n  changeEventBudgetCategory(input: $input) {\n    event {\n      budgetCategories {\n        edges {\n          ...CategoriesTable_categoryEdges\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment CategoriesTable_categoryEdges on EventBudgetCategoryEdge {\n  budgetedAmount\n  actualAmount\n  paidAmount\n  ...CategoryNameCell_categoryEdge\n  ...CategoryBudgetedAmountCell_categoryEdge\n  ...CategoryActualAmountCell_categoryEdge\n  ...CategoryActualOfBudgetedCell_categoryEdge\n  ...CategoryPaidAmountCell_categoryEdge\n  ...CategoryPaidOfActualCell_categoryEdge\n  ...CategoryActionCell_categoryEdge\n  node {\n    id\n    name\n  }\n}\n\nfragment CategoryNameCell_categoryEdge on EventBudgetCategoryEdge {\n  budgetedAmount\n  node {\n    id\n    name\n  }\n}\n\nfragment CategoryBudgetedAmountCell_categoryEdge on EventBudgetCategoryEdge {\n  budgetedAmount\n}\n\nfragment CategoryActualAmountCell_categoryEdge on EventBudgetCategoryEdge {\n  actualAmount\n}\n\nfragment CategoryActualOfBudgetedCell_categoryEdge on EventBudgetCategoryEdge {\n  budgetedAmount\n  actualAmount\n}\n\nfragment CategoryPaidAmountCell_categoryEdge on EventBudgetCategoryEdge {\n  paidAmount\n}\n\nfragment CategoryPaidOfActualCell_categoryEdge on EventBudgetCategoryEdge {\n  actualAmount\n  paidAmount\n}\n\nfragment CategoryActionCell_categoryEdge on EventBudgetCategoryEdge {\n  node {\n    id\n    name\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "changeEventBudgetCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "changeEventBudgetCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "ChangeEventBudgetCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "budgetCategories",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBudgetCategoryConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventBudgetCategoryEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "CategoriesTable_categoryEdges",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "changeEventBudgetCategoryMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "changeEventBudgetCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "ChangeEventBudgetCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "budgetCategories",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBudgetCategoryConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventBudgetCategoryEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "budgetedAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actualAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "paidAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BudgetCategory",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2e8057ddd638a1cdecac207094de7604';
module.exports = node;
