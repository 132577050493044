/* @flow */
import React from 'react';
import { type Match, Redirect } from 'react-router-dom';

import { setUser } from 'utils/Auth';
import withQueryParams from 'utils/routing/withQueryParams';

class AutoLogin extends React.Component<{
  match: Match,
  queryParams: {
    next?: string,
  },
}> {
  componentDidMount() {
    setUser({ access_token: this.props.match.params.token });
  }

  render() {
    return <Redirect to={this.props.queryParams.next || '/'} />;
  }
}

export default withQueryParams(AutoLogin);
