/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

const mutation = graphql`
  mutation addAttachmentToBriefMutation($input: AddAttachmentToBriefInput!) {
    addAttachmentToBrief(input: $input) {
      attachmentEdge {
        __typename
        node {
          id
        }
      }
    }
  }
`;

export default function addAttachmentToBrief(attachmentId: string, eventId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        attachmentId,
        eventId,
      },
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'attachmentEdge',
      connections: [{ field: 'briefAttachments' }],
    }),
  });
}
