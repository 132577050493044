/* @flow */
import React from 'react';
import styled from 'styled-components';

import Switch from 'components/material/Switch';

import type { QuestionType } from '../lib/types';

const Bar = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 25px 0 0 0;
  padding: 0 15px 0 20px;
  background: #f1f9fc;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

const SwitchLabel = styled.div`
  margin-right: 15px;
  font-size: 13px;
  font-weight: 500;
  color: #4a5665;
`;

const Right = styled.div`
  display: flex;
`;

const Action = styled.div`
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
  color: #96a2ab;
  &:hover {
    color: #4a5665;
  }
`;

const Hint = styled.div`
  background-color: #f1f9fc;
  color: #3ba9da;
  font-size: 13px;
`;

const HintCog = styled.span`
  margin-top: 3px;
  margin-right: 7px;
  font-size: 17px;
`;

const HintText = styled.i`
  margin-right: 6px;
  font-size: 13px;
  color: rgba(74, 86, 101, 0.66);
`;

export default class EditableQuestionActions extends React.PureComponent<{
  question: QuestionType,
  onChangeRequired: (required: boolean) => void,
  onRemoveQuestion: (questionId: string) => void,
}> {
  handleRemove = () => {
    this.props.onRemoveQuestion(this.props.question.id);
  };

  render() {
    const { question, onChangeRequired } = this.props;
    const canUpdate =
      question.id.startsWith('question_') ||
      (question.customField && !question.customField.required);

    return (
      <Bar>
        {canUpdate ? (
          <>
            <Left>
              <SwitchLabel>Required</SwitchLabel>
              <Switch enabled={question.required} size="small" onChange={onChangeRequired} />
            </Left>
            <Right>
              <Action onClick={this.handleRemove}>
                <i className="fa fa-fw fa-trash" />
              </Action>
            </Right>
          </>
        ) : (
          <Hint>
            <HintCog>*</HintCog>
            <HintText>This is a required field and cannot be deleted</HintText>
          </Hint>
        )}
      </Bar>
    );
  }
}
