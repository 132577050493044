/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import pageComponentsConfig, {
  type PageComponentKindType,
  type ReorderedPageComponentConfigType,
} from '../../../pageComponentsConfig';
import DraggableComponent from './DraggableComponent';
import PageComponent from './PageComponent';

import { type PageComponents_org } from './__generated__/PageComponents_org.graphql';

const Container = styled.div`
  padding: 30px 25px;
  ${props =>
    props.ibmView &&
    css`
      padding-top: 20px;
    `};
`;

const GapBetweenComponents = styled.div`
  height: 10px;
`;

const PageComponents = (props: {
  onMoveEnd: (reorderedPageComponentConfig?: ReorderedPageComponentConfigType) => void,
  onBeginDrag: (draggedNewComponentKind: PageComponentKindType) => void,
  onHoverPageComponent: (kind: PageComponentKindType) => void,
  onHoverOutPageComponent: () => void,
  org: PageComponents_org,
}) => {
  const { onMoveEnd, onBeginDrag, onHoverPageComponent, onHoverOutPageComponent, org } = props;
  const finalPageComponentsConfig = org.syncedToIbmWm
    ? pageComponentsConfig.filter(componentConfig => componentConfig.kind !== 'VIDEO')
    : pageComponentsConfig;
  return (
    <Container ibmView={org.syncedToIbmWm}>
      <div onMouseLeave={() => onHoverOutPageComponent()}>
        {finalPageComponentsConfig.map((componentConfig, index) => (
          <div
            key={componentConfig.kind}
            onMouseEnter={() => onHoverPageComponent(componentConfig.kind)}
          >
            {index > 0 && <GapBetweenComponents />}
            <DraggableComponent
              componentConfig={componentConfig}
              onMoveEnd={onMoveEnd}
              onBeginDrag={onBeginDrag}
            >
              <PageComponent componentConfig={componentConfig} />
            </DraggableComponent>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default createFragmentContainer(PageComponents, {
  org: graphql`
    fragment PageComponents_org on Org {
      syncedToIbmWm
    }
  `,
});
