/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import filterGroupColors from 'config/filterGroupColors.json';

import type { FieldType } from 'utils/customization/types';
import { type NumberRangeParam } from 'utils/routing/parseTypedQueryString';

import type { GroupType } from 'components/AllEvents/lib/getEventFields';
import { fieldFilterMap } from 'components/AllEvents/lib/renderEventFilter';
import Search from 'components/Search';

import FiltersGroup from './FiltersGroup';

export const FilterItemsContainer = styled.div`
  max-height: 60vh;
  overflow: auto;
  overflow-x: hidden;
  padding: 15px 10px 0 20px;
  @media (max-width: 460px) {
    max-height: unset;
    height: 100%;
  }
`;

const FilterSearchLabel = styled.div`
  padding: 10px 0;
  color: #3e4859;
  text-align: center;
`;

const StyledSearch = styled(Search)`
  max-width: initial;
  margin-bottom: 15px;
`;

export type FilterValueChangeParam = ?$ReadOnlyArray<string> | string | boolean | NumberRangeParam;

export type FilterChangeType = (name: string, value: FilterValueChangeParam) => void;

export default class FilterItems<F: {}> extends React.Component<
  {
    className?: string,
    filters: F,
    filterOptions: $ReadOnlyArray<FieldType>,
    groups?: $ReadOnlyArray<GroupType>,
    onFilterChange: (name: string, value: FilterValueChangeParam) => void,
    filterRenderer: (FieldType, F, FilterChangeType) => React.Node,
  },
  { search: string },
> {
  state = { search: '' };

  getGroups = () => this.props.groups || [{ id: -1, label: '' }];

  getCustomGroups = () =>
    this.getGroups()
      .filter(group => typeof group.id === 'string')
      .map(group => group.id);

  getGroupColor = (group: GroupType, index: number) => {
    if (index === 0) {
      return filterGroupColors[0];
    }

    if (typeof group.id === 'string') {
      return filterGroupColors[(this.getCustomGroups().indexOf(group.id) % 4) + 1];
    }

    return filterGroupColors[group.id % filterGroupColors.length];
  };

  handleSearch = (search: string) => {
    this.setState({ search });
  };

  getGroupFiltersCount = (group: GroupType) => {
    if (group.label === '') {
      return 0;
    }

    const { filters, filterOptions } = this.props;

    const groupFilters = filterOptions.filter(
      option => option.groupId === group.id || group.id === -1,
    );

    return groupFilters.reduce((count, filterOption) => {
      if (filterOption.kind !== 'DEFAULT' || filterOption.fieldName == null) {
        return filters[filterOption.id] ? count + 1 : count;
      }
      const filter =
        fieldFilterMap[filterOption.fieldName] && fieldFilterMap[filterOption.fieldName].filter;

      if (filters[filterOption.fieldName] || (filter && filters[filter])) {
        return count + 1;
      }

      return count;
    }, 0);
  };

  getGroupContent = (group: GroupType) => {
    const { filters, filterOptions, onFilterChange, filterRenderer } = this.props;
    const search = this.state.search.toLowerCase().trim();

    const groupFilters = filterOptions.filter(
      option => option.groupId === group.id || group.id === -1,
    );

    const searchedFilters =
      search && search !== ''
        ? groupFilters.filter(field => field.label.toLowerCase().includes(search))
        : groupFilters;

    return searchedFilters
      .map(field => filterRenderer(field, filters, onFilterChange))
      .filter(Boolean);
  };

  renderGroups = () => {
    const groups = this.getGroups();
    const groupContents = groups.map(this.getGroupContent);
    const allFiltersCount = groupContents.reduce(
      (acc, groupContent) => acc + groupContent.length,
      0,
    );

    if (allFiltersCount === 0) {
      return <FilterSearchLabel>No filters found</FilterSearchLabel>;
    }

    return groups.map((group, index) => {
      if (groupContents[index].length === 0) {
        return null;
      }

      return (
        <FiltersGroup
          key={group.id}
          expanded={index === 0}
          forceExpanded={allFiltersCount <= 5}
          name={group.label}
          borderColor={this.getGroupColor(group, index)}
          count={this.getGroupFiltersCount(group)}
        >
          {groupContents[index]}
        </FiltersGroup>
      );
    });
  };

  render() {
    return (
      <FilterItemsContainer className={this.props.className}>
        <StyledSearch
          onSearch={this.handleSearch}
          search={this.state.search}
          placeholder="Search Filters"
        />
        {this.renderGroups()}
      </FilterItemsContainer>
    );
  }
}
