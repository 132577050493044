/* @flow */
import * as React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import downloadedBrief from 'utils/analytics/events/briefs/downloadedBrief';
import currentOrigin from 'utils/currentOrigin';
import { stringifyLocation } from 'utils/Location';

import removeEvent from 'graph/mutations/event/removeEvent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import DocDownloadOverlay from 'components/Briefs/DocDownloadOverlay';
import ConfirmationWindow from 'components/ConfirmationWindow';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ExternalCalendarExport from 'components/ExternalCalendarExport';
import ShareDropdown from 'components/ShareDropdown';

import type { EventActions_event } from './__generated__/EventActions_event.graphql';
import type { EventActions_user } from './__generated__/EventActions_user.graphql';

const Column = styled.div`
  display: flex;
  align-items: center;
  margin: -10px -5px 0 -12px;
`;

class EventActions extends React.Component<
  {
    user: EventActions_user,
    event: EventActions_event,
    userEmail?: ?string,
  },
  {
    showModal: boolean,
    showCalendarExportWindow: boolean,
    showDocDownloadOverlay: boolean,
    showPdfDownloadOverlay: boolean,
  },
> {
  state = {
    showModal: false,
    showCalendarExportWindow: false,
    showDocDownloadOverlay: false,
    showPdfDownloadOverlay: false,
  };

  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleDeleteClick = () => {
    this.setState({ showModal: true });
  };

  handlePdfExport = () => {
    this.setState({ showPdfDownloadOverlay: true });
  };

  handlePdfDownload = () => {
    const event = this.props.event;
    downloadedBrief({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'pdf',
      fromWindow: 'event dashboard',
    });
  };

  handlePdfDownloadEnd = () => {
    this.setState({ showPdfDownloadOverlay: false });
  };

  handleDocExport = () => {
    this.setState({ showDocDownloadOverlay: true });
  };

  handleDocDownload = () => {
    const event = this.props.event;
    downloadedBrief({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'doc',
      fromWindow: 'event dashboard',
    });
  };

  handleDocExportEnd = () => {
    this.setState({ showDocDownloadOverlay: false });
  };

  handleModalHide = () => {
    this.setState({ showModal: false });
  };

  handleViewBrief = () => {
    window.open(`/events/${this.props.event.slug}/brief`, '_blank');
  };

  handleDelete = () => {
    removeEvent(this.props.user.id, this.props.event.team.id, this.props.event.id).catch(
      showModernMutationError,
    );
  };

  handleEventCalendarAdd = () => this.setState({ showCalendarExportWindow: true });

  handleHideAddCalendarWindow = () => this.setState({ showCalendarExportWindow: false });

  renderCalendarExportWindow = () => {
    const {
      name,
      slug,
      token,
      primaryLocation,
      startDate,
      endDate,
      startDateAllDay,
      endDateAllDay,
      tz,
      description,
      website,
    } = this.props.event;
    if (!startDate) return null;

    const icsUrl = `${currentOrigin()}/v1/events/${token}/ical`;
    const googleCalendarProps = {
      name,
      location: stringifyLocation(primaryLocation),
      dates: {
        startDate,
        endDate,
        allDay: !!(startDateAllDay || endDateAllDay),
        tz,
      },
      details: `
          ${name}
          ${currentOrigin()}/events/${slug}
          ${description || ''}
          ${website || ''}
        `,
    };

    if (!document.body) return null;

    return createPortal(
      <ExternalCalendarExport
        listTitle={name}
        icsUrl={icsUrl}
        googleCalendarProps={googleCalendarProps}
        showDownloadOption
        onHide={this.handleHideAddCalendarWindow}
      />,
      document.body,
    );
  };

  render() {
    const { event, userEmail } = this.props;

    const options = [
      {
        label: 'Send to external calendar',
        icon: 'calendar',
        disabled: !event.startDate,
        tooltip: event.startDate ? null : 'This event needs a date first.',
        onClick: this.handleEventCalendarAdd,
      },
      event.viewerCanViewBrief
        ? {
            label: 'View Brief',
            icon: 'eye',
            onClick: this.handleViewBrief,
          }
        : null,
      event.viewerCanViewBrief
        ? {
            label: 'Export Brief (PDF)',
            icon: 'file-pdf-o',
            onClick: this.handlePdfExport,
          }
        : null,
      event.viewerCanViewBrief && event.viewerCanExportBriefDoc
        ? {
            label: 'Export Brief (Google Doc)',
            icon: 'file-text-o',
            onClick: this.handleDocExport,
          }
        : null,
      event.viewerCanDelete
        ? {
            label: 'Delete',
            icon: 'trash',
            onClick: this.handleDeleteClick,
          }
        : null,
    ].filter(Boolean);

    if (options.length === 0) {
      return null;
    }

    return (
      <Column onClick={this.stopPropagation}>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={options}
          overlayContainer={document.body}
        />
        {this.state.showModal &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              onHide={this.handleModalHide}
              onConfirm={this.handleDelete}
              message={`Once ${event.name} is deleted, it's gone for good.`}
            />,
            document.body,
          )}
        {this.state.showCalendarExportWindow && this.renderCalendarExportWindow()}
        {this.state.showDocDownloadOverlay &&
          document.body &&
          createPortal(
            <DocDownloadOverlay
              exportDocUrl={event.exportDocUrl}
              fileName={event.name}
              onHide={this.handleDocExportEnd}
              onDownload={this.handleDocDownload}
            />,
            document.body,
          )}
        {this.state.showPdfDownloadOverlay &&
          document.body &&
          createPortal(
            <DownloadOverlayWithEmail
              downloadLink={event.exportPdfUrl}
              fileName={`${event.name}.pdf`}
              onHide={this.handlePdfDownloadEnd}
              email={userEmail}
              exportable="brief"
              onDownload={this.handlePdfDownload}
            />,
            document.body,
          )}
      </Column>
    );
  }
}

export default createFragmentContainer(
  EventActions,
  graphql`
    fragment EventActions_user on User {
      id
    }
    fragment EventActions_event on Event {
      id
      dbId
      slug
      name
      description
      website
      token
      startDate
      startDateAllDay
      endDate
      endDateAllDay
      tz
      exportPdfUrl
      exportDocUrl
      viewerCanDelete
      viewerCanUpdate
      viewerCanExportBriefDoc
      viewerCanViewBrief
      team {
        id
        dbId
        name
      }
      primaryLocation {
        city
        state
        name
        country
      }
    }
  `,
);
