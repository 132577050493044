/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TextInput from 'components/material/TextInput';

import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';
import inputBoxStyled from '../form/inputBoxStyled';
import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../lib/types';

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

export default class QuestionInput extends React.PureComponent<{
  question: QuestionType,
  value: QuestionValueType,
  onChangeValue: QuestionValueInputType => void,
  readOnly: boolean,
  error: ?string,
}> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeValue({
      textValue: e.currentTarget.value || null,
    });
  };

  render() {
    return (
      <>
        {this.props.question.description && (
          <HelperText>{this.props.question.description}</HelperText>
        )}
        <StyledTextInput
          value={this.props.value.textValue || ''}
          onChange={this.handleChange}
          error={!!this.props.error}
          disabled={this.props.readOnly}
        />
        {this.props.error && <ErrorMessage>{this.props.error}</ErrorMessage>}
      </>
    );
  }
}
