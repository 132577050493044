/* @flow */
import React from 'react';
import styled from 'styled-components';

import { ChangePasswordPanel } from 'components/panels/ChangePasswordPanel';

const Container = styled.div`
  position: relative;
  padding: 0 33px;
  a {
    cursor: pointer;
    color: ${props => props.theme.primaryActionColor};
    &:hover {
      text-decoration: underline;
    }
  }
  .panel {
    width: 100%;
    max-width: 350px;
  }
  .form-text-field {
    width: 100%;
    font-size: 13px;
    margin-bottom: 5px;

    input {
      height: 32px;
    }
  }
`;

export default class ChangePassword extends React.Component<{}> {
  passwordPanel: ChangePasswordPanel;

  showChangePassword = () => {
    this.passwordPanel.show();
  };

  render() {
    return (
      <Container>
        <a onClick={this.showChangePassword}>Change Password</a>

        <ChangePasswordPanel
          ref={ref => {
            this.passwordPanel = ref;
          }}
        />
      </Container>
    );
  }
}
