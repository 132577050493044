/* @flow */
import * as React from 'react';

import TextField from 'components/material/TextField';

type Props = {
  label?: string,
  value?: number,
  onChange?: (SyntheticEvent<HTMLInputElement>, ?number) => void,
  onKeyDown?: (e: SyntheticKeyboardEvent<HTMLInputElement>) => void,
  className?: string,
  autoFocus?: boolean,
  required?: boolean,
  name?: string,
  onClick?: (SyntheticEvent<HTMLInputElement>) => void,
  onFocus?: (SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (SyntheticEvent<HTMLInputElement>, percent: number) => void,
  inputRef?: HTMLInputElement => void,
  readOnly?: boolean,
  disabled?: boolean,
  placeholder?: string,
  minValue: number,
  maxValue: number,
};

type State = {
  focus: boolean,
  value: ?number,
};

export default class PercentField extends React.PureComponent<Props, State> {
  static defaultProps = {
    minValue: 0,
    maxValue: 100,
    fractionDigits: 0,
  };

  state = {
    value: this.props.value != null ? this.props.value : null,
    focus: false,
  };

  getFixedPercentValue = (percent: number) => {
    if (percent < this.props.minValue) {
      return this.props.minValue;
    }
    if (percent > this.props.maxValue) {
      return this.props.maxValue;
    }
    return percent;
  };

  getInputValue = () => {
    if (this.state.focus) {
      return this.state.value == null ? '' : String(this.state.value);
    }
    return `${this.props.value || 0}%`;
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const cleanValue = e.currentTarget.value.replace(/[^0-9.]+/, '');
    this.setState({
      value: cleanValue !== '' ? parseInt(cleanValue, 10) : null,
    });

    if (this.props.onChange) {
      this.props.onChange(e, +cleanValue);
    }
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const floatValue = parseFloat(e.currentTarget.value);
    const percent = this.getFixedPercentValue(Number.isFinite(floatValue) ? floatValue : 0);
    this.setState({
      focus: false,
      value: parseInt(percent, 10),
    });

    if (this.props.onBlur) {
      this.props.onBlur(e, percent);
    }
  };

  handleFocus = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      focus: true,
    });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  render() {
    return (
      <TextField
        name={this.props.name}
        label={this.props.label}
        value={this.getInputValue()}
        onClick={this.props.onClick}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.props.onKeyDown}
        onChange={this.handleChange}
        autoFocus={this.props.autoFocus}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        className={this.props.className}
        placeholder={this.props.placeholder}
        required={this.props.required}
        inputRef={this.props.inputRef}
      />
    );
  }
}
