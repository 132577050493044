/* @flow */
import { GOOGLE_OAUTH2_ID } from 'config/variables';

import appOrigin from 'utils/url/appOrigin';

import parseCallbackUrl from './parseCallbackUrl';
import authPopup from './authPopup';
import googleAuthorizeUrl from './googleAuthorizeUrl';

/**
 * Opens a popup to authenticate google.  Resolves with code or tokenif successful,
 * otherwise rejects if window is closed without authenticating or if google returned an error.
 */
export default function googleAuthPopup(
  responseType: 'code' | 'token',
  scope: string,
): Promise<string> {
  const callbackUrl = `${appOrigin()}/oauth2_popup_callback.html`;
  const connectUrl = googleAuthorizeUrl({
    client_id: GOOGLE_OAUTH2_ID,
    redirect_uri: callbackUrl,
    response_type: responseType,
    scope,
  });

  return authPopup(connectUrl, callbackUrl).then(successUrl => {
    const returnedParams = parseCallbackUrl(successUrl);
    if (returnedParams.code) {
      return returnedParams.code;
    }
    if (returnedParams.access_token) {
      return returnedParams.access_token;
    }
    if (returnedParams.error) {
      return Promise.reject(new Error(returnedParams.error));
    }
    return Promise.reject();
  });
}
