/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Loader from 'components/Loader';

const BaseRow = styled.div`
  margin-top: 10px;
  text-align: left;
`;

const LoadingRow = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled.a`
  margin-left: 25px;
  cursor: pointer;
  color: #39a8da;
  &:hover {
    color: #009dce;
    text-decoration: underline;
  }
`;

type Props = {
  totalCount: number,
  currentCount: number,
  stepSize: number,
  loading: boolean,
  onLoadMore: () => void,
  onShowAllResults?: () => void,
};

const SearchResultPagination = ({
  totalCount,
  currentCount,
  stepSize,
  loading,
  onLoadMore,
  onShowAllResults,
}: Props) => {
  if (totalCount === currentCount) {
    return null;
  }

  if (loading) {
    return (
      <LoadingRow>
        <Loader size={20} />
      </LoadingRow>
    );
  }

  const redirectingToAllResults = onShowAllResults != null && currentCount === 2 * stepSize;

  return (
    <BaseRow>
      <StyledLink onClick={redirectingToAllResults ? onShowAllResults : onLoadMore}>
        {redirectingToAllResults ? 'View all results' : 'Show more'}
      </StyledLink>
    </BaseRow>
  );
};

export default SearchResultPagination;
