/**
 * @flow
 * @relayHash b1a15476fdfb64e31535abd472040f57
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Frequency = "monthly" | "weekly";
export type ScheduledTime = "Friday" | "Monday" | "Saturday" | "Sunday" | "Thursday" | "Tuesday" | "Wednesday" | "end_of_month" | "start_of_month";
export type SavedViewTooltipQueryVariables = {|
  budgetReportingViewId: string
|};
export type SavedViewTooltipQueryResponse = {|
  +budgetReportingView: ?{|
    +exportScheduler?: ?{|
      +frequency: Frequency,
      +scheduledOn: ScheduledTime,
      +recipients: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +email: string,
          |}
        |}>
      |},
    |}
  |}
|};
export type SavedViewTooltipQuery = {|
  variables: SavedViewTooltipQueryVariables,
  response: SavedViewTooltipQueryResponse,
|};
*/


/*
query SavedViewTooltipQuery(
  $budgetReportingViewId: ID!
) {
  budgetReportingView: node(id: $budgetReportingViewId) {
    __typename
    ... on BudgetReportingView {
      exportScheduler {
        frequency
        scheduledOn
        recipients {
          edges {
            node {
              id
              firstName
              lastName
              email
            }
          }
        }
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "budgetReportingViewId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "budgetReportingViewId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "frequency",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scheduledOn",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "recipients",
  "storageKey": null,
  "args": null,
  "concreteType": "UserRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            v4,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SavedViewTooltipQuery",
  "id": null,
  "text": "query SavedViewTooltipQuery(\n  $budgetReportingViewId: ID!\n) {\n  budgetReportingView: node(id: $budgetReportingViewId) {\n    __typename\n    ... on BudgetReportingView {\n      exportScheduler {\n        frequency\n        scheduledOn\n        recipients {\n          edges {\n            node {\n              id\n              firstName\n              lastName\n              email\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SavedViewTooltipQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "budgetReportingView",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "BudgetReportingView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "exportScheduler",
                "storageKey": null,
                "args": null,
                "concreteType": "BudgetReportingScheduledExportView",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SavedViewTooltipQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "budgetReportingView",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v4,
          {
            "kind": "InlineFragment",
            "type": "BudgetReportingView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "exportScheduler",
                "storageKey": null,
                "args": null,
                "concreteType": "BudgetReportingScheduledExportView",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v5,
                  v4
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '33c9306db44d331fa9194cfeeb21930e';
module.exports = node;
