import './app.styl';

import React from 'react';
import ReactDOM from 'react-dom';
import UserStore from 'stores/UserStore';

import { initAnalytics } from 'utils/analytics';
import { isLoggedIn } from 'utils/Auth';
import { initErrorReporting } from 'utils/errorReporting';
import setDefaultTemplate from 'utils/setDefaultTemplate';
import setupBackgroundRefresh from 'utils/setupBackgroundRefresh';

import AppRouter from './AppRouter';

initErrorReporting();
initAnalytics();
setupBackgroundRefresh();
setDefaultTemplate();

// App
const element = document.getElementById('app');
const legacyBrowser = document.getElementById('legacy-browser');

const render = () => {
  ReactDOM.render(<AppRouter />, element);
};

if (!legacyBrowser) {
  if (isLoggedIn()) {
    UserStore.fetch().then(res => {
      if (res.onboarding_step >= 0 && res.onboarding_step < 6) {
        window.location = '/onboarding';
        return;
      }
      render();
    });
  } else {
    render();
  }
} else {
  element.innerHTML = '';
}
