/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation updateEventNoteMutation($input: UpdateEventNoteInput!) {
    updateEventNote(input: $input) {
      eventNote {
        id
        name
        content
        updatedAt
        createdAt
        updater {
          firstName
          lastName
          email
          ...MaterialAvatar_user
        }
      }
    }
  }
`;

export default function updateEventNote(noteId: string, name: string, content: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        noteId,
        name,
        content,
      },
    },
    optimisticResponse: {
      updateEventNote: {
        eventNote: { id: noteId, name, content },
      },
    },
  });
}
