import React from 'react';
import { hot } from 'react-hot-loader';
import { graphql } from 'react-relay';
import { Route, Router } from 'react-router-dom';

import FeatureAccessContext from 'contexts/FeatureAccess';

import { isLoggedIn } from 'utils/Auth';
import setupBrowserHistory from 'utils/setupBrowserHistory';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import AppRoutes from 'views/AppRoutes';

import type { AppRouterQueryResponse } from './__generated__/AppRouterQuery.graphql';

const query = graphql`
  query AppRouterQuery {
    org {
      subscription {
        featureAccess {
          legacyFeatures
        }
      }
    }
  }
`;

class AppRouter extends React.Component {
  renderRouter = () => {
    return (
      <Router history={setupBrowserHistory()}>
        <Route component={AppRoutes} />
      </Router>
    );
  };

  render() {
    if (!isLoggedIn()) {
      return this.renderRouter();
    }

    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(response: AppRouterQueryResponse) => (
          <FeatureAccessContext.Provider value={response.org.subscription.featureAccess}>
            {this.renderRouter()}
          </FeatureAccessContext.Provider>
        )}
        renderLoading={() => null}
      />
    );
  }
}

export default hot(module)(AppRouter);
