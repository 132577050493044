/* @flow */
import React from 'react';
import styled from 'styled-components';

import InfoIcon from 'images/infoIcon.svg';
import CheckBox from 'components/EventRequestForm/form/CheckBox';
import type { FormDetailsType } from 'components/EventRequestForm/lib/types';
import Tooltip from 'components/material/Tooltip';
import TemplateSearch, { type TemplateType } from 'components/TemplateSearch';

const SectionWrapper = styled.div`
  width: 100%;
  margin: 30px 0;
`;

const Row = styled.div`
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  color: rgba(74, 86, 101, 0.87);
  font-weight: 500;
`;

const Subtitle = styled.div`
  margin-bottom: 17px;
  color: rgba(74, 86, 101, 0.87);
`;

const StyledTooltip = styled(Tooltip)`
  display: inline-block;
  margin-left: 5px;
  color: #4a5665;
`;

const TimelineWarning = styled.div`
  font-size: 11px;
  font-weight: 400;
  font-style: italic;
  color: ${props => props.theme.mutedTextColor};
  margin-top: 3px;
  line-height: 1.3;
`;

const ChecklistTemplateSettings = (props: {
  requestForm: FormDetailsType,
  onChangeTemplate: (template: ?TemplateType) => void,
  onToggleCopyTimeline: () => void,
}) => {
  const { requestForm, onChangeTemplate, onToggleCopyTimeline } = props;
  const dueDateDisabled =
    requestForm.eventTemplate == null || requestForm.eventTemplate.startDate == null;

  return (
    <SectionWrapper>
      <Row>
        <Title>Checklist Template</Title>
        <Subtitle>
          Automatically copy the event checklist from a template, when approving requests.
          <StyledTooltip label="Reviewers will still be able to adjust this default setting when approving requests">
            <InfoIcon />
          </StyledTooltip>
        </Subtitle>
        <TemplateSearch
          placeholder="Checklist template"
          event={requestForm.eventTemplate}
          onSelect={onChangeTemplate}
          searchable
          clearable
        />
      </Row>
      {requestForm.eventTemplate != null && (
        <>
          <Row>
            <CheckBox
              compact
              checked={requestForm.copyTimeline}
              label="Set due dates based on event start date"
              onChange={onToggleCopyTimeline}
              disabled={dueDateDisabled}
            />
          </Row>
          {dueDateDisabled && (
            <Row>
              <TimelineWarning>
                The template you selected doesn&apos;t have a start date, which is necessary to
                anchor the timeline.
              </TimelineWarning>
            </Row>
          )}
        </>
      )}
    </SectionWrapper>
  );
};

export default ChecklistTemplateSettings;
