/* @flow */

import { type FilterItem, getKind } from 'components/material/Filters/FilterSelectorRow';

type CustomErrorMessages = $Shape<{
  field: string,
  value: string,
}>;

const validateFilterItem = (
  filterItem: FilterItem,
  errors?: CustomErrorMessages,
): { value?: ?string, field?: string } => {
  if (['is_null', 'is_not_null'].includes(filterItem.operator)) {
    return {};
  }

  const kind = getKind(filterItem);
  switch (kind) {
    case 'TEXT':
    case 'TEXTAREA':
    case 'LINK':
      return { value: !filterItem.values || !filterItem.values[0] ? 'Value is required' : null };
    case 'BOOLEAN':
      return {};
    case 'NUMBER':
    case 'CURRENCY':
      return {
        value:
          filterItem.minValue == null && filterItem.maxValue == null
            ? 'Either minimum or maximum value is required'
            : null,
      };
    case 'DATE':
      return {
        value:
          filterItem.minValue != null || filterItem.maxValue != null
            ? null
            : 'Date range is required',
      };
    case 'SELECT':
      if (filterItem.optionIds && filterItem.optionIds.length > 0) {
        return {};
      }

      return {
        value: ['equal', 'not_equal'].includes(filterItem.operator)
          ? 'Value is required'
          : 'At least one value is required',
      };
    case 'USER_SELECT':
      if (filterItem.users && filterItem.users.length > 0) {
        return {};
      }

      return {
        value: ['equal', 'not_equal'].includes(filterItem.operator)
          ? 'Member is required'
          : 'At least one member is required',
      };
    case 'MULTISELECT':
      return {
        value:
          filterItem.optionIds && filterItem.optionIds.length > 0
            ? null
            : 'At least one value is required',
      };
    case 'USER_MULTISELECT':
      return {
        value:
          filterItem.users && filterItem.users.length > 0
            ? null
            : 'At least one member is required',
      };
    default:
      return { field: errors && errors.field ? errors.field : 'Field is required' };
  }
};

export default validateFilterItem;
