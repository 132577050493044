/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import { type StaffAccessLevel } from 'config/staffAccessLevels';

import AccessTypeSelector, { type AccessType } from './AccessTypeSelector';
import { type User } from './index';
import { type InviteFields } from './InviteList';
import OrgUserSearch from './OrgUserSearch';
import UserSelectionRow from './SelectionRow/UserSelectionRow';

const AccessRow = styled.div`
  width: 50%;
`;

const UserSelectionContainer = styled.div`
  width: 70%;
`;

const SelectionList = styled.div`
  margin-top: 25px;
`;

export default class InviteToEventContents extends React.Component<{
  accessType: AccessType,
  onAccessTypeChange: (accessType: ?AccessType) => void,
  skipStaffAccessLevel?: StaffAccessLevel,
  invites: $ReadOnlyArray<InviteFields>,
  onSelectUser: (user: InviteFields) => void,
  onUnselectUser: (uid: string) => void,
  onAddNewMember?: () => void,
  onToggleQueryState?: (present: boolean) => void,
}> {
  handleUserSelect = (user: User) => {
    if (this.props.invites.find(invite => invite.uid === user.id) == null) {
      this.props.onSelectUser({ ...user, uid: user.id });
    }
  };

  render() {
    return (
      <React.Fragment>
        <AccessRow>
          <AccessTypeSelector
            value={this.props.accessType}
            onChange={this.props.onAccessTypeChange}
            scope="EVENT"
            skipStaffAccessLevel={this.props.skipStaffAccessLevel}
          />
        </AccessRow>
        <UserSelectionContainer>
          <OrgUserSearch
            label="Search for Members"
            onSelect={this.handleUserSelect}
            onAddNewMember={this.props.onAddNewMember}
            onToggleQueryState={this.props.onToggleQueryState}
          />
        </UserSelectionContainer>
        {this.props.invites.length > 0 && (
          <SelectionList>
            {this.props.invites.map(invite => (
              <UserSelectionRow
                key={invite.uid}
                invite={invite}
                onRemove={this.props.onUnselectUser}
              />
            ))}
          </SelectionList>
        )}
      </React.Fragment>
    );
  }
}
