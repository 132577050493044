/**
 * @flow
 * @relayHash cb2bd6d58a6abf5c44c52be8e508811f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCompanyToBriefInput = {
  clientMutationId?: ?string,
  eventId: string,
  companyId: string,
};
export type addCompanyToBriefMutationVariables = {|
  input: AddCompanyToBriefInput
|};
export type addCompanyToBriefMutationResponse = {|
  +addCompanyToBrief: ?{|
    +companyEdge: {|
      +__typename: string,
      +node: {|
        +id: string
      |},
    |}
  |}
|};
export type addCompanyToBriefMutation = {|
  variables: addCompanyToBriefMutationVariables,
  response: addCompanyToBriefMutationResponse,
|};
*/


/*
mutation addCompanyToBriefMutation(
  $input: AddCompanyToBriefInput!
) {
  addCompanyToBrief(input: $input) {
    companyEdge {
      __typename
      node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddCompanyToBriefInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addCompanyToBrief",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddCompanyToBriefInput!"
      }
    ],
    "concreteType": "AddCompanyToBriefPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "companyEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "CompanyRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addCompanyToBriefMutation",
  "id": null,
  "text": "mutation addCompanyToBriefMutation(\n  $input: AddCompanyToBriefInput!\n) {\n  addCompanyToBrief(input: $input) {\n    companyEdge {\n      __typename\n      node {\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addCompanyToBriefMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "addCompanyToBriefMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2acaebc5ad6f73984a221fe316c2eac';
module.exports = node;
