/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserContactFilterContainer_query$ref: FragmentReference;
export type UserContactFilterContainer_query = {|
  +searchUsers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +email: string,
        +firstName: string,
        +lastName: string,
        +$fragmentRefs: MaterialAvatar_user$ref,
      |}
    |}>
  |},
  +selectedUsers: $ReadOnlyArray<?{|
    +id: string,
    +email?: string,
    +firstName?: string,
    +lastName?: string,
    +$fragmentRefs: MaterialAvatar_user$ref,
  |}>,
  +org: {|
    +contacts: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +email: string,
          +firstName: string,
          +lastName: string,
          +$fragmentRefs: MaterialAvatar_user$ref,
        |}
      |}>
    |}
  |},
  +selectedContacts: $ReadOnlyArray<?{|
    +id: string,
    +email?: string,
    +firstName?: string,
    +lastName?: string,
    +$fragmentRefs: MaterialAvatar_user$ref,
  |}>,
  +$refType: UserContactFilterContainer_query$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 15,
  "type": "Int"
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "FragmentSpread",
  "name": "MaterialAvatar_user",
  "args": null
},
v6 = [
  v1,
  v2,
  v3,
  v4,
  v5
],
v7 = [
  v2,
  v3,
  v4,
  v5
];
return {
  "kind": "Fragment",
  "name": "UserContactFilterContainer_query",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "query",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "userIds",
      "type": "[ID!]!"
    },
    {
      "kind": "RootArgument",
      "name": "contactFilters",
      "type": "ContactFilters"
    },
    {
      "kind": "RootArgument",
      "name": "contactIds",
      "type": "[ID!]!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "searchUsers",
      "storageKey": null,
      "args": [
        v0,
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query",
          "type": "String"
        }
      ],
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": false,
              "selections": v6
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "selectedUsers",
      "name": "nodes",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "userIds",
          "type": "[ID!]!"
        }
      ],
      "concreteType": null,
      "plural": true,
      "selections": [
        v1,
        {
          "kind": "InlineFragment",
          "type": "User",
          "selections": v7
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "org",
      "storageKey": null,
      "args": null,
      "concreteType": "Org",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contacts",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filters",
              "variableName": "contactFilters",
              "type": "ContactFilters"
            },
            v0
          ],
          "concreteType": "ContactRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ContactRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Contact",
                  "plural": false,
                  "selections": v6
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "selectedContacts",
      "name": "nodes",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "contactIds",
          "type": "[ID!]!"
        }
      ],
      "concreteType": null,
      "plural": true,
      "selections": [
        v1,
        {
          "kind": "InlineFragment",
          "type": "Contact",
          "selections": v7
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f8a5c217e7464eb7c2b1dee1f46ff98';
module.exports = node;
