/* @flow */
import type { FieldType } from 'utils/customization/types';

export default function getCompanyFields(
  customFields: $ReadOnlyArray<FieldType>,
): $ReadOnlyArray<FieldType> {
  return [
    {
      id: 'name',
      label: 'Name',
      fieldName: 'name',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    ...customFields,
    {
      id: 'contacts_count',
      label: 'Contacts',
      fieldName: 'contacts_count',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      fieldName: 'updated_at',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'updated_by',
      label: 'Updated By',
      fieldName: 'updated_by',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'created_at',
      label: 'Created At',
      fieldName: 'created_at',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'created_by',
      label: 'Created By',
      fieldName: 'created_by',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
  ];
}
