/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import { type CircaFile } from 'utils/uploading/types';

import RichText, { type SupportInlineImage, EditorContainer, Actions } from 'components/RichText';
import ImageUploadingStatus, { type Status } from 'components/RichText/Image/ImageUploadingStatus';
import Tooltip from 'components/material/Tooltip';
import AttachmentPreview from 'components/RelayAttachments/AttachmentPreview';
import { Label } from 'components/material/TextField';

const EmptyState = styled.div`
  min-height: 32px;
  font-size: 13px;
  padding: 4px 55px 10px 0;
  position: relative;
  transition: box-shadow 0.3s;
  cursor: pointer;
  color: ${props => props.theme.mutedTextColor};
`;

const StyledRichText = styled(RichText)`
  margin-top: 5px;

  ${EditorContainer} {
    margin-left: 0;
    padding: 8px 25px 0 0;

    &:hover {
      background: none;
    }
  }

  ${Actions} {
    display: none;
  }
`;

const RichTextContainer = styled.div`
  ${props =>
    !props.editing &&
    css`
      border-bottom: 1px solid ${props.theme.borderColor};
      transition: border 0.3s;
    `}

  &:hover {
    border-bottom-color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const DescTooltip = styled(Tooltip)`
  display: inline-block;
  font-size: 12px;
  line-height: 1.17;
  margin-left: 5px;
  color: ${props => props.theme.secondaryActionColor};
`;

export type FocusWithTab = {
  focused: boolean,
  shouldGetFocus: boolean,
};

export type SupportImage = {
  onImageAdd: CircaFile => void,
  images: $ReadOnlyArray<CircaFile>,
  noUploadPreview?: boolean,
  onRemoveImages: ($ReadOnlyArray<CircaFile>) => void,
  onStartEditing: () => void,
};

export default class InlineRichText extends React.Component<
  {
    value: ?string,
    label: string,
    onChange: (?string, ?$ReadOnlyArray<CircaFile>) => void,
    disabled?: boolean,
    info?: ?string,
    focusWithTab?: FocusWithTab,
    supportImage?: ?SupportImage,
  },
  {
    editing: boolean,
    previewId: string,
    images: $ReadOnlyArray<CircaFile>,
    imageUploadingStatus: Status,
  },
> {
  supportInlineImage: ?SupportInlineImage;

  timeout: TimeoutID;

  constructor(props: $PropertyType<InlineRichText, 'props'>) {
    super(props);
    this.supportInlineImage = this.constructRichSupportInlineImage();
    this.state = {
      editing: false,
      previewId: '',
      images: this.props.supportImage ? this.props.supportImage.images : [],
      imageUploadingStatus: { isError: false, text: '' },
    };
  }

  static getDerivedStateFromProps(
    props: $PropertyType<InlineRichText, 'props'>,
    state: $PropertyType<InlineRichText, 'state'>,
  ) {
    if (props.focusWithTab && props.focusWithTab.focused && !state.editing) {
      return { editing: true };
    }
    return null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleEdit = () => {
    if (!this.props.disabled) {
      this.setState({ editing: true }, () => {
        this.timeout = setTimeout(() => {
          if (this.props.supportImage) {
            this.props.supportImage.onStartEditing();
          }
        }, 0);
      });
    }
  };

  handleCancel = () => {
    this.setState({ editing: false });
  };

  handleImageAdd = (file: CircaFile) => {
    this.setState(prevState => ({
      images: [...prevState.images, { id: file.fileurl, ...file }],
    }));

    if (this.props.supportImage != null) {
      this.props.supportImage.onImageAdd(file);
    }
  };

  handlePreview = (previewId: string) => {
    this.setState({ previewId });
  };

  handleStatusChange = (text: string, isError: boolean = false) => {
    const imageUploadingStatus = { text, isError };
    this.setState({ imageUploadingStatus });
  };

  handleSave = (content: ?string, editorImages: ?$ReadOnlyArray<string>) => {
    const attachments = this.state.images.filter(
      attachment => !editorImages || editorImages.includes(attachment.fileurl),
    );
    const removableAttachments = this.state.images.filter(
      attachment => editorImages && !editorImages.includes(attachment.fileurl),
    );

    if (this.props.supportImage != null) {
      this.props.supportImage.onRemoveImages(removableAttachments);
    }
    this.props.onChange(content === '\n' ? null : content, attachments);
  };

  constructRichSupportInlineImage = (): ?SupportInlineImage => {
    return this.props.supportImage
      ? {
          onImageAdd: this.handleImageAdd,
          onImagePreview: this.handlePreview,
          setImageUploadingStatus: this.handleStatusChange,
          noUploadPreview: this.props.supportImage.noUploadPreview,
        }
      : undefined;
  };

  render() {
    const showLabel = this.props.value || this.state.editing || this.props.disabled || undefined;
    return (
      <div>
        <Label data-collapsed={showLabel}>{this.props.label}</Label>
        <RichTextContainer editing={this.state.editing}>
          {this.props.value || this.state.editing ? (
            <StyledRichText
              defaultValue={this.props.value}
              autoFocus
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              onStartEditing={!this.props.disabled ? this.handleEdit : null}
              disabled={this.props.disabled}
              viewMode={!this.state.editing}
              shouldGetFocus={this.props.focusWithTab && this.props.focusWithTab.shouldGetFocus}
              supportInlineImage={this.supportInlineImage}
            />
          ) : (
            <EmptyState onClick={this.handleEdit}>
              {this.props.info && (
                <DescTooltip label={this.props.info} placement="bottom">
                  <i className="fa fa-info-circle" />
                </DescTooltip>
              )}
            </EmptyState>
          )}
        </RichTextContainer>
        {!this.props.disabled && this.props.supportImage && (
          <ImageUploadingStatus status={this.state.imageUploadingStatus} />
        )}
        {this.state.previewId && (
          <AttachmentPreview
            attachments={[]}
            mockAttachments={this.state.images}
            previewId={this.state.previewId}
            onPreview={this.handlePreview}
            onRemove={null}
          />
        )}
      </div>
    );
  }
}
