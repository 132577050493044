/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import personalizationDefaults from 'config/personalizationDefaults';

import { isBright } from 'utils/color';

import { TransparentColorOption } from 'images';

import ColorPicker, { type PopupPlacement } from '.';
import getPresetColors from './getPresetColors';

import type { ColorSelector_orgSettings } from './__generated__/ColorSelector_orgSettings.graphql';

const Container = styled.div`
  display: flex;
`;

const PickerWrapper = styled.div`
  flex: 1 auto;
`;

const Swatch = styled.div`
  width: 26px;
  height: 26px;
  background-color: ${props => props.color};
  border-radius: 26px;
  cursor: pointer;
  ${props =>
    props.highlight &&
    css`
      border: 1px solid #d9ddf5;
    `};
`;

const StyledTransparentColorOption = styled(TransparentColorOption)`
  width: 100%;
  height: 100%;
  border-radius: 26px;
`;

const StyledColorPicker = styled(ColorPicker)`
  position: relative;
  left: auto;
  right: 0;
`;

class ColorSelector extends React.PureComponent<
  {
    orgSettings: ColorSelector_orgSettings,
    currentColor: ?string,
    onChangeColor: (color: string) => void,
    flagClass?: string,
    alignTo?: PopupPlacement,
    includeTransparent?: boolean,
  },
  { lastCustomColor: ?string },
> {
  renderSwatch = (clickHandler: () => void) => {
    const { currentColor, orgSettings } = this.props;
    const color = currentColor || orgSettings.primaryColor;
    const highlightColor = isBright(color);
    return (
      <Swatch onClick={clickHandler} highlight={highlightColor} color={color}>
        {color === 'transparent' && <StyledTransparentColorOption />}
      </Swatch>
    );
  };

  render() {
    const {
      currentColor,
      flagClass,
      orgSettings,
      alignTo,
      onChangeColor,
      includeTransparent,
    } = this.props;
    const presetColors = getPresetColors({
      settings: orgSettings,
      includeTransparent,
    });
    return (
      <Container>
        <PickerWrapper>
          <StyledColorPicker
            color={currentColor || personalizationDefaults.eventListBgColor}
            renderSwatch={this.renderSwatch}
            onChangeComplete={onChangeColor}
            flagClass={flagClass}
            presetColors={presetColors}
            alignTo={alignTo}
          />
        </PickerWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(
  ColorSelector,
  graphql`
    fragment ColorSelector_orgSettings on OrgSettings {
      primaryColor
      secondaryColor
    }
  `,
);
