/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import ExternalLinkIcon from 'images/external.svg';
import Divider from 'components/Sidebar/Divider';
import Footer from 'components/Sidebar/Footer';
import Header from 'components/Sidebar/Header';
import MenuItem from 'components/Sidebar/MenuItem';

import type { ContactsMenu_org } from './__generated__/ContactsMenu_org.graphql';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  svg {
    margin-left: 8px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  svg {
    margin-left: 8px;
  }
  &:hover {
    svg {
      path:not(:first-of-type) {
        fill: #000;
      }
    }
  }
`;

const Title = styled.div`
  margin-bottom: 25px;
  margin-left: 9px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
`;

type Props = { org: ContactsMenu_org, pathPrefix: string };

const ContactsMenu = ({ org, pathPrefix }: Props) => {
  const { viewerCanUpdate } = org;
  return (
    <Menu>
      <Header>
        <Title>Contacts</Title>
        <MenuItem to={`${pathPrefix}/people`}>Contacts</MenuItem>
        <MenuItem to={`${pathPrefix}/companies`}>Companies</MenuItem>
        <MenuItem to={`${pathPrefix}/vendors`}>Vendors</MenuItem>
      </Header>
      {viewerCanUpdate && (
        <>
          <Divider />
          <Footer>
            <StyledMenuItem to="/settings/contacts/people" isActive={() => false}>
              Contact settings
              <ExternalLinkIcon />
            </StyledMenuItem>
          </Footer>
        </>
      )}
    </Menu>
  );
};

export default createFragmentContainer(
  ContactsMenu,
  graphql`
    fragment ContactsMenu_org on Org {
      viewerCanUpdate
    }
  `,
);
