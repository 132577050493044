/* @flow */
import * as React from 'react';

import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import isValidWebsite from 'utils/validators/isValidWebsite';

import type { QuestionType, QuestionValueType, QuestionValueInputType } from '../lib/types';
import LinkField from '../form/LinkField';
import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';

export default class QuestionLink extends React.PureComponent<{
  question: QuestionType,
  value: QuestionValueType,
  onChangeValue: QuestionValueInputType => void,
  readOnly: boolean,
  error: ?string,
  onChangeShowError: (showError: boolean) => void,
}> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeValue({ textValue: e.currentTarget.value || null });
    this.props.onChangeShowError(false);
  };

  handleBlur = () => {
    this.props.onChangeShowError(true);

    const httpValue = enforceHttpPrefix(this.props.value.textValue || '');

    if (isValidWebsite(httpValue) && httpValue !== this.props.value.textValue) {
      this.props.onChangeValue({ textValue: httpValue });
    }
  };

  render() {
    return (
      <>
        {this.props.question.description && (
          <HelperText>{this.props.question.description}</HelperText>
        )}
        <LinkField
          value={this.props.value.textValue || ''}
          error={!!this.props.error}
          onChange={this.handleChange}
          disabled={this.props.readOnly}
          onBlur={this.handleBlur}
        />
        {this.props.error && <ErrorMessage>{this.props.error}</ErrorMessage>}
      </>
    );
  }
}
