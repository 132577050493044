/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import publicRuleNames from 'config/publicRuleNames';

import FeatureAccessContext from 'contexts/FeatureAccess';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotFound from 'components/NotFound';

import RegistrationFormSubmission from './Main/Event/Registration/RegistrationFormSubmission';
import SharedEventList from './SharedEventList';

import type { PublicResourceRoutesResponse } from './__generated__/PublicResourceRoutesQuery.graphql';

const query = graphql`
  query PublicResourceRoutesQuery(
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
  ) {
    publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
    publicResourceToken(token: $resourceToken) {
      id
      shareableType
    }
    org {
      subscription {
        featureAccess {
          legacyFeatures
        }
      }
    }
  }
`;

export default class PublicResourceRoutes extends React.Component<{
  match: Match,
  history: RouterHistory,
  location: Location,
}> {
  renderShareableResource(shareableType: string) {
    const { match, history, location } = this.props;
    switch (shareableType) {
      case 'Registration::Form':
        return <RegistrationFormSubmission match={match} location={location} />;
      case 'EventList':
        return <SharedEventList history={history} match={match} location={location} />;
      default:
        return <NotFound />;
    }
  }

  render() {
    const { resourceToken, orgName } = this.props.match.params;

    if (!resourceToken) return <NotFound />;

    return (
      <DefaultQueryRenderer
        query={query}
        public
        variables={{
          publicRuleName: publicRuleNames.PUBLIC_RESOURCE_TOKEN,
          resourceToken,
          orgSlug: orgName,
        }}
        renderSuccess={({ publicResourceToken, org }: PublicResourceRoutesResponse) => (
          <FeatureAccessContext.Provider value={org.subscription.featureAccess}>
            {this.renderShareableResource(publicResourceToken.shareableType)}
          </FeatureAccessContext.Provider>
        )}
        renderLoading={() => null}
        renderError={() => <NotFound />}
      />
    );
  }
}
