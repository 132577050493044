/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  font-size: 13px;
  font-weight: 500;
  color: #828b93;
  cursor: pointer;
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: underline;
  }
`;

export default class MinimalButton extends React.Component<{
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  disabled?: boolean,
  label: string | React.Node,
  className?: string,
}> {
  render() {
    const { label, onClick, disabled, className } = this.props;
    return (
      <StyledButton onClick={onClick} disabled={disabled} className={className}>
        {label}
      </StyledButton>
    );
  }
}
