/* @flow */
import { type Field } from 'components/FieldSettings/types';

const eventFields: $ReadOnlyArray<Field> = [
  'Event Name',
  'Status',
  'Progress',
  'Leader',
  'Location',
  'Description',
].map(label => ({
  id: label.toLowerCase().replace(' ', '_'),
  kind: 'TEXT',
  label,
  fieldName: null,
  required: true,
  // showInCreateForm and showInMobileForm will be used in future.
  showInCreateForm: true,
  showInMobileForm: true,
  enabled: true,
  order: -1,
  isDefault: true,
  isSuggested: false,
  options: [],
}));

export default eventFields;
