/* @flow */
import React from 'react';

import { Content } from 'components/page/Content';

import CustomFieldSettingsQuery from './CustomFieldSettingsQuery';

export default class VendorSettings extends React.Component<{}> {
  render() {
    return (
      <Content>
        <CustomFieldSettingsQuery
          filters={{
            customizableType: ['VENDOR'],
            includeDisabled: true,
            includeRestrictManagingOptions: false,
          }}
          customizableType="VENDOR"
        />
      </Content>
    );
  }
}
