/**
 * @flow
 * @relayHash 2c780a40450338170c5b84744f28a09d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditableQuestionSelectOptionsQueryVariables = {|
  customFieldIds: $ReadOnlyArray<string>
|};
export type EditableQuestionSelectOptionsQueryResponse = {|
  +org: {|
    +customFields: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +options: {|
            +edges: $ReadOnlyArray<{|
              +node: {|
                +id: string,
                +name: string,
              |}
            |}>
          |}
        |}
      |}>
    |}
  |}
|};
export type EditableQuestionSelectOptionsQuery = {|
  variables: EditableQuestionSelectOptionsQueryVariables,
  response: EditableQuestionSelectOptionsQueryResponse,
|};
*/


/*
query EditableQuestionSelectOptionsQuery(
  $customFieldIds: [ID!]!
) {
  org {
    customFields(customizableType: [EVENT], ids: $customFieldIds, includeDisabled: true) {
      edges {
        node {
          options {
            edges {
              node {
                id
                name
              }
            }
          }
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "customFieldIds",
    "type": "[ID!]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "customizableType",
    "value": [
      "EVENT"
    ],
    "type": "[CustomizableType!]!"
  },
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "customFieldIds",
    "type": "[ID!]"
  },
  {
    "kind": "Literal",
    "name": "includeDisabled",
    "value": true,
    "type": "Boolean"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "options",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldOptionTypeRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldOptionTypeRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldOptionType",
          "plural": false,
          "selections": [
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditableQuestionSelectOptionsQuery",
  "id": null,
  "text": "query EditableQuestionSelectOptionsQuery(\n  $customFieldIds: [ID!]!\n) {\n  org {\n    customFields(customizableType: [EVENT], ids: $customFieldIds, includeDisabled: true) {\n      edges {\n        node {\n          options {\n            edges {\n              node {\n                id\n                name\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditableQuestionSelectOptionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": null,
            "args": v1,
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v3
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditableQuestionSelectOptionsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": null,
            "args": v1,
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v3,
                      v2
                    ]
                  }
                ]
              }
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b0ecdab352469d70d48135bbdb751145';
module.exports = node;
