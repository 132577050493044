/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactsMenu_org$ref = any;
type DashboardMenu_org$ref = any;
type Navbar_org$ref = any;
type ReportingMenu_org$ref = any;
type SettingsMenu_org$ref = any;
type VendorsMenu_org$ref = any;
type WorkspaceMenu_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MainContent_org$ref: FragmentReference;
export type MainContent_org = {|
  +viewerCanUpdate: boolean,
  +viewerCanCreateContacts: boolean,
  +viewerCanCreateVendors: boolean,
  +viewerCanViewWorkspace: boolean,
  +viewerCanViewReporting: boolean,
  +subscription: {|
    +upgradeRequired: boolean
  |},
  +$fragmentRefs: Navbar_org$ref & DashboardMenu_org$ref & WorkspaceMenu_org$ref & ReportingMenu_org$ref & SettingsMenu_org$ref & ContactsMenu_org$ref & VendorsMenu_org$ref,
  +$refType: MainContent_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MainContent_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanCreateContacts",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanCreateVendors",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanViewWorkspace",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanViewReporting",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subscription",
      "storageKey": null,
      "args": null,
      "concreteType": "Subscription",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "upgradeRequired",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "Navbar_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DashboardMenu_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "WorkspaceMenu_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ReportingMenu_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SettingsMenu_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactsMenu_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VendorsMenu_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a919889d1ac91e445cc3a844355a3b1c';
module.exports = node;
