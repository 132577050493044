/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import updateEventContact from 'graph/mutations/event_contact/updateEventContact';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import type { EventRegistrationStatus_contact } from './__generated__/EventRegistrationStatus_contact.graphql';
import type { EventRegistrationStatus_contactEvent } from './__generated__/EventRegistrationStatus_contactEvent.graphql';

class EventRegistrationStatus extends React.Component<{
  contactEvent: EventRegistrationStatus_contactEvent,
  contact: EventRegistrationStatus_contact,
  fieldSettings?: FieldType,
  updateColumnWidth: () => void,
}> {
  updateRegistrationStatus = registrationStatusId => {
    updateEventContact({
      eventId: this.props.contactEvent.node.id,
      contactId: this.props.contact.id,
      registrationStatusId,
    })
      .then(() => {
        this.props.updateColumnWidth();
      })
      .catch(showModernMutationError);
  };

  render() {
    const { fieldSettings, contactEvent } = this.props;
    return (
      <InlineSelectField
        options={fieldSettings ? fieldSettings.options : []}
        value={contactEvent.registrationStatus && contactEvent.registrationStatus.id}
        placeholder="Select Registration Status"
        onChange={this.updateRegistrationStatus}
        clearable={!!fieldSettings && !fieldSettings.required}
      />
    );
  }
}

export default createFragmentContainer(EventRegistrationStatus, {
  contactEvent: graphql`
    fragment EventRegistrationStatus_contactEvent on ContactEventEdge {
      registrationStatus {
        id
        name
      }
      node {
        id
        name
      }
    }
  `,
  contact: graphql`
    fragment EventRegistrationStatus_contact on Contact {
      id
    }
  `,
});
