/* @flow */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';
import fullNameOfUser from 'utils/fullNameOfUser';

const Container = styled.div`
  display: flex;
  flex: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  &:first-child {
    margin-right: 35px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 0 auto;
  flex-wrap: wrap;
  margin-right: 3px;
  align-items: center;
`;

const Label = styled.div`
  flex: 0 auto;
  margin-right: 10px;
  font-size: 12px;
  color: #828b93;
`;

const DateInfo = styled.div`
  flex: 0 auto;
  font-size: 12px;
  color: #3e4859;
`;

const Prefix = styled.div`
  flex: 0 auto;
  margin-right: 3px;
  font-size: 12px;
  color: #3e4859;
`;

const UserName = styled.div`
  flex: 0 auto;
  font-size: 12px;
  color: #3e4859;
  font-weight: 500;
`;

const UserNameDeleted = styled.div`
  flex: 0 auto;
  font-size: 12px;
  color: #828b93;
  font-weight: 500;
`;

type User = {
  firstName: string,
  lastName: ?string,
  email: string,
};

const EditInfo = (props: {
  date: string,
  user?: ?User,
  label: string,
  timezone: string,
  className?: string,
}) => {
  const { date, user, label, className, timezone } = props;
  const dateFormated = formatDateTime(moment(date).tz(timezone));
  return (
    <Container className={className}>
      <InfoContainer>
        <Label>{label}:</Label>
        <DateInfo>{dateFormated}</DateInfo>
      </InfoContainer>
      <InfoContainer>
        <Prefix>by</Prefix>
        {user ? (
          <UserName>{fullNameOfUser(user)}</UserName>
        ) : (
          <UserNameDeleted>Deleted user</UserNameDeleted>
        )}
      </InfoContainer>
    </Container>
  );
};

export default EditInfo;
