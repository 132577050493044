/**
 * @flow
 * @relayHash 23acc2e0241ccd5898d9055a41cfa919
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamBudgeting_budgetPeriod$ref = any;
export type SaveTeamBudgetPeriodInput = {
  clientMutationId?: ?string,
  year: number,
  quarter: number,
  teamId: string,
  amount: number,
};
export type saveTeamBudgetPeriodMutationVariables = {|
  input: SaveTeamBudgetPeriodInput
|};
export type saveTeamBudgetPeriodMutationResponse = {|
  +saveTeamBudgetPeriod: ?{|
    +budgetPeriodEdge: {|
      +__typename: string,
      +node: ?{|
        +id: string,
        +period: string,
        +year: number,
        +teams: {|
          +edges: $ReadOnlyArray<{|
            +amount: number,
            +node: {|
              +id: string,
              +name: string,
            |},
          |}>
        |},
        +$fragmentRefs: TeamBudgeting_budgetPeriod$ref,
      |},
    |}
  |}
|};
export type saveTeamBudgetPeriodMutation = {|
  variables: saveTeamBudgetPeriodMutationVariables,
  response: saveTeamBudgetPeriodMutationResponse,
|};
*/


/*
mutation saveTeamBudgetPeriodMutation(
  $input: SaveTeamBudgetPeriodInput!
) {
  saveTeamBudgetPeriod(input: $input) {
    budgetPeriodEdge {
      __typename
      node {
        id
        period
        year
        ...TeamBudgeting_budgetPeriod
        teams {
          edges {
            amount
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}

fragment TeamBudgeting_budgetPeriod on BudgetPeriod {
  id
  period
  startDate
  endDate
  teams {
    edges {
      amount
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SaveTeamBudgetPeriodInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "SaveTeamBudgetPeriodInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "period",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "year",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "teams",
  "storageKey": null,
  "args": null,
  "concreteType": "BudgetPeriodTeamConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetPeriodTeamEdge",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "amount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Team",
          "plural": false,
          "selections": [
            v3,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "saveTeamBudgetPeriodMutation",
  "id": null,
  "text": "mutation saveTeamBudgetPeriodMutation(\n  $input: SaveTeamBudgetPeriodInput!\n) {\n  saveTeamBudgetPeriod(input: $input) {\n    budgetPeriodEdge {\n      __typename\n      node {\n        id\n        period\n        year\n        ...TeamBudgeting_budgetPeriod\n        teams {\n          edges {\n            amount\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment TeamBudgeting_budgetPeriod on BudgetPeriod {\n  id\n  period\n  startDate\n  endDate\n  teams {\n    edges {\n      amount\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "saveTeamBudgetPeriodMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveTeamBudgetPeriod",
        "storageKey": null,
        "args": v1,
        "concreteType": "SaveTeamBudgetPeriodPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetPeriodEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetPeriodEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "BudgetPeriod",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "FragmentSpread",
                    "name": "TeamBudgeting_budgetPeriod",
                    "args": null
                  },
                  v6
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "saveTeamBudgetPeriodMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveTeamBudgetPeriod",
        "storageKey": null,
        "args": v1,
        "concreteType": "SaveTeamBudgetPeriodPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetPeriodEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetPeriodEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "BudgetPeriod",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endDate",
                    "args": null,
                    "storageKey": null
                  },
                  v6
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9cf9c1568db4ef3fae23d032195dfdd';
module.exports = node;
