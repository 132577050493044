/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SubtaskItem_subtask$ref = any;
type TaskItemAssignee_user$ref = any;
export type TaskStatus = "COMPLETED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefTaskItem_task$ref: FragmentReference;
export type BriefTaskItem_task = {|
  +name?: string,
  +slug?: string,
  +status?: TaskStatus,
  +dueDate?: ?any,
  +dueDateAllDay?: ?boolean,
  +assignees?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: TaskItemAssignee_user$ref,
      |}
    |}>
  |},
  +subtasks?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +order: number,
        +$fragmentRefs: SubtaskItem_subtask$ref,
      |}
    |}>
  |},
  +$refType: BriefTaskItem_task$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "BriefTaskItem_task",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeTaskTitle",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTaskLink",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTaskStatus",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTaskDueDate",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTaskAssignees",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTaskSubtasks",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskTitle",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskLink",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "slug",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskStatus",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskDueDate",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "dueDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "dueDateAllDay",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskAssignees",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "assignees",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "UserRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "FragmentSpread",
                      "name": "TaskItemAssignee_user",
                      "args": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskSubtasks",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "subtasks",
          "storageKey": null,
          "args": null,
          "concreteType": "DeliverableRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "DeliverableRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Deliverable",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "order",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "SubtaskItem_subtask",
                      "args": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '84629dbd949c14d729aeb3cc9c4f2a65';
module.exports = node;
