/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import { urlRegexOptionalProtocol } from 'utils/validators/isValidWebsite';

import connectMarketoAccount, {
  type connectMarketoAccountMutationInput,
} from 'graph/mutations/integration/connectMarketoAccount';
import disconnectMarketoAccount from 'graph/mutations/integration/disconnectMarketoAccount';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button, { OutlineButton } from 'components/budget/Button';
import ConfirmationWindow from 'components/ConfirmationWindow';
import { type IntegrationSettings } from 'components/integrations/IntegrationsSettings';
import SyncStatusIndicator from 'components/integrations/SyncStatusIndicator';
import TextField from 'components/material/TextField';

import { type MarketoAuth_org } from './__generated__/MarketoAuth_org.graphql';

const Root = styled.div`
  ${props =>
    props.connected
      ? css`
          padding: 17px 25px 21px 0;
          background: #f4f5f7;
        `
      : css`
          display: flex;
          justify-content: center;
        `}
`;

const Columns = styled.div`
  display: flex;
  align-items: ${props => (props.centered ? 'center' : 'flex-start')};
  flex: 1;

  &:not(:first-child) {
    padding-top: 10px;
  }

  button {
    width: 95px;
    margin-top: 10px;
  }

  > button {
    align-self: flex-start;
  }
`;

const IntegrationMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  align-self: center;
  padding: 0 30px;
`;

const StyledTextField = styled(TextField)`
  max-width: 380px;
  margin-bottom: 18px;
`;

const ConnectedLabel = styled.div`
  align-self: flex-start;
  margin: 10px 0 3px;
  font-size: 13px;
  color: ${props => props.color};
`;

const ConnectedValue = styled.div`
  align-self: flex-start;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #4a5665;
`;

const StyledIndicator = styled(SyncStatusIndicator)`
  margin: 12px 0 0 20px;
`;

class MarketoAuth extends React.PureComponent<
  {
    integration: IntegrationSettings,
    org: MarketoAuth_org,
    centered: boolean,
  },
  {
    loading: boolean,
    showDisconnectConfirmation: boolean,
    connection: connectMarketoAccountMutationInput,
    errors: connectMarketoAccountMutationInput,
  },
> {
  state = {
    loading: false,
    showDisconnectConfirmation: false,
    connection: {
      host: '',
      clientId: '',
      clientSecret: '',
    },
    errors: {
      host: '',
      clientId: '',
      clientSecret: '',
    },
  };

  handleConfigChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    this.setState(state => ({
      connection: {
        ...state.connection,
        [name]: value,
      },
      errors: { ...state.errors, [name]: '' },
    }));
  };

  handleHostBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!value || !urlRegexOptionalProtocol.test(value)) return;

    this.setState(prevState => ({
      connection: {
        ...prevState.connection,
        ...{ host: new URL(enforceHttpPrefix(value)).hostname },
      },
    }));
  };

  handleConnectAccount = () => {
    const connection = this.state.connection;
    const errors = Object.keys(connection).reduce(
      (errorsObject, connectionField) => {
        if (!connection[connectionField]) {
          return {
            ...errorsObject,
            [connectionField]: 'Required',
          };
        }

        if (
          connectionField === 'host' &&
          (!urlRegexOptionalProtocol.test(connection[connectionField]) ||
            !connection[connectionField].endsWith('mktorest.com'))
        ) {
          return {
            ...errorsObject,
            [connectionField]: 'Invalid API endpoint domain',
          };
        }

        return errorsObject;
      },
      { host: '', clientId: '', clientSecret: '' },
    );

    this.setState({ errors });

    if (Object.values(errors).filter(Boolean).length === 0) {
      this.setState({ loading: true });

      connectMarketoAccount(connection)
        .catch(error => {
          showModernMutationError(error);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  handleDisconnect = () => {
    disconnectMarketoAccount().catch(showModernMutationError);
  };

  handleShowDisconnectConfirmation = () => {
    this.setState({ showDisconnectConfirmation: true });
  };

  handleHideDisconnectConfirmation = () => {
    this.setState({ showDisconnectConfirmation: false });
  };

  render() {
    const { integration, org, centered } = this.props;
    const { loading, showDisconnectConfirmation, connection, errors } = this.state;

    return (
      <Root connected={integration.connected}>
        <Columns centered={centered}>
          <IntegrationMain>
            {!integration.connected && (
              <>
                <StyledTextField
                  label="API endpoint domain"
                  value={connection.host}
                  name="host"
                  error={errors.host}
                  onChange={this.handleConfigChange}
                  onBlur={this.handleHostBlur}
                  required
                  autoFocus
                />
                <StyledTextField
                  label="Client ID"
                  value={connection.clientId}
                  name="clientId"
                  error={errors.clientId}
                  onChange={this.handleConfigChange}
                  required
                />
                <StyledTextField
                  label="Client Secret"
                  value={connection.clientSecret}
                  name="clientSecret"
                  error={errors.clientSecret}
                  onChange={this.handleConfigChange}
                  required
                />
                <Button loading={loading} onClick={this.handleConnectAccount}>
                  Connect
                </Button>
              </>
            )}

            {org.marketoAccount && (
              <>
                <ConnectedLabel color={integration.color}>Connected Account</ConnectedLabel>
                <ConnectedValue>{org.marketoAccount.username}</ConnectedValue>
              </>
            )}
          </IntegrationMain>

          {integration.connected && (
            <OutlineButton
              label="Disconnect"
              loading={loading}
              onClick={this.handleShowDisconnectConfirmation}
              variant="danger"
            />
          )}
          {org.marketoAccount && <StyledIndicator status={org.marketoAccount.syncStatus} />}
        </Columns>

        {showDisconnectConfirmation && (
          <ConfirmationWindow
            onHide={this.handleHideDisconnectConfirmation}
            onConfirm={this.handleDisconnect}
            actionLabel="Disconnect"
            message={integration.disconnectConfirmation}
          />
        )}
      </Root>
    );
  }
}

export default createFragmentContainer(
  MarketoAuth,
  graphql`
    fragment MarketoAuth_org on Org {
      marketoAccount {
        username
        syncStatus {
          ...SyncStatusIndicator_status
        }
      }
    }
  `,
);
