/**
 * @flow
 * @relayHash 55b0e199c1d5edcf6a94a5c52c973f6b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type G2WLocationList = "PAST" | "UPCOMING";
export type G2WLocationPickerQueryVariables = {|
  integrableId: string,
  g2wUserId: string,
  list: G2WLocationList,
|};
export type G2WLocationPickerQueryResponse = {|
  +integrable: ?{|
    +g2wLocations?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +g2wKey: string,
          +subject: string,
          +times: $ReadOnlyArray<{|
            +startTime: any,
            +endTime: any,
          |}>,
          +timezone: string,
          +registrationUrl: string,
          +g2wUrl: string,
          +g2wUser: ?{|
            +id: string,
            +g2wKey: string,
            +firstName: string,
            +lastName: string,
            +email: string,
          |},
          +event: ?{|
            +id: string,
            +slug: string,
            +name: string,
          |},
        |}
      |}>
    |}
  |}
|};
export type G2WLocationPickerQuery = {|
  variables: G2WLocationPickerQueryVariables,
  response: G2WLocationPickerQueryResponse,
|};
*/


/*
query G2WLocationPickerQuery(
  $integrableId: ID!
  $g2wUserId: ID!
  $list: G2WLocationList!
) {
  integrable: node(id: $integrableId) {
    __typename
    ... on IntegrableInterface {
      g2wLocations(g2wUserId: $g2wUserId, list: $list) {
        edges {
          node {
            id
            g2wKey
            subject
            times {
              startTime
              endTime
            }
            timezone
            registrationUrl
            g2wUrl
            g2wUser {
              id
              g2wKey
              firstName
              lastName
              email
            }
            event {
              id
              slug
              name
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "integrableId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "g2wUserId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "list",
    "type": "G2WLocationList!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "integrableId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "g2wKey",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "g2wLocations",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "g2wUserId",
      "variableName": "g2wUserId",
      "type": "ID!"
    },
    {
      "kind": "Variable",
      "name": "list",
      "variableName": "list",
      "type": "G2WLocationList!"
    }
  ],
  "concreteType": "G2WLocationRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "G2WLocationRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "G2WLocation",
          "plural": false,
          "selections": [
            v2,
            v3,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "subject",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "times",
              "storageKey": null,
              "args": null,
              "concreteType": "TimeRangeType",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "startTime",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "endTime",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "timezone",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "registrationUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "g2wUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "g2wUser",
              "storageKey": null,
              "args": null,
              "concreteType": "G2WUser",
              "plural": false,
              "selections": [
                v2,
                v3,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "firstName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "lastName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "email",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "event",
              "storageKey": null,
              "args": null,
              "concreteType": "Event",
              "plural": false,
              "selections": [
                v2,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "slug",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "G2WLocationPickerQuery",
  "id": null,
  "text": "query G2WLocationPickerQuery(\n  $integrableId: ID!\n  $g2wUserId: ID!\n  $list: G2WLocationList!\n) {\n  integrable: node(id: $integrableId) {\n    __typename\n    ... on IntegrableInterface {\n      g2wLocations(g2wUserId: $g2wUserId, list: $list) {\n        edges {\n          node {\n            id\n            g2wKey\n            subject\n            times {\n              startTime\n              endTime\n            }\n            timezone\n            registrationUrl\n            g2wUrl\n            g2wUser {\n              id\n              g2wKey\n              firstName\n              lastName\n              email\n            }\n            event {\n              id\n              slug\n              name\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "G2WLocationPickerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "integrable",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "G2WLocationPickerQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "integrable",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v4,
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '84eea60251ee92dbec85e27e4fadded7';
module.exports = node;
