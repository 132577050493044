/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type DividerComponent_componentProps$ref = any;
type EmbedComponent_componentProps$ref = any;
type EventDatesComponent_componentProps$ref = any;
type EventNameComponent_componentProps$ref = any;
type ImageComponent_componentProps$ref = any;
type RegistrationFormComponent_componentProps$ref = any;
type TextComponent_componentProps$ref = any;
type VideoComponent_componentProps$ref = any;
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SharedColumnComponent_componentProps$ref: FragmentReference;
export type SharedColumnComponent_componentProps = {|
  +id: string,
  +kind: RegistrationPageComponentKind,
  +order: number,
  +parent: ?{|
    +id: string,
    +order: number,
    +childPageComponents: ?{|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +order: number,
          +columnChild: ?{|
            +id: string
          |},
        |}
      |}>
    |},
  |},
  +columnChild: ?{|
    +id: string,
    +order: number,
    +kind: RegistrationPageComponentKind,
    +$fragmentRefs: ImageComponent_componentProps$ref & EventNameComponent_componentProps$ref & EventDatesComponent_componentProps$ref & TextComponent_componentProps$ref & DividerComponent_componentProps$ref & EmbedComponent_componentProps$ref & VideoComponent_componentProps$ref & RegistrationFormComponent_componentProps$ref,
  |},
  +columnComponent: ?{|
    +id: string,
    +width: number,
    +padding: string,
  |},
  +$refType: SharedColumnComponent_componentProps$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SharedColumnComponent_componentProps",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    v2,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationPageComponent",
      "plural": false,
      "selections": [
        v0,
        v2,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "childPageComponents",
          "storageKey": null,
          "args": null,
          "concreteType": "RegistrationPageComponentRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationPageComponentRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationPageComponent",
                  "plural": false,
                  "selections": [
                    v0,
                    v2,
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "columnChild",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "RegistrationPageComponent",
                      "plural": false,
                      "selections": [
                        v0
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "columnChild",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationPageComponent",
      "plural": false,
      "selections": [
        v0,
        v2,
        v1,
        {
          "kind": "FragmentSpread",
          "name": "ImageComponent_componentProps",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "EventNameComponent_componentProps",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "EventDatesComponent_componentProps",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "TextComponent_componentProps",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "DividerComponent_componentProps",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "EmbedComponent_componentProps",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "VideoComponent_componentProps",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "RegistrationFormComponent_componentProps",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "columnComponent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationColumnComponent",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "width",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "padding",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '15356e917c8d077308ea8c90950ca0bd';
module.exports = node;
