/* @flow */

type Exportable = {|
  type: 'CSV' | 'Brief',
  file: 'csv' | 'pdf',
|};

const exportTypes: { [key: string]: Exportable } = {
  admin: {
    type: 'CSV',
    file: 'csv',
  },
  brief: {
    type: 'Brief',
    file: 'pdf',
  },
  all_events_csv: {
    type: 'CSV',
    file: 'csv',
  },
  contacts_csv: {
    type: 'CSV',
    file: 'csv',
  },
  companies_csv: {
    type: 'CSV',
    file: 'csv',
  },
  vendors_csv: {
    type: 'CSV',
    file: 'csv',
  },
  registered_contacts_csv: {
    type: 'CSV',
    file: 'csv',
  },
  members_csv: {
    type: 'CSV',
    file: 'csv',
  },
  expenses_csv: {
    type: 'CSV',
    file: 'csv',
  },
  payments_csv: {
    type: 'CSV',
    file: 'csv',
  },
  budget_report_csv: {
    type: 'CSV',
    file: 'csv',
  },
  event_tasks_csv: {
    type: 'CSV',
    file: 'csv',
  },
  opportunities_csv: {
    type: 'CSV',
    file: 'csv',
  },
  all_tasks_csv: {
    type: 'CSV',
    file: 'csv',
  },
  my_tasks_csv: {
    type: 'CSV',
    file: 'csv',
  },
};

export default exportTypes;
