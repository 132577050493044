/* @flow */
import type { RegistrationUIPropsType } from './RegistrationUIDefaultProps';

const makeUIPropsInsensitive = (UIProps: RegistrationUIPropsType): RegistrationUIPropsType => {
  const UIpropsKeys = Object.keys(UIProps);
  return UIpropsKeys.reduce((parsedProps, currentKey) => {
    if (['Copy', 'Width'].some(sensitiveValueKey => currentKey.includes(sensitiveValueKey))) {
      return { ...parsedProps, [currentKey]: UIProps[currentKey] };
    }
    if (currentKey.includes('Alignment')) {
      return { ...parsedProps, [currentKey]: UIProps[currentKey].toUpperCase() };
    }
    return { ...parsedProps, [currentKey]: UIProps[currentKey].toLowerCase() };
  }, {});
};

export default makeUIPropsInsensitive;
