/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TaskActivitiesRoot from './TaskActivitiesRoot';
import TaskCommentsRoot from './TaskCommentsRoot';

// This is done for participants overlay scroll issue on Firefox and Safari as they have problems with translate3d
const Container = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
`;

const Tabs = styled.div`
  display: flex;
  background: ${props => props.theme.primaryRowColor};
  padding: 0 15px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 5px;
  }
`;

const TabItem = styled.div`
  font-size: 13px;
  padding: 3px 10px;
  cursor: pointer;
  margin-right: 15px;
  ${props =>
    props.active
      ? `
    color: ${props.theme.rowPrimaryTextColor};
    font-weight: 500;
    border-bottom: 2px solid ${props.theme.primaryActionColor};
    `
      : `
    color: ${props.theme.rowSecondaryTextColor};
  `};
`;

export default class TaskActivities extends React.PureComponent<
  {
    taskSlug: string,
  },
  { commentsShown: boolean },
> {
  state = { commentsShown: true };

  handleCommentsShow = () => {
    this.setState({ commentsShown: true });
  };

  handleActivitiesShow = () => {
    this.setState({ commentsShown: false });
  };

  render() {
    return (
      <Container>
        <Tabs>
          <TabItem active={this.state.commentsShown} onClick={this.handleCommentsShow}>
            Comments
          </TabItem>
          <TabItem active={!this.state.commentsShown} onClick={this.handleActivitiesShow}>
            Activity
          </TabItem>
        </Tabs>

        {this.state.commentsShown ? (
          <TaskCommentsRoot taskSlug={this.props.taskSlug} />
        ) : (
          <TaskActivitiesRoot taskSlug={this.props.taskSlug} />
        )}
      </Container>
    );
  }
}
