/* @flow */
import React from 'react';
import { type RelayProp, type RouterHistory, createRefetchContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';
import styled from 'styled-components';
import Clipboard from 'clipboard';
import qs from 'qs';

import eventListSharedOptions from 'config/eventListSharedOptions';
import reservedPathVariables from 'config/reservedPathVariables';

import currentOrigin from 'utils/currentOrigin';
import escapeHtml from 'utils/string/escapeHtml';

import createEventList, { type MutationInput } from 'graph/mutations/eventList/createEventList';
import updateEventList from 'graph/mutations/eventList/updateEventList';
import showModernMutationError from 'graph/utils/showModernMutationError';

import DuplicateIcon from 'images/duplicate.svg';
import EmbedIcon from 'images/events/embed.svg';
import ExternalLinkIcon from 'images/external.svg';
import EmbedWindow from 'components/EmbedWindow';
import accessibilityOptions from 'components/EventRequestForm/lib/accessibilityOptions';
import Button from 'components/material/Button';
import CheckBox from 'components/material/CheckBox';
import Radio from 'components/material/Radio';
import Switch from 'components/material/Switch';
import TextField from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowScroll,
  WindowTitle,
} from 'components/material/Window';

import { type AllEventsShareViewWindow_eventList } from './__generated__/AllEventsShareViewWindow_eventList.graphql';
import { type AllEventsShareViewWindow_org } from './__generated__/AllEventsShareViewWindow_org.graphql';

const Container = styled.div`
  ${WindowScroll} {
    padding-bottom: 5px;
  }
`;

const StyledWindow = styled(Window)`
  margin-top: 65px;
  margin-right: 115px;
`;

const StyledWindowHeader = styled(WindowHeader)`
  @media (${props => props.theme.mobileOnly}) {
    height: auto;
  }
`;

const Title = styled.div`
  flex: 1;
`;

const WindowSubtitle = styled.div`
  max-width: 400px;
  margin: 4px auto -4px;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  color: #828b93;
`;

const IconButton = styled.button`
  color: #6e7884;
  cursor: pointer;

  &:hover {
    color: #3e4859;
  }
`;

const StyledTextField = styled(TextField)`
  margin: 0;

  input {
    padding: 5px 12px;
    border: 1px solid ${props => (props.error ? '#f38183' : '#c6bed7')};
    border-radius: 4px;
    font-size: 13px;
    background: #fff;
  }
`;

const LinkArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -5px;
  padding: ${props => (props.error ? '10px 35px 25px 35px' : '10px 35px')};
  font-size: 13px;
  color: #3e4859;
  background: #f6f2ff;

  ${StyledTextField} {
    flex: 1;
    margin: 0 10px;

    input {
      height: 40px;
    }
  }

  ${IconButton} {
    margin-left: 15px;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  margin: 20px 0 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #3e4859;
`;

const PreviewLink = styled.button`
  margin-left: auto;
  font-size: 13px;
  font-weight: 400;
  color: #3ba9da;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  font-size: 13px;

  button {
    margin-left: 40px;
  }
`;

const SettingsRow = styled.div`
  display: flex;
  align-items: center;
  width: 230px;
  padding: 5px 0 7px;

  ${IconButton} {
    margin-left: 10px;
  }
`;

const SettingsLabel = styled.div`
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  color: #3e4859;
`;

const SettingsLabelSecondary = styled.span`
  font-weight: 400;
  color: #828b93;
`;

const SettingsContent = styled.div`
  padding: 10px 0 10px 15px;
  font-size: 13px;
  color: #3e4859;
`;

const RadioItem = styled.div`
  padding: 5px 0;
  font-size: 13px;
  color: ${props => (props.checked ? '#3e4859' : '#828b93')};
`;

const RadioDescription = styled.div`
  margin-top: 2px;
  padding-left: 22px;
  font-size: 12px;
  line-height: 1.4;

  a {
    color: #3ba9da;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StopShareButton = styled.button`
  width: 100%;
  height: 48px;
  background: rgba(255, 206, 138, 0.68);
  border-radius: 0 0 4px 4px;
  font-size: 13px;
  color: rgba(62, 72, 89, 0.68);
  cursor: pointer;

  &:hover {
    background: rgba(255, 206, 138, 1);
    color: rgba(62, 72, 89, 1);
  }
`;

const StyledEmbedWindow = styled(EmbedWindow)`
  margin-top: 65px;
  margin-right: 115px;
`;

type EventListType = {
  publicResourceToken: string,
  name: string,
  shared: 'PRIVATE' | 'PUBLIC' | 'SSO',
  linkEventNameToBrief: boolean,
  actionsRequestFormIds: ?$ReadOnlyArray<string>,
  logo: ?string,
  font: ?string,
  backgroundUrl: ?string,
  backgroundColor: ?string,
  titleColor: ?string,
};

class AllEventsShareViewWindow extends React.Component<
  {
    eventList: ?AllEventsShareViewWindow_eventList,
    org: AllEventsShareViewWindow_org,
    onHide: () => void,
    relay: RelayProp,
    shownColumns: $ReadOnlyArray<string>,
    location: Location,
    history: RouterHistory,
    savedViewParams: MutationInput,
    userId: string,
  },
  {
    eventList: $Exact<EventListType>,
    loading: boolean,
    showEmbedWindow: boolean,
    updateSlugWithNameChange: boolean,
    nameTouched: boolean,
    slugTouched: boolean,
    copied: boolean,
  },
> {
  previewWindow: ?$Call<window.open> = null;

  clipboard = null;

  copyRef = React.createRef();

  constructor(props: $PropertyType<AllEventsShareViewWindow, 'props'>) {
    super(props);

    const { eventList } = this.props;

    const updateSlugWithNameChange = !eventList;

    this.state = {
      eventList: {
        publicResourceToken: (eventList && eventList.publicResourceToken.token) || '',
        name: eventList ? eventList.name : '',
        shared: this.isShared() && eventList ? eventList.shared : 'PUBLIC',
        linkEventNameToBrief: eventList ? eventList.linkEventNameToBrief : false,
        actionsRequestFormIds:
          eventList && eventList.actionsRequestForms.edges.length > 0
            ? eventList.actionsRequestForms.edges.map(edge => edge.node.id)
            : null,
        logo: eventList && eventList.logo,
        font: eventList && eventList.font,
        backgroundUrl: eventList && eventList.backgroundUrl,
        backgroundColor: eventList && eventList.backgroundColor,
        titleColor: eventList && eventList.titleColor,
      },
      loading: false,
      showEmbedWindow: false,
      updateSlugWithNameChange,
      nameTouched: false,
      slugTouched: false,
      copied: false,
    };
  }

  componentDidMount() {
    if (this.state.eventList.actionsRequestFormIds) {
      this.handleLoadRequestForms();
    }

    this.setupClipboard();

    window.onChangeEventListStyles = this.handleUpdateEventList;
  }

  componentDidUpdate() {
    this.setupClipboard();
  }

  componentWillUnmount() {
    if (this.previewWindow) {
      this.previewWindow.close();
    }

    if (this.clipboard) this.clipboard.destroy();

    window.onChangeEventListStyles = null;
  }

  nameToSlug = (name: string) =>
    name
      .toLowerCase()
      .split(/[^a-zA-Z0-9-_]+/)
      .join('-');

  setupClipboard = () => {
    if (this.clipboard) this.clipboard.destroy();

    this.clipboard =
      this.copyRef.current &&
      new Clipboard(this.copyRef.current, {
        text: () => `${this.baseUrl()}${this.state.eventList.publicResourceToken}`,
      }).on('success', () => {
        this.setState({ copied: true });
      });
  };

  handleOpenPreview = () => {
    window.open(`${this.baseUrl()}${this.state.eventList.publicResourceToken}`, '_blank');
  };

  handleLoadRequestForms = () => {
    if (!this.props.org.publishedRequestForms) {
      this.props.relay.refetch({ loadRequestForms: true });
    }
  };

  handleShowEmbedWindow = () => {
    this.setState({ showEmbedWindow: true });
  };

  handleHideEmbedWindow = () => {
    this.setState({ showEmbedWindow: false });
  };

  handleUpdateEventList = (eventList: $Exact<{ ...EventListType }>, save?: boolean = true) => {
    this.setState(
      state => ({ eventList: { ...state.eventList, ...eventList } }),
      () => {
        if (save && this.isShared()) {
          this.handleSave(false);
        }
      },
    );
  };

  handleToggleLinkEventNameToBrief = () => {
    this.handleUpdateEventList({
      linkEventNameToBrief: !this.state.eventList.linkEventNameToBrief,
    });
  };

  handleToggleAllowRequestingEvents = () => {
    this.handleUpdateEventList({
      actionsRequestFormIds: this.state.eventList.actionsRequestFormIds ? null : [],
    });

    if (this.state.eventList.actionsRequestFormIds) return;

    this.handleLoadRequestForms();
  };

  handleChangeName = (event: SyntheticEvent<HTMLInputElement>) => {
    const name = event.currentTarget.value;

    this.handleUpdateEventList({ name }, false);

    if (this.state.updateSlugWithNameChange) {
      this.handleUpdateEventList({ publicResourceToken: this.nameToSlug(name) }, false);

      this.setState({ slugTouched: false });
    }

    this.setState({ nameTouched: true });
  };

  handleBlurName = () => {
    if (this.isShared()) {
      this.handleSave(false);
    }
  };

  handleChangeSlug = (event: SyntheticEvent<HTMLInputElement>) => {
    const publicResourceToken = event.currentTarget.value.replace(/[^a-zA-Z0-9-_]/g, '-');

    this.handleUpdateEventList({ publicResourceToken }, false);

    this.setState({ updateSlugWithNameChange: !publicResourceToken.trim(), slugTouched: true });
  };

  handleBlurSlug = () => {
    if (this.isShared()) {
      this.handleSave(false);
    }
  };

  handleStopSharing = () => {
    if (!this.props.eventList) return;

    updateEventList({ id: this.props.eventList.id, shared: 'PRIVATE' }).catch(
      showModernMutationError,
    );

    this.props.onHide();
  };

  handleMouseLeave = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 300);
  };

  handlePreview = () => {
    if (this.previewWindow) {
      this.previewWindow.close();
    }

    const publishedRequestForms = this.props.org.publishedRequestForms
      ? this.props.org.publishedRequestForms.edges.map(edge => edge.node)
      : [];

    const { search } = this.props.location;
    const hash = qs.stringify(
      {
        shownColumns: this.props.shownColumns,
        name: this.state.eventList.name,
        isShared: this.isShared(),
        logo: this.state.eventList.logo,
        font: this.state.eventList.font,
        backgroundUrl: this.state.eventList.backgroundUrl,
        backgroundColor: this.state.eventList.backgroundColor,
        titleColor: this.state.eventList.titleColor,
        publicResourceToken: this.state.eventList.publicResourceToken,
        linkEventNameToBrief: this.state.eventList.linkEventNameToBrief,
        actionsRequestForms:
          this.state.eventList.actionsRequestFormIds &&
          this.state.eventList.actionsRequestFormIds.length > 0
            ? this.state.eventList.actionsRequestFormIds
                .map(formId => {
                  const form = publishedRequestForms.find(
                    publishedForm => publishedForm.id === formId,
                  );

                  if (!form) return null;

                  return { name: form.name, shareLink: form.shareLink };
                })
                .filter(Boolean)
            : null,
      },
      { skipNulls: true },
    );

    this.previewWindow = window.open(`/dashboard/saved_view_preview${search}#${hash}`);
  };

  handleSave = (hideWindowOnSave: boolean = true) => {
    if (this.saveDisabled()) return;

    if (!this.isShared()) this.setState({ loading: true });

    const savePromise = this.props.eventList
      ? updateEventList({
          id: this.props.eventList.id,
          ...this.state.eventList,
          actionsRequestFormIds: this.state.eventList.actionsRequestFormIds || [],
        })
      : createEventList(
          {
            ...this.props.savedViewParams,
            ...this.state.eventList,
            actionsRequestFormIds: this.state.eventList.actionsRequestFormIds || [],
          },
          { userId: this.props.userId },
        );

    savePromise
      .then(response => {
        if (response.createEventList) {
          this.props.history.push(`/dashboard/${response.createEventList.eventListsEdge.node.id}`);
        } else if (hideWindowOnSave) this.props.onHide();
      })
      .catch(error => {
        showModernMutationError(error);

        this.setState({ loading: false });
      });
  };

  baseUrl = () =>
    `${currentOrigin()}/${this.props.org.settings.subdomain ? '' : `${this.props.org.slug}/`}`;

  saveDisabled = () =>
    !this.state.eventList.name.trim() ||
    !this.state.eventList.publicResourceToken.trim() ||
    reservedPathVariables.includes(this.state.eventList.publicResourceToken.trim());

  isShared() {
    return !!this.props.eventList && this.props.eventList.shared !== 'PRIVATE';
  }

  render() {
    const { onHide, org } = this.props;
    const { eventList, loading, showEmbedWindow, nameTouched, slugTouched } = this.state;
    const reservedPath = reservedPathVariables.includes(eventList.publicResourceToken.trim());
    if (showEmbedWindow) {
      return (
        <StyledEmbedWindow
          onHide={this.handleHideEmbedWindow}
          url={`${this.baseUrl()}${eventList.publicResourceToken}`}
        />
      );
    }

    return (
      <Container>
        <StyledWindow onHide={onHide} size="medium">
          <StyledWindowHeader>
            <WindowClose onClick={onHide} />

            <Title>
              <WindowTitle>Share view</WindowTitle>

              <WindowSubtitle>
                Save and share this view with the current columns and filters
              </WindowSubtitle>
            </Title>
          </StyledWindowHeader>

          <LinkArea error={reservedPath}>
            {this.baseUrl()}
            <StyledTextField
              error={
                reservedPath
                  ? 'This is a reserved word'
                  : slugTouched && !eventList.publicResourceToken.trim()
              }
              value={eventList.publicResourceToken}
              onChange={this.handleChangeSlug}
              onBlur={this.handleBlurSlug}
            />

            {this.isShared() && (
              <>
                <IconButton ref={this.copyRef}>
                  <Tooltip label={this.state.copied ? 'Copied' : 'Copy'} placement="top">
                    <DuplicateIcon onMouseLeave={this.handleMouseLeave} />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={this.handleShowEmbedWindow}>
                  <Tooltip label="Embed" placement="top">
                    <EmbedIcon />
                  </Tooltip>
                </IconButton>
                {this.isShared() && !loading && (
                  <IconButton onClick={this.handleOpenPreview}>
                    <Tooltip label="Preview" placement="top">
                      <ExternalLinkIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </>
            )}
          </LinkArea>

          <WindowContent>
            <SectionTitle>
              Title
              <PreviewLink onClick={this.handlePreview}>Preview & Stylize</PreviewLink>
            </SectionTitle>

            <StyledTextField
              error={nameTouched && !eventList.name.trim()}
              value={eventList.name}
              onChange={this.handleChangeName}
              onBlur={this.handleBlurName}
            />

            <SectionTitle>Accessibility</SectionTitle>

            {eventListSharedOptions.map(option => {
              const checked = option.value === eventList.shared;

              return (
                <RadioItem key={option.value} checked={checked}>
                  <Radio
                    value={option.value}
                    label={option.label.replace('%s', org.name)}
                    onChange={() => this.handleUpdateEventList({ shared: option.value })}
                    disabled={option.value === 'SSO' && !org.hasSamlProvider}
                    checked={checked}
                    radioColor={checked ? '#29cc71' : '#cdd1d4'}
                  />

                  <RadioDescription
                    dangerouslySetInnerHTML={{
                      __html: option.description.replace('%s', escapeHtml(org.name)),
                    }}
                  />
                </RadioItem>
              );
            })}

            <SectionTitle>Settings</SectionTitle>

            <SettingsRow>
              <SettingsLabel>Link to Event Briefs</SettingsLabel>

              <Switch
                enabled={eventList.linkEventNameToBrief}
                onChange={this.handleToggleLinkEventNameToBrief}
                size="small"
              />
            </SettingsRow>

            {org.publishedRequestFormsCount.totalCount > 0 && (
              <>
                <SettingsRow>
                  <SettingsLabel>Link to Event Request Forms</SettingsLabel>

                  <Switch
                    enabled={!!eventList.actionsRequestFormIds}
                    onChange={this.handleToggleAllowRequestingEvents}
                    size="small"
                  />
                </SettingsRow>

                {org.publishedRequestForms && eventList.actionsRequestFormIds && (
                  <SettingsContent>
                    {org.publishedRequestForms.edges.map(({ node: form }) => {
                      const actionsRequestFormIds = eventList.actionsRequestFormIds || [];
                      const checked = actionsRequestFormIds.includes(form.id);

                      return (
                        <CheckBox
                          key={form.id}
                          label={
                            <>
                              {form.name}{' '}
                              <SettingsLabelSecondary>
                                ({accessibilityOptions[form.accessibility].label})
                              </SettingsLabelSecondary>
                            </>
                          }
                          checked={checked}
                          checkColor={checked ? null : '#cdd1d4'}
                          onChange={() => {
                            this.handleUpdateEventList({
                              actionsRequestFormIds: checked
                                ? actionsRequestFormIds.filter(id => id !== form.id)
                                : [...actionsRequestFormIds, form.id],
                            });
                          }}
                        />
                      );
                    })}
                  </SettingsContent>
                )}
              </>
            )}

            {(!this.isShared() || loading) && (
              <Footer>
                <Button minimal label="Cancel" onClick={onHide} />

                <Button
                  primary
                  label="Share"
                  onClick={() => this.handleSave()}
                  loading={loading}
                  disabled={this.saveDisabled()}
                />
              </Footer>
            )}
          </WindowContent>

          {this.isShared() && !loading && (
            <StopShareButton onClick={this.handleStopSharing}>
              Stop sharing the view
            </StopShareButton>
          )}
        </StyledWindow>
      </Container>
    );
  }
}

export default createRefetchContainer(
  AllEventsShareViewWindow,
  {
    eventList: graphql`
      fragment AllEventsShareViewWindow_eventList on EventList {
        id
        name
        shared
        linkEventNameToBrief
        logo
        font
        backgroundUrl
        backgroundColor
        titleColor
        publicResourceToken {
          token
        }
        actionsRequestForms {
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    org: graphql`
      fragment AllEventsShareViewWindow_org on Org
      @argumentDefinitions(loadRequestForms: { type: "Boolean!", defaultValue: false }) {
        name
        slug
        hasSamlProvider
        settings {
          subdomain
        }

        publishedRequestFormsCount: eventRequestForms(publishedOnly: true) {
          totalCount
        }

        ... @include(if: $loadRequestForms) {
          publishedRequestForms: eventRequestForms(publishedOnly: true) {
            edges {
              node {
                id
                name
                accessibility
                shareLink
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query AllEventsShareViewWindowRefetchQuery($loadRequestForms: Boolean!) {
      org {
        ...AllEventsShareViewWindow_org @arguments(loadRequestForms: $loadRequestForms)
      }
    }
  `,
);
