/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineMultiselectField from 'components/budget/Table/InlineMultiselectField';
import LockIcon from 'components/LockIcon';

import type { CustomizableMultiselect_customizable } from './__generated__/CustomizableMultiselect_customizable.graphql';

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
`;

class CustomizableMultiselect extends React.Component<{
  customizable: CustomizableMultiselect_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
}> {
  getActiveValues = (): $ReadOnlyArray<string> => {
    const { customizable, fieldSettings } = this.props;
    return customizable.customMultiselectFields
      .filter(field => field.customField.id === fieldSettings.id)
      .map(field => field.option.id);
  };

  handleSave = (values: ?$ReadOnlyArray<string>) => {
    const { customizable, fieldSettings, onUpdateCustomField } = this.props;
    if (onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        selectValues: values,
      });
    }
  };

  handleSelect = (value: string): void => {
    if (value != null) {
      this.handleSave([...this.getActiveValues(), value]);
    }
  };

  handleUnselect = (value: string): void => {
    const activeValues = this.getActiveValues();
    this.handleSave(activeValues.filter(val => val !== value));
  };

  handleHideOptions = () => {
    this.props.updateColumnWidth();
  };

  // user is not null only if getError is called for unselect Case
  getError = (value?: any): ?string => {
    const tresholdActiveValueCount = value != null ? 1 : 0;
    if (
      this.props.fieldSettings.required &&
      this.getActiveValues().length <= tresholdActiveValueCount
    ) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { fieldSettings, readOnly, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    const activeValues = this.getActiveValues();
    const activeLabels = fieldSettings.options
      .filter(option => activeValues.includes(option.value))
      .map(option => option.label);

    if (readOnly || fieldSettings.restrictChangingValue) {
      return (
        <InlineExpandableText>
          {activeLabels.join(', ')}
          {!readOnly && fieldSettings.restrictChangingValue && (
            <StyledLockIcon label="Salesforce" />
          )}
        </InlineExpandableText>
      );
    }

    return (
      <InlineMultiselectField
        placeholder={fieldSettings.label}
        options={fieldSettings.options}
        values={activeValues.length === 0 ? null : activeValues}
        onSelect={this.handleSelect}
        onUnselect={this.handleUnselect}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        clearable={!fieldSettings.required}
        onHideOptions={this.handleHideOptions}
        maxShowableItems={2}
      />
    );
  }
}

export default createFragmentContainer(
  CustomizableMultiselect,
  graphql`
    fragment CustomizableMultiselect_customizable on CustomizableInterface {
      id
      __typename
      customMultiselectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
    }
  `,
);
