/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import resendInvite from 'graph/mutations/user/resendInvite';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ShareDropdown, { Icon } from 'components/ShareDropdown';
import UserRemovalWindow from 'components/UserView/UserRemovalWindow';

import { type ParsedMembersFilters } from '../lib/parseMembersFilters';

import type { MemberActionsColumn_user } from './__generated__/MembersActionsColumn_user.graphql';

const Column = styled.div`
  margin: 0 -5px 0 -24px;
  & > div:first-child {
    margin-left: 0;
    padding-left: 0;
  }
`;

export { Icon };

class MembersActionsColumn extends React.Component<
  {
    user: MemberActionsColumn_user,
    filters: ParsedMembersFilters,
  },
  {
    showRemoveModal: boolean,
    reinvited: boolean,
  },
> {
  state = {
    showRemoveModal: false,
    reinvited: false,
  };

  stopPropagation = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation();
  };

  handleRemoveModalShow = () => {
    this.setState({ showRemoveModal: true });
  };

  handleRemoveModalHide = () => {
    this.setState({ showRemoveModal: false });
  };

  handleResendInvite = () => {
    this.setState({ reinvited: true });
    resendInvite(this.props.user.id).catch(err => {
      this.setState({ reinvited: false });
      showModernMutationError(err);
    });
  };

  render() {
    const { user, filters } = this.props;
    const { viewerCanUpdate, viewerCanRemove, inviteStatus } = user;

    const actions = [
      inviteStatus === 'INVITED' && !this.state.reinvited && viewerCanUpdate
        ? {
            label: 'Resend invite',
            icon: 'paper-plane',
            onClick: this.handleResendInvite,
          }
        : null,
      viewerCanRemove
        ? {
            label: 'Remove from Workspace',
            icon: 'times',
            onClick: this.handleRemoveModalShow,
          }
        : null,
    ].filter(Boolean);

    if (actions.length === 0) {
      return null;
    }

    return (
      <Column onClick={this.stopPropagation}>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={actions}
          overlayContainer={document.body}
        />
        {this.state.showRemoveModal &&
          document.body != null &&
          createPortal(
            <UserRemovalWindow
              userId={user.id}
              onHide={this.handleRemoveModalHide}
              filters={filters}
              fromWindow="Workspace Members"
            />,
            document.body,
          )}
      </Column>
    );
  }
}

export default createFragmentContainer(MembersActionsColumn, {
  user: graphql`
    fragment MembersActionsColumn_user on User {
      id
      firstName
      lastName
      inviteStatus
      viewerCanRemove
      viewerCanUpdate
    }
  `,
});
