/**
 * @flow
 * @relayHash 221fb39ba102e9d9a9d77aa44b3074e8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ScheduleListRow_scheduleItem$ref = any;
type SchedulesTimelineItem_item$ref = any;
export type AddItemToScheduleDayInput = {
  clientMutationId?: ?string,
  scheduleDayId: string,
  title?: ?string,
  description?: ?string,
  location?: ?string,
  startTime: string,
  endTime?: ?string,
  allDay: boolean,
};
export type createScheduleItemMutationVariables = {|
  input: AddItemToScheduleDayInput
|};
export type createScheduleItemMutationResponse = {|
  +addItemToScheduleDay: ?{|
    +scheduleItemEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +$fragmentRefs: ScheduleListRow_scheduleItem$ref & SchedulesTimelineItem_item$ref,
      |},
    |}
  |}
|};
export type createScheduleItemMutation = {|
  variables: createScheduleItemMutationVariables,
  response: createScheduleItemMutationResponse,
|};
*/


/*
mutation createScheduleItemMutation(
  $input: AddItemToScheduleDayInput!
) {
  addItemToScheduleDay(input: $input) {
    scheduleItemEdge {
      __typename
      node {
        id
        ...ScheduleListRow_scheduleItem
        ...SchedulesTimelineItem_item
      }
    }
  }
}

fragment ScheduleListRow_scheduleItem on ScheduleItem {
  id
  title
  all_day
  start_time
  end_time
  description
  location
  viewerCanUpdate
  viewerCanRemove
  scheduleParticipants {
    edges {
      node {
        user {
          id
          firstName
          lastName
          email
        }
        id
      }
    }
  }
}

fragment SchedulesTimelineItem_item on ScheduleItem {
  start_time
  end_time
  all_day
  title
  location
  description
  scheduleParticipants {
    edges {
      node {
        user {
          firstName
          lastName
          email
          id
        }
        id
      }
    }
  }
  scheduleDay {
    schedule {
      slug
      event {
        slug
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddItemToScheduleDayInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddItemToScheduleDayInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createScheduleItemMutation",
  "id": null,
  "text": "mutation createScheduleItemMutation(\n  $input: AddItemToScheduleDayInput!\n) {\n  addItemToScheduleDay(input: $input) {\n    scheduleItemEdge {\n      __typename\n      node {\n        id\n        ...ScheduleListRow_scheduleItem\n        ...SchedulesTimelineItem_item\n      }\n    }\n  }\n}\n\nfragment ScheduleListRow_scheduleItem on ScheduleItem {\n  id\n  title\n  all_day\n  start_time\n  end_time\n  description\n  location\n  viewerCanUpdate\n  viewerCanRemove\n  scheduleParticipants {\n    edges {\n      node {\n        user {\n          id\n          firstName\n          lastName\n          email\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment SchedulesTimelineItem_item on ScheduleItem {\n  start_time\n  end_time\n  all_day\n  title\n  location\n  description\n  scheduleParticipants {\n    edges {\n      node {\n        user {\n          firstName\n          lastName\n          email\n          id\n        }\n        id\n      }\n    }\n  }\n  scheduleDay {\n    schedule {\n      slug\n      event {\n        slug\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createScheduleItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addItemToScheduleDay",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddItemToScheduleDayPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scheduleItemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ScheduleItemRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ScheduleItem",
                "plural": false,
                "selections": [
                  v3,
                  {
                    "kind": "FragmentSpread",
                    "name": "ScheduleListRow_scheduleItem",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "SchedulesTimelineItem_item",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createScheduleItemMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addItemToScheduleDay",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddItemToScheduleDayPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scheduleItemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ScheduleItemRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ScheduleItem",
                "plural": false,
                "selections": [
                  v3,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "all_day",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "start_time",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "end_time",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "location",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanRemove",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "scheduleParticipants",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ScheduleParticipantRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ScheduleParticipantRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ScheduleParticipant",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "User",
                                "plural": false,
                                "selections": [
                                  v3,
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "firstName",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "lastName",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "email",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              },
                              v3
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "scheduleDay",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ScheduleDay",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "schedule",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Schedule",
                        "plural": false,
                        "selections": [
                          v4,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "event",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Event",
                            "plural": false,
                            "selections": [
                              v4,
                              v3
                            ]
                          },
                          v3
                        ]
                      },
                      v3
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7042d507f5e394ff04d6d28afbfe1373';
module.exports = node;
