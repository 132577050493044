/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AttendedContactsTotal_event$ref: FragmentReference;
export type AttendedContactsTotal_event = {|
  +attendedTotal: number,
  +$refType: AttendedContactsTotal_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "AttendedContactsTotal_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "attendedTotal",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6af2400c9f4e7aca1848477ca0e3e408';
module.exports = node;
