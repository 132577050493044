/* @flow */

const headerTitles = {
  all_tasks: 'All tasks',
  my_tasks: 'My tasks',
  upcoming_events: 'Upcoming events',
  all_events: 'All events',
  my_events: 'My events',
  recent_events: 'Recent events',
  requested_events: 'Requested events',
  past_events: 'Past events',
  event_requests: 'Event Requests',
  event_imports: 'Event Imports',
  member: 'Member Profile',
  settings: 'Account Settings',
  contact_events: 'Contact’s events',
  vendor_expenses: "Vendor's expenses",
  budget_reporting: 'Budget Reporting',
};

export default headerTitles;
