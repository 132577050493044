/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactAttendanceDuration_eventContact$ref: FragmentReference;
export type ContactAttendanceDuration_eventContact = {|
  +zoomRegistrant: ?{|
    +duration: ?number
  |},
  +g2wRegistrant: ?{|
    +duration: ?number
  |},
  +ibmWmRegistrant: ?{|
    +duration: ?number
  |},
  +$refType: ContactAttendanceDuration_eventContact$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "duration",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "ContactAttendanceDuration_eventContact",
  "type": "EventContactEdge",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "zoomRegistrant",
      "storageKey": null,
      "args": null,
      "concreteType": "ZoomRegistrant",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "g2wRegistrant",
      "storageKey": null,
      "args": null,
      "concreteType": "G2wRegistrant",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "ibmWmRegistrant",
      "storageKey": null,
      "args": null,
      "concreteType": "IbmWmRegistrant",
      "plural": false,
      "selections": v0
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc16152e912a05d3475fd3e31f835f4d';
module.exports = node;
