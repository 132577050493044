/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TaskRow_event$ref = any;
type TaskRow_task$ref = any;
type TasksFolder_folder$ref = any;
type TasksFolder_tasks$ref = any;
export type TaskStatus = "COMPLETED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TasksSectionViewContent_event$ref: FragmentReference;
export type TasksSectionViewContent_event = {|
  +id: string,
  +viewerCanCreateDeliverable: boolean,
  +folders: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
        +order: number,
        +viewerCanReorder: boolean,
        +$fragmentRefs: TasksFolder_folder$ref,
      |}
    |}>
  |},
  +deliverables: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +order: number,
        +name: string,
        +folderId: ?string,
        +status: TaskStatus,
        +viewerCanUpdateStatus: boolean,
        +viewerCanUpdateDueDate: boolean,
        +viewerCanAssign: boolean,
        +viewerCanReorder: boolean,
        +viewerCanUpdateTags: boolean,
        +viewerCanDelete: boolean,
        +assignees: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +firstName: string,
              +lastName: string,
              +email: string,
            |}
          |}>
        |},
        +tags: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +$fragmentRefs: TaskRow_task$ref & TasksFolder_tasks$ref,
      |}
    |}>
  |},
  +$fragmentRefs: TaskRow_event$ref,
  +$refType: TasksSectionViewContent_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanReorder",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TasksSectionViewContent_event",
  "type": "Event",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "deliverables"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filters",
      "type": "DeliverableFilters"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "FragmentSpread",
      "name": "TaskRow_event",
      "args": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanCreateDeliverable",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "folders",
      "storageKey": null,
      "args": null,
      "concreteType": "FolderRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "FolderRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Folder",
              "plural": false,
              "selections": [
                v0,
                v1,
                v2,
                v3,
                {
                  "kind": "FragmentSpread",
                  "name": "TasksFolder_folder",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "deliverables",
      "name": "__TasksSectionViewContent_deliverables_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "DeliverableRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Deliverable",
              "plural": false,
              "selections": [
                v0,
                v2,
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "folderId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "viewerCanUpdateStatus",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "viewerCanUpdateDueDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "viewerCanAssign",
                  "args": null,
                  "storageKey": null
                },
                v3,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "viewerCanUpdateTags",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "viewerCanDelete",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "assignees",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "User",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "firstName",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "lastName",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "email",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tags",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TagRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "TagRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Tag",
                          "plural": false,
                          "selections": [
                            v0
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "TaskRow_task",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "TasksFolder_tasks",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c1c38176c73a6be4e6e4ec665ea9ebe5';
module.exports = node;
