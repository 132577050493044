import React from 'react';

import { Panel, PanelFooter } from './Panel';
import { Button } from 'components/form/Button';
import { TextField } from 'components/form/TextField';

export class EditNamePanel extends React.Component {
  render() {
    return (
      <Panel {...this.props} ref="panel">
        <div className="edit-name-panel">
          <form>
            <TextField ref="field" label="Name" defaultValue={this.props.value} autoFocus />
            <PanelFooter>
              <div className="panel-buttons">
                <Button text="Cancel" minimal type="button" onClick={this.hide.bind(this)} />
                <Button text="Save" primary onClick={this.handleSubmit.bind(this)} />
              </div>
            </PanelFooter>
          </form>
        </div>
      </Panel>
    );
  }
  show() {
    this.refs.panel.show();
  }
  hide() {
    this.refs.panel.hide();
  }
  toggle() {
    this.refs.panel.toggle();
  }
  handleSubmit(e) {
    e.preventDefault();

    this.props.onSave(this.refs.field.value());
  }
}
