/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import Arrow from 'images/arrow.svg';
import { type SectionType } from 'components/EventRequestForm/lib/types';
import Tooltip from 'components/material/Tooltip';
import SectionNumber from 'views/EventRequestFormBuilder/components/SectionNumber';

const SectionLeftPanel = styled.div`
  position: absolute;
  left: 0;
  top: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionContainer = styled.div`
  margin-top: 10px;
`;

const SectionNavigationButton = styled(Arrow)`
  rotate: -90deg;
  height: 24px;
  color: #4a5665;
  cursor: pointer;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: initial;
    `};
`;

const SectionNavigationDownButton = styled(SectionNavigationButton)`
  rotate: 90deg;
`;

export default class EditableSectionLeftPanel extends React.PureComponent<{
  section: SectionType,
  sectionsCount: number,
  onMoveSection: (currentOrder: number, targetOrder: number) => void,
  hasDependecyFromPrev: boolean,
  hasDependencyWithNext: boolean,
  saving: boolean,
}> {
  upRestrictedSectionOrder = 2;

  handleMoveSectionUp = () => {
    const { section } = this.props;
    if (section.order !== this.upRestrictedSectionOrder) {
      this.props.onMoveSection(section.order, section.order - 1);
    }
  };

  handleMoveSectionDown = () => {
    const { section } = this.props;
    this.props.onMoveSection(section.order, section.order + 1);
  };

  render() {
    const {
      section,
      sectionsCount,
      saving,
      hasDependecyFromPrev,
      hasDependencyWithNext,
    } = this.props;

    const disabledUp = hasDependecyFromPrev || section.order === this.upRestrictedSectionOrder;

    return (
      <SectionLeftPanel>
        <SectionNumber>
          {section.order}/{sectionsCount}
        </SectionNumber>
        {section.order !== 1 && (
          <ActionContainer>
            <Tooltip
              label={hasDependecyFromPrev ? 'This section is dependent from previous one' : ''}
            >
              <SectionNavigationButton
                disabled={disabledUp || saving}
                onClick={disabledUp ? undefined : this.handleMoveSectionUp}
              />
            </Tooltip>
            {section.order !== sectionsCount && (
              <Tooltip
                label={hasDependencyWithNext ? 'The next section is dependent from this one' : ''}
              >
                <SectionNavigationDownButton
                  disabled={hasDependencyWithNext || saving}
                  onClick={hasDependencyWithNext ? undefined : this.handleMoveSectionDown}
                />
              </Tooltip>
            )}
          </ActionContainer>
        )}
      </SectionLeftPanel>
    );
  }
}
