/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import CopyLink from 'components/CopyLink';

import type { EventRequestFormsShareLinkColumn_requestForm } from './__generated__/EventRequestFormsShareLinkColumn_requestForm.graphql';

class EventRequestFormsShareLinkColumn extends React.PureComponent<{
  requestForm: EventRequestFormsShareLinkColumn_requestForm,
}> {
  render() {
    return !this.props.requestForm.shareLink ? null : (
      <CopyLink embed link={this.props.requestForm.shareLink} />
    );
  }
}

export default createFragmentContainer(
  EventRequestFormsShareLinkColumn,
  graphql`
    fragment EventRequestFormsShareLinkColumn_requestForm on EventRequestForm {
      shareLink
    }
  `,
);
