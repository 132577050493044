/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import BudgetReportingPage from './BudgetReportingPage';

import type { BudgetReportingQueryResponse } from './__generated__/BudgetReportingQuery.graphql';

const query = graphql`
  query BudgetReportingQuery {
    me {
      ...BudgetReportingPage_me
    }
    org {
      ...BudgetReportingPage_org
    }
  }
`;

export default class BudgetReporting extends React.PureComponent<{
  location: Location,
  history: RouterHistory,
}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(response: BudgetReportingQueryResponse) => {
          return (
            <BudgetReportingPage
              history={this.props.history}
              location={this.props.location}
              me={response.me}
              org={response.org}
            />
          );
        }}
      />
    );
  }
}
