/* @flow */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import DateTimePicker, { type DateConfig } from '../DateTimePicker';
import TimeRangePicker, { type TimesConfig } from '../TimeRangePicker';

const Root = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

const InputWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  margin-right: 28px;
`;

export type DatesConfig = {|
  startDate: ?string,
  endDate: ?string,
  allDay: boolean,
|};

export default class SingleDateTimeRangePicker extends React.PureComponent<{
  onChange: (datesConfig: DatesConfig) => void,
  ...DatesConfig,
  tz: string,
  dateLabel?: string,
  startTimeLabel?: string,
  endTimeLabel?: string,
  disabled?: boolean,
  clearable?: boolean,
  error?: ?string,
  defaultTimeInterval?: number,
  onFocus?: () => void,
  onBlur?: () => void,
}> {
  handleChangeDate = ({ date }: DateConfig) => {
    if (!date) {
      this.props.onChange({ startDate: null, endDate: null, allDay: true });
    } else {
      const parsedDate = moment(date).tz(this.props.tz);

      this.props.onChange({
        startDate: this.props.startDate
          ? moment(this.props.startDate)
              .tz(this.props.tz)
              .set({ year: parsedDate.year(), month: parsedDate.month(), date: parsedDate.date() })
              .format()
          : date,
        endDate: this.props.endDate
          ? moment(this.props.endDate)
              .tz(this.props.tz)
              .set({ year: parsedDate.year(), month: parsedDate.month(), date: parsedDate.date() })
              .format()
          : null,
        allDay: this.props.allDay,
      });
    }
  };

  handleChangeTimes = (timesConfig: TimesConfig) => {
    this.props.onChange({
      startDate:
        timesConfig.startTime ||
        moment(this.props.startDate)
          .tz(this.props.tz)
          .startOf('day')
          .format(),
      endDate: timesConfig.endTime,
      allDay: !timesConfig.startTime,
    });
  };

  render() {
    return (
      <Root>
        <InputWrapper>
          <DateTimePicker
            tz={this.props.tz}
            label={this.props.dateLabel}
            date={this.props.startDate}
            hideTime={this.props.allDay}
            onChange={this.handleChangeDate}
            dateOnly
            disabled={this.props.disabled}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            error={this.props.error}
            clearable={this.props.clearable}
          />
        </InputWrapper>

        <div>
          <TimeRangePicker
            tz={this.props.tz}
            date={
              this.props.startDate ||
              moment()
                .tz(this.props.tz)
                .format()
            }
            startTime={this.props.allDay ? null : this.props.startDate}
            endTime={this.props.endDate}
            onChange={this.handleChangeTimes}
            startLabel={this.props.startTimeLabel}
            endLabel={this.props.endTimeLabel}
            disabled={this.props.disabled}
            defaultInterval={this.props.defaultTimeInterval}
            startRequired
            endRequired
            startError={this.props.error ? ' ' : null}
            endError={this.props.error ? ' ' : null}
            onFocusStart={this.props.onFocus}
            onFocusEnd={this.props.onFocus}
            onBlurStart={this.props.onBlur}
            onBlurEnd={this.props.onBlur}
          />
        </div>
      </Root>
    );
  }
}
