/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type PaymentActionCell_payment$ref = any;
type PaymentActualDateCell_payment$ref = any;
type PaymentAmountCell_payment$ref = any;
type PaymentAmountOfExpenseCell_payment$ref = any;
type PaymentDueDateCell_payment$ref = any;
type PaymentExpenseNameCell_payment$ref = any;
type PaymentMethodCell_payment$ref = any;
type PaymentNoteCell_payment$ref = any;
type PaymentPONumberCell_payment$ref = any;
type PaymentReferenceCell_payment$ref = any;
type PaymentStatusCell_payment$ref = any;
type PaymentTypeCell_payment$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentsTableRenderer_payments$ref: FragmentReference;
export type PaymentsTableRenderer_payments = $ReadOnlyArray<{|
  +id: string,
  +$fragmentRefs: PaymentExpenseNameCell_payment$ref & PaymentTypeCell_payment$ref & PaymentAmountCell_payment$ref & PaymentAmountOfExpenseCell_payment$ref & PaymentStatusCell_payment$ref & PaymentDueDateCell_payment$ref & PaymentActualDateCell_payment$ref & PaymentMethodCell_payment$ref & PaymentPONumberCell_payment$ref & PaymentReferenceCell_payment$ref & PaymentNoteCell_payment$ref & PaymentActionCell_payment$ref,
  +$refType: PaymentsTableRenderer_payments$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PaymentsTableRenderer_payments",
  "type": "Payment",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentExpenseNameCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentTypeCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentAmountCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentAmountOfExpenseCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentStatusCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentDueDateCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentActualDateCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentMethodCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentPONumberCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentReferenceCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentNoteCell_payment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "PaymentActionCell_payment",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '46c4d29da3a999ca998bd106e0c92451';
module.exports = node;
