/* @flow */
import React from 'react';

import registrationUIDefaultProps, {
  type RegistrationUIPropsType,
} from './RegistrationUIDefaultProps';

const UIContext = React.createContext<RegistrationUIPropsType>(registrationUIDefaultProps);

export default UIContext;
