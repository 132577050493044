/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import UsersGroup from 'components/material/UsersGroup';

import type { EventRequestFormsReviewersColumn_requestForm } from './__generated__/EventRequestFormsReviewersColumn_requestForm.graphql';

class EventRequestFormsReviewersColumn extends React.PureComponent<{
  requestForm: EventRequestFormsReviewersColumn_requestForm,
}> {
  render() {
    const users = this.props.requestForm.reviewers.edges.map(({ node }) => node);
    return <UsersGroup users={users} />;
  }
}

export default createFragmentContainer(
  EventRequestFormsReviewersColumn,
  graphql`
    fragment EventRequestFormsReviewersColumn_requestForm on EventRequestForm {
      reviewers {
        edges {
          node {
            id
            ...UsersGroup_users
          }
        }
      }
    }
  `,
);
