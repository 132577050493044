/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import intersection from 'lodash/intersection';
import moment from 'moment';

import downloadedVendors from 'utils/analytics/downloadedVendors';
import type { FieldType } from 'utils/customization/types';

import addVendorAssignments from 'graph/mutations/event/addVendorAssignments';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/budget/Button';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ColumnFilter from 'components/material/ColumnFilters';
import AdvancedFilter from 'components/material/Filters/Advanced/AdvancedFilter';
import Search from 'components/Search';
import ShareDropdown from 'components/ShareDropdown';
import getColumnConfiguration from 'components/Vendors/lib/getColumnConfiguration';
import getVendorFilterConverters from 'components/Vendors/lib/getVendorFilterConverters';
import { type ParsedVendorsFilters } from 'components/Vendors/lib/parseVendorsFilters';
import renderVendorFilter, {
  type VendorFilterInputs,
} from 'components/Vendors/lib/renderVendorFilter';

import AddVendorWindow from './AddVendorWindow';
import EventVendorSearchSuggestion from './EventVendorSearchSuggestion';

import type { EventVendorsHeaderBar_event } from './__generated__/EventVendorsHeaderBar_event.graphql';
import type { EventVendorsHeaderBarQueryResponse } from './__generated__/EventVendorsHeaderBarQuery.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px 15px;
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 30px;
  padding: 9px 20px 8px 20px;
`;

const StyledSearch = styled(Search)`
  max-width: 265px;
  margin: 0 10px;
`;

const ActionsDropdown = styled(ShareDropdown)`
  padding-left: 0;
  border-left: none;
`;

const query = graphql`
  query EventVendorsHeaderBarQuery(
    $eventSlug: String!
    $filters: VendorFilters!
    $columns: [String!]
  ) {
    event(slug: $eventSlug) {
      vendorReportCSVProcessId(filters: $filters, columns: $columns)
    }
    me {
      email
    }
  }
`;

class EventVendorsHeaderBar extends React.Component<
  {
    event: EventVendorsHeaderBar_event,
    history: RouterHistory,
    filters: ParsedVendorsFilters,
    filterInputs: VendorFilterInputs,
    vendorFields: $ReadOnlyArray<FieldType>,
    shownColumns: $ReadOnlyArray<string>,
    onSearch: (query: string) => void,
    onColumnsChange: (shownColumns: $ReadOnlyArray<string>) => void,
    vendorsCount: number,
  },
  {
    adding: boolean,
    showSearchSuggestionBar: boolean,
    showCsvDownloadOverlay: boolean,
  },
> {
  state = {
    adding: false,
    showSearchSuggestionBar: false,
    showCsvDownloadOverlay: false,
  };

  handleSearch = (queryString: string) => {
    this.setState({ showSearchSuggestionBar: true });
    this.props.onSearch(queryString);
  };

  handleSuggestionBarHide = () => {
    this.setState({ showSearchSuggestionBar: false });
  };

  handleSuggestionBarShow = () => {
    this.setState({ showSearchSuggestionBar: true });
  };

  handleAdd = (vendorIds: $ReadOnlyArray<string>) => {
    const { event, filterInputs } = this.props;
    addVendorAssignments(event.id, vendorIds, filterInputs).catch(showModernMutationError);
  };

  handleVendorAddStart = () => {
    this.setState({ adding: true });
  };

  handleVendorAddEnd = () => {
    this.setState({ adding: false });
  };

  handleCsvExport = () => {
    this.setState({ showCsvDownloadOverlay: true });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCsvDownloadOverlay: false });

    downloadedVendors({
      eventId: this.props.event.dbId,
      eventName: this.props.event.name,
      teamId: this.props.event.team.dbId,
      teamName: this.props.event.team.name,
      fromWindow: 'event',
    });
  };

  renderDownloadOverlay = () => {
    const columns = intersection(
      this.props.vendorFields.map(field => field.fieldName || field.id),
      this.props.shownColumns,
    );
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ filters: this.props.filterInputs, columns, eventSlug: this.props.event.slug }}
        renderSuccess={(response: EventVendorsHeaderBarQueryResponse) => {
          return response.event ? (
            <DownloadOverlayWithEmail
              processUUID={response.event.vendorReportCSVProcessId}
              fileName={`Event Vendors Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleCsvDownloadEnd}
              email={response.me.email}
              exportable="vendors_csv"
              onDownload={this.handleCsvExport}
            />
          ) : null;
        }}
        renderLoading={() => null}
      />
    );
  };

  render() {
    const {
      event,
      history,
      shownColumns,
      onColumnsChange,
      filters,
      vendorFields,
      vendorsCount,
    } = this.props;

    return (
      <Container>
        {event.viewerCanCreateVendors && (
          <React.Fragment>
            <StyledButton onClick={this.handleVendorAddStart}>Add Vendor</StyledButton>
            {this.state.adding && (
              <AddVendorWindow
                eventName={event.name}
                onSave={this.handleAdd}
                onCancel={this.handleVendorAddEnd}
                fromWindow="event vendors"
              />
            )}
          </React.Fragment>
        )}
        <StyledSearch
          onSearch={this.handleSearch}
          onBlur={this.handleSuggestionBarHide}
          onFocus={this.handleSuggestionBarShow}
          search={filters.query}
          placeholder="Search vendors"
          suggestionComponent={props => {
            return vendorsCount === 0 ? (
              <EventVendorSearchSuggestion
                {...props}
                showSearchSuggestionBar={this.state.showSearchSuggestionBar}
                eventId={event.id}
              />
            ) : null;
          }}
        />
        <AdvancedFilter
          history={history}
          filters={filters}
          filterOptions={vendorFields}
          converterFn={getVendorFilterConverters}
          filterRenderer={renderVendorFilter}
        />
        <ColumnFilter
          filters={getColumnConfiguration(vendorFields)}
          shownFilters={shownColumns}
          onFilterChange={onColumnsChange}
        />
        <ActionsDropdown
          options={[
            {
              label: 'Download current view (CSV)',
              icon: 'file-excel-o',
              onClick: this.handleCsvExport,
            },
          ]}
        />
        {this.state.showCsvDownloadOverlay && this.renderDownloadOverlay()}
      </Container>
    );
  }
}

export default createFragmentContainer(
  EventVendorsHeaderBar,
  graphql`
    fragment EventVendorsHeaderBar_event on Event {
      id
      dbId
      name
      slug
      viewerCanCreateVendors
      team {
        dbId
        name
      }
    }
  `,
);
