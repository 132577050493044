/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect, Route, Switch } from 'react-router-dom';

import TemplateContent from './TemplateContent';
import TemplateSharing from './TemplateSharing';

import type { EventBriefRoutes_org } from './__generated__/EventBriefRoutes_org.graphql';

class EventBriefRoutes extends React.Component<{
  org: EventBriefRoutes_org,
  pathPrefix: string,
}> {
  render() {
    const { pathPrefix, org } = this.props;

    return (
      <Switch>
        <Route
          path={`${pathPrefix}/content`}
          exact
          render={props => <TemplateContent org={org} {...props} />}
        />
        <Route
          path={`${pathPrefix}/sharing`}
          exact
          render={props => <TemplateSharing org={org} {...props} />}
        />
        <Redirect from={pathPrefix} to={`${pathPrefix}/content`} />
      </Switch>
    );
  }
}

export default createFragmentContainer(
  EventBriefRoutes,
  graphql`
    fragment EventBriefRoutes_org on Org {
      ...TemplateContent_org
      ...TemplateSharing_org
    }
  `,
);
