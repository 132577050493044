/**
 * @flow
 * @relayHash be2506fa264bab9b0ce598fad510d2d1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaskActivities_task$ref = any;
export type TaskActivitiesPaginationQueryVariables = {|
  count: number,
  cursor?: ?string,
  taskSlug: string,
|};
export type TaskActivitiesPaginationQueryResponse = {|
  +deliverable: ?{|
    +$fragmentRefs: TaskActivities_task$ref
  |}
|};
export type TaskActivitiesPaginationQuery = {|
  variables: TaskActivitiesPaginationQueryVariables,
  response: TaskActivitiesPaginationQueryResponse,
|};
*/


/*
query TaskActivitiesPaginationQuery(
  $count: Int!
  $cursor: String
  $taskSlug: String!
) {
  deliverable(slug: $taskSlug) {
    ...TaskActivities_task
    id
  }
}

fragment TaskActivities_task on Deliverable {
  slug
  activities(first: $count, after: $cursor) {
    edges {
      node {
        id
        ...NotificationsList_notifications
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      startCursor
      endCursor
    }
  }
  event {
    tz
    id
  }
}

fragment NotificationsList_notifications on Notification {
  id
  ...NotificationItem_notification
}

fragment NotificationItem_notification on Notification {
  id
  text
  type
  createdAt
  owner {
    ...MaterialAvatar_user
    id
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "taskSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "taskSlug",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TaskActivitiesPaginationQuery",
  "id": null,
  "text": "query TaskActivitiesPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $taskSlug: String!\n) {\n  deliverable(slug: $taskSlug) {\n    ...TaskActivities_task\n    id\n  }\n}\n\nfragment TaskActivities_task on Deliverable {\n  slug\n  activities(first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        ...NotificationsList_notifications\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n  event {\n    tz\n    id\n  }\n}\n\nfragment NotificationsList_notifications on Notification {\n  id\n  ...NotificationItem_notification\n}\n\nfragment NotificationItem_notification on Notification {\n  id\n  text\n  type\n  createdAt\n  owner {\n    ...MaterialAvatar_user\n    id\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TaskActivitiesPaginationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": v1,
        "concreteType": "Deliverable",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TaskActivities_task",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TaskActivitiesPaginationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverable",
        "storageKey": null,
        "args": v1,
        "concreteType": "Deliverable",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "activities",
            "storageKey": null,
            "args": v2,
            "concreteType": "NotificationRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "NotificationRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Notification",
                    "plural": false,
                    "selections": [
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "text",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "owner",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "avatar",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "activities",
            "args": v2,
            "handle": "connection",
            "key": "TaskActivities_activities",
            "filters": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tz",
                "args": null,
                "storageKey": null
              },
              v3
            ]
          },
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2cca40353749aa1c691ad29b8295dc8d';
module.exports = node;
