/* @flow */
import React from 'react';
import styled from 'styled-components';

import { type StaffAccessLevel } from 'config/staffAccessLevels';

import addUserToEvent from 'graph/mutations/access/addUserToEvent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import AccessTypeSelector, { type AccessType } from 'components/InviteWindow/AccessTypeSelector';
import EventSearch, { type Event } from 'components/InviteWindow/InvitationEventSearch';
import EventSelectionRow from 'components/InviteWindow/SelectionRow/EventSelectionRow';
import Button from 'components/material/Button';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';

const AccessRow = styled.div`
  width: 50%;
`;

const Footer = styled.div`
  margin-top: 30px;
  text-align: right;
  button {
    margin-left: 30px;
  }
`;

const SelectionList = styled.div`
  margin-top: 25px;
`;

export default class AddUserToEventsWindow extends React.PureComponent<
  {
    userId: string,
    onHide: () => void,
  },
  {
    selectedEvents: $ReadOnlyArray<Event>,
    accessType: StaffAccessLevel,
  },
> {
  state = {
    selectedEvents: [],
    accessType: 'FULL',
  };

  handleChangeAccessType = (accessType: ?AccessType) => {
    if (accessType != null && accessType !== 'ADMIN' && accessType !== 'TEAM') {
      this.setState({
        accessType,
      });
    }
  };

  handleSelectEvent = (event: ?Event) => {
    this.setState(state => ({
      selectedEvents: event ? [...state.selectedEvents, event] : state.selectedEvents,
    }));
  };

  handleUnselectEvent = (eventId: string) => {
    this.setState(state => ({
      selectedEvents: state.selectedEvents.filter(event => event.id !== eventId),
    }));
  };

  handleSave = () => {
    addUserToEvent(this.props.userId, {
      accessLevel: this.state.accessType,
      eventIds: this.state.selectedEvents.map(event => event.id),
    }).catch(showModernMutationError);

    this.props.onHide();
  };

  render() {
    const { selectedEvents, accessType } = this.state;
    return (
      <Window onHide={this.props.onHide} size="medium">
        <WindowHeader>
          <WindowTitle>Add Member to Events</WindowTitle>
          <WindowClose onClick={this.props.onHide} />
        </WindowHeader>
        <WindowContent>
          <AccessRow>
            <AccessTypeSelector
              value={accessType}
              onChange={this.handleChangeAccessType}
              scope="EVENT"
            />
          </AccessRow>
          <EventSearch
            label="Select Events"
            selectedEventIds={selectedEvents.map(event => event.id)}
            onSelect={this.handleSelectEvent}
          />
          {selectedEvents.length > 0 && (
            <SelectionList>
              {selectedEvents.map(event => (
                <EventSelectionRow
                  key={event.id}
                  event={event}
                  onRemove={this.handleUnselectEvent}
                />
              ))}
            </SelectionList>
          )}
          <Footer>
            <Button label="Cancel" minimal onClick={this.props.onHide} />
            <Button
              label="Save"
              primary
              onClick={this.handleSave}
              disabled={selectedEvents.length === 0}
            />
          </Footer>
        </WindowContent>
      </Window>
    );
  }
}
