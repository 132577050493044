/* @flow */

import React from 'react';
import { createRefetchContainer, graphql, type RelayRefetchProp } from 'react-relay';
import styled from 'styled-components';

import theme from 'config/theme';

import CompanyIcon from 'components/Participants/CompanyIcon';
import NoResultsMessage from 'components/NoResultsMessage';
import Checkbox, { Check } from 'components/material/CheckBox';
import {
  BriefUserEmail,
  BriefUserName,
  BriefUserTitleCompany,
} from '../../BriefView/components/BriefUser';
import AddNewLink from '../AddNewLink';

import VendorsContainer_event from './__generated__/VendorsContainer_event.graphql';

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 25px;
  align-items: flex-start;
  ${Check} {
    margin-top: 3px;
    border-radius: 2px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
`;

class VendorsContainer extends React.Component<{
  relay: RelayRefetchProp,
  event: VendorsContainer_event,
  selectedVendors: $ReadOnlyArray<string>,
  searchQuery: string,
  onAllVendorCheckToggle: (
    isPrevAllCheck: boolean,
    searchedVendorIds: $ReadOnlyArray<string>,
  ) => void,
  handleVendorToggle: (attachmentId: string, isPrevChecked: boolean) => void,
}> {
  componentWillUnmount() {
    this.removeTabChangeListener();
  }

  removeTabChangeListener = () => {
    this.vendorWindow = null;
    document.removeEventListener('visibilitychange', this.documentFocus);
  };

  documentFocus = () => {
    if (document.visibilityState === 'visible') {
      const {
        relay,
        event: { slug: eventSlug },
        searchQuery: query,
      } = this.props;
      relay.refetch({ eventSlug, filters: { query } });
    }
  };

  handleAddNewClick = () => {
    const needToAddListener = !this.vendorWindow;
    this.vendorWindow = window.open(`/events/${this.props.event.slug}/vendors`, '_blank');
    if (needToAddListener) {
      this.vendorWindow.addEventListener('beforeunload', this.removeTabChangeListener);
      document.addEventListener('visibilitychange', this.documentFocus);
    }
  };

  vendorWindow: ?EventTarget;

  renderVendorLabel = vendor => {
    return (
      <Content>
        <CompanyIcon />
        <div>
          <BriefUserName firstName={vendor.firstName} lastName={vendor.lastName} />
          <BriefUserTitleCompany title={vendor.title} company={vendor.name} />
          <BriefUserEmail email={vendor.email} />
        </div>
      </Content>
    );
  };

  render() {
    const {
      event: { allVendors },
      selectedVendors,
      onAllVendorCheckToggle,
      handleVendorToggle,
    } = this.props;
    const allSearchedVendors = allVendors.edges.map(({ node }) => node);
    const allVendorsIds = allSearchedVendors.map(vendor => vendor.id);
    const checkAll = allSearchedVendors.every(vendor => selectedVendors.includes(vendor.id));

    return (
      <React.Fragment>
        <AddNewLink label="Add new vendor" onClick={this.handleAddNewClick} />
        {allSearchedVendors.length > 0 ? (
          <React.Fragment>
            <StyledCheckbox
              label="All"
              key={0}
              checked={checkAll}
              checkColor={checkAll ? theme.highlightColor : theme.silver}
              onChange={() => onAllVendorCheckToggle(checkAll, allVendorsIds)}
            />
            {allSearchedVendors.map(vendor => {
              const checked = selectedVendors.includes(vendor.id);
              return (
                <StyledCheckbox
                  key={vendor.id}
                  checked={checked}
                  checkColor={checked ? theme.highlightColor : theme.silver}
                  labelRenderer={() => this.renderVendorLabel(vendor)}
                  onChange={() => handleVendorToggle(vendor.id, checked)}
                />
              );
            })}
          </React.Fragment>
        ) : (
          <NoResultsMessage iconName="user-o" message="Vendor not found." />
        )}
      </React.Fragment>
    );
  }
}

export default createRefetchContainer(
  VendorsContainer,
  graphql`
    fragment VendorsContainer_event on Event {
      slug
      allVendors: vendors(filters: $filters) {
        edges {
          node {
            id
            firstName
            lastName
            email
            name
          }
        }
      }
    }
  `,
  graphql`
    query VendorsContainerRefetchQuery($eventSlug: String!, $filters: VendorFilters!) {
      event(slug: $eventSlug) {
        id
        ...VendorsContainer_event
      }
    }
  `,
);
