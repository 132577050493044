/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  createTeamMutationResponse,
  createTeamMutationVariables,
} from './__generated__/createTeamMutation.graphql';

const mutation = graphql`
  mutation createTeamMutation($input: CreateTeamInput!) {
    createTeam(input: $input) {
      user {
        id
        email
        teams {
          totalCount
        }
      }
      teamEdge {
        node {
          id
          dbId
          slug
          name
          viewerCanUpdate
          ...TeamBudgeting_teams
          org {
            ...BudgetSetup_org
          }
        }
        __typename
      }
    }
  }
`;

type CastMaybe<T> = (?T) => T;
type DefiniteResponse = $ObjMap<createTeamMutationResponse, CastMaybe<any>>;
type CreateTeam = $PropertyType<DefiniteResponse, 'createTeam'>;
type TeamEdge = $PropertyType<CreateTeam, 'teamEdge'>;
type Team = $PropertyType<TeamEdge, 'node'>;

export default function createTeam(
  teamName: string,
  fromWindow: 'onboarding' | 'easy_button',
  userId: string,
): Promise<Team> {
  const variables: createTeamMutationVariables = {
    input: {
      fromWindow,
      name: teamName,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: userId,
      edgeName: 'teamEdge',
      connections: [{ field: 'teams' }],
    }),
  }).then((response: createTeamMutationResponse) => {
    if (!response.createTeam) {
      return Promise.reject();
    }

    return response.createTeam.teamEdge.node;
  });
}
