import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import { createFragmentContainer, graphql } from 'react-relay';
import ClickOutside from 'react-onclickout';
import { withRouter } from 'react-router';

import formatTimeRange from 'utils/date/formatTimeRange';
import fullNameOfUser from 'utils/fullNameOfUser';

import RichText from 'components/RichText';
import { Button } from 'components/form/Button';

class SchedulesTimelineItem extends React.Component {
  static propTypes = {
    item: PropTypes.shape({
      start_time: PropTypes.string.isRequired,
      end_time: PropTypes.string,
      all_day: PropTypes.bool.isRequired,
      title: PropTypes.string,
      location: PropTypes.string,
      description: PropTypes.string,
      scheduleParticipants: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              user: PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
              }).isRequired,
            }).isRequired,
          }),
        ).isRequired,
      }).isRequired,
      scheduleDay: PropTypes.shape({
        schedule: PropTypes.shape({
          slug: PropTypes.string.isRequired,
          event: PropTypes.shape({
            slug: PropTypes.string.isRequired,
          }).isRequired,
        }),
      }).isRequired,
    }).isRequired,
    styles: PropTypes.object,
    time_zone: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    active: false,
  };

  handleClick = () => {
    this.setState({ active: true });
  };

  handlePopoverHide = () => {
    this.setState({ active: false });
  };

  handleViewClick = () => {
    const { schedule } = this.props.item.scheduleDay;
    const { event } = schedule;
    this.props.history.push(`/events/${event.slug}/schedules/${schedule.slug}`);
  };

  render() {
    const { styles, item, time_zone } = this.props;
    const { active } = this.state;

    const participants = item.scheduleParticipants.edges.map(x => x.node.user);

    return (
      <div style={styles} className="schedules-timeline-item">
        <div className="schedules-timeline-item-body" onClick={this.handleClick}>
          <strong title={item.title}>{item.title}</strong>
        </div>
        {active && (
          <ClickOutside onClickOut={this.handlePopoverHide}>
            <div className="schedules-timeline-item-popover">
              <div className="schedules-timeline-item-popover-body">
                {item.title && <header>{item.title}</header>}
                <div className="schedules-timeline-item-popover-details">
                  <p>
                    <strong>Time</strong>
                    <span>
                      {item.all_day
                        ? 'All Day'
                        : formatTimeRange(
                            item.start_time && moment.tz(item.start_time, time_zone),
                            item.end_time && moment.tz(item.end_time, time_zone),
                          )}
                    </span>
                  </p>
                  {item.location && (
                    <p>
                      <strong>Location</strong>
                      <span title={item.location}>{item.location}</span>
                    </p>
                  )}
                  {participants.length > 0 && (
                    <p>
                      <strong>Participants</strong>
                      <span>{participants.map(user => fullNameOfUser(user)).join(', ')}</span>
                    </p>
                  )}
                </div>
                {item.description && (
                  <div className="schedules-timeline-item-popover-description">
                    <RichText defaultValue={item.description} viewMode />
                  </div>
                )}
              </div>
              <footer>
                <Button text="Cancel" minimal onClick={this.handlePopoverHide} />
                <Button text="View" primary onClick={this.handleViewClick} />
              </footer>
            </div>
          </ClickOutside>
        )}
      </div>
    );
  }
}

export default createFragmentContainer(
  withRouter(SchedulesTimelineItem),
  graphql`
    fragment SchedulesTimelineItem_item on ScheduleItem {
      start_time
      end_time
      all_day
      title
      location
      description
      scheduleParticipants {
        edges {
          node {
            user {
              firstName
              lastName
              email
            }
          }
        }
      }
      scheduleDay {
        schedule {
          slug
          event {
            slug
          }
        }
      }
    }
  `,
);
