/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect, Route, Switch } from 'react-router-dom';

import FeatureAccessContext from 'contexts/FeatureAccess';

import Budget from './Budget';
import ContactSettings from './ContactSettings';
import VendorSettings from './VendorSettings';
import EventSettings from './EventSettings';
import Integrations from './Integrations';
import OrgStyle from './OrgStyle';

import { type SettingsRoutes_org } from './__generated__/SettingsRoutes_org.graphql';

type Props = { org: SettingsRoutes_org, pathPrefix: string };

class SettingsRoutes extends React.Component<Props> {
  static contextType = FeatureAccessContext;

  render() {
    const { org, pathPrefix } = this.props;
    return (
      <Switch>
        <Route path={`${pathPrefix}/general`} exact component={OrgStyle} />
        <Route
          path={`${pathPrefix}/events`}
          render={props => <EventSettings {...props} org={org} />}
        />
        {this.context.legacyFeatures ? (
          <Route
            path={`${pathPrefix}/contacts`}
            render={props => <ContactSettings org={org} {...props} />}
          />
        ) : (
          <Route path={`${pathPrefix}/vendors`} component={VendorSettings} />
        )}
        <Route path={`${pathPrefix}/budgets/:period?`} component={Budget} />
        <Route
          path={`${pathPrefix}/integrations`}
          render={props => <Integrations {...props} orgName={org.name} />}
        />
        <Redirect from={pathPrefix} to={`${pathPrefix}/general`} />
      </Switch>
    );
  }
}

export default createFragmentContainer(
  SettingsRoutes,
  graphql`
    fragment SettingsRoutes_org on Org {
      name
      ...ContactSettings_org
    }
  `,
);
