/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  removeEventListMutationResponse,
  removeEventListMutationVariables,
} from './__generated__/removeEventListMutation.graphql';

const mutation = graphql`
  mutation removeEventListMutation($input: RemoveEventListInput!) {
    removeEventList(input: $input) {
      removedEventListId
      user {
        eventLists {
          totalCount
        }
      }
    }
  }
`;

export default function removeEventList(
  parentId: string,
  eventListId: string,
  eventListsCount: number,
): Promise<removeEventListMutationResponse> {
  const variables: removeEventListMutationVariables = {
    input: {
      eventListId,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      removeEventList: {
        removedEventListId: eventListId,
        user: {
          id: parentId,
          eventLists: {
            totalCount: eventListsCount - 1,
          },
        },
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedEventListId',
        parentId,
        connections: [{ field: 'eventLists' }, { key: 'DashboardMenuSavedViews_eventLists' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedEventListId' }),
    ),
  });
}
