/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AllEventsActionButtons_org$ref = any;
type AllEventsViewActions_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsHeaderBar_org$ref: FragmentReference;
export type AllEventsHeaderBar_org = {|
  +$fragmentRefs: AllEventsActionButtons_org$ref & AllEventsViewActions_org$ref,
  +$refType: AllEventsHeaderBar_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "AllEventsHeaderBar_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AllEventsActionButtons_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AllEventsViewActions_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c840e42200037ede3a2c96348ef63285';
module.exports = node;
