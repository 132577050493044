/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import Tooltip from 'components/material/Tooltip';

const Label = styled.div`
  margin-left: 7px;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #d8d8d8;
  white-space: nowrap;
`;

const ViewMode = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  color: #5a6576;
  -webkit-tap-highlight-color: transparent;
  ${props =>
    props.active &&
    css`
      font-weight: bold;
      color: #373737;
    `};
  &:hover {
    color: #373737;
  }
  img,
  svg {
    opacity: ${props => (props.active ? 1 : 0.8)};
  }

  &:last-of-type {
    ${Label} {
      border: 0;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  @media (${props => props.theme.mobileOnly}) {
    flex: 1 1 auto;
  }
`;

const StyledTooltip = styled(Tooltip)`
  width: 25px;
  font-size: 16px;
  cursor: pointer;
  color: ${props =>
    props.active ? props.theme.secondaryActionDarkerColor : props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
  img,
  svg {
    opacity: ${props => (props.active ? 1 : 0.4)};
  }
`;

type Mode = {
  icon?: string,
  img?: string,
  svg?: React.ElementType,
  value: string,
  label: string,
};

export default class ViewModes extends React.PureComponent<{
  viewMode: string,
  onViewModeChange: string => void,
  modes: Array<Mode>,
  className?: string,
  // Remove withTooltip logic with tooltip when deprecating tasks logic
  withTooltip?: boolean,
}> {
  renderIcons = (mode: Mode) => {
    return (
      <>
        {mode.icon && <i className={`fa fa-fw fa-${mode.icon}`} />}
        {mode.img && <img src={mode.img} alt={mode.label} />}
        {mode.svg && <mode.svg />}
      </>
    );
  };

  render() {
    return (
      <Container className={this.props.className}>
        {this.props.modes.map(mode => (
          <ViewMode
            key={mode.value}
            active={this.props.viewMode === mode.value}
            onClick={
              this.props.viewMode === mode.value
                ? undefined
                : () => this.props.onViewModeChange(mode.value)
            }
          >
            {this.props.withTooltip ? (
              <StyledTooltip
                active={this.props.viewMode === mode.value}
                label={mode.label}
                placement="top"
              >
                {this.renderIcons(mode)}
              </StyledTooltip>
            ) : (
              <>
                {this.renderIcons(mode)}
                <Label>{mode.label}</Label>
              </>
            )}
          </ViewMode>
        ))}
      </Container>
    );
  }
}
