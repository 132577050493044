/* @flow */
import React from 'react';
import styled from 'styled-components';

import insertTextIntoTextField from 'utils/insertTextIntoTextField';

import TextField from 'components/material/TextField';
import EditableContainer from '../form/EditableContainer';

const Container = styled.div`
  margin: 30px 0;
`;

const Title = styled.div`
  font-size: 16px;
  color: rgb(74, 86, 101, 0.87);
  font-weight: 500;
`;

const StyledEditableContainer = styled(EditableContainer)`
  padding: 15px 30px;
  margin: 25px -30px 15px;
`;

const Preview = styled.div`
  margin-right: 20px;
  font-size: 13px;
  line-height: 1.8;
  color: #4a5665;
  white-space: pre-line;
`;

const Edit = styled.div`
  margin-top: -16px;
  font-size: 13px;
`;

const Tags = styled.div`
  margin-top: -5px;
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  margin-right: 10px;
  padding: 0 7px;
  background: #f1f9fc;
  border: 1px solid #daecf3;
  border-radius: 3px;
  line-height: 1.6;
  color: #616c79;
  cursor: pointer;
  &:hover {
    background: #daecf3;
  }
`;

export default class EditableEmailTemplate extends React.PureComponent<{
  title: string,
  template: string,
  onChange: (template: string) => void,
  onSave?: () => void,
  tags: $ReadOnlyArray<string>,
}> {
  handleSave = () => {
    if (this.props.template.trim()) {
      if (this.props.onSave) {
        this.props.onSave();
      }
      return true;
    }
    return false;
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange(e.currentTarget.value);
  };

  handleSelectTag = (e: SyntheticEvent<HTMLDivElement>, tag: string) => {
    e.preventDefault();

    if (!this.input) return;

    this.props.onChange(insertTextIntoTextField(this.input, `{{${tag}}}`));
  };

  input: HTMLInputElement;

  render() {
    return (
      <Container>
        <Title>{this.props.title}</Title>

        <StyledEditableContainer
          defaultPreviewing
          previewContent={<Preview>{this.props.template}</Preview>}
          editContent={
            <Edit>
              <TextField
                value={this.props.template}
                multiline
                autoFocus
                onChange={this.handleChange}
                inputRef={input => {
                  this.input = input;
                }}
              />
              <Tags>
                {this.props.tags.map(tag => (
                  <Tag key={tag} onMouseDown={e => this.handleSelectTag(e, tag)}>
                    {tag}
                  </Tag>
                ))}
              </Tags>
            </Edit>
          }
          onSave={this.handleSave}
        />
      </Container>
    );
  }
}
