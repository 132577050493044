/* @flow */
import enqueueCall from './enqueueCall';

const page = (path: string, options: Object) => {
  enqueueCall(() => {
    window.analytics.page(path, options);
  });
};

export default page;
