/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

const Step = styled.div`
  position: relative;
  width: ${props => (props.showStepNumber ? 70 : 143)}px;

  &:not(:last-child) {
    margin-right: 44px;

    &:after {
      content: '';
      position: absolute;
      top: 19px;
      right: -32px;
      display: block;
      width: 20px;
      height: 1px;
      background: #d0d4d7;
    }
  }
`;

const StepPill = styled.div`
  padding: 7px 12px;
  border: 1px solid #cdd1d4;
  border-radius: 50px;
  color: #cdd1d4;
  text-align: center;
  outline: none;

  ${props =>
    props.passed &&
    css`
      border-color: #5be68e;
      color: rgba(62, 72, 89, 0.67);
      background: #f4f4f4;
    `}

  ${props =>
    props.active &&
    css`
      font-weight: 500;
      border-color: #3ba9da;
      color: #3ba9da;
    `}

    ${props =>
    props.onClick &&
    css`
      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 1px #5be68e;
        cursor: pointer;
      }
    `}
`;

const StepLabel = styled.div`
  margin: 10px -20px;
  text-align: center;
  font-size: 13px;
  color: #a5afb7;

  ${props =>
    props.active &&
    css`
      font-weight: 500;
      color: #3ba9da;
    `}
`;

export default class StepProgress extends React.PureComponent<{
  steps: $ReadOnlyArray<string>,
  current: number,
  onChange?: number => void,
  showStepNumber?: boolean,
}> {
  render() {
    const { steps, current, onChange, showStepNumber } = this.props;

    return (
      <Root>
        {steps.map((step, index) => (
          <Step key={step} showStepNumber={showStepNumber}>
            <StepPill
              active={current === index}
              passed={current > index}
              onClick={
                onChange && current > index
                  ? event => {
                      event.currentTarget.blur();

                      onChange(index);
                    }
                  : undefined
              }
              tabIndex={onChange && current > index ? 0 : -1}
            >
              {showStepNumber ? index + 1 : step}
            </StepPill>

            {showStepNumber && <StepLabel active={current === index}>{step}</StepLabel>}
          </Step>
        ))}
      </Root>
    );
  }
}
