/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import DisabledIcon from 'images/syncIcons/disabledIcon.svg';
import FailedIcon from 'images/syncIcons/failedIcon.svg';
import PendingIcon from 'images/syncIcons/pendingIcon.svg';
import SyncedIcon from 'images/syncIcons/syncedIcon.svg';
import Loader from 'components/Loader';

import { type EventCRMSyncedContacts_event } from './__generated__/EventCRMSyncedContacts_event.graphql';

const Root = styled.div`
  padding: 25px 0;
`;

const Title = styled.div`
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 600;
  color: #3e4859;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 382px;
  height: 40px;
  margin-top: 10px;
  padding: 0 45px 0 50px;
  background: #f8f9f9;
  color: #3e4859;

  a {
    font-size: 13px;
    color: #3ba9da;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const RowIcon = styled.div`
  width: 36px;
`;

const RowLabel = styled.div`
  width: 90px;
`;

const RowCount = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 13px;

  ${props =>
    props.failed &&
    css`
      color: #f48183;
    `}
`;

const STATUSES = {
  OK: {
    label: 'Synced',
    count: event => event.syncedContacts.totalCount,
    icon: <SyncedIcon />,
  },
  DISABLED: {
    label: 'Disabled',
    count: event => event.disabledContacts.totalCount,
    icon: <DisabledIcon />,
  },
  FAILING: {
    label: 'Failed',
    count: event => event.failedContacts.totalCount,
    icon: <FailedIcon />,
  },
  PENDING: {
    label: 'Pending',
    count: event => event.pendingContacts.totalCount,
    icon: <PendingIcon />,
  },
};

const EventCRMSyncedContacts = ({ event }: { event: EventCRMSyncedContacts_event }) => {
  const empty = Object.keys(STATUSES).every(key => STATUSES[key].count(event) === 0);

  if (empty && event.allContacts.totalCount === 0) return null;

  return (
    <Root>
      <Title>Contacts Membership Status</Title>

      {empty && <Loader size={20} />}

      {Object.keys(STATUSES).map(key => {
        const count = STATUSES[key].count(event);

        if (count === 0) return null;

        return (
          <Row key={key}>
            <RowIcon>{STATUSES[key].icon}</RowIcon>

            <RowLabel>{STATUSES[key].label}</RowLabel>

            <RowCount failed={key === 'FAILING'}>{count}</RowCount>

            <Link to={`/events/${event.slug}/contacts/people?membership_sync_status=${key}`}>
              View Contacts
            </Link>
          </Row>
        );
      })}
    </Root>
  );
};

export default createFragmentContainer(
  EventCRMSyncedContacts,
  graphql`
    fragment EventCRMSyncedContacts_event on Event {
      id
      slug

      allContacts: eventContacts {
        totalCount
      }

      syncedContacts: eventContacts(filters: { membershipSyncStatuses: [OK] }) {
        totalCount
      }

      disabledContacts: eventContacts(filters: { membershipSyncStatuses: [DISABLED] }) {
        totalCount
      }

      failedContacts: eventContacts(filters: { membershipSyncStatuses: [FAILING] }) {
        totalCount
      }

      pendingContacts: eventContacts(filters: { membershipSyncStatuses: [PENDING] }) {
        totalCount
      }
    }
  `,
);
