/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SharedColumnComponent_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SharedRowComponent_registrationForm$ref: FragmentReference;
export type SharedRowComponent_registrationForm = {|
  +$fragmentRefs: SharedColumnComponent_registrationForm$ref,
  +$refType: SharedRowComponent_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SharedRowComponent_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "SharedColumnComponent_registrationForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a33b22c50b820b564617bfc48b028b33';
module.exports = node;
