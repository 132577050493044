/**
 * @flow
 * @relayHash 95c06ea106e3b5aaddc982aa2ac1909a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DisconnectSalesforceAccountInput = {
  clientMutationId?: ?string
};
export type disconnectSalesforceAccountMutationVariables = {|
  input: DisconnectSalesforceAccountInput
|};
export type disconnectSalesforceAccountMutationResponse = {|
  +disconnectSalesforceAccount: ?{|
    +updatedOrg: {|
      +id: string,
      +salesforceAccount: ?{|
        +id: string
      |},
    |}
  |}
|};
export type disconnectSalesforceAccountMutation = {|
  variables: disconnectSalesforceAccountMutationVariables,
  response: disconnectSalesforceAccountMutationResponse,
|};
*/


/*
mutation disconnectSalesforceAccountMutation(
  $input: DisconnectSalesforceAccountInput!
) {
  disconnectSalesforceAccount(input: $input) {
    updatedOrg {
      id
      salesforceAccount {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DisconnectSalesforceAccountInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "disconnectSalesforceAccount",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DisconnectSalesforceAccountInput!"
      }
    ],
    "concreteType": "DisconnectSalesforceAccountPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedOrg",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v1
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "disconnectSalesforceAccountMutation",
  "id": null,
  "text": "mutation disconnectSalesforceAccountMutation(\n  $input: DisconnectSalesforceAccountInput!\n) {\n  disconnectSalesforceAccount(input: $input) {\n    updatedOrg {\n      id\n      salesforceAccount {\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "disconnectSalesforceAccountMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "disconnectSalesforceAccountMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1936ec16cc1530a3fe9912ce6a91435c';
module.exports = node;
