/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import insertTextIntoTextField from 'utils/insertTextIntoTextField';
import sanitizeHtml from 'utils/string/sanitizeHtml';

import TextField from 'components/material/TextField';
import TinyRichText, { StyledContent } from 'components/TinyRichText';
import UIContext from 'views/Main/Event/Registration/UIContext';

import EditableContainer from '../EditableContainer';

const Container = styled.div`
  margin-bottom: 30px;
`;

const StyledEditableContainer = styled(EditableContainer)`
  padding: 15px 30px;
  margin: 0 -30px 15px;
`;

const Subject = styled.div`
  margin-bottom: 30px;
  font-weight: 500;
  color: #4a5665;
`;

const Preview = styled.div`
  margin-right: 20px;
  font-size: 13px;
  line-height: 1.8;
  color: #4a5665;
  white-space: pre-line;
  img {
    max-width: 100%;
    height: auto;
  }
`;

const Edit = styled.div`
  font-size: 13px;
`;

const EmailBodyText = styled(TextField)`
  margin-top: 30px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-bottom: 10px;
`;

const Tag = styled.div`
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 0 7px;
  background: #f1f9fc;
  border: 1px solid #daecf3;
  border-radius: 3px;
  line-height: 1.6;
  color: #616c79;
  cursor: pointer;
  &:hover {
    background: #daecf3;
  }
`;

export const StyledTinyRichText = styled(TinyRichText)`
  margin-top: 30px;
  ${props =>
    props.withCustomToolbar &&
    css`
      .tox .tox-toolbar:first-child {
        background: #fff;
        border-radius: 0;

        .tox-toolbar__group {
          padding: 7px 0;

          .tox-tbtn {
            height: auto;
            margin-right: 10px;
            padding: 0 7px;
            border-color: #daecf3 !important;
            font-size: 13px;
            line-height: 1.6;
            background: #f1f9fc;
            color: #616c79;
            cursor: pointer;

            &:hover {
              background: #daecf3;
            }
          }
        }
      }
    `}}
`;

export default class EditableEmailTemplate extends React.PureComponent<{
  subject: string,
  subjectPreview?: string,
  template: string,
  templatePreview?: string,
  onChangeSubject: (subject: string) => void,
  onChange: (template: string) => void,
  onSave?: () => void,
  tags: $ReadOnlyArray<string>,
  richEditor?: boolean,
  error?: ?string | ?boolean,
}> {
  subjectInput: HTMLInputElement;

  input: HTMLInputElement;

  handleSave = () => {
    if (this.props.template.trim()) {
      if (this.props.onSave) {
        this.props.onSave();
      }
      return true;
    }
    return false;
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange(e.currentTarget.value);
  };

  handleChangeSubject = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeSubject(e.currentTarget.value);
  };

  handleSelectTag = (e: SyntheticEvent<HTMLDivElement>, tag: string) => {
    e.preventDefault();

    if (!this.input) return;

    this.props.onChange(insertTextIntoTextField(this.input, `{{${tag}}}`));
  };

  handleSelectSubjectTag = (e: SyntheticEvent<HTMLDivElement>, tag: string) => {
    e.preventDefault();

    if (!this.subjectInput) return;

    this.props.onChangeSubject(insertTextIntoTextField(this.subjectInput, `{{${tag}}}`));
  };

  handleTemplateSave = (content: string) => {
    this.props.onChange(content);
  };

  render() {
    const {
      richEditor,
      error,
      subject,
      subjectPreview,
      template,
      templatePreview,
      tags,
    } = this.props;

    return (
      <Container>
        <StyledEditableContainer
          defaultPreviewing
          previewContent={
            <>
              <Subject>{subjectPreview || subject}</Subject>
              {richEditor ? (
                <StyledContent
                  style={{ marginRight: 20 }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(templatePreview || template, {
                      extendedTags: ['img'],
                    }),
                  }}
                />
              ) : (
                <Preview>{template}</Preview>
              )}
            </>
          }
          editing={false}
          editContent={
            <Edit>
              <TextField
                label="Email Subject"
                value={subject}
                multiline
                autoFocus
                onChange={this.handleChangeSubject}
                inputRef={subjectInput => {
                  this.subjectInput = subjectInput;
                }}
              />
              <Tags>
                {tags.map(tag => (
                  <Tag key={tag} onMouseDown={e => this.handleSelectSubjectTag(e, tag)}>
                    {tag}
                  </Tag>
                ))}
              </Tags>
              {richEditor ? (
                <UIContext.Consumer>
                  {({ loaderColor, fontFamily }) => (
                    <StyledTinyRichText
                      label="Email Body"
                      error={error}
                      required
                      value={template}
                      imageEnabled
                      withCustomToolbar
                      customToolbar={tags.map(tag => ({
                        key: tag.replace(/\s/g, ''),
                        tooltip: `Insert ${tag}`,
                        label: tag,
                      }))}
                      initOptions={{
                        content_style: `@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans');
                          .mce-content-body {
                                font-family: ${fontFamily || 'Roboto'};
                              }`,
                        min_height: 125,
                      }}
                      onChange={this.handleTemplateSave}
                      loaderColor={loaderColor}
                    />
                  )}
                </UIContext.Consumer>
              ) : (
                <>
                  <EmailBodyText
                    label="Email Body"
                    value={template}
                    multiline
                    onChange={this.handleChange}
                    inputRef={input => {
                      this.input = input;
                    }}
                  />
                  <Tags>
                    {tags.map(tag => (
                      <Tag key={tag} onMouseDown={e => this.handleSelectTag(e, tag)}>
                        {tag}
                      </Tag>
                    ))}
                  </Tags>
                </>
              )}
            </Edit>
          }
          onSave={this.handleSave}
        />
      </Container>
    );
  }
}
