/* @flow */
import React from 'react';

import COUNTRIES from 'config/countries.json';
import US_STATES from 'config/us_states.json';

import { type FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';

import EditableLinkField from 'components/material/EditableLinkField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';

import { type CompanyDefaultFields } from './CompanyFormFields';

type Props = {
  company: CompanyDefaultFields,
  // eslint-disable-next-line
  viewerCanUpdate: boolean,
  handleUpdate: (changes: $Shape<CompanyDefaultFields>) => void,
  field: FieldType,
  errors: { [string]: string },
};

const countries = COUNTRIES.map(country => ({ label: country, value: country }));
const usStates = Object.keys(US_STATES).map(k => ({
  label: US_STATES[k],
  value: US_STATES[k],
}));

const suggestedFields = {
  street: ({ company, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={company.street}
      onBlur={e => {
        if (company.street !== e.currentTarget.value) {
          handleUpdate({ street: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.street}
      disabled={!viewerCanUpdate}
    />
  ),
  city: ({ company, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={company.city}
      onBlur={e => {
        if (company.city !== e.currentTarget.value) {
          handleUpdate({ city: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.city}
      disabled={!viewerCanUpdate}
    />
  ),
  description: ({ company, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      multiline
      defaultValue={company.description}
      onBlur={e => {
        if (company.description !== e.currentTarget.value) {
          handleUpdate({ description: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.description}
      disabled={!viewerCanUpdate}
    />
  ),
  zip: ({ company, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={company.zip}
      onBlur={e => {
        if (company.zip !== e.currentTarget.value) {
          handleUpdate({ zip: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.zip}
      disabled={!viewerCanUpdate}
    />
  ),
  website: ({ company, handleUpdate, field, viewerCanUpdate, errors }: Props) => (
    <EditableLinkField
      defaultValue={company.website}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== company.website) {
          e.currentTarget.value = value;
          handleUpdate({ website: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.website}
    />
  ),
  twitter: ({ company, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={company.twitter}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== company.twitter) {
          e.currentTarget.value = value;
          handleUpdate({ twitter: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.twitter}
    />
  ),
  linkedin: ({ company, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={company.linkedin}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== company.linkedin) {
          e.currentTarget.value = value;
          handleUpdate({ linkedin: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.linkedin}
    />
  ),
  phone: ({ company, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={company.phone}
      onBlur={e => {
        const value = e.currentTarget.value.trim();
        if (company.phone !== value) {
          handleUpdate({ phone: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.phone}
      to={`tel:${company.phone || ''}`}
    />
  ),
  state: ({ company, handleUpdate, viewerCanUpdate, field, errors }: Props) =>
    company.country === 'United States' ? (
      <SelectField
        onChange={state => {
          if (state !== company.state) {
            handleUpdate({ state });
          }
        }}
        label={field.label}
        value={company.state}
        options={usStates}
        searchable
        clearable
        disabled={!viewerCanUpdate}
        required={field.required}
        error={errors.state}
      />
    ) : (
      <TextField
        defaultValue={company.state}
        onBlur={e => {
          if (company.state !== e.currentTarget.value) {
            handleUpdate({ state: e.currentTarget.value });
          }
        }}
        label={field.label}
        name={field.fieldName || ''}
        required={field.required}
        error={errors.state}
        disabled={!viewerCanUpdate}
      />
    ),
  country: ({ company, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <SelectField
      label={field.label}
      value={company.country}
      onChange={country => {
        if (country !== company.country) {
          handleUpdate({ country });
        }
      }}
      options={countries}
      searchable
      clearable
      disabled={!viewerCanUpdate}
      required={field.required}
      error={errors.country}
    />
  ),
};

export default suggestedFields;
