/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineCheckBoxField from 'components/budget/Table/InlineCheckBoxField';

import type { ContactHotLead_contact } from './__generated__/ContactHotLead_contact.graphql';

class ContactHotLead extends React.Component<{
  contact: ContactHotLead_contact,
  fieldSettings: FieldType,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
  updateColumnWidth: () => void,
}> {
  handleUpdate = (value: ?boolean) => {
    if (value !== this.props.contact.hotLead) {
      this.props.onUpdate({ hotLead: value });
      this.props.updateColumnWidth();
    }
  };

  render() {
    const { contact, fieldSettings } = this.props;
    return (
      <InlineCheckBoxField
        value={contact.hotLead}
        onChange={this.handleUpdate}
        disabled={fieldSettings.required || fieldSettings.restrictChangingValue}
      />
    );
  }
}

export default createFragmentContainer(
  ContactHotLead,
  graphql`
    fragment ContactHotLead_contact on Contact {
      hotLead
    }
  `,
);
