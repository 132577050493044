/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import type { CompanyFieldInput } from 'graph/mutations/company/updateCompany';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';
import Tooltip from 'components/material/Tooltip';

import type { CompanyName_company } from './__generated__/CompanyName_company.graphql';
import type { CompanyName_event } from './__generated__/CompanyName_event.graphql';

const SyncIcon = styled.i`
  font-size: 12px;
  color: ${props => props.theme.primaryActionColor};
`;

const IconContainer = styled.div`
  position: absolute;
  right: 2px;
  top: 16px;
`;

class name extends React.Component<{
  company: CompanyName_company,
  event: ?CompanyName_event,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<CompanyFieldInput>) => Promise<void>,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?string) => {
    if (!this.getError(value) && value !== this.props.company.name) {
      this.props.onUpdate({ name: value });
      this.props.updateColumnWidth();
    }
  };

  profilePath = () => {
    const { event, company } = this.props;
    return event != null
      ? `/events/${event.slug}/contacts/companies/${company.id}`
      : `/contacts/companies/${company.id}`;
  };

  getError = (value: ?string): ?string => {
    if (!value || !value.trim()) {
      return 'Required';
    }
    return null;
  };

  renderDisabledIcon = (): React.Node => {
    const { company } = this.props;
    return (
      <IconContainer>
        <Tooltip label="Salesforce account" placement="top">
          {company.salesforceId && <SyncIcon className="fa fa-fw fa-refresh" />}
        </Tooltip>
      </IconContainer>
    );
  };

  render() {
    const { company, fieldSettings, updateColumnWidth } = this.props;

    if (!company) {
      return null;
    }

    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={company.name || ''}
        getError={this.getError}
        onChange={this.handleUpdate}
        updateColumnWidth={updateColumnWidth}
        disabled={!!company.salesforceId}
        disabledIcon={this.renderDisabledIcon()}
      >
        <InlineExpandableText>
          <Link to={this.profilePath()} onMouseDown={this.handleMouseDown}>
            {company.name}
          </Link>
        </InlineExpandableText>
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  name,
  graphql`
    fragment CompanyName_company on Company {
      id
      name
      salesforceId
    }

    fragment CompanyName_event on Event {
      slug
    }
  `,
);
