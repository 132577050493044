import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import difference from 'lodash/difference';

import type { BriefSharingGraphValue } from 'config/eventBriefSharingOptions';

import saveEventBrief from 'graph/mutations/event_brief/saveEventBrief';
import showModernMutationError from 'graph/utils/showModernMutationError';

import BriefBuilder from './BriefBuilder';
import BriefHeaderActionsBar from './BriefHeaderActionsBar';
import BriefView from './BriefView';

import { type BriefContainer_event } from './__generated__/BriefContainer_event.graphql';
import { type BriefContainer_me } from './__generated__/BriefContainer_me.graphql';
import { type BriefContainer_org } from './__generated__/BriefContainer_org.graphql';

const Container = styled.div`
  display: flex;
  height: ${props => (props.viewerCanUpdate ? 'calc(100vh - 55px)' : '100%')};
`;

const getItemIds = (item: {|
  +edges: $ReadOnlyArray<{| +node: {| +id: string |} |}>,
|}): $ReadOnlyArray<string> => item.edges.map(({ node }) => node.id);

class BriefContainer extends React.Component<
  {
    event: BriefContainer_event,
    org: BriefContainer_org,
    me: BriefContainer_me,
  },
  {
    selectedNotes: $ReadOnlyArray<string>,
    selectedAttachments: $ReadOnlyArray<string>,
    selectedContacts: $ReadOnlyArray<string>,
    selectedVendors: $ReadOnlyArray<string>,
    selectedCompanies: $ReadOnlyArray<string>,
    editing: boolean,
    disableSave: boolean,
  },
> {
  state = {
    selectedNotes: getItemIds(this.props.event.briefSettings.notes),
    selectedAttachments: getItemIds(this.props.event.briefSettings.attachments),
    selectedContacts: getItemIds(this.props.event.briefSettings.contacts),
    selectedVendors: getItemIds(this.props.event.briefSettings.vendors),
    selectedCompanies: getItemIds(this.props.event.briefSettings.companies),
    accessibility: this.props.event.briefSettings.accessibility,
    editing: false,
    disableSave: true,
  };

  initialState = this.state;

  handleBriefSave = () => {
    this.setState({ disableSave: true });

    saveEventBrief(
      {
        accessibility: this.state.accessibility,
        noteIds: this.state.selectedNotes,
        contactIds: this.state.selectedContacts,
        vendorIds: this.state.selectedVendors,
        companyIds: this.state.selectedCompanies,
        attachmentIds: this.state.selectedAttachments,
      },
      this.props.event.id,
    )
      .then(() => {
        this.initialState = this.state;
      })
      .catch(showModernMutationError);
  };

  handleBriefCancel = () => {
    this.setState({ ...this.initialState, disableSave: true, editing: false });
  };

  handleAddNote = noteId => {
    this.setState(prevState => ({
      disableSave: false,
      selectedNotes: [...prevState.selectedNotes, noteId],
    }));
  };

  handleRemoveNote = (noteId: string) => {
    this.setState(prevState => ({
      disableSave: false,
      selectedNotes: prevState.selectedNotes.filter(id => id !== noteId),
    }));
  };

  handleAllNoteCheckToggle = (isPrevAllCheck: boolean) => {
    if (isPrevAllCheck) {
      this.setState({ disableSave: false, selectedNotes: [] });
    } else {
      const allNotesIds = this.props.event.eventNotes.edges.map(({ node }) => node.id);
      this.setState({ disableSave: false, selectedNotes: allNotesIds });
    }
  };

  handleAddAttachment = attachmentId => {
    this.setState(prevState => ({
      disableSave: false,
      selectedAttachments: [...prevState.selectedAttachments, attachmentId],
    }));
  };

  handleRemoveAttachment = (attachmentId: string) => {
    this.setState(prevState => ({
      disableSave: false,
      selectedAttachments: prevState.selectedAttachments.filter(id => id !== attachmentId),
    }));
  };

  handleAllAttachmentCheckToggle = (
    isPrevAllCheck: boolean,
    searchedAttachmentIds: $ReadOnlyArray<string>,
  ) => {
    if (isPrevAllCheck) {
      this.setState(prevState => ({
        disableSave: false,
        selectedAttachments: prevState.selectedAttachments.filter(
          sat => !searchedAttachmentIds.includes(sat),
        ),
      }));
    } else {
      this.setState(prevState => ({
        disableSave: false,
        selectedAttachments: [
          ...prevState.selectedAttachments,
          ...difference(searchedAttachmentIds, prevState.selectedAttachments),
        ],
      }));
    }
  };

  handleAddContact = (contactId: string) => {
    this.setState(prevState => ({
      disableSave: false,
      selectedContacts: [...prevState.selectedContacts, contactId],
    }));
  };

  handleRemoveContact = (contactId: string) => {
    this.setState(prevState => ({
      disableSave: false,
      selectedContacts: prevState.selectedContacts.filter(id => id !== contactId),
    }));
  };

  handleAllContactCheckToggle = (
    isPrevAllCheck: boolean,
    searchedContactIds: $ReadOnlyArray<string>,
  ) => {
    if (isPrevAllCheck) {
      this.setState(prevState => ({
        disableSave: false,
        selectedContacts: prevState.selectedContacts.filter(
          contact => !searchedContactIds.includes(contact),
        ),
      }));
    } else {
      this.setState(prevState => ({
        disableSave: false,
        selectedContacts: [
          ...prevState.selectedContacts,
          ...difference(searchedContactIds, prevState.selectedContacts),
        ],
      }));
    }
  };

  handleAddVendor = vendorId => {
    this.setState(prevState => ({
      disableSave: false,
      selectedVendors: [...prevState.selectedVendors, vendorId],
    }));
  };

  handleRemoveVendor = (vendorId: string) => {
    this.setState(prevState => ({
      disableSave: false,
      selectedVendors: prevState.selectedVendors.filter(id => id !== vendorId),
    }));
  };

  handleAllVendorCheckToggle = (
    isPrevAllCheck: boolean,
    searchedVendorIds: $ReadOnlyArray<string>,
  ) => {
    if (isPrevAllCheck) {
      this.setState(prevState => ({
        disableSave: false,
        selectedVendors: prevState.selectedVendors.filter(
          vendor => !searchedVendorIds.includes(vendor),
        ),
      }));
    } else {
      this.setState(prevState => ({
        disableSave: false,
        selectedVendors: [
          ...prevState.selectedVendors,
          ...difference(searchedVendorIds, prevState.selectedVendors),
        ],
      }));
    }
  };

  handleAddCompany = (companyId: string) => {
    this.setState(prevState => ({
      disableSave: false,
      selectedCompanies: [...prevState.selectedCompanies, companyId],
    }));
  };

  handleRemoveCompany = (companyId: string) => {
    this.setState(prevState => ({
      disableSave: false,
      selectedCompanies: prevState.selectedCompanies.filter(id => id !== companyId),
    }));
  };

  handleAllCompanyCheckToggle = (
    isPrevAllCheck: boolean,
    searchedCompanyIds: $ReadOnlyArray<string>,
  ) => {
    if (isPrevAllCheck) {
      this.setState(prevState => ({
        disableSave: false,
        selectedCompanies: prevState.selectedCompanies.filter(
          company => !searchedCompanyIds.includes(company),
        ),
      }));
    } else {
      this.setState(prevState => ({
        disableSave: false,
        selectedCompanies: [
          ...prevState.selectedCompanies,
          ...difference(searchedCompanyIds, prevState.selectedCompanies),
        ],
      }));
    }
  };

  handleAccessibilityChange = (accessibility: BriefSharingGraphValue) => {
    this.setState({ disableSave: false, accessibility });
  };

  handleEditClick = () => {
    this.setState(state => ({ editing: !state.editing }));
  };

  render() {
    const { org, event, me } = this.props;

    return (
      <>
        <BriefHeaderActionsBar
          event={event}
          me={me}
          accessibility={this.state.accessibility}
          onEditClick={this.handleEditClick}
        />
        <Container viewerCanUpdate={event.viewerCanUpdate}>
          <BriefView
            noteIds={this.state.selectedNotes}
            contactIds={this.state.selectedContacts}
            companyIds={this.state.selectedCompanies}
            vendorIds={this.state.selectedVendors}
            attachmentIds={this.state.selectedAttachments}
            editing={event.viewerCanUpdate}
            event={event}
          />
          <BriefBuilder
            org={org}
            event={event}
            editing={this.state.editing}
            disableSave={this.state.disableSave}
            selectedNotes={this.state.selectedNotes}
            onAddNote={this.handleAddNote}
            onRemoveNote={this.handleRemoveNote}
            onAllNoteCheckToggle={this.handleAllNoteCheckToggle}
            selectedAttachments={this.state.selectedAttachments}
            onAddAttachment={this.handleAddAttachment}
            onRemoveAttachment={this.handleRemoveAttachment}
            onAllAttachmentCheckToggle={this.handleAllAttachmentCheckToggle}
            selectedContacts={this.state.selectedContacts}
            onAddContact={this.handleAddContact}
            onRemoveContact={this.handleRemoveContact}
            onAllContactCheckToggle={this.handleAllContactCheckToggle}
            selectedVendors={this.state.selectedVendors}
            onAddVendor={this.handleAddVendor}
            onRemoveVendor={this.handleRemoveVendor}
            onAllVendorCheckToggle={this.handleAllVendorCheckToggle}
            selectedCompanies={this.state.selectedCompanies}
            onAddCompany={this.handleAddCompany}
            onRemoveCompany={this.handleRemoveCompany}
            onAllCompanyCheckToggle={this.handleAllCompanyCheckToggle}
            accessibility={this.state.accessibility}
            onAccessibilityChange={this.handleAccessibilityChange}
            onBriefSave={this.handleBriefSave}
            onBriefCancel={this.handleBriefCancel}
          />
        </Container>
      </>
    );
  }
}

export default createFragmentContainer(
  BriefContainer,
  graphql`
    fragment BriefContainer_event on Event {
      id
      viewerCanUpdate
      briefSettings {
        id
        accessibility
        notes {
          edges {
            node {
              id
              name
            }
          }
        }
        attachments {
          edges {
            node {
              id
            }
          }
        }
        contacts {
          edges {
            node {
              id
            }
          }
        }
        vendors {
          edges {
            node {
              id
            }
          }
        }
        companies {
          edges {
            node {
              id
            }
          }
        }
      }
      eventNotes {
        edges {
          node {
            id
          }
        }
      }
      ...BriefHeaderActionsBar_event
      ...BriefBuilder_event
      ...BriefView_event
    }
    fragment BriefContainer_org on Org {
      ...BriefBuilder_org
    }
    fragment BriefContainer_me on User {
      ...BriefHeaderActionsBar_me
    }
  `,
);
