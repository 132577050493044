/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import reviewEventRequestSubmission from 'graph/mutations/eventRequest/reviewEventRequestSubmission';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ShareDropdown from 'components/ShareDropdown';

import type { EventRequestsActionsColumn_eventRequestSubmission } from './__generated__/EventRequestsActionsColumn_eventRequestSubmission.graphql';

const Column = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  & > div:first-child {
    padding-left: 0;
    margin-left: 0;
  }
`;

class EventRequestsActionsColumn extends React.Component<{
  eventRequestSubmission: EventRequestsActionsColumn_eventRequestSubmission,
  history: RouterHistory,
  location: Location,
}> {
  stopPropagation = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation();
  };

  handleViewResponse = () => {
    this.props.history.push(
      `/dashboard/event_requests/${this.props.eventRequestSubmission.id}${this.props.location.search}`,
    );
  };

  handleViewEvent = () => {
    this.props.history.push(
      `/events/${this.props.eventRequestSubmission.event.slug}${this.props.location.search}`,
    );
  };

  handleArchive = () => {
    reviewEventRequestSubmission({
      requestSubmissionId: this.props.eventRequestSubmission.id,
      status: 'ARCHIVED',
      reviewerNotes: '',
    }).catch(showModernMutationError);
  };

  render() {
    return (
      <Column onClick={this.stopPropagation}>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={[
            { label: 'View Submission', icon: 'search', onClick: this.handleViewResponse },
            { label: 'View Event', icon: 'eye', onClick: this.handleViewEvent },
            !['ARCHIVED', 'APPROVED'].includes(this.props.eventRequestSubmission.status)
              ? { label: 'Archive', icon: 'archive', onClick: this.handleArchive }
              : null,
          ].filter(Boolean)}
          overlayContainer={document.body}
        />
      </Column>
    );
  }
}

export default createFragmentContainer(
  withRouter(EventRequestsActionsColumn),
  graphql`
    fragment EventRequestsActionsColumn_eventRequestSubmission on EventRequestSubmission {
      id
      status
      event {
        slug
      }
    }
  `,
);
