/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { OrgUserSearchRow_user } from './__generated__/OrgUserSearchRow_user.graphql';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  line-height: 1.4;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
`;

const StyledAvatar = styled(MaterialAvatar)`
  flex: 0 0 auto;
  margin-right: 10px;
`;

const Wrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

const Main = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const Secondary = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 13px;
`;

class OrgUserSearchRow extends React.PureComponent<{
  user: OrgUserSearchRow_user,
}> {
  render() {
    const userName = `${this.props.user.firstName} ${this.props.user.lastName}`;
    return (
      <Row>
        <StyledAvatar user={this.props.user} radius={15} />
        <Wrapper title={userName}>
          <Main>{userName}</Main>
          <Secondary>{this.props.user.email}</Secondary>
        </Wrapper>
      </Row>
    );
  }
}

export default createFragmentContainer(
  OrgUserSearchRow,
  graphql`
    fragment OrgUserSearchRow_user on User {
      firstName
      lastName
      email
      ...MaterialAvatar_user
    }
  `,
);
