/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import TaskActivities from './TaskActivities';

import type { TaskActivitiesRootQueryResponse } from './__generated__/TaskActivitiesRootQuery.graphql';

const query = graphql`
  query TaskActivitiesRootQuery($taskSlug: String!, $count: Int!, $cursor: String) {
    task: deliverable(slug: $taskSlug) {
      ...TaskActivities_task
    }
  }
`;

export default class TaskActivitiesRoot extends React.PureComponent<{ taskSlug: string }> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ taskSlug: this.props.taskSlug, count: 10 }}
        renderSuccess={(response: TaskActivitiesRootQueryResponse) =>
          response.task && <TaskActivities task={response.task} />
        }
      />
    );
  }
}
