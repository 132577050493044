/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import CircaIcon from 'images/circaIcon.svg';
import SalesforceIcon from 'images/integrations/salesforce.svg';
import SyncIcon from 'images/syncIcon.svg';
import Button from 'components/budget/Button';

import SalesforceCampaignData from './SalesforceCampaignData';

import type { RoiContainer_event } from './__generated__/RoiContainer_event.graphql';
import type { RoiContainer_org } from './__generated__/RoiContainer_org.graphql';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const InfoBox = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  max-width: 622px;
  padding: 50px 105px 40px;
  border-radius: 2px;
  background-color: #f7f8f9;
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 210px;
  margin-bottom: 48px;
`;

const StyledSyncIcon = styled(SyncIcon)`
  margin: 0 20px;
`;

const StyledSalesforceIcon = styled(SalesforceIcon)`
  width: 70px;
`;

const InfoText = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  color: #3e4859;
  text-align: center;
`;

const Highlight = styled.span`
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  flex: 0 auto;
  min-width: 116px;
  margin-right: 10px;
`;

class RoiContainer extends React.Component<{
  org: RoiContainer_org,
  event: RoiContainer_event,
  history: RouterHistory,
}> {
  navigateToIntegrations = () => {
    this.props.history.push('/settings/integrations');
  };

  navigateToCRM = () => {
    this.props.history.push(`/events/${this.props.event.slug}/info/crm`);
  };

  render() {
    const { event, org } = this.props;
    const salesforceConnected = !!org.salesforceAccount;
    const salesforceCampaignConnected = !!event.salesforceCampaign;
    if (!salesforceConnected) {
      return (
        <InfoContainer>
          <InfoBox>
            <IconsContainer>
              <CircaIcon />
              <StyledSyncIcon />
              <StyledSalesforceIcon width={70} height={62} />
            </IconsContainer>
            <InfoText>
              {org.viewerCanUpdate ? (
                <>
                  <Highlight>Enable Salesforce Integration</Highlight> to get more insight and
                  aggregated data.
                </>
              ) : (
                <>
                  <Highlight>Please contact your Organization admin</Highlight> to enable Salesforce
                  integration for more insight and aggregated data.
                </>
              )}
            </InfoText>
            {org.viewerCanUpdate && (
              <StyledButton onClick={this.navigateToIntegrations}>Enable Salesforce</StyledButton>
            )}
          </InfoBox>
        </InfoContainer>
      );
    }
    if (!salesforceCampaignConnected) {
      return (
        <InfoContainer>
          <InfoBox>
            <IconsContainer>
              <CircaIcon />
              <StyledSyncIcon />
              <StyledSalesforceIcon width={70} height={62} />
            </IconsContainer>
            <InfoText>
              {event.viewerCanViewROI ? (
                <>
                  <Highlight>Connect Salesforce campaign</Highlight> with this event in order to
                  view more insight and aggregated data.
                </>
              ) : (
                <>
                  <Highlight>Please contact your Organization admin</Highlight> to Connect
                  Salesforce campaign with this event in order to view more insight and aggregated
                  data.
                </>
              )}
            </InfoText>
            {event.viewerCanViewROI && (
              <StyledButton onClick={this.navigateToCRM}>Connect Campaign</StyledButton>
            )}
          </InfoBox>
        </InfoContainer>
      );
    }
    return (
      <Container>
        <SalesforceCampaignData campaign={event.salesforceCampaign} org={org} />
      </Container>
    );
  }
}

export default createFragmentContainer(RoiContainer, {
  event: graphql`
    fragment RoiContainer_event on Event {
      id
      slug
      viewerCanViewROI
      salesforceCampaign {
        ...SalesforceCampaignData_campaign
      }
    }
  `,
  org: graphql`
    fragment RoiContainer_org on Org {
      viewerCanUpdate
      salesforceAccount {
        id
      }
      ...SalesforceCampaignData_org
      settings {
        currency
      }
    }
  `,
});
