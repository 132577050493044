/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Homepage = "ALL_TASKS" | "EVENTS" | "MY_EVENTS" | "MY_TASKS" | "UPCOMING_EVENTS";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DashboardMenuSavedViews_user$ref: FragmentReference;
export type DashboardMenuSavedViews_user = {|
  +id: string,
  +homepage: Homepage,
  +homepageListId: ?string,
  +eventLists: {|
    +totalCount: number
  |},
  +$refType: DashboardMenuSavedViews_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "DashboardMenuSavedViews_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "homepage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "homepageListId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventLists",
      "storageKey": null,
      "args": null,
      "concreteType": "EventListRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6ad8e336b4583d7587bacb2aeaea2757';
module.exports = node;
