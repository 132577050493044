/**
 * @flow
 * @relayHash 054e24be06a69fc65db3bd3b3c83a1de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SettingsRoutes_org$ref = any;
export type SettingsQueryVariables = {||};
export type SettingsQueryResponse = {|
  +org: {|
    +name: string,
    +$fragmentRefs: SettingsRoutes_org$ref,
  |}
|};
export type SettingsQuery = {|
  variables: SettingsQueryVariables,
  response: SettingsQueryResponse,
|};
*/


/*
query SettingsQuery {
  org {
    name
    ...SettingsRoutes_org
    id
  }
}

fragment SettingsRoutes_org on Org {
  name
  ...ContactSettings_org
}

fragment ContactSettings_org on Org {
  name
  ...PeopleSettings_org
  ...OpportunitySettings_org
}

fragment PeopleSettings_org on Org {
  salesforceAccount {
    contactsSyncEnabled
    id
  }
}

fragment OpportunitySettings_org on Org {
  salesforceAccount {
    opportunitiesSyncEnabled
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SettingsQuery",
  "id": null,
  "text": "query SettingsQuery {\n  org {\n    name\n    ...SettingsRoutes_org\n    id\n  }\n}\n\nfragment SettingsRoutes_org on Org {\n  name\n  ...ContactSettings_org\n}\n\nfragment ContactSettings_org on Org {\n  name\n  ...PeopleSettings_org\n  ...OpportunitySettings_org\n}\n\nfragment PeopleSettings_org on Org {\n  salesforceAccount {\n    contactsSyncEnabled\n    id\n  }\n}\n\nfragment OpportunitySettings_org on Org {\n  salesforceAccount {\n    opportunitiesSyncEnabled\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "FragmentSpread",
            "name": "SettingsRoutes_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SettingsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "contactsSyncEnabled",
                "args": null,
                "storageKey": null
              },
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "opportunitiesSyncEnabled",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v1
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '153e8ea2ebf6a827a695f673b662a410';
module.exports = node;
