/* @flow */
import styled from 'styled-components';

const Title = styled.div`
  margin: 0 0 5px 25px;
  font-size: 11px;
  font-weight: 500;
  color: black;
`;

export default Title;
