/**
 * @flow
 * @relayHash 604c7229a16e6f90c4331a11278ff41e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EditableQuestionSelectOptionRowForContact_user$ref = any;
type MaterialAvatar_user$ref = any;
export type EditableQuestionSelectOptionsForContactQueryVariables = {||};
export type EditableQuestionSelectOptionsForContactQueryResponse = {|
  +org: {|
    +users: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +email: string,
          +avatar: ?string,
          +$fragmentRefs: EditableQuestionSelectOptionRowForContact_user$ref & MaterialAvatar_user$ref,
        |}
      |}>
    |}
  |}
|};
export type EditableQuestionSelectOptionsForContactQuery = {|
  variables: EditableQuestionSelectOptionsForContactQueryVariables,
  response: EditableQuestionSelectOptionsForContactQueryResponse,
|};
*/


/*
query EditableQuestionSelectOptionsForContactQuery {
  org {
    users {
      edges {
        node {
          id
          firstName
          lastName
          email
          avatar
          ...EditableQuestionSelectOptionRowForContact_user
          ...MaterialAvatar_user
        }
      }
    }
    id
  }
}

fragment EditableQuestionSelectOptionRowForContact_user on User {
  id
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditableQuestionSelectOptionsForContactQuery",
  "id": null,
  "text": "query EditableQuestionSelectOptionsForContactQuery {\n  org {\n    users {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          email\n          avatar\n          ...EditableQuestionSelectOptionRowForContact_user\n          ...MaterialAvatar_user\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment EditableQuestionSelectOptionRowForContact_user on User {\n  id\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditableQuestionSelectOptionsForContactQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": null,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      v2,
                      v3,
                      v4,
                      {
                        "kind": "FragmentSpread",
                        "name": "EditableQuestionSelectOptionRowForContact_user",
                        "args": null
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "MaterialAvatar_user",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditableQuestionSelectOptionsForContactQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": null,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      v2,
                      v3,
                      v4
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c91c19aab5b9097d4d99e357ac94dfcb';
module.exports = node;
