/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';
import styled from 'styled-components';

import SubmissionReviewForm from './SubmissionReviewForm';
import SubmissionReviewSidebar from './SubmissionReviewSidebar';

import type { SubmissionReviewContent_me } from './__generated__/SubmissionReviewContent_me.graphql';
import type { SubmissionReviewContent_org } from './__generated__/SubmissionReviewContent_org.graphql';
import type { SubmissionReviewContent_submission } from './__generated__/SubmissionReviewContent_submission.graphql';

const Columns = styled.div`
  display: flex;
  height: 100%;
`;

class SubmissionReviewContent extends React.PureComponent<{
  submission: SubmissionReviewContent_submission,
  org: SubmissionReviewContent_org,
  me: SubmissionReviewContent_me,
  onCancel: () => void,
  location: Location,
}> {
  render() {
    return (
      <Columns>
        <SubmissionReviewForm
          submission={this.props.submission}
          org={this.props.org}
          tz={this.props.me.tz}
          location={this.props.location}
        />

        <SubmissionReviewSidebar
          submission={this.props.submission}
          org={this.props.org}
          me={this.props.me}
          onCancel={this.props.onCancel}
        />
      </Columns>
    );
  }
}

export default createFragmentContainer(
  SubmissionReviewContent,
  graphql`
    fragment SubmissionReviewContent_submission on EventRequestSubmission {
      ...SubmissionReviewSidebar_submission
      ...SubmissionReviewForm_submission
    }

    fragment SubmissionReviewContent_org on Org {
      ...SubmissionReviewSidebar_org
      ...SubmissionReviewForm_org
    }

    fragment SubmissionReviewContent_me on User {
      tz
      ...SubmissionReviewSidebar_me
    }
  `,
);
