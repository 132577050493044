/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';
import { isEqual, omit } from 'lodash';

import getFakeId from 'utils/getFakeId';

import Button, { MinimalButton } from 'components/budget/Button';
import CheckBox from 'components/EventRequestForm/form/CheckBox';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';

import fieldTypeOptions from '../fieldTypeOptions';
import type {
  CustomizableType,
  Field,
  FieldInput,
  FieldOption,
  FieldType,
  SectionCustomizableType,
} from '../types';
import FieldOptions from './FieldOptions';

const Container = styled.div`
  min-width: 555px;
  padding: 10px 25px 20px;
  ${props =>
    props.adding
      ? css`
          margin-top: 10px;
          border-radius: 2px;
          animation: highlight 1s forwards;
        `
      : css`
          margin-top: 20px;
        `};
  @keyframes highlight {
    from {
      background-color: #d6f1ff;
    }
    to {
      background-color: #f4fafd;
    }
  }
`;

const Row = styled.div`
  display: flex;
`;

const StyledSelectField = styled(SelectField)`
  flex: 0 0 200px;
  margin-right: 45px;
`;

const StyledTextField = styled(TextField)`
  flex: 1 0 200px;
  margin-right: 28px;
`;

const StyledCheckBox = styled(CheckBox)`
  margin-top: 30px;
  &:not(:last-child) {
    margin-right: 40px;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 28px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  button:first-child {
    margin-right: 28px;
  }
`;

const defaultOptions: $ReadOnlyArray<FieldOption> = [
  {
    id: getFakeId(),
    name: 'Option 1',
    editable: true,
  },
  {
    id: getFakeId(),
    name: 'Option 2',
    editable: true,
  },
];

const defaultFieldProps: Field = {
  id: getFakeId(),
  kind: 'TEXT',
  label: '',
  fieldName: null,
  required: false,
  showInCreateForm: true,
  showInMobileForm: true,
  enabled: true,
  order: -1,
  options: defaultOptions,
};

export default class CustomFieldForm extends React.Component<
  {
    field?: Field,
    adding?: boolean,
    className?: string,
    sectionCustomizableType?: SectionCustomizableType,
    customizableType: CustomizableType,
    onSave: (fieldProps: FieldInput) => Promise<void>,
    onCancel?: () => void,
  },
  {
    field: Field,
    addingOption: boolean,
    loading: boolean,
    error: boolean,
  },
> {
  state = {
    field: this.props.field || defaultFieldProps,
    addingOption: false,
    loading: false,
    error: false,
  };

  fieldNameInput = React.createRef();

  componentDidMount() {
    if (!this.props.adding) {
      this.focusFieldNameInput();
    }
  }

  handleTypeChange = (kind: ?FieldType) => {
    this.setState(prevState => ({
      field: { ...prevState.field, ...(kind == null ? {} : { kind }) },
    }));

    this.focusFieldNameInput();
  };

  focusFieldNameInput = () => {
    if (this.fieldNameInput.current) {
      this.fieldNameInput.current.focus();
    }
  };

  handleLabelChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const label = e.currentTarget.value;
    this.setState(prevState => ({
      field: { ...prevState.field, label },
      error: !label,
    }));
  };

  generateEmptyOption = () => ({ id: getFakeId(), name: '', editable: true });

  handleOptionAdd = () => {
    this.setState(({ field }) => ({
      field: {
        ...field,
        options: [...field.options, this.generateEmptyOption()],
      },
      addingOption: true,
    }));
  };

  handleOptionUpdate = (id: string, value: string, enterPressed: boolean) => {
    this.setState(({ field, addingOption }) => ({
      field: {
        ...field,
        options: [
          ...field.options
            .map(option => (option.id === id ? { ...option, name: value } : option))
            .filter(option => option.name),
          ...(enterPressed && addingOption && value.trim() !== ''
            ? [this.generateEmptyOption()]
            : []),
        ],
      },
      addingOption: enterPressed && value.trim() !== '' ? addingOption : false,
    }));
  };

  handleOptionRemove = (id: string) => {
    this.setState(({ field }) => ({
      field: {
        ...field,
        options: field.options.filter(option => option.id !== id),
      },
    }));
  };

  handleRequiredToggle = () => {
    this.setState(({ field }) => {
      const required = !field.required;
      return {
        field: {
          ...field,
          required,
          ...(required ? { showInCreateForm: true, showInMobileForm: true } : {}),
        },
      };
    });
  };

  // handleCreateFormToggle = () => {
  //   this.setState(({ field }) => {
  //     const showInCreateForm = !field.showInCreateForm;
  //     return {
  //       field: {
  //         ...field,
  //         showInCreateForm,
  //         ...(!showInCreateForm && field.required ? { required: false } : {}),
  //       },
  //     };
  //   });
  // };

  // handleMobileFormToggle = () => {
  //   this.setState(({ field }) => {
  //     const showInMobileForm = !field.showInMobileForm;
  //     return {
  //       field: {
  //         ...field,
  //         showInMobileForm,
  //         ...(!showInMobileForm && field.required ? { required: false } : {}),
  //       },
  //     };
  //   });
  // };

  handleSave = () => {
    const { kind, label, required, showInCreateForm, showInMobileForm, options } = this.state.field;
    const updateOptions =
      ['SELECT', 'MULTISELECT'].includes(kind) || (kind === 'DEFAULT' && options.length > 0);

    this.setState({ loading: true });

    this.props
      .onSave({
        kind,
        label,
        required,
        showInCreateForm,
        showInMobileForm,
        ...(updateOptions
          ? {
              options: options.map(option =>
                option.id.startsWith('_UNSAVED_')
                  ? { name: option.name }
                  : { ...omit(option, 'editable') },
              ),
            }
          : {}),
      })
      .then(() => {
        const propOptions = this.props.field ? this.props.field.options : [];

        this.setState(prevState => ({
          loading: false,
          field: {
            ...prevState.field,
            options: propOptions,
          },
        }));
      });
  };

  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    if (event.key !== 'Enter') return;

    event.currentTarget.blur();
    const { adding, field } = this.props;
    const {
      field: { label },
      loading,
    } = this.state;
    const canSave = !!label;
    const currentState = omit(this.state.field, 'enabled');
    const passedProps = omit(field, 'enabled');
    const updateButtonDisabled = field && isEqual(currentState, passedProps);

    if (!loading && canSave && (adding || !updateButtonDisabled)) {
      this.handleSave();
    }
  };

  renderActionButtons() {
    const { adding, onCancel, field } = this.props;

    const {
      field: {
        label,
        // showInCreateForm,
        // showInMobileForm,
      },
      loading,
    } = this.state;

    const cantSave = !label;
    const currentState = omit(this.state.field, 'enabled');
    const passedProps = omit(field, 'enabled');
    const updateButtonDisabled = field && isEqual(currentState, passedProps);

    return (
      <Actions>
        {adding ? (
          <>
            {!loading && <MinimalButton label="Cancel" onClick={onCancel} />}
            <StyledButton onClick={this.handleSave} loading={loading} disabled={cantSave}>
              Add
            </StyledButton>
          </>
        ) : (
          <Button
            onClick={this.handleSave}
            loading={loading}
            disabled={cantSave || updateButtonDisabled}
          >
            Update Info
          </Button>
        )}
      </Actions>
    );
  }

  render() {
    const { adding, sectionCustomizableType, customizableType, className } = this.props;
    const {
      field: {
        kind,
        label,
        options,
        required,
        // showInCreateForm,
        // showInMobileForm,
        restrictManagingOptions,
      },
      error,
    } = this.state;

    // Flow freaks out for some reason without map
    const mappedFieldTypeOptions = fieldTypeOptions.map(option => ({ ...option }));
    const fieldTypes =
      kind === 'DEFAULT'
        ? [
            ...mappedFieldTypeOptions,
            {
              label: 'System Field',
              value: 'DEFAULT',
            },
          ]
        : mappedFieldTypeOptions;
    const showOptions =
      ['SELECT', 'MULTISELECT'].includes(kind) || (kind === 'DEFAULT' && options.length > 0);

    return (
      <Container adding={adding} className={className}>
        <Row>
          <StyledSelectField
            label="Field Type"
            value={kind}
            onChange={this.handleTypeChange}
            options={fieldTypes}
            disabled={kind === 'DEFAULT' || !adding}
          />
          <StyledTextField
            label="Field Name"
            value={label}
            onChange={this.handleLabelChange}
            onKeyDown={this.handleKeyDown}
            required
            error={error && 'Field Name is required'}
            inputRef={this.fieldNameInput}
          />
          {options.length > 3 && this.renderActionButtons()}
        </Row>
        {showOptions && (
          <FieldOptions
            restrictManagingOptions={restrictManagingOptions}
            options={options || defaultOptions}
            singleSelect={['SELECT', 'DEFAULT'].includes(kind)}
            onOptionAdd={this.handleOptionAdd}
            onOptionUpdate={this.handleOptionUpdate}
            onOptionRemove={this.handleOptionRemove}
          />
        )}
        {!['EVENT', 'SALESFORCEOPPORTUNITY'].includes(
          sectionCustomizableType || customizableType,
        ) && (
          <Row>
            <StyledCheckBox
              label="Required"
              checked={required}
              onChange={this.handleRequiredToggle}
              compact
            />
            {/* <StyledCheckBox
              label="On Web"
              checked={showInCreateForm}
              onChange={this.handleCreateFormToggle}
              compact
            />
            <StyledCheckBox
              label="On Mobile App"
              checked={showInMobileForm}
              onChange={this.handleMobileFormToggle}
              compact
            /> */}
          </Row>
        )}
        {this.renderActionButtons()}
      </Container>
    );
  }
}
