/* @flow */

type TokenInfoResponse = {
  // The application that is the intended user of the access token.
  aud: string,
  // The number of seconds left before the token becomes invalid.
  expires_in: string,
  // 	A space-delimited list of scopes that the user granted access to.
  scope: string,
  userid?: string,
};

/**
 * Returns data about the access token if valid, otherwise null if not valid.
 *
 * Reference: https://developers.google.com/identity/protocols/OAuth2UserAgent#validate-access-token
 */
export default function validateGoogleToken(accessToken: string): Promise<?TokenInfoResponse> {
  return fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${accessToken}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.ok) {
        return response;
      }
      return response.json().then(() => {
        return Promise.reject(new Error('Unexpected error'));
      });
    })
    .then(response => response.json())
    .then(data => data);
}
