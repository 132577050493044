/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import replaceLoDash from 'lodash/replace';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';

import formatDateTimeRange from 'utils/date/formatDateTimeRange';
import { stringifyLocation } from 'utils/Location';
import escapeHtml from 'utils/string/escapeHtml';
import isValidEmail from 'utils/validators/isValidEmail';
import isValidWebsite from 'utils/validators/isValidWebsite';

import updateRegistrationForm, {
  type updateRegistrationPropertyType,
} from 'graph/mutations/registration/updateRegistrationForm';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { MinimalButton } from 'components/budget/Button';
import EditableEmailTemplate from 'components/ContactForm/EditableEmailTemplate';
import ScheduledEmailTemplate from 'components/ContactForm/ScheduledEmailTemplate';
import Switch from 'components/material/Switch';
import TextInput from 'components/material/TextInput';
import inputBoxStyled from 'components/SharedForm/components/inputBoxStyled';
import { Error } from 'components/SharedForm/FieldComponents';

import BccEmails from './BccEmails';

import type { EmailSettings_event } from './__generated__/EmailSettings_event.graphql';
import type { EmailSettings_me } from './__generated__/EmailSettings_me.graphql';
import type { EmailSettings_org } from './__generated__/EmailSettings_org.graphql';
import type { EmailSettings_registrationForm } from './__generated__/EmailSettings_registrationForm.graphql';

const Block = styled.div`
  padding: 25px 45px 25px 41px;
  border: solid 1px #dadada;
  border-radius: 4px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

const RowWrapper = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => (props.extendedMargin ? 35 : 25)}px;
`;

const TitleWrapper = styled.div`
  max-width: 580px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1em;
  color: rgba(74, 86, 101, 0.87);
`;

const EmailTitle = styled(Title)`
  margin-top: 30px;
`;

const Label = styled.div`
  margin-top: 33px;
  margin-bottom: 4px;
  line-height: 1em;
  font-size: 16px;
  color: #828b93;
`;

const TextField = styled(TextInput)`
  ${props => inputBoxStyled(props)};
  max-width: 479px;
  height: 36px;
  min-height: 36px;
`;

const SubTitle = styled.div`
  margin: 10px 20px 0 0;
  font-size: 14px;
  line-height: 1em;
  font-style: italic;
  color: rgba(74, 86, 101, 0.71);
`;

const SwitchWrapper = styled.div`
  flex: 0 0 auto;
`;

const Info = styled.div`
  margin: 8px 0 40px;
  line-height: 1em;
  color: rgba(74, 86, 101, 0.54);
`;

const NewScheduledEventEmailButton = styled(MinimalButton)`
  font-size: 14px;
  font-weight: 400;
  color: #3ba9da;
`;

const Line = styled.div`
  margin: 0 -45px 36px -42px;
  border-bottom: 1px solid #d8d8d8;
`;

class EmailSettings extends React.PureComponent<
  {
    event?: EmailSettings_event,
    org: EmailSettings_org,
    registrationForm: EmailSettings_registrationForm,
    me: EmailSettings_me,
    defaultFont?: string,
  },
  {
    submitEmailTemplate: string,
    submitEmailSubject: string,
    addingScheduledEmailTemplate: boolean,
    errors: { [string]: ?string | ?boolean },
  },
> {
  state = {
    submitEmailTemplate: this.props.registrationForm.submitEmailTemplate || '',
    submitEmailSubject: this.props.registrationForm.submitEmailSubject || '',
    addingScheduledEmailTemplate: false,
    errors: {},
  };

  componentDidMount() {
    const defaultFont = this.props.defaultFont || '';
    const currentDefaultFont = this.getCurrentDefaultFont();
    const submitEmailTemplate = this.props.registrationForm.submitEmailTemplate;
    if (
      submitEmailTemplate &&
      defaultFont &&
      (!currentDefaultFont || currentDefaultFont.toLowerCase() !== defaultFont.toLowerCase())
    ) {
      this.updateRegistrationForm({
        submitEmailTemplate,
        defaultFont,
        forceUpdate: true,
      });
    }
  }

  getCurrentDefaultFont = () => {
    const submitEmailTemplate = this.props.registrationForm.submitEmailTemplate;
    if (!submitEmailTemplate) {
      return null;
    }
    const fontStart = submitEmailTemplate.substring(submitEmailTemplate.indexOf("'") + 1);
    return fontStart.substring(0, fontStart.indexOf("'"));
  };

  replaceContentTags = (content: string, contentFormat: 'html' | 'plain' = 'html'): string => {
    const { event, registrationForm } = this.props;
    if (event == null) return content;

    const registrationDates = {
      startDate: moment.tz(registrationForm.eventStartDate, registrationForm.tz),
      startDateAllDay: registrationForm.startDateAllDay,
      endDate: registrationForm.eventEndDate
        ? moment.tz(registrationForm.eventEndDate, registrationForm.tz)
        : null,
      endDateAllDay: registrationForm.endDateAllDay,
    };
    const escapedVirtualLocation =
      contentFormat === 'html' ? escapeHtml(event.virtualLocation || '') : event.virtualLocation;
    const virtualLocation =
      escapedVirtualLocation && isValidWebsite(escapedVirtualLocation) && contentFormat === 'html'
        ? `<a href="${escapedVirtualLocation}" target="_blank" rel="noopener" style="color: #5e5cb7;">${escapedVirtualLocation}</a>`
        : escapedVirtualLocation;

    const stringifiedPrimaryLocation =
      event.primaryLocation && stringifyLocation(event.primaryLocation);
    const primaryLocation =
      contentFormat === 'html'
        ? escapeHtml(stringifiedPrimaryLocation || '')
        : stringifiedPrimaryLocation;

    return content
      .replace(/{{First Name}}/g, 'Jane')
      .replace(/{{Last Name}}/g, 'Doe')
      .replace(
        /{{Event Name}}/g,
        contentFormat === 'html'
          ? escapeHtml(registrationForm.eventName)
          : registrationForm.eventName,
      )
      .replace(/{{Event Date\/Time}}/g, formatDateTimeRange(registrationDates))
      .replace(
        /{{Event Location}}/g,
        [primaryLocation, virtualLocation]
          .filter(Boolean)
          .join(contentFormat === 'html' ? '<br>' : ', '),
      )
      .replace(
        /{{Integration Details}}/g,
        (event.zoomLocation && event.zoomLocation.joinUrl) ||
          (event.g2wLocation && event.g2wLocation.registrationUrl) ||
          '',
      );
  };

  handleSendConfirmationEmailChange = () => {
    this.updateRegistrationForm({
      sendSubmitEmail: !this.props.registrationForm.sendSubmitEmail,
    });
  };

  handleSendCalendarInviteChange = () => {
    this.updateRegistrationForm({
      sendCalendarInvite: !this.props.registrationForm.sendCalendarInvite,
    });
  };

  handleCompanyEmailsOnlyChange = () => {
    this.updateRegistrationForm({
      companyEmailsOnly: !this.props.registrationForm.companyEmailsOnly,
    });
  };

  handleSubmitEmailTemplate = (template: string) => {
    const registrationForm = this.props.registrationForm;
    const submitEmailTemplateError =
      registrationForm.sendSubmitEmail &&
      !replaceLoDash(template, /<p>&nbsp;<\/p>/g, '').trim() &&
      "Cannot be blank (contact support if you don't want to send email notifications)";

    this.setState(state => ({
      submitEmailTemplate: template,
      errors: {
        ...state.errors,
        submitEmailTemplate: submitEmailTemplateError,
      },
    }));
  };

  handleSubmitEmailSubject = (submitEmailSubject: string) => {
    this.setState({ submitEmailSubject });
  };

  handleSaveSubmitEmailTemplate = () => {
    const registrationForm = this.props.registrationForm;
    const defaultFont = this.props.defaultFont;
    const { submitEmailSubject, submitEmailTemplate } = this.state;
    if (
      !this.state.errors.submitEmailTemplate &&
      submitEmailTemplate &&
      (submitEmailTemplate !== registrationForm.submitEmailTemplate ||
        submitEmailSubject !== registrationForm.submitEmailSubject)
    ) {
      this.updateRegistrationForm({
        submitEmailSubject,
        submitEmailTemplate,
        defaultFont,
      });
    }
  };

  handleSenderAddressSave = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    const senderAddress = e.currentTarget.value;
    if (
      senderAddress !== this.props.registrationForm.senderAddress &&
      (!senderAddress.trim() || isValidEmail(senderAddress))
    ) {
      this.updateRegistrationForm({ senderAddress: senderAddress.trim() });
      this.setState(state => ({
        errors: {
          ...state.errors,
          senderAddress: null,
        },
      }));
      return;
    }
    this.setState(state => ({
      errors: {
        ...state.errors,
        senderAddress: senderAddress.trim() && !isValidEmail(senderAddress),
      },
    }));
  };

  handleUpdateErrors = (errors: { [string]: ?string | ?boolean }) => {
    this.setState({ errors });
  };

  updateRegistrationForm = (properties: updateRegistrationPropertyType) => {
    updateRegistrationForm({ ...properties, formId: this.props.registrationForm.id })
      .then(updatedRegistrationForm => {
        this.setState(state => ({
          submitEmailTemplate:
            updatedRegistrationForm.submitEmailTemplate || state.submitEmailTemplate,
        }));
      })
      .catch(showModernMutationError);
  };

  render() {
    const { org, me, event, registrationForm } = this.props;
    const {
      submitEmailTemplate,
      submitEmailSubject,
      addingScheduledEmailTemplate,
      errors,
    } = this.state;
    const {
      sendSubmitEmail,
      sendCalendarInvite,
      companyEmailsOnly,
      senderAddress,
    } = registrationForm;

    const eventEmailTemplates = registrationForm.eventEmailTemplates.edges.map(edge => edge.node);

    const defaultTags = [
      'First Name',
      'Last Name',
      'Event Name',
      'Event Location',
      'Event Date/Time',
    ];

    const tags = org.syncedToIbmWm ? defaultTags : [...defaultTags, 'Integration Details'];

    return (
      <>
        <Block>
          <EmailTitle>Email Addresses</EmailTitle>
          {!org.syncedToIbmWm && (
            <>
              <Info>You can mention specific address to send the registration form to.</Info>
              <RowWrapper>
                <Label>Sender Address</Label>
                <TextField
                  onBlur={this.handleSenderAddressSave}
                  defaultValue={senderAddress}
                  error={errors.senderAddress}
                />
                {errors.senderAddress && <Error>Invalid email address</Error>}
              </RowWrapper>
            </>
          )}

          <BccEmails
            bccEmails={registrationForm.contactFormBccEmails.edges.map(({ node }) => node)}
            formId={registrationForm.id}
            errors={errors}
            onUpdateErrors={this.handleUpdateErrors}
          />
          <Line />
          <Row extendedMargin={!sendSubmitEmail}>
            <Title>Post-Submit Confirmation Email</Title>
            <Switch
              enabled={sendSubmitEmail}
              onChange={this.handleSendConfirmationEmailChange}
              size="small"
            />
          </Row>
          {sendSubmitEmail && (
            <EditableEmailTemplate
              subject={submitEmailSubject}
              subjectPreview={this.replaceContentTags(submitEmailSubject, 'plain')}
              template={submitEmailTemplate}
              templatePreview={this.replaceContentTags(submitEmailTemplate)}
              onChange={this.handleSubmitEmailTemplate}
              onChangeSubject={this.handleSubmitEmailSubject}
              onSave={this.handleSaveSubmitEmailTemplate}
              tags={tags}
              richEditor
              error={errors.submitEmailTemplate}
            />
          )}
          <Row>
            <TitleWrapper>
              <Title>Send Calendar Invite</Title>
              <SubTitle>
                When enabled, a calendar invite will be sent along with the confirmation email. Note
                that the date, time, and location on the invite will come from the associated Event
                fields
              </SubTitle>
            </TitleWrapper>
            <SwitchWrapper>
              <Switch
                enabled={sendCalendarInvite}
                onChange={this.handleSendCalendarInviteChange}
                size="small"
              />
            </SwitchWrapper>
          </Row>
          <Line />
          <Row>
            <Title>Scheduled Emails</Title>
          </Row>
          {sortBy(eventEmailTemplates, [
            item => parseInt(window.atob(item.id).split('-')[1], 10),
          ]).map(eventEmailTemplate => (
            <ScheduledEmailTemplate
              formId={registrationForm.id}
              org={org}
              key={eventEmailTemplate.id}
              isEventTemplate={event != null}
              eventEmailTemplate={eventEmailTemplate}
              previewFn={this.replaceContentTags}
              tags={tags}
              startDate={event && registrationForm.eventStartDate}
              endDate={event && registrationForm.eventEndDate}
              tz={me.tz}
            />
          ))}
          {addingScheduledEmailTemplate ? (
            <ScheduledEmailTemplate
              formId={registrationForm.id}
              org={org}
              eventEmailTemplate={null}
              previewFn={this.replaceContentTags}
              onSave={this.handleSaveSubmitEmailTemplate}
              onFinish={() => this.setState({ addingScheduledEmailTemplate: false })}
              tags={tags}
              startDate={event && registrationForm.eventStartDate}
              isEventTemplate={event != null}
              endDate={event && registrationForm.eventEndDate}
              tz={registrationForm.tz || me.tz}
            />
          ) : (
            <NewScheduledEventEmailButton
              label={
                <>
                  <i className="fa fa-fw fa-plus" /> Add Scheduled Email
                </>
              }
              onClick={() => this.setState({ addingScheduledEmailTemplate: true })}
            />
          )}
        </Block>
        {!org.syncedToIbmWm && (
          <Block>
            <Row>
              <TitleWrapper>
                <Title>Require company email address</Title>
                <SubTitle>
                  When enabled, registrants will not be able to use the following email domains:
                  gmail, yahoo, outlook, hotmail, msn, and temporary email generators
                </SubTitle>
              </TitleWrapper>
              <SwitchWrapper>
                <Switch
                  enabled={companyEmailsOnly}
                  onChange={this.handleCompanyEmailsOnlyChange}
                  size="small"
                />
              </SwitchWrapper>
            </Row>
          </Block>
        )}
      </>
    );
  }
}

export default createFragmentContainer(EmailSettings, {
  event: graphql`
    fragment EmailSettings_event on Event {
      zoomLocation {
        joinUrl
      }
      g2wLocation {
        registrationUrl
      }
      virtualLocation
      primaryLocation {
        name
        city
        state
        country
      }
    }
  `,
  org: graphql`
    fragment EmailSettings_org on Org {
      syncedToIbmWm
      ...ScheduledEmailTemplate_org
    }
  `,
  registrationForm: graphql`
    fragment EmailSettings_registrationForm on RegistrationForm {
      id
      eventName
      eventStartDate
      startDateAllDay
      eventEndDate
      endDateAllDay
      tz
      submitEmailSubject
      submitEmailTemplate
      sendSubmitEmail
      senderAddress
      sendCalendarInvite
      companyEmailsOnly
      contactFormBccEmails {
        edges {
          node {
            id
            email
          }
        }
      }
      eventEmailTemplates {
        edges {
          node {
            id
            ...ScheduledEmailTemplate_eventEmailTemplate
          }
        }
      }
    }
  `,
  me: graphql`
    fragment EmailSettings_me on User {
      tz
    }
  `,
});
