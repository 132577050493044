/**
 * @flow
 * @relayHash 4e4880aa10d8a1a8737a7ce97ca3fc89
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTextComponentInput = {
  clientMutationId?: ?string,
  id: string,
  padding?: ?string,
  backgroundColor?: ?string,
  value?: ?string,
  defaultFont?: ?string,
};
export type updateTextComponentMutationVariables = {|
  input: UpdateTextComponentInput
|};
export type updateTextComponentMutationResponse = {|
  +updateRegistrationFormTextComponent: ?{|
    +pageComponent: {|
      +textComponent: ?{|
        +id: string,
        +value: string,
        +padding: string,
        +backgroundColor: ?string,
      |}
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type updateTextComponentMutation = {|
  variables: updateTextComponentMutationVariables,
  response: updateTextComponentMutationResponse,
|};
*/


/*
mutation updateTextComponentMutation(
  $input: UpdateTextComponentInput!
) {
  updateRegistrationFormTextComponent(input: $input) {
    pageComponent {
      textComponent {
        id
        value
        padding
        backgroundColor
      }
      id
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTextComponentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateTextComponentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "textComponent",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationTextComponent",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "value",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "padding",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundColor",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateTextComponentMutation",
  "id": null,
  "text": "mutation updateTextComponentMutation(\n  $input: UpdateTextComponentInput!\n) {\n  updateRegistrationFormTextComponent(input: $input) {\n    pageComponent {\n      textComponent {\n        id\n        value\n        padding\n        backgroundColor\n      }\n      id\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateTextComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormTextComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTextComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3
            ]
          },
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateTextComponentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormTextComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTextComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3,
              v2
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '021162d71e8f9983ab47c5914c036d88';
module.exports = node;
