/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';

import type { SubmissionReviewFormRespondent_submission } from './__generated__/SubmissionReviewFormRespondent_submission.graphql';

const Container = styled.div`
  margin: 25px 0;
  padding: 15px 30px;
  border-radius: 3px;
  background: #fff;
`;

const Row = styled.div`
  display: flex;
  margin: 8px 0;
  color: #4a5665;
`;

const Label = styled.div`
  width: 150px;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    text-decoration: underline;
  }
`;

class SubmissionReviewFormRespondent extends React.PureComponent<{
  submission: SubmissionReviewFormRespondent_submission,
  tz: string,
  location: Location,
}> {
  getSubmittedByLink = () => {
    const {
      submission: { user, contact },
    } = this.props;
    if (user) {
      return {
        pathname: `/workspace/members/${user.id}`,
        state: {
          prevPath: `/dashboard/event_requests/${this.props.submission.id}`,
          prevPage: true,
          prevPageLabel: 'Requests Inbox',
          search: this.props.location.search,
        },
      };
    }
    if (contact) {
      return {
        pathname: `/contacts/people/${contact.slug}`,
        state: {
          prevPath: `/dashboard/event_requests/${this.props.submission.id}`,
          prevPage: true,
          prevPageLabel: 'Requests Inbox',
          search: this.props.location.search,
        },
      };
    }
    return '/';
  };

  render() {
    const {
      submission: {
        user,
        contact,
        createdAt,
        event: { slug, name },
      },
    } = this.props;
    const respondent = user || contact;

    if (!respondent) return null;

    return (
      <Container>
        <Row>
          <Label>Event:</Label>
          <StyledLink to={`/events/${slug}`}>{name}</StyledLink>
        </Row>
        <Row>
          <Label>Submitted By:</Label>
          <StyledLink to={this.getSubmittedByLink()}>
            {respondent.firstName} {respondent.lastName}
          </StyledLink>
        </Row>
        <Row>
          <Label>Email:</Label>
          {respondent.email}
        </Row>
        <Row>
          <Label>Submitted Date:</Label>
          {formatDateTime(moment.tz(createdAt, this.props.tz))}
        </Row>
      </Container>
    );
  }
}

export default createFragmentContainer(
  SubmissionReviewFormRespondent,
  graphql`
    fragment SubmissionReviewFormRespondent_submission on EventRequestSubmission {
      id
      createdAt
      user {
        id
        firstName
        lastName
        email
      }
      contact {
        slug
        firstName
        lastName
        email
      }
      event {
        slug
        name
      }
    }
  `,
);
