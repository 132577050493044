/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';

import { createMoment } from 'utils/Date';

import BriefScheduleItem from './BriefScheduleItem';
import DayContainer from './components/DayContainer';
import DayLabel from './components/DayLabel';
import ScheduleItemContainer from './components/ScheduleItemContainer';

import BriefScheduleDay_event from './__generated__/BriefScheduleDay_event.graphql';
import BriefScheduleDay_scheduleDay from './__generated__/BriefScheduleDay_scheduleDay.graphql';

type ScheduleItemType = $PropertyType<
  $ElementType<
    $PropertyType<$PropertyType<BriefScheduleDay_scheduleDay, 'scheduleItems'>, 'edges'>,
    0,
  >,
  'node',
>;

const BriefScheduleDay = (props: {
  event: BriefScheduleDay_event,
  scheduleDay: BriefScheduleDay_scheduleDay,
}) => {
  const {
    event,
    event: { tz },
    scheduleDay,
    scheduleDay: { scheduleItems },
  } = props;
  const dateMoment = createMoment(tz)(scheduleDay.date);
  const dayItems: $ReadOnlyArray<ScheduleItemType> = scheduleItems.edges.map(edge => edge.node);
  const dayItemsSorted = sortBy(dayItems, ['startTime']);
  return dayItemsSorted.length > 0 ? (
    <DayContainer>
      <DayLabel>{dateMoment.renderToAccordingFormat('dddd[,] MMM D')}</DayLabel>
      {dayItemsSorted.map(scheduleItem => (
        <ScheduleItemContainer key={scheduleItem.id}>
          <BriefScheduleItem scheduleItem={scheduleItem} event={event} />
        </ScheduleItemContainer>
      ))}
    </DayContainer>
  ) : null;
};

export default createFragmentContainer(BriefScheduleDay, {
  event: graphql`
    fragment BriefScheduleDay_event on Event {
      id
      tz
      ...BriefScheduleItem_event
    }
  `,
  scheduleDay: graphql`
    fragment BriefScheduleDay_scheduleDay on ScheduleDay {
      id
      date
      scheduleItems(isPersonalized: $personalizeSchedules) {
        edges {
          node {
            id
            allDay
            startTime
            ...BriefScheduleItem_scheduleItem
          }
        }
      }
    }
  `,
});
