/* @flow */
import * as React from 'react';
import { QueryRenderer } from 'react-relay';
import styled from 'styled-components';
import omit from 'lodash/omit';

import type {
  QueryRendererError,
  QueryRendererResponseType,
  QueryRendererRetry,
} from 'graph/types/QueryRendererResponseType';
import getRelayEnvironment from 'graph/utils/getRelayEnvironment';
import getRelayPublicEnvironment from 'graph/utils/getRelayPublicEnvironment';

import ErrorView from 'components/ErrorView';
import Loader from 'components/Loader';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;
/**
 * Same interface as QueryRenderer except:
 * - pass `renderSuccess` and optionally `renderError` and `renderLoading` instead of `render`
 * - `environment` prop not required
 */
export default class DefaultQueryRenderer extends React.Component<{
  render?: (props: any) => React.Node,
  renderSuccess: (props: any) => React.Node,
  renderError?: ?(error: QueryRendererError, retry: ?QueryRendererRetry) => React.Node,
  renderLoading?: ?() => React.Node,
  public?: boolean,
}> {
  render() {
    // eslint-disable-next-line react/prop-types
    if (this.props.render != null) {
      throw new Error('DefaultQueryRenderer accepts renderSuccess, not render');
    }
    const passThroughProps = omit(this.props, 'renderSuccess');
    const environment = this.props.public ? getRelayPublicEnvironment() : getRelayEnvironment();

    return (
      <QueryRenderer
        environment={environment}
        render={(response: QueryRendererResponseType<{}>) => {
          if (response.error) {
            return this.props.renderError ? (
              this.props.renderError(response.error, response.retry)
            ) : (
              <ErrorView error={response.error} retry={response.retry} />
            );
          }
          if (response.props) {
            return this.props.renderSuccess(response.props);
          }
          return this.props.renderLoading ? (
            this.props.renderLoading()
          ) : (
            <LoaderContainer>
              <Loader size={30} />
            </LoaderContainer>
          );
        }}
        {...passThroughProps}
      />
    );
  }
}
