import { SENTRY_PUBLIC_DSN } from 'config/variables';

function getRaven() {
  return typeof window !== 'undefined' ? require('raven-js') : null; // eslint-disable-line global-require
}

export function initErrorReporting() {
  const dsn = SENTRY_PUBLIC_DSN;
  const Raven = getRaven();
  if (dsn && Raven) {
    Raven.config(dsn).install();
  }
}

export function setErrorReportingUser(userInfo) {
  const Raven = getRaven();
  if (Raven) {
    Raven.setUserContext(userInfo);
  }
}

export function clearErrorReportingUser() {
  const Raven = getRaven();
  if (Raven) {
    Raven.setUserContext();
  }
}

/**
 * Sends an exception to Sentry without throwing it for the client.
 * Usage: reportException(new Error('message'));
 */
export function reportException(e, componentStack = null) {
  const Raven = getRaven();

  if (Raven) {
    const extra = componentStack ? { componentStack } : {};
    Raven.captureException(e, {
      extra,
    });
  }
}
