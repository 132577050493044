/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ExpenseActionCell_event$ref = any;
type ExpenseActualAmountCell_event$ref = any;
type ExpenseActualAmountFooterCell_event$ref = any;
type ExpenseNameCell_event$ref = any;
type ExpenseNoteCell_event$ref = any;
type ExpensePaymentStatusCell_event$ref = any;
type ExpenseTeamSplitCell_event$ref = any;
type ExpenseVendorCell_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpensesTableRenderer_event$ref: FragmentReference;
export type ExpensesTableRenderer_event = {|
  +$fragmentRefs: ExpenseNameCell_event$ref & ExpenseVendorCell_event$ref & ExpenseActualAmountCell_event$ref & ExpensePaymentStatusCell_event$ref & ExpenseNoteCell_event$ref & ExpenseActionCell_event$ref & ExpenseActualAmountFooterCell_event$ref & ExpenseTeamSplitCell_event$ref,
  +$refType: ExpensesTableRenderer_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExpensesTableRenderer_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ExpenseNameCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseVendorCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseActualAmountCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpensePaymentStatusCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseNoteCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseActionCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseActualAmountFooterCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ExpenseTeamSplitCell_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'df18f4668267af762ecebe8fb4626c97';
module.exports = node;
