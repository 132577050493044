/* @flow */
import React from 'react';
import styled from 'styled-components';

import DateTimeInput from '../DateTimeInput';
import DateTimeRangeOverlay from './DateTimeRangeOverlay';
import normalizeDatesConfig from './normalizeDatesConfig';

const Root = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
`;

export const InputWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;

  &:first-child {
    margin-right: 28px;
  }
`;

export type DatesConfig = {|
  startDate: ?string,
  endDate: ?string,
  hideStartTime: boolean,
  hideEndTime: boolean,
|};

export default class DateTimeRangePicker extends React.PureComponent<
  {
    onChange: (datesConfig: DatesConfig) => void,
    ...DatesConfig,
    tz: string,
    startLabel?: string,
    endLabel?: string,
    startPlaceholder?: string,
    endPlaceholder?: string,
    datesOnly?: boolean,
    disabled?: boolean,
    autoFocus?: boolean,
    error?: ?string | ?boolean,
    onFocus?: () => void,
    onBlur?: () => void,
    overlayContainer?: ?HTMLElement,
  },
  {
    showOverlay: boolean,
  },
> {
  state = {
    showOverlay: false,
  };

  inputRef = React.createRef();

  containerRef = React.createRef();

  overlayRef = React.createRef();

  handleShowOverlay = () => {
    this.setState({ showOverlay: true });

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleHideOverlay = () => {
    this.setState({ showOverlay: false });

    if (this.props.onBlur) this.props.onBlur();
  };

  handleBlur = (e: SyntheticEvent<HTMLDivElement>) => {
    const relatedTarget: ?Node = (e: any).relatedTarget || document.activeElement;
    const overlay = this.overlayRef.current;

    if (
      relatedTarget &&
      overlay &&
      (e.currentTarget.contains(relatedTarget) || overlay.contains(relatedTarget))
    )
      return;

    this.handleHideOverlay();
  };

  handleChange = (datesConfig: $Shape<DatesConfig>) => {
    this.props.onChange(
      normalizeDatesConfig({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        hideStartTime: this.props.hideStartTime,
        hideEndTime: this.props.hideEndTime,
        ...datesConfig,
      }),
    );
  };

  handleChangeStartInput = (dateConfig: {| date: ?string, hideTime: boolean |}) => {
    this.handleChange({
      startDate: dateConfig.date,
      hideStartTime: dateConfig.hideTime,
      endDate: dateConfig.date ? this.props.endDate : null,
      hideEndTime: dateConfig.date ? this.props.hideEndTime : true,
    });
  };

  handleChangeEndInput = (dateConfig: {| date: ?string, hideTime: boolean |}) => {
    this.handleChange({ endDate: dateConfig.date, hideEndTime: dateConfig.hideTime });
  };

  render() {
    return (
      <Root ref={this.containerRef} onBlur={this.handleBlur}>
        <InputWrapper>
          <DateTimeInput
            tz={this.props.tz}
            label={this.props.startLabel}
            placeholder={this.props.startPlaceholder}
            date={this.props.startDate}
            hideTime={this.props.hideStartTime}
            dateOnly={this.props.datesOnly}
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
            onFocus={this.handleShowOverlay}
            onChange={this.handleChangeStartInput}
            error={this.props.error}
            ref={this.inputRef}
          />
        </InputWrapper>

        <InputWrapper>
          <DateTimeInput
            tz={this.props.tz}
            label={this.props.endLabel}
            placeholder={this.props.endPlaceholder}
            date={this.props.endDate || this.props.startDate}
            hideTime={this.props.hideEndTime}
            dateOnly={this.props.datesOnly}
            disabled={this.props.disabled}
            onFocus={this.handleShowOverlay}
            onChange={this.handleChangeEndInput}
            error={!!this.props.error}
          />
        </InputWrapper>

        <DateTimeRangeOverlay
          container={this.props.overlayContainer || this.containerRef.current}
          target={this.inputRef.current}
          show={this.state.showOverlay}
          onHide={this.handleHideOverlay}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          hideStartTime={this.props.hideStartTime}
          hideEndTime={this.props.hideEndTime}
          tz={this.props.tz}
          onChange={this.props.onChange}
          datesOnly={this.props.datesOnly}
          disableHideOnClickOut
          overlayRef={this.overlayRef}
        />
      </Root>
    );
  }
}
