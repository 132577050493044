/* @flow */
import React from 'react';
import { type Location, type RouterHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { OutlineButton } from 'components/budget/Button';
import Loader from 'components/Loader';
import Button from 'components/material/Button';

const Footer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  flex-shrink: 0;
  background: #fff;
  border-top: 1px solid #eee;
  transition: 0.2s;
`;

const FooterInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 770px;
  height: 70px;
  div {
    button {
      min-width: 100px;
      margin-left: 20px;
      font-size: 16px;
      &:first-child {
        margin-right: 40px;
      }
    }
  }
`;

const HandlersBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  i {
    margin-right: 15px;
  }
`;

const StyledLoader = styled(Loader)`
  margin-right: 15px;
`;

const Errors = styled.div`
  display: flex;
  color: ${props => props.theme.negativeActionColor};
`;

const Success = styled.div`
  display: flex;
  color: ${props => props.theme.highlightActionColor};
`;

const Saving = styled.div`
  display: flex;
  color: ${props => props.theme.mutedTextColor};
`;

const CancelButton = styled(OutlineButton)`
  display: initial;
  min-width: 95px;
  margin-left: auto;
  margin-right: 40px;
  border-radius: 3px;
  color: ${props => props.theme.outlineButtonColor};
  border: 1px solid currentColor;
  &:hover,
  &:focus {
    color: ${props => props.theme.outlineButtonFocusColor};
    background: transparent;
  }
  &:disabled {
    background: transparent;
    color: ${props => props.theme.disabledActionColor};
    &:hover,
    &:focus {
      background: transparent;
      color: ${props => props.theme.disabledActionColor};
    }
  }
`;

class EventRequestFormBuilderFooter extends React.PureComponent<{
  activeStepIndex: number,
  onNextStep: () => void,
  onPrevStep?: () => void,
  errors: $ReadOnlyArray<string>,
  loading: boolean,
  saved?: boolean,
  saving?: boolean,
  location: Location,
  history: RouterHistory,
}> {
  handleBack = () => {
    if (this.props.onPrevStep) {
      this.props.onPrevStep();
    }
  };

  goToRequests = () => {
    const backUrl = this.props.location.state && this.props.location.state.backUrl;
    this.props.history.push(backUrl || '/settings/events/requests');
  };

  render() {
    return (
      <Footer>
        <FooterInner>
          <HandlersBox>
            {this.props.errors && this.props.errors.length > 0 && (
              <Errors>
                <i className="fa fa-fw fa-warning" />
                <div>
                  {this.props.errors.map(error => (
                    <div key={error}>{error}</div>
                  ))}
                </div>
              </Errors>
            )}
            {this.props.saving && (
              <Saving>
                <StyledLoader size={22} color="#29cc71" />
                <div>Saving</div>
              </Saving>
            )}
            {this.props.saved && (
              <Success>
                <i className="fa fa-fw fa-check" />
                <div>Saved</div>
              </Success>
            )}
          </HandlersBox>
          <div>
            <CancelButton label="Close" onClick={this.goToRequests} />
            <Button
              label="Back"
              disabled={this.props.activeStepIndex === 0 || this.props.loading}
              primary
              outline
              onClick={this.handleBack}
            />
            <Button
              label={this.props.activeStepIndex === 3 ? 'Publish' : 'Next'}
              primary
              onClick={this.props.onNextStep}
              loading={this.props.loading}
            />
          </div>
        </FooterInner>
      </Footer>
    );
  }
}

export default withRouter(EventRequestFormBuilderFooter);
