/* @flow */
import React from 'react';
import { type RelayRefetchProp, createRefetchContainer, graphql } from 'react-relay';
import type { Match } from 'react-router';
import styled from 'styled-components';

import Loader from 'components/Loader';

import UIContext from '../UIContext';
import RegistrationSettingsContent from './RegistrationSettingsContent';

import type { RegistrationSettingsRefetchContainer_query } from './__generated__/RegistrationSettingsRefetchContainer_query.graphql';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

class RegistrationSettingsRefetchContainer extends React.PureComponent<
  {
    match: Match,
    discardCounter?: number,
    query: RegistrationSettingsRefetchContainer_query,
    relay: RelayRefetchProp,
  },
  {
    loading: boolean,
  },
> {
  state = { loading: false };

  componentDidUpdate(prevProps: $PropertyType<RegistrationSettingsRefetchContainer, 'props'>) {
    const { discardCounter, match, relay } = this.props;
    if (discardCounter !== prevProps.discardCounter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: true });
      const eventSlug = match.params.eventSlug;
      relay.refetch(
        { eventSlug, isEventRegForm: !!eventSlug },
        null,
        () => {
          this.setState({ loading: false });
        },
        { force: true },
      );
    }
  }

  render() {
    const { me, event, org } = this.props.query;
    if (this.state.loading) {
      return (
        <UIContext.Consumer>
          {({ loaderColor }) => (
            <LoaderContainer>
              <Loader color={loaderColor} size={30} />
            </LoaderContainer>
          )}
        </UIContext.Consumer>
      );
    }

    return (
      <RegistrationSettingsContent
        me={me}
        org={org}
        event={event}
        registrationForm={event != null ? event.editableRegistrationForm : org.registrationForm}
      />
    );
  }
}

export default createRefetchContainer(
  RegistrationSettingsRefetchContainer,
  graphql`
    fragment RegistrationSettingsRefetchContainer_query on Query {
      event(slug: $eventSlug) @include(if: $isEventRegForm) {
        ...RegistrationSettingsContent_event
        editableRegistrationForm {
          ...RegistrationSettingsContent_registrationForm
        }
      }
      org {
        ...RegistrationSettingsContent_org
        registrationForm @skip(if: $isEventRegForm) {
          ...RegistrationSettingsContent_registrationForm
        }
      }
      me {
        ...RegistrationSettingsContent_me
      }
    }
  `,
  graphql`
    query RegistrationSettingsRefetchContainerQuery($eventSlug: String, $isEventRegForm: Boolean!) {
      ...RegistrationSettingsRefetchContainer_query
    }
  `,
);
