import isInIframe from 'utils/isInIframe';

import { expireCookie, getCookie } from './Cookies';
import setAccessToken from './setAccessToken';

function getUser() {
  const accessToken =
    (isInIframe() && sessionStorage.getItem('embed_user')) || getCookie('user') || null;

  if (!accessToken) return null;
  return {
    access_token: accessToken,
    user_id: +accessToken.split(':')[0],
  };
}

/**
 *  Public
 */

export function isLoggedIn() {
  return !!getUser();
}

export function getAccessToken() {
  return isLoggedIn() ? getUser().access_token : null;
}

export const setUser = user => {
  if (user && user.access_token) {
    const { access_token } = user;
    setAccessToken(access_token);
  } else {
    expireCookie('user');
  }
};
