/* @flow */
import React from 'react';
import styled from 'styled-components';

import fonts from 'config/fonts';
import timezones from 'config/timezones';

import stringifyTimeZone from 'utils/date/stringifyTimeZone';
import showErrorPopup from 'utils/showErrorPopup';
import pickFiles from 'utils/uploading/pickFiles';

import Button from 'components/material/Button';
import ColorPicker from 'components/material/ColorPicker';
import getPresetColors from 'components/material/ColorPicker/getPresetColors';
import SelectField from 'components/material/SelectField';
import TextInput from 'components/material/TextInput';
import {
  SettingContainer,
  SettingContent,
  SettingItemDetails,
  SettingItemLabel,
  SettingSection,
} from 'components/Settings';

const Logo = styled.img`
  max-width: 200px;
`;

const InputWrapper = styled.div`
  max-width: 300px;
`;

export type Styles = {|
  logo: ?string,
  primaryColor: string,
  secondaryColor: string,
  font: string,
  scheduleName: string,
  tz?: string,
|};

type Props = {
  styles: Styles,
  orgName?: string,
  onChangeOrgName?: (name: string) => void,
  onChange: (styles: $Shape<Styles>) => void,
};

type State = {
  orgName: string,
  scheduleName: string,
  propScheduleName: string,
};

export default class StyleSettings extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.styles.scheduleName !== prevState.propScheduleName) {
      return {
        propScheduleName: nextProps.styles.scheduleName,
        scheduleName: nextProps.styles.scheduleName,
      };
    }

    return null;
  }

  state = {
    scheduleName: this.props.styles.scheduleName,
    propScheduleName: this.props.styles.scheduleName,
    orgName: this.props.orgName || '',
  };

  handleChangePrimaryColor = (color: string) => {
    this.handleSave({ primaryColor: color });
  };

  handleChangeSecondaryColor = (color: string) => {
    this.handleSave({ secondaryColor: color });
  };

  handleChangeScheduleName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ scheduleName: e.currentTarget.value });
  };

  handleBlurScheduleName = () => {
    this.handleSave({ scheduleName: this.state.scheduleName });
  };

  handleChangeOrgName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ orgName: e.currentTarget.value });
  };

  handleBlurOrgName = () => {
    if (!this.state.orgName.trim()) {
      this.setState({ orgName: this.props.orgName });
      return;
    }
    if (this.props.onChangeOrgName && this.state.orgName !== this.props.orgName) {
      this.props.onChangeOrgName(this.state.orgName);
    }
  };

  handleChangeFont = (value: ?string) => {
    if (value) {
      this.handleSave({ font: value });
    }
  };

  handleChangeTz = (value: ?string) => {
    if (value) {
      this.handleSave({ tz: value });
    }
  };

  handleChooseLogo = () => {
    pickFiles({
      accept: ['image/*'],
      maxFiles: 1,
    })
      .then(files => {
        if (files && files[0]) {
          this.handleSave({ logo: files[0].fileurl });
        }
      })
      .catch(showErrorPopup);
  };

  handleSave(data: $Shape<Styles>) {
    if (Object.entries(data).every(([key, value]) => this.props.styles[key] === value)) return;

    this.props.onChange(data);
  }

  render() {
    const presetColors = getPresetColors({ settings: this.props.styles });
    const timezonesOptions = timezones.map(tz => ({
      value: tz.name,
      label: stringifyTimeZone(tz),
    }));
    return (
      <SettingContainer>
        <SettingSection>
          {this.props.styles.tz && (
            <SettingContent>
              <SettingItemLabel>Time Zone</SettingItemLabel>
              <SettingItemDetails>
                <InputWrapper>
                  <SelectField
                    options={timezonesOptions}
                    onChange={this.handleChangeTz}
                    value={this.props.styles.tz}
                    searchable
                  />
                </InputWrapper>
              </SettingItemDetails>
            </SettingContent>
          )}
          <SettingContent>
            <SettingItemLabel>Logo</SettingItemLabel>
            <SettingItemDetails>
              {this.props.styles.logo && <Logo src={this.props.styles.logo} alt="Logo" />}
              <div>
                <Button onClick={this.handleChooseLogo} label="Upload Logo" minimal />
              </div>
            </SettingItemDetails>
          </SettingContent>
          {this.props.orgName && (
            <SettingContent>
              <SettingItemLabel>Workspace name</SettingItemLabel>
              <SettingItemDetails>
                <InputWrapper>
                  <TextInput
                    value={this.state.orgName}
                    onChange={this.handleChangeOrgName}
                    onBlur={this.handleBlurOrgName}
                  />
                </InputWrapper>
              </SettingItemDetails>
            </SettingContent>
          )}
          <SettingContent>
            <SettingItemLabel>Primary color</SettingItemLabel>
            <SettingItemDetails>
              <ColorPicker
                color={this.props.styles.primaryColor}
                onChangeComplete={this.handleChangePrimaryColor}
                presetColors={presetColors}
              />
            </SettingItemDetails>
          </SettingContent>
          <SettingContent>
            <SettingItemLabel>Secondary color</SettingItemLabel>
            <SettingItemDetails>
              <ColorPicker
                color={this.props.styles.secondaryColor}
                onChangeComplete={this.handleChangeSecondaryColor}
                presetColors={presetColors}
              />
            </SettingItemDetails>
          </SettingContent>
          <SettingContent>
            <SettingItemLabel>Font</SettingItemLabel>
            <SettingItemDetails>
              <InputWrapper>
                <SelectField
                  options={fonts}
                  value={this.props.styles.font}
                  onChange={this.handleChangeFont}
                />
              </InputWrapper>
            </SettingItemDetails>
          </SettingContent>
          <SettingContent>
            <SettingItemLabel>Default schedule name</SettingItemLabel>
            <SettingItemDetails>
              <InputWrapper>
                <TextInput
                  value={this.state.scheduleName}
                  onChange={this.handleChangeScheduleName}
                  onBlur={this.handleBlurScheduleName}
                />
              </InputWrapper>
            </SettingItemDetails>
          </SettingContent>
        </SettingSection>
      </SettingContainer>
    );
  }
}
