/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import sortBy from 'lodash/sortBy';

import detectIE from 'utils/browsers/detectIE';
import isSafari from 'utils/browsers/isSafari';

import FormIcon from 'images/registration/form.svg';

import UIContext from '../../UIContext';
import pageComponentsConfig, {
  type ContentComponentType,
  type DragToColumnPropsType,
  type PageComponentKindType,
  type PageComponentType,
  type ReorderedPageComponentConfigType,
} from '../pageComponentsConfig';
import type { SelectedComponent } from '../RegistrationCreateContent';
import PageComponent from '../RegistrationPageProperties/Design/PageComponents/PageComponent';
import { FormComponentContent, Frame } from './components';
import DraggablePageComponent from './DraggablePageComponent';
import DroppableArea from './DroppableArea';

import type { RegistrationPageContent_org } from './__generated__/RegistrationPageContent_org.graphql';
import type { RegistrationPageContent_registrationForm } from './__generated__/RegistrationPageContent_registrationForm.graphql';

type Direction = 'up' | 'down';

const scrollSpeed = 4;

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: auto;
`;

const Container = styled.div`
  flex: 1 1 100%;
  overflow: auto;
  min-width: 800px;
  word-break: break-all;
  ${props =>
    props.browserIeOrSafari &&
    css`
      margin-top: -20px;
    `}
`;

const ScrollUp = styled.div`
  z-index: 10;
  flex: 0 0 20px;
  height: 20px;
  background-color: transparent;
`;

const ScrollDown = styled.div`
  z-index: 10;
  flex: 1 1 auto;
  height: 20px;
  margin-top: -20px;
  background-color: transparent;
`;

const Content = styled.div`
  width: ${props => `${props.widthNumeric}${props.widthMeasurement === 'PERCENTAGE' ? '%' : 'px'}`};
  max-width: 100%;
  padding: 54px 29px;
  margin: 0 auto;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-color: ${props => props.bgColor};
`;

const StyledPageComponent = styled(PageComponent)`
  border: 1px solid #6b80f9;
  box-shadow: 0 0 4px 0 rgba(136, 149, 224, 0.5);
`;

class RegistrationPageContent extends React.PureComponent<
  {
    registrationForm: RegistrationPageContent_registrationForm,
    org: RegistrationPageContent_org,
    pageComponents: Array<PageComponentType>,
    draggedNewComponentKind: ?PageComponentKindType,
    selectedComponent: SelectedComponent,
    hoveredPageComponent: PageComponentKindType,
    pageWidth: number,
    onSelectPageComponent: (id: string) => void,
    onPageComponentMoveEnd: (
      reorderedPageComponentConfig: ReorderedPageComponentConfigType,
    ) => void,
    onUnselectComponent: () => void,
    onShowPageComponents: () => void,
  },
  {
    draggedComponentId: ?string,
    draggedHeight: ?number,
    draggedToColumnProps: ?DragToColumnPropsType,
    scrollDirection: ?Direction,
  },
> {
  state = {
    draggedComponentId: null,
    draggedHeight: null,
    draggedToColumnProps: null,
    scrollDirection: null,
  };

  scrollContainer = React.createRef();

  timer: ?IntervalID;

  componentDidUpdate() {
    const scrollDirection = this.state.scrollDirection;
    if (!scrollDirection) {
      this.stopTimer();
      return;
    }
    if (scrollDirection === 'up') {
      this.timer = setInterval(this.scrollUp, 0);
      return;
    }
    this.timer = setInterval(this.scrollDown, 0);
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  stopTimer = () => {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  };

  scrollUp = () => {
    if (!this.scrollContainer || !this.scrollContainer.current) {
      return;
    }
    this.scrollContainer.current.scrollTop -= scrollSpeed;
  };

  scrollDown = () => {
    if (!this.scrollContainer || !this.scrollContainer.current) {
      return;
    }
    this.scrollContainer.current.scrollTop += scrollSpeed;
  };

  getContentComponent = (kind?: ?string): ?ContentComponentType => {
    if (!kind) {
      return null;
    }
    const currentComponent = pageComponentsConfig.find(
      pageComponent => pageComponent.kind === kind,
    );
    if (!currentComponent) {
      return FormComponentContent;
    }
    return currentComponent.contentComponent;
  };

  setColumnDragDropOptimistic = (
    reorderedPageComponentConfig: ReorderedPageComponentConfigType,
  ) => {
    if (!reorderedPageComponentConfig.parentId || !reorderedPageComponentConfig.id) {
      this.setState({ draggedToColumnProps: null });
      return;
    }
    const rowId = this.getContainingRowId(reorderedPageComponentConfig.parentId);
    if (!rowId) {
      return;
    }
    this.setState({
      draggedToColumnProps: {
        rowId,
        // below two lines checks for flow, it already exist at this point
        columnId: reorderedPageComponentConfig ? reorderedPageComponentConfig.parentId || '' : '',
        id: reorderedPageComponentConfig ? reorderedPageComponentConfig.id || '' : '',
      },
    });
  };

  handleResetDraggedToColumnProps = () => {
    this.setState({ draggedToColumnProps: null });
  };

  handlePageComponentMoveEnd = (
    reorderedPageComponentConfig?: ReorderedPageComponentConfigType,
  ) => {
    this.setState({ draggedComponentId: null });
    if (!reorderedPageComponentConfig) {
      return;
    }
    this.props.onPageComponentMoveEnd(reorderedPageComponentConfig);
    if (!reorderedPageComponentConfig.parentId) {
      return;
    }
    this.setColumnDragDropOptimistic(reorderedPageComponentConfig);
  };

  handleBeginDrag = (draggedComponentId: string) => {
    const element = document.getElementById(draggedComponentId);
    if (!element) {
      return;
    }
    this.props.onSelectPageComponent(draggedComponentId);
    this.setState({ draggedComponentId, draggedHeight: element.offsetHeight }, () => {
      setTimeout(() => this.setState({ draggedHeight: null }), 0);
    });
  };

  getDraggedPreview = (componentKind: ?PageComponentKindType): ?React.Node => {
    if (!componentKind) {
      return null;
    }
    const componentConfig =
      componentKind === 'FORM'
        ? { label: 'Form', icon: <FormIcon />, kind: 'FORM' }
        : pageComponentsConfig.find(config => config.kind === componentKind);
    if (!componentConfig) {
      return null;
    }
    return <StyledPageComponent componentConfig={componentConfig} />;
  };

  getContainingRowId = (columnId: string): ?string => {
    const pageComponents = this.props.registrationForm.pageComponents;
    const columnEdge = pageComponents.edges.find(({ node }) => node.id === columnId);

    if (!columnEdge) {
      return null;
    }
    const row = columnEdge.node.parent;
    if (!row) {
      return null;
    }
    return row.id;
  };

  getComponentToPassSizeValue = (): ?string => {
    const {
      selectedComponent,
      registrationForm: { pageComponents },
    } = this.props;

    if (!selectedComponent) {
      return null;
    }
    const { selectedComponentId, ...selectedComponentProps } = selectedComponent;
    if (!Object.values(selectedComponentProps).some(property => !!property)) {
      return null;
    }

    const selectedPageComponent = pageComponents.edges.find(
      ({ node }) => node.id === selectedComponent.selectedComponentId,
    );

    if (!selectedPageComponent) {
      return null;
    }

    if (!selectedPageComponent.node.parent) {
      return selectedPageComponent.node.id;
    }

    return this.getContainingRowId(selectedPageComponent.node.parent.id);
  };

  handleScrollUpHover = () => {
    this.setState({ scrollDirection: 'up' });
  };

  handleScrollDownHover = () => {
    this.setState({ scrollDirection: 'down' });
  };

  handleScrollTriggerHoverOut = () => {
    this.setState({ scrollDirection: null });
  };

  render() {
    const {
      registrationForm,
      org,
      pageComponents,
      selectedComponent,
      onSelectPageComponent,
      onUnselectComponent,
      onShowPageComponents,
      pageWidth,
      hoveredPageComponent,
      draggedNewComponentKind,
    } = this.props;
    if (!registrationForm) {
      return null;
    }
    const { draggedComponentId, draggedHeight, draggedToColumnProps } = this.state;
    const draggedComponent = registrationForm.pageComponents.edges.find(
      ({ node }) => node.id === draggedComponentId,
    );
    const draggedComponentKind =
      draggedNewComponentKind || (draggedComponent && draggedComponent.node.kind);
    const draggedPreview: ?React.Node = this.getDraggedPreview(
      draggedComponent && draggedComponent.node.kind,
    );
    const componentIdToPassSelectedProps = this.getComponentToPassSizeValue();
    let draggedComponentOrder: ?number = null;
    const browserIeOrSafari = isSafari || detectIE();
    return (
      <UIContext.Consumer>
        {({ fontFamily }) => (
          <Wrapper>
            {browserIeOrSafari != null && browserIeOrSafari !== false && (
              <ScrollUp
                onDragEnter={this.handleScrollUpHover}
                onDragLeave={this.handleScrollTriggerHoverOut}
              />
            )}
            <Container ref={this.scrollContainer}>
              <Content
                bgImage={registrationForm.backgroundUrl}
                bgColor={registrationForm.backgroundColor}
                widthNumeric={pageWidth || registrationForm.width}
                widthMeasurement={registrationForm.widthMeasurement}
              >
                {sortBy(pageComponents, 'order').map(pageComponent => {
                  const Component = this.getContentComponent(pageComponent.kind);
                  const content = Component && (
                    <Component
                      registrationForm={registrationForm}
                      componentProps={pageComponent}
                      org={org}
                      active={
                        selectedComponent &&
                        pageComponent.id === selectedComponent.selectedComponentId
                      }
                      defaultFont={fontFamily || 'Roboto'}
                      onSelectPageComponent={onSelectPageComponent}
                      selectedComponentId={
                        selectedComponent && selectedComponent.selectedComponentId
                      }
                      onUnselectComponent={onUnselectComponent}
                      draggedComponentId={draggedComponentId}
                      draggedComponentKind={draggedComponentKind}
                      hoveredPageComponent={hoveredPageComponent}
                      onMoveEnd={this.handlePageComponentMoveEnd}
                      onBeginDrag={this.handleBeginDrag}
                      onShowPageComponents={onShowPageComponents}
                      onResetDraggedToColumnProps={this.handleResetDraggedToColumnProps}
                      draggedToColumnProps={
                        draggedToColumnProps && draggedToColumnProps.rowId === pageComponent.id
                          ? draggedToColumnProps
                          : undefined
                      }
                      {...(componentIdToPassSelectedProps === pageComponent.id
                        ? { selectedComponent }
                        : {})}
                    />
                  );
                  const showPreview =
                    draggedComponentId === pageComponent.id &&
                    draggedPreview != null &&
                    draggedHeight != null;
                  const style =
                    showPreview && draggedHeight != null
                      ? {
                          height: `${draggedHeight}px`,
                        }
                      : {};
                  const currentComponentDraggedOrder =
                    pageComponent.id === draggedComponentId ? pageComponent.order : null;
                  draggedComponentOrder =
                    draggedComponentOrder !== null
                      ? draggedComponentOrder
                      : currentComponentDraggedOrder;
                  return (
                    <div
                      key={pageComponent.id}
                      style={style}
                      onClick={() => onSelectPageComponent(pageComponent.id)}
                    >
                      {(draggedComponentOrder == null ||
                        ![draggedComponentOrder, draggedComponentOrder + 1].some(
                          undroppableAreaOrder => undroppableAreaOrder === pageComponent.order,
                        )) && (
                        <DroppableArea
                          order={pageComponent.order}
                          parentId={null}
                          visible={draggedComponentKind || hoveredPageComponent}
                        />
                      )}
                      <DraggablePageComponent
                        pageComponent={{
                          id: pageComponent.id,
                          order: pageComponent.order,
                          kind: pageComponent.kind,
                        }}
                        selectedComponentId={
                          selectedComponent && selectedComponent.selectedComponentId
                        }
                        draggingState={draggedComponentKind}
                        onBeginDrag={this.handleBeginDrag}
                        onMoveEnd={this.handlePageComponentMoveEnd}
                        onUnselectComponent={onUnselectComponent}
                        dragPreviewInit={
                          draggedComponentId === pageComponent.id && draggedHeight != null
                        }
                      >
                        {showPreview ? (
                          draggedPreview
                        ) : (
                          <Frame
                            editing={
                              selectedComponent &&
                              pageComponent.id === selectedComponent.selectedComponentId
                            }
                            kind={pageComponent.kind}
                            registrationFormId={registrationForm.id}
                            pageComponentId={pageComponent.id}
                            onRemoveComponent={onUnselectComponent}
                          >
                            {content}
                          </Frame>
                        )}
                      </DraggablePageComponent>
                    </div>
                  );
                })}
                <div>
                  {draggedComponentOrder !== pageComponents.length - 1 && (
                    <DroppableArea
                      order={pageComponents.length}
                      parentId={null}
                      visible={draggedComponentKind || hoveredPageComponent}
                    />
                  )}
                </div>
              </Content>
            </Container>
            {browserIeOrSafari != null && browserIeOrSafari !== false && (
              <ScrollDown
                onDragEnter={this.handleScrollDownHover}
                onDragLeave={this.handleScrollTriggerHoverOut}
              />
            )}
          </Wrapper>
        )}
      </UIContext.Consumer>
    );
  }
}

export default createFragmentContainer(RegistrationPageContent, {
  registrationForm: graphql`
    fragment RegistrationPageContent_registrationForm on RegistrationForm {
      id
      backgroundUrl
      backgroundColor
      width
      widthMeasurement
      pageComponents {
        edges {
          node {
            id
            order
            kind
            parent {
              id
            }
            formComponent {
              id
            }
            childPageComponents {
              edges {
                node {
                  id
                  columnChild {
                    id
                  }
                }
              }
            }
          }
        }
      }
      ...EventNameComponent_registrationForm
      ...EventDatesComponent_registrationForm
      ...RowComponent_registrationForm
    }
  `,
  org: graphql`
    fragment RegistrationPageContent_org on Org {
      ...FormComponent_org
      ...ColumnComponent_org
      syncedToIbmWm
    }
  `,
});
