/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventAttendanceJoinTime_contactEvent$ref: FragmentReference;
export type EventAttendanceJoinTime_contactEvent = {|
  +zoomRegistrant: ?{|
    +times: $ReadOnlyArray<{|
      +startTime: any,
      +endTime: any,
    |}>
  |},
  +g2wRegistrant: ?{|
    +times: $ReadOnlyArray<{|
      +startTime: any,
      +endTime: any,
    |}>
  |},
  +node: {|
    +tz: string
  |},
  +$refType: EventAttendanceJoinTime_contactEvent$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "times",
    "storageKey": null,
    "args": null,
    "concreteType": "TimeRangeType",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "startTime",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endTime",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "EventAttendanceJoinTime_contactEvent",
  "type": "ContactEventEdge",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "zoomRegistrant",
      "storageKey": null,
      "args": null,
      "concreteType": "ZoomRegistrant",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "g2wRegistrant",
      "storageKey": null,
      "args": null,
      "concreteType": "G2wRegistrant",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tz",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f8bd0c8cfad8be2377f2bffa9ae3241';
module.exports = node;
