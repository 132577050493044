/* @flow */
import styled from 'styled-components';

const WindowSubtitle = styled.div`
  flex: 1 1 auto;
  padding: 0 8px;
  color: ${props => props.theme.windowSubtitleColor};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default WindowSubtitle;
