/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { ContactMarketoId_contact } from './__generated__/ContactMarketoId_contact.graphql';

class ContactMarketoId extends React.PureComponent<{
  contact: ContactMarketoId_contact,
  marketoHost: ?string,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  getMarketoId = () => {
    const contact = this.props.contact;
    return contact && contact.marketoId && contact.syncStatus.state !== 'PENDING'
      ? contact.marketoId
      : null;
  };

  render() {
    const marketoId = this.getMarketoId();
    if (marketoId == null || this.props.marketoHost == null) {
      return null;
    }
    return (
      <a
        onMouseDown={this.handleMouseDown}
        href={`https://${this.props.marketoHost}/leadDatabase/loadLeadDetail?leadId=${marketoId}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {marketoId}
      </a>
    );
  }
}

export default createFragmentContainer(
  ContactMarketoId,
  graphql`
    fragment ContactMarketoId_contact on Contact {
      marketoId
      syncStatus {
        state
      }
    }
  `,
);
