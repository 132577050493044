/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #faf2cc;
  background-color: #fcf8e3;
  color: #8a6d3b;
`;

const Title = styled.div`
  font-weight: 500;
`;

const WarningSection = styled.div`
  margin-top: 3px;
`;

export default class TransferEventPreviewWarning extends React.Component<{}> {
  render() {
    return (
      <Container>
        <Title>Notice</Title>
        <WarningSection>
          Any Expenses split across two or more Teams will stay split as-is.
          <br />
          This browser window will refresh after a successful transfer.
        </WarningSection>
      </Container>
    );
  }
}
