/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineEditableCell from 'components/budget/Table/InlineEditableCell';
import Avatar from 'components/material/Avatar';
import TextField from 'components/material/TextField';

import type { ContactName_contact } from './__generated__/ContactName_contact.graphql';
import type { ContactName_event } from './__generated__/ContactName_event.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 150px;
  margin: -6px -18px -6px -10px;
`;

const ContactNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  position: relative;
  flex: 0 0 26px;
  margin-right: 5px;
`;

const StyledLink = styled(Link)`
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `};
`;

const EditableContent = styled.div`
  display: flex;
  align-items: center;
  label {
    input {
      padding: 0 8px;
    }
  }
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

class ContactName extends React.PureComponent<
  {
    contact: ContactName_contact,
    event?: ContactName_event,
    onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
    updateColumnWidth: () => void,
  },
  {
    firstName: string,
    lastName: string,
    error: ?string,
    updating: boolean,
  },
> {
  state = {
    firstName: this.props.contact.firstName,
    lastName: this.props.contact.lastName,
    error: null,
    updating: false,
  };

  focusedElement: ?EventTarget = null;

  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleSave = () => {
    if (this.getError()) {
      return;
    }
    if (
      this.state.firstName !== this.props.contact.firstName ||
      this.state.lastName !== this.props.contact.lastName
    ) {
      this.setState({ updating: true });
      this.props
        .onUpdate({ firstName: this.state.firstName, lastName: this.state.lastName })
        .finally(() => {
          this.setState({ updating: false });
        });
      this.props.updateColumnWidth();
    }
  };

  handleCancel = () => {
    this.setState(state =>
      state.firstName !== this.props.contact.firstName ||
      state.lastName !== this.props.contact.lastName
        ? {
            firstName: this.props.contact.firstName,
            lastName: this.props.contact.lastName,
            error: null,
          }
        : null,
    );
  };

  handleChangeFirstName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ firstName: e.currentTarget.value, error: null });
  };

  handleChangeLastName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ lastName: e.currentTarget.value, error: null });
  };

  contactPath = () => {
    const { event, contact } = this.props;
    if (event == null) {
      return `/contacts/people/${contact.slug}`;
    }
    return `/events/${event.slug}/contacts/people/${this.props.contact.slug}`;
  };

  getError = (): ?string => {
    const { firstName, lastName } = this.state;

    if (!firstName.trim() && !lastName.trim()) {
      return 'First and Last Names are required';
    }
    if (!firstName.trim()) {
      return 'First Name is required';
    }
    if (!lastName.trim()) {
      return 'Last Name is required';
    }
    return null;
  };

  handleValidate = () => {
    const updateColumnWidth = this.props.updateColumnWidth;
    const error: ?string = this.getError();
    this.setState({ error });
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  render() {
    const { contact } = this.props;

    return (
      <Container>
        <InlineEditableCell
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          error={this.state.error}
          onValidate={this.handleValidate}
          doubleField
        >
          {({ onBlur, onKeyDown, editing }) => {
            if (!editing) {
              return (
                <ContactNameWrapper>
                  <StyledAvatar profile={contact} />
                  <StyledLink
                    to={this.contactPath()}
                    onMouseDown={this.handleMouseDown}
                    disabled={this.state.updating}
                  >
                    <TextWrapper>{fullNameOfUser(contact)}</TextWrapper>
                  </StyledLink>
                </ContactNameWrapper>
              );
            }
            return (
              <EditableContent
                tabIndex={0}
                onMouseDown={(event: SyntheticMouseEvent<HTMLDivElement>) => {
                  if (document.activeElement !== event.target && !this.state.error) {
                    this.focusedElement = event.target;
                  }
                }}
                onBlur={(event: SyntheticEvent<HTMLInputElement>) => {
                  // This solution is based on https://stackoverflow.com/questions/12092261/prevent-firing-the-blur-event-if-any-one-of-its-children-receives-focus
                  const error = !this.handleValidate();

                  if (
                    (this.focusedElement instanceof HTMLInputElement &&
                      event.currentTarget.contains(this.focusedElement)) ||
                    error
                  ) {
                    this.focusedElement = null;
                    return;
                  }

                  if (onBlur && !error) {
                    onBlur();
                  }
                }}
              >
                <TextField
                  autoFocus
                  placeholder="First Name"
                  onKeyDown={onKeyDown}
                  onChange={this.handleChangeFirstName}
                  value={this.state.firstName}
                />
                /
                <TextField
                  placeholder="Last Name"
                  onKeyDown={onKeyDown}
                  onChange={this.handleChangeLastName}
                  value={this.state.lastName}
                />
              </EditableContent>
            );
          }}
        </InlineEditableCell>
      </Container>
    );
  }
}

export default createFragmentContainer(ContactName, {
  contact: graphql`
    fragment ContactName_contact on Contact {
      slug
      email
      firstName
      lastName
      avatar
    }
  `,
  event: graphql`
    fragment ContactName_event on Event {
      slug
    }
  `,
});
