/**
 * @flow
 * @relayHash b121748717c4c91a3b496fe747709463
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateBudgetCategoryInput = {
  clientMutationId?: ?string,
  categoryId: string,
  name: string,
  description?: ?string,
};
export type updateBudgetCategoryMutationVariables = {|
  input: UpdateBudgetCategoryInput
|};
export type updateBudgetCategoryMutationResponse = {|
  +updateBudgetCategory: ?{|
    +budget_category: ?{|
      +name: string,
      +description: ?string,
    |}
  |}
|};
export type updateBudgetCategoryMutation = {|
  variables: updateBudgetCategoryMutationVariables,
  response: updateBudgetCategoryMutationResponse,
|};
*/


/*
mutation updateBudgetCategoryMutation(
  $input: UpdateBudgetCategoryInput!
) {
  updateBudgetCategory(input: $input) {
    budget_category {
      name
      description
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateBudgetCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateBudgetCategoryInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateBudgetCategoryMutation",
  "id": null,
  "text": "mutation updateBudgetCategoryMutation(\n  $input: UpdateBudgetCategoryInput!\n) {\n  updateBudgetCategory(input: $input) {\n    budget_category {\n      name\n      description\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateBudgetCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBudgetCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateBudgetCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budget_category",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetCategory",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateBudgetCategoryMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBudgetCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateBudgetCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budget_category",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetCategory",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ecad7e13c069ae1f018bdbf2022e81f6';
module.exports = node;
