import PropTypes from 'prop-types';
import React from 'react';
import ClickOutside from 'react-onclickout';

export default class GeneralErrorMessage extends React.Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    index: PropTypes.number.isRequired,
    onDismiss: PropTypes.func.isRequired,
  }
  componentDidMount() {
    setTimeout(() => {
      if (!this.unmounted && this.container) {
        this.container.classList.add('shown');
      }
    });
  }
  componentWillUnmount() {
    this.unmounted = true;
  }
  handleDismiss = () => {
    if (this.container) {
      this.container.classList.remove('shown');
    }
    this.props.onDismiss(this.props.index);
  }
  render() {
    return (
      <ClickOutside onClickOut={this.handleDismiss}>
        <div className="general-error-message" ref={(ref) => { this.container = ref; }}>
          <i className="fa fa-close" onClick={this.handleDismiss} />
          <span className="general-error-message-title">
            {this.props.title}
          </span>
          <span className="general-error-message-body">
            {this.props.text}
          </span>
        </div>
      </ClickOutside>
    );
  }
}
