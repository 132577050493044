/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';

import { type Event } from 'graph/mutations/event/updateEvent';

import InlineDateField from 'components/budget/Table/InlineDateField';
import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { EventStartDate_event } from './__generated__/EventStartDate_event.graphql';

const Date = styled.div`
  ${props =>
    props.isOverdue &&
    css`
      color: ${props.theme.negativeActionColor};
    `};
`;

class EventStartDate extends React.PureComponent<{
  event: EventStartDate_event,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdate?: (changedProps: $Shape<Event>) => Promise<void>,
}> {
  handleSave = ({ date, hideTime }) => {
    const { onUpdate, updateColumnWidth } = this.props;
    if (onUpdate) {
      if (date == null) {
        onUpdate({
          startDate: date,
          startDateAllDay: hideTime,
          endDate: null,
          endDateAllDay: hideTime,
        });
      } else {
        onUpdate({ startDate: date, startDateAllDay: hideTime });
      }
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    if (!value) {
      return null;
    }
    const {
      event: { endDate },
    } = this.props;
    const isValid = !endDate || moment(endDate).isAfter(value);
    if (!isValid) {
      return 'Should be before end date';
    }
    return null;
  };

  renderDate() {
    const {
      event: { startDate, startDateAllDay, tz },
    } = this.props;
    const hideTime = !!startDateAllDay;

    return hideTime ? (
      <Date>{formatDate(moment(startDate).tz(tz))}</Date>
    ) : (
      <DateTimeTooltip date={moment.tz(startDate, tz)} />
    );
  }

  render() {
    const {
      updateColumnWidth,
      event: { startDate, startDateAllDay, tz },
      readOnly,
    } = this.props;
    const date = startDate && moment.tz(startDate, tz);

    if (readOnly) {
      return date ? this.renderDate() : null;
    }

    return (
      <InlineDateField
        date={startDate}
        tz={tz}
        placeholder="Add Start Date"
        hideTime={!!startDateAllDay}
        getError={this.getError}
        onChange={this.handleSave}
        updateColumnWidth={updateColumnWidth}
      />
    );
  }
}

export default createFragmentContainer(
  EventStartDate,
  graphql`
    fragment EventStartDate_event on Event {
      startDate
      startDateAllDay
      endDate
      tz
    }
  `,
);
