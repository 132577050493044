/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type {
  createContactFormBccEmailMutationVariables,
  createContactFormBccEmailMutationResponse,
} from './__generated__/createContactFormBccEmailMutation.graphql';

const mutation = graphql`
  mutation createContactFormBccEmailMutation($input: CreateContactFormBccEmailInput!) {
    createContactFormBccEmail(input: $input) {
      contactFormBccEmailsEdge {
        node {
          id
          email
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function createContactFormBccEmail(
  input: $PropertyType<createContactFormBccEmailMutationVariables, 'input'>,
): Promise<createContactFormBccEmailMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    updater: addRecordToConnection({
      parentId: input.formId,
      edgeName: 'contactFormBccEmailsEdge',
      connections: [{ field: 'contactFormBccEmails' }],
    }),
  });
}
