/* @flow */
import { graphql } from 'react-relay';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';
import type {
  removeBudgetPeriodsMutationVariables,
  removeBudgetPeriodsMutationResponse,
} from './__generated__/removeBudgetPeriodsMutation.graphql';

const mutation = graphql`
  mutation removeBudgetPeriodsMutation($input: RemoveBudgetPeriodsInput!) {
    removeBudgetPeriods(input: $input) {
      removedPeriodIds
    }
  }
`;

export default function RemoveBudgetPeriods({
  orgId,
  fiscalYear,
}: {
  orgId: string,
  fiscalYear: number,
}): Promise<removeBudgetPeriodsMutationResponse> {
  const variables: removeBudgetPeriodsMutationVariables = {
    input: {
      fiscalYear,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedPeriodIds',
        parentId: orgId,
        connections: [{ field: 'budgetPeriods' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedPeriodIds' }),
    ),
  });
}
