/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Remove as RemoveIcon } from 'images';
import WarningIconCircle from 'images/warningIconCircle.svg';

const Container = styled.div``;

const Tag = styled.span`
  position: relative;
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 8px 25px 8px 8px;
  font-size: 14px;
  line-height: 11px;
  border-radius: 4px;
  background-color: ${props => (props.highlighted ? props.theme.mutedTextColor : '#f5f5f5')};
  color: ${props => props.theme.rowPrimaryTextColor};
  ${props =>
    props.hasError &&
    css`
      padding: 6px 25px 6px 8px;
      border: 1px solid ${props.theme.negativeActionColor};
    `}
`;

const RemoveAction = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  cursor: pointer;
  color: #868f96;

  &:hover {
    color: #4db1dd;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const TagEditInput = styled.input`
  width: ${props => (props.inputWidth ? props.inputWidth : 'unset')};
  min-width: 80px;
  margin: 0 4px 4px 0;
  padding: 4px 4px 4px 8px;
  font-size: 14px;
  line-height: 17px;
  border-radius: 4px;
  outline: none;
  border: 1px dashed #6e7884;
  background-color: #f5f5f5;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const StyledWarningIconCircle = styled(WarningIconCircle)`
  margin-right: 5px;
  color: transparent;
`;

const HiddenSpan = styled.span`
  position: absolute;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  white-space: pre;
`;

export default class TagListItem extends React.PureComponent<
  {
    onUpdate: (tag: string, index: number) => void,
    onRemove: (index: number) => void,
    handleInputUnFocus: () => void,
    handleInputFocus: () => void,
    highlighted: boolean,
    error: boolean,
    value: string,
    index: number,
  },
  {
    value: string,
    inputWidth: string,
    editing: boolean,
  },
> {
  state = {
    value: this.props.value,
    inputWidth: '',
    editing: false,
  };

  hiddenSpanRef = React.createRef();

  static getDerivedStateFromProps(
    props: $PropertyType<TagListItem, 'props'>,
    state: $PropertyType<TagListItem, 'state'>,
  ) {
    if (props.value !== state.value && !state.editing) {
      return { value: props.value };
    }

    return null;
  }

  containerClick = (event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  handleRemove = (event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onRemove(this.props.index);
  };

  handleEdit = () => {
    if (!this.hiddenSpanRef || !this.hiddenSpanRef.current) {
      return;
    }
    this.hiddenSpanRef.current.textContent = this.props.value;
    this.setState({
      inputWidth: `${this.hiddenSpanRef.current.offsetWidth + 20}px`,
      editing: true,
    });
  };

  onKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    // keyCode 46 -> Enter, keyCode 8 -> Space, keyCode 188 -> Comma,
    if (event.keyCode === 13 || event.keyCode === 32 || event.keyCode === 188) {
      event.preventDefault();
      const value = event.currentTarget.value.trim();

      if (value) {
        this.props.onUpdate(value, this.props.index);
      } else {
        this.props.onRemove(this.props.index);
      }

      this.setState({ editing: false, inputWidth: '' });
    }
  };

  handleInputBlur = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.trim();
    if (value) {
      this.props.onUpdate(value, this.props.index);
    } else {
      this.props.onRemove(this.props.index);
    }
    this.setState({ editing: false, inputWidth: '' });
    this.props.handleInputUnFocus();
  };

  handleInputChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    if (!this.hiddenSpanRef || !this.hiddenSpanRef.current) {
      return;
    }
    this.hiddenSpanRef.current.textContent = event.target.value;

    this.setState({
      inputWidth: `${this.hiddenSpanRef.current.offsetWidth + 20}px`,
      value: event.target.value,
    });
  };

  render() {
    return (
      <Container onClick={this.containerClick}>
        {this.state.editing ? (
          <TagEditInput
            onChange={this.handleInputChange}
            onBlur={this.handleInputBlur}
            onKeyDown={this.onKeyDown}
            onFocus={this.props.handleInputFocus}
            value={this.state.value}
            autoFocus
            inputWidth={this.state.inputWidth}
          />
        ) : (
          <Tag
            highlighted={this.props.highlighted}
            hasError={this.props.error}
            onClick={this.handleEdit}
          >
            {this.props.error && <StyledWarningIconCircle />}
            {this.props.value}
            <RemoveAction onClick={this.handleRemove}>
              <RemoveIcon />
            </RemoveAction>
          </Tag>
        )}
        {/* The 'HiddenSpan' is used to measure the width of the 'TagEditInput'. */}
        <HiddenSpan ref={this.hiddenSpanRef} />
      </Container>
    );
  }
}
