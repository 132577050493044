/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import type { ImportLogsSuccessCountColumn_importLog } from './__generated__/ImportLogsSuccessCountColumn_importLog.graphql';

type Props = {
  importLog: ImportLogsSuccessCountColumn_importLog,
};

const ImportLogsSuccessCountColumn = ({ importLog }: Props) => importLog.successCount;

export default createFragmentContainer(
  ImportLogsSuccessCountColumn,
  graphql`
    fragment ImportLogsSuccessCountColumn_importLog on ImportLog {
      successCount
    }
  `,
);
