/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateBriefTemplateEventInfoSectionMutationResponse,
  UpdateBriefTemplateEventInfoSectionInput,
} from './__generated__/updateBriefTemplateEventInfoSectionMutation.graphql';

const mutation = graphql`
  mutation updateBriefTemplateEventInfoSectionMutation(
    $input: UpdateBriefTemplateEventInfoSectionInput!
  ) {
    updateBriefTemplateEventInfoSection(input: $input) {
      eventInfoSection {
        id
        enabled
      }
    }
  }
`;

export default function updateBriefTemplateEventInfoSection(
  eventInfoSection: UpdateBriefTemplateEventInfoSectionInput,
): Promise<updateBriefTemplateEventInfoSectionMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input: eventInfoSection },
    optimisticResponse: {
      updateBriefTemplateEventInfoSection: {
        eventInfoSection,
      },
    },
  });
}
