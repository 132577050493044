/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type SectionCustomizableType } from 'components/FieldSettings/types';

import type {
  reorderCustomFieldSectionsMutationResponse,
  reorderCustomFieldSectionsMutationVariables,
} from './__generated__/reorderCustomFieldSectionsMutation.graphql';

const mutation = graphql`
  mutation reorderCustomFieldSectionsMutation($input: ReorderCustomFieldSectionsInput!) {
    reorderCustomFieldSections(input: $input) {
      updatedSections {
        id
        order
      }
    }
  }
`;

export default function reorderCustomFieldSections(
  customizableType: SectionCustomizableType,
  sectionsOrder: $ReadOnlyArray<{
    id: string,
    order: number,
  }>,
): Promise<reorderCustomFieldSectionsMutationResponse> {
  const variables: reorderCustomFieldSectionsMutationVariables = {
    input: { customizableType, sectionsOrder },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      reorderCustomFieldSections: {
        updatedSections: sectionsOrder,
      },
    },
  });
}
