/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type updateContactMutationVariables } from './__generated__/updateContactMutation.graphql';

const mutation = graphql`
  mutation updateContactMutation($input: UpdateContactInput!) {
    updateContact(input: $input) {
      contact {
        id
        slug
        firstName
        lastName
        salesforceSyncAs
        avatar
        website
        email
        phone1
        phone2
        title
        salesforceId
        hotLead
        emailOptIn
        contactType {
          id
        }
        company {
          id
          name
          salesforceId
        }
        owner {
          id
          firstName
          lastName
          email
          ...MaterialAvatar_user
        }
        twitter
        linkedin
        description
        country
        state
        city
        zip
        street
        contactType {
          id
        }
        syncStatus {
          lastSuccessAt
          state
          errorMessage
        }
      }
    }
  }
`;

export type ContactFieldInput = $Exact<$PropertyType<updateContactMutationVariables, 'input'>>;

export default function updateContact(input: ContactFieldInput) {
  const { contactId, eventId, fromWindow, ...rest } = input;
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    optimisticResponse: {
      updateContact: {
        contact: { id: contactId, ...rest },
      },
    },
  });
}
