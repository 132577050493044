/**
 * @flow
 * @relayHash c180ec5d48267ca17d0f4752f5db59cb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
export type CreateEventNoteInput = {
  clientMutationId?: ?string,
  eventId: string,
  name: string,
  content?: ?string,
};
export type createEventNoteMutationVariables = {|
  input: CreateEventNoteInput
|};
export type createEventNoteMutationResponse = {|
  +createEventNote: ?{|
    +eventNotesEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +dbId: number,
        +name: string,
        +content: string,
        +updatedAt: any,
        +createdAt: any,
        +creator: ?{|
          +firstName: string,
          +lastName: string,
          +email: string,
        |},
        +updater: ?{|
          +firstName: string,
          +lastName: string,
          +email: string,
          +$fragmentRefs: MaterialAvatar_user$ref,
        |},
        +attachments: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +fileurl: ?string,
              +filename: ?string,
              +filetype: ?string,
              +filesize: ?number,
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type createEventNoteMutation = {|
  variables: createEventNoteMutationVariables,
  response: createEventNoteMutationResponse,
|};
*/


/*
mutation createEventNoteMutation(
  $input: CreateEventNoteInput!
) {
  createEventNote(input: $input) {
    eventNotesEdge {
      __typename
      node {
        id
        dbId
        name
        content
        updatedAt
        createdAt
        creator {
          firstName
          lastName
          email
          id
        }
        updater {
          firstName
          lastName
          email
          ...MaterialAvatar_user
          id
        }
        attachments {
          edges {
            node {
              id
              fileurl
              filename
              filetype
              filesize
            }
          }
        }
      }
    }
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateEventNoteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateEventNoteInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attachments",
  "storageKey": null,
  "args": null,
  "concreteType": "AttachmentRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AttachmentRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Attachment",
          "plural": false,
          "selections": [
            v3,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "fileurl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filename",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filetype",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filesize",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createEventNoteMutation",
  "id": null,
  "text": "mutation createEventNoteMutation(\n  $input: CreateEventNoteInput!\n) {\n  createEventNote(input: $input) {\n    eventNotesEdge {\n      __typename\n      node {\n        id\n        dbId\n        name\n        content\n        updatedAt\n        createdAt\n        creator {\n          firstName\n          lastName\n          email\n          id\n        }\n        updater {\n          firstName\n          lastName\n          email\n          ...MaterialAvatar_user\n          id\n        }\n        attachments {\n          edges {\n            node {\n              id\n              fileurl\n              filename\n              filetype\n              filesize\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createEventNoteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createEventNote",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateEventNotePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventNotesEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "EventNoteRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "EventNote",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "creator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v9,
                      v10,
                      v11
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updater",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "FragmentSpread",
                        "name": "MaterialAvatar_user",
                        "args": null
                      }
                    ]
                  },
                  v12
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createEventNoteMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createEventNote",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateEventNotePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventNotesEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "EventNoteRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "EventNote",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "creator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v9,
                      v10,
                      v11,
                      v3
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updater",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v9,
                      v10,
                      v11,
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  v12
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca012d97635d366a625bd6576bb910ca';
module.exports = node;
