/**
 * @flow
 * @relayHash 7cdd59abf7f88df9d1146b207c8ef26e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveContactFromBriefInput = {
  clientMutationId?: ?string,
  eventId: string,
  contactId: string,
};
export type removeContactFromBriefMutationVariables = {|
  input: RemoveContactFromBriefInput
|};
export type removeContactFromBriefMutationResponse = {|
  +removeContactFromBrief: ?{|
    +removedId: string
  |}
|};
export type removeContactFromBriefMutation = {|
  variables: removeContactFromBriefMutationVariables,
  response: removeContactFromBriefMutationResponse,
|};
*/


/*
mutation removeContactFromBriefMutation(
  $input: RemoveContactFromBriefInput!
) {
  removeContactFromBrief(input: $input) {
    removedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveContactFromBriefInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeContactFromBrief",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveContactFromBriefInput!"
      }
    ],
    "concreteType": "RemoveContactFromBriefPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeContactFromBriefMutation",
  "id": null,
  "text": "mutation removeContactFromBriefMutation(\n  $input: RemoveContactFromBriefInput!\n) {\n  removeContactFromBrief(input: $input) {\n    removedId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeContactFromBriefMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeContactFromBriefMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b39d81ca9170d210e6d9c568bb4ea30c';
module.exports = node;
