/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BudgetReportingGroupContainer_org$ref = any;
export type BudgetReportingGroup = "BUDGET_CATEGORY" | "EVENT" | "EVENT_CREATOR" | "EVENT_LEADER" | "EVENT_REQUEST_FORM" | "EVENT_REQUEST_REVIEWER" | "EVENT_REQUEST_SUBMISSION_STATUS" | "EVENT_STATUS" | "LOCATION_COUNTRY" | "LOCATION_STATE" | "TEAM";
export type BudgetReportingSort = "ACTUAL_AMOUNT" | "ACTUAL_PLANNED" | "ACTUAL_TEAM_BUDGET" | "BUDGET_AMOUNT" | "NAME" | "PAID_ACTUAL" | "PAID_AMOUNT" | "PLANNED_AMOUNT";
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type Direction = "ASC" | "DESC";
export type EventFormat = "HYBRID" | "PHYSICAL" | "VIRTUAL";
export type EventRegistrationFormStatus = "DRAFT" | "NOT_AVAILABLE" | "PUBLISHED";
export type EventRequestSubmissionStatusFilter = "APPROVED" | "DECLINED" | "NEW" | "N_A" | "RESUBMITTED" | "RETURNED";
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetReportingPage_org$ref: FragmentReference;
export type BudgetReportingPage_org = {|
  +id: string,
  +settings: {|
    +currency: string,
    +fiscalYearStart: number,
  |},
  +salesforceAccount: ?{|
    +id: string
  |},
  +marketoAccount: ?{|
    +id: string
  |},
  +customFieldSections: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
        +order: number,
      |}
    |}>
  |},
  +customFields: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +label: string,
        +fieldName: ?string,
        +order: number,
        +kind: CustomFieldKindType,
        +required: boolean,
        +restrictChangingValue: boolean,
        +section: ?{|
          +id: string,
          +order: number,
        |},
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +name: string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +budgetReportingViews: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
        +group1: ?BudgetReportingGroup,
        +group2: ?BudgetReportingGroup,
        +customGroup1: ?{|
          +id: string
        |},
        +customGroup2: ?{|
          +id: string
        |},
        +eventIds: ?$ReadOnlyArray<string>,
        +eventName: ?$ReadOnlyArray<string>,
        +eventDate: ?$ReadOnlyArray<any>,
        +eventStatuses: ?$ReadOnlyArray<EventStatus>,
        +syncStatuses: ?$ReadOnlyArray<SyncState>,
        +opportunitiesNumber: ?{|
          +min: ?number,
          +max: ?number,
        |},
        +opportunitiesAmount: ?{|
          +min: ?number,
          +max: ?number,
        |},
        +contactsCount: ?{|
          +min: ?number,
          +max: ?number,
        |},
        +registeredContactsTotal: ?{|
          +min: ?number,
          +max: ?number,
        |},
        +attendedContactsTotal: ?{|
          +min: ?number,
          +max: ?number,
        |},
        +registrationFormStatuses: ?$ReadOnlyArray<EventRegistrationFormStatus>,
        +sort: ?BudgetReportingSort,
        +direction: ?Direction,
        +customSavedNumberFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +minValue: ?number,
              +maxValue: ?number,
              +customField: {|
                +id: string
              |},
            |}
          |}>
        |},
        +customSavedCurrencyFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +minValue: ?number,
              +maxValue: ?number,
              +fieldName: ?string,
              +customField: ?{|
                +id: string
              |},
            |}
          |}>
        |},
        +customSavedDateFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +minValue: ?any,
              +maxValue: ?any,
              +customField: {|
                +id: string
              |},
            |}
          |}>
        |},
        +customSavedBooleanFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +value: boolean,
              +customField: {|
                +id: string
              |},
            |}
          |}>
        |},
        +customSavedUserMultiselectFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +fieldName: ?string,
              +customField: ?{|
                +id: string
              |},
              +options: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +user: {|
                      +id: string
                    |}
                  |}
                |}>
              |},
            |}
          |}>
        |},
        +customSavedMultiselectFilters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +customField: {|
                +id: string
              |},
              +options: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +option: {|
                      +id: string
                    |}
                  |}
                |}>
              |},
            |}
          |}>
        |},
        +teams: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +leads: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +staffers: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +budgetCategories: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +venueNames: ?$ReadOnlyArray<string>,
        +cities: ?$ReadOnlyArray<string>,
        +states: ?$ReadOnlyArray<string>,
        +countries: ?$ReadOnlyArray<string>,
        +eventFormats: ?$ReadOnlyArray<EventFormat>,
        +creators: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +createdAt: ?$ReadOnlyArray<any>,
        +updaters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +updatedAt: ?$ReadOnlyArray<any>,
        +requestedDate: ?$ReadOnlyArray<any>,
        +requestStatuses: ?$ReadOnlyArray<EventRequestSubmissionStatusFilter>,
        +userRequesters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +contactRequesters: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +eventRequestForms: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
        +eventRequestReviewers: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$fragmentRefs: BudgetReportingGroupContainer_org$ref,
  +$refType: BudgetReportingPage_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
],
v2 = [
  "EVENT"
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "min",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "max",
    "args": null,
    "storageKey": null
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "minValue",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "maxValue",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": v1
},
v10 = [
  v0,
  v7,
  v8,
  v9
],
v11 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "UserRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": v1
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "BudgetReportingPage_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "FragmentSpread",
      "name": "BudgetReportingGroupContainer_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currency",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fiscalYearStart",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "marketoAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "MarketoAccount",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFieldSections",
      "storageKey": "customFieldSections(sectionCustomizableType:[\"EVENT\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "sectionCustomizableType",
          "value": v2,
          "type": "[SectionCustomizableType!]!"
        }
      ],
      "concreteType": "CustomFieldSectionTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldSectionTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldSectionType",
              "plural": false,
              "selections": [
                v0,
                v3,
                v4
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFields",
      "storageKey": "customFields(customizableType:[\"EVENT\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": v2,
          "type": "[CustomizableType!]!"
        }
      ],
      "concreteType": "CustomFieldTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldType",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "label",
                  "args": null,
                  "storageKey": null
                },
                v5,
                v4,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "required",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": "restrictChangingValue",
                  "name": "mappedToSalesforce",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "pull",
                      "value": true,
                      "type": "Boolean"
                    }
                  ],
                  "storageKey": "mappedToSalesforce(pull:true)"
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "section",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldSectionType",
                  "plural": false,
                  "selections": [
                    v0,
                    v4
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "options",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldOptionTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomFieldOptionTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomFieldOptionType",
                          "plural": false,
                          "selections": [
                            v0,
                            v3
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetReportingViews",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetReportingViewRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BudgetReportingViewRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BudgetReportingView",
              "plural": false,
              "selections": [
                v0,
                v3,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "group1",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "group2",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customGroup1",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldType",
                  "plural": false,
                  "selections": v1
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customGroup2",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldType",
                  "plural": false,
                  "selections": v1
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "eventIds",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "eventName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "eventDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "eventStatuses",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "syncStatuses",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "opportunitiesNumber",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "NumberRange",
                  "plural": false,
                  "selections": v6
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "opportunitiesAmount",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "NumberRange",
                  "plural": false,
                  "selections": v6
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contactsCount",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "NumberRange",
                  "plural": false,
                  "selections": v6
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "registeredContactsTotal",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "NumberRange",
                  "plural": false,
                  "selections": v6
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "attendedContactsTotal",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "NumberRange",
                  "plural": false,
                  "selections": v6
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "registrationFormStatuses",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "sort",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "direction",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedNumberFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedNumberFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedNumberFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedNumberFilterType",
                          "plural": false,
                          "selections": v10
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedCurrencyFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedCurrencyFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedCurrencyFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedCurrencyFilterType",
                          "plural": false,
                          "selections": [
                            v0,
                            v7,
                            v8,
                            v5,
                            v9
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedDateFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedDateFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedDateFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedDateFilterType",
                          "plural": false,
                          "selections": v10
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedBooleanFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedBooleanFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedBooleanFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedBooleanFilterType",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "value",
                              "args": null,
                              "storageKey": null
                            },
                            v9
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedUserMultiselectFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedUserMultiselectFilterType",
                          "plural": false,
                          "selections": [
                            v0,
                            v5,
                            v9,
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "options",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedUserMultiselectFilterOptionType",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "user",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "User",
                                          "plural": false,
                                          "selections": v1
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customSavedMultiselectFilters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedMultiselectFilterTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedMultiselectFilterTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedMultiselectFilterType",
                          "plural": false,
                          "selections": [
                            v0,
                            v9,
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "options",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedMultiselectFilterOptionType",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "option",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "CustomFieldOptionType",
                                          "plural": false,
                                          "selections": v1
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "teams",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TeamRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "TeamRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Team",
                          "plural": false,
                          "selections": v1
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "leads",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserRequiredConnection",
                  "plural": false,
                  "selections": v11
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "staffers",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserRequiredConnection",
                  "plural": false,
                  "selections": v11
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "budgetCategories",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BudgetCategoryRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "BudgetCategoryRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "BudgetCategory",
                          "plural": false,
                          "selections": v1
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "venueNames",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "cities",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "states",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "countries",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "eventFormats",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "creators",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserRequiredConnection",
                  "plural": false,
                  "selections": v11
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "updaters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserRequiredConnection",
                  "plural": false,
                  "selections": v11
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "requestedDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "requestStatuses",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "userRequesters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserRequiredConnection",
                  "plural": false,
                  "selections": v11
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contactRequesters",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContactRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ContactRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Contact",
                          "plural": false,
                          "selections": v1
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "eventRequestForms",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "EventRequestFormRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "EventRequestFormRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "EventRequestForm",
                          "plural": false,
                          "selections": v1
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "eventRequestReviewers",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserRequiredConnection",
                  "plural": false,
                  "selections": v11
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0fe45a40a66ddaa2b1b426a3dab4d12';
module.exports = node;
