/* @flow */
import React from 'react';
import styled from 'styled-components';

import submissionStatuses, {
  type SubmissionStatus,
} from 'components/EventRequestForm/lib/submissionStatuses';
import Overlay from 'components/material/Overlay';

const Container = styled.div`
  position: relative;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 35px;
  padding: 0 15px 0 20px;
  border-radius: 4px;
  border: 1px solid;
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.color};
  text-align: left;
  outline: 0;
  transition: 0.2s;
  i {
    font-size: 1.2rem;
  }
  &:not(:disabled) {
    cursor: pointer;
    &:hover,
    &:focus {
      padding: 0 14px 0 19px;
      border-width: 2px;
    }
    &:focus {
      box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  }
`;

const StyledOverlay = styled(Overlay)`
  width: 140px;
  margin-top: 5px;
`;

const StatusRow = styled.div`
  padding: 6px 19px;
  color: #3e4859;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    background: #f3fafd;
  }
`;

export default class SubmissionReviewStatusButton extends React.PureComponent<
  {
    disabled: boolean,
    status: SubmissionStatus,
    initialStatus: SubmissionStatus,
    onChangeStatus: (status: SubmissionStatus) => void,
  },
  {
    showOverlay: boolean,
  },
> {
  state = { showOverlay: false };

  button: ?HTMLButtonElement;

  handleShowOverlay = () => {
    this.setState({ showOverlay: true });
  };

  handleHideOverlay = () => {
    this.setState({ showOverlay: false });
  };

  handleChangeStatus = (status: SubmissionStatus) => {
    this.props.onChangeStatus(status);

    this.handleHideOverlay();
  };

  render() {
    const status = submissionStatuses.find(o => o.value === this.props.status);

    if (!status) return null;

    // Only allow to archive declined requests
    const availableActions =
      this.props.initialStatus === 'DECLINED'
        ? submissionStatuses.filter(({ value }) => value === 'ARCHIVED')
        : submissionStatuses.filter(({ auto }) => !auto);

    return (
      <Container>
        <Button
          color={status.color}
          ref={button => {
            this.button = button;
          }}
          onClick={this.handleShowOverlay}
          disabled={this.props.disabled}
        >
          {status.label} {!this.props.disabled && <i className="fa fa-angle-down" />}
        </Button>

        <StyledOverlay
          target={this.button}
          container={this}
          show={this.state.showOverlay}
          onHide={this.handleHideOverlay}
        >
          {availableActions.map(({ value, actionLabel }) => (
            <StatusRow key={value} onClick={() => this.handleChangeStatus(value)}>
              {actionLabel}
            </StatusRow>
          ))}
        </StyledOverlay>
      </Container>
    );
  }
}
