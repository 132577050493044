/* @flow */
import Cookies from 'js-cookie';

import getEnvironment from 'utils/getEnvironment';
import appDomain from 'utils/url/appDomain';

function getCookieLib() {
  const domain = appDomain();
  const cookieDomain = `${domain === '' ? '' : '.'}${domain.replace(/^app\./, '')}`;
  Cookies.defaults = {
    domain: cookieDomain,
    secure: getEnvironment() === 'development' ? undefined : true,
  };
  return Cookies;
}

export function getCookie(name: string): string {
  const lib = getCookieLib();
  return lib.get(name);
}

export function setCookie(
  name: string,
  value: mixed,
  options?: {
    expires?: number,
    path?: string,
    domain?: string,
    secure?: boolean,
    HttpOnly?: boolean,
  },
) {
  const lib = getCookieLib();
  lib.set(name, value, options);
}

export function expireCookie(name: string) {
  const lib = getCookieLib();
  lib.remove(name);
}
