/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import DateTimePicker, { type DateConfig } from 'components/date/DateTimePicker';

import inputBoxStyled from './components/inputBoxStyled';
import Label, { Header } from './FieldComponents';

const StyledDateTimePicker = styled(DateTimePicker)`
  margin: 0;
  label > input {
    ${props => inputBoxStyled(props)};
  }
`;

export default class DateField extends React.PureComponent<{
  tz: string,
  label: string,
  defaultValue: string,
  onChange: (dateConfig: DateConfig) => void,
  disabled?: boolean,
  required?: boolean,
  error?: string,
}> {
  render() {
    const { tz, label, defaultValue, onChange, disabled, required, error } = this.props;
    return (
      <Label required={required} hasError={error}>
        <Header>{label}</Header>
        <StyledDateTimePicker
          tz={tz}
          date={defaultValue}
          hideTime
          onChange={onChange}
          disabled={disabled}
          dateOnly
          required={required}
          error={error}
        />
      </Label>
    );
  }
}
