/* @flow */
import * as React from 'react';
import scriptLoader from 'react-async-script-loader';
import { GOOGLE_MAPS_API_KEY } from 'config/variables';

@scriptLoader([
  `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAPS_API_KEY}`,
])
export default class GoogleMapsScriptWrapper extends React.Component<{
  isScriptLoadSucceed?: boolean,
  isScriptLoaded?: boolean,
  children?: React.Node,
  loadingComponent?: React.Node,
}> {
  render() {
    const loadingComponent =
      this.props.loadingComponent === undefined ? null : this.props.loadingComponent;

    if (this.props.isScriptLoaded && this.props.isScriptLoadSucceed) {
      return this.props.children;
    }
    if (!this.props.isScriptLoaded) {
      return loadingComponent;
    }
    return <span>There was a problem loading this.</span>;
  }
}
