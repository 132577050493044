/* @flow */
import { graphql } from 'react-relay';

import checkImportStatus from 'utils/requests/checkImportStatus';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { revertImportMutationResponse } from './__generated__/revertImportMutation.graphql';

const mutation = graphql`
  mutation revertImportMutation($input: RevertImportInput!) {
    revertImport(input: $input) {
      uuid
    }
  }
`;

export default function revertImport(orgId: string, importLogId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        importLogId,
      },
    },
  }).then((response: revertImportMutationResponse) => {
    if (!response.revertImport) throw Error('Import revert failed');

    return checkImportStatus(response.revertImport.uuid, { isRevert: true });
  });
}
