/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import BriefField from '../../components/BriefField';

import { type EventInfoMarketoId_event } from './__generated__/EventInfoMarketoId_event.graphql';

const Link = styled.a`
  color: ${props => props.color};
`;

const EventInfoMarketoId = (props: {
  event: EventInfoMarketoId_event,
  name: string,
  primaryColor: string,
}) => {
  const {
    name,
    primaryColor,
    event: { marketoProgram },
  } = props;
  if (!marketoProgram) {
    return null;
  }
  const stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const { url, marketoId } = marketoProgram;
  return (
    <BriefField label={name} contentDataAvailable={marketoId}>
      <Link
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        color={primaryColor}
        onClick={stopPropagation}
      >
        {marketoId}
      </Link>
    </BriefField>
  );
};

export default createFragmentContainer(
  EventInfoMarketoId,
  graphql`
    fragment EventInfoMarketoId_event on Event {
      marketoProgram {
        marketoId
        url
      }
    }
  `,
);
