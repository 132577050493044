/* @flow */
import * as React from 'react';
import ClickOut from 'react-onclickout';
import styled, { css } from 'styled-components';

import escapeRegexString from 'utils/escapeRegexString';

import Overlay from 'components/material/Overlay';
import { Label } from 'components/material/SelectField';
import Tooltip from 'components/material/Tooltip';
import Search from 'components/Search';

import AddNewRow from './AddNewRow';
import RemovablePill from './RemovablePill';
import SelectFieldRow from './SelectFieldRow';

const StyledTooltip = styled(Tooltip)`
  display: inline-block;
  padding: 3px 8px 3px 6px;
  margin-right: 7px;
  background-color: #f5f5f5;
  color: #4a5665;
  line-height: 14px;
`;

const OverlayScroll = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const Container = styled.div`
  position: relative;
  ${props =>
    props.isStretched &&
    css`
      width: 100%;
    `};
  ${props =>
    props.withLabel &&
    css`
      margin: 8px 0;
    `};
`;

const Empty = styled.div`
  margin: 10px;
  font-size: 13px;
  font-style: italic;
  color: ${props => props.theme.rowSecondaryTextColor};
`;

export const Field = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  outline: none;

  ${props =>
    props.withLabel &&
    css`
      padding: 7px 0 3px 0;
    `};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border-bottom: 1px solid ${props => props.theme.borderColor};
  ${props =>
    props.noBorder &&
    css`
      border-bottom: none !important;
    `};
  ${props =>
    props.disabled &&
    css`
      border-style: dashed;
    `};
  ${props =>
    props.focused &&
    css`
      border-color: ${props.theme.primaryActionColor};
    `};
  &:hover {
    ${props =>
      !props.focused &&
      !props.disabled &&
      css`
        border-color: ${props.theme.secondaryActionDarkerColor};
      `};
  }
  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.negativeActionColor};
      &:hover:not(:focus) {
        border-color: 0 1px 0 ${props.theme.negativeActionColor};
      }
    `};
  transition: border-color 0.3s;
`;

export const Placeholder = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  width: 0;
  height: 25px;
  line-height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => (props.error ? props.theme.negativeActionColor : props.theme.labelColor)};
`;

const SelectionsList = styled.div`
  flex: 1 1 auto;

  ${props =>
    props.noWrap &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

const StyledOverlay = styled(Overlay)`
  display: flex;
  width: auto;
  min-width: 100%;
  height: auto;
  max-height: 250px;
  overflow-y: auto;
  margin-top: 3px;
`;

const Icon = styled.i`
  color: ${props => (props.error ? props.theme.negativeActionColor : 'rgba(55,64,76,0.3)')};
  font-size: 16px;
`;

const Error = styled.div`
  font-size: 11px;
  margin-top: 1px;
  color: ${props => props.theme.negativeActionColor};
`;

const OverlayFocus = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  outline: none;
`;

const SearchContainer = styled.div`
  padding: 10px;
  line-height: 22.4px;
`;

const StyledSearch = styled(Search)`
  max-width: 100%;
  font-size: 14px;
`;

type SelectItem<V> = {
  label: string,
  value: V,
};

export default class MultiSelectField<V> extends React.PureComponent<
  {
    className?: string,
    options: $ReadOnlyArray<SelectItem<V>>,
    onSelect: (value: V) => void,
    onUnselect: (value: V) => void,
    label?: string,
    placeholder?: string,
    values?: ?$ReadOnlyArray<V>,
    onHideOptions?: () => void,
    disabled?: boolean,
    required?: boolean,
    noBorder?: boolean,
    error?: ?string,
    extensible?: boolean,
    addActionLabel?: string,
    addActionPlaceholder?: string,
    onCreate?: (value: string) => void,
    maxShowableItems?: number,
    overlayContainer?: ?HTMLElement,
    autoFocus?: boolean,
    onClickAddNew?: () => void,
    isStretched?: boolean,
    searchable?: boolean,
  },
  {
    overlayShown: boolean,
    query: string,
  },
> {
  containerRef = React.createRef();

  fieldRef = React.createRef();

  overlayRef = React.createRef();

  static defaultProps = {
    maxShowableItems: Infinity,
  };

  state = {
    overlayShown: false,
    query: '',
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.setState({ overlayShown: true });
      this.focusField();
    }
  }

  // this function is used to focus div element (with tabIndex), which
  // fires blur event on other select type fields
  focusField = () => {
    if (this.fieldRef.current) {
      this.fieldRef.current.focus();
    }
  };

  handleOverlayShow = () => {
    if (!this.props.disabled) {
      this.setState({ overlayShown: true });
      this.focusField();
    }
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false, query: '' });

    if (this.props.onHideOptions) {
      this.props.onHideOptions();
    }
  };

  handleCreate = (value: string) => {
    if (this.props.onCreate != null) {
      this.props.onCreate(value);
    }
    this.handleOverlayHide();
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const container = this.containerRef.current;
    const overlay = this.overlayRef.current;
    const relatedTarget: ?Node = (e: any).relatedTarget || document.activeElement;
    if (
      container &&
      relatedTarget &&
      overlay &&
      (container.contains(relatedTarget) || overlay.contains(relatedTarget))
    ) {
      return;
    }
    this.handleOverlayHide();
  };

  handleSearchChange = (search: string) => {
    const { query } = this.state;

    if (query !== search) {
      this.setState({
        query: search,
      });
    }
  };

  // This should be an arrow function instead.
  // But because of a flow bug with generics we have to declare it as an old style function
  // and apply bind on it to keep the correct scope whenever using
  toggleOptionSelect(value: V) {
    if (this.props.values != null && this.props.values.includes(value)) {
      this.props.onUnselect(value);
    } else {
      this.props.onSelect(value);
    }
  }

  options(): $ReadOnlyArray<SelectItem<V>> {
    const query = this.state.query ? this.state.query.replace(/\s/g, '') : '';

    if (!query || !this.props.searchable) {
      return this.props.options;
    }

    const queryRegexp = new RegExp(escapeRegexString(query), 'i');
    return this.props.options.filter(option => {
      return queryRegexp.test(option.label.replace(/\s/g, ''));
    });
  }

  renderSelectedOptions() {
    const values = this.props.values || [];
    const selectedOptions = values
      .map(val => this.props.options.find(option => option.value === val))
      .filter(Boolean);
    const showableCount =
      this.props.maxShowableItems === Infinity
        ? selectedOptions.length
        : this.props.maxShowableItems;
    const shownOptions = selectedOptions.slice(0, showableCount);
    const hiddenOptions = selectedOptions.slice(showableCount, selectedOptions.length);

    const label = hiddenOptions.map((option, index) => (
      <div key={typeof option.value === 'string' ? option.value : index}>{option.label}</div>
    ));
    /* eslint-disable react/jsx-no-bind */
    const onSelect = this.toggleOptionSelect.bind(this);
    return (
      <SelectionsList noWrap={this.props.maxShowableItems !== Infinity}>
        {shownOptions.map((option, index) => (
          <RemovablePill
            value={option.value}
            key={typeof option.value === 'string' ? option.value : index}
            onRemove={onSelect}
            disabled={this.props.disabled}
          >
            {option.label}
          </RemovablePill>
        ))}
        {this.props.maxShowableItems !== Infinity && hiddenOptions.length > 0 ? (
          <StyledTooltip placement="top" label={label}>
            +{hiddenOptions.length}
          </StyledTooltip>
        ) : (
          ''
        )}
      </SelectionsList>
    );
  }

  render() {
    const selectedValues = this.props.values || [];
    const hasSelectedOptions = selectedValues.length > 0;
    /* eslint-disable react/jsx-no-bind */
    const onSelect = this.toggleOptionSelect.bind(this);
    const options = this.options();
    return (
      <ClickOut onBlur={this.handleBlur}>
        <Container
          className={this.props.className}
          withLabel={this.props.label}
          isStretched={this.props.isStretched}
          ref={this.containerRef}
        >
          <Field
            ref={this.fieldRef}
            onClick={this.handleOverlayShow}
            focused={this.state.overlayShown}
            disabled={this.props.disabled}
            noBorder={this.props.noBorder}
            error={this.props.error}
            withLabel={this.props.label}
            tabIndex={-1}
          >
            {hasSelectedOptions ? (
              this.renderSelectedOptions()
            ) : (
              <Placeholder error={this.props.error}>{this.props.placeholder}</Placeholder>
            )}
            {!this.props.disabled && (
              <Icon className="fa fa-fw fa-angle-down" error={this.props.error} />
            )}
          </Field>
          <Label
            error={this.props.error}
            focused={this.state.overlayShown}
            data-collapsed={
              this.state.overlayShown ||
              (this.props.values && this.props.values.length > 0) ||
              this.props.placeholder ||
              undefined
            }
          >
            {this.props.label}
            {this.props.required && '*'}
          </Label>
          {this.props.options.length > 0 && (
            <StyledOverlay
              show={this.state.overlayShown}
              onHide={this.handleOverlayHide}
              container={this.props.overlayContainer || this.containerRef.current}
              overlayRef={this.overlayRef}
              target={this.fieldRef.current}
            >
              <OverlayFocus tabIndex={-1}>
                {this.props.searchable && (
                  <SearchContainer>
                    <StyledSearch
                      onSearch={this.handleSearchChange}
                      search={this.state.query}
                      placeholder="Search..."
                      autoFocus
                    />
                  </SearchContainer>
                )}
                {options.length === 0 && <Empty>No results at this time.</Empty>}
                <OverlayScroll>
                  {options.map((option, index) => (
                    <SelectFieldRow
                      label={option.label}
                      value={option.value}
                      key={typeof option.value === 'string' ? option.value : index}
                      onClick={onSelect}
                      selected={selectedValues.includes(option.value)}
                    />
                  ))}
                  {this.props.extensible && (
                    <AddNewRow
                      label={this.props.addActionLabel}
                      placeholder={this.props.addActionPlaceholder}
                      onSave={this.handleCreate}
                      onAddNew={this.props.onClickAddNew}
                    />
                  )}
                </OverlayScroll>
              </OverlayFocus>
            </StyledOverlay>
          )}
          {this.props.error && <Error>{this.props.error}</Error>}
        </Container>
      </ClickOut>
    );
  }
}
