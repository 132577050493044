/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';

import { type CellPropsType } from 'components/budget/Table';
import InlineTextField from 'components/budget/Table/InlineTextField';

import { type ExpenseCellPropsType } from './index';

import { type ExpenseNameCell_event } from './__generated__/ExpenseNameCell_event.graphql';
import { type ExpenseNameCell_expense } from './__generated__/ExpenseNameCell_expense.graphql';

const preventDefault = (e: SyntheticEvent<HTMLElement>) => {
  e.preventDefault();
};

const ExpenseNameCell = ({
  expense,
  event,
  updateExpense,
  updateColumnWidth,
}: CellPropsType<ExpenseCellPropsType<ExpenseNameCell_expense, ExpenseNameCell_event>>) => (
  <InlineTextField
    value={expense.name}
    onChange={name => {
      updateExpense({ name });

      updateColumnWidth();
    }}
  >
    <Link to={`/events/${event.slug}/budget/${expense.id}`} onMouseDown={preventDefault}>
      {expense.name}
    </Link>
  </InlineTextField>
);

export default createFragmentContainer(
  ExpenseNameCell,
  graphql`
    fragment ExpenseNameCell_expense on Expense {
      id
      name
    }

    fragment ExpenseNameCell_event on Event {
      slug
    }
  `,
);
