/* @flow */
import React from 'react';
import styled from 'styled-components';
import Clipboard from 'clipboard';
import moment from 'moment-timezone';
import qs from 'qs';

import downloadICS from 'utils/requests/downloadICS';
import showErrorPopup from 'utils/showErrorPopup';

import CalendarIcon from 'images/calendarAlt.svg';
import ExternalLinkIcon from 'images/external.svg';
import LinkIcon from 'images/link.svg';
import { OutlineButton } from 'components/budget/Button';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowSubtitle,
  WindowTitle,
} from 'components/material/Window';

const StyledWindowHeader = styled(WindowHeader)`
  display: block;
  padding-bottom: 0;

  @media (${props => props.theme.mobileOnly}) {
    height: auto;
  }
`;

const StyledSubtitle = styled(WindowSubtitle)`
  font-size: 13px;
  color: #828b93;
  overflow: initial;
  white-space: initial;
  text-overflow: initial;
`;

const StyledWindowContent = styled(WindowContent)`
  padding: 24px 27px;

  > div:not(:first-child) {
    margin-top: 12px;
  }

  @media (max-width: 470px) {
    padding: 24px 14px;
  }
`;

const SharingOptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 4px;
  background: #f7f8f9;

  svg {
    margin-right: 8px;
  }

  @media (max-width: 470px) {
    padding: 8px 10px;
  }
`;

const OptionDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;

  svg {
    flex-shrink: 0;
    margin-right: 8px;
    fill: #3e4858;
  }
`;

const StyledButton = styled(OutlineButton)`
  flex: 0 0 98px;

  @media (max-width: 470px) {
    flex: 0 0 80px;
    padding: 8px;
  }
`;

const Subtext = styled.div`
  font-weight: 400;
  color: #6e7884;

  > a {
    margin-left: 5px;
    color: #5e5bff;
    &:hover {
      color: #403ec9;
      text-decoration: underline;
    }
  }
`;

type datesProp = {
  startDate: string,
  endDate?: ?string,
  allDay: boolean,
  tz: string,
};

export default class ExternalCalendarExport extends React.PureComponent<
  {
    listTitle: string,
    icsUrl: string,
    googleCalendarProps?: {
      name: string,
      location?: string,
      details?: string,
      dates: datesProp,
    },
    onHide: () => void,
    showDownloadOption?: boolean,
  },
  {
    copied: boolean,
    loading: boolean,
  },
> {
  state = {
    copied: false,
    loading: false,
  };

  clipboard = null;

  copyRef = React.createRef();

  componentDidMount() {
    if (this.props.googleCalendarProps == null) this.setupClipboard();
  }

  componentWillUnmount() {
    this.destroyClipboard();
  }

  setupClipboard = () => {
    this.clipboard = new Clipboard(this.copyRef, {
      text: () => this.props.icsUrl,
    }).on('success', () => {
      this.setState({ copied: true });
    });
  };

  destroyClipboard = () => {
    if (this.clipboard) {
      this.clipboard.destroy();
      this.clipboard = null;
    }
  };

  handleMouseLeave = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 300);
  };

  handleAddToGoogle = () => {
    const { googleCalendarProps: eventInfo } = this.props;
    if (eventInfo == null) return;

    const query = qs.stringify({
      action: 'TEMPLATE',
      text: eventInfo.name,
      dates: this.formatDates(eventInfo.dates),
      ctz: eventInfo.dates.tz,
      location: eventInfo.location,
      details: eventInfo.details && eventInfo.details.replace(/^\040+/gm, '').replace(/^\s+/, ''),
      output: 'xml',
      sf: true,
    });
    window.open(`https://calendar.google.com/calendar/render?${query}`);
  };

  formatDates = (dates: datesProp) =>
    `
    ${this.formatDate(dates.startDate, dates.allDay, dates.tz)}
    /
    ${
      dates.endDate
        ? this.formatDate(
            dates.allDay ? moment(dates.endDate).add(1, 'day') : dates.endDate,
            dates.allDay,
            dates.tz,
          )
        : this.formatDate(
            dates.allDay
              ? moment(dates.startDate).add(1, 'day')
              : moment(dates.startDate).add(1, 'hour'),
            dates.allDay,
            dates.tz,
          )
    }
  `.replace(/\s/g, '');

  formatDate = (date: moment, all_day: boolean, tz: string) =>
    moment.tz(date, tz).format(`YYYYMMDD${all_day ? '' : '[T]HHmmss'}`);

  handleDownloadClick = () => {
    const { icsUrl, listTitle } = this.props;
    this.setState({ loading: true });
    downloadICS(icsUrl, `${listTitle}.ics`)
      .catch(showErrorPopup)
      .then(() => this.setState({ loading: false }));
  };

  render() {
    const { listTitle, googleCalendarProps, showDownloadOption, onHide } = this.props;
    const { loading, copied } = this.state;
    return (
      <Window size={436} onHide={this.props.onHide}>
        <StyledWindowHeader>
          <WindowTitle>Add to calendar</WindowTitle>
          <StyledSubtitle>Add {listTitle} to an external calendar</StyledSubtitle>
          <WindowClose onClick={onHide} />
        </StyledWindowHeader>
        <StyledWindowContent>
          {googleCalendarProps == null ? (
            <SharingOptionRow>
              <OptionDescription>
                <LinkIcon />
                Import URL into any external calendar
              </OptionDescription>
              <StyledButton
                innerRef={el => {
                  this.copyRef = el;
                }}
                label={copied ? 'Copied' : 'Copy link'}
                onMouseLeave={this.handleMouseLeave}
              />
            </SharingOptionRow>
          ) : (
            <SharingOptionRow>
              <OptionDescription>
                <ExternalLinkIcon />
                Add to Google Calendar
              </OptionDescription>
              <StyledButton label="Add" onClick={this.handleAddToGoogle} />
            </SharingOptionRow>
          )}
          {showDownloadOption && (
            <SharingOptionRow>
              <OptionDescription>
                <CalendarIcon />
                Download iCal file
              </OptionDescription>
              <StyledButton label="Download" onClick={this.handleDownloadClick} loading={loading} />
            </SharingOptionRow>
          )}
          <Subtext>
            Learn more in our
            <a
              href="https://help.circa.co/en/articles/693568"
              target="_blank"
              rel="noreferrer noopener"
            >
              help center
            </a>
          </Subtext>
        </StyledWindowContent>
      </Window>
    );
  }
}
