/* @flow */
import * as React from 'react';
import type { Location } from 'react-router-dom';
import styled, { css } from 'styled-components';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';

import type { AlignType, FieldType } from 'utils/customization/types';
import { type SortParam } from 'utils/routing/parseTypedQueryString';

import Arrow from 'images/arrow.svg';
import CheckBox from 'components/EventRequestForm/form/CheckBox';
import Loader from 'components/Loader';
import Tooltip from 'components/material/Tooltip';

import TableAction from './TableAction';
import TableCell from './TableCell';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableRowGroup from './TableRowGroup';
import TableSort from './TableSort';

const noScrollCss = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const Root = styled.div`
  position: relative;
  z-index: 0;

  ${props =>
    !props.noBorder &&
    css`
      border: 1px solid #dfe1e5;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05);
    `};

  ${props =>
    !props.hasFooter &&
    css`
      border-bottom: 0;
    `};
`;

const TableScroll = styled.div`
  display: flex;
  overflow-y: auto;
  margin-left: -1px;
  padding-right: ${props => props.hideScrolls && '1px'};
  ${props => props.hideScrolls && noScrollCss}
`;

export const TableStickyScroll = styled.div`
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  z-index: ${props => props.zindex};
  display: flex;
  overflow-y: auto;
  margin-left: -1px;

  &:first-child {
    top: 0;
    ${noScrollCss};
  }

  &:not(:first-child) {
    bottom: 0;

    ${props =>
      props.sticky &&
      css`
        margin-top: -1px;
      `};
  }
`;

const ScrollContent = styled.div`
  flex: 1 1 auto;
`;

const Measurer = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const StyledArrow = styled(Arrow)`
  transition: 0.3s;
  margin: -2px 10px 0 0;
  color: #868f96;

  .expanded > * > & {
    transform: rotate(90deg);
  }
`;

const LeftShift = styled.span`
  display: inline-block;
  // Size of the expand/collaps arrow + margin
  width: 18px;
`;

const CheckboxBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -1px;
  border-top: 1px solid #dfe1e5;
  border-bottom: 1px solid #dfe1e5;
  border-left: ${props => (props.checked ? 2 : 0)}px solid #29cc71;
`;

const StyledCheckBox = styled(CheckBox)`
  z-index: 1;
  margin: 0 -8px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

export type CellPropsType<Props: {}> = $Exact<
  {
    updateColumnWidth: () => void,
  } & Props,
>;

export type ColumnType<P: {}, F: {}> = {|
  title: string,
  sortKey?: string,
  primarySortDirection?: 'asc' | 'desc',
  component: React.ComponentType<CellPropsType<P>>,
  footerComponent?: React.ComponentType<F>,
  align?: AlignType,
  type?: 'action',
  grow?: number,
  width?: number,
  minWidth?: number,
  fixedLeft?: number,
  fieldSettings?: FieldType,
  location?: ?Location,
|};

export default class Table<D: {}, P: {}, F: {}, C: {}> extends React.PureComponent<
  {|
    columns: $ReadOnlyArray<ColumnType<P, F>>,
    data: ?$ReadOnlyArray<D>,
    sort?: SortParam,
    onChangeSort?: (sort: SortParam) => void,
    keyExtractor: (rowData: D | C, rowIndex: number) => string | number,
    cellProps: (D, ?C, columnProps?: ColumnType<P, F>) => P,
    footerCellProps?: () => F,
    stickyFooter?: ?boolean,
    rowClickHandler?: ?(rowData: D | C) => ?() => void,
    innerRef?: $Call<React.createRef<any>>,
    groupOptions?: {
      groupChildExtractor: D => $ReadOnlyArray<C>,
      colors: $ReadOnlyArray<string>,
    },
    measurerComponent?: React.ElementType,
    widthMeasurer?: (colIndex: number, col: ColumnType<P, F>, measurer: HTMLElement) => number,
    selectedItems?: $ReadOnlyArray<string | number>,
    onSelectItems?: (items: $ReadOnlyArray<string | number>) => void,
    viewerCanUpdateAllItems?: boolean,
    checkUserCanUpdateItem?: (rowData: D | C) => string,
    setColumnWidthUpdater?: (updateColumnWidths: () => void) => void,
  |},
  { expandedGroups: $ReadOnlyArray<string>, colWidths: $ReadOnlyArray<number> },
> {
  state = { expandedGroups: [], colWidths: [] };

  hasCheckbox: boolean = this.props.selectedItems != null;

  scrollContainerElement: ?HTMLElement = null;

  lastScrollLeft: number = 0;

  lastShowTopShadow: boolean = false;

  lastShowBottomShadow: boolean = false;

  cachedData: ?$ReadOnlyArray<D> = this.props.data;

  rootRef = React.createRef();

  measurerRef = React.createRef();

  contentRef = React.createRef();

  headerRef = React.createRef();

  footerRef = React.createRef();

  contentScrollRef = React.createRef();

  headerScrollRef = React.createRef();

  footerScrollRef = React.createRef();

  componentDidMount() {
    const rootElement = this.rootRef.current;
    const scrollContainerElement = rootElement && this.getScrollContainer(rootElement);

    if (!rootElement || !scrollContainerElement) return;

    scrollContainerElement.addEventListener('scroll', this.handleScrollVertical, true);

    this.scrollContainerElement = scrollContainerElement;

    this.updateColumnWidths();
    this.handleScrollVertical();

    if (this.props.setColumnWidthUpdater) {
      this.props.setColumnWidthUpdater(this.updateColumnWidths);
    }
  }

  componentDidUpdate(prevProps: $PropertyType<Table<D, P, F, C>, 'props'>) {
    if (this.lastScrollLeft > 0) this.applyLeftShadow(true);
    if (this.lastShowTopShadow) this.applyTopShadow(true);
    if (this.lastShowBottomShadow) this.applyBottomShadow(true);

    const { data, keyExtractor } = this.props;

    if (
      !data ||
      (prevProps.data != null &&
        this.cachedData &&
        this.props.columns.length === prevProps.columns.length &&
        data.length === this.cachedData.length &&
        this.cachedData.every(
          (item, index) => keyExtractor(data[index], index) === keyExtractor(item, index),
        ))
    ) {
      return;
    }

    this.cachedData = data;

    this.updateColumnWidths();
  }

  componentWillUnmount() {
    const scrollContainerElement = this.scrollContainerElement;

    if (!scrollContainerElement) return;

    scrollContainerElement.removeEventListener('scroll', this.handleScrollVertical, true);

    this.scrollContainerElement = null;
  }

  measureSize = (element: HTMLElement, columnMinWidth?: number) => {
    const measurerElement = this.measurerRef.current;

    if (!measurerElement) return 0;

    measurerElement.appendChild(element);

    const width = measurerElement.offsetWidth;

    measurerElement.removeChild(element);

    return columnMinWidth != null && width <= columnMinWidth ? columnMinWidth : width + 5;
  };

  getScrollContainer = (node: HTMLElement) => {
    const parent: HTMLElement = (node.parentElement: any);

    if (!parent) return null;
    if (['auto', 'scroll'].includes(window.getComputedStyle(parent).overflowY)) return parent;

    return this.getScrollContainer(parent);
  };

  getTableRows = () => {
    const contentElement = this.contentRef.current;
    return contentElement == null
      ? []
      : flatMap(contentElement.children, childElement =>
          childElement.classList.contains('TableRowGroup')
            ? [...childElement.children]
            : childElement,
        );
  };

  updateColumnWidths = (colIndex?: number) => {
    const headerElement = this.headerRef.current;
    const footerElement = this.footerRef.current;
    const contentElement = this.contentRef.current;

    if (!contentElement || !headerElement || (this.props.footerCellProps && !footerElement)) return;

    const rows = [
      headerElement,
      ...this.getTableRows(),
      this.props.footerCellProps ? footerElement : null,
    ].filter(Boolean);
    const colWidths = this.props.columns.map((col, index) => {
      const currentIndex = this.hasCheckbox ? index + 1 : index;
      return col.width === undefined ? this.state.colWidths[currentIndex] : col.width;
    });

    if (this.props.widthMeasurer != null) {
      this.props.columns.forEach((col, index) => {
        if (
          (colIndex == null || colIndex === index) &&
          col.width == null &&
          this.props.widthMeasurer != null &&
          this.measurerRef.current != null
        ) {
          colWidths[index] = this.props.widthMeasurer(index, col, this.measurerRef.current);
        }
      });
    } else {
      const columnsContainers = this.props.columns.map(() => document.createElement('div'));

      rows.forEach(row =>
        [...row.children]
          .filter((_cell, index) => this.hasCheckbox !== true || index !== 0)
          .forEach((cell, index) => {
            if (
              (colIndex != null && index !== colIndex) ||
              this.props.columns[index].width != null
            ) {
              return;
            }
            const clonedElement = cell.cloneNode(true);
            clonedElement.style.minWidth = 'unset';
            columnsContainers[index].appendChild(clonedElement);
          }),
      );

      columnsContainers.forEach((columnContainer, index) => {
        if ((colIndex != null && index !== colIndex) || this.props.columns[index].width != null) {
          return;
        }
        colWidths[index] = this.measureSize(columnContainer, this.props.columns[index].minWidth);
      });
    }

    this.setState({ colWidths: this.hasCheckbox ? [undefined, ...colWidths] : colWidths });
  };

  handleScrollHorizontal = (event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();

    const target = event.target;

    if (!(target instanceof HTMLElement) || !event.currentTarget.contains(target)) return;

    const scrollLeft = Math.min(
      target.scrollWidth - target.offsetWidth,
      Math.max(0, target.scrollLeft),
    );

    if (scrollLeft === this.lastScrollLeft) return;

    const headerScrollElement = this.headerScrollRef.current;
    const footerScrollElement = this.footerScrollRef.current;
    const contentScrollElement = this.contentScrollRef.current;

    if (
      !headerScrollElement ||
      (this.props.footerCellProps && !footerScrollElement) ||
      !contentScrollElement
    ) {
      return;
    }

    headerScrollElement.scrollLeft = scrollLeft;
    if (this.props.footerCellProps && footerScrollElement) {
      footerScrollElement.scrollLeft = scrollLeft;
    }
    contentScrollElement.scrollLeft = scrollLeft;

    const lastShowLeftShadow = this.lastScrollLeft > 0;
    const showLeftShadow = scrollLeft > 0;

    this.lastScrollLeft = scrollLeft;

    if (lastShowLeftShadow === showLeftShadow) return;

    this.applyLeftShadow(showLeftShadow);
  };

  handleScrollVertical = () => {
    const rootElement = this.rootRef.current;
    const contentElement = this.contentRef.current;
    const scrollContainerElement = this.scrollContainerElement;

    if (!rootElement || !scrollContainerElement || !contentElement) return;

    const showTopShadow = scrollContainerElement.scrollTop - rootElement.offsetTop > 0;
    const showBottomShadow = this.props.stickyFooter
      ? scrollContainerElement.scrollTop +
          scrollContainerElement.offsetHeight -
          rootElement.offsetTop -
          rootElement.offsetHeight <
        0
      : false;

    if (showTopShadow !== this.lastShowTopShadow) {
      this.lastShowTopShadow = showTopShadow;

      this.applyTopShadow(showTopShadow);
    }

    if (showBottomShadow !== this.lastShowBottomShadow) {
      this.lastShowBottomShadow = showBottomShadow;

      this.applyBottomShadow(showBottomShadow);
    }
  };

  applyLeftShadow = (showShadow: boolean) => {
    const headerElement = this.headerRef.current;
    const footerElement = this.footerRef.current;
    const contentElement = this.contentRef.current;

    if (!contentElement || !headerElement || (this.props.footerCellProps && !footerElement)) return;

    const rows = [
      headerElement,
      ...this.getTableRows(),
      this.props.footerCellProps ? footerElement : null,
    ].filter(Boolean);

    rows.forEach((row, index) => {
      const fixedCell = row.children[this.hasCheckbox ? 1 : 0];
      const lastRow = index === rows.length - 1;

      fixedCell.style.boxShadow = showShadow
        ? `2px 0 0 rgba(0, 0, 0, 0.04), ${
            this.props.stickyFooter && lastRow ? '' : '0 -1px #dfe1e5, 0 1px #dfe1e5,'
          } inset -1px 0 #dfe1e5`
        : '';
    });
  };

  applyTopShadow = (showShadow: boolean) => {
    const headerScrollElement = this.headerScrollRef.current;

    if (!headerScrollElement) return;

    headerScrollElement.style.boxShadow = showShadow
      ? '0 1px 0 #dfe1e5, 0 3px 0 rgba(0, 0, 0, 0.04)'
      : '';
  };

  applyBottomShadow = (showShadow: boolean) => {
    const footerScrollElement = this.footerScrollRef.current;

    if (!footerScrollElement) return;

    footerScrollElement.style.boxShadow = showShadow ? '0 -2px 0 rgba(0, 0, 0, 0.04)' : '';
  };

  handleRowClick = (e: SyntheticMouseEvent<HTMLElement>) => {
    const key = e.currentTarget.dataset.key;
    this.setState(prevState => ({
      expandedGroups: prevState.expandedGroups.includes(key)
        ? prevState.expandedGroups.filter(item => item !== key)
        : [key, ...prevState.expandedGroups],
    }));
  };

  setRootRef = (element: ?HTMLElement) => {
    this.rootRef.current = element;

    if (!this.props.innerRef) return;

    if (typeof this.props.innerRef === 'function') {
      this.props.innerRef(element);
    } else {
      this.props.innerRef.current = element;
    }
  };

  handleCheckBoxClick = (key: string | number, shiftKey: boolean) => {
    const { selectedItems, onSelectItems, data, keyExtractor } = this.props;
    if (selectedItems == null || onSelectItems == null || data == null) {
      return;
    }

    if (selectedItems.includes(key)) {
      onSelectItems(selectedItems.filter(item => item !== key));
    } else if (shiftKey) {
      const lastSelected = selectedItems.slice(-1)[0];
      if (lastSelected) {
        const items = data.map((item, index) => keyExtractor(item, index));
        let fromIndex = items.findIndex(itemId => itemId === lastSelected);
        let toIndex = items.findIndex(itemId => itemId === key);

        if (fromIndex > toIndex) [fromIndex, toIndex] = [toIndex, fromIndex];

        onSelectItems(uniq([...selectedItems, ...items.slice(fromIndex, toIndex + 1)]));
      }
    } else {
      onSelectItems([...selectedItems, key]);
    }
  };

  handleSelectAll = (isChecked: boolean): void => {
    const { data, onSelectItems, keyExtractor } = this.props;
    if (data != null && onSelectItems != null) {
      onSelectItems(isChecked ? data.map((item, index) => keyExtractor(item, index)) : []);
    }
  };

  renderHeaderCheckBoxCell = (maxZIndex: number): React.Node => {
    const { selectedItems, data, keyExtractor } = this.props;
    const checked: boolean =
      selectedItems != null &&
      data != null &&
      data.length > 0 &&
      data.every((item, index) => selectedItems.includes(keyExtractor(item, index)));

    return (
      <TableCell grow={0} stickyZIndex={maxZIndex}>
        {checked && <CheckboxBackground checked={checked} />}
        {this.props.viewerCanUpdateAllItems !== false && (
          <StyledCheckBox
            checked={checked}
            compact
            onChange={() =>
              this.handleSelectAll != null ? this.handleSelectAll(!checked) : undefined
            }
          />
        )}
      </TableCell>
    );
  };

  renderCheckBoxCell = (
    key: string | number,
    checked: boolean,
    viewerCanUpdateWarning: ?string,
    stickyZIndex: number,
  ): React.Node => {
    return (
      <TableCell grow={0} stickyZIndex={stickyZIndex}>
        <CheckboxBackground checked={checked} />
        <Tooltip label={viewerCanUpdateWarning}>
          <StyledCheckBox
            checked={checked}
            compact
            disabled={!!viewerCanUpdateWarning}
            showTooltip
            onClick={(e: SyntheticMouseEvent<HTMLElement>) =>
              this.handleCheckBoxClick(key, e.shiftKey)
            }
          />
        </Tooltip>
      </TableCell>
    );
  };

  getTableCellKey = (fieldSettings: ?FieldType, title: string) => {
    return fieldSettings
      ? `${fieldSettings.groupId !== undefined ? fieldSettings.groupId : ''}_${
          fieldSettings.id
        }_${title}`
      : title;
  };

  renderTableCell(
    column: ColumnType<P, F>,
    colIndex: number,
    cellContent: React.Node,
    stickyZIndex: number,
    groupRowCellProps?: {| grouped: boolean, color: string |},
  ) {
    return (
      <TableCell
        key={this.getTableCellKey(column.fieldSettings, column.title)}
        align={column.align}
        type={column.type}
        grow={column.grow}
        fixedLeft={column.fixedLeft}
        stickyZIndex={stickyZIndex}
        noBorder={
          !this.props.columns[colIndex + 1] || this.props.columns[colIndex + 1].type === 'action'
        }
        leftBorder={
          this.props.columns[colIndex - 1] &&
          (this.props.columns[colIndex - 1].fixedLeft != null || colIndex === 1) &&
          this.props.columns[colIndex].type !== 'action'
        }
        {...groupRowCellProps}
      >
        {column.type === 'action' ? <TableAction>{cellContent}</TableAction> : cellContent}
      </TableCell>
    );
  }

  renderTableRow(rowData: D, rowIndex: number, maxZIndex: number) {
    const groupOptions = this.props.groupOptions;
    const childData = groupOptions != null ? groupOptions.groupChildExtractor(rowData) : [];
    const rowGroupProps =
      groupOptions != null
        ? {
            onClick: this.handleRowClick,
            grouped: true,
            parent: true,
          }
        : {
            onClick:
              groupOptions == null && this.props.rowClickHandler
                ? this.props.rowClickHandler(rowData)
                : null,
          };

    const groupRowCellProps =
      groupOptions != null
        ? { grouped: true, color: groupOptions.colors[rowIndex % groupOptions.colors.length] }
        : undefined;
    const key = this.props.keyExtractor(rowData, rowIndex);
    const checked = this.props.selectedItems != null && this.props.selectedItems.includes(key);
    const viewerCanUpdateWarning = this.props.checkUserCanUpdateItem
      ? this.props.checkUserCanUpdateItem(rowData)
      : null;
    return (
      <React.Fragment key={key}>
        <TableRow
          {...rowGroupProps}
          className={this.state.expandedGroups.includes(key) ? 'expanded' : ''}
          isLoading={!this.props.data}
          data-key={key}
          colWidths={this.state.colWidths}
          checked={checked}
        >
          {this.hasCheckbox &&
            this.renderCheckBoxCell(key, checked, viewerCanUpdateWarning, maxZIndex - rowIndex)}
          {this.props.columns.map((column, colIndex) => {
            const cellContent = this.props.data ? (
              <>
                {groupOptions != null && colIndex === 0 && <StyledArrow />}
                <column.component
                  {...this.props.cellProps(rowData, null, column)}
                  updateColumnWidth={() =>
                    window.requestAnimationFrame(() => this.updateColumnWidths(colIndex))
                  }
                />
              </>
            ) : (
              <span>&nbsp;</span>
            );

            return this.renderTableCell(
              column,
              colIndex,
              cellContent,
              maxZIndex - rowIndex,
              groupRowCellProps,
            );
          })}
        </TableRow>
        {groupOptions != null && (
          <TableRowGroup className="TableRowGroup">
            {childData.map((childRow, childIndex) => (
              <TableRow
                key={this.props.keyExtractor(childRow, childIndex)}
                isLoading={!this.props.data}
                grouped
                colWidths={this.state.colWidths}
                onClick={this.props.rowClickHandler ? this.props.rowClickHandler(childRow) : null}
              >
                {this.props.columns.map((column, colIndex) => {
                  const cellContent = this.props.data ? (
                    <column.component
                      {...this.props.cellProps(rowData, childRow)}
                      updateColumnWidth={() =>
                        window.requestAnimationFrame(() => this.updateColumnWidths(colIndex))
                      }
                    />
                  ) : (
                    <span>&nbsp;</span>
                  );

                  return this.renderTableCell(column, colIndex, cellContent, maxZIndex - rowIndex);
                })}
              </TableRow>
            ))}
          </TableRowGroup>
        )}
      </React.Fragment>
    );
  }

  render() {
    const MeasurerComponent = this.props.measurerComponent;
    const rows = this.props.data || this.cachedData || [];
    const maxZIndex = rows.length + 2;
    return (
      <Root
        noBorder={!this.props.data && !this.cachedData}
        ref={this.setRootRef}
        onScroll={this.handleScrollHorizontal}
        hasFooter={this.props.footerCellProps != null}
      >
        {!this.props.data && !this.cachedData ? (
          <LoaderContainer>
            <Loader size={30} />
          </LoaderContainer>
        ) : (
          <React.Fragment>
            <TableStickyScroll sticky ref={this.headerScrollRef} zindex={maxZIndex + 1}>
              <ScrollContent>
                <TableHeader ref={this.headerRef} colWidths={this.state.colWidths}>
                  {this.hasCheckbox && this.renderHeaderCheckBoxCell(maxZIndex)}
                  {this.props.columns.map((column, index) => (
                    <TableCell
                      key={this.getTableCellKey(column.fieldSettings, column.title)}
                      align={column.align}
                      type={column.type}
                      grow={column.grow}
                      fixedLeft={column.fixedLeft}
                      stickyZIndex={maxZIndex}
                      header
                      noBorder={
                        !this.props.columns[index + 1] ||
                        this.props.columns[index + 1].type === 'action'
                      }
                      leftBorder={
                        this.props.columns[index - 1] &&
                        (this.props.columns[index - 1].fixedLeft != null || index === 1) &&
                        this.props.columns[index].type !== 'action'
                      }
                    >
                      {column.sortKey && this.props.sort && this.props.onChangeSort ? (
                        <TableSort
                          sortKey={column.sortKey}
                          sort={this.props.sort}
                          onChange={this.props.onChangeSort}
                          align={column.align}
                          primarySortDirection={column.primarySortDirection}
                        >
                          {column.title}
                        </TableSort>
                      ) : (
                        column.title
                      )}
                    </TableCell>
                  ))}
                </TableHeader>
              </ScrollContent>
            </TableStickyScroll>

            <TableScroll ref={this.contentScrollRef} hideScrolls={this.props.footerCellProps}>
              <ScrollContent ref={this.contentRef}>
                {rows.map((rowData, rowIndex) => this.renderTableRow(rowData, rowIndex, maxZIndex))}
              </ScrollContent>
            </TableScroll>

            {this.props.footerCellProps != null && (
              <TableStickyScroll
                sticky={this.props.stickyFooter}
                zindex={maxZIndex}
                ref={this.footerScrollRef}
              >
                <ScrollContent>
                  <TableFooter
                    ref={this.footerRef}
                    highlight={this.props.stickyFooter}
                    colWidths={this.state.colWidths}
                  >
                    {this.props.columns.map((column, index) => (
                      <TableCell
                        key={this.getTableCellKey(column.fieldSettings, column.title)}
                        align={column.align}
                        type={column.type}
                        grow={column.grow}
                        fixedLeft={column.fixedLeft}
                        footer
                        noBorder={
                          !this.props.columns[index + 1] ||
                          this.props.columns[index + 1].type === 'action'
                        }
                        leftBorder={
                          this.props.columns[index - 1] &&
                          (this.props.columns[index - 1].fixedLeft != null || index === 1) &&
                          this.props.columns[index].type !== 'action'
                        }
                      >
                        {this.props.groupOptions != null && index === 0 && <LeftShift />}
                        {column.footerComponent &&
                          (this.props.data && this.props.footerCellProps != null ? (
                            <column.footerComponent {...this.props.footerCellProps()} />
                          ) : (
                            <span>&nbsp;</span>
                          ))}
                      </TableCell>
                    ))}
                  </TableFooter>
                </ScrollContent>
              </TableStickyScroll>
            )}
          </React.Fragment>
        )}

        {MeasurerComponent == null ? (
          <Measurer ref={this.measurerRef} />
        ) : (
          <Measurer>
            <MeasurerComponent ref={this.measurerRef} />
          </Measurer>
        )}
      </Root>
    );
  }
}
