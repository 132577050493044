/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import NumberFieldCore from 'components/material/NumberField';

import inputBoxStyled from './components/inputBoxStyled';
import Label, { Error, Header } from './FieldComponents';

const StyledNumberField = styled(NumberFieldCore)`
  margin: 0;
  input {
    ${props => inputBoxStyled(props)};
  }
`;

export default class NumberField extends React.PureComponent<{
  label: string,
  onBlur?: (SyntheticEvent<HTMLInputElement>, value: ?number, name: string) => void,
  disabled?: boolean,
  defaultValue?: ?number,
  required?: boolean,
  error?: string,
}> {
  render() {
    const { error, required, onBlur, defaultValue, disabled, label } = this.props;
    return (
      <Label required={required}>
        <Header>{label}</Header>
        <StyledNumberField
          defaultValue={defaultValue}
          onBlur={onBlur}
          error={error}
          disabled={disabled}
        />
        {error && <Error>{error}</Error>}
      </Label>
    );
  }
}
