/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import { type CreateCompanyFromWindow } from 'graph/mutations/company/createCompany';
import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';

import Button, { MinimalButton } from 'components/budget/Button';
import { type ContactType } from 'components/Contacts/ContactWindow/ContactTypeSelector';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import { type Participant } from 'components/Participants';
import CompanySearch from 'components/Participants/CompanySearch';
import ParticipantRow from 'components/Participants/ParticipantRow';

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: #96a2ab;
`;

const EventName = styled.span`
  color: ${props => props.theme.headerRowColor};
`;

const Buttons = styled.div`
  margin-top: 30px;
  text-align: right;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

export default class AddCompanyWindow extends React.Component<
  {
    eventName: string,
    onCancel: () => void,
    onSave: (companyIds: $ReadOnlyArray<string>) => void,
    fromWindow: CreateContactFromWindow | CreateCompanyFromWindow,
  },
  {
    selection: $ReadOnlyArray<Participant>,
  },
> {
  state = {
    selection: [],
  };

  searchUserQueryExists: ?boolean;

  handleClickPropagation = (e: SyntheticEvent<>) => {
    e.stopPropagation();
  };

  handleAddButtonClick = () => {
    if (this.state.selection.length > 0) {
      this.props.onSave(this.state.selection.map(company => company.id));
    }
    this.props.onCancel();
  };

  handleCompanySelect = (company: ?Participant, contactType: ContactType) => {
    if (
      contactType === 'companies' &&
      company &&
      !this.state.selection.some(c => company && c.id === company.id)
    ) {
      this.setState(prevState => ({
        selection: company ? [company, ...prevState.selection] : prevState.selection,
      }));
    }
  };

  handleCompanyUnselect = (company: ?Participant) => {
    this.setState(prevState => ({
      selection: prevState.selection.filter(c => company != null && c.id !== company.id),
    }));
  };

  handleToggleUserQueryState = (present: boolean) => {
    this.searchUserQueryExists = present;
  };

  isNotEdited = () => {
    return !(this.state.selection.length > 0 || this.searchUserQueryExists);
  };

  handleOverlayHide = () => {
    if (this.isNotEdited()) {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <Window size="medium" onHide={this.handleOverlayHide} onClick={this.handleClickPropagation}>
        <WindowHeader>
          <WindowTitle>
            <Title>
              Add Companies to <EventName>{this.props.eventName}</EventName>
            </Title>
          </WindowTitle>
          <WindowClose onClick={this.props.onCancel} />
        </WindowHeader>
        <WindowContent>
          <CompanySearch
            onSelect={this.handleCompanySelect}
            onToggleQueryState={this.handleToggleUserQueryState}
            fromWindow={this.props.fromWindow}
          />
          {this.state.selection.map(company => (
            <ParticipantRow
              key={company.id}
              participant={company}
              onRemove={this.handleCompanyUnselect}
            />
          ))}
          <Buttons>
            <MinimalButton label="Cancel" onClick={this.props.onCancel} />
            <SubmitButton
              onClick={this.handleAddButtonClick}
              disabled={this.state.selection.length === 0}
            >
              Add to Event
            </SubmitButton>
          </Buttons>
        </WindowContent>
      </Window>
    );
  }
}
