/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type VendorFilterInputs } from 'components/Vendors/lib/renderVendorFilter';

const mutation = graphql`
  mutation addVendorAssignmentsMutation(
    $input: AddVendorAssignmentsInput!
    $filters: VendorFilters
  ) {
    addVendorAssignments(input: $input) {
      event {
        ...EventVendorsTablePagination_totalCountEvent
      }
      vendorEdges {
        node {
          id
          name
          ...VendorName_vendor
          ...VendorWebsite_vendor
          ...VendorCompanyPhone_vendor
          ...VendorStreet_vendor
          ...VendorCity_vendor
          ...VendorState_vendor
          ...VendorZip_vendor
          ...VendorCountry_vendor
          ...VendorTwitter_vendor
          ...VendorLinkedin_vendor
          ...VendorDescription_vendor
          ...VendorFirstName_vendor
          ...VendorLastName_vendor
          ...VendorTitle_vendor
          ...VendorOfficePhone_vendor
          ...VendorMobilePhone_vendor
          ...VendorEmail_vendor
          ...VendorCreator_vendor
          ...VendorCreatedAt_vendor
          ...VendorUpdater_vendor
          ...VendorUpdatedAt_vendor
          ...CustomizableText_customizable
          ...CustomizableTextarea_customizable
          ...CustomizableLink_customizable
          ...CustomizableDate_customizable
          ...CustomizableBoolean_customizable
          ...CustomizableNumber_customizable
          ...CustomizableCurrency_customizable
          ...CustomizableSelect_customizable
          ...CustomizableMultiselect_customizable
          ...CustomizableUserSelect_customizable
          ...CustomizableUserMultiselect_customizable
        }
      }
    }
  }
`;

export default function addVendorAssignments(
  eventId: string,
  vendorIds: $ReadOnlyArray<string>,
  filtersVariables: VendorFilterInputs,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        eventId,
        vendorIds,
      },
      filters: filtersVariables,
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'vendorEdges',
      connections: [{ key: 'EventVendorsTablePagination_vendors' }],
    }),
  });
}
