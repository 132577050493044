/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 60px 30px 60px 30px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Icon = styled.i`
  color: ${props => props.theme.mutedTextColor};
  font-size: 50px;
  margin-bottom: 12px;
`;

const Image = styled.img`
  height: 80px;
  margin-bottom: 12px;
`;

const Message = styled.div`
  font-size: 16px;
  color: ${props => props.theme.mutedTextColor};
`;

export default class NoResultsMessage extends React.Component<{
  iconName?: string,
  imageSource?: string,
  message: React.Node,
}> {
  static defaultProps = {
    message: 'No results match filter',
  };

  renderIcon() {
    if (this.props.imageSource != null) {
      return (
        <Image alt="Empty Result" src={this.props.imageSource} />
      );
    }
    const iconName = this.props.iconName || 'bars';
    return (
      <Icon className={`fa fa-${iconName}`} />
    );
  }

  render() {
    return (
      <Container>
        {this.renderIcon()}
        <Message>
          {this.props.message}
        </Message>
      </Container>
    );
  }
}
