import React from 'react';
import UIActions from 'actions/UIActions';
import PropTypes from 'prop-types';
import UIStore from 'stores/UIStore';

import connectToStores from 'utils/connectToStores';

import { Modal } from './Modal';

@connectToStores
export default class Windows extends React.Component {
  static propTypes = {
    windows: PropTypes.shape({
      modal: PropTypes.shape({ shown: PropTypes.bool }).isRequired,
    }).isRequired,
  };

  static getStores() {
    return [UIStore];
  }

  static getPropsFromStores() {
    return UIStore.getState();
  }

  handleHideEventLimitWindow = () => {
    UIActions.hideWindow('upgrade');
  };

  render() {
    return (
      <div>{this.props.windows.modal.shown && <Modal value={this.props.windows.modal} />}</div>
    );
  }
}
