/* @flow */
import React from 'react';
import { commitLocalUpdate, graphql } from 'react-relay';
import styled from 'styled-components';
import { ConnectionHandler } from 'relay-runtime';

import removeUser from 'graph/mutations/access/removeUser';
import type { UpdateUserFromWindow } from 'graph/mutations/user/updateUser';
import fetchGraphqlQuery from 'graph/utils/fetchGraphqlQuery';
import getRelayEnvironment from 'graph/utils/getRelayEnvironment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import Loader from 'components/Loader';
import Window, { WindowContent } from 'components/material/Window';
import { type ParsedMembersFilters } from 'views/Main/Workspace/Members/lib/parseMembersFilters';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 12px;
`;

const Text = styled.div`
  margin-bottom: 12px;
  text-align: center;
  color: #3e4859;
  font-size: 16px;
  font-weight: bold;
`;

const SubText = styled.div`
  text-align: center;
  color: #3e4859;
`;

export default class UserRemovalWindow extends React.Component<
  {
    userId: string,
    onHide: () => void,
    filters?: ParsedMembersFilters,
    fromWindow: UpdateUserFromWindow,
    onRemoveCompleted?: () => void,
  },
  {
    showProgress: boolean,
  },
> {
  timer: ?TimeoutID;

  static defaultProps = {
    fromWindow: 'Workspace Members',
  };

  state = {
    showProgress: false,
  };

  componentWillUnmount() {
    this.stopTimer();
  }

  removeUserFromRelay = (orgId: string) => {
    commitLocalUpdate(getRelayEnvironment(), store => {
      const usersConnection = ConnectionHandler.getConnection(
        store.get(orgId),
        'MembersPagination_users',
        {},
      );
      if (usersConnection) {
        ConnectionHandler.deleteNode(usersConnection, this.props.userId);
      }
      store.delete(this.props.userId);
    });
  };

  stopTimer = () => {
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
  };

  checkStatus = async () => {
    const { userId, filters, onRemoveCompleted, onHide } = this.props;
    const result = await fetchGraphqlQuery(
      graphql`
        query UserRemovalWindowQuery($userId: ID!, $query: String) {
          org {
            id
            orgMemberExists(userId: $userId)
            subscription {
              fatmLimit
              fatmCount
            }
            ...MembersPagination_totalCountUsers
          }
        }
      `,
      { userId, query: filters ? filters.search : null },
    );
    if (result.org.orgMemberExists) {
      this.timer = setTimeout(this.checkStatus, 2000);
    } else {
      this.stopTimer();
      this.removeUserFromRelay(result.org.id);
      onHide();
      if (onRemoveCompleted) {
        onRemoveCompleted();
      }
    }
  };

  handleRemove = () => {
    const { userId, fromWindow, onHide, onRemoveCompleted } = this.props;

    this.setState({ showProgress: true });
    setTimeout(() => {
      removeUser(userId, fromWindow)
        .then(() => {
          this.timer = setTimeout(this.checkStatus, 2000);
        })
        .catch(error => {
          if (onRemoveCompleted) {
            onRemoveCompleted();
          }
          showModernMutationError(error);
          onHide();
        });
    }, 0);
  };

  render() {
    if (this.state.showProgress) {
      return (
        <Window size={440} onHide={null}>
          <WindowContent>
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
            <Text>Deleting member, please wait…</Text>
            <SubText>We’re deleting the member from all associated events.</SubText>
            <SubText>This may take a while.</SubText>
          </WindowContent>
        </Window>
      );
    }

    return (
      <ConfirmationWindow
        onHide={this.props.onHide}
        onConfirm={this.handleRemove}
        actionLabel="Remove member"
        message="This will permanently remove the Member from your Workspace."
        withLoading
      />
    );
  }
}
