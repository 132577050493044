/* @flow */
import styled from 'styled-components';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  align-self: stretch;
  margin-bottom: 10px;
  background-color: rgb(100, 94, 110, 0.18);
`;

export default Divider;
