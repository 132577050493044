/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import paymentMethods from 'config/paymentMethods';

import { type CellPropsType } from 'components/budget/Table';
import InlineSelectField from 'components/budget/Table/InlineSelectField';
import TextInput from 'components/material/TextInput';

import { type PaymentCellPropsType } from './index';

import { type PaymentMethodCell_payment } from './__generated__/PaymentMethodCell_payment.graphql';

const OtherRow = styled.div`
  margin: -5px -10px;

  input {
    padding: 4px 10px;
    border-bottom: 1px solid transparent;
  }
`;

class PaymentMethodCell extends React.PureComponent<
  CellPropsType<PaymentCellPropsType<PaymentMethodCell_payment>>,
  { showOtherInput: boolean },
> {
  state = { showOtherInput: false };

  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = event.currentTarget.value.trim();

      if (value) {
        this.props.updatePayment({ paymentMethod: value });

        this.props.updateColumnWidth();

        event.currentTarget.blur();
      }
    } else if (event.key === 'Escape') {
      event.currentTarget.blur();
    }
  };

  render() {
    const { payment, updatePayment, updateColumnWidth } = this.props;
    const { showOtherInput } = this.state;

    return (
      <InlineSelectField
        options={[
          ...paymentMethods.map(method => ({ label: method.label, value: method.value })),
          ...(!payment.paymentMethod ||
          paymentMethods.some(method => method.value === payment.paymentMethod)
            ? []
            : [
                {
                  label: payment.paymentMethod,
                  value: payment.paymentMethod,
                },
              ]),
          {
            label: 'Other',
            value: '__other',
            displayLabel: showOtherInput ? (
              <OtherRow>
                <TextInput
                  autoFocus
                  placeholder="Custom Payment Method"
                  onKeyDown={this.handleKeyDown}
                />
              </OtherRow>
            ) : null,
            onSelect: () => this.setState({ showOtherInput: true }),
          },
        ]}
        value={payment.paymentMethod}
        placeholder="Add Method"
        onChange={paymentMethod => {
          updatePayment({ paymentMethod });

          updateColumnWidth();
        }}
        onHideOptions={() => {
          this.setState({ showOtherInput: false });
        }}
        clearable
      />
    );
  }
}

export default createFragmentContainer(
  PaymentMethodCell,
  graphql`
    fragment PaymentMethodCell_payment on Payment {
      paymentMethod
    }
  `,
);
