/* @flow */

const paymentStatuses = {
  UNPAID: 'Unpaid',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  PAID: 'Paid',
};

export type PaymentStatus = $Keys<typeof paymentStatuses>;

export default paymentStatuses;
