import './styles.styl';

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';

import { createMoment } from 'utils/Date';

import createScheduleDay from 'graph/mutations/schedule/createScheduleDay';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ScheduleDay from './ScheduleDay';
import ScheduleDayForm from './ScheduleDayForm';
import ScheduleDayTemp from './ScheduleDayTemp';
import ScheduleFilters from './ScheduleFilters';

class ScheduleItem extends React.Component {
  static propTypes = {
    schedule: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      dbId: PropTypes.number.isRequired,
      scheduleDays: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              scheduleItems: PropTypes.shape({
                edges: PropTypes.arrayOf(
                  PropTypes.shape({
                    node: PropTypes.shape({
                      scheduleParticipants: PropTypes.shape({
                        edges: PropTypes.array.isRequired,
                      }).isRequired,
                    }),
                  }),
                ).isRequired,
              }).isRequired,
            }),
          }),
        ).isRequired,
      }).isRequired,
      event: PropTypes.shape({
        name: PropTypes.string.isRequired,
        tz: PropTypes.string.isRequired,
        startDate: PropTypes.string,
        viewerCanCreateSchedules: PropTypes.bool.isRequired,
        schedules: PropTypes.shape({
          edges: PropTypes.array.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    eventId: PropTypes.number.isRequired,
    teamId: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { event, scheduleDays } = this.props.schedule;

    if (event.startDate && event.schedules.edges.length < 2 && scheduleDays.edges.length === 0) {
      const date = createMoment(event.tz)(event.startDate).startOf('day');

      this.handleDayCreate(date.format(), false);
    }
  }

  handleDayCreate = date => {
    createScheduleDay(this.props.schedule.id, { date }).catch(showModernMutationError);
  };

  render() {
    const { schedule, eventId, teamId, filters } = this.props;

    const savedScheduleDays = schedule.scheduleDays.edges.map(x => x.node);
    const scheduleDays = sortBy(savedScheduleDays, 'date');

    return (
      <div className="schedule-item">
        <ScheduleFilters
          filters={filters}
          event={schedule.event}
          memberFilterShown={
            schedule.event.viewerCanCreateSchedules &&
            scheduleDays.some(day =>
              day.scheduleItems.edges.some(item => item.node.scheduleParticipants.edges.length > 0),
            )
          }
        />

        {scheduleDays.length === 0 && schedule.event.viewerCanCreateSchedules ? (
          <ScheduleDayForm schedule={schedule} onSave={this.handleDayCreate} />
        ) : (
          scheduleDays.map(scheduleDay => {
            if (scheduleDay.isTemporary) {
              return (
                <ScheduleDayTemp
                  key={scheduleDay.id}
                  scheduleDay={scheduleDay}
                  event={schedule.event}
                  eventId={eventId}
                  teamId={teamId}
                  filters={filters}
                  onDayCreate={this.handleDayCreate}
                  isTemporary
                />
              );
            }

            return (
              <ScheduleDay
                key={scheduleDay.id}
                scheduleDay={scheduleDay}
                usedDays={scheduleDays}
                event={schedule.event}
                eventId={eventId}
                teamId={teamId}
                filters={filters}
                onDayCreate={this.handleDayCreate}
                scheduleId={this.props.schedule.id}
              />
            );
          })
        )}
      </div>
    );
  }
}

export default createFragmentContainer(
  ScheduleItem,
  graphql`
    fragment ScheduleItem_schedule on Schedule {
      id
      dbId
      name
      ...ScheduleDayForm_schedule
      scheduleDays {
        edges {
          node {
            id
            date
            ...ScheduleDay_scheduleDay
            scheduleItems {
              edges {
                node {
                  scheduleParticipants {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      event {
        name
        tz
        startDate
        viewerCanCreateSchedules
        ...ScheduleFilters_event
        ...ScheduleDay_event
        ...ScheduleDayTemp_event
        schedules(first: 2) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `,
);
