/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import fileUploadDefaults from 'config/fileUploadDefaults';

import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import showErrorPopup from 'utils/showErrorPopup';
import pickFiles from 'utils/uploading/pickFiles';
import type { CircaFile, FilePickerSource } from 'utils/uploading/types';
import isValidWebsite from 'utils/validators/isValidWebsite';

import Button from 'components/material/Button';
import Overlay from 'components/material/Overlay';
import TextField, {
  Container as TextFieldContainer,
  StyledTextInput,
} from 'components/material/TextField';

const UploadOption = styled.a`
  display: block;
  padding: 10px 16px;
  line-height: 1em;
  color: ${props => props.theme.rowPrimaryTextColor};
  cursor: pointer;
  &:hover {
    background: rgba(59, 169, 218, 0.07);
  }
  ${props =>
    props.active &&
    css`
      background: rgba(59, 169, 218, 0.07);
    `};
`;

const LinkForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const LinkWrapper = styled.div`
  margin-bottom: 15px;
  ${TextFieldContainer} {
    margin: 0;
  }
  ${StyledTextInput} {
    padding: 0;
  }
`;

const ButtonWrapper = styled.div`
  align-self: flex-end;
  width: 85px;
  margin: 2px 0 8px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
`;

type AttachmentFormSubmitEvent = {
  target: {
    link: HTMLInputElement,
    linkName?: HTMLInputElement,
  },
  preventDefault: () => void,
};

export default class AttachmentOverlay extends React.Component<
  {
    show: boolean,
    target: any,
    container: any,
    onHide: () => void,
    onUploaded: (file: CircaFile) => void,
    hideLink?: ?boolean,
    hideSources?: $ReadOnlyArray<FilePickerSource>,
    singleUpload?: boolean,
    allowOnlyImages?: boolean,
    maxDimensions?: [number, number], // [width, height]
    className?: string,
    forceRightEdge?: boolean,
  },
  {
    error: ?string,
    showLinkForm: boolean,
    link: string,
    linkName: string,
  },
> {
  state = { showLinkForm: false, error: null, link: '', linkName: '' };

  shouldComponentUpdate(nextProps: $PropertyType<AttachmentOverlay, 'props'>) {
    if (this.props.hideLink && nextProps.show) {
      this.handleSourceClick();
      return false;
    }
    return true;
  }

  handleFormSubmit = (e: AttachmentFormSubmitEvent) => {
    e.preventDefault();

    const link = enforceHttpPrefix(this.state.link);
    const error = isValidWebsite(link) ? null : 'Link is not valid';

    if (!error) {
      const linkName = this.state.linkName.trim();

      this.handleHide();

      this.props.onUploaded({
        filename: linkName || '',
        filetype: 'link/manual',
        fileurl: link,
        filesize: null,
      });
    }

    this.setState({ error });
  };

  shouldShowSource = (key: string) => {
    return (
      !this.props.hideSources || (this.props.hideSources && !this.props.hideSources.includes(key))
    );
  };

  handleSourceClick = () => {
    this.handleHide();
    const { allowOnlyImages, maxDimensions, singleUpload } = this.props;
    const fromSources = fileUploadDefaults.fromSources.filter(this.shouldShowSource);
    const accept = allowOnlyImages ? { accept: ['image/*'] } : {};
    const imageMax = maxDimensions ? { imageMax: maxDimensions } : {};
    const maxFiles = singleUpload ? 1 : fileUploadDefaults.maxFiles;
    const concurrency = singleUpload ? 1 : fileUploadDefaults.concurrency;
    const options = {
      fromSources,
      maxFiles,
      concurrency,
      ...accept,
      ...imageMax,
    };
    pickFiles(options)
      .then(files => {
        files.forEach(file => this.props.onUploaded(file));
      })
      .catch(showErrorPopup);
  };

  handleChangeLink = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ link: e.currentTarget.value });
  };

  handleChangeLinkName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ linkName: e.currentTarget.value });
  };

  handleHide = () => {
    this.props.onHide();

    this.setState({ link: '', linkName: '', error: null });
  };

  handleAttachLinkClick = () => {
    this.setState(prevState => ({
      showLinkForm: !prevState.showLinkForm,
    }));
  };

  render() {
    const {
      show,
      target,
      container,
      className,
      forceRightEdge,
      hideLink,
      singleUpload,
    } = this.props;
    const uploadFileText = `Attach ${singleUpload ? 'a' : ''} file${!singleUpload ? 's' : ''}`;

    const { error, link, linkName, showLinkForm } = this.state;
    return hideLink ? null : (
      <Overlay
        show={show}
        target={target}
        container={container}
        onHide={this.handleHide}
        className={className}
        forceRightEdge={forceRightEdge}
      >
        <UploadOption onClick={this.handleSourceClick}>{uploadFileText}</UploadOption>
        <UploadOption onClick={this.handleAttachLinkClick} active={showLinkForm}>
          Attach a link
        </UploadOption>
        {this.state.showLinkForm && (
          <LinkForm onSubmit={this.handleFormSubmit}>
            <LinkWrapper>
              <TextField onChange={this.handleChangeLink} error={error} value={link} />
            </LinkWrapper>
            <TextField
              label="Link name (optional)"
              value={linkName}
              onChange={this.handleChangeLinkName}
            />
            <ButtonWrapper>
              <Button label="Attach" primary type="submit" />
            </ButtonWrapper>
          </LinkForm>
        )}
      </Overlay>
    );
  }
}
