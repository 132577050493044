/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';
import DateTimeTooltip from 'components/material/DateTimeTooltip';

import ContactCreatedAt_contact from './__generated__/ContactCreatedAt_contact.graphql';

type Props = {
  contact: ContactCreatedAt_contact,
  tz: string,
};

const ContactCreatedAt = (props: Props) =>
  props.contact.createdAt && (
    <DateTimeTooltip date={moment.tz(props.contact.createdAt, props.tz)} />
  );

export default createFragmentContainer(
  ContactCreatedAt,
  graphql`
    fragment ContactCreatedAt_contact on Contact {
      createdAt
    }
  `,
);
