/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type UpdateEventEmailTemplateInput } from './__generated__/updateEventEmailTemplateMutation.graphql';

const mutation = graphql`
  mutation updateEventEmailTemplateMutation($input: UpdateEventEmailTemplateInput!) {
    updateEventEmailTemplate(input: $input) {
      eventEmailTemplate {
        id
        interval
        intervalUnit
        baseDate
        direction
        emailSubject
        emailTemplate
        attachments {
          edges {
            node {
              id
              fileurl
              filename
              filetype
              filesize
              ...AttachmentPreview_attachments
            }
          }
        }
        ...ScheduledEmailFilters_eventEmailTemplate
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateEventEmailTemplate(input: UpdateEventEmailTemplateInput) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  });
}
