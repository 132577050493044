/* @flow */
import styled from 'styled-components';

const ResetLink = styled.a`
  align-self: flex-start;
  margin-left: 5px;
  line-height: 18px;
  font-size: 13px;
  color: #3ba9da;
  cursor: pointer;
`;

export default ResetLink;
