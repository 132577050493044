/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';
import { transparentize } from 'polished';

import { stringifyLocation } from 'utils/Location';
import isValidWebsite from 'utils/validators/isValidWebsite';

import CalendarIcon from 'images/calendarIcon.svg';
import LinkIcon from 'images/link.svg';
import LocationIcon from 'images/location.svg';
import EGLogo from 'images/logo_expanded.png';
import VideoIcon from 'images/video.svg';

import BriefViewSharedContext from './BriefViewSharedContext';
import BriefMap from './components/BriefMap';

import BriefHeader_event from './__generated__/BriefHeader_event.graphql';

const Container = styled.div`
  text-align: center;
  ${props =>
    props.isShared &&
    css`
      @media (max-width: 905px) {
        margin: 0 16px 0;
      }
    `};
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  max-width: 200px;
  max-height: 100px;
  margin-right: auto;
  @media (max-width: 600px) {
    margin: 0;
  }
`;

const PersonalizedHeader = styled.div`
  padding: 3px 0;
  font-size: 15px;
  font-weight: 500;
  color: #8d959e;
  text-transform: uppercase;
`;

const Name = styled.div`
  margin-top: 9px;
  font-size: 26px;
  font-weight: 500;
  color: ${props => props.theme.riverBed};
`;

const Date = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.riverBed};
`;

const Location = styled.div`
  font-size: 16px;
  color: ${props => transparentize(0.39, props.theme.darkestTextColor)};
`;

const Website = styled.div`
  margin-top: 13px;
  font-size: 16px;
  color: rgba(62, 72, 89, 0.61);
`;

const ExternalLink = styled.a`
  font-size: 16px;
  color: ${props => props.color};
`;

const Icon = styled.span`
  position: relative;
  top: -2px;
  height: 25px;
  margin-right: 10px;
  color: #868f96;
`;

const HeaderItem = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const formatDate = (date: moment, dateAllDay?: ?boolean) => {
  return dateAllDay
    ? date.format(`MMMM D, YYYY`)
    : `${date.format('MMMM D, YYYY')} at ${date.format('h:mm a z')} `;
};

const formatDateRange = ({
  startDate,
  endDate,
  startDateAllDay,
  endDateAllDay,
}: {
  startDate: moment,
  endDate: ?moment,
  startDateAllDay?: ?boolean,
  endDateAllDay?: ?boolean,
}) => {
  if (startDate) {
    if (endDate) {
      return `${formatDate(startDate, startDateAllDay)} - ${formatDate(endDate, endDateAllDay)}`;
    }
    return formatDate(startDate, startDateAllDay);
  }
  return '';
};

const BriefHeader = (props: { event: BriefHeader_event, possessiveUserName: string }) => {
  const {
    event,
    event: {
      name,
      tz,
      team: { logo, primaryColor },
      startDate,
      endDate,
      startDateAllDay,
      endDateAllDay,
      virtualLocation,
      primaryLocation,
      zoomLocation,
      g2wLocation,
      website,
      briefSettings: {
        briefTemplate: { personalizeSchedules },
      },
    },
    possessiveUserName,
  } = props;
  const dateConfig =
    startDate != null
      ? {
          startDate: moment.tz(startDate, tz),
          endDate: endDate ? moment.tz(endDate, tz) : null,
          startDateAllDay,
          endDateAllDay,
        }
      : null;
  const finalLogo = logo || EGLogo;

  const getPrimaryLocation = () => {
    return stringifyLocation(primaryLocation, 'address1');
  };

  const getVirtualLocation = () => {
    if (virtualLocation && !isValidWebsite(virtualLocation)) {
      return virtualLocation;
    }

    const url =
      (zoomLocation && zoomLocation.joinUrl) ||
      (g2wLocation && g2wLocation.registrationUrl) ||
      virtualLocation;

    return (
      <ExternalLink href={url} color={primaryColor} rel="noopener noreferrer" target="_blank">
        {url}
      </ExternalLink>
    );
  };

  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <Container isShared={sharedView}>
          <LogoWrapper>
            <Logo src={finalLogo} alt="Logo" />
            {personalizeSchedules && (
              <PersonalizedHeader>
                {possessiveUserName ? `${possessiveUserName} BRIEFING` : ''}
              </PersonalizedHeader>
            )}
          </LogoWrapper>
          <Name>{name}</Name>
          {dateConfig && (
            <HeaderItem>
              <Date>
                <Icon>
                  <CalendarIcon />
                </Icon>
                {formatDateRange(dateConfig)}
              </Date>
            </HeaderItem>
          )}
          {primaryLocation && (
            <HeaderItem>
              <Location>
                <Icon>
                  <LocationIcon />
                </Icon>
                Physical location: {getPrimaryLocation()}
              </Location>
            </HeaderItem>
          )}
          {(virtualLocation || zoomLocation || g2wLocation) && (
            <HeaderItem>
              <Location>
                <Icon>
                  <VideoIcon />
                </Icon>
                Virtual Meeting/Webinar: {getVirtualLocation()}
              </Location>
            </HeaderItem>
          )}

          {website && (
            <Website>
              <Icon>
                <LinkIcon />
              </Icon>
              Website:{' '}
              <ExternalLink
                href={website}
                color={primaryColor}
                rel="noopener noreferrer"
                target="_blank"
              >
                {website}
              </ExternalLink>
            </Website>
          )}
          {primaryLocation && <BriefMap event={event} />}
        </Container>
      )}
    </BriefViewSharedContext.Consumer>
  );
};

export default createFragmentContainer(BriefHeader, {
  event: graphql`
    fragment BriefHeader_event on Event {
      ...BriefMap_event @include(if: $includeLocation)
      id
      name
      tz
      team {
        logo
        primaryColor
      }
      briefSettings {
        briefTemplate {
          personalizeSchedules
        }
      }
      startDate @include(if: $includeDates)
      endDate @include(if: $includeDates)
      startDateAllDay @include(if: $includeDates)
      endDateAllDay @include(if: $includeDates)
      virtualLocation @include(if: $includeLocation)
      primaryLocation @include(if: $includeLocation) {
        id
        city
        name
        country
        state
        lat
        lng
        address1
      }
      zoomLocation @include(if: $includeLocation) {
        joinUrl
      }
      g2wLocation @include(if: $includeLocation) {
        registrationUrl
      }
      website @include(if: $includeWebsite)
    }
  `,
});
