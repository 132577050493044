/**
 * @flow
 * @relayHash b23f38e65dcfea453289dc51491530ee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type UpdateContactFormFieldInput = {
  clientMutationId?: ?string,
  fieldName?: ?string,
  contactFormFieldId: string,
  customFieldId?: ?string,
  label?: ?string,
  required?: ?boolean,
  showOtherOption?: ?boolean,
  helpText?: ?string,
  contactFormFieldOptionIds?: ?$ReadOnlyArray<string>,
  contactFormFieldUserOptionIds?: ?$ReadOnlyArray<string>,
  defaultFont?: ?string,
};
export type updateContactFormFieldMutationVariables = {|
  input: UpdateContactFormFieldInput,
  changingMapping: boolean,
|};
export type updateContactFormFieldMutationResponse = {|
  +updateContactFormField: ?{|
    +contactFormField: {|
      +label: string,
      +fieldName: ?string,
      +required: boolean,
      +helpText: ?string,
      +showOtherOption: boolean,
      +customField: ?{|
        +id: string,
        +kind: CustomFieldKindType,
        +label: string,
        +required: boolean,
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +name: string,
            |}
          |}>
        |},
      |},
      +contactFormFieldOptions: ?{|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +customFieldOption: {|
              +id: string
            |}
          |}
        |}>
      |},
      +contactFormFieldUserOptions: ?{|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +user: {|
              +id: string
            |}
          |}
        |}>
      |},
    |},
    +registrationForm: ?{|
      +id: string,
      +hasChanges: boolean,
    |},
    +removedTextFilterConnections?: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedLinkFilterConnections?: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedNumberFilterConnections?: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedCurrencyFilterConnections?: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedBooleanFilterConnections?: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedMultiselectFilterConnections?: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedDateFilterConnections?: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedUserMultiselectFilterConnections?: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
    +removedRuleConnections?: ?$ReadOnlyArray<{|
      +parentId: string,
      +childId: string,
    |}>,
  |}
|};
export type updateContactFormFieldMutation = {|
  variables: updateContactFormFieldMutationVariables,
  response: updateContactFormFieldMutationResponse,
|};
*/


/*
mutation updateContactFormFieldMutation(
  $input: UpdateContactFormFieldInput!
  $changingMapping: Boolean!
) {
  updateContactFormField(input: $input) {
    contactFormField {
      label
      fieldName
      required
      helpText
      showOtherOption
      customField {
        id
        kind
        label
        required
        options {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      contactFormFieldOptions {
        edges {
          node {
            customFieldOption {
              id
            }
            id
          }
        }
      }
      contactFormFieldUserOptions {
        edges {
          node {
            user {
              id
            }
            id
          }
        }
      }
      id
    }
    registrationForm {
      id
      hasChanges
    }
    removedTextFilterConnections @include(if: $changingMapping) {
      parentId
      childId
    }
  removedLinkFilterConnections @include(if: $changingMapping) {
      parentId
      childId
    }
  removedNumberFilterConnections @include(if: $changingMapping) {
      parentId
      childId
    }
  removedCurrencyFilterConnections @include(if: $changingMapping) {
      parentId
      childId
    }
  removedBooleanFilterConnections @include(if: $changingMapping) {
      parentId
      childId
    }
  removedMultiselectFilterConnections @include(if: $changingMapping) {
      parentId
      childId
    }
  removedDateFilterConnections @include(if: $changingMapping) {
      parentId
      childId
    }
  removedUserMultiselectFilterConnections @include(if: $changingMapping) {
      parentId
      childId
    }
  removedRuleConnections @include(if: $changingMapping) {
      parentId
      childId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateContactFormFieldInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingMapping",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateContactFormFieldInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "required",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "helpText",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "showOtherOption",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": [
    v7,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "kind",
      "args": null,
      "storageKey": null
    },
    v2,
    v4,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "options",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldOptionTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldOptionTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldOptionType",
              "plural": false,
              "selections": [
                v7,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v9 = [
  v7
],
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customFieldOption",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldOptionType",
  "plural": false,
  "selections": v9
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": v9
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v7,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "parentId",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "childId",
    "args": null,
    "storageKey": null
  }
],
v14 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingMapping",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "removedTextFilterConnections",
      "storageKey": null,
      "args": null,
      "concreteType": "RemovedConnection",
      "plural": true,
      "selections": v13
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "removedLinkFilterConnections",
      "storageKey": null,
      "args": null,
      "concreteType": "RemovedConnection",
      "plural": true,
      "selections": v13
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "removedNumberFilterConnections",
      "storageKey": null,
      "args": null,
      "concreteType": "RemovedConnection",
      "plural": true,
      "selections": v13
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "removedCurrencyFilterConnections",
      "storageKey": null,
      "args": null,
      "concreteType": "RemovedConnection",
      "plural": true,
      "selections": v13
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "removedBooleanFilterConnections",
      "storageKey": null,
      "args": null,
      "concreteType": "RemovedConnection",
      "plural": true,
      "selections": v13
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "removedMultiselectFilterConnections",
      "storageKey": null,
      "args": null,
      "concreteType": "RemovedConnection",
      "plural": true,
      "selections": v13
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "removedDateFilterConnections",
      "storageKey": null,
      "args": null,
      "concreteType": "RemovedConnection",
      "plural": true,
      "selections": v13
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "removedUserMultiselectFilterConnections",
      "storageKey": null,
      "args": null,
      "concreteType": "RemovedConnection",
      "plural": true,
      "selections": v13
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "removedRuleConnections",
      "storageKey": null,
      "args": null,
      "concreteType": "RemovedConnection",
      "plural": true,
      "selections": v13
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateContactFormFieldMutation",
  "id": null,
  "text": "mutation updateContactFormFieldMutation(\n  $input: UpdateContactFormFieldInput!\n  $changingMapping: Boolean!\n) {\n  updateContactFormField(input: $input) {\n    contactFormField {\n      label\n      fieldName\n      required\n      helpText\n      showOtherOption\n      customField {\n        id\n        kind\n        label\n        required\n        options {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n      contactFormFieldOptions {\n        edges {\n          node {\n            customFieldOption {\n              id\n            }\n            id\n          }\n        }\n      }\n      contactFormFieldUserOptions {\n        edges {\n          node {\n            user {\n              id\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n    removedTextFilterConnections @include(if: $changingMapping) {\n      parentId\n      childId\n    }\n  removedLinkFilterConnections @include(if: $changingMapping) {\n      parentId\n      childId\n    }\n  removedNumberFilterConnections @include(if: $changingMapping) {\n      parentId\n      childId\n    }\n  removedCurrencyFilterConnections @include(if: $changingMapping) {\n      parentId\n      childId\n    }\n  removedBooleanFilterConnections @include(if: $changingMapping) {\n      parentId\n      childId\n    }\n  removedMultiselectFilterConnections @include(if: $changingMapping) {\n      parentId\n      childId\n    }\n  removedDateFilterConnections @include(if: $changingMapping) {\n      parentId\n      childId\n    }\n  removedUserMultiselectFilterConnections @include(if: $changingMapping) {\n      parentId\n      childId\n    }\n  removedRuleConnections @include(if: $changingMapping) {\n      parentId\n      childId\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateContactFormFieldMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateContactFormField",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateContactFormFieldPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactFormField",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactFormField",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contactFormFieldOptions",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactFormFieldOptionRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldOptionRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldOption",
                        "plural": false,
                        "selections": [
                          v10
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contactFormFieldUserOptions",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactFormFieldUserOptionRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldUserOptionRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldUserOption",
                        "plural": false,
                        "selections": [
                          v11
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12,
          v14
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateContactFormFieldMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateContactFormField",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateContactFormFieldPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactFormField",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactFormField",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contactFormFieldOptions",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactFormFieldOptionRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldOptionRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldOption",
                        "plural": false,
                        "selections": [
                          v10,
                          v7
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contactFormFieldUserOptions",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactFormFieldUserOptionRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldUserOptionRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldUserOption",
                        "plural": false,
                        "selections": [
                          v11,
                          v7
                        ]
                      }
                    ]
                  }
                ]
              },
              v7
            ]
          },
          v12,
          v14
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba37833c76c280d9f878e7046cdcb226';
module.exports = node;
