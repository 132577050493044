/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import IntegrationSetupStepper, {
  type StepPageComponentType,
} from 'components/integrations/IntegrationSetupStepper';
import { type IntegrationSettings } from 'components/integrations/IntegrationsSettings';
import IntegrationSettingsIcon from 'components/integrations/IntegrationsSettings/IntegrationSettingsIcon';
import Window, { WindowClose } from 'components/material/Window';

import components from './lib/components';

import { type MarketoWindow_org } from './__generated__/MarketoWindow_org.graphql';

const Sidebar = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 50px 0;
  border-radius: 3px 0 0 3px;
  background: ${props => props.color};
  overflow-y: auto;

  @media (${props => props.theme.mobileOnly}) {
    width: 150px;
  }
`;

const Name = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
`;

const Container = styled.div`
  display: flex;
  height: calc(100vh - 100px);
`;

class MarketoWindow extends React.PureComponent<{
  integration: IntegrationSettings,
  org: MarketoWindow_org,
  onClose: () => void,
}> {
  render() {
    const { integration, org, onClose } = this.props;
    return (
      <Window onHide={onClose} size="xxxlarge">
        <WindowClose onClick={onClose} />

        <Container>
          <Sidebar color={integration.color}>
            <IntegrationSettingsIcon integration={{ ...integration, color: '#fff' }} size={85} />

            <Name>{integration.name} Integration</Name>
          </Sidebar>

          <IntegrationSetupStepper
            onFinish={onClose}
            components={components}
            integration={integration}
            renderPageByStep={(currentStep: StepPageComponentType) => (
              <currentStep.component org={org} integration={integration} centered />
            )}
          />
        </Container>
      </Window>
    );
  }
}

export default createFragmentContainer(
  MarketoWindow,
  graphql`
    fragment MarketoWindow_org on Org {
      ...MarketoAuth_org
    }
  `,
);
