/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { MemberEmail_user } from './__generated__/MemberEmail_user.graphql';

const Container = styled.div`
  display: flex;
  align-items: baseline;
`;

type Props = {
  user: MemberEmail_user,
};

const stopPropagation = (e: SyntheticMouseEvent<>) => {
  e.stopPropagation();
};

function MemberEmail({ user }: Props) {
  const email = user.email;
  return (
    <Container>
      <a
        href={`mailto:${email}`}
        target="_blank"
        rel="noreferrer noopener"
        onClick={stopPropagation}
      >
        {email}
      </a>
    </Container>
  );
}

export default createFragmentContainer(MemberEmail, {
  user: graphql`
    fragment MemberEmail_user on User {
      email
    }
  `,
});
