/* @flow */
import React from 'react';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import { type DateRangeParam, type NumberRangeParam } from 'utils/routing/parseTypedQueryString';

import type { OptionGroup } from 'components/material/SelectField';
import type { ColumnGroupConfiguration } from 'components/material/table';

import type {
  BudgetReportingSavedViewsType,
  CustomGroupFieldSettingsType,
} from '../BudgetReportingPage';
import type { ParsedBudgetReportingFilters } from '../lib/parseBudgetReportingFilters';
import BudgetReportingActions from './BudgetReportingActions';
import BudgetReportingFilters from './BudgetReportingFilters';
import GroupFilters from './GroupFilters';
import SavedViews from './SavedViews';

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding-bottom: 8px;
`;

const RightContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export default class BudgetReportingHeader extends React.PureComponent<{
  filters: ParsedBudgetReportingFilters,
  history: RouterHistory,
  savedViewId: ?string,
  customFieldOptions: $ReadOnlyArray<OptionGroup>,
  viewerCanCreateReportingSavedView: boolean,
  savedViews: BudgetReportingSavedViewsType,
  orgId: string,
  orgSettings: CustomGroupFieldSettingsType,
  groups: ColumnGroupConfiguration,
  filterOptions: $ReadOnlyArray<FieldType>,
  onFilterChange: (filterParams: {
    [string]: ?(string | ?$ReadOnlyArray<string> | boolean | NumberRangeParam | DateRangeParam),
  }) => void,
  onFiltersChanged: () => void,
  onSetSavedView: (viewId: string) => void,
}> {
  render() {
    return (
      <FiltersContainer>
        <SavedViews
          orgId={this.props.orgId}
          savedViewId={this.props.savedViewId}
          viewerCanCreateReportingSavedView={this.props.viewerCanCreateReportingSavedView}
          savedViews={this.props.savedViews}
          filters={this.props.filters}
          filterOptions={this.props.filterOptions}
          onSetSavedView={this.props.onSetSavedView}
        />
        <RightContainer>
          <GroupFilters
            savedViewId={this.props.savedViewId}
            filters={this.props.filters}
            customFieldOptions={this.props.customFieldOptions}
            orgSettings={this.props.orgSettings}
            onFilterChange={this.props.onFilterChange}
          />
          <GroupContainer>
            <BudgetReportingFilters
              history={this.props.history}
              filters={this.props.filters}
              filterOptions={this.props.filterOptions}
              groups={this.props.groups}
              viewMode="budget"
              mobileBreakpoint={440}
              onFiltersChanged={this.props.onFiltersChanged}
            />
            <BudgetReportingActions
              filters={this.props.filters}
              filterOptions={this.props.filterOptions}
            />
          </GroupContainer>
        </RightContainer>
      </FiltersContainer>
    );
  }
}
