/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type CellPropsType } from 'components/budget/Table';
import InlineCostField from 'components/budget/Table/InlineCostField';

import { type CategoryCellPropsType } from './index';

import { type CategoryBudgetedAmountCell_categoryEdge } from './__generated__/CategoryBudgetedAmountCell_categoryEdge.graphql';
import { type CategoryBudgetedAmountCell_org } from './__generated__/CategoryBudgetedAmountCell_org.graphql';

class CategoryBudgetedAmountCell extends React.PureComponent<
  CellPropsType<
    CategoryCellPropsType<
      CategoryBudgetedAmountCell_categoryEdge,
      {},
      CategoryBudgetedAmountCell_org,
    >,
  >,
> {
  getError = (amount: ?number): ?string => {
    if (amount == null || amount <= 0) {
      return 'Need to be greater then 0';
    }
    return null;
  };

  render() {
    const {
      org,
      categoryEdge,
      categoryEdgeMock,
      adding,
      changeAdding,
      updateCategoryEdge,
      updateColumnWidth,
    } = this.props;

    const { budgetedAmount } = categoryEdge || categoryEdgeMock || {};

    return (
      <InlineCostField
        value={budgetedAmount || null}
        placeholder="Add Amount"
        currency={org.settings.currency}
        getError={adding ? this.getError : null}
        updateColumnWidth={updateColumnWidth}
        onChange={changedBudgetedAmount => {
          const amount =
            changedBudgetedAmount == null || changedBudgetedAmount === 0
              ? budgetedAmount
              : changedBudgetedAmount;

          if (adding) {
            changeAdding({ budgetedAmount: amount });
          } else {
            updateCategoryEdge({ budgetedAmount: amount });
          }

          updateColumnWidth();
        }}
      />
    );
  }
}

export default createFragmentContainer(
  CategoryBudgetedAmountCell,
  graphql`
    fragment CategoryBudgetedAmountCell_categoryEdge on EventBudgetCategoryEdge {
      budgetedAmount
    }

    fragment CategoryBudgetedAmountCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
