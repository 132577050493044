/**
 * @flow
 * @relayHash a39c40dd2b5b3b596b0c50ccff3361eb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MyTasksPagination_totalCountDeliverables$ref = any;
export type ContactSaveMethod = "api" | "g2w" | "import" | "manual" | "past_g2w" | "past_zoom" | "registration" | "zapier" | "zoom";
export type Direction = "ASC" | "DESC";
export type EventFormat = "HYBRID" | "PHYSICAL" | "VIRTUAL";
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventRegistrationFormStatus = "DRAFT" | "NOT_AVAILABLE" | "PUBLISHED";
export type EventRequestSubmissionStatusFilter = "APPROVED" | "DECLINED" | "NEW" | "N_A" | "RESUBMITTED" | "RETURNED";
export type EventSort = "ATTENDED_CONTACTS_TOTAL" | "BOOTH" | "BOOTH_DIMENSIONS" | "CITY" | "COMMITMENT_LEVEL" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATOR" | "CUSTOM" | "END_DATE" | "EVENT_FORMAT" | "EVENT_LEAD" | "LAST_SYNCED" | "LOCATION" | "MARKETO_ID" | "NAME" | "OPPORTUNITIES_AMOUNT" | "OPPORTUNITIES_NUMBER" | "PROGRESS" | "REGISTERED_CONTACTS_TOTAL" | "REQUESTED_BY" | "REQUESTED_DATE" | "REQUESTED_FORM_NAME" | "REQUESTED_STATUS" | "SALESFORCE_ID" | "STAFF_COUNT" | "START_DATE" | "STATE" | "STREET" | "SYNC_STATUS" | "TEAM_NAME" | "TEMPLATES_FIRST" | "TOTAL_ACTUAL_COST" | "TOTAL_PAID_COST" | "TOTAL_PLANNED_COST" | "UPDATED_AT" | "UPDATER" | "VENUE_NAME" | "VIRTUAL_LOCATION" | "WEBSITE" | "ZIP_CODE";
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type TaskSort = "DUE_DATE" | "EVENT_NAME" | "SECTION" | "STATUS" | "TITLE";
export type TaskStatus = "COMPLETED" | "OPEN";
export type UnassignDeliverablesInput = {
  clientMutationId?: ?string,
  deliverableIds: $ReadOnlyArray<string>,
  userId: string,
  fromWindow: string,
};
export type DeliverableFilters = {
  query?: ?string,
  eventIds?: ?$ReadOnlyArray<string>,
  tagIds?: ?$ReadOnlyArray<string>,
  assigneeIds?: ?$ReadOnlyArray<string>,
  teamIds?: ?$ReadOnlyArray<string>,
  statuses?: ?$ReadOnlyArray<TaskStatus>,
  createdMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  dueDate?: ?DateTimeRange,
  eventFilters?: ?EventFilters,
  sort?: ?TaskSort,
  direction?: ?Direction,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type EventFilters = {
  sort?: ?EventSort,
  direction?: ?Direction,
  customFieldSortId?: ?string,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  queries?: ?$ReadOnlyArray<string>,
  search?: ?Search,
  listType?: ?EventListEnum,
  afterDate?: ?any,
  beforeDate?: ?any,
  includeUndated?: ?boolean,
  excludeTemplates?: ?boolean,
  statuses?: ?$ReadOnlyArray<EventStatus>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  opportunitiesNumber?: ?NumberRangeInput,
  opportunitiesAmount?: ?NumberRangeInput,
  teamIds?: ?$ReadOnlyArray<string>,
  leadIds?: ?$ReadOnlyArray<string>,
  staffIds?: ?$ReadOnlyArray<string>,
  budgetCategoryIds?: ?$ReadOnlyArray<string>,
  fullAccessEvents?: ?boolean,
  onlyWithTasks?: ?boolean,
  venueNames?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  eventFormats?: ?$ReadOnlyArray<EventFormat>,
  eventIds?: ?$ReadOnlyArray<string>,
  excludeEventIds?: ?$ReadOnlyArray<string>,
  updatedAt?: ?DateTimeRange,
  updaterIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  creatorIds?: ?$ReadOnlyArray<string>,
  requesterUserIds?: ?$ReadOnlyArray<string>,
  requesterContactIds?: ?$ReadOnlyArray<string>,
  requestedDate?: ?DateTimeRange,
  requestStatuses?: ?$ReadOnlyArray<EventRequestSubmissionStatusFilter>,
  requestFormIds?: ?$ReadOnlyArray<string>,
  requestReviewers?: ?$ReadOnlyArray<string>,
  registeredContactsTotal?: ?NumberRangeInput,
  attendedContactsTotal?: ?NumberRangeInput,
  registrationFormStatuses?: ?$ReadOnlyArray<EventRegistrationFormStatus>,
  contactsCount?: ?NumberRangeInput,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type unassignTasksMutationVariables = {|
  input: UnassignDeliverablesInput,
  hasFilters: boolean,
  filters?: ?DeliverableFilters,
|};
export type unassignTasksMutationResponse = {|
  +unassignDeliverables: ?{|
    +unassignedDeliverableIds: $ReadOnlyArray<string>,
    +user: {|
      +deliverables?: {|
        +totalCount: number
      |},
      +$fragmentRefs: MyTasksPagination_totalCountDeliverables$ref,
    |},
    +deliverables: $ReadOnlyArray<{|
      +id: string,
      +following: ?boolean,
      +assignees: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string
          |}
        |}>
      |},
      +subtasks: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +assignees: {|
              +edges: $ReadOnlyArray<{|
                +node: {|
                  +id: string
                |}
              |}>
            |}
          |}
        |}>
      |},
    |}>,
  |}
|};
export type unassignTasksMutation = {|
  variables: unassignTasksMutationVariables,
  response: unassignTasksMutationResponse,
|};
*/


/*
mutation unassignTasksMutation(
  $input: UnassignDeliverablesInput!
  $hasFilters: Boolean!
  $filters: DeliverableFilters
) {
  unassignDeliverables(input: $input) {
    unassignedDeliverableIds
    user {
      ...MyTasksPagination_totalCountDeliverables @include(if: $hasFilters)
  deliverables @include(if: $hasFilters) {
        totalCount
      }
      id
    }
    deliverables {
      id
      following
      assignees {
        edges {
          node {
            id
          }
        }
      }
      subtasks {
        edges {
          node {
            assignees {
              edges {
                node {
                  id
                }
              }
            }
            id
          }
        }
      }
    }
  }
}

fragment MyTasksPagination_totalCountDeliverables on User {
  deliverablesCount: deliverables(filters: $filters) {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UnassignDeliverablesInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hasFilters",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "DeliverableFilters",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UnassignDeliverablesInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unassignedDeliverableIds",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "deliverables",
  "storageKey": null,
  "args": null,
  "concreteType": "DeliverableRequiredConnection",
  "plural": false,
  "selections": v3
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "following",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "assignees",
  "storageKey": null,
  "args": null,
  "concreteType": "UserRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            v5
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "unassignTasksMutation",
  "id": null,
  "text": "mutation unassignTasksMutation(\n  $input: UnassignDeliverablesInput!\n  $hasFilters: Boolean!\n  $filters: DeliverableFilters\n) {\n  unassignDeliverables(input: $input) {\n    unassignedDeliverableIds\n    user {\n      ...MyTasksPagination_totalCountDeliverables @include(if: $hasFilters)\n  deliverables @include(if: $hasFilters) {\n        totalCount\n      }\n      id\n    }\n    deliverables {\n      id\n      following\n      assignees {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      subtasks {\n        edges {\n          node {\n            assignees {\n              edges {\n                node {\n                  id\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment MyTasksPagination_totalCountDeliverables on User {\n  deliverablesCount: deliverables(filters: $filters) {\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "unassignTasksMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "unassignDeliverables",
        "storageKey": null,
        "args": v1,
        "concreteType": "UnassignDeliverablesPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "hasFilters",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "MyTasksPagination_totalCountDeliverables",
                    "args": null
                  },
                  v4
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": true,
            "selections": [
              v5,
              v6,
              v7,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subtasks",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Deliverable",
                        "plural": false,
                        "selections": [
                          v7
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "unassignTasksMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "unassignDeliverables",
        "storageKey": null,
        "args": v1,
        "concreteType": "UnassignDeliverablesPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v5,
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "hasFilters",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": "deliverablesCount",
                    "name": "deliverables",
                    "storageKey": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "filters",
                        "variableName": "filters",
                        "type": "DeliverableFilters"
                      }
                    ],
                    "concreteType": "DeliverableRequiredConnection",
                    "plural": false,
                    "selections": v3
                  },
                  v4
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": true,
            "selections": [
              v5,
              v6,
              v7,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subtasks",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Deliverable",
                        "plural": false,
                        "selections": [
                          v7,
                          v5
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75fd1d6f3dd9bd0faa88c4746b0642b1';
module.exports = node;
