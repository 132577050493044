/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import ProgressBar from 'components/material/ProgressBar';

import BriefField from '../../components/BriefField';

import { type EventInfoProgress_event } from './__generated__/EventInfoProgress_event.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 84px;
  height: 7px;
  margin-right: 8px;
  background-color: #c5f4eb !important;
  border-radius: 10px;
  > div {
    background-color: #54d4bb;
  }
`;

const EventInfoProgress = (props: { name: string, event: EventInfoProgress_event }) => {
  const {
    name,
    event: { progress },
  } = props;

  if (progress == null) return null;

  return (
    <BriefField contentDataAvailable={progress} label={name}>
      <Container>
        <StyledProgressBar value={progress} />
        <span>{`${progress}%`}</span>
      </Container>
    </BriefField>
  );
};

export default createFragmentContainer(
  EventInfoProgress,
  graphql`
    fragment EventInfoProgress_event on Event {
      progress
    }
  `,
);
