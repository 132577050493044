/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type CellPropsType } from 'components/budget/Table';
import InlineDateField from 'components/budget/Table/InlineDateField';

import { type PaymentCellPropsType } from './index';

import { type PaymentDueDateCell_event } from './__generated__/PaymentDueDateCell_event.graphql';
import { type PaymentDueDateCell_payment } from './__generated__/PaymentDueDateCell_payment.graphql';

const PaymentDueDateCell = ({
  payment,
  event,
  updatePayment,
  updateColumnWidth,
}: CellPropsType<PaymentCellPropsType<PaymentDueDateCell_payment, PaymentDueDateCell_event>>) => (
  <InlineDateField
    date={payment.dueDate}
    tz={event.tz}
    hideTime
    dateOnly
    placeholder="Add Due Date"
    onChange={({ date }) => {
      updatePayment({ dueDate: date });

      updateColumnWidth();
    }}
  />
);

export default createFragmentContainer(
  PaymentDueDateCell,
  graphql`
    fragment PaymentDueDateCell_payment on Payment {
      dueDate
    }

    fragment PaymentDueDateCell_event on Event {
      tz
    }
  `,
);
