/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';
import DateTimeTooltip from 'components/material/DateTimeTooltip';

import VendorCreatedAt_vendor from './__generated__/VendorCreatedAt_vendor.graphql';

type Props = {
  vendor: VendorCreatedAt_vendor,
  tz: string,
};

const VendorCreatedAt = (props: Props) =>
  props.vendor.createdAt && <DateTimeTooltip date={moment.tz(props.vendor.createdAt, props.tz)} />;

export default createFragmentContainer(
  VendorCreatedAt,
  graphql`
    fragment VendorCreatedAt_vendor on Vendor {
      createdAt
    }
  `,
);
