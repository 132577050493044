/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import type { CreatedEventType } from './EventForm';
import EventForm from './EventForm';
import type { EventCreateSource } from './EventWindow';

const query = graphql`
  query EventFormQuery {
    me {
      ...EventForm_me
    }
    org {
      ...EventForm_org
    }
  }
`;

export default class EventFormQuery extends React.Component<{
  fromWindow: EventCreateSource,
  onHide: () => void,
  onCreate: (event: CreatedEventType) => void,
  formRef: (el: EventForm) => void,
}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={({ org, me }) => (
          <EventForm
            componentRef={this.props.formRef}
            me={me}
            org={org}
            onHide={this.props.onHide}
            onCreate={this.props.onCreate}
            fromWindow={this.props.fromWindow}
          />
        )}
      />
    );
  }
}
