/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultTextColor } from '../../registrationFormDefaults';
import generateFontFromProps from '../generateFontFromProps';
import generatePaddingProps from '../generatePaddingProps';

import type { EventNameComponent_componentProps } from './__generated__/EventNameComponent_componentProps.graphql';
import type { EventNameComponent_registrationForm } from './__generated__/EventNameComponent_registrationForm.graphql';

const Container = styled.div`
  padding: ${props => generatePaddingProps(props.componentProps.padding)};
  background-color: ${props => props.componentProps.backgroundColor || 'initial'};
  font: ${props => generateFontFromProps(props.componentProps)};
  text-align: ${props => props.componentProps.alignment};
  color: ${props => props.componentProps.color || defaultTextColor};
`;

const EventNameComponent = (props: {
  registrationForm: EventNameComponent_registrationForm,
  componentProps: EventNameComponent_componentProps,
  selectedComponent: SelectedComponent,
}) => {
  const {
    registrationForm,
    selectedComponent,
    componentProps: { eventNameComponent },
  } = props;
  if (!eventNameComponent) {
    return null;
  }
  const componentProps = selectedComponent
    ? {
        ...eventNameComponent,
        padding: selectedComponent.selectedComponentPadding || eventNameComponent.padding,
        fontSize:
          selectedComponent.selectedComponentFontSize == null
            ? eventNameComponent.fontSize
            : selectedComponent.selectedComponentFontSize,
      }
    : eventNameComponent;
  return <Container componentProps={componentProps}>{registrationForm.eventName}</Container>;
};

export default createFragmentContainer(EventNameComponent, {
  registrationForm: graphql`
    fragment EventNameComponent_registrationForm on RegistrationForm {
      eventName
    }
  `,
  componentProps: graphql`
    fragment EventNameComponent_componentProps on RegistrationPageComponent {
      eventNameComponent {
        padding
        alignment
        fontFamily
        fontStyle
        fontSize
        color
        backgroundColor
      }
    }
  `,
});
