/* @flow */
import { graphql } from 'react-relay';

import checkImportStatus from 'utils/requests/checkImportStatus';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  importContactsMutationResponse,
  importContactsMutationVariables,
} from './__generated__/importContactsMutation.graphql';

type InputType = $PropertyType<importContactsMutationVariables, 'input'>;
export type ContactsImportMappingsType = $PropertyType<InputType, 'mappings'>;

export type ImportContactFromWindow = 'org contacts' | 'event contacts';

const mutation = graphql`
  mutation importContactsMutation($input: ImportContactsInput!) {
    importContacts(input: $input) {
      uuid
    }
  }
`;

export default function importContacts(input: InputType) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  }).then((response: importContactsMutationResponse) => {
    if (!response.importContacts) throw Error('Import Failed');

    return checkImportStatus(response.importContacts.uuid);
  });
}
