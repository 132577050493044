/* @flow */
import React from 'react';
import { DragDropContext as dragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';

import fonts from 'config/fonts';

import { loadFonts } from 'utils/fonts';

import NoEventStartDate from 'images/noEventStartDate.svg';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import EmptyMessage from 'components/EmptyMessage';
import Loader from 'components/Loader';
import NotAuthorized from 'components/NotAuthorized';
import { Container } from 'components/page/Container';

import validateColorParams from './lib/validateColorParams';
import makeUIPropsInsensitive from './makeUIPropsInsensitive';
import RegistrationHeader from './RegistrationHeader';
import RegistrationRoutes from './RegistrationRoutes';
import registrationUIDefaultProps, {
  type RegistrationUIPropsType,
} from './RegistrationUIDefaultProps';
import UIContext from './UIContext';

import type { RegistrationQueryResponse } from './__generated__/RegistrationQuery.graphql';

const PageContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  background: #f6f2ff;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 10px 0;
`;

const StyledEmptyMessage = styled(EmptyMessage)`
  margin-top: 120px;
  background-color: transparent;
`;

const query = graphql`
  query RegistrationQuery($eventSlug: String, $isEventRegForm: Boolean!, $includeRules: Boolean!) {
    event(slug: $eventSlug) @include(if: $isEventRegForm) {
      ...RegistrationHeader_event
      startDate
      name
      viewerCanViewRegistration
      registrationForm {
        id
      }
    }
    org {
      name
      viewerCanUpdate
      ...RegistrationHeader_org
    }
  }
`;

class Registration extends React.PureComponent<
  {
    match: Match,
    location: Location,
  },
  {
    discardCounter: number,
  },
> {
  state = { discardCounter: 0 };

  componentDidMount() {
    loadFonts(fonts.map(font => font.value));
  }

  handleDiscard = () => {
    this.setState(prevState => ({ discardCounter: prevState.discardCounter + 1 }));
  };

  render() {
    const { match, location } = this.props;
    const parsedQueryString: RegistrationUIPropsType = validateColorParams(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    );

    const UIProps = makeUIPropsInsensitive({
      ...registrationUIDefaultProps,
      ...parsedQueryString,
    });
    const eventSlug = match.params.eventSlug;
    return (
      <UIContext.Provider value={UIProps}>
        <PageContent>
          <Container>
            <DefaultQueryRenderer
              query={query}
              variables={{ eventSlug, isEventRegForm: !!eventSlug, includeRules: true }}
              renderSuccess={({ event, org }: RegistrationQueryResponse) => {
                if (!event) {
                  if (!org.viewerCanUpdate) {
                    return <NotAuthorized />;
                  }
                  return (
                    <DocumentTitle title={`Reg Form Template - ${org.name}`}>
                      <>
                        <RegistrationHeader org={org} location={location} event={null} />
                        <RegistrationRoutes pathPrefix={match.path} location={location} />
                      </>
                    </DocumentTitle>
                  );
                }

                if (!event.viewerCanViewRegistration) {
                  return <NotAuthorized />;
                }

                if (!event.registrationForm) {
                  return event.startDate ? (
                    <DocumentTitle title={event.name}>
                      <RegistrationRoutes pathPrefix={match.path} location={location} />
                    </DocumentTitle>
                  ) : (
                    <StyledEmptyMessage
                      icon={<NoEventStartDate />}
                      text="Add Event Dates to manage and share the Contact Form"
                    />
                  );
                }
                return (
                  <DocumentTitle title={event.name}>
                    <>
                      <RegistrationHeader
                        event={event}
                        org={org}
                        location={location}
                        onDiscard={this.handleDiscard}
                      />
                      <RegistrationRoutes
                        pathPrefix={match.path}
                        location={location}
                        discardCounter={this.state.discardCounter}
                      />
                    </>
                  </DocumentTitle>
                );
              }}
              renderLoading={() => {
                return (
                  <LoaderContainer>
                    <Loader color={UIProps.loaderColor} />
                  </LoaderContainer>
                );
              }}
            />
          </Container>
        </PageContent>
      </UIContext.Provider>
    );
  }
}

export default dragDropContext(HTML5Backend)(Registration);
