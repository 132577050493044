/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import RequestSubmissionStatus from 'components/EventRequestForm/RequestSubmissionList/columns/RequestSubmissionStatus';

import type { EventRequestsItemStatus_eventRequestSubmission } from './__generated__/EventRequestsItemStatus_eventRequestSubmission.graphql';

type Props = {
  eventRequestSubmission: EventRequestsItemStatus_eventRequestSubmission,
  readOnly?: boolean,
};

const EventRequestsItemStatus = ({ eventRequestSubmission, readOnly }: Props) => {
  return <RequestSubmissionStatus requestSubmission={eventRequestSubmission} readOnly={readOnly} />;
};

export default createFragmentContainer(
  EventRequestsItemStatus,
  graphql`
    fragment EventRequestsItemStatus_eventRequestSubmission on EventRequestSubmission {
      ...RequestSubmissionStatus_requestSubmission
    }
  `,
);
