/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';

import type { EventVenueStreet_event } from './__generated__/EventVenueStreet_event.graphql';

const EventVenueStreet = (props: { event: EventVenueStreet_event }) => {
  const primaryLocation = props.event.primaryLocation;
  if (!primaryLocation) return null;

  return (
    <InlineExpandableText>
      {[primaryLocation.address1, primaryLocation.address2].filter(Boolean).join(', ')}
    </InlineExpandableText>
  );
};

export default createFragmentContainer(
  EventVenueStreet,
  graphql`
    fragment EventVenueStreet_event on Event {
      primaryLocation {
        address1
        address2
      }
    }
  `,
);
