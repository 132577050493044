/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  background: linear-gradient(118deg, #faf8ff 0%, #e1d6ff 107.94%);

  @media (${props => props.theme.mobileOnly}) {
    background: #fff;
  }
`;

export default ({ children }: { children: React.Node }) => <Background>{children}</Background>;
