/**
 * @flow
 * @relayHash 01a30adb98b0327a3476d02fab5dba7d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SectionCustomizableType = "EVENT";
export type CreateCustomFieldSectionInput = {
  clientMutationId?: ?string,
  name: string,
  customizableType: SectionCustomizableType,
};
export type createCustomFieldSectionMutationVariables = {|
  input: CreateCustomFieldSectionInput
|};
export type createCustomFieldSectionMutationResponse = {|
  +createCustomFieldSection: ?{|
    +customFieldSectionEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +name: string,
        +order: number,
      |},
    |}
  |}
|};
export type createCustomFieldSectionMutation = {|
  variables: createCustomFieldSectionMutationVariables,
  response: createCustomFieldSectionMutationResponse,
|};
*/


/*
mutation createCustomFieldSectionMutation(
  $input: CreateCustomFieldSectionInput!
) {
  createCustomFieldSection(input: $input) {
    customFieldSectionEdge {
      __typename
      node {
        id
        name
        order
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateCustomFieldSectionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCustomFieldSection",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateCustomFieldSectionInput!"
      }
    ],
    "concreteType": "CreateCustomFieldSectionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customFieldSectionEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomFieldSectionTypeRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomFieldSectionType",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "order",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createCustomFieldSectionMutation",
  "id": null,
  "text": "mutation createCustomFieldSectionMutation(\n  $input: CreateCustomFieldSectionInput!\n) {\n  createCustomFieldSection(input: $input) {\n    customFieldSectionEdge {\n      __typename\n      node {\n        id\n        name\n        order\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createCustomFieldSectionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "createCustomFieldSectionMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '89d3f73b7a009d2600779c966f66654e';
module.exports = node;
