/* @flow */
import * as React from 'react';
import type { Location } from 'react-router-dom';

import { reportException } from 'utils/errorReporting';
import getEnvironment from 'utils/getEnvironment';

import ErrorView from 'components/ErrorView';

export type ErrorInfo = {
  componentStack: string,
};

type Props = {
  children: React.Node,
  location?: ?Location,
};

type State = {
  error: ?Error,
  componentStack: ?string,
  location?: ?Location,
};

export default class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.error && prevState.location !== nextProps.location) {
      return {
        error: null,
        componentStack: null,
        location: nextProps.location,
      };
    }
    return { location: nextProps.location };
  }

  state = {
    error: null,
    componentStack: null,
    // eslint-disable-next-line react/no-unused-state
    location: this.props.location,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({
      error,
      componentStack: info.componentStack,
    });

    reportException(error, info.componentStack);
  }

  render() {
    const isDev = getEnvironment() === 'development';

    if (!isDev && this.state.error) {
      return (
        <ErrorView
          title="Hmmm..."
          error={{ message: 'Something on this page experienced a problem.  Please try again.' }}
        />
      );
    }
    if (this.state.error) {
      return (
        <ErrorView
          error={this.state.error}
          errorStack={this.state.error.stack}
          componentStack={this.state.componentStack}
        />
      );
    }

    return this.props.children;
  }
}
