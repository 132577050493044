/**
 * @flow
 * @relayHash 64b4277fcb1a4191f7f71b55f7361b62
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamItem_team$ref = any;
export type TeamsPageQueryVariables = {||};
export type TeamsPageQueryResponse = {|
  +me: {|
    +teams: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +viewerCanUpdate: boolean,
          +$fragmentRefs: TeamItem_team$ref,
        |}
      |}>
    |}
  |}
|};
export type TeamsPageQuery = {|
  variables: TeamsPageQueryVariables,
  response: TeamsPageQueryResponse,
|};
*/


/*
query TeamsPageQuery {
  me {
    teams {
      edges {
        node {
          id
          viewerCanUpdate
          ...TeamItem_team
        }
      }
    }
    id
  }
}

fragment TeamItem_team on Team {
  id
  name
  slug
  viewerCanUpdate
  events {
    totalCount
  }
  members {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamsPageQuery",
  "id": null,
  "text": "query TeamsPageQuery {\n  me {\n    teams {\n      edges {\n        node {\n          id\n          viewerCanUpdate\n          ...TeamItem_team\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment TeamItem_team on Team {\n  id\n  name\n  slug\n  viewerCanUpdate\n  events {\n    totalCount\n  }\n  members {\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teams",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      {
                        "kind": "FragmentSpread",
                        "name": "TeamItem_team",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamsPageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teams",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "events",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EventRequiredConnection",
                        "plural": false,
                        "selections": v2
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "members",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TeamMemberConnection",
                        "plural": false,
                        "selections": v2
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '144219b886bbc9a06e89cdbefbc13bc6';
module.exports = node;
