/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { type Location, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import formatDateFilterParam from 'utils/date/formatDateFilterParam';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type ColumnConfiguration from 'components/material/table';
import withTableContainer from 'components/material/table/withTableContainer';

import colsConfig from './columns';
import EventRequestsHeaderBar from './EventRequestsHeaderBar';
import EventRequestsTableHeader from './EventRequestsTableHeader';
import EventRequestsTablePagination from './EventRequestsTablePagination';
import parseEventRequestsItemFilters from './lib/parseEventRequestsItemFilters';

import type { EventRequestsContainerQueryResponse } from './__generated__/EventRequestsContainerQuery.graphql';

const RequestsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 25px;
`;

const RequestsTableContainerWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  border: 1px solid ${props => props.theme.primaryRowColor};
  border-top: none;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const RequestsTableContainer = styled.div`
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const query = graphql`
  query EventRequestsContainerQuery(
    $count: Int!
    $cursor: String
    $sort: EventRequestFormSubmissionSort
    $direction: Direction
    $requesterUserIds: [ID!]
    $requesterContactIds: [ID!]
    $requestedDate: [DateTime!]
    $statuses: [EventRequestSubmissionStatus!]
    $requestFormIds: [ID!]
    $requestReviewers: [ID!]
  ) {
    org {
      ...EventRequestsTablePagination_org
    }
    me {
      tz
    }
  }
`;

class EventRequestsContainer extends React.Component<{
  location: Location,
  history: RouterHistory,
  onColumnSizeChange: (value: string, width: number) => void,
  shownColsConfig: ColumnConfiguration,
  containerWidth: number,
  viewerCanUpdate: boolean,
}> {
  scrollContainer: ?HTMLElement;

  render() {
    const filters = parseEventRequestsItemFilters(this.props.location.search);
    const filtered = Object.values(filters).some(val => val != null);

    const { sort, requestedDate, ...restFilters } = filters;

    const requestDateParam = formatDateFilterParam(requestedDate);

    // Default sort if none specified
    const sortFilter = sort || {
      key: 'REQUESTED_DATE',
      asc: false,
    };

    const variables = {
      count: 25,
      sort: sortFilter.key,
      direction: sortFilter.asc ? 'ASC' : 'DESC',
      requestedDate: requestDateParam,
      ...restFilters,
    };

    return (
      <RequestsContainer>
        <Title>Event Requests</Title>
        <EventRequestsHeaderBar
          filters={filters}
          history={this.props.history}
          location={this.props.location}
          viewerCanUpdate={this.props.viewerCanUpdate}
        />
        <RequestsTableContainerWrapper>
          <RequestsTableContainer
            ref={el => {
              this.scrollContainer = el;
            }}
          >
            <EventRequestsTableHeader
              filters={filters}
              sort={sortFilter}
              history={this.props.history}
              shownColumns={this.props.shownColsConfig}
              width={this.props.containerWidth}
            />
            <DefaultQueryRenderer
              query={query}
              variables={variables}
              renderSuccess={(props: EventRequestsContainerQueryResponse) => (
                <EventRequestsTablePagination
                  org={props.org}
                  tz={props.me.tz}
                  filtered={filtered}
                  history={this.props.history}
                  location={this.props.location}
                  shownColumns={this.props.shownColsConfig}
                  width={this.props.containerWidth}
                  scrollContainer={this.scrollContainer}
                  onColumnSizeChange={this.props.onColumnSizeChange}
                />
              )}
            />
          </RequestsTableContainer>
        </RequestsTableContainerWrapper>
      </RequestsContainer>
    );
  }
}

export default withTableContainer(EventRequestsContainer, colsConfig);
