/* @flow */
import type { FieldType } from 'utils/customization/types';
import parseTypedQueryString, {
  type DateRangeParam,
  type CustomFieldParam,
  type SortParam,
  type NumberRangeParam,
  stringParamToStringArray,
  stringParamToDateRange,
  stringParamToString,
  stringParamToSort,
  stringParamToNumberRange,
} from 'utils/routing/parseTypedQueryString';
import getCustomFieldQueryParamsByKind from 'utils/filters/getCustomFieldQueryParamsByKind';

export type ParsedCompaniesFilters = {
  [key: string]: CustomFieldParam,

  created_at: ?DateRangeParam,
  created_by: ?$ReadOnlyArray<string>,
  updated_at: ?DateRangeParam,
  updated_by: ?$ReadOnlyArray<string>,
  city: ?$ReadOnlyArray<string>,
  state: ?$ReadOnlyArray<string>,
  country: ?$ReadOnlyArray<string>,
  sort: ?SortParam,
  search: ?string,
  contacts_count: ?NumberRangeParam,
};

export default function parseCompaniesFilters(
  queryString: string,
  filterOptions: $ReadOnlyArray<FieldType>,
): ParsedCompaniesFilters {
  return parseTypedQueryString(queryString, {
    ...filterOptions.reduce(
      (customFilters, filter) =>
        filter.kind !== 'DEFAULT'
          ? { ...customFilters, ...getCustomFieldQueryParamsByKind(filter) }
          : customFilters,
      {},
    ),

    created_at: stringParamToDateRange,
    created_by: stringParamToStringArray,
    updated_at: stringParamToDateRange,
    updated_by: stringParamToStringArray,
    city: stringParamToStringArray,
    state: stringParamToStringArray,
    country: stringParamToStringArray,
    sort: stringParamToSort,
    search: stringParamToString,
    contacts_count: stringParamToNumberRange,
  });
}
