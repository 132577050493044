import './styles.styl';

import PropTypes from 'prop-types';

import React from 'react';

import classNames from 'classnames';

import { Avatar } from 'components/Avatar';
import BasicDropdown from 'components/form/BasicDropdown';

export class FiltersItem extends React.Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
      user: PropTypes.object,
      onClick: PropTypes.func,
    })).isRequired,
    onSelect: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    searchable: PropTypes.bool,
    less: PropTypes.bool,
    disableReset: PropTypes.bool,
  }
  static defaultProps = {
    filters: {},
    onSelect: () => {},
    onReset: () => {},
  }
  render() {
    const { items, text, filters, name, onSelect, onReset, searchable, less } = this.props;
    const value = filters[name];

    return (
      <BasicDropdown
        label={text}
        items={items}
        itemComponent={item => (
          <li onClick={item.onClick}>
            {item.user && <Avatar size={23} user={item.user} />}
            <span className={item.className}>{item.text}</span>
          </li>
        )}
        selectedComponent={active => {
          const selected = items.find(item => item.text === active);
          return (
            <span>
              {selected && selected.user && <Avatar size={23} user={selected.user} />}
              <span className={selected && selected.className}>{active}</span>
              {!this.props.disableReset && (
                <div
                  className="filters-item-reset"
                  onClick={() => onReset(name)}
                >x</div>
              )}
            </span>
          );
        }}
        className={classNames(
          'filters-item',
          {
            active: filters[name] != null,
            'less-1150': less,
          },
        )}
        value={value}
        onChange={newValue => onSelect(name, newValue)}
        searchable={searchable}
      />
    );
  }
}
