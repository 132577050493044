/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import { type CellPropsType } from 'components/budget/Table';
import InlineEditableCell from 'components/budget/Table/InlineEditableCell';
import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import { type PaymentCellPropsType } from './index';

import { type PaymentNoteCell_event } from './__generated__/PaymentNoteCell_event.graphql';
import { type PaymentNoteCell_payment } from './__generated__/PaymentNoteCell_payment.graphql';

const PaymentNoteCell = ({
  payment,
  event,
  updatePayment,
  updateColumnWidth,
}: CellPropsType<PaymentCellPropsType<PaymentNoteCell_payment, PaymentNoteCell_event>>) => {
  const isMultiline = (payment.note || '').includes('\n');

  if (!isMultiline) {
    return (
      <InlineTextField
        placeholder="Add Note"
        value={payment.note || ''}
        onChange={note => {
          updatePayment({ note });

          updateColumnWidth();
        }}
      >
        {payment.note && <InlineExpandableText>{payment.note || ''}</InlineExpandableText>}
      </InlineTextField>
    );
  }

  return (
    <InlineEditableCell>
      {({ editing }) => {
        if (editing) {
          return (
            <Redirect
              push
              to={{
                pathname: `/events/${event.slug}/budget/${payment.expense.id}`,
                hash: 'payments',
                state: { referrer: window.location.href },
              }}
            />
          );
        }

        return <InlineExpandableText>{payment.note || ''}</InlineExpandableText>;
      }}
    </InlineEditableCell>
  );
};

export default createFragmentContainer(
  PaymentNoteCell,
  graphql`
    fragment PaymentNoteCell_payment on Payment {
      note

      expense {
        id
      }
    }

    fragment PaymentNoteCell_event on Event {
      slug
    }
  `,
);
