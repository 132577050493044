/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type updateContactFormBccEmailMutationVariables } from './__generated__/updateContactFormBccEmailMutation.graphql';

const mutation = graphql`
  mutation updateContactFormBccEmailMutation($input: UpdateContactFormBccEmailInput!) {
    updateContactFormBccEmail(input: $input) {
      contactFormBccEmail {
        id
        email
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateContactFormBccEmail(
  input: $Exact<$PropertyType<updateContactFormBccEmailMutationVariables, 'input'>>,
) {
  const { contactFormBccEmailId, ...rest } = input;
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    optimisticResponse: {
      updateContactFormBccEmail: {
        contactForm: { id: contactFormBccEmailId, ...rest },
      },
    },
  });
}
