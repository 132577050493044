/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #677386;

  > svg {
    margin-bottom: 20px;
  }
`;

type Props = { message: string, icon: React.Node, className?: string };

const EmptyView = ({ message, icon, className }: Props) => (
  <Root className={className}>
    {icon}
    <div>{message}</div>
  </Root>
);

export default EmptyView;
