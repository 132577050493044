/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type {
  createBudgetPeriodMutationVariables,
  createBudgetPeriodMutationResponse,
} from './__generated__/createBudgetPeriodMutation.graphql';

const mutation = graphql`
  mutation createBudgetPeriodMutation($input: CreateBudgetPeriodInput!) {
    createBudgetPeriod(input: $input) {
      budgetPeriodEdge {
        __typename
        node {
          id
          period
          year
          ...TeamBudgeting_budgetPeriod
        }
      }
    }
  }
`;

export default function createBudgetPeriod({ orgId, year }: { orgId: string, year: number }) {
  const variables: createBudgetPeriodMutationVariables = {
    input: {
      year,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: orgId,
      edgeName: 'budgetPeriodEdge',
      connections: [{ field: 'budgetPeriods' }],
    }),
    optimisticResponse: {
      createBudgetPeriod: {
        budgetPeriodEdge: {
          node: { period: `Q1 ${year}`, year },
        },
      },
    },
  }).then((response: createBudgetPeriodMutationResponse) => {
    if (response.createBudgetPeriod && response.createBudgetPeriod.budgetPeriodEdge.node) {
      return response.createBudgetPeriod.budgetPeriodEdge.node;
    }
    throw new Error('No period');
  });
}
