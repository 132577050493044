/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationColorSelector_orgSettings$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BackgroundStyling_orgSettings$ref: FragmentReference;
export type BackgroundStyling_orgSettings = {|
  +id: string,
  +$fragmentRefs: RegistrationColorSelector_orgSettings$ref,
  +$refType: BackgroundStyling_orgSettings$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BackgroundStyling_orgSettings",
  "type": "OrgSettings",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationColorSelector_orgSettings",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '65cf22d488b416e49b58c7c503e339d6';
module.exports = node;
