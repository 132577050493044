/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  color: ${props =>
    props.disabled ? props.theme.mutedTextColor : props.theme.rowPrimaryTextColor};
  padding: 5px 10px;
  display: flex;
  align-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  width: 100%;
  ${props => props.focused && `background: ${props.theme.hoverRowColor};`};
  outline: none;
`;

const Labels = styled.div`
  flex: 1 1 auto;
`;

const Label = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
`;

const SubLabel = styled.div`
  color: ${props => props.theme.infoMenuColor};
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Icon = styled.i`
  color: ${props => props.theme.primaryActionColor};
  font-size: 12px;
  margin-left: 10px;
`;

export default class SelectFieldRow<Value> extends React.PureComponent<{
  label: string,
  displayLabel?: React.Node,
  info?: ?string,
  value: Value,
  onSelect: Value => void,
  onHover: number => void,
  focused?: boolean,
  index: number,
  className?: string,
  icon?: ?string,
  disabled?: boolean,
}> {
  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleSelect = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    if (this.props.disabled) return;
    this.props.onSelect(this.props.value);
  };

  handleHover = () => {
    if (this.props.disabled) return;
    this.props.onHover(this.props.index);
  };

  render() {
    return (
      <Row
        onClick={this.handleSelect}
        tabIndex={-1}
        onFocus={this.stopPropagation}
        focused={this.props.focused}
        onMouseEnter={this.handleHover}
        className={this.props.className}
        disabled={this.props.disabled}
      >
        <Labels>
          {this.props.displayLabel != null ? (
            this.props.displayLabel
          ) : (
            <Label>{this.props.label}</Label>
          )}
          {this.props.info && <SubLabel>{this.props.info}</SubLabel>}
        </Labels>
        {this.props.icon && <Icon className={`fa fa-fw fa-${this.props.icon}`} />}
      </Row>
    );
  }
}
