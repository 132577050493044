/**
 * @flow
 * @relayHash af927d937f1dd7669b0698b1eb347a2d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
export type MarketoMappingResourceType = "COMPANY" | "CONTACT" | "EVENTCONTACT";
export type MarketoLeadMappingsQueryVariables = {|
  resourceType: MarketoMappingResourceType,
  customizableType: $ReadOnlyArray<CustomizableType>,
|};
export type MarketoLeadMappingsQueryResponse = {|
  +org: {|
    +id: string,
    +syncedToIbmWm: boolean,
    +marketoAccount: ?{|
      +id: string,
      +marketoFields: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +displayName: string,
            +dataType: string,
            +name: string,
            +readOnly: boolean,
          |}
        |}>
      |},
      +marketoFieldMappings: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +marketoField: string,
            +resourceType: MarketoMappingResourceType,
            +resourceFieldName: ?string,
            +resourceCustomField: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |},
    +customFields: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +kind: CustomFieldKindType,
          +order: number,
          +label: string,
          +required: boolean,
          +fieldName: ?string,
        |}
      |}>
    |},
  |}
|};
export type MarketoLeadMappingsQuery = {|
  variables: MarketoLeadMappingsQueryVariables,
  response: MarketoLeadMappingsQueryResponse,
|};
*/


/*
query MarketoLeadMappingsQuery(
  $resourceType: MarketoMappingResourceType!
  $customizableType: [CustomizableType!]!
) {
  org {
    id
    syncedToIbmWm
    marketoAccount {
      id
      marketoFields(resourceType: $resourceType) {
        edges {
          node {
            displayName
            dataType
            name
            readOnly
          }
        }
      }
      marketoFieldMappings(resourceType: $resourceType) {
        edges {
          node {
            id
            marketoField
            resourceType
            resourceFieldName
            resourceCustomField {
              id
            }
          }
        }
      }
    }
    customFields(customizableType: $customizableType) {
      edges {
        node {
          id
          kind
          order
          label
          required
          fieldName
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "resourceType",
    "type": "MarketoMappingResourceType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "customizableType",
    "type": "[CustomizableType!]!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "resourceType",
    "variableName": "resourceType",
    "type": "MarketoMappingResourceType!"
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "org",
    "storageKey": null,
    "args": null,
    "concreteType": "Org",
    "plural": false,
    "selections": [
      v1,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "syncedToIbmWm",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "marketoAccount",
        "storageKey": null,
        "args": null,
        "concreteType": "MarketoAccount",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketoFields",
            "storageKey": null,
            "args": v2,
            "concreteType": "MarketoFieldRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "MarketoFieldRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MarketoField",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "displayName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dataType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "readOnly",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketoFieldMappings",
            "storageKey": null,
            "args": v2,
            "concreteType": "MarketoFieldMappingRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "MarketoFieldMappingRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MarketoFieldMapping",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "marketoField",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "resourceType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "resourceFieldName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "resourceCustomField",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldType",
                        "plural": false,
                        "selections": [
                          v1
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customFields",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "customizableType",
            "variableName": "customizableType",
            "type": "[CustomizableType!]!"
          }
        ],
        "concreteType": "CustomFieldTypeRequiredConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomFieldTypeRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldType",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "kind",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "order",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "label",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "required",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fieldName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MarketoLeadMappingsQuery",
  "id": null,
  "text": "query MarketoLeadMappingsQuery(\n  $resourceType: MarketoMappingResourceType!\n  $customizableType: [CustomizableType!]!\n) {\n  org {\n    id\n    syncedToIbmWm\n    marketoAccount {\n      id\n      marketoFields(resourceType: $resourceType) {\n        edges {\n          node {\n            displayName\n            dataType\n            name\n            readOnly\n          }\n        }\n      }\n      marketoFieldMappings(resourceType: $resourceType) {\n        edges {\n          node {\n            id\n            marketoField\n            resourceType\n            resourceFieldName\n            resourceCustomField {\n              id\n            }\n          }\n        }\n      }\n    }\n    customFields(customizableType: $customizableType) {\n      edges {\n        node {\n          id\n          kind\n          order\n          label\n          required\n          fieldName\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MarketoLeadMappingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v3
  },
  "operation": {
    "kind": "Operation",
    "name": "MarketoLeadMappingsQuery",
    "argumentDefinitions": v0,
    "selections": v3
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd147d220dfe2f33275c6617e5ad4fe02';
module.exports = node;
