/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Loader from 'components/Loader';

const LoadingRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  background: ${props => props.theme.secondaryRowColor};
`;

export default class InfiniteScrollLoading extends React.PureComponent<{
  className?: ?string,
  loading: boolean,
}> {
  render() {
    const className = this.props.className;

    return (
      <LoadingRow className={className}>{this.props.loading && <Loader size={20} />}</LoadingRow>
    );
  }
}
