/* @flow */
import * as React from 'react';
import {
  type ConnectDragPreview,
  type ConnectDragSource,
  type DragSourceConnector,
  type DragSourceMonitor,
  DragSource,
} from 'react-dnd';
import ClickOut from 'react-onclickout';
import styled, { css } from 'styled-components';

import DragIcon from 'images/drag.svg';

import type {
  PageComponentKindType,
  ReorderedPageComponentConfigType,
} from '../pageComponentsConfig';
import { Container as FrameContainer, Header as FrameHeader } from './components/Frame';

type DragablePageComponentType = {
  id: string,
  order: number,
  kind: PageComponentKindType,
};

const DragIconWrapper = styled.div`
  position: absolute;
  display: block;
  top: 9px;
  left: 8px;
  z-index: 1;
  color: #868f96;
  opacity: ${props => (props.transparentIcon ? 0 : 1)};
  svg {
    display: block;
    cursor: grab;
    width: 8px;
    height: 14px;
    margin: 10px;
    color: #4a5665;
    &:hover {
      color: #3e4858;
    }
  }
  ${props =>
    props.disableDrag &&
    css`
      display: none;
    `}
`;

const Container = styled.div`
  position: relative;
  ${props =>
    props.preview &&
    css`
      max-width: 308px;
      border-radius: 5px;
    `}

  &:hover {
    ${props =>
      !props.draggingState &&
      css`
        & > div > ${DragIconWrapper} {
          opacity: ${props.preview ? 0 : 1};
        }
        ${!props.preview &&
        css`
          ${FrameContainer} {
            margin: 5px 0;
            border: 1px dashed #5db5d5;
            border-image: url('/images/component_hover_border.png') 1 round;
          }
          ${FrameHeader} {
            height: 40px;
            opacity: 1;
          }
        `}
      `}
  }
`;

class DraggablePageComponent extends React.PureComponent<
  {
    children: React.Node,
    connectDragSource?: ConnectDragSource,
    connectDragPreview?: ConnectDragPreview,
    selectedComponentId: ?string,
    draggingState: boolean,
    dragPreviewInit?: boolean,
    isOptimisticChild?: boolean,
    onUnselectComponent: () => void,
    /* eslint-disable react/no-unused-prop-types */
    // Used in Hover handler
    pageComponent: DragablePageComponentType,
    onBeginDrag: (pageComponentId: string) => void,
    onMoveEnd: (reorderedPageComponentConfig?: ReorderedPageComponentConfigType) => void,
  },
  { isSetMovingSection: boolean },
> {
  hasRelatedClasses = (el: HTMLElement) => {
    return ['component-styling', 'fsp-picker', 'tox-form'].some(currentClass =>
      el.classList.contains(currentClass),
    );
  };

  ignoreClickOut = (el: HTMLElement, selectedComponentId: ?string) => {
    if (el.id === selectedComponentId || this.hasRelatedClasses(el)) {
      return true;
    }
    if (
      !el.parentElement ||
      // $FlowFixMe
      !(el.parentElement instanceof HTMLElement || el.parentElement instanceof SVGElement)
    ) {
      return false;
    }
    return this.ignoreClickOut(el.parentElement, selectedComponentId);
  };

  handleClickOut = (e: SyntheticMouseEvent<HTMLElement> | SyntheticEvent<HTMLElement>) => {
    const { selectedComponentId, onUnselectComponent } = this.props;

    if (
      window.getSelection().type === 'Range' ||
      // $FlowFixMe
      ((e.target instanceof HTMLElement || e.target instanceof SVGElement) &&
        this.ignoreClickOut(e.target, selectedComponentId))
    ) {
      return;
    }
    onUnselectComponent();
  };

  renderView = (): React.Node => {
    const {
      connectDragSource,
      connectDragPreview,
      selectedComponentId,
      draggingState,
      children,
      dragPreviewInit,
      pageComponent,
      isOptimisticChild,
    } = this.props;
    if (!connectDragSource || !connectDragPreview) return null;
    const componentSelected = pageComponent.id === selectedComponentId;
    const columnComponent = pageComponent.kind === 'COLUMN';
    const style = dragPreviewInit
      ? {
          maxWidth: '308px',
          borderRadius: '5px !important',
        }
      : {};
    return (
      <>
        {connectDragPreview(
          <div style={style}>
            <ClickOut onClickOut={this.handleClickOut}>
              <Container
                preview={dragPreviewInit}
                draggingState={draggingState}
                id={pageComponent.id}
              >
                {children}
                {!columnComponent &&
                  connectDragSource(
                    <div>
                      <DragIconWrapper
                        transparentIcon={!componentSelected || dragPreviewInit}
                        disableDrag={isOptimisticChild}
                      >
                        <DragIcon />
                      </DragIconWrapper>
                    </div>,
                  )}
              </Container>
            </ClickOut>
          </div>,
        )}
      </>
    );
  };

  render() {
    return this.renderView();
  }
}

const ConnectedDraggableComponent = DragSource(
  'REGISTRATION_PAGE_COMPONENT',
  {
    beginDrag(props: $PropertyType<DraggablePageComponent, 'props'>) {
      props.onBeginDrag(props.pageComponent.id);
      return { ...props.pageComponent };
    },
    endDrag(props: $PropertyType<DraggablePageComponent, 'props'>, monitor: DragSourceMonitor) {
      const dropAreaConfig = monitor.getDropResult();
      props.onMoveEnd(
        dropAreaConfig && {
          parentId: dropAreaConfig.parentId,
          order: dropAreaConfig.order,
          kind: props.pageComponent.kind,
          id: props.pageComponent.id,
        },
      );
    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
)(DraggablePageComponent);

export default ConnectedDraggableComponent;
