/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import { type SortParam } from 'utils/routing/parseTypedQueryString';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import ExpensesTableRenderer from './ExpensesTableRenderer';

import { type ExpensesTableQueryResponse } from './__generated__/ExpensesTableQuery.graphql';

const query = graphql`
  query ExpensesTableQuery(
    $eventSlug: String!
    $sort: ExpenseSort
    $direction: Direction
    $query: String
  ) {
    event(slug: $eventSlug) {
      ...ExpensesTableRenderer_event

      expenses(first: 1000, query: $query, sort: $sort, direction: $direction)
        @connection(key: "ExpensesTable_expenses", filters: []) {
        edges {
          node {
            ...ExpensesTableRenderer_expenses
          }
        }
      }
    }

    org {
      ...ExpensesTableRenderer_org
    }
  }
`;

export default class ExpensesTable extends React.Component<{
  eventSlug: string,
  search: string,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  onChangeDataCount: (count: number) => void,
}> {
  static defaultProps = {
    sort: { key: 'NAME', asc: true },
  };

  renderTable = (response?: ExpensesTableQueryResponse) => {
    const { event, org } = response || {};

    const expenses = event ? event.expenses.edges.map(edge => edge.node) : null;

    return (
      <ExpensesTableRenderer
        sort={this.props.sort}
        onChangeSort={this.props.onChangeSort}
        event={event || null}
        org={org || null}
        expenses={expenses}
        onChangeDataCount={this.props.onChangeDataCount}
      />
    );
  };

  render() {
    const { eventSlug, sort, search } = this.props;

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          eventSlug,
          sort: sort.key,
          direction: sort.asc ? 'ASC' : 'DESC',
          query: search,
        }}
        renderSuccess={this.renderTable}
        renderLoading={this.renderTable}
      />
    );
  }
}
