/* @flow */
import styled, { css } from 'styled-components';

const Tag = styled.div`
  display: inline-block;
  padding: ${props => (props.compact ? '0 5px' : '2px 18px')};
  font-size: ${props => (props.compact ? '11px' : '13px')};
  vertical-align: middle;
  border: 1px solid #bcc0c3;
  border-radius: 15px;
  color: #bcc0c3;
  text-transform: capitalize;
  &:not(:last-child) {
    margin-right: 25px;
  }
  ${props =>
    props.active &&
    css`
      border: ${props.compact ? 1 : 2}px solid ${props.color || '#c32a80'};
      color: ${props.color || '#c32a80'};
      margin: -1px;
      &:not(:last-child) {
        margin-right: 24px;
      }
    `};
  ${props => !props.compact && !props.readOnly && 'cursor: pointer;'};
`;

export default Tag;
