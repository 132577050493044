/* @flow */
import EventInfoCustomBoolean from './custom/EventInfoCustomBoolean';
import EventInfoCustomCurrency from './custom/EventInfoCustomCurrency';
import EventInfoCustomDate from './custom/EventInfoCustomDate';
import EventInfoCustomLink from './custom/EventInfoCustomLink';
import EventInfoCustomMultiselect from './custom/EventInfoCustomMultiselect';
import EventInfoCustomNumber from './custom/EventInfoCustomNumber';
import EventInfoCustomSelect from './custom/EventInfoCustomSelect';
import EventInfoCustomText from './custom/EventInfoCustomText';
import EventInfoCustomTextarea from './custom/EventInfoCustomTextarea';
import EventInfoCustomUserMultiselect from './custom/EventInfoCustomUserMultiselect';
import EventInfoCustomUserSelect from './custom/EventInfoCustomUserSelect';
import EventInfoActualBudget from './EventInfoActualBudget';
import EventInfoAmountOfOpportunities from './EventInfoAmountOfOpportunities';
import EventInfoBooth from './EventInfoBooth';
import EventInfoDescription from './EventInfoDescription';
import EventInfoDimensions from './EventInfoDimensions';
import EventInfoFloorPlan from './EventInfoFloorPlan';
import EventInfoLastSynced from './EventInfoLastSynced';
import EventInfoLeader from './EventInfoLeader';
import EventInfoMarketoId from './EventInfoMarketoId';
import EventInfoNumberOfOpportunities from './EventInfoNumberOfOpportunities';
import EventInfoPlannedBudget from './EventInfoPlannedBudget';
import EventInfoProgress from './EventInfoProgress';
import EventInfoSalesforceId from './EventInfoSalesforceId';
import EventInfoStatus from './EventInfoStatus';
import EventInfoSyncStatus from './EventInfoSyncStatus';

export const fieldLabelMap = {
  STATUS: 'status',
  PROGRESS: 'progress',
  DESCRIPTION: 'description',
  FLOOR_PLAN: 'floor plan',
  LEADER: 'event leader',
  DIMENSIONS: 'booth dimensions',
  BOOTH_NUMBER: 'booth #',
  LAST_SYNCED: 'crm last synced',
  SALESFORCE_ID: 'crm campaign id',
  MARKETO_ID: 'marketo program id',
  NUMBER_OF_OPPORTUNITIES: 'Opportunities',
  AMOUNT_ALL_OPPORTUNITIES: 'Amount of Opportunities',
  SYNC_STATUS: 'crm sync status',
  PLANNED_COST: 'planned budget',
  ACTUAL_COST: 'actual budget',
};

export const fieldsMap = {
  STATUS: EventInfoStatus,
  PROGRESS: EventInfoProgress,
  DESCRIPTION: EventInfoDescription,
  FLOOR_PLAN: EventInfoFloorPlan,
  LEADER: EventInfoLeader,
  DIMENSIONS: EventInfoDimensions,
  BOOTH_NUMBER: EventInfoBooth,
  LAST_SYNCED: EventInfoLastSynced,
  SALESFORCE_ID: EventInfoSalesforceId,
  MARKETO_ID: EventInfoMarketoId,
  NUMBER_OF_OPPORTUNITIES: EventInfoNumberOfOpportunities,
  AMOUNT_ALL_OPPORTUNITIES: EventInfoAmountOfOpportunities,
  SYNC_STATUS: EventInfoSyncStatus,
  PLANNED_COST: EventInfoPlannedBudget,
  ACTUAL_COST: EventInfoActualBudget,
};

export const customFieldsMap = {
  DEFAULT: null,
  TEXT: EventInfoCustomText,
  TEXTAREA: EventInfoCustomTextarea,
  LINK: EventInfoCustomLink,
  NUMBER: EventInfoCustomNumber,
  CURRENCY: EventInfoCustomCurrency,
  DATE: EventInfoCustomDate,
  BOOLEAN: EventInfoCustomBoolean,
  USER_SELECT: EventInfoCustomUserSelect,
  USER_MULTISELECT: EventInfoCustomUserMultiselect,
  SELECT: EventInfoCustomSelect,
  MULTISELECT: EventInfoCustomMultiselect,
};
