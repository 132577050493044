/* @flow */
import escapeRegExp from 'lodash/escapeRegExp';

import { type FieldsType } from 'components/ImportWindow';

type OrgType = {
  +salesforceAccount: ?{|
    +id: string,
  |},
};

export const regExpFromLabel = (label: string): RegExp =>
  RegExp(escapeRegExp(label.toLowerCase().replace(/[^\w+]/g, '')));

const fields = (org: OrgType) => {
  const allFields = {
    team: { label: 'Team Name', guess: /team/ },
    name: { label: 'Event Name', guess: /^(event)?name/ },
    startDate: { label: 'Start Date', guess: /startdate/ },
    startTime: { label: 'Start Time', guess: /starttime/ },
    endDate: { label: 'End Date', guess: /enddate/ },
    endTime: { label: 'End Time', guess: /endtime/ },
    status: { label: 'Status', guess: /status/ },
    description: { label: 'Description', guess: /description/ },
    website: { label: 'Website', guess: /site|url|link/ },
    lead: { label: 'Leader', guess: /leader/ },
    locationName: { label: 'Venue Name', guess: /venue|locationname/ },
    locationStreet: { label: 'Street Address', guess: /street/ },
    locationCity: { label: 'City', guess: /city/ },
    locationState: { label: 'State/District', guess: /state|district/ },
    locationPostal: { label: 'Zip/Postal Code', guess: /zip|postal/ },
    locationCountry: { label: 'Country', guess: /country/ },
    virtualLocation: { label: 'Virtual Location', guess: /virtual|virtual location/ },
    location: { label: 'Location', guess: /address|location/ },
    booth: { label: 'Booth #', guess: /booth/ },
    boothDimensions: { label: 'Booth Dimensions', guess: /dimensions/ },
    floorPlan: { label: 'Floor Plan', guess: /floorplan/ },
    totalBudgetedAmount: {
      label: 'Planned Total',
      guess: /planned|budget|cost|expense|amount/,
    },
    totalActualAmount: {
      label: 'Actual Total',
      guess: /actual|budget|cost|expense|amount/,
    },
    notes: { label: 'Note', guess: /note/, multiple: true },
    salesforceId: org.salesforceAccount
      ? { label: 'CRM Campaign ID', guess: /salesforceid/ }
      : null,
  };

  return (Object.keys(allFields).reduce(
    (obj, key) => ({ ...obj, ...(allFields[key] ? { [key]: allFields[key] } : null) }),
    {},
  ): FieldsType<$Keys<typeof allFields>>);
};

export default fields;
