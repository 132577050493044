/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import syncStatuses from 'config/syncStatuses.json';

import ApproveIcon from 'images/approve.svg';
import MarketoIcon from 'images/integrations/marketo.svg';
import SalesforceIcon from 'images/integrations/salesforce.svg';
import DisabledIcon from 'images/syncIcons/disabledIcon.svg';
import FailedIcon from 'images/syncIcons/failedIcon.svg';
import PendingIcon from 'images/syncIcons/pendingIcon.svg';
import SyncedIcon from 'images/syncIcons/syncedIcon.svg';

import canSyncToMarketo, { type MarketoContactType } from './lib/canSyncToMarketo';
import canSyncToSalesforce, { type SalesforceContactType } from './lib/canSyncToSalesforce';

const Container = styled.div`
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 13px;
  background: #f3fafd;
  color: #4a5665;

  ${props =>
    (props.syncFailed || !props.syncEnabled) &&
    css`
      background: rgba(244, 129, 131, 0.07);
    `}

  ${props =>
    !props.syncEnabled &&
    !props.syncFailed &&
    css`
      color: rgba(116, 127, 141, 0.9);
    `}
`;

const Link = styled.a`
  color: #3ba9da;

  &:hover {
    text-decoration: underline;
  }
`;

const Columns = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Column = styled.div`
  &:not(:last-child) {
    width: 245px;
    margin-right: 20px;
  }
`;

const Row = styled.div`
  flex: 1;

  ${props =>
    props.centered &&
    css`
      text-align: center;
    `}
`;

const Icon = styled.span`
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const SyncedAsType = styled.strong`
  text-transform: capitalize;
`;

export type StateType = 'OK' | 'FAILING' | 'PENDING' | 'DISABLED';

const statusIcons = {
  OK: <SyncedIcon />,
  FAILING: <FailedIcon />,
  PENDING: <PendingIcon />,
  DISABLED: <DisabledIcon />,
};

export default class ContactSyncMessage extends React.PureComponent<{
  contact: SalesforceContactType & MarketoContactType,
  syncStatus?: {
    +lastSuccessAt: ?string,
    +state: StateType,
    +errorMessage: ?string,
  },
  salesforceId?: ?string,
  salesforceHost?: ?string,
  marketoId?: ?string,
  marketoHost?: ?string,
  salesforceSyncEnabled: boolean,
  marketoSyncEnabled: boolean,
  className?: string,
}> {
  renderSyncIntegration() {
    const { marketoSyncEnabled } = this.props;

    return (
      <>
        Syncing to:{' '}
        {marketoSyncEnabled ? (
          <Icon style={{ color: '#563E94' }}>
            <MarketoIcon />
          </Icon>
        ) : (
          <Icon>
            <SalesforceIcon />
          </Icon>
        )}
        <strong>{marketoSyncEnabled ? 'Marketo' : 'Salesforce'}</strong>
      </>
    );
  }

  renderSyncStatus(syncEnabled: boolean) {
    const { syncStatus } = this.props;

    return syncStatus ? (
      <>
        Sync Status: <Icon>{statusIcons[syncEnabled ? syncStatus.state : 'DISABLED']}</Icon>
        <strong>{syncStatuses[syncStatus.state]}</strong>
      </>
    ) : null;
  }

  renderSyncedAs = (contact: SalesforceContactType) => {
    return (
      <>
        Synced as: <SyncedAsType>{contact.salesforceSyncAs || 'contact'}</SyncedAsType>
      </>
    );
  };

  renderSyncedId() {
    const { salesforceId, salesforceHost, marketoId, marketoHost, marketoSyncEnabled } = this.props;

    return (
      <>
        {marketoSyncEnabled ? 'Marketo' : 'Salesforce'} ID:{' '}
        <Link
          href={
            marketoSyncEnabled
              ? `https://${marketoHost || ''}/leadDatabase/loadLeadDetail?leadId=${marketoId || ''}`
              : `https://${salesforceHost || ''}/${salesforceId || ''}`
          }
          target="_blank"
          rel="noreferrer noopener"
        >
          <strong>{marketoSyncEnabled ? marketoId : salesforceId}</strong>
        </Link>
      </>
    );
  }

  render() {
    const {
      syncStatus,
      contact,
      salesforceId,
      salesforceHost,
      marketoId,
      marketoHost,
      marketoSyncEnabled,
      salesforceSyncEnabled,
    } = this.props;

    if (!salesforceSyncEnabled && !marketoSyncEnabled) return null;
    const { syncEnabled, missingFields, invalidFields } = marketoSyncEnabled
      ? canSyncToMarketo(contact)
      : canSyncToSalesforce(contact);

    return (
      <Container
        className={this.props.className}
        syncEnabled={syncEnabled}
        syncFailed={syncStatus && syncStatus.state === 'FAILING'}
      >
        {syncStatus && (
          <>
            <Columns>
              <Column>{this.renderSyncIntegration()}</Column>

              <Column>{this.renderSyncStatus(syncEnabled)}</Column>

              {salesforceSyncEnabled && <Column>{this.renderSyncedAs(contact)}</Column>}
            </Columns>

            {(salesforceId || marketoId) && (
              <Columns>
                {((salesforceId && salesforceHost) || (marketoId && marketoHost)) && (
                  <Column>{this.renderSyncedId()}</Column>
                )}

                {syncStatus.lastSuccessAt && (
                  <Column>
                    Last sync: <strong>{moment(syncStatus.lastSuccessAt).fromNow()}</strong>
                  </Column>
                )}
              </Columns>
            )}

            {syncStatus.state === 'FAILING' && syncStatus.errorMessage && (
              <Columns>
                <Row>
                  <strong>{syncStatus.errorMessage}</strong>
                </Row>
              </Columns>
            )}
          </>
        )}

        {syncEnabled && !syncStatus && (
          <Columns>
            <Row centered>
              <Icon style={{ color: '#3ba9da' }}>
                <ApproveIcon />
              </Icon>
              Ready to Sync
            </Row>
          </Columns>
        )}

        {!syncEnabled && (
          <Columns>
            <Row>
              {invalidFields.includes('Account') && !missingFields.includes('Company') && (
                <div>Person is marked as Contact, but Salesforce Account does not exist.</div>
              )}

              {missingFields.length > 0 && (
                <div>
                  Missing Fields: <strong>{missingFields.join(', ')}</strong>
                </div>
              )}
            </Row>
          </Columns>
        )}
      </Container>
    );
  }
}
