/**
 * @flow
 * @relayHash dab3e9ae3e1a1dd6ac19b1c3fd1d9141
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EditableQuestionUserSelectOptions_users$ref = any;
export type EditableQuestionUserSelectOptionsQueryVariables = {||};
export type EditableQuestionUserSelectOptionsQueryResponse = {|
  +org: {|
    +users: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +$fragmentRefs: EditableQuestionUserSelectOptions_users$ref
        |}
      |}>
    |}
  |}
|};
export type EditableQuestionUserSelectOptionsQuery = {|
  variables: EditableQuestionUserSelectOptionsQueryVariables,
  response: EditableQuestionUserSelectOptionsQueryResponse,
|};
*/


/*
query EditableQuestionUserSelectOptionsQuery {
  org {
    users {
      edges {
        node {
          ...EditableQuestionUserSelectOptions_users
          id
        }
      }
    }
    id
  }
}

fragment EditableQuestionUserSelectOptions_users on User {
  id
  firstName
  lastName
  email
  ...EditableQuestionUserSelectOptionRow_user
  ...QuestionUserSelectOptionRow_user
}

fragment EditableQuestionUserSelectOptionRow_user on User {
  id
  firstName
  lastName
  ...MaterialAvatar_user
}

fragment QuestionUserSelectOptionRow_user on User {
  id
  firstName
  lastName
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditableQuestionUserSelectOptionsQuery",
  "id": null,
  "text": "query EditableQuestionUserSelectOptionsQuery {\n  org {\n    users {\n      edges {\n        node {\n          ...EditableQuestionUserSelectOptions_users\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment EditableQuestionUserSelectOptions_users on User {\n  id\n  firstName\n  lastName\n  email\n  ...EditableQuestionUserSelectOptionRow_user\n  ...QuestionUserSelectOptionRow_user\n}\n\nfragment EditableQuestionUserSelectOptionRow_user on User {\n  id\n  firstName\n  lastName\n  ...MaterialAvatar_user\n}\n\nfragment QuestionUserSelectOptionRow_user on User {\n  id\n  firstName\n  lastName\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditableQuestionUserSelectOptionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": null,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "EditableQuestionUserSelectOptions_users",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditableQuestionUserSelectOptionsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": null,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e64d6ddc1f5cd4072ca83e3cb66002e7';
module.exports = node;
