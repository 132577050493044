/* @flow */
import React from 'react';
import styled from 'styled-components';

import ResetLink from './ResetLink';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.div`
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 1em;
  color: #2c3360;
`;

const Label = (props: {
  text: string,
  required?: boolean,
  showReset: boolean,
  onReset: () => void,
}) => {
  const { text, required, showReset, onReset } = props;
  return (
    <Container>
      <Text>
        {text} {required && '*'}
      </Text>
      {showReset && <ResetLink onClick={onReset}>reset to default</ResetLink>}
    </Container>
  );
};

export default Label;
