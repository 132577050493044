/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { Content } from 'components/page/Content';
import UserView from 'components/UserView';

import type { UserQueryResponse } from './__generated__/UserQuery.graphql';

const StyledContent = styled(Content)`
  width: 100%;
  padding: 0;
`;

const query = graphql`
  query UserQuery($userId: ID!) {
    user: node(id: $userId) {
      ... on User {
        firstName
        lastName
        email
        ...UserView_user
      }
    }
    me {
      ...UserView_me
    }
    org {
      ...UserView_org
    }
  }
`;

export default class User extends React.Component<{
  match: Match,
  history: History,
  location: Location,
}> {
  render() {
    const variables = {
      userId: this.props.match.params.userId,
    };
    return (
      <StyledContent>
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: UserQueryResponse) =>
            props.user && (
              <DocumentTitle title={fullNameOfUser(props.user)}>
                <UserView
                  org={props.org}
                  user={props.user}
                  me={props.me}
                  history={this.props.history}
                  location={this.props.location}
                />
              </DocumentTitle>
            )
          }
        />
      </StyledContent>
    );
  }
}
