/* @flow */
import { graphql } from 'react-relay';

import checkImportStatus from 'utils/requests/checkImportStatus';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  ImportPeopleInput,
  importPeopleMutationResponse,
  UserImportInput,
} from './__generated__/importPeopleMutation.graphql';

export type PeopleImportMappingsType = UserImportInput;

const mutation = graphql`
  mutation importPeopleMutation($input: ImportPeopleInput!) {
    importPeople(input: $input) {
      uuid
    }
  }
`;

export default function importPeople(input: ImportPeopleInput) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  }).then((response: importPeopleMutationResponse) => {
    if (!response.importPeople) throw Error('Import Failed');

    return checkImportStatus(response.importPeople.uuid);
  });
}
