/* @flow */
const marketoCompanyFields = [
  'company',
  'site',
  'billingStreet',
  'billingCity',
  'billingState',
  'billingCountry',
  'billingPostalCode',
  'mainPhone',
  'website',
  'industry',
  'sicCode',
  'annualRevenue',
  'numberOfEmployees',
  'mktoCompanyNotes',
  'sfdcAccountId',
  'externalCompanyId',
  'contactCompany',
  'CompanyDunsNumber',
  'DandbCompanyId',
  'JigsawCompanyId_account',
  'DandbCompanyId_account',
];

export default marketoCompanyFields;
