/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompanyProfile_company$ref: FragmentReference;
export type CompanyProfile_company = {|
  +id: string,
  +name: string,
  +org: {|
    +id: string,
    +dbId: number,
    +settings: {|
      +currency: string
    |},
    +customFields: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +kind: CustomFieldKindType,
          +fieldName: ?string,
          +label: string,
          +required: boolean,
          +order: number,
          +viewerCanUpdate: boolean,
          +options: {|
            +edges: $ReadOnlyArray<{|
              +node: {|
                +id: string,
                +name: string,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +salesforceId: ?string,
  +phone: ?string,
  +website: ?string,
  +twitter: ?string,
  +linkedin: ?string,
  +description: ?string,
  +country: ?string,
  +state: ?string,
  +city: ?string,
  +zip: ?string,
  +street: ?string,
  +viewerCanRemove: boolean,
  +viewerCanUpdate: boolean,
  +customTextFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: string,
  |}>,
  +customTextareaFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: string,
  |}>,
  +customLinkFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: string,
  |}>,
  +customDateFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: any,
  |}>,
  +customNumberFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: number,
  |}>,
  +customCurrencyFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: number,
  |}>,
  +customBooleanFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: boolean,
  |}>,
  +customUserSelectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +user: {|
      +id: string,
      +firstName: string,
      +lastName: string,
      +email: string,
      +avatar: ?string,
      +$fragmentRefs: MaterialAvatar_user$ref,
    |},
  |}>,
  +customUserMultiselectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +user: {|
      +id: string,
      +firstName: string,
      +lastName: string,
      +email: string,
      +avatar: ?string,
      +$fragmentRefs: MaterialAvatar_user$ref,
    |},
  |}>,
  +customSelectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +option: {|
      +id: string
    |},
    +selectOtherValue: ?string,
  |}>,
  +customMultiselectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +option: {|
      +id: string
    |},
    +selectOtherValue: ?string,
  |}>,
  +__typename: "Company",
  +$refType: CompanyProfile_company$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
},
v3 = [
  v0
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": v3
},
v5 = [
  v4,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "value",
    "args": null,
    "storageKey": null
  }
],
v6 = [
  v4,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "user",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      v0,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "avatar",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "FragmentSpread",
        "name": "MaterialAvatar_user",
        "args": null
      }
    ]
  }
],
v7 = [
  v4,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "option",
    "storageKey": null,
    "args": null,
    "concreteType": "CustomFieldOptionType",
    "plural": false,
    "selections": v3
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "selectOtherValue",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "CompanyProfile_company",
  "type": "Company",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    v1,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "org",
      "storageKey": null,
      "args": null,
      "concreteType": "Org",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "dbId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "settings",
          "storageKey": null,
          "args": null,
          "concreteType": "OrgSettings",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "currency",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customFields",
          "storageKey": "customFields(customizableType:[\"COMPANY\"])",
          "args": [
            {
              "kind": "Literal",
              "name": "customizableType",
              "value": [
                "COMPANY"
              ],
              "type": "[CustomizableType!]!"
            }
          ],
          "concreteType": "CustomFieldTypeRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldTypeRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldType",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "kind",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fieldName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "label",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "required",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "order",
                      "args": null,
                      "storageKey": null
                    },
                    v2,
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "options",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomFieldOptionTypeRequiredConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomFieldOptionTypeRequiredEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomFieldOptionType",
                              "plural": false,
                              "selections": [
                                v0,
                                v1
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "salesforceId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "website",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "twitter",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "linkedin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "country",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "city",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "zip",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "street",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanRemove",
      "args": null,
      "storageKey": null
    },
    v2,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customTextFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomTextFieldType",
      "plural": true,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customTextareaFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomTextareaFieldType",
      "plural": true,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customLinkFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomLinkFieldType",
      "plural": true,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customDateFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomDateFieldType",
      "plural": true,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customNumberFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomNumberFieldType",
      "plural": true,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customCurrencyFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomCurrencyFieldType",
      "plural": true,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customBooleanFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomBooleanFieldType",
      "plural": true,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customUserSelectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomUserSelectFieldType",
      "plural": true,
      "selections": v6
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customUserMultiselectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomUserMultiselectFieldType",
      "plural": true,
      "selections": v6
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSelectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSelectFieldType",
      "plural": true,
      "selections": v7
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customMultiselectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomMultiselectFieldType",
      "plural": true,
      "selections": v7
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8bae7230f4b2c154187fcb3643623f01';
module.exports = node;
