/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import Restricted from '../../components/Restricted';
import SimpleBriefField from '../../components/SimpleBriefField';

import { type EventInfoActualBudget_event } from './__generated__/EventInfoActualBudget_event.graphql';
import { type EventInfoActualBudget_org } from './__generated__/EventInfoActualBudget_org.graphql';

const EventInfoActualBudget = (props: {
  name: string,
  event: EventInfoActualBudget_event,
  org: EventInfoActualBudget_org,
}) => {
  const {
    name,
    event: { viewerCanViewBudget, totalActualAmount },
    org: {
      settings: { currency },
    },
  } = props;

  return (
    totalActualAmount > 0 && (
      <SimpleBriefField
        label={name}
        contentDataAvailable={totalActualAmount}
        data={viewerCanViewBudget ? formatCost(totalActualAmount, currency) : <Restricted />}
      />
    )
  );
};

export default createFragmentContainer(EventInfoActualBudget, {
  event: graphql`
    fragment EventInfoActualBudget_event on Event {
      viewerCanViewBudget
      totalActualAmount
    }
  `,
  org: graphql`
    fragment EventInfoActualBudget_org on Org {
      settings {
        currency
      }
    }
  `,
});
