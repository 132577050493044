/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { BriefUserAvatar, BriefUserName } from '../../components/BriefUser';

import BriefViewSharedContext from '../../BriefViewSharedContext';

import TaskItemAssignee_user from './__generated__/TaskItemAssignee_user.graphql';

const Container = styled.div`
  display: flex;
  flex: 0 auto;
  align-items: center;
  margin-right: 45px;
  margin-bottom: 15px;
  min-width: 180px;
`;

const TaskItemAssignee = (props: { user: TaskItemAssignee_user }) => {
  const {
    user,
    user: { firstName, lastName },
  } = props;
  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <Container isShared={sharedView}>
          <BriefUserAvatar user={user} radius={19} />
          <BriefUserName firstName={firstName} lastName={lastName} />
        </Container>
      )}
    </BriefViewSharedContext.Consumer>
  );
};

export default createFragmentContainer(
  TaskItemAssignee,
  graphql`
    fragment TaskItemAssignee_user on User {
      id
      firstName
      lastName
      ...BriefUserAvatar_user
    }
  `,
);
