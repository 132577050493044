/* @flow */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import SelectField from 'components/material/SelectField';

const Root = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 13px;
`;

const MonthSelect = styled.div`
  width: 90px;
  margin: 0 10px;
`;

const YearSelect = styled.div`
  width: 55px;
  margin: 0 10px;
`;

const CurrentDate = styled.span`
  font-weight: 500;
  color: ${props => (props.disabled ? 'inherit' : '#3ba9da')};
`;

type SelectOptionsType<T> = $PropertyType<$PropertyType<SelectField<T>, 'props'>, 'options'>;

export default class CalendarCaption extends React.Component<
  {
    tz: string,
    date: Date,
    displayMonth: string,
    localeUtils: { getMonths: () => $ReadOnlyArray<string> },
    onChangeDisplayMonth: (month: Date) => void,
  },
  {
    yearSearchQuery: ?number,
  },
> {
  state = {
    yearSearchQuery: null,
  };

  normalizeDisplayMonth = (month: moment) => {
    if (!moment(this.props.displayMonth).isSame(this.props.date, 'month')) {
      month.subtract(1, 'month');
    }

    return month.toDate();
  };

  handleChangeMonth = (month: ?number) => {
    if (month == null) return;

    this.props.onChangeDisplayMonth(
      this.normalizeDisplayMonth(moment(this.props.date).month(month)),
    );
  };

  handleChangeYear = (year: ?number) => {
    if (year == null) return;

    this.props.onChangeDisplayMonth(this.normalizeDisplayMonth(moment(this.props.date).year(year)));
  };

  handleSearchYear = (query: string) => {
    const parsedQuery = +query;

    if (Number.isFinite(parsedQuery) && Number.isInteger(parsedQuery)) {
      this.setState({ yearSearchQuery: parsedQuery });
    }

    if (!query.trim()) {
      this.setState({ yearSearchQuery: null });
    }
  };

  yearOptions = (dateYear: number): SelectOptionsType<number> => {
    const { yearSearchQuery } = this.state;
    const currentYear = moment()
      .tz(this.props.tz)
      .year();
    const baseYear =
      yearSearchQuery == null
        ? dateYear
        : Math.min(
            Math.max(+String(yearSearchQuery * 1000).slice(0, 4), currentYear - 100),
            currentYear + 100,
          );

    let years = Array.from(
      { length: yearSearchQuery == null ? 5 : 10 },
      (_, index: number) => baseYear + index + (yearSearchQuery == null ? -1 : 0),
    );

    if (yearSearchQuery == null && !years.includes(currentYear)) {
      years = years[0] > currentYear ? [currentYear, ...years] : [...years, currentYear];
    }

    if (yearSearchQuery != null) {
      years = years.filter(year => String(year).startsWith(String(yearSearchQuery)));
    }

    return years.map(year => ({
      label: String(year),
      displayLabel:
        year === currentYear ? (
          <CurrentDate disabled={year === dateYear}>{year}</CurrentDate>
        ) : null,
      value: year,
      disabled: year === dateYear,
    }));
  };

  monthOptions = (dateMonth: number): SelectOptionsType<number> => {
    const currentMonth = moment()
      .tz(this.props.tz)
      .month();
    const currentYear = moment()
      .tz(this.props.tz)
      .year();
    const dateYear = this.props.date.getFullYear();

    return this.props.localeUtils.getMonths().map((month, index) => ({
      label: month,
      displayLabel:
        index === currentMonth && currentYear === dateYear ? (
          <CurrentDate disabled={index === dateMonth}>{month}</CurrentDate>
        ) : null,
      value: index,
      disabled: index === dateMonth,
    }));
  };

  render() {
    const dateYear = this.props.date.getFullYear();
    const dateMonth = this.props.date.getMonth();

    const yearOptions = this.yearOptions(dateYear);
    const monthOptions = this.monthOptions(dateMonth);

    return (
      <Root>
        <MonthSelect>
          <SelectField
            value={dateMonth}
            options={monthOptions}
            placeholder={monthOptions[dateMonth].label}
            onChange={this.handleChangeMonth}
            searchable
            noBoxShadow
            height={268}
            compact
          />
        </MonthSelect>

        <YearSelect>
          <SelectField
            value={dateYear}
            options={yearOptions}
            placeholder={String(dateYear)}
            onChange={this.handleChangeYear}
            onSearch={this.handleSearchYear}
            searchable
            noBoxShadow
            compact
          />
        </YearSelect>
      </Root>
    );
  }
}
