/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatLocation from 'utils/locations/formatLocation';

import type { EventLocation_staffedEvent } from './__generated__/EventLocation_staffedEvent.graphql';

type Props = {
  staffedEvent: EventLocation_staffedEvent,
};

const EventLocation = ({ staffedEvent }: Props) =>
  formatLocation(staffedEvent.event.primaryLocation);

export default createFragmentContainer(
  EventLocation,
  graphql`
    fragment EventLocation_staffedEvent on StaffMembership {
      event {
        primaryLocation {
          city
          state
          name
          country
        }
      }
    }
  `,
);
