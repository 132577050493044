/* @flow */
import { css } from 'styled-components';

const calendarStyles = css`
  .DayPicker-wrapper {
    position: relative;
    outline: none;
  }
  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
  }
  .DayPicker-Month {
    flex: 1 0 200px;
    min-width: 0;
    &:not(:first-child):not(:only-child) {
      margin-left: 45px;
      @media (max-width: 550px) {
        margin-left: 0;
      }
    }
  }
  .DayPicker-WeekdaysRow {
    display: flex;
    margin-bottom: 12px;
    line-height: 1;
  }
  .DayPicker-Weekday {
    flex: 1 1 0%;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #3a4c54;
    abbr {
      text-decoration: none;
    }
  }
  .DayPicker-Week {
    display: flex;
    line-height: 1;
  }
  .DayPicker-Day {
    flex: 1 1 0%;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    padding: 6px 10px;
    outline: none;
    font-size: 13px;
    font-variant-numeric: tabular-nums;
    color: #677386;
    cursor: pointer;
    user-select: none;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      width: 25px;
      height: 25px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 23px;
    }
    &:hover,
    &:focus {
      &:after {
        background: #f2fbff;
      }
    }
    &--weekend {
      color: rgba(103, 115, 134, 0.54);
    }
    &--outside {
      visibility: hidden;
    }
    &--today {
      font-weight: 500;
      color: #3ba9da;
    }
    &--selected:not(.DayPicker-Day--intermediate) {
      font-weight: 400;
      cursor: default;
      color: #fff;
      &:after {
        background: #39a8da;
      }
      &:hover,
      &:focus {
        &:after {
          background: #39a8da;
        }
      }
    }
    &--selected.DayPicker-Day--intermediate {
      cursor: default;
      &:before {
        background: #f2fbff;
      }
    }
    &--selected.DayPicker-Day--rangeStart:not(.DayPicker-Day--rangeEnd) {
      &:before {
        right: 0;
        width: 50%;
        background: #f2fbff;
      }
    }
    &--selected.DayPicker-Day--rangeEnd:not(.DayPicker-Day--rangeStart) {
      &:before {
        left: 0;
        width: 50%;
        background: #f2fbff;
      }
    }
  }
`;

export default calendarStyles;
