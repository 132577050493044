/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import Clipboard from 'clipboard';

import EmbedIcon from 'images/events/embed.svg';
import EmbedWindow from 'components/EmbedWindow';
import Tooltip from 'components/material/Tooltip';

const Container = styled.div`
  display: flex;
  a {
    margin-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${props => props.theme.primaryActionColor};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Icon = styled.i`
  flex: 0 0 20px;
`;

const IconButton = styled.button`
  margin-left: 10px;
  color: #6e7884;
  cursor: pointer;

  &:hover {
    color: #3e4859;
  }
`;

export default class CopyLink extends React.PureComponent<
  {
    link: string,
    hideLink?: boolean,
    className?: string,
    embed?: boolean,
  },
  {
    copied: boolean,
    showEmbed: boolean,
  },
> {
  state = { copied: false, showEmbed: false };

  copyIcon: ?HTMLElement;

  componentDidMount() {
    new Clipboard(this.copyIcon, {
      text: () => this.props.link,
    }).on('success', () => {
      this.setState({ copied: true });
    });
  }

  handleMouseLeave = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 300);
  };

  handleShowEmbedWindow = () => {
    this.setState({ showEmbed: true });
  };

  handleHideEmbedWindow = () => {
    this.setState({ showEmbed: false });
  };

  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  render() {
    return (
      <Container onClick={this.stopPropagation} className={this.props.className}>
        {!this.props.hideLink && (
          <a href={this.props.link} target="_blank" rel="noreferrer noopener">
            {this.props.link}
          </a>
        )}
        <Tooltip
          innerRef={el => {
            this.copyIcon = el;
          }}
          label={this.state.copied ? 'Copied' : 'Copy to clipboard'}
          placement="top"
        >
          <Icon className="fa fa-fw fa-clone" onMouseLeave={this.handleMouseLeave} />
        </Tooltip>
        {this.props.embed && (
          <>
            <IconButton>
              <Tooltip label="Embed" placement="top">
                <EmbedIcon onClick={this.handleShowEmbedWindow} />
              </Tooltip>
            </IconButton>
            {this.state.showEmbed &&
              document.body &&
              createPortal(
                <EmbedWindow url={this.props.link} onHide={this.handleHideEmbedWindow} />,
                document.body,
              )}
          </>
        )}
      </Container>
    );
  }
}
