/**
 * @flow
 * @relayHash 1de869ae625eb400c4fd900889e9747b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type HeaderProfileMenu_user$ref = any;
type MaterialAvatar_user$ref = any;
export type Homepage = "ALL_TASKS" | "EVENTS" | "MY_EVENTS" | "MY_TASKS" | "UPCOMING_EVENTS";
export type SubscriptionPlan = "BASIC" | "ENTERPRISE" | "INTERNAL" | "PREMIUM" | "TRIAL";
export type HeaderQueryVariables = {||};
export type HeaderQueryResponse = {|
  +me: {|
    +id: string,
    +homepage: Homepage,
    +homepageListId: ?string,
    +$fragmentRefs: MaterialAvatar_user$ref & HeaderProfileMenu_user$ref,
  |},
  +org: {|
    +analyticsEnabled: boolean,
    +subscription: {|
      +plan: SubscriptionPlan,
      +expiresAt: ?any,
      +upgradeRequired: boolean,
    |},
  |},
|};
export type HeaderQuery = {|
  variables: HeaderQueryVariables,
  response: HeaderQueryResponse,
|};
*/


/*
query HeaderQuery {
  me {
    id
    homepage
    homepageListId
    ...MaterialAvatar_user
    ...HeaderProfileMenu_user
  }
  org {
    analyticsEnabled
    subscription {
      plan
      expiresAt
      upgradeRequired
      id
    }
    id
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}

fragment HeaderProfileMenu_user on User {
  ...ProfileMenuItems_user
}

fragment ProfileMenuItems_user on User {
  id
  firstName
  lastName
  email
  ...MaterialAvatar_user
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "homepage",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "homepageListId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "analyticsEnabled",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plan",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expiresAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upgradeRequired",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "HeaderQuery",
  "id": null,
  "text": "query HeaderQuery {\n  me {\n    id\n    homepage\n    homepageListId\n    ...MaterialAvatar_user\n    ...HeaderProfileMenu_user\n  }\n  org {\n    analyticsEnabled\n    subscription {\n      plan\n      expiresAt\n      upgradeRequired\n      id\n    }\n    id\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n\nfragment HeaderProfileMenu_user on User {\n  ...ProfileMenuItems_user\n}\n\nfragment ProfileMenuItems_user on User {\n  id\n  firstName\n  lastName\n  email\n  ...MaterialAvatar_user\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "HeaderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          {
            "kind": "FragmentSpread",
            "name": "MaterialAvatar_user",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "HeaderProfileMenu_user",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v4,
              v5,
              v6
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HeaderQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avatar",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v4,
              v5,
              v6,
              v0
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fae007a6333399f7ba9538b09d361522';
module.exports = node;
