/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const Preview = styled.div`
  background: #fffde8;
  color: #636262;
  width: 330px;
  padding: 10px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: rotate(5deg);
  transform-origin: top left;
  position: relative;
  border-radius: 3px;
  ${props =>
    props.multiple &&
    css`
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 3px 3px 0 #fffde8, 3px 3px 10px rgba(0, 0, 0, 0.2),
        6px 6px 0 #fffde8, 6px 6px 10px rgba(0, 0, 0, 0.2);
    `}
`;

export default class TaskDragPreview extends React.PureComponent<{
  multiple?: boolean,
  name: string,
}> {
  render() {
    return <Preview multiple={this.props.multiple}>{this.props.name}</Preview>;
  }
}
