/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type DividerComponent_org$ref = any;
type EmbedComponent_org$ref = any;
type EventDatesComponent_org$ref = any;
type EventNameComponent_org$ref = any;
type ImageComponent_org$ref = any;
type RowComponent_org$ref = any;
type TextComponent_org$ref = any;
type VideoComponent_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationPageComponents_org$ref: FragmentReference;
export type RegistrationPageComponents_org = {|
  +id: string,
  +$fragmentRefs: ImageComponent_org$ref & EventNameComponent_org$ref & EventDatesComponent_org$ref & TextComponent_org$ref & DividerComponent_org$ref & VideoComponent_org$ref & EmbedComponent_org$ref & RowComponent_org$ref,
  +$refType: RegistrationPageComponents_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationPageComponents_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ImageComponent_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventNameComponent_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventDatesComponent_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TextComponent_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DividerComponent_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VideoComponent_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EmbedComponent_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RowComponent_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3d8db8d986e732e7c2c82e96c1bb90fc';
module.exports = node;
