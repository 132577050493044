/* @flow */
import {
  type EventRequestFormStatusValue,
  eventRequestFormStatuses,
} from 'config/eventRequestFormStatuses';
import eventStates, { type EventStateGraphValue } from 'config/eventStates';
import registrationFormStatuses, {
  type RegistrationFormStatusType,
} from 'config/registrationFormStatuses';
import syncStatuses from 'config/syncStatuses.json';

import type { FieldType } from 'utils/customization/types';
import getCustomFieldQueryParamsByKind from 'utils/filters/getCustomFieldQueryParamsByKind';
import parseTypedQueryString, {
  type CustomFieldParam,
  type DateRangeParam,
  type NumberRangeParam,
  type SortParam,
  stringParamToDateRange,
  stringParamToEnum,
  stringParamToEnumArray,
  stringParamToNumberRange,
  stringParamToSort,
  stringParamToStringArray,
} from 'utils/routing/parseTypedQueryString';

import { type EventFormat } from 'views/Main/Dashboard/AllEvents/AllEventsListPage/__generated__/AllEventsTablePaginationQuery.graphql';

import {
  type GroupingOptionType,
  getCustomFieldsGroupingOptions,
  rawGroupingOptions,
} from './reportGroupingOptions';

export type ParsedBudgetReportingFilters = {|
  [key: string]: CustomFieldParam,
  group1: ?GroupingOptionType,
  group2: ?GroupingOptionType,
  customGroup1: ?string,
  customGroup2: ?string,
  queries: ?$ReadOnlyArray<string>,
  date: ?DateRangeParam,
  statuses: ?$ReadOnlyArray<EventStateGraphValue>,
  syncStatuses: ?$ReadOnlyArray<$Keys<typeof syncStatuses>>,
  opportunitiesNumber: ?NumberRangeParam,
  opportunitiesAmount: ?NumberRangeParam,
  teamIds: ?$ReadOnlyArray<string>,
  leadIds: ?$ReadOnlyArray<string>,
  staffIds: ?$ReadOnlyArray<string>,
  budgetCategoryIds: ?$ReadOnlyArray<string>,
  venueNames: ?$ReadOnlyArray<string>,
  cities: ?$ReadOnlyArray<string>,
  states: ?$ReadOnlyArray<string>,
  countries: ?$ReadOnlyArray<string>,
  eventFormats: ?$ReadOnlyArray<EventFormat>,
  eventIds: ?$ReadOnlyArray<string>,
  updatedAt: ?DateRangeParam,
  updaterIds: ?$ReadOnlyArray<string>,
  createdAt: ?DateRangeParam,
  creatorIds: ?$ReadOnlyArray<string>,
  requesterUserIds: ?$ReadOnlyArray<string>,
  requesterContactIds: ?$ReadOnlyArray<string>,
  requestedDate: ?DateRangeParam,
  requestStatuses: ?$ReadOnlyArray<$Subtype<EventRequestFormStatusValue>>,
  requestFormIds: ?$ReadOnlyArray<string>,
  requestReviewers: ?$ReadOnlyArray<string>,
  contactsCount: ?NumberRangeParam,
  registeredContactsTotal: ?NumberRangeParam,
  attendedContactsTotal: ?NumberRangeParam,
  registrationFormStatuses: ?$ReadOnlyArray<RegistrationFormStatusType>,
  sort: ?SortParam,
|};

export default function parseBudgetReportingFilters(
  queryString: string,
  fiscalYearStart: number,
  filterOptions: $ReadOnlyArray<FieldType>,
): ParsedBudgetReportingFilters {
  const dateRangeParamToFiscalStringInner = (stringValue: string) => {
    return stringParamToDateRange(stringValue, fiscalYearStart);
  };
  const customFieldOptions = getCustomFieldsGroupingOptions(filterOptions);

  return parseTypedQueryString(queryString, {
    ...(filterOptions || []).reduce(
      (customFilters, filter) =>
        filter.kind !== 'DEFAULT'
          ? { ...customFilters, ...getCustomFieldQueryParamsByKind(filter) }
          : customFilters,
      {},
    ),
    group1: stringParamToEnum(Object.keys(rawGroupingOptions)),
    group2: stringParamToEnum(Object.keys(rawGroupingOptions)),
    customGroup1: stringParamToEnum(Object.keys(customFieldOptions)),
    customGroup2: stringParamToEnum(Object.keys(customFieldOptions)),
    queries: stringParamToStringArray,
    date: dateRangeParamToFiscalStringInner,
    statuses: stringParamToEnumArray(eventStates.map(s => s.graphValue)),
    syncStatuses: stringParamToEnumArray(Object.keys(syncStatuses)),
    opportunitiesNumber: stringParamToNumberRange,
    opportunitiesAmount: stringParamToNumberRange,
    teamIds: stringParamToStringArray,
    leadIds: stringParamToStringArray,
    staffIds: stringParamToStringArray,
    budgetCategoryIds: stringParamToStringArray,
    eventIds: stringParamToStringArray,
    updatedAt: stringParamToDateRange,
    updaterIds: stringParamToStringArray,
    createdAt: stringParamToDateRange,
    creatorIds: stringParamToStringArray,
    venueNames: stringParamToStringArray,
    cities: stringParamToStringArray,
    states: stringParamToStringArray,
    countries: stringParamToStringArray,
    eventFormats: stringParamToEnumArray(['VIRTUAL', 'PHYSICAL', 'HYBRID']),
    requesterUserIds: stringParamToStringArray,
    requesterContactIds: stringParamToStringArray,
    requestedDate: stringParamToDateRange,
    requestStatuses: stringParamToEnumArray(eventRequestFormStatuses.map(s => s.value)),
    requestFormIds: stringParamToStringArray,
    requestReviewers: stringParamToStringArray,
    contactsCount: stringParamToNumberRange,
    registeredContactsTotal: stringParamToNumberRange,
    attendedContactsTotal: stringParamToNumberRange,
    registrationFormStatuses: stringParamToEnumArray(Object.keys(registrationFormStatuses)),
    sort: stringParamToSort,
  });
}
