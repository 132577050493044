/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type DeliverableActionCell_deliverable$ref = any;
type DeliverableActionCell_me$ref = any;
type DeliverableAssignees_deliverable$ref = any;
type DeliverableAssignees_me$ref = any;
type DeliverableDueDate_deliverable$ref = any;
type DeliverableEventName_deliverable$ref = any;
type DeliverableStatus_deliverable$ref = any;
type DeliverableTags_deliverable$ref = any;
type DeliverableTitle_deliverable$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyTasksPagination_me$ref: FragmentReference;
export type MyTasksPagination_me = {|
  +id: string,
  +deliverables: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: DeliverableTags_deliverable$ref & DeliverableTitle_deliverable$ref & DeliverableStatus_deliverable$ref & DeliverableEventName_deliverable$ref & DeliverableDueDate_deliverable$ref & DeliverableAssignees_deliverable$ref & DeliverableActionCell_deliverable$ref,
      |}
    |}>,
    +pageInfo: {|
      +endCursor: ?string,
      +hasNextPage: boolean,
    |},
  |},
  +$fragmentRefs: DeliverableActionCell_me$ref & DeliverableAssignees_me$ref,
  +$refType: MyTasksPagination_me$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MyTasksPagination_me",
  "type": "User",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "deliverables"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "cursor",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "filters",
      "type": "DeliverableFilters"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "FragmentSpread",
      "name": "DeliverableActionCell_me",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DeliverableAssignees_me",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": "deliverables",
      "name": "__MyTasksPagination_deliverables_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "DeliverableRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Deliverable",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "FragmentSpread",
                  "name": "DeliverableTags_deliverable",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "DeliverableTitle_deliverable",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "DeliverableStatus_deliverable",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "DeliverableEventName_deliverable",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "DeliverableDueDate_deliverable",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "DeliverableAssignees_deliverable",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "DeliverableActionCell_deliverable",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '84dd27d1097944f956bcbe28784ffcfe';
module.exports = node;
