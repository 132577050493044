/* @flow */
import React from 'react';
import styled from 'styled-components';
import matchSorter from 'match-sorter';

import timezones from 'config/timezones';
import stringifyTimeZone from 'utils/date/stringifyTimeZone';
import SelectField, { Field } from 'components/material/SelectField';
import inputBoxStyled from '../components/inputBoxStyled';

import Label from './Label';

const StyledSelectField = styled(SelectField)`
  > div:first-child {
    overflow: hidden;
    height: 35px;
    padding: 0 10px;
    border-radius: 3px;
    border: 1px solid #d9ddf5;
    font-size: 13px;
    line-height: 1em;
    background: #fff;
    color: #464d72;
    cursor: pointer;

    > i {
      font-size: 18px;
      color: #3e4858;
    }
  }
  ${Field} {
    ${props => inputBoxStyled(props)};
  }
`;

const SelectItemLabel = styled.span`
  white-space: normal;
`;

export default class EventTimeZone extends React.PureComponent<{
  value: string,
  eventTz: string,
  onChange: ({ tz: ?string }) => void,
}> {
  handleChangeTz = (tz: ?string) => {
    this.props.onChange({ tz });
  };

  handleResetToEvent = () => {
    const { onChange, eventTz } = this.props;
    onChange({ tz: eventTz });
  };

  defaultTimezone = (defaultTimezone: string) => {
    const timezone = timezones.find(tz => tz.name === defaultTimezone);

    if (timezone) return defaultTimezone;

    const timezonesByOffset = timezones.filter(tz => tz.offset === 0);

    const filteredTimezones = matchSorter(
      timezonesByOffset,
      defaultTimezone
        .split('/')
        .slice(-1)[0]
        .split('_')
        .join(' '),
      { threshold: matchSorter.rankings.NO_MATCH, keys: ['label'] },
    );

    return filteredTimezones.length > 0 ? filteredTimezones[0].name : 'UTC';
  };

  render() {
    const { value, eventTz } = this.props;
    const timezone = this.defaultTimezone(value);

    const timezonesOptions = timezones.map(tz => ({
      value: tz.name,
      label: stringifyTimeZone(tz),
      displayLabel: <SelectItemLabel>{stringifyTimeZone(tz)}</SelectItemLabel>,
    }));

    return (
      <>
        <Label
          text="Event Timezone"
          required
          onReset={this.handleResetToEvent}
          showReset={timezone !== eventTz}
        />
        <StyledSelectField
          options={timezonesOptions}
          onChange={this.handleChangeTz}
          value={timezone}
          searchable
        />
      </>
    );
  }
}
