/* @flow */
import React from 'react';
import styled from 'styled-components';

import NumberField from 'components/material/NumberField';

import type { QuestionType } from '../lib/types';
import EditableQuestionSelectOptions from './EditableQuestionSelectOptions';

const SelectionLimitField = styled(NumberField)`
  margin: 0 0 12px;
`;

export default class EditableQuestionMultiselectOptions extends React.PureComponent<{
  question: QuestionType,
  onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
}> {
  handleChangeSelectionLimit = (e: SyntheticEvent<HTMLInputElement>, value: ?string | number) => {
    const parsedNumber = parseInt(value, 10);
    const maxSelectionSize = parsedNumber > 0 ? parsedNumber : null;
    this.props.onChangeQuestion({ maxSelectionSize });
  };

  render() {
    const { question, onChangeQuestion } = this.props;

    return (
      <>
        <SelectionLimitField
          label="Limit # of choices"
          maxLength={3}
          defaultValue={question.maxSelectionSize}
          onChange={this.handleChangeSelectionLimit}
        />
        <EditableQuestionSelectOptions question={question} onChangeQuestion={onChangeQuestion} />
      </>
    );
  }
}
