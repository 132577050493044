/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

const mutation = graphql`
  mutation addVendorToBriefMutation($input: AddVendorToBriefInput!) {
    addVendorToBrief(input: $input) {
      vendorEdge {
        __typename
        node {
          id
        }
      }
    }
  }
`;

export default function addVendorToBrief(vendorId: string, eventId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        vendorId,
        eventId,
      },
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'vendorEdge',
      connections: [{ field: 'briefVendors' }],
    }),
  });
}
