/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Subtasks_subtasks$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskSubtasks_task$ref: FragmentReference;
export type TaskSubtasks_task = {|
  +id: string,
  +viewerCanUpdateSubtasks: boolean,
  +subtasks: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: Subtasks_subtasks$ref
      |}
    |}>
  |},
  +event: {|
    +id: string,
    +tz: string,
  |},
  +$refType: TaskSubtasks_task$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TaskSubtasks_task",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateSubtasks",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subtasks",
      "storageKey": null,
      "args": null,
      "concreteType": "DeliverableRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "DeliverableRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Deliverable",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "Subtasks_subtasks",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tz",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6406aafcd68a8d3f46c933b87e6c4f24';
module.exports = node;
