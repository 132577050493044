/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthorizableRoute from 'utils/routing/AuthorizableRoute';

import Billing from './Billing';
import Members from './Members';
import User from './Members/User';
import Team from './Team';
import Teams from './Teams';

import type { WorkspaceRoutes_org } from './__generated__/WorkspaceRoutes_org.graphql';

type Props = { org: WorkspaceRoutes_org, pathPrefix: string };

const getDefaultRoute = (org: WorkspaceRoutes_org): string => {
  if (org.viewerCanViewOrgMembers) {
    return 'members';
  }
  if (org.viewerCanViewTeams) {
    return 'teams';
  }
  return 'billing';
};

const WorkspaceRoutes = ({ org, pathPrefix }: Props) => {
  const defaultRoute = getDefaultRoute(org);
  return (
    <Switch>
      <AuthorizableRoute
        authorized={org.viewerCanViewOrgMembers}
        exact
        path={`${pathPrefix}/members`}
        component={Members}
      />
      <AuthorizableRoute
        authorized={org.viewerCanViewTeams}
        exact
        path={`${pathPrefix}/teams`}
        component={Teams}
      />
      <AuthorizableRoute
        authorized={org.viewerCanViewTeams}
        path={`${pathPrefix}/teams/:teamSlug`}
        component={Team}
      />

      <Route path={`${pathPrefix}/members/:userId`} component={User} />
      <Route path={`${pathPrefix}/billing`} component={Billing} />

      <Redirect from="/workspace" to={`/workspace/${defaultRoute}`} />
    </Switch>
  );
};

export default createFragmentContainer(
  WorkspaceRoutes,
  graphql`
    fragment WorkspaceRoutes_org on Org {
      viewerCanImport
      viewerCanViewOrgMembers
      viewerCanViewTeams
    }
  `,
);
