/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import mappingComponents from '../lib/mappingComponents';
import mappingCustomComponents from '../lib/mappingCustomComponents';
import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../lib/types';
import QuestionHeader from './QuestionHeader';

const Container = styled.div`
  margin-bottom: 10px;
  padding: 10px 80px 10px 80px;
  ${props =>
    props.hasError &&
    css`
      background: #fff5f5;
    `};
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 20px 10px 20px;
  }
`;

const InputContainer = styled.div`
  padding-right: 80px;
  @media (${props => props.theme.mobileOnly}) {
    padding-right: 0;
  }
`;

export default class Question extends React.PureComponent<
  {
    question: QuestionType,
    value: QuestionValueType,
    usedBudgetCategoeryIds?: $ReadOnlyArray<string>,
    onChangeValue: (QuestionType, QuestionValueInputType) => void,
    handleShouldValidate?: (shouldValidate: boolean) => void,
    readOnly: boolean,
    currency: string,
    tz: string,
    errors: { [string]: ?string },
    className?: string,
  },
  {
    showError: boolean,
  },
> {
  state = {
    showError: true,
  };

  handleChangeValue = (value: QuestionValueInputType) => {
    this.props.onChangeValue(this.props.question, value);
  };

  handleChangeShowError = (showError: boolean) => {
    this.setState(state => (state.showError === showError ? null : { showError }));
  };

  render() {
    const { question, errors, value, className, usedBudgetCategoeryIds } = this.props;

    const error = this.state.showError ? errors[question.id] : null;

    const Component = question.mappingCustomField
      ? mappingCustomComponents[question.mappingCustomField.kind]
      : question.mapping && mappingComponents[question.mapping];

    return (
      <Container hasError={!!error} className={className}>
        <QuestionHeader
          label={question.name}
          required={question.required}
          hasError={!!error}
          showIteration
          showLabel={!question.mappingCustomField || question.mappingCustomField.kind !== 'BOOLEAN'}
        >
          <InputContainer>
            {Component && (
              <Component
                question={question}
                usedBudgetCategoeryIds={usedBudgetCategoeryIds}
                value={value}
                onChangeValue={this.handleChangeValue}
                handleShouldValidate={this.props.handleShouldValidate}
                tz={this.props.tz}
                currency={this.props.currency}
                readOnly={this.props.readOnly || false}
                error={error}
                onChangeShowError={this.handleChangeShowError}
              />
            )}
          </InputContainer>
        </QuestionHeader>
      </Container>
    );
  }
}
