/**
 * @flow
 * @relayHash 8300e5760b9e6aecfdaab7b8b20ee4df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountPreferencesForm_me$ref = any;
export type AccountPreferencesQueryVariables = {||};
export type AccountPreferencesQueryResponse = {|
  +me: {|
    +$fragmentRefs: AccountPreferencesForm_me$ref
  |}
|};
export type AccountPreferencesQuery = {|
  variables: AccountPreferencesQueryVariables,
  response: AccountPreferencesQueryResponse,
|};
*/


/*
query AccountPreferencesQuery {
  me {
    ...AccountPreferencesForm_me
    id
  }
}

fragment AccountPreferencesForm_me on User {
  id
  homepage
  homepageListId
  eventLists {
    edges {
      node {
        id
        name
      }
    }
  }
  metricUnits
  tz
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AccountPreferencesQuery",
  "id": null,
  "text": "query AccountPreferencesQuery {\n  me {\n    ...AccountPreferencesForm_me\n    id\n  }\n}\n\nfragment AccountPreferencesForm_me on User {\n  id\n  homepage\n  homepageListId\n  eventLists {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  metricUnits\n  tz\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AccountPreferencesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AccountPreferencesForm_me",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountPreferencesQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "homepage",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "homepageListId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventLists",
            "storageKey": null,
            "args": null,
            "concreteType": "EventListRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventListRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventList",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "metricUnits",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tz",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '00cc97d1e47c031accd5155c26ef6fe9';
module.exports = node;
