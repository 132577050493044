/* @flow */
import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import showErrorPopup from 'utils/showErrorPopup';

import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import LoadMoreRow from 'components/LoadMoreRow';
import { type ColumnConfiguration } from 'components/material/table';
import QuickFadeIn from 'components/QuickFadeIn';
import ScrollbarSizes from 'components/ScrollbarSizes';

import TeamMembersTable from './TeamMembersTable';

import type { TeamMembersTablePagination_me } from './__generated__/TeamMembersTablePagination_me.graphql';
import type { TeamMembersTablePagination_team } from './__generated__/TeamMembersTablePagination_team.graphql';
import type { TeamMembersTablePagination_totalCountTeam } from './__generated__/TeamMembersTablePagination_totalCountTeam.graphql';

const TeamMembersItemContainer = styled(QuickFadeIn)`
  flex: 1 1 auto;
  padding-bottom: 40px;
  min-width: ${props => props.minWidth}px;
  overflow-y: auto;
`;

const StyledLoadMoreRow = styled(LoadMoreRow)`
  position: absolute;
  right: ${props => props.vScrollbarSize}px;
  bottom: ${props => props.hScrollbarSize}px;
  width: 100%;
  padding-right: 20px;
`;

type Props = {
  team: TeamMembersTablePagination_team,
  totalCountTeam: TeamMembersTablePagination_totalCountTeam,
  me: TeamMembersTablePagination_me,
  relay: RelayPaginationProp,
  history: RouterHistory,
  location: Location,
  shownColumns: ColumnConfiguration,
  onColumnSizeChange: (value: string, width: number) => void,
  width: number,
  className?: string,
  scrollContainer: HTMLElement,
};

class TeamMembersTablePagination extends React.Component<
  Props,
  {
    refetching: boolean,
    vScrollbarSize: number,
    hScrollbarSize: number,
  },
> {
  state = {
    refetching: false,
    vScrollbarSize: 0,
    hScrollbarSize: 0,
  };

  paginationDisposable: ?Disposable;

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  handleScrollbarSizesChange = (sizes: { vScrollbarSize: number, hScrollbarSize: number }) => {
    this.setState(sizes);
  };

  render() {
    const members = this.props.team.members;
    const totalCount = this.props.totalCountTeam.membersCount.totalCount;

    return (
      <TeamMembersItemContainer className={this.props.className} minWidth={this.props.width}>
        {totalCount > 0 && (
          <TeamMembersTable
            members={members}
            team={this.props.team}
            me={this.props.me}
            history={this.props.history}
            location={this.props.location}
            shownColumns={this.props.shownColumns}
            onColumnSizeChange={this.props.onColumnSizeChange}
          />
        )}
        <StyledLoadMoreRow
          onLoadMore={this.handleLoadMore}
          loading={this.state.refetching}
          totalCount={totalCount}
          hasNextPage={members.pageInfo.hasNextPage}
          vScrollbarSize={this.state.vScrollbarSize}
          hScrollbarSize={this.state.hScrollbarSize}
        />
        <ScrollbarSizes
          shouldUpdate={members.edges.length}
          scrollContainer={this.props.scrollContainer}
          onScrollbarSizesChange={this.handleScrollbarSizesChange}
          vScrollbarSize={this.state.vScrollbarSize}
          hScrollbarSize={this.state.hScrollbarSize}
        />
      </TeamMembersItemContainer>
    );
  }
}
export default createPaginationContainer(
  TeamMembersTablePagination,
  {
    team: graphql`
      fragment TeamMembersTablePagination_team on Team {
        id
        slug
        ...TeamMembersTable_team
        members(first: $count, after: $cursor, sort: $sort, direction: $direction)
          @connection(key: "TeamMembersTablePagination_members", filters: []) {
          edges {
            node {
              id
            }
          }
          ...TeamMembersTable_members
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    totalCountTeam: graphql`
      fragment TeamMembersTablePagination_totalCountTeam on Team {
        membersCount: members {
          totalCount
        }
      }
    `,
    me: graphql`
      fragment TeamMembersTablePagination_me on User {
        ...TeamMembersTable_me
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.team && props.team.members;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        teamSlug: props.team.slug,
        count,
        cursor,
      };
    },
    query: graphql`
      query TeamMembersTablePaginationQuery(
        $teamSlug: String!
        $count: Int!
        $cursor: String
        $sort: TeamMembershipSort!
        $direction: Direction!
      ) {
        team(slug: $teamSlug) {
          ...TeamMembersTablePagination_team
        }
      }
    `,
  },
);
