/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateTeamMutationResponse,
  updateTeamMutationVariables,
} from './__generated__/updateTeamMutation.graphql';

type MutationInput = $PropertyType<updateTeamMutationVariables, 'input'>;
type Variables = $Exact<$Diff<MutationInput, { teamId: string }>>;

const mutation = graphql`
  mutation updateTeamMutation($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        dbId
        name
        logo
        primaryColor
        secondaryColor
        scheduleName
        font
        slug
      }
    }
  }
`;

export default function updateTeam(
  teamDbId: number,
  teamId: string,
  team: Variables,
): Promise<updateTeamMutationResponse> {
  const variables: updateTeamMutationVariables = {
    input: {
      teamId,
      ...team,
    },
  };
  const optimisticResponse = {
    updateTeam: {
      team: { id: teamId, ...team },
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
