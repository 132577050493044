/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventSyncStatus_contactEvent$ref: FragmentReference;
export type EventSyncStatus_contactEvent = {|
  +syncStatus: {|
    +state: SyncState,
    +errorMessage: string,
  |},
  +node: {|
    +salesforceCampaign: ?{|
      +id: string
    |},
    +marketoProgram: ?{|
      +id: string
    |},
  |},
  +$refType: EventSyncStatus_contactEvent$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "EventSyncStatus_contactEvent",
  "type": "ContactEventEdge",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "syncStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "errorMessage",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "salesforceCampaign",
          "storageKey": null,
          "args": null,
          "concreteType": "SalesforceCampaign",
          "plural": false,
          "selections": v0
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "marketoProgram",
          "storageKey": null,
          "args": null,
          "concreteType": "MarketoProgram",
          "plural": false,
          "selections": v0
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7214c2a28b518eebfecb9509026e0785';
module.exports = node;
