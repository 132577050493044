/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import contactCreateMethods from 'components/Contacts/enums/contactCreateMethods';

import type { ContactCreatedMethod_contact } from './__generated__/ContactCreatedMethod_contact.graphql';

type Props = {
  contact: ContactCreatedMethod_contact,
};

const ContactCreatedMethod = (props: Props) => {
  const createdMethod = contactCreateMethods.find(
    option => option.value === props.contact.createdMethod,
  );
  return createdMethod ? createdMethod.label : null;
};

export default createFragmentContainer(
  ContactCreatedMethod,
  graphql`
    fragment ContactCreatedMethod_contact on Contact {
      createdMethod
    }
  `,
);
