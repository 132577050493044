/**
 * @flow
 * @relayHash 6085c1d65be7927cb5584dcdf191ed12
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SolutionName = "G2W" | "ZOOM";
export type IntegrationsQueryVariables = {||};
export type IntegrationsQueryResponse = {|
  +org: {|
    +salesforceAccount: ?{|
      +id: string
    |},
    +marketoAccount: ?{|
      +id: string
    |},
    +traySolutionInstances: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +solutionName: SolutionName,
          +upgradeNeeded: boolean,
        |}
      |}>
    |},
  |}
|};
export type IntegrationsQuery = {|
  variables: IntegrationsQueryVariables,
  response: IntegrationsQueryResponse,
|};
*/


/*
query IntegrationsQuery {
  org {
    salesforceAccount {
      id
    }
    marketoAccount {
      id
    }
    traySolutionInstances(includeExpired: true) {
      edges {
        node {
          solutionName
          upgradeNeeded
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "salesforceAccount",
  "storageKey": null,
  "args": null,
  "concreteType": "SalesforceAccount",
  "plural": false,
  "selections": v1
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "marketoAccount",
  "storageKey": null,
  "args": null,
  "concreteType": "MarketoAccount",
  "plural": false,
  "selections": v1
},
v4 = [
  {
    "kind": "Literal",
    "name": "includeExpired",
    "value": true,
    "type": "Boolean"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "solutionName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upgradeNeeded",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "IntegrationsQuery",
  "id": null,
  "text": "query IntegrationsQuery {\n  org {\n    salesforceAccount {\n      id\n    }\n    marketoAccount {\n      id\n    }\n    traySolutionInstances(includeExpired: true) {\n      edges {\n        node {\n          solutionName\n          upgradeNeeded\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "IntegrationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "traySolutionInstances",
            "storageKey": "traySolutionInstances(includeExpired:true)",
            "args": v4,
            "concreteType": "TraySolutionInstanceRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TraySolutionInstanceRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TraySolutionInstance",
                    "plural": false,
                    "selections": [
                      v5,
                      v6
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "IntegrationsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "traySolutionInstances",
            "storageKey": "traySolutionInstances(includeExpired:true)",
            "args": v4,
            "concreteType": "TraySolutionInstanceRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TraySolutionInstanceRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TraySolutionInstance",
                    "plural": false,
                    "selections": [
                      v5,
                      v6,
                      v0
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd7d335ff88f352509dc3c41febf8dfee';
module.exports = node;
