/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import getTextSize from 'utils/getTextSize';

import { type ColumnConfiguration } from 'components/material/table';

import TeamMembersTableRow from './TeamMembersTableRow';

import type { TeamMembersTable_me } from './__generated__/TeamMembersTable_me.graphql';
import type { TeamMembersTable_members } from './__generated__/TeamMembersTable_members.graphql';
import type { TeamMembersTable_team } from './__generated__/TeamMembersTable_team.graphql';

const TableContainer = styled.div`
  height: 100%;
  overflow-x: hidden;
  border-bottom: 1px solid ${props => props.theme.primaryRowColor};
  border-top: none;
  background: #fff;
  > div:first-child {
    border-top: none;
  }
`;

class TeamMembersTable extends React.Component<{
  members: TeamMembersTable_members,
  me: TeamMembersTable_me,
  team: TeamMembersTable_team,
  history: RouterHistory,
  location: Location,
  shownColumns: ColumnConfiguration,
  onColumnSizeChange: (value: string, width: number) => void,
}> {
  handleColumnLoad = (value: string, str: string, extraSpace = 0) => {
    // 15 is the column padding, 500 is max width
    const columnSize = Math.min(getTextSize(str) + 15 + extraSpace, 500);

    if (columnSize > this.props.shownColumns.find(c => c.value === value).width) {
      this.props.onColumnSizeChange(value, columnSize);
    }
  };

  render() {
    return (
      <TableContainer>
        {this.props.members.edges.map(memberEdge => (
          <TeamMembersTableRow
            key={memberEdge.node.id}
            team={this.props.team}
            me={this.props.me}
            member={memberEdge}
            history={this.props.history}
            location={this.props.location}
            shownColumns={this.props.shownColumns}
            onColumnLoad={this.handleColumnLoad}
          />
        ))}
      </TableContainer>
    );
  }
}

export default createFragmentContainer(TeamMembersTable, {
  members: graphql`
    fragment TeamMembersTable_members on TeamMemberConnection {
      edges {
        ...TeamMembersTableRow_member
        node {
          id
        }
      }
    }
  `,
  team: graphql`
    fragment TeamMembersTable_team on Team {
      ...TeamMembersTableRow_team
    }
  `,
  me: graphql`
    fragment TeamMembersTable_me on User {
      ...TeamMembersTableRow_me
    }
  `,
});
