/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import BudgetContainer from './BudgetContainer';

const query = graphql`
  query BudgetQuery {
    org {
      ...BudgetContainer_org
    }
    me {
      ...BudgetContainer_me
    }
  }
`;

const Container = styled.div`
  padding: 0 25px 24px;
`;

export default class BudgetQuery extends React.Component<{
  match: Match,
  history: RouterHistory,
}> {
  render() {
    const { match, history } = this.props;
    return (
      <Container>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={props => (
            <BudgetContainer org={props.org} me={props.me} match={match} history={history} />
          )}
        />
      </Container>
    );
  }
}
