/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { Content } from 'components/page/Content';

import parseStaffFilters from './lib/parseStaffFilters';
import StaffPage from './StaffPage';

import type { StaffQueryResponse } from './__generated__/StaffQuery.graphql';

const StyledContent = styled(Content)`
  min-height: 0;
`;

// Additional properties for event are used in staff import logic
const query = graphql`
  query StaffQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...StaffPage_event
    }
    org {
      ...StaffPage_org
    }
  }
`;

export default class Staff extends React.Component<{
  match: Match,
  location: Location,
  history: History,
}> {
  render() {
    const { match, location, history } = this.props;
    const eventSlug: string = match.params.event_slug;
    const filters = parseStaffFilters(location.search);

    return (
      <StyledContent listView>
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug }}
          renderSuccess={(response: StaffQueryResponse) =>
            response.event && (
              <StaffPage
                event={response.event}
                org={response.org}
                filters={filters}
                location={location}
                history={history}
              />
            )
          }
        />
      </StyledContent>
    );
  }
}
