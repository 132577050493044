/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import SimpleBriefField from '../../components/SimpleBriefField';

import { type EventInfoNumberOfOpportunities_event } from './__generated__/EventInfoNumberOfOpportunities_event.graphql';

const EventInfoNumberOfOpportunities = (props: {
  name: string,
  event: EventInfoNumberOfOpportunities_event,
}) => {
  const {
    name,
    event: { salesforceCampaign },
  } = props;
  return (
    <SimpleBriefField
      label={name}
      contentDataAvailable={salesforceCampaign && salesforceCampaign.numberOfOpportunities}
      data={salesforceCampaign && salesforceCampaign.numberOfOpportunities}
    />
  );
};

export default createFragmentContainer(
  EventInfoNumberOfOpportunities,
  graphql`
    fragment EventInfoNumberOfOpportunities_event on Event {
      salesforceCampaign {
        numberOfOpportunities
      }
    }
  `,
);
