/* @flow */
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { type Location, Redirect, Route, Switch } from 'react-router-dom';

import headerTitles from './lib/headerTitles';
import TeamMembers from './TeamMembers';
import TeamStyles from './TeamStyles';

export default class TeamRoutes extends React.Component<{
  team: {
    id: number,
    relay_id: string,
    slug: string,
    name: string,
  },
  pathPrefix: string,
  rootUrl: string,
  location: Location,
}> {
  render() {
    const { team, rootUrl, pathPrefix, location } = this.props;
    const teamSlug = team.slug;
    const title = headerTitles[location.pathname.split('/').pop()] || headerTitles.staffing;
    return (
      <DocumentTitle title={`${title} - ${team.name}`}>
        <Switch>
          <Route
            path={`${pathPrefix}/style`}
            exact
            render={() => <TeamStyles teamId={team.relay_id} />}
          />
          <Route
            path={`${pathPrefix}/members`}
            render={props => <TeamMembers {...props} teamSlug={teamSlug} />}
          />
          <Redirect from={`${pathPrefix}`} to={`${rootUrl}/members`} />
        </Switch>
      </DocumentTitle>
    );
  }
}
