/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import omit from 'lodash/omit';

import convertCustomFieldValuesToInput from 'utils/customization/convertCustomFieldValuesToInput';
import getCustomFieldPlainValue from 'utils/customization/getCustomFieldPlainValue';
import getOptionKeysByKind from 'utils/customization/getOptionKeysByKind';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import { type CustomFieldsType, type FieldType } from 'utils/customization/types';
import isValidWebsite from 'utils/validators/isValidWebsite';

import createCompany, {
  type CreateCompanyFromWindow,
  type ResponseCompany,
} from 'graph/mutations/company/createCompany';
import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';
import { type UpdateCustomFieldValueInput } from 'graph/mutations/custom_field/updateCustomFieldValue';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { InputVariableFilters } from 'components/Companies/companiesTableColumnSettings';
import customFields, { type InexactUser } from 'components/Customizable/customFields';
import TextField from 'components/material/TextField';
import { type CompanyDefaultFields } from 'views/Main/AllContacts/Company/tabs/CompanyProfile/CompanyFormFields';
import suggestedFields from 'views/Main/AllContacts/Company/tabs/CompanyProfile/suggestedFields';

import { type ContactType } from './ContactTypeSelector';
import ContactWindowFooter from './ContactWindowFooter';

import { type CompanyForm_org } from './__generated__/CompanyForm_org.graphql';

const Row = styled.div`
  display: flex;
  margin: 20px 0;
`;

class CompanyForm extends React.PureComponent<
  {
    org: CompanyForm_org,
    tz: string,
    fromWindow: ?CreateContactFromWindow | CreateCompanyFromWindow,
    eventId: ?string,
    defaultActiveType?: ContactType,
    filterVariables: InputVariableFilters,
    onHide: () => void,
    onSave: ?('companies', ResponseCompany) => void,
    setOnContactFormEdited: (onHide: () => boolean) => void,
  },
  {
    defaultFieldValues: CompanyDefaultFields,
    customFieldValues: CustomFieldsType,
    errors: { [string]: string },
    loading: boolean,
    formEdited: boolean,
  },
> {
  state = {
    defaultFieldValues: {
      name: '',
      phone: null,
      website: null,
      twitter: null,
      linkedin: null,
      description: null,
      country: 'United States',
      state: null,
      city: null,
      zip: null,
      street: null,
    },
    customFieldValues: {
      customTextFields: [],
      customTextareaFields: [],
      customLinkFields: [],
      customDateFields: [],
      customNumberFields: [],
      customCurrencyFields: [],
      customBooleanFields: [],
      customUserSelectFields: [],
      customUserMultiselectFields: [],
      customSelectFields: [],
      customMultiselectFields: [],
    },
    errors: {},
    loading: false,
    formEdited: false,
  };

  componentDidMount() {
    this.props.setOnContactFormEdited(this.checkContactFormEdited);
  }

  checkContactFormEdited = (): boolean => {
    return this.state.formEdited;
  };

  handleChangeName = (event: SyntheticEvent<HTMLInputElement>) => {
    const name = event.currentTarget.value;

    this.setState(prevState => ({
      defaultFieldValues: { ...prevState.defaultFieldValues, name },
      formEdited: true,
    }));

    this.validateName(name);
  };

  handleUpdateCustomField = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    const { arrayKey, optionKey } = getOptionKeysByKind(field.kind);
    const value = Object.values(input)[0];
    const valuesArray = (Array.isArray(value)
      ? value
      : [value].filter(val => val != null)
    ).map(optionId => (users == null ? optionId : users.find(user => user.id === optionId)));

    this.setState(prevState => ({
      customFieldValues: {
        ...prevState.customFieldValues,
        [arrayKey]: [
          ...prevState.customFieldValues[arrayKey].filter(
            option => option.customField.id !== field.id,
          ),
          ...valuesArray.map(option => ({
            customField: { id: field.id },
            [optionKey]: optionKey === 'option' ? { id: option } : option,
          })),
        ],
      },
      formEdited: true,
    }));

    this.validateField(field, Object.values(input)[0]);
  };

  handleUpdateSuggestedField = (field: FieldType, changes: CompanyDefaultFields) => {
    this.setState(prevState => ({
      defaultFieldValues: { ...prevState.defaultFieldValues, ...changes },
      formEdited: true,
    }));

    this.validateField(field, Object.values(changes)[0]);
  };

  validateName = (value: ?string) =>
    new Promise(resolve =>
      this.setState(prevState => {
        if (value == null || !value.trim()) {
          return { errors: { ...prevState.errors, name: 'Name is required' } };
        }

        return {
          errors: omit(prevState.errors, 'name'),
        };
      }, resolve),
    );

  validateField = (field: FieldType, value: mixed) =>
    new Promise(resolve =>
      this.setState(prevState => {
        const key = field.fieldName || field.id;

        if (
          field.required &&
          (value == null ||
            value === false ||
            (typeof value === 'string' && !value.trim()) ||
            (Array.isArray(value) && value.length === 0))
        ) {
          return { errors: { ...prevState.errors, [key]: 'Required' } };
        }

        if (
          (field.kind === 'LINK' || ['website', 'twitter', 'linkedin'].includes(key)) &&
          value != null &&
          value !== '' &&
          !isValidWebsite(String(value))
        ) {
          return { errors: { ...prevState.errors, [key]: 'Invalid url' } };
        }

        return { errors: omit(prevState.errors, key) };
      }, resolve),
    );

  validateForm = () => {
    const { defaultFieldValues, customFieldValues } = this.state;

    return Promise.all([
      this.validateName(this.state.defaultFieldValues.name),
      ...this.normalizedCustomFields().map(field =>
        this.validateField(
          field,
          field.fieldName
            ? defaultFieldValues[field.fieldName]
            : getCustomFieldPlainValue(field, customFieldValues),
        ),
      ),
    ]);
  };

  handleSubmit = () => {
    const {
      org,
      fromWindow,
      eventId,
      onHide,
      onSave,
      filterVariables,
      defaultActiveType,
    } = this.props;
    const { defaultFieldValues, customFieldValues } = this.state;

    this.validateForm().then(() => {
      if (Object.keys(this.state.errors).length > 0) {
        const windows = document.getElementsByClassName('popupWindow');
        const popup = windows[windows.length - 1];
        if (popup) {
          popup.scrollTop = 0;
        }
        return;
      }

      this.setState({ loading: true });

      createCompany(
        org.id,
        {
          eventIds: eventId ? [eventId] : null,
          fromWindow,
          ...defaultFieldValues,
          customFieldValues: convertCustomFieldValuesToInput(customFieldValues),
        },
        defaultActiveType === 'companies' ? filterVariables : null,
      )
        .then((responseCompany: ResponseCompany) => {
          if (onSave) {
            onSave('companies', responseCompany);
          }

          onHide();
        })
        .catch(error => {
          showModernMutationError(error);

          this.setState({ loading: false });
        });
    });
  };

  normalizedCustomFields = () =>
    normalizeCustomFields(this.props.org.customFields.edges.map(edge => edge.node));

  render() {
    const { org, tz, onHide } = this.props;
    const { defaultFieldValues, customFieldValues, errors, loading } = this.state;

    return (
      <div>
        <Row>
          <TextField
            required
            name="name"
            label="Name"
            autoFocus
            value={defaultFieldValues.name || ''}
            onChange={this.handleChangeName}
            error={errors.name}
          />
        </Row>

        {this.normalizedCustomFields().map(field => {
          if (field.kind === 'DEFAULT') {
            const renderer = field.fieldName && suggestedFields[field.fieldName];

            if (!renderer) return null;

            return (
              <Row key={field.id}>
                {renderer({
                  company: defaultFieldValues,
                  field,
                  errors,
                  viewerCanUpdate: true,
                  handleUpdate: changes => this.handleUpdateSuggestedField(field, changes),
                })}
              </Row>
            );
          }

          return (
            <Row key={field.id}>
              {customFields[field.kind]({
                customizable: customFieldValues,
                field,
                errors,
                viewerCanUpdateValue: true,
                tz,
                currency: org.settings.currency,
                handleUpdate: (input, users) => this.handleUpdateCustomField(field, input, users),
              })}
            </Row>
          );
        })}

        <ContactWindowFooter
          showManageLink={org.viewerCanUpdate}
          contactType="companies"
          loading={loading}
          onCancel={onHide}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default createFragmentContainer(
  CompanyForm,
  graphql`
    fragment CompanyForm_org on Org {
      id
      viewerCanUpdate

      settings {
        currency
      }

      customFields(
        customizableType: $customizableType
        onlyCreateForm: true
        excludeSalesforcePullOnlyFields: $excludeSalesforcePullOnlyFields
      ) {
        edges {
          node {
            id
            kind
            label
            order
            required
            fieldName
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
);
