import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import qs from 'qs';

import { Content } from 'components/page/Content';
import NoResultsMessage from 'components/NoResultsMessage';
import GoogleDocsSchedule from './ScheduleItem/GoogleDocExportModal/GoogleDocsSchedule'; // eslint-disable-line

import createSchedule from 'graph/mutations/schedule/createSchedule';
import duplicateSchedule from 'graph/mutations/schedule/duplicateSchedule';
import removeSchedule from 'graph/mutations/schedule/removeSchedule';
import updateSchedule from 'graph/mutations/schedule/updateSchedule';
import showModernMutationError from 'graph/utils/showModernMutationError';
import downloadedSchedule from 'utils/analytics/events/downloadedSchedule';

import downloadICS from 'utils/requests/downloadICS';
import showErrorPopup from 'utils/showErrorPopup';
import GoogleDocExportModal from './ScheduleItem/GoogleDocExportModal';
import ScheduleItem from './ScheduleItem';
import SchedulesHeader from './SchedulesHeader';

class SchedulesPage extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      schedules: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              slug: PropTypes.string.isRequired,
              slugs: PropTypes.arrayOf(
                PropTypes.shape({
                  slug: PropTypes.string.isRequired,
                }),
              ).isRequired,
              order: PropTypes.number.isRequired,
              dbId: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
              token: PropTypes.string.isRequired,
            }).isRequired,
          }),
        ).isRequired,
      }).isRequired,
    }),
    scheduleSlug: PropTypes.string,
    eventId: PropTypes.number.isRequired,
    teamId: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
    defaultAttendee: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    queryParams: PropTypes.object.isRequired,
    user: PropTypes.shape({
      icsToken: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    exportingGDoc: false,
  };

  componentDidMount() {
    this.handleScheduleRedirect(this.props.scheduleSlug, this.getSchedules(this.props.event));
  }

  getSchedules = event => event.schedules.edges.map(x => x.node);

  handleScheduleRedirect = (scheduleSlug, schedules) => {
    const eventSlug = this.props.event.slug;
    const defaultAttendee = this.props.defaultAttendee;

    if (
      schedules.length > 0 &&
      (!scheduleSlug || !schedules.find(x => x.slugs.some(({ slug }) => slug === scheduleSlug)))
    ) {
      const { slug } = sortBy(schedules, 'order')[0];
      this.props.history.replace(
        `/events/${eventSlug}/schedules/${slug}${
          defaultAttendee ? `?attendee=${defaultAttendee}` : ''
        }`,
      );
    }
  };

  handleScheduleChange = scheduleSlug => {
    const eventSlug = this.props.event.slug;

    this.props.history.push(`/events/${eventSlug}/schedules/${scheduleSlug}`);
  };

  handleScheduleCreate = schedule => {
    createSchedule(this.props.event.id, { name: null, order: 0, ...schedule })
      .then(createdSchedule => {
        this.handleScheduleChange(createdSchedule.slug);
      })
      .catch(showModernMutationError);
  };

  handleScheduleCopy = (scheduleId, order) => {
    duplicateSchedule(this.props.event.id, scheduleId, { order })
      .then(createdSchedule => {
        this.handleScheduleChange(createdSchedule.slug);
      })
      .catch(showModernMutationError);
  };

  handleScheduleRemove = scheduleId => {
    removeSchedule(this.props.event.id, scheduleId).catch(showModernMutationError);
  };

  handleScheduleUpdate = (scheduleId, schedule) => {
    updateSchedule(scheduleId, schedule).catch(showModernMutationError);
  };

  handlePrintClick = scheduleId => {
    const { event } = this.props;
    if (this.props.filters.member || this.props.filters.attendee) {
      window.open(`/print/schedule/${scheduleId}?${qs.stringify(this.props.filters)}`);
    } else {
      window.open(`/print/schedule/${scheduleId}`);
    }
    downloadedSchedule({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'pdf',
    });
  };

  handleICalClick = scheduleToken => {
    const { event } = this.props;

    const link = `/v1/schedules/${scheduleToken}/ical?ics_token=${this.props.user.icsToken}${
      this.props.filters.member ? `&member=${this.props.filters.member}` : ''
    }`;

    downloadedSchedule({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'calendar',
    });
    downloadICS(link, `${event.name || 'event'}.ics`).catch(showErrorPopup);
  };

  handleShareClick = scheduleToken => {
    window.open(`/share/schedule/${scheduleToken}`);
  };

  handleWordClick = () => {
    this.setState({ exportingGDoc: true });
  };

  handleViewChange = () => {
    const { scheduleSlug, event } = this.props;

    this.props.history.push(`/events/${event.slug}/schedules?active=${scheduleSlug}`);
  };

  render() {
    const { event, scheduleSlug, eventId, teamId, filters } = this.props;

    const schedules = this.getSchedules(event);
    const activeSchedule = schedules.find(x => x.slugs.some(({ slug }) => slug === scheduleSlug));

    return (
      <Content className="schedules">
        <SchedulesHeader
          event={event}
          activeScheduleId={activeSchedule && activeSchedule.id}
          onScheduleChange={this.handleScheduleChange}
          onPrintClick={this.handlePrintClick}
          onICalClick={this.handleICalClick}
          onShareClick={this.handleShareClick}
          onWordClick={this.handleWordClick}
          teamId={teamId}
          onScheduleCreate={this.handleScheduleCreate}
          onScheduleCopy={this.handleScheduleCopy}
          onScheduleRemove={this.handleScheduleRemove}
          onScheduleUpdate={this.handleScheduleUpdate}
          filters={filters}
          onViewChange={this.handleViewChange}
        />
        {activeSchedule && (
          <ScheduleItem
            id={activeSchedule.id}
            eventId={eventId}
            teamId={teamId}
            queryParams={this.props.queryParams}
          />
        )}
        {schedules.length === 0 && (
          <NoResultsMessage iconName="clock-o" message="There are no schedules to show" />
        )}
        {this.state.exportingGDoc && (
          <GoogleDocExportModal
            onCancel={() => {
              this.setState({ exportingGDoc: false });
            }}
            schedule={activeSchedule}
            event={event}
            member={filters.member}
          />
        )}
      </Content>
    );
  }
}

export default createFragmentContainer(SchedulesPage, {
  event: graphql`
    fragment SchedulesPage_event on Event {
      id
      dbId
      slug
      name
      team {
        dbId
        name
      }
      schedules {
        edges {
          node {
            id
            slug
            slugs {
              slug
            }
            order
            dbId
            name
            token
            ...GoogleDocsSchedule_schedule
          }
        }
      }
      ...SchedulesHeader_event
      ...GoogleDocsSchedule_event
    }
  `,
  user: graphql`
    fragment SchedulesPage_user on User {
      icsToken
    }
  `,
});
