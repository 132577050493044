/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import CompanyOpportunities from './CompanyOpportunities';
import CompanyContacts from './tabs/CompanyContacts';
import CompanyProfile from './tabs/CompanyProfile';

import type { CompanyPageRoutes_event } from './__generated__/CompanyPage_event.graphql';
import type { CompanyPageRoutes_user } from './__generated__/CompanyPage_user.graphql';
import type { CompanyPageRoutes_company } from './__generated__/CompanyPageRoutes_company.graphql';
import type { CompanyPageRoutes_org } from './__generated__/CompanyPageRoutes_org.graphql';

const Container = styled.div`
  padding: 0 33px;
`;

class CompanyPageRoutes extends React.Component<{
  company: CompanyPageRoutes_company,
  org: CompanyPageRoutes_org,
  event?: CompanyPageRoutes_event,
  user: CompanyPageRoutes_user,
  contactsCount: number,
  opportunitiesSyncEnabled: boolean,
  pathPrefix: string,
  rootUrl: string,
}> {
  render() {
    const {
      user,
      org,
      company,
      event,
      contactsCount,
      opportunitiesSyncEnabled,
      pathPrefix,
      rootUrl,
    } = this.props;
    return (
      <Container>
        <Switch>
          <Redirect from={pathPrefix} exact to={`${rootUrl}/profile`} />
          <Route
            path={`${pathPrefix}/profile`}
            render={props => (
              <CompanyProfile user={user} company={company} event={event} {...props} />
            )}
          />
          <Route
            path={`${pathPrefix}/people`}
            render={props => (
              <CompanyContacts
                company={company}
                user={user}
                contactsCount={contactsCount}
                event={event}
                {...props}
              />
            )}
          />
          {opportunitiesSyncEnabled && (
            <Route
              path={`${pathPrefix}/opportunities`}
              render={props => <CompanyOpportunities {...props} org={org} companyId={company.id} />}
            />
          )}
        </Switch>
      </Container>
    );
  }
}

export default createFragmentContainer(CompanyPageRoutes, {
  org: graphql`
    fragment CompanyPageRoutes_org on Org {
      ...CompanyOpportunities_org
    }
  `,
  company: graphql`
    fragment CompanyPageRoutes_company on Company {
      id
      ...CompanyProfile_company
      ...CompanyContacts_company
    }
  `,
  user: graphql`
    fragment CompanyPageRoutes_user on User {
      ...CompanyProfile_user
    }
  `,
  event: graphql`
    fragment CompanyPageRoutes_event on Event {
      ...CompanyProfile_event
      ...CompanyContacts_event
    }
  `,
});
