/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import type { RequestSubmissionEventName_requestSubmission } from './__generated__/RequestSubmissionEventName_requestSubmission.graphql';

const StyledLink = styled(Link)`
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

type Props = {
  requestSubmission: RequestSubmissionEventName_requestSubmission,
  location: Location,
};

const RequestSubmissionEventName = ({ requestSubmission, location }: Props) => {
  return (
    <StyledLink to={`/dashboard/event_requests/${requestSubmission.id}${location.search}`}>
      {requestSubmission.event.name}
    </StyledLink>
  );
};

export default createFragmentContainer(
  withRouter(RequestSubmissionEventName),
  graphql`
    fragment RequestSubmissionEventName_requestSubmission on EventRequestSubmission {
      id
      event {
        name
      }
    }
  `,
);
