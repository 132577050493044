/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import difference from 'lodash/difference';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import type { RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';
import TablePagination from 'components/budget/Table/TablePagination';

import colsConfig, { type MembersCellPropsType } from './columns';
import { type ParsedMembersFilters } from './lib/parseMembersFilters';

import { type MembersPagination_org } from './__generated__/MembersPagination_org.graphql';
import { type MembersPagination_totalCountUsers } from './__generated__/MembersPagination_totalCountUsers.graphql';

type MemberType = $PropertyType<
  $ElementType<$PropertyType<$PropertyType<MembersPagination_org, 'users'>, 'edges'>, 0>,
  'node',
>;

const StaffContentContainer = styled.div`
  width: 100%;
`;

type Props = {
  org: ?MembersPagination_org,
  location: Location,
  history: RouterHistory,
  filters: ParsedMembersFilters,
  tz: string,
  totalCountUsers: ?MembersPagination_totalCountUsers,
  relay: RelayPaginationProp,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onMembersListUpdate: (availableMembers: $ReadOnlyArray<string>) => void,
};

type State = {
  refetching: boolean,
  availableMembers: $ReadOnlyArray<string>,
};

class MembersPagination extends React.Component<Props, State> {
  state = {
    refetching: false,
    availableMembers: [],
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const prevItems = prevState.availableMembers;
    const nextItems = nextProps.org ? nextProps.org.users.edges.map(({ node }) => node.id) : [];
    if (
      nextProps.org &&
      (difference(prevItems, nextItems).length > 0 || difference(nextItems, prevItems).length > 0)
    ) {
      nextProps.onMembersListUpdate(nextItems);
      return { availableMembers: nextItems };
    }
    return null;
  }

  cellProps = (user: MemberType): MembersCellPropsType<MemberType> => {
    const { location, tz, filters } = this.props;
    return {
      user,
      tz,
      location,
      filters,
    };
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  handleRowClick = (user: MemberType) => {
    if (user.viewerCanSeeProfile) {
      return () => {
        this.props.history.push(`/workspace/members/${user.id}`, {
          prevPage: true,
          search: this.props.location.search,
        });
      };
    }
    return null;
  };

  keyExtractor = (user: MemberType) => {
    return user.id;
  };

  render() {
    const { org, totalCountUsers, sort, onChangeSort } = this.props;

    return (
      <StaffContentContainer>
        {org != null && org.users.edges.length === 0 ? (
          <EmptyView message="No members match filters" icon={<NoResult />} />
        ) : (
          <Table
            data={org != null ? org.users.edges.map(({ node }) => node) : null}
            columns={colsConfig}
            sort={sort}
            onChangeSort={onChangeSort}
            cellProps={this.cellProps}
            keyExtractor={this.keyExtractor}
            rowClickHandler={this.handleRowClick}
          />
        )}

        {totalCountUsers != null && org != null && (
          <TablePagination
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            currentCount={org.users.edges.length !== 0 ? org.users.edges.length : null}
            totalCount={totalCountUsers.orgUsers.totalCount}
            entityName="Members"
          />
        )}
      </StaffContentContainer>
    );
  }
}
export default createPaginationContainer(
  MembersPagination,
  {
    org: graphql`
      fragment MembersPagination_org on Org {
        id
        users(first: $count, after: $cursor, sort: $sort, direction: $direction, query: $query)
          @connection(key: "MembersPagination_users", filters: []) {
          edges {
            node {
              id
              viewerCanSeeProfile
              ...MemberFullName_user
              ...MemberEmail_user
              ...MemberEventCount_user
              ...MemberTeamCount_user
              ...MemberLastActivity_user
              ...MemberInviteStatus_user
              ...MembersActionsColumn_user
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    totalCountUsers: graphql`
      fragment MembersPagination_totalCountUsers on Org {
        orgUsers: users(query: $query) {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.org && props.org.users;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        count,
        cursor,
      };
    },
    query: graphql`
      query MembersPaginationQuery(
        $count: Int!
        $cursor: String
        $sort: UserSort!
        $direction: Direction!
        $query: String
      ) {
        org {
          ...MembersPagination_org
        }
      }
    `,
  },
);
