/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MarketoProgramPicker_org$ref: FragmentReference;
export type MarketoProgramPicker_org = {|
  +marketoAccount: ?{|
    +id: string,
    +marketoPrograms?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +marketoId: string,
          +name: string,
          +url: string,
          +event: ?{|
            +name: string,
            +slug: string,
          |},
        |}
      |}>
    |},
  |},
  +$refType: MarketoProgramPicker_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MarketoProgramPicker_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "loadPrograms",
      "type": "Boolean!",
      "defaultValue": false
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "marketoAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "MarketoAccount",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "Condition",
          "passingValue": true,
          "condition": "loadPrograms",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "marketoPrograms",
              "storageKey": null,
              "args": null,
              "concreteType": "MarketoProgramRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MarketoProgramRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "MarketoProgram",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "marketoId",
                          "args": null,
                          "storageKey": null
                        },
                        v0,
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "url",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "event",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "Event",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "slug",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b6db2e208d2615a14a7f374a6545852';
module.exports = node;
