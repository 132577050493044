/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import {
  type ResponseVendor,
  type CreateVendorFromWindow,
} from 'graph/mutations/vendor/createVendor';

import Window, {
  WindowHeader,
  WindowTitle,
  WindowClose,
  WindowContent,
} from 'components/material/Window';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { type VendorFilterInputs } from 'components/Vendors/lib/renderVendorFilter';
import VendorForm from './VendorForm';

import { type CreateVendorWindowQueryResponse } from './__generated__/CreateVendorWindowQuery.graphql';

const query = graphql`
  query CreateVendorWindowQuery {
    org {
      ...VendorForm_org
    }
    me {
      tz
    }
  }
`;

export default class CreateVendorWindow extends React.PureComponent<{
  onHide: () => void,
  eventId?: ?string,
  onSave?: ResponseVendor => void,
  filterVariables?: VendorFilterInputs,
  fromWindow: CreateVendorFromWindow,
}> {
  onVendorFormEdited: ?() => boolean = null;

  handleHide = () => {
    if (!this.onVendorFormEdited || !this.onVendorFormEdited()) {
      this.props.onHide();
    }
  };

  setOnVendorFormEdited = (onHide: () => boolean) => {
    this.onVendorFormEdited = onHide;
  };

  renderContent = (response: CreateVendorWindowQueryResponse) => {
    const { onHide, onSave, filterVariables, fromWindow, eventId } = this.props;

    return (
      <VendorForm
        org={response.org}
        tz={response.me.tz}
        filterVariables={filterVariables}
        fromWindow={fromWindow}
        onSave={onSave}
        onHide={onHide}
        eventId={eventId}
        setOnVendorFormEdited={this.setOnVendorFormEdited}
      />
    );
  };

  render() {
    return (
      <Window onHide={this.handleHide} size={500}>
        <WindowHeader>
          <WindowTitle>Create New Vendor</WindowTitle>
          <WindowClose onClick={this.props.onHide} />
        </WindowHeader>

        <WindowContent>
          <DefaultQueryRenderer query={query} renderSuccess={this.renderContent} />
        </WindowContent>
      </Window>
    );
  }
}
