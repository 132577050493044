/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css, keyframes } from 'styled-components';

import UIContext from '../UIContext';
import EmailSettings from './EmailSettings';
import GeneralSettings from './GeneralSettings';
import RegistrationSettingsIntegrations from './RegistrationSettingsIntegrations';

import type { RegistrationSettingsContent_event } from './__generated__/RegistrationSettingsContent_event.graphql';
import type { RegistrationSettingsContent_me } from './__generated__/RegistrationSettingsContent_me.graphql';
import type { RegistrationSettingsContent_org } from './__generated__/RegistrationSettingsContent_org.graphql';
import type { RegistrationSettingsContent_registrationForm } from './__generated__/RegistrationSettingsContent_registrationForm.graphql';

type Section = 'general' | 'email' | 'integrations';

const fadeIn = keyframes`
  from { opacity: 0; visibility: hidden;}
  to { opacity: 1; visibility: visible;}
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 1%;
`;

const Navigation = styled.div`
  margin-left: 72px;
  padding-top: 40px;
  @media (${props => props.theme.mobileOnly}) {
    margin-left: 10px;
  }
`;

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 40px 30px 0;
  overflow-y: auto;
  scroll-behavior: smooth;
  @media (${props => props.theme.mobileOnly}) {
    padding-left: 25px;
  }
`;

const Content = styled.div`
  max-width: 755px;
  width: 100%;
`;

const Border = styled.div`
  flex: 0 0 auto;
  height: 18px;
  width: 3px;
  margin-right: 10px;
  background-color: #3ba9da;
  border-radius: 35%;
  opacity: 0;
  visibility: hidden;
`;

const Text = styled.a`
  width: 100px;
  line-height: 1em;
  color: rgba(75, 75, 75, 0.6);
  cursor: pointer;
  &:hover {
    font-weight: 500;
    color: #4b4b4b;
  }
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  ${props =>
    props.active &&
    css`
      ${Border} {
        animation: 1s ${fadeIn} forwards;
      }
      ${Text} {
        font-weight: 500;
        color: #4b4b4b;
      }
    `}
`;

const ContentItem = styled.div`
  margin-bottom: 30px;
`;

class RegistrationSettingsContent extends React.PureComponent<
  {
    org: RegistrationSettingsContent_org,
    me: RegistrationSettingsContent_me,
    event?: RegistrationSettingsContent_event,
    registrationForm: RegistrationSettingsContent_registrationForm,
  },
  {
    activeSection: Section,
  },
> {
  state = { activeSection: 'general' };

  scrollContainer = React.createRef();

  componentDidMount() {
    if (!this.scrollContainer || !this.scrollContainer.current) {
      return;
    }
    this.scrollContainer.current.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    if (!this.scrollContainer || !this.scrollContainer.current) {
      return;
    }
    this.scrollContainer.current.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e: SyntheticEvent<HTMLElement>) => {
    if (!(e.target instanceof Element) || !this.scrollContainer || !this.scrollContainer.current) {
      return;
    }
    const scrollContainer = this.scrollContainer.current;
    const scrollPosition = e.target.scrollTop;
    const baseOffset = scrollContainer.offsetTop;
    if (scrollPosition === e.target.scrollHeight - e.target.clientHeight) {
      // put here active last section
      this.setState({ activeSection: 'integrations' });
      return;
    }

    scrollContainer.childNodes[0].childNodes.forEach(section => {
      if (
        scrollPosition >= section.offsetTop - baseOffset &&
        scrollPosition < section.offsetTop + section.scrollHeight - baseOffset
      ) {
        this.setState({ activeSection: section.id });
      }
    });
  };

  handleGeneralClick = () => {
    this.scrollToSection('general');
  };

  handleEmailClick = () => {
    this.scrollToSection('email');
  };

  handleIntegrationsClick = () => {
    this.scrollToSection('integrations');
  };

  scrollToSection = (id: string) => {
    const elem = document.getElementById(id);
    if (!elem || !this.scrollContainer || !this.scrollContainer.current) {
      return;
    }
    this.scrollToSmoothly(elem.offsetTop - this.scrollContainer.current.offsetTop);
  };

  scrollToSmoothly = (position: number) => {
    if (position < 0) {
      return;
    }
    if (!this.scrollContainer || !this.scrollContainer.current) {
      return;
    }
    const scrollContainer = this.scrollContainer.current;
    scrollContainer.scrollTop = position;
  };

  render() {
    const { me, org, event, registrationForm } = this.props;
    const activeSection = this.state.activeSection;
    return (
      <Container>
        {!org.syncedToIbmWm && (
          <Navigation>
            <NavItem active={activeSection === 'general'}>
              <Border />
              <Text onClick={this.handleGeneralClick}>GENERAL</Text>
            </NavItem>
            <NavItem active={activeSection === 'email'}>
              <Border />
              <Text onClick={this.handleEmailClick}>EMAIL</Text>
            </NavItem>
            {event && (
              <NavItem active={activeSection === 'integrations'}>
                <Border />
                <Text onClick={this.handleIntegrationsClick}>INTEGRATIONS</Text>
              </NavItem>
            )}
          </Navigation>
        )}

        <ContentWrapper ref={this.scrollContainer}>
          <Content>
            <ContentItem id="general">
              <UIContext.Consumer>
                {({ fontFamily }) => (
                  <GeneralSettings
                    org={org}
                    isOrgTemplate={event == null}
                    registrationForm={registrationForm}
                    defaultFont={fontFamily}
                  />
                )}
              </UIContext.Consumer>
            </ContentItem>
            <ContentItem id="email">
              <UIContext.Consumer>
                {({ fontFamily }) => (
                  <EmailSettings
                    me={me}
                    event={event}
                    org={org}
                    registrationForm={registrationForm}
                    defaultFont={fontFamily}
                  />
                )}
              </UIContext.Consumer>
            </ContentItem>
            {!org.syncedToIbmWm && event && (
              <ContentItem id="integrations">
                <RegistrationSettingsIntegrations event={event} org={org} />
              </ContentItem>
            )}
          </Content>
        </ContentWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(RegistrationSettingsContent, {
  event: graphql`
    fragment RegistrationSettingsContent_event on Event {
      ...EmailSettings_event
      ...RegistrationSettingsIntegrations_event
    }
  `,
  org: graphql`
    fragment RegistrationSettingsContent_org on Org {
      ...RegistrationSettingsIntegrations_org
      ...GeneralSettings_org
      ...EmailSettings_org
      syncedToIbmWm
    }
  `,
  registrationForm: graphql`
    fragment RegistrationSettingsContent_registrationForm on RegistrationForm {
      ...GeneralSettings_registrationForm
      ...EmailSettings_registrationForm
    }
  `,
  me: graphql`
    fragment RegistrationSettingsContent_me on User {
      ...EmailSettings_me
    }
  `,
});
