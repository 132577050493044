/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type SortParam } from 'utils/routing/parseTypedQueryString';

import updateExpense from 'graph/mutations/expense/updateExpense';
import showModernMutationError from 'graph/utils/showModernMutationError';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';

import columns, { type ExpenseCellPropsType, type ExpenseFooterCellPropsType } from './columns';

import { type ExpensesTableRenderer_event } from './__generated__/ExpensesTableRenderer_event.graphql';
import { type ExpensesTableRenderer_expenses } from './__generated__/ExpensesTableRenderer_expenses.graphql';
import { type ExpensesTableRenderer_org } from './__generated__/ExpensesTableRenderer_org.graphql';

type ExpenseType = $ElementType<ExpensesTableRenderer_expenses, 0>;

class ExpensesTableRenderer extends React.Component<{
  expenses: ?ExpensesTableRenderer_expenses,
  event: ExpensesTableRenderer_event,
  org: ExpensesTableRenderer_org,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  onChangeDataCount: (count: number) => void,
}> {
  componentDidMount() {
    if (this.props.expenses) {
      this.props.onChangeDataCount(this.props.expenses.length);
    }
  }

  componentDidUpdate() {
    if (this.props.expenses) {
      this.props.onChangeDataCount(this.props.expenses.length);
    }
  }

  cellProps = (
    expense: ExpenseType,
  ): ExpenseCellPropsType<ExpenseType, ExpensesTableRenderer_event, ExpensesTableRenderer_org> => {
    const { org, event } = this.props;

    return {
      expense,
      org,
      event,
      updateExpense: updates => updateExpense(expense.id, updates).catch(showModernMutationError),
    };
  };

  footerCellProps = (): ExpenseFooterCellPropsType<
    ExpensesTableRenderer_event,
    ExpensesTableRenderer_org,
  > => {
    const { org, event } = this.props;

    return { org, event };
  };

  keyExtractor = (expense: ExpenseType) => expense.id;

  render() {
    const { expenses } = this.props;

    if (expenses && expenses.length === 0) {
      return <EmptyView message="No results at this time." icon={<NoResult />} />;
    }

    return (
      <Table
        data={expenses}
        columns={columns}
        sort={this.props.sort}
        onChangeSort={this.props.onChangeSort}
        cellProps={this.cellProps}
        footerCellProps={this.footerCellProps}
        keyExtractor={this.keyExtractor}
        stickyFooter
      />
    );
  }
}

export default createFragmentContainer(
  ExpensesTableRenderer,
  graphql`
    fragment ExpensesTableRenderer_expenses on Expense @relay(plural: true) {
      id
      ...ExpenseNameCell_expense
      ...ExpenseVendorCell_expense
      ...ExpenseBudgetCategoryCell_expense
      ...ExpenseActualAmountCell_expense
      ...ExpensePaymentStatusCell_expense
      ...ExpenseNoteCell_expense
      ...ExpenseActionCell_expense
      ...ExpenseTeamSplitCell_expense
    }

    fragment ExpensesTableRenderer_event on Event {
      ...ExpenseNameCell_event
      ...ExpenseVendorCell_event
      ...ExpenseActualAmountCell_event
      ...ExpensePaymentStatusCell_event
      ...ExpenseNoteCell_event
      ...ExpenseActionCell_event
      ...ExpenseActualAmountFooterCell_event
      ...ExpenseTeamSplitCell_event
    }

    fragment ExpensesTableRenderer_org on Org {
      ...ExpenseBudgetCategoryCell_org
      ...ExpenseActualAmountCell_org
      ...ExpenseActualAmountFooterCell_org
    }
  `,
);
