import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { createMoment } from 'utils/Date';

import { SingleCalendarPanel } from 'components/panels/SingleCalendarPanel';

import Title from './form/Title';

class ScheduleDayHeader extends React.Component {
  static propTypes = {
    scheduleDay: PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string,
      viewerCanUpdate: PropTypes.bool.isRequired,
      viewerCanRemove: PropTypes.bool.isRequired,
      schedule: PropTypes.shape({
        event: PropTypes.shape({
          tz: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    onDateSave: PropTypes.func.isRequired,
    onTitleSave: PropTypes.func.isRequired,
    onDayRemove: PropTypes.func.isRequired,
  };

  state = {
    date: this.props.scheduleDay.date,
  };

  handleDatePanelShow = () => {
    this.panel.show();
  };

  handleDateChange = ({ date }) => {
    this.setState({ date });
  };

  handleDateSave = ({ date }) => {
    this.props.onDateSave(new Date(date).toISOString());
  };

  handleTitleSave = title => {
    this.props.onTitleSave(title);
  };

  render() {
    const { scheduleDay, onDayRemove } = this.props;
    const { event } = scheduleDay.schedule;
    const { tz } = event;
    const { date } = this.state;

    const dateMoment = createMoment(tz)(scheduleDay.date);

    return (
      <div>
        <div className="schedule-item-meta">
          {this.props.scheduleDay.viewerCanRemove && (
            <a className="schedule-item-meta-action" onClick={onDayRemove}>
              <i className="fa fa-fw fa-trash" />
            </a>
          )}
          <div className={classNames('schedule-item-meta-date', { empty: !scheduleDay.date })}>
            <a
              onClick={
                this.props.scheduleDay.viewerCanUpdate ? this.handleDatePanelShow : undefined
              }
            >
              {scheduleDay.date ? dateMoment.renderDateTime(true) : '+ Add Day'}
            </a>
            <SingleCalendarPanel
              ref={panel => {
                this.panel = panel;
              }}
              hiddenCheckbox
              hideHeader
              all_day
              tz={tz}
              date={date}
              onChange={this.handleDateChange}
              onSave={this.handleDateSave}
              modalOnMobile
            />
          </div>
          <div className="schedule-item-meta-title">
            <Title
              value={scheduleDay.title}
              onSave={this.handleTitleSave}
              disabled={!this.props.scheduleDay.viewerCanUpdate}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(
  ScheduleDayHeader,
  graphql`
    fragment ScheduleDayHeader_scheduleDay on ScheduleDay {
      title
      date
      viewerCanUpdate
      viewerCanRemove
      schedule {
        event {
          tz
        }
      }
    }
  `,
);
