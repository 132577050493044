/* @flow */
type Options = {|
  fractionDigits?: number,
|};

const defaultOptions = { fractionDigits: 2 };

export default function formatNumber(
  number: number,
  currentOptions?: Options = Object.freeze({}),
): string {
  if (number == null) return '';

  const options = { ...defaultOptions, ...currentOptions };

  return number.toFixed(options.fractionDigits).replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,');
}
