/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { EventUpdater_event } from './__generated__/EventUpdater_event.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 17px;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

const EventUpdater = (props: { event: EventUpdater_event }) => {
  const updater = props.event.updater;
  return updater ? (
    <Container>
      <MaterialAvatar radius={13} user={updater} />
      <TextWrapper>{fullNameOfUser(updater)}</TextWrapper>
    </Container>
  ) : null;
};

export default createFragmentContainer(
  EventUpdater,
  graphql`
    fragment EventUpdater_event on Event {
      updater {
        firstName
        lastName
        avatar
        ...MaterialAvatar_user
      }
    }
  `,
);
