/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateEvent from 'graph/mutations/event/updateEvent';

import LockIcon from 'components/LockIcon';
import InlineRichText from 'components/material/InlineRichText';

import type { Description_event } from './__generated__/Description_event.graphql';

const Container = styled.div`
  position: relative;
  flex: 0 1 420px;
  width: 100%;
  margin-bottom: 25px;
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 10px;
`;

class Description extends React.Component<{
  event: Description_event,
}> {
  handleDescriptionChange = (description: ?string): void => {
    const desc = description ? description.trimRight() : '';
    updateEvent(this.props.event.id, { description: desc });
  };

  render() {
    const event = this.props.event;

    return (
      <Container>
        <InlineRichText
          value={event.description}
          label="Description"
          disabled={!event.viewerCanUpdate || event.descriptionMappedToSalesforce}
          onChange={this.handleDescriptionChange}
        />
        {event.viewerCanUpdate && event.descriptionMappedToSalesforce && (
          <StyledLockIcon label="Salesforce" />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  Description,
  graphql`
    fragment Description_event on Event {
      id
      name
      description
      viewerCanUpdate
      descriptionMappedToSalesforce: mappedToSalesforce(field: DESCRIPTION)
    }
  `,
);
