/* @flow */
import React from 'react';
import styled from 'styled-components';
import differenceBy from 'lodash/differenceBy';

import type { CircaFile } from 'utils/uploading/types';

import { Plus as PlusIcon } from 'images';
import { type BriefDataType } from 'components/RelayAttachments';
import AttachmentOverlay from 'components/RelayAttachments/AttachmentOverlay';
import AttachmentPreview from 'components/RelayAttachments/AttachmentPreview';
import AttachmentRow from 'components/RelayAttachments/AttachmentRow';
import AttachmentThumbnail from 'components/RelayAttachments/AttachmentThumbnail';

const LinkAttachments = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SectionHeader = styled.div`
  display: inline-block;
  margin-top: 18px;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 500;
  color: #3e4859;
  line-height: 14px;
`;

const SectionContent = styled.div`
  padding-top: 15px;
  @media (max-width: 480px) {
    padding-top: 10px;
  }
`;

const Container = styled.div`
  position: relative;
  margin-top: 17px;
  margin-bottom: 14px;
`;

const AddLink = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
  strong {
    font-size: 22px;
    margin-right: 6px;
  }
`;

const PlusAction = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  cursor: pointer;
  color: ${props => props.theme.primaryActionColor};
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default class ExpenseAttachments extends React.Component<
  {
    briefData?: BriefDataType,
    attachments: $ReadOnlyArray<any>,
    handleAttachmentAdd: (attachment: CircaFile) => void,
    handleAttachmentRemove: (attachmentId: string) => void,
  },
  {
    previewId: string,
    overlayShown: boolean,
  },
> {
  state = {
    previewId: '',
    overlayShown: false,
  };

  target: ?HTMLElement;

  handlePreview = (previewId: string) => {
    this.setState({ previewId });
  };

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  handleAttachmentUpload = (attachment: CircaFile) => {
    this.props.handleAttachmentAdd(attachment);
  };

  render() {
    const { attachments } = this.props;

    const { previewId } = this.state;
    const imageAttachments = (attachments || []).filter(a => {
      return (
        (a && a.filetype && a.filetype.startsWith('image')) || a.filetype === 'application/pdf'
      );
    });
    const linkAttachments = differenceBy(attachments, imageAttachments, 'id');

    return (
      <Container>
        <SectionHeader>Attachments</SectionHeader>
        <SectionContent>
          <ImagesContainer>
            {imageAttachments.map(
              a =>
                a && (
                  <AttachmentThumbnail
                    key={a.id}
                    attachment={a}
                    briefData={this.props.briefData}
                    onPreview={this.handlePreview}
                    onRemove={this.props.handleAttachmentRemove}
                  />
                ),
            )}
          </ImagesContainer>
          <LinkAttachments>
            {linkAttachments.map(
              l =>
                l && (
                  <AttachmentRow
                    key={l.id}
                    attachment={l}
                    briefData={this.props.briefData}
                    onRemove={this.props.handleAttachmentRemove}
                    onPreview={this.handlePreview}
                  />
                ),
            )}
          </LinkAttachments>
          {previewId && (
            <AttachmentPreview
              attachments={[]}
              mockAttachments={attachments}
              previewId={previewId}
              onPreview={this.handlePreview}
              onRemove={null}
            />
          )}
        </SectionContent>
        <AddLink
          ref={target => {
            this.target = target;
          }}
          onClick={this.handleOverlayShow}
        >
          <PlusAction>
            <PlusIcon />
          </PlusAction>
          Add Attachment
        </AddLink>
        <AttachmentOverlay
          target={this.target}
          container={this}
          onHide={this.handleOverlayHide}
          show={this.state.overlayShown}
          onUploaded={this.handleAttachmentUpload}
        />
      </Container>
    );
  }
}
