/* @flow */
import * as React from 'react';

import sortCustomFieldOptions from 'utils/customization/sortCustomFieldOptions';

import QuestionSelectFieldWrapper from 'components/EventRequestForm/Question/QuestionSelectOptions/components/QuestionSelectFieldWrapper';
import { StyledSelectField } from 'components/SharedForm/SelectField';

import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../../lib/types';
import StyledTextInput from './components/TextInput';
import { filteredOptions, mapOptions } from './lib/filteredOptions';

export default class QuestionSelectField extends React.Component<
  {
    question: QuestionType,
    value: QuestionValueType,
    onChangeValue: QuestionValueInputType => void,
    readOnly: boolean,
    error: ?string,
  },
  {
    showOtherOptionInput: boolean,
    keyword: string,
    selectedOption: string,
  },
> {
  state = {
    showOtherOptionInput: !!(this.props.value && this.props.value.textValue),
    keyword: '',
    selectedOption: this.props.value && this.props.value.selectIdsValue[0],
  };

  handleSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.state.keyword !== e.currentTarget.value.trim()) {
      this.setState({
        keyword: e.currentTarget.value.trim().toLowerCase(),
      });
    }
  };

  handleOptionChange = (optionId: ?string) => {
    if (this.props.readOnly) return;

    this.props.onChangeValue({
      selectIdsValue: optionId && optionId !== 'none' && optionId !== 'OTHER' ? [optionId] : [],
      textValue: null,
    });

    this.setState({ selectedOption: optionId || '' });
    this.setState({ showOtherOptionInput: optionId === 'OTHER' });
  };

  handleChangeOtherOption = (event: ?SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeValue({ textValue: (event && event.currentTarget.value) || null });
  };

  render() {
    const { question, value, error } = this.props;
    const { showOtherOptionInput, keyword, selectedOption } = this.state;
    const options = filteredOptions(question, value, keyword);

    const renderOtherOption =
      question.selectShowOtherOption &&
      (!question.mappingCustomField ||
        (question.mappingCustomField && !question.mappingCustomField.restrictManagingOptions));

    const availableOptions = [
      ...mapOptions(sortCustomFieldOptions(options)),
      renderOtherOption ? { label: 'Other', value: 'OTHER' } : null,
    ].filter(option => option !== null);

    const selectedValue = renderOtherOption && showOtherOptionInput ? 'OTHER' : selectedOption;

    return (
      <>
        {question.selectOptions.length > 10 && (
          <StyledTextInput placeholder="Search..." onChange={this.handleSearchChange} />
        )}
        <QuestionSelectFieldWrapper
          question={question}
          value={value}
          options={options}
          error={error}
          keyword={keyword}
          showOtherOptionInput={!!renderOtherOption && showOtherOptionInput}
          onChangeOtherOption={this.handleChangeOtherOption}
        >
          <StyledSelectField
            value={selectedValue}
            onChange={this.handleOptionChange}
            options={availableOptions.map(option => ({
              ...option,
            }))}
            error={error}
            clearable
            searchable
          />
        </QuestionSelectFieldWrapper>
      </>
    );
  }
}
