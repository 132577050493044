/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import EmailSubscriptions from './EmailSubscriptions';

import type { EmailSubscriptionsQueryResponse } from './__generated__/EmailSubscriptionsQuery.graphql';

const query = graphql`
  query EmailSubscriptionsQuery {
    user: me {
      ...EmailSubscriptions_user
    }
  }
`;

export default class EmailSubscriptionsRoot extends React.PureComponent<{}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(response: EmailSubscriptionsQueryResponse) => (
          <EmailSubscriptions user={response.user} />
        )}
      />
    );
  }
}
