/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestsItemRequestedBy_eventRequestSubmission$ref: FragmentReference;
export type EventRequestsItemRequestedBy_eventRequestSubmission = {|
  +user: ?{|
    +id: string,
    +firstName: string,
    +lastName: string,
    +email: string,
    +$fragmentRefs: MaterialAvatar_user$ref,
  |},
  +contact: ?{|
    +slug: string,
    +firstName: string,
    +lastName: string,
    +email: string,
    +$fragmentRefs: MaterialAvatar_user$ref,
  |},
  +$refType: EventRequestsItemRequestedBy_eventRequestSubmission$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "FragmentSpread",
  "name": "MaterialAvatar_user",
  "args": null
};
return {
  "kind": "Fragment",
  "name": "EventRequestsItemRequestedBy_eventRequestSubmission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        v0,
        v1,
        v2,
        v3
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contact",
      "storageKey": null,
      "args": null,
      "concreteType": "Contact",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "slug",
          "args": null,
          "storageKey": null
        },
        v0,
        v1,
        v2,
        v3
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1f261bb341a03da912ee5cf1529aa89';
module.exports = node;
