/* @flow */
import { type NumberRangeParam } from 'utils/routing/parseTypedQueryString';
import type { FieldType } from 'utils/customization/types';

import { parseDateFilterParams } from 'components/AllEvents/lib/parseDateFilters';

const getCustomFieldValue = (
  customField: FieldType,
  value: $ReadOnlyArray<string> | string | boolean | NumberRangeParam,
  tz: string,
) => {
  switch (customField.kind) {
    case 'BOOLEAN':
      return { booleanValue: value };
    case 'DATE':
      return value && typeof value === 'string'
        ? {
            dateRangeValue: parseDateFilterParams({
              afterDate: value.split('-')[0],
              beforeDate: value.split('-')[1],
              tz,
            }),
          }
        : null;
    case 'NUMBER':
    case 'CURRENCY':
      return typeof value === 'object' &&
        !Array.isArray(value) &&
        (value.min != null || value.max != null)
        ? {
            numberRangeParam: { min: value.min, max: value.max },
          }
        : null;
    case 'SELECT':
    case 'MULTISELECT':
      return Array.isArray(value) && value.length > 0
        ? {
            enumArrayValue: value,
          }
        : null;
    default:
      return Array.isArray(value) && value.length > 0 ? { stringArrayValue: value } : null;
  }
};

export default getCustomFieldValue;
