/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import createEventRequestSubmission from 'graph/mutations/eventRequest/createEventRequestSubmission';
import updateEventRequestSubmission from 'graph/mutations/eventRequest/updateEventRequestSubmission';

import RequestForm from 'components/EventRequestForm/RequestForm';

import SuccessWindow from './SuccessWindow';

import type { EventRequestSharedFormContainer_me } from './__generated__/EventRequestSharedFormContainer_me.graphql';
import type { EventRequestSharedFormContainer_org } from './__generated__/EventRequestSharedFormContainer_org.graphql';
import type { EventRequestSharedFormContainer_requestForm } from './__generated__/EventRequestSharedFormContainer_requestForm.graphql';
import type { EventRequestSharedFormContainer_requestSubmission } from './__generated__/EventRequestSharedFormContainer_requestSubmission.graphql';
import type { EventRequestSharedFormContainer_ssoUser } from './__generated__/EventRequestSharedFormContainer_ssoUser.graphql';

const Body = styled.div`
  flex: 1 1 auto;
  max-width: 770px;
  margin: 0 auto;
  border-radius: 8px;
`;

class EventRequestSharedFormContainer extends React.PureComponent<
  {
    org: EventRequestSharedFormContainer_org,
    me: EventRequestSharedFormContainer_me,
    ssoUser: EventRequestSharedFormContainer_ssoUser,
    requestForm: EventRequestSharedFormContainer_requestForm,
    requestSubmission: ?EventRequestSharedFormContainer_requestSubmission,
    token: string,
    submitToken: ?string,
  },
  {
    submitted: boolean,
  },
> {
  state = {
    submitted: false,
  };

  handleSubmit = ({ requesterInfo, questionValues }) => {
    const { token, submitToken } = this.props;

    const mutation = submitToken
      ? updateEventRequestSubmission({ token, submitToken, values: questionValues })
      : createEventRequestSubmission({ token, ...requesterInfo, values: questionValues });

    return mutation.then(() => {
      this.setState({ submitted: true });
    });
  };

  render() {
    const { requestForm, requestSubmission, org, me, submitToken, ssoUser } = this.props;

    return (
      <DocumentTitle title={requestForm.name}>
        <Body>
          {this.state.submitted ? (
            <SuccessWindow
              org={org}
              formLogo={requestForm.logo}
              submitMessage={requestForm.submitMessage}
            />
          ) : (
            <RequestForm
              requestForm={requestForm}
              org={org}
              me={me}
              ssoUser={ssoUser}
              tz={requestSubmission ? requestSubmission.event.tz : org.settings.tz}
              onSubmit={this.handleSubmit}
              requestSubmission={requestSubmission}
              submitToken={submitToken}
            />
          )}
        </Body>
      </DocumentTitle>
    );
  }
}

export default createFragmentContainer(EventRequestSharedFormContainer, {
  org: graphql`
    fragment EventRequestSharedFormContainer_org on Org {
      ...RequestForm_org
      ...SuccessWindow_org
      settings {
        tz
      }
    }
  `,
  me: graphql`
    fragment EventRequestSharedFormContainer_me on User {
      ...RequestForm_me
    }
  `,
  ssoUser: graphql`
    fragment EventRequestSharedFormContainer_ssoUser on SSOUser {
      ...RequestForm_ssoUser
    }
  `,
  requestForm: graphql`
    fragment EventRequestSharedFormContainer_requestForm on EventRequestForm {
      id
      name
      logo
      submitMessage
      ...RequestForm_requestForm
    }
  `,
  requestSubmission: graphql`
    fragment EventRequestSharedFormContainer_requestSubmission on EventRequestSubmission {
      event {
        tz
      }
      ...RequestForm_requestSubmission
    }
  `,
});
