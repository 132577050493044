/**
 * @flow
 * @relayHash 75c3a5e1c8fdebc91111efda4932b625
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SchedulesHeaderItem_schedule$ref = any;
export type AddScheduleToEventInput = {
  clientMutationId?: ?string,
  eventId: string,
  name: string,
  order: number,
};
export type createScheduleMutationVariables = {|
  input: AddScheduleToEventInput
|};
export type createScheduleMutationResponse = {|
  +addScheduleToEvent: ?{|
    +scheduleEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +name: string,
        +order: number,
        +slug: string,
        +dbId: number,
        +token: string,
        +slugs: $ReadOnlyArray<{|
          +slug: string
        |}>,
        +$fragmentRefs: SchedulesHeaderItem_schedule$ref,
      |},
    |}
  |}
|};
export type createScheduleMutation = {|
  variables: createScheduleMutationVariables,
  response: createScheduleMutationResponse,
|};
*/


/*
mutation createScheduleMutation(
  $input: AddScheduleToEventInput!
) {
  addScheduleToEvent(input: $input) {
    scheduleEdge {
      __typename
      node {
        id
        name
        order
        slug
        dbId
        token
        slugs {
          slug
          id
        }
        ...SchedulesHeaderItem_schedule
      }
    }
  }
}

fragment SchedulesHeaderItem_schedule on Schedule {
  id
  name
  slug
  order
  token
  viewerCanUpdate
  viewerCanRemove
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddScheduleToEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddScheduleToEventInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "token",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createScheduleMutation",
  "id": null,
  "text": "mutation createScheduleMutation(\n  $input: AddScheduleToEventInput!\n) {\n  addScheduleToEvent(input: $input) {\n    scheduleEdge {\n      __typename\n      node {\n        id\n        name\n        order\n        slug\n        dbId\n        token\n        slugs {\n          slug\n          id\n        }\n        ...SchedulesHeaderItem_schedule\n      }\n    }\n  }\n}\n\nfragment SchedulesHeaderItem_schedule on Schedule {\n  id\n  name\n  slug\n  order\n  token\n  viewerCanUpdate\n  viewerCanRemove\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createScheduleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addScheduleToEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddScheduleToEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scheduleEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ScheduleRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Schedule",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "slugs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Slug",
                    "plural": true,
                    "selections": [
                      v6
                    ]
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "SchedulesHeaderItem_schedule",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createScheduleMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addScheduleToEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddScheduleToEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scheduleEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ScheduleRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Schedule",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "slugs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Slug",
                    "plural": true,
                    "selections": [
                      v6,
                      v3
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanRemove",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e24099d424c2eeb13e41015a6c46d4e';
module.exports = node;
