/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  CreateEventRegistrationFormInput,
  createEventRegistrationFormMutationVariables,
} from './__generated__/createEventRegistrationFormMutation.graphql';

type createEventRegistrationFormPropertyType = $Shape<CreateEventRegistrationFormInput>;
const mutation = graphql`
  mutation createEventRegistrationFormMutation($input: CreateEventRegistrationFormInput!) {
    createEventRegistrationForm(input: $input) {
      event {
        id
        ...RegistrationPageProperties_event
        registrationForm {
          id
          eventName
          eventStartDate
          eventEndDate
          tz
          width
          widthMeasurement
          pageComponents {
            edges {
              node {
                id
                kind
                order
                parent {
                  id
                }
                childPageComponents {
                  edges {
                    node {
                      id
                      kind
                      order
                      columnChild {
                        id
                      }
                      ...ColumnComponent_componentProps
                    }
                  }
                }
                ...FormComponent_componentProps
                ...ImageComponent_componentProps
                ...EventNameComponent_componentProps
                ...EventDatesComponent_componentProps
                formComponent {
                  id
                  contactForm {
                    id
                    enableCompanyFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function createEventRegistrationForm(
  params: createEventRegistrationFormPropertyType,
) {
  const variables: createEventRegistrationFormMutationVariables = {
    input: {
      eventId: params.eventId,
      pageWidth: params.pageWidth,
      pageWidthMeasurement: params.pageWidthMeasurement,
      pageBackgroundColor: params.pageBackgroundColor,
      formWidth: params.formWidth,
      formWidthMeasurement: params.formWidthMeasurement,
      alignment: params.alignment,
      formFontFamily: params.formFontFamily,
      formFontStyle: params.formFontStyle,
      formFontColor: params.formFontColor,
      formButtonBackground: params.formButtonBackground,
      formButtonColor: params.formButtonColor,
      formButtonCopy: params.formButtonCopy,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
