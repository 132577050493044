/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import {
  type transferEventMutationVariables,
  type transferEventMutationResponse,
} from './__generated__/transferEventMutation.graphql';


const mutation = graphql`
  mutation transferEventMutation($input: TransferEventInput!) {
    transferEvent(input: $input) {
      event {
        id
      }
    }
  }
`;

export default function transferEvent(
  eventId: string,
  teamId: string,
): Promise<transferEventMutationResponse> {
  const variables:transferEventMutationVariables = {
    input: {
      eventId,
      teamId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
