/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import {
  type createEventBudgetCategoryMutationVariables,
  type createEventBudgetCategoryMutationResponse,
} from './__generated__/createEventBudgetCategoryMutation.graphql';

export type MutationInput = $PropertyType<createEventBudgetCategoryMutationVariables, 'input'>;

const mutation = graphql`
  mutation createEventBudgetCategoryMutation($input: CreateEventBudgetCategoryInput!) {
    createEventBudgetCategory(input: $input) {
      eventBudgetCategoryEdge {
        ...CategoriesTable_categoryEdges
      }
      event {
        totalBudgetedAmount
        budgetCategories {
          totalCount
        }
      }
    }
  }
`;

const createEventBudgetCategory = (
  input: MutationInput,
): Promise<createEventBudgetCategoryMutationResponse> => {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    updater: addRecordToConnection({
      edgeName: 'eventBudgetCategoryEdge',
      parentId: input.eventId,
      connections: [{ field: 'budgetCategories' }],
      rangeBehaviour: 'prepend',
    }),
  });
};

export default createEventBudgetCategory;
