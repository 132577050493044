/**
 * @flow
 * @relayHash a61572553002e002b0a1132d573c722b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddExpenseForm_event$ref = any;
export type VendorExpensesEventQueryVariables = {|
  eventId: string
|};
export type VendorExpensesEventQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: AddExpenseForm_event$ref
  |}
|};
export type VendorExpensesEventQuery = {|
  variables: VendorExpensesEventQueryVariables,
  response: VendorExpensesEventQueryResponse,
|};
*/


/*
query VendorExpensesEventQuery(
  $eventId: ID!
) {
  event: node(id: $eventId) {
    __typename
    ...AddExpenseForm_event
    id
  }
}

fragment AddExpenseForm_event on Event {
  ...PopupFormFields_event
  ...InlineFormFields_event
  id
  team {
    id
    name
    viewerCanUpdate
  }
}

fragment PopupFormFields_event on Event {
  id
}

fragment InlineFormFields_event on Event {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "VendorExpensesEventQuery",
  "id": null,
  "text": "query VendorExpensesEventQuery(\n  $eventId: ID!\n) {\n  event: node(id: $eventId) {\n    __typename\n    ...AddExpenseForm_event\n    id\n  }\n}\n\nfragment AddExpenseForm_event on Event {\n  ...PopupFormFields_event\n  ...InlineFormFields_event\n  id\n  team {\n    id\n    name\n    viewerCanUpdate\n  }\n}\n\nfragment PopupFormFields_event on Event {\n  id\n}\n\nfragment InlineFormFields_event on Event {\n  id\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "VendorExpensesEventQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "event",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AddExpenseForm_event",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VendorExpensesEventQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "event",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "InlineFragment",
            "type": "Event",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  v2,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdate",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6d9c194f01e5e643ee52ca2b106aad20';
module.exports = node;
