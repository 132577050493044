/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import debounce from 'lodash/debounce';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import SelectField from 'components/material/SelectField';

import EventOptionRow from './EventOptionRow';

import type { EventSearchQuery_response } from './__generated__/EventSearchQuery.graphql';

const query = graphql`
  query EventSearchQuery($query: String!, $includeSuggested: Boolean!) {
    org {
      allEvents: events(first: 10, query: $query, requestStatuses: [N_A, APPROVED]) {
        edges {
          node {
            id
            name
            startDate
            endDate
            tz
            primaryLocation {
              name
              city
              state
              country
            }
          }
        }
      }
      suggestedEvents: events(first: 3, query: $query, requestStatuses: [N_A, APPROVED])
        @include(if: $includeSuggested) {
        edges {
          node {
            id
            name
            startDate
            endDate
            tz
            primaryLocation {
              name
              city
              state
              country
            }
          }
        }
      }
    }
  }
`;

export type Event = {
  +id: string,
  +name: string,
  +startDate: any,
  +endDate: any,
  +tz: string,
  +primaryLocation: ?{
    +name: string,
    +city: string,
    +state: ?string,
    +country: ?string,
  },
};

export default class EventSearch extends React.PureComponent<
  {
    event?: ?Event,
    onSelect: (event: ?Event) => void,
    className?: string,
    label?: string,
    placeholder?: string,
    disabled?: boolean,
    required?: boolean,
    clearable?: boolean,
    noBoxShadow?: boolean,
    autoFocus?: boolean,
    includeSuggested?: boolean,
    onHideOptions?: () => void,
    onSearch?: (query: string) => void,
  },
  {
    query: string,
  },
> {
  // eslint-disable-next-line react/sort-comp
  static defaultProps = {
    includeSuggested: false,
  };

  state = {
    query: '',
  };

  handleSearch = debounce((search: string) => {
    this.setState({ query: search });
    if (this.props.onSearch) {
      this.props.onSearch(search);
    }
  }, 800);

  handleQueryChange = (search: string) => {
    this.handleSearch(search);
  };

  renderSelect = (response?: EventSearchQuery_response) => {
    const suggestedEvents: $ReadOnlyArray<Event> =
      response && response.org.suggestedEvents
        ? response.org.suggestedEvents.edges.map(edge => edge.node)
        : [];
    const suggestedEventIds = suggestedEvents.map(event => event.id);
    const allEvents: Array<Event> = response
      ? response.org.allEvents.edges
          .map(edge => edge.node)
          .filter(event => !suggestedEventIds.includes(event.id))
      : [];
    const loadedEvents = [...suggestedEvents, ...allEvents];

    const findEvent = (eventId: ?string, eventGroup: Array<Event>) =>
      eventGroup.find(c => c.id === eventId) || null;

    if (this.props.event) {
      const selectedEvent = findEvent(this.props.event.id, loadedEvents);

      if (!selectedEvent && this.props.event) {
        allEvents.unshift(this.props.event);
      }
    }

    const optionGroups =
      suggestedEvents.length === 0 || allEvents.length === 0
        ? []
        : [
            { label: 'SUGGESTED EVENTS', value: 'suggested' },
            { label: 'ALL EVENTS', value: 'all' },
          ];

    const options = [
      ...suggestedEvents.map(event => ({
        label: event.name,
        value: event.id,
        groupId: 'suggested',
        render: props => <EventOptionRow key={event.id} event={event} {...props} />,
      })),
      ...allEvents.map(event => ({
        label: event.name,
        value: event.id,
        groupId: 'all',
        render: props => <EventOptionRow key={event.id} event={event} {...props} />,
      })),
    ];

    return (
      <SelectField
        className={this.props.className}
        label={this.props.label}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        required={this.props.required}
        searchable
        optionGroups={optionGroups}
        options={options}
        onSearch={this.handleQueryChange}
        onChange={(eventId: ?string) => this.props.onSelect(findEvent(eventId, loadedEvents))}
        value={this.props.event && this.props.event.id}
        loading={!response}
        clearable={this.props.clearable}
        noBoxShadow={this.props.noBoxShadow}
        autoFocus={this.props.autoFocus}
        onHideOptions={this.props.onHideOptions}
      />
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          query: this.state.query,
          includeSuggested: this.props.includeSuggested,
        }}
        renderSuccess={this.renderSelect}
        renderLoading={this.renderSelect}
      />
    );
  }
}
