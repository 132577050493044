/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import type {
  updateBudgetCategoryMutationVariables,
  updateBudgetCategoryMutationResponse,
} from './__generated__/updateBudgetCategoryMutation.graphql';

const mutation = graphql`
  mutation updateBudgetCategoryMutation($input: UpdateBudgetCategoryInput!) {
    updateBudgetCategory(input: $input) {
      budget_category {
        name
        description
      }
    }
  }
`;

export default function updateBudgetCategory(args: {
  categoryId: string,
  name: string,
  description?: ?string,
}): Promise<updateBudgetCategoryMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        ...args,
      },
    },
    optimisticResponse: {
      updateBudgetCategory: {
        budget_category: {
          id: args.categoryId,
          name: args.name,
          description: args.description,
        },
      },
    },
  });
}
