/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import replaceQueryParams from 'utils/routing/replaceQueryParams';

import AllEventsSearchSuggestion from 'components/AllEvents/AllEventsSearchSuggestion';
import {
  type filtersForExportType,
  type ParsedAllEventsFilters,
} from 'components/AllEvents/lib/parseAllEventsFilters';
import type { ColumnGroupConfiguration } from 'components/material/table';
import Search from 'components/Search';
import ViewModes from 'components/ViewModes';
import {
  type EventViewMode,
  viewModes,
} from 'views/Main/Dashboard/AllEvents/components/AllEventsViewModes';

import { type SharedEventListType } from '.';
import AllEventsShareActionMenu from './AllEventsShareActionMenu';
import AllEventsShareFilters from './AllEventsShareFilters';
import RequestEventButton from './RequestEventButton';

import { type AllEventsListShareHeaderBar_org } from './__generated__/AllEventsListShareHeaderBar_org.graphql';

const Bar = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px;
  border-bottom: 1px solid #dfe1e5;
  @media (max-width: 450px) {
    padding: 0 8px;
  }
`;

const TableActions = styled.div`
  display: flex;
  flex: 0 auto;
  min-width: 113px;
  margin-left: 10px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSearch = styled(Search)`
  max-width: 263px;
  min-width: 175px;
`;
const RowItem = styled.div`
  min-height: 31px;
`;
const RowItem1 = styled(RowItem)``;
const RowItem2 = styled(RowItem)``;
const RowItem3 = styled(RowItem)`
  flex-grow: 1;
  > div {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
`;
const RowItem4 = styled(RowItem)``;

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 13px 0 13px 0;

  @media (max-width: 980px) {
    ${RowItem1} {
      order: 1;
      width: 50%;
      margin-bottom: 10px;
    }
    ${RowItem2} {
      order: 3;
      width: 50%;
      ${StyledSearch} {
        margin-left: 0;
      }
    }
    ${RowItem3} {
      order: 2;
      width: 50%;
      margin-bottom: 10px;
      flex-grow: unset;
      > div {
        position: unset;
        margin-top: 4px;
        transform: none;
        div:nth-of-type(3) {
          > div {
            margin-right: 0;
            padding-right: 0;
          }
        }
      }
    }
    ${RowItem4} {
      order: 4;
      align-self: flex-end;
      width: 50%;
    }
  }

  @media (max-width: 450px) {
    ${RowItem3} {
      > div {
        svg {
          display: none;
        }
        div {
          margin-right: 3px;
        }
      }
    }
  }
`;

class AllEventsListShareHeaderBar extends React.Component<
  {
    history: RouterHistory,
    eventFields: $ReadOnlyArray<FieldType>,
    filters: ParsedAllEventsFilters,
    eventList: SharedEventListType,
    org: AllEventsListShareHeaderBar_org,
    userEmail: ?string,
    viewMode: EventViewMode,
    className?: string,
    csvExportVariables: filtersForExportType,
    groups: ColumnGroupConfiguration,
    eventsCount: number,
    pathPrefix: string,
    isPreviewing: boolean,
    handleEmptyFilterApply?: () => void,
  },
  {
    csvFileName: ?string,
  },
> {
  handleEventsSearch = (search: string) => {
    const { history, isPreviewing } = this.props;
    const searchTarget = isPreviewing ? { search: search || null } : { queries: search };
    replaceQueryParams(history, searchTarget);
  };

  handleViewModeChange = (mode: string) => {
    this.props.history.push({
      pathname: `${this.props.pathPrefix}${mode === 'table' ? '' : `/${mode}`}`,
      search: this.props.history.location.search,
      hash: this.props.history.location.hash,
    });
  };

  render() {
    const {
      className,
      org,
      eventList,
      eventFields,
      groups,
      history,
      filters,
      viewMode,
      csvExportVariables,
      userEmail,
      isPreviewing,
      handleEmptyFilterApply,
    } = this.props;
    const searchObject = filters.search;
    const nameSearch = filters.queries ? filters.queries[0] : '';
    return (
      <Bar className={className}>
        <Row>
          <RowItem1>
            <RequestEventButton eventList={eventList} />
          </RowItem1>
          <RowItem2>
            <StyledSearch
              onSearch={this.handleEventsSearch}
              search={isPreviewing ? searchObject && searchObject.search : nameSearch}
              exactSearch={isPreviewing ? searchObject && searchObject.exactMatch : undefined}
              placeholder="Search events"
              suggestionComponent={props => {
                return this.props.eventsCount === 0 ? (
                  <AllEventsSearchSuggestion {...props} />
                ) : null;
              }}
            />
          </RowItem2>
          <RowItem3>
            <ViewModes
              viewMode={viewMode}
              modes={viewModes}
              onViewModeChange={this.handleViewModeChange}
            />
          </RowItem3>
          <RowItem4>
            <TableActions>
              <AllEventsShareFilters
                history={history}
                shownColumns={eventList.shownColumns}
                filterOptions={eventFields}
                filters={filters}
                org={org}
                groups={groups}
                viewMode={viewMode}
                isPreviewing={isPreviewing}
                mobileBreakpoint={430}
                handleEmptyFilterApply={handleEmptyFilterApply}
              />
              <Actions>
                <AllEventsShareActionMenu
                  csvExportVariables={csvExportVariables}
                  userEmail={userEmail}
                  shownColumns={eventList.shownColumns}
                  orgSlug={org.slug}
                  listName={eventList.name}
                  listToken={eventList.token}
                />
              </Actions>
            </TableActions>
          </RowItem4>
        </Row>
      </Bar>
    );
  }
}

export default createFragmentContainer(AllEventsListShareHeaderBar, {
  org: graphql`
    fragment AllEventsListShareHeaderBar_org on Org {
      slug
      ...AllEventsShareFilters_org
    }
  `,
});
