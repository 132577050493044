/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  EnableSolutionInstanceInput,
  enableSolutionInstanceMutationResponse,
} from './__generated__/enableSolutionInstanceMutation.graphql';

const mutation = graphql`
  mutation enableSolutionInstanceMutation($input: EnableSolutionInstanceInput!) {
    enableSolutionInstance(input: $input) {
      traySolutionInstanceEdge {
        node {
          id
          solutionName
          upgradeNeeded
          authAccountId
        }
      }
    }
  }
`;

const enableSolutionInstance = (
  input: EnableSolutionInstanceInput,
): Promise<enableSolutionInstanceMutationResponse> =>
  commitModernMutation({
    mutation,
    variables: {
      input,
    },
    updater: addRecordToConnection({
      parentId: input.integrableId,
      edgeName: 'traySolutionInstanceEdge',
      connections: [
        { field: 'traySolutionInstances' },
        { field: 'traySolutionInstances', args: { includeExpired: true } },
      ],
    }),
  });

export default enableSolutionInstance;
