/**
 * @flow
 * @relayHash 0794f595aacd2af3ac8afecca032d18c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
export type UpdateEventDatesComponentInput = {
  clientMutationId?: ?string,
  id: string,
  fontFamily?: ?string,
  fontStyle?: ?string,
  fontSize?: ?number,
  color?: ?string,
  padding?: ?string,
  alignment?: ?Alignment,
  backgroundColor?: ?string,
};
export type updateEventDatesComponentMutationVariables = {|
  input: UpdateEventDatesComponentInput
|};
export type updateEventDatesComponentMutationResponse = {|
  +updateRegistrationFormEventDatesComponent: ?{|
    +pageComponent: {|
      +eventDatesComponent: ?{|
        +id: string,
        +fontFamily: ?string,
        +fontStyle: ?string,
        +fontSize: ?number,
        +color: ?string,
        +padding: string,
        +alignment: Alignment,
        +backgroundColor: ?string,
      |}
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type updateEventDatesComponentMutation = {|
  variables: updateEventDatesComponentMutationVariables,
  response: updateEventDatesComponentMutationResponse,
|};
*/


/*
mutation updateEventDatesComponentMutation(
  $input: UpdateEventDatesComponentInput!
) {
  updateRegistrationFormEventDatesComponent(input: $input) {
    pageComponent {
      eventDatesComponent {
        id
        fontFamily
        fontStyle
        fontSize
        color
        padding
        alignment
        backgroundColor
      }
      id
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventDatesComponentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateEventDatesComponentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "eventDatesComponent",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationEventDatesComponent",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fontFamily",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fontStyle",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fontSize",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "padding",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "alignment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundColor",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventDatesComponentMutation",
  "id": null,
  "text": "mutation updateEventDatesComponentMutation(\n  $input: UpdateEventDatesComponentInput!\n) {\n  updateRegistrationFormEventDatesComponent(input: $input) {\n    pageComponent {\n      eventDatesComponent {\n        id\n        fontFamily\n        fontStyle\n        fontSize\n        color\n        padding\n        alignment\n        backgroundColor\n      }\n      id\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventDatesComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormEventDatesComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventDatesComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3
            ]
          },
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventDatesComponentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormEventDatesComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventDatesComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3,
              v2
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '55ac0ba7c96e19034ca180d8a35e67ab';
module.exports = node;
