/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationColorSelector_orgSettings$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type DividerComponent_org$ref: FragmentReference;
export type DividerComponent_org = {|
  +id: string,
  +settings: {|
    +$fragmentRefs: RegistrationColorSelector_orgSettings$ref
  |},
  +$refType: DividerComponent_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "DividerComponent_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "RegistrationColorSelector_orgSettings",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '694b801c0006b3fc3ca1789d166ff9c2';
module.exports = node;
