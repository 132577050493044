/* @flow */
import * as React from 'react';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';
import flatMap from 'lodash/flatMap';

import budgetCategoryColors from 'config/budgetCategoryColors';

import type { FieldType } from 'utils/customization/types';
import type { SortParam } from 'utils/routing/parseTypedQueryString';

import Table, { type ColumnType, TableStickyScroll } from 'components/budget/Table';
import TableCell from 'components/budget/Table/TableCell';

import type {
  BudgetReportingChildGroupType,
  BudgetReportParentGroupType,
  BudgetReportType,
} from '../BudgetReportingGroupContainer';
import type { CustomGroupFieldSettingsType } from '../BudgetReportingPage';
import parseGroupInfo from '../lib/parseGroupInfo';
import type { GroupingOptionType } from '../lib/reportGroupingOptions';
import columns, { type ReportingCellPropsType, type ReportingFooterCellPropsType } from './columns';

const Container = styled.div`
  ${TableStickyScroll} {
    &:not(:first-child) {
      bottom: -24px;
    }
  }
`;

export default class BudgetReportingTable extends React.PureComponent<{
  orgSettings: CustomGroupFieldSettingsType,
  eventFields: $ReadOnlyArray<FieldType>,
  report: BudgetReportType,
  group1: ?GroupingOptionType,
  group2: ?GroupingOptionType,
  customGroup1: ?string,
  customGroup2: ?string,
  onChangeSort: (sort: SortParam) => void,
  sort: SortParam,
  location?: ?Location,
}> {
  cellProps = (
    group: BudgetReportParentGroupType,
    childGroup: ?BudgetReportingChildGroupType,
  ): ReportingCellPropsType => ({
    group1: this.props.group1,
    group2: this.props.group2,
    customGroup1: this.props.customGroup1,
    customGroup2: this.props.customGroup2,
    group,
    childGroup,
    orgCurrency: this.props.orgSettings.currency,
    location: this.props.location,
  });

  footerCellProps = (): ReportingFooterCellPropsType => ({
    totals: this.props.report.totals,
    orgCurrency: this.props.orgSettings.currency,
  });

  keyExtractor = (group: BudgetReportParentGroupType): string =>
    `${group.paidAmount}_${group.plannedAmount}_${group.actualAmount}_${
      group.name || 'Uncategorized'
    }`;

  colWidthMeasurer = (
    colIndex: number,
    col: ColumnType<any, any>,
    measurer: HTMLElement,
  ): number => {
    // eslint-disable-next-line
    measurer.textContent = col.title;
    // Text width + sort icon offset width
    const titleWidth = measurer.offsetWidth + (col.sortKey == null ? 0 : 15);
    const group1 = this.props.group1 || this.props.customGroup1;
    const group2 = this.props.group2 || this.props.customGroup2;

    if (colIndex === 0) {
      const arrowOffsetWidth = group2 == null ? 0 : 18;
      const footerWidth = group2 == null ? 72 : 90;
      const groupWidths = this.props.report.groups
        .map(group => parseGroupInfo(group.name, group1).groupName || 'Uncategorized')
        .sort((a, b) => b.length - a.length)
        .slice(0, 10)
        .map(name => {
          // eslint-disable-next-line
          measurer.textContent = name;
          // content width + 5px buffer
          return measurer.offsetWidth + arrowOffsetWidth + 5;
        });
      const sortedWidths = [titleWidth, footerWidth, ...groupWidths].sort((a, b) => b - a);
      return sortedWidths[0];
    }
    if (colIndex === 1 && group2 != null) {
      const colWidths = flatMap(
        this.props.report.groups.map(group =>
          group.childGroups == null
            ? ''
            : group.childGroups.map(
                childGroup => parseGroupInfo(childGroup.name, group2).groupName || 'Uncategorized',
              ),
        ),
      )
        .sort((a, b) => b.length - a.length)
        .slice(0, 10)
        .map(name => {
          // eslint-disable-next-line
          measurer.textContent = name;
          // content width + 5px buffer
          return measurer.offsetWidth + 5;
        });
      const sortedWidths = [titleWidth, ...colWidths].sort((a, b) => b - a);
      return sortedWidths[0];
    }
    return 0;
  };

  render() {
    const {
      eventFields,
      group1,
      group2,
      customGroup1,
      customGroup2,
      report,
      sort,
      onChangeSort,
      location,
    } = this.props;
    const hasBudgetedAmount = !!(report && report.totals.budgetedAmount);

    return (
      <Container>
        <Table
          data={(report && report.groups) || null}
          columns={columns(
            group1,
            group2,
            customGroup1,
            customGroup2,
            eventFields,
            hasBudgetedAmount,
            location,
          )}
          sort={sort}
          onChangeSort={onChangeSort}
          cellProps={this.cellProps}
          footerCellProps={this.footerCellProps}
          keyExtractor={this.keyExtractor}
          groupOptions={
            group2 == null && customGroup2 == null
              ? undefined
              : {
                  groupChildExtractor: group => group.childGroups || [],
                  colors: budgetCategoryColors,
                }
          }
          stickyFooter
          measurerComponent={TableCell}
          widthMeasurer={this.colWidthMeasurer}
        />
      </Container>
    );
  }
}
