/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import syncStatuses from 'config/syncStatuses.json';

import ErrorIcon from 'components/ErrorIcon';

import BoldText from '../../components/BoldText';
import BriefField from '../../components/BriefField';

import { type EventInfoSyncStatus_event } from './__generated__/EventInfoSyncStatus_event.graphql';
import { type EventInfoSyncStatus_org } from './__generated__/EventInfoSyncStatus_org.graphql';

const EventInfoSyncStatus = (props: {
  name: string,
  event: EventInfoSyncStatus_event,
  org: EventInfoSyncStatus_org,
}) => {
  const {
    name,
    event: { pullStatus, pushStatus },
    org: { salesforceAccount },
  } = props;

  if (!salesforceAccount) return null;

  const syncState = () => {
    if (!pullStatus || !pushStatus) return 'DISABLED';

    if (pullStatus.state === 'PENDING' || pushStatus.state === 'PENDING') {
      return 'PENDING';
    }
    if (pullStatus.state === 'FAILING' || pushStatus.state === 'FAILING') {
      return 'FAILING';
    }
    return 'OK';
  };

  const renderComponent = (
    connected: boolean = false,
    syncStatus: ?string,
    errorMessage: ?string,
  ) => {
    const contentData = connected ? syncStatus : 'Not Connected';
    const content = connected ? (
      <React.Fragment>
        {syncStatus}
        {errorMessage && <ErrorIcon message={errorMessage} />}
      </React.Fragment>
    ) : (
      'Not Connected'
    );
    return (
      <BriefField label={name} contentDataAvailable={contentData}>
        <BoldText>{content}</BoldText>
      </BriefField>
    );
  };

  if (!pullStatus || !pushStatus) {
    return renderComponent();
  }

  const errorMessage = pullStatus.errorMessage || pushStatus.errorMessage;
  const syncStatus = syncStatuses[syncState()];
  return renderComponent(true, syncStatus, errorMessage);
};

export default createFragmentContainer(EventInfoSyncStatus, {
  event: graphql`
    fragment EventInfoSyncStatus_event on Event {
      pullStatus {
        state
        errorMessage
      }
      pushStatus {
        state
        errorMessage
      }
    }
  `,
  org: graphql`
    fragment EventInfoSyncStatus_org on Org {
      salesforceAccount {
        id
      }
    }
  `,
});
