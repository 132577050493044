/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import BriefField from '../../../components/BriefField';
import UserField from '../../../components/UserField';
import UserContainerInList from '../../../components/UserContainerInList';
import UserContainer from '../../../components/UserContainer';

import { type EventInfoCustomUserMultiselect_event } from './__generated__/EventInfoCustomUserMultiselect_event.graphql';

const StyledUserContainer = styled(UserContainer)`
  padding-top: 5px;
`;

const EventInfoCustomUserMultiselect = ({
  name,
  event,
  customFieldId,
}: {
  name: string,
  event: EventInfoCustomUserMultiselect_event,
  customFieldId: string,
}) => {
  const users = event.customUserMultiselectFields
    .filter(value => value.customField.id === customFieldId)
    .map(value => value.user);

  if (users.length === 0) return null;

  return (
    <BriefField label={name} contentDataAvailable={users.length || null}>
      <StyledUserContainer>
        {users.map(user => (
          <UserContainerInList key={user.id}>
            <UserField event={event} user={user} avatarRadius={27.5} />
          </UserContainerInList>
        ))}
      </StyledUserContainer>
    </BriefField>
  );
};

export default createFragmentContainer(
  EventInfoCustomUserMultiselect,
  graphql`
    fragment EventInfoCustomUserMultiselect_event on Event {
      ...UserField_event
      customUserMultiselectFields(customFieldIds: $customFieldIds) {
        user {
          id
          firstName
          lastName
          email
          phone
          officePhone
          title
          company
        }
        customField {
          id
        }
      }
    }
  `,
);
