/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';

import SortableHeader from 'components/material/SortableHeader';

import EventColumn from './EventColumn';
import TaskRowFolderColumn from './TaskRowFolderColumn';

const DateColumn = styled.div`
  width: 120px;
  margin-right: 10px;
`;

const TagsColumn = styled.div`
  width: 120px;
  margin-right: 10px;
`;

const AssigneesColumn = styled.div`
  width: 100px;
  margin-right: 46px;
`;

export default class TasksListHeader extends React.PureComponent<{
  sort: SortParam,
  onSortChange: SortParam => void,
  showEvent?: boolean,
  showFolder?: boolean,
}> {
  render() {
    const cols = [
      this.props.showEvent ? { label: 'Event', component: EventColumn } : null,
      this.props.showFolder
        ? { label: 'Section', key: 'SECTION', component: TaskRowFolderColumn }
        : null,
      { label: 'Due Date', key: 'DUE_DATE', component: DateColumn },
      { label: 'Tags', component: TagsColumn },
      {
        label: 'Assignees',
        component: AssigneesColumn,
      },
    ].filter(Boolean);

    return (
      <SortableHeader light sort={this.props.sort} cols={cols} onChange={this.props.onSortChange} />
    );
  }
}
