/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventContactsCount_event$ref: FragmentReference;
export type EventContactsCount_event = {|
  +eventContacts: {|
    +totalCount: number
  |},
  +$refType: EventContactsCount_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventContactsCount_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventContacts",
      "storageKey": null,
      "args": null,
      "concreteType": "EventContactConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ba64a76811c1f21cb534c3a27c2dc43f';
module.exports = node;
