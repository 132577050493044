/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import escapeRegexString from 'utils/escapeRegexString';

import Overlay from 'components/material/Overlay';

import SelectFieldRow from './SelectFieldRow';

export type RenderProps<P> = {|
  onSelect: P => void,
  onHover: number => void,
  onHide: () => void,
  focused: boolean,
  index: number,
  value: P,
  label: string,
  displayLabel?: React.Node,
  info?: ?string,
  groupId?: string,
  placeholder?: string,
  parent?: string,
  disabled?: boolean,
|};

export type SelectItem<P> = {
  +label: string,
  +displayLabel?: React.Node,
  +info?: ?string,
  +groupId?: string,
  +placeholder?: string,
  +parent?: string,
  +value: P,
  +disabled?: boolean,
  +render?: (RenderProps<P>) => React.Node,
  +onSelect?: () => void,
  +icon?: ?string,
};

export type OptionGroup = {|
  label: string,
  value: string,
|};

// This label is also used in MultiSelect field, when changing also check multiselect
export const Label = styled.div`
  pointer-events: none;
  color: ${props => (props.error ? props.theme.negativeActionColor : props.theme.labelColor)};
  position: absolute;
  top: 8px;
  transition: 0.2s;
  line-height: 1.8;
  &[data-collapsed] {
    font-size: 12px;
    top: -5px;
    line-height: 1;
  }
  ${props =>
    props.focused &&
    css`
      color: ${props.theme.primaryActionColor};
    `};
`;

export const StyledOverlay = styled(Overlay)`
  width: auto;
  min-width: 100%;
  height: auto;
  max-height: ${props => (props.height ? props.height : '305')}px;
  overflow-y: auto;
  margin-top: 3px;
`;

const OptionGroupLabel = styled.div`
  padding: 8px 15px;
  font-size: 13px;
  color: #9098a0;
  &:not(:first-child) {
    border-top: 1px solid #e6e6e6;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  ${props =>
    props.withLabel &&
    !props.compact &&
    css`
      margin: 8px 0;
    `};
`;

export const Field = styled.div`
  ${props => (props.compact ? 'padding: 0' : 'padding: 5px 0')};
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border-bottom: 1px solid;
  border-color: ${props => props.theme.borderColor};
  ${props =>
    props.noBoxShadow &&
    css`
      border-bottom-width: 0 !important;
    `};
  ${props =>
    props.disabled &&
    css`
      border-style: dashed;
    `};
  ${props =>
    props.focused &&
    css`
      border-color: ${props.theme.primaryActionColor};
    `};
  &:hover {
    ${props =>
      !props.focused &&
      !props.disabled &&
      css`
        border-color: ${props.theme.secondaryActionDarkerColor};
      `};
  }
  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.negativeActionColor};
      &:hover:not(:focus) {
        border-color: ${props.theme.negativeActionColor};
      }
    `};
  transition: border-color 0.3s;
  ${props =>
    props.withLabel &&
    !props.compact &&
    css`
      padding: 10px 0 3px 0;
    `};
`;

export const Icon = styled.i`
  color: ${props =>
    props.error ? props.theme.negativeActionColor : props.theme.secondaryActionColor};
  font-size: ${props => (props.small ? 13 : 16)}px;
  line-height: 1 !important;
  outline: none;
  ${props =>
    props.primary &&
    css`
      color: ${props.theme.primaryActionColor};
    `};
`;

const ClearIcon = styled(Icon)`
  font-size: 12px;
  &:hover {
    color: ${props => props.theme.negativeActionColor};
  }
`;

export const SelectedLabel = styled.div`
  flex: 1 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
  line-height: 1.4;
  color: ${props => (props.error ? props.theme.negativeActionColor : props.theme.labelColor)};
  ${props =>
    !props.showPlaceholder &&
    !props.disabled &&
    css`
      color: ${props.theme.rowPrimaryTextColor};
    `};
`;

export const Required = styled.span`
  font-size: 11px;
  vertical-align: top;
  margin-left: 3px;
`;

const Input = styled.input`
  outline: none !important;
  ${props =>
    props.shown
      ? css`
          flex: 1 1 auto;
          width: 0;
        `
      : css`
          margin-right: -1px;
          width: 1px;
          z-index: -1;
          opacity: 0;
        `};
  &::placeholder {
    color: ${props => props.theme.mutedTextColor};
  }
`;

export const Error = styled.div`
  font-size: 11px;
  margin-top: 1px;
  color: ${props => props.theme.negativeActionColor};
`;

const OverlayFocus = styled.div`
  outline: none;
`;

export default class SelectField<P> extends React.PureComponent<
  {
    className?: string,
    fieldClassName?: string,
    options: $ReadOnlyArray<SelectItem<P>>,
    onChange: (value: ?P) => void,
    label?: string,
    labelComponent?: React.ElementType,
    placeholder?: string,
    searchPlaceholder?: string,
    value?: ?P,
    clearable?: boolean,
    onHideOptions?: (value?: P) => void,
    compact?: boolean,
    disabled?: ?boolean,
    noBoxShadow?: ?boolean,
    searchable?: boolean,
    error?: ?string,
    required?: boolean,
    onSearch?: (query: string) => void,
    loading?: boolean,
    optionGroups?: $ReadOnlyArray<OptionGroup>,
    autoFocus?: boolean,
    overlayContainer?: ?HTMLElement,
    height?: number,
    onShowOptions?: ?() => void,
    flagClass?: string,
  },
  {
    overlayShown: boolean,
    focus: number,
    query: string,
  },
> {
  state = {
    overlayShown: false,
    focus: this.initialIndex(),
    query: '',
  };

  containerRef = React.createRef();

  fieldRef = React.createRef();

  inputRef = React.createRef();

  overlayRef = React.createRef();

  componentDidMount() {
    const input = this.inputRef.current;

    if (this.props.autoFocus && input) input.focus();
  }

  // type is mixed to handle flow error
  handleHideOverlay = (value?: mixed) => {
    this.setState({ overlayShown: false, focus: this.initialIndex(), query: '' }, () => {
      if (this.props.onHideOptions) {
        const convertedValue: P = (value: any);
        this.props.onHideOptions(convertedValue);
      }
    });

    if (this.props.onSearch) {
      this.props.onSearch('');
    }
  };

  handleShowOverlay = () => {
    if (!this.props.disabled) {
      this.setState({ overlayShown: true }, () => {
        if (this.state.overlayShown && this.props.onShowOptions) {
          this.props.onShowOptions();
        }
      });
    }
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const container = this.containerRef.current;
    const overlay = this.overlayRef.current;
    const relatedTarget: ?Node = (e: any).relatedTarget || document.activeElement;

    if (
      container &&
      relatedTarget &&
      overlay &&
      (container.contains(relatedTarget) || overlay.contains(relatedTarget))
    ) {
      return;
    }

    this.handleHideOverlay();
  };

  handleFocusOverlay = (e: SyntheticEvent<HTMLDivElement>) => {
    if (this.inputRef.current && !(e.target instanceof HTMLInputElement)) {
      this.inputRef.current.focus();
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (e.key === 'ArrowDown') {
      this.setState(state => ({ focus: this.nextIndex(state.focus, 1) }));
    } else if (e.key === 'ArrowUp') {
      this.setState(state => ({ focus: this.nextIndex(state.focus, -1) }));
    } else if (e.key === 'Enter') {
      e.preventDefault();

      const option = this.options()[this.state.focus];
      if (option && !option.disabled) {
        this.handleOptionSelect(option.value);
      }
    } else if (
      e.key === 'Backspace' &&
      this.props.clearable &&
      !this.props.searchable &&
      this.props.value != null
    ) {
      this.props.onChange(null);
    } else if (e.key === 'Escape') {
      this.handleHideOverlay();
    }
  };

  handleFieldClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.toLowerCase();

    if (this.props.searchable) {
      this.setState({ query: value }, () => {
        this.setState({ focus: this.initialIndex() });
      });

      if (this.props.onSearch) {
        this.props.onSearch(value);
      }
    } else {
      const options = this.options();
      const index = options.findIndex(
        option => !option.disabled && option.label.toLowerCase().startsWith(value),
      );
      if (options[index]) {
        this.setState({ focus: index });
      }
      this.setState({ query: '' });
    }
  };

  handleOptionHover = (index: number) => {
    this.setState({ focus: index });
  };

  handleClear = (e: MouseEvent) => {
    e.stopPropagation();
    this.handleHideOverlay();
    this.props.onChange(null);
  };

  handleOptionSelect(value: P) {
    const option = this.options().find(o => o.value === value);

    if (option && option.onSelect) {
      option.onSelect();
    } else {
      this.handleHideOverlay(value);
      this.props.onChange(value);
    }
  }

  nextIndex(initialIndex: number, direction: 1 | -1 = 1) {
    let index = initialIndex;
    const options = this.options();

    if (options[index]) {
      index += direction;
    } else if (direction > 0) {
      index = 0;
    } else if (direction < 0) {
      index = options.length - 1;
    }

    const enabledOptions = options.filter(o => !o.disabled);
    if (enabledOptions.length === 0) return -1;

    while (options[index] && options[index].disabled) {
      index += direction;
    }

    if (options[index]) return index;
    return this.nextIndex(index, direction);
  }

  initialIndex() {
    return this.nextIndex(-1, 1);
  }

  selectedOption(): ?SelectItem<P> {
    if (this.props.value !== null) {
      return this.props.options.find(option => option.value === this.props.value);
    }
    return null;
  }

  options(): $ReadOnlyArray<SelectItem<P>> {
    const query = this.state ? this.state.query.replace(/\s/g, '') : '';

    if (!query || !this.props.searchable || this.props.onSearch) {
      return this.props.options;
    }

    const queryRegexp = new RegExp(escapeRegexString(query), 'i');
    return this.props.options.filter(option => {
      return (
        (typeof option.label === 'string'
          ? queryRegexp.test(option.label.replace(/\s/g, ''))
          : false) ||
        (typeof option.info === 'string' ? queryRegexp.test(option.info.replace(/\s/g, '')) : false)
      );
    });
  }

  render() {
    const { className, placeholder } = this.props;
    const selectedOption = this.selectedOption();
    const focusedIndex = this.state.focus;
    const SelectedSelectLabel =
      this.props.labelComponent == null ? SelectedLabel : this.props.labelComponent;
    /* eslint-disable react/jsx-no-bind */
    const onSelect = this.handleOptionSelect.bind(this);
    const options = this.options();

    return (
      <Container
        className={className}
        withLabel={this.props.label}
        compact={this.props.compact}
        ref={this.containerRef}
        onBlur={this.handleBlur}
      >
        <Field
          ref={this.fieldRef}
          focused={this.state.overlayShown}
          onClick={this.handleFieldClick}
          compact={this.props.compact}
          disabled={this.props.disabled}
          noBoxShadow={this.props.noBoxShadow}
          error={this.props.error}
          withLabel={this.props.label}
          className={this.props.fieldClassName}
        >
          <Input
            onFocus={this.handleShowOverlay}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleInputChange}
            value={this.state.query}
            ref={this.inputRef}
            shown={this.props.searchable && this.state.overlayShown}
            placeholder={this.props.searchPlaceholder || this.props.placeholder}
            autoComplete="new-password"
          />
          {(!this.props.searchable || !this.state.overlayShown) && (
            <SelectedSelectLabel
              showPlaceholder={!selectedOption}
              error={this.props.error}
              value={this.props.value}
              disabled={this.props.disabled}
            >
              {selectedOption ? selectedOption.label : placeholder}
            </SelectedSelectLabel>
          )}
          {selectedOption &&
            selectedOption.icon &&
            (!this.props.searchable || !this.state.overlayShown) && (
              <Icon className={`fa fa-fw fa-${selectedOption.icon}`} primary small />
            )}
          {this.props.loading && <Icon className="fa fa-circle-o-notch fa-spin" small />}
          {!this.props.disabled &&
            this.props.clearable &&
            (!this.props.searchable || !this.state.overlayShown) &&
            selectedOption && (
              <ClearIcon className="fa fa-fw fa-times" onClick={this.handleClear} tabIndex={-1} />
            )}
          {!this.props.disabled && (
            <Icon className="fa fa-fw fa-angle-down" error={this.props.error} />
          )}
        </Field>
        <Label
          error={this.props.error}
          focused={this.state.overlayShown}
          data-collapsed={
            this.state.overlayShown ||
            this.props.value != null ||
            this.props.placeholder ||
            selectedOption ||
            undefined
          }
        >
          {this.props.label}
          {this.props.required && <Required>*</Required>}
        </Label>
        {options.length > 0 && (
          <StyledOverlay
            show={this.state.overlayShown}
            container={this.props.overlayContainer || this.containerRef.current}
            target={this.fieldRef.current}
            overlayRef={this.overlayRef}
            height={this.props.height}
            flagClass={this.props.flagClass}
          >
            <OverlayFocus tabIndex={-1} onFocus={this.handleFocusOverlay}>
              {
                options.reduce(
                  (obj, option) => {
                    const { groups, childComponents, counter } = obj;
                    const newObj = {};
                    const groupMatch = groups.find(group => group.value === option.groupId);
                    const optionKey =
                      typeof option.value === 'string' || typeof option.value === 'number'
                        ? option.value
                        : obj.counter;
                    if (groupMatch) {
                      newObj.groups = groups.filter(group => group.value !== groupMatch.value);
                      childComponents.push(
                        <OptionGroupLabel key={groupMatch.value}>
                          {groupMatch.label}
                        </OptionGroupLabel>,
                      );
                    } else {
                      newObj.groups = groups;
                    }
                    childComponents.push(
                      option.render ? (
                        option.render({
                          onSelect,
                          onHover: this.handleOptionHover,
                          onHide: this.handleHideOverlay,
                          focused: focusedIndex === obj.counter,
                          index: obj.counter,
                          label: option.label,
                          displayLabel: option.displayLabel,
                          info: option.info,
                          placeholder: option.placeholder,
                          parent: option.parent,
                          value: option.value,
                          disabled: option.disabled,
                        })
                      ) : (
                        <SelectFieldRow
                          label={option.label}
                          displayLabel={option.displayLabel}
                          info={option.info}
                          value={option.value}
                          key={optionKey}
                          onSelect={onSelect}
                          onHover={this.handleOptionHover}
                          focused={focusedIndex === obj.counter}
                          index={obj.counter}
                          icon={option.icon}
                          disabled={option.disabled}
                        />
                      ),
                    );
                    newObj.childComponents = childComponents;
                    newObj.counter = counter + 1;
                    return newObj;
                  },
                  { counter: 0, childComponents: [], groups: this.props.optionGroups || [] },
                ).childComponents
              }
            </OverlayFocus>
          </StyledOverlay>
        )}
        {this.props.error && <Error>{this.props.error}</Error>}
      </Container>
    );
  }
}
