/* @flow */
import * as React from 'react';

import countries from 'config/countries.json';
import { eventRequestFormStatuses } from 'config/eventRequestFormStatuses';
import eventStates from 'config/eventStates';
import syncStatuses from 'config/syncStatuses.json';
import usStates from 'config/us_states.json';

import type { FieldType } from 'utils/customization/types';
import renderFilterByKind from 'utils/filters/renderFilterByKind';

import type { ParsedAllEventsFilters } from 'components/AllEvents/lib/parseAllEventsFilters';
import AutocompleteFilterItem from 'components/material/Filters/Advanced/AutocompleteFilterItem';
import CheckFilterItem from 'components/material/Filters/Advanced/CheckFilterItem';
import DateFilterItem from 'components/material/Filters/Advanced/DateFilterItem';
import { type FilterValueChangeParam } from 'components/material/Filters/Advanced/FilterItems';
import RangeFilterItem from 'components/material/Filters/Advanced/RangeFilterItem';
import TextFilterItem from 'components/material/Filters/Advanced/TextFilterItem';
import UserContactFilterItem from 'components/material/Filters/Advanced/UserContactFilterItem';
import UserFilterItem from 'components/material/Filters/Advanced/UserFilterItem';
import { EVENT_FORMAT_TYPES } from 'views/Main/Event/window/EventForm';

type FieldFilter = {
  filter: string,
  label?: string,
  searchPlaceholder?: string,
  secondFilter?: string,
};

export const fieldFilterMap: { [string]: FieldFilter } = {
  name: {
    filter: 'queries',
  },
  start_date: {
    filter: 'date',
    label: 'Date',
  },
  team: {
    filter: 'teamIds',
    label: 'Teams',
  },
  status: {
    filter: 'statuses',
  },
  lead: {
    filter: 'leadIds',
    searchPlaceholder: 'Search leader',
  },
  event_staff: {
    filter: 'staffIds',
    searchPlaceholder: 'Search members',
  },
  venue_name: {
    filter: 'venueNames',
  },
  venue_city: {
    filter: 'cities',
  },
  venue_state: {
    filter: 'states',
  },
  venue_country: {
    filter: 'countries',
  },
  event_format: {
    filter: 'eventFormats',
  },
  sync_status: {
    filter: 'syncStatuses',
  },
  opportunities_number: {
    filter: 'opportunitiesNumber',
  },
  opportunities_amount: {
    filter: 'opportunitiesAmount',
  },
  request_form: {
    filter: 'requestFormIds',
  },
  requested_by: {
    filter: 'requesterUserIds',
    secondFilter: 'requesterContactIds',
    searchPlaceholder: 'Search Requesters',
  },
  requested_date: {
    filter: 'requestedDate',
  },
  request_status: {
    filter: 'requestStatuses',
  },
  request_reviewers: {
    filter: 'requestReviewers',
    label: 'Request Reviewers',
    searchPlaceholder: 'Search Members',
  },
  id: {
    filter: 'eventIds',
  },
  updated_at: {
    filter: 'updatedAt',
  },
  updater: {
    filter: 'updaterIds',
    searchPlaceholder: 'Search Members',
  },
  created_at: {
    filter: 'createdAt',
  },
  creator: {
    filter: 'creatorIds',
    searchPlaceholder: 'Search Members',
  },
  contacts_count: {
    filter: 'contactsCount',
  },
  registered_contacts_total: {
    filter: 'registeredContactsTotal',
  },
  attended_contacts_total: {
    filter: 'attendedContactsTotal',
  },
};

const syncStatusOptions: $ReadOnlyArray<{
  label: $Values<typeof syncStatuses>,
  value: $Keys<typeof syncStatuses>,
  labelRenderer?: () => React.Node,
}> = Object.entries(syncStatuses).map(([value, label]) => ({
  label: value === 'DISABLED' ? 'Not Connected' : label,
  value,
}));

export default function renderEventFilter(
  customField: FieldType,
  filters: $Shape<ParsedAllEventsFilters>,
  onFilterChange: (name: string, value: FilterValueChangeParam) => void,
  settings: {
    skipDateFilters?: boolean,
    fiscalYearStart?: number,
    showNameFilter?: boolean,
    isBudgetFilters?: boolean,
    teams: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
      |},
    |}>,
    eventRequestForms: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
      |},
    |}>,
  },
): React.Node {
  const { fieldName, label } = customField;
  const {
    teams,
    eventRequestForms,
    skipDateFilters,
    fiscalYearStart,
    showNameFilter,
    isBudgetFilters,
  } = settings;
  const fieldLabel =
    (fieldName && fieldFilterMap[fieldName] && fieldFilterMap[fieldName].label) || label;
  const name = (fieldName && fieldFilterMap[fieldName] && fieldFilterMap[fieldName].filter) || '';
  const secondaryName =
    (fieldName && fieldFilterMap[fieldName] && fieldFilterMap[fieldName].secondFilter) || '';
  const activeValues =
    (fieldName && fieldFilterMap[fieldName] && filters[fieldFilterMap[fieldName].filter]) || null;
  const secondaryActiveValues =
    (fieldName &&
      fieldFilterMap[fieldName] &&
      fieldFilterMap[fieldName].secondFilter &&
      filters[fieldFilterMap[fieldName].secondFilter]) ||
    null;
  const searchPlaceholder =
    (fieldName && fieldFilterMap[fieldName] && fieldFilterMap[fieldName].searchPlaceholder) || '';

  if (fieldName === 'name' && showNameFilter) {
    return (
      <TextFilterItem
        key={customField.id}
        label={fieldLabel}
        name={name}
        onChange={onFilterChange}
        activeValues={activeValues}
      />
    );
  }

  switch (fieldName) {
    case 'venue_name':
    case 'venue_city':
    case 'id':
      return (
        <TextFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          onChange={onFilterChange}
          activeValues={activeValues}
        />
      );
    case 'start_date':
      return !skipDateFilters ? (
        <DateFilterItem
          key={customField.id}
          label={isBudgetFilters ? 'Budget Period' : fieldLabel}
          name={name}
          onChange={onFilterChange}
          activeValue={activeValues}
          fiscalYearStart={fiscalYearStart}
          hideShortcutOptions={isBudgetFilters}
          showFiscalOptions
        />
      ) : null;
    case 'team':
      return (
        <CheckFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          maxOptions={15}
          options={teams.map(({ node }) => ({
            value: node.id,
            label: node.name,
          }))}
          onChange={onFilterChange}
          activeValues={activeValues}
        />
      );
    case 'status':
      return (
        <CheckFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          options={eventStates.map(status => ({
            label: status.label,
            value: status.graphValue,
          }))}
          onChange={onFilterChange}
          activeValues={activeValues}
          unsorted
        />
      );
    case 'updater':
    case 'creator':
    case 'lead':
    case 'event_staff':
      return (
        <UserFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          onChange={onFilterChange}
          activeValues={activeValues}
          searchPlaceholder={searchPlaceholder}
        />
      );
    case 'venue_state':
      return (
        <AutocompleteFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          onChange={onFilterChange}
          activeValues={activeValues}
          options={Object.entries(usStates).map(([key, value]) => [String(value), String(key)])}
          allowCustomValue
          autoFocus
        />
      );
    case 'venue_country':
      return (
        <AutocompleteFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          onChange={onFilterChange}
          activeValues={activeValues}
          options={['United States', ...countries.filter(c => c !== 'United States')]}
          autoFocus
        />
      );
    case 'event_format':
      return (
        <CheckFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          options={Object.keys(EVENT_FORMAT_TYPES).map(key => ({
            label: EVENT_FORMAT_TYPES[key],
            value: key.toUpperCase(),
          }))}
          onChange={onFilterChange}
          activeValues={activeValues}
          unsorted
        />
      );
    case 'sync_status':
      return (
        <CheckFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          options={syncStatusOptions}
          onChange={onFilterChange}
          activeValues={activeValues}
        />
      );
    case 'request_form':
      return (
        <CheckFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          options={eventRequestForms.map(({ node }) => ({
            value: node.id,
            label: node.name,
          }))}
          onChange={onFilterChange}
          activeValues={activeValues}
        />
      );
    case 'requested_by':
      return (
        <UserContactFilterItem
          key={customField.id}
          label={fieldLabel}
          userFilterName={name}
          contactFilterName={secondaryName}
          onChange={onFilterChange}
          userIds={activeValues}
          contactIds={secondaryActiveValues}
          searchPlaceholder={searchPlaceholder}
        />
      );
    case 'requested_date':
    case 'updated_at':
    case 'created_at':
      return (
        <DateFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          onChange={onFilterChange}
          activeValue={activeValues}
          hideShortcutOptions
        />
      );
    case 'request_status':
      return (
        <CheckFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          options={eventRequestFormStatuses}
          onChange={onFilterChange}
          activeValues={activeValues}
          unsorted
        />
      );
    case 'request_reviewers':
      return (
        <UserFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          onChange={onFilterChange}
          activeValues={activeValues}
          searchPlaceholder={searchPlaceholder}
          hasTeamAccess
        />
      );
    case 'registered_contacts_total':
    case 'attended_contacts_total':
    case 'contacts_count':
    case 'opportunities_number':
    case 'opportunities_amount':
      return (
        <RangeFilterItem
          key={customField.id}
          label={fieldLabel}
          name={name}
          onChange={onFilterChange}
          activeValue={activeValues}
          isCurrency={fieldName === 'opportunities_amount'}
          autoFocus
        />
      );

    default:
      return renderFilterByKind(customField, filters[customField.id], onFilterChange);
  }
}
