/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router';
import { type Location, Link } from 'react-router-dom';
import styled from 'styled-components';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';

import FeatureAccessContext from 'contexts/FeatureAccess';

import Submenu from 'components/Submenu';
import SubmenuItem from 'components/Submenu/SubmenuItem';

import type { InformationSubmenu_event } from './__generated__/InformationSubmenu_event.graphql';
import type { InformationSubmenu_org } from './__generated__/InformationSubmenu_org.graphql';

const StyledLink = styled(Link)`
  display: inline-block;
  margin: 5px 0 0 25px;
  cursor: pointer;
  color: #39a8da;

  &:hover {
    color: #009dce;
    text-decoration: underline;
  }
`;

export type SectionType = $PropertyType<
  $ElementType<
    $PropertyType<$PropertyType<InformationSubmenu_org, 'customFieldSections'>, 'edges'>,
    0,
  >,
  'node',
>;

class InformationSubmenu extends React.Component<{
  org: InformationSubmenu_org,
  event: InformationSubmenu_event,
  location: Location,
}> {
  static contextType = FeatureAccessContext;

  renderSections = (sections: $ReadOnlyArray<SectionType>) => {
    const { event, location } = this.props;
    if (sections.length > 0) {
      return sections.map(section => (
        <SubmenuItem
          key={section.id}
          to={`/events/${event.slug}/info/${section.order === 0 ? '' : section.id}`}
          active={RegExp(
            `/events/(.*)/info${section.order === 0 ? '(/)?' : `/${section.id}`}$`,
          ).test(location.pathname)}
          exact
        >
          {section.name}
        </SubmenuItem>
      ));
    }

    return null;
  };

  render() {
    const { org, event, location } = this.props;
    const sortedSections = sortBy(
      org.customFieldSections.edges.map(({ node }) => node),
      'order',
    );
    const [basicSections, otherSections] = partition([...sortedSections], { order: 0 });

    return (
      <Submenu>
        {this.renderSections(basicSections)}
        <SubmenuItem
          to={`/events/${event.slug}/info/floorPlan`}
          active={RegExp('/events/(.*)/info/floorPlan').test(location.pathname)}
        >
          Floor Plan
        </SubmenuItem>
        {this.renderSections(otherSections.filter(section => section.numberOfActiveFields > 0))}
        {this.context.legacyFeatures && event.viewerCanViewROI && (
          <SubmenuItem
            to={`/events/${event.slug}/info/crm`}
            active={RegExp('/events/(.*)/info/crm').test(location.pathname)}
          >
            CRM
          </SubmenuItem>
        )}
        <SubmenuItem
          to={`/events/${event.slug}/info/team`}
          active={RegExp('/events/(.*)/info/team').test(location.pathname)}
        >
          Team
        </SubmenuItem>
        {org.viewerCanUpdate && (
          <StyledLink
            to={{
              pathname: '/settings/events/event_fields',
              state: {
                from: 'eventInfo',
                fromUrl: `${location.pathname}${location.search}`,
              },
            }}
          >
            Manage Fields
          </StyledLink>
        )}
      </Submenu>
    );
  }
}

export default createFragmentContainer(
  withRouter(InformationSubmenu),
  graphql`
    fragment InformationSubmenu_org on Org {
      viewerCanUpdate
      customFieldSections(sectionCustomizableType: EVENT) {
        edges {
          node {
            id
            name
            order
            numberOfActiveFields
          }
        }
      }
    }
    fragment InformationSubmenu_event on Event {
      slug
      viewerCanViewROI
    }
  `,
);
