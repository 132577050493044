/* @flow */
import {
  type ColumnConfiguration,
  BaseColumn,
  BaseColumnAllowOverflow,
} from 'components/material/table';

import ImportLogsDateColumn from './ImportLogsDateColumn';
import ImportLogsErrorCountColumn from './ImportLogsErrorCountColumn';
import ImportLogsFilenameColumn from './ImportLogsFilenameColumn';
import ImportLogsSuccessCountColumn from './ImportLogsSuccessCountColumn';
import ImportLogsUserColumn from './ImportLogsUserColumn';

const colsConfig: ColumnConfiguration = [
  {
    label: 'File Name',
    key: 'FILE_NAME',
    component: BaseColumn,
    componentContent: ImportLogsFilenameColumn,
    value: 'filename',
    width: 300,
    grow: 1,
  },
  {
    label: 'Imported Date',
    key: 'CREATED_AT',
    component: BaseColumnAllowOverflow,
    componentContent: ImportLogsDateColumn,
    value: 'createdAt',
    width: 120,
    grow: 0.2,
  },
  {
    label: 'Imported by',
    key: 'USER',
    component: BaseColumn,
    componentContent: ImportLogsUserColumn,
    value: 'user',
    width: 160,
    grow: 0.2,
  },
  {
    label: 'Imported count',
    key: 'SUCCESS_COUNT',
    component: BaseColumn,
    componentContent: ImportLogsSuccessCountColumn,
    value: 'successCount',
    width: 130,
    grow: 0.2,
  },
  {
    label: 'Errors count',
    key: 'ERRORS_COUNT',
    component: BaseColumn,
    componentContent: ImportLogsErrorCountColumn,
    value: 'errorCount',
    width: 150,
  },
];

export default colsConfig;
