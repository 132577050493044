import './styles.styl';

import React from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget } from 'react-dnd';
import classNames from 'classnames';

const fileTarget = {
  drop(props, monitor) {
    props.onDrop(monitor.getItem().files);
  },
};

class FileDropZone extends React.Component {
  render() {
    const { connectDropTarget, isOver, canDrop, text } = this.props;
    const content = (
      <div
        className={classNames('dropzone-container', { dragging: !this.props.disabled && isOver })}
      >
        {!this.props.noPreview && (
          <div className={classNames('dropzone', this.props.className)}>
            <div className="attachment-drop">
              <i className="fa fa-cloud-upload" />
              <p>{text || 'Drop files here to add as attachment...'}</p>
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );

    if (this.props.disabled) return content;
    return connectDropTarget(content);
  }
}

export const DropZone = DropTarget(NativeTypes.FILE, fileTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(FileDropZone);
