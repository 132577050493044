/* @flow */

import isValidWebsite from 'utils/validators/isValidWebsite';

import mappingCustomKinds from './mappingCustomKinds';
import { type QuestionType, type QuestionValueType } from './types';

const mappingCustomValueValidators: $ObjMap<
  typeof mappingCustomKinds,
  () => (QuestionType, QuestionValueType) => ?string,
> = {
  DEFAULT: () => null,
  TEXT: (question, { textValue }) =>
    !question.required || (textValue || '').trim() ? null : 'Required',
  TEXTAREA: (question, { textareaValue }) =>
    !question.required || (textareaValue || '').trim() ? null : 'Required',
  LINK: (question, { textValue }) =>
    (textValue ? !isValidWebsite(textValue) && 'Invalid url' : question.required && 'Required') ||
    null,
  NUMBER: (question, { numberValue }) => {
    if (numberValue != null && numberValue.toString().length > 9) {
      return 'Number fields cannot be more than 9 digits';
    }
    return !question.required || numberValue != null ? null : 'Required';
  },
  CURRENCY: (question, { numberValue }) =>
    !question.required || numberValue != null ? null : 'Required',
  DATE: (question, { dateValue }) =>
    !question.required || (dateValue && dateValue.startDate) ? null : 'Required',
  BOOLEAN: (question, { booleanValue }) => (!question.required || booleanValue ? null : 'Required'),
  USER_SELECT: (question, { selectIdsValue }) =>
    !question.required || selectIdsValue.length > 0 ? null : 'Required',
  USER_MULTISELECT: (question, { selectIdsValue }) =>
    !question.required || selectIdsValue.length > 0 ? null : 'Required',
  SELECT: (question, { selectIdsValue, textValue }) => {
    const optionIds = question.selectOptions.map(option => option.id);
    return !question.required ||
      (selectIdsValue.length > 0 && selectIdsValue.every(value => optionIds.includes(value))) ||
      (question.selectShowOtherOption && (textValue || '').trim())
      ? null
      : 'Required';
  },
  MULTISELECT: (question, { selectIdsValue, textValue }) => {
    if (
      question.maxSelectionSize != null &&
      question.maxSelectionSize <
        selectIdsValue.length + (question.selectShowOtherOption && (textValue || '').trim() ? 1 : 0)
    ) {
      return `You can select maximum ${question.maxSelectionSize} option${
        question.maxSelectionSize > 1 ? 's' : ''
      }`;
    }
    const optionIds = question.selectOptions.map(option => option.id);
    return !question.required ||
      (selectIdsValue.length > 0 && selectIdsValue.some(value => optionIds.includes(value))) ||
      (question.selectShowOtherOption && (textValue || '').trim())
      ? null
      : 'Required';
  },
};

export default mappingCustomValueValidators;
