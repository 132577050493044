/* @flow */
import React from 'react';
import styled from 'styled-components';

import Button from 'components/budget/Button';
import ConnectCampaignPanel from 'components/Salesforce/ConnectCampaignPanel';

const Container = styled.div`
  position: relative;
`;

export default class SalesforceCampaignConnector extends React.PureComponent<
  {
    eventId: string,
  },
  {
    showConnectPanel: boolean,
  },
> {
  state = { showConnectPanel: false };

  target = React.createRef();

  container = React.createRef();

  handleShowConnectPanel = () => {
    this.setState({ showConnectPanel: true });
  };

  handleHideConnectPanel = () => {
    this.setState({ showConnectPanel: false });
  };

  render() {
    const eventId = this.props.eventId;
    return (
      <Container ref={this.container}>
        <Button onClick={this.handleShowConnectPanel} ref={this.target}>
          Connect Campaign
        </Button>

        <ConnectCampaignPanel
          show={this.state.showConnectPanel}
          eventId={eventId}
          onHide={this.handleHideConnectPanel}
          container={this.container && this.container.current}
          target={this.target && this.target.current}
        />
      </Container>
    );
  }
}
