/* @flow */
import React from 'react';
import moment from 'moment-timezone';

import { type CustomFieldsType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';

import { type UpdateCustomFieldValueInput } from 'graph/mutations/custom_field/updateCustomFieldValue';

import type { QuestionType } from 'components/ContactForm/lib/types';
import CheckBox from 'components/SharedForm/CheckBox';
import CostField from 'components/SharedForm/CostField';
import DateField from 'components/SharedForm/DateField';
import LinkField from 'components/SharedForm/LinkField';
import MultiSelect from 'components/SharedForm/MultiSelectField';
import NumberField from 'components/SharedForm/NumberField';
import SelectField from 'components/SharedForm/SelectField';
import TextField from 'components/SharedForm/TextField';
import UserSelectField from 'components/SharedForm/UserSelectField';
import { type User } from 'components/UserSelect';

export type Props = {
  customizable: CustomFieldsType,
  handleUpdate: (
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<User>,
  ) => void,
  // eslint-disable-next-line
  viewerCanUpdate: boolean,
  question: QuestionType,
  // eslint-disable-next-line
  currency: string,
  // eslint-disable-next-line
  tz: string,
  // eslint-disable-next-line
  errors: { [string]: string },
  // eslint-disable-next-line react/no-unused-prop-types
  dragStartId?: string,
};

const customFields = {
  LINK: ({ customizable, handleUpdate, viewerCanUpdate, question, errors }: Props) => {
    const linkField = customizable.customLinkFields.find(
      edge => question.customField && edge.customField.id === question.customField.id,
    );
    const linkValue = linkField ? linkField.value : '';
    return (
      <LinkField
        defaultValue={linkValue}
        onBlur={e => {
          const value = enforceHttpPrefix(e.currentTarget.value.trim());
          if (value !== linkValue) {
            e.currentTarget.value = value;
          }
          handleUpdate({ linkValue: value });
        }}
        label={question.label}
        name={question.id}
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
      />
    );
  },
  TEXT: ({ customizable, handleUpdate, question, errors, viewerCanUpdate }: Props) => {
    const textField = customizable.customTextFields.find(
      edge => question.customField && edge.customField.id === question.customField.id,
    );
    const textValue = textField ? textField.value : '';
    return (
      <TextField
        defaultValue={textValue}
        onBlur={e => handleUpdate({ textValue: e.currentTarget.value })}
        label={question.label}
        name={question.id}
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
        disabled={!viewerCanUpdate}
      />
    );
  },
  TEXTAREA: ({ customizable, handleUpdate, question, errors, viewerCanUpdate }: Props) => {
    const textareaField = customizable.customTextareaFields.find(
      edge => question.customField && edge.customField.id === question.customField.id,
    );
    const textareaValue = textareaField ? textareaField.value : '';
    return (
      <TextField
        multiline
        defaultValue={textareaValue}
        onBlur={e => handleUpdate({ textareaValue: e.currentTarget.value })}
        label={question.label}
        name={question.id}
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
        disabled={!viewerCanUpdate}
      />
    );
  },
  NUMBER: ({ customizable, handleUpdate, question, errors, viewerCanUpdate }: Props) => {
    const numberField = customizable.customNumberFields.find(
      edge => question.customField && edge.customField.id === question.customField.id,
    );
    const numberValue = numberField ? numberField.value : null;
    return (
      <NumberField
        defaultValue={numberValue}
        onBlur={(e, value) => handleUpdate({ numberValue: value })}
        label={question.label}
        name={question.id}
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
        disabled={!viewerCanUpdate}
      />
    );
  },
  BOOLEAN: ({ customizable, handleUpdate, question, viewerCanUpdate, errors }: Props) => {
    const booleanField = customizable.customBooleanFields.find(
      edge => question.customField && edge.customField.id === question.customField.id,
    );
    return (
      <CheckBox
        compact
        checked={booleanField ? booleanField.value : false}
        name={question.id}
        label={question.label}
        onChange={() => handleUpdate({ booleanValue: booleanField ? !booleanField.value : true })}
        disabled={!viewerCanUpdate}
        error={errors[question.customField ? question.customField.id : question.id]}
        required={question.required}
      />
    );
  },
  CURRENCY: ({
    customizable,
    handleUpdate,
    question,
    currency,
    errors,
    viewerCanUpdate,
  }: Props) => {
    const currencyField = customizable.customCurrencyFields.find(
      edge => question.customField && edge.customField.id === question.customField.id,
    );
    const currencyValue = currencyField ? currencyField.value : null;
    return (
      <CostField
        defaultValue={currencyValue}
        name={question.id}
        label={question.label}
        currency={currency}
        onBlur={(e, value) => handleUpdate({ currencyValue: value })}
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
        disabled={!viewerCanUpdate}
      />
    );
  },
  DATE: ({ customizable, handleUpdate, question, viewerCanUpdate, tz, errors }: Props) => {
    const dateField = customizable.customDateFields.find(
      edge => question.customField && edge.customField.id === question.customField.id,
    );

    return (
      <DateField
        label={question.label}
        tz={tz}
        defaultValue={dateField ? moment.tz(dateField.value, tz).format() : ''}
        hideTime
        onChange={time => {
          if (
            (dateField && !time.date) ||
            (!dateField && time.date) ||
            (dateField &&
              time.date &&
              !moment.tz(dateField.value, tz).isSame(moment.tz(time.date, tz), 'day'))
          ) {
            handleUpdate({ dateValue: time.date });
          }
        }}
        disabled={!viewerCanUpdate}
        dateOnly
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
      />
    );
  },
  SELECT: ({
    customizable,
    handleUpdate,
    viewerCanUpdate,
    question,
    errors,
    dragStartId,
  }: Props) => {
    const selectField = customizable.customSelectFields.find(
      edge => question.customField && edge.customField.id === question.customField.id,
    );

    const options = question.customField ? question.customField.options : [];

    const selectedValue =
      selectField && options.find(option => option.value === selectField.option.id);

    return (
      <SelectField
        label={question.label}
        otherOptionValue={selectField ? selectField.selectOtherValue : null}
        values={selectedValue ? [selectedValue.value] : []}
        onChange={value => {
          if (
            (selectedValue && !value) ||
            (!selectedValue && value) ||
            (selectedValue && value && selectedValue.value !== value)
          ) {
            handleUpdate({ selectValue: value, selectOtherValue: null });
          }
        }}
        onChangeOtherInput={value => {
          handleUpdate({ selectValue: 'other', selectOtherValue: value });
        }}
        options={options.slice(0, dragStartId === question.id ? 10 : undefined)}
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
      />
    );
  },
  MULTISELECT: ({
    customizable,
    handleUpdate,
    viewerCanUpdate,
    question,
    errors,
    dragStartId,
  }: Props) => {
    const multiselectValues = customizable.customMultiselectFields
      .filter(item => question.customField && item.customField.id === question.customField.id)
      .map(item => item.option.id);
    return (
      <MultiSelect
        label={question.label}
        values={multiselectValues}
        onChange={(value, checked) =>
          handleUpdate({
            selectValues: checked
              ? [...multiselectValues, value]
              : multiselectValues.filter(val => val !== value),
          })
        }
        onChangeOtherInput={value => {
          handleUpdate({ selectValues: multiselectValues, selectOtherValue: value });
        }}
        options={
          question.customField
            ? question.customField.options.slice(0, dragStartId === question.id ? 8 : undefined)
            : []
        }
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
      />
    );
  },
  USER_SELECT: ({
    customizable,
    handleUpdate,
    viewerCanUpdate,
    question,
    errors,
    dragStartId,
  }: Props) => {
    const userField = customizable.customUserSelectFields.find(
      edge => question.customField && edge.customField.id === question.customField.id,
    );

    return (
      <UserSelectField
        label={question.label}
        kind="SELECT"
        skipUserIds={question.contactFormFieldOptions || []}
        values={userField ? [userField.user.id] : []}
        selectedUsers={userField ? [userField.user] : []}
        onChange={selectUser => {
          if (
            (userField && !selectUser) ||
            (!userField && selectUser) ||
            (userField && selectUser && userField.user.id !== selectUser.id)
          ) {
            handleUpdate(
              { userValue: selectUser ? selectUser.id : null },
              selectUser ? [selectUser] : [],
            );
          }
        }}
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
        dragStart={dragStartId === question.id}
      />
    );
  },
  USER_MULTISELECT: ({
    customizable,
    handleUpdate,
    viewerCanUpdate,
    question,
    errors,
    dragStartId,
  }: Props) => {
    const selectedUsers = customizable.customUserMultiselectFields
      .filter(option => question.customField && option.customField.id === question.customField.id)
      .map(option => ({
        ...option.user,
        uid: option.user.id,
      }));
    const selectedUsersIds = selectedUsers.map(user => user.id);

    return (
      <UserSelectField
        label={question.label}
        kind="MULTISELECT"
        skipUserIds={question.contactFormFieldOptions || []}
        selectedUsers={selectedUsers}
        values={selectedUsersIds}
        onChange={(selectUser, checked) => {
          handleUpdate(
            {
              userValues: checked
                ? [...selectedUsersIds, selectUser.id]
                : selectedUsersIds.filter(userId => userId !== selectUser.id),
            },
            [...customizable.customUserMultiselectFields.map(node => node.user), selectUser],
          );
        }}
        disabled={!viewerCanUpdate}
        required={question.required}
        error={errors[question.customField ? question.customField.id : question.id]}
        dragStart={dragStartId === question.id}
      />
    );
  },
};

export default customFields;
