/**
 * @flow
 * @relayHash 076d273268fcddd0d1cc9b6ac1c6296e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VendorFieldSearchQueryVariables = {|
  vendorId: string
|};
export type VendorFieldSearchQueryResponse = {|
  +selectedVendor: ?{|
    +id: string,
    +firstName?: ?string,
    +lastName?: ?string,
    +email?: ?string,
    +name?: string,
  |}
|};
export type VendorFieldSearchQuery = {|
  variables: VendorFieldSearchQueryVariables,
  response: VendorFieldSearchQueryResponse,
|};
*/


/*
query VendorFieldSearchQuery(
  $vendorId: ID!
) {
  selectedVendor: node(id: $vendorId) {
    __typename
    id
    ... on Vendor {
      id
      firstName
      lastName
      email
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vendorId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "vendorId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "type": "Vendor",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "VendorFieldSearchQuery",
  "id": null,
  "text": "query VendorFieldSearchQuery(\n  $vendorId: ID!\n) {\n  selectedVendor: node(id: $vendorId) {\n    __typename\n    id\n    ... on Vendor {\n      id\n      firstName\n      lastName\n      email\n      name\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "VendorFieldSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "selectedVendor",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          v2,
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VendorFieldSearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "selectedVendor",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v2,
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e86d834a9c5eaf46b7f91b88cfef9f93';
module.exports = node;
