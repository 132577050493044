/* @flow */

const questionFileExtensions = {
  PDF: 'application/pdf',
  DOC: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PNG: 'image/png',
  JPG: 'image/jpeg',
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export default questionFileExtensions;
