/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import removeExpense from 'graph/mutations/expense/removeExpense';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import ShareDropdown from 'components/ShareDropdown';

import { type VendorExpenseCellPropsType } from './index';

import { type VendorExpenseAction_expense } from './__generated__/VendorExpenseAction_expense.graphql';

const Root = styled.div`
  margin: 0 -5px 0 -24px;
`;

class VendorExpenseAction extends React.PureComponent<
  VendorExpenseCellPropsType<VendorExpenseAction_expense>,
  { showConfirmation: boolean },
> {
  state = { showConfirmation: false };

  handleRemoveExpense = () => {
    removeExpense({
      expenseId: this.props.expense.id,
      eventId: this.props.expense.event.id,
      vendorId: this.props.vendorId,
    }).catch(showModernMutationError);
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    return (
      <Root>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={[
            {
              label: 'Delete',
              icon: 'trash',
              onClick: this.handleShowConfirmation,
            },
          ]}
          overlayContainer={document.body}
        />

        {this.state.showConfirmation &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              message="Once you delete this expense, it's gone for good."
              onConfirm={this.handleRemoveExpense}
              onHide={this.handleHideConfirmation}
            />,
            document.body,
          )}
      </Root>
    );
  }
}

export default createFragmentContainer(
  VendorExpenseAction,
  graphql`
    fragment VendorExpenseAction_expense on Expense {
      id
      name
      event {
        id
      }
    }
  `,
);
