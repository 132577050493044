/* @flow */
import React from 'react';
import styled from 'styled-components';
import Clipboard from 'clipboard';

import { type MarketoProgramType } from '.';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px;
  background: rgba(0, 0, 0, 0.7);
  transform: translateX(70%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
`;

const Root = styled.div`
  position: relative;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    ${Overlay} {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
`;

const CardLink = styled.a`
  display: block;
  padding: 15px;
  color: #fff;
  background: linear-gradient(to bottom, #5b92f4, #3d9ad9);

  [href] {
    &:hover {
      background: linear-gradient(to top, #5b92f4, #3d9ad9);
    }
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const Subtitle = styled.div`
  margin-top: 3px;
  font-size: 13px;
`;

const OverlayLink = styled.a`
  margin: 3px 0;
  font-size: 12px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }

  i {
    margin-right: 3px;
  }
`;

export default class MarketoProgramCard extends React.PureComponent<{
  marketoProgram: MarketoProgramType,
}> {
  copyLinkRef = React.createRef();

  clipboard = null;

  componentDidMount() {
    this.setupClipboard();
  }

  componentDidUpdate() {
    if (this.copyLinkRef.current == null) {
      this.destroyClipboard();
    } else {
      this.setupClipboard();
    }
  }

  componentWillUnmount() {
    this.destroyClipboard();
  }

  setupClipboard = () => {
    if (this.copyLinkRef.current != null) {
      this.clipboard = new Clipboard(this.copyLinkRef.current, {
        text: () => this.props.marketoProgram.url,
      });
    }
  };

  destroyClipboard = () => {
    if (this.clipboard) {
      this.clipboard.destroy();
      this.clipboard = null;
    }
  };

  render() {
    const marketoProgram = this.props.marketoProgram;

    return (
      <Root>
        <CardLink href={marketoProgram.url} target="_blank" rel="noreferrer noopener">
          <Title>{marketoProgram.name}</Title>

          <Subtitle>Marketo ID: {marketoProgram.marketoId}</Subtitle>
        </CardLink>

        <Overlay>
          <OverlayLink ref={this.copyLinkRef}>
            <i className="fa fa-fw fa-clone" /> Copy Marketo Link
          </OverlayLink>

          <OverlayLink href={marketoProgram.url} target="_blank" rel="noreferrer noopener">
            <i className="fa fa-fw fa-external-link" /> Open Marketo Program
          </OverlayLink>
        </Overlay>
      </Root>
    );
  }
}
