/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Chart from 'components/budget/Chart';
import Dropdown from 'components/budget/Dropdown';

import isBudgetEmpty from '../utils/isBudgetEmpty';

import { type BudgetCharts_event } from './__generated__/BudgetCharts_event.graphql';
import { type BudgetCharts_org } from './__generated__/BudgetCharts_org.graphql';

const Root = styled.div`
  position: relative;
  margin: 20px 25px;
  padding: 15px;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05);
`;

const DropdownContainer = styled.div`
  position: absolute;
  z-index: 10;
  width: 150px;
`;

class BudgetCharts extends React.PureComponent<{
  event: BudgetCharts_event,
  org: BudgetCharts_org,
  chart: 'total' | 'category',
  onChangeChart: ('total' | 'category') => void,
}> {
  handleChangeChart = (chart: ?'total' | 'category') => {
    if (!chart) return;

    this.props.onChangeChart(chart);
  };

  render() {
    const { event, org } = this.props;

    if (!isBudgetEmpty(event)) {
      return null;
    }

    const uncategorized = {
      label: 'Uncategorized',
      plannedAmount:
        event.totalBudgetedAmount -
        event.budgetCategories.edges.reduce((sum, edge) => sum + edge.budgetedAmount, 0),
      actualAmount:
        event.totalActualAmount -
        event.budgetCategories.edges.reduce((sum, edge) => sum + edge.actualAmount, 0),
      paidAmount:
        event.totalPaidAmount -
        event.budgetCategories.edges.reduce((sum, edge) => sum + edge.paidAmount, 0),
    };

    const data =
      this.props.chart === 'total'
        ? [
            {
              label: 'Event Total',
              plannedAmount: event.totalBudgetedAmount,
              actualAmount: event.totalActualAmount,
              paidAmount: event.totalPaidAmount,
            },
          ]
        : [
            ...event.budgetCategories.edges.map(edge => ({
              label: edge.node.name,
              plannedAmount: edge.budgetedAmount,
              actualAmount: edge.actualAmount,
              paidAmount: edge.paidAmount,
            })),
            ...(uncategorized.plannedAmount > 0 ? [uncategorized] : []),
          ];

    return (
      <Root>
        {event.budgetCategories.edges.length > 0 && (
          <DropdownContainer>
            <Dropdown
              value={this.props.chart}
              options={[
                { label: 'Total View', value: 'total' },
                { label: 'Category View', value: 'category' },
              ]}
              onChange={this.handleChangeChart}
            />
          </DropdownContainer>
        )}

        <Chart data={data} currency={org.settings.currency} />
      </Root>
    );
  }
}

export default createFragmentContainer(
  BudgetCharts,
  graphql`
    fragment BudgetCharts_event on Event {
      totalBudgetedAmount
      totalActualAmount
      totalPaidAmount

      budgetCategories {
        edges {
          budgetedAmount
          actualAmount
          paidAmount

          node {
            name
          }
        }
      }
    }

    fragment BudgetCharts_org on Org {
      settings {
        currency
      }
    }
  `,
);
