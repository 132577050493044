/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const BorderBox = styled.div`
  width: ${props => (props.compact ? 15 : 17)}px;
  height: ${props => (props.compact ? 15 : 17)}px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid;
  cursor: pointer;
  text-align: center;
  line-height: ${props => (props.compact ? 9 : 12)}px;
  user-select: none;
  i {
    opacity: 0;
    font-size: ${props => (props.compact ? 8 : 10)}px;
    transition: 0.2s;
    transform: scale(0.5);
  }
  & ~ span,
  & ~ div {
    margin-left: ${props => (props.compact ? 8 : 15)}px;
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  color: rgba(74, 86, 101, 0.87);
  input {
    display: none;
    & ~ ${BorderBox} {
      color: ${props => (props.error ? props.theme.negativeActionColor : '#cdd1d4')};
    }
    &:hover {
      & ~ ${BorderBox} {
        color: ${props => (props.error ? props.theme.negativeActionColor : '#868f96')};
      }
    }
    &:checked {
      & ~ ${BorderBox} {
        color: ${props => (props.error ? props.theme.negativeActionColor : '#29cc71')};
        i {
          opacity: 1;
          transform: scale(1);
        }
      }
      & ~ span,
      & ~ div {
        color: #3e4859;
      }
    }
    &:disabled {
      & ~ ${BorderBox} {
        cursor: default;
        border-color: #cdd1d4;
        i {
          color: #cdd1d4;
        }
      }
      & ~ span,
      & ~ div {
        color: rgba(74, 86, 101, 0.54);
      }
    }
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  bottom: -14px;
  left: ${props => (props.compact ? 15 : 17)}px;
  margin-top: 2px;
  font-size: 11px;
  color: ${props => props.theme.negativeActionColor};
`;

const Required = styled.span`
  margin-left: 3px;
  font-size: 11px;
  vertical-align: top;
`;

export default class CheckBox extends React.PureComponent<{
  label?: string,
  checked: boolean,
  disabled?: boolean,
  labelRenderer?: ?(disabled?: boolean) => React.Node,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onClick?: (e: SyntheticMouseEvent<HTMLDivElement>) => void,
  compact?: boolean,
  error?: ?string | ?boolean,
  required?: boolean,
  className?: string,
}> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.props.onChange && !this.props.disabled) {
      this.props.onChange(e);
    }
  };

  handleClick = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick(e);
    }
  };

  render() {
    const { checked, label, labelRenderer, disabled, compact, className } = this.props;
    return (
      <Label className={className} error={this.props.error}>
        <input type="checkbox" checked={checked} onChange={this.handleChange} disabled={disabled} />
        <BorderBox compact={compact} onClick={this.handleClick}>
          <i className="fa fa-fw fa-check" />
        </BorderBox>
        {labelRenderer ? (
          <div onClick={this.handleClick}>
            {labelRenderer(disabled)}
            {this.props.required && <Required>*</Required>}
          </div>
        ) : (
          label && (
            <span onClick={this.handleClick}>
              {label}
              {this.props.required && <Required>*</Required>}
            </span>
          )
        )}

        {typeof this.props.error === 'string' && (
          <ErrorMessage compact={compact}>{this.props.error}</ErrorMessage>
        )}
      </Label>
    );
  }
}
