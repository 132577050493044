/* @flow */
import React from 'react';
import styled from 'styled-components';

import ExpensesImportWindow from 'components/budget/ExpensesImportWindow';
import ImportLogsTable from 'components/ImportLogsTable';
import Button from 'components/material/Button';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 25px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

export default class Imports extends React.PureComponent<{}, { showImportWindow: boolean }> {
  state = {
    showImportWindow: false,
  };

  handleShowImportWindow = () => {
    this.setState({ showImportWindow: true });
  };

  handleHideImportWindow = () => {
    this.setState({ showImportWindow: false });
  };

  render() {
    return (
      <Page>
        <Title>Expense imports</Title>
        <Header>
          <Button label="Import expenses" primary onClick={this.handleShowImportWindow} />

          {this.state.showImportWindow && (
            <ExpensesImportWindow onClose={this.handleHideImportWindow} />
          )}
        </Header>

        <ImportLogsTable type="EXPENSES" />
      </Page>
    );
  }
}
