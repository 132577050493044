/* @flow */
import React from 'react';
import styled from 'styled-components';

import RichText, { EditorContainer } from 'components/RichText';

const Container = styled.div`
  text-align: left;
  word-break: break-word;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 20px;
  }
`;

const Logo = styled.img`
  max-width: 220px;
  max-height: 110px;
  margin-bottom: 33px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #3e4859;
  text-transform: uppercase;
`;

const StyledRichText = styled(RichText)`
  margin-top: 5px;
  white-space: pre-line;
  text-align: center;
  ${EditorContainer} {
    margin-left: 0;
    padding: 0;
    font-size: 14px;
    color: #616c79;
    box-shadow: none;
    &:hover {
      background: none;
    }
  }
`;

export default class FormHeader extends React.PureComponent<{
  logo: string,
  name: string,
  description: string,
  className?: string,
}> {
  render() {
    return (
      <Container className={this.props.className}>
        <Logo src={this.props.logo} />
        <Title>{this.props.name}</Title>
        {this.props.description.trim() && (
          <StyledRichText defaultValue={this.props.description} disabled viewMode />
        )}
      </Container>
    );
  }
}
