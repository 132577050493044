/* @flow */
export type SalesforceSyncTypes = 'lead' | 'contact';

type SyncOption = {
  +name: string, // Label to use in UI
  +value: SalesforceSyncTypes, // value for GraphQL API
  +color: string, // contact tag color
};

const salesforceSyncOptions: $ReadOnlyArray<SyncOption> = [
  {
    value: 'lead',
    name: 'Lead',
    color: '#00bcd4',
  },
  {
    value: 'contact',
    name: 'Contact',
    color: '#c14f93',
  },
];

export default salesforceSyncOptions;
