/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TaskExportCalendar_task$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskHeader_task$ref: FragmentReference;
export type TaskHeader_task = {|
  +following: ?boolean,
  +dueDate: ?any,
  +viewerCanDelete: boolean,
  +$fragmentRefs: TaskExportCalendar_task$ref,
  +$refType: TaskHeader_task$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TaskHeader_task",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "following",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanDelete",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TaskExportCalendar_task",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1e2f91a5714ccbcc863ed3f765ff2221';
module.exports = node;
