/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import omit from 'lodash/omit';

import { upload } from 'utils/Attachments';
import getBudgetQuarterString from 'utils/fiscal/getBudgetQuarterString';
import getCurrencyRates, { type RateType } from 'utils/getCurrencyRates';
import { convertNativeFile } from 'utils/uploading/convertFileStackFile';
import { type CircaFile } from 'utils/uploading/types';

import createExpense from 'graph/mutations/expense/createExpense';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { type TeamAllocationType } from '../../components/TeamAllocation/TeamSelectField';
import InlineFormFields from './InlineFormFields';
import PopupFormFields from './PopupFormFields';

import type { AddExpenseForm_event } from './__generated__/AddExpenseForm_event.graphql';
import type { AddExpenseForm_org } from './__generated__/AddExpenseForm_org.graphql';

export type ExpenseType = {
  name: string,
  amount: number,
  vendorId: ?string,
  eventId: ?string,
  budgetCategoryId: ?string,
  attachments: $ReadOnlyArray<CircaFile>,
  teamAllocations: $ReadOnlyArray<TeamAllocationType>,
};

const emptyExpenseForm = {
  name: '',
  amount: 0,
  vendorId: null,
  eventId: null,
  budgetCategoryId: null,
  attachments: [],
  teamAllocations: [],
};

class AddExpenseForm extends React.PureComponent<
  {
    onCloseButtonClick: () => void,
    org: AddExpenseForm_org,
    event: AddExpenseForm_event,
    popup?: boolean,
    taskId?: string,
    vendorId?: string,
    onEventSelect?: (eventId: ?string) => void,
  },
  {
    currency: string,
    eventId: ?string,
    errors: $ReadOnlyArray<string>,
    expense: ExpenseType,
    actualAmount: number,
    rates: RateType,
    rate: number,
    loading: boolean,
  },
> {
  mounted: boolean = false;

  state = {
    currency: this.props.org.settings.currency,
    errors: [],
    eventId: this.props.event ? this.props.event.id : null,
    expense: {
      ...emptyExpenseForm,
      teamAllocations: [
        {
          percent: 100,
          viewerCanUpdate: this.props.event ? this.props.event.team.viewerCanUpdate : null,
          id: this.props.event ? this.props.event.team.id : '',
          name: this.props.event ? this.props.event.team.name : '',
        },
      ],
    },
    actualAmount: 0,
    rate: 1,
    rates: {},
    loading: false,
  };

  static getDerivedStateFromProps(
    nextProps: $PropertyType<AddExpenseForm, 'props'>,
    prevState: $PropertyType<AddExpenseForm, 'state'>,
  ) {
    if (nextProps.event === null || nextProps.event.id === prevState.eventId) return null;

    return {
      eventId: nextProps.event.id,
      expense: {
        ...prevState.expense,
        teamAllocations: [
          {
            percent: 100,
            viewerCanUpdate: nextProps.event.team.viewerCanUpdate,
            id: nextProps.event.team.id,
            name: nextProps.event.team.name,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.mounted = true;
    getCurrencyRates().then(data => {
      if (this.mounted && data.rates) {
        this.setState({ rates: data.rates });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleNameChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    if (name.trim()) {
      if (name !== this.state.expense.name) {
        this.setState(previousState => ({
          expense: {
            ...previousState.expense,
            name,
          },
        }));
      }
      if (this.state.errors.includes('name')) {
        this.setState(prevState => ({
          errors: prevState.errors.filter(error => error !== 'name'),
        }));
      }
    } else if (!this.state.errors.includes('name')) {
      this.setState(prevState => ({
        errors: [...prevState.errors, 'name'],
        expense: {
          ...prevState.expense,
          name: '',
        },
      }));
    }
  };

  handleAmountChange = (e: SyntheticEvent<HTMLInputElement>, amount: ?number) => {
    const { rate } = this.state;
    const exchangedAmount = amount == null ? 0 : Math.round(amount / rate);
    const actualAmount = amount == null ? 0 : Math.round(amount);
    if (exchangedAmount == null) {
      if (!this.state.errors.includes('cost')) {
        this.setState(prevState => ({
          expense: {
            ...prevState.expense,
            amount: 0,
          },
          errors: [...prevState.errors, 'cost'],
        }));
      }
    } else {
      if (this.state.expense.amount !== amount) {
        this.setState(previousState => ({
          actualAmount,
          expense: {
            ...previousState.expense,
            amount: exchangedAmount,
          },
        }));
      }
      if (this.state.errors.includes('cost')) {
        this.setState(prevState => ({
          errors: prevState.errors.filter(error => error !== 'cost'),
        }));
      }
    }
  };

  handleCurrencyChange = (currency: string) => {
    this.setState({ currency });
    this.handleCurrencyExchange(currency);
  };

  handleCurrencyExchange = (currency: string) => {
    const { rates, expense } = this.state;
    if (this.props.org.settings.currency !== currency && expense.amount != null && rates != null) {
      const exchangeRate = rates[currency] / rates[this.props.org.settings.currency];
      const exchangedAmount: number = Math.round(exchangeRate * expense.amount);
      this.setState({
        actualAmount: exchangedAmount,
        rate: exchangeRate,
      });
    } else {
      this.setState({
        actualAmount: expense.amount,
        rate: 1,
      });
    }
  };

  handleAttachmentAdd = (attachment: CircaFile) => {
    const temporaryId = String(Math.random());
    this.setState(previousState => ({
      expense: {
        ...previousState.expense,
        attachments: [...previousState.expense.attachments, { id: temporaryId, ...attachment }],
      },
    }));
  };

  handleAttachmentRemove = (attachmentId: ?string) => {
    this.setState(previousState => ({
      expense: {
        ...previousState.expense,
        attachments: previousState.expense.attachments.filter(a => a.id !== attachmentId),
      },
    }));
  };

  handleBudgetCategoryChange = (budgetCategoryId: ?string) => {
    const {
      org: {
        budgetCategories,
        settings: { fiscalYearStart },
      },
    } = this.props;

    if (budgetCategoryId === 'manage') {
      window.open(`/settings/budgets/${getBudgetQuarterString(fiscalYearStart)}`);

      return;
    }

    const selectedCategory = budgetCategories.edges.find(
      ({ node }) => node.id === budgetCategoryId,
    );
    const previousSelectedCategory = budgetCategories.edges.find(
      ({ node }) => node.id === this.state.expense.budgetCategoryId,
    );

    this.setState(previousState => ({
      expense: {
        ...previousState.expense,
        budgetCategoryId,
        name:
          selectedCategory &&
          ((previousSelectedCategory != null &&
            previousSelectedCategory.node.name === previousState.expense.name) ||
            !previousState.expense.name)
            ? selectedCategory.node.name
            : previousState.expense.name,
      },
      errors: previousState.errors.filter(error => error !== 'name'),
    }));
  };

  handleVendorChange = (vendorId: ?string) => {
    this.setState(previousState => ({
      expense: {
        ...previousState.expense,
        vendorId,
      },
    }));
  };

  handleEventChange = (eventId: ?string) => {
    if (this.props.onEventSelect) {
      this.props.onEventSelect(eventId);
    }
    this.setState(previousState => ({
      expense: {
        ...previousState.expense,
        eventId,
      },
    }));
    if (this.state.errors.includes('event')) {
      this.setState(prevState => ({
        errors: prevState.errors.filter(error => error !== 'event'),
      }));
    }
  };

  handleExpenseCreate = () => {
    if (this.state.loading) {
      return;
    }

    const {
      expense: { name, amount, budgetCategoryId, vendorId, attachments, teamAllocations, eventId },
    } = this.state;

    const expense = {
      name,
      actualAmount: amount != null ? amount : null,
      categoryId: budgetCategoryId,
      vendorId: this.props.vendorId || vendorId,
      deliverableId: this.props.taskId || null,
      attachments: attachments && attachments.map(attachment => omit(attachment, ['id'])),
      teamAllocations: teamAllocations.map(t => ({ id: t.id, percent: t.percent })),
    };

    const errors = [];
    if (!expense.name.trim()) {
      errors.push('name');
    }
    if (amount == null) {
      errors.push('cost');
    }
    if (this.props.vendorId && eventId == null) {
      errors.push('event');
    }

    this.setState({ errors });

    const formWindow = this.props.vendorId ? 'vendor_tab' : 'budget_tab';

    if (errors.length === 0) {
      this.setState({ loading: true });
      createExpense(eventId || this.props.event.id, expense, formWindow)
        .then(() => {
          this.setState({ loading: false });
          this.props.onCloseButtonClick();
        })
        .catch(err => {
          this.setState({ loading: false });
          showModernMutationError(err);
        });
    }
  };

  handleFilesDrop = files => {
    files.forEach(nativeFile => {
      const temporaryId = String(Math.random());
      const convertedFile = convertNativeFile(nativeFile);

      this.setState(previousState => ({
        expense: {
          ...previousState.expense,
          attachments: [
            ...previousState.expense.attachments,
            { id: temporaryId, ...convertedFile },
          ],
        },
      }));
      upload(nativeFile).then((file: CircaFile) => {
        this.setState(state => {
          const attachments = [...state.expense.attachments];
          const attachmentIndex = attachments.findIndex(
            attachment => attachment.id === temporaryId,
          );
          attachments.splice(attachmentIndex, 1, { id: temporaryId, ...file });
          return { expense: { ...state.expense, attachments } };
        });
      });
    });
  };

  handleSetTeamAllocations = (teamAllocations: $ReadOnlyArray<TeamAllocationType>) => {
    this.setState(prevState => ({ expense: { ...prevState.expense, teamAllocations } }));
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.handleExpenseCreate();
    }
  };

  render() {
    const { expense, rates, errors, currency, actualAmount, rate } = this.state;
    const { org, event, popup } = this.props;
    const FormComponent = popup ? PopupFormFields : InlineFormFields;
    return (
      <FormComponent
        onBudgetCategoryChange={this.handleBudgetCategoryChange}
        onNameChange={this.handleNameChange}
        onAmountChange={this.handleAmountChange}
        onCurrencyChange={this.handleCurrencyChange}
        onVendorChange={this.handleVendorChange}
        onExpenseCreate={this.handleExpenseCreate}
        onAddAttachment={this.handleAttachmentAdd}
        onRemoveAttachment={this.handleAttachmentRemove}
        onCloseButtonClick={this.props.onCloseButtonClick}
        onKeyDown={this.handleKeyDown}
        onFilesDrop={this.handleFilesDrop}
        onSetTeamAllocations={this.handleSetTeamAllocations}
        currentTeamId={event ? event.team.id : ''}
        actualAmount={actualAmount}
        currency={currency}
        expense={expense}
        errors={errors}
        event={event}
        rates={rates}
        org={org}
        rate={rate}
        vendorId={this.props.vendorId}
        onEventSelect={this.handleEventChange}
      />
    );
  }
}
export default createFragmentContainer(AddExpenseForm, {
  org: graphql`
    fragment AddExpenseForm_org on Org {
      ...PopupFormFields_org
      ...InlineFormFields_org
      settings {
        currency
        fiscalYearStart
      }
      budgetCategories {
        edges {
          node {
            name
            description
            id
          }
        }
      }
    }
  `,
  event: graphql`
    fragment AddExpenseForm_event on Event {
      ...PopupFormFields_event
      ...InlineFormFields_event
      id
      team {
        id
        name
        viewerCanUpdate
      }
    }
  `,
});
