/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatPercentage from 'utils/number/formatPercentage';

import { type CategoryFooterCellPropsType } from './index';

import { type CategoryPaidOfActualFooterCell_event } from './__generated__/CategoryPaidOfActualFooterCell_event.graphql';

const CategoryPaidOfActualFooterCell = ({
  event,
}: CategoryFooterCellPropsType<CategoryPaidOfActualFooterCell_event>) =>
  formatPercentage(event.totalPaidAmount / event.totalActualAmount);

export default createFragmentContainer(
  CategoryPaidOfActualFooterCell,
  graphql`
    fragment CategoryPaidOfActualFooterCell_event on Event {
      totalActualAmount
      totalPaidAmount
    }
  `,
);
