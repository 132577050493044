/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { OpportunityName_opportunity } from './__generated__/OpportunityName_opportunity.graphql';

class OpportunityName extends React.Component<{
  opportunity: OpportunityName_opportunity,
}> {
  render() {
    return this.props.opportunity.name;
  }
}

export default createFragmentContainer(
  OpportunityName,
  graphql`
    fragment OpportunityName_opportunity on SalesforceOpportunityType {
      name
    }
  `,
);
