/* @flow */

import React from 'react';
import { type Location, type Match, NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: stretch;
  margin: 15px 0 42px;

  @media (${props => props.theme.mobileOnly}) {
    margin: 15px 0 20px;
  }
`;

const NavigationItem = styled.div`
  width: 100%;
  max-width: 170px;
  margin-right: 22px;

  &:last-of-type {
    margin-right: 0;
  }

  a {
    display: block;
    height: 100%;
    padding: 16px 22px 10px 22px;
    border-radius: 5px;
    border: 1px solid #6b7cfe;
    background-image: #fff;
    color: #6b7cfe;
  }
`;

const Count = styled.div`
  line-height: 16px;
  font-size: 20px;

  @media (max-width: 400px) {
    font-size: 17px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

type optionType = {
  to: string,
  count: number,
  exact?: boolean,
  label: string,
  labelPlural: string,
  isActive?: (match: Match, location: Location) => boolean,
};

export default class TabNavigation extends React.PureComponent<{
  options: $ReadOnlyArray<optionType>,
}> {
  render() {
    return (
      <Container>
        {this.props.options.map(option => {
          const { to, exact, count, label, labelPlural, isActive } = option;
          return (
            <NavigationItem key={to}>
              <NavLink
                to={to}
                exact={exact}
                isActive={isActive}
                activeStyle={{
                  border: '0px solid #6b7cfe',
                  backgroundImage: 'linear-gradient(to bottom, #5e7bfe 5%, #ae80fc 134%)',
                  color: '#fff',
                }}
              >
                <Count>{count}</Count>
                <Label>{count === 1 ? label : labelPlural}</Label>
              </NavLink>
            </NavigationItem>
          );
        })}
      </Container>
    );
  }
}
