/**
 * @flow
 * @relayHash e20015c26b0b7537980ef6e5af8cd033
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateLocationInput = {
  clientMutationId?: ?string,
  eventId: string,
  name: string,
  city: string,
  state?: ?string,
  address1?: ?string,
  address2?: ?string,
  country?: ?string,
  postal?: ?string,
  note?: ?string,
  website?: ?string,
  lat?: ?number,
  lng?: ?number,
};
export type createLocationMutationVariables = {|
  input: CreateLocationInput
|};
export type createLocationMutationResponse = {|
  +createLocation: ?{|
    +locationEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +dbId: number,
        +name: string,
        +state: ?string,
        +city: string,
        +country: ?string,
        +postal: ?string,
        +note: ?string,
        +website: ?string,
        +address1: ?string,
        +address2: ?string,
        +lat: number,
        +lng: number,
        +tz: string,
        +event: {|
          +dbId: number,
          +name: string,
        |},
      |},
    |}
  |}
|};
export type createLocationMutation = {|
  variables: createLocationMutationVariables,
  response: createLocationMutationResponse,
|};
*/


/*
mutation createLocationMutation(
  $input: CreateLocationInput!
) {
  createLocation(input: $input) {
    locationEdge {
      __typename
      node {
        id
        dbId
        name
        state
        city
        country
        postal
        note
        website
        address1
        address2
        lat
        lng
        tz
        event {
          dbId
          name
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateLocationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateLocationInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "country",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "postal",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "note",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "website",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "address1",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "address2",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lat",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lng",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tz",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createLocationMutation",
  "id": null,
  "text": "mutation createLocationMutation(\n  $input: CreateLocationInput!\n) {\n  createLocation(input: $input) {\n    locationEdge {\n      __typename\n      node {\n        id\n        dbId\n        name\n        state\n        city\n        country\n        postal\n        note\n        website\n        address1\n        address2\n        lat\n        lng\n        tz\n        event {\n          dbId\n          name\n          id\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createLocationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLocation",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateLocationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "locationEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "LocationRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Location",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "event",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      v4,
                      v5
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createLocationMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createLocation",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateLocationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "locationEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "LocationRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Location",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "event",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v3
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1fa34f7da966d993f0dadc0ab48862b7';
module.exports = node;
