/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const LoadingOverlay = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultButton = styled.button`
  position: relative;
  padding: ${props => (props.iconOnly ? '5px 7px' : '5px 22px')};
  border-radius: 4px;
  line-height: 1.6;
  background: ${props => props.theme.secondaryActionColor};
  color: ${props => props.theme.secondaryActionTextColor};
  cursor: pointer;
  outline: none;
  &:hover,
  &:focus {
    background: ${props => props.theme.secondaryActionDarkerColor};
  }
  &:disabled {
    background: ${props => props.theme.disabledActionColor};
    cursor: initial;
    &:hover,
    &:focus {
      background: ${props => props.theme.disabledActionColor};
    }
  }
  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
  ${props =>
    !props.iconOnly &&
    css`
      i {
        margin: 0 5px;
      }
    `};
  ${props =>
    props.loading &&
    css`
      > *:not(${LoadingOverlay}) {
        opacity: 0;
      }
    `};
`;

const PrimaryButton = styled(DefaultButton)`
  color: #ffffff;
  background: #685bc7;
  &:hover,
  &:focus {
    background: #5247a3;
  }
`;

const HighlightButton = styled(DefaultButton)`
  color: ${props => props.theme.highlightActionTextColor};
  background: ${props => props.theme.highlightActionColor};
  &:hover,
  &:focus {
    background: ${props => props.theme.highlightActionDarkerColor};
  }
`;

const NegativeButton = styled(DefaultButton)`
  color: ${props => props.theme.negativeActionTextColor};
  background: ${props => props.theme.negativeActionColor};
  &:hover,
  &:focus {
    background: ${props => props.theme.negativeActionDarkerColor};
  }
`;

const OutlineButton = styled(DefaultButton)`
  padding: ${props => (props.iconOnly ? '4px 7px' : '4px 22px')};
  background: transparent;
  color: ${props => props.theme.outlineButtonColor};
  border: 1px solid currentColor;
  &:hover,
  &:focus {
    color: ${props => props.theme.outlineButtonFocusColor};
    background: transparent;
  }
  &:disabled {
    background: transparent;
    color: ${props => props.theme.disabledActionColor};
    &:hover,
    &:focus {
      background: transparent;
      color: ${props => props.theme.disabledActionColor};
    }
  }
`;

const PrimaryOutlineButton = styled(OutlineButton)`
  color: #685bc7;
  &:hover,
  &:focus {
    color: #5247a3;
  }
`;

const NegativeOutlineButton = styled(OutlineButton)`
  color: ${props => props.theme.negativeActionColor};
  &:hover,
  &:focus {
    color: ${props => props.theme.negativeActionDarkerColor};
  }
`;

const MinimalButton = styled(DefaultButton)`
  background: transparent;
  color: ${props => props.theme.secondaryActionDarkerColor};
  padding: 4px 0;
  &:hover,
  &:focus {
    background: transparent;
    text-decoration: underline;
  }
  &:active {
    box-shadow: unset;
    color: ${props => props.theme.secondaryActionDarkestColor};
  }
`;

const MinimalPrimaryButton = styled(MinimalButton)`
  color: ${props => props.theme.primaryActionColor};
  &:active {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

export default class Button extends React.Component<{
  label?: string,
  icon?: string,
  iconPlacement: 'before' | 'after',
  primary?: boolean,
  negative?: boolean,
  highlight?: boolean,
  disabled?: boolean,
  outline?: boolean,
  minimal?: boolean,
  loading?: boolean,
  className?: string,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  type: string,
  innerRef?: (?HTMLButtonElement) => void,
  children?: React.Node,
}> {
  static defaultProps = {
    type: 'button',
    iconPlacement: 'before',
  };

  handleClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (this.props.onClick) {
      if (!this.props.loading) {
        this.props.onClick(e);
      }
      e.currentTarget.blur();
    }
  };

  render() {
    let Component;

    if (this.props.outline) {
      if (this.props.primary) Component = PrimaryOutlineButton;
      else if (this.props.negative) Component = NegativeOutlineButton;
      else Component = OutlineButton;
    } else if (this.props.minimal) {
      if (this.props.primary) Component = MinimalPrimaryButton;
      else Component = MinimalButton;
    } else if (this.props.primary) Component = PrimaryButton;
    else if (this.props.negative) Component = NegativeButton;
    else if (this.props.highlight) Component = HighlightButton;
    else Component = DefaultButton;

    return (
      <Component
        iconOnly={!this.props.label}
        loading={this.props.loading ? 'true' : null}
        className={this.props.className}
        disabled={this.props.disabled}
        onClick={this.handleClick}
        type={this.props.type}
        ref={this.props.innerRef}
      >
        {this.props.icon && this.props.iconPlacement === 'before' && (
          <i className={`fa fa-fw fa-${this.props.icon}`} />
        )}
        <span>{this.props.label}</span>
        {this.props.icon && this.props.iconPlacement === 'after' && (
          <i className={`fa fa-fw fa-${this.props.icon}`} />
        )}
        <span>{this.props.children}</span>
        {this.props.loading && (
          <LoadingOverlay>
            <i className="fa fa-circle-o-notch fa-spin" />
          </LoadingOverlay>
        )}
      </Component>
    );
  }
}
