/* @flow */
import React from 'react';
import styled from 'styled-components';

const RadioIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  border: solid 1px currentColor;
  color: ${props => props.color || props.theme.secondaryActionDarkerColor};
`;

const RadioPoint = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: currentColor;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  ${props => (props.disabled ? 'cursor: not-allowed;' : 'cursor: pointer;')} &:hover {
    ${RadioIcon} {
      box-shadow: 0 0 0 1px currentColor;
    }
  }
`;

const Label = styled.div`
  margin-left: 7px;
`;

export default class Radio<Value> extends React.Component<{
  className?: string,
  label?: ?string,
  value: Value,
  checked: boolean,
  disabled?: boolean,
  onChange: (Value, e: SyntheticMouseEvent<HTMLInputElement>) => void,
  radioColor?: ?string,
}> {
  handleChange = (e: SyntheticMouseEvent<HTMLInputElement>) => {
    if (!this.props.disabled) {
      this.props.onChange(this.props.value, e);
    }
  };

  render() {
    return (
      <Container
        className={this.props.className}
        onClick={this.handleChange}
        disabled={this.props.disabled}
      >
        <RadioIcon color={this.props.radioColor}>{this.props.checked && <RadioPoint />}</RadioIcon>
        {this.props.label && <Label>{this.props.label}</Label>}
      </Container>
    );
  }
}
