/* @flow */
import React from 'react';
import moment from 'moment-timezone';

import formatDateTimeISO, { dateFormat, dateTimeFormat } from 'utils/date/formatDateTimeISO';

import TextField from 'components/material/TextField';

type DateConfig = {| date: ?string, hideTime: boolean |};

const formatInputDateTime = (tz: string, { date, hideTime }: DateConfig) =>
  date ? formatDateTimeISO(moment(date).tz(tz), hideTime) : '';

export default class DateTimeInput extends React.PureComponent<
  {
    tz: string,
    onFocus?: (e: SyntheticEvent<HTMLInputElement>) => void,
    onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
    onKeyDown?: (e: SyntheticKeyboardEvent<HTMLInputElement>) => void,
    onChange: DateConfig => void,
    ...DateConfig,
    label?: string,
    placeholder?: string,
    dateOnly?: boolean,
    disabled?: boolean,
    autoFocus?: boolean,
    required?: boolean,
    error?: ?string | ?boolean,
    inputRef?: $Call<React.createRef<any>>,
  },
  {
    value: string,
    focused: boolean,
  },
> {
  static getDerivedStateFromProps(
    props: $PropertyType<DateTimeInput, 'props'>,
    state: $PropertyType<DateTimeInput, 'state'>,
  ) {
    if (state.focused) return null;

    return {
      value: formatInputDateTime(props.tz, {
        date: props.date,
        hideTime: props.hideTime || props.dateOnly || false,
      }),
    };
  }

  state = {
    value: formatInputDateTime(this.props.tz, {
      date: this.props.date,
      hideTime: this.props.hideTime || this.props.dateOnly || false,
    }),
    focused: false,
  };

  parseDateTime = (value: string): DateConfig => {
    let parsedDate = moment.tz(
      value,
      this.props.dateOnly ? dateFormat : dateTimeFormat,
      true,
      this.props.tz,
    );
    let hideTime = !!this.props.dateOnly;

    if (!this.props.dateOnly && !parsedDate.isValid()) {
      parsedDate = moment.tz(value, dateFormat, true, this.props.tz);
      hideTime = true;
    }

    return {
      date: parsedDate && parsedDate.isValid() ? parsedDate.format() : null,
      hideTime,
    };
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    this.setState({ value });

    const { date, hideTime } = this.parseDateTime(value);

    if ((date || !value.trim()) && this.state.focused) {
      this.props.onChange({ date, hideTime });
    }
  };

  handleFocus = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.props.onFocus) this.props.onFocus(e);

    this.setState({ focused: true });
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.props.onBlur) this.props.onBlur(e);

    this.setState({ focused: false });
  };

  render() {
    return (
      <TextField
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onKeyDown={this.props.onKeyDown}
        value={this.state.value}
        label={this.props.label}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        autoFocus={this.props.autoFocus}
        inputRef={this.props.inputRef}
        required={this.props.required}
        error={this.props.error}
      />
    );
  }
}
