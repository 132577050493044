/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Search from 'components/Search';

import VendorContainer from './VendorsContainer';

import { type BriefVendors_event } from './__generated__/BriefVendors_event.graphql';
import { type BriefVendorsQueryResponse } from './__generated__/BriefVendorsQuery.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const StyledSearch = styled(Search)`
  max-width: 100%;
  margin-bottom: 20px;
`;

const query = graphql`
  query BriefVendorsQuery($eventSlug: String!, $filters: VendorFilters!) {
    event(slug: $eventSlug) {
      ...VendorsContainer_event
    }
  }
`;

class BriefVendors extends React.PureComponent<
  {
    event: BriefVendors_event,
    selectedVendors: $ReadOnlyArray<string>,
    containerRef: (el: VendorContainer) => void,
    onAddVendor: (vendorId: string) => void,
    onRemoveVendor: (vendorId: string) => void,
    onAllVendorCheckToggle: (
      isPrevAllCheck: boolean,
      searchedVendorIds: $ReadOnlyArray<string>,
    ) => void,
  },
  {
    searchQuery: string,
  },
> {
  state = { searchQuery: '' };

  handleVendorSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  handleVendorToggle = (vendorId: string, isPrevChecked: boolean) => {
    if (isPrevChecked) {
      this.props.onRemoveVendor(vendorId);
    } else {
      this.props.onAddVendor(vendorId);
    }
  };

  render() {
    const {
      event: { slug },
      selectedVendors,
      onAllVendorCheckToggle,
      containerRef,
    } = this.props;
    return (
      <Container>
        <StyledSearch
          onSearch={this.handleVendorSearch}
          search={this.state.searchQuery}
          placeholder="Search vendors"
        />
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug: slug, filters: { query: this.state.searchQuery } }}
          renderSuccess={(props: BriefVendorsQueryResponse) => {
            if (props.event) {
              return (
                <VendorContainer
                  componentRef={containerRef}
                  event={props.event}
                  selectedVendors={selectedVendors}
                  searchQuery={this.state.searchQuery}
                  onAllVendorCheckToggle={onAllVendorCheckToggle}
                  handleVendorToggle={this.handleVendorToggle}
                />
              );
            }
            return null;
          }}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  BriefVendors,
  graphql`
    fragment BriefVendors_event on Event {
      slug
      id
    }
  `,
);
