/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompanyUpdatedAt_company$ref: FragmentReference;
export type CompanyUpdatedAt_company = {|
  +updatedAt: any,
  +$refType: CompanyUpdatedAt_company$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CompanyUpdatedAt_company",
  "type": "Company",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedAt",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '895c1d41999b2f9d453068b56eac50ba';
module.exports = node;
