/* @flow */

export type SubmissionStatus =
  | 'NEW'
  | 'RETURNED'
  | 'RESUBMITTED'
  | 'APPROVED'
  | 'DECLINED'
  | 'ARCHIVED';

type SubmissionStatusObject = {
  label: string,
  value: SubmissionStatus,
  color: string,
  auto: boolean,
  notify: boolean,
  actionLabel?: string,
};

const submissionStatuses: $ReadOnlyArray<SubmissionStatusObject> = [
  { label: 'New', value: 'NEW', color: '#5288f8', auto: true, notify: true },
  {
    label: 'Returned',
    actionLabel: 'Return',
    value: 'RETURNED',
    color: '#fba839',
    auto: false,
    notify: false,
  },
  { label: 'Resubmitted', value: 'RESUBMITTED', color: '#44b8f7', auto: true, notify: true },
  {
    label: 'Approved',
    actionLabel: 'Approve',
    value: 'APPROVED',
    color: '#29cc71',
    auto: false,
    notify: false,
  },
  {
    label: 'Declined',
    actionLabel: 'Decline',
    value: 'DECLINED',
    color: '#e85c5c',
    auto: false,
    notify: false,
  },
  {
    label: 'Archived',
    actionLabel: 'Archive',
    value: 'ARCHIVED',
    color: '#8d8d8d',
    auto: false,
    notify: false,
  },
];

export default submissionStatuses;
