/**
 * @flow
 * @relayHash 7d6a0597cf89ad22b49dafc6f2962f9f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EditableQuestion_org$ref = any;
export type EditableQuestionRefetchQueryVariables = {||};
export type EditableQuestionRefetchQueryResponse = {|
  +org: {|
    +$fragmentRefs: EditableQuestion_org$ref
  |}
|};
export type EditableQuestionRefetchQuery = {|
  variables: EditableQuestionRefetchQueryVariables,
  response: EditableQuestionRefetchQueryResponse,
|};
*/


/*
query EditableQuestionRefetchQuery {
  org {
    ...EditableQuestion_org
    id
  }
}

fragment EditableQuestion_org on Org {
  customFields(customizableType: [EVENT]) {
    edges {
      node {
        id
        label
        order
        kind
        section {
          order
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditableQuestionRefetchQuery",
  "id": null,
  "text": "query EditableQuestionRefetchQuery {\n  org {\n    ...EditableQuestion_org\n    id\n  }\n}\n\nfragment EditableQuestion_org on Org {\n  customFields(customizableType: [EVENT]) {\n    edges {\n      node {\n        id\n        label\n        order\n        kind\n        section {\n          order\n          id\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditableQuestionRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EditableQuestion_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditableQuestionRefetchQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"EVENT\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "customizableType",
                "value": [
                  "EVENT"
                ],
                "type": "[CustomizableType!]!"
              }
            ],
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      },
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "kind",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "section",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldSectionType",
                        "plural": false,
                        "selections": [
                          v1,
                          v0
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2c8e04561165d8e7afd0ae8a376dae3d';
module.exports = node;
