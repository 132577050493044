/* @flow */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import fileUploadDefaults from 'config/fileUploadDefaults';

import getReadableFileSize from 'utils/file/getReadableFileSize';
import { upload } from 'utils/Attachments';
import pickFiles from 'utils/uploading/pickFiles';
import showErrorPopup from 'utils/showErrorPopup';

import { DropZone } from 'components/DropZone';

import type { QuestionType, QuestionValueType, QuestionValueInputType } from '../lib/types';
import questionFileExtensions from '../lib/questionFileExtensions';
import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';

const DropContainer = styled.div`
  margin-top: 10px;
  padding: 31px 55px;
  border-radius: 4px;
  border: dashed 1px rgb(96, 185, 225);
  background: #fff;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #42aad8;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const SupportText = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
  color: rgba(74, 86, 101, 0.54);
`;

const RemoveIcon = styled.i`
  visibility: visible;
  margin-top: -15px;
  margin-right: -10px;
  transform: rotate(45deg);
  font-family: initial;
  font-size: 32px;
  color: #cdd1d4;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const FileItem = styled.div`
  margin-top: 9px;
  padding: 9px 23px;
  border-radius: 4px;
  background-color: rgb(241, 249, 252);
  &:hover {
    ${RemoveIcon} {
      visibility: visible;
    }
  }
`;

const FileName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const FileSize = styled.div`
  font-size: 13px;
  color: #9ea4ac;
`;

const UploadingIcon = styled.i`
  color: ${props => props.theme.mutedTextColor};
`;

export default class QuestionFile extends React.PureComponent<
  {
    question: QuestionType,
    value: QuestionValueType,
    onChangeValue: QuestionValueInputType => void,
    readOnly: boolean,
    error: ?string,
  },
  {
    uploading: boolean,
  },
> {
  state = {
    uploading: false,
  };

  getExts = () => {
    const hasExcel = this.props.question.fileExtensions.includes('XLSX');
    return hasExcel
      ? [...this.props.question.fileExtensions, 'XLS']
      : this.props.question.fileExtensions;
  };

  getMimeTypes = () => {
    const hasExcel = this.props.question.fileExtensions.includes('XLSX');
    return hasExcel
      ? [
          ...this.props.question.fileExtensions.map(ext => questionFileExtensions[ext]),
          'application/vnd.ms-excel',
          'application/excel',
          'application/x-excel',
          'application/x-msexcel',
        ]
      : this.props.question.fileExtensions.map(ext => questionFileExtensions[ext]);
  };

  handleFilesDrop = (files: Array<File>) => {
    const filteredFiles = files.filter(
      file => !this.getExts().includes(file.name ? file.name.split('.')[1] : ''),
    );

    if (filteredFiles.length === 0) return;

    this.setState({ uploading: true });

    Promise.all(
      filteredFiles.map(nativeFile => {
        const temporaryId = String(Math.random());

        return upload(nativeFile)
          .then(file => {
            const fileWithId = {
              id: temporaryId,
              filename: file.filename,
              filetype: file.filetype,
              fileurl: file.fileurl,
              filesize: file.filesize,
            };

            this.props.onChangeValue({ filesValue: [...this.props.value.filesValue, fileWithId] });
          })
          .catch(() => {
            this.props.onChangeValue({
              filesValue: this.props.value.filesValue.filter(file => file.id !== temporaryId),
            });
          });
      }),
    ).then(() => {
      this.setState({ uploading: false });
    });
  };

  handleUploadFiles = (e: SyntheticEvent<HTMLLinkElement>) => {
    e.preventDefault();

    if (this.props.readOnly) return;

    const options = {
      fromSources: [...fileUploadDefaults.fromSources, 'url'],
      accept: this.getMimeTypes(),
    };

    pickFiles(options)
      .then(files => {
        const filesWithIds = files.map(({ viewerCanDelete, ...file }) => ({
          id: String(Math.random()),
          ...file,
        }));

        this.props.onChangeValue({
          filesValue: [...this.props.value.filesValue, ...filesWithIds],
        });
      })
      .catch(showErrorPopup);
  };

  handleRemove = (id?: string) => {
    this.props.onChangeValue({
      filesValue: this.props.value.filesValue.filter(file => file.id !== id),
    });
  };

  render() {
    return (
      <div>
        <>
          {this.props.question.description && (
            <HelperText>{this.props.question.description}</HelperText>
          )}
          <DropZone noPreview onDrop={this.handleFilesDrop} disabled={this.props.readOnly}>
            <DropContainer>
              Drag and drop files here to upload or{' '}
              <StyledLink to="" onClick={this.handleUploadFiles}>
                Choose a file
              </StyledLink>
            </DropContainer>
          </DropZone>
          <SupportText>
            We support{' '}
            {this.props.question.fileExtensions
              .map(ext => (ext === 'XLSX' ? 'excel' : `.${ext}`))
              .join(', ')}{' '}
            file formats.
          </SupportText>
          {this.props.error && <ErrorMessage>{this.props.error}</ErrorMessage>}
          {this.state.uploading && <UploadingIcon className="fa fa-fw fa-circle-o-notch fa-spin" />}
          {this.props.value.filesValue.map(file => (
            <FileItem key={file.id || file.filename}>
              <FileRow>
                <FileName>{file.filename}</FileName>
                <RemoveIcon onClick={() => this.handleRemove(file.id)}>+</RemoveIcon>
              </FileRow>
              <FileSize>{getReadableFileSize(file.filesize || 0)}</FileSize>
            </FileItem>
          ))}
        </>
      </div>
    );
  }
}
