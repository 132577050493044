/* @flow */
import React from 'react';
import { type RelayRefetchProp, createRefetchContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';

import EditIcon from 'images/edit.svg';

import type { RegistrationFormTemplate_query } from './__generated__/RegistrationFormTemplate_query.graphql';

const StyledEditIcon = styled(EditIcon)`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 32px;
`;

const Container = styled.div`
  position: relative;
  max-width: 560px;
  padding: 28px 32px;
  border: solid 1px #dadada;
  border-radius: 4px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    background-color: #f2fbff;
    border: solid 1px #c7eafa;
    ${StyledEditIcon} {
      display: block;
    }
  }
`;

const Name = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  color: #313131;
`;

const UpdateDate = styled.div`
  font-size: 12px;
  line-height: 1em;
  color: #4a5665;
`;

class RegistrationFormTemplate extends React.PureComponent<{
  query: RegistrationFormTemplate_query,
  relay: RelayRefetchProp,
}> {
  builderWindow: any = null;

  documentFocus = throttle(() => this.props.relay.refetch({}, { force: true }), 1000, {
    trailing: false,
  });

  handleNavigateToFormBuilder = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    this.builderWindow = window.open(`/registration_builder`, '_blank');
    this.builderWindow.onbeforeunload = this.removeTabChangeListener;
    document.addEventListener('visibilitychange', this.documentFocus);
  };

  removeTabChangeListener = () => {
    this.documentFocus();
    document.removeEventListener('visibilitychange', this.documentFocus);
  };

  render() {
    const {
      registrationForm: { updatedAt, eventName },
      settings: { tz },
    } = this.props.query.org;
    return (
      <Container onClick={this.handleNavigateToFormBuilder}>
        <Name>{eventName}</Name>
        <UpdateDate>{`Edited ${formatDateTime(moment.tz(updatedAt, tz))}`}</UpdateDate>
        <StyledEditIcon />
      </Container>
    );
  }
}

export default createRefetchContainer(
  RegistrationFormTemplate,
  graphql`
    fragment RegistrationFormTemplate_query on Query {
      org {
        id
        registrationForm {
          eventName
          updatedAt
        }
        settings {
          tz
        }
      }
    }
  `,
  graphql`
    query RegistrationFormTemplateRefetchQuery {
      query {
        ...RegistrationFormTemplate_query
      }
    }
  `,
);
