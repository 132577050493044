/**
 * @flow
 * @relayHash 1e8c36a415f821134e07912e1735466b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventForm_me$ref = any;
type EventForm_org$ref = any;
export type EventFormQueryVariables = {||};
export type EventFormQueryResponse = {|
  +me: {|
    +$fragmentRefs: EventForm_me$ref
  |},
  +org: {|
    +$fragmentRefs: EventForm_org$ref
  |},
|};
export type EventFormQuery = {|
  variables: EventFormQueryVariables,
  response: EventFormQueryResponse,
|};
*/


/*
query EventFormQuery {
  me {
    ...EventForm_me
    id
  }
  org {
    ...EventForm_org
    id
  }
}

fragment EventForm_me on User {
  id
  tz
  firstName
  teams {
    edges {
      node {
        id
        dbId
        name
        viewerCanCreateEvents
      }
    }
  }
}

fragment EventForm_org on Org {
  settings {
    tz
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tz",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventFormQuery",
  "id": null,
  "text": "query EventFormQuery {\n  me {\n    ...EventForm_me\n    id\n  }\n  org {\n    ...EventForm_org\n    id\n  }\n}\n\nfragment EventForm_me on User {\n  id\n  tz\n  firstName\n  teams {\n    edges {\n      node {\n        id\n        dbId\n        name\n        viewerCanCreateEvents\n      }\n    }\n  }\n}\n\nfragment EventForm_org on Org {\n  settings {\n    tz\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EventForm_me",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EventForm_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventFormQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teams",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dbId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanCreateEvents",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              v1,
              v0
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '20ea65c35826fbe6aa6c45a60be59c07';
module.exports = node;
