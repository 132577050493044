/* @flow */

import VendorCity from './VendorCity';
import VendorCompanyPhone from './VendorCompanyPhone';
import VendorCountry from './VendorCountry';
import VendorCreatedAt from './VendorCreatedAt';
import VendorCreator from './VendorCreator';
import VendorDescription from './VendorDescription';
import VendorEmail from './VendorEmail';
import VendorFirstName from './VendorFirstName';
import VendorLastName from './VendorLastName';
import VendorLinkedin from './VendorLinkedin';
import VendorMobilePhone from './VendorMobilePhone';
import VendorName from './VendorName';
import VendorOfficePhone from './VendorOfficePhone';
import VendorState from './VendorState';
import VendorStreet from './VendorStreet';
import VendorTitle from './VendorTitle';
import VendorTwitter from './VendorTwitter';
import VendorUpdatedAt from './VendorUpdatedAt';
import VendorUpdater from './VendorUpdater';
import VendorWebsite from './VendorWebsite';
import VendorZip from './VendorZip';

const defaultTableComponents = {
  name: VendorName,
  website: VendorWebsite,
  company_phone: VendorCompanyPhone,
  street: VendorStreet,
  zip: VendorZip,
  city: VendorCity,
  country: VendorCountry,
  state: VendorState,
  twitter: VendorTwitter,
  linkedin: VendorLinkedin,
  description: VendorDescription,
  first_name: VendorFirstName,
  last_name: VendorLastName,
  title: VendorTitle,
  phone1: VendorOfficePhone,
  phone2: VendorMobilePhone,
  email: VendorEmail,
  updated_at: VendorUpdatedAt,
  updated_by: VendorUpdater,
  created_at: VendorCreatedAt,
  created_by: VendorCreator,
};

export {
  defaultTableComponents,
  VendorCity,
  VendorCompanyPhone,
  VendorCountry,
  VendorCreatedAt,
  VendorCreator,
  VendorDescription,
  VendorEmail,
  VendorFirstName,
  VendorLastName,
  VendorLinkedin,
  VendorMobilePhone,
  VendorName,
  VendorOfficePhone,
  VendorState,
  VendorStreet,
  VendorTitle,
  VendorTwitter,
  VendorUpdatedAt,
  VendorUpdater,
  VendorWebsite,
  VendorZip,
};
