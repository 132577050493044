/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextField from 'components/material/TextField';

import type { QuestionType } from '../lib/types';

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  > :first-child {
    margin-right: 25px;
  }
`;

export default class EditableQuestionTextareaOptions extends React.PureComponent<{
  question: QuestionType,
  onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
}> {
  handleChangeMin = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = +e.currentTarget.value;

    if (value === this.props.question.textMinLength || value > Number.MAX_SAFE_INTEGER) return;

    if (Number.isInteger(value)) {
      this.props.onChangeQuestion({ textMinLength: value > 0 ? value : null });
    }
  };

  handleChangeMax = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = +e.currentTarget.value;

    if (value === this.props.question.textMaxLength || value > Number.MAX_SAFE_INTEGER) return;

    if (Number.isInteger(value)) {
      this.props.onChangeQuestion({ textMaxLength: value > 0 ? value : null });
    }
  };

  render() {
    const { question } = this.props;

    return (
      <Row>
        <TextField
          label="Min Characters"
          value={question.textMinLength == null ? '' : String(question.textMinLength)}
          onChange={this.handleChangeMin}
        />
        <TextField
          label="Max Characters"
          value={question.textMaxLength == null ? '' : String(question.textMaxLength)}
          onChange={this.handleChangeMax}
        />
      </Row>
    );
  }
}
