/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AllEventsViewActions_eventList$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsHeaderBar_eventList$ref: FragmentReference;
export type AllEventsHeaderBar_eventList = {|
  +name: string,
  +$fragmentRefs: AllEventsViewActions_eventList$ref,
  +$refType: AllEventsHeaderBar_eventList$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "AllEventsHeaderBar_eventList",
  "type": "EventList",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AllEventsViewActions_eventList",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3f645131dfb6ab801eea23066c4bad67';
module.exports = node;
