/* @flow */
import * as React from 'react';
import { type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import SortableHeader from 'components/material/SortableHeader';
import { type ColumnConfiguration } from 'components/material/table';

const TableHeader = styled.div`
  display: flex;
  position: relative;
  height: 40px;
  min-width: ${props => props.minWidth}px;
  border: 1px solid ${props => props.theme.primaryRowColor};
  border-left: none;
  border-right: none;
`;

const StyledSortableHeader = styled(SortableHeader)`
  flex: 1 1 auto;
  padding-right: 50px;
`;

export default class RequestsInboxTableHeader extends React.PureComponent<{
  sort: SortParam,
  history: RouterHistory,
  shownColumns: ColumnConfiguration,
  width: number,
}> {
  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  render() {
    return (
      <TableHeader minWidth={this.props.width}>
        <StyledSortableHeader
          light
          sort={this.props.sort}
          cols={this.props.shownColumns}
          onChange={this.handleSortChange}
        />
      </TableHeader>
    );
  }
}
