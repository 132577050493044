/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type DividerComponent_selectedPageComponent$ref = any;
type EmbedComponent_selectedPageComponent$ref = any;
type EventDatesComponent_selectedPageComponent$ref = any;
type EventNameComponent_selectedPageComponent$ref = any;
type ImageComponent_selectedPageComponent$ref = any;
type RowComponent_selectedPageComponent$ref = any;
type TextComponent_selectedPageComponent$ref = any;
type VideoComponent_selectedPageComponent$ref = any;
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationPageComponents_selectedPageComponent$ref: FragmentReference;
export type RegistrationPageComponents_selectedPageComponent = {|
  +id: string,
  +kind: RegistrationPageComponentKind,
  +$fragmentRefs: ImageComponent_selectedPageComponent$ref & EventNameComponent_selectedPageComponent$ref & EventDatesComponent_selectedPageComponent$ref & TextComponent_selectedPageComponent$ref & DividerComponent_selectedPageComponent$ref & EmbedComponent_selectedPageComponent$ref & VideoComponent_selectedPageComponent$ref & RowComponent_selectedPageComponent$ref,
  +$refType: RegistrationPageComponents_selectedPageComponent$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationPageComponents_selectedPageComponent",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "kind",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ImageComponent_selectedPageComponent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventNameComponent_selectedPageComponent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventDatesComponent_selectedPageComponent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TextComponent_selectedPageComponent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DividerComponent_selectedPageComponent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EmbedComponent_selectedPageComponent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VideoComponent_selectedPageComponent",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RowComponent_selectedPageComponent",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a442b7afeee4e74d6469b0f60af56178';
module.exports = node;
