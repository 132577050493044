/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Location, type Match, type RouterHistory } from 'react-router-dom';

import viewedBrief from 'utils/analytics/events/briefs/viewedBrief';
import parseTypedQueryString, { stringParamToString } from 'utils/routing/parseTypedQueryString';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';

import BriefView from '../Brief/BriefView';

import type { BriefSharedQueryResponse } from './__generated__/BriefSharedQuery.graphql';

const query = graphql`
  query BriefSharedQuery($eventSlug: String!, $listToken: String) {
    event(slug: $eventSlug, listToken: $listToken) {
      ...BriefView_event
      viewerCanViewBrief
      dbId
      name
      team {
        dbId
        name
      }
    }
  }
`;

export default class BriefShared extends React.Component<{
  match: Match,
  history: RouterHistory,
  location: Location,
}> {
  render() {
    const { listToken, eventSlug } = this.props.match.params;
    const variables = {
      eventSlug,
      ...(listToken ? { listToken } : {}),
    };

    return (
      <DefaultQueryRenderer
        query={query}
        public
        variables={variables}
        renderSuccess={({ event }: BriefSharedQueryResponse) => {
          const searchQuery = parseTypedQueryString(this.props.history.location.search, {
            fromWindow: stringParamToString,
          });

          if (event != null) {
            viewedBrief({
              eventId: event.dbId,
              eventName: event.name,
              teamId: event.team.dbId,
              teamName: event.team.name,
              fromWindow: searchQuery.fromWindow || 'brief',
            });
          }
          return event && event.viewerCanViewBrief ? (
            <BriefView isSharedBrief event={event} listToken={listToken} />
          ) : (
            <NotAuthorized />
          );
        }}
        renderError={() => <NotAuthorized />}
      />
    );
  }
}
