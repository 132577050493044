/**
 * @flow
 * @relayHash b9bab47b2cac2b0fb5ea3398155f7dab
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactSaveMethod = "api" | "g2w" | "import" | "manual" | "past_g2w" | "past_zoom" | "registration" | "zapier" | "zoom";
export type ContactSort = "ATTENDANCE_DURATION" | "ATTENDANCE_JOIN_TIME" | "ATTENDANCE_LEAVE_TIME" | "ATTENDANCE_STATUS_ID" | "CITY" | "COMPANY_ID" | "CONTACT_TYPE_ID" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CREATED_METHOD" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "EMAIL_OPT_IN" | "EVENTS" | "HOT_LEAD" | "LAST_SYNCED" | "LINKEDIN" | "MARKETO_ID" | "MEMBERSHIP_LAST_SYNCED" | "MEMBERSHIP_SYNC_STATUS" | "NAME" | "OWNER_ID" | "PHONE1" | "PHONE2" | "REGISTERED_AT" | "REGISTRATION_STATUS_ID" | "REQUEST_SUBMISSIONS" | "SALESFORCE_ID" | "SALESFORCE_LEAD_STATUS_ID" | "SALESFORCE_SYNC_AS" | "STATE" | "STREET" | "SYNC_STATUS" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "UPDATED_METHOD" | "WEBSITE" | "ZIP";
export type ContactSyncOption = "contact" | "lead";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type ContactFilters = {
  query?: ?string,
  search?: ?Search,
  contactIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  updatedMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactTypeIds?: ?$ReadOnlyArray<string>,
  ownerIds?: ?$ReadOnlyArray<string>,
  senderIds?: ?$ReadOnlyArray<string>,
  salesforceSyncOptions?: ?$ReadOnlyArray<ContactSyncOption>,
  leadStatusIds?: ?$ReadOnlyArray<string>,
  sentAt?: ?DateTimeRange,
  emailOptIn?: ?boolean,
  hotLead?: ?boolean,
  registeredAt?: ?DateTimeRange,
  attendanceStatusIds?: ?$ReadOnlyArray<string>,
  registrationStatusIds?: ?$ReadOnlyArray<string>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  membershipSyncStatuses?: ?$ReadOnlyArray<SyncState>,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?ContactSort,
  direction?: ?Direction,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type ContactsHeaderBarQueryVariables = {|
  eventSlug: string,
  filters: ContactFilters,
  columns?: ?$ReadOnlyArray<string>,
|};
export type ContactsHeaderBarQueryResponse = {|
  +event: ?{|
    +contactReportCSVProcessId: string
  |}
|};
export type ContactsHeaderBarQuery = {|
  variables: ContactsHeaderBarQueryVariables,
  response: ContactsHeaderBarQueryResponse,
|};
*/


/*
query ContactsHeaderBarQuery(
  $eventSlug: String!
  $filters: ContactFilters!
  $columns: [String!]
) {
  event(slug: $eventSlug) {
    contactReportCSVProcessId(filters: $filters, columns: $columns)
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "ContactFilters!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "columns",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactReportCSVProcessId",
  "args": [
    {
      "kind": "Variable",
      "name": "columns",
      "variableName": "columns",
      "type": "[String!]"
    },
    {
      "kind": "Variable",
      "name": "filters",
      "variableName": "filters",
      "type": "ContactFilters!"
    }
  ],
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ContactsHeaderBarQuery",
  "id": null,
  "text": "query ContactsHeaderBarQuery(\n  $eventSlug: String!\n  $filters: ContactFilters!\n  $columns: [String!]\n) {\n  event(slug: $eventSlug) {\n    contactReportCSVProcessId(filters: $filters, columns: $columns)\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ContactsHeaderBarQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContactsHeaderBarQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '037e482bebb81f3c03089dc37b23950c';
module.exports = node;
