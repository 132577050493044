/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RequestSubmissionEventName_requestSubmission$ref = any;
type RequestSubmissionFormName_requestSubmission$ref = any;
type RequestSubmissionFormReviewers_requestSubmission$ref = any;
type RequestSubmissionRequestedDate_requestSubmission$ref = any;
type RequestSubmissionStatus_requestSubmission$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RequestSubmissionList_requestSubmissions$ref: FragmentReference;
export type RequestSubmissionList_requestSubmissions = $ReadOnlyArray<{|
  +id: string,
  +$fragmentRefs: RequestSubmissionStatus_requestSubmission$ref & RequestSubmissionFormName_requestSubmission$ref & RequestSubmissionRequestedDate_requestSubmission$ref & RequestSubmissionEventName_requestSubmission$ref & RequestSubmissionFormReviewers_requestSubmission$ref,
  +$refType: RequestSubmissionList_requestSubmissions$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RequestSubmissionList_requestSubmissions",
  "type": "EventRequestSubmission",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RequestSubmissionStatus_requestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RequestSubmissionFormName_requestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RequestSubmissionRequestedDate_requestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RequestSubmissionEventName_requestSubmission",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RequestSubmissionFormReviewers_requestSubmission",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '27172b909b98916588456d0138339fae';
module.exports = node;
