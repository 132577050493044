/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Block = styled.div`
  position: relative;
  border-radius: 3px;
  background-color: ${props => props.theme.negativeActionColor};
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  font-size: 13px;
  padding: 10px 15px;
  margin-top: 10px;
  padding-right: 40px;
  margin-bottom: 30px;
  i {
    position: absolute;
    right: 15px;
    top: 10px;
    opacity: 0.7;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      opacity: 1;
    }
  }
  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  p {
    color: #fff;
  }
`;

export default class AuthErrorBlock extends React.PureComponent<{
  text?: {} | string,
  onDismiss: () => void,
  children?: React.Node,
}> {
  render() {
    return (
      <Block>
        {typeof this.props.text === 'object'
          ? Object.keys(this.props.text).map(key => {
              const value = this.props.text && this.props.text[key].toString();
              if (key !== 'message' && key !== 'error') {
                return `${key[0].toUpperCase()}${key.slice(1)} ${value || ''}`;
              }
              return value;
            })
          : this.props.text}
        {this.props.children}
        <i className="fa fa-close" onClick={this.props.onDismiss} />
      </Block>
    );
  }
}
