/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { ContactUpdatedAt_contact } from './__generated__/ContactUpdatedAt_contact.graphql';

type Props = {
  contact: ContactUpdatedAt_contact,
  tz: string,
};

const ContactUpdatedAt = (props: Props) =>
  props.contact.updatedAt && (
    <DateTimeTooltip date={moment.tz(props.contact.updatedAt, props.tz)} />
  );

export default createFragmentContainer(
  ContactUpdatedAt,
  graphql`
    fragment ContactUpdatedAt_contact on Contact {
      updatedAt
    }
  `,
);
