/**
 * @flow
 * @relayHash 2413155d0b9922f7e5379a055cc1836b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventAccessLevel_staffedEvent$ref = any;
type EventActionsColumn_staffedEvent$ref = any;
type EventDateAdded_staffedEvent$ref = any;
type EventDate_staffedEvent$ref = any;
type EventLocation_staffedEvent$ref = any;
type EventName_staffedEvent$ref = any;
type EventOnsite_staffedEvent$ref = any;
export type Direction = "ASC" | "DESC";
export type StaffMembershipSort = "CUSTOM" | "EMAIL" | "EVENT_ACCESS_LEVEL" | "EVENT_DATE" | "EVENT_DATE_ADDED" | "EVENT_LOCATION" | "EVENT_NAME" | "LAST_ACTIVITY" | "NAME" | "ONSITE";
export type UserEventsQueryVariables = {|
  userId: string,
  sort?: ?StaffMembershipSort,
  direction: Direction,
|};
export type UserEventsQueryResponse = {|
  +user: ?{|
    +staffedEvents?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +event: {|
            +id: string,
            +slug: string,
            +viewerCanView: boolean,
          |},
          +$fragmentRefs: EventAccessLevel_staffedEvent$ref & EventDate_staffedEvent$ref & EventDateAdded_staffedEvent$ref & EventLocation_staffedEvent$ref & EventName_staffedEvent$ref & EventOnsite_staffedEvent$ref & EventActionsColumn_staffedEvent$ref,
        |}
      |}>
    |}
  |}
|};
export type UserEventsQuery = {|
  variables: UserEventsQueryVariables,
  response: UserEventsQueryResponse,
|};
*/


/*
query UserEventsQuery(
  $userId: ID!
  $sort: StaffMembershipSort
  $direction: Direction!
) {
  user: node(id: $userId) {
    __typename
    ... on User {
      staffedEvents(first: 1000, sort: $sort, direction: $direction) {
        edges {
          node {
            ...EventAccessLevel_staffedEvent
            ...EventDate_staffedEvent
            ...EventDateAdded_staffedEvent
            ...EventLocation_staffedEvent
            ...EventName_staffedEvent
            ...EventOnsite_staffedEvent
            ...EventActionsColumn_staffedEvent
            event {
              id
              slug
              viewerCanView
            }
            id
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    id
  }
}

fragment EventAccessLevel_staffedEvent on StaffMembership {
  accessLevel
  belongsToTeam
}

fragment EventDate_staffedEvent on StaffMembership {
  event {
    startDate
    endDate
    tz
    id
  }
}

fragment EventDateAdded_staffedEvent on StaffMembership {
  addedAt
  event {
    tz
    id
  }
}

fragment EventLocation_staffedEvent on StaffMembership {
  event {
    primaryLocation {
      city
      state
      name
      country
      id
    }
    id
  }
}

fragment EventName_staffedEvent on StaffMembership {
  event {
    slug
    name
    viewerCanView
    id
  }
}

fragment EventOnsite_staffedEvent on StaffMembership {
  id
  onsite
  viewerCanUpdate
}

fragment EventActionsColumn_staffedEvent on StaffMembership {
  id
  viewerCanUpdateAccessLevel
  viewerCanRemove
  accessLevel
  event {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "StaffMembershipSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanView",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = [
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction",
    "type": "Direction"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "StaffMembershipSort"
  }
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserEventsQuery",
  "id": null,
  "text": "query UserEventsQuery(\n  $userId: ID!\n  $sort: StaffMembershipSort\n  $direction: Direction!\n) {\n  user: node(id: $userId) {\n    __typename\n    ... on User {\n      staffedEvents(first: 1000, sort: $sort, direction: $direction) {\n        edges {\n          node {\n            ...EventAccessLevel_staffedEvent\n            ...EventDate_staffedEvent\n            ...EventDateAdded_staffedEvent\n            ...EventLocation_staffedEvent\n            ...EventName_staffedEvent\n            ...EventOnsite_staffedEvent\n            ...EventActionsColumn_staffedEvent\n            event {\n              id\n              slug\n              viewerCanView\n            }\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment EventAccessLevel_staffedEvent on StaffMembership {\n  accessLevel\n  belongsToTeam\n}\n\nfragment EventDate_staffedEvent on StaffMembership {\n  event {\n    startDate\n    endDate\n    tz\n    id\n  }\n}\n\nfragment EventDateAdded_staffedEvent on StaffMembership {\n  addedAt\n  event {\n    tz\n    id\n  }\n}\n\nfragment EventLocation_staffedEvent on StaffMembership {\n  event {\n    primaryLocation {\n      city\n      state\n      name\n      country\n      id\n    }\n    id\n  }\n}\n\nfragment EventName_staffedEvent on StaffMembership {\n  event {\n    slug\n    name\n    viewerCanView\n    id\n  }\n}\n\nfragment EventOnsite_staffedEvent on StaffMembership {\n  id\n  onsite\n  viewerCanUpdate\n}\n\nfragment EventActionsColumn_staffedEvent on StaffMembership {\n  id\n  viewerCanUpdateAccessLevel\n  viewerCanRemove\n  accessLevel\n  event {\n    id\n  }\n}\n",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "user",
          "staffedEvents"
        ]
      }
    ]
  },
  "fragment": {
    "kind": "Fragment",
    "name": "UserEventsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "User",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "staffedEvents",
                "name": "__UserEvents_staffedEvents_connection",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembershipRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffMembershipRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembership",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "EventAccessLevel_staffedEvent",
                            "args": null
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "EventDate_staffedEvent",
                            "args": null
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "EventDateAdded_staffedEvent",
                            "args": null
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "EventLocation_staffedEvent",
                            "args": null
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "EventName_staffedEvent",
                            "args": null
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "EventOnsite_staffedEvent",
                            "args": null
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "EventActionsColumn_staffedEvent",
                            "args": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "event",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Event",
                            "plural": false,
                            "selections": [
                              v2,
                              v3,
                              v4
                            ]
                          },
                          v5
                        ]
                      },
                      v6
                    ]
                  },
                  v7
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserEventsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          v5,
          v2,
          {
            "kind": "InlineFragment",
            "type": "User",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "staffedEvents",
                "storageKey": null,
                "args": v8,
                "concreteType": "StaffMembershipRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffMembershipRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembership",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "accessLevel",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "belongsToTeam",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "event",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Event",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "startDate",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "endDate",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "tz",
                                "args": null,
                                "storageKey": null
                              },
                              v2,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "primaryLocation",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Location",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "city",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "state",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  v9,
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "country",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  v2
                                ]
                              },
                              v3,
                              v9,
                              v4
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "addedAt",
                            "args": null,
                            "storageKey": null
                          },
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "onsite",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "viewerCanUpdate",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "viewerCanUpdateAccessLevel",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "viewerCanRemove",
                            "args": null,
                            "storageKey": null
                          },
                          v5
                        ]
                      },
                      v6
                    ]
                  },
                  v7
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "staffedEvents",
                "args": v8,
                "handle": "connection",
                "key": "UserEvents_staffedEvents",
                "filters": []
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6c91e59ec9e905895f426da0624f9ba';
module.exports = node;
