/* @flow */
import type { RecordProxy, RecordSourceSelectorProxy, SelectorData } from 'react-relay';

import getMutationRoot from './lib/getMutationRoot';

type Config = {
  deletedIdField: string,
};

function removeRecordFromStore({ deletedIdField }: Config) {
  return (store: RecordSourceSelectorProxy, data: SelectorData) => {
    const root: ?RecordProxy = getMutationRoot(store, data);

    if (!root) throw new Error('Root field missing in mutation');

    const deletedId = root.getValue(deletedIdField);

    if (!deletedId) throw new Error(`"${deletedIdField}" is not present in mutation response`);

    store.delete(deletedId);
  };
}

export default removeRecordFromStore;
