import PropTypes from 'prop-types';
import React from 'react';

import { createMoment } from 'utils/Date';
import { getQuarter } from 'utils/fiscal';
import { FiltersItem } from './FiltersItem';
import { CalendarsPanel } from 'components/panels/CalendarsPanel';

export class FiltersDateItem extends React.Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    fiscalYearStart: PropTypes.number,
  };
  static defaultProps = {
    filters: {},
    onSelect: () => {},
    onReset: () => {},
  };

  moment = createMoment();
  state = {
    start_date: this.moment()
      .startOf('day')
      .toDate(),
    end_date: this.moment()
      .add(1, 'week')
      .startOf('day')
      .toDate(),
  };

  handlePanelShow = e => {
    e.preventDefault();

    this.refs.panel.show();
  };

  handleDateChange = date => {
    this.setState(date);
  };

  handleDateSave = date => {
    this.props.onSelect(
      this.props.name,
      date.end_date
        ? `${this.moment(date.start_date).format('MM-DD-YYYY')}/${this.moment(date.end_date).format(
            'MM-DD-YYYY',
          )}`
        : `${this.moment(date.start_date).format('MM-DD-YYYY')}`,
    );
  };

  lastFourQuarters() {
    const fiscalYearStart = this.props.fiscalYearStart || 0;
    return [-3, -2, -1, 0].map(quarterIndex => {
      const quarter = getQuarter(quarterIndex, fiscalYearStart);
      return { value: quarter.key, text: quarter.label };
    });
  }

  render() {
    const { filters, text, name, onSelect, onReset } = this.props;
    const { start_date, end_date } = this.state;

    const items = [
      { text: 'Last Month', value: 'month' },
      { text: 'Last 90 Days', value: '90days' },
      { text: 'Last Year', value: 'year' },
      ...this.lastFourQuarters(),
      { text: 'Custom...', value: '...', onClick: this.handlePanelShow },
    ];

    const localFilters = Object.assign({}, filters);
    if (typeof localFilters[name] === 'object') {
      localFilters[name] = localFilters[name].displayName || localFilters[name].name;
    }

    return (
      <div className="filters-date-item">
        <FiltersItem
          filters={localFilters}
          text={text}
          name={name}
          onSelect={onSelect}
          onReset={onReset}
          items={items}
        />
        <CalendarsPanel
          hideHeader
          hiddenCheckbox
          start_date_all_day
          end_date_all_day
          start_date={start_date}
          end_date={end_date}
          onChange={this.handleDateChange}
          onSave={this.handleDateSave}
          ref="panel"
        />
      </div>
    );
  }
}
