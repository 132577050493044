/**
 * @flow
 * @relayHash b5606248dcf9907c3781818cf118f829
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
type OrgUserSearchRow_user$ref = any;
export type OrgUserSearchQueryVariables = {|
  query: string,
  hasTeamAccess?: ?boolean,
  limitedAccessOrMore?: ?boolean,
|};
export type OrgUserSearchQueryResponse = {|
  +org: {|
    +viewerCanManageStaff: boolean,
    +users: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +email: string,
          +$fragmentRefs: MaterialAvatar_user$ref & OrgUserSearchRow_user$ref,
        |}
      |}>
    |},
  |}
|};
export type OrgUserSearchQuery = {|
  variables: OrgUserSearchQueryVariables,
  response: OrgUserSearchQueryResponse,
|};
*/


/*
query OrgUserSearchQuery(
  $query: String!
  $hasTeamAccess: Boolean
  $limitedAccessOrMore: Boolean
) {
  org {
    viewerCanManageStaff
    users(first: 3, query: $query, hasTeamAccess: $hasTeamAccess, limitedAccessOrMore: $limitedAccessOrMore) {
      edges {
        node {
          id
          firstName
          lastName
          email
          ...MaterialAvatar_user
          ...OrgUserSearchRow_user
        }
      }
    }
    id
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}

fragment OrgUserSearchRow_user on User {
  firstName
  lastName
  email
  ...MaterialAvatar_user
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hasTeamAccess",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "limitedAccessOrMore",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanManageStaff",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "hasTeamAccess",
    "variableName": "hasTeamAccess",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "limitedAccessOrMore",
    "variableName": "limitedAccessOrMore",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query",
    "type": "String"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "OrgUserSearchQuery",
  "id": null,
  "text": "query OrgUserSearchQuery(\n  $query: String!\n  $hasTeamAccess: Boolean\n  $limitedAccessOrMore: Boolean\n) {\n  org {\n    viewerCanManageStaff\n    users(first: 3, query: $query, hasTeamAccess: $hasTeamAccess, limitedAccessOrMore: $limitedAccessOrMore) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          email\n          ...MaterialAvatar_user\n          ...OrgUserSearchRow_user\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n\nfragment OrgUserSearchRow_user on User {\n  firstName\n  lastName\n  email\n  ...MaterialAvatar_user\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OrgUserSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": v2,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5,
                      v6,
                      {
                        "kind": "FragmentSpread",
                        "name": "MaterialAvatar_user",
                        "args": null
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "OrgUserSearchRow_user",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrgUserSearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": v2,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5,
                      v6,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9be285b9207f0f05f7a23474a3c08e6f';
module.exports = node;
