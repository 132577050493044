/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateSalesforceMappingMutationResponse,
  updateSalesforceMappingMutationVariables,
} from './__generated__/updateSalesforceMappingMutation.graphql';

type InputType = $PropertyType<updateSalesforceMappingMutationVariables, 'input'>;

const mutation = graphql`
  mutation updateSalesforceMappingMutation($input: CreateOrUpdateSalesforceMappingInput!) {
    createOrUpdateSalesforceMapping(input: $input) {
      salesforceMappingEdge {
        node {
          circaField
          salesforceField
          toSalesforce
          fromSalesforce
          customField {
            id
          }
        }
      }
    }
  }
`;

export default function updateSalesforceMapping(
  input: InputType,
): Promise<updateSalesforceMappingMutationResponse> {
  const variables: updateSalesforceMappingMutationVariables = {
    input,
  };
  const optimisticResponse: updateSalesforceMappingMutationResponse = {
    createOrUpdateSalesforceMapping: {
      salesforceMappingEdge: {
        node: {
          circaField: input.circaField,
          salesforceField: input.salesforceField,
          toSalesforce: input.toSalesforce,
          fromSalesforce: input.fromSalesforce,
          customField: input.customFieldId
            ? {
                id: input.customFieldId,
              }
            : null,
        },
      },
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
