/* @flow */

import * as React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const ScrollButton = styled.div`
  flex: 0 0 56px;
  margin-top: 6px;
  text-align: center;
  font-size: 26px;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

export const ScrollArea = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-top: 16px;

  ${props =>
    props.scrollable &&
    `
    overflow-x: scroll;

    >a {
      height: 50px!important;
    }
  `};

  @media (max-width: 600px) {
    margin-top: 11px;
  }
`;

export default class Menu extends React.Component<
  {
    children: React.Node,
    secondary?: ?boolean,
    className?: string,
    scrollable?: ?boolean,
  },
  {
    contentTooBig: boolean,
    lockedToLeft: boolean,
    lockedToRight: boolean,
  },
> {
  state = {
    contentTooBig: false,
    lockedToLeft: true,
    lockedToRight: false,
  };

  resizeHandler: ?() => void;

  menuBar: ?HTMLDivElement;

  scrollArea: ?HTMLDivElement;

  componentDidMount = () => {
    if (this.props.scrollable) {
      this.resizeHandler = () => {
        this.updateDimensions();
      };
      window.addEventListener('resize', this.resizeHandler);
      if (
        this.menuBar &&
        this.scrollArea &&
        this.menuBar.offsetWidth < this.scrollArea.scrollWidth
      ) {
        this.setState({ contentTooBig: true });
      }
    }
  };

  componentDidUpdate(prevProps: $PropertyType<Menu, 'props'>) {
    if (
      React.Children.toArray(prevProps.children).length !==
      React.Children.toArray(this.props.children).length
    ) {
      this.updateDimensions();
    }
  }

  componentWillUnmount = () => {
    if (this.props.scrollable) {
      window.removeEventListener('resize', this.resizeHandler);
    }
  };

  updateDimensions = () => {
    if (!this.menuBar || !this.scrollArea) {
      return;
    }
    if (this.menuBar.offsetWidth < this.scrollArea.scrollWidth) {
      this.setState({ contentTooBig: true });
    } else {
      this.setState({ contentTooBig: false });
    }
    this.updateButtons();
  };

  updateButtons = () => {
    if (!this.scrollArea) {
      return;
    }
    if (this.scrollArea.offsetWidth + this.scrollArea.scrollLeft >= this.scrollArea.scrollWidth) {
      this.setState({
        lockedToLeft: false,
        lockedToRight: true,
      });
    } else if (this.scrollArea.scrollLeft === 0) {
      this.setState({
        lockedToLeft: true,
        lockedToRight: false,
      });
    } else {
      this.setState({
        lockedToLeft: false,
        lockedToRight: false,
      });
    }
  };

  handleScrollLeft = () => {
    if (!this.scrollArea) {
      return;
    }
    this.scrollArea.scrollLeft = 0;
    this.updateButtons();
  };

  handleScrollRight = () => {
    if (!this.scrollArea) {
      return;
    }
    this.scrollArea.scrollLeft = this.scrollArea.scrollWidth - this.scrollArea.offsetWidth;
    this.updateButtons();
  };

  render() {
    const classes = classNames(
      'site-menu',
      {
        'site-menu--secondary': this.props.secondary,
      },
      this.props.className,
    );
    const { scrollable } = this.props;
    const { contentTooBig, lockedToLeft, lockedToRight } = this.state;

    return (
      <div
        className={classes}
        ref={menuBar => {
          this.menuBar = menuBar;
        }}
      >
        {scrollable && contentTooBig && !lockedToLeft && (
          <ScrollButton onClick={this.handleScrollLeft}>
            <i className="fa fa-fw fa-angle-left" />
          </ScrollButton>
        )}
        <ScrollArea
          scrollable={scrollable}
          onScroll={this.updateButtons}
          ref={scrollArea => {
            this.scrollArea = scrollArea;
          }}
        >
          {this.props.children}
        </ScrollArea>
        {scrollable && contentTooBig && !lockedToRight && (
          <ScrollButton onClick={this.handleScrollRight}>
            <i className="fa fa-fw fa-angle-right" />
          </ScrollButton>
        )}
      </div>
    );
  }
}
