/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { TaskStatuses } from 'config/taskStatuses';

import reorderTasks from 'graph/mutations/task/reorderTasks';

import InlineUserSelectField from 'components/budget/Table/InlineUserSelectField';
import { type DateConfig } from 'components/date/DateTimePicker';
import DateTimeOverlay from 'components/date/DateTimePicker/DateTimeOverlay';
import Button from 'components/material/Button';
import TagsOverlay from 'components/material/Tags/TagsOverlay';
import Tooltip from 'components/material/Tooltip';
import { Header } from 'components/material/UserSelectInput';
import DeleteOverlay from 'components/Tasks/overlays/DeleteOverlay';
import FolderOverlay from 'components/Tasks/overlays/FolderOverlay';
import { type TaskRowType } from 'components/Tasks/TaskRow';

import type { TasksMultiselect_event } from './__generated__/TasksMultiselect_event.graphql';
import type { TasksMultiselect_org } from './__generated__/TasksMultiselect_org.graphql';

const Container = styled.div`
  display: flex;
  position: relative;
  margin: 0 10px;
`;

const StyledTooltip = styled(Tooltip)`
  margin-right: 4px;
`;

const ButtonContainer = styled.div`
  position: relative;
  width: 33px;
  height: 32px;

  ${Header} {
    padding: 1px 0 0 1px;
  }
  > div > div:first-child {
    border: 0;
  }
`;

class TasksMultiselect extends React.PureComponent<
  {
    org: TasksMultiselect_org,
    event: TasksMultiselect_event,
    selectedTasks: Array<TaskRowType>,
    onTasksUpdate: (Array<string>, Object) => void,
    onTasksStatusUpdate: (Array<string>, TaskStatuses) => void,
    onTasksRemove: (Array<string>) => void,
    onTasksAssign: (Array<string>, string) => void,
    onTasksUnassign: (Array<string>, string) => void,
    onTasksAddTag: (Array<string>, string) => void,
    onTasksRemoveTag: (Array<string>, string) => void,
    onFolderCreate: string => Promise<string>,
  },
  {
    dateOverlayShown: boolean,
    tagsOverlayShown: boolean,
    deleteOverlayShown: boolean,
    folderOverlayShown: boolean,

    date: ?string,
    allDay: ?boolean,
  },
> {
  state = {
    dateOverlayShown: false,
    tagsOverlayShown: false,
    deleteOverlayShown: false,
    folderOverlayShown: false,

    date: null,
    allDay: null,
  };

  dateButtonRef = React.createRef();

  tagsButton: ?HTMLElement;

  deleteButton: ?HTMLElement;

  folderButton: ?HTMLElement;

  handleStatusToggle = () => {
    const status = this.props.selectedTasks.every(task => task.status === 'COMPLETED')
      ? 'OPEN'
      : 'COMPLETED';

    this.props.onTasksStatusUpdate(
      this.props.selectedTasks.map(task => task.id),
      status,
    );
  };

  handleDateOverlayShow = () => this.setState({ dateOverlayShown: true });

  handleDateOverlayHide = () => {
    if (this.state.date) {
      this.props.onTasksUpdate(
        this.props.selectedTasks.map(task => task.id),
        {
          dueDate: this.state.date,
          dueDateAllDay: this.state.allDay,
        },
      );
    }

    this.setState({ dateOverlayShown: false, date: null, allDay: null });
  };

  handleTagsOverlayShow = () => this.setState({ tagsOverlayShown: true });

  handleTagsOverlayHide = () => this.setState({ tagsOverlayShown: false });

  handleDeleteOverlayShow = () => this.setState({ deleteOverlayShown: true });

  handleDeleteOverlayHide = () => this.setState({ deleteOverlayShown: false });

  handleFolderOverlayShow = () => this.setState({ folderOverlayShown: true });

  handleFolderOverlayHide = () => this.setState({ folderOverlayShown: false });

  handleChangeDate = (dateConfig: DateConfig) => {
    this.setState({ date: dateConfig.date, allDay: dateConfig.hideTime });
  };

  handleUserSelect = (userId, isActive) => {
    if (isActive) {
      this.props.onTasksUnassign(
        this.props.selectedTasks.map(task => task.id),
        userId,
      );
    } else {
      this.props.onTasksAssign(
        this.props.selectedTasks.map(task => task.id),
        userId,
      );
    }
  };

  handleTagSelect = (tagId, isActive) => {
    if (isActive) {
      this.props.onTasksRemoveTag(
        this.props.selectedTasks.map(task => task.id),
        tagId,
      );
    } else {
      this.props.onTasksAddTag(
        this.props.selectedTasks.map(task => task.id),
        tagId,
      );
    }
  };

  handleDeleteTasks = () => {
    this.props.onTasksRemove(this.props.selectedTasks.map(task => task.id));
  };

  handleTasksMove = (folderId: string) => {
    reorderTasks(
      this.props.selectedTasks.map(task => task.id),
      folderId,
      0,
      this.props.selectedTasks.map((task, index) => index),
    );
  };

  render() {
    const tasks = this.props.selectedTasks;

    const tags = this.props.org.tags.edges.map(edge => edge.node);
    const folders = this.props.event.folders.edges.map(edge => edge.node);

    const activeUsers = tasks[0].assignees.edges
      .filter(edge =>
        tasks.every(task => task.assignees.edges.some(({ node }) => node.id === edge.node.id)),
      )
      .map(edge => edge.node);

    const activeTags = tasks[0].tags.edges
      .map(edge => edge.node.id)
      .filter(tagId =>
        tasks.every(task => task.tags.edges.some(({ node: tag }) => tag.id === tagId)),
      );

    return (
      <Container>
        {tasks.every(t => t.viewerCanUpdateStatus) && (
          <StyledTooltip label="Toggle Status" placement="top">
            <Button icon="check" onClick={this.handleStatusToggle} outline primary />
          </StyledTooltip>
        )}
        {tasks.every(t => t.viewerCanUpdateDueDate) && (
          <StyledTooltip label="Set Due Date" placement="top">
            <ButtonContainer ref={this.dateButtonRef}>
              <Button icon="calendar" onClick={this.handleDateOverlayShow} outline primary />
            </ButtonContainer>

            <DateTimeOverlay
              target={this.dateButtonRef.current}
              container={this.dateButtonRef.current}
              show={this.state.dateOverlayShown}
              onHide={this.handleDateOverlayHide}
              onChange={this.handleChangeDate}
              date={this.state.date}
              hideTime={this.state.allDay == null || this.state.allDay}
              tz={this.props.event.tz}
            />
          </StyledTooltip>
        )}
        {tasks.every(t => t.viewerCanAssign) && (
          <StyledTooltip label="Assign Member">
            <ButtonContainer>
              <InlineUserSelectField
                activeUsers={activeUsers}
                onSelect={user => {
                  if (user) {
                    this.props.onTasksAssign(
                      this.props.selectedTasks.map(task => task.id),
                      user.id,
                    );
                  }
                }}
                onUnselect={user =>
                  this.props.onTasksUnassign(
                    this.props.selectedTasks.map(task => task.id),
                    user.id,
                  )
                }
                isMultiselect
                eventId={this.props.event.id}
              >
                <Button onClick={() => {}} icon="user" outline primary />
              </InlineUserSelectField>
            </ButtonContainer>
          </StyledTooltip>
        )}
        {tasks.every(t => t.parent == null && t.viewerCanReorder) && (
          <StyledTooltip label="Move to Section" placement="top">
            <ButtonContainer
              ref={el => {
                this.folderButton = el;
              }}
            >
              <Button icon="arrows" onClick={this.handleFolderOverlayShow} outline primary />
            </ButtonContainer>
            <FolderOverlay
              target={this.folderButton}
              container={this.folderButton}
              show={this.state.folderOverlayShown}
              onHide={this.handleFolderOverlayHide}
              folders={folders}
              onCreate={this.props.onFolderCreate}
              onMove={this.handleTasksMove}
            />
          </StyledTooltip>
        )}
        {tasks.every(t => t.viewerCanUpdateTags) && (
          <StyledTooltip label="Edit Tags" placement="top">
            <ButtonContainer
              ref={el => {
                this.tagsButton = el;
              }}
            >
              <Button icon="tag" onClick={this.handleTagsOverlayShow} outline primary />
            </ButtonContainer>
            <TagsOverlay
              target={this.tagsButton}
              container={this.tagsButton}
              show={this.state.tagsOverlayShown}
              onHide={this.handleTagsOverlayHide}
              tags={tags}
              activeTags={activeTags}
              onSelect={this.handleTagSelect}
            />
          </StyledTooltip>
        )}
        {tasks.every(t => t.viewerCanDelete) && (
          <StyledTooltip label="Delete Tasks" placement="top">
            <ButtonContainer
              ref={el => {
                this.deleteButton = el;
              }}
            >
              <Button icon="trash" onClick={this.handleDeleteOverlayShow} outline primary />
            </ButtonContainer>
            <DeleteOverlay
              target={this.deleteButton}
              container={this.deleteButton}
              onHide={this.handleDeleteOverlayHide}
              show={this.state.deleteOverlayShown}
              onDelete={this.handleDeleteTasks}
            />
          </StyledTooltip>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(TasksMultiselect, {
  event: graphql`
    fragment TasksMultiselect_event on Event {
      id
      tz
      folders {
        edges {
          node {
            ...FolderOverlay_folders
          }
        }
      }
    }
  `,
  org: graphql`
    fragment TasksMultiselect_org on Org {
      tags {
        edges {
          node {
            id
            ...TagsOverlay_tags
          }
        }
      }
    }
  `,
});
