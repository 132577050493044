/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled from 'styled-components';
import xorBy from 'lodash/xorBy';

import getOptionKeysByKind from 'utils/customization/getOptionKeysByKind';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import { type CustomFieldsType, type FieldType } from 'utils/customization/types';
import isValidEmail from 'utils/validators/isValidEmail';
import isValidWebsite from 'utils/validators/isValidWebsite';

import updateContact from 'graph/mutations/contact/updateContact';
import updateCustomFieldValue, {
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import showModernMutationError from 'graph/utils/showModernMutationError';

import customFields, { type InexactUser } from 'components/Customizable/customFields';
import LockIcon from 'components/LockIcon';
import EditableLinkField from 'components/material/EditableLinkField';
import TextField from 'components/material/TextField';

import { type ContactDefaultFields } from './ContactFormFields';
import suggestedFields, { suggestedFieldsDataType } from './suggestedFields';

import type { ContactProfile_contact } from './__generated__/ContactProfile_contact.graphql';
import type { ContactProfile_event } from './__generated__/ContactProfile_event.graphql';
import type { ContactProfile_user } from './__generated__/ContactProfile_user.graphql';

const ContactViewContent = styled.div`
  display: flex;
  margin-top: 30px;
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 495px;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 15px;
  padding-left: 8px;
  @media (max-width: 760px) {
    display: block;
    > div {
      margin-right: 0;
    }
  }
`;

const FieldColumn = styled.div`
  flex: 1 1 auto;
  &:not(:last-child) {
    margin-right: 35px;
  }
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: ${props => (props.kind === 'BOOLEAN' ? 0 : 16)}px;
`;

type Props = {
  contact: ContactProfile_contact,
  event: ContactProfile_event,
  user: ContactProfile_user,
  history: History,
  location: Location,
};

type State = {
  errors: { [string]: string },
  contactDefaultFields: ContactDefaultFields,
  contactCustomFields: CustomFieldsType,
};

class ContactProfile extends React.Component<Props, State> {
  state = {
    errors: {},
    contactDefaultFields: {
      firstName: this.props.contact.firstName,
      lastName: this.props.contact.lastName,
      email: this.props.contact.email,
      title: this.props.contact.title,
      company: this.props.contact.company,
      phone1: this.props.contact.phone1,
      phone2: this.props.contact.phone2,
      website: this.props.contact.website,
      twitter: this.props.contact.twitter,
      linkedin: this.props.contact.linkedin,
      avatar: this.props.contact.avatar,
      description: this.props.contact.description,
      country: this.props.contact.country,
      state: this.props.contact.state,
      city: this.props.contact.city,
      zip: this.props.contact.zip,
      street: this.props.contact.street,
      hotLead: this.props.contact.hotLead,
      emailOptIn: this.props.contact.emailOptIn,
      contactTypeId: this.props.contact.contactType ? this.props.contact.contactType.id : null,
      owner: this.props.contact.owner,
    },
    contactCustomFields: {
      customTextFields: this.props.contact.customTextFields,
      customTextareaFields: this.props.contact.customTextareaFields,
      customLinkFields: this.props.contact.customLinkFields,
      customDateFields: this.props.contact.customDateFields,
      customNumberFields: this.props.contact.customNumberFields,
      customCurrencyFields: this.props.contact.customCurrencyFields,
      customBooleanFields: this.props.contact.customBooleanFields,
      customUserSelectFields: this.props.contact.customUserSelectFields,
      customUserMultiselectFields: this.props.contact.customUserMultiselectFields,
      customSelectFields: this.props.contact.customSelectFields,
      customMultiselectFields: this.props.contact.customMultiselectFields,
    },
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      xorBy(
        [
          ...prevState.contactCustomFields.customMultiselectFields,
          ...prevState.contactCustomFields.customSelectFields,
        ],
        [...nextProps.contact.customMultiselectFields, ...nextProps.contact.customSelectFields],
        filed => filed.option.id,
      ).length > 0
    ) {
      return {
        contactCustomFields: {
          ...prevState.contactCustomFields,
          customMultiselectFields: nextProps.contact.customMultiselectFields,
          customSelectFields: nextProps.contact.customSelectFields,
        },
      };
    }
    return null;
  }

  handleFixedFieldsBlur = (name: string, value: string) => {
    const {
      contact: { id: contactId },
      event,
    } = this.props;
    const parsedValue = value.trim();
    if (parsedValue === this.state.contactDefaultFields[name]) return;
    this.setState(
      prevState => {
        const contactDefaultFields = { ...prevState.contactDefaultFields, [name]: parsedValue };
        if (['firstName', 'lastName', 'email'].includes(name) && !parsedValue) {
          return {
            contactDefaultFields,
            errors: { ...prevState.errors, [name]: 'Required' },
          };
        }
        if (name === 'email' && !isValidEmail(parsedValue)) {
          return {
            contactDefaultFields,
            errors: { ...prevState.errors, email: 'Invalid email' },
          };
        }

        return {
          contactDefaultFields,
          errors: Object.keys(prevState.errors).reduce((obj, key) => {
            return name === key ? obj : { ...obj, [key]: prevState.errors[key] };
          }, {}),
        };
      },
      () => {
        const {
          contactDefaultFields: { firstName, lastName, email },
          errors,
        } = this.state;
        if (Object.keys(errors).length > 0) return;

        updateContact({
          contactId,
          firstName,
          lastName,
          email,
          eventId: event ? event.id : null,
          fromWindow: event ? 'event contacts' : 'org contacts',
        }).catch(err => {
          if (err.message.includes('Email has already been taken')) {
            this.setState(prevState => ({
              errors: {
                ...prevState.errors,
                email: 'Email address already taken',
              },
            }));
          } else {
            showModernMutationError(err);
          }
        });
      },
    );
  };

  handleTextFieldBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    this.handleFixedFieldsBlur(e.currentTarget.name, e.currentTarget.value);
  };

  updateCustomFieldState = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    const { arrayKey, optionKey } = getOptionKeysByKind(field.kind);
    const value = Object.values(input)[0];
    const valuesArray = Array.isArray(value) ? value : [value].filter(Boolean);
    const optionsArray = valuesArray.map(optionId =>
      users == null ? optionId : users.find(user => user.id === optionId),
    );
    this.setState(prevState => {
      const fieldOptions = prevState.contactCustomFields[arrayKey];
      return {
        contactCustomFields: {
          ...prevState.contactCustomFields,
          [arrayKey]: [
            ...fieldOptions.filter(option => option.customField.id !== field.id),
            ...optionsArray.map(option => ({
              customField: { id: field.id },
              [optionKey]: optionKey === 'option' ? { id: option } : option,
            })),
          ],
        },
      };
    });
  };

  handleUpdateSuggestedField = (field: FieldType, changes: ContactDefaultFields) => {
    const {
      contact: { id: contactId },
      event,
    } = this.props;
    const value = Object.values(changes)[0];
    const fieldName = Object.keys(changes)[0];

    this.setState(prevState => ({
      contactDefaultFields: { ...prevState.contactDefaultFields, ...changes },
    }));
    if (field.required && (value === '' || value == null)) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [fieldName]: 'Required' },
      }));
      return;
    }
    if (
      ['website', 'twitter', 'linkedin'].includes(fieldName) &&
      typeof value === 'string' &&
      value !== '' &&
      !isValidWebsite(value)
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [fieldName]: 'Invalid url' },
      }));
      return;
    }

    if (field.fieldName === 'email' && typeof value === 'string' && !isValidEmail(value)) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [fieldName]: 'Invalid email' },
      }));
      return;
    }

    const { owner, company, registrationStatusId, attendanceStatusId, ...rest } = changes;
    const emptyCompany = fieldName === 'company' ? { company: null } : null;
    const emptyOwner = fieldName === 'owner' ? { ownerId: null } : null;
    updateContact({
      contactId,
      eventId: event ? event.id : null,
      fromWindow: event ? 'event contacts' : 'org contacts',
      ...(owner ? { ownerId: owner.id } : emptyOwner),
      ...(company && company.name
        ? { company: { name: company.name, id: company.id, salesforceId: company.salesforceId } }
        : emptyCompany),
      ...rest,
    }).catch(showModernMutationError);
    this.setState(prevState => ({
      errors: Object.keys(prevState.errors).reduce((obj, key) => {
        return key === fieldName ? obj : { ...obj, [key]: prevState.errors[key] };
      }, {}),
    }));
  };

  handleUpdateCustomField = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    this.updateCustomFieldState(field, input, users);
    const { event, contact } = this.props;
    if (field.required && !Object.values(input)[0]) {
      this.setState(prevState => ({ errors: { ...prevState.errors, [field.id]: 'Required' } }));
      return;
    }
    if (field.kind === 'LINK' && !!input.linkValue && !isValidWebsite(input.linkValue)) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Invalid url' },
      }));
      return;
    }
    if (field.kind === 'LINK' && field.required && !input.linkValue) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Invalid url' },
      }));
      return;
    }
    if (
      field.required &&
      field.kind === 'MULTISELECT' &&
      input.selectValues != null &&
      input.selectValues.length === 0
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    if (
      field.required &&
      field.kind === 'USER_MULTISELECT' &&
      input.userValues != null &&
      input.userValues.length === 0
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    updateCustomFieldValue(
      { customFieldId: field.id, ...input },
      contact,
      event ? event.id : null,
      event ? 'event contacts' : 'org contacts',
    ).catch(showModernMutationError);
    this.setState(prevState => ({
      errors: Object.keys(prevState.errors).reduce((obj, key) => {
        return key === field.id ? obj : { ...obj, [key]: prevState.errors[key] };
      }, {}),
    }));
  };

  handleCompanyLinkClick = () => {
    if (!this.props.contact.company) {
      return;
    }
    const companyId = this.props.contact.company.id;
    const eventSlug = this.props.event && this.props.event.slug;
    const path =
      eventSlug != null
        ? `/events/${eventSlug}/contacts/companies/${companyId}/profile`
        : `/contacts/companies/${companyId}/profile`;

    this.props.history.push(path, {
      prevPath: this.props.location.pathname,
      prevPage: true,
      prevPageLabel: 'Contact Profile',
      search: this.props.location.search,
    });
  };

  render() {
    const contact = this.props.contact;
    const { contactDefaultFields, contactCustomFields, errors } = this.state;
    const sortedCustomFields = normalizeCustomFields(
      contact.org.customFields.edges.map(edge => edge.node),
    );
    const contactsSyncEnabled =
      contact.org.salesforceAccount != null && contact.org.salesforceAccount.contactsSyncEnabled;
    return (
      <ContactViewContent>
        <DetailsColumn>
          <Row>
            <FieldColumn>
              <TextField
                label="First Name"
                defaultValue={contactDefaultFields.firstName}
                autoFocus
                name="firstName"
                disabled={!contact.viewerCanUpdate}
                onBlur={this.handleTextFieldBlur}
                error={errors.firstName}
                required
              />
            </FieldColumn>
            <FieldColumn>
              <TextField
                label="Last Name"
                defaultValue={contactDefaultFields.lastName}
                autoFocus
                name="lastName"
                disabled={!contact.viewerCanUpdate}
                onBlur={this.handleTextFieldBlur}
                error={errors.lastName}
                required
              />
            </FieldColumn>
          </Row>
          <Row>
            <FieldColumn>
              <EditableLinkField
                label="Email"
                defaultValue={contactDefaultFields.email}
                name="email"
                readOnly={!contact.viewerCanUpdate}
                to={`mailto:${contactDefaultFields.email || ''}`}
                onBlur={this.handleTextFieldBlur}
                error={errors.email}
                required
              />
            </FieldColumn>
            <FieldColumn />
          </Row>
          {sortedCustomFields.map(field => {
            const restrictChangingValue =
              contactsSyncEnabled &&
              (contact.salesforceSyncAs === 'contact'
                ? field.restrictChangingValueForContact
                : field.restrictChangingValueForLead);
            if (field.kind === 'DEFAULT') {
              const renderer = field.fieldName && suggestedFields[field.fieldName];
              if (!renderer) return null;
              return (
                <Row key={field.id}>
                  {renderer({
                    contact: contactDefaultFields,
                    viewerCanUpdate: contact.viewerCanUpdate && !restrictChangingValue,
                    handleUpdate: changes => this.handleUpdateSuggestedField(field, changes),
                    onCompanyLinkClick: this.handleCompanyLinkClick,
                    field,
                    currency: contact.org.settings.currency,
                    errors: this.state.errors,
                  })}
                  {restrictChangingValue && (
                    <StyledLockIcon
                      kind={suggestedFieldsDataType[field.fieldName || 'TEXT']}
                      label="Salesforce"
                    />
                  )}
                </Row>
              );
            }
            return (
              <Row key={field.id}>
                {customFields[field.kind]({
                  customizable: contactCustomFields,
                  viewerCanUpdateValue: contact.viewerCanUpdate && !restrictChangingValue,
                  handleUpdate: (input, users) => this.handleUpdateCustomField(field, input, users),
                  field,
                  currency: contact.org.settings.currency,
                  tz: this.props.user.tz,
                  errors: this.state.errors,
                })}
                {restrictChangingValue && <StyledLockIcon kind={field.kind} label="Salesforce" />}
              </Row>
            );
          })}
        </DetailsColumn>
      </ContactViewContent>
    );
  }
}

export default createFragmentContainer(ContactProfile, {
  contact: graphql`
    fragment ContactProfile_contact on Contact {
      id
      __typename
      firstName
      lastName
      email
      company {
        id
        name
        salesforceId
      }
      org {
        id
        dbId
        settings {
          currency
        }
        salesforceAccount {
          contactsSyncEnabled
        }
        customFields(customizableType: [CONTACT]) {
          edges {
            node {
              id
              kind
              fieldName
              label
              required
              order
              viewerCanUpdate
              restrictChangingValueForLead: mappedToSalesforce(pull: true, kind: LEAD)
              restrictChangingValueForContact: mappedToSalesforce(pull: true, kind: CONTACT)
              options {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      title
      phone1
      phone2
      website
      twitter
      linkedin
      avatar
      salesforceSyncAs
      description
      country
      state
      city
      zip
      street
      viewerCanRemove
      viewerCanUpdate
      hotLead
      emailOptIn
      contactType {
        id
      }
      owner {
        id
        firstName
        lastName
        email
        avatar
        ...UserOptionRow_user
      }
      customTextFields {
        customField {
          id
        }
        value
      }
      customTextareaFields {
        customField {
          id
        }
        value
      }
      customLinkFields {
        customField {
          id
        }
        value
      }
      customDateFields {
        customField {
          id
        }
        value
      }
      customNumberFields {
        customField {
          id
        }
        value
      }
      customCurrencyFields {
        customField {
          id
        }
        value
      }
      customBooleanFields {
        customField {
          id
        }
        value
      }
      customUserSelectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...MaterialAvatar_user
        }
      }
      customUserMultiselectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...MaterialAvatar_user
        }
      }
      customSelectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
      customMultiselectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
    }
  `,
  event: graphql`
    fragment ContactProfile_event on Event {
      id
      slug
    }
  `,
  user: graphql`
    fragment ContactProfile_user on User {
      tz
    }
  `,
});
