/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { Location, Match, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import publicRuleNames from 'config/publicRuleNames';

import viewedSharedEventList from 'utils/analytics/events/viewedSharedEventList';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';
import NotFound from 'components/NotFound';

import AllEventsShareContainer from './AllEventsShareContainer';

import { type SharedEventListQueryResponse } from './__generated__/SharedEventListQuery.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const query = graphql`
  query SharedEventListQuery(
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
    $isPreviewing: Boolean!
  ) {
    ... @skip(if: $isPreviewing) {
      publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
      eventList(resourceToken: $resourceToken) {
        ...AllEventsShareContainer_eventList
        name
      }
    }
    ... @include(if: $isPreviewing) {
      me {
        email
      }
    }
    org {
      ...AllEventsShareContainer_org
    }
    publicMe {
      email
    }
  }
`;

export default class SharedEventList extends React.Component<{
  match: Match,
  history: RouterHistory,
  location: Location,
}> {
  componentDidMount() {
    viewedSharedEventList();
  }

  render() {
    const { location, history, match } = this.props;

    const resourceToken = match.params.resourceToken;
    const isPreviewing = !resourceToken;

    return (
      <Container>
        <DefaultQueryRenderer
          variables={{
            publicRuleName: publicRuleNames.PUBLIC_EVENT_LIST,
            resourceToken: resourceToken || '',
            orgSlug: match.params.orgName,
            isPreviewing,
          }}
          public={!isPreviewing}
          query={query}
          renderSuccess={(response: SharedEventListQueryResponse) => {
            if (isPreviewing || response.eventList != null) {
              return (
                <DocumentTitle title={response.eventList ? response.eventList.name : 'Preview'}>
                  <AllEventsShareContainer
                    eventList={response.eventList || null}
                    org={response.org}
                    userEmail={
                      isPreviewing
                        ? response.me && response.me.email
                        : response.publicMe && response.publicMe.email
                    }
                    location={location}
                    history={history}
                    match={match}
                    isPreviewing={isPreviewing}
                  />
                </DocumentTitle>
              );
            }
            return <NotFound history={history} />;
          }}
          renderError={() => <NotAuthorized />}
        />
      </Container>
    );
  }
}
