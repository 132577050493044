/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation unfollowTaskMutation($input: UnfollowDeliverableInput!) {
    unfollowDeliverable(input: $input) {
      deliverable {
        id
        following
      }
    }
  }
`;

export default function unfollowTask(taskId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableId: taskId,
      },
    },
    optimisticResponse: {
      unfollowDeliverable: {
        deliverable: { id: taskId, following: false },
      },
    },
  });
}
