/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';

import BriefViewSharedContext from '../../BriefViewSharedContext';
import BriefBlockLabel from '../../components/BriefBlockLabel';
import { ItemName } from '../../components/NameFields';
import DueDate from './DueDate';
import InfoContainer from './InfoContainer';
import SubtaskItem from './SubtaskItem';
import TaskItemAssignee from './TaskItemAssignee';
import TaskStatus from './TaskStatus';

import { type BriefTaskItem_task } from './__generated__/BriefTaskItem_task.graphql';

const Container = styled.div`
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #cdd1d4;
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
  @media (max-width: 480px) {
    margin-left: -16px;
    margin-right: -16px;
    padding: 10px 16px;
  }
`;

const Assignees = styled.div`
  display: flex;
  flex: 1 auto;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const Subtasks = styled.div`
  margin-top: 25px;
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const StyledBriefBlockLabel = styled(BriefBlockLabel)`
  padding-bottom: 0;
`;

const renderName = (eventSlug: string, name: ?string, slug: ?string) => {
  const component = name && <ItemName>{name}</ItemName>;
  if (!slug) {
    return component;
  }
  const pathToTask = `/events/${eventSlug}/tasks/${slug}`;

  return (
    <a href={pathToTask} rel="noopener noreferrer" target="_blank">
      {component}
    </a>
  );
};

const BriefTaskItem = (props: {
  task: BriefTaskItem_task,
  primaryColor: string,
  timezone: string,
  eventSlug: string,
}) => {
  const {
    timezone,
    eventSlug,
    primaryColor,
    task: { name, slug, status, dueDate, dueDateAllDay, assignees, subtasks },
  } = props;
  const completed = status === 'COMPLETED';
  const taskAssignees = assignees && assignees.edges.map(({ node }) => node);
  const date = dueDate ? formatDateTime(moment(dueDate).tz(timezone), dueDateAllDay) : null;
  const orderedSubtasks =
    subtasks &&
    sortBy(
      subtasks.edges.map(({ node }) => node),
      ['order'],
    );

  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <Container>
          {renderName(eventSlug, name, slug)}
          <InfoContainer>
            {date && <DueDate date={date} />}
            <TaskStatus completed={completed} />
          </InfoContainer>
          {taskAssignees && taskAssignees.length > 0 && (
            <Assignees isShared={sharedView}>
              {taskAssignees.map(ta => (
                <TaskItemAssignee key={ta.id} user={ta} />
              ))}
            </Assignees>
          )}
          {orderedSubtasks && orderedSubtasks.length > 0 && (
            <Subtasks>
              <StyledBriefBlockLabel isShared={sharedView} color={primaryColor}>
                subtasks
              </StyledBriefBlockLabel>
              {orderedSubtasks.map(st => (
                <SubtaskItem
                  key={st.id}
                  subtask={st}
                  timezone={timezone}
                  eventSlug={eventSlug}
                  slug={slug}
                />
              ))}
            </Subtasks>
          )}
        </Container>
      )}
    </BriefViewSharedContext.Consumer>
  );
};
export default createFragmentContainer(BriefTaskItem, {
  task: graphql`
    fragment BriefTaskItem_task on Deliverable {
      name @include(if: $includeTaskTitle)
      slug @include(if: $includeTaskLink)
      status @include(if: $includeTaskStatus)
      dueDate @include(if: $includeTaskDueDate)
      dueDateAllDay @include(if: $includeTaskDueDate)
      assignees @include(if: $includeTaskAssignees) {
        edges {
          node {
            id
            ...TaskItemAssignee_user
          }
        }
      }
      subtasks @include(if: $includeTaskSubtasks) {
        edges {
          node {
            id
            order
            ...SubtaskItem_subtask
          }
        }
      }
    }
  `,
});
