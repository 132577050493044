/* @flow */
import determineCustomFieldMinWidth from 'utils/customization/determineCustomFieldMinWidth';
import type { FieldType } from 'utils/customization/types';
import type {
  CustomFieldParam,
  DateRangeParam,
  NumberRangeParam,
} from 'utils/routing/parseTypedQueryString';

import { type ColumnType } from 'components/budget/Table';
import kindToTableComponent from 'components/Customizable/kindToTableComponent';
import type { Filter } from 'components/material/ColumnFilters/columnFilterTypes';

import { defaultTableComponents } from './columns';

export type OpportunityTableSourceTab =
  | 'event_contacts_opportunities'
  | 'company_opportunities'
  | 'contact_opportunities';

// Flow will provide this type later as an input variable
export type InputVariableFilters = {
  [key: string]: CustomFieldParam,
  names: ?$ReadOnlyArray<string>,
  opportunityValues: ?NumberRangeParam,
  sort: string,
  direction: 'ASC' | 'DESC',
  customFilters: $ReadOnlyArray<?{
    customFieldId: string,
    dateParam?: ?DateRangeParam,
    numberParam?: ?NumberRangeParam,
    booleanParam?: ?boolean,
    optionIds?: ?$ReadOnlyArray<string>,
  }>,
  customFieldSortId: ?string,
};

export type OpportunityCellPropsType<Org: ?{} = {}, Opportunity: {} = {}> = {|
  org: Org,
  eventSlug: string,
  opportunity: Opportunity,
  customizable: Opportunity,
  fieldSettings: ?FieldType,
  tz: string,
  fromTab: OpportunityTableSourceTab,
  readOnly: boolean,
|};

export const defaultColumns = [
  'name',
  'associated_contacts',
  'amount',
  'status',
  'description',
  'company_id',
  'owner_id',
  'salesforce_id',
];

export default (
  opportunityFields: $ReadOnlyArray<FieldType>,
  shownColumns: $ReadOnlyArray<string>,
): $ReadOnlyArray<ColumnType<any, any>> => {
  return [
    ...opportunityFields
      .filter(opportunityField =>
        shownColumns.includes(opportunityField.fieldName || opportunityField.id),
      )
      .map(opportunityField => {
        if (opportunityField.fieldName) {
          return {
            title: opportunityField.label,
            fieldSettings: opportunityField,
            align: opportunityField.fieldName === 'amount' ? 'right' : 'left',
            component: defaultTableComponents[opportunityField.fieldName],
            sortKey: opportunityField.unsortable
              ? undefined
              : opportunityField.fieldName.toUpperCase(),
            minWidth: opportunityField.minWidth,
            ...(opportunityField.fieldName === 'salesforce_id' ? { grow: 1 } : null),
          };
        }

        return {
          title: opportunityField.label,
          fieldSettings: opportunityField,
          align: ['CURRENCY', 'NUMBER'].includes(opportunityField.kind) ? 'right' : 'left',
          component: kindToTableComponent[opportunityField.kind],
          sortKey: opportunityField.id,
          minWidth: determineCustomFieldMinWidth(opportunityField.kind),
        };
      }),
  ];
};

export function getColumnsShowConfig(
  opportunityFields: $ReadOnlyArray<FieldType>,
): $ReadOnlyArray<Filter> {
  return opportunityFields
    .filter(opportunityField => opportunityField.fieldName !== 'associated_contacts_roles')
    .map(opportunityField => {
      return {
        value: opportunityField.fieldName || opportunityField.id,
        label: opportunityField.label,
        default: defaultColumns.includes(opportunityField.fieldName),
        sticky: opportunityField.fieldName === 'salesforce_id',
      };
    });
}
