/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import updateEventContact from 'graph/mutations/event_contact/updateEventContact';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import type { ContactRegistrationStatus_contact } from './__generated__/ContactRegistrationStatus_contact.graphql';
import type { ContactRegistrationStatus_event } from './__generated__/ContactRegistrationStatus_event.graphql';
import type { ContactRegistrationStatus_eventContact } from './__generated__/ContactRegistrationStatus_eventContact.graphql';

class ContactRegistrationStatus extends React.Component<{
  eventContact: ContactRegistrationStatus_eventContact,
  contact: ContactRegistrationStatus_contact,
  event: ContactRegistrationStatus_event,
  fieldSettings?: FieldType,
  updateColumnWidth: () => void,
}> {
  updateRegistrationStatus = registrationStatusId => {
    updateEventContact({
      eventId: this.props.event.id,
      contactId: this.props.contact.id,
      registrationStatusId,
    })
      .then(() => {
        this.props.updateColumnWidth();
      })
      .catch(showModernMutationError);
  };

  getError = (): ?string => {
    const { fieldSettings, eventContact } = this.props;

    if (fieldSettings && fieldSettings.required && eventContact.registrationStatus == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { fieldSettings, eventContact, updateColumnWidth } = this.props;
    return (
      <InlineSelectField
        options={fieldSettings ? fieldSettings.options : []}
        value={eventContact.registrationStatus && eventContact.registrationStatus.id}
        placeholder="Select Registration Status"
        onChange={this.updateRegistrationStatus}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
      />
    );
  }
}

export default createFragmentContainer(ContactRegistrationStatus, {
  eventContact: graphql`
    fragment ContactRegistrationStatus_eventContact on EventContactEdge {
      registrationStatus {
        id
        name
      }
    }
  `,
  contact: graphql`
    fragment ContactRegistrationStatus_contact on Contact {
      id
    }
  `,
  event: graphql`
    fragment ContactRegistrationStatus_event on Event {
      id
    }
  `,
});
