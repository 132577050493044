/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DashboardRoutes_org$ref: FragmentReference;
export type DashboardRoutes_org = {|
  +viewerCanImport: boolean,
  +viewerCanUpdate: boolean,
  +viewerCanViewEventRequestSubmissions: boolean,
  +$refType: DashboardRoutes_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "DashboardRoutes_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanImport",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanViewEventRequestSubmissions",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b6e2ad5b33d6dd573d99f5c23397eb6e';
module.exports = node;
