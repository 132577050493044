/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  removeStafferMutationResponse,
  removeStafferMutationVariables,
} from './__generated__/removeStafferMutation.graphql';

const mutation = graphql`
  mutation removeStafferMutation($input: RemoveStafferInput!) {
    removeStaffer(input: $input) {
      removedMembershipId
      event {
        ...StaffTableRenderer_totalCountEvent
      }
    }
  }
`;

export default function removeStaffer(args: {
  eventId: string,
  userId: string,
}): Promise<removeStafferMutationResponse> {
  const variables: removeStafferMutationVariables = {
    input: {
      eventId: args.eventId,
      userId: args.userId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedMembershipId',
        parentId: args.eventId,
        connections: [{ key: 'StaffTableRenderer_staffers' }, { field: 'staffers' }],
      }),
      removeRecordFromConnection({
        deletedIdField: 'removedMembershipId',
        parentId: args.userId,
        connections: [{ key: 'UserEvents_staffedEvents' }, { field: 'staffedEvents' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedMembershipId' }),
    ),
  });
}
