/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type UserAvatar_user$ref = any;
type UserEvents_user$ref = any;
type UserTeams_user$ref = any;
export type TshirtSize = "L" | "M" | "S" | "XL" | "XS";
export type UserStatus = "CONFIRMED" | "INVITED" | "NOT_INVITED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserView_user$ref: FragmentReference;
export type UserView_user = {|
  +id: string,
  +firstName: string,
  +lastName: string,
  +company: ?string,
  +title: ?string,
  +email: string,
  +phone: ?string,
  +officePhone: ?string,
  +tshirtSize: ?TshirtSize,
  +bio: ?string,
  +avatar: ?string,
  +admin: boolean,
  +hasTeamAccess: boolean,
  +inviteStatus: UserStatus,
  +lastSeenAt: ?any,
  +viewerCanUpdate: boolean,
  +viewerCanUpdateAdmin: boolean,
  +viewerCanUpdateEmail: boolean,
  +viewerCanRemove: boolean,
  +memberships: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +slug: string
      |}
    |}>
  |},
  +staffedEvents: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +event: {|
          +slug: string
        |}
      |}
    |}>
  |},
  +$fragmentRefs: UserAvatar_user$ref & UserEvents_user$ref & UserTeams_user$ref,
  +$refType: UserView_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "slug",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "UserView_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "company",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "officePhone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tshirtSize",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "bio",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avatar",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "admin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasTeamAccess",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "inviteStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastSeenAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateAdmin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateEmail",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanRemove",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UserAvatar_user",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UserEvents_user",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UserTeams_user",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "memberships",
      "storageKey": null,
      "args": null,
      "concreteType": "TeamMembershipConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "MembershipTeamEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Team",
              "plural": false,
              "selections": v0
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffedEvents",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffMembershipRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffMembershipRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StaffMembership",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "event",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Event",
                  "plural": false,
                  "selections": v0
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b5994035cf27bb5df9030908f878cd9';
module.exports = node;
