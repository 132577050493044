/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import type { ImportLogsFilenameColumn_importLog } from './__generated__/ImportLogsFilenameColumn_importLog.graphql';

type Props = {
  importLog: ImportLogsFilenameColumn_importLog,
};

const ImportLogsFilenameColumn = ({ importLog }: Props) => importLog.fileName;

export default createFragmentContainer(
  ImportLogsFilenameColumn,
  graphql`
    fragment ImportLogsFilenameColumn_importLog on ImportLog {
      fileName
    }
  `,
);
