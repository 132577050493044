/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import BriefViewSharedContext from '../BriefViewSharedContext';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const UserContainer = (props: { children: React.Node, className?: string }) => {
  const { className, children } = props;
  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <Container isShared={sharedView} className={className}>
          {children}
        </Container>
      )}
    </BriefViewSharedContext.Consumer>
  );
};

export default UserContainer;
