/**
 * @flow
 * @relayHash f70f80afcf44b26c163b1c289d1d95e1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StaffAccessLevel = "FULL" | "LIMITED" | "NONE";
export type ImportPeopleInput = {
  clientMutationId?: ?string,
  mappings: UserImportInput,
  fileName: string,
  fileContent: string,
  staffInvites?: ?StaffInviteInput,
  teamIds?: ?$ReadOnlyArray<string>,
};
export type UserImportInput = {
  firstName?: ?string,
  lastName?: ?string,
  email?: ?string,
  company?: ?string,
  title?: ?string,
  phone?: ?string,
  officePhone?: ?string,
  tshirtSize?: ?string,
  bio?: ?string,
};
export type StaffInviteInput = {
  eventIds: $ReadOnlyArray<string>,
  accessLevel: StaffAccessLevel,
};
export type importPeopleMutationVariables = {|
  input: ImportPeopleInput
|};
export type importPeopleMutationResponse = {|
  +importPeople: ?{|
    +uuid: string
  |}
|};
export type importPeopleMutation = {|
  variables: importPeopleMutationVariables,
  response: importPeopleMutationResponse,
|};
*/


/*
mutation importPeopleMutation(
  $input: ImportPeopleInput!
) {
  importPeople(input: $input) {
    uuid
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportPeopleInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "importPeople",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ImportPeopleInput!"
      }
    ],
    "concreteType": "ImportPeoplePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uuid",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "importPeopleMutation",
  "id": null,
  "text": "mutation importPeopleMutation(\n  $input: ImportPeopleInput!\n) {\n  importPeople(input: $input) {\n    uuid\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "importPeopleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "importPeopleMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2695104306efb2145b84f8767a01656b';
module.exports = node;
