/* @flow */
import React from 'react';
import styled from 'styled-components';

import countries from 'config/countries.json';

import type { Location } from 'utils/locations/locationTypes';
import isValidWebsite from 'utils/validators/isValidWebsite';

import Map, { type ControlSettings } from 'components/Map';
import Button from 'components/material/Button';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';

const Fields = styled.div`
  margin-right: 15px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

const ZipField = styled(TextField)`
  width: 90px;
  margin-left: 15px;
`;

const Form = styled.form`
  display: flex;
`;

const StyledMap = styled(Map)`
  width: 200px;
  height: 160px;
  flex-shrink: 0;
`;

const ResetLink = styled.a`
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  margin-top: 5px;
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

const Buttons = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
`;

type RequiredFields = 'name' | 'city';
const requiredFields: Array<RequiredFields> = ['name', 'city'];

export default class LocationForm extends React.PureComponent<
  {
    defaultLocation?: ?$Shape<Location>,
    onReset?: () => void,
    onSave: (location: Location) => Promise<any>,
    mapControlSettings?: ControlSettings,
  },
  { location: Location, missing: Array<RequiredFields>, validWebsite: boolean, loading: boolean },
> {
  state = {
    location: {
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal: '',
      country: 'United States',
      website: '',
      note: '',
      ...this.props.defaultLocation,
    },
    missing: [],
    validWebsite: true,
    loading: false,
  };

  handleCountryChange = (country: ?string) => {
    this.setState(state => ({ location: { ...state.location, country } }));
  };

  handleFieldChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const changes = { [event.target.name]: event.target.value };
    this.setState(state => ({ location: { ...state.location, ...changes } }));
  };

  handleNormalizeWebsite = () => {
    this.setState(state => {
      if (state.location.website) {
        if (
          !isValidWebsite(state.location.website) &&
          isValidWebsite(`http://${state.location.website}`)
        ) {
          return {
            location: { ...state.location, website: `http://${state.location.website}` },
          };
        }
      }
      return null;
    });
  };

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState(
      state => {
        const missing = requiredFields.filter(
          prop => !state.location[prop] || !state.location[prop].trim(),
        );
        const validWebsite = state.location.website ? isValidWebsite(state.location.website) : true;

        return { missing, validWebsite };
      },
      () => {
        if (this.state.missing.length === 0 && this.state.validWebsite) {
          this.setState({ loading: true });
          this.props.onSave(this.state.location).then(() => {
            this.setState({ loading: false });
          });
        }
      },
    );
  };

  render() {
    const { location } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Fields>
          <Row>
            <TextField
              label="Venue Name *"
              name="name"
              value={location.name}
              onChange={this.handleFieldChange}
              autoFocus
              error={this.state.missing.includes('name') ? 'Required' : ''}
            />
          </Row>
          <Row>
            <TextField
              label="Address 1"
              name="address1"
              value={location.address1}
              onChange={this.handleFieldChange}
            />
          </Row>
          <Row>
            <TextField
              label="Address 2"
              name="address2"
              value={location.address2}
              onChange={this.handleFieldChange}
            />
          </Row>
          <Row>
            <TextField
              label="City *"
              name="city"
              value={location.city}
              onChange={this.handleFieldChange}
              error={this.state.missing.includes('city') ? 'Required' : ''}
            />
          </Row>
          <Row>
            <TextField
              label="State"
              name="state"
              value={location.state}
              onChange={this.handleFieldChange}
            />
            <ZipField
              label="Zip"
              name="postal"
              value={location.postal}
              onChange={this.handleFieldChange}
            />
          </Row>
          <SelectField
            searchable
            label="Country"
            options={countries.map(c => ({ label: c, value: c }))}
            value={location.country}
            onChange={this.handleCountryChange}
          />
          <Row>
            <TextField
              label="Venue Website"
              name="website"
              value={location.website}
              onChange={this.handleFieldChange}
              error={this.state.validWebsite ? '' : 'Invalid url'}
              onBlur={this.handleNormalizeWebsite}
            />
          </Row>
          <TextField
            label="Note"
            name="note"
            value={location.note}
            onChange={this.handleFieldChange}
            multiline
          />
        </Fields>
        <Sidebar>
          <StyledMap location={location} controlSettings={this.props.mapControlSettings} />
          {this.props.onReset && (
            <ResetLink onClick={this.props.onReset}>
              <i className="fa fa-undo" /> Reset Address
            </ResetLink>
          )}
          <Buttons>
            <Button label="Save" primary type="submit" loading={this.state.loading} />
          </Buttons>
        </Sidebar>
      </Form>
    );
  }
}
