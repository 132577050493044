/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import updateEdgeInConnection from 'graph/updaters/updateEdgeInConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateEventContactMutationResponse,
  updateEventContactMutationVariables,
} from './__generated__/updateEventContactMutation.graphql';

const mutation = graphql`
  mutation updateEventContactMutation(
    $input: UpdateEventContactInput!
    $changingContactRegistrationsTotal: Boolean!
  ) {
    updateEventContact(input: $input) {
      eventContactEdge {
        registrationStatus {
          id
          name
        }
        attendanceStatus {
          id
          name
        }
        syncStatus {
          state
          lastSuccessAt
          errorMessage
        }
        node {
          id
        }
      }
      contactEventEdge {
        registrationStatus {
          id
          name
        }
        attendanceStatus {
          id
          name
        }
        syncStatus {
          state
          lastSuccessAt
          errorMessage
        }
        node {
          id
          contactRegistrationsTotal @include(if: $changingContactRegistrationsTotal)
        }
      }
    }
  }
`;

export default function updateEventContact(args: {
  eventId: string,
  contactId: string,
  registrationStatusId?: ?string,
  attendanceStatusId?: ?string,
}): Promise<updateEventContactMutationResponse> {
  const variables: updateEventContactMutationVariables = {
    input: { ...args },
    changingContactRegistrationsTotal: args.registrationStatusId !== undefined,
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      updateEdgeInConnection({
        parentId: args.eventId,
        edgeName: 'eventContactEdge',
        connections: [
          { key: 'ContactsTablePagination_eventContacts' },
          { key: 'RegistrationResultsTable_eventContacts' },
        ],
      }),
      updateEdgeInConnection({
        parentId: args.contactId,
        edgeName: 'contactEventEdge',
        connections: [{ key: 'ContactEventsList_contactEvents' }],
      }),
    ),
  });
}
