/* @flow */

export const vimeoRegex = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/;
export const youtubeRegex = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/;

export default function convertVideoEmbedUrl(url: string): string {
  if (vimeoRegex.test(url)) {
    return url.replace(vimeoRegex, 'https://player.vimeo.com/video/$1');
  }
  return url.replace(youtubeRegex, 'https://www.youtube.com/embed/$1');
}
