/* @flow */
import React from 'react';
import styled from 'styled-components';

import fonts from 'config/fonts';

import { Field } from 'components/material/SelectField';
import { fontStyles } from '../../../registrationFormDefaults';
import PropertyRow from '../../components/PropertyRow';
import RegistrationSelectField from '../../components/RegistrationSelectField';
import inputBoxStyled from '../../components/inputBoxStyled';

import SizeField from './SizeField';

const StyledRegistrationSelectField = styled(RegistrationSelectField)`
  width: 130px;
  > div:first-child {
    overflow: hidden;
    height: 35px;
    padding: 0 10px;
    border-radius: 3px;
    border: 1px solid #d9ddf5;
    font-size: 13px;
    line-height: 1em;
    background: #fff;
    color: #464d72;
    cursor: pointer;
    > i {
      font-size: 18px;
      color: #3e4858;
    }
  }
  ${Field} {
    ${props => inputBoxStyled(props)};
  }
`;

const StyledSizeField = styled(SizeField)`
  width: 75px;
`;

export default class Font extends React.PureComponent<
  {
    fontFamily: ?string,
    fontStyle?: ?string,
    fontSize?: ?number,
    onSaveFontProperties: ({
      fontFamily?: string,
      fontStyle?: string,
      fontSize?: number,
    }) => void,
    onChangeComponentProperty: ({ selectedComponentFontSize: number }) => void,
  },
  {
    fontFamilyFocused: boolean,
    fontStyleFocused: boolean,
  },
> {
  state = { fontFamilyFocused: false, fontStyleFocused: false };

  handleSaveFontFamily = (fontFamily: ?string) => {
    if (fontFamily != null) {
      this.props.onSaveFontProperties({ fontFamily });
    }
  };

  handleSaveFontStyle = (fontStyle: ?string) => {
    if (fontStyle != null) {
      this.props.onSaveFontProperties({ fontStyle });
    }
  };

  handleSaveFontSize = (fontSize: number) => {
    if (fontSize != null) {
      this.props.onSaveFontProperties({ fontSize });
    }
  };

  handleFontFamilyFocus = () => {
    this.setState({ fontFamilyFocused: true });
  };

  handleFontFamilyUnfocus = () => {
    this.setState({ fontFamilyFocused: false });
  };

  handleFontStyleFocus = () => {
    this.setState({ fontStyleFocused: true });
  };

  handleFontStyleUnfocus = () => {
    this.setState({ fontStyleFocused: false });
  };

  handleSizeChange = (value: number) => {
    this.props.onChangeComponentProperty({ selectedComponentFontSize: value });
  };

  render() {
    const { fontFamily, fontStyle, fontSize } = this.props;
    const { fontFamilyFocused, fontStyleFocused } = this.state;
    const showFontStyle = {}.hasOwnProperty.call(this.props, 'fontStyle');
    const showFontSize = {}.hasOwnProperty.call(this.props, 'fontSize');
    return (
      <>
        <PropertyRow label="Font">
          <StyledRegistrationSelectField
            options={fonts}
            value={fontFamily || 'Roboto'}
            onChange={this.handleSaveFontFamily}
            onShowOptions={this.handleFontFamilyFocus}
            onHideOptions={this.handleFontFamilyUnfocus}
            focused={fontFamilyFocused}
          />
        </PropertyRow>
        {showFontStyle && (
          <PropertyRow label="Font style">
            <StyledRegistrationSelectField
              options={fontStyles}
              value={fontStyle || 'normal'}
              onChange={this.handleSaveFontStyle}
              onShowOptions={this.handleFontStyleFocus}
              onHideOptions={this.handleFontStyleUnfocus}
              focused={fontStyleFocused}
            />
          </PropertyRow>
        )}
        {showFontSize && (
          <PropertyRow label="Font size">
            <StyledSizeField
              value={fontSize}
              onSave={this.handleSaveFontSize}
              onChange={this.handleSizeChange}
              onZeroValue={14}
            />
          </PropertyRow>
        )}
      </>
    );
  }
}
