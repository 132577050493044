/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type EventRequestQuestionFileExtension = "CSV" | "DOC" | "JPG" | "PDF" | "PNG" | "XLSX";
export type EventRequestQuestionMapping = "ATTACHMENTS" | "BOOTH" | "BOOTH_DIMENSIONS" | "BUDGETED_AMOUNT" | "DATE" | "DESCRIPTION" | "EXPENSE" | "FLOOR_PLAN" | "LEADER" | "LOCATION" | "NAME" | "NOTE" | "PLANNED_BUDGET" | "WEBSITE";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubmissionReviewFormValue_question$ref: FragmentReference;
export type SubmissionReviewFormValue_question = {|
  +id: string,
  +order: number,
  +mapping: ?EventRequestQuestionMapping,
  +mappingCustomField: ?{|
    +id: string,
    +label: string,
    +kind: CustomFieldKindType,
  |},
  +name: string,
  +description: ?string,
  +required: boolean,
  +textMinLength: ?number,
  +textMaxLength: ?number,
  +fileExtensions: $ReadOnlyArray<EventRequestQuestionFileExtension>,
  +expenseName: ?string,
  +selectShowOtherOption: ?boolean,
  +maxSelectionSize: ?number,
  +budgetCategory: ?{|
    +id: string,
    +name: string,
  |},
  +budgetCategories: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +selectOptions: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +selectUsers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +firstName: string,
        +lastName: string,
        +email: string,
        +$fragmentRefs: MaterialAvatar_user$ref,
      |}
    |}>
  |},
  +$refType: SubmissionReviewFormValue_question$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  v0,
  v1
];
return {
  "kind": "Fragment",
  "name": "SubmissionReviewFormValue_question",
  "type": "EventRequestQuestion",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "order",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mapping",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "mappingCustomField",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldType",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "label",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "kind",
          "args": null,
          "storageKey": null
        }
      ]
    },
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "required",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "textMinLength",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "textMaxLength",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileExtensions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "expenseName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "selectShowOtherOption",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "maxSelectionSize",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetCategory",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetCategory",
      "plural": false,
      "selections": v2
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetCategories",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetCategoryRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BudgetCategoryRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BudgetCategory",
              "plural": false,
              "selections": v2
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "selectOptions",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldOptionTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldOptionTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldOptionType",
              "plural": false,
              "selections": v2
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "selectUsers",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "firstName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "lastName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "email",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "MaterialAvatar_user",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f63aa2548af5abab32ee0e338348db6';
module.exports = node;
