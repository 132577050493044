/* @flow */
import React from 'react';
import type { Location } from 'react-router';
import styled from 'styled-components';

import Menu from 'components/page/Menu';
import MenuItem from 'components/page/MenuItem';

const Container = styled.div`
  height: 48px;
  border-bottom: 1px solid #eaebec;
  overflow: hidden;
`;

const StyledMenuItem = styled(MenuItem)`
  &:not(:first-child) {
    margin-left: 20px;
  }
`;

const StyledMenu = styled(Menu)`
  padding-left: 33px;
`;

export default class CompanyPageMenu extends React.Component<{
  pathPrefix: string,
  contactsCount: number,
  opportunitiesCount: number,
  opportunitiesSyncEnabled: boolean,
  location: Location,
}> {
  render() {
    const {
      pathPrefix,
      contactsCount,
      opportunitiesCount,
      opportunitiesSyncEnabled,
      location,
    } = this.props;

    const state = location.state;

    return (
      <Container>
        <StyledMenu>
          <StyledMenuItem to={{ pathname: `${pathPrefix}/profile`, state }} text="Profile" />
          <StyledMenuItem
            to={{ pathname: `${pathPrefix}/people`, state }}
            text={`Contacts (${contactsCount})`}
          />
          {opportunitiesSyncEnabled && (
            <StyledMenuItem
              to={{ pathname: `${pathPrefix}/opportunities`, state }}
              text={`Opportunities (${opportunitiesCount})`}
            />
          )}
        </StyledMenu>
      </Container>
    );
  }
}
