/* @flow */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Company from './Company';
import Contact from './Contact';
import OrgCompanies from './OrgCompanies';
import OrgContacts from './OrgContacts';
import OrgVendors from './OrgVendors';
import Vendor from './Vendor';

type Props = { pathPrefix: string };

const ContactsRoutes = ({ pathPrefix }: Props) => {
  return (
    <Switch>
      <Route exact path={`${pathPrefix}/people`} component={OrgContacts} />
      <Route exact path={`${pathPrefix}/companies`} component={OrgCompanies} />
      <Route exact path={`${pathPrefix}/vendors`} component={OrgVendors} />
      <Route path={`${pathPrefix}/people/:contact_slug`} component={Contact} />
      <Route path={`${pathPrefix}/companies/:company_id`} component={Company} />
      <Route path={`${pathPrefix}/vendors/:vendorId`} component={Vendor} />
      <Redirect from={pathPrefix} to={`${pathPrefix}/people`} />
    </Switch>
  );
};

export default ContactsRoutes;
