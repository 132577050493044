import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import classNames from 'classnames';
import AutosizeInput from 'react-input-autosize';
import { findDOMNode } from 'react-dom';
import { DragSource as dragSource, DropTarget as dropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import UIActions from 'actions/UIActions';

/* eslint-disable react/no-find-dom-node */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

@dropTarget(
  'SCHEDULE',
  {
    hover(props, monitor, component) {
      const { id: dragId, order: dragOrder } = monitor.getItem();
      const { id: hoverId, order: hoverOrder } = props.schedule;

      if (dragId === hoverId) {
        return;
      }
      const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (dragOrder < hoverOrder && hoverClientX < 20) return;
      if (dragOrder > hoverOrder && hoverClientX < hoverBoundingRect.width - 20) return;

      props.onDragPreview(dragId, hoverId);
    },
    drop(props) {
      props.onDrop();
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
)
@dragSource(
  'SCHEDULE',
  {
    beginDrag(props, monitor, component) {
      const el = findDOMNode(component);
      return {
        id: props.schedule.id,
        order: props.schedule.order,
        previewHTML: el.outerHTML,
        width: el.offsetWidth + 1,
        height: el.offsetHeight,
        parent: el.parentNode,
      };
    },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
)
class SchedulesHeaderItem extends React.Component {
  static propTypes = {
    viewerCanCreateSchedules: PropTypes.bool.isRequired,
    schedule: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
      viewerCanUpdate: PropTypes.bool.isRequired,
      viewerCanRemove: PropTypes.bool.isRequired,
    }).isRequired,
    active: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
    onPrint: PropTypes.func.isRequired,
    onPublish: PropTypes.func.isRequired,
    onICalClick: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    nextId: PropTypes.string,
    prevId: PropTypes.string,
    getNewOrder: PropTypes.func.isRequired,
  };

  state = {
    editing: false,
    editingValue: '',
    dropdownShown: false,
  };

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  handleClick = () => {
    this.props.onSelect(this.props.schedule.slug);
  };

  handleStartEditing = () => {
    if (this.props.schedule.viewerCanUpdate) {
      this.setState({ editing: true, editingValue: this.props.schedule.name });
    }
  };

  handleEditingBlur = () => {
    const { editingValue } = this.state;

    if (editingValue.trim() && editingValue !== this.props.schedule.name) {
      this.props.onUpdate(this.props.schedule.id, { name: editingValue });
    }

    this.setState({ editing: false });
  };

  handleEditingKeyDown = e => {
    if (e.which === 13) {
      e.target.blur();
    } else if (e.which === 27) {
      this.setState({ editing: false });
    }
  };

  handleEditingChange = e => {
    this.setState({ editingValue: e.target.value });
  };

  handleToggleDropdown = e => {
    if (e) e.stopPropagation();

    this.setState(prevState => ({ dropdownShown: !prevState.dropdownShown }));
  };

  handlePrintClick = () => {
    this.props.onPrint(this.props.schedule.id);
  };

  handleMoveLeft = () => {
    const { prevId, onUpdate, schedule, getNewOrder } = this.props;

    onUpdate(this.props.schedule.id, { order: getNewOrder(schedule.id, prevId) });
  };

  handleMoveRight = () => {
    const { nextId, onUpdate, schedule, getNewOrder } = this.props;

    onUpdate(this.props.schedule.id, { order: getNewOrder(schedule.id, nextId) });
  };

  handleDeleteClick = () => {
    UIActions.showWindow('modal', {
      centered: true,
      noClose: true,
      className: 'event-remove-window',
      title: 'Are you sure?',
      message: 'Once you delete a Schedule, it is gone for good.',
      cancelButton: {
        text: 'Delete',
        action: () => {
          this.props.onRemove(this.props.schedule.id);
          UIActions.hideWindow('modal');
        },
      },
      confirmButton: {
        text: 'Cancel',
        action() {
          UIActions.hideWindow('modal');
        },
      },
    });
  };

  handleCopyClick = () => {
    this.props.onCopy(this.props.schedule.id);
  };

  handleICalClick = () => {
    this.props.onICalClick(this.props.schedule.token);
  };

  handlePublishClick = () => {
    this.props.onPublish(this.props.schedule.token);
  };

  render() {
    const {
      schedule,
      active,
      connectDragSource,
      connectDropTarget,
      isDragging,
      nextId,
      prevId,
    } = this.props;
    const { editing, editingValue, dropdownShown } = this.state;

    const component = (
      <div
        className={classNames('schedule-header-item', { active, dragging: isDragging })}
        onClick={this.handleClick}
      >
        <div className="schedule-header-item-name">
          {editing ? (
            <AutosizeInput
              type="text"
              value={editingValue}
              autoFocus
              onChange={this.handleEditingChange}
              onBlur={this.handleEditingBlur}
              onKeyDown={this.handleEditingKeyDown}
            />
          ) : (
            <span onDoubleClick={this.handleStartEditing}>{schedule.name}</span>
          )}
          <i className="fa fa-fw fa-caret-down" onClick={this.handleToggleDropdown} />
        </div>
        {dropdownShown && (
          <ul onMouseLeave={this.handleToggleDropdown} onClick={this.handleToggleDropdown}>
            {this.props.schedule.viewerCanUpdate && (
              <li onClick={this.handleStartEditing}>Rename</li>
            )}
            {this.props.viewerCanCreateSchedules && <li onClick={this.handleCopyClick}>Copy</li>}
            {this.props.schedule.viewerCanRemove && (
              <li onClick={this.handleDeleteClick}>Delete</li>
            )}
            {this.props.schedule.viewerCanUpdate && (
              <li onClick={this.handlePublishClick}>Publish to Web</li>
            )}
            <li onClick={this.handleICalClick}>Send to external calendar</li>
            <li onClick={this.handlePrintClick}>Export (PDF)</li>
            {this.props.schedule.viewerCanUpdate && (
              <React.Fragment>
                {(prevId || nextId) && <hr />}
                {prevId && <li onClick={this.handleMoveLeft}>Move Left</li>}
                {nextId && <li onClick={this.handleMoveRight}>Move Right</li>}
              </React.Fragment>
            )}
          </ul>
        )}
      </div>
    );

    if (this.props.schedule.viewerCanUpdate) {
      return connectDragSource(connectDropTarget(component));
    }

    return component;
  }
}

export default createFragmentContainer(
  SchedulesHeaderItem,
  graphql`
    fragment SchedulesHeaderItem_schedule on Schedule {
      id
      name
      slug
      order
      token
      viewerCanUpdate
      viewerCanRemove
    }
  `,
);
