/* @flow */
import { getAccessToken } from 'utils/Auth';

const checkImportStatus = (
  uuid: string,
  options?: { isRevert: boolean } = { isRevert: false },
): Promise<?{|
  +importedCount: number,
  +mergedCount: number,
  +errors: $ReadOnlyArray<{|
    +rowIndex: number,
    +error: string,
  |}>,
|}> => {
  return fetch(`/v1/${options.isRevert ? 'revert_status' : 'import_status'}/${uuid}`, {
    credentials: 'same-origin',
    headers: {
      Authorization: getAccessToken(),
    },
  })
    .then(response => {
      if (response.ok) return response.json();

      return response.json().then(body => Promise.reject(Error(body.errors.join('\n'))));
    })
    .then(body => {
      if (body.done) {
        if (options.isRevert) {
          return null;
        }

        return {
          importedCount: body.imported_count,
          mergedCount: body.updated_count,
          errors: body.errors.map(obj => ({ error: obj.error, rowIndex: obj.row_index })),
        };
      }

      return new Promise(resolve =>
        setTimeout(() => resolve(checkImportStatus(uuid, options)), 2000),
      );
    });
};

export default checkImportStatus;
