/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  ConfigureMarketoChannelMappingInput,
  configureMarketoChannelMappingMutationResponse,
} from './__generated__/configureMarketoChannelMappingMutation.graphql';

const mutation = graphql`
  mutation configureMarketoChannelMappingMutation($input: ConfigureMarketoChannelMappingInput!) {
    configureMarketoChannelMapping(input: $input) {
      marketoChannelMappingEdge {
        node {
          id
          channelId
          progressionStatus
          customFieldOption {
            id
          }
        }
      }
      removedMarketoChannelMappingId
    }
  }
`;

const configureMarketoChannelMapping = (
  input: ConfigureMarketoChannelMappingInput,
  marketoAccountId: string,
  marketoChannelMappingId?: string,
): Promise<configureMarketoChannelMappingMutationResponse> => {
  const removing = !input.customFieldOptionId;
  const optimisticResponse: ?configureMarketoChannelMappingMutationResponse = marketoChannelMappingId
    ? {
        configureMarketoChannelMapping: {
          marketoChannelMappingEdge: input.customFieldOptionId
            ? {
                node: {
                  id: marketoChannelMappingId,
                  channelId: input.channelId,
                  progressionStatus: input.progressionStatus,
                  customFieldOption: { id: input.customFieldOptionId },
                },
              }
            : null,
          removedMarketoChannelMappingId: removing ? marketoChannelMappingId : null,
        },
      }
    : null;

  return commitModernMutation({
    mutation,
    variables: { input },
    optimisticResponse,
    updater: removing
      ? removeRecordFromConnection({
          parentId: marketoAccountId,
          connections: [{ field: 'marketoChannelMappings' }],
          deletedIdField: 'removedMarketoChannelMappingId',
        })
      : !marketoChannelMappingId &&
        addRecordToConnection({
          parentId: marketoAccountId,
          edgeName: 'marketoChannelMappingEdge',
          connections: [{ field: 'marketoChannelMappings' }],
        }),
  });
};

export default configureMarketoChannelMapping;
