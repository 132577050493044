/* @flow */
import React from 'react';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { CompanyCreator_company } from './__generated__/CompanyCreator_company.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

type Props = {
  company: CompanyCreator_company,
};

const CompanyCreator = ({ company }: Props) => {
  if (!company || company.createdBy == null) {
    return null;
  }

  return (
    <Container>
      <MaterialAvatar radius={13} user={company.createdBy} />
      <TextWrapper>{fullNameOfUser(company.createdBy)}</TextWrapper>
    </Container>
  );
};

export default createFragmentContainer(
  CompanyCreator,
  graphql`
    fragment CompanyCreator_company on Company {
      createdBy {
        firstName
        lastName
        email
        ...MaterialAvatar_user
      }
    }
  `,
);
