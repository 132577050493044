/* @flow */
import React from 'react';
import styled from 'styled-components';
import pick from 'lodash/pick';

import Remove from 'images/remove.svg';
import TextField from 'components/material/TextField';

import { type InviteFields } from './types';
import { validateInvite } from './validation';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 89px;
  padding: 15px;
  border-radius: 2px;
  background-color: #f4f5f7;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Column = styled.div`
  flex: 1 1 100px;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const RemoveIcon = styled(Remove)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

export default class InviteListRow extends React.Component<{
  index: number,
  invite: InviteFields,
  emailFirst?: boolean,
  disableEditingInitial?: boolean,
  onChange: (index: number, fields: InviteFields) => void,
  onRemove?: ?(index: number) => void,
}> {
  initialInvite: InviteFields = { ...this.props.invite };

  handleChangeEmail = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.handleChange('email', e.target.value);
  };

  handleChangeFirstName = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.handleChange('firstName', e.target.value);
  };

  handleChangeLastName = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.handleChange('lastName', e.target.value);
  };

  handleChange = (field: $Keys<InviteFields>, value: string) => {
    this.props.onChange(this.props.index, Object.assign({}, this.props.invite, { [field]: value }));
  };

  handleRemove = () => {
    if (this.props.onRemove) this.props.onRemove(this.props.index);
  };

  handleBlurEmail = () => {
    const fields = pick(validateInvite(this.props.invite), 'email', 'emailError');
    this.props.onChange(this.props.index, Object.assign({}, this.props.invite, fields));

    this.validateBlankInvite();
  };

  handleBlurFirstName = () => {
    const fields = pick(validateInvite(this.props.invite), 'firstName', 'firstNameError');
    this.props.onChange(this.props.index, Object.assign({}, this.props.invite, fields));

    this.validateBlankInvite();
  };

  handleBlurLastName = () => {
    const fields = pick(validateInvite(this.props.invite), 'lastName', 'lastNameError');
    this.props.onChange(this.props.index, Object.assign({}, this.props.invite, fields));

    this.validateBlankInvite();
  };

  validateBlankInvite = () => {
    if (
      !this.props.invite.email.trim() &&
      !this.props.invite.firstName.trim() &&
      !this.props.invite.lastName.trim()
    ) {
      this.props.onChange(this.props.index, validateInvite(this.props.invite));
    }
  };

  render() {
    const { invite, emailFirst, disableEditingInitial, onRemove } = this.props;

    const emailColumn = (
      <Column>
        <TextField
          label="Email"
          value={invite.email}
          onChange={this.handleChangeEmail}
          error={invite.emailError}
          onBlur={this.handleBlurEmail}
          disabled={disableEditingInitial && !!this.initialInvite.email}
        />
      </Column>
    );

    return (
      <Row>
        {emailFirst && emailColumn}
        <Column>
          <TextField
            label="First Name"
            value={invite.firstName}
            onChange={this.handleChangeFirstName}
            error={invite.firstNameError}
            onBlur={this.handleBlurFirstName}
            disabled={disableEditingInitial && !!this.initialInvite.firstName}
          />
        </Column>
        <Column>
          <TextField
            label="Last Name"
            value={invite.lastName}
            onChange={this.handleChangeLastName}
            error={invite.lastNameError}
            onBlur={this.handleBlurLastName}
            disabled={disableEditingInitial && !!this.initialInvite.lastName}
          />
        </Column>
        {!emailFirst && emailColumn}

        {onRemove && <RemoveIcon onClick={this.handleRemove} />}
      </Row>
    );
  }
}
