/**
 * @flow
 * @relayHash 7f29a18f54e066fc7b737ec5703780e6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EventMultiselectQueryVariables = {|
  eventIds: $ReadOnlyArray<string>
|};
export type EventMultiselectQueryResponse = {|
  +selectedEvents: $ReadOnlyArray<?{|
    +id: string,
    +name?: string,
    +startDate?: ?any,
    +endDate?: ?any,
    +tz?: string,
    +primaryLocation?: ?{|
      +name: string,
      +city: string,
      +state: ?string,
      +country: ?string,
    |},
  |}>
|};
export type EventMultiselectQuery = {|
  variables: EventMultiselectQueryVariables,
  response: EventMultiselectQueryResponse,
|};
*/


/*
query EventMultiselectQuery(
  $eventIds: [ID!]!
) {
  selectedEvents: nodes(ids: $eventIds) {
    __typename
    id
    ... on Event {
      id
      name
      startDate
      endDate
      tz
      primaryLocation {
        name
        city
        state
        country
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventIds",
    "type": "[ID!]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "eventIds",
    "type": "[ID!]!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startDate",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "endDate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tz",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "country",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventMultiselectQuery",
  "id": null,
  "text": "query EventMultiselectQuery(\n  $eventIds: [ID!]!\n) {\n  selectedEvents: nodes(ids: $eventIds) {\n    __typename\n    id\n    ... on Event {\n      id\n      name\n      startDate\n      endDate\n      tz\n      primaryLocation {\n        name\n        city\n        state\n        country\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventMultiselectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "selectedEvents",
        "name": "nodes",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": true,
        "selections": [
          v2,
          {
            "kind": "InlineFragment",
            "type": "Event",
            "selections": [
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "primaryLocation",
                "storageKey": null,
                "args": null,
                "concreteType": "Location",
                "plural": false,
                "selections": [
                  v3,
                  v7,
                  v8,
                  v9
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventMultiselectQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "selectedEvents",
        "name": "nodes",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "InlineFragment",
            "type": "Event",
            "selections": [
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "primaryLocation",
                "storageKey": null,
                "args": null,
                "concreteType": "Location",
                "plural": false,
                "selections": [
                  v3,
                  v7,
                  v8,
                  v9,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4060f82e2301defefcf704ba65ccfb4b';
module.exports = node;
