/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Footer = styled.div`
  padding: 0;
  margin-top: 30px;
  color: #465261;
  text-align: center;
  a {
    color: #3aa9da;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
`;

export default class AuthFooter extends React.PureComponent<{
  children: React.Node,
}> {
  render() {
    return <Footer>{this.props.children}</Footer>;
  }
}
