/* @flow */
import * as React from 'react';

import countries from 'config/countries.json';
import usStates from 'config/us_states.json';

import type { FieldType } from 'utils/customization/types';
import renderFilterByKind from 'utils/filters/renderFilterByKind';
import {
  type CustomFieldParam,
  type DateRangeParam,
  type NumberRangeParam,
} from 'utils/routing/parseTypedQueryString';

import AutocompleteFilterItem from 'components/material/Filters/Advanced/AutocompleteFilterItem';
import DateFilterItem from 'components/material/Filters/Advanced/DateFilterItem';
import { type FilterValueChangeParam } from 'components/material/Filters/Advanced/FilterItems';
import TextFilterItem from 'components/material/Filters/Advanced/TextFilterItem';
import UserFilterItem from 'components/material/Filters/Advanced/UserFilterItem';
import type { ParsedVendorsFilters } from 'components/Vendors/lib/parseVendorsFilters';

// Flow will provide this type later as an input variable
export type VendorFilterInputs = {
  [key: string]: CustomFieldParam,
  createdAt: ?DateRangeParam,
  creatorIds: ?$ReadOnlyArray<string>,
  updatedAt: ?DateRangeParam,
  updaterIds: ?$ReadOnlyArray<string>,
  cities: ?$ReadOnlyArray<string>,
  states: ?$ReadOnlyArray<string>,
  countries: ?$ReadOnlyArray<string>,
  sort: string,
  direction: 'ASC' | 'DESC',
  query: ?string,
  customFilters: $ReadOnlyArray<?{
    customFieldId: string,
    dateParam?: ?DateRangeParam,
    numberParam?: ?NumberRangeParam,
    booleanParam?: ?boolean,
    optionIds?: ?$ReadOnlyArray<string>,
  }>,
  customFieldSortId: ?string,
};

export default function renderVendorFilter(
  customField: FieldType,
  filters: ParsedVendorsFilters,
  onFilterChange: (name: string, value: FilterValueChangeParam) => void,
): React.Node {
  switch (customField.fieldName) {
    case 'city':
      return (
        <TextFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
        />
      );
    case 'state':
      return (
        <AutocompleteFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
          options={Object.entries(usStates).map(([key, value]) => [String(value), String(key)])}
          allowCustomValue
          autoFocus
        />
      );
    case 'country':
      return (
        <AutocompleteFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
          options={['United States', ...countries.filter(c => c !== 'United States')]}
          autoFocus
        />
      );
    case 'created_by':
    case 'updated_by':
      return (
        <UserFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValues={filters[customField.fieldName]}
        />
      );
    case 'created_at':
    case 'updated_at':
      return (
        <DateFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.fieldName}
          onChange={onFilterChange}
          activeValue={filters[customField.fieldName]}
          hideShortcutOptions
        />
      );
    default:
      return renderFilterByKind(customField, filters[customField.id], onFilterChange);
  }
}
