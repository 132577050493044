/* @flow */
import React from 'react';
import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';

import convertDateToLocal from 'utils/date/convertDateTimeToLocal';

import CalendarCaption from '../Calendar/CalendarCaption';
import CalendarNavbar from '../Calendar/CalendarNavbar';
import calendarStyles from '../Calendar/calendarStyles';

const Root = styled.div`
  margin: 15px 20px;
  ${calendarStyles};
`;

const modifiers = {
  weekend: { daysOfWeek: [0, 6] },
};

export default class RangeCalendar extends React.PureComponent<
  {
    tz: string,
    startDate: ?string,
    endDate: ?string,
    onChange: (startDate: ?string, endDate: ?string) => void,
  },
  {
    displayMonth: Date,
    rangeStartDay: ?Date,
    hoveringDay: ?Date,
    prevStartDate: ?string,
    prevEndDate: ?string,
    changed: boolean,
  },
> {
  static getDerivedStateFromProps(
    props: $PropertyType<RangeCalendar, 'props'>,
    state: $PropertyType<RangeCalendar, 'state'>,
  ) {
    if (state.prevStartDate === props.startDate && state.prevEndDate === props.endDate) return null;

    const startDisplayMonth = moment.tz(props.startDate || undefined, props.tz).startOf('day');
    const endDisplayMonth = moment.tz(props.endDate || undefined, props.tz).startOf('day');

    return {
      displayMonth:
        startDisplayMonth.isSame(state.displayMonth, 'month') ||
        startDisplayMonth.clone().subtract(1, 'month').isSame(state.displayMonth, 'month') ||
        endDisplayMonth.isSame(state.displayMonth, 'month') ||
        endDisplayMonth.clone().subtract(1, 'month').isSame(state.displayMonth, 'month')
          ? state.displayMonth
          : new Date(convertDateToLocal(startDisplayMonth)),
      rangeStartDay: props.endDate == null ? state.rangeStartDay : null,
      hoveringDay: props.endDate == null ? state.hoveringDay : null,
      prevStartDate: props.startDate,
      prevEndDate: props.endDate,
    };
  }

  state = {
    displayMonth: new Date(
      convertDateToLocal(
        moment.tz(this.props.startDate || undefined, this.props.tz).startOf('day'),
      ),
    ),
    rangeStartDay: null,
    hoveringDay: null,
    // eslint-disable-next-line react/no-unused-state
    prevStartDate: this.props.startDate,
    // eslint-disable-next-line react/no-unused-state
    prevEndDate: this.props.endDate,
    changed: false,
  };

  handleChange = (startDate: ?string, endDate: ?string) => {
    this.props.onChange(startDate, endDate);

    this.setState(state => (state.changed ? null : { changed: true }));
  };

  handleClickDay = (day: Date) => {
    const localeDate = moment(day);

    if (!this.selectingFirstDay()) {
      const range = sortBy(
        this.selectedRange().map(date => {
          return moment(date).format();
        }),
      );
      this.handleChange(...range);

      this.setState({ rangeStartDay: null, hoveringDay: null });
    } else if (
      this.state.changed &&
      this.props.startDate &&
      this.props.endDate &&
      !moment(this.props.startDate).tz(this.props.tz).isSame(this.props.endDate, 'date') &&
      moment(this.props.endDate).tz(this.props.tz).isBefore(day)
    ) {
      const dateInTimezone = moment(day).tz(this.props.tz);
      localeDate.utcOffset(dateInTimezone.utcOffset(), true);
      this.handleChange(this.props.startDate, localeDate.format());
    } else {
      const dateInTimezone = moment(day).tz(this.props.tz);
      localeDate.utcOffset(dateInTimezone.utcOffset(), true);
      this.handleChange(localeDate.format(), null);
      this.setState({ rangeStartDay: localeDate.format(), hoveringDay: localeDate.format() });
    }
  };

  handleMouseEnterDay = (day: Date) => {
    if (this.selectingFirstDay()) return;
    const localeDate = moment(day);
    const dateInTimezone = moment(day).tz(this.props.tz);
    localeDate.utcOffset(dateInTimezone.utcOffset(), true);
    this.setState({ hoveringDay: localeDate.format() });
  };

  handleMouseLeave = () => {
    this.setState(state => (state.hoveringDay ? { hoveringDay: null } : null));
  };

  selectingFirstDay = () => !this.state.rangeStartDay && !this.state.hoveringDay;

  selectedRange = () => {
    return sortBy([
      this.state.rangeStartDay || this.props.startDate,
      this.state.hoveringDay || this.props.endDate || this.props.startDate,
    ]);
  };

  handleChangeDisplayMonth = (displayMonth: Date) => {
    this.setState({ displayMonth });
  };

  render() {
    const range = this.selectedRange().map(date => {
      if (!date) {
        return null;
      }
      const currentLocaleDate = moment(date);
      const dateInTimezone = moment(date).tz(this.props.tz);
      dateInTimezone.utcOffset(currentLocaleDate.utcOffset(), true);
      return new Date(convertDateToLocal(dateInTimezone));
    });
    return (
      <Root>
        <DayPicker
          fixedWeeks
          showOutsideDays={false}
          numberOfMonths={2}
          modifiers={{
            ...modifiers,
            intermediate: { after: range[0], before: range[1] },
            rangeStart: range[0],
            rangeEnd: range[1],
          }}
          month={this.state.displayMonth}
          selectedDays={{ from: range[0], to: range[1] }}
          onDayClick={this.handleClickDay}
          onMonthChange={this.handleChangeDisplayMonth}
          onDayFocus={this.handleMouseEnterDay}
          onDayMouseEnter={this.handleMouseEnterDay}
          onDayMouseLeave={this.handleMouseLeave}
          captionElement={props => (
            <CalendarCaption
              onChangeDisplayMonth={this.handleChangeDisplayMonth}
              displayMonth={this.state.displayMonth}
              tz={this.props.tz}
              {...props}
            />
          )}
          navbarElement={CalendarNavbar}
        />
      </Root>
    );
  }
}
