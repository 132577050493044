/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import type { EventVenueCountry_event } from './__generated__/EventVenueCountry_event.graphql';

const EventVenueCountry = (props: { event: EventVenueCountry_event }) => {
  const primaryLocation = props.event.primaryLocation;
  return primaryLocation ? primaryLocation.country : null;
};

export default createFragmentContainer(
  EventVenueCountry,
  graphql`
    fragment EventVenueCountry_event on Event {
      primaryLocation {
        country
      }
    }
  `,
);
