/**
 * @flow
 * @relayHash c9cb2582f205d7af859c118521f70d9f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InviteWindowContents_me$ref = any;
type InviteWindowContents_org$ref = any;
export type InviteWindowQueryVariables = {||};
export type InviteWindowQueryResponse = {|
  +me: {|
    +$fragmentRefs: InviteWindowContents_me$ref
  |},
  +org: {|
    +$fragmentRefs: InviteWindowContents_org$ref
  |},
|};
export type InviteWindowQuery = {|
  variables: InviteWindowQueryVariables,
  response: InviteWindowQueryResponse,
|};
*/


/*
query InviteWindowQuery {
  me {
    ...InviteWindowContents_me
    id
  }
  org {
    ...InviteWindowContents_org
    id
  }
}

fragment InviteWindowContents_me on User {
  ...InviteToOrgContents_me
}

fragment InviteWindowContents_org on Org {
  id
  viewerCanManageTeamMembers
  viewerCanManageUsers
  subscription {
    fatmLimit
    fatmCount
    id
  }
  teamMembers: users(hasTeamAccess: true) {
    edges {
      node {
        email
        id
      }
    }
  }
  ...InviteToOrgContents_org
}

fragment InviteToOrgContents_org on Org {
  viewerCanManageTeamMembers
  viewerCanManageStaff
}

fragment InviteToOrgContents_me on User {
  admin
  memberships {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "InviteWindowQuery",
  "id": null,
  "text": "query InviteWindowQuery {\n  me {\n    ...InviteWindowContents_me\n    id\n  }\n  org {\n    ...InviteWindowContents_org\n    id\n  }\n}\n\nfragment InviteWindowContents_me on User {\n  ...InviteToOrgContents_me\n}\n\nfragment InviteWindowContents_org on Org {\n  id\n  viewerCanManageTeamMembers\n  viewerCanManageUsers\n  subscription {\n    fatmLimit\n    fatmCount\n    id\n  }\n  teamMembers: users(hasTeamAccess: true) {\n    edges {\n      node {\n        email\n        id\n      }\n    }\n  }\n  ...InviteToOrgContents_org\n}\n\nfragment InviteToOrgContents_org on Org {\n  viewerCanManageTeamMembers\n  viewerCanManageStaff\n}\n\nfragment InviteToOrgContents_me on User {\n  admin\n  memberships {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "InviteWindowQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "InviteWindowContents_me",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "InviteWindowContents_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "InviteWindowQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "admin",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "memberships",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamMembershipConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "MembershipTeamEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageTeamMembers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageUsers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fatmLimit",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fatmCount",
                "args": null,
                "storageKey": null
              },
              v0
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "teamMembers",
            "name": "users",
            "storageKey": "users(hasTeamAccess:true)",
            "args": [
              {
                "kind": "Literal",
                "name": "hasTeamAccess",
                "value": true,
                "type": "Boolean"
              }
            ],
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      v0
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageStaff",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4c9be3aa01017b5e22308d4ba41e188e';
module.exports = node;
