/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RequestForm_requestSubmission$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestSharedFormContainer_requestSubmission$ref: FragmentReference;
export type EventRequestSharedFormContainer_requestSubmission = {|
  +event: {|
    +tz: string
  |},
  +$fragmentRefs: RequestForm_requestSubmission$ref,
  +$refType: EventRequestSharedFormContainer_requestSubmission$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestSharedFormContainer_requestSubmission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tz",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "RequestForm_requestSubmission",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2f5a01e8e533f1dbcde054f7d09c7852';
module.exports = node;
