/**
 * @flow
 * @relayHash 6a68efe5141024fa8f5d6eda28075316
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserOptionRow_user$ref = any;
export type UserSelectQueryVariables = {|
  eventId: string,
  query: string,
  includeEvent: boolean,
|};
export type UserSelectQueryResponse = {|
  +org?: {|
    +viewerCanManageStaff: boolean,
    +users: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +email: string,
          +$fragmentRefs: UserOptionRow_user$ref,
        |}
      |}>
    |},
  |},
  +event?: ?{|
    +viewerCanAddStaff?: boolean,
    +staffers?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +user: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +email: string,
            +$fragmentRefs: UserOptionRow_user$ref,
          |}
        |}
      |}>
    |},
    +team?: {|
      +users: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +email: string,
            +$fragmentRefs: UserOptionRow_user$ref,
          |}
        |}>
      |}
    |},
  |},
|};
export type UserSelectQuery = {|
  variables: UserSelectQueryVariables,
  response: UserSelectQueryResponse,
|};
*/


/*
query UserSelectQuery(
  $eventId: ID!
  $query: String!
  $includeEvent: Boolean!
) {
  org @skip(if: $includeEvent) {
    viewerCanManageStaff
    users(first: 3, query: $query) {
      edges {
        node {
          id
          firstName
          lastName
          email
          ...UserOptionRow_user
        }
      }
    }
    id
  }
  event: node(id: $eventId) @include(if: $includeEvent) {
    __typename
    ... on Event {
      viewerCanAddStaff
      staffers(first: 3, query: $query) {
        edges {
          node {
            user {
              id
              firstName
              lastName
              email
              ...UserOptionRow_user
            }
            id
          }
        }
      }
      team {
        users(first: 3, query: $query) {
          edges {
            node {
              id
              firstName
              lastName
              email
              ...UserOptionRow_user
            }
          }
        }
        id
      }
    }
    id
  }
}

fragment UserOptionRow_user on User {
  id
  firstName
  lastName
  email
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeEvent",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanManageStaff",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query",
    "type": "String"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v7 = [
  v3,
  v4,
  v5,
  v6,
  {
    "kind": "FragmentSpread",
    "name": "UserOptionRow_user",
    "args": null
  }
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "users",
  "storageKey": null,
  "args": v2,
  "concreteType": "UserRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": v7
        }
      ]
    }
  ]
},
v9 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId",
    "type": "ID!"
  }
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanAddStaff",
  "args": null,
  "storageKey": null
},
v11 = [
  v3,
  v4,
  v5,
  v6,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "avatar",
    "args": null,
    "storageKey": null
  }
],
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "users",
  "storageKey": null,
  "args": v2,
  "concreteType": "UserRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": v11
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserSelectQuery",
  "id": null,
  "text": "query UserSelectQuery(\n  $eventId: ID!\n  $query: String!\n  $includeEvent: Boolean!\n) {\n  org @skip(if: $includeEvent) {\n    viewerCanManageStaff\n    users(first: 3, query: $query) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          email\n          ...UserOptionRow_user\n        }\n      }\n    }\n    id\n  }\n  event: node(id: $eventId) @include(if: $includeEvent) {\n    __typename\n    ... on Event {\n      viewerCanAddStaff\n      staffers(first: 3, query: $query) {\n        edges {\n          node {\n            user {\n              id\n              firstName\n              lastName\n              email\n              ...UserOptionRow_user\n            }\n            id\n          }\n        }\n      }\n      team {\n        users(first: 3, query: $query) {\n          edges {\n            node {\n              id\n              firstName\n              lastName\n              email\n              ...UserOptionRow_user\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment UserOptionRow_user on User {\n  id\n  firstName\n  lastName\n  email\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserSelectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              v1,
              v8
            ]
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "event",
            "name": "node",
            "storageKey": null,
            "args": v9,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "Event",
                "selections": [
                  v10,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "staffers",
                    "storageKey": null,
                    "args": v2,
                    "concreteType": "StaffMembershipRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembershipRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffMembership",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "User",
                                "plural": false,
                                "selections": v7
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "team",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v8
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSelectQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              v1,
              v12,
              v3
            ]
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "event",
            "name": "node",
            "storageKey": null,
            "args": v9,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              v3,
              {
                "kind": "InlineFragment",
                "type": "Event",
                "selections": [
                  v10,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "staffers",
                    "storageKey": null,
                    "args": v2,
                    "concreteType": "StaffMembershipRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembershipRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StaffMembership",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "user",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "User",
                                "plural": false,
                                "selections": v11
                              },
                              v3
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "team",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v12,
                      v3
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7bbda74d193e7bec0b4baa603587f5a9';
module.exports = node;
