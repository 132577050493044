/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  savePublicResourceTokenMutationResponse,
  savePublicResourceTokenMutationVariables,
} from './__generated__/savePublicResourceTokenMutation.graphql';

const mutation = graphql`
  mutation savePublicResourceTokenMutation($input: SavePublicResourceTokenInput!) {
    savePublicResourceToken(input: $input) {
      publicResourceToken {
        id
        token
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function savePublicResourceToken(
  publicResourceTokenId: string,
  token: string,
): Promise<savePublicResourceTokenMutationResponse> {
  const variables: savePublicResourceTokenMutationVariables = {
    input: { publicResourceTokenId, token },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      savePublicResourceToken: {
        publicResourceToken: {
          id: publicResourceTokenId,
          token,
        },
      },
    },
  });
}
