/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import APISettings from './APISettings';

import { type CircaAPIQueryResponse } from './__generated__/CircaAPIQuery.graphql';

const query = graphql`
  query CircaAPIQuery {
    org {
      ...APISettings_org
    }
  }
`;

export default class CircaAPI extends React.Component<{}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(props: CircaAPIQueryResponse) => <APISettings org={props.org} />}
      />
    );
  }
}
