/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type VendorPageRoutes_vendor$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorPage_vendor$ref: FragmentReference;
export type VendorPage_vendor = {|
  +name: string,
  +id: string,
  +title: ?string,
  +org: {|
    +id: string
  |},
  +viewerCanRemove: boolean,
  +viewerCanUpdate: boolean,
  +events: {|
    +totalCount: number
  |},
  +$fragmentRefs: VendorPageRoutes_vendor$ref,
  +$refType: VendorPage_vendor$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "VendorPage_vendor",
  "type": "Vendor",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "VendorPageRoutes_vendor",
      "args": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "org",
      "storageKey": null,
      "args": null,
      "concreteType": "Org",
      "plural": false,
      "selections": [
        v0
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanRemove",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "events",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '380714f46f9c9e63ea91269035d4843b';
module.exports = node;
