/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type SalesforceKindType = "CONTACT" | "EVENT" | "EVENT_CONTACT" | "LEAD" | "OPPORTUNITY";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesforceMappingRow_salesforceMapping$ref: FragmentReference;
export type SalesforceMappingRow_salesforceMapping = {|
  +id: string,
  +kind: SalesforceKindType,
  +salesforceField: string,
  +toSalesforce: boolean,
  +fromSalesforce: boolean,
  +$refType: SalesforceMappingRow_salesforceMapping$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SalesforceMappingRow_salesforceMapping",
  "type": "SalesforceMapping",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "kind",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "salesforceField",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "toSalesforce",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fromSalesforce",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b228356589b763cb8e751c4081432888';
module.exports = node;
