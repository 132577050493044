/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import taskFields from 'config/brief/taskFields';

import { type BriefTemplateMutationVariables } from 'graph/mutations/briefTemplate/updateBriefTemplate';

import CheckBox from 'components/material/CheckBox';

const StyledCheckBox = styled(CheckBox)`
  margin-right: 15px;
  &:not(:first-child) {
    margin-top: 20px;
  }
  > div:first-child {
    border-radius: 2px;
  }
`;

import type { Tasks_template } from './__generated__/Tasks_template.graphql';

class Tasks extends React.PureComponent<{
  template: Tasks_template,
  onTemplateAttributeChange: (input: BriefTemplateMutationVariables) => void,
}> {
  handleFieldCheck = (fieldName: string, enabled: boolean) => {
    this.props.onTemplateAttributeChange({
      taskFields: this.props.template.taskFields.map(field => ({
        name: field.name,
        enabled: field.name === fieldName ? enabled : field.enabled,
      })),
    });
  };

  render() {
    return this.props.template.taskFields.map(field => (
      <StyledCheckBox
        key={field.name}
        label={taskFields[field.name]}
        checked={field.enabled}
        disabled={field.name === 'title'}
        onChange={(checked: boolean) => this.handleFieldCheck(field.name, checked)}
      />
    ));
  }
}

export default createFragmentContainer(
  Tasks,
  graphql`
    fragment Tasks_template on BriefTemplate {
      taskFields {
        name
        enabled
      }
    }
  `,
);
