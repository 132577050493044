/* @flow */
import moment from 'moment-timezone';

import formatDate from './formatDate';

export default function formatDateRange(startDate: moment, endDate: ?moment) {
  if (endDate == null || endDate.isSame(startDate, 'day')) {
    return formatDate(startDate);
  }
  if (startDate.isSame(endDate, 'month')) {
    return `${startDate.format('MMM D')} - ${endDate.format('D, YYYY')}`;
  }

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}
