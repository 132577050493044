/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import personalizationDefaults from 'config/personalizationDefaults';

import ContactFormBuilder from 'components/ContactFormBuilder';

import getFontLabel from '../../../lib/getFontLabel';
import type { PageComponentKindType } from '../../pageComponentsConfig';
import type { SelectedComponent } from '../../RegistrationCreateContent';
import {
  defaultButtonTextColor,
  defaultTextColor,
  fontStyles,
  registerButtonCopy,
} from '../../registrationFormDefaults';
import generateFontFromProps from '../generateFontFromProps';

import type { FormComponent_componentProps } from './__generated__/FormComponent_componentProps.graphql';
import type { FormComponent_org } from './__generated__/FormComponent_org.graphql';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  text-align: ${props => props.alignment || props.componentProps.alignment};
`;

const Content = styled.div`
  display: inline-block;
  max-width: 100%;
  width: ${props =>
    `${props.componentProps.width}${
      props.componentProps.widthMeasurement === 'PERCENTAGE' ? '%' : 'px'
    }`};
  text-align: initial;
`;

const StyledButton = styled.button`
  display: block;
  min-width: 222px;
  max-width: 400px;
  height: 45px;
  margin: 30px auto 0;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  font: ${props => generateFontFromProps(props.fontProps)};
  background: ${props => props.formComponent.buttonColor || props.orgPrimaryColor};
  color: ${props => props.formComponent.buttonTextColor || defaultButtonTextColor};
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

const FormComponent = ({
  org,
  componentProps: { formComponent },
  selectedComponent,
  draggedComponentKind,
}: {
  org: FormComponent_org,
  componentProps: FormComponent_componentProps,
  selectedComponent: SelectedComponent,
  draggedComponentKind: ?PageComponentKindType,
}) => {
  if (!formComponent) return null;
  const componentProps = selectedComponent
    ? {
        ...formComponent,
        width: selectedComponent.selectedComponentWidth,
      }
    : formComponent;
  return (
    <Container componentProps={componentProps}>
      <Content componentProps={componentProps}>
        {draggedComponentKind && <Cover />}
        <ContactFormBuilder
          contactForm={formComponent.contactForm}
          org={org}
          defaultFont={getFontLabel(
            formComponent.fontFamily || org.settings.font || personalizationDefaults.font,
          )}
          theme={{
            textFont: formComponent.fontFamily || org.settings.font || personalizationDefaults.font,
            textColor: formComponent.color || defaultTextColor,
            textStyle: formComponent.fontStyle || fontStyles[0].value,
          }}
        />

        <StyledButton
          type="button"
          formComponent={formComponent}
          orgPrimaryColor={org.settings.primaryColor}
          fontProps={{
            fontFamily:
              formComponent.fontFamily || org.settings.font || personalizationDefaults.font,
            fontStyle: formComponent.fontStyle || fontStyles[0].value,
            fontSize: 16,
          }}
        >
          {formComponent.buttonCopy || registerButtonCopy}
        </StyledButton>
      </Content>
    </Container>
  );
};

export default createFragmentContainer(FormComponent, {
  org: graphql`
    fragment FormComponent_org on Org {
      settings {
        font
        primaryColor
      }
      ...ContactFormBuilder_org
    }
  `,
  componentProps: graphql`
    fragment FormComponent_componentProps on RegistrationPageComponent {
      formComponent {
        fontFamily
        fontStyle
        color
        buttonCopy
        buttonColor
        buttonTextColor
        width
        widthMeasurement
        alignment
        contactForm {
          ...ContactFormBuilder_contactForm
        }
      }
    }
  `,
});
