/* @flow */
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Match } from 'react-router-dom';
import styled from 'styled-components';

import type { TaskStatuses } from 'config/taskStatuses';

import { upload } from 'utils/Attachments';
import { convertNativeFile } from 'utils/uploading/convertFileStackFile';
import type { CircaFile } from 'utils/uploading/types';

import removeExpense from 'graph/mutations/expense/removeExpense';
import removeAttachment from 'graph/mutations/removeAttachment';
import addTaskAttachment from 'graph/mutations/task/addTaskAttachment';
import addTaskCompany from 'graph/mutations/task/addTaskCompany';
import addTaskContact from 'graph/mutations/task/addTaskContact';
import addTaskDependency from 'graph/mutations/task/addTaskDependency';
import addTasksTag from 'graph/mutations/task/addTasksTag';
import addTaskVendor from 'graph/mutations/task/addTaskVendor';
import assignTasks from 'graph/mutations/task/assignTasks';
import followTask from 'graph/mutations/task/followTask';
import removeTaskCompany from 'graph/mutations/task/removeTaskCompany';
import removeTaskContact from 'graph/mutations/task/removeTaskContact';
import removeTaskDependency from 'graph/mutations/task/removeTaskDependency';
import removeTasks from 'graph/mutations/task/removeTasks';
import removeTasksTag from 'graph/mutations/task/removeTasksTag';
import removeTaskVendor from 'graph/mutations/task/removeTaskVendor';
import unassignTasks from 'graph/mutations/task/unassignTasks';
import unfollowTask from 'graph/mutations/task/unfollowTask';
import updateTasks from 'graph/mutations/task/updateTasks';
import updateTasksStatus from 'graph/mutations/task/updateTasksStatus';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import { DropZone } from 'components/DropZone';
import InviteWindow, { type User } from 'components/InviteWindow';

import TaskContent from './TaskContent';
import TaskHeader from './TaskHeader';
import TaskSidebar from './TaskSidebar';

import type { TaskWindowContent_org } from './__generated__/TaskWindowContent_org.graphql';
import type { TaskWindowContent_task } from './__generated__/TaskWindowContent_task.graphql';

const WindowContent = styled.div`
  display: flex;
  background: ${props => props.theme.windowBackgroundColor};
  border-radius: 0 0 4px 4px;
  @media (${props => props.theme.mobileOnly}) {
    display: block;
  }
`;

class TaskWindowContent extends React.PureComponent<
  {
    match: Match,
    task: TaskWindowContent_task,
    org: TaskWindowContent_org,
    userId: string,
    onWindowHide: () => void,
    updateColumnWidth?: () => void,
  },
  {
    showInviteWindow: boolean,
    sectionsShown: Array<string>,
    uploadingAttachments: Array<Object>,
    statusWarningShown: boolean,
    expenseModalShown: boolean,
    addExpenseForm: boolean,
  },
> {
  state = {
    sectionsShown: [],
    uploadingAttachments: [],
    statusWarningShown: false,
    showInviteWindow: false,
    expenseModalShown: false,
    addExpenseForm: false,
  };

  componentDidMount() {
    if (this.props.match && this.props.match.params.action === 'unfollow') {
      this.handleUnfollowTask();
    }
  }

  handleAddExpenseFormToggle = (addExpenseForm: boolean) => {
    this.setState({
      addExpenseForm,
    });
  };

  onToggleExpenseModal = (expenseModalShown: boolean) => {
    this.setState({
      expenseModalShown,
    });
  };

  handleInviteClick = () => {
    this.setState({ showInviteWindow: true });
  };

  handleHideInviteWindow = (users?: ?$ReadOnlyArray<User>) => {
    if (users != null) {
      users.forEach(user => {
        assignTasks([this.props.task.id], user.id, 'task');
      });
    }
    this.setState({ showInviteWindow: false });
  };

  handleTaskUpdate = obj => {
    updateTasks([this.props.task.id], obj, 'task').catch(showModernMutationError);
    if (this.props.updateColumnWidth) {
      this.props.updateColumnWidth();
    }
  };

  handleTryUpdateTaskStatus = (status: TaskStatuses) => {
    if (status === 'COMPLETED') {
      const hasOpenDependency = this.props.task.dependencies.edges.some(
        edge => edge.node.status === 'OPEN',
      );

      if (hasOpenDependency) {
        this.setState({ statusWarningShown: true });
        return;
      }
    }
    this.handleUpdateTaskStatus(status);
  };

  handleUpdateTaskStatus = (status: TaskStatuses) => {
    updateTasksStatus([this.props.task.id], status, 'task').catch(showModernMutationError);
  };

  handleTaskAssign = userId => {
    assignTasks([this.props.task.id], userId, 'task').catch(showModernMutationError);
  };

  handleTaskUnassign = userId => {
    unassignTasks([this.props.task.id], userId, 'task').catch(showModernMutationError);
  };

  handleAddTaskTag = tagId => {
    addTasksTag([this.props.task.id], tagId, 'task').catch(showModernMutationError);
  };

  handleRemoveTaskTag = tagId => {
    removeTasksTag([this.props.task.id], tagId, 'task').catch(showModernMutationError);
  };

  handleTaskRemove = () => {
    this.props.onWindowHide();
    // HACK:  Relay (1.4) does not correctly delete the node from the connection if this window
    // is still visible.  So, we do the update on a different cycle to give the URL time to
    // change and hide the window.
    setTimeout(() => {
      removeTasks([this.props.task.id], {
        eventId: this.props.task.event.id,
        orgId: this.props.org.id,
        userId: this.props.userId,
        fromWindow: 'task',
      }).catch(showModernMutationError);
    }, 0);
  };

  handleFollowTask = () => {
    followTask(this.props.task.id).catch(showModernMutationError);
  };

  handleUnfollowTask = () => {
    unfollowTask(this.props.task.id).catch(showModernMutationError);
  };

  handleSectionAdd = (sectionName: string) => {
    this.setState(state => ({ sectionsShown: [sectionName, ...state.sectionsShown] }));
  };

  handleSectionRemove = (sectionName: string) => {
    this.setState(state => ({
      sectionsShown: state.sectionsShown.filter(name => sectionName !== name),
    }));
  };

  handleAddTaskContact = (contactId: string) => {
    addTaskContact(this.props.task.id, contactId).catch(showModernMutationError);
  };

  handleAddTaskCompany = (companyId: string) => {
    addTaskCompany(this.props.task.id, companyId).catch(showModernMutationError);
  };

  handleAddTaskVendor = (vendorId: string) => {
    addTaskVendor(this.props.task.id, vendorId).catch(showModernMutationError);
  };

  handleRemoveTaskContact = (contactId: string) => {
    removeTaskContact(this.props.task.id, contactId).catch(showModernMutationError);
  };

  handleRemoveTaskCompany = (companyId: string) => {
    removeTaskCompany(this.props.task.id, companyId).catch(showModernMutationError);
  };

  handleRemoveTaskVendor = (vendorId: string) => {
    removeTaskVendor(this.props.task.id, vendorId).catch(showModernMutationError);
  };

  handleAddTaskAttachment = (file: CircaFile) => {
    return addTaskAttachment(this.props.task.id, file).catch(showModernMutationError);
  };

  handleRemoveAttachment = (attachmentId: string) => {
    removeAttachment(this.props.task.event.id, attachmentId, [this.props.task.id]).catch(
      showModernMutationError,
    );
  };

  handleRemoveExpense = (expenseId: string) => {
    removeExpense({
      eventId: this.props.task.event.id,
      taskId: this.props.task.id,
      expenseId,
    }).catch(showModernMutationError);
  };

  handleAddTaskDependency = (dependencyId: string) => {
    addTaskDependency(this.props.task.id, dependencyId).catch(showModernMutationError);
  };

  handleAddTaskDependent = (dependentId: string) => {
    addTaskDependency(dependentId, this.props.task.id).catch(showModernMutationError);
  };

  handleRemoveTaskDependency = (dependencyId: string) => {
    removeTaskDependency(this.props.task.id, dependencyId).catch(showModernMutationError);
  };

  handleRemoveTaskDependent = (dependentId: string) => {
    removeTaskDependency(dependentId, this.props.task.id).catch(showModernMutationError);
  };

  handleFilesDrop = files => {
    files.forEach(file => {
      const temporaryId = String(Math.random());
      const convertedFile = convertNativeFile(file);

      this.setState(state => ({
        uploadingAttachments: [
          ...state.uploadingAttachments,
          { id: temporaryId, ...convertedFile },
        ],
        sectionsShown: [...state.sectionsShown, 'attachments'],
      }));
      upload(file)
        .then(this.handleAddTaskAttachment)
        .then(() => {
          this.setState(state => ({
            uploadingAttachments: state.uploadingAttachments.filter(at => at.id !== temporaryId),
          }));
        });
    });
  };

  render() {
    const { task, org, onWindowHide } = this.props;

    return (
      <DocumentTitle title={task.name}>
        <DropZone
          onDrop={this.handleFilesDrop}
          disabled={
            !task.viewerCanUpdateAttachment ||
            this.state.expenseModalShown ||
            this.state.addExpenseForm
          }
        >
          <TaskHeader
            task={task}
            onFollow={this.handleFollowTask}
            onUnfollow={this.handleUnfollowTask}
            onRemove={this.handleTaskRemove}
            onWindowHide={onWindowHide}
          />
          <WindowContent>
            <TaskContent
              task={task}
              org={org}
              onUpdate={this.handleTaskUpdate}
              onUpdateStatus={this.handleTryUpdateTaskStatus}
              onAssign={this.handleTaskAssign}
              onUnassign={this.handleTaskUnassign}
              onAddTag={this.handleAddTaskTag}
              onRemoveTag={this.handleRemoveTaskTag}
              onAddContact={this.handleAddTaskContact}
              onAddVendor={this.handleAddTaskVendor}
              onAddCompany={this.handleAddTaskCompany}
              onRemoveContact={this.handleRemoveTaskContact}
              onRemoveVendor={this.handleRemoveTaskVendor}
              onRemoveCompany={this.handleRemoveTaskCompany}
              onAddAttachment={this.handleAddTaskAttachment}
              onRemoveAttachment={this.handleRemoveAttachment}
              onRemoveExpense={this.handleRemoveExpense}
              onToggleExpenseModal={this.onToggleExpenseModal}
              onAddDependency={this.handleAddTaskDependency}
              onAddDependent={this.handleAddTaskDependent}
              onRemoveDependency={this.handleRemoveTaskDependency}
              onRemoveDependent={this.handleRemoveTaskDependent}
              sectionsShown={this.state.sectionsShown}
              uploadingAttachments={this.state.uploadingAttachments}
              onSectionRemove={this.handleSectionRemove}
              onInvite={this.handleInviteClick}
              onToggleAddExpenseForm={this.handleAddExpenseFormToggle}
            />
            <TaskSidebar
              task={task}
              sectionsShown={this.state.sectionsShown}
              onSectionAdd={this.handleSectionAdd}
            />

            {this.state.statusWarningShown && (
              <ConfirmationWindow
                onHide={() => this.setState({ statusWarningShown: false })}
                onConfirm={() => this.handleUpdateTaskStatus('COMPLETED')}
                actionLabel="Update"
                actionNegative={false}
                message={
                  <>
                    This Task has dependencies that are still open.
                    <br />
                    Are you sure you want to mark this Task as completed?
                  </>
                }
              />
            )}
            {this.state.showInviteWindow && (
              <InviteWindow
                onHide={this.handleHideInviteWindow}
                eventId={task.event.id}
                fromWindow="task"
              />
            )}
          </WindowContent>
        </DropZone>
      </DocumentTitle>
    );
  }
}

export default createFragmentContainer(TaskWindowContent, {
  task: graphql`
    fragment TaskWindowContent_task on Deliverable {
      id
      dbId
      name
      event {
        id
        team {
          dbId
        }
      }
      viewerCanUpdateAttachment
      ...TaskHeader_task
      ...TaskSidebar_task
      ...TaskContent_task
      dependencies {
        edges {
          node {
            status
          }
        }
      }
    }
  `,
  org: graphql`
    fragment TaskWindowContent_org on Org {
      id
      ...TaskContent_org
    }
  `,
});
