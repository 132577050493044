/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  color: ${props => props.theme.rowPrimaryTextColor};
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

const RowLabel = styled.div`
  flex: 1 1 auto;
`;

const CheckIcon = styled.i`
  color: ${props => props.theme.primaryActionColor};
`;

export default class SelectFieldRow<V> extends React.PureComponent<{
  label: string,
  value: V,
  onClick: (value: V) => void,
  selected?: boolean,
  className?: string,
}> {
  handleClick = () => {
    this.props.onClick(this.props.value);
  };

  render() {
    return (
      <Row className={this.props.className} onClick={this.handleClick}>
        <RowLabel>{this.props.label}</RowLabel>
        {this.props.selected && <CheckIcon className="fa fa-fw fa-check" />}
      </Row>
    );
  }
}
