/* @flow */
import React from 'react';
import styled from 'styled-components';

import Menu from 'components/page/Menu';
import MenuItem from 'components/page/MenuItem';

const Container = styled.div`
  height: 48px;
  border-bottom: 1px solid #eaebec;
  overflow: hidden;
`;

const StyledMenuItem = styled(MenuItem)`
  &:not(:first-child) {
    margin-left: 20px;
  }
`;

const StyledMenu = styled(Menu)`
  padding-left: 33px;
`;

export default class VendorPageMenu extends React.Component<{
  pathPrefix: string,
}> {
  render() {
    const pathPrefix = this.props.pathPrefix;
    return (
      <Container>
        <StyledMenu>
          <StyledMenuItem to={`${pathPrefix}/expenses`} text="Expenses" />
          <StyledMenuItem to={`${pathPrefix}/profile`} text="Profile" />
        </StyledMenu>
      </Container>
    );
  }
}
