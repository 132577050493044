/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefScheduleItem_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefScheduleDay_event$ref: FragmentReference;
export type BriefScheduleDay_event = {|
  +id: string,
  +tz: string,
  +$fragmentRefs: BriefScheduleItem_event$ref,
  +$refType: BriefScheduleDay_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BriefScheduleDay_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "BriefScheduleItem_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bee092c69174ff867c797cd23576f04a';
module.exports = node;
