import range from 'lodash/range';
import moment from 'moment-timezone';

import UserStore from '../stores/UserStore';

moment.fn.toUTCWithDate = function toUTCWithDate() {
  return moment.utc([this.year(), this.month(), this.date()]);
};

moment.fn.changeTimeZoneKeepDate = function changeTimeZoneKeepDate(new_timezone) {
  return moment.tz(this.toArray(), new_timezone || UserStore.getState().user.time_zone);
};

moment.fn.toTimeZoneNoon = function toTimeZoneNoon(tz) {
  return moment.tz(
    [this.year(), this.month(), this.date()],
    tz || UserStore.getState().user.time_zone,
  );
};

moment.fn.getYears = function getYears(selected) {
  let current_year = this.year();
  const selected_year = selected.year();

  let count = Math.max(selected_year - current_year + 1, 7);

  if (selected_year < current_year) {
    count += current_year - selected_year;
    current_year -= current_year - selected_year;
  }
  current_year -= 1;

  return range(current_year, current_year + count);
};

moment.fn.getTime = function getTime() {
  return this.minutes() + this.hours() * 60;
};

moment.fn.renderInputDate = function renderInputDate(all_day) {
  if (all_day) {
    return this.format('MM/DD/YYYY');
  }
  return this.format('MM/DD/YYYY [at] h:mm a');
};

moment.fn.renderToAccordingFormat = function renderMonthWeekDay(format: string) {
  return this.format(format);
};

moment.fn.renderDateTime = function renderDateTime(all_day, options = {}) {
  const { shorterMonth = false, noTime = false, hideTimeZone = false } = options;

  const format = `MMM${shorterMonth ? '' : 'M'} D, YYYY`;
  if (all_day || noTime) {
    return this.format(format);
  }
  return this.format(`${format} [at] h:mm a${hideTimeZone ? '' : ' z'}`);
};

export const createMoment = function createMoment(tz) {
  const fn = (...args) => moment.tz(...args, tz || UserStore.getState().user.time_zone);
  fn.origin = moment;
  return fn;
};

export const isDisabled = (options = {}, current) => {
  const { after, after_all_day, before, before_all_day, tz, all_day } = options;
  const tzMoment = createMoment(tz);
  const tzCurrent = tzMoment(current).changeTimeZoneKeepDate(tz);
  if (after && before) {
    const tzAfter = tzMoment(after).changeTimeZoneKeepDate(tz);
    const tzBefore = tzMoment(before).changeTimeZoneKeepDate(tz);
    return (
      tzAfter.isSameOrAfter(tzCurrent, all_day || after_all_day ? 'day' : null) ||
      tzBefore.isSameOrBefore(tzCurrent, all_day || before_all_day ? 'day' : null)
    );
  }
  if (after) {
    const tzAfter = tzMoment(after).changeTimeZoneKeepDate(tz);
    return tzAfter.isSameOrAfter(tzCurrent, all_day || after_all_day ? 'day' : null);
  }
  if (before) {
    const tzBefore = tzMoment(before).changeTimeZoneKeepDate(tz);
    return tzBefore.isSameOrBefore(tzCurrent, all_day || before_all_day ? 'day' : null);
  }
  return false;
};

export function renderDateRange(values, options = {}) {
  const { shorterMonth = false, noTime = false } = options;
  const { startDateAllDay, endDateAllDay, tz } = values;

  const tzMoment = createMoment(tz);

  const startDate = tzMoment(values.startDate);
  const endDate = tzMoment(values.endDate);

  const dayFormat = 'D';
  const monthFormat = `MMM${shorterMonth ? '' : 'M'}`;
  const yearFormat = ', YYYY';
  const timeFormat = 'h:mm';

  if (!values.endDate) {
    // render as single date if no end date is present
    return startDate.renderDateTime(startDateAllDay, options);
  }

  if (startDate.isSame(endDate, 'year')) {
    // same year

    if (noTime || (startDateAllDay && endDateAllDay)) {
      // no time everywhere
      if (startDate.isSame(endDate, 'day')) {
        // same day
        return startDate.renderDateTime(startDateAllDay, options);
      }
      if (startDate.isSame(endDate, 'month')) {
        // same month
        return `${startDate.format(`${monthFormat} ${dayFormat}`)} - ${endDate.format(
          `${dayFormat}${yearFormat}`,
        )}`;
      }

      return `${startDate.format(`${monthFormat} ${dayFormat}`)} - ${endDate.format(
        `${monthFormat} ${dayFormat}${yearFormat}`,
      )}`;
    }
    // at least one time present
    if (startDate.isSame(endDate, 'day')) {
      // same day
      const sameMeridiem = startDate.format('a') === endDate.format('a');
      return `${startDate.format(
        `${monthFormat} ${dayFormat}${yearFormat}[ from ]${timeFormat}${sameMeridiem ? '' : ' a'}`,
      )} - ${endDate.format(`${timeFormat} a z`)}`;
    }
  }

  // for all other cases
  return `${startDate.renderDateTime(
    startDateAllDay,
    Object.assign({}, options, {
      hideTimeZone: !endDateAllDay,
    }),
  )} - ${endDate.renderDateTime(endDateAllDay, options)}`;
}
