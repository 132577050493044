/* @flow */
import * as React from 'react';
import {
  type ConnectDragPreview,
  type ConnectDragSource,
  type ConnectDropTarget,
  type DragSourceConnector,
  type DragSourceMonitor,
  type DropTargetConnector,
  type DropTargetMonitor,
  DragSource,
  DropTarget,
} from 'react-dnd';
import styled, { css } from 'styled-components';

import DragIcon from 'images/drag.svg';

import { type Section } from './types';

const StyledDragIcon = styled(DragIcon)`
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 1;
  width: 8px;
  height: 18px;
  cursor: grab;
  color: #868f96;
`;

const Container = styled.div`
  position: relative;
  ${props =>
    props.dragging &&
    css`
      background: ${props.dragging ? 'rgba(151, 151, 151, 0.1)' : 'transparent'};
      box-shadow: 0 0 5px inset rgba(0, 0, 0, 0.1);
      > * {
        visibility: hidden;
      }
    `};
`;

class DraggableSection extends React.PureComponent<
  {
    children: React.Node,
    connectDragSource?: ConnectDragSource,
    connectDragPreview?: ConnectDragPreview,
    connectDropTarget?: ConnectDropTarget,
    isDragging?: boolean,
    /* eslint-disable react/no-unused-prop-types */
    // Used in Hover handler
    section: Section,
    onMove: (sourceOrder: number, targetOrder: number) => void,
    onMoveEnd: () => void,
    onAllStart: () => void,
  },
  { isSetMovingSection: boolean },
> {
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      isDragging,
      children,
    } = this.props;

    if (!connectDragSource || !connectDropTarget || !connectDragPreview) return null;

    return connectDropTarget(
      <div>
        {connectDragPreview(
          <div>
            <Container dragging={isDragging}>
              {children}
              {connectDragSource(
                <div>
                  <StyledDragIcon />
                </div>,
              )}
            </Container>
          </div>,
        )}
      </div>,
    );
  }
}

const ConnectedDraggableComponent = DragSource(
  'CUSTOM_SECTION',
  {
    beginDrag(props: $PropertyType<DraggableSection, 'props'>) {
      return props.section;
    },
    endDrag(props: $PropertyType<DraggableSection, 'props'>) {
      props.onMoveEnd();
    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
)(DraggableSection);

const ConnectedDroppableComponent = DropTarget(
  'CUSTOM_SECTION',
  {
    hover(props: $PropertyType<DraggableSection, 'props'>, monitor: DropTargetMonitor) {
      props.onAllStart();

      if (this.lastSectionId === props.section.id) {
        return;
      }
      this.lastSectionId = props.section.id;

      const dragItem = monitor.getItem();
      const hoverOrder = props.section.order;

      if (dragItem.order === hoverOrder) return;

      props.onMove(dragItem.order, hoverOrder);

      // eslint-disable-next-line no-param-reassign
      monitor.getItem().order = hoverOrder;
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(ConnectedDraggableComponent);

export default ConnectedDroppableComponent;
