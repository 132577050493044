/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { ContactMembershipLastSynced_eventContact } from './__generated__/ContactMembershipLastSynced_eventContact.graphql';

const ContactMembershipLastSynced = ({
  eventContact,
  tz,
}: {
  eventContact: ContactMembershipLastSynced_eventContact,
  tz: string,
}) => {
  if (!eventContact.syncStatus.lastSuccessAt) return null;

  return (
    <DateTimeTooltip
      date={moment.tz(eventContact.syncStatus.lastSuccessAt, tz)}
      placement="right"
    />
  );
};

export default createFragmentContainer(ContactMembershipLastSynced, {
  eventContact: graphql`
    fragment ContactMembershipLastSynced_eventContact on EventContactEdge {
      syncStatus {
        lastSuccessAt
      }
    }
  `,
});
