/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { CircaFile } from 'utils/uploading/types';

import type { BriefDataType } from './AttachmentPreview';
import AttachmentPreview from './AttachmentPreview';
import AttachmentRow from './AttachmentRow';
import AttachmentUploadingRow from './AttachmentUploadingRow';
import RelayAttachmentRow from './RelayAttachmentRow';

import type { RelayAttachments_attachments } from './__generated__/RelayAttachments_attachments.graphql';

export type { BriefDataType };

class Attachments extends React.PureComponent<
  {
    attachments: RelayAttachments_attachments,
    mockAttachments?: $ReadOnlyArray<CircaFile>,
    uploadingAttachments: $ReadOnlyArray<{
      id: string,
      filename: string,
      filetype: string,
    }>,
    onRemove?: (attachmentId: string) => void,
    className?: string,
    readOnly?: boolean,
    briefData: BriefDataType,
  },
  { previewId: ?string },
> {
  static defaultProps = {
    uploadingAttachments: [],
  };

  state = { previewId: null };

  handlePreview = (attachmentId: ?string) => {
    this.setState({ previewId: attachmentId });
  };

  render() {
    return (
      <div className={this.props.className}>
        {this.props.attachments.map(attachment => (
          <RelayAttachmentRow
            key={attachment.id}
            attachment={attachment}
            onRemove={this.props.onRemove}
            onPreview={this.handlePreview}
            readOnly={this.props.readOnly}
            briefData={this.props.briefData}
          />
        ))}
        {this.props.mockAttachments &&
          this.props.mockAttachments.map(attachment => {
            return (
              <AttachmentRow
                key={attachment.id}
                attachment={attachment}
                onRemove={this.props.onRemove}
                onPreview={this.handlePreview}
                readOnly={this.props.readOnly}
                briefData={this.props.briefData}
              />
            );
          })}
        {this.props.uploadingAttachments.map(attachment => (
          <AttachmentUploadingRow attachment={attachment} key={attachment.id} />
        ))}
        {this.state.previewId && (
          <AttachmentPreview
            attachments={this.props.attachments.filter(
              attachment => attachment.filetype && !attachment.filetype.startsWith('link/'),
            )}
            mockAttachments={
              this.props.mockAttachments
                ? this.props.mockAttachments.filter(
                    attachment => attachment.filetype && !attachment.filetype.startsWith('link/'),
                  )
                : []
            }
            previewId={this.state.previewId}
            onPreview={this.handlePreview}
            onRemove={!this.props.readOnly ? this.props.onRemove : null}
            briefData={this.props.briefData}
          />
        )}
      </div>
    );
  }
}

export default createFragmentContainer(
  Attachments,
  graphql`
    fragment RelayAttachments_attachments on Attachment @relay(plural: true) {
      id
      filetype
      ...RelayAttachmentRow_attachment
      ...AttachmentPreview_attachments
    }
  `,
);
