/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type { updateEventRequestFormStatusMutationResponse } from './__generated__/updateEventRequestFormStatusMutation.graphql';

const mutation = graphql`
  mutation updateEventRequestFormStatusMutation($input: UpdateEventRequestFormStatusInput!) {
    updateEventRequestFormStatus(input: $input) {
      requestForm {
        id
        ...EventRequestFormsIsDraftColumn_requestForm
        ...EventRequestFormsUpdatedColumn_requestForm
        ...EventRequestFormsShareLinkColumn_requestForm
      }
    }
  }
`;

export default function updateEventRequestFormStatus(
  requestFormId: string,
  isDraft: boolean,
): Promise<updateEventRequestFormStatusMutationResponse> {
  const variables = {
    input: {
      requestFormId,
      isDraft,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
  });
}
