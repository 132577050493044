/* @flow */
import React from 'react';
import styled from 'styled-components';

import CheckBox from 'components/EventRequestForm/form/CheckBox';

import type { QuestionType, QuestionFileExtensionType } from '../lib/types';
import questionFileExtensions from '../lib/questionFileExtensions';

const Container = styled.div`
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  margin-top: 5px;
`;

const Title = styled.div`
  color: rgba(74, 86, 101, 0.54);
  font-size: 11px;
`;

const Col = styled.div`
  flex: 1 1 auto;
`;

export default class EditableQuestionFileOptions extends React.PureComponent<{
  question: QuestionType,
  onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
}> {
  handleChange = (ext: QuestionFileExtensionType, e: SyntheticEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;

    this.props.onChangeQuestion({
      fileExtensions: checked
        ? [...this.props.question.fileExtensions, ext]
        : this.props.question.fileExtensions.filter(extension => extension !== ext),
    });
  };

  render() {
    const extensions = this.props.question.fileExtensions;

    return (
      <Container>
        <Title>File Format</Title>
        <Row>
          {Object.keys(questionFileExtensions).map(ext => (
            <Col key={ext}>
              <CheckBox
                label={ext === 'XLSX' ? 'EXCEL' : ext}
                compact
                checked={extensions.includes(ext)}
                onChange={(e: SyntheticEvent<HTMLInputElement>) => this.handleChange(ext, e)}
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}
