/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';

import reorderBriefTemplateEventInfoSections from 'graph/mutations/briefTemplate/reorderBriefTemplateEventInfoSections';
import showModernMutationError from 'graph/utils/showModernMutationError';

import DraggableSection from '../DraggableSection';
import InfoSection from './InfoSection';

import type { EventInfoSection_org } from './__generated__/EventInfoSection_org.graphql';
import type { EventInfoSection_template } from './__generated__/EventInfoSection_template.graphql';

type SectionNodeType = $ElementType<
  $PropertyType<$PropertyType<EventInfoSection_template, 'eventInfoSections'>, 'edges'>,
  0,
>;

export type FieldType = $PropertyType<
  $ElementType<
    $PropertyType<$PropertyType<$PropertyType<SectionNodeType, 'node'>, 'fields'>, 'edges'>,
    0,
  >,
  'node',
>;

export type SectionType = {|
  ...$Diff<$PropertyType<SectionNodeType, 'node'>, { fields: any }>,
  fields: $ReadOnlyArray<FieldType>,
|};

class EventInfoSection extends React.PureComponent<
  {
    org: EventInfoSection_org,
    template: EventInfoSection_template,
  },
  {
    sectionsOrderOverride: ?$ReadOnlyArray<SectionType>,
    expandedSections: $ReadOnlyArray<string>,
  },
> {
  state = {
    sectionsOrderOverride: null,
    expandedSections: [],
  };

  getSortedSections = (): $ReadOnlyArray<SectionType> =>
    sortBy(
      this.state.sectionsOrderOverride ||
        this.props.template.eventInfoSections.edges
          .filter(sectionEdge => sectionEdge.node.fields.edges.length > 0)
          .map(sectionEdge => {
            const { fields, ...section } = sectionEdge.node;

            return {
              ...section,
              fields: fields.edges.map(fieldEdge => fieldEdge.node),
            };
          }),
      section => section.order,
    ).map((section, index) => ({ ...section, order: index + 1 }));

  getSortedSectionsInput = (sections: $ReadOnlyArray<SectionType>) => {
    let order = 0;
    const disabledOrders = this.props.template.eventInfoSections.edges
      .filter(sectionEdge => sectionEdge.node.fields.edges.length === 0)
      .map(({ node: section }) => section.order);
    return sortBy(sections, 'order').map(section => {
      order += 1;
      while (disabledOrders.includes(order)) {
        order += 1;
      }
      return {
        id: section.id,
        order,
      };
    });
  };

  mutationInput = (sections: $ReadOnlyArray<SectionType>) => {
    let order = 0;
    const disabledOrders = this.props.template.eventInfoSections.edges
      .filter(sectionEdge => sectionEdge.node.fields.edges.length === 0)
      .map(({ node: section }) => section.order);
    return sortBy(sections, 'order').map(section => {
      order += 1;
      while (disabledOrders.includes(order)) {
        order += 1;
      }
      return {
        id: section.id,
        order,
        enabled: section.enabled,
        fields: section.fields.map(field => ({
          id: field.id,
          order: field.order,
          enabled: field.enabled,
        })),
      };
    });
  };

  handleMoveSection = (sourceOrder: number, targetOrder: number) => {
    const sortedSections = this.getSortedSections();
    const sourceIndex = sortedSections.findIndex(section => section.order === sourceOrder);
    const targetIndex = sortedSections.findIndex(section => section.order === targetOrder);
    const direction = sourceIndex > targetIndex ? 0 : 1;
    const source = sortedSections[sourceIndex];

    const splitIndex = targetIndex + direction;
    const before = sortedSections
      .slice(0, splitIndex)
      .filter(section => section.order !== sourceOrder);
    const after = sortedSections.slice(splitIndex).filter(section => section.order !== sourceOrder);

    this.setState({
      sectionsOrderOverride: [...before, source, ...after].map((section, index) => ({
        ...section,
        order: index + 1,
      })),
    });
  };

  handleMoveStart = () => {
    setTimeout(() => this.setState({ expandedSections: [] }), 50);
  };

  handleMoveEnd = () => {
    if (this.state.sectionsOrderOverride) {
      reorderBriefTemplateEventInfoSections(
        this.getSortedSectionsInput(this.state.sectionsOrderOverride),
      ).catch(showModernMutationError);
    }
    this.setState({ sectionsOrderOverride: null });
  };

  handleToggleSectionExpanded = (sectionId: string, expanded: boolean) => {
    this.setState(prevState => ({
      expandedSections: expanded
        ? [...prevState.expandedSections, sectionId]
        : prevState.expandedSections.filter(section => section !== sectionId),
    }));
  };

  render() {
    const org = this.props.org;
    const salesforceEnabled = !!org.salesforceAccount;
    const marketoEnabled = !!org.marketoAccount;
    return this.getSortedSections().map(section =>
      section.sectionName === 'CRM' && !salesforceEnabled && !marketoEnabled ? null : (
        <DraggableSection
          section={section}
          draggableGroup="eventInfoSections"
          onMoveStart={this.handleMoveStart}
          onMove={this.handleMoveSection}
          onMoveEnd={this.handleMoveEnd}
          key={section.id}
        >
          <InfoSection
            section={section}
            expanded={this.state.expandedSections.includes(section.id)}
            onToggleExpanded={this.handleToggleSectionExpanded}
            salesforceEnabled={salesforceEnabled}
            marketoEnabled={marketoEnabled}
          />
        </DraggableSection>
      ),
    );
  }
}

export default createFragmentContainer(
  EventInfoSection,
  graphql`
    fragment EventInfoSection_template on BriefTemplate {
      id
      eventInfoSections {
        edges {
          node {
            id
            sectionName
            enabled
            order
            customFieldSection {
              id
              name
            }
            fields {
              edges {
                node {
                  id
                  fieldName
                  order
                  enabled
                  customField {
                    id
                    label
                  }
                }
              }
            }
          }
        }
      }
    }

    fragment EventInfoSection_org on Org {
      salesforceAccount {
        id
      }
      marketoAccount {
        id
      }
    }
  `,
);
