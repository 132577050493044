/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  UpdateFormComponentInput,
  updateFormComponentMutationVariables,
} from './__generated__/updateFormComponentMutation.graphql';

export type updateFormComponentPropertyType = $Shape<UpdateFormComponentInput>;

const mutation = graphql`
  mutation updateFormComponentMutation($input: UpdateFormComponentInput!) {
    updateFormComponent(input: $input) {
      formComponent {
        id
        fontFamily
        fontStyle
        color
        buttonCopy
        buttonColor
        buttonTextColor
        width
        widthMeasurement
        alignment
        contactForm {
          ...ContactFormBuilder_contactForm
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateFormComponent(properties: updateFormComponentPropertyType) {
  const {
    id,
    fontFamily,
    fontStyle,
    color,
    buttonCopy,
    buttonColor,
    buttonTextColor,
    width,
    widthMeasurement,
    alignment,
  } = properties;
  const variables: updateFormComponentMutationVariables = {
    input: {
      ...properties,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateFormComponent: {
        registrationForm: {
          id,
          fontFamily,
          fontStyle,
          color,
          buttonCopy,
          buttonColor,
          buttonTextColor,
          width,
          widthMeasurement,
          alignment,
        },
      },
    },
  });
}
