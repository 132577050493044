/* @flow */
import { type Location } from 'react-router-dom';

import type { FieldType } from 'utils/customization/types';

import ContactEventActionsColumn from './ContactEventActionsColumn';
import EventAttendanceDuration from './EventAttendanceDuration';
import EventAttendanceJoinTime from './EventAttendanceJoinTime';
import EventAttendanceLeaveTime from './EventAttendanceLeaveTime';
import EventAttendanceStatus from './EventAttendanceStatus';
import EventDate from './EventDate';
import EventLastSynced from './EventLastSynced';
import EventLocation from './EventLocation';
import EventName from './EventName';
import EventRegistrationStatus from './EventRegistrationStatus';
import EventStatus from './EventStatus';
import EventSyncStatus from './EventSyncStatus';
import EventVirtualLocation from './EventVirtualLocation';

export type EventCellPropsType<
  ContactEventEdge: {},
  Contact: {} = {},
  FieldSettings: ?FieldType = null,
> = {|
  contact: Contact,
  contactEvent: ContactEventEdge,
  fieldSettings: FieldSettings,
  salesforceSyncEnabled: boolean,
  marketoSyncEnabled: boolean,
  location: Location,
|};

const columns = (syncEnabled: boolean) => [
  {
    title: 'Event Name',
    sortKey: 'NAME',
    component: EventName,
    fieldSettings: {
      id: 'name',
      label: 'Name',
      fieldName: 'name',
      order: 0,
      required: true,
      kind: 'DEFAULT',
      options: [],
    },
    grow: 1,
  },
  {
    title: 'Event Dates',
    sortKey: 'START_DATE',
    fieldSettings: {
      id: 'start_date',
      label: 'Event Dates',
      fieldName: 'start_date',
      order: 0,
      required: false,
      kind: 'DEFAULT',
      options: [],
    },
    component: EventDate,
  },
  {
    title: 'Event Status',
    sortKey: 'COMMITMENT_LEVEL',
    fieldSettings: {
      id: 'status',
      label: 'Event Status',
      fieldName: 'status',
      order: 0,
      required: true,
      kind: 'DEFAULT',
      options: [],
    },
    component: EventStatus,
  },
  {
    title: 'Event Location',
    sortKey: 'LOCATION',
    fieldSettings: {
      id: 'event_location',
      label: 'Event Location',
      fieldName: 'event_location',
      order: 0,
      required: false,
      kind: 'DEFAULT',
      options: [],
    },
    component: EventLocation,
  },
  {
    title: 'Event Virtual Location',
    sortKey: 'VIRTUAL_LOCATION',
    fieldSettings: {
      id: 'event_virtual_location',
      label: 'Event Virtual Location',
      fieldName: 'event_virtual_location',
      order: 0,
      required: false,
      kind: 'DEFAULT',
      options: [],
    },
    component: EventVirtualLocation,
  },
  {
    title: 'Registration Status',
    sortKey: 'REGISTRATION_STATUS',
    component: EventRegistrationStatus,
  },
  {
    title: 'Attendance Status',
    sortKey: 'ATTENDANCE_STATUS',
    component: EventAttendanceStatus,
  },
  ...(syncEnabled
    ? [
        {
          title: 'Membership Sync Status',
          sortKey: 'MEMBERSHIP_SYNC_STATUS',
          component: EventSyncStatus,
        },
        {
          title: 'Membership Last Sync',
          sortKey: 'MEMBERSHIP_LAST_SYNCED',
          component: EventLastSynced,
        },
      ]
    : []),
  {
    title: 'Join Time',
    sortKey: 'ATTENDANCE_JOIN_TIME',
    component: EventAttendanceJoinTime,
  },
  {
    title: 'Leave Time',
    sortKey: 'ATTENDANCE_LEAVE_TIME',
    component: EventAttendanceLeaveTime,
  },
  {
    title: 'Time in Session',
    sortKey: 'ATTENDANCE_DURATION',
    component: EventAttendanceDuration,
    align: 'right',
  },
  {
    title: '',
    component: ContactEventActionsColumn,
    fieldSettings: {
      id: 'action',
      label: 'Action',
      fieldName: 'action',
      order: Infinity,
      required: false,
      kind: 'DEFAULT',
      options: [],
    },
    type: 'action',
  },
];

export default columns;
