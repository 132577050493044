/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomizableDate_customizable$ref: FragmentReference;
export type CustomizableDate_customizable = {|
  +id: string,
  +__typename: string,
  +customDateFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: any,
  |}>,
  +$refType: CustomizableDate_customizable$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CustomizableDate_customizable",
  "type": "CustomizableInterface",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customDateFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomDateFieldType",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customField",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "plural": false,
          "selections": [
            v0
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b5ca082113a9e824b10d2035e32ea2e';
module.exports = node;
