/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  removeAttachmentMutationResponse,
  removeAttachmentMutationVariables,
} from './__generated__/removeAttachmentMutation.graphql';

const mutation = graphql`
  mutation removeAttachmentMutation($input: RemoveAttachmentInput!) {
    removeAttachment(input: $input) {
      removedAttachmentId
    }
  }
`;

export default function removeAttachment(
  eventId: string,
  attachmentId: string,
  relatedParentIds?: string[],
): Promise<removeAttachmentMutationResponse> {
  const parentIds = [...(relatedParentIds || []), eventId];
  const updaters = parentIds.map((parentId: string) =>
    removeRecordFromConnection({
      deletedIdField: 'removedAttachmentId',
      parentId,
      connections: [{ field: 'attachments' }],
    }),
  );
  const variables: removeAttachmentMutationVariables = {
    input: {
      eventId,
      attachmentId,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      optimisticResponse: {
        removeAttachment: {
          removedAttachmentId: attachmentId,
        },
      },
    },
    updater: mergeUpdaters(
      ...updaters,
      removeRecordFromConnection({
        parentId: eventId,
        deletedIdField: 'removedAttachmentId',
        connections: [{ field: 'briefAttachments' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedAttachmentId' }),
    ),
  });
}
