/* @flow */
import React from 'react';
import styled from 'styled-components';

import CheckBox from '../form/CheckBox';

const Container = styled.div`
  margin: 30px 0;
  font-size: 16px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: rgb(74, 86, 101, 0.87);
`;

const Option = styled.div`
  margin: 15px 0;
`;

export default class PublishingOptions extends React.PureComponent<{
  showInEventsDashboard: boolean,
  onChange: ({ showInEventsDashboard: boolean }) => void,
}> {
  handleChangeShowInEventsDashboard = () => {
    this.props.onChange({ showInEventsDashboard: !this.props.showInEventsDashboard });
  };

  render() {
    const { showInEventsDashboard } = this.props;

    return (
      <Container>
        <Title>Publishing Options</Title>
        <Option>
          <CheckBox
            checked={showInEventsDashboard}
            onChange={this.handleChangeShowInEventsDashboard}
            label="Show a link to this event request form on the Events Dashboard to Members who don't have permission to create Events"
          />
        </Option>
      </Container>
    );
  }
}
