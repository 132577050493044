/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';
import styled from 'styled-components';

import ErrorBoundary from 'components/ErrorBoundary';

import TeamHeader from './TeamHeader';
import TeamMenu from './TeamMenu';
import TeamRoutes from './TeamRoutes';

import type { TeamPage_team } from './__generated__/TeamPage_team.graphql';

type Props = { team: TeamPage_team, pathPrefix: string, rootUrl: string, location: Location };

const Container = styled.div`
  padding-top: 35px;
`;

const TeamPage = (props: Props) => {
  const { team, pathPrefix, rootUrl, location } = props;
  return (
    <Container>
      <TeamHeader team={team} location={location} />
      <TeamMenu urlPrefix={`/workspace/teams/${team.slug}`} location={location} />

      <ErrorBoundary location={location}>
        <TeamRoutes
          team={{
            id: team.dbId,
            relay_id: team.id,
            slug: team.slug,
            name: team.name,
          }}
          pathPrefix={pathPrefix}
          rootUrl={rootUrl}
          location={location}
        />
      </ErrorBoundary>
    </Container>
  );
};

export default createFragmentContainer(TeamPage, {
  team: graphql`
    fragment TeamPage_team on Team {
      id
      dbId
      name
      slug
      ...TeamHeader_team
    }
  `,
});
