/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import syncStatuses from 'config/syncStatuses.json';

import { type CellPropsType } from 'components/budget/Table';
import eventContactSyncDisabledInfo from 'components/Contacts/lib/eventContactSyncDisabledInfo';
import ErrorIcon from 'components/ErrorIcon';

import { type EventCellPropsType } from './index';

import type { EventSyncStatus_contact } from './__generated__/EventSyncStatus_contact.graphql';
import type { EventSyncStatus_contactEvent } from './__generated__/EventSyncStatus_contactEvent.graphql';

const EventSyncStatus = ({
  contactEvent: { syncStatus, node: event },
  contact,
  salesforceSyncEnabled,
  marketoSyncEnabled,
}: CellPropsType<EventCellPropsType<EventSyncStatus_contactEvent, EventSyncStatus_contact>>) => {
  const disabledInfo =
    syncStatus.state === 'DISABLED'
      ? eventContactSyncDisabledInfo({ salesforceSyncEnabled, marketoSyncEnabled, event, contact })
      : null;

  return (
    <>
      {syncStatuses[syncStatus.state]}

      {(disabledInfo || syncStatus.errorMessage) && (
        <ErrorIcon message={disabledInfo || syncStatus.errorMessage} />
      )}
    </>
  );
};

export default createFragmentContainer(EventSyncStatus, {
  contactEvent: graphql`
    fragment EventSyncStatus_contactEvent on ContactEventEdge {
      syncStatus {
        state
        errorMessage
      }
      node {
        salesforceCampaign {
          id
        }
        marketoProgram {
          id
        }
      }
    }
  `,
  contact: graphql`
    fragment EventSyncStatus_contact on Contact {
      syncStatus {
        state
      }
    }
  `,
});
