/**
 * @flow
 * @relayHash e39a1459e6a67a5a849bd66492cb612c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SalesforceAuth_org$ref = any;
export type ConnectSalesforceAccountInput = {
  clientMutationId?: ?string,
  code: string,
  host: string,
  sandbox: boolean,
};
export type connectSalesforceAccountMutationVariables = {|
  input: ConnectSalesforceAccountInput
|};
export type connectSalesforceAccountMutationResponse = {|
  +connectSalesforceAccount: ?{|
    +updatedOrg: {|
      +salesforceAccount: ?{|
        +id: string,
        +username: string,
      |},
      +$fragmentRefs: SalesforceAuth_org$ref,
    |}
  |}
|};
export type connectSalesforceAccountMutation = {|
  variables: connectSalesforceAccountMutationVariables,
  response: connectSalesforceAccountMutationResponse,
|};
*/


/*
mutation connectSalesforceAccountMutation(
  $input: ConnectSalesforceAccountInput!
) {
  connectSalesforceAccount(input: $input) {
    updatedOrg {
      salesforceAccount {
        id
        username
      }
      ...SalesforceAuth_org
      id
    }
  }
}

fragment SalesforceAuth_org on Org {
  salesforceAccount {
    id
    ...CampaignSyncStatus_account
  }
  ...AccountConnector_org
  ...SyncOptions_org
}

fragment CampaignSyncStatus_account on SalesforceAccount {
  campaignSyncStatus {
    ...SyncStatusIndicator_status
  }
}

fragment AccountConnector_org on Org {
  salesforceAccount {
    username
    id
  }
}

fragment SyncOptions_org on Org {
  salesforceAccount {
    id
    host
    contactsSyncEnabled
    opportunitiesSyncEnabled
    opportunitySyncStatus {
      ...SyncStatusIndicator_status
    }
  }
  contactCustomFields: customFields(customizableType: [CONTACT], fieldNames: ["company_id"]) {
    totalCount
  }
}

fragment SyncStatusIndicator_status on SyncStatus {
  lastSuccessAt
  state
  errorMessage
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ConnectSalesforceAccountInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ConnectSalesforceAccountInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastSuccessAt",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "state",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "errorMessage",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "connectSalesforceAccountMutation",
  "id": null,
  "text": "mutation connectSalesforceAccountMutation(\n  $input: ConnectSalesforceAccountInput!\n) {\n  connectSalesforceAccount(input: $input) {\n    updatedOrg {\n      salesforceAccount {\n        id\n        username\n      }\n      ...SalesforceAuth_org\n      id\n    }\n  }\n}\n\nfragment SalesforceAuth_org on Org {\n  salesforceAccount {\n    id\n    ...CampaignSyncStatus_account\n  }\n  ...AccountConnector_org\n  ...SyncOptions_org\n}\n\nfragment CampaignSyncStatus_account on SalesforceAccount {\n  campaignSyncStatus {\n    ...SyncStatusIndicator_status\n  }\n}\n\nfragment AccountConnector_org on Org {\n  salesforceAccount {\n    username\n    id\n  }\n}\n\nfragment SyncOptions_org on Org {\n  salesforceAccount {\n    id\n    host\n    contactsSyncEnabled\n    opportunitiesSyncEnabled\n    opportunitySyncStatus {\n      ...SyncStatusIndicator_status\n    }\n  }\n  contactCustomFields: customFields(customizableType: [CONTACT], fieldNames: [\"company_id\"]) {\n    totalCount\n  }\n}\n\nfragment SyncStatusIndicator_status on SyncStatus {\n  lastSuccessAt\n  state\n  errorMessage\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "connectSalesforceAccountMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "connectSalesforceAccount",
        "storageKey": null,
        "args": v1,
        "concreteType": "ConnectSalesforceAccountPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedOrg",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "salesforceAccount",
                "storageKey": null,
                "args": null,
                "concreteType": "SalesforceAccount",
                "plural": false,
                "selections": [
                  v2,
                  v3
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "SalesforceAuth_org",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "connectSalesforceAccountMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "connectSalesforceAccount",
        "storageKey": null,
        "args": v1,
        "concreteType": "ConnectSalesforceAccountPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedOrg",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "salesforceAccount",
                "storageKey": null,
                "args": null,
                "concreteType": "SalesforceAccount",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "campaignSyncStatus",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SyncStatus",
                    "plural": false,
                    "selections": v4
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "host",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "contactsSyncEnabled",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "opportunitiesSyncEnabled",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "opportunitySyncStatus",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SyncStatus",
                    "plural": false,
                    "selections": v4
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "contactCustomFields",
                "name": "customFields",
                "storageKey": "customFields(customizableType:[\"CONTACT\"],fieldNames:[\"company_id\"])",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "customizableType",
                    "value": [
                      "CONTACT"
                    ],
                    "type": "[CustomizableType!]!"
                  },
                  {
                    "kind": "Literal",
                    "name": "fieldNames",
                    "value": [
                      "company_id"
                    ],
                    "type": "[String!]"
                  }
                ],
                "concreteType": "CustomFieldTypeRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalCount",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7011ed1d82aca4c495221c5fa97743f0';
module.exports = node;
