/* @flow */
import React from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';

import ConfirmationWindow from 'components/ConfirmationWindow';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';

import generateBlankQuestion from '../lib/generateBlankQuestion';
import mappingCustomKinds from '../lib/mappingCustomKinds';
import mappingCustomOptionsComponents from '../lib/mappingCustomOptionsComponents';
import mappingKinds from '../lib/mappingKinds';
import mappingLabels from '../lib/mappingLabels';
import mappingOptionsComponents from '../lib/mappingOptionsComponents';
import type { QuestionMappingType, QuestionType } from '../lib/types';
import EditableQuestionActions from './EditableQuestionActions';
import EditableQuestionErrors from './EditableQuestionErrors';

import type { EditableQuestion_org } from './__generated__/EditableQuestion_org.graphql';

const PrimaryTextFieldContainer = styled.div`
  font-weight: 500;
  font-size: 16px;
`;

const SelectFieldsContainer = styled.div`
  display: flex;
  margin: 8px 0;
  > * {
    &:first-child {
      width: 250px;
      margin-right: 40px;
    }
    &:last-child {
      flex: 1 1 auto;
    }
  }
`;

const OtherRow = styled.a`
  white-space: nowrap;
  font-size: 13px;
  color: ${props => props.theme.primaryActionColor};
`;

const WarningMessages = {
  remove: {
    title: 'Are you sure you want to remove this question?',
    message: 'This question has logical dependencies with other questions/sections.',
  },
  mapping: {
    title: 'Are you sure you want to change the mapping?',
    message:
      'Changing the mapping on this question will remove all logical dependencies that other questions/sections have with this one.',
  },
};

class EditableQuestion extends React.PureComponent<
  {
    org: EditableQuestion_org,
    isSubmitted: boolean,
    question: QuestionType,
    usedMappings: $ReadOnlyArray<string>,
    errors: $ReadOnlyArray<string>,
    onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
    onCheckDependencies: (question: QuestionType) => boolean,
    onRemoveQuestion: (questionId: string) => void,
    relay: RelayRefetchProp,
  },
  {
    warningMessageType: ?$Keys<typeof WarningMessages>,
    newMapping: ?QuestionMappingType | string,
  },
> {
  state = {
    warningMessageType: null,
    newMapping: null,
  };

  settingsWindow: any = null;

  /**
   * for document focus we need to call function with throttle as
   * `visibilitychange` listener calls the handler twice
   */
  documentFocus = throttle(() => this.props.relay.refetch(), 1000, { trailing: false });

  componentWillUnmount(): void {
    this.removeTabChangeListener();
  }

  removeTabChangeListener = () =>
    document.removeEventListener('visibilitychange', this.documentFocus);

  addTabChangeListener = () => {
    this.settingsWindow = window.open('/settings/events/event_fields', '_blank');
    this.settingsWindow.onbeforeunload = this.removeTabChangeListener;
    document.addEventListener('visibilitychange', this.documentFocus);
  };

  handleChangeName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeQuestion({ name: e.currentTarget.value });
  };

  handleChangeDescription = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeQuestion({ description: e.currentTarget.value || null });
  };

  handleChangeMapping = (mapping: ?QuestionMappingType | ?string) => {
    const { question, org, onCheckDependencies } = this.props;

    if (!mapping || mapping === question.mapping) return;

    if (mapping === '-1') {
      this.addTabChangeListener();
    } else {
      if (!this.state.newMapping && onCheckDependencies(question)) {
        this.setState({ warningMessageType: 'mapping', newMapping: mapping });
        return;
      }

      this.props.onChangeQuestion({
        ...generateBlankQuestion(question.order, question.sectionId || ''),
        id: question.id,
        name: question.name,
        description: question.description,
        mapping: mappingKinds[mapping] ? ((mapping: any): QuestionMappingType) : null,
        mappingCustomField: mappingKinds[mapping]
          ? null
          : org.customFields.edges
              .map(edge => edge.node)
              .find(customField => customField.id === mapping),
      });
    }
  };

  handleRemoveQuestion = () => {
    const { question, onCheckDependencies, onRemoveQuestion } = this.props;
    if (onCheckDependencies(question)) {
      this.setState({ warningMessageType: 'remove' });
    } else {
      onRemoveQuestion(question.id);
    }
  };

  render() {
    const {
      question,
      org,
      onChangeQuestion,
      onRemoveQuestion,
      usedMappings,
      isSubmitted,
      errors,
    } = this.props;
    const questionSaved = !question.id.startsWith('question_');

    const customMappings = sortBy(
      org.customFields.edges.map(edge => edge.node),
      field => [field.section && field.section.order, field.order],
    );

    const mappingOptions = [
      ...sortBy(
        [
          ...Object.entries(mappingLabels()).map(([mapping, label]) => ({
            label: String(label),
            value: mapping,
            disabled:
              !['EXPENSE', 'NOTE', 'PLANNED_BUDGET'].includes(mapping) &&
              usedMappings.includes(mapping),
          })),
          ...customMappings.map(customField => ({
            label: customField.label,
            value: customField.id,
            disabled: usedMappings.includes(customField.id),
          })),
        ],
        option => (option.disabled ? 1 : 0),
      ),
      {
        label: 'Manage Custom Fields',
        value: '-1',
        displayLabel: <OtherRow>Manage Custom Fields</OtherRow>,
      },
    ];

    const OptionsComponent = question.mappingCustomField
      ? mappingCustomOptionsComponents[question.mappingCustomField.kind]
      : question.mapping && mappingOptionsComponents[question.mapping];

    return (
      <div>
        <PrimaryTextFieldContainer>
          <TextField
            placeholder="Question"
            value={question.name}
            onChange={this.handleChangeName}
            autoFocus={!question.name}
            multiline
          />
        </PrimaryTextFieldContainer>
        <TextField
          placeholder="Add Help Text"
          value={question.description || ''}
          onChange={this.handleChangeDescription}
          multiline
        />
        <SelectFieldsContainer>
          <SelectField
            label="Map with"
            value={question.mappingCustomField ? question.mappingCustomField.id : question.mapping}
            options={mappingOptions}
            onChange={this.handleChangeMapping}
            disabled={(questionSaved && isSubmitted) || question.mapping === 'NAME'}
            height={245}
            searchable
          />
          <TextField
            label="Field Type"
            value={
              question.mappingCustomField
                ? mappingCustomKinds[question.mappingCustomField.kind]
                : (question.mapping && mappingKinds[question.mapping]) || ''
            }
            disabled
          />
        </SelectFieldsContainer>
        {OptionsComponent && (
          <OptionsComponent
            key={question.mappingCustomField ? question.mappingCustomField.id : question.mapping}
            question={question}
            onChangeQuestion={onChangeQuestion}
          />
        )}
        <EditableQuestionErrors errors={errors} />
        <EditableQuestionActions
          question={question}
          onChangeQuestion={onChangeQuestion}
          onRemoveQuestion={this.handleRemoveQuestion}
        />
        {this.state.warningMessageType && (
          <ConfirmationWindow
            onHide={() => this.setState({ warningMessageType: null, newMapping: null })}
            onConfirm={() =>
              this.state.warningMessageType === 'remove'
                ? onRemoveQuestion(question.id)
                : this.handleChangeMapping(this.state.newMapping)
            }
            actionLabel={this.state.warningMessageType === 'remove' ? 'Delete' : 'Change'}
            actionNegative={this.state.warningMessageType === 'remove'}
            title={WarningMessages[this.state.warningMessageType].title}
            message={WarningMessages[this.state.warningMessageType].message}
          />
        )}
      </div>
    );
  }
}

export default createRefetchContainer(
  EditableQuestion,
  graphql`
    fragment EditableQuestion_org on Org {
      customFields(customizableType: [EVENT]) {
        edges {
          node {
            id
            label
            order
            kind
            section {
              order
            }
          }
        }
      }
    }
  `,
  graphql`
    query EditableQuestionRefetchQuery {
      org {
        ...EditableQuestion_org
      }
    }
  `,
);
