/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextareaField from 'components/budget/Table/InlineTextareaField';

import type { CustomizableTextarea_customizable } from './__generated__/CustomizableTextarea_customizable.graphql';

class CustomizableTextarea extends React.Component<{
  customizable: CustomizableTextarea_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
}> {
  getValue = (): string => {
    const { customizable, fieldSettings } = this.props;
    const customTextField = customizable.customTextareaFields.find(
      textField => textField.customField.id === fieldSettings.id,
    );
    return customTextField ? customTextField.value : '';
  };

  handleSave = (value: ?string): void => {
    const { onUpdateCustomField, updateColumnWidth, customizable, fieldSettings } = this.props;
    if (!this.getError(value) && value !== this.getValue() && onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        textareaValue: value,
      });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    if (this.props.fieldSettings.required && (!value || !value.trim())) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { readOnly, fieldSettings, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    const customText = this.getValue();

    return (
      <InlineTextareaField
        value={customText}
        onChange={this.handleSave}
        placeholder={fieldSettings.label}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        readOnly={readOnly}
        restrictChangingValue={fieldSettings.restrictChangingValue}
      >
        {customText && <InlineExpandableText>{customText}</InlineExpandableText>}
      </InlineTextareaField>
    );
  }
}

export default createFragmentContainer(
  CustomizableTextarea,
  graphql`
    fragment CustomizableTextarea_customizable on CustomizableInterface {
      id
      __typename
      customTextareaFields {
        customField {
          id
        }
        value
      }
    }
  `,
);
