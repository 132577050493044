/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import AccountConnector from 'components/Salesforce/AccountConnector';

import CampaignSyncStatus from './CampaignSyncStatus';
import SyncOptions from './SyncOptions';

import type { SalesforceAuth_org } from './__generated__/SalesforceAuth_org.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 49px;
  padding: 10px 15px;
  margin: 0 -15px 25px -15px;
  color: ${props => props.theme.rowPrimaryTextColor};
  ${props =>
    props.isConnected &&
    css`
      background: ${props.theme.primaryRowColor};
    `};
`;

class SalesforceAuth extends React.PureComponent<{ org: SalesforceAuth_org }> {
  render() {
    const isConnected = !!this.props.org.salesforceAccount;

    return (
      <div>
        <Container isConnected={isConnected}>
          <AccountConnector org={this.props.org} />
          {isConnected && <CampaignSyncStatus account={this.props.org.salesforceAccount} />}
        </Container>
        <SyncOptions org={this.props.org} />
      </div>
    );
  }
}

export default createFragmentContainer(
  SalesforceAuth,
  graphql`
    fragment SalesforceAuth_org on Org {
      salesforceAccount {
        id
        ...CampaignSyncStatus_account
      }
      ...AccountConnector_org
      ...SyncOptions_org
    }
  `,
);
