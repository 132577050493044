/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled, { css } from 'styled-components';
import sortBy from 'lodash/sortBy';

import publicRuleNames from 'config/publicRuleNames';

import viewedRegistrationPage from 'utils/analytics/events/viewedRegistrationPage';
import { initFullStory } from 'utils/analytics/fullstory';
import { type CustomFieldInput } from 'utils/customization/types';
import isInIframe from 'utils/isInIframe';

import type { registerEventContactMutationResponse } from 'graph/mutations/contact/__generated__/registerEventContactMutation.graphql';
import registerEventContact from 'graph/mutations/contact/registerEventContact';

import { type ExtendedContactDefaultFields } from 'components/ContactForm/lib/suggestedFields';
import type { RegistrationFormDefaultValues } from 'components/ContactForm/lib/types';
import { type CompanyDefaultFields } from 'views/Main/AllContacts/Company/tabs/CompanyProfile/CompanyFormFields';

import pageComponentsConfig, {
  type ContentComponentType,
} from '../RegistrationCreate/pageComponentsConfig';
import RegistrationFormComponent from './components/RegistrationFormComponent';
import SharedRowComponent from './components/SharedRowComponent';
import RegistrationFooterLink from './RegistrationFooterLink';
import RegistrationFormPostSubmissionView from './RegistrationFormPostSubmissionView';

import { type RegistrationFormSubmissionContent_org } from './__generated__/RegistrationFormSubmissionContent_org.graphql';
import { type RegistrationFormSubmissionContent_registrationForm } from './__generated__/RegistrationFormSubmissionContent_registrationForm.graphql';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  text-align: center;
`;

const Container = styled.div`
  flex: 0 1 auto;
  margin: 0 auto;
  max-width: 100%;
  width: ${props => `${props.width}${props.widthMeasurement === 'PERCENTAGE' ? '%' : 'px'}`};
  padding: 40px 80px;
  text-align: initial;
  background-color: ${props => props.bgColor || '#ffffff;'};
  ${props =>
    !props.viewMode &&
    css`
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.06);
    `}
  @media (${props => props.theme.mobileOnly}) {
    padding: 20px 40px;
  }

  ${props =>
    props.bgImage &&
    css`
      background-image: url(${props.bgImage});
      background-position: center;
      background-size: cover;
    `}
  ${props =>
    props.isEmbed &&
    css`
      margin: 0 auto;
      padding: 20px 80px;
      box-shadow: none;
    `}
`;

export type EventPersonDataType = {| registrationStatusId: ?string, attendanceStatusId: ?string |};

export type DefaultFormValuesType = {
  contactValues?: ExtendedContactDefaultFields,
  companyValues?: CompanyDefaultFields,
  customFieldValues?: $ReadOnlyArray<CustomFieldInput>,
};

class RegistrationFormSubmissionContent extends React.PureComponent<
  {
    org: RegistrationFormSubmissionContent_org,
    registrationForm: RegistrationFormSubmissionContent_registrationForm,
    resourceToken: string,
    viewMode?: boolean,
    preview?: boolean,
    className?: string,
    orgSlug: ?string,
    email?: string,
    privacyPolicyURL?: string,
    lockEmail?: boolean,
    redirectUrl?: string,
    wmSSOToken?: ?string,
    defaultFormHiddenValues: ?DefaultFormValuesType,
    defaultFormValues: RegistrationFormDefaultValues,
    location: Location,
  },
  {
    showPostSubmissionView: boolean,
  },
> {
  state = {
    showPostSubmissionView: false,
  };

  componentDidMount() {
    const { org, preview, registrationForm } = this.props;
    if (!preview && registrationForm.event) {
      const event = registrationForm.event;
      viewedRegistrationPage({
        orgId: org.id,
        orgName: org.name,
        eventId: event.id,
        eventName: event.name,
        teamId: event.team.id,
        teamName: event.team.name,
      });
    }

    if (org.analyticsEnabled) {
      initFullStory(window.location.pathname, true);
    }
  }

  getContentComponent = (kind: string): ContentComponentType => {
    const currentComponent = pageComponentsConfig.find(
      pageComponent => pageComponent.kind === kind,
    );
    if (!currentComponent) {
      return null;
    }

    if (kind === 'ROW') {
      return SharedRowComponent;
    }

    return currentComponent.contentComponent;
  };

  handleSubmit = (
    defaultFieldValues,
    updatedAt,
    customFieldInputs,
    company,
  ): Promise<?registerEventContactMutationResponse> => {
    const {
      registrationForm,
      resourceToken,
      orgSlug,
      defaultFormHiddenValues,
      wmSSOToken,
    } = this.props;
    const { owner, ...restOfDefaultFieldValues } = defaultFieldValues;
    const contact = {
      ...(defaultFormHiddenValues && defaultFormHiddenValues.contactValues),
      ...restOfDefaultFieldValues,
    };
    if (owner) {
      contact.ownerId = owner.id;
    }
    const customFieldValues = [
      ...customFieldInputs,
      ...((defaultFormHiddenValues && defaultFormHiddenValues.customFieldValues) || []).filter(
        defaultValue =>
          !customFieldInputs.some(
            customFieldInput => customFieldInput.customFieldId === defaultValue.customFieldId,
          ),
      ),
    ];
    const companyValues = {
      ...(defaultFormHiddenValues && defaultFormHiddenValues.companyValues),
      ...company,
    };

    return registerEventContact({
      publicRuleName: publicRuleNames.REGISTRATION_FORM,
      orgSlug,
      wmSSOToken,
      resourceToken,
      contact,
      publishedAt: registrationForm.publishedAt,
      company: Object.keys(companyValues).length === 0 ? undefined : companyValues,
      customFieldValues,
    });
  };

  handleFinishSubmit = () => {
    if (this.props.redirectUrl == null) {
      this.setState({ showPostSubmissionView: true });
      return;
    }

    window.location.href = this.props.redirectUrl;
  };

  handleHidePostSubmissionView = () => {
    this.setState({ showPostSubmissionView: false });
  };

  render() {
    const {
      org,
      registrationForm,
      viewMode,
      className,
      preview,
      email,
      lockEmail,
      defaultFormValues,
      location,
      privacyPolicyURL,
    } = this.props;
    const isEmbed = isInIframe();
    const pageComponents = sortBy(
      registrationForm.pageComponents.edges
        .filter(({ node }) => node.parent == null && node.kind !== 'COLUMN')
        .map(edge => edge.node),
      'order',
    );

    const formPageComponent = pageComponents.find(component => component.kind === 'FORM');
    const fontFamily =
      formPageComponent != null && formPageComponent.formComponent != null
        ? formPageComponent.formComponent.fontFamily
        : org.settings.font;
    return (
      <Root className={className}>
        {!this.state.showPostSubmissionView && (
          <Container
            isEmbed={isEmbed}
            bgImage={registrationForm.backgroundUrl}
            bgColor={registrationForm.backgroundColor}
            width={registrationForm.width}
            widthMeasurement={registrationForm.widthMeasurement}
            viewMode={viewMode}
          >
            {pageComponents.map(pageComponent => {
              if (pageComponent.kind === 'FORM') {
                return (
                  <RegistrationFormComponent
                    key={pageComponent.id}
                    registrationForm={registrationForm}
                    componentProps={pageComponent}
                    org={org}
                    onSubmit={this.handleSubmit}
                    onFinishSubmit={this.handleFinishSubmit}
                    viewMode={viewMode}
                    email={email}
                    lockEmail={lockEmail}
                    defaultFormValues={defaultFormValues}
                    location={location}
                  />
                );
              }

              const Component = this.getContentComponent(pageComponent.kind);
              return (
                <Component
                  key={pageComponent.id}
                  org={org}
                  registrationForm={registrationForm}
                  componentProps={pageComponent}
                  readOnly
                  onSubmit={this.handleSubmit}
                  onFinishSubmit={this.handleFinishSubmit}
                  viewMode={viewMode}
                  preview={preview}
                  email={email}
                  lockEmail={lockEmail}
                  defaultFormValues={defaultFormValues}
                  location={location}
                />
              );
            })}
          </Container>
        )}

        {this.state.showPostSubmissionView && (
          <RegistrationFormPostSubmissionView
            submitMessage={registrationForm.submitMessage ? registrationForm.submitMessage : ''}
            onHidePostSubmissionView={this.handleHidePostSubmissionView}
          />
        )}
        <RegistrationFooterLink
          label="Privacy policy"
          link={privacyPolicyURL}
          fontFamily={fontFamily}
        />
      </Root>
    );
  }
}

export default createFragmentContainer(RegistrationFormSubmissionContent, {
  registrationForm: graphql`
    fragment RegistrationFormSubmissionContent_registrationForm on RegistrationForm {
      id
      publishedAt
      submitMessage
      backgroundUrl
      backgroundColor
      width
      widthMeasurement
      event {
        id
        name
        team {
          id
          name
        }
      }
      pageComponents {
        edges {
          node {
            id
            order
            kind
            parent {
              id
            }
            formComponent {
              fontFamily
            }
            ...ImageComponent_componentProps
            ...EventNameComponent_componentProps
            ...EventDatesComponent_componentProps
            ...TextComponent_componentProps
            ...DividerComponent_componentProps
            ...RegistrationFormComponent_componentProps
            ...VideoComponent_componentProps
            ...SharedRowComponent_componentProps
            ...EmbedComponent_componentProps
          }
        }
      }
      ...EventNameComponent_registrationForm
      ...EventDatesComponent_registrationForm
      ...RegistrationFormComponent_registrationForm
      ...SharedRowComponent_registrationForm
    }
  `,
  org: graphql`
    fragment RegistrationFormSubmissionContent_org on Org {
      id
      name
      analyticsEnabled
      settings {
        font
      }
      ...RegistrationFormComponent_org
      ...SharedRowComponent_org
    }
  `,
});
