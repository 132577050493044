/* @flow */
import {
  type ColumnConfiguration,
  BaseColumn,
  BaseColumnAllowOverflow,
  NumberColumn,
} from 'components/material/table';

import EventRequestFormsActionsColumn from './EventRequestFormsActionsColumn';
import EventRequestFormsCreatedColumn from './EventRequestFormsCreatedColumn';
import EventRequestFormsIsDraftColumn from './EventRequestFormsIsDraftColumn';
import EventRequestFormsLastSubmittedColumn from './EventRequestFormsLastSubmittedColumn';
import EventRequestFormsNameColumn from './EventRequestFormsNameColumn';
import EventRequestFormsReviewersColumn from './EventRequestFormsReviewersColumn';
import EventRequestFormsShareLinkColumn from './EventRequestFormsShareLinkColumn';
import EventRequestFormsSubmissionsColumn from './EventRequestFormsSubmissionsColumn';
import EventRequestFormsUpdatedColumn from './EventRequestFormsUpdatedColumn';

const colsConfig: ColumnConfiguration = [
  {
    label: 'Form Name',
    key: 'NAME',
    component: BaseColumn,
    componentContent: EventRequestFormsNameColumn,
    value: 'name',
    width: 200,
    grow: 1,
  },
  {
    label: 'Created at',
    key: 'CREATED_AT',
    component: BaseColumnAllowOverflow,
    componentContent: EventRequestFormsCreatedColumn,
    value: 'createdAt',
    width: 120,
    grow: 0.2,
  },
  {
    label: 'Updated at',
    key: 'UPDATED_AT',
    component: BaseColumnAllowOverflow,
    componentContent: EventRequestFormsUpdatedColumn,
    value: 'updatedAt',
    width: 130,
    grow: 0.2,
  },
  {
    label: 'Status',
    key: 'IS_DRAFT',
    component: BaseColumn,
    componentContent: EventRequestFormsIsDraftColumn,
    value: 'isDraft',
    width: 100,
    grow: 0.2,
  },
  {
    label: 'Sharing',
    component: BaseColumnAllowOverflow,
    componentContent: EventRequestFormsShareLinkColumn,
    value: 'shareLink',
    width: 270,
    grow: 0.2,
  },
  {
    label: 'Submissions',
    key: 'SUBMISSIONS_COUNT',
    component: NumberColumn,
    componentContent: EventRequestFormsSubmissionsColumn,
    value: 'submissionsCount',
    width: 120,
    grow: 0.2,
  },
  {
    label: 'Reviewers',
    component: BaseColumnAllowOverflow,
    componentContent: EventRequestFormsReviewersColumn,
    value: 'reviewers',
    width: 120,
    grow: 0.2,
  },
  {
    label: 'Last Submitted',
    key: 'LAST_SUBMITTED_AT',
    component: BaseColumnAllowOverflow,
    componentContent: EventRequestFormsLastSubmittedColumn,
    value: 'lastSubmittedAt',
    width: 120,
    grow: 0.2,
  },
  {
    label: '',
    key: '',
    component: BaseColumn,
    componentContent: EventRequestFormsActionsColumn,
    value: '',
    width: 50,
    grow: 0.2,
  },
];

export default colsConfig;
