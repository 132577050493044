/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Participants, { type Participant } from 'components/Participants';

import TaskSection from './TaskSection';

import type { TaskContacts_task } from './__generated__/TaskContacts_task.graphql';

const Container = styled.div`
  padding-left: 2px;
  max-width: 445px;
`;

class TaskContacts extends React.PureComponent<{
  task: TaskContacts_task,
  onAddContact: (contactId: string) => void,
  onAddCompany: (companyId: string) => void,
  onRemoveContact: (contactId: string) => void,
  onRemoveCompany: (companyId: string) => void,
}> {
  handleContactSelect = (contact: ?Participant) => {
    if (contact) {
      this.props.onAddContact(contact.id);
    }
  };

  handleContactRemove = (contact: ?Participant) => {
    if (contact) {
      this.props.onRemoveContact(contact.id);
    }
  };

  handleCompanyRemove = (company: ?Participant) => {
    if (company) {
      this.props.onRemoveCompany(company.id);
    }
  };

  handleCompanySelect = (company: ?Participant) => {
    if (company) {
      this.props.onAddCompany(company.id);
    }
  };

  render() {
    const {
      task: { event, viewerCanUpdateContact, companies, contacts },
    } = this.props;
    const autoFocus = contacts.edges.length === 0 && companies.edges.length === 0;
    const contactParticipants = contacts.edges.filter(Boolean).map(({ node: contact }) => ({
      ...contact,
      profileLink: `/tasks/${this.props.task.event.slug}/contacts/people/${contact.slug}/${this.props.task.slug}`,
    }));
    const companyParticipants = companies.edges.filter(Boolean).map(({ node: company }) => ({
      ...company,
      profileLink: `/tasks/${this.props.task.event.slug}/contacts/companies/${company.id}/${this.props.task.slug}`,
    }));
    return (
      <TaskSection title="Contacts" disableAdd>
        <Container>
          <Participants
            eventId={event.id}
            contacts={contactParticipants}
            companies={companyParticipants}
            onAddContact={this.handleContactSelect}
            onRemoveContact={this.handleContactRemove}
            onAddCompany={this.handleCompanySelect}
            onRemoveCompany={this.handleCompanyRemove}
            disabled={!viewerCanUpdateContact}
            defaultSearch="contacts"
            fromWindow="task"
            autoFocus={autoFocus}
          />
        </Container>
      </TaskSection>
    );
  }
}

export default createFragmentContainer(
  TaskContacts,
  graphql`
    fragment TaskContacts_task on Deliverable {
      viewerCanUpdateContact
      slug
      contacts {
        edges {
          node {
            id
            firstName
            lastName
            email
            salesforceSyncAs
            slug
          }
        }
      }
      companies {
        edges {
          node {
            id
            name
          }
        }
      }
      event {
        id
        slug
      }
    }
  `,
);
