/* @flow */
import validateRulesAndConditions, {
  type ContactFormFieldsValueType,
  type ContactFormFieldType,
} from './validateRulesAndConditions';

const isRequiredQuestion = (
  contactFormValues: ContactFormFieldsValueType,
  contactFormField: ContactFormFieldType,
  tz: string,
) => {
  const defaultRequired = contactFormField.required;

  if (!contactFormField.rules) {
    return defaultRequired;
  }

  // if no rules take default option to improve performance
  if (contactFormField.rules.edges.length === 0) {
    return contactFormField.required;
  }

  const makeRequiredRules = contactFormField.rules.edges
    .filter(({ node: rule }) => rule.action === 'MAKE_REQUIRED')
    .map(edge => edge.node);
  const makeOptionalRules =
    contactFormField.rules &&
    contactFormField.rules.edges
      .filter(({ node: rule }) => rule.action === 'MAKE_OPTIONAL')
      .map(edge => edge.node);

  const makeRequiredRuleResult = validateRulesAndConditions(
    makeRequiredRules,
    contactFormValues,
    tz,
  );
  const makeOptionalRuleResult = validateRulesAndConditions(
    makeOptionalRules || [],
    contactFormValues,
    tz,
  );

  // Default changes to false if there are only rules to make the question optional
  // Required rule has higher priority than optional rule
  if (defaultRequired && (makeRequiredRuleResult || !makeOptionalRuleResult)) {
    return true;
  }

  if (!defaultRequired && makeRequiredRuleResult) {
    return true;
  }

  return false;
};

export default isRequiredQuestion;
