/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import removeCompanies from 'graph/mutations/company/removeCompanies';
import removeCompanyAssignment from 'graph/mutations/event/removeCompanyAssignment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { InputVariableFilters } from 'components/Companies/companiesTableColumnSettings';
import ConfirmationWindow from 'components/ConfirmationWindow';
import ShareDropdown from 'components/ShareDropdown';

import type { CompanyActionCell_company } from './__generated__/CompanyActionCell_company.graphql';
import type { CompanyActionCell_event } from './__generated__/CompanyActionCell_event.graphql';
import type { CompanyActionCell_org } from './__generated__/CompanyActionCell_org.graphql';

const Root = styled.div`
  margin: 0 -5px 0 -24px;
`;

class CompanyActionCell extends React.PureComponent<
  {
    event: ?CompanyActionCell_event,
    company: CompanyActionCell_company,
    org: CompanyActionCell_org,
    filters: InputVariableFilters,
  },
  { confirmingDelete: boolean },
> {
  state = { confirmingDelete: false };

  handleShowRemoveModal = () => {
    this.setState({ confirmingDelete: true });
  };

  handleHideRemoveModal = () => {
    this.setState({ confirmingDelete: false });
  };

  handleRemoveCompany = () => {
    removeCompanies({
      companyIds: [this.props.company.id],
      orgId: this.props.org.id,
      filters: this.props.filters,
      ...(this.props.event ? { eventId: this.props.event.id } : null),
      fromWindow: 'org companies',
    }).catch(showModernMutationError);
  };

  handleUnassignFromEvent = () => {
    const { event, company } = this.props;
    if (event) {
      removeCompanyAssignment({
        eventId: event.id,
        companyId: company.id,
        fromWindow: 'event companies',
        filters: this.props.filters,
      }).catch(showModernMutationError);
    }
  };

  render() {
    const actionOptions = [
      this.props.event
        ? { label: 'Remove from Event', icon: 'minus', onClick: this.handleUnassignFromEvent }
        : { label: 'Delete', icon: 'trash', onClick: this.handleShowRemoveModal },
    ];

    return (
      <Root>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={actionOptions}
          overlayContainer={document.body}
        />
        {this.state.confirmingDelete &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              onHide={this.handleHideRemoveModal}
              onConfirm={this.handleRemoveCompany}
              title="Are you sure?"
              message="Once you delete a company, it's gone for good."
            />,
            document.body,
          )}
      </Root>
    );
  }
}

export default createFragmentContainer(
  CompanyActionCell,
  graphql`
    fragment CompanyActionCell_company on Company {
      id
      name
    }

    fragment CompanyActionCell_org on Org {
      id
    }

    fragment CompanyActionCell_event on Event {
      id
    }
  `,
);
