/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  DuplicateEventListInput,
  duplicateEventListMutationResponse,
} from './__generated__/duplicateEventListMutation.graphql';

const mutation = graphql`
  mutation duplicateEventListMutation($input: DuplicateEventListInput!) {
    duplicateEventList(input: $input) {
      eventListsEdge {
        node {
          id
          name
          shared
        }
      }
      user {
        eventLists(first: 5) {
          totalCount
        }
        allEventLists: eventLists {
          totalCount
        }
      }
    }
  }
`;

export default function duplicateEventList(
  input: DuplicateEventListInput,
  clientInput: { userId: string },
): Promise<duplicateEventListMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input },
    updater: addRecordToConnection({
      parentId: clientInput.userId,
      edgeName: 'eventListsEdge',
      connections: [{ key: 'DashboardMenuSavedViews_eventLists' }],
      rangeBehavior: 'prepend',
    }),
  });
}
