/**
 * @flow
 * @relayHash ffb207c93cccb2ab355d822a7c6a25cd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSelectOptionRow_user$ref = any;
export type UserSelectFieldQueryVariables = {|
  skipEvent: boolean,
  eventSlug?: ?string,
  query: string,
  publicRuleName: string,
  resourceToken: string,
  orgSlug?: ?string,
  skipUserIds?: ?$ReadOnlyArray<string>,
  wmSSOToken?: ?string,
|};
export type UserSelectFieldQueryResponse = {|
  +event?: ?{|
    +id: string
  |},
  +publicRule: boolean,
  +org: {|
    +users: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +email: string,
          +$fragmentRefs: UserSelectOptionRow_user$ref,
        |}
      |}>
    |}
  |},
|};
export type UserSelectFieldQuery = {|
  variables: UserSelectFieldQueryVariables,
  response: UserSelectFieldQueryResponse,
|};
*/


/*
query UserSelectFieldQuery(
  $skipEvent: Boolean!
  $eventSlug: String
  $query: String!
  $publicRuleName: String!
  $resourceToken: String!
  $orgSlug: String
  $skipUserIds: [ID!]
  $wmSSOToken: String
) {
  event(slug: $eventSlug) @skip(if: $skipEvent) {
    id
  }
  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug, wmSSOToken: $wmSSOToken)
  org {
    users(first: 3, query: $query, skipUserIds: $skipUserIds) {
      edges {
        node {
          id
          firstName
          lastName
          email
          ...UserSelectOptionRow_user
        }
      }
    }
    id
  }
}

fragment UserSelectOptionRow_user on User {
  id
  firstName
  lastName
  email
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "skipEvent",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "publicRuleName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "resourceToken",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgSlug",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipUserIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "wmSSOToken",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicRule",
  "args": [
    {
      "kind": "Variable",
      "name": "orgSlug",
      "variableName": "orgSlug",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "publicRuleName",
      "variableName": "publicRuleName",
      "type": "String!"
    },
    {
      "kind": "Variable",
      "name": "resourceToken",
      "variableName": "resourceToken",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "wmSSOToken",
      "variableName": "wmSSOToken",
      "type": "String"
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "skipUserIds",
    "variableName": "skipUserIds",
    "type": "[ID!]"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "Condition",
  "passingValue": false,
  "condition": "skipEvent",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "eventSlug",
          "type": "String"
        }
      ],
      "concreteType": "Event",
      "plural": false,
      "selections": [
        v3
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserSelectFieldQuery",
  "id": null,
  "text": "query UserSelectFieldQuery(\n  $skipEvent: Boolean!\n  $eventSlug: String\n  $query: String!\n  $publicRuleName: String!\n  $resourceToken: String!\n  $orgSlug: String\n  $skipUserIds: [ID!]\n  $wmSSOToken: String\n) {\n  event(slug: $eventSlug) @skip(if: $skipEvent) {\n    id\n  }\n  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug, wmSSOToken: $wmSSOToken)\n  org {\n    users(first: 3, query: $query, skipUserIds: $skipUserIds) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          email\n          ...UserSelectOptionRow_user\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment UserSelectOptionRow_user on User {\n  id\n  firstName\n  lastName\n  email\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserSelectFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": v2,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5,
                      v6,
                      {
                        "kind": "FragmentSpread",
                        "name": "UserSelectOptionRow_user",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      v7
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSelectFieldQuery",
    "argumentDefinitions": v0,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": v2,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5,
                      v6,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v3
        ]
      },
      v7
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '63611d15ec54a44183763e824ffa68dc';
module.exports = node;
