/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import SectionHeader from '../components/SectionHeader';
import SectionContent from '../components/SectionContent';

import BriefTaskItem from './BriefTaskItem';

import { type TasksBrief_event } from './__generated__/TasksBrief_event.graphql';
import { type TasksBrief_template } from './__generated__/TasksBrief_template.graphql';

const Container = styled.div`
  padding-bottom: 40px;
`;

const TasksBrief = (props: {
  template: TasksBrief_template,
  event: TasksBrief_event,
  name: string,
  primaryColor: string,
}) => {
  const {
    name,
    template: { taskFields },
    primaryColor,
    event: { tz, slug, deliverables },
  } = props;
  const tasks = sortBy(deliverables.edges.map(({ node }) => node), ['order']);
  const isVisible = taskFields.some(es => es.enabled);
  const tasksCount: number = tasks.length;
  return isVisible && tasksCount > 0 ? (
    <React.Fragment>
      <SectionHeader primaryColor={primaryColor} name={name} anchor="tasks" total={tasksCount} />
      <SectionContent>
        <Container>
          {tasks.map(task => (
            <BriefTaskItem
              key={task.id}
              task={task}
              timezone={tz}
              eventSlug={slug}
              primaryColor={primaryColor}
            />
          ))}
        </Container>
      </SectionContent>
    </React.Fragment>
  ) : null;
};

export default createFragmentContainer(TasksBrief, {
  event: graphql`
    fragment TasksBrief_event on Event {
      tz
      slug
      deliverables {
        edges {
          node {
            id
            order
            ...BriefTaskItem_task
          }
        }
      }
    }
  `,
  template: graphql`
    fragment TasksBrief_template on BriefTemplate {
      taskFields {
        name
        enabled
      }
    }
  `,
});
