/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import { ColsConfig, DefaultTableColumns } from './ColumnSettings';
import parseTeamMembersFilters from './lib/parseTeamMembersFilters';
import TeamMembersHeaderBar from './TeamMembersHeaderBar';
import TeamMembersTableHeader from './TeamMembersTableHeader';
import TeamMembersTablePagination from './TeamMembersTablePagination';

import { type TeamMembersContainer_org } from './__generated__/TeamMembersContainer_org.graphql';
import type { TeamMembersContainerQueryResponse } from './__generated__/TeamMembersContainerQuery.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TeamMembersTableContainerWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  border: 1px solid ${props => props.theme.primaryRowColor};
  border-top: none;
`;

const TeamMembersTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const query = graphql`
  query TeamMembersContainerQuery(
    $teamId: ID!
    $count: Int!
    $cursor: String
    $sort: TeamMembershipSort!
    $direction: Direction!
  ) {
    team: node(id: $teamId) {
      ... on Team {
        ...TeamMembersTablePagination_team
        ...TeamMembersTablePagination_totalCountTeam
      }
    }
    me {
      ...TeamMembersTablePagination_me
    }
  }
`;

class TeamMembersContainer extends React.Component<
  {
    org: TeamMembersContainer_org,
    teamId: string,
    location: Location,
    history: RouterHistory,
  },
  {
    customColumnWidths: { [string]: number },
  },
> {
  state = {
    customColumnWidths: {},
  };

  scrollContainer: ?HTMLElement;

  handleColsWidthChange = (value: string, width: number) => {
    this.setState(state => ({
      customColumnWidths: {
        ...state.customColumnWidths,
        [value]: Math.max(state.customColumnWidths[value] || 0, width),
      },
    }));
  };

  render() {
    const shownColumns = DefaultTableColumns;
    const filters = parseTeamMembersFilters(this.props.location.search);

    // Default sort if none specified
    const sortFilter = filters.sort || {
      key: 'FULL_NAME',
      asc: true,
    };

    const colsConfig = ColsConfig.filter(
      col => col.sticky || shownColumns.includes(col.value),
    ).map(col => ({ ...col, width: this.state.customColumnWidths[col.value] || col.width }));
    // Sum of column widths + 10px padding for each column + 50 for actions column
    const containerWidth =
      colsConfig.map(col => col.width).reduce((sum, width) => sum + width) +
      colsConfig.length * 10 +
      50;

    const variables = {
      count: 25,
      teamId: this.props.teamId,
      sort: sortFilter.key,
      direction: sortFilter.asc ? 'ASC' : 'DESC',
    };

    return (
      <Container>
        <TeamMembersHeaderBar
          history={this.props.history}
          location={this.props.location}
          teamId={this.props.teamId}
          canImport={this.props.org.viewerCanManageUsers}
        />
        <TeamMembersTableContainerWrapper>
          <TeamMembersTableContainer
            ref={el => {
              this.scrollContainer = el;
            }}
          >
            <TeamMembersTableHeader
              sort={sortFilter}
              history={this.props.history}
              shownColumns={colsConfig}
              width={containerWidth}
            />
            <DefaultQueryRenderer
              variables={variables}
              query={query}
              renderSuccess={(props: TeamMembersContainerQueryResponse) => (
                <TeamMembersTablePagination
                  team={props.team}
                  me={props.me}
                  totalCountTeam={props.team}
                  scrollContainer={this.scrollContainer}
                  width={containerWidth}
                  shownColumns={colsConfig}
                  onColumnSizeChange={this.handleColsWidthChange}
                  history={this.props.history}
                  location={this.props.location}
                />
              )}
            />
          </TeamMembersTableContainer>
        </TeamMembersTableContainerWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(
  TeamMembersContainer,
  graphql`
    fragment TeamMembersContainer_org on Org {
      viewerCanManageUsers
    }
  `,
);
