/* @flow */
import React from 'react';
import styled from 'styled-components';

import Button from 'components/material/Button';

const Container = styled.div`
  display: flex;
  padding: 10px 20px 8px 20px;
  background: ${props => props.theme.blackHaze};
`;

const Title = styled.div`
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.windowTitleColor};
`;

const ClearAction = styled(Button)`
  padding: 0;
  border: none;
  font-size: 13px;
  color: ${props => props.theme.minimalButtonColor};
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

export default class FiltersHeader extends React.Component<{
  onResetFilters: () => void,
}> {
  render() {
    return (
      <Container>
        <Title>Filters</Title>
        <ClearAction onClick={this.props.onResetFilters} label="clear all" minimal />
      </Container>
    );
  }
}
