/* @flow */
import React from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';

import formatTimeRange from 'utils/date/formatTimeRange';

import TimeRangePicker, { InputWrapper, type TimesConfig } from 'components/date/TimeRangePicker';

const TimePickerContainer = styled.div`
  margin-top: -5px;

  ${InputWrapper} {
    width: 70px;

    &:not(:last-child) {
      margin-right: 5px;
    }

    label {
      margin: 0;

      input {
        padding-right: 0;
      }

      & + i {
        top: 10px;
      }
    }
  }
`;

const AllDayRow = styled.div`
  padding: 5px 10px;
  border-top: 1px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.rowPrimaryTextColor};
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

export default class Time extends React.PureComponent<
  {
    defaultEditing: boolean,
    tz: string,
    allDay: boolean,
    startTime: ?string,
    endTime: ?string,
    date: string,
    onChange: ({ startTime: ?string, endTime: ?string, allDay: boolean }) => void,
    disabled: boolean,
  },
  {
    startTime: ?string,
    endTime: ?string,
    editing: boolean,
  },
> {
  static getDerivedStateFromProps(
    props: $PropertyType<Time, 'props'>,
    state: $PropertyType<Time, 'state'>,
  ) {
    if (state.editing) return null;

    return {
      startTime: props.allDay ? null : props.startTime,
      endTime: props.allDay ? null : props.endTime,
    };
  }

  state = {
    startTime: this.props.allDay ? null : this.props.startTime,
    endTime: this.props.allDay ? null : this.props.endTime,
    editing: this.props.defaultEditing,
  };

  handleChangeTimes = ({ startTime, endTime }: TimesConfig) => {
    this.setState({ startTime, endTime });
  };

  handleBlur = (event: SyntheticEvent<HTMLDivElement>) => {
    const relatedTarget: ?Node = (event: any).relatedTarget || document.activeElement;

    if (event.currentTarget.contains(relatedTarget) || !this.state.startTime) return;

    this.setState({ editing: false });

    this.props.onChange({
      allDay: false,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
    });
  };

  handleBlurEnd = () => {
    // Give time for state to update
    window.requestAnimationFrame(() => {
      if (this.state.startTime) {
        this.props.onChange({
          allDay: false,
          startTime: this.state.startTime,
          endTime: this.state.endTime,
        });

        this.setState({ editing: false });
      }
    });
  };

  handleClickTime = () => {
    this.setState({ editing: true });
  };

  handleClickAllDay = () => {
    const day = moment
      .tz(this.props.date, this.props.tz)
      .startOf('day')
      .format();

    this.setState({ editing: false });

    this.props.onChange({ allDay: true, startTime: day, endTime: day });
  };

  render() {
    const { tz, date, disabled, allDay } = this.props;
    const { editing, startTime, endTime } = this.state;

    if (editing) {
      return (
        <div className="schedule-list-row-date" onBlur={this.handleBlur} tabIndex={-1}>
          <div className="schedule-timeline editing">
            <i className="fa fa-fw fa-clock-o" />
            <TimePickerContainer>
              <TimeRangePicker
                tz={tz}
                date={date}
                startTime={startTime}
                endTime={endTime}
                startLabel="Starts"
                endLabel="Ends"
                startRequired
                startFooterContent={<AllDayRow onClick={this.handleClickAllDay}>All Day</AllDayRow>}
                onChange={this.handleChangeTimes}
                onBlurEnd={this.handleBlurEnd}
                autoFocus
              />
            </TimePickerContainer>
          </div>
        </div>
      );
    }

    return (
      <div className="schedule-list-row-date">
        <div
          className="schedule-timeline schedule-time"
          onClick={!disabled ? this.handleClickTime : undefined}
        >
          <span>
            {allDay
              ? 'All Day'
              : formatTimeRange(
                  startTime && moment.tz(startTime, tz),
                  endTime && moment.tz(endTime, tz),
                )}
          </span>
        </div>
      </div>
    );
  }
}
