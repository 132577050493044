import './styles.styl';

import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export class Avatar extends React.PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      avatar: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      gravatar: PropTypes.string,
    }),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  render() {
    const user = this.props.user;

    if (!user || (!user.avatar && !user.email && !user.first_name && !user.last_name))
      return <span />;

    let avatar = user.avatar || user.gravatar;
    const { first_name, last_name, email } = user;
    const size = +this.props.size;
    const styles = {
      width: size,
      height: size,
      lineHeight: `${size - 4}px`,
      fontSize: `${size / 2}px`,
    };

    const text = first_name && last_name ? first_name[0] + last_name[0] : email.slice(0, 2);

    if (avatar) {
      // replace http with https for gravatar
      avatar = avatar.replace('http://www.gravatar', 'https://www.gravatar');
      return <img src={avatar} style={styles} alt={text} className="avatar--image" />;
    }
    return (
      <div className="avatar-placeholder" style={styles}>
        {text}
      </div>
    );
  }
}
