/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import qs from 'qs';

import createDividerComponent from 'graph/mutations/registration/createDividerComponent';
import createEmbedComponent from 'graph/mutations/registration/createEmbedComponent';
import createEventDatesComponent from 'graph/mutations/registration/createEventDatesComponent';
import createEventNameComponent from 'graph/mutations/registration/createEventNameComponent';
import createEventRegistrationForm from 'graph/mutations/registration/createEventRegistrationForm';
import createImageComponent from 'graph/mutations/registration/createImageComponent';
import createRowComponent from 'graph/mutations/registration/createRowComponent';
import createTextComponent from 'graph/mutations/registration/createTextComponent';
import createVideoComponent from 'graph/mutations/registration/createVideoComponent';
import reorderRegistrationFormPageComponents from 'graph/mutations/registration/reorderRegistrationFormPageComponents';
import updateFormComponent, {
  type updateFormComponentPropertyType,
} from 'graph/mutations/registration/updateFormComponent';
import updateRegistrationForm, {
  type updateRegistrationPropertyType,
} from 'graph/mutations/registration/updateRegistrationForm';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Loader from 'components/Loader';

import getFontValue from '../lib/getFontValue';
import validateColorParams from '../lib/validateColorParams';
import makeUIPropsInsensitive from '../makeUIPropsInsensitive';
import registrationUIDefaultProps, {
  type RegistrationUIPropsType,
  formComponentPropsMapping,
  registrationFormPropsMapping,
} from '../RegistrationUIDefaultProps';
import UIContext from '../UIContext';
import type {
  PageComponentKindType,
  PageComponentType,
  ReorderedPageComponentConfigType,
} from './pageComponentsConfig';
import RegistrationPageContent from './RegistrationPageContent';
import RegistrationPageProperties from './RegistrationPageProperties';
import RegistrationPageComponents from './RegistrationPageProperties/RegistrationPageComponents';

import type { RegistrationCreateContent_event } from './__generated__/RegistrationCreateContent_event.graphql';
import type { RegistrationCreateContent_org } from './__generated__/RegistrationCreateContent_org.graphql';
import type { RegistrationCreateContent_registrationForm } from './__generated__/RegistrationCreateContent_registrationForm.graphql';

type ComponentOrderType = {
  id: string,
  order: number,
};

export type SelectedComponent = ?$Shape<{|
  selectedComponentId: string,
  selectedComponentWidth?: ?number,
  selectedComponentHeight?: ?number,
  selectedComponentPadding?: ?string,
  selectedRowLeftColumnPadding?: ?string,
  selectedRowRightColumnPadding?: ?string,
  selectedRowLeftWidth?: ?number,
  selectedRowCellSpacing?: ?number,
  selectedComponentFontSize?: ?number,
|}>;

export type ComponentInColumnPropsType = {
  parentId: string,
  parentRowId: string,
  rowChildPageComponents: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +columnChild: ?{|
          +id: string,
        |},
      |},
    |}>,
  |},
};

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  overflow-y: auto;
`;

const LeftPanel = styled.div`
  z-index: 2;
  display: flex;
  flex: 0 0 auto;
  width: 359px;
  min-width: 359px;
  border-right: 1px solid #eef0ff;
  background: ${props => props.background};
`;

const createMutationFunctions = {
  EVENT_NAME: createEventNameComponent,
  EVENT_DATES: createEventDatesComponent,
  IMAGE: createImageComponent,
  TEXT: createTextComponent,
  DIVIDER: createDividerComponent,
  EMBED: createEmbedComponent,
  VIDEO: createVideoComponent,
  ROW: createRowComponent,
  COLUMN: null,
  FORM: null,
};

const blankSelectedComponent = {
  selectedComponentWidth: null,
  selectedComponentHeight: null,
  selectedComponentPadding: null,
  selectedRowLeftColumnPadding: null,
  selectedRowRightColumnPadding: null,
  selectedRowLeftWidth: null,
  selectedRowCellSpacing: null,
  selectedComponentFontSize: null,
};

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

type WidthProps = { width?: number, widthMeasurement?: 'PERCENTAGE' | 'PX' };

class RegistrationCreateContent extends React.PureComponent<
  {
    registrationForm: RegistrationCreateContent_registrationForm,
    org: RegistrationCreateContent_org,
    event?: RegistrationCreateContent_event,
    match: Match,
    history: History,
    location: Location,
  },
  {
    pageWidth: number,
    selectedComponent: SelectedComponent,
    hoveredPageComponent: ?PageComponentKindType,
    draggedNewComponentKind: ?PageComponentKindType,
  },
> {
  state = {
    pageWidth: this.getPageWidth(),
    selectedComponent: null,
    hoveredPageComponent: null,
    draggedNewComponentKind: null,
  };

  componentDidMount() {
    const { registrationForm, event, location } = this.props;
    const formComponent = this.getFormComponent();
    const parsedQueryString = validateColorParams(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
      formComponent ? { formFontColor: formComponent.color } : undefined,
    );

    const {
      pageWidth: stringPageWidth,
      formWidth: stringFormWidth,
      formAlignment,
    } = parsedQueryString;

    const { width: pageWidth, widthMeasurement: pageWidthMeasurement } = stringPageWidth
      ? this.parseWidth(stringPageWidth)
      : this.getDefaultWidthProps('pageWidth');

    const { width: formWidth, widthMeasurement: formWidthMeasurement } = stringFormWidth
      ? this.parseWidth(stringFormWidth)
      : this.getDefaultWidthProps('formWidth');
    const defaultParams = {
      ...makeUIPropsInsensitive({
        ...registrationUIDefaultProps,
        ...parsedQueryString,
      }),
      pageWidth,
      pageWidthMeasurement,
      formWidth,
      formWidthMeasurement,
      alignment: formAlignment,
    };
    if (defaultParams.formFontFamily) {
      defaultParams.formFontFamily = getFontValue(defaultParams.formFontFamily);
    }

    if (event && !registrationForm) {
      createEventRegistrationForm({
        eventId: event.id,
        ...defaultParams,
      }).catch(showModernMutationError);
      return;
    }

    const caseInsensitiveUrlProps = makeUIPropsInsensitive(parsedQueryString);
    const pagePropsToUpdate = this.getPagePropsToUpdate(caseInsensitiveUrlProps);

    if (Object.keys(pagePropsToUpdate).length > 0) {
      updateRegistrationForm({
        ...pagePropsToUpdate,
        formId: registrationForm.id,
        forceUpdate: true,
      }).catch(showModernMutationError);
    }

    // for Flow to not complain
    if (!formComponent) return;

    const formPropsToUpdate = this.getFormPropsToUpdate(caseInsensitiveUrlProps);

    if (Object.keys(formPropsToUpdate).length > 0) {
      updateFormComponent({ ...formPropsToUpdate, id: formComponent.id, forceUpdate: true }).catch(
        showModernMutationError,
      );
    }
  }

  getPageWidth() {
    const registrationForm = this.props.registrationForm;
    return registrationForm ? registrationForm.width : 0;
  }

  getPagePropsToUpdate = (
    parsedQueryString: RegistrationUIPropsType,
  ): updateRegistrationPropertyType => {
    const registrationForm = this.props.registrationForm;
    const queryStringProps = Object.keys(parsedQueryString);
    return queryStringProps.reduce((finalProps, currentPropKey) => {
      if (currentPropKey === 'pageWidth') {
        const { width, widthMeasurement } = this.parseWidth(parsedQueryString.pageWidth);
        return {
          ...finalProps,
          width: width !== registrationForm.width ? width : registrationForm.width,
          widthMeasurement:
            widthMeasurement !== registrationForm.widthMeasurement
              ? widthMeasurement
              : registrationForm.widthMeasurement,
        };
      }
      return registrationFormPropsMapping[currentPropKey] &&
        parsedQueryString[currentPropKey] !==
          registrationForm[registrationFormPropsMapping[currentPropKey]]
        ? {
            ...finalProps,
            [registrationFormPropsMapping[currentPropKey]]: parsedQueryString[currentPropKey],
          }
        : { ...finalProps };
    }, {});
  };

  getFormComponent = () => {
    const registrationForm = this.props.registrationForm;
    if (!registrationForm) return null;

    const formComponentEdge = registrationForm.pageComponents.edges.find(
      ({ node }) => node.kind === 'FORM',
    );
    if (!formComponentEdge || !formComponentEdge.node.formComponent) {
      return null;
    }
    return formComponentEdge.node.formComponent;
  };

  getFormPropsToUpdate = (
    parsedQueryString: RegistrationUIPropsType,
  ): updateFormComponentPropertyType => {
    const formComponent = this.getFormComponent();
    if (!formComponent) {
      return {};
    }
    const queryStringProps = Object.keys(parsedQueryString);
    return queryStringProps.reduce((finalProps, currentPropKey) => {
      if (currentPropKey === 'formWidth') {
        const { width, widthMeasurement } = this.parseWidth(parsedQueryString.formWidth);
        return {
          ...finalProps,
          width: width !== formComponent.width ? width : formComponent.width,
          widthMeasurement:
            widthMeasurement !== formComponent.widthMeasurement
              ? widthMeasurement
              : formComponent.widthMeasurement,
        };
      }

      if (currentPropKey === 'formAlignment') {
        const alignment = parsedQueryString.formAlignment.toUpperCase();
        return alignment !== formComponent.alignment ? { ...finalProps, alignment } : finalProps;
      }
      if (
        currentPropKey === 'formFontFamily' &&
        parsedQueryString[currentPropKey] !==
          formComponent[formComponentPropsMapping[currentPropKey]]
      ) {
        return {
          ...finalProps,
          fontFamily: {
            label: parsedQueryString[currentPropKey],
            value: getFontValue(parsedQueryString[currentPropKey]),
          },
        };
      }
      if (
        !('fontFamily' in finalProps) &&
        currentPropKey === 'fontFamily' &&
        parsedQueryString[currentPropKey] !==
          formComponent[formComponentPropsMapping[currentPropKey]]
      ) {
        return {
          ...finalProps,
          fontFamily: {
            label: parsedQueryString[currentPropKey],
            value: getFontValue(parsedQueryString.fontFamily),
          },
        };
      }
      return formComponentPropsMapping[currentPropKey] &&
        parsedQueryString[currentPropKey] !==
          formComponent[formComponentPropsMapping[currentPropKey]]
        ? {
            ...finalProps,
            [formComponentPropsMapping[currentPropKey]]: parsedQueryString[currentPropKey],
          }
        : finalProps;
    }, {});
  };

  getDefaultWidthProps = (widthKey: string): WidthProps => {
    return {
      width: registrationUIDefaultProps[widthKey],
      measurement: registrationUIDefaultProps[`${widthKey}Measurement`],
    };
  };

  parseWidth = (width: string): WidthProps => {
    const widthLastChar = width.slice(-1);
    const widthNumeric = parseInt(width, 10);
    if (widthLastChar === '%') {
      return { width: widthNumeric, widthMeasurement: 'PERCENTAGE' };
    }
    return { width: widthNumeric, widthMeasurement: 'PX' };
  };

  handleSelectPageComponent = (selectedComponentId: string) => {
    this.setState(
      {
        selectedComponent: {
          ...blankSelectedComponent,
          selectedComponentId,
        },
      },
      () => {
        const selectedPageComponent = this.getSelectedPageComponent();
        const { event, history, location } = this.props;
        if (selectedPageComponent && selectedPageComponent.formComponent) {
          history.push(
            `${event ? `/events/${event.slug}` : ''}/registration_builder/create/design${
              location.search
            }`,
          );
        }
      },
    );
  };

  getSortedPageComponents = (): Array<PageComponentType> => {
    return sortBy(this.getNonChildPageComponents(), 'order');
  };

  getNonChildPageComponents = (): Array<PageComponentType> => {
    return this.props.registrationForm.pageComponents.edges
      .filter(({ node }) => node.parent == null && node.kind !== 'COLUMN')
      .map(({ node }) => ({ ...node }));
  };

  getReorderedComponentsOrder = (
    targetOrder: number,
    sourceId: ?string,
    excludeFromMainPage: boolean,
  ): Array<ComponentOrderType> => {
    if (!sourceId) {
      return [];
    }
    const sortedPageComponents = this.getSortedPageComponents();
    if (excludeFromMainPage) {
      return sortedPageComponents
        .filter(component => component.id !== sourceId)
        .map((component, index) => ({
          id: component.id,
          order: index,
        }));
    }
    const before = sortedPageComponents
      .slice(0, targetOrder)
      .filter(component => component.id !== sourceId);
    const after = sortedPageComponents
      .slice(targetOrder)
      .filter(component => component.id !== sourceId);
    const draggedComponent = { id: sourceId, order: targetOrder };
    return [...before, draggedComponent, ...after].map((component, index) => ({
      id: component.id,
      order: index,
    }));
  };

  handlePageComponentMoveEnd = (reorderedPageComponentConfig: ReorderedPageComponentConfigType) => {
    const registrationForm = this.props.registrationForm;
    const orderOverride = this.getReorderedComponentsOrder(
      reorderedPageComponentConfig.order,
      reorderedPageComponentConfig.id,
      !!reorderedPageComponentConfig.parentId,
    );
    if (orderOverride) {
      reorderRegistrationFormPageComponents(
        registrationForm.id,
        orderOverride,
        reorderedPageComponentConfig,
      ).catch(showModernMutationError);
    }
  };

  getNewComponentDropReorderState = (targetOrder: number): Array<ComponentOrderType> => {
    const sortedPageComponents = this.getSortedPageComponents();
    return sortedPageComponents.map((pageComponent, index) => ({
      id: pageComponent.id,
      order: targetOrder <= index ? index + 1 : index,
    }));
  };

  getParentProps = (columnId: ?string): ?ComponentInColumnPropsType => {
    if (!columnId) return undefined;

    const registrationForm = this.props.registrationForm;
    const columnComponentEdge = registrationForm.pageComponents.edges.find(
      ({ node }) => node.id === columnId,
    );
    if (!columnComponentEdge || !columnComponentEdge.node.parent) {
      return undefined;
    }
    const rowComponentEdge = registrationForm.pageComponents.edges.find(
      ({ node }) =>
        columnComponentEdge.node.parent && node.id === columnComponentEdge.node.parent.id,
    );
    if (!rowComponentEdge || !rowComponentEdge.node.childPageComponents) {
      return undefined;
    }
    return {
      parentId: columnId,
      parentRowId: columnComponentEdge.node.parent ? columnComponentEdge.node.parent.id : '',
      rowChildPageComponents: rowComponentEdge.node.childPageComponents,
    };
  };

  createNewPageComponent = (newPageComponentConfig?: ReorderedPageComponentConfigType) => {
    this.setState({ draggedNewComponentKind: null });
    if (!newPageComponentConfig) return;

    const createFunction = newPageComponentConfig.kind
      ? createMutationFunctions[newPageComponentConfig.kind]
      : null;
    const { registrationForm, location } = this.props;
    const parentProps = this.getParentProps(newPageComponentConfig.parentId);

    if (createFunction != null && registrationForm != null) {
      this.setState({
        selectedComponent: {
          ...blankSelectedComponent,
          selectedComponentId: 'new',
        },
      });
      const parsedQueryString = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const generalFontFamily = parsedQueryString.fontFamily
        ? getFontValue(parsedQueryString.fontFamily)
        : undefined;

      createFunction({
        formId: registrationForm.id,
        newComponentOrder: newPageComponentConfig.order,
        pageComponentsOrder: this.getNewComponentDropReorderState(newPageComponentConfig.order),
        parentProps,
        fontFamily: generalFontFamily,
      })
        .then(createdPageComponentId => {
          this.setState({
            selectedComponent: {
              ...blankSelectedComponent,
              selectedComponentId: createdPageComponentId,
            },
          });
        })
        .catch(error => showModernMutationError(error));
    }
  };

  handleBeginDrag = (draggedNewComponentKind: PageComponentKindType) => {
    this.setState({ draggedNewComponentKind, hoveredPageComponent: null });
  };

  getSelectedPageComponent = () => {
    const registrationForm = this.props.registrationForm;
    const selectedComponent = this.state.selectedComponent;
    const selectedPageComponent = registrationForm.pageComponents.edges.find(
      pageComponents =>
        pageComponents.node &&
        pageComponents.node.id === (selectedComponent && selectedComponent.selectedComponentId),
    );
    if (!selectedPageComponent) {
      return null;
    }
    return selectedPageComponent.node;
  };

  handleUnselectComponent = () => {
    this.setState({ selectedComponent: null });
  };

  handleShowPageComponents = () => {
    const { history, event, location } = this.props;
    this.setState({ selectedComponent: null });
    history.push(
      `${event ? `/events/${event.slug}` : ''}/registration_builder/create/design${
        location.search
      }`,
    );
  };

  handleChangePageWidth = (pageWidth: number) => {
    this.setState({ pageWidth });
  };

  handleChangeSelectedComponentProperty = (updatedProps: SelectedComponent) => {
    this.setState(prevState => ({
      selectedComponent: { ...prevState.selectedComponent, ...updatedProps },
    }));
  };

  handlePageComponentHover = (kind: PageComponentKindType) => {
    this.setState({ hoveredPageComponent: kind });
  };

  handlePageComponentHoverOut = () => {
    this.setState({ hoveredPageComponent: null });
  };

  render() {
    const { registrationForm, org, event, match, location } = this.props;
    if (!registrationForm) {
      return (
        <UIContext.Consumer>
          {({ loaderColor }) => (
            <LoaderContainer>
              <Loader color={loaderColor} size={20} />
            </LoaderContainer>
          )}
        </UIContext.Consumer>
      );
    }

    const {
      selectedComponent,
      pageWidth,
      hoveredPageComponent,
      draggedNewComponentKind,
    } = this.state;
    const selectedPageComponent = this.getSelectedPageComponent();

    const formComponentSelected = selectedPageComponent
      ? !!selectedPageComponent.formComponent
      : false;
    return (
      <Container>
        <UIContext.Consumer>
          {({ leftPanelBackground }) => (
            <LeftPanel background={leftPanelBackground}>
              {selectedPageComponent && !formComponentSelected ? (
                <RegistrationPageComponents
                  org={org}
                  selectedPageComponent={selectedPageComponent}
                  onClose={this.handleUnselectComponent}
                  onChangeSelectedComponentProperty={this.handleChangeSelectedComponentProperty}
                />
              ) : (
                <RegistrationPageProperties
                  registrationForm={registrationForm}
                  org={org}
                  event={event}
                  match={match}
                  location={location}
                  formComponentSelected={formComponentSelected}
                  onMoveEnd={this.createNewPageComponent}
                  onBeginDrag={this.handleBeginDrag}
                  onChangePageWidth={this.handleChangePageWidth}
                  onChangeSelectedComponentProperty={this.handleChangeSelectedComponentProperty}
                  onHoverPageComponent={this.handlePageComponentHover}
                  onHoverOutPageComponent={this.handlePageComponentHoverOut}
                />
              )}
            </LeftPanel>
          )}
        </UIContext.Consumer>

        <RegistrationPageContent
          org={org}
          registrationForm={registrationForm}
          pageWidth={pageWidth}
          pageComponents={this.getNonChildPageComponents()}
          draggedNewComponentKind={draggedNewComponentKind}
          selectedComponent={selectedComponent}
          hoveredPageComponent={hoveredPageComponent}
          onUnselectComponent={this.handleUnselectComponent}
          onSelectPageComponent={this.handleSelectPageComponent}
          onPageComponentMoveEnd={this.handlePageComponentMoveEnd}
          onShowPageComponents={this.handleShowPageComponents}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(RegistrationCreateContent, {
  registrationForm: graphql`
    fragment RegistrationCreateContent_registrationForm on RegistrationForm {
      id
      width
      widthMeasurement
      ...RegistrationPageContent_registrationForm
      ...RegistrationPageProperties_registrationForm
      pageComponents {
        edges {
          node {
            id
            order
            kind
            parent {
              id
            }
            formComponent {
              id
              fontFamily
              fontStyle
              color
              buttonCopy
              buttonColor
              buttonTextColor
              width
              widthMeasurement
              alignment
            }
            childPageComponents {
              edges {
                node {
                  id
                  columnChild {
                    id
                  }
                }
              }
            }
            ...ImageComponent_componentProps
            ...EventNameComponent_componentProps
            ...EventDatesComponent_componentProps
            ...TextComponent_componentProps
            ...DividerComponent_componentProps
            ...EmbedComponent_componentProps
            ...FormComponent_componentProps
            ...VideoComponent_componentProps
            ...RowComponent_componentProps
            ...RegistrationPageComponents_selectedPageComponent
          }
        }
      }
    }
  `,
  org: graphql`
    fragment RegistrationCreateContent_org on Org {
      # Used inside of RegistrationPageContent/components to avoid creating _org fragments with duplicate names
      syncedToIbmWm
      ...RegistrationPageComponents_org
      ...RegistrationPageProperties_org
      ...RegistrationPageContent_org
    }
  `,
  event: graphql`
    fragment RegistrationCreateContent_event on Event {
      id
      slug
      ...RegistrationPageProperties_event
    }
  `,
});
