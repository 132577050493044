function tryToRefresh() {
  if (document.hidden) { // check if the tab is active
    location.reload();
  } else {
    setTimeout(tryToRefresh, 1000 * 60 * 60); // <- try again in an hour
  }
}

export default function () {
  setTimeout(() => {
    tryToRefresh();
  }, 1000 * 60 * 60 * 36); // <- 36 hours
}
