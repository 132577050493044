/* @flow */
import moment from 'moment-timezone';
import getQuarterNumber from './getQuarterNumber';
import getYearStart from './getYearStart';

export default function getQuarterStart(startMonth: number, date: moment = moment()): moment {
  const quarterNumber = getQuarterNumber(startMonth, date);
  return getYearStart(startMonth, date)
    .add((quarterNumber - 1) * 3, 'month')
    .startOf('month');
}
