/**
 * @flow
 * @relayHash ed3376ba814507d9d459a69cf56fdd76
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EventSearchQueryVariables = {|
  query: string,
  includeSuggested: boolean,
|};
export type EventSearchQueryResponse = {|
  +org: {|
    +allEvents: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +startDate: ?any,
          +endDate: ?any,
          +tz: string,
          +primaryLocation: ?{|
            +name: string,
            +city: string,
            +state: ?string,
            +country: ?string,
          |},
        |}
      |}>
    |},
    +suggestedEvents?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +startDate: ?any,
          +endDate: ?any,
          +tz: string,
          +primaryLocation: ?{|
            +name: string,
            +city: string,
            +state: ?string,
            +country: ?string,
          |},
        |}
      |}>
    |},
  |}
|};
export type EventSearchQuery = {|
  variables: EventSearchQueryVariables,
  response: EventSearchQueryResponse,
|};
*/


/*
query EventSearchQuery(
  $query: String!
  $includeSuggested: Boolean!
) {
  org {
    allEvents: events(first: 10, query: $query, requestStatuses: [N_A, APPROVED]) {
      edges {
        node {
          id
          name
          startDate
          endDate
          tz
          primaryLocation {
            name
            city
            state
            country
            id
          }
        }
      }
    }
    suggestedEvents: events(first: 3, query: $query, requestStatuses: [N_A, APPROVED]) @include(if: $includeSuggested) {
      edges {
        node {
          id
          name
          startDate
          endDate
          tz
          primaryLocation {
            name
            city
            state
            country
            id
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeSuggested",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query",
  "type": "String"
},
v2 = {
  "kind": "Literal",
  "name": "requestStatuses",
  "value": [
    "N_A",
    "APPROVED"
  ],
  "type": "[EventRequestSubmissionStatusFilter!]"
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10,
    "type": "Int"
  },
  v1,
  v2
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startDate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "endDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tz",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "country",
  "args": null,
  "storageKey": null
},
v12 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "EventRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v4,
          v5,
          v6,
          v7,
          v8,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "primaryLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "Location",
            "plural": false,
            "selections": [
              v5,
              v9,
              v10,
              v11
            ]
          }
        ]
      }
    ]
  }
],
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  v1,
  v2
],
v14 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "EventRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v4,
          v5,
          v6,
          v7,
          v8,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "primaryLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "Location",
            "plural": false,
            "selections": [
              v5,
              v9,
              v10,
              v11,
              v4
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventSearchQuery",
  "id": null,
  "text": "query EventSearchQuery(\n  $query: String!\n  $includeSuggested: Boolean!\n) {\n  org {\n    allEvents: events(first: 10, query: $query, requestStatuses: [N_A, APPROVED]) {\n      edges {\n        node {\n          id\n          name\n          startDate\n          endDate\n          tz\n          primaryLocation {\n            name\n            city\n            state\n            country\n            id\n          }\n        }\n      }\n    }\n    suggestedEvents: events(first: 3, query: $query, requestStatuses: [N_A, APPROVED]) @include(if: $includeSuggested) {\n      edges {\n        node {\n          id\n          name\n          startDate\n          endDate\n          tz\n          primaryLocation {\n            name\n            city\n            state\n            country\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "allEvents",
            "name": "events",
            "storageKey": null,
            "args": v3,
            "concreteType": "EventRequiredConnection",
            "plural": false,
            "selections": v12
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "includeSuggested",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "suggestedEvents",
                "name": "events",
                "storageKey": null,
                "args": v13,
                "concreteType": "EventRequiredConnection",
                "plural": false,
                "selections": v12
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventSearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "allEvents",
            "name": "events",
            "storageKey": null,
            "args": v3,
            "concreteType": "EventRequiredConnection",
            "plural": false,
            "selections": v14
          },
          v4,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "includeSuggested",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "suggestedEvents",
                "name": "events",
                "storageKey": null,
                "args": v13,
                "concreteType": "EventRequiredConnection",
                "plural": false,
                "selections": v14
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cfe4846067cfb3abc3041fc7d0e7347d';
module.exports = node;
