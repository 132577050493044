/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';
import sortBy from 'lodash/sortBy';

import FeatureAccessContext from 'contexts/FeatureAccess';

import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import type { SortParam } from 'utils/routing/parseTypedQueryString';
import { type DateRangeParam, type NumberRangeParam } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import NoResult from 'images/noResult.svg';
import { getDefaultFilters, suggestedFields } from 'components/AllEvents/eventsTableColumnSettings';
import { ColsGroups } from 'components/AllEvents/lib/getEventFields';
import EmptyView from 'components/budget/EmptyView';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type { OptionGroup } from 'components/material/SelectField';

import BudgetReportingChart from './BudgetReportingChart';
import BudgetReportingHeader from './BudgetReportingHeader';
import type { BudgetReportingSavedViewsType } from './BudgetReportingPage';
import BudgetReportingTable from './BudgetReportingTable';
import parseBudgetReportingFilters from './lib/parseBudgetReportingFilters';

import type { BudgetReportingGroupContainer_org } from './__generated__/BudgetReportingGroupContainer_org.graphql';
import type { BudgetReportingGroupContainerQueryResponse } from './__generated__/BudgetReportingGroupContainerQuery.graphql';

type OrgType = $PropertyType<BudgetReportingGroupContainerQueryResponse, 'org'>;
export type BudgetReportType = $PropertyType<OrgType, 'budgetReport'>;
type BudgetReportGroupsType = $PropertyType<BudgetReportType, 'groups'>;
export type BudgetReportParentGroupType = $ElementType<BudgetReportGroupsType, 0>;
type BudgetReportingChildGroupsType = $PropertyType<BudgetReportParentGroupType, 'childGroups'>;
export type BudgetReportTotalsType = $PropertyType<BudgetReportType, 'totals'>;
export type BudgetReportingChildGroupType = $ElementType<
  $NonMaybeType<BudgetReportingChildGroupsType>,
  0,
>;

const query = graphql`
  query BudgetReportingGroupContainerQuery(
    $group1: BudgetReportingGroup
    $group2: BudgetReportingGroup
    $customGroup1: ID
    $customGroup2: ID
    $includeGroup2: Boolean!
    $filters: EventFilters!
    $sort: BudgetReportingSort
    $direction: Direction!
  ) {
    org {
      budgetReport(
        group1: $group1
        group2: $group2
        customGroup1: $customGroup1
        customGroup2: $customGroup2
        filters: $filters
        sort: $sort
        direction: $direction
      ) {
        totals {
          budgetedAmount
          plannedAmount
          actualAmount
          paidAmount
        }
        groups {
          name
          budgetedAmount
          plannedAmount
          actualAmount
          paidAmount
          childGroups @include(if: $includeGroup2) {
            name
            budgetedAmount
            plannedAmount
            actualAmount
            paidAmount
          }
        }
      }
    }
  }
`;

class BudgetReportingGroupContainer extends React.PureComponent<{
  location: Location,
  history: RouterHistory,
  org: BudgetReportingGroupContainer_org,
  savedViewId: ?string,
  savedViews: BudgetReportingSavedViewsType,
  customFieldOptions: $ReadOnlyArray<OptionGroup>,
  onFilterChange: (filterParams: {
    [string]: ?(string | ?$ReadOnlyArray<string> | boolean | NumberRangeParam | DateRangeParam),
  }) => void,
  eventFields: $ReadOnlyArray<FieldType>,
  onSetSavedView: (viewId: string) => void,
}> {
  // Key is iterated and reRender budgeted table
  // every time you add or remove filter from it
  budgetReportingTableKey: number = 1;

  chartContainerRef = React.createRef();

  handleChangeSort = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  renderTable = (response?: BudgetReportingGroupContainerQueryResponse) => {
    const { location, eventFields } = this.props;

    const filters = parseBudgetReportingFilters(
      location.search,
      this.props.org.settings.fiscalYearStart,
      eventFields,
    );
    if (filters.group1 == null && filters.customGroup1 == null) return null;
    const { sort, date, group1, group2, customGroup1, customGroup2 } = filters;
    const { org } = response || {};
    const sortFilter = sort || {
      key: 'ACTUAL_AMOUNT',
      asc: false,
    };

    return (
      <>
        {org && (
          <BudgetReportingChart
            container={this.chartContainerRef.current}
            fiscalYearStart={this.props.org.settings.fiscalYearStart}
            currency={this.props.org.settings.currency}
            group1={group1}
            group2={group2}
            customGroup1={customGroup1}
            customGroup2={customGroup2}
            budgetReport={org && org.budgetReport}
            dateFilter={date}
            onFilterChange={this.onFilterChange}
          />
        )}
        {org && org.budgetReport.groups.length === 0 ? (
          <EmptyView message="No results at this time." icon={<NoResult />} />
        ) : (
          <BudgetReportingTable
            key={this.budgetReportingTableKey}
            eventFields={eventFields}
            orgSettings={this.props.org.settings}
            report={org && org.budgetReport}
            group1={group1}
            group2={group2}
            customGroup1={customGroup1}
            customGroup2={customGroup2}
            onChangeSort={this.handleChangeSort}
            sort={sortFilter}
            location={location}
          />
        )}
      </>
    );
  };

  iterateBudgetReportingTableKey = () => {
    this.budgetReportingTableKey += 1;
  };

  onFiltersChanged = () => {
    this.iterateBudgetReportingTableKey();
  };

  onFilterChange = (filterParams: {
    [string]: ?(string | ?$ReadOnlyArray<string> | boolean | NumberRangeParam | DateRangeParam),
  }) => {
    this.iterateBudgetReportingTableKey();
    this.props.onFilterChange(filterParams);
  };

  static contextType = FeatureAccessContext;

  render() {
    const {
      org,
      location,
      eventFields,
      savedViewId,
      savedViews,
      customFieldOptions,
      onSetSavedView,
      history,
    } = this.props;
    const filters = parseBudgetReportingFilters(
      location.search,
      org.settings.fiscalYearStart,
      eventFields,
    );
    if (filters.group1 == null && filters.customGroup1 == null) return null;

    const { sort, date, group1, group2, customGroup1, customGroup2, ...eventFilters } = filters;
    const sortFilter = sort || {
      key: 'ACTUAL_AMOUNT',
      asc: false,
    };
    const customSortField: ?FieldType = eventFields.find(field => field.id === sortFilter.key);
    const defaultFilters = getDefaultFilters(eventFilters);
    const customFieldSections = sortBy(org.customFieldSections.edges, edge => edge.node.order).map(
      ({ node }) => ({
        label: node.name,
        id: node.id,
      }),
    );
    const groups = ColsGroups(
      {
        salesforceEnabled: !!org.salesforceAccount,
        marketoEnabled: !!org.marketoAccount,
        customFieldSections,
      },
      this.context.legacyFeatures,
    );
    return (
      <React.Fragment>
        <div ref={this.chartContainerRef} />
        <BudgetReportingHeader
          orgId={org.id}
          orgSettings={org.settings}
          history={history}
          filters={filters}
          savedViewId={savedViewId}
          viewerCanCreateReportingSavedView={org.viewerCanCreateReportingSavedView}
          savedViews={savedViews}
          onFilterChange={this.onFilterChange}
          onFiltersChanged={this.onFiltersChanged}
          customFieldOptions={customFieldOptions}
          onSetSavedView={onSetSavedView}
          filterOptions={eventFields}
          groups={groups}
        />
        <DefaultQueryRenderer
          query={query}
          variables={{
            group1,
            group2,
            customGroup1,
            customGroup2,
            filters: {
              ...defaultFilters,
              ...(date == null
                ? {}
                : {
                    afterDate: date.start.format(),
                    beforeDate: date.end.format(),
                  }),
              customFilters: eventFields
                .filter(
                  option =>
                    (option.fieldName == null || suggestedFields.includes(option.fieldName)) &&
                    eventFilters[option.id] != null,
                )
                .map(option => getCustomFieldVariable(eventFilters[option.id], option)),
            },
            includeGroup2: group2 != null || customGroup2 != null,
            sort: customSortField ? 'CUSTOM' : sortFilter.key,
            direction: sortFilter.asc ? 'ASC' : 'DESC',
          }}
          renderSuccess={this.renderTable}
          renderLoading={this.renderTable}
        />
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  BudgetReportingGroupContainer,
  graphql`
    fragment BudgetReportingGroupContainer_org on Org {
      id
      salesforceAccount {
        id
      }
      marketoAccount {
        id
      }
      viewerCanCreateReportingSavedView
      settings {
        currency
        fiscalYearStart
      }
      customFieldSections(sectionCustomizableType: [EVENT]) {
        edges {
          node {
            id
            name
            order
          }
        }
      }
    }
  `,
);
