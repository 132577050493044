/* @flow */

const customFieldsTypes = {
  TEXT: ['string', 'text'],
  TEXTAREA: ['text'],
  LINK: ['url'],
  NUMBER: ['integer', 'float', 'score', 'percent'],
  CURRENCY: ['currency', 'float'],
  DATE: ['date', 'datetime'],
  BOOLEAN: ['boolean'],
  USER_SELECT: ['string', 'text', 'email'],
  USER_MULTISELECT: ['string', 'text'],
  SELECT: ['string', 'text'],
  MULTISELECT: ['string', 'text'],
  DEFAULT: [],
};

export default customFieldsTypes;
