/* @flow */

import type { RequesterInfoType } from 'components/EventRequestForm/lib/types';
import validateCondition from 'components/FormLogic/lib/validateCondition';
import { type FilterItem, getFieldName } from 'components/material/Filters/FilterSelectorRow';

import type { QuestionValueWithMapping, RuleType } from '../index';

const getSavedConditions = rule => {
  return [
    ...rule.customSavedTextFilters.edges.map(edge => edge.node),
    ...rule.customSavedTextareaFilters.edges.map(edge => edge.node),
    ...rule.customSavedLinkFilters.edges.map(edge => edge.node),
    ...rule.customSavedNumberFilters.edges.map(edge => edge.node),
    ...rule.customSavedCurrencyFilters.edges.map(edge => edge.node),
    ...rule.customSavedBooleanFilters.edges.map(edge => edge.node),
    ...rule.customSavedMultiselectFilters.edges.map(({ node: filter }) => ({
      ...filter,
      optionIds: filter.options.edges
        .map(edge => (edge.node && edge.node.option ? edge.node.option.id : null))
        .filter(Boolean),
    })),
    ...rule.customSavedDateFilters.edges.map(edge => edge.node),
    ...rule.customSavedUserMultiselectFilters.edges.map(({ node: filter }) => ({
      ...filter,
      users: filter.options.edges.map(edge => edge.node.user).filter(Boolean),
    })),
  ];
};

const getFormFieldValue = (
  condition: FilterItem,
  requesterInfo: RequesterInfoType,
  questionValues: $ReadOnlyArray<QuestionValueWithMapping>,
) => {
  const fieldName = getFieldName(condition);

  if (fieldName && !condition.customField) {
    const value = questionValues.find(
      questionValue =>
        questionValue.fieldName != null &&
        [
          questionValue.fieldName,
          `${questionValue.fieldName}-${questionValue.questionId}`,
        ].includes(fieldName),
    );

    switch (fieldName.split('-')[0]) {
      case 'first_name':
        return requesterInfo.firstName;
      case 'last_name':
        return requesterInfo.lastName;
      case 'email':
        return requesterInfo.email;
      case 'BUDGETED_AMOUNT':
        return value && value.numberValue;
      case 'PLANNED_BUDGET':
        return value && value.numberValue;
      case 'LEADER':
        return value && value.selectIdsValue.map(selectValue => ({ id: selectValue }));
      default:
        return value && value.textValue;
    }
  }

  if (condition.customField == null) return null;

  const customFieldId = condition.customField && condition.customField.id;
  const value = questionValues.find(
    questionValue =>
      questionValue.customField != null && questionValue.customField.id === customFieldId,
  );
  if (value == null || value.customField == null) return null;

  switch (value.customField.kind) {
    case 'TEXT':
    case 'LINK':
      return value.textValue;
    case 'TEXTAREA':
      return value.textareaValue;
    case 'NUMBER':
    case 'CURRENCY':
      return value.numberValue;
    case 'BOOLEAN':
      return value.booleanValue;
    case 'DATE':
      return value.dateValue && value.dateValue.startDate;
    case 'SELECT':
    case 'MULTISELECT':
    case 'USER_SELECT':
    case 'USER_MULTISELECT':
      // Convert to a type that validateCondition reads
      return (value.textValue
        ? [value.textValue, ...value.selectIdsValue]
        : value.selectIdsValue
      ).map(selectValue => ({ id: selectValue }));
    default:
      return null;
  }
};

const validateRulesAndConditions = (
  rules: $ReadOnlyArray<RuleType>,
  requesterInfo: RequesterInfoType,
  questionValues: $ReadOnlyArray<QuestionValueWithMapping>,
  tz: string,
) => {
  return rules.reduce((ruleValidated, rule) => {
    // return if one of the rules meets given conditions
    if (ruleValidated) {
      return true;
    }

    return getSavedConditions(rule).reduce((conditionCheck, condition) => {
      const castCondition = ((condition: any): FilterItem);
      // If one condition is false then the rule should not be applied
      if (conditionCheck === false) {
        return false;
      }

      const values = getFormFieldValue(castCondition, requesterInfo, questionValues);

      return validateCondition(castCondition, values, tz);
    }, true);
  }, false);
};

export default validateRulesAndConditions;
