/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import countries from 'config/countries.json';
import usStates from 'config/us_states.json';

import { type NumberRangeParam } from 'utils/routing/parseTypedQueryString';

import { Remove as RemoveIcon } from 'images';
import { type Operator } from 'components/ContactForm/ScheduledEmailTemplate/__generated__/ScheduledEmailFilters_eventEmailTemplate.graphql';
import { type ScheduledEmailFilters_org } from 'components/ContactForm/ScheduledEmailTemplate/__generated__/ScheduledEmailFilters_org.graphql';
import DateTimeRangePicker, { type DatesConfig } from 'components/date/DateTimeRangePicker';
import staticFields from 'components/FormLogic/lib/staticFields';
import OrgUserSearch from 'components/InviteWindow/OrgUserSearch';
import UserSelectionRow from 'components/InviteWindow/SelectionRow/UserSelectionRow';
import AutocompleteFilterItem from 'components/material/Filters/Advanced/AutocompleteFilterItem';
import RangeFilterItem from 'components/material/Filters/Advanced/RangeFilterItem';
import MultiSelectField from 'components/material/MultiSelectField';
import MultiValueSearchSelect from 'components/material/MultiSelectField/MultiValueSearchSelect';
import SelectField, { type SelectItem } from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import UserSelect, { type User } from 'components/UserSelect';

export type CustomFieldType = $PropertyType<
  $ElementType<$PropertyType<$PropertyType<ScheduledEmailFilters_org, 'customFields'>, 'edges'>, 0>,
  'node',
>;

export type FilterItem = {
  id?: string,
  order?: number,
  fieldName?: ?string,
  operator?: Operator,
  values?: $ReadOnlyArray<string>,
  value?: ?boolean,
  minValue?: ?number | string,
  maxValue?: ?number | string,
  errors: { field?: string, value?: ?string },
  optionIds?: ?$ReadOnlyArray<string>,
  users?: $ReadOnlyArray<User>,
  customField?: ?CustomFieldType,
};

export const OPERATOR_VALUES: Array<SelectItem<Operator>> = [
  {
    value: 'equal',
    label: '= (is)',
  },
  {
    value: 'not_equal',
    label: '≠ (is not)',
  },
  {
    value: 'text_in',
    label: 'Contains',
  },
  {
    value: 'text_not_in',
    label: 'Does not contain',
  },
  {
    value: 'in',
    label: 'Contains',
  },
  {
    value: 'not_in',
    label: 'Does not contain',
  },
  {
    value: 'is_null',
    label: 'Is empty',
  },
  {
    value: 'is_not_null',
    label: 'Is not empty',
  },
  {
    value: 'text_starts_with',
    label: 'Starts with',
  },
  {
    value: 'text_ends_with',
    label: 'Ends with',
  },
];

const FILTER_KIND_BY_NAME = {
  // Generic fields
  first_name: 'TEXT',
  last_name: 'TEXT',
  email: 'TEXT',
  // ERF fields
  NAME: 'TEXT',
  BUDGETED_AMOUNT: 'CURRENCY',
  PLANNED_BUDGET: 'CURRENCY',
  LEADER: 'USER_SELECT',
  BOOTH: 'TEXT',
  BOOTH_DIMENSIONS: 'TEXT',
  FLOOR_PLAN: 'TEXT',
  WEBSITE: 'LINK',
  types: 'MULTISELECT',
  roles: 'MULTISELECT',
  // Contact fields
  contact_type_id: 'SELECT',
  company_name: 'TEXT',
  title: 'TEXT',
  description: 'TEXT',
  phone: 'TEXT',
  phone1: 'TEXT',
  phone2: 'TEXT',
  website: 'LINK',
  hot_lead: 'BOOLEAN',
  email_opt_in: 'BOOLEAN',
  owner_id: 'USER_SELECT',
  city: 'TEXT',
  state: 'TEXT',
  country: 'TEXT',
  zip: 'TEXT',
  registration_status_id: 'SELECT',
  attendance_status_id: 'SELECT',
};

const FilterContent = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 15px;
`;

const FilterContentInner = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 15px;
  flex: 1;
  ${props =>
    props.highlightOnRemove
      ? css`
          margin: 0 -30px ${props.hasErrors ? 0 : 10}px -10px;
          padding: 0 30px ${props.hasErrors ? 20 : 10}px 10px;
        `
      : css`
          margin-bottom: 20px;
        `}
  ${props =>
    props.hoverOnDeleteIcon &&
    css`
      background: rgba(0, 0, 0, 0.05);
    `}
`;

const ConditionPlace = styled.div`
  width: 35px;
  flex: 0 0 35px;
  margin-top: 13px;
`;

const FilterFieldPlace = styled.div`
  flex: 0 0 186px;
  margin-top: 5px;
  input {
    line-height: 20.8px;
  }
`;

const OperatorPlace = styled.div`
  flex: 0 0 120px;
  margin-top: 5px;
`;

const FilterValuePlace = styled.div`
  flex: 1 1 auto;
  label {
    margin-top: 2px;
    margin-bottom: 0;
  }
  input {
    line-height: 23.4px;
  }
`;

const RangeInnerContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;
`;

const SelectionList = styled.div`
  margin: 15px 0;
`;

const Error = styled.div`
  font-size: 11px;
  color: #f38183;
`;

const RemoveAction = styled.div`
  flex: 0 0 20px;
  align-self: flex-start;
  margin-top: 10px;
  margin-left: auto;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const StyledSelect = styled(SelectField)`
  margin-top: 2px;
`;

const StyledUserSelect = styled(UserSelect)`
  margin-top: 2px;
`;

const StyledMultiSelectField = styled(MultiSelectField)`
  margin-top: 10px;
`;

const StyledRangeFilterItem = styled(RangeFilterItem)`
  margin-bottom: 0;
  padding: 0;
`;

const StyledAutocompleteFilterItem = styled(AutocompleteFilterItem)`
  margin-left: 0;
`;

export const getFieldName = (filterItem: FilterItem): ?string => {
  if (filterItem.customField) {
    return filterItem.customField.fieldName;
  }

  return filterItem.fieldName;
};

export const getKind = (filterItem: FilterItem): string => {
  const fieldName = getFieldName(filterItem);

  if (fieldName) {
    return FILTER_KIND_BY_NAME[fieldName.split('-')[0]];
  }

  return filterItem.customField ? filterItem.customField.kind : '';
};

export default class FilterSelectorRow extends React.Component<
  {
    filterItem: FilterItem,
    onUpdate: (filterItem: FilterItem, oldFilterItem?: FilterItem) => void,
    onRemove: () => void,
    filterFields: $ReadOnlyArray<
      CustomFieldType | {| fieldName: string, label: string, order?: number |},
    >,
    usedFilters: $ReadOnlyArray<FilterItem>,
    tz: string,
    index: number,
    filterSelectPlaceholder?: string,
    conditionStartLabel: 'If' | 'Where',
    nonRemovable?: boolean,
    highlightOnRemove?: boolean,
  },
  {
    hoverOnDeleteIcon: boolean,
  },
> {
  state = {
    hoverOnDeleteIcon: false,
  };

  getOperatorValues = () => {
    const kind = getKind(this.props.filterItem);
    const fieldName = getFieldName(this.props.filterItem);

    if (kind.includes('MULTISELECT')) {
      return OPERATOR_VALUES.filter(item =>
        ['in', 'not_in', 'is_null', 'is_not_null'].includes(item.value),
      );
    }

    if (['TEXT', 'TEXTAREA', 'LINK'].includes(kind)) {
      const excludedStaticFieldOperatorValues = Object.keys(staticFields)
        .filter(item => item !== 'company_name')
        .includes(fieldName)
        ? ['is_null', 'is_not_null']
        : [];

      return OPERATOR_VALUES.filter(
        item => !['in', 'not_in', ...excludedStaticFieldOperatorValues].includes(item.value),
      );
    }

    return OPERATOR_VALUES.filter(
      item =>
        !['text_in', 'text_not_in', 'text_starts_with', 'text_ends_with'].includes(item.value),
    );
  };

  handleChangeFilterItem = (value: ?string) => {
    const { filterItem, filterFields, onUpdate } = this.props;

    const newFilterItem: FilterItem = { errors: {} };

    if (Object.keys(staticFields).includes(value)) {
      if (filterItem.fieldName === value) {
        return;
      }

      newFilterItem.fieldName = value;
      newFilterItem.customField = null;
    } else {
      const defaultField = filterFields.find(
        filterField => filterField.id == null && filterField.fieldName === value,
      );
      if (defaultField) {
        if (filterItem.fieldName === value) {
          return;
        }

        newFilterItem.fieldName = value;
        newFilterItem.customField = null;
      } else {
        if (filterItem.customField && filterItem.customField.id === value) {
          return;
        }
        // $FlowFixMe https://github.com/facebook/flow/issues/6356
        newFilterItem.customField = filterFields.find(filterField => filterField.id === value);
      }
    }

    const kind = getKind(newFilterItem);
    newFilterItem.operator = ['MULTISELECT', 'USER_MULTISELECT'].includes(kind) ? 'in' : 'equal';
    if (kind === 'BOOLEAN') {
      newFilterItem.value = true;
    }

    onUpdate(newFilterItem, filterItem);
  };

  handleChangeOperator = (value: ?Operator) => {
    const { filterItem, onUpdate } = this.props;

    if (value) {
      filterItem.operator = value;
      filterItem.minValue = null;
      filterItem.maxValue = null;
      filterItem.users = [];
      filterItem.optionIds = [];
      filterItem.values = [];
      filterItem.value = null;
      filterItem.errors.value = null;
    }

    onUpdate(filterItem);
  };

  handleChangeTextFilterValue = (e: SyntheticEvent<HTMLInputElement>) => {
    const { filterItem, onUpdate } = this.props;
    const value = e.currentTarget.value;

    filterItem.values = [value];
    filterItem.errors.value = value.trim() ? null : 'Value is required';

    onUpdate(filterItem);
  };

  handleChangeBooleanFilterValue = (value: ?boolean) => {
    const { filterItem, onUpdate } = this.props;

    filterItem.value = value;

    onUpdate(filterItem);
  };

  handleChangeNumberValue = (name: string, numberParam: ?NumberRangeParam) => {
    const { filterItem, onUpdate } = this.props;

    filterItem.minValue = numberParam ? numberParam.min : null;
    filterItem.maxValue = numberParam ? numberParam.max : null;
    filterItem.errors.value =
      filterItem.minValue != null || filterItem.maxValue != null
        ? null
        : 'Either minimum or maximum value is required';

    onUpdate(filterItem);
  };

  handleChangeDateValue = (datesConfig: DatesConfig) => {
    const { filterItem, onUpdate } = this.props;

    filterItem.minValue = datesConfig.startDate;
    filterItem.maxValue = datesConfig.endDate;

    filterItem.errors.value =
      filterItem.minValue != null || filterItem.maxValue != null ? null : 'Date range is required';

    onUpdate(filterItem);
  };

  handleValidateDateValues = () => {
    const { filterItem, onUpdate } = this.props;

    filterItem.errors.value =
      filterItem.minValue != null || filterItem.maxValue != null ? null : 'Date range is required';

    onUpdate(filterItem);
  };

  handleChangeSelectFilterValue = (value: ?string, paramName: string = 'optionIds') => {
    const { filterItem, onUpdate } = this.props;
    if (value) {
      filterItem.errors.value = null;
      filterItem[paramName] = [value];
    }
    onUpdate(filterItem);
  };

  handleSelectMultiSelectFilterValue = (value: string, paramName: string = 'optionIds') => {
    const { filterItem, onUpdate } = this.props;

    filterItem[paramName] = filterItem[paramName] ? [...filterItem[paramName], value] : [value];
    if (filterItem.errors.value) {
      filterItem.errors.value = null;
    }

    onUpdate(filterItem);
  };

  handleUnSelectMultiSelectFilterValue = (value: string, paramName: string = 'optionIds') => {
    const { filterItem, onUpdate } = this.props;
    filterItem[paramName] = (filterItem[paramName] || []).filter(optionId => optionId !== value);

    onUpdate(filterItem);
  };

  handleChangeUserSelectFilterValue = (value: ?User) => {
    const { filterItem, onUpdate } = this.props;
    if (value) {
      filterItem.users = [value];
      filterItem.errors.value = null;
    }

    onUpdate(filterItem);
  };

  handleChangeUserMultiSelectFilterValue = (users: $ReadOnlyArray<User>) => {
    const { filterItem, onUpdate } = this.props;
    filterItem.users = users;
    filterItem.errors.value = users && users.length > 0 ? null : 'At least one member is required';
    onUpdate(filterItem);
  };

  handleStateSelectValueChange = (name: string, values: $ReadOnlyArray<string>) => {
    const { filterItem, onUpdate } = this.props;
    filterItem.values = values;

    onUpdate(filterItem);
  };

  render() {
    const {
      filterFields,
      usedFilters,
      filterItem,
      tz,
      index,
      filterSelectPlaceholder,
      conditionStartLabel,
      nonRemovable,
      highlightOnRemove,
    } = this.props;
    const fieldSelectOptions = filterFields
      .filter(filterField => {
        // If item is selected before should be in available options
        if (
          (filterField.fieldName &&
            Object.keys(staticFields).includes(filterField.fieldName) &&
            filterField.fieldName === filterItem.fieldName) ||
          (!filterItem.customField && filterField.fieldName === filterItem.fieldName) ||
          (filterItem.customField && filterField.id === filterItem.customField.id)
        ) {
          return true;
        }

        // If item is used in other options should be filtered
        if (
          usedFilters.some(
            item =>
              (Object.keys(staticFields).includes(item.fieldName) &&
                item.fieldName === filterField.fieldName) ||
              (!item.customField && filterField.fieldName === item.fieldName) ||
              (item.customField && filterField.id && item.customField.id === filterField.id),
          )
        ) {
          return false;
        }
        return true;
      })
      .map(filterField => ({
        value: filterField.id || filterField.fieldName,
        label: filterField.label,
      }));

    const fieldName = getFieldName(filterItem);
    const kind = getKind(filterItem);

    const operatorDefaultValue = kind.includes('MULTISELECT') ? 'in' : 'equal';
    const operatorSelectedValue = filterItem.operator || operatorDefaultValue;

    const showTextFieldValues =
      !['country', 'state'].includes(fieldName) &&
      ['TEXT', 'TEXTAREA', 'LINK'].includes(kind) &&
      !['is_null', 'is_not_null'].includes(operatorSelectedValue);
    const showBooleanFieldValues = kind === 'BOOLEAN';
    const showOperatorSelect = kind && !['DATE', 'CURRENCY', 'NUMBER'].includes(kind);
    const showNumberRangeValues = ['NUMBER', 'CURRENCY'].includes(kind);
    const showDateRangeValues = kind === 'DATE';
    const showSelectFieldValues =
      ['SELECT', 'MULTISELECT'].includes(kind) &&
      ['equal', 'not_equal'].includes(filterItem.operator);
    const showMultiSelectFieldValues =
      ['SELECT', 'MULTISELECT'].includes(kind) && ['in', 'not_in'].includes(operatorSelectedValue);
    const showUserSelectFieldValues =
      ['USER_SELECT', 'USER_MULTISELECT'].includes(kind) &&
      ['equal', 'not_equal'].includes(operatorSelectedValue);
    const showUserMultiSelectFieldValues =
      ['USER_SELECT', 'USER_MULTISELECT'].includes(kind) &&
      ['in', 'not_in'].includes(operatorSelectedValue);

    // Country and state are text fields but in filters should be select
    const showStateSelectValues =
      fieldName === 'state' && ['equal', 'not_equal'].includes(filterItem.operator);
    const showStateMultiSelectValues =
      fieldName === 'state' &&
      ['text_in', 'text_not_in', 'text_ends_with', 'text_starts_with'].includes(
        filterItem.operator,
      );
    const showCountrySelectValues =
      fieldName === 'country' && ['equal', 'not_equal'].includes(filterItem.operator);
    const showCountryMultiSelectValues =
      fieldName === 'country' &&
      ['text_in', 'text_not_in', 'text_ends_with', 'text_starts_with'].includes(
        filterItem.operator,
      );
    const countryOptions =
      showCountrySelectValues || showCountryMultiSelectValues
        ? ['United States', ...countries.filter(c => c !== 'United States')].map(c => ({
            label: c,
            value: c,
          }))
        : [];

    return (
      <FilterContent>
        <ConditionPlace>{index === 0 ? conditionStartLabel : 'And'}</ConditionPlace>
        <FilterContentInner
          hoverOnDeleteIcon={highlightOnRemove && this.state.hoverOnDeleteIcon}
          highlightOnRemove={highlightOnRemove}
          hasErrors={filterItem.errors.value || filterItem.errors.field}
        >
          <FilterFieldPlace>
            <SelectField
              placeholder={filterSelectPlaceholder || 'Filter by…'}
              searchable
              error={filterItem.errors.field}
              value={filterItem.customField ? filterItem.customField.id : filterItem.fieldName}
              options={fieldSelectOptions}
              onChange={this.handleChangeFilterItem}
            />
          </FilterFieldPlace>
          {showOperatorSelect && (
            <OperatorPlace>
              <SelectField
                value={operatorSelectedValue}
                options={this.getOperatorValues()}
                onChange={this.handleChangeOperator}
                disabled={showBooleanFieldValues}
              />
            </OperatorPlace>
          )}
          <FilterValuePlace>
            {showTextFieldValues && (
              <TextField
                placeholder="Enter a value"
                value={filterItem.values ? filterItem.values.join(', ') : ''}
                required
                error={filterItem.errors.value}
                onChange={this.handleChangeTextFilterValue}
                onBlur={this.handleChangeTextFilterValue}
              />
            )}
            {showBooleanFieldValues && (
              <StyledSelect
                value={filterItem.value !== undefined ? filterItem.value : true}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]}
                onChange={this.handleChangeBooleanFilterValue}
              />
            )}
            {showSelectFieldValues && (
              <StyledSelect
                placeholder="Select a value"
                searchable
                value={filterItem.optionIds ? filterItem.optionIds[0] : null}
                options={
                  filterItem.customField && filterItem.customField.options
                    ? filterItem.customField.options.edges.map(({ node: option }) => ({
                        value: option.id,
                        label: option.name,
                      }))
                    : []
                }
                onChange={this.handleChangeSelectFilterValue}
                error={filterItem.errors.value}
              />
            )}
            {showMultiSelectFieldValues && (
              <StyledMultiSelectField
                isStretched
                placeholder="Select value(s)"
                values={filterItem.optionIds}
                options={
                  filterItem.customField && filterItem.customField.options
                    ? filterItem.customField.options.edges.map(({ node: option }) => ({
                        value: option.id,
                        label: option.name,
                      }))
                    : []
                }
                onSelect={this.handleSelectMultiSelectFilterValue}
                onUnselect={this.handleUnSelectMultiSelectFilterValue}
                error={filterItem.errors.value}
                searchable
              />
            )}
            {showCountrySelectValues && (
              <StyledSelect
                placeholder="Select a value"
                searchable
                value={filterItem.values ? filterItem.values[0] : null}
                options={countryOptions}
                onChange={value => this.handleChangeSelectFilterValue(value, 'values')}
                error={filterItem.errors.value}
              />
            )}
            {showCountryMultiSelectValues && (
              <StyledMultiSelectField
                isStretched
                placeholder="Select value(s)"
                values={filterItem.values}
                options={countryOptions}
                onSelect={value => this.handleSelectMultiSelectFilterValue(value, 'values')}
                onUnselect={value => this.handleUnSelectMultiSelectFilterValue(value, 'values')}
                error={filterItem.errors.value}
                searchable
              />
            )}
            {showStateSelectValues && (
              <StyledAutocompleteFilterItem
                label="Select a value"
                name=""
                skipHeader
                onChange={this.handleStateSelectValueChange}
                error={filterItem.errors ? filterItem.errors.value : null}
                activeValues={filterItem.values}
                singleOption
                options={Object.entries(usStates).map(([key, value]) => [
                  String(value),
                  String(key),
                ])}
                allowCustomValue={fieldName === 'state'}
                renderOptionString={option => option}
              />
            )}
            {showStateMultiSelectValues && (
              <MultiValueSearchSelect
                placeholder="Select value(s)"
                selectedValues={filterItem.values}
                onChange={values => this.handleStateSelectValueChange('', values)}
                options={Object.entries(usStates).map(([key, value]) => [
                  String(value),
                  String(key),
                ])}
                error={filterItem.errors.value}
              />
            )}
            {showNumberRangeValues && (
              <div>
                <RangeInnerContainer>
                  <StyledRangeFilterItem
                    name=""
                    minPlaceholder="Greater than"
                    maxPlaceholder="Less than"
                    onChange={this.handleChangeNumberValue}
                    activeValue={{
                      min: filterItem.minValue != null ? parseInt(filterItem.minValue, 10) : null,
                      max: filterItem.maxValue != null ? parseInt(filterItem.maxValue, 10) : null,
                    }}
                    isCurrency={kind === 'CURRENCY'}
                    skipHeader
                    error={!!filterItem.errors.value}
                  />
                </RangeInnerContainer>
                {filterItem.errors && <Error>{filterItem.errors.value}</Error>}
              </div>
            )}
            {showDateRangeValues && (
              <div>
                <DateTimeRangePicker
                  tz={tz}
                  startDate={filterItem.minValue != null ? filterItem.minValue.toString() : null}
                  endDate={filterItem.maxValue != null ? filterItem.maxValue.toString() : null}
                  hideEndTime
                  hideStartTime
                  startPlaceholder="Start Date"
                  endPlaceholder="End Date"
                  onChange={this.handleChangeDateValue}
                  onBlur={this.handleValidateDateValues}
                  datesOnly
                  error={filterItem.errors.value}
                />
              </div>
            )}
            {showUserSelectFieldValues && (
              <StyledUserSelect
                placeholder="Select a member"
                user={filterItem.users && filterItem.users[0]}
                onSelect={this.handleChangeUserSelectFilterValue}
                error={filterItem.errors.value}
                hideCreateNewOption
              />
            )}
            {showUserMultiSelectFieldValues && (
              <div>
                <OrgUserSearch
                  placeholder="Select member(s)"
                  onSelect={selectedUser => {
                    if (
                      filterItem.users &&
                      filterItem.users.find(user => user.id === selectedUser.id)
                    )
                      return;
                    this.handleChangeUserMultiSelectFilterValue([
                      ...(filterItem.users || []),
                      selectedUser,
                    ]);
                  }}
                  error={filterItem.errors.value}
                />
                {filterItem.users && filterItem.users.length > 0 && (
                  <SelectionList>
                    {filterItem.users.map(user => (
                      <UserSelectionRow
                        key={user.id}
                        noAvatar
                        invite={{ ...user, uid: user.id }}
                        onRemove={userId =>
                          this.handleChangeUserMultiSelectFilterValue(
                            (filterItem.users || []).filter(u => u.id !== userId),
                          )
                        }
                      />
                    ))}
                  </SelectionList>
                )}
              </div>
            )}
          </FilterValuePlace>
          {!nonRemovable && (
            <RemoveAction
              onMouseEnter={() => highlightOnRemove && this.setState({ hoverOnDeleteIcon: true })}
              onMouseLeave={() => highlightOnRemove && this.setState({ hoverOnDeleteIcon: false })}
              onClick={this.props.onRemove}
            >
              <RemoveIcon />
            </RemoveAction>
          )}
        </FilterContentInner>
      </FilterContent>
    );
  }
}
