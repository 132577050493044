import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const QuickFadeIn = styled.div`
  animation: ${fadeIn} 0.15s;
`;

export default QuickFadeIn;
