/* @flow */
import React from 'react';
import styled from 'styled-components';

import Switch from 'components/material/Switch';

const CompanyFieldsAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 85px 20px;
  padding: 11px 24px 11px 15px;
  background-color: #f1f9fc;
`;

const Text = styled.div`
  font-size: 13px;
  color: #616c79;
`;

const SubText = styled.div`
  opacity: 0.6;
  font-size: 12px;
  font-style: italic;
  line-height: 1.08;
  color: #616c79;
`;

type Props = {
  onCompanyFieldsToggle: (enabled: boolean) => void,
  enableCompanyFields: boolean,
};

const CompanyFieldsToggle = ({ onCompanyFieldsToggle, enableCompanyFields }: Props) => {
  return (
    <CompanyFieldsAction>
      <div>
        <Text>Include Company fields</Text>
        <SubText>Include Company fields. Company name is required to sync to CRM</SubText>
      </div>
      <Switch enabled={enableCompanyFields} onChange={onCompanyFieldsToggle} size="small" />
    </CompanyFieldsAction>
  );
};

export default CompanyFieldsToggle;
