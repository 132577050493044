/* @flow */
import * as React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';

import addCompanyFields from 'graph/mutations/contactForm/addCompanyFields';
import addContactFormField from 'graph/mutations/contactForm/addContactFormField';
import removeContactFormField from 'graph/mutations/contactForm/removeContactFormField';
import reorderContactForm from 'graph/mutations/contactForm/reorderContactForm';
import updateContactFormField from 'graph/mutations/contactForm/updateContactFormField';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Plus from 'images/plus.svg';
import ConfirmationWindow from 'components/ConfirmationWindow';
import EditableQuestion from 'components/ContactForm/EditableQuestion';
import isUserType from 'components/ContactForm/lib/isUserType';
import type { QuestionType, ReorderedQuestionType } from 'components/ContactForm/lib/types';
import SharedFormThemeContext, {
  type SharedFormThemeType,
} from 'components/SharedForm/lib/SharedFormThemeContext';

import CompanyFieldsToggle from './CompanyFieldsToggle';
import MandatoryFields from './MandatoryFields';

import type { ContactFormBuilder_contactForm } from './__generated__/ContactFormBuilder_contactForm.graphql';
import type { ContactFormBuilder_org } from './__generated__/ContactFormBuilder_org.graphql';

type ContactFormFieldType = $PropertyType<
  $ElementType<
    $PropertyType<$PropertyType<ContactFormBuilder_contactForm, 'contactFormFields'>, 'edges'>,
    0,
  >,
  'node',
>;

export type RulesType = $PropertyType<ContactFormFieldType, 'rules'>;

export const Container = styled.div`
  position: relative;
`;

const AddQuestionButton = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 85px;
  padding: 11px 20px;
  border: 1px dashed #5db5d5;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  color: #3ba9da;
  cursor: pointer;
  outline: none;

  &:hover {
    background: #edf2f5;
  }
`;

const AddQuestionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 9px;
  border-radius: 50%;
  background: #3ba9da;
  color: #fff;
`;

class ContactFormBuilder extends React.PureComponent<
  {
    org: ContactFormBuilder_org,
    contactForm: ContactFormBuilder_contactForm,
    theme?: SharedFormThemeType,
    defaultFont?: string,
  },
  {
    newQuestions: $ReadOnlyArray<QuestionType>,
    addedQuestionIds: $ReadOnlyArray<string>,
    adding: boolean,
    togglingCompanyFields: boolean,
    dependantFieldId: string,
    restrictedDruggingZone: boolean,
  },
> {
  state = {
    newQuestions: [],
    adding: false,
    togglingCompanyFields: false,
    addedQuestionIds: [],
    dependantFieldId: '',
    restrictedDruggingZone: false,
  };

  hasDependency: boolean = false;

  getAllCustomFields = () =>
    this.props.org.customFields.edges
      .map(edge => edge.node)
      .filter(question => question.fieldName !== 'company_id');

  getSortedContactFormFields = (): $ReadOnlyArray<QuestionType> => {
    const contactForm = this.props.contactForm;

    const contactFormFields: $ReadOnlyArray<QuestionType> = [
      ...contactForm.contactFormFields.edges.map(({ node: contactFormField }) => {
        const customField = this.getAllCustomFields().find(
          c => contactFormField.customField && c.id === contactFormField.customField.id,
        );

        const contactFormFieldOptions =
          contactFormField.contactFormFieldOptions &&
          contactFormField.contactFormFieldOptions.edges.map(
            ({ node }) => node.customFieldOption.id,
          );

        const contactFormFieldUserOptions =
          contactFormField.contactFormFieldUserOptions &&
          contactFormField.contactFormFieldUserOptions.edges.map(({ node }) => node.user.id);

        const customFieldNormalized = customField && {
          ...omit(customField, 'options'),
          options: sortBy(
            customField.options.edges.map(({ node }) => ({
              label: node.name,
              value: node.id,
            })),
            'label',
          ),
        };

        return {
          ...omit(contactFormField, ['contactFormFieldOptions', 'contactFormFieldUserOptions']),
          contactFormFieldOptions: isUserType(customFieldNormalized)
            ? contactFormFieldUserOptions
            : contactFormFieldOptions,
          customField: customFieldNormalized,
        };
      }),
      ...this.state.newQuestions,
    ];

    return sortBy(contactFormFields, 'order');
  };

  handleCreateQuestion = () => {
    const contactFormFields = this.getSortedContactFormFields();
    const lastQuestion = contactFormFields[contactFormFields.length - 1];
    const order = (lastQuestion ? lastQuestion.order : 0) + 1;
    const newQuestion: QuestionType = {
      id: uniqueId('question_'),
      order,
      label: '',
      fieldName: '',
      required: false,
      helpText: null,
      customField: null,
      showOtherOption: true,
      contactFormFieldOptions: [],
    };

    this.setState(prevState => ({ newQuestions: [...prevState.newQuestions, newQuestion] }));
  };

  handleConfirmationHide = () => {
    this.setState({ dependantFieldId: '' });
  };

  ruleDependencyChecker = (customFieldId: ?string, fieldName: ?string, rules?: RulesType) => {
    return (
      rules != null &&
      rules.edges.some(({ node: rule }) => {
        return [
          ...rule.customSavedTextFilters.edges,
          ...rule.customSavedTextareaFilters.edges,
          ...rule.customSavedLinkFilters.edges,
          ...rule.customSavedNumberFilters.edges,
          ...rule.customSavedCurrencyFilters.edges,
          ...rule.customSavedBooleanFilters.edges,
          ...rule.customSavedMultiselectFilters.edges,
          ...rule.customSavedDateFilters.edges,
          ...rule.customSavedUserMultiselectFilters.edges,
        ].some(({ node: field }) => {
          if (field == null) return false;

          if (field.customField != null) {
            return field.customField.id === customFieldId;
          }

          return field.fieldName && field.fieldName === fieldName;
        });
      })
    );
  };

  handleCheckMappingDependency = (fieldId: ?string, fieldName: ?string) => {
    const contactFormFields = this.getSortedContactFormFields();

    const field = contactFormFields.find(item => item.id === fieldId);
    const customFieldId = field && field.customField != null ? field.customField.id : '';

    return contactFormFields.some(contactFormField =>
      this.ruleDependencyChecker(customFieldId, fieldName || null, contactFormField.rules),
    );
  };

  handleRemoveQuestion = (fieldId: string) => {
    const contactFormFields = this.getSortedContactFormFields();

    const field = contactFormFields.find(item => item.id === fieldId);
    const customFieldId = field && field.customField != null ? field.customField.id : '';

    const hasDependency = contactFormFields
      .filter(formField => formField.id !== fieldId)
      .some(formField => this.ruleDependencyChecker(customFieldId, null, formField.rules));

    if (hasDependency) {
      this.setState({
        dependantFieldId: fieldId,
      });
    } else {
      this.handleRemove(fieldId);
    }
  };

  handleRemove = (fieldId: string) => {
    if (fieldId.startsWith('question_')) {
      this.setState(prevState => ({
        newQuestions: prevState.newQuestions.filter(question => question.id !== fieldId),
      }));
    } else {
      removeContactFormField({
        contactFormId: this.props.contactForm.id,
        contactFormFieldIds: [fieldId],
      })
        .then(() => {
          if (this.state.dependantFieldId) {
            this.setState({ dependantFieldId: '' });
          }
        })
        .catch(showModernMutationError);
    }
  };

  handleMoveQuestion = (reorderedQuestion: ReorderedQuestionType) => {
    const { question, order } = reorderedQuestion;
    if (question.order === order) {
      this.setState({ restrictedDruggingZone: false });
      return;
    }

    const sortedFields = this.getSortedContactFormFields();
    const direction = question.order > order ? 'up' : 'down';
    const initialQuestionIndex = sortedFields.indexOf(question);
    const currentQuestionIndex = sortedFields.findIndex(field => field.order === order);
    const questionsInBetween =
      direction === 'up'
        ? sortedFields.slice(currentQuestionIndex, initialQuestionIndex)
        : sortedFields.slice(initialQuestionIndex + 1, currentQuestionIndex + 1);

    const hasDependency = questionsInBetween.some(field => {
      return (
        (direction === 'up' &&
          this.ruleDependencyChecker(
            field.customField != null ? field.customField.id : null,
            field.fieldName || null,
            question.rules,
          )) ||
        (direction === 'down' &&
          this.ruleDependencyChecker(
            question.customField != null ? question.customField.id : null,
            question.fieldName || null,
            field.rules,
          ))
      );
    });

    this.setState({ restrictedDruggingZone: hasDependency });
  };

  handleMoveEnd = (reorderedQuestion: ReorderedQuestionType) => {
    const { question, order } = reorderedQuestion;

    if (this.state.restrictedDruggingZone || question.order === order) {
      this.setState({ restrictedDruggingZone: false });
      return;
    }

    const sortedFields = this.getSortedContactFormFields();
    const direction = question.order > order ? 'up' : 'down';
    const orderOverride = sortedFields.reduce(
      (arr: $ReadOnlyArray<QuestionType>, field: QuestionType) => {
        // Field is out of reorder range. Keep existing order and return
        if (
          (direction === 'up' && (field.order < order || field.order > question.order)) ||
          (direction === 'down' && (field.order < question.order || field.order > order))
        ) {
          return [...arr, field];
        }

        // Apply the new order to the field that is being dragged
        if (field.id === question.id) {
          return [...arr, { ...field, order }];
        }

        // Reorder questions in between initial and final positions
        return [
          ...arr,
          { ...field, order: direction === 'up' ? field.order + 1 : field.order - 1 },
        ];
      },
      [],
    );

    this.setState({
      newQuestions: orderOverride.filter(field => field.id.startsWith('question_')),
    });

    reorderContactForm(
      this.props.contactForm.id,
      orderOverride
        .filter(field => !field.id.startsWith('question_'))
        .map(field => ({ id: field.id, order: field.order })),
    ).catch(showModernMutationError);
  };

  handleChangeQuestion = (question: QuestionType) => {
    const questionData = {
      fieldName: question.fieldName,
      label: question.label,
      required: question.required,
      showOtherOption: question.showOtherOption,
      helpText: question.helpText,
      customFieldId: question.customField ? question.customField.id : null,
      contactFormFieldOptionIds: !isUserType(question.customField)
        ? question.contactFormFieldOptions
        : [],
      contactFormFieldUserOptionIds: isUserType(question.customField)
        ? question.contactFormFieldOptions
        : [],
    };
    const { defaultFont, contactForm } = this.props;

    if (question.id.startsWith('question_')) {
      this.setState(prevState => ({
        newQuestions: prevState.newQuestions.filter(newQuestion => newQuestion.id !== question.id),
      }));
      addContactFormField({
        contactFormId: contactForm.id,
        order: question.order,
        defaultFont,
        ...questionData,
      }).catch(showModernMutationError);
    } else {
      const initialFieldState = this.getSortedContactFormFields().find(
        field => field.id === question.id,
      );
      const initialCustomFieldId =
        initialFieldState && initialFieldState.customField && initialFieldState.customField.id;
      const customFieldId = question.customField && question.customField.id;
      const changingMapping = initialFieldState
        ? question.fieldName !== initialFieldState.fieldName ||
          initialCustomFieldId !== customFieldId
        : false;

      updateContactFormField(
        {
          contactFormFieldId: question.id,
          defaultFont,
          ...questionData,
        },
        changingMapping,
      ).catch(showModernMutationError);
    }
  };

  handleCompanyFieldsToggle = (enabled: boolean) => {
    if (this.state.togglingCompanyFields) {
      return;
    }
    this.setState({ togglingCompanyFields: true });
    if (enabled) {
      addCompanyFields({
        contactFormId: this.props.contactForm.id,
      })
        .then(() => {
          this.setState({ togglingCompanyFields: false });
        })
        .catch(error => {
          showModernMutationError(error);
          this.setState({ togglingCompanyFields: false });
        });
    } else {
      const companyFields = this.getSortedContactFormFields().filter(
        question =>
          question.fieldName === 'company_name' ||
          (question.customField && question.customField.customizableType === 'COMPANY'),
      );
      removeContactFormField({
        contactFormId: this.props.contactForm.id,
        contactFormFieldIds: companyFields.map(question => question.id),
        enableCompanyFields: false,
      })
        .then(() => {
          this.setState({ togglingCompanyFields: false });
        })
        .catch(error => {
          showModernMutationError(error);
          this.setState({ togglingCompanyFields: false });
        });
    }
  };

  render() {
    const { contactForm, org, theme, defaultFont } = this.props;
    const contactsSyncEnabled = org.salesforceAccount
      ? org.salesforceAccount.contactsSyncEnabled
      : false;

    const contactFormFields = this.getSortedContactFormFields();

    const usedCustomFieldIds = contactFormFields
      .map(question => question.customField && question.customField.id)
      .filter(Boolean);

    const availableFields = this.getAllCustomFields()
      .filter(
        customField =>
          !usedCustomFieldIds.includes(customField.id) &&
          (contactForm.enableCompanyFields ||
            (!contactForm.enableCompanyFields && customField.customizableType !== 'COMPANY')),
      )
      .map(question => ({
        ...omit(question, 'options'),
        options: question.options.edges.map(({ node: option }) => ({
          label: option.name,
          value: option.id,
        })),
      }));

    const showAddButton = this.state.newQuestions.length === 0 && availableFields.length > 0;

    return (
      <SharedFormThemeContext.Provider value={theme || {}}>
        <Container>
          <MandatoryFields />
          {contactFormFields.map(question => (
            <EditableQuestion
              key={question.id}
              currency={org.settings.currency}
              question={question}
              editing={this.state.addedQuestionIds.includes(question.id)}
              availableFields={availableFields}
              onChangeQuestion={this.handleChangeQuestion}
              onRemoveQuestion={this.handleRemoveQuestion}
              onMoveQuestion={this.handleMoveQuestion}
              onMoveEnd={this.handleMoveEnd}
              handleCheckMappingDependency={this.handleCheckMappingDependency}
              theme={theme}
              defaultFont={defaultFont}
              restrictedDruggingZone={this.state.restrictedDruggingZone}
            />
          ))}
          {!contactsSyncEnabled && (
            <CompanyFieldsToggle
              enableCompanyFields={contactForm.enableCompanyFields}
              onCompanyFieldsToggle={this.handleCompanyFieldsToggle}
            />
          )}
          {showAddButton && (
            <AddQuestionButton onClick={this.state.adding ? null : this.handleCreateQuestion}>
              <AddQuestionIcon>
                <Plus />
              </AddQuestionIcon>
              Add Question
            </AddQuestionButton>
          )}
        </Container>
        {this.state.dependantFieldId &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              onHide={this.handleConfirmationHide}
              onConfirm={() => this.handleRemove(this.state.dependantFieldId)}
              title="Are you sure you want to remove this question?"
              message="This question has logical dependencies with other questions."
            />,
            document.body,
          )}
      </SharedFormThemeContext.Provider>
    );
  }
}

export default createFragmentContainer(
  ContactFormBuilder,
  graphql`
    fragment ContactFormBuilder_contactForm on ContactForm {
      id
      enableCompanyFields
      contactFormFields {
        edges {
          node {
            id
            __typename
            label
            fieldName
            required
            order
            helpText
            customField {
              id
            }
            rules {
              edges {
                node {
                  id
                  customSavedTextFilters {
                    edges {
                      node {
                        id
                        fieldName
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedTextareaFilters {
                    edges {
                      node {
                        id
                        fieldName
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedLinkFilters {
                    edges {
                      node {
                        id
                        fieldName
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedNumberFilters {
                    edges {
                      node {
                        id
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedCurrencyFilters {
                    edges {
                      node {
                        id
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedDateFilters {
                    edges {
                      node {
                        id
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedBooleanFilters {
                    edges {
                      node {
                        id
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedUserMultiselectFilters {
                    edges {
                      node {
                        id
                        fieldName
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedMultiselectFilters {
                    edges {
                      node {
                        id
                        customField {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
            showOtherOption
            contactFormFieldOptions {
              edges {
                node {
                  customFieldOption {
                    id
                  }
                }
              }
            }
            contactFormFieldUserOptions {
              edges {
                node {
                  user {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }

    fragment ContactFormBuilder_org on Org {
      id
      name
      settings {
        logo
        currency
      }
      salesforceAccount {
        contactsSyncEnabled
      }
      customFields: customFields(customizableType: [CONTACT, COMPANY]) {
        edges {
          node {
            id
            kind
            customizableType
            label
            fieldName
            required
            order
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
);
