/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventInfoSection_org$ref = any;
type EventInfoSection_template$ref = any;
type EventStaff_template$ref = any;
type Schedules_template$ref = any;
type Tasks_template$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Section_org$ref: FragmentReference;
export type Section_org = {|
  +briefTemplate: {|
    +$fragmentRefs: EventInfoSection_template$ref & EventStaff_template$ref & Schedules_template$ref & Tasks_template$ref
  |},
  +$fragmentRefs: EventInfoSection_org$ref,
  +$refType: Section_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Section_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EventInfoSection_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "briefTemplate",
      "storageKey": null,
      "args": null,
      "concreteType": "BriefTemplate",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoSection_template",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "EventStaff_template",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "Schedules_template",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "Tasks_template",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '5a4ee6467655e9a6d05856e1434f8850';
module.exports = node;
