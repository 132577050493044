/* @flow */
import React from 'react';

import type { AppRouterQueryResponse } from '../__generated__/AppRouterQuery.graphql';

export type FeatureAccessType = $PropertyType<
  $PropertyType<$PropertyType<AppRouterQueryResponse, 'org'>, 'subscription'>,
  'featureAccess',
>;

const FeatureAccessContext = React.createContext<FeatureAccessType>({ legacyFeatures: false });

export default FeatureAccessContext;
