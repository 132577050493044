/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';
import styled from 'styled-components';

import personalizationDefaults from 'config/personalizationDefaults';

import SubmissionReviewFormHeader from './SubmissionReviewFormHeader';
import SubmissionReviewFormQuestions from './SubmissionReviewFormQuestions';
import SubmissionReviewFormRespondent from './SubmissionReviewFormRespondent';

import type { SubmissionReviewForm_org } from './__generated__/SubmissionReviewForm_org.graphql';
import type { SubmissionReviewForm_submission } from './__generated__/SubmissionReviewForm_submission.graphql';

const Container = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 25px 30px;
  word-break: break-word;
`;

class SubmissionReviewForm extends React.PureComponent<{
  submission: SubmissionReviewForm_submission,
  org: SubmissionReviewForm_org,
  tz: string,
  location: Location,
}> {
  render() {
    const {
      submission,
      submission: { form },
      org,
      org: { settings },
      location,
      tz,
    } = this.props;
    return (
      <Container>
        <SubmissionReviewFormHeader
          logo={form.logo || settings.logo || personalizationDefaults.logoExpanded}
          name={this.props.submission.form.name}
          description={this.props.submission.form.description}
        />
        <SubmissionReviewFormRespondent submission={submission} tz={tz} location={location} />
        <SubmissionReviewFormQuestions
          submission={submission}
          org={org}
          currency={settings.currency}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  SubmissionReviewForm,
  graphql`
    fragment SubmissionReviewForm_submission on EventRequestSubmission {
      ...SubmissionReviewFormRespondent_submission
      ...SubmissionReviewFormQuestions_submission
      form {
        name
        description
        logo
      }
    }

    fragment SubmissionReviewForm_org on Org {
      settings {
        logo
        currency
      }
      ...SubmissionReviewFormQuestions_org
    }
  `,
);
