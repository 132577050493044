/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RequestForm_ssoUser$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestSharedFormContainer_ssoUser$ref: FragmentReference;
export type EventRequestSharedFormContainer_ssoUser = {|
  +$fragmentRefs: RequestForm_ssoUser$ref,
  +$refType: EventRequestSharedFormContainer_ssoUser$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestSharedFormContainer_ssoUser",
  "type": "SSOUser",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "RequestForm_ssoUser",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1c57c0304a7bd146782c70e06d288e27';
module.exports = node;
