/* @flow */

const reservedPathVariables = [
  'account',
  'admin',
  'contacts',
  'vendors',
  'registration',
  'dashboard',
  'emails',
  'event_calendars',
  'event_lists',
  'events',
  'leads',
  'login',
  'logout',
  'meetings',
  'members',
  'not_found',
  'org',
  'reporting',
  'request',
  'scheduler',
  'settings',
  'signin',
  'tasks',
  'teams',
  'upgrade',
  'workspace',
  'map',
  'calendar',
];

export default reservedPathVariables;
