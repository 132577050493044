/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateOrg from 'graph/mutations/org/updateOrg';
import updateOrgSettings from 'graph/mutations/org/updateOrgSettings';
import showModernMutationError from 'graph/utils/showModernMutationError';

import StyleSettings, { type Styles } from 'components/StyleSettings';

import type { OrgStyle_org } from './__generated__/OrgStyle_org.graphql';

const Title = styled.div`
  margin: 25px 0 21px 0;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

class OrgStyle extends React.PureComponent<{ org: OrgStyle_org }> {
  handleChange = (data: $Shape<Styles>) => {
    updateOrgSettings(this.props.org.settings.id, { ...data }).catch(showModernMutationError);
  };

  handleOrgNameChange = (name: string) => {
    updateOrg({ name }).catch(showModernMutationError);
  };

  render() {
    const { org } = this.props;

    return (
      <>
        <Title>General Settings</Title>
        <StyleSettings
          styles={{
            logo: org.settings.logo,
            primaryColor: org.settings.primaryColor,
            secondaryColor: org.settings.secondaryColor,
            font: org.settings.font,
            scheduleName: org.settings.scheduleName,
            tz: org.settings.tz,
          }}
          orgName={org.name}
          onChangeOrgName={this.handleOrgNameChange}
          onChange={this.handleChange}
        />
      </>
    );
  }
}

export default createFragmentContainer(
  OrgStyle,
  graphql`
    fragment OrgStyle_org on Org {
      name
      settings {
        id
        logo
        primaryColor
        secondaryColor
        font
        scheduleName
        tz
      }
    }
  `,
);
