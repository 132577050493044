/* @flow */
import moment from 'moment-timezone';

import formatDate from './formatDate';
import formatDateTime from './formatDateTime';
import formatDateRange from './formatDateRange';
import formatTimeRange from './formatTimeRange';

export default function formatDateTimeRange({
  startDate,
  endDate,
  startDateAllDay,
  endDateAllDay,
}: {
  startDate: moment,
  endDate: ?moment,
  startDateAllDay?: ?boolean,
  endDateAllDay?: ?boolean,
}): string {
  if (startDate && endDate) {
    const noTimes = startDateAllDay && endDateAllDay;

    // start date and end date same day
    if (startDate.isSame(endDate, 'day')) {
      const time = noTimes
        ? ''
        : formatTimeRange(startDateAllDay ? null : startDate, endDateAllDay ? null : endDate, {
            showPrefix: true,
          });
      return `${formatDate(startDate)} ${time}`.trim();
    }

    if (startDate.isSame(endDate, 'month') && noTimes) {
      return formatDateRange(startDate, endDate);
    }

    return `${formatDateTime(startDate, !!startDateAllDay)} - ${formatDateTime(
      endDate,
      !!endDateAllDay,
    )}`;
  }
  return formatDateTime(startDate, !!startDateAllDay);
}
