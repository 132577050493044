/* @flow */
import moment from 'moment-timezone';
import {
  type FiscalDateRange,
  getQuarterNumber,
  getQuarterStart,
  getQuarterEnd,
  getYearEnd,
} from '.';

export default function getQuarter(
  quarterIndex: number,
  startMonth: number,
  date: moment = moment(),
): FiscalDateRange {
  const quarterStart = getQuarterStart(startMonth, date).add(quarterIndex * 3, 'months');
  const quarterEnd = getQuarterEnd(startMonth, date).add(quarterIndex * 3, 'months');
  const yearEnd = getYearEnd(startMonth, quarterStart);
  const quarterNumber = getQuarterNumber(startMonth, quarterStart);
  return {
    label: `Q${quarterNumber} ${yearEnd.year()}`,
    key: `FQ${quarterIndex}`,
    start: quarterStart,
    end: quarterEnd,
  };
}
