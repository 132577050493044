/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import intersection from 'lodash/intersection';
import moment from 'moment';

import downloadedContacts from 'utils/analytics/downloadedContacts';
import type { FieldType } from 'utils/customization/types';

import addContactAssignments from 'graph/mutations/event/addContactAssignments';
import removeContactAssignment from 'graph/mutations/event/removeContactAssignment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/budget/Button';
import ContactsFilters from 'components/Contacts/ContactsFilters';
import ContactsImportWindow from 'components/Contacts/ContactsImportWindow';
import ContactsMassUpdatePanel, {
  type AvailableContactsType,
} from 'components/Contacts/ContactsMassUpdatePanel';
import {
  type InputVariableFilters,
  getColumnsShowConfig,
} from 'components/Contacts/contactsTableColumnSettings';
import { type EventPersonDataType } from 'components/Contacts/ContactWindow/ContactForm';
import { type ParsedContactsFilters } from 'components/Contacts/lib/parseContactsFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ColumnFilter from 'components/material/ColumnFilters';
import Search from 'components/Search';
import ShareDropdown from 'components/ShareDropdown';

import AddContactWindow from './AddContactWindow';
import SearchSuggestionBar from './SearchSuggestionBar';

import type { ContactsHeaderBar_event } from './__generated__/ContactsHeaderBar_event.graphql';
import type { ContactsHeaderBar_org } from './__generated__/ContactsHeaderBar_org.graphql';
import type { ContactsHeaderBarQueryResponse } from './__generated__/ContactsHeaderBarQuery.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px 15px;
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 30px;
  padding-top: 9px;
`;

const ActionsDropdown = styled(ShareDropdown)`
  float: right;
  padding-left: 0;
  border-left: none;
  > i {
    font-size: 20px;
  }
`;

const query = graphql`
  query ContactsHeaderBarQuery(
    $eventSlug: String!
    $filters: ContactFilters!
    $columns: [String!]
  ) {
    event(slug: $eventSlug) {
      contactReportCSVProcessId(filters: $filters, columns: $columns)
    }
  }
`;

class ContactsHeaderBar extends React.PureComponent<
  {
    event: ContactsHeaderBar_event,
    org: ContactsHeaderBar_org,
    history: RouterHistory,
    filters: ParsedContactsFilters,
    contactFields: $ReadOnlyArray<FieldType>,
    onUpdateTableColumnWidths: () => void,
    shownColumns: $ReadOnlyArray<string>,
    search: ?string,
    // eslint-disable-next-line react/no-unused-prop-types
    contactsCount: number,
    onSearch: (query: string) => void,
    inputFilters: InputVariableFilters,
    userEmail: string,
    onColumnsChange: (shownColumns: $ReadOnlyArray<string>) => void,
    currentSelectedContacts: AvailableContactsType,
    rawContactFields: $ReadOnlyArray<FieldType>,
    allContactsSelected: boolean,
  },
  {
    adding: boolean,
    importing: boolean,
    showCsvDownloadOverlay: boolean,
    showSearchSuggestionBar: boolean,
  },
> {
  state = {
    adding: false,
    importing: false,
    showCsvDownloadOverlay: false,
    showSearchSuggestionBar: false,
  };

  handleContactAddStart = () => {
    this.setState({ adding: true });
  };

  handleContactAddEnd = () => {
    this.setState({ adding: false });
  };

  handleContactAdd = (
    eventContacts: $ReadOnlyArray<{ contactId: string, ...EventPersonDataType }>,
  ) => {
    addContactAssignments(
      [this.props.event.id],
      eventContacts,
      'event contacts',
      this.props.inputFilters,
    ).catch(showModernMutationError);
  };

  handleRemoveContactAssignment = () => {
    removeContactAssignment({
      eventId: this.props.event.id,
      contactIds: this.props.currentSelectedContacts.map(contact => contact.id),
      fromWindow: 'event contacts',
      filters: this.props.inputFilters,
    }).catch(showModernMutationError);
  };

  handleCsvExport = () => {
    this.setState({ showCsvDownloadOverlay: true });
  };

  handleDownloadCompleted = () => {
    downloadedContacts({
      eventId: this.props.event.dbId,
      eventName: this.props.event.name,
      teamId: this.props.event.team.dbId,
      teamName: this.props.event.team.name,
      fromWindow: 'event',
    });
  };

  handleHideDownloadOverlay = () => {
    this.setState({ showCsvDownloadOverlay: false });
  };

  handleImport = () => {
    this.setState({ importing: true });
  };

  handleImportEnd = () => {
    this.setState({ importing: false });
  };

  handleSearch = (queryString: string) => {
    this.setState({ showSearchSuggestionBar: true });
    this.props.onSearch(queryString);
  };

  handleSuggestionBarHide = () => {
    this.setState({ showSearchSuggestionBar: false });
  };

  handleSuggestionBarShow = () => {
    this.setState({ showSearchSuggestionBar: true });
  };

  renderDownloadOverlay = () => {
    const columns = intersection(
      this.props.contactFields.map(field => field.fieldName || field.id),
      this.props.shownColumns,
    );
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          filters: this.props.inputFilters,
          columns,
          eventSlug: this.props.event.slug,
        }}
        renderSuccess={(response: ContactsHeaderBarQueryResponse) => {
          return response.event ? (
            <DownloadOverlayWithEmail
              processUUID={response.event.contactReportCSVProcessId}
              fileName={`Event Contacts Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleHideDownloadOverlay}
              email={this.props.userEmail}
              exportable="contacts_csv"
              onDownload={this.handleDownloadCompleted}
            />
          ) : null;
        }}
        renderLoading={() => null}
      />
    );
  };

  render() {
    const {
      org,
      event,
      history,
      shownColumns,
      onColumnsChange,
      filters,
      inputFilters,
      contactFields,
      currentSelectedContacts,
      onUpdateTableColumnWidths,
      rawContactFields,
      contactsCount,
      allContactsSelected,
    } = this.props;
    const searchObject = filters.search;
    const canCreate = event.viewerCanCreateContacts;

    const contactColumns = getColumnsShowConfig(contactFields);

    const leadStatuses = org.salesforceAccount
      ? org.salesforceAccount.leadStatuses.edges.map(({ node }) => ({
          value: node.id,
          label: node.name,
        }))
      : [];
    return (
      <Container>
        {currentSelectedContacts.length === 0 ? (
          <React.Fragment>
            <StyledButton onClick={this.handleContactAddStart} primary disabled={!canCreate}>
              Add Contact
            </StyledButton>
            <Search
              onSearch={this.handleSearch}
              search={searchObject && searchObject.search}
              exactSearch={searchObject && searchObject.exactMatch}
              placeholder="Search Contacts"
              onBlur={this.handleSuggestionBarHide}
              onFocus={this.handleSuggestionBarShow}
              suggestionComponent={props => {
                return contactsCount === 0 ? (
                  <SearchSuggestionBar
                    {...props}
                    showSearchSuggestionBar={this.state.showSearchSuggestionBar}
                    type="contacts"
                    eventId={event.id}
                  />
                ) : null;
              }}
            />
            {this.state.adding && (
              <AddContactWindow
                eventName={event.name}
                onSave={this.handleContactAdd}
                onCancel={this.handleContactAddEnd}
                fromWindow="event contacts"
              />
            )}
          </React.Fragment>
        ) : (
          <ContactsMassUpdatePanel
            selectedContacts={currentSelectedContacts}
            orgId={org.id}
            eventId={event.id}
            contactFields={contactFields}
            filters={inputFilters}
            viewerCanRemoveContacts={org.viewerCanRemoveContacts}
            onRemoveFromEvent={this.handleRemoveContactAssignment}
            onUpdateTableColumnWidths={onUpdateTableColumnWidths}
            allContactsSelected={allContactsSelected}
            fromWindow="event contacts"
          />
        )}
        <ContactsFilters
          history={history}
          filters={filters}
          filterOptions={contactFields}
          leadStatuses={leadStatuses}
        />
        <ColumnFilter
          filters={contactColumns}
          shownFilters={shownColumns}
          onFilterChange={onColumnsChange}
        />
        <ActionsDropdown
          options={[
            {
              label: 'Download current view (CSV)',
              icon: 'file-excel-o',
              onClick: this.handleCsvExport,
            },
            {
              label: 'Import Contacts (CSV)',
              icon: 'cloud-upload',
              onClick: this.handleImport,
            },
          ]}
        />
        {this.state.showCsvDownloadOverlay && this.renderDownloadOverlay()}
        {this.state.importing && (
          <ContactsImportWindow
            onClose={this.handleImportEnd}
            eventId={this.props.event.id}
            eventDbId={this.props.event.dbId}
            eventName={this.props.event.name}
            fromWindow="event contacts"
            teamId={this.props.event.team.dbId}
            teamName={this.props.event.team.name}
            rawContactFields={rawContactFields}
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(ContactsHeaderBar, {
  event: graphql`
    fragment ContactsHeaderBar_event on Event {
      id
      dbId
      name
      slug
      viewerCanCreateContacts
      team {
        dbId
        name
      }
    }
  `,
  org: graphql`
    fragment ContactsHeaderBar_org on Org {
      id
      viewerCanRemoveContacts
      salesforceAccount {
        leadStatuses {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  `,
});
