/* @flow */

import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import { type ExpenseFooterCellPropsType } from './index';

import { type ExpenseActualAmountFooterCell_event } from './__generated__/ExpenseActualAmountFooterCell_event.graphql';
import { type ExpenseActualAmountFooterCell_org } from './__generated__/ExpenseActualAmountFooterCell_org.graphql';

const ExpenseActualAmountFooterCell = ({
  org,
  event,
}: ExpenseFooterCellPropsType<
  ExpenseActualAmountFooterCell_event,
  ExpenseActualAmountFooterCell_org,
>) => (event.totalActualAmount ? formatCost(event.totalActualAmount, org.settings.currency) : '-');

export default createFragmentContainer(
  ExpenseActualAmountFooterCell,
  graphql`
    fragment ExpenseActualAmountFooterCell_event on Event {
      totalActualAmount
    }

    fragment ExpenseActualAmountFooterCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
