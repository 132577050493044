/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Search from 'components/Search';

import AttachmentContainer from './AttachmentsContainer';

import { type BriefAttachments_event } from './__generated__/BriefAttachments_event.graphql';
import { type BriefAttachmentsQueryResponse } from './__generated__/BriefAttachmentsQuery.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const StyledSearch = styled(Search)`
  max-width: 100%;
  margin-bottom: 20px;
`;

const query = graphql`
  query BriefAttachmentsQuery($eventSlug: String!, $query: String) {
    event(slug: $eventSlug) {
      ...AttachmentsContainer_event
    }
  }
`;

class BriefAttachments extends React.PureComponent<
  {
    event: BriefAttachments_event,
    selectedAttachments: $ReadOnlyArray<string>,
    containerRef: (el: AttachmentContainer) => void,
    onAddAttachment: (attachmentId: string) => void,
    onRemoveAttachment: (attachmentId: string) => void,
    onAllAttachmentCheckToggle: (
      isPrevAllCheck: boolean,
      searchedAttachmentIds: $ReadOnlyArray<string>,
    ) => void,
  },
  {
    searchQuery: string,
  },
> {
  state = { searchQuery: '' };

  handleAttachmentSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  handleAttachmentToggle = (attachmentId: string, isPrevChecked: boolean) => {
    if (isPrevChecked) {
      this.props.onRemoveAttachment(attachmentId);
    } else {
      this.props.onAddAttachment(attachmentId);
    }
  };

  render() {
    const {
      event: { slug },
      selectedAttachments,
      onAllAttachmentCheckToggle,
      containerRef,
    } = this.props;
    return (
      <Container>
        <StyledSearch
          onSearch={this.handleAttachmentSearch}
          search={this.state.searchQuery}
          placeholder="Search Attachments"
        />
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug: slug, query: this.state.searchQuery }}
          renderSuccess={(props: BriefAttachmentsQueryResponse) => {
            if (props.event) {
              return (
                <AttachmentContainer
                  componentRef={containerRef}
                  event={props.event}
                  selectedAttachments={selectedAttachments}
                  searchQuery={this.state.searchQuery}
                  onAllAttachmentCheckToggle={onAllAttachmentCheckToggle}
                  handleAttachmentToggle={this.handleAttachmentToggle}
                />
              );
            }
            return null;
          }}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  BriefAttachments,
  graphql`
    fragment BriefAttachments_event on Event {
      id
      slug
    }
  `,
);
