/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import type { UpdateUserFromWindow } from 'graph/mutations/user/updateUser';

const mutation = graphql`
  mutation changeAdminMutation($input: ChangeAdminInput!) {
    changeAdmin(input: $input) {
      user {
        id
        admin
        hasTeamAccess
        viewerCanRemove
      }
      org {
        subscription {
          fatmLimit
          fatmCount
        }
      }
    }
  }
`;

export default function changeAdmin(
  userId: string,
  admin: boolean,
  fromWindow: UpdateUserFromWindow,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        userId,
        admin,
        fromWindow,
      },
    },
    optimisticResponse: {
      changeAdmin: {
        eventRole: { id: userId, admin },
      },
    },
  });
}
