/* @flow */
import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from 'config/theme';

import ErrorBoundary from 'components/ErrorBoundary';
import GeneralError from 'components/GeneralError';

export default class Container extends React.Component<{
  children: React.Node,
}> {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <div>
            {this.props.children}
            <GeneralError />
          </div>
        </ErrorBoundary>
      </ThemeProvider>
    );
  }
}
