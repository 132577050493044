/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/material/Button';
import SelectField from 'components/material/SelectField';

import { type FieldsType, type MappingsType } from './index';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuccessBox = styled.div`
  min-width: 400px;
  margin: 30px 0;
  padding: 8px;
  border-radius: 5px;
  background-color: #29cc71;
  line-height: 21px;
  font-size: 16px;
  color: white;
  text-align: center;
  strong {
    font-weight: 500;
  }
`;

const InfoMessage = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #3e4859;
`;

const RequiredReminder = styled.span`
  margin-top: 8px;
  font-size: 13px;
  color: #f48183;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

const HeaderRow = styled(Row)`
  position: relative;
  margin-top: 30px;
  > span {
    flex: 1 1 auto;
    font-weight: 500;
    text-align: center;
    color: #616c7a;
    &:first-child {
      margin-right: 40px;
    }
  }
`;

const Separator = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50% - 35px);
  width: 70px;
  height: 1px;
  background-color: #cdd1d4;
`;

const Mappings = styled.div`
  margin: 25px 0;
`;

const HeaderField = styled.div`
  flex: 1 1 0%;
  min-width: 0;
  height: 40px;
  margin-bottom: 20px;
  margin-right: 40px;
  background-color: #f8f9f9;
  border: solid 1px #f8f9f9;
  color: #878e98;
  > div {
    padding: 0 20px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledSelectField = styled(SelectField)`
  flex: 1 1 0%;
  height: 40px;
  margin-bottom: 20px;
  border: solid 1px #e7e7e7;
  &:hover {
    background-color: #f4fbff;
    border-color: #f4fbff;
  }
  > div:first-child {
    padding: 9px 15px;
  }
`;

const StyledLabel = styled.div`
  flex: 1 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #878e98;
  ${props =>
    !props.showPlaceholder &&
    css`
      color: #3e4859;
    `};
`;

export default class Mapping<F: string> extends React.Component<
  {
    fields: FieldsType<F>,
    importColumns: $ReadOnlyArray<string>,
    fileName: string,
    importResourceName: string,
    onNext: (mappings: MappingsType<F>) => void,
    onCancel: () => void,
    onValidateMappings: (mappings: MappingsType<F>) => ?string,
  },
  {
    mappings: MappingsType<F>,
  },
> {
  constructor(props: $PropertyType<Mapping<F>, 'props'>) {
    super(props);

    let fieldKeys = Object.keys(this.props.fields);

    this.state = {
      mappings: this.props.importColumns.reduce((obj, column) => {
        const columnQuery = column.toLowerCase().replace(/[^\w+]/g, '');
        const field = fieldKeys.find(fieldKey =>
          this.props.fields[fieldKey].guess.test(columnQuery),
        );

        if (!field) return obj;

        if (!this.props.fields[field].multiple) {
          fieldKeys = fieldKeys.filter(key => key !== field);
        }

        return { ...obj, [column]: field };
      }, {}),
    };
  }

  handleContinue = () => {
    this.props.onNext(this.state.mappings);
  };

  handleFieldSelect = (field: string, value: ?string) => {
    this.setState(state => {
      const mappings = { ...state.mappings, [field]: value };
      if (value == null) {
        delete mappings[field];
      }

      return { mappings };
    });
  };

  render() {
    const {
      fileName,
      fields,
      importColumns,
      importResourceName,
      onCancel,
      onValidateMappings,
    } = this.props;
    const { mappings } = this.state;

    const mappedFields = Object.values(mappings);
    const availableFields = Object.keys(fields).reduce((obj, key) => {
      return !fields[key].multiple && mappedFields.includes(key)
        ? obj
        : { ...obj, [key]: fields[key] };
    }, {});
    const error = onValidateMappings(mappings);

    return (
      <React.Fragment>
        <Row>
          <SuccessBox>
            <strong>{fileName}</strong> was uploaded!
          </SuccessBox>
        </Row>
        <Row>
          <InfoMessage>Map the file column headers with {importResourceName} fields</InfoMessage>
        </Row>
        <Row>
          <RequiredReminder>
            {error}
            &nbsp;
          </RequiredReminder>
        </Row>
        <HeaderRow>
          <span>Column Headers</span>
          <Separator />
          <span>{importResourceName} Fields</span>
        </HeaderRow>
        <Mappings>
          {importColumns.map(fieldName => (
            <Row key={fieldName}>
              <HeaderField>
                <div>{fieldName}</div>
              </HeaderField>
              <StyledSelectField
                noBoxShadow
                placeholder="Select Field"
                value={mappings[fieldName]}
                onChange={value => this.handleFieldSelect(fieldName, value)}
                labelComponent={StyledLabel}
                options={[
                  mappings[fieldName] == null
                    ? null
                    : {
                        value: mappings[fieldName],
                        label: fields[mappings[fieldName]].label,
                      },
                  ...Object.keys(availableFields).map(key => ({
                    label: availableFields[key].label,
                    value: key,
                  })),
                ].filter(Boolean)}
                searchable
                clearable
              />
            </Row>
          ))}
        </Mappings>
        <Buttons>
          <Button minimal label="Cancel" onClick={onCancel} />
          <SubmitButton
            primary
            label="Next"
            disabled={error != null}
            onClick={this.handleContinue}
          />
        </Buttons>
      </React.Fragment>
    );
  }
}
