/* @flow */
import styled, { css } from 'styled-components';

import TableCell from './TableCell';

const TableHeader = styled.header`
  display: flex;

  ${TableCell} {
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fff;
    color: #3e4859;

    &:first-child {
      z-index: 7;
    }
  }

  ${props =>
    props.colWidths.map(
      (width, index) => css`
        ${TableCell}:nth-child(${index + 1}) {
          min-width: ${width}px;
        }
      `,
    )};
`;

export default TableHeader;
