/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type { QuestionType } from '../../lib/types';

import EditableQuestionUserSelectOptions from './EditableQuestionUserSelectOptions';

import type { EditableQuestionUserSelectOptionsQueryResponse } from './__generated__/EditableQuestionUserSelectOptionsQuery.graphql';

const query = graphql`
  query EditableQuestionUserSelectOptionsQuery {
    org {
      users {
        edges {
          node {
            ...EditableQuestionUserSelectOptions_users
          }
        }
      }
    }
  }
`;

export default class EditableQuestionUserSelectOptionsRoot extends React.PureComponent<{
  question: QuestionType,
  onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
}> {
  getOrgUsers = (response: EditableQuestionUserSelectOptionsQueryResponse) =>
    response.org.users.edges.map(edge => edge.node);

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(response: EditableQuestionUserSelectOptionsQueryResponse) => (
          <EditableQuestionUserSelectOptions
            question={this.props.question}
            onChangeQuestion={this.props.onChangeQuestion}
            users={this.getOrgUsers(response)}
          />
        )}
      />
    );
  }
}
