/* @flow */
import moment from 'moment-timezone';

import {
  type EventRequestFormStatusValue,
  eventRequestFormStatuses,
} from 'config/eventRequestFormStatuses';
import eventStates, { type EventStateGraphValue } from 'config/eventStates';
import registrationFormStatuses, {
  type RegistrationFormStatusType,
} from 'config/registrationFormStatuses';
import syncStatuses from 'config/syncStatuses.json';

import type { FieldType } from 'utils/customization/types';
import getCustomFieldQueryParamsByKind from 'utils/filters/getCustomFieldQueryParamsByKind';
import parseTypedQueryString, {
  type DateRangeParam,
  type NumberRangeParam,
  type SearchParam,
  type SortParam,
  stringParamToDate,
  stringParamToDateRange,
  stringParamToEnum,
  stringParamToEnumArray,
  stringParamToNumberRange,
  stringParamToSearch,
  stringParamToSort,
  stringParamToStringArray,
} from 'utils/routing/parseTypedQueryString';

import { type EventFormat } from 'views/Main/Dashboard/AllEvents/AllEventsListPage/__generated__/AllEventsTablePaginationQuery.graphql';

import stringParamToFiscalDateRange from './stringParamToFiscalDateRange';

export type ParsedAllEventsFilters = {|
  search: ?SearchParam,
  teamIds: ?$ReadOnlyArray<string>,
  queries: ?$ReadOnlyArray<string>,
  date: ?DateRangeParam,
  listType?: ?('UPCOMING' | 'RECENT' | 'PAST' | 'NEXT30DAYS' | 'NEXT60DAYS' | 'NEXT90DAYS'),
  statuses: ?$ReadOnlyArray<EventStateGraphValue>,
  leadIds: ?$ReadOnlyArray<string>,
  staffIds: ?$ReadOnlyArray<string>,
  month: ?moment,
  week: ?moment,
  venueNames: ?$ReadOnlyArray<string>,
  cities: ?$ReadOnlyArray<string>,
  states: ?$ReadOnlyArray<string>,
  countries: ?$ReadOnlyArray<string>,
  eventFormats: ?$ReadOnlyArray<EventFormat>,
  sort: ?SortParam,
  syncStatuses: ?$ReadOnlyArray<$Keys<typeof syncStatuses>>,
  opportunitiesNumber: ?NumberRangeParam,
  opportunitiesAmount: ?NumberRangeParam,
  eventIds: ?$ReadOnlyArray<string>,
  updatedAt: ?DateRangeParam,
  updaterIds: ?$ReadOnlyArray<string>,
  createdAt: ?DateRangeParam,
  creatorIds: ?$ReadOnlyArray<string>,
  requesterUserIds: ?$ReadOnlyArray<string>,
  requesterContactIds: ?$ReadOnlyArray<string>,
  requestedDate: ?DateRangeParam,
  requestStatuses: ?$ReadOnlyArray<$Subtype<EventRequestFormStatusValue>>,
  requestFormIds: ?$ReadOnlyArray<string>,
  requestReviewers: ?$ReadOnlyArray<string>,
  registeredContactsTotal: ?NumberRangeParam,
  attendedContactsTotal: ?NumberRangeParam,
  registrationFormStatuses: ?$ReadOnlyArray<RegistrationFormStatusType>,
  contactsCount: ?NumberRangeParam,
|};

export type filtersForExportType = $Shape<{
  ...$Diff<
    ParsedAllEventsFilters,
    {
      month: ?moment,
      date: ?DateRangeParam,
      createdAt: ?DateRangeParam,
      updatedAt: ?DateRangeParam,
      requestedDate: ?DateRangeParam,
      sort: ?SortParam,
    },
  >,
  afterDate: string,
  beforeDate: string,
  includeUndated: ?boolean,
  requestedDate: ?$ReadOnlyArray<string>,
  updatedAt: ?$ReadOnlyArray<string>,
  createdAt: ?$ReadOnlyArray<string>,
  token: ?string,
  sort: string,
  direction: 'ASC' | 'DESC',
}>;

export default function parseAllEventsFilters(
  queryString: string,
  fiscalYearStart: number,
  filterOptions?: $ReadOnlyArray<FieldType>,
): ParsedAllEventsFilters {
  const stringParamToFiscalDateRangeInner = (stringValue: string) => {
    return stringParamToFiscalDateRange(stringValue, fiscalYearStart);
  };

  return parseTypedQueryString(queryString, {
    ...(filterOptions || []).reduce(
      (customFilters, filter) =>
        filter.kind !== 'DEFAULT'
          ? { ...customFilters, ...getCustomFieldQueryParamsByKind(filter) }
          : customFilters,
      {},
    ),
    search: stringParamToSearch,
    teamIds: stringParamToStringArray,
    queries: stringParamToStringArray,
    date: stringParamToFiscalDateRangeInner, // only list
    listType: stringParamToEnum([
      'UPCOMING',
      'RECENT',
      'PAST',
      'NEXT30DAYS',
      'NEXT60DAYS',
      'NEXT90DAYS',
    ]),
    statuses: stringParamToEnumArray(eventStates.map(s => s.graphValue)),
    leadIds: stringParamToStringArray,
    staffIds: stringParamToStringArray,
    month: stringParamToDate, // only calendar
    week: stringParamToDate, // only calendar
    venueNames: stringParamToStringArray,
    cities: stringParamToStringArray,
    states: stringParamToStringArray,
    countries: stringParamToStringArray,
    eventFormats: stringParamToEnumArray(['VIRTUAL', 'PHYSICAL', 'HYBRID']),
    sort: stringParamToSort,
    syncStatuses: stringParamToEnumArray(Object.keys(syncStatuses)),
    opportunitiesNumber: stringParamToNumberRange,
    opportunitiesAmount: stringParamToNumberRange,
    eventIds: stringParamToStringArray,
    updatedAt: stringParamToDateRange,
    updaterIds: stringParamToStringArray,
    createdAt: stringParamToDateRange,
    creatorIds: stringParamToStringArray,
    requesterUserIds: stringParamToStringArray,
    requesterContactIds: stringParamToStringArray,
    requestedDate: stringParamToDateRange,
    requestStatuses: stringParamToEnumArray(eventRequestFormStatuses.map(s => s.value)),
    requestFormIds: stringParamToStringArray,
    requestReviewers: stringParamToStringArray,
    registeredContactsTotal: stringParamToNumberRange,
    attendedContactsTotal: stringParamToNumberRange,
    registrationFormStatuses: stringParamToEnumArray(Object.keys(registrationFormStatuses)),
    contactsCount: stringParamToNumberRange,
  });
}
