/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import createTeam from 'graph/mutations/team/createTeam';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button, { MinimalButton } from 'components/budget/Button';
import InviteWindow from 'components/InviteWindow';
import TextField from 'components/material/TextField';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';

const Footer = styled.div`
  margin-top: 20px;
  text-align: right;
  button {
    margin-left: 15px;
  }
`;

const InviteWindowWrapper = styled.div`
  visibility: ${props => (props.shown ? 'visible' : 'hidden')};
`;

export default class TeamWindow extends React.PureComponent<
  {
    onHide: () => void,
    userId: string,
  },
  {
    name: string,
    nameError: boolean,
    createdTeamId: ?string,
    loading: boolean,
  },
> {
  state = {
    name: '',
    nameError: false,
    createdTeamId: null,
    loading: false,
  };

  handleChangeName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      name: e.currentTarget.value,
      nameError: e.currentTarget.value.trim().length === 0,
    });
  };

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (this.state.name.trim().length === 0) {
      this.setState({ nameError: true });

      return;
    }

    this.setState({ loading: true });

    createTeam(this.state.name, 'easy_button', this.props.userId)
      .then(team => {
        this.setState({ createdTeamId: team.id });
      })
      .catch(showModernMutationError)
      .then(() => {
        this.setState({ loading: false });
      });
  };

  handleOverlayHide = () => {
    if (!this.state.name && !this.state.nameError) {
      this.props.onHide();
    }
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.createdTeamId && (
          <Window onHide={this.handleOverlayHide} size="small">
            <WindowHeader>
              <WindowTitle>Create new team</WindowTitle>
              <WindowClose onClick={this.props.onHide} />
            </WindowHeader>
            <WindowContent>
              <form onSubmit={this.handleSubmit}>
                <TextField
                  label="Team name"
                  autoFocus
                  value={this.state.name}
                  onChange={this.handleChangeName}
                  error={this.state.nameError ? 'Team name is required' : ''}
                />

                <Footer>
                  <MinimalButton label="Cancel" onClick={this.props.onHide} />
                  <Button type="submit" loading={this.state.loading}>
                    Next
                  </Button>
                </Footer>
              </form>
            </WindowContent>
          </Window>
        )}

        <InviteWindowWrapper shown={!!this.state.createdTeamId}>
          {/* Rendering the window here so that there's no loading when it's shown */}
          <InviteWindow
            onHide={this.props.onHide}
            teamId={this.state.createdTeamId || undefined}
            teamName={this.state.name}
            size={this.state.createdTeamId ? undefined : 'small'}
            fromWindow="team"
          />
        </InviteWindowWrapper>
      </React.Fragment>
    );
  }
}
