/**
 * @flow
 * @relayHash 0067833cac5dcf6289a8bf0733651188
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CopyScheduleInEventInput = {
  clientMutationId?: ?string,
  eventId: string,
  scheduleId: string,
  order: number,
};
export type duplicateScheduleMutationVariables = {|
  input: CopyScheduleInEventInput
|};
export type duplicateScheduleMutationResponse = {|
  +copyScheduleInEvent: ?{|
    +scheduleEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +name: string,
        +order: number,
        +slug: string,
        +dbId: number,
        +token: string,
        +slugs: $ReadOnlyArray<{|
          +slug: string
        |}>,
        +viewerCanUpdate: boolean,
        +viewerCanRemove: boolean,
      |},
    |}
  |}
|};
export type duplicateScheduleMutation = {|
  variables: duplicateScheduleMutationVariables,
  response: duplicateScheduleMutationResponse,
|};
*/


/*
mutation duplicateScheduleMutation(
  $input: CopyScheduleInEventInput!
) {
  copyScheduleInEvent(input: $input) {
    scheduleEdge {
      __typename
      node {
        id
        name
        order
        slug
        dbId
        token
        slugs {
          slug
          id
        }
        viewerCanUpdate
        viewerCanRemove
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CopyScheduleInEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CopyScheduleInEventInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "token",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanRemove",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "duplicateScheduleMutation",
  "id": null,
  "text": "mutation duplicateScheduleMutation(\n  $input: CopyScheduleInEventInput!\n) {\n  copyScheduleInEvent(input: $input) {\n    scheduleEdge {\n      __typename\n      node {\n        id\n        name\n        order\n        slug\n        dbId\n        token\n        slugs {\n          slug\n          id\n        }\n        viewerCanUpdate\n        viewerCanRemove\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "duplicateScheduleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copyScheduleInEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopyScheduleInEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scheduleEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ScheduleRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Schedule",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "slugs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Slug",
                    "plural": true,
                    "selections": [
                      v6
                    ]
                  },
                  v9,
                  v10
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "duplicateScheduleMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copyScheduleInEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopyScheduleInEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scheduleEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ScheduleRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Schedule",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "slugs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Slug",
                    "plural": true,
                    "selections": [
                      v6,
                      v3
                    ]
                  },
                  v9,
                  v10
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3f8d3a5c242f574d7e4dc47031c46c69';
module.exports = node;
