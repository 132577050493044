/* @flow */
import React from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components';

const StyledLinkify = styled(Linkify)`
  color: rgba(74, 86, 101, 0.54);
  font-size: 14px;
  word-break: break-word;
  white-space: pre-line;
  a {
    color: rgba(74, 86, 101, 0.54);
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default class HelperText extends React.Component<{
  children: string,
}> {
  render() {
    return (
      <div>
        <StyledLinkify properties={{ target: '_blank' }}>{this.props.children}</StyledLinkify>
      </div>
    );
  }
}
