/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import moment from 'moment-timezone';

import { FILTER_DATE_FORMAT } from 'utils/parseFilters';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { Content } from 'components/page/Content';

import { type ParsedTaskFilters } from '../components/parseTaskFilters';
import TasksCalendar from './TasksCalendar';
import TasksCalendarDay from './TasksCalendarDay';

import { type TasksCalendarViewQueryResponse } from './__generated__/TasksCalendarViewQuery.graphql';

const query = graphql`
  query TasksCalendarViewQuery($filters: DeliverableFilters, $eventSlug: String!) {
    event(slug: $eventSlug) {
      deliverables(first: 1000, filters: $filters, includeSubtasks: true)
        @connection(key: "TasksCalendarView_deliverables", filters: []) {
        edges {
          node {
            id
            dueDate
            name
            status
            slug
            ...TasksCalendarDay_tasks
          }
        }
      }
    }
  }
`;

export default class TasksCalendarView extends React.PureComponent<
  {
    eventSlug: string,
    filters: ParsedTaskFilters,
    tz: string,
    onGetTaskLink: (taskSlug: string) => string,
  },
  {
    month: moment,
  },
> {
  state = {
    month: moment().tz(this.props.tz),
  };

  handleChangeMonth = (month: moment) => {
    this.setState({ month });
  };

  handleRenderDay = (
    date: moment,
    tasks: $ReadOnlyArray<
      $PropertyType<
        $ElementType<
          $PropertyType<
            $PropertyType<
              $NonMaybeType<$PropertyType<TasksCalendarViewQueryResponse, 'event'>>,
              'deliverables',
            >,
            'edges',
          >,
          0,
        >,
        'node',
      >,
    >,
  ) => {
    const dayTasks = tasks.filter(task => task.dueDate && date.isSame(task.dueDate, 'date'));

    return (
      <TasksCalendarDay
        date={date}
        tz={this.props.tz}
        onGetTaskLink={this.props.onGetTaskLink}
        tasks={dayTasks}
      />
    );
  };

  renderTaskCalendar = (response?: TasksCalendarViewQueryResponse) => {
    const tasks =
      response && response.event ? response.event.deliverables.edges.map(edge => edge.node) : [];
    return (
      <TasksCalendar
        renderDay={date => this.handleRenderDay(date, tasks)}
        tz={this.props.tz}
        tasks={tasks}
        month={this.state.month}
        onChangeMonth={this.handleChangeMonth}
      />
    );
  };

  render() {
    const { sort, ...calendarFilters } = this.props.filters;

    const variables = {
      eventSlug: this.props.eventSlug,
      filters: {
        ...calendarFilters,
        dueDate: {
          key: this.state.month
            .clone()
            .startOf('month')
            .subtract(7, 'd')
            .format(FILTER_DATE_FORMAT),
          start: this.state.month
            .clone()
            .startOf('month')
            .subtract(7, 'd')
            .format(FILTER_DATE_FORMAT),
          end: this.state.month.clone().endOf('month').add(7, 'd').format(FILTER_DATE_FORMAT),
        },
      },
    };
    return (
      <Content>
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={this.renderTaskCalendar}
          renderLoading={this.renderTaskCalendar}
        />
      </Content>
    );
  }
}
