/* @flow */
import sortBy from 'lodash/sortBy';

import customFieldsOptions from './customFieldsOptions';
import type { GroupField } from './types';

import { type SalesforceMapping_org } from '../__generated__/SalesforceMapping_org.graphql';

const optionsForField = field => {
  if (field.fieldName == null) {
    return customFieldsOptions[field.kind];
  }

  return {
    registration_status_id: { types: ['string', 'picklist'] },
    attendance_status_id: { types: ['string', 'picklist'] },
  }[field.fieldName];
};

const eventContactFields = (org: SalesforceMapping_org): $ReadOnlyArray<GroupField> => {
  const eventContactCustomFields = sortBy(
    org.eventContactCustomFields ? org.eventContactCustomFields.edges.map(edge => edge.node) : [],
    field => field.order,
  );

  return [
    {
      title: 'Event Attendance Fields',
      fields: [
        ...eventContactCustomFields.map(field => ({
          label: field.label,
          name: field.fieldName || 'custom',
          toSalesforce: true,
          fromSalesforce: false,
          nillable: !field.required,
          customFieldId: field.id,
          ...optionsForField(field),
        })),
        {
          label: 'Join Time',
          name: 'join_time',
          types: ['datetime'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'Leave Time',
          name: 'leave_time',
          types: ['datetime'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'Time in Session',
          name: 'duration',
          types: ['int', 'double'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        org.syncedToIbmWm
          ? {
              label: 'Total Live View Time',
              name: 'live_duration',
              types: ['int', 'double'],
              toSalesforce: true,
              fromSalesforce: false,
              nillable: true,
            }
          : null,
        org.syncedToIbmWm
          ? {
              label: 'Total VOD View Time',
              name: 'recorded_duration',
              types: ['int', 'double'],
              toSalesforce: true,
              fromSalesforce: false,
              nillable: true,
            }
          : null,
      ].filter(Boolean),
    },
  ];
};

export default eventContactFields;
