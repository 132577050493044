/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import formatCost from 'utils/number/formatCost';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineCostField from 'components/budget/Table/InlineCostField';
import LockIcon from 'components/LockIcon';

import type { CustomizableCurrency_customizable } from './__generated__/CustomizableCurrency_customizable.graphql';

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
`;

class CustomizableCurrency extends React.Component<{
  customizable: CustomizableCurrency_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
  org: { settings: { currency: string } },
}> {
  handleSave = (value: ?number): void => {
    const { customizable, fieldSettings, onUpdateCustomField, updateColumnWidth } = this.props;
    if (!this.getError(value) && value !== this.getValue() && onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        currencyValue: value,
      });
      updateColumnWidth();
    }
  };

  getValue = (): ?number => {
    const customCurrencyField = this.props.customizable.customCurrencyFields.find(
      currencyField => currencyField.customField.id === this.props.fieldSettings.id,
    );
    return customCurrencyField ? customCurrencyField.value : null;
  };

  getError = (value: ?number): ?string => {
    if (this.props.fieldSettings.required && value == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { fieldSettings, org, readOnly, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    if (readOnly || fieldSettings.restrictChangingValue) {
      return (
        <>
          {formatCost(this.getValue(), org.settings.currency) || '-'}
          {!readOnly && fieldSettings.restrictChangingValue && (
            <StyledLockIcon label="Salesforce" />
          )}
        </>
      );
    }

    return (
      <InlineCostField
        currency={org.settings.currency}
        value={this.getValue()}
        onChange={this.handleSave}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
      />
    );
  }
}

export default createFragmentContainer(
  CustomizableCurrency,
  graphql`
    fragment CustomizableCurrency_org on Org {
      settings {
        currency
      }
    }
    fragment CustomizableCurrency_customizable on CustomizableInterface {
      id
      __typename
      customCurrencyFields {
        customField {
          id
        }
        value
      }
    }
  `,
);
