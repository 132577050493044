/* @flow */
import styled, { css } from 'styled-components';

import TableRowGroup from './TableRowGroup';
import TableCell from './TableCell';
import TableAction from './TableAction';

const TableRow = styled.div`
  position: relative;
  display: flex;
  border-top: 1px solid #dfe1e5;

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: hsl(218, 10%, 50%);
    }
  }

  &:hover {
    ${TableCell} {
      background: #f2fbff;
    }

    ${TableAction} {
      opacity: 1;
    }
  }

  &:focus-within {
    ${TableCell} {
      background: #f2fbff;
    }

    ${TableAction} {
      opacity: 1;
    }
  }

  ${props =>
    props.checked &&
    css`
      ${TableCell} {
        background: #f2fbff;
      }
    `};

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `};

  ${props =>
    !props.grouped &&
    css`
      &:last-child {
        border-bottom: 1px solid #dfe1e5;
      }
    `};

  ${props =>
    props.grouped &&
    props.parent &&
    css`
      border-top: 0;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 3px;
      }

      &.expanded {
        & + ${TableRowGroup} {
          display: block !important;
        }
      }
    `};

  ${props =>
    props.isLoading &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background: linear-gradient(to right, #fff, #f2fbff, #fff);
        box-shadow: inset 1px 0 #dfe1e5;
      }
    `};

  ${props =>
    props.colWidths.map(
      (width, index) => css`
        ${TableCell}:nth-child(${index + 1}) {
          min-width: ${width}px;
        }
      `,
    )};
`;

export default TableRow;
