/* @flow */
import React from 'react';
import styled from 'styled-components';

import formatNumber from 'utils/number/formatNumber';

const Container = styled.div`
  padding-right: 19px;
`;

export default class NumberField extends React.PureComponent<{
  value: string | number,
  formatted?: boolean,
  fractionDigits?: number,
}> {
  render() {
    // Do not apply formatting if the value is already formatted
    const formattedValue =
      this.props.formatted || typeof this.props.value !== 'number'
        ? this.props.value
        : formatNumber(this.props.value, { fractionDigits: this.props.fractionDigits || 0 });
    return <Container>{formattedValue}</Container>;
  }
}
