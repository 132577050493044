/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import isValidEmail from 'utils/validators/isValidEmail';

import TextField from 'components/material/TextField';
import Button from 'components/material/Button';

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  ${props =>
    props.centered &&
    css`
      justify-content: center;
    `};
  margin-top: 15px;
  > button:first-child {
    margin-right: 22px;
  }
`;

export default class SendViaEmailOption extends React.Component<
  {
    onCancel: () => void,
    onConfirm: (email: string) => void,
  },
  {
    email: string,
    error: ?string,
  },
> {
  state = {
    email: '',
    error: null,
  };

  handleEmailInputChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isValidEmail(value)) {
      this.setState({ email: value, error: null });
    } else {
      this.setState({
        email: value,
        error: value === '' ? 'Email is required' : 'Email is not valid',
      });
    }
  };

  handleBlur = () => {
    if (this.state.email === '') {
      this.setState({ error: 'Email is required' });
    }
  };

  handleConfirm = () => {
    if (this.state.email !== '') {
      this.props.onConfirm(this.state.email);
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.handleConfirm();
    }
  };

  render() {
    return (
      <React.Fragment>
        <TextField
          autoFocus
          label="Enter your email"
          onChange={this.handleEmailInputChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          value={this.state.email}
          error={this.state.error}
        />
        <Buttons>
          <Button minimal label="Cancel" onClick={this.props.onCancel} />
          <Button
            primary
            label="Send"
            disabled={this.state.error != null || this.state.email === ''}
            onClick={this.handleConfirm}
          />
        </Buttons>
      </React.Fragment>
    );
  }
}
