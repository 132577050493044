/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { CreateComponentPropertiesType } from './createEventDatesComponent';

import type {
  createEmbedComponentFieldsMutationResponse,
  createEmbedComponentFieldsMutationVariables,
} from './__generated__/createEmbedComponentFieldsMutation.graphql';

const mutation = graphql`
  mutation createEmbedComponentFieldsMutation($input: CreateEmbedComponentInput!) {
    createRegistrationFormEmbedComponent(input: $input) {
      pageComponentEdge {
        __typename
        node {
          id
          order
          kind
          parent {
            id
          }
          ...EmbedComponent_componentProps
        }
      }
      updatedPageComponents {
        id
        order
        parent {
          id
        }
        childPageComponents {
          edges {
            node {
              id
              kind
              order
              columnChild {
                id
              }
              ...ColumnComponent_componentProps
            }
          }
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function createEmbedComponent(
  properties: CreateComponentPropertiesType,
): Promise<string> {
  const { formId, newComponentOrder, pageComponentsOrder, parentProps } = properties;
  const variables: createEmbedComponentFieldsMutationVariables = {
    input: {
      formId,
      newComponentOrder,
      pageComponentsOrder,
      parentId: parentProps ? parentProps.parentId : undefined,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      createRegistrationFormEmbedComponent: {
        updatedPageComponents: pageComponentsOrder.map(pageComponent => ({
          id: pageComponent.id,
          order: pageComponent.order,
          parent: undefined,
          ...(parentProps && parentProps.parentRowId === pageComponent.id
            ? {
                childPageComponents: {
                  edges: parentProps.rowChildPageComponents.edges.map(edge => {
                    if (!parentProps) {
                      return edge;
                    }
                    return edge.node.id !== parentProps.parentId
                      ? edge
                      : {
                          node: {
                            id: parentProps.parentId,
                            kind: 'COLUMN',
                            columnChild: {
                              order: newComponentOrder,
                              kind: 'EMBED',
                              embedComponent: {
                                alignment: 'center',
                                padding: '20 0 20 0',
                              },
                            },
                          },
                        };
                  }),
                },
              }
            : {}),
        })),
        pageComponentEdge: {
          node: {
            id: 'new',
            order: newComponentOrder,
            kind: 'EMBED',
            parent: parentProps ? { id: parentProps.parentId } : undefined,
            embedComponent: {
              alignment: 'center',
              padding: '20 0 20 0',
            },
          },
        },
      },
    },
    updater: addRecordToConnection({
      parentId: formId,
      edgeName: 'pageComponentEdge',
      connections: [{ field: 'pageComponents' }],
    }),
  }).then((response: createEmbedComponentFieldsMutationResponse) => {
    if (
      response.createRegistrationFormEmbedComponent &&
      response.createRegistrationFormEmbedComponent.pageComponentEdge.node
    ) {
      return response.createRegistrationFormEmbedComponent.pageComponentEdge.node.id;
    }
    throw new Error('No item id');
  });
}
