/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type { reorderFolderMutationVariables } from './__generated__/removeFolderMutation.graphql';

const mutation = graphql`
  mutation reorderFolderMutation($input: ReorderFolderInput!) {
    reorderFolder(input: $input) {
      updatedFolders {
        id
        order
      }
    }
  }
`;

export default function reorderFolder(
  folderId: string,
  newPosition: number,
  sortedFolders: Array<{ id: string, order: number }>,
) {
  const target = sortedFolders.find(folder => folder.id === folderId);
  const other = sortedFolders.filter(folder => folder.id !== folderId);
  const pre = other.slice(0, newPosition);
  const post = other.slice(newPosition);

  const reordered = [...pre, target, ...post]
    .map(
      (folder, i) =>
        folder && {
          id: folder.id,
          order: i * 10000,
        },
    )
    .filter(x => x);

  const optimisticResponse = {
    reorderFolder: {
      updatedFolders: reordered,
    },
  };

  const variables: reorderFolderMutationVariables = {
    input: {
      folderId,
      newPosition,
    },
  };

  commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
