import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import flatten from 'lodash/flatten';

import { createMoment } from 'utils/Date';

import SchedulesTimelineDay from './SchedulesTimelineDay';
import SchedulesTimelineColumn from './SchedulesTimelineColumn';

class SchedulesTimeline extends React.Component {
  static propTypes = {
    schedules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        scheduleDays: PropTypes.shape({
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape({
                id: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
              }).isRequired,
            }),
          ).isRequired,
        }).isRequired,
      }),
    ).isRequired,
    time_zone: PropTypes.string.isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  };
  render() {
    const { schedules, time_zone, colors } = this.props;

    const moment = createMoment(time_zone);

    const schedulesDays = schedules.map(schedule =>
      schedule.scheduleDays.edges.map(day => day.node),
    );
    const dates = sortBy(
      uniqWith(flatten(schedulesDays).map(day => day.date), (a, b) => moment(a).isSame(b, 'day')),
    );

    if (schedules.length === 0) {
      return (
        <div className="schedules-timeline-empty">
          <i className="fa fa-fw fa-list-ol" />
          <p>Select the schedules above.</p>
          <p>You can have up to 5 schedules per event.</p>
        </div>
      );
    }

    return (
      <div className="schedules-timeline">
        {dates.map(date => (
          <SchedulesTimelineDay
            date={date}
            days={schedulesDays.map(days => days.find(day => moment(day.date).isSame(date, 'day')))}
            time_zone={time_zone}
            key={date}
            colors={colors}
          />
        ))}
      </div>
    );
  }
}

export default createFragmentContainer(
  SchedulesTimeline,
  graphql`
    fragment SchedulesTimeline_schedules on Schedule @relay(plural: true) {
      id
      name
      scheduleDays {
        edges {
          node {
            id
            date
            scheduleItems {
              edges {
                node {
                  start_time
                  end_time
                  all_day
                  ...SchedulesTimelineColumn_items
                }
              }
            }
          }
        }
      }
    }
  `,
);
