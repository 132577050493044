/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventRequestFormsActionsColumn_requestForm$ref = any;
type EventRequestFormsCreatedColumn_requestForm$ref = any;
type EventRequestFormsIsDraftColumn_requestForm$ref = any;
type EventRequestFormsLastSubmittedColumn_requestForm$ref = any;
type EventRequestFormsNameColumn_requestForm$ref = any;
type EventRequestFormsReviewersColumn_requestForm$ref = any;
type EventRequestFormsShareLinkColumn_requestForm$ref = any;
type EventRequestFormsSubmissionsColumn_requestForm$ref = any;
type EventRequestFormsUpdatedColumn_requestForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestFormsTableRow_requestForm$ref: FragmentReference;
export type EventRequestFormsTableRow_requestForm = {|
  +id: string,
  +$fragmentRefs: EventRequestFormsNameColumn_requestForm$ref & EventRequestFormsCreatedColumn_requestForm$ref & EventRequestFormsUpdatedColumn_requestForm$ref & EventRequestFormsShareLinkColumn_requestForm$ref & EventRequestFormsSubmissionsColumn_requestForm$ref & EventRequestFormsLastSubmittedColumn_requestForm$ref & EventRequestFormsIsDraftColumn_requestForm$ref & EventRequestFormsActionsColumn_requestForm$ref & EventRequestFormsReviewersColumn_requestForm$ref,
  +$refType: EventRequestFormsTableRow_requestForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestFormsTableRow_requestForm",
  "type": "EventRequestForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormsNameColumn_requestForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormsCreatedColumn_requestForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormsUpdatedColumn_requestForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormsShareLinkColumn_requestForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormsSubmissionsColumn_requestForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormsLastSubmittedColumn_requestForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormsIsDraftColumn_requestForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormsActionsColumn_requestForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestFormsReviewersColumn_requestForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '02904aeeaaf7c63330fea91547c98c86';
module.exports = node;
