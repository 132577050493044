/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BudgetCharts_event$ref = any;
type BudgetStatsOverview_event$ref = any;
type CategoriesTable_categoryEdges$ref = any;
type CategoriesTable_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetStats_event$ref: FragmentReference;
export type BudgetStats_event = {|
  +budgetCategories: {|
    +edges: $ReadOnlyArray<{|
      +$fragmentRefs: CategoriesTable_categoryEdges$ref
    |}>
  |},
  +$fragmentRefs: BudgetCharts_event$ref & BudgetStatsOverview_event$ref & CategoriesTable_event$ref,
  +$refType: BudgetStats_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BudgetStats_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BudgetCharts_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "BudgetStatsOverview_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoriesTable_event",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetCategories",
      "storageKey": null,
      "args": null,
      "concreteType": "EventBudgetCategoryConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "EventBudgetCategoryEdge",
          "plural": true,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "CategoriesTable_categoryEdges",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '244acff436acbb22c28c2d8ca8ab03bc';
module.exports = node;
