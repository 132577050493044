/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type { PublishRegistrationFormInput } from './__generated__/publishRegistrationFormMutation.graphql';

const mutation = graphql`
  mutation publishRegistrationFormMutation(
    $input: PublishRegistrationFormInput!
    $reloadFormData: Boolean!
    $includeRules: Boolean!
  ) {
    publishRegistrationForm(input: $input) {
      event {
        id
        registrationForm {
          id
          publishedAt
          publicResourceToken @include(if: $reloadFormData) {
            token
          }
          # Load necessary data for the left side form preview on save and publish in event registration tab
          ...RegistrationFormSubmissionContent_registrationForm @include(if: $reloadFormData)
        }
        editableRegistrationForm {
          id
          hasChanges
        }
      }
    }
  }
`;

export default function publishRegistrationForm(
  input: PublishRegistrationFormInput,
  reloadOnSave?: boolean = false,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
      includeRules: false,
      reloadFormData: reloadOnSave && input.save,
    },
  });
}
