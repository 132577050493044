/* @flow */
import type { RouterHistory } from 'react-router-dom';

import { type SortParam } from './parseTypedQueryString';
import replaceQueryParams from './replaceQueryParams';
import stringifyQueryParamValues, { sortToStringParam } from './stringifyQueryParamValues';

/**
 * Changes the sort query param, encoding the name of the field to sort and asc/desc.
 * Any changes to this need to be reflected in parseTypedQueryString as well.
 */
export default function replaceSortQueryParam(
  history: RouterHistory,
  sort: SortParam,
  sortParamName: string = 'sort',
) {
  const params: { [key: string]: ?string } = stringifyQueryParamValues(
    { [sortParamName]: sort },
    {
      [sortParamName]: sortToStringParam,
    },
  );
  replaceQueryParams(history, params);
}
