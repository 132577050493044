/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import { urlRegexWithoutProtocol } from 'utils/validators/isValidWebsite';

import updateSalesforceSettings from 'graph/mutations/salesforce/updateSalesforceSettings';
import showModernMutationError from 'graph/utils/showModernMutationError';

import SyncStatusIndicator from 'components/integrations/SyncStatusIndicator';
import Switch from 'components/material/Switch';
import TextField from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';

import type { SyncOptions_org } from './__generated__/SyncOptions_org.graphql';

const Section = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 0;
  ${props =>
    props.separator &&
    css`
      border-top: 1px solid ${props.theme.borderColor};
    `};
`;

const Info = styled.div`
  flex: 1 1 auto;
`;

const Label = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  font-weight: 500;
`;

const Desc = styled.div`
  color: rgba(139, 147, 157, 0.6);
  font-size: 14px;
  ${props =>
    props.enabled &&
    css`
      color: rgba(139, 147, 157, 1);
    `}
`;

const StyledIndicator = styled(SyncStatusIndicator)`
  align-self: initial;
  margin-right: 16px;
`;

const TextFieldWrapper = styled.div`
  display: flex;
  padding-right: 20px;
`;

const StyledTextField = styled(TextField)`
  flex: 0 1 200px;
`;

class SyncOptions extends React.PureComponent<
  { org: SyncOptions_org },
  {
    contactSyncLoading: boolean,
    opportunitiesSyncLoading: boolean,
    hostError: ?string,
  },
> {
  state = {
    contactSyncLoading: false,
    opportunitiesSyncLoading: false,
    hostError: null,
  };

  handleContactsSyncChange = (enabled: boolean) => {
    this.setState({ contactSyncLoading: true });

    if (this.props.org.salesforceAccount) {
      updateSalesforceSettings(
        { contactsSyncEnabled: enabled },
        this.props.org.salesforceAccount.id,
      )
        .catch(showModernMutationError)
        .then(() => {
          this.setState({ contactSyncLoading: false });
        });
    }
  };

  handleOpportunitiesSyncChange = (enabled: boolean) => {
    this.setState({ opportunitiesSyncLoading: true });

    if (this.props.org.salesforceAccount) {
      updateSalesforceSettings(
        { opportunitiesSyncEnabled: enabled },
        this.props.org.salesforceAccount.id,
      )
        .catch(showModernMutationError)
        .then(() => {
          this.setState({ opportunitiesSyncLoading: false });
        });
    }
  };

  handleHostChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const host = e.currentTarget.value.trim().replace(/^https?:\/\//, '');
    e.currentTarget.value = host;
    const salesforceAccount = this.props.org.salesforceAccount;
    if (!host) {
      this.setState({ hostError: 'Required' });
      return;
    }
    if (!urlRegexWithoutProtocol.test(host)) {
      this.setState({ hostError: 'Invalid Host' });
      return;
    }
    this.setState({ hostError: null });
    if (salesforceAccount && salesforceAccount.host !== host) {
      updateSalesforceSettings({ host }, salesforceAccount.id).catch(showModernMutationError);
    }
  };

  handleHostKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  render() {
    const { salesforceAccount, contactCustomFields } = this.props.org;

    if (!salesforceAccount) return null;

    return (
      <>
        <TextFieldWrapper>
          <StyledTextField
            label="Salesforce Host"
            defaultValue={salesforceAccount.host}
            name="reference"
            onBlur={this.handleHostChange}
            onKeyDown={this.handleHostKeyDown}
            error={this.state.hostError}
          />
        </TextFieldWrapper>
        <Section>
          <Info>
            <Label>Contacts Sync</Label>
            <Desc enabled={salesforceAccount.contactsSyncEnabled}>
              Enable Circa to create and update Leads and Contacts in Salesforce
            </Desc>
          </Info>
          <Tooltip
            label={contactCustomFields.totalCount > 0 ? null : 'Company fields is required'}
            placement="top"
          >
            <Switch
              enabled={salesforceAccount.contactsSyncEnabled}
              loading={this.state.contactSyncLoading}
              onChange={this.handleContactsSyncChange}
              disabled={contactCustomFields.totalCount === 0}
            />
          </Tooltip>
        </Section>
        <Section separator>
          <Info>
            <Label>Opportunities Sync</Label>
            <Desc enabled={salesforceAccount.opportunitiesSyncEnabled}>
              Enable Circa to pull Opportunities from Salesforce
            </Desc>
          </Info>
          {salesforceAccount.opportunitiesSyncEnabled && (
            <StyledIndicator status={salesforceAccount.opportunitySyncStatus} />
          )}
          <Tooltip
            label={
              salesforceAccount.contactsSyncEnabled ? null : 'Enable Contacts/Leads Sync first'
            }
            placement="top"
          >
            <Switch
              enabled={salesforceAccount.opportunitiesSyncEnabled}
              loading={this.state.opportunitiesSyncLoading}
              onChange={this.handleOpportunitiesSyncChange}
              disabled={!salesforceAccount.contactsSyncEnabled}
            />
          </Tooltip>
        </Section>
      </>
    );
  }
}
export default createFragmentContainer(
  SyncOptions,
  graphql`
    fragment SyncOptions_org on Org {
      salesforceAccount {
        id
        host
        contactsSyncEnabled
        opportunitiesSyncEnabled
        opportunitySyncStatus {
          ...SyncStatusIndicator_status
        }
      }

      contactCustomFields: customFields(customizableType: [CONTACT], fieldNames: ["company_id"]) {
        totalCount
      }
    }
  `,
);
