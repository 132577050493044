/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserItemsContainer_query$ref: FragmentReference;
export type UserItemsContainer_query = {|
  +searchUsers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +email: string,
        +firstName: string,
        +lastName: string,
        +$fragmentRefs: MaterialAvatar_user$ref,
      |}
    |}>
  |},
  +selectedUsers: $ReadOnlyArray<?{|
    +id: string,
    +email?: string,
    +firstName?: string,
    +lastName?: string,
    +$fragmentRefs: MaterialAvatar_user$ref,
  |}>,
  +$refType: UserItemsContainer_query$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "FragmentSpread",
  "name": "MaterialAvatar_user",
  "args": null
};
return {
  "kind": "Fragment",
  "name": "UserItemsContainer_query",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "query",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "hasTeamAccess",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "eventIds",
      "type": "[ID!]"
    },
    {
      "kind": "RootArgument",
      "name": "userIds",
      "type": "[ID!]!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "searchUsers",
      "storageKey": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 15,
          "type": "Int"
        },
        {
          "kind": "Variable",
          "name": "hasTeamAccess",
          "variableName": "hasTeamAccess",
          "type": "Boolean"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query",
          "type": "String"
        },
        {
          "kind": "Variable",
          "name": "staffedEventIds",
          "variableName": "eventIds",
          "type": "[ID!]"
        }
      ],
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": false,
              "selections": [
                v0,
                v1,
                v2,
                v3,
                v4
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "selectedUsers",
      "name": "nodes",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "userIds",
          "type": "[ID!]!"
        }
      ],
      "concreteType": null,
      "plural": true,
      "selections": [
        v0,
        {
          "kind": "InlineFragment",
          "type": "User",
          "selections": [
            v1,
            v2,
            v3,
            v4
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb6d0d46d8b62f5dbfb456d43094781f';
module.exports = node;
