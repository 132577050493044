/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

const Root = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0 10px;
`;

const Tag = styled.button`
  display: block;
  width: 120px;
  height: 27px;
  margin: 0 10px;
  border: 1px solid #3749ff;
  border-radius: 11px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
  background: #3749ff;
  outline: none;

  ${props =>
    !props.active &&
    css`
      font-weight: 400;
      color: #afb3b7;
      background: transparent;
      border-color: #afb3b7;
      cursor: pointer;

      &:hover,
      &:focus {
        color: #3749ff;
        border-color: #3749ff;
      }
    `};
`;

const contactTypes = {
  contacts: 'Contact',
  companies: 'Company',
};

export type ContactType = $Keys<typeof contactTypes>;

type Props = {
  activeType: ContactType,
  onChange: (activeType: ContactType) => void,
};

const ContactTypeSelector = ({ activeType, onChange }: Props) => (
  <Root>
    {Object.keys(contactTypes).map(key => (
      <Tag
        key={key}
        active={activeType === key}
        onClick={() => onChange(key)}
        tabIndex={activeType === key ? -1 : 0}
      >
        {contactTypes[key]}
      </Tag>
    ))}
  </Root>
);

export default ContactTypeSelector;
