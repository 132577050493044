/* @flow */
import { css } from 'styled-components';

const inputBoxStyled = (props: { error: boolean, theme: { [string]: string } }) => {
  return css`
    margin-top: 10px;
    height: 40px;
    padding: 0 40px 0 8px;
    border-radius: 4px;
    border: solid 1px #dadada;
    background: #fff;
    outline: none;
    font-size: 15px;
    transition: 0.2s border-color;
    color: #3e4859;
    ~ i {
      top: 17px;
      right: 10px;
    }
    &:hover {
      border: solid 1px #dadada;
    }
    &:focus {
      border-color: #3ba9da;
      & ~ i {
        color: #3ba9da;
      }
    }
    ${props.error &&
      css`
        border-color: ${props.theme.negativeActionColor};
        &:hover:not(:focus) {
          border-color: ${props.theme.negativeActionColor};
        }
      `};
  `;
};

export default inputBoxStyled;
