/* @flow */
import React from 'react';
import styled from 'styled-components';

import InfoIcon from 'images/infoIcon.svg';

import { SectionContent, SectionHeader, SectionLabel } from './Section';

const StyledContent = styled(SectionContent)`
  margin: 17px 0;
  > div {
    font-size: 13px;
    line-height: 1.08;
    color: rgba(74, 86, 101, 0.87);
    > svg {
      margin: -1px 7px 0 0;
    }
  }
`;

const DummySection = (props: { title: string, message: string }) => {
  return (
    <>
      <SectionHeader>
        <SectionLabel shiftRight>{props.title}</SectionLabel>
      </SectionHeader>
      <StyledContent>
        <div>
          <InfoIcon />
          {props.message}
        </div>
      </StyledContent>
    </>
  );
};

export default DummySection;
