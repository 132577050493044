/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Match, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotFound from 'components/NotFound';
import { Content } from 'components/page/Content';

import NoteEdit from './NoteEdit';

import type { EventNote_event } from './__generated__/EventNote_event.graphql';
import type { EventNoteQueryResponse } from './__generated__/EventNoteQuery.graphql';

const query = graphql`
  query EventNoteQuery($eventNoteId: ID!) {
    eventNote: node(id: $eventNoteId) {
      ... on EventNote {
        id
        name
        ...NoteEdit_note
      }
    }
  }
`;

const StyledContent = styled(Content)`
  height: 100%;
  padding-top: 0;
  padding-right: 0;

  > div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
`;

class EventNote extends React.PureComponent<{
  match: Match,
  event: EventNote_event,
  history: RouterHistory,
  onCreateNewNote: (newNoteId: string) => void,
}> {
  render() {
    return (
      <StyledContent>
        <DefaultQueryRenderer
          query={query}
          variables={{ eventNoteId: this.props.match.params.eventNoteId }}
          renderSuccess={(response: EventNoteQueryResponse) => {
            const note = response.eventNote;
            if (!note) {
              return <NotFound />;
            }
            return (
              <NoteEdit
                note={note}
                event={this.props.event}
                match={this.props.match}
                history={this.props.history}
                onCreateNewNote={this.props.onCreateNewNote}
              />
            );
          }}
        />
      </StyledContent>
    );
  }
}

export default createFragmentContainer(EventNote, {
  event: graphql`
    fragment EventNote_event on Event {
      ...NoteEdit_event
    }
  `,
});
