/* @flow */
import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import showErrorPopup from 'utils/showErrorPopup';

import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import LoadMoreRow from 'components/LoadMoreRow';
import { type ColumnConfiguration } from 'components/material/table';
import NoResultsMessage from 'components/NoResultsMessage';
import QuickFadeIn from 'components/QuickFadeIn';
import ScrollbarSizes from 'components/ScrollbarSizes';

import EventRequestsTable from './EventRequestsTable';

import type { EventRequestsTablePagination_org } from './__generated__/EventRequestsTablePagination_org.graphql';

const EventRequestSubmissionContainer = styled(QuickFadeIn)`
  flex: 1 1 auto;
  min-width: ${props => props.minWidth}px;
  height: 100%;
  overflow-y: auto;
`;

const StyledLoadMoreRow = styled(LoadMoreRow)`
  position: absolute;
  right: ${props => props.vScrollbarSize}px;
  bottom: ${props => props.hScrollbarSize}px;
  width: 100%;
  padding-right: 20px;
`;

class EventRequestsTablePagination extends React.Component<
  {
    org: EventRequestsTablePagination_org,
    tz: string,
    filtered: boolean,
    relay: RelayPaginationProp,
    history: RouterHistory,
    location: Location,
    shownColumns: ColumnConfiguration,
    // eslint-disable-next-line react/no-unused-prop-types
    includedColumns: { [key: string]: boolean },
    onColumnSizeChange: (value: string, width: number) => void,
    width: number,
    className?: string,
    scrollContainer: HTMLElement,
  },
  {
    refetching: boolean,
    vScrollbarSize: number,
    hScrollbarSize: number,
  },
> {
  state = {
    refetching: false,
    vScrollbarSize: 0,
    hScrollbarSize: 0,
  };

  paginationDisposable: ?Disposable;

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  handleScrollbarSizesChange = (sizes: { vScrollbarSize: number, hScrollbarSize: number }) => {
    this.setState(sizes);
  };

  render() {
    const {
      eventRequestSubmissions,
      eventRequestSubmissions: { totalCount },
      viewerCanManageRequestForms,
    } = this.props.org;
    return (
      <EventRequestSubmissionContainer className={this.props.className} minWidth={this.props.width}>
        {totalCount > 0 && (
          <EventRequestsTable
            viewerCanManageRequestForms={viewerCanManageRequestForms}
            eventRequestSubmissions={eventRequestSubmissions.edges.map(({ node }) => node)}
            tz={this.props.tz}
            history={this.props.history}
            location={this.props.location}
            shownColumns={this.props.shownColumns}
            onColumnSizeChange={this.props.onColumnSizeChange}
          />
        )}
        {totalCount === 0 && (
          <NoResultsMessage
            iconName="calendar-o"
            message={
              this.props.filtered
                ? 'None of the Event Request form submissions match the filter criteria.'
                : 'There is no Event Request form submissions yet'
            }
          />
        )}
        <StyledLoadMoreRow
          onLoadMore={this.handleLoadMore}
          loading={this.state.refetching}
          totalCount={totalCount}
          hasNextPage={eventRequestSubmissions.pageInfo.hasNextPage}
          vScrollbarSize={this.state.vScrollbarSize}
          hScrollbarSize={this.state.hScrollbarSize}
        />
        <ScrollbarSizes
          shouldUpdate={eventRequestSubmissions.edges.length}
          scrollContainer={this.props.scrollContainer}
          onScrollbarSizesChange={this.handleScrollbarSizesChange}
          vScrollbarSize={this.state.vScrollbarSize}
          hScrollbarSize={this.state.hScrollbarSize}
        />
      </EventRequestSubmissionContainer>
    );
  }
}
export default createPaginationContainer(
  EventRequestsTablePagination,
  graphql`
    fragment EventRequestsTablePagination_org on Org {
      viewerCanManageRequestForms
      eventRequestSubmissions(
        first: $count
        after: $cursor
        sort: $sort
        direction: $direction
        requesterUserIds: $requesterUserIds
        requesterContactIds: $requesterContactIds
        requestedDate: $requestedDate
        statuses: $statuses
        requestFormIds: $requestFormIds
        requestReviewers: $requestReviewers
      ) @connection(key: "EventRequestsTablePagination_eventRequestSubmissions", filters: []) {
        edges {
          node {
            id
            ...EventRequestsTable_eventRequestSubmissions
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  `,
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.org && props.org.eventRequestSubmissions;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        count,
        cursor,
      };
    },
    query: graphql`
      query EventRequestsTablePaginationQuery(
        $count: Int!
        $cursor: String
        $sort: EventRequestFormSubmissionSort
        $direction: Direction
        $requesterUserIds: [ID!]
        $requesterContactIds: [ID!]
        $requestedDate: [DateTime!]
        $statuses: [EventRequestSubmissionStatus!]
        $requestFormIds: [ID!]
        $requestReviewers: [ID!]
      ) {
        org {
          ...EventRequestsTablePagination_org
        }
      }
    `,
  },
);
