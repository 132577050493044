/* @flow */
import * as React from 'react';
import type { RouterHistory } from 'react-router-dom';

import type { FieldType } from 'utils/customization/types';

import type { FilterGroup } from 'components/material/ColumnFilters/columnFilterTypes';

import AdvancedFilterWrapper from './AdvancedFilterWrapper';
import { type FilterChangeType } from './FilterItems';
import FiltersContent, { type ConverterFnType } from './FiltersContent';

export default class AdvancedFilter<F: {}> extends React.Component<
  {
    history: RouterHistory,
    filters: F,
    count?: number,
    filterOptions: $ReadOnlyArray<FieldType>,
    groups?: $ReadOnlyArray<FilterGroup>,
    mobileBreakpoint?: number,
    filterRenderer: (FieldType, F, FilterChangeType) => React.Node,
    converterFn: ConverterFnType,
    className?: string,
    onFiltersChanged?: () => void,
    onEmptyFilterApply?: () => void,
  },
  {
    showOverlay: boolean,
  },
> {
  render() {
    const {
      count,
      filters,
      history,
      groups,
      filterOptions,
      mobileBreakpoint,
      filterRenderer,
      converterFn,
      className,
      onFiltersChanged,
      onEmptyFilterApply,
    } = this.props;

    if (filterOptions.length === 0) {
      return null;
    }

    return (
      <AdvancedFilterWrapper
        className={className}
        mobileBreakpoint={mobileBreakpoint}
        count={count}
        filters={filters}
      >
        <FiltersContent
          history={history}
          filters={filters}
          filterOptions={filterOptions}
          groups={groups}
          filterRenderer={filterRenderer}
          converterFn={converterFn}
          onFiltersChanged={onFiltersChanged}
          onEmptyFilterApply={onEmptyFilterApply}
        />
      </AdvancedFilterWrapper>
    );
  }
}
