/* @flow */
import UserActions from 'actions/UserActions';

import { type HomepageGraphQLValue } from 'utils/getHomepageUrl';
import googleAuthPopup from 'utils/oauth/googleAuthPopup';

type SessionResponse = {
  relay_id: string,
  access_token: string,
  google_refresh_token: string,
  email: string,
  onboarding_step: number,
  homepage: HomepageGraphQLValue,
  homepage_list_id: ?string,
  sign_in_count: number,
  domain_to_redirect?: string,
  // etc... but the only consumer of the other attributes is alt.js
};

export default function loginWithGoogle(disallowSignup: boolean = false): Promise<SessionResponse> {
  return googleAuthPopup('code', 'email profile')
    .then(code =>
      fetch('/users/auth/google_oauth2/callback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          oauth: {
            code,
            disallow_signup: disallowSignup,
          },
        }),
      }).then(response => {
        if (response.ok) {
          return response;
        }
        return response.json().then(body => {
          if (body.error) {
            return Promise.reject(new Error(body.error));
          }
          return Promise.reject(new Error('We had a problem logging you in.'));
        });
      }),
    )
    .then(response => response.json())
    .then(response => {
      const user: SessionResponse = response;
      UserActions.updateUser(user);
      return user;
    });
}
