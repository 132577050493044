/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import Remove from 'images/remove.svg';
import Switch from 'components/material/Switch';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid #eaebec;

  > div {
    display: flex;
    align-items: center;
  }
  @media (max-width: 440px) {
    padding: 0 10px;
  }
`;

const MenuItemsWrapper = styled.div`
  flex: 1 1 auto;
`;

const MenuItem = styled.div`
  position: relative;
  margin: 0 10px;
  padding: 14px 7px;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      font-weight: 500;
      color: #303030;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: #5e5cb7;
      }
    `}

  @media (${props => props.theme.mobileOnly}) {
    margin-right: 5px;
    padding: 14px 2px;

    &:not(:first-child) {
      margin-left: 5px;
    }
  }
`;

const ToggleLabel = styled.span`
  margin-right: 13px;
  font-size: 12px;
  line-height: 1;
  font-style: italic;

  @media (${props => props.theme.mobileOnly}) {
    margin-right: 7px;
  }
`;

const Close = styled(Remove)`
  display: none;
  width: 18px;
  height: 18px;
  margin-left: 15px;
  color: ${props => props.theme.secondaryActionColor};
  @media (max-width: 440px) {
    display: block;
    margin-left: 10px;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export type SearchMenuItemTypes = 'ALL' | 'EVENTS' | 'CONTACTS' | 'NOTES';

type Props = {
  active: ?SearchMenuItemTypes,
  exactMatch: boolean,
  onToggleExact: (exactMatch: boolean) => void,
  onClose: () => void,
  onActiveMenuChange: (active: SearchMenuItemTypes) => void,
  includeContacts: boolean,
};

const menuItems: $ReadOnlyArray<{ key: SearchMenuItemTypes, label: string }> = [
  { key: 'ALL', label: 'All' },
  { key: 'EVENTS', label: 'Events' },
  { key: 'CONTACTS', label: 'Contacts' },
  { key: 'NOTES', label: 'Notes' },
];

const SearchMenu = ({
  active,
  exactMatch,
  onToggleExact,
  onActiveMenuChange,
  includeContacts,
  onClose,
}: Props) => {
  const filteredMenuItems = menuItems.filter(item => item.key !== 'CONTACTS' || includeContacts);
  return (
    <Container>
      <MenuItemsWrapper>
        {filteredMenuItems.map(menuItem => (
          <MenuItem
            key={menuItem.key}
            active={menuItem.key === active}
            onClick={() => {
              onActiveMenuChange(menuItem.key);
            }}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </MenuItemsWrapper>
      <ToggleContainer>
        <ToggleLabel>Exact phrase</ToggleLabel>
        <Switch enabled={exactMatch} onChange={onToggleExact} size="small" />
      </ToggleContainer>
      <div>
        <Close onClick={onClose} />
      </div>
    </Container>
  );
};

export default SearchMenu;
