/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import TextInput from 'components/material/TextInput';
import Tooltip from 'components/material/Tooltip';

export const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 8px 0;
`;

export const Label = styled.div`
  position: absolute;
  top: 8px;
  max-width: 100%;
  pointer-events: none;
  color: ${props => (props.error ? props.theme.negativeActionColor : props.theme.labelColor)};
  font-size: ${props => (props.defaultFontSize ? props.defaultFontSize : 14)}px;
  transition: 0.2s;
  line-height: 1.8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &[data-collapsed] {
    font-size: 12px;
    top: -5px;
    line-height: 1.1;
  }
`;

export const Icon = styled.i`
  position: absolute;
  right: 0;
  top: 8px;
  color: ${props => (props.error ? props.theme.negativeActionColor : props.theme.mutedTextColor)};
  font-style: normal;
  transition: 0.2s;
`;

export const StyledTextInput = styled(TextInput)`
  padding-top: 8px;
  padding-bottom: 2px;
  &:focus ~ ${Label}, &:focus ~ ${Icon} {
    color: ${props => props.theme.primaryActionColor};
  }
  ${props =>
    props.withIcon &&
    css`
      padding-right: 20px;
    `} ${props =>
    props.error &&
    css`
      border-color: ${props.theme.negativeActionColor};
      &:hover:not(:focus) {
        border-color: ${props.theme.negativeActionColor};
      }
    `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const DescTooltip = styled(Tooltip)`
  display: inline-block;
  position: absolute;
  right: 0;
  top: 8px;
  margin-left: 5px;
  color: ${props => props.theme.secondaryActionColor};
`;

export const Error = styled.div`
  position: absolute;
  font-size: 11px;
  margin-top: 2px;
  color: ${props => props.theme.negativeActionColor};
`;

const Required = styled.span`
  font-size: 11px;
  vertical-align: top;
  margin-left: 3px;
`;

type Props = {
  id?: string,
  label?: string,
  defaultValue?: ?string,
  value?: string,
  onChange?: ?(SyntheticInputEvent<HTMLInputElement>) => void,
  className?: string,
  autoFocus?: boolean,
  required?: boolean,
  multiline?: boolean,
  name?: string,
  icon?: string | React.Node,
  info?: ?string,
  maxLength?: number,
  onClick?: (SyntheticEvent<HTMLInputElement>) => void,
  onFocus?: (SyntheticFocusEvent<HTMLInputElement>) => void,
  onBlur?: (SyntheticFocusEvent<HTMLInputElement>) => void,
  onKeyDown?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
  error?: ?string | ?boolean,
  placeholder?: string,
  inputRef?: $Call<React.createRef<any>>,
  readOnly?: boolean,
  inputStyle?: ?{},
  type?: string,
  disabled?: boolean,
  prefix?: string,
  defaultFontSize?: number,
};

type State = {
  focused: boolean,
  internalValue: string,
};

export const Root = Container;

export const Input = StyledTextInput;

export default class TextField extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.value != null && props.value !== state.internalValue) {
      return { internalValue: props.value };
    }

    return null;
  }

  state = {
    focused: false,
    internalValue: this.props.value || this.props.defaultValue || '',
  };

  handleFocus = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.setState({ focused: true });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  handleBlur = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.setState({ focused: false });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ internalValue: e.currentTarget.value });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    const labelCollapsed = this.state.focused || this.state.internalValue;
    return (
      <Container className={this.props.className}>
        <StyledTextInput
          id={this.props.id}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          name={this.props.name}
          onClick={this.props.onClick}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.props.onKeyDown}
          onChange={this.handleChange}
          multiline={this.props.multiline}
          autoFocus={this.props.autoFocus}
          withIcon={this.props.icon}
          maxLength={this.props.maxLength}
          error={this.props.error}
          inputRef={this.props.inputRef}
          placeholder={!this.props.label || labelCollapsed ? this.props.placeholder : ''}
          readOnly={this.props.readOnly}
          style={this.props.inputStyle}
          type={this.props.type}
          disabled={this.props.disabled}
          prefix={this.props.prefix || ''}
        />
        <Label
          data-collapsed={labelCollapsed || undefined}
          error={this.props.error}
          defaultFontSize={this.props.defaultFontSize}
        >
          {this.props.label}
          {this.props.label && this.props.required && <Required>*</Required>}
        </Label>
        {this.props.icon != null && (
          <Icon
            className={typeof this.props.icon === 'string' ? `fa fa-fw fa-${this.props.icon}` : ''}
            error={this.props.error}
          >
            {typeof this.props.icon !== 'string' && this.props.icon}
          </Icon>
        )}
        {this.props.info && (
          <DescTooltip label={this.props.info} placement="bottom">
            <i className="fa fa-fw fa-info-circle" />
          </DescTooltip>
        )}
        {typeof this.props.error === 'string' && <Error>{this.props.error}</Error>}
      </Container>
    );
  }
}
