/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation syncCampaignsMutation($input: SyncCampaignsInput!) {
    syncCampaigns(input: $input) {
      clientMutationId
    }
  }
`;

export default function syncCampaigns(): Promise<null> {
  return commitModernMutation({
    mutation,
    variables: { input: {} },
  })
    .catch(() => {})
    .then(() => null);
}
