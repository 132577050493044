/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import { defaultTableComponents } from 'components/Companies/columns';
import type { InputVariableFilters } from 'components/Companies/companiesTableColumnSettings';
import colsConfig from 'components/Companies/companiesTableColumnSettings';
import getCompanyFields from 'components/Companies/lib/getCompanyFields';
import getShownColumns from 'components/Companies/lib/getShownColumns';
import parseCompaniesFilters from 'components/Companies/lib/parseCompaniesFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import EventCompaniesHeaderBar from './EventCompaniesHeaderBar';
import EventCompaniesTablePagination from './EventCompaniesTablePagination';

import { type EventCompaniesPage_event } from './__generated__/EventCompaniesPage_event.graphql';
import { type EventCompaniesPage_org } from './__generated__/EventCompaniesPage_org.graphql';
import { type EventCompaniesPageQueryResponse } from './__generated__/EventCompaniesPageQuery.graphql';

const query = graphql`
  query EventCompaniesPageQuery(
    $count: Int!
    $cursor: String
    $eventSlug: String!
    $filters: CompanyFilters!
    $includeName: Boolean!
    $includeWebsite: Boolean!
    $includeStreet: Boolean!
    $includeCity: Boolean!
    $includeState: Boolean!
    $includeZip: Boolean!
    $includeCountry: Boolean!
    $includeTwitter: Boolean!
    $includeLinkedin: Boolean!
    $includeDescription: Boolean!
    $includePhone: Boolean!
    $includeCreatedAt: Boolean!
    $includeCreatedBy: Boolean!
    $includeUpdatedAt: Boolean!
    $includeUpdatedBy: Boolean!
    $includeContactsCount: Boolean!
    $includeSalesforceId: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
  ) {
    event(slug: $eventSlug) {
      ...EventCompaniesTablePagination_event
      ...EventCompaniesTablePagination_totalCountEvent
      ...EventCompaniesHeaderBar_event
    }
    org {
      ...EventCompaniesHeaderBar_org
      ...EventCompaniesTablePagination_org
    }
  }
`;

class EventCompaniesPage extends React.Component<
  {
    org: EventCompaniesPage_org,
    event: EventCompaniesPage_event,
    history: RouterHistory,
    location: Location,
    salesforceEnabled: boolean,
    tz: string,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
    selectedCompanies: $ReadOnlyArray<string>,
    availableCompanies: $ReadOnlyArray<string>,
    companiesCount: number,
    allCompaniesSelected: boolean,
  },
> {
  updateTableColumnWidths: ?() => void = null;

  companyFields: $ReadOnlyArray<FieldType> = getCompanyFields(
    normalizeCustomFields(this.props.org.companyCustomFields.edges.map(edge => edge.node)),
  );

  state = {
    shownColumns: getShownColumns('eventCompaniesShownColumns'),
    availableCompanies: [],
    selectedCompanies: [],
    companiesCount: 0,
    allCompaniesSelected: false,
  };

  handleCompaniesListUpdate = (availableCompanies: $ReadOnlyArray<string>) => {
    this.setState({ availableCompanies });
  };

  handleSelectCompanies = (selectedCompanies: $ReadOnlyArray<string>) => {
    this.setState({ selectedCompanies });
  };

  getCurrentSelection = (): $ReadOnlyArray<string> => {
    return this.state.availableCompanies.filter(item =>
      this.state.selectedCompanies.includes(item),
    );
  };

  handleUpdateTableColumnWidths = () => {
    if (this.updateTableColumnWidths) this.updateTableColumnWidths();
  };

  handleAllCompaniesSelectedUpdate = (allCompaniesSelected: boolean) => {
    this.setState({
      allCompaniesSelected,
    });
  };

  setColumnWidthUpdater = (updateColumnWidths: () => void) => {
    this.updateTableColumnWidths = updateColumnWidths;
  };

  handleCompaniesSearch = (search: string) => {
    replaceQueryParams(this.props.history, { search: search || null });
  };

  handleColumnsChange = (shownColumns: $ReadOnlyArray<string>) => {
    storage.set('eventCompaniesShownColumns', shownColumns);
    this.setState({ shownColumns });
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  updateCompaniesCount = (count: number) => {
    this.setState({ companiesCount: count });
  };

  renderTable = (props: {|
    org: ?$PropertyType<EventCompaniesPageQueryResponse, 'org'>,
    event: ?$PropertyType<EventCompaniesPageQueryResponse, 'event'>,
    filtered: boolean,
    filters: InputVariableFilters,
    sort: SortParam,
    includedColumns: { [string]: boolean },
  |}): React.Node => (
    <EventCompaniesTablePagination
      org={props.org}
      event={props.event}
      shownColumns={colsConfig(this.companyFields, this.state.shownColumns)}
      filtered={props.filtered}
      filters={props.filters}
      totalCountEvent={props.event}
      onChangeSort={this.handleSortChange}
      onCompaniesCountUpdate={this.updateCompaniesCount}
      sort={props.sort}
      tz={this.props.tz}
      includedColumns={props.includedColumns}
      onSelectCompanies={this.handleSelectCompanies}
      selectedCompanies={this.state.selectedCompanies}
      setColumnWidthUpdater={this.setColumnWidthUpdater}
      onCompaniesListUpdate={this.handleCompaniesListUpdate}
      handleAllCompaniesSelectedUpdate={this.handleAllCompaniesSelectedUpdate}
    />
  );

  render() {
    const { org, event, history, location, salesforceEnabled } = this.props;
    const shownColumns = this.state.shownColumns;
    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      this.companyFields,
      shownColumns,
    );

    const filters = parseCompaniesFilters(location.search, this.companyFields);
    const filtered = Object.keys(filters).some(key => key !== 'sort' && !!filters[key]);
    // Default sorting to name field
    const sortFilter = filters.sort || {
      key: 'NAME',
      asc: true,
    };

    const customSortField: ?FieldType = this.companyFields.find(
      field => field.id === sortFilter.key,
    );

    const variables = {
      count: 25,
      eventSlug: event.slug,
      filters: {
        sort: customSortField ? 'CUSTOM' : sortFilter.key,
        customFieldSortId: customSortField != null ? customSortField.id : null,
        query: filters.search,
        direction: sortFilter.asc ? 'ASC' : 'DESC',
        createdAt: filters.created_at,
        creatorIds: filters.created_by,
        updatedAt: filters.updated_at,
        updaterIds: filters.updated_by,
        cities: filters.city,
        states: filters.state,
        countries: filters.country,
        contactsCount: filters.contacts_count,
        customFilters: this.companyFields
          .filter(option => option.fieldName == null && filters[option.id] != null)
          .map(option => getCustomFieldVariable(filters[option.id], option)),
      },
      ...includedColumns,
    };

    const currentSelectedCompanies = this.getCurrentSelection();

    return (
      <>
        <EventCompaniesHeaderBar
          org={org}
          event={event}
          history={history}
          filters={filters}
          filterInputs={variables.filters}
          companyFields={this.companyFields}
          companiesCount={this.state.companiesCount}
          search={filters.search}
          onSearch={this.handleCompaniesSearch}
          shownColumns={shownColumns}
          currentSelectedCompanies={currentSelectedCompanies}
          onUpdateTableColumnWidths={this.handleUpdateTableColumnWidths}
          orgCompaniesCount={this.state.availableCompanies.length}
          onColumnsChange={this.handleColumnsChange}
          salesforceEnabled={salesforceEnabled}
          allCompaniesSelected={this.state.allCompaniesSelected}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: EventCompaniesPageQueryResponse) =>
            this.renderTable({
              org: props.org,
              event: props.event,
              filtered,
              filters: variables.filters,
              sort: sortFilter,
              includedColumns,
            })
          }
          renderLoading={() =>
            this.renderTable({
              org: null,
              event: null,
              filtered,
              filters: variables.filters,
              sort: sortFilter,
              includedColumns,
            })
          }
        />
      </>
    );
  }
}

export default createFragmentContainer(EventCompaniesPage, {
  event: graphql`
    fragment EventCompaniesPage_event on Event {
      id
      name
      slug
      ...EventCompaniesHeaderBar_event
    }
  `,
  org: graphql`
    fragment EventCompaniesPage_org on Org {
      id
      name
      ...EventCompaniesHeaderBar_org
      companyCustomFields: customFields(customizableType: [COMPANY]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
});
