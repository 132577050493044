/* @flow */

const fileUploadDefaults = {
  maxFiles: 20,
  concurrency: 20,
  fromSources: ['local_file_system', 'googledrive', 'dropbox', 'box', 'onedrive'],
  maxSize: 10485760, // 10mb
};

export default fileUploadDefaults;
