/**
 * @flow
 * @relayHash 51de1c423a42338e595605b5b3d72a9b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type UpdateCustomFieldInput = {
  clientMutationId?: ?string,
  id: string,
  kind?: ?CustomFieldKindType,
  label?: ?string,
  required?: ?boolean,
  showInCreateForm?: ?boolean,
  showInMobileForm?: ?boolean,
  enabled?: ?boolean,
  options?: ?$ReadOnlyArray<FieldOptionInput>,
};
export type FieldOptionInput = {
  id?: ?string,
  name: string,
};
export type updateCustomFieldMutationVariables = {|
  input: UpdateCustomFieldInput
|};
export type updateCustomFieldMutationResponse = {|
  +updateCustomField: ?{|
    +customField: {|
      +id: string,
      +kind: CustomFieldKindType,
      +label: string,
      +required: boolean,
      +enabled: boolean,
      +showInCreateForm: boolean,
      +showInMobileForm: boolean,
      +restrictManagingOptions: boolean,
      +options: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +name: string,
            +editable: boolean,
          |}
        |}>
      |},
    |}
  |}
|};
export type updateCustomFieldMutation = {|
  variables: updateCustomFieldMutationVariables,
  response: updateCustomFieldMutationResponse,
|};
*/


/*
mutation updateCustomFieldMutation(
  $input: UpdateCustomFieldInput!
) {
  updateCustomField(input: $input) {
    customField {
      id
      kind
      label
      required
      enabled
      showInCreateForm
      showInMobileForm
      restrictManagingOptions: mappedToSalesforce
      options {
        edges {
          node {
            id
            name
            editable
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCustomFieldInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateCustomField",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateCustomFieldInput!"
      }
    ],
    "concreteType": "UpdateCustomFieldPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customField",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomFieldType",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "kind",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "label",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "required",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "enabled",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "showInCreateForm",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "showInMobileForm",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": "restrictManagingOptions",
            "name": "mappedToSalesforce",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "options",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomFieldOptionTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldOptionTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldOptionType",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "editable",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateCustomFieldMutation",
  "id": null,
  "text": "mutation updateCustomFieldMutation(\n  $input: UpdateCustomFieldInput!\n) {\n  updateCustomField(input: $input) {\n    customField {\n      id\n      kind\n      label\n      required\n      enabled\n      showInCreateForm\n      showInMobileForm\n      restrictManagingOptions: mappedToSalesforce\n      options {\n        edges {\n          node {\n            id\n            name\n            editable\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateCustomFieldMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "updateCustomFieldMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '94c96d1379fbfe6c90a7aca82b126968';
module.exports = node;
