/* @flow */

export default function parseGroupInfo(
  groupInfo: ?string,
  groupType: ?string,
): { groupName: string, slug?: string } {
  if (groupInfo == null || groupInfo === '') {
    return { groupName: 'Uncategorized' };
  }
  if (groupType === 'EVENT') {
    const splitterIndex = groupInfo.lastIndexOf('|');
    const slug = groupInfo.slice(splitterIndex + 1);
    const groupName = groupInfo.slice(0, splitterIndex);
    return { groupName, slug };
  }
  return { groupName: groupInfo };
}
