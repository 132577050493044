/* @flow */

/* eslint-disable no-param-reassign */

const insertTextIntoTextField = (
  element: HTMLInputElement | HTMLTextAreaElement,
  text: string,
): string => {
  const { value, selectionStart, selectionEnd } = element;
  const before = value.slice(0, selectionStart);
  const after = value.slice(selectionEnd);
  const newValue = `${before}${/\s/.test(before.slice(-1)[0]) ? '' : ' '}${text}${
    /\s/.test(after[0]) ? '' : ' '
  }${after}`;
  const newSelection = selectionStart + (newValue.length - value.length);

  window.requestAnimationFrame(() => {
    element.selectionStart = newSelection;
    element.selectionEnd = newSelection;
    element.focus();
  });

  return newValue;
};

export default insertTextIntoTextField;
