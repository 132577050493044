/* @flow */

type EventDataAmounts = {
  +totalBudgetedAmount: number,
  +totalActualAmount: number,
  +totalPaidAmount: number,
  +budgetCategories: {|
    +edges: $ReadOnlyArray<{
      +node: {|
        +name: string,
      |},
    }>,
  |},
};

const isBudgetEmpty = (event: ?EventDataAmounts) => {
  return (
    event != null &&
    (event.totalBudgetedAmount !== 0 ||
      event.totalActualAmount !== 0 ||
      event.totalPaidAmount !== 0 ||
      event.budgetCategories.edges.length > 0)
  );
};

export default isBudgetEmpty;
