/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CustomizableBoolean_customizable$ref = any;
type CustomizableCurrency_customizable$ref = any;
type CustomizableDate_customizable$ref = any;
type CustomizableLink_customizable$ref = any;
type CustomizableMultiselect_customizable$ref = any;
type CustomizableNumber_customizable$ref = any;
type CustomizableSelect_customizable$ref = any;
type CustomizableText_customizable$ref = any;
type CustomizableTextarea_customizable$ref = any;
type CustomizableUserMultiselect_customizable$ref = any;
type CustomizableUserSelect_customizable$ref = any;
type StaffAccessLevelCell_staffer$ref = any;
type StaffActionsCell_staffer$ref = any;
type StaffLastActivityCell_staffer$ref = any;
type StaffMemberEmail_staffer$ref = any;
type StaffNameCell_staffer$ref = any;
type StaffOnsiteCell_staffer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffTableRenderer_event$ref: FragmentReference;
export type StaffTableRenderer_event = {|
  +id: string,
  +slug: string,
  +tz: string,
  +viewerCanAddStaff: boolean,
  +staffers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +user: {|
          +id: string,
          +viewerCanSeeProfile: boolean,
        |},
        +$fragmentRefs: StaffNameCell_staffer$ref & StaffMemberEmail_staffer$ref & StaffAccessLevelCell_staffer$ref & StaffLastActivityCell_staffer$ref & StaffOnsiteCell_staffer$ref & StaffActionsCell_staffer$ref & CustomizableText_customizable$ref & CustomizableTextarea_customizable$ref & CustomizableLink_customizable$ref & CustomizableDate_customizable$ref & CustomizableBoolean_customizable$ref & CustomizableNumber_customizable$ref & CustomizableCurrency_customizable$ref & CustomizableSelect_customizable$ref & CustomizableMultiselect_customizable$ref & CustomizableUserSelect_customizable$ref & CustomizableUserMultiselect_customizable$ref,
      |}
    |}>
  |},
  +$refType: StaffTableRenderer_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "StaffTableRenderer_event",
  "type": "Event",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "staffers"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count",
      "type": "Int"
    },
    {
      "kind": "RootArgument",
      "name": "cursor",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "sort",
      "type": "StaffMembershipSort"
    },
    {
      "kind": "RootArgument",
      "name": "customFieldSortId",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "direction",
      "type": "Direction"
    },
    {
      "kind": "RootArgument",
      "name": "includeEmail",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventAccessLevel",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeLastActivity",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeOnsite",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableText",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableTextarea",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableLink",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableDate",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableBoolean",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableNumber",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableCurrency",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableMultiselect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserSelect",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeCustomizableUserMultiselect",
      "type": "Boolean"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanAddStaff",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "staffers",
      "name": "__StaffTableRenderer_staffers_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffMembershipRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffMembershipRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StaffMembership",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "viewerCanSeeProfile",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "StaffNameCell_staffer",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "StaffActionsCell_staffer",
                  "args": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeEmail",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "StaffMemberEmail_staffer",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeEventAccessLevel",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "StaffAccessLevelCell_staffer",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeLastActivity",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "StaffLastActivityCell_staffer",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeOnsite",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "StaffOnsiteCell_staffer",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableText",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableText_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableTextarea",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableTextarea_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableLink",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableLink_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableDate",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableDate_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableBoolean",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableBoolean_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableNumber",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableNumber_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableCurrency",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableCurrency_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableSelect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableSelect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableMultiselect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableMultiselect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableUserSelect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableUserSelect_customizable",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeCustomizableUserMultiselect",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "CustomizableUserMultiselect_customizable",
                      "args": null
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '51428ee69f94bdec8cc5ca436fbfbfae';
module.exports = node;
