/* @flow */
import React from 'react';
import { Redirect } from 'react-router-dom';

import { setUser } from 'utils/Auth';
import withQueryParams from 'utils/routing/withQueryParams';

class AutoLogout extends React.Component<{
  queryParams: {
    next?: string,
  },
}> {
  componentDidMount() {
    setUser(null);
  }

  render() {
    return <Redirect to={this.props.queryParams.next || '/'} />;
  }
}

export default withQueryParams(AutoLogout);
