/* @flow */
import * as React from 'react';

import StatusAccepted from 'images/rsvp/accepted.svg';
import StatusDeclined from 'images/rsvp/declined.svg';
import StatusPending from 'images/rsvp/pending.svg';
import StatusTentative from 'images/rsvp/tentative.svg';

const rsvpStatusOptions: { [string]: { label: string, icon: React.Node } } = {
  INDEFINITE: {
    label: 'No response',
    icon: <StatusPending />,
  },
  ACCEPTED: {
    label: 'Yes',
    icon: <StatusAccepted />,
  },
  DECLINED: {
    label: 'No',
    icon: <StatusDeclined />,
  },
  TENTATIVE: {
    label: 'Maybe',
    icon: <StatusTentative />,
  },
};

export default rsvpStatusOptions;
