/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';

import ClearIconDefault from 'components/ClearIcon';
import Switch from 'components/material/Switch';
import TextInput from 'components/material/TextInput';

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 216px;
  outline: none;
  ${props =>
    props.advanced &&
    css`
      max-width: 333px;
      > input {
        padding-right: 123px;
      }
    `};
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 3px;
  left: 8px;
  color: #868f96;
`;

const ExactSearchControl = styled.div`
  position: absolute;
  top: 7px;
  right: 27px;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-style: italic;
  color: black;

  > div {
    margin-left: 6px;
  }
`;

const ClearIcon = styled(ClearIconDefault)`
  top: 9px;
  color: #3e4858;
`;

export const SearchTextInput = styled(TextInput)`
  padding: 2px 27px 2px 33px;
  border: 1px solid rgba(151, 151, 151, 0.43);
  border-radius: 5px;
  color: #3e4859;
`;

export default class Search extends React.PureComponent<
  {
    onSearch: (query: string) => void,
    search?: ?string,
    exactSearch?: ?boolean,
    placeholder?: string,
    className?: string,
    autoFocus?: boolean,
    onBlur?: (SyntheticFocusEvent<HTMLInputElement>) => void,
    onFocus?: (SyntheticFocusEvent<HTMLInputElement>) => void,
    onKeyDown?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
    suggestionComponent?: ({ handleToggleSuggestionComponent: () => void }) => React.Node,
  },
  { exactSearch: boolean, focused: boolean, childPopupIsActive: boolean },
> {
  state = {
    exactSearch: this.props.exactSearch === true,
    focused: false,
    childPopupIsActive: false,
  };

  input: HTMLInputElement;

  handleSearchChange = debounce((query: string) => {
    this.props.onSearch(query);
  }, 800);

  componentDidUpdate(prevProps: $PropertyType<Search, 'props'>) {
    if (prevProps.search !== this.props.search) {
      this.input.value = this.props.search || '';
    }
  }

  handleClear = () => {
    this.input.value = '';
    this.props.onSearch('');
    this.input.focus();
  };

  handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.handleSearchChange(`${e.currentTarget.value}${this.state.exactSearch ? '~EXACT' : ''}`);
  };

  handleToggleExact = (exactSearch: boolean) => {
    this.setState({ exactSearch }, () => {
      this.handleSearchChange(`${this.props.search || ''}${exactSearch ? '~EXACT' : ''}`);
    });
    this.input.focus();
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    if (this.state.childPopupIsActive) return;
    this.setState({ focused: false });
  };

  handleToggleSuggestionComponent = () => {
    this.setState(prevState => ({
      childPopupIsActive: !prevState.childPopupIsActive,
    }));
  };

  render() {
    const {
      className,
      search,
      exactSearch,
      placeholder,
      onFocus,
      onBlur,
      onKeyDown,
      suggestionComponent,
      autoFocus,
    } = this.props;
    const isAdvancedSearch = exactSearch !== undefined;

    return (
      <Container
        className={className}
        advanced={isAdvancedSearch}
        tabIndex={-1}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      >
        <SearchTextInput
          defaultValue={search}
          onChange={this.handleInputChange}
          placeholder={placeholder}
          inputRef={input => {
            this.input = input;
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
        />
        <SearchIcon className="fa fa-fw fa-search" />
        {exactSearch != null && (
          <ExactSearchControl>
            Exact phrase{' '}
            <Switch
              enabled={this.state.exactSearch}
              onChange={this.handleToggleExact}
              size="micro"
            />
          </ExactSearchControl>
        )}
        {search && <ClearIcon onClick={this.handleClear} />}
        {suggestionComponent != null &&
          this.state.focused &&
          search &&
          suggestionComponent({
            handleToggleSuggestionComponent: this.handleToggleSuggestionComponent,
          })}
      </Container>
    );
  }
}
