/* @flow */
import React from 'react';
import { type Location, NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 0 0 auto;
  background: #fff;
`;

const Tab = styled(NavLink)`
  flex: 1 1 auto;
  width: 50%;
  padding: 19px 0;
  color: #7e7e7e;
  text-align: center;
  line-height: 1em;
  border-bottom: 2px solid transparent;
  &:hover {
    color: #373951;
  }
  &.active {
    color: #373951;
    border-bottom-color: #7385a2;
  }
`;

const PagePropertiesHeader = (props: { eventSlug: ?string, location: Location }) => {
  const { eventSlug, location } = props;
  return (
    <Container>
      <Tab
        to={`${eventSlug ? `/events/${eventSlug}/` : ''}registration_builder/create/eventInfo${
          location.search
        }`}
      >
        EVENT INFO
      </Tab>
      <Tab
        to={`${eventSlug ? `/events/${eventSlug}/` : ''}registration_builder/create/design${
          location.search
        }`}
      >
        DESIGN
      </Tab>
    </Container>
  );
};

export default PagePropertiesHeader;
