/* @flow */
import { stringify } from 'qs';

// https://developers.google.com/identity/protocols/OAuth2UserAgent#redirecting
type Params = {
  client_id: string,
  redirect_uri: string,
  response_type: 'token' | 'code',
  scope: string,
  state?: string,
  include_granted_scopes?: boolean,
  login_hint?: string,
  prompt?: 'none' | 'consent' | 'select_account',
};

export default function googleAuthorizeUrl(params: Params): string {
  return `https://accounts.google.com/o/oauth2/v2/auth?${stringify(params)}`;
}
