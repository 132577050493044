/* @flow */

export default [
  { value: 'import', label: 'Import' },
  { value: 'manual', label: 'Manual' },
  { value: 'registration', label: 'Registration' },
  { value: 'api', label: 'API' },
  { value: 'zoom', label: 'Zoom Registration' },
  { value: 'past_zoom', label: 'Past Zoom Registration' },
  { value: 'g2w', label: 'GoToWebinar Registration' },
  { value: 'past_g2w', label: 'Past GoToWebinar Registration' },
  { value: 'zapier', label: 'Zapier' },
];
