/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';

import AllEventsCalendarEventWindowContent from './AllEventsCalendarEventWindowContent';

import type { AllEventsCalendarEventWindowQueryResponse } from './__generated__/AllEventsCalendarEventWindowQuery.graphql';

const query = graphql`
  query AllEventsCalendarEventWindowQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...AllEventsCalendarEventWindowContent_event
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

class AllEventsCalendarEventWindowQuery extends React.PureComponent<{
  eventSlug: string,
  linkEventNameToBrief?: boolean,
  onHide: () => void,
}> {
  render() {
    const { eventSlug, onHide, linkEventNameToBrief } = this.props;
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ eventSlug }}
        renderSuccess={(response: AllEventsCalendarEventWindowQueryResponse) =>
          response.event ? (
            <AllEventsCalendarEventWindowContent
              slug={eventSlug}
              event={response.event}
              onHide={onHide}
              linkEventNameToBrief={linkEventNameToBrief}
            />
          ) : null
        }
        renderLoading={() => (
          <LoaderContainer>
            <Loader size={30} />
          </LoaderContainer>
        )}
      />
    );
  }
}

export default AllEventsCalendarEventWindowQuery;
