/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TaskWindowContent_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskWindowPopup_org$ref: FragmentReference;
export type TaskWindowPopup_org = {|
  +$fragmentRefs: TaskWindowContent_org$ref,
  +$refType: TaskWindowPopup_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TaskWindowPopup_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "TaskWindowContent_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ed5f36a505b398c343e15bd5e12a39fb';
module.exports = node;
