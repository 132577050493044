/**
 * @flow
 * @relayHash 296e8355ce0337d4b87c9081e18a5e81
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDividerComponentInput = {
  clientMutationId?: ?string,
  id: string,
  height?: ?number,
  color?: ?string,
  padding?: ?string,
};
export type updateDividerComponentMutationVariables = {|
  input: UpdateDividerComponentInput
|};
export type updateDividerComponentMutationResponse = {|
  +updateRegistrationFormDividerComponent: ?{|
    +pageComponent: {|
      +dividerComponent: ?{|
        +id: string,
        +height: number,
        +color: ?string,
        +padding: string,
      |}
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type updateDividerComponentMutation = {|
  variables: updateDividerComponentMutationVariables,
  response: updateDividerComponentMutationResponse,
|};
*/


/*
mutation updateDividerComponentMutation(
  $input: UpdateDividerComponentInput!
) {
  updateRegistrationFormDividerComponent(input: $input) {
    pageComponent {
      dividerComponent {
        id
        height
        color
        padding
      }
      id
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDividerComponentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateDividerComponentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "dividerComponent",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationDividerComponent",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "height",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "padding",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateDividerComponentMutation",
  "id": null,
  "text": "mutation updateDividerComponentMutation(\n  $input: UpdateDividerComponentInput!\n) {\n  updateRegistrationFormDividerComponent(input: $input) {\n    pageComponent {\n      dividerComponent {\n        id\n        height\n        color\n        padding\n      }\n      id\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateDividerComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormDividerComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateDividerComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3
            ]
          },
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateDividerComponentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormDividerComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateDividerComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3,
              v2
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b9ed1330611fc11816ebcd2a70eb5d62';
module.exports = node;
