/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultTextColor } from '../../registrationFormDefaults';
import generatePaddingProps from '../generatePaddingProps';

import type { DividerComponent_componentProps } from './__generated__/DividerComponent_componentProps.graphql';

const DividerLine = styled.div`
  margin: ${props => generatePaddingProps(props.componentProps.padding)};
  height: ${props => `${props.componentProps.height}px`};
  background-color: ${props => props.componentProps.color || defaultTextColor};
`;

const DividerComponent = (props: {
  componentProps: DividerComponent_componentProps,
  selectedComponent: SelectedComponent,
}) => {
  const {
    selectedComponent,
    componentProps: { dividerComponent },
  } = props;
  if (!dividerComponent) {
    return null;
  }
  const componentProps = selectedComponent
    ? {
        ...dividerComponent,
        padding: selectedComponent.selectedComponentPadding || dividerComponent.padding,
        height:
          selectedComponent.selectedComponentHeight == null
            ? dividerComponent.height
            : selectedComponent.selectedComponentHeight,
      }
    : dividerComponent;
  return <DividerLine componentProps={componentProps} />;
};

export default createFragmentContainer(DividerComponent, {
  componentProps: graphql`
    fragment DividerComponent_componentProps on RegistrationPageComponent {
      dividerComponent {
        padding
        height
        color
      }
    }
  `,
});
