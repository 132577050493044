/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation removeCommentMutation($input: RemoveCommentInput!) {
    removeComment(input: $input) {
      removedCommentId
    }
  }
`;

export default function removeComment(commentableId: string, commentId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        commentId,
      },
    },
    optimisticResponse: {
      removeComment: {
        removedCommentId: commentId,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedCommentId',
        parentId: commentableId,
        connections: [
          { key: 'TaskComments_comments' },
          { key: 'SubmissionReviewComments_comments' },
        ],
      }),
      removeRecordFromStore({ deletedIdField: 'removedCommentId' }),
    ),
  });
}
