/* @flow */
import React from 'react';
import styled from 'styled-components';

import NotAvailable from 'images/registration/notAvailable.svg';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 912px;
  padding: 144px 0 166px;
  border: solid 1px #dadada;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #ffffff;
`;

const IconWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

const Title = styled.div`
  margin-bottom: 23px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #373951;
`;

const SubTitle = styled.div`
  font-size: 18px;
  text-align: center;
  color: #373951;
`;

const NotFound = () => {
  return (
    <Container>
      <Inner>
        <IconWrapper>
          <NotAvailable />
        </IconWrapper>
        <Title>Sorry this registration form is not available</Title>
        <SubTitle>Please contact your event organizer</SubTitle>
      </Inner>
    </Container>
  );
};

export default NotFound;
