/* @flow */
import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';

import showErrorPopup from 'utils/showErrorPopup';

import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import { SearchResultPagination, SectionContainer, SectionTitle } from './components';
import EventSearchResult from './EventSearchResult';

import { type EventsSearchPagination_searchResults } from './__generated__/EventsSearchPagination_searchResults.graphql';

class EventsSearchPagination extends React.Component<
  {
    searchResults: EventsSearchPagination_searchResults,
    totalCount: number,
    relay: RelayPaginationProp,
    isolated?: boolean,
    mobileView?: boolean,
    onShowAllResults: () => void,
  },
  {
    refetching: boolean,
  },
> {
  state = { refetching: false };

  paginationDisposable: ?Disposable;

  handleLoadMore = () => {
    const { isolated, relay } = this.props;
    this.setState({ refetching: true });

    this.paginationDisposable = relay.loadMore(isolated ? 10 : 5, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  render() {
    const { searchResults, totalCount, isolated, onShowAllResults, mobileView } = this.props;

    return (
      <SectionContainer scrollable={isolated} mobileView={mobileView}>
        <SectionTitle>{isolated ? 'RESULTS' : 'EVENTS'}</SectionTitle>
        {searchResults.events.edges.map(({ node }) => (
          <EventSearchResult key={node.id} event={node} />
        ))}
        <SearchResultPagination
          onLoadMore={this.handleLoadMore}
          stepSize={isolated ? 10 : 5}
          currentCount={searchResults.events.edges.length}
          totalCount={totalCount}
          loading={this.state.refetching}
          onShowAllResults={onShowAllResults}
        />
      </SectionContainer>
    );
  }
}

export default createPaginationContainer(
  EventsSearchPagination,
  {
    searchResults: graphql`
      fragment EventsSearchPagination_searchResults on Query {
        events: searchEvents(
          search: $search
          filters: $eventFilters
          after: $cursor
          first: $count
        ) @connection(key: "EventsSearchPagination_events", filters: []) {
          edges {
            node {
              id
              ...EventSearchResult_event
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.searchResults && props.searchResults.events;
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
      };
    },
    query: graphql`
      query EventsSearchPaginationQuery(
        $search: Search!
        $eventFilters: EventFilters!
        $count: Int!
        $cursor: String
      ) {
        ...EventsSearchPagination_searchResults
      }
    `,
  },
);
