/**
 * @flow
 * @relayHash 53d51b08a1aa12f8367c8ca87825fcf3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SalesforceKindType = "CONTACT" | "EVENT" | "EVENT_CONTACT" | "LEAD" | "OPPORTUNITY";
export type CreateOrUpdateSalesforceMappingInput = {
  clientMutationId?: ?string,
  kind: SalesforceKindType,
  circaField: string,
  salesforceField: string,
  toSalesforce: boolean,
  fromSalesforce: boolean,
  customFieldId?: ?string,
};
export type updateSalesforceMappingMutationVariables = {|
  input: CreateOrUpdateSalesforceMappingInput
|};
export type updateSalesforceMappingMutationResponse = {|
  +createOrUpdateSalesforceMapping: ?{|
    +salesforceMappingEdge: {|
      +node: {|
        +circaField: string,
        +salesforceField: string,
        +toSalesforce: boolean,
        +fromSalesforce: boolean,
        +customField: ?{|
          +id: string
        |},
      |}
    |}
  |}
|};
export type updateSalesforceMappingMutation = {|
  variables: updateSalesforceMappingMutationVariables,
  response: updateSalesforceMappingMutationResponse,
|};
*/


/*
mutation updateSalesforceMappingMutation(
  $input: CreateOrUpdateSalesforceMappingInput!
) {
  createOrUpdateSalesforceMapping(input: $input) {
    salesforceMappingEdge {
      node {
        circaField
        salesforceField
        toSalesforce
        fromSalesforce
        customField {
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateOrUpdateSalesforceMappingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateOrUpdateSalesforceMappingInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "circaField",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "salesforceField",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "toSalesforce",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fromSalesforce",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": [
    v6
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateSalesforceMappingMutation",
  "id": null,
  "text": "mutation updateSalesforceMappingMutation(\n  $input: CreateOrUpdateSalesforceMappingInput!\n) {\n  createOrUpdateSalesforceMapping(input: $input) {\n    salesforceMappingEdge {\n      node {\n        circaField\n        salesforceField\n        toSalesforce\n        fromSalesforce\n        customField {\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateSalesforceMappingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createOrUpdateSalesforceMapping",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateOrUpdateSalesforceMappingPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceMappingEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceMappingRequiredEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SalesforceMapping",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v7
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateSalesforceMappingMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createOrUpdateSalesforceMapping",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateOrUpdateSalesforceMappingPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceMappingEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceMappingRequiredEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SalesforceMapping",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v7,
                  v6
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab47c63215af47cf32d32565e686c742';
module.exports = node;
