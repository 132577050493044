/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';

import updateTasks from 'graph/mutations/task/updateTasks';

import InlineDateField from 'components/budget/Table/InlineDateField';
import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { DeliverableDueDate_deliverable } from './__generated__/DeliverableDueDate_deliverable.graphql';

const Date = styled.div`
  ${props =>
    props.isOverdue &&
    css`
      color: ${props.theme.negativeActionColor};
    `};
`;

class DeliverableDueDate extends React.PureComponent<{
  deliverable: DeliverableDueDate_deliverable,
  location: Location,
}> {
  handleDueDateChange = ({ date, hideTime }) => {
    updateTasks(
      [this.props.deliverable.id],
      { dueDate: date, dueDateAllDay: hideTime },
      this.props.location.pathname.includes('all_tasks') ? 'all tasks' : 'my tasks',
    );
  };

  render() {
    const {
      deliverable: { dueDate, dueDateAllDay, viewerCanUpdateDueDate, status, event },
    } = this.props;

    const hideTime = !!dueDateAllDay;
    const completed = status === 'COMPLETED';
    const now = moment().tz(event.tz);

    const date = dueDate ? moment.tz(dueDate, event.tz) : null;
    const dateFormatted = date ? formatDate(date) : null;
    const isOverdue = !completed && date && now.isAfter(date, dueDateAllDay ? 'day' : null);

    if (!viewerCanUpdateDueDate) {
      if (date == null) {
        return null;
      }

      return hideTime ? (
        <Date isOverdue={!!isOverdue}>{dateFormatted}</Date>
      ) : (
        <DateTimeTooltip isOverdue={!!isOverdue} date={date} />
      );
    }

    return (
      <InlineDateField
        date={dueDate}
        tz={event.tz}
        placeholder="Add Due Date"
        hideTime={hideTime}
        onChange={this.handleDueDateChange}
        isOverdue={!!isOverdue}
      />
    );
  }
}

export default createFragmentContainer(
  DeliverableDueDate,
  graphql`
    fragment DeliverableDueDate_deliverable on Deliverable {
      id
      dueDate
      status
      dueDateAllDay
      viewerCanUpdateDueDate
      event {
        tz
      }
    }
  `,
);
