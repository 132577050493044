/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  removeContactFormBccEmailMutationVariables,
  removeContactFormBccEmailMutationResponse,
} from './__generated__/removeContactFormBccEmailMutation.graphql';

const mutation = graphql`
  mutation removeContactFormBccEmailMutation($input: RemoveContactFormBccEmailInput!) {
    removeContactFormBccEmail(input: $input) {
      removedContactFormBccEmailId
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function removeContactFormBccEmail(
  contactFormId: string,
  input: $PropertyType<removeContactFormBccEmailMutationVariables, 'input'>,
): Promise<removeContactFormBccEmailMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedContactFormBccEmailId',
        parentId: contactFormId,
        connections: [{ field: 'contactFormBccEmails' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedContactFormBccEmailId' }),
    ),
  });
}
