/* @flow */
import sortBy from 'lodash/sortBy';

import customFieldsOptions from './customFieldsOptions';
import type { GroupField } from './types';

import { type SalesforceMapping_org } from '../__generated__/SalesforceMapping_org.graphql';

const opportunityFields = (org: SalesforceMapping_org): $ReadOnlyArray<GroupField> => {
  const opportunityCustomFields = sortBy(
    org.opportunityCustomFields ? org.opportunityCustomFields.edges.map(edge => edge.node) : [],
    field => field.order,
  );

  return [
    {
      title: '',
      fields: [
        {
          label: 'Opportunity Name',
          name: 'name',
          types: ['string'],
          fixedMappingField: 'Name',
          toSalesforce: false,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'Associated Contacts',
          name: 'associated_contacts',
          types: ['reference'],
          fixedMappingField: 'ContactRoles',
          toSalesforce: false,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'Value',
          name: 'amount',
          types: ['currency'],
          fixedMappingField: 'Amount',
          toSalesforce: false,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'Description',
          name: 'description',
          types: ['textarea'],
          fixedMappingField: 'Description',
          toSalesforce: false,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'Company',
          name: 'company_id',
          types: ['reference'],
          fixedMappingField: 'AccountId',
          toSalesforce: false,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'Owner',
          name: 'owner_id',
          types: ['reference'],
          fixedMappingField: 'OwnerId',
          toSalesforce: false,
          fromSalesforce: true,
          nillable: false,
        },
        ...opportunityCustomFields
          .filter(field => field.kind !== 'USER_MULTISELECT')
          .map(field => ({
            label: field.label,
            name: field.fieldName || 'custom',
            toSalesforce: false,
            fromSalesforce: true,
            nillable: !field.required && field.kind !== 'BOOLEAN',
            customFieldId: field.id,
            ...customFieldsOptions[field.kind],
          })),
      ],
    },
  ];
};

export default opportunityFields;
