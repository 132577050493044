/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import removeContactAssignment from 'graph/mutations/event/removeContactAssignment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ShareDropdown from 'components/ShareDropdown';

import type { ContactEventActionsColumn_contact } from './__generated__/ContactEventActionsColumn_contact.graphql';
import type { ContactEventActionsColumn_contactEvent } from './__generated__/ContactEventActionsColumn_contactEvent.graphql';

const Root = styled.div`
  margin: 0 -5px 0 -24px;
`;

class ContactEventActionsColumn extends React.Component<{
  contactEvent: ContactEventActionsColumn_contactEvent,
  contact: ContactEventActionsColumn_contact,
}> {
  stopPropagation = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleEventRemove = () => {
    removeContactAssignment({
      eventId: this.props.contactEvent.node.id,
      fromWindow: 'contact profile',
      contactIds: [this.props.contact.id],
    }).catch(showModernMutationError);
  };

  render() {
    return (
      <Root onClick={this.stopPropagation}>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={[
            {
              label: 'Remove from Event',
              icon: 'trash',
              onClick: this.handleEventRemove,
            },
          ]}
          overlayContainer={document.body}
        />
      </Root>
    );
  }
}

export default createFragmentContainer(ContactEventActionsColumn, {
  contactEvent: graphql`
    fragment ContactEventActionsColumn_contactEvent on ContactEventEdge {
      node {
        id
      }
    }
  `,
  contact: graphql`
    fragment ContactEventActionsColumn_contact on Contact {
      id
    }
  `,
});
