/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router';
import type { Match } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/material/Button';

import type { BriefWeb_event } from './__generated__/BriefWeb_event.graphql';

const Container = styled.div`
  flex: 0 0 60px;
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
  padding: 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
  :last-child {
    margin-right: 0;
  }
`;

class BriefWeb extends React.Component<{
  match: Match,
  event: BriefWeb_event,
}> {
  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleViewBriefWeb = () => {
    const { match, event } = this.props;
    const token = match.params.resourceToken || '';
    window.open(
      `/events/${event.slug}/share/brief/${token}?fromWindow=${
        token === '' ? 'event dashboard' : 'shared event calendar'
      }`,
    );
  };

  render() {
    if (!this.props.event.viewerCanViewBrief) {
      return null;
    }

    return (
      <Container onClick={this.stopPropagation}>
        <StyledButton minimal label="WEB" onClick={this.handleViewBriefWeb} />
      </Container>
    );
  }
}

export default createFragmentContainer(
  withRouter(BriefWeb),
  graphql`
    fragment BriefWeb_event on Event {
      slug
      viewerCanViewBrief
    }
  `,
);
