/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { type Location, type Match } from 'react-router';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import ExpensePageWrapper from './ExpensePageWrapper';

import { type ExpensePageQueryResponse } from './__generated__/ExpensePageQuery.graphql';

const query = graphql`
  query ExpensePageQuery($expenseId: ID!) {
    org {
      ...ExpensePageWrapper_org
    }
    expense: node(id: $expenseId) {
      id
      ...ExpensePageWrapper_expense
    }
  }
`;

export default class ExpensePage extends React.Component<{
  match: Match,
  location: Location,
}> {
  render() {
    const {
      match: {
        params: { expenseId },
      },
      location,
    } = this.props;

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ expenseId }}
        renderSuccess={(props: ExpensePageQueryResponse) => {
          return <ExpensePageWrapper expense={props.expense} org={props.org} location={location} />;
        }}
      />
    );
  }
}
