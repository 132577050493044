/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type EventRequestFormAccessibility = "PUBLIC" | "SSO" | "USER";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsShareViewWindow_org$ref: FragmentReference;
export type AllEventsShareViewWindow_org = {|
  +name: string,
  +slug: string,
  +hasSamlProvider: boolean,
  +settings: {|
    +subdomain: ?string
  |},
  +publishedRequestFormsCount: {|
    +totalCount: number
  |},
  +publishedRequestForms?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
        +accessibility: EventRequestFormAccessibility,
        +shareLink: ?string,
      |}
    |}>
  |},
  +$refType: AllEventsShareViewWindow_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "publishedOnly",
    "value": true,
    "type": "Boolean"
  }
];
return {
  "kind": "Fragment",
  "name": "AllEventsShareViewWindow_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "loadRequestForms",
      "type": "Boolean!",
      "defaultValue": false
    }
  ],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasSamlProvider",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subdomain",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "publishedRequestFormsCount",
      "name": "eventRequestForms",
      "storageKey": "eventRequestForms(publishedOnly:true)",
      "args": v1,
      "concreteType": "EventRequestFormRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "loadRequestForms",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": "publishedRequestForms",
          "name": "eventRequestForms",
          "storageKey": "eventRequestForms(publishedOnly:true)",
          "args": v1,
          "concreteType": "EventRequestFormRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "EventRequestFormRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "EventRequestForm",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "id",
                      "args": null,
                      "storageKey": null
                    },
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "accessibility",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "shareLink",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '923baf8f088c7067c96eef667119670f';
module.exports = node;
