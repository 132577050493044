/* @flow */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  margin: -40px -30px 30px;
  padding: 0 30px;
  border-bottom: 1px solid #d8d8d8;
  background: #fff;
`;

const Item = styled(NavLink)`
  margin-bottom: -1px;
  padding: 12px 15px;
  font-size: 13px;
  color: #828b93;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &.active {
    font-weight: 500;
    color: #3e4859;
    border-bottom: 2px solid #39a8da;
  }

  &:hover {
    color: #3e4859;
  }
`;

export default class IntegrationSettingsSubmenu extends React.PureComponent<{
  items: $ReadOnlyArray<{ to: string, label: string }>,
}> {
  render() {
    const { items } = this.props;

    return (
      <Root>
        {items.map(item => (
          <Item key={item.to} to={item.to} activeClassName="active">
            {item.label}
          </Item>
        ))}
      </Root>
    );
  }
}
