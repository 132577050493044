/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import MaterialAvatar from 'components/material/MaterialAvatar';
import Tag from 'components/Contacts/Tag';

import type { UserSearchRow_user } from './__generated__/UserSearchRow_user.graphql';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  line-height: 1.4;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
`;

const Main = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  white-space: nowrap;
`;

const Secondary = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 12px;
  white-space: nowrap;
`;

const StyledTag = styled(Tag)`
  margin-left: 7px;
`;

const StyledAvatar = styled(MaterialAvatar)`
  margin-right: 10px;
`;

class UserSearchRow extends React.PureComponent<{
  user: UserSearchRow_user,
}> {
  render() {
    return (
      <Row>
        <StyledAvatar user={this.props.user} radius={12} />
        <div>
          <Main>
            {this.props.user.firstName} {this.props.user.lastName}
            <StyledTag color="#37B2FF" compact active>
              Member
            </StyledTag>
          </Main>
          <Secondary>{this.props.user.email}</Secondary>
        </div>
      </Row>
    );
  }
}

export default createFragmentContainer(
  UserSearchRow,
  graphql`
    fragment UserSearchRow_user on User {
      firstName
      lastName
      email
      ...MaterialAvatar_user
    }
  `,
);
