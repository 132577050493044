/* @flow */
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';

import type { FieldType } from 'utils/customization/types';

import type { AllEventsNew_org } from 'views/Main/Dashboard/AllEvents/AllEventsListPage/__generated__/AllEventsListPage_org.graphql';

type ConfigParams = {
  salesforceEnabled: boolean,
  marketoEnabled: boolean,
  customFieldSections: $ReadOnlyArray<{| +id: string, +label: string |}>,
};

export type GroupType = {
  +label: string, // Label to use in column settings
  +id: number | string, // Unique identifier
};

export type ColumnGroupConfiguration = $ReadOnlyArray<GroupType>;

export const ColsGroups = (
  params: ConfigParams,
  includeLegacyFeatures: boolean,
): ColumnGroupConfiguration => {
  return [
    {
      ...params.customFieldSections[0],
    },
    {
      label: 'Location',
      id: 2,
    },
    {
      label: 'FLOOR PLAN',
      id: 3,
    },
    ...params.customFieldSections.slice(1),
    {
      label: 'Budget',
      id: 6,
    },
    ...(includeLegacyFeatures
      ? [
          {
            label: 'Contacts',
            id: 10,
          },
        ]
      : []),
    params.salesforceEnabled || params.marketoEnabled
      ? {
          label: 'CRM',
          id: 7,
        }
      : null,
    {
      label: 'Request Forms',
      id: 8,
    },
    {
      label: 'Metadata',
      id: 9,
    },
  ].filter(Boolean);
};

// fieldName is used for
//  shownColumns config
//  filter items map in components/AllEvents/lib/renderEventFilter.js
//  defaultTableComponents in components/AllEvents/columns/index.js
// id used for
//  sorting
export default function getEventFields(
  customFields: $ReadOnlyArray<FieldType>,
  org: AllEventsNew_org,
  includeLegacyFeatures: boolean,
): $ReadOnlyArray<FieldType> {
  const salesforceEnabled = !!org.salesforceAccount;
  const marketoEnabled = !!org.marketoAccount;
  const basicSectionId = sortBy(org.customFieldSections.edges, edge => edge.node.order)[0].node.id;
  const [basicCustomFields, otherCustomFields] = partition(
    // Convert $ReadOnlyArray to Array
    [...customFields],
    field => field.section && field.section.id === basicSectionId,
  );
  return [
    {
      id: 'name',
      label: 'Event Name',
      fieldName: 'name',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: basicSectionId,
    },
    {
      id: 'event_format',
      label: 'Format',
      fieldName: 'event_format',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: basicSectionId,
    },
    {
      id: 'team_name',
      label: 'Team Name',
      fieldName: 'team',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: basicSectionId,
    },
    {
      id: 'event_lead',
      label: 'Leader',
      fieldName: 'lead',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: basicSectionId,
      minWidth: 155,
    },
    {
      id: 'event_staff',
      label: 'Staff',
      fieldName: 'event_staff',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      unsortable: true,
      groupId: basicSectionId,
    },
    {
      id: 'start_date',
      label: 'Start Date',
      fieldName: 'start_date',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: basicSectionId,
      minWidth: 130,
    },
    {
      id: 'end_date',
      label: 'End Date',
      fieldName: 'end_date',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: basicSectionId,
      minWidth: 130,
    },
    {
      id: 'commitment_level',
      label: 'Status',
      fieldName: 'status',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: basicSectionId,
    },
    {
      id: 'progress',
      label: 'Progress',
      fieldName: 'progress',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: basicSectionId,
    },
    {
      id: 'website',
      label: 'Website',
      fieldName: 'website',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: basicSectionId,
      minWidth: 215,
    },
    ...basicCustomFields,
    {
      id: 'brief_web',
      label: 'Brief',
      fieldName: 'brief_web',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      unsortable: true,
      groupId: basicSectionId,
      options: [],
    },
    {
      id: 'venue_name',
      label: 'Venue Name',
      fieldName: 'venue_name',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 2,
    },
    {
      id: 'street',
      label: 'Street Address',
      fieldName: 'venue_street',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 2,
    },
    {
      id: 'city',
      label: 'City',
      fieldName: 'venue_city',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 2,
    },
    {
      id: 'state',
      label: 'State/District',
      fieldName: 'venue_state',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 2,
    },
    {
      id: 'zip_code',
      label: 'Zip/Postal Code',
      fieldName: 'venue_zip',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 2,
    },
    {
      id: 'country',
      label: 'Country',
      fieldName: 'venue_country',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 2,
    },
    {
      id: 'virtual_location',
      label: 'Virtual Location',
      fieldName: 'virtual_location',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 2,
    },
    {
      id: 'booth',
      label: 'Booth #',
      fieldName: 'booth',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 3,
    },
    {
      id: 'booth_dimensions',
      label: 'Dimensions',
      fieldName: 'booth_dimensions',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 3,
    },
    ...otherCustomFields,
    {
      id: 'total_planned_cost',
      label: 'Planned Total',
      fieldName: 'planned_total_cost',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 6,
      align: 'right',
    },
    {
      id: 'total_actual_cost',
      label: 'Actual Total',
      fieldName: 'actual_total_cost',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 6,
      align: 'right',
    },
    {
      id: 'total_paid_cost',
      label: 'Paid Total',
      fieldName: 'paid_total_cost',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 6,
      align: 'right',
    },
    ...(includeLegacyFeatures
      ? [
          {
            id: 'contacts_count',
            label: 'Total Contacts',
            fieldName: 'contacts_count',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
            groupId: 10,
            align: 'right',
          },
          {
            id: 'registered_contacts_total',
            label: 'Registered Contacts',
            fieldName: 'registered_contacts_total',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
            groupId: 10,
            align: 'right',
          },
          {
            id: 'attended_contacts_total',
            label: 'Attended Contacts',
            fieldName: 'attended_contacts_total',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
            groupId: 10,
            align: 'right',
          },
        ]
      : []),
    ...(salesforceEnabled && includeLegacyFeatures
      ? [
          {
            id: 'sync_status',
            label: 'CRM Sync Status',
            fieldName: 'sync_status',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
            groupId: 7,
          },
          {
            id: 'last_synced',
            label: 'CRM Last Sync',
            fieldName: 'last_sync',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
            groupId: 7,
          },
          {
            id: 'salesforce_id',
            label: 'CRM Campaign ID',
            fieldName: 'salesforce_id',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
            groupId: 7,
          },
          {
            id: 'opportunities_number',
            label: 'Opportunities',
            fieldName: 'opportunities_number',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
            groupId: 7,
          },
          {
            id: 'opportunities_amount',
            label: 'Opportunities Amount',
            fieldName: 'opportunities_amount',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
            groupId: 7,
          },
        ]
      : []),
    ...(marketoEnabled && includeLegacyFeatures
      ? [
          {
            id: 'marketo_id',
            label: 'Marketo Program ID',
            fieldName: 'marketo_id',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
            groupId: 7,
          },
        ]
      : []),
    {
      id: 'requested_form_name',
      label: 'Request Form',
      fieldName: 'request_form',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 8,
    },
    {
      id: 'requested_by',
      label: 'Submitted by',
      fieldName: 'requested_by',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 8,
    },
    {
      id: 'requested_date',
      label: 'Submitted Date',
      fieldName: 'requested_date',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 8,
    },
    {
      id: 'requested_status',
      label: 'Request Status',
      fieldName: 'request_status',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 8,
    },
    {
      id: 'request_reviewers',
      label: 'Reviewers',
      fieldName: 'request_reviewers',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      unsortable: true,
      groupId: 8,
    },
    {
      id: 'id',
      label: 'Event ID',
      fieldName: 'id',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      unsortable: true,
      groupId: 9,
    },
    {
      id: 'updated_at',
      label: 'Updated at',
      fieldName: 'updated_at',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 9,
    },
    {
      id: 'updater',
      label: 'Updated by',
      fieldName: 'updater',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 9,
    },
    {
      id: 'created_at',
      label: 'Created at',
      fieldName: 'created_at',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 9,
    },
    {
      id: 'creator',
      label: 'Created by',
      fieldName: 'creator',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      groupId: 9,
    },
  ];
}
