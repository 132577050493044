/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { MarketoId_event } from './__generated__/MarketoId_event.graphql';

const Link = styled.a`
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    text-decoration: underline;
  }
`;

const stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
  e.stopPropagation();
};

const MarketoId = (props: { event: MarketoId_event }) => {
  const marketoProgram = props.event.marketoProgram;

  if (!marketoProgram) return null;

  return (
    <Link
      href={marketoProgram.url}
      target="_blank"
      rel="noreferrer noopener"
      onClick={stopPropagation}
    >
      {marketoProgram.marketoId}
    </Link>
  );
};

export default createFragmentContainer(
  MarketoId,
  graphql`
    fragment MarketoId_event on Event {
      marketoProgram {
        marketoId
        url
      }
    }
  `,
);
