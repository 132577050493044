/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

// import configureSolutionInstance from 'graph/mutations/integration/configureSolutionInstance';
import enableSolutionInstance from 'graph/mutations/integration/enableSolutionInstance';
import removeSolutionInstance from 'graph/mutations/integration/removeSolutionInstance';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button, { OutlineButton } from 'components/budget/Button';
import ConfirmationWindow from 'components/ConfirmationWindow';

import { type IntegrationSettings } from '.';
import IntegrationMessage from './IntegrationMessage';
import IntegrationSettingsIcon from './IntegrationSettingsIcon';

// import openTrayPopup from './lib/openTrayPopup';
import { type IntegrationSettingsAuth_integrable } from './__generated__/IntegrationSettingsAuth_integrable.graphql';

const Columns = styled.div`
  display: flex;
  align-items: ${props => (props.centered ? 'center' : 'flex-start')};

  &:not(:first-child) {
    padding-top: 10px;
  }
`;

const IntegrationMain = styled.div`
  flex: 1;
  align-self: center;
  padding: 0 30px;
`;

const IntegrationButtons = styled.div`
  > button {
    width: 95px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }
`;

const IntegrationName = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #3e4859;

  & + ${IntegrationButtons} {
    margin-top: 10px;
  }
`;

const ConnectedLabel = styled.div`
  margin: 10px 0 3px;
  font-size: 13px;
  color: ${props => props.color};
`;

const ConnectedValue = styled.div`
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #4a5665;
`;

const UpgradeMessage = styled.div`
  margin-left: ${props => (props.showIcon ? 0 : 30)}px;
  margin-bottom: 20px;
  padding: 7px 10px;
  border-radius: 6px;
  border: 1px solid #ff7980;
  background: #fff;
`;

class IntegrationSettingsAuth extends React.PureComponent<
  {
    integrable: IntegrationSettingsAuth_integrable,
    integration: IntegrationSettings,
    showIcon: boolean,
    showTitle: boolean,
    showButtons: boolean,
    showExpireMessage: boolean,
    centered: boolean,
  },
  {
    loading: boolean,
    showDisconnectConfirmation: boolean,
  },
> {
  state = {
    loading: false,
    showDisconnectConfirmation: false,
  };

  handleConnectSolution = () => {
    try {
      throw new Error(
        `We are unable to connect your ${this.getIntegrationName()} account. Please contact success@circa.co for assistance.`,
      );
    } catch (error) {
      showModernMutationError(error);
    }
    // const integrableId = this.props.integrable && this.props.integrable.id;
    // const solutionName = this.props.integration.solutionName;
    //
    // if (!integrableId || !solutionName) return;
    //
    // this.setState({ loading: true });
    //
    // const popup = openTrayPopup(this.handleClosePopup, this.handleEnableSolution);
    //
    // configureSolutionInstance({ integrableId, solutionName })
    //   .then(frameUrl => {
    //     popup.location.href = frameUrl;
    //   })
    //   .catch(error => {
    //     showModernMutationError(error);
    //
    //     this.handleClosePopup();
    //   });
  };

  handleEnableSolution = () => {
    const integrableId = this.props.integrable && this.props.integrable.id;
    const solutionName = this.props.integration.solutionName;

    if (!integrableId || !solutionName) return;

    enableSolutionInstance({ integrableId, solutionName })
      .catch(showModernMutationError)
      .then(() => {
        this.handleClosePopup();
      });
  };

  handleClosePopup = () => {
    this.setState(state => (state.loading ? { loading: false } : null));
  };

  handleRemoveSolution = () => {
    const integrableId = this.props.integrable && this.props.integrable.id;
    const solutionInstance =
      this.props.integration.solutionName &&
      this.props.integrable &&
      this.props.integrable.traySolutionInstances.edges
        .map(edge => edge.node)
        .find(instance => instance.solutionName === this.props.integration.solutionName);

    if (!integrableId || !solutionInstance) return;

    removeSolutionInstance({ integrableId, traySolutionInstanceId: solutionInstance.id }).catch(
      showModernMutationError,
    );
  };

  handleShowDisconnectConfirmation = () => {
    this.setState({ showDisconnectConfirmation: true });
  };

  handleHideDisconnectConfirmation = () => {
    this.setState({ showDisconnectConfirmation: false });
  };

  getIntegrationName = () => {
    const { headerName, name } = this.props.integration;
    return headerName || name.replace(/Integration$/, '');
  };

  render() {
    const {
      integration,
      integrable,
      showIcon,
      showTitle,
      showButtons,
      showExpireMessage,
      centered,
    } = this.props;
    const { loading, showDisconnectConfirmation } = this.state;
    const solutionInstance =
      integration.solutionName &&
      integrable &&
      integrable.traySolutionInstances.edges
        .map(edge => edge.node)
        .find(instance => instance.solutionName === integration.solutionName);
    const integrationName = this.getIntegrationName();

    return (
      <div>
        {integration.expired && showExpireMessage && (
          <UpgradeMessage showIcon={showIcon}>
            <IntegrationMessage>
              Authentication expired.{' '}
              <a onClick={loading ? undefined : this.handleConnectSolution}>Update</a> to connect
              again.
            </IntegrationMessage>
          </UpgradeMessage>
        )}

        <Columns centered={centered}>
          {showIcon && <IntegrationSettingsIcon size={70} integration={integration} />}

          <IntegrationMain>
            {showTitle && <IntegrationName>{integrationName} Integration</IntegrationName>}

            {showButtons && !integration.connected && integrable && (
              <IntegrationButtons>
                <Button loading={loading} onClick={this.handleConnectSolution}>
                  Connect {showTitle ? '' : 'Account'}
                </Button>
              </IntegrationButtons>
            )}

            {solutionInstance && solutionInstance.authAccountId && (
              <>
                <ConnectedLabel color={integration.color}>
                  Connected {showTitle ? '' : integrationName} Account
                </ConnectedLabel>
                <ConnectedValue>{solutionInstance.authAccountId}</ConnectedValue>
              </>
            )}
          </IntegrationMain>

          {showButtons && (
            <IntegrationButtons>
              {integration.expired && (
                <Button loading={loading} onClick={this.handleConnectSolution}>
                  Update
                </Button>
              )}

              {integration.connected && (
                <OutlineButton
                  label="Disconnect"
                  onClick={this.handleShowDisconnectConfirmation}
                  variant="danger"
                />
              )}
            </IntegrationButtons>
          )}
        </Columns>

        {showDisconnectConfirmation && (
          <ConfirmationWindow
            onHide={this.handleHideDisconnectConfirmation}
            onConfirm={this.handleRemoveSolution}
            actionLabel="Disconnect"
            message={integration.disconnectConfirmation}
          />
        )}
      </div>
    );
  }
}

export default createFragmentContainer(
  IntegrationSettingsAuth,
  graphql`
    fragment IntegrationSettingsAuth_integrable on IntegrableInterface {
      id
      traySolutionInstances(includeExpired: true) {
        edges {
          node {
            id
            solutionName
            authAccountId
          }
        }
      }
    }
  `,
);
