/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SubmissionReviewComments_org$ref = any;
type SubmissionReviewTeam_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubmissionReviewSidebar_org$ref: FragmentReference;
export type SubmissionReviewSidebar_org = {|
  +requestsTeam: {|
    +id: string
  |},
  +$fragmentRefs: SubmissionReviewTeam_org$ref & SubmissionReviewComments_org$ref,
  +$refType: SubmissionReviewSidebar_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SubmissionReviewSidebar_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "SubmissionReviewTeam_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SubmissionReviewComments_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "requestsTeam",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '17825ce3f8fbe04e1390e5c04f035340';
module.exports = node;
