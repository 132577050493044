/* @flow */

import React from 'react';
import { type Location, Redirect, Route, Switch } from 'react-router-dom';

import RegistrationCreate from './RegistrationCreate';
import RegistrationLogic from './RegistrationLogic';
import RegistrationSettings from './RegistrationSettings';

const RegistrationRoutes = (props: {
  pathPrefix: string,
  location: Location,
  discardCounter?: number,
}) => {
  const { pathPrefix, location, discardCounter } = props;
  return (
    <Switch>
      <Route
        path={`${pathPrefix}/create`}
        render={pageProps => <RegistrationCreate {...pageProps} discardCounter={discardCounter} />}
      />
      <Route
        path={`${pathPrefix}/logic`}
        render={pageProps => <RegistrationLogic {...pageProps} discardCounter={discardCounter} />}
      />
      <Route
        path={`${pathPrefix}/settings`}
        render={pageProps => (
          <RegistrationSettings {...pageProps} discardCounter={discardCounter} />
        )}
      />
      <Redirect from={pathPrefix} to={`${pathPrefix}/create${location.search}`} />
    </Switch>
  );
};

export default RegistrationRoutes;
