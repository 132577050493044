/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import Calendar from 'images/checklist/calendar.svg';
import DueDate from 'images/checklist/due_date.svg';
import Sections from 'images/checklist/sections.svg';
import Button from 'components/material/Button';
import Tooltip from 'components/material/Tooltip';
import Search from 'components/Search';
import ImportOverlay from 'components/Tasks/overlays/ImportOverlay';
import ViewModes from 'components/ViewModes';

import { type ParsedTaskFilters } from '../components/parseTaskFilters';
import TaskFilters from '../components/TaskFilters';
import TasksHeaderShare from '../TasksHeaderShare';
import TasksMultiselect from './TasksMultiselect';

import type { TasksHeader_event } from './__generated__/TasksHeader_event.graphql';
import type { TasksHeader_org } from './__generated__/TasksHeader_org.graphql';

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 4px 28px 14px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  @media (max-width: 790px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const StyledTaskFilters = styled(TaskFilters)`
  @media (max-width: 790px) {
    min-width: auto;
    margin-left: 0;
  }
`;

const ButtonContainer = styled.div`
  position: relative;
`;

const SearchContainer = styled.div`
  width: 100%;
  max-width: 216px;
  margin: 0 10px;
  @media (max-width: 600px) {
    display: none;
  }
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: 10px;
  &:nth-of-type(1) {
    min-width: 390px;
  }
  &:nth-of-type(2) {
    min-width: 290px;
    justify-content: center;
  }

  @media (max-width: 1180px) {
    &:nth-of-type(2) {
      flex: initial;
      justify-content: flex-end;
    }
  }

  @media (max-width: 1020px) {
    &:nth-of-type(2) {
      justify-content: flex-start;
    }
  }
`;

class TasksHeader extends React.PureComponent<
  {
    userEmail: string,
    event: TasksHeader_event,
    org: TasksHeader_org,
    onAddFormShow: () => void,
    selectedTasks: Array<string>,
    onTasksUpdate: (Array<string>, Object) => void,
    onTasksStatusUpdate: (Array<string>, 0 | 1) => void,
    onTasksRemove: (Array<string>) => void,
    onTasksAssign: (Array<string>, string) => void,
    onTasksUnassign: (Array<string>, string) => void,
    onTasksAddTag: (Array<string>, string) => void,
    onTasksRemoveTag: (Array<string>, string) => void,
    onFolderCreate: string => Promise<string>,
    onImport: ({
      sourceEvent: number,
      copyDueDates: boolean,
      folders: Array<number>,
    }) => Promise<any>,
    filters: ParsedTaskFilters,
    viewMode: string,
    onViewModeChange: (viewMode: string) => void,
    searchQuery: string,
    onChangeSearchQuery: (searchQuery: string) => void,
    history: RouterHistory,
  },
  { importOverlayShown: boolean },
> {
  state = {
    importOverlayShown: false,
  };

  importButton: ?HTMLElement;

  handleImportOverlayShow = () => this.setState({ importOverlayShown: true });

  handleImportOverlayHide = () => this.setState({ importOverlayShown: false });

  render() {
    const {
      event,
      org,
      userEmail,
      history,
      selectedTasks,
      onAddFormShow,
      viewMode,
      filters,
    } = this.props;
    const multiselectShown = selectedTasks.length > 0;

    const viewerCanCreateDeliverable = event.viewerCanCreateDeliverable;
    const tags = org.tags.edges.map(edge => edge.node);

    return (
      <Header>
        <HeaderItem>
          {viewerCanCreateDeliverable && (
            <>
              <StyledButton
                label="Add Task"
                primary
                onClick={onAddFormShow}
                disabled={multiselectShown}
              />
              <Tooltip label="Import" placement="top">
                <ButtonContainer
                  ref={el => {
                    this.importButton = el;
                  }}
                >
                  <Button
                    icon="arrow-down"
                    disabled={multiselectShown}
                    onClick={this.handleImportOverlayShow}
                    outline
                    primary
                  />
                </ButtonContainer>
              </Tooltip>
            </>
          )}
          <ImportOverlay
            target={this.importButton}
            container={this.importButton}
            show={this.state.importOverlayShown}
            onHide={this.handleImportOverlayHide}
            onImport={this.props.onImport}
            hasStartDate={event.startDate != null}
          />
          {multiselectShown && (
            <TasksMultiselect
              org={org}
              event={event}
              selectedTasks={selectedTasks}
              onTasksUpdate={this.props.onTasksUpdate}
              onTasksStatusUpdate={this.props.onTasksStatusUpdate}
              onTasksRemove={this.props.onTasksRemove}
              onTasksAssign={this.props.onTasksAssign}
              onTasksUnassign={this.props.onTasksUnassign}
              onTasksAddTag={this.props.onTasksAddTag}
              onTasksRemoveTag={this.props.onTasksRemoveTag}
              onFolderCreate={this.props.onFolderCreate}
            />
          )}
          {!multiselectShown && (
            <SearchContainer>
              <Search
                placeholder="Search Tasks"
                search={this.props.searchQuery}
                onSearch={this.props.onChangeSearchQuery}
              />
            </SearchContainer>
          )}
        </HeaderItem>
        <HeaderItem>
          <ViewModes
            viewMode={this.props.viewMode}
            onViewModeChange={this.props.onViewModeChange}
            modes={[
              { value: 'section', label: 'Section', svg: Sections },
              { value: 'due-date', label: 'Due Date', svg: DueDate },
              { value: 'calendar', label: 'Calendar', svg: Calendar },
            ]}
          />
        </HeaderItem>
        <HeaderItem>
          <StyledTaskFilters viewMode={viewMode} filters={filters} tags={tags} history={history} />
          <TasksHeaderShare event={event} userEmail={userEmail} />
        </HeaderItem>
      </Header>
    );
  }
}

export default createFragmentContainer(TasksHeader, {
  org: graphql`
    fragment TasksHeader_org on Org {
      ...TasksMultiselect_org
      tags {
        edges {
          node {
            ...TaskFilters_tags
          }
        }
      }
    }
  `,
  event: graphql`
    fragment TasksHeader_event on Event {
      id
      slug
      name
      startDate
      viewerCanCreateDeliverable
      ...TasksMultiselect_event
      ...TasksHeaderShare_event
    }
  `,
});
