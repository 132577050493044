/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type VendorPageRoutes_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorPage_org$ref: FragmentReference;
export type VendorPage_org = {|
  +id: string,
  +customFields: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +enabled: boolean
      |}
    |}>
  |},
  +$fragmentRefs: VendorPageRoutes_org$ref,
  +$refType: VendorPage_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VendorPage_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VendorPageRoutes_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFields",
      "storageKey": "customFields(customizableType:[\"VENDOR\"],fieldNames:[\"title\"],includeDisabled:true)",
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": [
            "VENDOR"
          ],
          "type": "[CustomizableType!]!"
        },
        {
          "kind": "Literal",
          "name": "fieldNames",
          "value": [
            "title"
          ],
          "type": "[String!]"
        },
        {
          "kind": "Literal",
          "name": "includeDisabled",
          "value": true,
          "type": "Boolean"
        }
      ],
      "concreteType": "CustomFieldTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldType",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "enabled",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6151da0482df0790ac6bdde51d576db6';
module.exports = node;
