/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';

import NotesBrief from './NotesBrief';

import type { NotesBriefRootQueryResponse } from './__generated__/NotesBriefRootQuery.graphql';

const query = graphql`
  query NotesBriefRootQuery(
    $eventSlug: String!
    $noteIds: [ID!]!
    $listToken: String
    $sharedView: Boolean!
  ) {
    event(slug: $eventSlug, listToken: $listToken) {
      briefSettings @include(if: $sharedView) {
        notes {
          edges {
            node {
              id
              name
              updatedAt
              content
              updater {
                ...NotesUpdater_user
                ...MaterialAvatar_user
              }
              attachments {
                edges {
                  node {
                    id
                    fileurl
                    filename
                    filetype
                    filesize
                  }
                }
              }
            }
          }
        }
      }
      eventNotes(noteIds: $noteIds) @skip(if: $sharedView) {
        edges {
          node {
            id
            name
            updatedAt
            content
            updater {
              ...NotesUpdater_user
              ...MaterialAvatar_user
            }
            attachments {
              edges {
                node {
                  id
                  fileurl
                  filename
                  filetype
                  filesize
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class NotesBriefRoot extends React.Component<{
  tz: string,
  eventSlug: string,
  noteIds: $ReadOnlyArray<string>,
  listToken: ?string,
  sharedView: boolean,
  primaryColor: string,
}> {
  cachedNotes = [];

  render() {
    const { listToken, eventSlug, noteIds, sharedView, primaryColor, tz } = this.props;
    const variables = {
      eventSlug,
      noteIds,
      sharedView,
      ...(listToken ? { listToken } : {}),
    };

    return (
      <DefaultQueryRenderer
        query={query}
        public={sharedView}
        variables={variables}
        renderSuccess={(dataProps: NotesBriefRootQueryResponse) => {
          if (!dataProps.event) {
            return <NotAuthorized />;
          }
          const { briefSettings, eventNotes } = dataProps.event;
          const sharedNotes = briefSettings ? briefSettings.notes.edges.map(edge => edge.node) : [];
          const savedNotes = eventNotes ? eventNotes.edges.map(edge => edge.node) : [];
          this.cachedNotes = sharedView ? sharedNotes : savedNotes;
          return <NotesBrief primaryColor={primaryColor} notes={this.cachedNotes} tz={tz} />;
        }}
        renderLoading={() => (
          <NotesBrief primaryColor={primaryColor} notes={this.cachedNotes} tz={tz} />
        )}
      />
    );
  }
}
