/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';
import LockIcon from 'components/LockIcon';

import type { CustomizableText_customizable } from './__generated__/CustomizableText_customizable.graphql';

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
`;

class CustomizableText extends React.Component<{
  customizable: CustomizableText_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
}> {
  getValue = (): string => {
    const { customizable, fieldSettings } = this.props;
    const customTextField = customizable.customTextFields.find(
      textField => textField.customField.id === fieldSettings.id,
    );
    return customTextField ? customTextField.value : '';
  };

  handleSave = (value: ?string): void => {
    const { onUpdateCustomField, updateColumnWidth, customizable, fieldSettings } = this.props;
    if (!this.getError(value) && value !== this.getValue() && onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        textValue: value,
      });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    if (this.props.fieldSettings.required && (!value || !value.trim())) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { readOnly, fieldSettings, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    const customText = this.getValue();
    if (readOnly || fieldSettings.restrictChangingValue) {
      return (
        <InlineExpandableText>
          {customText}
          {!readOnly && fieldSettings.restrictChangingValue && (
            <StyledLockIcon label="Salesforce" />
          )}
        </InlineExpandableText>
      );
    }
    return (
      <InlineTextField
        value={customText}
        onChange={this.handleSave}
        placeholder={fieldSettings.label}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
      >
        {customText && <InlineExpandableText>{customText}</InlineExpandableText>}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  CustomizableText,
  graphql`
    fragment CustomizableText_customizable on CustomizableInterface {
      id
      __typename
      customTextFields {
        customField {
          id
        }
        value
      }
    }
  `,
);
