/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextField from 'components/material/TextField';

const NameText = styled(TextField)`
  width: 100%;
  margin: 0;
  > input {
    padding-top: 0;
  }
`;

type Props = {
  name: string,
  onSave: (name: string) => void,
  className?: string,
  placeholder?: string,
};

export default class OrgSettingsPropsTextField extends React.Component<Props> {
  name: HTMLInputElement;

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.handleSave();
    }
  };

  handleSave = () => {
    this.props.onSave(this.name.value);
  };

  handleFocus = (e: SyntheticEvent<HTMLInputElement>) => {
    const lastIndex = e.currentTarget.value.length;
    e.currentTarget.setSelectionRange(lastIndex, lastIndex);
  };

  render() {
    return (
      <NameText
        className={this.props.className}
        placeholder={this.props.placeholder}
        defaultValue={this.props.name || ''}
        inputRef={t => {
          this.name = t;
        }}
        autoFocus
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleSave}
      />
    );
  }
}
