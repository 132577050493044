/* @flow */
import React from 'react';

const NavContext = React.createContext<{
  showSidebar: boolean,
  onToggleSidebar: (shownMenu: string) => void,
}>({
  showSidebar: false,
  onToggleSidebar: () => {},
});

export default NavContext;
