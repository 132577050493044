/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory, withRouter } from 'react-router-dom';

import type { FieldType } from 'utils/customization/types';
import { stringParamToSearch } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import stringifyQueryParamValues, {
  searchToStringParam,
} from 'utils/routing/stringifyQueryParamValues';

import getEventFilterConverters from 'components/AllEvents/lib/getEventFilterConverters';
import { parseDateFilterParams } from 'components/AllEvents/lib/parseDateFilters';

import { type AllEventsPopulateFilters_eventList } from './__generated__/AllEventsPopulateFilters_eventList.graphql';
import { type AllEventsPopulateFilters_me } from './__generated__/AllEventsPopulateFilters_me.graphql';

class AllEventsPopulateFilters extends React.PureComponent<{
  eventList: ?AllEventsPopulateFilters_eventList,
  me: AllEventsPopulateFilters_me,
  filtered: boolean,
  history: RouterHistory,
  eventFields: $ReadOnlyArray<FieldType>,
  onChangeShownColumns: (shownColumns: $ReadOnlyArray<string>, save: boolean) => void,
}> {
  componentDidMount() {
    const { eventList, filtered, history, eventFields, onChangeShownColumns } = this.props;

    if (eventList) {
      if (!filtered) {
        const eventListFilters = {
          teamIds: eventList.teams.edges.map(edge => edge.node.id),
          search: eventList.search && stringParamToSearch(eventList.search),
          queries: eventList.queries,
          leadIds: eventList.leads.edges.map(edge => edge.node.id),
          creatorIds: eventList.creators.edges.map(edge => edge.node.id),
          updaterIds: eventList.updaters.edges.map(edge => edge.node.id),
          staffIds: eventList.staffers.edges.map(edge => edge.node.id),
          statuses: eventList.statuses,
          venueNames: eventList.venueNames,
          eventIds: eventList.eventIds,
          cities: eventList.cities,
          states: eventList.states,
          countries: eventList.countries,
          eventFormats: eventList.eventFormats,
          date: this.getDateFilterValue(
            eventList.afterDate,
            eventList.beforeDate,
            eventList.listType,
          ),
          month: null,
          week: null,
          syncStatuses: eventList.syncStatuses,
          requestedDate: eventList.requestedDate
            ? this.getDateFilterValue(eventList.requestedDate[0], eventList.requestedDate[1])
            : null,
          createdAt: eventList.createdAt
            ? this.getDateFilterValue(eventList.createdAt[0], eventList.createdAt[1])
            : null,
          updatedAt: eventList.updatedAt
            ? this.getDateFilterValue(eventList.updatedAt[0], eventList.updatedAt[1])
            : null,
          requestStatuses: eventList.requestStatuses,
          requesterUserIds: eventList.userRequesters.edges.map(edge => edge.node.id),
          requesterContactIds: eventList.contactRequesters.edges.map(edge => edge.node.id),
          requestFormIds: eventList.eventRequestForms.edges.map(edge => edge.node.id),
          requestReviewers: eventList.eventRequestReviewers.edges.map(edge => edge.node.id),
          contactsCount:
            eventList.contactsCount != null
              ? {
                  min: eventList.contactsCount.min,
                  max: eventList.contactsCount.max,
                }
              : null,
          opportunitiesNumber:
            eventList.opportunitiesNumber != null
              ? {
                  min: eventList.opportunitiesNumber.min,
                  max: eventList.opportunitiesNumber.max,
                }
              : null,
          opportunitiesAmount:
            eventList.opportunitiesAmount != null
              ? {
                  min: eventList.opportunitiesAmount.min,
                  max: eventList.opportunitiesAmount.max,
                }
              : null,
          registeredContactsTotal:
            eventList.registeredContactsTotal != null
              ? {
                  min: eventList.registeredContactsTotal.min,
                  max: eventList.registeredContactsTotal.max,
                }
              : null,
          attendedContactsTotal:
            eventList.attendedContactsTotal != null
              ? {
                  min: eventList.attendedContactsTotal.min,
                  max: eventList.attendedContactsTotal.max,
                }
              : null,
          registrationFormStatuses: eventList.registrationFormStatuses,
          ...[
            ...eventList.customSavedNumberFilters.edges,
            ...eventList.customSavedCurrencyFilters.edges,
          ].reduce(
            (constructedFilters, filter) => ({
              ...constructedFilters,
              [filter.node.customField
                ? filter.node.customField.id
                : filter.node.fieldName || '']: {
                min: filter.node.minValue,
                max: filter.node.maxValue,
              },
            }),
            {},
          ),
          ...eventList.customSavedDateFilters.edges.reduce(
            (constructedFilters, filter) => ({
              ...constructedFilters,
              [filter.node.customField.id]: this.getDateFilterValue(
                filter.node.minValue,
                filter.node.maxValue,
              ),
            }),
            {},
          ),
          ...eventList.customSavedBooleanFilters.edges.reduce(
            (constructedFilters, filter) => ({
              ...constructedFilters,
              [filter.node.customField.id]: filter.node.value,
            }),
            {},
          ),
          ...eventList.customSavedUserMultiselectFilters.edges.reduce(
            (constructedFilters, filter) => {
              return {
                ...constructedFilters,
                [filter.node.customField
                  ? filter.node.customField.id
                  : filter.node.fieldName || '']: filter.node.options.edges.map(
                  edge => edge.node.user.id,
                ),
              };
            },
            {},
          ),
          ...eventList.customSavedMultiselectFilters.edges.reduce((constructedFilters, filter) => {
            return {
              ...constructedFilters,
              [filter.node.customField.id]: filter.node.options.edges.map(
                edge => edge.node.option.id,
              ),
            };
          }, {}),
        };

        replaceQueryParams(
          history,
          stringifyQueryParamValues(eventListFilters, {
            search: searchToStringParam,
            ...getEventFilterConverters(eventFields),
          }),
        );

        if (eventList.sort || eventList.customSort) {
          replaceSortQueryParam(history, {
            key: eventList.customSort ? eventList.customSort.id : eventList.sort || '',
            asc: eventList.direction === 'ASC',
          });
        }
      }

      onChangeShownColumns(
        eventList.savedListColumns.edges.map(
          edge => (edge.node.customField && edge.node.customField.id) || edge.node.columnName || '',
        ),
        false,
      );
    }
  }

  getDateFilterValue = (afterDate: ?string, beforeDate: ?string, listType: ?string) => {
    const parsedDateFilter = parseDateFilterParams({
      beforeDate,
      afterDate,
      listType,
      tz: this.props.me.tz,
    });
    return parsedDateFilter ? parsedDateFilter.key : null;
  };

  render() {
    return null;
  }
}

export default createFragmentContainer(withRouter(AllEventsPopulateFilters), {
  eventList: graphql`
    fragment AllEventsPopulateFilters_eventList on EventList {
      id
      search
      queries
      statuses
      beforeDate
      afterDate
      listType
      venueNames
      eventIds
      cities
      states
      countries
      eventFormats
      syncStatuses
      contactsCount {
        min
        max
      }
      opportunitiesNumber {
        min
        max
      }
      opportunitiesAmount {
        min
        max
      }
      registeredContactsTotal {
        min
        max
      }
      attendedContactsTotal {
        min
        max
      }
      registrationFormStatuses
      sort
      direction
      savedListColumns {
        edges {
          node {
            columnName
            customField {
              id
            }
          }
        }
      }
      customSort {
        id
      }
      teams {
        edges {
          node {
            id
          }
        }
      }
      leads {
        edges {
          node {
            id
          }
        }
      }
      creators {
        edges {
          node {
            id
          }
        }
      }
      updaters {
        edges {
          node {
            id
          }
        }
      }
      staffers {
        edges {
          node {
            id
          }
        }
      }
      requestStatuses
      requestedDate
      updatedAt
      createdAt
      eventRequestForms {
        edges {
          node {
            id
          }
        }
      }
      eventRequestReviewers {
        edges {
          node {
            id
          }
        }
      }
      userRequesters {
        edges {
          node {
            id
          }
        }
      }
      contactRequesters {
        edges {
          node {
            id
          }
        }
      }
      actionsRequestForms {
        edges {
          node {
            id
          }
        }
      }
      customSavedNumberFilters {
        edges {
          node {
            id
            minValue
            maxValue
            customField {
              id
            }
          }
        }
      }
      customSavedCurrencyFilters {
        edges {
          node {
            id
            minValue
            maxValue
            fieldName
            customField {
              id
            }
          }
        }
      }
      customSavedDateFilters {
        edges {
          node {
            id
            minValue
            maxValue
            customField {
              id
            }
          }
        }
      }
      customSavedBooleanFilters {
        edges {
          node {
            id
            value
            customField {
              id
            }
          }
        }
      }
      customSavedUserMultiselectFilters {
        edges {
          node {
            id
            fieldName
            customField {
              id
            }
            options {
              edges {
                node {
                  user {
                    id
                  }
                }
              }
            }
          }
        }
      }
      customSavedMultiselectFilters {
        edges {
          node {
            id
            options {
              edges {
                node {
                  option {
                    id
                  }
                }
              }
            }
            customField {
              id
            }
          }
        }
      }
    }
  `,
  me: graphql`
    fragment AllEventsPopulateFilters_me on User {
      id
      tz
    }
  `,
});
