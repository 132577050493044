/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import formatPercentage from 'utils/number/formatPercentage';

import InlineEditableCell from 'components/budget/Table/InlineEditableCell';
import InlineSelectField from 'components/budget/Table/InlineSelectField';

import { type VendorExpenseCellPropsType } from './index';

import { type VendorExpensePaymentStatus_expense } from './__generated__/VendorExpensePaymentStatus_expense.graphql';

export const status = (ratio: number) => {
  if (!Number.isFinite(ratio) || ratio <= 0) return 'Unpaid';
  if (ratio >= 1) return 'Paid';

  return `Partial (${formatPercentage(ratio)})`;
};

const VendorExpensePaymentStatusCell = ({
  expense,
  updateExpense,
}: VendorExpenseCellPropsType<VendorExpensePaymentStatus_expense>) => {
  const expenseStatus = status(expense.paidAmount / expense.actualAmount);

  if (expense.actualAmount === 0) return expenseStatus;

  if (!expense.viewerCanUpdatePaymentStatus) {
    return (
      <InlineEditableCell>
        {({ editing }) => {
          if (editing) {
            return (
              <Redirect
                push
                to={{
                  pathname: `/events/${expense.event.slug}/budget/${expense.id}`,
                  hash: 'payments',
                }}
              />
            );
          }

          return expenseStatus;
        }}
      </InlineEditableCell>
    );
  }

  return (
    <InlineSelectField
      value={status === 'Paid' ? 'PAID' : 'UNPAID'}
      options={[
        { label: 'Unpaid', value: 'UNPAID' },
        { label: 'Paid', value: 'PAID' },
      ]}
      onChange={paymentStatus => {
        if (!paymentStatus) return;

        updateExpense({ paymentStatus, actualAmount: expense.actualAmount });
      }}
    >
      {expenseStatus}
    </InlineSelectField>
  );
};

export default createFragmentContainer(
  VendorExpensePaymentStatusCell,
  graphql`
    fragment VendorExpensePaymentStatus_expense on Expense {
      id
      actualAmount
      paidAmount
      viewerCanUpdatePaymentStatus
      event {
        slug
      }
    }
  `,
);
