/* @flow */

import { type QuestionType } from './types';

const isCompanyField = (question: $Shape<QuestionType>): boolean => {
  if (
    question.fieldName === 'company_name' ||
    (question.customField && question.customField.customizableType === 'COMPANY')
  ) {
    return true;
  }
  return false;
};

export default isCompanyField;
