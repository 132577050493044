/**
 * @flow
 * @relayHash e6b4cf097b27b3925cc918b457c401ef
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveFolderInput = {
  clientMutationId?: ?string,
  folderId: string,
};
export type removeFolderMutationVariables = {|
  input: RemoveFolderInput
|};
export type removeFolderMutationResponse = {|
  +removeFolder: ?{|
    +removedFolderId: string,
    +removedDeliverableIds: $ReadOnlyArray<?string>,
    +event: {|
      +progress: ?number
    |},
  |}
|};
export type removeFolderMutation = {|
  variables: removeFolderMutationVariables,
  response: removeFolderMutationResponse,
|};
*/


/*
mutation removeFolderMutation(
  $input: RemoveFolderInput!
) {
  removeFolder(input: $input) {
    removedFolderId
    removedDeliverableIds
    event {
      progress
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveFolderInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveFolderInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedFolderId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedDeliverableIds",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "progress",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeFolderMutation",
  "id": null,
  "text": "mutation removeFolderMutation(\n  $input: RemoveFolderInput!\n) {\n  removeFolder(input: $input) {\n    removedFolderId\n    removedDeliverableIds\n    event {\n      progress\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeFolderMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeFolder",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveFolderPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeFolderMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeFolder",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveFolderPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v4,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a56d2d7ed801619b822943cad74331a';
module.exports = node;
