/* @flow */
import React from 'react';
import styled from 'styled-components';

import ShareDropdown from 'components/ShareDropdown';

const StyledShareDropdown = styled(ShareDropdown)`
  > i {
    color: #cdd1d4;
    &:hover {
      color: #96a2ab;
    }
  }
`;

export default class OrgSettingsPropsActions extends React.PureComponent<{
  onRemove: () => void,
  className?: string,
}> {
  handleRemove = () => {
    this.props.onRemove();
  };

  render() {
    return (
      <StyledShareDropdown
        className={this.props.className}
        options={[{ label: 'Delete', icon: 'trash', onClick: this.handleRemove }]}
        noBorder
      />
    );
  }
}
