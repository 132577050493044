/* @flow */
import allFonts from 'config/fonts';

export const loadFonts = (
  fonts: $ReadOnlyArray<string>,
  options: { loadBold: boolean } = {
    loadBold: false,
  },
) => {
  const fontsToLoad = (options.loadBold
    ? fonts.reduce((arr, font) => [...arr, font, font ? `${font}:bold` : null], [])
    : fonts
  ).filter(x => x);

  if (fontsToLoad.length > 0) {
    global.WebFontConfig = {
      google: { families: fontsToLoad },
    };
    (function addFontScript() {
      const wf = document.createElement('script');
      wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
      wf.type = 'text/javascript';
      wf.async = true;
      const s = document.getElementsByTagName('script')[0];
      if (s && s.parentNode) {
        s.parentNode.insertBefore(wf, s);
      }
    })();
  }
};

export const getFontFamily = (value: ?string) => {
  const font = allFonts.find(f => f.value === value);
  if (font) {
    return `'${font.label}', sans-serif`;
  }
  return '';
};
