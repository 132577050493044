/* @flow */
import type { ColorType } from '.';

const getPresetColors = ({
  settings,
  includeTransparent,
  customPreset,
}: {
  settings: { +primaryColor: string, +secondaryColor: string },
  includeTransparent?: boolean,
  customPreset?: Array<ColorType>,
}): Array<ColorType> => {
  const preset = [
    { color: settings.primaryColor, title: 'Org Primary Color' },
    { color: settings.secondaryColor, title: 'Org Secondary Color' },
    { color: '#ffffff', title: 'White' },
    { color: '#000000', title: 'Black' },
    ...(includeTransparent ? [{ color: 'transparent', title: 'Transparent' }] : []),
  ].filter(Boolean);
  if (customPreset != null && customPreset.length > 0) {
    return [...preset, ...customPreset];
  }
  return preset;
};

export default getPresetColors;
