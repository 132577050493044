/* @flow */
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import removeCompanies from 'graph/mutations/company/removeCompanies';
import removeCompanyAssignment from 'graph/mutations/event/removeCompanyAssignment';
import addCompanyToBrief from 'graph/mutations/event_brief/addCompanyToBrief';
import removeCompanyFromBrief from 'graph/mutations/event_brief/removeCompanyFromBrief';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Breadcrumb from 'components/Breadcrumb';
import ConfirmationWindow from 'components/ConfirmationWindow';
import Tag from 'components/Contacts/Tag';
import Tooltip from 'components/material/Tooltip';
import CompanyIcon from 'components/Participants/CompanyIcon';
import ShareDropdown from 'components/ShareDropdown';

import CompanyPageMenu from './CompanyPageMenu';
import CompanyPageRoutes from './CompanyPageRoutes';

import type { CompanyPage_company } from './__generated__/CompanyPage_company.graphql';
import type { CompanyPage_event } from './__generated__/CompanyPage_event.graphql';
import type { CompanyPage_org } from './__generated__/CompanyPage_org.graphql';
import type { CompanyPage_user } from './__generated__/CompanyPage_user.graphql';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 33px 0 33px;
`;

const CompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 33px 15px 33px;
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4px;
`;

const CompanyName = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: #4a5665;
`;

const CompanyTagIcon = styled.div`
  display: block;
`;

const StyledTag = styled(Tag)`
  margin-right: 14px !important;
  font-weight: 500;
`;

const Container = styled.div`
  padding-top: 20px;
`;

const StyledCompanyIcon = styled(CompanyIcon)`
  margin-right: 30px;
  border: 0;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom,
    #5ec267,
    #3dcb84 47%,
    #4dbab0 100%,
    #017a84,
    #146173
  );
  > svg {
    color: #fff;
  }
`;

const SyncIcon = styled.i`
  font-size: 15px;
  color: ${props => props.theme.primaryActionColor};
`;

const CompanyTags = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

class CompanyPage extends React.PureComponent<
  {
    org: CompanyPage_org,
    company: CompanyPage_company,
    user: CompanyPage_user,
    event?: CompanyPage_event,
    location: Location,
    history: History,
    match: Match,
  },
  {
    confirmingDelete: boolean,
  },
> {
  state = {
    confirmingDelete: false,
  };

  getReturnUrl = () => {
    const { state } = this.props.location;
    if (state && state.prevPage === true && state.prevPath) {
      return `${state.prevPath}${state.search || ''}`;
    }

    const params = this.props.match.params;
    switch (params[0]) {
      case 'events':
        return state && state.from === 'event_contacts_opportunities'
          ? state.fromUrl
          : `/events/${params.event_slug}/contacts/companies`;
      case 'tasks':
        return `/events/${params.event_slug}/tasks/${params.task_slug}`;
      default:
        return '/contacts/companies';
    }
  };

  getBreadCrumbLabel = () => {
    const { state } = this.props.location;

    if (state && state.prevPageLabel) {
      return state.prevPageLabel;
    }

    switch (this.props.match.params[0]) {
      case 'events':
        return 'Event Companies';
      case 'tasks':
        return 'Task Companies';
      default:
        return 'All Companies';
    }
  };

  handleGoBack = () => {
    this.props.history.replace(this.getReturnUrl());
  };

  handleBreadCrumbClick = () => {
    this.props.history.push(this.getReturnUrl());
  };

  handleRemove = () => {
    const company = this.props.company;
    removeCompanies({
      companyIds: [company.id],
      orgId: company.org.id,
      ...(this.props.event ? { eventId: this.props.event.id } : null),
      fromWindow: 'company profile',
    }).catch(showModernMutationError);
    this.handleGoBack();
  };

  handleRemoveCompanyAssignment = () => {
    if (this.props.event != null) {
      removeCompanyAssignment({
        eventId: this.props.event.id,
        fromWindow: 'company profile',
        companyId: this.props.company.id,
      }).catch(showModernMutationError);
    }
    this.handleGoBack();
  };

  handleConfirmationShow = () => {
    this.setState({ confirmingDelete: true });
  };

  handleConfirmationHide = () => {
    this.setState({ confirmingDelete: false });
  };

  handleAddCompanyToBrief = () => {
    if (this.props.event) {
      addCompanyToBrief(this.props.company.id, this.props.event.id).catch(showModernMutationError);
    }
  };

  handleRemoveCompanyFromBrief = () => {
    if (this.props.event) {
      removeCompanyFromBrief(this.props.company.id, this.props.event.id).catch(
        showModernMutationError,
      );
    }
  };

  getBriefActions = () => {
    const { company, event } = this.props;
    if (event != null && event.viewerCanUpdate) {
      const eventBriefCompanyIds = event.briefCompanies.edges.map(edge => edge.node.id);
      if (eventBriefCompanyIds.includes(company.id)) {
        return {
          label: 'Remove from Brief',
          icon: 'minus',
          onClick: this.handleRemoveCompanyFromBrief,
        };
      }
      return {
        label: 'Add to Brief',
        icon: 'plus',
        onClick: this.handleAddCompanyToBrief,
      };
    }
    return null;
  };

  render() {
    const { org, company, event, user, match, location } = this.props;

    if (!company) return null;

    const opportunitiesSyncEnabled =
      org.salesforceAccount != null && org.salesforceAccount.opportunitiesSyncEnabled;
    const actionOptions = [
      event != null
        ? { label: 'Remove from event', icon: 'minus', onClick: this.handleRemoveCompanyAssignment }
        : null,
      this.getBriefActions(),
      {
        label: 'Delete',
        icon: 'trash',
        onClick: this.handleConfirmationShow,
        disabled: !company.viewerCanRemove,
      },
    ].filter(Boolean);
    return (
      <DocumentTitle title={company.name}>
        <div>
          <HeaderContainer>
            <Breadcrumb
              path={[
                {
                  label: this.getBreadCrumbLabel(),
                  onClick: this.handleBreadCrumbClick,
                },
                { label: company.name },
              ]}
            />
            <ShareDropdown noBorder options={actionOptions} />
            {this.state.confirmingDelete && (
              <ConfirmationWindow
                onHide={this.handleConfirmationHide}
                onConfirm={this.handleRemove}
                message="Once you delete a Company, it's gone from all of your Events and Workspace."
              />
            )}
          </HeaderContainer>
          <Container>
            <CompanyInfoContainer>
              <StyledCompanyIcon size={85} iconSize={35} />
              <CompanyInfo>
                <CompanyName>{company.name}</CompanyName>
                <CompanyTags>
                  <CompanyTagIcon>
                    <StyledTag color="#4FC186" active readOnly>
                      Company
                    </StyledTag>
                  </CompanyTagIcon>
                  <Tooltip label="Salesforce account" placement="right">
                    {company.salesforceId && <SyncIcon className="fa fa-fw fa-refresh" />}
                  </Tooltip>
                </CompanyTags>
              </CompanyInfo>
            </CompanyInfoContainer>
            <CompanyPageMenu
              pathPrefix={match.url}
              contactsCount={company.contacts.totalCount}
              opportunitiesCount={company.opportunities.totalCount}
              opportunitiesSyncEnabled={opportunitiesSyncEnabled}
              location={location}
            />
            <CompanyPageRoutes
              company={company}
              org={org}
              event={event}
              contactsCount={company.contacts.totalCount}
              user={user}
              opportunitiesSyncEnabled={opportunitiesSyncEnabled}
              pathPrefix={match.path}
              rootUrl={match.url}
              forceUpdate={`${location.pathname}${location.search}`}
            />
          </Container>
        </div>
      </DocumentTitle>
    );
  }
}

export default createFragmentContainer(CompanyPage, {
  org: graphql`
    fragment CompanyPage_org on Org {
      salesforceAccount {
        opportunitiesSyncEnabled
      }
      ...CompanyPageRoutes_org
    }
  `,
  company: graphql`
    fragment CompanyPage_company on Company {
      id
      name
      salesforceId
      org {
        id
      }
      viewerCanRemove
      viewerCanUpdate
      contacts {
        totalCount
      }
      opportunities {
        totalCount
      }
      ...CompanyPageRoutes_company
    }
  `,
  user: graphql`
    fragment CompanyPage_user on User {
      ...CompanyPageRoutes_user
    }
  `,
  event: graphql`
    fragment CompanyPage_event on Event {
      id
      viewerCanUpdate
      briefCompanies {
        edges {
          node {
            id
          }
        }
      }
      ...CompanyPageRoutes_event
    }
  `,
});
