/* @flow */
export default function formatLocation(
  location: ?{
    +name: string,
    +city: string,
    +state: ?string,
    +country: ?string,
  },
): string {
  if (!location) return '';

  return [
    location.name,
    location.city === location.name ? null : location.city,
    location.country === 'United States' ? location.state : location.country,
  ]
    .filter(Boolean)
    .join(', ');
}
