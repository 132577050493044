/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import downloadedSchedule from 'utils/analytics/events/downloadedSchedule';

import Loader from 'components/Loader';
import Button from 'components/material/Button';
import Window from 'components/material/Window';

import GoogleDocsSchedule from './GoogleDocsSchedule';

const StyledWindow = styled(Window)`
  max-width: 500px;
  background-color: ${props => props.theme.windowBackgroundColor};
`;

const Header = styled.div`
  display: flex;
  height: 60px;
  padding: 0 0 0 20px;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  background-color: ${props => props.theme.windowHeaderBackgroundColor};
  font-size: 18px;
  white-space: nowrap;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 0 0 10px;
    height: 40px;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  font-weight: 500;
  flex: 1 1 auto;
  width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CloseAction = styled.div`
  width: 59px;
  height: 59px;
  cursor: pointer;
  border-left: 1px solid ${props => props.theme.borderColor};
  text-align: center;
  color: ${props => props.theme.secondaryActionDarkerColor};
  line-height: 59px;
  font-size: 16px;
  @media (${props => props.theme.mobileOnly}) {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
`;

const ModalBody = styled.div`
  min-height: 76px;
  padding: 18px 26px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px;
    overflow: visible;
  }
`;

const CenteredDiv = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 0;
`;

export default class GoogleDocExportModal extends React.PureComponent<
  {
    onCancel: () => void,
    schedule: Object,
    event: Object,
    member: Object,
  },
  {
    exporting: boolean,
    alternateLink: ?string,
  },
> {
  state = {
    exporting: true,
    alternateLink: null,
  };

  handleClickPropagation = (e: SyntheticEvent<>) => {
    e.stopPropagation();
  };

  handleExportGDocsFinished = (data: Object) => {
    if (data.success) {
      this.setState({ exporting: false, alternateLink: data.alternateLink });
    } else {
      this.setState({ exporting: false, alternateLink: null });
    }
  };

  handleExportClick = () => {
    const event = this.props.event;
    downloadedSchedule({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'google doc',
    });
    window.open(this.state.alternateLink);
  };

  render() {
    const { onCancel, schedule, event, member } = this.props;

    return (
      <StyledWindow onHide={onCancel} onClick={this.handleClickPropagation}>
        <Header>
          <Title>Preparing export</Title>

          <CloseAction onClick={onCancel}>
            <i className="fa-fw fa fa-times" />
          </CloseAction>
        </Header>
        <ModalBody>
          {this.state.exporting && (
            <LoaderContainer>
              <Loader size={20} />
            </LoaderContainer>
          )}
          {!this.state.exporting &&
            (this.state.alternateLink ? (
              <div>
                <CenteredDiv>The schedule is ready to export to Google Docs.</CenteredDiv>
                <CenteredDiv>
                  <Button label="Export" primary onClick={this.handleExportClick} />
                </CenteredDiv>
              </div>
            ) : (
              <div>Something went wrong please try again</div>
            ))}
          <div style={{ display: 'none' }}>
            <GoogleDocsSchedule
              schedule={schedule}
              event={event}
              member={member}
              onFinished={this.handleExportGDocsFinished}
            />
          </div>
        </ModalBody>
      </StyledWindow>
    );
  }
}
