/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';
import { darken } from 'polished';

import discardRegistrationFormChanges from 'graph/mutations/registration/discardRegistrationFormChanges';
import saveRegistrationFormChanges from 'graph/mutations/registration/saveRegistrationFormChanges';
import showModernMutationError from 'graph/utils/showModernMutationError';

import EyeIcon from 'images/registration/eyeIcon.svg';
import Button from 'components/budget/Button';
import ConfirmationWindow from 'components/ConfirmationWindow';
import Frame, { type WindowType } from 'components/Frame';
import Mask from 'components/Mask';

import LiveActions from './LiveActions';
import RegistrationPreview from './RegistrationPreview';
import RegistrationTab from './RegistrationTab';
import UIContext from './UIContext';

import type { RegistrationHeader_event } from './__generated__/RegistrationHeader_event.graphql';
import type { RegistrationHeader_org } from './__generated__/RegistrationHeader_org.graphql';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  height: 85px;
  padding: 25px 35px;
  background-color: ${props => props.backgroundColor};
  transition: padding 0.2s;

  @media (max-width: 900px) {
    justify-content: space-between;
    padding: 25px 10px;
  }
`;

const Tabs = styled.div`
  flex: 0 auto;
  display: flex;
  transition: margin-left 0.2s;

  @media (max-width: 900px) {
    margin-left: 45px;
  }
  @media (max-width: 800px) {
    margin-left: 0;
  }
`;

const Actions = styled.div`
  position: absolute;
  top: 25px;
  right: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 20px;
    font-size: 14px;
    transition: margin-left 0.2s;

    @media (max-width: 1050px) {
      margin-left: 10px;
    }
  }

  @media (max-width: 450px) {
    right: 10px;
  }
`;

const Preview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 30px;
  border-radius: 5px;
  border: solid 1px #d8d8d8;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  &:hover,
  &:focus {
    background: ${props => darken(0.1, props.backgroundColor)};
  }
`;

const FinishButton = styled(Button)`
  width: 115px;
  height: 30px;
  @media (max-width: 450px) {
    margin-left: 10px;
  }
`;

class RegistrationHeader extends React.PureComponent<
  {
    org: RegistrationHeader_org,
    event?: RegistrationHeader_event,
    onDiscard?: () => void,
    location: Location,
  },
  {
    previewWindow: ?WindowType,
    showMask: boolean,
    showDiscardConfirmationWindow: boolean,
  },
> {
  state = {
    previewWindow: null,
    showMask: false,
    showDiscardConfirmationWindow: false,
  };

  componentWillUnmount() {
    if (this.state.previewWindow) {
      this.state.previewWindow.close();
    }
  }

  getTabSettings = (
    createTabCopy: string,
    settingsTabCopy: string,
    logicTabCopy: string,
  ): $ReadOnlyArray<{
    label: string,
    link: string,
  }> => {
    return [
      {
        label: createTabCopy,
        link: 'create',
      },
      {
        label: logicTabCopy,
        link: 'logic',
      },
      {
        label: settingsTabCopy,
        link: 'settings',
      },
    ];
  };

  handleCloseWindow = () => {
    window.close();
  };

  handlePreview = () => {
    if (this.state.previewWindow) {
      this.state.previewWindow.close();
    }

    const previewWindow = window.open();

    previewWindow.document.title = 'Preview';
    previewWindow.onbeforeunload = () => this.setState({ previewWindow: null });

    this.setState({ previewWindow });
  };

  handleSave = () => {
    this.setState({ showMask: true });
    const event = this.props.event;
    // Flow...
    if (event == null || event.editableRegistrationForm == null) return;

    saveRegistrationFormChanges(event.editableRegistrationForm.id)
      .then(() => {
        this.setState({ showMask: false });
      })
      .catch(showModernMutationError);
  };

  handleShowDiscardConfirmation = () => {
    if (this.props.org.syncedToIbmWm) {
      this.handleDiscard();
      return;
    }
    this.setState({ showDiscardConfirmationWindow: true });
  };

  handleHideDiscardConfirmation = () => {
    this.setState({ showDiscardConfirmationWindow: false });
  };

  handleConfirmDiscarding = () => {
    this.handleDiscard();
    this.handleHideDiscardConfirmation();
  };

  handleDiscard = () => {
    this.setState({ showMask: true });
    const { event, onDiscard } = this.props;
    // Flow...
    if (event == null || event.editableRegistrationForm == null) return;

    discardRegistrationFormChanges(event.editableRegistrationForm.id, false)
      .then(() => {
        if (onDiscard) {
          onDiscard();
        }
        this.setState({ showMask: false });
      })
      .catch(showModernMutationError);
  };

  render() {
    const { event, location, org } = this.props;
    const { previewWindow, showMask, showDiscardConfirmationWindow } = this.state;
    return (
      <UIContext.Consumer>
        {({
          headerBackground,
          publishButtonBackground,
          publishButtonColor,
          publishButtonCopy,
          liveButtonColor,
          liveButtonBackground,
          liveButtonCopy,
          saveChangesButtonBackground,
          saveChangesButtonColor,
          saveChangesButtonCopy,
          discardChangesButtonBackground,
          discardChangesButtonColor,
          discardChangesButtonCopy,
          createTabCopy,
          settingsTabCopy,
          logicTabCopy,
          loaderColor,
        }) => (
          <Container backgroundColor={headerBackground}>
            <Tabs>
              {this.getTabSettings(createTabCopy, settingsTabCopy, logicTabCopy).map(tab => (
                <RegistrationTab
                  key={tab.label}
                  label={tab.label}
                  link={`${event ? `/events/${event.slug}` : ''}/registration_builder/${tab.link}${
                    location.search
                  }`}
                  active={RegExp(
                    `${event ? '/events/(.*)' : ''}/registration_builder/${tab.link}/(.*)`,
                  ).test(location.pathname)}
                />
              ))}
            </Tabs>
            <Actions>
              {event && (
                <>
                  {event.editableRegistrationForm && event.editableRegistrationForm.hasChanges && (
                    <>
                      <StyledButton
                        onClick={this.handleSave}
                        backgroundColor={saveChangesButtonBackground}
                        textColor={saveChangesButtonColor}
                      >
                        {saveChangesButtonCopy}
                      </StyledButton>
                      <StyledButton
                        onClick={this.handleShowDiscardConfirmation}
                        backgroundColor={discardChangesButtonBackground}
                        textColor={discardChangesButtonColor}
                      >
                        {discardChangesButtonCopy}
                      </StyledButton>
                      {showDiscardConfirmationWindow && (
                        <ConfirmationWindow
                          actionNegative={false}
                          actionLabel="Confirm"
                          onHide={this.handleHideDiscardConfirmation}
                          onConfirm={this.handleConfirmDiscarding}
                          title="Are you sure?"
                          message="Once you proceed with this, all the changes, you or your teammates made to the registration form, are gone for good."
                        />
                      )}
                    </>
                  )}
                  <LiveActions
                    event={event}
                    liveCopy={liveButtonCopy}
                    liveColor={liveButtonColor}
                    liveBackground={liveButtonBackground}
                    draftCopy={publishButtonCopy}
                    draftColor={publishButtonColor}
                    draftBackground={publishButtonBackground}
                    syncedToIbmWm={org.syncedToIbmWm}
                    loaderColor={loaderColor}
                  />
                  {showMask && <Mask loaderColor={loaderColor} />}
                </>
              )}
              {/* Org template editor. Non IBM org */}
              {!event && !org.syncedToIbmWm && (
                <>
                  <Preview onClick={this.handlePreview}>
                    <EyeIcon />
                  </Preview>
                  {previewWindow && (
                    <Frame window={previewWindow}>
                      <RegistrationPreview registrationForm={org.registrationForm} org={org} />
                    </Frame>
                  )}
                  <FinishButton onClick={this.handleCloseWindow}>Finish Editing</FinishButton>
                </>
              )}
            </Actions>
          </Container>
        )}
      </UIContext.Consumer>
    );
  }
}

export default createFragmentContainer(withRouter(RegistrationHeader), {
  event: graphql`
    fragment RegistrationHeader_event on Event {
      slug
      registrationForm {
        id
        publishedAt
        ...RegistrationPreview_registrationForm
      }
      editableRegistrationForm {
        id
        hasChanges
      }
      ...LiveActions_event
    }
  `,
  org: graphql`
    fragment RegistrationHeader_org on Org {
      syncedToIbmWm
      registrationForm @skip(if: $isEventRegForm) {
        id
        ...RegistrationPreview_registrationForm
      }
      ...RegistrationPreview_org @skip(if: $isEventRegForm)
    }
  `,
});
