/**
 * @flow
 * @relayHash c81134f1062d774af7d5a40d6bb21afe
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddExpenseAttachmentInput = {
  clientMutationId?: ?string,
  expenseId: string,
  filename: string,
  fileurl: string,
  filetype: string,
  filesize?: ?number,
};
export type addExpenseAttachmentMutationVariables = {|
  input: AddExpenseAttachmentInput
|};
export type addExpenseAttachmentMutationResponse = {|
  +addExpenseAttachment: ?{|
    +attachmentEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +fileurl: ?string,
        +filename: ?string,
        +filetype: ?string,
        +filesize: ?number,
        +viewerCanDelete: boolean,
      |},
    |}
  |}
|};
export type addExpenseAttachmentMutation = {|
  variables: addExpenseAttachmentMutationVariables,
  response: addExpenseAttachmentMutationResponse,
|};
*/


/*
mutation addExpenseAttachmentMutation(
  $input: AddExpenseAttachmentInput!
) {
  addExpenseAttachment(input: $input) {
    attachmentEdge {
      __typename
      node {
        id
        fileurl
        filename
        filetype
        filesize
        viewerCanDelete
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddExpenseAttachmentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addExpenseAttachment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddExpenseAttachmentInput!"
      }
    ],
    "concreteType": "AddExpenseAttachmentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "attachmentEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "AttachmentRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Attachment",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fileurl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filename",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filetype",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filesize",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "viewerCanDelete",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addExpenseAttachmentMutation",
  "id": null,
  "text": "mutation addExpenseAttachmentMutation(\n  $input: AddExpenseAttachmentInput!\n) {\n  addExpenseAttachment(input: $input) {\n    attachmentEdge {\n      __typename\n      node {\n        id\n        fileurl\n        filename\n        filetype\n        filesize\n        viewerCanDelete\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addExpenseAttachmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "addExpenseAttachmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0b3f680ce810a12be902e400684a4a8';
module.exports = node;
