/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import DependencyRow from 'components/Tasks/Dependencies/DependencyRow'; // eslint-disable-line

const mutation = graphql`
  mutation addTaskDependencyMutation($input: AddDeliverableDependencyInput!) {
    addDeliverableDependency(input: $input) {
      dependencyEdge {
        __typename
        node {
          id
          ...DependencyRow_dependency
        }
      }
      dependentEdge {
        __typename
        node {
          id
          ...DependencyRow_dependency
        }
      }
    }
  }
`;

export default function addTaskDependency(dependentId: string, dependencyId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        dependentId,
        dependencyId,
      },
    },
    updater: mergeUpdaters(
      addRecordToConnection({
        parentId: dependentId,
        edgeName: 'dependencyEdge',
        connections: [{ field: 'dependencies' }],
      }),
      addRecordToConnection({
        parentId: dependencyId,
        edgeName: 'dependentEdge',
        connections: [{ field: 'dependents' }],
      }),
    ),
  });
}
