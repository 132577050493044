/* @flow */
import React from 'react';
import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import moment from 'moment-timezone';

import convertDateToLocal from 'utils/date/convertDateTimeToLocal';

import CalendarCaption from './CalendarCaption';
import CalendarNavbar from './CalendarNavbar';
import calendarStyles from './calendarStyles';

const Root = styled.div`
  margin: 15px 20px;
  ${calendarStyles};
`;

const modifiers = {
  weekend: { daysOfWeek: [0, 6] },
};

export default class Calendar extends React.PureComponent<
  { tz: string, date: ?string, onChange: (date: string) => void },
  { displayMonth: Date, prevDate: ?string },
> {
  static getDerivedStateFromProps(
    props: $PropertyType<Calendar, 'props'>,
    state: $PropertyType<Calendar, 'state'>,
  ) {
    if (props.date === state.prevDate) return null;

    return {
      displayMonth: new Date(convertDateToLocal(moment(props.date || undefined).tz(props.tz))),
      prevDate: props.date,
    };
  }

  state = {
    displayMonth: new Date(
      convertDateToLocal(moment(this.props.date || undefined).tz(this.props.tz)),
    ),
    // eslint-disable-next-line react/no-unused-state
    prevDate: this.props.date,
  };

  handleClickDay = (day: Date) => {
    const dateInTimezone = moment(day).tz(this.props.tz);
    const localeDate = moment(day);
    localeDate.utcOffset(dateInTimezone.utcOffset(), true);
    this.props.onChange(localeDate.format());
  };

  handleChangeDisplayMonth = (displayMonth: Date) => {
    this.setState({ displayMonth });
  };

  render() {
    return (
      <Root>
        <DayPicker
          fixedWeeks
          showOutsideDays={false}
          modifiers={modifiers}
          month={this.state.displayMonth}
          selectedDays={
            this.props.date
              ? new Date(convertDateToLocal(moment(this.props.date).tz(this.props.tz)))
              : null
          }
          onDayClick={this.handleClickDay}
          onMonthChange={this.handleChangeDisplayMonth}
          captionElement={props => (
            <CalendarCaption
              onChangeDisplayMonth={this.handleChangeDisplayMonth}
              displayMonth={this.state.displayMonth}
              tz={this.props.tz}
              {...props}
            />
          )}
          navbarElement={CalendarNavbar}
        />
      </Root>
    );
  }
}
