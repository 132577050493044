import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserStore from 'stores/UserStore';

import { isLoggedIn } from 'utils/Auth';
import getHomepageUrl from 'utils/getHomepageUrl';

export default class PublicOnlyRoute extends React.Component {
  static propTypes = {
    component: PropTypes.func,
    render: PropTypes.func,
  };

  render() {
    const { component: Component, render, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          if (isLoggedIn()) {
            const user = UserStore.getState().user;
            return (
              <Redirect
                to={getHomepageUrl(user.homepage_list_id || user.homepage, user.relay_id)}
              />
            );
          }
          if (Component) {
            return <Component {...props} />;
          }

          return render(props);
        }}
      />
    );
  }
}
