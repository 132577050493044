/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import getLinkError from 'utils/getLinkError';

import type { CompanyFieldInput } from 'graph/mutations/company/updateCompany';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import CompanyWebsite_company from './__generated__/CompanyWebsite_company.graphql';

class CompanyWebsite extends React.PureComponent<{
  company: CompanyWebsite_company,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<CompanyFieldInput>) => void,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?string) => {
    const linkValue = value ? enforceHttpPrefix(value.trim()) : null;
    if (!this.getError(linkValue) && linkValue !== this.props.company.website) {
      this.props.onUpdate({ website: linkValue });
      this.props.updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    return getLinkError(value, this.props.fieldSettings.required);
  };

  render() {
    const { company, updateColumnWidth } = this.props;

    if (!company) {
      return null;
    }

    return (
      <InlineTextField
        placeholder="Ex: https://www.example.com"
        value={company.website || ''}
        getError={this.getError}
        onChange={this.handleUpdate}
        updateColumnWidth={updateColumnWidth}
      >
        {company.website && (
          <InlineExpandableText>
            <a
              onMouseDown={this.handleMouseDown}
              href={company.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {company.website}
            </a>
          </InlineExpandableText>
        )}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  CompanyWebsite,
  graphql`
    fragment CompanyWebsite_company on Company {
      website
    }
  `,
);
