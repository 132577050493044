/* @flow */
import moment from 'moment-timezone';

import { type TimeZone } from 'utils/date/stringifyTimeZone';

const timezones: $ReadOnlyArray<TimeZone> = [
  { label: 'Niue', name: 'Pacific/Niue' },
  { label: 'Pago Pago', name: 'Pacific/Pago_Pago' },
  { label: 'Hawaii Time', name: 'Pacific/Honolulu' },
  { label: 'Rarotonga', name: 'Pacific/Rarotonga' },
  { label: 'Tahiti', name: 'Pacific/Tahiti' },
  { label: 'Marquesas', name: 'Pacific/Marquesas' },
  { label: 'Gambier', name: 'Pacific/Gambier' },
  { label: 'Alaska Time', name: 'America/Anchorage' },
  { label: 'Pitcairn', name: 'Pacific/Pitcairn' },
  { label: 'Dawson', name: 'America/Dawson' },
  { label: 'Mountain Time - Arizona', name: 'America/Phoenix' },
  { label: 'Mountain Time - Dawson Creek', name: 'America/Dawson_Creek' },
  { label: 'Mountain Time - Hermosillo', name: 'America/Hermosillo' },
  { label: 'Pacific Standard Time', name: 'US/Pacific' },
  { label: 'Pacific Time', name: 'America/Los_Angeles' },
  { label: 'Pacific Time - Tijuana', name: 'America/Tijuana' },
  { label: 'Pacific Time - Vancouver', name: 'America/Vancouver' },
  { label: 'Pacific Time - Whitehorse', name: 'America/Whitehorse' },
  { label: 'Belize', name: 'America/Belize' },
  { label: 'Boise', name: 'America/Boise' },
  { label: 'Central Time - Regina', name: 'America/Regina' },
  { label: 'Central Time - Tegucigalpa', name: 'America/Tegucigalpa' },
  { label: 'Costa Rica', name: 'America/Costa_Rica' },
  { label: 'Easter Island', name: 'Pacific/Easter' },
  { label: 'El Salvador', name: 'America/El_Salvador' },
  { label: 'Galapagos', name: 'Pacific/Galapagos' },
  { label: 'Guatemala', name: 'America/Guatemala' },
  { label: 'Managua', name: 'America/Managua' },
  { label: 'Mountain Time', name: 'America/Denver' },
  { label: 'Mountain Time - Chihuahua, Mazatlan', name: 'America/Mazatlan' },
  { label: 'Mountain Time - Edmonton', name: 'America/Edmonton' },
  { label: 'Mountain Time - Yellowknife', name: 'America/Yellowknife' },
  { label: 'America Cancun', name: 'America/Cancun' },
  { label: 'Bogota', name: 'America/Bogota' },
  { label: 'Central Time', name: 'America/Chicago' },
  { label: 'Central Time - Mexico City', name: 'America/Mexico_City' },
  { label: 'Central Time - Winnipeg', name: 'America/Winnipeg' },
  { label: 'Guayaquil', name: 'America/Guayaquil' },
  { label: 'Jamaica', name: 'America/Jamaica' },
  { label: 'Lima', name: 'America/Lima' },
  { label: 'Panama', name: 'America/Panama' },
  { label: 'Rio Branco', name: 'America/Rio_Branco' },
  { label: 'Asuncion', name: 'America/Asuncion' },
  { label: 'Barbados', name: 'America/Barbados' },
  { label: 'Boa Vista', name: 'America/Boa_Vista' },
  { label: 'Campo Grande', name: 'America/Campo_Grande' },
  { label: 'Caracas', name: 'America/Caracas' },
  { label: 'Cuiaba', name: 'America/Cuiaba' },
  { label: 'Curacao', name: 'America/Curacao' },
  { label: 'Detroit', name: 'America/Detroit' },
  { label: 'Eastern Time', name: 'America/New_York' },
  { label: 'Eastern Time - Iqaluit', name: 'America/Iqaluit' },
  { label: 'Eastern Time - Toronto', name: 'America/Toronto' },
  { label: 'Grand Turk', name: 'America/Grand_Turk' },
  { label: 'Guyana', name: 'America/Guyana' },
  { label: 'Havana', name: 'America/Havana' },
  { label: 'La Paz', name: 'America/La_Paz' },
  { label: 'Manaus', name: 'America/Manaus' },
  { label: 'Martinique', name: 'America/Martinique' },
  { label: 'Nassau', name: 'America/Nassau' },
  { label: 'Port of Spain', name: 'America/Port_of_Spain' },
  { label: 'Port-au-Prince', name: 'America/Port-au-Prince' },
  { label: 'Porto Velho', name: 'America/Porto_Velho' },
  { label: 'Puerto Rico', name: 'America/Puerto_Rico' },
  { label: 'Santiago', name: 'America/Santiago' },
  { label: 'Santo Domingo', name: 'America/Santo_Domingo' },
  { label: 'Araguaina', name: 'America/Araguaina' },
  { label: 'Atlantic Time - Halifax', name: 'America/Halifax' },
  { label: 'Belem', name: 'America/Belem' },
  { label: 'Bermuda', name: 'Atlantic/Bermuda' },
  { label: 'Buenos Aires', name: 'America/Argentina/Buenos_Aires' },
  { label: 'Cayenne', name: 'America/Cayenne' },
  { label: 'Cordoba', name: 'America/Argentina/Cordoba' },
  { label: 'Fortaleza', name: 'America/Fortaleza' },
  { label: 'Maceio', name: 'America/Maceio' },
  { label: 'Montevideo', name: 'America/Montevideo' },
  { label: 'Palmer', name: 'Antarctica/Palmer' },
  { label: 'Paramaribo', name: 'America/Paramaribo' },
  { label: 'Punta Arenas', name: 'America/Punta_Arenas' },
  { label: 'Recife', name: 'America/Recife' },
  { label: 'Rothera', name: 'Antarctica/Rothera' },
  { label: 'Salvador', name: 'America/Bahia' },
  { label: 'Sao Paulo', name: 'America/Sao_Paulo' },
  { label: 'Stanley', name: 'Atlantic/Stanley' },
  { label: 'Thule', name: 'America/Thule' },
  { label: 'Newfoundland Time - St. Johns', name: 'America/St_Johns' },
  { label: 'Godthab', name: 'America/Godthab' },
  { label: 'Miquelon', name: 'America/Miquelon' },
  { label: 'Noronha', name: 'America/Noronha' },
  { label: 'South Georgia', name: 'Atlantic/South_Georgia' },
  { label: 'Cape Verde', name: 'Atlantic/Cape_Verde' },
  { label: 'Abidjan', name: 'Africa/Abidjan' },
  { label: 'Accra', name: 'Africa/Accra' },
  { label: 'Azores', name: 'Atlantic/Azores' },
  { label: 'Bissau', name: 'Africa/Bissau' },
  { label: 'Casablanca', name: 'Africa/Casablanca' },
  { label: 'Danmarkshavn', name: 'America/Danmarkshavn' },
  { label: 'El Aaiun', name: 'Africa/El_Aaiun' },
  { label: 'GMT (no daylight saving)', name: 'Etc/GMT' },
  { label: 'Monrovia', name: 'Africa/Monrovia' },
  { label: 'Reykjavik', name: 'Atlantic/Reykjavik' },
  { label: 'Scoresbysund', name: 'America/Scoresbysund' },
  { label: 'UTC', name: 'UTC' },
  { label: 'Algiers', name: 'Africa/Algiers' },
  { label: 'Canary Islands', name: 'Atlantic/Canary' },
  { label: 'Dublin', name: 'Europe/Dublin' },
  { label: 'Faeroe', name: 'Atlantic/Faroe' },
  { label: 'Lagos', name: 'Africa/Lagos' },
  { label: 'Lisbon', name: 'Europe/Lisbon' },
  { label: 'London', name: 'Europe/London' },
  { label: 'Ndjamena', name: 'Africa/Ndjamena' },
  { label: 'Sao Tome', name: 'Africa/Sao_Tome' },
  { label: 'Tunis', name: 'Africa/Tunis' },
  { label: 'Amsterdam', name: 'Europe/Amsterdam' },
  { label: 'Andorra', name: 'Europe/Andorra' },
  { label: 'Berlin', name: 'Europe/Berlin' },
  { label: 'Brussels', name: 'Europe/Brussels' },
  { label: 'Budapest', name: 'Europe/Budapest' },
  { label: 'Cairo', name: 'Africa/Cairo' },
  { label: 'Central European Time - Belgrade', name: 'Europe/Belgrade' },
  { label: 'Central European Time - Prague', name: 'Europe/Prague' },
  { label: 'Ceuta', name: 'Africa/Ceuta' },
  { label: 'Copenhagen', name: 'Europe/Copenhagen' },
  { label: 'Gibraltar', name: 'Europe/Gibraltar' },
  { label: 'Johannesburg', name: 'Africa/Johannesburg' },
  { label: 'Khartoum', name: 'Africa/Khartoum' },
  { label: 'Luxembourg', name: 'Europe/Luxembourg' },
  { label: 'Madrid', name: 'Europe/Madrid' },
  { label: 'Malta', name: 'Europe/Malta' },
  { label: 'Maputo', name: 'Africa/Maputo' },
  { label: 'Monaco', name: 'Europe/Monaco' },
  { label: 'Moscow-01 - Kaliningrad', name: 'Europe/Kaliningrad' },
  { label: 'Oslo', name: 'Europe/Oslo' },
  { label: 'Paris', name: 'Europe/Paris' },
  { label: 'Rome', name: 'Europe/Rome' },
  { label: 'Stockholm', name: 'Europe/Stockholm' },
  { label: 'Tirane', name: 'Europe/Tirane' },
  { label: 'Tripoli', name: 'Africa/Tripoli' },
  { label: 'Vienna', name: 'Europe/Vienna' },
  { label: 'Warsaw', name: 'Europe/Warsaw' },
  { label: 'Windhoek', name: 'Africa/Windhoek' },
  { label: 'Zurich', name: 'Europe/Zurich' },
  { label: 'Amman', name: 'Asia/Amman' },
  { label: 'Athens', name: 'Europe/Athens' },
  { label: 'Baghdad', name: 'Asia/Baghdad' },
  { label: 'Beirut', name: 'Asia/Beirut' },
  { label: 'Bucharest', name: 'Europe/Bucharest' },
  { label: 'Chisinau', name: 'Europe/Chisinau' },
  { label: 'Damascus', name: 'Asia/Damascus' },
  { label: 'Gaza', name: 'Asia/Gaza' },
  { label: 'Helsinki', name: 'Europe/Helsinki' },
  { label: 'Istanbul', name: 'Europe/Istanbul' },
  { label: 'Jerusalem', name: 'Asia/Jerusalem' },
  { label: 'Kiev', name: 'Europe/Kiev' },
  { label: 'Minsk', name: 'Europe/Minsk' },
  { label: 'Moscow+00 - Moscow', name: 'Europe/Moscow' },
  { label: 'Nairobi', name: 'Africa/Nairobi' },
  { label: 'Nicosia', name: 'Asia/Nicosia' },
  { label: 'Qatar', name: 'Asia/Qatar' },
  { label: 'Riga', name: 'Europe/Riga' },
  { label: 'Riyadh', name: 'Asia/Riyadh' },
  { label: 'Sofia', name: 'Europe/Sofia' },
  { label: 'Syowa', name: 'Antarctica/Syowa' },
  { label: 'Tallinn', name: 'Europe/Tallinn' },
  { label: 'Vilnius', name: 'Europe/Vilnius' },
  { label: 'Baku', name: 'Asia/Baku' },
  { label: 'Dubai', name: 'Asia/Dubai' },
  { label: 'Mahe', name: 'Indian/Mahe' },
  { label: 'Mauritius', name: 'Indian/Mauritius' },
  { label: 'Moscow+01 - Samara', name: 'Europe/Samara' },
  { label: 'Reunion', name: 'Indian/Reunion' },
  { label: 'Tbilisi', name: 'Asia/Tbilisi' },
  { label: 'Yerevan', name: 'Asia/Yerevan' },
  { label: 'Kabul', name: 'Asia/Kabul' },
  { label: 'Tehran', name: 'Asia/Tehran' },
  { label: 'Aqtau', name: 'Asia/Aqtau' },
  { label: 'Aqtobe', name: 'Asia/Aqtobe' },
  { label: 'Ashgabat', name: 'Asia/Ashgabat' },
  { label: 'Dushanbe', name: 'Asia/Dushanbe' },
  { label: 'Karachi', name: 'Asia/Karachi' },
  { label: 'Kerguelen', name: 'Indian/Kerguelen' },
  { label: 'Maldives', name: 'Indian/Maldives' },
  { label: 'Mawson', name: 'Antarctica/Mawson' },
  { label: 'Moscow+02 - Yekaterinburg', name: 'Asia/Yekaterinburg' },
  { label: 'Tashkent', name: 'Asia/Tashkent' },
  { label: 'Colombo', name: 'Asia/Colombo' },
  { label: 'India Standard Time', name: 'Asia/Calcutta' },
  { label: 'Katmandu', name: 'Asia/Katmandu' },
  { label: 'Almaty', name: 'Asia/Almaty' },
  { label: 'Bishkek', name: 'Asia/Bishkek' },
  { label: 'Chagos', name: 'Indian/Chagos' },
  { label: 'Dhaka', name: 'Asia/Dhaka' },
  { label: 'Moscow+03 - Omsk', name: 'Asia/Omsk' },
  { label: 'Thimphu', name: 'Asia/Thimphu' },
  { label: 'Vostok', name: 'Antarctica/Vostok' },
  { label: 'Cocos', name: 'Indian/Cocos' },
  { label: 'Rangoon', name: 'Asia/Yangon' },
  { label: 'Bangkok', name: 'Asia/Bangkok' },
  { label: 'Christmas', name: 'Indian/Christmas' },
  { label: 'Davis', name: 'Antarctica/Davis' },
  { label: 'Hanoi', name: 'Asia/Saigon' },
  { label: 'Hovd', name: 'Asia/Hovd' },
  { label: 'Jakarta', name: 'Asia/Jakarta' },
  { label: 'Moscow+04 - Krasnoyarsk', name: 'Asia/Krasnoyarsk' },
  { label: 'Brunei', name: 'Asia/Brunei' },
  { label: 'Casey', name: 'Antarctica/Casey' },
  { label: 'China Time - Beijing', name: 'Asia/Shanghai' },
  { label: 'Choibalsan', name: 'Asia/Choibalsan' },
  { label: 'Hong Kong', name: 'Asia/Hong_Kong' },
  { label: 'Kuala Lumpur', name: 'Asia/Kuala_Lumpur' },
  { label: 'Macau', name: 'Asia/Macau' },
  { label: 'Makassar', name: 'Asia/Makassar' },
  { label: 'Manila', name: 'Asia/Manila' },
  { label: 'Moscow+05 - Irkutsk', name: 'Asia/Irkutsk' },
  { label: 'Singapore', name: 'Asia/Singapore' },
  { label: 'Taipei', name: 'Asia/Taipei' },
  { label: 'Ulaanbaatar', name: 'Asia/Ulaanbaatar' },
  { label: 'Western Time - Perth', name: 'Australia/Perth' },
  { label: 'Dili', name: 'Asia/Dili' },
  { label: 'Jayapura', name: 'Asia/Jayapura' },
  { label: 'Moscow+06 - Yakutsk', name: 'Asia/Yakutsk' },
  { label: 'Palau', name: 'Pacific/Palau' },
  { label: 'Pyongyang', name: 'Asia/Pyongyang' },
  { label: 'Seoul', name: 'Asia/Seoul' },
  { label: 'Tokyo', name: 'Asia/Tokyo' },
  { label: 'Central Time - Adelaide', name: 'Australia/Adelaide' },
  { label: 'Central Time - Darwin', name: 'Australia/Darwin' },
  { label: "Dumont D'Urville", name: 'Antarctica/DumontDUrville' },
  { label: 'Eastern Time - Brisbane', name: 'Australia/Brisbane' },
  { label: 'Eastern Time - Hobart', name: 'Australia/Hobart' },
  { label: 'Eastern Time - Melbourne', name: 'Australia/Melbourne' },
  { label: 'Eastern Time - Melbourne, Sydney', name: 'Australia/Sydney' },
  { label: 'Guam', name: 'Pacific/Guam' },
  { label: 'Moscow+07 - Vladivostok', name: 'Asia/Vladivostok' },
  { label: 'Port Moresby', name: 'Pacific/Port_Moresby' },
  { label: 'Truk', name: 'Pacific/Chuuk' },
  { label: 'Efate', name: 'Pacific/Efate' },
  { label: 'Guadalcanal', name: 'Pacific/Guadalcanal' },
  { label: 'Kosrae', name: 'Pacific/Kosrae' },
  { label: 'Moscow+08 - Magadan', name: 'Asia/Magadan' },
  { label: 'Norfolk', name: 'Pacific/Norfolk' },
  { label: 'Noumea', name: 'Pacific/Noumea' },
  { label: 'Ponape', name: 'Pacific/Pohnpei' },
  { label: 'Auckland', name: 'Pacific/Auckland' },
  { label: 'Fiji', name: 'Pacific/Fiji' },
  { label: 'Funafuti', name: 'Pacific/Funafuti' },
  { label: 'Kwajalein', name: 'Pacific/Kwajalein' },
  { label: 'Majuro', name: 'Pacific/Majuro' },
  { label: 'Moscow+09 - Petropavlovsk-Kamchatskiy', name: 'Asia/Kamchatka' },
  { label: 'Nauru', name: 'Pacific/Nauru' },
  { label: 'Tarawa', name: 'Pacific/Tarawa' },
  { label: 'Wake', name: 'Pacific/Wake' },
  { label: 'Wallis', name: 'Pacific/Wallis' },
  { label: 'Apia', name: 'Pacific/Apia' },
  { label: 'Enderbury', name: 'Pacific/Enderbury' },
  { label: 'Fakaofo', name: 'Pacific/Fakaofo' },
  { label: 'Tongatapu', name: 'Pacific/Tongatapu' },
  { label: 'Kiritimati', name: 'Pacific/Kiritimati' },
].map(tz => ({
  ...tz,
  offset: moment().tz(tz.name).utcOffset() / 60,
}));

export default timezones;
