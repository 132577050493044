/* @flow */
import { type InviteFields } from './types';
import isValidEmail from 'utils/validators/isValidEmail';

export function validateInvite(unvalidatedInvite: InviteFields): InviteFields {
  const invite = Object.assign({}, unvalidatedInvite, {
    email: unvalidatedInvite.email.trim(),
    firstName: unvalidatedInvite.firstName.trim(),
    lastName: unvalidatedInvite.lastName.trim(),
    emailError: null,
    firstNameError: null,
    lastNameError: null,
  });
  if (!invite.firstName && !invite.lastName && !invite.email) {
    return invite;
  }
  if (!invite.firstName) {
    invite.firstNameError = 'Required';
  }
  if (!invite.lastName) {
    invite.lastNameError = 'Required';
  }
  if (!isValidEmail(invite.email)) {
    invite.emailError = 'Invalid email address';
  }
  return invite;
}

export function inviteIsValid(invite: InviteFields): boolean {
  return !invite.firstNameError && !invite.lastNameError && !invite.emailError;
}

export function invitesAreValid(invites: $ReadOnlyArray<InviteFields>): boolean {
  return invites.every(inviteIsValid);
}
