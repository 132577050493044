/* @flow */
import {
  CustomizableBoolean,
  CustomizableCurrency,
  CustomizableDate,
  CustomizableLink,
  CustomizableMultiselect,
  CustomizableNumber,
  CustomizableSelect,
  CustomizableText,
  CustomizableTextarea,
  CustomizableUserMultiselect,
  CustomizableUserSelect,
} from './index';

const kindToTableComponent = {
  TEXT: CustomizableText,
  TEXTAREA: CustomizableTextarea,
  DATE: CustomizableDate,
  LINK: CustomizableLink,
  CURRENCY: CustomizableCurrency,
  NUMBER: CustomizableNumber,
  BOOLEAN: CustomizableBoolean,
  SELECT: CustomizableSelect,
  MULTISELECT: CustomizableMultiselect,
  USER_SELECT: CustomizableUserSelect,
  USER_MULTISELECT: CustomizableUserMultiselect,
  EVENT: CustomizableText,
  DEFAULT: CustomizableText,
};

export default kindToTableComponent;
