/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 45px;
`;

const Label = styled.div`
  margin-bottom: ${props => (props.onlyLabel ? '30px' : '2px')};
  font-weight: 500;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const Info = styled.div`
  margin-top: 4px;
  margin-bottom: 15px;
  font-size: 13px;
  color: #828b93;
`;

const Section = (props: {
  children: React.Node,
  label?: string,
  info?: string,
  className?: string,
}) => {
  const { label, info, children, className } = props;
  return (
    <Container className={className}>
      {label && <Label onlyLabel={!info}>{label}</Label>}
      {info && <Info>{info}</Info>}
      {children}
    </Container>
  );
};

export default Section;
