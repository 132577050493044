/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactPageRoutes_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactPage_org$ref: FragmentReference;
export type ContactPage_org = {|
  +id: string,
  +customFields: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +enabled: boolean
      |}
    |}>
  |},
  +salesforceAccount: ?{|
    +contactsSyncEnabled: boolean,
    +opportunitiesSyncEnabled: boolean,
    +host: string,
  |},
  +marketoAccount: ?{|
    +host: string
  |},
  +$fragmentRefs: ContactPageRoutes_org$ref,
  +$refType: ContactPage_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "host",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ContactPage_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFields",
      "storageKey": "customFields(customizableType:[\"CONTACT\"],fieldNames:[\"title\"],includeDisabled:true)",
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": [
            "CONTACT"
          ],
          "type": "[CustomizableType!]!"
        },
        {
          "kind": "Literal",
          "name": "fieldNames",
          "value": [
            "title"
          ],
          "type": "[String!]"
        },
        {
          "kind": "Literal",
          "name": "includeDisabled",
          "value": true,
          "type": "Boolean"
        }
      ],
      "concreteType": "CustomFieldTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldType",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "enabled",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "contactsSyncEnabled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "opportunitiesSyncEnabled",
          "args": null,
          "storageKey": null
        },
        v0
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "marketoAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "MarketoAccount",
      "plural": false,
      "selections": [
        v0
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactPageRoutes_org",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a22b7252ffcb0881c694f5b099e922e5';
module.exports = node;
