/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import TimePicker from '../TimePicker';

export type TimesConfig = {| startTime: ?string, endTime: ?string |};

const Root = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

export const InputWrapper = styled.div`
  width: 77px;
  font-size: 13px;
  &:not(:last-child) {
    margin-right: 28px;
  }
`;

export default class TimeRangePicker extends React.PureComponent<{
  tz: string,
  date: string,
  startTime: ?string,
  endTime: ?string,
  startLabel?: string,
  endLabel?: string,
  onChange: (timesConfig: TimesConfig) => void,
  disabled?: boolean,
  autoFocus?: boolean,
  onFocusStart?: () => void,
  onFocusEnd?: () => void,
  onBlurStart?: () => void,
  onBlurEnd?: () => void,
  startRequired?: boolean,
  endRequired?: boolean,
  startError?: ?string,
  endError?: ?string,
  startFooterContent?: React.Node,
  delimiter?: React.Node,
  className?: string,
  defaultInterval?: number,
}> {
  startTimeInputWrapperRef = React.createRef();

  endTimeInputWrapperRef = React.createRef();

  handleChange = (timesConfigShape: $Shape<TimesConfig>) => {
    const timesConfig = {
      startTime: this.props.startTime,
      endTime: this.props.endTime,
      ...timesConfigShape,
    };

    if (!timesConfig.startTime && timesConfig.endTime) {
      Object.assign(timesConfig, { startTime: timesConfig.endTime, endTime: null });

      // Give time for AutocompleteInput to update and clear End Time input value
      window.requestAnimationFrame(() => {
        const inputWrapper = this.endTimeInputWrapperRef.current;
        const input = inputWrapper && inputWrapper.querySelector('input');
        if (input) {
          input.value = '';
        }
      });
    }

    if (timesConfig.startTime && timesConfig.endTime) {
      const startOfDay = moment.tz(this.props.date, this.props.tz).startOf('date');
      const endOfDay = moment.tz(this.props.date, this.props.tz).endOf('date');
      const startTime = moment.tz(timesConfig.startTime, this.props.tz);
      const endTime = moment.tz(timesConfig.endTime, this.props.tz);

      if (!this.props.endRequired && moment(startTime).isSame(endTime, 'minute')) {
        Object.assign(timesConfig, { endTime: null });
      } else if (startTime.isSameOrAfter(endTime, 'minute')) {
        if (timesConfigShape.startTime) {
          Object.assign(timesConfig, {
            startTime: endOfDay.isSame(startTime, 'minute')
              ? startTime
                  .clone()
                  .subtract(1, 'minute')
                  .format()
              : timesConfig.startTime,
            endTime: moment
              .min(endOfDay, startTime.clone().add(this.props.defaultInterval, 'minute'))
              .format(),
          });
        } else {
          Object.assign(timesConfig, {
            startTime: moment
              .max(startOfDay, endTime.clone().subtract(this.props.defaultInterval, 'minute'))
              .format(),
            endTime: startOfDay.isSame(endTime, 'minute')
              ? endTime
                  .clone()
                  .add(1, 'minute')
                  .format()
              : timesConfig.endTime,
          });
        }
      }
    }

    this.props.onChange(timesConfig);
  };

  handleChangeStart = (time: ?string) => {
    this.handleChange({ startTime: time, endTime: time ? this.props.endTime : null });

    if (this.props.autoFocus) {
      // Give time for start time input to blur
      window.requestAnimationFrame(() => {
        const inputWrapper =
          time === this.props.endTime
            ? this.startTimeInputWrapperRef.current
            : this.endTimeInputWrapperRef.current;
        const input = inputWrapper && inputWrapper.querySelector('input');

        if (input) {
          input.focus();
        }
      });
    }
  };

  handleChangeEnd = (time: ?string) => {
    this.handleChange({ endTime: time });
  };

  render() {
    return (
      <Root className={this.props.className}>
        <InputWrapper ref={this.startTimeInputWrapperRef}>
          <TimePicker
            tz={this.props.tz}
            date={this.props.date}
            time={this.props.startTime}
            label={this.props.startLabel}
            onChange={this.handleChangeStart}
            disabled={this.props.disabled}
            onFocus={this.props.onFocusStart}
            onBlur={this.props.onBlurStart}
            autoFocus={this.props.autoFocus}
            clearable={!this.props.startRequired}
            footerContent={this.props.startFooterContent}
            error={this.props.startError}
            defaultInterval={this.props.defaultInterval}
            className="startTime"
          />
        </InputWrapper>
        {this.props.delimiter}
        <InputWrapper ref={this.endTimeInputWrapperRef}>
          <TimePicker
            tz={this.props.tz}
            date={this.props.date}
            time={this.props.endTime}
            label={this.props.endLabel}
            onChange={this.handleChangeEnd}
            disabled={this.props.disabled || (this.props.startRequired && !this.props.startTime)}
            clearable={!this.props.endRequired}
            onFocus={this.props.onFocusEnd}
            onBlur={this.props.onBlurEnd}
            initialTime={
              this.props.startTime
                ? moment(this.props.startTime)
                    .tz(this.props.tz)
                    .add(30, 'minute')
                    .format()
                : null
            }
            error={this.props.endError}
            defaultInterval={this.props.defaultInterval}
            className="endTime"
          />
        </InputWrapper>
      </Root>
    );
  }
}
