/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import AuthorizableRoute from 'utils/routing/AuthorizableRoute';
import storage from 'utils/storage';

import ErrorBoundary from 'components/ErrorBoundary';
import NotFound from 'components/NotFound';

import Account from './Account';
import AllContacts from './AllContacts';
import Company from './AllContacts/Company';
import Contact from './AllContacts/Contact';
import Vendor from './AllContacts/Vendor';
import Dashboard from './Dashboard';
import { type DashboardMenuSavedViewsQueryResponse } from './Dashboard/__generated__/DashboardMenuSavedViewsQuery.graphql';
import Event from './Event';
import { Sidebar } from './layout';
import Header from './layout/Header';
import Navbar, { menuConfig } from './layout/Navbar';
import NavContext from './layout/Navbar/NavContext';
import Reporting from './Reporting';
import Settings from './Settings';
import Vendors from './Vendors';
import Workspace from './Workspace';

import { type MainContent_me } from './__generated__/MainContent_me.graphql';
import { type MainContent_org } from './__generated__/MainContent_org.graphql';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-height: 0;
  height: 100%;
  background: #f6f2ff;
`;

const LeftBars = styled.div`
  position: absolute;
  left: ${props => (props.shown ? 0 : -290)}px;
  top: 55px;
  display: flex;
  width: 290px;
  height: calc(100% - 55px);
  opacity: ${props => (props.shown ? 1 : 0.5)};
  transition: all 0.3s;
  z-index: 12;
  background: #f6f3ff;
`;

const LeftBarsOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: #000;
  opacity: 0.5;
`;

const prefixRoutes = ['events', 'account', 'registration', 'tasks'];

class MainContent extends React.Component<
  {
    org: MainContent_org,
    me: MainContent_me,
    errorPageShown?: ?boolean,
    location: Location,
    history: RouterHistory,
    accessToLegacyFeatures: boolean,
  },
  {
    showSidebar: boolean,
    selectedMenu: ?string,
    rootPath: string,
    prevSelectedMenu: ?string,
    cachedResponse: ?DashboardMenuSavedViewsQueryResponse,
    showMobileMenu: boolean,
  },
> {
  state = {
    showSidebar:
      Object.keys(menuConfig(this.props.org, this.props.accessToLegacyFeatures)).includes(
        this.getNavigationRoot(),
      ) && storage.get('showSidebar') !== false,
    selectedMenu: this.getDefaultMenu(),
    rootPath: this.getNavigationRoot(),
    prevSelectedMenu: null,
    cachedResponse: null,
    showMobileMenu: window.innerWidth < 800,
  };

  static getDerivedStateFromProps(
    props: $PropertyType<MainContent, 'props'>,
    state: $PropertyType<MainContent, 'state'>,
  ) {
    const newRootPath = props.location.pathname.split('/')[1];

    if (newRootPath !== state.rootPath) {
      return {
        rootPath: newRootPath,
        prevSelectedMenu:
          state.selectedMenu ||
          (Object.keys(menuConfig(props.org, props.accessToLegacyFeatures)).includes(state.rootPath)
            ? state.rootPath
            : null),
        selectedMenu:
          prefixRoutes.includes(newRootPath) && newRootPath !== 'account' ? 'dashboard' : null,
      };
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    if (this.state.showMobileMenu && this.state.showSidebar) {
      this.handleToggleSidebar('');
    }
  }

  componentDidUpdate(prevProp: $PropertyType<MainContent, 'props'>) {
    if (
      this.state.selectedMenu &&
      this.state.selectedMenu !== 'settings' &&
      this.getNavigationRoot() === 'settings' &&
      this.props.location !== prevProp.location
    ) {
      // Had to do this in componentDidUpdate, but its safe and will not cause infinite loop
      // The case is for opening configuration submenu when clicked (event, contact, budget) settings
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedMenu: null });
    }
    if (
      this.state.showMobileMenu &&
      this.state.showSidebar &&
      this.props.location.pathname !== prevProp.location.pathname
    ) {
      this.handleToggleSidebar('');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getDefaultMenu() {
    return prefixRoutes.includes(this.getNavigationRoot()) ? 'dashboard' : null;
  }

  getNavigationRoot() {
    return this.props.location.pathname.split('/')[1];
  }

  handleResize = () => {
    const isMobileScreen = window.innerWidth < 800;

    if (isMobileScreen && !this.state.showMobileMenu) {
      this.setState({ showMobileMenu: true });
    } else if (!isMobileScreen && this.state.showMobileMenu) {
      this.setState({ showMobileMenu: false });
    }
  };

  selectedSubmenu = () => {
    return this.props.location.pathname.split('/')[2];
  };

  isAnyRootActive = () => {
    const { org, accessToLegacyFeatures } = this.props;
    return Object.keys(menuConfig(org, accessToLegacyFeatures)).includes(this.getNavigationRoot());
  };

  handleChangeCachedResponse = (cachedResponse: DashboardMenuSavedViewsQueryResponse) => {
    this.setState({ cachedResponse });
  };

  handleToggleSidebar = (shownMenu: string) => {
    const navigationRoot = this.getNavigationRoot();
    const selectedMenu = this.state.selectedMenu;

    if (this.state.showMobileMenu) {
      this.setState(prevState => {
        if (!prevState.showSidebar || !shownMenu) {
          storage.set('showSidebar', !prevState.showSidebar);
          return { showSidebar: !prevState.showSidebar };
        }

        storage.set('showSidebar', true);
        return {
          selectedMenu: shownMenu,
          prevSelectedMenu: null,
          showSidebar: true,
        };
      });
      return;
    }

    if (this.isAnyRootActive() && navigationRoot === shownMenu && !!this.selectedSubmenu()) {
      this.setState(prevState => {
        const showSidebar =
          prevState.selectedMenu && prevState.selectedMenu !== shownMenu
            ? true
            : !prevState.showSidebar;
        storage.set('showSidebar', showSidebar);
        return {
          selectedMenu: null,
          prevSelectedMenu: null,
          showSidebar,
        };
      });
      return;
    }

    if (selectedMenu === shownMenu) {
      this.setState(prevState => {
        storage.set('showSidebar', !prevState.showSidebar);
        return { showSidebar: !prevState.showSidebar };
      });
      return;
    }
    this.setState({
      selectedMenu: shownMenu,
      prevSelectedMenu: null,
      showSidebar: true,
    });
    storage.set('showSidebar', true);
  };

  getAuthorizedSelectedMenu = (selectedMenu: string): string => {
    const org = this.props.org;
    const prevSelectedMenu = this.state.prevSelectedMenu;

    if (selectedMenu === 'account' && prevSelectedMenu != null) {
      return prevSelectedMenu;
    }
    if (
      (selectedMenu === 'settings' && org.viewerCanUpdate) ||
      (selectedMenu === 'reporting' && org.viewerCanViewReporting) ||
      (selectedMenu === 'workspace' && org.viewerCanViewWorkspace) ||
      (selectedMenu === 'contacts' && org.viewerCanCreateContacts) ||
      (selectedMenu === 'vendors' && org.viewerCanCreateVendors)
    ) {
      return selectedMenu;
    }

    return 'dashboard';
  };

  renderSidebar = (navigationRoot: string, selectedMenu: ?string, showSidebar: boolean) => {
    const { me, org, location, accessToLegacyFeatures } = this.props;
    const menu = menuConfig(org, accessToLegacyFeatures);

    const authorizedSelectedMenu = this.getAuthorizedSelectedMenu(selectedMenu || navigationRoot);

    const MenuComponent = menu[authorizedSelectedMenu].component;
    return (
      <Sidebar
        shown={showSidebar}
        disabled={org.subscription.upgradeRequired}
        showMobileMenu={this.state.showMobileMenu}
      >
        <MenuComponent
          org={org}
          user={me}
          location={location}
          pathPrefix={`/${menu[authorizedSelectedMenu].pathPrefix}`}
          cachedResponse={this.state.cachedResponse}
          onChangeCachedResponse={this.handleChangeCachedResponse}
        />
      </Sidebar>
    );
  };

  renderLeftBars = () => {
    const { org, location, accessToLegacyFeatures } = this.props;
    const { selectedMenu, showSidebar } = this.state;

    const navigationRoot = this.getNavigationRoot();
    const navigationRootPaths = [
      ...Object.keys(menuConfig(org, accessToLegacyFeatures)),
      ...prefixRoutes,
    ];

    return (
      <>
        <Navbar
          location={location}
          org={org}
          selectedMenu={this.getAuthorizedSelectedMenu(selectedMenu || navigationRoot)}
          showSidebar={showSidebar}
          onToggleSidebar={this.handleToggleSidebar}
          accessToLegacyFeatures={accessToLegacyFeatures}
        />
        {navigationRootPaths.includes(navigationRoot) &&
          this.renderSidebar(navigationRoot, selectedMenu, showSidebar)}
      </>
    );
  };

  renderConditionalRoute = (props: {
    path: string,
    component: React$ComponentType<any>,
    legacy: boolean,
    authorized?: boolean,
  }) => {
    const { path, component, legacy, authorized } = props;
    if (this.props.accessToLegacyFeatures !== legacy) {
      return null;
    }

    if (authorized !== undefined) {
      return <AuthorizableRoute path={path} component={component} authorized={authorized} />;
    }

    return <Route path={path} component={component} />;
  };

  render() {
    const { org, errorPageShown, location, history } = this.props;
    const { showSidebar, showMobileMenu } = this.state;

    return (
      <NavContext.Provider
        value={{
          showSidebar,
          onToggleSidebar: this.handleToggleSidebar,
        }}
      >
        <Header showMobileMenu={showMobileMenu} />
        <Container className={!showMobileMenu && showSidebar && 'sidebar-shown'}>
          <ErrorBoundary location={location}>
            {showMobileMenu && showSidebar && (
              <LeftBarsOverlay onClick={() => this.handleToggleSidebar('')} />
            )}
            {showMobileMenu ? (
              <LeftBars shown={showSidebar}>{this.renderLeftBars()}</LeftBars>
            ) : (
              this.renderLeftBars()
            )}
            {errorPageShown ? (
              <NotFound history={history} />
            ) : (
              <Switch>
                {this.renderConditionalRoute({
                  path: '/(events)/:event_slug/contacts/(people|opportunities)/:contact_slug',
                  component: Contact,
                  legacy: true,
                })}
                {this.renderConditionalRoute({
                  path: '/(events)/:event_slug/contacts/companies/:company_id',
                  component: Company,
                  legacy: true,
                })}
                {this.renderConditionalRoute({
                  path: '/(tasks)/:event_slug/contacts/people/:contact_slug/:task_slug',
                  component: Contact,
                  legacy: true,
                })}
                {this.renderConditionalRoute({
                  path: '/(tasks)/:event_slug/contacts/companies/:company_id/:task_slug',
                  component: Company,
                  legacy: true,
                })}
                {this.renderConditionalRoute({
                  path: '/contacts',
                  component: AllContacts,
                  legacy: true,
                  authorized: org.viewerCanCreateContacts,
                })}
                {this.renderConditionalRoute({
                  path: '/vendors',
                  component: Vendors,
                  legacy: false,
                  authorized: org.viewerCanCreateVendors,
                })}
                <Route
                  path="/(tasks)/:event_slug/vendors/:vendorId/:task_slug"
                  component={Vendor}
                />
                <Route path="/(events)/:event_slug/vendors/:vendorId" component={Vendor} />
                <Route path="/dashboard" render={props => <Dashboard {...props} org={org} />} />
                <AuthorizableRoute
                  path="/workspace"
                  component={Workspace}
                  authorized={org.viewerCanViewWorkspace}
                />
                <AuthorizableRoute
                  path="/reporting"
                  component={Reporting}
                  authorized={org.viewerCanViewReporting}
                />
                <AuthorizableRoute
                  authorized={org.viewerCanUpdate}
                  path="/settings"
                  component={Settings}
                />
                <Route
                  path="/account"
                  render={props => <Account location={props.location} match={props.match} />}
                />
                <Route path="/events/:event_slug" component={Event} />
              </Switch>
            )}
          </ErrorBoundary>
        </Container>
      </NavContext.Provider>
    );
  }
}

export default createFragmentContainer(MainContent, {
  org: graphql`
    fragment MainContent_org on Org {
      viewerCanUpdate
      viewerCanCreateContacts
      viewerCanCreateVendors
      viewerCanViewWorkspace
      viewerCanViewReporting
      subscription {
        upgradeRequired
      }
      ...Navbar_org
      ...DashboardMenu_org
      ...WorkspaceMenu_org
      ...ReportingMenu_org
      ...SettingsMenu_org
      ...ContactsMenu_org
      ...VendorsMenu_org
    }
  `,
  me: graphql`
    fragment MainContent_me on User {
      ...DashboardMenu_user
    }
  `,
});
