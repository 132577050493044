/* @flow */

const staticFields = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  company_name: 'Company - Name',
};

export default staticFields;
