/* @flow */
import * as React from 'react';

import type { FieldType } from 'utils/customization/types';
import { type CustomFieldParam } from 'utils/routing/parseTypedQueryString';

import CheckFilterItem from 'components/material/Filters/Advanced/CheckFilterItem';
import DateFilterItem from 'components/material/Filters/Advanced/DateFilterItem';
import { type FilterValueChangeParam } from 'components/material/Filters/Advanced/FilterItems';
import RadioFilterItem from 'components/material/Filters/Advanced/RadioFilterItem';
import RangeFilterItem from 'components/material/Filters/Advanced/RangeFilterItem';
import TextFilterItem from 'components/material/Filters/Advanced/TextFilterItem';
import UserFilterItem from 'components/material/Filters/Advanced/UserFilterItem';

export default function renderFilterByKind(
  customField: FieldType,
  activeValues: CustomFieldParam,
  onFilterChange: (name: string, value: FilterValueChangeParam) => void,
): React.Node {
  switch (customField.kind) {
    case 'USER_SELECT':
    case 'USER_MULTISELECT':
      return (
        <UserFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.id}
          onChange={onFilterChange}
          activeValues={activeValues != null ? activeValues.stringArrayValue : null}
        />
      );
    case 'DATE':
      return (
        <DateFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.id}
          onChange={onFilterChange}
          activeValue={activeValues != null ? activeValues.dateRangeValue : null}
          hideShortcutOptions
        />
      );
    case 'SELECT':
    case 'MULTISELECT':
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.id}
          options={customField.options}
          onChange={onFilterChange}
          activeValues={activeValues != null ? activeValues.enumArrayValue : null}
          unsorted
        />
      );
    case 'BOOLEAN':
      return (
        <RadioFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.id}
          onChange={onFilterChange}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]}
          activeValue={
            activeValues != null && activeValues.booleanValue != null
              ? activeValues.booleanValue
              : null
          }
        />
      );
    case 'NUMBER':
    case 'CURRENCY':
      return (
        <RangeFilterItem
          key={customField.id}
          label={customField.label}
          name={customField.id}
          onChange={onFilterChange}
          activeValue={activeValues != null ? activeValues.numberRangeParam : null}
          isCurrency={customField.kind === 'CURRENCY'}
          autoFocus
        />
      );
    default:
      return null;
  }
}
