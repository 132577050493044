/* @flow */
import React from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';

import fullNameOfUser from 'utils/fullNameOfUser';

import ClearIcon from 'components/ClearIcon';
import CheckBox from 'components/material/CheckBox';
import MaterialAvatar from 'components/material/MaterialAvatar';
import TextInput from 'components/material/TextInput';

import type { ContactFilterContainer_query } from './__generated__/ContactFilterContainer_query.graphql';

const Container = styled.div``;

const TextWrapper = styled.div`
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  margin-top: 10px;
  padding: 0 27px 0 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Main = styled.div`
  margin-left: 10px;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const StyledCheckBox = styled(CheckBox)`
  margin-top: 16px;
  &:last-of-type {
    margin-bottom: 1px;
  }
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: ${props => props.theme.darkestTextColor};
  > div:first-of-type {
    color: ${props => (props.checked ? props.theme.highlightColor : props.theme.iron)};
  }
`;

type ContactType = $Shape<{|
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  $fragmentRefs: any,
|}>;

class ContactFilterContainer extends React.Component<
  {
    activeValues: $ReadOnlyArray<string>,
    onChange: (userIds: ?$ReadOnlyArray<string>, query: string) => void,
    query: ContactFilterContainer_query,
    relay: RelayRefetchProp,
  },
  { query: string },
> {
  state = { query: '' };

  cachedUsers: $ReadOnlyArray<ContactType> = [];

  fetchUsers = throttle((query: string) => {
    this.props.relay.refetch(
      {
        query,
        contactIds: this.props.activeValues,
        contactFilters: { query },
      },
      null,
    );
  }, 800);

  handleOptionChange = (checked: boolean, contact: ContactType): void => {
    const activeValues = this.props.activeValues;

    const values = checked
      ? [...activeValues, contact.id]
      : activeValues.filter(contactId => contactId !== contact.id);

    this.props.onChange(values, this.state.query);
  };

  handleQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ query: e.currentTarget.value });
    this.fetchUsers(e.currentTarget.value);
  };

  handleClear = () => {
    this.setState({ query: '' });
    this.fetchUsers('');
  };

  renderUserRow = (user: ContactType) => (
    <Row>
      <MaterialAvatar user={user} radius={11} />
      <Main>{fullNameOfUser(user)}</Main>
    </Row>
  );

  render() {
    const { query, activeValues } = this.props;

    this.cachedUsers =
      query && query.selectedContacts && query.org.contacts
        ? sortBy(
            uniqBy(
              [
                ...query.selectedContacts.map(contact => ({
                  ...contact,
                })),
                ...query.org.contacts.edges.map(edge => ({
                  ...edge.node,
                })),
              ],
              'id',
            ),
            option => `${option.firstName} ${option.lastName} ${option.email}`.toLowerCase(),
          )
        : this.cachedUsers;

    return (
      <Container>
        <TextWrapper>
          <StyledTextInput
            placeholder="Search Contacts"
            autoFocus
            value={this.state.query}
            onChange={this.handleQueryChange}
          />
          {this.state.query && <ClearIcon onClick={this.handleClear} />}
        </TextWrapper>
        {this.cachedUsers.map(option => (
          <StyledCheckBox
            key={option.id}
            labelRenderer={() => this.renderUserRow(option)}
            checked={activeValues.some(optionId => optionId === option.id)}
            onChange={checked => {
              this.handleOptionChange(checked, option);
            }}
          />
        ))}
      </Container>
    );
  }
}

export default createRefetchContainer(
  ContactFilterContainer,
  graphql`
    fragment ContactFilterContainer_query on Query {
      org {
        contacts(first: 15, filters: $contactFilters) {
          edges {
            node {
              id
              email
              firstName
              lastName
              ...MaterialAvatar_user
            }
          }
        }
      }
      selectedContacts: nodes(ids: $contactIds) {
        ... on Contact {
          id
          email
          firstName
          lastName
          ...MaterialAvatar_user
        }
      }
    }
  `,
  graphql`
    query ContactFilterContainerRefetchQuery(
      $contactIds: [ID!]!
      $contactFilters: ContactFilters!
    ) {
      query {
        ...ContactFilterContainer_query
      }
    }
  `,
);
