/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetReportingGroupContainer_org$ref: FragmentReference;
export type BudgetReportingGroupContainer_org = {|
  +id: string,
  +salesforceAccount: ?{|
    +id: string
  |},
  +marketoAccount: ?{|
    +id: string
  |},
  +viewerCanCreateReportingSavedView: boolean,
  +settings: {|
    +currency: string,
    +fiscalYearStart: number,
  |},
  +customFieldSections: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
        +order: number,
      |}
    |}>
  |},
  +$refType: BudgetReportingGroupContainer_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
];
return {
  "kind": "Fragment",
  "name": "BudgetReportingGroupContainer_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "marketoAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "MarketoAccount",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanCreateReportingSavedView",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currency",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fiscalYearStart",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFieldSections",
      "storageKey": "customFieldSections(sectionCustomizableType:[\"EVENT\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "sectionCustomizableType",
          "value": [
            "EVENT"
          ],
          "type": "[SectionCustomizableType!]!"
        }
      ],
      "concreteType": "CustomFieldSectionTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldSectionTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldSectionType",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ed42fbe2122d72b18175931979134e5b';
module.exports = node;
