/* @flow */
import React from 'react';
import styled from 'styled-components';

import ClearIcon from 'components/ClearIcon';
import CheckBox from 'components/EventRequestForm/form/CheckBox';
import TextField from 'components/material/TextField';
import Radio from 'components/SharedForm/Radio';

import { type FieldOption } from '../types';

const StyledClearIcon = styled(ClearIcon)`
  display: none;
  margin-left: 5px;
  color: #868f96;
  &:hover {
    color: #4db1dd;
  }
`;

const StyledTextField = styled(TextField)`
  width: 200px;
  margin: 0 0 0 10px;
  > input {
    padding-top: 2px;
    &:not(:focus) {
      border-color: transparent;
    }
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 5px;
  }
  &:hover {
    ${StyledClearIcon} {
      top: 8px;
      left: 230px;
      display: block;
    }
  }
`;

export default class FieldOptionRow extends React.Component<
  {
    option: FieldOption,
    singleSelect?: boolean,
    restrictManagingOptions: boolean,
    onUpdate: (id: string, value: string, enterPressed: boolean) => void,
    onRemove?: (id: string) => void,
    hasFocus: boolean,
  },
  { value: string },
> {
  state = { value: this.props.option.name };

  enterButtonClicked: boolean = false;

  handelBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    if (!this.props.option.editable) {
      return;
    }
    const value = e.currentTarget.value;

    if (value.trim() === '') {
      this.setState({ value: this.props.option.name });
      if (this.props.option.name !== '') {
        return;
      }
    }

    this.props.onUpdate(this.props.option.id, value, this.enterButtonClicked);

    this.enterButtonClicked = false;
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.enterButtonClicked = true;
      e.currentTarget.blur();
    }
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ value: e.currentTarget.value });
  };

  handleRemove = () => {
    const {
      option: { id },
      onRemove,
    } = this.props;

    if (onRemove) {
      onRemove(id);
    }
  };

  render() {
    const {
      hasFocus,
      option: { editable },
      singleSelect,
      onRemove,
      restrictManagingOptions,
    } = this.props;
    const InputComponent = singleSelect ? Radio : CheckBox;
    return (
      <Row>
        <InputComponent checked={false} disabled compact />
        <StyledTextField
          autoFocus={hasFocus}
          value={this.state.value}
          onBlur={this.handelBlur}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          readOnly={!editable || restrictManagingOptions}
        />
        {editable && !restrictManagingOptions && onRemove && (
          <StyledClearIcon onClick={this.handleRemove} />
        )}
      </Row>
    );
  }
}
