/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { Content } from 'components/page/Content';

import SettingsRoutes from './SettingsRoutes';

import { type SettingsQueryResponse } from './__generated__/SettingsQuery.graphql';

const StyledContent = styled(Content)`
  padding: 0;
  overflow: auto;
`;

const query = graphql`
  query SettingsQuery {
    org {
      name
      ...SettingsRoutes_org
    }
  }
`;

type Props = { match: Match, location: Location };

const headerTitles = {
  general: 'General',
  events: 'Events',
  contacts: 'Contacts',
  vendors: 'Vendors',
  budgets: 'Budgets',
  integrations: 'Integrations',
};

const Settings = ({ match, location }: Props) => {
  const title = headerTitles[location.pathname.split('/')[2]] || headerTitles.general;
  return (
    <StyledContent>
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(response: SettingsQueryResponse) => (
          <DocumentTitle title={`${title} - ${response.org.name}`}>
            <SettingsRoutes org={response.org} pathPrefix={match.path} />
          </DocumentTitle>
        )}
      />
    </StyledContent>
  );
};

export default Settings;
