/**
 * @flow
 * @relayHash c00c1672736fdd474ce2c9f3f986ef30
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddAttachmentToBriefInput = {
  clientMutationId?: ?string,
  eventId: string,
  attachmentId: string,
};
export type addAttachmentToBriefMutationVariables = {|
  input: AddAttachmentToBriefInput
|};
export type addAttachmentToBriefMutationResponse = {|
  +addAttachmentToBrief: ?{|
    +attachmentEdge: {|
      +__typename: string,
      +node: {|
        +id: string
      |},
    |}
  |}
|};
export type addAttachmentToBriefMutation = {|
  variables: addAttachmentToBriefMutationVariables,
  response: addAttachmentToBriefMutationResponse,
|};
*/


/*
mutation addAttachmentToBriefMutation(
  $input: AddAttachmentToBriefInput!
) {
  addAttachmentToBrief(input: $input) {
    attachmentEdge {
      __typename
      node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddAttachmentToBriefInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addAttachmentToBrief",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddAttachmentToBriefInput!"
      }
    ],
    "concreteType": "AddAttachmentToBriefPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "attachmentEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "AttachmentRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Attachment",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addAttachmentToBriefMutation",
  "id": null,
  "text": "mutation addAttachmentToBriefMutation(\n  $input: AddAttachmentToBriefInput!\n) {\n  addAttachmentToBrief(input: $input) {\n    attachmentEdge {\n      __typename\n      node {\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addAttachmentToBriefMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "addAttachmentToBriefMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b01816a06c0ced6a5890b23d2809c771';
module.exports = node;
