/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import sortBy from 'lodash/sortBy';

import FeatureAccessContext from 'contexts/FeatureAccess';

import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import storage from 'utils/storage';

import {
  getColumnsShowConfig,
  getDefaultFilters,
  suggestedFields,
} from 'components/AllEvents/eventsTableColumnSettings';
import getEventFields, { ColsGroups } from 'components/AllEvents/lib/getEventFields';
import parseAllEventsFilters from 'components/AllEvents/lib/parseAllEventsFilters';
import parseDateFilters from 'components/AllEvents/lib/parseDateFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import headerTitles from '../lib/headerTitles';
import AllEventsCalendar from './AllEventsCalendar';
import AllEventsListPage from './AllEventsListPage';
import AllEventsMap from './AllEventsMap';
import AllEventsPopulateFilters from './AllEventsPopulateFilters';

import type { AllEventsQueryResponse } from './__generated__/AllEventsQuery.graphql';

const query = graphql`
  query AllEventsQuery($eventListId: ID!, $includeEventList: Boolean!) {
    me {
      id
      ...AllEventsPopulateFilters_me
      ...AllEventsListPage_user
      ...AllEventsCalendar_user
      ...AllEventsMap_user
    }
    org {
      ...AllEventsListPage_org
      ...AllEventsCalendar_org
      ...AllEventsMap_org
      customFieldSections(sectionCustomizableType: [EVENT]) {
        edges {
          node {
            id
            name
            order
          }
        }
      }
      customFields(customizableType: [EVENT]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            restrictChangingValue: mappedToSalesforce(pull: true)
            section {
              id
              order
            }
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      settings {
        fiscalYearStart
      }
      salesforceAccount {
        id
      }
      marketoAccount {
        id
      }
    }
    ... @include(if: $includeEventList) {
      eventList: node(id: $eventListId) {
        ...AllEventsListPage_eventList
        ...AllEventsCalendar_eventList
        ...AllEventsMap_eventList
        ...AllEventsPopulateFilters_eventList
      }
    }
  }
`;

class AllEvents extends React.Component<
  {
    me: $PropertyType<AllEventsQueryResponse, 'me'>,
    org: $PropertyType<AllEventsQueryResponse, 'org'>,
    eventList: $PropertyType<AllEventsQueryResponse, 'eventList'>,
    match: Match,
    location: Location,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
  },
> {
  eventFields: $ReadOnlyArray<FieldType> = getEventFields(
    normalizeCustomFields(this.props.org.customFields.edges.map(({ node }) => node)),
    this.props.org,
    this.context.legacyFeatures,
  );

  state = {
    shownColumns: AllEvents.getShownColumns(
      this.props.me.id,
      this.eventFields,
      this.props.location.pathname,
    ),
  };

  static getShownColumns(
    userId: string,
    columns: $ReadOnlyArray<FieldType>,
    pathName: string,
  ): $ReadOnlyArray<string> {
    const cacheKey = `${userId}_allEventsShownColumns`;
    const shownColumns: any = (storage.get(cacheKey): ?mixed);
    const allColumns = Array.isArray(shownColumns)
      ? shownColumns
      : getColumnsShowConfig(columns)
          .filter(column => column.default)
          .map(column => column.value);

    if (pathName.includes('requested_events') && !allColumns.includes('request_status')) {
      return [...allColumns, 'request_status'];
    }

    return allColumns;
  }

  handleChangeShownColumns = (shownColumns: $ReadOnlyArray<string>, save: boolean = true) => {
    if (save) {
      storage.set(`${this.props.me.id}_allEventsShownColumns`, shownColumns);
    }

    this.setState({ shownColumns });
  };

  static contextType = FeatureAccessContext;

  render() {
    const { me, org, eventList, match, location } = this.props;
    const { shownColumns } = this.state;
    const pathPrefix = match.url;
    const filters = parseAllEventsFilters(
      location.search,
      org.settings.fiscalYearStart,
      this.eventFields,
    );
    const filtered = Object.values(filters).some(val => val != null);
    const { search, sort, date, month, week, ...otherFilters } = filters;
    const defaultFilters = getDefaultFilters(otherFilters);
    const customFieldSections = sortBy(org.customFieldSections.edges, edge => edge.node.order).map(
      ({ node }) => ({
        label: node.name,
        id: node.id,
      }),
    );
    const groups = ColsGroups(
      {
        salesforceEnabled: !!org.salesforceAccount,
        marketoEnabled: !!org.marketoAccount,
        customFieldSections,
      },
      this.context.legacyFeatures,
    );
    const customFilters = this.eventFields
      .filter(
        option =>
          (option.fieldName == null || suggestedFields.includes(option.fieldName)) &&
          otherFilters[option.id] != null,
      )
      .map(option => getCustomFieldVariable(otherFilters[option.id], option));
    const sortFilter = sort || {
      key: 'START_DATE',
      asc: true,
    };
    const customSortField: ?FieldType = this.eventFields.find(field => field.id === sortFilter.key);
    const dateParams = parseDateFilters(date);
    const filterVariables = {
      search,
      sort: customSortField ? 'CUSTOM' : sortFilter.key,
      customFieldSortId: customSortField != null ? customSortField.id : null,
      direction: sortFilter.asc ? 'ASC' : 'DESC',
      ...defaultFilters,
      ...dateParams,
      customFilters,
    };

    return (
      <>
        <AllEventsPopulateFilters
          key={(location.state && location.state.key) || location.pathname}
          me={me}
          eventList={eventList || null}
          filtered={filtered}
          eventFields={this.eventFields}
          onChangeShownColumns={this.handleChangeShownColumns}
        />
        {!location.state || !location.state.fromSavedViewMenu ? (
          <Switch>
            <Route
              path={match.path}
              exact
              render={props => (
                <AllEventsListPage
                  org={org}
                  user={me}
                  eventList={eventList || null}
                  eventFields={this.eventFields}
                  shownColumns={shownColumns}
                  onChangeShownColumns={this.handleChangeShownColumns}
                  pathPrefix={pathPrefix}
                  filters={filters}
                  filtered={filtered}
                  filterVariables={filterVariables}
                  groups={groups}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.path}/calendar`}
              exact
              render={props => (
                <AllEventsCalendar
                  org={org}
                  user={me}
                  eventList={eventList || null}
                  eventFields={this.eventFields}
                  shownColumns={shownColumns}
                  pathPrefix={pathPrefix}
                  filters={filters}
                  filterVariables={filterVariables}
                  groups={groups}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.path}/map`}
              exact
              render={props => (
                <AllEventsMap
                  org={org}
                  user={me}
                  eventList={eventList || null}
                  eventFields={this.eventFields}
                  shownColumns={shownColumns}
                  pathPrefix={pathPrefix}
                  filters={filters}
                  filterVariables={filterVariables}
                  groups={groups}
                  match={match}
                  location={location}
                  {...props}
                />
              )}
            />
          </Switch>
        ) : null}
      </>
    );
  }
}

export default ({ match, location }: { match: Match, location: Location }) => (
  <DefaultQueryRenderer
    query={query}
    variables={{
      eventListId: match.params.list_id || '',
      includeEventList: !(match.params.list_id && headerTitles[match.params.list_id]),
    }}
    renderSuccess={(response: AllEventsQueryResponse) => (
      <AllEvents
        match={match}
        location={location}
        me={response.me}
        org={response.org}
        eventList={response.eventList}
      />
    )}
  />
);
