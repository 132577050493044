/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesforceSyncStatusInfo_event$ref: FragmentReference;
export type SalesforceSyncStatusInfo_event = {|
  +id: string,
  +tz: string,
  +pullStatus: ?{|
    +lastSuccessAt: ?any,
    +state: SyncState,
    +errorMessage: string,
  |},
  +pushStatus: ?{|
    +lastSuccessAt: ?any,
    +state: SyncState,
    +errorMessage: string,
  |},
  +$refType: SalesforceSyncStatusInfo_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastSuccessAt",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "state",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "errorMessage",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "SalesforceSyncStatusInfo_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pullStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pushStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": v0
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2037adada3aa504cea87751a96c60f55';
module.exports = node;
