/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';

import type {
  removeScheduleItemMutationVariables,
  removeScheduleItemMutationResponse,
} from './__generated__/removeScheduleItemMutation.graphql';

const mutation = graphql`
  mutation removeScheduleItemMutation($input: RemoveItemFromScheduleDayInput!) {
    removeItemFromScheduleDay(input: $input) {
      removedItemId
    }
  }
`;

export default function removeScheduleItem(
  scheduleDayId: string,
  scheduleItemId: string,
): Promise<removeScheduleItemMutationResponse> {
  const variables: removeScheduleItemMutationVariables = {
    input: {
      scheduleItemId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedItemId',
        parentId: scheduleDayId,
        connections: [{ field: 'scheduleItems' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedItemId' }),
    ),
  });
}
