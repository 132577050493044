/**
 * @flow
 * @relayHash 7b02979a35eb1dc99936921d93a03e17
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NotesContainer_event$ref = any;
export type NotesQueryVariables = {|
  eventSlug: string
|};
export type NotesQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: NotesContainer_event$ref
  |}
|};
export type NotesQuery = {|
  variables: NotesQueryVariables,
  response: NotesQueryResponse,
|};
*/


/*
query NotesQuery(
  $eventSlug: String!
) {
  event(slug: $eventSlug) {
    ...NotesContainer_event
    id
  }
}

fragment NotesContainer_event on Event {
  id
  dbId
  name
  tz
  viewerCanCreateNote
  ...EventNote_event
  eventNotes {
    edges {
      node {
        id
        updatedAt
        ...NoteItem_note
      }
    }
  }
}

fragment EventNote_event on Event {
  ...NoteEdit_event
}

fragment NoteItem_note on EventNote {
  id
  name
  updatedAt
  viewerCanDelete
  updater {
    firstName
    lastName
    ...MaterialAvatar_user
    id
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}

fragment NoteEdit_event on Event {
  id
  viewerCanUpdate
  viewerCanCreateNote
  tz
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NotesQuery",
  "id": null,
  "text": "query NotesQuery(\n  $eventSlug: String!\n) {\n  event(slug: $eventSlug) {\n    ...NotesContainer_event\n    id\n  }\n}\n\nfragment NotesContainer_event on Event {\n  id\n  dbId\n  name\n  tz\n  viewerCanCreateNote\n  ...EventNote_event\n  eventNotes {\n    edges {\n      node {\n        id\n        updatedAt\n        ...NoteItem_note\n      }\n    }\n  }\n}\n\nfragment EventNote_event on Event {\n  ...NoteEdit_event\n}\n\nfragment NoteItem_note on EventNote {\n  id\n  name\n  updatedAt\n  viewerCanDelete\n  updater {\n    firstName\n    lastName\n    ...MaterialAvatar_user\n    id\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n\nfragment NoteEdit_event on Event {\n  id\n  viewerCanUpdate\n  viewerCanCreateNote\n  tz\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NotesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NotesContainer_event",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NotesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dbId",
            "args": null,
            "storageKey": null
          },
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tz",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanCreateNote",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanUpdate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventNotes",
            "storageKey": null,
            "args": null,
            "concreteType": "EventNoteRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventNoteRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventNote",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanDelete",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "updater",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                          },
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "avatar",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9907b81a22451aea7321a9c89fc773cf';
module.exports = node;
