/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type Match, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDateTimeRange from 'utils/date/formatDateTimeRange';
import fullNameOfUser from 'utils/fullNameOfUser';

import CalendarIcon from 'images/calendarIcon.svg';
import Expand from 'images/expand.svg';
import Remove from 'images/remove.svg';
import { OverlayContext } from 'components/material/Overlay';

import AllEventsCalendarEventWindowLocation from './AllEventsCalendarEventWindowLocation';

import type { AllEventsCalendarEventWindowContent_event } from './__generated__/AllEventsCalendarEventWindowContent_event.graphql';

const Container = styled.div`
  margin: 17px 20px 0 24px;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Label = styled.div`
  margin-right: auto;
  word-break: break-word;
  font-size: 17px;
  text-decoration: none;
  color: #3e4859;
`;

const StyledLink = styled(Link)`
  margin-left: 10px;
`;

const CloseIcon = styled(Remove)`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin: 2px 0 0 25px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  position: relative;
  align-self: top;
  flex-shrink: 0;
  top: -1px;
  height: 25px;
  margin-right: 10px;
  color: #3e4858;
`;

const DateRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 13px;
`;

const HorizontalLine = styled.hr`
  display: block;
  height: 1px;
  margin: 0 -8px 20px 0;
  border-top: 1px solid #d8d8d8;
`;

const LeaderRow = styled.div`
  margin-bottom: 10px;
`;

class AllEventsCalendarEventWindowContent extends React.PureComponent<{
  slug: string,
  event: AllEventsCalendarEventWindowContent_event,
  linkEventNameToBrief?: boolean,
  resourceToken?: ?string,
  shownColumns?: $ReadOnlyArray<string>,
  location: Location,
  match: Match,
  onHide: () => void,
}> {
  componentDidMount = () => {
    this.context.scheduleUpdate();
  };

  getEventLink = (): ?{ link: string, target: '_self' | '_blank' } => {
    const { slug, linkEventNameToBrief, resourceToken } = this.props;

    if (linkEventNameToBrief === true) {
      return { link: `/events/${slug}/share/brief/${resourceToken || ''}`, target: '_blank' };
    }

    if (linkEventNameToBrief == null) {
      return { link: `/events/${slug}`, target: '_self' };
    }

    return null;
  };

  static contextType = OverlayContext;

  render() {
    const { event, location, match, shownColumns, onHide } = this.props;

    const eventLink = this.getEventLink();

    const eventLinkUrl = eventLink
      ? {
          pathname: eventLink.link,
          state: {
            from: match.params.list_id,
            fromUrl: `${location.pathname}${location.search}`,
          },
        }
      : '';

    return (
      <>
        <Container>
          <Header>
            <Label
              as={eventLinkUrl ? Link : undefined}
              to={eventLinkUrl}
              target={eventLink ? eventLink.target : ''}
            >
              {event.name}
            </Label>
            {eventLink && (
              <StyledLink target={eventLink.target} to={eventLinkUrl}>
                <Expand />
              </StyledLink>
            )}
            <CloseIcon onClick={onHide} />
          </Header>
          {(shownColumns == null ||
            (shownColumns.includes('start_date') && shownColumns.includes('end_date'))) && (
            <DateRow>
              <StyledCalendarIcon />
              {formatDateTimeRange({
                startDate: moment.tz(event.startDate, event.tz),
                endDate: event.endDate ? moment.tz(event.endDate, event.tz) : null,
                startDateAllDay: event.startDateAllDay,
                endDateAllDay: event.endDateAllDay,
              })}
            </DateRow>
          )}
          <AllEventsCalendarEventWindowLocation event={event} shownColumns={shownColumns} />
          {event.lead && (
            <>
              <HorizontalLine />
              {(shownColumns == null || shownColumns.includes('lead')) && (
                <LeaderRow>
                  Event Leader: <strong>{fullNameOfUser(event.lead)}</strong>
                </LeaderRow>
              )}
            </>
          )}
        </Container>
      </>
    );
  }
}

export default createFragmentContainer(
  withRouter(AllEventsCalendarEventWindowContent),
  graphql`
    fragment AllEventsCalendarEventWindowContent_event on Event {
      name
      startDate
      endDate
      startDateAllDay
      endDateAllDay
      tz
      lead {
        id
        firstName
        lastName
        email
      }
      ...AllEventsCalendarEventWindowLocation_event
    }
  `,
);
