/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import type {
  updateScheduleDayMutationVariables,
  updateScheduleDayMutationResponse,
} from './__generated__/updateScheduleDayMutation.graphql';

const mutation = graphql`
  mutation updateScheduleDayMutation($input: UpdateDayInScheduleInput!) {
    updateDayInSchedule(input: $input) {
      scheduleDay {
        id
        title
        date
      }
    }
  }
`;

export default function updateScheduleDay(
  scheduleDayId: string,
  args: {| title?: string, date?: ?string |},
): Promise<updateScheduleDayMutationResponse> {
  const variables: updateScheduleDayMutationVariables = {
    input: {
      scheduleDayId,
      ...args,
    },
  };
  const optimisticResponse = {
    updateDayInSchedule: {
      scheduleDay: {
        id: scheduleDayId,
        ...args,
      },
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
