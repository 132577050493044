/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateBriefTemplate, {
  type BriefTemplateMutationVariables,
} from 'graph/mutations/briefTemplate/updateBriefTemplate';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Sections from './Sections';

import type { TemplateContent_org } from './__generated__/TemplateContent_org.graphql';

const Container = styled.div`
  max-width: 700px;
`;

class TemplateContent extends React.PureComponent<{
  org: TemplateContent_org,
}> {
  handleTemplateAttributeChange = (input: BriefTemplateMutationVariables) => {
    updateBriefTemplate(this.props.org.briefTemplate.id, input).catch(showModernMutationError);
  };

  render() {
    return (
      <Container>
        <Sections
          org={this.props.org}
          onTemplateAttributeChange={this.handleTemplateAttributeChange}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  TemplateContent,
  graphql`
    fragment TemplateContent_org on Org {
      briefTemplate {
        id
      }
      ...Sections_org
    }
  `,
);
