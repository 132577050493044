/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ZoomLocationPicker_integrable$ref: FragmentReference;
export type ZoomLocationPicker_integrable = {|
  +id: string,
  +viewerCanConfigureIntegrations: boolean,
  +zoomSolutionInstances: {|
    +totalCount: number
  |},
  +zoomUsers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +zoomId: string,
        +firstName: string,
        +lastName: string,
        +email: string,
        +freePlan: boolean,
      |}
    |}>
  |},
  +$refType: ZoomLocationPicker_integrable$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ZoomLocationPicker_integrable",
  "type": "IntegrableInterface",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanConfigureIntegrations",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "zoomSolutionInstances",
      "name": "traySolutionInstances",
      "storageKey": "traySolutionInstances(solutionName:\"ZOOM\")",
      "args": [
        {
          "kind": "Literal",
          "name": "solutionName",
          "value": "ZOOM",
          "type": "SolutionName"
        }
      ],
      "concreteType": "TraySolutionInstanceRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "zoomUsers",
      "storageKey": null,
      "args": null,
      "concreteType": "ZoomUserRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ZoomUserRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ZoomUser",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "zoomId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "firstName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "lastName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "email",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "freePlan",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a9b881ebbc85aed32b860e5a45819faf';
module.exports = node;
