/* @flow */
import track from '../track';

type Args = {|
  orgId: string,
  orgName: string,
  eventId: string,
  eventName: string,
  teamId: string,
  teamName: string,
|};

const viewedContactForm = ({ orgId, orgName, eventId, eventName, teamId, teamName }: Args) => {
  track('viewed_contact_form', {
    org_id: orgId,
    org_name: orgName,
    event_id: eventId,
    event_name: eventName,
    team_id: teamId,
    team_name: teamName,
  });
};

export default viewedContactForm;
