/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import formatDateRange from 'utils/date/formatDateRange';

import { type CellPropsType } from 'components/budget/Table';

import { type EventCellPropsType } from './index';

import type { EventDate_contactEvent } from './__generated__/EventDate_contactEvent.graphql';

const EventDate = ({ contactEvent }: CellPropsType<EventCellPropsType<EventDate_contactEvent>>) => {
  const { startDate, endDate, tz } = contactEvent.node;
  return startDate && formatDateRange(moment.tz(startDate, tz), endDate && moment.tz(endDate, tz));
};

export default createFragmentContainer(
  EventDate,
  graphql`
    fragment EventDate_contactEvent on ContactEventEdge {
      node {
        startDate
        endDate
        tz
      }
    }
  `,
);
