/* @flow */
import moment from 'moment-timezone';

import { type DatesConfig } from './index';

const normalizeDatesConfig = (datesConfig: DatesConfig): DatesConfig => {
  const datesConfigCopy = { ...datesConfig };

  if (
    datesConfigCopy.startDate &&
    datesConfigCopy.endDate &&
    moment(datesConfigCopy.startDate).isAfter(datesConfigCopy.endDate)
  ) {
    Object.assign(datesConfigCopy, {
      startDate: datesConfigCopy.endDate,
      endDate: datesConfigCopy.startDate,
      hideStartTime: datesConfigCopy.hideEndTime,
      hideEndTime: datesConfigCopy.hideStartTime,
    });
  }

  if (
    datesConfigCopy.startDate &&
    datesConfigCopy.endDate &&
    datesConfigCopy.hideStartTime === datesConfigCopy.hideEndTime &&
    moment(datesConfigCopy.startDate).isSame(datesConfigCopy.endDate)
  ) {
    Object.assign(datesConfigCopy, {
      endDate: null,
      hideEndTime: true,
    });
  }

  return datesConfigCopy;
};

export default normalizeDatesConfig;
