/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import SelectField, { Field } from 'components/material/SelectField';

import InlineEditableCell from './InlineEditableCell';

const Root = styled.div`
  ${Field} {
    width: 100%;
    padding: 0 10px 0 20px;
  }
`;

const StyledSelectField = styled(SelectField)`
  display: flex;
`;

export default class InlineSelectField<T> extends React.PureComponent<
  {
    children?: React.Node,
    defaultEditing?: boolean,
    placeholder?: string,
    getError?: (value?: T) => ?string,
    updateColumnWidth?: () => void,
    disabled?: boolean,
    hideLockIcon?: boolean,
  } & $PropertyType<SelectField<T>, 'props'>,
  {
    error: ?string,
  },
> {
  state = { error: null };

  forceBlur: boolean = false;

  componentDidUpdate(prevProps: $PropertyType<InlineSelectField<T>, 'props'>) {
    const { clearable, value } = this.props;
    if (this.state.error && !clearable && value != null && prevProps.value == null) {
      this.forceBlur = true;
    }
  }

  handleClear = () => {
    this.props.onChange(null);
  };

  handleValidate = (value?: T): boolean => {
    const { getError, updateColumnWidth } = this.props;
    const error: ?string = getError != null ? getError(value) : null;
    this.setState(state => (state.error === error ? null : { error }));
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  render() {
    const activeOption = this.props.options.find(option => option.value === this.props.value);

    return (
      <Root>
        <InlineEditableCell
          placeholder={this.props.placeholder}
          defaultEditing={this.props.defaultEditing}
          onClear={this.props.clearable && this.props.value != null ? this.handleClear : null}
          onValidate={this.handleValidate}
          error={this.state.error}
          disabled={this.props.disabled}
          hideLockIcon={this.props.hideLockIcon}
        >
          {({ onBlur, editing }) => {
            if (!editing) {
              return this.props.children != null
                ? this.props.children
                : activeOption && activeOption.label;
            }

            if (this.forceBlur && onBlur) {
              onBlur();
              this.forceBlur = false;
            }

            return (
              <StyledSelectField
                {...this.props}
                clearable={false}
                overlayContainer={document.body}
                noBoxShadow
                autoFocus
                searchable
                onChange={value => {
                  this.props.onChange(value);
                }}
                onHideOptions={(value?: T) => {
                  if (this.handleValidate) {
                    // $FlowFixMe the same generic doesn't recognized by Flow
                    if (onBlur) onBlur(value);
                    if (this.props.onHideOptions) this.props.onHideOptions();
                  }
                }}
              />
            );
          }}
        </InlineEditableCell>
      </Root>
    );
  }
}
