/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type Match, type RouterHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import capitalize from 'lodash/capitalize';
import moment from 'moment';

import downloadedOrgEventView from 'utils/analytics/events/downloadedOrgEventView';
import currentOrigin from 'utils/currentOrigin';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import { searchToStringParam } from 'utils/routing/stringifyQueryParamValues';
import appSubdomain from 'utils/url/appSubdomain';

import createEventList, { type MutationInput } from 'graph/mutations/eventList/createEventList';
import updateEventList from 'graph/mutations/eventList/updateEventList';
import showModernMutationError from 'graph/utils/showModernMutationError';

import AddToCalendarIcon from 'images/addToCalendar.svg';
import ExportIcon from 'images/events/export.svg';
import SaveView from 'images/events/save_view.svg';
import SharedView from 'images/events/shared_view.svg';
import { getDefaultFilters, suggestedFields } from 'components/AllEvents/eventsTableColumnSettings';
import type {
  filtersForExportType,
  ParsedAllEventsFilters,
} from 'components/AllEvents/lib/parseAllEventsFilters';
import parseDateFilters from 'components/AllEvents/lib/parseDateFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ExternalCalendarExport from 'components/ExternalCalendarExport';
import type {
  FrequencyType,
  ScheduledOnType,
} from 'components/ScheduledReporting/ScheduledReportingForm';

import AllEventsShareViewWindow from './AllEventsShareViewWindow';
import EditViewWindowContent from './SavedEventListEditWindow/EditViewWindowContent';

import { type AllEventsViewActions_eventList } from './__generated__/AllEventsViewActions_eventList.graphql';
import { type AllEventsViewActions_org } from './__generated__/AllEventsViewActions_org.graphql';
import { type AllEventsViewActions_user } from './__generated__/AllEventsViewActions_user.graphql';
import { type AllEventsViewActionsQueryResponse } from './__generated__/AllEventsViewActionsQuery.graphql';

export type SchedulerType = ?{|
  frequency: FrequencyType,
  scheduledOn: ScheduledOnType,
  recipientIds: $ReadOnlyArray<string>,
|};

const Container = styled.div`
  display: flex;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 0;
  border-radius: 3px;
  font-size: 13px;
  line-height: 13px;
  color: #5a6576;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 15px;
  }

  &:nth-of-type(2) {
    margin-right: 15px;
    ${props =>
      props.active &&
      css`
        padding: 0 10px;
      `};
  }

  svg {
    margin-right: 10px;
  }

  ${props =>
    props.active
      ? css`
          background: rgba(255, 223, 109, 0.38);

          &:hover,
          &:focus {
            background: rgba(255, 223, 109, 0.48);
          }
        `
      : css`
          &:hover,
          &:focus {
            color: #373737;
          }
        `};

  @media (max-width: 580px) {
    svg {
      margin-right: 5px;
    }
  }
  @media (max-width: 450px) {
    position: relative;
    &:not(:last-child) {
      margin-right: 12px;
      &::after {
        content: '';
        position: absolute;
        right: -8px;
        background-color: #d8d8d8;
        width: 1px;
        height: 20px;
        margin-right: 2px;
      }
    }
    &:nth-of-type(2) {
      margin-right: 12px;
      ${props =>
        props.active &&
        css`
          padding: 0 4px;
        `};
    }

    svg {
      display: none;
    }
  }
`;

const StyledSharedView = styled(SharedView)`
  transform: scale(1.3);
  margin-left: 3px;
  margin-right: 13px;
  path {
    fill: #6e7884;
  }
`;

const query = graphql`
  query AllEventsViewActionsQuery($filters: EventFilters!, $columns: [String!]) {
    org {
      eventReportCSVProcessId(filters: $filters, columns: $columns)
    }
  }
`;

class AllEventsViewActions extends React.PureComponent<
  {
    eventList: ?AllEventsViewActions_eventList,
    org: AllEventsViewActions_org,
    user: AllEventsViewActions_user,
    csvExportVariables: filtersForExportType,
    shownColumns: $ReadOnlyArray<string>,
    filters: ParsedAllEventsFilters,
    eventFields: $ReadOnlyArray<FieldType>,
    history: RouterHistory,
    location: Location,
    match: Match,
    className?: ?string,
  },
  {
    showCSVDownloadOverlay: boolean,
    showShareViewWindow: boolean,
    showCalendarExportWindow: boolean,
    showConfirmationWindow: boolean,
  },
> {
  state = {
    showCSVDownloadOverlay: false,
    showShareViewWindow: false,
    showCalendarExportWindow: false,
    showConfirmationWindow: false,
  };

  handleCsvExport = () => {
    this.setState({ showCSVDownloadOverlay: true });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCSVDownloadOverlay: false });
    downloadedOrgEventView();
  };

  handleShowAddCalendarWindow = () => {
    this.setState({ showCalendarExportWindow: true });
  };

  handleHideAddCalendarWindow = () => {
    this.setState({ showCalendarExportWindow: false });
  };

  handleShowShareViewWindow = () => {
    this.setState({ showShareViewWindow: true });
  };

  handleHideShareViewWindow = () => {
    this.setState({ showShareViewWindow: false });
  };

  handleShowConfirmationWindow = () => {
    this.setState({ showConfirmationWindow: true });
  };

  handleHideConfirmationWindow = () => {
    this.setState({ showConfirmationWindow: false });
  };

  handleCreateView = (name: string, scheduler: SchedulerType) => {
    this.handleHideConfirmationWindow();
    createEventList(
      { name, ...(scheduler && { scheduler }), ...this.savedViewParams(false) },
      { userId: this.props.user.id },
    )
      .then(response => {
        if (response.createEventList) {
          this.props.history.push(`/dashboard/${response.createEventList.eventListsEdge.node.id}`);
        }
      })
      .catch(showModernMutationError);
  };

  handleUpdateView = (name: string, scheduler: ?SchedulerType, hasChanges: boolean) => {
    this.handleHideConfirmationWindow();

    if (!this.props.eventList) return;

    updateEventList({
      id: this.props.eventList.id,
      name,
      ...(hasChanges ? { scheduler } : {}),
      ...this.savedViewParams(true),
    }).catch(showModernMutationError);
  };

  getSortObject = () => {
    const {
      eventFields,
      filters: { sort },
    } = this.props;

    if (!sort) {
      return null;
    }

    if (eventFields.find(field => field.id === sort.key)) {
      return { customFieldSortId: sort.key, direction: sort.asc ? 'ASC' : 'DESC' };
    }

    return { sort: sort.key, direction: sort.asc ? 'ASC' : 'DESC' };
  };

  savedViewParams = (
    includeNullableFilters: boolean,
  ): $Exact<$Diff<MutationInput, { name: string }>> => {
    const { search, date, month, week, sort, ...filters } = this.props.filters;

    const defaultFilters = getDefaultFilters(filters);
    const { includeUndated, ...dateParams } = parseDateFilters(date);
    const customFilters = this.props.eventFields
      .filter(
        option =>
          (option.fieldName == null || suggestedFields.includes(option.fieldName)) &&
          (includeNullableFilters || filters[option.id] != null),
      )
      .map(option => getCustomFieldVariable(filters[option.id], option));

    return {
      shownColumns: this.props.shownColumns,
      search: searchToStringParam(search),
      customFilters,
      ...defaultFilters,
      listType: null,
      afterDate: null,
      beforeDate: null,
      ...dateParams,
      ...this.getSortObject(),
    };
  };

  renderCalendarExportWindow = () => {
    const { org, user, eventList, match } = this.props;
    const listTitle = eventList
      ? eventList.name
      : `${capitalize(match.params.list_id).split('_')[0]} ${org.name}`;
    const listToken = eventList ? eventList.token : match.params.list_id;
    // listToken is not null. (listToken || '') is for Flow
    const icsUrl = `${currentOrigin()}/v1/events_calendar/${listToken || ''}/${user.icsToken}`;
    return (
      <ExternalCalendarExport
        listTitle={`${listTitle} events`}
        icsUrl={icsUrl}
        onHide={this.handleHideAddCalendarWindow}
      />
    );
  };

  renderDownloadOverlay = () => {
    const { shownColumns, csvExportVariables } = this.props;
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          filters: csvExportVariables,
          columns: shownColumns,
        }}
        renderSuccess={({ org }: AllEventsViewActionsQueryResponse) => (
          <DownloadOverlayWithEmail
            processUUID={org.eventReportCSVProcessId}
            fileName={`Circa Events Exported ${moment().format('YYYY-MM-DD')}.csv`}
            onHide={this.handleCsvDownloadEnd}
            email={this.props.user.email}
            exportable="all_events_csv"
            onDownload={downloadedOrgEventView}
          />
        )}
        renderLoading={() => null}
      />
    );
  };

  handleOpenSharedList = () => {
    const baseUrl = `${currentOrigin()}/${appSubdomain() ? '' : `${this.props.org.slug}/`}`;
    window.open(
      `${baseUrl}${(this.props.eventList && this.props.eventList.publicResourceToken.token) || ''}`,
      '_blank',
    );
  };

  render() {
    const { eventList, org, user, shownColumns, location, history, className } = this.props;
    const {
      showConfirmationWindow,
      showShareViewWindow,
      showCSVDownloadOverlay,
      showCalendarExportWindow,
    } = this.state;
    const isShared = eventList && eventList.shared !== 'PRIVATE';

    return (
      <>
        <Container className={className}>
          {org.viewerCanCreateEventLists && (
            <ActionButton onClick={this.handleShowConfirmationWindow}>
              <SaveView />
              Save view
            </ActionButton>
          )}

          {org.viewerCanShareEventLists && (
            <ActionButton active={isShared} onClick={this.handleShowShareViewWindow}>
              <StyledSharedView />
              {isShared ? 'Shared view' : 'Share view'}
            </ActionButton>
          )}
          {!org.viewerCanShareEventLists && isShared && (
            <ActionButton active onClick={this.handleOpenSharedList}>
              <StyledSharedView />
              View Shared Page
            </ActionButton>
          )}
          <ActionButton onClick={this.handleShowAddCalendarWindow}>
            <AddToCalendarIcon />
            Add to calendar
          </ActionButton>
          <ActionButton onClick={this.handleCsvExport}>
            <ExportIcon />
            Export
          </ActionButton>
        </Container>
        {showCSVDownloadOverlay && this.renderDownloadOverlay()}
        {showCalendarExportWindow && this.renderCalendarExportWindow()}

        {showShareViewWindow && (
          <AllEventsShareViewWindow
            eventList={eventList || null}
            org={org}
            location={location}
            history={history}
            shownColumns={shownColumns}
            onHide={this.handleHideShareViewWindow}
            savedViewParams={this.savedViewParams(false)}
            userId={user.id}
          />
        )}

        {showConfirmationWindow && (
          <EditViewWindowContent
            exportScheduler={eventList && eventList.exportScheduler}
            name={eventList ? eventList.name : 'Saved view'}
            viewerCanUpdate={org.viewerCanUpdate}
            viewerCanUpdateEventList={eventList && eventList.viewerCanUpdate}
            onHide={this.handleHideConfirmationWindow}
            onUpdate={this.handleUpdateView}
            onSaveNew={this.handleCreateView}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(AllEventsViewActions, {
  eventList: graphql`
    fragment AllEventsViewActions_eventList on EventList {
      id
      name
      token
      shared
      viewerCanUpdate
      exportScheduler {
        frequency
        scheduledOn
        recipients {
          edges {
            node {
              id
              firstName
              lastName
              avatar
              email
            }
          }
        }
      }
      publicResourceToken {
        token
      }
      ...AllEventsShareViewWindow_eventList
    }
  `,
  user: graphql`
    fragment AllEventsViewActions_user on User {
      id
      email
      icsToken
    }
  `,
  org: graphql`
    fragment AllEventsViewActions_org on Org {
      viewerCanCreateEventLists
      viewerCanShareEventLists
      viewerCanUpdate
      slug
      name
      ...AllEventsShareViewWindow_org
    }
  `,
});
