/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import styled from 'styled-components';

import type { SalesforceId_event } from './__generated__/SalesforceId_event.graphql';

const Link = styled.a`
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    text-decoration: underline;
  }
`;

const stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
  e.stopPropagation();
};

const SalesforceId = (props: { event: SalesforceId_event }) => {
  const salesforceCampaign = props.event.salesforceCampaign;

  if (!salesforceCampaign) return null;

  return (
    <Link
      href={salesforceCampaign.campaignUrl}
      target="_blank"
      rel="noreferrer noopener"
      onClick={stopPropagation}
    >
      {salesforceCampaign.campaignSfdcid}
    </Link>
  );
};

export default createFragmentContainer(
  SalesforceId,
  graphql`
    fragment SalesforceId_event on Event {
      salesforceCampaign {
        campaignSfdcid
        campaignUrl
      }
    }
  `,
);
