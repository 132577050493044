/* @flow */
import * as React from 'react';

import { CompositeDecorator } from 'draft-js';

import Mention from './Mention';

type Props = {
  children: React.Node,
  offsetKey: string,
};

export default new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'MENTION';
      }, callback);
    },
    component: (props: Props) => {
      return (
        <Mention data-offset-key={props.offsetKey}>
          {props.children}
        </Mention>
      );
    },
  },
]);
