/* @flow */
import type { FieldType } from 'utils/customization/types';

export default function getContactFields(
  customFields: $ReadOnlyArray<FieldType>,
  { salesforceEnabled, marketoEnabled }: { salesforceEnabled: boolean, marketoEnabled: boolean },
  showEventContactFields?: boolean,
): $ReadOnlyArray<FieldType> {
  return [
    {
      id: 'name',
      label: 'Name',
      fieldName: 'name',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'email',
      label: 'Email',
      fieldName: 'email',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
      minWidth: 215,
    },
    ...(salesforceEnabled
      ? [
          {
            id: 'salesforce_id',
            label: 'Salesforce ID',
            fieldName: 'salesforce_id',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
          {
            id: 'salesforce_sync_as',
            label: 'Salesforce Sync As',
            fieldName: 'salesforce_sync_as',
            order: 0,
            kind: 'DEFAULT',
            required: true,
            options: [],
          },
          {
            id: 'salesforce_lead_status_id',
            label: 'Salesforce Lead Status',
            fieldName: 'salesforce_lead_status_id',
            order: 0,
            kind: 'DEFAULT',
            required: true,
            options: [],
          },
        ]
      : []),
    ...(marketoEnabled
      ? [
          {
            id: 'marketo_id',
            label: 'Marketo ID',
            fieldName: 'marketo_id',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
        ]
      : []),
    ...(salesforceEnabled || marketoEnabled
      ? [
          {
            id: 'sync_status',
            label: 'Sync Status',
            fieldName: 'sync_status',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
          {
            id: 'last_synced',
            label: 'Last Sync',
            fieldName: 'last_synced',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
          ...(showEventContactFields
            ? [
                {
                  id: 'membership_sync_status',
                  label: 'Membership Sync Status',
                  fieldName: 'membership_sync_status',
                  order: 0,
                  kind: 'DEFAULT',
                  required: false,
                  options: [],
                },
                {
                  id: 'membership_last_synced',
                  label: 'Membership Last Sync',
                  fieldName: 'membership_last_synced',
                  order: 0,
                  kind: 'DEFAULT',
                  required: false,
                  options: [],
                },
              ]
            : []),
        ]
      : []),
    {
      id: 'events',
      label: 'Events',
      fieldName: 'events',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'request_submissions',
      label: 'Request Submissions',
      fieldName: 'request_submissions',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    ...customFields.filter(customField => customField.customizableType === 'CONTACT'),
    ...(showEventContactFields
      ? [
          ...customFields.filter(customField => customField.customizableType === 'EVENTCONTACT'),
          {
            id: 'registered_at',
            label: 'Registration Date',
            fieldName: 'registered_at',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
          {
            id: 'attendance_join_time',
            label: 'Join Time',
            fieldName: 'attendance_join_time',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
          {
            id: 'attendance_leave_time',
            label: 'Leave Time',
            fieldName: 'attendance_leave_time',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
          {
            id: 'attendance_duration',
            label: 'Time in Session',
            fieldName: 'attendance_duration',
            order: 0,
            kind: 'DEFAULT',
            required: false,
            options: [],
          },
        ]
      : []),
    {
      id: 'updated_at',
      label: 'Updated At',
      fieldName: 'updated_at',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'updated_by',
      label: 'Updated By',
      fieldName: 'updated_by',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'updated_method',
      label: 'Updated Method',
      fieldName: 'updated_method',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'created_at',
      label: 'Created At',
      fieldName: 'created_at',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'created_by',
      label: 'Created By',
      fieldName: 'created_by',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'created_method',
      label: 'Created Method',
      fieldName: 'created_method',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
  ];
}
