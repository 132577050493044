/* @flow */
import { graphql } from 'react-relay';

import updateEdgeInConnection from 'graph/updaters/updateEdgeInConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type massUpdateContactsMutationVariables } from './__generated__/massUpdateContactsMutation.graphql';

const mutation = graphql`
  mutation massUpdateContactsMutation($input: MassUpdateContactsInput!) {
    massUpdateContacts(input: $input) {
      eventContactEdges {
        ...ContactRegistrationStatus_eventContact
        ...ContactAttendanceStatus_eventContact
        node {
          id
        }
      }
      contactEdges {
        node {
          ...ContactSalesforceSyncAs_contact
          ...ContactCompany_contact
          ...ContactOwner_contact
          ...ContactUpdater_contact
          ...ContactUpdatedAt_contact
          ...ContactUpdatedMethod_contact
          ...CustomizableSelect_customizable
          ...CustomizableUserSelect_customizable
        }
      }
    }
  }
`;

export default function updateContacts(
  input: $Exact<$PropertyType<massUpdateContactsMutationVariables, 'input'>>,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    updater: input.eventId
      ? updateEdgeInConnection({
          parentId: input.eventId,
          edgeName: 'eventContactEdges',
          connections: [{ key: 'ContactsTablePagination_eventContacts' }],
        })
      : null,
  });
}
