/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ImportLogsFilenameColumn_importLog$ref: FragmentReference;
export type ImportLogsFilenameColumn_importLog = {|
  +fileName: string,
  +$refType: ImportLogsFilenameColumn_importLog$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ImportLogsFilenameColumn_importLog",
  "type": "ImportLog",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileName",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd56ac23219f10c55e9fec6c19dae141a';
module.exports = node;
