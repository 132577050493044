/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import hash from 'string-hash';
import type { MaterialAvatar_user } from './__generated__/MaterialAvatar_user.graphql';

type StyledProps = {
  radius: number,
};

const Avatar = styled.div`
  height: ${(props: StyledProps) => props.radius * 2}px;
  width: ${(props: StyledProps) => props.radius * 2}px;
  font-size: ${(props: StyledProps) => props.radius}px;
  line-height: ${(props: StyledProps) => (props.radius + 1) * 2}px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

class MaterialAvatar extends React.PureComponent<{
  user: MaterialAvatar_user,
  radius: number,
  className?: string,
}> {
  static defaultProps = {
    radius: 28,
  };

  render() {
    const initial =
      [this.props.user.firstName[0], this.props.user.lastName[0]].filter(x => x).join('') ||
      (typeof this.props.user.email === 'string' ? this.props.user.email : ' ')[0];
    const bgStyle = {
      backgroundColor: `hsl(${hash(this.props.user.id) % 360}, 100%, 35%)`,
    };

    return this.props.user.avatar ? (
      <Avatar radius={this.props.radius} className={this.props.className}>
        <img src={this.props.user.avatar} alt={initial} />
      </Avatar>
    ) : (
      <Avatar radius={this.props.radius} className={this.props.className} style={bgStyle}>
        {initial}
      </Avatar>
    );
  }
}

export default createFragmentContainer(MaterialAvatar, {
  user: graphql`
    fragment MaterialAvatar_user on Assignable {
      id
      firstName
      lastName
      email
      avatar
    }
  `,
});
