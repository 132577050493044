/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  removeCampaignFromEventMutationResponse,
  removeCampaignFromEventMutationVariables,
} from './__generated__/removeCampaignFromEventMutation.graphql';

const mutation = graphql`
  mutation removeCampaignFromEventMutation($input: RemoveCampaignFromEventInput!) {
    removeCampaignFromEvent(input: $input) {
      event {
        salesforceCampaign {
          id
        }
        syncedContacts: eventContacts(filters: { membershipSyncStatuses: [OK] }) {
          totalCount
        }
        disabledContacts: eventContacts(filters: { membershipSyncStatuses: [DISABLED] }) {
          totalCount
        }
        failedContacts: eventContacts(filters: { membershipSyncStatuses: [FAILING] }) {
          totalCount
        }
        pendingContacts: eventContacts(filters: { membershipSyncStatuses: [PENDING] }) {
          totalCount
        }
      }
    }
  }
`;

export default function removeCampaignFromEvent(args: {
  eventId: string,
}): Promise<removeCampaignFromEventMutationResponse> {
  const variables: removeCampaignFromEventMutationVariables = {
    input: {
      ...args,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
