/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventInfoStatus_event$ref: FragmentReference;
export type EventInfoStatus_event = {|
  +commitmentLevel: EventStatus,
  +$refType: EventInfoStatus_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventInfoStatus_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "commitmentLevel",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '12ac6f1d7d8510baf114e71e3d78a1e4';
module.exports = node;
