/**
 * @flow
 * @relayHash 7191925c88af4ec8f253393b95fae9cd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
export type UpdateVideoComponentInput = {
  clientMutationId?: ?string,
  id: string,
  url?: ?string,
  width?: ?number,
  height?: ?number,
  padding?: ?string,
  autoplay?: ?boolean,
  alignment?: ?Alignment,
  backgroundColor?: ?string,
};
export type updateVideoComponentMutationVariables = {|
  input: UpdateVideoComponentInput
|};
export type updateVideoComponentMutationResponse = {|
  +updateRegistrationFormVideoComponent: ?{|
    +pageComponent: {|
      +videoComponent: ?{|
        +id: string,
        +url: ?string,
        +width: number,
        +height: number,
        +autoplay: boolean,
        +padding: string,
        +alignment: Alignment,
        +backgroundColor: ?string,
      |}
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type updateVideoComponentMutation = {|
  variables: updateVideoComponentMutationVariables,
  response: updateVideoComponentMutationResponse,
|};
*/


/*
mutation updateVideoComponentMutation(
  $input: UpdateVideoComponentInput!
) {
  updateRegistrationFormVideoComponent(input: $input) {
    pageComponent {
      videoComponent {
        id
        url
        width
        height
        autoplay
        padding
        alignment
        backgroundColor
      }
      id
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateVideoComponentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateVideoComponentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "videoComponent",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationVideoComponent",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "width",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "height",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "autoplay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "padding",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "alignment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundColor",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateVideoComponentMutation",
  "id": null,
  "text": "mutation updateVideoComponentMutation(\n  $input: UpdateVideoComponentInput!\n) {\n  updateRegistrationFormVideoComponent(input: $input) {\n    pageComponent {\n      videoComponent {\n        id\n        url\n        width\n        height\n        autoplay\n        padding\n        alignment\n        backgroundColor\n      }\n      id\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateVideoComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormVideoComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateVideoComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3
            ]
          },
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateVideoComponentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormVideoComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateVideoComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3,
              v2
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b1bbd798c51a80ad21f29bff436a89d';
module.exports = node;
