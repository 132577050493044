/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Button from 'components/material/Button';
import styled from 'styled-components';
import TransferEventModal from './components/TransferEventModal';

import type { TransferTeamSection_event } from './__generated__/TransferTeamSection_event.graphql';
import type { TransferTeamSection_me } from './__generated__/TransferTeamSection_me.graphql';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
`;

const Label = styled.div`
  color: ${props => props.theme.labelColor};
  font-size: 13px;
  margin-bottom: 6px;
`;

const CurrentTeamContent = styled.div`
  flex: 0 0 220px;
  padding-bottom: 3px;
  border-bottom: 1px dashed #d8d8d8;
  margin-right: 20px;
  font-size: 14px;
  opacity: 0.54;
  color: #4a5665;
`;
const ButtonStyled = styled(Button)`
  flex: initial;
`;

class TransferTeamSection extends React.Component<
  {
    event: TransferTeamSection_event,
    me: TransferTeamSection_me,
  },
  {
    showOverlay: boolean,
  },
> {
  state = {
    showOverlay: false,
  };

  handleHideModal = () => {
    this.setState({ showOverlay: false });
  };

  handleShowModal = () => {
    this.setState({ showOverlay: true });
  };

  render() {
    const { me, event } = this.props;
    const teams = me.teams.edges.filter(({ node }) => node.viewerCanCreateEvents);
    const transferDisabled: boolean = teams.length < 2;
    return (
      <Container>
        <CurrentTeamContent>
          <Label>Current team</Label>
          <div>{event.team.name}</div>
        </CurrentTeamContent>
        {event.viewerCanTransfer && (
          <div>
            <ButtonStyled
              disabled={transferDisabled}
              label="Transfer"
              primary
              onClick={this.handleShowModal}
            />
            {this.state.showOverlay && (
              <TransferEventModal event={event} teams={me.teams} onHide={this.handleHideModal} />
            )}
          </div>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  TransferTeamSection,
  graphql`
    fragment TransferTeamSection_event on Event {
      viewerCanTransfer
      id
      dbId
      name
      team {
        id
        name
      }
    }

    fragment TransferTeamSection_me on User {
      teams {
        edges {
          node {
            id
            name
            viewerCanCreateEvents
          }
        }
      }
    }
  `,
);
