/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled from 'styled-components';

import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
} from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import RequestSubmissionList from 'components/EventRequestForm/RequestSubmissionList';

import type { ContactRequestsQueryResponse } from './__generated__/ContactRequestsQuery.graphql';

const Container = styled.div`
  margin: 25px 0;
`;

const query = graphql`
  query ContactRequestsQuery(
    $contactId: ID!
    $sort: EventRequestFormSubmissionSort
    $direction: Direction!
  ) {
    me {
      tz
    }
    contact: node(id: $contactId) {
      ... on Contact {
        eventRequestSubmissions(sort: $sort, direction: $direction) {
          edges {
            node {
              ...RequestSubmissionList_requestSubmissions
            }
          }
        }
      }
    }
  }
`;

export default class ContactRequests extends React.Component<{
  contactId: string,
  location: Location,
  history: History,
}> {
  handleChangeSort = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort, 'requestSort');
  };

  sort = () => {
    return (
      parseTypedQueryString(this.props.location.search, {
        requestSort: stringParamToSort,
      }).requestSort || {
        key: 'REQUESTED_DATE',
        asc: false,
      }
    );
  };

  renderList = (response?: ContactRequestsQueryResponse) => {
    const requestSubmissions =
      response && response.contact && response.contact.eventRequestSubmissions
        ? response.contact.eventRequestSubmissions.edges.map(edge => edge.node)
        : null;

    return (
      <RequestSubmissionList
        requestSubmissions={requestSubmissions}
        sort={this.sort()}
        onChangeSort={this.handleChangeSort}
        tz={response ? response.me.tz : 'UTC'}
      />
    );
  };

  render() {
    const sort = this.sort();

    return (
      <Container>
        <DefaultQueryRenderer
          query={query}
          variables={{
            contactId: this.props.contactId,
            sort: sort.key,
            direction: sort.asc ? 'ASC' : 'DESC',
          }}
          renderSuccess={this.renderList}
          renderLoading={this.renderList}
        />
      </Container>
    );
  }
}
