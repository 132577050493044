/**
 * @flow
 * @relayHash 7e5503e7bacab46cbbf47c6a4e171812
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EventBriefAccessibility = "DISABLED" | "MEMBERS" | "PUBLIC" | "SSO";
export type UpdateBriefTemplateInput = {
  clientMutationId?: ?string,
  accessibility?: ?EventBriefAccessibility,
  headerSettings?: ?BriefHeaderConfigInput,
  sections?: ?$ReadOnlyArray<SortableSectionInput>,
  eventStaffFields?: ?$ReadOnlyArray<SimpleSectionInput>,
  taskFields?: ?$ReadOnlyArray<SimpleSectionInput>,
  scheduleFields?: ?$ReadOnlyArray<SimpleSectionInput>,
  personalizeSchedules?: ?boolean,
};
export type BriefHeaderConfigInput = {
  includeDates: boolean,
  includeWebsite: boolean,
  includeLocation: boolean,
};
export type SortableSectionInput = {
  id?: ?string,
  name?: ?string,
  order: number,
  enabled?: ?boolean,
};
export type SimpleSectionInput = {
  name: string,
  enabled: boolean,
};
export type updateBriefTemplateMutationVariables = {|
  input: UpdateBriefTemplateInput,
  changingAccessibility: boolean,
  changingHeaderSettings: boolean,
  changingSections: boolean,
  changingEventStaffFields: boolean,
  changingTaskFields: boolean,
  changingScheduleFields: boolean,
  changingPersonalizeSchedules: boolean,
|};
export type updateBriefTemplateMutationResponse = {|
  +updateBriefTemplate: ?{|
    +org: {|
      +id: string,
      +briefTemplate: {|
        +accessibility?: EventBriefAccessibility,
        +sections?: $ReadOnlyArray<{|
          +name: string,
          +order: number,
          +enabled: boolean,
        |}>,
        +headerSettings?: {|
          +includeDates: boolean,
          +includeLocation: boolean,
          +includeWebsite: boolean,
        |},
        +eventStaffFields?: $ReadOnlyArray<{|
          +name: string,
          +enabled: boolean,
        |}>,
        +taskFields?: $ReadOnlyArray<{|
          +name: string,
          +enabled: boolean,
        |}>,
        +scheduleFields?: $ReadOnlyArray<{|
          +name: string,
          +enabled: boolean,
        |}>,
        +personalizeSchedules?: boolean,
      |},
    |}
  |}
|};
export type updateBriefTemplateMutation = {|
  variables: updateBriefTemplateMutationVariables,
  response: updateBriefTemplateMutationResponse,
|};
*/


/*
mutation updateBriefTemplateMutation(
  $input: UpdateBriefTemplateInput!
  $changingAccessibility: Boolean!
  $changingHeaderSettings: Boolean!
  $changingSections: Boolean!
  $changingEventStaffFields: Boolean!
  $changingTaskFields: Boolean!
  $changingScheduleFields: Boolean!
  $changingPersonalizeSchedules: Boolean!
) {
  updateBriefTemplate(input: $input) {
    org {
      id
      briefTemplate {
        accessibility @include(if: $changingAccessibility)
        sections @include(if: $changingSections) {
          name
          order
          enabled
        }
        headerSettings @include(if: $changingHeaderSettings) {
          includeDates
          includeLocation
          includeWebsite
        }
        eventStaffFields @include(if: $changingEventStaffFields) {
          name
          enabled
        }
        taskFields @include(if: $changingTaskFields) {
          name
          enabled
        }
        scheduleFields @include(if: $changingScheduleFields) {
          name
          enabled
        }
        personalizeSchedules @include(if: $changingPersonalizeSchedules)
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateBriefTemplateInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingAccessibility",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingHeaderSettings",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingSections",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingEventStaffFields",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingTaskFields",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingScheduleFields",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingPersonalizeSchedules",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateBriefTemplateInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingAccessibility",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "accessibility",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enabled",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingSections",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sections",
      "storageKey": null,
      "args": null,
      "concreteType": "SortableSection",
      "plural": true,
      "selections": [
        v4,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "order",
          "args": null,
          "storageKey": null
        },
        v5
      ]
    }
  ]
},
v7 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingHeaderSettings",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "headerSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "BriefTemplateHeader",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "includeDates",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "includeLocation",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "includeWebsite",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v8 = [
  v4,
  v5
],
v9 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingEventStaffFields",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventStaffFields",
      "storageKey": null,
      "args": null,
      "concreteType": "SimpleSection",
      "plural": true,
      "selections": v8
    }
  ]
},
v10 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingTaskFields",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "taskFields",
      "storageKey": null,
      "args": null,
      "concreteType": "SimpleSection",
      "plural": true,
      "selections": v8
    }
  ]
},
v11 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingScheduleFields",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "scheduleFields",
      "storageKey": null,
      "args": null,
      "concreteType": "SimpleSection",
      "plural": true,
      "selections": v8
    }
  ]
},
v12 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingPersonalizeSchedules",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "personalizeSchedules",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateBriefTemplateMutation",
  "id": null,
  "text": "mutation updateBriefTemplateMutation(\n  $input: UpdateBriefTemplateInput!\n  $changingAccessibility: Boolean!\n  $changingHeaderSettings: Boolean!\n  $changingSections: Boolean!\n  $changingEventStaffFields: Boolean!\n  $changingTaskFields: Boolean!\n  $changingScheduleFields: Boolean!\n  $changingPersonalizeSchedules: Boolean!\n) {\n  updateBriefTemplate(input: $input) {\n    org {\n      id\n      briefTemplate {\n        accessibility @include(if: $changingAccessibility)\n        sections @include(if: $changingSections) {\n          name\n          order\n          enabled\n        }\n        headerSettings @include(if: $changingHeaderSettings) {\n          includeDates\n          includeLocation\n          includeWebsite\n        }\n        eventStaffFields @include(if: $changingEventStaffFields) {\n          name\n          enabled\n        }\n        taskFields @include(if: $changingTaskFields) {\n          name\n          enabled\n        }\n        scheduleFields @include(if: $changingScheduleFields) {\n          name\n          enabled\n        }\n        personalizeSchedules @include(if: $changingPersonalizeSchedules)\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateBriefTemplateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBriefTemplate",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateBriefTemplatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefTemplate",
                "storageKey": null,
                "args": null,
                "concreteType": "BriefTemplate",
                "plural": false,
                "selections": [
                  v3,
                  v6,
                  v7,
                  v9,
                  v10,
                  v11,
                  v12
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateBriefTemplateMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBriefTemplate",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateBriefTemplatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefTemplate",
                "storageKey": null,
                "args": null,
                "concreteType": "BriefTemplate",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v6,
                  v7,
                  v9,
                  v10,
                  v11,
                  v12
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f682200e4dfc0af649f6d7a3cf9d0eeb';
module.exports = node;
