/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import eventTabs from 'config/eventTabs';

import FeatureAccessContext from 'contexts/FeatureAccess';

import updateEventTabs from 'graph/mutations/event/updateEventTabs';

import Menu from 'components/page/Menu';
import MenuItem from 'components/page/MenuItem';
import TabsSettings from 'components/TabsSettings';

import type { EventMenu_event } from './__generated__/EventMenu_event.graphql';

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 0 30px 0 32px;
  height: 48px;
  border-bottom: 1px solid #eaebec;
  border-top-left-radius: 20px;
  background: #fff;
  box-shadow: 0px -2px 5px -3px rgb(0 0 0 / 14%);

  @media (${props => props.theme.mobileOnly}) {
    padding: 0 10px;
  }
`;

const MenuContainer = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

const StyledMenu = styled(Menu)`
  div& {
    padding: 0;
    border: 0;
  }
`;

const StyledTabsSettings = styled(TabsSettings)`
  margin-top: 8px;
`;

class EventMenu extends React.Component<{
  event: EventMenu_event,
  shownTabs: $ReadOnlyArray<string>,
  disabledTabs: $ReadOnlyArray<string>,
}> {
  handleTabsChange = (tabs: $ReadOnlyArray<string>) => {
    updateEventTabs(this.props.event.id, tabs);
  };

  static contextType = FeatureAccessContext;

  render() {
    const eventSlug = this.props.event.slug;
    const tabsToShow = this.props.shownTabs.filter(tab => !this.props.disabledTabs.includes(tab));
    const filteredTabs = eventTabs.filter(
      eventTab =>
        !['ROI', 'CONTACTS', 'REGISTRATION'].includes(eventTab.value) ||
        this.context.legacyFeatures,
    );

    return (
      <Container>
        <MenuContainer>
          <StyledMenu scrollable>
            {tabsToShow.includes('INFO') && (
              <MenuItem
                text="Info"
                icon="info"
                to={`/events/${eventSlug}/info`}
                activePattern="/events/(.*)/info"
              />
            )}
            {tabsToShow.includes('STAFF') && (
              <MenuItem
                text="Staff"
                icon="users"
                to={`/events/${eventSlug}/staff`}
                activePattern="/events/(.*)/staff"
              />
            )}
            {tabsToShow.includes('BUDGET') && (
              <MenuItem
                text="Budget"
                icon="usd"
                to={`/events/${eventSlug}/budget`}
                activePattern="/events/(.*)/budget"
              />
            )}
            {tabsToShow.includes('VENDORS') && (
              <MenuItem
                text="Vendors"
                icon="building"
                to={`/events/${eventSlug}/vendors`}
                activePattern="/events/(.*)/vendors"
              />
            )}
            {tabsToShow.includes('ROI') && (
              <MenuItem
                text="ROI"
                icon="line-chart"
                to={`/events/${eventSlug}/roi`}
                activePattern="/events/(.*)/roi"
              />
            )}
            {tabsToShow.includes('CONTACTS') && (
              <MenuItem
                text="Contacts"
                icon="user"
                to={`/events/${eventSlug}/contacts`}
                activePattern="/events/(.*)/contacts"
              />
            )}
            {tabsToShow.includes('REGISTRATION') && (
              <MenuItem
                text="Registration"
                icon="pencil-square-o"
                to={`/events/${eventSlug}/registration`}
                activePattern="/events/(.*)/registration"
              />
            )}
            {tabsToShow.includes('CHECKLIST') && (
              <MenuItem
                text="Checklist"
                icon="list-ul"
                to={`/events/${eventSlug}/tasks`}
                activePattern="/events/(.*)/tasks"
              />
            )}
            {tabsToShow.includes('SCHEDULE') && (
              <MenuItem
                text="Schedule"
                icon="clock-o"
                to={`/events/${eventSlug}/schedules`}
                activePattern="/events/(.*)/schedules"
              />
            )}
            {tabsToShow.includes('ATTACHMENTS') && (
              <MenuItem
                text="Attachments"
                icon="paperclip"
                to={`/events/${eventSlug}/attachments`}
                activePattern="/events/(.*)/attachments"
              />
            )}
            {tabsToShow.includes('NOTES') && (
              <MenuItem
                text="Notes"
                icon="sticky-note-o"
                to={`/events/${eventSlug}/notes`}
                activePattern="/events/(.*)/notes"
              />
            )}
          </StyledMenu>
        </MenuContainer>
        {this.props.event.viewerCanUpdate && (
          <StyledTabsSettings
            tabs={filteredTabs}
            shownTabs={this.props.shownTabs}
            onTabsChange={this.handleTabsChange}
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  EventMenu,
  graphql`
    fragment EventMenu_event on Event {
      id
      slug
      viewerCanUpdate
    }
  `,
);
