/* @flow */
import React from 'react';
import styled from 'styled-components';

import blockedByUpgradePrompt from 'utils/analytics/blockedByUpgradePrompt';

import ProgressBar from 'components/billing/BillingProgressBar';

const Container = styled.div`
  margin: -10px -25px 15px;
  padding: 18px 100px;
  background-color: #fef2f3;
  font-size: 13px;
`;

const InfoLine = styled.div`
  margin-top: 10px;
  color: #3e4859;
  text-align: center;

  a {
    color: #39a8da;
    &:hover {
      color: #009dce;
      text-decoration: underline;
    }
  }
`;

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default class UsersImportLimitError extends React.PureComponent<{
  limit: number,
  fromWindow: 'team' | 'org members' | 'easy_button',
}> {
  componentDidMount() {
    blockedByUpgradePrompt(this.props.fromWindow);
  }

  render() {
    return (
      <Container>
        <ProgressBarContainer>
          <ProgressBar
            limit={this.props.limit}
            used={this.props.limit}
            label="Team Members"
            labelPlacement="before"
          />
        </ProgressBarContainer>
        <InfoLine>
          You&apos;ve reached the Team Member limit included in your subscription
          <br />
          <a href="mailto:success@circa.co" rel="noopener noreferrer" target="_blank">
            Contact us to add Team Members
          </a>
        </InfoLine>
      </Container>
    );
  }
}
