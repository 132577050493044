/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import getLinkError from 'utils/getLinkError';

import type { VendorFieldInput } from 'graph/mutations/vendor/updateVendor';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import VendorTwitter_vendor from './__generated__/VendorTwitter_vendor.graphql';

class VendorTwitter extends React.Component<{
  vendor: VendorTwitter_vendor,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<VendorFieldInput>) => void,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?string) => {
    const linkValue = value ? enforceHttpPrefix(value.trim()) : null;
    if (!this.getError(linkValue) && linkValue !== this.props.vendor.twitter) {
      this.props.onUpdate({ twitter: linkValue });
      this.props.updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    return getLinkError(value, this.props.fieldSettings.required);
  };

  render() {
    const { vendor, updateColumnWidth } = this.props;
    return (
      <InlineTextField
        placeholder="Ex: https://www.example.com"
        value={vendor.twitter || ''}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        onChange={this.handleUpdate}
      >
        {vendor.twitter && (
          <InlineExpandableText>
            <a
              onMouseDown={this.handleMouseDown}
              href={vendor.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              {vendor.twitter}
            </a>
          </InlineExpandableText>
        )}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  VendorTwitter,
  graphql`
    fragment VendorTwitter_vendor on Vendor {
      twitter
    }
  `,
);
