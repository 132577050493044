/**
 * @flow
 * @relayHash f91013a2090be7f18559b01d2f5890ed
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Direction = "ASC" | "DESC";
export type EventFormat = "HYBRID" | "PHYSICAL" | "VIRTUAL";
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventRegistrationFormStatus = "DRAFT" | "NOT_AVAILABLE" | "PUBLISHED";
export type EventRequestSubmissionStatusFilter = "APPROVED" | "DECLINED" | "NEW" | "N_A" | "RESUBMITTED" | "RETURNED";
export type EventSort = "ATTENDED_CONTACTS_TOTAL" | "BOOTH" | "BOOTH_DIMENSIONS" | "CITY" | "COMMITMENT_LEVEL" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATOR" | "CUSTOM" | "END_DATE" | "EVENT_FORMAT" | "EVENT_LEAD" | "LAST_SYNCED" | "LOCATION" | "MARKETO_ID" | "NAME" | "OPPORTUNITIES_AMOUNT" | "OPPORTUNITIES_NUMBER" | "PROGRESS" | "REGISTERED_CONTACTS_TOTAL" | "REQUESTED_BY" | "REQUESTED_DATE" | "REQUESTED_FORM_NAME" | "REQUESTED_STATUS" | "SALESFORCE_ID" | "STAFF_COUNT" | "START_DATE" | "STATE" | "STREET" | "SYNC_STATUS" | "TEAM_NAME" | "TEMPLATES_FIRST" | "TOTAL_ACTUAL_COST" | "TOTAL_PAID_COST" | "TOTAL_PLANNED_COST" | "UPDATED_AT" | "UPDATER" | "VENUE_NAME" | "VIRTUAL_LOCATION" | "WEBSITE" | "ZIP_CODE";
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type EventFilters = {
  sort?: ?EventSort,
  direction?: ?Direction,
  customFieldSortId?: ?string,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  queries?: ?$ReadOnlyArray<string>,
  search?: ?Search,
  listType?: ?EventListEnum,
  afterDate?: ?any,
  beforeDate?: ?any,
  includeUndated?: ?boolean,
  excludeTemplates?: ?boolean,
  statuses?: ?$ReadOnlyArray<EventStatus>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  opportunitiesNumber?: ?NumberRangeInput,
  opportunitiesAmount?: ?NumberRangeInput,
  teamIds?: ?$ReadOnlyArray<string>,
  leadIds?: ?$ReadOnlyArray<string>,
  staffIds?: ?$ReadOnlyArray<string>,
  budgetCategoryIds?: ?$ReadOnlyArray<string>,
  fullAccessEvents?: ?boolean,
  onlyWithTasks?: ?boolean,
  venueNames?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  eventFormats?: ?$ReadOnlyArray<EventFormat>,
  eventIds?: ?$ReadOnlyArray<string>,
  excludeEventIds?: ?$ReadOnlyArray<string>,
  updatedAt?: ?DateTimeRange,
  updaterIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  creatorIds?: ?$ReadOnlyArray<string>,
  requesterUserIds?: ?$ReadOnlyArray<string>,
  requesterContactIds?: ?$ReadOnlyArray<string>,
  requestedDate?: ?DateTimeRange,
  requestStatuses?: ?$ReadOnlyArray<EventRequestSubmissionStatusFilter>,
  requestFormIds?: ?$ReadOnlyArray<string>,
  requestReviewers?: ?$ReadOnlyArray<string>,
  registeredContactsTotal?: ?NumberRangeInput,
  attendedContactsTotal?: ?NumberRangeInput,
  registrationFormStatuses?: ?$ReadOnlyArray<EventRegistrationFormStatus>,
  contactsCount?: ?NumberRangeInput,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type TemplateSearchQueryVariables = {|
  filters: EventFilters,
  includeFolders: boolean,
|};
export type TemplateSearchQueryResponse = {|
  +me: {|
    +events: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +dbId: number,
          +name: string,
          +startDate: ?any,
          +team: {|
            +name: string
          |},
          +folders?: {|
            +edges: $ReadOnlyArray<{|
              +node: {|
                +dbId: number,
                +name: string,
              |}
            |}>
          |},
        |}
      |}>
    |}
  |}
|};
export type TemplateSearchQuery = {|
  variables: TemplateSearchQueryVariables,
  response: TemplateSearchQueryResponse,
|};
*/


/*
query TemplateSearchQuery(
  $filters: EventFilters!
  $includeFolders: Boolean!
) {
  me {
    events(first: 15, filters: $filters) {
      edges {
        node {
          id
          dbId
          name
          startDate
          team {
            name
            id
          }
          folders @include(if: $includeFolders) {
            edges {
              node {
                dbId
                name
                id
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "EventFilters!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeFolders",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters",
    "type": "EventFilters"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 15,
    "type": "Int"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startDate",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TemplateSearchQuery",
  "id": null,
  "text": "query TemplateSearchQuery(\n  $filters: EventFilters!\n  $includeFolders: Boolean!\n) {\n  me {\n    events(first: 15, filters: $filters) {\n      edges {\n        node {\n          id\n          dbId\n          name\n          startDate\n          team {\n            name\n            id\n          }\n          folders @include(if: $includeFolders) {\n            edges {\n              node {\n                dbId\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TemplateSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "events",
            "storageKey": null,
            "args": v1,
            "concreteType": "EventRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v4,
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "team",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Team",
                        "plural": false,
                        "selections": [
                          v4
                        ]
                      },
                      {
                        "kind": "Condition",
                        "passingValue": true,
                        "condition": "includeFolders",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "folders",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "FolderRequiredConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "FolderRequiredEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Folder",
                                    "plural": false,
                                    "selections": [
                                      v3,
                                      v4
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TemplateSearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "events",
            "storageKey": null,
            "args": v1,
            "concreteType": "EventRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v4,
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "team",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Team",
                        "plural": false,
                        "selections": [
                          v4,
                          v2
                        ]
                      },
                      {
                        "kind": "Condition",
                        "passingValue": true,
                        "condition": "includeFolders",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "folders",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "FolderRequiredConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "FolderRequiredEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Folder",
                                    "plural": false,
                                    "selections": [
                                      v3,
                                      v4,
                                      v2
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d2ef68f1da76493562e689d6d968956';
module.exports = node;
