/**
 * @flow
 * @relayHash 5d149b3904e3186670cd146ceaf430be
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveBudgetCategoryInput = {
  clientMutationId?: ?string,
  categoryId: string,
};
export type removeBudgetCategoryMutationVariables = {|
  input: RemoveBudgetCategoryInput
|};
export type removeBudgetCategoryMutationResponse = {|
  +removeBudgetCategory: ?{|
    +removedCategoryId: string
  |}
|};
export type removeBudgetCategoryMutation = {|
  variables: removeBudgetCategoryMutationVariables,
  response: removeBudgetCategoryMutationResponse,
|};
*/


/*
mutation removeBudgetCategoryMutation(
  $input: RemoveBudgetCategoryInput!
) {
  removeBudgetCategory(input: $input) {
    removedCategoryId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveBudgetCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeBudgetCategory",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveBudgetCategoryInput!"
      }
    ],
    "concreteType": "RemoveBudgetCategoryPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedCategoryId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeBudgetCategoryMutation",
  "id": null,
  "text": "mutation removeBudgetCategoryMutation(\n  $input: RemoveBudgetCategoryInput!\n) {\n  removeBudgetCategory(input: $input) {\n    removedCategoryId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeBudgetCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeBudgetCategoryMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c8c7ec0ed4d76bddfb06fe0eac459b4f';
module.exports = node;
