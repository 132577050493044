/* @flow */
import * as React from 'react';
import {
  type ConnectDragPreview,
  type ConnectDragSource,
  type ConnectDropTarget,
  type DragSourceConnector,
  type DragSourceMonitor,
  type DropTargetConnector,
  type DropTargetMonitor,
  DragSource,
  DropTarget,
} from 'react-dnd';
import styled, { css } from 'styled-components';

import DragIcon from 'images/drag-icon.svg';

import type { QuestionType, ReorderedQuestionType } from '../lib/types';

const StyledDragIcon = styled(DragIcon)`
  position: absolute;
  top: ${props => (props.editing ? 70 : 20)}px;
  left: 20px;
  z-index: 1;
  width: 18px;
  height: 25px;
  cursor: grab;
  visibility: hidden;
  opacity: 0.42;
  transition: 0.2s;
  &:hover {
    visibility: visible;
    opacity: 1;
  }
`;

const DropAreaMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  word-break: break-word;
  color: #3e4859;
  visibility: hidden;
  text-align: center;
`;

const Container = styled.div`
  position: relative;
  &:hover {
    ${StyledDragIcon} {
      visibility: visible;
    }
  }

  ${props =>
    props.hovered &&
    !props.dragged &&
    css`
      background-color: ${props.restrictedDruggingZone ? '#ffffff' : '#f1f9fc'};
      border: 1px dashed #5db5d5;
      border-image: url('/images/component_hover_border.png') 1 round;
      transition: 0.2s;
      color: #3ba9da;

      ${props.restrictedDruggingZone &&
      css`
        > * {
          visibility: hidden;
        }
        ${DropAreaMessage} {
          visibility: visible;
        }
      `};
    `};
`;

const DragLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

class EditableQuestionDraggable extends React.PureComponent<{
  editing: boolean,
  children: React.Node,
  connectDragSource?: ConnectDragSource,
  connectDragPreview?: ConnectDragPreview,
  connectDropTarget?: ConnectDropTarget,
  hovered?: boolean,
  dragged?: boolean,
  restrictedDruggingZone: boolean,
  /* eslint-disable react/no-unused-prop-types */
  // Used in Hover handler
  question: QuestionType,
  draggingQuestion: ?QuestionType,
  onMove: (sourceQuestion: ReorderedQuestionType) => void,
  onMoveEnd: (reorderedQuestionType: ReorderedQuestionType) => void,
  onBeginDrag?: (question: QuestionType) => void,
}> {
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      question,
      draggingQuestion,
      hovered,
      dragged,
      restrictedDruggingZone,
    } = this.props;

    if (!connectDragSource || !connectDropTarget || !connectDragPreview) return null;

    return connectDropTarget(
      <div>
        {connectDragPreview(
          <div>
            <Container
              hovered={
                hovered &&
                draggingQuestion &&
                (draggingQuestion.sectionId === question.sectionId ||
                  draggingQuestion.mapping !== 'NAME')
              }
              dragged={dragged}
              restrictedDruggingZone={restrictedDruggingZone}
            >
              {hovered && <DragLayer />}
              {this.props.children}
              {connectDragSource(
                <div>
                  <StyledDragIcon editing={this.props.editing} />
                  <DropAreaMessage>
                    This question cannot be moved here because of logical dependencies it has.
                  </DropAreaMessage>
                </div>,
              )}
            </Container>
          </div>,
        )}
      </div>,
    );
  }
}

const ConnectedDraggableComponent = DragSource(
  'QUESTION',
  {
    beginDrag(props: $PropertyType<EditableQuestionDraggable, 'props'>) {
      if (props.onBeginDrag) {
        props.onBeginDrag(props.question);
      }
      return { ...props.question };
    },
    endDrag(props: $PropertyType<EditableQuestionDraggable, 'props'>, monitor: DragSourceMonitor) {
      const dropAreaConfig = monitor.getDropResult();
      props.onMoveEnd(
        dropAreaConfig && {
          question: props.question,
          targetSection: dropAreaConfig.targetSection,
          targetQuestion: dropAreaConfig.targetQuestion,
        },
      );
      this.lastQuestionId = null;
    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    dragged: monitor.isDragging(),
  }),
)(EditableQuestionDraggable);

const ConnectedDroppableComponent = DropTarget(
  'QUESTION',
  {
    drop(props) {
      return { targetQuestion: props.question };
    },
    hover(props: $PropertyType<EditableQuestionDraggable, 'props'>, monitor: DropTargetMonitor) {
      const draggingQuestion: QuestionType = monitor.getItem();
      if (
        draggingQuestion.sectionId !== props.question.sectionId &&
        draggingQuestion.mapping === 'NAME'
      ) {
        return;
      }

      if (
        draggingQuestion.order === props.question.order &&
        draggingQuestion.sectionId === props.question.sectionId
      ) {
        return;
      }

      props.onMove({
        question: draggingQuestion,
        targetQuestion: props.question,
      });
    },
  },
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
  }),
)(ConnectedDraggableComponent);

export default ConnectedDroppableComponent;
