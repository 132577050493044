/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateDividerComponent, {
  type updateDividerComponentPropertyType,
} from 'graph/mutations/registration/updateDividerComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultTextColor } from '../../registrationFormDefaults';
import PropertyRow from '../components/PropertyRow';
import RegistrationColorSelector from '../components/RegistrationColorSelector';
import { Section } from '.';
import { BlockingLayer, PaddingComponent } from './components';
import SizeField from './components/SizeField';

import DividerComponent_org from './__generated__/DividerComponent_org.graphql';
import DividerComponent_selectedPageComponent from './__generated__/DividerComponent_selectedPageComponent.graphql';

const Container = styled.div`
  position: relative;
`;

const StyledSizeField = styled(SizeField)`
  width: 75px;
`;

class DividerComponent extends React.PureComponent<{
  org: DividerComponent_org,
  selectedPageComponent: DividerComponent_selectedPageComponent,
  onChangeComponentProperty: (updatedProps: SelectedComponent) => void,
}> {
  updateDividerComponent = (properties: updateDividerComponentPropertyType) => {
    updateDividerComponent({
      ...properties,
      id: this.props.selectedPageComponent.dividerComponent.id,
    }).catch(showModernMutationError);
  };

  handleSavePadding = (padding: string) => {
    this.updateDividerComponent({ padding });
  };

  handleChangeColor = (color: string) => {
    this.updateDividerComponent({ color });
  };

  handleSaveHeight = (height: number) => {
    this.updateDividerComponent({ height });
  };

  handleChangeHeight = (height: number) => {
    this.props.onChangeComponentProperty({ selectedComponentHeight: height });
  };

  render() {
    const {
      org,
      onChangeComponentProperty,
      selectedPageComponent: { id, dividerComponent },
    } = this.props;

    return (
      <Container>
        {id === 'new' && <BlockingLayer />}
        <Section>
          <PropertyRow label="Height">
            <StyledSizeField
              value={dividerComponent.height}
              onSave={this.handleSaveHeight}
              onChange={this.handleChangeHeight}
            />
          </PropertyRow>
          <PropertyRow label="Color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={dividerComponent.color || defaultTextColor}
              onChangeColor={this.handleChangeColor}
              alignTo="bottom-right"
            />
          </PropertyRow>
          <PropertyRow label="Padding">
            <PaddingComponent
              paddingString={dividerComponent.padding}
              onSave={this.handleSavePadding}
              onChange={onChangeComponentProperty}
            />
          </PropertyRow>
        </Section>
      </Container>
    );
  }
}

export default createFragmentContainer(DividerComponent, {
  org: graphql`
    fragment DividerComponent_org on Org {
      id
      settings {
        ...RegistrationColorSelector_orgSettings
      }
    }
  `,
  selectedPageComponent: graphql`
    fragment DividerComponent_selectedPageComponent on RegistrationPageComponent {
      id
      kind
      dividerComponent {
        id
        padding
        height
        color
      }
    }
  `,
});
