/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import RequestSubmissionFormName from 'components/EventRequestForm/RequestSubmissionList/columns/RequestSubmissionFormName';

import type { EventRequestsItemFormName_eventRequestSubmission } from './__generated__/EventRequestsItemFormName_eventRequestSubmission.graphql';

const StyledLink = styled(Link)`
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

class EventRequestsItemFormName extends React.PureComponent<{
  eventRequestSubmission: EventRequestsItemFormName_eventRequestSubmission,
  viewerCanManageRequestForms: boolean,
  onColumnLoad?: (value: string, str: string, extraSpace?: number) => void,
  name: string,
}> {
  componentDidMount() {
    const { name, eventRequestSubmission, onColumnLoad } = this.props;
    if (onColumnLoad) {
      onColumnLoad(name, eventRequestSubmission.form.name);
    }
  }

  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  render() {
    const { eventRequestSubmission, viewerCanManageRequestForms } = this.props;

    const content = <RequestSubmissionFormName requestSubmission={eventRequestSubmission} />;

    if (!viewerCanManageRequestForms) {
      return <InlineExpandableText>{content}</InlineExpandableText>;
    }

    return (
      <StyledLink
        to={`/settings/requests/${eventRequestSubmission.form.id}`}
        onClick={this.stopPropagation}
      >
        <InlineExpandableText>{content}</InlineExpandableText>
      </StyledLink>
    );
  }
}

export default createFragmentContainer(
  EventRequestsItemFormName,
  graphql`
    fragment EventRequestsItemFormName_eventRequestSubmission on EventRequestSubmission {
      ...RequestSubmissionFormName_requestSubmission

      form {
        id
        name
      }
    }
  `,
);
