/* @flow */
import { graphql } from 'react-relay';

import type { UpdateUserFromWindow } from 'graph/mutations/user/updateUser';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  removeUserMutationResponse,
  removeUserMutationVariables,
} from './__generated__/removeUserMutation.graphql';

const mutation = graphql`
  mutation removeUserMutation($input: RemoveUserInput!) {
    removeUser(input: $input) {
      clientMutationId
    }
  }
`;

export default function removeUser(
  userId: string,
  fromWindow: UpdateUserFromWindow,
): Promise<removeUserMutationResponse> {
  const variables: removeUserMutationVariables = {
    input: {
      userId,
      fromWindow: fromWindow.toLowerCase(),
    },
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
