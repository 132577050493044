/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import FormSection from 'components/EventRequestForm/FormSection';

import SubmissionReviewFormValue from './SubmissionReviewFormValue';

import type { SubmissionReviewFormQuestions_org } from './__generated__/SubmissionReviewFormQuestions_org.graphql';
import type { SubmissionReviewFormQuestions_submission } from './__generated__/SubmissionReviewFormQuestions_submission.graphql';

const Container = styled.div`
  padding: 15px 70px 15px 45px;
  border-radius: 4px;
  background: #fff;
  counter-reset: question-counter;
`;

const QuestionContainer = styled.div`
  display: flex;
  padding: 15px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }
`;

const SectionContainer = styled.div`
  position: relative;
`;

const FormSectionContainer = styled.div`
  > div {
    margin: 30px 0 28px 10px;
    font-size: 13px;
  }
`;

const Question = styled.div`
  margin-bottom: 5px;
  color: rgba(74, 86, 101, 0.87);
`;

const QuestionMain = styled.div`
  flex: 1 1 auto;
`;

const Number = styled.div`
  width: 26px;
  margin-right: 17px;
  text-align: right;
  font-weight: 500;
  color: #4a5665;
  counter-increment: question-counter;
  &:before {
    content: counter(question-counter) '.';
  }
`;

const SectionNumber = styled.div`
  position: absolute;
  left: -45px;
  width: 45px;
  padding: 2px 4px;
  background-color: #f1f9fc;
  text-align: right;
  color: #4a5665;
  font-size: 13px;
  font-weight: bold;
`;

class SubmissionReviewFormQuestions extends React.PureComponent<{
  org: SubmissionReviewFormQuestions_org,
  submission: SubmissionReviewFormQuestions_submission,
  currency: string,
}> {
  render() {
    const { org, submission, currency } = this.props;
    const filteredSections = submission.form.sections.edges.filter(({ node }) =>
      Boolean(
        node.questions.edges.length ||
          node.title ||
          node.helpText ||
          (node.description && node.description.trim()),
      ),
    );
    return (
      <Container>
        {sortBy(
          filteredSections.map(edge => edge.node),
          'order',
        ).map(
          (section, index, array) =>
            section.questions.edges.length > 0 && (
              <SectionContainer key={section.id}>
                {array.length > 1 && (
                  <SectionNumber>
                    {index + 1}/{array.length}
                  </SectionNumber>
                )}
                {(section.title || section.helpText || section.description) && (
                  <FormSectionContainer>
                    <FormSection
                      title={section.title}
                      helpText={section.helpText}
                      description={section.description}
                      fontSize={14}
                      isPreview
                    />
                  </FormSectionContainer>
                )}
                {sortBy(
                  section.questions.edges.map(edge => edge.node),
                  'order',
                ).map(question => (
                  <QuestionContainer key={question.id}>
                    <Number />
                    <QuestionMain>
                      <Question>
                        {question.name} {question.required && '*'}
                      </Question>
                      <SubmissionReviewFormValue
                        org={org}
                        submission={submission}
                        question={question}
                        currency={currency}
                      />
                    </QuestionMain>
                  </QuestionContainer>
                ))}
              </SectionContainer>
            ),
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  SubmissionReviewFormQuestions,
  graphql`
    fragment SubmissionReviewFormQuestions_org on Org {
      ...SubmissionReviewFormValue_org
    }

    fragment SubmissionReviewFormQuestions_submission on EventRequestSubmission {
      id
      status
      ...SubmissionReviewFormValue_submission
      form {
        sections {
          edges {
            node {
              id
              title
              helpText
              description
              order
              questions {
                edges {
                  node {
                    id
                    order
                    name
                    required
                    ...SubmissionReviewFormValue_question
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
);
