/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import updateColumnComponent from 'graph/mutations/registration/updateColumnComponent';
import updateRowComponent, {
  type updateRowComponentPropertyType,
} from 'graph/mutations/registration/updateRowComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultBackgroundColor } from '../../registrationFormDefaults';
import PropertyRow from '../components/PropertyRow';
import RegistrationColorSelector from '../components/RegistrationColorSelector';
import { Section } from '.';
import { BlockingLayer, PaddingComponent, SizeField } from './components';

import { type RowComponent_org } from './__generated__/RowComponent_org.graphql';
import { type RowComponent_selectedPageComponent } from './__generated__/RowComponent_selectedPageComponent.graphql';

const Container = styled.div`
  position: relative;
`;

const StyledSizeField = styled(SizeField)`
  width: 65px;
`;

const SectionLabel = styled.div`
  margin-bottom: 23px;
  line-height: 1em;
  font-weight: bold;
  color: #29305d;
`;

class RowComponent extends React.PureComponent<
  {
    org: RowComponent_org,
    selectedPageComponent: RowComponent_selectedPageComponent,
    onChangeComponentProperty: (updatedProps: SelectedComponent) => void,
  },
  {
    leftColumnWidth: ?number,
  },
> {
  state = { leftColumnWidth: null };

  updateRowComponent = (properties: updateRowComponentPropertyType) => {
    const rowComponent = this.props.selectedPageComponent.rowComponent;
    if (!rowComponent) {
      return;
    }
    updateRowComponent({
      ...properties,
      id: rowComponent.id,
    }).catch(showModernMutationError);
  };

  handleSavePadding = (padding: string) => {
    this.updateRowComponent({ padding });
  };

  handleSaveCellSpacing = (cellSpacing: number) => {
    this.updateRowComponent({ cellSpacing });
  };

  handleChangeBackgroundColor = (backgroundColor: string) => {
    this.updateRowComponent({ backgroundColor });
  };

  handleSaveLeftWidth = (width: number) => {
    const childPageComponents = this.props.selectedPageComponent.childPageComponents;
    if (!childPageComponents) {
      return;
    }
    const columnPageComponentNodes = sortBy(
      childPageComponents.edges
        .map(({ node }) => node)
        .filter(component => component.kind === 'COLUMN'),
      'order',
    );
    if (!columnPageComponentNodes[0].columnComponent) {
      return;
    }
    updateColumnComponent({
      width,
      id: columnPageComponentNodes[0].columnComponent.id,
    }).catch(showModernMutationError);
  };

  handleSaveLeftPadding = (padding: string) => {
    const childPageComponents = this.props.selectedPageComponent.childPageComponents;
    if (!childPageComponents) {
      return;
    }
    const columnPageComponentNodes = sortBy(
      childPageComponents.edges
        .map(({ node }) => node)
        .filter(component => component.kind === 'COLUMN'),
      'order',
    );
    if (!columnPageComponentNodes[0].columnComponent) {
      return;
    }
    updateColumnComponent({
      padding,
      id: columnPageComponentNodes[0].columnComponent.id,
    }).catch(showModernMutationError);
  };

  handleSaveRightWidth = (width: number) => {
    const childPageComponents = this.props.selectedPageComponent.childPageComponents;
    if (!childPageComponents) {
      return;
    }
    const columnPageComponentNodes = sortBy(
      childPageComponents.edges
        .map(({ node }) => node)
        .filter(component => component.kind === 'COLUMN'),
      'order',
    );
    if (!columnPageComponentNodes[1].columnComponent) {
      return;
    }
    updateColumnComponent({
      width,
      id: columnPageComponentNodes[1].columnComponent.id,
    }).catch(showModernMutationError);
  };

  handleSaveRightPadding = (padding: string) => {
    const childPageComponents = this.props.selectedPageComponent.childPageComponents;
    if (!childPageComponents) {
      return;
    }
    const columnPageComponentNodes = sortBy(
      childPageComponents.edges
        .map(({ node }) => node)
        .filter(component => component.kind === 'COLUMN'),
      'order',
    );
    if (!columnPageComponentNodes[1].columnComponent) {
      return;
    }
    updateColumnComponent({
      padding,
      id: columnPageComponentNodes[1].columnComponent.id,
    }).catch(showModernMutationError);
  };

  handleChangeRowRightWidth = (rightColumnWidth: number) => {
    this.props.onChangeComponentProperty({ selectedRowLeftWidth: 100 - rightColumnWidth });
    this.setState({ leftColumnWidth: 100 - rightColumnWidth });
  };

  handleChangeRowLeftWidth = (leftColumnWidth: number) => {
    this.props.onChangeComponentProperty({ selectedRowLeftWidth: leftColumnWidth });
    this.setState({ leftColumnWidth });
  };

  handleChangeCellSpacing = (spacing: number) => {
    this.props.onChangeComponentProperty({ selectedRowCellSpacing: spacing });
  };

  handleChangeLeftColumnPadding = (updatedProps: SelectedComponent) => {
    if (!updatedProps) {
      return;
    }
    this.props.onChangeComponentProperty({
      selectedRowLeftColumnPadding: updatedProps.selectedComponentPadding,
    });
  };

  handleChangeRightColumnPadding = (updatedProps: SelectedComponent) => {
    if (!updatedProps) {
      return;
    }
    this.props.onChangeComponentProperty({
      selectedRowRightColumnPadding: updatedProps.selectedComponentPadding,
    });
  };

  render() {
    const {
      org,
      onChangeComponentProperty,
      selectedPageComponent: { id, rowComponent, childPageComponents },
    } = this.props;
    if (!rowComponent || !childPageComponents) {
      return null;
    }
    const columnPageComponentNodes = sortBy(
      childPageComponents.edges.map(({ node }) => node),
      'order',
    );
    const leftColumnWidth = this.state.leftColumnWidth;
    return (
      <Container>
        {id === 'new' && <BlockingLayer />}
        <Section>
          <PropertyRow label="Padding">
            <PaddingComponent
              paddingString={rowComponent.padding}
              onSave={this.handleSavePadding}
              onChange={onChangeComponentProperty}
            />
          </PropertyRow>
          <PropertyRow label="Cell padding">
            <StyledSizeField
              value={rowComponent.cellSpacing}
              onSave={this.handleSaveCellSpacing}
              onChange={this.handleChangeCellSpacing}
            />
          </PropertyRow>
          <PropertyRow label="Background color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={rowComponent.backgroundColor || defaultBackgroundColor}
              onChangeColor={this.handleChangeBackgroundColor}
              alignTo="bottom-right"
              includeTransparent
            />
          </PropertyRow>
        </Section>
        <Section>
          {columnPageComponentNodes[0].columnComponent && (
            <>
              <SectionLabel>Left Column</SectionLabel>
              <PropertyRow label="Width">
                <StyledSizeField
                  value={
                    leftColumnWidth != null
                      ? leftColumnWidth
                      : columnPageComponentNodes[0].columnComponent.width
                  }
                  onSave={this.handleSaveLeftWidth}
                  onChange={this.handleChangeRowLeftWidth}
                  maxValue={100}
                  sizeSymbol="%"
                />
              </PropertyRow>
              <PropertyRow label="Padding">
                <PaddingComponent
                  paddingString={columnPageComponentNodes[0].columnComponent.padding}
                  onSave={this.handleSaveLeftPadding}
                  onChange={this.handleChangeLeftColumnPadding}
                />
              </PropertyRow>
            </>
          )}
        </Section>
        <Section>
          {columnPageComponentNodes[1].columnComponent && (
            <>
              <SectionLabel>Right Column</SectionLabel>
              <PropertyRow label="Width">
                <StyledSizeField
                  value={
                    leftColumnWidth != null
                      ? 100 - leftColumnWidth
                      : columnPageComponentNodes[1].columnComponent.width
                  }
                  onSave={this.handleSaveRightWidth}
                  onChange={this.handleChangeRowRightWidth}
                  maxValue={100}
                  sizeSymbol="%"
                />
              </PropertyRow>
              <PropertyRow label="Padding">
                <PaddingComponent
                  paddingString={columnPageComponentNodes[1].columnComponent.padding}
                  onSave={this.handleSaveRightPadding}
                  onChange={this.handleChangeRightColumnPadding}
                />
              </PropertyRow>
            </>
          )}
        </Section>
      </Container>
    );
  }
}

export default createFragmentContainer(RowComponent, {
  org: graphql`
    fragment RowComponent_org on Org {
      id
      settings {
        ...RegistrationColorSelector_orgSettings
      }
    }
  `,
  selectedPageComponent: graphql`
    fragment RowComponent_selectedPageComponent on RegistrationPageComponent {
      id
      kind
      childPageComponents {
        edges {
          node {
            id
            order
            kind
            columnComponent {
              id
              width
              padding
            }
          }
        }
      }
      rowComponent {
        id
        padding
        cellSpacing
        backgroundColor
      }
    }
  `,
});
