/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { InputVariableFilters } from 'components/Companies/companiesTableColumnSettings';

const mutation = graphql`
  mutation removeCompaniesMutation(
    $filters: CompanyFilters
    $input: RemoveCompaniesInput!
    $hasFilters: Boolean!
  ) {
    removeCompanies(input: $input) {
      org {
        companies @include(if: $hasFilters) {
          totalCount
        }
        ...OrgCompaniesTablePagination_totalCountOrg @include(if: $hasFilters)
      }
      event @include(if: $hasFilters) {
        ...EventCompaniesTablePagination_totalCountEvent
        companies @include(if: $hasFilters) {
          totalCount
        }
      }
      removedCompanyIds
    }
  }
`;

type Args = {
  orgId?: string,
  eventId?: ?string,
  companyIds: $ReadOnlyArray<string>,
  filters?: InputVariableFilters,
  fromWindow: string,
};

export default function removeCompanies({ eventId, orgId, companyIds, filters, fromWindow }: Args) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        companyIds,
        eventId,
        fromWindow,
      },
      filters,
      hasFilters: !!filters,
    },
    optimisticResponse: {
      removeCompanies: {
        removedCompanyIds: companyIds,
      },
    },
    updater: mergeUpdaters(
      ...[
        eventId != null
          ? removeRecordFromConnection({
              deletedIdField: 'removedCompanyIds',
              parentId: eventId,
              connections: [{ key: 'EventCompaniesTablePagination_companies' }],
            })
          : null,
        orgId != null
          ? removeRecordFromConnection({
              deletedIdField: 'removedCompanyIds',
              parentId: orgId,
              connections: [{ key: 'OrgCompaniesTablePagination_companies' }],
            })
          : null,
      ].filter(Boolean),
      removeRecordFromStore({ deletedIdField: 'removedCompanyIds' }),
    ),
  });
}
