/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import VendorExpenses from './VendorExpenses';
import VendorProfile from './VendorProfile';

import type { VendorPageRoutes_event } from './__generated__/VendorPageRoutes_event.graphql';
import type { VendorPageRoutes_org } from './__generated__/VendorPageRoutes_org.graphql';
import type { VendorPageRoutes_user } from './__generated__/VendorPageRoutes_user.graphql';
import type { VendorPageRoutes_vendor } from './__generated__/VendorPageRoutes_vendor.graphql';

const Container = styled.div`
  padding: 0 33px;
`;

class VendorPageRoutes extends React.Component<{
  vendor: VendorPageRoutes_vendor,
  event?: VendorPageRoutes_event,
  user: VendorPageRoutes_user,
  org: VendorPageRoutes_org,
  pathPrefix: string,
  rootUrl: string,
}> {
  render() {
    const { user, vendor, event, pathPrefix, rootUrl, org } = this.props;
    return (
      <Container>
        <Switch>
          <Redirect from={pathPrefix} exact to={`${rootUrl}/expenses`} />
          <Route
            path={`${pathPrefix}/expenses`}
            render={props => <VendorExpenses vendor={vendor} org={org} {...props} />}
          />
          <Route
            path={`${pathPrefix}/profile`}
            render={props => <VendorProfile vendor={vendor} event={event} user={user} {...props} />}
          />
        </Switch>
      </Container>
    );
  }
}

export default createFragmentContainer(VendorPageRoutes, {
  vendor: graphql`
    fragment VendorPageRoutes_vendor on Vendor {
      ...VendorProfile_vendor
      ...VendorExpenses_vendor
    }
  `,
  user: graphql`
    fragment VendorPageRoutes_user on User {
      ...VendorProfile_user
    }
  `,
  event: graphql`
    fragment VendorPageRoutes_event on Event {
      ...VendorProfile_event
    }
  `,
  org: graphql`
    fragment VendorPageRoutes_org on Org {
      ...VendorExpenses_org
    }
  `,
});
