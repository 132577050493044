/* @flow */
import track from './track';

export type UpgradePromptBlockedFrom =
  | 'user profile page'
  | 'team'
  | 'org members'
  | 'easy_button'
  | 'import users';

const blockedByUpgradePrompt = (fromWindow: UpgradePromptBlockedFrom) => {
  track('blocked_by_upgrade_prompt', { from_window: fromWindow });
};

export default blockedByUpgradePrompt;
