/**
 * @flow
 * @relayHash 7aafdbccf0b13be9a851391cddab750a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RoiContainer_event$ref = any;
type RoiContainer_org$ref = any;
export type RoiQueryVariables = {|
  eventSlug: string
|};
export type RoiQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: RoiContainer_event$ref
  |},
  +org: {|
    +$fragmentRefs: RoiContainer_org$ref
  |},
|};
export type RoiQuery = {|
  variables: RoiQueryVariables,
  response: RoiQueryResponse,
|};
*/


/*
query RoiQuery(
  $eventSlug: String!
) {
  event(slug: $eventSlug) {
    ...RoiContainer_event
    id
  }
  org {
    ...RoiContainer_org
    id
  }
}

fragment RoiContainer_event on Event {
  id
  slug
  viewerCanViewROI
  salesforceCampaign {
    ...SalesforceCampaignData_campaign
    id
  }
}

fragment RoiContainer_org on Org {
  viewerCanUpdate
  salesforceAccount {
    id
  }
  ...SalesforceCampaignData_org
  settings {
    currency
    id
  }
}

fragment SalesforceCampaignData_org on Org {
  salesforceAccount {
    opportunitiesSyncEnabled
    id
  }
  settings {
    currency
    id
  }
}

fragment SalesforceCampaignData_campaign on SalesforceCampaign {
  budgetedCost
  actualCost
  numberOfContacts
  numberOfLeads
  numberOfConvertedLeads
  numberOfOpportunities
  numberOfWonOpportunities
  amountAllOpportunities
  amountWonOpportunities
  totalAmountAllOpportunities
  totalAmountAllWonOpportunities
  totalNumberOfOpportunities
  totalNumberOfWonOpportunities
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RoiQuery",
  "id": null,
  "text": "query RoiQuery(\n  $eventSlug: String!\n) {\n  event(slug: $eventSlug) {\n    ...RoiContainer_event\n    id\n  }\n  org {\n    ...RoiContainer_org\n    id\n  }\n}\n\nfragment RoiContainer_event on Event {\n  id\n  slug\n  viewerCanViewROI\n  salesforceCampaign {\n    ...SalesforceCampaignData_campaign\n    id\n  }\n}\n\nfragment RoiContainer_org on Org {\n  viewerCanUpdate\n  salesforceAccount {\n    id\n  }\n  ...SalesforceCampaignData_org\n  settings {\n    currency\n    id\n  }\n}\n\nfragment SalesforceCampaignData_org on Org {\n  salesforceAccount {\n    opportunitiesSyncEnabled\n    id\n  }\n  settings {\n    currency\n    id\n  }\n}\n\nfragment SalesforceCampaignData_campaign on SalesforceCampaign {\n  budgetedCost\n  actualCost\n  numberOfContacts\n  numberOfLeads\n  numberOfConvertedLeads\n  numberOfOpportunities\n  numberOfWonOpportunities\n  amountAllOpportunities\n  amountWonOpportunities\n  totalAmountAllOpportunities\n  totalAmountAllWonOpportunities\n  totalNumberOfOpportunities\n  totalNumberOfWonOpportunities\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RoiQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RoiContainer_event",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RoiContainer_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RoiQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanViewROI",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceCampaign",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceCampaign",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "budgetedCost",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "actualCost",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfContacts",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfLeads",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfConvertedLeads",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfOpportunities",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfWonOpportunities",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "amountAllOpportunities",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "amountWonOpportunities",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalAmountAllOpportunities",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalAmountAllWonOpportunities",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalNumberOfOpportunities",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalNumberOfWonOpportunities",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanUpdate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "opportunitiesSyncEnabled",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currency",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd81f3ab632c89d2136a0107bd2ae2bac';
module.exports = node;
