/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import RegistrationChart from './RegistrationChart';
import RegistrationTotals from './RegistrationTotals';

import type { RegistrationSummary_event } from './__generated__/RegistrationSummary_event.graphql';

type Props = {
  event: RegistrationSummary_event,
};

const RegistrationSummary = ({ event }: Props) => {
  const data = event.contactRegistrationsCountData || [];

  const totalRegistered = data.reduce((acc, current) => acc + current.registrationCount, 0);

  const showEventResults = event.attendedTotal > 0 && (event.zoomLocation || event.g2wLocation);

  return (
    <>
      {showEventResults ? (
        <RegistrationTotals event={event} totalRegistered={totalRegistered} />
      ) : (
        <RegistrationChart data={data} totalRegistered={totalRegistered} />
      )}
    </>
  );
};

export default createFragmentContainer(RegistrationSummary, {
  event: graphql`
    fragment RegistrationSummary_event on Event {
      contactRegistrationsCountData(filters: $filters) {
        registrationCount
        registrationDate
      }
      attendedTotal(filters: $filters)
      zoomLocation {
        id
      }
      g2wLocation {
        id
      }
      ...RegistrationTotals_event
    }
  `,
});
