/* @flow */
import * as React from 'react';
import {
  type ConnectDropTarget,
  type DropTargetConnector,
  type DropTargetMonitor,
  DropTarget,
} from 'react-dnd';
import styled, { css } from 'styled-components';

const DropArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  opacity: 0;
  background-color: #fff;
  border: 1px dashed #5db5d5;
  border-image: url('/images/component_hover_border.png') 1 round;
  transition: 0.2s;
  color: #3ba9da;
  ${props =>
    props.hovered &&
    css`
      background-color: #f1f9fc;
    `};
  ${props =>
    props.visible &&
    css`
      height: 60px;
      opacity: 1;
    `}
`;

class DroppableArea extends React.PureComponent<{
  connectDropTarget?: ConnectDropTarget,
  hovered: boolean,
  visible: boolean,
  /* eslint-disable react/no-unused-prop-types */
  // Used in drop handler
  order: number,
  parentId: ?string,
}> {
  render() {
    const { connectDropTarget, hovered, visible } = this.props;
    if (!connectDropTarget) return null;

    return connectDropTarget(
      <div>
        <DropArea hovered={hovered} visible={visible}>
          {hovered ? `Drop` : `Drag`} Component Here
        </DropArea>
      </div>,
    );
  }
}

const ConnectedDroppableComponent = DropTarget(
  'REGISTRATION_PAGE_COMPONENT',
  {
    drop(props) {
      return { order: props.order, parentId: props.parentId };
    },
  },
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
  }),
)(DroppableArea);

export default ConnectedDroppableComponent;
