import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';

import { SingleCalendarPanel } from 'components/panels/SingleCalendarPanel';

class ScheduleDayForm extends React.Component {
  static propTypes = {
    schedule: PropTypes.shape({
      id: PropTypes.string.isRequired,
      viewerCanUpdate: PropTypes.bool.isRequired,
      event: PropTypes.shape({
        tz: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    date: null,
  };

  handleDateChange = ({ date }) => this.setState({ date });

  handleDateSave = () => {
    this.props.onSave(this.state.date);
  };

  handleDatePanelShow = () => this.panel.show();

  render() {
    const { schedule } = this.props;
    const { date } = this.state;

    return (
      <div className="schedule-day">
        <div className="schedule-item-meta">
          <div className="schedule-item-meta-date empty">
            {this.props.schedule.viewerCanUpdate && (
              <a onClick={this.handleDatePanelShow}>+ Add Day</a>
            )}
            <SingleCalendarPanel
              ref={panel => {
                this.panel = panel;
              }}
              hiddenCheckbox
              hideHeader
              all_day
              tz={schedule.event.tz}
              date={date}
              onChange={this.handleDateChange}
              onSave={this.handleDateSave}
              modalOnMobile
            />
          </div>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(
  ScheduleDayForm,
  graphql`
    fragment ScheduleDayForm_schedule on Schedule {
      id
      viewerCanUpdate
      event {
        tz
      }
    }
  `,
);
