/* @flow */
import * as React from 'react';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';

import type { InfoBoxOptionsType } from './googleMapsWrapperTypes';

export default class InfoBoxFixedWrapper extends React.Component<{
  infoBoxOptions: {
    fixedInfoBoxView: React.ComponentType<any>,
    customProp?: InfoBoxOptionsType,
  },
  position: {
    lat: number,
    lng: number,
  },
  markers: $ReadOnlyArray<string>,
  mapHeight: number,
}> {
  render() {
    const position = this.props.position;
    const GoogleMaps = window.google.maps;

    const infoBoxOptions = {
      closeBoxURL: '',
      disableAutoPan: true,
      pixelOffset: new GoogleMaps.Size(10, 10),
      position: new GoogleMaps.LatLng(position.lat, position.lng),
      isHidden: false,
      alignBottom: false,
      enableEventPropagation: false,
      pane: 'mapPane',
      boxStyle: {
        maxHeight: `${this.props.mapHeight - 20}px`,
        width: '275px',
      },
    };

    const InfoBoxContentInfo = this.props.infoBoxOptions.fixedInfoBoxView;

    return (
      <InfoBox options={infoBoxOptions}>
        <InfoBoxContentInfo
          eventIds={this.props.markers}
          {...this.props.infoBoxOptions.customProp}
        />
      </InfoBox>
    );
  }
}
