/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import formatCost from 'utils/number/formatCost';

import RelayAttachments from 'components/RelayAttachments';

import type { ExpenseItem_expense } from './__generated__/ExpenseItem_expense.graphql';

const Actions = styled.div`
  visibility: hidden;
  position: absolute;
  right: 10px;
  top: 8px;
`;

const ExpenseLink = styled(Link)`
  position: relative;
  display: block;
  padding: 8px 55px 8px 15px;
  margin-bottom: 10px;
  border-radius: 3px;
  cursor: pointer;
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 13px;
  &:hover {
    background: ${props => props.theme.primaryRowColor};
    ${Actions} {
      visibility: visible;
    }
  }
`;

const Label = styled.div`
  width: 160px;
  color: ${props => props.theme.rowPrimaryTextColor};
  font-weight: 500;
  flex-shrink: 0;
`;

const Row = styled.div`
  display: flex;
  margin-top: 1px;
  padding: 3px 0;
  align-items: flex-start;
  a {
    color: ${props => props.theme.primaryActionColor};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  ${props =>
    props.note &&
    `
    font-style:italic;
    strong {
      font-style: normal;
    }
  `};
  @media (${props => props.theme.mobileOnly}) {
    flex-direction: column;
  }
`;

const Action = styled.i`
  margin-left: 3px;
  color: ${props => props.theme.secondaryActionColor};
  font-size: 14px;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledAttachments = styled.div`
  flex: 1 1 auto;
  margin-left: -10px;
  margin-right: -45px;
  @media (${props => props.theme.mobileOnly}) {
    width: 100%;
  }
`;

class ExpenseItem extends React.PureComponent<{
  expense: ExpenseItem_expense,
  eventSlug: string,
  onRemove: string => void,
  currency: string,
}> {
  stopPropagation = (e: SyntheticEvent<>) => {
    e.stopPropagation();
  };

  handleRemove = (e: SyntheticEvent<>) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onRemove(this.props.expense.id);
  };

  render() {
    const { expense, eventSlug } = this.props;

    const attachments = expense.attachments.edges.map(edge => edge.node);

    return (
      <ExpenseLink to={`/events/${eventSlug}/budget/${expense.id}`}>
        <Row>
          <Label>Name</Label>
          {expense.name}
        </Row>
        {expense.actualAmount != null ? (
          <Row>
            <Label>Actual Amount</Label>
            {formatCost(expense.actualAmount, this.props.currency)}
          </Row>
        ) : null}
        {expense.vendor && (
          <Row>
            <Label>Vendor</Label>
            <Link to={`/events/${eventSlug}/vendors/${expense.vendor.id}`}>
              {expense.vendor.name}
            </Link>
          </Row>
        )}
        {expense.budgetCategory && (
          <Row>
            <Label>Category</Label>
            <Tags>{expense.budgetCategory.name}</Tags>
          </Row>
        )}
        {expense.note && (
          <Row>
            <Label>Note</Label>
            {expense.note}
          </Row>
        )}
        {attachments.length > 0 && (
          <Row>
            <Label>Attachments</Label>
            <StyledAttachments onClick={this.stopPropagation}>
              <RelayAttachments attachments={attachments} />
            </StyledAttachments>
          </Row>
        )}
        <Actions>
          <Action className="fa fa-fw fa-trash" onClick={this.handleRemove} />
        </Actions>
      </ExpenseLink>
    );
  }
}

export default createFragmentContainer(
  ExpenseItem,
  graphql`
    fragment ExpenseItem_expense on Expense {
      id
      name
      actualAmount
      note
      vendor {
        id
        name
      }
      budgetCategory {
        name
      }
      attachments {
        edges {
          node {
            id
            ...RelayAttachments_attachments
          }
        }
      }
    }
  `,
);
