/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MarketoAuth_org$ref = any;
type MarketoWindow_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MarketoContent_org$ref: FragmentReference;
export type MarketoContent_org = {|
  +$fragmentRefs: MarketoAuth_org$ref & MarketoWindow_org$ref,
  +$refType: MarketoContent_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MarketoContent_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "MarketoAuth_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MarketoWindow_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b93c50cce6698cd66e3befee20768fe8';
module.exports = node;
