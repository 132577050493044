/**
 * @flow
 * @relayHash b09dd7be04e83d049f20866ace53ff0c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MarketoChannelMappingsQueryVariables = {||};
export type MarketoChannelMappingsQueryResponse = {|
  +org: {|
    +id: string,
    +marketoAccount: ?{|
      +id: string,
      +marketoChannels: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +channelId: string,
            +applicableProgramType: string,
            +name: string,
            +progressionStatuses: $ReadOnlyArray<{|
              +name: string
            |}>,
          |}
        |}>
      |},
      +marketoChannelMappings: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +channelId: string,
            +progressionStatus: string,
            +customFieldOption: {|
              +id: string
            |},
          |}
        |}>
      |},
    |},
    +customFields: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +options: {|
            +edges: $ReadOnlyArray<{|
              +node: {|
                +id: string,
                +name: string,
              |}
            |}>
          |}
        |}
      |}>
    |},
  |}
|};
export type MarketoChannelMappingsQuery = {|
  variables: MarketoChannelMappingsQueryVariables,
  response: MarketoChannelMappingsQueryResponse,
|};
*/


/*
query MarketoChannelMappingsQuery {
  org {
    id
    marketoAccount {
      id
      marketoChannels {
        edges {
          node {
            channelId
            applicableProgramType
            name
            progressionStatuses {
              name
            }
          }
        }
      }
      marketoChannelMappings {
        edges {
          node {
            id
            channelId
            progressionStatus
            customFieldOption {
              id
            }
          }
        }
      }
    }
    customFields(customizableType: [EVENTCONTACT], fieldNames: ["attendance_status_id", "registration_status_id"]) {
      edges {
        node {
          options {
            edges {
              node {
                id
                name
              }
            }
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "channelId",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "marketoAccount",
  "storageKey": null,
  "args": null,
  "concreteType": "MarketoAccount",
  "plural": false,
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "marketoChannels",
      "storageKey": null,
      "args": null,
      "concreteType": "MarketoChannelRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "MarketoChannelRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "MarketoChannel",
              "plural": false,
              "selections": [
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "applicableProgramType",
                  "args": null,
                  "storageKey": null
                },
                v2,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "progressionStatuses",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MarketoChannelProgressionStatus",
                  "plural": true,
                  "selections": [
                    v2
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "marketoChannelMappings",
      "storageKey": null,
      "args": null,
      "concreteType": "MarketoChannelMappingRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "MarketoChannelMappingRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "MarketoChannelMapping",
              "plural": false,
              "selections": [
                v0,
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "progressionStatus",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customFieldOption",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldOptionType",
                  "plural": false,
                  "selections": [
                    v0
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v4 = [
  {
    "kind": "Literal",
    "name": "customizableType",
    "value": [
      "EVENTCONTACT"
    ],
    "type": "[CustomizableType!]!"
  },
  {
    "kind": "Literal",
    "name": "fieldNames",
    "value": [
      "attendance_status_id",
      "registration_status_id"
    ],
    "type": "[String!]"
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "options",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldOptionTypeRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldOptionTypeRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldOptionType",
          "plural": false,
          "selections": [
            v0,
            v2
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MarketoChannelMappingsQuery",
  "id": null,
  "text": "query MarketoChannelMappingsQuery {\n  org {\n    id\n    marketoAccount {\n      id\n      marketoChannels {\n        edges {\n          node {\n            channelId\n            applicableProgramType\n            name\n            progressionStatuses {\n              name\n            }\n          }\n        }\n      }\n      marketoChannelMappings {\n        edges {\n          node {\n            id\n            channelId\n            progressionStatus\n            customFieldOption {\n              id\n            }\n          }\n        }\n      }\n    }\n    customFields(customizableType: [EVENTCONTACT], fieldNames: [\"attendance_status_id\", \"registration_status_id\"]) {\n      edges {\n        node {\n          options {\n            edges {\n              node {\n                id\n                name\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MarketoChannelMappingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"EVENTCONTACT\"],fieldNames:[\"attendance_status_id\",\"registration_status_id\"])",
            "args": v4,
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v5
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MarketoChannelMappingsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"EVENTCONTACT\"],fieldNames:[\"attendance_status_id\",\"registration_status_id\"])",
            "args": v4,
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v5,
                      v0
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bdcd41727e0bbf1d90a0edc9ad9a7f0d';
module.exports = node;
