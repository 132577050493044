/* @flow */

const mappingKinds = {
  NAME: 'Text Input',
  LOCATION: 'Location',
  DATE: 'Date',
  WEBSITE: 'URL',
  DESCRIPTION: 'Textarea',
  NOTE: 'Textarea',
  ATTACHMENTS: 'File Upload',
  BOOTH: 'Text Input',
  BOOTH_DIMENSIONS: 'Text Input',
  FLOOR_PLAN: 'Textarea',
  LEADER: 'User Select',
  EXPENSE: 'Expense',
  BUDGETED_AMOUNT: 'Amount',
  PLANNED_BUDGET: 'Amount',
};

export default mappingKinds;
