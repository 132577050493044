/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationLogicContent_org$ref = any;
type RegistrationLogicContent_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationLogicRefetchContainer_query$ref: FragmentReference;
export type RegistrationLogicRefetchContainer_query = {|
  +event?: ?{|
    +editableRegistrationForm: ?{|
      +tz: string,
      +$fragmentRefs: RegistrationLogicContent_registrationForm$ref,
    |}
  |},
  +org: {|
    +registrationForm?: {|
      +$fragmentRefs: RegistrationLogicContent_registrationForm$ref
    |},
    +$fragmentRefs: RegistrationLogicContent_org$ref,
  |},
  +$refType: RegistrationLogicRefetchContainer_query$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "FragmentSpread",
  "name": "RegistrationLogicContent_registrationForm",
  "args": null
};
return {
  "kind": "Fragment",
  "name": "RegistrationLogicRefetchContainer_query",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "eventSlug",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "isEventRegForm",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "org",
      "storageKey": null,
      "args": null,
      "concreteType": "Org",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "RegistrationLogicContent_org",
          "args": null
        },
        {
          "kind": "Condition",
          "passingValue": false,
          "condition": "isEventRegForm",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "registrationForm",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationForm",
              "plural": false,
              "selections": [
                v0
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "isEventRegForm",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "event",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "slug",
              "variableName": "eventSlug",
              "type": "String"
            }
          ],
          "concreteType": "Event",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "editableRegistrationForm",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationForm",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "tz",
                  "args": null,
                  "storageKey": null
                },
                v0
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a69af1a6fa160ec573873353ee1410f';
module.exports = node;
