/* @flow */
import React from 'react';
import styled from 'styled-components';

import sanitizeHtml from 'utils/string/sanitizeHtml';

import EditableContainer from 'components/ContactForm/EditableContainer';
import { StyledTinyRichText } from 'components/ContactForm/EditableEmailTemplate';
import { StyledContent } from 'components/TinyRichText';

import UIContext from '../../UIContext';

const Container = styled.div`
  margin-bottom: 30px;
`;

const StyledEditableContainer = styled(EditableContainer)`
  padding: 15px 30px;
  margin: 25px -30px 15px;
`;

const Edit = styled.div`
  font-size: 13px;
`;

export default class EditableSubmitMessage extends React.PureComponent<{
  template: string,
  onChange: (template: string) => void,
  onSave?: () => void,
  error?: ?string | ?boolean,
}> {
  handleSave = () => {
    if (this.props.template.trim()) {
      if (this.props.onSave) {
        this.props.onSave();
      }
      return true;
    }
    return false;
  };

  handleTemplateChange = (template: string) => {
    this.props.onChange(template);
  };

  render() {
    const { template, error } = this.props;
    return (
      <Container>
        <StyledEditableContainer
          defaultPreviewing
          previewContent={
            <StyledContent
              style={{ marginRight: 20, minHeight: 60 }}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(template),
              }}
            />
          }
          editing={false}
          editContent={
            <Edit>
              <UIContext.Consumer>
                {({ loaderColor, fontFamily }) => (
                  <StyledTinyRichText
                    label=""
                    error={error}
                    required
                    value={template}
                    onChange={this.handleTemplateChange}
                    loaderColor={loaderColor}
                    initOptions={{
                      content_style: `@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans');
                        .mce-content-body {
                              font-family: ${fontFamily || 'Roboto'};
                            }`,
                      min_height: 125,
                    }}
                  />
                )}
              </UIContext.Consumer>
            </Edit>
          }
          onSave={this.handleSave}
        />
      </Container>
    );
  }
}
