/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BackgroundStyling_registrationForm$ref = any;
type FormFieldsStyling_formComponent$ref = any;
export type Measurement = "PERCENTAGE" | "PX";
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PageStyling_registrationForm$ref: FragmentReference;
export type PageStyling_registrationForm = {|
  +id: string,
  +width: number,
  +widthMeasurement: Measurement,
  +pageComponents: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +kind: RegistrationPageComponentKind,
        +formComponent: ?{|
          +id: string,
          +$fragmentRefs: FormFieldsStyling_formComponent$ref,
        |},
      |}
    |}>
  |},
  +$fragmentRefs: BackgroundStyling_registrationForm$ref,
  +$refType: PageStyling_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PageStyling_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "width",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "widthMeasurement",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "BackgroundStyling_registrationForm",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pageComponents",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationPageComponentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RegistrationPageComponentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationPageComponent",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "formComponent",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationFormComponent",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "FragmentSpread",
                      "name": "FormFieldsStyling_formComponent",
                      "args": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc535da52ea8ae872bd68f84948b3dc1';
module.exports = node;
