/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import currencies from 'config/currencies.json';

import type { RateType } from 'utils/getCurrencyRates';
import formatCost from 'utils/number/formatCost';
import type { CircaFile } from 'utils/uploading/types';

import VendorField from 'components/budget/VendorField';
import { DropZone } from 'components/DropZone';
import SimpleAttachments from 'components/material/Attachments/SimpleAttachments';
import Button from 'components/material/Button';
import CostField from 'components/material/CostField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';

import TeamAllocation from '../../components/TeamAllocation';
import type { TeamAllocationType } from '../../components/TeamAllocation/TeamSelectField';
import type { ExpenseType } from './index';

import type { PopupFormFields_event } from './__generated__/PopupFormFields_event.graphql';
import type { PopupFormFields_org } from './__generated__/PopupFormFields_org.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  width: 360px;
`;

const StyledSelectField = styled(SelectField)`
  width: 89px;
  margin-left: 28px;
`;

const ManageLink = styled.div`
  color: #3ba9da;
`;

const StyledBudgetCategory = styled(SelectField)`
  width: 100%;
`;

const AmountContainer = styled.div`
  position: relative;
`;

const CalculatedAmount = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0;
  font-size: 12px;
  color: #2dd69c;
`;

const StyledCostField = styled(CostField)`
  width: 116px;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

const StyledTeamAllocation = styled(TeamAllocation)`
  margin-top: 0;
`;

class PopupFormFields extends React.PureComponent<{
  onAmountChange: (e: SyntheticEvent<HTMLInputElement>, amount: ?number) => void,
  onSetTeamAllocations: (teams: $ReadOnlyArray<TeamAllocationType>) => void,
  onKeyDown: (e: SyntheticKeyboardEvent<HTMLInputElement>) => void,
  onNameChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onBudgetCategoryChange: (budgetCategoryId: ?string) => void,
  onCurrencyChange: (currency: string) => void,
  onVendorChange: (vendorId: ?string) => void,
  onAddAttachment: (attachment: CircaFile) => void,
  onRemoveAttachment: (attachmentId: ?string) => void,
  onFilesDrop: (files: Array<File>) => void,
  onCloseButtonClick: () => void,
  onExpenseCreate: () => void,
  currentTeamId: string,
  expense: ExpenseType,
  errors: $ReadOnlyArray<string>,
  org: PopupFormFields_org,
  event: PopupFormFields_event,
  actualAmount: number,
  currency: string,
  rates: RateType,
  rate: number,
}> {
  handleCurrencyChange = (currency: ?string) => {
    if (currency) {
      this.props.onCurrencyChange(currency);
    }
  };

  render() {
    const { expense, errors, org, actualAmount, currency, rates, event, rate } = this.props;
    const attachmentUploading = expense.attachments.some(attachment => !attachment.fileurl);
    return (
      <Container>
        <DropZone onDrop={this.props.onFilesDrop}>
          <Row>
            <StyledTextField
              name="name"
              label="Expense"
              value={expense.name}
              onKeyDown={this.props.onKeyDown}
              onChange={this.props.onNameChange}
              error={errors.includes('name') ? 'Required' : ''}
              required
            />
          </Row>
          <Row>
            <StyledBudgetCategory
              clearable
              value={expense.budgetCategoryId}
              options={[
                ...org.budgetCategories.edges
                  .map(edge => edge.node)
                  .map(category => ({
                    label: category.name,
                    value: category.id,
                    info: category.description,
                  })),
                org.viewerCanUpdate
                  ? {
                      label: 'Manage Categories',
                      value: 'manage',
                      displayLabel: <ManageLink>Manage Categories</ManageLink>,
                    }
                  : null,
              ].filter(Boolean)}
              searchable
              label="Expense Category"
              onChange={this.props.onBudgetCategoryChange}
            />
          </Row>
          <Row>
            <AmountContainer>
              <StyledCostField
                name="actualCost"
                label="Amount"
                defaultValue={actualAmount}
                onKeyDown={this.props.onKeyDown}
                onChange={this.props.onAmountChange}
                error={errors.includes('cost') ? 'Required' : ''}
                currency={currency}
                required
              />
              {currency !== org.settings.currency && (
                <CalculatedAmount>
                  <Tooltip
                    label={`${formatCost(100, org.settings.currency)} = ${formatCost(
                      Math.round(rate * 100),
                      currency,
                    )}\nWorkspace Currency: ${org.settings.currency}`}
                    placement="bottom"
                  >
                    {formatCost(expense.amount, org.settings.currency)}
                  </Tooltip>
                </CalculatedAmount>
              )}
            </AmountContainer>
            <StyledSelectField
              value={currency}
              onChange={this.handleCurrencyChange}
              options={currencies.map(item => ({
                value: item.code,
                label: `${item.code} (${item.symbol})`,
              }))}
              searchable
              disabled={Object.keys(rates).length === 0}
            />
          </Row>
          <Row>
            <VendorField
              eventId={event.id}
              vendorId={expense.vendorId}
              onSelect={this.props.onVendorChange}
              label="Vendor"
              clearable
            />
          </Row>
          <Row>
            <StyledTeamAllocation
              currentTeamId={this.props.currentTeamId}
              teams={expense.teamAllocations}
              onSetTeamAllocations={this.props.onSetTeamAllocations}
            />
          </Row>
          <Row>
            <SimpleAttachments
              attachments={expense.attachments}
              onAdd={this.props.onAddAttachment}
              onRemove={this.props.onRemoveAttachment}
            />
          </Row>
          <ButtonsRow>
            <Button minimal label="Cancel" onClick={this.props.onCloseButtonClick} />
            <SubmitButton
              primary
              label="Create"
              type="submit"
              onClick={this.props.onExpenseCreate}
              disabled={attachmentUploading}
            />
          </ButtonsRow>
        </DropZone>
      </Container>
    );
  }
}
export default createFragmentContainer(PopupFormFields, {
  org: graphql`
    fragment PopupFormFields_org on Org {
      viewerCanUpdate
      settings {
        currency
      }
      budgetCategories {
        edges {
          node {
            name
            description
            id
          }
        }
      }
    }
  `,
  event: graphql`
    fragment PopupFormFields_event on Event {
      id
    }
  `,
});
