/**
 * @flow
 * @relayHash de8e0ec3fb84b30285ded4b9c6b40281
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
export type CreateCustomFieldInput = {
  clientMutationId?: ?string,
  customizableType: CustomizableType,
  kind: CustomFieldKindType,
  label: string,
  required: boolean,
  showInCreateForm: boolean,
  showInMobileForm: boolean,
  sectionId?: ?string,
  options?: ?$ReadOnlyArray<FieldOptionInput>,
};
export type FieldOptionInput = {
  id?: ?string,
  name: string,
};
export type createCustomFieldMutationVariables = {|
  input: CreateCustomFieldInput
|};
export type createCustomFieldMutationResponse = {|
  +createCustomField: ?{|
    +customFieldEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +kind: CustomFieldKindType,
        +label: string,
        +required: boolean,
        +enabled: boolean,
        +order: number,
        +section: ?{|
          +id: string
        |},
        +showInCreateForm: boolean,
        +showInMobileForm: boolean,
        +restrictManagingOptions: boolean,
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +name: string,
              +editable: boolean,
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type createCustomFieldMutation = {|
  variables: createCustomFieldMutationVariables,
  response: createCustomFieldMutationResponse,
|};
*/


/*
mutation createCustomFieldMutation(
  $input: CreateCustomFieldInput!
) {
  createCustomField(input: $input) {
    customFieldEdge {
      __typename
      node {
        id
        kind
        label
        required
        enabled
        order
        section {
          id
        }
        showInCreateForm
        showInMobileForm
        restrictManagingOptions: mappedToSalesforce
        options {
          edges {
            node {
              id
              name
              editable
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateCustomFieldInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCustomField",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateCustomFieldInput!"
      }
    ],
    "concreteType": "CreateCustomFieldPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customFieldEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomFieldTypeRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomFieldType",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "kind",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "label",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "required",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "enabled",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "order",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "section",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldSectionType",
                "plural": false,
                "selections": [
                  v1
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "showInCreateForm",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "showInMobileForm",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": "restrictManagingOptions",
                "name": "mappedToSalesforce",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "options",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldOptionTypeRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldOptionTypeRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionType",
                        "plural": false,
                        "selections": [
                          v1,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "editable",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createCustomFieldMutation",
  "id": null,
  "text": "mutation createCustomFieldMutation(\n  $input: CreateCustomFieldInput!\n) {\n  createCustomField(input: $input) {\n    customFieldEdge {\n      __typename\n      node {\n        id\n        kind\n        label\n        required\n        enabled\n        order\n        section {\n          id\n        }\n        showInCreateForm\n        showInMobileForm\n        restrictManagingOptions: mappedToSalesforce\n        options {\n          edges {\n            node {\n              id\n              name\n              editable\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createCustomFieldMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "createCustomFieldMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48d90b32d2b14e0da10969175cf97da8';
module.exports = node;
