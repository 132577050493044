/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';
import formatCost from 'utils/number/formatCost';
import formatNumber from 'utils/number/formatNumber';

import MaterialAvatar from 'components/material/MaterialAvatar';
import Tooltip from 'components/material/Tooltip';

import mappingCustomKinds from './mappingCustomKinds';
import { type QuestionType, type QuestionValueType } from './types';

const OtherOption = styled(Tooltip)`
  display: inline-block;
  padding: 3px;
  border: 1px solid #ffdc9e;
  border-radius: 2px;
  font-weight: 400;
  line-height: 1;
  background: #fff7e8;
`;

const UsersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px 15px 0;
`;

const StyledMaterialAvatar = styled(MaterialAvatar)`
  margin-right: 10px;
`;

const selectFormatter = (question, { textValue, selectIdsValue }) => {
  const options = sortBy(
    selectIdsValue
      .map(id => question.selectOptions.find(option => option.id === id))
      .filter(Boolean),

    option => option.name.toLowerCase(),
  );

  if (options.length === 0 && !textValue) return null;

  return (
    <>
      {options.map(option => option.name).join(', ')}
      {textValue && question.selectShowOtherOption && (
        <>
          {options.length > 0 && ', '}
          <OtherOption label="Will be added to Workspace Settings if approved">
            {textValue}
          </OtherOption>
        </>
      )}
    </>
  );
};

export const userSelectFormatter = (
  question: QuestionType,
  { selectIdsValue }: QuestionValueType,
) => {
  const users = selectIdsValue
    .map(id => question.selectUsers.find(user => user.id === id))
    .filter(Boolean);

  if (users.length === 0) return null;

  return (
    <UsersContainer>
      {users.map(user => (
        <UserContainer key={user.id}>
          <StyledMaterialAvatar user={user} radius={11.5} />
          <Link to={`/workspace/members/${user.id}`}>
            {user.firstName} {user.lastName}
          </Link>
        </UserContainer>
      ))}
    </UsersContainer>
  );
};

export const linkRenderer = (question: QuestionType, { textValue }: QuestionValueType) =>
  textValue && (
    <a href={textValue} target="_blank" rel="noreferrer noopener">
      {textValue}
    </a>
  );

const mappingCustomValueFormatters: $ObjMap<
  typeof mappingCustomKinds,
  () => (QuestionType, QuestionValueType, { currency: string, tz: string }) => React.Node,
> = {
  DEFAULT: () => null,
  TEXT: (question, { textValue }) => textValue,
  TEXTAREA: (question, { textareaValue }) => textareaValue,
  LINK: linkRenderer,
  NUMBER: (question, { numberValue }) =>
    numberValue == null ? null : formatNumber(numberValue, { fractionDigits: 0 }),
  CURRENCY: (question, { numberValue }, { currency }) => formatCost(numberValue, currency),
  DATE: (question, { dateValue }, { tz }) =>
    dateValue && formatDate(moment.tz(dateValue.startDate, tz)),
  BOOLEAN: (question, { booleanValue }) => (booleanValue ? 'Yes' : 'No'),
  USER_SELECT: userSelectFormatter,
  USER_MULTISELECT: userSelectFormatter,
  SELECT: selectFormatter,
  MULTISELECT: selectFormatter,
};

export default mappingCustomValueFormatters;
