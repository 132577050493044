/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';

import publicRuleNames from 'config/publicRuleNames';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import { defaultTableComponents } from 'components/AllEvents/columns';
import colsConfig, {
  getDefaultFilters,
  suggestedFields,
} from 'components/AllEvents/eventsTableColumnSettings';
import type { ParsedAllEventsFilters } from 'components/AllEvents/lib/parseAllEventsFilters';
import parseDateFilters from 'components/AllEvents/lib/parseDateFilters';
import { type ColumnType } from 'components/budget/Table';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type { ColumnGroupConfiguration } from 'components/material/table';

import { type SharedEventListType } from '.';
import AllEventsListShareHeaderBar from './AllEventsListShareHeaderBar';
import SharedEventsTablePagination from './SharedEventsTablePagination';

import type { AllEventsListSharePageContainerQueryResponse } from './__generated__/AllEventsListSharePageContainerQuery.graphql';
import { type AllEventsShareContainer_org } from './__generated__/AllEventsShareContainer_org.graphql';

const AllEventsTableContainer = styled.div`
  position: relative;
  z-index: 9;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin: -1px;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  flex: 1 1 auto;
  max-height: 100%;
  overflow: auto;
  background: ${props => props.theme.secondaryRowColor};
`;

const query = graphql`
  query AllEventsListSharePageContainerQuery(
    $isShared: Boolean!
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
    $count: Int!
    $cursor: String
    $filters: EventFilters!
    $readOnly: Boolean!
    $includeName: Boolean!
    $includeTeam: Boolean!
    $includeStartDate: Boolean!
    $includeEndDate: Boolean!
    $includeVirtualLocation: Boolean!
    $includeEventFormat: Boolean!
    $includeVenueCity: Boolean!
    $includeVenueCountry: Boolean!
    $includeVenueName: Boolean!
    $includeVenueState: Boolean!
    $includeVenueStreet: Boolean!
    $includeVenueZip: Boolean!
    $includeStatus: Boolean!
    $includeProgress: Boolean!
    $includeWebsite: Boolean!
    $includeLead: Boolean!
    $includeEventStaff: Boolean!
    $includeBooth: Boolean!
    $includeBoothDimensions: Boolean!
    $includeActualTotalCost: Boolean!
    $includePaidTotalCost: Boolean!
    $includePlannedTotalCost: Boolean!
    $includeOpportunitiesNumber: Boolean!
    $includeOpportunitiesAmount: Boolean!
    $includeSalesforceId: Boolean!
    $includeMarketoId: Boolean!
    $includeSyncStatus: Boolean!
    $includeLastSync: Boolean!
    $includeRequestForm: Boolean!
    $includeRequestReviewers: Boolean!
    $includeRequestStatus: Boolean!
    $includeRequestedBy: Boolean!
    $includeRequestedDate: Boolean!
    $includeUpdatedAt: Boolean!
    $includeUpdater: Boolean!
    $includeCreatedAt: Boolean!
    $includeCreator: Boolean!
    $includeId: Boolean!
    $includeBriefWeb: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
    $includeContactsCount: Boolean!
    $includeRegisteredContactsTotal: Boolean!
    $includeAttendedContactsTotal: Boolean!
  ) {
    ... @include(if: $isShared) {
      publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
      eventList(resourceToken: $resourceToken) {
        events(first: $count, after: $cursor, filters: $filters) {
          totalCount
        }
        ...SharedEventsTablePagination_eventList
      }
    }
    ... @skip(if: $isShared) {
      me {
        events(first: $count, after: $cursor, filters: $filters) {
          totalCount
        }
        ...SharedEventsTablePagination_eventList
      }
    }
    org {
      ...SharedEventsTablePagination_org
    }
  }
`;

const PAGE_SIZE = 25;

export default class AllEventsListSharePageContainer extends React.Component<
  {
    history: RouterHistory,
    match: Match,
    eventList: SharedEventListType,
    org: AllEventsShareContainer_org,
    eventFields: $ReadOnlyArray<FieldType>,
    groups: ColumnGroupConfiguration,
    userEmail: ?string,
    filters: ParsedAllEventsFilters,
    pathPrefix: string,
  },
  { eventsCount: number },
> {
  scrollContainer = React.createRef();

  state = { eventsCount: 0 };

  componentDidMount() {
    const beforeDate = this.props.eventList.beforeDate
      ? moment(this.props.eventList.beforeDate)
      : null;
    this.state = { eventsCount: 0 };

    const skipDateFilter =
      storage.get(`${this.props.eventList.id}_noFilterSharedEventList`) || false;

    if (!this.filtered() && !this.props.eventList.listType && !beforeDate && !skipDateFilter) {
      const queryParams = {
        date: 'upcoming',
      };
      replaceQueryParams(this.props.history, queryParams);
    }
  }

  handleEmptyFilterApply = () => {
    const appliedFilters = Object.keys(this.props.filters).filter(
      k => this.props.filters[k] != null,
    );

    // Check if only date filter were applied
    if (
      appliedFilters.length === 1 &&
      Object.prototype.hasOwnProperty.call(this.props.filters, 'date') &&
      this.props.filters.date != null &&
      this.props.filters.date.key === 'upcoming'
    ) {
      const storageKey = `${this.props.eventList.id}_noFilterSharedEventList`;
      storage.set(storageKey, true);
    }
  };

  setEventsCount = (eventsCount: number) => {
    if (this.state.eventsCount !== eventsCount) {
      this.setState({ eventsCount });
    }
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  filtered = () =>
    Object.keys(this.props.filters).some(key => key !== 'sort' && this.props.filters[key] != null);

  renderTable = (props: {|
    org: ?$PropertyType<AllEventsListSharePageContainerQueryResponse, 'org'>,
    eventList: ?$PropertyType<AllEventsListSharePageContainerQueryResponse, 'eventList'>,
    me: ?$PropertyType<AllEventsListSharePageContainerQueryResponse, 'me'>,
    shownColumns: $ReadOnlyArray<ColumnType<any, any>>,
    filtered: boolean,
    sort: SortParam,
    includedColumns: { [string]: boolean },
  |}): React.Node => {
    const { org, me, shownColumns, eventList, filtered, sort } = props;
    return (
      <SharedEventsTablePagination
        org={org}
        userEmail={this.props.userEmail}
        shownColumns={shownColumns}
        eventList={eventList || me}
        filtered={filtered}
        sort={sort}
        includedColumns={props.includedColumns}
        onChangeSort={this.handleSortChange}
        scrollContainer={this.scrollContainer && this.scrollContainer.current}
        pageSize={PAGE_SIZE}
        linkEventNameToBrief={this.props.eventList.linkEventNameToBrief}
      />
    );
  };

  render() {
    const {
      org,
      eventList,
      eventFields,
      groups,
      history,
      match,
      userEmail,
      filters,
      filters: { search, sort, month, date, requestedDate, updatedAt, createdAt, ...otherFilters },
      pathPrefix,
    } = this.props;
    const filtered = this.filtered();
    const defaultFilters = getDefaultFilters(otherFilters);
    // Default date filter to Upcoming/Past events depending on current tab
    const dateParams = parseDateFilters(date);

    // Default sort if none specified
    const sortFilter = sort || {
      key: eventList.sort || 'START_DATE',
      asc: eventList.direction !== 'DESC',
    };
    const shownColumnsConfig = colsConfig({
      eventFields,
      shownColumns: eventList.shownColumns,
      skipActions: true,
    });

    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      eventFields,
      eventList.shownColumns,
    );

    const customSortField: ?FieldType = eventFields.find(field => field.id === sortFilter.key);
    const customFilters = eventFields
      .filter(
        option =>
          (option.fieldName == null || suggestedFields.includes(option.fieldName)) &&
          otherFilters[option.id] != null,
      )
      .map(option => getCustomFieldVariable(otherFilters[option.id], option));
    const isPreviewing = !match.params.resourceToken;
    const variables = {
      isShared: !isPreviewing,
      publicRuleName: publicRuleNames.PUBLIC_EVENT_LIST,
      resourceToken: match.params.resourceToken || '',
      orgSlug: match.params.orgName,
      count: PAGE_SIZE,
      ...includedColumns,
      readOnly: true,
      contactFilters: { salesforceSyncOptions: ['lead'] },
      filters: {
        sort: customSortField ? 'CUSTOM' : sortFilter.key,
        customFieldSortId: customSortField != null ? customSortField.id : null,
        direction: sortFilter.asc ? 'ASC' : 'DESC',
        search: isPreviewing ? search : undefined,
        ...defaultFilters,
        ...dateParams,
        requestedDate,
        createdAt,
        updatedAt,
        customFilters,
      },
    };

    const csvExportVariables = variables.filters;
    return (
      <>
        <AllEventsListShareHeaderBar
          userEmail={userEmail}
          history={history}
          filters={filters}
          org={org}
          eventList={eventList}
          groups={groups}
          eventFields={eventFields}
          viewMode="table"
          csvExportVariables={csvExportVariables}
          eventsCount={this.state.eventsCount}
          pathPrefix={pathPrefix}
          isPreviewing={isPreviewing}
          handleEmptyFilterApply={this.handleEmptyFilterApply}
        />
        <AllEventsTableContainer>
          <ScrollContainer ref={this.scrollContainer}>
            <DefaultQueryRenderer
              query={query}
              variables={variables}
              public={!!match.params.resourceToken}
              renderLoading={() =>
                this.renderTable({
                  org: null,
                  eventList: null,
                  me: null,
                  shownColumns: shownColumnsConfig,
                  filtered,
                  sort: sortFilter,
                  includedColumns,
                })
              }
              renderSuccess={(response: AllEventsListSharePageContainerQueryResponse) => {
                const eventListInterface = response.eventList || response.me;

                if (eventListInterface) {
                  this.setEventsCount(eventListInterface.events.totalCount);
                }

                return this.renderTable({
                  org: response.org,
                  eventList: response.eventList,
                  me: response.me,
                  shownColumns: shownColumnsConfig,
                  filtered,
                  sort: sortFilter,
                  includedColumns,
                });
              }}
            />
          </ScrollContainer>
        </AllEventsTableContainer>
      </>
    );
  }
}
