/* @flow */

import mappingCustomKinds from './mappingCustomKinds';
import { type QuestionType } from './types';

const mappingCustomQuestionValidators: $ObjMap<
  typeof mappingCustomKinds,
  () => QuestionType => ?string,
> = {
  DEFAULT: () => null,
  TEXT: () => null,
  TEXTAREA: () => null,
  LINK: () => null,
  NUMBER: () => null,
  CURRENCY: () => null,
  DATE: () => null,
  BOOLEAN: () => null,
  USER_SELECT: question =>
    question.selectUsers.length === 0 ? 'Please select at least one option' : null,
  USER_MULTISELECT: question =>
    question.selectUsers.length === 0 ? 'Please select at least one option' : null,
  SELECT: question =>
    question.selectOptions.length === 0 ? 'Please select at least one option' : null,
  MULTISELECT: question =>
    question.selectOptions.length === 0 ? 'Please select at least one option' : null,
};

export default mappingCustomQuestionValidators;
