/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import differenceBy from 'lodash/differenceBy';

import type { CircaFile } from 'utils/uploading/types';

import { MinimalButton } from 'components/budget/Button';
import AttachmentOverlay from 'components/RelayAttachments/AttachmentOverlay';
import AttachmentPreview from 'components/RelayAttachments/AttachmentPreview';

import FileAttachment from './FileAttachment';
import LinkAttachment from './LinkAttachment';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const AddLink = styled(MinimalButton)`
  color: ${props => props.theme.primaryActionColor};
`;

const AttachmentItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default class SimpleAttachments extends React.PureComponent<
  {
    attachments: $ReadOnlyArray<CircaFile>,
    onAdd: (attachment: CircaFile) => void,
    onRemove?: (attachmentId: string) => void,
    disabled?: boolean,
    overlayContainer?: ?HTMLElement,
    className?: string,
    hideLink?: boolean,
  },
  {
    overlayShown: boolean,
    previewId: ?string,
  },
> {
  state = { overlayShown: false, previewId: null };

  target: ?MinimalButton;

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  handleAttachmentUpload = (attachment: CircaFile) => {
    this.props.onAdd(attachment);
  };

  handlePreview = (previewId: string) => {
    this.setState({ previewId });
  };

  render() {
    const { attachments, hideLink, disabled, onRemove, overlayContainer, className } = this.props;
    const { previewId, overlayShown } = this.state;
    const fileAttachments = (attachments || []).filter(a => {
      return (
        a &&
        a.filetype &&
        (a.filetype.startsWith('image') ||
          a.filetype === 'application/pdf' ||
          !a.filetype.startsWith('link/'))
      );
    });
    const linkAttachments = differenceBy(attachments, fileAttachments, 'id');

    return (
      <Container className={className}>
        <AttachmentItemsContainer>
          {fileAttachments.map(
            a =>
              a && (
                <FileAttachment
                  key={a.id}
                  attachment={a}
                  onPreview={this.handlePreview}
                  onRemove={onRemove}
                />
              ),
          )}
          {linkAttachments.map(
            l => l && <LinkAttachment key={l.id} attachment={l} onRemove={onRemove} />,
          )}
        </AttachmentItemsContainer>

        {!disabled && (
          <AddLink
            ref={target => {
              this.target = target;
            }}
            onClick={this.handleOverlayShow}
            label="+ Add Attachment"
          />
        )}

        {previewId && (
          <AttachmentPreview
            attachments={fileAttachments}
            mockAttachments={[]}
            previewId={previewId}
            onPreview={this.handlePreview}
            onRemove={onRemove}
          />
        )}
        <AttachmentOverlay
          hideLink={hideLink}
          target={this.target}
          container={overlayContainer || this}
          onHide={this.handleOverlayHide}
          show={overlayShown}
          onUploaded={this.handleAttachmentUpload}
        />
      </Container>
    );
  }
}
