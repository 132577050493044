/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TasksCount from './TasksCount';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  margin: 0 15px;
`;

const Name = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${props =>
    props.completed &&
    `
      text-decoration: line-through;
      color: ${props.theme.rowSecondaryTextColor};
    `};
`;

const StyledTasksCount = styled(TasksCount)`
  white-space: nowrap;
`;

export default class TaskRowName extends React.PureComponent<{
  name: string,
  completed: boolean,
  subtasksCount?: number,
  completedSubtasksCount?: number,
}> {
  render() {
    const { completed, name, subtasksCount, completedSubtasksCount } = this.props;
    const hasCounter = subtasksCount != null && completedSubtasksCount != null && subtasksCount > 0;
    const taskCompleted = hasCounter && completed;
    return (
      <Container>
        <Name completed={completed}>{name}</Name>
        {hasCounter && (
          <StyledTasksCount completed={taskCompleted}>
            {completedSubtasksCount}/{subtasksCount}
          </StyledTasksCount>
        )}
      </Container>
    );
  }
}
