/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import removeRegistrationPageComponent from 'graph/mutations/registration/removeRegistrationPageComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import pageComponentsConfig, { type PageComponentConfigType } from '../../pageComponentsConfig';

export const Header = styled.div`
  display: flex;
  height: 0;
  padding-left: 35px;
  background-color: #f1f9fc;
  border-radius: 4px;
  text-transform: uppercase;
  line-height: 40px;
  font-family: Roboto-Slab;
  font-weight: 500;
  color: #383e56;
  opacity: 0;
  transition: 0.2s;
`;

export const Container = styled.div`
  position: relative;
  margin: 0;
  padding: 5px;
  border-radius: 6px;
  border: 1px dashed transparent;
  transition: 0.2s;
  ${props =>
    props.editing &&
    css`
      margin: 5px 0;
      border: 1px solid #5db5d5 !important;
      & > ${Header} {
        height: 40px;
        opacity: 1;
      }
    `}
`;

const RemoveButton = styled.i`
  margin: 0 15px 0 auto;
  cursor: pointer;
  color: #868f96;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

export default class Frame extends React.PureComponent<
  {
    children: React.Node,
    registrationFormId: string,
    pageComponentId: string,
    kind: PageComponentConfigType,
    editing: boolean,
    onRemoveComponent: () => void,
  },
  { removing: boolean },
> {
  handleRemove = () => {
    removeRegistrationPageComponent(
      this.props.registrationFormId,
      this.props.pageComponentId,
    ).catch(showModernMutationError);
    this.props.onRemoveComponent();
  };

  getLabel = (): string => {
    const kind = this.props.kind;
    if (kind === 'FORM') {
      return 'Form';
    }
    const currentConfig = pageComponentsConfig.find(config => config.kind === this.props.kind);

    return currentConfig ? currentConfig.label : '';
  };

  render() {
    const { editing, children, kind, pageComponentId } = this.props;
    const label = this.getLabel();
    return (
      <Container editing={editing || pageComponentId === 'new'}>
        <Header>
          {label}
          {kind !== 'FORM' && (
            <RemoveButton
              className="fa fa-fw fa-trash"
              title="Remove"
              onClick={this.handleRemove}
            />
          )}
        </Header>
        {children}
      </Container>
    );
  }
}
