/**
 * @flow
 * @relayHash 7ac58d1e3a1995a2e42d70b2cfaa7b23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BriefView_event$ref = any;
export type BriefSharedQueryVariables = {|
  eventSlug: string,
  listToken?: ?string,
|};
export type BriefSharedQueryResponse = {|
  +event: ?{|
    +viewerCanViewBrief: boolean,
    +dbId: number,
    +name: string,
    +team: {|
      +dbId: number,
      +name: string,
    |},
    +$fragmentRefs: BriefView_event$ref,
  |}
|};
export type BriefSharedQuery = {|
  variables: BriefSharedQueryVariables,
  response: BriefSharedQueryResponse,
|};
*/


/*
query BriefSharedQuery(
  $eventSlug: String!
  $listToken: String
) {
  event(slug: $eventSlug, listToken: $listToken) {
    ...BriefView_event
    viewerCanViewBrief
    dbId
    name
    team {
      dbId
      name
      id
    }
    id
  }
}

fragment BriefView_event on Event {
  id
  slug
  team {
    font
    id
  }
  briefSettings {
    accessibility
    briefTemplate {
      headerSettings {
        includeDates
        includeWebsite
        includeLocation
      }
      sections {
        name
        enabled
      }
      eventInfoSections {
        edges {
          node {
            order
            enabled
            sectionName
            fields {
              edges {
                node {
                  order
                  enabled
                  fieldName
                  customField {
                    id
                  }
                  id
                }
              }
            }
            id
          }
        }
      }
      eventStaffFields {
        name
        enabled
      }
      taskFields {
        name
        enabled
      }
      scheduleFields {
        name
        enabled
      }
      personalizeSchedules
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "listToken",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "listToken",
    "variableName": "listToken",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanViewBrief",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enabled",
  "args": null,
  "storageKey": null
},
v7 = [
  v4,
  v6
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BriefSharedQuery",
  "id": null,
  "text": "query BriefSharedQuery(\n  $eventSlug: String!\n  $listToken: String\n) {\n  event(slug: $eventSlug, listToken: $listToken) {\n    ...BriefView_event\n    viewerCanViewBrief\n    dbId\n    name\n    team {\n      dbId\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment BriefView_event on Event {\n  id\n  slug\n  team {\n    font\n    id\n  }\n  briefSettings {\n    accessibility\n    briefTemplate {\n      headerSettings {\n        includeDates\n        includeWebsite\n        includeLocation\n      }\n      sections {\n        name\n        enabled\n      }\n      eventInfoSections {\n        edges {\n          node {\n            order\n            enabled\n            sectionName\n            fields {\n              edges {\n                node {\n                  order\n                  enabled\n                  fieldName\n                  customField {\n                    id\n                  }\n                  id\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n      eventStaffFields {\n        name\n        enabled\n      }\n      taskFields {\n        name\n        enabled\n      }\n      scheduleFields {\n        name\n        enabled\n      }\n      personalizeSchedules\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BriefSharedQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BriefView_event",
            "args": null
          },
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v3,
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BriefSharedQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "font",
                "args": null,
                "storageKey": null
              },
              v5,
              v3,
              v4
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "briefSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "EventBriefTemplate",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "accessibility",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefTemplate",
                "storageKey": null,
                "args": null,
                "concreteType": "BriefTemplate",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "headerSettings",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BriefTemplateHeader",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "includeDates",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "includeWebsite",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "includeLocation",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "sections",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SortableSection",
                    "plural": true,
                    "selections": v7
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "eventInfoSections",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BriefTemplateEventInfoSectionRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BriefTemplateEventInfoSectionRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BriefTemplateEventInfoSection",
                            "plural": false,
                            "selections": [
                              v8,
                              v6,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "sectionName",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "fields",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BriefTemplateEventInfoFieldRequiredConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BriefTemplateEventInfoFieldRequiredEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BriefTemplateEventInfoField",
                                        "plural": false,
                                        "selections": [
                                          v8,
                                          v6,
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "fieldName",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "customField",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "CustomFieldType",
                                            "plural": false,
                                            "selections": [
                                              v5
                                            ]
                                          },
                                          v5
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              v5
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "eventStaffFields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SimpleSection",
                    "plural": true,
                    "selections": v7
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "taskFields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SimpleSection",
                    "plural": true,
                    "selections": v7
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "scheduleFields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SimpleSection",
                    "plural": true,
                    "selections": v7
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "personalizeSchedules",
                    "args": null,
                    "storageKey": null
                  },
                  v5
                ]
              },
              v5
            ]
          },
          v2,
          v3,
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4171ce05b6a2e29a9ac2c40282916adf';
module.exports = node;
