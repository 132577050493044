/* @flow */
import styled from 'styled-components';

const SectionNumber = styled.div`
  padding: 1px 10px 0;
  width: 55px;
  background-color: #f1f9fc;
  text-align: right;
  color: #4a5665;
  font-size: 16px;
  font-weight: bold;
`;
export default SectionNumber;
