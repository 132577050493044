/* @flow */

import React from 'react';
import styled from 'styled-components';

import { type Field } from './types';
import {
  BriefUserName,
  BriefUserAvatar,
  BriefUserEmail,
  BriefUserPhone,
  BriefUserTitleCompany,
  BriefUserTag,
} from './BriefUser';

const Content = styled.div`
  display: flex;
`;

type User = {
  id: string,
  firstName: string,
  lastName: string,
  email: ?string,
  phone1: ?string,
  phone2: ?string,
  title: ?string,
  company: ?string | Object,
};

type Props = {
  customFields: $ReadOnlyArray<Field<'CONTACT'>>,
  contact: $ReadOnly<User>,
  avatarRadius: number,
};

const ContactField = ({ contact, avatarRadius, customFields }: Props) => {
  const { firstName, lastName, title, company, email, phone1, phone2 } = contact;
  const enabledFields = customFields.map(field => field.fieldName);

  return (
    <Content>
      <BriefUserAvatar user={contact} radius={avatarRadius} />
      <div>
        <BriefUserName firstName={firstName} lastName={lastName} />
        <BriefUserTag tag="contact" />
        {(enabledFields.includes('title') || enabledFields.includes('company')) && (
          <BriefUserTitleCompany title={title} company={company} />
        )}
        {enabledFields.includes('email') && <BriefUserEmail email={email} />}
        {enabledFields.includes('phone1') && <BriefUserPhone phone={phone1} />}
        {enabledFields.includes('phone2') && <BriefUserPhone phone={phone2} />}
      </div>
    </Content>
  );
};

export default ContactField;
