/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateSalesforceSettingsMutationResponse,
  updateSalesforceSettingsMutationVariables,
} from './__generated__/updateSalesforceSettingsMutation.graphql';

const mutation = graphql`
  mutation updateSalesforceSettingsMutation($input: UpdateSalesForceSettingsInput!) {
    updateSalesforceSettings(input: $input) {
      salesforceAccount {
        host
        defaultLeadSource
        contactsSyncEnabled
        opportunitiesSyncEnabled
      }
    }
  }
`;

export type SalesforceSettingsOptions = {|
  +host?: string,
  +defaultLeadSource?: ?string,
  +contactsSyncEnabled?: boolean,
  +opportunitiesSyncEnabled?: boolean,
|};

export default function updateSalesforceSettings(
  accountSettings: SalesforceSettingsOptions,
  salesforceAccountId?: string,
): Promise<updateSalesforceSettingsMutationResponse> {
  const variables: updateSalesforceSettingsMutationVariables = {
    input: { ...accountSettings },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateSalesforceSettings: {
        salesforceAccount: {
          id: salesforceAccountId,
          ...accountSettings,
          ...(accountSettings.contactsSyncEnabled === false
            ? { opportunitiesSyncEnabled: false }
            : {}),
        },
      },
    },
  });
}
