import { stringParamToDateRange } from 'utils/routing/parseTypedQueryString';

const stringParamToFiscalDateRange = (key: string, fiscalYearStart: string) => {
  return ['UPCOMING', 'PAST', 'RECENT', 'NEXT30DAYS', 'NEX60DAYS', 'NEXT90DAYS'].includes(
    key.toUpperCase(),
  )
    ? { key }
    : stringParamToDateRange(key, fiscalYearStart);
};

export default stringParamToFiscalDateRange;
