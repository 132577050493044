/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { EventRequestFormsUpdatedColumn_requestForm } from './__generated__/EventRequestFormsUpdatedColumn_requestForm.graphql';

class EventRequestFormsUpdatedColumn extends React.PureComponent<{
  tz: string,
  requestForm: EventRequestFormsUpdatedColumn_requestForm,
}> {
  render() {
    return <DateTimeTooltip date={moment.tz(this.props.requestForm.updatedAt, this.props.tz)} />;
  }
}

export default createFragmentContainer(
  EventRequestFormsUpdatedColumn,
  graphql`
    fragment EventRequestFormsUpdatedColumn_requestForm on EventRequestForm {
      updatedAt
    }
  `,
);
