/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type SalesforceMappingInputType } from 'graph/mutations/salesforce/createSalesforceMapping';

import IntegrationMapping from 'components/integrations/IntegrationsSettings/IntegrationMapping';

import fieldDisabledReasons from './lib/fieldDisabledReasons';
import type { Field } from './lib/types';

import type { SalesforceMappingRow_salesforceFields } from './__generated__/SalesforceMappingRow_salesforceFields.graphql';
import type {
  SalesforceKindType,
  SalesforceMappingRow_salesforceMapping,
} from './__generated__/SalesforceMappingRow_salesforceMapping.graphql';

class SalesforceMappingRow extends React.PureComponent<{
  field: Field,
  kind: SalesforceKindType,
  salesforceObjectLabel: string,
  salesforceFields: SalesforceMappingRow_salesforceFields,
  salesforceMapping: ?SalesforceMappingRow_salesforceMapping,
  activeFields: $ReadOnlyArray<string>,
  onUpdate: SalesforceMappingInputType => void,
  onCreate: SalesforceMappingInputType => void,
  onRemove: (mappingId: string) => void,
  onUpdateSetting: (setting: string, value: boolean) => void,
}> {
  handleSelectMapping = (salesforceField, direction) => {
    const { kind, field, salesforceMapping } = this.props;
    const mapping = {
      kind,
      circaField: field.name,
      salesforceField: salesforceField || '',
      toSalesforce: ['to', 'both'].includes(direction),
      fromSalesforce: ['from', 'both'].includes(direction),
      customFieldId: field.customFieldId,
    };
    if (field.fixedSettingField) {
      this.props.onUpdateSetting(field.fixedSettingField, direction != null);
    } else if (!salesforceMapping) {
      this.props.onCreate(mapping);
    } else if (mapping.salesforceField && direction) {
      const fieldUpdated = mapping.salesforceField !== salesforceMapping.salesforceField;
      const directionUpdated =
        mapping.toSalesforce !== salesforceMapping.toSalesforce ||
        mapping.fromSalesforce !== salesforceMapping.fromSalesforce;
      if (fieldUpdated || directionUpdated) {
        this.props.onUpdate(mapping);
      }
    } else {
      this.props.onRemove(salesforceMapping.id);
    }
  };

  getDirectionValue = (field: { +toSalesforce: boolean, +fromSalesforce: boolean }): ?string => {
    if (field.toSalesforce && field.fromSalesforce) {
      return 'both';
    }
    if (field.toSalesforce) {
      return 'to';
    }
    if (field.fromSalesforce) {
      return 'from';
    }
    return null;
  };

  directionSalesforceValue(): ?string {
    const { salesforceMapping, field } = this.props;
    if (salesforceMapping) {
      return this.getDirectionValue(salesforceMapping);
    }

    if (field.fixedMappingField && !field.fixedMappingCanDisable && !field.fixedSettingField) {
      return this.getDirectionValue(field);
    }

    return null;
  }

  render() {
    const {
      kind,
      field,
      salesforceMapping,
      salesforceFields,
      activeFields,
      salesforceObjectLabel,
    } = this.props;

    // ContactRoles is not an Opportunity field. Adding a fake field mapping just to show to user
    const adjustedSalesforceFields = [
      ...salesforceFields,
      ...(kind === 'OPPORTUNITY'
        ? [
            {
              label: 'Contact Roles',
              name: 'ContactRoles',
              nillable: false,
              type: 'reference',
              updateable: true,
              maxLength: 0,
            },
          ]
        : []),
    ];

    const salesforceFieldValue =
      field.fixedMappingField || (salesforceMapping && salesforceMapping.salesforceField);

    const salesforceField = salesforceFieldValue
      ? adjustedSalesforceFields.find(sfField => sfField.name === salesforceFieldValue)
      : null;

    const disabledReasons = fieldDisabledReasons(
      field,
      salesforceField,
      activeFields.filter(
        sfField => salesforceMapping && sfField !== salesforceMapping.salesforceField,
      ),
      salesforceFieldValue,
    );

    const tooltip = disabledReasons[0];
    const options = adjustedSalesforceFields.map(sfField => {
      const optionDisabledReasons = fieldDisabledReasons(
        field,
        sfField,
        activeFields,
        salesforceFieldValue,
      );

      return {
        label: sfField.label.replace(/\sID$/, ''),
        value: sfField.name,
        disabled:
          optionDisabledReasons.length > 0 &&
          (salesforceFieldValue !== sfField.name || optionDisabledReasons.length !== 1),
        disabledInfo: optionDisabledReasons[0],
      };
    });

    return (
      <IntegrationMapping
        label={field.label}
        fieldSelectPlaceholder={`Select ${salesforceObjectLabel} Field`}
        directionSelectPlaceholder="Not Syncing"
        fieldSelectOptions={options}
        directionSelectOptions={[
          { label: 'To Salesforce', value: 'to', disabled: !field.toSalesforce },
          { label: 'From Salesforce', value: 'from', disabled: !field.fromSalesforce },
          { label: 'Both', value: 'both', disabled: !field.fromSalesforce || !field.toSalesforce },
        ]}
        fieldSelectValue={salesforceFieldValue}
        directionSelectValue={this.directionSalesforceValue()}
        disabledInfo={tooltip}
        onSelect={this.handleSelectMapping}
        disableFieldSelect={!!field.fixedMappingField}
        disableDirectionSelect={
          !!field.fixedMappingField && !field.fixedSettingField && !field.fixedMappingCanDisable
        }
      />
    );
  }
}

export default createFragmentContainer(SalesforceMappingRow, {
  salesforceFields: graphql`
    fragment SalesforceMappingRow_salesforceFields on SalesforceField @relay(plural: true) {
      name
      label
      nillable
      maxLength
      type
      updateable
    }
  `,
  salesforceMapping: graphql`
    fragment SalesforceMappingRow_salesforceMapping on SalesforceMapping {
      id
      kind
      salesforceField
      toSalesforce
      fromSalesforce
    }
  `,
});
