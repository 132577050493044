/* @flow */
import React from 'react';
import {
  type ConnectDropTarget,
  type DropTargetConnector,
  type DropTargetMonitor,
  DropTarget,
} from 'react-dnd';
import styled, { css } from 'styled-components';

import removeCustomFieldSection from 'graph/mutations/customFieldSections/removeCustomFieldSection';
import updateCustomFieldSection from 'graph/mutations/customFieldSections/updateCustomFieldSection';

import Arrow from 'images/arrow.svg';
import ConfirmationWindow from 'components/ConfirmationWindow';
import TextField from 'components/material/TextField';

import { type Field, type Section, type SectionCustomizableType } from './types';

const StyledArrow = styled(Arrow)`
  flex-shrink: 0;
  margin: -2px 12px 0 2px;
  color: #868f96;
  transition: 0.3s;
  cursor: pointer;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  margin: 8px 0;
  padding-left: 30px;

  ${props =>
    props.isNew &&
    css`
      animation: highlight 1s forwards;
    `};

  &:hover {
    > div {
      display: flex;
    }
  }

  ${props =>
    props.expanded &&
    css`
      ${StyledArrow} {
        transform: rotate(90deg);
      }
    `};

  @keyframes highlight {
    from {
      background-color: #d6f1ff;
    }
    to {
      background-color: #f4fafd;
    }
  }
`;

const SectionName = styled.div`
  padding-right: 25px;
  font-size: 14px;
  font-weight: 500;
  color: #3e4859;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
  user-select: none;
`;

const ButtonsContainer = styled.div`
  display: none;
  font-size: 15px;

  > div {
    padding: 0 3px;
  }
`;

const EditButton = styled.div`
  color: #868f96;
  cursor: pointer;

  &:hover {
    color: #4db1dd;
  }
`;

const RemoveButton = styled.div`
  color: #868f96;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.negativeActionColor};
  }
`;

const StyledTextField = styled(TextField)`
  width: 150px;

  input {
    padding-top: 3px;
  }
`;

class CustomizableSectionHeader extends React.Component<
  {
    orgId: string,
    section: Section,
    expanded: boolean,
    sectionCustomizableType: SectionCustomizableType,
    connectDropTarget?: ConnectDropTarget,
    onAddSection: (sectionName: string) => void,
    onExpandToggle: () => void,
    /* eslint-disable react/no-unused-prop-types */
    // Used in Hover handler
    onMoveFieldToSection: (dragField: Field, sectionId: string) => void,
  },
  { editing: boolean, removing: boolean, sectionNameError: string },
> {
  state = { editing: false, removing: false, sectionNameError: '' };

  saveSection = (sectionName: string) => {
    if (this.props.section.name === '') {
      this.props.onAddSection(sectionName);
      return;
    }
    if (sectionName === '') {
      this.setState({ sectionNameError: ' ' });
      return;
    }

    this.setState({ sectionNameError: '', editing: false });
    if (this.props.section.name === sectionName) {
      return;
    }

    updateCustomFieldSection(this.props.section.id, { name: sectionName });
  };

  handleEditSection = () => {
    this.setState({ editing: true });
  };

  handleSectionNameBlur = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.saveSection(e.currentTarget.value.trim());
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.saveSection(e.currentTarget.value.trim());
    }
  };

  handleRemoveSection = () => {
    this.setState({ removing: true });
  };

  handleHideRemoveModal = () => {
    this.setState({ removing: false });
  };

  handleRemoveConfirm = () => {
    removeCustomFieldSection(this.props.orgId, this.props.section.id, {
      sectionCustomizableType: [this.props.sectionCustomizableType],
    });
  };

  render() {
    const { section, expanded, onExpandToggle, connectDropTarget } = this.props;
    const { sectionNameError, editing, removing } = this.state;
    const newSection = section.name === '';
    if (!connectDropTarget) return null;

    return connectDropTarget(
      <div>
        <SectionHeader expanded={expanded} onClick={onExpandToggle} isNew={newSection}>
          <StyledArrow />
          {editing || newSection ? (
            <StyledTextField
              autoFocus
              defaultValue={section.name}
              onKeyDown={this.handleKeyDown}
              onClick={(e: SyntheticEvent<HTMLElement>) => e.stopPropagation()}
              onBlur={this.handleSectionNameBlur}
              placeholder="Section name"
              error={sectionNameError}
            />
          ) : (
            <SectionName>{section.name}</SectionName>
          )}
          {!newSection && (
            <ButtonsContainer onClick={(e: SyntheticEvent<HTMLElement>) => e.stopPropagation()}>
              <EditButton onClick={this.handleEditSection}>
                <i className="fa fa-fw fa-pencil" />
              </EditButton>
              {section.order !== 0 && (
                <RemoveButton onClick={this.handleRemoveSection}>
                  <i className="fa fa-fw fa-trash" />
                </RemoveButton>
              )}
            </ButtonsContainer>
          )}
        </SectionHeader>
        {removing && (
          <ConfirmationWindow
            onHide={this.handleHideRemoveModal}
            onConfirm={this.handleRemoveConfirm}
            title="Are you sure?"
            message="Are you sure you want to remove the selected Section? Once you delete it, this action can't be undone."
          />
        )}
      </div>,
    );
  }
}

export default DropTarget(
  'CUSTOM_FIELD',
  {
    hover(props: $PropertyType<CustomizableSectionHeader, 'props'>, monitor: DropTargetMonitor) {
      const dragField: Field = monitor.getItem();
      if (
        (dragField.section && dragField.section.id === props.section.id) ||
        dragField.kind === 'DEFAULT' ||
        dragField.isSuggested
      ) {
        return;
      }

      props.onMoveFieldToSection(dragField, props.section.id);

      // eslint-disable-next-line no-param-reassign
      monitor.getItem().section = props.section;
      // eslint-disable-next-line no-param-reassign
      monitor.getItem().order = 0;
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(CustomizableSectionHeader);
