/* @flow */
import React from 'react';
import styled from 'styled-components';

import Button from 'components/material/Button';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 8px 20px;
  background: ${props => props.theme.teamSettingsRowHoverColor};
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const DefaultsAction = styled(Button)`
  padding: 0;
  border: none;
  font-size: 13px;
  color: ${props => props.theme.minimalButtonColor};
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

export default class ColumnFiltersHeader extends React.Component<{
  onSetDefaults?: () => void,
}> {
  render() {
    const onSetDefaults = this.props.onSetDefaults;
    return (
      <Container>
        <Title>Columns</Title>
        {onSetDefaults && <DefaultsAction onClick={onSetDefaults} label="reset" minimal />}
      </Container>
    );
  }
}
