/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type UserStatus = "CONFIRMED" | "INVITED" | "NOT_INVITED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MembersActionsColumn_user$ref: FragmentReference;
export type MembersActionsColumn_user = {|
  +id: string,
  +firstName: string,
  +lastName: string,
  +inviteStatus: UserStatus,
  +viewerCanRemove: boolean,
  +viewerCanUpdate: boolean,
  +$refType: MembersActionsColumn_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MembersActionsColumn_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "inviteStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanRemove",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '5cad8dfaaa1a995142dfdb3c49caf5f2';
module.exports = node;
