/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AllEventsListShareHeaderBar_org$ref = any;
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsShareContainer_org$ref: FragmentReference;
export type AllEventsShareContainer_org = {|
  +settings: {|
    +font: string,
    +primaryColor: string,
    +fiscalYearStart: number,
  |},
  +salesforceAccount: ?{|
    +id: string
  |},
  +marketoAccount: ?{|
    +id: string
  |},
  +customFieldSections: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
        +order: number,
      |}
    |}>
  |},
  +customFields: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +label: string,
        +fieldName: ?string,
        +order: number,
        +kind: CustomFieldKindType,
        +required: boolean,
        +restrictChangingValue: boolean,
        +section: ?{|
          +id: string,
          +order: number,
        |},
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +name: string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$fragmentRefs: AllEventsListShareHeaderBar_org$ref,
  +$refType: AllEventsShareContainer_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
],
v2 = [
  "EVENT"
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AllEventsShareContainer_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "font",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "primaryColor",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fiscalYearStart",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "marketoAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "MarketoAccount",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFieldSections",
      "storageKey": "customFieldSections(sectionCustomizableType:[\"EVENT\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "sectionCustomizableType",
          "value": v2,
          "type": "[SectionCustomizableType!]!"
        }
      ],
      "concreteType": "CustomFieldSectionTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldSectionTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldSectionType",
              "plural": false,
              "selections": [
                v0,
                v3,
                v4
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFields",
      "storageKey": "customFields(customizableType:[\"EVENT\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": v2,
          "type": "[CustomizableType!]!"
        }
      ],
      "concreteType": "CustomFieldTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldType",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "label",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fieldName",
                  "args": null,
                  "storageKey": null
                },
                v4,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "required",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": "restrictChangingValue",
                  "name": "mappedToSalesforce",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "pull",
                      "value": true,
                      "type": "Boolean"
                    }
                  ],
                  "storageKey": "mappedToSalesforce(pull:true)"
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "section",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldSectionType",
                  "plural": false,
                  "selections": [
                    v0,
                    v4
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "options",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldOptionTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomFieldOptionTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomFieldOptionType",
                          "plural": false,
                          "selections": [
                            v0,
                            v3
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "AllEventsListShareHeaderBar_org",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '21493e3df41f1d56199bad8720ac8e25';
module.exports = node;
