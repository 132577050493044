/* @flow */
import styled, { css } from 'styled-components';
import BaseColumnAllowOverflow from './BaseColumnAllowOverflow';

const BaseColumn = styled(BaseColumnAllowOverflow)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${props =>
    props.width &&
    css`
      flex: ${props.grow || 0} 0 ${props.width}px;
    `};
`;

export default BaseColumn;
