/* @flow */
import React from 'react';
import styled from 'styled-components';

import SweetRichText from 'components/material/SweetRichText';
import TextField from 'components/material/TextField';

const Row = styled.div`
  margin-top: 15px;
`;

const StyledSweetRichText = styled(SweetRichText)`
  margin-top: 24px;
`;

export default class EditableSectionHeaderForm extends React.PureComponent<{
  onChangeTitle: (name: string) => void,
  onChangeHelpText: (helpText: string) => void,
  onChangeDescription: (description: string) => void,
  title: ?string,
  helpText: ?string,
  description: ?string,
}> {
  handleChangeTitle = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeTitle(e.currentTarget.value);
  };

  handleChangeHelpText = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeHelpText(e.currentTarget.value);
  };

  handleChangeDescription = (description: string) => {
    this.props.onChangeDescription(description);
  };

  render() {
    return (
      <>
        <Row>
          <TextField
            value={this.props.title || ''}
            label="Section Title"
            placeholder="Section Title (optional)"
            autoFocus
            onChange={this.handleChangeTitle}
          />
        </Row>
        <Row>
          <TextField
            value={this.props.helpText || ''}
            label="Section Help Text"
            placeholder="Section Help Text (optional)"
            onChange={this.handleChangeHelpText}
          />
        </Row>
        <Row>
          <StyledSweetRichText
            label="Section Description"
            description={(this.props.description && this.props.description.trim()) || ''}
            onSave={this.handleChangeDescription}
            stickyPanel
            hideActionButtons
          />
        </Row>
      </>
    );
  }
}
