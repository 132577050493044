/* @flow */
import React from 'react';
import styled from 'styled-components';
import Papa from 'papaparse';

import ImportError from 'images/importError.svg';
import ImportUpload from 'images/importUpload.svg';
import WarningIcon from 'images/warning.svg';
import { DropZone } from 'components/DropZone';

import { type FieldsType, type ProcessingResults } from './index';

const Container = styled.div`
  position: relative;
  margin-top: 10px;
  padding-top: 40px;
  > img {
    user-select: none;
    user-drag: none;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoRow = styled.div`
  display: flex;
  max-width: 490px;
  margin: 40px auto 0;
  padding: 8px 15px;
  font-size: 12px;
  line-height: 1.5;
  background: #fff7e8;
  color: #677386;
  > svg {
    width: 18px;
    height: 14px;
    margin: 2px 10px 0 0;
    color: #ffb634;
  }
`;

const InfoMessage = styled.span`
  margin-top: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #3e4859;
`;

const ErrorMessage = styled.span`
  margin-top: 40px;
  font-weight: 500;
  color: #f48183;
`;

const InputLabel = styled.label`
  margin-top: 20px;
  padding: ${props => (props.iconOnly ? '5px 7px' : '5px 22px')};
  border-radius: 3px;
  color: ${props => props.theme.primaryActionTextColor};
  background: ${props => props.theme.primaryActionColor};
  &:hover,
  &:focus {
    background: ${props => props.theme.primaryActionDarkerColor};
  }
  cursor: pointer;
  outline: none;
`;

const HiddenInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
`;

export default class Uploading extends React.Component<
  {
    fields: FieldsType<any>,
    importResourceName: string,
    onComplete: ({ fileName: string, fileContent: string, results: ProcessingResults }) => void,
    onValidateMappings: (mappings: {}) => ?string,
  },
  {
    error: ?string,
    parsing: ?string,
  },
> {
  state = {
    error: null,
    parsing: null,
  };

  handleFileUpload = (files: FileList) => {
    if (files.length > 1) {
      this.setState({ error: 'One CSV file only' });
    } else if (
      !(
        files[0].type === 'text/csv' ||
        (files[0].type === 'application/vnd.ms-excel' && files[0].name.endsWith('.csv'))
      ) // A hack for windows
    ) {
      this.setState({ error: 'CSV file format only' });
    } else {
      this.setState({ error: null, parsing: files[0].name });
      Papa.parse(files[0], {
        header: true,
        trimHeaders: true,
        skipEmptyLines: true,
        complete: this.handleParseEnd,
      });
    }
  };

  handleFilePick = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.files[0] != null) {
      this.handleFileUpload(e.currentTarget.files);
    }
  };

  handleParseEnd = (
    results: { data: ProcessingResults, meta: { fields: $ReadOnlyArray<string> } },
    file: File,
  ) => {
    if (results.meta.fields.every(f => f === '')) {
      this.setState({
        error: `We couldn't parse the column headers. Does your file have separate column headers for ${Object.keys(
          this.props.fields,
        )
          .slice(0, 3)
          .map(key => this.props.fields[key].label.toLowerCase())
          .join(', ')}, etc?`,
        parsing: null,
      });
    } else {
      const filteredData = results.data
        .filter(row => Object.values(row).some(c => typeof c === 'string' && c.trim()))
        .map(row =>
          Object.keys(row)
            .filter(key => key.trim())
            .reduce((obj, key) => ({ ...obj, [key]: row[key] }), {}),
        );
      if (filteredData.length === 0) {
        this.setState({
          error: `There are no ${this.props.importResourceName}s in that file`,
          parsing: null,
        });
      } else {
        this.setState({ parsing: null });

        this.props.onComplete({
          fileName: file.name,
          fileContent: Papa.unparse(filteredData, { header: true }),
          results: filteredData,
        });
      }
    }
  };

  render() {
    const { error, parsing } = this.state;

    const Icon = error != null ? ImportError : ImportUpload;

    return (
      <Container>
        <DropZone onDrop={this.handleFileUpload} text="Drop CSV file here...">
          <Row>
            <Icon />
          </Row>
          {error != null && (
            <Row>
              <ErrorMessage>{error}</ErrorMessage>
            </Row>
          )}
          <Row>
            <InfoMessage>
              {parsing != null ? `Processing ${parsing}` : 'Drag and drop for upload or'}
            </InfoMessage>
          </Row>
          {parsing == null && (
            <Row>
              <InputLabel>
                <HiddenInput type="file" accept="text/csv, .csv" onChange={this.handleFilePick} />
                Choose File
              </InputLabel>
            </Row>
          )}
          <InfoRow>
            <WarningIcon />
            Remember to use .csv file format and put column headers in the first row.{' '}
            {this.props.onValidateMappings({})}
          </InfoRow>
        </DropZone>
      </Container>
    );
  }
}
