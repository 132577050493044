/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/material/Button';
import Window from 'components/material/Window';

const Header = styled.div`
  height: 50px;
  line-height: 50px;
  font-size: 17px;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.rowPrimaryTextColor};
  font-weight: 500;
`;

const Content = styled.div`
  padding: 25px 15px;
  color: ${props => props.theme.rowPrimaryTextColor};
  text-align: center;
  word-break: break-word;
  white-space: normal;
`;

const Buttons = styled.div`
  padding: 5px 15px 15px 15px;
  text-align: center;
  > button {
    margin-left: 6px;
  }
`;

export default class ConfirmationWindow extends React.PureComponent<{
  onHide: () => void,
  onConfirm: () => void,
  title: string | React.Node,
  message: string | React.Node,
  actionLabel: string,
  actionNegative: boolean,
  loading?: boolean,
  flagClass?: string,
  withLoading?: boolean,
}> {
  static defaultProps = {
    title: 'Are you sure?',
    actionLabel: 'Delete',
    actionNegative: true,
  };

  handleHide = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    this.props.onHide();
  };

  handleConfirm = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    const { onHide, onConfirm, withLoading } = this.props;
    if (!withLoading) {
      onHide();
    }
    onConfirm();
  };

  render() {
    const { onHide, flagClass, title, message, actionLabel, loading, actionNegative } = this.props;
    return (
      <Window size="small" onHide={onHide} flagClass={flagClass}>
        <Header>{title}</Header>
        <Content>{message}</Content>
        <Buttons>
          <Button
            label={actionLabel}
            negative={actionNegative}
            primary={!actionNegative}
            onClick={this.handleConfirm}
            loading={loading}
          />
          <Button label="Cancel" outline onClick={this.handleHide} />
        </Buttons>
      </Window>
    );
  }
}
