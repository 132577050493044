/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import sortBy from 'lodash/sortBy';

import ClearIcon from 'components/ClearIcon';
import CheckBox from 'components/material/CheckBox';
import TextInput from 'components/material/TextInput';

const Container = styled.div`
  flex: 1 auto;
  padding: 0 0 0 10px;
  overflow: hidden;
`;

const TextWrapper = styled.div`
  display: flex;
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  flex: 1 1 auto;
  margin-top: 10px;
  padding: 0 27px 0 0;
`;

const InfoText = styled.div`
  font-style: italic;
  color: ${props => props.theme.mutedTextColor};
  padding: 3px 7px;
  font-size: 12px;
  text-align: ${props => (props.centered ? 'center' : 'right')};
`;

const StyledCheckBox = styled(CheckBox)`
  margin-top: 15px;
  &:last-of-type {
    margin-bottom: 3px;
  }
  font-size: 14px;
  line-height: 1;
  color: ${props => props.theme.infoMenuHoverColor};
  > div:first-of-type {
    ${props =>
      props.checked
        ? css`
            color: ${props.theme.highlightColor};
          `
        : css`
            color: ${props.theme.iron};
          `};
  }
  > div:last-of-type {
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ShowAll = styled.div`
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

export type Option<Value> =
  | {|
      value: Value,
      label: string,
    |}
  | {|
      value: Value,
      label: string,
      labelRenderer?: () => React.Node,
    |};

const defMaxOptions = 15;

export default class CheckFilterBody<Name, Value> extends React.Component<
  {
    name: Name,
    options: $ReadOnlyArray<Option<Value>>,
    activeValues: ?$ReadOnlyArray<Value>,
    maxOptions?: number,
    onChange: (Name, ?$ReadOnlyArray<Value>) => void,
    unsorted?: boolean,
  },
  {
    query: string,
    showAll: boolean,
  },
> {
  state = {
    query: '',
    showAll: false,
  };

  handleOptionChange: (boolean, Value) => void = (checked, value) => {
    const activeValues = this.activeValues();
    const values = checked ? [...activeValues, value] : activeValues.filter(s => s !== value);
    this.props.onChange(this.props.name, values);
  };

  handleQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ query: e.currentTarget.value });
  };

  handleClear = () => {
    this.setState({ query: '' });
  };

  handleShowAll = () => {
    this.setState({ showAll: true });
  };

  activeValues(): $ReadOnlyArray<Value> {
    return this.props.activeValues || [];
  }

  handleOptionsFilter(): $ReadOnlyArray<Option<Value>> {
    const query = this.state.query.replace(/\s/g, '');
    const queryRegexp = new RegExp(query, 'i');
    const activeValues = this.activeValues();
    return this.props.options.filter(option => {
      const optionQuery = option.label.replace(/\s/g, '');
      return activeValues.includes(option.value) || queryRegexp.test(optionQuery);
    });
  }

  render() {
    const maxOptions = this.props.maxOptions != null ? this.props.maxOptions : defMaxOptions;

    const options = this.handleOptionsFilter();
    const sortedOptions = this.props.unsorted
      ? options
      : sortBy(options.slice(), option => option.label.toLowerCase());
    const slicedOptions = this.state.showAll ? sortedOptions : sortedOptions.slice(0, maxOptions);

    const showInput = maxOptions != null && maxOptions < this.props.options.length;
    const shouldShowMore = !this.state.showAll && maxOptions != null && maxOptions < options.length;

    return (
      <Container>
        {showInput && (
          <TextWrapper>
            <StyledTextInput
              placeholder="Search..."
              autoFocus
              value={this.state.query}
              onChange={this.handleQueryChange}
            />
            {this.state.query && <ClearIcon onClick={this.handleClear} />}
          </TextWrapper>
        )}
        {slicedOptions.map(option => (
          <StyledCheckBox
            key={typeof option.value === 'string' ? option.value : option.label}
            label={option.label}
            labelRenderer={
              option.labelRenderer !== undefined
                ? () => option.labelRenderer && option.labelRenderer()
                : undefined
            }
            checked={this.activeValues().includes(option.value)}
            onChange={checked => {
              this.handleOptionChange(checked, option.value);
            }}
          />
        ))}
        {shouldShowMore && <ShowAll onClick={this.handleShowAll}>see all {options.length}</ShowAll>}
        {options.length === 0 && <InfoText>No Results</InfoText>}
      </Container>
    );
  }
}
