/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import formatDateTimeISO from 'utils/date/formatDateTimeISO';

import type { ContactAttendanceJoinTime_event } from './__generated__/ContactAttendanceJoinTime_event.graphql';
import type { ContactAttendanceJoinTime_eventContact } from './__generated__/ContactAttendanceJoinTime_eventContact.graphql';

const ContactAttendanceJoinTime = (props: {
  eventContact: ContactAttendanceJoinTime_eventContact,
  event: ContactAttendanceJoinTime_event,
}) => {
  const { event, eventContact } = props;
  const joinTime =
    (eventContact.zoomRegistrant && (eventContact.zoomRegistrant.times[0] || {}).startTime) ||
    (eventContact.g2wRegistrant && (eventContact.g2wRegistrant.times[0] || {}).startTime);

  if (!joinTime) return null;

  return formatDateTimeISO(moment(joinTime).tz(event.tz));
};

export default createFragmentContainer(
  ContactAttendanceJoinTime,
  graphql`
    fragment ContactAttendanceJoinTime_eventContact on EventContactEdge {
      zoomRegistrant {
        times {
          startTime
          endTime
        }
      }
      g2wRegistrant {
        times {
          startTime
          endTime
        }
      }
    }

    fragment ContactAttendanceJoinTime_event on Event {
      tz
    }
  `,
);
