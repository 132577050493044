/**
 * @flow
 * @relayHash 0d2a06263d157b4346709c1286ae2fa1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ZoomLocationPicker_integrable$ref = any;
export type ZoomLocationPickerRefetchQueryVariables = {|
  integrableId: string
|};
export type ZoomLocationPickerRefetchQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: ZoomLocationPicker_integrable$ref
  |}
|};
export type ZoomLocationPickerRefetchQuery = {|
  variables: ZoomLocationPickerRefetchQueryVariables,
  response: ZoomLocationPickerRefetchQueryResponse,
|};
*/


/*
query ZoomLocationPickerRefetchQuery(
  $integrableId: ID!
) {
  node(id: $integrableId) {
    __typename
    ...ZoomLocationPicker_integrable
    id
  }
}

fragment ZoomLocationPicker_integrable on IntegrableInterface {
  id
  viewerCanConfigureIntegrations
  zoomSolutionInstances: traySolutionInstances(solutionName: ZOOM) {
    totalCount
  }
  zoomUsers {
    edges {
      node {
        id
        zoomId
        firstName
        lastName
        email
        freePlan
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "integrableId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "integrableId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ZoomLocationPickerRefetchQuery",
  "id": null,
  "text": "query ZoomLocationPickerRefetchQuery(\n  $integrableId: ID!\n) {\n  node(id: $integrableId) {\n    __typename\n    ...ZoomLocationPicker_integrable\n    id\n  }\n}\n\nfragment ZoomLocationPicker_integrable on IntegrableInterface {\n  id\n  viewerCanConfigureIntegrations\n  zoomSolutionInstances: traySolutionInstances(solutionName: ZOOM) {\n    totalCount\n  }\n  zoomUsers {\n    edges {\n      node {\n        id\n        zoomId\n        firstName\n        lastName\n        email\n        freePlan\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ZoomLocationPickerRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ZoomLocationPicker_integrable",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ZoomLocationPickerRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanConfigureIntegrations",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "zoomSolutionInstances",
            "name": "traySolutionInstances",
            "storageKey": "traySolutionInstances(solutionName:\"ZOOM\")",
            "args": [
              {
                "kind": "Literal",
                "name": "solutionName",
                "value": "ZOOM",
                "type": "SolutionName"
              }
            ],
            "concreteType": "TraySolutionInstanceRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "zoomUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "ZoomUserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ZoomUserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ZoomUser",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "zoomId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "freePlan",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e868b219054421c0a0478d5f08eefb20';
module.exports = node;
