/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';

import TeamItem from './TeamItem';
import TeamsHeader from './TeamsHeader';

import type { TeamsPage_me } from './__generated__/TeamsPage_me.graphql';
import type { TeamsPage_org } from './__generated__/TeamsPage_org.graphql';
import type { TeamsPageQueryResponse } from './__generated__/TeamsPageQuery.graphql';

const query = graphql`
  query TeamsPageQuery {
    me {
      teams {
        edges {
          node {
            id
            viewerCanUpdate
            ...TeamItem_team
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0 25px 24px;
`;

const TeamsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

class TeamsPage extends React.PureComponent<{
  me: TeamsPage_me,
  org: TeamsPage_org,
  history: RouterHistory,
  location: Location,
}> {
  renderTable = (props: { me: ?$PropertyType<TeamsPageQueryResponse, 'me'> }): ?React.Node => {
    if (!props.me) {
      return (
        <LoaderContainer>
          <Loader size={30} />
        </LoaderContainer>
      );
    }
    const teams = props.me.teams.edges.map(({ node }) => node);
    return (
      <TeamsContainer>
        {teams.map((team, index) => (
          <TeamItem key={team.id} team={team} indexInList={index} />
        ))}
      </TeamsContainer>
    );
  };

  render() {
    const { location, history, org } = this.props;

    return (
      <Container>
        <TeamsHeader me={this.props.me} org={org} history={history} location={location} />
        <DefaultQueryRenderer
          query={query}
          variables={{}}
          renderSuccess={({ me }: TeamsPageQueryResponse) =>
            this.renderTable({
              me,
            })
          }
          renderLoading={() =>
            this.renderTable({
              me: null,
            })
          }
        />
      </Container>
    );
  }
}

export default createFragmentContainer(TeamsPage, {
  me: graphql`
    fragment TeamsPage_me on User {
      ...TeamsHeader_me
    }
  `,
  org: graphql`
    fragment TeamsPage_org on Org {
      ...TeamsHeader_org
    }
  `,
});
