/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import isValidWebsite from 'utils/validators/isValidWebsite';

import EventVirtualLocationTypeIcon from 'components/EventVirtualLocation/EventVirtualLocationTypeIcon';

import { type Location_event } from './__generated__/Location_event.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const IconWrapper = styled.div`
  margin: -2px 0;
`;

const Label = styled.div`
  padding-right: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
`;

const StyledLink = styled.a`
  padding-right: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  color: ${props => props.theme.primaryActionColor};

  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
    text-decoration: underline;
  }
`;

class Location extends React.Component<
  {
    event: Location_event,
  },
  {
    showOverlay: boolean,
  },
> {
  render() {
    const virtualLocation = this.props.event.virtualLocation;

    // Do not show the location if there is no legacy value
    if (!virtualLocation) return null;

    return (
      <Container>
        <IconWrapper>
          <EventVirtualLocationTypeIcon locationType="virtual" />
        </IconWrapper>
        {isValidWebsite(virtualLocation) ? (
          <StyledLink
            title={virtualLocation.length > 20 ? virtualLocation : ''}
            href={virtualLocation}
            target="_blank"
            rel="noopener noreferrer"
          >
            {virtualLocation}
          </StyledLink>
        ) : (
          <Label>{virtualLocation}</Label>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  Location,
  graphql`
    fragment Location_event on Event {
      virtualLocation
    }
  `,
);
