/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Match, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import publicRuleNames from 'config/publicRuleNames';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import FilterItemHeader from '../FilterItemHeader';
import UserItemsContainer from './UserItemsContainer';

import type { UserFilterItemQueryResponse } from './__generated__/UserFilterItemQuery.graphql';

const Container = styled.div`
  flex: 1 1 auto;
  padding: 0 0 0 10px;
`;

const query = graphql`
  query UserFilterItemQuery(
    $query: String!
    $userIds: [ID!]!
    $hasTeamAccess: Boolean!
    $eventIds: [ID!]
    $publicRuleName: String!
    $orgSlug: String
    $resourceToken: String!
  ) {
    query {
      publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
      ...UserItemsContainer_query
    }
  }
`;

class UserFilterItem extends React.Component<
  {
    match: Match,
    activeValues: ?$ReadOnlyArray<string>,
    label: string,
    name: string,
    onChange: (name: string, activeValues: ?Array<string>) => void,
    expanded?: boolean,
    searchPlaceholder?: string,
    hasTeamAccess?: boolean,
    eventId?: string,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleFilterClear = () => {
    this.props.onChange(this.props.name, []);
  };

  handleChange = (name: string, activeValues: ?Array<string>, queryVal: string) => {
    this.setState({ query: queryVal });
    this.props.onChange(name, activeValues);
  };

  render() {
    return (
      <FilterItemHeader
        label={this.props.label}
        count={this.props.activeValues ? this.props.activeValues.length : 0}
        onClear={this.handleFilterClear}
        expanded={this.props.expanded}
      >
        <Container>
          <DefaultQueryRenderer
            public={!!this.props.match.params.resourceToken}
            query={query}
            variables={{
              query: this.state.query,
              userIds: this.props.activeValues || [],
              hasTeamAccess: !!this.props.hasTeamAccess,
              publicRuleName: publicRuleNames.PUBLIC_EVENT_LIST,
              resourceToken: this.props.match.params.resourceToken || '',
              orgSlug: this.props.match.params.orgName,
              eventIds: [this.props.eventId].filter(Boolean),
            }}
            renderSuccess={(response: UserFilterItemQueryResponse) => (
              <UserItemsContainer
                query={response.query}
                activeValues={this.props.activeValues}
                name={this.props.name}
                onChange={this.handleChange}
                searchPlaceholder={this.props.searchPlaceholder}
              />
            )}
            renderLoading={() => (
              <UserItemsContainer
                query={null}
                activeValues={this.props.activeValues}
                name={this.props.name}
                onChange={this.handleChange}
                searchPlaceholder={this.props.searchPlaceholder}
              />
            )}
          />
        </Container>
      </FilterItemHeader>
    );
  }
}

export default withRouter(UserFilterItem);
