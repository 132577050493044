/**
 * @flow
 * @relayHash 4b6b1a8e0549e02b7ad19118db59bf63
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AllEventsCalendarEventWindowContent_event$ref = any;
export type AllEventsCalendarEventWindowPublicQueryVariables = {|
  eventSlug: string,
  publicRuleName: string,
  resourceToken: string,
  orgSlug?: ?string,
|};
export type AllEventsCalendarEventWindowPublicQueryResponse = {|
  +publicRule: boolean,
  +eventList: ?{|
    +savedListColumns: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +columnName: ?string
        |}
      |}>
    |}
  |},
  +event: ?{|
    +$fragmentRefs: AllEventsCalendarEventWindowContent_event$ref
  |},
|};
export type AllEventsCalendarEventWindowPublicQuery = {|
  variables: AllEventsCalendarEventWindowPublicQueryVariables,
  response: AllEventsCalendarEventWindowPublicQueryResponse,
|};
*/


/*
query AllEventsCalendarEventWindowPublicQuery(
  $eventSlug: String!
  $publicRuleName: String!
  $resourceToken: String!
  $orgSlug: String
) {
  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
  eventList(resourceToken: $resourceToken) {
    savedListColumns {
      edges {
        node {
          columnName
          id
        }
      }
    }
    id
  }
  event(slug: $eventSlug) {
    ...AllEventsCalendarEventWindowContent_event
    id
  }
}

fragment AllEventsCalendarEventWindowContent_event on Event {
  name
  startDate
  endDate
  startDateAllDay
  endDateAllDay
  tz
  lead {
    id
    firstName
    lastName
    email
  }
  ...AllEventsCalendarEventWindowLocation_event
}

fragment AllEventsCalendarEventWindowLocation_event on Event {
  virtualLocation
  primaryLocation {
    city
    name
    country
    state
    id
  }
  zoomLocation {
    kind
    joinUrl
    id
  }
  g2wLocation {
    registrationUrl
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "publicRuleName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "resourceToken",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgSlug",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicRule",
  "args": [
    {
      "kind": "Variable",
      "name": "orgSlug",
      "variableName": "orgSlug",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "publicRuleName",
      "variableName": "publicRuleName",
      "type": "String!"
    },
    {
      "kind": "Variable",
      "name": "resourceToken",
      "variableName": "resourceToken",
      "type": "String"
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "resourceToken",
    "variableName": "resourceToken",
    "type": "String!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "columnName",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AllEventsCalendarEventWindowPublicQuery",
  "id": null,
  "text": "query AllEventsCalendarEventWindowPublicQuery(\n  $eventSlug: String!\n  $publicRuleName: String!\n  $resourceToken: String!\n  $orgSlug: String\n) {\n  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)\n  eventList(resourceToken: $resourceToken) {\n    savedListColumns {\n      edges {\n        node {\n          columnName\n          id\n        }\n      }\n    }\n    id\n  }\n  event(slug: $eventSlug) {\n    ...AllEventsCalendarEventWindowContent_event\n    id\n  }\n}\n\nfragment AllEventsCalendarEventWindowContent_event on Event {\n  name\n  startDate\n  endDate\n  startDateAllDay\n  endDateAllDay\n  tz\n  lead {\n    id\n    firstName\n    lastName\n    email\n  }\n  ...AllEventsCalendarEventWindowLocation_event\n}\n\nfragment AllEventsCalendarEventWindowLocation_event on Event {\n  virtualLocation\n  primaryLocation {\n    city\n    name\n    country\n    state\n    id\n  }\n  zoomLocation {\n    kind\n    joinUrl\n    id\n  }\n  g2wLocation {\n    registrationUrl\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AllEventsCalendarEventWindowPublicQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventList",
        "storageKey": null,
        "args": v2,
        "concreteType": "EventList",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "savedListColumns",
            "storageKey": null,
            "args": null,
            "concreteType": "SavedListColumnTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedListColumnTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SavedListColumnType",
                    "plural": false,
                    "selections": [
                      v3
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v4,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AllEventsCalendarEventWindowContent_event",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllEventsCalendarEventWindowPublicQuery",
    "argumentDefinitions": v0,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventList",
        "storageKey": null,
        "args": v2,
        "concreteType": "EventList",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "savedListColumns",
            "storageKey": null,
            "args": null,
            "concreteType": "SavedListColumnTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SavedListColumnTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SavedListColumnType",
                    "plural": false,
                    "selections": [
                      v3,
                      v5
                    ]
                  }
                ]
              }
            ]
          },
          v5
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v4,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v6,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "startDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "startDateAllDay",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endDateAllDay",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tz",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "lead",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "virtualLocation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "primaryLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "Location",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "city",
                "args": null,
                "storageKey": null
              },
              v6,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "country",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "state",
                "args": null,
                "storageKey": null
              },
              v5
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "zoomLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "ZoomLocation",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "kind",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "joinUrl",
                "args": null,
                "storageKey": null
              },
              v5
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "g2wLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "G2WLocation",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "registrationUrl",
                "args": null,
                "storageKey": null
              },
              v5
            ]
          },
          v5
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bb0e1c868415b366672b61ecf8e0a622';
module.exports = node;
