import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import getSupportAccessToken from 'utils/getSupportAccessToken';
import supportLogout from 'utils/authentication/supportLogout';

function fetchQuery(operation, variables) {
  return fetch('/graphql/query', {
    method: 'POST',
    headers: {
      Authorization: getSupportAccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then(response => {
    if (response.status === 401) {
      supportLogout();
      return Promise.reject(new Error(
        'Authentication token invalid.',
      ));
    }
    if (!response.ok) {
      return Promise.reject(new Error(
        'Sorry, there was a problem making this request.',
      ));
    }
    return response.json().then(json => {
      if (json.errors) {
        return Promise.reject(new Error(
          json.errors.map(error => error.message).join(', '),
        ));
      }
      return Promise.resolve(json);
    });
  });
}

const source = new RecordSource();
const store = new Store(source);
const network = Network.create(fetchQuery);

const environment = new Environment({
  network,
  store,
});

/**
 * Return the singleton environment/store
 */
export default function getRelayModernEnvironment() {
  return environment;
}
