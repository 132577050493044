/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import TeamStylesContent from './TeamStylesContent';

import type { TeamStylesQueryResponse } from './__generated__/TeamStylesQuery.graphql';

const query = graphql`
  query TeamStylesQuery($id: ID!) {
    team: node(id: $id) {
      ... on Team {
        id
        ...TeamStylesContent_team
      }
    }
  }
`;

const Container = styled.div`
  padding: 15px 25px 24px;
`;

export default class TeamStyles extends React.Component<{
  teamId: string,
}> {
  render() {
    return (
      <Container>
        <DefaultQueryRenderer
          variables={{
            id: this.props.teamId,
          }}
          query={query}
          renderSuccess={(props: TeamStylesQueryResponse) =>
            props.team ? <TeamStylesContent team={props.team} /> : <div>not found</div>
          }
        />
      </Container>
    );
  }
}
