/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';

import publicRuleNames from 'config/publicRuleNames';

import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import storage from 'utils/storage';

import { getDefaultFilters, suggestedFields } from 'components/AllEvents/eventsTableColumnSettings';
import type { ParsedAllEventsFilters } from 'components/AllEvents/lib/parseAllEventsFilters';
import parseDateFilters from 'components/AllEvents/lib/parseDateFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';
import type { ColumnGroupConfiguration } from 'components/material/table';

import { type SharedEventListType } from '.';
import AllEventsListShareHeaderBar from './AllEventsListShareHeaderBar';
import AllEventsMapShareContainer from './AllEventsMapShareContainer';

import type { AllEventsMapSharePageContainerQueryResponse } from './__generated__/AllEventsMapSharePageContainerQuery.graphql';
import { type AllEventsShareContainer_org } from './__generated__/AllEventsShareContainer_org.graphql';

const AllEventsSharedMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
`;

const AllEventsMapWrapperContainer = styled.div`
  height: 100%;

  > * {
    height: 100% !important;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 50px 0;
`;

const StyledLoading = styled(Loader)`
  background: #fff;
`;

const query = graphql`
  query AllEventsMapSharePageContainerQuery(
    $isShared: Boolean!
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
    $count: Int!
    $filters: EventFilters!
  ) {
    ... @include(if: $isShared) {
      publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
      eventList(resourceToken: $resourceToken) {
        events(first: $count, filters: $filters) {
          totalCount
        }
        ...AllEventsMapShareContainer_eventList
      }
    }
    ... @skip(if: $isShared) {
      me {
        events(first: $count, filters: $filters) {
          totalCount
        }
        ...AllEventsMapShareContainer_eventList
      }
    }
  }
`;

export default class AllEventsMapSharePageContainer extends React.Component<
  {
    eventList: SharedEventListType,
    org: AllEventsShareContainer_org,
    userEmail: ?string,
    eventFields: $ReadOnlyArray<FieldType>,
    groups: ColumnGroupConfiguration,
    filters: ParsedAllEventsFilters,
    history: RouterHistory,
    match: Match,
    pathPrefix: string,
  },
  { eventsCount: number },
> {
  state = { eventsCount: 0 };

  componentDidMount() {
    const beforeDate = this.props.eventList.beforeDate
      ? moment(this.props.eventList.beforeDate)
      : null;

    const skipDateFilter =
      storage.get(`${this.props.eventList.id}_noFilterSharedEventList`) || false;
    if (
      !this.filtered() &&
      !['UPCOMING', 'PAST'].includes(this.props.eventList.listType) &&
      (!beforeDate || beforeDate.isAfter(moment().startOf('day'))) &&
      !skipDateFilter
    ) {
      const queryParams = {
        date: 'upcoming',
      };
      replaceQueryParams(this.props.history, queryParams);
    }
  }

  handleEmptyFilterApply = () => {
    const appliedFilters = Object.keys(this.props.filters).filter(
      k => this.props.filters[k] != null,
    );

    // Check if only date filter were applied
    if (
      appliedFilters.length === 1 &&
      Object.prototype.hasOwnProperty.call(this.props.filters, 'date') &&
      this.props.filters.date != null &&
      this.props.filters.date.key === 'upcoming'
    ) {
      const storageKey = `${this.props.eventList.id}_noFilterSharedEventList`;
      storage.set(storageKey, true);
    }
  };

  filtered = () => Object.values(this.props.filters).some(val => val != null);

  setEventsCount = (eventsCount: number) => {
    if (this.state.eventsCount !== eventsCount) {
      this.setState({ eventsCount });
    }
  };

  render() {
    const {
      filters,
      org,
      eventList,
      eventFields,
      userEmail,
      groups,
      history,
      match,
      pathPrefix,
    } = this.props;

    const {
      search,
      sort,
      month,
      date,
      requestedDate,
      updatedAt,
      createdAt,
      ...otherFilters
    } = filters;
    const defaultFilters = getDefaultFilters(otherFilters);
    // Default date filter to Upcoming/Past events depending on current tab
    const dateParams = parseDateFilters(date);
    const customFilters = eventFields
      .filter(
        option =>
          (option.fieldName == null || suggestedFields.includes(option.fieldName)) &&
          otherFilters[option.id] != null,
      )
      .map(option => getCustomFieldVariable(otherFilters[option.id], option));

    const isPreviewing = !match.params.resourceToken;
    const variables = {
      isShared: !isPreviewing,
      publicRuleName: publicRuleNames.PUBLIC_EVENT_LIST,
      resourceToken: match.params.resourceToken || '',
      orgSlug: match.params.orgName,
      count: 500,
      filters: {
        search: isPreviewing ? search : undefined,
        ...defaultFilters,
        ...dateParams,
        requestedDate,
        createdAt,
        updatedAt,
        customFilters,
      },
    };

    const csvExportVariables = variables.filters;

    return (
      <>
        <AllEventsListShareHeaderBar
          userEmail={userEmail}
          history={history}
          filters={filters}
          org={org}
          eventList={eventList}
          groups={groups}
          eventFields={eventFields}
          viewMode="map"
          csvExportVariables={csvExportVariables}
          eventsCount={this.state.eventsCount}
          pathPrefix={pathPrefix}
          isPreviewing={isPreviewing}
          handleEmptyFilterApply={this.handleEmptyFilterApply}
        />
        <AllEventsSharedMapContainer>
          <AllEventsMapWrapperContainer>
            <DefaultQueryRenderer
              query={query}
              variables={variables}
              public={!!match.params.resourceToken}
              renderLoading={() => (
                <LoadingWrapper>
                  <StyledLoading size={30} />
                </LoadingWrapper>
              )}
              renderSuccess={(response: AllEventsMapSharePageContainerQueryResponse) => {
                const eventListInterface = response.eventList || response.me;

                if (eventListInterface) {
                  this.setEventsCount(eventListInterface.events.totalCount);
                }

                return <AllEventsMapShareContainer eventList={eventListInterface} />;
              }}
            />
          </AllEventsMapWrapperContainer>
        </AllEventsSharedMapContainer>
      </>
    );
  }
}
