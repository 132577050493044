/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import FilterButton from './FilterButton';
import FiltersOverlay from './FiltersOverlay';

export const FilterContext = React.createContext<{ hideOverlay: () => void }>({
  hideOverlay: () => {},
});

const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 113px;
  margin-left: auto;

  @media (${props =>
      props.mobileBreakpoint ? `max-width: ${props.mobileBreakpoint}px` : props.theme.mobileOnly}) {
    min-width: 59px;

    > div > div > span {
      display: none;
    }
    > div > div > svg:first-of-type {
      margin-right: 0;
    }
    > div > div > span + div {
      margin-left: 8px;
    }
  }
`;

const Container = styled.div`
  position: relative;
  align-self: baseline;
  margin-left: auto;
  padding: ${props => (props.count === 0 ? '4px 10px' : '4px 4px 4px 10px')};
  border-radius: 15px;
  white-space: nowrap;
  font-size: 13px;
  cursor: pointer;
  background-color: #edf0fc;
  color: #4069e1;
  -webkit-tap-highlight-color: transparent;
`;

export default class AdvancedFilterWrapper<F: {}> extends React.Component<
  {
    mobileBreakpoint?: number,
    className?: string,
    children: React.Node,
    count?: number,
    filters: F,
  },
  {
    showOverlay: boolean,
  },
> {
  state = {
    showOverlay: false,
  };

  containerRef = React.createRef();

  targetRef = React.createRef();

  getFiltersCount = () => {
    const { filters, count } = this.props;

    if (count != null) {
      return count;
    }

    return Object.keys(filters).filter(
      name => !['sort', 'search', 'query'].includes(name) && filters[name] != null,
    ).length;
  };

  handleShowFilters = () => {
    this.setState({ showOverlay: true });
  };

  handleOverlayHide = () => {
    this.setState({ showOverlay: false });
  };

  render() {
    const { mobileBreakpoint, className, children } = this.props;
    // FilterWrapper used for fixing moving overlay issue, when changing filters or resetting
    return (
      <FilterWrapper
        className={className}
        ref={this.containerRef}
        mobileBreakpoint={mobileBreakpoint}
        onClickCapture={this.handleShowFilters}
      >
        <Container count={this.getFiltersCount()}>
          <FilterButton count={this.getFiltersCount()} ref={this.targetRef} />
          <FiltersOverlay
            show={this.state.showOverlay}
            container={this.containerRef.current}
            target={this.targetRef.current}
            onHide={this.handleOverlayHide}
            forceRightEdge
          >
            <FilterContext.Provider value={{ hideOverlay: this.handleOverlayHide }}>
              {children}
            </FilterContext.Provider>
          </FiltersOverlay>
        </Container>
      </FilterWrapper>
    );
  }
}
