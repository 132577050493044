/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';
import { darken } from 'polished';

import downloadedBrief from 'utils/analytics/events/briefs/downloadedBrief';

import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import DocDownloadOverlay from 'components/Briefs/DocDownloadOverlay';
import Button from 'components/material/Button';

import type { BriefDownload_event } from './__generated__/BriefDownload_event.graphql';

const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1 1 auto;
  z-index: 100;
  background: #fff;
  @media (max-width: 308px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  margin: 15px 0 5px;
  padding: 7px 22px;
  background: #fff;
  border: 1px solid ${props => props.color};
  border-radius: 4px;
  font-weight: 500;
  color: ${props => props.color};
  &:hover {
    background: #fff;
    color: ${props => darken(0.1, props.color)};
  }
  &:nth-child(2) {
    margin-left: 25px;
  }
  @media (max-width: 480px) {
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
  @media (max-width: 308px) {
    &:first-child {
      margin-bottom: 0;
    }
    &:nth-child(2) {
      margin-left: 0;
    }
  }
`;

class BriefDownload extends React.Component<
  {
    event: BriefDownload_event,
    listToken?: string,
    userEmail?: ?string,
  },
  {
    showDocDownloadOverlay: boolean,
    showPdfDownloadOverlay: boolean,
  },
> {
  state = {
    showDocDownloadOverlay: false,
    showPdfDownloadOverlay: false,
  };

  handlePdfExport = () => {
    this.setState({ showPdfDownloadOverlay: true });
  };

  handlePdfDownload = () => {
    const { event } = this.props;
    downloadedBrief({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'pdf',
      fromWindow: 'event brief',
    });
  };

  handlePdfDownloadEnd = () => {
    this.setState({ showPdfDownloadOverlay: false });
  };

  handleDocExport = () => {
    this.setState({ showDocDownloadOverlay: true });
  };

  handleDocDownload = () => {
    const { event } = this.props;
    downloadedBrief({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'doc',
      fromWindow: 'event brief',
    });
  };

  handleDocExportEnd = () => {
    this.setState({ showDocDownloadOverlay: false });
  };

  render() {
    const {
      userEmail,
      listToken,
      event: { viewerCanExportBriefDoc, exportPdfUrl, exportDocUrl, name: eventName },
    } = this.props;
    return (
      <Container>
        <StyledButton label="Download PDF" onClick={this.handlePdfExport} color="#e2574c" />
        {!listToken &&
          viewerCanExportBriefDoc && (
            <StyledButton label="Download G Doc" onClick={this.handleDocExport} color="#518ef8" />
          )}
        {this.state.showDocDownloadOverlay && (
          <DocDownloadOverlay
            exportDocUrl={exportDocUrl}
            fileName={eventName}
            onHide={this.handleDocExportEnd}
            onDownload={this.handleDocDownload}
          />
        )}
        {this.state.showPdfDownloadOverlay && (
          <DownloadOverlayWithEmail
            downloadLink={`${exportPdfUrl}&list_token=${listToken || ''}`}
            fileName={`${eventName}.pdf`}
            onHide={this.handlePdfDownloadEnd}
            email={userEmail}
            exportable="brief"
            onDownload={this.handlePdfDownload}
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  BriefDownload,
  graphql`
    fragment BriefDownload_event on Event {
      slug
      dbId
      name
      exportDocUrl
      exportPdfUrl
      viewerCanExportBriefDoc
      team {
        dbId
        name
      }
    }
  `,
);
