/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import { type CustomFieldInput } from 'utils/customization/types';

import massUpdateCompanies from 'graph/mutations/company/massUpdateCompanies';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button, { MinimalButton } from 'components/budget/Button';
import SelectField from 'components/material/SelectField';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import UserSelect, { type User } from 'components/UserSelect';

const CompaniesCountContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

const Row = styled.div`
  margin-top: 20px;
`;

const Footer = styled.div`
  margin-top: 40px;
  text-align: right;

  > *:last-child {
    margin-left: 20px;
  }
`;

export default class CompaniesUpdateWindow extends React.PureComponent<
  {
    eventId?: ?string,
    onHide: () => void,
    onUpdateTableColumnWidths: () => void,
    selectedCompanies: $ReadOnlyArray<string>,
    companyFields: $ReadOnlyArray<FieldType>,
  },
  {
    selectFields: $ReadOnlyArray<CustomFieldInput>,
    userSelectFields: $ReadOnlyArray<{ customFieldId: string, user: ?User }>,
  },
> {
  state = {
    selectFields: [],
    userSelectFields: [],
  };

  handleSave = () => {
    massUpdateCompanies({
      eventId: this.props.eventId,
      companyIds: this.props.selectedCompanies,
      customFieldValues: [
        ...this.state.selectFields,
        ...this.state.userSelectFields.map(select => ({
          customFieldId: select.customFieldId,
          userValue: select.user ? select.user.id : null,
        })),
      ],
    })
      .then(() => {
        this.props.onHide();
        this.props.onUpdateTableColumnWidths();
      })
      .catch(err => {
        showModernMutationError(err);
      });
  };

  handleUpdateSelectField = (customFieldId: string, selectValue: ?string) => {
    if (selectValue != null) {
      this.setState(state => ({
        selectFields: [
          ...state.selectFields.filter(item => item.customFieldId !== customFieldId),
          { customFieldId, selectValue: selectValue != null ? selectValue : '' },
        ],
      }));
    } else {
      this.setState(state => ({
        selectFields: state.selectFields.filter(item => item.customFieldId !== customFieldId),
      }));
    }
  };

  handleUpdateUserSelectField = (customFieldId: string, user: ?User) => {
    if (user != null) {
      this.setState(state => ({
        userSelectFields: [
          ...state.userSelectFields.filter(item => item.customFieldId !== customFieldId),
          { customFieldId, user },
        ],
      }));
    } else {
      this.setState(state => ({
        userSelectFields: state.userSelectFields.filter(
          item => item.customFieldId !== customFieldId,
        ),
      }));
    }
  };

  render() {
    const { onHide, selectedCompanies, companyFields } = this.props;

    return (
      <Window onHide={onHide} size={438}>
        <WindowHeader>
          <WindowTitle>
            Update Info
            <CompaniesCountContainer>
              {`${selectedCompanies.length} ${
                selectedCompanies.length === 1 ? ' company' : ' companies'
              } selected`}
            </CompaniesCountContainer>
          </WindowTitle>
          <WindowClose onClick={onHide} />
        </WindowHeader>
        <WindowContent>
          {companyFields
            .filter(field => field.kind === 'SELECT' || field.kind === 'USER_SELECT')
            .map(field => {
              if (field.kind === 'SELECT') {
                const selectField = this.state.selectFields.find(
                  customSelect => customSelect.customFieldId === field.id,
                );
                return (
                  <Row key={field.id}>
                    <SelectField
                      label={field.label}
                      value={selectField ? selectField.selectValue : null}
                      onChange={value => {
                        this.handleUpdateSelectField(field.id, value);
                      }}
                      options={field.options}
                      searchable
                      clearable
                    />
                  </Row>
                );
              }

              const userSelectField = this.state.userSelectFields.find(
                customUserSelect => customUserSelect.customFieldId === field.id,
              );
              return (
                <Row key={field.id}>
                  <UserSelect
                    label={field.label}
                    user={userSelectField ? userSelectField.user : null}
                    onSelect={selectUser => {
                      this.handleUpdateUserSelectField(field.id, selectUser);
                    }}
                    clearable
                  />
                </Row>
              );
            })}

          <Footer>
            <MinimalButton label="Cancel" onClick={onHide} />
            <Button onClick={this.handleSave}>Update</Button>
          </Footer>
        </WindowContent>
      </Window>
    );
  }
}
