/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationFormActionsBar_event$ref = any;
type RegistrationFormSubmissionContent_registrationForm$ref = any;
type RegistrationResults_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExistingRegistrationForm_event$ref: FragmentReference;
export type ExistingRegistrationForm_event = {|
  +slug: string,
  +tz: string,
  +registrationForm: ?{|
    +id: string,
    +publishedAt: ?any,
    +publicResourceToken: {|
      +token: string
    |},
    +$fragmentRefs: RegistrationFormSubmissionContent_registrationForm$ref,
  |},
  +$fragmentRefs: RegistrationFormActionsBar_event$ref & RegistrationResults_event$ref,
  +$refType: ExistingRegistrationForm_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExistingRegistrationForm_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "registrationForm",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationForm",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "publishedAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "publicResourceToken",
          "storageKey": null,
          "args": null,
          "concreteType": "PublicResourceToken",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "token",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "RegistrationFormSubmissionContent_registrationForm",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationFormActionsBar_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationResults_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bb22b57e68d553104a111c8d18ffa7c1';
module.exports = node;
