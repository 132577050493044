/* @flow */

import isValidWebsite from 'utils/validators/isValidWebsite';

import mappingKinds from './mappingKinds';
import { type QuestionType, type QuestionValueType } from './types';

const textAreaValidator = (question, { textareaValue }) => {
  const stringValue = (textareaValue || '').trim();
  const min = question.textMinLength != null ? question.textMinLength : 0;
  const max = question.textMaxLength != null ? question.textMaxLength : Infinity;

  if (question.required && !stringValue) {
    return 'Required';
  }

  if (stringValue.length === 0) return null;

  if (max !== Infinity && (stringValue.length < min || stringValue.length > max)) {
    return `Please use ${min} to ${max} characters`;
  }

  if (stringValue.length < min) {
    return `Min ${min} characters`;
  }

  return null;
};

const mappingValueValidators: $ObjMap<
  typeof mappingKinds,
  () => (QuestionType, QuestionValueType) => ?string,
> = {
  NAME: (question, { textValue }) =>
    !question.required || (textValue || '').trim() ? null : 'Required',
  LOCATION: (question, { locationValue, textValue }) => {
    if (question.required && !locationValue && !textValue) {
      return 'Required';
    }

    if (textValue && !isValidWebsite(textValue)) {
      return 'Invalid url';
    }

    return null;
  },
  DATE: (question, { dateValue }) => (!question.required || dateValue ? null : 'Required'),
  WEBSITE: (question, { textValue }) =>
    (textValue ? !isValidWebsite(textValue) && 'Invalid url' : question.required && 'Required') ||
    null,
  DESCRIPTION: textAreaValidator,
  NOTE: textAreaValidator,
  ATTACHMENTS: (question, { filesValue }) =>
    !question.required || filesValue.length > 0 ? null : 'Required',
  BOOTH: (question, { textValue }) =>
    !question.required || (textValue || '').trim() ? null : 'Required',
  BOOTH_DIMENSIONS: (question, { textValue }) =>
    !question.required || (textValue || '').trim() ? null : 'Required',
  FLOOR_PLAN: textAreaValidator,
  LEADER: (question, { selectIdsValue }) =>
    !question.required || selectIdsValue.length > 0 ? null : 'Required',
  EXPENSE: (question, { textValue, numberValue }) => {
    const nameFilled = question.expenseName || !!(textValue || '').trim();
    const amountFilled = numberValue != null;

    if (question.required) {
      if (nameFilled && !amountFilled) {
        return 'Expense amount is required';
      }

      if (!nameFilled || !amountFilled) {
        return 'Fields are required';
      }
    } else if (!question.expenseName && nameFilled !== amountFilled) {
      return 'Fill either both or none';
    }

    return null;
  },
  BUDGETED_AMOUNT: (question, { numberValue }) =>
    !question.required || numberValue != null ? null : 'Required',
  PLANNED_BUDGET: (question, { budgetCategoryId, numberValue }) => {
    const categoryFilled = question.budgetCategory || !!budgetCategoryId;
    const amountFilled = numberValue != null;

    if (question.required) {
      if (categoryFilled && !amountFilled) {
        return 'Amount is required';
      }

      if (!categoryFilled || !amountFilled) {
        return 'Fields are required';
      }
    } else if (!question.budgetCategory && categoryFilled !== amountFilled) {
      return 'Fill either both or none';
    }

    return null;
  },
};

export default mappingValueValidators;
