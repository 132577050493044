/**
 * @flow
 * @relayHash e5085b731105ae3da0e794a9010f306e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
type NotesUpdater_user$ref = any;
export type NotesBriefRootQueryVariables = {|
  eventSlug: string,
  noteIds: $ReadOnlyArray<string>,
  listToken?: ?string,
  sharedView: boolean,
|};
export type NotesBriefRootQueryResponse = {|
  +event: ?{|
    +briefSettings?: {|
      +notes: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +name: string,
            +updatedAt: any,
            +content: string,
            +updater: ?{|
              +$fragmentRefs: NotesUpdater_user$ref & MaterialAvatar_user$ref
            |},
            +attachments: {|
              +edges: $ReadOnlyArray<{|
                +node: {|
                  +id: string,
                  +fileurl: ?string,
                  +filename: ?string,
                  +filetype: ?string,
                  +filesize: ?number,
                |}
              |}>
            |},
          |}
        |}>
      |}
    |},
    +eventNotes?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +updatedAt: any,
          +content: string,
          +updater: ?{|
            +$fragmentRefs: NotesUpdater_user$ref & MaterialAvatar_user$ref
          |},
          +attachments: {|
            +edges: $ReadOnlyArray<{|
              +node: {|
                +id: string,
                +fileurl: ?string,
                +filename: ?string,
                +filetype: ?string,
                +filesize: ?number,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |}
|};
export type NotesBriefRootQuery = {|
  variables: NotesBriefRootQueryVariables,
  response: NotesBriefRootQueryResponse,
|};
*/


/*
query NotesBriefRootQuery(
  $eventSlug: String!
  $noteIds: [ID!]!
  $listToken: String
  $sharedView: Boolean!
) {
  event(slug: $eventSlug, listToken: $listToken) {
    briefSettings @include(if: $sharedView) {
      notes {
        edges {
          node {
            id
            name
            updatedAt
            content
            updater {
              ...NotesUpdater_user
              ...MaterialAvatar_user
              id
            }
            attachments {
              edges {
                node {
                  id
                  fileurl
                  filename
                  filetype
                  filesize
                }
              }
            }
          }
        }
      }
      id
    }
    eventNotes(noteIds: $noteIds) @skip(if: $sharedView) {
      edges {
        node {
          id
          name
          updatedAt
          content
          updater {
            ...NotesUpdater_user
            ...MaterialAvatar_user
            id
          }
          attachments {
            edges {
              node {
                id
                fileurl
                filename
                filetype
                filesize
              }
            }
          }
        }
      }
    }
    id
  }
}

fragment NotesUpdater_user on Assignable {
  firstName
  lastName
  ...BriefUserAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}

fragment BriefUserAvatar_user on Assignable {
  avatar
  ...MaterialAvatar_user
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "noteIds",
    "type": "[ID!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "listToken",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sharedView",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "listToken",
    "variableName": "listToken",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "content",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attachments",
  "storageKey": null,
  "args": null,
  "concreteType": "AttachmentRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AttachmentRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Attachment",
          "plural": false,
          "selections": [
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "fileurl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filename",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filetype",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filesize",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v7 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "EventNoteRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "EventNote",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updater",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "NotesUpdater_user",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "MaterialAvatar_user",
                "args": null
              }
            ]
          },
          v6
        ]
      }
    ]
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "noteIds",
    "variableName": "noteIds",
    "type": "[ID!]"
  }
],
v9 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "EventNoteRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "EventNote",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updater",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "avatar",
                "args": null,
                "storageKey": null
              },
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v6
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NotesBriefRootQuery",
  "id": null,
  "text": "query NotesBriefRootQuery(\n  $eventSlug: String!\n  $noteIds: [ID!]!\n  $listToken: String\n  $sharedView: Boolean!\n) {\n  event(slug: $eventSlug, listToken: $listToken) {\n    briefSettings @include(if: $sharedView) {\n      notes {\n        edges {\n          node {\n            id\n            name\n            updatedAt\n            content\n            updater {\n              ...NotesUpdater_user\n              ...MaterialAvatar_user\n              id\n            }\n            attachments {\n              edges {\n                node {\n                  id\n                  fileurl\n                  filename\n                  filetype\n                  filesize\n                }\n              }\n            }\n          }\n        }\n      }\n      id\n    }\n    eventNotes(noteIds: $noteIds) @skip(if: $sharedView) {\n      edges {\n        node {\n          id\n          name\n          updatedAt\n          content\n          updater {\n            ...NotesUpdater_user\n            ...MaterialAvatar_user\n            id\n          }\n          attachments {\n            edges {\n              node {\n                id\n                fileurl\n                filename\n                filetype\n                filesize\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment NotesUpdater_user on Assignable {\n  firstName\n  lastName\n  ...BriefUserAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n\nfragment BriefUserAvatar_user on Assignable {\n  avatar\n  ...MaterialAvatar_user\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NotesBriefRootQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBriefTemplate",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "notes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventNoteRequiredConnection",
                    "plural": false,
                    "selections": v7
                  }
                ]
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": false,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventNotes",
                "storageKey": null,
                "args": v8,
                "concreteType": "EventNoteRequiredConnection",
                "plural": false,
                "selections": v7
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NotesBriefRootQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBriefTemplate",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "notes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventNoteRequiredConnection",
                    "plural": false,
                    "selections": v9
                  },
                  v2
                ]
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": false,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventNotes",
                "storageKey": null,
                "args": v8,
                "concreteType": "EventNoteRequiredConnection",
                "plural": false,
                "selections": v9
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '407f286ffd4173ddb5f5271ece65ecf0';
module.exports = node;
