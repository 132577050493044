/* @flow */

import type { CustomFieldInput, CustomFieldsType } from './types';

const convertCustomFieldValuesToInput = (
  customFieldValues: CustomFieldsType,
): $ReadOnlyArray<CustomFieldInput> => [
  ...customFieldValues.customTextFields.map(field => ({
    customFieldId: field.customField.id,
    textValue: field.value,
  })),
  ...customFieldValues.customTextareaFields.map(field => ({
    customFieldId: field.customField.id,
    textareaValue: field.value,
  })),
  ...customFieldValues.customLinkFields.map(field => ({
    customFieldId: field.customField.id,
    linkValue: field.value,
  })),
  ...customFieldValues.customNumberFields.map(field => ({
    customFieldId: field.customField.id,
    numberValue: field.value,
  })),
  ...customFieldValues.customCurrencyFields.map(field => ({
    customFieldId: field.customField.id,
    currencyValue: field.value,
  })),
  ...customFieldValues.customDateFields.map(field => ({
    customFieldId: field.customField.id,
    dateValue: field.value,
  })),
  ...customFieldValues.customBooleanFields.map(field => ({
    customFieldId: field.customField.id,
    booleanValue: field.value,
  })),
  ...customFieldValues.customUserSelectFields.map(field => ({
    customFieldId: field.customField.id,
    userValue: field.user.id,
  })),
  ...customFieldValues.customUserMultiselectFields.reduce((array, field) => {
    const existingField = array.find(item => item.customFieldId === field.customField.id);

    if (existingField) {
      existingField.userValues.push(field.user.id);

      return array;
    }

    return [...array, { customFieldId: field.customField.id, userValues: [field.user.id] }];
  }, []),
  ...customFieldValues.customSelectFields.map(field => ({
    customFieldId: field.customField.id,
    selectValue: field.selectOtherValue ? null : field.option.id,
    selectOtherValue: field.selectOtherValue,
  })),
  ...customFieldValues.customMultiselectFields.reduce((array, field) => {
    const existingField = array.find(item => item.customFieldId === field.customField.id);

    if (existingField) {
      if (field.option.id !== 'other') {
        existingField.selectValues.push(field.option.id);
      }
      if (field.selectOtherValue !== null) {
        existingField.selectOtherValue = field.selectOtherValue;
      }

      return array;
    }

    return [
      ...array,
      {
        customFieldId: field.customField.id,
        selectValues: field.option.id !== 'other' ? [field.option.id] : [],
        ...(field.selectOtherValue !== null ? { selectOtherValue: field.selectOtherValue } : {}),
      },
    ];
  }, []),
];

export default convertCustomFieldValuesToInput;
