/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Remove from 'images/remove.svg';

const RemoveIcon = styled.div`
  margin: 0 10px;
  line-height: 20px;
  visibility: hidden;
  text-align: center;
  cursor: pointer;
  &:hover {
    visibility: visible;
  }
`;

const Icon = styled(Remove)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  padding: 10px 0 10px 10px;
  border-left: 5px solid #f1f9fc;
  font-size: 13px;
  color: ${props => (props.disabled ? props.theme.labelColor : props.theme.rowPrimaryTextColor)};
  &:hover {
    background-color: ${props => (props.disabled ? 'inherit' : '#f1f9fc')};
    > ${RemoveIcon} {
      width: auto;
      visibility: visible;
    }
  }
  > :first-child {
    flex: 1 1 auto;
  }
`;

export default class SelectionRow extends React.Component<{
  value: string,
  disabled?: boolean,
  onRemove: ?(id: string) => void,
  children: React.Node,
}> {
  handleRemove = () => {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.value);
    }
  };

  render() {
    const { disabled, onRemove, children } = this.props;
    return (
      <Container disabled={disabled}>
        {children}
        {!disabled && onRemove && (
          <RemoveIcon onClick={this.handleRemove}>
            <Icon />
          </RemoveIcon>
        )}
      </Container>
    );
  }
}
