/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateEmbedComponentMutationVariables,
  UpdateEmbedComponentInput,
} from './__generated__/updateEmbedComponentMutation.graphql';

export type updateEmbedComponentPropertyType = $Shape<UpdateEmbedComponentInput>;

const mutation = graphql`
  mutation updateEmbedComponentMutation($input: UpdateEmbedComponentInput!) {
    updateRegistrationFormEmbedComponent(input: $input) {
      pageComponent {
        embedComponent {
          id
          code
          padding
          alignment
          backgroundColor
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateEmbedComponent(properties: updateEmbedComponentPropertyType) {
  const variables: updateEmbedComponentMutationVariables = {
    input: {
      ...properties,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateRegistrationFormEmbedComponent: {
        pageComponent: {
          embedComponent: properties,
        },
      },
    },
  });
}
