/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import Button from 'components/material/Button';

import AllEventsUpdateWindow from './AllEventsUpdateWindow';

const Container = styled.div`
  position: relative;
`;

const SelectionInfo = styled.span`
  min-width: 125px;
  padding: 9px 6px;
  color: #828b93;
  @media (max-width: 520px) {
    display: inline-block;
    min-width: unset;
    padding: 0;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    background-color: #29cc71;
    color: #fff;
    > span {
      display: none;
    }
  }
`;

const ActionButton = styled(Button)`
  margin-left: 10px;
  padding: 2px 13px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

export default class AllEventsMassUpdatePanel extends React.PureComponent<
  {
    selectedEvents: $ReadOnlyArray<string>,
    eventFields: $ReadOnlyArray<FieldType>,
    onUpdateTableColumnWidths: () => void,
  },
  {
    showUpdateWindow: boolean,
  },
> {
  state = {
    showUpdateWindow: false,
  };

  handleShowUpdateWindow = (): void => {
    this.setState({ showUpdateWindow: true });
  };

  handleHideUpdateWindow = (): void => {
    this.setState({ showUpdateWindow: false });
  };

  render() {
    const { selectedEvents, eventFields, onUpdateTableColumnWidths } = this.props;

    const eventsCount = selectedEvents.length;

    return (
      <Container>
        <SelectionInfo>
          {eventsCount} <span>{eventsCount > 1 ? 'Items' : 'Item'} selected</span>
        </SelectionInfo>
        <ActionButton label="Update Info" outline primary onClick={this.handleShowUpdateWindow} />
        {this.state.showUpdateWindow && (
          <AllEventsUpdateWindow
            selectedEvents={selectedEvents}
            eventFields={eventFields}
            onHide={this.handleHideUpdateWindow}
            onUpdateTableColumnWidths={onUpdateTableColumnWidths}
          />
        )}
      </Container>
    );
  }
}
