/* @flow */

import mappingKinds from './mappingKinds';
import { type QuestionType } from './types';

const textAreaValidator = question =>
  question.textMinLength != null &&
  question.textMaxLength != null &&
  question.textMaxLength < question.textMinLength
    ? 'Please correct the character range'
    : null;

const mappingQuestionValidators: $ObjMap<typeof mappingKinds, () => QuestionType => ?string> = {
  NAME: () => null,
  LOCATION: () => null,
  DATE: () => null,
  WEBSITE: () => null,
  DESCRIPTION: textAreaValidator,
  NOTE: textAreaValidator,
  ATTACHMENTS: question =>
    question.fileExtensions.length === 0 ? 'Please select at least one extension' : null,
  BOOTH: () => null,
  BOOTH_DIMENSIONS: () => null,
  FLOOR_PLAN: textAreaValidator,
  LEADER: question =>
    question.selectUsers.length === 0 ? 'Please select at least one option' : null,
  EXPENSE: () => null,
  BUDGETED_AMOUNT: () => null,
  PLANNED_BUDGET: question => {
    if (question.allowRequesterToChooseBudgetCategory) {
      return question.budgetCategories.length === 0 ? 'Please select at least one option' : null;
    }

    return question.budgetCategory == null ? 'Please select default planned budget amount' : null;
  },
};

export default mappingQuestionValidators;
