/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';

import { type DateConfig } from 'components/date/DateTimePicker';
import DateTimeOverlay from 'components/date/DateTimePicker/DateTimeOverlay';
import RemovablePill from 'components/material/RemovablePill';

const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0 0 20px -6px;
  @media (${props => props.theme.mobileOnly}) {
    display: inline-block;
  }
`;

const Row = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  font-size: 13px;
  padding: 3px 6px;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.primaryRowColor};
  }
  ${props =>
    props.overdue &&
    css`
      background: ${props.theme.negativeActionColor};
      color: ${props.theme.negativeActionTextColor};
      &:hover {
        background: ${props.theme.negativeActionDarkerColor};
      }
    `};
`;

const Label = styled.span`
  font-weight: 500;
`;

export default class TaskDate extends React.PureComponent<
  {
    date: ?string,
    allDay: ?boolean,
    onChange: ({
      date: ?string,
      allDay: ?boolean,
    }) => void,
    timezone: string,
    disabled?: boolean,
    onSectionRemove: string => void,
    completed?: boolean,
  },
  { overlayShown: boolean },
> {
  state = {
    overlayShown: false,
  };

  containerRef = React.createRef();

  componentDidMount() {
    if (!this.props.date) {
      this.handleOverlayShow();
    }
  }

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  handleOverlayShow = () => this.setState({ overlayShown: true });

  handleRemove = () => {
    this.props.onChange({ date: null, allDay: false });
    this.props.onSectionRemove('date');
  };

  handleChange = (dateConfig: DateConfig) => {
    this.props.onChange({ date: dateConfig.date, allDay: dateConfig.hideTime });
  };

  render() {
    const date = this.props.date ? moment(this.props.date).tz(this.props.timezone) : null;

    const now = moment().tz(this.props.timezone);
    const isOverdue =
      !this.props.completed && date && now.isAfter(date, this.props.allDay ? 'day' : null);

    return (
      <Container>
        <RemovablePill disabled={this.props.disabled} onRemove={this.handleRemove}>
          <Row
            onClick={!this.props.disabled && this.handleOverlayShow}
            ref={this.containerRef}
            overdue={isOverdue}
          >
            <Label>Due Date: </Label> {date ? formatDateTime(date, this.props.allDay) : null}
          </Row>
        </RemovablePill>

        <DateTimeOverlay
          show={this.state.overlayShown}
          target={this.containerRef.current}
          container={this.containerRef.current}
          onHide={this.handleOverlayHide}
          tz={this.props.timezone}
          onChange={this.handleChange}
          date={this.props.date}
          hideTime={this.props.allDay == null || this.props.allDay}
        />
      </Container>
    );
  }
}
