/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Button from 'components/material/Button';

import CreatePaymentWindow from './CreatePaymentWindow';
import Payment from './Payment';

import Payments_expense from './__generated__/Payments_expense.graphql';
import Payments_org from './__generated__/Payments_org.graphql';

const Container = styled.div`
  max-width: 1106px;
  margin: 0 0 50px 28px;
`;

const PaymentsContainer = styled.div`
  margin-top: 20px;
  border: 1px solid #d8d8d8;
`;

const Title = styled.div`
  padding: 18px 0 20px 0;
  color: #3e4859;
  font-size: 14px;
  font-weight: 500;
`;

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 41px;
  max-width: 1106px;
  border-bottom: 1px solid #d8d8d8;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
`;

const ScrollContainer = styled.div`
  padding-bottom: 25px;
  overflow-x: auto;
`;

const PaymentsBlock = styled.div`
  overflow-x: auto;
`;

const Header = styled.div`
  display: flex;
  width: 1106px;
  height: 40px;
  font-size: 13px;
  line-height: 40px;
`;

const Label = styled.div`
  width: ${props => props.width}px;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  color: #3e4859;
  font-weight: 500;
  text-align: ${props => (props.alignRight ? 'right' : 'left')};
`;

class Payments extends React.Component<
  {
    org: Payments_org,
    expense: Payments_expense,
  },
  {
    showNewPayment: boolean,
  },
> {
  state = {
    showNewPayment: false,
  };

  paymentsHeader: ?HTMLDivElement;

  paymentsBody: ?HTMLDivElement;

  ignoreHeaderScroll: boolean = false;

  ignoreBodyScroll: boolean = false;

  handleBodyScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    const paymentsHeader = this.paymentsHeader;

    if (!this.ignoreBodyScroll && paymentsHeader) {
      const target: HTMLDivElement = e.currentTarget;
      paymentsHeader.scrollLeft = target.scrollLeft;

      // This code handles Desktop Safari scroll bounce effect (when reaching edges) cancelling it.
      if (target.scrollLeft + target.offsetWidth + 3 > target.scrollWidth) {
        target.scrollLeft = target.scrollWidth - target.offsetWidth;
        paymentsHeader.scrollLeft = target.scrollWidth - target.offsetWidth;
      }
      if (target.scrollLeft < 1) {
        target.scrollLeft = 0;
        paymentsHeader.scrollLeft = 0;
      }
      this.ignoreHeaderScroll = true;
    }
    this.ignoreBodyScroll = false;
  };

  handleHeaderScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    const paymentsBody = this.paymentsBody;

    if (!this.ignoreHeaderScroll && paymentsBody) {
      const target: HTMLDivElement = e.currentTarget;
      paymentsBody.scrollLeft = target.scrollLeft;

      // This code handles Desktop Safari scroll bounce effect (when reaching edges) cancelling it.
      if (target.scrollLeft + target.offsetWidth + 3 > target.scrollWidth) {
        target.scrollLeft = target.scrollWidth - target.offsetWidth;
        paymentsBody.scrollLeft = target.scrollWidth - target.offsetWidth;
      }
      if (target.scrollLeft < 1) {
        target.scrollLeft = 0;
        paymentsBody.scrollLeft = 0;
      }
      this.ignoreBodyScroll = true;
    }
    this.ignoreHeaderScroll = false;
  };

  handleNewPaymentShow = () => {
    this.setState({ showNewPayment: true });
  };

  handleNewPaymentClose = () => {
    this.setState({ showNewPayment: false });
  };

  render() {
    const {
      org,
      expense: {
        id,
        actualAmount,
        payments,
        event: { tz },
      },
    } = this.props;
    const { showNewPayment } = this.state;
    return (
      <Container id="payments">
        <Title>Payments</Title>
        <Button label="Add Payment" primary onClick={this.handleNewPaymentShow} />
        <PaymentsContainer>
          <HeaderContainer>
            <ScrollContainer
              ref={el => {
                this.paymentsHeader = el;
              }}
              onScroll={this.handleHeaderScroll}
            >
              <Header>
                <Label width="85" marginLeft="50">
                  Type
                </Label>
                <Label alignRight width="149">
                  Amount
                </Label>
                <Label width="111" marginLeft="37">
                  Currency
                </Label>
                <Label width="106">% of Total</Label>
                <Label width="145">Due Date</Label>
                <Label width="145">Paid Date</Label>
                <Label width="110">Status</Label>
                <Label width="168">Method</Label>
              </Header>
            </ScrollContainer>
          </HeaderContainer>
          <PaymentsBlock
            ref={el => {
              this.paymentsBody = el;
            }}
            onScroll={this.handleBodyScroll}
          >
            {payments.edges.map(({ node }, index) => (
              <Payment
                key={node.id}
                expenseId={id}
                payment={node}
                org={org}
                index={index}
                tz={tz}
              />
            ))}
          </PaymentsBlock>
        </PaymentsContainer>
        {showNewPayment && (
          <CreatePaymentWindow
            expenseId={id}
            org={org}
            tz={tz}
            onNewPaymentClose={this.handleNewPaymentClose}
            expenseActualAmount={actualAmount}
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(Payments, {
  expense: graphql`
    fragment Payments_expense on Expense {
      id
      actualAmount
      event {
        tz
      }
      payments {
        edges {
          node {
            id
            ...Payment_payment
          }
        }
      }
    }
  `,
  org: graphql`
    fragment Payments_org on Org {
      ...Payment_org
      ...CreatePaymentWindow_org
    }
  `,
});
