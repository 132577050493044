/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';

const mutation = graphql`
  mutation removeAttachmentFromPaymentMutation($input: RemoveAttachmentFromPaymentInput!) {
    removeAttachmentFromPayment(input: $input) {
      removedId
    }
  }
`;

export default function removeAttachmentFromPayment(attachmentId: string, paymentId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        attachmentId,
        paymentId,
      },
    },
    updater: removeRecordFromConnection({
      deletedIdField: 'removedId',
      parentId: paymentId,
      connections: [{ field: 'attachments' }],
    }),
  });
}
