/* @flow */
import formatCost from 'utils/number/formatCost';

import { type ReportingFooterCellPropsType } from './index';

const ReportingTotalBudgetedAmountCell = ({
  totals,
  orgCurrency,
}: ReportingFooterCellPropsType) => {
  return formatCost(totals.budgetedAmount, orgCurrency);
};

export default ReportingTotalBudgetedAmountCell;
