/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AddExpenseForm_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpenseWindow_org$ref: FragmentReference;
export type ExpenseWindow_org = {|
  +$fragmentRefs: AddExpenseForm_org$ref,
  +$refType: ExpenseWindow_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExpenseWindow_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AddExpenseForm_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a5f2c8661c3d4b97f6f9c7a7a2d9cae6';
module.exports = node;
