import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';

import { createMoment } from 'utils/Date';

import createScheduleDay from 'graph/mutations/schedule/createScheduleDay';
import createScheduleItem from 'graph/mutations/schedule/createScheduleItem';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ScheduleDayAdd from './ScheduleDayAdd';
import ScheduleList from './ScheduleList';

class ScheduleDayTemp extends React.Component {
  static propTypes = {
    scheduleDay: PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string,
      title: PropTypes.string,
      schedule: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        dbId: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    event: PropTypes.shape({
      name: PropTypes.string.isRequired,
      tz: PropTypes.string.isRequired,
      viewerCanCreateSchedules: PropTypes.bool.isRequired,
    }).isRequired,
    filters: PropTypes.object.isRequired,
    teamId: PropTypes.number.isRequired,
    eventId: PropTypes.number.isRequired,
    onDayCreate: PropTypes.func.isRequired,
  };

  state = {
    addFormIndex: null,
  };

  handleItemAddShow = index => {
    this.setState({ addFormIndex: typeof index === 'number' ? index : 0 });
  };

  handleItemAddCancel = () => {
    this.setState({ addFormIndex: null });
  };

  handleItemCreate = item => {
    createScheduleDay(this.props.scheduleDay.schedule.id, { date: this.props.scheduleDay.date })
      .then(createdScheduleDay => {
        return createScheduleItem(createdScheduleDay.id, item);
      })
      .catch(showModernMutationError);
  };

  render() {
    const { scheduleDay, teamId, eventId, filters, event } = this.props;
    const { addFormIndex } = this.state;

    const dateMoment = createMoment(event.tz)(scheduleDay.date);
    const noop = () => {};

    return (
      <div className="schedule-day">
        <div>
          <div className="schedule-item-meta">
            <div className="schedule-item-meta-date">
              <a>{dateMoment.renderDateTime(true)}</a>
            </div>
          </div>
        </div>
        <ScheduleList
          scheduleDay={scheduleDay}
          event={event}
          onItemCreate={this.handleItemCreate}
          onItemUpdate={noop}
          onItemRemove={noop}
          onParticipantAdd={noop}
          onParticipantRemove={noop}
          onAddFormShow={this.handleItemAddShow}
          onAddFormHide={this.handleItemAddCancel}
          eventId={eventId}
          teamId={teamId}
          filters={filters}
          addFormIndex={addFormIndex}
        />
        {this.props.event.viewerCanCreateSchedules && (
          <ScheduleDayAdd tz={event.tz} onCreate={this.props.onDayCreate} />
        )}
      </div>
    );
  }
}

export default createFragmentContainer(
  ScheduleDayTemp,
  graphql`
    fragment ScheduleDayTemp_event on Event {
      name
      tz
      viewerCanCreateSchedules
      ...ScheduleList_event
    }
  `,
);
