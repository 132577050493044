/**
 * @flow
 * @relayHash 70d592520e5696999a8ec6f362f063e0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
export type UpdateEventNameComponentInput = {
  clientMutationId?: ?string,
  id: string,
  fontFamily?: ?string,
  fontStyle?: ?string,
  fontSize?: ?number,
  color?: ?string,
  padding?: ?string,
  alignment?: ?Alignment,
  backgroundColor?: ?string,
};
export type updateEventNameComponentMutationVariables = {|
  input: UpdateEventNameComponentInput
|};
export type updateEventNameComponentMutationResponse = {|
  +updateRegistrationFormEventNameComponent: ?{|
    +pageComponent: {|
      +eventNameComponent: ?{|
        +id: string,
        +fontFamily: ?string,
        +fontStyle: ?string,
        +fontSize: ?number,
        +color: ?string,
        +padding: string,
        +alignment: Alignment,
        +backgroundColor: ?string,
      |}
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type updateEventNameComponentMutation = {|
  variables: updateEventNameComponentMutationVariables,
  response: updateEventNameComponentMutationResponse,
|};
*/


/*
mutation updateEventNameComponentMutation(
  $input: UpdateEventNameComponentInput!
) {
  updateRegistrationFormEventNameComponent(input: $input) {
    pageComponent {
      eventNameComponent {
        id
        fontFamily
        fontStyle
        fontSize
        color
        padding
        alignment
        backgroundColor
      }
      id
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventNameComponentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateEventNameComponentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "eventNameComponent",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationEventNameComponent",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fontFamily",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fontStyle",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fontSize",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "color",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "padding",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "alignment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundColor",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventNameComponentMutation",
  "id": null,
  "text": "mutation updateEventNameComponentMutation(\n  $input: UpdateEventNameComponentInput!\n) {\n  updateRegistrationFormEventNameComponent(input: $input) {\n    pageComponent {\n      eventNameComponent {\n        id\n        fontFamily\n        fontStyle\n        fontSize\n        color\n        padding\n        alignment\n        backgroundColor\n      }\n      id\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventNameComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormEventNameComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventNameComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3
            ]
          },
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventNameComponentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRegistrationFormEventNameComponent",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEventNameComponentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageComponent",
            "storageKey": null,
            "args": null,
            "concreteType": "RegistrationPageComponent",
            "plural": false,
            "selections": [
              v3,
              v2
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4c8afd83a0c4c0ae6c59004999408e61';
module.exports = node;
