/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefUserAvatar_user$ref = any;
type UserField_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventStaffBrief_event$ref: FragmentReference;
export type EventStaffBrief_event = {|
  +staffers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +onsite?: boolean,
        +user: {|
          +id: string,
          +firstName?: string,
          +lastName?: string,
          +email?: string,
          +phone?: ?string,
          +officePhone?: ?string,
          +title?: ?string,
          +company?: ?string,
          +$fragmentRefs: BriefUserAvatar_user$ref,
        |},
      |}
    |}>
  |},
  +$fragmentRefs: UserField_event$ref,
  +$refType: EventStaffBrief_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventStaffBrief_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeStaffOnsite",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStaffName",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStaffEmail",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStaffPhone",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStaffOfficePhone",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStaffTitle",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStaffCompany",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeStaffAvatar",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "UserField_event",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffers",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffMembershipRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffMembershipRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StaffMembership",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "id",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "Condition",
                      "passingValue": true,
                      "condition": "includeStaffName",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "firstName",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "lastName",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "Condition",
                      "passingValue": true,
                      "condition": "includeStaffEmail",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "email",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "Condition",
                      "passingValue": true,
                      "condition": "includeStaffPhone",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "phone",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "Condition",
                      "passingValue": true,
                      "condition": "includeStaffOfficePhone",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "officePhone",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "Condition",
                      "passingValue": true,
                      "condition": "includeStaffTitle",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "title",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "Condition",
                      "passingValue": true,
                      "condition": "includeStaffCompany",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "company",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "Condition",
                      "passingValue": true,
                      "condition": "includeStaffAvatar",
                      "selections": [
                        {
                          "kind": "FragmentSpread",
                          "name": "BriefUserAvatar_user",
                          "args": null
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "Condition",
                  "passingValue": true,
                  "condition": "includeStaffOnsite",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "onsite",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7c46e64e6307bde23160fd7fe355f886';
module.exports = node;
