/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorWebsite_vendor$ref: FragmentReference;
export type VendorWebsite_vendor = {|
  +website: ?string,
  +$refType: VendorWebsite_vendor$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VendorWebsite_vendor",
  "type": "Vendor",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "website",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3788711839d07fe49b961b7e4069202e';
module.exports = node;
