/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ExternalLinkIcon from 'images/external.svg';

const ExternalLinkMarker = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
  flex: 0 0 66px;
  font-size: 11px;
  color: rgba(74, 86, 101, 0.8);
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 15px 3px 25px;
  outline: none;

  &:hover {
    background-color: #ebf2fa;
    ${ExternalLinkMarker} {
      visibility: visible;
    }
  }
`;

const SearchResultDetails = styled.div`
  flex: 1;
  margin-right: 10px;
  word-break: break-word;
`;

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  margin-right: 4px;
`;

type Props = { path: string, children: React.Node };

const Container = ({ path, children }: Props) => {
  return (
    <StyledLink to={path} target="_blank" rel="noreferrer noopener">
      <SearchResultDetails>{children}</SearchResultDetails>
      <ExternalLinkMarker>
        <StyledExternalLinkIcon /> New Tab
      </ExternalLinkMarker>
    </StyledLink>
  );
};

export default Container;
