/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import formatCost from 'utils/number/formatCost';

import { type CellPropsType } from 'components/budget/Table';
import InlineCostField from 'components/budget/Table/InlineCostField';
import InlineEditableCell from 'components/budget/Table/InlineEditableCell';

import { type ExpenseCellPropsType } from './index';

import { type ExpenseActualAmountCell_event } from './__generated__/ExpenseActualAmountCell_event.graphql';
import { type ExpenseActualAmountCell_expense } from './__generated__/ExpenseActualAmountCell_expense.graphql';
import { type ExpenseActualAmountCell_org } from './__generated__/ExpenseActualAmountCell_org.graphql';

const ExpenseActualAmountCell = ({
  expense,
  event,
  org,
  updateExpense,
  updateColumnWidth,
}: CellPropsType<
  ExpenseCellPropsType<
    ExpenseActualAmountCell_expense,
    ExpenseActualAmountCell_event,
    ExpenseActualAmountCell_org,
  >,
>) => {
  if (!expense.viewerCanUpdateAmount) {
    return (
      <InlineEditableCell>
        {({ editing }) => {
          if (editing) {
            return (
              <Redirect
                push
                to={{
                  pathname: `/events/${event.slug}/budget/${expense.id}`,
                  hash: 'payments',
                }}
              />
            );
          }

          return formatCost(expense.actualAmount, org.settings.currency);
        }}
      </InlineEditableCell>
    );
  }

  return (
    <InlineCostField
      value={expense.actualAmount}
      currency={org.settings.currency}
      onChange={actualAmount => {
        updateExpense({
          actualAmount: actualAmount == null ? expense.actualAmount : actualAmount,
        });

        updateColumnWidth();
      }}
    />
  );
};

export default createFragmentContainer(
  ExpenseActualAmountCell,
  graphql`
    fragment ExpenseActualAmountCell_expense on Expense {
      id
      actualAmount
      viewerCanUpdateAmount
    }

    fragment ExpenseActualAmountCell_event on Event {
      slug
    }

    fragment ExpenseActualAmountCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
