/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import NoEventStartDate from 'images/noEventStartDate.svg';
import NoRegistrationForm from 'images/noRegistrationForm.svg';
import Button from 'components/budget/Button';
import EmptyMessage from 'components/EmptyMessage';

import type { UncreatedRegistrationForm_event } from './__generated__/UncreatedRegistrationForm_event.graphql';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const InfoBox = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  max-width: 788px;
  padding: 35px 0 49px;
  background-color: #ffffff;
  border: solid 1px #dadada;
  border-radius: 5px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 210px;
  margin-bottom: 18px;
`;

const Title = styled.div`
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #373951;
  text-align: center;
  line-height: 1em;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1em;
  font-weight: 500;
  color: #4a5665;
`;

const StyledButton = styled(Button)`
  flex: 0 auto;
  min-width: 116px;
  margin-top: 20px;
`;

const StyledEmptyMessage = styled(EmptyMessage)`
  margin-top: 120px;
  background-color: transparent;
`;

type Props = { event: UncreatedRegistrationForm_event, navigateToFormBuilder: () => void };

const UncreatedRegistrationForm = (props: Props) => {
  const { event, navigateToFormBuilder } = props;
  return !event.startDate ? (
    <StyledEmptyMessage
      icon={<NoEventStartDate />}
      text="Add Event Dates to manage and share the Contact Form"
    />
  ) : (
    <Container>
      <InfoBox>
        <IconsContainer>
          <NoRegistrationForm />
        </IconsContainer>
        <Title>Build event registration form</Title>
        <Description>Customize and publish event registration form</Description>
        <StyledButton onClick={navigateToFormBuilder}>Build the form</StyledButton>
      </InfoBox>
    </Container>
  );
};

export default createFragmentContainer(UncreatedRegistrationForm, {
  event: graphql`
    fragment UncreatedRegistrationForm_event on Event {
      startDate
    }
  `,
});
