/* @flow */
import React from 'react';
import styled from 'styled-components';

import isValidWebsite from 'utils/validators/isValidWebsite';

import generateFontFromProps from '../RegistrationCreate/RegistrationPageContent/generateFontFromProps';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 100%;
  height: 40px;
  background-color: #f4f4f4;
`;

const Link = styled.a`
  font: ${props => generateFontFromProps({ fontFamily: props.fontFamily })};
  font-size: 12px;
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    text-decoration: underline;
  }
`;

const stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
  e.stopPropagation();
};

const RegistrationFooterLink = (props: { label: string, link?: string, fontFamily: ?string }) => {
  const { link, fontFamily, label } = props;

  if (!link || !isValidWebsite(link)) return null;

  return (
    <Container>
      <Link
        fontFamily={fontFamily}
        href={link}
        target="_blank"
        rel="noreferrer noopener"
        onClick={stopPropagation}
      >
        {label}
      </Link>
    </Container>
  );
};

export default RegistrationFooterLink;
