/**
 * @flow
 * @relayHash 2f9e354bf0e55690a649976c67628120
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompanyForm_org$ref = any;
type ContactForm_org$ref = any;
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
export type ContactWindowQueryVariables = {|
  customizableType: $ReadOnlyArray<CustomizableType>,
  excludeSalesforcePullOnlyFields: boolean,
|};
export type ContactWindowQueryResponse = {|
  +org: {|
    +$fragmentRefs: ContactForm_org$ref & CompanyForm_org$ref
  |},
  +me: {|
    +tz: string
  |},
|};
export type ContactWindowQuery = {|
  variables: ContactWindowQueryVariables,
  response: ContactWindowQueryResponse,
|};
*/


/*
query ContactWindowQuery(
  $customizableType: [CustomizableType!]!
  $excludeSalesforcePullOnlyFields: Boolean!
) {
  org {
    ...ContactForm_org
    ...CompanyForm_org
    id
  }
  me {
    tz
    id
  }
}

fragment ContactForm_org on Org {
  id
  viewerCanUpdate
  salesforceAccount {
    contactsSyncEnabled
    id
  }
  marketoAccount {
    id
  }
  settings {
    currency
    domain
    id
  }
  customFields(customizableType: $customizableType, onlyCreateForm: true, excludeSalesforcePullOnlyFields: $excludeSalesforcePullOnlyFields) {
    edges {
      node {
        id
        kind
        label
        order
        required
        fieldName
        options {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}

fragment CompanyForm_org on Org {
  id
  viewerCanUpdate
  settings {
    currency
    id
  }
  customFields(customizableType: $customizableType, onlyCreateForm: true, excludeSalesforcePullOnlyFields: $excludeSalesforcePullOnlyFields) {
    edges {
      node {
        id
        kind
        label
        order
        required
        fieldName
        options {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "customizableType",
    "type": "[CustomizableType!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "excludeSalesforcePullOnlyFields",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tz",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ContactWindowQuery",
  "id": null,
  "text": "query ContactWindowQuery(\n  $customizableType: [CustomizableType!]!\n  $excludeSalesforcePullOnlyFields: Boolean!\n) {\n  org {\n    ...ContactForm_org\n    ...CompanyForm_org\n    id\n  }\n  me {\n    tz\n    id\n  }\n}\n\nfragment ContactForm_org on Org {\n  id\n  viewerCanUpdate\n  salesforceAccount {\n    contactsSyncEnabled\n    id\n  }\n  marketoAccount {\n    id\n  }\n  settings {\n    currency\n    domain\n    id\n  }\n  customFields(customizableType: $customizableType, onlyCreateForm: true, excludeSalesforcePullOnlyFields: $excludeSalesforcePullOnlyFields) {\n    edges {\n      node {\n        id\n        kind\n        label\n        order\n        required\n        fieldName\n        options {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CompanyForm_org on Org {\n  id\n  viewerCanUpdate\n  settings {\n    currency\n    id\n  }\n  customFields(customizableType: $customizableType, onlyCreateForm: true, excludeSalesforcePullOnlyFields: $excludeSalesforcePullOnlyFields) {\n    edges {\n      node {\n        id\n        kind\n        label\n        order\n        required\n        fieldName\n        options {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ContactWindowQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContactForm_org",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "CompanyForm_org",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContactWindowQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanUpdate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "contactsSyncEnabled",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketoAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketoAccount",
            "plural": false,
            "selections": [
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currency",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "domain",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "customizableType",
                "variableName": "customizableType",
                "type": "[CustomizableType!]!"
              },
              {
                "kind": "Variable",
                "name": "excludeSalesforcePullOnlyFields",
                "variableName": "excludeSalesforcePullOnlyFields",
                "type": "Boolean"
              },
              {
                "kind": "Literal",
                "name": "onlyCreateForm",
                "value": true,
                "type": "Boolean"
              }
            ],
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "kind",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "required",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fieldName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "options",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionTypeRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldOptionTypeRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CustomFieldOptionType",
                                "plural": false,
                                "selections": [
                                  v2,
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v1,
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e3186d1d3c8dc09eba47e7b68ba1501';
module.exports = node;
