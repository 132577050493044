/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { ImportLogsDateColumn_importLog } from './__generated__/ImportLogsDateColumn_importLog.graphql';

type Props = {
  importLog: ImportLogsDateColumn_importLog,
  tz: string,
};

const ImportLogsFilenameColumn = ({ importLog, tz }: Props) => (
  <DateTimeTooltip date={moment.tz(importLog.createdAt, tz)} />
);

export default createFragmentContainer(
  ImportLogsFilenameColumn,
  graphql`
    fragment ImportLogsDateColumn_importLog on ImportLog {
      createdAt
    }
  `,
);
