/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MarketoId_event$ref: FragmentReference;
export type MarketoId_event = {|
  +marketoProgram: ?{|
    +marketoId: string,
    +url: string,
  |},
  +$refType: MarketoId_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MarketoId_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "marketoProgram",
      "storageKey": null,
      "args": null,
      "concreteType": "MarketoProgram",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "marketoId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "url",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bdc1a7903527f49fb1b2d8a1340f6515';
module.exports = node;
