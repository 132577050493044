/**
 * @flow
 * @relayHash a8fd589badb46f1f8db80c0e10316063
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamInput = {
  clientMutationId?: ?string,
  teamId: string,
  name?: ?string,
  logo?: ?string,
  primaryColor?: ?string,
  secondaryColor?: ?string,
  scheduleName?: ?string,
  font?: ?string,
};
export type updateTeamMutationVariables = {|
  input: UpdateTeamInput
|};
export type updateTeamMutationResponse = {|
  +updateTeam: ?{|
    +team: ?{|
      +dbId: number,
      +name: string,
      +logo: ?string,
      +primaryColor: string,
      +secondaryColor: string,
      +scheduleName: string,
      +font: string,
      +slug: string,
    |}
  |}
|};
export type updateTeamMutation = {|
  variables: updateTeamMutationVariables,
  response: updateTeamMutationResponse,
|};
*/


/*
mutation updateTeamMutation(
  $input: UpdateTeamInput!
) {
  updateTeam(input: $input) {
    team {
      dbId
      name
      logo
      primaryColor
      secondaryColor
      scheduleName
      font
      slug
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateTeamInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "logo",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "primaryColor",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "secondaryColor",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scheduleName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "font",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateTeamMutation",
  "id": null,
  "text": "mutation updateTeamMutation(\n  $input: UpdateTeamInput!\n) {\n  updateTeam(input: $input) {\n    team {\n      dbId\n      name\n      logo\n      primaryColor\n      secondaryColor\n      scheduleName\n      font\n      slug\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateTeamMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateTeamMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aaa9a227f30f1698bf706be19ac679db';
module.exports = node;
