/* @flow */
import determineCustomFieldMinWidth from 'utils/customization/determineCustomFieldMinWidth';
import type { FieldType } from 'utils/customization/types';
import type {
  CustomFieldParam,
  DateRangeParam,
  NumberRangeParam,
} from 'utils/routing/parseTypedQueryString';

import { type CompanyFieldInput } from 'graph/mutations/company/updateCompany';
import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import { type ColumnType } from 'components/budget/Table';
import kindToTableComponent from 'components/Customizable/kindToTableComponent';
import type { Filter } from 'components/material/ColumnFilters/columnFilterTypes';

import { CompanyActionCell, defaultTableComponents } from './columns';

// Flow will provide this type later as an input variable
export type InputVariableFilters = {
  [key: string]: CustomFieldParam,
  createdAt: ?DateRangeParam,
  creatorIds: ?$ReadOnlyArray<string>,
  updatedAt: ?DateRangeParam,
  updaterIds: ?$ReadOnlyArray<string>,
  cities: ?$ReadOnlyArray<string>,
  states: ?$ReadOnlyArray<string>,
  countries: ?$ReadOnlyArray<string>,
  sort: string,
  direction: 'ASC' | 'DESC',
  query: ?string,
  contactsCount: ?NumberRangeParam,
  customFilters: $ReadOnlyArray<?{
    customFieldId: string,
    dateParam?: ?DateRangeParam,
    numberParam?: ?NumberRangeParam,
    booleanParam?: ?boolean,
    optionIds?: ?$ReadOnlyArray<string>,
  }>,
  customFieldSortId: ?string,
};

export type CompanyCellPropsType<Event: ?{}, Org: ?{} = {}, Company: {} = {}> = {|
  org: Org,
  event: ?Event,
  company: Company,
  filters: InputVariableFilters,
  customizable: Company,
  fieldSettings: ?FieldType,
  tz: string,
  isLast?: boolean,
  onUpdate: (args: $Shape<CompanyFieldInput>) => Promise<void>,
  onUpdateCustomField: (
    customizable: CustomizableResponse,
    args: UpdateCustomFieldValueInput,
  ) => Promise<void>,
|};

export const defaultColumns = ['name', 'contacts_count', 'created_by', 'created_at'];

export default (
  companyFields: $ReadOnlyArray<FieldType>,
  shownColumns: $ReadOnlyArray<string>,
): $ReadOnlyArray<ColumnType<any, any>> => {
  return [
    ...companyFields
      .filter(companyField => shownColumns.includes(companyField.fieldName || companyField.id))
      .map(companyField => {
        if (companyField.fieldName) {
          return {
            title: companyField.label,
            fieldSettings: companyField,
            component: defaultTableComponents[companyField.fieldName],
            sortKey: companyField.fieldName.toUpperCase(),
            minWidth: ['website', 'linkedin', 'twitter'].includes(companyField.fieldName)
              ? 215
              : companyField.minWidth,
            ...(companyField.fieldName === 'name' ? { grow: 1, fixedLeft: 41 } : null),
          };
        }

        return {
          title: companyField.label,
          fieldSettings: companyField,
          align: ['CURRENCY', 'NUMBER'].includes(companyField.kind) ? 'right' : 'left',
          component: kindToTableComponent[companyField.kind],
          sortKey: companyField.id,
          minWidth: determineCustomFieldMinWidth(companyField.kind),
        };
      }),
    {
      title: '',
      type: 'action',
      component: CompanyActionCell,
    },
  ];
};

export function getColumnsShowConfig(
  companyFields: $ReadOnlyArray<FieldType>,
): $ReadOnlyArray<Filter> {
  return companyFields.map(companyField => {
    return {
      value: companyField.fieldName || companyField.id,
      label: companyField.label,
      default: defaultColumns.includes(companyField.fieldName),
      sticky: companyField.fieldName === 'name',
    };
  });
}
