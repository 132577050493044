/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type { CircaFile } from 'utils/uploading/types';

const mutation = graphql`
  mutation addTaskAttachmentMutation($input: AddDeliverableAttachmentInput!) {
    addDeliverableAttachment(input: $input) {
      deliverable {
        id
        dbId
        name
        event {
          dbId
          name
        }
      }
      attachmentEdge {
        __typename
        node {
          id
          fileurl
          filename
          filetype
          filesize
          viewerCanDelete
        }
      }
    }
  }
`;

export default function addTaskAttachment(taskId: string, file: CircaFile) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableId: taskId,
        ...file,
      },
    },
    updater: addRecordToConnection({
      parentId: taskId,
      edgeName: 'attachmentEdge',
      connections: [{ field: 'attachments' }],
    }),
  });
}
