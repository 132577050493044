/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';

import publicRuleNames from 'config/publicRuleNames';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import AllEventsMapMarkersInfoBox from './AllEventsMapMarkersInfoBox';

import type { AllEventsMapMarkersInfoQueryResponse } from './__generated__/AllEventsMapMarkersInfoQuery.graphql';

const Container = styled.div`
  width: 273px;
`;

const query = graphql`
  query AllEventsMapMarkersInfoQuery(
    $eventIds: [ID!]!
    $filters: EventFilters!
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
    $isShared: Boolean!
  ) {
    ... @skip(if: $isShared) {
      events: nodes(ids: $eventIds) {
        ... on Event {
          id
          ...AllEventsMapMarkerInfoBox_event
        }
      }
    }
    ... @include(if: $isShared) {
      publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
      eventList(resourceToken: $resourceToken) {
        events(filters: $filters) {
          edges {
            node {
              id
              ...AllEventsMapMarkerInfoBox_event
            }
          }
        }
      }
    }
  }
`;

export default class AllEventsMapMarkersInfo extends React.PureComponent<{
  eventIds: $ReadOnlyArray<string>,
  resourceToken: string,
  orgSlug: ?string,
  match: Match,
  location: Location,
}> {
  render() {
    const { eventIds, resourceToken, orgSlug, match, location } = this.props;
    const isShared = !!resourceToken;
    const variables = {
      eventIds,
      filters: {
        eventIds,
        direction: 'ASC',
      },
      publicRuleName: publicRuleNames.PUBLIC_EVENT_LIST,
      resourceToken: resourceToken || '',
      orgSlug,
      isShared,
    };
    return (
      <Container>
        <DefaultQueryRenderer
          public={isShared}
          query={query}
          variables={variables}
          renderSuccess={(props: AllEventsMapMarkersInfoQueryResponse) => {
            return (
              <AllEventsMapMarkersInfoBox
                linkEvent={!isShared}
                events={
                  props.eventList
                    ? props.eventList.events.edges.map(edge => edge.node)
                    : props.events
                }
                match={match}
                location={location}
              />
            );
          }}
          renderLoading={() => null}
        />
      </Container>
    );
  }
}
