/* @flow */
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import removeContacts from 'graph/mutations/contact/removeContacts';
import removeContactAssignment from 'graph/mutations/event/removeContactAssignment';
import addContactToBrief from 'graph/mutations/event_brief/addContactToBrief';
import removeContactFromBrief from 'graph/mutations/event_brief/removeContactFromBrief';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Breadcrumb from 'components/Breadcrumb';
import ConfirmationWindow from 'components/ConfirmationWindow';
import ContactSyncMessage from 'components/Contacts/ContactSyncMessage';
import Tag from 'components/Contacts/Tag';
import Avatar from 'components/material/Avatar';
import ShareDropdown from 'components/ShareDropdown';

import ContactPageMenu from './ContactPageMenu';
import ContactPageRoutes from './ContactPageRoutes';

import type { ContactPage_contact } from './__generated__/ContactPage_contact.graphql';
import type { ContactPage_event } from './__generated__/ContactPage_event.graphql';
import type { ContactPage_org } from './__generated__/ContactPage_org.graphql';
import type { ContactPage_user } from './__generated__/ContactPage_user.graphql';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 33px 0 33px;
`;

const StyledContactSyncMessage = styled(ContactSyncMessage)`
  padding: 15px 33px;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 33px 15px 33px;
`;

const StyledAvatar = styled(Avatar)`
  position: relative;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 30px;
`;

const ContactName = styled.div`
  font-size: 17px;
  color: #4a5665;
`;

const ContactTitle = styled.div`
  margin-top: 3px;
  font-size: 14px;
  color: #4a5665;
`;

const ContactTag = styled.div`
  display: block;
  margin-top: 8px;
`;

const Container = styled.div`
  padding-top: 20px;
`;

class ContactPage extends React.PureComponent<
  {
    org: ContactPage_org,
    contact: ContactPage_contact,
    user: ContactPage_user,
    event: ContactPage_event,
    location: Location,
    history: History,
    match: Match,
  },
  {
    confirmingDelete: boolean,
  },
> {
  state = {
    confirmingDelete: false,
  };

  componentDidUpdate(prevProps: $PropertyType<ContactPage, 'props'>) {
    if (this.props.contact.slug === prevProps.contact.slug) return;

    this.props.history.replace({
      pathname: this.props.location.pathname.replace(
        `/people/${prevProps.contact.slug}`,
        `/people/${this.props.contact.slug}`,
      ),
      search: this.props.location.search,
      state: this.props.location.state,
    });
  }

  getReturnUrl = () => {
    const { state } = this.props.location;
    if (state && state.prevPage === true && state.prevPath) {
      return `${state.prevPath}${state.search || ''}`;
    }

    const params = this.props.match.params;
    switch (params[0]) {
      case 'events':
        return `/events/${params.event_slug}/contacts`;
      case 'tasks':
        return `/events/${params.event_slug}/tasks/${params.task_slug}`;
      default:
        return '/contacts/people';
    }
  };

  getBreadCrumbLabel = () => {
    const { state } = this.props.location;
    if (state && state.prevPageLabel) {
      return state.prevPageLabel;
    }

    switch (this.props.match.params[0]) {
      case 'leads':
        return 'Leads Inbox';
      case 'events':
        return 'Event Contacts';
      case 'tasks':
        return 'Task Contacts';
      case 'registration':
        return 'Registration Results';
      default:
        return 'All Contacts';
    }
  };

  handleGoBack = () => {
    this.props.history.replace(this.getReturnUrl());
  };

  handleBreadCrumbClick = () => {
    this.props.history.push(this.getReturnUrl());
  };

  handleRemove = () => {
    const contact = this.props.contact;
    this.handleGoBack();
    removeContacts({
      contactIds: [contact.id],
      orgId: this.props.org.id,
      ...(this.props.event ? { eventId: this.props.event.id } : null),
      fromWindow: 'contact profile',
    }).catch(showModernMutationError);
  };

  handleRemoveContactAssignment = () => {
    this.handleGoBack();
    if (this.props.event != null) {
      removeContactAssignment({
        eventId: this.props.event.id,
        fromWindow: 'contact profile',
        contactIds: [this.props.contact.id],
      }).catch(showModernMutationError);
    }
  };

  handleConfirmationShow = () => {
    this.setState({ confirmingDelete: true });
  };

  handleConfirmationHide = () => {
    this.setState({ confirmingDelete: false });
  };

  handleAddContactToBrief = () => {
    if (this.props.event) {
      addContactToBrief(this.props.contact.id, this.props.event.id).catch(showModernMutationError);
    }
  };

  handleRemoveContactFromBrief = () => {
    if (this.props.event) {
      removeContactFromBrief(this.props.contact.id, this.props.event.id).catch(
        showModernMutationError,
      );
    }
  };

  getBriefActions = () => {
    const { contact, event } = this.props;
    if (event != null && event.viewerCanUpdate) {
      const eventBriefContactIds = event.briefContacts.edges.map(edge => edge.node.id);
      if (eventBriefContactIds.includes(contact.id)) {
        return {
          label: 'Remove from Brief',
          icon: 'minus',
          onClick: this.handleRemoveContactFromBrief,
        };
      }
      return {
        label: 'Add to Brief',
        icon: 'plus',
        onClick: this.handleAddContactToBrief,
      };
    }
    return null;
  };

  render() {
    const { org, contact, event, user, match, location } = this.props;
    if (!contact) return null;

    const salesforceSyncEnabled = !!(
      org.salesforceAccount && org.salesforceAccount.contactsSyncEnabled
    );
    const opportunitiesSyncEnabled =
      org.salesforceAccount != null &&
      org.salesforceAccount.opportunitiesSyncEnabled &&
      contact.salesforceSyncAs === 'contact';
    const marketoAccount = org.marketoAccount;
    const marketoSyncEnabled = !!marketoAccount;
    const actionOptions = [
      event != null
        ? { label: 'Remove from event', icon: 'minus', onClick: this.handleRemoveContactAssignment }
        : null,
      this.getBriefActions(),
      {
        label: 'Delete',
        icon: 'trash',
        onClick: this.handleConfirmationShow,
        disabled: !contact.viewerCanRemove,
      },
    ].filter(Boolean);

    return (
      <DocumentTitle title={`${contact.firstName || ''} ${contact.lastName || ''}`}>
        <div>
          <HeaderContainer>
            <Breadcrumb
              path={[
                {
                  label: this.getBreadCrumbLabel(),
                  onClick: this.handleBreadCrumbClick,
                },
                { label: fullNameOfUser(contact) },
              ]}
            />
            <ShareDropdown noBorder options={actionOptions} />
            {this.state.confirmingDelete && (
              <ConfirmationWindow
                onHide={this.handleConfirmationHide}
                onConfirm={this.handleRemove}
                message="Once you delete a contact, it's gone from all of your Events and Workspace."
              />
            )}
          </HeaderContainer>

          <StyledContactSyncMessage
            contact={contact}
            syncStatus={contact.syncStatus}
            salesforceId={contact.salesforceId}
            marketoId={contact.marketoId}
            salesforceHost={org.salesforceAccount && org.salesforceAccount.host}
            marketoHost={marketoAccount && marketoAccount.host}
            salesforceSyncEnabled={salesforceSyncEnabled}
            marketoSyncEnabled={marketoSyncEnabled}
          />

          <Container>
            <ContactInfoContainer>
              <StyledAvatar
                profile={{
                  avatar: contact.avatar,
                  email: contact.email || '',
                  firstName: contact.firstName || '',
                  lastName: contact.lastName || '',
                }}
                size={42}
                radius="8px"
              />
              <ContactInfo>
                <ContactName>
                  {contact.firstName} {contact.lastName}
                </ContactName>
                {org.customFields.edges[0].node.enabled && (
                  <ContactTitle>{contact.title}</ContactTitle>
                )}
                {salesforceSyncEnabled && contact.salesforceSyncAs && (
                  <ContactTag>
                    <Tag color="#3749ff" active readOnly>
                      {contact.salesforceSyncAs}
                      {contact.salesforceSyncAs === 'lead' && contact.salesforceLeadStatus
                        ? ` - ${contact.salesforceLeadStatus.name}`
                        : ''}
                    </Tag>
                  </ContactTag>
                )}
              </ContactInfo>
            </ContactInfoContainer>
            <ContactPageMenu
              eventsCount={contact.events.totalCount}
              opportunitiesCount={contact.opportunities.totalCount}
              opportunitiesSyncEnabled={opportunitiesSyncEnabled}
              requestsCount={contact.eventRequestSubmissions.totalCount}
              restrictedEventsCount={contact.restrictedEventsCount}
              pathPrefix={match.url}
              location={location}
            />
            <ContactPageRoutes
              contact={contact}
              org={org}
              event={event}
              user={user}
              eventsCount={contact.events.totalCount}
              opportunitiesSyncEnabled={opportunitiesSyncEnabled}
              pathPrefix={match.path}
              rootUrl={match.url}
              forceUpdate={`${location.pathname}${location.search}`}
              fromWindow={this.props.event ? 'event contacts' : 'org contacts'}
            />
          </Container>
        </div>
      </DocumentTitle>
    );
  }
}

export default createFragmentContainer(ContactPage, {
  org: graphql`
    fragment ContactPage_org on Org {
      id
      customFields(customizableType: [CONTACT], fieldNames: ["title"], includeDisabled: true) {
        edges {
          node {
            enabled
          }
        }
      }
      salesforceAccount {
        contactsSyncEnabled
        opportunitiesSyncEnabled
        host
      }
      marketoAccount {
        host
      }
      ...ContactPageRoutes_org
    }
  `,
  contact: graphql`
    fragment ContactPage_contact on Contact {
      id
      email
      slug
      firstName
      lastName
      salesforceSyncAs
      restrictedEventsCount
      salesforceLeadStatus {
        name
      }
      company {
        id
        name
        salesforceId
      }
      title
      avatar
      salesforceId
      marketoId
      syncStatus {
        lastSuccessAt
        state
        errorMessage
      }
      viewerCanRemove
      viewerCanUpdate
      events {
        totalCount
      }
      opportunities {
        totalCount
      }
      eventRequestSubmissions {
        totalCount
      }
      ...ContactPageRoutes_contact
    }
  `,
  user: graphql`
    fragment ContactPage_user on User {
      ...ContactPageRoutes_user
    }
  `,
  event: graphql`
    fragment ContactPage_event on Event {
      id
      viewerCanUpdate
      briefContacts {
        edges {
          node {
            id
          }
        }
      }
      ...ContactPageRoutes_event
    }
  `,
});
