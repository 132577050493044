/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import addTasksTag from 'graph/mutations/task/addTasksTag';
import removeTasksTag from 'graph/mutations/task/removeTasksTag';

import InlineTags from 'components/InlineTags';

import type { DeliverableTags_deliverable } from './__generated__/DeliverableTags_deliverable.graphql';
import type { DeliverableTags_org } from './__generated__/DeliverableTags_org.graphql';

const Container = styled.div`
  margin: -6px 0px -8px -2px;
`;

class DeliverableTags extends React.PureComponent<{
  deliverable: DeliverableTags_deliverable,
  org: DeliverableTags_org,
}> {
  handleTagAdd = tagId => {
    addTasksTag([this.props.deliverable.id], tagId, 'checklist');
  };

  handleTagRemove = tagId => {
    removeTasksTag([this.props.deliverable.id], tagId, 'checklist');
  };

  render() {
    const { deliverable, org } = this.props;
    return (
      <Container>
        <InlineTags
          tags={deliverable.tags.edges.map(edge => edge.node)}
          orgTags={org.tags.edges.map(edge => edge.node)}
          canManageTags={org.viewerCanManageTags}
          onAddTag={this.handleTagAdd}
          onRemoveTag={this.handleTagRemove}
          disabled={!deliverable.viewerCanUpdateTags}
          viewPage="table"
        />
      </Container>
    );
  }
}
export default createFragmentContainer(DeliverableTags, {
  deliverable: graphql`
    fragment DeliverableTags_deliverable on Deliverable {
      id
      viewerCanUpdateTags
      tags {
        edges {
          node {
            ...InlineTags_tags
          }
        }
      }
    }
  `,
  org: graphql`
    fragment DeliverableTags_org on Org {
      viewerCanManageTags
      tags {
        edges {
          node {
            ...InlineTags_orgTags
          }
        }
      }
    }
  `,
});
