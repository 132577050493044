/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  UpdateEventDatesComponentInput,
  updateEventDatesComponentMutationVariables,
} from './__generated__/updateEventDatesComponentMutation.graphql';

export type updateEventDatesComponentPropertyType = $Shape<UpdateEventDatesComponentInput>;

const mutation = graphql`
  mutation updateEventDatesComponentMutation($input: UpdateEventDatesComponentInput!) {
    updateRegistrationFormEventDatesComponent(input: $input) {
      pageComponent {
        eventDatesComponent {
          id
          fontFamily
          fontStyle
          fontSize
          color
          padding
          alignment
          backgroundColor
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateEventDatesComponent(
  properties: updateEventDatesComponentPropertyType,
) {
  const {
    id,
    fontFamily,
    fontStyle,
    fontSize,
    color,
    padding,
    alignment,
    backgroundColor,
  } = properties;
  const variables: updateEventDatesComponentMutationVariables = {
    input: {
      ...properties,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateRegistrationFormEventDatesComponent: {
        pageComponent: {
          eventDatesComponent: {
            id,
            fontFamily,
            fontStyle,
            fontSize,
            color,
            padding,
            alignment,
            backgroundColor,
          },
        },
      },
    },
  });
}
