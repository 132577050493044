/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import InlineEditableCell from 'components/budget/Table/InlineEditableCell';
import InlineExpandableText from 'components/budget/Table/InlineExpandableText';

import { type ExpenseCellPropsType } from './index';

import { type ExpenseTeamSplitCell_event } from './__generated__/ExpenseTeamSplitCell_event.graphql';
import { type ExpenseTeamSplitCell_expense } from './__generated__/ExpenseTeamSplitCell_expense.graphql';

const isOwnTeamPercent100 = (teams, currentTeamId) =>
  currentTeamId && teams.length === 1 && teams[0].id === currentTeamId && teams[0].percent === 100;

const ExpenseTeamSplitCell = ({
  expense,
  event,
}: ExpenseCellPropsType<ExpenseTeamSplitCell_expense, ExpenseTeamSplitCell_event>) => {
  return (
    <InlineEditableCell>
      {({ editing }) => {
        if (editing) {
          return <Redirect push to={`/events/${event.slug}/budget/${expense.id}`} />;
        }

        const expenseAllocations = expense.expenseTeamAllocations.edges.map(team => ({
          percent: team.node.percent,
          id: team.node.team.id,
          name: team.node.team.name,
        }));

        return (
          <InlineExpandableText>
            {!isOwnTeamPercent100(expenseAllocations, event.team.id) &&
              expenseAllocations.map(team => `${team.name} (${team.percent}%)`).join(', ')}
          </InlineExpandableText>
        );
      }}
    </InlineEditableCell>
  );
};

export default createFragmentContainer(
  ExpenseTeamSplitCell,
  graphql`
    fragment ExpenseTeamSplitCell_expense on Expense {
      id
      expenseTeamAllocations {
        edges {
          node {
            percent
            team {
              id
              name
            }
          }
        }
      }
    }

    fragment ExpenseTeamSplitCell_event on Event {
      slug
      team {
        id
      }
    }
  `,
);
