/**
 * @flow
 * @relayHash be705a406bba229f15bee5a2bf895b58
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UsersGroup_users$ref = any;
export type AddParticipantToScheduleItemInput = {
  clientMutationId?: ?string,
  userId: string,
  scheduleItemId: string,
};
export type addParticipantMutationVariables = {|
  input: AddParticipantToScheduleItemInput
|};
export type addParticipantMutationResponse = {|
  +addParticipantToScheduleItem: ?{|
    +scheduleParticipantEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +user: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +avatar: ?string,
          +email: string,
        |},
      |},
    |},
    +event: {|
      +staffers: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +user: {|
              +$fragmentRefs: UsersGroup_users$ref
            |}
          |}
        |}>
      |}
    |},
  |}
|};
export type addParticipantMutation = {|
  variables: addParticipantMutationVariables,
  response: addParticipantMutationResponse,
|};
*/


/*
mutation addParticipantMutation(
  $input: AddParticipantToScheduleItemInput!
) {
  addParticipantToScheduleItem(input: $input) {
    scheduleParticipantEdge {
      __typename
      node {
        id
        user {
          id
          firstName
          lastName
          avatar
          email
        }
      }
    }
    event {
      staffers {
        edges {
          node {
            user {
              ...UsersGroup_users
              id
            }
            id
          }
        }
      }
      id
    }
  }
}

fragment UsersGroup_users on Assignable {
  id
  email
  firstName
  lastName
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddParticipantToScheduleItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddParticipantToScheduleItemInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "scheduleParticipantEdge",
  "storageKey": null,
  "args": null,
  "concreteType": "ScheduleParticipantRequiredEdge",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "ScheduleParticipant",
      "plural": false,
      "selections": [
        v2,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            v2,
            v3,
            v4,
            v5,
            v6
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addParticipantMutation",
  "id": null,
  "text": "mutation addParticipantMutation(\n  $input: AddParticipantToScheduleItemInput!\n) {\n  addParticipantToScheduleItem(input: $input) {\n    scheduleParticipantEdge {\n      __typename\n      node {\n        id\n        user {\n          id\n          firstName\n          lastName\n          avatar\n          email\n        }\n      }\n    }\n    event {\n      staffers {\n        edges {\n          node {\n            user {\n              ...UsersGroup_users\n              id\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment UsersGroup_users on Assignable {\n  id\n  email\n  firstName\n  lastName\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addParticipantMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addParticipantToScheduleItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddParticipantToScheduleItemPayload",
        "plural": false,
        "selections": [
          v7,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "staffers",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembershipRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffMembershipRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembership",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "FragmentSpread",
                                "name": "UsersGroup_users",
                                "args": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "addParticipantMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addParticipantToScheduleItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddParticipantToScheduleItemPayload",
        "plural": false,
        "selections": [
          v7,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "staffers",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembershipRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffMembershipRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembership",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              v2,
                              v6,
                              v3,
                              v4,
                              v5
                            ]
                          },
                          v2
                        ]
                      }
                    ]
                  }
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e25ab6480f23d037fbe158bd142b262';
module.exports = node;
