/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventRequestsItemStatus_eventRequestSubmission$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestsStatus_event$ref: FragmentReference;
export type EventRequestsStatus_event = {|
  +eventRequestSubmission: ?{|
    +viewerCanUpdate?: boolean,
    +$fragmentRefs: EventRequestsItemStatus_eventRequestSubmission$ref,
  |},
  +$refType: EventRequestsStatus_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestsStatus_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "readOnly",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventRequestSubmission",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestSubmission",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventRequestsItemStatus_eventRequestSubmission",
          "args": null
        },
        {
          "kind": "Condition",
          "passingValue": false,
          "condition": "readOnly",
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "viewerCanUpdate",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f61597a169ba3dd07e79d51e62a7fc74';
module.exports = node;
