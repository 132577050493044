/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type { ReorderedPageComponentConfigType } from 'views/Main/Event/Registration/RegistrationCreate/pageComponentsConfig';

import type {
  reorderRegistrationFormPageComponentsFieldsMutationResponse,
  reorderRegistrationFormPageComponentsFieldsMutationVariables,
} from './__generated__/reorderRegistrationFormPageComponentsFieldsMutation.graphql';

const mutation = graphql`
  mutation reorderRegistrationFormPageComponentsFieldsMutation(
    $input: ReorderRegistrationFormPageComponentsInput!
  ) {
    reorderRegistrationFormPageComponents(input: $input) {
      updatedPageComponents {
        id
        order
        parent {
          id
        }
        childPageComponents {
          edges {
            node {
              id
              columnChild {
                id
              }
            }
          }
        }
        ...ImageComponent_componentProps
        ...EventNameComponent_componentProps
        ...EventDatesComponent_componentProps
        ...TextComponent_componentProps
        ...DividerComponent_componentProps
        ...RowComponent_componentProps
        ...VideoComponent_componentProps
        ...FormComponent_componentProps
        ...RegistrationPageComponents_selectedPageComponent
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export type PageComponentOrderType = {
  id: string,
  order: number,
};

export default function reorderRegistrationFormPageComponents(
  formId: string,
  pageComponentsOrder: $ReadOnlyArray<PageComponentOrderType>,
  reorderedPageComponentConfig?: ?ReorderedPageComponentConfigType,
): Promise<reorderRegistrationFormPageComponentsFieldsMutationResponse> {
  const variables: reorderRegistrationFormPageComponentsFieldsMutationVariables = {
    input: {
      formId,
      pageComponentsOrder,
      reorderedComponentParent:
        reorderedPageComponentConfig &&
        reorderedPageComponentConfig.parentId &&
        reorderedPageComponentConfig.id
          ? {
              id: reorderedPageComponentConfig.id,
              parentId: reorderedPageComponentConfig.parentId,
            }
          : undefined,
    },
  };

  const updatedPageComponents = pageComponentsOrder.map(pageComponent => ({
    id: pageComponent.id,
    order: pageComponent.order,
    parent: null,
  }));
  if (
    reorderedPageComponentConfig &&
    reorderedPageComponentConfig.id &&
    reorderedPageComponentConfig.parentId
  ) {
    updatedPageComponents.push({
      id: reorderedPageComponentConfig.id,
      order: 0,
      parent: { id: reorderedPageComponentConfig.parentId },
    });
  }

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      reorderRegistrationFormPageComponents: {
        updatedPageComponents,
      },
    },
  });
}
