/**
 * @flow
 * @relayHash d09bdb19c167091cc9ec370ea6a557af
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PublicResourceRoutesQueryVariables = {|
  publicRuleName: string,
  resourceToken: string,
  orgSlug?: ?string,
|};
export type PublicResourceRoutesQueryResponse = {|
  +publicRule: boolean,
  +publicResourceToken: {|
    +id: string,
    +shareableType: string,
  |},
  +org: {|
    +subscription: {|
      +featureAccess: {|
        +legacyFeatures: boolean
      |}
    |}
  |},
|};
export type PublicResourceRoutesQuery = {|
  variables: PublicResourceRoutesQueryVariables,
  response: PublicResourceRoutesQueryResponse,
|};
*/


/*
query PublicResourceRoutesQuery(
  $publicRuleName: String!
  $resourceToken: String!
  $orgSlug: String
) {
  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
  publicResourceToken(token: $resourceToken) {
    id
    shareableType
  }
  org {
    subscription {
      featureAccess {
        legacyFeatures
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "publicRuleName",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "resourceToken",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orgSlug",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicRule",
  "args": [
    {
      "kind": "Variable",
      "name": "orgSlug",
      "variableName": "orgSlug",
      "type": "String"
    },
    {
      "kind": "Variable",
      "name": "publicRuleName",
      "variableName": "publicRuleName",
      "type": "String!"
    },
    {
      "kind": "Variable",
      "name": "resourceToken",
      "variableName": "resourceToken",
      "type": "String"
    }
  ],
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "publicResourceToken",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "token",
      "variableName": "resourceToken",
      "type": "String!"
    }
  ],
  "concreteType": "PublicResourceToken",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shareableType",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "featureAccess",
  "storageKey": null,
  "args": null,
  "concreteType": "FeatureAccess",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyFeatures",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PublicResourceRoutesQuery",
  "id": null,
  "text": "query PublicResourceRoutesQuery(\n  $publicRuleName: String!\n  $resourceToken: String!\n  $orgSlug: String\n) {\n  publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)\n  publicResourceToken(token: $resourceToken) {\n    id\n    shareableType\n  }\n  org {\n    subscription {\n      featureAccess {\n        legacyFeatures\n      }\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PublicResourceRoutesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v1,
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PublicResourceRoutesQuery",
    "argumentDefinitions": v0,
    "selections": [
      v1,
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v4,
              v2
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '34c37e8ad3cc1873e59448447969240d';
module.exports = node;
