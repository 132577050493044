/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type LiveActions_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationFormActionsBar_event$ref: FragmentReference;
export type RegistrationFormActionsBar_event = {|
  +id: string,
  +editableRegistrationForm: ?{|
    +id: string,
    +hasChanges: boolean,
  |},
  +$fragmentRefs: LiveActions_event$ref,
  +$refType: RegistrationFormActionsBar_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RegistrationFormActionsBar_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "editableRegistrationForm",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationForm",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "hasChanges",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "LiveActions_event",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb9614df179febf2e41de65517b6d41e';
module.exports = node;
