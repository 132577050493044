/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactPageRoutes_contact$ref = any;
export type ContactSyncOption = "contact" | "lead";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactPage_contact$ref: FragmentReference;
export type ContactPage_contact = {|
  +id: string,
  +email: string,
  +slug: string,
  +firstName: string,
  +lastName: string,
  +salesforceSyncAs: ?ContactSyncOption,
  +restrictedEventsCount: number,
  +salesforceLeadStatus: ?{|
    +name: string
  |},
  +company: ?{|
    +id: string,
    +name: string,
    +salesforceId: ?string,
  |},
  +title: ?string,
  +avatar: ?string,
  +salesforceId: ?string,
  +marketoId: ?string,
  +syncStatus: {|
    +lastSuccessAt: ?any,
    +state: SyncState,
    +errorMessage: string,
  |},
  +viewerCanRemove: boolean,
  +viewerCanUpdate: boolean,
  +events: {|
    +totalCount: number
  |},
  +opportunities: {|
    +totalCount: number
  |},
  +eventRequestSubmissions: {|
    +totalCount: number
  |},
  +$fragmentRefs: ContactPageRoutes_contact$ref,
  +$refType: ContactPage_contact$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "salesforceId",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "ContactPage_contact",
  "type": "Contact",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "salesforceSyncAs",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "restrictedEventsCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceLeadStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceLeadStatusType",
      "plural": false,
      "selections": [
        v1
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "company",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": false,
      "selections": [
        v0,
        v1,
        v2
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "avatar",
      "args": null,
      "storageKey": null
    },
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "marketoId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "syncStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastSuccessAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "errorMessage",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanRemove",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "events",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequiredConnection",
      "plural": false,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "opportunities",
      "storageKey": null,
      "args": null,
      "concreteType": "ContactOpportunityConnection",
      "plural": false,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventRequestSubmissions",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestSubmissionRequiredConnection",
      "plural": false,
      "selections": v3
    },
    {
      "kind": "FragmentSpread",
      "name": "ContactPageRoutes_contact",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5a3414becb8f012b3e976bad793cb672';
module.exports = node;
