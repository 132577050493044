/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import formatLocation from 'utils/locations/formatLocation';
import type { Location } from 'utils/locations/locationTypes';
import isValidWebsite from 'utils/validators/isValidWebsite';

import SelectField, { Field, SelectedLabel } from 'components/material/SelectField';

import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';
import LinkField from '../form/LinkField';
import LocationField from '../form/LocationField';
import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../lib/types';

const StyledSelectField = styled(SelectField)`
  width: 145px;
  margin: 10px 15px 0 0;
  border-radius: 4px;
  border: solid 1px #dadada;
  background-color: #ffffff;
  font-size: 16px;

  ${Field} {
    padding: 8px 10px 6px;
    border: 0;
  }

  ${SelectedLabel} {
    color: rgba(74, 86, 101, 0.54);
  }
`;

const LocationInput = styled.div`
  flex: 1 1 auto;
`;

const StyledLinkField = styled(LinkField)`
  flex: 1 1 auto;
`;

const StyledLocationField = styled(LocationField)`
  flex: 1 1 auto;
  input {
    ${props =>
      props.error &&
      css`
        border-color: ${props.theme.negativeActionColor};
        &:hover:not(:focus) {
          border-color: ${props.theme.negativeActionColor};
        }
      `};
  }
`;

const QuestionInputContainer = styled.div`
  display: flex;
  /* there is issue with select field unFocus, if it is the first element in div */
  flex-direction: row-reverse;
`;

const LocationTypeOptions = [
  {
    label: 'Address',
    value: 'physical',
  },
  {
    label: 'Virtual/Online',
    value: 'virtual',
  },
];

type LocationType = 'physical' | 'virtual';

export default class QuestionLocation extends React.PureComponent<
  {
    question: QuestionType,
    value: QuestionValueType,
    onChangeValue: QuestionValueInputType => void,
    readOnly: boolean,
    error: ?string,
    onChangeShowError: (showError: boolean) => void,
  },
  {
    locationType: LocationType,
  },
> {
  state = {
    locationType: this.props.value.textValue ? 'virtual' : 'physical',
  };

  handleSelectLocation = (locationArg: Location) => {
    const { website, note, google_placeid, ...location } = locationArg;

    this.props.onChangeValue({ locationValue: location });
  };

  handleChangeLocationType = (locationType: ?LocationType) => {
    if (!locationType || locationType === this.state.locationType) {
      return;
    }

    this.setState({ locationType });
    this.props.onChangeValue({ locationValue: null, textValue: null });
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeValue({ textValue: e.currentTarget.value || null });
    this.props.onChangeShowError(false);
  };

  handleLocationBlur = () => {
    this.props.onChangeShowError(true);

    const httpValue = enforceHttpPrefix(this.props.value.textValue || '');

    if (isValidWebsite(httpValue) && httpValue !== this.props.value.textValue) {
      this.props.onChangeValue({ textValue: httpValue });
    }
  };

  render() {
    const { textValue, locationValue } = this.props.value;
    const { locationType } = this.state;
    const defaultQuery = locationValue
      ? formatLocation({
          name: locationValue.name,
          city: locationValue.city,
          country: locationValue.country,
          state: locationValue.state,
        })
      : '';

    return (
      <React.Fragment>
        {this.props.question.description && (
          <HelperText>{this.props.question.description}</HelperText>
        )}
        <QuestionInputContainer>
          <LocationInput>
            {locationType === 'physical' && (
              <StyledLocationField
                defaultQuery={defaultQuery}
                onSelectLocation={this.handleSelectLocation}
                error={this.props.error}
                disabled={this.props.readOnly}
              />
            )}
            {locationType === 'virtual' && (
              <StyledLinkField
                value={textValue || ''}
                placeholder="Enter virtual location"
                error={!!this.props.error}
                onChange={this.handleChange}
                onBlur={this.handleLocationBlur}
                disabled={this.props.readOnly}
              />
            )}
          </LocationInput>
          <StyledSelectField
            value={this.state.locationType}
            options={LocationTypeOptions}
            onChange={this.handleChangeLocationType}
            compact
          />
        </QuestionInputContainer>
        {this.props.error && <ErrorMessage>{this.props.error}</ErrorMessage>}
      </React.Fragment>
    );
  }
}
