/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import type {
  resendInviteMutationVariables,
  resendInviteMutationResponse,
} from './__generated__/resendInviteMutation.graphql';

const mutation = graphql`
  mutation resendInviteMutation($input: ResendInviteInput!) {
    resendInvite(input: $input) {
      user {
        id
      }
    }
  }
`;

export default function resendInvite(
  userId: string,
  teamId?: string,
): Promise<resendInviteMutationResponse> {
  const variables: resendInviteMutationVariables = {
    input: {
      userId,
      teamId,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
  });
}
