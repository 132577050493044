/**
 * @flow
 * @relayHash 2afd034d281a85ff70a7bb31bb0cba15
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetEventMarketoProgramInput = {
  clientMutationId?: ?string,
  eventId: string,
  marketoProgram?: ?MarketoProgramInput,
};
export type MarketoProgramInput = {
  marketoId: string,
  name: string,
  url: string,
};
export type setEventMarketoProgramMutationVariables = {|
  input: SetEventMarketoProgramInput
|};
export type setEventMarketoProgramMutationResponse = {|
  +setEventMarketoProgram: ?{|
    +event: {|
      +id: string,
      +marketoProgram: ?{|
        +marketoId: string,
        +name: string,
        +url: string,
      |},
      +syncedContacts: {|
        +totalCount: number
      |},
      +disabledContacts: {|
        +totalCount: number
      |},
      +failedContacts: {|
        +totalCount: number
      |},
      +pendingContacts: {|
        +totalCount: number
      |},
    |}
  |}
|};
export type setEventMarketoProgramMutation = {|
  variables: setEventMarketoProgramMutationVariables,
  response: setEventMarketoProgramMutationResponse,
|};
*/


/*
mutation setEventMarketoProgramMutation(
  $input: SetEventMarketoProgramInput!
) {
  setEventMarketoProgram(input: $input) {
    event {
      id
      marketoProgram {
        marketoId
        name
        url
        id
      }
      syncedContacts: eventContacts(filters: {membershipSyncStatuses: [OK]}) {
        totalCount
      }
      disabledContacts: eventContacts(filters: {membershipSyncStatuses: [DISABLED]}) {
        totalCount
      }
      failedContacts: eventContacts(filters: {membershipSyncStatuses: [FAILING]}) {
        totalCount
      }
      pendingContacts: eventContacts(filters: {membershipSyncStatuses: [PENDING]}) {
        totalCount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetEventMarketoProgramInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "SetEventMarketoProgramInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "marketoId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v7 = {
  "kind": "LinkedField",
  "alias": "syncedContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"OK\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "OK"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v6
},
v8 = {
  "kind": "LinkedField",
  "alias": "disabledContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"DISABLED\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "DISABLED"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v6
},
v9 = {
  "kind": "LinkedField",
  "alias": "failedContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"FAILING\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "FAILING"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v6
},
v10 = {
  "kind": "LinkedField",
  "alias": "pendingContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"PENDING\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "PENDING"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v6
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "setEventMarketoProgramMutation",
  "id": null,
  "text": "mutation setEventMarketoProgramMutation(\n  $input: SetEventMarketoProgramInput!\n) {\n  setEventMarketoProgram(input: $input) {\n    event {\n      id\n      marketoProgram {\n        marketoId\n        name\n        url\n        id\n      }\n      syncedContacts: eventContacts(filters: {membershipSyncStatuses: [OK]}) {\n        totalCount\n      }\n      disabledContacts: eventContacts(filters: {membershipSyncStatuses: [DISABLED]}) {\n        totalCount\n      }\n      failedContacts: eventContacts(filters: {membershipSyncStatuses: [FAILING]}) {\n        totalCount\n      }\n      pendingContacts: eventContacts(filters: {membershipSyncStatuses: [PENDING]}) {\n        totalCount\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "setEventMarketoProgramMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "setEventMarketoProgram",
        "storageKey": null,
        "args": v1,
        "concreteType": "SetEventMarketoProgramPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "marketoProgram",
                "storageKey": null,
                "args": null,
                "concreteType": "MarketoProgram",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5
                ]
              },
              v7,
              v8,
              v9,
              v10
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "setEventMarketoProgramMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "setEventMarketoProgram",
        "storageKey": null,
        "args": v1,
        "concreteType": "SetEventMarketoProgramPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "marketoProgram",
                "storageKey": null,
                "args": null,
                "concreteType": "MarketoProgram",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v2
                ]
              },
              v7,
              v8,
              v9,
              v10
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '07c5c1026139a5dd283af30c4c4f4762';
module.exports = node;
