/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type updateCompanyMutationVariables } from './__generated__/updateCompanyMutation.graphql';

const mutation = graphql`
  mutation updateCompanyMutation($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        id
        name
        website
        phone
        twitter
        linkedin
        description
        country
        state
        city
        zip
        street
      }
    }
  }
`;

export type CompanyFieldInput = $Exact<$PropertyType<updateCompanyMutationVariables, 'input'>>;

export default function updateCompany(input: CompanyFieldInput) {
  const { companyId, eventId, fromWindow, ...rest } = input;
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    optimisticResponse: {
      updateCompany: {
        company: { id: companyId, ...rest },
      },
    },
  });
}
