/* @flow */
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'react-relay';
import type { Match } from 'react-router';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

import viewedEventRequestForm from 'utils/analytics/events/viewedEventRequestForm';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';

import EventRequestSharedFormContainer from './EventRequestSharedFormContainer';

import type { EventRequestSharedFormQueryResponse } from './__generated__/EventRequestSharedFormQuery.graphql';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 100px;
  padding-bottom: 120px;
  align-items: center;
  overflow-y: auto;
  background: #fafafa;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0;
  }
`;

const query = graphql`
  query EventRequestSharedFormQuery($token: String!, $submitToken: String!, $isUpdating: Boolean!) {
    org {
      ...EventRequestSharedFormContainer_org
    }
    publicMe {
      ...EventRequestSharedFormContainer_me
    }
    ssoUser {
      ...EventRequestSharedFormContainer_ssoUser
    }
    eventRequestForm(token: $token) {
      ...EventRequestSharedFormContainer_requestForm
    }
    eventRequestSubmission(token: $submitToken) @include(if: $isUpdating) {
      ...EventRequestSharedFormContainer_requestSubmission
    }
  }
`;

class EventRequestSharedForm extends React.PureComponent<{
  match: Match,
}> {
  componentDidMount() {
    viewedEventRequestForm();
  }

  render() {
    const { token, submitToken } = this.props.match.params;

    return (
      <Background>
        <DefaultQueryRenderer
          query={query}
          public
          variables={{
            token,
            submitToken: submitToken || '',
            isUpdating: submitToken != null,
          }}
          renderSuccess={(response: EventRequestSharedFormQueryResponse) => (
            <EventRequestSharedFormContainer
              requestForm={response.eventRequestForm}
              requestSubmission={response.eventRequestSubmission || null}
              org={response.org}
              me={response.publicMe || null}
              ssoUser={response.ssoUser || null}
              token={token}
              submitToken={submitToken}
            />
          )}
          renderError={() => <NotAuthorized />}
        />
      </Background>
    );
  }
}

export default DragDropContext(HTML5Backend)(EventRequestSharedForm);
