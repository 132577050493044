/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import type { Match, RouterLocation } from 'react-router';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import EventBriefRoutes from './EventBriefRoutes';
import EventBriefSubmenu from './EventBriefSubmenu';

import { type EventBriefTemplateQueryResponse } from './__generated__/EventBriefTemplateQuery.graphql';

const Columns = styled.div`
  display: flex;
  margin-top: 15px;
  color: #4a5665;
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

const query = graphql`
  query EventBriefTemplateQuery {
    org {
      ...EventBriefRoutes_org
    }
  }
`;

export default class EventBriefTemplate extends React.Component<{
  match: Match,
  location: RouterLocation,
}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(props: EventBriefTemplateQueryResponse) => (
          <Columns>
            <EventBriefSubmenu />
            <Content>
              <EventBriefRoutes
                org={props.org}
                pathPrefix={this.props.match.path}
                pathname={this.props.location.pathname}
              />
            </Content>
          </Columns>
        )}
      />
    );
  }
}
