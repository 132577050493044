/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateBriefTemplateEventInfoFieldMutationResponse,
  UpdateBriefTemplateEventInfoFieldInput,
} from './__generated__/updateBriefTemplateEventInfoFieldMutation.graphql';

const mutation = graphql`
  mutation updateBriefTemplateEventInfoFieldMutation(
    $input: UpdateBriefTemplateEventInfoFieldInput!
  ) {
    updateBriefTemplateEventInfoField(input: $input) {
      eventInfoField {
        id
        enabled
      }
    }
  }
`;

export default function updateBriefTemplateEventInfoField(
  eventInfoField: UpdateBriefTemplateEventInfoFieldInput,
): Promise<updateBriefTemplateEventInfoFieldMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input: eventInfoField },
    optimisticResponse: {
      updateBriefTemplateEventInfoField: {
        eventInfoField,
      },
    },
  });
}
