/* @flow */

import * as React from 'react';
import styled, { css } from 'styled-components';

import BriefViewSharedContext from '../BriefViewSharedContext';

const Header = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 14px;
  border-bottom: 3px solid ${props => props.color};
`;

const Name = styled.div`
  display: inline-block;
  align-self: center;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 3px;
  font-weight: 500;
  color: ${props => props.color};
  text-transform: uppercase;
  ${props =>
    props.isShared &&
    css`
      @media (max-width: 905px) {
        font-size: 16px;
      }
    `};
`;

const Total = styled.div`
  display: inline-block;
  margin-left: 10px;
  padding: 5px 0;
  font-size: 16px;
  color: #3e4859;
  line-height: 14px;
`;

const SectionHeader = (props: {
  name: string,
  primaryColor: string,
  anchor: string,
  total?: number,
}) => {
  const { name, primaryColor, anchor, total } = props;
  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <Header className="brief_anchor" color={primaryColor} data-name={anchor}>
          <Name isShared={sharedView} color={primaryColor}>
            {name}
          </Name>
          {total != null && <Total>/ {total} total</Total>}
        </Header>
      )}
    </BriefViewSharedContext.Consumer>
  );
};

export default SectionHeader;
