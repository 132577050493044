/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ImportLogsDateColumn_importLog$ref: FragmentReference;
export type ImportLogsDateColumn_importLog = {|
  +createdAt: any,
  +$refType: ImportLogsDateColumn_importLog$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ImportLogsDateColumn_importLog",
  "type": "ImportLog",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a664526c2301bc781e6616bdd81306a4';
module.exports = node;
