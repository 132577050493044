/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type CellPropsType } from 'components/budget/Table';
import InlineDateField from 'components/budget/Table/InlineDateField';

import { type PaymentCellPropsType } from './index';

import { type PaymentActualDateCell_event } from './__generated__/PaymentActualDateCell_event.graphql';
import { type PaymentActualDateCell_payment } from './__generated__/PaymentActualDateCell_payment.graphql';

const PaymentActualDateCell = ({
  payment,
  event,
  updatePayment,
  updateColumnWidth,
}: CellPropsType<
  PaymentCellPropsType<PaymentActualDateCell_payment, PaymentActualDateCell_event>,
>) => (
  <InlineDateField
    date={payment.actualDate}
    tz={event.tz}
    hideTime
    dateOnly
    placeholder="Add Paid Date"
    onChange={({ date }) => {
      updatePayment({ actualDate: date });

      updateColumnWidth();
    }}
  />
);

export default createFragmentContainer(
  PaymentActualDateCell,
  graphql`
    fragment PaymentActualDateCell_payment on Payment {
      actualDate
    }

    fragment PaymentActualDateCell_event on Event {
      tz
    }
  `,
);
