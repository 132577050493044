/**
 * @flow
 * @relayHash bd3ccf7ca9ff6f438301c2c30ab9e725
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AllEventsCalendarEventWindowContent_event$ref = any;
export type AllEventsCalendarEventWindowQueryVariables = {|
  eventSlug: string
|};
export type AllEventsCalendarEventWindowQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: AllEventsCalendarEventWindowContent_event$ref
  |}
|};
export type AllEventsCalendarEventWindowQuery = {|
  variables: AllEventsCalendarEventWindowQueryVariables,
  response: AllEventsCalendarEventWindowQueryResponse,
|};
*/


/*
query AllEventsCalendarEventWindowQuery(
  $eventSlug: String!
) {
  event(slug: $eventSlug) {
    ...AllEventsCalendarEventWindowContent_event
    id
  }
}

fragment AllEventsCalendarEventWindowContent_event on Event {
  name
  startDate
  endDate
  startDateAllDay
  endDateAllDay
  tz
  lead {
    id
    firstName
    lastName
    email
  }
  ...AllEventsCalendarEventWindowLocation_event
}

fragment AllEventsCalendarEventWindowLocation_event on Event {
  virtualLocation
  primaryLocation {
    city
    name
    country
    state
    id
  }
  zoomLocation {
    kind
    joinUrl
    id
  }
  g2wLocation {
    registrationUrl
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AllEventsCalendarEventWindowQuery",
  "id": null,
  "text": "query AllEventsCalendarEventWindowQuery(\n  $eventSlug: String!\n) {\n  event(slug: $eventSlug) {\n    ...AllEventsCalendarEventWindowContent_event\n    id\n  }\n}\n\nfragment AllEventsCalendarEventWindowContent_event on Event {\n  name\n  startDate\n  endDate\n  startDateAllDay\n  endDateAllDay\n  tz\n  lead {\n    id\n    firstName\n    lastName\n    email\n  }\n  ...AllEventsCalendarEventWindowLocation_event\n}\n\nfragment AllEventsCalendarEventWindowLocation_event on Event {\n  virtualLocation\n  primaryLocation {\n    city\n    name\n    country\n    state\n    id\n  }\n  zoomLocation {\n    kind\n    joinUrl\n    id\n  }\n  g2wLocation {\n    registrationUrl\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AllEventsCalendarEventWindowQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AllEventsCalendarEventWindowContent_event",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllEventsCalendarEventWindowQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "startDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "startDateAllDay",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endDateAllDay",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tz",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "lead",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "virtualLocation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "primaryLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "Location",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "city",
                "args": null,
                "storageKey": null
              },
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "country",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "state",
                "args": null,
                "storageKey": null
              },
              v3
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "zoomLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "ZoomLocation",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "kind",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "joinUrl",
                "args": null,
                "storageKey": null
              },
              v3
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "g2wLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "G2WLocation",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "registrationUrl",
                "args": null,
                "storageKey": null
              },
              v3
            ]
          },
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'caddf75a6a978cb3356fda76c8812947';
module.exports = node;
