/**
 * @flow
 * @relayHash 10322bcfce148d332a9a57650879d561
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContactsSearchPagination_searchResults$ref = any;
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type ContactsSearchContentQueryVariables = {|
  search: Search,
  count: number,
  cursor?: ?string,
|};
export type ContactsSearchContentQueryResponse = {|
  +contactsTotalCount: {|
    +totalCount: number
  |},
  +$fragmentRefs: ContactsSearchPagination_searchResults$ref,
|};
export type ContactsSearchContentQuery = {|
  variables: ContactsSearchContentQueryVariables,
  response: ContactsSearchContentQueryResponse,
|};
*/


/*
query ContactsSearchContentQuery(
  $search: Search!
  $count: Int!
  $cursor: String
) {
  contactsTotalCount: searchContacts(search: $search) {
    totalCount
  }
  ...ContactsSearchPagination_searchResults
}

fragment ContactsSearchPagination_searchResults on Query {
  contacts: searchContacts(search: $search, after: $cursor, first: $count) {
    edges {
      node {
        slug
        ...ContactSearchResult_contact
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ContactSearchResult_contact on ContactSearchResult {
  slug
  firstName
  lastName
  email
  company
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "Search!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search",
  "type": "Search!"
},
v2 = {
  "kind": "LinkedField",
  "alias": "contactsTotalCount",
  "name": "searchContacts",
  "storageKey": null,
  "args": [
    v1
  ],
  "concreteType": "ContactSearchResultRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  v1
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ContactsSearchContentQuery",
  "id": null,
  "text": "query ContactsSearchContentQuery(\n  $search: Search!\n  $count: Int!\n  $cursor: String\n) {\n  contactsTotalCount: searchContacts(search: $search) {\n    totalCount\n  }\n  ...ContactsSearchPagination_searchResults\n}\n\nfragment ContactsSearchPagination_searchResults on Query {\n  contacts: searchContacts(search: $search, after: $cursor, first: $count) {\n    edges {\n      node {\n        slug\n        ...ContactSearchResult_contact\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ContactSearchResult_contact on ContactSearchResult {\n  slug\n  firstName\n  lastName\n  email\n  company\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ContactsSearchContentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v2,
      {
        "kind": "FragmentSpread",
        "name": "ContactsSearchPagination_searchResults",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContactsSearchContentQuery",
    "argumentDefinitions": v0,
    "selections": [
      v2,
      {
        "kind": "LinkedField",
        "alias": "contacts",
        "name": "searchContacts",
        "storageKey": null,
        "args": v3,
        "concreteType": "ContactSearchResultRequiredConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactSearchResultRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactSearchResult",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "slug",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "company",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "avatar",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": "contacts",
        "name": "searchContacts",
        "args": v3,
        "handle": "connection",
        "key": "ContactsTablePagination_contacts",
        "filters": []
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72dfe4f2f93847ffd7154414afa1fbcb';
module.exports = node;
