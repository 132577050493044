/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const BUTTON_VARIANTS = {
  primary: { hover: '#5247A3', border: '#685bc7', text: '#685bc7' },
  danger: { hover: '#f38183', border: '#f25d60', text: '#f25d60' },
  disabled: { hover: '#cdd1d4', border: '#cdd1d4', text: '#cdd1d4' },
  cancel: { hover: '#4a5665', border: '#868f96', text: '#868f96' },
  blue: { hover: '#009dce', border: '#39a8da', text: '#39a8da' },
};

type ButtonVariantType = $Keys<$Diff<typeof BUTTON_VARIANTS, { disabled: any }>>;

const Loader = styled.i`
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  font-size: 17px;
`;

const StyledButton = styled.button`
  position: relative;
  padding: 8px 14px;
  border-radius: ${props => (props.radius == null ? 4 : props.radius)}px;
  color: ${props => BUTTON_VARIANTS[props.variant].text};
  border: 1px solid ${props => BUTTON_VARIANTS[props.variant].border};
  font-size: 13px;
  line-height: 1;
  outline: none;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${props => BUTTON_VARIANTS[props.variant].hover};
    border-color: ${props => BUTTON_VARIANTS[props.variant].hover};
  }
  &:disabled {
    border-color: ${BUTTON_VARIANTS.disabled.border};
    color: ${BUTTON_VARIANTS.disabled.text};
    cursor: initial;
  }

  > svg,
  > i:not(${Loader}) {
    margin: 0 5px 0 -4px;
  }

  ${props =>
    props.loading &&
    css`
      > *:not(${Loader}) {
        visibility: hidden;
      }
    `};
`;

export default class OutlineButton extends React.Component<{
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLButtonElement>) => void,
  label: string | React.Node,
  variant: ButtonVariantType,
  className?: string,
  radius?: number,
  loading?: boolean,
  svgIcon?: React.ElementType,
  disabled?: boolean,
  innerRef?: (?HTMLButtonElement) => void,
}> {
  static defaultProps = { variant: 'primary' };

  render() {
    const {
      label,
      onClick,
      className,
      radius,
      variant,
      disabled,
      loading,
      svgIcon: SvgIcon,
      onMouseLeave,
      innerRef,
    } = this.props;

    return (
      <StyledButton
        onClick={onClick}
        className={className}
        variant={variant}
        radius={radius}
        disabled={disabled || loading}
        onMouseLeave={onMouseLeave}
        loading={loading ? 1 : 0}
        ref={button => {
          if (innerRef) {
            innerRef(button);
          }
        }}
      >
        {loading && <Loader className="fa fa-circle-o-notch fa-spin" />}
        {SvgIcon && <SvgIcon fill={BUTTON_VARIANTS[disabled ? 'disabled' : variant].text} />}
        <span>{label}</span>
      </StyledButton>
    );
  }
}
