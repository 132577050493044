/* @flow */
import { getCookie } from 'utils/Cookies';

export default function requestEmailDelivery(
  uuid: string,
  email: string,
  key: string,
  fileName: string,
  authorizeBy: string,
): Promise<void> {
  const filenameWithoutExtension = fileName.split('.')[0];
  return fetch(
    `/v1/exportable_generators/${uuid}/request_email_delivery?email=${email}&key=${key}&filename=${filenameWithoutExtension}`,
    {
      credentials: 'same-origin',
      method: 'post',
      headers: {
        Authorization: getCookie(authorizeBy),
      },
    },
  ).then(response => {
    if (response.status >= 400) {
      return response.json().then(json => Promise.reject(new Error(json.errors)));
    }
    return response.json();
  });
}
