/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable react/no-multi-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
import './calendar.styl';

import React from 'react';
import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { createMoment, isDisabled } from 'utils/Date';

import ClearIcon from 'components/ClearIcon';
import TimePicker from 'components/date/TimePicker';
import { Container as InputContainer, StyledTextInput } from 'components/material/TextField';

const TimeWrapper = styled.div`
  display: flex;
  ${ClearIcon} {
    display: none;
  }
`;

const Label = styled.div`
  padding-right: 10px;
  font-size: 13px;
  font-weight: 500;
  color: #626161;
`;

const StyledTimePicker = styled(TimePicker)`
  width: 85px;
  ${InputContainer} {
    margin: 0;
  }
  ${StyledTextInput} {
    width: 100%;
    padding: 5px 7px;
    padding-left: 20px !important; /* its for overwriting another important style */
    border-radius: 3px;
    border: 1px solid #dfdede;
    font-size: 13px;
    line-height: inherit;
    color: #4a4a4a;
    background: #fff;
  }
`;

export class Calendar extends React.Component {
  handleDayClick = (selected, { disabled }) => {
    if (disabled) return;

    const { onDateChange, date, tz } = this.props;
    const _selected = moment.tz(moment(selected).toArray(), tz);
    const _date = this.moment(date);

    onDateChange(
      _date.year(_selected.year()).month(_selected.month()).date(_selected.date()).toDate(),
    );
  };

  handleDropdownChange = selected => {
    const { onDateChange, date } = this.props;

    const _selected = this.moment(selected);
    const _date = this.moment(date);

    onDateChange(_date.year(_selected.year()).month(_selected.month()).toDate());
  };

  handleTimeChange = time => {
    const { date, onDateChange } = this.props;
    const _date = this.moment(date);
    const _time = this.moment(time || date);

    onDateChange(_date.hour(_time.hour()).minute(_time.minute()).toDate());
  };

  handleAllDayChange = () => {
    const { date, all_day, tz, onAllDayChange, onDateChange } = this.props;
    const _date = this.moment(date);
    onAllDayChange(!all_day);
    if (!all_day) onDateChange(_date.toUTCWithDate().toDate());
    else onDateChange(_date.toTimeZoneNoon(tz).toDate());
  };

  render() {
    const { date, tz, all_day, after, before, usedDays, hiddenCheckbox } = this.props;
    this.moment = createMoment(tz);
    const _date = this.moment(date);
    const today = this.moment();
    const modifiers = {
      selected: day => {
        const _day = _date
          .clone()
          .year(day.getFullYear())
          .month(day.getMonth())
          .date(day.getDate());
        return _date.isSame(_day.toDate(), 'day');
      },
      disabled: day => {
        const _day = _date
          .clone()
          .year(day.getFullYear())
          .month(day.getMonth())
          .date(day.getDate());
        return isDisabled(this.props, _day.toDate());
      },
      current: day => {
        return moment.tz(moment(day).toArray(), tz).isSame(today, 'day');
      },
    };

    const disabledDaysTz = usedDays
      ? usedDays.map(day => moment(moment.tz(day.date, tz).toArray()).toDate())
      : null;

    return (
      <div className="date-time-picker">
        <DayPicker
          month={moment(_date.toArray()).toDate()}
          modifiers={modifiers}
          fromMonth={after && createMoment(tz)(after).toDate()}
          toMonth={before && createMoment(tz)(before).toDate()}
          onDayClick={this.handleDayClick}
          disabledDays={disabledDaysTz}
          captionElement={
            <Caption
              onChange={this.handleDropdownChange}
              currentDate={date}
              isDisabled={isDisabled.bind(this, this.props)}
              moment={this.moment}
            />
          }
        />
        {!hiddenCheckbox && (
          <label className="date-time-picker-all-day-checkbox">
            <input type="checkbox" checked={all_day} onChange={this.handleAllDayChange} />
            All Day
          </label>
        )}
        {!all_day && (
          <TimeWrapper>
            <Label>Time:</Label>
            <StyledTimePicker
              tz={tz}
              date={date || today.format()}
              time={date || today.format()}
              onChange={this.handleTimeChange}
            />
          </TimeWrapper>
        )}
      </div>
    );
  }
}

class Caption extends React.Component {
  render() {
    const { currentDate, onChange, isDisabled, moment, date } = this.props;
    const years = moment().getYears(moment(date));
    const months = moment.origin.monthsShort();
    const handleChange = () => {
      const [year, month] = [this.year, this.month];
      onChange(moment([year.value, month.value]).toDate());
    };
    const mmonth = moment(date);
    const current_date = moment(currentDate).month(mmonth.month()).year(mmonth.year());
    const currentMonth = current_date.month();
    const currentYear = current_date.year();
    return (
      <div className="DayPicker-Caption">
        <div className="DayPicker-Caption-Dropdown">
          <select
            name="month"
            onChange={handleChange}
            value={currentMonth}
            ref={month => {
              this.month = month;
            }}
          >
            {months.map((month, i) => (
              <option
                key={i}
                value={i}
                disabled={isDisabled(moment([currentYear, i, current_date.date()]))}
              >
                {month}
              </option>
            ))}
          </select>
        </div>
        <div className="DayPicker-Caption-Dropdown">
          <select
            name="year"
            onChange={handleChange}
            value={currentYear}
            ref={year => {
              this.year = year;
            }}
          >
            {years.map((year, i) => (
              <option
                key={i}
                value={year}
                disabled={isDisabled(moment([year, currentMonth, current_date.date()]))}
              >
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}
