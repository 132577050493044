/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import { NoSearchResultsMessage, SectionContainer } from './components';
import ContactsSearchPagination from './ContactsSearchPagination';
import EventNotesSearchPagination from './EventNotesSearchPagination';
import EventsSearchPagination from './EventsSearchPagination';
import type { ContentComponentProps } from './SearchOverlay';

import type { GeneralSearchContentQueryResponse } from './__generated__/GeneralSearchContentQuery.graphql';

const AllSearchResultsContainer = styled(SectionContainer)`
  > div:first-child {
    margin-top: 0;
  }
  > div:last-child {
    margin-bottom: 0;
  }
`;

const searchQuery = graphql`
  query GeneralSearchContentQuery(
    $search: Search!
    $eventFilters: EventFilters!
    $includeContacts: Boolean!
    $count: Int!
    $cursor: String
  ) {
    eventsTotalCount: searchEvents(search: $search, filters: $eventFilters) {
      totalCount
    }
    contactsTotalCount: searchContacts(search: $search) @include(if: $includeContacts) {
      totalCount
    }
    notesTotalCount: searchEventNotes(search: $search) {
      totalCount
    }
    ...EventsSearchPagination_searchResults
    ...ContactsSearchPagination_searchResults @include(if: $includeContacts)
    ...EventNotesSearchPagination_searchResults
  }
`;

class GeneralSearchContent extends React.PureComponent<ContentComponentProps> {
  getSearchString = (): string => {
    const { query, exactMatch } = this.props;
    return `${query}${exactMatch ? '~EXACT' : ''}`;
  };

  handleShowAllContactResults = () => {
    window.open(`/contacts/people?search=${this.getSearchString()}`, '_blank');
  };

  handleShowAllEventResults = () => {
    window.open(
      `/dashboard/all_events?requestStatuses=N_A,APPROVE&search=${this.getSearchString()}`,
      '_blank',
    );
  };

  render() {
    const { query, exactMatch, includeContacts } = this.props;

    const variables = {
      search: {
        search: query,
        exactMatch,
      },
      eventFilters: {
        requestStatuses: ['N_A', 'APPROVED'],
      },
      includeContacts,
      count: 5,
    };

    return (
      <DefaultQueryRenderer
        query={searchQuery}
        variables={variables}
        renderSuccess={(response: ?GeneralSearchContentQueryResponse) => {
          if (response == null) {
            return null;
          }

          if (
            response.eventsTotalCount.totalCount +
              (response.contactsTotalCount == null ? 0 : response.contactsTotalCount.totalCount) +
              response.notesTotalCount.totalCount ===
            0
          ) {
            return <NoSearchResultsMessage query={query} />;
          }

          return (
            <AllSearchResultsContainer scrollable mobileView>
              {response.eventsTotalCount.totalCount > 0 && (
                <EventsSearchPagination
                  query={query}
                  searchResults={response}
                  totalCount={response.eventsTotalCount.totalCount}
                  onShowAllResults={this.handleShowAllEventResults}
                />
              )}
              {includeContacts &&
                response.contactsTotalCount &&
                response.contactsTotalCount.totalCount > 0 && (
                  <ContactsSearchPagination
                    query={query}
                    searchResults={response}
                    totalCount={response.contactsTotalCount.totalCount}
                    onShowAllResults={this.handleShowAllContactResults}
                  />
                )}
              {response.notesTotalCount.totalCount > 0 && (
                <EventNotesSearchPagination
                  query={query}
                  searchResults={response}
                  totalCount={response.notesTotalCount.totalCount}
                />
              )}
            </AllSearchResultsContainer>
          );
        }}
      />
    );
  }
}

export default GeneralSearchContent;
