import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';

import { download, getFileTypeClassName } from 'utils/Attachments';

import addAttachmentToBrief from 'graph/mutations/event_brief/addAttachmentToBrief';
import removeAttachmentFromBrief from 'graph/mutations/event_brief/removeAttachmentFromBrief';
import showModernMutationError from 'graph/utils/showModernMutationError';

class AttachmentItem extends React.Component {
  static propTypes = {
    disableDelete: PropTypes.bool,
    onRemove: PropTypes.func.isRequired,
    onPreview: PropTypes.func.isRequired,
    attachment: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      fileurl: PropTypes.string,
      filename: PropTypes.string.isRequired,
      filetype: PropTypes.string.isRequired,
      loading: PropTypes.bool,
      email: PropTypes.shape({
        id: PropTypes.string.isRequired,
        subject: PropTypes.string,
      }),
      deliverable: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }).isRequired,
    event_relay_id: PropTypes.string,
    event_slug: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    canUpdateBrief: PropTypes.bool,
    includedInBrief: PropTypes.bool,
  };

  handleClick = should_open => {
    if (this.props.attachment.loading) return;
    const { attachment, onPreview } = this.props;
    if (should_open) {
      window.open(attachment.fileurl);
    } else {
      onPreview();
    }
  };

  handleDownload = e => {
    e.stopPropagation();

    const { attachment } = this.props;
    const { fileurl, filename } = attachment;

    download(fileurl, filename);
  };

  handleRemove = e => {
    e.stopPropagation();
    this.props.onRemove();
  };

  handleDeliverableClick = e => {
    e.stopPropagation();
    this.props.history.push(
      `/events/${this.props.event_slug}/tasks/${this.props.attachment.deliverable.slug}`,
    );
  };

  handleSaveBrief = e => {
    e.stopPropagation();
    if (this.props.includedInBrief) {
      removeAttachmentFromBrief(this.props.attachment.id, this.props.event_relay_id).catch(
        showModernMutationError,
      );
    } else {
      addAttachmentToBrief(this.props.attachment.id, this.props.event_relay_id).catch(
        showModernMutationError,
      );
    }
  };

  render() {
    const { attachment, disableDelete, event_slug, canUpdateBrief, includedInBrief } = this.props;
    const briefClassName = includedInBrief ? 'fa-minus' : 'fa-plus';
    const briefTooltipName = includedInBrief ? 'Remove from Brief' : 'Add to Brief';
    const { loading } = attachment;
    const isEmail = attachment.email;
    const isLink = (attachment.filetype || '').startsWith('link/');
    const iconClassName = loading
      ? 'fa-circle-o-notch fa-spin'
      : getFileTypeClassName(attachment.filetype);
    return (
      <div
        className={classNames('attachment-item', { loading })}
        onClick={() => this.handleClick(isLink)}
      >
        {isEmail && attachment.email.subject ? (
          <i className="fa fa-fw fa-envelope" />
        ) : (
          <i className={classNames('fa fa-fw', iconClassName)} />
        )}
        <div className="attachment-item-main">
          <a title={attachment.filename}>{attachment.filename || attachment.fileurl}</a>
        </div>
        {!loading && (
          <div className="attachment-actions">
            {canUpdateBrief && (
              <span onClick={this.handleSaveBrief} data-tooltip={briefTooltipName}>
                <i className={classNames('fa fa-fw', briefClassName)} />
              </span>
            )}
            {event_slug &&
              attachment.deliverable && (
                <span data-tooltip="View Task" onClick={this.handleDeliverableClick}>
                  <i className="fa fa-fw fa-check" />
                </span>
              )}
            {!isLink && (
              <span data-tooltip="Preview">
                <i className="fa fa-fw fa-eye" />
              </span>
            )}
            {isLink && (
              <span data-tooltip="Open Link">
                <i className="fa fa-fw fa-external-link" />
              </span>
            )}
            {!isLink && (
              <span onClick={this.handleDownload} data-tooltip="Download">
                <i className="fa fa-fw fa-download" />
              </span>
            )}
            {!disableDelete && (
              <span data-tooltip="Delete" onClick={this.handleRemove}>
                <i className="fa fa-fw fa-trash" />
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(AttachmentItem);
