/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import replaceLoDash from 'lodash/replace';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';
import insertTextIntoTextField from 'utils/insertTextIntoTextField';
import sanitizeHtml from 'utils/string/sanitizeHtml';
import type { CircaFile } from 'utils/uploading/types';

import {
  type EventEmailTemplateBaseDate,
  type EventEmailTemplateDirection,
  type EventEmailTemplateIntervalUnit,
} from 'graph/mutations/eventEmailTemplate/__generated__/createEventEmailTemplateMutation.graphql';
import createEventEmailTemplate from 'graph/mutations/eventEmailTemplate/createEventEmailTemplate';
import removeEventEmailTemplate from 'graph/mutations/eventEmailTemplate/removeEventEmailTemplate';
import updateEventEmailTemplate from 'graph/mutations/eventEmailTemplate/updateEventEmailTemplate';

import { MinimalButton } from 'components/budget/Button';
import SimpleAttachments from 'components/material/Attachments/SimpleAttachments';
import NumberField from 'components/material/NumberField';
import SelectField from 'components/material/SelectField';
import TextField, { Error } from 'components/material/TextField';
import ShareDropdown from 'components/ShareDropdown';
import { StyledContent } from 'components/TinyRichText';
import UIContext from 'views/Main/Event/Registration/UIContext';

import EditableContainer from '../EditableContainer';
import { StyledTinyRichText } from '../EditableEmailTemplate';
import ScheduledEmailFilters from './ScheduledEmailFilters';
import ScheduledEmailTemplateFilterTooltip from './ScheduledEmailTemplateFilterTooltip';

import type { ScheduledEmailTemplate_eventEmailTemplate } from './__generated__/ScheduledEmailTemplate_eventEmailTemplate.graphql';
import type { ScheduledEmailTemplate_org } from './__generated__/ScheduledEmailTemplate_org.graphql';

const BASE_DATE_LABELS = [
  {
    value: 'before_start_date',
    label: 'Before event start',
  },
  {
    value: 'after_start_date',
    label: 'After event start',
  },
  {
    value: 'before_end_date',
    label: 'Before event end',
  },
  {
    value: 'after_end_date',
    label: 'After event end',
  },
];

const INTERVAL_UNIT_OPTIONS = [
  {
    value: 'minutes',
    label: 'Minutes',
  },
  {
    value: 'hours',
    label: 'Hours',
  },
  {
    value: 'days',
    label: 'Days',
  },
  {
    value: 'months',
    label: 'Months',
  },
];

const ScheduleTimeLabel = styled.div`
  line-height: 14px;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 13px;
  color: #4a5665;

  span {
    font-weight: 500;
  }
`;

const Container = styled.div`
  margin: 15px -30px;
  border: solid 1px #dadada;
  border-radius: 4px;
`;

const StyledEditableContainer = styled(EditableContainer)`
  padding: 15px 30px;
`;

const ScheduledEmailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  padding: 0 20px;
  background-color: #f4fbff;
  font-family: ${props => props.fontFamily};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

const ActionsDropdown = styled(ShareDropdown)`
  padding-left: 0;
  margin-left: 0;
`;

const Subject = styled.div`
  padding-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
  font-weight: 500;
  color: #4a5665;
`;

const Edit = styled.div`
  font-size: 13px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 40px;
`;

const EmailSubjectField = styled(TextField)`
  margin-top: 21px;
  ${Error} {
    position: relative;
    margin-top: 3px;
  }
`;

const EmailScheduleField = styled(NumberField)`
  margin: 2px 0 8px 0;
  input {
    padding-bottom: 5px;
  }
`;

const ScheduledTimeHint = styled.div`
  height: 11px;
  margin-top: -4px;
  font-size: 11px;
  line-height: 1;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-bottom: 10px;
`;

const Tag = styled.div`
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 0 7px;
  background: #f1f9fc;
  border: 1px solid #daecf3;
  border-radius: 3px;
  line-height: 1.6;
  color: #616c79;
  cursor: pointer;
  &:hover {
    background: #daecf3;
  }
`;

const DeleteButton = styled(MinimalButton)`
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
`;

const CancelButton = styled(MinimalButton)`
  margin-top: 24px;
  margin-left: auto;
  font-size: 14px;
  font-weight: 400;
`;

const SaveButton = styled(MinimalButton)`
  margin-top: 24px;
  margin-left: -28px;
  font-size: 14px;
  font-weight: 400;
  color: #3aaadd;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D8D8D8FF' stroke-width='4' stroke-dasharray='4%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  &:last-of-type {
    display: none;
  }
`;

const TimeIcon = styled.i`
  margin-left: -3px;
  font-size: 14px;
`;

const VerticalLine = styled.span`
  width: 1px;
  margin: 0 15px;
  height: 15px;
  background-color: rgb(216, 216, 216);
`;

const AttachmentsViewTitle = styled.div`
  margin-top: 25px;
  color: ${props => props.theme.labelColor};
`;

const StyledAttachments = styled(SimpleAttachments)`
  margin-top: 15px;
  margin-bottom: 10px;
`;

class ScheduledEmailTemplate extends React.Component<
  {
    formId: string,
    eventEmailTemplate: ?ScheduledEmailTemplate_eventEmailTemplate,
    org: ScheduledEmailTemplate_org,
    isEventTemplate: boolean,
    previewFn: (content: string, contentFormat: 'html' | 'plain') => string,
    onFinish?: () => void,
    tags: $ReadOnlyArray<string>,
    startDate?: string,
    endDate?: ?string,
    tz: string,
  },
  {
    editing: boolean,
    emailSubject: string,
    emailTemplate: string,
    interval: ?number,
    intervalUnit: EventEmailTemplateIntervalUnit,
    baseDate: EventEmailTemplateBaseDate,
    direction: EventEmailTemplateDirection,
    attachments: $ReadOnlyArray<CircaFile>,
    errors: { [string]: ?string },
  },
> {
  state = {
    editing: false,
    emailSubject: this.props.eventEmailTemplate ? this.props.eventEmailTemplate.emailSubject : '',
    emailTemplate: this.props.eventEmailTemplate ? this.props.eventEmailTemplate.emailTemplate : '',
    interval: this.props.eventEmailTemplate ? this.props.eventEmailTemplate.interval : null,
    intervalUnit: this.props.eventEmailTemplate
      ? this.props.eventEmailTemplate.intervalUnit
      : 'minutes',
    baseDate: this.props.eventEmailTemplate ? this.props.eventEmailTemplate.baseDate : 'start_date',
    direction: this.props.eventEmailTemplate ? this.props.eventEmailTemplate.direction : 'minus',
    attachments: this.initialAttachments(),
    errors: {},
  };

  initialState = this.state;

  filtersRef = React.createRef();

  subjectInput: HTMLInputElement;

  input: HTMLInputElement;

  isNewScheduledEmailTempalte = () => !this.props.eventEmailTemplate;

  getBaseDateSelectedValue = () => {
    const { baseDate, direction } = this.state;
    const prefix = direction === 'minus' ? 'before' : 'after';
    return (
      BASE_DATE_LABELS.find(option => option.value === `${prefix}_${baseDate}`) ||
      BASE_DATE_LABELS[0]
    );
  };

  getScheduledTime = (): ?string => {
    const { startDate, endDate, tz } = this.props;
    const { interval, intervalUnit, baseDate, direction } = this.state;

    if (startDate == null || interval == null) return null;

    const baseTime = moment.tz(baseDate === 'start_date' ? startDate : endDate, tz);
    const scheduledTime = baseTime[direction === 'minus' ? 'subtract' : 'add'](
      interval,
      intervalUnit,
    );
    return formatDateTime(scheduledTime);
  };

  handleChangeSubject = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    this.setState(prevState => ({
      emailSubject: value,
      errors: {
        ...prevState.errors,
        emailSubject: !value.trim() && 'Email Subject is required',
      },
    }));
  };

  handleSelectSubjectTag = (e: SyntheticEvent<HTMLDivElement>, tag: string) => {
    e.preventDefault();

    if (!this.subjectInput) return;

    this.setState({ emailSubject: insertTextIntoTextField(this.subjectInput, `{{${tag}}}`) });
  };

  handlechangeEmailTemplate = (emailTemplate: string) => {
    const emailTemplateError =
      !replaceLoDash(emailTemplate, /<p>&nbsp;<\/p>/g, '').trim() && 'Email Body is required';

    this.setState(state => ({
      emailTemplate,
      errors: {
        ...state.errors,
        emailTemplate: emailTemplateError,
      },
    }));
  };

  handleChangeInterval = (e: SyntheticEvent<HTMLInputElement>, value: ?string | number) => {
    const parsedNumber = parseInt(value, 10);
    const interval = parsedNumber > 0 ? parsedNumber : null;

    this.setState(prevState => ({
      interval,
      errors: {
        ...prevState.errors,
        interval:
          interval == null &&
          (parsedNumber < 0 ? 'Number of... cannot be negative' : 'Number of... is required'),
      },
    }));
  };

  handleChangeIntervalUnit = (intervalUnit: ?EventEmailTemplateIntervalUnit) => {
    this.setState({ intervalUnit: intervalUnit || 'minutes' });
  };

  handleChangeBaseDate = (baseDate: ?string) => {
    if (!baseDate) return;

    const direction = baseDate.startsWith('before') ? 'minus' : 'plus';
    const newBaseDate = ((baseDate.split('_').slice(1).join('_'): any): EventEmailTemplateBaseDate);
    this.setState({ direction, baseDate: newBaseDate });
  };

  handleCancel = () => {
    this.setState(this.initialState);
    if (this.props.onFinish) {
      this.props.onFinish();
    }
  };

  handleRemove = () => {
    const { formId, eventEmailTemplate } = this.props;
    if (!eventEmailTemplate) return;

    removeEventEmailTemplate(formId, eventEmailTemplate.id);
  };

  validateFields = () => {
    const { interval, emailTemplate, emailSubject } = this.state;
    const emailTemplateError = !replaceLoDash(emailTemplate, /<p>&nbsp;<\/p>/g, '').trim()
      ? 'Email Body is required'
      : null;

    const errors = {
      interval: interval == null ? 'Number of... is required' : null,
      emailTemplate: emailTemplateError,
      emailSubject: !emailSubject.trim() ? 'Email Subject is required' : null,
    };

    this.setState({ errors });

    return Object.values(errors).filter(Boolean).length === 0;
  };

  handleAddAttachment = (file: CircaFile) => {
    this.setState(prevState => ({
      attachments: [...prevState.attachments, { ...file, id: `mock-${String(Math.random())}` }],
    }));
  };

  handleRemoveAttachment = (attachmentId: string) => {
    this.setState(prevState => ({
      attachments: prevState.attachments.filter(attachment => attachment.id !== attachmentId),
    }));
  };

  handleSave = () => {
    const customFilters = this.filtersRef.current ? this.filtersRef.current() : [];

    if (customFilters.filter(item => item.errors.field || item.errors.value).length > 0) {
      return;
    }

    const {
      emailSubject,
      emailTemplate,
      interval,
      intervalUnit,
      baseDate,
      direction,
      attachments,
    } = this.state;

    if (!this.validateFields() || interval == null || !emailSubject || !emailTemplate) {
      return;
    }

    const templateData = {
      emailSubject,
      emailTemplate,
      interval,
      intervalUnit,
      baseDate,
      direction,
    };

    const customFiltersForSave = customFilters.map(({ errors, ...rest }) => rest);
    const { formId, eventEmailTemplate, onFinish } = this.props;

    const savedAttachments = attachments.map(attachment => {
      return {
        ...(attachment.id && attachment.id.startsWith('mock-') ? {} : { id: attachment.id }),
        filename: attachment.filename,
        filetype: attachment.filetype,
        filesize: attachment.filesize,
        fileurl: attachment.fileurl,
      };
    });

    const initialAttachments = this.initialAttachments().map(attachment => ({
      id: attachment.id,
      filename: attachment.filename,
      filetype: attachment.filetype,
      filesize: attachment.filesize,
      fileurl: attachment.fileurl,
    }));

    const attachmentsChanged = !isEqual(savedAttachments, initialAttachments);

    if (this.isNewScheduledEmailTempalte()) {
      createEventEmailTemplate({
        formId,
        ...templateData,
        customFilters: customFiltersForSave,
        ...(attachmentsChanged ? { attachments: savedAttachments } : {}),
      }).then(() => {
        if (eventEmailTemplate) {
          this.setState({ attachments: this.initialAttachments() });
        }
      });

      if (onFinish) {
        onFinish();
      }
    } else {
      updateEventEmailTemplate({
        ...templateData,
        eventEmailTemplateId: eventEmailTemplate ? eventEmailTemplate.id : '',
        customFilters: customFiltersForSave,
        ...(attachmentsChanged ? { attachments: savedAttachments } : {}),
      }).then(() => {
        if (eventEmailTemplate) {
          this.setState({ attachments: this.initialAttachments() });
        }
      });
    }
    this.setState({ editing: false }, () => {
      this.initialState = this.state;
    });
  };

  handleEdit = () => {
    this.setState({ editing: true });
  };

  initialAttachments() {
    return this.props.eventEmailTemplate
      ? this.props.eventEmailTemplate.attachments.edges.map(({ node }) => {
          return ((node: any): CircaFile);
        })
      : [];
  }

  render() {
    const { org, eventEmailTemplate, isEventTemplate, tags, tz, previewFn } = this.props;
    const { emailSubject, emailTemplate, interval, intervalUnit, errors } = this.state;

    const baseDateSelected = this.getBaseDateSelectedValue();
    const scheduledTime = this.getScheduledTime();
    const formattedScheduledTime = scheduledTime == null ? '' : `: ${scheduledTime}`;

    const headerActionOptions = [
      { label: 'Edit', icon: 'pencil', onClick: this.handleEdit },
      { label: 'Delete', icon: 'trash', onClick: this.handleRemove },
    ];

    return (
      <>
        <Container>
          <UIContext.Consumer>
            {({ fontFamily }) => (
              <ScheduledEmailsHeader fontFamily={fontFamily}>
                <Subject>{previewFn(emailSubject, 'plain') || emailSubject}</Subject>
                {eventEmailTemplate && (
                  <HeaderActions>
                    <ScheduledEmailTemplateFilterTooltip
                      fontFamily={fontFamily}
                      eventEmailTemplate={eventEmailTemplate}
                      tz={tz}
                    />
                    <VerticalLine />
                    <ActionsDropdown noBorder hoverColor="#4db1dd" options={headerActionOptions} />
                  </HeaderActions>
                )}
              </ScheduledEmailsHeader>
            )}
          </UIContext.Consumer>
          <StyledEditableContainer
            noBorder
            ignoreClickOut
            onChangeEditing={(editing: boolean) => {
              this.setState({ editing });
            }}
            defaultPreviewing={!this.isNewScheduledEmailTempalte()}
            previewContent={
              <>
                <ScheduleTimeLabel>
                  <TimeIcon className="fa fa-fw fa-clock-o" />
                  <span>
                    {interval} {intervalUnit} {baseDateSelected.label.toLowerCase()}
                  </span>
                  {formattedScheduledTime}
                </ScheduleTimeLabel>
                <StyledContent
                  style={{ marginRight: 20 }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                      this.props.previewFn(emailTemplate, 'html') || emailTemplate,
                      {
                        extendedTags: ['img'],
                      },
                    ),
                  }}
                />
                {isEventTemplate && this.state.attachments.length > 0 && (
                  <>
                    <AttachmentsViewTitle>
                      <i className="fa fa-fw fa-paperclip" /> Attachments
                    </AttachmentsViewTitle>
                    <StyledAttachments
                      attachments={this.state.attachments}
                      onAdd={this.handleAddAttachment}
                      overlayContainer={document.body}
                      disabled
                    />
                  </>
                )}
              </>
            }
            editing={this.state.editing}
            editContent={
              <Edit>
                <Row>
                  <EmailScheduleField
                    autoFocus
                    defaultValue={interval}
                    label="Number of…"
                    onChange={this.handleChangeInterval}
                    onBlur={this.handleChangeInterval}
                    maxLength={3}
                    required
                    error={errors.interval}
                  />
                  <SelectField
                    value={intervalUnit}
                    options={INTERVAL_UNIT_OPTIONS}
                    onChange={this.handleChangeIntervalUnit}
                  />
                  <SelectField
                    value={baseDateSelected.value}
                    options={BASE_DATE_LABELS}
                    onChange={this.handleChangeBaseDate}
                  />
                </Row>
                <ScheduledTimeHint>{scheduledTime}</ScheduledTimeHint>
                <EmailSubjectField
                  label="Email Subject"
                  value={emailSubject}
                  required
                  multiline
                  error={errors.emailSubject}
                  onChange={this.handleChangeSubject}
                  onBlur={this.handleChangeSubject}
                  inputRef={subjectInput => {
                    this.subjectInput = subjectInput;
                  }}
                />
                <Tags>
                  {tags.map(tag => (
                    <Tag key={tag} onMouseDown={e => this.handleSelectSubjectTag(e, tag)}>
                      {tag}
                    </Tag>
                  ))}
                </Tags>
                <UIContext.Consumer>
                  {({ loaderColor, fontFamily }) => (
                    <StyledTinyRichText
                      label="Email Body"
                      error={errors.emailTemplate}
                      required
                      value={emailTemplate}
                      imageEnabled
                      withCustomToolbar
                      customToolbar={tags.map(tag => ({
                        key: tag.replace(/\s/g, ''),
                        tooltip: `Insert ${tag}`,
                        label: tag,
                      }))}
                      initOptions={{
                        content_style: `@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans');
                          .mce-content-body {
                                font-family: ${fontFamily || 'Roboto'};
                              }`,
                        min_height: 125,
                      }}
                      onChange={this.handlechangeEmailTemplate}
                      onSave={this.handlechangeEmailTemplate}
                      loaderColor={loaderColor}
                    />
                  )}
                </UIContext.Consumer>
                {isEventTemplate && (
                  <Row>
                    <StyledAttachments
                      attachments={this.state.attachments}
                      onAdd={this.handleAddAttachment}
                      onRemove={this.handleRemoveAttachment}
                      overlayContainer={document.body}
                      hideLink
                    />
                  </Row>
                )}
                <ScheduledEmailFilters
                  filtersRef={this.filtersRef}
                  org={org}
                  eventEmailTemplate={eventEmailTemplate}
                  tz={tz}
                />

                <Row>
                  {!this.isNewScheduledEmailTempalte() && (
                    <DeleteButton
                      label={
                        <>
                          <i className="fa fa-fw fa-trash" /> Delete
                        </>
                      }
                      onClick={this.handleRemove}
                    />
                  )}
                  <CancelButton label="Cancel" onClick={this.handleCancel} />
                  <SaveButton label="Save" onClick={this.handleSave} />
                </Row>
              </Edit>
            }
            onSave={() => false}
          />
        </Container>

        <Line />
      </>
    );
  }
}

export default createFragmentContainer(ScheduledEmailTemplate, {
  eventEmailTemplate: graphql`
    fragment ScheduledEmailTemplate_eventEmailTemplate on EventEmailTemplate {
      id
      baseDate
      direction
      interval
      intervalUnit
      emailSubject
      emailTemplate
      attachments {
        edges {
          node {
            id
            fileurl
            filetype
            filesize
            filename
            ...AttachmentPreview_attachments
          }
        }
      }
      ...ScheduledEmailFilters_eventEmailTemplate
      ...ScheduledEmailTemplateFilterTooltip_eventEmailTemplate
    }
  `,
  org: graphql`
    fragment ScheduledEmailTemplate_org on Org {
      ...ScheduledEmailFilters_org
    }
  `,
});
