/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorsTablePagination_totalCountOrg$ref: FragmentReference;
export type VendorsTablePagination_totalCountOrg = {|
  +vendorsCount: {|
    +totalCount: number
  |},
  +$refType: VendorsTablePagination_totalCountOrg$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VendorsTablePagination_totalCountOrg",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filters",
      "type": "VendorFilters"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "vendorsCount",
      "name": "vendors",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters",
          "type": "VendorFilters"
        }
      ],
      "concreteType": "VendorRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b47e209bdd4b64db2875772b366b0838';
module.exports = node;
