/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import NumberField from './components/NumberField';

import type { BudgetActual_event } from './__generated__/BudgetActual_event.graphql';
import type { BudgetActual_org } from './__generated__/BudgetActual_org.graphql';

const BudgetActual = (props: { event: BudgetActual_event, org: BudgetActual_org }) => {
  const { event, org } = props;

  if (!event.viewerCanViewBudget) return <i>Restricted</i>;

  const currency = org.settings.currency;
  const totalActualAmount = event.totalActualAmount;

  return totalActualAmount !== null ? (
    <NumberField value={formatCost(totalActualAmount, currency)} formatted />
  ) : null;
};

export default createFragmentContainer(BudgetActual, {
  event: graphql`
    fragment BudgetActual_event on Event {
      viewerCanViewBudget
      totalActualAmount
    }
  `,
  org: graphql`
    fragment BudgetActual_org on Org {
      settings {
        currency
      }
    }
  `,
});
