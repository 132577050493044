/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactMembershipSyncStatus_eventContact$ref: FragmentReference;
export type ContactMembershipSyncStatus_eventContact = {|
  +syncStatus: {|
    +state: SyncState,
    +errorMessage: string,
  |},
  +node: {|
    +syncStatus: {|
      +state: SyncState
    |}
  |},
  +$refType: ContactMembershipSyncStatus_eventContact$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ContactMembershipSyncStatus_eventContact",
  "type": "EventContactEdge",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "syncStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "errorMessage",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Contact",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "syncStatus",
          "storageKey": null,
          "args": null,
          "concreteType": "SyncStatus",
          "plural": false,
          "selections": [
            v0
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3cbfa2d623a9dfbd288d0b9127b6b137';
module.exports = node;
