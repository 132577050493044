/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

const mutation = graphql`
  mutation addCompanyToBriefMutation($input: AddCompanyToBriefInput!) {
    addCompanyToBrief(input: $input) {
      companyEdge {
        __typename
        node {
          id
        }
      }
    }
  }
`;

export default function addCompanyToBrief(companyId: string, eventId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        companyId,
        eventId,
      },
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'companyEdge',
      connections: [{ field: 'briefCompanies' }],
    }),
  });
}
