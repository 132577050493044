/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { SubmissionStatus } from 'components/EventRequestForm/lib/submissionStatuses';
import TextField from 'components/material/TextField';

import SubmissionReviewDeclineReasons from './SubmissionReviewDeclineReasons';
import SubmissionReviewStatusButton from './SubmissionReviewStatusButton';

import type { SubmissionReviewStatus_submission } from './__generated__/SubmissionReviewStatus_submission.graphql';

const Container = styled.div`
  padding: 15px 15px 40px;
`;

const ReviewerNotes = styled.div`
  margin-top: 15px;
`;

class SubmissionReviewStatus extends React.PureComponent<{
  submission: SubmissionReviewStatus_submission,
  status: SubmissionStatus,
  declineReasonId: ?string,
  reviewerNotes: string,
  onChangeStatus: (status: SubmissionStatus) => void,
  onChangeDeclineReasonId: (declineReasonId: string) => void,
  onChangeReviewerNotes: (requesterNotes: string) => void,
}> {
  handleChangeRequesterNotes = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeReviewerNotes(e.currentTarget.value);
  };

  render() {
    return (
      <Container>
        <SubmissionReviewStatusButton
          disabled={['APPROVED'].includes(this.props.submission.status)}
          status={this.props.status}
          initialStatus={this.props.submission.status}
          onChangeStatus={this.props.onChangeStatus}
        />

        {this.props.status === 'DECLINED' && (
          <SubmissionReviewDeclineReasons
            submission={this.props.submission}
            declineReasonId={this.props.declineReasonId}
            onChangeDeclineReasonId={this.props.onChangeDeclineReasonId}
          />
        )}

        {this.props.status !== 'ARCHIVED' && this.props.status !== this.props.submission.status && (
          <ReviewerNotes>
            <TextField
              label="Note for the submitter"
              multiline
              value={this.props.reviewerNotes}
              onChange={this.handleChangeRequesterNotes}
              autoFocus={this.props.status !== 'DECLINED'}
              key={this.props.status}
            />
          </ReviewerNotes>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  SubmissionReviewStatus,
  graphql`
    fragment SubmissionReviewStatus_submission on EventRequestSubmission {
      status
      ...SubmissionReviewDeclineReasons_submission
    }
  `,
);
