/* @flow */
import React from 'react';
import ClickOut from 'react-onclickout';
import styled, { css } from 'styled-components';

import AutocompleteInput from 'components/material/AutocompleteInput';

import FilterItemHeader from '../FilterItemHeader';

const FilterBody = styled.div`
  width: 100%;
  margin-left: 10px;
`;

const Option = styled.div`
  padding: 5px 10px;
  color: ${props => props.theme.rowPrimaryTextColor};
  font-size: 13px;
  ${props =>
    props.custom &&
    css`
      font-weight: 500;
    `};
`;

const ActiveValues = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ActiveTag = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 0 1px 8px;
  margin: 5px 5px 0 0;
  background: ${props => props.theme.primaryRowColor};
  color: ${props => props.theme.rowPrimaryTextColor};
  border-radius: 2px;
  font-size: 13px;
`;

const RemoveIcon = styled.div`
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 10px;
  color: ${props => props.theme.mutedTextColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.negativeActionColor};
  }
`;

export default class AutocompleteFilterItem extends React.PureComponent<
  {
    label: string,
    name: string,
    placeholder?: string,
    error?: ?string,
    onChange: (string, $ReadOnlyArray<string>) => void,
    activeValues: ?$ReadOnlyArray<string>,
    // if option is an array only the first value in array is shown but the other ones are used for suggestion
    options: $ReadOnlyArray<string | $ReadOnlyArray<string>>,
    skipHeader?: boolean,
    allowCustomValue?: boolean,
    singleOption?: boolean,
    autoFocus?: boolean,
    renderOptionString?: ?(option: any) => string,
    className?: string,
  },
  { defaultQuery: string },
> {
  state = {
    defaultQuery: '',
  };

  handleFilterClear = () => {
    this.props.onChange(this.props.name, []);
  };

  handleFilterOptions = (query: string) => {
    const suggestions = this.props.options
      .filter(option =>
        (Array.isArray(option) ? option : [option]).some(o =>
          o.toLowerCase().replace(/\s/g, '').startsWith(query.toLowerCase().replace(/\s/g, '')),
        ),
      )
      .map(o => (Array.isArray(o) ? o[0] : o))
      .filter(option =>
        this.props.activeValues ? !this.props.activeValues.includes(option) : true,
      )
      .slice(0, 3);

    if (this.props.allowCustomValue && !this.optionExists(query)) {
      return [query, ...suggestions];
    }
    return suggestions;
  };

  handleFilter = (option: ?string) => {
    if (option) {
      if (this.props.singleOption) {
        this.props.onChange(this.props.name, [option]);
      } else {
        this.props.onChange(this.props.name, [...(this.props.activeValues || []), option]);
      }
    }
  };

  handleAutocompleteBlur = (query: string) => {
    this.setState({ defaultQuery: query });
  };

  handleRemoveFilter = (option: string) => {
    if (this.props.activeValues) {
      this.props.onChange(
        this.props.name,
        this.props.activeValues.filter(value => value !== option),
      );
    }
  };

  handleOutClick = () => {
    const query = this.state.defaultQuery;
    if (
      this.props.singleOption &&
      query &&
      (!this.props.activeValues || query !== this.props.activeValues[0])
    ) {
      this.handleFilter(query);
    }
  };

  optionExists(query: string) {
    return this.props.options.some(o =>
      (Array.isArray(o) ? o : [o]).some(v => v.toLowerCase() === query.toLowerCase()),
    );
  }

  renderOption = (option: string) => {
    return <Option custom={!this.optionExists(option)}>{option}</Option>;
  };

  renderBody = () => {
    const {
      singleOption,
      activeValues,
      placeholder,
      error,
      autoFocus,
      renderOptionString,
      className,
    } = this.props;

    return (
      <ClickOut onClickOut={this.handleOutClick}>
        <FilterBody className={className}>
          <AutocompleteInput
            defaultQuery={
              singleOption && activeValues && activeValues.length > 0 ? activeValues[0] : undefined
            }
            onFilterOptions={this.handleFilterOptions}
            renderOption={this.renderOption}
            renderOptionString={renderOptionString}
            onSelect={this.handleFilter}
            onBlur={this.handleAutocompleteBlur}
            placeholder={placeholder || 'Search...'}
            autoFocus={autoFocus}
            error={error}
          />
          {!singleOption && activeValues && (
            <ActiveValues>
              {activeValues.map(value => (
                <ActiveTag key={value}>
                  {value}
                  <RemoveIcon onClick={() => this.handleRemoveFilter(value)}>
                    <i className="fa fa-times " />
                  </RemoveIcon>
                </ActiveTag>
              ))}
            </ActiveValues>
          )}
        </FilterBody>
      </ClickOut>
    );
  };

  render() {
    if (this.props.skipHeader) {
      return this.renderBody();
    }

    return (
      <FilterItemHeader
        label={this.props.label}
        count={this.props.activeValues ? this.props.activeValues.length : 0}
        onClear={this.handleFilterClear}
      >
        {this.renderBody()}
      </FilterItemHeader>
    );
  }
}
