/**
 * @flow
 * @relayHash 2d91506ba29e0455a0aeaca212275090
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveCampaignFromEventInput = {
  clientMutationId?: ?string,
  eventId: string,
};
export type removeCampaignFromEventMutationVariables = {|
  input: RemoveCampaignFromEventInput
|};
export type removeCampaignFromEventMutationResponse = {|
  +removeCampaignFromEvent: ?{|
    +event: {|
      +salesforceCampaign: ?{|
        +id: string
      |},
      +syncedContacts: {|
        +totalCount: number
      |},
      +disabledContacts: {|
        +totalCount: number
      |},
      +failedContacts: {|
        +totalCount: number
      |},
      +pendingContacts: {|
        +totalCount: number
      |},
    |}
  |}
|};
export type removeCampaignFromEventMutation = {|
  variables: removeCampaignFromEventMutationVariables,
  response: removeCampaignFromEventMutationResponse,
|};
*/


/*
mutation removeCampaignFromEventMutation(
  $input: RemoveCampaignFromEventInput!
) {
  removeCampaignFromEvent(input: $input) {
    event {
      salesforceCampaign {
        id
      }
      syncedContacts: eventContacts(filters: {membershipSyncStatuses: [OK]}) {
        totalCount
      }
      disabledContacts: eventContacts(filters: {membershipSyncStatuses: [DISABLED]}) {
        totalCount
      }
      failedContacts: eventContacts(filters: {membershipSyncStatuses: [FAILING]}) {
        totalCount
      }
      pendingContacts: eventContacts(filters: {membershipSyncStatuses: [PENDING]}) {
        totalCount
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveCampaignFromEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveCampaignFromEventInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "salesforceCampaign",
  "storageKey": null,
  "args": null,
  "concreteType": "SalesforceCampaign",
  "plural": false,
  "selections": [
    v2
  ]
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": "syncedContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"OK\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "OK"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v4
},
v6 = {
  "kind": "LinkedField",
  "alias": "disabledContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"DISABLED\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "DISABLED"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v4
},
v7 = {
  "kind": "LinkedField",
  "alias": "failedContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"FAILING\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "FAILING"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v4
},
v8 = {
  "kind": "LinkedField",
  "alias": "pendingContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"PENDING\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "PENDING"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v4
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeCampaignFromEventMutation",
  "id": null,
  "text": "mutation removeCampaignFromEventMutation(\n  $input: RemoveCampaignFromEventInput!\n) {\n  removeCampaignFromEvent(input: $input) {\n    event {\n      salesforceCampaign {\n        id\n      }\n      syncedContacts: eventContacts(filters: {membershipSyncStatuses: [OK]}) {\n        totalCount\n      }\n      disabledContacts: eventContacts(filters: {membershipSyncStatuses: [DISABLED]}) {\n        totalCount\n      }\n      failedContacts: eventContacts(filters: {membershipSyncStatuses: [FAILING]}) {\n        totalCount\n      }\n      pendingContacts: eventContacts(filters: {membershipSyncStatuses: [PENDING]}) {\n        totalCount\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeCampaignFromEventMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeCampaignFromEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveCampaignFromEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v5,
              v6,
              v7,
              v8
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeCampaignFromEventMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeCampaignFromEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveCampaignFromEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v5,
              v6,
              v7,
              v8,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c27542b3a1ed956f3637fb9125248a3d';
module.exports = node;
