/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import { NoSearchResultsMessage } from './components';
import EventNotesSearchPagination from './EventNotesSearchPagination';
import type { ContentComponentProps } from './SearchOverlay';

import type { NotesSearchContentQueryResponse } from './__generated__/NotesSearchContentQuery.graphql';

const searchQuery = graphql`
  query NotesSearchContentQuery($search: Search!, $count: Int!, $cursor: String) {
    notesTotalCount: searchEventNotes(search: $search) {
      totalCount
    }
    ...EventNotesSearchPagination_searchResults
  }
`;

export default class NotesSearchContent extends React.PureComponent<ContentComponentProps> {
  render() {
    const { query, exactMatch } = this.props;

    const variables = {
      search: {
        search: query,
        exactMatch,
      },
      count: 10,
    };

    return (
      <DefaultQueryRenderer
        query={searchQuery}
        variables={variables}
        renderSuccess={(response: ?NotesSearchContentQueryResponse) => {
          if (response == null) {
            return null;
          }

          if (response.notesTotalCount.totalCount === 0) {
            return <NoSearchResultsMessage query={query} />;
          }

          return (
            <EventNotesSearchPagination
              mobile
              query={query}
              searchResults={response}
              totalCount={response.notesTotalCount.totalCount}
              isolated
            />
          );
        }}
      />
    );
  }
}
