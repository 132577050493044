/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Overlay from 'components/material/Overlay';

export const StyledOverlay = styled(Overlay)`
  width: 332px;

  @media (max-width: 460px) {
    position: fixed !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: none !important;
    width: 100% !important;
    height: 100%;
    z-index: 999;
  }
`;

export default class FiltersOverlay extends React.PureComponent<{
  show: boolean,
  target: ?React$ElementRef<React$ElementType>,
  container: ?React$ElementRef<React$ElementType>,
  onHide: () => void,
  forceRightEdge?: boolean,
  children: React.Node,
  className?: string,
}> {
  render() {
    return (
      <StyledOverlay
        className={this.props.className}
        show={this.props.show}
        target={this.props.target}
        onHide={this.props.onHide}
        container={this.props.container}
        forceRightEdge={this.props.forceRightEdge}
      >
        {this.props.children}
      </StyledOverlay>
    );
  }
}
