/* @flow */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';

import Button from 'components/material/Button';
import Overlay from 'components/material/Overlay';

import Calendar from '../Calendar';
import TimePicker from '../TimePicker';
import { type DateConfig } from './index';

const StyledOverlay = styled(Overlay)`
  width: 260px;
  margin-top: 3px;
`;

const OverlayFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid #efefef;
  font-size: 13px;
`;

const OverlayFooterButtons = styled.div`
  flex: 1 1 auto;
  text-align: right;
`;

const ClearLink = styled.a`
  color: #f25d60;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #d84548;
  }
`;

const TimePickerRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 3px 0 10px;
  font-size: 13px;
`;

const TimePickerContainer = styled.div`
  width: 77px;
  margin: -8px 0;
`;

const OverlayBorder = styled.div`
  height: 1px;
  background: #efefef;
`;

const DateLabel = styled.div`
  margin-right: 10px;
  font-weight: 500;
  color: #3a4c54;
`;

const OverlayFocus = styled.div`
  outline: none;
`;

export default class DateTimeOverlay extends React.PureComponent<{
  container: ?React.Component<any> | HTMLElement,
  target: ?React.Component<any>,
  show: boolean,
  onHide: () => void,
  ...DateConfig,
  tz: string,
  onChange: (dateConfig: DateConfig) => void,
  dateOnly?: boolean,
  disableHideOnClickOut?: boolean,
  overlayRef?: $Call<React.createRef<any>>,
  forceRightEdge?: boolean,
  clearable?: ?boolean,
}> {
  handleChangeDate = (date: string) => {
    this.props.onChange({ date, hideTime: this.props.hideTime });
  };

  handleChangeTime = (time: ?string) => {
    this.props.onChange({ date: time || this.props.date, hideTime: !time });
  };

  handleRemoveDate = () => {
    this.props.onChange({ date: null, hideTime: true });

    this.props.onHide();
  };

  handleClickApply = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    this.props.onHide();
  };

  render() {
    return (
      <StyledOverlay
        container={this.props.container}
        target={this.props.target}
        show={this.props.show}
        onHide={this.props.disableHideOnClickOut ? null : this.props.onHide}
        overlayRef={this.props.overlayRef}
        forceRightEdge={this.props.forceRightEdge}
      >
        <OverlayFocus tabIndex={-1}>
          <Calendar tz={this.props.tz} date={this.props.date} onChange={this.handleChangeDate} />

          {this.props.date && !this.props.dateOnly && (
            <React.Fragment>
              <OverlayBorder />
              <TimePickerRow>
                <DateLabel>{formatDate(moment(this.props.date).tz(this.props.tz))}</DateLabel>

                <TimePickerContainer>
                  <TimePicker
                    tz={this.props.tz}
                    date={this.props.date || ''}
                    time={this.props.hideTime ? null : this.props.date}
                    onChange={this.handleChangeTime}
                  />
                </TimePickerContainer>
              </TimePickerRow>
            </React.Fragment>
          )}

          <OverlayFooter>
            {this.props.date && this.props.clearable && (
              <ClearLink onClick={this.handleRemoveDate}>Remove</ClearLink>
            )}

            <OverlayFooterButtons>
              <Button label="Apply" primary onClick={this.handleClickApply} />
            </OverlayFooterButtons>
          </OverlayFooter>
        </OverlayFocus>
      </StyledOverlay>
    );
  }
}
