/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import { eventInfoFieldLabels, eventInfoSectionLabels } from 'config/brief/eventInfoLabels';

import reorderBriefTemplateEventInfoFields from 'graph/mutations/briefTemplate/reorderBriefTemplateEventInfoFields';
import updateBriefTemplateEventInfoField from 'graph/mutations/briefTemplate/updateBriefTemplateEventInfoField';
import updateBriefTemplateEventInfoSection from 'graph/mutations/briefTemplate/updateBriefTemplateEventInfoSection';
import showModernMutationError from 'graph/utils/showModernMutationError';

import CheckBox from 'components/material/CheckBox';

import DraggableSection from '../DraggableSection';
import { type FieldType, type SectionType } from './index';

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding-left: 25px;
`;

const Title = styled.div`
  flex: 1 1 auto;
`;

const Icon = styled.i`
  font-size: 18px;
  font-weight: 500;
  color: #96a2ab;
`;

const StyledCheckBox = styled(CheckBox)`
  margin-right: 10px;
  > div:first-child {
    border-radius: 2px;
  }
`;

const SectionContent = styled.div`
  > div {
    max-width: 560px;
    margin-left: 35px;
  }
`;

const salesforceFields = [
  'SALESFORCE_ID',
  'LAST_SYNCED',
  'SYNC_STATUS',
  'NUMBER_OF_OPPORTUNITIES',
  'AMOUNT_ALL_OPPORTUNITIES',
];
const marketoFields = ['MARKETO_ID'];

class InfoSection extends React.PureComponent<
  {
    section: SectionType,
    expanded: boolean,
    onToggleExpanded: (sectionId: string, expanded: boolean) => void,
    salesforceEnabled: boolean,
    marketoEnabled: boolean,
  },
  {
    fieldsOrderOverride: ?$ReadOnlyArray<FieldType>,
  },
> {
  state = {
    fieldsOrderOverride: null,
  };

  getSortedFields = (): $ReadOnlyArray<FieldType> =>
    sortBy(
      this.state.fieldsOrderOverride || this.props.section.fields,
      'order',
    ).map((field, index) => ({ ...field, order: index + 1 }));

  getSortedFieldsInput = (fields: $ReadOnlyArray<FieldType>) => {
    let order = 0;
    const sortedFieldOrders = this.props.section.fields.map(field => field.order);
    return sortBy(fields, 'order').map(section => {
      order += 1;
      while (!sortedFieldOrders.includes(order)) {
        order += 1;
        if (Math.max(...sortedFieldOrders) < order) {
          break;
        }
      }
      return {
        id: section.id,
        order,
      };
    });
  };

  handleToggleSectionEnabled = (enabled: boolean) => {
    updateBriefTemplateEventInfoSection({
      eventInfoSectionId: this.props.section.id,
      enabled,
    }).catch(showModernMutationError);
    if (enabled === false && this.props.expanded) {
      this.props.onToggleExpanded(this.props.section.id, false);
    }
  };

  handleToggleExpanded = () => {
    if (this.props.section.enabled) {
      this.props.onToggleExpanded(this.props.section.id, !this.props.expanded);
    }
  };

  handleToggleFieldEnabled = (eventInfoFieldId: string, enabled: boolean) => {
    updateBriefTemplateEventInfoField({ eventInfoFieldId, enabled }).catch(showModernMutationError);
  };

  handleMoveField = (sourceOrder: number, targetOrder: number) => {
    const sortedFields = this.getSortedFields();
    const sourceIndex = sortedFields.findIndex(field => field.order === sourceOrder);
    const targetIndex = sortedFields.findIndex(field => field.order === targetOrder);
    const direction = sourceIndex > targetIndex ? 0 : 1;
    const source = sortedFields[sourceIndex];

    const splitIndex = targetIndex + direction;
    const before = sortedFields.slice(0, splitIndex).filter(field => field.order !== sourceOrder);
    const after = sortedFields.slice(splitIndex).filter(field => field.order !== sourceOrder);

    this.setState({
      fieldsOrderOverride: [...before, source, ...after].map((field, index) => ({
        ...field,
        order: index,
      })),
    });
  };

  handleMoveFieldEnd = () => {
    if (this.state.fieldsOrderOverride) {
      reorderBriefTemplateEventInfoFields(
        this.getSortedFieldsInput(this.state.fieldsOrderOverride),
      ).catch(showModernMutationError);
    }
    this.setState({ fieldsOrderOverride: null });
  };

  render() {
    const { section, salesforceEnabled, marketoEnabled } = this.props;

    return (
      <React.Fragment>
        <Header>
          <Icon
            onClick={this.handleToggleExpanded}
            className={`fa fa-fw fa-angle-${this.props.expanded ? 'down' : 'right'}`}
          />
          <StyledCheckBox
            checked={section.enabled}
            onChange={this.handleToggleSectionEnabled}
            disabled={section.fields.length === 0}
          />
          <Title onClick={this.handleToggleExpanded} empty={section.fields.length === 0}>
            {this.props.section.customFieldSection
              ? this.props.section.customFieldSection.name
              : this.props.section.sectionName &&
                eventInfoSectionLabels[this.props.section.sectionName]}
          </Title>
        </Header>
        {this.props.expanded && (
          <SectionContent>
            {this.getSortedFields().map(
              field =>
                ((!salesforceEnabled && !salesforceFields.includes(field.fieldName)) ||
                  (!marketoEnabled && !marketoFields.includes(field.fieldName))) && (
                  <DraggableSection
                    section={field}
                    draggableGroup={this.props.section.id}
                    onMove={this.handleMoveField}
                    onMoveEnd={this.handleMoveFieldEnd}
                    key={field.id}
                  >
                    <Header>
                      <StyledCheckBox
                        checked={field.enabled}
                        onChange={(enabled: boolean) =>
                          this.handleToggleFieldEnabled(field.id, enabled)
                        }
                      />
                      <Title>
                        {field.customField
                          ? field.customField.label
                          : field.fieldName && eventInfoFieldLabels[field.fieldName]}
                      </Title>
                    </Header>
                  </DraggableSection>
                ),
            )}
          </SectionContent>
        )}
      </React.Fragment>
    );
  }
}

export default InfoSection;
