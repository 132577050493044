/* @flow */
import * as React from 'react';
import {
  type ConnectDragPreview,
  type ConnectDragSource,
  type ConnectDropTarget,
  type DragSourceConnector,
  type DragSourceMonitor,
  type DropTargetConnector,
  type DropTargetMonitor,
  DragSource,
  DropTarget,
} from 'react-dnd';
import styled, { css } from 'styled-components';

import DragIcon from 'images/drag.svg';

import { type Field } from './types';

const StyledDragIcon = styled(DragIcon)`
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 1;
  width: 8px;
  height: 18px;
  cursor: grab;
  color: #868f96;
`;

const Container = styled.div`
  position: relative;
  ${props =>
    props.dragging &&
    css`
      background: ${props.dragging ? 'rgba(151, 151, 151, 0.1)' : 'transparent'};
      box-shadow: 0 0 5px inset rgba(0, 0, 0, 0.1);
      > * {
        visibility: hidden;
      }
    `};
`;

class DraggableField extends React.PureComponent<{
  children: React.Node,
  connectDragSource?: ConnectDragSource,
  connectDragPreview?: ConnectDragPreview,
  connectDropTarget?: ConnectDropTarget,
  isDragging?: boolean,
  /* eslint-disable react/no-unused-prop-types */
  // Used in Hover handler
  field: Field,
  onMove: (sourceOrder: Field, targetOrder: Field) => void,
  draggingField: Field,
  onMoveEnd: () => void,
}> {
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      isDragging,
      children,
      draggingField,
      field,
    } = this.props;

    if (!connectDragSource || !connectDropTarget || !connectDragPreview) return null;

    return connectDropTarget(
      <div>
        {connectDragPreview(
          <div>
            <Container dragging={isDragging || (draggingField && draggingField.id === field.id)}>
              {children}
              {connectDragSource(
                <div>
                  <StyledDragIcon />
                </div>,
              )}
            </Container>
          </div>,
        )}
      </div>,
    );
  }
}

const ConnectedDraggableComponent = DragSource(
  'CUSTOM_FIELD',
  {
    beginDrag(props: $PropertyType<DraggableField, 'props'>) {
      return props.field;
    },
    endDrag(props: $PropertyType<DraggableField, 'props'>) {
      props.onMoveEnd();
    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
)(DraggableField);

const ConnectedDroppableComponent = DropTarget(
  'CUSTOM_FIELD',
  {
    hover(props: $PropertyType<DraggableField, 'props'>, monitor: DropTargetMonitor) {
      const dragItem = monitor.getItem();

      if (this.lastHoverFieldId === props.field.id) {
        return;
      }
      this.lastHoverFieldId = props.field.id;
      if (
        dragItem.id === props.field.id ||
        ((dragItem.kind === 'DEFAULT' || dragItem.isSuggested) &&
          dragItem.section != null &&
          props.field.section != null &&
          dragItem.section.id !== props.field.section.id)
      ) {
        return;
      }

      props.onMove(dragItem, props.field);
      dragItem.order = props.field.order;

      if (props.field.section && dragItem.section) {
        if (props.field.section.id !== dragItem.section.id) {
          // 50 is added just in case when fields are deleted between last 2 fields in section
          dragItem.order = props.field.order + 50;
        }
        dragItem.section = { id: props.field.section.id };
      }
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(ConnectedDraggableComponent);

export default ConnectedDroppableComponent;
