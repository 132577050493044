/* @flow */
import partition from 'lodash/partition';
import moment from 'moment-timezone';

type Event = $Subtype<{
  +startDate: ?any,
  +endDate: ?any,
}>;

export default function eventPartition(
  events: Array<Event>,
): { upcoming: $ReadOnlyArray<Event>, past: $ReadOnlyArray<Event> } {
  const twoDaysAgo = moment().subtract(2, 'day');
  const [upcoming, past] = partition(
    events,
    event =>
      !event.startDate ||
      (event.startDate && moment(event.startDate).isSameOrAfter(twoDaysAgo)) ||
      (event.endDate && moment(event.endDate).isSameOrAfter(twoDaysAgo)),
  );

  return {
    upcoming,
    past,
  };
}
