/* @flow */
import type { Location } from 'react-router-dom';

import updateExpense, {
  type Expense as MutationExpense,
} from 'graph/mutations/expense/updateExpense';

import EventName from './EventName';
import VendorExpenseAction from './VendorExpenseAction';
import VendorExpenseActual from './VendorExpenseAmount';
import VendorExpenseCategory from './VendorExpenseCategory';
import VendorExpenseName from './VendorExpenseName';
import VendorExpenseNote from './VendorExpenseNote';
import VendorExpensePaymentStatus from './VendorExpensePaymentStatus';

export type VendorExpenseCellPropsType<Expense: {}, Org: {} = {}> = {|
  org: Org,
  expense: Expense,
  vendorId: string,
  updateExpense: MutationExpense => $Call<typeof updateExpense, string, MutationExpense>,
  location: Location,
|};

const columns = [
  {
    title: 'Expense',
    sortKey: 'NAME',
    component: VendorExpenseName,
    grow: 1,
  },
  {
    title: 'Event Name',
    sortKey: 'EVENT_NAME',
    component: EventName,
  },
  {
    title: 'Category',
    sortKey: 'BUDGET_CATEGORY',
    component: VendorExpenseCategory,
  },
  {
    title: 'Amount',
    sortKey: 'ACTUAL_AMOUNT',
    component: VendorExpenseActual,
    align: 'right',
  },
  {
    title: 'Payment Status',
    sortKey: 'PAYMENT_STATUS',
    component: VendorExpensePaymentStatus,
  },
  {
    title: 'Note',
    sortKey: 'NOTE',
    component: VendorExpenseNote,
  },
  {
    title: '',
    component: VendorExpenseAction,
    type: 'action',
  },
];

export default columns;
