/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-left: 10px;
  @media (${props => props.theme.mobileOnly}) {
    display: none;
  }
`;

const Item = styled.div`
  color: ${props => props.theme.secondaryActionColor};
  font-size: 16px;
  margin-left: 15px;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
  ${props =>
    props.disabled &&
    css`
      visibility: hidden;
    `};
`;

export const styledComponent = Container;

export default class TaskRowActions extends React.PureComponent<{
  onRemove: () => void,
  disabledRemove?: boolean,
}> {
  handleRemove = (e: SyntheticEvent<>) => {
    if (this.props.disabledRemove) return;
    e.stopPropagation();
    e.preventDefault();
    this.props.onRemove();
  };

  render() {
    return (
      <Container>
        <Item onClick={this.handleRemove} disabled={this.props.disabledRemove}>
          <i className="fa fa-fw fa-trash" />
        </Item>
      </Container>
    );
  }
}
