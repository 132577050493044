/**
 * @flow
 * @relayHash 84b52b450cdadeebc830f924a846829f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddVendorToBriefInput = {
  clientMutationId?: ?string,
  eventId: string,
  vendorId: string,
};
export type addVendorToBriefMutationVariables = {|
  input: AddVendorToBriefInput
|};
export type addVendorToBriefMutationResponse = {|
  +addVendorToBrief: ?{|
    +vendorEdge: {|
      +__typename: string,
      +node: {|
        +id: string
      |},
    |}
  |}
|};
export type addVendorToBriefMutation = {|
  variables: addVendorToBriefMutationVariables,
  response: addVendorToBriefMutationResponse,
|};
*/


/*
mutation addVendorToBriefMutation(
  $input: AddVendorToBriefInput!
) {
  addVendorToBrief(input: $input) {
    vendorEdge {
      __typename
      node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddVendorToBriefInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addVendorToBrief",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddVendorToBriefInput!"
      }
    ],
    "concreteType": "AddVendorToBriefPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vendorEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "VendorRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Vendor",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addVendorToBriefMutation",
  "id": null,
  "text": "mutation addVendorToBriefMutation(\n  $input: AddVendorToBriefInput!\n) {\n  addVendorToBrief(input: $input) {\n    vendorEdge {\n      __typename\n      node {\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addVendorToBriefMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "addVendorToBriefMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '22c671d74fccaed3149513b1d887fa71';
module.exports = node;
