/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import { type FormSubmissionFn } from 'components/ContactForm/ContactFormSubmission';
import type { RegistrationFormDefaultValues } from 'components/ContactForm/lib/types';

import generatePaddingProps from '../../RegistrationCreate/RegistrationPageContent/generatePaddingProps';
import SharedColumnComponent from './SharedColumnComponent';

import { type SharedRowComponent_componentProps } from './__generated__/SharedRowComponent_componentProps.graphql';
import { type SharedRowComponent_org } from './__generated__/SharedRowComponent_org.graphql';
import { type SharedRowComponent_registrationForm } from './__generated__/SharedRowComponent_registrationForm.graphql';

const Container = styled.div`
  display: flex;
  padding: ${props => generatePaddingProps(props.componentProps.padding)};
  background-color: ${props => props.componentProps.backgroundColor || 'initial'};
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const LeftColumnComponent = styled(SharedColumnComponent)`
  margin-right: ${props => props.rightMargin}px;
`;

class SharedRowComponent extends React.PureComponent<{
  org: SharedRowComponent_org,
  registrationForm: SharedRowComponent_registrationForm,
  componentProps: SharedRowComponent_componentProps,
  onSubmit: FormSubmissionFn,
  onFinishSubmit: () => void,
  viewMode?: boolean,
  preview?: boolean,
  email?: string,
  lockEmail?: boolean,
  defaultFormValues: RegistrationFormDefaultValues,
  location: Location,
}> {
  render() {
    const {
      org,
      registrationForm,
      onSubmit,
      onFinishSubmit,
      componentProps: { rowComponent, childPageComponents },
      viewMode,
      preview,
      email,
      lockEmail,
      defaultFormValues,
      location,
    } = this.props;
    if (!rowComponent || !childPageComponents) {
      return null;
    }
    const columnPageComponentNodes = sortBy(
      childPageComponents.edges
        .map(({ node }) => node)
        .filter(component => component.kind === 'COLUMN'),
      'order',
    );
    return (
      <Container componentProps={rowComponent}>
        <LeftColumnComponent
          key={columnPageComponentNodes[0].id}
          org={org}
          registrationForm={registrationForm}
          componentProps={columnPageComponentNodes[0]}
          rightMargin={rowComponent.cellSpacing}
          onSubmit={onSubmit}
          onFinishSubmit={onFinishSubmit}
          viewMode={viewMode}
          preview={preview}
          cellSpacing={rowComponent.cellSpacing}
          email={email}
          lockEmail={lockEmail}
          defaultFormValues={defaultFormValues}
          location={location}
        />
        <SharedColumnComponent
          key={columnPageComponentNodes[1].id}
          org={org}
          registrationForm={registrationForm}
          componentProps={columnPageComponentNodes[1]}
          onSubmit={onSubmit}
          onFinishSubmit={onFinishSubmit}
          viewMode={viewMode}
          preview={preview}
          cellSpacing={rowComponent.cellSpacing}
          email={email}
          lockEmail={lockEmail}
          defaultFormValues={defaultFormValues}
          location={location}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(SharedRowComponent, {
  org: graphql`
    fragment SharedRowComponent_org on Org {
      ...SharedColumnComponent_org
    }
  `,
  registrationForm: graphql`
    fragment SharedRowComponent_registrationForm on RegistrationForm {
      ...SharedColumnComponent_registrationForm
    }
  `,
  componentProps: graphql`
    fragment SharedRowComponent_componentProps on RegistrationPageComponent {
      id
      childPageComponents {
        edges {
          node {
            id
            kind
            order
            ...SharedColumnComponent_componentProps
          }
        }
      }
      rowComponent {
        id
        cellSpacing
        padding
        backgroundColor
      }
    }
  `,
});
