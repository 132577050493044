/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';

import {
  type removeEventBudgetCategoryMutationVariables,
  type removeEventBudgetCategoryMutationResponse,
} from './__generated__/removeEventBudgetCategoryMutation.graphql';

export type MutationInput = $PropertyType<removeEventBudgetCategoryMutationVariables, 'input'>;

type OptimisticResponse = {|
  +removeEventBudgetCategory: $Shape<{
    ...$NonMaybeType<
      $PropertyType<removeEventBudgetCategoryMutationResponse, 'removeEventBudgetCategory'>,
    >,
  }>,
|};

const mutation = graphql`
  mutation removeEventBudgetCategoryMutation($input: RemoveEventBudgetCategoryInput!) {
    removeEventBudgetCategory(input: $input) {
      removedCategoryId
      event {
        totalBudgetedAmount
        budgetCategories {
          totalCount
        }
      }
    }
  }
`;

const removeEventBudgetCategory = (
  input: MutationInput,
): Promise<removeEventBudgetCategoryMutationResponse> => {
  const optimisticResponse: OptimisticResponse = {
    removeEventBudgetCategory: {
      removedCategoryId: input.categoryId,
    },
  };

  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    optimisticResponse,
    updater: removeRecordFromConnection({
      deletedIdField: 'removedCategoryId',
      parentId: input.eventId,
      connections: [{ field: 'budgetCategories' }],
    }),
  });
};

export default removeEventBudgetCategory;
