/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';

import createBudgetReportingView from 'graph/mutations/budgetReportingViews/createBudgetReportingView';
import removeBudgetReportingView from 'graph/mutations/budgetReportingViews/removeBudgetReportingView';
import updateBudgetReportingView from 'graph/mutations/budgetReportingViews/updateBudgetReportingView';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { getDefaultFilters, suggestedFields } from 'components/AllEvents/eventsTableColumnSettings';
import SelectField, {
  type RenderProps,
  SelectedLabel,
} from 'components/material/SelectField/index';
import type {
  FrequencyType,
  ScheduledOnType,
} from 'components/ScheduledReporting/ScheduledReportingForm';

import type { BudgetReportingSavedViewsType } from '../../BudgetReportingPage';
import type { ParsedBudgetReportingFilters } from '../../lib/parseBudgetReportingFilters';
import EditViewWindow from './EditViewWindow';
import SavedViewTooltip from './SavedViewTooltip';
import SaveViewWindow from './SaveViewWindow';

export type SchedulerType = ?{|
  frequency: FrequencyType,
  scheduledOn: ScheduledOnType,
  includePayments: boolean,
  includeExpenses: boolean,
  recipientIds: $ReadOnlyArray<string>,
|};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledSelectField = styled(SelectField)`
  width: 260px;
  height: 30px;
  padding: 0 5px 0 15px;
  border-radius: 50px;
  font-size: 13px;
  background-color: #edf0fc;
  color: #4069e1;
  cursor: pointer;
  user-select: none;

  > div:first-child {
    overflow: hidden;
    border: 0;

    > i {
      font-size: 18px;
      color: #868f96;
    }

    > div {
      color: inherit;
    }
  }

  > div:nth-child(3) {
    min-width: 260px;
    transform: translate3d(0px, 30px, 0px) !important;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => props.selected && `background: ${props.theme.hoverRowColor};`};
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;
const SelectedLabelContent = styled.div`
  display: flex;
`;

const StyledLabel = styled.div`
  flex: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const View = styled.div`
  flex: 1 1 auto;
  padding: 5px 14px;
  font-size: 13px;
  color: #677386;
  cursor: pointer;
`;

const EditIcon = styled.i`
  padding: 6px 10px 0 0;
  color: #868f96;
  &:hover {
    color: rgba(55, 64, 76, 0.8);
  }
  cursor: pointer;
`;

const SaveAction = styled.div`
  ${props =>
    props.savedViewExists &&
    css`
      margin-left: 18px;
      @media (max-width: 1290px) {
        margin-left: 14px;
      }
    `};
  font-size: 13px;
  color: #3da9da;
  &:hover {
    color: #009dce;
    text-decoration: underline;
  }
  cursor: pointer;
`;

export default class SavedViews extends React.Component<
  {
    orgId: string,
    savedViewId: ?string,
    viewerCanCreateReportingSavedView: boolean,
    savedViews: BudgetReportingSavedViewsType,
    filters: ParsedBudgetReportingFilters,
    filterOptions: $ReadOnlyArray<FieldType>,
    onSetSavedView: (viewId: string) => void,
  },
  {
    showEditViewWindow: boolean,
    showSaveViewWindow: boolean,
    editedViewId: ?string,
    selectedViewId: ?string,
  },
> {
  state = {
    showEditViewWindow: false,
    showSaveViewWindow: false,
    editedViewId: null,
    selectedViewId: this.props.savedViewId,
  };

  overwrite: boolean;

  getDirection = (direction: ?boolean): ?('ASC' | 'DESC') => {
    if (typeof direction === 'boolean') {
      return direction ? 'ASC' : 'DESC';
    }
    return null;
  };

  getCustomFilters = (
    filters: $Shape<ParsedBudgetReportingFilters>,
    includeNullableFilters: boolean,
  ) => {
    return this.props.filterOptions
      .filter(
        option =>
          (option.fieldName == null || suggestedFields.includes(option.fieldName)) &&
          (includeNullableFilters || filters[option.id] != null),
      )
      .map(option => getCustomFieldVariable(filters[option.id], option));
  };

  getParentGroup = () => {
    const { group1, customGroup1 } = this.props.filters;
    if (customGroup1 != null) {
      return null;
    }
    return !group1 || group1 === 'EVENT_INFO_FIELDS' ? 'TEAM' : group1;
  };

  getGroupFiltersInput = () => {
    const { group2, customGroup1, customGroup2 } = this.props.filters;

    return {
      group1: this.getParentGroup(),
      group2: customGroup2 || group2 === 'EVENT_INFO_FIELDS' ? null : group2,
      customGroup1,
      customGroup2,
    };
  };

  handleChangeView = (selectedViewId: string) => {
    this.setState({ selectedViewId });
    this.props.onSetSavedView(selectedViewId);
  };

  handleHideEditViewWindow = () => {
    this.setState({ showEditViewWindow: false });
  };

  handleHideSaveViewWindow = () => {
    this.setState({ showSaveViewWindow: false });
  };

  handleShowSaveViewWindow = () => {
    this.setState({ showSaveViewWindow: true });
    this.setState({ showSaveViewWindow: true });
  };

  handleSaveView = (name: string, scheduler: ?SchedulerType, budgetReportingViewId: string) => {
    const { sort, date, requestedDate, group1, group2, ...restFilters } = this.props.filters;

    const filters = {
      ...getDefaultFilters(restFilters),
      beforeDate: date && date.end ? date.end.format() : null,
      afterDate: date && date.start ? date.start.format() : null,
      requestedDate,
      customFilters: this.getCustomFilters(restFilters, true),
    };

    const budgetReportingViewData = {
      filters,
      sort: sort ? sort.key : null,
      direction: sort ? this.getDirection(sort.asc) : null,
      ...this.getGroupFiltersInput(),
    };

    updateBudgetReportingView({
      budgetReportingViewId,
      name,
      scheduler,
      ...(this.overwrite ? { budgetReportingViewData } : {}),
    }).catch(showModernMutationError);
    this.handleHideEditViewWindow();
  };

  handleCreateView = (name: string, scheduler: SchedulerType) => {
    const { sort, date, requestedDate, group1, group2, ...restFilters } = this.props.filters;

    const filters = {
      ...getDefaultFilters(restFilters),
      beforeDate: date && date.end ? date.end.format() : null,
      afterDate: date && date.start ? date.start.format() : null,
      requestedDate,
      customFilters: this.getCustomFilters(restFilters, false),
    };

    createBudgetReportingView(this.props.orgId, {
      filters,
      name,
      ...(scheduler && { scheduler }),
      sort: sort ? sort.key : null,
      direction: sort ? this.getDirection(sort.asc) : null,
      ...this.getGroupFiltersInput(),
    })
      .then(budgetReportingView => {
        this.setState({ selectedViewId: budgetReportingView.id });
      })
      .catch(showModernMutationError);
    this.handleHideSaveViewWindow();
  };

  handleRemoveView = (viewId: string) => {
    removeBudgetReportingView(this.props.orgId, viewId)
      .then(() => {
        if (this.props.savedViews.edges.length > 0) {
          const selectedViewId = this.props.savedViews.edges[0].node.id;
          this.setState({ selectedViewId });
          this.props.onSetSavedView(selectedViewId);
        }
      })
      .catch(showModernMutationError);
    this.handleHideEditViewWindow();
  };

  handleClickEdit = (props: RenderProps<string>) => {
    this.overwrite = false;
    this.setState({ showEditViewWindow: true, editedViewId: props.value });
  };

  handleClickOverwrite = (selectedViewId: ?string) => {
    this.overwrite = true;
    this.setState({ showEditViewWindow: true, editedViewId: selectedViewId });
  };

  renderSavedViewRow = (props: RenderProps<string>) => (
    <OptionContainer selected={props.value === this.state.selectedViewId} key={props.value}>
      <View
        onMouseEnter={() => props.onHover(props.index)}
        onMouseDown={() => {
          props.onSelect(props.value);
          props.onHide();
        }}
      >
        {props.label}
      </View>
      {this.props.viewerCanCreateReportingSavedView && (
        <EditIcon
          selected={props.value === this.state.selectedViewId}
          className="fa fa-pencil"
          onMouseEnter={() => props.onHover(props.index)}
          onClick={() => this.handleClickEdit(props)}
        />
      )}
    </OptionContainer>
  );

  LabelWithIcon = ({ children, value, ...props }: { children: string, value: string }) => (
    <SelectedLabel {...props}>
      <SelectedLabelContent>
        <StyledLabel>{children}</StyledLabel>
        {this.props.viewerCanCreateReportingSavedView && <SavedViewTooltip editedViewId={value} />}
      </SelectedLabelContent>
    </SelectedLabel>
  );

  render() {
    const savedViews = this.props.savedViews.edges.map(edge => edge.node);
    const options = savedViews.map(view => ({
      value: view.id,
      label: view.name,
      render: this.renderSavedViewRow,
      onSelect: () => this.handleChangeView(view.id),
    }));
    const editedView = savedViews.find(view => view.id === this.state.editedViewId);

    const savedViewExists = options.length > 0;

    return (
      <Container>
        {savedViewExists && (
          <StyledSelectField
            label=""
            value={this.state.selectedViewId}
            options={options}
            disabled={false}
            onChange={() => {}}
            labelComponent={this.LabelWithIcon}
          />
        )}
        {this.props.viewerCanCreateReportingSavedView && (
          <>
            {this.state.selectedViewId && savedViewExists && (
              <SaveAction
                savedViewExists={savedViewExists}
                onClick={() => this.handleClickOverwrite(this.state.selectedViewId)}
              >
                Overwrite
              </SaveAction>
            )}
            <SaveAction savedViewExists={savedViewExists} onClick={this.handleShowSaveViewWindow}>
              + Create View
            </SaveAction>
          </>
        )}
        {this.state.showEditViewWindow && editedView && (
          <EditViewWindow
            editedViewId={editedView.id}
            handleHideEditViewWindow={this.handleHideEditViewWindow}
            handleRemoveView={this.handleRemoveView}
            handleSaveView={this.handleSaveView}
          />
        )}
        {this.state.showSaveViewWindow && (
          <SaveViewWindow
            filters={this.props.filters}
            savedViewName=""
            savedViewId={null}
            onHide={this.handleHideSaveViewWindow}
            onSaveNew={this.handleCreateView}
            onSave={this.handleSaveView}
            viewerCanUpdate={this.props.viewerCanCreateReportingSavedView}
          />
        )}
      </Container>
    );
  }
}
