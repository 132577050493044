/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import configureMarketoFieldMapping, {
  type ResourceType,
} from 'graph/mutations/integration/configureMarketoFieldMapping';
import showModernMutationError from 'graph/utils/showModernMutationError';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import IntegrationMapping from 'components/integrations/IntegrationsSettings/IntegrationMapping';

import companyFields from './lib/companyFields';
import contactFields from './lib/contactFields';
import eventContactFields from './lib/eventContactFields';
import marketoCompanyFields from './lib/marketoCompanyFields';

import {
  type MarketoLeadMappingsQueryResponse,
  type MarketoLeadMappingsQueryVariables,
} from './__generated__/MarketoLeadMappingsQuery.graphql';

type MarketoLeadMappingQueryOrgType = $PropertyType<MarketoLeadMappingsQueryResponse, 'org'>;
type MarketoFieldMappingType = $PropertyType<
  $ElementType<
    $PropertyType<
      $PropertyType<
        $NonMaybeType<$PropertyType<MarketoLeadMappingQueryOrgType, 'marketoAccount'>>,
        'marketoFieldMappings',
      >,
      'edges',
    >,
    0,
  >,
  'node',
>;
type MarketoFieldType = $PropertyType<
  $ElementType<
    $PropertyType<
      $PropertyType<
        $NonMaybeType<$PropertyType<MarketoLeadMappingQueryOrgType, 'marketoAccount'>>,
        'marketoFields',
      >,
      'edges',
    >,
    0,
  >,
  'node',
>;

export type ResourceFieldType = {|
  label: string,
  name: ?string,
  types: $ReadOnlyArray<string>,
  fixedMappingField?: ?string,
  fixedMappingCanDisable?: ?boolean,
  customFieldId?: ?string,
|};

const query = graphql`
  query MarketoLeadMappingsQuery(
    $resourceType: MarketoMappingResourceType!
    $customizableType: [CustomizableType!]!
  ) {
    org {
      id
      syncedToIbmWm

      marketoAccount {
        id
        marketoFields(resourceType: $resourceType) {
          edges {
            node {
              displayName
              dataType
              name
              readOnly
            }
          }
        }

        marketoFieldMappings(resourceType: $resourceType) {
          edges {
            node {
              id
              marketoField
              resourceType
              resourceFieldName
              resourceCustomField {
                id
              }
            }
          }
        }
      }

      customFields(customizableType: $customizableType) {
        edges {
          node {
            id
            kind
            order
            label
            required
            fieldName
          }
        }
      }
    }
  }
`;

const RESOURCE_FIELDS = {
  CONTACT: contactFields,
  COMPANY: companyFields,
  EVENTCONTACT: eventContactFields,
};

const RESOURCE_MARKETO_MAPPINGS = {
  CONTACT: 'Lead',
  COMPANY: 'Company',
  EVENTCONTACT: 'Member',
};

class MarketoLeadMappings extends React.PureComponent<{ resourceType: ResourceType }> {
  fieldDisableReasons = (
    activeFields: $ReadOnlyArray<string>,
    field: ResourceFieldType,
    marketoFieldValue: ?string,
    marketoField: ?MarketoFieldType,
  ) =>
    [
      ...(marketoField
        ? [
            marketoField.readOnly ? 'Field is not updateable' : null,
            field.types.includes(marketoField.dataType) ? null : 'Field type is not compatible',
            activeFields.includes(marketoField.name) ? 'Field is already being synced' : null,
          ]
        : []),
      marketoField ? null : `Field "${marketoFieldValue || ''}" not found in Marketo`,
    ].filter(Boolean);

  handleMappingSelect = (
    selectedMarketoField: ?string,
    resourceType: ResourceType,
    resourceFieldName: ?string,
    resourceCustomFieldId: ?string,
    org: MarketoLeadMappingQueryOrgType,
    marketoFieldMapping: ?MarketoFieldMappingType,
  ) => {
    if (!org.marketoAccount) return;

    configureMarketoFieldMapping(
      {
        resourceType,
        resourceFieldName,
        resourceCustomFieldId,
        marketoField: selectedMarketoField,
      },
      org.marketoAccount.id,
      marketoFieldMapping == null ? undefined : marketoFieldMapping.id,
    ).catch(showModernMutationError);
  };

  renderSuccess = (response: MarketoLeadMappingsQueryResponse) => {
    const org = response.org;
    const { resourceType } = this.props;
    const resourceFields = RESOURCE_FIELDS[resourceType](org);
    const marketoFields = org.marketoAccount
      ? org.marketoAccount.marketoFields.edges
          .map(edge => edge.node)
          .filter(field =>
            resourceType === 'COMPANY'
              ? marketoCompanyFields.includes(field.name)
              : !marketoCompanyFields.includes(field.name) || field.name === 'company',
          )
      : [];
    const marketoFieldMappings = org.marketoAccount
      ? org.marketoAccount.marketoFieldMappings.edges.map(edge => edge.node)
      : [];
    const activeFields = [
      ...resourceFields
        .filter(field => field.fixedMappingField && !field.fixedMappingCanDisable)
        .map(field => field.fixedMappingField)
        .filter(Boolean),
      ...marketoFieldMappings.map(marketoField => marketoField.marketoField),
    ];

    return (
      <>
        {resourceFields.map(field => {
          const marketoFieldMapping = marketoFieldMappings.find(mapping =>
            field.name
              ? field.name === mapping.resourceFieldName
              : field.customFieldId ===
                (mapping.resourceCustomField && mapping.resourceCustomField.id),
          );
          const marketoFieldValue = marketoFieldMapping
            ? marketoFieldMapping.marketoField
            : field.fixedMappingField;
          const marketoField = marketoFields.find(mkField => mkField.name === marketoFieldValue);

          const disabledReasons = marketoFieldValue
            ? this.fieldDisableReasons(
                activeFields.filter(mkField => marketoFieldValue && mkField !== marketoFieldValue),
                field,
                marketoFieldValue,
                marketoField,
              )
            : [];

          const options = marketoFields.map(mkField => {
            const optionDisabledReasons = this.fieldDisableReasons(
              activeFields,
              field,
              marketoFieldValue,
              mkField,
            );

            return {
              label: mkField.displayName,
              value: mkField.name,
              disabled: optionDisabledReasons.length > 0 && marketoFieldValue !== mkField.name,
              disabledInfo: optionDisabledReasons[0],
            };
          });

          return (
            <IntegrationMapping
              key={field.name || field.customFieldId}
              label={field.label}
              fieldSelectPlaceholder={`Select ${RESOURCE_MARKETO_MAPPINGS[resourceType]} Field`}
              directionSelectPlaceholder="Not Syncing"
              fieldSelectOptions={options}
              directionSelectOptions={[
                { label: 'To Marketo', value: 'to' },
                { label: 'From Marketo', value: 'from', disabled: true },
              ]}
              fieldSelectValue={marketoFieldValue}
              directionSelectValue={
                (field.fixedMappingField && !field.fixedMappingCanDisable) || marketoFieldMapping
                  ? 'to'
                  : null
              }
              disabledInfo={disabledReasons[0]}
              onSelect={selectedMarketoField => {
                this.handleMappingSelect(
                  selectedMarketoField,
                  resourceType,
                  field.name,
                  field.customFieldId,
                  org,
                  marketoFieldMapping,
                );
              }}
              disableFieldSelect={!!field.fixedMappingField}
              disableDirectionSelect={!!field.fixedMappingField && !field.fixedMappingCanDisable}
            />
          );
        })}
      </>
    );
  };

  render() {
    const { resourceType } = this.props;
    const variables: MarketoLeadMappingsQueryVariables = {
      resourceType,
      customizableType: [resourceType],
    };

    return (
      <DefaultQueryRenderer
        query={query}
        variables={variables}
        renderSuccess={this.renderSuccess}
      />
    );
  }
}

export default MarketoLeadMappings;
