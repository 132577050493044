/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import storage from 'utils/storage';

import ArrowIcon from 'images/arrow.svg';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Divider from 'components/Sidebar/Divider';

import DashboardMenuSavedViewRow from './DashboardMenuSavedViewRow';

import { type DashboardMenuSavedViews_user } from './__generated__/DashboardMenuSavedViews_user.graphql';
import { type DashboardMenuSavedViewsQueryResponse } from './__generated__/DashboardMenuSavedViewsQuery.graphql';

const SectionTitle = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px 5px 13px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.42);

  &:hover {
    z-index: 2;
  }

  &:hover,
  &:focus {
    background: #e9e4f5;
  }
`;

const StyledArrowIcon = styled(ArrowIcon)`
  width: 6px;
  margin-left: auto;
  color: #868f96;
  transform: rotate(${props => (props.collapsed ? 0 : 90)}deg);
`;

const SectionContent = styled.div`
  align-self: stretch;
  padding-bottom: 26px;
`;

const MoreViewsLink = styled.button`
  margin-left: 13px;
  font-size: 13px;
  color: #3ba9da;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

// name and shared props are needed for caching
const query = graphql`
  query DashboardMenuSavedViewsQuery($count: Int) {
    org {
      ...DashboardMenuSavedViewRow_org
    }
    me {
      eventLists(first: $count)
        @connection(key: "DashboardMenuSavedViews_eventLists", filters: []) {
        edges {
          node {
            id
            name
            shared
            ...DashboardMenuSavedViewRow_eventList
          }
        }
      }
    }
  }
`;

class DashboardMenuSavedViews extends React.PureComponent<
  {
    user: DashboardMenuSavedViews_user,
    pathPrefix: string,
    cachedResponse: ?DashboardMenuSavedViewsQueryResponse,
    onChangeCachedResponse: (cachedResponse: ?DashboardMenuSavedViewsQueryResponse) => void,
    viewerCanUpdate: boolean,
  },
  { collapsed: boolean, count: ?number, refetchKey: number },
> {
  storageKey = `SavedViewsCollapsed[${this.props.user.id}]`;

  state = {
    collapsed: storage.get(this.storageKey) === true,
    count: storage.get(this.storageKey) === true ? 0 : 5,
    refetchKey: Date.now(),
  };

  handleToggleSection = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();

    this.setState(
      state => ({ collapsed: !state.collapsed, count: state.collapsed ? 5 : 0 }),
      () => {
        storage.set(this.storageKey, this.state.collapsed);
      },
    );
  };

  handleLoadMore = () => {
    this.setState({ count: null });
  };

  handleRemoveSavedView = () => {
    if (this.props.user.eventLists.totalCount > 4) {
      this.setState({ refetchKey: Date.now() });
    }
  };

  renderContent = (
    response?: ?DashboardMenuSavedViewsQueryResponse = this.props.cachedResponse,
  ) => {
    const { user, pathPrefix, onChangeCachedResponse, viewerCanUpdate } = this.props;
    const eventLists = response
      ? response.me.eventLists.edges.map(edge => (edge && edge.node) || null).filter(Boolean)
      : [];

    if (response) {
      onChangeCachedResponse(response);
    } else {
      return null;
    }

    return (
      <SectionContent>
        {eventLists.map(eventList => (
          <DashboardMenuSavedViewRow
            key={eventList.id}
            org={response && response.org}
            eventList={eventList}
            userId={user.id}
            pathPrefix={pathPrefix}
            homepage={user.homepage}
            homepageListId={user.homepageListId}
            eventListsCount={user.eventLists.totalCount}
            onRemoveSavedView={this.handleRemoveSavedView}
            viewerCanUpdate={viewerCanUpdate}
          />
        ))}

        {user.eventLists.totalCount > eventLists.length && (
          <MoreViewsLink onClick={this.handleLoadMore}>
            + {user.eventLists.totalCount - eventLists.length} views
          </MoreViewsLink>
        )}
      </SectionContent>
    );
  };

  render() {
    const { user } = this.props;
    const { refetchKey, collapsed, count } = this.state;

    return (
      <>
        <Divider />
        <SectionTitle onClick={this.handleToggleSection}>
          SAVED VIEWS ({user.eventLists.totalCount})
          <StyledArrowIcon collapsed={collapsed ? 1 : 0} />
        </SectionTitle>

        {!collapsed && (
          <DefaultQueryRenderer
            key={refetchKey}
            query={query}
            variables={{ count }}
            renderSuccess={this.renderContent}
            renderLoading={this.renderContent}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(
  DashboardMenuSavedViews,
  graphql`
    fragment DashboardMenuSavedViews_user on User {
      id
      homepage
      homepageListId
      eventLists {
        totalCount
      }
    }
  `,
);
