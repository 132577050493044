/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateDividerComponentMutationVariables,
  UpdateDividerComponentInput,
} from './__generated__/updateDividerComponentMutation.graphql';

export type updateDividerComponentPropertyType = $Shape<UpdateDividerComponentInput>;

const mutation = graphql`
  mutation updateDividerComponentMutation($input: UpdateDividerComponentInput!) {
    updateRegistrationFormDividerComponent(input: $input) {
      pageComponent {
        dividerComponent {
          id
          height
          color
          padding
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateDividerComponent(properties: updateDividerComponentPropertyType) {
  const variables: updateDividerComponentMutationVariables = {
    input: {
      ...properties,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateRegistrationFormDividerComponent: {
        pageComponent: {
          dividerComponent: properties,
        },
      },
    },
  });
}
