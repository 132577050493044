/* @flow */

export default function appSubdomain(): ?string {
  const hostname = window.location.hostname;
  const domains = hostname.split('.');
  const subdomain = domains.slice(0, -1)[0];

  return subdomain === 'app' || hostname === 'localhost' || hostname.endsWith('.herokuapp.com')
    ? null
    : subdomain;
}
