/* @flow */
import sortBy from 'lodash/sortBy';

import customFieldsOptions from './customFieldsOptions';
import type { GroupField } from './types';

import { type SalesforceMapping_org } from '../__generated__/SalesforceMapping_org.graphql';

const optionsForField = field => {
  if (field.fieldName == null) {
    return customFieldsOptions[field.kind];
  }

  return {
    contact_type_id: { types: ['picklist'] },
    title: { types: ['string'] },
    owner_id: {
      types: ['reference'],
    },
    website: { types: ['url'] },
    phone1: { types: ['phone'] },
    phone2: { types: ['phone'] },
    street: { types: ['string', 'textarea'] },
    city: { types: ['string'] },
    state: { types: ['string'] },
    zip: { types: ['string'] },
    country: { types: ['string'] },
    twitter: { types: ['url'] },
    linkedin: { types: ['url'] },
    description: { types: ['textarea'], longText: true },
    hot_lead: { types: ['boolean'] },
    email_opt_in: { types: ['boolean'] },
  }[field.fieldName];
};

const contactFields = (
  org: SalesforceMapping_org,
  kind: 'CONTACT' | 'LEAD',
): $ReadOnlyArray<GroupField> => {
  const contactCustomFields = sortBy(
    org.contactCustomFields ? org.contactCustomFields.edges.map(edge => edge.node) : [],
    field => field.order,
  );

  return [
    {
      title: 'General',
      fields: [
        {
          label: 'Email',
          name: 'email',
          types: ['email'],
          fixedMappingField: 'Email',
          toSalesforce: true,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'First Name',
          name: 'first_name',
          types: ['string'],
          fixedMappingField: 'FirstName',
          toSalesforce: true,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'Last Name',
          name: 'last_name',
          types: ['string'],
          fixedMappingField: 'LastName',
          toSalesforce: true,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'Company',
          name: 'company',
          types: kind === 'CONTACT' ? ['reference'] : ['string'],
          fixedMappingField: kind === 'CONTACT' ? 'AccountId' : 'Company',
          toSalesforce: true,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'Lead Source',
          name: 'lead_source',
          types: ['picklist'],
          fixedMappingField: 'LeadSource',
          fixedMappingCanDisable: true,
          toSalesforce: true,
          fromSalesforce: true,
          nillable: true,
        },
        ...contactCustomFields
          .filter(field => field.kind !== 'USER_MULTISELECT' && field.fieldName !== 'company_id')
          .map(field => ({
            label: field.label,
            name: field.fieldName || 'custom',
            toSalesforce: true,
            ...(field.fieldName === 'owner_id'
              ? { fixedMappingField: 'OwnerId', fixedMappingCanDisable: true }
              : {}),
            fromSalesforce: true,
            nillable:
              !field.required &&
              field.kind !== 'BOOLEAN' &&
              !['hot_lead', 'email_opt_in'].includes(field.fieldName),
            customFieldId: field.id,
            ...optionsForField(field),
          })),
      ],
    },
  ];
};

export default contactFields;
