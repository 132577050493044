/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DefaultLeadSource_org$ref: FragmentReference;
export type DefaultLeadSource_org = {|
  +salesforceAccount: ?{|
    +leadSourcePickList: $ReadOnlyArray<string>,
    +defaultLeadSource: ?string,
  |},
  +$refType: DefaultLeadSource_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "DefaultLeadSource_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "leadSourcePickList",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "defaultLeadSource",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bc4878d1a0c6a20bbf50ca3a6b3af9df';
module.exports = node;
