/* @flow */
import * as React from 'react';

import { submissionStatuses } from 'config/eventRequestFormStatuses';

import type { FieldType } from 'utils/customization/types';

import CheckFilterItem from 'components/material/Filters/Advanced/CheckFilterItem';
import DateFilterItem from 'components/material/Filters/Advanced/DateFilterItem';
import { type FilterValueChangeParam } from 'components/material/Filters/Advanced/FilterItems';
import UserContactFilterItem from 'components/material/Filters/Advanced/UserContactFilterItem';
import UserFilterItem from 'components/material/Filters/Advanced/UserFilterItem';

import type { ParsedEventRequestsItemFilters } from './parseEventRequestsItemFilters';

import type { EventRequestsFiltersQueryResponse } from '../__generated__/EventRequestsFiltersQuery.graphql';

export default function renderRequestInboxFilter(
  requestForms: $PropertyType<
    $PropertyType<EventRequestsFiltersQueryResponse, 'org'>,
    'eventRequestForms',
  >,
  customField: FieldType,
  filters: ParsedEventRequestsItemFilters,
  onFilterChange: (name: string, value: FilterValueChangeParam) => void,
): React.Node {
  const fieldName = customField.fieldName;

  switch (fieldName) {
    case 'requestFormIds': {
      const eventRequestForms = requestForms.edges.map(({ node }) => ({
        label: node.name,
        value: node.id,
      }));

      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={fieldName}
          options={eventRequestForms}
          onChange={onFilterChange}
          activeValues={filters[fieldName]}
        />
      );
    }
    case 'senderIds':
      return (
        <UserContactFilterItem
          key={customField.id}
          label={customField.label}
          userFilterName="requesterUserIds"
          contactFilterName="requesterContactIds"
          onChange={onFilterChange}
          userIds={filters.requesterUserIds}
          contactIds={filters.requesterContactIds}
          searchPlaceholder="Search Requesters"
        />
      );
    case 'requestReviewers':
      return (
        <UserFilterItem
          key={customField.id}
          label={customField.label}
          name={fieldName}
          onChange={onFilterChange}
          activeValues={filters.requestReviewers}
          searchPlaceholder="Search Members"
          hasTeamAccess
        />
      );
    case 'requestedDate':
      return (
        <DateFilterItem
          key={customField.id}
          label={customField.label}
          name={fieldName}
          onChange={onFilterChange}
          activeValue={filters[fieldName]}
          hideShortcutOptions
        />
      );
    default:
      return (
        <CheckFilterItem
          key={customField.id}
          label={customField.label}
          name={fieldName || ''}
          options={submissionStatuses}
          onChange={onFilterChange}
          activeValues={filters.statuses}
          unsorted
        />
      );
  }
}
