/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';
import sortBy from 'lodash/sortBy';

import Approve from 'images/approve.svg';
import Warning from 'images/warning.svg';
import SelectField, {
  type SelectItem,
  Field,
  SelectedLabel,
} from 'components/material/SelectField';
import Tooltip from 'components/material/Tooltip';

const Column = styled.div`
  flex: 1 1 0%;
  margin-right: 35px;
  height: 40px;
  min-width: 0;

  &:last-of-type {
    margin-right: 15px;
  }

  &:nth-child(3) {
    flex-grow: 0.8;
    margin-right: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  max-width: 900px;
  margin-bottom: 20px;
  align-items: center;

  ${Column} {
    background: ${props => (props.active ? '#f3fafd' : '#f8f9f9')};

    ${props =>
      props.disabled &&
      css`
        background: rgba(244, 129, 131, 0.07);
      `};
  }
`;

const FieldName = styled.div`
  margin: 0 20px;
  font-weight: 500;
  line-height: 40px;
  color: #3e4859;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusIcon = styled.i`
  font-size: ${props => (props.disabled ? 16 : 18)}px;
  color: ${props => (props.disabled ? '#f38183' : '#29CC71')};

  svg {
    width: 18px;
  }
`;

const StatusIconPlaceholder = styled.div`
  width: 18px;
`;

const StyledSelectField = styled(SelectField)`
  height: 100%;

  ${Field} {
    height: 100%;
    padding: 0 10px 0 15px;
  }
`;

const SyncIcon = styled.i`
  margin-right: 8px;
  font-size: 12px;
  color: ${props => (props.active ? props.theme.primaryActionColor : props.theme.mutedTextColor)};
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
`;

const DisplayLabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -1px 0;
`;

const SyncSelectLabel = ({ children, ...props }) => (
  <SelectedLabel {...props}>
    <SyncIcon className="fa fa-fw fa-refresh" active={!props.showPlaceholder} />

    {children}
  </SelectedLabel>
);

export default class IntegrationMapping extends React.PureComponent<
  {
    label: string,
    fieldSelectPlaceholder: string,
    directionSelectPlaceholder?: string,
    fieldSelectOptions: $ReadOnlyArray<SelectItem<string> & { disabledInfo?: ?string }>,
    directionSelectOptions?: $ReadOnlyArray<SelectItem<string>>,
    fieldSelectValue: ?string,
    directionSelectValue?: ?string,
    disabledInfo: ?string,
    onSelect: (field: ?string, direction?: ?string) => void,
    disableFieldSelect?: boolean,
    disableDirectionSelect?: boolean,
  },
  {
    fieldSelectValue: ?string,
    directionSelectValue: ?string,
  },
> {
  state = {
    fieldSelectValue: this.props.fieldSelectValue,
    directionSelectValue: this.props.fieldSelectValue ? this.props.directionSelectValue : null,
  };

  handleChangeFieldSelect = (fieldSelectValue: ?string) => {
    this.setState(state => {
      if (!this.props.disableDirectionSelect) {
        const activeDirectionOptions = (this.props.directionSelectOptions || []).filter(
          option => !option.disabled,
        );
        const existingDirectionOptionEnabled = activeDirectionOptions.some(
          option => option.value === state.directionSelectValue,
        );
        const onlyOption = activeDirectionOptions.length === 1 && activeDirectionOptions[0].value;

        if (!fieldSelectValue || (!existingDirectionOptionEnabled && !onlyOption)) {
          return { fieldSelectValue, directionSelectValue: null };
        }

        if (onlyOption) {
          return { fieldSelectValue, directionSelectValue: onlyOption };
        }
      }

      return { fieldSelectValue };
    }, this.handleSelect);
  };

  handleChangeDirectionSelect = (directionSelectValue: ?string) => {
    this.setState(
      state => ({
        fieldSelectValue:
          this.props.disableFieldSelect || directionSelectValue ? state.fieldSelectValue : null,
        directionSelectValue,
      }),
      this.handleSelect,
    );
  };

  handleSelect = () => {
    const { fieldSelectValue, directionSelectValue } = this.state;
    const {
      onSelect,
      directionSelectOptions,
      disableFieldSelect,
      disableDirectionSelect,
    } = this.props;

    const bothSelected =
      (disableFieldSelect || fieldSelectValue) &&
      (disableDirectionSelect || directionSelectValue || !directionSelectOptions);
    const bothCleared =
      (disableFieldSelect || !fieldSelectValue) &&
      (disableDirectionSelect || !directionSelectValue || !directionSelectOptions);

    if (bothSelected || bothCleared) {
      onSelect(fieldSelectValue, directionSelectValue);
    }
  };

  render() {
    const {
      label,
      fieldSelectPlaceholder,
      directionSelectPlaceholder,
      fieldSelectOptions,
      directionSelectOptions,
      disabledInfo,
      disableFieldSelect,
      disableDirectionSelect,
    } = this.props;
    const { fieldSelectValue, directionSelectValue } = this.state;

    const active = !!fieldSelectValue && (!directionSelectOptions || !!directionSelectValue);

    return (
      <Row active={active} disabled={active && !!disabledInfo}>
        <Column>
          <FieldName>{label}</FieldName>
        </Column>

        <Column>
          <StyledSelectField
            options={sortBy(fieldSelectOptions, option => option.disabled).map(option =>
              option.disabledInfo && option.disabled
                ? {
                    ...option,
                    displayLabel: (
                      <DisplayLabelRow>
                        {option.displayLabel ?? option.label}

                        <StyledTooltip label={option.disabledInfo} placement="left">
                          <i className="fa fa-fw fa-info-circle" />
                        </StyledTooltip>
                      </DisplayLabelRow>
                    ),
                  }
                : option,
            )}
            value={fieldSelectValue}
            placeholder={fieldSelectPlaceholder}
            noBoxShadow
            onChange={this.handleChangeFieldSelect}
            disabled={disableFieldSelect}
            clearable={!directionSelectOptions}
            searchable
          />
        </Column>

        {directionSelectOptions && (
          <Column>
            <StyledSelectField
              options={directionSelectOptions}
              value={directionSelectValue}
              placeholder={directionSelectPlaceholder}
              noBoxShadow
              onChange={this.handleChangeDirectionSelect}
              disabled={disableDirectionSelect}
              clearable
              labelComponent={SyncSelectLabel}
            />
          </Column>
        )}

        <Tooltip placement="left" label={active && disabledInfo}>
          {active ? (
            <StatusIcon disabled={!!disabledInfo}>
              {disabledInfo ? <Warning /> : <Approve />}
            </StatusIcon>
          ) : (
            <StatusIconPlaceholder />
          )}
        </Tooltip>
      </Row>
    );
  }
}
