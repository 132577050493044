/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';

import type {
  removeEventMutationResponse,
  removeEventMutationVariables,
} from './__generated__/removeEventMutation.graphql';

const mutation = graphql`
  mutation removeEventMutation($input: RemoveEventInput!) {
    removeEvent(input: $input) {
      removedEventId
    }
  }
`;

export default function removeEvent(
  userId: string,
  teamId: string,
  eventId: string,
): Promise<removeEventMutationResponse> {
  const variables: removeEventMutationVariables = {
    input: {
      eventId,
    },
  };
  const optimisticResponse: removeEventMutationResponse = {
    removeEvent: {
      removedEventId: eventId,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedEventId',
        parentId: teamId,
        connections: [{ field: 'events' }, { field: 'events', args: { listType: 'UPCOMING' } }],
      }),
      removeRecordFromConnection({
        deletedIdField: 'removedEventId',
        parentId: userId,
        connections: [{ key: 'AllEventsTablePagination_events' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedEventId' }),
    ),
  });
}
