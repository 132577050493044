/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import { type IntegrationSettings } from 'components/integrations/IntegrationsSettings';
import Button from 'components/material/Button';
import StepProgress from 'components/StepProgress';

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const Title = styled.div`
  padding: 35px 0 50px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #3c4145;
`;

const Hightlight = styled.span`
  color: #828b93;
`;

const Scroll = styled.div`
  flex: 1;
  padding: 50px 40px;
  overflow-y: auto;
`;

const Buttons = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  box-shadow: 0 -9px 6px -10px rgba(0, 0, 0, 0.1);

  button {
    width: 126px;
    margin-left: 15px;
  }
`;

export type StepPageComponentType = {
  label: string,
  stepTitle: string,
  path: string,
  component: React.ComponentType<any>,
};

export default class SalesforceWindow extends React.PureComponent<
  {
    onFinish: () => void,
    integration: IntegrationSettings,
    components: StepPageComponentType[],
    renderPageByStep: (component: StepPageComponentType) => React.Node,
  },
  { stepIndex: number },
> {
  static getDerivedStateFromProps(
    props: $PropertyType<SalesforceWindow, 'props'>,
    state: $PropertyType<SalesforceWindow, 'state'>,
  ) {
    if (props.integration.expired && state.stepIndex !== 0) {
      return { stepIndex: 0 };
    }

    return null;
  }

  state = { stepIndex: 0 };

  handleChangeStep = (stepIndex: number) => this.setState({ stepIndex });

  handleBack = () => this.setState(state => ({ stepIndex: state.stepIndex - 1 }));

  handleNext = () => {
    if (this.state.stepIndex === this.props.components.length - 1) {
      this.props.onFinish();
    } else {
      this.setState(state => ({ stepIndex: state.stepIndex + 1 }));
    }
  };

  render() {
    const { integration, components, renderPageByStep } = this.props;
    const { stepIndex } = this.state;

    const currentStep = components[stepIndex];

    return (
      <Content>
        <Scroll>
          <StepProgress
            steps={components.map(step => step.label)}
            current={stepIndex}
            showStepNumber
          />

          <Title>
            <Hightlight>
              Step {stepIndex + 1}/{components.length}:
            </Hightlight>{' '}
            {currentStep.stepTitle}
          </Title>

          {renderPageByStep(currentStep)}
        </Scroll>

        {integration.connected && !integration.expired && (
          <Buttons>
            {stepIndex > 0 && <Button label="Back" primary outline onClick={this.handleBack} />}

            <Button
              label={stepIndex === components.length - 1 ? 'Finish' : 'Next'}
              primary
              onClick={this.handleNext}
            />
          </Buttons>
        )}
      </Content>
    );
  }
}
