/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import Clipboard from 'clipboard';

import type { BriefSharingGraphValue } from 'config/eventBriefSharingOptions';

import currentOrigin from 'utils/currentOrigin';

import ArrowDownIcon from 'images/arrowDown.svg';
import DuplicateIcon from 'images/duplicate.svg';
import EditIcon from 'images/edit.svg';
import EmbedIcon from 'images/events/embed.svg';
import Tooltip from 'components/material/Tooltip';

import BriefDownloadOptionSelectOverlay from './BriefDownloadOptionSelectOverlay';
import BriefEmbedWindow from './BriefEmbedWindow';

import { type BriefHeaderActionsBar_event } from './__generated__/BriefHeaderActionsBar_event.graphql';
import { type BriefHeaderActionsBar_me } from './__generated__/BriefHeaderActionsBar_me.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  z-index: 101;
  min-height: 55px;
  padding: 0 13px 0 40px;
  background-color: #285dff;
`;

const Title = styled.div`
  margin-right: auto;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
`;

const Action = styled.button`
  height: 32px;
  margin-left: 15px;
  padding: 0 20px;
  border: solid 1px #edf0fc;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #f5f5f5;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.24);
  }
  svg {
    margin-right: 10px;
  }
`;

const StyledAction = styled(Action)`
  display: flex;
  align-items: center;
  svg {
    margin: 2px 10px 0 0;
  }
`;

const StyledText = styled.div`
  margin-top: 2px;
`;

class BriefHeaderActionsBar extends React.Component<
  {
    event: BriefHeaderActionsBar_event,
    me: BriefHeaderActionsBar_me,
    accessibility: BriefSharingGraphValue,
    onEditClick: () => void,
  },
  { copied: boolean, showBriefEmbedWindow: boolean, selectingDownloadOption: boolean },
> {
  state = { copied: false, showBriefEmbedWindow: false, selectingDownloadOption: false };

  copyButton: ?HTMLElement;

  downloadButton = React.createRef();

  container = React.createRef();

  shareLink = `${currentOrigin()}/events/${this.props.event.slug}/share/brief`;

  componentDidMount() {
    this.addCopyListener();
  }

  componentDidUpdate = () => {
    if (this.props.accessibility !== 'DISABLED') {
      this.addCopyListener();
    }
  };

  addCopyListener = () => {
    if (this.copyButton) {
      new Clipboard(this.copyButton, {
        text: () => this.shareLink,
      }).on('success', () => {
        this.setState({ copied: true });
      });
    }
  };

  handleMouseLeave = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 300);
  };

  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleShowBriefEmbedWindow = () => {
    this.setState({ showBriefEmbedWindow: true });
  };

  handleHideBriefEmbedWindow = () => {
    this.setState({ showBriefEmbedWindow: false });
  };

  handleShowOptionSelectOverlay = () => {
    this.setState({ selectingDownloadOption: true });
  };

  handleHideOptionSelectOverlay = () => {
    this.setState({ selectingDownloadOption: false });
  };

  render() {
    return (
      <Container ref={this.container}>
        <Title>PREVIEW MODE</Title>
        {this.props.accessibility !== 'DISABLED' && (
          <>
            <Action ref={this.downloadButton} onClick={this.handleShowOptionSelectOverlay}>
              <ArrowDownIcon />
              Download
            </Action>
            <BriefDownloadOptionSelectOverlay
              event={this.props.event}
              me={this.props.me}
              show={this.state.selectingDownloadOption}
              target={this.downloadButton.current}
              container={this.container.current}
              onHide={this.handleHideOptionSelectOverlay}
            />
            <Tooltip
              innerRef={el => {
                this.copyButton = el;
              }}
              label={this.state.copied ? 'Copied' : ''}
              placement="bottom"
            >
              <Action onClick={this.stopPropagation} onMouseLeave={this.handleMouseLeave}>
                <DuplicateIcon />
                Copy link
              </Action>
            </Tooltip>
            <StyledAction onClick={this.handleShowBriefEmbedWindow}>
              <EmbedIcon />
              <StyledText>Embed</StyledText>
            </StyledAction>
          </>
        )}
        {this.props.event.viewerCanUpdate && (
          <Action onClick={this.props.onEditClick}>
            <EditIcon />
            Edit Content
          </Action>
        )}
        {this.state.showBriefEmbedWindow && (
          <BriefEmbedWindow shareLink={this.shareLink} onHide={this.handleHideBriefEmbedWindow} />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  BriefHeaderActionsBar,
  graphql`
    fragment BriefHeaderActionsBar_event on Event {
      slug
      viewerCanUpdate
      ...BriefDownloadOptionSelectOverlay_event
    }
    fragment BriefHeaderActionsBar_me on User {
      ...BriefDownloadOptionSelectOverlay_me
    }
  `,
);
