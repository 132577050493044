/* @flow */
import React from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';

import fullNameOfUser from 'utils/fullNameOfUser';

import ClearIcon from 'components/ClearIcon';
import CheckBox from 'components/material/CheckBox';
import MaterialAvatar from 'components/material/MaterialAvatar';
import TextInput from 'components/material/TextInput';

import type { UserContactFilterContainer_query } from './__generated__/UserContactFilterContainer_query.graphql';

const Container = styled.div``;

const TextWrapper = styled.div`
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  margin-top: 10px;
  padding: 0 27px 0 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Main = styled.div`
  margin-left: 10px;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const StyledCheckBox = styled(CheckBox)`
  margin-top: 16px;
  &:last-of-type {
    margin-bottom: 1px;
  }
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: ${props => props.theme.darkestTextColor};
  > div:first-of-type {
    color: ${props => (props.checked ? props.theme.highlightColor : props.theme.iron)};
  }
`;

type RequesterType = $Shape<{|
  id: string,
  kind: 'user' | 'contact',
  email: string,
  firstName: string,
  lastName: string,
  $fragmentRefs: any,
|}>;

class UserContactFilterContainer extends React.Component<
  {
    userIds: $ReadOnlyArray<string>,
    contactIds: $ReadOnlyArray<string>,
    onUserChange: (userIds: ?$ReadOnlyArray<string>, query: string) => void,
    onContactChange: (userIds: ?$ReadOnlyArray<string>, query: string) => void,
    query: UserContactFilterContainer_query,
    relay: RelayRefetchProp,
  },
  { query: string },
> {
  state = { query: '' };

  cachedUsers: $ReadOnlyArray<RequesterType> = [];

  fetchUsers = throttle((query: string) => {
    this.props.relay.refetch(
      {
        query,
        userIds: this.props.userIds,
        contactIds: this.props.contactIds,
        contactFilters: { query },
      },
      null,
    );
  }, 800);

  handleOptionChange = (checked: boolean, requester: RequesterType): void => {
    const { userIds, contactIds } = this.props;
    const values = checked
      ? [...(requester.kind === 'user' ? userIds : contactIds), requester.id]
      : (requester.kind === 'user' ? userIds : contactIds).filter(
          userId => userId !== requester.id,
        );
    if (requester.kind === 'user') {
      this.props.onUserChange(values, this.state.query);
    } else {
      this.props.onContactChange(values, this.state.query);
    }
  };

  handleQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ query: e.currentTarget.value });
    this.fetchUsers(e.currentTarget.value);
  };

  handleClear = () => {
    this.setState({ query: '' });
    this.fetchUsers('');
  };

  renderUserRow = (user: RequesterType) => (
    <Row>
      <MaterialAvatar user={user} radius={11} />
      <Main>{fullNameOfUser(user)}</Main>
    </Row>
  );

  render() {
    const { query, userIds, contactIds } = this.props;

    this.cachedUsers =
      query &&
      query.selectedUsers &&
      query.searchUsers &&
      query.selectedContacts &&
      query.org.contacts
        ? sortBy(
            uniqBy(
              [
                ...query.selectedUsers.map(user => ({ ...user, kind: 'user' })),
                ...query.searchUsers.edges.map(edge => ({ ...edge.node, kind: 'user' })),
                ...query.selectedContacts.map(contact => ({
                  ...contact,
                  kind: 'contact',
                })),
                ...query.org.contacts.edges.map(edge => ({
                  ...edge.node,
                  kind: 'contact',
                })),
              ],
              'id',
            ),
            option => `${option.firstName} ${option.lastName} ${option.email}`.toLowerCase(),
          )
        : this.cachedUsers;

    return (
      <Container>
        <TextWrapper>
          <StyledTextInput
            placeholder="Search Requesters"
            autoFocus
            value={this.state.query}
            onChange={this.handleQueryChange}
          />
          {this.state.query && <ClearIcon onClick={this.handleClear} />}
        </TextWrapper>
        {this.cachedUsers.map(option => (
          <StyledCheckBox
            key={option.id}
            labelRenderer={() => this.renderUserRow(option)}
            checked={[...userIds, ...contactIds].some(optionId => optionId === option.id)}
            onChange={checked => {
              this.handleOptionChange(checked, option);
            }}
          />
        ))}
      </Container>
    );
  }
}

export default createRefetchContainer(
  UserContactFilterContainer,
  graphql`
    fragment UserContactFilterContainer_query on Query {
      searchUsers(first: 15, query: $query) {
        edges {
          node {
            id
            email
            firstName
            lastName
            ...MaterialAvatar_user
          }
        }
      }
      selectedUsers: nodes(ids: $userIds) {
        id
        ... on User {
          id
          email
          firstName
          lastName
          ...MaterialAvatar_user
        }
      }
      org {
        contacts(first: 15, filters: $contactFilters) {
          edges {
            node {
              id
              email
              firstName
              lastName
              ...MaterialAvatar_user
            }
          }
        }
      }
      selectedContacts: nodes(ids: $contactIds) {
        id
        ... on Contact {
          id
          email
          firstName
          lastName
          ...MaterialAvatar_user
        }
      }
    }
  `,
  graphql`
    query UserContactFilterContainerRefetchQuery(
      $query: String!
      $userIds: [ID!]!
      $contactIds: [ID!]!
      $contactFilters: ContactFilters!
    ) {
      query {
        ...UserContactFilterContainer_query
      }
    }
  `,
);
