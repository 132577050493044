/* @flow */
import './styles.styl';
import * as React from 'react';

import classNames from 'classnames';
import { EditNamePanel } from 'components/panels/EditNamePanel';

export default class HeaderTitle extends React.Component<
  {
    className?: string,
    disableEditing?: boolean,
    value: string,
    onChange: (name: string) => void,
  },
  {
    editing: boolean,
  },
> {
  state = { editing: false };

  componentDidUpdate() {
    if (this.input) {
      this.input.select();
    } else if (this.state.editing) this.panel.show();
  }

  toggleTitleEditing = () => {
    if (!this.props.disableEditing) {
      this.setState(prevState => ({
        editing: !prevState.editing,
      }));
    }
  };

  handleTitleEditing = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.which === 13 || e.which === 27) {
      // enter || esc
      if (e.which === 13) {
        // enter
        const title = e.currentTarget.value;

        if (title.trim() && title !== this.props.value) {
          this.props.onChange(title);
        }
      }
      this.toggleTitleEditing();
    }
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const title = e.currentTarget.value;
    this.toggleTitleEditing();

    if (title.trim() && title !== this.props.value) {
      this.props.onChange(title);
    }
  };

  handlePanelClose = () => {
    this.panel.hide();
    this.toggleTitleEditing();
  };

  handleTitleSave = (title: string) => {
    this.panel.hide();
    this.toggleTitleEditing();

    if (title.trim() && title !== this.props.value) {
      this.props.onChange(title);
    }
  };

  input: ?HTMLInputElement;

  panel: React.ElementRef<EditNamePanel>;

  render() {
    if (
      this.state.editing &&
      document.documentElement &&
      document.documentElement.clientWidth >= 800
    ) {
      return (
        <div className={classNames('header-title-editing', this.props.className)}>
          <input
            ref={el => {
              this.input = el;
            }}
            onKeyDown={this.handleTitleEditing}
            onBlur={this.handleBlur}
            type="text"
            defaultValue={this.props.value}
          />
        </div>
      );
    }

    return (
      <div
        className={classNames('header-title', this.props.className)}
        onClick={this.toggleTitleEditing}
      >
        <div title={this.props.value}>{this.props.value}</div>
        <EditNamePanel
          onClose={this.handlePanelClose}
          onSave={this.handleTitleSave}
          value={this.props.value}
          title="Edit Name"
          modalOnMobile
          ref={el => {
            this.panel = el;
          }}
        />
      </div>
    );
  }
}
