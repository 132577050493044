/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import filterGroupColors from 'config/filterGroupColors.json';

import type { ColumnGroupConfiguration, GroupType } from 'components/AllEvents/lib/getEventFields';
import Search from 'components/Search';

import ColumnFiltersGroup from './ColumnFiltersGroup';
import ColumnFiltersItems from './ColumnFiltersItems';
import type { Filter } from './columnFilterTypes';

const ColumnFiltersContainer = styled.div`
  padding-top: 15px;
`;

const StyledColumnFilterItems = styled(ColumnFiltersItems)`
  padding-left: 35px;
`;

const StyledSearch = styled(Search)`
  max-width: 306px;
  margin: 0 20px 7px 20px;
  font-size: 14px;
`;

const ColumnSearchLabel = styled.div`
  padding: 15px 0 10px;
  font-size: 14px;
  color: #3e4859;
  text-align: center;
`;

export default class ColumnFiltersContent extends React.PureComponent<
  {
    filters: $ReadOnlyArray<Filter>,
    groups?: ColumnGroupConfiguration,
    shownFilters: $ReadOnlyArray<string>,
    onChange: (columnNames: $ReadOnlyArray<string>) => void,
  },
  { search: string },
> {
  state = { search: '' };

  getFilters = () => {
    const search = this.state.search.toLowerCase().trim();
    return search !== ''
      ? this.props.filters.filter(filter => filter.label.toLowerCase().includes(search))
      : this.props.filters;
  };

  handleFilterToggle = (value: string, checked: boolean) => {
    const { shownFilters, filters, onChange } = this.props;
    const isStickyColumn = filters.some(item => item.value === value && item.sticky);
    if (isStickyColumn) {
      return;
    }
    if (checked) {
      onChange(shownFilters.filter(item => item !== value));
    } else {
      onChange(
        sortBy([...shownFilters, value], s =>
          this.props.filters.findIndex(filter => filter.value === s),
        ),
      );
    }
  };

  handleGroupFiltersToggle = (groupId: string | number) => {
    const { shownFilters, onChange } = this.props;
    const filters = this.getFilters();
    const groupFilterValues = filters
      .filter(filter => filter.groupId === groupId)
      .map(filter => filter.value);
    const allChecked = groupFilterValues.every(value => shownFilters.includes(value));
    if (allChecked) {
      onChange(
        shownFilters.filter(
          item =>
            !groupFilterValues.includes(item) ||
            filters.findIndex(filter => filter.sticky === true && filter.value === item) > -1,
        ),
      );
    } else {
      const newFilterValues = groupFilterValues.filter(value => !shownFilters.includes(value));
      onChange(
        sortBy([...shownFilters, ...newFilterValues], s =>
          this.props.filters.findIndex(filter => filter.value === s),
        ),
      );
    }
  };

  handleSearch = (search: string) => {
    this.setState({ search });
  };

  getGroups = () => this.props.groups || [{ id: -1, label: '' }];

  getCustomGroups = () =>
    this.getGroups()
      .filter(group => typeof group.id === 'string')
      .map(group => group.id);

  getGroupColor = (group: GroupType, index: number) => {
    if (index === 0) {
      return filterGroupColors[0];
    }

    if (typeof group.id === 'string') {
      return filterGroupColors[(this.getCustomGroups().indexOf(group.id) % 4) + 1];
    }

    return filterGroupColors[group.id % filterGroupColors.length];
  };

  renderColumnFilters = () => {
    const { groups, shownFilters } = this.props;
    const filters = this.getFilters();

    const shownGroups = groups
      ? groups.filter(group => filters.some(filter => filter && filter.groupId === group.id))
      : null;

    if (filters.length === 0) {
      return <ColumnSearchLabel>No columns found</ColumnSearchLabel>;
    }

    return shownGroups ? (
      shownGroups.map((group, index) => {
        const groupFilters = filters.filter(filter => filter.groupId === group.id);
        const checked = groupFilters.some(filter => shownFilters.includes(filter.value));

        return (
          <ColumnFiltersGroup
            key={group.id}
            label={group.label}
            expanded={group.label === 'Basics'}
            id={group.id}
            checked={checked}
            handleGroupFiltersToggle={this.handleGroupFiltersToggle}
            indeterminate={
              !groupFilters.every(filter => shownFilters.includes(filter.value)) && checked
            }
            borderColor={this.getGroupColor(group, index)}
            forceExpanded={filters.length <= 5}
          >
            <StyledColumnFilterItems
              filters={filters.filter(filter => group.id === filter.groupId)}
              onChange={this.handleFilterToggle}
              shownFilters={shownFilters}
            />
          </ColumnFiltersGroup>
        );
      })
    ) : (
      <ColumnFiltersItems
        filters={filters}
        onChange={this.handleFilterToggle}
        shownFilters={this.props.shownFilters}
      />
    );
  };

  render() {
    const search = this.state.search;

    return (
      <ColumnFiltersContainer>
        <StyledSearch onSearch={this.handleSearch} search={search} placeholder="Search Columns" />
        {this.renderColumnFilters()}
      </ColumnFiltersContainer>
    );
  }
}
