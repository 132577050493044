/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { TeamMemberLastActivity_member } from './__generated__/TeamMemberLastActivity_member.graphql';

class TeamMemberLastActivity extends React.PureComponent<{
  member: TeamMemberLastActivity_member,
  tz: string,
}> {
  render() {
    const { member, tz } = this.props;
    return (
      <div>
        {member.node.lastSeenAt && (
          <DateTimeTooltip date={moment.tz(member.node.lastSeenAt, tz)} placement="top" />
        )}
      </div>
    );
  }
}

export default createFragmentContainer(
  TeamMemberLastActivity,
  graphql`
    fragment TeamMemberLastActivity_member on TeamMemberEdge {
      node {
        lastSeenAt
      }
    }
  `,
);
