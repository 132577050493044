/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { OpportunityStatus_opportunity } from './__generated__/OpportunityStatus_opportunity.graphql';

class OpportunityDescription extends React.Component<{
  opportunity: OpportunityStatus_opportunity,
}> {
  render() {
    return this.props.opportunity.status.name;
  }
}

export default createFragmentContainer(
  OpportunityDescription,
  graphql`
    fragment OpportunityStatus_opportunity on SalesforceOpportunityType {
      status {
        name
      }
    }
  `,
);
