/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';

import { type ParsedTaskFilters } from '../components/parseTaskFilters';
import TasksDueDateViewContent from './TasksDueDateViewContent';

import { type TasksDueDateView_org } from './__generated__/TasksDueDateView_org.graphql';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const query = graphql`
  query TasksDueDateViewQuery(
    $filters: DeliverableFilters
    $eventSlug: String!
    $count: Int!
    $cursor: String
  ) {
    event(slug: $eventSlug) {
      ...TasksDueDateViewContent_event
      ...TasksDueDateViewContent_totalCountEvent
    }
  }
`;

class TasksDueDateView extends React.PureComponent<{
  filters: ParsedTaskFilters,
  filtered: boolean,
  history: RouterHistory,
  eventSlug: string,
  org: TasksDueDateView_org,
  onSelectedTasksChange: (Array<string>) => void,
  selectedTasks: Array<string>,
  onTaskUpdate: (string, Object) => void,
  onTaskStatusUpdate: (string, 0 | 1) => void,
  onTaskRemove: string => void,
  onTaskAssign: (string, string) => void,
  onTaskUnassign: (string, string) => void,
  onTaskAddTag: (string, string) => void,
  onTaskRemoveTag: (string, string) => void,
  onGetTaskLink: string => string,
  onInviteClick: (taskId: string) => void,
  newTasks: Array<string>,
}> {
  firstOpen = true;

  renderTable = props => {
    return <TasksDueDateViewContent {...this.props} {...props} totalCountEvent={props.event} />;
  };

  render() {
    const sortFilter = this.props.filters.sort || {
      key: 'DUE_DATE',
      asc: true,
    };

    const variables = {
      count: 50,
      eventSlug: this.props.eventSlug,
      filters: {
        ...this.props.filters,
        sort: sortFilter.key,
        direction: sortFilter.asc ? 'ASC' : 'DESC',
      },
    };

    return (
      <DefaultQueryRenderer
        query={query}
        variables={variables}
        renderSuccess={response => {
          this.firstOpen = false;
          return this.renderTable({
            event: response.event,
            sort: sortFilter,
            filtered: this.props.filtered,
          });
        }}
        renderLoading={() => {
          return this.firstOpen ? (
            <LoaderContainer>
              <Loader size={30} />
            </LoaderContainer>
          ) : (
            this.renderTable({
              event: null,
              sort: sortFilter,
              loading: true,
              filtered: this.props.filtered,
            })
          );
        }}
      />
    );
  }
}

export default createFragmentContainer(TasksDueDateView, {
  org: graphql`
    fragment TasksDueDateView_org on Org {
      ...TasksDueDateViewContent_org
    }
  `,
});
