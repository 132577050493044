/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import xorBy from 'lodash/xorBy';

import getOptionKeysByKind from 'utils/customization/getOptionKeysByKind';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import { type CustomFieldsType, type FieldType } from 'utils/customization/types';
import isValidWebsite from 'utils/validators/isValidWebsite';

import updateCompany from 'graph/mutations/company/updateCompany';
import updateCustomFieldValue, {
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import showModernMutationError from 'graph/utils/showModernMutationError';

import customFields, { type InexactUser } from 'components/Customizable/customFields';
import TextField from 'components/material/TextField';

import { type CompanyDefaultFields } from './CompanyFormFields';
import suggestedFields from './suggestedFields';

import type { CompanyProfile_company } from './__generated__/CompanyProfile_company.graphql';
import type { CompanyProfile_event } from './__generated__/CompanyProfile_event.graphql';
import type { CompanyProfile_user } from './__generated__/CompanyProfile_user.graphql';

const CompanyViewContent = styled.div`
  display: flex;
  margin-top: 30px;
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 495px;
`;

const Row = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 15px;
  padding-left: 8px;
`;

const FieldColumn = styled.div`
  flex: 1 1 auto;
  &:not(:last-child) {
    margin-right: 35px;
  }
`;

type Props = {
  company: CompanyProfile_company,
  event?: CompanyProfile_event,
  user: CompanyProfile_user,
};

type State = {
  errors: { [string]: string },
  companyDefaultFields: CompanyDefaultFields,
  companyCustomFields: CustomFieldsType,
};

class CompanyProfile extends React.Component<Props, State> {
  state = {
    errors: {},
    companyDefaultFields: {
      name: this.props.company.name,
      phone: this.props.company.phone,
      website: this.props.company.website,
      twitter: this.props.company.twitter,
      linkedin: this.props.company.linkedin,
      description: this.props.company.description,
      country: this.props.company.country,
      state: this.props.company.state,
      city: this.props.company.city,
      zip: this.props.company.zip,
      street: this.props.company.street,
    },
    companyCustomFields: {
      customTextFields: this.props.company.customTextFields,
      customTextareaFields: this.props.company.customTextareaFields,
      customLinkFields: this.props.company.customLinkFields,
      customDateFields: this.props.company.customDateFields,
      customNumberFields: this.props.company.customNumberFields,
      customCurrencyFields: this.props.company.customCurrencyFields,
      customBooleanFields: this.props.company.customBooleanFields,
      customUserSelectFields: this.props.company.customUserSelectFields,
      customUserMultiselectFields: this.props.company.customUserMultiselectFields,
      customSelectFields: this.props.company.customSelectFields,
      customMultiselectFields: this.props.company.customMultiselectFields,
    },
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (
      xorBy(
        [
          ...prevState.companyCustomFields.customMultiselectFields,
          ...prevState.companyCustomFields.customSelectFields,
        ],
        [...nextProps.company.customMultiselectFields, ...nextProps.company.customSelectFields],
        filed => filed.option.id,
      ).length > 0
    ) {
      return {
        companyCustomFields: {
          ...prevState.companyCustomFields,
          customMultiselectFields: nextProps.company.customMultiselectFields,
          customSelectFields: nextProps.company.customSelectFields,
        },
      };
    }
    return null;
  }

  handleNameFieldBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const {
      company: { id: companyId },
      event,
    } = this.props;
    const parsedValue = e.currentTarget.value.trim();
    if (parsedValue === this.state.companyDefaultFields.name) return;
    this.setState(
      prevState => ({
        companyDefaultFields: { ...prevState.companyDefaultFields, name: parsedValue },
      }),
      () => {
        if (!this.state.companyDefaultFields.name) {
          this.setState(prevState => ({
            errors: { ...prevState.errors, name: 'Name is required' },
          }));
          return;
        }
        updateCompany({
          companyId,
          name: this.state.companyDefaultFields.name,
          eventId: event ? event.id : null,
          fromWindow: event ? 'event companies' : 'org companies',
        }).catch(showModernMutationError);
      },
    );
  };

  updateCustomFieldState = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    const { arrayKey, optionKey } = getOptionKeysByKind(field.kind);
    const value = Object.values(input)[0];
    const valuesArray = Array.isArray(value) ? value : [value].filter(Boolean);
    const optionsArray = valuesArray.map(optionId =>
      users == null ? optionId : users.find(user => user.id === optionId),
    );
    this.setState(prevState => {
      const fieldOptions = prevState.companyCustomFields[arrayKey];
      return {
        companyCustomFields: {
          ...prevState.companyCustomFields,
          [arrayKey]: [
            ...fieldOptions.filter(option => option.customField.id !== field.id),
            ...optionsArray.map(option => ({
              customField: { id: field.id },
              [optionKey]: optionKey === 'option' ? { id: option } : option,
            })),
          ],
        },
      };
    });
  };

  handleUpdateSuggestedField = (field: FieldType, changes: CompanyDefaultFields) => {
    const {
      company: { id: companyId },
      event,
    } = this.props;
    const value = Object.values(changes)[0];
    const fieldName = Object.keys(changes)[0];

    this.setState(prevState => ({
      companyDefaultFields: { ...prevState.companyDefaultFields, ...changes },
    }));
    if (field.required && (value === '' || value == null)) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [fieldName]: 'Required' },
      }));
      return;
    }
    if (
      ['website', 'twitter', 'linkedin'].includes(fieldName) &&
      typeof value === 'string' &&
      value !== '' &&
      !isValidWebsite(value)
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [fieldName]: 'Invalid url' },
      }));
      return;
    }

    updateCompany({
      companyId,
      eventId: event ? event.id : null,
      fromWindow: event ? 'event companies' : 'org companies',
      ...changes,
    }).catch(showModernMutationError);
    this.setState(prevState => ({
      errors: Object.keys(prevState.errors).reduce((obj, key) => {
        return key === fieldName ? obj : { ...obj, [key]: prevState.errors[key] };
      }, {}),
    }));
  };

  handleUpdateCustomField = (
    field: FieldType,
    input: $Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
    users?: $ReadOnlyArray<InexactUser>,
  ) => {
    this.updateCustomFieldState(field, input, users);
    const { event, company } = this.props;
    if (field.required && !Object.values(input)[0]) {
      this.setState(prevState => ({ errors: { ...prevState.errors, [field.id]: 'Required' } }));
      return;
    }
    if (field.kind === 'LINK' && !!input.linkValue && !isValidWebsite(input.linkValue)) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Invalid url' },
      }));
      return;
    }
    if (field.kind === 'LINK' && field.required && !input.linkValue) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    if (
      field.required &&
      field.kind === 'MULTISELECT' &&
      input.selectValues != null &&
      input.selectValues.length === 0
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    if (
      field.required &&
      field.kind === 'USER_MULTISELECT' &&
      input.userValues != null &&
      input.userValues.length === 0
    ) {
      this.setState(prevState => ({
        errors: { ...prevState.errors, [field.id]: 'Required' },
      }));
      return;
    }
    updateCustomFieldValue(
      { customFieldId: field.id, ...input },
      company,
      event ? event.id : null,
      event ? 'event companies' : 'org companies',
    ).catch(showModernMutationError);
    this.setState(prevState => ({
      errors: Object.keys(prevState.errors).reduce((obj, key) => {
        return key === field.id ? obj : { ...obj, [key]: prevState.errors[key] };
      }, {}),
    }));
  };

  render() {
    const company = this.props.company;
    const { companyDefaultFields, companyCustomFields, errors } = this.state;
    const sortedCustomFields = normalizeCustomFields(
      company.org.customFields.edges.map(edge => edge.node),
    );

    return (
      <CompanyViewContent>
        <DetailsColumn>
          <Row>
            <FieldColumn>
              <TextField
                label="Name"
                defaultValue={companyDefaultFields.name}
                autoFocus
                name="name"
                disabled={!company.viewerCanUpdate || !!company.salesforceId}
                onBlur={this.handleNameFieldBlur}
                error={errors.name}
                required
              />
            </FieldColumn>
          </Row>
          {sortedCustomFields.map(field => {
            if (field.kind === 'DEFAULT') {
              const renderer = field.fieldName && suggestedFields[field.fieldName];
              if (!renderer) return null;
              return (
                <Row key={field.id}>
                  {renderer({
                    company: companyDefaultFields,
                    viewerCanUpdate: company.viewerCanUpdate,
                    handleUpdate: changes => this.handleUpdateSuggestedField(field, changes),
                    field,
                    currency: company.org.settings.currency,
                    errors: this.state.errors,
                  })}
                </Row>
              );
            }
            return (
              <Row key={field.id}>
                {customFields[field.kind]({
                  customizable: companyCustomFields,
                  viewerCanUpdateValue: company.viewerCanUpdate,
                  handleUpdate: (input, users) => this.handleUpdateCustomField(field, input, users),
                  field,
                  currency: company.org.settings.currency,
                  tz: this.props.user.tz,
                  errors: this.state.errors,
                })}
              </Row>
            );
          })}
        </DetailsColumn>
      </CompanyViewContent>
    );
  }
}

export default createFragmentContainer(CompanyProfile, {
  company: graphql`
    fragment CompanyProfile_company on Company {
      id
      __typename
      name
      org {
        id
        dbId
        settings {
          currency
        }
        customFields(customizableType: [COMPANY]) {
          edges {
            node {
              id
              kind
              fieldName
              label
              required
              order
              viewerCanUpdate
              options {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      salesforceId
      phone
      website
      twitter
      linkedin
      description
      country
      state
      city
      zip
      street
      viewerCanRemove
      viewerCanUpdate
      customTextFields {
        customField {
          id
        }
        value
      }
      customTextareaFields {
        customField {
          id
        }
        value
      }
      customLinkFields {
        customField {
          id
        }
        value
      }
      customDateFields {
        customField {
          id
        }
        value
      }
      customNumberFields {
        customField {
          id
        }
        value
      }
      customCurrencyFields {
        customField {
          id
        }
        value
      }
      customBooleanFields {
        customField {
          id
        }
        value
      }
      customUserSelectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...MaterialAvatar_user
        }
      }
      customUserMultiselectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...MaterialAvatar_user
        }
      }
      customSelectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
      customMultiselectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
    }
  `,
  event: graphql`
    fragment CompanyProfile_event on Event {
      id
    }
  `,
  user: graphql`
    fragment CompanyProfile_user on User {
      tz
    }
  `,
});
