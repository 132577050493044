/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import HelperText from 'components/EventRequestForm/form/HelperText';
import type { QuestionType, QuestionValueType } from 'components/EventRequestForm/lib/types';

import ErrorMessage from '../../../form/ErrorMessage';
import TextInput from './TextInput';

const ComponentWrapper = styled.div`
  position: relative;
`;

const Component = styled.div`
  margin: 10px 0 5px;
  max-height: 285px;
  min-height: 50px;
  ${props =>
    props.isScrollable &&
    css`
      overflow-y: scroll;
      &:before {
        content: '';
        z-index: 1;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 15px;
        background: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0.001));
        pointer-events: none;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 15px;
        background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0.001));
        pointer-events: none;
      }
    `};
`;

const StyledTextInput = styled(TextInput)`
  margin-top: 0;
  margin-bottom: 10px;
`;

export default class QuestionSelectFieldWrapper extends React.Component<{
  question: QuestionType,
  value: QuestionValueType,
  options: $ReadOnlyArray<{| +id: string, +name: string |}>,
  error: ?string,
  children: React.Node,
  showOtherOptionInput: boolean,
  canScroll?: boolean,
  onChangeOtherOption?: (value: ?SyntheticEvent<HTMLInputElement>) => void,
  onBlurOtherOption?: (value: ?SyntheticEvent<HTMLInputElement>) => void,
}> {
  render() {
    const {
      question,
      options,
      value,
      error,
      children,
      showOtherOptionInput,
      canScroll,
      onChangeOtherOption,
      onBlurOtherOption,
    } = this.props;
    const otherValue = (value && value.textValue) || '';

    // Scroll bar appears when option count is greater than 8
    const isScrollable = options.length > 8 - (question.selectShowOtherOption ? 1 : 0) && canScroll;

    return (
      <>
        <ComponentWrapper>
          {question.description && <HelperText>{question.description}</HelperText>}
          <Component isScrollable={isScrollable}>
            {children}
            {showOtherOptionInput && (
              <StyledTextInput
                autoFocus={!value || !value.textValue}
                value={otherValue}
                onChange={onChangeOtherOption}
                onBlur={onBlurOtherOption}
              />
            )}
          </Component>
        </ComponentWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  }
}
