/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateEventDatesComponent, {
  type updateEventDatesComponentPropertyType,
} from 'graph/mutations/registration/updateEventDatesComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultBackgroundColor, defaultTextColor } from '../../registrationFormDefaults';
import PropertyRow from '../components/PropertyRow';
import RegistrationColorSelector from '../components/RegistrationColorSelector';
import { Section } from '.';
import { AlignmentToggle, BlockingLayer, Font, PaddingComponent } from './components';
import { type AlignmentType } from './components/AlignmentToggle';

import EventDatesComponent_org from './__generated__/EventDatesComponent_org.graphql';
import EventDatesComponent_selectedPageComponent from './__generated__/EventDatesComponent_selectedPageComponent.graphql';

const Container = styled.div`
  position: relative;
`;

class EventDatesComponent extends React.PureComponent<{
  org: EventDatesComponent_org,
  selectedPageComponent: EventDatesComponent_selectedPageComponent,
  onChangeComponentProperty: (updatedProps: SelectedComponent) => void,
}> {
  updateEventDatesComponent = (properties: updateEventDatesComponentPropertyType) => {
    updateEventDatesComponent({
      ...properties,
      id: this.props.selectedPageComponent.eventDatesComponent.id,
    }).catch(showModernMutationError);
  };

  handleChangeAlignment = (alignment: AlignmentType) => {
    this.updateEventDatesComponent({ alignment });
  };

  handleSavePadding = (padding: string) => {
    this.updateEventDatesComponent({ padding });
  };

  handleChangeFontColor = (color: string) => {
    this.updateEventDatesComponent({ color });
  };

  handleChangeBackgroundColor = (backgroundColor: string) => {
    this.updateEventDatesComponent({ backgroundColor });
  };

  render() {
    const {
      org,
      onChangeComponentProperty,
      selectedPageComponent: { id, eventDatesComponent },
    } = this.props;

    return (
      <Container>
        {id === 'new' && <BlockingLayer />}
        <Section>
          <Font
            fontFamily={eventDatesComponent.fontFamily}
            fontStyle={eventDatesComponent.fontStyle}
            fontSize={eventDatesComponent.fontSize}
            onChangeComponentProperty={onChangeComponentProperty}
            onSaveFontProperties={this.updateEventDatesComponent}
          />
          <PropertyRow label="Alignment">
            <AlignmentToggle
              selectedAlignment={eventDatesComponent.alignment}
              onChange={this.handleChangeAlignment}
            />
          </PropertyRow>
          <PropertyRow label="Font color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={eventDatesComponent.color || defaultTextColor}
              onChangeColor={this.handleChangeFontColor}
              alignTo="bottom-right"
            />
          </PropertyRow>
        </Section>
        <Section>
          <PropertyRow label="Background color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={eventDatesComponent.backgroundColor || defaultBackgroundColor}
              onChangeColor={this.handleChangeBackgroundColor}
              includeTransparent
            />
          </PropertyRow>
          <PropertyRow label="Text Padding">
            <PaddingComponent
              paddingString={eventDatesComponent.padding}
              onSave={this.handleSavePadding}
              onChange={onChangeComponentProperty}
            />
          </PropertyRow>
        </Section>
      </Container>
    );
  }
}

export default createFragmentContainer(EventDatesComponent, {
  org: graphql`
    fragment EventDatesComponent_org on Org {
      id
      settings {
        ...RegistrationColorSelector_orgSettings
      }
    }
  `,
  selectedPageComponent: graphql`
    fragment EventDatesComponent_selectedPageComponent on RegistrationPageComponent {
      id
      kind
      eventDatesComponent {
        id
        padding
        alignment
        fontFamily
        fontStyle
        fontSize
        color
        backgroundColor
      }
    }
  `,
});
