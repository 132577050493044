/* @flow */
import * as React from 'react';
import styled from 'styled-components';

export const StyledMention = styled.span`
  color: ${props => props.theme.primaryActionColor};
  font-weight: 500;
  &:before {
    content: '@';
    color: ${props => props.theme.mutedTextColor};
    font-weight: 400;
  }
`;

export default class Mention extends React.PureComponent<{
  children: React.Node,
}> {
  render() {
    return <StyledMention {...this.props}>{this.props.children}</StyledMention>;
  }
}
