/* @flow */

import * as React from 'react';
import { type Location, type LocationShape, type Match, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';

const ClearIcon = styled.i`
  position: absolute;
  right: -2px;
  top: 6px;
  display: none;
  cursor: pointer;
  font-size: 10px;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.negativeActionColor};
  }
`;

const Container = styled.div`
  position: relative;
  &:hover {
    ${ClearIcon} {
      display: block;
    }
  }
`;

export default class MenuItem extends React.Component<{
  index?: ?boolean,
  desktopOnly?: ?boolean,
  icon?: ?string,
  to: string | LocationShape,
  text: string,
  children?: React.Node,
  disabled?: ?boolean,
  className?: ?string,
  activePattern?: string,
  onClicked?: ?(e: Event, to: string | LocationShape) => void,
  isActive?: (match: Match, location: Location) => boolean,
  onRemove?: ?(text: string) => void,
}> {
  handleClickNav = (e: Event) => {
    const onCLicked = this.props.onClicked || null;
    if (onCLicked && this.props.to) {
      onCLicked(e, this.props.to);
    }
  };

  handleRemove = () => {
    const { onRemove, text } = this.props;
    if (onRemove) {
      onRemove(text);
    }
  };

  handleCheckActivePattern = (match: Match, location: Location) => {
    if (this.props.activePattern == null) {
      return false;
    }

    const regex = new RegExp(this.props.activePattern);
    return regex.test(location.pathname);
  };

  render() {
    const activeClasses = classNames('active', {
      icon: this.props.icon,
    });
    const linkClasses = classNames(
      'site-menu__link',
      {
        icon: this.props.icon,
        'mobile-hidden': this.props.desktopOnly || this.props.disabled,
      },
      this.props.className,
    );
    return !this.props.disabled ? (
      <Container className={this.props.className}>
        <NavLink
          activeClassName={activeClasses}
          className={linkClasses}
          exact={this.props.index || false}
          to={this.props.to}
          onClick={this.handleClickNav}
          isActive={this.props.activePattern ? this.handleCheckActivePattern : this.props.isActive}
        >
          {this.props.icon && <i className={`fa fa-fw fa-${this.props.icon}`} />}
          <span>{this.props.text}</span>
          {this.props.children}
        </NavLink>
        {this.props.onRemove && (
          <ClearIcon className="fa fa-fw fa-times" onClick={this.handleRemove} />
        )}
      </Container>
    ) : (
      <span className={linkClasses}>
        {this.props.icon && <i className={`fa fa-fw fa-${this.props.icon}`} />}
        <span>{this.props.text}</span>
        {this.props.children}
      </span>
    );
  }
}
