/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import getBudgetQuarterString from 'utils/fiscal/getBudgetQuarterString';

import CategoryPill from 'components/budget/CategoryPill';
import { type CellPropsType } from 'components/budget/Table';
import InlineSelectField from 'components/budget/Table/InlineSelectField';

import { type VendorExpenseCellPropsType } from './index';

import { type VendorExpenseCategory_expense } from './__generated__/VendorExpenseCategory_expense.graphql';
import { type VendorExpenseCategory_org } from './__generated__/VendorExpenseCategory_org.graphql';

const ManageLink = styled.a`
  color: #3ba9da;
`;

const preventDefault = (event: SyntheticEvent<HTMLElement>) => {
  event.preventDefault();
};

const VendorExpenseCategory = ({
  expense,
  org,
  updateExpense,
  updateColumnWidth,
}: CellPropsType<
  VendorExpenseCellPropsType<VendorExpenseCategory_expense, VendorExpenseCategory_org>,
>) => (
  <InlineSelectField
    options={[
      ...sortBy(
        org.budgetCategories.edges.map(edge => edge.node),
        'name',
      ).map(category => ({
        label: category.name,
        value: category.id,
        info: category.description,
      })),
      org.viewerCanUpdate
        ? {
            label: 'Manage Categories',
            value: 'manage',
            displayLabel: (
              <ManageLink
                href={`/settings/budgets/${getBudgetQuarterString(org.settings.fiscalYearStart)}`}
                target="_blank"
                onClick={preventDefault}
              >
                Manage Categories
              </ManageLink>
            ),
          }
        : null,
    ].filter(Boolean)}
    value={expense.budgetCategory && expense.budgetCategory.id}
    searchable
    placeholder="Select Category"
    onChange={categoryId => {
      if (categoryId === 'manage') {
        window.open('/settings/budgets');

        return;
      }

      updateExpense({ categoryId }).then(() => {
        updateColumnWidth();
      });
    }}
    clearable
  >
    {expense.budgetCategory && <CategoryPill category={expense.budgetCategory} />}
  </InlineSelectField>
);

export default createFragmentContainer(
  VendorExpenseCategory,
  graphql`
    fragment VendorExpenseCategory_expense on Expense {
      budgetCategory {
        id
        ...CategoryPill_category
      }
    }

    fragment VendorExpenseCategory_org on Org {
      viewerCanUpdate
      settings {
        fiscalYearStart
      }
      budgetCategories {
        edges {
          node {
            id
            name
            description
          }
        }
      }
    }
  `,
);
