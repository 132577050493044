/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateEventNameComponentMutationVariables,
  UpdateEventNameComponentInput,
} from './__generated__/updateEventNameComponentMutation.graphql';

export type updateEventNameComponentPropertyType = $Shape<UpdateEventNameComponentInput>;

const mutation = graphql`
  mutation updateEventNameComponentMutation($input: UpdateEventNameComponentInput!) {
    updateRegistrationFormEventNameComponent(input: $input) {
      pageComponent {
        eventNameComponent {
          id
          fontFamily
          fontStyle
          fontSize
          color
          padding
          alignment
          backgroundColor
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateEventNameComponent(properties: updateEventNameComponentPropertyType) {
  const {
    id,
    fontFamily,
    fontStyle,
    fontSize,
    color,
    padding,
    alignment,
    backgroundColor,
  } = properties;
  const variables: updateEventNameComponentMutationVariables = {
    input: {
      ...properties,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateRegistrationFormEventNameComponent: {
        pageComponent: {
          eventNameComponent: {
            id,
            fontFamily,
            fontStyle,
            fontSize,
            color,
            padding,
            alignment,
            backgroundColor,
          },
        },
      },
    },
  });
}
