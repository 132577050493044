/**
 * @flow
 * @relayHash af8b96764f5c0a4e0e032dd028fdbef9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type queryEventsImportWindowQueryVariables = {||};
export type queryEventsImportWindowQueryResponse = {|
  +org: {|
    +id: string,
    +name: string,
    +salesforceAccount: ?{|
      +id: string
    |},
    +users: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +firstName: string,
          +lastName: string,
          +email: string,
        |}
      |}>
    |},
    +customFields: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +label: string,
          +fieldName: ?string,
          +kind: CustomFieldKindType,
          +order: number,
          +section: ?{|
            +order: number
          |},
        |}
      |}>
    |},
  |}
|};
export type queryEventsImportWindowQuery = {|
  variables: queryEventsImportWindowQueryVariables,
  response: queryEventsImportWindowQueryResponse,
|};
*/


/*
query queryEventsImportWindowQuery {
  org {
    id
    name
    salesforceAccount {
      id
    }
    users {
      edges {
        node {
          firstName
          lastName
          email
          id
        }
      }
    }
    customFields(customizableType: [EVENT]) {
      edges {
        node {
          id
          label
          fieldName
          kind
          order
          section {
            order
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "salesforceAccount",
  "storageKey": null,
  "args": null,
  "concreteType": "SalesforceAccount",
  "plural": false,
  "selections": [
    v0
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "customizableType",
    "value": [
      "EVENT"
    ],
    "type": "[CustomizableType!]!"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "queryEventsImportWindowQuery",
  "id": null,
  "text": "query queryEventsImportWindowQuery {\n  org {\n    id\n    name\n    salesforceAccount {\n      id\n    }\n    users {\n      edges {\n        node {\n          firstName\n          lastName\n          email\n          id\n        }\n      }\n    }\n    customFields(customizableType: [EVENT]) {\n      edges {\n        node {\n          id\n          label\n          fieldName\n          kind\n          order\n          section {\n            order\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "queryEventsImportWindowQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": null,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"EVENT\"])",
            "args": v6,
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v0,
                      v7,
                      v8,
                      v9,
                      v10,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "section",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldSectionType",
                        "plural": false,
                        "selections": [
                          v10
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "queryEventsImportWindowQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": null,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5,
                      v0
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"EVENT\"])",
            "args": v6,
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v0,
                      v7,
                      v8,
                      v9,
                      v10,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "section",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldSectionType",
                        "plural": false,
                        "selections": [
                          v10,
                          v0
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75a79a5c6c2e434d90c238a3fbf3b39d';
module.exports = node;
