/* @flow */
import sortBy from 'lodash/sortBy';

import { type CustomizableType, type Field } from 'components/FieldSettings/types';

import companyDefaultFields from './companyDefaultFields';
import opportunityDefaultFields from './opportunityDefaultFields';
import personDefaultFields from './personDefaultFields';
import vendorDefaultFields from './vendorDefaultFields';

const getDefaultFields = (customizableType: CustomizableType, syncEnabled?: boolean) => {
  switch (customizableType) {
    case 'CONTACT':
      return personDefaultFields.filter(field =>
        syncEnabled ? field : field.id !== 'salesforce_sync_as',
      );
    case 'SALESFORCEOPPORTUNITY':
      return opportunityDefaultFields;
    case 'COMPANY':
      return companyDefaultFields;
    case 'VENDOR':
      return vendorDefaultFields;
    default:
      return [];
  }
};

const getStandardizedFields = (
  fields: $ReadOnlyArray<Field>,
  customizableType: CustomizableType,
  syncEnabled?: boolean,
): $ReadOnlyArray<Field> => {
  const defaultFields = getDefaultFields(customizableType, syncEnabled);
  return [
    ...defaultFields,
    ...sortBy(
      fields.map(field => ({
        ...field,
        isDefault: false,
        isSuggested: !!field.fieldName,
        canDisable: syncEnabled ? field.fieldName !== 'company_id' : true,
      })),
      'order',
    ),
  ];
};

export default getStandardizedFields;
