/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';

import type {
  removeParticipantMutationVariables,
  removeParticipantMutationResponse,
} from './__generated__/removeParticipantMutation.graphql';

const mutation = graphql`
  mutation removeParticipantMutation($input: RemoveParticipantFromScheduleItemInput!) {
    removeParticipantFromScheduleItem(input: $input) {
      removedParticipantId
    }
  }
`;

export default function removeParticipant(
  scheduleItemId: string,
  userId: string,
): Promise<removeParticipantMutationResponse> {
  const variables: removeParticipantMutationVariables = {
    input: {
      scheduleItemId,
      userId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: removeRecordFromConnection({
      parentId: scheduleItemId,
      connections: [{ field: 'scheduleParticipants' }],
      deletedIdField: 'removedParticipantId',
    }),
  });
}
