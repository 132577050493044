/* @flow */

import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { transparentize } from 'polished';

import BriefDownload from './BriefDownload';

import { type BriefMenu_event } from './__generated__/BriefMenu_event.graphql';

export type BriefMenuItems = {
  eventInfo?: boolean,
  eventStaff?: boolean,
  schedules?: boolean,
  tasks?: boolean,
  notes?: boolean,
  contacts?: boolean,
  vendors?: boolean,
  attachments?: boolean,
};

const sectionsMap = {
  event_info: 'eventInfo',
  event_staff: 'eventStaff',
  schedules: 'schedules',
  tasks: 'tasks',
  notes: 'notes',
  contacts: 'contacts',
  vendors: 'vendors',
  attachments: 'attachments',
};

const sectionNames = {
  eventInfo: 'Event Info',
  eventStaff: 'Event Staff',
  schedules: 'Schedule',
  tasks: 'Tasks',
  notes: 'Notes',
  contacts: 'Contacts',
  vendors: 'Vendors',
  attachments: 'Attachments',
};

const nonSortableSections = [
  { name: 'notes' },
  { name: 'contacts' },
  { name: 'vendors' },
  { name: 'attachments' },
];

const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  flex: 1 1 auto;
  padding: 20px 0;
  background: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  user-select: none;
  @media (max-width: 800px) {
    justify-content: left;
    padding: 6px 0;
  }
`;

const MenuButton = styled.div`
  display: none;
  color: ${props => (props.menuOpened ? props.color : 'inherit')};
  cursor: pointer;
  @media (max-width: 800px) {
    display: block;
    padding: 0 15px;
    font-size: 20px;
  }
`;

const MenuBar = styled.div`
  display: flex;
  justify-content: center;
  transition: max-height ${props => (props.menuOpened ? '0.5s' : '0.1s')} linear;
  @media (max-width: 800px) {
    max-height: ${props => (props.menuOpened ? '1000px' : '0px')};
    overflow: hidden;
    flex-direction: column;
  }
`;

const MenuItem = styled.a`
  padding: 0 10px;
  font-size: 15px;
  font-weight: 500;
  color: ${props => (props.active ? props.color : transparentize(0.39, '#4a5665'))};
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: ${props => props.color};
  }
  @media (pointer: coarse) {
    &:hover {
      color: ${props => (props.active ? props.color : transparentize(0.39, '#4a5665'))};
    }
  }
  @media (max-width: 940px) {
    padding: 0 5px;
    font-size: 14px;
  }
  @media (max-width: 800px) {
    padding: 20px 0 0 30px;
    color: ${props => props.color};
  }
`;

const BriefDownloadContainer = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

class BriefMenu extends React.Component<
  {
    event: BriefMenu_event,
    listToken?: string,
    userEmail?: ?string,
    noteIds?: $ReadOnlyArray<string>,
    contactIds?: $ReadOnlyArray<string>,
    companyIds?: $ReadOnlyArray<string>,
    vendorIds?: $ReadOnlyArray<string>,
    attachmentIds?: $ReadOnlyArray<string>,
  },
  {
    menuOpened: boolean,
    current: string,
  },
> {
  state = { menuOpened: false, current: '' };

  briefPageElement: ?HTMLElement;

  componentDidMount() {
    this.briefPageElement = document.getElementById('briefPage');
    const anchors = this.getAnchors();

    if (anchors.length > 0) {
      this.setState({ current: anchors[anchors.length - 1].dataset.name });
    }

    if (this.briefPageElement) this.briefPageElement.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    if (this.briefPageElement) {
      this.briefPageElement.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const briefPageElement: ?HTMLElement = document.getElementById('briefPage');
    const { current } = this.state;

    if (briefPageElement) {
      const fromBottom = briefPageElement.scrollTop + briefPageElement.offsetHeight;
      const anchors = this.getAnchors();

      // fromBottom is the the screen bottom line position and by -350 the menu item
      // is changed when the section header reaches -350 and above position.
      const currentAnchor = anchors.find(item => item.offsetTop <= fromBottom - 350);
      if (currentAnchor && current !== currentAnchor.dataset.name) {
        this.setState({ current: currentAnchor.dataset.name });
      }
    }
  };

  handleMenuClick = () => {
    this.setState(prevState => ({ menuOpened: !prevState.menuOpened }));
  };

  handleLinkClick = event => {
    const anchors = this.getAnchors();
    const top: number =
      (anchors.find(x => x.dataset.name === event.currentTarget.dataset.name) || {}).offsetTop -
      (window.innerWidth > 800 ? 80 : 60);

    if (this.briefPageElement) {
      this.briefPageElement.scrollTop = top;
    }
    this.setState({ menuOpened: false });
  };

  getMenu = () => {
    const { event, noteIds, contactIds, companyIds, vendorIds, attachmentIds } = this.props;

    const enabledSections = event.briefSettings.briefTemplate.sections
      .filter(item => item.enabled)
      .map(item => {
        return sectionsMap[item.name];
      });

    return {
      eventInfo: enabledSections.includes('eventInfo'),
      eventStaff: enabledSections.includes('eventStaff') && event.staffers.totalCount > 0,
      schedules:
        enabledSections.includes('schedules') &&
        event.schedules.edges
          .map(({ node }) => node)
          .filter(
            schedule =>
              schedule.scheduleDays.totalCount > 0 &&
              schedule.scheduleDays.edges.find(
                ({ node }) => node.scheduleItemsCount.totalCount > 0,
              ),
          ).length > 0,
      tasks: enabledSections.includes('tasks') && event.deliverables.totalCount > 0,
      // Total count doesn't work for notes, attachements and contacs. Total count return 0
      // if manually they haven't been added or removed from event (brief editor, sections)
      notes: Array.isArray(noteIds)
        ? noteIds.length > 0
        : event.briefSettings.notes.edges.length > 0,
      contacts:
        Array.isArray(contactIds) || Array.isArray(companyIds)
          ? (contactIds || []).length + (companyIds || []).length > 0
          : event.briefSettings.contacts.edges.length + event.briefSettings.companies.edges.length >
            0,
      vendors: Array.isArray(vendorIds)
        ? vendorIds.length > 0
        : event.briefSettings.vendors.edges.length > 0,
      attachments: Array.isArray(attachmentIds)
        ? attachmentIds.length > 0
        : event.briefSettings.attachments.edges.length > 0,
    };
  };

  getAnchors = (): $ReadOnlyArray<HTMLElement> =>
    [...document.getElementsByClassName('brief_anchor')].reverse();

  render() {
    const { event, listToken, userEmail } = this.props;
    const { menuOpened, current } = this.state;
    const menuItems: BriefMenuItems = this.getMenu();

    const primaryColor: string = event.team.primaryColor;
    const sections = [...event.briefSettings.briefTemplate.sections, ...nonSortableSections];

    return (
      <Container>
        <MenuButton
          onClick={this.handleMenuClick}
          menuOpened={menuOpened}
          color={primaryColor}
          className="fa fa-fw fa-bars"
        />
        <MenuBar menuOpened={menuOpened}>
          {sections.map(section => {
            if (menuItems[sectionsMap[section.name]] === true) {
              return (
                <MenuItem
                  key={section.name}
                  onClick={this.handleLinkClick}
                  color={primaryColor}
                  active={current === section.name}
                  data-name={section.name}
                >
                  {sectionNames[sectionsMap[section.name]]}
                </MenuItem>
              );
            }
            return null;
          })}
          <BriefDownloadContainer>
            <BriefDownload listToken={listToken} event={event} userEmail={userEmail} />
          </BriefDownloadContainer>
        </MenuBar>
      </Container>
    );
  }
}

export default createFragmentContainer(
  BriefMenu,
  graphql`
    fragment BriefMenu_event on Event {
      ...BriefDownload_event
      team {
        primaryColor
      }
      deliverables {
        totalCount
      }
      schedules(isPersonalized: $personalizeSchedules) {
        edges {
          node {
            id
            scheduleDays {
              totalCount
              edges {
                node {
                  scheduleItemsCount: scheduleItems(isPersonalized: $personalizeSchedules) {
                    totalCount
                  }
                }
              }
            }
          }
        }
      }
      staffers {
        totalCount
      }
      briefSettings {
        briefTemplate {
          sections {
            name
            order
            enabled
          }
        }
        notes {
          edges {
            node {
              id
            }
          }
        }
        attachments {
          edges {
            node {
              id
            }
          }
        }
        contacts {
          edges {
            node {
              id
            }
          }
        }
        companies {
          edges {
            node {
              id
            }
          }
        }
        vendors {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `,
);
