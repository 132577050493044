import commonDomains from 'config/commonDomains.json';

import isValidEmail from './isValidEmail';

const isValidBusinessEmail = email => {
  const regex = RegExp(`^(.*[@](${commonDomains.join('|')})).*`, 'i');
  return isValidEmail(email) && !regex.test(email);
};

export default isValidBusinessEmail;
