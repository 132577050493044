/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import theme from 'config/theme';

import Checkbox from 'components/material/CheckBox';

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 20px;
  padding: 3px 6px;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  ${props =>
    props.checked
      ? `
    background: ${props.theme.highlightColor};
    color: ${props.theme.checkboxTextColor};
  `
      : `
    background: ${props.theme.primaryRowColor};
    color: ${props.theme.rowPrimaryTextColor};
  `};
`;

export default class TaskStatus extends React.PureComponent<{
  completed: boolean,
  onToggle: () => void,
  disabled?: boolean,
}> {
  render() {
    return (
      <StyledCheckbox
        checked={this.props.completed}
        label={this.props.completed ? 'Completed' : 'Open'}
        onChange={this.props.disabled ? () => {} : this.props.onToggle}
        checkColor={this.props.completed ? theme.checkboxTextColor : null}
        disabled={this.props.disabled}
      />
    );
  }
}
