/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SchedulesTimelineItem_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SchedulesTimelineColumn_items$ref: FragmentReference;
export type SchedulesTimelineColumn_items = $ReadOnlyArray<{|
  +id: string,
  +start_time: any,
  +end_time: ?any,
  +all_day: boolean,
  +$fragmentRefs: SchedulesTimelineItem_item$ref,
  +$refType: SchedulesTimelineColumn_items$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SchedulesTimelineColumn_items",
  "type": "ScheduleItem",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "start_time",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "end_time",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "all_day",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SchedulesTimelineItem_item",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f8bdd65b180c936c4ce0c28614ac6f67';
module.exports = node;
