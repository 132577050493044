/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Window, { WindowContent } from 'components/material/Window';
import Button from 'components/material/Button';
import ContactMultiselect from 'components/ContactMultiselect';

const Title = styled.div`
  margin: 40px 0 26px 0;
  font-size: 17px;
  font-weight: 500;
  color: #96a2ab;
`;

const CompanyName = styled.span`
  color: ${props => props.theme.headerRowColor};
`;

const Buttons = styled.div`
  margin-top: 30px;
  text-align: right;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

export default class AddToEventsWindow extends React.Component<
  {
    companyName: string,
    eventId?: ?string,
    onCancel: () => void,
    onSave: (contactIds: $ReadOnlyArray<string>) => void,
  },
  {
    selectedContacts: $ReadOnlyArray<string>,
  },
> {
  state = {
    selectedContacts: [],
  };

  handleClickPropagation = (e: SyntheticEvent<>) => {
    e.stopPropagation();
  };

  handleAddButtonClick = () => {
    if (this.state.selectedContacts.length > 0) {
      this.props.onSave(this.state.selectedContacts);
    }
    this.props.onCancel();
  };

  handleSelectedContactsUpdate = (selectedContacts: $ReadOnlyArray<string>) => {
    this.setState({ selectedContacts });
  };

  render() {
    return (
      <Window size="medium" onHide={this.props.onCancel} onClick={this.handleClickPropagation}>
        <WindowContent>
          <Title>
            Add Contacts to <CompanyName>{this.props.companyName}</CompanyName>
          </Title>
          <ContactMultiselect
            updateSelectedContacts={this.handleSelectedContactsUpdate}
            selectedContacts={this.state.selectedContacts}
            eventId={this.props.eventId}
          />
          <Buttons>
            <Button minimal label="Cancel" onClick={this.props.onCancel} />
            <SubmitButton
              primary
              disabled={this.state.selectedContacts.length === 0}
              label={
                this.state.selectedContacts.length > 1
                  ? `Add ${this.state.selectedContacts.length} Contacts to Company`
                  : 'Add Contact to Company'
              }
              onClick={this.handleAddButtonClick}
            />
          </Buttons>
        </WindowContent>
      </Window>
    );
  }
}
