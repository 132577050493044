/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type FilterType } from 'components/FieldSettings/types';

import type {
  removeCustomFieldMutationVariables,
  removeCustomFieldMutationResponse,
} from './__generated__/removeCustomFieldMutation.graphql';

const mutation = graphql`
  mutation removeCustomFieldMutation($input: RemoveCustomFieldInput!) {
    removeCustomField(input: $input) {
      removedCustomFieldId
    }
  }
`;

export default function removeCustomField(
  orgId: string,
  customFieldId: string,
  filters: FilterType,
): Promise<removeCustomFieldMutationResponse> {
  const variables: removeCustomFieldMutationVariables = {
    input: {
      customFieldId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedCustomFieldId',
        parentId: orgId,
        connections: [
          {
            field: 'customFields',
            args: filters,
          },
        ],
      }),
      removeRecordFromStore({ deletedIdField: 'removedCustomFieldId' }),
    ),
  });
}
