/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import Tag from 'components/Contacts/Tag';

import CompanyIcon from './CompanyIcon';

import type { VendorSearchRow_vendor } from './__generated__/VendorSearchRow_vendor.graphql';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  line-height: 1.4;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
`;

const Main = styled.div`
  flex: 1 1 auto;
`;

const Name = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const StyledTag = styled(Tag)`
  margin-left: 7px;
`;

class VendorSearchRow extends React.PureComponent<{ vendor: VendorSearchRow_vendor }> {
  render() {
    const name = this.props.vendor.name;
    return (
      <Row>
        <CompanyIcon />
        <Main>
          <Name>
            {name}
            <StyledTag color="#C14F93" compact active>
              Vendor
            </StyledTag>
          </Name>
        </Main>
      </Row>
    );
  }
}

export default createFragmentContainer(
  VendorSearchRow,
  graphql`
    fragment VendorSearchRow_vendor on Vendor {
      name
    }
  `,
);
