/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

export type Payment = {|
  +actualDate?: ?string,
  +dueDate?: ?string,
  +amount?: ?number,
  +paymentMethod?: ?string,
  +paymentType?: ?string,
  +poNumber?: ?string,
  +reference?: ?string,
  +status?: ?string,
  +note?: ?string,
|};

const mutation = graphql`
  mutation updatePaymentMutation($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      expense {
        id
        actualAmount
        paidAmount

        event {
          totalActualAmount
          totalPaidAmount
        }
      }
      payment {
        id
        ...Payment_payment
      }
    }
  }
`;

export default function updatePayment(paymentId: string, payment: Payment) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        paymentId,
        ...payment,
      },
    },
    optimisticResponse: {
      updatePayment: {
        payment: { id: paymentId, ...payment },
      },
    },
  });
}
