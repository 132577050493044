/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Route, Switch } from 'react-router-dom';

import AuthorizableRoute from 'utils/routing/AuthorizableRoute';

import AllEvents from './AllEvents';
import EventImports from './EventImports';
import EventRequests from './EventRequests';
import headerTitles from './lib/headerTitles';
import AllTasks from './Tasks/AllTasks';
import MyTasks from './Tasks/MyTasks';

import { type DashboardRoutes_org } from './__generated__/DashboardRoutes_org.graphql';

type Props = { org: DashboardRoutes_org, pathPrefix: string, location: Location };

const DashboardRoutes = ({ org, pathPrefix, location }: Props) => {
  const title = headerTitles[location.pathname.split('/').pop()] || headerTitles.event_requests;
  return (
    <DocumentTitle title={title}>
      <Switch>
        <AuthorizableRoute
          authorized={org.viewerCanViewEventRequestSubmissions}
          path={`${pathPrefix}/event_requests/:submissionId?`}
          exact
          render={props => <EventRequests {...props} viewerCanUpdate={org.viewerCanUpdate} />}
        />
        <AuthorizableRoute
          authorized={org.viewerCanImport}
          path={`${pathPrefix}/event_imports`}
          component={EventImports}
        />
        <Route path={`${pathPrefix}/all_tasks`} component={AllTasks} />
        <Route path={`${pathPrefix}/my_tasks`} component={MyTasks} />
        <Route path={`${pathPrefix}/:list_id`} component={AllEvents} />
      </Switch>
    </DocumentTitle>
  );
};

export default createFragmentContainer(
  DashboardRoutes,
  graphql`
    fragment DashboardRoutes_org on Org {
      viewerCanImport
      viewerCanUpdate
      viewerCanViewEventRequestSubmissions
    }
  `,
);
