/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import BriefScheduleDay from './BriefScheduleDay';

import ScheduleItemContainer_event from './__generated__/ScheduleItemContainer_event.graphql';
import ScheduleItemContainer_schedule from './__generated__/ScheduleItemContainer_schedule.graphql';

const Container = styled.div`
  padding-bottom: 25px;
`;
const ScheduleItemContainer = (props: {
  schedule: ScheduleItemContainer_schedule,
  event: ScheduleItemContainer_event,
}) => {
  const {
    schedule: { scheduleDays },
    event,
  } = props;

  const savedScheduleDays = scheduleDays.edges.map(day => day.node);
  const orderedScheduleDays = sortBy(savedScheduleDays, 'date');

  return (
    <Container>
      {orderedScheduleDays.map(scheduleDay => (
        <BriefScheduleDay key={scheduleDay.date} event={event} scheduleDay={scheduleDay} />
      ))}
    </Container>
  );
};

export default createFragmentContainer(ScheduleItemContainer, {
  event: graphql`
    fragment ScheduleItemContainer_event on Event {
      ...BriefScheduleDay_event
    }
  `,
  schedule: graphql`
    fragment ScheduleItemContainer_schedule on Schedule {
      id
      scheduleDays {
        edges {
          node {
            id
            date
            ...BriefScheduleDay_scheduleDay
          }
        }
      }
    }
  `,
});
