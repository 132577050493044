/**
 * @flow
 * @relayHash a161fff9dd34aa5f26c0564da091a08c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventsFilterContainer_query$ref = any;
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventFilterItemQueryVariables = {|
  query: string,
  eventIds: $ReadOnlyArray<string>,
  listType?: ?EventListEnum,
|};
export type EventFilterItemQueryResponse = {|
  +query: {|
    +$fragmentRefs: EventsFilterContainer_query$ref
  |}
|};
export type EventFilterItemQuery = {|
  variables: EventFilterItemQueryVariables,
  response: EventFilterItemQueryResponse,
|};
*/


/*
query EventFilterItemQuery(
  $query: String!
  $eventIds: [ID!]!
  $listType: EventListEnum
) {
  query {
    ...EventsFilterContainer_query
  }
}

fragment EventsFilterContainer_query on Query {
  org {
    events(first: 10, query: $query, listType: $listType) {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
  selectedEvents: nodes(ids: $eventIds) {
    __typename
    id
    ... on Event {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "eventIds",
    "type": "[ID!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "listType",
    "type": "EventListEnum",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventFilterItemQuery",
  "id": null,
  "text": "query EventFilterItemQuery(\n  $query: String!\n  $eventIds: [ID!]!\n  $listType: EventListEnum\n) {\n  query {\n    ...EventsFilterContainer_query\n  }\n}\n\nfragment EventsFilterContainer_query on Query {\n  org {\n    events(first: 10, query: $query, listType: $listType) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n  selectedEvents: nodes(ids: $eventIds) {\n    __typename\n    id\n    ... on Event {\n      id\n      name\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventFilterItemQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EventsFilterContainer_query",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventFilterItemQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "events",
                "storageKey": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10,
                    "type": "Int"
                  },
                  {
                    "kind": "Variable",
                    "name": "listType",
                    "variableName": "listType",
                    "type": "EventListEnum"
                  },
                  {
                    "kind": "Variable",
                    "name": "query",
                    "variableName": "query",
                    "type": "String"
                  }
                ],
                "concreteType": "EventRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Event",
                        "plural": false,
                        "selections": [
                          v1,
                          v2
                        ]
                      }
                    ]
                  }
                ]
              },
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "selectedEvents",
            "name": "nodes",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "ids",
                "variableName": "eventIds",
                "type": "[ID!]!"
              }
            ],
            "concreteType": null,
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              v1,
              {
                "kind": "InlineFragment",
                "type": "Event",
                "selections": [
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f15a186fc989dbbcd29408d5db2c80fb';
module.exports = node;
