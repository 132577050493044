/**
 * @flow
 * @relayHash b5f714f73f156a62a99f1ea4b3333b0e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EventBriefAccessibility = "DISABLED" | "MEMBERS" | "PUBLIC" | "SSO";
export type SaveEventBriefInput = {
  clientMutationId?: ?string,
  eventId: string,
  accessibility: EventBriefAccessibility,
  contactIds: $ReadOnlyArray<string>,
  vendorIds: $ReadOnlyArray<string>,
  companyIds: $ReadOnlyArray<string>,
  noteIds: $ReadOnlyArray<string>,
  attachmentIds: $ReadOnlyArray<string>,
};
export type saveEventBriefMutationVariables = {|
  input: SaveEventBriefInput
|};
export type saveEventBriefMutationResponse = {|
  +saveEventBrief: ?{|
    +briefSettings: {|
      +id: string,
      +accessibility: EventBriefAccessibility,
    |}
  |}
|};
export type saveEventBriefMutation = {|
  variables: saveEventBriefMutationVariables,
  response: saveEventBriefMutationResponse,
|};
*/


/*
mutation saveEventBriefMutation(
  $input: SaveEventBriefInput!
) {
  saveEventBrief(input: $input) {
    briefSettings {
      id
      accessibility
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SaveEventBriefInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "saveEventBrief",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SaveEventBriefInput!"
      }
    ],
    "concreteType": "SaveEventBriefPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "briefSettings",
        "storageKey": null,
        "args": null,
        "concreteType": "EventBriefTemplate",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "accessibility",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "saveEventBriefMutation",
  "id": null,
  "text": "mutation saveEventBriefMutation(\n  $input: SaveEventBriefInput!\n) {\n  saveEventBrief(input: $input) {\n    briefSettings {\n      id\n      accessibility\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "saveEventBriefMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "saveEventBriefMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '25dd2ba7def8b81403efb3a8e0e6dc6b';
module.exports = node;
