/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import type { RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';
import TablePagination from 'components/budget/Table/TablePagination';
import NoResultsMessage from 'components/NoResultsMessage';

import colsConfig, { type DeliverablesCellPropsType, type TasksFiltersInputType } from '../columns';

import { type MyTasksPagination_me } from './__generated__/MyTasksPagination_me.graphql';
import { type MyTasksPagination_org } from './__generated__/MyTasksPagination_org.graphql';
import { type MyTasksPagination_totalCountDeliverables } from './__generated__/MyTasksPagination_totalCountDeliverables.graphql';

type DeliverableType = $PropertyType<
  $ElementType<$PropertyType<$PropertyType<MyTasksPagination_me, 'deliverables'>, 'edges'>, 0>,
  'node',
>;

const MyTasksContainer = styled.div`
  width: 100%;
`;

type Props = {
  org: MyTasksPagination_org,
  me: MyTasksPagination_me,
  location: Location,
  filters: TasksFiltersInputType,
  filtered: boolean,
  totalCountDeliverables: ?MyTasksPagination_totalCountDeliverables,
  relay: RelayPaginationProp,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  setColumnWidthUpdater: (updateColumnWidths: () => void) => void,
};

type State = {
  refetching: boolean,
};

class MyTasksPagination extends React.Component<Props, State> {
  state = {
    refetching: false,
  };

  cellProps = (deliverable: DeliverableType): DeliverablesCellPropsType<DeliverableType> => {
    const { location, filters, org, me } = this.props;
    return {
      deliverable,
      org,
      me,
      location,
      filters,
    };
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  keyExtractor = (deliverable: DeliverableType) => {
    return deliverable.id;
  };

  render() {
    const {
      totalCountDeliverables,
      sort,
      onChangeSort,
      filtered,
      me,
      setColumnWidthUpdater,
    } = this.props;
    const optimisticDeliverablesEdges = me ? me.deliverables.edges.filter(Boolean) : [];

    return (
      <MyTasksContainer>
        {(!me || optimisticDeliverablesEdges.length !== 0) && (
          <Table
            data={me != null ? me.deliverables.edges.map(({ node }) => node) : null}
            columns={colsConfig}
            sort={sort}
            onChangeSort={onChangeSort}
            cellProps={this.cellProps}
            keyExtractor={this.keyExtractor}
            setColumnWidthUpdater={setColumnWidthUpdater}
          />
        )}

        {me &&
          optimisticDeliverablesEdges.length === 0 &&
          (filtered ? (
            <EmptyView message="No Tasks match filters" icon={<NoResult />} />
          ) : (
            <NoResultsMessage iconName="tasks" message="Tasks not found" />
          ))}
        {me && optimisticDeliverablesEdges.length !== 0 && (
          <TablePagination
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            currentCount={optimisticDeliverablesEdges ? optimisticDeliverablesEdges.length : null}
            totalCount={
              totalCountDeliverables ? totalCountDeliverables.deliverablesCount.totalCount : null
            }
            entityName="Tasks"
          />
        )}
      </MyTasksContainer>
    );
  }
}
export default createPaginationContainer(
  MyTasksPagination,
  {
    me: graphql`
      fragment MyTasksPagination_me on User {
        id
        ...DeliverableActionCell_me
        ...DeliverableAssignees_me
        deliverables(first: $count, after: $cursor, filters: $filters)
          @connection(key: "MyTasksPagination_deliverables", filters: []) {
          edges {
            node {
              id
              ...DeliverableTags_deliverable
              ...DeliverableTitle_deliverable
              ...DeliverableStatus_deliverable
              ...DeliverableEventName_deliverable
              ...DeliverableDueDate_deliverable
              ...DeliverableAssignees_deliverable
              ...DeliverableActionCell_deliverable
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    org: graphql`
      fragment MyTasksPagination_org on Org {
        ...DeliverableTags_org
        ...DeliverableTitle_org
        ...DeliverableActionCell_org
      }
    `,
    totalCountDeliverables: graphql`
      fragment MyTasksPagination_totalCountDeliverables on User {
        deliverablesCount: deliverables(filters: $filters) {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.me && props.me.deliverables;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        count,
        cursor,
      };
    },
    query: graphql`
      query MyTasksPaginationQuery($count: Int!, $cursor: String, $filters: DeliverableFilters!) {
        me {
          ...MyTasksPagination_me
        }
      }
    `,
  },
);
