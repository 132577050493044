/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Avatar from 'components/material/Avatar';

const Options = styled.div`
  position: fixed;
  z-index: 10000;
  background: ${props => props.theme.contentBackgroundColor};
  border: 1px solid ${props => props.theme.borderColor};
  margin-top: 20px;
  width: 170px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
`;

const Option = styled.div`
  padding: 5px 7px;
  display: flex;
  color: ${props => props.theme.rowPrimaryTextColor};
  align-items: center;
  cursor: pointer;
  ${props => props.focused && `background: ${props.theme.hoverRowColor};`};
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
`;

const UserName = styled.div`
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  flex: 1 1 auto;
  font-size: 13px;
`;

export default class MentionsOverlay extends React.PureComponent<{
  position: { top: number, left: number },
  users: $ReadOnlyArray<{
    +id: string,
    +firstName: string,
    +lastName: string,
    +avatar: ?string,
    +email?: string,
  }>,
  focus: number,
  onFocusChange: (index: number) => void,
  onSelect: (e: SyntheticEvent<HTMLDivElement>) => string,
}> {
  render() {
    if (this.props.users.length === 0) return null;

    return (
      <Options style={{ top: this.props.position.top, left: this.props.position.left }}>
        {this.props.users.map((user, index) => (
          <Option
            key={user.id}
            onMouseEnter={() => this.props.onFocusChange(index)}
            focused={this.props.focus === index}
            onMouseDown={this.props.onSelect}
          >
            <Avatar profile={user} size={13} />
            <UserName>{user.firstName}</UserName>
          </Option>
        ))}
      </Options>
    );
  }
}
