/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import RequestSubmissionRequestedDate from 'components/EventRequestForm/RequestSubmissionList/columns/RequestSubmissionRequestedDate';

import EventRequestsItemRequestedDate_eventRequestSubmission from './__generated__/EventRequestsItemRequestedDate_eventRequestSubmission.graphql';

type Props = {
  eventRequestSubmission: EventRequestsItemRequestedDate_eventRequestSubmission,
  tz: string,
};

const EventRequestsItemRequestedDate = ({ eventRequestSubmission, tz }: Props) => {
  return <RequestSubmissionRequestedDate requestSubmission={eventRequestSubmission} tz={tz} />;
};

export default createFragmentContainer(
  EventRequestsItemRequestedDate,
  graphql`
    fragment EventRequestsItemRequestedDate_eventRequestSubmission on EventRequestSubmission {
      ...RequestSubmissionRequestedDate_requestSubmission
    }
  `,
);
