/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment';

import downloadedChecklist from 'utils/analytics/events/downloadedChecklist';
import currentOrigin from 'utils/currentOrigin';

import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ExternalCalendarExport from 'components/ExternalCalendarExport';
import ShareDropdown from 'components/ShareDropdown';

import { type TasksHeaderShare_event } from './__generated__/TasksHeaderShare_event.graphql';

class TasksHeaderShare extends React.PureComponent<
  {
    userEmail: string,
    event: TasksHeaderShare_event,
  },
  {
    showCsvDownloadOverlay: boolean,
    showCalendarExportWindow: boolean,
  },
> {
  state = {
    showCalendarExportWindow: false,
    showCsvDownloadOverlay: false,
  };

  handleShowAddCalendarWindow = () => {
    this.setState({ showCalendarExportWindow: true });
  };

  handleHideAddCalendarWindow = () => {
    this.setState({ showCalendarExportWindow: false });
  };

  handleDownloadCSV = () => {
    const { event } = this.props;
    this.setState({ showCsvDownloadOverlay: true });
    downloadedChecklist({
      eventId: event.dbId,
      eventName: event.name,
      teamId: event.team.dbId,
      teamName: event.team.name,
      format: 'csv',
    });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCsvDownloadOverlay: false });
  };

  renderCalendarExportWindow = () => {
    const event = this.props.event;
    const icsUrl = `${currentOrigin()}/v1/events/${event.token}/tasks/ical`;
    return (
      <ExternalCalendarExport
        listTitle={`${event.name} tasks`}
        icsUrl={icsUrl}
        onHide={this.handleHideAddCalendarWindow}
      />
    );
  };

  render() {
    const { event, userEmail } = this.props;
    const { showCalendarExportWindow, showCsvDownloadOverlay } = this.state;
    const dateFormat = moment().format('YYYY-MM-DD');

    return (
      <>
        <ShareDropdown
          options={[
            {
              label: 'Sync with external calendar',
              icon: 'calendar',
              onClick: this.handleShowAddCalendarWindow,
            },
            {
              label: 'Download Checklist (CSV)',
              icon: 'file-excel-o',
              onClick: this.handleDownloadCSV,
            },
          ]}
        />
        {showCalendarExportWindow && this.renderCalendarExportWindow()}
        {showCsvDownloadOverlay && (
          <DownloadOverlayWithEmail
            downloadLink={`/v1/export/${event.slug}/event_tasks_csv`}
            fileName={`${event.name} Tasks  ${dateFormat}.csv`}
            onHide={this.handleCsvDownloadEnd}
            email={userEmail}
            exportable="event_tasks_csv"
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(
  TasksHeaderShare,
  graphql`
    fragment TasksHeaderShare_event on Event {
      dbId
      token
      slug
      name
      team {
        dbId
        name
      }
    }
  `,
);
