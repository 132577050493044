/* @flow */
import type { FieldType } from 'utils/customization/types';
import getCustomFieldFilterConverterByKind from 'utils/filters/getCustomFieldFilterConverterByKind';
import {
  numberRangeToStringParam,
  stringArrayToStringParam,
  stringToStringParam,
} from 'utils/routing/stringifyQueryParamValues';

import { fieldFilterMap } from 'components/AllEvents/lib/renderEventFilter';

import { suggestedFields } from '../eventsTableColumnSettings';

export default function getEventFilterConverters(
  customFields: $ReadOnlyArray<FieldType>,
  hasGroupFilters?: boolean,
) {
  return customFields.reduce(
    (converter, customField) => {
      if (customField.fieldName != null && !suggestedFields.includes(customField.fieldName)) {
        const filterName =
          fieldFilterMap[customField.fieldName] && fieldFilterMap[customField.fieldName].filter;
        switch (filterName) {
          case 'date':
          case 'updatedAt':
          case 'createdAt':
          case 'requestedDate':
            return { ...converter, [filterName]: stringToStringParam };
          case 'registeredContactsTotal':
          case 'attendedContactsTotal':
          case 'contactsCount':
          case 'opportunitiesNumber':
          case 'opportunitiesAmount':
            return { ...converter, [filterName]: numberRangeToStringParam };
          case 'requesterUserIds':
            return {
              ...converter,
              requesterUserIds: stringArrayToStringParam,
              requesterContactIds: stringArrayToStringParam,
            };
          case false:
            return converter;
          default:
            return { ...converter, [filterName]: stringArrayToStringParam };
        }
      }
      return { ...converter, ...getCustomFieldFilterConverterByKind(customField) };
    },
    {
      ...(hasGroupFilters
        ? {
            group1: stringToStringParam,
            group2: stringToStringParam,
            customGroup1: stringToStringParam,
            customGroup2: stringToStringParam,
          }
        : {}),
    },
  );
}
