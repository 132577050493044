/**
 * @flow
 * @relayHash 6a9b2f34f3329890ea39131eae93808e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContactsSearchPagination_searchResults$ref = any;
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type ContactsSearchPaginationQueryVariables = {|
  search: Search,
  count: number,
  cursor?: ?string,
|};
export type ContactsSearchPaginationQueryResponse = {|
  +$fragmentRefs: ContactsSearchPagination_searchResults$ref
|};
export type ContactsSearchPaginationQuery = {|
  variables: ContactsSearchPaginationQueryVariables,
  response: ContactsSearchPaginationQueryResponse,
|};
*/


/*
query ContactsSearchPaginationQuery(
  $search: Search!
  $count: Int!
  $cursor: String
) {
  ...ContactsSearchPagination_searchResults
}

fragment ContactsSearchPagination_searchResults on Query {
  contacts: searchContacts(search: $search, after: $cursor, first: $count) {
    edges {
      node {
        slug
        ...ContactSearchResult_contact
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ContactSearchResult_contact on ContactSearchResult {
  slug
  firstName
  lastName
  email
  company
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "Search!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search",
    "type": "Search!"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ContactsSearchPaginationQuery",
  "id": null,
  "text": "query ContactsSearchPaginationQuery(\n  $search: Search!\n  $count: Int!\n  $cursor: String\n) {\n  ...ContactsSearchPagination_searchResults\n}\n\nfragment ContactsSearchPagination_searchResults on Query {\n  contacts: searchContacts(search: $search, after: $cursor, first: $count) {\n    edges {\n      node {\n        slug\n        ...ContactSearchResult_contact\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ContactSearchResult_contact on ContactSearchResult {\n  slug\n  firstName\n  lastName\n  email\n  company\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ContactsSearchPaginationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ContactsSearchPagination_searchResults",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContactsSearchPaginationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "contacts",
        "name": "searchContacts",
        "storageKey": null,
        "args": v1,
        "concreteType": "ContactSearchResultRequiredConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactSearchResultRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactSearchResult",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "slug",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "company",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "avatar",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": "contacts",
        "name": "searchContacts",
        "args": v1,
        "handle": "connection",
        "key": "ContactsTablePagination_contacts",
        "filters": []
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3097e51b25c5e8ca964bbedac27ef04a';
module.exports = node;
