/* @flow */
import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  margin-top: 30px;
`;

const Header = styled.div`
  padding-bottom: 5px;
  font-size: 14px;
  color: #666666;
`;

const TopComponent = styled.div`
  font-weight: 500;
  color: red;
  font-size: 14px;
`;

const SubComponent = styled.div`
  font-size: 12px;
`;

type ParsedStackLine = {|
  component: string,
  parent: string,
|};

function splitLine(line: string): ParsedStackLine {
  const matches = /^in ([^ ]+)(?: \(created by ([^ ]+)\))?$/.exec(line);
  const component: string = (matches && matches[1]) || 'Unknown';
  const parent: string = (matches && matches[2]) || component;
  return {
    component,
    parent,
  };
}

function stripStackLine(line: string): string {
  return line.replace(/\(.*\)/, '').replace(/^at/, '');
}

export default class ComponentStack extends React.PureComponent<{
  componentStack: string,
  errorStack?: ?string,
}> {
  render() {
    const componentLines: Array<ParsedStackLine> = this.props.componentStack
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0)
      .map(line => splitLine(line));

    const stackLines = (this.props.errorStack || '')
      .split('\n')
      .map(line => stripStackLine(line.trim()))
      .filter(line => line.length > 0);

    return (
      <div>
        <Section>
          <Header>Error Stack</Header>
          <TopComponent>{stackLines[1]}</TopComponent>
          {stackLines.slice(2, 5).map((line, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <SubComponent key={i}>{line}</SubComponent>
          ))}
        </Section>
        <Section>
          <Header>Component Stack</Header>
          <TopComponent>{componentLines && componentLines[0].component}</TopComponent>
          {componentLines.slice(1, 4).map((line, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <SubComponent key={i}>{line.component}</SubComponent>
          ))}
        </Section>
      </div>
    );
  }
}
