/* @flow */
import track from '../../track';

type DownloadedBriefDetails = {|
  +eventId: number,
  +eventName: string,
  +teamId: number,
  +teamName: string,
  +format: string,
  +fromWindow: 'event dashboard' | 'event brief' | 'shared event calendar',
|};

const downloadedBrief = (details: DownloadedBriefDetails) => {
  track('downloaded_brief', {
    event_id: details.eventId,
    event_name: details.eventName,
    team_id: details.teamId,
    team_name: details.teamName,
    format: details.format,
    from_window: details.fromWindow,
  });
};

export default downloadedBrief;
