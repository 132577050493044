/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';
import { type Sort } from 'components/material/SortableHeader';

import columns, { type ContactCellPropsType } from './columns';

import type { CompanyContactsList_company } from './__generated__/CompanyContactsList_company.graphql';
import type { CompanyContactsList_contacts } from './__generated__/CompanyContactsList_contacts.graphql';
import type { CompanyContactsList_org } from './__generated__/CompanyContactsList_org.graphql';

type CompanyContactType = $ElementType<CompanyContactsList_contacts, 0>;

type Props = {
  contacts: CompanyContactsList_contacts,
  company: CompanyContactsList_company,
  org: CompanyContactsList_org,
  sort: Sort,
  onChangeSort: Sort => void,
  location: Location,
};

class CompanyContactsList extends React.Component<Props> {
  cellProps = (
    contact: CompanyContactType,
  ): ContactCellPropsType<CompanyContactType, CompanyContactsList_company> => {
    const { company, location } = this.props;
    return {
      contact,
      company,
      location,
    };
  };

  keyExtractor = (companyContact: CompanyContactType) => companyContact.id;

  configureColumns = () => {
    const customFields = this.props.org.customFields.edges.map(({ node }) => node);
    return columns.map(column => {
      const sortKeyLowered = column.sortKey ? column.sortKey.toLowerCase() : '';
      const customField = customFields.find(field =>
        [sortKeyLowered, `${sortKeyLowered}_id`].some(f => f === field.fieldName),
      );
      if (customField) {
        return {
          ...column,
          title: customField.label,
        };
      }
      return column;
    });
  };

  render() {
    const { sort, onChangeSort, contacts } = this.props;
    const configuredColumns = this.configureColumns();
    if (contacts.length === 0) {
      return <EmptyView message="Add the first contact for this company." icon={<NoResult />} />;
    }

    return (
      <Table
        data={contacts}
        columns={configuredColumns}
        sort={sort}
        onChangeSort={onChangeSort}
        cellProps={this.cellProps}
        keyExtractor={this.keyExtractor}
      />
    );
  }
}

export default createFragmentContainer(CompanyContactsList, {
  contacts: graphql`
    fragment CompanyContactsList_contacts on Contact @relay(plural: true) {
      id
      ...CompanyContactEmail_contact
      ...CompanyContactName_contact
      ...CompanyContactTitle_contact
      ...CompanyContactActionsColumn_contact
    }
  `,
  company: graphql`
    fragment CompanyContactsList_company on Company {
      id
      ...CompanyContactActionsColumn_company
    }
  `,
  org: graphql`
    fragment CompanyContactsList_org on Org {
      id
      dbId
      customFields(customizableType: [EVENTCONTACT]) {
        edges {
          node {
            id
            fieldName
            label
            required
            order
            kind
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
});
