/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import removeEventRequestForm from 'graph/mutations/eventRequest/removeEventRequestForm';
import updateEventRequestFormStatus from 'graph/mutations/eventRequest/updateEventRequestFormStatus';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { Publish, Unpublish } from 'images';
import ConfirmationWindow from 'components/ConfirmationWindow';
import ShareDropdown, { Icon as ActionsIcon } from 'components/ShareDropdown';

import type { EventRequestFormsActionsColumn_requestForm } from './__generated__/EventRequestFormsActionsColumn_requestForm.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export { ActionsIcon };

class EventRequestFormsActionsColumn extends React.PureComponent<
  {
    requestForm: EventRequestFormsActionsColumn_requestForm,
    orgId: string,
    history: RouterHistory,
  },
  {
    showConfirmation: boolean,
  },
> {
  state = {
    showConfirmation: false,
  };

  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleRemove = () => {
    removeEventRequestForm({
      orgId: this.props.orgId,
      requestFormId: this.props.requestForm.id,
    }).catch(showModernMutationError);
  };

  handlePublish = () => {
    updateEventRequestFormStatus(this.props.requestForm.id, !this.props.requestForm.isDraft).catch(
      showModernMutationError,
    );
  };

  handleEditForm = () => {
    this.props.history.push(`/settings/requests/${this.props.requestForm.id}`);
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    const actions = [
      {
        label: 'Edit Form',
        icon: 'pencil',
        onClick: this.handleEditForm,
      },
      {
        label: 'Delete',
        icon: 'trash',
        onClick: this.handleShowConfirmation,
      },
      {
        label: this.props.requestForm.isDraft ? 'Publish' : 'Unpublish',
        svg: this.props.requestForm.isDraft ? Publish : Unpublish,
        onClick: this.handlePublish,
      },
    ];
    return (
      <Container onClick={this.stopPropagation}>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={actions}
          overlayContainer={document.body}
        />
        {this.state.showConfirmation && (
          <ConfirmationWindow
            actionLabel="Delete"
            onHide={this.handleHideConfirmation}
            onConfirm={this.handleRemove}
            message="Once you delete a Request Form, it's gone for good. Note that Events created from the Request Form will not be deleted."
          />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  withRouter(EventRequestFormsActionsColumn),
  graphql`
    fragment EventRequestFormsActionsColumn_requestForm on EventRequestForm {
      id
      isDraft
    }
  `,
);
