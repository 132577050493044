/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import Content from '../../layout';
import CompanyPage from './CompanyPage';

import type { CompanyQueryResponse } from './__generated__/CompanyQuery.graphql';

const query = graphql`
  query CompanyQuery($id: ID!, $includeEvent: Boolean!, $eventSlug: String) {
    org {
      ...CompanyPage_org
    }
    company: node(id: $id) {
      ...CompanyPage_company
    }
    ... @include(if: $includeEvent) {
      event(slug: $eventSlug) {
        ...CompanyPage_event
      }
    }
    user: me {
      ...CompanyPage_user
    }
  }
`;

const StyledContent = styled(Content)`
  padding: 0;
`;

export default class CompanyRoot extends React.Component<{
  match: Match,
  history: History,
  location: Location,
}> {
  render() {
    return (
      <StyledContent>
        <DefaultQueryRenderer
          query={query}
          variables={{
            id: this.props.match.params.company_id,
            eventSlug: this.props.match.params.event_slug,
            includeEvent: this.props.match.params.event_slug != null,
          }}
          renderSuccess={(props: CompanyQueryResponse) =>
            props.company &&
            props.user && (
              <CompanyPage
                org={props.org}
                event={props.event || null}
                company={props.company}
                user={props.user}
                location={this.props.location}
                history={this.props.history}
                match={this.props.match}
              />
            )
          }
        />
      </StyledContent>
    );
  }
}
