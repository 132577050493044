/**
 * @flow
 * @relayHash 4c5b4b47a35a23499803b0bfa8fadc16
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TaskStatus = "COMPLETED" | "OPEN";
export type UpdateDeliverablesStatusInput = {
  clientMutationId?: ?string,
  deliverableIds: $ReadOnlyArray<string>,
  status: TaskStatus,
  fromWindow?: ?string,
};
export type updateTasksStatusMutationVariables = {|
  input: UpdateDeliverablesStatusInput
|};
export type updateTasksStatusMutationResponse = {|
  +updateDeliverablesStatus: ?{|
    +event: {|
      +progress: ?number
    |},
    +deliverables: $ReadOnlyArray<{|
      +id: string,
      +status: TaskStatus,
      +name: string,
      +dbId: number,
      +dependents: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +blockedByOtherTasks: boolean
          |}
        |}>
      |},
      +subtasksCount: number,
      +completedSubtasksCount: number,
    |}>,
  |}
|};
export type updateTasksStatusMutation = {|
  variables: updateTasksStatusMutationVariables,
  response: updateTasksStatusMutationResponse,
|};
*/


/*
mutation updateTasksStatusMutation(
  $input: UpdateDeliverablesStatusInput!
) {
  updateDeliverablesStatus(input: $input) {
    event {
      progress
      id
    }
    deliverables {
      id
      status
      name
      dbId
      dependents {
        edges {
          node {
            blockedByOtherTasks
            id
          }
        }
      }
      subtasksCount
      completedSubtasksCount
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDeliverablesStatusInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateDeliverablesStatusInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "progress",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "blockedByOtherTasks",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtasksCount",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completedSubtasksCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateTasksStatusMutation",
  "id": null,
  "text": "mutation updateTasksStatusMutation(\n  $input: UpdateDeliverablesStatusInput!\n) {\n  updateDeliverablesStatus(input: $input) {\n    event {\n      progress\n      id\n    }\n    deliverables {\n      id\n      status\n      name\n      dbId\n      dependents {\n        edges {\n          node {\n            blockedByOtherTasks\n            id\n          }\n        }\n      }\n      subtasksCount\n      completedSubtasksCount\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateTasksStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDeliverablesStatus",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateDeliverablesStatusPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "dependents",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Deliverable",
                        "plural": false,
                        "selections": [
                          v7
                        ]
                      }
                    ]
                  }
                ]
              },
              v8,
              v9
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateTasksStatusMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateDeliverablesStatus",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateDeliverablesStatusPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverables",
            "storageKey": null,
            "args": null,
            "concreteType": "Deliverable",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "dependents",
                "storageKey": null,
                "args": null,
                "concreteType": "DeliverableRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DeliverableRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Deliverable",
                        "plural": false,
                        "selections": [
                          v7,
                          v3
                        ]
                      }
                    ]
                  }
                ]
              },
              v8,
              v9
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '21c9e1799918d97ce9bec2ac390ef0fc';
module.exports = node;
