import './styles.styl';

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';

import { getFilters } from 'utils/filters';
import withQueryParams from 'utils/routing/withQueryParams';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import SchedulesPage from './SchedulesPage';
import SchedulesMulti from './SchedulesMulti';

const query = graphql`
  query SchedulesQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      dbId
      team {
        dbId
      }
      ...SchedulesPage_event
      ...SchedulesMulti_event
    }
    user: me {
      ...SchedulesPage_user
    }
  }
`;

class Schedules extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        event_slug: PropTypes.string,
        schedule_slug: PropTypes.string,
      }).isRequired,
    }).isRequired,
    queryParams: PropTypes.shape({
      active: PropTypes.string,
      attendee: PropTypes.string,
    }).isRequired,
    history: PropTypes.object.isRequired,
  };

  render() {
    const schedule_slug = this.props.match.params.schedule_slug;
    const ComponentToRender =
      !schedule_slug && 'active' in this.props.queryParams ? SchedulesMulti : SchedulesPage;

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ eventSlug: this.props.match.params.event_slug }}
        renderSuccess={response => (
          <ComponentToRender
            event={response.event}
            user={response.user}
            eventId={response.event.dbId}
            teamId={response.event.team.dbId}
            active={(this.props.queryParams.active || '').split(',')}
            filters={getFilters(
              {
                member: 'string',
                attendee: 'string',
              },
              this.props.queryParams,
            )}
            history={this.props.history}
            queryParams={this.props.queryParams}
            scheduleSlug={schedule_slug}
            defaultAttendee={this.props.queryParams.attendee}
          />
        )}
      />
    );
  }
}

export default withQueryParams(Schedules);
