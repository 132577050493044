/**
 * @flow
 * @relayHash 8aa7163ef3fe108f3c12c48463b69d2d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AttachmentsBriefRootQueryVariables = {|
  eventSlug: string,
  attachmentIds: $ReadOnlyArray<string>,
  listToken?: ?string,
  sharedView: boolean,
|};
export type AttachmentsBriefRootQueryResponse = {|
  +event: ?{|
    +briefSettings?: {|
      +attachments: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +filename: ?string,
            +fileurl: ?string,
            +filetype: ?string,
          |}
        |}>
      |}
    |},
    +allAttachments?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +filename: ?string,
          +fileurl: ?string,
          +filetype: ?string,
        |}
      |}>
    |},
  |}
|};
export type AttachmentsBriefRootQuery = {|
  variables: AttachmentsBriefRootQueryVariables,
  response: AttachmentsBriefRootQueryResponse,
|};
*/


/*
query AttachmentsBriefRootQuery(
  $eventSlug: String!
  $attachmentIds: [ID!]!
  $listToken: String
  $sharedView: Boolean!
) {
  event(slug: $eventSlug, listToken: $listToken) {
    briefSettings @include(if: $sharedView) {
      attachments {
        edges {
          node {
            id
            filename
            fileurl
            filetype
          }
        }
      }
      id
    }
    allAttachments(attachmentIds: $attachmentIds) @skip(if: $sharedView) {
      edges {
        node {
          id
          filename
          fileurl
          filetype
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "attachmentIds",
    "type": "[ID!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "listToken",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sharedView",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "listToken",
    "variableName": "listToken",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "AttachmentRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Attachment",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "filename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileurl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "filetype",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attachments",
  "storageKey": null,
  "args": null,
  "concreteType": "AttachmentRequiredConnection",
  "plural": false,
  "selections": v3
},
v5 = {
  "kind": "Condition",
  "passingValue": false,
  "condition": "sharedView",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "allAttachments",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "attachmentIds",
          "variableName": "attachmentIds",
          "type": "[ID!]"
        }
      ],
      "concreteType": "AttachmentRequiredConnection",
      "plural": false,
      "selections": v3
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AttachmentsBriefRootQuery",
  "id": null,
  "text": "query AttachmentsBriefRootQuery(\n  $eventSlug: String!\n  $attachmentIds: [ID!]!\n  $listToken: String\n  $sharedView: Boolean!\n) {\n  event(slug: $eventSlug, listToken: $listToken) {\n    briefSettings @include(if: $sharedView) {\n      attachments {\n        edges {\n          node {\n            id\n            filename\n            fileurl\n            filetype\n          }\n        }\n      }\n      id\n    }\n    allAttachments(attachmentIds: $attachmentIds) @skip(if: $sharedView) {\n      edges {\n        node {\n          id\n          filename\n          fileurl\n          filetype\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AttachmentsBriefRootQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBriefTemplate",
                "plural": false,
                "selections": [
                  v4
                ]
              }
            ]
          },
          v5
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AttachmentsBriefRootQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBriefTemplate",
                "plural": false,
                "selections": [
                  v4,
                  v2
                ]
              }
            ]
          },
          v5
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d45b262face7d5e8d1c1164f256b04d';
module.exports = node;
