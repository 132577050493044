/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import SectionContent from '../components/SectionContent';
import SectionHeader from '../components/SectionHeader';
import UserContainer from '../components/UserContainer';
import UserContainerInList from '../components/UserContainerInList';
import UserField from '../components/UserField';

import { type EventStaffBrief_event } from './__generated__/EventStaffBrief_event.graphql';
import { type EventStaffBrief_template } from './__generated__/EventStaffBrief_template.graphql';

const StyledUserContainer = styled(UserContainer)`
  padding-bottom: 40px;
`;

const EventStaffBrief = (props: {
  event: EventStaffBrief_event,
  name: string,
  template: EventStaffBrief_template,
  primaryColor: string,
}) => {
  const {
    name,
    template: { eventStaffFields },
    primaryColor,
    event: { staffers },
    event,
  } = props;
  const staff = staffers.edges.map(({ node: { onsite, user } }) => ({ ...user, onsite }));
  const staffLength = staff.length;
  const isVisible = eventStaffFields.some(es => es.enabled);
  return isVisible && staffLength > 0 ? (
    <React.Fragment>
      <SectionHeader
        primaryColor={primaryColor}
        name={name}
        anchor="event_staff"
        total={staffLength}
      />
      <SectionContent>
        <StyledUserContainer>
          {staff.map(s => {
            return (
              <UserContainerInList key={s.id}>
                <UserField event={event} user={s} avatarRadius={20} />
              </UserContainerInList>
            );
          })}
        </StyledUserContainer>
      </SectionContent>
    </React.Fragment>
  ) : null;
};

export default createFragmentContainer(EventStaffBrief, {
  event: graphql`
    fragment EventStaffBrief_event on Event {
      ...UserField_event
      staffers {
        edges {
          node {
            onsite @include(if: $includeStaffOnsite)
            user {
              id
              firstName @include(if: $includeStaffName)
              lastName @include(if: $includeStaffName)
              email @include(if: $includeStaffEmail)
              phone @include(if: $includeStaffPhone)
              officePhone @include(if: $includeStaffOfficePhone)
              title @include(if: $includeStaffTitle)
              company @include(if: $includeStaffCompany)
              ...BriefUserAvatar_user @include(if: $includeStaffAvatar)
            }
          }
        }
      }
    }
  `,
  template: graphql`
    fragment EventStaffBrief_template on BriefTemplate {
      eventStaffFields {
        name
        enabled
      }
    }
  `,
});
