/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import hash from 'string-hash';

type StyledProps = {
  size: number,
};

const AvatarImage = styled.div`
  height: ${(props: StyledProps) => props.size * 2}px;
  width: ${(props: StyledProps) => props.size * 2}px;
  font-size: ${(props: StyledProps) => props.size}px;
  line-height: ${(props: StyledProps) => props.size * 2}px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: ${props => props.radius || '50%'};
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export default class Avatar extends React.PureComponent<{
  profile: {
    +id?: string,
    +avatar: ?string,
    +firstName: string,
    +lastName: string,
    +email?: string,
  },
  size: number,
  radius?: string,
  className?: string,
  onMouseOver?: (e: Event) => void,
  onMouseOut?: (e: Event) => void,
  onBlur?: (e: Event) => void,
  onFocus?: (e: Event) => void,
  children?: React.Node,
}> {
  static defaultProps = {
    size: 13,
  };

  render() {
    const { avatar, firstName, lastName, email, id } = this.props.profile;

    const initial =
      [firstName[0], lastName[0]].filter(x => x).join('') ||
      (typeof email === 'string' ? email : ' ')[0];
    const bgStyle = {
      backgroundColor: `hsl(${hash(id || firstName) % 360}, 100%, 35%)`,
    };
    return (
      <div
        className={this.props.className}
        onMouseOver={this.props.onMouseOver}
        onMouseOut={this.props.onMouseOut}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
      >
        {avatar ? (
          <AvatarImage radius={this.props.radius} size={this.props.size}>
            <img src={avatar} alt={initial} />
          </AvatarImage>
        ) : (
          <AvatarImage radius={this.props.radius} size={this.props.size} style={bgStyle}>
            {initial}
          </AvatarImage>
        )}
        {this.props.children}
      </div>
    );
  }
}
