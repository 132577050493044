/* @flow */
import { type Location } from 'react-router-dom';

import CompanyContactActionsColumn from './CompanyContactActionsColumn';
import CompanyContactEmail from './CompanyContactEmail';
import CompanyContactName from './CompanyContactName';
import CompanyContactTitle from './CompanyContactTitle';

export type ContactCellPropsType<CompanyContact: {}, Company: {}> = {|
  contact: CompanyContact,
  company: Company,
  location: Location,
|};

const columns = [
  {
    title: 'Name',
    sortKey: 'NAME',
    component: CompanyContactName,
    grow: 1,
  },
  {
    title: 'Title',
    sortKey: 'TITLE',
    component: CompanyContactTitle,
  },
  {
    title: 'Email',
    sortKey: 'EMAIL',
    component: CompanyContactEmail,
  },
  {
    title: '',
    component: CompanyContactActionsColumn,
    type: 'action',
  },
];

export default columns;
