/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateColumnComponentMutationVariables,
  UpdateColumnComponentInput,
} from './__generated__/updateColumnComponentMutation.graphql';

export type updateColumnComponentPropertyType = $Shape<UpdateColumnComponentInput>;

const mutation = graphql`
  mutation updateColumnComponentMutation($input: UpdateColumnComponentInput!) {
    updateRegistrationFormColumnComponent(input: $input) {
      pageComponent {
        id
        ...RowComponent_selectedPageComponent
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateEventNameComponent(properties: updateColumnComponentPropertyType) {
  const variables: updateColumnComponentMutationVariables = {
    input: {
      ...properties,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
