/* @flow */

import type { FieldType } from 'utils/customization/types';
import capitalize from 'utils/string/capitalize';

import { suggestedFields } from 'components/AllEvents/eventsTableColumnSettings';

export const customFieldIncludes = {
  includeCustomizableText: false,
  includeCustomizableTextarea: false,
  includeCustomizableLink: false,
  includeCustomizableDate: false,
  includeCustomizableBoolean: false,
  includeCustomizableNumber: false,
  includeCustomizableCurrency: false,
  includeCustomizableSelect: false,
  includeCustomizableMultiselect: false,
  includeCustomizableUserSelect: false,
  includeCustomizableUserMultiselect: false,
};

export default function getTableIncludeProps(
  defaultFieldNames: $ReadOnlyArray<string>,
  fieldsConfiguration: $ReadOnlyArray<FieldType>,
  shownColumns: $ReadOnlyArray<string>,
): { [string]: boolean } {
  // Include default fields if field configuration is enabled and field is shown
  const defaultFieldIncludes = defaultFieldNames.reduce((includes, field) => {
    const fieldOption = fieldsConfiguration.find(
      config => config.fieldName && config.fieldName === field,
    );
    return {
      ...includes,
      [`include${capitalize(field)}`]: fieldOption != null && shownColumns.includes(field),
    };
  }, {});

  return {
    ...defaultFieldIncludes,
    ...customFieldIncludes,
    ...fieldsConfiguration.reduce((includes, field) => {
      // Default fields case is covered above
      if (field.fieldName && !suggestedFields.includes(field.fieldName)) return includes;

      // Include custom field if at least one field of given type is shown
      if (shownColumns.includes(field.id)) {
        return {
          ...includes,
          [`includeCustomizable${capitalize(field.kind)}`]: true,
        };
      }
      return includes;
    }, {}),
  };
}
