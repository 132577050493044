/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefHeader_event$ref = any;
type EventInfoBrief_event$ref = any;
type EventInfoBrief_template$ref = any;
type EventStaffBrief_event$ref = any;
type EventStaffBrief_template$ref = any;
type ScheduleBrief_event$ref = any;
type TasksBrief_event$ref = any;
type TasksBrief_template$ref = any;
export type EventBriefAccessibility = "DISABLED" | "MEMBERS" | "PUBLIC" | "SSO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefViewContainer_event$ref: FragmentReference;
export type BriefViewContainer_event = {|
  +id: string,
  +tz: string,
  +slug: string,
  +team: {|
    +primaryColor: string
  |},
  +staffers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +briefSettings: {|
    +accessibility: EventBriefAccessibility,
    +briefTemplate: {|
      +sections: $ReadOnlyArray<{|
        +name: string,
        +order: number,
        +enabled: boolean,
      |}>,
      +$fragmentRefs: EventInfoBrief_template$ref & EventStaffBrief_template$ref & TasksBrief_template$ref,
    |},
    +notes: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +attachments: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +contacts: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +vendors: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +companies: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
  |},
  +$fragmentRefs: BriefHeader_event$ref & EventInfoBrief_event$ref & EventStaffBrief_event$ref & ScheduleBrief_event$ref & TasksBrief_event$ref,
  +$refType: BriefViewContainer_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
];
return {
  "kind": "Fragment",
  "name": "BriefViewContainer_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeEventInfo",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventStaff",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeSchedules",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTasks",
      "type": "Boolean"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "primaryColor",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffers",
      "storageKey": null,
      "args": null,
      "concreteType": "StaffMembershipRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StaffMembershipRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StaffMembership",
              "plural": false,
              "selections": v1
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "briefSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "EventBriefTemplate",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "accessibility",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "briefTemplate",
          "storageKey": null,
          "args": null,
          "concreteType": "BriefTemplate",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "sections",
              "storageKey": null,
              "args": null,
              "concreteType": "SortableSection",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "enabled",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "EventInfoBrief_template",
              "args": null
            },
            {
              "kind": "FragmentSpread",
              "name": "EventStaffBrief_template",
              "args": null
            },
            {
              "kind": "FragmentSpread",
              "name": "TasksBrief_template",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "notes",
          "storageKey": null,
          "args": null,
          "concreteType": "EventNoteRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "EventNoteRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "EventNote",
                  "plural": false,
                  "selections": v1
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "attachments",
          "storageKey": null,
          "args": null,
          "concreteType": "AttachmentRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "AttachmentRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Attachment",
                  "plural": false,
                  "selections": v1
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contacts",
          "storageKey": null,
          "args": null,
          "concreteType": "ContactRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ContactRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Contact",
                  "plural": false,
                  "selections": v1
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "vendors",
          "storageKey": null,
          "args": null,
          "concreteType": "VendorRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "VendorRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Vendor",
                  "plural": false,
                  "selections": v1
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "companies",
          "storageKey": null,
          "args": null,
          "concreteType": "CompanyRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "CompanyRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Company",
                  "plural": false,
                  "selections": v1
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "BriefHeader_event",
      "args": null
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventInfo",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoBrief_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventStaff",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventStaffBrief_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeSchedules",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ScheduleBrief_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTasks",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "TasksBrief_event",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '434c3af79cdf46658e29870ec4bf3538';
module.exports = node;
