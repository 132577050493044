/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import formatDuration from 'utils/number/formatDuration';

import type { RegistrationTotals_event } from './__generated__/RegistrationTotals_event.graphql';

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 36px;
  background-color: #fff;
  overflow: hidden;
`;

const Label = styled.div`
  text-align: center;
  font-size: 17px;
  color: rgba(55, 57, 81, 0.7);
`;

const Value = styled.div`
  text-align: center;
  font-family: Roboto-Slab;
  font-size: 26px;
  font-weight: 500;
  color: #373951;
`;

const CardItem = styled.div`
  flex: 0 1 286px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 124px;
  margin: 0 20px;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 11px 6px -10px #00000019;
  border: solid 1px #dadada;
  text-align: center;
  &:nth-of-type(2) {
    ${Label} {
      color: rgba(48, 85, 191, 0.7);
    }
    ${Value} {
      color: #3055bf;
    }
  }
  &:nth-of-type(3) {
    ${Label} {
      color: rgba(42, 201, 183, 0.75);
    }
    ${Value} {
      color: #2ac9b7;
    }
  }
  @media (${props => props.theme.mobileOnly}) {
    margin: 0 5px;
    padding: 10px;
    ${Label} {
      font-size: 14px;
    }
    ${Value} {
      font-size: 15px;
    }
  }
`;

type Props = {
  totalRegistered: number,
  event: RegistrationTotals_event,
};

const RegistrationTotals = ({ totalRegistered, event }: Props) => {
  return (
    <Container>
      <CardItem>
        <Label>REGISTERED</Label>
        <Value>{totalRegistered}</Value>
      </CardItem>
      <CardItem>
        <Label>AVERAGE TIME IN SESSION</Label>
        <Value>{formatDuration(event.averageAttendedTime)}</Value>
      </CardItem>
    </Container>
  );
};

export default createFragmentContainer(RegistrationTotals, {
  event: graphql`
    fragment RegistrationTotals_event on Event {
      averageAttendedTime
    }
  `,
});
