import alt from '../alt';
import UIActions from '../actions/UIActions';

class UIStore {
  constructor() {
    this.windows = {
      upgrade: { shown: false },
      modal: { shown: false },
      calendarShare: { shown: false },
    };
    this.sidebarOpenOnMobile = false;
    this.errorPageShown = false;
    this.errorMessages = [];

    this.bindActions(UIActions);
  }

  onShowErrorMessage([title, text, small]) {
    this.errorMessages.push({ title, text, small });
    return this.errorMessages.length - 1;
  }

  onHideErrorMessage(index) {
    if (index) {
      this.errorMessages.splice(index, 1);
    }
    this.errorMessages.shift();
  }

  onShowErrorPage() {
    this.errorPageShown = true;
  }

  onHideErrorPage() {
    this.errorPageShown = false;
  }

  onShowWindow(arg) {
    let key;
    let obj;
    if (Array.isArray(arg)) {
      [key, obj] = arg;
    } else {
      [key, obj] = [arg, {}];
    }
    this.windows[key] = Object.assign({}, obj, { shown: true });
  }

  onHideWindow(key) {
    this.windows[key] = { shown: false };
  }
}

export default alt.createStore(UIStore, 'UIStore');
