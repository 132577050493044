/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';

import GoogleMapsWrapper from 'components/GoogleMapsWrapper';
import type { MapMarkerType } from 'components/GoogleMapsWrapper/googleMapsWrapperTypes';

import AllEventsMapMarkerInfo from './AllEventsMapMarkerInfo';
import AllEventsMapMarkersInfo from './AllEventsMapMarkersInfo';

import type { AllEventsMapContainer_events } from './__generated__/AllEventsMapContainer_events.graphql';

class AllEventsMapContainer extends React.Component<{
  events: AllEventsMapContainer_events,
  match: Match,
  location: Location,
}> {
  getLocationMarkers(): $ReadOnlyArray<MapMarkerType> {
    return this.props.events.reduce(
      (
        result: $ReadOnlyArray<MapMarkerType>,
        event: $ElementType<AllEventsMapContainer_events, 0>,
      ) => {
        const primaryLocation = event.primaryLocation;
        if (primaryLocation) {
          return [
            ...result,
            {
              keyId: event.id,
              slug: event.slug,
              lat: primaryLocation.lat,
              lng: primaryLocation.lng,
            },
          ];
        }
        return result;
      },
      [],
    );
  }

  render() {
    const { match, location } = this.props;

    return (
      <GoogleMapsWrapper
        defaultZoom={8}
        containerElementHeight={750}
        mapElement={<div style={{ height: '100%' }} />}
        windowTopOffsetMobile={154}
        windowTopOffsetDesktop={200}
        markers={this.getLocationMarkers()}
        infoBoxOptions={{
          infoBoxView: AllEventsMapMarkerInfo,
          infoBoxOffset: -140,
          customProp: { match, location },
        }}
        fixedInfoBoxOptions={{
          fixedInfoBoxView: AllEventsMapMarkersInfo,
          customProp: { match, location },
        }}
      />
    );
  }
}

export default createFragmentContainer(
  AllEventsMapContainer,
  graphql`
    fragment AllEventsMapContainer_events on Event @relay(plural: true) {
      id
      slug
      primaryLocation {
        lat
        lng
      }
    }
  `,
);
