/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatPercentage from 'utils/number/formatPercentage';

import { type CategoryFooterCellPropsType } from './index';

import { type CategoryActualOfBudgetedFooterCell_event } from './__generated__/CategoryActualOfBudgetedFooterCell_event.graphql';

const CategoryActualOfBudgetedFooterCell = ({
  event,
}: CategoryFooterCellPropsType<CategoryActualOfBudgetedFooterCell_event>) =>
  formatPercentage(event.totalActualAmount / event.totalBudgetedAmount);

export default createFragmentContainer(
  CategoryActualOfBudgetedFooterCell,
  graphql`
    fragment CategoryActualOfBudgetedFooterCell_event on Event {
      totalBudgetedAmount
      totalActualAmount
    }
  `,
);
