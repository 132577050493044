/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/budget/Button';
import InviteWindow from 'components/InviteWindow';
import ShareDropdown from 'components/ShareDropdown';
import UsersImportWindow from 'components/UsersImportWindow';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 10px 15px;
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
  padding: 8px 14px;
  border-radius: 5px;
`;

export default class TeamMembersHeaderBar extends React.PureComponent<
  { teamId: string, canImport: boolean },
  { showInviteWindow: boolean, showImportWindow: boolean },
> {
  state = {
    showInviteWindow: false,
    showImportWindow: false,
  };

  handleShowInviteWindow = () => {
    this.setState({ showInviteWindow: true });
  };

  handleHideInviteWindow = () => {
    this.setState({ showInviteWindow: false });
  };

  handleShowImportWindow = () => {
    this.handleHideInviteWindow();
    this.setState({ showImportWindow: true });
  };

  handleHideImportWindow = () => {
    this.setState({ showImportWindow: false });
  };

  render() {
    const options = [
      this.props.canImport
        ? {
            label: 'Import Members (CSV)',
            icon: 'cloud-upload',
            onClick: this.handleShowImportWindow,
          }
        : null,
    ].filter(Boolean);

    return (
      <Container>
        <StyledButton onClick={this.handleShowInviteWindow}>Add a team member</StyledButton>
        {this.state.showInviteWindow && (
          <InviteWindow
            onHide={this.handleHideInviteWindow}
            teamId={this.props.teamId}
            onClickImport={this.handleShowImportWindow}
            fromWindow="team"
          />
        )}

        {options.length > 0 && <ShareDropdown noBorder options={options} />}
        {this.state.showImportWindow && (
          <UsersImportWindow onClose={this.handleHideImportWindow} teamId={this.props.teamId} />
        )}
      </Container>
    );
  }
}
