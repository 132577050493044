/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextField from 'components/material/TextField';

const Container = styled.div`
  width: 100%;
`;

const Hint = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 13px;
  text-align: right;
  margin-top: -8px;
  margin-bottom: 8px;
`;

export default class SimpleCommentInput extends React.PureComponent<
  {
    onSave: string => void,
    onCancel?: () => void,
    placeholder?: string,
    autoFocus?: boolean,
    defaultValue?: string,
  },
  {
    value: string,
  },
> {
  state = {
    value: this.props.defaultValue || '',
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ value: e.currentTarget.value });
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      const value = this.state.value.trim();

      if (value) {
        this.setState({ value: '' });

        this.props.onSave(value);

        if (this.props.onCancel) {
          this.props.onCancel();
        }
      }
    } else if (e.key === 'Escape' && this.props.onCancel) {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <Container>
        <TextField
          multiline
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          autoFocus={this.props.autoFocus}
        />
        {this.state.value.trim() && <Hint>hit enter to save</Hint>}
      </Container>
    );
  }
}
