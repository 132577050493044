/* @flow */
import * as React from 'react';
import ReactDOM from 'react-dom';

import Card from './Card';

const defaultGroup = 'main';
const portalNodes: { [string]: any } = {};

type Props = {|
  parent: React.Node | HTMLElement,
  active?: boolean,
  placement?: 'top' | 'bottom' | 'right' | 'left',
  align?: 'center' | 'right' | 'left' | null,
  useHover?: boolean,
  group?: string,
  tooltipTimeout?: number,
  children: React.Node,
  additionalOffset?: number,
|};

export default class StatelessTooltip extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.active) {
      return;
    }

    this.renderPortal(this.props);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      (!portalNodes[prevProps.group || defaultGroup] && !this.props.active) ||
      (!prevProps.active && !this.props.active)
    ) {
      return;
    }

    const props = { ...this.props };
    const newProps = { ...this.props };

    if (
      portalNodes[prevProps.group || defaultGroup] &&
      portalNodes[prevProps.group || defaultGroup].timeout
    ) {
      clearTimeout(portalNodes[prevProps.group || defaultGroup].timeout);
    }

    if (prevProps.active && !props.active) {
      newProps.active = true;
      portalNodes[prevProps.group || defaultGroup].timeout = setTimeout(() => {
        props.active = false;
        this.renderPortal(props);
      }, prevProps.tooltipTimeout);
    }

    this.renderPortal(newProps);
  }

  componentWillUnmount() {
    if (portalNodes[this.props.group || defaultGroup]) {
      ReactDOM.unmountComponentAtNode(portalNodes[this.props.group || defaultGroup].node);
      clearTimeout(portalNodes[this.props.group || defaultGroup].timeout);
    }
  }

  createPortal() {
    portalNodes[this.props.group || defaultGroup] = {
      node: document.createElement('div'),
      timeout: false,
    };
    portalNodes[this.props.group || defaultGroup].node.className = 'ToolTipPortal';
    if (document.body != null) {
      document.body.appendChild(portalNodes[this.props.group || defaultGroup].node);
    }
  }

  renderPortal(props: Props) {
    if (!portalNodes[this.props.group || defaultGroup]) {
      this.createPortal();
    }
    const { parent, ...other } = props;
    ReactDOM.render(
      <Card parentEl={parent} {...other} />,
      portalNodes[this.props.group || defaultGroup].node,
    );
  }

  render() {
    return null;
  }
}
