/* @flow */
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const TableLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

export default TableLink;
