/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoryActualOfBudgetedFooterCell_event$ref: FragmentReference;
export type CategoryActualOfBudgetedFooterCell_event = {|
  +totalBudgetedAmount: number,
  +totalActualAmount: number,
  +$refType: CategoryActualOfBudgetedFooterCell_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CategoryActualOfBudgetedFooterCell_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalBudgetedAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalActualAmount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1d9c289bdf5e940f49600f820cb2d82f';
module.exports = node;
