/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled from 'styled-components';

import parseTypedQueryString, { stringParamToSort } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import addContactsToCompany from 'graph/mutations/company/addContactsToCompany';
import showModernMutationError from 'graph/utils/showModernMutationError';

import NoResult from 'images/noResult.svg';
import Button from 'components/budget/Button';
import EmptyView from 'components/budget/EmptyView';
import AddToContactsWindow from 'components/Company/AddToContactsWindow';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type { Sort } from 'components/material/SortableHeader';

import CompanyContactsList from './CompanyContactsList';

import { type CompanyContacts_company } from './__generated__/CompanyContacts_company.graphql';
import { type CompanyContacts_event } from './__generated__/CompanyContacts_event.graphql';
import { type CompanyContactsQueryResponse } from './__generated__/CompanyContactsQuery.graphql';

const Container = styled.div`
  margin: 25px auto;
`;

const StyledButton = styled(Button)`
  margin-bottom: 20px;
`;

const query = graphql`
  query CompanyContactsQuery($companyId: ID!, $sort: ContactSort, $direction: Direction!) {
    company: node(id: $companyId) {
      ... on Company {
        ...CompanyContactsList_company
        contacts(first: 1000, sort: $sort, direction: $direction)
          @connection(key: "CompanyContactsList_contacts", filters: []) {
          edges {
            node {
              id
              ...CompanyContactsList_contacts
            }
          }
        }
      }
    }
    org {
      id
      ...CompanyContactsList_org
    }
  }
`;

class CompanyContacts extends React.Component<
  {
    company: CompanyContacts_company,
    event?: CompanyContacts_event,
    contactsCount: number,
    location: Location,
    history: History,
  },
  {
    windowShown: boolean,
  },
> {
  state = { windowShown: false };

  handleSort = (sort: Sort) => {
    replaceSortQueryParam(this.props.history, sort, 'contactSort');
  };

  handleAddingToContactsShow = () => {
    this.setState({ windowShown: true });
  };

  handleAddingToContactsHide = () => {
    this.setState({ windowShown: false });
  };

  handleAddToContactsSave = (contactIds: $ReadOnlyArray<string>) => {
    addContactsToCompany(this.props.company.org.id, this.props.company.id, contactIds).catch(
      showModernMutationError,
    );
    this.handleAddingToContactsHide();
  };

  render() {
    const { company, location, history, contactsCount, event } = this.props;

    const filters = parseTypedQueryString(location.search, {
      contactSort: stringParamToSort,
    });
    const sort = filters.contactSort || {
      key: 'NAME',
      asc: true,
    };
    return (
      <Container>
        <StyledButton onClick={this.handleAddingToContactsShow} loading={this.state.windowShown}>
          Add Contacts
        </StyledButton>
        {this.state.windowShown && (
          <AddToContactsWindow
            companyName={company.name}
            eventId={event ? event.id : null}
            onCancel={this.handleAddingToContactsHide}
            onSave={this.handleAddToContactsSave}
          />
        )}
        {contactsCount > 0 ? (
          <DefaultQueryRenderer
            query={query}
            variables={{
              companyId: company.id,
              sort: sort.key,
              direction: sort.asc ? 'ASC' : 'DESC',
            }}
            renderSuccess={(response: CompanyContactsQueryResponse) => {
              if (!response.company) {
                return null;
              }
              const companyContacts = response.company.contacts
                ? response.company.contacts.edges.map(({ node }) => node)
                : null;
              return (
                <CompanyContactsList
                  company={response.company}
                  contacts={companyContacts}
                  org={response.org}
                  sort={sort}
                  onChangeSort={this.handleSort}
                  history={history}
                  location={location}
                />
              );
            }}
          />
        ) : (
          <EmptyView message="Add the first contact for this company." icon={<NoResult />} />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(CompanyContacts, {
  company: graphql`
    fragment CompanyContacts_company on Company {
      id
      name
      org {
        id
      }
    }
  `,
  event: graphql`
    fragment CompanyContacts_event on Event {
      id
    }
  `,
});
