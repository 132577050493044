/* @flow */
import React from 'react';
import styled from 'styled-components';

import CheckBox from 'components/EventRequestForm/form/CheckBox';
import type { Participant } from 'components/Participants';
import OrgReviewerSearch from 'components/Participants/OrgReviewerSearch';
import ParticipantRow from 'components/Participants/ParticipantRow';

const ReviewersWrapper = styled.div`
  width: 100%;
  margin: 30px 0;
`;
const ReviewersContainer = styled.div`
  width: 420px;
  i {
    font-style: normal;
  }
`;

const Row = styled.div`
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  color: rgba(74, 86, 101, 0.87);
  font-weight: 500;
`;

const Subtitle = styled.div`
  margin-bottom: 4px;
  color: rgba(74, 86, 101, 0.87);
`;

const EventRequestFormReviewers = (props: {
  onSelectReviewer: (user: Participant) => void,
  selecetedReviewerIds: $ReadOnlyArray<string>,
  reviewers: $ReadOnlyArray<Participant>,
  onRemoveReviewer: (user: Participant) => void,
  notifyReviewers: boolean,
  onToggleNotifyReviewers: () => void,
}) => {
  const {
    onSelectReviewer,
    selecetedReviewerIds,
    reviewers,
    onRemoveReviewer,
    notifyReviewers,
    onToggleNotifyReviewers,
  } = props;

  return (
    <ReviewersWrapper>
      <ReviewersContainer>
        <Title>Reviewers</Title>
        <Subtitle>Only Admins and Team Members can be added as Reviewers.</Subtitle>
        <OrgReviewerSearch
          label="Reviewer"
          onSelect={onSelectReviewer}
          selecetedUserIds={selecetedReviewerIds}
          hasTeamAccess
        />
        {reviewers.length > 0 && (
          <Row>
            {reviewers.map(reviewer => (
              <ParticipantRow
                key={reviewer.id}
                participant={reviewer}
                onRemove={onRemoveReviewer}
              />
            ))}
          </Row>
        )}
      </ReviewersContainer>
      <Row>
        <CheckBox
          compact
          checked={notifyReviewers}
          label="Send an email notification to Reviewers for new submissions and submission updates"
          onChange={onToggleNotifyReviewers}
        />
      </Row>
    </ReviewersWrapper>
  );
};

export default EventRequestFormReviewers;
