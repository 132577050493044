/* @flow */

export type Tab = {
  label: string,
  value: string,
  path: string,
  disabled?: boolean,
  sticky?: boolean,
  hideByDefault?: boolean,
};

type Tabs = $ReadOnlyArray<Tab>;

const tabs: Tabs = [
  {
    label: 'Info',
    value: 'INFO',
    path: '/info',
    sticky: true,
  },
  {
    label: 'Staff',
    value: 'STAFF',
    path: '/staff',
  },
  { label: 'Budget', value: 'BUDGET', path: '/budget' },
  { label: 'Vendors', value: 'VENDORS', path: '/vendors', hideByDefault: true },
  { label: 'ROI', value: 'ROI', path: '/roi' },
  { label: 'Contacts', value: 'CONTACTS', path: '/contacts' },
  { label: 'Registration', value: 'REGISTRATION', path: '/registration' },
  { label: 'Checklist', value: 'CHECKLIST', path: '/tasks' },
  { label: 'Schedule', value: 'SCHEDULE', path: '/schedules' },
  { label: 'Attachments', value: 'ATTACHMENTS', path: '/attachments' },
  { label: 'Notes', value: 'NOTES', path: '/notes' },
];

export default tabs;
