/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import type {
  removeScheduleDayMutationVariables,
  removeScheduleDayMutationResponse,
} from './__generated__/removeScheduleDayMutation.graphql';

const mutation = graphql`
  mutation removeScheduleDayMutation($input: RemoveDayFromScheduleInput!) {
    removeDayFromSchedule(input: $input) {
      removedDayId
    }
  }
`;

export default function removeScheduleDay(
  scheduleId: string,
  scheduleDayId: string,
): Promise<removeScheduleDayMutationResponse> {
  const variables: removeScheduleDayMutationVariables = {
    input: {
      scheduleDayId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedDayId',
        parentId: scheduleId,
        connections: [{ field: 'scheduleDays' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedDayId' }),
    ),
  });
}
