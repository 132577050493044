/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment-timezone';

import BillingProgressBar from 'components/billing/BillingProgressBar';

import type { ActiveSubscriptionMessage_subscription } from './__generated__/ActiveSubscriptionMessage_subscription.graphql';

const Top = styled.div`
  padding-bottom: 40px;
  border-bottom: 1px solid #d8d8d8;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  color: #6c767f;
`;

const Left = styled.div`
  flex: 1 1 auto;
`;

const TopInfoLine = styled.div`
  line-height: 1.69;
  > span {
    font-weight: 500;
  }
`;

const Right = styled.div`
  flex: 0 0 203px;
  padding-top: 10px;
`;

const BottomInfoLine = styled.div`
  margin-top: 4px;
  > span {
    font-weight: 500;
  }
`;

type Props = {
  subscription: ActiveSubscriptionMessage_subscription,
};

const ActiveSubscriptionMessage = ({ subscription }: Props) => {
  const renewalDate = subscription.expiresAt
    ? moment(subscription.expiresAt).format('dddd, MMMM Do YYYY')
    : 'Indefinite';
  return (
    <React.Fragment>
      <Top>
        <TopInfoLine>
          Next renewal date: <span>{renewalDate}</span>
        </TopInfoLine>
      </Top>
      <Bottom>
        <Left>
          {subscription.fatmLimit != null && (
            <BottomInfoLine>
              Team Members included in your subscription: <span>{subscription.fatmLimit}</span>
            </BottomInfoLine>
          )}
          <BottomInfoLine>
            Current Team Members: <span>{subscription.fatmCount}</span>
          </BottomInfoLine>
        </Left>
        {subscription.fatmLimit != null && (
          <Right>
            <BillingProgressBar
              limit={subscription.fatmLimit}
              used={subscription.fatmCount}
              label="Team Members"
              labelAlignment="right"
            />
          </Right>
        )}
      </Bottom>
    </React.Fragment>
  );
};

export default createFragmentContainer(
  ActiveSubscriptionMessage,
  graphql`
    fragment ActiveSubscriptionMessage_subscription on Subscription {
      expiresAt
      fatmLimit
      fatmCount
    }
  `,
);
