/**
 * @flow
 * @relayHash 2eb1c6014a31ed6cc4eaff286c956224
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DependencyRow_dependency$ref = any;
export type AddDeliverableDependencyInput = {
  clientMutationId?: ?string,
  dependentId: string,
  dependencyId: string,
};
export type addTaskDependencyMutationVariables = {|
  input: AddDeliverableDependencyInput
|};
export type addTaskDependencyMutationResponse = {|
  +addDeliverableDependency: ?{|
    +dependencyEdge: ?{|
      +__typename: string,
      +node: ?{|
        +id: string,
        +$fragmentRefs: DependencyRow_dependency$ref,
      |},
    |},
    +dependentEdge: ?{|
      +__typename: string,
      +node: ?{|
        +id: string,
        +$fragmentRefs: DependencyRow_dependency$ref,
      |},
    |},
  |}
|};
export type addTaskDependencyMutation = {|
  variables: addTaskDependencyMutationVariables,
  response: addTaskDependencyMutationResponse,
|};
*/


/*
mutation addTaskDependencyMutation(
  $input: AddDeliverableDependencyInput!
) {
  addDeliverableDependency(input: $input) {
    dependencyEdge {
      __typename
      node {
        id
        ...DependencyRow_dependency
      }
    }
    dependentEdge {
      __typename
      node {
        id
        ...DependencyRow_dependency
      }
    }
  }
}

fragment DependencyRow_dependency on Deliverable {
  id
  name
  status
  slug
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddDeliverableDependencyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddDeliverableDependencyInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  v2,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "node",
    "storageKey": null,
    "args": null,
    "concreteType": "Deliverable",
    "plural": false,
    "selections": [
      v3,
      {
        "kind": "FragmentSpread",
        "name": "DependencyRow_dependency",
        "args": null
      }
    ]
  }
],
v5 = [
  v2,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "node",
    "storageKey": null,
    "args": null,
    "concreteType": "Deliverable",
    "plural": false,
    "selections": [
      v3,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "slug",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addTaskDependencyMutation",
  "id": null,
  "text": "mutation addTaskDependencyMutation(\n  $input: AddDeliverableDependencyInput!\n) {\n  addDeliverableDependency(input: $input) {\n    dependencyEdge {\n      __typename\n      node {\n        id\n        ...DependencyRow_dependency\n      }\n    }\n    dependentEdge {\n      __typename\n      node {\n        id\n        ...DependencyRow_dependency\n      }\n    }\n  }\n}\n\nfragment DependencyRow_dependency on Deliverable {\n  id\n  name\n  status\n  slug\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addTaskDependencyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDeliverableDependency",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddDeliverableDependencyPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "dependencyEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableEdge",
            "plural": false,
            "selections": v4
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "dependentEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableEdge",
            "plural": false,
            "selections": v4
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "addTaskDependencyMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDeliverableDependency",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddDeliverableDependencyPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "dependencyEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableEdge",
            "plural": false,
            "selections": v5
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "dependentEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableEdge",
            "plural": false,
            "selections": v5
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '29613aa22ff3910f6e896a154b339aa6';
module.exports = node;
