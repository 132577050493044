/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MyTasksHeaderBar_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyTasksPage_org$ref: FragmentReference;
export type MyTasksPage_org = {|
  +settings: {|
    +fiscalYearStart: number
  |},
  +$fragmentRefs: MyTasksHeaderBar_org$ref,
  +$refType: MyTasksPage_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MyTasksPage_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "MyTasksHeaderBar_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fiscalYearStart",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '755f95dcd33c0e02677402e6408c7f2e';
module.exports = node;
