/* @flow */
import formatCost from 'utils/number/formatCost';

import { type ReportingFooterCellPropsType } from './index';

const ReportingTotalPlannedAmountCell = ({ totals, orgCurrency }: ReportingFooterCellPropsType) => {
  return formatCost(totals.plannedAmount, orgCurrency);
};

export default ReportingTotalPlannedAmountCell;
