/**
 * @flow
 * @relayHash ba0944cfbe41d399c48ca48b0f649718
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MembersPagination_org$ref = any;
type MembersPagination_totalCountUsers$ref = any;
export type Direction = "ASC" | "DESC";
export type UserSort = "ACCOUNT_STATUS" | "ADMIN" | "CONFIRMED_AT" | "CREATED_AT" | "EMAIL" | "EVENT_COUNT" | "FULL_NAME" | "ID" | "LAST_ACTIVITY" | "ORG_ADMINS_COUNT" | "ORG_ANALYTICS_ENABLED" | "ORG_CONTACTS_COUNT" | "ORG_CRM_CONNECTED_EVENTS" | "ORG_CRM_ENABLED" | "ORG_CRM_LAST_SYNC" | "ORG_DOMAIN" | "ORG_EVENTS_COUNT" | "ORG_EVENTS_UPCOMING" | "ORG_ID" | "ORG_MEMBERS_COUNT" | "ORG_NAME" | "ORG_SSO_ENABLED" | "ORG_SUBDOMAIN" | "ORG_SUBSCRIPTION" | "ORG_SUBSCRIPTION_TERM" | "ORG_SYNCED_TO_IBM" | "ORG_TEAM_MEMBERS_COUNT" | "ROLE" | "STAFFED_EVENTS_UPCOMING" | "SUBSCRIPTION_DAYS_LEFT" | "SUBSCRIPTION_EXPIRE_AT" | "SUBSCRIPTION_MEMBERS_LEFT" | "SUBSCRIPTION_MEMBERS_LIMIT" | "TEAM_COUNT" | "TIMEZONE";
export type MembersPageQueryVariables = {|
  count: number,
  cursor?: ?string,
  sort: UserSort,
  direction: Direction,
  query?: ?string,
|};
export type MembersPageQueryResponse = {|
  +org: {|
    +$fragmentRefs: MembersPagination_org$ref & MembersPagination_totalCountUsers$ref
  |},
  +me: {|
    +tz: string,
    +id: string,
  |},
|};
export type MembersPageQuery = {|
  variables: MembersPageQueryVariables,
  response: MembersPageQueryResponse,
|};
*/


/*
query MembersPageQuery(
  $count: Int!
  $cursor: String
  $sort: UserSort!
  $direction: Direction!
  $query: String
) {
  org {
    ...MembersPagination_org
    ...MembersPagination_totalCountUsers
    id
  }
  me {
    tz
    id
  }
}

fragment MembersPagination_org on Org {
  id
  users(first: $count, after: $cursor, sort: $sort, direction: $direction, query: $query) {
    edges {
      node {
        id
        viewerCanSeeProfile
        ...MemberFullName_user
        ...MemberEmail_user
        ...MemberEventCount_user
        ...MemberTeamCount_user
        ...MemberLastActivity_user
        ...MemberInviteStatus_user
        ...MembersActionsColumn_user
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment MembersPagination_totalCountUsers on Org {
  orgUsers: users(query: $query) {
    totalCount
  }
}

fragment MemberFullName_user on User {
  viewerCanSeeProfile
  id
  firstName
  lastName
  email
  admin
  ...MaterialAvatar_user
}

fragment MemberEmail_user on User {
  email
}

fragment MemberEventCount_user on User {
  staffedEvents {
    totalCount
  }
}

fragment MemberTeamCount_user on User {
  memberships {
    totalCount
  }
}

fragment MemberLastActivity_user on User {
  lastSeenAt
}

fragment MemberInviteStatus_user on User {
  inviteStatus
}

fragment MembersActionsColumn_user on User {
  id
  firstName
  lastName
  inviteStatus
  viewerCanRemove
  viewerCanUpdate
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "UserSort!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "me",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    v1
  ]
},
v3 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query",
  "type": "String"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction",
    "type": "Direction"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  v3,
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "UserSort"
  }
],
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MembersPageQuery",
  "id": null,
  "text": "query MembersPageQuery(\n  $count: Int!\n  $cursor: String\n  $sort: UserSort!\n  $direction: Direction!\n  $query: String\n) {\n  org {\n    ...MembersPagination_org\n    ...MembersPagination_totalCountUsers\n    id\n  }\n  me {\n    tz\n    id\n  }\n}\n\nfragment MembersPagination_org on Org {\n  id\n  users(first: $count, after: $cursor, sort: $sort, direction: $direction, query: $query) {\n    edges {\n      node {\n        id\n        viewerCanSeeProfile\n        ...MemberFullName_user\n        ...MemberEmail_user\n        ...MemberEventCount_user\n        ...MemberTeamCount_user\n        ...MemberLastActivity_user\n        ...MemberInviteStatus_user\n        ...MembersActionsColumn_user\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment MembersPagination_totalCountUsers on Org {\n  orgUsers: users(query: $query) {\n    totalCount\n  }\n}\n\nfragment MemberFullName_user on User {\n  viewerCanSeeProfile\n  id\n  firstName\n  lastName\n  email\n  admin\n  ...MaterialAvatar_user\n}\n\nfragment MemberEmail_user on User {\n  email\n}\n\nfragment MemberEventCount_user on User {\n  staffedEvents {\n    totalCount\n  }\n}\n\nfragment MemberTeamCount_user on User {\n  memberships {\n    totalCount\n  }\n}\n\nfragment MemberLastActivity_user on User {\n  lastSeenAt\n}\n\nfragment MemberInviteStatus_user on User {\n  inviteStatus\n}\n\nfragment MembersActionsColumn_user on User {\n  id\n  firstName\n  lastName\n  inviteStatus\n  viewerCanRemove\n  viewerCanUpdate\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MembersPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "MembersPagination_org",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "MembersPagination_totalCountUsers",
            "args": null
          }
        ]
      },
      v2
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MembersPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "users",
            "storageKey": null,
            "args": v4,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanSeeProfile",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "admin",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "staffedEvents",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembershipRequiredConnection",
                        "plural": false,
                        "selections": v5
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "memberships",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TeamMembershipConnection",
                        "plural": false,
                        "selections": v5
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastSeenAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "inviteStatus",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanRemove",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanUpdate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "users",
            "args": v4,
            "handle": "connection",
            "key": "MembersPagination_users",
            "filters": []
          },
          {
            "kind": "LinkedField",
            "alias": "orgUsers",
            "name": "users",
            "storageKey": null,
            "args": [
              v3
            ],
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": v5
          }
        ]
      },
      v2
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '85bba7c217884cd7df5de6b30c6f14a0';
module.exports = node;
