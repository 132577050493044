/**
 * @flow
 * @relayHash ae8bbea0b228285339ef42c681fd1470
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ReportingRoutes_org$ref = any;
export type ReportingQueryVariables = {||};
export type ReportingQueryResponse = {|
  +org: {|
    +$fragmentRefs: ReportingRoutes_org$ref
  |}
|};
export type ReportingQuery = {|
  variables: ReportingQueryVariables,
  response: ReportingQueryResponse,
|};
*/


/*
query ReportingQuery {
  org {
    ...ReportingRoutes_org
    id
  }
}

fragment ReportingRoutes_org on Org {
  viewerCanViewReporting
  viewerCanImport
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "operationKind": "query",
  "name": "ReportingQuery",
  "id": null,
  "text": "query ReportingQuery {\n  org {\n    ...ReportingRoutes_org\n    id\n  }\n}\n\nfragment ReportingRoutes_org on Org {\n  viewerCanViewReporting\n  viewerCanImport\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ReportingQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ReportingRoutes_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReportingQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanViewReporting",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanImport",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
// prettier-ignore
(node/*: any*/).hash = 'b00db42a4c431a87eb0849fffc290c18';
module.exports = node;
