/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ZoomLocationKind = "MEETING" | "WEBINAR";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationSettingsIntegrations_event$ref: FragmentReference;
export type RegistrationSettingsIntegrations_event = {|
  +id: string,
  +zoomLocation: ?{|
    +id: string,
    +kind: ZoomLocationKind,
    +zoomUser: ?{|
      +id: string,
      +zoomId: string,
      +firstName: string,
      +lastName: string,
      +email: string,
      +freePlan: boolean,
    |},
    +zoomId: string,
    +joinUrl: string,
    +zoomUrl: string,
    +topic: string,
    +startTime: ?any,
    +duration: ?number,
    +timezone: string,
  |},
  +g2wLocation: ?{|
    +id: string,
    +g2wKey: string,
    +subject: string,
    +times: $ReadOnlyArray<{|
      +startTime: any,
      +endTime: any,
    |}>,
    +timezone: string,
    +registrationUrl: string,
    +g2wUrl: string,
    +g2wUser: ?{|
      +id: string,
      +g2wKey: string,
      +firstName: string,
      +lastName: string,
      +email: string,
    |},
  |},
  +registrationForm: ?{|
    +id: string,
    +eventName: string,
    +eventStartDate: any,
    +eventEndDate: ?any,
    +startDateAllDay: ?boolean,
    +endDateAllDay: ?boolean,
    +tz: string,
  |},
  +$refType: RegistrationSettingsIntegrations_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "zoomId",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startTime",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timezone",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "g2wKey",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RegistrationSettingsIntegrations_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "zoomLocation",
      "storageKey": null,
      "args": null,
      "concreteType": "ZoomLocation",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "kind",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "zoomUser",
          "storageKey": null,
          "args": null,
          "concreteType": "ZoomUser",
          "plural": false,
          "selections": [
            v0,
            v1,
            v2,
            v3,
            v4,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "freePlan",
              "args": null,
              "storageKey": null
            }
          ]
        },
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "joinUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "zoomUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "topic",
          "args": null,
          "storageKey": null
        },
        v5,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "duration",
          "args": null,
          "storageKey": null
        },
        v6
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "g2wLocation",
      "storageKey": null,
      "args": null,
      "concreteType": "G2WLocation",
      "plural": false,
      "selections": [
        v0,
        v7,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subject",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "times",
          "storageKey": null,
          "args": null,
          "concreteType": "TimeRangeType",
          "plural": true,
          "selections": [
            v5,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endTime",
              "args": null,
              "storageKey": null
            }
          ]
        },
        v6,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "registrationUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "g2wUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "g2wUser",
          "storageKey": null,
          "args": null,
          "concreteType": "G2WUser",
          "plural": false,
          "selections": [
            v0,
            v7,
            v2,
            v3,
            v4
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "registrationForm",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationForm",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "eventName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "eventStartDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "eventEndDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "startDateAllDay",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "endDateAllDay",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tz",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '786da3b3223478dd8537fc24ca242174';
module.exports = node;
