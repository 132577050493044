/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import NumberField from './components/NumberField';

import type { SalesforceOpportunitiesAmount_event } from './__generated__/SalesforceOpportunitiesAmount_event.graphql';
import type { SalesforceOpportunitiesAmount_org } from './__generated__/SalesforceOpportunitiesAmount_org.graphql';

const SalesforceOpportunitiesAmount = (props: {
  event: SalesforceOpportunitiesAmount_event,
  org: SalesforceOpportunitiesAmount_org,
}) => {
  const { event, org } = props;
  const currency = org.settings.currency;
  const salesforceCampaign = event.salesforceCampaign;

  return salesforceCampaign != null ? (
    <NumberField
      value={formatCost(salesforceCampaign.amountAllOpportunities, currency)}
      formatted
    />
  ) : null;
};

export default createFragmentContainer(SalesforceOpportunitiesAmount, {
  event: graphql`
    fragment SalesforceOpportunitiesAmount_event on Event {
      salesforceCampaign {
        amountAllOpportunities
      }
    }
  `,
  org: graphql`
    fragment SalesforceOpportunitiesAmount_org on Org {
      settings {
        currency
      }
    }
  `,
});
