/* @flow */
import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  cursor: pointer;
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    text-decoration: underline;
  }
`;

export default class StyledAnchor extends React.PureComponent<{
  href: string,
  text?: string,
  target?: string,
  title?: string,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const href = this.props.href;
    const text = this.props.text || href;
    const target = this.props.target || '_blank';

    return (
      <StyledLink
        href={href}
        target={target}
        onMouseDown={this.handleMouseDown}
        title={this.props.title}
        rel="noopener noreferrer"
      >
        {text}
      </StyledLink>
    );
  }
}
