/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatLocation from 'utils/locations/formatLocation';

import { type CellPropsType } from 'components/budget/Table';

import { type EventCellPropsType } from './index';

import type { EventLocation_contactEvent } from './__generated__/EventLocation_contactEvent.graphql';

const EventLocation = ({
  contactEvent,
}: CellPropsType<EventCellPropsType<EventLocation_contactEvent>>) => {
  return formatLocation(contactEvent.node.primaryLocation);
};

export default createFragmentContainer(
  EventLocation,
  graphql`
    fragment EventLocation_contactEvent on ContactEventEdge {
      node {
        primaryLocation {
          city
          state
          name
          country
        }
      }
    }
  `,
);
