/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import Avatar from 'components/material/Avatar';

import SearchResultContainer from './components/SearchResultContainer';

import type { ContactSearchResult_contact } from './__generated__/ContactSearchResult_contact.graphql';

const ContactResultContainer = styled.div`
  display: flex;
`;

const ContactInfoContainer = styled.div`
  flex: 1;
`;

const StyledAvatar = styled(Avatar)`
  position: relative;
  flex: 0 0 21px;
  margin-right: 9px;
`;

const ContactName = styled.div`
  color: #3e4859;
`;

const ContactDetails = styled.div`
  font-size: 11px;
  color: rgba(62, 72, 89, 0.76);
`;

type Props = { contact: ContactSearchResult_contact };

const ContactSearchResult = ({ contact }: Props) => {
  return (
    <SearchResultContainer path={`/contacts/people/${contact.slug}`}>
      <ContactResultContainer>
        <StyledAvatar profile={contact} />
        <ContactInfoContainer>
          <ContactName>{fullNameOfUser(contact)}</ContactName>
          <ContactDetails>
            {[contact.email, contact.company].filter(Boolean).join(', ')}
          </ContactDetails>
        </ContactInfoContainer>
      </ContactResultContainer>
    </SearchResultContainer>
  );
};

export default createFragmentContainer(
  ContactSearchResult,
  graphql`
    fragment ContactSearchResult_contact on ContactSearchResult {
      slug
      firstName
      lastName
      email
      company
      avatar
    }
  `,
);
