/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventFormat_event$ref: FragmentReference;
export type EventFormat_event = {|
  +virtualLocation: ?string,
  +zoomLocation: ?{|
    +id: string
  |},
  +g2wLocation: ?{|
    +id: string
  |},
  +primaryLocation: ?{|
    +id: string
  |},
  +$refType: EventFormat_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "EventFormat_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "virtualLocation",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "zoomLocation",
      "storageKey": null,
      "args": null,
      "concreteType": "ZoomLocation",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "g2wLocation",
      "storageKey": null,
      "args": null,
      "concreteType": "G2WLocation",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "primaryLocation",
      "storageKey": null,
      "args": null,
      "concreteType": "Location",
      "plural": false,
      "selections": v0
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '826aa6ca86699e1bdee91891e6d981f6';
module.exports = node;
