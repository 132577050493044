/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import { type CategoryCellPropsType } from './index';

import { type CategoryActualAmountCell_categoryEdge } from './__generated__/CategoryActualAmountCell_categoryEdge.graphql';
import { type CategoryActualAmountCell_org } from './__generated__/CategoryActualAmountCell_org.graphql';

const CategoryActualAmountCell = ({
  categoryEdge,
  categoryEdgeMock,
  org,
  adding,
}: CategoryCellPropsType<
  CategoryActualAmountCell_categoryEdge,
  {},
  CategoryActualAmountCell_org,
>) => {
  if (adding) return '-';

  return (
    formatCost((categoryEdge || categoryEdgeMock || {}).actualAmount, org.settings.currency) || '-'
  );
};

export default createFragmentContainer(
  CategoryActualAmountCell,
  graphql`
    fragment CategoryActualAmountCell_categoryEdge on EventBudgetCategoryEdge {
      actualAmount
    }

    fragment CategoryActualAmountCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
