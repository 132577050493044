/* @flow */
import startsWith from 'lodash/startsWith';

import currentOrigin from 'utils/currentOrigin';

/**
 * Opens a popup window for OAuthing
 *
 * @return {Promise} Resolves if params are returned (successful or not)
 *  rejects if the window is closed before completing.
 */
export default function oauthPopup(connectUrl: string, callbackUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const popup = window.open(connectUrl, 'windowname', 'width=480, height=740');
    const timer = setInterval(() => {
      if (popup.closed) {
        clearInterval(timer);
        reject();
      }
    }, 300);

    window.addEventListener(
      'message',
      e => {
        // Window sending the message does not belong to app domain
        if (!currentOrigin().includes(e.origin.replace(/^https?:\/\/(app.)?/, ''))) {
          return;
        }

        const currentUrl = e.data.currentUrl;

        if (startsWith(currentUrl, callbackUrl)) {
          console.log('returned with url', currentUrl);
          clearInterval(timer);
          popup.close();
          resolve(currentUrl);
        }
      },
      false,
    );
  });
}
