/* @flow */
import { graphql } from 'react-relay';
import omit from 'lodash/omit';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type PaymentStatus } from 'config/paymentStatuses';
import type { CircaFile } from 'utils/uploading/types';
import type {
  createPaymentMutationResponse,
  createPaymentMutationVariables,
} from './__generated__/createPaymentMutation.graphql';

const mutation = graphql`
  mutation createPaymentMutation($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      expense {
        id
        actualAmount
        paidAmount
        viewerCanUpdateAmount

        # Payments are being updated when payments connection is fetched on expense.
        # No need for updater.
        payments {
          edges {
            node {
              viewerCanDelete
            }
          }
        }

        event {
          id
          totalActualAmount
          totalPaidAmount
        }
      }
      paymentEdge {
        __typename
        node {
          id
          ...Payment_payment
        }
      }
    }
  }
`;

export type PaymentType = {
  expenseId: string,
  actualDate: ?string,
  dueDate: ?string,
  amount: number,
  paymentMethod: ?string,
  paymentType: string,
  poNumber: ?string,
  reference: ?string,
  status: PaymentStatus,
  note: ?string,
  attachments?: $ReadOnlyArray<CircaFile>,
};

export default function createPayment(expenseId: string, payment: PaymentType) {
  const variables: createPaymentMutationVariables = {
    input: {
      expenseId,
      ...omit(payment, ['id', 'attachments']),
    },
  };

  return commitModernMutation({
    mutation,
    variables,
  }).then((response: createPaymentMutationResponse) => {
    if (response.createPayment) {
      return response.createPayment.paymentEdge.node.id;
    }
    throw new Error('No Payment Id');
  });
}
