/* @flow */
import type moment from 'moment-timezone';

import {
  type DateRangeParam,
  type NumberRangeParam,
  type SearchParam,
  type SortParam,
  ARRAY_DELIMETER,
  FILTER_DATE_FORMAT,
  SORT_DELIMETER,
} from './parseTypedQueryString';

export function stringToStringParam(typedValue: ?string): ?string {
  return typedValue || null;
}

export function stringArrayToStringParam(arr: $ReadOnlyArray<string>): ?string {
  const joined = arr.join(ARRAY_DELIMETER);
  return joined.length > 0 ? joined : null;
}

export function stringArrayToBase64StringParam(arr: $ReadOnlyArray<string>): ?string {
  const joined = arr.map(value => window.btoa(value)).join(ARRAY_DELIMETER);
  return joined.length > 0 ? joined : null;
}

export function booleanToStringParam(typedValue: boolean): string {
  return typedValue ? 'true' : 'false';
}

export function numberToStringParam(number: number): string {
  return number.toString();
}

export function sortToStringParam(sort: SortParam): string {
  return [sort.key, sort.asc ? 'asc' : 'desc'].join(SORT_DELIMETER);
}

export function searchToStringParam(search: ?SearchParam): ?string {
  if (search == null) {
    return null;
  }
  return `${search.search}${search.exactMatch ? '~EXACT' : ''}`;
}

export function dateToStringParam(date: moment): string {
  return date.format(FILTER_DATE_FORMAT);
}

export function dateRangeToStringParam(dateRange: DateRangeParam): string {
  return dateRange.key || '';
}

export function numberRangeToStringParam(range: ?NumberRangeParam): ?string {
  if (range == null || (range.min == null && range.max == null)) {
    return null;
  }
  return [
    range.min == null ? null : `min:${range.min}`,
    range.max == null ? null : `max:${range.max}`,
  ].join(ARRAY_DELIMETER);
}

export type ExtractParamValueType = <ParamValue>(mixed) => ParamValue => ?string;

/**
 * Converts an object of params with complex values like arrays, strings, sort params, and booleans
 * (for example that you would get back from filters) into an object with only string values.
 * The resulting object can then be safely stringified with `qs.stringify`.
 * This is the serialization counterpart to `parseTypedQueryString'.
 * Ex:
 * { tags: ['one', two'], isComplete: true }
 * might convert to:
 * { tags: 'one,two', isComplete: 'true' }
 * @param typedParams object with values of any kind of type
 * @param stringifiers object of functions that convert the value of that property to a string
 */
export default function stringifyQueryParamValues<
  TypedParams: {
    +[paramName: string]: mixed,
  },
  Stringifiers: $ObjMap<TypedParams, ExtractParamValueType>,
>(
  typedParams: TypedParams,
  stringifiers: Stringifiers,
  // Note: return type could be { [key: ($Keys<TypedParams> & $Keys<Stringifiers>)]: ?string } but
  // there's a flow issue: https://github.com/facebook/flow/issues/3325
): { [key: string]: ?string } {
  const paramKeys = Object.keys(typedParams);
  const valueKeys = Object.keys(stringifiers);
  const commonKeys = paramKeys.filter(k => valueKeys.includes(k));
  return commonKeys.reduce((acc, paramName) => {
    const conversionFunction = stringifiers[paramName];
    return Object.assign(acc, {
      [paramName]:
        typedParams[paramName] != null ? conversionFunction(typedParams[paramName]) : null,
    });
  }, {});
}
