/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { EditableDeclineReasonsRow_declineReason } from './__generated__/EditableDeclineReasonsRow_declineReason.graphql';

import CheckBox from '../form/CheckBox';
import TextInput from 'components/material/TextInput';

const Action = styled.div`
  margin-left: 10px;
  cursor: pointer;
  color: #d8d8d8;
  opacity: 0;
  visibility: hidden;
  transition: 0.1s;
  &:hover {
    color: #aaa;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  &:hover {
    ${Action} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const InputContainer = styled.div`
  width: 50%;
  margin: -7px 0 -7px 8px;
`;

class EditableDeclineReasonsRow extends React.PureComponent<
  {
    declineReason: EditableDeclineReasonsRow_declineReason,
    active: boolean,
    onToggleChecked: (declineReasonId: string) => void,
    onUpdate: (declineReasonId: string, name: string) => void,
  },
  { editing: boolean },
> {
  state = {
    editing: false,
  };

  handleToggleChecked = () => {
    this.props.onToggleChecked(this.props.declineReason.id);
  };

  handleStartEdit = () => {
    this.setState({ editing: true });
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ editing: false });

    const value = e.currentTarget.value.trim();

    if (value) {
      this.props.onUpdate(this.props.declineReason.id, value);
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      this.setState({ editing: false });
    } else if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  render() {
    return (
      <Row>
        <CheckBox
          compact
          checked={this.props.active}
          label={this.state.editing ? undefined : this.props.declineReason.name}
          onChange={this.handleToggleChecked}
        />
        {this.state.editing && (
          <InputContainer>
            <TextInput
              autoFocus
              defaultValue={this.props.declineReason.name}
              onBlur={this.handleBlur}
              onKeyDown={this.handleKeyDown}
            />
          </InputContainer>
        )}
        {!this.state.editing && (
          <Action onClick={this.handleStartEdit}>
            <i className="fa fa-fw fa-pencil" />
          </Action>
        )}
      </Row>
    );
  }
}

export default createFragmentContainer(
  EditableDeclineReasonsRow,
  graphql`
    fragment EditableDeclineReasonsRow_declineReason on DeclineReason {
      id
      name
    }
  `,
);
