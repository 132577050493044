/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ScheduleDayHeader_scheduleDay$ref = any;
type ScheduleList_scheduleDay$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduleDay_scheduleDay$ref: FragmentReference;
export type ScheduleDay_scheduleDay = {|
  +id: string,
  +date: any,
  +title: ?string,
  +scheduleItems: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +schedule: {|
    +id: string,
    +dbId: number,
    +name: string,
    +viewerCanUpdate: boolean,
  |},
  +$fragmentRefs: ScheduleDayHeader_scheduleDay$ref & ScheduleList_scheduleDay$ref,
  +$refType: ScheduleDay_scheduleDay$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ScheduleDay_scheduleDay",
  "type": "ScheduleDay",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ScheduleDayHeader_scheduleDay",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ScheduleList_scheduleDay",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "scheduleItems",
      "storageKey": null,
      "args": null,
      "concreteType": "ScheduleItemRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ScheduleItemRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ScheduleItem",
              "plural": false,
              "selections": [
                v0
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "schedule",
      "storageKey": null,
      "args": null,
      "concreteType": "Schedule",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "dbId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "viewerCanUpdate",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8c00862e528f19d83b1f87f757b34af';
module.exports = node;
