/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import type { History, Match } from 'react-router';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { type IntegrationSettings } from 'components/integrations/IntegrationsSettings';

import MarketoContent from './MarketoContent';

import { type SalesforceQueryResponse } from './__generated__/MarketoQuery.graphql';

const query = graphql`
  query MarketoQuery {
    org {
      ...MarketoContent_org
    }
  }
`;

export default class Salesforce extends React.Component<{
  match: Match,
  history: History,
  integration: IntegrationSettings,
}> {
  render() {
    const { match, history, integration } = this.props;

    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(props: SalesforceQueryResponse) => (
          <MarketoContent
            org={props.org}
            integration={integration}
            match={match}
            history={history}
          />
        )}
      />
    );
  }
}
