/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import IntegrationSetupStepper, {
  type StepPageComponentType,
} from 'components/integrations/IntegrationSetupStepper';
import { type IntegrationSettings } from 'components/integrations/IntegrationsSettings';
import IntegrationSettingsIcon from 'components/integrations/IntegrationsSettings/IntegrationSettingsIcon';
import Window, { WindowClose } from 'components/material/Window';

import getSalesforceComponentsConfig from './lib/getSalesforceComponentsConfig';

import { type SalesforceWindow_org } from './__generated__/SalesforceWindow_org.graphql';

const Sidebar = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 50px 0;
  border-radius: 3px 0 0 3px;
  background: ${props => props.color};
  overflow-y: auto;

  @media (${props => props.theme.mobileOnly}) {
    width: 150px;
  }
`;

const StyledIntegrationSettingsIcon = styled(IntegrationSettingsIcon)`
  svg {
    path:first-of-type {
      fill: #fff;
    }

    path:nth-of-type(2),
    path:nth-of-type(3) {
      fill: #00a1e0;
    }
  }
`;

const Name = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
`;

const Container = styled.div`
  display: flex;
  height: calc(100vh - 100px);
`;

class SalesforceWindow extends React.PureComponent<{
  integration: IntegrationSettings,
  org: SalesforceWindow_org,
  onClose: () => void,
}> {
  render() {
    const { integration, org, onClose } = this.props;
    const salesforceAccount = org.salesforceAccount;

    const contactsSyncEnabled = !!salesforceAccount && salesforceAccount.contactsSyncEnabled;
    const opportunitiesSyncEnabled =
      !!salesforceAccount && salesforceAccount.opportunitiesSyncEnabled;

    const filteredComponents = getSalesforceComponentsConfig(
      contactsSyncEnabled,
      opportunitiesSyncEnabled,
    );

    return (
      <Window onHide={onClose} size="xxxlarge">
        <WindowClose onClick={onClose} />

        <Container>
          <Sidebar color={integration.color}>
            <StyledIntegrationSettingsIcon
              integration={{ ...integration, color: '#fff' }}
              size={85}
            />

            <Name>{integration.name} Integration</Name>
          </Sidebar>

          <IntegrationSetupStepper
            onFinish={onClose}
            components={filteredComponents}
            integration={integration}
            renderPageByStep={(currentStep: StepPageComponentType) => (
              <currentStep.component org={org} />
            )}
          />
        </Container>
      </Window>
    );
  }
}

export default createFragmentContainer(
  SalesforceWindow,
  graphql`
    fragment SalesforceWindow_org on Org {
      ...SalesforceAuth_org
      ...SalesforceSettings_org
      salesforceAccount {
        contactsSyncEnabled
        opportunitiesSyncEnabled
      }
    }
  `,
);
