/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import type { ContactTitle_contact } from './__generated__/ContactTitle_contact.graphql';

class ContactTitle extends React.Component<{
  contact: ContactTitle_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  handleUpdate = (value: ?string) => {
    if (!this.getError(value) && value !== this.props.contact.title) {
      this.props.onUpdate({ title: value });
      this.props.updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    if (this.props.fieldSettings.required && (!value || !value.trim())) {
      return 'Required';
    }

    return null;
  };

  render() {
    const { contact, fieldSettings, updateColumnWidth } = this.props;
    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={contact.title || ''}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        disabled={fieldSettings.restrictChangingValue}
        onChange={this.handleUpdate}
      >
        {contact.title && <InlineExpandableText>{contact.title}</InlineExpandableText>}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  ContactTitle,
  graphql`
    fragment ContactTitle_contact on Contact {
      title
    }
  `,
);
