/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import TextField from 'components/material/TextField';

const ClickToEdit = styled.i`
  position: absolute;
  top: 15px;
  right: 0;
  display: none;
  cursor: pointer;
  color: #afb3b7;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  &:hover {
    ${ClickToEdit} {
      display: inline;
    }
  }
`;

const StyledTextField = styled(TextField)`
  input {
    padding-right: 20px;
  }

  ${props =>
    !props.editing &&
    !props.disabled &&
    css`
      > input {
        cursor: pointer;
        color: ${props.theme.primaryActionColor};
      }
    `};
  ${props =>
    props.disabledBorder &&
    css`
      > input {
        border-style: dashed;
        &:hover {
          border-style: dashed;
        }
      }
    `};
`;

export default class EditableLinkField extends React.PureComponent<
  {
    label?: string,
    defaultValue?: ?string,
    className?: string,
    name?: string,
    onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
    error?: ?string,
    readOnly?: boolean,
    to?: string,
    className?: string,
    required?: boolean,
  },
  {
    editing: boolean,
  },
> {
  state = {
    editing: !this.props.readOnly && !this.props.defaultValue,
  };

  textField: ?HTMLInputElement;

  handleClick = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.state.editing === false) {
      if (this.textField && (!this.textField.value || !!this.props.error)) {
        this.handleStartEdit();
      } else {
        window.open(this.props.to || e.currentTarget.value);
      }
    }
  };

  handleStartEdit = () => {
    if (this.props.readOnly) return;

    this.setState({ editing: true });
    if (this.textField) {
      this.textField.focus();
    }
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    this.setState({ editing: false });
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  render() {
    return (
      <Container className={this.props.className}>
        <StyledTextField
          inputRef={ref => {
            this.textField = ref;
          }}
          editing={this.state.editing || !!this.props.error}
          label={this.props.label}
          defaultValue={this.props.defaultValue}
          name={this.props.name}
          error={this.props.error}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          readOnly={!this.state.editing}
          onClick={this.handleClick}
          required={this.props.required}
          disabled={this.props.readOnly && !this.props.defaultValue}
          disabledBorder={this.props.readOnly}
        />
        {!this.props.readOnly && !this.state.editing && (
          <ClickToEdit className="fa fa-fw fa-pencil" onClick={this.handleStartEdit} />
        )}
      </Container>
    );
  }
}
