/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import SelectField from 'components/material/SelectField';

import type { SubmissionReviewTeam_me } from './__generated__/SubmissionReviewTeam_me.graphql';
import type { SubmissionReviewTeam_org } from './__generated__/SubmissionReviewTeam_org.graphql';

const Container = styled.div`
  padding: 20px 16px 30px;
`;

class SubmissionReviewTeam extends React.PureComponent<{
  org: SubmissionReviewTeam_org,
  me: SubmissionReviewTeam_me,
  teamId: ?string,
  disabled: boolean,
  onChangeTeamId: (teamId: string) => void,
}> {
  handleChangeTeamId = (teamId: ?string) => {
    if (!teamId) return;

    this.props.onChangeTeamId(teamId);
  };

  render() {
    const teamOptions = this.props.me.memberships.edges.map(({ node }) => ({
      value: node.id,
      label: node.name,
      disabled: this.props.org.requestsTeam.id === node.id,
    }));

    return (
      <Container>
        <SelectField
          value={this.props.teamId}
          options={teamOptions}
          onChange={this.handleChangeTeamId}
          disabled={this.props.disabled}
          placeholder="Select Team"
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  SubmissionReviewTeam,
  graphql`
    fragment SubmissionReviewTeam_org on Org {
      requestsTeam {
        id
      }
    }

    fragment SubmissionReviewTeam_me on User {
      memberships {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
);
