/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import type { Match } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import OrgVendors from '../AllContacts/OrgVendors';
import Vendor from '../AllContacts/Vendor';

const Vendors = (props: { match: Match }) => {
  const pathPrefix = props.match.path;
  return (
    <DocumentTitle title="Vendors">
      <Switch>
        <Route exact path={pathPrefix} component={OrgVendors} />
        <Route path={`${pathPrefix}/:vendorId`} component={Vendor} />
      </Switch>
    </DocumentTitle>
  );
};

export default Vendors;
