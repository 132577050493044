/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import throttle from 'lodash/throttle';

import fullNameOfUser from 'utils/fullNameOfUser';

import showModernMutationError from 'graph/utils/showModernMutationError';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import AutocompleteInput from 'components/material/AutocompleteInput';

import OrgLeadSearchRow from './OrgLeadSearchRow';

import type { OrgLeadSearchQuery_response } from './__generated__/OrgLeadSearchQuery.graphql';

const StyledAutocompleteInput = styled(AutocompleteInput)`
  flex: 1 1 auto;
  margin-bottom: 15px;
`;

const query = graphql`
  query OrgLeadSearchQuery($query: String!, $skipUserIds: [ID!]) {
    org {
      users(skipUserIds: $skipUserIds, first: 3, query: $query) {
        edges {
          node {
            id
            firstName
            lastName
            avatar
            email
            ...OrgLeadSearchRow_user
          }
        }
      }
    }
  }
`;

export type UserType = {
  id: string,
  firstName: string,
  lastName: string,
  avatar: ?string,
  email: string,
};

export default class OrgLeadSearch extends React.PureComponent<
  {
    defaultValue?: ?UserType,
    skipUserIds?: $ReadOnlyArray<string>,
    onSelect: (lead: ?UserType) => void | Promise<{}>,
    className?: string,
    skipLabel?: boolean,
    required?: boolean,
    disabled?: boolean,
    error?: string,
    clearable?: boolean,
  },
  {
    query: string,
    activeValue: string,
  },
> {
  static getDerivedStateFromProps(
    nextProps: $PropertyType<OrgLeadSearch, 'props'>,
    prevState: $PropertyType<OrgLeadSearch, 'state'>,
  ) {
    const activeValue = nextProps.defaultValue ? fullNameOfUser(nextProps.defaultValue) : '';
    if (activeValue === prevState.activeValue) return null;

    return { activeValue };
  }

  state = {
    query: '',
    activeValue: this.props.defaultValue ? fullNameOfUser(this.props.defaultValue) : '',
  };

  queryExists: boolean;

  cachedUsers: Array<UserType> = [];

  handleFilter = throttle((str: string) => {
    this.setState({ query: str });
    this.checkSearchQueryState(str);
  }, 800);

  checkSearchQueryState = (str?: string = '') => {
    if (str.trim() && !this.queryExists) {
      this.queryExists = true;
    }
    if (!str.trim() && this.queryExists) {
      this.queryExists = false;
    }
  };

  setActiveValue = (lead: ?UserType) => {
    this.setState({ activeValue: lead ? fullNameOfUser(lead) : '' });
  };

  handleSelect = (lead: ?UserType) => {
    Promise.resolve(this.props.onSelect(lead))
      .then(() => this.setActiveValue(lead))
      .catch(showModernMutationError);
  };

  renderOption = (user: UserType) => <OrgLeadSearchRow user={user} />;

  renderInput = (response?: OrgLeadSearchQuery_response) => {
    this.cachedUsers =
      response && response.org ? response.org.users.edges.map(edge => edge.node) : this.cachedUsers;

    const labelProps = {
      label: this.props.skipLabel ? '' : 'Event Leader',
      ...(this.props.skipLabel ? { placeholder: 'Event Leader' } : null),
    };

    return (
      <StyledAutocompleteInput
        className={this.props.className}
        defaultQuery={this.state.activeValue}
        required={this.props.required}
        error={this.props.error}
        options={this.cachedUsers}
        onFilterOptions={this.handleFilter}
        onSelect={this.handleSelect}
        renderOption={this.renderOption}
        renderOptionString={fullNameOfUser}
        disabled={this.props.disabled}
        clearable={this.props.clearable}
        showOptionsOnEmpty
        resetOnBlur
        {...labelProps}
      />
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          query: this.state.query,
          skipUserIds: this.props.skipUserIds || [],
        }}
        renderSuccess={this.renderInput}
        renderLoading={this.renderInput}
      />
    );
  }
}
