/* @flow */
import React from 'react';
import styled from 'styled-components';

import Button, { MinimalButton } from 'components/budget/Button';

import { type ContactType } from './ContactTypeSelector';

const Root = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  a {
    font-size: 13px;
    color: #3aa8da;
    text-decoration: underline;

    &:hover {
      color: #009dce;
    }
  }

  button {
    margin-left: 30px;
  }
`;

const LinkContainer = styled.div`
  flex: 1 1 auto;
`;

type Props = {
  contactType: ContactType,
  showManageLink: boolean,
  loading: boolean,
  onCancel: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onSubmit: (event: SyntheticEvent<HTMLButtonElement>) => void,
};

const ContactWindowFooter = ({
  contactType,
  showManageLink,
  loading,
  onCancel,
  onSubmit,
}: Props) => (
  <Root>
    <LinkContainer>
      {showManageLink && (
        <a
          href={`/settings/contacts/${contactType === 'contacts' ? 'people' : contactType}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          Manage Form Settings
        </a>
      )}
    </LinkContainer>

    <MinimalButton label="Cancel" onClick={onCancel} />

    <Button onClick={onSubmit} loading={loading}>
      Create
    </Button>
  </Root>
);

export default ContactWindowFooter;
