/* @flow */
import React from 'react';
import styled from 'styled-components';
import differenceBy from 'lodash/differenceBy';

import AttachmentPreview from 'components/RelayAttachments/AttachmentPreview';

import SectionHeader from '../components/SectionHeader';
import SectionContent from '../components/SectionContent';
import BriefImage, { type AttachmentType } from '../components/BriefImage';
import BriefLink from '../components/BriefLink';

const LinkAttachments = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

class AttachmentsBrief extends React.Component<
  {
    attachments: $ReadOnlyArray<AttachmentType>,
    primaryColor: string,
  },
  { previewId: string },
> {
  state = { previewId: '' };

  handlePreview = (previewId: string) => {
    this.setState({ previewId });
  };

  render() {
    const { primaryColor, attachments } = this.props;

    const { previewId } = this.state;
    const imageAttachments = (attachments || []).filter(a => {
      return (
        (a && a.filetype && a.filetype.startsWith('image')) || a.filetype === 'application/pdf'
      );
    });
    const linkAttachments = differenceBy(attachments, imageAttachments, 'id');
    return (
      <React.Fragment>
        <SectionHeader primaryColor={primaryColor} name="ATTACHMENTS" anchor="attachments" />
        <SectionContent>
          <ImagesContainer>
            {imageAttachments.map(
              a => a && <BriefImage key={a.id} attachment={a} onPreview={this.handlePreview} />,
            )}
          </ImagesContainer>
          <LinkAttachments>
            {linkAttachments.map(
              l => l && <BriefLink key={l.id} attachment={l} onPreview={this.handlePreview} />,
            )}
          </LinkAttachments>
          {previewId && (
            <AttachmentPreview
              attachments={[]}
              mockAttachments={attachments}
              previewId={previewId}
              onPreview={this.handlePreview}
              onRemove={null}
            />
          )}
        </SectionContent>
      </React.Fragment>
    );
  }
}

export default AttachmentsBrief;
