/* @flow */
import React from 'react';
import styled from 'styled-components';

import VendorSettingsPage from '../ContactSettings/VendorSettings';

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  padding-bottom: 100px;
`;

const Title = styled.div`
  margin: 25px 0 21px 25px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const VendorSettings = () => {
  return (
    <>
      <Title>Vendors Settings</Title>
      <Container>
        <VendorSettingsPage />
      </Container>
    </>
  );
};

export default VendorSettings;
