/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Location, type Match, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Window, { WindowClose } from 'components/material/Window';

import SubmissionReviewContent from './SubmissionReviewContent';

import type { SubmissionReviewQueryResponse } from './__generated__/SubmissionReviewQuery.graphql';

const query = graphql`
  query SubmissionReviewQuery($submissionId: ID!) {
    submission: node(id: $submissionId) {
      ...SubmissionReviewContent_submission
    }
    org {
      ...SubmissionReviewContent_org
    }
    me {
      ...SubmissionReviewContent_me
    }
  }
`;

const StyledWindow = styled(Window)`
  height: 100%;
  background: #f8f9f9;
`;

const StyledWindowClose = styled(WindowClose)`
  top: -2px;
  right: auto;
  left: 100%;
  width: 25px;
  height: 25px;
  margin-left: 35px;
  color: #fff;
  font-size: 55px;
  opacity: 0.8;
  &:hover {
    color: #fff;
    opacity: 1;
  }
`;

export default class SubmissionReview extends React.Component<{
  location: Location,
  history: RouterHistory,
  match: Match,
}> {
  closeWindow = () => {
    const search = this.props.location.search;

    this.props.history.push(`/dashboard/event_requests${search}`);
  };

  render() {
    const submissionId = this.props.match.params.submissionId;

    if (!submissionId) return null;

    return (
      <StyledWindow size="xxxlarge" onHide={this.closeWindow}>
        <DefaultQueryRenderer
          query={query}
          variables={{ submissionId }}
          renderSuccess={(response: SubmissionReviewQueryResponse) => (
            <SubmissionReviewContent
              submission={response.submission}
              org={response.org}
              me={response.me}
              onCancel={this.closeWindow}
              location={this.props.location}
            />
          )}
        />

        <StyledWindowClose onClick={this.closeWindow} />
      </StyledWindow>
    );
  }
}
