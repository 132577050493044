/* @flow */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDateTimeRange from 'utils/date/formatDateTimeRange';

import CalendarIcon from 'images/calendarIcon.svg';
import DateTimeRangePicker, {
  type DatesConfig,
  InputWrapper as DateTimeRangePickerInputWrapper,
} from 'components/date/DateTimeRangePicker';
import { Input as TextFieldInput, Root as TextFieldRoot } from 'components/material/TextField';

import Detail from './Detail';

type Dates = {
  startDate: ?string,
  endDate: ?string,
  startDateAllDay: ?boolean,
  endDateAllDay: ?boolean,
};

const Container = styled.div`
  padding: 0 !important;
  ${DateTimeRangePickerInputWrapper} {
    ${TextFieldRoot} {
      margin: 0;
      ${TextFieldInput} {
        padding: 0 3px;
      }
    }
    &:first-child {
      margin-right: 10px;
    }
  }
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  color: #868f96;
  margin: -2px 5px 0 0;
`;

export default class DateComponent extends React.Component<
  Dates & {
    tz: string,
    disabled: boolean,
    onChange: Dates => void,
    id?: string,
  },
  {
    shown: boolean,
    datesConfig: Dates,
    initialProperties: Dates,
  },
> {
  state = {
    shown: false,
    datesConfig: {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      startDateAllDay: !!this.props.startDateAllDay,
      endDateAllDay: !!this.props.endDateAllDay,
    },
    initialProperties: {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      startDateAllDay: !!this.props.startDateAllDay,
      endDateAllDay: !!this.props.endDateAllDay,
    },
  };

  static getDerivedStateFromProps(
    props: $PropertyType<DateComponent, 'props'>,
    state: $PropertyType<DateComponent, 'state'>,
  ) {
    const changedProperties = {};
    if (props.startDate !== state.initialProperties.startDate) {
      changedProperties.startDate = props.startDate;
    }
    if (props.endDate !== state.initialProperties.endDate) {
      changedProperties.endDate = props.endDate;
    }
    if (props.startDateAllDay !== state.initialProperties.startDateAllDay) {
      changedProperties.startDateAllDay = props.startDateAllDay;
    }
    if (props.endDateAllDay !== state.initialProperties.endDateAllDay) {
      changedProperties.endDateAllDay = props.endDateAllDay;
    }
    return {
      datesConfig: { ...state.datesConfig, ...changedProperties },
      initialProperties: { ...state.initialProperties, ...changedProperties },
    };
  }

  handleChange = (datesConfig: DatesConfig) => {
    this.setState({
      datesConfig: {
        startDate: datesConfig.startDate,
        endDate: datesConfig.endDate,
        startDateAllDay: datesConfig.hideStartTime,
        endDateAllDay: datesConfig.hideEndTime,
      },
    });
  };

  show = () => {
    if (!this.props.disabled) {
      this.setState({
        shown: true,
      });
    }
  };

  hide = () => {
    const { startDate, endDate, startDateAllDay, endDateAllDay } = this.state.datesConfig;
    if (
      startDate !== this.props.startDate ||
      endDate !== this.props.endDate ||
      startDateAllDay !== this.props.startDateAllDay ||
      endDateAllDay !== this.props.endDateAllDay
    ) {
      this.props.onChange({
        startDate,
        endDate,
        startDateAllDay,
        endDateAllDay,
      });
    }
    this.setState({
      shown: false,
    });
  };

  render() {
    const tz = this.props.tz;

    const {
      shown,
      datesConfig: { startDate, endDate, startDateAllDay, endDateAllDay },
    } = this.state;
    if (!shown) {
      return (
        <Detail
          onClick={this.show}
          className="event-date"
          id={this.props.id}
          text={
            startDate &&
            formatDateTimeRange({
              startDate: startDate ? moment(startDate).tz(tz) : null,
              endDate: endDate ? moment(endDate).tz(tz) : null,
              startDateAllDay,
              endDateAllDay,
              tz,
            })
          }
          placeholder="Add date"
          icon={<StyledCalendarIcon />}
          hideIconOnText
        />
      );
    }
    return (
      <Container className="event-detail event-date active">
        <DateTimeRangePicker
          tz={tz}
          startDate={startDate}
          endDate={endDate}
          hideStartTime={startDateAllDay == null || startDateAllDay}
          hideEndTime={endDateAllDay == null || endDateAllDay}
          startPlaceholder="Start Date"
          endPlaceholder="End Date"
          onChange={this.handleChange}
          onBlur={this.hide}
          autoFocus
        />
      </Container>
    );
  }
}
