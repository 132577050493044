/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';

import getHomepageUrl from 'utils/getHomepageUrl';

import CircaLogo from 'images/circaLogoNoHex.svg';
import BurgerMenuIconSvg from 'images/header/burger_menu.svg';
import Button from 'components/budget/Button';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import HamburgerMenu from 'components/Hamburger';
import MaterialAvatar from 'components/material/MaterialAvatar';

import NavContext from '../Navbar/NavContext';
import HeaderProfileMenu from './HeaderProfileMenu';
import Search from './Search';

import type { HeaderQueryResponse } from './__generated__/HeaderQuery.graphql';

const Container = styled.header`
  flex-shrink: 0;
  position: relative;
  z-index: 887;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 55px;
  padding-bottom: 12px;
  background: #000;
  color: #fff;
`;

const Logo = styled(Link)`
  flex: 0 0 auto;
  align-self: flex-start;
  height: 25px;
  width: 65px;
  margin: 15px 16px 0 ${props => (props.showMobileMenu ? '0px' : '16px')};
`;

const RightItems = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
`;

const Profile = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-end;
  margin-right: 22px;
  padding: 4px 5px 4px ${props => (props.showMobileMenu ? '5px' : '15px')};
  border-radius: 16px;
  cursor: pointer;
  &:hover {
    background-color: #252b32;
  }
  @media (max-width: 680px) {
    margin-right: 0;
  }
`;

const BurgerMenuIcon = styled(BurgerMenuIconSvg)`
  flex: 0 0 auto;
  width: 20px;
  height: 14px;
  margin-right: 10px;
`;

const ActionElements = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  margin-top: 11px;
`;

const TrialExpiryMessage = styled.div`
  margin-right: 12px;
  font-size: 14px;
  line-height: 16px;
  color: white;

  > div:first-of-type {
    font-weight: 500;
  }

  @media (max-width: 550px) {
    display: none;
  }
`;

const query = graphql`
  query HeaderQuery {
    me {
      id
      homepage
      homepageListId
      ...MaterialAvatar_user
      ...HeaderProfileMenu_user
    }
    org {
      analyticsEnabled
      subscription {
        plan
        expiresAt
        upgradeRequired
      }
    }
  }
`;

class Header extends React.PureComponent<{ showMobileMenu: boolean }, { showMenu: boolean }> {
  state = {
    showMenu: false,
  };

  menuContainer = React.createRef();

  menuTarget = React.createRef();

  handleUpgradeClick = () => {
    window.open(
      'mailto:help@simplecirca.com?subject=Upgrade%20Request&body=Hi,%0A%0AI%20would%20like%20to%20upgrade...',
      '_blank',
    );
  };

  handleShowMenu = () => {
    this.setState({ showMenu: true });
  };

  handleHideMenu = () => {
    this.setState({ showMenu: false });
  };

  renderHeader = ({ me, org }: HeaderQueryResponse) => {
    const subscription = org.subscription;
    const trialExpiresSoon =
      subscription.plan === 'TRIAL' &&
      !subscription.upgradeRequired &&
      subscription.expiresAt !== null;
    const trialExpiresInDays = trialExpiresSoon
      ? moment(subscription.expiresAt).diff(moment(), 'days') + 1
      : null;
    return (
      <Container>
        {this.props.showMobileMenu ? (
          <NavContext.Consumer>
            {({ showSidebar, onToggleSidebar }) => (
              <HamburgerMenu onChange={() => onToggleSidebar('')} open={!!showSidebar} />
            )}
          </NavContext.Consumer>
        ) : null}
        <Logo
          showMobileMenu={this.props.showMobileMenu}
          to={getHomepageUrl(me.homepageListId || me.homepage, me.id)}
        >
          <CircaLogo />
        </Logo>
        <RightItems ref={this.menuContainer}>
          <Search analyticsEnabled={org.analyticsEnabled} />
          <ActionElements>
            {trialExpiresInDays !== null && (
              <>
                <TrialExpiryMessage>
                  <div>Free trial</div>
                  <div>{trialExpiresInDays} days left</div>
                </TrialExpiryMessage>
                <Button onClick={this.handleUpgradeClick}>Upgrade</Button>
              </>
            )}
            <Profile
              showMobileMenu={this.props.showMobileMenu}
              ref={this.menuTarget}
              onClick={this.handleShowMenu}
            >
              {!this.props.showMobileMenu && <BurgerMenuIcon />}
              <MaterialAvatar user={me} radius={12.5} />
            </Profile>
          </ActionElements>

          <HeaderProfileMenu
            show={this.state.showMenu}
            onHide={this.handleHideMenu}
            target={this.menuTarget && this.menuTarget.current}
            container={this.menuContainer && this.menuContainer.current}
            user={me}
          />
        </RightItems>
      </Container>
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={this.renderHeader}
        renderLoading={() => null}
      />
    );
  }
}

export default Header;
