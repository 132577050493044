/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import addAttachmentToBrief from 'graph/mutations/event_brief/addAttachmentToBrief';
import removeAttachmentFromBrief from 'graph/mutations/event_brief/removeAttachmentFromBrief';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { CircaFile } from 'utils/uploading/types';
import { download } from 'utils/Attachments';

import Tooltip from 'components/material/Tooltip';

import type { BriefDataType } from './AttachmentPreview';

const AcionContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  display: none;
  justify-content: flex-end;
  > div {
    display: flex;
    flex: 0 auto;
    align-items: center;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      i {
        color: #37404c;
      }
    }
    i {
      flex: 1 1 auto;
      color: #96a2ab;
    }
  }
`;

const ImagePreview = styled.div`
  margin-right: 5px;
`;

const ImageDownload = styled.div`
  margin-right: 5px;
`;

const Container = styled.div`
  position: relative;
  width: 130px;
  height: 130px;
  margin: 0 20px 20px 0;
  &:hover {
    ${AcionContainer} {
      display: flex;
    }
  }
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 auto;
  width: 100%;
  height: 100%;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

const Attachment = styled.img`
  max-width: 128px;
  max-height: 128px;
  cursor: pointer;
`;

export default class AttachmentThumbnail extends React.PureComponent<{
  attachment: CircaFile,
  onRemove?: (attachmentId: string) => void,
  onPreview: (attachmentId: string) => void,
  readOnly?: boolean,
  briefData?: BriefDataType,
}> {
  handleRemoveClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.onRemove && this.props.attachment.id != null) {
      this.props.onRemove(this.props.attachment.id);
    }
  };

  handleDownloadClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    download(this.props.attachment.fileurl, this.props.attachment.filename);
  };

  handleClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.isLink()) {
      window.open(this.props.attachment.fileurl);
    } else if (this.props.attachment.id != null) {
      this.props.onPreview(this.props.attachment.id);
    }
  };

  handleSaveBrief = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { briefData, attachment } = this.props;
    if (briefData) {
      if (briefData.briefAttachmentIds.includes(attachment.id)) {
        if (!attachment.id) return;
        removeAttachmentFromBrief(attachment.id, briefData.eventId).catch(showModernMutationError);
      } else {
        if (!attachment.id) return;
        addAttachmentToBrief(attachment.id, briefData.eventId).catch(showModernMutationError);
      }
    }
  };

  isLink() {
    return this.props.attachment.filetype && this.props.attachment.filetype.startsWith('link/');
  }

  render() {
    const {
      attachment: { id, fileurl, filename, filetype },
      briefData,
    } = this.props;
    const urlParts = fileurl ? fileurl.split('/') : [];
    const thumbnailUrl =
      filetype === 'application/pdf'
        ? `https://process.filestackapi.com/output=format:jpg/resize=height:180,width:180/${
            urlParts[urlParts.length - 1]
          }`
        : fileurl;
    const includedInBrief = briefData && briefData.briefAttachmentIds.includes(id);

    return (
      <Container>
        <AcionContainer>
          <ImagePreview onClick={this.handleSaveBrief}>
            <Tooltip label="Add to Brief" placement="top">
              <i className={classNames('fa fa-fw', includedInBrief ? 'fa-minus' : 'fa-plus')} />
            </Tooltip>
          </ImagePreview>
          <ImageDownload onClick={this.handleDownloadClick}>
            <Tooltip label="Download" placement="top">
              <i className="fa fa-fw fa-download" />
            </Tooltip>
          </ImageDownload>
          <ImagePreview onClick={this.handleClick}>
            <Tooltip label="Preview" placement="top">
              <i className="fa fa-fw fa-search" />
            </Tooltip>
          </ImagePreview>
          {!this.props.readOnly &&
            this.props.onRemove &&
            (this.props.attachment.viewerCanDelete != null
              ? this.props.attachment.viewerCanDelete
              : true) && (
              <ImagePreview onClick={this.handleRemoveClick}>
                <Tooltip label="Delete" placement="top">
                  <i className="fa fa-fw fa-times" />
                </Tooltip>
              </ImagePreview>
            )}
        </AcionContainer>
        <ThumbnailContainer>
          <Attachment src={thumbnailUrl} alt={filename || fileurl} onClick={this.handleClick} />
        </ThumbnailContainer>
      </Container>
    );
  }
}
