/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import updateEventFloorPlan from 'graph/mutations/event_floor_plan/updateEventFloorPlan';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import type { BoothNumber_event } from './__generated__/BoothNumber_event.graphql';

class BoothNumber extends React.PureComponent<{
  event: BoothNumber_event,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
}> {
  handleUpdate = (value: ?string) => {
    const { event, updateColumnWidth } = this.props;
    if (value === event.eventFloorPlan.booth) {
      return;
    }
    updateEventFloorPlan(event.eventFloorPlan.id, { booth: value }).catch(showModernMutationError);
    updateColumnWidth();
  };

  render() {
    const { fieldSettings, event, readOnly, updateColumnWidth } = this.props;
    const booth = event.eventFloorPlan.booth;
    if (readOnly) {
      return <InlineExpandableText>{booth}</InlineExpandableText>;
    }
    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={booth || ''}
        onChange={this.handleUpdate}
        updateColumnWidth={updateColumnWidth}
      >
        {booth && <InlineExpandableText>{booth}</InlineExpandableText>}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  BoothNumber,
  graphql`
    fragment BoothNumber_event on Event {
      eventFloorPlan {
        id
        booth
      }
    }
  `,
);
