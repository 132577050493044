/* @flow */
import React from 'react';
import styled from 'styled-components';

import CheckBox from 'components/EventRequestForm/form/CheckBox';

const EmailFieldsCheckBoxRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  > * + * {
    margin-top: 12px;
  }
`;

export default class BudgetReportingSchedulerFields extends React.PureComponent<{
  budgetReportingSchedulerFields: ?{ +includePayments: boolean, +includeExpenses: boolean },
  handleIncludePaymentsChange: () => void,
  handleIncludeExpensesChange: () => void,
}> {
  render() {
    const { budgetReportingSchedulerFields } = this.props;

    return (
      budgetReportingSchedulerFields != null && (
        <EmailFieldsCheckBoxRow>
          <CheckBox
            compact
            checked={budgetReportingSchedulerFields.includeExpenses}
            label="Include Expenses"
            onChange={this.props.handleIncludeExpensesChange}
          />
          <CheckBox
            compact
            checked={budgetReportingSchedulerFields.includePayments}
            label="Include Payments"
            onChange={this.props.handleIncludePaymentsChange}
          />
        </EmailFieldsCheckBoxRow>
      )
    );
  }
}
