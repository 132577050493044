/* @flow */
import styled from 'styled-components';

/**
 * TODO: we have inconsistent window headers.  This one has no close button and blends
 * in with the window color.  We should only use one style of window.
 */
const WindowHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 0;
  position: relative;

  @media (${props => props.theme.mobileOnly}) {
    padding-top: 11px;
  }
`;
export default WindowHeader;
