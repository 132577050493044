/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { darken } from 'polished';

import fonts from 'config/fonts';
import personalizationDefaults from 'config/personalizationDefaults';

import { isBright } from 'utils/color';
import { loadFonts } from 'utils/fonts';
import type { CircaFile } from 'utils/uploading/types';

import BgImageSelectWindow from 'components/material/BgImageSelectWindow';
import Button from 'components/material/Button';
import ColorSelector from 'components/material/ColorPicker/ColorSelector';
import SelectField, { Field } from 'components/material/SelectField';
import AttachmentOverlay from 'components/RelayAttachments/AttachmentOverlay';

import { type EventListDesign_orgSettings } from './__generated__/EventListDesign_orgSettings.graphql';

const ActionsWrapper = styled.div`
  flex: 1 1 auto;
  text-align: right;
  line-height: 1em;
`;

const ActionButton = styled.a`
  margin-left: 14px;
  font-size: 13px;
  color: #009dce;
  &:hover,
  &:active {
    color: ${darken(0.05, '#009dce')};
  }
  text-decoration: underline;
  cursor: pointer;
`;

const Logo = styled.img`
  max-width: 160px;
  max-height: 80px;
`;

const StyledSelectField = styled(SelectField)`
  width: 150px;

  ${Field} {
    padding-left: 10px;
    padding-right: 5px;
    border-width: 1px;
    border-radius: 3px;
  }
`;

const Remover = styled.div`
  position: absolute;
  display: none;
  top: -7px;
  right: -7px;
  width: 15px;
  height: 15px;
  line-height: 14px;
  font-size: 15px;
  font-weight: 500;
  background: #f7f8f9;
  color: #868f96;
  border-radius: 20px;
  text-align: center;
  transform: rotate(-45deg);
  cursor: pointer;
  &:hover {
    color: #4a5665;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  &:hover {
    ${Remover} {
      display: block;
    }
  }
`;

const Image = styled.img`
  width: 40px;
  cursor: pointer;
`;

const SectionContainer = styled.div`
  padding: 20px 20px 20px 25px;
  border-bottom: 1px solid #d2d9f5;
  &:first-child {
    padding-top: 9px;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const SectionTitle = styled.div`
  flex: 0 1 auto;
  font-size: 14px;
  font-weight: 600;
  color: #29305d;
`;

const SectionRow = styled.div`
  display: flex;
  padding: 8px 0;
`;

const SectionRowLabel = styled.div`
  flex: 1 1 auto;
  padding: 5px 0;
  font-size: 14px;
  color: #464d72;
`;

const AddButton = styled(Button)`
  width: 53px;
  height: 24px;
  margin-top: 8px;
  padding: 0;
  border-radius: 2px;
  font-size: 12px;
`;

export type EventListDesignFields = {
  logo: ?string,
  font: ?string,
  backgroundUrl: ?string,
  backgroundColor: ?string,
  titleColor: ?string,
};

class EventListDesign extends React.PureComponent<
  {
    orgSettings: EventListDesign_orgSettings,
    eventList: EventListDesignFields,
    onEventListChange: ($Shape<EventListDesignFields>) => void,
  },
  {
    showBgImageSelectWindow: boolean,
    showPickerMenu: boolean,
  },
> {
  state = {
    showBgImageSelectWindow: false,
    showPickerMenu: false,
  };

  uploadLogoButton: React.createRef<typeof ActionButton> = React.createRef();

  componentDidMount() {
    loadFonts(fonts.map(font => font.value));
  }

  handleClickUploadBg = () => {
    this.setState({ showBgImageSelectWindow: true });
  };

  handleBgImageWindowHide = () => {
    this.setState({ showBgImageSelectWindow: false });
  };

  handleClickUploadLogo = () => {
    this.setState({ showPickerMenu: true });
  };

  hidePickerMenu = () => {
    this.setState({ showPickerMenu: false });
  };

  handleSaveBgImage = (backgroundUrl: ?string) => {
    this.props.onEventListChange({
      backgroundUrl,
    });
  };

  handleRemoveBgImage = () => {
    this.props.onEventListChange({
      backgroundUrl: null,
    });
  };

  handleRemoveLogo = () => {
    this.props.onEventListChange({
      logo: null,
    });
  };

  handleAddLogo = (attachment: CircaFile) => {
    this.props.onEventListChange({
      logo: attachment.fileurl,
    });
  };

  handleSetDefaultLogo = () => {
    this.props.onEventListChange({
      logo: this.props.orgSettings.logo,
    });
  };

  handleChangeBackgroundColor = (color: string) => {
    this.props.onEventListChange({
      backgroundColor: color,
    });
  };

  handleChangeTitleColor = (color: string) => {
    this.props.onEventListChange({
      titleColor: color,
    });
  };

  handleSetDefaultFont = () => {
    this.handleChangeFont(this.props.orgSettings.font);
  };

  handleChangeFont = (value: ?string) => {
    this.props.onEventListChange({
      font: value,
    });
  };

  titleColor = () => {
    const { eventList, orgSettings } = this.props;

    if (eventList.titleColor) {
      return eventList.titleColor;
    }

    if (isBright(eventList.backgroundColor || orgSettings.primaryColor)) {
      return '#000000';
    }

    return '#ffffff';
  };

  render() {
    const { eventList, orgSettings } = this.props;
    const isOrgLogo = eventList.logo && eventList.logo === orgSettings.logo;
    const isOrgFont = !eventList.font || eventList.font === orgSettings.font;
    return (
      <>
        <div>
          <SectionContainer>
            <SectionHeader>
              <SectionTitle>Logo</SectionTitle>
              <ActionsWrapper>
                {!isOrgLogo && (
                  <ActionButton onClick={this.handleSetDefaultLogo}>Use Org Logo</ActionButton>
                )}
                <ActionButton onClick={this.handleClickUploadLogo} ref={this.uploadLogoButton}>
                  Upload
                </ActionButton>
              </ActionsWrapper>
            </SectionHeader>
            <AttachmentOverlay
              target={this.uploadLogoButton.current}
              container={this}
              onHide={this.hidePickerMenu}
              show={this.state.showPickerMenu}
              onUploaded={this.handleAddLogo}
              hideSources={['googledrive']}
              maxDimensions={[220, 110]}
              hideLink
              singleUpload
              allowOnlyImages
              forceRightEdge
            />
            {eventList.logo && (
              <ImageWrapper>
                <Logo src={eventList.logo} />
                <Remover onClick={this.handleRemoveLogo}>+</Remover>
              </ImageWrapper>
            )}
          </SectionContainer>

          <SectionContainer>
            <SectionHeader>
              <SectionTitle>Background</SectionTitle>
            </SectionHeader>

            <SectionRow>
              <SectionRowLabel>Background Color</SectionRowLabel>

              <ColorSelector
                orgSettings={orgSettings}
                currentColor={eventList.backgroundColor}
                onChangeColor={this.handleChangeBackgroundColor}
                includeTransparent
                alignTo="bottom-right"
              />
            </SectionRow>

            <SectionRow>
              <SectionRowLabel>Background Image</SectionRowLabel>

              {eventList.backgroundUrl ? (
                <ImageWrapper>
                  <Image src={eventList.backgroundUrl} />
                  <Remover onClick={this.handleRemoveBgImage}>+</Remover>
                </ImageWrapper>
              ) : (
                <AddButton onClick={this.handleClickUploadBg} label="Add" primary />
              )}
            </SectionRow>
          </SectionContainer>

          <SectionContainer>
            <SectionHeader>
              <SectionTitle>Font Styles</SectionTitle>
              <ActionsWrapper>
                {!isOrgFont && (
                  <ActionButton onClick={this.handleSetDefaultFont}>Use Org Font</ActionButton>
                )}
              </ActionsWrapper>
            </SectionHeader>

            <SectionRow>
              <SectionRowLabel>Font</SectionRowLabel>

              <StyledSelectField
                options={fonts}
                value={eventList.font || orgSettings.font || personalizationDefaults.font}
                onChange={this.handleChangeFont}
              />
            </SectionRow>

            <SectionRow>
              <SectionRowLabel>Font Color</SectionRowLabel>

              <ColorSelector
                orgSettings={orgSettings}
                currentColor={this.titleColor()}
                onChangeColor={this.handleChangeTitleColor}
                alignTo="bottom-right"
              />
            </SectionRow>
          </SectionContainer>
        </div>

        {this.state.showBgImageSelectWindow && (
          <BgImageSelectWindow
            value={eventList.backgroundUrl}
            onHide={this.handleBgImageWindowHide}
            onSave={this.handleSaveBgImage}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(
  EventListDesign,
  graphql`
    fragment EventListDesign_orgSettings on OrgSettings {
      logo
      font
      primaryColor
      ...ColorSelector_orgSettings
    }
  `,
);
