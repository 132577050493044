/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Description_event$ref = any;
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type General_event$ref: FragmentReference;
export type General_event = {|
  +uniqueId: string,
  +id: string,
  +commitmentLevel: EventStatus,
  +viewerCanUpdate: boolean,
  +mappedToSalesforce: boolean,
  +lead: ?{|
    +id: string,
    +firstName: string,
    +lastName: string,
    +email: string,
    +avatar: ?string,
  |},
  +$fragmentRefs: Description_event$ref,
  +$refType: General_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "General_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "uniqueId",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "commitmentLevel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mappedToSalesforce",
      "args": [
        {
          "kind": "Literal",
          "name": "field",
          "value": "STATE",
          "type": "EventIntegrationFields!"
        }
      ],
      "storageKey": "mappedToSalesforce(field:\"STATE\")"
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "lead",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "email",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "avatar",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "Description_event",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '08039840177d541b636e8075f85b483e';
module.exports = node;
