/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';

const Link = styled.a`
  margin-right: ${props => (props.align === 'right' ? 0 : 13)}px;
  color: hsl(218, 18%, 30%);
  cursor: pointer;
  user-select: none;

  &:hover,
  &:active {
    color: hsl(218, 10%, 50%);
  }
`;

const Icon = styled.i`
  position: absolute;
  margin-left: 5px;
  color: ${props => (props.active ? '#868f96' : '#CDD1D4')};
`;

export default class TableSort<K: string> extends React.PureComponent<{
  children: React.Node,
  sort: SortParam,
  sortKey: K,
  onChange: (sort: SortParam) => void,
  align?: 'left' | 'right',
  primarySortDirection?: 'asc' | 'desc',
}> {
  static defaultProps = { primarySortDirection: 'asc' };

  handleClick = () => {
    const { sortKey, sort, onChange, primarySortDirection } = this.props;

    if (sort.key === sortKey) {
      onChange({ key: sortKey, asc: !sort.asc });
    } else {
      onChange({ key: sortKey, asc: primarySortDirection === 'asc' });
    }
  };

  render() {
    const { children, sort, sortKey, align } = this.props;

    return (
      <Link onClick={this.handleClick} align={align}>
        {children}

        {sort.key === sortKey ? (
          <Icon className={`fa fa-fw fa-caret-${sort.asc ? 'up' : 'down'}`} active />
        ) : (
          <Icon className="fa fa-fw fa-sort" />
        )}
      </Link>
    );
  }
}
