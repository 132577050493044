/**
 * @flow
 * @relayHash 84ff8aa96d051e1658e447eb5e9e4eda
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BriefUserAvatar_user$ref = any;
export type CompanySort = "CITY" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CUSTOM" | "DESCRIPTION" | "LINKEDIN" | "NAME" | "PHONE" | "SALESFORCE_ID" | "STATE" | "STREET" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "WEBSITE" | "ZIP";
export type ContactSaveMethod = "api" | "g2w" | "import" | "manual" | "past_g2w" | "past_zoom" | "registration" | "zapier" | "zoom";
export type ContactSort = "ATTENDANCE_DURATION" | "ATTENDANCE_JOIN_TIME" | "ATTENDANCE_LEAVE_TIME" | "ATTENDANCE_STATUS_ID" | "CITY" | "COMPANY_ID" | "CONTACT_TYPE_ID" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CREATED_METHOD" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "EMAIL_OPT_IN" | "EVENTS" | "HOT_LEAD" | "LAST_SYNCED" | "LINKEDIN" | "MARKETO_ID" | "MEMBERSHIP_LAST_SYNCED" | "MEMBERSHIP_SYNC_STATUS" | "NAME" | "OWNER_ID" | "PHONE1" | "PHONE2" | "REGISTERED_AT" | "REGISTRATION_STATUS_ID" | "REQUEST_SUBMISSIONS" | "SALESFORCE_ID" | "SALESFORCE_LEAD_STATUS_ID" | "SALESFORCE_SYNC_AS" | "STATE" | "STREET" | "SYNC_STATUS" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "UPDATED_METHOD" | "WEBSITE" | "ZIP";
export type ContactSyncOption = "contact" | "lead";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type ContactFilters = {
  query?: ?string,
  search?: ?Search,
  contactIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  updatedMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactTypeIds?: ?$ReadOnlyArray<string>,
  ownerIds?: ?$ReadOnlyArray<string>,
  senderIds?: ?$ReadOnlyArray<string>,
  salesforceSyncOptions?: ?$ReadOnlyArray<ContactSyncOption>,
  leadStatusIds?: ?$ReadOnlyArray<string>,
  sentAt?: ?DateTimeRange,
  emailOptIn?: ?boolean,
  hotLead?: ?boolean,
  registeredAt?: ?DateTimeRange,
  attendanceStatusIds?: ?$ReadOnlyArray<string>,
  registrationStatusIds?: ?$ReadOnlyArray<string>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  membershipSyncStatuses?: ?$ReadOnlyArray<SyncState>,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?ContactSort,
  direction?: ?Direction,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type CompanyFilters = {
  query?: ?string,
  companyIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactsCount?: ?NumberRangeInput,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?CompanySort,
  direction?: ?Direction,
};
export type EventContactsBriefRootQueryVariables = {|
  eventSlug: string,
  contactFilters: ContactFilters,
  companyFilters: CompanyFilters,
  listToken?: ?string,
  skipContacts: boolean,
  skipCompanies: boolean,
  sharedView: boolean,
|};
export type EventContactsBriefRootQueryResponse = {|
  +event: ?{|
    +briefSettings?: {|
      +contacts: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +email: string,
            +phone1: ?string,
            +phone2: ?string,
            +title: ?string,
            +company: ?{|
              +id: string,
              +name: string,
            |},
            +$fragmentRefs: BriefUserAvatar_user$ref,
          |}
        |}>
      |},
      +companies: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +name: string,
            +phone: ?string,
          |}
        |}>
      |},
    |},
    +eventContacts?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +email: string,
          +phone1: ?string,
          +phone2: ?string,
          +title: ?string,
          +company: ?{|
            +id: string,
            +name: string,
          |},
          +$fragmentRefs: BriefUserAvatar_user$ref,
        |}
      |}>
    |},
    +companies?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +phone: ?string,
        |}
      |}>
    |},
  |}
|};
export type EventContactsBriefRootQuery = {|
  variables: EventContactsBriefRootQueryVariables,
  response: EventContactsBriefRootQueryResponse,
|};
*/


/*
query EventContactsBriefRootQuery(
  $eventSlug: String!
  $contactFilters: ContactFilters!
  $companyFilters: CompanyFilters!
  $listToken: String
  $skipContacts: Boolean!
  $skipCompanies: Boolean!
  $sharedView: Boolean!
) {
  event(slug: $eventSlug, listToken: $listToken) {
    briefSettings @include(if: $sharedView) {
      contacts {
        edges {
          node {
            id
            firstName
            lastName
            email
            phone1
            phone2
            title
            company {
              id
              name
            }
            ...BriefUserAvatar_user
          }
        }
      }
      companies {
        edges {
          node {
            id
            name
            phone
          }
        }
      }
      id
    }
    eventContacts(filters: $contactFilters) @skip(if: $skipContacts) {
      edges {
        node {
          id
          firstName
          lastName
          email
          phone1
          phone2
          title
          company {
            id
            name
          }
          ...BriefUserAvatar_user
        }
      }
    }
    companies(filters: $companyFilters) @skip(if: $skipCompanies) {
      edges {
        node {
          id
          name
          phone
        }
      }
    }
    id
  }
}

fragment BriefUserAvatar_user on Assignable {
  avatar
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contactFilters",
    "type": "ContactFilters!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "companyFilters",
    "type": "CompanyFilters!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "listToken",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipContacts",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipCompanies",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sharedView",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "listToken",
    "variableName": "listToken",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone1",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone2",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "company",
  "storageKey": null,
  "args": null,
  "concreteType": "Company",
  "plural": false,
  "selections": [
    v2,
    v9
  ]
},
v11 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "node",
    "storageKey": null,
    "args": null,
    "concreteType": "Contact",
    "plural": false,
    "selections": [
      v2,
      v3,
      v4,
      v5,
      v6,
      v7,
      v8,
      v10,
      {
        "kind": "FragmentSpread",
        "name": "BriefUserAvatar_user",
        "args": null
      }
    ]
  }
],
v12 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "CompanyRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          v2,
          v9,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
],
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "companies",
  "storageKey": null,
  "args": null,
  "concreteType": "CompanyRequiredConnection",
  "plural": false,
  "selections": v12
},
v14 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "contactFilters",
    "type": "ContactFilters"
  }
],
v15 = {
  "kind": "Condition",
  "passingValue": false,
  "condition": "skipCompanies",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "companies",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "companyFilters",
          "type": "CompanyFilters"
        }
      ],
      "concreteType": "CompanyRequiredConnection",
      "plural": false,
      "selections": v12
    }
  ]
},
v16 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "node",
    "storageKey": null,
    "args": null,
    "concreteType": "Contact",
    "plural": false,
    "selections": [
      v2,
      v3,
      v4,
      v5,
      v6,
      v7,
      v8,
      v10,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "avatar",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventContactsBriefRootQuery",
  "id": null,
  "text": "query EventContactsBriefRootQuery(\n  $eventSlug: String!\n  $contactFilters: ContactFilters!\n  $companyFilters: CompanyFilters!\n  $listToken: String\n  $skipContacts: Boolean!\n  $skipCompanies: Boolean!\n  $sharedView: Boolean!\n) {\n  event(slug: $eventSlug, listToken: $listToken) {\n    briefSettings @include(if: $sharedView) {\n      contacts {\n        edges {\n          node {\n            id\n            firstName\n            lastName\n            email\n            phone1\n            phone2\n            title\n            company {\n              id\n              name\n            }\n            ...BriefUserAvatar_user\n          }\n        }\n      }\n      companies {\n        edges {\n          node {\n            id\n            name\n            phone\n          }\n        }\n      }\n      id\n    }\n    eventContacts(filters: $contactFilters) @skip(if: $skipContacts) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          email\n          phone1\n          phone2\n          title\n          company {\n            id\n            name\n          }\n          ...BriefUserAvatar_user\n        }\n      }\n    }\n    companies(filters: $companyFilters) @skip(if: $skipCompanies) {\n      edges {\n        node {\n          id\n          name\n          phone\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment BriefUserAvatar_user on Assignable {\n  avatar\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventContactsBriefRootQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBriefTemplate",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contacts",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactRequiredEdge",
                        "plural": true,
                        "selections": v11
                      }
                    ]
                  },
                  v13
                ]
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": false,
            "condition": "skipContacts",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventContacts",
                "storageKey": null,
                "args": v14,
                "concreteType": "EventContactConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventContactEdge",
                    "plural": true,
                    "selections": v11
                  }
                ]
              }
            ]
          },
          v15
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventContactsBriefRootQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "sharedView",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "briefSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "EventBriefTemplate",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contacts",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactRequiredEdge",
                        "plural": true,
                        "selections": v16
                      }
                    ]
                  },
                  v13,
                  v2
                ]
              }
            ]
          },
          {
            "kind": "Condition",
            "passingValue": false,
            "condition": "skipContacts",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventContacts",
                "storageKey": null,
                "args": v14,
                "concreteType": "EventContactConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventContactEdge",
                    "plural": true,
                    "selections": v16
                  }
                ]
              }
            ]
          },
          v15
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '904b8fbfb0c6716ed931e8b445f4dfde';
module.exports = node;
