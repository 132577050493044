/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logout from 'utils/authentication/logout';
import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { ProfileMenuItems_user } from './__generated__/ProfileMenuItems_user.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 36px 14px 23px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(100, 94, 110, 0.18);
  }
`;

const UserBlock = styled.div`
  display: flex;
`;

const StyledMaterialAvatar = styled(MaterialAvatar)`
  flex: 0 0 auto;
`;

const UserInfo = styled.div`
  margin-left: 14px;
  overflow: hidden;
`;

const Email = styled.div`
  font-size: 13px;
  color: #3e4859;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled(Email)`
  font-weight: bold;
`;

const LinkNode = styled.a`
  margin-bottom: 19px;
  font-size: 14px;
  color: #3e4859;
  cursor: pointer;
  &:only-child {
    margin-bottom: 0;
  }
  &:hover {
    color: #000000;
  }
`;

const StyledLinkNode = styled(LinkNode)`
  margin-bottom: 0;
`;

class ProfileMenuItems extends React.Component<{
  onHide: () => void,
  user: ProfileMenuItems_user,
}> {
  handleLinkClick = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    this.props.onHide();
  };

  handleLogout = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.onHide();
    logout();
  };

  render() {
    const user = this.props.user;
    return (
      <Container>
        <Section>
          <UserBlock>
            <StyledMaterialAvatar user={user} radius={22.5} />
            <UserInfo>
              <Name>{fullNameOfUser(user)}</Name>
              <Email>{user.email}</Email>
            </UserInfo>
          </UserBlock>
        </Section>
        <Section>
          <LinkNode as={Link} to="/account" onClick={this.handleLinkClick}>
            Account Settings
          </LinkNode>
          <LinkNode
            target="_blank"
            rel="noopener noreferrer"
            href="https://updates.circa.co"
            onClick={this.handleLinkClick}
          >
            Updates
          </LinkNode>
          <StyledLinkNode
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:success@circa.co"
            onClick={this.handleLinkClick}
          >
            Contact us
          </StyledLinkNode>
        </Section>
        <Section>
          <LinkNode href="#" onClick={this.handleLogout}>
            Sign out
          </LinkNode>
        </Section>
      </Container>
    );
  }
}

export default createFragmentContainer(ProfileMenuItems, {
  user: graphql`
    fragment ProfileMenuItems_user on User {
      id
      firstName
      lastName
      email
      ...MaterialAvatar_user
    }
  `,
});
