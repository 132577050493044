/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';
import { type Field } from '../components/types';

import EventVendorsBrief, { type VendorType } from './EventVendorsBrief';

import { type EventVendorsBriefRootQueryResponse } from './__generated__/EventVendorsBriefRootQuery.graphql';

const query = graphql`
  query EventVendorsBriefRootQuery(
    $eventSlug: String!
    $vendorFilters: VendorFilters!
    $listToken: String
    $sharedView: Boolean!
  ) {
    event(slug: $eventSlug, listToken: $listToken) {
      briefSettings @include(if: $sharedView) {
        vendors {
          edges {
            node {
              id
              firstName
              lastName
              email
              phone1
              phone2
              title
              name
            }
          }
        }
      }
      vendors(filters: $vendorFilters) @skip(if: $sharedView) {
        edges {
          node {
            id
            firstName
            lastName
            email
            phone1
            phone2
            title
            name
          }
        }
      }
    }
  }
`;

export default class EventVendorsBriefRoot extends React.Component<{
  customFields: $ReadOnlyArray<Field<'VENDOR'>>,
  vendorIds: $ReadOnlyArray<string>,
  listToken: ?string,
  eventSlug: string,
  sharedView: boolean,
  primaryColor: string,
}> {
  cachedVendors: $ReadOnlyArray<VendorType> = [];

  render() {
    const { listToken, eventSlug, vendorIds, sharedView, primaryColor, customFields } = this.props;
    const variables = {
      eventSlug,
      vendorFilters: { vendorIds },
      sharedView,
      ...(listToken ? { listToken } : {}),
    };

    return (
      <DefaultQueryRenderer
        query={query}
        public={sharedView}
        variables={variables}
        renderSuccess={(dataProps: EventVendorsBriefRootQueryResponse) => {
          if (!dataProps.event) {
            return <NotAuthorized />;
          }

          const sharedVendors = dataProps.event.briefSettings
            ? dataProps.event.briefSettings.vendors.edges.map(edge => edge.node)
            : [];
          const savedVendors = dataProps.event.vendors
            ? dataProps.event.vendors.edges.map(edge => edge.node)
            : [];
          this.cachedVendors = sharedView ? sharedVendors : savedVendors;
          return (
            <EventVendorsBrief
              customFields={customFields}
              vendors={this.cachedVendors}
              primaryColor={primaryColor}
            />
          );
        }}
        renderLoading={() => (
          <EventVendorsBrief
            customFields={customFields}
            vendors={this.cachedVendors}
            primaryColor={primaryColor}
          />
        )}
      />
    );
  }
}
