/* @flow */
import { graphql } from 'react-relay';

import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  removeContactFromCompanyMutationVariables,
  removeContactFromCompanyMutationResponse,
} from './__generated__/removeContactFromCompanyMutation.graphql';

const mutation = graphql`
  mutation removeContactFromCompanyMutation($input: RemoveContactFromCompanyInput!) {
    removeContactFromCompany(input: $input) {
      removedContactId
      company {
        ...CompanyPage_company
      }
    }
  }
`;

export default function removeContactFromCompany(args: {
  companyId: string,
  contactId: string,
}): Promise<removeContactFromCompanyMutationResponse> {
  const variables: removeContactFromCompanyMutationVariables = {
    input: { ...args },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      removeContactFromCompany: {
        removedContactId: args.contactId,
      },
    },
    updater: removeRecordFromConnection({
      deletedIdField: 'removedContactId',
      parentId: args.companyId,
      connections: [{ key: 'CompanyContactsList_contacts' }],
    }),
  });
}
