/* @flow */
/* global google */
import type { GooglePlace } from './locationTypes';

declare var google: any;

/**
 * The Google Maps API library must already be loaded to call this.
 */
export default function requestMapsPlace(placeId: string): Promise<GooglePlace> {
  return new Promise((resolve, reject) => {
    const dummyMap = document.createElement('div');
    const service = new google.maps.places.PlacesService(dummyMap);
    service.getDetails({
      placeId,
    }, (place, status) => {
      if (status !== 'OK' && status !== 'ZERO_RESULTS') {
        reject({
          message: `Bad google maps status: ${status}`,
        });
      }

      resolve(place);
    });
  });
}
