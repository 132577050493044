/**
 * @flow
 * @relayHash a1b7c0d365dd746cd5aa43ce4f1f91c0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffTableRenderer_totalCountEvent$ref = any;
export type RemoveStafferInput = {
  clientMutationId?: ?string,
  eventId: string,
  userId: string,
};
export type removeStafferMutationVariables = {|
  input: RemoveStafferInput
|};
export type removeStafferMutationResponse = {|
  +removeStaffer: ?{|
    +removedMembershipId: string,
    +event: {|
      +$fragmentRefs: StaffTableRenderer_totalCountEvent$ref
    |},
  |}
|};
export type removeStafferMutation = {|
  variables: removeStafferMutationVariables,
  response: removeStafferMutationResponse,
|};
*/


/*
mutation removeStafferMutation(
  $input: RemoveStafferInput!
) {
  removeStaffer(input: $input) {
    removedMembershipId
    event {
      ...StaffTableRenderer_totalCountEvent
      id
    }
  }
}

fragment StaffTableRenderer_totalCountEvent on Event {
  staffersCount: staffers {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveStafferInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveStafferInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedMembershipId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeStafferMutation",
  "id": null,
  "text": "mutation removeStafferMutation(\n  $input: RemoveStafferInput!\n) {\n  removeStaffer(input: $input) {\n    removedMembershipId\n    event {\n      ...StaffTableRenderer_totalCountEvent\n      id\n    }\n  }\n}\n\nfragment StaffTableRenderer_totalCountEvent on Event {\n  staffersCount: staffers {\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeStafferMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeStaffer",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveStafferPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "StaffTableRenderer_totalCountEvent",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeStafferMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeStaffer",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveStafferPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "staffersCount",
                "name": "staffers",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembershipRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalCount",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cec946011c90fae35899713346946a1c';
module.exports = node;
