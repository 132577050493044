/* @flow */
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import { getAccessToken } from 'utils/Auth';

function fetchQuery(operation, variables) {
  return fetch('/graphql/public_query', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
      Authorization: getAccessToken(),
    },
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  })
    .then(response => {
      return response.json();
    })
    .then(body => {
      if (body.errors) {
        return Promise.reject(new Error(body.errors[0].message || body.errors[0]));
      }
      if (body.redirect_url) {
        window.location.href = body.redirect_url;

        // Delay showing Unauthorized message
        return new Promise(resolve => setTimeout(resolve, 5000));
      }

      return body;
    });
}

const source = new RecordSource();
const store = new Store(source);
const network = Network.create(fetchQuery); // see note below
const handlerProvider = null;

const environment = new Environment({
  handlerProvider, // Can omit.
  network,
  store,
});

export default () => environment;
