/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Overlay from 'components/material/Overlay';

const StyledOverlay = styled(Overlay)`
  width: 60px;
`;

const Header = styled.div`
  font-weight: 500;
  padding: 0 3px;
  line-height: 1.5;
  cursor: pointer;
  color: ${props => props.theme.rowPrimaryTextColor};
  ${props =>
    props.active &&
    `
    color: ${props.theme.primaryActionColor};
    font-weight: 500;
  `};
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
  &:last-child {
    font-weight: 400;
  }
`;

const SIZES = ['20px', '18px', '16px', '13px'];
const LABELS = ['H1', 'H2', 'H3', 'Regular'];

export default class RichTextHeaderOverlay extends React.PureComponent<{
  target: any,
  container: any,
  show: boolean,
  onHide: () => void,
  types: Array<string>,
  onToggle: string => void,
  activeStyle: ?string,
}> {
  handleToggle = (key: string) => {
    this.props.onHide();

    this.props.onToggle(key);
  };

  render() {
    return (
      <StyledOverlay
        target={this.props.target}
        container={this.props.container}
        show={this.props.show}
        onHide={this.props.onHide}
      >
        {this.props.types.map((key, index) => (
          <Header
            key={key}
            onClick={() => this.handleToggle(key)}
            active={this.props.activeStyle === key}
            style={{ fontSize: SIZES[index] }}
          >
            {LABELS[index]}
          </Header>
        ))}
      </StyledOverlay>
    );
  }
}
