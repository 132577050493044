/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router';
import { type Location, type Match, Link } from 'react-router-dom';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import { type Event } from 'graph/mutations/event/updateEvent';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import type { EventName_event } from './__generated__/EventName_event.graphql';

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

class EventName extends React.PureComponent<{
  event: EventName_event,
  match: Match,
  location: Location,
  readOnly?: boolean,
  linkEventNameToBrief?: boolean,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate?: (changedProps: $Shape<Event>) => Promise<void>,
}> {
  handleMouseDown = (e: SyntheticEvent<>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?string) => {
    const { event, updateColumnWidth, onUpdate } = this.props;
    if (this.getError(value) || value === event.name) {
      return;
    }
    if (onUpdate) {
      onUpdate({ name: value || '' }); // the value can't be blank at this moment this for flow
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    const isValid = value && value.trim();
    if (!isValid) {
      return 'Required';
    }
    return null;
  };

  renderContent = () => {
    const { event, linkEventNameToBrief, match, location } = this.props;

    // Shared list view case when link to brief is disabled
    if (linkEventNameToBrief != null && !linkEventNameToBrief) {
      return <InlineExpandableText>{event.name}</InlineExpandableText>;
    }
    const link = linkEventNameToBrief
      ? `/events/${event.slug}/share/brief/${match.params.resourceToken || ''}`
      : `/events/${event.slug}`;
    const target = linkEventNameToBrief ? '_blank' : '_self';

    return (
      <StyledLink
        to={{
          pathname: link,
          state: { from: match.params.list_id, fromUrl: `${location.pathname}${location.search}` },
        }}
        target={target}
        onMouseDown={this.handleMouseDown}
      >
        <InlineExpandableText>{event.name}</InlineExpandableText>
      </StyledLink>
    );
  };

  render() {
    const { fieldSettings, event, readOnly, updateColumnWidth } = this.props;

    if (readOnly) {
      return this.renderContent();
    }

    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={event.name}
        onChange={this.handleUpdate}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
      >
        {this.renderContent()}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  withRouter(EventName),
  graphql`
    fragment EventName_event on Event {
      name
      slug
    }
  `,
);
