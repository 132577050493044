/* @flow */

import type { syncStatus } from 'components/Salesforce/types/syncStatus';

type ContactType = {
  +syncStatus: {
    +state: $PropertyType<syncStatus, 'state'>,
  },
};

type EventType = {
  +marketoProgram: ?{ +id: string },
  +salesforceCampaign: ?{ +id: string },
};

const eventContactSyncDisabledInfo = ({
  contact,
  event,
  salesforceSyncEnabled,
  marketoSyncEnabled,
}: {
  contact: ContactType,
  event: EventType,
  salesforceSyncEnabled: boolean,
  marketoSyncEnabled: boolean,
}) => {
  if (!salesforceSyncEnabled && !marketoSyncEnabled) return null;

  const programConnected = !!event.marketoProgram;
  const campaignConnected = !!event.salesforceCampaign;
  const contactSyncDisabled = contact.syncStatus.state === 'DISABLED';
  const contactSyncPending = ['FAILING', 'PENDING'].includes(contact.syncStatus.state);

  if (contactSyncDisabled) return 'Contact Not Synced';

  if (contactSyncPending) return 'Waiting for Contact to sync';

  if (marketoSyncEnabled) {
    if (!programConnected) return 'Marketo Program Not Connected';

    return 'Missing mapping for Attendance Status';
  }

  if (salesforceSyncEnabled && !campaignConnected) {
    return 'Salesforce Campaign Not Connected';
  }

  return null;
};

export default eventContactSyncDisabledInfo;
