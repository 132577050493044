/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { upload } from 'utils/Attachments';
import showErrorPopup from 'utils/showErrorPopup';
import pickFiles from 'utils/uploading/pickFiles';

import { DropZone } from 'components/DropZone';
import Loader from 'components/Loader';
import Avatar from 'components/material/Avatar';

import type { UserAvatar_user } from './__generated__/UserAvatar_user.graphql';

const ChangeCta = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  padding: 30px 0;
  border-radius: 8px;
  background-color: rgba(31, 31, 31, 0.2);
  font-size: 13px;
  color: white;
  text-align: center;
  cursor: pointer;
`;

const StyledAvatar = styled(Avatar)`
  position: relative;
  margin-bottom: 20px;
  &:hover > ${ChangeCta} {
    display: block;
  }
`;

class UserAvatar extends React.PureComponent<
  {
    user: UserAvatar_user,
    className?: string,
    onSave: (avatar: string) => void,
    disabled?: boolean,
  },
  { loading: boolean },
> {
  state = {
    loading: false,
  };

  handleFileDrop = (files: Array<{ type: string }>) => {
    const file = files.find(f => f.type && f.type.startsWith('image'));
    if (file) {
      this.setState({ loading: true });
      upload(file)
        .then(storedFile => {
          this.props.onSave(storedFile.fileurl);
        })
        .catch(showErrorPopup)
        .then(() => {
          this.setState({ loading: false });
        });
    }
  };

  handleUploadAvatar = () => {
    const options = {
      maxFiles: 1,
      accept: 'image/*',
    };
    pickFiles(options)
      .then(files => {
        this.props.onSave(files[0].fileurl);
      })
      .catch(showErrorPopup);
  };

  render() {
    return (
      <DropZone onDrop={this.handleFileDrop} noPreview disabled={this.props.disabled}>
        <StyledAvatar
          profile={this.props.user}
          size={50}
          radius="8px"
          className={this.props.className}
        >
          {!this.props.disabled && (
            <ChangeCta onClick={this.handleUploadAvatar}>
              {this.state.loading ? (
                <Loader size={30} />
              ) : (
                <>
                  CHANGE
                  <br />
                  AVATAR
                </>
              )}
            </ChangeCta>
          )}
        </StyledAvatar>
      </DropZone>
    );
  }
}

export default createFragmentContainer(
  UserAvatar,
  graphql`
    fragment UserAvatar_user on User {
      avatar
      firstName
      lastName
      email
    }
  `,
);
