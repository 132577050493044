/* @flow */
import { graphql } from 'react-relay';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';
import type {
  removeBudgetCategoryMutationVariables,
  removeBudgetCategoryMutationResponse,
} from './__generated__/removeBudgetCategoryMutation.graphql';

const mutation = graphql`
  mutation removeBudgetCategoryMutation($input: RemoveBudgetCategoryInput!) {
    removeBudgetCategory(input: $input) {
      removedCategoryId
    }
  }
`;

export default function removeBudgetCategory(args: {
  orgId: string,
  budgetCategoryId: string,
}): Promise<removeBudgetCategoryMutationResponse> {
  const variables: removeBudgetCategoryMutationVariables = {
    input: {
      categoryId: args.budgetCategoryId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      removeBudgetCategory: {
        removedCategoryId: args.budgetCategoryId,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedCategoryId',
        parentId: args.orgId,
        connections: [{ field: 'budgetCategories' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedCategoryId' }),
    ),
  });
}
