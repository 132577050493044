/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 5px 0;
  font-size: ${props => props.fontSize || 18}px;
  color: rgba(74, 86, 101, 0.87);
  ${props =>
    props.required &&
    css`
      &:after {
        position: absolute;
        left: -12px;
        top: 0;
        content: '*';
        margin-right: 4px;
        color: ${props.hasError ? '#f25d60' : '#519cf9'};
      }
    `};
  ${props =>
    props.showIteration &&
    css`
      counter-increment: question-counter;
      &:before {
        content: counter(question-counter) '.';

        margin-right: 4px;
      }
      &:after {
        position: absolute;
        content: ${props.required ? '*' : ''};
      }
    `};
  @media (${props => props.theme.mobileOnly}) {
    font-size: 15px;
  }
`;

const Header = styled.span`
  word-break: break-word;
`;

export default class QuestionHeader extends React.Component<{
  children: React.Node,
  label: string,
  required?: boolean,
  fontSize?: number,
  hasError?: boolean,
  showIteration?: boolean,
  showLabel?: boolean,
  className?: string,
}> {
  static defaultProps = { showLabel: true };

  render() {
    return (
      <Label
        as="div"
        className={this.props.className}
        required={this.props.required}
        fontSize={this.props.fontSize}
        showIteration={this.props.showLabel && this.props.showIteration}
        hasError={this.props.hasError}
      >
        {this.props.showLabel && <Header>{this.props.label}</Header>}

        {this.props.children}
      </Label>
    );
  }
}
