/* @flow */
import type { RequesterInfoType } from 'components/EventRequestForm/lib/types';

import type { QuestionValueWithMapping, RelayQuestionType } from '../index';
import validateRulesAndConditions from './validateRulesAndConditions';

const isQuestionRequired = (
  question: RelayQuestionType,
  requesterInfo: RequesterInfoType,
  questionValues: $ReadOnlyArray<QuestionValueWithMapping>,
  tz: string,
) => {
  const defaultRequired = question.required;

  // Apply the default if there are no rules
  if (question.rules.edges.length === 0) {
    return defaultRequired;
  }

  const makeRequiredRules = question.rules.edges
    .filter(({ node: rule }) => rule.action === 'MAKE_REQUIRED')
    .map(edge => edge.node);
  const makeOptionalRules = question.rules.edges
    .filter(({ node: rule }) => rule.action === 'MAKE_OPTIONAL')
    .map(edge => edge.node);

  const makeRequiredRuleResult = validateRulesAndConditions(
    makeRequiredRules,
    requesterInfo,
    questionValues,
    tz,
  );
  const makeOptionalRuleResult = validateRulesAndConditions(
    makeOptionalRules,
    requesterInfo,
    questionValues,
    tz,
  );

  // Default changes to false if there are only rules to make the question optional
  // Required rule has higher priority than optional rule
  if (defaultRequired && (makeRequiredRuleResult || !makeOptionalRuleResult)) {
    return true;
  }

  if (!defaultRequired && makeRequiredRuleResult) {
    return true;
  }

  return false;
};

export default isQuestionRequired;
