/* @flow */

const activityScopeOptions = [
  { value: 'MY_TEAMS', label: 'My Teams' },
  { value: 'MY_EVENTS', label: 'My Events' },
];

const activityFrequencyOptions = [
  { value: 1, label: 'Daily' },
  { value: 2, label: 'Weekly' },
  { value: 0, label: 'Never' },
];

const assignmentFrequencyOptions = [
  { value: 1, label: 'Immediately' },
  { value: 2, label: 'Daily' },
  { value: 3, label: 'Weekly' },
  { value: 0, label: 'Never' },
];

export { activityFrequencyOptions, activityScopeOptions, assignmentFrequencyOptions };
