/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment-timezone';

import type { FieldType } from 'utils/customization/types';
import formatDate from 'utils/date/formatDate';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineDateField from 'components/budget/Table/InlineDateField';
import { type DateConfig } from 'components/date/DateTimePicker';
import LockIcon from 'components/LockIcon';

import type { CustomizableDate_customizable } from './__generated__/CustomizableDate_customizable.graphql';

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
`;

class CustomizableDate extends React.Component<{
  customizable: CustomizableDate_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
  tz: string,
  isLast?: boolean,
}> {
  getValue = (): ?string => {
    const { customizable, fieldSettings, tz } = this.props;
    const customDateField = customizable.customDateFields.find(
      dateField => dateField.customField.id === fieldSettings.id,
    );
    return customDateField ? moment.tz(customDateField.value, tz).format() : null;
  };

  handleSave = (value: ?DateConfig): void => {
    const { onUpdateCustomField, customizable, fieldSettings, updateColumnWidth } = this.props;
    if (value && !this.getError(value.date) && value !== this.getValue() && onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        dateValue: value ? value.date : null,
      });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    if (this.props.fieldSettings.required && !value) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { readOnly, tz, fieldSettings, isLast, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    const date = this.getValue();
    const dateFormatted = date ? formatDate(moment(date).tz(tz)) : null;
    if (readOnly || fieldSettings.restrictChangingValue) {
      return (
        <>
          {dateFormatted}
          {!readOnly && fieldSettings.restrictChangingValue && (
            <StyledLockIcon label="Salesforce" />
          )}
        </>
      );
    }
    return (
      <InlineDateField
        date={this.getValue()}
        tz={tz}
        dateOnly
        hideTime
        placeholder={fieldSettings.label}
        onChange={this.handleSave}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        forceRightEdge={isLast}
      />
    );
  }
}

export default createFragmentContainer(
  CustomizableDate,
  graphql`
    fragment CustomizableDate_customizable on CustomizableInterface {
      id
      __typename
      customDateFields {
        customField {
          id
        }
        value
      }
    }
  `,
);
