/* @flow */
import styled from 'styled-components';
import BaseColumn from './BaseColumn';

const BaseColumnSticky = styled(BaseColumn)`
  position: sticky;
  flex: ${props => props.grow || 0} 0 ${props => props.width}px;
  z-index: 3;
  left: 0;
  line-height: ${props => (props.header ? 37 : 39)}px;
  padding-left: 15px;
  border-right: 1px solid ${props => props.theme.primaryRowColor};
  margin: 0 15px 0 -16px;
  background: ${props => props.theme.secondaryRowColor};
`;

export default BaseColumnSticky;
