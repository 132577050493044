/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type SectionInput } from 'components/FieldSettings/types';

import type {
  updateCustomFieldSectionMutationResponse,
  updateCustomFieldSectionMutationVariables,
} from './__generated__/updateCustomFieldSectionMutation.graphql';

const mutation = graphql`
  mutation updateCustomFieldSectionMutation($input: UpdateCustomFieldSectionInput!) {
    updateCustomFieldSection(input: $input) {
      customFieldSection {
        id
        name
        order
      }
    }
  }
`;

export default function updateCustomFieldSection(
  id: string,
  input: SectionInput,
): Promise<updateCustomFieldSectionMutationResponse> {
  const variables: updateCustomFieldSectionMutationVariables = {
    input: { id, ...input },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateCustomFieldSection: {
        customFieldSection: {
          id,
          ...input,
        },
      },
    },
  });
}
