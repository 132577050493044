/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import currencies from 'config/currencies.json';

import type { RateType } from 'utils/getCurrencyRates';
import formatCost from 'utils/number/formatCost';
import type { CircaFile } from 'utils/uploading/types';

import VendorField from 'components/budget/VendorField';
import { DropZone } from 'components/DropZone';
import SimpleAttachments from 'components/material/Attachments/SimpleAttachments';
import Button from 'components/material/Button';
import CostField from 'components/material/CostField';
import EventSelect from 'components/material/EventSelect';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';

import TeamAllocation from '../../components/TeamAllocation';
import type { TeamAllocationType } from '../../components/TeamAllocation/TeamSelectField';
import type { ExpenseType } from './index';

import type { InlineFormFields_event } from './__generated__/InlineFormFields_event.graphql';
import type { InlineFormFields_org } from './__generated__/InlineFormFields_org.graphql';

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  padding: 10px 25px 20px;
  background-color: #f2fbff;
`;

const Row = styled.div`
  display: flex;
  align-items: baseline;
  &:nth-child(3) {
    margin-top: 15px;
  }
`;

const StyledRow = styled(Row)`
  align-items: flex-end;
`;

const SubmitWrapper = styled.div`
  margin-left: 23px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 360px;
`;

const StyledVendorField = styled.div`
  width: 360px;
  margin-left: 28px;
`;

const AmountContainer = styled.div`
  position: relative;
`;

const StyledAttachments = styled(SimpleAttachments)`
  margin-top: 25px;
`;

const StyledCostField = styled(CostField)`
  width: 116px;
  margin-left: 28px;
`;

const CalculatedAmount = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0;
  font-size: 12px;
  color: #2dd69c;
`;

const StyledSelectField = styled(SelectField)`
  width: 89px;
  margin-left: 28px;
`;

const StyledBudgetCategory = styled(SelectField)`
  width: 100%;
  max-width: 360px;
`;

const ManageLink = styled.div`
  color: #3ba9da;
`;

const StyledDropZone = styled(DropZone)`
  .attachment-drop {
    padding-top: 15px;
    i {
      font-size: 40px !important;
    }
  }
`;

class InlineFormFields extends React.PureComponent<{
  onAmountChange: (e: SyntheticEvent<HTMLInputElement>, amount: ?number) => void,
  onNameChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onKeyDown: (e: SyntheticKeyboardEvent<HTMLInputElement>) => void,
  onBudgetCategoryChange: (budgetCategoryId: ?string) => void,
  onCurrencyChange: (currency: string) => void,
  onVendorChange: (vendorId: ?string) => void,
  onAddAttachment: (attachment: CircaFile) => void,
  onRemoveAttachment: (attachmentId: ?string) => void,
  onFilesDrop: (files: Array<File>) => void,
  onCloseButtonClick: () => void,
  onExpenseCreate: () => void,
  onSetTeamAllocations: (teams: $ReadOnlyArray<TeamAllocationType>) => void,
  currentTeamId: string,
  expense: ExpenseType,
  errors: $ReadOnlyArray<string>,
  org: InlineFormFields_org,
  event?: InlineFormFields_event,
  actualAmount: number,
  currency: string,
  rates: RateType,
  rate: number,
  vendorId?: string,
  onEventSelect?: (eventId: ?string) => void,
}> {
  handleCurrencyChange = (currency: ?string) => {
    if (currency) {
      this.props.onCurrencyChange(currency);
    }
  };

  render() {
    const {
      expense,
      errors,
      org,
      actualAmount,
      currency,
      rates,
      event,
      rate,
      vendorId,
      currentTeamId,
    } = this.props;
    const attachmentUploading = expense.attachments.some(attachment => !attachment.fileurl);
    return (
      <Container>
        <StyledDropZone onDrop={this.props.onFilesDrop}>
          <Row>
            <StyledTextField
              name="name"
              label="Expense"
              value={expense.name}
              onChange={this.props.onNameChange}
              onKeyDown={this.props.onKeyDown}
              error={errors.includes('name') ? 'Required' : ''}
              required
            />
            <StyledVendorField>
              {vendorId && this.props.onEventSelect ? (
                <EventSelect
                  name="event"
                  label="Select Events"
                  eventId={event ? event.id : ''}
                  onSelect={this.props.onEventSelect}
                  error={errors.includes('event') ? 'Required' : ''}
                  required
                  fullAccessEvents
                />
              ) : (
                <VendorField
                  eventId={event ? event.id : ''}
                  vendorId={expense.vendorId}
                  onSelect={this.props.onVendorChange}
                  label="Vendor"
                  clearable
                />
              )}
            </StyledVendorField>
          </Row>
          <Row>
            <StyledBudgetCategory
              label="Expense Category"
              value={expense.budgetCategoryId}
              options={[
                ...sortBy(
                  org.budgetCategories.edges.map(edge => edge.node),
                  'name',
                ).map(category => ({
                  label: category.name,
                  value: category.id,
                  info: category.description,
                })),
                org.viewerCanUpdate
                  ? {
                      label: 'Manage Categories',
                      value: 'manage',
                      displayLabel: <ManageLink>Manage Categories</ManageLink>,
                    }
                  : null,
              ].filter(Boolean)}
              searchable
              onChange={this.props.onBudgetCategoryChange}
              clearable
            />
            <AmountContainer>
              <StyledCostField
                name="actualCost"
                label="Amount"
                onKeyDown={this.props.onKeyDown}
                defaultValue={actualAmount}
                onChange={this.props.onAmountChange}
                error={errors.includes('cost') ? 'Required' : ''}
                currency={currency}
                required
              />
              {currency !== org.settings.currency && (
                <CalculatedAmount>
                  <Tooltip
                    label={`${formatCost(100, org.settings.currency)} = ${formatCost(
                      Math.round(rate * 100),
                      currency,
                    )}\nWorkspace Currency: ${org.settings.currency}`}
                    placement="bottom"
                  >
                    {formatCost(expense.amount, org.settings.currency)}
                  </Tooltip>
                </CalculatedAmount>
              )}
            </AmountContainer>
            <StyledSelectField
              value={currency}
              onChange={this.handleCurrencyChange}
              options={currencies.map(item => ({
                value: item.code,
                label: `${item.code} (${item.symbol})`,
              }))}
              searchable
              disabled={Object.keys(rates).length === 0}
            />
          </Row>
          <Row>
            {event && (
              <TeamAllocation
                currentTeamId={currentTeamId}
                teams={expense.teamAllocations}
                onSetTeamAllocations={this.props.onSetTeamAllocations}
              />
            )}
          </Row>
          <StyledRow>
            <StyledAttachments
              attachments={expense.attachments}
              onAdd={this.props.onAddAttachment}
              onRemove={this.props.onRemoveAttachment}
            />
            <Row>
              <Button minimal label="Cancel" onClick={this.props.onCloseButtonClick} />
              <SubmitWrapper>
                <Button
                  primary
                  label="Add"
                  onClick={this.props.onExpenseCreate}
                  disabled={attachmentUploading}
                />
              </SubmitWrapper>
            </Row>
          </StyledRow>
        </StyledDropZone>
      </Container>
    );
  }
}
export default createFragmentContainer(InlineFormFields, {
  org: graphql`
    fragment InlineFormFields_org on Org {
      viewerCanUpdate
      settings {
        currency
      }
      budgetCategories {
        edges {
          node {
            name
            description
            id
          }
        }
      }
    }
  `,
  event: graphql`
    fragment InlineFormFields_event on Event {
      id
    }
  `,
});
