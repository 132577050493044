/* @flow */
import * as React from 'react';

import sortCustomFieldOptions from 'utils/customization/sortCustomFieldOptions';

import QuestionSelectFieldWrapper from 'components/EventRequestForm/Question/QuestionSelectOptions/components/QuestionSelectFieldWrapper';

import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../../lib/types';
import QuestionSelectOptionRow from './components/QuestionSelectOptionRow';
import StyledTextInput from './components/TextInput';
import { filteredOptions } from './lib/filteredOptions';

export default class QuestionMultiSelectField extends React.PureComponent<
  {
    question: QuestionType,
    value: QuestionValueType,
    onChangeValue: QuestionValueInputType => void,
    handleShouldValidate?: (shouldValidate: boolean) => void,
    readOnly: boolean,
    error: ?string,
  },
  {
    showOtherOptionInput: boolean,
    keyword: string,
  },
> {
  state = {
    showOtherOptionInput: !!(this.props.value && this.props.value.textValue),
    keyword: '',
  };

  handleSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.state.keyword !== e.currentTarget.value.trim()) {
      this.setState({
        keyword: e.currentTarget.value.trim().toLowerCase(),
      });
    }
  };

  handleOptionChange = (optionId: ?string, checked: boolean) => {
    const { readOnly, value, onChangeValue } = this.props;
    if (readOnly || !optionId) return;

    const selectIdsValue = value ? value.selectIdsValue : [];

    onChangeValue({
      selectIdsValue: checked
        ? [...selectIdsValue, optionId]
        : selectIdsValue.filter(id => id !== optionId),
    });
  };

  handleChangeOtherOption = (value: ?SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeValue({ textValue: (value && value.currentTarget.value) || null });
  };

  handleBlurOtherOption = (value: ?SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeValue({ textValue: (value && value.currentTarget.value.trim()) || null });
  };

  handleToggleOtherOptionInput = () => {
    const { onChangeValue, handleShouldValidate } = this.props;

    onChangeValue({ textValue: null });
    this.setState(state => ({ showOtherOptionInput: !state.showOtherOptionInput }));

    if (handleShouldValidate) {
      handleShouldValidate(!this.state.showOtherOptionInput);
    }
  };

  render() {
    const { question, value, error } = this.props;
    const { showOtherOptionInput, keyword } = this.state;
    const options = filteredOptions(question, value, keyword);
    const selectIdsValue = value ? value.selectIdsValue : [];
    const selectionLimitReached =
      question.maxSelectionSize != null &&
      question.maxSelectionSize <= selectIdsValue.length + showOtherOptionInput;

    const renderOtherOption =
      question.selectShowOtherOption &&
      (!question.mappingCustomField ||
        (question.mappingCustomField && !question.mappingCustomField.restrictManagingOptions));

    return (
      <>
        {question.selectOptions.length > 10 && (
          <StyledTextInput placeholder="Search..." onChange={this.handleSearchChange} />
        )}
        <QuestionSelectFieldWrapper
          question={question}
          value={value}
          options={options}
          keyword={keyword}
          error={error}
          showOtherOptionInput={!!renderOtherOption && showOtherOptionInput}
          onChangeOtherOption={this.handleChangeOtherOption}
          onBlurOtherOption={this.handleBlurOtherOption}
          canScroll
        >
          {sortCustomFieldOptions(options).map(option => (
            <QuestionSelectOptionRow
              key={option.id}
              checked={selectIdsValue.includes(option.id)}
              disabled={selectionLimitReached && !selectIdsValue.includes(option.id)}
              option={option}
              onChange={this.handleOptionChange}
            />
          ))}

          {renderOtherOption && (
            <QuestionSelectOptionRow
              checked={showOtherOptionInput}
              disabled={selectionLimitReached && !showOtherOptionInput}
              option={{ id: 'OTHER', name: 'Other' }}
              onChange={this.handleToggleOtherOptionInput}
            />
          )}
        </QuestionSelectFieldWrapper>
      </>
    );
  }
}
