/* @flow */
import styled from 'styled-components';

import TableRow from './TableRow';

const TableRowGroup = styled.div`
  display: none;
  &:last-child {
    ${TableRow}:last-child {
      border-bottom: 1px solid #dfe1e5;
    }
  }
`;

export default TableRowGroup;
