/* @flow */
import React from 'react';

import importedContacts from 'utils/analytics/events/importedContacts';
import type { FieldType } from 'utils/customization/types';

import importContacts, {
  type ContactsImportMappingsType,
  type ImportContactFromWindow,
} from 'graph/mutations/contact/importContacts';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ImportWindow, { type ImportProps, type MappingsType } from 'components/ImportWindow';

const contactFieldsRegex = {
  owner_id: { key: 'owner' },
  company_id: { key: 'companyName' },
  contact_type_id: { key: 'contactType' },
  email_opt_in: { key: 'emailOptIn' },
  hot_lead: { key: 'hotLead' },
  registration_status_id: { key: 'registrationStatus' },
  attendance_status_id: { key: 'attendanceStatus' },
};

export default class ContactsImportWindow extends React.PureComponent<{
  eventId?: string,
  eventDbId?: number,
  eventName?: string,
  teamId?: number,
  teamName?: string,
  onClose: () => void,
  fromWindow: ImportContactFromWindow,
  rawContactFields: $ReadOnlyArray<FieldType>,
}> {
  fields = this.props.rawContactFields.reduce(
    (fieldsObject, field) => {
      if (field.fieldName == null) {
        return {
          ...fieldsObject,
          [field.id]: {
            label: field.label,
            guess: new RegExp(field.label.toLowerCase().replace(/[^\w+]/g, '')),
            required: field.required,
            custom: true,
          },
        };
      }

      const fieldName =
        contactFieldsRegex[field.fieldName] != null
          ? contactFieldsRegex[field.fieldName].key
          : field.fieldName;

      return {
        ...fieldsObject,
        [fieldName]: {
          label: field.label,
          guess: new RegExp(field.label.toLowerCase().replace(/[^\w+]/g, '')),
          required: field.required,
        },
      };
    },
    {
      firstName: {
        label: 'First Name',
        guess: /^(first|full|contact)?name/,
        required: true,
      },
      lastName: {
        label: 'Last Name',
        guess: /lastname/,
        required: true,
      },
      email: {
        label: 'Email',
        guess: /email/,
        required: true,
      },
    },
  );

  handleValidateMappings = (mappings: MappingsType<string>) => {
    const mappingFields = Object.values(mappings);

    const missingRequiredField = Object.keys(this.fields).find(
      key => this.fields[key].required && !mappingFields.includes(key),
    );

    if (missingRequiredField) {
      return `${this.fields[missingRequiredField].label} is required`;
    }

    return null;
  };

  handleImport = ({ mappings, fileName, fileContent }: ImportProps<ContactsImportMappingsType>) => {
    return importContacts({
      mappings,
      fileName,
      fileContent,
      eventId: this.props.eventId,
    })
      .then(importResults => {
        if (importResults && (importResults.importedCount > 0 || importResults.mergedCount > 0)) {
          importedContacts({
            eventId: this.props.eventDbId,
            eventName: this.props.eventName,
            teamId: this.props.teamId,
            teamName: this.props.teamName,
            fromWindow: this.props.fromWindow,
          });
        }

        return importResults;
      })
      .catch(showModernMutationError);
  };

  renderConfirmationMessage = (count: number) =>
    `You are about to import ${count} contact${count > 1 ? 's' : ''}.`;

  render() {
    const { eventName, onClose } = this.props;

    return (
      <ImportWindow
        title={`Import Contacts ${eventName != null ? ` to ${eventName}` : ''}`}
        importResourceName="Contact"
        fields={this.fields}
        renderConfirmationMessage={this.renderConfirmationMessage}
        onValidateMappings={this.handleValidateMappings}
        onClose={onClose}
        onImport={this.handleImport}
      />
    );
  }
}
