/* @flow */
import { type FilterItem, getKind } from 'components/material/Filters/FilterSelectorRow';

const getFilterItemValueForSave = (filterItem: FilterItem) => {
  switch (getKind(filterItem)) {
    case 'TEXT':
      return { textParam: filterItem.values };
    case 'TEXTAREA':
      return { textareaParam: filterItem.values };
    case 'LINK':
      return { linkParam: filterItem.values };
    case 'BOOLEAN':
      return { booleanParam: filterItem.value };
    case 'NUMBER':
    case 'CURRENCY':
      return { numberParam: { min: filterItem.minValue, max: filterItem.maxValue } };
    case 'DATE':
      return {
        dateParam: {
          start: filterItem.minValue,
          end: filterItem.maxValue != null ? filterItem.maxValue : filterItem.minValue,
        },
      };
    case 'SELECT':
    case 'MULTISELECT':
      return { optionIds: filterItem.optionIds };
    case 'USER_SELECT':
    case 'USER_MULTISELECT':
      return {
        optionIds:
          filterItem.users && filterItem.users.length > 0
            ? filterItem.users.map(user => user.id)
            : [],
      };
    default:
      return { field: 'Field is required' };
  }
};

export default getFilterItemValueForSave;
