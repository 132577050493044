/* @flow */
import React from 'react';

export default class ScrollbarSizes extends React.Component<{
  scrollContainer: HTMLElement,
  vScrollbarSize: number,
  hScrollbarSize: number,
  onScrollbarSizesChange: ({ vScrollbarSize: number, hScrollbarSize: number }) => void,
  shouldUpdate: any, // changing the value will force re-render
}> {
  componentDidMount() {
    this.props.onScrollbarSizesChange(this.scrollbarSizes());

    window.addEventListener('resize', this.handleResize);
  }

  shouldComponentUpdate(nextProps: { shouldUpdate: any }) {
    return this.props.shouldUpdate !== nextProps.shouldUpdate;
  }

  componentDidUpdate() {
    this.props.onScrollbarSizesChange(this.scrollbarSizes());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { vScrollbarSize, hScrollbarSize } = this.scrollbarSizes();

    if (
      vScrollbarSize !== this.props.vScrollbarSize ||
      hScrollbarSize !== this.props.hScrollbarSize
    ) {
      this.props.onScrollbarSizesChange(this.scrollbarSizes());
    }
  };

  scrollbarSizes() {
    const element = this.props.scrollContainer;
    const vScrollbarSize = element.offsetWidth - element.clientWidth;
    const hScrollbarSize = element.offsetHeight - element.clientHeight;

    return { vScrollbarSize, hScrollbarSize };
  }

  render() {
    return null;
  }
}
