/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled from 'styled-components';

import parseTypedQueryString, { stringParamToSort } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Button from 'components/material/Button';
import type { Sort } from 'components/material/SortableHeader';

import AddUserToEventsWindow from './AddUserToEventsWindow';
import columns, { type StaffedEventCellPropsType } from './columns';

import type { UserEvents_me } from './__generated__/UserEvents_me.graphql';
import type { UserEvents_org } from './__generated__/UserEvents_org.graphql';
import type { UserEvents_user } from './__generated__/UserEvents_user.graphql';
import type { UserEventsQueryResponse } from './__generated__/UserEventsQuery.graphql';

const Container = styled.div`
  margin-top: 30px;
`;

const Header = styled.div`
  margin-bottom: 17px;
  padding: 0 0 15px 5px;
  border-bottom: 1px solid #eaebec;
  font-size: 16px;
  font-weight: 500;
  line-height: 0.875;
`;

const StyledButton = styled(Button)`
  margin-bottom: 20px;
`;

type StaffedEvent = $ElementType<
  $NonMaybeType<
    $PropertyType<$NonMaybeType<$PropertyType<UserEventsQueryResponse, 'user'>>, 'staffedEvents'>,
  >,
  0,
>;

const query = graphql`
  query UserEventsQuery($userId: ID!, $sort: StaffMembershipSort, $direction: Direction!) {
    user: node(id: $userId) {
      ... on User {
        staffedEvents(first: 1000, sort: $sort, direction: $direction)
          @connection(key: "UserEvents_staffedEvents", filters: []) {
          edges {
            node {
              ...EventAccessLevel_staffedEvent
              ...EventDate_staffedEvent
              ...EventDateAdded_staffedEvent
              ...EventLocation_staffedEvent
              ...EventName_staffedEvent
              ...EventOnsite_staffedEvent
              ...EventActionsColumn_staffedEvent
              event {
                id
                slug
                viewerCanView
              }
            }
          }
        }
      }
    }
  }
`;

class UserEvents extends React.Component<
  {
    org: UserEvents_org,
    user: UserEvents_user,
    me: UserEvents_me,
    location: Location,
    history: History,
  },
  {
    adding: boolean,
  },
> {
  state = { adding: false };

  handleAddStart = () => {
    this.setState({ adding: true });
  };

  handleAddEnd = () => {
    this.setState({ adding: false });
  };

  handleChangeSort = (sort: Sort) => {
    replaceSortQueryParam(this.props.history, sort, 'eventSort');
  };

  cellProps = (staffedEvent: StaffedEvent): StaffedEventCellPropsType<StaffedEvent> => {
    return {
      staffedEvent,
      userId: this.props.user.id,
      currentUserId: this.props.me.id,
      invitedUser: this.props.user.inviteStatus !== 'NOT_INVITED',
      location: this.props.location,
    };
  };

  keyExtractor = (staffedEventEdge: StaffedEvent) => staffedEventEdge.id;

  sort = () =>
    parseTypedQueryString(this.props.location.search, {
      eventSort: stringParamToSort,
    }).eventSort || {
      key: 'EVENT_DATE_ADDED',
      asc: true,
    };

  rowClickHandler = (staffedEvent: StaffedEvent) => {
    const event = staffedEvent.event;
    if (!event.viewerCanView) {
      return null;
    }

    return () => this.props.history.push(`/events/${event.slug}`);
  };

  renderTable = (response?: UserEventsQueryResponse) => {
    const staffedEventEdges =
      response && response.user && response.user.staffedEvents
        ? response.user.staffedEvents.edges.map(({ node }) => node)
        : null;

    if (staffedEventEdges && staffedEventEdges.length === 0) {
      return (
        <EmptyView message="Workspace Member is not staffed on any Events" icon={<NoResult />} />
      );
    }

    return (
      <Table
        columns={columns}
        cellProps={this.cellProps}
        keyExtractor={this.keyExtractor}
        data={staffedEventEdges}
        sort={this.sort()}
        onChangeSort={this.handleChangeSort}
        rowClickHandler={this.rowClickHandler}
      />
    );
  };

  render() {
    const sort = this.sort();
    // Team member can add himself to staff.
    // Full access users who can only manage event staff can't add himself to anything new.
    const canAddToEvent =
      this.props.org.viewerCanManageTeamMembers ||
      (this.props.org.viewerCanManageStaff && this.props.user.id !== this.props.me.id);

    return (
      <Container>
        <Header>Events</Header>
        {canAddToEvent && (
          <StyledButton primary outline onClick={this.handleAddStart}>
            Add to Event Staff
          </StyledButton>
        )}
        {this.state.adding && (
          <AddUserToEventsWindow
            userId={this.props.user.id}
            me={this.props.me}
            onHide={this.handleAddEnd}
          />
        )}

        <DefaultQueryRenderer
          query={query}
          variables={{
            userId: this.props.user.id,
            sort: sort.key,
            direction: sort.asc ? 'ASC' : 'DESC',
          }}
          renderSuccess={this.renderTable}
          renderLoading={this.renderTable}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(UserEvents, {
  user: graphql`
    fragment UserEvents_user on User {
      id
      inviteStatus
    }
  `,
  me: graphql`
    fragment UserEvents_me on User {
      id
    }
  `,
  org: graphql`
    fragment UserEvents_org on Org {
      viewerCanManageStaff
      viewerCanManageTeamMembers
    }
  `,
});
