/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation addNoteToBriefMutation($input: AddNoteToBriefInput!) {
    addNoteToBrief(input: $input) {
      eventNote {
        includedInBrief
      }
    }
  }
`;

export default function addNoteToBrief(noteId: string, eventId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        noteId,
        eventId,
      },
    },
    optimisticResponse: {
      addNoteToBrief: {
        eventNote: {
          includedInBrief: true,
        },
      },
    },
  });
}
