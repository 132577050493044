/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { Content } from 'components/page/Content';

import CustomFieldSettingsQuery from './CustomFieldSettingsQuery';

import type { OpportunitySettings_org } from './__generated__/PeopleSettings_org.graphql';

class OpportunitySettings extends React.Component<{
  org: OpportunitySettings_org,
}> {
  render() {
    const salesforceAccount = this.props.org.salesforceAccount;
    const syncEnabled =
      salesforceAccount == null ? false : salesforceAccount.opportunitiesSyncEnabled;
    const filters = {
      customizableType: ['SALESFORCEOPPORTUNITY'],
      includeDisabled: true,
      includeRestrictManagingOptions: false,
    };
    return (
      <Content>
        <CustomFieldSettingsQuery
          filters={filters}
          customizableType="SALESFORCEOPPORTUNITY"
          syncEnabled={syncEnabled}
        />
      </Content>
    );
  }
}

export default createFragmentContainer(
  OpportunitySettings,
  graphql`
    fragment OpportunitySettings_org on Org {
      salesforceAccount {
        opportunitiesSyncEnabled
      }
    }
  `,
);
