/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationResults_event$ref: FragmentReference;
export type RegistrationResults_event = {|
  +slug: string,
  +registrationForm: ?{|
    +publishedAt: ?any
  |},
  +$refType: RegistrationResults_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationResults_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "registrationForm",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationForm",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "publishedAt",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6f568ac8e0d91e992e94a31cf44bfbf2';
module.exports = node;
