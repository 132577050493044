/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';

import removeTasks from 'graph/mutations/task/removeTasks';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';

import type { TasksFiltersInputType } from './index';

import type { DeliverableActionCell_deliverable } from './__generated__/DeliverableActionCell_deliverable.graphql';
import type { DeliverableActionCell_me } from './__generated__/DeliverableActionCell_me.graphql';
import type { DeliverableActionCell_org } from './__generated__/DeliverableActionCell_org.graphql';

const RemoveIcon = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 5px;
  color: ${props => props.theme.secondaryActionColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

class DeliverableActionCell extends React.PureComponent<
  {
    deliverable: DeliverableActionCell_deliverable,
    org: DeliverableActionCell_org,
    me?: DeliverableActionCell_me,
    location: Location,
    filters: TasksFiltersInputType,
  },
  { showConfirmation: boolean },
> {
  state = { showConfirmation: false };

  handleDeliverableRemove = () => {
    const {
      location,
      deliverable: { id },
      org,
      me,
    } = this.props;

    removeTasks([id], {
      fromWindow: location.pathname.includes('all_tasks') ? 'all tasks' : 'my tasks',
      ...(location.pathname.includes('all_tasks') ? { orgId: org.id } : {}),
      ...(location.pathname.includes('my_tasks') && me != null ? { userId: me.id } : {}),
      filters: this.props.filters,
    }).catch(showModernMutationError);
  };

  handleConfirmationShow = () => {
    this.setState({ showConfirmation: true });
  };

  handleConfirmationHide = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    const {
      deliverable: { viewerCanDelete },
    } = this.props;

    if (!viewerCanDelete) return null;
    return (
      <>
        <RemoveIcon onClick={this.handleConfirmationShow}>
          <i className="fa fa-fw fa-trash" />
        </RemoveIcon>

        {this.state.showConfirmation &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              message={"Once you delete this Task, it's gone for good."}
              onConfirm={this.handleDeliverableRemove}
              onHide={this.handleConfirmationHide}
            />,
            document.body,
          )}
      </>
    );
  }
}

export default createFragmentContainer(DeliverableActionCell, {
  deliverable: graphql`
    fragment DeliverableActionCell_deliverable on Deliverable {
      id
      name
      viewerCanDelete
    }
  `,
  org: graphql`
    fragment DeliverableActionCell_org on Org {
      id
    }
  `,
  me: graphql`
    fragment DeliverableActionCell_me on User {
      id
    }
  `,
});
