/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import type { OpportunityAmount_opportunity } from './__generated__/OpportunityAmount_opportunity.graphql';
import type { OpportunityAmount_org } from './__generated__/OpportunityAmount_org.graphql';

class OpportunityAmount extends React.Component<{
  opportunity: OpportunityAmount_opportunity,
  org: OpportunityAmount_org,
}> {
  render() {
    const {
      org,
      opportunity: { amount, viewerCanViewOpportunityAmount },
    } = this.props;
    if (!viewerCanViewOpportunityAmount) return <i>Restricted</i>;
    return formatCost(amount, org.settings.currency);
  }
}

export default createFragmentContainer(
  OpportunityAmount,
  graphql`
    fragment OpportunityAmount_org on Org {
      settings {
        currency
      }
    }
    fragment OpportunityAmount_opportunity on SalesforceOpportunityType {
      amount
      viewerCanViewOpportunityAmount
    }
  `,
);
