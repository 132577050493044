/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduleDayHeader_scheduleDay$ref: FragmentReference;
export type ScheduleDayHeader_scheduleDay = {|
  +title: ?string,
  +date: any,
  +viewerCanUpdate: boolean,
  +viewerCanRemove: boolean,
  +schedule: {|
    +event: {|
      +tz: string
    |}
  |},
  +$refType: ScheduleDayHeader_scheduleDay$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ScheduleDayHeader_scheduleDay",
  "type": "ScheduleDay",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanRemove",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "schedule",
      "storageKey": null,
      "args": null,
      "concreteType": "Schedule",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "event",
          "storageKey": null,
          "args": null,
          "concreteType": "Event",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "tz",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ccc4950e919e5ffd2e6d89efa30d245d';
module.exports = node;
