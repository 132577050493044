export default class Validator {
  constructor(schema) {
    this.schema = schema;

    this.validators = {
      required(data) {
        return data && String(data).length > 0;
      },
      requiredInScope(data, rules, key) {
        const { fields } = rules;
        const notEmpty = fields.every(f => {
          return data[f] && String(data[f]).length > 0;
        });
        const allEmpty = fields.every(f => {
          return !data[f];
        });
        return allEmpty || (notEmpty && data[key] && String(data[key]).length > 0);
      },
      requiredUnless(data, rules, key) {
        const { fields } = rules;
        const notEmpty = fields.every(f => {
          return data[f] && String(data[f]).length > 0;
        });
        return notEmpty || (data[key] && String(data[key]).length > 0);
      },
      requiredIf(data, conditions, key) {
        const conditionsMet = Object.keys(conditions).every(cKey => {
          return conditions[cKey] === data[cKey];
        });
        return !conditionsMet || (data[key] && String(data[key]).length > 0);
      },
      max(data, value) {
        return data && String(data).length <= value;
      },
      min(data, value) {
        return data && String(data).length >= value;
      },
      email(data) {
        // taken from is.js (https://github.com/arasatasaygin/is.js/blob/master/is.js#L310)
        return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(data);
      },
      matches(data, value) {
        return data === value;
      },
      message() {
        return true;
      },
      fields() {
        return true;
      },
      conditions() {
        return true;
      },
      optional(data) {
        return true;
      },
    };
  }
  isValid(data) {
    const errors = {};
    for (let key in data) {
      let rules = this.schema[key];

      if (! Array.isArray(rules)) {
        rules = [rules];
      }
      rules.some(_rules => {
        if (!_rules || (_rules.optional && !data[key])) return;
        for (let rule in _rules) {
          if (_rules[rule]) {
            let x = _rules[rule];
            let value;
            if (rule === 'matches') x = data[x];
            if (rule === 'requiredInScope' || rule === 'requiredUnless') {
              x = _rules;
              value = data;
            } else if (rule === 'requiredIf') {
              x = _rules.conditions;
              value = data;
            } else {
              value = data[key];
            }
            if (!this.validators[rule](value, x, key)) {
              errors[key] = _rules.message || ' ';
              break;
            }
          }
        }
        return errors[key];
      });
    }
    return [Object.keys(errors).length === 0, errors];
  }
}
