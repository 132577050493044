/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import canSyncToSalesforce from 'components/Contacts/lib/canSyncToSalesforce';
import Tag from 'components/Contacts/Tag';
import MaterialAvatar from 'components/material/MaterialAvatar';
import SyncEnabledIcon from 'components/Salesforce/SyncIcons/SyncEnabledIcon';

import type { ContactSearchRow_contact } from './__generated__/ContactSearchRow_contact.graphql';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  line-height: 1.4;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
`;

const Main = styled.div`
  flex: 1 1 auto;
`;

const StyledTag = styled(Tag)`
  margin-left: 7px;
`;

const Name = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  white-space: nowrap;
`;

const Secondary = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 12px;
  white-space: nowrap;
`;

const StyledAvatar = styled(MaterialAvatar)`
  margin-right: 10px;
`;

const SyncStatus = styled.div`
  margin: 0 10px;
`;

class ContactSearchRow extends React.PureComponent<{
  contact: ContactSearchRow_contact,
  salesforceEnabled?: boolean,
}> {
  render() {
    const { syncEnabled } = canSyncToSalesforce(this.props.contact);

    return (
      <Row>
        <StyledAvatar user={this.props.contact} radius={12} />
        <Main>
          <Name>
            {this.props.contact.firstName} {this.props.contact.lastName}
            <StyledTag color="#3749FF" compact active>
              Contact
            </StyledTag>
          </Name>
          <Secondary>
            {[
              this.props.contact.title,
              this.props.contact.company && this.props.contact.company.name,
            ]
              .filter(Boolean)
              .join(' @ ') || this.props.contact.email}
          </Secondary>
        </Main>
        {this.props.salesforceEnabled && (
          <SyncStatus>
            <SyncEnabledIcon
              syncStatus={syncEnabled ? this.props.contact.syncStatus.state : 'DISABLED'}
            />
          </SyncStatus>
        )}
      </Row>
    );
  }
}

export default createFragmentContainer(
  ContactSearchRow,
  graphql`
    fragment ContactSearchRow_contact on Contact {
      firstName
      lastName
      email
      salesforceSyncAs
      title
      syncStatus {
        state
      }
      company {
        name
        salesforceId
      }
      ...MaterialAvatar_user
    }
  `,
);
