/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  createEventRequestSubmissionMutationResponse,
  createEventRequestSubmissionMutationVariables,
} from './__generated__/createEventRequestSubmissionMutation.graphql';

const mutation = graphql`
  mutation createEventRequestSubmissionMutation($input: CreateEventRequestSubmissionInput!) {
    createEventRequestSubmission(input: $input) {
      requestSubmissionEdge {
        node {
          id
        }
      }
    }
  }
`;

export default function createEventRequestSubmission(
  data: $PropertyType<createEventRequestSubmissionMutationVariables, 'input'>,
): Promise<createEventRequestSubmissionMutationResponse> {
  const variables: createEventRequestSubmissionMutationVariables = {
    input: {
      ...data,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    public: true,
  });
}
