/* @flow */
import React from 'react';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { VendorCreator_vendor } from './__generated__/VendorCreator_vendor.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

class VendorCreator extends React.PureComponent<{
  vendor: VendorCreator_vendor,
}> {
  render() {
    if (this.props.vendor.createdBy == null) {
      return null;
    }
    return (
      <Container>
        <MaterialAvatar radius={13} user={this.props.vendor.createdBy} />
        <TextWrapper>{fullNameOfUser(this.props.vendor.createdBy)}</TextWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(
  VendorCreator,
  graphql`
    fragment VendorCreator_vendor on Vendor {
      createdBy {
        firstName
        lastName
        email
        ...MaterialAvatar_user
      }
    }
  `,
);
