/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import addAttachmentToBrief from 'graph/mutations/event_brief/addAttachmentToBrief';
import removeAttachmentFromBrief from 'graph/mutations/event_brief/removeAttachmentFromBrief';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { CircaFile } from 'utils/uploading/types';
import { download } from 'utils/Attachments';

import type { BriefDataType } from './AttachmentPreview';

const Action = styled.div`
  visibility: hidden;
  flex-shrink: 0;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const StyledTooltip = styled.span`
  margin-left: 8px;
`;

const Row = styled.div`
  display: flex;
  cursor: pointer;
  color: ${props => props.theme.primaryActionColor};
  border-radius: 3px;
  font-size: 13px;
  padding: 3px 5px 3px 10px;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
    color: ${props => props.theme.primaryActionDarkerColor};
    ${Action} {
      visibility: visible;
    }
  }
`;

const Name = styled.div`
  flex: 1 1 auto;
  width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default class AttachmentRow extends React.PureComponent<{
  attachment: CircaFile,
  onRemove?: (attachmentId: string) => void,
  onPreview: (attachmentId: string) => void,
  readOnly?: boolean,
  briefData?: BriefDataType,
}> {
  handleRemoveClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.onRemove && this.props.attachment.id != null) {
      this.props.onRemove(this.props.attachment.id);
    }
  };

  handleDownloadClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    download(this.props.attachment.fileurl, this.props.attachment.filename);
  };

  handleClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.isLink()) {
      window.open(this.props.attachment.fileurl);
    } else if (this.props.attachment.id != null) {
      this.props.onPreview(this.props.attachment.id);
    }
  };

  handleSaveBrief = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { briefData, attachment } = this.props;
    if (briefData) {
      if (briefData.briefAttachmentIds.includes(attachment.id)) {
        if (!attachment.id) return;
        removeAttachmentFromBrief(attachment.id, briefData.eventId).catch(showModernMutationError);
      } else {
        if (!attachment.id) return;
        addAttachmentToBrief(attachment.id, briefData.eventId).catch(showModernMutationError);
      }
    }
  };

  isLink() {
    return this.props.attachment.filetype && this.props.attachment.filetype.startsWith('link/');
  }

  render() {
    const isLink = this.isLink();

    const { briefData } = this.props;
    const includedInBrief =
      briefData && briefData.briefAttachmentIds.includes(this.props.attachment.id);
    return (
      <Row onClick={this.handleClick}>
        <Name>{this.props.attachment.filename || this.props.attachment.fileurl}</Name>
        {briefData &&
          briefData.canUpdateBrief && (
            <span data-tooltip={includedInBrief ? 'Remove from Brief' : 'Add to Brief'}>
              <Action
                className={classNames('fa fa-fw', includedInBrief ? 'fa-minus' : 'fa-plus')}
                onClick={this.handleSaveBrief}
              />
            </span>
          )}
        {isLink && (
          <StyledTooltip data-tooltip="Open Link">
            <Action className="fa fa-fw fa-external-link" />
          </StyledTooltip>
        )}
        {!isLink && (
          <StyledTooltip data-tooltip="Preview">
            <Action className="fa fa-fw fa-eye" />
          </StyledTooltip>
        )}
        {!isLink && (
          <StyledTooltip data-tooltip="Download">
            <Action className="fa fa-fw fa-download" onClick={this.handleDownloadClick} />
          </StyledTooltip>
        )}
        {!this.props.readOnly &&
          this.props.onRemove &&
          (this.props.attachment.viewerCanDelete != null
            ? this.props.attachment.viewerCanDelete
            : true) && (
            <StyledTooltip data-tooltip="Delete">
              <Action className="fa fa-fw fa-trash" onClick={this.handleRemoveClick} />
            </StyledTooltip>
          )}
      </Row>
    );
  }
}
