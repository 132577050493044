/* @flow */

import React from 'react';
import styled, { css } from 'styled-components';

import BriefViewSharedContext from '../BriefViewSharedContext';

const MainText = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.rowPrimaryTextColor};
  ${props =>
    props.isShared &&
    css`
      @media (max-width: 905px) {
        font-size: 16px;
      }
    `};
`;

const SecondoryText = styled.div`
  font-size: 14px;
  color: ${props => props.theme.rowPrimaryTextColor};
  text-decoration: underline;
`;

const HeadingField = (props: {
  mainText: ?string,
  secondaryText: ?string,
  className?: ?string,
}) => {
  const { mainText, secondaryText, className } = props;
  return mainText || secondaryText ? (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <div className={className}>
          {mainText && <MainText isShared={sharedView}>{mainText}</MainText>}
          {secondaryText && <SecondoryText>{`at ${secondaryText}`}</SecondoryText>}
        </div>
      )}
    </BriefViewSharedContext.Consumer>
  ) : null;
};
export default HeadingField;
