/**
 * @flow
 * @relayHash 9ae8b910af1f694caf860e9a9a5eb579
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmailActivityEventScope = "MY_EVENTS" | "MY_TEAMS";
export type UpdateEmailSubscriptionInput = {
  clientMutationId?: ?string,
  userId: string,
  eventScope?: ?EmailActivityEventScope,
  activitySummary?: ?number,
  assignmentNotifications?: ?number,
  activityNotifications?: ?boolean,
  mentionNotifications?: ?boolean,
  reminders?: ?boolean,
  postEventEngagementReport?: ?boolean,
  contactRegistrationNotifications?: ?boolean,
  contactAttendanceNotifications?: ?boolean,
  addEventStaffNotifications?: ?boolean,
};
export type updateEmailSubscriptionMutationVariables = {|
  input: UpdateEmailSubscriptionInput
|};
export type updateEmailSubscriptionMutationResponse = {|
  +updateEmailSubscription: ?{|
    +user: {|
      +id: string,
      +emailSubscription: {|
        +activitySummary: number,
        +eventScope: EmailActivityEventScope,
        +assignmentNotifications: number,
        +activityNotifications: boolean,
        +mentionNotifications: boolean,
        +reminders: boolean,
        +postEventEngagementReport: boolean,
        +contactRegistrationNotifications: boolean,
        +contactAttendanceNotifications: boolean,
        +addEventStaffNotifications: boolean,
      |},
    |}
  |}
|};
export type updateEmailSubscriptionMutation = {|
  variables: updateEmailSubscriptionMutationVariables,
  response: updateEmailSubscriptionMutationResponse,
|};
*/


/*
mutation updateEmailSubscriptionMutation(
  $input: UpdateEmailSubscriptionInput!
) {
  updateEmailSubscription(input: $input) {
    user {
      id
      emailSubscription {
        activitySummary
        eventScope
        assignmentNotifications
        activityNotifications
        mentionNotifications
        reminders
        postEventEngagementReport
        contactRegistrationNotifications
        contactAttendanceNotifications
        addEventStaffNotifications
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEmailSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateEmailSubscriptionInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activitySummary",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eventScope",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "assignmentNotifications",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "activityNotifications",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mentionNotifications",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reminders",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "postEventEngagementReport",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactRegistrationNotifications",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactAttendanceNotifications",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "addEventStaffNotifications",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEmailSubscriptionMutation",
  "id": null,
  "text": "mutation updateEmailSubscriptionMutation(\n  $input: UpdateEmailSubscriptionInput!\n) {\n  updateEmailSubscription(input: $input) {\n    user {\n      id\n      emailSubscription {\n        activitySummary\n        eventScope\n        assignmentNotifications\n        activityNotifications\n        mentionNotifications\n        reminders\n        postEventEngagementReport\n        contactRegistrationNotifications\n        contactAttendanceNotifications\n        addEventStaffNotifications\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEmailSubscriptionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateEmailSubscription",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEmailSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "emailSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "EmailSubscription",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  v11,
                  v12
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEmailSubscriptionMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateEmailSubscription",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateEmailSubscriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "emailSubscription",
                "storageKey": null,
                "args": null,
                "concreteType": "EmailSubscription",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  v11,
                  v12,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b60d9f1a9811735e0b98a628005bbca5';
module.exports = node;
