/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventAttendanceStatus_contactEvent$ref: FragmentReference;
export type EventAttendanceStatus_contactEvent = {|
  +attendanceStatus: ?{|
    +id: string,
    +name: string,
  |},
  +node: {|
    +id: string,
    +name: string,
  |},
  +$refType: EventAttendanceStatus_contactEvent$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "EventAttendanceStatus_contactEvent",
  "type": "ContactEventEdge",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "attendanceStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldOptionType",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": v0
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3a0acc79fdf8f3dd64221ea3024b7a71';
module.exports = node;
