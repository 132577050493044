/* @flow */
import * as React from 'react';
import { GoogleMap, withGoogleMap } from 'react-google-maps';

import GoogleMapsScriptWrapper from 'components/GoogleMapsScriptWrapper';

import type { InfoBoxOptionsType, MapMarkerType } from './googleMapsWrapperTypes';
import InfoBoxFixedWrapper from './InfoBoxFixedWrapper';
import MarkersWrapper from './MarkersWrapper';

const MapsWrapper = withGoogleMap(props => {
  const mapOptions = {
    streetViewControl: false,
    scrollwheel: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.TOP_RIGHT,
    },
  };

  return (
    <GoogleMap {...props} options={mapOptions}>
      {props.children}
    </GoogleMap>
  );
});

export default class GoogleMapsWrapper extends React.Component<
  {
    defaultZoom?: ?number,
    containerElementHeight: number,
    windowTopOffsetMobile: number, // Required to calculate the map height on initialization and resize
    windowTopOffsetDesktop: number, // Required to calculate the map height on initialization and resize
    mapElement?: React.Node,
    markers?: $ReadOnlyArray<MapMarkerType>,
    defaultCenter?: {
      lat: number,
      lng: number,
    },
    infoBoxOptions?: {
      infoBoxView?: React.ComponentType<any>,
      infoBoxOffset?: number,
      customProp?: InfoBoxOptionsType,
    },
    fixedInfoBoxOptions?: {
      fixedInfoBoxView: React.ComponentType<any>,
      customProp?: InfoBoxOptionsType,
    },
  },
  {
    height: number,
    inBounds: boolean,
    toggledBoxId: string,
    clusteredMarkersIds: ?$ReadOnlyArray<string>,
    fixedPopupPosition: ?{
      lat: number,
      lng: number,
    },
  },
> {
  state = {
    height: this.props.containerElementHeight,
    inBounds: false,
    toggledBoxId: '',
    fixedPopupPosition: null,
    clusteredMarkersIds: null,
  };

  mapContainer: React.ElementRef<GoogleMap>;

  componentDidMount() {
    this.updateLayout();
    window.addEventListener('resize', this.updateLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  updateLayout = () => {
    const innerHeight = window.innerHeight;
    const innerWidth = window.innerWidth;
    const maxWithMobile = 800;
    const mobileHeaderHeight = this.props.windowTopOffsetMobile;
    const desktopHeaderHeight = this.props.windowTopOffsetDesktop;

    const heightOffset = innerWidth < maxWithMobile ? mobileHeaderHeight : desktopHeaderHeight;
    const height = innerHeight - heightOffset;

    this.setState({ height });
  };

  defaultZoomLevel = () => {
    const markers: ?$ReadOnlyArray<MapMarkerType> = this.props.markers;
    const defaultZoom = 2;
    if (markers && markers.length === 0) {
      return defaultZoom;
    }

    return this.props.defaultZoom !== null ? this.props.defaultZoom : defaultZoom;
  };

  handleOnToggle = (markerId: string) => {
    this.setState({ toggledBoxId: markerId, fixedPopupPosition: null });
  };

  handleClusteredClicked = (
    markers: $ReadOnlyArray<string>,
    position: { lat: number, lng: number },
  ) => {
    this.setState({ fixedPopupPosition: position, clusteredMarkersIds: markers });
  };

  handleBoundsChanged = () => {
    if (this.mapContainer != null && this.state.fixedPopupPosition != null) {
      const mapContainer = this.mapContainer;
      const map = mapContainer.state.map;
      const bounds = map.getBounds().toJSON();
      this.setState({
        fixedPopupPosition: {
          lat: bounds.north,
          lng: bounds.west,
        },
      });
    }
  };

  handleMapDefaultCentre(): { lat: number, lng: number } {
    if (this.props.defaultCenter) {
      return { lat: this.props.defaultCenter.lat, lng: this.props.defaultCenter.lng };
    }
    const markers: ?$ReadOnlyArray<MapMarkerType> = this.props.markers;

    if (markers) {
      if (markers.length === 1) {
        return { lat: markers[0].lat, lng: markers[0].lng };
      }
    }

    // Somewhere in the North Atlantic Ocean to center the entire world in empty view
    return { lat: 28.769321, lng: -34.39539 };
  }

  handleFitBounds() {
    if (this.state.inBounds) {
      return;
    }

    if (this.mapContainer != null) {
      const mapContainer = this.mapContainer;
      const { LatLng, LatLngBounds } = window.google.maps;
      const bounds = new LatLngBounds();
      const markers: ?$ReadOnlyArray<MapMarkerType> = this.props.markers;
      const googleMap = mapContainer.state.map;

      if ((markers && markers.length === 0) || markers == null) return;

      const newBounds = markers.map(marker => new LatLng(Number(marker.lat), Number(marker.lng)));

      newBounds.forEach(bound => bounds.extend(bound));
      googleMap.fitBounds(bounds);

      this.setState({ inBounds: true });
      const currentZoom = googleMap.getZoom();

      if (currentZoom > 15) {
        setTimeout(() => {
          mapContainer.setState({ zoom: 15 });
        }, 100);
      }
    }
  }

  render() {
    const clustered: boolean = !!this.props.markers && this.props.markers.length >= 1;
    const markers = this.props.markers;
    return (
      <GoogleMapsScriptWrapper>
        <MapsWrapper
          defaultZoom={this.defaultZoomLevel()}
          defaultCenter={this.handleMapDefaultCentre()}
          containerElement={<div style={{ height: `${this.state.height}px` }} />}
          loadingElement={<div style={{ height: '100%' }} />}
          mapElement={
            this.props.mapElement !== null ? (
              this.props.mapElement
            ) : (
              <div style={{ height: '100%' }} />
            )
          }
          clickableIcons={false}
          onTilesLoaded={() => this.handleFitBounds()}
          markers={this.props.markers}
          ref={ref => {
            this.mapContainer = ref;
          }}
          onClick={() => this.handleOnToggle('')}
          onIdle={this.handleBoundsChanged}
        >
          {this.props.fixedInfoBoxOptions &&
            this.state.clusteredMarkersIds != null &&
            this.state.fixedPopupPosition != null && (
              <InfoBoxFixedWrapper
                infoBoxOptions={this.props.fixedInfoBoxOptions}
                markers={this.state.clusteredMarkersIds}
                position={this.state.fixedPopupPosition}
                mapHeight={this.state.height}
              />
            )}

          {!!markers && markers.length > 0 && (
            <MarkersWrapper
              markers={markers}
              handleOnToggle={this.handleOnToggle}
              toggledBoxId={this.state.toggledBoxId}
              clustered={clustered}
              handleClusteredClicked={this.handleClusteredClicked}
              infoBoxOptions={this.props.infoBoxOptions}
              fixedInfoBoxIsOpen={this.state.fixedPopupPosition != null}
            />
          )}
        </MapsWrapper>
      </GoogleMapsScriptWrapper>
    );
  }
}
