/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import TaskWindowPopup from 'components/Tasks/TaskWindow/TaskWindowPopup';

import type { DeliverableTitle_deliverable } from './__generated__/DeliverableTitle_deliverable.graphql';
import type { DeliverableTitle_org } from './__generated__/DeliverableTitle_org.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  cursor: pointer;
`;

type Props = {
  deliverable: DeliverableTitle_deliverable,
  org: DeliverableTitle_org,
  updateColumnWidth: () => void,
};

type State = {
  showTaskPopup: boolean,
};

class DeliverableTitle extends React.PureComponent<Props, State> {
  state = { showTaskPopup: false };

  handleTaskWindowShow = () => {
    this.setState({ showTaskPopup: true });
  };

  handleTaskWindowHide = () => {
    this.setState({ showTaskPopup: false });
  };

  render() {
    const { deliverable, org } = this.props;

    return (
      <Container>
        <TextWrapper onClick={this.handleTaskWindowShow}>{deliverable.name}</TextWrapper>

        {this.state.showTaskPopup &&
          document.body &&
          createPortal(
            <TaskWindowPopup
              org={org}
              onHide={this.handleTaskWindowHide}
              taskSlug={deliverable.slug}
              updateColumnWidth={this.props.updateColumnWidth}
            />,
            document.body,
          )}
      </Container>
    );
  }
}
export default createFragmentContainer(DeliverableTitle, {
  deliverable: graphql`
    fragment DeliverableTitle_deliverable on Deliverable {
      name
      slug
    }
  `,
  org: graphql`
    fragment DeliverableTitle_org on Org {
      id
      ...TaskWindowPopup_org
    }
  `,
});
