/* @flow */
import * as React from 'react';

import BriefField from './BriefField';

const SimpleBriefField = (props: {
  contentDataAvailable: ?(string | number),
  label: string,
  data?: ?(string | number | React.Node),
  className?: ?string,
}) => {
  const { label, contentDataAvailable, data, className } = props;
  const presentingData = data != null ? data : contentDataAvailable;
  return (
    <BriefField label={label} contentDataAvailable={contentDataAvailable} className={className}>
      {presentingData}
    </BriefField>
  );
};
export default SimpleBriefField;
