/**
 * @flow
 * @relayHash b22c6dc788f4a59af533dcc03cda01b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ConnectCampaignPanelSearch_event$ref = any;
export type ConnectCampaignPanelQueryVariables = {|
  eventId: string
|};
export type ConnectCampaignPanelQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: ConnectCampaignPanelSearch_event$ref
  |}
|};
export type ConnectCampaignPanelQuery = {|
  variables: ConnectCampaignPanelQueryVariables,
  response: ConnectCampaignPanelQueryResponse,
|};
*/


/*
query ConnectCampaignPanelQuery(
  $eventId: ID!
) {
  event: node(id: $eventId) {
    __typename
    ...ConnectCampaignPanelSearch_event
    id
  }
}

fragment ConnectCampaignPanelSearch_event on Event {
  id
  salesforceCampaign {
    id
    name
  }
  team {
    org {
      salesforceAccount {
        salesforceCampaigns(first: 10, query: "") {
          edges {
            node {
              id
              ...ConnectCampaignPanelRow_campaign
            }
          }
        }
        id
      }
      id
    }
    id
  }
}

fragment ConnectCampaignPanelRow_campaign on SalesforceCampaign {
  id
  name
  event {
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ConnectCampaignPanelQuery",
  "id": null,
  "text": "query ConnectCampaignPanelQuery(\n  $eventId: ID!\n) {\n  event: node(id: $eventId) {\n    __typename\n    ...ConnectCampaignPanelSearch_event\n    id\n  }\n}\n\nfragment ConnectCampaignPanelSearch_event on Event {\n  id\n  salesforceCampaign {\n    id\n    name\n  }\n  team {\n    org {\n      salesforceAccount {\n        salesforceCampaigns(first: 10, query: \"\") {\n          edges {\n            node {\n              id\n              ...ConnectCampaignPanelRow_campaign\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment ConnectCampaignPanelRow_campaign on SalesforceCampaign {\n  id\n  name\n  event {\n    name\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ConnectCampaignPanelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "event",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ConnectCampaignPanelSearch_event",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ConnectCampaignPanelQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "event",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v2,
          {
            "kind": "InlineFragment",
            "type": "Event",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "salesforceCampaign",
                "storageKey": null,
                "args": null,
                "concreteType": "SalesforceCampaign",
                "plural": false,
                "selections": [
                  v2,
                  v3
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "org",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Org",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "salesforceAccount",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SalesforceAccount",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "salesforceCampaigns",
                            "storageKey": "salesforceCampaigns(first:10,query:\"\")",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 10,
                                "type": "Int"
                              },
                              {
                                "kind": "Literal",
                                "name": "query",
                                "value": "",
                                "type": "String"
                              }
                            ],
                            "concreteType": "SalesforceCampaignRequiredConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "SalesforceCampaignRequiredEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "SalesforceCampaign",
                                    "plural": false,
                                    "selections": [
                                      v2,
                                      v3,
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "event",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "Event",
                                        "plural": false,
                                        "selections": [
                                          v3,
                                          v2
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          v2
                        ]
                      },
                      v2
                    ]
                  },
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49e6b1d239c8c65a9cc91975ceb422d0';
module.exports = node;
