/**
 * @flow
 * @relayHash 55aaaf49a7c40ae0409229f777ce4ff1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamPage_team$ref = any;
export type TeamQueryVariables = {|
  teamSlug: string
|};
export type TeamQueryResponse = {|
  +team: {|
    +id: string,
    +name: string,
    +viewerCanUpdate: boolean,
    +$fragmentRefs: TeamPage_team$ref,
  |}
|};
export type TeamQuery = {|
  variables: TeamQueryVariables,
  response: TeamQueryResponse,
|};
*/


/*
query TeamQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    id
    name
    viewerCanUpdate
    ...TeamPage_team
  }
}

fragment TeamPage_team on Team {
  id
  dbId
  name
  slug
  ...TeamHeader_team
}

fragment TeamHeader_team on Team {
  id
  dbId
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamQuery",
  "id": null,
  "text": "query TeamQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    id\n    name\n    viewerCanUpdate\n    ...TeamPage_team\n  }\n}\n\nfragment TeamPage_team on Team {\n  id\n  dbId\n  name\n  slug\n  ...TeamHeader_team\n}\n\nfragment TeamHeader_team on Team {\n  id\n  dbId\n  name\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "FragmentSpread",
            "name": "TeamPage_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dbId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '541485a1e5baa546e9dff59bcfbffdc3';
module.exports = node;
