/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation removeFolderMutation($input: RemoveFolderInput!) {
    removeFolder(input: $input) {
      removedFolderId
      removedDeliverableIds
      event {
        progress
      }
    }
  }
`;

export default function removeFolder(eventId: string, folderId: string, tasksIds: Array<string>) {
  commitModernMutation({
    mutation,
    variables: {
      input: {
        folderId,
      },
    },
    optimisticResponse: {
      removeFolder: {
        removedFolderId: folderId,
        removedDeliverableIds: tasksIds,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedFolderId',
        parentId: eventId,
        connections: [{ field: 'folders' }],
      }),
      removeRecordFromConnection({
        deletedIdField: 'removedDeliverableIds',
        parentId: eventId,
        connections: [
          { field: 'deliverables' },
          { key: 'TasksSectionViewContent_deliverables' },
          { key: 'TasksDueDateViewContent_deliverables' },
        ],
      }),
      removeRecordFromStore({ deletedIdField: 'removedFolderId' }),
    ),
  });
}
