/* @flow */
import React from 'react';
import styled from 'styled-components';

import Radio from 'components/SharedForm/Radio';

import CheckBox from '../../form/CheckBox';

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: default;
`;

const OptionLabel = styled.div`
  flex: 1 1 auto;
  margin-left: 20px;
  font-size: 13px;
  color: ${props => (props.active ? '#4a5665' : 'rgba(74, 86, 101, 0.54)')};
`;

const OptionAction = styled.div`
  line-height: 20px;
  font-size: 20px;
  cursor: pointer;
  color: #d8d8d8;
  &:hover {
    color: #aaa;
  }
`;

const ShowHideIcon = styled.div`
  > i:last-child {
    display: none;
  }
  &:hover {
    i:first-child {
      display: none;
    }
    i:last-child {
      display: inline-block;
    }
  }
`;

export default class EditableQuestionSelectOptionRow extends React.PureComponent<{
  type: 'checkbox' | 'radio',
  active: boolean,
  option: {
    +id: string,
    +name: string,
  },
  onShow: (id: string) => void,
  onHide: (id: string) => void,
}> {
  handleShow = () => {
    this.props.onShow(this.props.option.id);
  };

  handleHide = () => {
    this.props.onHide(this.props.option.id);
  };

  render() {
    const { type, active, option } = this.props;

    return (
      <OptionRow>
        {type === 'checkbox' ? (
          <CheckBox checked={false} disabled compact />
        ) : (
          <Radio checked={false} disabled compact />
        )}
        <OptionLabel active={active}>{option.name}</OptionLabel>
        <OptionAction>
          {active ? (
            <ShowHideIcon onClick={this.handleHide}>
              <i className="fa fa-fw fa-eye" />
              <i className="fa fa-fw fa-eye-slash" />
            </ShowHideIcon>
          ) : (
            <ShowHideIcon onClick={this.handleShow}>
              <i className="fa fa-fw fa-eye-slash" />
              <i className="fa fa-fw fa-eye" />
            </ShowHideIcon>
          )}
        </OptionAction>
      </OptionRow>
    );
  }
}
