/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import {
  type changeEventBudgetCategoryMutationVariables,
  type changeEventBudgetCategoryMutationResponse,
} from './__generated__/changeEventBudgetCategoryMutation.graphql';

export type ChangeMutationInput = $PropertyType<
  changeEventBudgetCategoryMutationVariables,
  'input',
>;

const mutation = graphql`
  mutation changeEventBudgetCategoryMutation($input: ChangeEventBudgetCategoryInput!) {
    changeEventBudgetCategory(input: $input) {
      event {
        budgetCategories {
          edges {
            ...CategoriesTable_categoryEdges
          }
        }
      }
    }
  }
`;

const changeEventBudgetCategory = (
  input: ChangeMutationInput,
): Promise<changeEventBudgetCategoryMutationResponse> => {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  });
};

export default changeEventBudgetCategory;
