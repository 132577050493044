/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type IntegrationSettingsAuth_integrable$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type IntegrationSettingsPage_integrable$ref: FragmentReference;
export type IntegrationSettingsPage_integrable = {|
  +$fragmentRefs: IntegrationSettingsAuth_integrable$ref,
  +$refType: IntegrationSettingsPage_integrable$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "IntegrationSettingsPage_integrable",
  "type": "IntegrableInterface",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "IntegrationSettingsAuth_integrable",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0545bd2f756062f92ebfeba7bbcab48b';
module.exports = node;
