/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Direction = "ASC" | "DESC";
export type EventFormat = "HYBRID" | "PHYSICAL" | "VIRTUAL";
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventRegistrationFormStatus = "DRAFT" | "NOT_AVAILABLE" | "PUBLISHED";
export type EventRequestSubmissionStatusFilter = "APPROVED" | "DECLINED" | "NEW" | "N_A" | "RESUBMITTED" | "RETURNED";
export type EventSort = "ATTENDED_CONTACTS_TOTAL" | "BOOTH" | "BOOTH_DIMENSIONS" | "CITY" | "COMMITMENT_LEVEL" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATOR" | "CUSTOM" | "END_DATE" | "EVENT_FORMAT" | "EVENT_LEAD" | "LAST_SYNCED" | "LOCATION" | "MARKETO_ID" | "NAME" | "OPPORTUNITIES_AMOUNT" | "OPPORTUNITIES_NUMBER" | "PROGRESS" | "REGISTERED_CONTACTS_TOTAL" | "REQUESTED_BY" | "REQUESTED_DATE" | "REQUESTED_FORM_NAME" | "REQUESTED_STATUS" | "SALESFORCE_ID" | "STAFF_COUNT" | "START_DATE" | "STATE" | "STREET" | "SYNC_STATUS" | "TEAM_NAME" | "TEMPLATES_FIRST" | "TOTAL_ACTUAL_COST" | "TOTAL_PAID_COST" | "TOTAL_PLANNED_COST" | "UPDATED_AT" | "UPDATER" | "VENUE_NAME" | "VIRTUAL_LOCATION" | "WEBSITE" | "ZIP_CODE";
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsPopulateFilters_eventList$ref: FragmentReference;
export type AllEventsPopulateFilters_eventList = {|
  +id: string,
  +search: ?string,
  +queries: ?$ReadOnlyArray<string>,
  +statuses: ?$ReadOnlyArray<EventStatus>,
  +beforeDate: ?any,
  +afterDate: ?any,
  +listType: ?EventListEnum,
  +venueNames: ?$ReadOnlyArray<string>,
  +eventIds: ?$ReadOnlyArray<string>,
  +cities: ?$ReadOnlyArray<string>,
  +states: ?$ReadOnlyArray<string>,
  +countries: ?$ReadOnlyArray<string>,
  +eventFormats: ?$ReadOnlyArray<EventFormat>,
  +syncStatuses: ?$ReadOnlyArray<SyncState>,
  +contactsCount: ?{|
    +min: ?number,
    +max: ?number,
  |},
  +opportunitiesNumber: ?{|
    +min: ?number,
    +max: ?number,
  |},
  +opportunitiesAmount: ?{|
    +min: ?number,
    +max: ?number,
  |},
  +registeredContactsTotal: ?{|
    +min: ?number,
    +max: ?number,
  |},
  +attendedContactsTotal: ?{|
    +min: ?number,
    +max: ?number,
  |},
  +registrationFormStatuses: ?$ReadOnlyArray<EventRegistrationFormStatus>,
  +sort: ?EventSort,
  +direction: ?Direction,
  +savedListColumns: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +columnName: ?string,
        +customField: ?{|
          +id: string
        |},
      |}
    |}>
  |},
  +customSort: ?{|
    +id: string
  |},
  +teams: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +leads: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +creators: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +updaters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +staffers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +requestStatuses: ?$ReadOnlyArray<EventRequestSubmissionStatusFilter>,
  +requestedDate: ?$ReadOnlyArray<any>,
  +updatedAt: ?$ReadOnlyArray<any>,
  +createdAt: ?$ReadOnlyArray<any>,
  +eventRequestForms: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +eventRequestReviewers: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +userRequesters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +contactRequesters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +actionsRequestForms: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |},
  +customSavedNumberFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +minValue: ?number,
        +maxValue: ?number,
        +customField: {|
          +id: string
        |},
      |}
    |}>
  |},
  +customSavedCurrencyFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +minValue: ?number,
        +maxValue: ?number,
        +fieldName: ?string,
        +customField: ?{|
          +id: string
        |},
      |}
    |}>
  |},
  +customSavedDateFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +minValue: ?any,
        +maxValue: ?any,
        +customField: {|
          +id: string
        |},
      |}
    |}>
  |},
  +customSavedBooleanFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +value: boolean,
        +customField: {|
          +id: string
        |},
      |}
    |}>
  |},
  +customSavedUserMultiselectFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +fieldName: ?string,
        +customField: ?{|
          +id: string
        |},
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +user: {|
                +id: string
              |}
            |}
          |}>
        |},
      |}
    |}>
  |},
  +customSavedMultiselectFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +option: {|
                +id: string
              |}
            |}
          |}>
        |},
        +customField: {|
          +id: string
        |},
      |}
    |}>
  |},
  +$refType: AllEventsPopulateFilters_eventList$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "min",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "max",
    "args": null,
    "storageKey": null
  }
],
v2 = [
  v0
],
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": v2
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "UserRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": v2
      }
    ]
  }
],
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "EventRequestFormRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "EventRequestForm",
        "plural": false,
        "selections": v2
      }
    ]
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "minValue",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "maxValue",
  "args": null,
  "storageKey": null
},
v8 = [
  v0,
  v6,
  v7,
  v3
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AllEventsPopulateFilters_eventList",
  "type": "EventList",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "search",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "queries",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statuses",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "beforeDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "afterDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "listType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "venueNames",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "eventIds",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cities",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "states",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "countries",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "eventFormats",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "syncStatuses",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contactsCount",
      "storageKey": null,
      "args": null,
      "concreteType": "NumberRange",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "opportunitiesNumber",
      "storageKey": null,
      "args": null,
      "concreteType": "NumberRange",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "opportunitiesAmount",
      "storageKey": null,
      "args": null,
      "concreteType": "NumberRange",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "registeredContactsTotal",
      "storageKey": null,
      "args": null,
      "concreteType": "NumberRange",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "attendedContactsTotal",
      "storageKey": null,
      "args": null,
      "concreteType": "NumberRange",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "registrationFormStatuses",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sort",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "direction",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "savedListColumns",
      "storageKey": null,
      "args": null,
      "concreteType": "SavedListColumnTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SavedListColumnTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SavedListColumnType",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "columnName",
                  "args": null,
                  "storageKey": null
                },
                v3
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSort",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldType",
      "plural": false,
      "selections": v2
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "teams",
      "storageKey": null,
      "args": null,
      "concreteType": "TeamRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TeamRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Team",
              "plural": false,
              "selections": v2
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "leads",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": v4
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "creators",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": v4
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "updaters",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": v4
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "staffers",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": v4
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requestStatuses",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requestedDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventRequestForms",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestFormRequiredConnection",
      "plural": false,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventRequestReviewers",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": v4
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "userRequesters",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": v4
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contactRequesters",
      "storageKey": null,
      "args": null,
      "concreteType": "ContactRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ContactRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Contact",
              "plural": false,
              "selections": v2
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actionsRequestForms",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestFormRequiredConnection",
      "plural": false,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedNumberFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedNumberFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedNumberFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedNumberFilterType",
              "plural": false,
              "selections": v8
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedCurrencyFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedCurrencyFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedCurrencyFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedCurrencyFilterType",
              "plural": false,
              "selections": [
                v0,
                v6,
                v7,
                v9,
                v3
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedDateFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedDateFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedDateFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedDateFilterType",
              "plural": false,
              "selections": v8
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedBooleanFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedBooleanFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedBooleanFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedBooleanFilterType",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "value",
                  "args": null,
                  "storageKey": null
                },
                v3
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedUserMultiselectFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedUserMultiselectFilterType",
              "plural": false,
              "selections": [
                v0,
                v9,
                v3,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "options",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedUserMultiselectFilterOptionType",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "user",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "User",
                              "plural": false,
                              "selections": v2
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedMultiselectFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedMultiselectFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedMultiselectFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedMultiselectFilterType",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "options",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedMultiselectFilterOptionType",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "option",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomFieldOptionType",
                              "plural": false,
                              "selections": v2
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                v3
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'baf600152f97b4b52c882427fa1c96bb';
module.exports = node;
