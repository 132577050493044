/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { EventUpdatedAt_event } from './__generated__/EventUpdatedAt_event.graphql';

const EventUpdatedAt = (props: { event: EventUpdatedAt_event }) => {
  const event = props.event;
  const date = event.updatedAt && moment.tz(event.updatedAt, event.tz);
  return date ? <DateTimeTooltip date={date} /> : null;
};

export default createFragmentContainer(
  EventUpdatedAt,
  graphql`
    fragment EventUpdatedAt_event on Event {
      updatedAt
      tz
    }
  `,
);
