/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { createFolderMutationResponse } from './__generated__/createFolderMutation.graphql';

const mutation = graphql`
  mutation createFolderMutation($input: CreateFolderInput!) {
    createFolder(input: $input) {
      event {
        id
        dbId
        name
      }
      folderEdge {
        __typename
        node {
          id
          dbId
          name
          order
          viewerCanUpdate
          viewerCanDelete
          ...TasksFolder_folder
        }
      }
    }
  }
`;

export default function createFolder(eventId: string, name: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        eventId,
        name,
      },
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'folderEdge',
      connections: [{ field: 'folders' }],
    }),
  }).then((response: createFolderMutationResponse) => {
    if (response.createFolder) {
      const folder = response.createFolder.folderEdge.node;

      return folder.id;
    }
    throw new Error('No Folder Id');
  });
}
