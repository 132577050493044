/* @flow */
import styled from 'styled-components';

const WindowTitle = styled.div`
  flex: 1 1 auto;
  color: ${props => props.theme.windowTitleColor};
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
`;
export default WindowTitle;
