/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineTextField from 'components/budget/Table/InlineTextField';

import ContactStreet_contact from './__generated__/ContactStreet_contact.graphql';

class ContactStreet extends React.PureComponent<{
  contact: ContactStreet_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  handleUpdate = (value: ?string) => {
    if (!this.getError(value) && value !== this.props.contact.street) {
      this.props.onUpdate({ street: value });
      this.props.updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    if (this.props.fieldSettings.required && (!value || !value.trim())) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { contact, fieldSettings, updateColumnWidth } = this.props;
    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={contact.street || ''}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        onChange={this.handleUpdate}
        disabled={fieldSettings.restrictChangingValue}
      />
    );
  }
}

export default createFragmentContainer(
  ContactStreet,
  graphql`
    fragment ContactStreet_contact on Contact {
      street
    }
  `,
);
