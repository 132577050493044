/* @flow */
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  margin-top: -2px;
  line-height: 1;
  a {
    font-size: 22px;
    color: #3ba9da;
    cursor: pointer;
    &:hover {
      color: #248fbe;
    }
  }
`;

type Props = {
  showPreviousButton: boolean,
  showNextButton: boolean,
  onPreviousClick: () => void,
  onNextClick: () => void,
};

const CalendarNavbar = (props: Props) => (
  <Root>
    <a onClick={() => props.onPreviousClick()}>
      {props.showPreviousButton && <i className="fa fa-angle-left" />}
    </a>
    <a onClick={() => props.onNextClick()}>
      {props.showNextButton && <i className="fa fa-angle-right" />}
    </a>
  </Root>
);

export default CalendarNavbar;
