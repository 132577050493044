/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CompanyPageRoutes_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompanyPage_company$ref: FragmentReference;
export type CompanyPage_company = {|
  +id: string,
  +name: string,
  +salesforceId: ?string,
  +org: {|
    +id: string
  |},
  +viewerCanRemove: boolean,
  +viewerCanUpdate: boolean,
  +contacts: {|
    +totalCount: number
  |},
  +opportunities: {|
    +totalCount: number
  |},
  +$fragmentRefs: CompanyPageRoutes_company$ref,
  +$refType: CompanyPage_company$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "CompanyPage_company",
  "type": "Company",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "salesforceId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "org",
      "storageKey": null,
      "args": null,
      "concreteType": "Org",
      "plural": false,
      "selections": [
        v0
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanRemove",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contacts",
      "storageKey": null,
      "args": null,
      "concreteType": "ContactRequiredConnection",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "opportunities",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceOpportunityTypeRequiredConnection",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "FragmentSpread",
      "name": "CompanyPageRoutes_company",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '08bdd42088caa683289bd7056dbdeca2';
module.exports = node;
