/**
 * @flow
 * @relayHash 78b6c6ba37715ee26a54061f07f08095
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MembersPage_org$ref = any;
export type MembersQueryVariables = {||};
export type MembersQueryResponse = {|
  +org: {|
    +id: string,
    +$fragmentRefs: MembersPage_org$ref,
  |}
|};
export type MembersQuery = {|
  variables: MembersQueryVariables,
  response: MembersQueryResponse,
|};
*/


/*
query MembersQuery {
  org {
    id
    ...MembersPage_org
  }
}

fragment MembersPage_org on Org {
  id
  ...MembersHeader_org
}

fragment MembersHeader_org on Org {
  id
  viewerCanManageUsers
  viewerCanManageStaff
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MembersQuery",
  "id": null,
  "text": "query MembersQuery {\n  org {\n    id\n    ...MembersPage_org\n  }\n}\n\nfragment MembersPage_org on Org {\n  id\n  ...MembersHeader_org\n}\n\nfragment MembersHeader_org on Org {\n  id\n  viewerCanManageUsers\n  viewerCanManageStaff\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MembersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "FragmentSpread",
            "name": "MembersPage_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MembersQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageUsers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageStaff",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd6392cc6332a5fc449dba7c2020785d2';
module.exports = node;
