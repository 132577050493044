/**
 * @flow
 * @relayHash 77970f02f4fe3ead4880c958c4c4d456
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BillingContents_me$ref = any;
type BillingContents_org$ref = any;
export type BillingQueryVariables = {||};
export type BillingQueryResponse = {|
  +org: {|
    +$fragmentRefs: BillingContents_org$ref
  |},
  +me: {|
    +$fragmentRefs: BillingContents_me$ref
  |},
|};
export type BillingQuery = {|
  variables: BillingQueryVariables,
  response: BillingQueryResponse,
|};
*/


/*
query BillingQuery {
  org {
    ...BillingContents_org
    id
  }
  me {
    ...BillingContents_me
    id
  }
}

fragment BillingContents_org on Org {
  subscription {
    expiresAt
    upgradeRequired
    plan
    ...BillingTrialMessage_subscription
    ...BillingPlanMessage_subscription
    id
  }
}

fragment BillingContents_me on User {
  id
  email
}

fragment BillingTrialMessage_subscription on Subscription {
  expiresAt
  upgradeRequired
}

fragment BillingPlanMessage_subscription on Subscription {
  upgradeRequired
  ...ActiveSubscriptionMessage_subscription
}

fragment ActiveSubscriptionMessage_subscription on Subscription {
  expiresAt
  fatmLimit
  fatmCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BillingQuery",
  "id": null,
  "text": "query BillingQuery {\n  org {\n    ...BillingContents_org\n    id\n  }\n  me {\n    ...BillingContents_me\n    id\n  }\n}\n\nfragment BillingContents_org on Org {\n  subscription {\n    expiresAt\n    upgradeRequired\n    plan\n    ...BillingTrialMessage_subscription\n    ...BillingPlanMessage_subscription\n    id\n  }\n}\n\nfragment BillingContents_me on User {\n  id\n  email\n}\n\nfragment BillingTrialMessage_subscription on Subscription {\n  expiresAt\n  upgradeRequired\n}\n\nfragment BillingPlanMessage_subscription on Subscription {\n  upgradeRequired\n  ...ActiveSubscriptionMessage_subscription\n}\n\nfragment ActiveSubscriptionMessage_subscription on Subscription {\n  expiresAt\n  fatmLimit\n  fatmCount\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BillingQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BillingContents_org",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BillingContents_me",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BillingQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "expiresAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "upgradeRequired",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "plan",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fatmLimit",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fatmCount",
                "args": null,
                "storageKey": null
              },
              v0
            ]
          },
          v0
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5afc0c71c481a63329271701fa082ffe';
module.exports = node;
