/* @flow */
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import eventTabs from 'config/eventTabs';

import FeatureAccessContext from 'contexts/FeatureAccess';

import AuthorizableRoute from 'utils/routing/AuthorizableRoute';

import ErrorTester from 'components/ErrorTester';

import Attachments from './Attachments';
import Budget from './Budget';
import Contacts from './Contacts';
import Information from './Information';
import Notes from './Notes';
import EventRegistration from './Registration/EventRegistration';
import Roi from './Roi';
import Schedules from './Schedules';
import Staff from './Staff';
import Tasks from './Tasks';
import Vendors from './Vendors';

export default class EventRoutes extends React.Component<{
  pathPrefix: string,
  rootUrl: string,
  eventSlug: string,
  fiscalYearStart: number,
  disabledTabs: $ReadOnlyArray<string>,
}> {
  static contextType = FeatureAccessContext;

  renderLegacyRoute = (path: string, Component: React$ComponentType<any>, authorized: boolean) => {
    if (this.context.legacyFeatures) {
      return (
        <AuthorizableRoute
          authorized={authorized}
          path={path}
          render={props => <Component {...props} eventSlug={this.props.eventSlug} />}
        />
      );
    }

    return null;
  };

  render() {
    const { disabledTabs, pathPrefix } = this.props;
    return (
      <Switch>
        <Route path={`${this.props.pathPrefix}/info`} component={Information} />
        {this.renderLegacyRoute(
          `${pathPrefix}/contacts`,
          Contacts,
          !disabledTabs.includes('CONTACTS'),
        )}
        {this.renderLegacyRoute(
          `${pathPrefix}/registration`,
          EventRegistration,
          !disabledTabs.includes('REGISTRATION'),
        )}
        <AuthorizableRoute
          authorized={!this.props.disabledTabs.includes('VENDORS')}
          path={`${this.props.pathPrefix}/vendors`}
          render={props => <Vendors {...props} eventSlug={this.props.eventSlug} />}
        />
        {this.renderLegacyRoute(`${pathPrefix}/roi`, Roi, !disabledTabs.includes('ROI'))}
        <AuthorizableRoute
          authorized={!this.props.disabledTabs.includes('ATTACHMENTS')}
          path={`${this.props.pathPrefix}/attachments`}
          render={() => <Attachments eventSlug={this.props.eventSlug} />}
        />
        <AuthorizableRoute
          path={`${this.props.pathPrefix}/budget`}
          authorized={!this.props.disabledTabs.includes('BUDGET')}
          render={props => <Budget {...props} fiscalYearStart={this.props.fiscalYearStart} />}
        />
        <Route
          path={`${this.props.pathPrefix}/schedules/:schedule_slug?`}
          exact
          component={Schedules}
        />
        <Route path={`${this.props.pathPrefix}/tasks/:taskSlug?`} component={Tasks} />
        <Route path={`${this.props.pathPrefix}/notes/:eventNoteId?`} component={Notes} />
        <Route path={`${this.props.pathPrefix}/staff`} component={Staff} />
        <Route
          path={`${this.props.pathPrefix}/errors`}
          render={props => <ErrorTester history={props.history} location={props.location} />}
        />
        <Redirect
          to={`${this.props.rootUrl}${
            this.props.rootUrl.endsWith('/') ? eventTabs[0].path.slice(1) : eventTabs[0].path
          }`}
        />
      </Switch>
    );
  }
}
