/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RequestSubmissionRequestedDate_requestSubmission$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestsItemRequestedDate_eventRequestSubmission$ref: FragmentReference;
export type EventRequestsItemRequestedDate_eventRequestSubmission = {|
  +$fragmentRefs: RequestSubmissionRequestedDate_requestSubmission$ref,
  +$refType: EventRequestsItemRequestedDate_eventRequestSubmission$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestsItemRequestedDate_eventRequestSubmission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "RequestSubmissionRequestedDate_requestSubmission",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6a699899e3fb5398f3a746191eba1823';
module.exports = node;
