/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import LockIcon from 'components/LockIcon';

import TextareaModal from './TextareaModal';

const HoverBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: -1px;
  opacity: 0;
  border: 1px solid #39a8da;
`;

const ExpandIcon = styled.i`
  display: none;
  position: absolute;
  right: 7px;
  ${props =>
    props.restrictChangingValue &&
    css`
      right: 17px;
    `}
  top: 15px;
  z-index: 1;
  cursor: pointer;
  color: #cdd1d4;
  &:hover {
    color: #96a2ab;
  }
`;

const Root = styled.div`
  width: 100%;
  ${props =>
    props.clearable &&
    css`
      padding-right: 17px;
    `};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'initial')};
  &:hover {
    ${HoverBackground} {
      opacity: 1;
    }
    ${ExpandIcon} {
      display: block;
    }
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  outline: none;
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
  z-index: 1;
`;

export default class InlineTextareaField extends React.PureComponent<
  {|
    value: string,
    onChange: string => any,
    placeholder?: string,
    readOnly?: boolean,
    restrictChangingValue?: boolean,
    disabledIcon?: React.Node,
    children?: React.Node,
    getError?: ?(value: ?string) => ?string,
    updateColumnWidth?: () => void,
  |},
  {
    value: string,
    expanded: boolean,
    error: ?string,
  },
> {
  state = {
    value: this.props.value,
    expanded: false,
    error: null,
  };

  handleSave = () => {
    if (this.props.value == null || this.state.value.trim() !== this.props.value.trim()) {
      this.props.onChange(this.state.value.trim());
    }
  };

  handleCancel = () => {
    this.setState(state =>
      state.value !== this.props.value ? { value: this.props.value, error: null } : null,
    );
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ value: e.currentTarget.value, error: null });
  };

  handleValidate = (): boolean => {
    const { getError, updateColumnWidth } = this.props;
    const error: ?string = getError != null ? getError(this.state.value) : null;
    this.setState(state => (state.error === error ? null : { error }));
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  handleExpand = () => {
    this.setState({ expanded: true });
  };

  handleHideWindow = () => {
    this.setState({ expanded: false, value: this.props.value });
  };

  handleSaveAndHideWindow = () => {
    if (this.handleValidate()) {
      this.handleSave();
      this.setState({ expanded: false });
    }
  };

  render() {
    const {
      readOnly,
      restrictChangingValue,
      disabledIcon,
      children,
      placeholder,
      value,
    } = this.props;

    const disabled = readOnly || restrictChangingValue;

    return (
      <Root onClick={this.handleExpand}>
        <HoverBackground />
        <Content>
          {children != null ? children : value}
          {this.state.expanded && (
            <TextareaModal
              placeholder={placeholder}
              value={this.state.value}
              disabled={disabled}
              onChange={this.handleChange}
              onHide={this.handleHideWindow}
              onSave={this.handleSaveAndHideWindow}
              error={this.state.error}
            />
          )}
        </Content>
        {!readOnly &&
          restrictChangingValue &&
          (disabledIcon != null ? disabledIcon : <StyledLockIcon label="Salesforce" />)}
        <ExpandIcon restrictChangingValue={restrictChangingValue} className="fa fa-expand" />
      </Root>
    );
  }
}
