/* @flow */

import {
  EventInfoBrief,
  EventStaffBrief,
  ScheduleBrief,
  TasksBrief,
} from './Sections';

const sectionsMap = {
  event_info: {
    name: 'event info',
    component: EventInfoBrief,
  },
  event_staff: {
    name: 'event staff',
    component: EventStaffBrief,
  },
  schedules: {
    name: 'schedule',
    component: ScheduleBrief,
  },
  tasks: {
    name: 'tasks',
    component: TasksBrief,
  },
};

export default sectionsMap;
