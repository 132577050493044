/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import type { TaskStatuses } from 'config/taskStatuses';

import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import removeFolder from 'graph/mutations/folder/removeFolder';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Loader from 'components/Loader';
import Checkbox from 'components/material/CheckBox';
import { Content } from 'components/page/Content';
import DraggableFolder from 'components/Tasks/DraggableFolder';
import DraggableTask from 'components/Tasks/DraggableTask';
import DroppableFolder from 'components/Tasks/DroppableFolder';
import TaskRow, { type TaskRowType } from 'components/Tasks/TaskRow';
import TasksFolder from 'components/Tasks/TasksFolder';
import TasksListHeader from 'components/Tasks/TasksListHeader';

import type { TasksSectionViewContent_event } from './__generated__/TasksSectionViewContent_event.graphql';
import type { TasksSectionViewContent_org } from './__generated__/TasksSectionViewContent_org.graphql';

const StyledCheckbox = styled(Checkbox)`
  margin-left: 15px;
  flex: 1 1 auto;
  ${props => props.hidden && 'visibility: hidden;'};
`;

const StyledContent = styled(Content)`
  &:hover {
    ${StyledCheckbox} {
      visibility: visible !important;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  @media (${props => props.theme.mobileOnly}) {
    display: none;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

type FolderType = $PropertyType<
  $ElementType<$PropertyType<$PropertyType<TasksSectionViewContent_event, 'folders'>, 'edges'>, 0>,
  'node',
>;

class TasksSectionViewContent extends React.PureComponent<
  {
    filtered: boolean,
    event: ?TasksSectionViewContent_event,
    org: TasksSectionViewContent_org,
    onSelectedTasksChange: (tasks: Array<TaskRowType>) => void,
    selectedTasks: Array<TaskRowType>,
    onTaskUpdate: (string, Object) => void,
    onTaskStatusUpdate: (task: TaskRowType, status: TaskStatuses) => void,
    onTaskRemove: string => void,
    onTaskAssign: (string, string) => void,
    onTaskUnassign: (string, string) => void,
    onTaskAddTag: (string, string) => void,
    onTaskRemoveTag: (string, string) => void,
    onTasksReorder: (Array<string>, folderId: ?string, number, Array<number>) => void,
    onGetTaskLink: string => string,
    onFolderUpdate: (string, Object) => void,
    onFolderReorder: (string, number, $ReadOnlyArray<FolderType>) => void,
    onInviteClick: (taskId: string) => void,
    onChangeViewMode: (viewMode: string) => void,
    newTasks: Array<string>,
    loading?: boolean,
    history: RouterHistory,
  },
  {
    draggingTaskIds: ?Array<string>,
    draggingTaskOrder: ?number,
    draggingTaskOrdersOverride: ?Array<number>,
    draggingTaskFolderOverride: ?string,
    draggingFolderId: ?string,
    draggingFolderOrderOverride: ?number,
    openFolders: Array<string>,
    deliverables: Array<TaskRowType>,
  },
> {
  state = {
    draggingTaskIds: null,
    draggingTaskOrder: null,
    draggingTaskOrdersOverride: null,
    draggingTaskFolderOverride: null,
    draggingFolderId: null,
    draggingFolderOrderOverride: null,
    openFolders: [],
    deliverables: [],
  };

  static getDerivedStateFromProps(
    nextProps: $PropertyType<TasksSectionViewContent, 'props'>,
    prevState: $PropertyType<TasksSectionViewContent, 'state'>,
  ) {
    if (
      nextProps.event &&
      !isEqual(
        nextProps.event.deliverables.edges.map(edge => edge.node),
        prevState.deliverables,
      )
    ) {
      nextProps.onSelectedTasksChange(
        nextProps.event.deliverables.edges
          .filter(edge =>
            nextProps.selectedTasks.some(selectedTask => selectedTask.id === edge.node.id),
          )
          .map(edge => edge.node),
      );
      return {
        deliverables: nextProps.event
          ? nextProps.event.deliverables.edges.map(edge => edge.node)
          : [],
      };
    }

    return null;
  }

  handleTaskSelect = (task: TaskRowType, shiftKey: boolean) => {
    const { selectedTasks, onSelectedTasksChange } = this.props;
    if (selectedTasks.some(selectedTask => selectedTask.id === task.id)) {
      onSelectedTasksChange(selectedTasks.filter(selectedTask => selectedTask.id !== task.id));
      return;
    }
    if (shiftKey) {
      const lastSelected = selectedTasks.slice(-1)[0];
      if (lastSelected) {
        const tasks = this.tasksRange(lastSelected.id, task.id);

        onSelectedTasksChange(uniq([...selectedTasks, ...tasks]));
        return;
      }
    }
    onSelectedTasksChange([...selectedTasks, task]);
  };

  handleMoveStart = (draggingTaskId, draggingTaskOrder) => {
    const movingTasks = this.movingTasks(draggingTaskId);
    const sortedTasks = movingTasks.length > 1 ? this.flatSortedTasks() : [];
    const draggingTaskIds = sortBy(movingTasks, taskId =>
      sortedTasks.findIndex(task => task.id === taskId),
    );

    this.setState({
      draggingTaskIds,
      draggingTaskOrder,
    });

    return draggingTaskIds.length > 1;
  };

  handleMoveTask = (hoverTaskOrder, hoverTaskFolder) => {
    // To prevent ESLint from complaining about unused state field
    const { draggingTaskOrder } = this.state;
    this.setState(state => {
      const { draggingTaskIds } = state;
      if (draggingTaskIds) {
        const adjustment = draggingTaskOrder < hoverTaskOrder ? 1 : -1;
        const orders = draggingTaskIds.map(
          (taskId, index) => hoverTaskOrder + adjustment * (index + 1),
        );

        return {
          draggingTaskOrder: hoverTaskOrder + adjustment,
          draggingTaskOrdersOverride: adjustment > 0 ? orders : orders.reverse(),
          draggingTaskFolderOverride: hoverTaskFolder,
        };
      }
      return null;
    });
  };

  handleMoveEnd = () => {
    if (this.state.draggingTaskOrdersOverride) {
      const folderTasks = this.folderTasks(
        this.state.draggingTaskFolderOverride,
        this.sortedTasks(),
      );
      const index = this.state.draggingTaskIds
        ? folderTasks.findIndex(
            task => this.state.draggingTaskIds && task.id === this.state.draggingTaskIds[0],
          )
        : -1;
      if (index > -1 && this.state.draggingTaskIds && this.state.draggingTaskOrdersOverride) {
        this.props.onTasksReorder(
          this.state.draggingTaskIds,
          this.state.draggingTaskFolderOverride,
          index,
          this.state.draggingTaskOrdersOverride,
        );
      }
    }
    this.setState({
      draggingTaskIds: null,
      draggingTaskOrder: null,
      draggingTaskOrdersOverride: null,
      draggingTaskFolderOverride: null,
    });
  };

  handleMoveIntoFolder = (hoverFolderId: ?string) => {
    if (this.state.draggingTaskFolderOverride !== hoverFolderId) {
      this.setState(state => {
        const draggingTaskIds = (state.draggingTaskIds || []).map((id, i) => i);
        return {
          draggingTaskFolderOverride: hoverFolderId,
          draggingTaskOrdersOverride: state.draggingTaskOrdersOverride || draggingTaskIds,
        };
      });
    }
  };

  handleFolderMoveStart = (folderId, folderOrder) => {
    this.setState({
      draggingFolderId: folderId,
      draggingFolderOrderOverride: folderOrder,
      openFolders: [],
    });
  };

  handleFolderMove = (hoverFolderOrder: number) => {
    this.setState(state => {
      if (state.draggingFolderOrderOverride == null) {
        return {};
      }
      const adjustment = state.draggingFolderOrderOverride < hoverFolderOrder ? 1 : -1;

      return {
        draggingFolderOrderOverride: hoverFolderOrder + adjustment,
      };
    });
  };

  handleFolderMoveEnd = () => {
    const sortedFolders = this.sortedFolders();
    const newIndex = sortedFolders.findIndex(f => f.id === this.state.draggingFolderId);

    if (this.state.draggingFolderId) {
      this.props.onFolderReorder(this.state.draggingFolderId, newIndex, sortedFolders);
    }

    this.setState({
      draggingFolderId: null,
      draggingFolderOrderOverride: null,
    });
  };

  handleToggleFolderOpen = folderId => {
    if (this.state.openFolders.includes(folderId)) {
      this.setState(state => ({ openFolders: state.openFolders.filter(f => f !== folderId) }));
    } else {
      this.setState(state => ({ openFolders: [...state.openFolders, folderId] }));
    }
  };

  handleCheckboxChange = (checked: boolean) => {
    const { event, onSelectedTasksChange } = this.props;

    if (event && checked) {
      onSelectedTasksChange(event.deliverables.edges.map(edge => edge.node));
    } else {
      onSelectedTasksChange([]);
    }
  };

  handleSortChange = (sort: { key: string, asc: boolean }) => {
    if (sort.key === 'DUE_DATE') {
      replaceSortQueryParam(this.props.history, { key: 'DUE_DATE', asc: true });
      this.props.onChangeViewMode('due-date');
    }
  };

  handleFolderRemove = folderId => {
    if (!this.props.event) {
      return;
    }
    const tasksId = this.props.event.deliverables.edges
      .filter(({ node: task }) => task.folderId === folderId)
      .map(({ node: task }) => task.id);
    this.props.onSelectedTasksChange(
      this.props.selectedTasks.filter(taskId => !tasksId.includes(taskId)),
    );
    if (!this.props.event) {
      return;
    }
    removeFolder(this.props.event.id, folderId, tasksId);
  };

  movingTasks(taskId) {
    const selectedTask = this.props.selectedTasks.find(t => t.id === taskId);

    return [selectedTask ? selectedTask.id : taskId];
  }

  sortedFolders() {
    const folders = this.props.event
      ? this.props.event.folders.edges.map(edge => edge.node).filter(Boolean)
      : [];
    return sortBy(folders, folder => {
      if (folder.id === this.state.draggingFolderId) {
        return this.state.draggingFolderOrderOverride;
      }
      return folder.order;
    });
  }

  isDraggingTask(taskId) {
    return (
      this.state.draggingTaskIds &&
      this.state.draggingTaskOrdersOverride &&
      this.state.draggingTaskIds.includes(taskId)
    );
  }

  sortedTasks() {
    const tasks = sortBy(
      this.props.event ? this.props.event.deliverables.edges.map(edge => edge.node) : [],
      task => {
        if (this.isDraggingTask(task.id) && this.state.draggingTaskIds) {
          const taskDraggingIndex = this.state.draggingTaskIds.findIndex(
            taskId => taskId === task.id,
          );
          if (taskDraggingIndex > -1 && this.state.draggingTaskOrdersOverride) {
            return this.state.draggingTaskOrdersOverride[taskDraggingIndex];
          }
        }
        return task.order;
      },
    );

    return tasks;
  }

  folderTasks(folderId, tasks) {
    return tasks.filter(task =>
      this.isDraggingTask(task.id)
        ? this.state.draggingTaskFolderOverride === folderId
        : task.folderId === folderId,
    );
  }

  flatSortedTasks() {
    const folders = this.sortedFolders();
    const tasks = this.sortedTasks();
    return [...folders.map(folder => folder.id), null].reduce(
      (arr, folderId) => [...arr, ...this.folderTasks(folderId, tasks)],
      [],
    );
  }

  tasksRange(fromId: string, toId: string) {
    const tasks = this.flatSortedTasks();
    let fromIndex = tasks.findIndex(task => task.id === fromId);
    let toIndex = tasks.findIndex(task => task.id === toId);

    if (fromIndex > toIndex) [fromIndex, toIndex] = [toIndex, fromIndex];

    return tasks.slice(fromIndex, toIndex + 1);
  }

  renderTasks(tasks, folders, folderId) {
    return tasks.map(task => (
      <DraggableTask
        key={task.id}
        taskId={task.id}
        taskOrder={task.order}
        taskName={task.name}
        folderId={folderId}
        onMoveStart={this.handleMoveStart}
        onMove={this.handleMoveTask}
        onMoveEnd={this.handleMoveEnd}
        draggingTaskIds={this.state.draggingTaskIds || []}
        disabled={!task.viewerCanReorder}
      >
        <TaskRow
          task={task}
          org={this.props.org}
          event={this.props.event}
          standalone={!folderId}
          selected={this.props.selectedTasks.some(selectedTask => selectedTask.id === task.id)}
          onSelect={this.handleTaskSelect}
          onUpdate={this.props.onTaskUpdate}
          onUpdateStatus={this.props.onTaskStatusUpdate}
          onRemove={this.props.onTaskRemove}
          onAssign={this.props.onTaskAssign}
          onUnassign={this.props.onTaskUnassign}
          onAddTag={this.props.onTaskAddTag}
          onRemoveTag={this.props.onTaskRemoveTag}
          onGetLink={this.props.onGetTaskLink}
          onInviteClick={this.props.onInviteClick}
          new={this.props.newTasks.includes(task.id)}
        />
      </DraggableTask>
    ));
  }

  render() {
    const { event, newTasks, loading, onFolderUpdate, filtered } = this.props;
    const folders = this.sortedFolders();
    const tasks = this.sortedTasks();
    const rootTasks = this.folderTasks(null, tasks);
    const tasksCount = tasks.length;

    return (
      <StyledContent>
        {(loading || tasks.length > 0) && (
          <Header>
            <StyledCheckbox
              checked={tasksCount > 0 && this.props.selectedTasks.length > 0}
              hidden={this.props.selectedTasks.length === 0}
              onChange={this.handleCheckboxChange}
              indeterminate={this.props.selectedTasks.length < tasks.length}
            />
            <TasksListHeader
              sort={{ key: 'order', asc: true }}
              onSortChange={this.handleSortChange}
            />
          </Header>
        )}

        {loading && (
          <LoaderContainer>
            <Loader size={30} />
          </LoaderContainer>
        )}

        {folders.map(folder => {
          const folderTasks = this.folderTasks(folder.id, tasks);

          if (event && !event.viewerCanCreateDeliverable && folderTasks.length === 0) {
            return null;
          }

          const folderOpen = this.state.openFolders.includes(folder.id);

          return (
            <DroppableFolder
              key={folder.id}
              onMove={this.handleMoveIntoFolder}
              folderId={folder.id}
            >
              <DraggableFolder
                folderId={folder.id}
                folderName={folder.name}
                folderOrder={folder.order}
                onMoveStart={this.handleFolderMoveStart}
                onMove={this.handleFolderMove}
                onMoveEnd={this.handleFolderMoveEnd}
                disabled={folderOpen || !folder.viewerCanReorder}
              >
                <TasksFolder
                  folder={folder}
                  tasks={folderTasks}
                  onRemove={this.handleFolderRemove}
                  onUpdate={onFolderUpdate}
                  new={folderTasks.some(task => newTasks.includes(task.id))}
                  open={folderOpen}
                  onToggleOpen={this.handleToggleFolderOpen}
                >
                  {folderOpen && this.renderTasks(folderTasks, folders, folder.id)}
                </TasksFolder>
              </DraggableFolder>
            </DroppableFolder>
          );
        })}

        {!loading && filtered && tasksCount === 0 && (
          <EmptyView message="No tasks match filters" icon={<NoResult />} />
        )}

        <DroppableFolder onMove={this.handleMoveIntoFolder} folderId={null}>
          {this.renderTasks(rootTasks, folders, null)}
        </DroppableFolder>
      </StyledContent>
    );
  }
}

export default createFragmentContainer(TasksSectionViewContent, {
  event: graphql`
    fragment TasksSectionViewContent_event on Event {
      id
      ...TaskRow_event
      viewerCanCreateDeliverable
      folders {
        edges {
          node {
            id
            name
            order
            viewerCanReorder
            ...TasksFolder_folder
          }
        }
      }
      deliverables(first: 1000, filters: $filters, includeSubtasks: false)
        @connection(key: "TasksSectionViewContent_deliverables", filters: []) {
        edges {
          node {
            id
            order
            name
            folderId
            status
            viewerCanUpdateStatus
            viewerCanUpdateDueDate
            viewerCanAssign
            viewerCanReorder
            viewerCanUpdateTags
            viewerCanDelete
            assignees {
              edges {
                node {
                  id
                  firstName
                  lastName
                  email
                }
              }
            }
            tags {
              edges {
                node {
                  id
                }
              }
            }
            ...TaskRow_task
            ...TasksFolder_tasks
          }
        }
      }
    }
  `,
  org: graphql`
    fragment TasksSectionViewContent_org on Org {
      ...TaskRow_org
    }
  `,
});
