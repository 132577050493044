/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import type { ContactEventsCount_contact } from './__generated__/ContactEventsCount_contact.graphql';

const ContactEventsCount = (props: { contact: ContactEventsCount_contact }) =>
  props.contact.events.totalCount;

export default createFragmentContainer(
  ContactEventsCount,
  graphql`
    fragment ContactEventsCount_contact on Contact {
      events {
        totalCount
      }
    }
  `,
);
