/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

const Background = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border-radius: 34px;
  background: ${props =>
    props.enabled ? props.theme.highlightColor : props.theme.secondaryActionColor};
  cursor: pointer;
  ${props =>
    props.loading &&
    css`
      cursor: wait;
    `};
  ${props =>
    props.disabled &&
    css`
      cursor: default;
    `};
  opacity: ${props => (props.loading || props.disabled ? 0.7 : 1)};
  transition: 0.2s;
`;

const Circle = styled.div`
  position: absolute;
  top: 1px;
  left: ${props => (props.enabled ? '100%' : '1px')};
  bottom: 1px;
  margin-left: ${props => (props.enabled ? -props.size - 1 : 0)}px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background: ${props => props.theme.checkboxTextColor};
  transition: 0.2s;
`;

export default class Switch extends React.PureComponent<{
  enabled: boolean,
  onChange: (enabled: boolean) => void,
  loading?: boolean,
  disabled?: boolean,
  size: 'normal' | 'small' | 'micro',
}> {
  static defaultProps = {
    size: 'normal',
  };

  handleClick = () => {
    if (this.props.loading || this.props.disabled) return;

    this.props.onChange(!this.props.enabled);
  };

  render() {
    const sizes = {
      normal: [34, 18],
      small: [30, 14],
      micro: [21, 10],
    };

    return (
      <Background
        enabled={this.props.enabled}
        onClick={this.handleClick}
        loading={this.props.loading}
        disabled={this.props.disabled}
        width={sizes[this.props.size][0]}
        height={sizes[this.props.size][1]}
      >
        <Circle size={sizes[this.props.size][1] - 2} enabled={this.props.enabled} />
      </Background>
    );
  }
}
