/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import eventStates from 'config/eventStates';

import updateEvent from 'graph/mutations/event/updateEvent';

import EventStatusSelectorOverlay from 'components/EventStatusSelectorOverlay';

import type { EventHeaderStatus_event } from './__generated__/EventHeaderStatus_event.graphql';

const StatusContainer = styled.div`
  position: relative;
  color: #3e4859;
  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

const StatusText = styled.span`
  position: relative;
  margin-left: 5px;
  font-weight: 500;

  ${props => props.editable && 'cursor: pointer;'};
`;

class EventHeaderStatus extends React.Component<
  {
    event: EventHeaderStatus_event,
  },
  {
    editing: boolean,
  },
> {
  state = {
    editing: false,
  };

  container: ?HTMLElement;

  handleStartEditing = () => {
    if (this.props.event.viewerCanUpdate) {
      this.setState({ editing: true });
    }
  };

  handleEndEditing = () => {
    this.setState({ editing: false });
  };

  handleChangeStatus = status => {
    updateEvent(
      this.props.event.id,
      {
        status,
      },
      'header',
    );
  };

  render() {
    const statusOption = eventStates.find(
      state => state.graphValue === this.props.event.commitmentLevel,
    );

    return (
      <StatusContainer
        ref={ref => {
          this.container = ref;
        }}
        onClick={this.handleStartEditing}
      >
        Status:
        <StatusText editable={this.props.event.viewerCanUpdate}>
          {statusOption ? statusOption.label : ''}
        </StatusText>
        <EventStatusSelectorOverlay
          show={this.state.editing}
          target={this.container}
          container={this.container}
          onHide={this.handleEndEditing}
          status={this.props.event.commitmentLevel}
          onChange={this.handleChangeStatus}
        />
      </StatusContainer>
    );
  }
}

export default createFragmentContainer(
  EventHeaderStatus,
  graphql`
    fragment EventHeaderStatus_event on Event {
      id
      commitmentLevel
      viewerCanUpdate
    }
  `,
);
