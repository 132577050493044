/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation updateFolderMutation($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      folder {
        id
        name
      }
    }
  }
`;

type Fields = { name: string };

export default function updateFolder(folderId: string, { name }: Fields) {
  commitModernMutation({
    mutation,
    variables: {
      input: {
        folderId,
        name,
      },
    },
    optimisticResponse: {
      updateFolder: {
        folder: { id: folderId, name },
      },
    },
  });
}
