/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { VendorUpdatedAt_vendor } from './__generated__/VendorUpdatedAt_vendor.graphql';

type Props = {
  vendor: VendorUpdatedAt_vendor,
  tz: string,
};

const VendorUpdatedAt = (props: Props) =>
  props.vendor.updatedAt && <DateTimeTooltip date={moment.tz(props.vendor.updatedAt, props.tz)} />;

export default createFragmentContainer(
  VendorUpdatedAt,
  graphql`
    fragment VendorUpdatedAt_vendor on Vendor {
      updatedAt
    }
  `,
);
