/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import { type CategoryFooterCellPropsType } from './index';

import { type CategoryBudgetedAmountFooterCell_event } from './__generated__/CategoryBudgetedAmountFooterCell_event.graphql';
import { type CategoryBudgetedAmountFooterCell_org } from './__generated__/CategoryBudgetedAmountFooterCell_org.graphql';

const CategoryBudgetedAmountFooterCell = ({
  event,
  org,
}: CategoryFooterCellPropsType<
  CategoryBudgetedAmountFooterCell_event,
  CategoryBudgetedAmountFooterCell_org,
>) => formatCost(event.totalBudgetedAmount, org.settings.currency);

export default createFragmentContainer(
  CategoryBudgetedAmountFooterCell,
  graphql`
    fragment CategoryBudgetedAmountFooterCell_event on Event {
      totalBudgetedAmount
    }

    fragment CategoryBudgetedAmountFooterCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
