/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextInput from 'components/material/TextInput';
import QuestionHeader from '../Question/QuestionHeader';
import ErrorMessage from '../form/ErrorMessage';
import inputBoxStyled from '../form/inputBoxStyled';

const StyledTextInput = styled(TextInput)`
  ${props => inputBoxStyled(props)};
`;

const Container = styled.div`
  margin: 0 29px 30px;
  padding: 25px 92px 50px;
  @media (${props => props.theme.mobileOnly}) {
    margin: 0 -10px 24px -10px;
    padding: 20px 25px 29px 25px;
  }
  border-radius: 10px;
  background: #f8f8f8;
`;

const Title = styled.div`
  margin-bottom: 40px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: #3e4859;
`;

const NameRow = styled.div`
  display: flex;
  @media (${props => props.theme.mobileOnly}) {
    flex-direction: column;
    > label:first-of-type {
      margin-bottom: 20px;
    }
  }
  margin-bottom: 20px;
  & > :first-child {
    margin-right: 40px;
  }
`;

export default class RequesterInfo extends React.PureComponent<
  {
    firstName: string,
    lastName: string,
    email: string,
    ssoUser?: boolean,
    readOnly: boolean,
    errors: { [string]: ?string },
    onChange: ({| firstName?: string, lastName?: string, email?: string |}) => void,
  },
  {
    showEmailError: boolean,
  },
> {
  state = { showEmailError: true };

  handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const fieldName = e.currentTarget.name;
    const fieldValue = e.currentTarget.value;

    this.props.onChange({ [fieldName]: fieldValue });
  };

  handleChangeEmail = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ showEmailError: false });
    this.handleInputChange(e);
  };

  handleBlurEmail = () => {
    this.setState({ showEmailError: true });
  };

  render() {
    const { firstName, lastName, email, errors } = this.props;

    return (
      <Container>
        <Title>Your Contact Info</Title>
        <NameRow>
          <QuestionHeader label="First Name" fontSize={16} required>
            <StyledTextInput
              name="firstName"
              value={firstName}
              onChange={this.handleInputChange}
              error={errors.firstName}
              disabled={this.props.readOnly}
              required
            />
            {errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
          </QuestionHeader>
          <QuestionHeader label="Last Name" fontSize={16} required>
            <StyledTextInput
              name="lastName"
              value={lastName}
              onChange={this.handleInputChange}
              error={errors.lastName}
              disabled={this.props.readOnly}
              required
            />
            {errors.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>}
          </QuestionHeader>
        </NameRow>
        <QuestionHeader label="Email" fontSize={16} required>
          <StyledTextInput
            name="email"
            value={email}
            onChange={this.handleChangeEmail}
            onBlur={this.handleBlurEmail}
            error={this.state.showEmailError && errors.email}
            required
            disabled={!!this.props.ssoUser || this.props.readOnly}
          />
          {errors.email && this.state.showEmailError && <ErrorMessage>{errors.email}</ErrorMessage>}
        </QuestionHeader>
      </Container>
    );
  }
}
