/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineUserSelectField from 'components/budget/Table/InlineUserSelectField';
import UsersGroup from 'components/material/UsersGroup';
import { type User } from 'components/UserSelect';

import type { CustomizableUserMultiselect_customizable } from './__generated__/CustomizableUserMultiselect_customizable.graphql';

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin: -6px -18px -6px -10px;
`;

class CustomizableUserMultiselect extends React.Component<{
  customizable: ?CustomizableUserMultiselect_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: ?CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
}> {
  getActiveUsers = (): $ReadOnlyArray<User> => {
    const { customizable, fieldSettings } = this.props;
    if (!customizable) return [];
    return customizable.customUserMultiselectFields
      .filter(userField => userField.customField.id === fieldSettings.id)
      .map(field => field.user);
  };

  handleSave = (values: ?$ReadOnlyArray<string>) => {
    const { onUpdateCustomField, updateColumnWidth, customizable, fieldSettings } = this.props;
    if (onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        userValues: values,
      });
      updateColumnWidth();
    }
  };

  handleSelect = (user: ?User) => {
    if (user != null) {
      this.handleSave([...this.getActiveUsers().map(activeUser => activeUser.id), user.id]);
    }
  };

  handleUnselect = (user: User) => {
    const activeUsers = this.getActiveUsers();
    this.handleSave([
      ...activeUsers
        .filter(activeUser => activeUser.id !== user.id)
        .map(activeUser => activeUser.id),
    ]);
  };

  // user is not null only if getError is called for unselect Case
  getError = (user?: User): ?string => {
    const tresholdActiveUserCount = user ? 1 : 0;
    if (
      this.props.fieldSettings.required &&
      this.getActiveUsers().length <= tresholdActiveUserCount
    ) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { fieldSettings, readOnly, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    const activeUsers = this.getActiveUsers();
    if (readOnly) {
      return (
        activeUsers.length > 0 && (
          <UserContainer>
            <UsersGroup users={activeUsers} />
          </UserContainer>
        )
      );
    }
    return (
      <InlineUserSelectField
        placeholder={fieldSettings.label}
        activeUsers={activeUsers}
        onSelect={this.handleSelect}
        onUnselect={this.handleUnselect}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        isMultiselect
        // eslint-disable-next-line no-underscore-dangle
        eventId={customizable.__typename === 'Event' ? customizable.id : undefined}
      >
        {activeUsers.length > 0 && (
          <UserContainer>
            <UsersGroup users={activeUsers} maxCount={4} avatarRadius={13} />
          </UserContainer>
        )}
      </InlineUserSelectField>
    );
  }
}

export default createFragmentContainer(
  CustomizableUserMultiselect,
  graphql`
    fragment CustomizableUserMultiselect_customizable on CustomizableInterface {
      id
      __typename
      customUserMultiselectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...UsersGroup_users
        }
      }
    }
  `,
);
