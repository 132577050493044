/* @flow */
import type {
  PayloadError,
  RecordSourceSelectorProxy,
  RelayMutationConfig,
  SelectorData,
  SelectorStoreUpdater,
  UploadableMap,
  Variables,
} from 'react-relay';
import { type GraphQLTaggedNode, commitMutation } from 'react-relay';

import getMutationRoot from 'graph/updaters/lib/getMutationRoot';
import getRelayEnvironment from 'graph/utils/getRelayEnvironment';
import getRelayPublicEnvironment from 'graph/utils/getRelayPublicEnvironment';

type CompatMutationConfig = {|
  public?: boolean,
  configs?: Array<RelayMutationConfig>,
  mutation: GraphQLTaggedNode,
  variables: Variables,
  uploadables?: UploadableMap,
  optimisticResponse?: ?{},
  optimisticUpdater?: ?SelectorStoreUpdater,
  updater?: ?SelectorStoreUpdater,
  // Handled by Promise:
  // onCompleted?: ?(response: T, errors: ?Array<PayloadError>) => void,
  // onError?: ?(error: Error) => void,
|};

/**
 * Wrapper for commitMutation calls for the main app.
 */
export default function commitModernMutation<MutationResponse>(
  config: CompatMutationConfig,
): Promise<MutationResponse> {
  // Necessary to prevent updater working on error
  const wrapUpdater = (updater: ?SelectorStoreUpdater) => (
    store: RecordSourceSelectorProxy,
    data: SelectorData,
  ) => {
    if (updater && getMutationRoot(store, data)) {
      updater(store, data);
    }
  };

  return new Promise((resolve, reject) =>
    commitMutation(config.public ? getRelayPublicEnvironment() : getRelayEnvironment(), {
      ...config,
      optimisticUpdater:
        config.optimisticResponse && !config.optimisticUpdater
          ? wrapUpdater(config.updater)
          : wrapUpdater(config.optimisticUpdater),
      updater: wrapUpdater(config.updater),
      onCompleted: (response, errors: ?Array<PayloadError>) => {
        if (errors && errors.length > 0) {
          const errorMessage = errors.map(e => e.message).join('\n');
          reject(Error(errorMessage));
        } else {
          resolve(response);
        }
      },
      onError: reject,
    }),
  );
}
