/* @flow */
import formatCost from 'utils/number/formatCost';

import { type ReportingFooterCellPropsType } from './index';

const ReportingTotalPaidAmountCell = ({ totals, orgCurrency }: ReportingFooterCellPropsType) => {
  return formatCost(totals.paidAmount, orgCurrency);
};

export default ReportingTotalPaidAmountCell;
