/* @flow */
import styled, { css } from 'styled-components';
import Checkbox from 'components/material/CheckBox';

const StyledCheckbox = styled(Checkbox)`
  flex: 0 1 auto;
  ${props => props.hidden && 'visibility: hidden;'};
  & > div {
    margin: 12px;
    border-radius: 2px;
    ${props =>
      props.checked
        ? css`
            color: #96a2ab;
          `
        : css`
            color: #cdd1d4;
          `};
  }
`;

export default StyledCheckbox;
