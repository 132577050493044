/**
 * @flow
 * @relayHash 5c41e52e45603283cebc70cb1ceb7d53
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateOrgInput = {
  clientMutationId?: ?string,
  name?: ?string,
};
export type updateOrgMutationVariables = {|
  input: UpdateOrgInput
|};
export type updateOrgMutationResponse = {|
  +updateOrg: ?{|
    +org: {|
      +id: string,
      +name: string,
    |}
  |}
|};
export type updateOrgMutation = {|
  variables: updateOrgMutationVariables,
  response: updateOrgMutationResponse,
|};
*/


/*
mutation updateOrgMutation(
  $input: UpdateOrgInput!
) {
  updateOrg(input: $input) {
    org {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateOrgInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateOrg",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateOrgInput!"
      }
    ],
    "concreteType": "UpdateOrgPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateOrgMutation",
  "id": null,
  "text": "mutation updateOrgMutation(\n  $input: UpdateOrgInput!\n) {\n  updateOrg(input: $input) {\n    org {\n      id\n      name\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateOrgMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateOrgMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '64841e3496071cd3241237dbf97aaa92';
module.exports = node;
