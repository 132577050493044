/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ConnectCampaignPanelRow_campaign$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ConnectCampaignPanelSearch_event$ref: FragmentReference;
export type ConnectCampaignPanelSearch_event = {|
  +id: string,
  +salesforceCampaign: ?{|
    +id: string,
    +name: string,
  |},
  +team: {|
    +org: {|
      +salesforceAccount: ?{|
        +salesforceCampaigns: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +$fragmentRefs: ConnectCampaignPanelRow_campaign$ref,
            |}
          |}>
        |}
      |}
    |}
  |},
  +$refType: ConnectCampaignPanelSearch_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ConnectCampaignPanelSearch_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "query",
      "type": "String!",
      "defaultValue": ""
    }
  ],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceCampaign",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceCampaign",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "org",
          "storageKey": null,
          "args": null,
          "concreteType": "Org",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "salesforceAccount",
              "storageKey": null,
              "args": null,
              "concreteType": "SalesforceAccount",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "salesforceCampaigns",
                  "storageKey": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 10,
                      "type": "Int"
                    },
                    {
                      "kind": "Variable",
                      "name": "query",
                      "variableName": "query",
                      "type": "String"
                    }
                  ],
                  "concreteType": "SalesforceCampaignRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "SalesforceCampaignRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "SalesforceCampaign",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "FragmentSpread",
                              "name": "ConnectCampaignPanelRow_campaign",
                              "args": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a73aafcca62da18cc0d9a6e0032c52f9';
module.exports = node;
