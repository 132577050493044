/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { PopupPlacement } from 'components/material/ColorPicker';
import ColorSelector from 'components/material/ColorPicker/ColorSelector';

import type { RegistrationColorSelector_orgSettings } from './__generated__/RegistrationColorSelector_orgSettings.graphql';

const RegistrationColorSelector = (props: {
  orgSettings: RegistrationColorSelector_orgSettings,
  currentColor: ?string,
  onChangeColor: (color: string) => void,
  alignTo?: PopupPlacement,
  includeTransparent?: boolean,
}) => {
  const {
    orgSettings,
    currentColor,
    onChangeColor,
    includeTransparent,
    alignTo = 'top-right',
  } = props;
  return (
    <ColorSelector
      orgSettings={orgSettings}
      currentColor={currentColor}
      onChangeColor={onChangeColor}
      flagClass="component-styling"
      alignTo={alignTo}
      includeTransparent={includeTransparent}
    />
  );
};

export default createFragmentContainer(RegistrationColorSelector, {
  orgSettings: graphql`
    fragment RegistrationColorSelector_orgSettings on OrgSettings {
      id
      ...ColorSelector_orgSettings
    }
  `,
});
