import React from 'react';
import { DragLayer as dragLayer } from 'react-dnd';

import DraggingSubtaskItem from 'components/Subtasks/DraggingSubtaskItem';
import TaskDragPreview from 'components/Tasks/TaskDragPreview';

type ItemType = {
  name: string,
  previewHTML: string,
  item: { status: number },
  parent: ItemType,
  width: number,
  height: number,
};

@dragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  clientOffset: monitor.getClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
}))
export default class CustomDragLayer extends React.Component<{
  currentOffset: { x: number, y: number },
  clientOffset: { x: number, y: number },
  item: ItemType,
  itemType: string,
  isDragging: boolean,
}> {
  lastStyles = null;

  allowRendering = true;

  getItemStyles() {
    if (!this.allowRendering) return this.lastStyles;
    const { currentOffset, clientOffset, itemType, item } = this.props;
    if (!currentOffset || !clientOffset) return { display: 'none' };

    let { x, y } = itemType === 'TASK' || itemType === 'FOLDER' ? clientOffset : currentOffset;

    if (item.parent) {
      const parentClientRect = item.parent.getBoundingClientRect();
      x = Math.max(x, parentClientRect.left);
      x = Math.min(x + item.width, parentClientRect.left + parentClientRect.width) - item.width;
      y = Math.max(y, parentClientRect.top);
      y = Math.min(y + item.height, parentClientRect.top + parentClientRect.height) - item.height;
    }

    const transform = `translate(${x}px, ${y}px)`;
    const styles = {
      transform,
      WebkitTransform: transform,
    };
    this.lastStyles = styles;
    this.allowRendering = false;
    this.requestFrame();
    return styles;
  }

  requestFrame() {
    requestAnimationFrame(() => {
      this.allowRendering = true;
    });
  }

  renderItem = (type, item) => {
    if (type === 'TASK' || type === 'FOLDER') {
      return <TaskDragPreview multiple={item.multiple} name={item.name} />;
    }
    if (type === 'SUBTASK') {
      return <DraggingSubtaskItem html={item.previewHTML} width={item.width} />;
    }
    if (type === 'SCHEDULE') {
      return (
        <div
          className="schedule-header-item-preview"
          style={{ width: `${item.width}px` }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: item.previewHTML }}
        />
      );
    }
    if (type === 'SIDEBAR') {
      return (
        <div className="team-drag-preview">
          <i className="fa fa-fw fa-caret-right" />
          {item.name}
        </div>
      );
    }

    return null;
  };

  render() {
    const { item, itemType, isDragging } = this.props;
    if (!isDragging) {
      return null;
    }

    return (
      <div className="drag-layer">
        <div style={this.getItemStyles()}>{this.renderItem(itemType, item)}</div>
      </div>
    );
  }
}
