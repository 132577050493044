/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type FilterType } from 'components/FieldSettings/types';

import type {
  createCustomFieldMutationResponse,
  createCustomFieldMutationVariables,
} from './__generated__/createCustomFieldMutation.graphql';

const mutation = graphql`
  mutation createCustomFieldMutation($input: CreateCustomFieldInput!) {
    createCustomField(input: $input) {
      customFieldEdge {
        __typename
        node {
          id
          kind
          label
          required
          enabled
          order
          section {
            id
          }
          showInCreateForm
          showInMobileForm
          restrictManagingOptions: mappedToSalesforce
          options {
            edges {
              node {
                id
                name
                editable
              }
            }
          }
        }
      }
    }
  }
`;

export default function createCustomField(
  orgId: string,
  inputVariables: {
    ...$PropertyType<createCustomFieldMutationVariables, 'input'>,
  },
  filters: FilterType,
): Promise<
  $PropertyType<
    $PropertyType<
      $NonMaybeType<$PropertyType<createCustomFieldMutationResponse, 'createCustomField'>>,
      'customFieldEdge',
    >,
    'node',
  >,
> {
  return commitModernMutation({
    mutation,
    variables: { input: inputVariables },
    updater: addRecordToConnection({
      parentId: orgId,
      edgeName: 'customFieldEdge',
      connections: [
        {
          field: 'customFields',
          args: filters,
        },
      ],
    }),
  }).then((response: createCustomFieldMutationResponse) => {
    if (response.createCustomField && response.createCustomField.customFieldEdge.node) {
      return response.createCustomField.customFieldEdge.node;
    }
    throw new Error('could not create a custom field');
  });
}
