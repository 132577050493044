/* @flow */

import styled, { css } from 'styled-components';

const BriefBlockLabel = styled.div`
  padding-bottom: 15px;
  font-size: 17px;
  font-weight: 500;
  color: ${props => props.color};
  text-transform: uppercase;
  ${props =>
    props.isShared &&
    css`
      @media (max-width: 905px) {
        font-size: 15px;
      }
    `};
`;

export default BriefBlockLabel;
