/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { SalesforceCampaignInfo_campaign } from './__generated__/SalesforceCampaignInfo_campaign.graphql';

const Container = styled.div`
  flex: 1 1 340px;
`;

const InfoRow = styled.div`
  display: flex;
  padding: 4px 0;
  color: rgba(74, 86, 101, 0.87);
`;

const InfoLabel = styled.div`
  flex: 0 0 140px;
`;

const CampaignLink = styled.a`
  color: ${props => props.theme.primaryActionColor};
  font-weight: 500;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

class SalesforceCampaignInfo extends React.PureComponent<{
  campaign: SalesforceCampaignInfo_campaign,
}> {
  render() {
    return (
      <Container>
        <InfoRow>
          <InfoLabel>Campaign Name</InfoLabel>
          <strong>{this.props.campaign.name}</strong>
        </InfoRow>

        <InfoRow>
          <InfoLabel>Campaign ID</InfoLabel>
          <CampaignLink
            href={this.props.campaign.campaignUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.props.campaign.campaignSfdcid}
          </CampaignLink>
        </InfoRow>
      </Container>
    );
  }
}

export default createFragmentContainer(
  SalesforceCampaignInfo,
  graphql`
    fragment SalesforceCampaignInfo_campaign on SalesforceCampaign {
      name
      campaignSfdcid
      campaignUrl
    }
  `,
);
