/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import partition from 'lodash/partition';

import addUserToTeam from 'graph/mutations/access/addUserToTeam';
import showModernMutationError from 'graph/utils/showModernMutationError';

import SelectionRow from 'components/InviteWindow/SelectionRow';
import Button from 'components/material/Button';
import SelectField from 'components/material/SelectField';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';

import type { AddUserToTeamsWindow_me } from './__generated__/AddUserToTeamsWindow_me.graphql';

const Footer = styled.div`
  margin-top: 30px;
  text-align: right;
  button {
    margin-left: 30px;
  }
`;

const SelectionList = styled.div`
  margin-top: 25px;
`;

class AddUserToTeamsWindow extends React.PureComponent<
  {
    me: AddUserToTeamsWindow_me,
    userId: string,
    onHide: () => void,
  },
  {
    selectedTeamIds: $ReadOnlyArray<string>,
  },
> {
  state = { selectedTeamIds: [] };

  handleSelectTeam = (teamId: ?string) => {
    this.setState(prevState => ({
      selectedTeamIds:
        teamId != null ? [...prevState.selectedTeamIds, teamId] : prevState.selectedTeamIds,
    }));
  };

  handleUnselectTeam = (id: string) => {
    this.setState(prevState => ({
      selectedTeamIds: prevState.selectedTeamIds.filter(teamId => id !== teamId),
    }));
  };

  handleSave = () => {
    addUserToTeam(this.props.userId, this.state.selectedTeamIds).catch(showModernMutationError);

    this.props.onHide();
  };

  render() {
    const teams = this.props.me.memberships.edges.map(edge => ({
      label: edge.node.name,
      value: edge.node.id,
    }));
    const [selectedTeams, teamOptions] = partition(teams, option =>
      this.state.selectedTeamIds.includes(option.value),
    );
    return (
      <Window onHide={this.props.onHide} size="medium">
        <WindowHeader>
          <WindowTitle>Add Member to Teams</WindowTitle>
          <WindowClose onClick={this.props.onHide} />
        </WindowHeader>
        <WindowContent>
          <SelectField
            label="Select Teams"
            options={teamOptions}
            onChange={this.handleSelectTeam}
            searchable
          />
          {selectedTeams.length > 0 && (
            <SelectionList>
              {selectedTeams.map(team => (
                <SelectionRow
                  key={team.value}
                  value={team.value}
                  onRemove={this.handleUnselectTeam}
                >
                  <div>{team.label}</div>
                </SelectionRow>
              ))}
            </SelectionList>
          )}
          <Footer>
            <Button label="Cancel" minimal onClick={this.props.onHide} />
            <Button
              label="Save"
              primary
              onClick={this.handleSave}
              disabled={selectedTeams.length === 0}
            />
          </Footer>
        </WindowContent>
      </Window>
    );
  }
}

export default createFragmentContainer(
  AddUserToTeamsWindow,
  graphql`
    fragment AddUserToTeamsWindow_me on User {
      memberships {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
);
