/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrgCompaniesTablePagination_totalCountOrg$ref: FragmentReference;
export type OrgCompaniesTablePagination_totalCountOrg = {|
  +companiesCount: {|
    +totalCount: number
  |},
  +$refType: OrgCompaniesTablePagination_totalCountOrg$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "OrgCompaniesTablePagination_totalCountOrg",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filters",
      "type": "CompanyFilters"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "companiesCount",
      "name": "companies",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters",
          "type": "CompanyFilters"
        }
      ],
      "concreteType": "CompanyRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '38d9a74b3504be8ee38c7e9fdb19fc99';
module.exports = node;
