/* @flow */
import styled from 'styled-components';

const WindowContent = styled.div`
  padding: 0 35px 25px 35px;
  background: ${props => props.theme.windowBackgroundColor};
  border-radius: 0 0 4px 4px;
`;

export default WindowContent;
