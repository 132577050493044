/* @flow */

import type { Location } from 'react-router-dom';

import { type ColumnType } from 'components/budget/Table';

import { type ParsedMembersFilters } from '../lib/parseMembersFilters';
import MemberEmail from './MemberEmail';
import MemberEventCount from './MemberEventCount';
import MemberFullName from './MemberFullName';
import MemberInviteStatus from './MemberInviteStatus';
import MemberLastActivity from './MemberLastActivity';
import MembersActionsColumn from './MembersActionsColumn';
import MemberTeamCount from './MemberTeamCount';

export type MembersCellPropsType<CellType: {}> = {|
  user: CellType,
  tz: string,
  location: Location,
  filters: ParsedMembersFilters,
|};

const colsConfig: $ReadOnlyArray<ColumnType<any, any>> = [
  {
    title: 'Name',
    sortKey: 'FULL_NAME',
    component: MemberFullName,
    grow: 1,
  },
  {
    title: 'Team Count',
    sortKey: 'TEAM_COUNT',
    component: MemberTeamCount,
    width: 130,
  },
  {
    title: 'Event Count',
    sortKey: 'EVENT_COUNT',
    component: MemberEventCount,
    width: 130,
  },
  {
    title: 'Email',
    sortKey: 'EMAIL',
    component: MemberEmail,
    grow: 0.8,
  },
  {
    title: 'Account Status',
    sortKey: 'ACCOUNT_STATUS',
    component: MemberInviteStatus,
    width: 180,
  },
  {
    title: 'Last Activity',
    sortKey: 'LAST_ACTIVITY',
    component: MemberLastActivity,
    width: 140,
  },
  {
    title: '',
    type: 'action',
    component: MembersActionsColumn,
  },
];

export default colsConfig;
