/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import type { RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';
import TablePagination from 'components/budget/Table/TablePagination';
import NoResultsMessage from 'components/NoResultsMessage';

import colsConfig, { type DeliverablesCellPropsType, type TasksFiltersInputType } from '../columns';

import { type AllTasksPagination_org } from './__generated__/AllTasksPagination_org.graphql';
import { type AllTasksPagination_totalCountDeliverables } from './__generated__/AllTasksPagination_totalCountDeliverables.graphql';

type DeliverableType = $PropertyType<
  $ElementType<$PropertyType<$PropertyType<AllTasksPagination_org, 'deliverables'>, 'edges'>, 0>,
  'node',
>;

const AllTasksContainer = styled.div`
  width: 100%;
`;

type Props = {
  org: AllTasksPagination_org,
  location: Location,
  filters: TasksFiltersInputType,
  filtered: boolean,
  totalCountDeliverables: ?AllTasksPagination_totalCountDeliverables,
  relay: RelayPaginationProp,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  setColumnWidthUpdater: (updateColumnWidths: () => void) => void,
};

type State = {
  refetching: boolean,
};

class AllTasksPagination extends React.Component<Props, State> {
  state = {
    refetching: false,
  };

  cellProps = (deliverable: DeliverableType): DeliverablesCellPropsType<DeliverableType> => {
    const { location, filters, org } = this.props;
    return {
      deliverable,
      org,
      me: null,
      location,
      filters,
    };
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  keyExtractor = (deliverable: DeliverableType) => {
    return deliverable.id;
  };

  render() {
    const {
      org,
      totalCountDeliverables,
      sort,
      onChangeSort,
      filtered,
      setColumnWidthUpdater,
    } = this.props;

    const optimisticDeliverablesEdges = org ? org.deliverables.edges.filter(Boolean) : [];
    return (
      <AllTasksContainer>
        {(!org || optimisticDeliverablesEdges.length !== 0) && (
          <Table
            data={org != null ? org.deliverables.edges.map(({ node }) => node) : null}
            columns={colsConfig}
            sort={sort}
            onChangeSort={onChangeSort}
            cellProps={this.cellProps}
            keyExtractor={this.keyExtractor}
            setColumnWidthUpdater={setColumnWidthUpdater}
          />
        )}

        {org &&
          optimisticDeliverablesEdges.length === 0 &&
          (filtered ? (
            <EmptyView message="No Tasks match filters" icon={<NoResult />} />
          ) : (
            <NoResultsMessage iconName="tasks" message="Tasks not found" />
          ))}
        {org && optimisticDeliverablesEdges.length !== 0 && (
          <TablePagination
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            currentCount={optimisticDeliverablesEdges ? optimisticDeliverablesEdges.length : null}
            totalCount={
              totalCountDeliverables ? totalCountDeliverables.deliverablesCount.totalCount : null
            }
            entityName="Tasks"
          />
        )}
      </AllTasksContainer>
    );
  }
}
export default createPaginationContainer(
  AllTasksPagination,
  {
    org: graphql`
      fragment AllTasksPagination_org on Org {
        id
        ...DeliverableTags_org
        ...DeliverableTitle_org
        ...DeliverableActionCell_org
        deliverables(first: $count, after: $cursor, filters: $filters)
          @connection(key: "AllTasksPagination_deliverables", filters: []) {
          edges {
            node {
              id
              ...DeliverableTags_deliverable
              ...DeliverableTitle_deliverable
              ...DeliverableStatus_deliverable
              ...DeliverableEventName_deliverable
              ...DeliverableDueDate_deliverable
              ...DeliverableAssignees_deliverable
              ...DeliverableActionCell_deliverable
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    `,
    totalCountDeliverables: graphql`
      fragment AllTasksPagination_totalCountDeliverables on Org {
        deliverablesCount: deliverables(filters: $filters) {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.org && props.org.deliverables;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        count,
        cursor,
      };
    },
    query: graphql`
      query AllTasksPaginationQuery($count: Int!, $cursor: String, $filters: DeliverableFilters!) {
        org {
          ...AllTasksPagination_org
        }
      }
    `,
  },
);
