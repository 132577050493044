/* @flow */
import * as React from 'react';
import { DragDropContext as dragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { Match } from 'react-router';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';

import EventRequestFormBuilderBody from './EventRequestFormBuilderBody';
import EventRequestFormBuilderSteps from './EventRequestFormBuilderSteps';

import type { EventRequestFormBuilderQueryResponse } from './__generated__/EventRequestFormBuilderQuery.graphql';

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: #fafafa;
`;

const Title = styled.div`
  flex-shrink: 0;
  margin-top: 50px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  color: #3e4859;
`;

const query = graphql`
  query EventRequestFormBuilderQuery($requestFormId: ID!) {
    org {
      viewerCanViewEventRequestSubmissions
      ...EventRequestFormBuilderBody_org
    }
    requestForm: node(id: $requestFormId) {
      ...EventRequestFormBuilderBody_requestForm
    }
  }
`;

class EventRequestFormBuilder extends React.PureComponent<
  {
    match: Match,
  },
  {
    activeStepIndex: number,
    showStepsShadow: boolean,
  },
> {
  state = {
    activeStepIndex: 0,
    showStepsShadow: false,
  };

  scrollElement: ?HTMLDivElement;

  handleChangeStep = (stepIndex: number) => {
    this.setState({ activeStepIndex: stepIndex });

    if (!this.scrollElement) return;

    this.scrollElement.scrollTop = 0;
  };

  handleNextStep = () => {
    this.handleChangeStep(this.state.activeStepIndex + 1);
  };

  handlePrevStep = () => {
    this.handleChangeStep(this.state.activeStepIndex - 1);
  };

  handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    const stepsHeight = 87;

    const showStepsShadow = e.currentTarget.scrollTop > stepsHeight;

    if (showStepsShadow !== this.state.showStepsShadow) {
      this.setState({ showStepsShadow });
    }
  };

  render() {
    const { activeStepIndex } = this.state;

    const requestFormId: string = this.props.match.params.requestFormId;

    return (
      <DocumentTitle title="Event Request Form Builder">
        <Background
          onScroll={this.handleScroll}
          ref={el => {
            this.scrollElement = el;
          }}
        >
          <DefaultQueryRenderer
            query={query}
            variables={{
              requestFormId,
              hasTeamAccess: false,
            }}
            renderSuccess={(response: EventRequestFormBuilderQueryResponse) => {
              if (!response.org.viewerCanViewEventRequestSubmissions) {
                return <NotAuthorized />;
              }
              return (
                <>
                  <Title>Event Request Form Builder</Title>
                  <EventRequestFormBuilderSteps
                    activeStepIndex={activeStepIndex}
                    onChangeStep={this.handleChangeStep}
                    showShadow={this.state.showStepsShadow}
                  />
                  <EventRequestFormBuilderBody
                    org={response.org}
                    requestForm={response.requestForm || null}
                    activeStepIndex={activeStepIndex}
                    onNextStep={this.handleNextStep}
                    onPrevStep={this.handlePrevStep}
                    scrollElement={this.scrollElement}
                  />
                </>
              );
            }}
          />
        </Background>
      </DocumentTitle>
    );
  }
}

export default dragDropContext(HTML5Backend)(EventRequestFormBuilder);
