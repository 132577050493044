/* @flow */
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';

import convertDateToLocal from 'utils/date/convertDateTimeToLocal';

import { type AllEventsCalendarEvent } from '../AllEventsCalendarView';

const eventCalendarSlots = (
  events: $ReadOnlyArray<AllEventsCalendarEvent>,
  calendarStart: moment,
  daysCount: number,
) => {
  const sortedEvents = sortBy(events, [
    event => event.startDate,
    event => -moment.tz(event.endDate || event.startDate, event.tz).diff(event.startDate, 'day'),
  ]);

  return range(daysCount).reduce((slots, index) => {
    const day = moment(calendarStart).add(index, 'day');
    const dayEvents = sortedEvents.filter(
      event =>
        moment(
          convertDateToLocal(moment.tz(event.endDate || event.startDate, event.tz)),
        ).isSameOrAfter(day, 'day') &&
        moment(convertDateToLocal(moment.tz(event.startDate, event.tz))).isSameOrBefore(day, 'day'),
    );

    const prevDaySlots = slots[index - 1] || [];
    const daySlots = ([...range(Math.max(dayEvents.length, prevDaySlots.length))].fill(null): any);

    dayEvents.forEach(event => {
      const prevIndex = prevDaySlots.findIndex(i => i && i.id === event.id);
      const freeIndex = daySlots.findIndex(i => !i);
      const newIndex = prevIndex === -1 ? freeIndex : prevIndex;

      daySlots[newIndex] = event;
    });

    return [
      ...slots,
      daySlots.filter(
        (event, i, array) =>
          event || (day.day() !== 0 && array.slice(i + 1).filter(Boolean).length !== 0),
      ),
    ];
  }, []);
};

export default eventCalendarSlots;
