/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import EventRequestsItemStatus from 'views/Main/Dashboard/EventRequests/columns/EventRequestsItemStatus';

import type { EventRequestsStatus_event } from './__generated__/EventRequestsStatus_event.graphql';

const EventRequestsStatus = (props: { event: EventRequestsStatus_event, readOnly?: boolean }) => {
  const { event, readOnly } = props;
  const submission = event.eventRequestSubmission;
  if (submission == null) {
    return 'N/A';
  }

  return (
    <EventRequestsItemStatus
      eventRequestSubmission={submission}
      readOnly={readOnly || !submission.viewerCanUpdate}
    />
  );
};

export default createFragmentContainer(
  EventRequestsStatus,
  graphql`
    fragment EventRequestsStatus_event on Event {
      eventRequestSubmission {
        viewerCanUpdate @skip(if: $readOnly)
        ...EventRequestsItemStatus_eventRequestSubmission
      }
    }
  `,
);
