/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AllEventsHeaderBar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsMap_user$ref: FragmentReference;
export type AllEventsMap_user = {|
  +$fragmentRefs: AllEventsHeaderBar_user$ref,
  +$refType: AllEventsMap_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "AllEventsMap_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AllEventsHeaderBar_user",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'cfee96525054f7d5d819f136ce26216b';
module.exports = node;
