/* @flow */
import track from '../track';

type Props = {
  eventId: number,
  eventName: string,
  teamId: number,
  teamName: string,
  format: 'pdf' | 'csv',
};

const downloadedChecklist = (props: Props) => {
  track('downloaded_checklist', {
    event_id: props.eventId,
    event_name: props.eventName,
    team_id: props.teamId,
    team_name: props.teamName,
    format: props.format,
  });
};

export default downloadedChecklist;
