/* @flow */
import styled from 'styled-components';

const HeaderBar = styled.div`
  display: flex;
  align-items: center;
  min-width: 555px;
  padding: 0 15px 0 30px;
  color: #3e4859;
  > div:not(:last-child) {
    margin-right: 15px;
  }
`;

const FieldNameColumn = styled.div`
  flex: 1 0 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RequiredColumn = styled.div`
  flex: 0.1 0 60px;
  display: flex;
  justify-content: center;
  margin-right: ${props => props.restrictManagingOptions && `-18px !important`};
`;

// const CreateFormColumn = styled.div`
//   flex: 0.1 0 100px;
//   display: flex;
//   justify-content: center;
// `;

// const MobileFormColumn = styled.div`
//   flex: 0.1 0 100px;
//   display: flex;
//   justify-content: center;
// `;

const ActionsColumn = styled.div`
  flex: 0 0 115px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export {
  // CreateFormColumn,
  // MobileFormColumn,
  ActionsColumn,
  FieldNameColumn,
  HeaderBar,
  RequiredColumn,
};
