/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import AttachmentPreview from 'components/RelayAttachments/AttachmentPreview';
import DescriptionRichText from '../../components/DescriptionRichText';

import { type EventInfoFloorPlan_event } from './__generated__/EventInfoFloorPlan_event.graphql';

class EventInfoFloorPlan extends React.PureComponent<
  {
    event: EventInfoFloorPlan_event,
    name: string,
  },
  { previewId: ?string },
> {
  state = { previewId: null };

  handlePreview = (previewId: ?string) => {
    this.setState({ previewId });
  };

  render() {
    const {
      name,
      event: {
        eventFloorPlan: { floorPlan, attachments },
      },
    } = this.props;
    const images = attachments.edges.map(({ node }) => ({
      ...node,
      id: node.fileurl,
    }));
    return (
      <React.Fragment>
        <DescriptionRichText
          name={name}
          description={floorPlan}
          contentDataAvailable={floorPlan}
          supportInlineImage={{
            onImagePreview: this.handlePreview,
          }}
        />
        {this.state.previewId && (
          <AttachmentPreview
            attachments={[]}
            mockAttachments={images}
            previewId={this.state.previewId}
            onPreview={this.handlePreview}
            onRemove={null}
          />
        )}
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  EventInfoFloorPlan,
  graphql`
    fragment EventInfoFloorPlan_event on Event {
      eventFloorPlan {
        floorPlan
        attachments {
          edges {
            node {
              id
              fileurl
              filename
              filetype
              filesize
            }
          }
        }
      }
    }
  `,
);
