/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import { BrowserRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { renderDateRange } from 'utils/Date';

import UsersGroup from 'components/material/UsersGroup';

import type { AllEventsMapMarkerInfo_event } from './__generated__/AllEventsMapMarkerInfoQuery.graphql';

const InfoBoxContainer = styled.div`
  width: 273px;
  border-radius: 2px;
  border: solid 1px #e6e6e6;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  line-height: 0.93;
`;

const EventTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 500;
  &:hover {
    overflow: auto;
    > a {
      text-decoration: underline;
      white-space: normal;
    }
  }
`;

const NameLink = styled(Link)`
  white-space: nowrap;
  text-decoration: none;
  line-height: 1.4;
  color: #3e4859;
`;

const InfoBoxHeader = styled.div`
  padding: 14px;
`;

const InfoBoxContent = styled.div`
  padding: 0 14px;
  font-size: 14px;
  color: #4a5665;
`;

const InfoBoxAvatars = styled(InfoBoxContent)`
  display: flex;
  padding: 14px;
  border-top: 1px solid #eaebec;
`;

const SmallIcon = styled.i`
  width: 15px;
  font-size: 13px;
  padding-right: 23px;
  color: #96a2ab;
`;

const SmallMapIcon = styled(SmallIcon)`
  font-size: 20px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  padding-bottom: 10px;
  line-height: 1.4;
`;

const UserAvatars = styled.div`
  font-size: 12px;
  color: #828b93;

  > * {
    margin-top: 6px;
  }

  &:not(:last-child) {
    margin-right: 25px;
  }
`;

class AllEventsMapMarkerInfoBox extends React.PureComponent<{
  event: AllEventsMapMarkerInfo_event,
  linkEvent: boolean,
  match?: Match,
  location?: Location,
}> {
  render() {
    const { event, match, location, linkEvent } = this.props;
    const primaryLocation = event.primaryLocation;
    const lead = event.lead;
    const staffers = event.staffers.edges.map(edge => edge.node.user);

    const backUrl =
      linkEvent && location && match
        ? `from=${
            match && match.params && match.params.list_id ? match.params.list_id : ''
          }&fromUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`
        : '';

    return (
      <InfoBoxContainer>
        <InfoBoxHeader>
          <EventTitle>
            {linkEvent ? (
              <BrowserRouter>
                <NameLink to={`/events/${event.slug}/info?${backUrl}`}>{event.name}</NameLink>
              </BrowserRouter>
            ) : (
              event.name
            )}
          </EventTitle>
        </InfoBoxHeader>
        <InfoBoxContent>
          {event.startDate && (
            <DetailsWrapper>
              <SmallIcon className="fa fa-w fa-calendar" />
              {renderDateRange(
                {
                  startDate: event.startDate,
                  endDate: event.endDate,
                  startDateAllDay: event.startDateAllDay,
                  endDateAllDay: event.endDateAllDay,
                  tz: event.tz,
                },
                { noTime: true },
              )}
            </DetailsWrapper>
          )}
          <DetailsWrapper>
            <SmallMapIcon className="fa fa-w fa-map-marker" />
            <div>
              {primaryLocation.address1}
              {primaryLocation.address1 && <br />}
              {primaryLocation.address2}
              {primaryLocation.address2 && <br />}
              {[primaryLocation.city, primaryLocation.state, primaryLocation.postal].join(' ')}{' '}
              <br />
              {primaryLocation.country}
            </div>
          </DetailsWrapper>
        </InfoBoxContent>
        {(lead || staffers.length > 0) && (
          <InfoBoxAvatars>
            {lead && (
              <UserAvatars>
                Leader
                <UsersGroup users={[lead]} />
              </UserAvatars>
            )}
            {staffers.length > 0 && (
              <UserAvatars>
                Staff
                <UsersGroup users={staffers} maxCount={6} />
              </UserAvatars>
            )}
          </InfoBoxAvatars>
        )}
      </InfoBoxContainer>
    );
  }
}

export default createFragmentContainer(
  AllEventsMapMarkerInfoBox,
  graphql`
    fragment AllEventsMapMarkerInfoBox_event on Event {
      id
      slug
      name
      startDate
      endDate
      startDateAllDay
      endDateAllDay
      tz
      primaryLocation {
        name
        city
        state
        address1
        address2
        country
        postal
        note
        website
        lat
        lng
      }
      lead {
        ...UsersGroup_users
      }
      staffers {
        edges {
          node {
            user {
              ...UsersGroup_users
            }
          }
        }
      }
    }
  `,
);
