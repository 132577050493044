/**
 * @flow
 * @relayHash 889514f0a243f7259630ad4a1aef980e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
export type ContactSyncOption = "contact" | "lead";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type UpdateContactInput = {
  clientMutationId?: ?string,
  contactId: string,
  firstName?: ?string,
  lastName?: ?string,
  ownerId?: ?string,
  avatar?: ?string,
  company?: ?CompanyInput,
  title?: ?string,
  email?: ?string,
  phone1?: ?string,
  phone2?: ?string,
  website?: ?string,
  twitter?: ?string,
  linkedin?: ?string,
  description?: ?string,
  country?: ?string,
  state?: ?string,
  city?: ?string,
  zip?: ?string,
  street?: ?string,
  fromWindow?: ?string,
  eventId?: ?string,
  hotLead?: ?boolean,
  emailOptIn?: ?boolean,
  contactTypeId?: ?string,
};
export type CompanyInput = {
  id: string,
  name: string,
  salesforceId?: ?string,
};
export type updateContactMutationVariables = {|
  input: UpdateContactInput
|};
export type updateContactMutationResponse = {|
  +updateContact: ?{|
    +contact: ?{|
      +id: string,
      +slug: string,
      +firstName: string,
      +lastName: string,
      +salesforceSyncAs: ?ContactSyncOption,
      +avatar: ?string,
      +website: ?string,
      +email: string,
      +phone1: ?string,
      +phone2: ?string,
      +title: ?string,
      +salesforceId: ?string,
      +hotLead: boolean,
      +emailOptIn: boolean,
      +contactType: ?{|
        +id: string
      |},
      +company: ?{|
        +id: string,
        +name: string,
        +salesforceId: ?string,
      |},
      +owner: ?{|
        +id: string,
        +firstName: string,
        +lastName: string,
        +email: string,
        +$fragmentRefs: MaterialAvatar_user$ref,
      |},
      +twitter: ?string,
      +linkedin: ?string,
      +description: ?string,
      +country: ?string,
      +state: ?string,
      +city: ?string,
      +zip: ?string,
      +street: ?string,
      +syncStatus: {|
        +lastSuccessAt: ?any,
        +state: SyncState,
        +errorMessage: string,
      |},
    |}
  |}
|};
export type updateContactMutation = {|
  variables: updateContactMutationVariables,
  response: updateContactMutationResponse,
|};
*/


/*
mutation updateContactMutation(
  $input: UpdateContactInput!
) {
  updateContact(input: $input) {
    contact {
      id
      slug
      firstName
      lastName
      salesforceSyncAs
      avatar
      website
      email
      phone1
      phone2
      title
      salesforceId
      hotLead
      emailOptIn
      contactType {
        id
      }
      company {
        id
        name
        salesforceId
      }
      owner {
        id
        firstName
        lastName
        email
        ...MaterialAvatar_user
      }
      twitter
      linkedin
      description
      country
      state
      city
      zip
      street
      syncStatus {
        lastSuccessAt
        state
        errorMessage
      }
    }
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateContactInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateContactInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "salesforceSyncAs",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "website",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone1",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone2",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "salesforceId",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hotLead",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailOptIn",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contactType",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldOptionType",
  "plural": false,
  "selections": [
    v2
  ]
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "company",
  "storageKey": null,
  "args": null,
  "concreteType": "Company",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    v13
  ]
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "twitter",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "linkedin",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "country",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "state",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "zip",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "street",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "syncStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "SyncStatus",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastSuccessAt",
      "args": null,
      "storageKey": null
    },
    v22,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "errorMessage",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateContactMutation",
  "id": null,
  "text": "mutation updateContactMutation(\n  $input: UpdateContactInput!\n) {\n  updateContact(input: $input) {\n    contact {\n      id\n      slug\n      firstName\n      lastName\n      salesforceSyncAs\n      avatar\n      website\n      email\n      phone1\n      phone2\n      title\n      salesforceId\n      hotLead\n      emailOptIn\n      contactType {\n        id\n      }\n      company {\n        id\n        name\n        salesforceId\n      }\n      owner {\n        id\n        firstName\n        lastName\n        email\n        ...MaterialAvatar_user\n      }\n      twitter\n      linkedin\n      description\n      country\n      state\n      city\n      zip\n      street\n      syncStatus {\n        lastSuccessAt\n        state\n        errorMessage\n      }\n    }\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateContactMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateContact",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateContactPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contact",
            "storageKey": null,
            "args": null,
            "concreteType": "Contact",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              v13,
              v14,
              v15,
              v16,
              v17,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "owner",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v2,
                  v4,
                  v5,
                  v9,
                  {
                    "kind": "FragmentSpread",
                    "name": "MaterialAvatar_user",
                    "args": null
                  }
                ]
              },
              v18,
              v19,
              v20,
              v21,
              v22,
              v23,
              v24,
              v25,
              v26
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateContactMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateContact",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateContactPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contact",
            "storageKey": null,
            "args": null,
            "concreteType": "Contact",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              v13,
              v14,
              v15,
              v16,
              v17,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "owner",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v2,
                  v4,
                  v5,
                  v9,
                  v7
                ]
              },
              v18,
              v19,
              v20,
              v21,
              v22,
              v23,
              v24,
              v25,
              v26
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ddb138e171ff1732968c367bb5c05e23';
module.exports = node;
