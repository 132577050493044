/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import type { TaskStatuses } from 'config/taskStatuses';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';
import { type TaskRow_task } from 'components/Tasks/__generated__/TaskRow_task.graphql';

import { type ParsedTaskFilters } from '../components/parseTaskFilters';
import TasksSectionViewContent from './TasksSectionViewContent';

import type { TasksSectionView_org } from './__generated__/TasksSectionView_org.graphql';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const query = graphql`
  query TasksSectionViewQuery($filters: DeliverableFilters, $eventSlug: String!) {
    event(slug: $eventSlug) {
      ...TasksSectionViewContent_event
    }
  }
`;

class TasksSectionView extends React.PureComponent<{
  filters: ParsedTaskFilters,
  filtered: boolean,
  eventSlug: string,
  org: TasksSectionView_org,
  onSelectedTasksChange: (Array<string>) => void,
  selectedTasks: Array<string>,
  onTaskUpdate: (string, Object) => void,
  onTaskStatusUpdate: (task: TaskRow_task, status: TaskStatuses) => void,
  onTaskRemove: string => void,
  onTaskAssign: (string, string) => void,
  onTaskUnassign: (string, string) => void,
  onTaskAddTag: (string, string) => void,
  onTaskRemoveTag: (string, string) => void,
  onTasksReorder: (Array<string>, folderId: ?string, number, Array<number>) => void,
  onGetTaskLink: string => string,
  onFolderRemove: string => void,
  onFolderUpdate: (string, Object) => void,
  onFolderReorder: (string, number, $ReadOnlyArray<{ id: string, order: number }>) => void,
  onInviteClick: (taskId: string) => void,
  onChangeViewMode: (viewMode: string) => void,
  newTasks: Array<string>,
  history: RouterHistory,
}> {
  firstOpen = true;

  render() {
    const { sort, ...sectionFilters } = this.props.filters;
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ eventSlug: this.props.eventSlug, filters: sectionFilters }}
        renderSuccess={response => {
          const { event } = response;
          this.firstOpen = false;
          return <TasksSectionViewContent {...this.props} event={event} />;
        }}
        renderLoading={() => {
          return this.firstOpen ? (
            <LoaderContainer>
              <Loader size={30} />
            </LoaderContainer>
          ) : (
            <TasksSectionViewContent {...this.props} event={null} loading />
          );
        }}
      />
    );
  }
}

export default createFragmentContainer(TasksSectionView, {
  org: graphql`
    fragment TasksSectionView_org on Org {
      ...TasksSectionViewContent_org
    }
  `,
});
