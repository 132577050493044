/* @flow */
import * as React from 'react';

import TextField from 'components/material/TextField';

import InlineEditableCell from './InlineEditableCell';

export default class InlineTextField extends React.PureComponent<
  {|
    value: string,
    onChange: string => any,
    placeholder?: string,
    disabled?: boolean,
    disabledIcon?: React.Node,
    children?: React.Node,
    getError?: ?(value: ?string) => ?string,
    updateColumnWidth?: () => void,
  |},
  {
    value: string,
    error: ?string,
  },
> {
  state = {
    value: this.props.value,
    error: null,
  };

  handleSave = () => {
    if (this.props.value == null || this.state.value.trim() !== this.props.value.trim()) {
      this.props.onChange(this.state.value.trim());
    }
  };

  handleCancel = () => {
    this.setState(state =>
      state.value !== this.props.value ? { value: this.props.value, error: null } : null,
    );
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ value: e.currentTarget.value, error: null });
  };

  handleValidate = (): boolean => {
    const { getError, updateColumnWidth } = this.props;
    const error: ?string = getError != null ? getError(this.state.value) : null;
    this.setState(state => (state.error === error ? null : { error }));
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  render() {
    return (
      <InlineEditableCell
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        onValidate={this.handleValidate}
        error={this.state.error}
        disabled={this.props.disabled}
        disabledIcon={this.props.disabledIcon}
      >
        {({ onBlur, onKeyDown, editing }) => {
          if (!editing) {
            return this.props.children != null ? this.props.children : this.props.value;
          }

          return (
            <TextField
              autoFocus
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              onChange={this.handleChange}
              value={this.state.value}
              placeholder={this.props.placeholder}
            />
          );
        }}
      </InlineEditableCell>
    );
  }
}
