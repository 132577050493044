/* @flow */
import React from 'react';
import { type RelayRefetchProp, createRefetchContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import showModernMutationError from 'graph/utils/showModernMutationError';

import SelectField from 'components/material/SelectField';

import MarketoProgramCard from './MarketoProgramCard';

import { type MarketoProgramPicker_org } from './__generated__/MarketoProgramPicker_org.graphql';

export type MarketoProgramType = $Diff<
  $PropertyType<
    $ElementType<
      $PropertyType<
        $NonMaybeType<
          $PropertyType<
            $NonMaybeType<$PropertyType<MarketoProgramPicker_org, 'marketoAccount'>>,
            'marketoPrograms',
          >,
        >,
        'edges',
      >,
      0,
    >,
    'node',
  >,
  { event: any },
>;

const MarketoProgramOption = styled.span`
  a {
    color: #4eb1dd;
  }
  small {
    display: block;
    margin-top: -2px;
    font-size: 11px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const RemoveLink = styled.a`
  margin-left: auto;
  font-size: 12px;
  color: #f38183;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

class MarketoProgramPicker extends React.PureComponent<
  {
    org: MarketoProgramPicker_org,
    relay: RelayRefetchProp,
    marketoProgram: ?MarketoProgramType,
    onSelect: (?MarketoProgramType) => void,
  },
  {
    loading: boolean,
    loaded: boolean,
  },
> {
  state = { loading: false, loaded: false };

  handleShowOptions = () => {
    this.setState({ loading: true });

    this.props.relay.refetch({ loadPrograms: true }, null, error => {
      this.setState({ loading: false, loaded: !error });

      if (!error) return;

      showModernMutationError(error);
    });
  };

  handleChange = (marketoId: ?string) => {
    const marketoPrograms =
      this.props.org.marketoAccount && this.props.org.marketoAccount.marketoPrograms
        ? this.props.org.marketoAccount.marketoPrograms.edges.map(edge => edge.node)
        : [];
    const marketoProgram = marketoPrograms.find(program => program.marketoId === marketoId);

    if (marketoProgram) {
      const { event, ...marketoProgramWithoutEvent } = marketoProgram;

      this.props.onSelect({ ...marketoProgramWithoutEvent });

      this.setState({ loaded: false });
    }
  };

  handleRemove = () => {
    this.props.onSelect(null);

    this.setState({ loaded: false });
  };

  render() {
    const { org, marketoProgram } = this.props;
    const { loading, loaded } = this.state;
    const marketoPrograms =
      org.marketoAccount && org.marketoAccount.marketoPrograms && loaded
        ? org.marketoAccount.marketoPrograms.edges.map(edge => edge.node)
        : [];
    const marketoProgramId = marketoProgram && marketoProgram.marketoId;

    return (
      <div>
        <SelectField
          options={sortBy(
            marketoPrograms.map(program => ({
              label: program.name,
              value: program.marketoId,
              disabled: !!program.event && program.marketoId !== marketoProgramId,
              displayLabel: (
                <MarketoProgramOption>
                  {program.name}
                  {program.event && program.marketoId !== marketoProgramId && (
                    <small>
                      Associated with:{' '}
                      <Link to={`/events/${program.event.slug}`}>{program.event.name}</Link>
                    </small>
                  )}
                </MarketoProgramOption>
              ),
            })),
            option => option.disabled,
          )}
          value={marketoProgramId}
          onChange={this.handleChange}
          searchable
          placeholder="Connect Marketo Program"
          onShowOptions={loading || loaded ? null : this.handleShowOptions}
          loading={loading}
        />

        {marketoProgram && <MarketoProgramCard marketoProgram={marketoProgram} />}

        {marketoProgram && (
          <LinksContainer>
            <RemoveLink onClick={this.handleRemove}>Remove Marketo Program</RemoveLink>
          </LinksContainer>
        )}
      </div>
    );
  }
}

export default createRefetchContainer(
  MarketoProgramPicker,
  graphql`
    fragment MarketoProgramPicker_org on Org
    @argumentDefinitions(loadPrograms: { type: "Boolean!", defaultValue: false }) {
      marketoAccount {
        id
        ... @include(if: $loadPrograms) {
          marketoPrograms {
            edges {
              node {
                marketoId
                name
                url
                event {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  `,
  graphql`
    query MarketoProgramPickerRefetchQuery($loadPrograms: Boolean!) {
      org {
        ...MarketoProgramPicker_org @arguments(loadPrograms: $loadPrograms)
      }
    }
  `,
);
