/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import removeUserFromTeam from 'graph/mutations/access/removeUserFromTeam';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import ShareDropdown from 'components/ShareDropdown';

import type { TeamMembershipActionsColumn_membershipEdge } from './__generated__/TeamMembershipActionsColumn_membershipEdge.graphql';

const Container = styled.div`
  margin: 0 -5px 0 -24px;
`;

class StaffedEventActionsColumn extends React.PureComponent<
  {
    membershipEdge: TeamMembershipActionsColumn_membershipEdge,
    userId: string,
    currentUserId: string,
  },
  {
    showConfirmation: boolean,
  },
> {
  state = {
    showConfirmation: false,
  };

  handleRemove = () => {
    const { userId, currentUserId, membershipEdge } = this.props;
    removeUserFromTeam(membershipEdge.node.id, userId)
      .then(() => {
        if (userId === currentUserId) {
          // Do a hard refresh to update the sidebar
          window.location.reload();
        }
      })
      .catch(showModernMutationError);
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    if (!this.props.membershipEdge.viewerCanRemove) {
      return null;
    }
    const isCurrentUser = this.props.userId === this.props.currentUserId;

    return (
      <Container>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={[
            {
              label: isCurrentUser ? 'Leave from Team' : 'Remove from Team',
              icon: isCurrentUser ? 'sign-out' : 'times',
              onClick: this.handleShowConfirmation,
            },
          ]}
          overlayContainer={document.body}
        />

        {this.state.showConfirmation &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              actionLabel="Remove"
              onHide={this.handleHideConfirmation}
              onConfirm={this.handleRemove}
              message={`Note that ${
                isCurrentUser ? 'you' : 'this user'
              } may still have access to Events in this Team as an Event Staff Member.`}
            />,
            document.body,
          )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  StaffedEventActionsColumn,
  graphql`
    fragment TeamMembershipActionsColumn_membershipEdge on MembershipTeamEdge {
      viewerCanRemove
      node {
        id
      }
    }
  `,
);
