/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type InputVariableFilters } from 'components/Contacts/contactsTableColumnSettings';

const mutation = graphql`
  mutation removeContactAssignmentMutation(
    $input: RemoveContactAssignmentInput!
    $filters: ContactFilters
    $hasFilters: Boolean!
  ) {
    removeContactAssignment(input: $input) {
      eventContactEdges @skip(if: $hasFilters) {
        node {
          id
          events {
            totalCount
          }
        }
      }
      event @include(if: $hasFilters) {
        eventContacts {
          totalCount
        }
        contactRegistrationsTotal
        ...ContactsTablePagination_totalCountEvent
      }
      removedContactIds
      removedEventId
    }
  }
`;

type Args = {
  eventId: string,
  contactIds: $ReadOnlyArray<string>,
  fromWindow: 'contact profile' | 'event contacts',
  filters?: InputVariableFilters,
};

export default function removeContactAssignment({
  eventId,
  contactIds,
  fromWindow,
  filters,
}: Args) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        contactIds,
        eventId,
        fromWindow,
      },
      filters,
      hasFilters: !!filters,
    },
    optimisticResponse: {
      removeContactAssignment: {
        removedContactIds: contactIds,
        removedEventId: eventId,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedContactIds',
        parentId: eventId,
        connections: [{ key: 'ContactsTablePagination_eventContacts' }],
      }),
      removeRecordFromConnection({
        deletedIdField: 'removedEventId',
        parentId: contactIds[0],
        connections: [{ key: 'ContactEventsList_contactEvents' }],
      }),
    ),
  });
}
