/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Remove as RemoveIcon } from 'images';
import LockIcon from 'components/LockIcon';

const HoverBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: -1px;
  opacity: 0;
  border: 1px solid #39a8da;
`;

const Error = styled.div`
  position: relative;
  bottom: -33px;
  z-index: 2;
  margin: -4px ${props => (props.rightAligned ? -36 : -21)}px 0 -21px;
  padding: 3px 10px;
  font-size: 13px;
  color: #ffffff;
  text-align: left;
  background-color: #f25d60;
  ${props =>
    props.doubleField &&
    css`
      width: calc(100% + 14px);
      bottom: -39px;
      margin-left: -11px;
      padding-right: 24px;
    `}
`;

const Root = styled.div`
  width: 100%;
  ${props =>
    props.clearable &&
    css`
      padding-right: 17px;
    `};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'initial')};
  &:hover {
    ${HoverBackground} {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  outline: none;
`;

export const EditContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  margin: -1px;
  border: 1px solid ${props => (props.invalid ? '#f25d60' : '#39a8da')};
  background: #fff;

  label {
    margin: 0;

    input {
      padding: 0 20px;
      height: 100%;
      border-color: transparent !important;
    }
  }
`;

const RemoveAction = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  cursor: pointer;
  color: #868f96;

  &:hover {
    color: #4db1dd;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 12px;
`;

export default class InlineEditableCell<V: any> extends React.PureComponent<
  {
    children: ({
      editing: boolean,
      onBlur?: (value?: V) => void,
      onKeyDown?: (event: SyntheticKeyboardEvent<HTMLElement>) => void,
    }) => React.Node,
    onSave?: () => void,
    onCancel?: () => void,
    onClear?: ?() => void,
    defaultEditing?: ?boolean,
    onValidate?: (value?: V) => boolean,
    error?: ?string,
    rightAligned?: boolean,
    doubleField?: boolean,
    disabledIcon?: React.Node,
    // TODO: Rename disabled and hideLockIcon to match their purpose
    disabled?: boolean,
    hideLockIcon?: boolean,
  },
  { editing: boolean },
> {
  state = {
    editing: this.props.defaultEditing != null ? this.props.defaultEditing : false,
  };

  handleEdit = () => {
    if (!this.props.disabled) {
      this.setState({ editing: true });
    }
  };

  handleBlur = (value?: V) => {
    const { onValidate, onSave } = this.props;
    if (!onValidate || onValidate(value)) {
      this.setState({ editing: false });
      if (onSave) onSave();
    }
  };

  handleClickClear = (event: SyntheticEvent<HTMLElement>) => {
    event.stopPropagation();

    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      if (this.props.onCancel) this.props.onCancel();

      this.setState({ editing: false });
    } else if (event.key === 'Enter') {
      this.handleBlur();
    }
  };

  render() {
    const {
      children,
      onClear,
      error,
      rightAligned,
      doubleField,
      disabled,
      disabledIcon,
      hideLockIcon,
    } = this.props;
    const { editing } = this.state;
    return (
      <Root disabled={disabled} onClick={editing ? null : this.handleEdit} clearable={!!onClear}>
        {!editing && !disabled && (
          <HoverBackground>
            {onClear && (
              <RemoveAction onClick={this.handleClickClear}>
                <RemoveIcon />
              </RemoveAction>
            )}
          </HoverBackground>
        )}

        {editing ? (
          <>
            <EditContent invalid={error}>
              {children({
                editing: true,
                onBlur: this.handleBlur,
                onKeyDown: this.handleKeyDown,
              })}
            </EditContent>
            {error && (
              <Error rightAligned={rightAligned} doubleField={doubleField}>
                {error}
              </Error>
            )}
          </>
        ) : (
          <Content tabIndex={0} onFocus={this.handleEdit}>
            {children({ editing: false })}
          </Content>
        )}
        {!hideLockIcon &&
          disabled &&
          (disabledIcon != null ? disabledIcon : <StyledLockIcon label="Salesforce" />)}
      </Root>
    );
  }
}
