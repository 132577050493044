/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import ImportUpload from 'images/importUpload.svg';
import Button from 'components/material/Button';

const Container = styled.div`
  flex: 1 1 auto;
  padding-top: 50px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    margin: 20px 0 40px 0;
  }
`;

const InfoMessage = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #3e4859;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

export default class Importing extends React.Component<{
  message: string,
  onImport: () => void,
  onCancel: () => void,
}> {
  render() {
    const { message, onImport, onCancel } = this.props;

    return (
      <>
        <Container>
          <Row>
            <ImportUpload draggable={false} />
          </Row>
          <Row>
            <InfoMessage>{message}</InfoMessage>
          </Row>
        </Container>

        <Buttons>
          <Button minimal label="Cancel" onClick={onCancel} />
          <SubmitButton primary label="Import" onClick={onImport} />
        </Buttons>
      </>
    );
  }
}
