/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationFormSubmissionContent_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationPreview_registrationForm$ref: FragmentReference;
export type RegistrationPreview_registrationForm = {|
  +$fragmentRefs: RegistrationFormSubmissionContent_registrationForm$ref,
  +$refType: RegistrationPreview_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationPreview_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "RegistrationFormSubmissionContent_registrationForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '253e28ab7cd21036a34afd0e9ad5efc0';
module.exports = node;
