/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import NumberField from './components/NumberField';

import type { BudgetPaid_event } from './__generated__/BudgetPaid_event.graphql';
import type { BudgetPaid_org } from './__generated__/BudgetPaid_org.graphql';

const BudgetPaid = (props: { event: BudgetPaid_event, org: BudgetPaid_org }) => {
  const { event, org } = props;
  if (!event.viewerCanViewBudget) return <i>Restricted</i>;

  const currency = org.settings.currency;
  const totalPaidAmount = event.totalPaidAmount;

  return totalPaidAmount !== null ? (
    <NumberField value={formatCost(totalPaidAmount, currency)} formatted />
  ) : null;
};

export default createFragmentContainer(BudgetPaid, {
  event: graphql`
    fragment BudgetPaid_event on Event {
      viewerCanViewBudget
      totalPaidAmount
    }
  `,
  org: graphql`
    fragment BudgetPaid_org on Org {
      settings {
        currency
      }
    }
  `,
});
