/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import {
  type CreateCompanyFromWindow,
  type ResponseCompany,
} from 'graph/mutations/company/createCompany';
import {
  type CreateContactFromWindow,
  type ResponseContact,
} from 'graph/mutations/contact/createContact';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import InviteWindow, { type User } from 'components/InviteWindow';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';

import CompanyForm from './CompanyForm';
import ContactForm, { type EventPersonDataType } from './ContactForm';
import ContactTypeSelector, { type ContactType } from './ContactTypeSelector';

import { type ContactWindowQueryResponse } from './__generated__/ContactWindowQuery.graphql';

const query = graphql`
  query ContactWindowQuery(
    $customizableType: [CustomizableType!]!
    $excludeSalesforcePullOnlyFields: Boolean!
  ) {
    org {
      ...ContactForm_org
      ...CompanyForm_org
    }

    me {
      tz
    }
  }
`;

export default class ContactWindow<FilterVariables: {}> extends React.PureComponent<
  {
    defaultActiveType: ContactType,
    onHide: () => void,
    onSave?:
      | (('contacts', $Diff<ResponseContact, { +$fragmentRefs: any }>, EventPersonDataType) => void)
      | (('companies', $Diff<ResponseCompany, { +$fragmentRefs: any }>) => void),
    fromWindow?: CreateContactFromWindow | CreateCompanyFromWindow,
    eventId?: ?string,
    filterVariables?: FilterVariables,
    includeEventData?: boolean,
  },
  {
    activeType: ContactType,
    inviteWindowDefaultUser: ?$Exact<$ReadOnly<{ ...User }>>,
  },
> {
  static defaultProps = {
    defaultActiveType: 'contacts',
  };

  onContactFormEdited: ?() => boolean = null;

  state = {
    activeType: this.props.defaultActiveType,
    inviteWindowDefaultUser: null,
  };

  handleChangeActiveType = (activeType: ContactType) => {
    this.setState({ activeType });
  };

  handleShowInviteWindow = (user: $Exact<$ReadOnly<{ ...User }>>) => {
    this.setState({ inviteWindowDefaultUser: user });
  };

  handleHide = () => {
    if (!this.onContactFormEdited || !this.onContactFormEdited()) {
      this.props.onHide();
    }
  };

  setOnContactFormEdited = (onHide: () => boolean) => {
    this.onContactFormEdited = onHide;
  };

  customizableType = () => {
    if (this.state.activeType === 'companies') {
      return ['COMPANY'];
    }

    if (this.props.includeEventData) {
      return ['CONTACT', 'EVENTCONTACT'];
    }

    return ['CONTACT'];
  };

  renderContent = (response: ContactWindowQueryResponse) => {
    const { onHide, onSave, fromWindow, filterVariables, eventId, defaultActiveType } = this.props;
    const { activeType } = this.state;

    const FormComponent = { contacts: ContactForm, companies: CompanyForm }[activeType];

    return (
      <React.Fragment>
        <ContactTypeSelector activeType={activeType} onChange={this.handleChangeActiveType} />

        <FormComponent
          defaultActiveType={defaultActiveType}
          org={response.org}
          tz={response.me.tz}
          fromWindow={fromWindow}
          filterVariables={filterVariables}
          onSave={onSave}
          onHide={onHide}
          onShowInviteWindow={this.handleShowInviteWindow}
          setOnContactFormEdited={this.setOnContactFormEdited}
          eventId={eventId}
        />
      </React.Fragment>
    );
  };

  render() {
    const { onHide } = this.props;
    const { inviteWindowDefaultUser, activeType } = this.state;

    if (inviteWindowDefaultUser) {
      return (
        <InviteWindow
          onHide={onHide}
          fromWindow="create contact"
          defaultUser={inviteWindowDefaultUser}
        />
      );
    }

    return (
      <Window onHide={this.handleHide} size={500}>
        <WindowHeader>
          <WindowTitle>Create New Contact</WindowTitle>

          <WindowClose onClick={onHide} />
        </WindowHeader>

        <WindowContent>
          <DefaultQueryRenderer
            query={query}
            variables={{
              customizableType: this.customizableType(),
              excludeSalesforcePullOnlyFields: activeType === 'contacts',
            }}
            renderSuccess={this.renderContent}
          />
        </WindowContent>
      </Window>
    );
  }
}
