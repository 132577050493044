/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type CellPropsType } from 'components/budget/Table';
import InlineCostField from 'components/budget/Table/InlineCostField';

import { type PaymentCellPropsType } from './index';

import { type PaymentAmountCell_org } from './__generated__/PaymentAmountCell_org.graphql';
import { type PaymentAmountCell_payment } from './__generated__/PaymentAmountCell_payment.graphql';

const PaymentAmountCell = ({
  payment,
  org,
  updatePayment,
  updateColumnWidth,
}: CellPropsType<PaymentCellPropsType<PaymentAmountCell_payment, {}, PaymentAmountCell_org>>) => (
  <InlineCostField
    value={payment.amount}
    currency={org.settings.currency}
    onChange={amount => {
      updatePayment({ amount: amount == null ? payment.amount : amount });

      updateColumnWidth();
    }}
  />
);

export default createFragmentContainer(
  PaymentAmountCell,
  graphql`
    fragment PaymentAmountCell_payment on Payment {
      amount
    }

    fragment PaymentAmountCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
