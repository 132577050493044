/* @flow */
import type { GooglePlace, GooglePlaceComponent, Location } from './locationTypes';

function nameForType(
  components: Array<GooglePlaceComponent>,
  type: string,
  useShortName?: boolean,
): string {
  const match = components.find(component => component.types.includes(type));
  if (!match) {
    return '';
  }
  return useShortName && match.short_name ? match.short_name : match.long_name;
}

export default function googlePlaceToLocation(place: GooglePlace): Location {
  const comps = place.address_components;
  const country = nameForType(comps, 'country');
  const cityState = ['Hong Kong', 'Singapore', 'The Bahamas', 'Gibraltar', 'Monaco'].includes(
    country,
  )
    ? country
    : '';
  return {
    name: place.name,
    address1: [nameForType(comps, 'street_number'), nameForType(comps, 'route')]
      .filter(x => x)
      .join(' '),
    address2: '',
    city:
      nameForType(comps, 'locality') ||
      nameForType(comps, 'postal_town') ||
      nameForType(comps, 'colloquial_area') ||
      nameForType(comps, 'administrative_area_level_2') ||
      nameForType(comps, 'administrative_area_level_1') ||
      cityState,
    state: nameForType(comps, 'administrative_area_level_1', country === 'United States'),
    postal: nameForType(comps, 'postal_code'),
    country: nameForType(comps, 'country'),
    google_placeid: place.place_id,
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
    website: '',
    note: '',
  };
}
