/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import fullNameOfUser from 'utils/fullNameOfUser';

import type { ImportLogsUserColumn_importLog } from './__generated__/ImportLogsUserColumn_importLog.graphql';

type Props = {
  importLog: ImportLogsUserColumn_importLog,
};

const ImportLogsUserColumn = ({ importLog }: Props) => fullNameOfUser(importLog.user);

export default createFragmentContainer(
  ImportLogsUserColumn,
  graphql`
    fragment ImportLogsUserColumn_importLog on ImportLog {
      user {
        firstName
        lastName
      }
    }
  `,
);
