/* @flow */
import * as React from 'react';
import {
  type ConnectDragPreview,
  type ConnectDragSource,
  type ConnectDropTarget,
  DragSource,
  DropTarget,
} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styled from 'styled-components';

import TaskRow from 'components/Tasks/TaskRow';

let allowRendering = true;
const requestFrame = () => {
  requestAnimationFrame(() => {
    allowRendering = true;
  });
};

const DragContainer = styled.div`
  ${props =>
    props.isDragging &&
    `
    > ${TaskRow} {
      visibility: hidden;
      opacity: 0;
    }
  `};
`;

class DraggableTask extends React.PureComponent<{
  // Dragging
  connectDragSource: ConnectDragSource,
  connectDragPreview: (any, any) => void,

  // Dropping
  connectDropTarget: ConnectDropTarget,

  // External
  taskId: string,
  draggingTaskIds: Array<string>,
  children: React.Node,
  disabled?: boolean,
  // Used in DnD handler only
  // taskOrder: number,
  // folderId: ?string,
  // onMoveStart: (string, number) => boolean,
  // onMove: (hoverOrder: number, hoverFolderId: ?string) => void,
  // onMoveEnd: () => void,
}> {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  render() {
    const { connectDragSource, connectDropTarget } = this.props;
    const isDragging = this.props.draggingTaskIds.includes(this.props.taskId);

    if (this.props.disabled) return this.props.children;

    return connectDropTarget(
      <div>
        {connectDragSource(
          <div>
            <DragContainer isDragging={isDragging}>{this.props.children}</DragContainer>
          </div>,
        )}
      </div>,
    );
  }
}

const dragSpec = {
  beginDrag(props) {
    const multiple = props.onMoveStart(props.taskId, props.taskOrder);
    return {
      taskId: props.taskId,
      taskOrder: props.taskOrder,
      folderId: props.folderId,
      name: props.taskName,
      multiple,
    };
  },
  endDrag(props) {
    props.onMoveEnd();
  },
};

function dragCollect(
  connect,
): {
  connectDragSource: ConnectDragSource,
  connectDragPreview: ConnectDragPreview,
} {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

const Draggable = DragSource('TASK', dragSpec, dragCollect)(DraggableTask);

const dropSpec = {
  hover(props) {
    if (allowRendering) {
      const hoverTaskId = props.taskId;

      const differentItem = !props.draggingTaskIds.includes(hoverTaskId);
      if (differentItem) {
        props.onMove(props.taskOrder, props.folderId);
      }
      allowRendering = false;
      requestFrame();
    }
  },
};

function dropCollect(
  connect,
): {
  connectDropTarget: ConnectDropTarget,
} {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

const Droppable = DropTarget('TASK', dropSpec, dropCollect)(Draggable);

export default Droppable;
