/* @flow */
import * as React from 'react';

import ContactUsLink from './ContactUsLink';

const ExpiredSubscriptionMessage = () => (
  <React.Fragment>
    Your subscription has expired, please <ContactUsLink /> to renew.
  </React.Fragment>
);

export default ExpiredSubscriptionMessage;
