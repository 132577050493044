/* @flow */

import * as React from 'react';
import styled from 'styled-components';

import type { CircaFile } from 'utils/uploading/types';

import { Remove as RemoveIcon } from 'images';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 auto;
  margin: 0 10px 10px 0;
  padding: 2px 10px;
  border: 1px solid #c3e0ec;
  border-radius: 13.5px;
  background-color: #ffffff;
  &:hover {
    border-color: #80b6cd;
  }
`;

const Filename = styled.div`
  max-width: ${props => props.maxWidth}px;
  color: #3e4859;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: zoom-in;
  &:last-child {
    margin-right: 0;
  }
`;

const LoadingIcon = styled.i`
  flex-shrink: 0;
  color: ${props => props.theme.mutedTextColor};
  margin-left: 8px;
`;

const CancelAction = styled.div`
  width: 13px;
  height: 13px;
  margin-left: 10px;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const FileAttachment = (props: {
  attachment: CircaFile,
  onPreview: (attachmentId: string) => void,
  onRemove?: (attachmentId: string) => void,
}) => {
  const {
    attachment: { id, filename, fileurl },
    onPreview,
    onRemove,
  } = props;

  const handlePreview = (e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (id != null) {
      onPreview(id);
    }
  };

  return (
    <Container>
      <Filename onClick={handlePreview} maxWidth={onRemove ? 150 : 173}>
        {filename}
      </Filename>
      {onRemove && (
        <>
          {fileurl ? (
            <CancelAction onClick={() => id != null && onRemove(id)}>
              <RemoveIcon />
            </CancelAction>
          ) : (
            <LoadingIcon className="fa fa-fw fa-circle-o-notch fa-spin" />
          )}
        </>
      )}
    </Container>
  );
};

export default FileAttachment;
