/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ScheduleListRow_scheduleItem$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduleList_scheduleDay$ref: FragmentReference;
export type ScheduleList_scheduleDay = {|
  +date: any,
  +viewerCanUpdate: boolean,
  +scheduleItems: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +start_time: any,
        +end_time: ?any,
        +all_day: boolean,
        +scheduleParticipants: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +user: {|
                +slug: string
              |}
            |}
          |}>
        |},
        +$fragmentRefs: ScheduleListRow_scheduleItem$ref,
      |}
    |}>
  |},
  +$refType: ScheduleList_scheduleDay$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ScheduleList_scheduleDay",
  "type": "ScheduleDay",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "scheduleItems",
      "storageKey": null,
      "args": null,
      "concreteType": "ScheduleItemRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ScheduleItemRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ScheduleItem",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "start_time",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "end_time",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "all_day",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "scheduleParticipants",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ScheduleParticipantRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ScheduleParticipantRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ScheduleParticipant",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "user",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "User",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "slug",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ScheduleListRow_scheduleItem",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '12c1ebd085a49ce9eff999fc813a76c8';
module.exports = node;
