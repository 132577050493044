/* @flow */

import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import { stringifyLocation } from 'utils/Location';
import GoogleMaps from 'components/GoogleMaps';

import BriefMap_event from './__generated__/BriefMap_event.graphql';

const InfoBox = styled.div`
  display: inline-block;
  width: 180px;
  padding: 15px;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  background-color: white;
  text-align: center;
`;

const InfoBoxHeader = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const InfoBoxBody = styled.div`
  text-align: center;
  font-size: 13px;
`;

const InfoBoxLink = styled.a`
  color: #427fed;
  font-size: 13px;
  cursor: pointer;
`;

const StyledGoogleMaps = styled(GoogleMaps)`
  height: 210px;
  margin-top: 24px;
  @media (max-width: 480px) {
    margin-top: 17px;
  }
`;

const BriefMap = (props: { event: BriefMap_event }) => {
  const {
    event: { primaryLocation },
  } = props;
  return (
    <StyledGoogleMaps lat={primaryLocation.lat} lng={primaryLocation.lng} infoBoxOffset={-90}>
      <InfoBox>
        <div>
          <InfoBoxHeader>{primaryLocation.name}</InfoBoxHeader>
          <InfoBoxBody>{stringifyLocation(primaryLocation, 'address1')}</InfoBoxBody>
          <InfoBoxLink
            href={`https://www.google.com/maps/search/?api=1&query=
          ${primaryLocation.lat},${primaryLocation.lng}`}
            target="_blank"
          >
            View on Google Maps
          </InfoBoxLink>
        </div>
      </InfoBox>
    </StyledGoogleMaps>
  );
};

export default createFragmentContainer(BriefMap, {
  event: graphql`
    fragment BriefMap_event on Event {
      id
      primaryLocation @include(if: $includeLocation) {
        id
        city
        name
        country
        state
        lat
        lng
        address1
      }
    }
  `,
});
