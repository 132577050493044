/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import type { TasksFromWindow } from './createTasks';

const mutation = graphql`
  mutation addTasksTagMutation($input: AddDeliverablesTagInput!) {
    addDeliverablesTag(input: $input) {
      deliverables {
        id
        dbId
        name
        event {
          dbId
          name
        }
        tags {
          edges {
            node {
              id
              color
              name
            }
          }
        }
      }
    }
  }
`;

export default function addTasksTag(
  taskIds: Array<string>,
  tagId: string,
  fromWindow: TasksFromWindow,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableIds: taskIds,
        tagId,
        fromWindow,
      },
    },
  });
}
