/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import FormIcon from 'images/registration/form.svg';
import RowIcon from 'images/registration/row.svg';

import pageComponentsConfig, {
  type ContentComponentType,
  type PageComponentKindType,
  type PageComponentType,
  type ReorderedPageComponentConfigType,
} from '../../pageComponentsConfig';
import type { SelectedComponent } from '../../RegistrationCreateContent';
import PageComponent from '../../RegistrationPageProperties/Design/PageComponents/PageComponent';
import DraggablePageComponent from '../DraggablePageComponent';
import DroppableArea from '../DroppableArea';
import generatePaddingProps from '../generatePaddingProps';
import { FormComponentContent, Frame } from '.';

import { type ColumnComponent_componentProps } from './__generated__/ColumnComponent_componentProps.graphql';
import { type ColumnComponent_org } from './__generated__/ColumnComponent_org.graphql';
import { type ColumnComponent_registrationForm } from './__generated__/ColumnComponent_registrationForm.graphql';

const Container = styled.div`
  flex: 1 1 auto;
  width: ${props => props.componentProps.width}%;
  padding: ${props => generatePaddingProps(props.componentProps.padding)};
  overflow-x: auto;
`;

const Text = styled.div`
  color: #3ba9da;
`;

const StyledRowIcon = styled(RowIcon)`
  margin-right: 15px;
  path {
    fill: #3ba9da;
  }
  rect {
    stroke: #3ba9da;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #ffffff;
`;

const AddComponentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
`;

const StyledPageComponent = styled(PageComponent)`
  border: 1px solid #6b80f9;
  box-shadow: 0 0 4px 0 rgba(136, 149, 224, 0.5);
`;

class ColumnComponent extends React.PureComponent<
  {
    org: ColumnComponent_org,
    registrationForm: ColumnComponent_registrationForm,
    componentProps: ColumnComponent_componentProps,
    className?: string,
    selectedComponent: SelectedComponent,
    selectedComponentId: ?string,
    draggedComponentId: ?string,
    draggedComponentKind: ?PageComponentKindType,
    hoveredPageComponent: ?string,
    optimisticChildId?: string,
    onUnselectComponent: () => void,
    onSelectPageComponent: (id: string) => void,
    onMoveEnd: (reorderedPageComponentConfig: ?ReorderedPageComponentConfigType) => void,
    onBeginDrag: (id: string) => void,
    onShowPageComponents: () => void,
    resetOptimisticChildId: () => void,
    readOnly?: boolean,
    defaultFont: string,
  },
  {
    draggedComponentId: ?string,
    draggedHeight: ?number,
    childMoved: boolean,
  },
> {
  state = {
    draggedComponentId: null,
    draggedHeight: null,
    childMoved: false,
  };

  componentDidUpdate(prevProps: $PropertyType<ColumnComponent, 'props'>) {
    const {
      optimisticChildId,
      resetOptimisticChildId,
      componentProps: { columnChild },
    } = this.props;
    if (!columnChild && prevProps.componentProps.columnChild) {
      // making default childMoved, when the reordered response came
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ childMoved: false });
    }
    if (optimisticChildId && !prevProps.componentProps.columnChild && columnChild) {
      resetOptimisticChildId();
    }
  }

  getContentComponent = (kind: PageComponentKindType): ?ContentComponentType => {
    if (kind === 'COLUMN') {
      return null;
    }
    const currentComponent = pageComponentsConfig.find(
      pageComponent => pageComponent.kind === kind,
    );
    if (!currentComponent) {
      return FormComponentContent;
    }
    return currentComponent.contentComponent;
  };

  handleShowPageComponents = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    this.props.onShowPageComponents();
  };

  emptyComponentView = (): React.Node => {
    const {
      hoveredPageComponent,
      draggedComponentKind,
      componentProps: { id, order },
    } = this.props;
    const dropCandidateKind = hoveredPageComponent || draggedComponentKind;
    if (
      dropCandidateKind &&
      !['DIVIDER', 'ROW'].some(restrictedComponent => dropCandidateKind === restrictedComponent)
    ) {
      return <DroppableArea order={order} parentId={id} visible />;
    }
    return (
      <EmptyContainer>
        <AddComponentButton onClick={this.handleShowPageComponents}>
          <StyledRowIcon />
          <Text>Add Component</Text>
        </AddComponentButton>
      </EmptyContainer>
    );
  };

  handleSelectPageComponent = (e: SyntheticEvent<HTMLElement>) => {
    const {
      onSelectPageComponent,
      componentProps: { columnChild },
    } = this.props;
    const childComponent = columnChild || { id: null, order: 0, kind: null };
    if (childComponent.id) {
      e.stopPropagation();
      onSelectPageComponent(childComponent.id);
    }
  };

  handleBeginDrag = (draggedComponentId: string) => {
    const element = document.getElementById(draggedComponentId);
    if (!element) {
      return;
    }
    this.setState({ draggedComponentId, draggedHeight: element.offsetHeight }, () => {
      this.props.onBeginDrag(draggedComponentId);
      setTimeout(() => this.setState({ draggedComponentId: null, draggedHeight: null }), 0);
    });
  };

  getDraggedPreview = (
    componentId: ?string,
    optimisticComponent?: ?PageComponentType,
  ): ?React.Node => {
    if (!componentId) {
      return null;
    }
    const columnChild = this.props.componentProps.columnChild || optimisticComponent;
    if (!columnChild || columnChild.id !== componentId) {
      return null;
    }

    const componentConfig =
      columnChild.kind === 'FORM'
        ? { label: 'Form', icon: <FormIcon />, kind: 'FORM' }
        : pageComponentsConfig.find(config => config.kind === columnChild.kind);
    if (!componentConfig) {
      return null;
    }
    return <StyledPageComponent componentConfig={componentConfig} />;
  };

  getColumnWidth = (): number => {
    const {
      selectedComponent,
      componentProps: { order, columnComponent },
    } = this.props;
    const defaultWidth = 50; // for flow to not complain
    if (!selectedComponent || selectedComponent.selectedRowLeftWidth == null) {
      return columnComponent ? columnComponent.width : defaultWidth;
    }
    if (order === 0) {
      return selectedComponent.selectedRowLeftWidth;
    }
    return 100 - selectedComponent.selectedRowLeftWidth;
  };

  getColumnPadding = (): string => {
    const {
      selectedComponent,
      componentProps: { order, columnComponent },
    } = this.props;
    const defautlPadding = '0 0 0 0'; // for flow to not complain
    if (!selectedComponent) {
      return columnComponent ? columnComponent.padding : defautlPadding;
    }
    if (
      !selectedComponent.selectedRowLeftColumnPadding &&
      !selectedComponent.selectedRowRightColumnPadding
    ) {
      return columnComponent ? columnComponent.padding : defautlPadding;
    }

    if (order === 0) {
      return selectedComponent.selectedRowLeftColumnPadding || defautlPadding;
    }
    return selectedComponent.selectedRowRightColumnPadding || defautlPadding;
  };

  getPageComponentById = (id?: ?string): ?PageComponentType => {
    if (!id) {
      return null;
    }
    const pageComponents = this.props.registrationForm.pageComponents;
    const pageComponentEdge = pageComponents.edges.find(({ node }) => node.id === id);
    if (!pageComponentEdge) {
      return null;
    }
    return pageComponentEdge.node;
  };

  handleColumnComponentMoveEnd = (
    reorderedPageComponentConfig?: ?ReorderedPageComponentConfigType,
  ) => {
    const { onMoveEnd, resetOptimisticChildId } = this.props;
    onMoveEnd(reorderedPageComponentConfig);
    if (!reorderedPageComponentConfig) {
      return;
    }
    this.setState({ childMoved: true });
    if (!reorderedPageComponentConfig.parentId) {
      resetOptimisticChildId();
    }
  };

  render() {
    const {
      org,
      registrationForm,
      componentProps: { columnComponent, parent, columnChild, order },
      className,
      selectedComponent,
      selectedComponentId,
      optimisticChildId,
      onUnselectComponent,
      readOnly,
      defaultFont,
    } = this.props;

    if (!columnComponent) {
      return null;
    }

    const { draggedComponentId, draggedHeight, childMoved } = this.state;
    const emptyColumnComponent = {
      id: `child${order}`,
      order,
      kind: 'COLUMN',
    };
    const optimisticChild = this.getPageComponentById(optimisticChildId);
    const childComponent = columnChild || optimisticChild || emptyColumnComponent;
    const Component = this.getContentComponent(childComponent.kind);
    const emptyComponent = this.emptyComponentView();
    const draggedPreview: ?React.Node = this.getDraggedPreview(draggedComponentId, optimisticChild);
    const selfRowSelected =
      parent && selectedComponent && selectedComponent.selectedComponentId === parent.id;
    const childComponentSelected =
      selectedComponent &&
      ((columnChild && columnChild.id === selectedComponent.selectedComponentId) ||
        (optimisticChild && optimisticChild.id === selectedComponent.selectedComponentId));

    const componentProps =
      selfRowSelected &&
      selectedComponent &&
      (selectedComponent.selectedRowLeftWidth != null ||
        (order === 0
          ? selectedComponent.selectedRowLeftColumnPadding
          : selectedComponent.selectedRowRightColumnPadding))
        ? {
            ...columnComponent,
            width: this.getColumnWidth(),
            padding: this.getColumnPadding(),
          }
        : columnComponent;

    if (readOnly) {
      return (
        <Container componentProps={componentProps} className={className}>
          {Component && (
            <Component
              registrationForm={registrationForm}
              componentProps={childComponent}
              defaultFont={defaultFont}
            />
          )}
        </Container>
      );
    }
    const framedComponent = Component && (
      <Frame
        editing={childComponent.id === selectedComponentId}
        kind={childComponent.kind}
        registrationFormId={registrationForm.id}
        pageComponentId={childComponent.id}
        onRemoveComponent={onUnselectComponent}
      >
        <Component
          org={org}
          defaultFont={defaultFont}
          registrationForm={registrationForm}
          componentProps={childComponent}
          active={childComponent.id === selectedComponentId}
          {...(childComponentSelected ? { selectedComponent } : {})}
        />
      </Frame>
    );

    if (!framedComponent || (childMoved && !optimisticChild)) {
      return (
        <Container componentProps={componentProps} className={className}>
          {emptyComponent}
        </Container>
      );
    }

    const showPreview = draggedComponentId === childComponent.id && draggedPreview != null;
    const style =
      showPreview && draggedHeight != null
        ? {
            height: `${draggedHeight}px`,
          }
        : {};
    return (
      <Container componentProps={componentProps} className={className}>
        <div onClick={this.handleSelectPageComponent} style={style}>
          <DraggablePageComponent
            pageComponent={{
              id: childComponent.id,
              order: childComponent.order,
              kind: childComponent.kind,
            }}
            selectedComponentId={selectedComponentId}
            onUnselectComponent={onUnselectComponent}
            onMoveEnd={this.handleColumnComponentMoveEnd}
            onBeginDrag={this.handleBeginDrag}
            dragPreviewInit={draggedComponentId === childComponent.id}
            isOptimisticChild={!!optimisticChildId}
          >
            {showPreview ? draggedPreview : framedComponent}
          </DraggablePageComponent>
        </div>
      </Container>
    );
  }
}

export default createFragmentContainer(ColumnComponent, {
  org: graphql`
    fragment ColumnComponent_org on Org {
      ...FormComponent_org
    }
  `,
  registrationForm: graphql`
    fragment ColumnComponent_registrationForm on RegistrationForm {
      id
      ...EventNameComponent_registrationForm
      ...EventDatesComponent_registrationForm
      pageComponents {
        edges {
          node {
            id
            order
            kind
            childPageComponents {
              edges {
                node {
                  id
                  columnChild {
                    id
                  }
                }
              }
            }
            ...ImageComponent_componentProps
            ...EventNameComponent_componentProps
            ...EventDatesComponent_componentProps
            ...TextComponent_componentProps
            ...DividerComponent_componentProps
            ...EmbedComponent_componentProps
            ...VideoComponent_componentProps
            ...FormComponent_componentProps
          }
        }
      }
    }
  `,
  componentProps: graphql`
    fragment ColumnComponent_componentProps on RegistrationPageComponent {
      id
      kind
      order
      parent {
        id
        order
        childPageComponents {
          edges {
            node {
              id
              order
              columnChild {
                id
              }
            }
          }
        }
      }
      columnChild {
        id
        order
        kind
        ...ImageComponent_componentProps
        ...EventNameComponent_componentProps
        ...EventDatesComponent_componentProps
        ...TextComponent_componentProps
        ...DividerComponent_componentProps
        ...EmbedComponent_componentProps
        ...VideoComponent_componentProps
        ...FormComponent_componentProps
      }
      columnComponent {
        id
        width
        padding
      }
    }
  `,
});
