/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type General_event$ref = any;
type MaterialAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomSection_event$ref: FragmentReference;
export type CustomSection_event = {|
  +id: string,
  +viewerCanUpdate: boolean,
  +customTextFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: string,
  |}>,
  +customTextareaFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: string,
  |}>,
  +customLinkFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: string,
  |}>,
  +customDateFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: any,
  |}>,
  +customNumberFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: number,
  |}>,
  +customCurrencyFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: number,
  |}>,
  +customBooleanFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +value: boolean,
  |}>,
  +customUserSelectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +user: {|
      +id: string,
      +firstName: string,
      +lastName: string,
      +email: string,
      +avatar: ?string,
      +$fragmentRefs: MaterialAvatar_user$ref,
    |},
  |}>,
  +customUserMultiselectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +user: {|
      +id: string,
      +firstName: string,
      +lastName: string,
      +email: string,
      +avatar: ?string,
      +$fragmentRefs: MaterialAvatar_user$ref,
    |},
  |}>,
  +customSelectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +option: {|
      +id: string
    |},
    +selectOtherValue: ?string,
  |}>,
  +customMultiselectFields: $ReadOnlyArray<{|
    +customField: {|
      +id: string
    |},
    +option: {|
      +id: string
    |},
    +selectOtherValue: ?string,
  |}>,
  +__typename: "Event",
  +$fragmentRefs: General_event$ref,
  +$refType: CustomSection_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": v1
},
v3 = [
  v2,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "value",
    "args": null,
    "storageKey": null
  }
],
v4 = [
  v2,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "user",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      v0,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "avatar",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "FragmentSpread",
        "name": "MaterialAvatar_user",
        "args": null
      }
    ]
  }
],
v5 = [
  v2,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "option",
    "storageKey": null,
    "args": null,
    "concreteType": "CustomFieldOptionType",
    "plural": false,
    "selections": v1
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "selectOtherValue",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "CustomSection_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "General_event",
      "args": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customTextFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomTextFieldType",
      "plural": true,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customTextareaFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomTextareaFieldType",
      "plural": true,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customLinkFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomLinkFieldType",
      "plural": true,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customDateFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomDateFieldType",
      "plural": true,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customNumberFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomNumberFieldType",
      "plural": true,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customCurrencyFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomCurrencyFieldType",
      "plural": true,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customBooleanFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomBooleanFieldType",
      "plural": true,
      "selections": v3
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customUserSelectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomUserSelectFieldType",
      "plural": true,
      "selections": v4
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customUserMultiselectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomUserMultiselectFieldType",
      "plural": true,
      "selections": v4
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSelectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSelectFieldType",
      "plural": true,
      "selections": v5
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customMultiselectFields",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomMultiselectFieldType",
      "plural": true,
      "selections": v5
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c33c9c6935ffb57751e85bd58de83be9';
module.exports = node;
