/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import Content from '../layout';
import DashboardRoutes from './DashboardRoutes';

import { type DashboardQueryResponse } from './__generated__/DashboardQuery.graphql';

const query = graphql`
  query DashboardQuery {
    org {
      ...DashboardRoutes_org
    }
  }
`;

type Props = { match: Match, location: Location };

const Dashboard = ({ match, location }: Props) => {
  return (
    <Content>
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(response: DashboardQueryResponse) => (
          <DashboardRoutes org={response.org} pathPrefix={match.path} location={location} />
        )}
      />
    </Content>
  );
};

export default Dashboard;
