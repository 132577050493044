/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatPercentage from 'utils/number/formatPercentage';

import { type CellPropsType } from 'components/budget/Table';

import { type PaymentCellPropsType } from './index';

import { type PaymentAmountOfExpenseCell_payment } from './__generated__/PaymentAmountOfExpenseCell_payment.graphql';

const PaymentAmountOfExpenseCell = ({
  payment,
}: CellPropsType<PaymentCellPropsType<PaymentAmountOfExpenseCell_payment>>) =>
  formatPercentage(payment.amount / payment.expense.actualAmount) || '-';

export default createFragmentContainer(
  PaymentAmountOfExpenseCell,
  graphql`
    fragment PaymentAmountOfExpenseCell_payment on Payment {
      amount

      expense {
        actualAmount
      }
    }
  `,
);
