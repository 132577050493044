/* @flow */
import { type ColumnConfiguration, BaseColumn } from 'components/material/table';

import {
  TeamMemberEmail,
  TeamMemberFullName,
  TeamMemberInviteStatus,
  TeamMemberLastActivity,
} from './columns/TeamMemberColumns';

const ColsConfig: ColumnConfiguration = [
  {
    label: 'Name',
    key: 'FULL_NAME',
    contentComponent: TeamMemberFullName,
    component: BaseColumn,
    value: 'full_name',
    width: 200,
    default: true,
    grow: 1,
  },
  {
    label: 'Email',
    key: 'EMAIL',
    contentComponent: TeamMemberEmail,
    component: BaseColumn,
    value: 'email',
    width: 180,
    default: true,
    grow: 1,
  },
  {
    label: 'Account Status',
    key: 'ACCOUNT_STATUS',
    contentComponent: TeamMemberInviteStatus,
    component: BaseColumn,
    value: 'event',
    width: 105,
    default: true,
    grow: 1,
  },
  {
    label: 'Last Activity',
    key: 'LAST_ACTIVITY',
    contentComponent: TeamMemberLastActivity,
    component: BaseColumn,
    value: 'last_activity',
    width: 120,
    default: true,
    grow: 1,
  },
];

const DefaultTableColumns: $ReadOnlyArray<string> = ColsConfig.filter(
  columnConfig => columnConfig.default,
).map(column => column.value);

export { ColsConfig, DefaultTableColumns };
