/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';

import createComment from 'graph/mutations/comment/createComment';
import removeComment from 'graph/mutations/comment/removeComment';
import updateComment from 'graph/mutations/comment/updateComment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import CommentInput from 'components/Comments/CommentInput';
import CommentItem from 'components/Comments/CommentItem';
import Button from 'components/material/Button';

import type { SubmissionReviewComments_org } from './__generated__/SubmissionReviewComments_org.graphql';
import type { SubmissionReviewComments_submission } from './__generated__/SubmissionReviewComments_submission.graphql';

const StyledCommentItem = styled(CommentItem)`
  padding: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
  color: ${props => props.theme.primaryActionColor};
`;

class SubmissionReviewComments extends React.PureComponent<
  {
    submission: SubmissionReviewComments_submission,
    org: SubmissionReviewComments_org,
    tz: string,
    currentUserId: string,
    relay: RelayPaginationProp,
  },
  {
    loading: boolean,
    replyingTo: ?string,
  },
> {
  state = { loading: false, replyingTo: null };

  handleLoadMore = () => {
    if (this.props.relay.isLoading()) return;

    this.setState({ loading: true });
    this.props.relay.loadMore(5, () => {
      this.setState({ loading: false });
    });
  };

  handleCreate = (content: string) => {
    createComment(this.props.submission.id, content).catch(showModernMutationError);
  };

  handleRemove = (commentId: string) => {
    removeComment(this.props.submission.id, commentId).catch(showModernMutationError);
  };

  handleUpdate = (commentId: string, content: string) => {
    updateComment(commentId, content).catch(showModernMutationError);
  };

  handleReply = (userSlug?: string) => {
    this.setState({ replyingTo: userSlug || null });
  };

  render() {
    const comments = this.props.submission.comments.edges.map(edge => edge.node);
    const users = this.props.submission.reviewers.edges.map(edge => edge.node);
    const admins = this.props.org.users.edges.map(edge => edge.node);
    const reviewers = uniqBy([...users, ...admins], 'id');

    return (
      <React.Fragment>
        <CommentInput
          placeholder="Add comment"
          users={reviewers}
          currentUserId={this.props.currentUserId}
          onSave={this.handleCreate}
          replyingTo={this.state.replyingTo}
          onReplyHandled={this.handleReply}
          hideEmoji
        />

        {comments.map(comment => (
          <StyledCommentItem
            key={comment.id}
            comment={comment}
            users={reviewers}
            currentUserId={this.props.currentUserId}
            onRemove={this.handleRemove}
            onUpdate={this.handleUpdate}
            onReply={this.handleReply}
            tz={this.props.tz}
            hideEmoji
          />
        ))}

        {this.props.relay.hasMore() && (
          <StyledButton
            label="See More"
            minimal
            onClick={this.handleLoadMore}
            loading={this.state.loading}
          />
        )}
      </React.Fragment>
    );
  }
}

export default createPaginationContainer(
  SubmissionReviewComments,
  {
    submission: graphql`
      fragment SubmissionReviewComments_submission on EventRequestSubmission
      @argumentDefinitions(count: { type: "Int", defaultValue: 5 }, cursor: { type: "String" }) {
        id
        reviewers {
          edges {
            node {
              id
              ...CommentInput_users
              ...CommentItem_users
            }
          }
        }
        comments(first: $count, after: $cursor)
          @connection(key: "SubmissionReviewComments_comments") {
          edges {
            node {
              id
              ...CommentItem_comment
            }
          }
        }
      }
    `,
    org: graphql`
      fragment SubmissionReviewComments_org on Org {
        users(onlyAdmins: true) {
          edges {
            node {
              id
              ...CommentInput_users
              ...CommentItem_users
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.submission && props.submission.comments;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
        submissionId: props.submission.id,
      };
    },
    query: graphql`
      query SubmissionReviewCommentsPaginationQuery(
        $count: Int!
        $cursor: String
        $submissionId: ID!
      ) {
        node(id: $submissionId) {
          ...SubmissionReviewComments_submission @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
