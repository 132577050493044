/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import inviteStatuses from 'config/userInviteStatuses';

import type { MemberInviteStatus_user } from './__generated__/MemberInviteStatus_user.graphql';

type Props = {
  user: MemberInviteStatus_user,
};

function MemberInviteStatus({ user }: Props) {
  return <div>{inviteStatuses[user.inviteStatus]}</div>;
}

export default createFragmentContainer(
  MemberInviteStatus,
  graphql`
    fragment MemberInviteStatus_user on User {
      inviteStatus
    }
  `,
);
