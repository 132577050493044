/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation saveRegistrationFormChangesMutation($input: SaveRegistrationFormChangesInput!) {
    saveRegistrationFormChanges(input: $input) {
      event {
        id
        registrationForm {
          id
          publishedAt
        }
        editableRegistrationForm {
          id
          hasChanges
        }
      }
    }
  }
`;

export default function saveRegistrationFormChanges(formId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: { formId },
    },
  });
}
