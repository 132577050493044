/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextField from 'components/material/TextField';
import EditableContainer from '../form/EditableContainer';

const Container = styled.div`
  margin: 30px 0;
`;

const Title = styled.div`
  font-size: 16px;
  color: rgb(74, 86, 101, 0.87);
  font-weight: 500;
`;

const StyledEditableContainer = styled(EditableContainer)`
  padding: 15px 30px;
  margin: 25px -30px 15px;
  background: #f7f7f7;
`;

const Preview = styled.div`
  padding: 13px 0 12px;
  margin-right: 20px;
  color: #4a5665;
`;

const Edit = styled.div`
  margin-top: -5px;
`;

export default class EditableSubmitMessage extends React.PureComponent<{
  submitMessage: string,
  onChange: (submitMessage: string) => void,
  onSave?: () => void,
}> {
  handleSave = () => {
    if (this.props.onSave) {
      this.props.onSave();
    }
    return !!this.props.submitMessage.trim();
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange(e.currentTarget.value);
  };

  render() {
    return (
      <Container>
        <Title>Post-Submit Confirmation Message</Title>
        <StyledEditableContainer
          defaultPreviewing
          previewContent={<Preview>{this.props.submitMessage}</Preview>}
          editContent={
            <Edit>
              <TextField value={this.props.submitMessage} autoFocus onChange={this.handleChange} />
            </Edit>
          }
          onSave={this.handleSave}
        />
      </Container>
    );
  }
}
