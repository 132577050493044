/* @flow */
import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
} from 'utils/routing/parseTypedQueryString';

export type ParsedStaffFilters = {
  sort: ?SortParam,
};

export default function parseStaffFilters(queryString: string): ParsedStaffFilters {
  return parseTypedQueryString(queryString, {
    sort: stringParamToSort,
  });
}
