/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Overlay from 'components/material/Overlay';

import ProfileMenuItems from './ProfileMenuItems';

import type { HeaderProfileMenu_user } from './__generated__/HeaderProfileMenu_user.graphql';

const StyledOverlay = styled(Overlay)`
  width: auto;
  max-width: 325px;
  margin-top: 5px;
  border-radius: 5px;
  border: solid 1px #dadde1;
  background-color: #ffffff;
  box-shadow: none;
`;

class HeaderProfileMenu extends React.Component<{
  show: boolean,
  target: ?HTMLElement,
  container: ?HTMLElement,
  onHide: () => void,
  user: HeaderProfileMenu_user,
}> {
  render() {
    const { user, show, target, onHide, container } = this.props;
    return (
      <StyledOverlay
        show={show}
        target={target}
        onHide={onHide}
        container={container}
        forceRightEdge
      >
        <ProfileMenuItems user={user} onHide={onHide} />
      </StyledOverlay>
    );
  }
}

export default createFragmentContainer(HeaderProfileMenu, {
  user: graphql`
    fragment HeaderProfileMenu_user on User {
      ...ProfileMenuItems_user
    }
  `,
});
