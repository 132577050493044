/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MembersHeader_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MembersPage_org$ref: FragmentReference;
export type MembersPage_org = {|
  +id: string,
  +$fragmentRefs: MembersHeader_org$ref,
  +$refType: MembersPage_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MembersPage_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MembersHeader_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '38e352a3fc21a4795239d6e01774481a';
module.exports = node;
