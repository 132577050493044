/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import reservedPathVariables from 'config/reservedPathVariables';

import currentOrigin from 'utils/currentOrigin';

import savePublicResourceToken from 'graph/mutations/publicResourceToken/savePublicResourceToken';
import updateRegistrationForm from 'graph/mutations/registration/updateRegistrationForm';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ThankYou from 'images/registration/thankYou.svg';
import Switch from 'components/material/Switch';
import TextInput from 'components/material/TextInput';
import inputBoxStyled from 'components/SharedForm/components/inputBoxStyled';

import EditableSubmitMessage from './EditableSubmitMessage';

import type { GeneralSettings_org } from './__generated__/GeneralSettings_org.graphql';
import type { GeneralSettings_registrationForm } from './__generated__/GeneralSettings_registrationForm.graphql';

const Block = styled.div`
  padding: 30px 45px 0 40px;
  border: solid 1px #dadada;
  border-radius: 4px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const ResourceTokenRow = styled(Row)`
  justify-content: flex-start;
  align-items: baseline;
  color: #596a7f;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1em;
  color: rgba(74, 86, 101, 0.87);
`;

const HelpText = styled.div`
  margin: 5px 0 25px;
  opacity: 0.71;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  color: rgba(74, 86, 101, 0.71);
`;

const StyledThankYou = styled(ThankYou)`
  width: 136px;
  height: 84px;
  margin: 0 auto;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: #dd3b3b;
`;

const TextField = styled(TextInput)`
  ${props => inputBoxStyled(props)};
  max-width: 237px;
  height: 36px;
  min-height: 36px;
  margin-top: 0;
`;

const TitleWrapper = styled.div`
  max-width: 580px;
`;

const SubTitle = styled.div`
  margin: 10px 20px 0 0;
  font-size: 14px;
  line-height: 1em;
  font-style: italic;
  color: rgba(74, 86, 101, 0.71);
`;

const SwitchWrapper = styled.div`
  flex: 0 0 auto;
`;

const Line = styled.div`
  margin: 0 -45px 36px -42px;
  border-bottom: 1px solid #d8d8d8;
`;

class GeneralSettings extends React.PureComponent<
  {
    org: GeneralSettings_org,
    registrationForm: GeneralSettings_registrationForm,
    isOrgTemplate: boolean,
    defaultFont?: string,
  },
  {
    publicResourceToken: string,
    submitMessage: string,
    errors: { [string]: ?string | ?boolean },
  },
> {
  state = {
    publicResourceToken: this.props.registrationForm.publicResourceToken.token,
    submitMessage: this.props.registrationForm.submitMessage || '',
    errors: {},
  };

  componentDidMount() {
    const defaultFont = this.props.defaultFont || '';
    const registrationForm = this.props.registrationForm;
    const currentDefaultFont = this.getCurrentDefaultFont();
    const submitMessage = registrationForm.submitMessage;
    if (
      submitMessage &&
      defaultFont &&
      (!currentDefaultFont || currentDefaultFont.toLowerCase() !== defaultFont.toLowerCase())
    ) {
      updateRegistrationForm({
        formId: registrationForm.id,
        submitMessage,
        defaultFont,
        forceUpdate: true,
      })
        .then(updatedRegistrationForm => {
          this.setState({
            submitMessage: updatedRegistrationForm.submitMessage || submitMessage,
          });
        })
        .catch(showModernMutationError);
    }
  }

  getCurrentDefaultFont = () => {
    const submitMessage = this.props.registrationForm.submitMessage;
    if (!submitMessage) {
      return null;
    }
    const fontStart = submitMessage.substring(submitMessage.indexOf("'") + 1);
    return fontStart.substring(0, fontStart.indexOf("'"));
  };

  handleChangeResourceToken = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    this.setState(prevState => ({
      publicResourceToken: value.replace(/[^a-zA-Z0-9-_]/g, '-'),
      errors: { ...prevState.errors, token: null },
    }));
  };

  handleBlurResourceToken = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    const newToken = e.currentTarget.value.trim();

    if (reservedPathVariables.includes(newToken)) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          token: 'This is a reserved word',
        },
      }));
      return;
    }

    const registrationForm = this.props.registrationForm;
    const publicResourceToken = registrationForm.publicResourceToken;
    if (newToken === '') {
      this.setState({
        publicResourceToken: publicResourceToken.token,
      });
    } else if (newToken !== publicResourceToken.token) {
      savePublicResourceToken(publicResourceToken.id, newToken)
        .catch((error: Error) => {
          this.setState(prevState => ({
            errors: {
              ...prevState.errors,
              token: error.message,
            },
          }));
        })
        .catch(showModernMutationError);
    }
  };

  handleChangeSubmitMessage = (submitMessage: string) => {
    this.setState(prevState => ({
      submitMessage,
      errors: {
        ...prevState.errors,
        submitMessage: submitMessage ? null : 'Post-Submit Message cannot be blank',
      },
    }));
  };

  handleSaveSubmitMessage = () => {
    const submitMessage = this.state.submitMessage.trim();
    const { defaultFont, registrationForm } = this.props;

    if (submitMessage !== registrationForm.submitMessage) {
      updateRegistrationForm({ formId: registrationForm.id, submitMessage, defaultFont });
    }
  };

  handleAutoApprovalChange = () => {
    const registrationForm = this.props.registrationForm;
    updateRegistrationForm({
      formId: registrationForm.id,
      autoApproval: !registrationForm.autoApproval,
    });
  };

  render() {
    const { org, registrationForm, isOrgTemplate } = this.props;
    const { errors, publicResourceToken, submitMessage } = this.state;
    const host = currentOrigin();

    return (
      <>
        {!isOrgTemplate && !org.syncedToIbmWm && (
          <Block>
            <Row>
              <Title>Shareable URL</Title>
            </Row>
            <ResourceTokenRow>
              {host}/{org.settings.subdomain ? '' : `${org.slug}/`}
              <div>
                <TextField
                  onChange={this.handleChangeResourceToken}
                  onBlur={this.handleBlurResourceToken}
                  value={publicResourceToken}
                  error={errors.token}
                />
                {errors.token && <ErrorMessage>{errors.token}</ErrorMessage>}
              </div>
            </ResourceTokenRow>
          </Block>
        )}
        {(!org.syncedToIbmWm || org.canConfigureRegistrationApproval) && (
          <Block>
            {org.canConfigureRegistrationApproval && (
              <Row>
                <TitleWrapper>
                  <Title>Automatically approve registrations</Title>
                  <SubTitle>
                    When disabled, the confirmation email and the calendar invitation will not be
                    sent, until the registration is manually approved. You can approve a
                    registration by changing the status from Pending to Registered.
                  </SubTitle>
                </TitleWrapper>
                <SwitchWrapper>
                  <Switch
                    enabled={registrationForm.autoApproval}
                    onChange={this.handleAutoApprovalChange}
                    size="small"
                  />
                </SwitchWrapper>
              </Row>
            )}
            {!org.syncedToIbmWm && (
              <>
                <Line />
                <Title>Confirmation Message</Title>
                <HelpText>Click to edit</HelpText>
                <Row>
                  <StyledThankYou />
                </Row>
                <EditableSubmitMessage
                  template={submitMessage || ''}
                  onChange={this.handleChangeSubmitMessage}
                  onSave={this.handleSaveSubmitMessage}
                  error={errors.submitMessage}
                />
              </>
            )}
          </Block>
        )}
      </>
    );
  }
}

export default createFragmentContainer(GeneralSettings, {
  org: graphql`
    fragment GeneralSettings_org on Org {
      id
      slug
      syncedToIbmWm
      canConfigureRegistrationApproval
      settings {
        subdomain
      }
    }
  `,
  registrationForm: graphql`
    fragment GeneralSettings_registrationForm on RegistrationForm {
      id
      autoApproval
      id
      submitMessage
      publicResourceToken {
        id
        token
      }
    }
  `,
});
