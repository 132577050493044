/* @flow */
import React from 'react';
import ClickOut from 'react-onclickout';
import styled, { css } from 'styled-components';

import Overlay from 'components/material/Overlay';
import Search, { SearchIcon } from 'components/Search';

import { Field, Placeholder } from './index';
import RemovablePill from './RemovablePill';
import SelectFieldRow from './SelectFieldRow';

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
`;

export const StyledOverlay = styled(Overlay)`
  display: flex;
  min-width: 100%;
  width: auto;
  max-height: 250px;
  margin-top: 3px;
  margin-bottom: 50px;
`;

const OverlayFocus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  outline: none;
`;

const OverlayScroll = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const SearchContainer = styled.div`
  padding: 5px 10px;
`;

const StyledSearch = styled(Search)`
  max-width: 100%;
  font-size: 14px;
  ${SearchIcon} {
    top: 5px;
  }
`;

const Icon = styled.i`
  color: ${props => (props.error ? props.theme.negativeActionColor : 'rgba(55,64,76,0.3)')};
  font-size: 16px;
`;

const SelectionsList = styled.div`
  flex: 1 1 auto;

  ${props =>
    props.noWrap &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

const Error = styled.div`
  font-size: 11px;
  margin-top: 1px;
  color: ${props => props.theme.negativeActionColor};
`;

const StyledSelectFieldRow = styled(SelectFieldRow)`
  ${props =>
    props.focused &&
    css`
      background: ${props.theme.hoverRowColor};
    `};
`;

export default class MultiValueSearchSelect extends React.Component<
  {
    options: $ReadOnlyArray<string | $ReadOnlyArray<string>>,
    selectedValues: ?$ReadOnlyArray<string>,
    onChange: (values: $ReadOnlyArray<string>) => void,
    error?: ?string,
    placeholder?: string,
  },
  {
    query: string,
    focus: number,
    overlayShown: boolean,
  },
> {
  state = {
    query: '',
    focus: 0,
    overlayShown: false,
  };

  containerRef = React.createRef();

  fieldRef = React.createRef();

  overlayRef = React.createRef();

  overlayScrollRef = React.createRef();

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const container = this.containerRef.current;
    const overlay = this.overlayRef.current;
    const relatedTarget: ?Node = (e: any).relatedTarget || document.activeElement;
    if (
      container &&
      relatedTarget &&
      overlay &&
      (container.contains(relatedTarget) || overlay.contains(relatedTarget))
    ) {
      return;
    }
    this.handleOverlayHide();
  };

  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      const query = this.state.query;
      if (query.length > 1 && !(this.props.selectedValues || []).includes(query)) {
        this.toggleOptionSelect(query);
      }
    }
  };

  handleQueryChange = (query: string) => {
    this.setState({ query });
  };

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false, query: '' });
  };

  handleOptionHover = (index: number) => {
    this.setState({ focus: index });
  };

  handleFilterOptions = (query: string) => {
    const suggestions = this.props.options
      .filter(option =>
        (Array.isArray(option) ? option : [option]).some(o =>
          o.toLowerCase().replace(/\s/g, '').startsWith(query.toLowerCase().replace(/\s/g, '')),
        ),
      )
      .map(o => (Array.isArray(o) ? o[0] : o));

    if (query && !this.optionExists(query)) {
      return [query, ...suggestions];
    }
    return suggestions;
  };

  toggleOptionSelect = (value: string) => {
    if (this.props.selectedValues != null && this.props.selectedValues.includes(value)) {
      this.props.onChange(this.props.selectedValues.filter(option => option !== value));
    } else {
      this.props.onChange([...(this.props.selectedValues || []), value]);
    }
  };

  optionExists(query: string) {
    return this.props.options.some(o =>
      (Array.isArray(o) ? o : [o]).some(v => v.toLowerCase() === query.toLowerCase()),
    );
  }

  focusedIndex(length: number): number {
    const index = this.state.focus % length;
    if (this.state.focus >= 0 || index === 0) {
      return index;
    }
    return length + index;
  }

  renderSelectedOptions() {
    const selectedValues = this.props.selectedValues;

    return (
      <SelectionsList>
        {(selectedValues || []).map(selectedValue => (
          <RemovablePill
            value={selectedValue}
            key={selectedValue}
            onRemove={this.toggleOptionSelect}
          >
            {selectedValue}
          </RemovablePill>
        ))}
      </SelectionsList>
    );
  }

  render() {
    const { options, selectedValues, error } = this.props;

    const hasSelectedOptions = (selectedValues || []).length > 0;
    const focusedIndex = this.focusedIndex(options.length);

    return (
      <ClickOut onClickOut={this.handleBlur}>
        <Container ref={this.containerRef} focused={this.state.overlayShown}>
          <Field
            ref={this.fieldRef}
            onClick={this.handleOverlayShow}
            focused={this.state.overlayShown}
            error={error}
            tabIndex={-1}
          >
            {hasSelectedOptions ? (
              this.renderSelectedOptions()
            ) : (
              <Placeholder error={this.props.error}>{this.props.placeholder}</Placeholder>
            )}
            <Icon className="fa fa-fw fa-angle-down" error={this.props.error} />
          </Field>
          <StyledOverlay
            show={this.state.overlayShown}
            target={this.fieldRef.current}
            container={this.containerRef.current}
            overlayRef={this.overlayRef}
          >
            <OverlayFocus tabIndex={-1} ref={this.overlayRef}>
              <SearchContainer>
                <StyledSearch
                  onSearch={this.handleQueryChange}
                  onKeyDown={this.handleKeyDown}
                  search={this.state.query}
                  placeholder="Search..."
                  autoFocus
                />
              </SearchContainer>
              <OverlayScroll ref={this.overlayScrollRef}>
                {this.handleFilterOptions(this.state.query).map((option, index) => (
                  <StyledSelectFieldRow
                    key={index} // eslint-disable-line react/no-array-index-key
                    focused={focusedIndex === index}
                    onMouseEnter={() => this.handleOptionHover(index)}
                    label={option}
                    value={option}
                    onClick={this.toggleOptionSelect}
                    selected={(selectedValues || []).includes(option)}
                  />
                ))}
              </OverlayScroll>
            </OverlayFocus>
          </StyledOverlay>
          {this.props.error && <Error>{this.props.error}</Error>}
        </Container>
      </ClickOut>
    );
  }
}
