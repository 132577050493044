/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import type { Tab } from 'config/eventTabs';

import TabsSettingsOverlay from './TabsSettingsOverlay';

const Container = styled.div`
  flex: 0 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (${props => props.theme.mobileOnly}) {
    display: none;
  }
`;

const IconContainer = styled.div`
  position: relative;
`;

const Icon = styled.i`
  font-size: 22px;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

export default class TabsSettings extends React.PureComponent<
  {
    tabs: $ReadOnlyArray<Tab>,
    shownTabs: $ReadOnlyArray<string>,
    onTabsChange: (tabs: $ReadOnlyArray<string>) => void,
    className?: string,
  },
  {
    overlayShown: boolean,
  },
> {
  state = {
    overlayShown: false,
  };

  icon: ?HTMLElement;

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  render() {
    const shownTabs = this.props.tabs
      .filter(t => this.props.shownTabs.includes(t.value) || t.sticky === true)
      .map(t => t.value);

    return (
      <Container className={this.props.className}>
        <IconContainer
          ref={el => {
            this.icon = el;
          }}
        >
          <Icon className="fa fa-fw fa-cog" onClick={this.handleOverlayShow} />
        </IconContainer>

        <TabsSettingsOverlay
          container={this.icon}
          target={this.icon}
          onHide={this.handleOverlayHide}
          show={this.state.overlayShown}
          tabs={this.props.tabs}
          shownTabs={shownTabs}
          onChange={this.props.onTabsChange}
        />
      </Container>
    );
  }
}
