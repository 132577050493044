/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import updateEdgeInConnection from 'graph/updaters/updateEdgeInConnection';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type {
  SaveTeamBudgetPeriodMutationVariables,
  SaveTeamBudgetPeriodMutationResponse,
} from './__generated__/saveTeamBudgetPeriodMutation.graphql';

const mutation = graphql`
  mutation saveTeamBudgetPeriodMutation($input: SaveTeamBudgetPeriodInput!) {
    saveTeamBudgetPeriod(input: $input) {
      budgetPeriodEdge {
        __typename
        node {
          id
          period
          year
          ...TeamBudgeting_budgetPeriod
          teams {
            edges {
              amount
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default function saveTeamBudgetPeriod({
  orgId,
  periodId,
  year,
  quarter,
  teamId,
  amount,
}: {
  orgId: string,
  periodId?: ?string,
  year: number,
  quarter: number,
  teamId: string,
  amount: number,
}): Promise<SaveTeamBudgetPeriodMutationResponse> {
  const variables: SaveTeamBudgetPeriodMutationVariables = {
    input: {
      year,
      quarter,
      teamId,
      amount,
    },
  };
  const updater = mergeUpdaters(
    updateEdgeInConnection({
      parentId: orgId,
      edgeName: 'budgetPeriodEdge',
      connections: [{ field: 'budgetPeriods' }],
    }),
    ...(!periodId
      ? [
          addRecordToConnection({
            parentId: orgId,
            edgeName: 'budgetPeriodEdge',
            connections: [{ field: 'budgetPeriods' }],
          }),
        ]
      : []),
  );
  return commitModernMutation({
    mutation,
    variables,
    updater,
  }).then((response: SaveTeamBudgetPeriodMutationResponse) => {
    if (response.saveTeamBudgetPeriod && response.saveTeamBudgetPeriod.budgetPeriodEdge.node) {
      return response.saveTeamBudgetPeriod.budgetPeriodEdge.node;
    }
    throw new Error('No period');
  });
}
