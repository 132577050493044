/**
 * @flow
 * @relayHash 2d64e577102f72a9d054daac27add7d5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SyncStatusIndicator_status$ref = any;
export type ConnectMarketoAccountInput = {
  clientMutationId?: ?string,
  host: string,
  clientId: string,
  clientSecret: string,
};
export type connectMarketoAccountMutationVariables = {|
  input: ConnectMarketoAccountInput
|};
export type connectMarketoAccountMutationResponse = {|
  +connectMarketoAccount: ?{|
    +updatedOrg: {|
      +marketoAccount: ?{|
        +id: string,
        +username: string,
        +syncStatus: {|
          +$fragmentRefs: SyncStatusIndicator_status$ref
        |},
      |}
    |}
  |}
|};
export type connectMarketoAccountMutation = {|
  variables: connectMarketoAccountMutationVariables,
  response: connectMarketoAccountMutationResponse,
|};
*/


/*
mutation connectMarketoAccountMutation(
  $input: ConnectMarketoAccountInput!
) {
  connectMarketoAccount(input: $input) {
    updatedOrg {
      marketoAccount {
        id
        username
        syncStatus {
          ...SyncStatusIndicator_status
        }
      }
      id
    }
  }
}

fragment SyncStatusIndicator_status on SyncStatus {
  lastSuccessAt
  state
  errorMessage
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ConnectMarketoAccountInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ConnectMarketoAccountInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "connectMarketoAccountMutation",
  "id": null,
  "text": "mutation connectMarketoAccountMutation(\n  $input: ConnectMarketoAccountInput!\n) {\n  connectMarketoAccount(input: $input) {\n    updatedOrg {\n      marketoAccount {\n        id\n        username\n        syncStatus {\n          ...SyncStatusIndicator_status\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment SyncStatusIndicator_status on SyncStatus {\n  lastSuccessAt\n  state\n  errorMessage\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "connectMarketoAccountMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "connectMarketoAccount",
        "storageKey": null,
        "args": v1,
        "concreteType": "ConnectMarketoAccountPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedOrg",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "marketoAccount",
                "storageKey": null,
                "args": null,
                "concreteType": "MarketoAccount",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "syncStatus",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SyncStatus",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "SyncStatusIndicator_status",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "connectMarketoAccountMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "connectMarketoAccount",
        "storageKey": null,
        "args": v1,
        "concreteType": "ConnectMarketoAccountPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "updatedOrg",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "marketoAccount",
                "storageKey": null,
                "args": null,
                "concreteType": "MarketoAccount",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "syncStatus",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SyncStatus",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastSuccessAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "state",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "errorMessage",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd3ef1add61b8046293c4c8ae6df2fad';
module.exports = node;
