import { getAccessToken } from './Auth';

import UIActions from '../actions/UIActions';
import UserActions from '../actions/UserActions';

function shouldPrefix(url) {
  return url[0] === '/';
}

function makeUrl(url) {
  return `/${shouldPrefix(url) ? 'v1' : ''}${url}`;
}

function showErrorMessage(error) {
  const { response = {}, config = {} } = error;
  const { data = {} } = response;
  if (data.error === 'Unauthorized.') {
    UserActions.removeUser();
  } else {
    let message = data.error || data.message;
    if (typeof message !== 'string') message = 'An unexpected error has occurred.';
    const debugging = `${String(config.method).toUpperCase()} ${config.url}\n${error.status} `;
    UIActions.showErrorMessage('Error', message, debugging);
    console.error('message', debugging);
  }
}

function wrapFetch(method, url, data, callback, errorCallback) {
  if (typeof data === 'function') {
    /* eslint-disable no-param-reassign */
    errorCallback = callback;
    callback = data;
    data = null;
    /* eslint-enable */
  }

  const headers = {
    'content-type': 'application/json',
    Authorization: getAccessToken(),
  };

  return fetch(makeUrl(url), {
    headers,
    method,
    body: data ? JSON.stringify(data) : null,
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 401) {
        return null;
      }

      return (response.headers.get('content-type').includes('application/json')
        ? response.json()
        : response.text()
      ).then(body => {
        if (callback && !shouldPrefix(url)) {
          setTimeout(
            () => callback(response.status === 500 ? 'An unexpected error has occurred.' : body),
            0,
          );
        }

        const error = new Error('An unexpected error has occurred.');

        if (errorCallback) {
          setTimeout(() => errorCallback(body), 0);
        } else if (!callback || shouldPrefix(url)) {
          showErrorMessage(error);
        }

        throw error;
      });
    })
    .then(body => {
      if (callback) {
        setTimeout(() => callback(body), 0);
      }

      return body;
    });
}

/**
 *  Public
 */

export const Get = (url, callback, errorCallback) => wrapFetch('GET', url, callback, errorCallback);

export const Post = (url, data, callback, errorCallback) =>
  wrapFetch('POST', url, data, callback, errorCallback);

export const Delete = (url, callback, errorCallback) =>
  wrapFetch('DELETE', url, callback, errorCallback);

export const Patch = (url, data, callback, errorCallback) =>
  wrapFetch('PATCH', url, data, callback, errorCallback);
