/**
 * @flow
 * @relayHash 5793cd709c6fe705710ce525b786fbcf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BudgetSetup_org$ref = any;
type TeamBudgeting_teams$ref = any;
export type CreateTeamInput = {
  clientMutationId?: ?string,
  name: string,
  fromWindow: string,
};
export type createTeamMutationVariables = {|
  input: CreateTeamInput
|};
export type createTeamMutationResponse = {|
  +createTeam: ?{|
    +user: {|
      +id: string,
      +email: string,
      +teams: {|
        +totalCount: number
      |},
    |},
    +teamEdge: {|
      +node: {|
        +id: string,
        +dbId: number,
        +slug: string,
        +name: string,
        +viewerCanUpdate: boolean,
        +org: {|
          +$fragmentRefs: BudgetSetup_org$ref
        |},
        +$fragmentRefs: TeamBudgeting_teams$ref,
      |},
      +__typename: string,
    |},
  |}
|};
export type createTeamMutation = {|
  variables: createTeamMutationVariables,
  response: createTeamMutationResponse,
|};
*/


/*
mutation createTeamMutation(
  $input: CreateTeamInput!
) {
  createTeam(input: $input) {
    user {
      id
      email
      teams {
        totalCount
      }
    }
    teamEdge {
      node {
        id
        dbId
        slug
        name
        viewerCanUpdate
        ...TeamBudgeting_teams
        org {
          ...BudgetSetup_org
          id
        }
      }
      __typename
    }
  }
}

fragment TeamBudgeting_teams on Team {
  id
  name
}

fragment BudgetSetup_org on Org {
  budgetPeriods {
    edges {
      node {
        id
        period
        year
        ...TeamBudgeting_budgetPeriod
      }
    }
  }
  ...FiscalYearPanel_org
  ...TeamBudgeting_org
}

fragment TeamBudgeting_budgetPeriod on BudgetPeriod {
  id
  period
  startDate
  endDate
  teams {
    edges {
      amount
      node {
        id
        name
      }
    }
  }
}

fragment FiscalYearPanel_org on Org {
  id
  settings {
    fiscalYearStart
    id
  }
  budgetPeriods {
    edges {
      node {
        period
        year
        id
      }
    }
  }
}

fragment TeamBudgeting_org on Org {
  id
  settings {
    currency
    ...TeamRow_settings
    id
  }
}

fragment TeamRow_settings on OrgSettings {
  currency
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateTeamInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "teams",
      "storageKey": null,
      "args": null,
      "concreteType": "TeamRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createTeamMutation",
  "id": null,
  "text": "mutation createTeamMutation(\n  $input: CreateTeamInput!\n) {\n  createTeam(input: $input) {\n    user {\n      id\n      email\n      teams {\n        totalCount\n      }\n    }\n    teamEdge {\n      node {\n        id\n        dbId\n        slug\n        name\n        viewerCanUpdate\n        ...TeamBudgeting_teams\n        org {\n          ...BudgetSetup_org\n          id\n        }\n      }\n      __typename\n    }\n  }\n}\n\nfragment TeamBudgeting_teams on Team {\n  id\n  name\n}\n\nfragment BudgetSetup_org on Org {\n  budgetPeriods {\n    edges {\n      node {\n        id\n        period\n        year\n        ...TeamBudgeting_budgetPeriod\n      }\n    }\n  }\n  ...FiscalYearPanel_org\n  ...TeamBudgeting_org\n}\n\nfragment TeamBudgeting_budgetPeriod on BudgetPeriod {\n  id\n  period\n  startDate\n  endDate\n  teams {\n    edges {\n      amount\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment FiscalYearPanel_org on Org {\n  id\n  settings {\n    fiscalYearStart\n    id\n  }\n  budgetPeriods {\n    edges {\n      node {\n        period\n        year\n        id\n      }\n    }\n  }\n}\n\nfragment TeamBudgeting_org on Org {\n  id\n  settings {\n    currency\n    ...TeamRow_settings\n    id\n  }\n}\n\nfragment TeamRow_settings on OrgSettings {\n  currency\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createTeamMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateTeamPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teamEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamRequiredEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  v2,
                  v4,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "FragmentSpread",
                    "name": "TeamBudgeting_teams",
                    "args": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "org",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Org",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "BudgetSetup_org",
                        "args": null
                      }
                    ]
                  }
                ]
              },
              v8
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createTeamMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateTeamPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teamEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamRequiredEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  v2,
                  v4,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "org",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Org",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "budgetPeriods",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BudgetPeriodRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BudgetPeriodRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BudgetPeriod",
                                "plural": false,
                                "selections": [
                                  v2,
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "period",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "year",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "startDate",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "endDate",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "teams",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BudgetPeriodTeamConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BudgetPeriodTeamEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "amount",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Team",
                                            "plural": false,
                                            "selections": [
                                              v2,
                                              v6
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      v2,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "settings",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrgSettings",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fiscalYearStart",
                            "args": null,
                            "storageKey": null
                          },
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "currency",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              v8
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '19ed44dcdcd053c6c8ff86e63d9ec3e7';
module.exports = node;
