/**
 * @flow
 * @relayHash 0f800cf010ce98dea78a6ae13bad569c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AllTasksPage_me$ref = any;
type AllTasksPage_org$ref = any;
export type AllTasksQueryVariables = {||};
export type AllTasksQueryResponse = {|
  +org: {|
    +$fragmentRefs: AllTasksPage_org$ref
  |},
  +me: {|
    +$fragmentRefs: AllTasksPage_me$ref
  |},
|};
export type AllTasksQuery = {|
  variables: AllTasksQueryVariables,
  response: AllTasksQueryResponse,
|};
*/


/*
query AllTasksQuery {
  org {
    ...AllTasksPage_org
    id
  }
  me {
    ...AllTasksPage_me
    id
  }
}

fragment AllTasksPage_org on Org {
  ...AllTasksHeaderBar_org
  settings {
    fiscalYearStart
    id
  }
}

fragment AllTasksPage_me on User {
  ...AllTasksHeaderBar_me
}

fragment AllTasksHeaderBar_me on User {
  teams {
    edges {
      node {
        ...TaskFilters_teams
        id
      }
    }
  }
}

fragment TaskFilters_teams on Team {
  id
  name
}

fragment AllTasksHeaderBar_org on Org {
  tags {
    edges {
      node {
        ...TaskFilters_tags
        id
      }
    }
  }
}

fragment TaskFilters_tags on Tag {
  id
  color
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AllTasksQuery",
  "id": null,
  "text": "query AllTasksQuery {\n  org {\n    ...AllTasksPage_org\n    id\n  }\n  me {\n    ...AllTasksPage_me\n    id\n  }\n}\n\nfragment AllTasksPage_org on Org {\n  ...AllTasksHeaderBar_org\n  settings {\n    fiscalYearStart\n    id\n  }\n}\n\nfragment AllTasksPage_me on User {\n  ...AllTasksHeaderBar_me\n}\n\nfragment AllTasksHeaderBar_me on User {\n  teams {\n    edges {\n      node {\n        ...TaskFilters_teams\n        id\n      }\n    }\n  }\n}\n\nfragment TaskFilters_teams on Team {\n  id\n  name\n}\n\nfragment AllTasksHeaderBar_org on Org {\n  tags {\n    edges {\n      node {\n        ...TaskFilters_tags\n        id\n      }\n    }\n  }\n}\n\nfragment TaskFilters_tags on Tag {\n  id\n  color\n  name\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AllTasksQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AllTasksPage_org",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AllTasksPage_me",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllTasksQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tags",
            "storageKey": null,
            "args": null,
            "concreteType": "TagRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Tag",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "color",
                        "args": null,
                        "storageKey": null
                      },
                      v1
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fiscalYearStart",
                "args": null,
                "storageKey": null
              },
              v0
            ]
          },
          v0
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teams",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Team",
                    "plural": false,
                    "selections": [
                      v0,
                      v1
                    ]
                  }
                ]
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '493ce39ca4876e6a627739bae354b8dc';
module.exports = node;
