/* @flow */
import styled from 'styled-components';

const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  @media (max-width: 480px) {
    margin: 0 -16px;
  }
`;

export default DayContainer;
