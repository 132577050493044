/* @flow */

const taskFields: { [key: string]: string } = {
  title: 'Title',
  status: 'Status',
  assignees: 'Assignees',
  due_date: 'Due Datetime',
  subtasks: 'Subtasks',
  link: 'Link to Task',
};

export default taskFields;
