/* @flow */
import fullNameOfUser from 'utils/fullNameOfUser';
import getEnvironment from 'utils/getEnvironment';
import checkEnabled from './checkEnabled';

type UserInfoType = {
  dbId: number,
  firstName: string,
  lastName: string,
  email: string,
  org_id: number,
  org_name: string,
};

let userInfo: ?UserInfoType = null;

const TRACKING_PATHS: $ReadOnlyArray<RegExp> = [
  /^\/events\/[A-Za-z0-9-]*\/registration/, // Event Registration tab
];

const shouldRecord = (path: string) => TRACKING_PATHS.some(regexp => regexp.test(path));

const setFullStoryUser = () => {
  if (userInfo == null || !window.FS) return;

  const { dbId, firstName, lastName, ...userProps } = userInfo;
  // $FlowFixMe complains about inexact userProps spread
  window.FS.identify(dbId, { displayName: fullNameOfUser(userInfo), ...userProps });
};

const initFullStory = (path: string, ignorePathCheck: ?boolean = false) => {
  if (
    !window.FS &&
    getEnvironment() === 'production' &&
    checkEnabled() &&
    (ignorePathCheck || shouldRecord(path))
  ) {
    /* eslint-disable */
    window['_fs_debug'] = false;
    window['_fs_host'] = 'fullstory.com';
    window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
    window['_fs_org'] = '10CPSJ';
    window['_fs_namespace'] = 'FS';
    (function(m,n,e,t,l,o,g,y){
      if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
      // $FlowFixMe
      g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
      // $FlowFixMe
      o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
      // $FlowFixMe
      y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
      // $FlowFixMe
      g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
      // $FlowFixMe
      g.anonymize=function(){g.identify(!!0)};
      // $FlowFixMe
      g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
      // $FlowFixMe
      g.log = function(a,b){g("log",[a,b])};
      // $FlowFixMe
      g.consent=function(a){g("consent",!arguments.length||a)};
      // $FlowFixMe
      g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
      g.clearUserCookie=function(){};
      // $FlowFixMe
      g.setVars=function(n, p){g('setVars',[n,p]);};
      g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
      // $FlowFixMe
      if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
      g._v="1.3.0";
    })(window,document,window['_fs_namespace'],'script','user');
    /* eslint-enable */

    setFullStoryUser();
  }
};

const identifyFullStoryUser = (user: UserInfoType) => {
  userInfo = user;
  setFullStoryUser();
};

export { initFullStory, identifyFullStoryUser };
