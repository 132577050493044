/* @flow */
import React from 'react';

type Props = {
  scrollContainer: HTMLElement,
  onScrollToBottom: () => void,
  totalCount: number,
  pageSize: number,
};

export default class InfiniteScrollListener extends React.Component<Props> {
  componentDidMount() {
    this.props.scrollContainer.addEventListener('scroll', this.handleScroll);

    // Load more after initial loading when window height is too big for scrolling 25 items
    if (this.props.totalCount > this.props.pageSize) {
      this.handleScroll();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { scrollContainer, totalCount, pageSize } = this.props;
    if (scrollContainer !== prevProps.scrollContainer) {
      prevProps.scrollContainer.removeEventListener('scroll', this.handleScroll);
      scrollContainer.addEventListener('scroll', this.handleScroll);
    }

    // Load more after filtering when window height is too big for scrolling 25 items
    if (totalCount !== prevProps.totalCount && totalCount > pageSize) {
      this.handleScroll();
    }
  }

  componentWillUnmount() {
    this.props.scrollContainer.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { scrollHeight, scrollTop, offsetHeight } = this.props.scrollContainer;
    const pixelsLeft = scrollHeight - Math.ceil(scrollTop) - offsetHeight;
    if (pixelsLeft <= 1) {
      this.props.onScrollToBottom();
    }
  };

  render() {
    return null;
  }
}
