/* @flow */
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';

import { OPERATOR_VALUES } from 'components/material/Filters/FilterSelectorRow';

import staticFields from './staticFields';

const getFieldName = filterItem => {
  if (!filterItem.customField) {
    return staticFields[filterItem.fieldName || 'first_name'];
  }

  return filterItem.customField.customizableType === 'COMPANY'
    ? `Company - ${filterItem.customField.label}`
    : filterItem.customField.label;
};

type OrderedFiltersForViewType = {
  order: number,
  fieldName: string,
  operator: string,
  value: string,
};

export default function getSavedFiltersForView(
  filtersKeeper: any,
  tz: string,
  getFieldNameCalee?: (filter: any) => string,
): $ReadOnlyArray<OrderedFiltersForViewType> {
  if (filtersKeeper == null) {
    return [];
  }

  const fieldNameCalee = getFieldNameCalee || getFieldName;

  const savedFilters = [
    ...filtersKeeper.customSavedTextFilters.edges.map(({ node: filter }) => {
      const operator = OPERATOR_VALUES.find(item => item.value === filter.operator);
      return {
        order: filter.order,
        fieldName: fieldNameCalee(filter),
        operator: operator ? operator.label : '',
        value: filter && filter.values ? filter.values.join(', ') : '',
      };
    }),
    ...filtersKeeper.customSavedTextareaFilters.edges.map(({ node: filter }) => {
      const operator = OPERATOR_VALUES.find(item => item.value === filter.operator);
      return {
        order: filter.order,
        fieldName: fieldNameCalee(filter),
        operator: operator ? operator.label : '',
        value: filter && filter.values ? filter.values.join(', ') : '',
      };
    }),
    ...filtersKeeper.customSavedLinkFilters.edges.map(({ node: filter }) => {
      const operator = OPERATOR_VALUES.find(item => item.value === filter.operator);
      return {
        order: filter.order,
        fieldName: fieldNameCalee(filter),
        operator: operator ? operator.label : '',
        value: filter && filter.values && filter.values[0] ? filter.values[0] : '',
      };
    }),
    ...filtersKeeper.customSavedBooleanFilters.edges.map(({ node: filter }) => {
      const operator = OPERATOR_VALUES.find(item => item.value === 'equal');
      return {
        order: filter.order,
        fieldName: fieldNameCalee(filter),
        operator: operator ? operator.label : '',
        value: filter && filter.value ? 'Yes' : 'No',
      };
    }),
    ...filtersKeeper.customSavedDateFilters.edges.map(({ node: filter }) => {
      const startDate = filter.minValue != null ? moment.tz(filter.minValue, tz) : '';
      const endDate = filter.maxValue != null ? moment.tz(filter.maxValue, tz) : '';
      const sameDate = startDate && startDate.isSame(endDate);

      return {
        order: filter.order,
        fieldName: fieldNameCalee(filter),
        operator: sameDate ? 'is on' : 'is between',
        value: sameDate
          ? formatDate(startDate)
          : `${formatDate(startDate)} and ${formatDate(endDate)}`,
      };
    }),
    ...filtersKeeper.customSavedNumberFilters.edges.map(({ node: filter }) => {
      const minValue = filter.minValue != null ? filter.minValue : null;
      const maxValue = filter.maxValue != null ? filter.maxValue : null;
      const singleValueOperator = minValue != null ? 'is greater than' : 'is less than';

      return {
        order: filter.order,
        fieldName: fieldNameCalee(filter),
        operator: minValue != null && maxValue != null ? 'is between' : singleValueOperator,
        value: `${minValue != null ? minValue : ''} ${
          minValue != null && maxValue != null ? 'and' : ''
        } ${maxValue != null ? maxValue : ''}`,
      };
    }),
    ...filtersKeeper.customSavedCurrencyFilters.edges.map(({ node: filter }) => {
      const minValue = filter.minValue != null ? filter.minValue / 100 : null;
      const maxValue = filter.maxValue != null ? filter.maxValue / 100 : null;
      const singleValueOperator = minValue != null ? 'is greater than' : 'is less than';

      return {
        order: filter.order,
        fieldName: fieldNameCalee(filter),
        operator: minValue != null && maxValue != null ? 'is between' : singleValueOperator,
        value: `${minValue != null ? minValue : ''} ${
          minValue != null && maxValue != null ? 'and' : ''
        } ${maxValue != null ? maxValue : ''}`,
      };
    }),
    ...filtersKeeper.customSavedMultiselectFilters.edges.map(({ node: filter }) => {
      const operator = OPERATOR_VALUES.find(item => item.value === filter.operator);

      return {
        order: filter.order,
        fieldName: fieldNameCalee(filter),
        operator: operator ? operator.label : '',
        value: filter.options.edges.map(edge => edge.node.option.name).join(', '),
      };
    }),
    ...filtersKeeper.customSavedUserMultiselectFilters.edges.map(({ node: filter }) => {
      const operator = OPERATOR_VALUES.find(item => item.value === filter.operator);
      const optionValues = filter.options.edges
        .map(({ node }) => `${node.user.firstName} ${node.user.lastName}`)
        .join(', ');

      return {
        order: filter.order,
        fieldName: fieldNameCalee(filter),
        operator: operator ? operator.label : '',
        value: optionValues,
      };
    }),
  ];

  return sortBy(savedFilters, 'order');
}
