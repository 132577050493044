/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import type {
  PageComponentKindType,
  ReorderedPageComponentConfigType,
} from '../../pageComponentsConfig';
import type { SelectedComponent } from '../../RegistrationCreateContent';
import DesignSection from './DesignSection';
import PageComponents from './PageComponents';
import PageStyling from './PageStyling';

import type { Design_org } from './__generated__/Design_org.graphql';
import type { Design_registrationForm } from './__generated__/Design_registrationForm.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-top: 20px;
  background: #fff;
  overflow-y: auto;
  ${props =>
    props.ibmView &&
    css`
      padding-top: 25px;
      background-color: transparent;
    `}
`;

const Title = styled.div`
  padding-left: 25px;
  font-family: 'Roboto-Slab';
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  color: #383e56;
`;

class Design extends React.PureComponent<{
  registrationForm: Design_registrationForm,
  org: Design_org,
  showPageStyling: boolean,
  onMoveEnd: (reorderedPageComponentConfig?: ReorderedPageComponentConfigType) => void,
  onBeginDrag: (draggedNewComponentKind: PageComponentKindType) => void,
  onChangePageWidth: (width: number) => void,
  onChangeSelectedComponentProperty: (updatedProps: SelectedComponent) => void,
  onHoverPageComponent: (kind: PageComponentKindType) => void,
  onHoverOutPageComponent: () => void,
}> {
  renderPageComponents = () => {
    const {
      org,
      onMoveEnd,
      onBeginDrag,
      onHoverPageComponent,
      onHoverOutPageComponent,
      showPageStyling,
    } = this.props;
    const PageComponentsBlock = (
      <PageComponents
        org={org}
        onMoveEnd={onMoveEnd}
        onBeginDrag={onBeginDrag}
        onHoverPageComponent={onHoverPageComponent}
        onHoverOutPageComponent={onHoverOutPageComponent}
      />
    );
    return org.syncedToIbmWm ? (
      <>
        <Title>Form components</Title>
        {PageComponentsBlock}
      </>
    ) : (
      <DesignSection type="components" forceOpen={!showPageStyling}>
        {PageComponentsBlock}
      </DesignSection>
    );
  };

  render() {
    const {
      registrationForm,
      org,
      showPageStyling,
      onChangePageWidth,
      onChangeSelectedComponentProperty,
    } = this.props;
    return (
      <Container ibmView={org.syncedToIbmWm}>
        {this.renderPageComponents()}
        {!org.syncedToIbmWm && (
          <DesignSection type="styling" forceOpen={showPageStyling}>
            <PageStyling
              org={org}
              registrationForm={registrationForm}
              onChangeWidth={onChangePageWidth}
              onChangeSelectedComponentProperty={onChangeSelectedComponentProperty}
            />
          </DesignSection>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(Design, {
  registrationForm: graphql`
    fragment Design_registrationForm on RegistrationForm {
      ...PageStyling_registrationForm
    }
  `,
  org: graphql`
    fragment Design_org on Org {
      syncedToIbmWm
      ...PageComponents_org
      ...PageStyling_org
    }
  `,
});
