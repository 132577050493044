/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ImportLogsActionsColumn_importLog$ref: FragmentReference;
export type ImportLogsActionsColumn_importLog = {|
  +id: string,
  +originalFileUrl: string,
  +viewerCanRevert: boolean,
  +$refType: ImportLogsActionsColumn_importLog$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ImportLogsActionsColumn_importLog",
  "type": "ImportLog",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "originalFileUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanRevert",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '168324356537c4b0c02dc3704e9f8893';
module.exports = node;
