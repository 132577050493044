/* @flow */
import React from 'react';
import styled from 'styled-components';

import AttachmentPreview from 'components/RelayAttachments/AttachmentPreview';
import BriefRichText from '../components/BriefRichText';
import NotesUpdater from '../components/NotesUpdater';

import NotesUpdater_user from '../components/__generated__/NotesUpdater_user.graphql';

const Container = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid #cdd1d4;
  @media (max-width: 480px) {
    margin: 15px -16px 0;
    padding: 0 16px;
  }
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
`;

const NotesHeader = styled.div`
  margin-bottom: 5px;
  color: #4a5665;
  font-weight: 500;
  font-size: 17px;
`;

export type NoteType = {|
  +id: string,
  +name: string,
  +content: string,
  +updatedAt: any,
  +updater: ?NotesUpdater_user,
  +attachments: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +fileurl: ?string,
        +filename: ?string,
        +filetype: ?string,
        +filesize: ?number,
      |},
    |}>,
  |},
|};

export default class NoteItem extends React.PureComponent<
  { note: NoteType, tz: string },
  { previewId: ?string },
> {
  state = { previewId: null };

  handlePreview = (previewId: ?string) => {
    this.setState({ previewId });
  };

  render() {
    const { note, tz } = this.props;
    const images = note.attachments.edges.map(({ node }) => ({
      ...node,
      id: node.fileurl,
    }));
    return (
      <Container>
        <NotesHeader>{note.name}</NotesHeader>
        <NotesUpdater user={note.updater} updatedAt={note.updatedAt} tz={tz} />
        <BriefRichText
          defaultValue={note.content}
          disabled
          viewMode
          supportInlineImage={{
            onImagePreview: this.handlePreview,
          }}
        />
        {this.state.previewId && (
          <AttachmentPreview
            attachments={[]}
            mockAttachments={images}
            previewId={this.state.previewId}
            onPreview={this.handlePreview}
            onRemove={null}
          />
        )}
      </Container>
    );
  }
}
