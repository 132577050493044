/**
 * @flow
 * @relayHash f7eed8c455a77db0b38c817f2a223fb7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamMembersContainer_org$ref = any;
export type TeamMembersQueryVariables = {|
  teamSlug: string
|};
export type TeamMembersQueryResponse = {|
  +org: {|
    +$fragmentRefs: TeamMembersContainer_org$ref
  |},
  +team: {|
    +id: string
  |},
|};
export type TeamMembersQuery = {|
  variables: TeamMembersQueryVariables,
  response: TeamMembersQueryResponse,
|};
*/


/*
query TeamMembersQuery(
  $teamSlug: String!
) {
  org {
    ...TeamMembersContainer_org
    id
  }
  team(slug: $teamSlug) {
    id
  }
}

fragment TeamMembersContainer_org on Org {
  viewerCanManageUsers
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "team",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "teamSlug",
      "type": "String"
    }
  ],
  "concreteType": "Team",
  "plural": false,
  "selections": [
    v1
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamMembersQuery",
  "id": null,
  "text": "query TeamMembersQuery(\n  $teamSlug: String!\n) {\n  org {\n    ...TeamMembersContainer_org\n    id\n  }\n  team(slug: $teamSlug) {\n    id\n  }\n}\n\nfragment TeamMembersContainer_org on Org {\n  viewerCanManageUsers\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamMembersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TeamMembersContainer_org",
            "args": null
          }
        ]
      },
      v2
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamMembersQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageUsers",
            "args": null,
            "storageKey": null
          },
          v1
        ]
      },
      v2
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c854407318da347eceea2b949bb97f86';
module.exports = node;
