/* @flow */

const eventStaffFields: { [key: string]: string } = {
  avatar: 'Avatar',
  name: 'Name',
  company: 'Company',
  title: 'Title',
  email: 'Email',
  phone: 'Mobile Phone',
  office_phone: 'Office Phone',
  onsite: 'Based',
};

export default eventStaffFields;
