/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestFormsActionsColumn_requestForm$ref: FragmentReference;
export type EventRequestFormsActionsColumn_requestForm = {|
  +id: string,
  +isDraft: boolean,
  +$refType: EventRequestFormsActionsColumn_requestForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestFormsActionsColumn_requestForm",
  "type": "EventRequestForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDraft",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e20b3691a2610f57b4fd5ef33e7807f4';
module.exports = node;
