/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type VendorExpenseAmount_org$ref = any;
type VendorExpenseCategory_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorExpensesTableRenderer_org$ref: FragmentReference;
export type VendorExpensesTableRenderer_org = {|
  +$fragmentRefs: VendorExpenseCategory_org$ref & VendorExpenseAmount_org$ref,
  +$refType: VendorExpensesTableRenderer_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VendorExpensesTableRenderer_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "VendorExpenseCategory_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "VendorExpenseAmount_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '70fb818dfa8dcdc0ad85a9324cc66597';
module.exports = node;
