/* @flow */
import type { RecordProxy } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

export type Connection = {
  // Connection key specified via @connection directive
  key?: string,
  filters?: {},
  // Connection field name directly on record, needs explicit args if present on connection
  field?: string,
  args?: {},
};

function getConnection(parentRecord: RecordProxy, { key, filters, field, args }: Connection) {
  const connection: ?RecordProxy = key
    ? ConnectionHandler.getConnection(parentRecord, key, filters)
    : parentRecord.getLinkedRecord(field, args);

  return connection;
}

export default getConnection;
