/**
 * @flow
 * @relayHash 94b26d796eb29823ebd96de77de5b357
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SubtaskRow_subtask$ref = any;
type TaskRow_task$ref = any;
export type CreateDeliverablesInput = {
  clientMutationId?: ?string,
  eventId: string,
  names: $ReadOnlyArray<string>,
  due_date?: ?string,
  due_date_all_day?: ?boolean,
  assignees?: ?$ReadOnlyArray<?string>,
  folderId?: ?string,
  parentId?: ?string,
  fromWindow?: ?string,
};
export type createTasksMutationVariables = {|
  input: CreateDeliverablesInput
|};
export type createTasksMutationResponse = {|
  +createDeliverables: ?{|
    +deliverableEdges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +dbId: number,
        +name: string,
        +order: number,
        +folderId: ?string,
        +parent: ?{|
          +subtasksCount: number,
          +completedSubtasksCount: number,
        |},
        +event: {|
          +progress: ?number
        |},
        +viewerCanReorder: boolean,
        +$fragmentRefs: TaskRow_task$ref & SubtaskRow_subtask$ref,
      |}
    |}>
  |}
|};
export type createTasksMutation = {|
  variables: createTasksMutationVariables,
  response: createTasksMutationResponse,
|};
*/


/*
mutation createTasksMutation(
  $input: CreateDeliverablesInput!
) {
  createDeliverables(input: $input) {
    deliverableEdges {
      node {
        id
        dbId
        name
        order
        folderId
        parent {
          subtasksCount
          completedSubtasksCount
          id
        }
        event {
          progress
          id
        }
        viewerCanReorder
        ...TaskRow_task
        ...SubtaskRow_subtask
      }
    }
  }
}

fragment TaskRow_task on Deliverable {
  id
  name
  slug
  status
  dueDate
  dueDateAllDay
  folderId
  tags {
    edges {
      node {
        id
        ...InlineTags_tags
      }
    }
  }
  assignees {
    edges {
      node {
        id
        ...TaskRowAssignees_assignees
      }
    }
  }
  parent {
    slug
    id
  }
  subtasksCount
  completedSubtasksCount
  viewerCanUpdateTags
  viewerCanUpdateStatus
  viewerCanUpdateDueDate
  viewerCanReorder
  viewerCanAssign
  viewerCanDelete
  viewerCanSelect
  blockedByOtherTasks
}

fragment SubtaskRow_subtask on Deliverable {
  id
  name
  status
  order
  dueDate
  dueDateAllDay
  viewerCanUpdateDetails
  viewerCanUpdateStatus
  viewerCanUpdateDueDate
  viewerCanAssign
  viewerCanDelete
  assignees {
    edges {
      node {
        ...TaskRowAssignees_assignees
        id
      }
    }
  }
}

fragment TaskRowAssignees_assignees on User {
  id
  firstName
  lastName
  email
  ...UsersGroup_users
}

fragment UsersGroup_users on Assignable {
  id
  email
  firstName
  lastName
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}

fragment InlineTags_tags on Tag {
  id
  color
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDeliverablesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateDeliverablesInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "folderId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subtasksCount",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "completedSubtasksCount",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "progress",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanReorder",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createTasksMutation",
  "id": null,
  "text": "mutation createTasksMutation(\n  $input: CreateDeliverablesInput!\n) {\n  createDeliverables(input: $input) {\n    deliverableEdges {\n      node {\n        id\n        dbId\n        name\n        order\n        folderId\n        parent {\n          subtasksCount\n          completedSubtasksCount\n          id\n        }\n        event {\n          progress\n          id\n        }\n        viewerCanReorder\n        ...TaskRow_task\n        ...SubtaskRow_subtask\n      }\n    }\n  }\n}\n\nfragment TaskRow_task on Deliverable {\n  id\n  name\n  slug\n  status\n  dueDate\n  dueDateAllDay\n  folderId\n  tags {\n    edges {\n      node {\n        id\n        ...InlineTags_tags\n      }\n    }\n  }\n  assignees {\n    edges {\n      node {\n        id\n        ...TaskRowAssignees_assignees\n      }\n    }\n  }\n  parent {\n    slug\n    id\n  }\n  subtasksCount\n  completedSubtasksCount\n  viewerCanUpdateTags\n  viewerCanUpdateStatus\n  viewerCanUpdateDueDate\n  viewerCanReorder\n  viewerCanAssign\n  viewerCanDelete\n  viewerCanSelect\n  blockedByOtherTasks\n}\n\nfragment SubtaskRow_subtask on Deliverable {\n  id\n  name\n  status\n  order\n  dueDate\n  dueDateAllDay\n  viewerCanUpdateDetails\n  viewerCanUpdateStatus\n  viewerCanUpdateDueDate\n  viewerCanAssign\n  viewerCanDelete\n  assignees {\n    edges {\n      node {\n        ...TaskRowAssignees_assignees\n        id\n      }\n    }\n  }\n}\n\nfragment TaskRowAssignees_assignees on User {\n  id\n  firstName\n  lastName\n  email\n  ...UsersGroup_users\n}\n\nfragment UsersGroup_users on Assignable {\n  id\n  email\n  firstName\n  lastName\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n\nfragment InlineTags_tags on Tag {\n  id\n  color\n  name\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createTasksMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createDeliverables",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateDeliverablesPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverableEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Deliverable",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "parent",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Deliverable",
                    "plural": false,
                    "selections": [
                      v7,
                      v8
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "event",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      v9
                    ]
                  },
                  v10,
                  {
                    "kind": "FragmentSpread",
                    "name": "TaskRow_task",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "SubtaskRow_subtask",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createTasksMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createDeliverables",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateDeliverablesPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deliverableEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "DeliverableRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Deliverable",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "parent",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Deliverable",
                    "plural": false,
                    "selections": [
                      v7,
                      v8,
                      v2,
                      v11
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "event",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      v9,
                      v2
                    ]
                  },
                  v10,
                  v11,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dueDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dueDateAllDay",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "tags",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TagRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Tag",
                            "plural": false,
                            "selections": [
                              v2,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "color",
                                "args": null,
                                "storageKey": null
                              },
                              v4
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "assignees",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": [
                              v2,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "firstName",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "lastName",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "email",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "avatar",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  v7,
                  v8,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdateTags",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdateStatus",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdateDueDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanAssign",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanDelete",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanSelect",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "blockedByOtherTasks",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "viewerCanUpdateDetails",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e385d93d8217fe63187bf6fb1a1c0a5';
module.exports = node;
