/* @flow */
import qs from 'qs';

/**
 * Gets the data salesforce returns via a url with either `#` params on success
 * or `?` query string params on failure.
 * @param { string } url
 * @return { Object } - hash of query string params
 */
export default function parseCallbackUrl(urlString: string): { [string]: string } {
  // IE 11 doesn't support `URL` class so using this method instead
  const parser = document.createElement('a');
  parser.href = urlString;
  const searchParams = qs.parse(parser.search.replace('?', ''));
  const hashParams = qs.parse(parser.hash.replace('#', ''));
  return Object.assign({}, hashParams, searchParams);
}
