/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import type { MemberTeamCount_user } from './__generated__/MemberTeamCount_user.graphql';

type Props = {
  user: MemberTeamCount_user,
};

function MemberTeamCount({ user }: Props) {
  return user.memberships.totalCount;
}

export default createFragmentContainer(
  MemberTeamCount,
  graphql`
    fragment MemberTeamCount_user on User {
      memberships {
        totalCount
      }
    }
  `,
);
