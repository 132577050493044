/* @flow */
import React from 'react';
import styled from 'styled-components';

import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import isValidWebsite from 'utils/validators/isValidWebsite';

import VimeoIcon from 'images/registration/vimeo.svg';
import YoutubeIcon from 'images/registration/youtube.svg';
import TextField from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';

import { vimeoRegex, youtubeRegex } from '../../../RegistrationPageContent/convertVideoEmbedUrl';

const StyledTextField = styled(TextField)`
  margin: 15px 0 ${props => (props.error ? '10px' : '0')} 0;
  input {
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid ${props => (props.error ? props.theme.negativeActionColor : '#d9ddf5')};
    &:hover {
      border-color: ${props => (props.error ? props.theme.negativeActionColor : '#a5a9c2')};
    }
    &:focus {
      outline: 0;
      border-color: ${props => (props.error ? props.theme.negativeActionColor : '#a5a9c2')};
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin: 0 10px 0 auto;
`;

export default class VideoLink extends React.PureComponent<
  {
    value: string,
    onChange: (url: string) => void,
  },
  {
    url: string,
    error: ?string,
  },
> {
  state = {
    url: this.props.value != null ? this.props.value : '',
    error: null,
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const url = e.currentTarget.value.trim();
    if (this.state.url !== url) {
      this.setState({
        url,
      });
    }
  };

  handleBlur = () => {
    const url = this.state.url;
    if (!isValidWebsite(enforceHttpPrefix(url))) {
      this.setState({ error: 'Invalid url' });
      return;
    }
    if (!vimeoRegex.test(url) && !youtubeRegex.test(url)) {
      this.setState({ error: 'Only Youtube or Vimeo links are supported' });
      return;
    }
    this.props.onChange(url);
    if (this.state.error) {
      this.setState({ error: null });
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleBlur();
    }
  };

  render() {
    return (
      <>
        <StyledTooltip label="Youtube or Vimeo links are supported" placement="top">
          <YoutubeIcon />
        </StyledTooltip>
        <Tooltip label="Youtube or Vimeo links are supported" placement="top">
          <VimeoIcon />
        </Tooltip>
        <StyledTextField
          value={this.state.url}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          error={this.state.error}
          autoFocus
        />
      </>
    );
  }
}
