/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import COUNTRIES from 'config/countries.json';

import type { FieldType } from 'utils/customization/types';

import type { VendorFieldInput } from 'graph/mutations/vendor/updateVendor';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import VendorCountry_vendor from './__generated__/VendorCountry_vendor.graphql';

const countryOptions = COUNTRIES.map(country => ({ label: country, value: country }));

class VendorCountry extends React.Component<{
  vendor: VendorCountry_vendor,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<VendorFieldInput>) => Promise<void>,
}> {
  handleUpdate = (value: ?string) => {
    if (value !== this.props.vendor.country) {
      this.props.onUpdate({ country: value });
      this.props.updateColumnWidth();
    }
  };

  getError = (): ?string => {
    const { fieldSettings, vendor } = this.props;

    if (fieldSettings.required && vendor.country == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { vendor, fieldSettings, updateColumnWidth } = this.props;
    return (
      <InlineSelectField
        placeholder={fieldSettings.label}
        options={countryOptions}
        value={vendor.country || ''}
        onChange={this.handleUpdate}
        clearable={!fieldSettings.required}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        searchable
      />
    );
  }
}

export default createFragmentContainer(
  VendorCountry,
  graphql`
    fragment VendorCountry_vendor on Vendor {
      country
    }
  `,
);
