/* @flow */
import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
} from 'utils/routing/parseTypedQueryString';

export type ParsedImportLogsFilters = {
  sort: ?SortParam,
};

export default function parseImportLogsFilters(queryString: string): ParsedImportLogsFilters {
  return parseTypedQueryString(queryString, {
    sort: stringParamToSort,
  });
}
