/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import TAG_COLORS from 'config/tag_colors.json';

import EmptyLink from 'components/material/table/EmptyLink';
import TagsOverlay from 'components/material/Tags/TagsOverlay';
import Tooltip from 'components/material/Tooltip';

import type { InlineTags_orgTags } from './__generated__/InlineTags_orgTags.graphql';
import type { InlineTags_tags } from './__generated__/InlineTags_tags.graphql';

const Container = styled.div`
  position: relative;
`;

const Tag = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: ${props => TAG_COLORS[props.color]};

  ${props =>
    props.small &&
    css`
      height: 10px;
      margin: 2px 0;
    `};

  ${props =>
    props.big &&
    css`
      position: relative;
      width: 108px;
      height: 20px;

      &:after {
        content: attr(data-name);
        position: absolute;
        top: 0;
        left: 5px;
        right: 5px;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: ${props.theme.tagTextColor};
      }
    `};
`;

const Tags = styled.div`
  display: flex;

  ${props =>
    props.tableView &&
    css`
      padding-top: 4px;
    `};

  ${props =>
    props.multiline &&
    css`
      flex-wrap: wrap;
      padding-top: 0;
    `};
`;

const EmptyLinkContainer = styled.div`
  ${props =>
    props.tableView &&
    css`
      padding-top: 5px;
    `};
`;

const StyledTooltip = styled(Tooltip)`
  margin-right: 7px;
`;

class InlineTags extends React.PureComponent<
  {
    tags: InlineTags_tags,
    orgTags: InlineTags_orgTags,
    canManageTags?: boolean,
    onAddTag: string => void,
    onRemoveTag: string => void,
    disabled?: boolean,
    viewPage?: string,
  },
  {
    overlayShown: boolean,
  },
> {
  state = {
    overlayShown: false,
  };

  containerRef = React.createRef();

  handleClick = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleOverlayShow = () => {
    if (!this.props.disabled) {
      this.setState({ overlayShown: true });
    }
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  handleSelect = (tagId, isActive) => {
    if (isActive) {
      this.props.onRemoveTag(tagId);
    } else {
      this.props.onAddTag(tagId);
    }
  };

  render() {
    const tableCellView = this.props.viewPage && this.props.viewPage === 'table';
    return (
      <Container onClick={this.handleClick} ref={this.containerRef}>
        {this.props.tags.length > 0 ? (
          <Tags
            onClick={this.handleOverlayShow}
            multiline={this.props.tags.length > 5}
            tableView={tableCellView}
          >
            {this.props.tags.map(tag => (
              <StyledTooltip
                label={this.props.tags.length > 1 ? tag.name : null}
                key={tag.id}
                placement="top"
              >
                <Tag
                  color={tag.color}
                  small={this.props.tags.length > 5}
                  big={this.props.tags.length === 1}
                  data-name={tag.name}
                />
              </StyledTooltip>
            ))}
          </Tags>
        ) : (
          !this.props.disabled && (
            <EmptyLinkContainer tableView={tableCellView}>
              <EmptyLink
                label="Apply Tag"
                icon="tag"
                onClick={this.handleOverlayShow}
                highlighted={this.state.overlayShown}
              />
            </EmptyLinkContainer>
          )
        )}
        <TagsOverlay
          target={this.containerRef.current}
          container={tableCellView ? document.body : this.containerRef.current}
          onHide={this.handleOverlayHide}
          show={this.state.overlayShown}
          tags={this.props.orgTags}
          activeTags={this.props.tags.map(tag => tag.id)}
          canManageTags={this.props.canManageTags}
          onSelect={this.handleSelect}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(InlineTags, {
  tags: graphql`
    fragment InlineTags_tags on Tag @relay(plural: true) {
      id
      color
      name
    }
  `,
  orgTags: graphql`
    fragment InlineTags_orgTags on Tag @relay(plural: true) {
      id
      ...TagsOverlay_tags
    }
  `,
});
