/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type {
  createBudgetCategoryMutationVariables,
  createBudgetCategoryMutationResponse,
} from './__generated__/createBudgetCategoryMutation.graphql';

const mutation = graphql`
  mutation createBudgetCategoryMutation($input: CreateBudgetCategoryInput!) {
    createBudgetCategory(input: $input) {
      budgetCategoryEdge {
        __typename
        node {
          id
          name
          description
        }
      }
    }
  }
`;

export default function createBudgetCategory(args: {
  orgId: string,
  name: string,
  description?: ?string,
}) {
  const variables: createBudgetCategoryMutationVariables = {
    input: {
      name: args.name,
      description: args.description,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: args.orgId,
      edgeName: 'budgetCategoryEdge',
      connections: [{ field: 'budgetCategories' }],
    }),
  }).then((response: createBudgetCategoryMutationResponse) => {
    if (response.createBudgetCategory && response.createBudgetCategory.budgetCategoryEdge.node) {
      return response.createBudgetCategory.budgetCategoryEdge.node.id;
    }
    throw new Error('No category Id');
  });
}
