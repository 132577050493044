/* @flow */
import moment from 'moment-timezone';
import { type FiscalDateRange, getYearStart, getYearEnd } from '.';

export default function getFiscalYear(
  yearIndex: number,
  startMonth: number,
  date: moment = moment(),
): FiscalDateRange {
  const yearStart = getYearStart(startMonth, date).add(yearIndex, 'year');
  const yearEnd = getYearEnd(startMonth, date).add(yearIndex, 'year');
  return {
    label: `FY ${yearEnd.year()}`,
    key: `FY${yearIndex}`,
    start: yearStart,
    end: yearEnd,
  };
}
