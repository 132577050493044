/* @flow */
import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  line-height: 1.4;
  color: ${props => props.theme.primaryActionColor};
  i {
    margin-right: 5px;
  }
`;

export default class NewParticipantRow extends React.PureComponent<{
  label: string,
  onClick: (e: SyntheticEvent<HTMLElement>) => void,
}> {
  render() {
    return (
      <Row onClick={this.props.onClick}>
        <i className="fa fa-plus fa-fw" />
        {this.props.label}
      </Row>
    );
  }
}
