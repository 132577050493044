/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import formatDateTimeISO from 'utils/date/formatDateTimeISO';

import { type CellPropsType } from 'components/budget/Table';

import { type EventCellPropsType } from './index';

import type { EventAttendanceLeaveTime_contactEvent } from './__generated__/EventAttendanceLeaveTime_contactEvent.graphql';

const EventAttendanceLeaveTime = ({
  contactEvent,
}: CellPropsType<EventCellPropsType<EventAttendanceLeaveTime_contactEvent>>) => {
  const leaveTime =
    (contactEvent.zoomRegistrant &&
      (contactEvent.zoomRegistrant.times.slice(-1)[0] || {}).endTime) ||
    (contactEvent.g2wRegistrant && (contactEvent.g2wRegistrant.times.slice(-1)[0] || {}).endTime);

  if (!leaveTime) return null;

  return formatDateTimeISO(moment(leaveTime).tz(contactEvent.node.tz));
};

export default createFragmentContainer(
  EventAttendanceLeaveTime,
  graphql`
    fragment EventAttendanceLeaveTime_contactEvent on ContactEventEdge {
      zoomRegistrant {
        times {
          startTime
          endTime
        }
      }
      g2wRegistrant {
        times {
          startTime
          endTime
        }
      }
      node {
        tz
      }
    }
  `,
);
