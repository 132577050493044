/* @flow */
import { graphql } from 'react-relay';

import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type InputVariableFilters } from 'components/Contacts/contactsTableColumnSettings';

const mutation = graphql`
  mutation addContactAssignmentsMutation(
    $input: AddContactAssignmentsInput!
    $filters: ContactFilters
    $hasFilters: Boolean!
  ) {
    addContactAssignments(input: $input) {
      contactEventEdges {
        ...ContactEventsList_contactEvents @skip(if: $hasFilters)
        node {
          id
          eventContacts @include(if: $hasFilters) {
            totalCount
          }
          companies @include(if: $hasFilters) {
            totalCount
          }
          ...ContactsTablePagination_totalCountEvent @include(if: $hasFilters)
          contactRegistrationsTotal
        }
      }
      eventContactEdges {
        ...ContactRegistrationStatus_eventContact
        ...ContactAttendanceStatus_eventContact
        ...ContactAttendanceJoinTime_eventContact
        ...ContactAttendanceLeaveTime_eventContact
        ...ContactAttendanceDuration_eventContact
        ...ContactMembershipSyncStatus_eventContact
        ...ContactMembershipLastSynced_eventContact
        node {
          id
          events @skip(if: $hasFilters) {
            totalCount
          }
          ...ContactTitle_contact @include(if: $hasFilters)
          ...ContactPhone_contact @include(if: $hasFilters)
          ...ContactPersonType_contact @include(if: $hasFilters)
          ...ContactName_contact @include(if: $hasFilters)
          ...ContactSalesforceSyncAs_contact @include(if: $hasFilters)
          ...ContactCompany_contact @include(if: $hasFilters)
          ...ContactEmail_contact @include(if: $hasFilters)
          ...ContactWebsite_contact @include(if: $hasFilters)
          ...ContactTwitter_contact @include(if: $hasFilters)
          ...ContactLinkedin_contact @include(if: $hasFilters)
          ...ContactOwner_contact @include(if: $hasFilters)
          ...ContactUpdater_contact @include(if: $hasFilters)
          ...ContactUpdatedAt_contact @include(if: $hasFilters)
          ...ContactUpdatedMethod_contact @include(if: $hasFilters)
          ...ContactCreator_contact @include(if: $hasFilters)
          ...ContactCreatedAt_contact @include(if: $hasFilters)
          ...ContactCreatedMethod_contact @include(if: $hasFilters)
          ...ContactLastSynced_contact @include(if: $hasFilters)
          ...ContactSyncStatus_contact @include(if: $hasFilters)
          ...ContactSalesforceId_contact @include(if: $hasFilters)
          ...ContactMarketoId_contact @include(if: $hasFilters)
          ...ContactStreet_contact @include(if: $hasFilters)
          ...ContactCity_contact @include(if: $hasFilters)
          ...ContactState_contact @include(if: $hasFilters)
          ...ContactZip_contact @include(if: $hasFilters)
          ...ContactCountry_contact @include(if: $hasFilters)
          ...ContactDescription_contact @include(if: $hasFilters)
          ...ContactEmailOptIn_contact @include(if: $hasFilters)
          ...ContactHotLead_contact @include(if: $hasFilters)
          ...CustomizableText_customizable @include(if: $hasFilters)
          ...CustomizableTextarea_customizable @include(if: $hasFilters)
          ...CustomizableLink_customizable @include(if: $hasFilters)
          ...CustomizableDate_customizable @include(if: $hasFilters)
          ...CustomizableBoolean_customizable @include(if: $hasFilters)
          ...CustomizableNumber_customizable @include(if: $hasFilters)
          ...CustomizableCurrency_customizable @include(if: $hasFilters)
          ...CustomizableSelect_customizable @include(if: $hasFilters)
          ...CustomizableMultiselect_customizable @include(if: $hasFilters)
          ...CustomizableUserSelect_customizable @include(if: $hasFilters)
          ...CustomizableUserMultiselect_customizable @include(if: $hasFilters)
          ...ContactActionCell_contact @include(if: $hasFilters)
          ...ContactRequestSubmissionsCount_contact @include(if: $hasFilters)
          ...ContactEventsCount_contact @include(if: $hasFilters)
          ...ContactRegistrationStatus_contact @include(if: $hasFilters)
          ...ContactAttendanceStatus_contact @include(if: $hasFilters)
        }
      }
    }
  }
`;

export default function addContactAssignments(
  eventIds: $ReadOnlyArray<string>,
  contacts: $ReadOnlyArray<{
    contactId: string,
    registrationStatusId?: ?string,
    attendanceStatusId?: ?string,
  }>,
  fromWindow: CreateContactFromWindow,
  filters?: InputVariableFilters,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        eventIds,
        contacts,
        fromWindow,
      },
      filters,
      hasFilters: !!filters,
    },
    updater: mergeUpdaters(
      ...eventIds.map(eventId =>
        addRecordToConnection({
          parentId: eventId,
          edgeName: 'eventContactEdges',
          connections: [{ key: 'ContactsTablePagination_eventContacts' }],
        }),
      ),
      ...contacts.map(contact =>
        addRecordToConnection({
          parentId: contact.contactId,
          edgeName: 'contactEventEdges',
          connections: [{ key: 'ContactEventsList_contactEvents' }],
        }),
      ),
    ),
  });
}
