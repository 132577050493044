/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type History, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import setEventMarketoProgram from 'graph/mutations/integration/setEventMarketoProgram';
import removeCampaignFromEvent from 'graph/mutations/salesforce/removeCampaignFromEvent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import IntegrationMessage from 'components/integrations/IntegrationsSettings/IntegrationMessage';
import MarketoProgramPicker from 'components/integrations/MarketoProgramPicker';
import Button from 'components/material/Button';

import EventCRMSyncedContacts from './EventCRMSyncedContacts';
import SalesforceCampaignConnector from './SalesforceCampaignConnector';
import SalesforceCampaignInfo from './SalesforceCampaignInfo';
import SalesforceSyncStatusInfo from './SalesforceSyncStatusInfo';

import type { CRM_event } from './__generated__/CRM_event.graphql';
import type { CRM_org } from './__generated__/CRM_org.graphql';

const ConnectedCampaign = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 15px;
  margin: 25px 0;
  border: solid #eaebec;
  border-width: 1px 0;
`;

const StyledButton = styled(Button)`
  flex: 0 0 121px;
  @media (max-width: 1060px) {
    margin-top: 20px;
  }
`;

const PickerContainer = styled.div`
  max-width: 380px;
`;

const SyncedContactsContainer = styled.div`
  &:not(:empty) {
    margin-top: 25px;
    border-top: 1px solid #d8d8d8;
  }
`;

class CRM extends React.PureComponent<{
  event: CRM_event,
  org: CRM_org,
  history: History,
}> {
  handleRemoveCampaign = () => {
    removeCampaignFromEvent({
      eventId: this.props.event.id,
    }).catch(showModernMutationError);
  };

  handleOpenOpportunitiesSync = () => {
    this.props.history.push('/settings/integrations/salesforce');
  };

  render() {
    const org = this.props.org;
    const salesforceConnected = !!org.salesforceAccount;
    const marketoConnected = !!org.marketoAccount;

    const contactsSyncEnabled =
      marketoConnected || (org.salesforceAccount && org.salesforceAccount.contactsSyncEnabled);

    const salesforceCampaignConnected = !!this.props.event.salesforceCampaign;

    if (marketoConnected) {
      return (
        <>
          <PickerContainer>
            <MarketoProgramPicker
              marketoProgram={this.props.event.marketoProgram}
              org={org}
              onSelect={marketoProgram => {
                setEventMarketoProgram({
                  eventId: this.props.event.id,
                  marketoProgram,
                }).catch(showModernMutationError);
              }}
            />
          </PickerContainer>

          {this.props.event.marketoProgram && (
            <SyncedContactsContainer>
              <EventCRMSyncedContacts event={this.props.event} />
            </SyncedContactsContainer>
          )}
        </>
      );
    }

    if (!salesforceConnected) {
      return (
        <IntegrationMessage>
          Go to <Link to="/settings/integrations">Integrations</Link> to enable.
        </IntegrationMessage>
      );
    }

    if (!salesforceCampaignConnected) {
      return <SalesforceCampaignConnector eventId={this.props.event.id} />;
    }

    return (
      <div>
        <SalesforceSyncStatusInfo event={this.props.event} />

        <ConnectedCampaign>
          <SalesforceCampaignInfo campaign={this.props.event.salesforceCampaign} />

          <StyledButton label="Disconnect" negative outline onClick={this.handleRemoveCampaign} />
        </ConnectedCampaign>
        {contactsSyncEnabled && <EventCRMSyncedContacts event={this.props.event} />}
      </div>
    );
  }
}

export default createFragmentContainer(withRouter(CRM), {
  event: graphql`
    fragment CRM_event on Event {
      id
      ...SalesforceSyncStatusInfo_event
      ...EventCRMSyncedContacts_event
      salesforceCampaign {
        id
        ...SalesforceCampaignInfo_campaign
      }
      marketoProgram {
        marketoId
        name
        url
      }
    }
  `,
  org: graphql`
    fragment CRM_org on Org {
      id
      ...MarketoProgramPicker_org
      salesforceAccount {
        contactsSyncEnabled
      }
      marketoAccount {
        id
      }
      settings {
        currency
      }
    }
  `,
});
