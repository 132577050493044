/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Tooltip from 'components/material/Tooltip';

import { type IntegrationSettings } from '.';
import IntegrationSettingsIcon from './IntegrationSettingsIcon';

const Card = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 65px;
  margin: 0 10px 10px 0;
  padding: 10px;
  border: 1px solid #dadada;
  border-radius: 4px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  background: #fff;
  overflow: hidden;

  &[href] {
    cursor: pointer;

    &:hover {
      background-color: #f2fbff;
      border-color: #bce6f9;
    }
  }

  &:not([href]) {
    cursor: default;

    > * {
      opacity: 0.6;
    }

    ${props =>
      props.comingSoon &&
      css`
        &:after {
          content: 'Coming Soon';
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 2px 18px;
          border-radius: 3px 0 0 0;
          font-size: 13px;
          font-style: italic;
          background-image: linear-gradient(to bottom, #5e7bfe 5%, #ae80fc 134%);
          color: #fff;
        }
      `}
  }
`;

const CardName = styled.div`
  margin: 3px 0 0 10px;
  font-size: 15px;
  font-weight: 500;
  color: #3e4859;

  ${props =>
    props.connected &&
    css`
      &:after {
        content: 'Connected';
        display: block;
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        color: #595a5d;
      }
    `}

  ${props =>
    props.pending &&
    css`
      &:after {
        content: 'Pending...';
      }
    `}

  ${props =>
    props.expired &&
    css`
      &:after {
        content: 'Authentication Expired';
        color: #ff7980;
      }
    `}
`;

const IntegrationSettingsCard = ({ integration }: { integration: IntegrationSettings }) => {
  return (
    <Tooltip label={integration.disabledInfo}>
      <Card
        key={integration.name}
        as={!integration.disabledInfo && integration.route ? Link : null}
        to={integration.route && `/settings/integrations/${integration.route}`}
        comingSoon={!integration.route}
        style={{ width: `${integration.cardWidth ?? 190}px` }}
      >
        <IntegrationSettingsIcon integration={integration} size={30} />

        <CardName
          connected={integration.connected}
          expired={integration.expired}
          pending={integration.pending}
        >
          {integration.name}
        </CardName>
      </Card>
    </Tooltip>
  );
};

export default IntegrationSettingsCard;
