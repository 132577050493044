/* @flow */

import React from 'react';
import { type RouterHistory } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import UIActions from 'actions/UIActions';
import ErrorView from 'components/ErrorView';

export default class NotFound extends React.Component<{}> {
  componentDidUpdate() {
    // TODO: don't control this component via Alt
    UIActions.hideErrorPage.defer();
  }

  render() {
    // HACK: pretend this is a bad GraphQL request
    const error = {
      message: "Couldn't find this page.",
    };
    return (
      <DocumentTitle title="Not Found (404) Error">
        <ErrorView error={error} />
      </DocumentTitle>
    );
  }
}
