/* @flow */
import React from 'react';
import styled from 'styled-components';

import LinkIcon from 'images/link.svg';
import { LinkPanel } from 'components/panels/LinkPanel';

import Detail from './Detail';

const StyledLinkIcon = styled(LinkIcon)`
  flex: 0 0 auto;
  margin-right: 5px;
`;

export default class EventWebsite extends React.Component<{
  value: ?string,
  onChange: () => void,
  disable?: boolean,
}> {
  panel: LinkPanel;

  handleDetailClick = () => {
    if (!this.props.disable) {
      this.panel.show();
    }
  };

  render() {
    return (
      <div className="event-website">
        <Detail
          text={this.props.value}
          placeholder="Add website"
          link={this.props.value}
          icon={<StyledLinkIcon />}
          onClick={this.handleDetailClick}
        />
        <LinkPanel
          ref={panel => {
            this.panel = panel;
          }}
          value={this.props.value}
          onSave={this.props.onChange}
          title="Event Website"
          modalOnMobile
        />
      </div>
    );
  }
}
