/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory, withRouter } from 'react-router-dom';
import lodashOmit from 'lodash/omit';

import updateEventRequestForm from 'graph/mutations/eventRequest/updateEventRequestForm';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { type FormDetailsType } from 'components/EventRequestForm/lib/types';
import RequestForm from 'components/EventRequestForm/RequestForm';

import EventRequestFormBuilderFooter from './EventRequestFormBuilderFooter';

import type { EventRequestFormBuilderPreview_requestForm } from './__generated__/EventRequestFormBuilderPreview_requestForm.graphql';
import type { EventRequestFormBuilderSettings_org } from './__generated__/EventRequestFormBuilderSettings_org.graphql';

class EventRequestFormBuilderPreview extends React.PureComponent<
  {
    org: EventRequestFormBuilderSettings_org,
    requestForm: EventRequestFormBuilderPreview_requestForm,
    editingRequestForm: FormDetailsType,
    history: RouterHistory,
    onPrevStep: () => void,
  },
  {
    loading: boolean,
  },
> {
  state = {
    loading: false,
  };

  handleNext = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true });

    const requestFormInput = {
      ...this.props.editingRequestForm,
      isDraft: false,
    };

    const requestPromise = updateEventRequestForm(
      this.props.requestForm.id,
      lodashOmit(requestFormInput, 'id'),
    );

    requestPromise
      .then(() => {
        this.props.history.push('/settings/events/requests');
      })
      .catch(error => {
        this.setState({ loading: false });

        showModernMutationError(error);
      });
  };

  render() {
    return (
      <>
        <RequestForm
          requestForm={this.props.requestForm}
          org={this.props.org}
          me={null}
          ssoUser={null}
          requestSubmission={null}
        />
        <EventRequestFormBuilderFooter
          activeStepIndex={3}
          onNextStep={this.handleNext}
          onPrevStep={this.props.onPrevStep}
          loading={this.state.loading}
          errors={([]: $ReadOnlyArray<string>)}
        />
      </>
    );
  }
}

export default createFragmentContainer(
  withRouter(EventRequestFormBuilderPreview),
  graphql`
    fragment EventRequestFormBuilderPreview_org on Org {
      ...RequestForm_org
    }

    fragment EventRequestFormBuilderPreview_requestForm on EventRequestForm {
      id
      ...RequestForm_requestForm
    }
  `,
);
