/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Participants, { type Participant } from 'components/Participants';

import TaskSection from './TaskSection';
import type { TaskVendors_task } from './__generated__/TaskVendors_task.graphql';

const Container = styled.div`
  padding-left: 2px;
  max-width: 445px;
`;

class TaskVendors extends React.PureComponent<{
  task: TaskVendors_task,
  onAddVendor: (vendorId: string) => void,
  onRemoveVendor: (vendorId: string) => void,
}> {
  handleVendorSelect = (vendor: ?Participant) => {
    if (vendor) {
      this.props.onAddVendor(vendor.id);
    }
  };

  handleVendorRemove = (vendor: ?Participant) => {
    if (vendor) {
      this.props.onRemoveVendor(vendor.id);
    }
  };

  render() {
    const {
      task: { event, viewerCanUpdateContact, vendors },
    } = this.props;
    const autoFocus = vendors.edges.length === 0 && vendors.edges.length === 0;
    const vendorParticipants = vendors.edges.filter(Boolean).map(({ node: vendor }) => ({
      ...vendor,
      profileLink: `/tasks/${this.props.task.event.slug}/vendors/${vendor.id}/${
        this.props.task.slug
      }`,
    }));
    return (
      <TaskSection title="Vendors" disableAdd>
        <Container>
          <Participants
            eventId={event.id}
            vendors={vendorParticipants}
            onAddVendor={this.handleVendorSelect}
            onRemoveVendor={this.handleVendorRemove}
            disabled={!viewerCanUpdateContact}
            searchBarWidth={304}
            defaultSearch="vendors"
            fromWindow="task"
            autoFocus={autoFocus}
          />
        </Container>
      </TaskSection>
    );
  }
}

export default createFragmentContainer(
  TaskVendors,
  graphql`
    fragment TaskVendors_task on Deliverable {
      viewerCanUpdateContact
      slug
      vendors {
        edges {
          node {
            id
            name
          }
        }
      }
      event {
        id
        slug
      }
    }
  `,
);
