/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import ConfirmationWindow from 'components/ConfirmationWindow';
import Tooltip from 'components/material/Tooltip';

import TaskExportCalendar from './TaskExportCalendar';

import type { TaskHeader_task } from './__generated__/TaskHeader_task.graphql';

const Header = styled.div`
  height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  white-space: nowrap;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 0 0 10px;
    height: 40px;
  }
`;

const Action = styled.i`
  font-size: 17px;
  color: ${props =>
    props.active ? props.theme.secondaryActionDarkerColor : props.theme.secondaryActionColor};
  flex-shrink: 0;
  ${props =>
    !props.disabled &&
    `
    cursor: pointer;
    &:hover {
      color: ${props.theme.secondaryActionDarkerColor};
    }
  `};
`;

const ActionTooltip = styled(Tooltip)`
  margin-left: 8px;
`;

const CloseIcon = styled.div`
  width: 40px;
  text-align: center;
  margin-left: 5px;
  border-left: 1px solid ${props => props.theme.borderColor};
  height: 100%;
  line-height: 40px;
  display: none;
  @media (${props => props.theme.mobileOnly}) {
    display: block;
  }
`;

class TaskHeader extends React.PureComponent<
  {
    task: TaskHeader_task,
    onFollow: () => void,
    onUnfollow: () => void,
    onRemove: () => void,
    onWindowHide: () => void,
  },
  {
    showConfirmation: boolean,
  },
> {
  state = {
    showConfirmation: false,
  };

  handleToggleFollowing = () => {
    if (this.props.task.following) {
      this.props.onUnfollow();
    } else {
      this.props.onFollow();
    }
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  renderTaskExportCalendar = (onClick: () => void) => (
    <Action className="fa fa-fw fa-calendar-plus-o" onClick={onClick} />
  );

  render() {
    return (
      <Header>
        <ActionTooltip label={this.props.task.following ? 'Following' : 'Follow'} placement="top">
          <Action
            onClick={this.handleToggleFollowing}
            className="fa fa-fw fa-rss"
            active={this.props.task.following}
          />
        </ActionTooltip>
        <ActionTooltip
          label={this.props.task.dueDate ? 'Send to external Calendar' : 'Add due date to enable'}
          placement="top"
        >
          {this.props.task.dueDate ? (
            <TaskExportCalendar task={this.props.task} render={this.renderTaskExportCalendar} />
          ) : (
            <Action className="fa fa-fw fa-calendar-plus-o" disabled />
          )}
        </ActionTooltip>

        {this.props.task.viewerCanDelete && (
          <ActionTooltip label="Delete" placement="top">
            <Action className="fa fa-fw fa-trash" onClick={this.handleShowConfirmation} />
          </ActionTooltip>
        )}
        <CloseIcon onClick={this.props.onWindowHide}>
          <Action className="fa fa-fw fa-times" />
        </CloseIcon>

        {this.state.showConfirmation && (
          <ConfirmationWindow
            onHide={this.handleHideConfirmation}
            onConfirm={this.props.onRemove}
            title="Are you sure?"
            message="Once you delete this Task, it's gone for good."
          />
        )}
      </Header>
    );
  }
}

export default createFragmentContainer(
  TaskHeader,
  graphql`
    fragment TaskHeader_task on Deliverable {
      following
      dueDate
      viewerCanDelete
      ...TaskExportCalendar_task
    }
  `,
);
