/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import COUNTRIES from 'config/countries.json';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import ContactCountry_contact from './__generated__/ContactCountry_contact.graphql';

const countryOptions = COUNTRIES.map(country => ({ label: country, value: country }));

class ContactCountry extends React.PureComponent<{
  contact: ContactCountry_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => void,
}> {
  getError = (): ?string => {
    const { fieldSettings, contact } = this.props;
    if (!fieldSettings.required) {
      return null;
    }
    if (fieldSettings.required && contact.country == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { fieldSettings, contact, updateColumnWidth } = this.props;

    return (
      <InlineSelectField
        placeholder={fieldSettings.label}
        options={countryOptions}
        value={contact.country}
        onChange={country => {
          this.props.onUpdate({ country });
          this.props.updateColumnWidth();
        }}
        updateColumnWidth={updateColumnWidth}
        getError={this.getError}
        disabled={fieldSettings.restrictChangingValue}
        searchable
      />
    );
  }
}

export default createFragmentContainer(
  ContactCountry,
  graphql`
    fragment ContactCountry_contact on Contact {
      country
    }
  `,
);
