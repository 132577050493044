/* @flow */
import React from 'react';
import type { RelayRefetchProp } from 'react-relay';
import { createRefetchContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';

import ClearIcon from 'components/ClearIcon';
import CheckBox from 'components/material/CheckBox';
import TextInput from 'components/material/TextInput';

import type { EventsFilterContainer_query } from './__generated__/EventsFilterContainer_query.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const TextWrapper = styled.div`
  display: flex;
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  flex: 1 1 auto;
  margin-top: 10px;
  padding: 0 27px 0 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Main = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
  margin-left: 10px;
`;

const StyledCheckBox = styled(CheckBox)`
  flex: 1 1 auto;
  margin-top: 16px;
  &:last-of-type {
    margin-bottom: 1px;
  }
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: ${props => props.theme.darkestTextColor};
  > div:first-of-type {
    color: ${props => (props.checked ? props.theme.highlightColor : props.theme.iron)};
  }
`;

type EventType = $Shape<{|
  id: string,
  name: string,
|}>;

class EventsFilterContainer extends React.Component<
  {
    activeValues: ?$ReadOnlyArray<string>,
    name: string,
    onChange: (name: string, activeValues: ?$ReadOnlyArray<string>, query: string) => void,
    query: EventsFilterContainer_query,
    relay: RelayRefetchProp,
  },
  { query: string },
> {
  state = { query: '' };

  cachedEvents: ?$ReadOnlyArray<EventType> = null;

  fetchEvents = throttle((query: string) => {
    this.props.relay.refetch(
      {
        query,
        eventIds: this.activeValues(),
        listType: !query ? 'UPCOMING' : null,
      },
      null,
    );
  }, 800);

  handleOptionChange: (boolean, string) => void = (checked, value) => {
    const activeValues = this.activeValues();
    const values = checked ? [...activeValues, value] : activeValues.filter(s => s !== value);
    this.props.onChange(this.props.name, values, this.state.query);
  };

  handleQueryChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ query: e.currentTarget.value });
    this.fetchEvents(e.currentTarget.value);
  };

  handleClear = () => {
    this.setState({ query: '' });
    this.fetchEvents('');
  };

  activeValues(): $ReadOnlyArray<string> {
    return this.props.activeValues || [];
  }

  renderEventRow = (event: EventType) => {
    return (
      <Row>
        <Main>{event.name}</Main>
      </Row>
    );
  };

  render() {
    this.cachedEvents = this.props.query
      ? sortBy(
          uniqBy(
            [
              ...(this.props.query.selectedEvents || []),
              ...this.props.query.org.events.edges.map(edge => edge.node),
            ],
            'id',
          ),
          event => event != null && event.name != null && event.name.toLowerCase(),
        )
      : this.cachedEvents;

    return (
      <Container>
        <TextWrapper>
          <StyledTextInput
            placeholder="Search event"
            autoFocus
            value={this.state.query}
            onChange={this.handleQueryChange}
          />
          {this.state.query && <ClearIcon className="fa fa-times" onClick={this.handleClear} />}
        </TextWrapper>
        {(this.cachedEvents || []).map((option: EventType) => (
          <StyledCheckBox
            key={option.id}
            labelRenderer={() => this.renderEventRow(option)}
            checked={this.activeValues().includes(option.id)}
            onChange={checked => {
              this.handleOptionChange(checked, option.id);
            }}
          />
        ))}
      </Container>
    );
  }
}

export default createRefetchContainer(
  EventsFilterContainer,
  graphql`
    fragment EventsFilterContainer_query on Query {
      org {
        events(first: 10, query: $query, listType: $listType) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      selectedEvents: nodes(ids: $eventIds) {
        id
        ... on Event {
          id
          name
        }
      }
    }
  `,
  graphql`
    query EventsFilterContainerRefetchQuery(
      $query: String!
      $eventIds: [ID!]!
      $listType: EventListEnum
    ) {
      query {
        ...EventsFilterContainer_query
      }
    }
  `,
);
