/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { RouterLocation } from 'react-router';
import { type Match } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { Content } from 'components/page/Content';

import InformationRoutes from './InformationRoutes';
import InformationSubmenu from './InformationSubmenu';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LeftContent = styled.div`
  flex: 0 0 254px;
`;

const RightContent = styled.div`
  flex: 1 0 200px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 5px 20px 20px 0;
  }
`;

const query = graphql`
  query InformationQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...InformationSubmenu_event
    }
    org {
      ...InformationSubmenu_org
    }
  }
`;

export default class Information extends React.Component<{
  match: Match,
  location: RouterLocation,
}> {
  render() {
    return (
      <Content>
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug: this.props.match.params.event_slug }}
          renderSuccess={props => (
            <Container>
              <LeftContent>
                <InformationSubmenu
                  org={props.org}
                  event={props.event}
                  location={this.props.location}
                />
              </LeftContent>
              <RightContent>
                <InformationRoutes
                  pathPrefix={this.props.match.path}
                  key={this.props.location.pathname}
                />
              </RightContent>
            </Container>
          )}
        />
      </Content>
    );
  }
}
