/* @flow */
import { type SalesforceKindType } from '../__generated__/SalesforceMapping_org.graphql';

type SalesforceObjectLabelMappingType = {
  [SalesforceKindType]: string,
};

const salesforceObjectLabelMapping: SalesforceObjectLabelMappingType = {
  EVENT: 'Campaign',
  CONTACT: 'Contact',
  LEAD: 'Lead',
  EVENT_CONTACT: 'Campaign Member',
  OPPORTUNITY: 'Opportunity',
};

export default (kind: SalesforceKindType): string => {
  return salesforceObjectLabelMapping[kind];
};
