/* @flow */
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import { type ContextRouter, type Match, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import FeatureAccessContext from 'contexts/FeatureAccess';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import IntegrationsSettings, {
  type IntegrationSettings,
} from 'components/integrations/IntegrationsSettings';
import IntegrationSettingsPage from 'components/integrations/IntegrationsSettings/IntegrationSettingsPage';

import integrations from './lib/integrations';

import { type IntegrationsIntegrableQueryResponse } from './__generated__/IntegrationsIntegrableQuery.graphql';
import { type IntegrationsQueryResponse } from './__generated__/IntegrationsQuery.graphql';

const Title = styled.div`
  margin: 25px 0 17px 0;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const Container = styled.div`
  padding: 0 25px 24px;
`;

const integrableQuery = graphql`
  query IntegrationsIntegrableQuery {
    org {
      ...IntegrationSettingsPage_integrable
    }
  }
`;

const integrationsQuery = graphql`
  query IntegrationsQuery {
    org {
      salesforceAccount {
        id
      }
      marketoAccount {
        id
      }
      traySolutionInstances(includeExpired: true) {
        edges {
          node {
            solutionName
            upgradeNeeded
          }
        }
      }
    }
  }
`;

export default class Integrations extends React.Component<{
  match: Match,
  orgName: string,
}> {
  static contextType = FeatureAccessContext;

  renderSettings = (integrationSettings: $ReadOnlyArray<IntegrationSettings>) => (
    routeProps: ContextRouter,
  ) => (
    <DocumentTitle title={`Integrations - ${this.props.orgName}`}>
      <IntegrationsSettings {...routeProps} integrations={integrationSettings} />
    </DocumentTitle>
  );

  renderIntegrationPage = (
    routeProps: ContextRouter,
    integration: IntegrationSettings,
    response?: IntegrationsIntegrableQueryResponse,
  ) => (
    <IntegrationSettingsPage
      integrable={response ? response.org : null}
      integration={integration}
      rootUrl={this.props.match.url}
    >
      {integration.component && (
        <integration.component
          {...routeProps}
          integration={integration}
          integrable={response ? response.org : null}
        />
      )}
    </IntegrationSettingsPage>
  );

  renderIntegrationRoute = (routeProps: ContextRouter, integration: IntegrationSettings) => (
    <DocumentTitle title={`Integrations - ${integration.name} - ${this.props.orgName}`}>
      {integration.solutionName ? (
        <DefaultQueryRenderer
          query={integrableQuery}
          renderSuccess={response => this.renderIntegrationPage(routeProps, integration, response)}
        />
      ) : (
        this.renderIntegrationPage(routeProps, integration)
      )}
    </DocumentTitle>
  );

  renderRoutes = (response: IntegrationsQueryResponse) => {
    const integrationSettings = integrations(response, this.context.legacyFeatures);

    return (
      <Switch>
        <Route
          path={this.props.match.path}
          exact
          render={this.renderSettings(integrationSettings)}
        />

        {integrationSettings.map(
          integration =>
            integration.route &&
            !integration.disabledInfo && (
              <Route
                key={integration.route}
                path={`${this.props.match.path}/${integration.route}`}
                render={routeProps => this.renderIntegrationRoute(routeProps, integration)}
              />
            ),
        )}

        <Redirect to={this.props.match.url} />
      </Switch>
    );
  };

  render() {
    return (
      <DocumentTitle title={`Integrations - ${this.props.orgName}`}>
        <Container>
          <Title>Integrations Settings</Title>
          <DefaultQueryRenderer query={integrationsQuery} renderSuccess={this.renderRoutes} />
        </Container>
      </DocumentTitle>
    );
  }
}
