const userInviteStatuses = {
  CONFIRMED: 'Confirmed',
  INVITED: 'Unconfirmed',
  NOT_INVITED: 'Not Invited (No Access)',
};

export type statuses = $Keys<userInviteStatuses>;

export function nameWithStatus(userName: string, inviteStatus: statuses) {
  if (inviteStatus !== 'INVITED') return userName;

  return `${userName} (${userInviteStatuses[inviteStatus]})`;
}

export default userInviteStatuses;
