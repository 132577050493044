/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventDatesComponent_registrationForm$ref = any;
type EventNameComponent_registrationForm$ref = any;
type RegistrationFormComponent_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SharedColumnComponent_registrationForm$ref: FragmentReference;
export type SharedColumnComponent_registrationForm = {|
  +$fragmentRefs: EventNameComponent_registrationForm$ref & EventDatesComponent_registrationForm$ref & RegistrationFormComponent_registrationForm$ref,
  +$refType: SharedColumnComponent_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SharedColumnComponent_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EventNameComponent_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventDatesComponent_registrationForm",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationFormComponent_registrationForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bb3675a730236574408fbdf9daedd5c2';
module.exports = node;
