/* @flow */
import React from 'react';
import styled from 'styled-components';
import type { GooglePlacePrediction } from 'utils/locations/locationTypes';

const Row = styled.div`
  padding: 8px 10px;
  cursor: pointer;
`;

const PrimaryText = styled.div`
  margin-bottom: 5px;
  color: ${props => props.theme.rowPrimaryTextColor};
  font-size: 13px;
  line-height: 1.1;
`;

const SecondaryText = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 13px;
  line-height: 1.1;
`;

export default class LocationAutocompleteRow extends React.Component<{
  prediction: GooglePlacePrediction,
}> {
  render() {
    return (
      <Row>
        <PrimaryText>{this.props.prediction.structured_formatting.main_text}</PrimaryText>
        <SecondaryText>{this.props.prediction.structured_formatting.secondary_text}</SecondaryText>
      </Row>
    );
  }
}
