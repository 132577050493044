/* @flow */
import React from 'react';
import styled from 'styled-components';

import { Field } from 'components/material/SelectField';
import RegistrationSelectField from './RegistrationSelectField';
import { measurements } from '../../registrationFormDefaults';
import inputBoxStyled from './inputBoxStyled';

import SizeField from '../RegistrationPageComponents/components/SizeField';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
`;

const StyledRegistrationSelectField = styled(RegistrationSelectField)`
  width: auto;
  > div:first-child {
    overflow: hidden;
    width: 66px;
    height: 35px;
    padding: 0 10px;
    border-radius: 3px;
    border: 1px solid #d9ddf5;
    font-size: 13px;
    line-height: 1em;
    background: #fff;
    color: #464d72;
    cursor: pointer;

    > i {
      font-size: 18px;
      color: #3e4858;
    }
  }
  ${Field} {
    ${props => inputBoxStyled(props)};
  }
`;

const StyledSizeField = styled(SizeField)`
  width: 76px;
  margin-right: 10px;
  padding: 0 10px;
  text-align: left;
`;

export type WidthMeasurementType = 'PERCENTAGE' | 'PX';

const percentageMaxValue = 100;

export default class WidthModifier extends React.PureComponent<
  {
    width: number,
    widthMeasurement: WidthMeasurementType,
    className?: string,
    onChangeWidth?: (value: number) => void,
    onSaveWidthOrMeasurement: (width?: number, widthMeasurement?: WidthMeasurementType) => void,
  },
  {
    measurementFocused: boolean,
  },
> {
  state = { measurementFocused: false };

  handleSaveWidth = (width?: number) => {
    if (width != null && width !== this.props.width) {
      this.props.onSaveWidthOrMeasurement(width);
      this.handleChangeWidth(width);
    }
  };

  handleChangeWidth = (width: number) => {
    const onChangeWidth = this.props.onChangeWidth;
    if (onChangeWidth) {
      onChangeWidth(width);
    }
  };

  correctedWidth = (widthMeasurement: WidthMeasurementType): number => {
    const width = this.props.width;
    if (widthMeasurement === 'PX') {
      return width === 100 ? 1000 : width;
    }
    return width > 100 ? 100 : width;
  };

  handleSaveMeasurement = (widthMeasurement: ?WidthMeasurementType) => {
    if (widthMeasurement) {
      const width = this.correctedWidth(widthMeasurement);
      this.handleChangeWidth(width);
      this.props.onSaveWidthOrMeasurement(width, widthMeasurement);
    }
  };

  handleMeasurementFocus = () => {
    this.setState({ measurementFocused: true });
  };

  handleMeasurementUnfocus = () => {
    this.setState({ measurementFocused: false });
  };

  render() {
    const { width, widthMeasurement, className } = this.props;
    return (
      <Container className={className}>
        <StyledSizeField
          value={width}
          maxValue={widthMeasurement === 'PERCENTAGE' ? percentageMaxValue : undefined}
          onSave={this.handleSaveWidth}
          onChange={this.handleChangeWidth}
          sizeSymbol=""
          maxLength={4}
        />
        <StyledRegistrationSelectField
          options={measurements}
          value={widthMeasurement}
          focused={this.state.measurementFocused}
          onChange={this.handleSaveMeasurement}
          onShowOptions={this.handleMeasurementFocus}
          onHideOptions={this.handleMeasurementUnfocus}
        />
      </Container>
    );
  }
}
