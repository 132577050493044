/* @flow */
import React from 'react';
import styled from 'styled-components';

import CompanyIconSVG from 'images/Company';

const IconContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  width: ${props => (props.size ? props.size : 26)}px;
  height: ${props => (props.size ? props.size : 26)}px;
  margin-right: 10px;
  border: 1px solid #828b93;
  border-radius: 50%;
  color: #828b93;
  > svg {
    width: ${props => (props.iconSize ? props.iconSize : 12)}px;
    height: ${props => (props.iconSize ? props.iconSize : 12)}px;
  }
`;

type Props = {
  size?: number,
  iconSize?: number,
  className?: string,
};

const CompanyIcon = ({ size, iconSize, className }: Props) => (
  <IconContainer className={className} size={size} iconSize={iconSize}>
    <CompanyIconSVG />
  </IconContainer>
);

export default CompanyIcon;
