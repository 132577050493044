/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import type { ContactRequestSubmissionsCount_contact } from './__generated__/ContactRequestSubmissionsCount_contact.graphql';

const ContactRequestSubmissionsCount = (props: {
  contact: ContactRequestSubmissionsCount_contact,
}) => props.contact.eventRequestSubmissions.totalCount;

export default createFragmentContainer(
  ContactRequestSubmissionsCount,
  graphql`
    fragment ContactRequestSubmissionsCount_contact on Contact {
      eventRequestSubmissions {
        totalCount
      }
    }
  `,
);
