/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import removeCompanies from 'graph/mutations/company/removeCompanies';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { type InputVariableFilters } from 'components/Companies/companiesTableColumnSettings';
import ConfirmationWindow from 'components/ConfirmationWindow';
import Button from 'components/material/Button';

import CompaniesUpdateWindow from './CompaniesUpdateWindow';

const SelectionInfo = styled.span`
  min-width: 125px;
  padding: 5px 6px;
  color: #828b93;
`;

const ActionButton = styled(Button)`
  margin-left: 10px;
  padding: 2px 13px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

export default class CompaniesMassUpdatePanel extends React.PureComponent<
  {
    orgId?: string,
    eventId?: ?string,
    selectedCompanies: $ReadOnlyArray<string>,
    companyFields: $ReadOnlyArray<FieldType>,
    filters: InputVariableFilters,
    fromWindow: string,
    allCompaniesSelected: boolean,
    onUpdateTableColumnWidths: () => void,
    viewerCanRemoveCompanies: boolean,
  },
  {
    showDeleteWindow: boolean,
    showUpdateWindow: boolean,
  },
> {
  state = {
    showDeleteWindow: false,
    showUpdateWindow: false,
  };

  handleRemove = () => {
    removeCompanies({
      companyIds: this.props.selectedCompanies,
      orgId: this.props.orgId,
      eventId: this.props.eventId,
      companyFields: this.props.companyFields,
      filters: this.props.filters,
      fromWindow: this.props.fromWindow,
    })
      .then(() => {
        if (this.props.allCompaniesSelected) {
          window.location.reload();
        }
      })
      .catch(showModernMutationError);
  };

  handleShowDeleteWindow = () => {
    this.setState({ showDeleteWindow: true });
  };

  handleConfirmationHide = () => {
    this.setState({ showDeleteWindow: false });
  };

  handleShowUpdateWindow = (): void => {
    this.setState({ showUpdateWindow: true });
  };

  handleHideUpdateWindow = (): void => {
    this.setState({ showUpdateWindow: false });
  };

  render() {
    const {
      selectedCompanies,
      companyFields,
      viewerCanRemoveCompanies,
      onUpdateTableColumnWidths,
    } = this.props;

    const companiesCount = selectedCompanies.length;

    return (
      <>
        <SelectionInfo>
          {companiesCount} {companiesCount > 1 ? 'Items' : 'Item'} selected
        </SelectionInfo>
        {companyFields.some(field => field.kind === 'SELECT' || field.kind === 'USER_SELECT') && (
          <ActionButton label="Update Info" outline primary onClick={this.handleShowUpdateWindow} />
        )}

        {viewerCanRemoveCompanies && (
          <ActionButton label="Delete" outline primary onClick={this.handleShowDeleteWindow} />
        )}
        {this.state.showDeleteWindow && (
          <ConfirmationWindow
            onHide={this.handleConfirmationHide}
            onConfirm={this.handleRemove}
            message={`You are about to delete ${companiesCount} ${
              companiesCount > 1 ? 'companies' : 'company'
            }. Once you delete a company, it is removed from all your events and org.`}
          />
        )}
        {this.state.showUpdateWindow && (
          <CompaniesUpdateWindow
            eventId={this.props.eventId}
            onHide={this.handleHideUpdateWindow}
            selectedCompanies={selectedCompanies}
            companyFields={companyFields}
            onUpdateTableColumnWidths={onUpdateTableColumnWidths}
          />
        )}
      </>
    );
  }
}
