/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SalesforceAuth_org$ref = any;
type SalesforceSettings_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesforceWindow_org$ref: FragmentReference;
export type SalesforceWindow_org = {|
  +salesforceAccount: ?{|
    +contactsSyncEnabled: boolean,
    +opportunitiesSyncEnabled: boolean,
  |},
  +$fragmentRefs: SalesforceAuth_org$ref & SalesforceSettings_org$ref,
  +$refType: SalesforceWindow_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SalesforceWindow_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "SalesforceAuth_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SalesforceSettings_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "contactsSyncEnabled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "opportunitiesSyncEnabled",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'fc37ff040bc563813d5d607ed49115e4';
module.exports = node;
