/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import CostField from 'components/material/CostField';

import type { QuestionType, QuestionValueType, QuestionValueInputType } from '../lib/types';
import inputBoxStyled from '../form/inputBoxStyled';
import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';

const StyledCostField = styled(CostField)`
  input {
    ${props => inputBoxStyled(props)};
  }
`;

export default class QuestionAmount extends React.PureComponent<{
  question: QuestionType,
  value: QuestionValueType,
  onChangeValue: QuestionValueInputType => void,
  readOnly: boolean,
  currency: string,
  error: ?string,
}> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>, parsedCost: ?number) => {
    this.props.onChangeValue({ numberValue: parsedCost });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.question.description && (
          <HelperText>{this.props.question.description}</HelperText>
        )}
        <StyledCostField
          currency={this.props.currency}
          defaultValue={this.props.value.numberValue}
          onChange={this.handleChange}
          error={this.props.error ? ' ' : null}
          disabled={this.props.readOnly}
        />
        {this.props.error && <ErrorMessage>{this.props.error}</ErrorMessage>}
      </React.Fragment>
    );
  }
}
