/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefScheduleItem_scheduleItem$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefScheduleDay_scheduleDay$ref: FragmentReference;
export type BriefScheduleDay_scheduleDay = {|
  +id: string,
  +date: any,
  +scheduleItems: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +allDay: boolean,
        +startTime: any,
        +$fragmentRefs: BriefScheduleItem_scheduleItem$ref,
      |}
    |}>
  |},
  +$refType: BriefScheduleDay_scheduleDay$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "BriefScheduleDay_scheduleDay",
  "type": "ScheduleDay",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "personalizeSchedules",
      "type": "Boolean"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "scheduleItems",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "isPersonalized",
          "variableName": "personalizeSchedules",
          "type": "Boolean"
        }
      ],
      "concreteType": "ScheduleItemRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ScheduleItemRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ScheduleItem",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "allDay",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "startTime",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "BriefScheduleItem_scheduleItem",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2ee2ce3b499e4137546d2b75d84b9d18';
module.exports = node;
