/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type VendorFilterInputs } from 'components/Vendors/lib/renderVendorFilter';

import type {
  createVendorMutationResponse,
  createVendorMutationVariables,
} from './__generated__/createVendorMutation.graphql';

export type CreateVendorFromWindow = 'event vendors' | 'org vendors';
export type ResponseVendor = $PropertyType<
  $PropertyType<
    $NonMaybeType<$PropertyType<createVendorMutationResponse, 'createVendor'>>,
    'vendorEdge',
  >,
  'node',
>;

type Input = $Diff<
  $PropertyType<createVendorMutationVariables, 'input'>,
  {
    fromWindow: ?string,
    company: ?$PropertyType<$PropertyType<createVendorMutationVariables, 'input'>, 'company'>,
  },
> & {
  company?: ?$ReadOnly<
    $NonMaybeType<$PropertyType<$PropertyType<createVendorMutationVariables, 'input'>, 'company'>>,
  >,
  fromWindow: CreateVendorFromWindow,
};

const mutation = graphql`
  mutation createVendorMutation(
    $input: CreateVendorInput!
    $filters: VendorFilters
    $updateOrgTotalCount: Boolean!
  ) {
    createVendor(input: $input) {
      ... @include(if: $updateOrgTotalCount) {
        org {
          ...VendorsTablePagination_totalCountOrg
        }
      }
      vendorEdge {
        __typename
        node {
          id
          name
          ...VendorName_vendor
          ...VendorWebsite_vendor
          ...VendorCompanyPhone_vendor
          ...VendorStreet_vendor
          ...VendorCity_vendor
          ...VendorState_vendor
          ...VendorZip_vendor
          ...VendorCountry_vendor
          ...VendorTwitter_vendor
          ...VendorLinkedin_vendor
          ...VendorDescription_vendor
          ...VendorFirstName_vendor
          ...VendorLastName_vendor
          ...VendorTitle_vendor
          ...VendorOfficePhone_vendor
          ...VendorMobilePhone_vendor
          ...VendorEmail_vendor
          ...VendorCreator_vendor
          ...VendorCreatedAt_vendor
          ...VendorUpdater_vendor
          ...VendorUpdatedAt_vendor
          ...CustomizableText_customizable
          ...CustomizableTextarea_customizable
          ...CustomizableLink_customizable
          ...CustomizableDate_customizable
          ...CustomizableBoolean_customizable
          ...CustomizableNumber_customizable
          ...CustomizableCurrency_customizable
          ...CustomizableSelect_customizable
          ...CustomizableMultiselect_customizable
          ...CustomizableUserSelect_customizable
          ...CustomizableUserMultiselect_customizable
        }
      }
    }
  }
`;

export default function createVendor(
  orgId: string,
  input: Input,
  filtersVariables?: VendorFilterInputs,
): Promise<ResponseVendor> {
  return commitModernMutation({
    mutation,
    variables: {
      input,
      filters: filtersVariables,
      updateOrgTotalCount: filtersVariables != null,
    },
    updater: mergeUpdaters(
      addRecordToConnection({
        parentId: orgId,
        edgeName: 'vendorEdge',
        connections: [{ key: 'OrgVendorsTablePagination_vendors' }],
      }),
      ...(input.eventIds || []).map(eventId =>
        addRecordToConnection({
          parentId: eventId,
          edgeName: 'vendorEdge',
          connections: [{ key: 'EventVendorsTablePagination_vendors' }, { field: 'vendors' }],
        }),
      ),
    ),
  }).then((response: createVendorMutationResponse) => {
    if (!response.createVendor) {
      throw Error('Incomplete response');
    }

    return response.createVendor.vendorEdge.node;
  });
}
