/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import downloadedBudget from 'utils/analytics/downloadedBudget';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';

import { getDefaultFilters, suggestedFields } from 'components/AllEvents/eventsTableColumnSettings';
import CsvDownloadWindow from 'components/budget/CsvDownloadWindow';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ShareDropdown from 'components/ShareDropdown';

import type { ParsedBudgetReportingFilters } from '../lib/parseBudgetReportingFilters';

import type { BudgetReportingActionsQueryResponse } from './__generated__/BudgetReportingActionsQuery.graphql';

const query = graphql`
  query BudgetReportingActionsQuery(
    $group1: BudgetReportingGroup
    $group2: BudgetReportingGroup
    $customGroup1: ID
    $customGroup2: ID
    $filters: EventFilters!
    $sort: BudgetReportingSort
    $direction: Direction!
    $includeExpenses: Boolean!
    $includePayments: Boolean!
  ) {
    org {
      name
      budgetReportCSVProcessId(
        group1: $group1
        group2: $group2
        customGroup1: $customGroup1
        customGroup2: $customGroup2
        filters: $filters
        sort: $sort
        direction: $direction
        includeExpenses: $includeExpenses
        includePayments: $includePayments
      )
    }
    me {
      email
    }
  }
`;

export default class BudgetReportingActions extends React.PureComponent<
  {
    filters: ParsedBudgetReportingFilters,
    filterOptions: $ReadOnlyArray<FieldType>,
  },
  {
    showDownloadOverlay: boolean,
    showDownloadOptions: boolean,
    includeExpenses: boolean,
    includePayments: boolean,
  },
> {
  state = {
    showDownloadOverlay: false,
    showDownloadOptions: false,
    includeExpenses: false,
    includePayments: false,
  };

  handleClickDownload = () => {
    this.setState({ showDownloadOptions: true, includeExpenses: false, includePayments: false });
  };

  handleShowDownloadOverlay = () => {
    this.setState({ showDownloadOverlay: true, showDownloadOptions: false });
  };

  handleReportDownload = () => {
    downloadedBudget({
      eventId: null,
      eventName: null,
      teamId: null,
      teamName: null,
      fromWindow: 'org budget',
    });
  };

  handleHideDownloadOverlay = () => {
    this.setState({ showDownloadOverlay: false });
  };

  handleHideDownloadOptions = () => {
    this.setState({ showDownloadOptions: false });
  };

  handleChangeDownloadOptions = (
    value: 'includeExpenses' | 'includePayments',
    checked: boolean,
  ) => {
    this.setState({ [value]: checked });
  };

  renderDownloadOverlay = () => {
    const {
      sort,
      date,
      group1,
      group2,
      customGroup1,
      customGroup2,
      ...eventFilters
    } = this.props.filters;

    const sortFilter = sort || {
      key: 'ACTUAL_AMOUNT',
      asc: false,
    };
    const defaultFilters = getDefaultFilters(eventFilters);

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          group1,
          group2,
          customGroup1,
          customGroup2,
          filters: {
            ...defaultFilters,
            ...(date == null
              ? {}
              : {
                  afterDate: date.start.format(),
                  beforeDate: date.end.format(),
                }),
            customFilters: this.props.filterOptions
              .filter(
                option =>
                  (option.fieldName == null || suggestedFields.includes(option.fieldName)) &&
                  eventFilters[option.id] != null,
              )
              .map(option => getCustomFieldVariable(eventFilters[option.id], option)),
          },
          sort: sortFilter.key,
          direction: sortFilter.asc ? 'ASC' : 'DESC',
          includeExpenses: this.state.includeExpenses,
          includePayments: this.state.includePayments,
        }}
        renderSuccess={(response: BudgetReportingActionsQueryResponse) => {
          return (
            <DownloadOverlayWithEmail
              processUUID={response.org.budgetReportCSVProcessId}
              fileName={`'${response.org.name}' Budget Report.csv`}
              onHide={this.handleHideDownloadOverlay}
              email={response.me.email}
              exportable="budget_report_csv"
              onDownload={this.handleReportDownload}
            />
          );
        }}
        renderLoading={() => null}
      />
    );
  };

  render() {
    const {
      showDownloadOverlay,
      showDownloadOptions,
      includePayments,
      includeExpenses,
    } = this.state;

    return (
      <>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={[
            {
              label: 'Download current view (CSV)',
              icon: 'file-excel-o',
              onClick: this.handleClickDownload,
            },
          ]}
        />
        {showDownloadOverlay && this.renderDownloadOverlay()}

        {showDownloadOptions && (
          <CsvDownloadWindow
            title="Download Budget Report"
            subtitle="Download the current view as a CSV spreadsheet."
            options={[
              { label: 'Include Expenses', value: 'includeExpenses', checked: includeExpenses },
              { label: 'Include Payments', value: 'includePayments', checked: includePayments },
            ]}
            onChange={this.handleChangeDownloadOptions}
            onCancel={this.handleHideDownloadOptions}
            onDownload={this.handleShowDownloadOverlay}
          />
        )}
      </>
    );
  }
}
