import sanitize from 'sanitize-html';

type SanitizeOptions = {
  extendedTags?: $ReadOnlyArray<string>,
  extendedAttrs?: $ReadOnlyArray<string>,
  disallowedTagsMode?: 'escape' | 'discard' | 'recursiveEscape',
};

export default function sanitizeHtml(
  value,
  { extendedTags, extendedAttrs, disallowedTagsMode } = {},
): (value: string, options: SanitizeOptions) => string {
  return sanitize(value, {
    allowedTags: [...sanitize.defaults.allowedTags, ...(extendedTags || [])],
    allowedAttributes: {
      '*': ['src', 'style', 'href', 'target', 'rel', 'width', 'height', ...(extendedAttrs || [])],
    },
    disallowedTagsMode: disallowedTagsMode || 'recursiveEscape',
  });
}
