/* @flow */
import validateColor from 'utils/validateColor';

import registrationUIDefaultProps, {
  type RegistrationUIPropsType,
} from '../RegistrationUIDefaultProps';

const validateColorParams = (
  UIProps: registrationUIDefaultProps,
  defaultProps?: registrationUIDefaultProps,
): RegistrationUIPropsType => {
  const UIpropsKeys = Object.keys(UIProps);
  return UIpropsKeys.reduce((parsedProps, currentKey) => {
    if (
      ['Background', 'Color', 'Border'].some(colorParamKey => currentKey.includes(colorParamKey))
    ) {
      const uppercasedColor = UIProps[currentKey].toUpperCase();
      const defaultValue =
        defaultProps && defaultProps[currentKey]
          ? defaultProps[currentKey]
          : registrationUIDefaultProps[currentKey];
      return {
        ...parsedProps,
        [currentKey]: validateColor(uppercasedColor) ? uppercasedColor : defaultValue,
      };
    }

    return { ...parsedProps, [currentKey]: UIProps[currentKey] };
  }, {});
};

export default validateColorParams;
