/* @flow */
import * as React from 'react';
import {
  type ConnectDropTarget,
  type DropTargetConnector,
  type DropTargetMonitor,
  DropTarget,
} from 'react-dnd';
import styled, { css } from 'styled-components';

import type { QuestionType, ReorderedQuestionType, SectionType } from '../lib/types';

const DropAreaMessage = styled.div`
  word-break: break-word;
  color: #3e4859;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 6px;
  ${props =>
    props.hovered &&
    css`
      height: 70px;
      background-color: ${props.restrictedDruggingZone ? '#ffffff' : '#f1f9fc'};
      border: 1px dashed #5db5d5;
      border-image: url('/images/component_hover_border.png') 1 round;
    `}
`;

class EditableSectionHeader extends React.PureComponent<{
  /* eslint-disable react/no-unused-prop-types */
  // Used in Hover handler
  onMove: (sourceQuestion: ReorderedQuestionType) => void,
  restrictedDruggingZone: boolean,
  draggingQuestion: ?QuestionType,
  connectDropTarget?: ConnectDropTarget,
  section: SectionType,
  hovered?: boolean,
}> {
  render() {
    const { connectDropTarget, section, draggingQuestion, restrictedDruggingZone } = this.props;
    if (!connectDropTarget) return null;

    const hovered =
      this.props.hovered &&
      draggingQuestion &&
      draggingQuestion.sectionId !== section.id &&
      draggingQuestion.mapping !== 'NAME';

    return connectDropTarget(
      <div>
        <Container hovered={hovered} restrictedDruggingZone={restrictedDruggingZone}>
          {hovered && (
            <DropAreaMessage>
              {restrictedDruggingZone
                ? 'This question cannot be moved here because of logical dependencies it has.'
                : 'Drop question here to make it the last one in current section.'}
            </DropAreaMessage>
          )}
        </Container>
      </div>,
    );
  }
}

export default DropTarget(
  'QUESTION',
  {
    drop(props) {
      return { targetSection: props.section };
    },
    hover(props: $PropertyType<EditableSectionHeader, 'props'>, monitor: DropTargetMonitor) {
      const dragQuestion = monitor.getItem();
      if (dragQuestion.sectionId === props.section.id || dragQuestion.mapping === 'NAME') {
        return;
      }

      props.onMove({ question: dragQuestion, targetSection: props.section });
    },
  },
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
  }),
)(EditableSectionHeader);
