/* @flow */
import * as React from 'react';
import DayPicker from 'react-day-picker';
import Swipeable from 'react-swipeable';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { createMoment } from 'utils/Date';

import TasksCalendarHeader from './TasksCalendarHeader';

const Container = styled.div`
  .DayPicker-WeekdaysRow,
  .DayPicker-Week {
    display: flex;
  }
  .DayPicker-Day,
  .DayPicker-Weekday {
    flex: 1 0;
    width: 0;
  }
  .DayPicker-Weekday {
    padding: 3px;
    font-size: 12px;
    text-transform: uppercase;
    color: ${props => props.theme.mutedTextColor};
    abbr {
      text-decoration: none;
    }
  }
  .DayPicker-Day {
    outline: none;
    height: 130px;
    background: ${props => props.theme.contentBackgroundColor};
    margin-right: 1px;
    margin-bottom: 1px;
    border: 1px solid ${props => props.theme.primaryRowColor};
  }
  .DayPicker-Day--past {
    background: ${props => props.theme.primaryRowColor};
  }
  .DayPicker-Day--today {
    background: ${props => props.theme.hoverRowColor};
  }
  .DayPicker-Day--outside > div > :first-child {
    opacity: 0.5;
  }
`;

export default class TasksCalendar extends React.PureComponent<{
  tz: string,
  renderDay: moment => React.Node,
  month: moment,
  onChangeMonth: moment => void,
}> {
  handleMonthChange = (month: moment) => {
    this.props.onChangeMonth(month);
  };

  prevMonth = () => {
    this.props.onChangeMonth(this.props.month.clone().subtract(1, 'month'));
  };

  nextMonth = () => {
    this.props.onChangeMonth(this.props.month.clone().add(1, 'month'));
  };

  renderDay = (day: Date) => {
    return this.props.renderDay(createMoment(this.props.tz)(day));
  };

  render() {
    const now = createMoment(this.props.tz)();
    const modifiers = {
      past: day => now.isAfter(day, 'day'),
      tz_today: day => now.isSame(day, 'day'),
    };

    return (
      <Container>
        <Swipeable onSwipedRight={this.prevMonth} onSwipedLeft={this.nextMonth}>
          <DayPicker
            canChangeMonth={false}
            modifiers={modifiers}
            renderDay={this.renderDay}
            captionElement={
              // $FlowExpectedError DayPicker provides the `date` prop
              <TasksCalendarHeader tz={this.props.tz} onChange={this.handleMonthChange} />
            }
            enableOutsideDays
            month={moment(this.props.month.toArray()).toDate()}
          />
        </Swipeable>
      </Container>
    );
  }
}
