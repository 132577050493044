/* @flow */
import React from 'react';
import { type RelayRefetchProp, createRefetchContainer, graphql } from 'react-relay';
import type { Match } from 'react-router';
import styled from 'styled-components';

import Loader from 'components/Loader';

import UIContext from '../UIContext';
import RegistrationLogicContent from './RegistrationLogicContent';

import type { RegistrationLogicRefetchContainer_query } from './__generated__/RegistrationLogicRefetchContainer_query.graphql';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

class RegistrationLogicRefetchContainer extends React.PureComponent<
  {
    match: Match,
    discardCounter?: number,
    userTz: string,
    query: RegistrationLogicRefetchContainer_query,
    relay: RelayRefetchProp,
  },
  {
    loading: boolean,
  },
> {
  state = { loading: false };

  componentDidUpdate(prevProps: $PropertyType<RegistrationLogicRefetchContainer, 'props'>) {
    const { discardCounter, match, relay } = this.props;
    if (discardCounter !== prevProps.discardCounter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: true });
      const eventSlug = match.params.eventSlug;
      relay.refetch(
        { eventSlug, isEventRegForm: !!eventSlug },
        null,
        () => {
          this.setState({ loading: false });
        },
        { force: true },
      );
    }
  }

  render() {
    const {
      userTz,
      query: { event, org },
    } = this.props;
    if (this.state.loading) {
      return (
        <UIContext.Consumer>
          {({ loaderColor }) => (
            <LoaderContainer>
              <Loader color={loaderColor} size={30} />
            </LoaderContainer>
          )}
        </UIContext.Consumer>
      );
    }

    return (
      <RegistrationLogicContent
        org={org}
        registrationForm={event != null ? event.editableRegistrationForm : org.registrationForm}
        tz={event && event.editableRegistrationForm ? event.editableRegistrationForm.tz : userTz}
      />
    );
  }
}

export default createRefetchContainer(
  RegistrationLogicRefetchContainer,
  graphql`
    fragment RegistrationLogicRefetchContainer_query on Query {
      event(slug: $eventSlug) @include(if: $isEventRegForm) {
        editableRegistrationForm {
          tz
          ...RegistrationLogicContent_registrationForm
        }
      }
      org {
        ...RegistrationLogicContent_org
        registrationForm @skip(if: $isEventRegForm) {
          ...RegistrationLogicContent_registrationForm
        }
      }
    }
  `,
  graphql`
    query RegistrationLogicRefetchContainerQuery($eventSlug: String, $isEventRegForm: Boolean!) {
      ...RegistrationLogicRefetchContainer_query
    }
  `,
);
