/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import currentOrigin from 'utils/currentOrigin';
import { renderDateRange } from 'utils/Date';
import { stringifyLocation } from 'utils/Location';

import ExternalCalendarExport from 'components/ExternalCalendarExport';

import type { TaskExportCalendar_task } from './__generated__/TaskExportCalendar_task.graphql';

class TaskExportCalendar extends React.PureComponent<
  {
    render: (() => void) => React.Node,
    task: TaskExportCalendar_task,
  },
  {
    showCalendarExportWindow: boolean,
  },
> {
  state = { showCalendarExportWindow: false };

  handleShowCalendarAdd = () => this.setState({ showCalendarExportWindow: true });

  handleHideAddCalendarWindow = () => this.setState({ showCalendarExportWindow: false });

  renderCalendarExportWindow = () => {
    const task = this.props.task;
    const event = task.event;
    const assignees = task.assignees.edges.map(edge => edge.node);

    const icsUrl = `${currentOrigin()}/v1/events/${event.token}/tasks/ical?task=${task.dbId}`;

    if (!task.dueDate) return null;

    const googleCalendarProps = {
      name: task.name,
      dates: {
        startDate: task.dueDate,
        allDay: !!task.dueDateAllDay,
        tz: event.tz,
      },
      details: `
          EVENT DETAILS
          ${event.name}
          ${
            event.startDate
              ? renderDateRange({
                  startDate: event.startDate,
                  endDate: event.endDate,
                  startDateAllDay: event.startDateAllDay,
                  endDateAllDay: event.endDateAllDay,
                  tz: event.tz,
                })
              : ''
          }
          ${stringifyLocation(event.primaryLocation)}
          ${event.website || ''}
          ---
          TASK DETAILS
          ${task.name}
          ${currentOrigin()}/${event.slug}/tasks/${task.slug}
          ${assignees.map(user => `@${user.firstName} ${user.lastName}`).join(', ')}
          ${task.description || ''}
        `,
    };

    return (
      <ExternalCalendarExport
        listTitle="this task"
        icsUrl={icsUrl}
        googleCalendarProps={googleCalendarProps}
        showDownloadOption
        onHide={this.handleHideAddCalendarWindow}
      />
    );
  };

  render() {
    return (
      <>
        {this.props.render(this.handleShowCalendarAdd)}
        {this.state.showCalendarExportWindow && this.renderCalendarExportWindow()}
      </>
    );
  }
}

export default createFragmentContainer(
  TaskExportCalendar,
  graphql`
    fragment TaskExportCalendar_task on Deliverable {
      id
      dbId
      name
      dueDate
      dueDateAllDay
      slug
      description
      assignees {
        edges {
          node {
            firstName
            lastName
          }
        }
      }
      event {
        token
        slug
        name
        startDate
        endDate
        startDateAllDay
        endDateAllDay
        tz
        website
        primaryLocation {
          country
          city
          state
          name
        }
      }
    }
  `,
);
