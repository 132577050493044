/**
 * @flow
 * @relayHash 617dbc4b8ccd8eb0dfb874a1e74c3b1a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VendorsPage_org$ref = any;
type VendorsPage_user$ref = any;
export type OrgVendorsQueryVariables = {||};
export type OrgVendorsQueryResponse = {|
  +org: {|
    +$fragmentRefs: VendorsPage_org$ref
  |},
  +me: {|
    +$fragmentRefs: VendorsPage_user$ref
  |},
|};
export type OrgVendorsQuery = {|
  variables: OrgVendorsQueryVariables,
  response: OrgVendorsQueryResponse,
|};
*/


/*
query OrgVendorsQuery {
  org {
    ...VendorsPage_org
    id
  }
  me {
    ...VendorsPage_user
    id
  }
}

fragment VendorsPage_org on Org {
  id
  customFields(customizableType: [VENDOR]) {
    edges {
      node {
        id
        label
        fieldName
        order
        kind
        required
        options {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
  ...VendorsHeaderBar_org
}

fragment VendorsPage_user on User {
  tz
}

fragment VendorsHeaderBar_org on Org {
  name
  viewerCanCreateVendors
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "OrgVendorsQuery",
  "id": null,
  "text": "query OrgVendorsQuery {\n  org {\n    ...VendorsPage_org\n    id\n  }\n  me {\n    ...VendorsPage_user\n    id\n  }\n}\n\nfragment VendorsPage_org on Org {\n  id\n  customFields(customizableType: [VENDOR]) {\n    edges {\n      node {\n        id\n        label\n        fieldName\n        order\n        kind\n        required\n        options {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n  ...VendorsHeaderBar_org\n}\n\nfragment VendorsPage_user on User {\n  tz\n}\n\nfragment VendorsHeaderBar_org on Org {\n  name\n  viewerCanCreateVendors\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OrgVendorsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "VendorsPage_org",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "VendorsPage_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrgVendorsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"VENDOR\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "customizableType",
                "value": [
                  "VENDOR"
                ],
                "type": "[CustomizableType!]!"
              }
            ],
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fieldName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "kind",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "required",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "options",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionTypeRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldOptionTypeRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CustomFieldOptionType",
                                "plural": false,
                                "selections": [
                                  v0,
                                  v1
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanCreateVendors",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tz",
            "args": null,
            "storageKey": null
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'df38c2758a1b1056092bd10160d42100';
module.exports = node;
