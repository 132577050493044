/* @flow */

import React from 'react';
import styled from 'styled-components';

import SectionHeader from '../components/SectionHeader';
import SectionContent from '../components/SectionContent';
import UserContainerInList from '../components/UserContainerInList';
import UserContainer from '../components/UserContainer';
import ContactField from '../components/ContactField';
import { type Field } from '../components/types';
import CompanyField from '../components/CompanyField';

const StyledUserContainer = styled(UserContainer)`
  padding-bottom: ${props => (props.hasBottom ? '40px' : '20px')};
`;

export type ContactType = {|
  +id: string,
  +firstName: string,
  +lastName: string,
  +email: string,
  +phone1: ?string,
  +phone2: ?string,
  +title: ?string,
  +company: ?{|
    +id: string,
    +name: string,
  |},
  +$fragmentRefs: any,
|};

export type CompanyType = {|
  +id: string,
  +name: string,
  +phone: ?string,
|};

class EventContactsBrief extends React.Component<{
  customFields: $ReadOnlyArray<Field<'COMPANY | CONTACT'>>,
  contacts: $ReadOnlyArray<ContactType>,
  companies: $ReadOnlyArray<CompanyType>,
  primaryColor: string,
}> {
  render() {
    const { primaryColor, contacts, companies, customFields } = this.props;
    const hasContacts = contacts.length + companies.length > 0;

    // $FlowFixMe
    const companyFields: $ReadOnlyArray<Field<'COMPANY'>> = customFields.filter(
      item => item.customizableType === 'COMPANY',
    );

    // $FlowFixMe
    const contactFields: $ReadOnlyArray<Field<'CONTACT'>> = customFields.filter(
      item => item.customizableType === 'CONTACT',
    );

    return hasContacts ? (
      <React.Fragment>
        <SectionHeader primaryColor={primaryColor} name="Event Contacts" anchor="contacts" />
        <SectionContent>
          <StyledUserContainer hasBottom={companies.length === 0}>
            {contacts.map(contact => (
              <UserContainerInList key={contact.id}>
                <ContactField
                  contact={contact}
                  customFields={contactFields}
                  avatarRadius={20}
                  showContact
                />
              </UserContainerInList>
            ))}
          </StyledUserContainer>
          <StyledUserContainer hasBottom>
            {companies.map(company => (
              <UserContainerInList key={company.id}>
                <CompanyField
                  company={company}
                  size={40}
                  iconSize={15}
                  customFields={companyFields}
                  showTag
                />
              </UserContainerInList>
            ))}
          </StyledUserContainer>
        </SectionContent>
      </React.Fragment>
    ) : null;
  }
}

export default EventContactsBrief;
