/* @flow */
import { GOOGLE_OAUTH2_ID } from 'config/variables';

import appOrigin from 'utils/url/appOrigin';

import authPopup from './authPopup';
import parseCallbackUrl from './parseCallbackUrl';
import googleAuthorizeUrl from './googleAuthorizeUrl';

type GoogleTokenCredentials = {
  accessToken: string,
  expiresAt: Date,
};

/**
 * Opens a popup to authenticate google.  Resolves with code or tokenif successful,
 * otherwise rejects if window is closed without authenticating or if google returned an error.
 */
export default function googleTokenAuthPopup(
  scopes: $ReadOnlyArray<string>,
): Promise<GoogleTokenCredentials> {
  const callbackUrl = `${appOrigin()}/oauth2_popup_callback.html`;
  const connectUrl = googleAuthorizeUrl({
    client_id: GOOGLE_OAUTH2_ID,
    redirect_uri: callbackUrl,
    response_type: 'token',
    scope: scopes.join(' '),
  });

  return authPopup(connectUrl, callbackUrl).then(successUrl => {
    const returnedParams = parseCallbackUrl(successUrl);
    if (returnedParams.access_token && returnedParams.expires_in) {
      const expiresIn: number = Number.parseInt(returnedParams.expires_in, 10);
      const expiresAt: Date = new Date();
      expiresAt.setSeconds(expiresIn);

      return {
        accessToken: returnedParams.access_token,
        expiresAt,
      };
    }
    if (returnedParams.error) {
      return Promise.reject(new Error(returnedParams.error));
    }
    return Promise.reject();
  });
}
