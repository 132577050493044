/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import fullNameOfUser from 'utils/fullNameOfUser';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import isUserType from '../../lib/isUserType';
import type { QuestionType, User } from '../../lib/types';
import EditableQuestionSelectOptionsForContact from './EditableQuestionSelectOptions';

import type { EditableQuestionSelectOptionsForContactQueryResponse } from './__generated__/EditableQuestionSelectOptionsForContactQuery.graphql';

const query = graphql`
  query EditableQuestionSelectOptionsForContactQuery {
    org {
      users {
        edges {
          node {
            id
            firstName
            lastName
            email
            avatar
            ...EditableQuestionSelectOptionRowForContact_user
            ...MaterialAvatar_user
          }
        }
      }
    }
  }
`;

export default class EditableQuestionSelectOptions2Root extends React.PureComponent<{
  question: QuestionType,
  mappingLabel?: string,
  onSetOptions: (values: $ReadOnlyArray<string>) => void,
  onSetOther: (show: boolean) => void,
  dragStartId?: string,
}> {
  getOrgOptions = (response: EditableQuestionSelectOptionsForContactQueryResponse) => {
    const { dragStartId, question } = this.props;
    return response.org.users.edges
      .map(edge => edge.node)
      .slice(0, dragStartId === question.id ? 5 : undefined)
      .map(user => {
        return {
          ...user,
          value: user.id,
          label: fullNameOfUser(user),
        };
      });
  };

  renderSelectOption = (options: $ReadOnlyArray<{ +label: string, +value: string } | User>) => {
    const { question, mappingLabel, onSetOptions, onSetOther } = this.props;
    return (
      <EditableQuestionSelectOptionsForContact
        question={question}
        mappingLabel={mappingLabel}
        options={options}
        disabledOptions={question.contactFormFieldOptions || []}
        onSetOptions={onSetOptions}
        onSetOther={onSetOther}
      />
    );
  };

  render() {
    const { question } = this.props;

    if (question.customField && !isUserType(question.customField)) {
      return this.renderSelectOption(question.customField.options);
    }

    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(response: EditableQuestionSelectOptionsForContactQueryResponse) =>
          this.renderSelectOption(this.getOrgOptions(response))
        }
      />
    );
  }
}
