/* @flow */

import type { RawDraftContentState } from 'draft-js';

export default function convertToString(rawState: RawDraftContentState): string {
  const { blocks, entityMap } = rawState;

  return blocks
    .map(block => {
      let text = block.text;
      let delta = 0;

      block.entityRanges.forEach(({ key, offset, length }) => {
        const slug = entityMap[key].data.slug;

        const prevChar = (text[offset + delta - 1] || '').trim();
        const nextChar = (text[offset + length + delta] || '').trim();

        text = `${text.slice(0, offset + delta)}${prevChar ? ' ' : ''}@${slug}${nextChar
          ? ' '
          : ''}${text.slice(offset + length + delta)}`;

        delta += slug.length + 1 - length + (prevChar ? 1 : 0) + (nextChar ? 1 : 0);
      });

      return text;
    })
    .join('\n');
}
