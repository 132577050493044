/* @flow */
import React from 'react';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';

import type { CompanyUpdater_company } from './__generated__/CompanyUpdater_company.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

type Props = {
  company: CompanyUpdater_company,
};

const CompanyUpdater = ({ company }: Props) => {
  if (!company || company.updatedBy == null) {
    return null;
  }

  return (
    <Container>
      <MaterialAvatar radius={13} user={company.updatedBy} />
      <TextWrapper>{fullNameOfUser(company.updatedBy)}</TextWrapper>
    </Container>
  );
};

export default createFragmentContainer(
  CompanyUpdater,
  graphql`
    fragment CompanyUpdater_company on Company {
      updatedBy {
        firstName
        lastName
        email
        ...MaterialAvatar_user
      }
    }
  `,
);
