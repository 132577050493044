/**
 * @flow
 * @relayHash b63f9b2127df488c89c09e2fead5ba55
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContactSearchRow_contact$ref = any;
export type ContactSaveMethod = "api" | "g2w" | "import" | "manual" | "past_g2w" | "past_zoom" | "registration" | "zapier" | "zoom";
export type ContactSort = "ATTENDANCE_DURATION" | "ATTENDANCE_JOIN_TIME" | "ATTENDANCE_LEAVE_TIME" | "ATTENDANCE_STATUS_ID" | "CITY" | "COMPANY_ID" | "CONTACT_TYPE_ID" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CREATED_METHOD" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "EMAIL_OPT_IN" | "EVENTS" | "HOT_LEAD" | "LAST_SYNCED" | "LINKEDIN" | "MARKETO_ID" | "MEMBERSHIP_LAST_SYNCED" | "MEMBERSHIP_SYNC_STATUS" | "NAME" | "OWNER_ID" | "PHONE1" | "PHONE2" | "REGISTERED_AT" | "REGISTRATION_STATUS_ID" | "REQUEST_SUBMISSIONS" | "SALESFORCE_ID" | "SALESFORCE_LEAD_STATUS_ID" | "SALESFORCE_SYNC_AS" | "STATE" | "STREET" | "SYNC_STATUS" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "UPDATED_METHOD" | "WEBSITE" | "ZIP";
export type ContactSyncOption = "contact" | "lead";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type ContactFilters = {
  query?: ?string,
  search?: ?Search,
  contactIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  updatedMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactTypeIds?: ?$ReadOnlyArray<string>,
  ownerIds?: ?$ReadOnlyArray<string>,
  senderIds?: ?$ReadOnlyArray<string>,
  salesforceSyncOptions?: ?$ReadOnlyArray<ContactSyncOption>,
  leadStatusIds?: ?$ReadOnlyArray<string>,
  sentAt?: ?DateTimeRange,
  emailOptIn?: ?boolean,
  hotLead?: ?boolean,
  registeredAt?: ?DateTimeRange,
  attendanceStatusIds?: ?$ReadOnlyArray<string>,
  registrationStatusIds?: ?$ReadOnlyArray<string>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  membershipSyncStatuses?: ?$ReadOnlyArray<SyncState>,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?ContactSort,
  direction?: ?Direction,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type ContactSearchQueryVariables = {|
  eventId: string,
  includeEvent: boolean,
  filters: ContactFilters,
|};
export type ContactSearchQueryResponse = {|
  +org: {|
    +id: string,
    +salesforceAccount: ?{|
      +contactsSyncEnabled: boolean
    |},
    +viewerCanCreateContacts?: boolean,
    +contacts: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +avatar: ?string,
          +email: string,
          +salesforceSyncAs: ?ContactSyncOption,
          +syncStatus: {|
            +state: SyncState,
            +lastSuccessAt: ?any,
            +errorMessage: string,
          |},
          +company: ?{|
            +name: string
          |},
          +$fragmentRefs: ContactSearchRow_contact$ref,
        |}
      |}>
    |},
  |},
  +event?: ?{|
    +viewerCanCreateContacts?: boolean
  |},
|};
export type ContactSearchQuery = {|
  variables: ContactSearchQueryVariables,
  response: ContactSearchQueryResponse,
|};
*/


/*
query ContactSearchQuery(
  $eventId: ID!
  $includeEvent: Boolean!
  $filters: ContactFilters!
) {
  org {
    id
    salesforceAccount {
      contactsSyncEnabled
      id
    }
    viewerCanCreateContacts @skip(if: $includeEvent)
    contacts(first: 3, filters: $filters) {
      edges {
        node {
          id
          firstName
          lastName
          avatar
          email
          salesforceSyncAs
          syncStatus {
            state
            lastSuccessAt
            errorMessage
          }
          company {
            name
            id
          }
          ...ContactSearchRow_contact
        }
      }
    }
  }
  event: node(id: $eventId) @include(if: $includeEvent) {
    __typename
    ... on Event {
      viewerCanCreateContacts
    }
    id
  }
}

fragment ContactSearchRow_contact on Contact {
  firstName
  lastName
  email
  salesforceSyncAs
  title
  syncStatus {
    state
  }
  company {
    name
    salesforceId
    id
  }
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeEvent",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "ContactFilters!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactsSyncEnabled",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters",
    "type": "ContactFilters"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "salesforceSyncAs",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "syncStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "SyncStatus",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastSuccessAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "errorMessage",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v11 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "viewerCanCreateContacts",
    "args": null,
    "storageKey": null
  }
],
v12 = {
  "kind": "Condition",
  "passingValue": false,
  "condition": "includeEvent",
  "selections": v11
},
v13 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId",
    "type": "ID!"
  }
],
v14 = {
  "kind": "InlineFragment",
  "type": "Event",
  "selections": v11
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ContactSearchQuery",
  "id": null,
  "text": "query ContactSearchQuery(\n  $eventId: ID!\n  $includeEvent: Boolean!\n  $filters: ContactFilters!\n) {\n  org {\n    id\n    salesforceAccount {\n      contactsSyncEnabled\n      id\n    }\n    viewerCanCreateContacts @skip(if: $includeEvent)\n    contacts(first: 3, filters: $filters) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          avatar\n          email\n          salesforceSyncAs\n          syncStatus {\n            state\n            lastSuccessAt\n            errorMessage\n          }\n          company {\n            name\n            id\n          }\n          ...ContactSearchRow_contact\n        }\n      }\n    }\n  }\n  event: node(id: $eventId) @include(if: $includeEvent) {\n    __typename\n    ... on Event {\n      viewerCanCreateContacts\n    }\n    id\n  }\n}\n\nfragment ContactSearchRow_contact on Contact {\n  firstName\n  lastName\n  email\n  salesforceSyncAs\n  title\n  syncStatus {\n    state\n  }\n  company {\n    name\n    salesforceId\n    id\n  }\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ContactSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contacts",
            "storageKey": null,
            "args": v3,
            "concreteType": "ContactRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Contact",
                    "plural": false,
                    "selections": [
                      v1,
                      v4,
                      v5,
                      v6,
                      v7,
                      v8,
                      v9,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "company",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Company",
                        "plural": false,
                        "selections": [
                          v10
                        ]
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "ContactSearchRow_contact",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "event",
            "name": "node",
            "storageKey": null,
            "args": v13,
            "concreteType": null,
            "plural": false,
            "selections": [
              v14
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContactSearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v2,
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contacts",
            "storageKey": null,
            "args": v3,
            "concreteType": "ContactRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Contact",
                    "plural": false,
                    "selections": [
                      v1,
                      v4,
                      v5,
                      v6,
                      v7,
                      v8,
                      v9,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "company",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Company",
                        "plural": false,
                        "selections": [
                          v10,
                          v1,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "salesforceId",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "event",
            "name": "node",
            "storageKey": null,
            "args": v13,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              v1,
              v14
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '109f91339c8659b0c580b28b14ccbb84';
module.exports = node;
