/* @flow */
import { INLINE_IMAGE_CONTAINER } from '../Image';

const determineImage = (e: EventTarget) => {
  let element = e;
  const svgParts = ['rect', 'polygon', 'path', 'g'];
  // image container has 4 level of childs
  // or svg image clicked since svg image has not parentElement or referencing to itself
  for (let i = 0; i < 4; i += 1) {
    if (
      element instanceof Element &&
      (element.id === INLINE_IMAGE_CONTAINER || svgParts.includes(element.localName))
    ) {
      return true;
    }
    element =
      element instanceof Element && element.parentElement instanceof Element
        ? element.parentNode
        : element;
  }
  return false;
};
export default determineImage;
