/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import eventStaffFields from 'config/brief/eventStaffFields';

import { type BriefTemplateMutationVariables } from 'graph/mutations/briefTemplate/updateBriefTemplate';

import CheckBox from 'components/material/CheckBox';

import type { EventStaff_template } from './__generated__/EventStaff_template.graphql';

const StyledCheckBox = styled(CheckBox)`
  margin-right: 15px;
  &:not(:first-child) {
    margin-top: 20px;
  }
  > div:first-child {
    border-radius: 2px;
  }
`;

class EventStaff extends React.PureComponent<{
  template: EventStaff_template,
  onTemplateAttributeChange: (input: BriefTemplateMutationVariables) => void,
}> {
  handleFieldCheck = (fieldName: string, enabled: boolean) => {
    this.props.onTemplateAttributeChange({
      eventStaffFields: this.props.template.eventStaffFields.map(field =>
        field.name === fieldName
          ? {
              name: fieldName,
              enabled,
            }
          : { ...field },
      ),
    });
  };

  render() {
    return this.props.template.eventStaffFields.map(field => (
      <StyledCheckBox
        key={field.name}
        label={eventStaffFields[field.name]}
        checked={field.enabled}
        onChange={(checked: boolean) => this.handleFieldCheck(field.name, checked)}
      />
    ));
  }
}

export default createFragmentContainer(
  EventStaff,
  graphql`
    fragment EventStaff_template on BriefTemplate {
      eventStaffFields {
        name
        enabled
      }
    }
  `,
);
