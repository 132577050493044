/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventVendorsHeaderBar_event$ref: FragmentReference;
export type EventVendorsHeaderBar_event = {|
  +id: string,
  +dbId: number,
  +name: string,
  +slug: string,
  +viewerCanCreateVendors: boolean,
  +team: {|
    +dbId: number,
    +name: string,
  |},
  +$refType: EventVendorsHeaderBar_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventVendorsHeaderBar_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanCreateVendors",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        v0,
        v1
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '80fadf231ed509153d6e42d407732708';
module.exports = node;
