/* @flow */
import styled, { css } from 'styled-components';

import TableCell from './TableCell';

const TableFooter = styled.footer`
  display: flex;

  ${TableCell} {
    color: #3e4859;
  }

  ${props =>
    props.highlight &&
    css`
      background: #f2fbff;
      border-top: 1px solid #4db1dd;

      ${TableCell} {
        background: #f2fbff;

        &:not(:first-child) {
          border-color: transparent;
        }
      }
    `};

  ${props =>
    props.colWidths.map(
      (width, index) => css`
        ${TableCell}:nth-child(${index + 1}) {
          min-width: ${width}px;
        }
      `,
    )};
`;

export default TableFooter;
