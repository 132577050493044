/* @flow */
import React from 'react';
import styled from 'styled-components';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';

import invitePeople from 'graph/mutations/access/invitePeople';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InviteList, {
  type InviteFields,
  generateBlankInvite,
  invitesAreValid,
  validateInvite,
} from 'components/InviteWindow/InviteList';
import Button from 'components/material/Button';

const Container = styled.div`
  flex: 1 1 auto;
`;

const InfoMessage = styled.div`
  margin: 50px 0 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #3e4859;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

const StyledInviteList = styled(InviteList)`
  > * {
    background: transparent;
  }
`;

type UserType = {|
  +email: string,
  +firstName: string,
  +lastName: string,
|};

export default class EventsImportWindowConfig extends React.PureComponent<
  {
    orgId: string,
    orgUsers: $ReadOnlyArray<UserType>,
    importUsers: $ReadOnlyArray<UserType>,
    onNext: () => void,
  },
  {
    loading: boolean,
    invites: $ReadOnlyArray<InviteFields>,
  },
> {
  state = {
    loading: false,
    invites: uniqBy(
      [...this.props.importUsers],
      user => `${user.email}${user.firstName}${user.lastName}`,
    )
      .filter(user => !this.userExists(user))
      .map(user => ({ ...generateBlankInvite(), ...user })),
  };

  componentDidMount() {
    if (this.state.invites.length === 0) {
      this.props.onNext();
    }
  }

  componentDidUpdate() {
    if (this.state.invites.length === 0) {
      this.props.onNext();
    }
  }

  handleChangeInvites = (invites: $ReadOnlyArray<InviteFields>) => {
    this.setState({ invites });
  };

  validateInvites = () => {
    this.setState(state => ({ invites: state.invites.map(validateInvite) }));
    return invitesAreValid(this.state.invites.map(validateInvite));
  };

  handleInvite = () => {
    if (!this.validateInvites() || this.state.loading) return;

    this.setState({ loading: true });

    invitePeople(this.props.orgId, {
      personInvites: this.state.invites.map(invite =>
        pick(invite, ['email', 'firstName', 'lastName']),
      ),
      fromWindow: 'event staff',
    })
      .then(() => this.props.onNext())
      .catch(error => {
        showModernMutationError(error);

        this.setState({ loading: false });
      });
  };

  userExists(user: UserType) {
    if (user.email) {
      return this.props.orgUsers.some(orgUser => orgUser.email === user.email);
    }
    return this.props.orgUsers.some(
      orgUser =>
        (orgUser.firstName.toLowerCase() === user.firstName.toLowerCase() &&
          orgUser.lastName.toLowerCase() === user.lastName.toLowerCase()) ||
        (orgUser.firstName.toLowerCase() === user.lastName.toLowerCase() &&
          orgUser.lastName.toLowerCase() === user.firstName.toLowerCase()),
    );
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          {this.state.invites.length === 1 ? (
            <InfoMessage>
              We can’t find this user in your Workspace. Would you like to add them now?
            </InfoMessage>
          ) : (
            <InfoMessage>
              We can’t find these {this.state.invites.length} users in your Workspace. Would you
              like to add them now?
            </InfoMessage>
          )}

          <StyledInviteList
            invites={this.state.invites}
            onChange={this.handleChangeInvites}
            emailFirst
            canAddInvites={false}
            canRemoveInvites
            disableEditingInitial
          />
        </Container>

        <Buttons>
          <Button minimal label="Skip" onClick={this.props.onNext} />
          <SubmitButton
            primary
            label="Invite"
            onClick={this.handleInvite}
            loading={this.state.loading}
          />
        </Buttons>
      </React.Fragment>
    );
  }
}
