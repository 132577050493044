/* @flow */
import determineCustomFieldMinWidth from 'utils/customization/determineCustomFieldMinWidth';
import type { FieldType } from 'utils/customization/types';

import { type ColumnType } from 'components/budget/Table';
import kindToTableComponent from 'components/Customizable/kindToTableComponent';

import { defaultTableComponents } from './index';

export default (
  fields: $ReadOnlyArray<FieldType>,
  shownColumns: $ReadOnlyArray<string>,
): $ReadOnlyArray<ColumnType<any, any>> => {
  return fields
    .filter(field => shownColumns.includes(field.fieldName || field.id))
    .map(field => {
      if (field.fieldName != null) {
        return {
          title: field.label,
          fieldSettings: field,
          component: defaultTableComponents[field.fieldName],
          sortKey: field.fieldName.toUpperCase(),
          minWidth: ['email', 'website', 'linkedin', 'twitter'].includes(field.fieldName)
            ? 215
            : field.minWidth,
          ...(field.fieldName === 'name' ? { grow: 1 } : null),
        };
      }

      return {
        title: field.label,
        fieldSettings: field,
        sortKey: field.id,
        align: ['CURRENCY', 'NUMBER'].includes(field.kind) ? 'right' : 'left',
        component: kindToTableComponent[field.kind],
        minWidth: determineCustomFieldMinWidth(field.kind),
      };
    });
};
