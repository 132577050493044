/**
 * @flow
 * @relayHash 2b00345cd52e10cfef7d4e6f8f930352
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveDeliverablesTagInput = {
  clientMutationId?: ?string,
  deliverableIds: $ReadOnlyArray<string>,
  tagId: string,
  fromWindow: string,
};
export type removeTasksTagMutationVariables = {|
  input: RemoveDeliverablesTagInput
|};
export type removeTasksTagMutationResponse = {|
  +removeDeliverablesTag: ?{|
    +deliverables: $ReadOnlyArray<{|
      +id: string,
      +tags: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string
          |}
        |}>
      |},
    |}>
  |}
|};
export type removeTasksTagMutation = {|
  variables: removeTasksTagMutationVariables,
  response: removeTasksTagMutationResponse,
|};
*/


/*
mutation removeTasksTagMutation(
  $input: RemoveDeliverablesTagInput!
) {
  removeDeliverablesTag(input: $input) {
    deliverables {
      id
      tags {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveDeliverablesTagInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeDeliverablesTag",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveDeliverablesTagInput!"
      }
    ],
    "concreteType": "RemoveDeliverablesTagPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "Deliverable",
        "plural": true,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tags",
            "storageKey": null,
            "args": null,
            "concreteType": "TagRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Tag",
                    "plural": false,
                    "selections": [
                      v1
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeTasksTagMutation",
  "id": null,
  "text": "mutation removeTasksTagMutation(\n  $input: RemoveDeliverablesTagInput!\n) {\n  removeDeliverablesTag(input: $input) {\n    deliverables {\n      id\n      tags {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeTasksTagMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "removeTasksTagMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6c2075896370859d8c6a3ce13fdcdfd';
module.exports = node;
