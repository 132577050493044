/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

import fullNameOfUser from 'utils/fullNameOfUser';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import InviteWindow, { type InviteFromWindow } from 'components/InviteWindow';
import SelectField, { type RenderProps } from 'components/material/SelectField';
import UsersImportWindow from 'components/UsersImportWindow';

import UserOptionRow from './UserOptionRow';

import type { UserSelectQuery_response } from './__generated__/UserSelectQuery.graphql';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  line-height: 1.4;
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
  i {
    margin-right: 5px;
  }
`;

const query = graphql`
  query UserSelectQuery($eventId: ID!, $query: String!, $includeEvent: Boolean!) {
    org @skip(if: $includeEvent) {
      viewerCanManageStaff
      users(first: 3, query: $query) {
        edges {
          node {
            id
            firstName
            lastName
            email
            ...UserOptionRow_user
          }
        }
      }
    }
    event: node(id: $eventId) @include(if: $includeEvent) {
      ... on Event {
        viewerCanAddStaff
        staffers(first: 3, query: $query) {
          edges {
            node {
              user {
                id
                firstName
                lastName
                email
                ...UserOptionRow_user
              }
            }
          }
        }
        team {
          users(first: 3, query: $query) {
            edges {
              node {
                id
                firstName
                lastName
                email
                ...UserOptionRow_user
              }
            }
          }
        }
      }
    }
  }
`;

export type User = {
  +id: string,
  +firstName: string,
  +lastName: string,
  +email: string,
  +avatar?: ?string,
};

export default class UserSelect extends React.PureComponent<
  {
    user?: ?User,
    onSelect: (user: ?User) => void,
    onHideOptions?: (ownerId?: string) => void,
    className?: string,
    label?: string,
    placeholder?: string,
    searchPlaceholder?: string,
    error?: ?string,
    disabled?: boolean,
    required?: boolean,
    clearable?: boolean,
    noBoxShadow?: boolean,
    autoFocus?: boolean,
    eventId?: string,
    onToggleOwnerQuery?: (present: boolean) => void,
    fromWindow?: InviteFromWindow,
    hideCreateNewOption?: boolean,
  },
  {
    query: string,
    showInviteWindow: boolean,
    showImportUsersWindow: boolean,
  },
> {
  state = {
    query: this.props.user ? fullNameOfUser(this.props.user) : '',
    showInviteWindow: false,
    showImportUsersWindow: false,
  };

  userQueryExists: ?boolean;

  handleSearch = debounce((search: string) => {
    this.setState({ query: search });
    this.handleUserSearch(search);
  }, 800);

  handleChange = (user: ?User) => {
    this.props.onSelect(user);
    if (user == null) {
      this.handleSearch('');
    }
  };

  handleUserSearch = (searchQuery?: string = '') => {
    if (this.props.onHideOptions) {
      this.props.onHideOptions(searchQuery);
    }
    if (searchQuery.trim() && !this.userQueryExists && this.props.onToggleOwnerQuery) {
      this.props.onToggleOwnerQuery(true);
      this.userQueryExists = true;
    }
    if (!searchQuery.trim() && this.userQueryExists && this.props.onToggleOwnerQuery) {
      this.props.onToggleOwnerQuery(false);
      this.userQueryExists = false;
    }
  };

  handleShowInviteWindow = () => {
    this.setState({ showInviteWindow: true });
  };

  handleHideInviteWindow = (users?: ?$ReadOnlyArray<User>) => {
    if (users) {
      this.handleChange((users[0]: User));
    }
    this.setState({ showInviteWindow: false });
  };

  handleShowImportUsersWindow = () => {
    this.handleHideInviteWindow();

    this.setState({ showImportUsersWindow: true });
  };

  handleHideImportUsersWindow = () => {
    this.setState({ showImportUsersWindow: false });
  };

  getUsers = (response?: UserSelectQuery_response): Array<User> => {
    if (!response) {
      return [];
    }
    if (response.org) {
      return response.org.users.edges.map(({ node }) => node);
    }
    if (!response.event) {
      return [];
    }
    return uniqBy(
      [
        ...response.event.staffers.edges.map(({ node }) => node.user),
        ...response.event.team.users.edges.map(({ node }) => node),
      ],
      'id',
    );
  };

  addCreateNewOption = (response?: UserSelectQuery_response) => {
    if (!response) {
      return null;
    }
    const { org, event } = response;
    if (org) {
      return org.viewerCanManageStaff
        ? {
            label: 'Add new Workspace Member',
            value: '-1',
            render: (props: RenderProps<string>) => (
              <Row key="-1" onClick={this.handleShowInviteWindow}>
                <i className="fa fa-plus fa-fw" />
                {props.label}
              </Row>
            ),
          }
        : null;
    }

    if (!event || !event.viewerCanAddStaff) {
      return null;
    }
    return {
      label: 'Add Event Staff',
      value: '-1',
      render: (props: RenderProps<string>) => (
        <Row key="-1" onClick={this.handleShowInviteWindow}>
          <i className="fa fa-plus fa-fw" />
          {props.label}
        </Row>
      ),
    };
  };

  renderSelect = (response?: UserSelectQuery_response) => {
    const {
      user,
      className,
      label,
      placeholder,
      searchPlaceholder,
      disabled,
      required,
      clearable,
      noBoxShadow,
      autoFocus,
      error,
      hideCreateNewOption,
    } = this.props;
    const users: Array<User> = this.getUsers(response);

    if (user && !users.some(u => u.id === user.id)) {
      users.unshift(user);
    }

    const options = [
      ...users.map(userOption => ({
        label: fullNameOfUser(userOption),
        value: userOption.id,
        render: props => (
          <UserOptionRow
            key={userOption.id}
            user={userOption}
            inEvent={response && response.event}
            {...props}
          />
        ),
      })),
      !hideCreateNewOption ? this.addCreateNewOption(response) : null,
    ].filter(Boolean);

    return (
      <SelectField
        className={className}
        label={label}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder}
        disabled={disabled}
        required={required}
        searchable
        options={options}
        onSearch={this.handleSearch}
        onChange={(userId: ?string) => this.handleChange(users.find(u => u.id === userId))}
        value={user && user.id}
        loading={!response}
        clearable={clearable}
        noBoxShadow={noBoxShadow}
        autoFocus={autoFocus}
        onHideOptions={this.handleUserSearch}
        error={error}
      />
    );
  };

  render() {
    const { user, eventId, fromWindow } = this.props;
    const searchQuery = this.state.query || (user ? fullNameOfUser(user) : '');
    const variables = { query: searchQuery, eventId: eventId || '', includeEvent: !!eventId };
    return (
      <>
        {this.state.showInviteWindow && (
          <InviteWindow
            onHide={this.handleHideInviteWindow}
            eventId={this.props.eventId}
            onClickImport={this.handleShowImportUsersWindow}
            fromWindow={fromWindow || 'easy_button'}
          />
        )}
        {this.state.showImportUsersWindow && (
          <UsersImportWindow onClose={this.handleHideImportUsersWindow} />
        )}
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={this.renderSelect}
          renderLoading={this.renderSelect}
        />
      </>
    );
  }
}
