/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import { Plus } from 'images';
import Remove from 'images/remove.svg';
import WarningIcon from 'images/warning.svg';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import PercentField from 'components/material/PercentField';

import TeamSelectField, { type TeamAllocationType } from './TeamSelectField';

import type { AddTeamActionQueryResponse } from './__generated__/AddTeamActionQuery.graphql';

const Container = styled.div`
  padding: 17px 18px 15px 15px;
`;

const RemoveButton = styled(Remove)`
  position: absolute;
  right: 0;
  width: 14px;
  height: 14px;
  margin-left: 15px;
  color: #cdd1d4;
  cursor: pointer;
  visibility: hidden;
`;

const TeamRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  &:hover {
    ${RemoveButton} {
      visibility: visible;
    }
  }
`;

const TeamLabel = styled.div`
  flex: 1 1 auto;
  font-size: 13px;
  color: #4a5665;
`;

const StyledPercentField = styled(PercentField)`
  width: 61px;
  margin: 0 25px 0 0;
  input {
    padding: 1px 5px 0 9px;
    border: solid 1px #d9d9d9;
    border-radius: 3px;
    transition: border 0.3s;
    font-size: 13px;
    background: #fff;
    color: #4a5665;
    &:hover {
      border: solid 1px #c3e0ec;
    }
    &:focus {
      border-color: #3ba9da;
    }
  }
`;

const Action = styled.div`
  font-size: 13px;
  cursor: pointer;
  color: #3ba9da;
`;

const PlusIcon = styled(Plus)`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  cursor: pointer;
  color: ${props => props.theme.primaryActionColor};
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 5px 20px 6px 14px;
  background-color: #fff7e8;
  font-size: 12px;
  font-style: italic;
  color: #677386;
`;

const StyledWarningIcon = styled(WarningIcon)`
  width: 21px;
  height: 15px;
  margin-top: 2px;
  margin-right: 10px;
  color: #ffb634;
`;

const query = graphql`
  query AddTeamActionQuery {
    me {
      memberships {
        edges {
          node {
            id
            viewerCanUpdate
            ...TeamSelectField_teams
          }
        }
      }
    }
  }
`;

export default class AddTeamAction extends React.Component<
  {
    teamAllocations: $ReadOnlyArray<TeamAllocationType>,
    onSetTeamAllocations: (teams: $ReadOnlyArray<TeamAllocationType>) => void,
    className?: string,
  },
  {
    showTeamSelect: boolean,
    teams: $ReadOnlyArray<TeamAllocationType>,
  },
> {
  state = {
    teams: this.props.teamAllocations,
    showTeamSelect: false,
  };

  isTotalPercent100 = () =>
    this.state.teams.length === 0 ||
    this.state.teams.reduce((sum, t) => sum + t.percent, 0) === 100;

  saveTeamAllocations = () => {
    if (this.isTotalPercent100()) {
      this.props.onSetTeamAllocations(this.state.teams.filter(team => team.percent > 0));
    }
  };

  handleShowTeamSelect = () => {
    this.setState({ showTeamSelect: true });
  };

  handleHideTeamSelect = () => {
    this.setState({ showTeamSelect: false });
  };

  getModifiedExistingTeams = (
    teams: $ReadOnlyArray<TeamAllocationType>,
    percent: number,
  ): $ReadOnlyArray<TeamAllocationType> => {
    const teamPercent100 = teams.find(team => team.percent === 100);
    if (!teamPercent100 || percent >= 100 || percent === 0) {
      return teams;
    }
    return teams.map(team => {
      return team.id === teamPercent100.id ? { ...team, percent: 100 - percent } : team;
    });
  };

  handleAddTeam = (team: TeamAllocationType) => {
    this.setState(
      prevState => {
        return { teams: [...prevState.teams, team], showTeamSelect: false };
      },
      () => {
        this.saveTeamAllocations();
      },
    );
  };

  handlePercentChange = (
    e: SyntheticEvent<HTMLInputElement>,
    percent: number,
    team: TeamAllocationType,
  ) => {
    const oldTeam = this.state.teams.find(t => t.id === team.id);
    if (oldTeam && oldTeam.percent !== percent) {
      this.setState(
        prevState => {
          const teams = this.getModifiedExistingTeams(prevState.teams, percent);
          return {
            teams: [
              ...teams.filter(t => t.id !== team.id),
              { id: team.id, name: team.name, percent, viewerCanUpdate: true },
            ],
          };
        },
        () => {
          this.saveTeamAllocations();
        },
      );
    }
  };

  handleRemoveTeam = (teamId: string) => {
    this.setState(
      prevState => ({ teams: prevState.teams.filter(t => t.id !== teamId) }),
      () => {
        this.saveTeamAllocations();
      },
    );
  };

  handleKeyDownOnPercent = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  render() {
    const teams = sortBy(this.state.teams, 'name');
    return (
      <React.Fragment>
        <Container className={this.props.className}>
          {teams.map(team => (
            <TeamRow key={team.id}>
              <TeamLabel>{team.name}</TeamLabel>
              <StyledPercentField
                symbol="%"
                value={team.percent}
                disabled={!team.viewerCanUpdate}
                onKeyDown={this.handleKeyDownOnPercent}
                onBlur={(e, value) => this.handlePercentChange(e, value, team)}
              />
              {teams.length > 1 && team.viewerCanUpdate && (
                <RemoveButton onClick={() => this.handleRemoveTeam(team.id)} />
              )}
            </TeamRow>
          ))}
          <DefaultQueryRenderer
            query={query}
            renderSuccess={(response: AddTeamActionQueryResponse) => {
              const allTeams = response.me.memberships.edges.map(edge => edge.node);
              const showTeamAction =
                !this.state.showTeamSelect &&
                teams.filter(team => team.viewerCanUpdate).length < allTeams.length;

              return (
                <React.Fragment>
                  {this.state.showTeamSelect && (
                    <TeamSelectField
                      teams={response.me.memberships.edges
                        .filter(edge => !teams.some(team => team.id === edge.node.id))
                        .map(edge => edge.node)}
                      onAddTeam={this.handleAddTeam}
                      onHideTeamSelect={this.handleHideTeamSelect}
                    />
                  )}
                  {showTeamAction && (
                    <Action onClick={this.handleShowTeamSelect}>
                      <PlusIcon /> Add Team
                    </Action>
                  )}
                </React.Fragment>
              );
            }}
            renderLoading={() => null}
          />
        </Container>
        {!this.isTotalPercent100() && (
          <Footer>
            <StyledWarningIcon />
            The total % for team allocation should be 100%.
          </Footer>
        )}
      </React.Fragment>
    );
  }
}
