/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import EventRequestsItemRequestedDate from 'views/Main/Dashboard/EventRequests/columns/EventRequestsItemRequestedDate';

import type { EventRequestsRequestedDate_event } from './__generated__/EventRequestsRequestedDate_event.graphql';

type Props = {
  event: EventRequestsRequestedDate_event,
};

const EventRequestsRequestedDate = ({ event }: Props) => {
  if (!event.eventRequestSubmission) {
    return null;
  }

  return (
    <EventRequestsItemRequestedDate
      tz={event.tz}
      eventRequestSubmission={event.eventRequestSubmission}
    />
  );
};

export default createFragmentContainer(
  EventRequestsRequestedDate,
  graphql`
    fragment EventRequestsRequestedDate_event on Event {
      tz
      eventRequestSubmission {
        ...EventRequestsItemRequestedDate_eventRequestSubmission
      }
    }
  `,
);
