/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import TeamMembersContainer from './TeamMembersContainer';

import type { TeamMembersQueryResponse } from './__generated__/TeamMembersQuery.graphql';

const query = graphql`
  query TeamMembersQuery($teamSlug: String!) {
    org {
      ...TeamMembersContainer_org
    }
    team(slug: $teamSlug) {
      id
    }
  }
`;

const Container = styled.div`
  padding: 30px 33px 15px; ;
`;

export default class TeamMembers extends React.Component<{
  teamSlug: string,
}> {
  render() {
    return (
      <Container>
        <DefaultQueryRenderer
          query={query}
          variables={{ teamSlug: this.props.teamSlug }}
          renderSuccess={(props: TeamMembersQueryResponse) => (
            <TeamMembersContainer {...this.props} org={props.org} teamId={props.team.id} />
          )}
        />
      </Container>
    );
  }
}
