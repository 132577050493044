/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import removeStaffer from 'graph/mutations/access/removeStaffer';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import ManageStaffWindow from 'components/ManageStaffWindow';
import ShareDropdown from 'components/ShareDropdown';

import type { StaffActionsCell_staffer } from './__generated__/StaffActionsCell_staffer.graphql';

const Root = styled.div`
  margin: 0 -5px 0 -24px;
`;

class StaffActionsCell extends React.PureComponent<
  {
    staffer: StaffActionsCell_staffer,
    eventId: string,
    userId: string,
  },
  {
    showConfirmation: boolean,
    loading: boolean,
    showManage: boolean,
  },
> {
  state = {
    showConfirmation: false,
    loading: false,
    showManage: false,
  };

  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  handleShowManage = () => {
    this.setState({ showManage: true });
  };

  handleHideManage = () => {
    this.setState({ showManage: false });
  };

  handleRemove = () => {
    const {
      eventId,
      userId: currentUserId,
      staffer: {
        belongsToTeam,
        user: { id: userId },
      },
    } = this.props;
    this.setState({ loading: true });
    removeStaffer({ eventId, userId })
      .catch(showModernMutationError)
      .then(() => {
        if (currentUserId === userId && !belongsToTeam) {
          // Do a hard refresh
          window.location = '/';
        }
      });
  };

  render() {
    const currentUser = this.props.userId === this.props.staffer.user.id;
    const actions = [
      this.props.staffer.viewerCanUpdateAccessLevel
        ? {
            label: 'Manage Access Level',
            icon: 'lock',
            onClick: this.handleShowManage,
          }
        : null,
      this.props.staffer.viewerCanRemove
        ? {
            label: currentUser ? 'Leave from Staff' : 'Remove from Staff',
            icon: currentUser ? 'sign-out' : 'times',
            onClick: this.handleShowConfirmation,
          }
        : null,
    ].filter(Boolean);

    if (actions.length === 0) {
      return null;
    }

    return (
      <Root onClick={this.stopPropagation}>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={actions}
          overlayContainer={document.body}
        />
        {this.state.showConfirmation &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              actionLabel="Remove"
              onHide={this.handleHideConfirmation}
              onConfirm={this.handleRemove}
              message={`${
                currentUser ? 'You' : 'They'
              } will be removed from Tasks, Schedules and other parts of this Event.`}
              loading={this.state.loading}
            />,
            document.body,
          )}

        {this.state.showManage &&
          document.body &&
          createPortal(
            <ManageStaffWindow
              onHide={this.handleHideManage}
              accessLevel={this.props.staffer.accessLevel}
              eventId={this.props.eventId}
              userId={this.props.staffer.user.id}
              invitedUser={this.props.staffer.user.inviteStatus !== 'NOT_INVITED'}
            />,
            document.body,
          )}
      </Root>
    );
  }
}

export default createFragmentContainer(
  StaffActionsCell,
  graphql`
    fragment StaffActionsCell_staffer on StaffMembership {
      viewerCanUpdateAccessLevel
      viewerCanRemove
      belongsToTeam
      accessLevel
      user {
        id
        inviteStatus
      }
    }
  `,
);
