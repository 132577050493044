/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import UsersGroup from 'components/material/UsersGroup';

import type { EventStaff_event } from './__generated__/EventStaff_event.graphql';

const StyledUsersGroup = styled(UsersGroup)`
  padding-right: 17px;
  margin: -6px -18px -6px -10px;
`;

const EventStaff = (props: { event: EventStaff_event }) => {
  const users = props.event.staffers.edges.map(({ node }) => node.user);
  return <StyledUsersGroup users={users} />;
};

export default createFragmentContainer(
  EventStaff,
  graphql`
    fragment EventStaff_event on Event {
      staffers {
        edges {
          node {
            user {
              ...UsersGroup_users
            }
          }
        }
      }
    }
  `,
);
