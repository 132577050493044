import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';

import { createMoment } from 'utils/Date';

import SharedScheduleListRow from './SharedScheduleListRow';

class SharedScheduleList extends React.Component {
  static propTypes = {
    scheduleDay: PropTypes.any,
    time_zone: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    member: PropTypes.string,
  };

  render() {
    const { color, time_zone, member } = this.props;
    const scheduleItems = this.props.scheduleDay.scheduleItems.edges.map(x => x.node);
    const moment = createMoment(time_zone);
    const filteredItems = member
      ? scheduleItems.filter(i =>
          i.scheduleParticipants.edges.some(({ node }) => node.user.slug === member),
        )
      : scheduleItems;
    const schedule_items = sortBy(filteredItems, [
      s => moment(s.start_time).getTime(),
      s => (s.end_time ? moment(s.end_time).getTime() : 0),
    ]);

    return (
      <div>
        <table width="100%" style={{ maxWidth: '600px' }}>
          <tbody width="100%">
            {schedule_items.map(item => (
              <SharedScheduleListRow
                scheduleItem={item}
                key={item.id}
                time_zone={this.props.time_zone}
                color={color}
              />
            ))}
          </tbody>
        </table>
        <br />
      </div>
    );
  }
}

export default createFragmentContainer(
  SharedScheduleList,
  graphql`
    fragment SharedScheduleList_scheduleDay on ScheduleDay {
      date
      scheduleItems {
        edges {
          node {
            id
            start_time
            end_time
            all_day
            scheduleParticipants {
              edges {
                node {
                  user {
                    slug
                  }
                }
              }
            }
            ...SharedScheduleListRow_scheduleItem
          }
        }
      }
    }
  `,
);
