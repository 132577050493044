/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type TaskStatus = "COMPLETED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TasksFolder_tasks$ref: FragmentReference;
export type TasksFolder_tasks = $ReadOnlyArray<{|
  +status: TaskStatus,
  +$refType: TasksFolder_tasks$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TasksFolder_tasks",
  "type": "Deliverable",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '4d84500e6d89a46a2c4aa64a14fa3b16';
module.exports = node;
