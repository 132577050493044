import { createBrowserHistory } from 'history';

import { page } from 'utils/analytics';
import { initFullStory } from 'utils/analytics/fullstory';

/* Singleton in its own file because it can't be reloaded by React Hot Loader */
const browserHistory = createBrowserHistory();

browserHistory.listen(location => {
  page(location.pathname);
  initFullStory(location.pathname);
});

export default function setupBrowserHistory() {
  return browserHistory;
}
