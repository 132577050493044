/**
 * @flow
 * @relayHash 752788bc9eec9948d6efede6956a3fd1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactSyncOption = "contact" | "lead";
export type AddDeliverableContactInput = {
  clientMutationId?: ?string,
  deliverableId: string,
  contactId: string,
};
export type addTaskContactMutationVariables = {|
  input: AddDeliverableContactInput
|};
export type addTaskContactMutationResponse = {|
  +addDeliverableContact: ?{|
    +contactEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +firstName: string,
        +lastName: string,
        +email: string,
        +salesforceSyncAs: ?ContactSyncOption,
        +slug: string,
      |},
    |}
  |}
|};
export type addTaskContactMutation = {|
  variables: addTaskContactMutationVariables,
  response: addTaskContactMutationResponse,
|};
*/


/*
mutation addTaskContactMutation(
  $input: AddDeliverableContactInput!
) {
  addDeliverableContact(input: $input) {
    contactEdge {
      __typename
      node {
        id
        firstName
        lastName
        email
        salesforceSyncAs
        slug
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddDeliverableContactInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addDeliverableContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddDeliverableContactInput!"
      }
    ],
    "concreteType": "AddDeliverableContactPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contactEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "ContactRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Contact",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "salesforceSyncAs",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "slug",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addTaskContactMutation",
  "id": null,
  "text": "mutation addTaskContactMutation(\n  $input: AddDeliverableContactInput!\n) {\n  addDeliverableContact(input: $input) {\n    contactEdge {\n      __typename\n      node {\n        id\n        firstName\n        lastName\n        email\n        salesforceSyncAs\n        slug\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addTaskContactMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "addTaskContactMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc1595f2aed047eca79f04d250098cc9';
module.exports = node;
