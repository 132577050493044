/* @flow */
import React from 'react';
import { type RouterHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import UserStore from 'stores/UserStore';

import withQueryParams from 'utils/routing/withQueryParams';
import isValidEmail from 'utils/validators/isValidEmail';

import AuthButton from 'components/Auth/AuthButton';
import ErrorBlock from 'components/Auth/AuthErrorBlock';
import AuthFooter from 'components/Auth/AuthFooter';
import GoogleButton from 'components/Auth/AuthGoogleButton';
import AuthOptionDivider from 'components/Auth/AuthOptionDivider';
import AuthWindow from 'components/Auth/AuthWindow';
import TextField, { Label } from 'components/material/TextField';

import AcceptAgreementsCheckBoxes from '../components/AcceptAgreementsCheckBoxes';

const StyledTextField = styled(TextField)`
  margin: 16px 0 24px 0;

  input {
    &:focus {
      border-bottom-color: #5e5bff;

      ~ ${Label} {
        color: #5e5bff;
      }
    }
  }
`;

const StyledButton = styled(AuthButton)`
  margin-top: 40px;
  padding: 10px 14px;
  border-radius: 52px;
  background-color: #5e5bff;

  &:hover {
    background-color: #403ec9;
  }
`;

const FakeInput = styled.input`
  position: absolute;
  z-index: -1;
`;

const StyledParagraph = styled.p`
  a {
    color: #5554c3;
  }
`;

class SignUp extends React.PureComponent<
  {
    history: RouterHistory,
  },
  {
    emailError: boolean,
    passwordError: boolean,
    termsError: boolean,
    loading: boolean,
    thankYouText: ?string,
    serverError: ?string | ?{},
    acceptedTOS: boolean,
    acceptedPrivacyPolicy: boolean,
  },
> {
  state = {
    emailError: false,
    passwordError: false,
    termsError: false,
    serverError: null,
    loading: false,
    thankYouText: null,
    acceptedTOS: false,
    acceptedPrivacyPolicy: false,
  };

  email: HTMLInputElement;

  password: HTMLInputElement;

  handleSignUp = e => {
    e.preventDefault();

    const email = this.email.value;
    const password = this.password.value;

    const errors = {
      emailError: !isValidEmail(email),
      passwordError: password.length < 8,
      termsError: !this.state.acceptedTOS || !this.state.acceptedPrivacyPolicy,
    };

    this.setState(errors);

    if (errors.emailError || errors.passwordError || errors.termsError) return;

    this.setState({ loading: true });
    UserStore.signup(
      email,
      password,
      response => {
        if (response && response.message) {
          return this.setState({ loading: false, thankYouText: response.message });
        }
        return null;
      },
      error => {
        this.setState({ loading: false, serverError: error });
      },
    );
  };

  handleInviteResend = () => {
    UserStore.resendInviteConfirmation(this.email.value).then(() => {
      this.setState({ thankYouText: 'We resent your invitation. Please check you inbox.' });
    });
  };

  handleErrorDismiss = () => {
    this.setState({ serverError: null });
  };

  handleConfirmationButtonClick = () => {
    this.props.history.push('/');
  };

  handleChangeAgreements = values => {
    this.setState(values);
  };

  showError = error => {
    this.setState({ serverError: error });
  };

  renderThankYouWindow() {
    return (
      <AuthWindow title="Thank you!">
        <p>{this.state.thankYouText}</p>
        <StyledButton onClick={this.handleConfirmationButtonClick} label="OK" primary />
      </AuthWindow>
    );
  }

  render() {
    if (this.state.thankYouText) {
      return this.renderThankYouWindow();
    }

    return (
      <AuthWindow title="Sign Up">
        <GoogleButton onError={this.showError} label="Sign Up with Google" />
        <AuthOptionDivider />
        <div>
          {typeof this.state.serverError === 'string' &&
          this.state.serverError.includes('already registered by a teammate') ? (
            <ErrorBlock onDismiss={this.handleErrorDismiss}>
              <p>
                Hey there! Your account was already registered by a teammate and it&apos;s just
                waiting to be confirmed.
              </p>

              <p>
                You should&apos;ve already received an invitation with a confirmation link.
                <a onClick={this.handleInviteResend}>Click here to resend.</a>
              </p>
            </ErrorBlock>
          ) : (
            this.state.serverError && (
              <ErrorBlock text={this.state.serverError} onDismiss={this.handleErrorDismiss} />
            )
          )}
          <form onSubmit={this.handleSignUp} noValidate>
            <FakeInput type="email" name="email" />
            <FakeInput type="password" name="password" />
            <StyledTextField
              error={this.state.emailError ? 'Email is not valid' : null}
              type="email"
              autoFocus
              label="Email"
              inputRef={input => {
                this.email = input;
              }}
            />
            <StyledTextField
              error={
                this.state.passwordError ? 'Password must be at least 8 characters long' : null
              }
              type="password"
              label="Password"
              inputRef={input => {
                this.password = input;
              }}
            />
            <AcceptAgreementsCheckBoxes
              values={{
                acceptedTOS: this.state.acceptedTOS,
                acceptedPrivacyPolicy: this.state.acceptedPrivacyPolicy,
              }}
              onChange={this.handleChangeAgreements}
              error={this.state.termsError}
            />
            <StyledButton type="submit" label="Sign Up" primary loading={this.state.loading} />
          </form>
        </div>
        <AuthFooter>
          <StyledParagraph>
            Already have an account? <Link to="/">Sign In</Link>
          </StyledParagraph>
          <StyledParagraph>
            Didn&apos;t receive an account confirmation email? <Link to="/resend">Resend</Link>
          </StyledParagraph>
        </AuthFooter>
      </AuthWindow>
    );
  }
}

export default withQueryParams(SignUp);
