/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import Content from '../../layout';
import ContactPage from './ContactPage';

import type { ContactQueryResponse } from './__generated__/ContactQuery.graphql';

const query = graphql`
  query ContactQuery($contactSlug: String!, $includeEvent: Boolean!, $eventSlug: String) {
    org {
      ...ContactPage_org
    }
    contact(slug: $contactSlug) {
      ...ContactPage_contact
    }
    ... @include(if: $includeEvent) {
      event(slug: $eventSlug) {
        ...ContactPage_event
      }
    }
    user: me {
      ...ContactPage_user
    }
  }
`;

const StyledContent = styled(Content)`
  padding: 10px 0 0;
`;

export default class ContactRoot extends React.Component<{
  match: Match,
  history: History,
  location: Location,
}> {
  render() {
    const contactSlug = this.props.match.params.contact_slug;
    return (
      <StyledContent>
        <DefaultQueryRenderer
          query={query}
          variables={{
            contactSlug,
            eventSlug: this.props.match.params.event_slug,
            includeEvent: this.props.match.params.event_slug != null,
          }}
          renderSuccess={(props: ContactQueryResponse) =>
            props.contact &&
            props.user && (
              <ContactPage
                org={props.org}
                event={props.event || null}
                contact={props.contact}
                user={props.user}
                location={this.props.location}
                history={this.props.history}
                match={this.props.match}
              />
            )
          }
        />
      </StyledContent>
    );
  }
}
