/**
 * @flow
 * @relayHash 96e219a96be80ed7cf84648709d9f48c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventNotesSearchPagination_searchResults$ref = any;
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type EventNotesSearchPaginationQueryVariables = {|
  search: Search,
  count: number,
  cursor?: ?string,
|};
export type EventNotesSearchPaginationQueryResponse = {|
  +$fragmentRefs: EventNotesSearchPagination_searchResults$ref
|};
export type EventNotesSearchPaginationQuery = {|
  variables: EventNotesSearchPaginationQueryVariables,
  response: EventNotesSearchPaginationQueryResponse,
|};
*/


/*
query EventNotesSearchPaginationQuery(
  $search: Search!
  $count: Int!
  $cursor: String
) {
  ...EventNotesSearchPagination_searchResults
}

fragment EventNotesSearchPagination_searchResults on Query {
  eventNotes: searchEventNotes(search: $search, after: $cursor, first: $count) {
    edges {
      node {
        noteId
        ...EventNoteSearchResult_note
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment EventNoteSearchResult_note on EventNoteSearchResult {
  noteId
  name
  eventName
  eventSlug
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "Search!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search",
    "type": "Search!"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventNotesSearchPaginationQuery",
  "id": null,
  "text": "query EventNotesSearchPaginationQuery(\n  $search: Search!\n  $count: Int!\n  $cursor: String\n) {\n  ...EventNotesSearchPagination_searchResults\n}\n\nfragment EventNotesSearchPagination_searchResults on Query {\n  eventNotes: searchEventNotes(search: $search, after: $cursor, first: $count) {\n    edges {\n      node {\n        noteId\n        ...EventNoteSearchResult_note\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EventNoteSearchResult_note on EventNoteSearchResult {\n  noteId\n  name\n  eventName\n  eventSlug\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventNotesSearchPaginationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventNotesSearchPagination_searchResults",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventNotesSearchPaginationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "eventNotes",
        "name": "searchEventNotes",
        "storageKey": null,
        "args": v1,
        "concreteType": "EventNoteSearchResultRequiredConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "EventNoteSearchResultRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "EventNoteSearchResult",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "noteId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "eventName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "eventSlug",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": "eventNotes",
        "name": "searchEventNotes",
        "args": v1,
        "handle": "connection",
        "key": "EventNotesTablePagination_eventNotes",
        "filters": []
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f99f9da2083d6b12f412d02506163c9a';
module.exports = node;
