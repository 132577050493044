/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesforceCampaignData_campaign$ref: FragmentReference;
export type SalesforceCampaignData_campaign = {|
  +budgetedCost: ?number,
  +actualCost: ?number,
  +numberOfContacts: number,
  +numberOfLeads: number,
  +numberOfConvertedLeads: number,
  +numberOfOpportunities: number,
  +numberOfWonOpportunities: number,
  +amountAllOpportunities: number,
  +amountWonOpportunities: number,
  +totalAmountAllOpportunities: ?number,
  +totalAmountAllWonOpportunities: ?number,
  +totalNumberOfOpportunities: ?number,
  +totalNumberOfWonOpportunities: ?number,
  +$refType: SalesforceCampaignData_campaign$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SalesforceCampaignData_campaign",
  "type": "SalesforceCampaign",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "budgetedCost",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "actualCost",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfContacts",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfLeads",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfConvertedLeads",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfOpportunities",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "numberOfWonOpportunities",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amountAllOpportunities",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amountWonOpportunities",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalAmountAllOpportunities",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalAmountAllWonOpportunities",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalNumberOfOpportunities",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalNumberOfWonOpportunities",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c918dbad4e4698f6c8bd09c79917044a';
module.exports = node;
