/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type FormComponent_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ColumnComponent_org$ref: FragmentReference;
export type ColumnComponent_org = {|
  +$fragmentRefs: FormComponent_org$ref,
  +$refType: ColumnComponent_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ColumnComponent_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FormComponent_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd2349433af5a4c7a111b3b4e28733a69';
module.exports = node;
