/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import discardRegistrationFormChanges from 'graph/mutations/registration/discardRegistrationFormChanges';
import showModernMutationError from 'graph/utils/showModernMutationError';

import { OutlineButton } from 'components/budget/Button';
import ConfirmationWindow from 'components/ConfirmationWindow';

import LiveActions from '../LiveActions';

import type { RegistrationFormActionsBar_event } from './__generated__/RegistrationFormActionsBar_event.graphql';

const Container = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1em;
  font-weight: 500;
  color: #000;
`;

const Actions = styled.div`
  display: flex;
`;

const StyledButton = styled(OutlineButton)`
  margin-left: 20px;
  padding: 7px 14px;
  font-size: 14px;
`;

class RegistrationFormActionsBar extends React.PureComponent<
  {
    event: RegistrationFormActionsBar_event,
    onRegFormEdit: (e: SyntheticEvent<HTMLElement>) => void,
  },
  {
    showDiscardConfirmationWindow: boolean,
  },
> {
  state = {
    showDiscardConfirmationWindow: false,
  };

  handleShowDiscardConfirmation = () => {
    this.setState({ showDiscardConfirmationWindow: true });
  };

  handleHideDiscardConfirmation = () => {
    this.setState({ showDiscardConfirmationWindow: false });
  };

  handleConfirmDiscarding = () => {
    this.handleDiscardChanges();
    this.handleHideDiscardConfirmation();
  };

  handleDiscardChanges = () => {
    const registrationForm = this.props.event.editableRegistrationForm;
    if (registrationForm == null) return;

    discardRegistrationFormChanges(registrationForm.id).catch(showModernMutationError);
  };

  render() {
    const { event, onRegFormEdit } = this.props;
    const hasChanges = event.editableRegistrationForm && event.editableRegistrationForm.hasChanges;

    return (
      <Container>
        <Title>Event Registration form</Title>
        <Actions>
          <LiveActions event={event} draftBackground="#929292" reloadFormAfterSave />
          <StyledButton
            label={hasChanges ? 'Continue editing' : 'Edit the form'}
            onClick={onRegFormEdit}
          />
          {hasChanges && (
            <StyledButton
              label="Discard changes"
              variant="danger"
              onClick={this.handleShowDiscardConfirmation}
            />
          )}
          {this.state.showDiscardConfirmationWindow && (
            <ConfirmationWindow
              actionNegative={false}
              actionLabel="Confirm"
              onHide={this.handleHideDiscardConfirmation}
              onConfirm={this.handleConfirmDiscarding}
              title="Are you sure?"
              message="Once you proceed with this, all the changes, you or your teammates made to the registration form, are gone for good."
            />
          )}
        </Actions>
      </Container>
    );
  }
}

export default createFragmentContainer(RegistrationFormActionsBar, {
  event: graphql`
    fragment RegistrationFormActionsBar_event on Event {
      id
      editableRegistrationForm {
        id
        hasChanges
      }
      ...LiveActions_event
    }
  `,
});
