/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import eventStates from 'config/eventStates';

import updateEvent from 'graph/mutations/event/updateEvent';

import LockIcon from 'components/LockIcon';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import { type Participant } from 'components/Participants';
import UserSearch from 'components/Participants/UserSearch';

import Description from '../Description';

import type { General_event } from './__generated__/General_event.graphql';

const ItemContainer = styled.div`
  position: relative;
  flex: 0 1 ${props => (props.big ? 420 : 180)}px;
  margin-bottom: 23px;
  ${props =>
    !props.big &&
    css`
      &:first-of-type {
        margin-right: 60px;
      }
    `};
  @media (${props => props.theme.mobileOnly}) {
    &:first-of-type {
      margin-right: 0;
    }
    flex: 1 1 auto;
    width: 100%;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  @media (${props => props.theme.mobileOnly}) {
    flex-direction: column;
  }
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  left: 160px;
  top: 15px;
`;

class General extends React.Component<{
  event: General_event,
}> {
  handleSelectLead = (user: ?Participant): void => {
    const lead = user && {
      id: user.id,
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
    };
    updateEvent(this.props.event.id, { lead });
  };

  handleEventStateChange = (status): void => {
    updateEvent(this.props.event.id, { status });
  };

  render() {
    const event = this.props.event;
    const lead = event.lead && { ...event.lead };

    return (
      <div>
        <Row>
          <ItemContainer>
            <TextField label="Event ID" value={event.uniqueId} disabled />
          </ItemContainer>
        </Row>
        <Row>
          <ItemContainer>
            <SelectField
              label="Status"
              value={event.commitmentLevel}
              onChange={this.handleEventStateChange}
              options={eventStates.map(s => ({ value: s.graphValue, label: s.label }))}
              disabled={!event.viewerCanUpdate || event.mappedToSalesforce}
            />
          </ItemContainer>
          {event.viewerCanUpdate && event.mappedToSalesforce && (
            <StyledLockIcon label="Salesforce" />
          )}
        </Row>
        <Row>
          <ItemContainer big>
            <UserSearch
              defaultOption={lead}
              eventId={event.id}
              label="Event Leader"
              onSelect={this.handleSelectLead}
              fillValueOnSelect
              disabled={!event.viewerCanUpdate}
              fromWindow="leader event settings"
              clearable
            />
          </ItemContainer>
        </Row>
        <Row>
          <Description event={event} />
        </Row>
      </div>
    );
  }
}

export default createFragmentContainer(General, {
  event: graphql`
    fragment General_event on Event {
      uniqueId
      id
      commitmentLevel
      viewerCanUpdate
      mappedToSalesforce(field: STATE)
      lead {
        id
        firstName
        lastName
        email
        avatar
      }
      ...Description_event
    }
  `,
});
