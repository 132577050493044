/**
 * @flow
 * @relayHash a534ff7505c49e96e3a2165ec7760de6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type UpdateEventContactInput = {
  clientMutationId?: ?string,
  eventId: string,
  contactId: string,
  registrationStatusId?: ?string,
  attendanceStatusId?: ?string,
};
export type updateEventContactMutationVariables = {|
  input: UpdateEventContactInput,
  changingContactRegistrationsTotal: boolean,
|};
export type updateEventContactMutationResponse = {|
  +updateEventContact: ?{|
    +eventContactEdge: {|
      +registrationStatus: ?{|
        +id: string,
        +name: string,
      |},
      +attendanceStatus: ?{|
        +id: string,
        +name: string,
      |},
      +syncStatus: {|
        +state: SyncState,
        +lastSuccessAt: ?any,
        +errorMessage: string,
      |},
      +node: {|
        +id: string
      |},
    |},
    +contactEventEdge: {|
      +registrationStatus: ?{|
        +id: string,
        +name: string,
      |},
      +attendanceStatus: ?{|
        +id: string,
        +name: string,
      |},
      +syncStatus: {|
        +state: SyncState,
        +lastSuccessAt: ?any,
        +errorMessage: string,
      |},
      +node: {|
        +id: string,
        +contactRegistrationsTotal?: number,
      |},
    |},
  |}
|};
export type updateEventContactMutation = {|
  variables: updateEventContactMutationVariables,
  response: updateEventContactMutationResponse,
|};
*/


/*
mutation updateEventContactMutation(
  $input: UpdateEventContactInput!
  $changingContactRegistrationsTotal: Boolean!
) {
  updateEventContact(input: $input) {
    eventContactEdge {
      registrationStatus {
        id
        name
      }
      attendanceStatus {
        id
        name
      }
      syncStatus {
        state
        lastSuccessAt
        errorMessage
      }
      node {
        id
      }
    }
    contactEventEdge {
      registrationStatus {
        id
        name
      }
      attendanceStatus {
        id
        name
      }
      syncStatus {
        state
        lastSuccessAt
        errorMessage
      }
      node {
        id
        contactRegistrationsTotal @include(if: $changingContactRegistrationsTotal)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventContactInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingContactRegistrationsTotal",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  v1,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldOptionType",
  "plural": false,
  "selections": v2
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attendanceStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldOptionType",
  "plural": false,
  "selections": v2
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "syncStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "SyncStatus",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "state",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastSuccessAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "errorMessage",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateEventContact",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateEventContactInput!"
      }
    ],
    "concreteType": "UpdateEventContactPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventContactEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "EventContactEdge",
        "plural": false,
        "selections": [
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Contact",
            "plural": false,
            "selections": [
              v1
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contactEventEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "ContactEventEdge",
        "plural": false,
        "selections": [
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "Condition",
                "passingValue": true,
                "condition": "changingContactRegistrationsTotal",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "contactRegistrationsTotal",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventContactMutation",
  "id": null,
  "text": "mutation updateEventContactMutation(\n  $input: UpdateEventContactInput!\n  $changingContactRegistrationsTotal: Boolean!\n) {\n  updateEventContact(input: $input) {\n    eventContactEdge {\n      registrationStatus {\n        id\n        name\n      }\n      attendanceStatus {\n        id\n        name\n      }\n      syncStatus {\n        state\n        lastSuccessAt\n        errorMessage\n      }\n      node {\n        id\n      }\n    }\n    contactEventEdge {\n      registrationStatus {\n        id\n        name\n      }\n      attendanceStatus {\n        id\n        name\n      }\n      syncStatus {\n        state\n        lastSuccessAt\n        errorMessage\n      }\n      node {\n        id\n        contactRegistrationsTotal @include(if: $changingContactRegistrationsTotal)\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventContactMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v6
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventContactMutation",
    "argumentDefinitions": v0,
    "selections": v6
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc724029e0c5b014fb85e4b638a0e663';
module.exports = node;
