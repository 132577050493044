/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventInfoCustomText_event$ref: FragmentReference;
export type EventInfoCustomText_event = {|
  +customTextFields: $ReadOnlyArray<{|
    +value: string,
    +customField: {|
      +id: string
    |},
  |}>,
  +$refType: EventInfoCustomText_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventInfoCustomText_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "customFieldIds",
      "type": "[ID!]"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customTextFields",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "customFieldIds",
          "variableName": "customFieldIds",
          "type": "[ID!]"
        }
      ],
      "concreteType": "CustomTextFieldType",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customField",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldType",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f4f6088a199d6c2ae8bd633fe80f8e69';
module.exports = node;
