/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import formatDateRange from 'utils/date/formatDateRange';

import type { EventDate_staffedEvent } from './__generated__/EventDate_staffedEvent.graphql';

type Props = {
  staffedEvent: EventDate_staffedEvent,
};

const EventDate = ({ staffedEvent }: Props) => {
  const event = staffedEvent.event;
  return (
    event.startDate &&
    formatDateRange(
      moment.tz(event.startDate, event.tz),
      event.endDate && moment.tz(event.endDate, event.tz),
    )
  );
};

export default createFragmentContainer(
  EventDate,
  graphql`
    fragment EventDate_staffedEvent on StaffMembership {
      event {
        startDate
        endDate
        tz
      }
    }
  `,
);
