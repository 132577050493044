/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import BriefField from '../../../components/BriefField';

import { type EventInfoCustomLink_event } from './__generated__/EventInfoCustomLink_event.graphql';

const Link = styled.a`
  color: ${props => props.color};
`;

const EventInfoCustomLink = ({
  name,
  event,
  customFieldId,
  primaryColor,
}: {
  name: string,
  event: EventInfoCustomLink_event,
  customFieldId: string,
  primaryColor: string,
}) => {
  const fieldValue = event.customLinkFields.find(value => value.customField.id === customFieldId);

  if (!fieldValue) return null;

  return (
    <BriefField label={name} contentDataAvailable={fieldValue.value}>
      <Link href={fieldValue.value} rel="noopener noreferrer" target="_blank" color={primaryColor}>
        {fieldValue.value}
      </Link>
    </BriefField>
  );
};

export default createFragmentContainer(
  EventInfoCustomLink,
  graphql`
    fragment EventInfoCustomLink_event on Event {
      customLinkFields(customFieldIds: $customFieldIds) {
        value
        customField {
          id
        }
      }
    }
  `,
);
