/* @flow */
import React from 'react';

import { type SupportInlineImage } from 'components/RichText';
import BriefField from './BriefField';
import Restricted from './Restricted';
import BriefRichText from './BriefRichText';

const DescriptionRichText = (props: {
  description: ?string,
  name: string,
  contentDataAvailable: ?string,
  restricted?: ?boolean,
  className?: string,
  supportInlineImage?: SupportInlineImage,
}) => {
  const {
    name,
    description,
    contentDataAvailable,
    restricted,
    className,
    supportInlineImage,
  } = props;

  if (!(description != null && description.trim() !== '')) {
    return null;
  }

  return (
    <BriefField className={className} label={name} contentDataAvailable={contentDataAvailable}>
      {restricted ? (
        <Restricted />
      ) : (
        <BriefRichText
          defaultValue={description}
          disabled
          viewMode
          supportInlineImage={supportInlineImage}
        />
      )}
    </BriefField>
  );
};
export default DescriptionRichText;
