/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import updateCustomFieldValue, {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Table, { type ColumnType } from 'components/budget/Table';
import TablePagination from 'components/budget/Table/TablePagination';
import { type ColumnConfiguration } from 'components/material/table';
import NoResultsMessage from 'components/NoResultsMessage';

import { type StaffCellPropsType } from './columns';

import { type StaffTableRenderer_event } from './__generated__/StaffTableRenderer_event.graphql';
import { type StaffTableRenderer_org } from './__generated__/StaffTableRenderer_org.graphql';
import { type StaffTableRenderer_totalCountEvent } from './__generated__/StaffTableRenderer_totalCountEvent.graphql';

type StaffType = $ElementType<$PropertyType<StaffTableRenderer_event, 'staffers'>, 0>;

const StaffContentContainer = styled.div`
  width: 100%;
`;

class StaffTableRenderer extends React.Component<
  {
    event: ?StaffTableRenderer_event,
    org: ?StaffTableRenderer_org,
    location: Location,
    history: RouterHistory,
    userId: ?string,
    totalCountEvent: StaffTableRenderer_totalCountEvent,
    relay: RelayPaginationProp,
    sort: SortParam,
    onChangeSort: (sort: SortParam) => void,
    shownColumns: ColumnConfiguration,
  },
  {
    refetching: boolean,
  },
> {
  state = {
    refetching: false,
  };

  paginationDisposable: ?Disposable;

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  cellProps = (
    staffer: StaffType,
    _group,
    column?: ColumnType<any, any>,
  ): StaffCellPropsType<StaffType, ?StaffTableRenderer_org> => {
    const { event, org, location, history, userId } = this.props;
    return {
      eventId: event ? event.id : '',
      staffer,
      org,
      tz: event ? event.tz : '',
      location,
      history,
      userId,
      readOnly: event == null || !event.viewerCanAddStaff,
      customizable: staffer,
      fieldSettings: column != null ? column.fieldSettings : null,
      onUpdateCustomField: (
        customizable: CustomizableResponse,
        args: UpdateCustomFieldValueInput,
      ): Promise<void> => {
        if (event == null) return Promise.resolve();

        return updateCustomFieldValue(args, customizable, event.id, 'event staff')
          .catch(showModernMutationError)
          .then(() => undefined);
      },
    };
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  keyExtractor = (staff: StaffType) => {
    return staff.id;
  };

  render() {
    const { event, totalCountEvent, shownColumns } = this.props;

    return (
      <StaffContentContainer>
        {(!event || event.staffers.edges.length !== 0) && (
          <Table
            data={event != null ? event.staffers.edges.map(({ node }) => node) : null}
            columns={shownColumns}
            sort={this.props.sort}
            onChangeSort={this.props.onChangeSort}
            cellProps={this.cellProps}
            keyExtractor={this.keyExtractor}
          />
        )}

        {event && event.staffers.edges.length === 0 && (
          <NoResultsMessage iconName="users" message="Add the first staff member" />
        )}

        {event && event.staffers.edges.length !== 0 && (
          <TablePagination
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            currentCount={event ? event.staffers.edges.length : null}
            totalCount={totalCountEvent.staffersCount.totalCount}
            entityName="Staff"
          />
        )}
      </StaffContentContainer>
    );
  }
}
export default createPaginationContainer(
  StaffTableRenderer,
  {
    event: graphql`
      fragment StaffTableRenderer_event on Event {
        id
        slug
        tz
        viewerCanAddStaff
        staffers(
          first: $count
          after: $cursor
          sort: $sort
          customFieldSortId: $customFieldSortId
          direction: $direction
        ) @connection(key: "StaffTableRenderer_staffers", filters: []) {
          edges {
            node {
              id
              user {
                id
                viewerCanSeeProfile
              }
              ...StaffNameCell_staffer
              ...StaffMemberEmail_staffer @include(if: $includeEmail)
              ...StaffAccessLevelCell_staffer @include(if: $includeEventAccessLevel)
              ...StaffLastActivityCell_staffer @include(if: $includeLastActivity)
              ...StaffOnsiteCell_staffer @include(if: $includeOnsite)
              ...StaffActionsCell_staffer
              ...CustomizableText_customizable @include(if: $includeCustomizableText)
              ...CustomizableTextarea_customizable @include(if: $includeCustomizableTextarea)
              ...CustomizableLink_customizable @include(if: $includeCustomizableLink)
              ...CustomizableDate_customizable @include(if: $includeCustomizableDate)
              ...CustomizableBoolean_customizable @include(if: $includeCustomizableBoolean)
              ...CustomizableNumber_customizable @include(if: $includeCustomizableNumber)
              ...CustomizableCurrency_customizable @include(if: $includeCustomizableCurrency)
              ...CustomizableSelect_customizable @include(if: $includeCustomizableSelect)
              ...CustomizableMultiselect_customizable @include(if: $includeCustomizableMultiselect)
              ...CustomizableUserSelect_customizable @include(if: $includeCustomizableUserSelect)
              ...CustomizableUserMultiselect_customizable
                @include(if: $includeCustomizableUserMultiselect)
            }
          }
        }
      }
    `,
    org: graphql`
      fragment StaffTableRenderer_org on Org {
        ...CustomizableCurrency_org
      }
    `,
    totalCountEvent: graphql`
      fragment StaffTableRenderer_totalCountEvent on Event {
        staffersCount: staffers {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.event.staffers;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
      };
    },
    query: graphql`
      query StaffTableRendererQuery(
        $count: Int!
        $cursor: String
        $eventSlug: String!
        $sort: StaffMembershipSort
        $customFieldSortId: String
        $direction: Direction!
        $includeEmail: Boolean!
        $includeEventAccessLevel: Boolean!
        $includeOnsite: Boolean!
        $includeLastActivity: Boolean!
        $includeCustomizableText: Boolean!
        $includeCustomizableTextarea: Boolean!
        $includeCustomizableLink: Boolean!
        $includeCustomizableDate: Boolean!
        $includeCustomizableBoolean: Boolean!
        $includeCustomizableNumber: Boolean!
        $includeCustomizableCurrency: Boolean!
        $includeCustomizableSelect: Boolean!
        $includeCustomizableMultiselect: Boolean!
        $includeCustomizableUserSelect: Boolean!
        $includeCustomizableUserMultiselect: Boolean!
      ) {
        event(slug: $eventSlug) {
          ...StaffTableRenderer_event
        }
        org {
          ...StaffTableRenderer_org
        }
      }
    `,
  },
);
