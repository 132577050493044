/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { CompanyContactEmail_contact } from './__generated__/CompanyContactEmail_contact.graphql';

class CompanyContactEmail extends React.Component<{
  contact: CompanyContactEmail_contact,
}> {
  preventDefault = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  render() {
    const contact = this.props.contact;
    return (
      <a
        onMouseDown={this.preventDefault}
        href={`mailto:${contact.email}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {contact.email}
      </a>
    );
  }
}

export default createFragmentContainer(
  CompanyContactEmail,
  graphql`
    fragment CompanyContactEmail_contact on Contact {
      email
    }
  `,
);
