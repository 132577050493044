/* @flow */
import React from 'react';
import styled from 'styled-components';

import Window, {
  WindowContent,
  WindowHeader,
  WindowTitle,
  WindowClose,
} from 'components/material/Window';
import ConfirmationWindow from 'components/ConfirmationWindow';
import Button from 'components/material/Button';
import AccessTypeSelector, { type AccessType } from 'components/InviteWindow/AccessTypeSelector';
import { type StaffAccessLevel } from 'config/staffAccessLevels';

import updateStafferAccessLevel from 'graph/mutations/access/updateStafferAccessLevel';
import showModernMutationError from 'graph/utils/showModernMutationError';

const Footer = styled.div`
  margin-top: 30px;
  text-align: right;
  button {
    margin-left: 30px;
  }
`;

export default class ManageStaffWindow extends React.PureComponent<
  {
    eventId: string,
    userId: string,
    invitedUser: boolean,
    onHide: () => void,
    accessLevel: StaffAccessLevel,
  },
  {
    accessLevel: StaffAccessLevel,
    confirmationShown: boolean,
  },
> {
  state = {
    accessLevel: this.props.accessLevel,
    confirmationShown: false,
  };

  handleChangeAccessLevel = (accessLevel: ?AccessType) => {
    if (accessLevel === 'FULL' || accessLevel === 'LIMITED' || accessLevel === 'NONE') {
      this.setState({ accessLevel });
    }
  };

  handleSave = () => {
    if (!this.props.invitedUser && this.state.accessLevel !== 'NONE') {
      this.setState({ confirmationShown: true });
    } else {
      this.handleUpdate();
    }
  };

  handleConfirmationHide = () => {
    this.setState({ confirmationShown: false });
  };

  handleUpdate = () => {
    updateStafferAccessLevel({
      eventId: this.props.eventId,
      userId: this.props.userId,
      accessLevel: this.state.accessLevel,
    }).catch(showModernMutationError);

    this.props.onHide();
  };

  render() {
    return (
      <Window onHide={this.props.onHide} size="small">
        <WindowHeader>
          <WindowTitle>Manage Access Level</WindowTitle>
          <WindowClose onClick={this.props.onHide} />
        </WindowHeader>
        <WindowContent>
          <AccessTypeSelector
            value={this.state.accessLevel}
            onChange={this.handleChangeAccessLevel}
            scope="EVENT"
          />

          <Footer>
            <Button label="Cancel" minimal onClick={this.props.onHide} />
            <Button
              label="Save"
              primary
              onClick={this.handleSave}
              disabled={this.props.accessLevel === this.state.accessLevel}
            />
          </Footer>
        </WindowContent>
        {this.state.confirmationShown && (
          <ConfirmationWindow
            onHide={this.handleConfirmationHide}
            onConfirm={this.handleUpdate}
            actionLabel="Confirm"
            message="Upgrading this Member's from No Access will send them a Welcome email and enable them to sign in."
          />
        )}
      </Window>
    );
  }
}
