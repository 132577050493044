/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';

import { nameWithStatus } from 'config/userInviteStatuses';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';
import { TableLink } from 'components/material/table';

import type { StaffNameCell_staffer } from './__generated__/StaffNameCell_staffer.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 150px;
  margin: -6px -18px -6px -10px;
`;

const StyledAvatar = styled(MaterialAvatar)`
  margin-right: 12px;
`;

const Name = styled.div`
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const Tag = styled.div`
  margin-left: 15px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 1.6;
  border-radius: 20px;
  background: ${props => props.theme.primaryActionColor};
  color: ${props => props.theme.primaryActionTextColor};
`;

class StaffNameCell extends React.PureComponent<{
  staffer: StaffNameCell_staffer,
  eventSlug: string,
  location: Location,
}> {
  render() {
    const user = this.props.staffer.user;
    const fullName = nameWithStatus(fullNameOfUser(user), user.inviteStatus);

    return (
      <Container>
        <StyledAvatar user={user} radius={11.5} />
        {user.viewerCanSeeProfile ? (
          <TableLink
            to={{
              pathname: `/workspace/members/${user.id}`,
              state: {
                prevPage: true,
                eventSlug: this.props.eventSlug,
                search: this.props.location.search,
              },
            }}
          >
            {fullName}
          </TableLink>
        ) : (
          <Name>{fullName}</Name>
        )}
        {user.admin && <Tag>Admin</Tag>}
      </Container>
    );
  }
}

export default createFragmentContainer(
  StaffNameCell,
  graphql`
    fragment StaffNameCell_staffer on StaffMembership {
      user {
        id
        admin
        firstName
        lastName
        email
        inviteStatus
        viewerCanSeeProfile
        ...MaterialAvatar_user
      }
    }
  `,
);
