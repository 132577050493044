/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import Button from 'components/material/Button';

import type { NotificationsList_notifications } from './__generated__/NotificationsList_notifications.graphql';

import NotificationItem from './NotificationItem';

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
  color: ${props => props.theme.primaryActionColor};
`;

class NotificationsList extends React.PureComponent<
  {
    notifications: NotificationsList_notifications,
    timezone: string,
    hasMore: boolean,
    loadMore: () => Promise<void>,
  },
  {
    loading: boolean,
  },
> {
  state = {
    loading: false,
  };

  handleLoadMore = () => {
    this.setState({ loading: true });

    this.props.loadMore().then(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <div>
        {this.props.notifications.map(notification => (
          <NotificationItem
            notification={notification}
            key={notification.id}
            timezone={this.props.timezone}
          />
        ))}
        {this.props.hasMore && (
          <StyledButton
            label="See More"
            minimal
            onClick={this.handleLoadMore}
            loading={this.state.loading}
          />
        )}
      </div>
    );
  }
}

export default createFragmentContainer(
  NotificationsList,
  graphql`
    fragment NotificationsList_notifications on Notification @relay(plural: true) {
      id
      ...NotificationItem_notification
    }
  `,
);
