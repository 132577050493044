/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import Overlay from 'components/material/Overlay';
import Tooltip from 'components/material/Tooltip';

export const StyledOverlay = styled(Overlay)`
  width: auto;
`;

const Option = styled.div`
  padding: 7px;
  display: flex;
  color: ${props => props.theme.rowPrimaryTextColor};
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.6;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
    ${props =>
      !props.disabled &&
      css`
        i {
          color: ${props.theme.primaryActionColor};
        }
        svg {
          color: ${props.theme.primaryActionColor};
        }
      `};
  }
  i {
    color: ${props => props.theme.rowSecondaryTextColor};
    margin-right: 10px;
  }
  svg {
    margin-right: 10px;
    color: ${props => props.theme.rowSecondaryTextColor};
    fill: currentColor;
  }
  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.mutedTextColor};
      cursor: not-allowed;
    `};
`;

export type OptionType = {
  label: string,
  icon?: string,
  svg?: React.ElementType,
  tooltip?: ?string,
  disabled?: ?boolean,
  onClick: ?() => void,
};

export default class ShareDropdownOverlay extends React.PureComponent<{
  show: boolean,
  target: any,
  container: any,
  onHide: () => void,
  options?: Array<OptionType>,
}> {
  handleOptionClick = (option: any) => {
    if (!option.disabled) {
      this.props.onHide();
      option.onClick();
    }
  };

  renderOption = (option: OptionType) => {
    return (
      <Tooltip label={option.tooltip} placement="top" key={option.label}>
        <Option disabled={option.disabled} onClick={() => this.handleOptionClick(option)}>
          {option.icon && <i className={`fa fa-fw fa-${option.icon}`} />}
          {option.svg && <option.svg />}
          {option.label}
        </Option>
      </Tooltip>
    );
  };

  renderOptions = () => {
    if (Array.isArray(this.props.options)) {
      return this.props.options.map(option => this.renderOption(option));
    }
    return <div>Nothing to render</div>;
  };

  render() {
    return (
      <StyledOverlay
        show={this.props.show}
        target={this.props.target}
        onHide={this.props.onHide}
        container={this.props.container}
        forceRightEdge
      >
        {this.renderOptions()}
      </StyledOverlay>
    );
  }
}
