import './title.styl';

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import ClickOutside from 'react-onclickout';

export default class Title extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
  };
  state = {
    editing: false,
  };
  handleCancelEditing = () => this.setState({ editing: false });
  handleStartEditing = () => this.setState({ editing: true });
  handleSave = () => {
    const { value } = this.input;

    if (value !== this.props.value) {
      this.props.onSave(value);
    }

    this.setState({ editing: false });
  };
  handleKeyDown = e => {
    if (e.which === 13) {
      this.handleSave();
    } else if (e.which === 27) {
      this.handleCancelEditing();
    }
  };
  handleTitleRemoveClick = e => {
    e.stopPropagation();

    this.props.onSave(null);
  };
  render() {
    const { value, disabled } = this.props;
    const { editing } = this.state;

    if (editing) {
      return (
        <ClickOutside onClickOut={this.handleCancelEditing}>
          <div className="schedule-title-edit">
            <input
              type="text"
              defaultValue={value}
              ref={input => {
                this.input = input;
              }}
              autoFocus
              onKeyDown={this.handleKeyDown}
            />
            <i className="fa fa-check fa-fw" onClick={this.handleSave} />
            <i className="fa fa-times fa-fw" onClick={this.handleCancelEditing} />
          </div>
        </ClickOutside>
      );
    }

    return (
      <div className={classNames('schedule-title', { empty: !value })}>
        <a onClick={!disabled ? this.handleStartEditing : undefined}>
          {value || (!disabled && '+ Add Title')}
          {value &&
            !disabled && (
              <span
                className="schedule-title-remove"
                data-tooltip="Remove Title"
                onClick={this.handleTitleRemoveClick}
              >
                <i className="fa fa-fw fa-times" />
              </span>
            )}
        </a>
      </div>
    );
  }
}
