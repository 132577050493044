/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorExpenseName_expense$ref: FragmentReference;
export type VendorExpenseName_expense = {|
  +id: string,
  +name: string,
  +event: {|
    +slug: string
  |},
  +$refType: VendorExpenseName_expense$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VendorExpenseName_expense",
  "type": "Expense",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "slug",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e0308159feb9ddaa96f3ba454d72ccb3';
module.exports = node;
