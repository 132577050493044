/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextField from 'components/SharedForm/TextField';

const Container = styled.div`
  padding: 0 85px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 45px 26px 0;
  }
`;

const Row = styled.div`
  display: flex;
  @media (max-width: 500px) {
    display: block;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 35px;
  input {
    background-color: #fff;
  }
`;

const NameTextField = styled(StyledTextField)`
  &:first-of-type {
    margin-right: 39px;
  }
`;

const MandatoryFields = () => {
  return (
    <Container>
      <Row>
        <NameTextField label="First Name" required disabled />
        <NameTextField label="Last Name" required disabled />
      </Row>
      <StyledTextField label="Email" required disabled />
    </Container>
  );
};

export default MandatoryFields;
