/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import type {
  updateScheduleMutationVariables,
  updateScheduleMutationResponse,
} from './__generated__/updateScheduleMutation.graphql';

const mutation = graphql`
  mutation updateScheduleMutation($input: UpdateScheduleInEventInput!) {
    updateScheduleInEvent(input: $input) {
      schedule {
        id
        name
        order
        slug
        slugs {
          slug
        }
      }
    }
  }
`;

export default function updateSchedule(
  scheduleId: string,
  args: {| name?: string, order?: number, shared?: boolean |},
): Promise<updateScheduleMutationResponse> {
  const variables: updateScheduleMutationVariables = {
    input: {
      scheduleId,
      ...args,
    },
  };
  const optimisticResponse = {
    updateScheduleInEvent: {
      schedule: {
        id: scheduleId,
        ...args,
      },
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
