/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type RouterHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import getTextSize from 'utils/getTextSize';

import type { ColumnConfiguration } from 'components/material/table';
import { TableRow } from 'components/material/table';

import { ActionsIcon } from './columns/EventRequestFormsActionsColumn';

import type { EventRequestFormsTableRow_requestForm } from './__generated__/EventRequestFormsTableRow_requestForm.graphql';

const StyledRow = styled(TableRow)`
  padding-right: 50px;
  cursor: pointer;
  &:first-of-type {
    border-top: 0;
  }
  /* Actions column. Only shown on hover */
  > *:last-of-type ${ActionsIcon} {
    visibility: hidden;
  }
  &:hover {
    > *:last-of-type ${ActionsIcon} {
      visibility: visible;
    }
  }
`;

class EventRequestFormsTableRow extends React.PureComponent<{
  requestForm: EventRequestFormsTableRow_requestForm,
  tz: string,
  orgId: string,
  history: RouterHistory,
  location: Location,
  columns: ColumnConfiguration,
  onChangeColumnSize: (value: string, size: number) => void,
}> {
  handleRowClick = () => {
    const currentUrl = `${this.props.location.pathname}${this.props.location.search}`;

    this.props.history.push(`/settings/requests/${this.props.requestForm.id}`, {
      backUrl: currentUrl,
    });
  };

  handleColumnLoad = (value: string, str: string, extraSpace = 0) => {
    // 15 is the column padding, 500 is max width
    const columnSize = Math.min(getTextSize(str) + 15 + extraSpace, 500);

    if (columnSize > this.props.columns.find(c => c.value === value).width) {
      this.props.onChangeColumnSize(value, columnSize);
    }
  };

  render() {
    const { requestForm, tz, columns, orgId } = this.props;

    return (
      <StyledRow onClick={this.handleRowClick}>
        {columns.map(column => (
          <column.component width={column.width} key={column.value} grow={column.grow}>
            <column.componentContent
              requestForm={requestForm}
              tz={tz}
              orgId={orgId}
              onColumnLoad={(str, extraSpace) =>
                this.handleColumnLoad(column.value, str, extraSpace)
              }
            />
          </column.component>
        ))}
      </StyledRow>
    );
  }
}

export default createFragmentContainer(
  withRouter(EventRequestFormsTableRow),
  graphql`
    fragment EventRequestFormsTableRow_requestForm on EventRequestForm {
      id
      ...EventRequestFormsNameColumn_requestForm
      ...EventRequestFormsCreatedColumn_requestForm
      ...EventRequestFormsUpdatedColumn_requestForm
      ...EventRequestFormsShareLinkColumn_requestForm
      ...EventRequestFormsSubmissionsColumn_requestForm
      ...EventRequestFormsLastSubmittedColumn_requestForm
      ...EventRequestFormsIsDraftColumn_requestForm
      ...EventRequestFormsActionsColumn_requestForm
      ...EventRequestFormsReviewersColumn_requestForm
    }
  `,
);
