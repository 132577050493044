/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventRequestsTableRow_eventRequestSubmission$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestsTable_eventRequestSubmissions$ref: FragmentReference;
export type EventRequestsTable_eventRequestSubmissions = $ReadOnlyArray<{|
  +id: string,
  +$fragmentRefs: EventRequestsTableRow_eventRequestSubmission$ref,
  +$refType: EventRequestsTable_eventRequestSubmissions$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestsTable_eventRequestSubmissions",
  "type": "EventRequestSubmission",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventRequestsTableRow_eventRequestSubmission",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '722f174a7147a86e1e018a6048b25d85';
module.exports = node;
