/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import type { QuestionType } from '../../lib/types';
import EditableQuestionSelectOptions from './EditableQuestionSelectOptions';

import type { EditableQuestionSelectOptionsQueryResponse } from './__generated__/EditableQuestionSelectOptionsQuery.graphql';

const query = graphql`
  query EditableQuestionSelectOptionsQuery($customFieldIds: [ID!]!) {
    org {
      customFields(customizableType: [EVENT], ids: $customFieldIds, includeDisabled: true) {
        edges {
          node {
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class EditableQuestionSelectOptionsRoot extends React.PureComponent<{
  question: QuestionType,
  onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
}> {
  getOrgOptions = (response: EditableQuestionSelectOptionsQueryResponse) =>
    response.org.customFields.edges[0]
      ? response.org.customFields.edges[0].node.options.edges.map(edge => edge.node)
      : [];

  render() {
    const { question } = this.props;
    const mappingCustomField = question.mappingCustomField;

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ customFieldIds: mappingCustomField ? [mappingCustomField.id] : [] }}
        renderSuccess={(response: EditableQuestionSelectOptionsQueryResponse) => (
          <EditableQuestionSelectOptions
            question={question}
            onChangeQuestion={this.props.onChangeQuestion}
            options={this.getOrgOptions(response)}
            mappingLabel={mappingCustomField ? mappingCustomField.label : ''}
          />
        )}
      />
    );
  }
}
