/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';
import removeContacts from 'graph/mutations/contact/removeContacts';
import addContactAssignments from 'graph/mutations/event/addContactAssignments';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import AddToEventsWindow from 'components/Contacts/AddToEventsWindow';
import { type InputVariableFilters } from 'components/Contacts/contactsTableColumnSettings';
import Button from 'components/material/Button';

import ContactsUpdateWindow from './ContactsUpdateWindow';

export type AvailableContactsType = $ReadOnlyArray<{|
  +id: string,
  +salesforceSyncAs: ?string,
|}>;

const SelectionInfo = styled.span`
  min-width: 125px;
  padding: 5px 6px;
  font-size: 13px;
  color: #828b93;
`;

const ActionButton = styled(Button)`
  margin-left: 10px;
  padding: 2px 13px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: 13px;
`;

export default class ContactsMassUpdatePanel extends React.PureComponent<
  {
    orgId: string,
    eventId?: ?string,
    fromWindow: CreateContactFromWindow,
    selectedContacts: AvailableContactsType,
    contactFields: $ReadOnlyArray<FieldType>,
    filters: InputVariableFilters,
    onRemoveFromEvent?: () => void,
    onUpdateTableColumnWidths: () => void,
    viewerCanRemoveContacts: boolean,
    allContactsSelected: boolean,
  },
  {
    showRemoveFromEventWindow: boolean,
    showDeleteWindow: boolean,
    addingToEvents: boolean,
    showUpdateWindow: boolean,
  },
> {
  state = {
    showRemoveFromEventWindow: false,
    showDeleteWindow: false,
    addingToEvents: false,
    showUpdateWindow: false,
  };

  handleRemove = () => {
    removeContacts({
      contactIds: this.props.selectedContacts.map(contact => contact.id),
      orgId: this.props.orgId,
      eventId: this.props.eventId,
      contactFields: this.props.contactFields,
      filters: this.props.filters,
      fromWindow: this.props.fromWindow,
    })
      .then(() => {
        if (this.props.allContactsSelected) {
          window.location.reload();
        }
      })
      .catch(showModernMutationError);
  };

  handleShowDeleteWindow = () => {
    this.setState({ showDeleteWindow: true });
  };

  handleShowRemoveFromEventWindow = () => {
    this.setState({ showRemoveFromEventWindow: true });
  };

  handleConfirmationHide = () => {
    this.setState({ showDeleteWindow: false, showRemoveFromEventWindow: false });
  };

  handleAddToEventsShow = () => {
    this.setState({ addingToEvents: true });
  };

  handleAddToEventsHide = () => {
    this.setState({ addingToEvents: false });
  };

  handleShowUpdateWindow = (): void => {
    this.setState({ showUpdateWindow: true });
  };

  handleHideUpdateWindow = (): void => {
    this.setState({ showUpdateWindow: false });
  };

  handleAddToEventsSave = (eventIds: $ReadOnlyArray<string>) => {
    addContactAssignments(
      eventIds,
      this.props.selectedContacts.map(contact => ({ contactId: contact.id })),
      this.props.fromWindow,
      this.props.filters,
    ).catch(showModernMutationError);
  };

  render() {
    const {
      selectedContacts,
      contactFields,
      viewerCanRemoveContacts,
      onUpdateTableColumnWidths,
      eventId,
    } = this.props;

    const contactsCount = selectedContacts.length;

    return (
      <React.Fragment>
        <SelectionInfo>
          {contactsCount} {contactsCount > 1 ? 'Items' : 'Item'} selected
        </SelectionInfo>
        {contactFields.some(
          field =>
            field.fieldName === 'owner_id' ||
            field.fieldName === 'company_id' ||
            field.kind === 'SELECT' ||
            field.kind === 'USER_SELECT',
        ) && (
          <ActionButton label="Update Info" outline primary onClick={this.handleShowUpdateWindow} />
        )}
        {eventId ? (
          <ActionButton
            label="Remove from Event"
            outline
            primary
            onClick={this.handleShowRemoveFromEventWindow}
          />
        ) : (
          <ActionButton label="Add to Event" outline primary onClick={this.handleAddToEventsShow} />
        )}

        {viewerCanRemoveContacts && (
          <ActionButton label="Delete" outline primary onClick={this.handleShowDeleteWindow} />
        )}
        {this.state.showDeleteWindow && (
          <ConfirmationWindow
            onHide={this.handleConfirmationHide}
            onConfirm={this.handleRemove}
            message={`You are about to delete ${contactsCount} ${
              contactsCount > 1 ? 'contacts' : 'contact'
            }. Once you delete a contact, they are removed from all your events and org.`}
          />
        )}
        {this.props.onRemoveFromEvent && this.state.showRemoveFromEventWindow && (
          <ConfirmationWindow
            onHide={this.handleConfirmationHide}
            onConfirm={this.props.onRemoveFromEvent}
            message={`Are you sure you want to remove selected contact${
              contactsCount > 1 ? 's' : ''
            } from this event?`}
          />
        )}
        {this.state.addingToEvents && (
          <AddToEventsWindow
            contactName=""
            contactsCount={this.props.selectedContacts && this.props.selectedContacts.length}
            onCancel={this.handleAddToEventsHide}
            onSave={this.handleAddToEventsSave}
          />
        )}
        {this.state.showUpdateWindow && (
          <ContactsUpdateWindow
            eventId={this.props.eventId}
            onHide={this.handleHideUpdateWindow}
            selectedContacts={selectedContacts}
            contactFields={contactFields}
            onUpdateTableColumnWidths={onUpdateTableColumnWidths}
          />
        )}
      </React.Fragment>
    );
  }
}
