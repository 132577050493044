/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  reorderBriefTemplateEventInfoFieldsMutationResponse,
  SortableFieldInput,
} from './__generated__/reorderBriefTemplateEventInfoFieldsMutation.graphql';

const mutation = graphql`
  mutation reorderBriefTemplateEventInfoFieldsMutation(
    $input: ReorderBriefTemplateEventInfoFieldsInput!
  ) {
    reorderBriefTemplateEventInfoFields(input: $input) {
      eventInfoFields {
        id
        order
        customField {
          id
        }
      }
    }
  }
`;

export default function reorderBriefTemplateEventInfoFields(
  eventInfoFields: $ReadOnlyArray<SortableFieldInput>,
): Promise<reorderBriefTemplateEventInfoFieldsMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input: { eventInfoFields } },
    optimisticResponse: {
      reorderBriefTemplateEventInfoFields: {
        eventInfoFields,
      },
    },
  });
}
