/**
 * @flow
 * @relayHash d3cad3f1af4544d9b63acdbd55ea81e7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateDeclineReasonInput = {
  clientMutationId?: ?string,
  name: string,
};
export type createDeclineReasonMutationVariables = {|
  input: CreateDeclineReasonInput
|};
export type createDeclineReasonMutationResponse = {|
  +createDeclineReason: ?{|
    +declineReasonEdge: {|
      +node: {|
        +id: string,
        +name: string,
      |}
    |}
  |}
|};
export type createDeclineReasonMutation = {|
  variables: createDeclineReasonMutationVariables,
  response: createDeclineReasonMutationResponse,
|};
*/


/*
mutation createDeclineReasonMutation(
  $input: CreateDeclineReasonInput!
) {
  createDeclineReason(input: $input) {
    declineReasonEdge {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDeclineReasonInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createDeclineReason",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateDeclineReasonInput!"
      }
    ],
    "concreteType": "CreateDeclineReasonPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "declineReasonEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "DeclineReasonRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "DeclineReason",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createDeclineReasonMutation",
  "id": null,
  "text": "mutation createDeclineReasonMutation(\n  $input: CreateDeclineReasonInput!\n) {\n  createDeclineReason(input: $input) {\n    declineReasonEdge {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createDeclineReasonMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "createDeclineReasonMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b6d87c9a42f870ccb2b047c4dd0b461d';
module.exports = node;
