/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { TaskStatuses } from 'config/taskStatuses';

import ConfirmationWindow from 'components/ConfirmationWindow';
import InlineTags from 'components/InlineTags';
import Checkbox, { styledComponent as checkboxComponent } from 'components/material/CheckBox';
import { styledComponent as emptyLinkComponent } from 'components/material/table/EmptyLink';

import TaskRowActions, { styledComponent as actionsComponent } from './TaskRowActions';
import TaskRowAssignees from './TaskRowAssignees';
import TaskRowDate from './TaskRowDate';
import TaskRowFolderColumn from './TaskRowFolderColumn';
import TaskRowName from './TaskRowName';
import TaskRowStatus from './TaskRowStatus';

import type { TaskRow_event } from './__generated__/TaskRow_event.graphql';
import type { TaskRow_org } from './__generated__/TaskRow_org.graphql';
import type { TaskRow_task } from './__generated__/TaskRow_task.graphql';

// Needed for multiselect to work
export type TaskRowType =
  | {
      +id: string,
      +name: string,
      +status: TaskStatuses,
      +folderId: ?string,
      +tags: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
          |},
        |}>,
      |},
      +parent: ?{
        +id: string,
      },
      +assignees: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +email: string,
          |},
        |}>,
      |},
      +viewerCanUpdateTags: boolean,
      +viewerCanUpdateStatus: boolean,
      +viewerCanUpdateDueDate: boolean,
      +viewerCanReorder: boolean,
      +viewerCanAssign: boolean,
      +viewerCanDelete: boolean,
    }
  | {
      +id: string,
      +name: string,
      +status: TaskStatuses,
      +folderId: ?string,
      +tags: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
          |},
        |}>,
      |},
      +assignees: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +email: string,
          |},
        |}>,
      |},
      +viewerCanUpdateTags: boolean,
      +viewerCanUpdateStatus: boolean,
      +viewerCanUpdateDueDate: boolean,
      +viewerCanReorder: boolean,
      +viewerCanAssign: boolean,
      +viewerCanDelete: boolean,
    };

const Container = styled.div`
  height: ${props => (props.standalone ? '45px' : '41px')};
  display: flex;
  align-items: center;
  background: ${props =>
    props.standalone ? props.theme.primaryRowColor : props.theme.secondaryRowColor};
  ${props => props.selected && `background: ${props.theme.hoverRowColor};`} ${props =>
    props.standalone
      ? `border-bottom: 5px solid ${props.theme.secondaryRowColor}`
      : `border-top: 1px solid ${props.theme.primaryRowColor}`};
  padding-right: ${props => (props.standalone ? '15px' : '14px')};
  &:hover {
    background: ${props => props.theme.hoverRowColor};
    cursor: pointer;
    ${checkboxComponent}, ${actionsComponent}, ${emptyLinkComponent} {
      visibility: visible;
    }
  }
  ${actionsComponent}, ${emptyLinkComponent} {
    visibility: hidden;
  }
  ${props =>
    !props.selected &&
    css`
      ${checkboxComponent} {
        visibility: hidden;
      }
    `} ${props => props.new && 'animation: new_task 3s normal'};
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 10px;
    ${checkboxComponent} {
      display: none;
    }
  }
  @keyframes new_task {
    from {
      ${props => `background: ${props.theme.hoverRowColor};`};
    }
    to {
      background: ${props =>
        props.standalone ? props.theme.primaryRowColor : props.theme.secondaryRowColor};
    }
  }
`;

const TaskCheckbox = styled(Checkbox)`
  > div {
    margin: ${props => (props.standalone ? '15px' : '14px')};
  }
`;

const TagsContainer = styled.div`
  width: 120px;
  margin: 0 10px;
  @media (${props => props.theme.mobileOnly}) {
    display: none;
  }
`;

class TaskRow extends React.PureComponent<
  {
    history: RouterHistory,
    folders?: Array<{ id: string, name: string }>,
    task: TaskRow_task,
    event: TaskRow_event,
    org: TaskRow_org,
    standalone: boolean,
    selected?: boolean,
    onSelect?: (task: TaskRow_task, shiftPressed: boolean) => void,
    onUpdate: (string, Object) => void,
    onUpdateStatus: (task: TaskRow_task, status: TaskStatuses) => void,
    onRemove: string => void,
    onAssign: (string, string) => void,
    onUnassign: (string, string) => void,
    onAddTag: (string, string) => void,
    onRemoveTag: (string, string) => void,
    onGetLink: (taskSlug: string, eventSlug: string) => string,
    onInviteClick: (taskId: string) => void,
    new?: boolean,
    showFolder?: boolean,
    className?: string,
  },
  {
    showConfirmation: boolean,
  },
> {
  state = {
    showConfirmation: false,
  };

  handleSelect = (checked, e: SyntheticMouseEvent<>) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.onSelect) this.props.onSelect(this.props.task, e.shiftKey);
  };

  handleUpdate = obj => {
    this.props.onUpdate(this.props.task.id, obj);
  };

  handleStatusChange = checked => {
    this.props.onUpdateStatus(this.props.task, checked ? 'COMPLETED' : 'OPEN');
  };

  handleRemove = () => {
    this.props.onRemove(this.props.task.id);
  };

  handleAssign = userId => {
    this.props.onAssign(this.props.task.id, userId);
  };

  handleUnassign = userId => {
    this.props.onUnassign(this.props.task.id, userId);
  };

  handleAddTag = tagId => {
    this.props.onAddTag(this.props.task.id, tagId);
  };

  handleRemoveTag = tagId => {
    this.props.onRemoveTag(this.props.task.id, tagId);
  };

  handleDateChange = ({ date, allDay }) => {
    this.handleUpdate({ dueDate: date, dueDateAllDay: allDay });
  };

  handleRowClick = () => {
    const taskPath = this.props.onGetLink(
      this.props.task.parent ? this.props.task.parent.slug : this.props.task.slug,
      this.props.event.slug,
    );
    this.props.history.push(taskPath);
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    const {
      event,
      task,
      folders,
      standalone,
      selected,
      showFolder,
      className,
      org,
      onInviteClick,
    } = this.props;
    const isCompleted = task.status === 'COMPLETED';

    const {
      viewerCanUpdateTags,
      viewerCanUpdateStatus,
      viewerCanUpdateDueDate,
      viewerCanAssign,
      viewerCanDelete,
      viewerCanSelect,
    } = task;

    const folder =
      showFolder && folders && task.folderId ? folders.find(f => f.id === task.folderId) : null;
    const folderName = folder ? folder.name : null;

    return (
      <>
        <Container
          standalone={standalone}
          selected={selected}
          new={this.props.new}
          onClick={this.handleRowClick}
          className={className}
        >
          {this.props.onSelect && (
            <TaskCheckbox
              checked={selected || false}
              onChange={this.handleSelect}
              standalone={standalone}
              disabled={!viewerCanSelect}
            />
          )}
          <TaskRowStatus
            checked={isCompleted}
            onChange={this.handleStatusChange}
            disabled={!viewerCanUpdateStatus}
          />
          <TaskRowName
            completed={isCompleted}
            name={task.name}
            subtasksCount={task.subtasksCount}
            completedSubtasksCount={task.completedSubtasksCount}
          />
          {folderName && <TaskRowFolderColumn>{folderName}</TaskRowFolderColumn>}
          <TaskRowDate
            date={task.dueDate}
            allDay={task.dueDate ? task.dueDateAllDay : null}
            tz={event.tz}
            onChange={this.handleDateChange}
            disabled={!viewerCanUpdateDueDate}
            completed={isCompleted}
          />
          <TagsContainer>
            <InlineTags
              tags={task.tags.edges.map(edge => edge.node)}
              orgTags={org.tags.edges.map(edge => edge.node)}
              canManageTags={org.viewerCanManageTags}
              onAddTag={this.handleAddTag}
              onRemoveTag={this.handleRemoveTag}
              disabled={!viewerCanUpdateTags}
            />
          </TagsContainer>
          <TaskRowAssignees
            eventId={event.id}
            assignees={task.assignees.edges.map(edge => edge.node)}
            onAssign={this.handleAssign}
            onUnassign={this.handleUnassign}
            disabled={!viewerCanAssign}
            onInviteClick={() => onInviteClick && onInviteClick(task.id)}
          />
          <TaskRowActions
            onRemove={this.handleShowConfirmation}
            disabledRemove={!viewerCanDelete}
          />
        </Container>
        {this.state.showConfirmation && (
          <ConfirmationWindow
            onHide={this.handleHideConfirmation}
            onConfirm={this.handleRemove}
            title="Are you sure?"
            message={`Once you delete this ${
              task.parent ? 'Subtask' : 'Task'
            }, it's gone for good.`}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(withRouter(TaskRow), {
  task: graphql`
    fragment TaskRow_task on Deliverable {
      id
      name
      slug
      status
      dueDate
      dueDateAllDay
      folderId
      tags {
        edges {
          node {
            id
            ...InlineTags_tags
          }
        }
      }
      assignees {
        edges {
          node {
            id
            ...TaskRowAssignees_assignees
          }
        }
      }
      parent {
        slug
      }
      subtasksCount
      completedSubtasksCount
      viewerCanUpdateTags
      viewerCanUpdateStatus
      viewerCanUpdateDueDate
      viewerCanReorder # used for reorder in header
      viewerCanAssign
      viewerCanDelete
      viewerCanSelect
      blockedByOtherTasks
    }
  `,
  event: graphql`
    fragment TaskRow_event on Event {
      id
      slug
      tz
    }
  `,
  org: graphql`
    fragment TaskRow_org on Org {
      viewerCanManageTags
      tags {
        edges {
          node {
            ...InlineTags_orgTags
          }
        }
      }
    }
  `,
});
