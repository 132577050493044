/* @flow */
import React from 'react';
import { type RouterHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import UserStore from 'stores/UserStore';

import withQueryParams from 'utils/routing/withQueryParams';

import AuthButton from 'components/Auth/AuthButton';
import AuthFooter from 'components/Auth/AuthFooter';
import Window from 'components/Auth/AuthWindow';
import Loader from 'components/Loader';
import TextField from 'components/material/TextField';

const StyledTextField = styled(TextField)`
  margin: 10px 0 25px 0;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

class NewPassword extends React.Component<
  {
    queryParams: { reset_password_token?: ?string },
    history: RouterHistory,
  },
  {
    loading: boolean,
    passwordError: boolean,
    confirmationError: boolean,
    buttonLoading: boolean,
  },
> {
  state = {
    loading: true,
    passwordError: false,
    confirmationError: false,
    buttonLoading: false,
  };

  componentDidMount() {
    const token = this.props.queryParams.reset_password_token;

    if (!token) {
      this.props.history.replace('/reset?error=Password reset token invalid.');
      return;
    }

    UserStore.validateToken(
      token,
      () => {
        this.setState({ loading: false });
      },
      ({ error }) => {
        if (error.includes('expired')) {
          this.props.history.replace(
            '/reset?error=Password reset token expired. Please request a new one below.',
          );
        } else {
          this.props.history.replace('/reset?error=Password reset token invalid.');
        }
      },
    );
  }

  handleSubmit = e => {
    e.preventDefault();

    const token = this.props.queryParams.reset_password_token;
    const password = e.target.elements.password.value;
    const passwordConfirmation = e.target.elements.password_confirmation.value;

    const errors = {
      passwordError: password.length < 8,
      confirmationError: password !== passwordConfirmation,
    };

    this.setState(errors);

    if (errors.passwordError || errors.confirmationError) return;

    this.setState({ buttonLoading: true });
    UserStore.resetPassword(token, password, response => {
      this.setState({ buttonLoading: false });

      if (response && response.message.indexOf('success') > -1) {
        this.props.history.push('/?error=Password successfully reset. Please sign in.');
      } else {
        this.props.history.push(
          '/reset?error=There was an error. Please request new password reset.',
        );
      }
    });
  };

  handleLoginLinkClick = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <div>
        <Window title="Reset your password">
          {this.state.loading ? (
            <LoaderContainer>
              <Loader size={45} />
            </LoaderContainer>
          ) : (
            <form onSubmit={this.handleSubmit}>
              <StyledTextField
                type="password"
                error={
                  this.state.passwordError ? 'Password must be at least 8 characters long' : null
                }
                label="New password"
                autoFocus
                name="password"
              />
              <StyledTextField
                type="password"
                error={this.state.confirmationError ? 'Passwords do not match' : null}
                label="Repeat new password"
                name="password_confirmation"
              />

              <AuthButton type="submit" loading={this.state.buttonLoading} label="Save" primary />
            </form>
          )}
          <AuthFooter>
            <p>
              Remember it after all? <Link to="/">Sign In</Link>
            </p>
          </AuthFooter>
        </Window>
      </div>
    );
  }
}

export default withQueryParams(NewPassword);
