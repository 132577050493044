/**
 * @flow
 * @relayHash 3722dfde5d9be1b580b0be34e2a80ee0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffPage_event$ref = any;
type StaffPage_org$ref = any;
export type StaffQueryVariables = {|
  eventSlug: string
|};
export type StaffQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: StaffPage_event$ref
  |},
  +org: {|
    +$fragmentRefs: StaffPage_org$ref
  |},
|};
export type StaffQuery = {|
  variables: StaffQueryVariables,
  response: StaffQueryResponse,
|};
*/


/*
query StaffQuery(
  $eventSlug: String!
) {
  event(slug: $eventSlug) {
    ...StaffPage_event
    id
  }
  org {
    ...StaffPage_org
    id
  }
}

fragment StaffPage_event on Event {
  slug
  ...StaffHeader_event
}

fragment StaffPage_org on Org {
  ...StaffHeader_org
  customFields(customizableType: [STAFFMEMBERSHIP]) {
    edges {
      node {
        id
        label
        fieldName
        order
        kind
        required
        customizableType
        options {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}

fragment StaffHeader_org on Org {
  viewerCanManageUsers
}

fragment StaffHeader_event on Event {
  id
  name
  startDate
  endDate
  tz
  primaryLocation {
    name
    city
    state
    country
    id
  }
  viewerCanAddStaff
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "StaffQuery",
  "id": null,
  "text": "query StaffQuery(\n  $eventSlug: String!\n) {\n  event(slug: $eventSlug) {\n    ...StaffPage_event\n    id\n  }\n  org {\n    ...StaffPage_org\n    id\n  }\n}\n\nfragment StaffPage_event on Event {\n  slug\n  ...StaffHeader_event\n}\n\nfragment StaffPage_org on Org {\n  ...StaffHeader_org\n  customFields(customizableType: [STAFFMEMBERSHIP]) {\n    edges {\n      node {\n        id\n        label\n        fieldName\n        order\n        kind\n        required\n        customizableType\n        options {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment StaffHeader_org on Org {\n  viewerCanManageUsers\n}\n\nfragment StaffHeader_event on Event {\n  id\n  name\n  startDate\n  endDate\n  tz\n  primaryLocation {\n    name\n    city\n    state\n    country\n    id\n  }\n  viewerCanAddStaff\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "StaffQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "StaffPage_event",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "StaffPage_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StaffQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "startDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "endDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tz",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "primaryLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "Location",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "city",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "state",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "country",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanAddStaff",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanManageUsers",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"STAFFMEMBERSHIP\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "customizableType",
                "value": [
                  "STAFFMEMBERSHIP"
                ],
                "type": "[CustomizableType!]!"
              }
            ],
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fieldName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "kind",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "required",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "customizableType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "options",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionTypeRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldOptionTypeRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CustomFieldOptionType",
                                "plural": false,
                                "selections": [
                                  v2,
                                  v3
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5f35a40a252c38c31cf87f2e4a01ac28';
module.exports = node;
