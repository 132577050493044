/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { ContactSalesforceSyncAs_contact } from './__generated__/ContactSalesforceSyncAs_contact.graphql';

class ContactSalesforceSyncAs extends React.Component<{
  contact: ContactSalesforceSyncAs_contact,
}> {
  render() {
    const { contact } = this.props;

    return contact.salesforceSyncAs || null;
  }
}

export default createFragmentContainer(
  ContactSalesforceSyncAs,
  graphql`
    fragment ContactSalesforceSyncAs_contact on Contact {
      salesforceSyncAs
    }
  `,
);
