/* @flow */
import * as React from 'react';

import storage from 'utils/storage';

import { type ColumnConfiguration } from './index';

const getDefaultTableColumns = (ColsConfig: ColumnConfiguration): $ReadOnlyArray<string> =>
  ColsConfig.filter(columnConfig => columnConfig.default).map(column => column.value);

const getShownColumns = (
  storageName: ?string,
  ColsConfig: ColumnConfiguration,
): $ReadOnlyArray<string> => {
  const shownColumns: any = storageName ? (storage.get(storageName): ?mixed) : null;
  const defaultTableColumns = storageName
    ? getDefaultTableColumns(ColsConfig)
    : ColsConfig.map(column => column.value);
  return Array.isArray(shownColumns) ? shownColumns : defaultTableColumns;
};

export default <Props: {}>(
  Component: React.ComponentType<Props>,
  ColsConfig: ColumnConfiguration,
): Class<React.Component<{ ...Props, storageName?: string }, any>> => {
  return class extends React.Component<
    { ...Props, storageName?: string },
    {
      customColumnWidths: { [string]: number },
      shownColumns: $ReadOnlyArray<string>,
    },
  > {
    state = {
      shownColumns: getShownColumns(
        this.props.storageName == null ? null : String(this.props.storageName),
        ColsConfig,
      ),
      customColumnWidths: {},
    };

    handleColsWidthChange = (value: string, width: number) => {
      this.setState(state => ({
        customColumnWidths: {
          ...state.customColumnWidths,
          [value]: Math.max(state.customColumnWidths[value] || 0, width),
        },
      }));
    };

    handleColumnsChange = (shownColumns?: $ReadOnlyArray<string>) => {
      if (this.props.storageName != null) {
        storage.set(String(this.props.storageName), shownColumns);
      }
      this.setState({
        shownColumns: shownColumns || getDefaultTableColumns(ColsConfig),
      });
    };

    render() {
      const shownColsConfig = ColsConfig.filter(
        col => col.sticky || this.state.shownColumns.includes(col.value),
      ).map(col => ({
        ...col,
        width: this.state.customColumnWidths[col.value] || col.width,
      }));

      // Sum of column widths + 10px padding for each column + 50 for actions column
      // This is experimental we can skip 50 and use in tables where they need it.
      const containerWidth =
        shownColsConfig.map(col => col.width).reduce((sum, width) => sum + width) +
        shownColsConfig.length * 10 +
        50;

      return (
        <Component
          {...this.props}
          ColsConfig={ColsConfig}
          shownColsConfig={shownColsConfig}
          onColumnSizeChange={this.handleColsWidthChange}
          onShownColumnsChange={this.handleColumnsChange}
          containerWidth={containerWidth}
        />
      );
    }
  };
};
