/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  disconnectSalesforceAccountMutationResponse,
  disconnectSalesforceAccountMutationVariables,
} from './__generated__/disconnectSalesforceAccountMutation.graphql';

const mutation = graphql`
  mutation disconnectSalesforceAccountMutation($input: DisconnectSalesforceAccountInput!) {
    disconnectSalesforceAccount(input: $input) {
      updatedOrg {
        id
        salesforceAccount {
          id
        }
      }
    }
  }
`;

export default function disconnectSalesforceAccount(): Promise<disconnectSalesforceAccountMutationResponse> {
  const variables: disconnectSalesforceAccountMutationVariables = {
    input: {},
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
