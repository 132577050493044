/* @flow */
import { graphql } from 'react-relay';

import { type CustomFieldFromWindowTypes } from 'utils/customization/types';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import {
  type updateCustomFieldValueMutationResponse,
  type updateCustomFieldValueMutationVariables,
} from './__generated__/updateCustomFieldValueMutation.graphql';

export type UpdateCustomFieldValueInput = $Exact<
  $PropertyType<
    $PropertyType<updateCustomFieldValueMutationVariables, 'input'>,
    'customFieldValue',
  >,
>;

export type CustomizableResponse = $ReadOnly<
  {
    ...{
      ...$NonMaybeType<
        $PropertyType<
          $NonMaybeType<
            $PropertyType<updateCustomFieldValueMutationResponse, 'updateCustomFieldValue'>,
          >,
          'customizable',
        >,
      >,
    },
  } & { +id: string, +__typename: string },
>;

const mutation = graphql`
  mutation updateCustomFieldValueMutation($input: UpdateCustomFieldValueInput!) {
    updateCustomFieldValue(input: $input) {
      customFieldOptionEdge {
        node {
          id
          name
        }
      }
      customizable {
        id
        __typename
        customTextFields {
          customField {
            id
          }
          value
        }
        customTextareaFields {
          customField {
            id
          }
          value
        }
        customLinkFields {
          customField {
            id
          }
          value
        }
        customDateFields {
          customField {
            id
          }
          value
        }
        customNumberFields {
          customField {
            id
          }
          value
        }
        customCurrencyFields {
          customField {
            id
          }
          value
        }
        customBooleanFields {
          customField {
            id
          }
          value
        }
        customUserSelectFields {
          customField {
            id
          }
          user {
            id
          }
        }
        customUserSelectFields {
          customField {
            id
          }
          user {
            id
            firstName
            lastName
            email
            avatar
            ...MaterialAvatar_user
          }
        }
        customUserMultiselectFields {
          customField {
            id
          }
          user {
            id
            firstName
            lastName
            email
            avatar
            ...MaterialAvatar_user
          }
        }
        customSelectFields {
          customField {
            id
          }
          option {
            id
          }
          selectOtherValue
        }
        customMultiselectFields {
          customField {
            id
          }
          option {
            id
          }
          selectOtherValue
        }
        ... on Contact {
          syncStatus {
            lastSuccessAt
            state
            errorMessage
          }
        }
      }
    }
  }
`;

export default function updateCustomFieldValue(
  input: UpdateCustomFieldValueInput,
  customizable: CustomizableResponse,
  eventId?: ?string,
  fromWindow: CustomFieldFromWindowTypes,
): Promise<updateCustomFieldValueMutationResponse> {
  const { userValues, selectValues, customFieldId, selectOtherValue } = input;
  const { customUserMultiselectFields, customMultiselectFields } = customizable;
  const optimisticResponse = {
    updateCustomFieldValue: {
      ...(selectOtherValue != null
        ? {
            customFieldOptionEdge: {
              node: {
                name: selectOtherValue,
              },
            },
          }
        : {}),
      customizable: {
        ...customizable,
        ...{
          ...(customizable.customTextFields != null
            ? {
                customTextFields: [
                  ...customizable.customTextFields.filter(
                    field => field.customField.id !== input.customFieldId,
                  ),
                  ...(input.textValue
                    ? [{ customField: { id: input.customFieldId }, value: input.textValue }]
                    : []),
                ],
              }
            : {}),
          ...(customizable.customTextareaFields != null
            ? {
                customTextareaFields: [
                  ...customizable.customTextareaFields.filter(
                    field => field.customField.id !== input.customFieldId,
                  ),
                  ...(input.textareaValue
                    ? [{ customField: { id: input.customFieldId }, value: input.textareaValue }]
                    : []),
                ],
              }
            : {}),
          ...(customizable.customLinkFields != null
            ? {
                customLinkFields: [
                  ...customizable.customLinkFields.filter(
                    field => field.customField.id !== input.customFieldId,
                  ),
                  ...(input.linkValue
                    ? [{ customField: { id: input.customFieldId }, value: input.linkValue }]
                    : []),
                ],
              }
            : {}),
          ...(customizable.customBooleanFields != null
            ? {
                customBooleanFields: [
                  ...customizable.customBooleanFields.filter(
                    field => field.customField.id !== input.customFieldId,
                  ),
                  ...(input.booleanValue
                    ? [{ customField: { id: input.customFieldId }, value: input.booleanValue }]
                    : []),
                ],
              }
            : {}),
          ...(customizable.customDateFields != null
            ? {
                customDateFields: [
                  ...customizable.customDateFields.filter(
                    field => field.customField.id !== input.customFieldId,
                  ),
                  ...(input.dateValue
                    ? [{ customField: { id: input.customFieldId }, value: input.dateValue }]
                    : []),
                ],
              }
            : {}),
          ...(customizable.customCurrencyFields != null
            ? {
                customCurrencyFields: [
                  ...customizable.customCurrencyFields.filter(
                    field => field.customField.id !== input.customFieldId,
                  ),
                  ...(input.currencyValue != null
                    ? [{ customField: { id: input.customFieldId }, value: input.currencyValue }]
                    : []),
                ],
              }
            : {}),
          ...(customizable.customNumberFields != null
            ? {
                customNumberFields: [
                  ...customizable.customNumberFields.filter(
                    field => field.customField.id !== input.customFieldId,
                  ),
                  ...(input.numberValue != null
                    ? [{ customField: { id: input.customFieldId }, value: input.numberValue }]
                    : []),
                ],
              }
            : {}),
          ...(customizable.customUserSelectFields != null
            ? {
                customUserSelectFields: [
                  ...customizable.customUserSelectFields.filter(
                    field => field.customField.id !== input.customFieldId,
                  ),
                  ...(input.userValue != null
                    ? [{ customField: { id: input.customFieldId }, user: { id: input.userValue } }]
                    : []),
                ],
              }
            : {}),
          ...(customUserMultiselectFields != null
            ? {
                customUserMultiselectFields: userValues
                  ? [
                      // Keep old options
                      ...customUserMultiselectFields.filter(
                        field =>
                          field.customField.id !== input.customFieldId ||
                          userValues.includes(field.user.id),
                      ),
                      // Add new options to the field
                      ...userValues
                        .filter(
                          userId =>
                            !customUserMultiselectFields.some(field => field.user.id === userId),
                        )
                        .map(userId => ({
                          customField: { id: input.customFieldId },
                          user: { id: userId },
                        })),
                    ]
                  : customUserMultiselectFields,
              }
            : {}),
          ...(customizable.customSelectFields != null
            ? {
                customSelectFields: [
                  ...customizable.customSelectFields.filter(
                    field => field.customField.id !== input.customFieldId,
                  ),
                  ...(input.selectValue != null
                    ? [
                        {
                          customField: { id: input.customFieldId },
                          option: { id: input.selectValue },
                        },
                      ]
                    : []),
                ],
              }
            : {}),
          ...(customMultiselectFields != null
            ? {
                customMultiselectFields: selectValues
                  ? [
                      // Keep old options
                      ...customMultiselectFields.filter(
                        field =>
                          field.customField.id !== input.customFieldId ||
                          selectValues.includes(field.option.id),
                      ),
                      // Add new options to the field
                      ...selectValues
                        .filter(
                          optionId =>
                            !customMultiselectFields.some(field => field.option.id === optionId),
                        )
                        .map(optionId => ({
                          customField: { id: input.customFieldId },
                          option: { id: optionId },
                        })),
                    ]
                  : customMultiselectFields,
              }
            : {}),
        },
      },
    },
  };
  const variables: updateCustomFieldValueMutationVariables = {
    input: {
      customizableId: customizable.id,
      customFieldValue: input,
      eventId,
      fromWindow,
    },
  };

  return commitModernMutation({
    optimisticResponse,
    mutation,
    variables,
    updater: selectOtherValue
      ? addRecordToConnection({
          parentId: customFieldId,
          edgeName: 'customFieldOptionEdge',
          connections: [{ field: 'options' }],
        })
      : undefined,
  });
}
