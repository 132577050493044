/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';

const mutation = graphql`
  mutation removeTaskCompanyMutation($input: RemoveDeliverableCompanyInput!) {
    removeDeliverableCompany(input: $input) {
      removedCompanyId
    }
  }
`;

export default function removeTaskCompany(taskId: string, companyId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableId: taskId,
        companyId,
      },
    },
    optimisticResponse: {
      removeDeliverableCompany: {
        removedCompanyId: companyId,
      },
    },
    updater: removeRecordFromConnection({
      parentId: taskId,
      connections: [{ field: 'companies' }],
      deletedIdField: 'removedCompanyId',
    }),
  });
}
