/* @flow */
import {
  type GraphQLTaggedNode,
  type RelayMutationConfig,
  type SelectorStoreUpdater,
  type UploadableMap,
  type Variables,
  commitMutation,
} from 'react-relay';

import getRelayModernEnvironment from 'graph/utils/getRelayModernEnvironment';

type Config = {
  configs?: Array<RelayMutationConfig>,
  mutation: GraphQLTaggedNode,
  variables: Variables,
  uploadables?: UploadableMap,
  optimisticResponse?: ?Object,
  optimisticUpdater?: ?SelectorStoreUpdater,
  updater?: ?SelectorStoreUpdater,

  // Handled by Promise:
  // onCompleted?: ?(response: T, errors: ?Array<PayloadError>) => void,
  // onError?: ?(error: Error) => void,
};

/**
 * Wrapper for commitMutation calls in our support app.  Uses the Support relay environment and
 * returns a promise that resolves on success.  Do not use in the main app.
 * On error, the promise resolves with `{ message: '...' }`
 */

export default function commitSupportMutation<MutationResponse>(
  config: Config,
): Promise<MutationResponse> {
  return new Promise((resolve, reject) =>
    commitMutation(getRelayModernEnvironment(), {
      mutation: config.mutation,
      variables: config.variables,
      optimisticResponse: config.optimisticResponse,
      optimisticUpdater: config.optimisticUpdater,
      updater: config.updater,
      onCompleted: resolve,
      onError: reject,
    }),
  );
}
