import './calendar.styl';

import React from 'react';
import classNames from 'classnames';

import { Calendar } from './Calendar';

import { createMoment, isDisabled } from 'utils/Date';

export class DoubleCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { endDateShown: false };
  }
  render() {
    const { end_date, start_date, end_date_all_day, start_date_all_day, tz, hiddenCheckbox } = this.props;
    return (
      <div className={classNames('double-calendar', { 'end-date': this.state.endDateShown })}>
        <div className="double-calendar-mobile-tabs">
          <a onClick={this.showStartDate.bind(this)}>Start Date</a>
          <a onClick={this.showEndDate.bind(this)}>{!end_date && 'Add'} End Date</a>
        </div>
        <div className="double-calendar-item calendar-start-date">
          <div className="calendar-title">Start Date</div>
          <Calendar
            before={end_date}
            before_all_day={end_date_all_day}
            date={start_date}
            all_day={start_date_all_day}
            tz={tz}
            onDateChange={this.handleStartDateChange.bind(this)}
            onAllDayChange={this.handleStartDateAllDayChange.bind(this)}
            hiddenCheckbox={hiddenCheckbox}
          />
        </div>
        {this.renderEndDate()}
      </div>
    );
  }
  renderEndDate() {
    const { end_date, start_date, end_date_all_day, start_date_all_day, tz } = this.props;
    if (end_date) {
      return (
        <div className="double-calendar-item calendar-end-date">
          <a onClick={this.handleToggleEndDate.bind(this)} className="double-calendar-end-date-remove">Remove End Date</a>
          <div className="calendar-title">End Date <a onClick={this.handleToggleEndDate.bind(this)} className="double-calendar-remove">Remove</a></div>
          <Calendar
            after={start_date}
            after_all_date={start_date_all_day}
            date={end_date}
            all_day={end_date_all_day}
            tz={tz}
            onDateChange={this.handleEndDateChange.bind(this)}
            onAllDayChange={this.handleEndDateAllDayChange.bind(this)}
          />
        </div>
      );
    } else {
      return (
        <div className="double-calendar-item calendar-end-date">
          <div className="calendar-title">End Date <a onClick={this.handleToggleEndDate.bind(this)} className="double-calendar-remove">Add</a></div>
        </div>
      );
    }
  }

  showEndDate() {
    if (! this.props.endDate) {
      this.handleToggleEndDate();
    }
    this.setState({ endDateShown: true });
  }
  showStartDate() {
    this.setState({ endDateShown: false });
  }
  handleStartDateChange(date) {
    this.props.onChange({
      start_date: date,
    });
  }
  handleEndDateChange(date) {
    this.props.onChange({
      end_date: date,
    });
  }
  handleStartDateAllDayChange(all_day) {
    this.props.onChange({
      start_date_all_day: all_day,
    });
  }
  handleEndDateAllDayChange(all_day) {
    this.props.onChange({
      end_date_all_day: all_day,
    });
  }
  handleToggleEndDate() {
    if (!this.props.end_date) {
      const { start_date, start_date_all_day, tz } = this.props;
      const _start_date = createMoment(tz)(start_date);
      const _end_date = _start_date.clone();

      if (start_date_all_day) {
        _end_date.add(1, 'day');
      } else {
        _end_date.add(30, 'minutes');
      }

      this.props.onChange({
        end_date: _end_date.toDate(),
        end_date_all_day: start_date_all_day,
      });
    } else {
      this.props.onChange({
        end_date: null,
      });
    }
    this.setState({
      endDateShown: ! this.props.end_date,
    });
  }
}
