/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type { CircaFile } from 'utils/uploading/types';

const mutation = graphql`
  mutation addExpenseAttachmentMutation($input: AddExpenseAttachmentInput!) {
    addExpenseAttachment(input: $input) {
      attachmentEdge {
        __typename
        node {
          id
          fileurl
          filename
          filetype
          filesize
          viewerCanDelete
        }
      }
    }
  }
`;

export default function addExpenseAttachment(expenseId: string, file: CircaFile) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        expenseId,
        ...file,
      },
    },
    updater: addRecordToConnection({
      parentId: expenseId,
      edgeName: 'attachmentEdge',
      connections: [{ field: 'attachments' }],
    }),
  });
}
