/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type SectionFilterType } from 'components/FieldSettings/types';

import type {
  removeCustomFieldSectionMutationVariables,
  removeCustomFieldSectionMutationResponse,
} from './__generated__/removeCustomFieldSectionMutation.graphql';

const mutation = graphql`
  mutation removeCustomFieldSectionMutation($input: RemoveCustomFieldSectionInput!) {
    removeCustomFieldSection(input: $input) {
      removedCustomFieldSectionId
    }
  }
`;

export default function removeCustomField(
  orgId: string,
  customFieldSectionId: string,
  filters: SectionFilterType,
): Promise<removeCustomFieldSectionMutationResponse> {
  const variables: removeCustomFieldSectionMutationVariables = {
    input: {
      customFieldSectionId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedCustomFieldSectionId',
        parentId: orgId,
        connections: [
          {
            field: 'customFieldSections',
            args: filters,
          },
        ],
      }),
      removeRecordFromStore({ deletedIdField: 'removedCustomFieldSectionId' }),
    ),
  });
}
