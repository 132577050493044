/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ActiveSubscriptionMessage_subscription$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BillingPlanMessage_subscription$ref: FragmentReference;
export type BillingPlanMessage_subscription = {|
  +upgradeRequired: boolean,
  +$fragmentRefs: ActiveSubscriptionMessage_subscription$ref,
  +$refType: BillingPlanMessage_subscription$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BillingPlanMessage_subscription",
  "type": "Subscription",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "upgradeRequired",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ActiveSubscriptionMessage_subscription",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '79ea04f65db56916f1fce66950451be5';
module.exports = node;
