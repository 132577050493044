/**
 * @flow
 * @relayHash 9e42b19ab6d96d566d9a8316c4fc7f43
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TasksFolder_folder$ref = any;
export type CreateFolderInput = {
  clientMutationId?: ?string,
  eventId: string,
  name: string,
};
export type createFolderMutationVariables = {|
  input: CreateFolderInput
|};
export type createFolderMutationResponse = {|
  +createFolder: ?{|
    +event: {|
      +id: string,
      +dbId: number,
      +name: string,
    |},
    +folderEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +dbId: number,
        +name: string,
        +order: number,
        +viewerCanUpdate: boolean,
        +viewerCanDelete: boolean,
        +$fragmentRefs: TasksFolder_folder$ref,
      |},
    |},
  |}
|};
export type createFolderMutation = {|
  variables: createFolderMutationVariables,
  response: createFolderMutationResponse,
|};
*/


/*
mutation createFolderMutation(
  $input: CreateFolderInput!
) {
  createFolder(input: $input) {
    event {
      id
      dbId
      name
    }
    folderEdge {
      __typename
      node {
        id
        dbId
        name
        order
        viewerCanUpdate
        viewerCanDelete
        ...TasksFolder_folder
      }
    }
  }
}

fragment TasksFolder_folder on Folder {
  id
  name
  viewerCanUpdate
  viewerCanDelete
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateFolderInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateFolderInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "event",
  "storageKey": null,
  "args": null,
  "concreteType": "Event",
  "plural": false,
  "selections": [
    v2,
    v3,
    v4
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanDelete",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createFolderMutation",
  "id": null,
  "text": "mutation createFolderMutation(\n  $input: CreateFolderInput!\n) {\n  createFolder(input: $input) {\n    event {\n      id\n      dbId\n      name\n    }\n    folderEdge {\n      __typename\n      node {\n        id\n        dbId\n        name\n        order\n        viewerCanUpdate\n        viewerCanDelete\n        ...TasksFolder_folder\n      }\n    }\n  }\n}\n\nfragment TasksFolder_folder on Folder {\n  id\n  name\n  viewerCanUpdate\n  viewerCanDelete\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createFolderMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createFolder",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateFolderPayload",
        "plural": false,
        "selections": [
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "folderEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "FolderRequiredEdge",
            "plural": false,
            "selections": [
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Folder",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v7,
                  v8,
                  v9,
                  {
                    "kind": "FragmentSpread",
                    "name": "TasksFolder_folder",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createFolderMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createFolder",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateFolderPayload",
        "plural": false,
        "selections": [
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "folderEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "FolderRequiredEdge",
            "plural": false,
            "selections": [
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Folder",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v7,
                  v8,
                  v9
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b320928020a5add8f67349a17b1a4e1';
module.exports = node;
