/* @flow */
import validateRulesAndConditions, {
  type ContactFormFieldsValueType,
  type ContactFormFieldType,
} from './validateRulesAndConditions';

const isVisibleQuestion = (
  contactFormValues: ContactFormFieldsValueType,
  question: ContactFormFieldType,
  tz: string,
) => {
  // if no rules with hide actions then question is visible
  if (
    !question.rules ||
    !question.rules.edges.some(({ node: rule }) =>
      ['HIDE', 'HIDE_BY_DEFAULT'].includes(rule.action),
    )
  ) {
    return true;
  }

  const defaultVisible = !question.rules.edges.some(
    ({ node: rule }) => rule.action === 'HIDE_BY_DEFAULT',
  );

  const makeVisibleRules =
    question.rules &&
    question.rules.edges.filter(({ node: rule }) => rule.action === 'SHOW').map(edge => edge.node);
  const makeHiddenRules =
    question.rules &&
    question.rules.edges.filter(({ node: rule }) => rule.action === 'HIDE').map(edge => edge.node);

  const makeVisibleRuleResult = validateRulesAndConditions(
    makeVisibleRules || [],
    contactFormValues,
    tz,
  );
  const makeHiddenRuleResult = validateRulesAndConditions(
    makeHiddenRules || [],
    contactFormValues,
    tz,
  );

  // Default changes to false if there are only rules to make the question hidden
  // Visible rule has higher priority than hide rule
  if (defaultVisible && (makeVisibleRuleResult || !makeHiddenRuleResult)) {
    return true;
  }

  if (!defaultVisible && makeVisibleRuleResult) {
    return true;
  }

  return false;
};

export default isVisibleQuestion;
