/* @flow */
import type { RouterHistory } from 'react-router-dom';
import qs from 'qs';

type QueryParams = { [key: string]: ?string };

/**
 * Redirects to a new URL, overwriting the params in paramSubset with new values.  Params not
 * listed in paramSubset are preserved at their existing values.
 * Use this to update filters or sorting, for example.
 */
export default function replaceQueryParams(history: RouterHistory, paramSubset: QueryParams) {
  const paramObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const updatedParamObject = Object.assign({}, paramObject, paramSubset);
  const search = qs.stringify(updatedParamObject, { skipNulls: true });

  history.replace({
    search,
    hash: history.location.hash,
  });
}
