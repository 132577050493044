/**
 * @flow
 * @relayHash 8cbd856eda8bdf2e41b30551ee3a1f31
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DiscardRegistrationFormChangesInput = {
  clientMutationId?: ?string,
  formId: string,
};
export type discardRegistrationFormChangesMutationVariables = {|
  input: DiscardRegistrationFormChangesInput,
  expectingResponse: boolean,
|};
export type discardRegistrationFormChangesMutationResponse = {|
  +discardRegistrationFormChanges: ?{|
    +event?: {|
      +id: string,
      +editableRegistrationForm: ?{|
        +id: string,
        +hasChanges: boolean,
      |},
    |}
  |}
|};
export type discardRegistrationFormChangesMutation = {|
  variables: discardRegistrationFormChangesMutationVariables,
  response: discardRegistrationFormChangesMutationResponse,
|};
*/


/*
mutation discardRegistrationFormChangesMutation(
  $input: DiscardRegistrationFormChangesInput!
  $expectingResponse: Boolean!
) {
  discardRegistrationFormChanges(input: $input) {
    event @include(if: $expectingResponse) {
      id
      editableRegistrationForm {
        id
        hasChanges
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DiscardRegistrationFormChangesInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "expectingResponse",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "discardRegistrationFormChanges",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DiscardRegistrationFormChangesInput!"
      }
    ],
    "concreteType": "DiscardRegistrationFormChangesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "expectingResponse",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "editableRegistrationForm",
                "storageKey": null,
                "args": null,
                "concreteType": "RegistrationForm",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasChanges",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "discardRegistrationFormChangesMutation",
  "id": null,
  "text": "mutation discardRegistrationFormChangesMutation(\n  $input: DiscardRegistrationFormChangesInput!\n  $expectingResponse: Boolean!\n) {\n  discardRegistrationFormChanges(input: $input) {\n    event @include(if: $expectingResponse) {\n      id\n      editableRegistrationForm {\n        id\n        hasChanges\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "discardRegistrationFormChangesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "discardRegistrationFormChangesMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e533c9fcc3bafa30f3361c05e8b6c351';
module.exports = node;
