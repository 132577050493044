/* @flow */
import { type FieldType } from 'utils/customization/types';
import capitalize from 'utils/string/capitalize';

const getOptionKeysByKind = (
  kind: $PropertyType<FieldType, 'kind'>,
): { arrayKey: string, optionKey: string } => {
  const arrayKey = `custom${capitalize(kind)}Fields`;
  switch (kind) {
    case 'TEXT':
    case 'TEXTAREA':
    case 'NUMBER':
    case 'CURRENCY':
    case 'DATE':
    case 'BOOLEAN':
    case 'LINK':
      return {
        arrayKey,
        optionKey: 'value',
      };
    case 'USER_SELECT':
    case 'USER_MULTISELECT':
      return {
        arrayKey,
        optionKey: 'user',
      };
    default:
      return {
        arrayKey,
        optionKey: 'option',
      };
  }
};

export default getOptionKeysByKind;
