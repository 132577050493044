/**
 * @flow
 * @relayHash abef29fb908319713c677dc3e25df3e0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UsersImportWindowQueryVariables = {||};
export type UsersImportWindowQueryResponse = {|
  +org: {|
    +subscription: {|
      +fatmLimit: ?number,
      +fatmCount: number,
    |},
    +teamMembers: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +email: string
        |}
      |}>
    |},
  |}
|};
export type UsersImportWindowQuery = {|
  variables: UsersImportWindowQueryVariables,
  response: UsersImportWindowQueryResponse,
|};
*/


/*
query UsersImportWindowQuery {
  org {
    subscription {
      fatmLimit
      fatmCount
      id
    }
    teamMembers: users(hasTeamAccess: true) {
      edges {
        node {
          email
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fatmLimit",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fatmCount",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "hasTeamAccess",
    "value": true,
    "type": "Boolean"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UsersImportWindowQuery",
  "id": null,
  "text": "query UsersImportWindowQuery {\n  org {\n    subscription {\n      fatmLimit\n      fatmCount\n      id\n    }\n    teamMembers: users(hasTeamAccess: true) {\n      edges {\n        node {\n          email\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UsersImportWindowQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v0,
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "teamMembers",
            "name": "users",
            "storageKey": "users(hasTeamAccess:true)",
            "args": v2,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UsersImportWindowQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v0,
              v1,
              v4
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "teamMembers",
            "name": "users",
            "storageKey": "users(hasTeamAccess:true)",
            "args": v2,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v3,
                      v4
                    ]
                  }
                ]
              }
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd118317bd50dc260bf053bd682c5d99d';
module.exports = node;
