/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';
import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation removeEventEmailTemplateMutation($input: RemoveEventEmailTemplateInput!) {
    removeEventEmailTemplate(input: $input) {
      removedEventEmailTemplateId
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function removeEventEmailTemplate(formId: string, eventEmailTemplateId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        eventEmailTemplateId,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedEventEmailTemplateId',
        parentId: formId,
        connections: [{ field: 'eventEmailTemplates' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedEventEmailTemplateId' }),
    ),
  });
}
