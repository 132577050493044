/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import staffAccessLevels, { type StaffAccessLevel } from 'config/staffAccessLevels';

import escapeRegexString from 'utils/escapeRegexString';

import SelectField from 'components/material/SelectField';

import AccessTypeSelector, { type AccessScope, type AccessType } from './AccessTypeSelector';
import EventSearch, { type Event } from './InvitationEventSearch';
import SelectionRow from './SelectionRow';
import EventSelectionRow from './SelectionRow/EventSelectionRow';

import type { InviteToOrgContents_me } from './__generated__/InviteToOrgContents_me.graphql';
import type { InviteToOrgContents_org } from './__generated__/InviteToOrgContents_org.graphql';

const AccessRowColumn = styled.div`
  flex: 1 1 auto;
`;

const AccessRow = styled.div`
  display: flex;
  flex-direction: ${props => (props.wrapSelects ? 'column' : 'row')};

  > ${AccessRowColumn}:not(:first-child):not(:only-child) {
    margin: ${props => (props.wrapSelects ? '15px 0 0 0' : '0 0 0 35px')};
  }
`;

const SelectionList = styled.div`
  margin-top: 25px;
`;

class InviteToOrgContents extends React.Component<
  {
    me: InviteToOrgContents_me,
    org: InviteToOrgContents_org,
    accessType: AccessType,
    accessScope?: AccessScope,
    onAccessTypeChange: (accessType: ?AccessType) => void,
    skipStaffAccessLevel?: StaffAccessLevel,
    selectedTeamIds: $ReadOnlyArray<string>,
    onSelectTeam: (teamId: string) => void,
    onUnselectTeam: (teamId: string) => void,
    selectedEvents: $ReadOnlyArray<Event>,
    onSelectEvent: (event: Event) => void,
    onUnselectEvent: (eventId: string) => void,
    wrapSelects?: boolean,
    canChangeTeams?: boolean,
    canChangeEvents?: boolean,
    onToggleTeamQueryState?: (present: boolean) => void,
    onToggleEventQueryState?: (present: boolean) => void,
  },
  {
    teamQuery: ?string,
  },
> {
  state = { teamQuery: null };

  eventQueryExists: boolean;

  static defaultProps = {
    canChangeTeams: true,
    canChangeEvents: true,
  };

  getAccessScope() {
    if (this.props.accessScope) return this.props.accessScope;
    if (this.props.me.admin) {
      return 'ORG';
    }
    if (this.props.org.viewerCanManageTeamMembers) {
      return 'TEAM';
    }
    return 'EVENT';
  }

  handleSelectTeam = (teamId: ?string) => {
    if (teamId != null) {
      this.props.onSelectTeam(teamId);
    }
  };

  handleSelectEvent = (event: ?Event) => {
    if (event != null) {
      this.props.onSelectEvent(event);
    }
  };

  handleTeamSearch = (query?: string = '') => {
    const trimmedQuery = query.trim();

    if (trimmedQuery && !this.state.teamQuery && this.props.onToggleTeamQueryState) {
      this.props.onToggleTeamQueryState(true);
    }
    if (!trimmedQuery) {
      this.handleHideOptions();
    }
    this.setState({ teamQuery: trimmedQuery });
  };

  handleHideOptions = () => {
    if (this.state.teamQuery && this.props.onToggleTeamQueryState) {
      this.props.onToggleTeamQueryState(false);
    }

    this.setState({ teamQuery: '' });
  };

  handleEventSearch = (query?: string = '') => {
    if (query.trim() && !this.eventQueryExists && this.props.onToggleEventQueryState) {
      this.props.onToggleEventQueryState(true);
      this.eventQueryExists = true;
    }
    if (!query.trim() && this.eventQueryExists && this.props.onToggleEventQueryState) {
      this.props.onToggleEventQueryState(false);
      this.eventQueryExists = false;
    }
  };

  filteredTeams = (): $ReadOnlyArray<{ +id: string, +name: string }> => {
    if (!this.state.teamQuery) {
      return this.props.me.memberships.edges.map(edge => edge.node);
    }

    const queryRegexp = new RegExp(escapeRegexString(this.state.teamQuery), 'i');
    return this.props.me.memberships.edges
      .filter(edge => queryRegexp.test(edge.node.name.replace(/\s/g, '')))
      .map(edge => edge.node);
  };

  render() {
    const teams = this.filteredTeams().map(team => ({
      label: team.name,
      value: team.id,
    }));

    const teamOptions = teams.filter(team => !this.props.selectedTeamIds.includes(team.value));
    const selectedTeams = this.props.me.memberships.edges
      .map(edge => edge.node)
      .filter(team => this.props.selectedTeamIds.includes(team.id));

    return (
      <>
        <AccessRow wrapSelects={this.props.wrapSelects}>
          <AccessRowColumn>
            <AccessTypeSelector
              value={this.props.accessType}
              onChange={this.props.onAccessTypeChange}
              scope={this.getAccessScope()}
              skipStaffAccessLevel={this.props.skipStaffAccessLevel}
            />
          </AccessRowColumn>
          <AccessRowColumn>
            {this.props.canChangeTeams && this.props.accessType === 'TEAM' && (
              <SelectField
                label="Select Teams *"
                options={teamOptions}
                onChange={this.handleSelectTeam}
                onSearch={this.handleTeamSearch}
                onHideOptions={this.handleHideOptions}
                searchable
              />
            )}
            {this.props.canChangeEvents && staffAccessLevels.includes(this.props.accessType) && (
              <EventSearch
                label="Select Events *"
                selectedEventIds={this.props.selectedEvents.map(event => event.id)}
                onSelect={this.handleSelectEvent}
                onSearch={this.handleEventSearch}
                onHideOptions={this.handleEventSearch}
              />
            )}
          </AccessRowColumn>
        </AccessRow>
        {this.props.accessType === 'TEAM' && selectedTeams.length > 0 && (
          <SelectionList>
            {selectedTeams.map(team => (
              <SelectionRow
                key={team.id}
                value={team.id}
                onRemove={this.props.canChangeTeams ? this.props.onUnselectTeam : null}
              >
                <div>{team.name}</div>
              </SelectionRow>
            ))}
          </SelectionList>
        )}
        {staffAccessLevels.includes(this.props.accessType) &&
          this.props.selectedEvents.length > 0 &&
          this.props.canChangeEvents && (
            <SelectionList>
              {this.props.selectedEvents.map(event => (
                <EventSelectionRow
                  key={event.id}
                  event={event}
                  onRemove={this.props.onUnselectEvent}
                />
              ))}
            </SelectionList>
          )}
      </>
    );
  }
}

export default createFragmentContainer(InviteToOrgContents, {
  me: graphql`
    fragment InviteToOrgContents_me on User {
      admin
      memberships {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
  org: graphql`
    fragment InviteToOrgContents_org on Org {
      viewerCanManageTeamMembers
      viewerCanManageStaff
    }
  `,
});
