/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { DependencyRow_dependency } from './__generated__/DependencyRow_dependency.graphql';

const Remove = styled.div`
  font-size: 14px;
  margin-left: 10px;
  color: ${props => props.theme.secondaryActionColor};
  cursor: pointer;
  visibility: hidden;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const Container = styled.div`
  display: flex;
  padding: 3px 5px 3px 15px;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
    ${Remove} {
      visibility: visible;
    }
  }
`;

const Name = styled.div`
  flex: 1 1 auto;
  width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.primaryActionColor};
`;

const Status = styled.i`
  color: ${props =>
    props.completed ? props.theme.highlightColor : props.theme.checkboxUncheckedColor};
  margin-right: 6px;
`;

class DependencyRow extends React.PureComponent<{
  dependency: DependencyRow_dependency,
  disabled?: boolean,
  onClick: (slug: string) => void,
  onRemove: (id: string) => void,
}> {
  handleRemove = (e: SyntheticEvent<>) => {
    e.stopPropagation();
    this.props.onRemove(this.props.dependency.id);
  };

  handleClick = () => {
    this.props.onClick(this.props.dependency.slug);
  };

  render() {
    return (
      <Container onClick={this.handleClick}>
        <Status
          className="fa fa-fw fa-check"
          completed={this.props.dependency.status === 'COMPLETED'}
        />
        <Name>{this.props.dependency.name}</Name>
        {!this.props.disabled && (
          <Remove onClick={this.handleRemove}>
            <i className="fa fa-fw fa-trash" />
          </Remove>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  DependencyRow,
  graphql`
    fragment DependencyRow_dependency on Deliverable {
      id
      name
      status
      slug
    }
  `,
);
