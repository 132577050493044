/* @flow */

import React from 'react';
import { createRefetchContainer, graphql, type RelayRefetchProp } from 'react-relay';
import styled from 'styled-components';

import theme from 'config/theme';

import NoResultsMessage from 'components/NoResultsMessage';
import Checkbox, { Check } from 'components/material/CheckBox';
import {
  BriefUserAvatar,
  BriefUserEmail,
  BriefUserName,
  BriefUserTitleCompany,
} from '../../BriefView/components/BriefUser';
import AddNewLink from '../AddNewLink';

import ContactsContainer_event from './__generated__/ContactsContainer_event.graphql';

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 25px;
  align-items: flex-start;
  ${Check} {
    margin-top: 3px;
    border-radius: 2px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
`;

const StyledBriefUserAvatar = styled(BriefUserAvatar)`
  margin-right: 10px;
  border: ${props => (props.avatar != null ? '0' : '1')}px solid #979797;
`;

class ContactsContainer extends React.Component<{
  relay: RelayRefetchProp,
  event: ContactsContainer_event,
  selectedContacts: $ReadOnlyArray<string>,
  searchQuery: string,
  onAllContactCheckToggle: (
    isPrevAllCheck: boolean,
    searchedContactIds: $ReadOnlyArray<string>,
  ) => void,
  handleContactToggle: (attachmentId: string, isPrevChecked: boolean) => void,
}> {
  componentWillUnmount() {
    this.removeTabChangeListener();
  }

  removeTabChangeListener = () => {
    this.contactWindow = null;
    document.removeEventListener('visibilitychange', this.documentFocus);
  };

  documentFocus = () => {
    if (document.visibilityState === 'visible') {
      const {
        relay,
        event: { slug: eventSlug },
        searchQuery: query,
      } = this.props;
      relay.refetch({ eventSlug, query });
    }
  };

  handleAddNewClick = () => {
    const needToAddListener = !this.contactWindow;
    this.contactWindow = window.open(`/events/${this.props.event.slug}/contacts`, '_blank');
    if (needToAddListener) {
      this.contactWindow.addEventListener('beforeunload', this.removeTabChangeListener);
      document.addEventListener('visibilitychange', this.documentFocus);
    }
  };

  contactWindow: ?EventTarget;

  renderContactLabel = contact => {
    return (
      <Content>
        <StyledBriefUserAvatar user={contact} radius={13} />
        <div>
          <BriefUserName firstName={contact.firstName} lastName={contact.lastName} />
          <BriefUserTitleCompany title={contact.title} company={contact.company} />
          <BriefUserEmail email={contact.email} />
        </div>
      </Content>
    );
  };

  render() {
    const {
      event: { allContacts },
      selectedContacts,
      onAllContactCheckToggle,
      handleContactToggle,
    } = this.props;
    const allSearchedContacts = allContacts.edges.map(({ node }) => node);
    const allContactsIds = allSearchedContacts.map(contact => contact.id);
    const checkAll = allSearchedContacts.every(contact => selectedContacts.includes(contact.id));

    return (
      <React.Fragment>
        <AddNewLink label="Add new contact" onClick={this.handleAddNewClick} />
        {allSearchedContacts.length > 0 ? (
          <React.Fragment>
            <StyledCheckbox
              label="All"
              key={0}
              checked={checkAll}
              checkColor={checkAll ? theme.highlightColor : theme.silver}
              onChange={() => onAllContactCheckToggle(checkAll, allContactsIds)}
            />
            {allSearchedContacts.map(contact => {
              const checked = selectedContacts.includes(contact.id);
              return (
                <StyledCheckbox
                  key={contact.id}
                  checked={checked}
                  checkColor={checked ? theme.highlightColor : theme.silver}
                  labelRenderer={() => this.renderContactLabel(contact)}
                  onChange={() => handleContactToggle(contact.id, checked)}
                />
              );
            })}
          </React.Fragment>
        ) : (
          <NoResultsMessage iconName="user-o" message="Contact not found." />
        )}
      </React.Fragment>
    );
  }
}

export default createRefetchContainer(
  ContactsContainer,
  graphql`
    fragment ContactsContainer_event on Event {
      slug
      allContacts: eventContacts(filters: $contactFilters) {
        edges {
          node {
            id
            firstName
            lastName
            email
            company {
              name
            }
            ...BriefUserAvatar_user
          }
        }
      }
    }
  `,
  graphql`
    query ContactsContainerRefetchQuery($eventSlug: String!, $contactFilters: ContactFilters!) {
      event(slug: $eventSlug) {
        id
        ...ContactsContainer_event
      }
    }
  `,
);
