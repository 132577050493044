/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { NotificationItem_notification } from './__generated__/NotificationItem_notification.graphql';

import MaterialAvatar from 'components/material/MaterialAvatar';

import { createMoment } from 'utils/Date';

const StyledAvatar = styled(MaterialAvatar)`
  flex-shrink: 0;
  margin-right: 15px;
  @media (${props => props.theme.mobileOnly}) {
    margin-right: 8px;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 8px 15px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
  @media (${props => props.theme.mobileOnly}) {
    padding: 8px 5px;
  }
`;

const Content = styled.div`
  font-size: 13px;
  padding: 2px 0;
  line-height: 1.4;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const Info = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: ${props => props.theme.rowSecondaryTextColor};
`;

class NotificationsList extends React.PureComponent<{
  notification: NotificationItem_notification,
  timezone: string,
}> {
  renderText() {
    const string = this.props.notification.text;

    return string.split('|||').map((part, index) => {
      if (index === 0) {
        return (
          <strong key="user">
            {part}
          </strong>
        );
      }
      return part;
    });
  }

  render() {
    const createdAt = createMoment(this.props.timezone)(this.props.notification.createdAt);
    const infoText = this.props.notification.type.includes('email')
      ? `${createdAt.fromNow(true)} ago via email`
      : `${createdAt.fromNow(true)} ago`;

    return (
      <Container>
        <StyledAvatar user={this.props.notification.owner} radius={13} />
        <Content>
          {this.renderText()}
          <Info>
            {infoText}
          </Info>
        </Content>
      </Container>
    );
  }
}

export default createFragmentContainer(
  NotificationsList,
  graphql`
    fragment NotificationItem_notification on Notification {
      id
      text
      type
      createdAt
      owner {
        ...MaterialAvatar_user
      }
    }
  `,
);
