/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';

import type { EventVenueName_event } from './__generated__/EventVenueName_event.graphql';

const EventVenueName = (props: { event: EventVenueName_event }) => {
  const primaryLocation = props.event.primaryLocation;
  return (
    <InlineExpandableText>
      {primaryLocation && primaryLocation.name ? primaryLocation.name : null}
    </InlineExpandableText>
  );
};

export default createFragmentContainer(
  EventVenueName,
  graphql`
    fragment EventVenueName_event on Event {
      primaryLocation {
        name
      }
    }
  `,
);
