/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import CustomizableFieldsList from 'components/FieldSettings/CustomizableFieldsList';
import { type CustomizableType, type FilterType } from 'components/FieldSettings/types';

import getStandardizedFields from './lib/getStandardizedFields';

import { type CustomFieldSettingsQueryResponse } from './__generated__/CustomFieldSettingsQuery.graphql';

const query = graphql`
  query CustomFieldSettingsQuery(
    $customizableType: [CustomizableType!]!
    $includeDisabled: Boolean
    $includeRestrictManagingOptions: Boolean!
  ) {
    org {
      id
      customFields(customizableType: $customizableType, includeDisabled: $includeDisabled) {
        edges {
          node {
            id
            kind
            label
            fieldName
            required
            enabled
            order
            showInCreateForm
            showInMobileForm
            restrictManagingOptions: mappedToSalesforce @skip(if: $includeRestrictManagingOptions)
            options {
              edges {
                node {
                  id
                  name
                  editable
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class CustomFieldSettingsQuery extends React.Component<{
  syncEnabled?: boolean,
  filters: FilterType,
  customizableType: CustomizableType,
}> {
  render() {
    const { filters, syncEnabled, customizableType } = this.props;
    return (
      <DefaultQueryRenderer
        query={query}
        variables={filters}
        renderSuccess={(props: CustomFieldSettingsQueryResponse) => {
          const fields = props.org.customFields.edges.map(edge => {
            const { options, ...rest } = edge.node;
            return {
              ...rest,
              options: (options && options.edges.map(optionEdge => optionEdge.node)) || [],
            };
          });
          return (
            <CustomizableFieldsList
              orgId={props.org.id}
              customizableType={customizableType}
              filters={filters}
              fields={getStandardizedFields(fields, customizableType, syncEnabled)}
            />
          );
        }}
      />
    );
  }
}
