import React from 'react';

import { DoubleCalendar } from 'components/form/DoubleCalendar';
import { Panel, PanelContent, PanelFooter } from './Panel';
import { Button } from 'components/form/Button';

import { createMoment, isDisabled } from 'utils/Date';

export class CalendarsPanel extends React.Component {
  state = {
    error: false,
  };

  cached_all_day = {};

  handleOk() {
    this.hide();
    if (this.props.onSave) {
      const { start_date, end_date, start_date_all_day, end_date_all_day } = this.props;
      this.props.onSave({
        start_date,
        end_date,
        start_date_all_day: start_date ? start_date_all_day : null,
        end_date_all_day: end_date ? end_date_all_day : null,
      });
    }
  }
  handleChange(date) {
    const nextProps = Object.assign({}, this.props, date);
    const { start_date, start_date_all_day, end_date, end_date_all_day, tz } = nextProps;

    // all day is not yet updated here in props, so we need a hack to get it
    if ('start_date_all_day' in date) this.cached_all_day.start_date = start_date_all_day;
    if ('end_date_all_day' in date) this.cached_all_day.end_date = end_date_all_day;

    const actual_start_all_day =
      this.cached_all_day.start_date == null ? start_date_all_day : this.cached_all_day.start_date;
    const actual_end_all_day =
      this.cached_all_day.end_date == null ? end_date_all_day : this.cached_all_day.end_date;

    if (this.state.error) {
      this.setState({ error: false });
    }

    if (end_date) {
      const _start_date = createMoment(tz)(start_date);
      const _end_date = createMoment(tz)(end_date);

      const disabled = isDisabled(
        {
          after: start_date,
          after_all_date: actual_start_all_day,
          all_day: actual_end_all_day,
          tz,
        },
        _end_date.toDate(),
      );

      if (disabled) {
        this.setState({ error: true });
      } else {
        this.props.onChange(date);
      }
    } else {
      this.props.onChange(date);
    }
  }
  show() {
    this.refs.panel.show();
  }
  hide() {
    this.refs.panel.hide();
  }
  toggle() {
    this.refs.panel.toggle();
  }

  render() {
    const {
      start_date,
      end_date,
      start_date_all_day,
      end_date_all_day,
      tz,
      hiddenCheckbox,
    } = this.props;
    return (
      <Panel size="calendar" minHeight={440} align="mobile-left-arrow" {...this.props} ref="panel">
        <div>
          {this.state.error && (
            <div className="double-calendar-error">Oops! Start time should be before end time.</div>
          )}
          <DoubleCalendar
            onChange={this.handleChange.bind(this)}
            start_date={start_date}
            start_date_all_day={start_date_all_day}
            end_date={end_date}
            end_date_all_day={end_date_all_day}
            hiddenCheckbox={hiddenCheckbox}
            tz={tz}
          />
          <PanelFooter>
            {this.props.onRemove && (
              <a className="panel-remove-link" onClick={this.props.onRemove}>
                Remove
              </a>
            )}
            <div className="panel-buttons">
              {this.props.onReset ? (
                <Button text="Clear" minimal type="button" onClick={this.props.onReset} />
              ) : (
                <Button
                  text="Cancel"
                  minimal
                  type="button"
                  onClick={this.props.onClose || this.hide.bind(this)}
                />
              )}
              <Button
                text={this.props.onSave ? 'Save' : 'OK'}
                onClick={this.handleOk.bind(this)}
                primary
                type="button"
              />
            </div>
          </PanelFooter>
        </div>
      </Panel>
    );
  }
}
