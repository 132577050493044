/* @flow */

const headerTitles = {
  profile: 'Profile Settings',
  preferences: 'Preferences',
  'email-subscriptions': 'Email Subscriptions',
  'calendar-settings': 'Calendar Settings',
  'password-reset': 'Password Reset',
};

export default headerTitles;
