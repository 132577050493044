/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory, withRouter } from 'react-router-dom';

import { type SortParam } from 'utils/routing/parseTypedQueryString';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table from 'components/budget/Table';

import columns, { type RequestSubmissionCellPropsType } from './columns';

import type { RequestSubmissionList_requestSubmissions } from './__generated__/RequestSubmissionList_requestSubmissions.graphql';

type RequestSubmission = $ElementType<RequestSubmissionList_requestSubmissions, 0>;

class RequestSubmissionList extends React.PureComponent<{
  requestSubmissions: ?RequestSubmissionList_requestSubmissions,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  tz: string,
  history: RouterHistory,
}> {
  cellProps = (
    requestSubmission: RequestSubmission,
  ): RequestSubmissionCellPropsType<RequestSubmission> => {
    return {
      requestSubmission,
      tz: this.props.tz,
    };
  };

  keyExtractor = (requestSubmission: RequestSubmission) => requestSubmission.id;

  rowClickHandler = (requestSubmission: RequestSubmission) => {
    return () => this.props.history.push(`/dashboard/event_requests/${requestSubmission.id}`);
  };

  render() {
    const { requestSubmissions, sort, onChangeSort } = this.props;

    if (requestSubmissions && requestSubmissions.length === 0) {
      return <EmptyView message="No results at this time." icon={<NoResult />} />;
    }

    return (
      <Table
        columns={columns}
        data={requestSubmissions}
        sort={sort}
        onChangeSort={onChangeSort}
        cellProps={this.cellProps}
        keyExtractor={this.keyExtractor}
        rowClickHandler={this.rowClickHandler}
      />
    );
  }
}

export default createFragmentContainer(
  withRouter(RequestSubmissionList),
  graphql`
    fragment RequestSubmissionList_requestSubmissions on EventRequestSubmission
    @relay(plural: true) {
      id
      ...RequestSubmissionStatus_requestSubmission
      ...RequestSubmissionFormName_requestSubmission
      ...RequestSubmissionRequestedDate_requestSubmission
      ...RequestSubmissionEventName_requestSubmission
      ...RequestSubmissionFormReviewers_requestSubmission
    }
  `,
);
