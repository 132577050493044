/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import getLinkError from 'utils/getLinkError';

import { type Event } from 'graph/mutations/event/updateEvent';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';
import LockIcon from 'components/LockIcon';

import StyledAnchor from './components/StyledAnchor';

import type { EventWebsite_event } from './__generated__/EventWebsite_event.graphql';

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 12px;
`;

class EventWebsite extends React.PureComponent<{
  event: EventWebsite_event,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdate?: (changedProps: $Shape<Event>) => Promise<void>,
}> {
  handleUpdate = (value: ?string) => {
    const { event, updateColumnWidth, onUpdate } = this.props;
    const website = value ? enforceHttpPrefix(value.trim()) : null;
    if (!this.getError(website) && website !== event.website && onUpdate) {
      onUpdate({ website });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    return getLinkError(value, this.props.fieldSettings.required);
  };

  render() {
    const {
      readOnly,
      updateColumnWidth,
      event: { website, mappedToSalesforce },
    } = this.props;
    const websiteFormatted = website ? <StyledAnchor href={website} title={website} /> : null;

    if (readOnly || mappedToSalesforce) {
      return (
        <InlineExpandableText>
          {websiteFormatted}
          {!readOnly && mappedToSalesforce && <StyledLockIcon label="Salesforce" />}
        </InlineExpandableText>
      );
    }

    return (
      <InlineTextField
        placeholder="Ex: https://www.example.com"
        value={website || ''}
        getError={this.getError}
        onChange={this.handleUpdate}
        updateColumnWidth={updateColumnWidth}
      >
        <InlineExpandableText>{websiteFormatted}</InlineExpandableText>
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  EventWebsite,
  graphql`
    fragment EventWebsite_event on Event {
      website
      viewerCanUpdate
      mappedToSalesforce(field: WEBSITE)
    }
  `,
);
