/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import omit from 'lodash/omit';

import type { FieldType } from 'utils/customization/types';

import AdvancedFilter from 'components/material/Filters/Advanced/AdvancedFilter';
import { type FilterChangeType } from 'components/material/Filters/Advanced/FilterItems';

import getTaskFields from './getTaskFields';
import getTaskFilterConverters from './getTaskFilterConverters';
import type { ParsedTaskFilters } from './parseTaskFilters';
import renderTaskFilter from './renderTaskFilter';

import type { TaskFilters_tags, TaskFilters_teams } from './__generated__/TaskFilters_tags.graphql';

class TaskFilters extends React.Component<{
  history: RouterHistory,
  filters: ParsedTaskFilters,
  tags: TaskFilters_tags,
  teams: TaskFilters_teams,
  viewMode: string,
  className?: string,
}> {
  filterRenderer = (
    field: FieldType,
    filters: ParsedTaskFilters,
    onFilterChange: FilterChangeType,
  ) => {
    return renderTaskFilter(field, filters, this.props.tags, this.props.teams, onFilterChange);
  };

  render() {
    const { filters, viewMode, history, className } = this.props;

    const shownFilters = viewMode === 'calendar' ? omit(filters, 'dueDate') : filters;

    return (
      <AdvancedFilter
        history={history}
        filters={shownFilters}
        filterOptions={getTaskFields(viewMode)}
        converterFn={getTaskFilterConverters}
        filterRenderer={this.filterRenderer}
        mobileBreakpoint={300}
        className={className}
      />
    );
  }
}

export default createFragmentContainer(TaskFilters, {
  tags: graphql`
    fragment TaskFilters_tags on Tag @relay(plural: true) {
      id
      color
      name
    }
  `,
  teams: graphql`
    fragment TaskFilters_teams on Team @relay(plural: true) {
      id
      name
    }
  `,
});
