/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  margin-top: 5px;
  font-size: 11px;
  color: #dd3b3b;
`;

export default class ErrorMessage extends React.Component<{
  children: React.Node,
}> {
  render() {
    return <Message>{this.props.children}</Message>;
  }
}
