/* @flow */
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import rsvpStatusOptions from 'utils/calendar/rsvpStatusOptions';
import fullNameOfUser from 'utils/fullNameOfUser';

import Remove from 'images/remove.svg';
import Tag from 'components/Contacts/Tag';
import Avatar from 'components/material/Avatar';
import SyncEnabledIcon from 'components/Salesforce/SyncIcons/SyncEnabledIcon';

import CompanyIcon from './CompanyIcon';
import { type Participant, type ParticipantLabel, participantTags } from './index';

const StyledRemove = styled(Remove)`
  visibility: hidden;
  align-self: center;
  width: 14px;
  color: ${props => props.theme.secondaryActionColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  line-height: 1.4;
  position: relative;
  &:hover {
    background-color: ${props => props.theme.hoverRowColor};
    ${StyledRemove} {
      visibility: visible;
    }
  }
`;

export const Main = styled.div`
  font-weight: 500;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

export const Secondary = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 12px;
  margin-top: 3px;
`;

const StyledTag = styled(Tag)`
  margin-left: 7px;
`;

const StyledAvatar = styled(Avatar)`
  position: relative;
  margin-right: 10px;
  > svg {
    position: absolute;
    bottom: -3px;
    right: -7px;
  }
`;

const Info = styled.div`
  flex: 1 1 auto;
`;

const SyncStatus = styled.div`
  margin: 10px;
`;

export default class ParticipantRow extends React.PureComponent<{
  participant: Participant,
  onRemove: Participant => void,
  salesforceEnabled?: boolean,
  disabled?: boolean,
  type?: ParticipantLabel,
  multipleOptions?: boolean,
}> {
  handleRemove = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.onRemove(this.props.participant);
  };

  renderParticipationStatus = (participant: Participant) => {
    return participant.participationStatus === undefined
      ? null
      : rsvpStatusOptions[participant.participationStatus].icon;
  };

  renderAvatar = (participant: Participant) => {
    if (participant.name) {
      return <CompanyIcon />;
    }

    return (
      <StyledAvatar
        profile={{
          id: participant.id,
          email: participant.email || '',
          firstName: participant.firstName || '',
          lastName: participant.lastName || '',
          avatar: participant.avatar,
        }}
      >
        {this.renderParticipationStatus(participant)}
      </StyledAvatar>
    );
  };

  renderContent() {
    const { participant, type, multipleOptions } = this.props;
    const color = type && participantTags[type.toLowerCase()];
    const name = participant.name || fullNameOfUser(participant);
    const avatarComponent = this.renderAvatar(participant);
    return (
      <Row>
        {avatarComponent}
        <Info>
          <Main>
            {name}
            {type && multipleOptions && (
              <StyledTag color={color} compact active>
                {type}
              </StyledTag>
            )}
          </Main>
          {participant.email && <Secondary>{participant.email}</Secondary>}
        </Info>
        {this.props.salesforceEnabled && participant.syncStatus && (
          <SyncStatus>
            {participant.syncStatus && (
              <SyncEnabledIcon syncStatus={participant.syncStatus.state} />
            )}
          </SyncStatus>
        )}
        {!this.props.disabled && <StyledRemove onClick={this.handleRemove} />}
      </Row>
    );
  }

  render() {
    if (this.props.participant.profileLink != null) {
      return <Link to={this.props.participant.profileLink || ''}>{this.renderContent()}</Link>;
    }
    return this.renderContent();
  }
}
