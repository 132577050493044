/* @flow */
import React from 'react';
import styled from 'styled-components';

import Submenu from 'components/Submenu';
import SubmenuItem from 'components/Submenu/SubmenuItem';

const StyledSubmenuItem = styled(SubmenuItem)`
  &[aria-current='true'] {
    font-weight: 500;
  }
`;

export default class EventBriefSubmenu extends React.Component<{}> {
  render() {
    return (
      <Submenu>
        <StyledSubmenuItem to="/settings/events/briefs/event_brief/content">
          Contents & Layout
        </StyledSubmenuItem>
        <StyledSubmenuItem to="/settings/events/briefs/event_brief/sharing">
          Default Accessibility
        </StyledSubmenuItem>
      </Submenu>
    );
  }
}
