/* @flow */
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export default styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 9px;
  color: #3e4859;

  &::before {
    content: '';
    flex-shrink: 0;
    width: 3px;
    height: 24px;
    margin-right: 10px;
    border-radius: 1.5px;
  }

  &:hover {
    color: #000;
  }

  &.active {
    font-weight: bold;
    color: #000;
    &::before {
      background-color: #5e5cb7;
    }
  }
`;
