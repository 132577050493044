/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import getLinkError from 'utils/getLinkError';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import type { ContactTwitter_contact } from './__generated__/ContactTwitter_contact.graphql';

class ContactTwitter extends React.Component<{
  contact: ContactTwitter_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?string) => {
    const linkValue = value ? enforceHttpPrefix(value.trim()) : null;
    if (!this.getError(linkValue) && linkValue !== this.props.contact.twitter) {
      this.props.onUpdate({ twitter: linkValue });
      this.props.updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    return getLinkError(value, this.props.fieldSettings.required);
  };

  render() {
    const { contact, updateColumnWidth, fieldSettings } = this.props;
    return (
      <InlineTextField
        placeholder="Ex: https://www.example.com"
        value={contact.twitter || ''}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        onChange={this.handleUpdate}
        disabled={fieldSettings.restrictChangingValue}
      >
        {contact.twitter && (
          <InlineExpandableText>
            <a
              onMouseDown={this.handleMouseDown}
              href={contact.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contact.twitter}
            </a>
          </InlineExpandableText>
        )}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  ContactTwitter,
  graphql`
    fragment ContactTwitter_contact on Contact {
      twitter
    }
  `,
);
