/**
 * @flow
 * @relayHash be389683034b87eed0ac9e173072d8d0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveDayFromScheduleInput = {
  clientMutationId?: ?string,
  scheduleDayId: string,
};
export type removeScheduleDayMutationVariables = {|
  input: RemoveDayFromScheduleInput
|};
export type removeScheduleDayMutationResponse = {|
  +removeDayFromSchedule: ?{|
    +removedDayId: string
  |}
|};
export type removeScheduleDayMutation = {|
  variables: removeScheduleDayMutationVariables,
  response: removeScheduleDayMutationResponse,
|};
*/


/*
mutation removeScheduleDayMutation(
  $input: RemoveDayFromScheduleInput!
) {
  removeDayFromSchedule(input: $input) {
    removedDayId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveDayFromScheduleInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeDayFromSchedule",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveDayFromScheduleInput!"
      }
    ],
    "concreteType": "RemoveDayFromSchedulePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedDayId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeScheduleDayMutation",
  "id": null,
  "text": "mutation removeScheduleDayMutation(\n  $input: RemoveDayFromScheduleInput!\n) {\n  removeDayFromSchedule(input: $input) {\n    removedDayId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeScheduleDayMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeScheduleDayMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '994b31dd99d8a652b994e4be8beced87';
module.exports = node;
