/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import sortBy from 'lodash/sortBy';

import TextInput from 'components/material/TextInput';

import type { QuestionType } from '../../lib/types';
import EditableQuestionUserSelectOptionRow from './EditableQuestionUserSelectOptionRow';

import { type EditableQuestionUserSelectOptions_users } from './__generated__/EditableQuestionUserSelectOptions_users.graphql';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: 18px;
  ${props =>
    props.showShadow &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 15px;
        background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.001));
        pointer-events: none;
      }
    `}
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0.001));
    pointer-events: none;
  }
`;

const Options = styled.div`
  flex: 1 1 auto;
  max-height: 235px;
  min-height: 50px;
  padding-right: 15px;
  overflow: auto;
`;

const EmptyMessage = styled.div`
  text-align: center;
  color: #3e4859;
  a {
    color: #3ba9da;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const MultiselectContainer = styled.div`
  margin: 5px 5px -20px;
  text-align: right;
  a {
    color: #3ba9da;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

class EditableQuestionSelectOptions extends React.PureComponent<
  {
    question: QuestionType,
    onChangeQuestion: (changes: $Exact<{ ...QuestionType }>) => void,
    users: EditableQuestionUserSelectOptions_users,
  },
  {
    keyword: string,
  },
> {
  state = {
    keyword: '',
  };

  componentDidMount() {
    if (this.props.question.selectUsers.length === 0) {
      this.props.onChangeQuestion({ selectUsers: this.props.users });
    }
  }

  isMultiselect = () =>
    this.props.question.mappingCustomField &&
    this.props.question.mappingCustomField.kind === 'MULTISELECT';

  filteredOptions = () => {
    const { keyword } = this.state;

    return this.props.users.filter(user =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(keyword),
    );
  };

  handleShowOption = (id: string) => {
    const userOption = this.props.users.find(user => user.id === id);

    if (!userOption) return;

    this.props.onChangeQuestion({ selectUsers: [...this.props.question.selectUsers, userOption] });
  };

  handleHideOption = (id: string) => {
    this.props.onChangeQuestion({
      selectUsers: this.props.question.selectUsers.filter(user => user.id !== id),
    });
  };

  handleShowAllOptions = () => {
    this.props.onChangeQuestion({ selectUsers: this.props.users });
  };

  handleHideAllOptions = () => {
    this.props.onChangeQuestion({ selectUsers: [] });
  };

  handleSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { keyword } = this.state;

    if (keyword !== e.currentTarget.value.trim()) {
      this.setState({
        keyword: e.currentTarget.value.trim().toLowerCase(),
      });
    }
  };

  render() {
    const { users, question } = this.props;

    const filteredOptions = this.filteredOptions();
    const showLinkToSettings = filteredOptions.length === 0;

    return (
      <React.Fragment>
        {users.length > 5 && (
          <React.Fragment>
            <TextInput placeholder="Search for User" onChange={this.handleSearchChange} />

            {users.length === filteredOptions.length && (
              <MultiselectContainer>
                {question.selectUsers.length === users.length ? (
                  <a onClick={this.handleHideAllOptions}>hide all</a>
                ) : (
                  <a onClick={this.handleShowAllOptions}>show all</a>
                )}
              </MultiselectContainer>
            )}
          </React.Fragment>
        )}
        <Container showShadow={!showLinkToSettings}>
          <Options question={question}>
            {showLinkToSettings && (
              <EmptyMessage>
                Add members in{' '}
                <a href="/workspace/members" target="_blank">
                  Workspace
                </a>
              </EmptyMessage>
            )}

            {sortBy(filteredOptions, option =>
              `${option.firstName} ${option.lastName}`.toLowerCase(),
            ).map(option => (
              <EditableQuestionUserSelectOptionRow
                key={option.id}
                type={this.isMultiselect() ? 'checkbox' : 'radio'}
                active={question.selectUsers.some(user => user.id === option.id)}
                user={option}
                onShow={this.handleShowOption}
                onHide={this.handleHideOption}
              />
            ))}
          </Options>
        </Container>
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  EditableQuestionSelectOptions,
  graphql`
    fragment EditableQuestionUserSelectOptions_users on User @relay(plural: true) {
      id
      firstName
      lastName
      email
      ...EditableQuestionUserSelectOptionRow_user
      ...QuestionUserSelectOptionRow_user
    }
  `,
);
