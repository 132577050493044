/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';

import { type CellPropsType } from 'components/budget/Table';

import { type EventCellPropsType } from './index';

import type { EventName_contactEvent } from './__generated__/EventName_contactEvent.graphql';

const preventDefault = (e: SyntheticEvent<HTMLElement>) => {
  e.preventDefault();
};

const EventName = ({
  contactEvent,
  location,
}: CellPropsType<EventCellPropsType<EventName_contactEvent>>) => {
  const { slug, name } = contactEvent.node;
  return (
    <Link
      to={{
        pathname: `/events/${slug}`,
        state: {
          from: 'contact_events',
          fromUrl: location.pathname,
        },
      }}
      onMouseDown={preventDefault}
    >
      {name}
    </Link>
  );
};

export default createFragmentContainer(
  EventName,
  graphql`
    fragment EventName_contactEvent on ContactEventEdge {
      node {
        slug
        name
      }
    }
  `,
);
