/* @flow */

import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { type CircaFile } from 'utils/uploading/types';

import addNoteToBrief from 'graph/mutations/event_brief/addNoteToBrief';
import removeNoteFromBrief from 'graph/mutations/event_brief/removeNoteFromBrief';
import addEventNoteAttachment from 'graph/mutations/event_note/addEventNoteAttachment';
import createEventNote from 'graph/mutations/event_note/createEventNote';
import removeEventNote from 'graph/mutations/event_note/removeEventNote';
import updateEventNote from 'graph/mutations/event_note/updateEventNote';
import removeAttachment from 'graph/mutations/removeAttachment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import RichText from 'components/RichText';
import ShareDropdown, { IconContainer } from 'components/ShareDropdown';
import { StyledOverlay } from 'components/ShareDropdown/ShareDropdownOverlay';

import NoteForm from '../NoteForm';

import type { NoteEdit_event } from './__generated__/NoteEdit_event.graphql';

const Container = styled.div`
  height: 100%;
`;

export const StyledRichText = styled(RichText)`
  max-width: 570px;
  margin: 5px 0px 20px;
  color: ${props => props.theme.darkestTextColor};
  font-size: 14px;
`;

const StyledShareDropdown = styled(ShareDropdown)`
  position: sticky;
  bottom: 96%;
  display: flex;
  justify-content: flex-end;
  border-left: 0px;
  margin-left: 350px;
  padding-right: 27px;
  z-index: 1;
  ${StyledOverlay} {
    margin-right: 27px;
  }
  ${IconContainer} {
    align-self: flex-end;
  }
`;

type User = {
  id: string,
  firstName: string,
  lastName: string,
};

type Note = {
  id: string,
  name: string,
  content: string,
  creator: User,
  includedInBrief: boolean,
  viewerCanDelete: boolean,
  viewerCanUpdate: boolean,
  attachments: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +fileurl: string,
        +filename: string,
        +filetype: string,
        +filesize: number,
      |},
    |}>,
  |},
};

class NoteEdit extends React.Component<
  {
    note: Note,
    event: NoteEdit_event,
    onCreateNewNote: (newNoteId: string) => void,
  },
  {
    showRemoveModal: boolean,
    name: string,
    description: string,
  },
> {
  state = {
    showRemoveModal: false,
    name: this.props.note.name,
    description: this.props.note.content,
  };

  handleUpdate = (name: string, description: string) => {
    if (this.state.name !== name || this.state.description !== description) {
      this.setState({ name, description }, () =>
        updateEventNote(this.props.note.id, name, description).catch(showModernMutationError),
      );
    }
  };

  handleRemove = () => {
    removeEventNote(this.props.event.id, this.props.note.id).catch(showModernMutationError);
  };

  handleImageAdd = (file: CircaFile) => {
    addEventNoteAttachment(this.props.note.id, file).catch(showModernMutationError);
  };

  handleRemoveImages = (removableAttachments: $ReadOnlyArray<CircaFile>) => {
    removableAttachments.forEach(ra => {
      const attachment = this.props.note.attachments.edges.find(
        ({ node }) => ra.fileurl === node.fileurl,
      );
      if (attachment != null) {
        removeAttachment(this.props.event.id, attachment.node.id, [this.props.note.id]).catch(
          showModernMutationError,
        );
      }
    });
  };

  handleShowRemoveModal = () => {
    this.setState({ showRemoveModal: true });
  };

  handleHideRemoveModal = () => {
    this.setState({ showRemoveModal: false });
  };

  handleAddToBrief = () => {
    const {
      event: { id: eventId },
      note: { id },
    } = this.props;
    addNoteToBrief(id, eventId).catch(showModernMutationError);
  };

  handleRemoveFromBrief = () => {
    const {
      event: { id: eventId },
      note: { id },
    } = this.props;
    removeNoteFromBrief(id, eventId).catch(showModernMutationError);
  };

  handleCopy = () => {
    const {
      event: { id: eventId },
      note: { name, content, attachments: attachmentEdges },
    } = this.props;
    const attachments = attachmentEdges.edges.map(({ node }) => ({
      ...node,
      filesize: +node.filesize,
    }));
    createEventNote(eventId, `${name} Copy`, content, attachments)
      .then(({ id: eventNoteId }) => {
        this.props.onCreateNewNote(eventNoteId);
      })
      .catch(showModernMutationError);
  };

  render() {
    const {
      event,
      event: { viewerCanCreateNote, tz },
      note,
      note: { name, includedInBrief, viewerCanDelete, viewerCanUpdate, content, attachments },
    } = this.props;
    const formAttachments = attachments.edges.map(({ node }) => ({
      ...node,
      id: node.fileurl,
    }));
    const shareOptions = [
      viewerCanCreateNote
        ? {
            icon: 'copy',
            onClick: this.handleCopy,
            label: 'Copy Note',
          }
        : null,
      viewerCanDelete
        ? {
            icon: 'trash',
            onClick: this.handleShowRemoveModal,
            label: 'Delete Note',
          }
        : null,
    ].filter(Boolean);
    if (event.viewerCanUpdate) {
      shareOptions.push(
        includedInBrief
          ? {
              icon: 'times',
              onClick: this.handleRemoveFromBrief,
              label: 'Remove from Brief',
            }
          : {
              icon: 'plus',
              onClick: this.handleAddToBrief,
              label: 'Add to Brief',
            },
      );
    }

    return (
      <Container>
        <NoteForm
          defaultName={name}
          defaultDescription={content}
          onSave={this.handleUpdate}
          attachments={formAttachments}
          onImageAdd={this.handleImageAdd}
          onRemoveImages={this.handleRemoveImages}
          canUpdate={viewerCanUpdate}
          eventNote={note}
          tz={tz}
        />
        {this.state.showRemoveModal && (
          <ConfirmationWindow
            onHide={this.handleHideRemoveModal}
            onConfirm={this.handleRemove}
            title="Are you sure?"
            message="Once you delete a Note, it's gone for good."
          />
        )}
        {shareOptions.length > 0 && <StyledShareDropdown options={shareOptions} />}
      </Container>
    );
  }
}

export default createFragmentContainer(NoteEdit, {
  note: graphql`
    fragment NoteEdit_note on EventNote {
      id
      name
      content
      includedInBrief
      viewerCanDelete
      viewerCanUpdate
      attachments {
        edges {
          node {
            id
            fileurl
            filename
            filetype
            filesize
          }
        }
      }
      ...NoteForm_eventNote
    }
  `,
  event: graphql`
    fragment NoteEdit_event on Event {
      id
      viewerCanUpdate
      viewerCanCreateNote
      tz
    }
  `,
});
