/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import EditableContainer from '../form/EditableContainer';
import generateBlankQuestionValue from '../lib/generateBlankQuestionValue';
import mappingCustomQuestionValidators from '../lib/mappingCustomQuestionValidators';
import mappingQuestionValidators from '../lib/mappingQuestionValidators';
import { type QuestionType, type ReorderedQuestionType } from '../lib/types';
import Question from '../Question';
import EditableQuestion from './EditableQuestion';
import EditableQuestionDraggable from './EditableQuestionDraggable';

import type { EditableQuestionContainer_org } from './__generated__/EditableQuestionContainer_org.graphql';

const StyledQuestion = styled(Question)`
  padding: 0;
`;

class EditableQuestionContainer extends React.PureComponent<
  {
    org: EditableQuestionContainer_org,
    isSubmitted: boolean,
    question: QuestionType,
    onBeginDrag?: (question: QuestionType) => void,
    onChangeQuestion: (question: QuestionType) => void,
    onCheckDependencies: (question: QuestionType) => boolean,
    onRemoveQuestion: (id: string) => void,
    onChangeEditing: (questionId: string, editing: boolean) => void,
    onMoveQuestion: (sourceQuestion: QuestionType, targetQuestion: QuestionType) => void,
    onDropQuestion: (reorderedQuestion: ReorderedQuestionType) => void,
    onHasChanges: (hasChanges: boolean) => void,
    usedMappings: $ReadOnlyArray<string>,
    draggingQuestion: QuestionType,
    restrictedDruggingZone: boolean,
  },
  {
    question: QuestionType,
    errors: $ReadOnlyArray<string>,
    editing: boolean,
  },
> {
  static getDerivedStateFromProps(
    nextProps: $PropertyType<EditableQuestionContainer, 'props'>,
    prevState: $PropertyType<EditableQuestionContainer, 'state'>,
  ) {
    if (nextProps.question.order === prevState.question.order) return null;

    return {
      question: {
        ...prevState.question,
        order: nextProps.question.order,
      },
    };
  }

  state = {
    errors: [],
    editing: false,
    question: this.props.question,
  };

  validateQuestion = (question: QuestionType) => {
    const errors = [];

    if (!question.name.trim()) {
      errors.push('Question is required');
    }

    if (!question.mapping && !question.mappingCustomField && question.id.startsWith('question_')) {
      errors.push('Please map the question with an event field');
    }

    const questionValidator = question.mappingCustomField
      ? mappingCustomQuestionValidators[question.mappingCustomField.kind]
      : question.mapping && mappingQuestionValidators[question.mapping];

    const errorMessage = questionValidator && questionValidator(question);

    if (errorMessage) {
      errors.push(errorMessage);
    }

    return errors;
  };

  handleSave = () => {
    this.setState(state => ({ errors: this.validateQuestion(state.question) }));

    const errors = this.validateQuestion(this.state.question);

    if (errors.length === 0) {
      this.props.onChangeQuestion(this.state.question);
    }

    return errors.length === 0;
  };

  handleChange = (changes: $Exact<{ ...QuestionType }>) => {
    this.props.onHasChanges(true);

    this.setState(state => ({
      question: {
        ...state.question,
        ...changes,
      },
      errors:
        state.errors.length !== 0
          ? this.validateQuestion({
              ...state.question,
              ...changes,
            })
          : [],
    }));
  };

  handleChangeEditing = (editing: boolean) => {
    this.props.onChangeEditing(this.props.question.id, editing);

    this.setState({ editing });
  };

  checkPreview = (): boolean => {
    const { question, draggingQuestion } = this.props;
    if (draggingQuestion && draggingQuestion.id === question.id) {
      return this.validateQuestion(question).length === 0;
    }
    return !!this.props.question.name.trim();
  };

  render() {
    const usedMappings = this.props.usedMappings.filter(
      mapping =>
        mapping !==
        (this.props.question.mappingCustomField
          ? this.props.question.mappingCustomField.id
          : this.props.question.mapping),
    );

    return (
      <EditableQuestionDraggable
        editing={this.state.editing}
        question={this.state.question}
        onMove={this.props.onMoveQuestion}
        onBeginDrag={this.props.onBeginDrag}
        onMoveEnd={this.props.onDropQuestion}
        restrictedDruggingZone={this.props.restrictedDruggingZone}
        draggingQuestion={this.props.draggingQuestion}
      >
        <EditableContainer
          defaultPreviewing={this.checkPreview()}
          showIteration
          previewContent={
            <StyledQuestion
              question={this.props.question}
              currency={this.props.org.settings.currency}
              tz=""
              readOnly
              errors={{}}
              value={generateBlankQuestionValue(this.props.question.id)}
              onChangeValue={() => {}}
            />
          }
          editContent={
            <EditableQuestion
              isSubmitted={this.props.isSubmitted}
              org={this.props.org}
              question={this.state.question}
              onChangeQuestion={this.handleChange}
              onCheckDependencies={this.props.onCheckDependencies}
              onRemoveQuestion={this.props.onRemoveQuestion}
              usedMappings={usedMappings}
              errors={this.state.errors}
            />
          }
          onSave={this.handleSave}
          onChangeEditing={this.handleChangeEditing}
        />
      </EditableQuestionDraggable>
    );
  }
}

export default createFragmentContainer(
  EditableQuestionContainer,
  graphql`
    fragment EditableQuestionContainer_org on Org {
      ...EditableQuestion_org
      settings {
        currency
      }
    }
  `,
);
