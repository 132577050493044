/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import formatNumber from 'utils/number/formatNumber';

import TextField from '../../../components/TextField';

import { type EventInfoCustomNumber_event } from './__generated__/EventInfoCustomNumber_event.graphql';

const EventInfoCustomNumber = ({
  name,
  event,
  customFieldId,
}: {
  name: string,
  event: EventInfoCustomNumber_event,
  customFieldId: string,
}) => {
  const fieldValue = event.customNumberFields.find(value => value.customField.id === customFieldId);

  if (!fieldValue) return null;

  return (
    <TextField name={name} description={formatNumber(fieldValue.value, { fractionDigits: 0 })} />
  );
};

export default createFragmentContainer(
  EventInfoCustomNumber,
  graphql`
    fragment EventInfoCustomNumber_event on Event {
      customNumberFields(customFieldIds: $customFieldIds) {
        value
        customField {
          id
        }
      }
    }
  `,
);
