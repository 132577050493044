/* @flow */
import React from 'react';
import styled from 'styled-components';

import StepProgress from 'components/StepProgress';

const StepsContainer = styled.div`
  position: sticky;
  top: 10px;
  z-index: 5;
  flex-shrink: 0;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  margin: 25px 0;
  border-radius: 8px;
  box-shadow: 0 0 ${props => (props.showShadow ? 5 : 0)}px rgba(0, 0, 0, 0.12);
  background: ${props => (props.showShadow ? '#fff' : 'transparent')};
  transition: 0.2s;
`;

export default class EventRequestFormBuilderSteps extends React.PureComponent<{
  activeStepIndex: number,
  showShadow: boolean,
  onChangeStep: (stepIndex: number) => void,
}> {
  render() {
    return (
      <StepsContainer showShadow={this.props.showShadow}>
        <StepProgress
          steps={['Questionnaire', 'Logic', 'Settings', 'Preview']}
          current={this.props.activeStepIndex}
          onChange={this.props.onChangeStep}
        />
      </StepsContainer>
    );
  }
}
