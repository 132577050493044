/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefUserAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type NotesUpdater_user$ref: FragmentReference;
export type NotesUpdater_user = {|
  +firstName: string,
  +lastName: string,
  +$fragmentRefs: BriefUserAvatar_user$ref,
  +$refType: NotesUpdater_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "NotesUpdater_user",
  "type": "Assignable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "BriefUserAvatar_user",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '19edf1dd2c7e96d2186b6f7181be0346';
module.exports = node;
