import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

import RichText from 'components/RichText';

export default class Description extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  state = {
    editing: false,
    key: 1,
  };

  handleSave = value => {
    this.setState({ editing: false });

    const sanitizedValue = sanitizeHtml(value);
    if (value !== sanitizedValue) {
      this.setState(prevState => ({ key: prevState.key + 1 }));
    }

    if (sanitizedValue !== this.props.value) {
      this.props.onChange(sanitizedValue);
    }
  };

  handleEditStart = () => {
    if (!this.props.disabled) {
      this.setState({ editing: true });
    }
  };

  handleEditEnd = () => {
    this.setState({ editing: false });
  };

  render() {
    return (
      <div className="schedule-list-row-description">
        {this.state.editing || this.props.value ? (
          <RichText
            key={this.state.key}
            defaultValue={this.props.value}
            autoFocus
            onSave={this.handleSave}
            viewMode={!this.state.editing}
            onStartEditing={this.props.disabled ? null : this.handleEditStart}
            onCancel={this.handleEditEnd}
          />
        ) : (
          <div className="schedule-list-row-empty">
            <a onClick={this.handleEditStart}>
              <i className="fa fa-fw fa-file-text" /> Description
            </a>
          </div>
        )}
      </div>
    );
  }
}
