/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link } from 'react-router-dom';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import type { CompanyContactName_contact } from './__generated__/CompanyContactName_contact.graphql';

const TextWrapper = styled.div`
  margin-left: 5px;
`;

class CompanyContactName extends React.PureComponent<{
  eventSlug?: string,
  contact: CompanyContactName_contact,
  location: Location,
}> {
  preventDefault = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  contactPath = () => {
    return this.props.eventSlug != null
      ? `/events/${this.props.eventSlug}/contacts/people/${this.props.contact.slug}`
      : `/contacts/people/${this.props.contact.slug}/profile`;
  };

  render() {
    const { location, contact, eventSlug } = this.props;
    return (
      <Link
        to={{
          pathname: this.contactPath(),
          state: {
            prevPage: true,
            prevPageLabel: eventSlug == null ? 'Company contacts' : '',
            prevPath: location.pathname,
          },
        }}
        onMouseDown={this.preventDefault}
      >
        <TextWrapper>{fullNameOfUser(contact)}</TextWrapper>
      </Link>
    );
  }
}

export default createFragmentContainer(
  CompanyContactName,
  graphql`
    fragment CompanyContactName_contact on Contact {
      slug
      email
      firstName
      lastName
    }
  `,
);
