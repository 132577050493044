/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import AccountPreferencesForm from './AccountPreferencesForm';

import type { AccountPreferencesQueryResponse } from './__generated__/AccountPreferencesQuery.graphql';

const query = graphql`
  query AccountPreferencesQuery {
    me {
      ...AccountPreferencesForm_me
    }
  }
`;

export default class AccountPreferences extends React.Component<{}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(props: AccountPreferencesQueryResponse) => (
          <AccountPreferencesForm me={props.me} />
        )}
      />
    );
  }
}
