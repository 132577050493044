/* @flow */

import React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import capitalize from 'lodash/capitalize';

import { getFontFamily, loadFonts } from 'utils/fonts';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';
import NotAuthorized from 'components/NotAuthorized';

import BriefMenu from './BriefMenu';
import BriefDownload from './BriefMenu/BriefDownload';
import BriefViewContainer from './BriefViewContainer';
import BriefViewSharedContext from './BriefViewSharedContext';
import ScrollTopButton from './components/ScrollTopButton';

import { type BriefView_event } from './__generated__/BriefView_event.graphql';
import { type BriefViewQueryResponse } from './__generated__/BriefViewQuery.graphql';

const BriefDownloadContainer = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const BriefViewWrapper = styled.div`
  flex: 1 1 auto;
  overflow: scroll;
  font-family: ${props => props.font};
  -webkit-overflow-scrolling: touch;
  ${props =>
    !props.editing &&
    css`
      height: 100vh;
    `}
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
`;

const query = graphql`
  query BriefViewQuery(
    $eventSlug: String!
    $listToken: String
    $includeDates: Boolean!
    $includeLocation: Boolean!
    $includeWebsite: Boolean!
    $includeEventInfo: Boolean!
    $includeEventStatus: Boolean!
    $includeEventProgress: Boolean!
    $includeEventDescription: Boolean!
    $includeEventFloorPlan: Boolean!
    $includeEventLeader: Boolean!
    $includeEventDimensions: Boolean!
    $includeEventBoothNumber: Boolean!
    $includeEventLastSynced: Boolean!
    $includeEventSalesforceId: Boolean!
    $includeEventMarketoId: Boolean!
    $includeEventAmountAllOpportunities: Boolean!
    $includeEventNumberOfOpportunities: Boolean!
    $includeEventSyncStatus: Boolean!
    $includeEventPlannedCost: Boolean!
    $includeEventActualCost: Boolean!
    $includeEventStaff: Boolean!
    $includeStaffAvatar: Boolean!
    $includeStaffCompany: Boolean!
    $includeStaffEmail: Boolean!
    $includeStaffOnsite: Boolean!
    $includeStaffName: Boolean!
    $includeStaffPhone: Boolean!
    $includeStaffOfficePhone: Boolean!
    $includeStaffTitle: Boolean!
    $includeSchedules: Boolean!
    $personalizeSchedules: Boolean!
    $includeScheduleDescription: Boolean!
    $includeScheduleLocation: Boolean!
    $includeScheduleParticipants: Boolean!
    $includeTasks: Boolean!
    $includeTaskAssignees: Boolean!
    $includeTaskDueDate: Boolean!
    $includeTaskLink: Boolean!
    $includeTaskStatus: Boolean!
    $includeTaskSubtasks: Boolean!
    $includeTaskTitle: Boolean!
    $customFieldIds: [ID!]!
  ) {
    event(slug: $eventSlug, listToken: $listToken) {
      ...BriefViewContainer_event
      ...BriefDownload_event
      ...BriefMenu_event
      ...ScrollTopButton_event
      name
      viewerCanUpdate
    }
    org {
      ...BriefViewContainer_org
    }
    publicMe {
      email
      ...BriefViewContainer_publicMe
    }
  }
`;

const BriefView = (props: {
  isSharedBrief?: boolean,
  event: BriefView_event,
  noteIds?: $ReadOnlyArray<string>,
  contactIds?: $ReadOnlyArray<string>,
  companyIds?: $ReadOnlyArray<string>,
  vendorIds?: $ReadOnlyArray<string>,
  attachmentIds?: $ReadOnlyArray<string>,
  listToken?: string,
  editing?: boolean,
}) => {
  const {
    isSharedBrief = false,
    listToken,
    noteIds,
    contactIds,
    companyIds,
    vendorIds,
    attachmentIds,
    editing,
    event: {
      slug,
      team: { font },
      briefSettings: {
        briefTemplate,
        briefTemplate: {
          headerSettings,
          sections,
          eventStaffFields,
          taskFields,
          scheduleFields,
          personalizeSchedules,
        },
      },
    },
  } = props;
  const eventInfoSections = briefTemplate.eventInfoSections.edges.map(sectionEdge => {
    const { fields, ...restFields } = sectionEdge.node;
    return {
      ...restFields,
      fields: fields.edges.map(fieldEdge => fieldEdge.node),
    };
  });

  if (font) {
    loadFonts([font], { loadBold: true });
  }

  const getInclusionName = (fieldName, sectionName) => {
    const nameSplitted = fieldName.split('_');
    return `include${sectionName}${nameSplitted.map(ns => capitalize(ns)).join('')}`;
  };

  const getSectionsInclusionVariables = (settings, sectionName = '') => {
    const sectionsInclusion = {};
    settings.forEach(section => {
      if (section.fields) {
        section.fields.forEach(field => {
          if (field.fieldName) {
            sectionsInclusion[getInclusionName(field.fieldName, sectionName)] =
              section.enabled && field.enabled;
          }
        });
        return;
      }
      sectionsInclusion[getInclusionName(section.name, sectionName)] = section.enabled;
    });
    return sectionsInclusion;
  };

  const variables = {
    eventSlug: slug,
    includeTest: true,
    isSharedBrief,
    contactFilters: { salesforceSyncOptions: ['lead'] },
    customFieldIds: eventInfoSections.reduce(
      (array, section) => [
        ...array,
        ...section.fields
          .map(
            field => section.enabled && field.enabled && field.customField && field.customField.id,
          )
          .filter(Boolean),
      ],
      [],
    ),
    personalizeSchedules,
    includeStaffOnsite: true,
    ...headerSettings,
    ...getSectionsInclusionVariables(sections),
    ...getSectionsInclusionVariables(eventInfoSections, 'Event'),
    ...getSectionsInclusionVariables(eventStaffFields, 'Staff'),
    ...getSectionsInclusionVariables(taskFields, 'Task'),
    ...getSectionsInclusionVariables(scheduleFields, 'Schedule'),
  };
  const finalVariables = listToken ? { ...variables, listToken } : variables;
  return (
    <DefaultQueryRenderer
      query={query}
      public={isSharedBrief}
      variables={finalVariables}
      renderSuccess={(dataProps: BriefViewQueryResponse) => {
        const userEmail: ?string = dataProps.publicMe && dataProps.publicMe.email;
        return dataProps.event ? (
          <DocumentTitle title={dataProps.event ? dataProps.event.name : 'Circa'}>
            <BriefViewSharedContext.Provider value={{ sharedView: isSharedBrief }}>
              <BriefViewWrapper editing={editing} id="briefPage" font={getFontFamily(font)}>
                {isSharedBrief && (
                  <BriefDownloadContainer>
                    <BriefDownload
                      listToken={listToken}
                      event={dataProps.event}
                      userEmail={userEmail}
                    />
                  </BriefDownloadContainer>
                )}
                <BriefMenu
                  listToken={listToken}
                  event={dataProps.event}
                  userEmail={userEmail}
                  noteIds={noteIds}
                  contactIds={contactIds}
                  companyIds={companyIds}
                  vendorIds={vendorIds}
                  attachmentIds={attachmentIds}
                />
                <BriefViewContainer
                  org={dataProps.org}
                  event={dataProps.event}
                  publicMe={dataProps.publicMe}
                  attachmentIds={attachmentIds}
                  contactIds={contactIds}
                  companyIds={companyIds}
                  vendorIds={vendorIds}
                  noteIds={noteIds}
                  sharedView={isSharedBrief}
                  listToken={listToken}
                />
                <ScrollTopButton event={dataProps.event} isHidden={!isSharedBrief} />
              </BriefViewWrapper>
            </BriefViewSharedContext.Provider>
          </DocumentTitle>
        ) : (
          <NotAuthorized />
        );
      }}
      renderLoading={() => (
        <LoaderContainer>
          <Loader size={30} />
        </LoaderContainer>
      )}
    />
  );
};

export default createFragmentContainer(
  BriefView,
  graphql`
    fragment BriefView_event on Event {
      id
      slug
      team {
        font
      }
      briefSettings {
        accessibility
        briefTemplate {
          headerSettings {
            includeDates
            includeWebsite
            includeLocation
          }
          sections {
            name
            enabled
          }
          eventInfoSections {
            edges {
              node {
                order
                enabled
                sectionName
                fields {
                  edges {
                    node {
                      order
                      enabled
                      fieldName
                      customField {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
          eventStaffFields {
            name
            enabled
          }
          taskFields {
            name
            enabled
          }
          scheduleFields {
            name
            enabled
          }
          personalizeSchedules
        }
      }
    }
  `,
);
