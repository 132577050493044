/**
 * @flow
 * @relayHash 9c4f4b61a16ff6f6a6157a6867a916b6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ScheduleDay_scheduleDay$ref = any;
export type AddDayToScheduleInput = {
  clientMutationId?: ?string,
  scheduleId: string,
  date: string,
  title?: ?string,
};
export type createScheduleDayMutationVariables = {|
  input: AddDayToScheduleInput
|};
export type createScheduleDayMutationResponse = {|
  +addDayToSchedule: ?{|
    +scheduleDayEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +date: any,
        +title: ?string,
        +$fragmentRefs: ScheduleDay_scheduleDay$ref,
      |},
    |}
  |}
|};
export type createScheduleDayMutation = {|
  variables: createScheduleDayMutationVariables,
  response: createScheduleDayMutationResponse,
|};
*/


/*
mutation createScheduleDayMutation(
  $input: AddDayToScheduleInput!
) {
  addDayToSchedule(input: $input) {
    scheduleDayEdge {
      __typename
      node {
        id
        date
        title
        ...ScheduleDay_scheduleDay
      }
    }
  }
}

fragment ScheduleDay_scheduleDay on ScheduleDay {
  id
  date
  title
  ...ScheduleDayHeader_scheduleDay
  ...ScheduleList_scheduleDay
  scheduleItems {
    edges {
      node {
        id
      }
    }
  }
  schedule {
    id
    dbId
    name
    viewerCanUpdate
  }
}

fragment ScheduleDayHeader_scheduleDay on ScheduleDay {
  title
  date
  viewerCanUpdate
  viewerCanRemove
  schedule {
    event {
      tz
      id
    }
    id
  }
}

fragment ScheduleList_scheduleDay on ScheduleDay {
  date
  viewerCanUpdate
  scheduleItems {
    edges {
      node {
        id
        start_time
        end_time
        all_day
        scheduleParticipants {
          edges {
            node {
              user {
                slug
                id
              }
              id
            }
          }
        }
        ...ScheduleListRow_scheduleItem
      }
    }
  }
}

fragment ScheduleListRow_scheduleItem on ScheduleItem {
  id
  title
  all_day
  start_time
  end_time
  description
  location
  viewerCanUpdate
  viewerCanRemove
  scheduleParticipants {
    edges {
      node {
        user {
          id
          firstName
          lastName
          email
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddDayToScheduleInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddDayToScheduleInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "date",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanRemove",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createScheduleDayMutation",
  "id": null,
  "text": "mutation createScheduleDayMutation(\n  $input: AddDayToScheduleInput!\n) {\n  addDayToSchedule(input: $input) {\n    scheduleDayEdge {\n      __typename\n      node {\n        id\n        date\n        title\n        ...ScheduleDay_scheduleDay\n      }\n    }\n  }\n}\n\nfragment ScheduleDay_scheduleDay on ScheduleDay {\n  id\n  date\n  title\n  ...ScheduleDayHeader_scheduleDay\n  ...ScheduleList_scheduleDay\n  scheduleItems {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  schedule {\n    id\n    dbId\n    name\n    viewerCanUpdate\n  }\n}\n\nfragment ScheduleDayHeader_scheduleDay on ScheduleDay {\n  title\n  date\n  viewerCanUpdate\n  viewerCanRemove\n  schedule {\n    event {\n      tz\n      id\n    }\n    id\n  }\n}\n\nfragment ScheduleList_scheduleDay on ScheduleDay {\n  date\n  viewerCanUpdate\n  scheduleItems {\n    edges {\n      node {\n        id\n        start_time\n        end_time\n        all_day\n        scheduleParticipants {\n          edges {\n            node {\n              user {\n                slug\n                id\n              }\n              id\n            }\n          }\n        }\n        ...ScheduleListRow_scheduleItem\n      }\n    }\n  }\n}\n\nfragment ScheduleListRow_scheduleItem on ScheduleItem {\n  id\n  title\n  all_day\n  start_time\n  end_time\n  description\n  location\n  viewerCanUpdate\n  viewerCanRemove\n  scheduleParticipants {\n    edges {\n      node {\n        user {\n          id\n          firstName\n          lastName\n          email\n        }\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createScheduleDayMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDayToSchedule",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddDayToSchedulePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scheduleDayEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ScheduleDayRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ScheduleDay",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "FragmentSpread",
                    "name": "ScheduleDay_scheduleDay",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createScheduleDayMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDayToSchedule",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddDayToSchedulePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scheduleDayEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ScheduleDayRequiredEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ScheduleDay",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "schedule",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Schedule",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "event",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Event",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "tz",
                            "args": null,
                            "storageKey": null
                          },
                          v3
                        ]
                      },
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dbId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      v6
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "scheduleItems",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ScheduleItemRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ScheduleItemRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ScheduleItem",
                            "plural": false,
                            "selections": [
                              v3,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "start_time",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "end_time",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "all_day",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "scheduleParticipants",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ScheduleParticipantRequiredConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ScheduleParticipantRequiredEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "ScheduleParticipant",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "user",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "slug",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              v3,
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "firstName",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "lastName",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "email",
                                                "args": null,
                                                "storageKey": null
                                              }
                                            ]
                                          },
                                          v3
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              v5,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "description",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "location",
                                "args": null,
                                "storageKey": null
                              },
                              v6,
                              v7
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd12f6cc699ab76593118ae53be7eb6c9';
module.exports = node;
