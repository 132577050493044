/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import getUserPossessiveName from 'utils/getUserPossessiveName';

import { type Field } from 'views/Main/Event/Brief/BriefView/components/types';

import BriefHeader from './BriefHeader';
import BriefSortableSections from './BriefSortableSections';
import AttachmentsBriefRoot from './Sections/AttachmentsBriefRoot';
import EventContactsBriefRoot from './Sections/EventContactsBriefRoot';
import EventVendorsBriefRoot from './Sections/EventVendorsBriefRoot';
import NotesBriefRoot from './Sections/NotesBriefRoot';

import { type BriefViewContainer_event } from './__generated__/BriefViewContainer_event.graphql';
import { type BriefViewContainer_org } from './__generated__/BriefViewContainer_org.graphql';
import { type BriefViewContainer_publicMe } from './__generated__/BriefViewContainer_publicMe.graphql';

const Container = styled.div`
  flex: 1 1 auto;
  padding-bottom: 45px;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
`;

const ContentWrapper = styled.div`
  margin: 0 ${props => (props.isShared ? '10px' : '30px')};
`;

const Content = styled.div`
  padding: 30px 30px 0;
  background: #fff;
  border-radius: 4px;
  ${props =>
    props.isShared
      ? css`
          max-width: 950px;
          margin: 0 auto;
          @media (max-width: 960px) {
            padding: 40px 5px 0;
          }
        `
      : css`
          position: relative;
          left: 50%;
          width: 950px;
          margin-left: -475px;
          @media (max-width: 1260px) {
            left: 0;
            margin-left: 0;
          }
        `};
`;

const SectionsContainer = styled.div`
  padding-top: 50px;
  @media (max-width: 480px) {
    padding-top: 35px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 10px;
`;

class BriefViewContainer extends React.Component<{
  org: BriefViewContainer_org,
  event: BriefViewContainer_event,
  publicMe: BriefViewContainer_publicMe,
  noteIds?: $ReadOnlyArray<string>,
  contactIds?: $ReadOnlyArray<string>,
  companyIds?: $ReadOnlyArray<string>,
  vendorIds?: $ReadOnlyArray<string>,
  attachmentIds?: $ReadOnlyArray<string>,
  sharedView: boolean,
  listToken: ?string,
}> {
  render() {
    const {
      listToken,
      sharedView,
      noteIds = [],
      contactIds = [],
      companyIds = [],
      vendorIds = [],
      attachmentIds = [],
      org,
      event,
      event: {
        tz,
        slug,
        team: { primaryColor },
        staffers,
        briefSettings: { notes, contacts, companies, vendors, attachments },
      },
      publicMe,
    } = this.props;

    const showNotes = (notes.edges.length > 0 && sharedView) || noteIds.length > 0;
    const showContacts =
      (contacts.edges.length + companies.edges.length > 0 && sharedView) ||
      contactIds.length + companyIds.length > 0;
    const showVendors = (vendors.edges.length > 0 && sharedView) || vendorIds.length > 0;
    const showAttachments =
      (attachments.edges.length > 0 && sharedView) || attachmentIds.length > 0;

    const { firstName = '', lastName = '', id = '' } = publicMe || {};
    const possessiveUserName = staffers.edges.find(e => e.node.id === id)
      ? getUserPossessiveName(firstName, lastName)
      : '';

    const customFields = org.customFields.edges.map(edge => edge.node);
    // $FlowFixMe
    const contactFields: $ReadOnlyArray<Field<'CONTACT' | 'COMPANY'>> = customFields.filter(item =>
      ['CONTACT', 'COMPANY'].includes(item.customizableType),
    );
    // $FlowFixMe
    const vendorFields: $ReadOnlyArray<Field<'VENDOR'>> = customFields.filter(
      item => item.customizableType === 'VENDOR',
    );

    return (
      <Container>
        <ContentWrapper isShared={sharedView}>
          <Content isShared={sharedView}>
            <BriefHeader event={event} possessiveUserName={possessiveUserName} />
            <SectionsContainer>
              <BriefSortableSections
                customFields={customFields}
                event={event}
                org={org}
                primaryColor={primaryColor}
                possessiveUserName={possessiveUserName}
              />
              {showNotes && (
                <SectionWrapper>
                  <NotesBriefRoot
                    tz={tz}
                    eventSlug={slug}
                    noteIds={noteIds}
                    listToken={listToken}
                    sharedView={sharedView}
                    primaryColor={primaryColor}
                  />
                </SectionWrapper>
              )}
              {showContacts && (
                <SectionWrapper>
                  <EventContactsBriefRoot
                    customFields={contactFields}
                    eventSlug={slug}
                    contactIds={contactIds}
                    companyIds={companyIds}
                    listToken={listToken}
                    sharedView={sharedView}
                    primaryColor={primaryColor}
                  />
                </SectionWrapper>
              )}
              {showVendors && (
                <SectionWrapper>
                  <EventVendorsBriefRoot
                    customFields={vendorFields}
                    eventSlug={slug}
                    vendorIds={vendorIds}
                    listToken={listToken}
                    sharedView={sharedView}
                    primaryColor={primaryColor}
                  />
                </SectionWrapper>
              )}
              {showAttachments && (
                <SectionWrapper>
                  <AttachmentsBriefRoot
                    eventSlug={slug}
                    attachmentIds={attachmentIds}
                    listToken={listToken}
                    sharedView={sharedView}
                    primaryColor={primaryColor}
                  />
                </SectionWrapper>
              )}
            </SectionsContainer>
          </Content>
        </ContentWrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(BriefViewContainer, {
  event: graphql`
    fragment BriefViewContainer_event on Event {
      id
      tz
      slug
      team {
        primaryColor
      }
      staffers {
        edges {
          node {
            id
          }
        }
      }
      briefSettings {
        accessibility
        briefTemplate {
          sections {
            name
            order
            enabled
          }
          ...EventInfoBrief_template
          ...EventStaffBrief_template
          ...TasksBrief_template
        }
        notes {
          edges {
            node {
              id
            }
          }
        }
        attachments {
          edges {
            node {
              id
            }
          }
        }
        contacts {
          edges {
            node {
              id
            }
          }
        }
        vendors {
          edges {
            node {
              id
            }
          }
        }
        companies {
          edges {
            node {
              id
            }
          }
        }
      }
      ...BriefHeader_event
      ... @include(if: $includeEventInfo) {
        ...EventInfoBrief_event
      }
      ... @include(if: $includeEventStaff) {
        ...EventStaffBrief_event
      }
      ... @include(if: $includeSchedules) {
        ...ScheduleBrief_event
      }
      ... @include(if: $includeTasks) {
        ...TasksBrief_event
      }
    }
  `,
  org: graphql`
    fragment BriefViewContainer_org on Org {
      ...EventInfoBrief_org
      customFields(customizableType: [CONTACT, COMPANY, VENDOR]) {
        edges {
          node {
            customizableType
            fieldName
          }
        }
      }
    }
  `,
  publicMe: graphql`
    fragment BriefViewContainer_publicMe on User {
      id
      firstName
      lastName
    }
  `,
});
