/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { StaffMemberEmail_staffer } from './__generated__/StaffMemberEmail_staffer.graphql';

const EmailLink = styled.a`
  color: ${props => props.theme.primaryActionColor};
`;

class StaffMemberEmail extends React.PureComponent<{
  staffer: StaffMemberEmail_staffer,
}> {
  stopPropagation = (e: SyntheticMouseEvent<>) => {
    e.stopPropagation();
  };

  render() {
    const email = this.props.staffer.user.email;
    if (!email) {
      return null;
    }

    return (
      <EmailLink
        href={`mailto:${email}`}
        target="_blank"
        onClick={this.stopPropagation}
        rel="noopener noreferrer"
      >
        {email}
      </EmailLink>
    );
  }
}

export default createFragmentContainer(
  StaffMemberEmail,
  graphql`
    fragment StaffMemberEmail_staffer on StaffMembership {
      user {
        email
      }
    }
  `,
);
