/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RequestSubmissionStatus_requestSubmission$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventRequestsItemStatus_eventRequestSubmission$ref: FragmentReference;
export type EventRequestsItemStatus_eventRequestSubmission = {|
  +$fragmentRefs: RequestSubmissionStatus_requestSubmission$ref,
  +$refType: EventRequestsItemStatus_eventRequestSubmission$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventRequestsItemStatus_eventRequestSubmission",
  "type": "EventRequestSubmission",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "RequestSubmissionStatus_requestSubmission",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6898caed28fcd31fbda60432199b2443';
module.exports = node;
