import PropTypes from 'prop-types';
import React from 'react';

import { SingleCalendarPanel } from 'components/panels/SingleCalendarPanel';

export default class ScheduleDayAdd extends React.Component {
  static propTypes = {
    time_zone: PropTypes.string.isRequired,
    usedDays: PropTypes.array,
    onCreate: PropTypes.func.isRequired,
  };

  state = {
    date: null,
  };

  handleSave = ({ date }) => {
    this.props.onCreate(date.toISOString());
  };

  handleChange = ({ date }) => this.setState({ date });

  handleDayAddClick = () => this.panel.show();

  render() {
    const { time_zone, usedDays } = this.props;
    const { date } = this.state;

    return (
      <div className="schedule-day-add">
        <div className="schedule-list-add day" onClick={this.handleDayAddClick}>
          <span className="schedule-list-add-icon">+</span>
          <a>ADD DAY</a>
        </div>
        <SingleCalendarPanel
          ref={panel => {
            this.panel = panel;
          }}
          hiddenCheckbox
          hideHeader
          all_day
          tz={time_zone}
          date={date}
          usedDays={usedDays}
          onChange={this.handleChange}
          onSave={this.handleSave}
          modalOnMobile
        />
      </div>
    );
  }
}
