/* @flow */
import React from 'react';
import styled from 'styled-components';

import sanitizeHtml from 'utils/string/sanitizeHtml';

import TextField from 'components/material/TextField';

const StyledTextField = styled(TextField)`
  margin: 15px 0 0 0;
  background-color: #fff;
  line-height: 0;
  textarea {
    min-height: 100px;
    max-height: 200px;
    padding: 5px 10px;
    border: 1px solid ${props => props.theme.borderColor};
    border-color: ${props =>
      props.error ? props.theme.negativeActionColor : props.theme.borderColor};
    &:hover {
      border: 1px solid ${props => props.theme.primaryActionColor};
      border-color: ${props =>
        props.error ? props.theme.negativeActionColor : props.theme.borderColor};
    }
    &:focus {
      outline: 0;
      border: 1px solid ${props => props.theme.primaryActionColor};
      border-color: ${props =>
        props.error ? props.theme.negativeActionColor : props.theme.borderColor};
    }
  }
`;

export default class EmbedCode extends React.PureComponent<
  {
    value: string,
    onChange: (code: string) => void,
  },
  {
    code: string,
    error: ?string,
  },
> {
  state = {
    code: this.props.value != null ? this.props.value : '',
    error: null,
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const code = e.currentTarget.value.trim();
    if (this.state.code !== code) {
      this.setState({
        code,
      });
    }
  };

  handleBlur = () => {
    const code = this.state.code;
    const cleanCode = sanitizeHtml(code, {
      extendedTags: ['img', 'video', 'audio', 'source', 'iframe'],
      extendedAttrs: ['title', 'autoplay', 'controls', 'frameborder', 'allow', 'allowfullscreen'],
      disallowedTagsMode: 'discard',
    });
    if (cleanCode !== null) {
      this.props.onChange(cleanCode);
      if (this.state.error) {
        this.setState({
          error: null,
        });
      }
      return;
    }
    this.setState({ error: 'Embed Code is not valid' });
  };

  render() {
    return (
      <StyledTextField
        value={this.state.code}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={this.state.error}
        autoFocus
        multiline
      />
    );
  }
}
