/**
 * @flow
 * @relayHash 8e049e13a61018ceabe7a6ade7139280
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CompanySort = "CITY" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CUSTOM" | "DESCRIPTION" | "LINKEDIN" | "NAME" | "PHONE" | "SALESFORCE_ID" | "STATE" | "STREET" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "WEBSITE" | "ZIP";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type CompanyFilters = {
  query?: ?string,
  companyIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactsCount?: ?NumberRangeInput,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?CompanySort,
  direction?: ?Direction,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type EventCompaniesHeaderBarQueryVariables = {|
  eventSlug: string,
  filters: CompanyFilters,
  columns?: ?$ReadOnlyArray<string>,
|};
export type EventCompaniesHeaderBarQueryResponse = {|
  +event: ?{|
    +companyReportCSVProcessId: string
  |},
  +me: {|
    +email: string
  |},
|};
export type EventCompaniesHeaderBarQuery = {|
  variables: EventCompaniesHeaderBarQueryVariables,
  response: EventCompaniesHeaderBarQueryResponse,
|};
*/


/*
query EventCompaniesHeaderBarQuery(
  $eventSlug: String!
  $filters: CompanyFilters!
  $columns: [String!]
) {
  event(slug: $eventSlug) {
    companyReportCSVProcessId(filters: $filters, columns: $columns)
    id
  }
  me {
    email
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "CompanyFilters!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "columns",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "companyReportCSVProcessId",
  "args": [
    {
      "kind": "Variable",
      "name": "columns",
      "variableName": "columns",
      "type": "[String!]"
    },
    {
      "kind": "Variable",
      "name": "filters",
      "variableName": "filters",
      "type": "CompanyFilters!"
    }
  ],
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventCompaniesHeaderBarQuery",
  "id": null,
  "text": "query EventCompaniesHeaderBarQuery(\n  $eventSlug: String!\n  $filters: CompanyFilters!\n  $columns: [String!]\n) {\n  event(slug: $eventSlug) {\n    companyReportCSVProcessId(filters: $filters, columns: $columns)\n    id\n  }\n  me {\n    email\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventCompaniesHeaderBarQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EventCompaniesHeaderBarQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          v4
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v3,
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '11c136e19050176c2af70729f655b7b6';
module.exports = node;
