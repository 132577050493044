/* @flow */
import React from 'react';

import FilterItemHeader from '../FilterItemHeader';
import CheckFilterBody, { type Option } from './CheckFilterBody';

export default class CheckFilterItem<Name, Value> extends React.Component<{
  options: $ReadOnlyArray<Option<Value>>,
  activeValues: ?$ReadOnlyArray<Value>,
  label: string,
  name: Name,
  onChange: (Name, ?$ReadOnlyArray<Value>) => void,
  expanded?: boolean,
  maxOptions?: number,
  unsorted?: boolean,
}> {
  handleFilterClear = () => {
    this.props.onChange(this.props.name, []);
  };

  render() {
    return (
      <FilterItemHeader
        label={this.props.label}
        count={this.props.activeValues ? this.props.activeValues.length : 0}
        onClear={this.handleFilterClear}
        expanded={this.props.expanded}
        disabled={this.props.options.length === 0}
      >
        <CheckFilterBody
          name={this.props.name}
          maxOptions={this.props.maxOptions}
          options={this.props.options}
          onChange={this.props.onChange}
          activeValues={this.props.activeValues}
          unsorted={this.props.unsorted}
        />
      </FilterItemHeader>
    );
  }
}
