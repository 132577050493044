/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  addCompanyFieldsMutationResponse,
  addCompanyFieldsMutationVariables,
} from './__generated__/addCompanyFieldsMutation.graphql';

const mutation = graphql`
  mutation addCompanyFieldsMutation($input: AddCompanyFieldsInput!) {
    addCompanyFields(input: $input) {
      contactForm {
        enableCompanyFields
      }
      contactFormFieldEdges {
        node {
          id
          label
          fieldName
          required
          order
          customField {
            id
          }
          contactFormFieldOptions {
            edges {
              node {
                customFieldOption {
                  id
                }
              }
            }
          }
          contactFormFieldUserOptions {
            edges {
              node {
                user {
                  id
                }
              }
            }
          }
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function addCompanyFields(
  input: $PropertyType<addCompanyFieldsMutationVariables, 'input'>,
): Promise<addCompanyFieldsMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input },
    updater: addRecordToConnection({
      parentId: input.contactFormId,
      edgeName: 'contactFormFieldEdges',
      connections: [
        {
          field: 'contactFormFields',
        },
      ],
    }),
  });
}
