/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import TaskComments from './TaskComments';

import type { TaskCommentsRootQueryResponse } from './__generated__/TaskCommentsRootQuery.graphql';

const query = graphql`
  query TaskCommentsRootQuery($taskSlug: String!, $count: Int!, $cursor: String) {
    task: deliverable(slug: $taskSlug) {
      ...TaskComments_task
    }
  }
`;

export default class TaskCommentsRoot extends React.PureComponent<{ taskSlug: string }> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ taskSlug: this.props.taskSlug, count: 7 }}
        renderSuccess={(response: TaskCommentsRootQueryResponse) =>
          response.task && <TaskComments task={response.task} />
        }
      />
    );
  }
}
