/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import Arrow from 'images/arrow.svg';

import UIContext from '../../../UIContext';

type DesignSectionType = 'components' | 'styling';

const StyledArrow = styled(Arrow)`
  ${props =>
    props.open &&
    css`
      transform: rotate(90deg);
    `}
`;

const Header = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 20px 29px 20px 25px;
  cursor: pointer;
  border-bottom: 1px solid #d2d9f5;
`;

const Label = styled.div`
  color: #383e56;
  font-family: Roboto-Slab;
  font-weight: 500;
  line-height: 1em;
`;

const Content = styled.div`
  display: none;
  flex: 1 0 auto;
  flex-direction: column;
  background-color: ${props => props.background};
  border-bottom: 1px solid #d2d9f5;
  ${props =>
    props.fullHeight &&
    css`
      flex: 1 0 auto;
    `}
  ${props =>
    props.open &&
    css`
      display: flex;
    `}
`;

export default class DesignSection extends React.PureComponent<
  {
    type: DesignSectionType,
    children: React.Node,
    forceOpen?: boolean,
  },
  {
    open: boolean,
    forceOpenValue: boolean,
  },
> {
  state = { open: true, forceOpenValue: !!this.props.forceOpen };

  static getDerivedStateFromProps(
    props: $PropertyType<DesignSection, 'props'>,
    state: $PropertyType<DesignSection, 'state'>,
  ) {
    if (state.forceOpenValue === props.forceOpen) {
      return null;
    }
    return { open: props.forceOpen, forceOpenValue: props.forceOpen };
  }

  handleToggle = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render() {
    const { type, children } = this.props;
    const open = this.state.open;
    return (
      <>
        <Header onClick={this.handleToggle}>
          <Label>PAGE {type.toUpperCase()}</Label>
          <StyledArrow fill="#3e4858" open={open} />
        </Header>
        <UIContext.Consumer>
          {({ leftPanelBackground }) => (
            <Content open={open} fullHeight={type === 'styling'} background={leftPanelBackground}>
              {children}
            </Content>
          )}
        </UIContext.Consumer>
      </>
    );
  }
}
