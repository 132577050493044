/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Match } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';

import UIContext from '../UIContext';
import RegistrationSettingsRefetchContainer from './RegistrationSettingsRefetchContainer';

import type { RegistrationSettingsQueryResponse } from './__generated__/RegistrationSettingsQuery.graphql';

const query = graphql`
  query RegistrationSettingsQuery($eventSlug: String, $isEventRegForm: Boolean!) {
    ...RegistrationSettingsRefetchContainer_query
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 10px 0;
`;

const RegistrationSettings = (props: { match: Match, discardCounter?: number }) => {
  const { match, discardCounter } = props;
  const eventSlug = match.params.eventSlug;
  return (
    <DefaultQueryRenderer
      query={query}
      variables={{ eventSlug, isEventRegForm: !!eventSlug }}
      renderSuccess={(response: RegistrationSettingsQueryResponse) => {
        return (
          <RegistrationSettingsRefetchContainer
            query={response}
            match={match}
            discardCounter={discardCounter}
          />
        );
      }}
      renderLoading={() => {
        return (
          <UIContext.Consumer>
            {({ loaderColor }) => (
              <LoaderContainer>
                <Loader color={loaderColor} size={30} />
              </LoaderContainer>
            )}
          </UIContext.Consumer>
        );
      }}
    />
  );
};

export default RegistrationSettings;
