/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import SectionContent from '../components/SectionContent';
import SectionHeader from '../components/SectionHeader';
import ScheduleItemContainer from './ScheduleItemContainer';
import ScheduleNavigationItem from './ScheduleNavigationItem';

import { type ScheduleBrief_event } from './__generated__/ScheduleBrief_event.graphql';

const NavBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

class ScheduleBrief extends React.Component<
  {
    event: ScheduleBrief_event,
    name: string,
    possessiveUserName: string,
    primaryColor: string,
  },
  { selectedScheduleId: string },
> {
  state = { selectedScheduleId: this.selectFirstSchedule() };

  getAvailableScheduleList() {
    const {
      event: { schedules },
    } = this.props;
    return sortBy(
      schedules.edges
        .map(({ node }) => node)
        .filter(schedule => schedule.scheduleDays.edges.length > 0),
      ['order'],
    );
  }

  handleScheduleChange = (scheduleId: string) => {
    if (this.state.selectedScheduleId !== scheduleId) {
      this.setState({ selectedScheduleId: scheduleId });
    }
  };

  getSectionName = (): string => {
    const {
      name,
      event: {
        briefSettings: {
          briefTemplate: { personalizeSchedules },
        },
      },
      possessiveUserName,
    } = this.props;
    return personalizeSchedules ? `${possessiveUserName} ${name}` : name;
  };

  selectFirstSchedule() {
    const scheduleList = this.getAvailableScheduleList();
    if (scheduleList.length === 0) {
      return '';
    }
    return scheduleList[0].id;
  }

  render() {
    const { primaryColor, event } = this.props;
    const { selectedScheduleId } = this.state;
    const scheduleList = this.getAvailableScheduleList();
    const isVisible =
      scheduleList.filter(
        schedule =>
          schedule.scheduleDays.totalCount > 0 &&
          schedule.scheduleDays.edges.find(({ node }) => node.scheduleItems.totalCount > 0),
      ).length > 0;

    const currentSchedule = selectedScheduleId
      ? scheduleList.find(s => s.id === selectedScheduleId)
      : null;
    return isVisible ? (
      <React.Fragment>
        <SectionHeader
          primaryColor={primaryColor}
          name={this.getSectionName()}
          anchor="schedules"
        />
        <SectionContent>
          <NavBar>
            {scheduleList.map(sc => (
              <ScheduleNavigationItem
                key={sc.id}
                schedule={sc}
                primaryColor={primaryColor}
                onScheduleClick={this.handleScheduleChange}
                isActive={sc.id === selectedScheduleId}
              />
            ))}
          </NavBar>
          {currentSchedule && <ScheduleItemContainer schedule={currentSchedule} event={event} />}
        </SectionContent>
      </React.Fragment>
    ) : null;
  }
}

export default createFragmentContainer(
  ScheduleBrief,
  graphql`
    fragment ScheduleBrief_event on Event {
      ...ScheduleItemContainer_event
      briefSettings {
        briefTemplate {
          personalizeSchedules
        }
      }
      schedules(isPersonalized: $personalizeSchedules) {
        edges {
          node {
            id
            order
            scheduleDays {
              totalCount
              edges {
                node {
                  scheduleItems(isPersonalized: $personalizeSchedules) {
                    totalCount
                  }
                }
              }
            }
            ...ScheduleItemContainer_schedule
            ...ScheduleNavigationItem_schedule
          }
        }
      }
    }
  `,
);
