/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import colsConfig from './columns';
import parseMembersFilters, { type ParsedMembersFilters } from './lib/parseMembersFilters';
import MembersHeader from './MembersHeader';
import MembersPagination from './MembersPagination';

import type { MembersPage_org } from './__generated__/MembersPage_org.graphql';
import type { MembersPageQueryResponse } from './__generated__/MembersPageQuery.graphql';

const query = graphql`
  query MembersPageQuery(
    $count: Int!
    $cursor: String
    $sort: UserSort!
    $direction: Direction!
    $query: String
  ) {
    org {
      ...MembersPagination_org
      ...MembersPagination_totalCountUsers
    }
    me {
      tz
      id
    }
  }
`;

const Container = styled.div`
  padding: 0 25px 24px;
`;

class MembersPage extends React.PureComponent<
  {
    org: MembersPage_org,
    history: RouterHistory,
    location: Location,
  },
  {
    availableMembers: $ReadOnlyArray<string>,
  },
> {
  state = {
    availableMembers: [],
  };

  handleMembersListUpdate = (availableMembers: $ReadOnlyArray<string>) => {
    this.setState({ availableMembers });
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  handleOrgMembersSearch = (search: string) => {
    replaceQueryParams(this.props.history, { search: search || null });
  };

  renderTable = (props: {
    org: ?$PropertyType<MembersPageQueryResponse, 'org'>,
    me: ?$PropertyType<MembersPageQueryResponse, 'me'>,
    sort: SortParam,
    filters: ParsedMembersFilters,
  }): React.Node => (
    <MembersPagination
      org={props.org}
      totalCountUsers={props.org}
      history={this.props.history}
      location={this.props.location}
      sort={props.sort}
      onChangeSort={this.handleSortChange}
      onMembersListUpdate={this.handleMembersListUpdate}
      shownColumns={colsConfig}
      tz={props.me ? props.me.tz : null}
      filters={props.filters}
    />
  );

  render() {
    const { location, history } = this.props;
    const filters = parseMembersFilters(location.search);

    const sortFilter = filters.sort || {
      key: 'FULL_NAME',
      asc: true,
    };

    const variables = {
      count: 25,
      sort: sortFilter.key,
      direction: sortFilter.asc ? 'ASC' : 'DESC',
      query: filters.search,
    };

    return (
      <Container>
        <MembersHeader
          org={this.props.org}
          history={history}
          location={location}
          orgMembersCount={this.state.availableMembers.length}
          onSearch={this.handleOrgMembersSearch}
          filters={filters}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={({ org, me }: MembersPageQueryResponse) =>
            this.renderTable({
              org,
              me,
              sort: sortFilter,
              filters,
            })
          }
          renderLoading={() =>
            this.renderTable({
              org: null,
              me: null,
              sort: sortFilter,
              filters,
            })
          }
        />
      </Container>
    );
  }
}

export default createFragmentContainer(MembersPage, {
  org: graphql`
    fragment MembersPage_org on Org {
      id
      ...MembersHeader_org
    }
  `,
});
