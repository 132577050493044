/* @flow */
import React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import createCustomField from 'graph/mutations/customFields/createCustomField';
import removeCustomField from 'graph/mutations/customFields/removeCustomField';
import reorderCustomFields from 'graph/mutations/customFields/reorderCustomFields';
import updateCustomField from 'graph/mutations/customFields/updateCustomField';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/budget/Button';

import CustomFieldForm from './CustomFieldForm';
import CustomizableField from './CustomizableField';
import DraggableField from './DraggableField';
import {
  // CreateFormColumn,
  // MobileFormColumn,
  ActionsColumn,
  FieldNameColumn,
  HeaderBar,
  RequiredColumn,
} from './fieldFragments';
import {
  type CustomizableType,
  type Field,
  type FieldInput,
  type FilterType,
  type Section,
  type SectionCustomizableType,
} from './types';

const StyledNameColumn = styled(FieldNameColumn)`
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
`;

export default class CustomizableFieldsList extends React.Component<
  {
    orgId: string,
    customizableType: CustomizableType,
    fields: $ReadOnlyArray<Field>,
    filters: FilterType,
    section?: Section,
    sectionCustomizableType?: SectionCustomizableType,
    draggingField?: ?Field,
    onMoveEnd?: () => void,
    onMoveField?: (sourceField: Field, targetField: Field) => void,
  },
  {
    adding: boolean,
    orderOverride: ?$ReadOnlyArray<Field>,
  },
> {
  state = {
    adding: false,
    orderOverride: null,
  };

  // This logic should be sent to CustomizableSection when sections ara added for contacts etc
  handleFieldCreate = (props: FieldInput): Promise<void> => {
    const { orgId, customizableType, filters, section } = this.props;
    return createCustomField(
      orgId,
      { ...props, customizableType, ...(section != null ? { sectionId: section.id } : null) },
      { customizableType: filters.customizableType, includeDisabled: filters.includeDisabled },
    )
      .then(() => {
        this.setState({ adding: false });
      })
      .catch(showModernMutationError);
  };

  handleFieldUpdate = (id: string, props: FieldInput): Promise<any> => {
    return updateCustomField(id, props).catch(showModernMutationError);
  };

  handleRemove = (id: string) => {
    const { orgId, filters } = this.props;
    removeCustomField(orgId, id, {
      customizableType: filters.customizableType,
      includeDisabled: filters.includeDisabled,
    }).catch(showModernMutationError);
  };

  handleAddClick = () => {
    this.setState({ adding: true });
  };

  handleCancelCreate = () => {
    this.setState({ adding: false });
  };

  getSortedCustomFields = (): Array<Field> => {
    return sortBy(
      this.state.orderOverride || this.props.fields.filter(field => !field.isDefault),
      'order',
    );
  };

  handleMoveField = (sourceField: Field, targetField: Field) => {
    if (this.props.onMoveField) {
      this.props.onMoveField(sourceField, targetField);
      return;
    }
    const direction = sourceField.order > targetField.order ? 0 : 1;
    const sortedFields = this.getSortedCustomFields();
    const source = sortedFields.find(field => field.order === sourceField.order);

    if (!source) {
      return;
    }

    const splitIndex = targetField.order + direction;
    const before = sortedFields
      .slice(0, splitIndex)
      .filter(field => field.order !== sourceField.order);
    const after = sortedFields.slice(splitIndex).filter(field => field.order !== sourceField.order);
    this.setState({
      orderOverride: [...before, source, ...after].map((field, index) => ({
        ...field,
        order: index,
      })),
    });
  };

  handleMoveEnd = () => {
    if (this.props.onMoveEnd) {
      this.props.onMoveEnd();
      return;
    }

    if (this.state.orderOverride) {
      reorderCustomFields(
        this.props.customizableType,
        this.state.orderOverride.map(({ id, order }) => ({ id, order })),
      ).catch(showModernMutationError);
    }
    this.setState({ orderOverride: null });
  };

  render() {
    const defaultFields = this.props.fields.filter(field => field.isDefault);
    return (
      <React.Fragment>
        {!['EVENT', 'SALESFORCEOPPORTUNITY'].includes(
          this.props.sectionCustomizableType || this.props.customizableType,
        ) && (
          <HeaderBar>
            <StyledNameColumn>Field Name</StyledNameColumn>
            <RequiredColumn>Required</RequiredColumn>
            {/* <CreateFormColumn>On Web</CreateFormColumn>
            <MobileFormColumn>On Mobile App</MobileFormColumn> */}
            <ActionsColumn />
          </HeaderBar>
        )}
        {defaultFields.map(field => (
          <CustomizableField
            field={field}
            key={field.id}
            sectionCustomizableType={this.props.sectionCustomizableType}
            customizableType={this.props.customizableType}
            onUpdate={this.handleFieldUpdate}
            onRemove={this.handleRemove}
          />
        ))}
        {this.getSortedCustomFields().map(field => (
          <DraggableField
            field={field}
            onMove={this.handleMoveField}
            onMoveEnd={this.handleMoveEnd}
            draggingField={this.props.draggingField}
            key={field.id}
          >
            <CustomizableField
              field={field}
              key={field.id}
              onUpdate={this.handleFieldUpdate}
              onRemove={this.handleRemove}
              sectionCustomizableType={this.props.sectionCustomizableType}
              customizableType={this.props.customizableType}
            />
          </DraggableField>
        ))}
        {this.state.adding ? (
          <CustomFieldForm
            adding
            onSave={this.handleFieldCreate}
            onCancel={this.handleCancelCreate}
            sectionCustomizableType={this.props.sectionCustomizableType}
            customizableType={this.props.customizableType}
          />
        ) : (
          !this.props.section &&
          this.props.customizableType !== 'EVENTCONTACT' && (
            <StyledButton onClick={this.handleAddClick}>Add New Field</StyledButton>
          )
        )}
      </React.Fragment>
    );
  }
}
