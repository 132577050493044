/**
 * @flow
 * @relayHash 10c668b83c1e1bf2a3d75c4b0017f004
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateItemInScheduleDayInput = {
  clientMutationId?: ?string,
  scheduleItemId: string,
  title?: ?string,
  description?: ?string,
  location?: ?string,
  startTime?: ?string,
  endTime?: ?string,
  allDay?: ?boolean,
};
export type updateScheduleItemMutationVariables = {|
  input: UpdateItemInScheduleDayInput
|};
export type updateScheduleItemMutationResponse = {|
  +updateItemInScheduleDay: ?{|
    +scheduleItem: {|
      +id: string,
      +start_time: any,
      +end_time: ?any,
      +title: ?string,
      +description: ?string,
      +location: ?string,
      +all_day: boolean,
    |}
  |}
|};
export type updateScheduleItemMutation = {|
  variables: updateScheduleItemMutationVariables,
  response: updateScheduleItemMutationResponse,
|};
*/


/*
mutation updateScheduleItemMutation(
  $input: UpdateItemInScheduleDayInput!
) {
  updateItemInScheduleDay(input: $input) {
    scheduleItem {
      id
      start_time
      end_time
      title
      description
      location
      all_day
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateItemInScheduleDayInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateItemInScheduleDay",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateItemInScheduleDayInput!"
      }
    ],
    "concreteType": "UpdateItemInScheduleDayPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "scheduleItem",
        "storageKey": null,
        "args": null,
        "concreteType": "ScheduleItem",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "start_time",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "end_time",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "location",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "all_day",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateScheduleItemMutation",
  "id": null,
  "text": "mutation updateScheduleItemMutation(\n  $input: UpdateItemInScheduleDayInput!\n) {\n  updateItemInScheduleDay(input: $input) {\n    scheduleItem {\n      id\n      start_time\n      end_time\n      title\n      description\n      location\n      all_day\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateScheduleItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateScheduleItemMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c070c261e1722b5871294710730bb00';
module.exports = node;
