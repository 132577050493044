/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Participants, { type Participant } from 'components/Participants';

import { type ContactMultiselectQueryResponse } from './__generated__/ContactMultiselectQuery.graphql';

const query = graphql`
  query ContactMultiselectQuery($contactIds: [ID!]!) {
    selectedContacts: nodes(ids: $contactIds) {
      id
      ... on Contact {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export default class ContactMultiselect extends React.PureComponent<{
  selectedContacts: $ReadOnlyArray<string>,
  updateSelectedContacts: (contactIds: $ReadOnlyArray<string>) => void,
  eventId?: ?string,
}> {
  cachedContacts = [];

  handleContactSelect = (contact: ?Participant): void => {
    if (contact && !this.props.selectedContacts.some(id => contact && id === contact.id)) {
      this.props.updateSelectedContacts([contact.id, ...this.props.selectedContacts]);
    }
  };

  handleContactRemove = (contact: ?Participant): void => {
    if (contact) {
      this.props.updateSelectedContacts(
        this.props.selectedContacts.filter(id => contact && contact.id !== id),
      );
    }
  };

  renderSuccess = (response: ?ContactMultiselectQueryResponse) => {
    this.cachedContacts = response
      ? response.selectedContacts.filter(Boolean)
      : this.cachedContacts;
    return (
      <Participants
        eventId={this.props.eventId}
        contacts={this.cachedContacts}
        onAddContact={this.handleContactSelect}
        onRemoveContact={this.handleContactRemove}
        defaultSearch="contacts"
        fromWindow="companies"
        autoFocus
      />
    );
  };

  render() {
    return (
      <DefaultQueryRenderer
        variables={{ contactIds: this.props.selectedContacts }}
        query={query}
        renderSuccess={this.renderSuccess}
        renderLoading={this.renderSuccess}
      />
    );
  }
}
