/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ContactSyncOption = "contact" | "lead";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesforceSettings_org$ref: FragmentReference;
export type SalesforceSettings_org = {|
  +settings: {|
    +id: string,
    +defaultTag: ?ContactSyncOption,
  |},
  +$refType: SalesforceSettings_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SalesforceSettings_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "defaultTag",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b7451ea66cec0828d73e476d25cfc359';
module.exports = node;
