/* @flow */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import timezones from 'config/timezones';

import stringifyTimeZone from 'utils/date/stringifyTimeZone';

import TextField from 'components/material/TextField';
import SelectField from 'components/material/SelectField';
import SingleDateTimeRangePicker, {
  type DatesConfig,
} from 'components/date/SingleDateTimeRangePicker';
import Button from 'components/material/Button';
import Radio from 'components/material/Radio';

const Form = styled.form``;

const Row = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RadioRow = styled(Row)`
  margin-top: -10px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  &:first-child {
    margin-left: auto;
  }

  button {
    width: 135px;
    margin-left: 10px;
    font-size: 13px;
    font-weight: 500;
  }
`;

const RadioWrapper = styled.div`
  font-weight: 600;
  color: ${props => (props.checked ? '#4b4b4b' : 'rgba(75, 75, 75, 0.48)')};

  &:not(:last-child) {
    margin-right: 42px;
  }
`;

const experienceTypes = {
  CLASSIC: 'Standard',
  BROADCAST: 'Webcast',
};

export type G2WLocationInputType = {|
  subject: string,
  times: $ReadOnlyArray<{| startTime: string, endTime: string |}>,
  timezone: string,
  experienceType: $Keys<typeof experienceTypes>,
|};

export default class G2WLocationForm extends React.PureComponent<
  {
    defaultSubject: string,
    defaultTimezone: string,
    defaultDate: ?string,
    onCancel: () => void,
    onCreate: G2WLocationInputType => Promise<any>,
  },
  {
    ...G2WLocationInputType,
    subjectError: boolean,
    timesError: boolean,
    loading: boolean,
  },
> {
  constructor(props: $PropertyType<G2WLocationForm, 'props'>) {
    super(props);

    const defaultDate = this.props.defaultDate ? moment(this.props.defaultDate) : null;
    const today = moment()
      .tz(this.props.defaultTimezone)
      .startOf('day')
      .add(1, 'day');

    this.state = {
      subject: this.props.defaultSubject,
      times: [
        defaultDate && defaultDate.isAfter()
          ? { startTime: defaultDate.format(), endTime: defaultDate.add(1, 'hour').format() }
          : { startTime: today.format(), endTime: today.add(1, 'hour').format() },
      ],
      timezone: this.props.defaultTimezone,
      experienceType: 'CLASSIC',
      subjectError: false,
      timesError: false,
      loading: false,
    };
  }

  handleChangeSubject = (event: SyntheticEvent<HTMLInputElement>) => {
    const subject = event.currentTarget.value;

    this.setState(state => {
      const changes = { subject };

      return { ...changes, ...this.handleValidate({ ...state, ...changes }) };
    });
  };

  handleChangeTime = (datesConfig: DatesConfig) => {
    this.setState(state => {
      if (!datesConfig.startDate || !datesConfig.endDate) return null;

      const changes = {
        times: [{ startTime: datesConfig.startDate, endTime: datesConfig.endDate }],
      };

      return { ...changes, ...this.handleValidate({ ...state, ...changes }) };
    });
  };

  handleChangeExperienceType = (experienceType: $Keys<typeof experienceTypes>) => {
    this.setState({ experienceType });
  };

  handleChangeTimezone = (timezone: ?string) => {
    this.setState(state => {
      if (!timezone) return null;

      const convertTimezone = date =>
        moment
          .tz(
            moment(date)
              .tz(state.timezone)
              .toArray(),
            timezone,
          )
          .format();

      const changes = {
        timezone,
        times: [
          {
            startTime: convertTimezone(state.times[0].startTime),
            endTime: convertTimezone(state.times[0].endTime),
          },
        ],
      };

      return { ...changes, ...this.handleValidate({ ...state, ...changes }) };
    });
  };

  handleValidate = (state: $PropertyType<G2WLocationForm, 'state'>) => ({
    subjectError: !state.subject.trim(),
    timesError: moment(state.times[0][0]).isBefore(),
  });

  handleCreate = (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    const { subject, times, timezone, experienceType } = this.state;
    const errors = this.handleValidate(this.state);

    this.setState(errors);

    if (Object.values(errors).some(error => error)) return;

    this.setState({ loading: true });

    this.props.onCreate({ subject, times, timezone, experienceType }).then(() => {
      this.setState({ loading: false });
    });
  };

  render() {
    const { onCancel } = this.props;
    const {
      subject,
      times,
      timezone,
      experienceType,
      subjectError,
      timesError,
      loading,
    } = this.state;

    return (
      <Form>
        <Row>
          <TextField
            label="Webinar Title"
            value={subject}
            autoFocus
            onChange={this.handleChangeSubject}
            error={subjectError ? 'Required' : null}
          />
        </Row>

        <Row>
          <SingleDateTimeRangePicker
            tz={timezone}
            startDate={times[0].startTime}
            endDate={times[0].endTime}
            allDay={false}
            onChange={this.handleChangeTime}
            dateLabel="Start Date"
            startTimeLabel="Start Time"
            endTimeLabel="End Time"
            clearable={false}
            error={timesError ? 'Must be in future' : null}
            defaultTimeInterval={15}
          />
        </Row>

        <Row>
          <SelectField
            label="Time Zone"
            value={timezone}
            options={timezones.map(tz => ({
              value: tz.name,
              label: stringifyTimeZone(tz),
            }))}
            onChange={this.handleChangeTimezone}
            searchable
          />
        </Row>

        <RadioRow>
          {Object.keys(experienceTypes).map(type => (
            <RadioWrapper key={type} checked={type === experienceType}>
              <Radio
                label={experienceTypes[type]}
                value={type}
                checked={type === experienceType}
                onChange={this.handleChangeExperienceType}
                radioColor={type === experienceType ? '#29cc71' : '#cdd1d4'}
              />
            </RadioWrapper>
          ))}
        </RadioRow>

        <Row>
          <ButtonWrapper>
            <Button label="Cancel" minimal onClick={onCancel} />
          </ButtonWrapper>

          <ButtonWrapper>
            <Button
              type="submit"
              label="Create Webinar"
              primary
              onClick={this.handleCreate}
              loading={loading}
            />
          </ButtonWrapper>
        </Row>
      </Form>
    );
  }
}
