/* @flow */
import moment from 'moment-timezone';

import { type TimeZone } from 'utils/date/stringifyTimeZone';

const zoomTimezones: $ReadOnlyArray<TimeZone> = [
  { name: 'Pacific/Pago_Pago', label: 'Pago Pago' },
  { name: 'Pacific/Honolulu', label: 'Hawaii' },
  { name: 'America/Anchorage', label: 'Alaska' },
  { name: 'America/Juneau', label: 'Juneau' },
  { name: 'America/Vancouver', label: 'Vancouver' },
  { name: 'America/Los_Angeles', label: 'Pacific Time (US and Canada)' },
  { name: 'America/Tijuana', label: 'Tijuana' },
  { name: 'America/Phoenix', label: 'Arizona' },
  { name: 'America/Edmonton', label: 'Edmonton' },
  { name: 'America/Denver', label: 'Mountain Time (US and Canada)' },
  { name: 'America/Mazatlan', label: 'Mazatlan' },
  { name: 'America/Regina', label: 'Saskatchewan' },
  { name: 'America/Guatemala', label: 'Guatemala' },
  { name: 'America/El_Salvador', label: 'El Salvador' },
  { name: 'America/Managua', label: 'Managua' },
  { name: 'America/Costa_Rica', label: 'Costa Rica' },
  { name: 'America/Tegucigalpa', label: 'Tegucigalpa' },
  { name: 'America/Chihuahua', label: 'Chihuahua' },
  { name: 'America/Winnipeg', label: 'Winnipeg' },
  { name: 'America/Chicago', label: 'Central Time (US and Canada)' },
  { name: 'America/Mexico_City', label: 'Mexico City' },
  { name: 'America/Panama', label: 'Panama' },
  { name: 'America/Bogota', label: 'Bogota' },
  { name: 'America/Lima', label: 'Lima' },
  { name: 'America/Monterrey', label: 'Monterrey' },
  { name: 'America/Montreal', label: 'Montreal' },
  { name: 'America/New_York', label: 'Eastern Time (US and Canada)' },
  { name: 'America/Indianapolis', label: 'Indiana (East)' },
  { name: 'America/Puerto_Rico', label: 'Puerto Rico' },
  { name: 'America/Caracas', label: 'Caracas' },
  { name: 'America/Santiago', label: 'Santiago' },
  { name: 'America/La_Paz', label: 'La Paz' },
  { name: 'America/Guyana', label: 'Guyana' },
  { name: 'America/Halifax', label: 'Halifax' },
  { name: 'America/Montevideo', label: 'Montevideo' },
  { name: 'America/Araguaina', label: 'Recife' },
  { name: 'America/Argentina/Buenos_Aires', label: 'Buenos Aires, Georgetown' },
  { name: 'America/Sao_Paulo', label: 'Sao Paulo' },
  { name: 'Canada/Atlantic', label: 'Atlantic Time (Canada)' },
  { name: 'America/St_Johns', label: 'Newfoundland and Labrador' },
  { name: 'America/Godthab', label: 'Greenland' },
  { name: 'Atlantic/Cape_Verde', label: 'Cape Verde Islands' },
  { name: 'Atlantic/Azores', label: 'Azores' },
  { name: 'UTC', label: 'Universal Time UTC' },
  { name: 'Etc/Greenwich', label: 'Greenwich Mean Time' },
  { name: 'Atlantic/Reykjavik', label: 'Reykjavik' },
  { name: 'Africa/Nouakchott', label: 'Nouakchott' },
  { name: 'Europe/Dublin', label: 'Dublin' },
  { name: 'Europe/London', label: 'London' },
  { name: 'Europe/Lisbon', label: 'Lisbon' },
  { name: 'Africa/Casablanca', label: 'Casablanca' },
  { name: 'Africa/Bangui', label: 'West Central Africa' },
  { name: 'Africa/Algiers', label: 'Algiers' },
  { name: 'Africa/Tunis', label: 'Tunis' },
  { name: 'Europe/Belgrade', label: 'Belgrade, Bratislava, Ljubljana' },
  { name: 'CET', label: 'Sarajevo, Skopje, Zagreb' },
  { name: 'Europe/Oslo', label: 'Oslo' },
  { name: 'Europe/Copenhagen', label: 'Copenhagen' },
  { name: 'Europe/Brussels', label: 'Brussels' },
  { name: 'Europe/Berlin', label: 'Amsterdam, Berlin, Rome, Stockholm, Vienna' },
  { name: 'Europe/Amsterdam', label: 'Amsterdam' },
  { name: 'Europe/Rome', label: 'Rome' },
  { name: 'Europe/Stockholm', label: 'Stockholm' },
  { name: 'Europe/Vienna', label: 'Vienna' },
  { name: 'Europe/Luxembourg', label: 'Luxembourg' },
  { name: 'Europe/Paris', label: 'Paris' },
  { name: 'Europe/Zurich', label: 'Zurich' },
  { name: 'Europe/Madrid', label: 'Madrid' },
  { name: 'Africa/Harare', label: 'Harare, Pretoria' },
  { name: 'Europe/Warsaw', label: 'Warsaw' },
  { name: 'Europe/Prague', label: 'Prague Bratislava' },
  { name: 'Europe/Budapest', label: 'Budapest' },
  { name: 'Africa/Tripoli', label: 'Tripoli' },
  { name: 'Africa/Cairo', label: 'Cairo' },
  { name: 'Africa/Johannesburg', label: 'Johannesburg' },
  { name: 'Africa/Khartoum', label: 'Khartoum' },
  { name: 'Europe/Helsinki', label: 'Helsinki' },
  { name: 'Africa/Nairobi', label: 'Nairobi' },
  { name: 'Europe/Sofia', label: 'Sofia' },
  { name: 'Europe/Istanbul', label: 'Istanbul' },
  { name: 'Europe/Athens', label: 'Athens' },
  { name: 'Europe/Bucharest', label: 'Bucharest' },
  { name: 'Asia/Nicosia', label: 'Nicosia' },
  { name: 'Asia/Beirut', label: 'Beirut' },
  { name: 'Asia/Damascus', label: 'Damascus' },
  { name: 'Asia/Jerusalem', label: 'Jerusalem' },
  { name: 'Asia/Amman', label: 'Amman' },
  { name: 'Europe/Moscow', label: 'Moscow' },
  { name: 'Asia/Baghdad', label: 'Baghdad' },
  { name: 'Asia/Kuwait', label: 'Kuwait' },
  { name: 'Asia/Riyadh', label: 'Riyadh' },
  { name: 'Asia/Bahrain', label: 'Bahrain' },
  { name: 'Asia/Qatar', label: 'Qatar' },
  { name: 'Asia/Aden', label: 'Aden' },
  { name: 'Africa/Djibouti', label: 'Djibouti' },
  { name: 'Africa/Mogadishu', label: 'Mogadishu' },
  { name: 'Europe/Kiev', label: 'Kiev' },
  { name: 'Europe/Minsk', label: 'Minsk' },
  { name: 'Asia/Dubai', label: 'Dubai' },
  { name: 'Asia/Muscat', label: 'Muscat' },
  { name: 'Asia/Baku', label: 'Baku, Tbilisi, Yerevan' },
  { name: 'Asia/Tehran', label: 'Tehran' },
  { name: 'Asia/Kabul', label: 'Kabul' },
  { name: 'Asia/Yekaterinburg', label: 'Yekaterinburg' },
  { name: 'Asia/Tashkent', label: 'Islamabad, Karachi, Tashkent' },
  { name: 'Asia/Calcutta', label: 'India' },
  { name: 'Asia/Kolkata', label: 'Mumbai, Kolkata, New Delhi' },
  { name: 'Asia/Kathmandu', label: 'Kathmandu' },
  { name: 'Asia/Almaty', label: 'Almaty' },
  { name: 'Asia/Dacca', label: 'Dacca' },
  { name: 'Asia/Dhaka', label: 'Astana, Dhaka' },
  { name: 'Asia/Rangoon', label: 'Rangoon' },
  { name: 'Asia/Novosibirsk', label: 'Novosibirsk' },
  { name: 'Asia/Krasnoyarsk', label: 'Krasnoyarsk' },
  { name: 'Asia/Bangkok', label: 'Bangkok' },
  { name: 'Asia/Saigon', label: 'Vietnam' },
  { name: 'Asia/Jakarta', label: 'Jakarta' },
  { name: 'Asia/Irkutsk', label: 'Irkutsk, Ulaanbaatar' },
  { name: 'Asia/Shanghai', label: 'Beijing, Shanghai' },
  { name: 'Asia/Hong_Kong', label: 'Hong Kong SAR' },
  { name: 'Asia/Taipei', label: 'Taipei' },
  { name: 'Asia/Kuala_Lumpur', label: 'Kuala Lumpur' },
  { name: 'Asia/Singapore', label: 'Singapore' },
  { name: 'Australia/Perth', label: 'Perth' },
  { name: 'Asia/Yakutsk', label: 'Yakutsk' },
  { name: 'Asia/Seoul', label: 'Seoul' },
  { name: 'Asia/Tokyo', label: 'Osaka, Sapporo, Tokyo' },
  { name: 'Australia/Darwin', label: 'Darwin' },
  { name: 'Australia/Adelaide', label: 'Adelaide' },
  { name: 'Asia/Vladivostok', label: 'Vladivostok' },
  { name: 'Pacific/Port_Moresby', label: 'Guam, Port Moresby' },
  { name: 'Australia/Brisbane', label: 'Brisbane' },
  { name: 'Australia/Sydney', label: 'Canberra, Melbourne, Sydney' },
  { name: 'Australia/Hobart', label: 'Hobart' },
  { name: 'Asia/Magadan', label: 'Magadan' },
  { name: 'Pacific/Noumea', label: 'New Caledonia' },
  { name: 'Asia/Kamchatka', label: 'Kamchatka' },
  { name: 'Pacific/Fiji', label: 'Fiji Islands, Marshall Islands' },
  { name: 'Pacific/Auckland', label: 'Auckland, Wellington' },
  { name: 'Pacific/Apia', label: 'Independent State of Samoa' },
].map(tz => ({
  ...tz,
  offset:
    moment()
      .tz(tz.name)
      .utcOffset() / 60,
}));

export default zoomTimezones;
