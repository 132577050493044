/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  SaveFormLogicInput,
  saveFormLogicMutationResponse,
} from './__generated__/saveFormLogicMutation.graphql';

const mutation = graphql`
  mutation saveFormLogicMutation($input: SaveFormLogicInput!) {
    saveFormLogic(input: $input) {
      ruleable {
        id
        __typename
        rules {
          edges {
            node {
              id
              action
              order
              customSavedTextFilters {
                edges {
                  node {
                    id
                    order
                    fieldName
                    values
                    operator
                    customField {
                      id
                      customizableType
                    }
                  }
                }
              }
              customSavedTextareaFilters {
                edges {
                  node {
                    id
                    order
                    fieldName
                    values
                    operator
                    customField {
                      id
                      customizableType
                    }
                  }
                }
              }
              customSavedLinkFilters {
                edges {
                  node {
                    id
                    order
                    values
                    operator
                    fieldName
                    customField {
                      id
                      customizableType
                    }
                  }
                }
              }
              customSavedNumberFilters {
                edges {
                  node {
                    id
                    order
                    minValue
                    maxValue
                    customField {
                      id
                      customizableType
                    }
                  }
                }
              }
              customSavedCurrencyFilters {
                edges {
                  node {
                    id
                    order
                    minValue
                    maxValue
                    fieldName
                    customField {
                      id
                      customizableType
                    }
                  }
                }
              }
              customSavedDateFilters {
                edges {
                  node {
                    id
                    order
                    minValue
                    maxValue
                    customField {
                      id
                    }
                  }
                }
              }
              customSavedBooleanFilters {
                edges {
                  node {
                    id
                    value
                    order
                    customField {
                      id
                    }
                  }
                }
              }
              customSavedUserMultiselectFilters {
                edges {
                  node {
                    id
                    order
                    operator
                    fieldName
                    customField {
                      id
                      customizableType
                    }
                    options {
                      edges {
                        node {
                          user {
                            id
                            email
                            firstName
                            lastName
                            ...MaterialAvatar_user
                          }
                        }
                      }
                    }
                  }
                }
              }
              customSavedMultiselectFilters {
                edges {
                  node {
                    id
                    order
                    operator
                    options {
                      edges {
                        node {
                          option {
                            id
                            name
                          }
                        }
                      }
                    }
                    customField {
                      id
                      customizableType
                    }
                  }
                }
              }
            }
          }
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function saveFormLogic(
  input: SaveFormLogicInput,
): Promise<saveFormLogicMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input },
  });
}
