/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import Clear from 'components/ClearIcon';
import Overlay from 'components/material/Overlay';
import TextField, { Icon } from 'components/material/TextField';

export const ClearIcon = styled(Clear)`
  ${props =>
    props.hasicon &&
    css`
      top: 21px;
      right: 0;
    `};
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  outline: none;
  ${props =>
    (props.hasicon || props.clearable) &&
    css`
      input {
        padding-right: ${props.hasicon && props.clearable ? '35px' : '20px'};
      }
      ${Icon} {
        right: ${props.hasicon && props.clearable ? '20px' : '0'};
      }
    `};
`;

const Option = styled.div`
  cursor: pointer;
  ${props =>
    props.focused &&
    css`
      background: ${props.theme.hoverRowColor};
    `};
`;

export const StyledOverlay = styled(Overlay)`
  display: flex;
  min-width: 100%;
  width: auto;
  max-height: ${props => (props.maxHeight != null ? `${props.maxHeight}px` : 'unset')};
  margin-top: 3px;
  margin-bottom: 50px;
`;

const OverlayScroll = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const Empty = styled.div`
  font-size: 13px;
  font-style: italic;
  margin: 10px;
  color: ${props => props.theme.rowSecondaryTextColor};
`;

const OverlayFocus = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  outline: none;
`;

export default class AutocompleteInput<OptionType> extends React.PureComponent<
  {
    defaultQuery?: ?string,
    options?: $ReadOnlyArray<OptionType>,
    onFilterOptions: (query: string) => $ReadOnlyArray<OptionType> | Promise<any> | void,
    renderOption: (option: OptionType) => React.Node,
    renderOptionString?: ?(option: OptionType) => string,
    onSelect: (option: ?OptionType) => void,
    onFocus?: () => void,
    onBlur?: (query: string) => void,
    onKeyDown?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
    autoFocus?: boolean,
    placeholder?: string,
    emptyText?: string,
    footerContent?: ?React.Node,
    className?: string,
    icon?: ?string,
    label?: string,
    showOptionsOnEmpty?: boolean,
    clearable?: boolean,
    readOnly?: boolean,
    required?: boolean,
    resetOnBlur?: boolean,
    disabled?: boolean,
    overlayMaxHeight?: ?number,
    initialFocus?: number,
    overlayContainer?: ?HTMLElement,
    prefix?: string,
    error?: ?string,
  },
  { query: string, focus: number, overlayShown: boolean, prevDefaultQuery: string },
> {
  static getDerivedStateFromProps(
    props: $PropertyType<AutocompleteInput<OptionType>, 'props'>,
    state: $PropertyType<AutocompleteInput<OptionType>, 'state'>,
  ) {
    if (state.overlayShown || (props.defaultQuery || '') === (state.prevDefaultQuery || ''))
      return null;

    return { query: props.defaultQuery || '', prevDefaultQuery: props.defaultQuery || '' };
  }

  state = {
    query: this.props.defaultQuery || '',
    focus: 0,
    overlayShown: false,
    // eslint-disable-next-line react/no-unused-state
    prevDefaultQuery: this.props.defaultQuery || '',
  };

  containerRef = React.createRef();

  inputRef = React.createRef();

  focusRef = React.createRef();

  overlayRef = React.createRef();

  overlayScrollRef = React.createRef();

  newQuery: ?string;

  componentDidUpdate(
    prevProps: $PropertyType<AutocompleteInput<OptionType>, 'props'>,
    prevState: $PropertyType<AutocompleteInput<OptionType>, 'state'>,
  ) {
    window.requestAnimationFrame(() => {
      if (!prevState.overlayShown && this.state.overlayShown) {
        this.scrollToFocusedElement();
      }
    });
  }

  handleQueryChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { prefix } = this.props;
    const query = prefix ? e.target.value.replace(`${prefix} `, '') : e.target.value;
    this.setState({ query }, () => {
      this.props.onFilterOptions(this.state.query);
    });
  };

  handleInputKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.setState(
        state => ({ focus: state.focus + 1 }),
        () => {
          this.scrollToFocusedElement(1);
        },
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.setState(
        state => ({ focus: state.focus - 1 }),
        () => {
          this.scrollToFocusedElement(-1);
        },
      );
    } else if (e.key === 'Enter') {
      e.preventDefault();
      this.handleOptionSelect();
    } else if (e.key === 'Escape') {
      if (this.containerRef.current) this.containerRef.current.focus();
    }
    if (this.props.onKeyDown) this.props.onKeyDown(e);
  };

  handleOptionHover = (index: number) => {
    this.setState({ focus: index });
  };

  handleOptionSelect = () => {
    const options = this.options();
    const focusedIndex = this.focusedIndex(options.length);

    const option = options[focusedIndex];

    if (option != null) {
      this.props.onSelect(option);
      const newQuery = this.props.renderOptionString ? this.props.renderOptionString(option) : '';
      this.newQuery = newQuery;
      this.setState({ focus: 0, query: newQuery });
    }

    if (this.focusRef.current) this.focusRef.current.focus();
  };

  handleFooterClick = () => {
    if (this.focusRef.current) this.focusRef.current.focus();
  };

  handleOverlayShow = () => {
    if (this.props.resetOnBlur && this.state.query) {
      this.props.onFilterOptions('');
      this.setState({ query: '' });
    }

    if (this.props.onFocus) this.props.onFocus();

    this.setState({ overlayShown: true, focus: this.props.initialFocus || 0 });
  };

  handleBlur = (e: SyntheticEvent<HTMLDivElement>) => {
    const container = this.containerRef.current;
    const overlay = this.overlayRef.current;
    const relatedTarget: ?Node = (e: any).relatedTarget || document.activeElement;

    if (
      container &&
      overlay &&
      relatedTarget &&
      this.focusRef.current !== relatedTarget &&
      (container.contains(relatedTarget) || overlay.contains(relatedTarget))
    ) {
      return;
    }

    const { onBlur, resetOnBlur, defaultQuery } = this.props;

    if (container && onBlur) {
      onBlur(container.querySelector('input').value);
    }

    this.setState({ overlayShown: false, focus: 0 });

    if (resetOnBlur && !this.newQuery && defaultQuery) {
      this.setState({ query: defaultQuery });
    }
    this.newQuery = null;
  };

  handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  handleClear = (e: MouseEvent) => {
    e.stopPropagation();
    this.setState({ query: '' });
    if (this.props.onSelect) {
      this.props.onSelect(null);
    }
  };

  scrollToFocusedElement(direction: 1 | -1 = -1) {
    const scrollElement = this.overlayScrollRef.current;

    if (!scrollElement) return;

    const optionElements = [...scrollElement.children];

    const focusElement = optionElements[this.focusedIndex(this.options().length)];

    if (!focusElement) return;

    const isVisible =
      focusElement.offsetTop > scrollElement.scrollTop &&
      focusElement.offsetTop + focusElement.offsetHeight <
        scrollElement.scrollTop + scrollElement.offsetHeight;

    if (!isVisible) {
      if (direction === -1) {
        scrollElement.scrollTop = focusElement.offsetTop;
      } else {
        scrollElement.scrollTop =
          focusElement.offsetTop + focusElement.offsetHeight - scrollElement.offsetHeight;
      }
    }
  }

  focusedIndex(length: number): number {
    const maxIndex = length + (this.props.footerContent == null ? 0 : 1);
    const index = this.state.focus % maxIndex;
    if (this.state.focus >= 0 || index === 0) {
      return index;
    }
    return maxIndex + index;
  }

  options(): $ReadOnlyArray<OptionType> {
    if (this.props.options) return this.props.options;
    const filterResponse = this.props.onFilterOptions(this.state.query);

    return Array.isArray(filterResponse) ? filterResponse : [];
  }

  render() {
    const options = this.options();
    const focusedIndex = this.focusedIndex(options.length);

    return (
      <Container
        className={this.props.className}
        hasicon={this.props.icon != null || undefined}
        clearable={this.props.clearable}
        ref={this.containerRef}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
      >
        <TextField
          value={this.state.query}
          onChange={this.handleQueryChange}
          onKeyDown={this.handleInputKeyDown}
          onFocus={this.handleOverlayShow}
          autoFocus={this.props.autoFocus}
          placeholder={this.props.placeholder}
          icon={this.props.icon}
          label={this.props.label}
          readOnly={this.props.readOnly}
          required={this.props.required}
          disabled={this.props.disabled}
          prefix={this.props.prefix || ''}
          ref={this.inputRef}
          error={this.props.error}
        />
        {this.props.clearable && !this.props.disabled && this.state.query && (
          <ClearIcon onClick={this.handleClear} hasicon={this.props.icon != null || undefined} />
        )}
        <StyledOverlay
          show={
            this.state.overlayShown &&
            ((this.props.showOptionsOnEmpty && options.length > 0) ||
              this.props.footerContent != null ||
              (this.state.query.length > 0 &&
                ((this.props.emptyText && this.state.query.length > 2) || options.length > 0)))
          }
          target={this.inputRef.current}
          container={this.props.overlayContainer || this.containerRef.current}
          maxHeight={this.props.overlayMaxHeight}
        >
          <OverlayFocus tabIndex={-1} ref={this.overlayRef}>
            {options.length === 0 && this.props.emptyText && <Empty>{this.props.emptyText}</Empty>}
            <OverlayScroll ref={this.overlayScrollRef}>
              {options.map((option, index) => (
                <Option
                  key={index} // eslint-disable-line react/no-array-index-key
                  focused={focusedIndex === index}
                  onMouseEnter={() => this.handleOptionHover(index)}
                  onClick={this.handleOptionSelect}
                >
                  {this.props.renderOption(option)}
                </Option>
              ))}
              {this.props.footerContent != null && (
                <Option
                  key={options.length}
                  focused={focusedIndex === options.length}
                  onMouseEnter={() => this.handleOptionHover(options.length)}
                  onClick={this.handleFooterClick}
                >
                  {this.props.footerContent}
                </Option>
              )}
            </OverlayScroll>
          </OverlayFocus>
        </StyledOverlay>

        <OverlayFocus ref={this.focusRef} tabIndex={-1} />
      </Container>
    );
  }
}
