/* @flow */
import * as React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import { camelCase, omit, sortBy } from 'lodash';

import viewedContactForm from 'utils/analytics/events/viewedContactForm';
import convertCustomFieldValuesToInput from 'utils/customization/convertCustomFieldValuesToInput';
import getCustomFieldPlainValue from 'utils/customization/getCustomFieldPlainValue';
import getOptionKeysByKind from 'utils/customization/getOptionKeysByKind';
import type { CustomFieldInput, CustomFieldsType } from 'utils/customization/types';
import sanitizeHtml from 'utils/string/sanitizeHtml';
import isValidBusinessEmail from 'utils/validators/isValidBusinessEmail';
import isValidEmail from 'utils/validators/isValidEmail';
import isValidWebsite from 'utils/validators/isValidWebsite';

import type { registerEventContactMutationResponse } from 'graph/mutations/contact/__generated__/registerEventContactMutation.graphql';
import { type UpdateCustomFieldValueInput } from 'graph/mutations/custom_field/updateCustomFieldValue';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/budget/Button';
import { withSharedFormTheme } from 'components/SharedForm/lib/SharedFormThemeContext';
import TextField from 'components/SharedForm/TextField';
import { StyledContent } from 'components/TinyRichText';
import type { User } from 'components/UserSelect';
import { type CompanyDefaultFields } from 'views/Main/AllContacts/Company/tabs/CompanyProfile/CompanyFormFields';
import generateFontFromProps from 'views/Main/Event/Registration/RegistrationCreate/RegistrationPageContent/generateFontFromProps';

import companySuggestedFields from '../lib/companySuggestedFields';
import { defaultCustomFieldValues } from '../lib/contactDefaultValues';
import customFields from '../lib/customFields';
import getDefaultFieldValue from '../lib/getDefaultFieldValue';
import isCompanyField from '../lib/isCompanyField';
import isUserType from '../lib/isUserType';
import isRequiredQuestion from '../lib/rules/isRequiredQuestion';
import isVisibleQuestion from '../lib/rules/isVisibleQuestion';
import suggestedFields, {
  type ExtendedContactDefaultFields,
  defaultSelectFields,
} from '../lib/suggestedFields';
import type { QuestionType, RegistrationFormDefaultValues } from '../lib/types';

import type { ContactFormSubmission_contactForm } from './__generated__/ContactFormSubmission_contactForm.graphql';
import type { ContactFormSubmission_event } from './__generated__/ContactFormSubmission_event.graphql';
import type { ContactFormSubmission_org } from './__generated__/ContactFormSubmission_org.graphql';

export type FormSubmissionFn = (
  defaultFieldValues: $Shape<ExtendedContactDefaultFields>,
  updatedAt: string,
  customFieldValues: $ReadOnlyArray<CustomFieldInput>,
  company: ?$Shape<CompanyDefaultFields>,
) => Promise<registerEventContactMutationResponse>;

const Container = styled.div`
  position: relative;
`;

const Row = styled.div`
  margin: 20px 0 30px 0;
`;

const StyledButton = styled(Button)``; // this used in ButtonWrapper to note have check in renderCreateButton

const ButtonWrapper = withSharedFormTheme(styled.div`
  display: flex;
  padding: 20px 0;

  ${StyledButton} {
    min-width: 222px;
    max-width: 400px;
    height: 45px;
    border-radius: 4px;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    ${props =>
      (props.sharedFormTheme.textFont || props.sharedFormTheme.textStyle) &&
      css`
        font: ${props.sharedFormTheme
          ? generateFontFromProps({
              fontFamily: props.sharedFormTheme.textFont,
              fontStyle: props.sharedFormTheme.textStyle,
              fontSize: 16,
            })
          : ''};
      `}
    ${props =>
      props.sharedFormTheme.buttonColor &&
      css`
        background: ${props.sharedFormTheme.buttonColor};
      `};
    ${props =>
      props.sharedFormTheme.buttonTextColor &&
      css`
        color: ${props.sharedFormTheme.buttonTextColor};
      `};
    ${props =>
      props.sharedFormTheme.buttonColor &&
      css`
        &:hover,
        &:focus {
          background: ${props.sharedFormTheme.buttonColor};
          opacity: 0.9;
        }
      `}
  }
`);

const NameRow = styled.div`
  display: flex;
  margin: 20px 0 30px 0;
  @media (max-width: 500px) {
    display: block;
  }
`;

const NameTextField = styled(TextField)`
  &:first-of-type {
    margin-right: 39px;
  }
  @media (max-width: 500px) {
    &:first-of-type {
      margin-bottom: 30px;
    }
  }
`;

const mandatoryFields = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
};

const mandatoryQuestions = Object.keys(mandatoryFields).map((key, index) => ({
  id: key,
  fieldName: key,
  order: index - 3,
  label: mandatoryFields[key],
  required: true,
  visible: true,
  helpText: null,
  customField: null,
  contactFormFieldOptions: [],
  showOtherOption: false,
}));

class ContactFormSubmission extends React.PureComponent<
  {
    defaultFormValues: RegistrationFormDefaultValues,
    contactForm: ContactFormSubmission_contactForm,
    org: ContactFormSubmission_org,
    event?: ContactFormSubmission_event,
    tz: string,
    onSubmit: FormSubmissionFn,
    onFinishSubmit: () => void,
    viewMode?: boolean,
    companyEmailsOnly?: boolean,
    updatedAt: string,
    className?: string,
    createCopy?: string,
    email?: string,
    lockEmail?: boolean,
  },
  {
    defaultFieldValues: $Shape<ExtendedContactDefaultFields>,
    companyDefaultFieldValues: $Shape<CompanyDefaultFields>,
    customFieldValues: CustomFieldsType,
    errors: { [string]: string },
    companyErrors: { [string]: string },
    loading: boolean,
  },
> {
  state = {
    ...(this.props.defaultFormValues || {
      defaultFieldValues: {
        firstName: '',
        lastName: '',
        email: this.props.email || '',
        contactTypeOther: null,
      },
      companyDefaultFieldValues: { name: '' },
      customFieldValues: defaultCustomFieldValues,
    }),
    errors: {},
    companyErrors: {},
    loading: false,
  };

  containerRef = React.createRef();

  componentDidMount() {
    const { event, org } = this.props;
    if (event) {
      viewedContactForm({
        orgId: org.id,
        orgName: org.name,
        eventId: event.id,
        eventName: event.name,
        teamId: event.team.id,
        teamName: event.team.name,
      });
    }
  }

  getSortedContactFormFields = (): $ReadOnlyArray<QuestionType> => {
    const { contactForm, tz, viewMode } = this.props;
    const { defaultFieldValues, companyDefaultFieldValues, customFieldValues } = this.state;

    const contactFormFields: Array<QuestionType> = contactForm.contactFormFields.edges.map(
      ({ node: contactFormField }) => {
        const contactFormFieldOptions = contactFormField.contactFormFieldOptions
          ? contactFormField.contactFormFieldOptions.edges.map(
              ({ node }) => node.customFieldOption.id,
            )
          : [];

        const contactFormFieldUserOptions =
          contactFormField.contactFormFieldUserOptions &&
          contactFormField.contactFormFieldUserOptions.edges.map(({ node }) => node.user.id);

        const customFieldNormalized = contactFormField.customField && {
          ...omit(contactFormField.customField, 'options'),
          options: contactFormField.customField
            ? [
                ...sortBy(
                  contactFormField.customField.options.edges
                    .filter(({ node }) => !contactFormFieldOptions.includes(node.id))
                    .map(({ node }) => ({
                      label: node.name,
                      value: node.id,
                    })),
                  'label',
                ),
                contactFormField.showOtherOption ? { label: 'Other', value: 'other' } : null,
              ].filter(Boolean)
            : [],
        };

        const visible = viewMode
          ? true
          : isVisibleQuestion(
              { defaultFieldValues, companyDefaultFieldValues, customFieldValues },
              contactFormField,
              tz,
            );

        // If question is not visible no need to check required conditions
        const required =
          viewMode || !visible
            ? contactFormField.required
            : isRequiredQuestion(
                { defaultFieldValues, companyDefaultFieldValues, customFieldValues },
                contactFormField,
                tz,
              );

        return {
          ...omit(contactFormField, ['contactFormFieldOptions', 'contactFormFieldUserOptions']),
          contactFormFieldOptions: isUserType(customFieldNormalized)
            ? contactFormFieldUserOptions
            : contactFormFieldOptions,
          customField: customFieldNormalized,
          required,
          visible,
        };
      },
    );

    return sortBy(contactFormFields, 'order');
  };

  getSuggestedRenderer = (question: QuestionType) => {
    const coreRenderer = question.fieldName ? suggestedFields[question.fieldName] : null;
    const suggestedRenderer =
      question.customField &&
      question.customField.fieldName &&
      suggestedFields[question.customField.fieldName];

    return coreRenderer || suggestedRenderer;
  };

  handleUpdateCustomField = (
    question: ?QuestionType,
    input: {
      ...$Diff<UpdateCustomFieldValueInput, { customFieldId: string }>,
      selectOtherValue?: ?string,
    },
    users?: $ReadOnlyArray<User>,
  ) => {
    if (!question || !question.customField) {
      return;
    }
    const { arrayKey, optionKey } = getOptionKeysByKind(question.customField.kind);
    const value = Object.values(input)[0];
    const valuesArray = (Array.isArray(value)
      ? value
      : [value].filter(val => val != null)
    ).map(optionId => (users == null ? optionId : users.find(user => user.id === optionId)));

    const selectOtherValue =
      input.selectOtherValue || input.selectOtherValue === ''
        ? input.selectOtherValue.trim()
        : null;

    this.setState(
      prevState => ({
        customFieldValues: {
          ...prevState.customFieldValues,
          [arrayKey]: [
            ...prevState.customFieldValues[arrayKey].filter(
              option =>
                question &&
                question.customField &&
                option.customField.id !== question.customField.id,
            ),
            ...valuesArray.map(option => ({
              customField: {
                id: question && question.customField ? question.customField.id : null,
              },
              [optionKey]: optionKey === 'option' ? { id: option } : option,
              selectOtherValue,
            })),
          ],
        },
      }),
      () => {
        this.validateForm(true);
      },
    );

    this.validateField(question, Object.values(input)[0], selectOtherValue, false);
  };

  handleUpdateCompanySuggestedField = (field: QuestionType, changes: CompanyDefaultFields) => {
    this.setState(
      prevState => ({
        companyDefaultFieldValues: { ...prevState.companyDefaultFieldValues, ...changes },
      }),
      () => {
        this.validateForm(true);
      },
    );

    this.validateField(field, Object.values(changes)[0], null, false);
  };

  handleUpdateSuggestedField = (
    question: QuestionType,
    changes: $Shape<ExtendedContactDefaultFields>,
  ) => {
    this.setState(
      prevState => ({
        defaultFieldValues: { ...prevState.defaultFieldValues, ...changes },
      }),
      () => {
        this.validateForm(true);
      },
    );

    const otherValue = Object.values(changes)[1];

    this.validateField(
      question,
      Object.values(changes)[0],
      typeof otherValue === 'string' ? otherValue : null,
      false,
    );
  };

  validateField = (
    question: QuestionType,
    value: mixed,
    selectOtherValue: ?string,
    removeErrorsOnly: boolean,
  ) =>
    new Promise(resolve =>
      this.setState(prevState => {
        const customFieldKey =
          question.customField && (question.customField.fieldName || question.customField.id);
        const key = customFieldKey || question.fieldName || '';
        const kind = (question.customField && question.customField.kind) || '';

        const invalidSelectedValue = selectOtherValue !== null && !selectOtherValue;
        const belongsToCompany = isCompanyField(question);

        if (removeErrorsOnly || !question.visible) {
          if (belongsToCompany) {
            return prevState.companyErrors[key] === 'Required'
              ? {
                  companyErrors: omit(prevState.companyErrors, key),
                }
              : null;
          }
          return prevState.errors[key] === 'Required'
            ? {
                errors: omit(prevState.errors, key),
              }
            : null;
        }

        if (
          question.required &&
          (value == null ||
            value === false ||
            (typeof value === 'string' && !value.trim()) ||
            ((kind === 'SELECT' || defaultSelectFields.includes(customFieldKey)) &&
              value === 'other' &&
              invalidSelectedValue) ||
            (Array.isArray(value) &&
              ['MULTISELECT', 'USER_MULTISELECT'].includes(kind) &&
              (value.length === 0 ||
                (value.length === 1 && value.includes('other') && invalidSelectedValue))))
        ) {
          if (belongsToCompany) {
            return { companyErrors: { ...prevState.companyErrors, [key]: 'Required' } };
          }
          return { errors: { ...prevState.errors, [key]: 'Required' } };
        }

        if (question.fieldName === 'email' && typeof value === 'string' && !isValidEmail(value)) {
          return { errors: { ...prevState.errors, email: 'Invalid email address' } };
        }

        if (
          this.props.companyEmailsOnly &&
          question.fieldName === 'email' &&
          typeof value === 'string' &&
          !isValidBusinessEmail(value)
        ) {
          return {
            errors: { ...prevState.errors, email: 'Please use your@company.com email address' },
          };
        }

        if (
          question.customField &&
          question.customField.kind === 'NUMBER' &&
          value != null &&
          typeof value === 'number' &&
          value.toString().length > 9
        ) {
          if (belongsToCompany) {
            return {
              companyErrors: {
                ...prevState.companyErrors,
                [key]: 'Number fields cannot be more than 9 digits',
              },
            };
          }
          return {
            errors: {
              ...prevState.errors,
              [key]: 'Number fields cannot be more than 9 digits',
            },
          };
        }

        if (
          ((question.customField && question.customField.kind === 'LINK') ||
            ['website', 'twitter', 'linkedin'].includes(key)) &&
          value != null &&
          value !== '' &&
          !isValidWebsite(String(value))
        ) {
          if (belongsToCompany) {
            return { companyErrors: { ...prevState.companyErrors, [key]: 'Invalid url' } };
          }
          return { errors: { ...prevState.errors, [key]: 'Invalid url' } };
        }

        if (belongsToCompany) {
          return { companyErrors: omit(prevState.companyErrors, key) };
        }
        return { errors: omit(prevState.errors, key) };
      }, resolve),
    );

  getDefaultFieldOtherValue = (fieldName: ?string): ?string => {
    const { defaultFieldValues } = this.state;
    if (fieldName === 'contact_type_id') {
      return defaultFieldValues.contactTypeOther;
    }

    return null;
  };

  validateForm = (removeErrorsOnly: boolean) => {
    const { customFieldValues, companyDefaultFieldValues, defaultFieldValues } = this.state;

    return Promise.all(
      [...mandatoryQuestions, ...this.getSortedContactFormFields()].map(question => {
        if (question.required && removeErrorsOnly) {
          return Promise.resolve();
        }

        if (question.customField && ['SELECT', 'MULTISELECT'].includes(question.customField.kind)) {
          const customFieldValue = customFieldValues.customSelectFields.find(
            edge => question.customField && edge.customField.id === question.customField.id,
          );

          const selectedValue = getCustomFieldPlainValue(
            omit(question.customField, 'customizableType'),
            customFieldValues,
          );

          const selectedIds = Array.isArray(selectedValue)
            ? selectedValue
                .map(val => (typeof val === 'object' && val != null ? val.id : null))
                .filter(Boolean)
            : null;

          const selectedId =
            selectedValue != null && typeof selectedValue === 'object' ? selectedValue.id : null;

          return this.validateField(
            question,
            selectedIds || selectedId,
            customFieldValue ? customFieldValue.selectOtherValue : null,
            false,
          );
        }

        const fieldName =
          question.fieldName || (question.customField && question.customField.fieldName);

        const defaultOtherValue = this.getDefaultFieldOtherValue(fieldName);

        return this.validateField(
          question,
          fieldName
            ? getDefaultFieldValue({ defaultFieldValues, companyDefaultFieldValues }, question)
            : getCustomFieldPlainValue(
                omit(question.customField, 'customizableType'),
                customFieldValues,
              ),
          defaultOtherValue,
          removeErrorsOnly,
        );
      }),
    );
  };

  filterAnswersByVisibleQuestions = () => {
    const { defaultFieldValues, companyDefaultFieldValues, customFieldValues } = this.state;

    const hiddenQuestions = this.getSortedContactFormFields().filter(question => !question.visible);

    hiddenQuestions.forEach(question => {
      const fieldName =
        question.fieldName || (question.customField && question.customField.fieldName);
      const companyField = isCompanyField(question);

      if (fieldName) {
        if (companyField) {
          delete companyDefaultFieldValues[camelCase(fieldName)];
        } else {
          delete defaultFieldValues[camelCase(fieldName)];
          if (fieldName === 'contact_type_id') {
            delete defaultFieldValues.contactTypeOther;
          }
        }
      } else {
        if (!question.customField) {
          return;
        }

        const { arrayKey } = getOptionKeysByKind(question.customField.kind);
        customFieldValues[arrayKey] = [
          ...customFieldValues[arrayKey].filter(
            option =>
              question && question.customField && option.customField.id !== question.customField.id,
          ),
        ];
      }
    });

    return { defaultFieldValues, companyDefaultFieldValues, customFieldValues };
  };

  handleSubmit = () => {
    const { contactForm, onSubmit, updatedAt } = this.props;
    const {
      defaultFieldValues,
      companyDefaultFieldValues,
      customFieldValues,
    } = this.filterAnswersByVisibleQuestions();

    this.validateForm(false).then(() => {
      if (
        Object.keys(this.state.errors).length > 0 ||
        Object.keys(this.state.companyErrors).length > 0
      ) {
        if (this.containerRef.current) {
          this.containerRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
        return;
      }
      this.setState({ loading: true });
      onSubmit(
        defaultFieldValues,
        updatedAt,
        convertCustomFieldValuesToInput(customFieldValues),
        contactForm.enableCompanyFields ? companyDefaultFieldValues : null,
      )
        .then(() => {
          this.setState({ loading: false });
          this.props.onFinishSubmit();
        })
        .catch(error => {
          if (error.message.includes('Email has already been taken')) {
            this.setState(prevState => ({
              errors: {
                ...prevState.errors,
                email: 'Email address already taken',
              },
            }));
          } else {
            showModernMutationError(error);
          }
          this.setState({ loading: false });
        });
    });
  };

  renderCreateButton = (): ?React.Node => {
    const loading = this.state.loading;
    const createCopy = this.props.createCopy;
    const portalPlace = document.getElementById('footerButtonWrapper');
    const button = (
      <StyledButton onClick={this.props.viewMode ? () => {} : this.handleSubmit} loading={loading}>
        {createCopy || 'Confirm'}
      </StyledButton>
    );
    if (createCopy) {
      return <ButtonWrapper>{button}</ButtonWrapper>;
    }
    if (!portalPlace) {
      return null;
    }
    return createPortal(button, portalPlace);
  };

  render() {
    const { org, tz, className, viewMode, lockEmail } = this.props;
    const {
      defaultFieldValues,
      companyDefaultFieldValues,
      customFieldValues,
      errors,
      companyErrors,
    } = this.state;

    const createButton = this.renderCreateButton();

    if (createButton == null && !viewMode) {
      return null;
    }

    const visibleContactFormFields = this.getSortedContactFormFields().filter(
      question => question.visible,
    );

    return (
      <Container ref={this.containerRef} className={className}>
        <NameRow>
          <NameTextField
            defaultValue={defaultFieldValues.firstName}
            onBlur={(e: SyntheticEvent<HTMLInputElement>) =>
              this.handleUpdateSuggestedField(mandatoryQuestions[0], {
                firstName: e.currentTarget.value,
              })
            }
            label={mandatoryQuestions[0].label}
            required
            error={errors.first_name}
            disabled={viewMode}
          />
          <NameTextField
            defaultValue={defaultFieldValues.lastName}
            onBlur={(e: SyntheticEvent<HTMLInputElement>) =>
              this.handleUpdateSuggestedField(mandatoryQuestions[1], {
                lastName: e.currentTarget.value,
              })
            }
            label={mandatoryQuestions[1].label}
            required
            error={errors.last_name}
            disabled={viewMode}
          />
        </NameRow>
        <Row>
          <TextField
            defaultValue={defaultFieldValues.email}
            onBlur={(e: SyntheticEvent<HTMLInputElement>) =>
              this.handleUpdateSuggestedField(mandatoryQuestions[2], {
                email: e.currentTarget.value,
              })
            }
            label={mandatoryQuestions[2].label}
            required
            error={errors.email}
            disabled={viewMode}
            readOnly={lockEmail}
          />
        </Row>
        {visibleContactFormFields.map(question => {
          const helpText = question.helpText && (
            <StyledContent
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(question.helpText) }}
              style={{ marginTop: '12px' }}
            />
          );
          if (
            !question.customField ||
            (question.customField && question.customField.kind === 'DEFAULT')
          ) {
            if (isCompanyField(question)) {
              return (
                <Row key={question.id}>
                  {companySuggestedFields[
                    (question.customField && question.customField.fieldName) ||
                      question.fieldName ||
                      'company_name'
                  ]({
                    company: companyDefaultFieldValues,
                    question,
                    errors: companyErrors,
                    viewerCanUpdate: !viewMode,
                    handleUpdate: changes =>
                      this.handleUpdateCompanySuggestedField(question, changes),
                  })}
                  {helpText}
                </Row>
              );
            }

            const renderer = this.getSuggestedRenderer(question);

            if (!renderer) return null;

            return (
              <Row key={question.id}>
                {renderer({
                  contact: defaultFieldValues,
                  question,
                  errors,
                  viewerCanUpdate: !viewMode,
                  handleUpdate: changes => this.handleUpdateSuggestedField(question, changes),
                })}
                {helpText}
              </Row>
            );
          }

          if (question.customField.kind === 'DEFAULT') {
            return null;
          }

          return (
            <Row key={question.id}>
              {customFields[question.customField.kind]({
                customizable: customFieldValues,
                question,
                errors: { ...errors, ...companyErrors },
                viewerCanUpdate: !viewMode,
                tz,
                currency: org.settings.currency,
                handleUpdate: (input, users) =>
                  this.handleUpdateCustomField(question, input, users),
              })}
              {helpText}
            </Row>
          );
        })}
        {createButton}
      </Container>
    );
  }
}

export default createFragmentContainer(ContactFormSubmission, {
  contactForm: graphql`
    fragment ContactFormSubmission_contactForm on ContactForm {
      id
      enableCompanyFields
      contactFormFields {
        edges {
          node {
            id
            label
            fieldName
            required
            helpText
            order
            rules @include(if: $includeRules) {
              edges {
                node {
                  id
                  action
                  order
                  customSavedTextFilters {
                    edges {
                      node {
                        id
                        order
                        fieldName
                        values
                        operator
                        customField {
                          id
                          fieldName
                          kind
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedTextareaFilters {
                    edges {
                      node {
                        id
                        order
                        fieldName
                        values
                        operator
                        customField {
                          id
                          fieldName
                          kind
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedLinkFilters {
                    edges {
                      node {
                        id
                        order
                        values
                        operator
                        fieldName
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedNumberFilters {
                    edges {
                      node {
                        id
                        order
                        minValue
                        maxValue
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedCurrencyFilters {
                    edges {
                      node {
                        id
                        order
                        minValue
                        maxValue
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedDateFilters {
                    edges {
                      node {
                        id
                        order
                        minValue
                        maxValue
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedBooleanFilters {
                    edges {
                      node {
                        id
                        value
                        order
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedUserMultiselectFilters {
                    edges {
                      node {
                        id
                        order
                        operator
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                        options {
                          edges {
                            node {
                              user {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  customSavedMultiselectFilters {
                    edges {
                      node {
                        id
                        order
                        operator
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                        options {
                          edges {
                            node {
                              option {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            customField {
              id
              kind
              customizableType
              fieldName
              options {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            showOtherOption
            contactFormFieldOptions {
              edges {
                node {
                  customFieldOption {
                    id
                  }
                }
              }
            }
            contactFormFieldUserOptions {
              edges {
                node {
                  user {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  org: graphql`
    fragment ContactFormSubmission_org on Org {
      id
      name
      settings {
        currency
        defaultTag
      }
    }
  `,
  event: graphql`
    fragment ContactFormSubmission_event on Event {
      id
      name
      team {
        id
        name
      }
    }
  `,
});
