/* @flow */
import React from 'react';
import type { RouterHistory } from 'react-router-dom';

import updatedOrgEventView, {
  type OrgEventViewWindow,
} from 'utils/analytics/events/updatedOrgEventView';

import ViewCal from 'images/events/view_cal.svg';
import ViewMap from 'images/events/view_map.svg';
import ViewTable from 'images/events/view_table.svg';
import ViewModes from 'components/ViewModes';

export type EventViewMode = 'calendar' | 'table' | 'map';

export const viewModes = [
  { svg: ViewTable, value: 'table', label: 'Table' },
  { svg: ViewCal, value: 'calendar', label: 'Calendar' },
  { svg: ViewMap, value: 'map', label: 'Map' },
];

export default class AllEventsViewModes extends React.Component<{
  viewMode: EventViewMode,
  pathPrefix: string,
  history: RouterHistory,
  fromWindow: OrgEventViewWindow,
}> {
  handleViewModeChange = (mode: string) => {
    const pathPrefix = this.props.pathPrefix;

    if (mode === 'calendar') {
      this.props.history.push({
        pathname: `${pathPrefix}/calendar`,
        search: this.props.history.location.search,
      });
    } else if (mode === 'map') {
      this.props.history.push({
        pathname: `${pathPrefix}/map`,
        search: this.props.history.location.search,
      });
    } else {
      this.props.history.push({
        pathname: pathPrefix,
        search: this.props.history.location.search,
      });
    }
    updatedOrgEventView(this.props.fromWindow);
  };

  render() {
    return (
      <ViewModes
        viewMode={this.props.viewMode}
        modes={viewModes}
        onViewModeChange={this.handleViewModeChange}
      />
    );
  }
}
