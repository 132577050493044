/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type EventBriefAccessibility = "DISABLED" | "MEMBERS" | "PUBLIC" | "SSO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TemplateSharing_org$ref: FragmentReference;
export type TemplateSharing_org = {|
  +name: string,
  +hasSamlProvider: boolean,
  +briefTemplate: {|
    +id: string,
    +accessibility: EventBriefAccessibility,
  |},
  +$refType: TemplateSharing_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TemplateSharing_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasSamlProvider",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "briefTemplate",
      "storageKey": null,
      "args": null,
      "concreteType": "BriefTemplate",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "accessibility",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0c9870af113896e0bfe5e05469d404f6';
module.exports = node;
