/* @flow */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Quarters = styled.div`
  display: flex;
  padding: 0 4px;
  margin-top: 13px;
`;

const Quarter = styled(NavLink)`
  flex: 0 70px;
  width: 70px;
  height: 30px;
  margin-right: 2px;
  line-height: 30px;
  color: ${props => props.theme.infoMenuColor};
  border-radius: 17px;
  text-align: center;
  &:hover {
    background-color: #f2fbff;
  }
  &.active {
    font-weight: 500;
    color: ${props => props.theme.infoMenuHoverColor};
    background-color: #f2fbff;
  }
`;

const QuarterPanel = (props: { year: number }) => {
  return (
    <React.Fragment>
      <Quarters>
        {[...Array(4).keys()].map(key => (
          <Quarter key={key} to={`/settings/budgets/Q${key + 1}_${props.year}`}>
            Q{key + 1}
          </Quarter>
        ))}
      </Quarters>
    </React.Fragment>
  );
};

export default QuarterPanel;
