/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { EventRequestFormsIsDraftColumn_requestForm } from './__generated__/EventRequestFormsIsDraftColumn_requestForm.graphql';

class EventRequestFormsSubmissionsColumn extends React.PureComponent<{
  requestForm: EventRequestFormsIsDraftColumn_requestForm,
}> {
  render() {
    return this.props.requestForm.isDraft ? 'Draft' : 'Published';
  }
}

export default createFragmentContainer(
  EventRequestFormsSubmissionsColumn,
  graphql`
    fragment EventRequestFormsIsDraftColumn_requestForm on EventRequestForm {
      isDraft
    }
  `,
);
