/**
 * @flow
 * @relayHash d0c4ea94bd619773d3776dc66dc56a28
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AllEventsFiltersQueryVariables = {||};
export type AllEventsFiltersQueryResponse = {|
  +me: {|
    +teams: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
        |}
      |}>
    |}
  |},
  +org: {|
    +settings: {|
      +fiscalYearStart: number
    |},
    +eventRequestForms: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
        |}
      |}>
    |},
  |},
|};
export type AllEventsFiltersQuery = {|
  variables: AllEventsFiltersQueryVariables,
  response: AllEventsFiltersQueryResponse,
|};
*/


/*
query AllEventsFiltersQuery {
  me {
    teams {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
  org {
    settings {
      fiscalYearStart
      id
    }
    eventRequestForms {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  v0,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "teams",
  "storageKey": null,
  "args": null,
  "concreteType": "TeamRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TeamRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Team",
          "plural": false,
          "selections": v1
        }
      ]
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fiscalYearStart",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "eventRequestForms",
  "storageKey": null,
  "args": null,
  "concreteType": "EventRequestFormRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestFormRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "EventRequestForm",
          "plural": false,
          "selections": v1
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AllEventsFiltersQuery",
  "id": null,
  "text": "query AllEventsFiltersQuery {\n  me {\n    teams {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n  org {\n    settings {\n      fiscalYearStart\n      id\n    }\n    eventRequestForms {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AllEventsFiltersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v2
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              v3
            ]
          },
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AllEventsFiltersQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v2,
          v0
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              v3,
              v0
            ]
          },
          v4,
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bddd47f9ba7d15bd608b7dfe5170c7e8';
module.exports = node;
