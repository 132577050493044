/* @flow */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FeatureAccessContext from 'contexts/FeatureAccess';

import InformationSettingsQuery from './Settings/InformationSettingsQuery';

export default class InformationRoutes extends React.Component<{
  pathPrefix: string,
}> {
  static contextType = FeatureAccessContext;

  render() {
    const { pathPrefix } = this.props;

    return (
      <Switch>
        <Route
          path={`${pathPrefix}/floorPlan`}
          exact
          render={props => <InformationSettingsQuery {...props} componentName="FloorPlan" />}
        />
        {this.context.legacyFeatures && (
          <Route
            path={`${pathPrefix}/crm`}
            exact
            render={props => <InformationSettingsQuery {...props} componentName="CRM" />}
          />
        )}
        <Route
          path={`${pathPrefix}/team`}
          exact
          render={props => <InformationSettingsQuery {...props} componentName="Team" />}
        />
        <Route
          path={`${pathPrefix}/:section_id?`}
          exact
          render={props => <InformationSettingsQuery {...props} componentName="Custom" />}
        />
      </Switch>
    );
  }
}
