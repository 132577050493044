/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';
import styled from 'styled-components';

import ExpenseForm from './ExpenseForm';
import Payments from './Payments';

import type { ExpensePageWrapper_expense } from './__generated__/ExpensePageWrapper_expense.graphql';
import type { ExpensePageWrapper_org } from './__generated__/ExpensePageWrapper_org.graphql';

const ExpenseContainer = styled.div`
  padding: 0 20px;
  border-bottom: 1px solid #d8d8d8;
`;

const PaymentsContainer = styled.div`
  padding: 0 20px;
`;

class ExpensePageWrapper extends React.Component<{
  expense: ExpensePageWrapper_expense,
  org: ExpensePageWrapper_org,
  location: Location,
}> {
  componentDidMount() {
    window.requestAnimationFrame(() => {
      const highlightElement: ?HTMLElement = this.props.location.hash
        ? document.querySelector(this.props.location.hash)
        : null;

      if (!highlightElement) return;

      highlightElement.scrollIntoView({ behavior: 'smooth' });
      highlightElement.style.backgroundColor = '#fff7e8';
      highlightElement.style.boxShadow = 'inset 0 0 1px #FFB634';

      window.requestAnimationFrame(() => {
        highlightElement.style.transition = '1s 1s background-color, 1s 1s box-shadow';
        highlightElement.style.backgroundColor = '';
        highlightElement.style.boxShadow = '';
      });
    });
  }

  render() {
    const { expense, org } = this.props;
    return (
      <>
        <ExpenseContainer>
          <ExpenseForm expense={expense} org={org} />
        </ExpenseContainer>
        <PaymentsContainer>
          <Payments expense={expense} org={org} />
        </PaymentsContainer>
      </>
    );
  }
}

export default createFragmentContainer(ExpensePageWrapper, {
  expense: graphql`
    fragment ExpensePageWrapper_expense on Expense {
      ...ExpenseForm_expense
      ...Payments_expense
    }
  `,
  org: graphql`
    fragment ExpensePageWrapper_org on Org {
      ...ExpenseForm_org
      ...Payments_org
    }
  `,
});
