/* @flow */
import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import isValidWebsite from 'utils/validators/isValidWebsite';

const getLinkError = (value: ?string, required?: boolean): ?string => {
  if (!value && required) {
    return 'Required';
  }
  if (value && !isValidWebsite(enforceHttpPrefix(value))) {
    return 'Invalid url';
  }
  return null;
};

export default getLinkError;
