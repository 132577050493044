/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { ImportLogsErrorCountColumn_importLog } from './__generated__/ImportLogsErrorCountColumn_importLog.graphql';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ErrorCount = styled.div`
  font-size: 13px;
  color: #f48183;
`;

const DownloadLink = styled.a`
  font-size: 13px;
  color: #3ba9da;
  &:hover {
    text-decoration: underline;
  }
`;

type Props = {
  importLog: ImportLogsErrorCountColumn_importLog,
};

const ImportLogsErrorCountColumn = ({ importLog }: Props) => {
  if (!importLog.errorsCount) return 0;

  return (
    <Container>
      <ErrorCount>{importLog.errorsCount}</ErrorCount>

      {importLog.errorsFileUrl && (
        <DownloadLink href={importLog.errorsFileUrl} target="_blank" rel="noreferrer noopener">
          Download .csv file
        </DownloadLink>
      )}
    </Container>
  );
};

export default createFragmentContainer(
  ImportLogsErrorCountColumn,
  graphql`
    fragment ImportLogsErrorCountColumn_importLog on ImportLog {
      errorsCount
      errorsFileUrl
    }
  `,
);
