/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefMap_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefHeader_event$ref: FragmentReference;
export type BriefHeader_event = {|
  +virtualLocation?: ?string,
  +primaryLocation?: ?{|
    +id: string,
    +city: string,
    +name: string,
    +country: ?string,
    +state: ?string,
    +lat: number,
    +lng: number,
    +address1: ?string,
  |},
  +zoomLocation?: ?{|
    +joinUrl: string
  |},
  +g2wLocation?: ?{|
    +registrationUrl: string
  |},
  +id: string,
  +name: string,
  +tz: string,
  +team: {|
    +logo: ?string,
    +primaryColor: string,
  |},
  +briefSettings: {|
    +briefTemplate: {|
      +personalizeSchedules: boolean
    |}
  |},
  +startDate?: ?any,
  +endDate?: ?any,
  +startDateAllDay?: ?boolean,
  +endDateAllDay?: ?boolean,
  +website?: ?string,
  +$fragmentRefs: BriefMap_event$ref,
  +$refType: BriefHeader_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "BriefHeader_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeLocation",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeDates",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeWebsite",
      "type": "Boolean"
    }
  ],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "logo",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "primaryColor",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "briefSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "EventBriefTemplate",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "briefTemplate",
          "storageKey": null,
          "args": null,
          "concreteType": "BriefTemplate",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "personalizeSchedules",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeLocation",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "BriefMap_event",
          "args": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "virtualLocation",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "primaryLocation",
          "storageKey": null,
          "args": null,
          "concreteType": "Location",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "city",
              "args": null,
              "storageKey": null
            },
            v1,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "country",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "state",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lat",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lng",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "address1",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "zoomLocation",
          "storageKey": null,
          "args": null,
          "concreteType": "ZoomLocation",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "joinUrl",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "g2wLocation",
          "storageKey": null,
          "args": null,
          "concreteType": "G2WLocation",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "registrationUrl",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeDates",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "startDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "endDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "startDateAllDay",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "endDateAllDay",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeWebsite",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "website",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ed743041de69c47cb7af9d798b577b8b';
module.exports = node;
