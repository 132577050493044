/* @flow */
import styled from 'styled-components';

const TableAction = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 8px 10px 0;
  opacity: 0;

  &:empty {
    visibility: hidden;
  }
`;

export default TableAction;
