/* @flow */

import React from 'react';
import { createRefetchContainer, graphql, type RelayRefetchProp } from 'react-relay';
import styled from 'styled-components';

import theme from 'config/theme';

import CompanyIcon from 'components/Participants/CompanyIcon';
import NoResultsMessage from 'components/NoResultsMessage';
import Checkbox, { Check } from 'components/material/CheckBox';
import { BriefUserEmail } from '../../BriefView/components/BriefUser';
import BoldText from '../../BriefView/components/BoldText';
import AddNewLink from '../AddNewLink';

import CompaniesContainer_event from './__generated__/CompaniesContainer_event.graphql';

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 25px;
  align-items: flex-start;
  ${Check} {
    margin-top: 3px;
    border-radius: 2px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
`;

class CompaniesContainer extends React.Component<{
  relay: RelayRefetchProp,
  event: CompaniesContainer_event,
  selectedCompanies: $ReadOnlyArray<string>,
  searchQuery: string,
  onAllCompanyCheckToggle: (
    isPrevAllCheck: boolean,
    searchedCompanyIds: $ReadOnlyArray<string>,
  ) => void,
  handleCompanyToggle: (attachmentId: string, isPrevChecked: boolean) => void,
}> {
  componentWillUnmount() {
    this.removeTabChangeListener();
  }

  removeTabChangeListener = () => {
    this.companyWindow = null;
    document.removeEventListener('visibilitychange', this.documentFocus);
  };

  documentFocus = () => {
    if (document.visibilityState === 'visible') {
      const {
        relay,
        event: { slug: eventSlug },
        searchQuery: query,
      } = this.props;
      relay.refetch({ eventSlug, query });
    }
  };

  handleAddNewClick = () => {
    const needToAddListener = !this.companyWindow;
    this.companyWindow = window.open(
      `/events/${this.props.event.slug}/contacts/companies`,
      '_blank',
    );
    if (needToAddListener) {
      this.companyWindow.addEventListener('beforeunload', this.removeTabChangeListener);
      document.addEventListener('visibilitychange', this.documentFocus);
    }
  };

  companyWindow: ?EventTarget;

  renderCompanyLabel = company => {
    return (
      <Content>
        <CompanyIcon />
        <div>
          <BoldText>{company.name}</BoldText>
          <BriefUserEmail email={company.email} />
        </div>
      </Content>
    );
  };

  render() {
    const {
      event: { companies },
      selectedCompanies,
      onAllCompanyCheckToggle,
      handleCompanyToggle,
    } = this.props;
    const allSearchedCompanies = companies.edges.map(({ node }) => node);
    const allCompaniesIds = allSearchedCompanies.map(company => company.id);
    const checkAll = allSearchedCompanies.every(company => selectedCompanies.includes(company.id));

    return (
      <React.Fragment>
        <AddNewLink label="Add new company" onClick={this.handleAddNewClick} />
        {allSearchedCompanies.length > 0 ? (
          <React.Fragment>
            <StyledCheckbox
              label="All"
              key={0}
              checked={checkAll}
              checkColor={checkAll ? theme.highlightColor : theme.silver}
              onChange={() => onAllCompanyCheckToggle(checkAll, allCompaniesIds)}
            />
            {allSearchedCompanies.map(company => {
              const checked = selectedCompanies.includes(company.id);
              return (
                <StyledCheckbox
                  key={company.id}
                  checked={checked}
                  checkColor={checked ? theme.highlightColor : theme.silver}
                  labelRenderer={() => this.renderCompanyLabel(company)}
                  onChange={() => handleCompanyToggle(company.id, checked)}
                />
              );
            })}
          </React.Fragment>
        ) : (
          <NoResultsMessage iconName="user-o" message="Company not found." />
        )}
      </React.Fragment>
    );
  }
}

export default createRefetchContainer(
  CompaniesContainer,
  graphql`
    fragment CompaniesContainer_event on Event {
      slug
      companies(filters: $companyFilters) {
        edges {
          node {
            id
            name
            website
            phone
          }
        }
      }
    }
  `,
  graphql`
    query CompaniesContainerRefetchQuery($eventSlug: String!, $companyFilters: CompanyFilters!) {
      event(slug: $eventSlug) {
        id
        ...CompaniesContainer_event
      }
    }
  `,
);
