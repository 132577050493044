/* @flow */
import React from 'react';
import type { RouterHistory } from 'react-router';
import styled from 'styled-components';

import createEventRequestForm from 'graph/mutations/eventRequest/createEventRequestForm';

import Button from 'components/budget/Button';

const Header = styled.div`
  margin-bottom: 15px;
`;

export default class StaffHeader extends React.PureComponent<
  {
    history: RouterHistory,
    currentUrl: string,
  },
  {
    loading: boolean,
  },
> {
  state = { loading: false };

  handleClickCreate = () => {
    this.setState({ loading: true });
    createEventRequestForm().then(data => {
      this.setState({ loading: false });
      if (data.createEventRequestForm) {
        this.props.history.push(
          `/settings/requests/${data.createEventRequestForm.requestFormEdge.node.id}`,
          {
            backUrl: this.props.currentUrl,
          },
        );
      }
    });
  };

  render() {
    return (
      <Header>
        <Button primary onClick={this.handleClickCreate} loading={this.state.loading}>
          Create request form
        </Button>
      </Header>
    );
  }
}
