/* @flow */
import {
  validateHTMLColorHex,
  validateHTMLColorName,
  validateHTMLColorRgb,
  validateHTMLColorSpecialName,
} from 'validate-color';

const validateColor = (color?: string) => {
  return (
    (color && validateHTMLColorHex(color)) ||
    validateHTMLColorName(color) ||
    validateHTMLColorSpecialName(color) ||
    validateHTMLColorRgb(color)
  );
};

export default validateColor;
