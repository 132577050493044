/* @flow */
import type { Field } from './types';

type SalesforceField = {|
  +name: string,
  +label: string,
  +nillable: boolean,
  +maxLength: number,
  +type: string,
  +updateable: boolean,
  +$refType?: any,
  +$fragmentRefs?: any,
|};

export default (
  ourField: Field,
  sfField: ?SalesforceField,
  activeFields: $ReadOnlyArray<string>,
  mappedSfFieldName: ?string,
): $ReadOnlyArray<string> =>
  [
    ...(sfField
      ? [
          !sfField.updateable ? 'Field is not updateable' : null,
          !ourField.types.includes(sfField.type) ? 'Field type is not compatible' : null,
          ourField.nillable && !sfField.nillable
            ? 'Not required field is mapped to a required field in Salesforce'
            : null,
          ourField.longText && sfField.maxLength < 700 ? 'Salesforce field is too short' : null,
          activeFields.includes(sfField.name) ? 'Field is already being synced' : null,
        ]
      : []),
    sfField ? null : `Field "${mappedSfFieldName || ''}" not found in Salesforce`,
  ].filter(Boolean);
