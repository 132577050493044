/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import configureMarketoChannelMapping from 'graph/mutations/integration/configureMarketoChannelMapping';
import showModernMutationError from 'graph/utils/showModernMutationError';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import IntegrationMapping from 'components/integrations/IntegrationsSettings/IntegrationMapping';

import { type MarketoChannelMappingsQueryResponse } from './__generated__/MarketoChannelMappingsQuery.graphql';

type MarketoChannelMappingQueryOrgType = $PropertyType<MarketoChannelMappingsQueryResponse, 'org'>;
type MarketoChannelMappingType = $PropertyType<
  $ElementType<
    $PropertyType<
      $PropertyType<
        $NonMaybeType<$PropertyType<MarketoChannelMappingQueryOrgType, 'marketoAccount'>>,
        'marketoChannelMappings',
      >,
      'edges',
    >,
    0,
  >,
  'node',
>;

const query = graphql`
  query MarketoChannelMappingsQuery {
    org {
      id
      marketoAccount {
        id
        marketoChannels {
          edges {
            node {
              channelId
              applicableProgramType
              name
              progressionStatuses {
                name
              }
            }
          }
        }

        marketoChannelMappings {
          edges {
            node {
              id
              channelId
              progressionStatus
              customFieldOption {
                id
              }
            }
          }
        }
      }

      customFields(
        customizableType: [EVENTCONTACT]
        fieldNames: ["attendance_status_id", "registration_status_id"]
      ) {
        edges {
          node {
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Columns = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 40px;
`;

const ChannelName = styled.div`
  width: 150px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #3c4145;
`;

const Mappings = styled.div`
  flex: 1;
`;

const MappingsHeader = styled.div`
  display: flex;
  padding: 0 18px 12px 0;
`;

const MarketoHeaderTitle = styled.div`
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  color: rgb(60, 65, 69, 0.7);
`;

class MarketoChannelMappings extends React.PureComponent<{}> {
  handleMappingSelect = (
    channelId: string,
    progressionStatusName: string,
    customFieldOptionId: ?string,
    org: MarketoChannelMappingQueryOrgType,
    channelMapping: ?MarketoChannelMappingType,
  ) => {
    if (!org.marketoAccount) return;

    configureMarketoChannelMapping(
      {
        channelId,
        progressionStatus: progressionStatusName,
        customFieldOptionId,
      },
      org.marketoAccount.id,
      channelMapping == null ? undefined : channelMapping.id,
    ).catch(showModernMutationError);
  };

  renderSuccess = (response: MarketoChannelMappingsQueryResponse) => {
    const org = response.org;
    const marketoChannels = org.marketoAccount
      ? org.marketoAccount.marketoChannels.edges.map(edge => edge.node)
      : [];
    const marketoChannelMappings = org.marketoAccount
      ? org.marketoAccount.marketoChannelMappings.edges.map(edge => edge.node)
      : [];
    const customFieldOptions = org.customFields.edges.reduce(
      (array, customFieldEdge) => [
        ...array,
        ...customFieldEdge.node.options.edges.map(
          customFieldOptionEdge => customFieldOptionEdge.node,
        ),
      ],
      [],
    );
    const disabledInfo = '';

    return (
      <>
        {marketoChannels.map(marketoChannel => {
          return (
            <Columns key={marketoChannel.channelId}>
              <ChannelName>{marketoChannel.name}</ChannelName>

              <Mappings>
                <MappingsHeader>
                  <MarketoHeaderTitle>Marketo Status</MarketoHeaderTitle>

                  <MarketoHeaderTitle>Circa Status</MarketoHeaderTitle>
                </MappingsHeader>

                {marketoChannel.progressionStatuses.map(progressionStatus => {
                  if (
                    progressionStatus.name === 'Not in Program' ||
                    (progressionStatus.name === 'Registration Error' &&
                      marketoChannel.applicableProgramType === 'webinar')
                  ) {
                    return null;
                  }

                  const channelMapping = marketoChannelMappings.find(
                    mapping =>
                      mapping.channelId === marketoChannel.channelId &&
                      mapping.progressionStatus === progressionStatus.name,
                  );
                  const channelMappingOptionId =
                    channelMapping && channelMapping.customFieldOption.id;

                  return (
                    <IntegrationMapping
                      key={progressionStatus.name}
                      label={progressionStatus.name}
                      fieldSelectPlaceholder="Select Status"
                      fieldSelectOptions={customFieldOptions.map(option => ({
                        value: option.id,
                        label: option.name,
                        disabled:
                          (!channelMapping || channelMapping.customFieldOption.id !== option.id) &&
                          marketoChannelMappings.some(
                            mapping =>
                              mapping.customFieldOption.id === option.id &&
                              mapping.channelId === marketoChannel.channelId,
                          ),
                        disabledInfo: 'Status is already being synced',
                      }))}
                      fieldSelectValue={channelMappingOptionId}
                      disabledInfo={disabledInfo}
                      onSelect={customFieldOptionId => {
                        this.handleMappingSelect(
                          marketoChannel.channelId,
                          progressionStatus.name,
                          customFieldOptionId,
                          org,
                          channelMapping,
                        );
                      }}
                    />
                  );
                })}
              </Mappings>
            </Columns>
          );
        })}
      </>
    );
  };

  render() {
    return <DefaultQueryRenderer query={query} renderSuccess={this.renderSuccess} />;
  }
}

export default MarketoChannelMappings;
