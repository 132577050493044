/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import difference from 'lodash/difference';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import updateCompany, { type CompanyFieldInput } from 'graph/mutations/company/updateCompany';
import updateCustomFieldValue, {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';
import showModernMutationError from 'graph/utils/showModernMutationError';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table, { type ColumnType } from 'components/budget/Table';
import TablePagination from 'components/budget/Table/TablePagination';
import type {
  CompanyCellPropsType,
  InputVariableFilters,
} from 'components/Companies/companiesTableColumnSettings';
import { type ColumnConfiguration } from 'components/material/table';
import NoResultsMessage from 'components/NoResultsMessage';

import type { OrgCompaniesTablePagination_org } from './__generated__/OrgCompaniesTablePagination_org.graphql';
import type { OrgCompaniesTablePagination_totalCountOrg } from './__generated__/OrgCompaniesTablePagination_totalCountOrg.graphql';

const CompaniesContentContainer = styled.div`
  width: 100%;
`;

type Props = {
  org: ?OrgCompaniesTablePagination_org,
  totalCountOrg: ?OrgCompaniesTablePagination_totalCountOrg,
  relay: RelayPaginationProp,
  shownColumns: ColumnConfiguration,
  filtered: boolean,
  filters: InputVariableFilters,
  totalCount: number,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  tz: string,
  includedColumns: { [string]: boolean },
  onSelectCompanies: (items: $ReadOnlyArray<string | number>) => void,
  selectedCompanies: $ReadOnlyArray<string>,
  setColumnWidthUpdater: (updateColumnWidths: () => void) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onCompaniesListUpdate: (contacts: $ReadOnlyArray<string>) => void,
  handleAllCompaniesSelectedUpdate: (allCompaniesSelected: boolean) => void,
};

type State = {
  refetching: boolean,
  availableCompanies: $ReadOnlyArray<string>,
};

class OrgCompaniesTablePagination extends React.Component<Props, State> {
  state = {
    refetching: false,
    availableCompanies: [],
  };

  paginationDisposable: ?Disposable;

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const prevItems = prevState.availableCompanies;
    const nextItems = nextProps.org
      ? nextProps.org.companies.edges.filter(Boolean).map(({ node }) => node.id)
      : [];
    if (
      nextProps.org &&
      (difference(prevItems, nextItems).length > 0 || difference(nextItems, prevItems).length > 0)
    ) {
      nextProps.onCompaniesListUpdate(nextItems);
      return { availableCompanies: nextItems };
    }
    return null;
  }

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  cellProps = (
    company,
    _group,
    column?: ColumnType<any, any>,
  ): CompanyCellPropsType<
    null,
    ?OrgCompaniesTablePagination_org,
    {|
      +id: string,
      +$fragmentRefs: any,
    |},
  > => {
    const { org, filters, tz, shownColumns } = this.props;
    const columnsLength = shownColumns.length;
    return {
      company,
      event: null,
      customizable: company,
      filters,
      org,
      fieldSettings: column != null ? column.fieldSettings : null,
      isLast:
        column != null && column.fieldSettings != null
          ? shownColumns[columnsLength - 2].fieldSettings &&
            shownColumns[columnsLength - 2].fieldSettings.id === column.fieldSettings.id
          : false,
      onUpdate: (args: $Shape<CompanyFieldInput>): Promise<void> => {
        return updateCompany({ companyId: company.id, ...args, fromWindow: 'org companies' }).catch(
          showModernMutationError,
        );
      },
      onUpdateCustomField: (
        customizable: CustomizableResponse,
        args: UpdateCustomFieldValueInput,
      ): Promise<void> => {
        return updateCustomFieldValue(args, customizable, undefined, 'org companies')
          .catch(showModernMutationError)
          .then(() => undefined);
      },
      tz,
    };
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  keyExtractor = (company: {| +id: string, +$fragmentRefs: any |}) => {
    return company.id;
  };

  onSelectCompanies = (selectedCompanies: $ReadOnlyArray<string | number>) => {
    const shownContactsEdges = this.props.org ? this.props.org.companies.edges.filter(Boolean) : [];
    const totalCount =
      this.props.totalCountOrg && this.props.totalCountOrg.companiesCount
        ? this.props.totalCountOrg.companiesCount.totalCount
        : null;

    this.props.onSelectCompanies(selectedCompanies);
    if (totalCount !== selectedCompanies.length) {
      this.props.handleAllCompaniesSelectedUpdate(
        selectedCompanies.length === shownContactsEdges.length,
      );
    }
  };

  render() {
    const { org, shownColumns, filtered, selectedCompanies, setColumnWidthUpdater } = this.props;

    const optimisticCompaniesEdges = org ? org.companies.edges.filter(Boolean) : [];
    return (
      <CompaniesContentContainer>
        {(!org || optimisticCompaniesEdges.length !== 0) && (
          <Table
            data={org != null ? optimisticCompaniesEdges.map(({ node }) => node) : null}
            columns={shownColumns}
            sort={this.props.sort}
            onChangeSort={this.props.onChangeSort}
            cellProps={this.cellProps}
            keyExtractor={this.keyExtractor}
            selectedItems={selectedCompanies}
            onSelectItems={this.onSelectCompanies}
            setColumnWidthUpdater={setColumnWidthUpdater}
          />
        )}

        {org &&
          optimisticCompaniesEdges.length === 0 &&
          (filtered ? (
            <EmptyView message="No companies match filters" icon={<NoResult />} />
          ) : (
            <NoResultsMessage iconName="user" message="Add the first company" />
          ))}
        {org && optimisticCompaniesEdges.length !== 0 && (
          <TablePagination
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            currentCount={org ? optimisticCompaniesEdges.length : null}
            totalCount={
              this.props.totalCountOrg && this.props.totalCountOrg.companiesCount
                ? this.props.totalCountOrg.companiesCount.totalCount
                : null
            }
            entityName="Company"
          />
        )}
      </CompaniesContentContainer>
    );
  }
}
export default createPaginationContainer(
  OrgCompaniesTablePagination,
  {
    org: graphql`
      fragment OrgCompaniesTablePagination_org on Org {
        salesforceAccount {
          host
        }
        ...CustomizableCurrency_org @include(if: $includeCustomizableCurrency)
        ...CompanyActionCell_org
        companies(first: $count, after: $cursor, filters: $filters)
          @connection(key: "OrgCompaniesTablePagination_companies", filters: []) {
          edges {
            node {
              id
              ...CompanyName_company @include(if: $includeName)
              ...CompanyPhone_company @include(if: $includePhone)
              ...CompanyWebsite_company @include(if: $includeWebsite)
              ...CompanyTwitter_company @include(if: $includeTwitter)
              ...CompanyLinkedin_company @include(if: $includeLinkedin)
              ...CompanyUpdater_company @include(if: $includeUpdatedBy)
              ...CompanyUpdatedAt_company @include(if: $includeUpdatedAt)
              ...CompanyCreator_company @include(if: $includeCreatedBy)
              ...CompanyContactsCount_company @include(if: $includeContactsCount)
              ...CompanyCreatedAt_company @include(if: $includeCreatedAt)
              ...CompanySalesforceId_company @include(if: $includeSalesforceId)
              ...CompanyStreet_company @include(if: $includeStreet)
              ...CompanyCity_company @include(if: $includeCity)
              ...CompanyState_company @include(if: $includeState)
              ...CompanyZip_company @include(if: $includeZip)
              ...CompanyCountry_company @include(if: $includeCountry)
              ...CompanyDescription_company @include(if: $includeDescription)
              ...CustomizableText_customizable @include(if: $includeCustomizableText)
              ...CustomizableTextarea_customizable @include(if: $includeCustomizableTextarea)
              ...CustomizableLink_customizable @include(if: $includeCustomizableLink)
              ...CustomizableDate_customizable @include(if: $includeCustomizableDate)
              ...CustomizableBoolean_customizable @include(if: $includeCustomizableBoolean)
              ...CustomizableNumber_customizable @include(if: $includeCustomizableNumber)
              ...CustomizableCurrency_customizable @include(if: $includeCustomizableCurrency)
              ...CustomizableSelect_customizable @include(if: $includeCustomizableSelect)
              ...CustomizableMultiselect_customizable @include(if: $includeCustomizableMultiselect)
              ...CustomizableUserSelect_customizable @include(if: $includeCustomizableUserSelect)
              ...CustomizableUserMultiselect_customizable
                @include(if: $includeCustomizableUserMultiselect)
              ...CompanyActionCell_company
            }
          }
        }
      }
    `,
    totalCountOrg: graphql`
      fragment OrgCompaniesTablePagination_totalCountOrg on Org {
        companiesCount: companies(filters: $filters) {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.org && props.org.companies;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        count,
        cursor,
      };
    },
    query: graphql`
      query OrgCompaniesTablePaginationQuery(
        $count: Int!
        $cursor: String
        $filters: CompanyFilters!
        $includeName: Boolean!
        $includeWebsite: Boolean!
        $includeStreet: Boolean!
        $includeCity: Boolean!
        $includeState: Boolean!
        $includeZip: Boolean!
        $includeCountry: Boolean!
        $includeTwitter: Boolean!
        $includeLinkedin: Boolean!
        $includeDescription: Boolean!
        $includePhone: Boolean!
        $includeCreatedAt: Boolean!
        $includeCreatedBy: Boolean!
        $includeUpdatedAt: Boolean!
        $includeUpdatedBy: Boolean!
        $includeContactsCount: Boolean!
        $includeSalesforceId: Boolean!
        $includeCustomizableText: Boolean!
        $includeCustomizableTextarea: Boolean!
        $includeCustomizableLink: Boolean!
        $includeCustomizableDate: Boolean!
        $includeCustomizableBoolean: Boolean!
        $includeCustomizableNumber: Boolean!
        $includeCustomizableCurrency: Boolean!
        $includeCustomizableSelect: Boolean!
        $includeCustomizableMultiselect: Boolean!
        $includeCustomizableUserSelect: Boolean!
        $includeCustomizableUserMultiselect: Boolean!
      ) {
        org {
          ...OrgCompaniesTablePagination_org
        }
      }
    `,
  },
);
