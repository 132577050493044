/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import Tag from 'components/Contacts/Tag';
import MaterialAvatar from 'components/material/MaterialAvatar';

import type { UserOptionRow_user } from './__generated__/UserOptionRow_user.graphql';

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  line-height: 1.4;
  cursor: pointer;
  ${props =>
    props.focused &&
    css`
      background: ${props.theme.hoverRowColor};
    `};
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

const Wrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

const Main = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const Secondary = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 13px;
`;

const StyledAvatar = styled(MaterialAvatar)`
  flex: 0 0 auto;
  margin-right: 10px;
`;

const StyledTag = styled(Tag)`
  margin-left: 7px;
`;

class UserOptionRow extends React.PureComponent<{
  user: UserOptionRow_user,
  onSelect: (value: string) => void,
  focused: boolean,
  index: number,
  onHover: number => void,
  inEvent?: boolean,
}> {
  handleClick = () => {
    this.props.onSelect(this.props.user.id);
  };

  handleHover = () => {
    this.props.onHover(this.props.index);
  };

  render() {
    const { user, focused, inEvent } = this.props;
    const userName = fullNameOfUser(user);
    return (
      <Row onClick={this.handleClick} focused={focused} onMouseEnter={this.handleHover}>
        <StyledAvatar user={user} radius={12} />
        <Wrapper title={userName}>
          <Main>
            {userName}
            {inEvent && (
              <StyledTag color="#37B2FF" compact active>
                Member
              </StyledTag>
            )}
          </Main>

          {userName !== user.email && <Secondary>{user.email}</Secondary>}
        </Wrapper>
      </Row>
    );
  }
}

export default createFragmentContainer(
  UserOptionRow,
  graphql`
    fragment UserOptionRow_user on User {
      id
      firstName
      lastName
      email
      ...MaterialAvatar_user
    }
  `,
);
