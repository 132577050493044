/* @flow */
/* eslint-disable no-restricted-globals */

export default function parseCost(costStr: string): ?number {
  const cost = parseFloat(costStr.replace(/,/g, ''));
  if (isNaN(cost)) return null;

  if (cost !== Math.floor(cost)) {
    const dollars = Math.floor(cost);
    const cents = Math.round((cost - dollars) * 100);
    return dollars * 100 + cents;
  }

  return cost * 100;
}
