/* @flow */

import React from 'react';
import { graphql } from 'react-relay';
import { withRouter } from 'react-router';
import type { Match } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import CRM from './CRM';
import CustomSection from './CustomSection';
import FloorPlan from './FloorPlan';
import TransferTeamSection from './TransferTeamSection';

import type { InformationSettingsQueryResponse } from './__generated__/InformationSettingsQuery.graphql';

const query = graphql`
  query InformationSettingsQuery($eventSlug: String!, $sectionId: ID) {
    event(slug: $eventSlug) {
      dbId
      viewerCanDelete
      team {
        name
      }
      ...CustomSection_event
      ...FloorPlan_event
      ...CRM_event
      ...TransferTeamSection_event
    }
    org {
      ...CustomSection_org
      ...CRM_org
    }

    me {
      ...TransferTeamSection_me
      ...CustomSection_user
    }
  }
`;

class InformationSettingsQuery extends React.Component<{
  match: Match,
  componentName: string,
}> {
  render() {
    const eventSlug = this.props.match.params.event_slug;
    const sectionId = this.props.match.params.section_id;
    const componentName = this.props.componentName;

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ eventSlug, sectionId }}
        renderSuccess={(props: InformationSettingsQueryResponse) => {
          switch (componentName) {
            case 'FloorPlan':
              return <FloorPlan event={props.event} />;
            case 'CRM':
              return <CRM event={props.event} org={props.org} />;
            case 'Team':
              return <TransferTeamSection event={props.event} me={props.me} />;
            case 'Custom':
              return (
                <CustomSection
                  event={props.event}
                  org={props.org}
                  sectionId={sectionId}
                  user={props.me}
                />
              );
            default:
              return null;
          }
        }}
      />
    );
  }
}

export default withRouter(InformationSettingsQuery);
