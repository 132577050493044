/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type CellPropsType } from 'components/budget/Table';
import InlineTextField from 'components/budget/Table/InlineTextField';

import { type PaymentCellPropsType } from './index';

import { type PaymentPONumberCell_payment } from './__generated__/PaymentPONumberCell_payment.graphql';

const PaymentPONumberCell = ({
  payment,
  updatePayment,
  updateColumnWidth,
}: CellPropsType<PaymentCellPropsType<PaymentPONumberCell_payment>>) => (
  <InlineTextField
    value={payment.poNumber || ''}
    placeholder="Add PO#"
    onChange={poNumber => {
      updatePayment({ poNumber });

      updateColumnWidth();
    }}
  />
);

export default createFragmentContainer(
  PaymentPONumberCell,
  graphql`
    fragment PaymentPONumberCell_payment on Payment {
      poNumber
    }
  `,
);
