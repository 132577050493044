/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactFormSubmission_event$ref: FragmentReference;
export type ContactFormSubmission_event = {|
  +id: string,
  +name: string,
  +team: {|
    +id: string,
    +name: string,
  |},
  +$refType: ContactFormSubmission_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ContactFormSubmission_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        v0,
        v1
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6cc364e15cf31729a478b5481b5d8093';
module.exports = node;
