/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link } from 'react-router-dom';
import styled from 'styled-components';
import { darken } from 'polished';

import updateTeam from 'graph/mutations/team/updateTeam';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Arrow from 'images/arrow.svg';

import type { TeamHeader_team } from './__generated__/TeamHeader_team.graphql';

const Container = styled.div`
  margin: 0 33px 15px;
`;

const LinkToTeams = styled(Link)`
  display: flex;
  color: #3ba9da;
  font-size: 14px;
  line-height: 12px;
  text-decoration: none;
  &:hover {
    color: ${darken(0.1, '#3ba9da')};
  }
`;

const StyledArrow = styled(Arrow)`
  margin-right: 10px;
  transform: rotate(180deg);
  &:hover {
    color: ${darken(0.1, '#3ba9da')};
  }
`;

const Name = styled.div`
  margin-top: 30px;
  font-size: 20px;
  line-height: 21px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
`;

const StyledInput = styled.input`
  width: 400px;
  margin-top: 30px;
  padding: 0 8px;
  background-color: white;
  border: 1px solid #3baadd;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 500;
  outline: none;
`;

class TeamHeader extends React.PureComponent<
  { team: TeamHeader_team, location: Location },
  { editing: boolean },
> {
  state = {
    editing: false,
  };

  handleEditingToggle = () => {
    this.setState(prevState => ({
      editing: !prevState.editing,
    }));
  };

  handleNameUpdate = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' && e.key !== 'Escape') {
      return;
    }
    if (e.key === 'Enter') {
      const name = e.currentTarget.value;
      if (name.trim() && name !== this.props.team.name) {
        updateTeam(this.props.team.dbId, this.props.team.id, { name }).catch(
          showModernMutationError,
        );
      }
    }
    this.handleEditingToggle();
  };

  handleNameBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    this.handleEditingToggle();
    if (name.trim() && name !== this.props.team.name) {
      updateTeam(this.props.team.dbId, this.props.team.id, { name }).catch(showModernMutationError);
    }
  };

  getBreadcrumbLabel = () => {
    const state = this.props.location.state;
    if (state) {
      return state.prevLabel;
    }
    return 'Teams';
  };

  getBreadcrumbPath = () => {
    const state = this.props.location.state;
    if (state) {
      return state.prevUrl;
    }
    return '/workspace/teams';
  };

  render() {
    const { team } = this.props;
    return (
      <Container>
        <LinkToTeams to={this.getBreadcrumbPath()}>
          <StyledArrow />
          {this.getBreadcrumbLabel()}
        </LinkToTeams>
        {this.state.editing ? (
          <StyledInput
            onKeyDown={this.handleNameUpdate}
            onBlur={this.handleNameBlur}
            defaultValue={team.name}
            autoFocus
          />
        ) : (
          <Name onClick={this.handleEditingToggle}>{team.name}</Name>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(TeamHeader, {
  team: graphql`
    fragment TeamHeader_team on Team {
      id
      dbId
      name
    }
  `,
});
