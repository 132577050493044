/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { defaultTableComponents } from 'components/Opportunities/columns';
import getOpportunityFields from 'components/Opportunities/lib/getOpportunityFields';
import getShownColumns from 'components/Opportunities/lib/getShownColumns';
import parseOpportunitiesFilters from 'components/Opportunities/lib/parseOpportunitiesFilters';
import colsConfig, {
  type InputVariableFilters,
} from 'components/Opportunities/OpportunitiesTableColumnSettings';

import OpportunitiesHeaderBar from './OpportunitiesHeaderBar';
import OpportunitiesTablePagination from './OpportunitiesTablePagination';

import { type OpportunitiesPage_event } from './__generated__/OpportunitiesPage_event.graphql';
import { type OpportunitiesPage_org } from './__generated__/OpportunitiesPage_org.graphql';
import { type OpportunitiesPageQueryResponse } from './__generated__/OpportunitiesPageQuery.graphql';

const query = graphql`
  query OpportunitiesPageQuery(
    $count: Int!
    $cursor: String
    $eventSlug: String!
    $filters: OpportunityFilters!
    $includeAmount: Boolean!
    $includeCompanyId: Boolean!
    $includeDescription: Boolean!
    $includeStatus: Boolean!
    $includeSalesforceId: Boolean!
    $includeOwnerId: Boolean!
    $includeAssociatedContacts: Boolean!
    $includeName: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
  ) {
    event(slug: $eventSlug) {
      id
      ...OpportunitiesTablePagination_event
      ...OpportunitiesTablePagination_totalCountEvent
    }
    org {
      id
      ...OpportunitiesTablePagination_org
    }
  }
`;

class EventCompaniesPage extends React.Component<
  {
    org: OpportunitiesPage_org,
    event: OpportunitiesPage_event,
    history: RouterHistory,
    location: Location,
    tz: string,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
  },
> {
  opportunityFields: $ReadOnlyArray<FieldType> = getOpportunityFields(
    normalizeCustomFields(this.props.org.opportunityCustomFields.edges.map(edge => edge.node)),
    this.props.org.salesforceAccount
      ? this.props.org.salesforceAccount.opportunityRoles.edges.map(({ node }) => ({
          value: node.id,
          label: node.name,
        }))
      : [],
    this.props.org.salesforceAccount
      ? this.props.org.salesforceAccount.opportunityStatuses.edges.map(({ node }) => ({
          value: node.id,
          label: node.name,
        }))
      : [],
  );

  state = {
    shownColumns: getShownColumns('eventOpportunitiesShownColumns'),
  };

  handleOpportunitiesSearch = (search: string) => {
    replaceQueryParams(this.props.history, { search: search || null });
  };

  handleColumnsChange = (shownColumns: $ReadOnlyArray<string>) => {
    storage.set('eventOpportunitiesShownColumns', shownColumns);
    this.setState({ shownColumns });
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  renderTable = (props: {|
    org: ?$PropertyType<OpportunitiesPageQueryResponse, 'org'>,
    event: ?$PropertyType<OpportunitiesPageQueryResponse, 'event'>,
    filtered: boolean,
    filters: InputVariableFilters,
    sort: SortParam,
    includedColumns: { [string]: boolean },
  |}): React.Node => (
    <OpportunitiesTablePagination
      org={props.org}
      event={props.event}
      shownColumns={colsConfig(this.opportunityFields, this.state.shownColumns)}
      filtered={props.filtered}
      totalCountEvent={props.event}
      onChangeSort={this.handleSortChange}
      sort={props.sort}
      tz={this.props.tz}
      includedColumns={props.includedColumns}
    />
  );

  render() {
    const { event, history, location } = this.props;
    const shownColumns = this.state.shownColumns;
    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      this.opportunityFields,
      shownColumns,
    );

    const filters = parseOpportunitiesFilters(location.search, this.opportunityFields);
    const filtered = Object.keys(filters).some(key => key !== 'sort' && !!filters[key]);
    // Default sorting to name field
    const sortFilter = filters.sort || {
      key: 'NAME',
      asc: true,
    };

    const customSortField: ?FieldType = this.opportunityFields.find(
      field => field.id === sortFilter.key,
    );

    const variables = {
      count: 25,
      eventSlug: event.slug,
      filters: {
        sort: customSortField ? 'CUSTOM' : sortFilter.key,
        customFieldSortId: customSortField != null ? customSortField.id : null,
        direction: sortFilter.asc ? 'ASC' : 'DESC',
        names: filters.name,
        amountRange: filters.amount,
        salesforceIds: filters.salesforce_id,
        ownerIds: filters.owner_id,
        statusIds: filters.status,
        search: filters.search,
        associatedContactsIds: filters.associated_contacts,
        associatedContactsRoleIds: filters.associated_contacts_roles,
        customFilters: this.opportunityFields
          .filter(option => option.fieldName == null && filters[option.id] != null)
          .map(option => getCustomFieldVariable(filters[option.id], option)),
      },
      ...includedColumns,
    };

    return (
      <>
        <OpportunitiesHeaderBar
          event={event}
          history={history}
          filters={filters}
          filterInputs={variables.filters}
          opportunityFields={this.opportunityFields}
          search={filters.search}
          shownColumns={shownColumns}
          onSearch={this.handleOpportunitiesSearch}
          onColumnsChange={this.handleColumnsChange}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: OpportunitiesPageQueryResponse) =>
            this.renderTable({
              org: props.org,
              event: props.event,
              filtered,
              filters: variables.filters,
              sort: sortFilter,
              includedColumns,
            })
          }
          renderLoading={() =>
            this.renderTable({
              org: null,
              event: null,
              filtered,
              filters: variables.filters,
              sort: sortFilter,
              includedColumns,
            })
          }
        />
      </>
    );
  }
}

export default createFragmentContainer(EventCompaniesPage, {
  event: graphql`
    fragment OpportunitiesPage_event on Event {
      slug
      ...OpportunitiesHeaderBar_event
    }
  `,
  org: graphql`
    fragment OpportunitiesPage_org on Org {
      salesforceAccount {
        opportunityRoles {
          edges {
            node {
              id
              name
            }
          }
        }
        opportunityStatuses(onlyEnabled: true) {
          edges {
            node {
              id
              name
            }
          }
        }
      }

      opportunityCustomFields: customFields(customizableType: [SALESFORCEOPPORTUNITY]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
});
