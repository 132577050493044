/* @flow */
import moment from 'moment-timezone';

export default function getYearStart(startMonth: number, date: moment = moment()): moment {
  if (startMonth > date.month()) {
    return date
      .clone()
      .subtract(1, 'year')
      .month(startMonth)
      .startOf('month');
  }
  return date
    .clone()
    .month(startMonth)
    .startOf('month');
}
