/**
 * @flow
 * @relayHash 17518250d0872347a9236dc60b287063
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type CreateEventInput = {
  clientMutationId?: ?string,
  teamId: string,
  name: string,
  state?: ?number,
  status?: ?EventStatus,
  leadId?: ?string,
  website?: ?string,
  startDate?: ?any,
  startDateAllDay?: ?boolean,
  endDate?: ?any,
  endDateAllDay?: ?boolean,
  templateId?: ?string,
  copyTimeLine?: ?boolean,
  location?: ?LocationInput,
  fromWindow?: ?string,
  templateType?: ?string,
};
export type LocationInput = {
  name: string,
  city: string,
  state?: ?string,
  address1?: ?string,
  address2?: ?string,
  country?: ?string,
  postal?: ?string,
  note?: ?string,
  website?: ?string,
  timezone?: ?string,
  lat?: ?number,
  lng?: ?number,
};
export type createEventMutationVariables = {|
  input: CreateEventInput
|};
export type createEventMutationResponse = {|
  +createEvent: ?{|
    +eventEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +dbId: number,
        +name: string,
        +commitmentLevel: EventStatus,
        +slug: string,
      |},
    |}
  |}
|};
export type createEventMutation = {|
  variables: createEventMutationVariables,
  response: createEventMutationResponse,
|};
*/


/*
mutation createEventMutation(
  $input: CreateEventInput!
) {
  createEvent(input: $input) {
    eventEdge {
      __typename
      node {
        id
        dbId
        name
        commitmentLevel
        slug
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createEvent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateEventInput!"
      }
    ],
    "concreteType": "CreateEventPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "EventRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dbId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "commitmentLevel",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "slug",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createEventMutation",
  "id": null,
  "text": "mutation createEventMutation(\n  $input: CreateEventInput!\n) {\n  createEvent(input: $input) {\n    eventEdge {\n      __typename\n      node {\n        id\n        dbId\n        name\n        commitmentLevel\n        slug\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createEventMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "createEventMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e001105a9f240229b462696e915f0f9c';
module.exports = node;
