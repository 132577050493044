/* @flow */
import type { Location } from 'react-router-dom';

import type { FieldType } from 'utils/customization/types';

import type {
  BudgetReportingChildGroupType,
  BudgetReportParentGroupType,
  BudgetReportTotalsType,
} from '../../BudgetReportingGroupContainer';
import { type GroupingOptionType, getGroupOptionLabel } from '../../lib/reportGroupingOptions';
import ReportingActualAmountCell from './ReportingActualAmountCell';
import ReportingActualPercentCell from './ReportingActualPercentCell';
import ReportingBudgetedAmountCell from './ReportingBudgetedAmountCell';
import ReportingGroup1Cell from './ReportingGroup1Cell';
import ReportingGroup2Cell from './ReportingGroup2Cell';
import ReportingPaidAmountCell from './ReportingPaidAmountCell';
import ReportingPaidPercentCell from './ReportingPaidPercentCell';
import ReportingPlannedAmountCell from './ReportingPlannedAmountCell';
import ReportingPlannedPercentCell from './ReportingPlannedPercentCell';
import ReportingTotalActualAmountCell from './ReportingTotalActualAmountCell';
import ReportingTotalActualPercentCell from './ReportingTotalActualPercentCell';
import ReportingTotalBudgetedAmountCell from './ReportingTotalBudgetedAmountCell';
import ReportingTotalPaidAmountCell from './ReportingTotalPaidAmountCell';
import ReportingTotalPaidPercentCell from './ReportingTotalPaidPercentCell';
import ReportingTotalPlannedAmountCell from './ReportingTotalPlannedAmountCell';
import ReportingTotalPlannedPercentCell from './ReportingTotalPlannedPercentCell';

export type ReportingFooterCellPropsType = {|
  totals: BudgetReportTotalsType,
  orgCurrency: string,
|};

export type ReportingCellPropsType = {|
  group1: ?GroupingOptionType,
  group2: ?GroupingOptionType,
  customGroup1: ?string,
  customGroup2: ?string,
  group: BudgetReportParentGroupType,
  childGroup?: ?BudgetReportingChildGroupType,
  orgCurrency: string,
  location?: ?Location,
|};

const getColsConfig = (
  group1: ?GroupingOptionType,
  group2: ?GroupingOptionType,
  customGroup1: ?string,
  customGroup2: ?string,
  eventFields: $ReadOnlyArray<FieldType>,
  showBudgeting: boolean = true,
  location?: ?Location,
) => {
  const group1Label = getGroupOptionLabel(group1 || customGroup1, eventFields);
  return [
    {
      title: group1Label,
      sortKey: 'NAME',
      component: ReportingGroup1Cell,
      footerComponent: () => 'Total',
      grow: 1,
      location,
    },
    group2 != null || customGroup2 != null
      ? {
          title: getGroupOptionLabel(group2 || customGroup2, eventFields),
          sortKey: 'NAME',
          component: ReportingGroup2Cell,
          grow: 1,
          location,
        }
      : null,
    showBudgeting
      ? {
          title: 'Team Budget',
          sortKey: 'BUDGET_AMOUNT',
          component: ReportingBudgetedAmountCell,
          footerComponent: ReportingTotalBudgetedAmountCell,
          align: 'right',
          width: 160,
        }
      : null,
    {
      title: 'Planned',
      sortKey: 'PLANNED_AMOUNT',
      component: ReportingPlannedAmountCell,
      footerComponent: ReportingTotalPlannedAmountCell,
      align: 'right',
      width: 160,
    },
    showBudgeting
      ? {
          title: 'Actual / Team Budget',
          sortKey: 'ACTUAL_TEAM_BUDGET',
          component: ReportingPlannedPercentCell,
          footerComponent: ReportingTotalPlannedPercentCell,
          align: 'right',
          width: 200,
        }
      : null,
    {
      title: 'Actual',
      sortKey: 'ACTUAL_AMOUNT',
      component: ReportingActualAmountCell,
      footerComponent: ReportingTotalActualAmountCell,
      align: 'right',
      width: 160,
    },
    {
      title: 'Actual / Planned',
      sortKey: 'ACTUAL_PLANNED',
      component: ReportingActualPercentCell,
      footerComponent: ReportingTotalActualPercentCell,
      align: 'right',
      width: 160,
    },
    {
      title: 'Paid',
      sortKey: 'PAID_AMOUNT',
      component: ReportingPaidAmountCell,
      footerComponent: ReportingTotalPaidAmountCell,
      align: 'right',
      width: 160,
    },
    {
      title: 'Paid / Actual',
      sortKey: 'PAID_ACTUAL',
      component: ReportingPaidPercentCell,
      footerComponent: ReportingTotalPaidPercentCell,
      align: 'right',
      width: 160,
    },
  ].filter(Boolean);
};

export default getColsConfig;
