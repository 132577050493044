/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorDescription_vendor$ref: FragmentReference;
export type VendorDescription_vendor = {|
  +description: ?string,
  +$refType: VendorDescription_vendor$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VendorDescription_vendor",
  "type": "Vendor",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a226f9b76ccda58e69baffd45b0cfedc';
module.exports = node;
