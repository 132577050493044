/* @flow */
import { getAccessToken } from 'utils/Auth';

export type RateType = { [string]: number };

type CurrencyRatesResponseType = {|
  base: string,
  date: string,
  rates: RateType,
  success: boolean,
  timestamp: number,
|};

export default function getCurrencyRates(): Promise<CurrencyRatesResponseType> {
  return fetch(`/v1/currency_rates`, {
    credentials: 'same-origin',
    headers: {
      Authorization: getAccessToken(),
    },
  }).then(response => {
    if (response.ok) return response.json();
    return response.json().then(body => Promise.reject(new Error(body.error)));
  });
}
