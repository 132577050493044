/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type updateVendorMutationVariables } from './__generated__/updateVendorMutation.graphql';

const mutation = graphql`
  mutation updateVendorMutation($input: UpdateVendorInput!) {
    updateVendor(input: $input) {
      vendor {
        id
        name
        firstName
        lastName
        website
        email
        phone1
        phone2
        companyPhone
        title
        twitter
        linkedin
        description
        country
        state
        city
        zip
        street
      }
    }
  }
`;

export type VendorFieldInput = $Exact<$PropertyType<updateVendorMutationVariables, 'input'>>;

export default function updateVendor(input: VendorFieldInput) {
  const { vendorId, eventId, fromWindow, ...rest } = input;
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    optimisticResponse: {
      updateVendor: {
        vendor: { id: vendorId, ...rest },
      },
    },
  });
}
