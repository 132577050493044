/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type DeliverableActionCell_org$ref = any;
type DeliverableTags_org$ref = any;
type DeliverableTitle_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyTasksPagination_org$ref: FragmentReference;
export type MyTasksPagination_org = {|
  +$fragmentRefs: DeliverableTags_org$ref & DeliverableTitle_org$ref & DeliverableActionCell_org$ref,
  +$refType: MyTasksPagination_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MyTasksPagination_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "DeliverableTags_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DeliverableTitle_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DeliverableActionCell_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1b36e3c7fff7cfb38ae786c27ce1ca93';
module.exports = node;
