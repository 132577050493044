/* @flow */
import React from 'react';
import styled from 'styled-components';

import Button, { MinimalButton } from 'components/budget/Button';

const Container = styled.div`
  flex: 1 1 auto;
`;

const Actions = styled.div`
  display: flex;
  justify-content: ${props => (props.isConnected ? 'space-between' : 'center')};
`;

const LeftButton = styled(Button)`
  margin-right: 10px;
`;

const ChangeButton = styled(MinimalButton)`
  color: rgb(59, 169, 218);
`;

const DisconnectButton = styled(Button)`
  align-self: flex-end;
  margin-right: 30px;
`;

type Props = {
  onClickConnectProd: () => void,
  onClickConnectSandbox?: () => void,
  onClickDisconnect: () => void,
  error?: ?string,
  isConnected: boolean,
  accountIdentifier: ?string,
  serviceName: string,
  submitting: boolean,
  editing: boolean,
  sandbox: boolean,
  onStartEditing: () => void,
  onCancelEditing: () => void,
};

const IntegrationConnectionBar = ({
  isConnected,
  editing,
  submitting,
  sandbox,
  serviceName,
  accountIdentifier,
  onStartEditing,
  onClickConnectProd,
  onClickConnectSandbox,
  onCancelEditing,
  onClickDisconnect,
  error,
}: Props) => {
  const actionName = isConnected ? 'Update' : 'Connect';

  return (
    <Container>
      {error ? <div>Error: {error}</div> : null}
      {isConnected && !editing ? (
        <div>
          <span>
            Connected to {serviceName} as {accountIdentifier}
          </span>{' '}
          <ChangeButton label="Change" onClick={onStartEditing} />
        </div>
      ) : (
        <Actions isConnected={isConnected}>
          <div>
            <LeftButton
              primary
              onClick={onClickConnectProd}
              disabled={submitting}
              loading={!sandbox && submitting}
            >
              {actionName} Account
            </LeftButton>
            {onClickConnectSandbox && (
              <LeftButton
                variant="cancel"
                onClick={onClickConnectSandbox}
                disabled={submitting}
                loading={sandbox && submitting}
              >
                {actionName} Sandbox
              </LeftButton>
            )}
            {editing && <ChangeButton label="Cancel" onClick={onCancelEditing} />}
          </div>
          {isConnected && (
            <DisconnectButton variant="danger" onClick={onClickDisconnect} disabled={submitting}>
              Disconnect
            </DisconnectButton>
          )}
        </Actions>
      )}
    </Container>
  );
};

export default IntegrationConnectionBar;
