/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import type {
  updateScheduleItemMutationVariables,
  updateScheduleItemMutationResponse,
} from './__generated__/updateScheduleItemMutation.graphql';

const mutation = graphql`
  mutation updateScheduleItemMutation($input: UpdateItemInScheduleDayInput!) {
    updateItemInScheduleDay(input: $input) {
      scheduleItem {
        id
        start_time
        end_time
        title
        description
        location
        all_day
      }
    }
  }
`;

export default function updateScheduleItem(
  scheduleItemId: string,
  args: {|
    startTime?: string,
    endTime?: ?string,
    title?: ?string,
    description?: ?string,
    location?: ?string,
    allDay?: boolean,
  |},
): Promise<updateScheduleItemMutationResponse> {
  const variables: updateScheduleItemMutationVariables = {
    input: {
      scheduleItemId,
      ...args,
    },
  };
  const optimisticResponse = {
    updateItemInScheduleDay: {
      scheduleItem: {
        id: scheduleItemId,
        ...args,
      },
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
