/* @flow */
import React from 'react';
import styled from 'styled-components';

import Window, {
  WindowHeader,
  WindowClose,
  WindowTitle,
  WindowContent,
} from 'components/material/Window';
import Button from 'components/material/Button';
import CheckBox, { Label as CheckBoxLabel } from 'components/material/CheckBox';

const Root = styled.div`
  font-size: 13px;
  color: #4a5665;

  ${WindowHeader} {
    padding: 18px 35px 8px 25px;
  }

  ${WindowTitle} {
    padding: 0;
    text-align: left;
    font-size: 14px;
    color: inherit;
  }

  ${WindowContent} {
    padding: 0 25px 21px;
  }

  ${WindowClose} {
    top: 15px;
    right: 15px;
    font-size: 34px;
  }

  ${CheckBoxLabel} {
    margin-left: 10px;
  }
`;

const Content = styled.div`
  padding: 30px 0;

  > * + * {
    margin-top: 12px;
  }
`;

const Footer = styled.div`
  text-align: right;

  > *:last-child {
    margin-left: 20px;
  }
`;

type Props<V> = {|
  title: string,
  subtitle: string,
  options: $ReadOnlyArray<{ label: string, value: V, checked: boolean }>,
  onChange: (value: V, checked: boolean) => void,
  onDownload: () => void,
  onCancel: () => void,
|};

const CsvDownloadWindow = <V: string | number>({
  title,
  subtitle,
  options,
  onChange,
  onDownload,
  onCancel,
}: Props<V>) => {
  return (
    <Root>
      <Window size={417} onHide={onCancel}>
        <WindowHeader>
          <WindowTitle>{title}</WindowTitle>
          <WindowClose onClick={onCancel} />
        </WindowHeader>

        <WindowContent>
          {subtitle}

          <Content>
            {options.map(option => (
              <CheckBox
                key={option.value}
                label={option.label}
                checked={option.checked}
                checkColor={option.checked ? null : '#cdd1d4'}
                onChange={() => {
                  onChange(option.value, !option.checked);
                }}
              />
            ))}
          </Content>

          <Footer>
            <Button label="Cancel" onClick={onCancel} minimal />
            <Button label="Download" onClick={onDownload} primary />
          </Footer>
        </WindowContent>
      </Window>
    </Root>
  );
};

export default CsvDownloadWindow;
