/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TaskItemAssignee_user$ref = any;
export type TaskStatus = "COMPLETED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubtaskItem_subtask$ref: FragmentReference;
export type SubtaskItem_subtask = {|
  +name?: string,
  +status?: TaskStatus,
  +dueDate?: ?any,
  +dueDateAllDay?: ?boolean,
  +assignees?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: TaskItemAssignee_user$ref,
      |}
    |}>
  |},
  +$refType: SubtaskItem_subtask$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SubtaskItem_subtask",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeTaskTitle",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTaskStatus",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTaskDueDate",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeTaskAssignees",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskTitle",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskStatus",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskDueDate",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "dueDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "dueDateAllDay",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeTaskAssignees",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "assignees",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "UserRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "User",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "id",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "TaskItemAssignee_user",
                      "args": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2c53f2dec7b5618b38c4640faea75265';
module.exports = node;
