import PropTypes from 'prop-types';
import React from 'react';
import sortBy from 'lodash/sortBy';

import SchedulesTimelineColumn from './SchedulesTimelineColumn';

import { createMoment } from 'utils/Date';

const HOUR_HEIGHT = 90;

export default class SchedulesTimelineDay extends React.Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
    time_zone: PropTypes.string.isRequired,
    days: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      scheduleItems: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.shape({
          node: PropTypes.shape({
            start_time: PropTypes.string.isRequired,
            end_time: PropTypes.string,
            all_day: PropTypes.bool,
          }).isRequired,
        })).isRequired,
      }).isRequired,
    })).isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  };
  genereateTimes = (start_time, end_time) => {
    const moment = createMoment(this.props.time_zone);
    const start = moment(start_time);
    const end = moment(end_time);

    const times = [];
    for (const date = start.clone(); date.isBefore(end); date.add(30, 'minute')) {
      times.push(date.format('h:mm a'));
    }
    return times;
  }
  render() {
    const { date, time_zone, days, colors } = this.props;

    const moment = createMoment(time_zone);
    const dateMoment = moment(date);

    let start_time = null;
    let end_time = null;
    let all_day_count = 0;

    const daysItems = days.map(day =>
      day && sortBy(day.scheduleItems.edges.map(x => x.node), [
        s => moment(s.start_time).getTime(), s => (s.end_time ? moment(s.end_time).getTime() : 0),
      ]),
    );

    if (daysItems.every(items => !items || items.length === 0)) return <div />;

    daysItems
      .filter(Boolean)
      .map(items => items.filter(item => !item.all_day))
      .forEach(items => {
        if (items.length === 0) return;
        const first = items[0].start_time;
        const last = items[items.length - 1].end_time || items[items.length - 1].start_time;
        if (!start_time || moment(first).getTime() < moment(start_time).getTime()) {
          start_time = first;
        }
        if (!end_time || moment(last).getTime() > moment(end_time).getTime()) end_time = last;
      });

    if (start_time) {
      start_time = moment(start_time).year(dateMoment.year())
        .month(dateMoment.month()).date(dateMoment.date()).startOf('hour').toDate();
    }
    if (end_time) {
      end_time = moment(end_time).year(dateMoment.year()).month(dateMoment.month())
        .date(dateMoment.date()).add(30, 'minute').toDate();
    }
    all_day_count = Math.max(...daysItems.map(items =>
      (items ? items.filter(item => item.all_day).length : 0)
    ));

    const times = start_time && end_time && this.genereateTimes(start_time, end_time);

    return (
      <div className="schedules-timeline-day">
        <div className="schedules-timeline-header">
          <aside>
            {dateMoment.renderDateTime(true, { shorterMonth: true })}
          </aside>
          {days.map((day, index) => <div key={index}>{day && day.title}</div>)}
        </div>
        <div className="schedules-timeline-body">
          <aside className="schedules-timeline-times">
            {all_day_count > 0 &&
              <p style={{ height: `${all_day_count * (HOUR_HEIGHT / 2)}px` }}>All Day</p>
            }
            {times && times.map(time => <p key={time}>{time}</p>)}
          </aside>
          {daysItems.map((items, index) =>
            (items ?
              <SchedulesTimelineColumn
                items={items}
                key={index}
                time_zone={time_zone}
                color={colors[index]}
                start_time={start_time && start_time.toISOString()}
                all_day_count={all_day_count}
                hour_height={HOUR_HEIGHT}
              />
            :
              <div key={index} />
            )
          )}
        </div>
      </div>
    );
  }
}
