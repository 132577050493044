/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import MembersPage from './MembersPage';

import type { MembersQueryResponse } from './__generated__/MembersQuery.graphql';

const query = graphql`
  query MembersQuery {
    org {
      id
      ...MembersPage_org
    }
  }
`;

export default class Members extends React.PureComponent<{
  location: Location,
  history: RouterHistory,
}> {
  render() {
    const { location, history } = this.props;
    return (
      <DefaultQueryRenderer
        query={query}
        renderSuccess={(props: MembersQueryResponse) => (
          <MembersPage org={props.org} history={history} location={location} />
        )}
      />
    );
  }
}
