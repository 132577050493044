/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import formatDateTimeISO from 'utils/date/formatDateTimeISO';

import { type CellPropsType } from 'components/budget/Table';

import { type EventCellPropsType } from './index';

import type { EventAttendanceJoinTime_contactEvent } from './__generated__/EventAttendanceJoinTime_contactEvent.graphql';

const EventAttendanceJoinTime = ({
  contactEvent,
}: CellPropsType<EventCellPropsType<EventAttendanceJoinTime_contactEvent>>) => {
  const joinTime =
    (contactEvent.zoomRegistrant && (contactEvent.zoomRegistrant.times[0] || {}).startTime) ||
    (contactEvent.g2wRegistrant && (contactEvent.g2wRegistrant.times[0] || {}).startTime);

  if (!joinTime) return null;

  return formatDateTimeISO(moment(joinTime).tz(contactEvent.node.tz));
};

export default createFragmentContainer(
  EventAttendanceJoinTime,
  graphql`
    fragment EventAttendanceJoinTime_contactEvent on ContactEventEdge {
      zoomRegistrant {
        times {
          startTime
          endTime
        }
      }
      g2wRegistrant {
        times {
          startTime
          endTime
        }
      }
      node {
        tz
      }
    }
  `,
);
