/* @flow */
import * as React from 'react';
import {
  DragSource,
  DropTarget,
  type ConnectDragSource,
  type ConnectDropTarget,
  type ConnectDragPreview,
} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styled from 'styled-components';

import TasksFolder from 'components/Tasks/TasksFolder';

const DragContainer = styled.div`
  ${props =>
    props.isDragging &&
    `
    ${TasksFolder} {
      visibility: hidden;
      opacity: 0;
    }
  `};
`;

class DraggableFolder extends React.PureComponent<{
  // Dragging
  connectDragSource: ConnectDragSource,
  connectDragPreview: ConnectDragPreview,
  isDragging: boolean,
  //
  // // Dropping
  connectDropTarget: ConnectDropTarget,

  // External
  children: React.Node,
  disabled: boolean,
  // Used in DnD handler only
  folderId: string,
  folderOrder: number,
  folderName: string,
  onMoveStart: (string, number) => void,
  onMove: (hoverOrder: number) => void,
  onMoveEnd: () => void,
}> {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  render() {
    const { connectDragSource, connectDropTarget } = this.props;

    if (this.props.disabled) return this.props.children;

    return connectDropTarget(
      <div>
        {connectDragSource(
          <div>
            <DragContainer isDragging={this.props.isDragging}>{this.props.children}</DragContainer>
          </div>,
        )}
      </div>,
    );
  }
}

const folderSource = {
  beginDrag(props) {
    props.onMoveStart(props.folderId, props.folderOrder);
    return {
      folderId: props.folderId,
      folderOrder: props.folderOrder,
      name: props.folderName,
    };
  },
  endDrag(props) {
    props.onMoveEnd();
  },
};

function draggableCollect(connect, monitor): {
  connectDragSource: ConnectDragSource,
  connectDragPreview: ConnectDragPreview,
  isDragging: boolean,
} {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const Draggable = DragSource(
  'FOLDER',
  folderSource,
  draggableCollect,
)(DraggableFolder);


const folderTarget = {
  hover(props, monitor) {
    const hoverFolderId = props.folderId;
    const folderId = monitor.getItem().folderId;

    const differentItem = folderId !== hoverFolderId;
    if (differentItem) {
      props.onMove(props.folderOrder);
    }
  },
};

function droppableCollect(connect): {
  connectDropTarget: ConnectDropTarget,
} {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}
const Droppable = DropTarget('FOLDER', folderTarget, droppableCollect)(Draggable);

export default Droppable;
