/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment-timezone';

import type { SyncStatusIndicator_status } from 'components/integrations/__generated__/SyncStatusIndicator_status.graphql';
import Tooltip from 'components/material/Tooltip';

const Box = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 3px 10px;
  cursor: default;
  align-self: flex-start;
  font-size: 12px;
  background: #fff;
`;

const Icon = styled.i`
  color: ${props => props.color};
  margin-right: 5px;
`;

class SyncStatusIndicator extends React.PureComponent<{
  status: SyncStatusIndicator_status,
  label?: string,
  className?: ?string,
}> {
  iconColor = () => {
    if (this.props.status.state === 'OK') return '#29CC71';
    if (this.props.status.state === 'PENDING') return '#ccc';
    return '#dd3b3b';
  };

  tooltipLabel = () => {
    const lastSuccess = this.props.status.lastSuccessAt
      ? `Last sync was ${moment(this.props.status.lastSuccessAt).fromNow()}.`
      : '';

    if (this.props.status.state === 'OK') {
      return lastSuccess;
    }

    if (this.props.status.state === 'PENDING') {
      return `Sync has been scheduled. ${lastSuccess}`;
    }

    return (
      <span>
        {this.props.status.errorMessage}
        <br />
        {lastSuccess}
      </span>
    );
  };

  syncStatusLabel = (): string => {
    const status = this.props.status;
    if (status.state === 'OK') return 'Synced';
    if (status.state === 'FAILING') return 'Sync Error';
    return 'Pending';
  };

  render() {
    const { label, className } = this.props;

    return (
      <Box className={className}>
        <Tooltip label={this.tooltipLabel()} placement="left">
          <Icon className="fa fa-circle" color={this.iconColor()} />
          {label != null && `${label} - `}
          {this.syncStatusLabel()}
        </Tooltip>
      </Box>
    );
  }
}

export default createFragmentContainer(
  SyncStatusIndicator,
  graphql`
    fragment SyncStatusIndicator_status on SyncStatus {
      lastSuccessAt
      state
      errorMessage
    }
  `,
);
