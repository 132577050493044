/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';

import NumberField from './components/NumberField';
import type { AttendedContactsTotal_event } from './__generated__/AttendedContactsTotal_event.graphql';

const AttendedContactsTotal = (props: { event: AttendedContactsTotal_event }) => {
  const { attendedTotal } = props.event;

  return <NumberField value={attendedTotal} />;
};

export default createFragmentContainer(
  AttendedContactsTotal,
  graphql`
    fragment AttendedContactsTotal_event on Event {
      attendedTotal
    }
  `,
);
