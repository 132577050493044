/* @flow */
import type { FieldType } from 'utils/customization/types';
import {
  stringToStringParam,
  stringArrayToStringParam,
} from 'utils/routing/stringifyQueryParamValues';
import getCustomFieldFilterConverterByKind from 'utils/filters/getCustomFieldFilterConverterByKind';

export default function getVendorFilterConverters(customFields: $ReadOnlyArray<FieldType>) {
  return customFields.reduce((converter, customField) => {
    if (customField.fieldName != null) {
      switch (customField.fieldName) {
        case 'created_at':
        case 'updated_at':
          return { ...converter, [customField.fieldName]: stringToStringParam };
        default:
          return { ...converter, [customField.fieldName]: stringArrayToStringParam };
      }
    }
    return { ...converter, ...getCustomFieldFilterConverterByKind(customField) };
  }, {});
}
