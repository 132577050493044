/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { EditorState, getVisibleSelectionRect, Modifier, RichUtils } from 'draft-js';

import type { CircaFile } from 'utils/uploading/types';

import ClearFormatIcon from 'images/format_clear.svg';
import AttachmentOverlay from 'components/RelayAttachments/AttachmentOverlay';

import RichTextEmojiOverlay from './RichTextEmojiOverlay';
import RichTextHeaderOverlay from './RichTextHeaderOverlay';
import RichTextLinkOverlay from './RichTextLinkOverlay';

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Button = styled.div`
  margin-right: 5px;
  padding: 2px 5px;
  color: ${props => props.theme.secondaryActionDarkerColor};
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
  ${props => props.active && `color: ${props.theme.primaryActionColor}`};
`;

const StyledClearFormatIcon = styled(ClearFormatIcon)`
  width: 16px;
  height: 16px;
`;

const INLINE_STYLES = [
  { icon: 'bold', key: 'BOLD' },
  { icon: 'italic', key: 'ITALIC' },
];

const BLOCK_TYPES = [
  { icon: 'list-ul', key: 'unordered-list-item' },
  { icon: 'list-ol', key: 'ordered-list-item' },
];

const HEADER_BLOCK_TYPES = ['header-one', 'header-two', 'header-three', 'unstyled'];

type Position = {
  top: number,
  left: number,
};

type RichControlProps = {
  editorState: EditorState,
  onChange: EditorState => void,
  target: any,
  query: string,
  replacementRanges: { start: number, end: number },
  emojiOverlayShown: boolean,
  onEmojiOverlayHide: () => void,
  triggerEmojiButton: () => void,
  emojiButtonTriggered: boolean,
  onTriggerFocus: () => void,
  supportInlineImage?: boolean,
  onImageUpload?: CircaFile => void,
  onImageUploadOpen?: () => void,
};

type RichControlsState = {
  headerOverlayShown: boolean,
  linkOverlayShown: boolean,
  overlayPosition: Position,
  showPickerMenu: boolean,
};

export default class RichTextControls extends React.PureComponent<
  RichControlProps,
  RichControlsState,
> {
  state = {
    headerOverlayShown: false,
    linkOverlayShown: false,
    overlayPosition: { top: 0, left: 0 },
    showPickerMenu: false,
  };

  addAttachmentButton = React.createRef();

  headerButton: ?HTMLElement;

  linkButton: ?HTMLElement;

  emojiButton: ?HTMLElement;

  componentDidUpdate(prevProps: RichControlProps, prevState: RichControlsState) {
    const overlayPosition = this.getOverlayPosition();
    if (
      this.props.emojiOverlayShown &&
      !this.props.emojiButtonTriggered &&
      this.isPositionChanged(overlayPosition, prevState.overlayPosition)
    ) {
      // there is bug with getVisibleSelectionRect method of draftjs, https://github.com/facebook/draft-js/issues/262
      // had to do setState here
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ overlayPosition });
    }
  }

  getOverlayPosition = () => {
    const selectionPosition = getVisibleSelectionRect(window);
    const editorPosition = this.props.target.editor.getBoundingClientRect();
    return selectionPosition
      ? {
          ...selectionPosition,
          top: selectionPosition.top - editorPosition.top,
          left: selectionPosition.left - editorPosition.left,
        }
      : undefined;
  };

  isPositionChanged = (currentPosition: ?Position, prevPosition: Position): boolean => {
    if (
      currentPosition != null &&
      (currentPosition.top !== prevPosition.top || currentPosition.left !== prevPosition.left)
    ) {
      return true;
    }
    return false;
  };

  handleToggleInlineStyle = (inlineStyle: string) => {
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle));
  };

  handleToggleBlockType = (blockType: string) => {
    this.props.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
  };

  handleAddLink = (url: string, label?: ?string) => {
    const contentState = this.props.editorState.getCurrentContent();
    const selection = this.props.editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity('LINK', 'IMMUTABLE', { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    if (selection.isCollapsed()) {
      // Insert new text
      const newContentState = Modifier.insertText(contentState, selection, label, null, entityKey);
      this.props.onChange(
        EditorState.moveFocusToEnd(
          EditorState.push(this.props.editorState, newContentState, 'insert-characters'),
        ),
      );
    } else {
      // Apply link to selected text
      const newContentState = Modifier.applyEntity(contentState, selection, entityKey);
      this.props.onChange(
        EditorState.moveFocusToEnd(
          EditorState.push(this.props.editorState, newContentState, 'apply-entity'),
        ),
      );
    }
  };

  handleRemoveLink = () => {
    const editorState = this.props.editorState;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const newContentState = Modifier.applyEntity(contentState, selection, null);
    this.props.onChange(
      EditorState.moveFocusToEnd(
        EditorState.push(this.props.editorState, newContentState, 'apply-entity'),
      ),
    );
  };

  handleSelectEmoji = (native: string) => {
    const emojiButtonTriggeredState = this.props.emojiButtonTriggered;
    requestAnimationFrame(() => {
      const contentState = this.props.editorState.getCurrentContent();
      let selection = this.props.editorState.getSelection();

      if (!emojiButtonTriggeredState) {
        selection = selection.set('anchorOffset', this.props.replacementRanges.start);
        selection = selection.set('focusOffset', this.props.replacementRanges.end);
      }
      const newContentState = Modifier.replaceText(contentState, selection, native);

      if (emojiButtonTriggeredState) {
        this.props.onChange(
          EditorState.moveFocusToEnd(
            EditorState.push(this.props.editorState, newContentState, 'insert-characters'),
          ),
        );
      } else {
        this.props.onChange(
          EditorState.push(this.props.editorState, newContentState, 'insert-characters'),
        );
      }
    });
  };

  handleHeaderOverlayShow = () => {
    this.setState({ headerOverlayShown: true });
  };

  handleHeaderOverlayHide = () => {
    this.setState({ headerOverlayShown: false });
  };

  handleLinkOverlayShow = () => {
    this.setState({ linkOverlayShown: true });
  };

  handleLinkOverlayHide = () => {
    this.setState({ linkOverlayShown: false });
    this.props.onTriggerFocus();
  };

  handleMouseDown = (e: SyntheticMouseEvent<HTMLInputElement>) => {
    const clickingOnInput = e.currentTarget.type === 'text';
    if (!clickingOnInput) {
      e.preventDefault();
    }
  };

  handleRemoveFormatting = () => {
    const editorState = this.props.editorState;
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      return;
    }

    const removedInlineStyles = INLINE_STYLES.reduce((contentState, style) => {
      return Modifier.removeInlineStyle(contentState, selection, style.key);
    }, editorState.getCurrentContent());

    const unstyleEditorState = EditorState.push(
      editorState,
      removedInlineStyles,
      'change-inline-style',
    );

    const removedBlockesState = Modifier.setBlockType(
      unstyleEditorState.getCurrentContent(),
      selection,
      'unstyled',
    );

    this.props.onChange(
      EditorState.push(unstyleEditorState, removedBlockesState, 'change-block-type'),
    );
  };

  handleShowPickerMenu = () => {
    this.setState({
      showPickerMenu: true,
    });
  };

  hidePickerMenu = () => {
    if (this.props.onImageUploadOpen != null) {
      this.props.onImageUploadOpen();
    }
    this.setState({
      showPickerMenu: false,
    });
  };

  handleAttachmentAdd = (file: CircaFile) => {
    if (this.props.onImageUpload != null) {
      this.props.onImageUpload(file);
    }
  };

  render() {
    const editorState = this.props.editorState;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const contentBlock = contentState.getBlockForKey(selection.getStartKey());
    const blockType = contentBlock.getType();
    const currentStyle = editorState.getCurrentInlineStyle();

    let currentEntity;

    const currentEntityKey = contentBlock.getEntityAt(selection.getStartOffset());
    if (currentEntityKey) {
      currentEntity = contentState.getEntity(currentEntityKey);
    }

    const linkActive = currentEntity && currentEntity.type === 'LINK';

    return (
      <Container onMouseDown={this.handleMouseDown}>
        {INLINE_STYLES.map(({ icon, key }) => (
          <Button
            onClick={() => this.handleToggleInlineStyle(key)}
            key={key}
            active={currentStyle.has(key)}
          >
            <i className={`fa fa-fw fa-${icon}`} />
          </Button>
        ))}

        <Button
          onClick={this.handleHeaderOverlayShow}
          active={HEADER_BLOCK_TYPES.some(key => blockType === key && key !== 'unstyled')}
          ref={el => {
            this.headerButton = el;
          }}
        >
          <i className="fa fa-fw fa-header" />
        </Button>
        <RichTextHeaderOverlay
          onHide={this.handleHeaderOverlayHide}
          show={this.state.headerOverlayShown}
          container={this}
          target={this.headerButton}
          types={HEADER_BLOCK_TYPES}
          onToggle={this.handleToggleBlockType}
          activeStyle={blockType}
        />

        {BLOCK_TYPES.map(({ icon, key }) => (
          <Button
            onClick={() => this.handleToggleBlockType(key)}
            active={blockType === key}
            key={key}
          >
            <i className={`fa fa-fw fa-${icon}`} />
          </Button>
        ))}

        <Button
          onClick={linkActive ? this.handleRemoveLink : this.handleLinkOverlayShow}
          ref={el => {
            this.linkButton = el;
          }}
          active={linkActive}
        >
          <i className={`fa fa-fw fa-${linkActive ? 'unlink' : 'link'}`} />
        </Button>
        {this.state.linkOverlayShown && (
          <RichTextLinkOverlay
            onHide={this.handleLinkOverlayHide}
            container={this}
            target={this.linkButton}
            onAdd={this.handleAddLink}
            selectedText={!selection.isCollapsed()}
          />
        )}

        <Button
          onClick={this.props.triggerEmojiButton}
          ref={el => {
            this.emojiButton = el;
          }}
        >
          <i className="fa fa-fw fa-smile-o" />
        </Button>
        <Button onClick={this.handleRemoveFormatting}>
          <StyledClearFormatIcon viewBox="0 0 24 24" />
        </Button>
        {this.props.supportInlineImage && (
          <>
            <Button onClick={this.handleShowPickerMenu} ref={this.addAttachmentButton}>
              <i className="fa fa-fw fa-picture-o" />
            </Button>
            <AttachmentOverlay
              target={this.addAttachmentButton.current}
              container={this}
              onHide={this.hidePickerMenu}
              show={this.state.showPickerMenu}
              onUploaded={this.handleAttachmentAdd}
              allowOnlyImages
              hideLink
            />
          </>
        )}
        {this.props.emojiButtonTriggered ? (
          <RichTextEmojiOverlay
            onHide={this.props.onEmojiOverlayHide}
            show={this.props.emojiOverlayShown}
            container={this}
            target={this.emojiButton}
            onInsert={this.handleSelectEmoji}
            buttonTriggered={this.props.emojiButtonTriggered}
          />
        ) : (
          <RichTextEmojiOverlay
            onHide={this.props.onEmojiOverlayHide}
            show={this.props.emojiOverlayShown}
            query={this.props.query}
            container={this}
            position={this.state.overlayPosition}
            target={this.props.target}
            onInsert={this.handleSelectEmoji}
          />
        )}
      </Container>
    );
  }
}
