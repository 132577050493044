/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineSelectField from 'components/budget/Table/InlineSelectField';
import LockIcon from 'components/LockIcon';

import type { CustomizableSelect_customizable } from './__generated__/CustomizableSelect_customizable.graphql';

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
`;

class CustomizableSelect extends React.Component<{
  customizable: CustomizableSelect_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
}> {
  getValue = (): ?string => {
    const { customizable, fieldSettings } = this.props;
    const customSelectFields = customizable.customSelectFields.find(
      selectField => selectField.customField.id === fieldSettings.id,
    );
    return customSelectFields ? customSelectFields.option.id : null;
  };

  handleSave = (value: ?string): void => {
    const { onUpdateCustomField, updateColumnWidth, customizable, fieldSettings } = this.props;
    if (value !== this.getValue() && onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        selectValue: value,
      });
      updateColumnWidth();
    }
  };

  getError = (): ?string => {
    if (this.props.fieldSettings.required && this.getValue() == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { readOnly, fieldSettings, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    const value = this.getValue();
    const activeOption = fieldSettings.options.find(option => option.value === value);
    if (readOnly || fieldSettings.restrictChangingValue) {
      return (
        <>
          {activeOption != null ? activeOption.label : null}{' '}
          {!readOnly && fieldSettings.restrictChangingValue && (
            <StyledLockIcon label="Salesforce" />
          )}
        </>
      );
    }
    return (
      <InlineSelectField
        placeholder={fieldSettings.label}
        options={fieldSettings.options}
        value={value}
        onChange={this.handleSave}
        clearable={!fieldSettings.required}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
      />
    );
  }
}

export default createFragmentContainer(
  CustomizableSelect,
  graphql`
    fragment CustomizableSelect_customizable on CustomizableInterface {
      id
      __typename
      customSelectFields {
        customField {
          id
        }
        option {
          id
        }
        selectOtherValue
      }
    }
  `,
);
