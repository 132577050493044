/* @flow */
import moment from 'moment-timezone';

import type { FieldType } from 'utils/customization/types';
import { getFiscalYear, getQuarter } from 'utils/fiscal';
import { type DateRangeParam, FILTER_DATE_FORMAT } from 'utils/routing/parseTypedQueryString';

import { suggestedFields } from 'components/AllEvents/eventsTableColumnSettings';

import { type ParsedBudgetReportingFilters } from './parseBudgetReportingFilters';

import type { BudgetReportingPage_org } from '../__generated__/BudgetReportingPage_org.graphql';

type budgetReportingViewEdgesType = $PropertyType<
  $PropertyType<BudgetReportingPage_org, 'budgetReportingViews'>,
  'edges',
>;
type budgetReportingViewEdgeType = $ElementType<budgetReportingViewEdgesType, 0>;
type budgetReportingView = $PropertyType<budgetReportingViewEdgeType, 'node'>;

const getFiscalPeriod = (start: moment, end: moment, fiscalYearStart: number): ?string => {
  const fiscalYear = [-1, 0, 1]
    .map(yearOffset => getFiscalYear(yearOffset, fiscalYearStart))
    .find(year => start.isSame(year.start, 'date') && end.isSame(year.end, 'date'));
  if (fiscalYear != null) return fiscalYear.key;

  const fiscalQuarter = [-2, -1, 0, 1, 2]
    .map(quarterOffset => getQuarter(quarterOffset, fiscalYearStart))
    .find(quarter => start.isSame(quarter.start, 'date') && end.isSame(quarter.end, 'date'));
  if (fiscalQuarter != null) return fiscalQuarter.key;

  return `${start.format(FILTER_DATE_FORMAT)}-${end.format(FILTER_DATE_FORMAT)}`;
};

const parseDateFilterParams = (
  after: ?string,
  before: ?string,
  tz: string,
  matchFiscalPeriod: ?boolean,
  fiscalYearStart: ?number,
): ?{
  key: ?string,
  start?: moment,
  end?: moment,
} => {
  if (after == null || before == null) {
    return null;
  }

  const start = moment.tz(after, tz);
  const end = moment.tz(before, tz);
  const key =
    matchFiscalPeriod && fiscalYearStart != null
      ? getFiscalPeriod(start, end, fiscalYearStart)
      : `${start.format(FILTER_DATE_FORMAT)}-${end.format(FILTER_DATE_FORMAT)}`;
  return {
    key,
    start,
    end,
  };
};

function getCustomSavedViewFilters(
  view: budgetReportingView,
  eventFields: $ReadOnlyArray<FieldType>,
  tz: string,
) {
  const customFilters = {
    ...[...view.customSavedNumberFilters.edges, ...view.customSavedCurrencyFilters.edges].reduce(
      (constructedFilters, filter) => ({
        ...constructedFilters,
        [filter.node.customField ? filter.node.customField.id : filter.node.fieldName || '']: {
          min: filter.node.minValue,
          max: filter.node.maxValue,
        },
      }),
      {},
    ),
    ...view.customSavedDateFilters.edges.reduce((constructedFilters, filter) => {
      const dateRange = parseDateFilterParams(filter.node.minValue, filter.node.maxValue, tz);
      return {
        ...constructedFilters,
        [filter.node.customField.id]: dateRange ? dateRange.key : null,
      };
    }, {}),
    ...view.customSavedBooleanFilters.edges.reduce(
      (constructedFilters, filter) => ({
        ...constructedFilters,
        [filter.node.customField.id]: filter.node.value,
      }),
      {},
    ),
    ...view.customSavedUserMultiselectFilters.edges.reduce((constructedFilters, filter) => {
      return {
        ...constructedFilters,
        [filter.node.customField
          ? filter.node.customField.id
          : filter.node.fieldName || '']: filter.node.options.edges.map(edge => edge.node.user.id),
      };
    }, {}),

    ...view.customSavedMultiselectFilters.edges.reduce((constructedFilters, filter) => {
      return {
        ...constructedFilters,
        [filter.node.customField.id]: filter.node.options.edges.map(edge => edge.node.option.id),
      };
    }, {}),
  };
  return eventFields
    .filter(field => field.fieldName == null || suggestedFields.includes(field.fieldName))
    .reduce(
      (values, option) =>
        customFilters[option.id]
          ? { ...values, [option.id]: customFilters[option.id] }
          : { ...values, [option.id]: null },
      {},
    );
}

export default function savedViewToFilterObject(
  view: budgetReportingView,
  fiscalYearStart: number,
  eventFields: $ReadOnlyArray<FieldType>,
  tz: string,
): ParsedBudgetReportingFilters {
  return {
    ...getCustomSavedViewFilters(view, eventFields, tz),
    group1: view.group1,
    group2: view.group2,
    customGroup1: view.customGroup1 ? view.customGroup1.id : null,
    customGroup2: view.customGroup2 ? view.customGroup2.id : null,
    queries: view.eventName,
    date: view.eventDate
      ? parseDateFilterParams(view.eventDate[0], view.eventDate[1], tz, true, fiscalYearStart)
      : null,
    statuses: view.eventStatuses,
    syncStatuses: view.syncStatuses,
    teamIds: view.teams.edges.map(edge => edge.node.id),
    leadIds: view.leads.edges.map(edge => edge.node.id),
    staffIds: view.staffers.edges.map(edge => edge.node.id),
    budgetCategoryIds: view.budgetCategories.edges.map(edge => edge.node.id),
    venueNames: view.venueNames,
    cities: view.cities,
    states: view.states,
    countries: view.countries,
    eventFormats: view.eventFormats,
    eventIds: view.eventIds,
    updatedAt: view.updatedAt
      ? parseDateFilterParams(view.updatedAt[0], view.updatedAt[1], tz)
      : null,
    updaterIds: view.updaters.edges.map(edge => edge.node.id),
    createdAt: view.createdAt
      ? parseDateFilterParams(view.createdAt[0], view.createdAt[1], tz)
      : null,
    creatorIds: view.creators.edges.map(edge => edge.node.id),
    requesterUserIds: view.userRequesters.edges.map(edge => edge.node.id),
    requesterContactIds: view.contactRequesters.edges.map(edge => edge.node.id),
    requestedDate: view.requestedDate
      ? parseDateFilterParams(view.requestedDate[0], view.requestedDate[1], tz)
      : null,
    requestStatuses: view.requestStatuses,
    requestFormIds: view.eventRequestForms.edges.map(edge => edge.node.id),
    requestReviewers: view.eventRequestReviewers.edges.map(edge => edge.node.id),
    contactsCount:
      view.contactsCount != null
        ? {
            min: view.contactsCount.min,
            max: view.contactsCount.max,
          }
        : null,
    opportunitiesNumber:
      view.opportunitiesNumber != null
        ? {
            min: view.opportunitiesNumber.min,
            max: view.opportunitiesNumber.max,
          }
        : null,
    opportunitiesAmount:
      view.opportunitiesAmount != null
        ? {
            min: view.opportunitiesAmount.min,
            max: view.opportunitiesAmount.max,
          }
        : null,
    registeredContactsTotal:
      view.registeredContactsTotal != null
        ? {
            min: view.registeredContactsTotal.min,
            max: view.registeredContactsTotal.max,
          }
        : null,
    attendedContactsTotal:
      view.attendedContactsTotal != null
        ? {
            min: view.attendedContactsTotal.min,
            max: view.attendedContactsTotal.max,
          }
        : null,
    registrationFormStatuses: view.registrationFormStatuses,
    sort: view.sort && {
      key: view.sort,
      asc: view.direction === 'ASC',
    },
  };
}

export function dateFilterToDateParam(date: ?DateRangeParam) {
  if (date == null) return null;

  return date.key == null ? `${date.start.format()}-${date.end.format()}` : date.key;
}
