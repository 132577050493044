/**
 * @flow
 * @relayHash dd680d159f34668b7e0b4a235b56fb2c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamDateAdded_membershipEdge$ref = any;
type TeamMembershipActionsColumn_membershipEdge$ref = any;
type TeamName_membershipEdge$ref = any;
export type Direction = "ASC" | "DESC";
export type TeamMembershipSort = "ACCOUNT_STATUS" | "EMAIL" | "FULL_NAME" | "LAST_ACTIVITY" | "TEAM_DATE_ADDED" | "TEAM_NAME";
export type UserTeamsQueryVariables = {|
  userId: string,
  sort?: ?TeamMembershipSort,
  direction: Direction,
|};
export type UserTeamsQueryResponse = {|
  +user: ?{|
    +memberships?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +slug: string,
          +viewerCanUpdate: boolean,
        |},
        +$fragmentRefs: TeamName_membershipEdge$ref & TeamDateAdded_membershipEdge$ref & TeamMembershipActionsColumn_membershipEdge$ref,
      |}>
    |}
  |}
|};
export type UserTeamsQuery = {|
  variables: UserTeamsQueryVariables,
  response: UserTeamsQueryResponse,
|};
*/


/*
query UserTeamsQuery(
  $userId: ID!
  $sort: TeamMembershipSort
  $direction: Direction!
) {
  user: node(id: $userId) {
    __typename
    ... on User {
      memberships(first: 1000, sort: $sort, direction: $direction) {
        edges {
          ...TeamName_membershipEdge
          ...TeamDateAdded_membershipEdge
          ...TeamMembershipActionsColumn_membershipEdge
          node {
            id
            slug
            viewerCanUpdate
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    id
  }
}

fragment TeamName_membershipEdge on MembershipTeamEdge {
  node {
    slug
    name
    viewerCanUpdate
    id
  }
}

fragment TeamDateAdded_membershipEdge on MembershipTeamEdge {
  addedAt
}

fragment TeamMembershipActionsColumn_membershipEdge on MembershipTeamEdge {
  viewerCanRemove
  node {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "TeamMembershipSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdate",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = [
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction",
    "type": "Direction"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "TeamMembershipSort"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserTeamsQuery",
  "id": null,
  "text": "query UserTeamsQuery(\n  $userId: ID!\n  $sort: TeamMembershipSort\n  $direction: Direction!\n) {\n  user: node(id: $userId) {\n    __typename\n    ... on User {\n      memberships(first: 1000, sort: $sort, direction: $direction) {\n        edges {\n          ...TeamName_membershipEdge\n          ...TeamDateAdded_membershipEdge\n          ...TeamMembershipActionsColumn_membershipEdge\n          node {\n            id\n            slug\n            viewerCanUpdate\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment TeamName_membershipEdge on MembershipTeamEdge {\n  node {\n    slug\n    name\n    viewerCanUpdate\n    id\n  }\n}\n\nfragment TeamDateAdded_membershipEdge on MembershipTeamEdge {\n  addedAt\n}\n\nfragment TeamMembershipActionsColumn_membershipEdge on MembershipTeamEdge {\n  viewerCanRemove\n  node {\n    id\n  }\n}\n",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "user",
          "memberships"
        ]
      }
    ]
  },
  "fragment": {
    "kind": "Fragment",
    "name": "UserTeamsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "User",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "memberships",
                "name": "__UserTeams_memberships_connection",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamMembershipConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MembershipTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "TeamName_membershipEdge",
                        "args": null
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "TeamDateAdded_membershipEdge",
                        "args": null
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "TeamMembershipActionsColumn_membershipEdge",
                        "args": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Team",
                        "plural": false,
                        "selections": [
                          v2,
                          v3,
                          v4,
                          v5
                        ]
                      },
                      v6
                    ]
                  },
                  v7
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserTeamsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "user",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          v5,
          v2,
          {
            "kind": "InlineFragment",
            "type": "User",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "memberships",
                "storageKey": null,
                "args": v8,
                "concreteType": "TeamMembershipConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MembershipTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Team",
                        "plural": false,
                        "selections": [
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          v4,
                          v2,
                          v5
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "addedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanRemove",
                        "args": null,
                        "storageKey": null
                      },
                      v6
                    ]
                  },
                  v7
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "memberships",
                "args": v8,
                "handle": "connection",
                "key": "UserTeams_memberships",
                "filters": []
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd27f99d8d5506f4fb8dfcbdf4228d1a6';
module.exports = node;
