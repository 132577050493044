/* @flow */

import React from 'react';
import styled, { css } from 'styled-components';

import { participantTags } from 'components/Participants';

const tagColors = {
  ...participantTags,
  leader: '#2165a0',
  customer: '#c14f93',
};

const ContactTag = styled.div`
  display: inline-block;
  height: 23px;
  margin: 0 3px;
  padding: 0 10px;
  border: 1px solid ${props => tagColors[props.tag]};
  border-radius: 11px;
  color: ${props => tagColors[props.tag]};
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  text-transform: capitalize;
  ${props =>
    props.tag === 'organizer' &&
    css`
      background-color: #3e4858;
      border-color: #3e4858;
      color: #fff;
    `};
`;

type Props = { tag: $Keys<typeof tagColors> };

const BriefUserTag = ({ tag }: Props) => (tag ? <ContactTag tag={tag}>{tag}</ContactTag> : null);

export default BriefUserTag;
