/* @flow */

export default function checkSAMLEnabled(email: string) {
  return fetch('/saml/check', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  })
    .then(response => response.json())
    .then(data => {
      return {
        enabled: data.enabled,
        orgName: data.orgName,
        orgPrimaryColor: data.orgPrimaryColor,
      };
    });
}
