/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type massUpdateCompaniesMutationVariables } from './__generated__/massUpdateCompaniesMutation.graphql';

const mutation = graphql`
  mutation massUpdateCompaniesMutation($input: MassUpdateCompaniesInput!) {
    massUpdateCompanies(input: $input) {
      companyEdges {
        node {
          ...CompanyUpdater_company
          ...CompanyUpdatedAt_company
          ...CustomizableSelect_customizable
          ...CustomizableUserSelect_customizable
        }
      }
    }
  }
`;

export default function updateCompanies(
  input: $Exact<$PropertyType<massUpdateCompaniesMutationVariables, 'input'>>,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  });
}
