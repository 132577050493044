/**
 * @flow
 * @relayHash ce2e50a2456725392d188d9bea93a339
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactSyncOption = "contact" | "lead";
export type UpdateOrgSettingsInput = {
  clientMutationId?: ?string,
  settingId: string,
  fiscalYearStart?: ?number,
  analyticsEnabled?: ?boolean,
  syncedToIbmWm?: ?boolean,
  logo?: ?string,
  primaryColor?: ?string,
  secondaryColor?: ?string,
  scheduleName?: ?string,
  font?: ?string,
  defaultTag?: ?string,
  tz?: ?string,
  currency?: ?string,
  domain?: ?string,
  subdomain?: ?string,
  apiPushNotificationsUrl?: ?string,
  apiPushNotificationsBlocked?: ?boolean,
};
export type updateOrgSettingsMutationVariables = {|
  input: UpdateOrgSettingsInput,
  changingAnalyticsEnabled: boolean,
  changingSyncedToIbmWm: boolean,
  changingFiscalYearStart: boolean,
  changingLogo: boolean,
  changingPrimaryColor: boolean,
  changingSecondaryColor: boolean,
  changingScheduleName: boolean,
  changingFont: boolean,
  changingTz: boolean,
  changingDefaultTag: boolean,
  changingCurrency: boolean,
  changingDomain: boolean,
  changingSubdomain: boolean,
  changingApiPushNotificationsUrl: boolean,
  changingApiPushNotificationsBlocked: boolean,
|};
export type updateOrgSettingsMutationResponse = {|
  +updateOrgSettings: ?{|
    +removedBudgetPeriodIds: $ReadOnlyArray<string>,
    +org: {|
      +id: string,
      +analyticsEnabled?: boolean,
      +syncedToIbmWm?: boolean,
      +settings: {|
        +fiscalYearStart?: number,
        +logo?: ?string,
        +primaryColor?: string,
        +secondaryColor?: string,
        +tz?: string,
        +scheduleName?: string,
        +font?: string,
        +defaultTag?: ?ContactSyncOption,
        +currency?: string,
        +domain?: ?string,
        +subdomain?: ?string,
        +newSubdomain?: ?string,
        +apiPushNotificationsUrl?: ?string,
        +apiPushNotificationsBlocked?: boolean,
      |},
    |},
  |}
|};
export type updateOrgSettingsMutation = {|
  variables: updateOrgSettingsMutationVariables,
  response: updateOrgSettingsMutationResponse,
|};
*/


/*
mutation updateOrgSettingsMutation(
  $input: UpdateOrgSettingsInput!
  $changingAnalyticsEnabled: Boolean!
  $changingSyncedToIbmWm: Boolean!
  $changingFiscalYearStart: Boolean!
  $changingLogo: Boolean!
  $changingPrimaryColor: Boolean!
  $changingSecondaryColor: Boolean!
  $changingScheduleName: Boolean!
  $changingFont: Boolean!
  $changingTz: Boolean!
  $changingDefaultTag: Boolean!
  $changingCurrency: Boolean!
  $changingDomain: Boolean!
  $changingSubdomain: Boolean!
  $changingApiPushNotificationsUrl: Boolean!
  $changingApiPushNotificationsBlocked: Boolean!
) {
  updateOrgSettings(input: $input) {
    removedBudgetPeriodIds
    org {
      id
      analyticsEnabled @include(if: $changingAnalyticsEnabled)
      syncedToIbmWm @include(if: $changingSyncedToIbmWm)
      settings {
        fiscalYearStart @include(if: $changingFiscalYearStart)
        logo @include(if: $changingLogo)
        primaryColor @include(if: $changingPrimaryColor)
        secondaryColor @include(if: $changingSecondaryColor)
        tz @include(if: $changingTz)
        scheduleName @include(if: $changingScheduleName)
        font @include(if: $changingFont)
        defaultTag @include(if: $changingDefaultTag)
        currency @include(if: $changingCurrency)
        domain @include(if: $changingDomain)
        subdomain @include(if: $changingSubdomain)
  newSubdomain @include(if: $changingSubdomain)
        apiPushNotificationsUrl @include(if: $changingApiPushNotificationsUrl)
        apiPushNotificationsBlocked @include(if: $changingApiPushNotificationsBlocked)
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateOrgSettingsInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingAnalyticsEnabled",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingSyncedToIbmWm",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingFiscalYearStart",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingLogo",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingPrimaryColor",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingSecondaryColor",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingScheduleName",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingFont",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingTz",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingDefaultTag",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingCurrency",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingDomain",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingSubdomain",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingApiPushNotificationsUrl",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "changingApiPushNotificationsBlocked",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateOrgSettingsInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedBudgetPeriodIds",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingFiscalYearStart",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fiscalYearStart",
      "args": null,
      "storageKey": null
    }
  ]
},
v5 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingLogo",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logo",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingPrimaryColor",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "primaryColor",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingSecondaryColor",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "secondaryColor",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingTz",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tz",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingScheduleName",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "scheduleName",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingFont",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "font",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingDefaultTag",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "defaultTag",
      "args": null,
      "storageKey": null
    }
  ]
},
v12 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingCurrency",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currency",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingDomain",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "domain",
      "args": null,
      "storageKey": null
    }
  ]
},
v14 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingSubdomain",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subdomain",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "newSubdomain",
      "args": null,
      "storageKey": null
    }
  ]
},
v15 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingApiPushNotificationsUrl",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "apiPushNotificationsUrl",
      "args": null,
      "storageKey": null
    }
  ]
},
v16 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingApiPushNotificationsBlocked",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "apiPushNotificationsBlocked",
      "args": null,
      "storageKey": null
    }
  ]
},
v17 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingAnalyticsEnabled",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "analyticsEnabled",
      "args": null,
      "storageKey": null
    }
  ]
},
v18 = {
  "kind": "Condition",
  "passingValue": true,
  "condition": "changingSyncedToIbmWm",
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "syncedToIbmWm",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateOrgSettingsMutation",
  "id": null,
  "text": "mutation updateOrgSettingsMutation(\n  $input: UpdateOrgSettingsInput!\n  $changingAnalyticsEnabled: Boolean!\n  $changingSyncedToIbmWm: Boolean!\n  $changingFiscalYearStart: Boolean!\n  $changingLogo: Boolean!\n  $changingPrimaryColor: Boolean!\n  $changingSecondaryColor: Boolean!\n  $changingScheduleName: Boolean!\n  $changingFont: Boolean!\n  $changingTz: Boolean!\n  $changingDefaultTag: Boolean!\n  $changingCurrency: Boolean!\n  $changingDomain: Boolean!\n  $changingSubdomain: Boolean!\n  $changingApiPushNotificationsUrl: Boolean!\n  $changingApiPushNotificationsBlocked: Boolean!\n) {\n  updateOrgSettings(input: $input) {\n    removedBudgetPeriodIds\n    org {\n      id\n      analyticsEnabled @include(if: $changingAnalyticsEnabled)\n      syncedToIbmWm @include(if: $changingSyncedToIbmWm)\n      settings {\n        fiscalYearStart @include(if: $changingFiscalYearStart)\n        logo @include(if: $changingLogo)\n        primaryColor @include(if: $changingPrimaryColor)\n        secondaryColor @include(if: $changingSecondaryColor)\n        tz @include(if: $changingTz)\n        scheduleName @include(if: $changingScheduleName)\n        font @include(if: $changingFont)\n        defaultTag @include(if: $changingDefaultTag)\n        currency @include(if: $changingCurrency)\n        domain @include(if: $changingDomain)\n        subdomain @include(if: $changingSubdomain)\n  newSubdomain @include(if: $changingSubdomain)\n        apiPushNotificationsUrl @include(if: $changingApiPushNotificationsUrl)\n        apiPushNotificationsBlocked @include(if: $changingApiPushNotificationsBlocked)\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateOrgSettingsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrgSettings",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateOrgSettingsPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "OrgSettings",
                "plural": false,
                "selections": [
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16
                ]
              },
              v17,
              v18
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateOrgSettingsMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateOrgSettings",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateOrgSettingsPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "OrgSettings",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v8,
                  v9,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16
                ]
              },
              v17,
              v18
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac97737051650ddf15a1d80c9fad65b1';
module.exports = node;
