/**
 * @flow
 * @relayHash d0af8da3c3a38492fffa9a8d9da265da
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ImportLogsTableList_org$ref = any;
export type Direction = "ASC" | "DESC";
export type ImportLogSort = "CREATED_AT" | "ERRORS_COUNT" | "FILE_NAME" | "SUCCESS_COUNT" | "USER";
export type ImportType = "EVENTS" | "EXPENSES";
export type ImportLogsTableListPaginationQueryVariables = {|
  count: number,
  cursor?: ?string,
  type: ImportType,
  sort?: ?ImportLogSort,
  direction: Direction,
|};
export type ImportLogsTableListPaginationQueryResponse = {|
  +org: {|
    +$fragmentRefs: ImportLogsTableList_org$ref
  |}
|};
export type ImportLogsTableListPaginationQuery = {|
  variables: ImportLogsTableListPaginationQueryVariables,
  response: ImportLogsTableListPaginationQueryResponse,
|};
*/


/*
query ImportLogsTableListPaginationQuery(
  $count: Int!
  $cursor: String
  $type: ImportType!
  $sort: ImportLogSort
  $direction: Direction!
) {
  org {
    ...ImportLogsTableList_org
    id
  }
}

fragment ImportLogsTableList_org on Org {
  id
  importLogs(first: $count, after: $cursor, type: $type, sort: $sort, direction: $direction) {
    edges {
      node {
        id
        ...ImportLogsTableRow_importLog
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}

fragment ImportLogsTableRow_importLog on ImportLog {
  id
  ...ImportLogsFilenameColumn_importLog
  ...ImportLogsDateColumn_importLog
  ...ImportLogsUserColumn_importLog
  ...ImportLogsSuccessCountColumn_importLog
  ...ImportLogsErrorCountColumn_importLog
  ...ImportLogsActionsColumn_importLog
}

fragment ImportLogsFilenameColumn_importLog on ImportLog {
  fileName
}

fragment ImportLogsDateColumn_importLog on ImportLog {
  createdAt
}

fragment ImportLogsUserColumn_importLog on ImportLog {
  user {
    firstName
    lastName
    id
  }
}

fragment ImportLogsSuccessCountColumn_importLog on ImportLog {
  successCount
}

fragment ImportLogsErrorCountColumn_importLog on ImportLog {
  errorsCount
  errorsFileUrl
}

fragment ImportLogsActionsColumn_importLog on ImportLog {
  id
  originalFileUrl
  viewerCanRevert
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "ImportType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "ImportLogSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction",
    "type": "Direction"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "ImportLogSort"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type",
    "type": "ImportType!"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ImportLogsTableListPaginationQuery",
  "id": null,
  "text": "query ImportLogsTableListPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $type: ImportType!\n  $sort: ImportLogSort\n  $direction: Direction!\n) {\n  org {\n    ...ImportLogsTableList_org\n    id\n  }\n}\n\nfragment ImportLogsTableList_org on Org {\n  id\n  importLogs(first: $count, after: $cursor, type: $type, sort: $sort, direction: $direction) {\n    edges {\n      node {\n        id\n        ...ImportLogsTableRow_importLog\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment ImportLogsTableRow_importLog on ImportLog {\n  id\n  ...ImportLogsFilenameColumn_importLog\n  ...ImportLogsDateColumn_importLog\n  ...ImportLogsUserColumn_importLog\n  ...ImportLogsSuccessCountColumn_importLog\n  ...ImportLogsErrorCountColumn_importLog\n  ...ImportLogsActionsColumn_importLog\n}\n\nfragment ImportLogsFilenameColumn_importLog on ImportLog {\n  fileName\n}\n\nfragment ImportLogsDateColumn_importLog on ImportLog {\n  createdAt\n}\n\nfragment ImportLogsUserColumn_importLog on ImportLog {\n  user {\n    firstName\n    lastName\n    id\n  }\n}\n\nfragment ImportLogsSuccessCountColumn_importLog on ImportLog {\n  successCount\n}\n\nfragment ImportLogsErrorCountColumn_importLog on ImportLog {\n  errorsCount\n  errorsFileUrl\n}\n\nfragment ImportLogsActionsColumn_importLog on ImportLog {\n  id\n  originalFileUrl\n  viewerCanRevert\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ImportLogsTableListPaginationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ImportLogsTableList_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ImportLogsTableListPaginationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "importLogs",
            "storageKey": null,
            "args": v2,
            "concreteType": "ImportLogRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ImportLogRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ImportLog",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                          },
                          v1
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "successCount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "errorsCount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "errorsFileUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "originalFileUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanRevert",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "importLogs",
            "args": v2,
            "handle": "connection",
            "key": "ImportLogsTableList_importLogs",
            "filters": []
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a49c240054fb59fc91c9597233846fff';
module.exports = node;
