/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TextInput from 'components/material/TextInput';

const Container = styled.div`
  padding: 5px 15px;
`;

const Help = styled.div`
  text-align: right;
  color: ${props => props.theme.mutedTextColor};
  font-size: 12px;
  margin-top: 3px;
`;

export default class SubtaskInput extends React.PureComponent<{
  onCancel: () => void,
  onSave: (name: string) => void,
  defaultValue?: string,
}> {
  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = typeof e.currentTarget.value === 'string' ? e.currentTarget.value.trim() : '';
    if (!value || value === this.props.defaultValue) this.props.onCancel();
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const value = typeof e.currentTarget.value === 'string' ? e.currentTarget.value.trim() : '';
      if (value) {
        this.props.onSave(value);
        e.currentTarget.value = '';
      }
    } else if (e.key === 'Escape') {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <Container>
        <TextInput
          autoFocus
          placeholder="Enter Subtask Name"
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          defaultValue={this.props.defaultValue}
        />
        <Help>press enter to save</Help>
      </Container>
    );
  }
}
