/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';
import { parse } from 'qs';

import personalizationDefaults from 'config/personalizationDefaults';

import ContactFormSubmission, {
  type FormSubmissionFn,
} from 'components/ContactForm/ContactFormSubmission';
import type { RegistrationFormDefaultValues } from 'components/ContactForm/lib/types';
import SharedFormThemeContext from 'components/SharedForm/lib/SharedFormThemeContext';

import {
  defaultButtonTextColor,
  defaultTextColor,
  fontStyles,
  registerButtonCopy,
} from '../../RegistrationCreate/registrationFormDefaults';

import type { RegistrationFormComponent_componentProps } from './__generated__/RegistrationFormComponent_componentProps.graphql';
import type { RegistrationFormComponent_org } from './__generated__/RegistrationFormComponent_org.graphql';
import type { RegistrationFormComponent_registrationForm } from './__generated__/RegistrationFormComponent_registrationForm.graphql';

type Props = {
  org: RegistrationFormComponent_org,
  componentProps: RegistrationFormComponent_componentProps,
  registrationForm: RegistrationFormComponent_registrationForm,
  onSubmit: FormSubmissionFn,
  onFinishSubmit: () => void,
  viewMode: boolean,
  email?: string,
  lockEmail?: boolean,
  defaultFormValues: RegistrationFormDefaultValues,
  location?: Location,
};

const Container = styled.div`
  width: 100%;
  height: auto;
  text-align: ${props => props.componentProps.alignment};
`;

const Content = styled.div`
  display: inline-block;
  max-width: 100%;
  width: ${props =>
    `${props.componentProps.width}${
      props.componentProps.widthMeasurement === 'PERCENTAGE' ? '%' : 'px'
    }`};
  text-align: initial;
`;

const RegistrationFormComponent = ({
  org,
  componentProps: { formComponent },
  registrationForm,
  onSubmit,
  onFinishSubmit,
  viewMode,
  email,
  lockEmail,
  defaultFormValues,
  location,
}: Props) => {
  if (!formComponent) return null;

  const formButtonCopy = location
    ? parse(location.search, {
        ignoreQueryPrefix: true,
      }).formButtonCopy
    : null;

  return (
    <SharedFormThemeContext.Provider
      value={{
        textFont: formComponent.fontFamily || org.settings.font || personalizationDefaults.font,
        textColor: formComponent.color || defaultTextColor,
        textStyle: formComponent.fontStyle || fontStyles[0].value,
        backgroundColor: registrationForm.backgroundColor || '#ffffff',
        buttonColor: formComponent.buttonColor || org.settings.primaryColor,
        buttonTextColor: formComponent.buttonTextColor || defaultButtonTextColor,
      }}
    >
      <Container componentProps={formComponent}>
        <Content componentProps={formComponent}>
          <ContactFormSubmission
            contactForm={formComponent.contactForm}
            org={org}
            event={registrationForm.event || null}
            companyEmailsOnly={registrationForm.companyEmailsOnly}
            context="REGISTRATION_FORM"
            tz={registrationForm.tz}
            onSubmit={onSubmit}
            onFinishSubmit={onFinishSubmit}
            createCopy={formButtonCopy || formComponent.buttonCopy || registerButtonCopy}
            viewMode={viewMode}
            email={email}
            lockEmail={lockEmail}
            defaultFormValues={defaultFormValues}
          />
        </Content>
      </Container>
    </SharedFormThemeContext.Provider>
  );
};

export default createFragmentContainer(RegistrationFormComponent, {
  org: graphql`
    fragment RegistrationFormComponent_org on Org {
      settings {
        font
        primaryColor
      }
      ...ContactFormSubmission_org
    }
  `,
  componentProps: graphql`
    fragment RegistrationFormComponent_componentProps on RegistrationPageComponent {
      formComponent {
        fontFamily
        fontStyle
        color
        buttonCopy
        buttonColor
        buttonTextColor
        width
        widthMeasurement
        alignment
        contactForm {
          ...ContactFormSubmission_contactForm
        }
      }
    }
  `,
  registrationForm: graphql`
    fragment RegistrationFormComponent_registrationForm on RegistrationForm {
      backgroundColor
      companyEmailsOnly
      tz
      event {
        ...ContactFormSubmission_event
      }
    }
  `,
});
