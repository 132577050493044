/* @flow */
import { graphql } from 'react-relay';

import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';
import commitSupportMutation from 'graph/utils/commitSupportMutation';

import type {
  UpdateOrgSettingsInput,
  updateOrgSettingsMutationResponse,
  updateOrgSettingsMutationVariables,
} from './__generated__/updateOrgSettingsMutation.graphql';

const mutation = graphql`
  mutation updateOrgSettingsMutation(
    $input: UpdateOrgSettingsInput!
    $changingAnalyticsEnabled: Boolean!
    $changingSyncedToIbmWm: Boolean!
    $changingFiscalYearStart: Boolean!
    $changingLogo: Boolean!
    $changingPrimaryColor: Boolean!
    $changingSecondaryColor: Boolean!
    $changingScheduleName: Boolean!
    $changingFont: Boolean!
    $changingTz: Boolean!
    $changingDefaultTag: Boolean!
    $changingCurrency: Boolean!
    $changingDomain: Boolean!
    $changingSubdomain: Boolean!
    $changingApiPushNotificationsUrl: Boolean!
    $changingApiPushNotificationsBlocked: Boolean!
  ) {
    updateOrgSettings(input: $input) {
      removedBudgetPeriodIds
      org {
        id
        analyticsEnabled @include(if: $changingAnalyticsEnabled)
        syncedToIbmWm @include(if: $changingSyncedToIbmWm)
        settings {
          fiscalYearStart @include(if: $changingFiscalYearStart)
          logo @include(if: $changingLogo)
          primaryColor @include(if: $changingPrimaryColor)
          secondaryColor @include(if: $changingSecondaryColor)
          tz @include(if: $changingTz)
          scheduleName @include(if: $changingScheduleName)
          font @include(if: $changingFont)
          defaultTag @include(if: $changingDefaultTag)
          currency @include(if: $changingCurrency)
          domain @include(if: $changingDomain)
          subdomain @include(if: $changingSubdomain)
          newSubdomain @include(if: $changingSubdomain)
          apiPushNotificationsUrl @include(if: $changingApiPushNotificationsUrl)
          apiPushNotificationsBlocked @include(if: $changingApiPushNotificationsBlocked)
        }
      }
    }
  }
`;

export default function updateOrgSettings(
  settingId: string,
  orgSettings: $Exact<$Diff<UpdateOrgSettingsInput, { settingId: string }>>,
  mutationOptions?: {
    orgId?: string,
    asSupportUser?: boolean,
  },
): Promise<updateOrgSettingsMutationResponse> {
  const variables: $Shape<updateOrgSettingsMutationVariables> = {
    input: { settingId, ...orgSettings },
    ...[
      'analyticsEnabled',
      'syncedToIbmWm',
      'fiscalYearStart',
      'logo',
      'primaryColor',
      'secondaryColor',
      'tz',
      'scheduleName',
      'font',
      'defaultTag',
      'currency',
      'domain',
      'subdomain',
      'apiPushNotificationsUrl',
      'apiPushNotificationsBlocked',
    ]
      .map(key => ({
        [`changing${key[0].toUpperCase()}${key.slice(1)}`]: orgSettings[key] !== undefined,
      }))
      .reduce((acc, item) => ({ ...acc, ...item }), {}),
  };
  const updater =
    orgSettings.fiscalYearStart !== undefined &&
    mutationOptions &&
    mutationOptions.orgId &&
    removeRecordFromConnection({
      deletedIdField: 'removedBudgetPeriodIds',
      parentId: mutationOptions.orgId,
      connections: [{ field: 'budgetPeriods' }],
    });
  const optimisticResponse =
    orgSettings.fiscalYearStart === undefined || !mutationOptions || !mutationOptions.orgId
      ? {
          updateOrgSettings: {
            org: { settings: { id: settingId, ...orgSettings } },
          },
        }
      : undefined;

  const mutationMode =
    mutationOptions && mutationOptions.asSupportUser ? commitSupportMutation : commitModernMutation;
  return mutationMode({
    mutation,
    variables,
    updater,
    optimisticResponse,
  });
}
