/* @flow */
import { graphql } from 'react-relay';

import checkImportStatus from 'utils/requests/checkImportStatus';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  importEventsMutationVariables,
  importEventsMutationResponse,
} from './__generated__/importEventsMutation.graphql';

type InputType = $PropertyType<importEventsMutationVariables, 'input'>;
export type EventsImportMappingsType = $PropertyType<InputType, 'mappings'>;

const mutation = graphql`
  mutation importEventsMutation($input: ImportEventsInput!) {
    importEvents(input: $input) {
      uuid
    }
  }
`;

export default function importEvents(input: InputType) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  }).then((response: importEventsMutationResponse) => {
    if (!response.importEvents) throw Error('Import Failed');

    return checkImportStatus(response.importEvents.uuid);
  });
}
