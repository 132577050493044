/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import BriefViewSharedContext from '../../BriefViewSharedContext';

import { type ScheduleNavigationItem_schedule } from './__generated__/ScheduleNavigationItem_schedule.graphql';

const Container = styled.div`
  padding: 0 3px 2px;
  margin: 0 20px 30px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  color: ${props => props.theme.infoMenuColor};
  cursor: pointer;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${props => props.activeColor};
    border-bottom-color: ${props => props.activeColor};
  }

  ${props =>
    props.isActive &&
    css`
      color: ${props.activeColor};
      border-bottom-color: ${props.activeColor};
    `};
  ${props =>
    props.isShared &&
    css`
      @media (max-width: 905px) {
        font-size: 15px;
      }
    `};
`;

const ScheduleNavigationItem = (props: {
  schedule: ScheduleNavigationItem_schedule,
  isActive?: boolean,
  onScheduleClick: (scheduleId: string) => void,
  primaryColor: string,
}) => {
  const {
    onScheduleClick,
    isActive,
    schedule: { id, name },
    primaryColor,
  } = props;
  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <Container
          isShared={sharedView}
          isActive={isActive}
          activeColor={primaryColor}
          onClick={() => onScheduleClick(id)}
        >
          {name}
        </Container>
      )}
    </BriefViewSharedContext.Consumer>
  );
};

export default createFragmentContainer(
  ScheduleNavigationItem,
  graphql`
    fragment ScheduleNavigationItem_schedule on Schedule {
      id
      name
    }
  `,
);
