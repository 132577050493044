/* @flow */

export type EventStateGraphValue =
  | 'COMMITTED'
  | 'CONSIDERING'
  | 'SKIPPING'
  | 'CANCELLED'
  | 'POSTPONED'
  | 'COMPLETED';

type EventState = {|
  +label: string, // Label in dropdowns
  +pastLabel: string, // Past tense label in dropdowns
  +graphValue: EventStateGraphValue, // value for GraphQL API
  +dbValue: number, // value in the database
|};

const eventStates: $ReadOnlyArray<EventState> = [
  {
    label: 'Committed',
    pastLabel: 'Committed',
    graphValue: 'COMMITTED',
    dbValue: 0,
  },
  {
    label: 'Considering',
    pastLabel: 'Considered',
    graphValue: 'CONSIDERING',
    dbValue: 1,
  },
  {
    label: 'Skipping',
    pastLabel: 'Skipped',
    graphValue: 'SKIPPING',
    dbValue: 2,
  },
  {
    label: 'Cancelled',
    pastLabel: 'Cancelled',
    graphValue: 'CANCELLED',
    dbValue: 3,
  },
  {
    label: 'Postponed',
    pastLabel: 'Postponed',
    graphValue: 'POSTPONED',
    dbValue: 4,
  },
  {
    label: 'Completed',
    pastLabel: 'Completed',
    graphValue: 'COMPLETED',
    dbValue: 5,
  },
];

export function getEventStateLabel(graphValue: ?EventStateGraphValue): ?string {
  const type = eventStates.find(status => status.graphValue === graphValue);
  return type != null ? type.label : null;
}

export default eventStates;
