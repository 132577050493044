/**
 * @flow
 * @relayHash e65938488154143d8ffaee4d40470908
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReorderBriefTemplateEventInfoSectionsInput = {
  clientMutationId?: ?string,
  eventInfoSections?: ?$ReadOnlyArray<SortableSectionInput>,
};
export type SortableSectionInput = {
  id?: ?string,
  name?: ?string,
  order: number,
  enabled?: ?boolean,
};
export type reorderBriefTemplateEventInfoSectionsMutationVariables = {|
  input: ReorderBriefTemplateEventInfoSectionsInput
|};
export type reorderBriefTemplateEventInfoSectionsMutationResponse = {|
  +reorderBriefTemplateEventInfoSections: ?{|
    +eventInfoSections: $ReadOnlyArray<{|
      +id: string,
      +order: number,
    |}>
  |}
|};
export type reorderBriefTemplateEventInfoSectionsMutation = {|
  variables: reorderBriefTemplateEventInfoSectionsMutationVariables,
  response: reorderBriefTemplateEventInfoSectionsMutationResponse,
|};
*/


/*
mutation reorderBriefTemplateEventInfoSectionsMutation(
  $input: ReorderBriefTemplateEventInfoSectionsInput!
) {
  reorderBriefTemplateEventInfoSections(input: $input) {
    eventInfoSections {
      id
      order
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReorderBriefTemplateEventInfoSectionsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reorderBriefTemplateEventInfoSections",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ReorderBriefTemplateEventInfoSectionsInput!"
      }
    ],
    "concreteType": "ReorderBriefTemplateEventInfoSectionsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventInfoSections",
        "storageKey": null,
        "args": null,
        "concreteType": "BriefTemplateEventInfoSection",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "order",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "reorderBriefTemplateEventInfoSectionsMutation",
  "id": null,
  "text": "mutation reorderBriefTemplateEventInfoSectionsMutation(\n  $input: ReorderBriefTemplateEventInfoSectionsInput!\n) {\n  reorderBriefTemplateEventInfoSections(input: $input) {\n    eventInfoSections {\n      id\n      order\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "reorderBriefTemplateEventInfoSectionsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "reorderBriefTemplateEventInfoSectionsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a06f9297d022249ccdcab1ea0fa23ad4';
module.exports = node;
