/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import intersection from 'lodash/intersection';
import moment from 'moment';

import downloadedCompanies from 'utils/analytics/downloadedCompanies';
import type { FieldType } from 'utils/customization/types';

import ExportIcon from 'images/events/export.svg';
import Button, { MinimalButton } from 'components/budget/Button';
import CompaniesMassUpdatePanel from 'components/Companies/CompaniesMassUpdatePanel';
import type { InputVariableFilters } from 'components/Companies/companiesTableColumnSettings';
import { getColumnsShowConfig } from 'components/Companies/companiesTableColumnSettings';
import getCompanyFilterConverters from 'components/Companies/lib/getCompanyFilterConverters';
import { type ParsedCompaniesFilters } from 'components/Companies/lib/parseCompaniesFilters';
import renderCompanyFilter from 'components/Companies/lib/renderCompanyFilter';
import ContactWindow from 'components/Contacts/ContactWindow';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import DownloadOverlayWithEmail from 'components/DownloadOverlayWithEmail';
import ColumnFilter from 'components/material/ColumnFilters';
import AdvancedFilter from 'components/material/Filters/Advanced/AdvancedFilter';
import Search from 'components/Search';

import headerTitles from '../lib/headerTitles';
import OrgCompanySearchSuggestion from './OrgCompanySearchSuggestion';

import type { OrgCompaniesHeaderBar_org } from './__generated__/OrgCompaniesHeaderBar_org.graphql';
import type { OrgCompaniesHeaderBarQueryResponse } from './__generated__/OrgCompaniesHeaderBarQuery.graphql';

const Container = styled.div`
  padding-top: 25px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &:first-of-type {
    margin-bottom: 21px;
  }
  @media (min-width: 800px) and (max-width: 1085px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const RowItem = styled.div`
  flex: 1;
  min-height: 31px;
  margin-bottom: 13px;

  &:first-of-type {
    display: flex;
  }
`;

const TableActions = styled.div`
  display: flex;
  flex: 0 auto;
  margin-right: 5px;
`;

const StyledButton = styled(Button)`
  flex: 0 auto;
  min-width: 116px;
  margin-right: 20px;
  padding-top: 9px;
`;

const StyledSearch = styled(Search)`
  max-width: 250px;
  margin-right: 10px;
`;

const StyledExportIcon = styled(ExportIcon)`
  margin-right: 10px;
`;

const ExportButton = styled(MinimalButton)`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 5px;
`;

const query = graphql`
  query OrgCompaniesHeaderBarQuery($filters: CompanyFilters!, $columns: [String!]) {
    org {
      companyReportCSVProcessId(filters: $filters, columns: $columns)
    }
    me {
      email
    }
  }
`;

class OrgCompaniesHeaderBar extends React.PureComponent<
  {
    org: OrgCompaniesHeaderBar_org,
    history: RouterHistory,
    filters: ParsedCompaniesFilters,
    inputVariableFilters: InputVariableFilters,
    companyFields: $ReadOnlyArray<FieldType>,
    shownColumns: $ReadOnlyArray<string>,
    onUpdateTableColumnWidths: () => void,
    currentSelectedCompanies: $ReadOnlyArray<string>,
    search: ?string,
    pathPrefix: string,
    onSearch: (query: string) => void,
    onColumnsChange: (shownColumns: $ReadOnlyArray<string>) => void,
    orgCompaniesCount: number,
    allCompaniesSelected: boolean,
  },
  {
    adding: boolean,
    showCsvDownloadOverlay: boolean,
  },
> {
  state = {
    adding: false,
    showCsvDownloadOverlay: false,
  };

  handleCompanyAddStart = () => {
    this.setState({ adding: true });
  };

  handleCompanyAddEnd = () => {
    this.setState({ adding: false });
  };

  handleCsvExport = () => {
    this.setState({ showCsvDownloadOverlay: true });
  };

  handleCsvDownloadEnd = () => {
    this.setState({ showCsvDownloadOverlay: false });

    downloadedCompanies({
      eventId: null,
      eventName: null,
      teamId: null,
      teamName: null,
      fromWindow: 'org',
    });
  };

  renderDownloadOverlay = () => {
    const columns = intersection(
      this.props.companyFields.map(field => field.fieldName || field.id),
      this.props.shownColumns,
    );
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ filters: this.props.inputVariableFilters, columns }}
        renderSuccess={(response: OrgCompaniesHeaderBarQueryResponse) => {
          return (
            <DownloadOverlayWithEmail
              processUUID={response.org.companyReportCSVProcessId}
              fileName={`Workspace Companies Exported ${moment().format('YYYY-MM-DD')}.csv`}
              onHide={this.handleCsvDownloadEnd}
              email={response.me.email}
              exportable="companies_csv"
              onDownload={this.handleCsvExport}
            />
          );
        }}
        renderLoading={() => null}
      />
    );
  };

  render() {
    const {
      org,
      org: { viewerCanCreateCompanies },
      search,
      history,
      shownColumns,
      onColumnsChange,
      filters,
      currentSelectedCompanies,
      onUpdateTableColumnWidths,
      inputVariableFilters,
      companyFields,
      onSearch,
      pathPrefix,
      allCompaniesSelected,
    } = this.props;

    const companyColumns = getColumnsShowConfig(companyFields);
    const title = `${org.name}'s ${headerTitles[pathPrefix.split('/').pop()]}`;
    return (
      <Container>
        <Row>
          <Title>{title}</Title>
          <ExportButton
            onClick={this.handleCsvExport}
            label={
              <>
                <StyledExportIcon />
                Export
              </>
            }
          />
          {this.state.showCsvDownloadOverlay && this.renderDownloadOverlay()}
        </Row>
        <Row>
          <RowItem>
            {currentSelectedCompanies.length === 0 ? (
              <>
                {viewerCanCreateCompanies && (
                  <StyledButton onClick={this.handleCompanyAddStart} primary>
                    + Add Company
                  </StyledButton>
                )}
                <StyledSearch
                  onSearch={onSearch}
                  search={search}
                  placeholder="Search All Companies"
                  suggestionComponent={props => {
                    return this.props.orgCompaniesCount === 0 ? (
                      <OrgCompanySearchSuggestion
                        {...props}
                        handleCompanyAddStart={this.handleCompanyAddStart}
                      />
                    ) : null;
                  }}
                />
                {this.state.adding && (
                  <ContactWindow
                    onHide={this.handleCompanyAddEnd}
                    defaultActiveType="companies"
                    fromWindow="org companies"
                    filterVariables={inputVariableFilters}
                  />
                )}
              </>
            ) : (
              <CompaniesMassUpdatePanel
                selectedCompanies={currentSelectedCompanies}
                orgId={org.id}
                companyFields={companyFields}
                filters={inputVariableFilters}
                viewerCanRemoveCompanies={org.viewerCanRemoveCompanies}
                onUpdateTableColumnWidths={onUpdateTableColumnWidths}
                allCompaniesSelected={allCompaniesSelected}
                fromWindow="org companies"
              />
            )}
          </RowItem>
          <RowItem>
            <TableActions>
              <AdvancedFilter
                history={history}
                filters={filters}
                filterOptions={companyFields}
                converterFn={getCompanyFilterConverters}
                filterRenderer={renderCompanyFilter}
              />
              <ColumnFilter
                filters={companyColumns}
                shownFilters={shownColumns}
                onFilterChange={onColumnsChange}
              />
            </TableActions>
          </RowItem>
        </Row>
      </Container>
    );
  }
}

export default createFragmentContainer(
  OrgCompaniesHeaderBar,
  graphql`
    fragment OrgCompaniesHeaderBar_org on Org {
      id
      name
      viewerCanCreateCompanies
      viewerCanRemoveCompanies
    }
  `,
);
