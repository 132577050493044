/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Back from 'images/back.svg';

import { type IntegrationSettings } from '.';
import IntegrationSettingsAuth from './IntegrationSettingsAuth';

import { type IntegrationSettingsPage_integrable } from './__generated__/IntegrationSettingsPage_integrable.graphql';

const Header = styled.div`
  padding: 15px 30px 30px 0;

  a {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #3ba9da;

    &:hover {
      color: #248fbe;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }
`;

const Auth = styled.div`
  max-width: 615px;
  padding: 0 30px 30px;
`;

const Content = styled.div`
  padding: 30px 30px 30px 0;
  border-top: 1px solid #d8d8d8;
`;

class IntegrationSettingsPage extends React.PureComponent<{
  rootUrl: string,
  integration: IntegrationSettings,
  children: React.Node,
  integrable: ?IntegrationSettingsPage_integrable,
}> {
  render() {
    const { rootUrl, integration, children, integrable } = this.props;

    return (
      <>
        <Header>
          <Link to={rootUrl}>
            <Back />
            Integrations
          </Link>
        </Header>

        <Auth>
          <IntegrationSettingsAuth
            integrable={integrable}
            integration={integration}
            showIcon
            showTitle
            showButtons={!integration.component}
            showExpireMessage={!integration.component}
          />
        </Auth>

        <Content>{children}</Content>
      </>
    );
  }
}

export default createFragmentContainer(
  IntegrationSettingsPage,
  graphql`
    fragment IntegrationSettingsPage_integrable on IntegrableInterface {
      ...IntegrationSettingsAuth_integrable
    }
  `,
);
