/**
 * @flow
 * @relayHash 8b4b3b461afcad104a503bbd1e0e1291
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompanyUpdatedAt_company$ref = any;
type CompanyUpdater_company$ref = any;
type CustomizableSelect_customizable$ref = any;
type CustomizableUserSelect_customizable$ref = any;
export type MassUpdateCompaniesInput = {
  clientMutationId?: ?string,
  eventId?: ?string,
  companyIds: $ReadOnlyArray<string>,
  customFieldValues?: ?$ReadOnlyArray<CustomFieldValueInput>,
};
export type CustomFieldValueInput = {
  customFieldId: string,
  textValue?: ?string,
  textareaValue?: ?string,
  linkValue?: ?string,
  numberValue?: ?number,
  currencyValue?: ?number,
  dateValue?: ?any,
  booleanValue?: ?boolean,
  userValue?: ?string,
  userValues?: ?$ReadOnlyArray<string>,
  selectValue?: ?string,
  selectOtherValue?: ?string,
  selectValues?: ?$ReadOnlyArray<string>,
};
export type massUpdateCompaniesMutationVariables = {|
  input: MassUpdateCompaniesInput
|};
export type massUpdateCompaniesMutationResponse = {|
  +massUpdateCompanies: ?{|
    +companyEdges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: CompanyUpdater_company$ref & CompanyUpdatedAt_company$ref & CustomizableSelect_customizable$ref & CustomizableUserSelect_customizable$ref
      |}
    |}>
  |}
|};
export type massUpdateCompaniesMutation = {|
  variables: massUpdateCompaniesMutationVariables,
  response: massUpdateCompaniesMutationResponse,
|};
*/


/*
mutation massUpdateCompaniesMutation(
  $input: MassUpdateCompaniesInput!
) {
  massUpdateCompanies(input: $input) {
    companyEdges {
      node {
        ...CompanyUpdater_company
        ...CompanyUpdatedAt_company
        ...CustomizableSelect_customizable
        ...CustomizableUserSelect_customizable
        id
      }
    }
  }
}

fragment CompanyUpdater_company on Company {
  updatedBy {
    firstName
    lastName
    email
    ...MaterialAvatar_user
    id
  }
}

fragment CompanyUpdatedAt_company on Company {
  updatedAt
}

fragment CustomizableSelect_customizable on CustomizableInterface {
  id
  __typename
  customSelectFields {
    customField {
      id
    }
    option {
      id
    }
    selectOtherValue
    id
  }
}

fragment CustomizableUserSelect_customizable on CustomizableInterface {
  id
  __typename
  customUserSelectFields {
    customField {
      id
    }
    user {
      id
      firstName
      lastName
      email
      avatar
      ...MaterialAvatar_user
    }
    id
  }
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "MassUpdateCompaniesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "MassUpdateCompaniesInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v7 = [
  v5
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": v7
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "massUpdateCompaniesMutation",
  "id": null,
  "text": "mutation massUpdateCompaniesMutation(\n  $input: MassUpdateCompaniesInput!\n) {\n  massUpdateCompanies(input: $input) {\n    companyEdges {\n      node {\n        ...CompanyUpdater_company\n        ...CompanyUpdatedAt_company\n        ...CustomizableSelect_customizable\n        ...CustomizableUserSelect_customizable\n        id\n      }\n    }\n  }\n}\n\nfragment CompanyUpdater_company on Company {\n  updatedBy {\n    firstName\n    lastName\n    email\n    ...MaterialAvatar_user\n    id\n  }\n}\n\nfragment CompanyUpdatedAt_company on Company {\n  updatedAt\n}\n\nfragment CustomizableSelect_customizable on CustomizableInterface {\n  id\n  __typename\n  customSelectFields {\n    customField {\n      id\n    }\n    option {\n      id\n    }\n    selectOtherValue\n    id\n  }\n}\n\nfragment CustomizableUserSelect_customizable on CustomizableInterface {\n  id\n  __typename\n  customUserSelectFields {\n    customField {\n      id\n    }\n    user {\n      id\n      firstName\n      lastName\n      email\n      avatar\n      ...MaterialAvatar_user\n    }\n    id\n  }\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "massUpdateCompaniesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "massUpdateCompanies",
        "storageKey": null,
        "args": v1,
        "concreteType": "MassUpdateCompaniesPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "companyEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "CompanyRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "CompanyUpdater_company",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "CompanyUpdatedAt_company",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "CustomizableSelect_customizable",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "CustomizableUserSelect_customizable",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "massUpdateCompaniesMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "massUpdateCompanies",
        "storageKey": null,
        "args": v1,
        "concreteType": "MassUpdateCompaniesPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "companyEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "CompanyRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Company",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updatedBy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      v4,
                      v5,
                      v6
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "updatedAt",
                    "args": null,
                    "storageKey": null
                  },
                  v5,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customSelectFields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomSelectFieldType",
                    "plural": true,
                    "selections": [
                      v8,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "option",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionType",
                        "plural": false,
                        "selections": v7
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "selectOtherValue",
                        "args": null,
                        "storageKey": null
                      },
                      v5
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customUserSelectFields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomUserSelectFieldType",
                    "plural": true,
                    "selections": [
                      v8,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": [
                          v5,
                          v2,
                          v3,
                          v4,
                          v6
                        ]
                      },
                      v5
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4698bc2ba3ccdf13d04066ba0ee2087c';
module.exports = node;
