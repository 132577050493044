/* @flow */
import * as React from 'react';
import {
  type ConnectDragPreview,
  type ConnectDragSource,
  type ConnectDropTarget,
  type DragSourceConnector,
  type DragSourceMonitor,
  type DropTargetConnector,
  type DropTargetMonitor,
  DragSource,
  DropTarget,
} from 'react-dnd';
import styled, { css } from 'styled-components';

import DragIcon from 'images/drag-icon.svg';

import { type SectionType } from './index';

const StyledDragIcon = styled(DragIcon)`
  position: absolute;
  top: 10px;
  left: 5px;
  z-index: 1;
  width: 14px;
  height: 20px;
  cursor: grab;
  opacity: 0.42;
  transition: 0.2s;
  &:hover {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: relative;
  ${props =>
    props.dragging &&
    css`
      background: ${props.dragging ? css`rgba(151, 151, 151, 0.1)` : 'transparent'};
      box-shadow: 0 0 5px inset rgba(0, 0, 0, 0.1);
      > * {
        visibility: hidden;
      }
    `};
`;

class DraggableSection extends React.PureComponent<{
  children: React.Node,
  connectDragSource?: ConnectDragSource,
  connectDragPreview?: ConnectDragPreview,
  connectDropTarget?: ConnectDropTarget,
  isDragging?: boolean,
  /* eslint-disable react/no-unused-prop-types */
  // Used in Hover handler
  section: SectionType,
  draggableGroup: string,
  onMoveStart?: () => void,
  onMove: (sourceOrder: number, targetOrder: number) => void,
  onMoveEnd: () => void,
}> {
  render() {
    const { connectDragSource, connectDropTarget, connectDragPreview } = this.props;

    if (!connectDragSource || !connectDropTarget || !connectDragPreview) return null;

    return connectDropTarget(
      <div>
        {connectDragPreview(
          <div>
            <Container dragging={this.props.isDragging}>
              {this.props.children}
              {connectDragSource(
                <div>
                  <StyledDragIcon />
                </div>,
              )}
            </Container>
          </div>,
        )}
      </div>,
    );
  }
}

const ConnectedDraggableComponent = DragSource(
  'SECTION',
  {
    beginDrag(props: $PropertyType<DraggableSection, 'props'>) {
      if (props.onMoveStart) {
        props.onMoveStart();
      }
      return {
        name: props.section.name,
        order: props.section.order,
        draggableGroup: props.draggableGroup,
      };
    },
    endDrag(props: $PropertyType<DraggableSection, 'props'>) {
      props.onMoveEnd();
    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
)(DraggableSection);

const ConnectedDroppableComponent = DropTarget(
  'SECTION',
  {
    hover(props: $PropertyType<DraggableSection, 'props'>, monitor: DropTargetMonitor) {
      const dragItem = monitor.getItem();
      const hoverOrder = props.section.order;
      const hoverGroup = props.draggableGroup;

      if (dragItem.order === hoverOrder || dragItem.draggableGroup !== hoverGroup) return;

      props.onMove(dragItem.order, hoverOrder);

      // eslint-disable-next-line no-param-reassign
      monitor.getItem().order = hoverOrder;
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(ConnectedDraggableComponent);

export default ConnectedDroppableComponent;
