/**
 * @flow
 * @relayHash e884f9afc231a70640c0bd880b1a79aa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VendorExpensesTableRenderer_expenses$ref = any;
export type Direction = "ASC" | "DESC";
export type ExpenseSort = "ACTUAL_AMOUNT" | "BUDGET_CATEGORY" | "EVENT_NAME" | "NAME" | "NOTE" | "PAYMENT_STATUS" | "VENDOR_COMPANY";
export type VendorExpensesQueryVariables = {|
  vendorId: string,
  sort?: ?ExpenseSort,
  direction: Direction,
|};
export type VendorExpensesQueryResponse = {|
  +vendor: ?{|
    +expenses?: {|
      +totalCount: number,
      +edges: $ReadOnlyArray<{|
        +node: {|
          +$fragmentRefs: VendorExpensesTableRenderer_expenses$ref
        |}
      |}>,
    |}
  |}
|};
export type VendorExpensesQuery = {|
  variables: VendorExpensesQueryVariables,
  response: VendorExpensesQueryResponse,
|};
*/


/*
query VendorExpensesQuery(
  $vendorId: ID!
  $sort: ExpenseSort
  $direction: Direction!
) {
  vendor: node(id: $vendorId) {
    __typename
    ... on Vendor {
      expenses(first: 1000, sort: $sort, direction: $direction) {
        totalCount
        edges {
          node {
            ...VendorExpensesTableRenderer_expenses
            id
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    id
  }
}

fragment VendorExpensesTableRenderer_expenses on Expense {
  id
  ...VendorExpenseName_expense
  ...VendorExpenseAmount_expense
  ...VendorExpenseNote_expense
  ...VendorExpenseCategory_expense
  ...VendorExpensePaymentStatus_expense
  ...VendorExpenseAction_expense
  ...EventName_expense
}

fragment VendorExpenseName_expense on Expense {
  id
  name
  event {
    slug
    id
  }
}

fragment VendorExpenseAmount_expense on Expense {
  id
  actualAmount
  viewerCanUpdateAmount
  event {
    slug
    id
  }
}

fragment VendorExpenseNote_expense on Expense {
  id
  note
  event {
    slug
    id
  }
}

fragment VendorExpenseCategory_expense on Expense {
  budgetCategory {
    id
    ...CategoryPill_category
  }
}

fragment VendorExpensePaymentStatus_expense on Expense {
  id
  actualAmount
  paidAmount
  viewerCanUpdatePaymentStatus
  event {
    slug
    id
  }
}

fragment VendorExpenseAction_expense on Expense {
  id
  name
  event {
    id
  }
}

fragment EventName_expense on Expense {
  event {
    slug
    name
    id
  }
}

fragment CategoryPill_category on BudgetCategory {
  name
  color
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vendorId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "ExpenseSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "vendorId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction",
    "type": "Direction"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "ExpenseSort"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "VendorExpensesQuery",
  "id": null,
  "text": "query VendorExpensesQuery(\n  $vendorId: ID!\n  $sort: ExpenseSort\n  $direction: Direction!\n) {\n  vendor: node(id: $vendorId) {\n    __typename\n    ... on Vendor {\n      expenses(first: 1000, sort: $sort, direction: $direction) {\n        totalCount\n        edges {\n          node {\n            ...VendorExpensesTableRenderer_expenses\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment VendorExpensesTableRenderer_expenses on Expense {\n  id\n  ...VendorExpenseName_expense\n  ...VendorExpenseAmount_expense\n  ...VendorExpenseNote_expense\n  ...VendorExpenseCategory_expense\n  ...VendorExpensePaymentStatus_expense\n  ...VendorExpenseAction_expense\n  ...EventName_expense\n}\n\nfragment VendorExpenseName_expense on Expense {\n  id\n  name\n  event {\n    slug\n    id\n  }\n}\n\nfragment VendorExpenseAmount_expense on Expense {\n  id\n  actualAmount\n  viewerCanUpdateAmount\n  event {\n    slug\n    id\n  }\n}\n\nfragment VendorExpenseNote_expense on Expense {\n  id\n  note\n  event {\n    slug\n    id\n  }\n}\n\nfragment VendorExpenseCategory_expense on Expense {\n  budgetCategory {\n    id\n    ...CategoryPill_category\n  }\n}\n\nfragment VendorExpensePaymentStatus_expense on Expense {\n  id\n  actualAmount\n  paidAmount\n  viewerCanUpdatePaymentStatus\n  event {\n    slug\n    id\n  }\n}\n\nfragment VendorExpenseAction_expense on Expense {\n  id\n  name\n  event {\n    id\n  }\n}\n\nfragment EventName_expense on Expense {\n  event {\n    slug\n    name\n    id\n  }\n}\n\nfragment CategoryPill_category on BudgetCategory {\n  name\n  color\n}\n",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "vendor",
          "expenses"
        ]
      }
    ]
  },
  "fragment": {
    "kind": "Fragment",
    "name": "VendorExpensesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "vendor",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Vendor",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "expenses",
                "name": "__VendorExpenses_expenses_connection",
                "storageKey": null,
                "args": null,
                "concreteType": "ExpenseRequiredConnection",
                "plural": false,
                "selections": [
                  v2,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ExpenseRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Expense",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "VendorExpensesTableRenderer_expenses",
                            "args": null
                          },
                          v3
                        ]
                      },
                      v4
                    ]
                  },
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VendorExpensesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "vendor",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          v3,
          v6,
          {
            "kind": "InlineFragment",
            "type": "Vendor",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "expenses",
                "storageKey": null,
                "args": v7,
                "concreteType": "ExpenseRequiredConnection",
                "plural": false,
                "selections": [
                  v2,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ExpenseRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Expense",
                        "plural": false,
                        "selections": [
                          v6,
                          v8,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "event",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Event",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "slug",
                                "args": null,
                                "storageKey": null
                              },
                              v6,
                              v8
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "actualAmount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "viewerCanUpdateAmount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "note",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "budgetCategory",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BudgetCategory",
                            "plural": false,
                            "selections": [
                              v6,
                              v8,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "color",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "paidAmount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "viewerCanUpdatePaymentStatus",
                            "args": null,
                            "storageKey": null
                          },
                          v3
                        ]
                      },
                      v4
                    ]
                  },
                  v5
                ]
              },
              {
                "kind": "LinkedHandle",
                "alias": null,
                "name": "expenses",
                "args": v7,
                "handle": "connection",
                "key": "VendorExpenses_expenses",
                "filters": []
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '05197ca785ad82097a8aa0335aa30c91';
module.exports = node;
