/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';

const StyledInfoBox = styled(InfoBox)`
  width: 270px;
`;

export default class SimpleInfoBox extends React.Component<{
  infoBoxOffset?: number,
  children: React.Node,
}> {
  render() {
    const googleMaps = window.google.maps;
    const infoBoxOptions = {
      closeBoxURL: '',
      infoBoxClearance: new googleMaps.Size(20, 20),
      enableEventPropagation: false,
      pixelOffset: new googleMaps.Size(this.props.infoBoxOffset || 0, 0),
    };

    return <StyledInfoBox options={infoBoxOptions}>{this.props.children}</StyledInfoBox>;
  }
}
