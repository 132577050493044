/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';

import FeatureAccessContext from 'contexts/FeatureAccess';

import getBudgetQuarterString from 'utils/fiscal/getBudgetQuarterString';

import Header from 'components/Sidebar/Header';
import MenuItem from 'components/Sidebar/MenuItem';

import type { SettingsMenu_org } from './__generated__/SettingsMenu_org.graphql';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Title = styled.div`
  margin-bottom: 25px;
  margin-left: 9px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
`;

type Props = { org: SettingsMenu_org, pathPrefix: string, location: Location };

class SettingsMenu extends React.Component<Props> {
  static contextType = FeatureAccessContext;

  render() {
    const { org, pathPrefix, location } = this.props;
    return (
      <Menu>
        <Header>
          <Title>Settings</Title>
          <MenuItem to={`${pathPrefix}/general`}>General</MenuItem>
          <MenuItem to={`${pathPrefix}/events`}>Events</MenuItem>
          {this.context.legacyFeatures ? (
            <MenuItem to={`${pathPrefix}/contacts`}>Contacts</MenuItem>
          ) : (
            <MenuItem to={`${pathPrefix}/vendors`}>Vendors</MenuItem>
          )}
          {org.viewerCanViewReporting && (
            <MenuItem
              isActive={() => location.pathname.startsWith(`${pathPrefix}/budgets`)}
              to={`${pathPrefix}/budgets/${getBudgetQuarterString(org.settings.fiscalYearStart)}`}
            >
              Budgets
            </MenuItem>
          )}
          {org.viewerCanUpdate && (
            <MenuItem to={`${pathPrefix}/integrations`}>Integrations</MenuItem>
          )}
        </Header>
      </Menu>
    );
  }
}

export default createFragmentContainer(
  SettingsMenu,
  graphql`
    fragment SettingsMenu_org on Org {
      viewerCanUpdate
      viewerCanViewReporting
      settings {
        fiscalYearStart
      }
    }
  `,
);
