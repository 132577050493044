/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type TaskStatus = "COMPLETED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeliverableStatus_deliverable$ref: FragmentReference;
export type DeliverableStatus_deliverable = {|
  +id: string,
  +status: TaskStatus,
  +blockedByOtherTasks: boolean,
  +viewerCanUpdateStatus: boolean,
  +$refType: DeliverableStatus_deliverable$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "DeliverableStatus_deliverable",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "blockedByOtherTasks",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateStatus",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e937a720b60df102931b4b5f41fa2c2b';
module.exports = node;
