/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import ShareDropdownOverlay, { type OptionType } from './ShareDropdownOverlay';

const Icon = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #868f96;
  position: relative;
  ${props =>
    props.overlayShown &&
    css`
      visibility: visible !important; // Keep dots shown on table row mouse out
    `};
  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #868f96;
  }
  &:after {
    left: 6px;
  }
  &:before {
    left: -6px;
  }
`;

export const IconContainer = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    ${Icon} {
      background: ${props => props.hoverColor || props.theme.secondaryActionDarkerColor};
      &:after,
      &:before {
        background: ${props => props.hoverColor || props.theme.secondaryActionDarkerColor};
      }
    }
  }
`;

const Container = styled.div`
  ${props =>
    !props.noBorder && `border-left: 1px solid ${props.theme.borderColor};`} padding-left: 10px;
  margin-left: 10px;
  position: relative;
  @media (${props => props.theme.mobileOnly}) {
    border-left: 0;
  }
`;

export { Icon };

export default class ShareDropdown extends React.PureComponent<
  {
    options?: Array<OptionType>,
    className?: string,
    noBorder?: boolean,
    hoverColor?: string,
    overlayContainer?: ?HTMLElement,
  },
  {
    overlayShown: boolean,
  },
> {
  state = { overlayShown: false };

  button: ?HTMLElement;

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  preventDefault = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  render() {
    return (
      <Container
        className={this.props.className}
        noBorder={this.props.noBorder}
        onClick={this.preventDefault}
      >
        <IconContainer
          ref={el => {
            this.button = el;
          }}
          onClick={this.handleOverlayShow}
          hoverColor={this.props.hoverColor}
        >
          <Icon overlayShown={this.state.overlayShown} />
        </IconContainer>

        {this.state.overlayShown && (
          <ShareDropdownOverlay
            show={this.state.overlayShown}
            onHide={this.handleOverlayHide}
            target={this.button}
            container={this.props.overlayContainer || this}
            options={this.props.options}
          />
        )}
      </Container>
    );
  }
}
