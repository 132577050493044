/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import { getEventStateLabel } from 'config/eventStates';

import { type CellPropsType } from 'components/budget/Table';

import { type EventCellPropsType } from './index';

import type { EventStatus_contactEvent } from './__generated__/EventStatus_contactEvent.graphql';

const EventStatus = ({
  contactEvent,
}: CellPropsType<EventCellPropsType<EventStatus_contactEvent>>) =>
  getEventStateLabel(contactEvent.node.commitmentLevel);

export default createFragmentContainer(
  EventStatus,
  graphql`
    fragment EventStatus_contactEvent on ContactEventEdge {
      node {
        commitmentLevel
      }
    }
  `,
);
