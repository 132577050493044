/* @flow */
import React from 'react';
import styled from 'styled-components';

import CheckBox from 'components/SharedForm/CheckBox';
import Radio from 'components/SharedForm/Radio';

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  font-size: 14px;
  cursor: default;
`;

export default class SelectOptionRow extends React.PureComponent<{
  type: 'checkbox' | 'radio',
  checked: boolean,
  option: {
    value: string,
    label: string,
  },
  onChange: (label: string, checked: boolean) => void,
}> {
  handleChange = () => {
    this.props.onChange(this.props.option.value || '', !this.props.checked);
  };

  render() {
    const { type, checked, option } = this.props;
    return (
      <OptionRow>
        {type === 'checkbox' ? (
          <CheckBox checked={checked} onClick={this.handleChange} label={option.label} compact />
        ) : (
          <Radio checked={checked} onClick={this.handleChange} label={option.label} compact />
        )}
      </OptionRow>
    );
  }
}
