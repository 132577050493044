/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import revertImport from 'graph/mutations/import/revertImport';
import showModernMutationError from 'graph/utils/showModernMutationError';

import SuccessImage from 'images/successfulExport.svg';
import ProgressBar from 'components/material/ProgressBar';
import Window, { WindowContent } from 'components/material/Window';

const StyledWindowContent = styled(WindowContent)`
  padding: 20px 25px 15px 25px;
`;

const Message = styled.div`
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: #4a5665;
  ${props =>
    props.centered &&
    css`
      text-align: center;
    `};
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: 10px;
  > div {
    background-color: #26d17c;
  }
`;

const Success = styled.div`
  padding: 11px 0 27px 0;
  text-align: center;
  > img {
    margin-bottom: 13px;
  }
`;

export default class RevertImportWindow extends React.Component<
  {
    orgId: string,
    importLogId: string,
    onFinish: () => void,
  },
  {
    inProgress: boolean,
  },
> {
  state = { inProgress: true };

  timeout: TimeoutID;

  componentDidMount() {
    revertImport(this.props.orgId, this.props.importLogId)
      .then(() => {
        this.setState({ inProgress: false });
        this.timeout = setTimeout(() => this.props.onFinish(), 2000);
      })
      .catch(showModernMutationError);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    return (
      <Window size="small" onHide={() => {}}>
        <StyledWindowContent>
          {this.state.inProgress ? (
            <React.Fragment>
              <Message>Reverting import</Message>
              <StyledProgressBar indeterminate />
            </React.Fragment>
          ) : (
            <Success>
              <SuccessImage />
              <Message centered>Import successfully reverted</Message>
            </Success>
          )}
        </StyledWindowContent>
      </Window>
    );
  }
}
