/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import Content from '../layout';
import headerTitles from './lib/headerTitles';
import WorkspaceRoutes from './WorkspaceRoutes';

import type { WorkspaceQueryResponse } from './__generated__/WorkspaceQuery.graphql';

type Props = { location: Location, match: Match };

const query = graphql`
  query WorkspaceQuery {
    org {
      id
      ...WorkspaceRoutes_org
    }
  }
`;

const StyledContent = styled(Content)`
  padding: 0 0 24px;
`;

const Workspace = ({ match, location }: Props) => {
  const title = headerTitles[location.pathname.split('/').pop()] || headerTitles.members;
  return (
    <DocumentTitle title={title}>
      <StyledContent>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(props: WorkspaceQueryResponse) => (
            <WorkspaceRoutes org={props.org} pathPrefix={match.path} />
          )}
        />
      </StyledContent>
    </DocumentTitle>
  );
};

export default Workspace;
