/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location as LocationType, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import getHomepageUrl from 'utils/getHomepageUrl';
import withQueryParams from 'utils/routing/withQueryParams';

import updateEvent from 'graph/mutations/event/updateEvent';

import BackLink from 'components/BackLink';
import HeaderTitle from 'components/HeaderTitle';
import ProgressBar from 'components/material/ProgressBar';
import PageHeader, { HeaderContent } from 'components/page/PageHeader';
import headerTitles from 'views/Main/Dashboard/lib/headerTitles';

import DateComponent from './Date';
import EventHeaderActions from './EventHeaderActions';
import EventHeaderStatus from './EventHeaderStatus';
import EventWebsite from './EventWebsite';
import Location from './Location';
import PhysicalLocation from './PhysicalLocation';

import type { EventHeader_event } from './__generated__/EventHeader_event.graphql';
import type { EventHeader_user } from './__generated__/EventHeader_user.graphql';

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 30px 0 15px;
`;

const EventDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 2px 23px;

  @media (${props => props.theme.mobileOnly}) {
    line-height: 1;
    border: 0;
    padding-top: 5px;
    padding-left: 38px;
    padding-bottom: 10px;
    margin-top: 0;
    .event-detail {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  > div {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    @media (${props => props.theme.mobileOnly}) {
      margin-right: 15px;
    }
  }
  &:empty {
    display: none;
  }
`;

const EventName = styled(PageHeader)`
  display: flex;
  height: auto;
  padding: 0 25px;
  color: #3e4859;

  ${HeaderContent} {
    flex-wrap: wrap;
  }

  .hamburger {
    display: none;
  }
`;

const StyledHeaderTitle = styled(HeaderTitle)`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  font-size: 22px;
  font-weight: 500;
`;

const TeamLink = styled(Link)`
  flex-shrink: 0;
  margin-left: 10px;
  padding-top: 3px;
  cursor: pointer;
  color: #5e5bff;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledBackLink = styled(BackLink)`
  margin: 0;
`;

const StyledProgressBar = styled(ProgressBar)`
  height: 7px;
  margin: 8px 6px 0 0;
  background-color: #c5f4eb !important;
  border-radius: 10px;
  > div {
    background-color: #54d4bb;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
  padding-right: 30px;
`;

const Progress = styled.div`
  width: 130px;
`;

const ProgressText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
`;
const ProgressTitle = styled.div`
  color: #6e7884;
`;

const ProgressPerCent = styled.div`
  font-weight: bold;
`;

class EventHeader extends React.Component<
  {
    event: EventHeader_event,
    user: EventHeader_user,
    onToggleSidebar: () => void,
    // eslint-disable-next-line react/no-unused-prop-types
    location: LocationType,
    queryParams: { from?: string, fromUrl?: string },
  },
  {
    previousPage: ?{ from: string, url: string },
  },
> {
  state = {
    previousPage: null,
  };

  static getDerivedStateFromProps(
    nextProps: $PropertyType<EventHeader, 'props'>,
    prevState: $PropertyType<EventHeader, 'state'>,
  ) {
    if (
      nextProps.location.state == null ||
      nextProps.location.state.from == null ||
      (prevState.previousPage && prevState.previousPage.from === nextProps.location.state.from)
    ) {
      return null;
    }

    return {
      previousPage: {
        from: nextProps.location.state.from,
        url: nextProps.location.state.fromUrl,
      },
    };
  }

  componentDidMount() {
    const { queryParams } = this.props;
    if (queryParams && queryParams.from && queryParams.fromUrl) {
      this.setState({
        previousPage: {
          from: queryParams.from,
          url: queryParams.fromUrl,
        },
      });
    }
  }

  handleTitleChange = name => {
    updateEvent(this.props.event.id, { name }, 'header');
  };

  handleWebsiteChange = website => {
    updateEvent(
      this.props.event.id,
      { website: website && website.trim() === '' ? null : website },
      'header',
    );
  };

  handleDateChange = dates => {
    const { event } = this.props;
    updateEvent(event.id, { ...dates }, 'header');
  };

  getBackLink = () => {
    const user = this.props.user;
    if (this.state.previousPage) {
      return this.state.previousPage.url;
    }

    if (this.props.queryParams.fromUrl) {
      return this.props.queryParams.fromUrl;
    }

    return getHomepageUrl(user.homepageListId || user.homepage, user.id);
  };

  getTeamEventsLink = () => `/dashboard/all_events?teamIds=${this.props.event.team.id}`;

  render() {
    const { event, onToggleSidebar } = this.props;
    const previousPage = this.state.previousPage;

    return (
      <>
        <HeaderTop>
          <StyledBackLink
            label={previousPage ? headerTitles[previousPage.from] || 'Saved View' : 'Homepage'}
            path={this.getBackLink()}
          />
          {event.progress != null && (
            <Progress>
              <ProgressText>
                <ProgressTitle>Progress</ProgressTitle>
                <ProgressPerCent>{`${event.progress}%`}</ProgressPerCent>
              </ProgressText>
              <StyledProgressBar value={event.progress} />
            </Progress>
          )}
        </HeaderTop>
        <HeaderRow>
          <EventName onToggleSidebar={onToggleSidebar}>
            <StyledHeaderTitle
              value={event.name}
              onChange={this.handleTitleChange}
              disableEditing={!event.viewerCanUpdate}
            />
            {(event.eventRequestSubmission == null ||
              event.eventRequestSubmission.status === 'APPROVED') && (
              <TeamLink to={this.getTeamEventsLink()}>/ {event.team.name}</TeamLink>
            )}
          </EventName>
          <EventHeaderActions event={event} />
        </HeaderRow>
        <EventDetailsWrapper>
          <EventHeaderStatus event={event} />
          {(event.viewerCanUpdate || event.startDate) && (
            <DateComponent
              id="eventHeaderDate"
              startDate={event.startDate}
              endDate={event.endDate}
              startDateAllDay={event.startDateAllDay}
              endDateAllDay={event.endDateAllDay}
              onChange={this.handleDateChange}
              tz={event.tz}
              disabled={!event.viewerCanUpdate}
            />
          )}
          <Location event={event} />
          <PhysicalLocation event={event} />

          {((event.viewerCanUpdate && !event.mappedToSalesforce) || event.website) && (
            <EventWebsite
              value={event.website}
              disable={!event.viewerCanUpdate || event.mappedToSalesforce}
              onChange={this.handleWebsiteChange}
            />
          )}
        </EventDetailsWrapper>
      </>
    );
  }
}

export default createFragmentContainer(withRouter(withQueryParams(EventHeader)), {
  event: graphql`
    fragment EventHeader_event on Event {
      id
      __typename
      eventRequestSubmission {
        status
      }
      dbId
      slug
      name
      startDate
      endDate
      startDateAllDay
      endDateAllDay
      website
      progress
      mappedToSalesforce(field: WEBSITE)
      viewerCanUpdate
      ...EventHeaderStatus_event
      ...EventHeaderActions_event
      ...Location_event
      ...PhysicalLocation_event
      tz
      virtualLocation
      primaryLocation {
        id
      }
      team {
        id
        name
      }
    }
  `,
  user: graphql`
    fragment EventHeader_user on User {
      id
      homepage
      homepageListId
    }
  `,
});
