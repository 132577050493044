/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';
import { TableLink } from 'components/material/table';

import EventRequestsItemRequestedBy_eventRequestSubmission from './__generated__/EventRequestsItemRequestedBy_eventRequestSubmission.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

class EventRequestsItemRequestedBy extends React.PureComponent<{
  eventRequestSubmission: EventRequestsItemRequestedBy_eventRequestSubmission,
  onColumnLoad?: (value: string, str: string, extraSpace?: number) => void,
  name: string,
  location: Location,
  orgName?: string,
  readOnly?: boolean,
  className?: boolean,
}> {
  componentDidMount() {
    const submission = this.props.eventRequestSubmission;
    const requester = submission.user || submission.contact;
    const onColumnLoad = this.props.onColumnLoad;
    if (onColumnLoad) {
      onColumnLoad(this.props.name, fullNameOfUser(requester), 31); // Avatar 26 + 5 margin = 31
    }
  }

  getSubmittedByLink = () => {
    const {
      orgName,
      eventRequestSubmission: { user, contact },
    } = this.props;

    const state = {
      prevPath: orgName ? '/events' : '/dashboard/event_requests',
      prevPage: true,
      prevPageLabel: orgName ? `${orgName} Events` : 'Requests Inbox',
      search: this.props.location.search,
    };

    if (user) {
      return {
        pathname: `/workspace/members/${user.id}`,
        state,
      };
    }

    if (contact) {
      return {
        pathname: `/contacts/people/${contact.slug}`,
        state,
      };
    }
    return '/';
  };

  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  render() {
    const {
      readOnly,
      className,
      eventRequestSubmission: { user, contact },
    } = this.props;
    const requester = user || contact;
    if (!requester) {
      return null;
    }

    if (readOnly) {
      return (
        <Container className={className}>
          <MaterialAvatar radius={13} user={requester} />
          <TextWrapper>{fullNameOfUser(requester)}</TextWrapper>
        </Container>
      );
    }

    return (
      <Container className={className}>
        <MaterialAvatar radius={13} user={requester} />
        <TableLink to={this.getSubmittedByLink()} onClick={this.stopPropagation}>
          <TextWrapper>{fullNameOfUser(requester)}</TextWrapper>
        </TableLink>
      </Container>
    );
  }
}

export default createFragmentContainer(
  withRouter(EventRequestsItemRequestedBy),
  graphql`
    fragment EventRequestsItemRequestedBy_eventRequestSubmission on EventRequestSubmission {
      user {
        id
        firstName
        lastName
        email
        ...MaterialAvatar_user
      }
      contact {
        slug
        firstName
        lastName
        email
        ...MaterialAvatar_user
      }
    }
  `,
);
