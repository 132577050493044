/* @flow */

import React from 'react';
import styled from 'styled-components';

const Onsite = styled.div`
  display: inline-block;
  height: 23px;
  margin: 0 3px;
  padding: 0 10px;
  border: 1px solid ${props => (props.onsite ? '#29cc71' : '#828b93')};
  border-radius: 11px;
  color: ${props => (props.onsite ? '#29cc71' : '#828b93')};
  font-size: 13px;
  text-align: center;
  line-height: 21px;
`;

const BriefUserOnsite = (props: { onsite: ?boolean }) => {
  const { onsite } = props;
  return typeof onsite === 'boolean' ? (
    <Onsite onsite={onsite}>{onsite ? 'On-site' : 'Remote'}</Onsite>
  ) : null;
};

export default BriefUserOnsite;
