/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import StepProgress from 'components/StepProgress';

import Confirmation from './Confirmation';
import Importing from './Importing';
import Mapping from './Mapping';
import Uploading from './Uploading';

const WindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 460px;
`;

const StyledWindowContent = styled(WindowContent)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export type ImportingSteps =
  | 'uploading'
  | 'validating'
  | 'mapping'
  | 'configuring'
  | 'confirmation'
  | 'importing';
export type ProcessingResults = $ReadOnlyArray<{ [column: string]: string }>;
export type MappingsType<F: string> = { [column: string]: F };
export type FieldsType<F: string> = {
  [field: F]: { label: string, guess: RegExp, multiple?: boolean, custom?: boolean },
};
export type ImportData<F: string> = $ReadOnlyArray<{ [field: F]: ?string }>;
export type ErrorType = { +rowIndex: number, +error: string };
export type ImportResults = {
  +importedCount: number,
  +mergedCount?: ?number,
  +errors: $ReadOnlyArray<ErrorType>,
};
export type ValidatingStepProps<M: {}> = {
  onStartOver: () => void,
  onNext: () => void,
  uploadedData: ProcessingResults,
  mappings: M,
};
export type ConfigStepProps<F: string> = { onNext: () => void, importData: ImportData<F> };
export type ImportProps<M: {}> = {
  data: $ReadOnlyArray<M>,
  mappings: M,
  fileName: string,
  fileContent: string,
};

export default class ImportWindow<F: string, M: { [F]: any }> extends React.Component<
  {
    title: string,
    fields: FieldsType<F>,
    importResourceName: string,
    renderValidatingStep?: ?(props: ValidatingStepProps<M>) => React.Node,
    renderConfigStep?: ?(props: ConfigStepProps<F>) => React.Node,
    renderConfirmationMessage: (count: number) => string,
    onValidateMappings: (mappings: MappingsType<F>) => ?string,
    onImport: (props: ImportProps<M>) => Promise<?ImportResults>,
    onClose: () => void,
  },
  {
    step: ImportingSteps,
    processingData: ?ProcessingResults,
    fileName: string,
    fileContent: string,
    mappings: ?MappingsType<F>,
  },
> {
  state = {
    step: 'uploading',
    processingData: null,
    fileName: '',
    fileContent: '',
    mappings: null,
  };

  handleUploadEnd = (obj: {
    fileName: string,
    results: ProcessingResults,
    fileContent: string,
  }) => {
    this.setState({
      step: 'mapping',
      processingData: obj.results,
      fileName: obj.fileName,
      fileContent: obj.fileContent,
    });
  };

  handleStartOver = () => {
    this.setState({
      step: 'uploading',
      processingData: null,
      fileName: '',
      fileContent: '',
    });
  };

  handleValidatingEnd = () => {
    this.setState({ step: 'confirmation' });
  };

  handleConfigEnd = () => {
    this.setState({ step: this.props.renderValidatingStep ? 'validating' : 'confirmation' });
  };

  handleStartImport = () => {
    this.setState({ step: 'importing' });
  };

  handleMappingEnd = <T: string>(mappings: MappingsType<T>) => {
    const nextStep = this.props.renderValidatingStep ? 'validating' : 'confirmation';
    this.setState({ step: this.props.renderConfigStep ? 'configuring' : nextStep, mappings });
  };

  handleImport = () => {
    return this.props.onImport({
      data: this.importData(),
      mappings: this.invertMappings(),
      fileName: this.state.fileName,
      fileContent: this.state.fileContent,
    });
  };

  invertMappings(): M {
    const mappings = Object.keys(this.state.mappings || {}).reduce((obj, column) => {
      if (!this.state.mappings) return obj;

      const field = this.state.mappings[column];
      if (this.props.fields[field].custom) {
        return {
          ...obj,
          customFields: [...(obj.customFields || []), { customFieldId: field, columnName: column }],
        };
      }

      if (!this.props.fields[field].multiple) {
        return { ...obj, [field]: column };
      }
      return { ...obj, [field]: obj[field] ? [...obj[field], column] : [column] };
    }, {});

    return (mappings: any);
  }

  importData(): $ReadOnlyArray<M> {
    const { processingData, mappings } = this.state;

    if (!processingData || !mappings) return [];

    const data = processingData.map(row => {
      return Object.keys(mappings).reduce((obj, column) => {
        const field = mappings[column];

        if (!this.props.fields[field].multiple) {
          return { ...obj, [field]: row[column] };
        }
        return { ...obj, [field]: obj[field] ? [...obj[field], row[column]] : [row[column]] };
      }, {});
    });

    return (data: any);
  }

  render() {
    const {
      title,
      fields,
      importResourceName,
      renderValidatingStep,
      renderConfigStep,
      renderConfirmationMessage,
      onClose,
      onValidateMappings,
    } = this.props;
    const { step, processingData, fileName, mappings } = this.state;

    return (
      <Window size="large" onHide={step === 'importing' ? null : onClose}>
        <WindowContainer>
          <WindowHeader>
            <WindowTitle>{title}</WindowTitle>
            {step !== 'importing' && <WindowClose onClick={onClose} />}
          </WindowHeader>
          <StyledWindowContent>
            <StepProgress
              current={[
                ['uploading', 'validating'],
                ['mapping'],
                ['configuring', 'confirmation', 'importing'],
              ].findIndex(steps => steps.includes(step))}
              steps={['File Upload', 'Mapping', 'Import']}
            />

            {step === 'uploading' && (
              <Uploading
                onComplete={this.handleUploadEnd}
                fields={fields}
                importResourceName={importResourceName}
                onValidateMappings={onValidateMappings}
              />
            )}
            {step === 'validating' &&
              processingData &&
              mappings &&
              renderValidatingStep &&
              renderValidatingStep({
                onStartOver: this.handleStartOver,
                onNext: this.handleValidatingEnd,
                uploadedData: processingData,
                mappings: this.invertMappings(),
              })}
            {step === 'mapping' && processingData != null && (
              <Mapping
                fields={fields}
                importColumns={Object.keys(processingData[0])}
                fileName={fileName}
                importResourceName={importResourceName}
                onNext={this.handleMappingEnd}
                onCancel={onClose}
                onValidateMappings={onValidateMappings}
              />
            )}
            {step === 'configuring' &&
              renderConfigStep &&
              renderConfigStep({ onNext: this.handleConfigEnd, importData: this.importData() })}
            {step === 'confirmation' && (
              <Confirmation
                message={renderConfirmationMessage(processingData ? processingData.length : 0)}
                onImport={this.handleStartImport}
                onCancel={onClose}
              />
            )}
            {step === 'importing' && mappings != null && processingData != null && (
              <Importing
                mappings={mappings}
                data={processingData}
                importResourceName={importResourceName}
                fileName={fileName}
                onPrev={this.handleConfigEnd}
                onClose={onClose}
                onImport={this.handleImport}
              />
            )}
          </StyledWindowContent>
        </WindowContainer>
      </Window>
    );
  }
}
