/* @flow */
import UIActions from 'actions/UIActions';

export type MutationError = {
  source?: ?{
    errors?: ?Array<{
      message: string,
    }>,
  },
  message?: string,
};

/**
 * Show the error returned by a failed mutation in a top right notification,
 * then throw the error so error handlers can catch it.
 */
export default function showModernMutationError(error: MutationError) {
  if (error.source && error.source.errors) {
    const errorMessage = error.source.errors.map(err => err.message).join('  \n');

    UIActions.showErrorMessage(['Something went wrong.', errorMessage]);

    console.error(error);
  } else if (typeof error.message === 'string') {
    UIActions.showErrorMessage([error.message]);
  } else {
    UIActions.showErrorMessage([
      'Sorry, something went wrong.',
      `
      Our team has been notified.
      Please let us know if the problem persists.
      `,
    ]);
  }
}
