/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  CreateEventListInput,
  createEventListMutationResponse,
} from './__generated__/createEventListMutation.graphql';

export type MutationInput = CreateEventListInput;

const mutation = graphql`
  mutation createEventListMutation($input: CreateEventListInput!) {
    createEventList(input: $input) {
      eventListsEdge {
        node {
          id
          name
          shared
          exportScheduler {
            frequency
            scheduledOn
            recipients {
              edges {
                node {
                  id
                  firstName
                  lastName
                  email
                }
              }
            }
          }
        }
      }
      user {
        eventLists(first: 5) {
          totalCount
        }
        allEventLists: eventLists {
          totalCount
        }
      }
    }
  }
`;

export default function createEventList(
  input: CreateEventListInput,
  clientInput: { userId: string },
): Promise<createEventListMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: { input },
    updater: addRecordToConnection({
      parentId: clientInput.userId,
      edgeName: 'eventListsEdge',
      connections: [{ key: 'DashboardMenuSavedViews_eventLists' }],
      rangeBehavior: 'prepend',
    }),
  });
}
