/* @flow */

const scheduleFields: { [key: string]: string } = {
  title: 'Title',
  time: 'Time',
  description: 'Description',
  location: 'Location',
  participants: 'Participants',
};

export default scheduleFields;
