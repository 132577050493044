/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import FilterItemHeader from 'components/material/Filters/Advanced/FilterItemHeader';

import ContactFilterContainer from './ContactFilterContainer';

import type { ContactFilterItemQueryResponse } from './__generated__/ContactFilterItemQuery.graphql';

const Container = styled.div`
  flex: 1 1 auto;
  padding: 0 0 0 10px;
`;

const query = graphql`
  query ContactFilterItemQuery($contactFilters: ContactFilters!, $contactIds: [ID!]!) {
    query {
      ...ContactFilterContainer_query
    }
  }
`;

export default class ContactFilterItem extends React.Component<
  {
    name: string,
    activeValues: ?$ReadOnlyArray<string>,
    label: string,
    onChange: (name: string, values: ?$ReadOnlyArray<string>) => void,
    expanded?: boolean,
    searchPlaceholder?: string,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleFilterClear = () => {
    this.props.onChange(this.props.name, null);
  };

  handleChange = (contactIds: ?$ReadOnlyArray<string>, queryVal: string) => {
    this.setState({ query: queryVal });
    this.props.onChange(this.props.name, contactIds);
  };

  render() {
    const contactIds = this.props.activeValues || [];
    return (
      <FilterItemHeader
        label={this.props.label}
        count={contactIds.length}
        onClear={this.handleFilterClear}
        expanded={this.props.expanded}
      >
        <Container>
          <DefaultQueryRenderer
            query={query}
            variables={{
              query: this.state.query,
              contactFilters: { query: this.state.query },
              contactIds,
            }}
            renderSuccess={(response: ContactFilterItemQueryResponse) => (
              <ContactFilterContainer
                query={response.query}
                activeValues={contactIds}
                onChange={this.handleChange}
                searchPlaceholder={this.props.searchPlaceholder}
              />
            )}
            renderLoading={() => (
              <ContactFilterContainer
                query={null}
                activeValues={contactIds}
                onChange={this.handleChange}
              />
            )}
          />
        </Container>
      </FilterItemHeader>
    );
  }
}
