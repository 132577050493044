/* @flow */

export default function forgotPassword(email: string): Promise<string> {
  return fetch('/users/password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: {
        email,
      },
    }),
  })
    .then(response => {
      if (response.ok) {
        return response;
      }
      return response.json().then(body => {
        if (body.errors && body.errors.length > 0) {
          return Promise.reject(new Error(body.errors.join(' ')));
        }
        return Promise.reject(new Error('Unexpected error'));
      });
    })
    .then(response => response.json())
    .then(data => data.message);
}
