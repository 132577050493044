/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';

import {
  type removePaymentMutationVariables,
  type removePaymentMutationResponse,
} from './__generated__/removePaymentMutation.graphql';

export type MutationInput = $PropertyType<removePaymentMutationVariables, 'input'> & {
  eventId?: string,
  expenseId?: string,
};

const mutation = graphql`
  mutation removePaymentMutation($input: RemovePaymentInput!) {
    removePayment(input: $input) {
      expense {
        actualAmount
        paidAmount
        viewerCanUpdateAmount

        event {
          totalActualAmount
          totalPaidAmount
        }

        payments {
          edges {
            node {
              viewerCanDelete
            }
          }
        }
      }
      removedPaymentId
    }
  }
`;

export default function removePayment(
  input: MutationInput,
): Promise<removePaymentMutationResponse> {
  const optimisticResponse: removePaymentMutationResponse = {
    removePayment: {
      expense: null,
      removedPaymentId: input.paymentId,
    },
  };

  return commitModernMutation({
    mutation,
    variables: {
      input: {
        paymentId: input.paymentId,
      },
    },
    optimisticResponse,
    updater: mergeUpdaters(
      ...(input.expenseId
        ? [
            removeRecordFromConnection({
              deletedIdField: 'removedPaymentId',
              parentId: input.expenseId,
              connections: [{ field: 'payments' }],
            }),
          ]
        : []),
      ...(input.eventId
        ? [
            removeRecordFromConnection({
              deletedIdField: 'removedPaymentId',
              parentId: input.eventId,
              connections: [{ key: 'PaymentsTable_payments' }, { field: 'payments' }],
            }),
          ]
        : []),
      removeRecordFromStore({ deletedIdField: 'removedPaymentId' }),
    ),
  });
}
