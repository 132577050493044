/**
 * @flow
 * @relayHash 9f14c5471ddbfa78851e705e36859010
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SavePublicResourceTokenInput = {
  clientMutationId?: ?string,
  publicResourceTokenId: string,
  token: string,
};
export type savePublicResourceTokenMutationVariables = {|
  input: SavePublicResourceTokenInput
|};
export type savePublicResourceTokenMutationResponse = {|
  +savePublicResourceToken: ?{|
    +publicResourceToken: {|
      +id: string,
      +token: string,
    |},
    +registrationForm: ?{|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type savePublicResourceTokenMutation = {|
  variables: savePublicResourceTokenMutationVariables,
  response: savePublicResourceTokenMutationResponse,
|};
*/


/*
mutation savePublicResourceTokenMutation(
  $input: SavePublicResourceTokenInput!
) {
  savePublicResourceToken(input: $input) {
    publicResourceToken {
      id
      token
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SavePublicResourceTokenInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "savePublicResourceToken",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SavePublicResourceTokenInput!"
      }
    ],
    "concreteType": "SavePublicResourceTokenPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "publicResourceToken",
        "storageKey": null,
        "args": null,
        "concreteType": "PublicResourceToken",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "token",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "registrationForm",
        "storageKey": null,
        "args": null,
        "concreteType": "RegistrationForm",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasChanges",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "savePublicResourceTokenMutation",
  "id": null,
  "text": "mutation savePublicResourceTokenMutation(\n  $input: SavePublicResourceTokenInput!\n) {\n  savePublicResourceToken(input: $input) {\n    publicResourceToken {\n      id\n      token\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "savePublicResourceTokenMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "savePublicResourceTokenMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '30b99ca32f523e770903ce131d1e36d2';
module.exports = node;
