/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';
import type { User } from 'components/UserSelect';
import CheckBox from 'components/SharedForm/CheckBox';
import Radio from 'components/SharedForm/Radio';

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  font-size: 14px;
  cursor: default;
`;

const StyledMaterialAvatar = styled(MaterialAvatar)`
  margin-right: 12px;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.54;
    `};
`;

class UserSelectOptionRow extends React.PureComponent<{
  type: 'checkbox' | 'radio',
  checked: boolean,
  user: User,
  disabled?: boolean,
  onChange: (value: User, checked: boolean) => void,
}> {
  handleChange = () => {
    if (this.props.disabled) {
      return;
    }
    this.props.onChange(this.props.user, !this.props.checked);
  };

  renderUserItem = (disabled?: boolean) => {
    const { user } = this.props;
    return (
      <UserContainer>
        <StyledMaterialAvatar user={user} radius={15} disabled={disabled} />
        <div>{fullNameOfUser(user)}</div>
      </UserContainer>
    );
  };

  render() {
    const { type, checked } = this.props;
    return (
      <OptionRow>
        {type === 'checkbox' ? (
          <CheckBox
            checked={checked}
            onClick={this.handleChange}
            labelRenderer={this.renderUserItem}
            compact
          />
        ) : (
          <Radio
            checked={checked}
            onClick={this.handleChange}
            labelRenderer={this.renderUserItem}
            compact
          />
        )}
      </OptionRow>
    );
  }
}

export default createFragmentContainer(
  UserSelectOptionRow,
  graphql`
    fragment UserSelectOptionRow_user on User {
      id
      firstName
      lastName
      email
      ...MaterialAvatar_user
    }
  `,
);
