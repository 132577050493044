/* @flow */
import currencies from 'config/currencies.json';

import formatNumber from './formatNumber';

export default function formatCost(cost: ?number, currencyCode: string): string {
  if (cost != null && Number.isInteger(cost)) {
    const actualCost = cost / 100;
    const formattedNumber = formatNumber(Math.abs(actualCost));
    const currency = currencyCode && currencies.find(c => c.code === currencyCode);

    return `${actualCost < 0 ? '-' : ''}${currency ? currency.symbol : ''}${formattedNumber}`;
  }
  return '';
}
