/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { Location, Match, RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import Content from '../../layout';
import VendorsPage from './VendorsPage';

import type { OrgVendorsQueryResponse } from './__generated__/OrgVendorsQuery.graphql';

const query = graphql`
  query OrgVendorsQuery {
    org {
      ...VendorsPage_org
    }
    me {
      ...VendorsPage_user
    }
  }
`;

export default class OrgVendors extends React.PureComponent<{
  location: Location,
  history: RouterHistory,
  match: Match,
}> {
  render() {
    const { location, history } = this.props;
    const pathPrefix = this.props.match.url;
    return (
      <Content>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(props: OrgVendorsQueryResponse) => {
            return (
              <VendorsPage
                location={location}
                history={history}
                org={props.org}
                user={props.me}
                pathPrefix={pathPrefix}
              />
            );
          }}
        />
      </Content>
    );
  }
}
