/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';
import { omit, sortBy } from 'lodash';

import type { FieldType } from 'utils/customization/types';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table, { type ColumnType } from 'components/budget/Table';
import { type Sort } from 'components/material/SortableHeader';

import columns, { type EventCellPropsType } from './columns';

import type { ContactEventsList_contact } from './__generated__/ContactEventsList_contact.graphql';
import type { ContactEventsList_contactEvents } from './__generated__/ContactEventsList_contactEvents.graphql';
import type { ContactEventsList_org } from './__generated__/ContactEventsList_org.graphql';

type ContactEventType = $ElementType<ContactEventsList_contactEvents, 0>;

type Props = {
  contact: ContactEventsList_contact,
  contactEvents: ContactEventsList_contactEvents,
  org: ContactEventsList_org,
  sort: Sort,
  onChangeSort: Sort => void,
  location: Location,
};

const ContactEventsList = ({
  sort,
  onChangeSort,
  contact,
  org,
  contactEvents,
  location,
}: Props) => {
  const salesforceSyncEnabled =
    !!org.salesforceAccount && org.salesforceAccount.contactsSyncEnabled;
  const marketoSyncEnabled = !!org.marketoAccount;

  const cellProps = (
    contactEvent: ContactEventType,
    _group,
    column?: ColumnType<any, any>,
  ): EventCellPropsType<ContactEventType, ContactEventsList_contact, ?FieldType> => ({
    contactEvent,
    contact,
    fieldSettings: column != null ? column.fieldSettings : null,
    salesforceSyncEnabled,
    marketoSyncEnabled,
    location,
  });

  const keyExtractor = (contactEvent: ContactEventType) => contactEvent.node.id;

  const configureColumns = (): $ReadOnlyArray<ColumnType<any, any>> => {
    const customFields = org.customFields.edges.map(({ node }) => node);
    return sortBy(
      columns(salesforceSyncEnabled || marketoSyncEnabled)
        .filter(column => {
          if (column.fieldSettings) {
            return true;
          }
          const sortKeyLowered = column.sortKey.toLowerCase();
          const customField = customFields.find(field =>
            [sortKeyLowered, `${sortKeyLowered}_id`].some(f => f === field.fieldName),
          );
          return !customField || customField.enabled;
        })
        .map(column => {
          if (column.fieldSettings) {
            return column;
          }
          const sortKeyLowered = column.sortKey.toLowerCase();
          const customField = customFields.find(field =>
            [sortKeyLowered, `${sortKeyLowered}_id`].some(f => f === field.fieldName),
          );
          if (customField) {
            const { options, ...fieldSettings } = customField;
            return {
              ...omit(column, 'enabled'),
              fieldSettings: {
                ...fieldSettings,
                options: customField.options.edges.map(({ node }) => ({
                  label: node.name,
                  value: node.id,
                })),
              },
              title: customField.label,
            };
          }
          return {
            ...column,
            fieldSettings: {
              order: Math.max(...customFields.map(field => field.order)) + 1,
              id: 'default',
              label: 'Default',
              fieldName: 'default',
              required: false,
              kind: 'DEFAULT',
              options: [],
            },
          };
        }),
      column => column.fieldSettings.order,
    );
  };

  const configuredColumns = configureColumns();

  if (contactEvents.length === 0) {
    return <EmptyView message="Add the first event for this contact." icon={<NoResult />} />;
  }

  return (
    <Table
      data={contactEvents}
      columns={configuredColumns}
      sort={sort}
      onChangeSort={onChangeSort}
      cellProps={cellProps}
      keyExtractor={keyExtractor}
    />
  );
};

export default createFragmentContainer(ContactEventsList, {
  contactEvents: graphql`
    fragment ContactEventsList_contactEvents on ContactEventEdge @relay(plural: true) {
      ...EventDate_contactEvent
      ...EventLocation_contactEvent
      ...EventVirtualLocation_contactEvent
      ...EventName_contactEvent
      ...EventStatus_contactEvent
      ...EventRegistrationStatus_contactEvent
      ...EventAttendanceStatus_contactEvent
      ...EventAttendanceJoinTime_contactEvent
      ...EventAttendanceLeaveTime_contactEvent
      ...EventAttendanceDuration_contactEvent
      ...EventSyncStatus_contactEvent
      ...EventLastSynced_contactEvent
      ...ContactEventActionsColumn_contactEvent
      node {
        id
      }
    }
  `,
  contact: graphql`
    fragment ContactEventsList_contact on Contact {
      id
      ...EventRegistrationStatus_contact
      ...EventAttendanceStatus_contact
      ...EventSyncStatus_contact
      ...ContactEventActionsColumn_contact
    }
  `,
  org: graphql`
    fragment ContactEventsList_org on Org {
      id
      dbId
      salesforceAccount {
        contactsSyncEnabled
      }
      marketoAccount {
        id
      }
      customFields(customizableType: [EVENTCONTACT], includeDisabled: true) {
        edges {
          node {
            id
            enabled
            fieldName
            label
            required
            order
            kind
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
});
