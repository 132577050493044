/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import UIContext from '../../../../UIContext';
import type { DraggableComponentConfigType } from './DraggableComponent';

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 308px;
  height: 50px;
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid #d9ddf5;
  border-radius: 5px;
  cursor: grab;
  &:hover {
    border-color: ${props => props.hoverColor || '#6b80f9'};
    box-shadow: 0 0 4px 0 rgba(136, 149, 224, 0.5);
  }
`;

const Label = styled.div`
  margin-left: 15px;
  color: #29305d;
  line-height: 1em;
`;

class PageComponent extends React.PureComponent<{
  componentConfig?: DraggableComponentConfigType,
  className?: string,
}> {
  render() {
    const { className, componentConfig } = this.props;
    if (!componentConfig) {
      return null;
    }
    const { icon, label } = componentConfig;
    return (
      <UIContext.Consumer>
        {({ pageComponentHoverBorder }) => (
          <Container className={className} hoverColor={pageComponentHoverBorder}>
            {icon}
            <Label>{label}</Label>
          </Container>
        )}
      </UIContext.Consumer>
    );
  }
}

export default PageComponent;
