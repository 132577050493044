/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import contactCreateMethods from 'components/Contacts/enums/contactCreateMethods';

import type { ContactUpdatedMethod_contact } from './__generated__/ContactUpdatedMethod_contact.graphql';

type Props = {
  contact: ContactUpdatedMethod_contact,
};

const ContactUpdatedMethod = (props: Props) => {
  const updatedMethod = contactCreateMethods.find(
    option => option.value === props.contact.updatedMethod,
  );
  return updatedMethod ? updatedMethod.label : null;
};

export default createFragmentContainer(
  ContactUpdatedMethod,
  graphql`
    fragment ContactUpdatedMethod_contact on Contact {
      updatedMethod
    }
  `,
);
