/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { StaffLastActivityCell_staffer } from './__generated__/StaffLastActivityCell_staffer.graphql';

class StaffLastActivityCell extends React.PureComponent<{
  staffer: StaffLastActivityCell_staffer,
  tz: string,
}> {
  render() {
    const user = this.props.staffer.user;

    if (!user.lastSeenAt) return null;

    return <DateTimeTooltip date={moment.tz(user.lastSeenAt, this.props.tz)} />;
  }
}

export default createFragmentContainer(
  StaffLastActivityCell,
  graphql`
    fragment StaffLastActivityCell_staffer on StaffMembership {
      user {
        lastSeenAt
      }
    }
  `,
);
