/**
 * @flow
 * @relayHash 29d4b8bb8c94faa30665bd774fb4b809
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssignDeliverablesInput = {
  clientMutationId?: ?string,
  deliverableIds: $ReadOnlyArray<string>,
  userId: string,
  fromWindow: string,
};
export type assignTasksMutationVariables = {|
  input: AssignDeliverablesInput
|};
export type assignTasksMutationResponse = {|
  +assignDeliverables: ?{|
    +deliverables: $ReadOnlyArray<{|
      +id: string,
      +following: ?boolean,
      +name: string,
      +assignees: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +avatar: ?string,
            +email: string,
          |}
        |}>
      |},
    |}>
  |}
|};
export type assignTasksMutation = {|
  variables: assignTasksMutationVariables,
  response: assignTasksMutationResponse,
|};
*/


/*
mutation assignTasksMutation(
  $input: AssignDeliverablesInput!
) {
  assignDeliverables(input: $input) {
    deliverables {
      id
      following
      name
      assignees {
        edges {
          node {
            id
            firstName
            lastName
            avatar
            email
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AssignDeliverablesInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "assignDeliverables",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AssignDeliverablesInput!"
      }
    ],
    "concreteType": "AssignDeliverablesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliverables",
        "storageKey": null,
        "args": null,
        "concreteType": "Deliverable",
        "plural": true,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "following",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "assignees",
            "storageKey": null,
            "args": null,
            "concreteType": "UserRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "assignTasksMutation",
  "id": null,
  "text": "mutation assignTasksMutation(\n  $input: AssignDeliverablesInput!\n) {\n  assignDeliverables(input: $input) {\n    deliverables {\n      id\n      following\n      name\n      assignees {\n        edges {\n          node {\n            id\n            firstName\n            lastName\n            avatar\n            email\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "assignTasksMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "assignTasksMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8f720dd117b63330c04e86017586c832';
module.exports = node;
