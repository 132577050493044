/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import classNames from 'classnames';

import { download } from 'utils/Attachments';
import type { CircaFile } from 'utils/uploading/types';

import addAttachmentToBrief from 'graph/mutations/event_brief/addAttachmentToBrief';
import removeAttachmentFromBrief from 'graph/mutations/event_brief/removeAttachmentFromBrief';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button from 'components/material/Button';

import type { AttachmentPreview_attachments } from './__generated__/AttachmentPreview_attachments.graphql';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  cursor: initial;
`;

const Content = styled.div`
  flex: 1 1 auto;
  outline: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: ${props => props.theme.overlayBackgroundColor};
  img {
    max-width: 90vh;
    max-height: 90vh;
  }
  iframe {
    width: 100%;
    max-width: 900px;
    height: 100%;
  }
`;

const Unavailable = styled.div`
  color: white;
  text-align: center;
  p {
    margin-bottom: 10px;
  }
`;

const Caption = styled.div`
  position: relative;
  flex-shrink: 0;
  padding: 15px;
  background: ${props => props.theme.contentBackgroundColor};
  text-align: center;
  color: ${props => props.theme.rowPrimaryTextColor};
  font-weight: 500;
  ul {
    font-size: 13px;
    list-style: none;
    display: flex;
    justify-content: center;
    li {
      margin: 5px 7px 0 7px;
    }
    a {
      color: ${props => props.theme.secondaryActionColor};
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.secondaryActionDarkerColor};
      }
    }
  }
`;

const NavIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  color: ${props => props.theme.primaryRowColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 22px;
  ${props => props.right && 'right: 0;'};
  ${props => props.left && 'left: 0;'};
  &:hover {
    color: ${props => props.theme.secondaryRowColor};
  }
`;

export type BriefDataType = {
  eventId: string,
  canUpdateBrief: boolean,
  briefAttachmentIds: $ReadOnlyArray<string>,
};

class AttachmentPreview extends React.PureComponent<{
  previewId: string,
  attachments: AttachmentPreview_attachments,
  mockAttachments: $ReadOnlyArray<CircaFile>,
  onPreview: (attachmentId: ?string) => void,
  onRemove?: (attachmentId: ?string) => void,
  briefData?: BriefDataType,
}> {
  static defaultProps = {
    mockAttachments: [],
  };

  handleHide = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.onPreview(null);
  };

  handlePrev = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const prevAttachment = this.props.attachments.find(
      (at, index, ats) => ats[index + 1] && ats[index + 1].id === this.props.previewId,
    );
    const prevMockAttachment = this.props.mockAttachments.find(
      (at, index, ats) => ats[index + 1] && ats[index + 1].id === this.props.previewId,
    );

    if (prevAttachment) {
      this.props.onPreview(prevAttachment.id);
    }
    if (prevMockAttachment) {
      this.props.onPreview(prevMockAttachment.id);
    }
  };

  handleNext = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const nextAttachment = this.props.attachments.find(
      (at, index, ats) => ats[index - 1] && ats[index - 1].id === this.props.previewId,
    );
    const nextMockAttachment = this.props.mockAttachments.find(
      (at, index, ats) => ats[index - 1] && ats[index - 1].id === this.props.previewId,
    );

    if (nextAttachment) {
      this.props.onPreview(nextAttachment.id);
    }
    if (nextMockAttachment) {
      this.props.onPreview(nextMockAttachment.id);
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      this.handlePrev(e);
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      this.handleNext(e);
    } else if (e.key === 'Escape') {
      this.handleHide(e);
    }
  };

  handleRef = el => {
    if (el) el.focus();
  };

  handleOpenClick = () => {
    const attachment = this.props.attachments.find(at => at.id === this.props.previewId);
    const mockAttachment = this.props.mockAttachments.find(at => at.id === this.props.previewId);

    if (attachment) {
      window.open(attachment.fileurl);
    }

    if (mockAttachment) {
      window.open(mockAttachment.fileurl);
    }
  };

  handleDownloadClick = () => {
    const attachment = this.props.attachments.find(at => at.id === this.props.previewId);
    const mockAttachment = this.props.mockAttachments.find(at => at.id === this.props.previewId);

    if (attachment) {
      download(attachment.fileurl, attachment.filename);
    }

    if (mockAttachment) {
      download(mockAttachment.fileurl, mockAttachment.filename);
    }
  };

  handleRemoveClick = (e: SyntheticEvent<HTMLElement>) => {
    const attachment = this.props.attachments.find(at => at.id === this.props.previewId);

    this.handleHide(e);

    if (attachment && this.props.onRemove) {
      this.props.onRemove(attachment.id);
    }
  };

  handleSaveBrief = () => {
    const attachment = this.getAttachment();
    const { briefData } = this.props;
    if (briefData) {
      if (briefData.briefAttachmentIds.includes(attachment.id)) {
        if (!attachment.id) return;
        removeAttachmentFromBrief(attachment.id, briefData.eventId).catch(showModernMutationError);
      } else {
        if (!attachment.id) return;
        addAttachmentToBrief(attachment.id, briefData.eventId).catch(showModernMutationError);
      }
    }
  };

  getAttachment = (): $ElementType<AttachmentPreview_attachments, 0> | CircaFile => {
    const attachmentIndex = this.props.attachments.findIndex(
      at => at && at.id === this.props.previewId,
    );
    const mockAttachmentIndex = this.props.mockAttachments.findIndex(
      at => at && at.id === this.props.previewId,
    );
    const attachment =
      attachmentIndex === -1
        ? this.props.mockAttachments[mockAttachmentIndex]
        : this.props.attachments[attachmentIndex];
    return attachment;
  };

  renderPreview = attachment => {
    if (attachment) {
      if (attachment.filetype && attachment.filetype.startsWith('image/')) {
        return <img src={attachment.fileurl} alt={attachment.filename} key={attachment.id} />;
      }
      if (attachment.filetype === 'application/pdf') {
        return (
          <iframe
            src={`https://docs.google.com/gview?url=${attachment.fileurl || ''}&embedded=true`}
            key={attachment.fileurl}
            frameBorder="0"
            title="Preview"
          />
        );
      }
      if (
        [
          'application/msword',
          'application/vnd.ms-excel',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ].includes(attachment.filetype)
      ) {
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${attachment.fileurl || ''}`}
            key={attachment.fileurl}
            frameBorder="0"
            title="Preview"
          />
        );
      }
    }

    return (
      <Unavailable>
        <p>No Preview Available</p>
        <Button icon="download" label="Download" onClick={this.handleDownloadClick} />
      </Unavailable>
    );
  };

  render() {
    const { briefData } = this.props;

    const attachmentIndex = this.props.attachments.findIndex(
      at => at && at.id === this.props.previewId,
    );
    const mockAttachmentIndex = this.props.mockAttachments.findIndex(
      at => at && at.id === this.props.previewId,
    );
    const attachment = this.getAttachment();
    if (!attachment) return null;

    const includedInBrief = briefData && briefData.briefAttachmentIds.includes(attachment.id);
    return (
      <Container
        onClick={e => e.stopPropagation()}
        onKeyDown={this.handleKeyDown}
        ref={this.handleRef}
        tabIndex={0}
      >
        <Content onClick={this.handleHide}>{this.renderPreview(attachment)}</Content>
        <Caption>
          {attachment.filename}
          <ul>
            {briefData && briefData.canUpdateBrief && (
              <li>
                <a onClick={this.handleSaveBrief}>
                  <i className={classNames('fa fa-fw', includedInBrief ? 'fa-minus' : 'fa-plus')} />
                  {includedInBrief ? 'Remove from Brief' : 'Add to Brief'}
                </a>
              </li>
            )}
            <li>
              <a onClick={this.handleOpenClick}>
                <i className="fa fa-fw fa-external-link" /> Open
              </a>
            </li>
            <li>
              <a onClick={this.handleDownloadClick}>
                <i className="fa fa-fw fa-download" /> Download
              </a>
            </li>
            {this.props.onRemove &&
              (typeof attachment.viewerCanDelete === 'boolean'
                ? attachment.viewerCanDelete
                : true) && (
                <li>
                  <a onClick={this.handleRemoveClick}>
                    <i className="fa fa-fw fa-trash" /> Delete
                  </a>
                </li>
              )}
          </ul>
        </Caption>
        {(this.props.attachments[attachmentIndex + 1] ||
          this.props.mockAttachments[mockAttachmentIndex + 1]) && (
          <NavIcon right onClick={this.handleNext}>
            <i className="fa fa-fw fa-chevron-right" />
          </NavIcon>
        )}
        {(this.props.attachments[attachmentIndex - 1] ||
          this.props.mockAttachments[mockAttachmentIndex - 1]) && (
          <NavIcon left onClick={this.handlePrev}>
            <i className="fa fa-fw fa-chevron-left" />
          </NavIcon>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  AttachmentPreview,
  graphql`
    fragment AttachmentPreview_attachments on Attachment @relay(plural: true) {
      id
      filename
      fileurl
      filetype
      viewerCanDelete
    }
  `,
);
