/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';

import UIContext from '../UIContext';
import RegistrationCreateRefetchContainer from './RegistrationCreateRefetchContainer';

import type { RegistrationCreateQueryResponse } from './__generated__/RegistrationCreateQuery.graphql';

const query = graphql`
  query RegistrationCreateQuery($eventSlug: String, $isEventRegForm: Boolean!) {
    ...RegistrationCreateRefetchContainer_query
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const RegistrationCreate = (props: {
  match: Match,
  history: History,
  location: Location,
  discardCounter?: number,
}) => {
  const { match, history, location, discardCounter } = props;
  const eventSlug = match.params.eventSlug;
  return (
    <DefaultQueryRenderer
      query={query}
      variables={{ eventSlug, isEventRegForm: !!eventSlug }}
      renderSuccess={(response: RegistrationCreateQueryResponse) => {
        return (
          <RegistrationCreateRefetchContainer
            query={response}
            match={match}
            history={history}
            location={location}
            discardCounter={discardCounter}
          />
        );
      }}
      renderLoading={() => {
        return (
          <UIContext.Consumer>
            {({ loaderColor }) => (
              <LoaderContainer>
                <Loader color={loaderColor} size={30} />
              </LoaderContainer>
            )}
          </UIContext.Consumer>
        );
      }}
    />
  );
};

export default RegistrationCreate;
