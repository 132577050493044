/* @flow */
import React from 'react';
import { type Location, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import FeatureAccessContext from 'contexts/FeatureAccess';

import CreateVendorWindow from 'components/Vendors/CreateVendorWindow';

const Container = styled.div`
  position: absolute;
  top: 35px;
  padding: 8px 10px;
  font-size: 13px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
`;

const StyledLink = styled.a`
  color: #3aa8da;
  cursor: pointer;
`;

class SearchSuggestionBar extends React.Component<
  {
    location: Location,
    showSearchSuggestionBar: boolean,
    eventId: ?string,
    handleToggleSuggestionComponent?: () => void,
  },
  { addingContact: boolean },
> {
  state = { addingContact: false };

  handleWindowShow = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({ addingContact: true });
    if (this.props.handleToggleSuggestionComponent != null) {
      this.props.handleToggleSuggestionComponent();
    }
  };

  handleWindowClose = () => {
    this.setState({ addingContact: false });
    if (this.props.handleToggleSuggestionComponent != null) {
      this.props.handleToggleSuggestionComponent();
    }
  };

  handleOpenOrgVendors = () => {
    const orgVendorsPath = this.context.legacyFeatures ? '/contacts/vendors' : '/vendors';
    window.open(`${orgVendorsPath}${this.props.location.search}`, '_blank');
  };

  static contextType = FeatureAccessContext;

  render() {
    const { showSearchSuggestionBar, eventId } = this.props;
    return (
      <React.Fragment>
        {showSearchSuggestionBar && (
          <Container>
            <div>Don&apos;t see Vendors you&apos;re looking for?</div>
            <div>
              <StyledLink onMouseDown={this.handleOpenOrgVendors}>
                Search Workspace Vendors
              </StyledLink>
              {` or `}
              <StyledLink onMouseDown={this.handleWindowShow}>Create New Vendor</StyledLink>
            </div>
          </Container>
        )}
        {this.state.addingContact && (
          <CreateVendorWindow
            eventId={eventId}
            onHide={this.handleWindowClose}
            fromWindow="org vendors"
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(SearchSuggestionBar);
