/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import CustomizableSectionsList from 'components/FieldSettings/CustomizableSectionsList';

import getStandardizedFields from './lib/getStandardizedFields';

import { type CustomFieldSettingsQueryResponse } from './__generated__/EventFieldsSettingsQuery.graphql';

const query = graphql`
  query EventFieldsSettingsQuery(
    $sectionCustomizableType: [SectionCustomizableType!]!
    $customizableType: [CustomizableType!]!
    $includeDisabled: Boolean
  ) {
    org {
      id
      customFieldSections(sectionCustomizableType: $sectionCustomizableType) {
        edges {
          node {
            id
            name
            order
          }
        }
      }
      customFields(customizableType: $customizableType, includeDisabled: $includeDisabled) {
        edges {
          node {
            id
            kind
            label
            fieldName
            required
            enabled
            order
            showInCreateForm
            showInMobileForm
            restrictManagingOptions: mappedToSalesforce
            section {
              id
            }
            options {
              edges {
                node {
                  id
                  name
                  editable
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class EventFieldsSettings extends React.Component<{ title: string }> {
  render() {
    const filters = {
      customizableType: ['EVENT'],
      sectionCustomizableType: ['EVENT'],
      includeDisabled: true,
    };
    return (
      <DocumentTitle title={this.props.title}>
        <DefaultQueryRenderer
          query={query}
          variables={filters}
          renderSuccess={(props: CustomFieldSettingsQueryResponse) => {
            const fields = props.org.customFields.edges.map(edge => {
              const { options, ...rest } = edge.node;
              return {
                ...rest,
                options: (options && options.edges.map(optionEdge => optionEdge.node)) || [],
              };
            });

            return (
              <CustomizableSectionsList
                orgId={props.org.id}
                customizableType="EVENT"
                sectionCustomizableType="EVENT"
                filters={filters}
                fields={getStandardizedFields(fields)}
                sections={sortBy(
                  props.org.customFieldSections.edges.map(({ node }) => node),
                  'order',
                )}
              />
            );
          }}
        />
      </DocumentTitle>
    );
  }
}
