/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import CenterIcon from 'images/alignment/center.svg';
import LeftIcon from 'images/alignment/left.svg';
import RightIcon from 'images/alignment/right.svg';

const Container = styled.div`
  display: flex;
  height: 34px;
`;

const AlignItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  border: 1px solid #d9ddf5;
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    z-index: 3;
    border: 1px solid #a5a9c2;
    border-radius: 3px !important;
    &:nth-child(1) {
      width: 44px;
      border-right: 1px solid #a5a9c2;
    }
    &:nth-child(2) {
      z-index: 3;
      border: 1px solid #a5a9c2;
    }
    &:nth-child(3) {
      border-left: 1px solid #a5a9c2;
    }
  }

  &:nth-child(1) {
    border-right: 1px transparent;
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    ${props =>
      props.value === 'LEFT' &&
      css`
        z-index: 2;
        width: 44px;
        border: 1px solid #6c80fa !important;
        border-radius: 3px;
        box-shadow: 0 0 4px 0 rgba(136, 149, 224, 0.5);
        g {
          fill: #909eee;
        }
      `}
  }

  &:nth-child(2) {
    margin: 0 -1px;
    border: 1px solid #d9ddf5;
    z-index: 1;

    ${props =>
      props.value === 'CENTER' &&
      css`
        border-radius: 3px;
        border: 1px solid #6c80fa !important;
        box-shadow: 0 0 4px 0 rgba(136, 149, 224, 0.5);
        g {
          fill: #909eee;
        }
      `}
  }

  &:nth-child(3) {
    border-left: 1px transparent;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    ${props =>
      props.value === 'RIGHT' &&
      css`
        z-index: 2;
        border: 1px solid #6c80fa !important;
        border-radius: 3px;
        box-shadow: 0 0 4px 0 rgba(136, 149, 224, 0.5);
        g {
          fill: #909eee;
        }
      `}
  }
`;

export type AlignmentType = 'LEFT' | 'CENTER' | 'RIGHT';

export default class AlignmentToggle extends React.PureComponent<{
  selectedAlignment: AlignmentType,
  onChange: (selectedAlignment: AlignmentType) => void,
}> {
  render() {
    const { selectedAlignment, onChange } = this.props;

    return (
      <Container>
        <AlignItem value={selectedAlignment} onClick={() => onChange('LEFT')}>
          <LeftIcon />
        </AlignItem>
        <AlignItem value={selectedAlignment} onClick={() => onChange('CENTER')}>
          <CenterIcon />
        </AlignItem>
        <AlignItem value={selectedAlignment} onClick={() => onChange('RIGHT')}>
          <RightIcon />
        </AlignItem>
      </Container>
    );
  }
}
