/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type InviteToOrgContents_org$ref: FragmentReference;
export type InviteToOrgContents_org = {|
  +viewerCanManageTeamMembers: boolean,
  +viewerCanManageStaff: boolean,
  +$refType: InviteToOrgContents_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "InviteToOrgContents_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanManageTeamMembers",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanManageStaff",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0ff5d31983a0cb3e45e8df5c20d152b3';
module.exports = node;
