/* @flow */

import { getAccessToken } from 'utils/Auth';
import checkEnabled from './checkEnabled';

const track = (name: string, options?: Object, integrations?: Object) => {
  if (checkEnabled()) {
    fetch('/v1/analytics/send_event', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: getAccessToken(),
      },
      body: JSON.stringify({
        event_name: name,
        properties: options,
        integrations,
      }),
    });
  }
};
export default track;
