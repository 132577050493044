/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type NoteEdit_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventNote_event$ref: FragmentReference;
export type EventNote_event = {|
  +$fragmentRefs: NoteEdit_event$ref,
  +$refType: EventNote_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventNote_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "NoteEdit_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0cdf21a2c2c4b8c872ce3f0e4dff219f';
module.exports = node;
