/* @flow */
import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import difference from 'lodash/difference';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import updateCustomFieldValue, {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';
import updateVendor, { type VendorFieldInput } from 'graph/mutations/vendor/updateVendor';
import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';
import showModernMutationError from 'graph/utils/showModernMutationError';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table, { type ColumnType } from 'components/budget/Table';
import TablePagination from 'components/budget/Table/TablePagination';
import { type ColumnConfiguration } from 'components/material/table';
import NoResultsMessage from 'components/NoResultsMessage';
import { type VendorCellPropsType } from 'components/Vendors/lib/types';

import type { VendorsTablePagination_org } from './__generated__/VendorsTablePagination_org.graphql';
import type { VendorsTablePagination_totalCountOrg } from './__generated__/VendorsTablePagination_totalCountOrg.graphql';

const VendorsContentContainer = styled.div`
  width: 100%;
`;

type Props = {
  org: ?VendorsTablePagination_org,
  totalCountOrg: ?VendorsTablePagination_totalCountOrg,
  relay: RelayPaginationProp,
  shownColumns: ColumnConfiguration,
  filtered: boolean,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  tz: string,
  includedColumns: { [string]: boolean },
  // eslint-disable-next-line react/no-unused-prop-types
  onVendorsListUpdate: (contacts: $ReadOnlyArray<string>) => void,
  pathPrefix: string,
};

type State = {
  refetching: boolean,
  availableVendors: $ReadOnlyArray<string>,
};

class VendorsTablePagination extends React.Component<Props, State> {
  state = {
    refetching: false,
    availableVendors: [],
  };

  paginationDisposable: ?Disposable;

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const prevItems = prevState.availableVendors;
    const nextItems = nextProps.org
      ? nextProps.org.vendors.edges.filter(Boolean).map(({ node }) => node.id)
      : [];
    if (
      nextProps.org &&
      (difference(prevItems, nextItems).length > 0 || difference(nextItems, prevItems).length > 0)
    ) {
      nextProps.onVendorsListUpdate(nextItems);
      return { availableVendors: nextItems };
    }
    return null;
  }

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  cellProps = (
    vendor,
    _group,
    column?: ColumnType<any, any>,
  ): VendorCellPropsType<
    ?VendorsTablePagination_org,
    {|
      +id: string,
      +$fragmentRefs: any,
    |},
  > => {
    const { org, tz, shownColumns, pathPrefix } = this.props;
    const columnsLength = shownColumns.length;
    return {
      vendor,
      customizable: vendor,
      org,
      fieldSettings: column != null ? column.fieldSettings : null,
      isLast:
        column != null && column.fieldSettings != null
          ? shownColumns[columnsLength - 1].fieldSettings &&
            shownColumns[columnsLength - 1].fieldSettings.id === column.fieldSettings.id
          : false,
      onUpdate: (args: $Shape<VendorFieldInput>): Promise<void> => {
        return updateVendor({ vendorId: vendor.id, ...args, fromWindow: 'org vendors' }).catch(
          showModernMutationError,
        );
      },
      onUpdateCustomField: (
        customizable: CustomizableResponse,
        args: UpdateCustomFieldValueInput,
      ): Promise<void> => {
        return updateCustomFieldValue(args, customizable, undefined, 'org vendors')
          .catch(showModernMutationError)
          .then(() => undefined);
      },
      tz,
      pathPrefix,
    };
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  keyExtractor = (vendor: {| +id: string, +$fragmentRefs: any |}) => {
    return vendor.id;
  };

  render() {
    const { org, shownColumns, filtered } = this.props;

    const optimisticVendorsEdges = org ? org.vendors.edges.filter(Boolean) : [];
    return (
      <VendorsContentContainer>
        {(!org || optimisticVendorsEdges.length !== 0) && (
          <Table
            data={org != null ? optimisticVendorsEdges.map(({ node }) => node) : null}
            columns={shownColumns}
            sort={this.props.sort}
            onChangeSort={this.props.onChangeSort}
            cellProps={this.cellProps}
            keyExtractor={this.keyExtractor}
          />
        )}

        {org &&
          optimisticVendorsEdges.length === 0 &&
          (filtered ? (
            <EmptyView message="No vendors match filters" icon={<NoResult />} />
          ) : (
            <NoResultsMessage iconName="user" message="Add the first vendor" />
          ))}
        {org && optimisticVendorsEdges.length !== 0 && (
          <TablePagination
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            currentCount={org ? optimisticVendorsEdges.length : null}
            totalCount={
              this.props.totalCountOrg && this.props.totalCountOrg.vendorsCount
                ? this.props.totalCountOrg.vendorsCount.totalCount
                : null
            }
            entityName="Vendors"
          />
        )}
      </VendorsContentContainer>
    );
  }
}
export default createPaginationContainer(
  VendorsTablePagination,
  {
    org: graphql`
      fragment VendorsTablePagination_org on Org {
        ...CustomizableCurrency_org @include(if: $includeCustomizableCurrency)
        vendors(first: $count, after: $cursor, filters: $filters)
          @connection(key: "OrgVendorsTablePagination_vendors", filters: []) {
          edges {
            node {
              id
              ...VendorName_vendor @include(if: $includeName)
              ...VendorWebsite_vendor @include(if: $includeWebsite)
              ...VendorCompanyPhone_vendor @include(if: $includeCompanyPhone)
              ...VendorStreet_vendor @include(if: $includeStreet)
              ...VendorCity_vendor @include(if: $includeCity)
              ...VendorState_vendor @include(if: $includeState)
              ...VendorZip_vendor @include(if: $includeZip)
              ...VendorCountry_vendor @include(if: $includeCountry)
              ...VendorTwitter_vendor @include(if: $includeTwitter)
              ...VendorLinkedin_vendor @include(if: $includeLinkedin)
              ...VendorDescription_vendor @include(if: $includeDescription)
              ...VendorFirstName_vendor @include(if: $includeFirstName)
              ...VendorLastName_vendor @include(if: $includeLastName)
              ...VendorTitle_vendor @include(if: $includeTitle)
              ...VendorOfficePhone_vendor @include(if: $includePhone1)
              ...VendorMobilePhone_vendor @include(if: $includePhone2)
              ...VendorEmail_vendor @include(if: $includeEmail)
              ...VendorCreator_vendor @include(if: $includeCreatedBy)
              ...VendorCreatedAt_vendor @include(if: $includeCreatedAt)
              ...VendorUpdater_vendor @include(if: $includeUpdatedBy)
              ...VendorUpdatedAt_vendor @include(if: $includeUpdatedAt)
              ...CustomizableText_customizable @include(if: $includeCustomizableText)
              ...CustomizableTextarea_customizable @include(if: $includeCustomizableTextarea)
              ...CustomizableLink_customizable @include(if: $includeCustomizableLink)
              ...CustomizableDate_customizable @include(if: $includeCustomizableDate)
              ...CustomizableBoolean_customizable @include(if: $includeCustomizableBoolean)
              ...CustomizableNumber_customizable @include(if: $includeCustomizableNumber)
              ...CustomizableCurrency_customizable @include(if: $includeCustomizableCurrency)
              ...CustomizableSelect_customizable @include(if: $includeCustomizableSelect)
              ...CustomizableMultiselect_customizable @include(if: $includeCustomizableMultiselect)
              ...CustomizableUserSelect_customizable @include(if: $includeCustomizableUserSelect)
              ...CustomizableUserMultiselect_customizable
                @include(if: $includeCustomizableUserMultiselect)
            }
          }
        }
      }
    `,
    totalCountOrg: graphql`
      fragment VendorsTablePagination_totalCountOrg on Org {
        vendorsCount: vendors(filters: $filters) {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.org && props.org.vendors;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        count,
        cursor,
      };
    },
    query: graphql`
      query VendorsTablePaginationQuery(
        $count: Int!
        $cursor: String
        $filters: VendorFilters!
        $includeName: Boolean!
        $includeWebsite: Boolean!
        $includeCompanyPhone: Boolean!
        $includeStreet: Boolean!
        $includeCity: Boolean!
        $includeState: Boolean!
        $includeZip: Boolean!
        $includeCountry: Boolean!
        $includeTwitter: Boolean!
        $includeLinkedin: Boolean!
        $includeDescription: Boolean!
        $includeFirstName: Boolean!
        $includeLastName: Boolean!
        $includeTitle: Boolean!
        $includePhone1: Boolean!
        $includePhone2: Boolean!
        $includeEmail: Boolean!
        $includeCreatedAt: Boolean!
        $includeCreatedBy: Boolean!
        $includeUpdatedAt: Boolean!
        $includeUpdatedBy: Boolean!
        $includeCustomizableText: Boolean!
        $includeCustomizableTextarea: Boolean!
        $includeCustomizableLink: Boolean!
        $includeCustomizableDate: Boolean!
        $includeCustomizableBoolean: Boolean!
        $includeCustomizableNumber: Boolean!
        $includeCustomizableCurrency: Boolean!
        $includeCustomizableSelect: Boolean!
        $includeCustomizableMultiselect: Boolean!
        $includeCustomizableUserSelect: Boolean!
        $includeCustomizableUserMultiselect: Boolean!
      ) {
        org {
          ...VendorsTablePagination_org
        }
      }
    `,
  },
);
