/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ExpenseFinanceField_expense$ref = any;
type ExpenseFormHeader_expense$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpenseForm_expense$ref: FragmentReference;
export type ExpenseForm_expense = {|
  +id: string,
  +name: string,
  +note: string,
  +paidAmount: number,
  +actualAmount: number,
  +viewerCanUpdateAmount: boolean,
  +vendor: ?{|
    +id: string
  |},
  +budgetCategory: ?{|
    +id: string,
    +name: string,
  |},
  +attachments: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +fileurl: ?string,
        +filetype: ?string,
        +filename: ?string,
      |}
    |}>
  |},
  +payments: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +attachments: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +fileurl: ?string,
              +filetype: ?string,
              +filename: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +deliverable: ?{|
    +name: string,
    +id: string,
    +slug: string,
  |},
  +event: {|
    +id: string,
    +slug: string,
    +viewerCanUpdate: boolean,
    +briefAttachments: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string
        |}
      |}>
    |},
    +deliverables: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
        |}
      |}>
    |},
  |},
  +$fragmentRefs: ExpenseFormHeader_expense$ref & ExpenseFinanceField_expense$ref,
  +$refType: ExpenseForm_expense$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  v0
],
v3 = [
  v0,
  v1
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "attachments",
  "storageKey": null,
  "args": null,
  "concreteType": "AttachmentRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AttachmentRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Attachment",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "fileurl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filetype",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filename",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ExpenseForm_expense",
  "type": "Expense",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ExpenseFormHeader_expense",
      "args": null
    },
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "note",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paidAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "actualAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vendor",
      "storageKey": null,
      "args": null,
      "concreteType": "Vendor",
      "plural": false,
      "selections": v2
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetCategory",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetCategory",
      "plural": false,
      "selections": v3
    },
    v4,
    {
      "kind": "FragmentSpread",
      "name": "ExpenseFinanceField_expense",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "payments",
      "storageKey": null,
      "args": null,
      "concreteType": "PaymentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "PaymentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Payment",
              "plural": false,
              "selections": [
                v0,
                v4
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "deliverable",
      "storageKey": null,
      "args": null,
      "concreteType": "Deliverable",
      "plural": false,
      "selections": [
        v1,
        v0,
        v5
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        v0,
        v5,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "viewerCanUpdate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "briefAttachments",
          "storageKey": null,
          "args": null,
          "concreteType": "AttachmentRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "AttachmentRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Attachment",
                  "plural": false,
                  "selections": v2
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "deliverables",
          "storageKey": null,
          "args": null,
          "concreteType": "DeliverableRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "DeliverableRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Deliverable",
                  "plural": false,
                  "selections": v3
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2304881ef5cc44ea9223359a6c140bd8';
module.exports = node;
