/* @flow */
import setSupportAccessToken from 'utils/setSupportAccessToken';

/**
 * Logs out the current support user
 */
export default function supportLogout() {
  setSupportAccessToken(null);
  window.location.href = '/admin';
}
