/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineNumberField from 'components/budget/Table/InlineNumberField';
import LockIcon from 'components/LockIcon';

import type { CustomizableNumber_customizable } from './__generated__/CustomizableNumber_customizable.graphql';

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
`;

class CustomizableNumber extends React.Component<{
  customizable: CustomizableNumber_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
}> {
  handleSave = (value: ?number): void => {
    const { onUpdateCustomField, updateColumnWidth, customizable, fieldSettings } = this.props;
    if (!this.getError(value) && value !== this.getValue() && onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        numberValue: value != null ? +value : null,
      });
      updateColumnWidth();
    }
  };

  getValue = (): ?number => {
    const { customizable, fieldSettings } = this.props;
    const customNumberField = customizable.customNumberFields.find(
      numberField => numberField.customField.id === fieldSettings.id,
    );
    return customNumberField ? customNumberField.value : null;
  };

  getError = (value: ?number): ?string => {
    const { fieldSettings } = this.props;
    if (value == null && fieldSettings.required) {
      return 'Required';
    }
    if (value != null && value.toString().length > 9) {
      return 'No more 9 digits';
    }
    return null;
  };

  render() {
    const { readOnly, fieldSettings, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    const value = this.getValue();
    if (readOnly || fieldSettings.restrictChangingValue) {
      return (
        <>
          {value != null ? value : null}
          {!readOnly && fieldSettings.restrictChangingValue && (
            <StyledLockIcon label="Salesforce" />
          )}
        </>
      );
    }
    return (
      <InlineNumberField
        value={value}
        onChange={this.handleSave}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
      />
    );
  }
}

export default createFragmentContainer(
  CustomizableNumber,
  graphql`
    fragment CustomizableNumber_customizable on CustomizableInterface {
      id
      __typename
      customNumberFields {
        customField {
          id
        }
        value
      }
    }
  `,
);
