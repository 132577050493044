/* @flow */
import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const Link = styled.div`
  display: flex;
  margin-bottom: 20px;
  vertical-align: middle;
  line-height: 22px;
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  > span {
    margin-right: 7px;
    font-size: 22px;
    color: ${props => props.theme.minimalButtonColor};
  }
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
    > span {
      color: ${props => darken(0.1, props.theme.minimalButtonColor)};
    }
  }
`;

const AddNewLink = (props: { label: string, onClick: () => void, className?: string }) => {
  const { label, onClick, className } = props;
  return (
    <Link onClick={onClick} className={className}>
      <span>+</span> {label}
    </Link>
  );
};

export default AddNewLink;
