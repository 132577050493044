/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';
import sortBy from 'lodash/sortBy';

import TextInput from 'components/material/TextInput';

import isUserType from '../../lib/isUserType';
import type { QuestionType, User } from '../../lib/types';
import EditableQuestionSelectOptionRow from './EditableQuestionSelectOptionRow';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 18px;
  position: relative;
  ${props =>
    props.showShadow &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 15px;
        background: linear-gradient(to bottom, white, transparent);
        pointer-events: none;
      }
    `} &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(to top, white, transparent);
    pointer-events: none;
  }
`;

const Options = styled.div`
  flex: 1 1 auto;
  max-height: 235px;
  min-height: 50px;
  overflow: auto;
  padding-right: 15px;
`;

const MultiselectContainer = styled.div`
  margin: 5px 5px -20px;
  text-align: right;
  a {
    color: #3ba9da;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default class EditableQuestionSelectOptions extends React.PureComponent<
  {
    question: QuestionType,
    options: $ReadOnlyArray<{ +label: string, +value: string } | User>,
    disabledOptions: $ReadOnlyArray<string>,
    onSetOptions: (values: $ReadOnlyArray<string>) => void,
    onSetOther: (show: boolean) => void,
  },
  {
    keyword: string,
  },
> {
  state = {
    keyword: '',
  };

  filteredOptions = () => {
    return this.props.options.filter(option =>
      option.label.toLowerCase().includes(this.state.keyword),
    );
  };

  handleShowOption = (value: string) => {
    this.props.onSetOptions(this.props.disabledOptions.filter(option => option !== value));
  };

  handleHideOption = (value: string) => {
    this.props.onSetOptions([...this.props.disabledOptions, value]);
  };

  handleShowOther = () => {
    this.props.onSetOther(true);
  };

  handleHideOther = () => {
    this.props.onSetOther(false);
  };

  handleShowAllOptions = () => {
    this.props.onSetOptions([]);
  };

  handleHideAllOptions = () => {
    this.props.onSetOptions(this.props.options.map(option => option.value));
  };

  handleSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { keyword } = this.state;

    if (keyword !== e.currentTarget.value.trim()) {
      this.setState({
        keyword: e.currentTarget.value.trim().toLowerCase(),
      });
    }
  };

  render() {
    const {
      options,
      disabledOptions,
      question,
      question: { customField },
    } = this.props;

    const filteredOptions = this.filteredOptions();

    return (
      <React.Fragment>
        {options.length >= 2 && (
          <React.Fragment>
            <TextInput
              placeholder={`Search for ${this.props.question.label}`}
              onChange={this.handleSearchChange}
            />

            {options.length === filteredOptions.length && (
              <MultiselectContainer>
                {disabledOptions.length === 0 ? (
                  <a onClick={this.handleHideAllOptions}>hide all</a>
                ) : (
                  <a onClick={this.handleShowAllOptions}>show all</a>
                )}
              </MultiselectContainer>
            )}
          </React.Fragment>
        )}
        <Container showShadow>
          <Options question={question}>
            {sortBy(this.props.options, 'label').map(option => (
              <EditableQuestionSelectOptionRow
                key={option.value}
                type={
                  customField && ['MULTISELECT', 'USER_MULTISELECT'].includes(customField.kind)
                    ? 'checkbox'
                    : 'radio'
                }
                active={!disabledOptions.some(opt => opt === option.value)}
                option={option}
                user={isUserType(customField) ? option : null}
                onShow={this.handleShowOption}
                onHide={this.handleHideOption}
              />
            ))}
            {!isUserType(customField) && (
              <EditableQuestionSelectOptionRow
                type={customField && customField.kind === 'MULTISELECT' ? 'checkbox' : 'radio'}
                active={question.showOtherOption}
                option={{ value: 'OTHER', label: 'Other' }}
                user={null}
                onShow={this.handleShowOther}
                onHide={this.handleHideOther}
              />
            )}
          </Options>
        </Container>
      </React.Fragment>
    );
  }
}
