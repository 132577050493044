/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import columnsConfig, { defaultTableComponents, getColumnsShowConfig } from './columns';
import getStaffFields from './lib/getStaffFields';
import type { ParsedStaffFilters } from './lib/parseStaffFilters';
import StaffHeader from './StaffHeader';
import StaffTableRenderer from './StaffTableRenderer';

import type { StaffPage_event } from './__generated__/StaffPage_event.graphql';
import type { StaffPage_org } from './__generated__/StaffPage_org.graphql';
import type { StaffPageQueryResponse } from './__generated__/StaffPageQuery.graphql';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const query = graphql`
  query StaffPageQuery(
    $count: Int!
    $cursor: String
    $eventSlug: String!
    $sort: StaffMembershipSort
    $customFieldSortId: String
    $direction: Direction!
    $includeEmail: Boolean!
    $includeEventAccessLevel: Boolean!
    $includeOnsite: Boolean!
    $includeLastActivity: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
  ) {
    event(slug: $eventSlug) {
      ...StaffTableRenderer_event
      ...StaffTableRenderer_totalCountEvent
    }
    org {
      ...StaffTableRenderer_org
    }
    me {
      id
    }
  }
`;

class StaffPage extends React.PureComponent<
  {
    org: StaffPage_org,
    event: StaffPage_event,
    filters: ParsedStaffFilters,
    history: RouterHistory,
    location: Location,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
  },
> {
  staffFields: $ReadOnlyArray<FieldType> = getStaffFields(
    normalizeCustomFields(this.props.org.customFields.edges.map(edge => edge.node)),
  );

  state = {
    shownColumns: StaffPage.getShownColumns(this.staffFields),
  };

  static getShownColumns(columns: $ReadOnlyArray<FieldType>): $ReadOnlyArray<string> {
    const shownColumns: any = (storage.get('eventStaffShownColumns'): ?mixed);
    return Array.isArray(shownColumns)
      ? shownColumns
      : getColumnsShowConfig(columns)
          .filter(column => column.default)
          .map(column => column.value);
  }

  handleColumnsChange = (shownColumns: $ReadOnlyArray<string>) => {
    storage.set('eventStaffShownColumns', shownColumns);
    this.setState({ shownColumns });
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  renderTable = (props: {
    event: ?$PropertyType<StaffPageQueryResponse, 'event'>,
    me: ?$PropertyType<StaffPageQueryResponse, 'me'>,
    org: ?$PropertyType<StaffPageQueryResponse, 'org'>,
    sort: SortParam,
  }): React.Node => {
    const columns = columnsConfig(this.staffFields, this.state.shownColumns);

    return (
      <StaffTableRenderer
        event={props.event}
        org={props.org}
        totalCountEvent={props.event}
        history={this.props.history}
        location={this.props.location}
        userId={props.me ? props.me.id : null}
        sort={props.sort}
        onChangeSort={this.handleSortChange}
        shownColumns={columns}
      />
    );
  };

  render() {
    const { filters, event, org } = this.props;
    const sortFilter = filters.sort || {
      key: 'NAME',
      asc: true,
    };
    const shownColumns = this.state.shownColumns;
    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      this.staffFields,
      shownColumns,
    );
    const customSortField: ?FieldType = this.staffFields.find(field => field.id === sortFilter.key);

    const variables = {
      count: 50,
      eventSlug: event.slug,
      sort: customSortField ? 'CUSTOM' : sortFilter.key,
      customFieldSortId: customSortField != null ? customSortField.id : null,
      direction: sortFilter.asc ? 'ASC' : 'DESC',
      ...includedColumns,
    };

    return (
      <Page>
        <StaffHeader
          org={org}
          event={event}
          staffFields={this.staffFields}
          shownColumns={shownColumns}
          onColumnsChange={this.handleColumnsChange}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(response: StaffPageQueryResponse) =>
            this.renderTable({
              event: response.event,
              me: response.me,
              org: response.org,
              sort: sortFilter,
            })
          }
          renderLoading={() =>
            this.renderTable({
              event: null,
              me: null,
              org: null,
              sort: sortFilter,
            })
          }
        />
      </Page>
    );
  }
}

export default createFragmentContainer(StaffPage, {
  org: graphql`
    fragment StaffPage_org on Org {
      ...StaffHeader_org
      customFields(customizableType: [STAFFMEMBERSHIP]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            customizableType
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  event: graphql`
    fragment StaffPage_event on Event {
      slug
      ...StaffHeader_event
    }
  `,
});
