/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompanyCreatedAt_company$ref: FragmentReference;
export type CompanyCreatedAt_company = {|
  +createdAt: any,
  +$refType: CompanyCreatedAt_company$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CompanyCreatedAt_company",
  "type": "Company",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0dea699af5213b994107218bbd43ffb7';
module.exports = node;
