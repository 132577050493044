/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { CircaFile } from 'utils/uploading/types';
import AttachmentRow from './AttachmentRow';

import type { BriefDataType } from './AttachmentPreview';

class RelayAttachmentRow extends React.PureComponent<{
  attachment: CircaFile,
  onRemove?: (attachmentId: string) => void,
  onPreview: (attachmentId: string) => void,
  readOnly?: boolean,
  briefData?: BriefDataType,
}> {
  render() {
    if (!this.props.attachment) return null;

    return (
      <AttachmentRow
        attachment={this.props.attachment}
        onRemove={this.props.onRemove}
        onPreview={this.props.onPreview}
        readOnly={this.props.readOnly}
        briefData={this.props.briefData}
      />
    );
  }
}

export default createFragmentContainer(
  RelayAttachmentRow,
  graphql`
    fragment RelayAttachmentRow_attachment on Attachment {
      id
      filename
      fileurl
      filetype
      viewerCanDelete
    }
  `,
);
