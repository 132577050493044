/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateVideoComponentMutationVariables,
  UpdateVideoComponentInput,
} from './__generated__/updateVideoComponentMutation.graphql';

export type updateVideoComponentPropertyType = $Shape<UpdateVideoComponentInput>;

const mutation = graphql`
  mutation updateVideoComponentMutation($input: UpdateVideoComponentInput!) {
    updateRegistrationFormVideoComponent(input: $input) {
      pageComponent {
        videoComponent {
          id
          url
          width
          height
          autoplay
          padding
          alignment
          backgroundColor
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function updateVideoComponent(properties: updateVideoComponentPropertyType) {
  const variables: updateVideoComponentMutationVariables = {
    input: {
      ...properties,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateRegistrationFormVideoComponent: {
        pageComponent: {
          videoComponent: properties,
        },
      },
    },
  });
}
