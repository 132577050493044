/* @flow */
import * as React from 'react';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import styled from 'styled-components';

import type { InfoBoxOptionsType } from './googleMapsWrapperTypes';

const StyledInfoBox = styled(InfoBox)`
  width: 270px;
`;

export default class InfoBoxWrapper extends React.Component<{
  infoBoxView: React.ComponentType<any>,
  infoBoxOffset?: number,
  slug?: string,
  customProp?: InfoBoxOptionsType,
}> {
  render() {
    const InfoBoxContent = this.props.infoBoxView;
    const googleMaps = window.google.maps;
    const infoBoxOptions = {
      closeBoxURL: '',
      infoBoxClearance: new googleMaps.Size(20, 280),
      enableEventPropagation: false,
      pixelOffset: new googleMaps.Size(this.props.infoBoxOffset || 0, 0),
    };

    return (
      <StyledInfoBox options={infoBoxOptions}>
        <InfoBoxContent slug={this.props.slug} {...this.props.customProp} />
      </StyledInfoBox>
    );
  }
}
