/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Remove as RemoveIcon } from 'images';
import { MinimalButton } from 'components/budget/Button';
import FilterSelectorRow, { type FilterItem } from 'components/material/Filters/FilterSelectorRow';
import SelectField from 'components/material/SelectField';

import type { ConditionField, RuleActionType, RuleType } from './lib/types';

const SECTION_ACTIONS = [
  { value: 'HIDE_BY_DEFAULT', label: 'Hide section by default' },
  { value: 'HIDE', label: 'Hide section' },
  { value: 'SHOW', label: 'Show section' },
];

const QUESTION_ACTIONS = [
  { value: 'HIDE_BY_DEFAULT', label: 'Hide question by default' },
  { value: 'HIDE', label: 'Hide question' },
  { value: 'SHOW', label: 'Show question' },
  { value: 'MAKE_REQUIRED', label: 'Make question required' },
  { value: 'MAKE_OPTIONAL', label: 'Make question not required' },
];

const Container = styled.div`
  margin: 0 -30px 20px -30px;
  padding: 6px 30px 10px 30px;
  ${props =>
    props.hoverOnDeleteIcon &&
    css`
      background: rgba(0, 0, 0, 0.05);
    `}
`;

const RuleSelectField = styled(SelectField)`
  margin-right: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ConditionsWrapper = styled.div`
  margin-top: 20px;
`;

const ConditionsContainer = styled.div`
  margin-left: 20px;
`;

const RemoveAction = styled.div`
  width: 20px;
  margin-top: 14px;
  margin-left: auto;
  cursor: pointer;
  color: ${props => props.theme.secondaryActionColor};
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default class RuleEditor extends React.Component<
  {
    rule: RuleType,
    conditionFields: $ReadOnlyArray<ConditionField>,
    onUpdate: (rule: any) => void,
    onRemove: () => void,
    tz: string,
    onlyHideShow?: boolean,
  },
  { hoverOnDeleteIcon: boolean },
> {
  state = {
    hoverOnDeleteIcon: false,
  };

  initialConditions = this.props.rule.conditions;

  findHiddenCondition = (condition?: FilterItem) => {
    return condition && (condition.fieldName || condition.customField)
      ? this.initialConditions.find(
          item =>
            condition &&
            (item.fieldName || item.customField) &&
            ((!condition.customField && item.fieldName === condition.fieldName) ||
              (condition.customField &&
                (!item.customField || item.customField.id === condition.customField.id))),
        )
      : null;
  };

  handleAddNewCondition = () => {
    const { rule, onUpdate } = this.props;

    rule.conditions = [...rule.conditions, { errors: {} }];
    onUpdate(rule);
  };

  handleChangeRuleAction = (value: ?RuleActionType) => {
    const { rule, onUpdate } = this.props;

    const newRule: RuleType = {
      order: rule.order,
      conditions: [],
      hiddenConditions: [],
    };

    if (value) {
      newRule.action = value;
      newRule.conditions = value === 'HIDE_BY_DEFAULT' ? [] : [{ errors: {} }];
    }
    onUpdate(newRule);
  };

  handleUpdate = (condition: FilterItem, oldCondition?: FilterItem, index: number) => {
    const { rule, onUpdate } = this.props;

    rule.conditions = [
      ...rule.conditions.slice(0, index),
      condition,
      ...rule.conditions.slice(index + 1),
    ];

    const fixedHiddenConditions = rule.hiddenConditions.filter(
      item =>
        (!condition.customField && item.fieldName !== condition.fieldName) ||
        (condition.customField &&
          (!item.customField || item.customField.id !== condition.customField.id)),
    );

    const newHiddenCondition = this.findHiddenCondition(oldCondition);

    rule.hiddenConditions = !newHiddenCondition
      ? fixedHiddenConditions
      : [...fixedHiddenConditions, newHiddenCondition];

    onUpdate(rule);
  };

  handleRemoveCondition = (index: number) => {
    const { rule, onUpdate } = this.props;

    const condition = rule.conditions[index];

    rule.conditions = rule.conditions.filter((_, currentIndex) => currentIndex !== index);

    const newHiddenCondition = this.findHiddenCondition(condition);
    if (newHiddenCondition) {
      rule.hiddenConditions = [...rule.hiddenConditions, newHiddenCondition];
    }

    onUpdate(rule);
  };

  render() {
    const { rule, conditionFields, tz, onRemove, onlyHideShow } = this.props;

    return (
      <Container hoverOnDeleteIcon={this.state.hoverOnDeleteIcon}>
        <Row>
          <RuleSelectField
            placeholder="Select action…"
            value={rule.action}
            options={onlyHideShow ? SECTION_ACTIONS : QUESTION_ACTIONS}
            onChange={this.handleChangeRuleAction}
          />
          {rule.action && (
            <RemoveAction
              onClick={onRemove}
              onMouseEnter={() => this.setState({ hoverOnDeleteIcon: true })}
              onMouseLeave={() => this.setState({ hoverOnDeleteIcon: false })}
            >
              <RemoveIcon />
            </RemoveAction>
          )}
        </Row>
        {rule.action && rule.action !== 'HIDE_BY_DEFAULT' && (
          <ConditionsContainer>
            <ConditionsWrapper>
              {rule.conditions.map((condition, index) => {
                return (
                  <FilterSelectorRow
                    filterItem={condition}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}-${rule.conditions.length}`}
                    onUpdate={(item, oldItem) => this.handleUpdate(item, oldItem, index)}
                    onRemove={() => this.handleRemoveCondition(index)}
                    filterFields={conditionFields}
                    usedFilters={rule.conditions}
                    index={index}
                    tz={tz}
                    filterSelectPlaceholder="Select a question…"
                    conditionStartLabel="If"
                    nonRemovable={rule.conditions.length === 1}
                    highlightOnRemove
                  />
                );
              })}
            </ConditionsWrapper>
            {rule.conditions.length < conditionFields.length && (
              <MinimalButton onClick={this.handleAddNewCondition} label="+ Add condition" />
            )}
          </ConditionsContainer>
        )}
      </Container>
    );
  }
}
