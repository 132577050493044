/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import omit from 'lodash/omit';

import type { FieldType } from 'utils/customization/types';

import getEventFilterConverters from 'components/AllEvents/lib/getEventFilterConverters';
import type { ParsedAllEventsFilters } from 'components/AllEvents/lib/parseAllEventsFilters';
import renderEventFilter from 'components/AllEvents/lib/renderEventFilter';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type { FilterGroup } from 'components/material/ColumnFilters/columnFilterTypes';
import AdvancedFilterWrapper from 'components/material/Filters/Advanced/AdvancedFilterWrapper';
import { type FilterChangeType } from 'components/material/Filters/Advanced/FilterItems';
import FiltersContent from 'components/material/Filters/Advanced/FiltersContent';

import type { AllEventsFiltersQueryResponse } from './__generated__/AllEventsFiltersQuery.graphql';

const query = graphql`
  query AllEventsFiltersQuery {
    me {
      teams {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    org {
      settings {
        fiscalYearStart
      }
      eventRequestForms {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export default class AllEventsFilters extends React.Component<{
  history: RouterHistory,
  filters: ParsedAllEventsFilters,
  filterOptions: $ReadOnlyArray<FieldType>,
  groups: $ReadOnlyArray<FilterGroup>,
  viewMode: 'calendar' | 'table' | 'map',
  mobileBreakpoint?: number,
}> {
  filterRenderer = (
    props: AllEventsFiltersQueryResponse,
    field: FieldType,
    filters: ParsedAllEventsFilters,
    onFilterChange: FilterChangeType,
  ) => {
    const { viewMode } = this.props;
    const fiscalYearStart = props.org.settings.fiscalYearStart;

    return renderEventFilter(field, filters, onFilterChange, {
      skipDateFilters: viewMode === 'calendar',
      fiscalYearStart,
      showNameFilter: true,
      teams: props.me.teams.edges,
      eventRequestForms: props.org.eventRequestForms.edges,
    });
  };

  getFilteredOptions = () => {
    const { viewMode, filterOptions } = this.props;
    return viewMode === 'calendar'
      ? filterOptions.filter(option => option.id !== 'start_date')
      : filterOptions;
  };

  render() {
    const { viewMode, filters, history, groups, mobileBreakpoint } = this.props;

    const shownFilters = viewMode === 'calendar' ? omit(filters, 'date') : filters;

    const { requesterUserIds, requesterContactIds, ...otherFilters } = shownFilters;
    const requesterFilters = [...(requesterUserIds || []), ...(requesterContactIds || [])];
    const count =
      Object.keys(otherFilters).filter(
        x =>
          ![
            'sort',
            'search',
            'month',
            'week',
            ...(viewMode === 'calendar' ? ['date'] : []),
          ].includes(x) && otherFilters[x] != null,
      ).length + (requesterFilters.length > 0 ? 1 : 0);

    return (
      <AdvancedFilterWrapper
        count={count}
        filters={shownFilters}
        mobileBreakpoint={mobileBreakpoint}
      >
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(props: AllEventsFiltersQueryResponse) => (
            <FiltersContent
              history={history}
              filters={shownFilters}
              filterOptions={this.getFilteredOptions()}
              groups={groups}
              filterRenderer={(...args) => this.filterRenderer(props, ...args)}
              converterFn={getEventFilterConverters}
            />
          )}
        />
      </AdvancedFilterWrapper>
    );
  }
}
