/**
 * @flow
 * @relayHash 8fc8724025016e783fe0a510a6eb9924
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EventTabs = "ATTACHMENTS" | "BUDGET" | "CHECKLIST" | "CONTACTS" | "INFO" | "NOTES" | "REGISTRATION" | "ROI" | "SCHEDULE" | "STAFF" | "VENDORS";
export type UpdateEventTabsInput = {
  clientMutationId?: ?string,
  eventId: string,
  tabs: $ReadOnlyArray<?EventTabs>,
};
export type updateEventTabsMutationVariables = {|
  input: UpdateEventTabsInput
|};
export type updateEventTabsMutationResponse = {|
  +updateEventTabs: ?{|
    +event: {|
      +id: string,
      +shownTabs: $ReadOnlyArray<EventTabs>,
    |}
  |}
|};
export type updateEventTabsMutation = {|
  variables: updateEventTabsMutationVariables,
  response: updateEventTabsMutationResponse,
|};
*/


/*
mutation updateEventTabsMutation(
  $input: UpdateEventTabsInput!
) {
  updateEventTabs(input: $input) {
    event {
      id
      shownTabs
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventTabsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateEventTabs",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateEventTabsInput!"
      }
    ],
    "concreteType": "UpdateEventTabsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "shownTabs",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventTabsMutation",
  "id": null,
  "text": "mutation updateEventTabsMutation(\n  $input: UpdateEventTabsInput!\n) {\n  updateEventTabs(input: $input) {\n    event {\n      id\n      shownTabs\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventTabsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventTabsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b71184d90ce4f9cc5694ca9c331c2e2b';
module.exports = node;
