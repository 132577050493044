/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ContactSaveMethod = "api" | "g2w" | "import" | "manual" | "past_g2w" | "past_zoom" | "registration" | "zapier" | "zoom";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactUpdatedMethod_contact$ref: FragmentReference;
export type ContactUpdatedMethod_contact = {|
  +updatedMethod: ContactSaveMethod,
  +$refType: ContactUpdatedMethod_contact$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactUpdatedMethod_contact",
  "type": "Contact",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedMethod",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '085ed32928513142fa80cb28299ba315';
module.exports = node;
