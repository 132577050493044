/**
 * @flow
 * @relayHash e6aba939b516eec4ec742b2d404d6a21
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
export type SectionCustomizableType = "EVENT";
export type EventFieldsSettingsQueryVariables = {|
  sectionCustomizableType: $ReadOnlyArray<SectionCustomizableType>,
  customizableType: $ReadOnlyArray<CustomizableType>,
  includeDisabled?: ?boolean,
|};
export type EventFieldsSettingsQueryResponse = {|
  +org: {|
    +id: string,
    +customFieldSections: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +order: number,
        |}
      |}>
    |},
    +customFields: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +kind: CustomFieldKindType,
          +label: string,
          +fieldName: ?string,
          +required: boolean,
          +enabled: boolean,
          +order: number,
          +showInCreateForm: boolean,
          +showInMobileForm: boolean,
          +restrictManagingOptions: boolean,
          +section: ?{|
            +id: string
          |},
          +options: {|
            +edges: $ReadOnlyArray<{|
              +node: {|
                +id: string,
                +name: string,
                +editable: boolean,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |}
|};
export type EventFieldsSettingsQuery = {|
  variables: EventFieldsSettingsQueryVariables,
  response: EventFieldsSettingsQueryResponse,
|};
*/


/*
query EventFieldsSettingsQuery(
  $sectionCustomizableType: [SectionCustomizableType!]!
  $customizableType: [CustomizableType!]!
  $includeDisabled: Boolean
) {
  org {
    id
    customFieldSections(sectionCustomizableType: $sectionCustomizableType) {
      edges {
        node {
          id
          name
          order
        }
      }
    }
    customFields(customizableType: $customizableType, includeDisabled: $includeDisabled) {
      edges {
        node {
          id
          kind
          label
          fieldName
          required
          enabled
          order
          showInCreateForm
          showInMobileForm
          restrictManagingOptions: mappedToSalesforce
          section {
            id
          }
          options {
            edges {
              node {
                id
                name
                editable
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sectionCustomizableType",
    "type": "[SectionCustomizableType!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "customizableType",
    "type": "[CustomizableType!]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeDisabled",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "org",
    "storageKey": null,
    "args": null,
    "concreteType": "Org",
    "plural": false,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customFieldSections",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "sectionCustomizableType",
            "variableName": "sectionCustomizableType",
            "type": "[SectionCustomizableType!]!"
          }
        ],
        "concreteType": "CustomFieldSectionTypeRequiredConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomFieldSectionTypeRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldSectionType",
                "plural": false,
                "selections": [
                  v1,
                  v2,
                  v3
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customFields",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "customizableType",
            "variableName": "customizableType",
            "type": "[CustomizableType!]!"
          },
          {
            "kind": "Variable",
            "name": "includeDisabled",
            "variableName": "includeDisabled",
            "type": "Boolean"
          }
        ],
        "concreteType": "CustomFieldTypeRequiredConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomFieldTypeRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldType",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "kind",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "label",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fieldName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "required",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "enabled",
                    "args": null,
                    "storageKey": null
                  },
                  v3,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "showInCreateForm",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "showInMobileForm",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": "restrictManagingOptions",
                    "name": "mappedToSalesforce",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "section",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldSectionType",
                    "plural": false,
                    "selections": [
                      v1
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "options",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldOptionTypeRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionTypeRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldOptionType",
                            "plural": false,
                            "selections": [
                              v1,
                              v2,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "editable",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EventFieldsSettingsQuery",
  "id": null,
  "text": "query EventFieldsSettingsQuery(\n  $sectionCustomizableType: [SectionCustomizableType!]!\n  $customizableType: [CustomizableType!]!\n  $includeDisabled: Boolean\n) {\n  org {\n    id\n    customFieldSections(sectionCustomizableType: $sectionCustomizableType) {\n      edges {\n        node {\n          id\n          name\n          order\n        }\n      }\n    }\n    customFields(customizableType: $customizableType, includeDisabled: $includeDisabled) {\n      edges {\n        node {\n          id\n          kind\n          label\n          fieldName\n          required\n          enabled\n          order\n          showInCreateForm\n          showInMobileForm\n          restrictManagingOptions: mappedToSalesforce\n          section {\n            id\n          }\n          options {\n            edges {\n              node {\n                id\n                name\n                editable\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EventFieldsSettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v4
  },
  "operation": {
    "kind": "Operation",
    "name": "EventFieldsSettingsQuery",
    "argumentDefinitions": v0,
    "selections": v4
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9be1fb7917f2b3255e432232a12b70b9';
module.exports = node;
