/* @flow */
import React from 'react';
import { type RouterHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import moment from 'moment';

import formatDateRange from 'utils/date/formatDateRange';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import stringifyQueryParamValues, {
  dateToStringParam,
} from 'utils/routing/stringifyQueryParamValues';

import Arrow from 'images/arrow.svg';
import Dropdown from 'components/budget/Dropdown';

const Root = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  margin: -10px -10px 10px;
  padding: 10px;
  background: #f2fbff;

  @media (max-width: 920px) {
    display: flex;
    justify-content: flex-end;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 240px;
  max-width: 270px;
  margin: 0 auto;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  color: #3e4859;
  user-select: none;

  @media (max-width: 920px) {
    margin: initial;
  }
`;

const SideContainer = styled.div`
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  width: 150px;

  > *:first-child {
    flex: 1;
  }

  @media (max-width: 920px) {
  }

  @media (max-width: 327px) {
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  color: #c4cbd0;
  outline: none;
  ${props =>
    props.active &&
    css`
      cursor: pointer;
      color: #868f96;
      &:hover {
        color: #39a8da;
      }
    `};

  &:first-child {
    margin-right: 15px;
    transform: rotate(180deg);
  }

  &:last-child {
    margin-left: 15px;
  }
`;

const LoadingIcon = styled.i`
  margin-left: 10px;
  font-size: 14px;
  color: #868f96;
`;

export default class AllEventsCalendarControls extends React.PureComponent<
  {
    history: RouterHistory,
    loading: boolean,
    month: string,
    week: ?string,
  },
> {
  converters = {
    month: dateToStringParam,
    week: dateToStringParam,
  };

  minDate = () =>
    moment()
      .startOf('day')
      .subtract(this.props.week ? 26 : 6, this.props.week ? 'week' : 'months');

  maxDate = () =>
    moment()
      .startOf('day')
      .add(this.props.week ? 26 : 6, this.props.week ? 'week' : 'month');

  handleChangeDate = ({ month, week }: { month: moment, week: ?moment }) =>
    replaceQueryParams(
      this.props.history,
      stringifyQueryParamValues({ month: week ? null : month, week }, this.converters),
    );

  handleChangeView = (view: ?'week' | 'month') => {
    const { month, week } = this.props;

    const newMonth = week ? moment(week).endOf('week').startOf('month') : moment(month);
    const currentWeek = moment().isSame(month, 'month') ? moment().startOf('week') : null;
    const newWeek = view === 'week' ? currentWeek || moment(month).startOf('week') : null;
    const minDate = this.minDate();
    const maxDate = this.maxDate();

    this.handleChangeDate({
      month: moment.min(maxDate, moment.max(minDate, newMonth)),
      week: newWeek && moment.min(maxDate, moment.max(minDate, newWeek)),
    });
  };

  handleClickPrev = () => {
    this.handleChangeDate({
      month: moment(this.props.month).subtract(1, 'month'),
      week: this.props.week ? moment(this.props.week).subtract(1, 'week') : null,
    });
  };

  handleClickNext = () => {
    this.handleChangeDate({
      month: moment(this.props.month).add(1, 'month'),
      week: this.props.week ? moment(this.props.week).add(1, 'week') : null,
    });
  };

  render() {
    const { month, week, loading } = this.props;

    const prevActive = moment(week || month).isAfter(this.minDate(), week ? 'week' : 'month');
    const nextActive = moment(week || month).isBefore(this.maxDate(), week ? 'week' : 'month');

    const title = week
      ? formatDateRange(moment(week).startOf('week'), moment(week).endOf('week'))
      : moment(month).format('MMMM, YYYY');

    return (
      <Root>
        <SideContainer>
          <Dropdown
            value={week ? 'week' : 'month'}
            options={[
              { label: 'Month', value: 'month' },
              { label: 'Week', value: 'week' },
            ]}
            onChange={this.handleChangeView}
          />

          <LoadingIcon
            className="fa fa-circle-o-notch fa-spin"
            style={{ visibility: loading ? 'visible' : 'hidden' }}
          />
        </SideContainer>

        <Title compact={false}>
          <Button onClick={prevActive ? this.handleClickPrev : null} active={prevActive}>
            <Arrow />
          </Button>
          {title}
          <Button onClick={nextActive ? this.handleClickNext : null} active={nextActive}>
            <Arrow />
          </Button>
        </Title>
      </Root>
    );
  }
}
