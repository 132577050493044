/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type PaymentStatus = "APPROVED" | "PAID" | "PENDING" | "UNPAID";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentStatusCell_payment$ref: FragmentReference;
export type PaymentStatusCell_payment = {|
  +status: PaymentStatus,
  +$refType: PaymentStatusCell_payment$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PaymentStatusCell_payment",
  "type": "Payment",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd94ce20d8a7a9922385963b006a656b7';
module.exports = node;
