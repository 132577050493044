/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import capitalize from 'lodash/capitalize';

import SimpleBriefField from '../../components/SimpleBriefField';

import { type EventInfoBooth_event } from './__generated__/EventInfoBooth_event.graphql';

const EventInfoBooth = (props: { name: string, event: EventInfoBooth_event }) => {
  const {
    name,
    event: {
      eventFloorPlan: { booth },
    },
  } = props;
  return <SimpleBriefField label={name} contentDataAvailable={booth} data={capitalize(booth)} />;
};

export default createFragmentContainer(
  EventInfoBooth,
  graphql`
    fragment EventInfoBooth_event on Event {
      eventFloorPlan {
        booth
      }
    }
  `,
);
