/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Match, type RouterHistory, Redirect, Route, Switch } from 'react-router';
import styled from 'styled-components';

import { type IntegrationSettings } from 'components/integrations/IntegrationsSettings';
import IntegrationSettingsSubmenu from 'components/integrations/IntegrationsSettings/IntegrationSettingsSubmenu';

import components from './lib/components';
import MarketoWindow from './MarketoWindow';

import { type MarketoContent_org } from './__generated__/MarketoContent_org.graphql';

const Content = styled.div`
  max-width: 710px;
`;

class MarketoContent extends React.PureComponent<
  {
    integration: IntegrationSettings,
    match: Match,
    history: RouterHistory,
    org: MarketoContent_org,
  },
  { showWindow: boolean },
> {
  state = { showWindow: !this.props.integration.connected };

  componentDidUpdate() {
    if (this.state.showWindow || this.props.integration.connected) return;

    this.props.history.push('/settings/integrations');
  }

  handleCloseWindow = () => {
    if (this.props.integration.connected) {
      this.setState({ showWindow: false });
    } else {
      this.props.history.push('/settings/integrations');
    }
  };

  render() {
    const { integration, match, org } = this.props;

    const tabs =
      integration.connected && !integration.expired ? components : components.slice(0, 1);

    if (this.state.showWindow) {
      return <MarketoWindow integration={integration} org={org} onClose={this.handleCloseWindow} />;
    }

    return (
      <>
        <IntegrationSettingsSubmenu
          items={tabs.map(tab => ({ label: tab.label, to: `${match.url}/${tab.path}` }))}
        />

        <Content>
          <Switch>
            {tabs.map(tab => (
              <Route
                key={tab.path}
                path={`${match.path}/${tab.path}`}
                exact
                render={props => <tab.component {...props} org={org} integration={integration} />}
              />
            ))}

            <Redirect from={match.path} to={`${match.path}/${tabs[0].path}`} />
          </Switch>
        </Content>
      </>
    );
  }
}

export default createFragmentContainer(
  MarketoContent,
  graphql`
    fragment MarketoContent_org on Org {
      ...MarketoAuth_org
      ...MarketoWindow_org
    }
  `,
);
