/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const Label = styled.div`
  ${props =>
    props.addPadding &&
    css`
      padding-bottom: 10px;
    `}
  line-height: 1em;
  color: #464d72;
`;

const PropertyRow = (props: { label: string, children: React.Node, multiline?: boolean }) => {
  const { label, children, multiline } = props;
  return (
    <Container>
      <Label addPadding={multiline}>{label}</Label>
      {children}
    </Container>
  );
};

export default PropertyRow;
