/**
 * @flow
 * @relayHash 42d9586bc5529dcd55a3d8e13a5e4636
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DashboardRoutes_org$ref = any;
export type DashboardQueryVariables = {||};
export type DashboardQueryResponse = {|
  +org: {|
    +$fragmentRefs: DashboardRoutes_org$ref
  |}
|};
export type DashboardQuery = {|
  variables: DashboardQueryVariables,
  response: DashboardQueryResponse,
|};
*/


/*
query DashboardQuery {
  org {
    ...DashboardRoutes_org
    id
  }
}

fragment DashboardRoutes_org on Org {
  viewerCanImport
  viewerCanUpdate
  viewerCanViewEventRequestSubmissions
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "operationKind": "query",
  "name": "DashboardQuery",
  "id": null,
  "text": "query DashboardQuery {\n  org {\n    ...DashboardRoutes_org\n    id\n  }\n}\n\nfragment DashboardRoutes_org on Org {\n  viewerCanImport\n  viewerCanUpdate\n  viewerCanViewEventRequestSubmissions\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DashboardRoutes_org",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanImport",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanUpdate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanViewEventRequestSubmissions",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
// prettier-ignore
(node/*: any*/).hash = 'c33f7c0f8a83ab6673d2b9b123f504d1';
module.exports = node;
