/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventInfoActualBudget_event$ref = any;
type EventInfoAmountOfOpportunities_event$ref = any;
type EventInfoBooth_event$ref = any;
type EventInfoCustomBoolean_event$ref = any;
type EventInfoCustomCurrency_event$ref = any;
type EventInfoCustomDate_event$ref = any;
type EventInfoCustomLink_event$ref = any;
type EventInfoCustomMultiselect_event$ref = any;
type EventInfoCustomNumber_event$ref = any;
type EventInfoCustomSelect_event$ref = any;
type EventInfoCustomText_event$ref = any;
type EventInfoCustomTextarea_event$ref = any;
type EventInfoCustomUserMultiselect_event$ref = any;
type EventInfoCustomUserSelect_event$ref = any;
type EventInfoDescription_event$ref = any;
type EventInfoDimensions_event$ref = any;
type EventInfoFloorPlan_event$ref = any;
type EventInfoLastSynced_event$ref = any;
type EventInfoLeader_event$ref = any;
type EventInfoMarketoId_event$ref = any;
type EventInfoNumberOfOpportunities_event$ref = any;
type EventInfoPlannedBudget_event$ref = any;
type EventInfoProgress_event$ref = any;
type EventInfoSalesforceId_event$ref = any;
type EventInfoStatus_event$ref = any;
type EventInfoSyncStatus_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventInfoBrief_event$ref: FragmentReference;
export type EventInfoBrief_event = {|
  +$fragmentRefs: EventInfoStatus_event$ref & EventInfoProgress_event$ref & EventInfoDescription_event$ref & EventInfoFloorPlan_event$ref & EventInfoLeader_event$ref & EventInfoDimensions_event$ref & EventInfoBooth_event$ref & EventInfoLastSynced_event$ref & EventInfoSalesforceId_event$ref & EventInfoMarketoId_event$ref & EventInfoAmountOfOpportunities_event$ref & EventInfoNumberOfOpportunities_event$ref & EventInfoSyncStatus_event$ref & EventInfoPlannedBudget_event$ref & EventInfoActualBudget_event$ref & EventInfoCustomText_event$ref & EventInfoCustomTextarea_event$ref & EventInfoCustomLink_event$ref & EventInfoCustomNumber_event$ref & EventInfoCustomCurrency_event$ref & EventInfoCustomDate_event$ref & EventInfoCustomBoolean_event$ref & EventInfoCustomUserSelect_event$ref & EventInfoCustomUserMultiselect_event$ref & EventInfoCustomSelect_event$ref & EventInfoCustomMultiselect_event$ref,
  +$refType: EventInfoBrief_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventInfoBrief_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeEventStatus",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventProgress",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventDescription",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventFloorPlan",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventLeader",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventDimensions",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventBoothNumber",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventLastSynced",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventSalesforceId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventMarketoId",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventAmountAllOpportunities",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventNumberOfOpportunities",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventSyncStatus",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventPlannedCost",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventActualCost",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomText_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomTextarea_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomLink_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomNumber_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomCurrency_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomDate_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomBoolean_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomUserSelect_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomUserMultiselect_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomSelect_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomMultiselect_event",
      "args": null
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventStatus",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoStatus_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventProgress",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoProgress_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventDescription",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoDescription_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventFloorPlan",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoFloorPlan_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventLeader",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoLeader_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventDimensions",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoDimensions_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventBoothNumber",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoBooth_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventLastSynced",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoLastSynced_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventSalesforceId",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoSalesforceId_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventMarketoId",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoMarketoId_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventAmountAllOpportunities",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoAmountOfOpportunities_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventNumberOfOpportunities",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoNumberOfOpportunities_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventSyncStatus",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoSyncStatus_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventPlannedCost",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoPlannedBudget_event",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventActualCost",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoActualBudget_event",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '5f5a3b766469a11ad39a136a225dfdb4';
module.exports = node;
