/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type SubmissionReviewForm_org$ref = any;
type SubmissionReviewSidebar_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubmissionReviewContent_org$ref: FragmentReference;
export type SubmissionReviewContent_org = {|
  +$fragmentRefs: SubmissionReviewSidebar_org$ref & SubmissionReviewForm_org$ref,
  +$refType: SubmissionReviewContent_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SubmissionReviewContent_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "SubmissionReviewSidebar_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SubmissionReviewForm_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '34835a419325bc4f4a9ea00c12c2d41f';
module.exports = node;
