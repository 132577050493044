/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineTextField from 'components/budget/Table/InlineTextField';

import type { ContactPhone_contact } from './__generated__/ContactPhone_contact.graphql';

class ContactPhone extends React.Component<{
  contact: ContactPhone_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?string) => {
    const { contact, fieldSettings, updateColumnWidth, onUpdate } = this.props;
    const currentValue = fieldSettings.fieldName != null ? contact[fieldSettings.fieldName] : '';

    if (!this.getError(value) && value !== currentValue && fieldSettings.fieldName != null) {
      onUpdate({ [fieldSettings.fieldName]: value });
      updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    if (this.props.fieldSettings.required && (!value || !value.trim())) {
      return 'Required';
    }
    return null;
  };

  handlePhoneDial = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { contact, fieldSettings } = this.props;
    const value = fieldSettings.fieldName != null ? contact[fieldSettings.fieldName] : '';
    window.open(`tel:${value}`);
  };

  render() {
    const { contact, fieldSettings, updateColumnWidth } = this.props;
    const value = fieldSettings.fieldName != null ? contact[fieldSettings.fieldName] : '';

    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={value || ''}
        getError={this.getError}
        onChange={this.handleUpdate}
        updateColumnWidth={updateColumnWidth}
        disabled={fieldSettings.restrictChangingValue}
      >
        {value && (
          <a
            onMouseDown={this.handleMouseDown}
            onClick={this.handlePhoneDial}
            href={`tel:${value}`}
          >
            {value}
          </a>
        )}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  ContactPhone,
  graphql`
    fragment ContactPhone_contact on Contact {
      phone1
      phone2
    }
  `,
);
