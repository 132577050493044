/* @flow */

import type { RequesterInfoType } from 'components/EventRequestForm/lib/types';

import type { QuestionValueWithMapping, RelayQuestionType, RelaySectionType } from '../index';
import validateRulesAndConditions from './validateRulesAndConditions';

const isRuleableHidden = (
  ruleable: RelaySectionType | RelayQuestionType,
  requesterInfo: RequesterInfoType,
  questionValues: $ReadOnlyArray<QuestionValueWithMapping>,
  tz: string,
): boolean => {
  if (
    !ruleable.rules ||
    !ruleable.rules.edges.some(({ node: rule }) =>
      ['HIDE', 'HIDE_BY_DEFAULT'].includes(rule.action),
    )
  ) {
    return false;
  }

  const defaultVisible = !ruleable.rules.edges.some(
    ({ node: rule }) => rule.action === 'HIDE_BY_DEFAULT',
  );

  const makeVisibleRules = ruleable.rules.edges
    .filter(({ node: rule }) => rule.action === 'SHOW')
    .map(edge => edge.node);
  const makeHiddenRules = ruleable.rules.edges
    .filter(({ node: rule }) => rule.action === 'HIDE')
    .map(edge => edge.node);

  const makeVisibleRuleResult = validateRulesAndConditions(
    makeVisibleRules,
    requesterInfo,
    questionValues,
    tz,
  );
  const makeHiddenRuleResult = validateRulesAndConditions(
    makeHiddenRules,
    requesterInfo,
    questionValues,
    tz,
  );

  // Default changes to false if there are only rules to make the question hidden
  // Visible rule has higher priority than hide rule
  if (defaultVisible && (makeVisibleRuleResult || !makeHiddenRuleResult)) {
    return false;
  }

  if (!defaultVisible && makeVisibleRuleResult) {
    return false;
  }

  return true;
};

export default isRuleableHidden;
