/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type DefaultLeadSource_org$ref = any;
type RequiredFieldsWarning_salesforceFields$ref = any;
type SalesforceMappingRow_salesforceFields$ref = any;
type SalesforceMappingRow_salesforceMapping$ref = any;
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type SalesforceKindType = "CONTACT" | "EVENT" | "EVENT_CONTACT" | "LEAD" | "OPPORTUNITY";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesforceMapping_org$ref: FragmentReference;
export type SalesforceMapping_org = {|
  +syncedToIbmWm: boolean,
  +salesforceAccount: ?{|
    +id: string,
    +salesforceFields: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +kind: SalesforceKindType,
          +$fragmentRefs: RequiredFieldsWarning_salesforceFields$ref & SalesforceMappingRow_salesforceFields$ref,
        |}
      |}>
    |},
    +salesforceMappings: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +kind: SalesforceKindType,
          +circaField: string,
          +salesforceField: string,
          +customField: ?{|
            +id: string
          |},
          +$fragmentRefs: SalesforceMappingRow_salesforceMapping$ref,
        |}
      |}>
    |},
    +opportunityStatuses?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +enabled: boolean,
        |}
      |}>
    |},
  |},
  +contactCustomFields?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +kind: CustomFieldKindType,
        +order: number,
        +label: string,
        +required: boolean,
        +fieldName: ?string,
      |}
    |}>
  |},
  +eventContactCustomFields?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +kind: CustomFieldKindType,
        +order: number,
        +label: string,
        +required: boolean,
        +fieldName: ?string,
      |}
    |}>
  |},
  +eventCustomFields?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +kind: CustomFieldKindType,
        +order: number,
        +label: string,
        +required: boolean,
        +fieldName: ?string,
        +section: ?{|
          +order: number
        |},
      |}
    |}>
  |},
  +opportunityCustomFields?: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +kind: CustomFieldKindType,
        +order: number,
        +label: string,
        +required: boolean,
        +fieldName: ?string,
      |}
    |}>
  |},
  +$fragmentRefs: DefaultLeadSource_org$ref,
  +$refType: SalesforceMapping_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "kinds",
    "variableName": "kinds",
    "type": "[SalesforceKindType!]!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "required",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "CustomFieldTypeRequiredEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomFieldType",
        "plural": false,
        "selections": [
          v0,
          v2,
          v3,
          v4,
          v5,
          v6
        ]
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "SalesforceMapping_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "kinds",
      "type": "[SalesforceKindType!]!"
    },
    {
      "kind": "RootArgument",
      "name": "isOpportunity",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "isContact",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "isEventContact",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "isEvent",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "syncedToIbmWm",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "salesforceFields",
          "storageKey": null,
          "args": v1,
          "concreteType": "SalesforceFieldRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "SalesforceFieldRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SalesforceField",
                  "plural": false,
                  "selections": [
                    v2,
                    {
                      "kind": "FragmentSpread",
                      "name": "RequiredFieldsWarning_salesforceFields",
                      "args": null
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "SalesforceMappingRow_salesforceFields",
                      "args": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "salesforceMappings",
          "storageKey": null,
          "args": v1,
          "concreteType": "SalesforceMappingRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "SalesforceMappingRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SalesforceMapping",
                  "plural": false,
                  "selections": [
                    v2,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "circaField",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "salesforceField",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "customField",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomFieldType",
                      "plural": false,
                      "selections": [
                        v0
                      ]
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "SalesforceMappingRow_salesforceMapping",
                      "args": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "Condition",
          "passingValue": true,
          "condition": "isOpportunity",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "opportunityStatuses",
              "storageKey": null,
              "args": null,
              "concreteType": "SalesforceOpportunityStatusTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SalesforceOpportunityStatusTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "SalesforceOpportunityStatusType",
                      "plural": false,
                      "selections": [
                        v0,
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "name",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "enabled",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "isContact",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "DefaultLeadSource_org",
          "args": null
        },
        {
          "kind": "LinkedField",
          "alias": "contactCustomFields",
          "name": "customFields",
          "storageKey": "customFields(customizableType:[\"CONTACT\"])",
          "args": [
            {
              "kind": "Literal",
              "name": "customizableType",
              "value": [
                "CONTACT"
              ],
              "type": "[CustomizableType!]!"
            }
          ],
          "concreteType": "CustomFieldTypeRequiredConnection",
          "plural": false,
          "selections": v7
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "isEventContact",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": "eventContactCustomFields",
          "name": "customFields",
          "storageKey": "customFields(customizableType:[\"EVENTCONTACT\"])",
          "args": [
            {
              "kind": "Literal",
              "name": "customizableType",
              "value": [
                "EVENTCONTACT"
              ],
              "type": "[CustomizableType!]!"
            }
          ],
          "concreteType": "CustomFieldTypeRequiredConnection",
          "plural": false,
          "selections": v7
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "isEvent",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": "eventCustomFields",
          "name": "customFields",
          "storageKey": "customFields(customizableType:[\"EVENT\"])",
          "args": [
            {
              "kind": "Literal",
              "name": "customizableType",
              "value": [
                "EVENT"
              ],
              "type": "[CustomizableType!]!"
            }
          ],
          "concreteType": "CustomFieldTypeRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldTypeRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldType",
                  "plural": false,
                  "selections": [
                    v0,
                    v2,
                    v3,
                    v4,
                    v5,
                    v6,
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "section",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomFieldSectionType",
                      "plural": false,
                      "selections": [
                        v3
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "isOpportunity",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": "opportunityCustomFields",
          "name": "customFields",
          "storageKey": "customFields(customizableType:[\"SALESFORCEOPPORTUNITY\"])",
          "args": [
            {
              "kind": "Literal",
              "name": "customizableType",
              "value": [
                "SALESFORCEOPPORTUNITY"
              ],
              "type": "[CustomizableType!]!"
            }
          ],
          "concreteType": "CustomFieldTypeRequiredConnection",
          "plural": false,
          "selections": v7
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f8bae8f441acb07c00a21ad62887db2';
module.exports = node;
