/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import NotAuthorized from 'components/NotAuthorized';

import Attachments from './Attachments';

import type { AttachmentsQueryResponse } from './__generated__/AttachmentsQuery.graphql';

const query = graphql`
  query AttachmentsQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      viewerCanViewAttachments
      ...Attachments_event
    }
    me {
      id
    }
  }
`;

export default class AttachmentsContainer extends React.Component<{
  eventSlug: string,
}> {
  render() {
    return (
      <DefaultQueryRenderer
        variables={{
          eventSlug: this.props.eventSlug,
        }}
        query={query}
        renderSuccess={(props: AttachmentsQueryResponse) => {
          if (props.event == null || !props.event.viewerCanViewAttachments) {
            return <NotAuthorized />;
          }
          return <Attachments event={props.event} userId={props.me.id} />;
        }}
      />
    );
  }
}
