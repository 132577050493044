/* @flow */
import updatePayment, {
  type Payment as MutationPayment,
} from 'graph/mutations/payment/updatePayment';

import PaymentActionCell from './PaymentActionCell';
import PaymentActualDateCell from './PaymentActualDateCell';
import PaymentAmountCell from './PaymentAmountCell';
import PaymentAmountFooterCell from './PaymentAmountFooterCell';
import PaymentAmountOfExpenseCell from './PaymentAmountOfExpenseCell';
import PaymentDueDateCell from './PaymentDueDateCell';
import PaymentExpenseNameCell from './PaymentExpenseNameCell';
import PaymentMethodCell from './PaymentMethodCell';
import PaymentNoteCell from './PaymentNoteCell';
import PaymentPONumberCell from './PaymentPONumberCell';
import PaymentReferenceCell from './PaymentReferenceCell';
import PaymentStatusCell from './PaymentStatusCell';
import PaymentTypeCell from './PaymentTypeCell';

export type PaymentFooterCellPropsType<Event: {} = {}, Org: {} = {}> = {|
  org: Org,
  event: Event,
|};

export type PaymentCellPropsType<Payment: {}, Event: {} = {}, Org: {} = {}> = {|
  org: Org,
  event: Event,
  payment: Payment,
  updatePayment: MutationPayment => $Call<typeof updatePayment, string, MutationPayment>,
|};

const columns = [
  {
    title: 'Expense',
    sortKey: 'EXPENSE_NAME',
    component: PaymentExpenseNameCell,
    footerComponent: () => 'Total',
    grow: 1,
  },
  {
    title: 'Payment Type',
    sortKey: 'PAYMENT_TYPE',
    component: PaymentTypeCell,
  },
  {
    title: 'Amount',
    sortKey: 'AMOUNT',
    align: 'right',
    component: PaymentAmountCell,
    footerComponent: PaymentAmountFooterCell,
  },
  {
    title: '% of Total',
    sortKey: 'PERCENT_OF_EXPENSE',
    align: 'right',
    component: PaymentAmountOfExpenseCell,
  },
  {
    title: 'Due Date',
    sortKey: 'DUE_DATE',
    component: PaymentDueDateCell,
  },
  {
    title: 'Paid Date',
    sortKey: 'ACTUAL_DATE',
    component: PaymentActualDateCell,
  },
  {
    title: 'Status',
    sortKey: 'STATUS',
    component: PaymentStatusCell,
  },
  {
    title: 'Method',
    sortKey: 'PAYMENT_METHOD',
    component: PaymentMethodCell,
  },
  {
    title: 'PO#',
    sortKey: 'PO_NUMBER',
    component: PaymentPONumberCell,
  },
  {
    title: 'Ref. Code',
    sortKey: 'REFERENCE',
    component: PaymentReferenceCell,
  },
  {
    title: 'Note',
    sortKey: 'NOTE',
    component: PaymentNoteCell,
  },
  {
    title: '',
    component: PaymentActionCell,
    type: 'action',
  },
];

export default columns;
