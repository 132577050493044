/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  addParticipantMutationResponse,
  addParticipantMutationVariables,
} from './__generated__/addParticipantMutation.graphql';

const mutation = graphql`
  mutation addParticipantMutation($input: AddParticipantToScheduleItemInput!) {
    addParticipantToScheduleItem(input: $input) {
      scheduleParticipantEdge {
        __typename
        node {
          id
          user {
            id
            firstName
            lastName
            avatar
            email
          }
        }
      }
      event {
        staffers {
          edges {
            node {
              user {
                ...UsersGroup_users
              }
            }
          }
        }
      }
    }
  }
`;

export default function addParticipant(
  scheduleItemId: string,
  userId: string,
): Promise<addParticipantMutationResponse> {
  const variables: addParticipantMutationVariables = {
    input: {
      scheduleItemId,
      userId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: scheduleItemId,
      edgeName: 'scheduleParticipantEdge',
      connections: [{ field: 'scheduleParticipants' }],
    }),
  });
}
