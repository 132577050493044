/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { CompanyUpdatedAt_company } from './__generated__/CompanyUpdatedAt_company.graphql';

type Props = {
  company: CompanyUpdatedAt_company,
  tz: string,
};

const CompanyUpdatedAt = ({ company, tz }: Props) => {
  if (!company) {
    return null;
  }

  return company.updatedAt && <DateTimeTooltip date={moment.tz(company.updatedAt, tz)} />;
};

export default createFragmentContainer(
  CompanyUpdatedAt,
  graphql`
    fragment CompanyUpdatedAt_company on Company {
      updatedAt
    }
  `,
);
