/* @flow */
import { clearErrorReportingUser } from 'utils/errorReporting';
import { setUser, getAccessToken } from 'utils/Auth';
import { reset } from 'utils/analytics';
import { expireCookie } from 'utils/Cookies';
import showErrorPopup from 'utils/showErrorPopup';
import { clearGoogleCredentials } from '../oauth/requireGoogleAccessToken';

/* Logs out the current user */
export default function logout() {
  fetch('/users/sign_out', {
    method: 'DELETE',
    headers: {
      Authorization: getAccessToken(),
    },
  })
    .then(() => {
      setUser(null);
      expireCookie('disableAnalytics');
      clearErrorReportingUser();
      reset();
      clearGoogleCredentials();
      window.location.href = '/';
    })
    .catch(showErrorPopup);
}
