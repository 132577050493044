/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ImportLogsTableList_totalCountOrg$ref: FragmentReference;
export type ImportLogsTableList_totalCountOrg = {|
  +importsCount: {|
    +totalCount: number
  |},
  +$refType: ImportLogsTableList_totalCountOrg$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ImportLogsTableList_totalCountOrg",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "type",
      "type": "ImportType!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "importsCount",
      "name": "importLogs",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "type",
          "type": "ImportType!"
        }
      ],
      "concreteType": "ImportLogRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b976caf46b84f78291a7589fccca7fdc';
module.exports = node;
