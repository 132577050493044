/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type CellPropsType } from 'components/budget/Table';
import InlineTextField from 'components/budget/Table/InlineTextField';

import { type PaymentCellPropsType } from './index';

import { type PaymentReferenceCell_payment } from './__generated__/PaymentReferenceCell_payment.graphql';

const PaymentReferenceCell = ({
  payment,
  updatePayment,
  updateColumnWidth,
}: CellPropsType<PaymentCellPropsType<PaymentReferenceCell_payment>>) => (
  <InlineTextField
    value={payment.reference || ''}
    placeholder="Add Ref. Code"
    onChange={reference => {
      updatePayment({ reference });

      updateColumnWidth();
    }}
  />
);

export default createFragmentContainer(
  PaymentReferenceCell,
  graphql`
    fragment PaymentReferenceCell_payment on Payment {
      reference
    }
  `,
);
