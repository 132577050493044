/* @flow */
import React from 'react';
import styled from 'styled-components';
import uniqueId from 'lodash/uniqueId';

import InviteListRow from './InviteListRow';
import { type InviteFields } from './types';

export { validateInvite, invitesAreValid, inviteIsValid } from './validation';
export type { InviteFields };

export const generateBlankInvite: () => InviteFields = () => ({
  uid: uniqueId(),
  email: '',
  firstName: '',
  lastName: '',
});

const Container = styled.div`
  margin-top: 27px;
`;

const AddLink = styled.div`
  text-align: right;
  > a {
    color: ${props => props.theme.primaryActionColor};
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    &:hover {
      color: ${props => props.theme.primaryActionDarkerColor};
    }
    strong {
      font-size: 22px;
      margin-right: 6px;
    }
  }
`;

export default class InviteList extends React.Component<{
  invites: $ReadOnlyArray<InviteFields>,
  onChange: ($ReadOnlyArray<InviteFields>) => void,
  canAddInvites?: boolean,
  canRemoveInvites?: boolean,
  emailFirst?: boolean,
  disableEditingInitial?: boolean,
  className?: string,
}> {
  static defaultProps = {
    canAddInvites: true,
    canRemoveInvites: false,
  };

  handleChangeInvite = (index: number, fields: InviteFields) => {
    const newInvites = this.props.invites.slice();
    newInvites[index] = fields;
    this.props.onChange(newInvites);
  };

  handleAddInvite = () => {
    const newInvites = this.props.invites.slice();
    newInvites.push({
      uid: uniqueId(),
      email: '',
      firstName: '',
      lastName: '',
    });
    this.props.onChange(newInvites);
  };

  handleRemoveInvite = (index: number) => {
    this.props.onChange(this.props.invites.filter((invite, i) => i !== index));
  };

  render() {
    return (
      <Container className={this.props.className}>
        {this.props.invites.map((invite, index) => (
          <InviteListRow
            key={invite.uid}
            index={index}
            invite={invite}
            emailFirst={this.props.emailFirst}
            disableEditingInitial={this.props.disableEditingInitial}
            onChange={this.handleChangeInvite}
            onRemove={this.props.canRemoveInvites ? this.handleRemoveInvite : null}
          />
        ))}
        {this.props.invites.length > 0 && this.props.canAddInvites && (
          <AddLink>
            <a onClick={this.handleAddInvite}>
              <strong>+</strong> Add another
            </a>
          </AddLink>
        )}
      </Container>
    );
  }
}
