/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import Overlay from 'components/material/Overlay';

const Root = styled.div`
  position: relative;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 5px 0 15px;
  border-radius: 50px;
  font-size: 13px;
  background-color: #edf0fc;
  color: #4069e1;
  cursor: pointer;
  user-select: none;
`;

const Icon = styled.i`
  font-size: 18px;
  color: #868f96;
`;

const OptionIcon = styled(Icon)`
  margin-right: -10px;
`;

const LabelEllipsis = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledOverlay = styled(Overlay)`
  z-index: 10;
  width: auto;
  min-width: 100%;
  margin-top: 5px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    height: 10px;
  }

  ${props =>
    props.nested &&
    css`
      left: 100% !important;
      overflow-y: auto;
      min-width: 175px;
      max-height: 500px;
      margin-top: -30px;
      margin-left: -10px;
    `};
`;

const OptionRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 15px;
  white-space: nowrap;
  font-size: 13px;
  color: #677386;
  user-select: none;

  &:hover,
  &:active {
    background: #f2fbff;
  }

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `};
`;

const Placeholder = styled.div`
  color: rgba(103, 115, 134, 0.7);
`;

type NestedOptionType<V: string> = {|
  label: string,
  value: V,
|};

type OptionType<V: string> = {|
  label: string,
  value: V,
  options?: $ReadOnlyArray<NestedOptionType<V>>,
|};

export default class Dropdown<V: string> extends React.PureComponent<
  {
    value: ?V,
    options: $ReadOnlyArray<OptionType<V>>,
    onChange: (value: ?V) => void,
    clearable?: ?boolean,
  },
  {
    showOverlay: boolean,
    nestedOverlayOptionElement: ?Element,
    nestedOverlayOptionValue: ?string,
  },
> {
  state = {
    showOverlay: false,
    nestedOverlayOptionElement: null,
    nestedOverlayOptionValue: null,
  };

  labelRef = React.createRef();

  rootRef = React.createRef();

  handleShowOverlay = () => {
    this.setState({ showOverlay: true });
  };

  handleHideOverlay = () => {
    this.setState({
      showOverlay: false,
      nestedOverlayOptionElement: null,
      nestedOverlayOptionValue: null,
    });
  };

  handleHideNestedOverlay = () => {
    this.setState(state => {
      if (state.nestedOverlayOptionElement || state.nestedOverlayOptionValue) return null;

      return { nestedOverlayOptionElement: null, nestedOverlayOptionValue: null };
    });
  };

  handleClear = () => {
    this.handleHideOverlay();

    this.props.onChange(null);
  };

  handleSelect(value: V) {
    this.handleHideOverlay();

    this.props.onChange(value);
  }

  handleShowNestedOverlay(value: V, element: Element) {
    this.setState({ nestedOverlayOptionElement: element, nestedOverlayOptionValue: value });
  }

  render() {
    const { options, value, clearable } = this.props;
    const { showOverlay, nestedOverlayOptionValue, nestedOverlayOptionElement } = this.state;

    const activeOption = options
      .reduce((array, option) => [...array, option, ...(option.options || [])], [])
      .find(option => option.value === value);

    const hoveredOption =
      nestedOverlayOptionValue && options.find(option => option.value === nestedOverlayOptionValue);
    const nestedOptions = (hoveredOption && hoveredOption.options) || [];

    return (
      <Root ref={this.rootRef} onMouseLeave={this.handleHideOverlay}>
        <Label ref={this.labelRef} onClick={this.handleShowOverlay}>
          <LabelEllipsis>
            {activeOption ? activeOption.label : <Placeholder>Select...</Placeholder>}
          </LabelEllipsis>

          <Icon className="fa fa-fw fa-angle-down" />
        </Label>

        <StyledOverlay
          container={this.rootRef.current}
          target={this.labelRef.current}
          show={showOverlay}
          onHide={this.handleHideOverlay}
          forceRightEdge
        >
          {clearable && activeOption && <OptionRow onClick={this.handleClear}>None</OptionRow>}

          {options
            .filter(option => !activeOption || option.value !== activeOption.value)
            .map(option => {
              const hasNestedOptions = !!option.options;
              const onClick = hasNestedOptions ? null : () => this.handleSelect(option.value);
              const onMouseEnter = hasNestedOptions
                ? e => this.handleShowNestedOverlay(option.value, e.currentTarget)
                : this.handleHideNestedOverlay;

              return (
                <OptionRow key={option.value} onClick={onClick} onMouseEnter={onMouseEnter}>
                  {option.label}

                  {hasNestedOptions && <OptionIcon className="fa fa-fw fa-angle-right" />}
                </OptionRow>
              );
            })}
        </StyledOverlay>

        {nestedOverlayOptionElement && (
          <StyledOverlay
            nested
            show={!!nestedOverlayOptionElement}
            container={nestedOverlayOptionElement}
            target={nestedOverlayOptionElement}
            keepInViewport
          >
            {nestedOptions
              .filter(option => !activeOption || option.value !== activeOption.value)
              .map(option => (
                <OptionRow key={option.value} onClick={() => this.handleSelect(option.value)}>
                  {option.label}
                </OptionRow>
              ))}
          </StyledOverlay>
        )}
      </Root>
    );
  }
}
