/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpenseTeamSplitCell_expense$ref: FragmentReference;
export type ExpenseTeamSplitCell_expense = {|
  +id: string,
  +expenseTeamAllocations: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +percent: number,
        +team: {|
          +id: string,
          +name: string,
        |},
      |}
    |}>
  |},
  +$refType: ExpenseTeamSplitCell_expense$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ExpenseTeamSplitCell_expense",
  "type": "Expense",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "expenseTeamAllocations",
      "storageKey": null,
      "args": null,
      "concreteType": "ExpenseTeamAllocationRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ExpenseTeamAllocationRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ExpenseTeamAllocation",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "percent",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "team",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Team",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '023447a45ce965e1f3bb284e22cb91b9';
module.exports = node;
