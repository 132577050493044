/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

import type {
  createScheduleItemMutationVariables,
  createScheduleItemMutationResponse,
} from './__generated__/createScheduleItemMutation.graphql';

const mutation = graphql`
  mutation createScheduleItemMutation($input: AddItemToScheduleDayInput!) {
    addItemToScheduleDay(input: $input) {
      scheduleItemEdge {
        __typename
        node {
          id
          ...ScheduleListRow_scheduleItem
          ...SchedulesTimelineItem_item
        }
      }
    }
  }
`;

export default function createScheduleItem(
  scheduleDayId: string,
  args: {|
    title: string,
    startTime: string,
    endTime: ?string,
    allDay: boolean,
  |},
): Promise<createScheduleItemMutationResponse> {
  const variables: createScheduleItemMutationVariables = {
    input: {
      scheduleDayId,
      ...args,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: addRecordToConnection({
      parentId: scheduleDayId,
      edgeName: 'scheduleItemEdge',
      connections: [{ field: 'scheduleItems' }],
    }),
  });
}
