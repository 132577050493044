import alt from '../alt';

class UIActions {
  constructor() {
    this.generateActions('showWindow', 'hideWindow',
      'showErrorMessage', 'hideErrorMessage', 'showErrorPage', 'hideErrorPage');
  }
}

export default alt.createActions(UIActions);
