/**
 * @flow
 * @relayHash f2622a84174dfad54b97af7babee3aba
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DashboardMenuSavedViewRow_eventList$ref = any;
type DashboardMenuSavedViewRow_org$ref = any;
export type EventListShare = "PRIVATE" | "PUBLIC" | "SSO";
export type DashboardMenuSavedViewsQueryVariables = {|
  count?: ?number
|};
export type DashboardMenuSavedViewsQueryResponse = {|
  +org: {|
    +$fragmentRefs: DashboardMenuSavedViewRow_org$ref
  |},
  +me: {|
    +eventLists: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +shared: EventListShare,
          +$fragmentRefs: DashboardMenuSavedViewRow_eventList$ref,
        |}
      |}>
    |}
  |},
|};
export type DashboardMenuSavedViewsQuery = {|
  variables: DashboardMenuSavedViewsQueryVariables,
  response: DashboardMenuSavedViewsQueryResponse,
|};
*/


/*
query DashboardMenuSavedViewsQuery(
  $count: Int
) {
  org {
    ...DashboardMenuSavedViewRow_org
    id
  }
  me {
    eventLists(first: $count) {
      edges {
        node {
          id
          name
          shared
          ...DashboardMenuSavedViewRow_eventList
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}

fragment DashboardMenuSavedViewRow_org on Org {
  slug
  settings {
    subdomain
    id
  }
}

fragment DashboardMenuSavedViewRow_eventList on EventList {
  id
  name
  shared
  viewerCanUpdate
  publicResourceToken {
    token
    id
  }
  exportScheduler {
    frequency
    scheduledOn
    recipients {
      edges {
        node {
          id
          firstName
          lastName
          email
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shared",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DashboardMenuSavedViewsQuery",
  "id": null,
  "text": "query DashboardMenuSavedViewsQuery(\n  $count: Int\n) {\n  org {\n    ...DashboardMenuSavedViewRow_org\n    id\n  }\n  me {\n    eventLists(first: $count) {\n      edges {\n        node {\n          id\n          name\n          shared\n          ...DashboardMenuSavedViewRow_eventList\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n\nfragment DashboardMenuSavedViewRow_org on Org {\n  slug\n  settings {\n    subdomain\n    id\n  }\n}\n\nfragment DashboardMenuSavedViewRow_eventList on EventList {\n  id\n  name\n  shared\n  viewerCanUpdate\n  publicResourceToken {\n    token\n    id\n  }\n  exportScheduler {\n    frequency\n    scheduledOn\n    recipients {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          email\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": null,
        "direction": "forward",
        "path": [
          "me",
          "eventLists"
        ]
      }
    ]
  },
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardMenuSavedViewsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DashboardMenuSavedViewRow_org",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "eventLists",
            "name": "__DashboardMenuSavedViews_eventLists_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "EventListRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventListRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventList",
                    "plural": false,
                    "selections": [
                      v1,
                      v2,
                      v3,
                      {
                        "kind": "FragmentSpread",
                        "name": "DashboardMenuSavedViewRow_eventList",
                        "args": null
                      },
                      v4
                    ]
                  },
                  v5
                ]
              },
              v6
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardMenuSavedViewsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "subdomain",
                "args": null,
                "storageKey": null
              },
              v1
            ]
          },
          v1
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventLists",
            "storageKey": null,
            "args": v7,
            "concreteType": "EventListRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventListRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventList",
                    "plural": false,
                    "selections": [
                      v1,
                      v2,
                      v3,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "viewerCanUpdate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "publicResourceToken",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PublicResourceToken",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "token",
                            "args": null,
                            "storageKey": null
                          },
                          v1
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "exportScheduler",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EventListScheduledExportView",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "frequency",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "scheduledOn",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "recipients",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserRequiredConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserRequiredEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "plural": false,
                                    "selections": [
                                      v1,
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "firstName",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "lastName",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "email",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          v1
                        ]
                      },
                      v4
                    ]
                  },
                  v5
                ]
              },
              v6
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "eventLists",
            "args": v7,
            "handle": "connection",
            "key": "DashboardMenuSavedViews_eventLists",
            "filters": []
          },
          v1
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0ac7f799793518a30c684f867bce0af';
module.exports = node;
