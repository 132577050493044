/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefScheduleDay_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduleItemContainer_event$ref: FragmentReference;
export type ScheduleItemContainer_event = {|
  +$fragmentRefs: BriefScheduleDay_event$ref,
  +$refType: ScheduleItemContainer_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ScheduleItemContainer_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BriefScheduleDay_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '136b10a95f57dab3837f889d4082ae6c';
module.exports = node;
