/**
 * @flow
 * @relayHash 0a99281a8fccfff1b5c4f44ba65b95a4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VendorsContainer_event$ref = any;
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type VendorSort = "CITY" | "COMPANY_PHONE" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "FIRST_NAME" | "LAST_NAME" | "LINKEDIN" | "NAME" | "PHONE1" | "PHONE2" | "STATE" | "STREET" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "WEBSITE" | "ZIP";
export type VendorFilters = {
  query?: ?string,
  vendorIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?VendorSort,
  direction?: ?Direction,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type BriefVendorsQueryVariables = {|
  eventSlug: string,
  filters: VendorFilters,
|};
export type BriefVendorsQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: VendorsContainer_event$ref
  |}
|};
export type BriefVendorsQuery = {|
  variables: BriefVendorsQueryVariables,
  response: BriefVendorsQueryResponse,
|};
*/


/*
query BriefVendorsQuery(
  $eventSlug: String!
  $filters: VendorFilters!
) {
  event(slug: $eventSlug) {
    ...VendorsContainer_event
    id
  }
}

fragment VendorsContainer_event on Event {
  slug
  allVendors: vendors(filters: $filters) {
    edges {
      node {
        id
        firstName
        lastName
        email
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "VendorFilters!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BriefVendorsQuery",
  "id": null,
  "text": "query BriefVendorsQuery(\n  $eventSlug: String!\n  $filters: VendorFilters!\n) {\n  event(slug: $eventSlug) {\n    ...VendorsContainer_event\n    id\n  }\n}\n\nfragment VendorsContainer_event on Event {\n  slug\n  allVendors: vendors(filters: $filters) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        email\n        name\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BriefVendorsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "VendorsContainer_event",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BriefVendorsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "allVendors",
            "name": "vendors",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filters",
                "variableName": "filters",
                "type": "VendorFilters"
              }
            ],
            "concreteType": "VendorRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VendorRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Vendor",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c32a2fe139abfdedcdefb87561ae349';
module.exports = node;
