/* @flow */
import React from 'react';
import styled from 'styled-components';

import { type DateRangeParam, type NumberRangeParam } from 'utils/routing/parseTypedQueryString';

import Apply from 'images/apply.svg';
import Button from 'components/budget/Button';
import Dropdown from 'components/budget/Dropdown';
import type { OptionGroup } from 'components/material/SelectField';

import type { CustomGroupFieldSettingsType } from '../BudgetReportingPage';
import type { ParsedBudgetReportingFilters } from '../lib/parseBudgetReportingFilters';
import groupOptions, { rawGroupingOptions } from '../lib/reportGroupingOptions';

const GroupFiltersWrapper = styled.div`
  display: flex;
  flex: 1 0;
  justify-content: flex-end;
  margin-bottom: 10px;
  padding: 0 45px;
  white-space: nowrap;
  @media (max-width: 1340px) {
    padding: 0 20px;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #4a5665;

  &:nth-child(2) {
    margin-left: 10px;
  }

  > div {
    display: inline-block;
    width: 152px;
    margin-left: 13px;

    @media (max-width: 700px) {
      width: 100px;
    }

    @media (max-width: 500px) {
      width: 80px;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  svg > path {
    fill: #ffffff;
  }
`;

export default class GroupFilters extends React.PureComponent<
  {
    savedViewId: ?string,
    filters: ParsedBudgetReportingFilters,
    orgSettings: CustomGroupFieldSettingsType,
    customFieldOptions: $ReadOnlyArray<OptionGroup>,
    onFilterChange: (filterParams: {
      [string]: ?(string | ?$ReadOnlyArray<string> | boolean | NumberRangeParam | DateRangeParam),
    }) => void,
  },
  {
    savedViewId: ?string,
    group1: string,
    group2: ?string,
  },
> {
  static getDerivedStateFromProps(
    nextProps: $PropertyType<GroupFilters, 'props'>,
    prevState: $PropertyType<GroupFilters, 'state'>,
  ) {
    if (nextProps.savedViewId !== prevState.savedViewId) {
      return {
        group1: nextProps.filters.group1 || nextProps.filters.customGroup1,
        group2: nextProps.filters.group2 || nextProps.filters.customGroup2,
        savedViewId: nextProps.savedViewId,
      };
    }

    return null;
  }

  state = {
    savedViewId: this.props.savedViewId,
    group1: this.props.filters.group1 || this.props.filters.customGroup1 || 'TEAM',
    group2: this.props.filters.group2 || this.props.filters.customGroup2,
  };

  handleChangeGroup1 = (group1: ?string) => {
    this.setState(prevState => {
      if (!group1) return null;

      // Clear Group2
      if (
        prevState.group2 === group1 ||
        (group1 === 'EVENT' && prevState.group2 !== 'BUDGET_CATEGORY')
      ) {
        return { group1, group2: null };
      }
      return { ...prevState, group1 };
    });
  };

  handleChangeGroup2 = (group2: ?string) => this.setState({ group2 });

  handleButtonClick = () => {
    const defaultGroupingOptionsKeys = Object.keys(rawGroupingOptions);

    this.props.onFilterChange({
      ...(defaultGroupingOptionsKeys.includes(this.state.group1)
        ? { group1: this.state.group1, customGroup1: null }
        : { group1: null, customGroup1: this.state.group1 }),
      ...(defaultGroupingOptionsKeys.includes(this.state.group2)
        ? { group2: this.state.group2, customGroup2: null }
        : { group2: null, customGroup2: this.state.group2 }),
    });
  };

  render() {
    const { filters, orgSettings } = this.props;
    return (
      <GroupFiltersWrapper>
        <DropdownWrapper>
          Group by
          <Dropdown
            value={this.state.group1}
            options={groupOptions(orgSettings, this.props.customFieldOptions)}
            onChange={this.handleChangeGroup1}
          />
        </DropdownWrapper>

        <DropdownWrapper>
          and
          <Dropdown
            value={this.state.group2}
            options={groupOptions(orgSettings, this.props.customFieldOptions, this.state.group1)}
            onChange={this.handleChangeGroup2}
            clearable
          />
        </DropdownWrapper>
        <StyledButton
          onClick={this.handleButtonClick}
          disabled={
            (filters.group1 === this.state.group1 || filters.customGroup1 === this.state.group1) &&
            (filters.group2 === this.state.group2 || filters.customGroup2 === this.state.group2) &&
            (this.state.group2 != null || (filters.group2 == null && filters.customGroup2 == null))
          }
          svgIcon={Apply}
          radius={15}
        >
          Apply
        </StyledButton>
      </GroupFiltersWrapper>
    );
  }
}
