/* @flow */
import React from 'react';
import styled from 'styled-components';
import Clipboard from 'clipboard';

import Button from 'components/budget/Button';
import TextField from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';

const StyledWindow = styled(Window)`
  margin-top: 15px;
  margin-right: 115px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    align-self: flex-end;
  }
`;

const StyledTextField = styled(TextField)`
  margin: 0;

  textarea {
    padding: 5px 12px;
    border: 1px solid #c6bed7;
    border-radius: 4px;
    font-size: 13px;
    background: #fff;
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  min-width: 84px;
`;

export default class BriefEmbedWindow extends React.Component<
  {
    shareLink: string,
    onHide: () => void,
  },
  { copied: boolean },
> {
  state = { copied: false };

  copyRef = null;

  clipboard = null;

  componentDidMount() {
    this.clipboard = new Clipboard(this.copyRef, {
      text: this.embedCode,
    }).on('success', () => {
      this.setState({ copied: true });
    });
  }

  componentWillUnmount() {
    if (this.clipboard) this.clipboard.destroy();
  }

  handleMouseLeave = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 300);
  };

  embedCode = () => {
    return `<div style="position: relative;padding-top: 56.25%;overflow: hidden;">\
<iframe style="position: absolute;top: 0;left: 0; width: 100%;height: 100%;border: 0;" src="${this.props.shareLink}"></iframe>\
</div>`;
  };

  render() {
    const onHide = this.props.onHide;

    return (
      <StyledWindow onHide={onHide} size="medium">
        <WindowHeader>
          <WindowClose onClick={onHide} />

          <WindowTitle>Embed Form</WindowTitle>
        </WindowHeader>

        <WindowContent>
          <Content>
            <StyledTextField value={this.embedCode()} readOnly multiline />

            <Tooltip
              innerRef={el => {
                this.copyRef = el;
              }}
              label={this.state.copied ? 'Copied' : ''}
            >
              <StyledButton onMouseLeave={this.handleMouseLeave}>Copy</StyledButton>
            </Tooltip>
          </Content>
        </WindowContent>
      </StyledWindow>
    );
  }
}
