/* @flow */
import React from 'react';
import styled from 'styled-components';

import TextInput from 'components/material/TextInput';
import CheckBox from '../form/CheckBox';

const Container = styled.div`
  margin-top: 5px;
`;

const AddLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  color: #3ba9da;
  &:hover {
    color: #2483ad;
  }
  i {
    margin-right: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 1;
  }
`;

const InputContainer = styled.div`
  width: 50%;
  margin: -6px 0;
  display: flex;
  & > :first-child {
    margin-right: 8px;
  }
`;

export default class EditableDeclineReasonsAdd extends React.PureComponent<
  { onCreate: (name: string) => void },
  { adding: boolean },
> {
  state = {
    adding: false,
  };

  handleStartAdding = () => {
    this.setState({ adding: true });
  };

  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ adding: false });

    const value = e.currentTarget.value.trim();

    if (value) {
      this.props.onCreate(value);
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      this.setState({ adding: false });
    } else if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  render() {
    return (
      <Container>
        {this.state.adding ? (
          <InputContainer>
            <CheckBox compact checked />
            <TextInput autoFocus onBlur={this.handleBlur} onKeyDown={this.handleKeyDown} />
          </InputContainer>
        ) : (
          <AddLink onClick={this.handleStartAdding}>
            <i>+</i> Add Another Reason
          </AddLink>
        )}
      </Container>
    );
  }
}
