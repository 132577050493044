/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const TextCell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  .fa-pencil {
    color: ${props => props.theme.rowSecondaryTextColor};
    padding-left: 5px;
    vertical-align: top;
    visibility: hidden;
  }
  &:hover {
    .fa-pencil {
      visibility: visible;
    }
  }
`;

export default class EditableCell extends React.Component<{ children: React.Node }> {
  render() {
    const { children, ...rest } = this.props;

    return (
      <TextCell {...rest}>
        {children}
        <i className="fa fa-pencil" />
      </TextCell>
    );
  }
}
