/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import { type NumberRangeParam } from 'utils/routing/parseTypedQueryString';

import NumberField from 'components/material/NumberField';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: 5px;
  padding: 0 0 0 10px;
`;

const TextWrapper = styled.div`
  position: relative;
  display: flex;
`;

const StyledNumberField = styled(NumberField)`
  flex: 1 1 auto;
  margin-top: 10px;
  padding: 0;
  &:first-child {
    margin-right: 20px;
  }
`;

export default class RangeFilterBody extends React.Component<{
  range: NumberRangeParam,
  onMinBlur: (e: SyntheticEvent<HTMLInputElement>, ?number) => void,
  onMaxBlur: (e: SyntheticEvent<HTMLInputElement>, ?number) => void,
  minPlaceholder?: string,
  maxPlaceholder?: string,
  className?: string,
  autoFocus?: boolean,
  error?: boolean,
}> {
  minField: ?HTMLInputElement;

  componentDidMount() {
    if (this.minField && this.props.autoFocus) {
      this.minField.focus();
    }
  }

  render() {
    const range = this.props.range;
    return (
      <Container className={this.props.className}>
        <TextWrapper>
          <StyledNumberField
            key={`min:${range.min == null ? '' : range.min}`}
            placeholder={this.props.minPlaceholder || 'Min'}
            defaultValue={range.min === 0 ? null : range.min}
            onBlur={this.props.onMinBlur}
            error={this.props.error}
            inputRef={ref => {
              this.minField = ref;
            }}
          />
          <StyledNumberField
            key={`max:${range.max == null ? '' : range.max}`}
            placeholder={this.props.maxPlaceholder || 'Max'}
            defaultValue={range.max == null ? null : range.max}
            onBlur={this.props.onMaxBlur}
            error={this.props.error}
          />
        </TextWrapper>
      </Container>
    );
  }
}
