/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import CheckBox from 'components/material/CheckBox';

const GroupCheckBox = styled(CheckBox)`
  padding: 7px 0 5px 0;
  font-size: 14px;
  line-height: 1;
  color: ${props => props.theme.oxfordBlue};
  > div:first-of-type {
    color: ${props => (props.checked ? props.theme.highlightColor : '#cdd1d4')};
  }
  &:last-of-type {
    margin-bottom: 1px;
  }
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.infoMenuColor};
`;

const Wrapper = styled.div`
  margin: 10px 20px 0 20px;
  padding-left: 10px;
  border-left: 5px solid ${props => props.borderColor};
`;

const Row = styled.div`
  padding-left: 10px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: #828b93;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.hoverRowColor};
  }
`;

const RowContent = styled.div`
  display: flex;
`;

const ChildRow = styled.div`
  display: flex;
  ${props =>
    props.isExpanded
      ? css`
          display: flex;
          flex-direction: column;
        `
      : css`
          display: none;
        `};
`;
export default class ColumnFiltersGroup extends React.Component<
  {
    label: string,
    children: React.Node,
    checked: boolean,
    handleGroupFiltersToggle: (id: string | number) => void,
    id: number | string,
    expanded?: boolean,
    indeterminate: boolean,
    borderColor: string,
    forceExpanded?: boolean,
  },
  {
    isExpanded: boolean,
  },
> {
  state = {
    isExpanded: this.props.expanded || false,
  };

  handleToggleExpand = () => {
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  handleGroupFiltersCheckBoxToggle = (value: boolean, e: SyntheticMouseEvent<HTMLInputElement>) => {
    if (this.state.isExpanded) {
      e.stopPropagation();
    }
    this.props.handleGroupFiltersToggle(this.props.id);
  };

  render() {
    return (
      <Wrapper borderColor={this.props.borderColor}>
        <Row onClick={this.handleToggleExpand}>
          <RowContent>
            <GroupCheckBox
              label={this.props.label}
              checked={this.props.checked}
              onChange={this.handleGroupFiltersCheckBoxToggle}
              indeterminate={this.props.indeterminate}
            />
          </RowContent>
        </Row>
        <ChildRow isExpanded={this.props.forceExpanded || this.state.isExpanded}>
          {this.props.children}
        </ChildRow>
      </Wrapper>
    );
  }
}
