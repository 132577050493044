/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';

import publicRuleNames from 'config/publicRuleNames';

import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';

import { getDefaultFilters, suggestedFields } from 'components/AllEvents/eventsTableColumnSettings';
import type { ParsedAllEventsFilters } from 'components/AllEvents/lib/parseAllEventsFilters';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type { ColumnGroupConfiguration } from 'components/material/table';
import AllEventsCalendarView from 'views/Main/Dashboard/AllEvents/AllEventsCalendar/AllEventsCalendarView';

import { type SharedEventListType } from '.';
import AllEventsListShareHeaderBar from './AllEventsListShareHeaderBar';

import type { AllEventsCalendarSharePageContainerQueryResponse } from './__generated__/AllEventsCalendarSharePageContainerQuery.graphql';
import { type AllEventsShareContainer_org } from './__generated__/AllEventsShareContainer_org.graphql';

const query = graphql`
  query AllEventsCalendarSharePageContainerQuery(
    $isShared: Boolean!
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
    $filters: EventFilters!
  ) {
    ... @include(if: $isShared) {
      publicRule(publicRuleName: $publicRuleName, resourceToken: $resourceToken, orgSlug: $orgSlug)
      eventList(resourceToken: $resourceToken) {
        events(filters: $filters) {
          edges {
            node {
              ...AllEventsCalendarView_events
            }
          }
        }
      }
    }
    ... @skip(if: $isShared) {
      me {
        events(filters: $filters) {
          edges {
            node {
              ...AllEventsCalendarView_events
            }
          }
        }
      }
    }
  }
`;

const AllEventsSharedCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  overflow: auto;
`;
const StyledAllEventsCalendarView = styled(AllEventsCalendarView)`
  margin: 0;
  border-radius: 0;
`;

export default class AllEventsCalendarSharePageContainer extends React.Component<
  {
    eventList: SharedEventListType,
    org: AllEventsShareContainer_org,
    userEmail: ?string,
    eventFields: $ReadOnlyArray<FieldType>,
    groups: ColumnGroupConfiguration,
    filters: ParsedAllEventsFilters,
    history: RouterHistory,
    match: Match,
    pathPrefix: string,
  },
  { eventsCount: number },
> {
  state = { eventsCount: 0 };

  filtered = () => Object.values(this.props.filters).some(val => val != null);

  setEventsCount = (eventsCount: number) => {
    if (this.state.eventsCount !== eventsCount) {
      this.setState({ eventsCount });
    }
  };

  renderCalendar = (month: string, week: ?string) => (
    response?: AllEventsCalendarSharePageContainerQueryResponse,
  ) => {
    const eventListInterface = response && (response.eventList || response.me);

    if (eventListInterface) {
      this.setEventsCount(eventListInterface.events.edges.length);
    }

    return (
      <StyledAllEventsCalendarView
        linkEventNameToBrief={this.props.eventList.linkEventNameToBrief}
        events={eventListInterface ? eventListInterface.events.edges.map(({ node }) => node) : null}
        month={month}
        week={week}
        history={this.props.history}
      />
    );
  };

  render() {
    const {
      filters,
      history,
      match,
      org,
      eventList,
      userEmail,
      groups,
      eventFields,
      pathPrefix,
    } = this.props;
    const {
      search,
      sort,
      month: omittedMonth,
      week: omittedWeek,
      date,
      requestedDate,
      updatedAt,
      createdAt,
      ...otherFilters
    } = filters;
    const defaultFilters = getDefaultFilters(otherFilters);
    const month =
      filters.month != null ? filters.month.format() : moment().startOf('month').format();
    const week = filters.week != null ? filters.week.format() : null;
    const afterDate = moment(week || month)
      .subtract(week ? 1 : 6, 'days')
      .format();
    const beforeDate = moment(week || month)
      .endOf(week ? 'week' : 'month')
      .add(week ? 1 : 6, 'days')
      .format();
    const customFilters = eventFields
      .filter(
        option =>
          (option.fieldName == null || suggestedFields.includes(option.fieldName)) &&
          otherFilters[option.id] != null,
      )
      .map(option => getCustomFieldVariable(otherFilters[option.id], option));

    const isPreviewing = !match.params.resourceToken;
    const variables = {
      isShared: !isPreviewing,
      publicRuleName: publicRuleNames.PUBLIC_EVENT_LIST,
      resourceToken: match.params.resourceToken || '',
      orgSlug: match.params.orgName,
      count: 500,
      filters: {
        search: isPreviewing ? search : undefined,
        sort: 'START_DATE',
        direction: 'ASC',
        ...defaultFilters,
        afterDate,
        beforeDate,
        createdAt,
        updatedAt,
        requestedDate,
        customFilters,
      },
    };

    const csvExportVariables = variables.filters;

    return (
      <>
        <AllEventsListShareHeaderBar
          userEmail={userEmail}
          history={history}
          filters={filters}
          org={org}
          eventList={eventList}
          groups={groups}
          eventFields={eventFields}
          viewMode="calendar"
          csvExportVariables={csvExportVariables}
          eventsCount={this.state.eventsCount}
          pathPrefix={pathPrefix}
          isPreviewing={isPreviewing}
        />
        <AllEventsSharedCalendarContainer>
          <DefaultQueryRenderer
            public={!!match.params.resourceToken}
            query={query}
            variables={variables}
            renderLoading={this.renderCalendar(month, week)}
            renderSuccess={this.renderCalendar(month, week)}
          />
        </AllEventsSharedCalendarContainer>
      </>
    );
  }
}
