/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationFormFieldLogic_contactFormFields$ref: FragmentReference;
export type RegistrationFormFieldLogic_contactFormFields = $ReadOnlyArray<{|
  +id: string,
  +label: string,
  +order: number,
  +fieldName: ?string,
  +customField: ?{|
    +id: string,
    +label: string,
    +fieldName: ?string,
    +customizableType: CustomizableType,
    +order: number,
    +kind: CustomFieldKindType,
    +options: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +editable: boolean,
        |}
      |}>
    |},
  |},
  +$refType: RegistrationFormFieldLogic_contactFormFields$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RegistrationFormFieldLogic_contactFormFields",
  "type": "ContactFormField",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    v2,
    v3,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customField",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldType",
      "plural": false,
      "selections": [
        v0,
        v1,
        v3,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "customizableType",
          "args": null,
          "storageKey": null
        },
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "kind",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "options",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldOptionTypeRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldOptionTypeRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldOptionType",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "editable",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c1aaa4fcd0fc7db8ebac5b4198539a90';
module.exports = node;
