/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import type { EventVenueState_event } from './__generated__/EventVenueState_event.graphql';

const EventVenueState = (props: { event: EventVenueState_event }) => {
  const primaryLocation = props.event.primaryLocation;
  return primaryLocation ? primaryLocation.state : null;
};

export default createFragmentContainer(
  EventVenueState,
  graphql`
    fragment EventVenueState_event on Event {
      primaryLocation {
        state
      }
    }
  `,
);
