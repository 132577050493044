/* @flow */
import React from 'react';
import styled from 'styled-components';

import TagListItem from './TagListItem';

export const TagContent = styled.div`
  max-height: 95px;
  overflow: auto;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${props => props.theme.labelColor};
`;

const Container = styled.div`
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
  &.focused:not(.has-error) {
    ${TagContent} {
      border-color: ${props => props.theme.primaryActionColor};
    }
    ${Label} {
      color: ${props => props.theme.primaryActionColor};
    }
  }
  &.has-error {
    ${TagContent} {
      border-color: ${props => props.theme.negativeActionColor};
    }
    ${Label} {
      color: ${props => props.theme.negativeActionColor};
    }
  }
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagListInput = styled.input`
  flex: 1;
  margin: 5px 0;
  font-size: 14px;
  outline: none;
  &::placeholder {
    color: rgba(74, 86, 101, 0.54);
    font-size: 14px;
    font-weight: normal;
  }
`;

const Error = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  font-size: 12px;
  color: ${props => props.theme.negativeActionColor};
`;

const ErrorHelpText = styled.div`
  margin-left: 15px;
  color: ${props => props.theme.labelColor};
`;
export default class TagMultiselect extends React.Component<
  {
    onUpdate: (tag: string, index: number) => void,
    onAdd: (tag: $ReadOnlyArray<string>) => void,
    onRemove: (index: number) => void,
    errors: $ReadOnlyArray<string>,
    tags: $ReadOnlyArray<string>,
    placeholder?: string,
    tagLimit?: number,
    label: string,
    autofocus?: boolean,
    className?: string,
  },
  {
    value: string,
    isInputFocused: boolean,
    selectedTagIndex: ?number,
  },
> {
  state = {
    value: '',
    isInputFocused: false,
    selectedTagIndex: null,
  };

  containerRef = React.createRef();

  inputRef = React.createRef();

  componentDidMount() {
    if (!this.containerRef || !this.containerRef.current) {
      return;
    }
    this.containerRef.current.addEventListener('keydown', this.handleTagBackspace);
    if (this.props.autofocus) this.forceInputFocus();
  }

  componentWillUnmount() {
    if (!this.containerRef || !this.containerRef.current) {
      return;
    }
    this.containerRef.current.removeEventListener('keydown', this.handleTagBackspace);
  }

  handleTagBackspace = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    // keyCode 46 -> Delete, keyCode 8 -> Backspace,
    if (this.state.selectedTagIndex != null && (event.keyCode === 8 || event.keyCode === 46)) {
      this.props.onRemove(this.state.selectedTagIndex);
      this.forceInputFocus();
    }
  };

  forceInputFocus = () => {
    if (this.inputRef && this.inputRef.current) this.inputRef.current.focus();
  };

  handleClickToRemove = (index: number) => {
    const { tags, onRemove } = this.props;
    onRemove(index);
    if (tags.length === 1) this.forceInputFocus();
  };

  handleInputChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value });
    if (this.state.selectedTagIndex !== null) {
      this.setState({ selectedTagIndex: null });
    }
  };

  handleInputFocus = () => {
    this.setState({ isInputFocused: true, selectedTagIndex: null });
  };

  handleInputUnFocus = () => {
    this.setState({ isInputFocused: false, selectedTagIndex: null });
  };

  handleInputBlur = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.trim();
    if (value) {
      this.props.onAdd([value]);
      this.setState({ value: '' });
    }
    this.handleInputUnFocus();
  };

  onKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.trim();
    // keyCode 46 -> Delete, keyCode 8 -> Backspace,
    if ((event.keyCode === 8 || event.keyCode === 46) && !value && this.props.tags.length > 0) {
      if (this.state.selectedTagIndex == null) {
        this.setState({ selectedTagIndex: this.props.tags.length - 1 });
      } else {
        this.setState({ selectedTagIndex: null });
      }
    }
    // keyCode 46 -> Enter, keyCode 8 -> Space, keyCode 188 -> Comma,
    if (event.keyCode === 13 || event.keyCode === 32 || event.keyCode === 188) {
      event.preventDefault();
      if (value) {
        this.props.onAdd([value]);
        this.setState({ value: '' });
      }
    }
  };

  handlePaste = async (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    try {
      const pastedText = await navigator.clipboard.readText();
      const tagArray = pastedText.split(/[ ,\n]+/);
      if (tagArray.length > 0) {
        this.props.onAdd(tagArray);
      }
      this.setState({ value: '' });
      if (this.state.selectedTagIndex !== null) {
        this.setState({ selectedTagIndex: null });
      }
    } catch (error) {
      console.error('Failed to read clipboard data:', error);
    }
  };

  render() {
    const { tags, className, placeholder, label, errors, tagLimit } = this.props;
    const { value, isInputFocused, selectedTagIndex } = this.state;
    const hasError = errors.some(error => error !== '');
    const errorNumber = errors.filter(error => error !== '').length;
    const showInput = tagLimit == null || tags.length < tagLimit;
    const containerClass = [className, hasError && 'has-error', isInputFocused && 'focused']
      .filter(Boolean)
      .join(' ');
    return (
      <Container ref={this.containerRef} onClick={this.forceInputFocus} className={containerClass}>
        <Label>{label}</Label>
        <TagContent>
          <TagList>
            {tags != null &&
              tags.map((tag, index) => (
                <TagListItem
                  key={index} // eslint-disable-line react/no-array-index-key
                  value={tag}
                  index={index}
                  error={errors[index] === 'error'}
                  highlighted={selectedTagIndex === index}
                  onRemove={this.handleClickToRemove}
                  onUpdate={this.props.onUpdate}
                  handleInputFocus={this.handleInputFocus}
                  handleInputUnFocus={this.handleInputUnFocus}
                />
              ))}
            {showInput && (
              <TagListInput
                value={value}
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                onBlur={this.handleInputBlur}
                onKeyDown={this.onKeyDown}
                onPaste={this.handlePaste}
                placeholder={tags.length === 0 ? placeholder : ''}
                ref={this.inputRef}
              />
            )}
          </TagList>
        </TagContent>
        {hasError && (
          <Error focused={isInputFocused}>
            {`${errorNumber} ${errorNumber > 1 ? 'Errors' : 'Error'}`}
            <ErrorHelpText>Remove all items with errors</ErrorHelpText>
          </Error>
        )}
      </Container>
    );
  }
}
