/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  margin: 3px 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  color: ${props => props.theme.infoMenuHoverColor};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  ${props =>
    props.showBackground &&
    css`
      background-color: rgba(59, 169, 218, 0.07);
    `};
  ${props =>
    props.disabled &&
    css`
      cursor: default;
      ${Header} {
        color: #b4b9bf;
      }
    `};
  padding: ${props => (props.showBackground ? '7px 10px' : '9px 10px')};
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-left: 16px;
  color: ${props => props.theme.primaryActionTextColor};
  background: ${props => props.theme.primaryActionColor};
  border-radius: 50%;
  font-size: 10px;
  font-weight: 500;
`;

const ClearIcon = styled.i`
  color: ${props => props.theme.secondaryActionColor};
  font-size: 11px;
  &:hover {
    color: ${props => props.theme.negativeActionColor};
  }
`;

const Row = styled.div`
  display: flex;
`;

export default class FilterItemHeader extends React.Component<
  {
    label: string,
    count: number,
    expanded?: boolean,
    children: React.Node,
    onClear: () => void,
    onClose?: () => void,
    disabled?: boolean,
  },
  {
    isExpanded: boolean,
  },
> {
  state = {
    isExpanded: this.props.expanded || false,
  };

  handleToggleExpand = () => {
    if (!this.props.disabled) {
      this.setState(
        state => ({ isExpanded: !state.isExpanded }),
        () => {
          if (!this.state.isExpanded && this.props.onClose) {
            this.props.onClose();
          }
        },
      );
    }
  };

  handleFilterClear = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    this.props.onClear();
  };

  render() {
    return (
      <Container>
        <Wrapper
          onClick={this.handleToggleExpand}
          showBackground={this.props.count > 0}
          disabled={this.props.disabled}
        >
          <Header>
            {this.props.label}
            {this.props.count > 0 && <Circle>{this.props.count}</Circle>}
          </Header>
          {this.props.count > 0 && (
            <ClearIcon onClick={this.handleFilterClear} className="fa fa-times" />
          )}
        </Wrapper>
        {this.state.isExpanded && <Row>{this.props.children}</Row>}
      </Container>
    );
  }
}
