/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';

const mutation = graphql`
  mutation removeTaskContactMutation($input: RemoveDeliverableContactInput!) {
    removeDeliverableContact(input: $input) {
      removedContactId
    }
  }
`;

export default function removeTaskContact(taskId: string, contactId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableId: taskId,
        contactId,
      },
    },
    optimisticResponse: {
      removeDeliverableContact: {
        removedContactId: contactId,
      },
    },
    updater: removeRecordFromConnection({
      parentId: taskId,
      connections: [{ field: 'contacts' }],
      deletedIdField: 'removedContactId',
    }),
  });
}
