/* @flow */
import * as React from 'react';
import {
  type ConnectDragPreview,
  type ConnectDragSource,
  type DragSourceConnector,
  type DragSourceMonitor,
  DragSource,
} from 'react-dnd';

import type {
  PageComponentKindType,
  ReorderedPageComponentConfigType,
} from '../../../pageComponentsConfig';

export type DraggableComponentConfigType = {
  icon: React.Node,
  label: string,
  kind: PageComponentKindType,
};

class DraggableComponent extends React.PureComponent<
  {
    children: React.Node,
    connectDragSource?: ConnectDragSource,
    connectDragPreview?: ConnectDragPreview,
    /* eslint-disable react/no-unused-prop-types */
    // Used in Hover handler
    componentConfig: DraggableComponentConfigType,
    onMoveEnd: (reorderedPageComponentConfig?: ReorderedPageComponentConfigType) => void,
    onBeginDrag: (draggedNewComponentKind: PageComponentKindType) => void,
  },
  { isSetMovingSection: boolean },
> {
  render() {
    const { connectDragSource, connectDragPreview, children } = this.props;

    if (!connectDragSource || !connectDragPreview) return null;

    return <div>{connectDragSource(<div>{children}</div>)}</div>;
  }
}

const ConnectedDraggableComponent = DragSource(
  'REGISTRATION_PAGE_COMPONENT',
  {
    beginDrag(props: $PropertyType<DraggableComponent, 'props'>) {
      props.onBeginDrag(props.componentConfig.kind);
      return { ...props.componentConfig };
    },
    endDrag(props: $PropertyType<DraggableComponent, 'props'>, monitor: DragSourceMonitor) {
      const dropAreaConfig = monitor.getDropResult();
      props.onMoveEnd(
        dropAreaConfig && {
          parentId: dropAreaConfig.parentId,
          order: dropAreaConfig.order,
          kind: props.componentConfig.kind,
        },
      );
    },
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
)(DraggableComponent);

export default ConnectedDraggableComponent;
