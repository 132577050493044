const determineCustomFieldMinWidth = kind => {
  switch (kind) {
    case 'LINK':
    case 'USER_SELECT':
    case 'USER_MULTISELECT':
      return 215;
    case 'NUMBER':
    case 'CURRENCY':
      return 120;
    default:
      return undefined;
  }
};

export default determineCustomFieldMinWidth;
