import styled from 'styled-components';

export const SettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  max-width: 1000px;
`;

export const SettingSection = styled.div`
  font-size: 13px;
  margin-bottom: 50px;
`;

export const SettingHeader = styled.div`
  padding-bottom: 7px;
  border-bottom: 1px solid ${props => props.theme.borderSeparatorColor};
  color: #929292;
  font-size: 13px;
  font-weight: 500;
`;

export const SettingContent = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const SettingItemLabel = styled.div`
  flex: 0 0 245px;
  color: ${props => props.theme.infoTextColor};
  font-size: 13px;
`;

export const SettingItemDetails = styled.div`
  flex: 1 1 auto;
  display: ${props => (props.inline ? 'flex' : 'block')};
  align-items: center;
`;

export const SettingsItemInputLabel = styled.label`
  margin-left: -20px;
`;

export const SettingsItemInput = styled.input`
  flex-shrink: 0;
  margin-right: 8px;
`;

export const SettingItemExplanation = styled.p`
  color: ${props => props.theme.infoTextColor};
  font-size: 13px;
  font-style: italic;
  margin: 3px 0;
  a {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SettingSelectFieldContainer = styled.div`
  min-width: 120px;
  max-width: 300px;
  margin-right: 20px;
`;
