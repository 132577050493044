/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import { type PaymentFooterCellPropsType } from './index';

import { type PaymentAmountFooterCell_event } from './__generated__/PaymentAmountFooterCell_event.graphql';
import { type PaymentAmountFooterCell_org } from './__generated__/PaymentAmountFooterCell_org.graphql';

const PaymentAmountFooterCell = ({
  event,
  org,
}: PaymentFooterCellPropsType<PaymentAmountFooterCell_event, PaymentAmountFooterCell_org>) =>
  formatCost(event.totalActualAmount, org.settings.currency);

export default createFragmentContainer(
  PaymentAmountFooterCell,
  graphql`
    fragment PaymentAmountFooterCell_event on Event {
      totalActualAmount
    }

    fragment PaymentAmountFooterCell_org on Org {
      settings {
        currency
      }
    }
  `,
);
