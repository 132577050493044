/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateBudgetReportingViewMutationResponse,
  updateBudgetReportingViewMutationVariables,
} from './__generated__/removeBudgetReportingViewMutation.graphql';

const mutation = graphql`
  mutation updateBudgetReportingViewMutation($input: UpdateBudgetReportingViewInput!) {
    updateBudgetReportingView(input: $input) {
      budgetReportingView {
        id
        name
        group1
        group2
        customGroup1 {
          id
        }
        customGroup2 {
          id
        }
        eventIds
        eventName
        eventDate
        eventStatuses
        syncStatuses
        sort
        direction
        exportScheduler {
          frequency
          scheduledOn
          includePayments
          includeExpenses
          recipients {
            edges {
              node {
                id
                firstName
                lastName
                avatar
                email
              }
            }
          }
        }
        customSavedNumberFilters {
          edges {
            node {
              id
              minValue
              maxValue
              customField {
                id
              }
            }
          }
        }
        customSavedCurrencyFilters {
          edges {
            node {
              id
              minValue
              maxValue
              customField {
                id
              }
            }
          }
        }
        customSavedDateFilters {
          edges {
            node {
              id
              minValue
              maxValue
              customField {
                id
              }
            }
          }
        }
        customSavedBooleanFilters {
          edges {
            node {
              id
              value
              customField {
                id
              }
            }
          }
        }
        customSavedUserMultiselectFilters {
          edges {
            node {
              id
              customField {
                id
              }
              options {
                edges {
                  node {
                    user {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        customSavedMultiselectFilters {
          edges {
            node {
              id
              customField {
                id
              }
              options {
                edges {
                  node {
                    option {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        teams {
          edges {
            node {
              id
            }
          }
        }
        leads {
          edges {
            node {
              id
            }
          }
        }
        staffers {
          edges {
            node {
              id
            }
          }
        }
        budgetCategories {
          edges {
            node {
              id
            }
          }
        }
        venueNames
        cities
        states
        countries
        opportunitiesNumber {
          min
          max
        }
        opportunitiesAmount {
          min
          max
        }
        contactsCount {
          min
          max
        }
        registeredContactsTotal {
          min
          max
        }
        attendedContactsTotal {
          min
          max
        }
        registrationFormStatuses
        eventFormats
        creators {
          edges {
            node {
              id
            }
          }
        }
        createdAt
        updaters {
          edges {
            node {
              id
            }
          }
        }
        updatedAt
        requestedDate
        requestStatuses
        userRequesters {
          edges {
            node {
              id
            }
          }
        }
        contactRequesters {
          edges {
            node {
              id
            }
          }
        }
        eventRequestForms {
          edges {
            node {
              id
            }
          }
        }
        eventRequestReviewers {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export default function updateBudgetReportingView(
  input: $PropertyType<updateBudgetReportingViewMutationVariables, 'input'>,
): Promise<updateBudgetReportingViewMutationResponse> {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  });
}
