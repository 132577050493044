/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import OrgUserSearch from 'components/InviteWindow/OrgUserSearch';
import UserSelectionRow from 'components/InviteWindow/SelectionRow/UserSelectionRow';
import SelectField from 'components/material/SelectField';
import { type User } from 'components/UserSelect';
import type {
  FrequencyType as Frequency,
  ScheduledOnType as ScheduledOn,
} from 'views/Main/Reporting/BudgetReporting/BudgetReportingHeader/SavedViews/EditViewWindow/__generated__/EditViewWindowQuery.graphql';

export type FrequencyType = Frequency;

export type ScheduledOnType = ScheduledOn;

const FREQUENCIES = [
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Never',
    value: 'never',
  },
];

export const MONTHLY_SCHEDULED_TIMES = [
  {
    label: 'Beginning of the month',
    value: 'start_of_month',
  },
  {
    label: 'End of the month',
    value: 'end_of_month',
  },
];

export const WEEKLY_SCHEDULED_TIMES = [
  {
    label: 'Sunday',
    value: 'Sunday',
  },
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
  },
];

export type SchedulerStateType = {|
  +frequency: FrequencyType,
  +scheduledOn: ScheduledOnType,
  +recipients: $ReadOnlyArray<User>,
|};

const ListContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 18px;
  position: relative;
  ${props =>
    props.showShadow &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 15px;
        background: linear-gradient(to bottom, white, transparent);
        pointer-events: none;
      }
    `}
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 15px;
      background: linear-gradient(to top, white, transparent);
      pointer-events: none;
    }
  }
`;

const Options = styled.div`
  flex: 1 1 auto;
  max-height: 225px;
  overflow: auto;
`;

const WindowEmailSubTitle = styled.div`
  margin: 9px 0 30px 0;
  font-size: 13px;
  color: #4a5665;
`;

const EmailFieldsRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const UserSearchContainer = styled.div`
  width: 100%;
`;

const StyledSelectField = styled(SelectField)`
  width: calc(50% - 10px);
`;

export default class ScheduledReportingForm extends React.PureComponent<{
  scheduler: ?SchedulerStateType,
  errors: $ReadOnlyArray<string>,
  handleFrequencyChange: (frequency: ?FrequencyType | 'never') => void,
  handleScheduledOnChange: (scheduledOn: ?ScheduledOnType) => void,
  handleChangeUserMultiSelectFilterValue: (users: $ReadOnlyArray<User>) => void,
  hasTeamAccess?: boolean,
  limitedAccessOrMore?: boolean,
}> {
  render() {
    const { scheduler, errors, hasTeamAccess, limitedAccessOrMore } = this.props;

    return (
      <>
        <WindowEmailSubTitle>
          Set up a recurring email with this view attached as a CSV spreadsheet
        </WindowEmailSubTitle>
        <EmailFieldsRow>
          <StyledSelectField
            value={scheduler != null ? scheduler.frequency : 'never'}
            options={FREQUENCIES}
            label="Frequency"
            onChange={this.props.handleFrequencyChange}
          />
          {scheduler != null && (
            <StyledSelectField
              value={scheduler.scheduledOn}
              options={
                scheduler.frequency === 'weekly' ? WEEKLY_SCHEDULED_TIMES : MONTHLY_SCHEDULED_TIMES
              }
              label={scheduler.frequency === 'weekly' ? 'On' : 'At'}
              onChange={this.props.handleScheduledOnChange}
            />
          )}
        </EmailFieldsRow>
        {scheduler != null && (
          <EmailFieldsRow>
            <UserSearchContainer>
              <OrgUserSearch
                error={errors.includes('recipients') ? 'Required' : ''}
                placeholder="Search Members"
                label="Send to"
                hasTeamAccess={hasTeamAccess}
                limitedAcccessOrMore={limitedAccessOrMore}
                onSelect={selectedUser => {
                  if (
                    scheduler.recipients &&
                    scheduler.recipients.find(user => user.id === selectedUser.id)
                  )
                    return;
                  this.props.handleChangeUserMultiSelectFilterValue([
                    ...(scheduler.recipients || []),
                    selectedUser,
                  ]);
                }}
              />
              {scheduler.recipients && scheduler.recipients.length > 0 && (
                <ListContainer showShadow={scheduler.recipients.length > 3}>
                  <Options>
                    {scheduler.recipients.map(user => (
                      <UserSelectionRow
                        key={user.id}
                        invite={{ ...user, uid: user.id }}
                        onRemove={userId =>
                          this.props.handleChangeUserMultiSelectFilterValue(
                            (scheduler.recipients || []).filter(u => u.id !== userId),
                          )
                        }
                      />
                    ))}
                  </Options>
                </ListContainer>
              )}
            </UserSearchContainer>
          </EmailFieldsRow>
        )}
      </>
    );
  }
}
