/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import scheduleFields from 'config/brief/scheduleFields';

import { type BriefTemplateMutationVariables } from 'graph/mutations/briefTemplate/updateBriefTemplate';

import CheckBox from 'components/material/CheckBox';
import Switch from 'components/material/Switch';

import type { Schedules_template } from './__generated__/Schedules_template.graphql';

const Personalization = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const Description = styled.div`
  flex: 1 1 auto;
  > div:first-child {
    font-weight: 500;
  }
  > div:last-child {
    margin-top: 5px;
    color: rgba(74, 86, 101, 0.54);
    line-height: 1.08;
    font-size: 13px;
  }
`;

const HorizontalLine = styled.div`
  height: 1px;
  max-width: initial !important;
  margin: 15px 0;
  background-color: #eaebec;
`;

const IncludeMore = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: baseline;
  margin-top: 25px;
`;

const StyledPersonalizeCheckBox = styled(CheckBox)`
  margin-right: 15px;
  > div:first-child {
    border-radius: 2px;
  }
`;

const StyledCheckBox = styled(CheckBox)`
  margin-right: 15px;
  text-transform: capitalize;
  &:not(:first-child) {
    margin-top: 20px;
  }
  > div:first-child {
    border-radius: 2px;
  }
`;

class Schedules extends React.PureComponent<{
  template: Schedules_template,
  onTemplateAttributeChange: (input: BriefTemplateMutationVariables) => void,
}> {
  handleTogglePersonalization = () => {
    this.props.onTemplateAttributeChange({
      personalizeSchedules: !this.props.template.personalizeSchedules,
    });
  };

  handleFieldCheck = (fieldName: string, enabled: boolean) => {
    this.props.onTemplateAttributeChange({
      scheduleFields: this.props.template.scheduleFields.map(field => ({
        name: field.name,
        enabled: field.name === fieldName ? enabled : field.enabled,
      })),
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.template.scheduleFields.map(field => (
          <StyledCheckBox
            key={field.name}
            label={scheduleFields[field.name]}
            checked={field.enabled}
            disabled={field.name === 'title' || field.name === 'time'}
            onChange={(checked: boolean) => this.handleFieldCheck(field.name, checked)}
          />
        ))}
        <Personalization>
          <Description>
            <div>Personalize</div>
            <div>Show Members only their own Schedule</div>
          </Description>
          <Switch
            enabled={this.props.template.personalizeSchedules}
            onChange={this.handleTogglePersonalization}
          />
        </Personalization>
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  Schedules,
  graphql`
    fragment Schedules_template on BriefTemplate {
      personalizeSchedules
      scheduleFields {
        name
        enabled
      }
    }
  `,
);
