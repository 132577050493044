/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ExpenseItem_expense$ref = any;
type ExpenseWindow_event$ref = any;
type ExpenseWindow_org$ref = any;
type ExpensesOverlay_expenses$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskExpenses_task$ref: FragmentReference;
export type TaskExpenses_task = {|
  +id: string,
  +slug: string,
  +name: string,
  +expenses: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: ExpenseItem_expense$ref,
      |}
    |}>
  |},
  +event: {|
    +id: string,
    +slug: string,
    +tz: string,
    +viewerCanViewBudget: boolean,
    +expenses: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +deliverable: ?{|
            +id: string
          |},
          +$fragmentRefs: ExpensesOverlay_expenses$ref,
        |}
      |}>
    |},
    +team: {|
      +id: string,
      +org: {|
        +settings: {|
          +currency: string
        |},
        +$fragmentRefs: ExpenseWindow_org$ref,
      |},
    |},
    +$fragmentRefs: ExpenseWindow_event$ref,
  |},
  +$refType: TaskExpenses_task$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TaskExpenses_task",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "expenses",
      "storageKey": null,
      "args": null,
      "concreteType": "ExpenseRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ExpenseRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Expense",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "FragmentSpread",
                  "name": "ExpenseItem_expense",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        v0,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tz",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "viewerCanViewBudget",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ExpenseWindow_event",
          "args": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "expenses",
          "storageKey": null,
          "args": null,
          "concreteType": "ExpenseRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ExpenseRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Expense",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ExpensesOverlay_expenses",
                      "args": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "deliverable",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Deliverable",
                      "plural": false,
                      "selections": [
                        v0
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "team",
          "storageKey": null,
          "args": null,
          "concreteType": "Team",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "org",
              "storageKey": null,
              "args": null,
              "concreteType": "Org",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "ExpenseWindow_org",
                  "args": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "settings",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OrgSettings",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "currency",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c11b0bd88463597d380f7014d1698b7';
module.exports = node;
