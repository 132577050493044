/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import commitModernMutation from 'graph/utils/commitModernMutation';

type Fields = {
  sourceEvent: number,
  eventId: string,
  targetEvent: number,
  copyDueDates: boolean,
  folders: Array<number>,
  fromWindow: string,
};

const mutation = graphql`
  mutation importEventTemplateMutation(
    $input: ImportEventTemplateInput!
    $updateSubtasks: Boolean!
  ) {
    importEventTemplate(input: $input) {
      event {
        id
        progress
        ...Tasks_event
      }
      deliverableEdges {
        node {
          id
          dbId
          name
          order
          folderId
          parent {
            subtasksCount
            completedSubtasksCount
          }
          viewerCanReorder
          ...TaskRow_task
          ...SubtaskRow_subtask
        }
      }
      subtaskEdges @include(if: $updateSubtasks) {
        node {
          id
          dbId
          name
          order
          folderId
          parent {
            subtasksCount
            completedSubtasksCount
          }
          ...TaskRow_task
          ...SubtaskRow_subtask
        }
      }
    }
  }
`;

export default function importEventTemplate({
  sourceEvent,
  eventId,
  targetEvent,
  copyDueDates,
  folders,
  fromWindow,
}: Fields) {
  const updateSubtasks = fromWindow === 'due-date';
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        targetId: targetEvent,
        sourceId: sourceEvent,
        folders,
        template_due_dates: copyDueDates,
      },
      updateSubtasks,
    },

    updater: mergeUpdaters(
      ...[
        addRecordToConnection({
          parentId: eventId,
          edgeName: 'deliverableEdges',
          connections: [
            { field: 'deliverables' },
            { key: 'TasksSectionViewContent_deliverables' },
            { key: 'TasksDueDateViewContent_deliverables' },
            { key: 'TasksCalendarView_deliverables' },
          ],
        }),
        updateSubtasks
          ? addRecordToConnection({
              parentId: eventId,
              edgeName: 'subtaskEdges',
              connections: [
                { field: 'deliverables' },
                { key: 'TasksDueDateViewContent_deliverables' },
                { key: 'TasksCalendarView_deliverables' },
              ],
            })
          : null,
      ].filter(Boolean),
    ),
  });
}
