/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import BudgetCharts from './BudgetCharts';
import BudgetStatsOverview from './BudgetStatsOverview';
import CategoriesTable from './CategoriesTable';

import { type BudgetStats_event } from './__generated__/BudgetStats_event.graphql';
import { type BudgetStats_org } from './__generated__/BudgetStats_org.graphql';

const DarkBackground = styled.div`
  padding: 20px 5px 0;
  background: #f7f8f9;
`;

class BudgetStats extends React.PureComponent<{
  event: BudgetStats_event,
  org: BudgetStats_org,
  chart: 'total' | 'category',
  onChangeChart: ('total' | 'category') => void,
}> {
  render() {
    const { event, org } = this.props;

    return (
      <React.Fragment>
        <BudgetCharts
          event={event}
          org={org}
          chart={this.props.chart}
          onChangeChart={this.props.onChangeChart}
        />

        <DarkBackground>
          <BudgetStatsOverview event={event} org={org} />

          <CategoriesTable categoryEdges={event.budgetCategories.edges} event={event} org={org} />
        </DarkBackground>
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  BudgetStats,
  graphql`
    fragment BudgetStats_event on Event {
      ...BudgetCharts_event
      ...BudgetStatsOverview_event
      ...CategoriesTable_event

      budgetCategories {
        edges {
          ...CategoriesTable_categoryEdges
        }
      }
    }

    fragment BudgetStats_org on Org {
      ...BudgetCharts_org
      ...BudgetStatsOverview_org
      ...CategoriesTable_org
    }
  `,
);
