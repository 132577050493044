/* @flow */
import { graphql } from 'react-relay';

import { type StaffAccessLevel } from 'config/staffAccessLevels';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateStafferAccessLevelMutationResponse,
  updateStafferAccessLevelMutationVariables,
} from './__generated__/updateStafferAccessLevelMutation.graphql';

const mutation = graphql`
  mutation updateStafferAccessLevelMutation($input: UpdateStafferAccessLevelInput!) {
    updateStafferAccessLevel(input: $input) {
      staffMembership {
        id
        accessLevel
        viewerCanUpdateAccessLevel
        viewerCanRemove
        user {
          id
          inviteStatus
        }
      }
    }
  }
`;

export default function updateStafferAccessLevel(args: {
  eventId: string,
  userId: string,
  accessLevel: StaffAccessLevel,
}): Promise<updateStafferAccessLevelMutationResponse> {
  const variables: updateStafferAccessLevelMutationVariables = {
    input: {
      eventId: args.eventId,
      userId: args.userId,
      accessLevel: args.accessLevel,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateStafferAccessLevel: {
        staffMembership: {
          accessLevel: args.accessLevel,
          user: {
            id: args.userId,
          },
        },
      },
    },
  });
}
