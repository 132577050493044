/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import formatDateTimeISO from 'utils/date/formatDateTimeISO';

import type { ContactAttendanceLeaveTime_event } from './__generated__/ContactAttendanceLeaveTime_event.graphql';
import type { ContactAttendanceLeaveTime_eventContact } from './__generated__/ContactAttendanceLeaveTime_eventContact.graphql';

const ContactAttendanceLeaveTime = (props: {
  eventContact: ContactAttendanceLeaveTime_eventContact,
  event: ContactAttendanceLeaveTime_event,
}) => {
  const { event, eventContact } = props;
  const leaveTime =
    (eventContact.zoomRegistrant &&
      (eventContact.zoomRegistrant.times.slice(-1)[0] || {}).endTime) ||
    (eventContact.g2wRegistrant && (eventContact.g2wRegistrant.times.slice(-1)[0] || {}).endTime);

  if (!leaveTime) return null;

  return formatDateTimeISO(moment(leaveTime).tz(event.tz));
};

export default createFragmentContainer(
  ContactAttendanceLeaveTime,
  graphql`
    fragment ContactAttendanceLeaveTime_eventContact on EventContactEdge {
      zoomRegistrant {
        times {
          startTime
          endTime
        }
      }
      g2wRegistrant {
        times {
          startTime
          endTime
        }
      }
    }

    fragment ContactAttendanceLeaveTime_event on Event {
      tz
    }
  `,
);
