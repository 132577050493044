/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import SelectField, { SelectedLabel } from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import TinyRichText from 'components/TinyRichText';
import UIContext from 'views/Main/Event/Registration/UIContext';

import type { Field, QuestionType } from '../lib/types';
import EditableQuestionActions from './EditableQuestionActions';
import EditableQuestionErrors from './EditableQuestionErrors';
import EditableQuestionSelectOptions from './EditableQuestionSelectOptions';

const staticFields = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  company_name: 'Company Name',
};

const StyledTextField = styled(TextField)`
  font-weight: 500;
  font-size: 16px;
  color: rgba(74, 86, 101, 1);
`;

const StyledSelectField = styled(SelectField)`
  ${props =>
    props.disabled &&
    css`
      ${SelectedLabel} {
        color: rgba(74, 86, 101, 0.54);
      }
    `};
`;

const SelectFieldsContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex: 1 1 auto;
`;

const StyledTinyRichText = styled(TinyRichText)`
  margin-top: 10px;
  .tox .tox-toolbar__group {
    padding: 0 3px;
  }
`;

export default class EditableQuestionView extends React.PureComponent<{
  question: QuestionType,
  availableFields: $ReadOnlyArray<Field>,
  errors: $ReadOnlyArray<string>,
  onChangeName: (label: string) => void,
  onChangeMapping: (customField: ?Field, fieldName?: string) => void,
  onChangeRequired: (required: boolean) => void,
  onChangeHelpText: (helpText: string) => void,
  onRemoveQuestion: (questionId: string) => void,
  onSetOptions: (values: $ReadOnlyArray<string>) => void,
  onSetOther: (show: boolean) => void,
  dragStartId?: string,
  defaultFont?: string,
}> {
  handleChangeName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeName(e.currentTarget.value);
  };

  handleChangeMapping = (mapId: ?string) => {
    if (!mapId || mapId === this.props.question.id) return;

    const newCustomField = this.props.availableFields.find(field => field.id === mapId);
    if (newCustomField) {
      this.props.onChangeMapping(newCustomField);
    }
  };

  getMappedValue = (): ?string => {
    const question = this.props.question;
    if (question.id.startsWith('question_')) {
      return question.customField ? question.customField.id : null;
    }
    return question.customField ? question.customField.id : question.id;
  };

  isDisabledMapping = () => {
    const question = this.props.question;
    if (question.id.startsWith('question_')) {
      return false;
    }

    return question.customField ? question.customField.required : true;
  };

  render() {
    const { question, availableFields, dragStartId, defaultFont = 'open sans' } = this.props;
    const newQuestionsMapping =
      question.id.startsWith('question_') && question.customField
        ? {
            value: question.customField.id,
            label: `${question.customField.customizableType === 'COMPANY' ? 'Company - ' : ''}${
              question.customField.label
            }`,
          }
        : null;

    const mapOptions = [
      question.id.startsWith('question_')
        ? newQuestionsMapping
        : {
            value: question.customField ? question.customField.id : question.id,
            label: question.customField
              ? `${question.customField.customizableType === 'COMPANY' ? 'Company - ' : ''}${
                  question.customField.label
                }`
              : staticFields[question.fieldName || 'first_name'],
          },
      ...availableFields
        .filter(
          customField =>
            !question.customField ||
            (question.customField && customField.id !== question.customField.id),
        )
        .map(customField => ({
          label: `${customField.customizableType === 'COMPANY' ? 'Company - ' : ''}${
            customField.label
          }`,
          value: customField.id,
        })),
    ].filter(Boolean);

    const hasSelectOptions =
      question.customField &&
      (question.customField.fieldName === 'owner_id' ||
        ['SELECT', 'MULTISELECT', 'USER_SELECT', 'USER_MULTISELECT'].includes(
          question.customField.kind,
        ) ||
        (question.customField && (question.customField.options || []).length > 0));
    return (
      <div>
        <StyledTextField
          placeholder="Question"
          value={question.label}
          onChange={this.handleChangeName}
          autoFocus={!question.id.startsWith('question_')}
          multiline
        />
        <SelectFieldsContainer>
          <StyledSelectField
            label="Map with"
            autoFocus={question.id.startsWith('question_')}
            value={this.getMappedValue()}
            options={mapOptions}
            onChange={this.handleChangeMapping}
            disabled={this.isDisabledMapping()}
            height={250}
            searchable
          />
        </SelectFieldsContainer>

        {hasSelectOptions && (
          <EditableQuestionSelectOptions
            question={question}
            onSetOptions={this.props.onSetOptions}
            onSetOther={this.props.onSetOther}
            dragStartId={dragStartId}
          />
        )}
        <UIContext.Consumer>
          {({ loaderColor }) => {
            return (
              <StyledTinyRichText
                label="Help text"
                value={question.helpText}
                initOptions={{
                  toolbar:
                    'fontselect fontsizeselect | forecolor link | bold italic underline |  bullist numlist | alignleft aligncenter alignright',
                  min_height: 80,
                  content_style: `@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans');
                              .mce-content-body {
                                font-family: ${defaultFont};
                              };
                              .mce-content-body > div {
                                font-family: ${defaultFont} !important;
                              }`,
                }}
                onChange={this.props.onChangeHelpText}
                loaderColor={loaderColor}
              />
            );
          }}
        </UIContext.Consumer>

        <EditableQuestionErrors errors={this.props.errors} />

        <EditableQuestionActions
          question={question}
          onChangeRequired={this.props.onChangeRequired}
          onRemoveQuestion={this.props.onRemoveQuestion}
        />
      </div>
    );
  }
}
