/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { type SortParam } from 'utils/routing/parseTypedQueryString';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import Table, { type ColumnType } from 'components/budget/Table';
import { type ColumnConfiguration } from 'components/material/table';
import type {
  OpportunityCellPropsType,
  OpportunityTableSourceTab,
} from 'components/Opportunities/OpportunitiesTableColumnSettings';

import type { OpportunitiesList_opportunities } from './__generated__/OpportunitiesList_opportunities.graphql';
import type { OpportunitiesList_org } from './__generated__/OpportunitiesList_org.graphql';

type Opportunity = $ElementType<OpportunitiesList_opportunities, 0>;

class OpportunitiesList extends React.PureComponent<{
  org: ?OpportunitiesList_org,
  opportunities: ?OpportunitiesList_opportunities,
  shownColumns: ColumnConfiguration,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  tz: string,
  fromTab: OpportunityTableSourceTab,
}> {
  cellProps = (
    opportunity: Opportunity,
    _group,
    column?: ColumnType<any, any>,
  ): $Shape<
    OpportunityCellPropsType<
      ?OpportunitiesList_org,
      {|
        +id: string,
        +$fragmentRefs?: any,
        +$refType: any,
      |},
    >,
  > => {
    const { org, tz, fromTab } = this.props;

    return {
      org,
      opportunity,
      customizable: opportunity,
      fieldSettings: column != null ? column.fieldSettings : null,
      readOnly: true,
      fromTab,
      tz,
    };
  };

  keyExtractor = (opportunity: Opportunity) => {
    return opportunity.id;
  };

  render() {
    const { opportunities, sort, shownColumns, onChangeSort } = this.props;

    if (opportunities && opportunities.length === 0) {
      return <EmptyView message="No results at this time." icon={<NoResult />} />;
    }

    return (
      <Table
        columns={shownColumns}
        data={opportunities}
        sort={sort}
        onChangeSort={onChangeSort}
        cellProps={this.cellProps}
        keyExtractor={this.keyExtractor}
      />
    );
  }
}

export default createFragmentContainer(
  OpportunitiesList,
  graphql`
    fragment OpportunitiesList_org on Org {
      ...OpportunityAmount_org @include(if: $includeAmount)
      ...CustomizableCurrency_org @include(if: $includeCustomizableCurrency)
      ...OpportunitySalesforceId_org
    }
    fragment OpportunitiesList_opportunities on SalesforceOpportunityType @relay(plural: true) {
      id
      ...OpportunityAmount_opportunity @include(if: $includeAmount)
      ...OpportunityCompany_opportunity @include(if: $includeCompanyId)
      ...OpportunityStatus_opportunity @include(if: $includeStatus)
      ...OpportunityDescription_opportunity @include(if: $includeDescription)
      ...OpportunitySalesforceId_opportunity @include(if: $includeSalesforceId)
      ...OpportunityName_opportunity @include(if: $includeName)
      ...OpportunityOwner_opportunity @include(if: $includeOwnerId)
      ...OpportunityAssociatedContacts_opportunity @include(if: $includeAssociatedContacts)
      ...CustomizableText_customizable @include(if: $includeCustomizableText)
      ...CustomizableTextarea_customizable @include(if: $includeCustomizableTextarea)
      ...CustomizableLink_customizable @include(if: $includeCustomizableLink)
      ...CustomizableDate_customizable @include(if: $includeCustomizableDate)
      ...CustomizableBoolean_customizable @include(if: $includeCustomizableBoolean)
      ...CustomizableNumber_customizable @include(if: $includeCustomizableNumber)
      ...CustomizableCurrency_customizable @include(if: $includeCustomizableCurrency)
      ...CustomizableSelect_customizable @include(if: $includeCustomizableSelect)
      ...CustomizableMultiselect_customizable @include(if: $includeCustomizableMultiselect)
      ...CustomizableUserSelect_customizable @include(if: $includeCustomizableUserSelect)
      ...CustomizableUserMultiselect_customizable @include(if: $includeCustomizableUserMultiselect)
    }
  `,
);
