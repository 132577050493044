/* @flow */
import React from 'react';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';

import ColumnFilter from 'components/material/ColumnFilters';
import AdvancedFilter from 'components/material/Filters/Advanced/AdvancedFilter';
import getOpportunityFilterConverters from 'components/Opportunities/lib/getOpportunityFilterConverters';
import { type ParsedOpportunitiesFilters } from 'components/Opportunities/lib/parseOpportunitiesFilters';
import renderOpportunityFilter from 'components/Opportunities/lib/renderOpportunityFilter';
import { getColumnsShowConfig } from 'components/Opportunities/OpportunitiesTableColumnSettings';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
`;

export default class CompanyOpportunitiesHeader extends React.Component<{
  opportunityFields: $ReadOnlyArray<FieldType>,
  history: RouterHistory,
  filters: ParsedOpportunitiesFilters,
  shownColumns: $ReadOnlyArray<string>,
  onColumnsChange: (shownColumns: $ReadOnlyArray<string>) => void,
}> {
  render() {
    const { shownColumns, history, opportunityFields, filters, onColumnsChange } = this.props;
    const opportunityColumns = getColumnsShowConfig(opportunityFields);

    return (
      <Container>
        <AdvancedFilter
          history={history}
          filters={filters}
          filterOptions={opportunityFields}
          converterFn={getOpportunityFilterConverters}
          filterRenderer={renderOpportunityFilter}
        />
        <ColumnFilter
          filters={opportunityColumns}
          shownFilters={shownColumns}
          onFilterChange={onColumnsChange}
        />
      </Container>
    );
  }
}
