/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import CostFieldCore from 'components/material/CostField';

import inputBoxStyled from './components/inputBoxStyled';
import Label, { Error, Header } from './FieldComponents';

const StyledCostField = styled(CostFieldCore)`
  margin: 0;
  input {
    ${props => inputBoxStyled(props)};
  }
`;

export default class CostField extends React.PureComponent<{
  label: string,
  onBlur?: (SyntheticEvent<HTMLInputElement>, ?number) => void,
  disabled?: boolean,
  defaultValue?: ?number,
  currency: string,
  required?: boolean,
  error?: string,
}> {
  render() {
    const {
      label,
      required,
      error,
      onBlur,
      currency,
      defaultValue,
      disabled,
    } = this.props;
    return (
      <Label required={required}>
        <Header>{label}</Header>
        <StyledCostField
          defaultValue={defaultValue}
          currency={currency}
          onBlur={onBlur}
          error={error}
          disabled={disabled}
        />
        {this.props.error && <Error>{this.props.error}</Error>}
      </Label>
    );
  }
}
