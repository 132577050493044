/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import updateStaffer from 'graph/mutations/staff/updateStaffer';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineSelectField from 'components/budget/Table/InlineSelectField';

import type { StaffOnsiteCell_staffer } from './__generated__/StaffOnsiteCell_staffer.graphql';

const options = [
  {
    label: 'On-site',
    value: true,
  },
  {
    label: 'Remote',
    value: false,
  },
];

class StaffOnsiteCell extends React.PureComponent<
  {
    staffer: StaffOnsiteCell_staffer,
  },
  {
    editing: boolean,
  },
> {
  stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleEventSelect = (onsite: ?boolean): void => {
    if (onsite !== this.props.staffer.onsite) {
      updateStaffer({
        membershipId: this.props.staffer.id,
        onsite: onsite || false,
      }).catch(showModernMutationError);
    }
  };

  render() {
    const { onsite, viewerCanUpdate } = this.props.staffer;

    return (
      <div onClick={this.stopPropagation}>
        <InlineSelectField
          options={options}
          value={onsite}
          onChange={this.handleEventSelect}
          disabled={!viewerCanUpdate}
          hideLockIcon
        />
      </div>
    );
  }
}

export default createFragmentContainer(
  StaffOnsiteCell,
  graphql`
    fragment StaffOnsiteCell_staffer on StaffMembership {
      id
      onsite
      viewerCanUpdate
    }
  `,
);
