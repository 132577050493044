/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type RouterHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import uniq from 'lodash/uniq';
import { transparentize } from 'polished';

import getBudgetQuarterString from 'utils/fiscal/getBudgetQuarterString';
import getFiscalYear from 'utils/fiscal/getFiscalYear';
import getQuarterNumber from 'utils/fiscal/getQuarterNumber';

import createBudgetPeriod from 'graph/mutations/budget_period/createBudgetPeriod';
import removeBudgetPeriods from 'graph/mutations/budget_period/removeBudgetPeriods';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import { Container as PickerLabel, StyledTextInput } from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';
import Menu, { ScrollArea } from 'components/page/Menu';
import MenuItem from 'components/page/MenuItem';

import FiscalYearPicker from './FiscalYearPicker';

import { type FiscalYearPanel_org } from './__generated__/FiscalYearPanel_org.graphql';

const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;
const Container = styled.div`
  display: flex;
  flex: 0 0 auto;
  max-width: calc(100% - 120px);
  padding: 0;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 10px;
  }
`;

const StyledMenu = styled(Menu)`
  ${ScrollArea} {
    padding-right: 2px;
  }
  div& {
    flex: 1 1 auto;
    height: 50px;
    padding: 0;
    border: 0;
  }
`;

const StyledTooltip = styled(Tooltip)`
  flex: 0 1 auto;
  margin: 6px 30px;
`;

const AddNewYear = styled.a`
  font-size: 24px;
  color: #39a8da;
  cursor: pointer;
  &:hover {
    color: ${transparentize(0.1, '#39a8da')};
  }
`;

const StyledFiscalYearPicker = styled(FiscalYearPicker)`
  margin-left: 30px;
  margin-top: 0;
  width: 100px;
  ${StyledTextInput} {
    height: 100%;
    padding: 0 10px 8px;
    color: #828b93;
  }
  ${PickerLabel} {
    margin: 6px 0 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 0 10px;
  span {
    color: #828b93;
  }
  &.active span {
    color: #4a5665;
  }
`;

class FiscalYearPanel extends React.Component<
  {
    org: FiscalYearPanel_org,
    history: RouterHistory,
  },
  { showFiscalAdd: boolean, removableYear: ?number },
> {
  state = { showFiscalAdd: false, removableYear: null };

  handleFiscalCreate = () => {
    this.setState({ showFiscalAdd: true });
  };

  // In backend period like "Q1 2019" in url "Q1_2019"
  handleAddFiscalYear = (year: number) => {
    this.setState({ showFiscalAdd: false });
    createBudgetPeriod({ orgId: this.props.org.id, year })
      .then(({ period }) =>
        this.props.history.push(`/settings/budgets/${period.replace(' ', '_')}`),
      )
      .catch(showModernMutationError);
  };

  isFiscalYearMatch = (match, location, itemYear): boolean => {
    if (match) return true;
    const pathnameSplitted = location.pathname.split('_');
    return parseInt(pathnameSplitted[pathnameSplitted.length - 1], 10) === itemYear;
  };

  // Getting year from label like FY 2019
  handleRemove = (text: string) => {
    const year = parseInt(text.split(' ')[1], 10);
    this.setState({ removableYear: year });
  };

  handleFiscalYearRemove = () => {
    const { removableYear: fiscalYear } = this.state;
    if (fiscalYear != null) {
      removeBudgetPeriods({ orgId: this.props.org.id, fiscalYear }).then(() => {
        const {
          org: {
            settings: { fiscalYearStart },
          },
          history,
        } = this.props;

        const currentPeriodPage = `/settings/budgets/${getBudgetQuarterString(fiscalYearStart)}`;
        history.push(currentPeriodPage);
      });
    }
  };

  handleFYInputBlur = () => {
    this.setState({ showFiscalAdd: false });
  };

  handleHideRemoveWarning = () => {
    this.setState({ removableYear: null });
  };

  render() {
    const {
      org: {
        budgetPeriods,
        settings: { fiscalYearStart },
      },
    } = this.props;
    const { showFiscalAdd, removableYear } = this.state;
    const currentFiscalYear = getFiscalYear(0, fiscalYearStart).end.year();
    const currentQuarter = getQuarterNumber(fiscalYearStart);
    const years = uniq([
      ...budgetPeriods.edges.map(({ node }) => node.year),
      currentFiscalYear,
    ]).sort();
    return (
      <Wrapper>
        <Container>
          <StyledMenu scrollable>
            {years.map(year => {
              const handleRemove = currentFiscalYear === year ? null : this.handleRemove;
              return (
                <StyledMenuItem
                  key={`FY ${year}`}
                  text={`FY ${year}`}
                  index
                  to={`/settings/budgets/Q${
                    year === currentFiscalYear ? currentQuarter : 1
                  }_${year}`}
                  isActive={(match, location) => this.isFiscalYearMatch(match, location, year)}
                  onRemove={handleRemove}
                />
              );
            })}
          </StyledMenu>
        </Container>
        {showFiscalAdd ? (
          <StyledFiscalYearPicker
            usedYears={years}
            onAddYear={this.handleAddFiscalYear}
            onBlur={this.handleFYInputBlur}
          />
        ) : (
          <StyledTooltip placement="top" label="Add Fiscal Year">
            <AddNewYear onClick={this.handleFiscalCreate}>+</AddNewYear>
          </StyledTooltip>
        )}
        {removableYear != null && (
          <ConfirmationWindow
            onHide={this.handleHideRemoveWarning}
            onConfirm={this.handleFiscalYearRemove}
            actionLabel="Confirm"
            title="Are you sure?"
            message="Deleting this budget year will permanently delete all of the budgeted amounts
                     in it. Note that it will not delete any amounts on expenses or events."
          />
        )}
      </Wrapper>
    );
  }
}

export default createFragmentContainer(
  withRouter(FiscalYearPanel),
  graphql`
    fragment FiscalYearPanel_org on Org {
      id
      settings {
        fiscalYearStart
      }
      budgetPeriods {
        edges {
          node {
            period
            year
          }
        }
      }
    }
  `,
);
