/* @flow */
import React from 'react';
import { type RouterHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import UserStore from 'stores/UserStore';

import withQueryParams from 'utils/routing/withQueryParams';
import isValidEmail from 'utils/validators/isValidEmail';

import AuthButton from 'components/Auth/AuthButton';
import ErrorBlock from 'components/Auth/AuthErrorBlock';
import AuthFooter from 'components/Auth/AuthFooter';
import Window from 'components/Auth/AuthWindow';
import TextField, { Label } from 'components/material/TextField';

const StyledTextField = styled(TextField)`
  margin: 16px 0 24px 0;

  input {
    &:focus {
      border-bottom-color: #5554c3;

      ~ ${Label} {
        color: #5554c3;
      }
    }
  }
`;

const StyledButton = styled(AuthButton)`
  margin-top: 40px;
  padding: 10px 14px;
  border-radius: 52px;
  background-color: #5e5bff;

  &:hover {
    background-color: #403ec9;
  }
`;

const StyledParagraph = styled.p`
  a {
    color: #5554c3;
  }
`;

class ConfirmationResend extends React.Component<
  {
    queryParams: { error?: ?string },
    history: RouterHistory,
  },
  { serverError: ?string, loading: boolean, thankYouText: ?string, emailError: boolean },
> {
  state = {
    serverError: this.props.queryParams.error,
    emailError: false,
    loading: false,
    thankYouText: null,
  };

  componentDidMount() {
    const error = this.props.queryParams.error;
    if (error) {
      this.props.history.replace('/resend');
    }
  }

  handleConfirmationResend = e => {
    if (e) e.preventDefault();

    const email = e.target.elements.email.value;

    if (!isValidEmail(email)) {
      this.setState({ emailError: true });
      return;
    }

    this.setState({ loading: true, emailError: false });
    UserStore.resendEmailConfirmation(email, response => {
      if (response && response.message) {
        this.setState({ loading: false, thankYouText: response.message });
      } else {
        this.setState({ loading: false, serverError: response || 'Email not found' });
      }
    });
  };

  handleErrorDismiss = () => {
    this.setState({ serverError: null });
  };

  handleConfirmationButtonClick = () => {
    this.props.history.push('/');
  };

  renderThankYouWindow() {
    return (
      <Window title="Thank you!">
        <p>{this.state.thankYouText}</p>
        <StyledButton onClick={this.handleConfirmationButtonClick} label="OK" primary />
      </Window>
    );
  }

  render() {
    if (this.state.thankYouText) return this.renderThankYouWindow();

    return (
      <div>
        <Window title="Resend">
          {this.state.serverError && (
            <ErrorBlock text={this.state.serverError} onDismiss={this.handleErrorDismiss} />
          )}
          <p>
            Provide the email you used to sign up for Circa and we’ll resend your confirmation email
            there:
          </p>
          <form onSubmit={this.handleConfirmationResend} noValidate>
            <StyledTextField
              type="email"
              error={this.state.emailError ? 'Email is not valid' : null}
              label="Your email"
              name="email"
              autoFocus
            />
            <StyledButton
              type="submit"
              loading={this.state.loading}
              label="Resend confirmation email"
              primary
            />
          </form>
          <AuthFooter>
            <StyledParagraph>
              Already confirmed your account? <Link to="/">Sign In</Link>
            </StyledParagraph>
          </AuthFooter>
        </Window>
      </div>
    );
  }
}

export default withQueryParams(ConfirmationResend);
