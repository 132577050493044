import './styles.styl';

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

import omit from 'lodash/omit';

export class Button extends React.Component {
  static propTypes = {
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    negative: PropTypes.bool,
    danger: PropTypes.bool,
    loading: PropTypes.bool,
    minimal: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const classes = classNames(
      {
        button: true,
        'button-primary': this.props.primary,
        'button-secondary': this.props.secondary,
        'button-danger': this.props.danger,
        'button-negative': this.props.negative,
        'button-minimal': this.props.minimal,
        'button-disabled': this.props.disabled,
      },
      this.props.className,
    );

    const buttonProps = omit(this.props, [
      'primary',
      'secondary',
      'negative',
      'danger',
      'loading',
      'minimal',
      'disabled',
      'text',
      'className',
    ]);

    return (
      <button
        {...buttonProps}
        className={classes}
        disabled={this.props.loading || this.props.disabled}
      >
        {!this.props.loading && this.props.text}
        {this.props.loading && <i className="fa fa-fw fa-circle-o-notch fa-spin" />}
      </button>
    );
  }
}
