export function fitInViewPort(element, options = {}) {
  element.removeAttribute('style');

  const pageWidth = document.documentElement.clientWidth;
  const pageHeight = document.documentElement.clientHeight;
  const { top, right, bottom, left, width, height } = element.getBoundingClientRect();

  const minimum = Object.assign(
    {
      height,
      width,
    },
    options,
  );

  if (minimum.height < height) minimum.height = height;
  if (minimum.width < width) minimum.width = width;

  const inTop = top >= 0;
  const inRight = right + (minimum.width - width) <= pageWidth;
  const inBottom = bottom + (minimum.height - height) <= pageHeight;
  const inLeft = left >= 0;

  const newDimensions = {
    Bottom: inTop ? 0 : 0 - top,
    Left: inRight ? 0 : right + (minimum.width - width) - pageWidth + 3,
    Top: inBottom ? 0 : bottom + (minimum.height - height) - pageHeight + 3,
    Right: inLeft ? 0 : 0 - left,
  };

  const newPosition = {
    top: top + newDimensions.Bottom - newDimensions.Top,
    left: left + newDimensions.Right - newDimensions.Left,
  };

  if (!options.keepPosition) {
    element.style.position = 'fixed';
    element.style.top = `${newPosition.top}px`;
    element.style.left = `${newPosition.left}px`;
    element.style.bottom = 'auto';
    element.style.right = 'auto';
    element.style.zIndex = 1000;
    element.style.width = `${width}px`;
    element.style.margin = '0';
    element.style.minWidth = 'unset';
  } else {
    Object.keys(newDimensions).forEach(key => {
      const value = newDimensions[key];

      if (value) {
        element.style[`margin${key}`] = `-${value}px`;
      }
    });
  }
}

export function isInViewPort(element) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
