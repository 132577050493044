/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Match, type RouterHistory, Route } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import currencies from 'config/currencies.json';

import getFiscalYear from 'utils/fiscal/getFiscalYear';
import getQuarterNumber from 'utils/fiscal/getQuarterNumber';

import createBudgetCategory from 'graph/mutations/budget_category/createBudgetCategory';
import removeBudgetCategory from 'graph/mutations/budget_category/removeBudgetCategory';
import updateBudgetCategory from 'graph/mutations/budget_category/updateBudgetCategory';
import updateOrgSettings from 'graph/mutations/org/updateOrgSettings';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import LockIcon from 'components/LockIcon';
import SelectField, { Label as SelectFieldLabel } from 'components/material/SelectField';

import OrgSettingsProps, { AddRow } from '../components/OrgSettingsProps';
import { Container as Row, Name as CategoryName } from '../components/OrgSettingsPropsRow';
import BudgetSetup from './BudgetSetup';
import Section from './Section';

import type { BudgetContainer_me } from './__generated__/BudgetContainer_me.graphql';
import type { BudgetContainer_org } from './__generated__/BudgetContainer_org.graphql';

const Title = styled.div`
  margin: 25px 0 21px 0;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const ConfigContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 23px 22px 0;
  background-color: #f7f8f9;
`;

const StyledSelectField = styled(SelectField)`
  flex: 1 0 0px;
  width: 155px;
  max-width: 155px;
  margin-bottom: 25px;
  font-size: 13px;
  &:not(:last-child) {
    margin-right: 75px;
  }
  &:first-child {
    max-width: 115px;
    width: 115px;
  }
  ${SelectFieldLabel} {
    top: -8px;
    left: 0;
    font-size: 11px;
  }
`;

const StyledOrgSettingsProps = styled(OrgSettingsProps)`
  flex: 1 0 auto;
  margin-left: 2px;
  font-size: 13px;
  ${AddRow} {
    flex: 1 0 auto;
    padding-bottom: 17px;
    font-size: 13px;
  }
  ${Row} {
    flex: 1 0 auto; /* IE 11 fix */
  }
  ${CategoryName} {
    color: #4a5665;
  }
`;

const CurrencyWrapper = styled.div`
  position: relative;
  flex: 1 0 0px;
  width: 155px;
  max-width: 190px;
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 75px;
  top: 16px;
`;

const TeamBudgetingSection = styled(Section)`
  margin-bottom: 55px;
`;

class BudgetContainer extends React.Component<
  {
    org: BudgetContainer_org,
    me: BudgetContainer_me,
    match: Match,
    history: RouterHistory,
  },
  { changedFiscalStart: ?number },
> {
  state = {
    changedFiscalStart: null,
  };

  setCurrentQuarter = () => {
    const {
      org: {
        settings: { fiscalYearStart },
      },
      history,
    } = this.props;
    const currentFiscalYear = getFiscalYear(0, fiscalYearStart).end.year();
    const currentQuarter = getQuarterNumber(fiscalYearStart);
    const currentPeriodPage = `/settings/budgets/Q${currentQuarter}_${currentFiscalYear}`;
    history.push(currentPeriodPage);
  };

  handleCategoryCreate = (name, description) => {
    createBudgetCategory({
      orgId: this.props.org.id,
      name,
      description,
    }).catch(showModernMutationError);
  };

  handleCategoryRemove = budgetCategoryId => {
    removeBudgetCategory({
      orgId: this.props.org.id,
      budgetCategoryId,
    }).catch(showModernMutationError);
  };

  handleBudgetCategorySave = (categoryId, name, description) => {
    updateBudgetCategory({
      categoryId,
      name,
      description,
    }).catch(showModernMutationError);
  };

  initiateFiscalYearStartChange = (fiscalYearStart: ?number) => {
    if (this.props.org.settings.fiscalYearStart !== fiscalYearStart) {
      this.setState({ changedFiscalStart: fiscalYearStart });
    }
  };

  handleCurrencyChange = (currency: ?string) => {
    if (currency) {
      updateOrgSettings(this.props.org.settings.id, { currency }).catch(showModernMutationError);
    }
  };

  handleHideResetWarning = () => {
    this.setState({ changedFiscalStart: null });
  };

  handleFiscalYearStartChange = () => {
    updateOrgSettings(
      this.props.org.settings.id,
      {
        fiscalYearStart: this.state.changedFiscalStart,
      },
      { orgId: this.props.org.id },
    )
      .then(() => {
        this.setCurrentQuarter();
      })
      .catch(showModernMutationError);
  };

  render() {
    const {
      org,
      me,
      org: { viewerCanChangeCurrency },
      history,
      match: {
        path,
        params: { period },
      },
    } = this.props;
    const categories = [
      ...org.budgetCategories.edges.map(edge => edge.node),
      { id: 'uncategorized', name: 'Uncategorized', isDefault: true, description: null },
    ];
    const months = moment.months().map((month, index) => ({ label: month, value: index }));
    return (
      <>
        <Title>Budget settings</Title>
        <Section>
          <ConfigContainer>
            <CurrencyWrapper>
              <StyledSelectField
                label="Default Currency"
                value={org.settings.currency}
                onChange={this.handleCurrencyChange}
                options={currencies.map(currency => ({
                  value: currency.code,
                  label: `${currency.code} (${currency.symbol})`,
                }))}
                searchable
                disabled={!viewerCanChangeCurrency}
              />
              {!viewerCanChangeCurrency && (
                <StyledLockIcon
                  // need for multiline tooltip
                  // eslint-disable-next-line react/jsx-curly-brace-presence
                  label={`Default currency has already been set for your Workspace.
                Please contact Support to update.`}
                />
              )}
            </CurrencyWrapper>
            <StyledSelectField
              label="Fiscal Year Start Month"
              value={org.settings.fiscalYearStart}
              onChange={this.initiateFiscalYearStartChange}
              options={months}
            />
          </ConfigContainer>
        </Section>
        <TeamBudgetingSection
          label="Workspace Budget Setup"
          info="Set up your Workspace Budget for each quarter of each year."
        >
          <Route
            exact
            path={`${path}/:period`}
            render={renderProps => {
              return (
                <BudgetSetup
                  org={org}
                  user={me}
                  period={period}
                  history={history}
                  {...renderProps}
                />
              );
            }}
          />
        </TeamBudgetingSection>
        <Section label={`Expense Categories (${categories.length})`}>
          <StyledOrgSettingsProps
            onSave={this.handleBudgetCategorySave}
            onDelete={this.handleCategoryRemove}
            onCreate={this.handleCategoryCreate}
            itemName="Expense Category"
            itemType="category"
            items={categories}
            showAllItems
          />
        </Section>
        {this.state.changedFiscalStart !== null && (
          <ConfirmationWindow
            onHide={this.handleHideResetWarning}
            onConfirm={this.handleFiscalYearStartChange}
            actionLabel="Change"
            title="Are you sure?"
            message="Changing the Fiscal Year Start Month will reset all Team Budget amounts. Other
                     amounts, like Event Budget Categories, will not be affected. You can re-add
                     Team Budget amounts to the new Fiscal Quarters after they reset."
          />
        )}
      </>
    );
  }
}
export default createFragmentContainer(BudgetContainer, {
  org: graphql`
    fragment BudgetContainer_org on Org {
      id
      viewerCanChangeCurrency
      settings {
        currency
        id
        fiscalYearStart
      }
      budgetCategories {
        edges {
          node {
            id
            name
            description
          }
        }
      }
      budgetPeriods {
        edges {
          node {
            year
          }
        }
      }
      ...BudgetSetup_org
    }
  `,
  me: graphql`
    fragment BudgetContainer_me on User {
      ...BudgetSetup_user
    }
  `,
});
