/* @flow */

import getOptionKeysByKind from './getOptionKeysByKind';
import type { CustomFieldsType, FieldType } from './types';

const getCustomFieldPlainValue = (
  customField: FieldType,
  customFieldValues: CustomFieldsType,
): ?mixed => {
  const { arrayKey, optionKey } = getOptionKeysByKind(customField.kind);
  const fieldValuesArray = customFieldValues[arrayKey]
    .filter(
      fieldValue =>
        fieldValue.customField.id === customField.id &&
        (!fieldValue.option || fieldValue.option.id !== 'other' || fieldValue.selectOtherValue),
    )
    .map(fieldValue => fieldValue[optionKey]);

  if (customField.kind.includes('MULTISELECT')) {
    return fieldValuesArray;
  }

  return fieldValuesArray[0];
};

export default getCustomFieldPlainValue;
