/* @flow */
import * as React from 'react';
import { type ColorResult, type HexColor, SketchPicker } from 'react-color';
import styled, { css } from 'styled-components';

export type PopupPlacement = 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left';

export type ColorType = string | {| color: string, title: string |};

const Swatch = styled.div`
  position: relative;
  display: inline-block;
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Color = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background-color: ${props => props.color};
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
`;

const Picker = styled.div`
  position: absolute;
  z-index: 100;
  ${props => {
    switch (props.alignTo) {
      case 'bottom-right':
        return css`
          right: 0;
        `;
      case 'top-right':
        return css`
          bottom: 26px;
          right: 0;
        `;
      case 'top-left':
        return css`
          bottom: 26px;
        `;
      default:
        return css`
          left: auto;
        `;
    }
  }}
`;

export default class ColorPicker extends React.Component<
  {
    color: HexColor,
    onChangeComplete: HexColor => void,
    className?: ?string,
    renderSwatch?: (() => void) => React.Node,
    flagClass?: string,
    alignTo?: PopupPlacement,
    presetColors?: Array<ColorType>,
  },
  {
    open: boolean,
  },
> {
  state = {
    open: false,
  };

  static defaultProps = {
    alignTo: 'bottom-left',
  };

  handleChangeComplete = (colorResult: ColorResult) => {
    const { color, onChangeComplete } = this.props;
    const newColor = colorResult.hex;
    if (newColor === color) return;

    onChangeComplete(newColor);
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { className, flagClass, color, renderSwatch, alignTo, presetColors } = this.props;
    return (
      <div className={className}>
        {renderSwatch ? (
          renderSwatch(this.handleOpen)
        ) : (
          <Swatch onClick={this.handleOpen}>
            <Color color={color} />
          </Swatch>
        )}
        {this.state.open && (
          <div className={flagClass}>
            <Cover onClick={this.handleClose} />
            <Picker alignTo={alignTo}>
              <SketchPicker
                color={color}
                onChangeComplete={this.handleChangeComplete}
                disableAlpha
                presetColors={presetColors}
              />
            </Picker>
          </div>
        )}
      </div>
    );
  }
}
