/**
 * @flow
 * @relayHash 7f72d1b222b1ea25aef4459dde3896b7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BudgetReportingGroup = "BUDGET_CATEGORY" | "EVENT" | "EVENT_CREATOR" | "EVENT_LEADER" | "EVENT_REQUEST_FORM" | "EVENT_REQUEST_REVIEWER" | "EVENT_REQUEST_SUBMISSION_STATUS" | "EVENT_STATUS" | "LOCATION_COUNTRY" | "LOCATION_STATE" | "TEAM";
export type BudgetReportingSort = "ACTUAL_AMOUNT" | "ACTUAL_PLANNED" | "ACTUAL_TEAM_BUDGET" | "BUDGET_AMOUNT" | "NAME" | "PAID_ACTUAL" | "PAID_AMOUNT" | "PLANNED_AMOUNT";
export type Direction = "ASC" | "DESC";
export type EventFormat = "HYBRID" | "PHYSICAL" | "VIRTUAL";
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventRegistrationFormStatus = "DRAFT" | "NOT_AVAILABLE" | "PUBLISHED";
export type EventRequestSubmissionStatusFilter = "APPROVED" | "DECLINED" | "NEW" | "N_A" | "RESUBMITTED" | "RETURNED";
export type EventSort = "ATTENDED_CONTACTS_TOTAL" | "BOOTH" | "BOOTH_DIMENSIONS" | "CITY" | "COMMITMENT_LEVEL" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATOR" | "CUSTOM" | "END_DATE" | "EVENT_FORMAT" | "EVENT_LEAD" | "LAST_SYNCED" | "LOCATION" | "MARKETO_ID" | "NAME" | "OPPORTUNITIES_AMOUNT" | "OPPORTUNITIES_NUMBER" | "PROGRESS" | "REGISTERED_CONTACTS_TOTAL" | "REQUESTED_BY" | "REQUESTED_DATE" | "REQUESTED_FORM_NAME" | "REQUESTED_STATUS" | "SALESFORCE_ID" | "STAFF_COUNT" | "START_DATE" | "STATE" | "STREET" | "SYNC_STATUS" | "TEAM_NAME" | "TEMPLATES_FIRST" | "TOTAL_ACTUAL_COST" | "TOTAL_PAID_COST" | "TOTAL_PLANNED_COST" | "UPDATED_AT" | "UPDATER" | "VENUE_NAME" | "VIRTUAL_LOCATION" | "WEBSITE" | "ZIP_CODE";
export type EventStatus = "CANCELLED" | "COMMITTED" | "COMPLETED" | "CONSIDERING" | "POSTPONED" | "SKIPPING";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type EventFilters = {
  sort?: ?EventSort,
  direction?: ?Direction,
  customFieldSortId?: ?string,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  queries?: ?$ReadOnlyArray<string>,
  search?: ?Search,
  listType?: ?EventListEnum,
  afterDate?: ?any,
  beforeDate?: ?any,
  includeUndated?: ?boolean,
  excludeTemplates?: ?boolean,
  statuses?: ?$ReadOnlyArray<EventStatus>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  opportunitiesNumber?: ?NumberRangeInput,
  opportunitiesAmount?: ?NumberRangeInput,
  teamIds?: ?$ReadOnlyArray<string>,
  leadIds?: ?$ReadOnlyArray<string>,
  staffIds?: ?$ReadOnlyArray<string>,
  budgetCategoryIds?: ?$ReadOnlyArray<string>,
  fullAccessEvents?: ?boolean,
  onlyWithTasks?: ?boolean,
  venueNames?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  eventFormats?: ?$ReadOnlyArray<EventFormat>,
  eventIds?: ?$ReadOnlyArray<string>,
  excludeEventIds?: ?$ReadOnlyArray<string>,
  updatedAt?: ?DateTimeRange,
  updaterIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  creatorIds?: ?$ReadOnlyArray<string>,
  requesterUserIds?: ?$ReadOnlyArray<string>,
  requesterContactIds?: ?$ReadOnlyArray<string>,
  requestedDate?: ?DateTimeRange,
  requestStatuses?: ?$ReadOnlyArray<EventRequestSubmissionStatusFilter>,
  requestFormIds?: ?$ReadOnlyArray<string>,
  requestReviewers?: ?$ReadOnlyArray<string>,
  registeredContactsTotal?: ?NumberRangeInput,
  attendedContactsTotal?: ?NumberRangeInput,
  registrationFormStatuses?: ?$ReadOnlyArray<EventRegistrationFormStatus>,
  contactsCount?: ?NumberRangeInput,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type BudgetReportingGroupContainerQueryVariables = {|
  group1?: ?BudgetReportingGroup,
  group2?: ?BudgetReportingGroup,
  customGroup1?: ?string,
  customGroup2?: ?string,
  includeGroup2: boolean,
  filters: EventFilters,
  sort?: ?BudgetReportingSort,
  direction: Direction,
|};
export type BudgetReportingGroupContainerQueryResponse = {|
  +org: {|
    +budgetReport: {|
      +totals: {|
        +budgetedAmount: ?number,
        +plannedAmount: number,
        +actualAmount: number,
        +paidAmount: number,
      |},
      +groups: $ReadOnlyArray<{|
        +name: ?string,
        +budgetedAmount: ?number,
        +plannedAmount: number,
        +actualAmount: number,
        +paidAmount: number,
        +childGroups?: $ReadOnlyArray<{|
          +name: ?string,
          +budgetedAmount: ?number,
          +plannedAmount: number,
          +actualAmount: number,
          +paidAmount: number,
        |}>,
      |}>,
    |}
  |}
|};
export type BudgetReportingGroupContainerQuery = {|
  variables: BudgetReportingGroupContainerQueryVariables,
  response: BudgetReportingGroupContainerQueryResponse,
|};
*/


/*
query BudgetReportingGroupContainerQuery(
  $group1: BudgetReportingGroup
  $group2: BudgetReportingGroup
  $customGroup1: ID
  $customGroup2: ID
  $includeGroup2: Boolean!
  $filters: EventFilters!
  $sort: BudgetReportingSort
  $direction: Direction!
) {
  org {
    budgetReport(group1: $group1, group2: $group2, customGroup1: $customGroup1, customGroup2: $customGroup2, filters: $filters, sort: $sort, direction: $direction) {
      totals {
        budgetedAmount
        plannedAmount
        actualAmount
        paidAmount
      }
      groups {
        name
        budgetedAmount
        plannedAmount
        actualAmount
        paidAmount
        childGroups @include(if: $includeGroup2) {
          name
          budgetedAmount
          plannedAmount
          actualAmount
          paidAmount
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "group1",
    "type": "BudgetReportingGroup",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "group2",
    "type": "BudgetReportingGroup",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "customGroup1",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "customGroup2",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeGroup2",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "EventFilters!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "BudgetReportingSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "direction",
    "type": "Direction!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "budgetedAmount",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "plannedAmount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actualAmount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "paidAmount",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "budgetReport",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "customGroup1",
      "variableName": "customGroup1",
      "type": "ID"
    },
    {
      "kind": "Variable",
      "name": "customGroup2",
      "variableName": "customGroup2",
      "type": "ID"
    },
    {
      "kind": "Variable",
      "name": "direction",
      "variableName": "direction",
      "type": "Direction"
    },
    {
      "kind": "Variable",
      "name": "filters",
      "variableName": "filters",
      "type": "EventFilters!"
    },
    {
      "kind": "Variable",
      "name": "group1",
      "variableName": "group1",
      "type": "BudgetReportingGroup"
    },
    {
      "kind": "Variable",
      "name": "group2",
      "variableName": "group2",
      "type": "BudgetReportingGroup"
    },
    {
      "kind": "Variable",
      "name": "sort",
      "variableName": "sort",
      "type": "BudgetReportingSort"
    }
  ],
  "concreteType": "Report",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "totals",
      "storageKey": null,
      "args": null,
      "concreteType": "Totals",
      "plural": false,
      "selections": [
        v1,
        v2,
        v3,
        v4
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "groups",
      "storageKey": null,
      "args": null,
      "concreteType": "ParentGroup",
      "plural": true,
      "selections": [
        v5,
        v1,
        v2,
        v3,
        v4,
        {
          "kind": "Condition",
          "passingValue": true,
          "condition": "includeGroup2",
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "childGroups",
              "storageKey": null,
              "args": null,
              "concreteType": "ChildGroup",
              "plural": true,
              "selections": [
                v5,
                v1,
                v2,
                v3,
                v4
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BudgetReportingGroupContainerQuery",
  "id": null,
  "text": "query BudgetReportingGroupContainerQuery(\n  $group1: BudgetReportingGroup\n  $group2: BudgetReportingGroup\n  $customGroup1: ID\n  $customGroup2: ID\n  $includeGroup2: Boolean!\n  $filters: EventFilters!\n  $sort: BudgetReportingSort\n  $direction: Direction!\n) {\n  org {\n    budgetReport(group1: $group1, group2: $group2, customGroup1: $customGroup1, customGroup2: $customGroup2, filters: $filters, sort: $sort, direction: $direction) {\n      totals {\n        budgetedAmount\n        plannedAmount\n        actualAmount\n        paidAmount\n      }\n      groups {\n        name\n        budgetedAmount\n        plannedAmount\n        actualAmount\n        paidAmount\n        childGroups @include(if: $includeGroup2) {\n          name\n          budgetedAmount\n          plannedAmount\n          actualAmount\n          paidAmount\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BudgetReportingGroupContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v6
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BudgetReportingGroupContainerQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v6,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72f3f034cc75ffdbc5da6784d4907db0';
module.exports = node;
