/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import getBudgetQuarterString from 'utils/fiscal/getBudgetQuarterString';

import { type CellPropsType } from 'components/budget/Table';
import InlineSelectField from 'components/budget/Table/InlineSelectField';

import { UNCATEGORIZED_ID } from '../index';
import { type CategoryCellPropsType } from './index';

import { type CategoryNameCell_categoryEdge } from './__generated__/CategoryNameCell_categoryEdge.graphql';
import { type CategoryNameCell_event } from './__generated__/CategoryNameCell_event.graphql';
import { type CategoryNameCell_org } from './__generated__/CategoryNameCell_org.graphql';

const ManageLink = styled.a`
  color: #3ba9da;
`;

const preventDefault = (event: SyntheticEvent<HTMLElement>) => {
  event.preventDefault();
};

const CategoryNameCell = ({
  categoryEdge,
  categoryEdgeMock,
  org,
  event,
  adding,
  isAddingActive,
  rootElementRef,
  hasUncategorized,
  updateColumnWidth,
  changeAdding,
  changeCategoryEdge,
  removeAdding,
}: CellPropsType<
  CategoryCellPropsType<
    CategoryNameCell_categoryEdge,
    CategoryNameCell_event,
    CategoryNameCell_org,
  >,
>) => {
  const { budgetedAmount, node } = categoryEdge || categoryEdgeMock || {};

  if ((!categoryEdge || isAddingActive) && !adding) {
    return node.name || '';
  }

  const bugdetSettingsLink = `/settings/budgets/${getBudgetQuarterString(
    org.settings.fiscalYearStart,
  )}`;

  return (
    <InlineSelectField
      options={[
        ...sortBy(
          org.budgetCategories.edges.map(edge => edge.node),
          'name',
        )
          .filter(category =>
            event.budgetCategories.edges.every(edge => edge.node.id !== category.id),
          )
          .map(category => ({
            label: category.name,
            value: category.id,
            info: category.description,
          })),
        ...(hasUncategorized ? [] : [{ value: UNCATEGORIZED_ID, label: 'Uncategorized' }]),
        ...(org.viewerCanUpdate
          ? [
              {
                label: 'Manage Categories',
                value: 'manage',
                displayLabel: (
                  <ManageLink href={bugdetSettingsLink} target="_blank" onClick={preventDefault}>
                    Manage Categories
                  </ManageLink>
                ),
              },
            ]
          : []),
      ]}
      value={node.id}
      searchable
      placeholder="Select Category"
      onChange={categoryId => {
        if (!categoryId) return;

        if (categoryId === 'manage') {
          window.open(bugdetSettingsLink);

          return;
        }

        if (adding) {
          changeAdding({ categoryId });
          updateColumnWidth();
        } else {
          changeCategoryEdge(categoryId);
        }

        const rootElement = rootElementRef.current;

        if (!budgetedAmount && rootElement) {
          const focusElement =
            rootElement.querySelectorAll('input')[1] || rootElement.querySelector('[tabindex]');

          if (focusElement) focusElement.focus();
        }
      }}
      onHideOptions={() => {
        if (!node.id) {
          removeAdding();
        }
      }}
      defaultEditing={adding || false}
    >
      {adding ? null : node.name}
    </InlineSelectField>
  );
};

export default createFragmentContainer(
  CategoryNameCell,
  graphql`
    fragment CategoryNameCell_categoryEdge on EventBudgetCategoryEdge {
      budgetedAmount

      node {
        id
        name
      }
    }

    fragment CategoryNameCell_event on Event {
      budgetCategories {
        edges {
          node {
            id
          }
        }
      }
    }

    fragment CategoryNameCell_org on Org {
      viewerCanUpdate

      settings {
        fiscalYearStart
      }

      budgetCategories {
        edges {
          node {
            id
            name
            description
          }
        }
      }
    }
  `,
);
