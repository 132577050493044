/**
 * @flow
 * @relayHash badb515492dfcc80aad2a0857aaeb556
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VendorSearchRow_vendor$ref = any;
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type VendorSort = "CITY" | "COMPANY_PHONE" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "FIRST_NAME" | "LAST_NAME" | "LINKEDIN" | "NAME" | "PHONE1" | "PHONE2" | "STATE" | "STREET" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "WEBSITE" | "ZIP";
export type VendorFilters = {
  query?: ?string,
  vendorIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?VendorSort,
  direction?: ?Direction,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type VendorSearchQueryVariables = {|
  eventId: string,
  includeEvent: boolean,
  filters: VendorFilters,
|};
export type VendorSearchQueryResponse = {|
  +org: {|
    +id: string,
    +viewerCanCreateVendors?: boolean,
    +vendors: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
          +$fragmentRefs: VendorSearchRow_vendor$ref,
        |}
      |}>
    |},
  |},
  +event?: ?{|
    +viewerCanCreateVendors?: boolean
  |},
|};
export type VendorSearchQuery = {|
  variables: VendorSearchQueryVariables,
  response: VendorSearchQueryResponse,
|};
*/


/*
query VendorSearchQuery(
  $eventId: ID!
  $includeEvent: Boolean!
  $filters: VendorFilters!
) {
  org {
    id
    viewerCanCreateVendors @skip(if: $includeEvent)
    vendors(first: 3, filters: $filters) {
      edges {
        node {
          id
          name
          ...VendorSearchRow_vendor
        }
      }
    }
  }
  event: node(id: $eventId) @include(if: $includeEvent) {
    __typename
    ... on Event {
      viewerCanCreateVendors
    }
    id
  }
}

fragment VendorSearchRow_vendor on Vendor {
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeEvent",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "VendorFilters!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters",
    "type": "VendorFilters"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "viewerCanCreateVendors",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "Condition",
  "passingValue": false,
  "condition": "includeEvent",
  "selections": v4
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId",
    "type": "ID!"
  }
],
v7 = {
  "kind": "InlineFragment",
  "type": "Event",
  "selections": v4
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "VendorSearchQuery",
  "id": null,
  "text": "query VendorSearchQuery(\n  $eventId: ID!\n  $includeEvent: Boolean!\n  $filters: VendorFilters!\n) {\n  org {\n    id\n    viewerCanCreateVendors @skip(if: $includeEvent)\n    vendors(first: 3, filters: $filters) {\n      edges {\n        node {\n          id\n          name\n          ...VendorSearchRow_vendor\n        }\n      }\n    }\n  }\n  event: node(id: $eventId) @include(if: $includeEvent) {\n    __typename\n    ... on Event {\n      viewerCanCreateVendors\n    }\n    id\n  }\n}\n\nfragment VendorSearchRow_vendor on Vendor {\n  name\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "VendorSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vendors",
            "storageKey": null,
            "args": v2,
            "concreteType": "VendorRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VendorRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Vendor",
                    "plural": false,
                    "selections": [
                      v1,
                      v3,
                      {
                        "kind": "FragmentSpread",
                        "name": "VendorSearchRow_vendor",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v5
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "event",
            "name": "node",
            "storageKey": null,
            "args": v6,
            "concreteType": null,
            "plural": false,
            "selections": [
              v7
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VendorSearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vendors",
            "storageKey": null,
            "args": v2,
            "concreteType": "VendorRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VendorRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Vendor",
                    "plural": false,
                    "selections": [
                      v1,
                      v3
                    ]
                  }
                ]
              }
            ]
          },
          v5
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "includeEvent",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "event",
            "name": "node",
            "storageKey": null,
            "args": v6,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              v1,
              v7
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3fa75b276aee20976b56bbc225d0fc9';
module.exports = node;
