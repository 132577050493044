/* @flow */
import React from 'react';

const ContactUsLink = () => (
  <a href="mailto:success@circa.co" rel="noopener noreferrer" target="_blank">
    contact us
  </a>
);

export default ContactUsLink;
