/* @flow */
import parseTypedQueryString, {
  type SortParam,
  stringParamToSort,
} from 'utils/routing/parseTypedQueryString';

export type ParsedTeamMembersFilters = {
  sort: ?SortParam,
};

export default function parseTeamMembersFilters(queryString: string): ParsedTeamMembersFilters {
  return parseTypedQueryString(queryString, {
    sort: stringParamToSort,
  });
}
