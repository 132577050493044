/* @flow */

import React from 'react';
import styled from 'styled-components';

const Email = styled.a`
  display: block;
  color: ${props => props.theme.primaryActionColor};
`;

const BriefUserEmail = (props: { email: ?string }) => {
  const { email } = props;
  return email ? <Email href={`mailto:${email}`}>{email}</Email> : null;
};

export default BriefUserEmail;
