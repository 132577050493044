/* @flow */

const paymentTypes = {
  Full: 'Full',
  Deposit: 'Deposit',
  Down: 'Down',
  Balance: 'Balance',
  Final: 'Final',
  Other: 'Other',
};

export type PaymentType = $Keys<typeof paymentTypes>;

export default paymentTypes;
