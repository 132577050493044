/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';
import escapeRegexString from 'utils/escapeRegexString';

import Overlay from 'components/material/Overlay';
import TextInput from 'components/material/TextInput';

import emojisObj from 'config/emojis.json';

const StyledOverlay = styled(Overlay)`
  display: flex;
  flex-wrap: wrap;
  width: 210px;
  ${props =>
    props.position &&
    css`
      top: 0 !important;
      left: ${props.position.left}px !important;
    `};
`;

const Emoji = styled.div`
  font-size: 22px;
  padding: 0 5px;
  cursor: pointer;
  flex: 1 1 auto;
  text-align: center;
  &:hover {
    background: ${props => props.theme.hoverRowColor};
  }
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
`;

const initialSet = ['+1', '-1', '100', 'heart_eyes', 'laughing', 'joy'];

export default class RichTextHeaderOverlay extends React.PureComponent<
  {
    target: any,
    container: any,
    show: boolean,
    onHide: () => void,
    onInsert: string => void,
    position?: { top: number, left: number },
    query?: string,
    buttonTriggered?: boolean,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleEmojiSelect = (emoji: string, e: SyntheticEvent<>) => {
    e.preventDefault();

    this.props.onHide();

    this.props.onInsert(emoji);
  };

  handleInputChange = (e: SyntheticInputEvent<>) => {
    this.setState({ query: e.target.value });
  };

  handleHide = () => {
    this.props.onHide();

    this.setState({ query: '' });
  };

  handleInputClick = (e: SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.currentTarget.focus();
  };

  queryEmojis(): $ReadOnlyArray<string> {
    const regexp = new RegExp(escapeRegexString(this.props.query || this.state.query), 'i');

    return Object.keys(emojisObj).filter(name => regexp.test(name));
  }

  render() {
    const emojis =
      this.state.query.trim() || (this.props.query && this.props.query.trim())
        ? this.queryEmojis()
        : initialSet;

    if (emojis.length === 0 && !this.props.buttonTriggered) return null;

    return (
      <StyledOverlay
        target={this.props.target}
        container={this.props.container}
        show={this.props.show}
        onHide={this.handleHide}
        position={this.props.position}
      >
        {!{}.hasOwnProperty.call(this.props, 'query') && (
          <StyledTextInput
            autoFocus
            placeholder="Search Emoji"
            name="emoji"
            onChange={this.handleInputChange}
            onClick={this.handleInputClick}
          />
        )}
        {emojis.slice(0, 6).map(name => {
          const code = emojisObj[name];
          const emoji = String.fromCodePoint(parseInt(code, 16));

          return (
            <Emoji
              key={name}
              title={`:${name}:`}
              onMouseDown={e => this.handleEmojiSelect(emoji, e)}
            >
              {emoji}
            </Emoji>
          );
        })}
      </StyledOverlay>
    );
  }
}
