/* @flow */
import type { FieldType } from 'utils/customization/types';
import getCustomFieldQueryParamsByKind from 'utils/filters/getCustomFieldQueryParamsByKind';
import parseTypedQueryString, {
  type CustomFieldParam,
  type DateRangeParam,
  type SearchParam,
  type SortParam,
  stringParamToBoolean,
  stringParamToDateRange,
  stringParamToEnumArray,
  stringParamToSearch,
  stringParamToSort,
  stringParamToStringArray,
} from 'utils/routing/parseTypedQueryString';

import contactCreateMethods from 'components/Contacts/enums/contactCreateMethods';
import salesforceSyncAsOptions from 'components/Contacts/enums/salesforceSyncAsOptions';
import salesforceSyncStatuses from 'components/Contacts/enums/salesforceSyncStatuses';

export type ParsedContactsFilters = {
  [key: string]: CustomFieldParam,

  created_at: ?DateRangeParam,
  created_by: ?$ReadOnlyArray<string>,
  created_method: ?$ReadOnlyArray<string>,
  updated_at: ?DateRangeParam,
  updated_by: ?$ReadOnlyArray<string>,
  updated_method: ?$ReadOnlyArray<string>,
  contact_type_id: ?$ReadOnlyArray<string>,
  owner_id: ?$ReadOnlyArray<string>,
  company_id: ?$ReadOnlyArray<string>,
  owner: ?$ReadOnlyArray<string>,
  city: ?$ReadOnlyArray<string>,
  state: ?$ReadOnlyArray<string>,
  country: ?$ReadOnlyArray<string>,
  hot_lead: ?boolean,
  email_opt_in: ?boolean,
  salesforce_sync_as: ?$ReadOnlyArray<string>,
  salesforce_lead_status_id: ?$ReadOnlyArray<string>,
  sync_status: ?$ReadOnlyArray<string>,
  membership_sync_status: ?$ReadOnlyArray<string>,
  registered_at: ?DateRangeParam,
  registration_status_id: ?$ReadOnlyArray<string>,
  attendance_status_id: ?$ReadOnlyArray<string>,
  sort: ?SortParam,
  search: ?SearchParam,
};

function getFieldOptions(filterOptions: $ReadOnlyArray<FieldType>, fieldName: string) {
  const filterOption = filterOptions.find(filter => filter.fieldName === fieldName);
  return (filterOption && filterOption.options.map(option => option.value)) || [];
}

export default function parseContactsFilters(
  queryString: string,
  filterOptions: $ReadOnlyArray<FieldType>,
): ParsedContactsFilters {
  return parseTypedQueryString(queryString, {
    ...filterOptions.reduce(
      (customFilters, filter) =>
        filter.kind !== 'DEFAULT'
          ? { ...customFilters, ...getCustomFieldQueryParamsByKind(filter) }
          : customFilters,
      {},
    ),

    created_at: stringParamToDateRange,
    created_by: stringParamToStringArray,
    created_method: stringParamToEnumArray(contactCreateMethods.map(method => method.value)),
    updated_at: stringParamToDateRange,
    updated_by: stringParamToStringArray,
    updated_method: stringParamToEnumArray(contactCreateMethods.map(method => method.value)),
    contact_type_id: stringParamToEnumArray(getFieldOptions(filterOptions, 'contact_type_id')),
    owner_id: stringParamToStringArray,
    company_id: stringParamToStringArray,
    owner: stringParamToStringArray,
    city: stringParamToStringArray,
    state: stringParamToStringArray,
    country: stringParamToStringArray,
    hot_lead: stringParamToBoolean,
    email_opt_in: stringParamToBoolean,
    salesforce_sync_as: stringParamToEnumArray(salesforceSyncAsOptions.map(option => option.value)),
    salesforce_lead_status_id: stringParamToStringArray,
    sync_status: stringParamToEnumArray(salesforceSyncStatuses.map(option => option.value)),
    membership_sync_status: stringParamToEnumArray(
      salesforceSyncStatuses.map(option => option.value),
    ),
    registered_at: stringParamToDateRange,
    registration_status_id: stringParamToStringArray,
    attendance_status_id: stringParamToStringArray,
    sort: stringParamToSort,
    search: stringParamToSearch,
  });
}
