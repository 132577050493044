/* @flow */
import { intersection, isEqual } from 'lodash';
import moment from 'moment-timezone';

import { type FilterItem, getKind } from 'components/material/Filters/FilterSelectorRow';

const evaluateNumberRange = (
  value: number,
  minValue: ?number | string,
  maxValue: ?number | string,
) => {
  if (minValue != null && maxValue != null) {
    return Number(minValue) <= value && Number(maxValue) >= value;
  }
  if (minValue != null) {
    return Number(minValue) <= value;
  }
  if (maxValue != null) {
    return Number(maxValue) >= value;
  }
  return false;
};

const validateSelectFilters = (condition: FilterItem, values: mixed, kind: string) => {
  const arrayValues = Array.isArray(values) ? values : [values].filter(Boolean);
  const fixedTypeValues = ((arrayValues: any): $ReadOnlyArray<{ id: string }>);
  const selectedIds = fixedTypeValues.map(({ id }) => id);

  const optionIds: $ReadOnlyArray<string> = condition.optionIds || [];
  const userIds: $ReadOnlyArray<string> = Array.isArray(condition.users)
    ? condition.users.map(user => user.id)
    : [];

  const conditionOptionIds: $ReadOnlyArray<string> = kind.includes('USER') ? userIds : optionIds;
  switch (condition.operator) {
    case 'equal':
      return isEqual(selectedIds, conditionOptionIds);
    case 'not_equal':
      return !isEqual(selectedIds, conditionOptionIds);
    case 'in':
      if (conditionOptionIds != null) {
        return intersection(selectedIds, conditionOptionIds).length > 0;
      }
      return false;
    case 'not_in':
      return intersection(selectedIds, conditionOptionIds).length === 0;
    case 'is_null':
      return selectedIds.length === 0;
    case 'is_not_null':
      return selectedIds.length > 0;
    default:
      return false;
  }
};

const validateCondition = (condition: FilterItem, values: mixed, tz: string) => {
  const kind = getKind(condition);

  const conditionValue = condition.values ? condition.values[0] : '';

  switch (kind) {
    case 'TEXT':
    case 'TEXTAREA':
    case 'LINK':
      switch (condition.operator) {
        case 'equal':
          return conditionValue === values;
        case 'not_equal':
          return conditionValue !== values;
        case 'text_in':
          if (
            condition.customField &&
            ['country', 'state'].includes(condition.customField.fieldName)
          ) {
            return values != null && (condition.values || []).some(cValue => cValue === values);
          }

          return values != null && String(values).includes(conditionValue);
        case 'text_not_in':
          if (
            condition.customField &&
            ['country', 'state'].includes(condition.customField.fieldName)
          ) {
            return values == null || !(condition.values || []).some(cValue => cValue === values);
          }

          return values != null && !String(values).includes(conditionValue);
        case 'text_starts_with':
          return String(values).startsWith(conditionValue);
        case 'text_ends_with':
          return String(values).endsWith(conditionValue);
        case 'is_null':
          return values == null || String(values).trim() === '';
        case 'is_not_null':
          return values != null && !String(values).includes(conditionValue);
        default:
          return false;
      }
    case 'BOOLEAN':
      return condition.value === (values != null ? values : false);
    case 'NUMBER':
    case 'CURRENCY':
      return evaluateNumberRange(
        values != null ? Number(values) : 0,
        condition.minValue,
        condition.maxValue,
      );
    case 'DATE':
      if (values == null) {
        return false;
      }
      return (
        moment.tz(values, tz).isSameOrAfter(moment.tz(condition.minValue, tz), 'day') &&
        moment.tz(values, tz).isSameOrBefore(moment.tz(condition.maxValue, tz), 'day')
      );
    case 'SELECT':
    case 'MULTISELECT':
    case 'USER_SELECT':
    case 'USER_MULTISELECT':
      return validateSelectFilters(condition, values, kind);
    default:
      return false;
  }
};

export default validateCondition;
