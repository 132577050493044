/* @flow */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import EventBriefTemplate from './EventBriefTemplate';

export default class BriefRoutes extends React.Component<{
  pathPrefix: string,
}> {
  render() {
    const pathPrefix = this.props.pathPrefix;

    return (
      <Switch>
        <Route path={`${pathPrefix}/event_brief`} component={EventBriefTemplate} />
        <Redirect from={pathPrefix} to={`${pathPrefix}/event_brief`} />
      </Switch>
    );
  }
}
