/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScheduledEmailTemplateFilterTooltip_eventEmailTemplate$ref: FragmentReference;
export type ScheduledEmailTemplateFilterTooltip_eventEmailTemplate = {|
  +id: string,
  +customSavedTextFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +values: ?$ReadOnlyArray<string>,
        +operator: Operator,
        +order: number,
        +fieldName: ?string,
        +customField: ?{|
          +label: string,
          +customizableType: CustomizableType,
        |},
      |}
    |}>
  |},
  +customSavedTextareaFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +values: ?$ReadOnlyArray<string>,
        +operator: Operator,
        +order: number,
        +fieldName: ?string,
        +customField: ?{|
          +label: string,
          +customizableType: CustomizableType,
        |},
      |}
    |}>
  |},
  +customSavedLinkFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +values: ?$ReadOnlyArray<string>,
        +operator: Operator,
        +order: number,
        +customField: ?{|
          +label: string,
          +customizableType: CustomizableType,
        |},
      |}
    |}>
  |},
  +customSavedNumberFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +minValue: ?number,
        +maxValue: ?number,
        +order: number,
        +customField: {|
          +label: string,
          +customizableType: CustomizableType,
        |},
      |}
    |}>
  |},
  +customSavedCurrencyFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +minValue: ?number,
        +maxValue: ?number,
        +order: number,
        +customField: ?{|
          +label: string,
          +customizableType: CustomizableType,
        |},
      |}
    |}>
  |},
  +customSavedDateFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +minValue: ?any,
        +maxValue: ?any,
        +order: number,
        +customField: {|
          +label: string,
          +customizableType: CustomizableType,
        |},
      |}
    |}>
  |},
  +customSavedBooleanFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +value: boolean,
        +order: number,
        +customField: {|
          +label: string,
          +customizableType: CustomizableType,
        |},
      |}
    |}>
  |},
  +customSavedUserMultiselectFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +operator: Operator,
        +order: number,
        +customField: ?{|
          +id: string,
          +label: string,
          +customizableType: CustomizableType,
        |},
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +user: {|
                +firstName: string,
                +lastName: string,
              |}
            |}
          |}>
        |},
      |}
    |}>
  |},
  +customSavedMultiselectFilters: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +operator: Operator,
        +order: number,
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +option: {|
                +name: string
              |}
            |}
          |}>
        |},
        +customField: {|
          +label: string,
          +customizableType: CustomizableType,
        |},
      |}
    |}>
  |},
  +$refType: ScheduledEmailTemplateFilterTooltip_eventEmailTemplate$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "values",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "operator",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "customizableType",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": [
    v4,
    v5
  ]
},
v7 = [
  v1,
  v2,
  v3,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "fieldName",
    "args": null,
    "storageKey": null
  },
  v6
],
v8 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "minValue",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "maxValue",
    "args": null,
    "storageKey": null
  },
  v3,
  v6
];
return {
  "kind": "Fragment",
  "name": "ScheduledEmailTemplateFilterTooltip_eventEmailTemplate",
  "type": "EventEmailTemplate",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedTextFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedTextFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedTextFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedTextFilterType",
              "plural": false,
              "selections": v7
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedTextareaFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedTextareaFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedTextareaFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedTextareaFilterType",
              "plural": false,
              "selections": v7
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedLinkFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedLinkFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedLinkFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedLinkFilterType",
              "plural": false,
              "selections": [
                v1,
                v2,
                v3,
                v6
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedNumberFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedNumberFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedNumberFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedNumberFilterType",
              "plural": false,
              "selections": v8
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedCurrencyFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedCurrencyFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedCurrencyFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedCurrencyFilterType",
              "plural": false,
              "selections": v8
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedDateFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedDateFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedDateFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedDateFilterType",
              "plural": false,
              "selections": v8
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedBooleanFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedBooleanFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedBooleanFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedBooleanFilterType",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "value",
                  "args": null,
                  "storageKey": null
                },
                v3,
                v6
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedUserMultiselectFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedUserMultiselectFilterType",
              "plural": false,
              "selections": [
                v2,
                v3,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customField",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldType",
                  "plural": false,
                  "selections": [
                    v0,
                    v4,
                    v5
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "options",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedUserMultiselectFilterOptionType",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "user",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "User",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "firstName",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "lastName",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSavedMultiselectFilters",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomSavedMultiselectFilterTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomSavedMultiselectFilterTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedMultiselectFilterType",
              "plural": false,
              "selections": [
                v2,
                v3,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "options",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedMultiselectFilterOptionType",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "option",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomFieldOptionType",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "name",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                v6
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e578c25a4b9bfc0b035090670481e7bb';
module.exports = node;
