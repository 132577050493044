/* @flow */
import React from 'react';

import { type StepPageComponentType } from 'components/integrations/IntegrationSetupStepper';

import SalesforceAuth from '../SalesforceAuth';
import SalesforceMapping from '../SalesforceMapping';
import SalesforceSettings from '../SalesforceSettings';

const getSalesforceComponentsConfig = (
  contactsSyncEnabled: boolean,
  opportunitiesSyncEnabled: boolean,
): StepPageComponentType[] =>
  [
    {
      label: 'Authentication',
      stepTitle: 'Connect your Salesforce Account',
      path: 'auth',
      component: <P: {}>(props: P) => <SalesforceAuth {...props} />,
    },
    {
      label: 'Campaigns',
      stepTitle: 'Map Circa Event fields with Salesforce Campaign fields',
      path: 'mapping/event',
      component: <P: {}>(props: P) => <SalesforceMapping {...props} kinds={['EVENT']} />,
    },
    ...(contactsSyncEnabled
      ? [
          {
            label: 'Leads',
            stepTitle: 'Map Salesforce Lead fields with Circa Contacts fields',
            path: 'mapping/lead',
            component: <P: {}>(props: P) => <SalesforceMapping {...props} kinds={['LEAD']} />,
          },
          {
            label: 'Contacts',
            stepTitle: 'Map Salesforce Contact fields with Circa Contacts fields',
            path: 'mapping/contact',
            component: <P: {}>(props: P) => <SalesforceMapping {...props} kinds={['CONTACT']} />,
          },
        ]
      : []),
    ...(opportunitiesSyncEnabled
      ? [
          {
            label: 'Opportunities',
            stepTitle: 'Map Salesforce Opportunity fields with Circa Contacts fields',
            path: 'mapping/opportunity',
            component: <P: {}>(props: P) => (
              <SalesforceMapping {...props} kinds={['OPPORTUNITY']} systemFieldsToggle />
            ),
          },
        ]
      : []),
    ...(contactsSyncEnabled
      ? [
          {
            label: 'Campaign Members',
            stepTitle: 'Map Salesforce Campaign Member fields with Circa Contact Attendance fields',
            path: 'mapping/event-contact',
            component: <P: {}>(props: P) => (
              <SalesforceMapping {...props} kinds={['EVENT_CONTACT']} />
            ),
          },
        ]
      : []),
    ...(contactsSyncEnabled
      ? [
          {
            label: 'Settings',
            stepTitle: 'Select default Salesforce object to sync your Circa Contacts',
            path: 'mapping/company',
            component: <P: {}>(props: P) => <SalesforceSettings {...props} />,
          },
        ]
      : []),
  ].filter(Boolean);

export default getSalesforceComponentsConfig;
