/* @flow */
import { graphql } from 'react-relay';
import omit from 'lodash/omit';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateEmailSubscriptionMutationResponse,
  updateEmailSubscriptionMutationVariables,
} from './__generated__/updateEmailSubscriptionMutation.graphql';

const mutation = graphql`
  mutation updateEmailSubscriptionMutation($input: UpdateEmailSubscriptionInput!) {
    updateEmailSubscription(input: $input) {
      user {
        id
        emailSubscription {
          activitySummary
          eventScope
          assignmentNotifications
          activityNotifications
          mentionNotifications
          reminders
          postEventEngagementReport
          contactRegistrationNotifications
          contactAttendanceNotifications
          addEventStaffNotifications
        }
      }
    }
  }
`;

type Variables = $PropertyType<updateEmailSubscriptionMutationVariables, 'input'>;

export default function updateEmailSubscription(
  input: Variables,
): Promise<updateEmailSubscriptionMutationResponse> {
  const variables: updateEmailSubscriptionMutationVariables = {
    input,
  };

  const optimisticResponse: updateEmailSubscriptionMutationResponse = {
    updateEmailSubscription: {
      user: {
        id: input.userId,
        emailSubscription: omit(input, 'userId'),
      },
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
  });
}
