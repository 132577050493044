/* @flow */

import CompanyName from './CompanyName';
import CompanyPhone from './CompanyPhone';
import CompanyWebsite from './CompanyWebsite';
import CompanyTwitter from './CompanyTwitter';
import CompanyLinkedin from './CompanyLinkedin';
import CompanyUpdater from './CompanyUpdater';
import CompanyUpdatedAt from './CompanyUpdatedAt';
import CompanyCreator from './CompanyCreator';
import CompanyContactsCount from './CompanyContactsCount';
import CompanyCreatedAt from './CompanyCreatedAt';
import CompanySalesforceId from './CompanySalesforceId';
import CompanyStreet from './CompanyStreet';
import CompanyCity from './CompanyCity';
import CompanyState from './CompanyState';
import CompanyZip from './CompanyZip';
import CompanyCountry from './CompanyCountry';
import CompanyDescription from './CompanyDescription';
import CompanyActionCell from './CompanyActionCell';

export {
  CompanyName,
  CompanyDescription,
  CompanyPhone,
  CompanyTwitter,
  CompanyLinkedin,
  CompanyUpdater,
  CompanyUpdatedAt,
  CompanyCreator,
  CompanyContactsCount,
  CompanyCreatedAt,
  CompanySalesforceId,
  CompanyCountry,
  CompanyState,
  CompanyCity,
  CompanyStreet,
  CompanyZip,
  CompanyWebsite,
  CompanyActionCell,
};

export const defaultTableComponents = {
  name: CompanyName,
  website: CompanyWebsite,
  salesforce_id: CompanySalesforceId,
  street: CompanyStreet,
  zip: CompanyZip,
  city: CompanyCity,
  country: CompanyCountry,
  state: CompanyState,
  twitter: CompanyTwitter,
  linkedin: CompanyLinkedin,
  description: CompanyDescription,
  phone: CompanyPhone,
  updated_at: CompanyUpdatedAt,
  updated_by: CompanyUpdater,
  created_at: CompanyCreatedAt,
  created_by: CompanyCreator,
  contacts_count: CompanyContactsCount,
};
