/* @flow */

export const isBright = (color: string) => {
  if (color === 'transparent') {
    return true;
  }

  if (!/^#([A-Fa-f0-9]{6})/.test(color)) {
    throw new Error('Wrong color code');
  }

  const red = parseInt(color.slice(1, 3), 16);
  const green = parseInt(color.slice(3, 5), 16);
  const blue = parseInt(color.slice(5, 7), 16);
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
  return brightness > 190;
};

export default { isBright };
