/* @flow */

import * as React from 'react';
import styled from 'styled-components';

import BriefViewSharedContext from '../BriefViewSharedContext';

const Content = styled.div`
  padding-top: 25px;
  @media (max-width: 480px) {
    padding-top: 20px;
  }
`;

const SectionContent = (props: { children: React.Node }) => {
  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => <Content isShared={sharedView}>{props.children}</Content>}
    </BriefViewSharedContext.Consumer>
  );
};

export default SectionContent;
