/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import { NoSearchResultsMessage } from './components';
import ContactsSearchPagination from './ContactsSearchPagination';
import type { ContentComponentProps } from './SearchOverlay';

import type { ContactsSearchContentQueryResponse } from './__generated__/ContactsSearchContentQuery.graphql';

const searchQuery = graphql`
  query ContactsSearchContentQuery($search: Search!, $count: Int!, $cursor: String) {
    contactsTotalCount: searchContacts(search: $search) {
      totalCount
    }
    ...ContactsSearchPagination_searchResults
  }
`;

export default class ContactsSearchContent extends React.PureComponent<ContentComponentProps> {
  handleShowAllResults = () => {
    const { query, exactMatch } = this.props;
    const searchString = `${query}${exactMatch ? '~EXACT' : ''}`;
    window.open(`/contacts/people?search=${searchString}`, '_blank');
  };

  render() {
    const { query, exactMatch } = this.props;

    const variables = {
      search: {
        search: query,
        exactMatch,
      },
      count: 10,
    };

    return (
      <DefaultQueryRenderer
        query={searchQuery}
        variables={variables}
        renderSuccess={(response: ?ContactsSearchContentQueryResponse) => {
          if (response == null) {
            return null;
          }

          if (response.contactsTotalCount.totalCount === 0) {
            return <NoSearchResultsMessage query={query} />;
          }

          return (
            <ContactsSearchPagination
              mobileView
              query={query}
              searchResults={response}
              totalCount={response.contactsTotalCount.totalCount}
              onShowAllResults={this.handleShowAllResults}
              isolated
            />
          );
        }}
      />
    );
  }
}
