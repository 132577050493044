/* @flow */
import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import styled, { keyframes } from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import { type EventCellPropsType } from 'components/AllEvents/eventsTableColumnSettings';
import Table, { type ColumnType } from 'components/budget/Table';
import InfiniteScrollListener from 'components/InfiniteScrollListener';
import InfiniteScrollLoading from 'components/InfiniteScrollLoading';
import { type ColumnConfiguration } from 'components/material/table';
import NoResultsMessage from 'components/NoResultsMessage';
import ScrollbarSizes from 'components/ScrollbarSizes';
import StickyTotal from 'components/StickyTotal';

import type { SharedEventsTablePagination_eventList } from './__generated__/SharedEventsTablePagination_eventList.graphql';
import type { SharedEventsTablePagination_org } from './__generated__/SharedEventsTablePagination_org.graphql';

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const AllEventsContentContainer = styled.div`
  min-width: ${props => props.minWidth}px;
  animation: ${fadeIn} 0.15s;
`;

const TableWrapper = styled.div`
  max-height: 100%;
`;

class SharedEventsTablePagination extends React.Component<
  {
    eventList?: SharedEventsTablePagination_eventList,
    org: SharedEventsTablePagination_org,
    relay: RelayPaginationProp,
    filtered: boolean,
    shownColumns: ColumnConfiguration,
    sort: SortParam,
    includedColumns: { [string]: boolean },
    onChangeSort: (sort: SortParam) => void,
    className?: boolean,
    scrollContainer: HTMLElement,
    userEmail?: ?string,
    pageSize: number,
    linkEventNameToBrief: boolean,
  },
  {
    refetching: boolean,
    vScrollbarSize: number,
    hScrollbarSize: number,
  },
> {
  state = {
    refetching: false,
    vScrollbarSize: 0,
    hScrollbarSize: 0,
  };

  paginationDisposable: ?Disposable;

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  cellProps = (
    event,
    _group,
    column?: ColumnType<any, any>,
  ): EventCellPropsType<
    SharedEventsTablePagination_org,
    ?{},
    {|
      +id: string,
      +$fragmentRefs: any,
      +tz: string,
    |},
  > => {
    const { org, shownColumns, userEmail, linkEventNameToBrief } = this.props;
    const columnsLength = shownColumns.length;
    return {
      org,
      user: null,
      event,
      customizable: event,
      fieldSettings: column != null ? column.fieldSettings : null,
      linkEventNameToBrief,
      readOnly: true,
      userEmail,
      tz: event.tz,
      isLast:
        column != null && column.fieldSettings != null
          ? shownColumns[columnsLength - 2] &&
            shownColumns[columnsLength - 2].fieldSettings &&
            shownColumns[columnsLength - 2].fieldSettings.id === column.fieldSettings.id
          : false,
      salesforceHost: org != null && org.salesforceAccount ? org.salesforceAccount.host : null,
    };
  };

  keyExtractor = (event: {| +id: string, +$fragmentRefs: any, +tz: string |}): string => {
    return event.id;
  };

  handleLoadMore = amount => {
    const { eventList, relay } = this.props;
    if (!eventList) {
      return;
    }
    if (this.state.refetching || !eventList.events.pageInfo.hasNextPage) {
      return;
    }
    this.setState({ refetching: true });

    this.paginationDisposable = relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  handleScrollToBottom = () => {
    this.handleLoadMore(this.props.pageSize);
  };

  handleScrollbarSizesChange = (sizes: { vScrollbarSize: number, hScrollbarSize: number }) => {
    this.setState(sizes);
  };

  render() {
    const {
      eventList,
      className,
      shownColumns,
      sort,
      onChangeSort,
      filtered,
      scrollContainer,
      pageSize,
    } = this.props;

    const eventNodes =
      eventList != null ? eventList.events.edges.map(({ node }) => node).filter(Boolean) : null;
    const totalCount = eventList ? eventList.events.totalCount : 0;
    return (
      <AllEventsContentContainer className={className}>
        {(!eventNodes || eventNodes.length > 0) && (
          <TableWrapper>
            <Table
              data={eventNodes}
              columns={shownColumns}
              sort={sort}
              onChangeSort={onChangeSort}
              cellProps={this.cellProps}
              keyExtractor={this.keyExtractor}
            />
          </TableWrapper>
        )}
        {eventNodes && eventNodes.length === 0 && (
          <NoResultsMessage
            iconName="calendar-o"
            message={filtered ? 'No events match filters' : 'No events here yet'}
          />
        )}
        {scrollContainer && (
          <>
            <InfiniteScrollListener
              scrollContainer={scrollContainer}
              onScrollToBottom={this.handleScrollToBottom}
              totalCount={totalCount}
              pageSize={pageSize}
            />
            <InfiniteScrollLoading loading={this.state.refetching} />
            <StickyTotal
              total={totalCount}
              vScrollbarSize={this.state.vScrollbarSize}
              hScrollbarSize={this.state.hScrollbarSize}
            />
            <ScrollbarSizes
              shouldUpdate={eventNodes && eventNodes.length}
              scrollContainer={scrollContainer}
              onScrollbarSizesChange={this.handleScrollbarSizesChange}
              vScrollbarSize={this.state.vScrollbarSize}
              hScrollbarSize={this.state.hScrollbarSize}
            />
          </>
        )}
      </AllEventsContentContainer>
    );
  }
}

export default createPaginationContainer(
  SharedEventsTablePagination,
  {
    org: graphql`
      fragment SharedEventsTablePagination_org on Org {
        salesforceAccount {
          id
          host
        }
        ...CustomizableCurrency_org @include(if: $includeCustomizableCurrency)
        ...BudgetPlanned_org @include(if: $includePlannedTotalCost)
        ...BudgetActual_org @include(if: $includeActualTotalCost)
        ...BudgetPaid_org @include(if: $includePaidTotalCost)
        ...SalesforceOpportunitiesAmount_org @include(if: $includeOpportunitiesAmount)
        ...EventRequestsRequestedBy_org @include(if: $includeRequestedBy)
      }
    `,
    eventList: graphql`
      fragment SharedEventsTablePagination_eventList on EventListInterface {
        events(first: $count, after: $cursor, filters: $filters)
          @connection(key: "SharedEventsTablePagination_events", filters: []) {
          edges {
            node {
              id
              tz
              ...EventName_event @include(if: $includeName)
              ...TeamName_event @include(if: $includeTeam)
              ...EventStartDate_event @include(if: $includeStartDate)
              ...EventEndDate_event @include(if: $includeEndDate)
              ...EventVirtualLocation_event @include(if: $includeVirtualLocation)
              ...EventFormat_event @include(if: $includeEventFormat)
              ...EventVenueZip_event @include(if: $includeVenueZip)
              ...EventVenueStreet_event @include(if: $includeVenueStreet)
              ...EventVenueState_event @include(if: $includeVenueState)
              ...EventVenueName_event @include(if: $includeVenueName)
              ...EventVenueCountry_event @include(if: $includeVenueCountry)
              ...EventVenueCity_event @include(if: $includeVenueCity)
              ...EventStatus_event @include(if: $includeStatus)
              ...EventProgress_event @include(if: $includeProgress)
              ...EventWebsite_event @include(if: $includeWebsite)
              ...EventLead_event @include(if: $includeLead)
              ...EventStaff_event @include(if: $includeEventStaff)
              ...BoothNumber_event @include(if: $includeBooth)
              ...BoothDimensions_event @include(if: $includeBoothDimensions)
              ...BudgetPlanned_event @include(if: $includePlannedTotalCost)
              ...BudgetActual_event @include(if: $includeActualTotalCost)
              ...BudgetPaid_event @include(if: $includePaidTotalCost)
              ...SalesforceOpportunitiesNumber_event @include(if: $includeOpportunitiesNumber)
              ...SalesforceOpportunitiesAmount_event @include(if: $includeOpportunitiesAmount)
              ...SalesforceId_event @include(if: $includeSalesforceId)
              ...MarketoId_event @include(if: $includeMarketoId)
              ...SalesforceLastSync_event @include(if: $includeLastSync)
              ...SalesforceSyncStatus_event @include(if: $includeSyncStatus)
              ...EventRequestsStatus_event @include(if: $includeRequestStatus)
              ...EventRequestsReviewers_event @include(if: $includeRequestReviewers)
              ...EventRequestsRequestedDate_event @include(if: $includeRequestedDate)
              ...EventRequestsRequestedBy_event @include(if: $includeRequestedBy)
              ...EventRequestsFormName_event @include(if: $includeRequestForm)
              ...EventUpdater_event @include(if: $includeUpdater)
              ...EventUpdatedAt_event @include(if: $includeUpdatedAt)
              ...EventCreator_event @include(if: $includeCreator)
              ...EventCreatedAt_event @include(if: $includeCreatedAt)
              ...EventId_event @include(if: $includeId)
              ...CustomizableText_customizable @include(if: $includeCustomizableText)
              ...CustomizableTextarea_customizable @include(if: $includeCustomizableTextarea)
              ...CustomizableLink_customizable @include(if: $includeCustomizableLink)
              ...CustomizableDate_customizable @include(if: $includeCustomizableDate)
              ...CustomizableBoolean_customizable @include(if: $includeCustomizableBoolean)
              ...CustomizableNumber_customizable @include(if: $includeCustomizableNumber)
              ...CustomizableCurrency_customizable @include(if: $includeCustomizableCurrency)
              ...CustomizableSelect_customizable @include(if: $includeCustomizableSelect)
              ...CustomizableMultiselect_customizable @include(if: $includeCustomizableMultiselect)
              ...CustomizableUserSelect_customizable @include(if: $includeCustomizableUserSelect)
              ...CustomizableUserMultiselect_customizable
                @include(if: $includeCustomizableUserMultiselect)
              ...EventContactsCount_event @include(if: $includeContactsCount)
              ...RegisteredContactsTotal_event @include(if: $includeRegisteredContactsTotal)
              ...AttendedContactsTotal_event @include(if: $includeAttendedContactsTotal)
              ...BriefWeb_event @include(if: $includeBriefWeb)
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.eventList && props.eventList.events;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        readOnly: !!props.readOnly,
        count,
        cursor,
      };
    },
    query: graphql`
      query SharedEventsTablePaginationQuery(
        $isShared: Boolean!
        $publicRuleName: String!
        $resourceToken: String!
        $orgSlug: String
        $count: Int!
        $readOnly: Boolean!
        $cursor: String
        $filters: EventFilters!
        $includeName: Boolean!
        $includeTeam: Boolean!
        $includeStartDate: Boolean!
        $includeEndDate: Boolean!
        $includeVirtualLocation: Boolean!
        $includeEventFormat: Boolean!
        $includeVenueCity: Boolean!
        $includeVenueCountry: Boolean!
        $includeVenueName: Boolean!
        $includeVenueState: Boolean!
        $includeVenueStreet: Boolean!
        $includeVenueZip: Boolean!
        $includeStatus: Boolean!
        $includeProgress: Boolean!
        $includeWebsite: Boolean!
        $includeLead: Boolean!
        $includeEventStaff: Boolean!
        $includeBooth: Boolean!
        $includeBoothDimensions: Boolean!
        $includeActualTotalCost: Boolean!
        $includePaidTotalCost: Boolean!
        $includePlannedTotalCost: Boolean!
        $includeSalesforceId: Boolean!
        $includeMarketoId: Boolean!
        $includeSyncStatus: Boolean!
        $includeLastSync: Boolean!
        $includeOpportunitiesNumber: Boolean!
        $includeOpportunitiesAmount: Boolean!
        $includeRequestForm: Boolean!
        $includeRequestReviewers: Boolean!
        $includeRequestStatus: Boolean!
        $includeRequestedBy: Boolean!
        $includeRequestedDate: Boolean!
        $includeUpdatedAt: Boolean!
        $includeUpdater: Boolean!
        $includeCreatedAt: Boolean!
        $includeCreator: Boolean!
        $includeId: Boolean!
        $includeBriefWeb: Boolean!
        $includeCustomizableBoolean: Boolean!
        $includeCustomizableNumber: Boolean!
        $includeCustomizableCurrency: Boolean!
        $includeCustomizableText: Boolean!
        $includeCustomizableTextarea: Boolean!
        $includeCustomizableLink: Boolean!
        $includeCustomizableDate: Boolean!
        $includeCustomizableSelect: Boolean!
        $includeCustomizableMultiselect: Boolean!
        $includeCustomizableUserSelect: Boolean!
        $includeCustomizableUserMultiselect: Boolean!
        $includeContactsCount: Boolean!
        $includeRegisteredContactsTotal: Boolean!
        $includeAttendedContactsTotal: Boolean!
      ) {
        ... @include(if: $isShared) {
          publicRule(
            publicRuleName: $publicRuleName
            resourceToken: $resourceToken
            orgSlug: $orgSlug
          )
          eventList(resourceToken: $resourceToken) {
            ...SharedEventsTablePagination_eventList
          }
        }
        ... @skip(if: $isShared) {
          me {
            ...SharedEventsTablePagination_eventList
          }
        }
      }
    `,
  },
);
