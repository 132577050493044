/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import type { CircaFile } from 'utils/uploading/types';
import { upload } from 'utils/Attachments';

import { DropZone } from 'components/DropZone';
import AttachmentOverlay from 'components/RelayAttachments/AttachmentOverlay';
import TextField from 'components/material/TextField';
import SweetRichText from 'components/material/SweetRichText';

const Container = styled.div`
  padding: 10px 0 7px;
`;

const Logo = styled.img`
  flex: 0 auto;
  max-width: 220px;
  max-height: 110px;
`;

const StyledTextField = styled(TextField)`
  margin-top: 40px;
`;

const StyledSweetRichText = styled(SweetRichText)`
  margin-top: 24px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButtons = styled.div`
  flex: 1 auto;
  justify-content: flex-end;
  padding-top: 5px;
  text-align: right;
`;

const Button = styled.a`
  margin-left: 20px;
  text-decoration: underline;
  color: #009dce;
  cursor: pointer;
  &:hover,
  &:active {
    color: ${darken(0.05, '#009dce')};
  }
`;

const StyledDropZone = styled(DropZone)`
  .attachment-drop {
    padding-top: 15px;
    i {
      font-size: 40px !important;
    }
  }
`;

export default class EditableFormHeaderContainer extends React.PureComponent<
  {
    logo: string,
    orgLogo: ?string,
    name: string,
    description: string,
    nameError: boolean,
    onChangeName: (name: string) => void,
    onChangeDescription: (description: string) => void,
    onChangeLogo: (logo: ?string) => void,
  },
  {
    descriptionShouldGetFocus: boolean,
    showPickerMenu: boolean,
  },
> {
  state = {
    descriptionShouldGetFocus: false,
    showPickerMenu: false,
  };

  addLogoButton: React.createRef<typeof Button> = React.createRef();

  handleChangeName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeName(e.currentTarget.value);
  };

  handleChangeDescription = (description: string) => {
    this.setState({ descriptionShouldGetFocus: false });
    this.props.onChangeDescription(description);
  };

  handleTitleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab'].some(key => key === e.key)) {
      e.preventDefault();
      e.currentTarget.blur();
      this.setState({ descriptionShouldGetFocus: true });
    }
  };

  hidePickerMenu = () => {
    this.setState({
      showPickerMenu: false,
    });
  };

  handleAddLogo = (attachment: CircaFile) => {
    this.props.onChangeLogo(attachment.fileurl);
  };

  handleChangeLogo = () => {
    this.setState({ showPickerMenu: true });
  };

  handleSetOrgDefault = () => {
    this.props.onChangeLogo(this.props.orgLogo);
  };

  handleFilesDrop = (files: Array<File>) => {
    const logoFile = files.filter(f => f.type.startsWith('image/'))[0];
    if (logoFile) {
      upload(logoFile).then((file: CircaFile) => {
        this.handleAddLogo(file);
      });
    }
  };

  render() {
    const { logo, name, nameError, description } = this.props;
    return (
      <StyledDropZone onDrop={this.handleFilesDrop}>
        <Container>
          <LogoContainer>
            <Logo src={logo} />
            <ActionButtons>
              <Button onClick={this.handleChangeLogo} ref={this.addLogoButton}>
                Change Logo
              </Button>
              <AttachmentOverlay
                target={this.addLogoButton.current}
                container={this}
                onHide={this.hidePickerMenu}
                show={this.state.showPickerMenu}
                onUploaded={this.handleAddLogo}
                hideSources={['googledrive']}
                hideLink
                singleUpload
                allowOnlyImages
              />
              <Button onClick={this.handleSetOrgDefault}>Use Org Logo</Button>
            </ActionButtons>
          </LogoContainer>
          <StyledTextField
            value={name}
            label="Form Title"
            autoFocus
            onChange={this.handleChangeName}
            onKeyDown={this.handleTitleKeyDown}
            error={nameError ? 'Required' : null}
          />
          <StyledSweetRichText
            label="Form Description"
            description={description.trim()}
            onSave={this.handleChangeDescription}
            shouldGetFocus={this.state.descriptionShouldGetFocus}
            stickyPanel
            hideActionButtons
          />
        </Container>
      </StyledDropZone>
    );
  }
}
