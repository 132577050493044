/**
 * @flow
 * @relayHash c6f7bcc01953e13a70e0443b430a1868
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type AddCampaignToEventInput = {
  clientMutationId?: ?string,
  eventId: string,
  salesforceCampaignId: string,
};
export type addCampaignToEventMutationVariables = {|
  input: AddCampaignToEventInput
|};
export type addCampaignToEventMutationResponse = {|
  +addCampaignToEvent: ?{|
    +event: {|
      +salesforceCampaign: ?{|
        +id: string,
        +name: string,
        +campaignSfdcid: string,
        +campaignUrl: string,
      |},
      +pullStatus: ?{|
        +state: SyncState,
        +errorMessage: string,
        +lastSuccessAt: ?any,
      |},
      +pushStatus: ?{|
        +state: SyncState,
        +errorMessage: string,
        +lastSuccessAt: ?any,
      |},
      +syncedContacts: {|
        +totalCount: number
      |},
      +disabledContacts: {|
        +totalCount: number
      |},
      +failedContacts: {|
        +totalCount: number
      |},
      +pendingContacts: {|
        +totalCount: number
      |},
    |}
  |}
|};
export type addCampaignToEventMutation = {|
  variables: addCampaignToEventMutationVariables,
  response: addCampaignToEventMutationResponse,
|};
*/


/*
mutation addCampaignToEventMutation(
  $input: AddCampaignToEventInput!
) {
  addCampaignToEvent(input: $input) {
    event {
      salesforceCampaign {
        id
        name
        campaignSfdcid
        campaignUrl
      }
      pullStatus {
        state
        errorMessage
        lastSuccessAt
      }
      pushStatus {
        state
        errorMessage
        lastSuccessAt
      }
      syncedContacts: eventContacts(filters: {membershipSyncStatuses: [OK]}) {
        totalCount
      }
      disabledContacts: eventContacts(filters: {membershipSyncStatuses: [DISABLED]}) {
        totalCount
      }
      failedContacts: eventContacts(filters: {membershipSyncStatuses: [FAILING]}) {
        totalCount
      }
      pendingContacts: eventContacts(filters: {membershipSyncStatuses: [PENDING]}) {
        totalCount
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddCampaignToEventInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddCampaignToEventInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "salesforceCampaign",
  "storageKey": null,
  "args": null,
  "concreteType": "SalesforceCampaign",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "campaignSfdcid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "campaignUrl",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "state",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "errorMessage",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastSuccessAt",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pullStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "SyncStatus",
  "plural": false,
  "selections": v4
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pushStatus",
  "storageKey": null,
  "args": null,
  "concreteType": "SyncStatus",
  "plural": false,
  "selections": v4
},
v7 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v8 = {
  "kind": "LinkedField",
  "alias": "syncedContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"OK\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "OK"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v7
},
v9 = {
  "kind": "LinkedField",
  "alias": "disabledContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"DISABLED\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "DISABLED"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v7
},
v10 = {
  "kind": "LinkedField",
  "alias": "failedContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"FAILING\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "FAILING"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v7
},
v11 = {
  "kind": "LinkedField",
  "alias": "pendingContacts",
  "name": "eventContacts",
  "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"PENDING\"]})",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "membershipSyncStatuses": [
          "PENDING"
        ]
      },
      "type": "ContactFilters"
    }
  ],
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v7
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addCampaignToEventMutation",
  "id": null,
  "text": "mutation addCampaignToEventMutation(\n  $input: AddCampaignToEventInput!\n) {\n  addCampaignToEvent(input: $input) {\n    event {\n      salesforceCampaign {\n        id\n        name\n        campaignSfdcid\n        campaignUrl\n      }\n      pullStatus {\n        state\n        errorMessage\n        lastSuccessAt\n      }\n      pushStatus {\n        state\n        errorMessage\n        lastSuccessAt\n      }\n      syncedContacts: eventContacts(filters: {membershipSyncStatuses: [OK]}) {\n        totalCount\n      }\n      disabledContacts: eventContacts(filters: {membershipSyncStatuses: [DISABLED]}) {\n        totalCount\n      }\n      failedContacts: eventContacts(filters: {membershipSyncStatuses: [FAILING]}) {\n        totalCount\n      }\n      pendingContacts: eventContacts(filters: {membershipSyncStatuses: [PENDING]}) {\n        totalCount\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addCampaignToEventMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addCampaignToEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddCampaignToEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v5,
              v6,
              v8,
              v9,
              v10,
              v11
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "addCampaignToEventMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addCampaignToEvent",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddCampaignToEventPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              v3,
              v5,
              v6,
              v8,
              v9,
              v10,
              v11,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f799d2c0b7ad2bd81b1faaf7d0bd2f1b';
module.exports = node;
