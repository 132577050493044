/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import { type CreateCompanyFromWindow } from 'graph/mutations/company/createCompany';
import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';

import { type EventPersonDataType } from 'components/Contacts/ContactWindow/ContactForm';
import Button from 'components/material/Button';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import { type Participant } from 'components/Participants';
import OrgContactSearch from 'components/Participants/OrgContactSearch';
import ParticipantRow from 'components/Participants/ParticipantRow';

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: #96a2ab;
`;

const EventName = styled.span`
  color: ${props => props.theme.headerRowColor};
`;

const Buttons = styled.div`
  margin-top: 30px;
  text-align: right;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

export default class AddContactWindow extends React.Component<
  {
    eventName: string,
    onCancel: () => void,
    onSave: (eventContacts: $ReadOnlyArray<{ contactId: string, ...EventPersonDataType }>) => void,
    fromWindow: CreateContactFromWindow | CreateCompanyFromWindow,
  },
  {
    selection: Array<{ contact: Participant, eventContactData: EventPersonDataType }>,
  },
> {
  state = {
    selection: [],
  };

  searchUserQueryExists: ?boolean;

  handleClickPropagation = (e: SyntheticEvent<>) => {
    e.stopPropagation();
  };

  handleAddButtonClick = () => {
    if (this.state.selection.length > 0) {
      this.props.onSave(
        this.state.selection.map(selection => ({
          contactId: selection.contact.id,
          ...selection.eventContactData,
        })),
      );
    }
    this.props.onCancel();
  };

  handleContactSelect = (
    contact: ?Participant,
    eventContactData?: EventPersonDataType = {
      registrationStatusId: null,
      attendanceStatusId: null,
    },
  ) => {
    this.setState(prevState => {
      if (
        !contact ||
        prevState.selection.some(selection => contact && contact.id === selection.contact.id)
      ) {
        return null;
      }

      return {
        selection: [{ contact, eventContactData }, ...prevState.selection],
      };
    });
  };

  handleContactUnselect = (contact: ?Participant) => {
    this.setState(prevState => ({
      selection: prevState.selection.filter(
        selection => contact && selection.contact.id !== contact.id,
      ),
    }));
  };

  handleToggleUserQueryState = (present: boolean) => {
    this.searchUserQueryExists = present;
  };

  isNotEdited = () => {
    return !(this.state.selection.length > 0 || this.searchUserQueryExists);
  };

  handleOverlayHide = () => {
    if (this.isNotEdited()) {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <Window size="medium" onHide={this.handleOverlayHide} onClick={this.handleClickPropagation}>
        <WindowHeader>
          <WindowTitle>
            <Title>
              Add Contacts to <EventName>{this.props.eventName}</EventName>
            </Title>
          </WindowTitle>
          <WindowClose onClick={this.props.onCancel} />
        </WindowHeader>
        <WindowContent>
          <OrgContactSearch
            onSelect={this.handleContactSelect}
            onToggleQueryState={this.handleToggleUserQueryState}
            includeEventDataInNewWindow
            fromWindow={this.props.fromWindow}
          />
          {this.state.selection.map(selection => (
            <ParticipantRow
              key={selection.contact.id}
              participant={selection.contact}
              onRemove={this.handleContactUnselect}
            />
          ))}
          <Buttons>
            <Button minimal label="Cancel" onClick={this.props.onCancel} />
            <SubmitButton
              primary
              label="Add to Event"
              onClick={this.handleAddButtonClick}
              disabled={this.state.selection.length === 0}
            />
          </Buttons>
        </WindowContent>
      </Window>
    );
  }
}
