import React from 'react';
import styled from 'styled-components';

import Label from './BriefTaskLabel';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Status = styled.div`
  margin-left: 7px;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  border-radius: 2px;
  color: #fff;
  background-color: ${props => (props.completed ? '#29cc71' : '#bebebe')};
`;

const TaskStatus = (props: { completed: boolean }) => {
  const { completed } = props;
  return (
    <Container>
      <Label>STATUS</Label>
      <Status completed={completed}>{completed ? 'Completed' : 'Open'}</Status>
    </Container>
  );
};
export default TaskStatus;
