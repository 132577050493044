/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { graphql, createFragmentContainer } from 'react-relay';
import styled from 'styled-components';

import addContactAssignments from 'graph/mutations/event/addContactAssignments';
import removeContactAssignment from 'graph/mutations/event/removeContactAssignment';
import addContactToBrief from 'graph/mutations/event_brief/addContactToBrief';
import removeContactFromBrief from 'graph/mutations/event_brief/removeContactFromBrief';
import showModernMutationError from 'graph/utils/showModernMutationError';

import fullNameOfUser from 'utils/fullNameOfUser';
import AddToEventsWindow from 'components/Contacts/AddToEventsWindow';
import ShareDropdown from 'components/ShareDropdown';

import type { InputVariableFilters } from 'components/Contacts/contactsTableColumnSettings';

import type { ContactActionCell_contact } from './__generated__/ContactActionCell_contact.graphql';
import type { ContactActionCell_event } from './__generated__/ContactActionCell_event.graphql';

const Root = styled.div`
  margin: 0 -5px 0 -24px;
`;

class ContactActionCell extends React.PureComponent<
  {
    contact: ContactActionCell_contact,
    event: ?ContactActionCell_event,
    inputFilters: InputVariableFilters,
  },
  { addingToEvents: boolean },
> {
  state = { addingToEvents: false };

  handleAddingToEventsShow = () => {
    this.setState({ addingToEvents: true });
  };

  handleAddingToEventsHide = () => {
    this.setState({ addingToEvents: false });
  };

  handleAddToEventsSave = (eventIds: $ReadOnlyArray<string>) => {
    addContactAssignments(eventIds, [{ contactId: this.props.contact.id }], 'org contacts').catch(
      showModernMutationError,
    );
  };

  handleAddContactToBrief = () => {
    if (this.props.event) {
      addContactToBrief(this.props.contact.id, this.props.event.id).catch(showModernMutationError);
    }
  };

  handleRemoveContactFromBrief = () => {
    if (this.props.event) {
      removeContactFromBrief(this.props.contact.id, this.props.event.id).catch(
        showModernMutationError,
      );
    }
  };

  handleRemoveContactAssignment = () => {
    if (this.props.event) {
      removeContactAssignment({
        eventId: this.props.event.id,
        contactIds: [this.props.contact.id],
        fromWindow: 'event contacts',
        filters: this.props.inputFilters,
      }).catch(showModernMutationError);
    }
  };

  getBriefActions = () => {
    const { contact, event } = this.props;
    if (event != null && event.viewerCanUpdate) {
      if (event.briefContacts.edges.map(({ node }) => node.id).includes(contact.id)) {
        return {
          label: 'Remove from Brief',
          icon: 'minus',
          onClick: this.handleRemoveContactFromBrief,
        };
      }
      return {
        label: 'Add to Brief',
        icon: 'plus',
        onClick: this.handleAddContactToBrief,
      };
    }
    return null;
  };

  render() {
    const contactName = fullNameOfUser(this.props.contact);
    const actionOptions = [
      this.props.event != null
        ? { label: 'Remove from event', icon: 'minus', onClick: this.handleRemoveContactAssignment }
        : { label: 'Add to Event', icon: 'plus', onClick: this.handleAddingToEventsShow },
      this.getBriefActions(),
    ].filter(Boolean);

    return (
      <Root>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={actionOptions}
          overlayContainer={document.body}
        />
        {this.state.addingToEvents &&
          document.body &&
          createPortal(
            <AddToEventsWindow
              contactName={contactName}
              onCancel={this.handleAddingToEventsHide}
              onSave={this.handleAddToEventsSave}
            />,
            document.body,
          )}
      </Root>
    );
  }
}

export default createFragmentContainer(ContactActionCell, {
  contact: graphql`
    fragment ContactActionCell_contact on Contact {
      id
      firstName
      lastName
      email
    }
  `,
  event: graphql`
    fragment ContactActionCell_event on Event {
      id
      viewerCanUpdate
      briefContacts {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
});
