import { createMoment, renderDateRange } from 'utils/Date';
import { getQuarter } from 'utils/fiscal';
import storage from 'utils/storage';

function parseDates(string, moment) {
  const dates = string.split('/', 2);
  const value = {};

  ['start_date', 'end_date'].forEach((type, i) => {
    if (dates[i]) {
      const date = moment(dates[i], 'MM-DD-YYYY');
      if (date.isValid()) {
        value[type] = date.toDate();
      }
    }
  });

  if (value.start_date) {
    value.displayName = renderDateRange(
      {
        startDate: value.start_date,
        endDate: value.end_date,
        startDateAllDay: true,
        endDateAllDay: true,
      },
      { shorterMonth: true, noTime: true },
    );
    return value;
  }
  return null;
}

function stringifyDates({ name }) {
  return name;
}

/**
 * Extracts the quarter number out of a string like 'FQ0, FQ1, FQ-1'
 */
function parseQuarter(string) {
  const exp = /FQ(-?\d{1})/;
  const result = exp.exec(string);
  if (result) {
    return parseInt(result[1], 10);
  }
  return null;
}

/**
 * Converts a filter params stored in the query string into values that the FilterBar can use.
 *
 * @param [Object] schema - a dictionary where the key is the param name, and the value is the
 *  param 'type', type is defined below.  example: { vendor: 'string', start_date: 'date' }
 * @param [Object] props.queryParams
 * @param fiscalYearStart number
 * @return [Object] object of params
 */
export const getFilters = (schema, query = {}, fiscalYearStart = 0) => {
  const filters = {};
  const moment = createMoment();
  const now = moment();

  Object.keys(schema).forEach(key => {
    const type = schema[key];
    let value = null;
    if (key in query) {
      switch (type) {
        case 'string':
          value = String(query[key]);
          break;
        case 'number':
          value = +query[key];
          break;
        case 'date':
          if (query[key] === 'month') {
            const month = now.clone().subtract(1, 'month').startOf('month');
            value = {
              start_date: month.toDate(),
              end_date: month.endOf('month').toDate(),
            };
          } else if (query[key] === '90days') {
            const ago90 = now.clone().subtract(90, 'days').startOf('date');
            value = {
              start_date: ago90.toDate(),
              end_date: now.endOf('date').toDate(),
            };
          } else if (query[key] === 'year') {
            const year = now.clone().subtract(1, 'year').startOf('year');
            value = {
              start_date: year.toDate(),
              end_date: year.endOf('year').toDate(),
            };
          } else if (parseQuarter(query[key]) !== null) {
            const quarterIndex = parseQuarter(query[key]);
            const quarter = getQuarter(quarterIndex, fiscalYearStart);
            value = {
              start_date: quarter.start.toDate(),
              end_date: quarter.end.toDate(),
            };
          } else {
            value = parseDates(query[key], moment);
          }
          break;
        default:
          break;
      }
      if (value != null && !Number.isNaN(value)) {
        if (typeof value === 'object') {
          value.name = query[key];
        }
        filters[key] = value;
      }
    }
  });
  return filters;
};

export const normalizeFilters = filters => {
  const newFilters = {};
  // TODO: Change for to forEach
  // eslint-disable-next-line no-restricted-syntax
  for (const [name, filter] of Object.entries(filters)) {
    if (filter && typeof filter === 'object') {
      // filter is a date
      newFilters[name] = stringifyDates(filter);
    } else {
      newFilters[name] = filter;
    }
  }
  return newFilters;
};

export const getSavedFilters = (key, defaultFilters = {}, shownFilters = []) => {
  let filters = Object.assign({}, defaultFilters);

  filters = storage.get(`${key}Filters`) || filters;

  Object.keys(filters).forEach(k => {
    if (!shownFilters.includes(k)) {
      delete filters[k];
    }
  });

  return filters;
};

export const saveFilters = (key, filters) => {
  const newFilters = normalizeFilters(filters);
  storage.set(`${key}Filters`, newFilters);
};
