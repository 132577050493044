/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  updateBriefTemplateMutationResponse,
  updateBriefTemplateMutationVariables,
} from './__generated__/updateBriefTemplateMutation.graphql';

const mutation = graphql`
  mutation updateBriefTemplateMutation(
    $input: UpdateBriefTemplateInput!
    $changingAccessibility: Boolean!
    $changingHeaderSettings: Boolean!
    $changingSections: Boolean!
    $changingEventStaffFields: Boolean!
    $changingTaskFields: Boolean!
    $changingScheduleFields: Boolean!
    $changingPersonalizeSchedules: Boolean!
  ) {
    updateBriefTemplate(input: $input) {
      org {
        id
        briefTemplate {
          accessibility @include(if: $changingAccessibility)
          sections @include(if: $changingSections) {
            name
            order
            enabled
          }
          headerSettings @include(if: $changingHeaderSettings) {
            includeDates
            includeLocation
            includeWebsite
          }
          eventStaffFields @include(if: $changingEventStaffFields) {
            name
            enabled
          }
          taskFields @include(if: $changingTaskFields) {
            name
            enabled
          }
          scheduleFields @include(if: $changingScheduleFields) {
            name
            enabled
          }
          personalizeSchedules @include(if: $changingPersonalizeSchedules)
        }
      }
    }
  }
`;

export type BriefTemplateMutationVariables = $Exact<
  $PropertyType<updateBriefTemplateMutationVariables, 'input'>,
>;

export default function updateBriefTemplate(
  id: string,
  briefSettings: BriefTemplateMutationVariables,
): Promise<updateBriefTemplateMutationResponse> {
  const variables: $Shape<updateBriefTemplateMutationVariables> = {
    input: briefSettings,
    ...[
      'accessibility',
      'sections',
      'headerSettings',
      'eventStaffFields',
      'taskFields',
      'scheduleFields',
      'personalizeSchedules',
    ]
      .map(key => ({
        [`changing${key[0].toUpperCase()}${key.slice(1)}`]: briefSettings[key] !== undefined,
      }))
      .reduce((acc, item) => ({ ...acc, ...item }), {}),
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateBriefTemplate: {
        org: {
          briefTemplate: {
            id,
            ...briefSettings,
          },
        },
      },
    },
  });
}
