/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationPageComponents_org$ref = any;
type RegistrationPageContent_org$ref = any;
type RegistrationPageProperties_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationCreateContent_org$ref: FragmentReference;
export type RegistrationCreateContent_org = {|
  +syncedToIbmWm: boolean,
  +$fragmentRefs: RegistrationPageComponents_org$ref & RegistrationPageProperties_org$ref & RegistrationPageContent_org$ref,
  +$refType: RegistrationCreateContent_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationCreateContent_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "syncedToIbmWm",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationPageComponents_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationPageProperties_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationPageContent_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6d311c77f5f5a246fedcb420ed35b983';
module.exports = node;
