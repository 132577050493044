/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactProfile_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactPageRoutes_event$ref: FragmentReference;
export type ContactPageRoutes_event = {|
  +$fragmentRefs: ContactProfile_event$ref,
  +$refType: ContactPageRoutes_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactPageRoutes_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ContactProfile_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '507e405cf306c0a7f3d24c3baf11b7ea';
module.exports = node;
