/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { createTasksMutationResponse } from './__generated__/createTasksMutation.graphql';

export type TasksFromWindow =
  | 'task'
  | 'multiselect'
  | 'checklist'
  | 'team staffing'
  | 'all tasks'
  | 'my tasks'
  | 'onboarding';

type Fields = {
  names: Array<string>,
  dueDate?: ?string,
  dueDateAllDay?: ?boolean,
  assignees?: Array<string>,
  folderId?: ?string,
  parentId?: ?string,
  fromWindow?: ?TasksFromWindow,
};

const mutation = graphql`
  mutation createTasksMutation($input: CreateDeliverablesInput!) {
    createDeliverables(input: $input) {
      deliverableEdges {
        node {
          id
          dbId
          name
          order
          folderId
          parent {
            subtasksCount
            completedSubtasksCount
          }
          event {
            progress
          }
          viewerCanReorder
          ...TaskRow_task
          ...SubtaskRow_subtask
        }
      }
    }
  }
`;

export default function createTasks(
  eventId: string,
  { names, dueDate, dueDateAllDay, assignees, folderId, parentId, fromWindow }: Fields,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        eventId,
        names,
        due_date: dueDate,
        due_date_all_day: dueDateAllDay,
        assignees,
        folderId,
        parentId,
        fromWindow,
      },
    },
    updater: mergeUpdaters(
      ...[
        parentId
          ? addRecordToConnection({
              parentId,
              connections: [{ field: 'subtasks' }],
              edgeName: 'deliverableEdges',
            })
          : null,
        addRecordToConnection({
          parentId: eventId,
          connections: [
            parentId ? null : { field: 'deliverables' },
            parentId ? null : { key: 'TasksSectionViewContent_deliverables' },
            { key: 'TasksDueDateViewContent_deliverables' },
            { key: 'TasksCalendarView_deliverables' },
          ].filter(Boolean),
          edgeName: 'deliverableEdges',
        }),
      ].filter(Boolean),
    ),
  }).then((response: createTasksMutationResponse) => {
    if (response.createDeliverables) {
      return response.createDeliverables.deliverableEdges.map(edge => edge.node.id);
    }
    return [];
  });
}
