/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import { type CreateVendorFromWindow } from 'graph/mutations/vendor/createVendor';

import Button, { MinimalButton } from 'components/budget/Button';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import { type Participant } from 'components/Participants';
import ParticipantRow from 'components/Participants/ParticipantRow';
import VendorSearch from 'components/Participants/VendorSearch';

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  color: #96a2ab;
`;

const EventName = styled.span`
  color: ${props => props.theme.headerRowColor};
`;

const Buttons = styled.div`
  margin-top: 30px;
  text-align: right;
`;

const SubmitButton = styled(Button)`
  margin-left: 30px;
`;

export default class AddVendorWindow extends React.Component<
  {
    eventName: string,
    onCancel: () => void,
    onSave: (vendorIds: $ReadOnlyArray<string>) => void,
    fromWindow: CreateVendorFromWindow,
  },
  {
    selection: $ReadOnlyArray<Participant>,
  },
> {
  state = {
    selection: [],
  };

  searchUserQueryExists: ?boolean;

  handleClickPropagation = (e: SyntheticEvent<>) => {
    e.stopPropagation();
  };

  handleAddButtonClick = () => {
    if (this.state.selection.length > 0) {
      this.props.onSave(this.state.selection.map(vendor => vendor.id));
    }
    this.props.onCancel();
  };

  handleVendorSelect = (vendor: ?Participant) => {
    if (vendor && !this.state.selection.some(c => vendor && c.id === vendor.id)) {
      this.setState(prevState => ({
        selection: vendor ? [vendor, ...prevState.selection] : prevState.selection,
      }));
    }
  };

  handleVendorUnselect = (vendor: ?Participant) => {
    this.setState(prevState => ({
      selection: prevState.selection.filter(c => vendor != null && c.id !== vendor.id),
    }));
  };

  handleToggleUserQueryState = (present: boolean) => {
    this.searchUserQueryExists = present;
  };

  isNotEdited = () => {
    return !(this.state.selection.length > 0 || this.searchUserQueryExists);
  };

  handleOverlayHide = () => {
    if (this.isNotEdited()) {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <Window size="medium" onHide={this.handleOverlayHide} onClick={this.handleClickPropagation}>
        <WindowHeader>
          <WindowTitle>
            <Title>
              Add Vendors to <EventName>{this.props.eventName}</EventName>
            </Title>
          </WindowTitle>
          <WindowClose onClick={this.props.onCancel} />
        </WindowHeader>
        <WindowContent>
          <VendorSearch
            onSelect={this.handleVendorSelect}
            onToggleQueryState={this.handleToggleUserQueryState}
            fromWindow={this.props.fromWindow}
          />
          {this.state.selection.map(vendor => (
            <ParticipantRow
              key={vendor.id}
              participant={vendor}
              onRemove={this.handleVendorUnselect}
            />
          ))}
          <Buttons>
            <MinimalButton label="Cancel" onClick={this.props.onCancel} />
            <SubmitButton
              onClick={this.handleAddButtonClick}
              disabled={this.state.selection.length === 0}
            >
              Add to Event
            </SubmitButton>
          </Buttons>
        </WindowContent>
      </Window>
    );
  }
}
