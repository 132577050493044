/* @flow */

import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

const mutation = graphql`
  mutation removeVendorAssignmentMutation($input: RemoveVendorAssignmentInput!) {
    removeVendorAssignment(input: $input) {
      removedVendorId
      removedEventId
    }
  }
`;

type Args = {
  eventId: string,
  vendorId: string,
  fromWindow: 'vendor profile' | 'event vendors',
};

export default function removeVendorAssignment({ eventId, vendorId, fromWindow }: Args) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        vendorId,
        eventId,
        fromWindow,
      },
    },
    optimisticResponse: {
      removeVendorAssignment: {
        removedVendorId: vendorId,
        removedEventId: eventId,
      },
    },
  });
}
