/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import briefTemplateSections from 'config/brief/briefTemplateSections';

import { type BriefTemplateMutationVariables } from 'graph/mutations/briefTemplate/updateBriefTemplate';

import Switch from 'components/material/Switch';

import EventInfoSection from './EventInfoSection';
import EventStaff from './EventStaff';
import { type SectionType } from './index';
import Schedules from './Schedules';
import Tasks from './Tasks';

import type { Section_org } from './__generated__/Section_org.graphql';

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  margin-top: 10px;
  padding: 0 26px;
  background-color: #f7f8f9;
`;

export const SectionLabel = styled.div`
  flex: 1 1 auto;
  font-weight: 500;
  ${props =>
    props.shiftRight &&
    css`
      padding-left: 23px;
    `};
`;

export const SectionContent = styled.div`
  margin: 25px 0;
  > div {
    max-width: 560px;
    padding: 0 26px;
  }
`;

export const ExpandStateIcon = styled.i`
  font-size: 18px;
  font-weight: 500;
  color: #96a2ab;
`;

class Section extends React.PureComponent<{
  section: SectionType,
  onToggleEnabled: (name: string, enabled: boolean) => void,
  expanded: boolean,
  onToggleExpanded: (name: string, expanded: boolean) => void,
  org: Section_org,
  onTemplateAttributeChange: (input: BriefTemplateMutationVariables) => void,
}> {
  getContentComponent() {
    switch (this.props.section.name) {
      case 'event_info':
        return EventInfoSection;
      case 'event_staff':
        return EventStaff;
      case 'schedules':
        return Schedules;
      case 'tasks':
        return Tasks;
      default:
        return null;
    }
  }

  handleToggleEnabled = (enabled: boolean) => {
    this.props.onToggleEnabled(this.props.section.name || this.props.section.id || '', enabled);
    if (enabled === false && this.props.expanded) {
      this.props.onToggleExpanded(this.props.section.name || this.props.section.id || '', false);
    }
  };

  handleToggleExpanded = () => {
    if (this.props.section.enabled) {
      this.props.onToggleExpanded(
        this.props.section.name || this.props.section.id || '',
        !this.props.expanded,
      );
    }
  };

  render() {
    const Component = this.getContentComponent();
    return (
      <React.Fragment>
        <SectionHeader>
          {Component != null && (
            <ExpandStateIcon
              onClick={this.handleToggleExpanded}
              className={`fa fa-fw fa-angle-${this.props.expanded ? 'down' : 'right'}`}
            />
          )}
          <SectionLabel onClick={this.handleToggleExpanded} shiftRight={Component == null}>
            {briefTemplateSections[this.props.section.name || this.props.section.id || '']}
          </SectionLabel>
          <Switch enabled={this.props.section.enabled} onChange={this.handleToggleEnabled} />
        </SectionHeader>
        {Component != null && this.props.expanded && (
          <SectionContent>
            <Component
              expanded={this.props.expanded}
              org={this.props.org}
              template={this.props.org.briefTemplate}
              onTemplateAttributeChange={this.props.onTemplateAttributeChange}
            />
          </SectionContent>
        )}
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  Section,
  graphql`
    fragment Section_org on Org {
      ...EventInfoSection_org
      briefTemplate {
        ...EventInfoSection_template
        ...EventStaff_template
        ...Schedules_template
        ...Tasks_template
      }
    }
  `,
);
