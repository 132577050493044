/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Direction = "ASC" | "DESC";
export type EventListEnum = "NEXT30DAYS" | "NEXT60DAYS" | "NEXT90DAYS" | "PAST" | "RECENT" | "UPCOMING";
export type EventSort = "ATTENDED_CONTACTS_TOTAL" | "BOOTH" | "BOOTH_DIMENSIONS" | "CITY" | "COMMITMENT_LEVEL" | "CONTACTS_COUNT" | "COUNTRY" | "CREATED_AT" | "CREATOR" | "CUSTOM" | "END_DATE" | "EVENT_FORMAT" | "EVENT_LEAD" | "LAST_SYNCED" | "LOCATION" | "MARKETO_ID" | "NAME" | "OPPORTUNITIES_AMOUNT" | "OPPORTUNITIES_NUMBER" | "PROGRESS" | "REGISTERED_CONTACTS_TOTAL" | "REQUESTED_BY" | "REQUESTED_DATE" | "REQUESTED_FORM_NAME" | "REQUESTED_STATUS" | "SALESFORCE_ID" | "STAFF_COUNT" | "START_DATE" | "STATE" | "STREET" | "SYNC_STATUS" | "TEAM_NAME" | "TEMPLATES_FIRST" | "TOTAL_ACTUAL_COST" | "TOTAL_PAID_COST" | "TOTAL_PLANNED_COST" | "UPDATED_AT" | "UPDATER" | "VENUE_NAME" | "VIRTUAL_LOCATION" | "WEBSITE" | "ZIP_CODE";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AllEventsShareContainer_eventList$ref: FragmentReference;
export type AllEventsShareContainer_eventList = {|
  +id: string,
  +name: string,
  +token: string,
  +linkEventNameToBrief: boolean,
  +logo: ?string,
  +font: ?string,
  +titleColor: ?string,
  +backgroundColor: ?string,
  +backgroundUrl: ?string,
  +listType: ?EventListEnum,
  +beforeDate: ?any,
  +sort: ?EventSort,
  +customSort: ?{|
    +id: string
  |},
  +direction: ?Direction,
  +savedListColumns: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +columnName: ?string,
        +customField: ?{|
          +id: string
        |},
      |}
    |}>
  |},
  +actionsRequestForms: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +name: string,
        +shareLink: ?string,
      |}
    |}>
  |},
  +$refType: AllEventsShareContainer_eventList$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  v0
];
return {
  "kind": "Fragment",
  "name": "AllEventsShareContainer_eventList",
  "type": "EventList",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "token",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "linkEventNameToBrief",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "font",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "titleColor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundColor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "backgroundUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "listType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "beforeDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sort",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customSort",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomFieldType",
      "plural": false,
      "selections": v2
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "direction",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "savedListColumns",
      "storageKey": null,
      "args": null,
      "concreteType": "SavedListColumnTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SavedListColumnTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SavedListColumnType",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "columnName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "customField",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldType",
                  "plural": false,
                  "selections": v2
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actionsRequestForms",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestFormRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "EventRequestFormRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "EventRequestForm",
              "plural": false,
              "selections": [
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "shareLink",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f26321695f023f1594c3bca82c641665';
module.exports = node;
