/**
 * @flow
 * @relayHash 8573a2023d28f9a78577108ecae4a697
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ZoomLocationKind = "MEETING" | "WEBINAR";
export type ZoomLocationList = "PAST" | "UPCOMING";
export type ZoomLocationPickerQueryVariables = {|
  integrableId: string,
  kind: ZoomLocationKind,
  zoomUserId: string,
  list: ZoomLocationList,
|};
export type ZoomLocationPickerQueryResponse = {|
  +integrable: ?{|
    +zoomLocations?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +kind: ZoomLocationKind,
          +zoomId: string,
          +zoomUser: ?{|
            +id: string,
            +zoomId: string,
            +firstName: string,
            +lastName: string,
            +email: string,
            +freePlan: boolean,
          |},
          +joinUrl: string,
          +zoomUrl: string,
          +topic: string,
          +startTime: ?any,
          +duration: ?number,
          +timezone: string,
          +event: ?{|
            +id: string,
            +name: string,
            +slug: string,
          |},
        |}
      |}>
    |}
  |}
|};
export type ZoomLocationPickerQuery = {|
  variables: ZoomLocationPickerQueryVariables,
  response: ZoomLocationPickerQueryResponse,
|};
*/


/*
query ZoomLocationPickerQuery(
  $integrableId: ID!
  $kind: ZoomLocationKind!
  $zoomUserId: ID!
  $list: ZoomLocationList!
) {
  integrable: node(id: $integrableId) {
    __typename
    ... on IntegrableInterface {
      zoomLocations(kind: $kind, zoomUserId: $zoomUserId, list: $list) {
        edges {
          node {
            id
            kind
            zoomId
            zoomUser {
              id
              zoomId
              firstName
              lastName
              email
              freePlan
            }
            joinUrl
            zoomUrl
            topic
            startTime
            duration
            timezone
            event {
              id
              name
              slug
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "integrableId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "kind",
    "type": "ZoomLocationKind!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "zoomUserId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "list",
    "type": "ZoomLocationList!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "integrableId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "zoomId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "zoomLocations",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "kind",
      "variableName": "kind",
      "type": "ZoomLocationKind!"
    },
    {
      "kind": "Variable",
      "name": "list",
      "variableName": "list",
      "type": "ZoomLocationList!"
    },
    {
      "kind": "Variable",
      "name": "zoomUserId",
      "variableName": "zoomUserId",
      "type": "ID!"
    }
  ],
  "concreteType": "ZoomLocationRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ZoomLocationRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ZoomLocation",
          "plural": false,
          "selections": [
            v2,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "kind",
              "args": null,
              "storageKey": null
            },
            v3,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "zoomUser",
              "storageKey": null,
              "args": null,
              "concreteType": "ZoomUser",
              "plural": false,
              "selections": [
                v2,
                v3,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "firstName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "lastName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "email",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "freePlan",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "joinUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "zoomUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "topic",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "startTime",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "duration",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "timezone",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "event",
              "storageKey": null,
              "args": null,
              "concreteType": "Event",
              "plural": false,
              "selections": [
                v2,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "slug",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ZoomLocationPickerQuery",
  "id": null,
  "text": "query ZoomLocationPickerQuery(\n  $integrableId: ID!\n  $kind: ZoomLocationKind!\n  $zoomUserId: ID!\n  $list: ZoomLocationList!\n) {\n  integrable: node(id: $integrableId) {\n    __typename\n    ... on IntegrableInterface {\n      zoomLocations(kind: $kind, zoomUserId: $zoomUserId, list: $list) {\n        edges {\n          node {\n            id\n            kind\n            zoomId\n            zoomUser {\n              id\n              zoomId\n              firstName\n              lastName\n              email\n              freePlan\n            }\n            joinUrl\n            zoomUrl\n            topic\n            startTime\n            duration\n            timezone\n            event {\n              id\n              name\n              slug\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ZoomLocationPickerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "integrable",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ZoomLocationPickerQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "integrable",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v4,
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a4cafd9be458ef306be38065c9bf95b9';
module.exports = node;
