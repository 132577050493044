/* @flow */

import React from 'react';
import styled from 'styled-components';

import SectionHeader from '../components/SectionHeader';
import SectionContent from '../components/SectionContent';
import UserContainerInList from '../components/UserContainerInList';
import UserContainer from '../components/UserContainer';
import VendorField from '../components/VendorField';
import { type Field } from '../components/types';

const StyledUserContainer = styled(UserContainer)`
  padding-bottom: 40px;
`;

const StyledVendorField = styled(VendorField)`
  > div:first-child {
    width: 40px;
    height: 40px;
    > svg {
      width: 15px;
      height: 15px;
    }
  }
`;

export type VendorType = {|
  +id: string,
  +firstName: ?string,
  +lastName: ?string,
  +email: ?string,
  +phone1: ?string,
  +phone2: ?string,
  +title: ?string,
  +name: string,
|};

class EventVendorsBrief extends React.Component<{
  customFields: $ReadOnlyArray<Field<'VENDOR'>>,
  vendors: $ReadOnlyArray<VendorType>,
  primaryColor: string,
}> {
  render() {
    const { primaryColor, vendors, customFields } = this.props;
    const vendorsLength = vendors.length;
    return vendorsLength > 0 ? (
      <React.Fragment>
        <SectionHeader primaryColor={primaryColor} name="Event Vendors" anchor="vendors" />
        <SectionContent>
          <StyledUserContainer>
            {vendors.map(vendor => {
              return (
                vendor && (
                  <UserContainerInList key={vendor.id}>
                    <StyledVendorField vendor={vendor} customFields={customFields} />
                  </UserContainerInList>
                )
              );
            })}
          </StyledUserContainer>
        </SectionContent>
      </React.Fragment>
    ) : null;
  }
}

export default EventVendorsBrief;
