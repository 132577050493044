/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import ColumnFilterOverlay from './ColumnFiltersOverlay';
import type { Filter, FilterGroup } from './columnFilterTypes';

const Container = styled.div`
  position: relative;
  align-self: baseline;
  margin-left: 14px;
  padding: 4px 4px 4px 13px;
  border-radius: 15px;
  font-size: 13px;
  cursor: pointer;
  background-color: #edf0fc;
  color: #4069e1;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  @media (${props =>
      props.mobileBreakpoint ? `max-width: ${props.mobileBreakpoint}px` : props.theme.mobileOnly}) {
    > div > svg:first-of-type {
      margin-right: 0;
    }
    > div > span,
    > div > svg:last-of-type {
      display: none;
    }
    > div > span + div {
      margin-left: 0;
    }
  }
`;

const BubbleIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 19px;
  height: 19px;
  margin-left: 6px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  background-color: #4a5665;
  color: #fff;
`;

const Label = styled.span`
  vertical-align: middle;
`;

const ColumnIcon = styled.i`
  transform: rotate(90deg);
  margin-right: 9px;
  font-size: 12px;
  color: #4069e1;
`;

export default class ColumnFilters extends React.PureComponent<
  {
    filters: $ReadOnlyArray<Filter>,
    shownFilters: $ReadOnlyArray<string>,
    onFilterChange: (columns: $ReadOnlyArray<string>) => void,
    groups?: $ReadOnlyArray<FilterGroup>,
    mobileBreakpoint?: number,
    className?: string,
  },
  {
    overlayShown: boolean,
  },
> {
  containerRef = React.createRef();

  targetRef = React.createRef();

  state = {
    overlayShown: false,
  };

  handleSetDefaults = () => {
    const defaultFilters = this.props.filters.filter(item => item.default).map(item => item.value);
    this.props.onFilterChange(defaultFilters);
  };

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  render() {
    const { filters, shownFilters, className, mobileBreakpoint, groups } = this.props;
    const allColumns = filters.map(column => column.value);
    const shownColumns = shownFilters.filter(value => allColumns.includes(value));
    return (
      <Container
        className={className}
        onClickCapture={this.handleOverlayShow}
        ref={this.containerRef}
        mobileBreakpoint={mobileBreakpoint}
      >
        <div ref={this.targetRef}>
          <ColumnIcon className="fa fa-bars" />
          <Label>Columns</Label>
          <BubbleIcon>{shownColumns.length}</BubbleIcon>
        </div>
        <ColumnFilterOverlay
          container={this.containerRef.current}
          target={this.targetRef.current}
          onHide={this.handleOverlayHide}
          show={this.state.overlayShown}
          filters={filters}
          groups={groups}
          shownColumns={shownFilters}
          onChange={this.props.onFilterChange}
          onSetDefaults={this.handleSetDefaults}
        />
      </Container>
    );
  }
}
