/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router-dom';

import getTableIncludeProps from 'utils/customization/getTableIncludeProps';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import getCustomFieldVariable from 'utils/filters/getCustomFieldVariable';
import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceQueryParams from 'utils/routing/replaceQueryParams';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import storage from 'utils/storage';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import getShownColumns from 'components/Vendors/lib/getShownColumns';
import getVendorFields from 'components/Vendors/lib/getVendorFields';
import parseVendorsFilters from 'components/Vendors/lib/parseVendorsFilters';
import { defaultTableComponents } from 'components/Vendors/table/columns';
import columnSettings from 'components/Vendors/table/columns/columnSettings';

import VendorsHeaderBar from './VendorsHeaderBar';
import VendorsTablePagination from './VendorsTablePagination';

import { type VendorsPage_org } from './__generated__/VendorsPage_org.graphql';
import { type VendorsPage_user } from './__generated__/VendorsPage_user.graphql';
import { type VendorsPageQueryResponse } from './__generated__/VendorsPageQuery.graphql';

const query = graphql`
  query VendorsPageQuery(
    $count: Int!
    $cursor: String
    $filters: VendorFilters!
    $includeName: Boolean!
    $includeWebsite: Boolean!
    $includeCompanyPhone: Boolean!
    $includeStreet: Boolean!
    $includeCity: Boolean!
    $includeState: Boolean!
    $includeZip: Boolean!
    $includeCountry: Boolean!
    $includeTwitter: Boolean!
    $includeLinkedin: Boolean!
    $includeDescription: Boolean!
    $includeFirstName: Boolean!
    $includeLastName: Boolean!
    $includeTitle: Boolean!
    $includePhone1: Boolean!
    $includePhone2: Boolean!
    $includeEmail: Boolean!
    $includeCreatedAt: Boolean!
    $includeCreatedBy: Boolean!
    $includeUpdatedAt: Boolean!
    $includeUpdatedBy: Boolean!
    $includeCustomizableText: Boolean!
    $includeCustomizableTextarea: Boolean!
    $includeCustomizableLink: Boolean!
    $includeCustomizableDate: Boolean!
    $includeCustomizableBoolean: Boolean!
    $includeCustomizableNumber: Boolean!
    $includeCustomizableCurrency: Boolean!
    $includeCustomizableSelect: Boolean!
    $includeCustomizableMultiselect: Boolean!
    $includeCustomizableUserSelect: Boolean!
    $includeCustomizableUserMultiselect: Boolean!
  ) {
    org {
      ...VendorsTablePagination_org
      ...VendorsTablePagination_totalCountOrg
    }
  }
`;

class VendorsPage extends React.Component<
  {
    org: VendorsPage_org,
    user: VendorsPage_user,
    history: RouterHistory,
    location: Location,
    pathPrefix: string,
  },
  {
    shownColumns: $ReadOnlyArray<string>,
    availableVendors: $ReadOnlyArray<string>,
  },
> {
  vendorFields: $ReadOnlyArray<FieldType> = getVendorFields(
    normalizeCustomFields(this.props.org.customFields.edges.map(edge => edge.node)),
  );

  state = {
    shownColumns: getShownColumns('orgVendorsShownColumns'),
    availableVendors: [],
  };

  handleVendorsListUpdate = (availableVendors: $ReadOnlyArray<string>) => {
    this.setState({ availableVendors });
  };

  handleSearch = (search: string) => {
    replaceQueryParams(this.props.history, { query: search || null });
  };

  handleColumnsChange = (shownColumns: $ReadOnlyArray<string>) => {
    storage.set('orgVendorsShownColumns', shownColumns);
    this.setState({ shownColumns });
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  renderTable = (props: {|
    org: ?$PropertyType<VendorsPageQueryResponse, 'org'>,
    filtered: boolean,
    sort: SortParam,
    includedColumns: { [string]: boolean },
  |}): React.Node => (
    <VendorsTablePagination
      org={props.org}
      totalCountOrg={props.org}
      shownColumns={columnSettings(this.vendorFields, this.state.shownColumns)}
      filtered={props.filtered}
      onChangeSort={this.handleSortChange}
      onVendorsListUpdate={this.handleVendorsListUpdate}
      sort={props.sort}
      tz={this.props.user.tz}
      includedColumns={props.includedColumns}
      pathPrefix={this.props.pathPrefix}
    />
  );

  render() {
    const { org, location, history, pathPrefix } = this.props;
    const shownColumns = this.state.shownColumns;
    const includedColumns = getTableIncludeProps(
      Object.keys(defaultTableComponents),
      this.vendorFields,
      shownColumns,
    );

    const filters = parseVendorsFilters(location.search, this.vendorFields);
    const filtered = Object.values(filters).some(val => !!val);
    // Default sorting
    const sortFilter = filters.sort || {
      key: 'NAME',
      asc: true,
    };

    const customSortField: ?FieldType = this.vendorFields.find(
      field => field.id === sortFilter.key,
    );

    const variables = {
      count: 25,
      filters: {
        sort: customSortField ? 'CUSTOM' : sortFilter.key,
        customFieldSortId: customSortField != null ? customSortField.id : null,
        direction: sortFilter.asc ? 'ASC' : 'DESC',
        query: filters.query,
        createdAt: filters.created_at,
        creatorIds: filters.created_by,
        updatedAt: filters.updated_at,
        updaterIds: filters.updated_by,
        cities: filters.city,
        states: filters.state,
        countries: filters.country,
        customFilters: this.vendorFields
          .filter(option => option.fieldName == null && filters[option.id] != null)
          .map(option => getCustomFieldVariable(filters[option.id], option)),
      },
      ...includedColumns,
    };

    return (
      <div>
        <VendorsHeaderBar
          org={org}
          history={history}
          filters={filters}
          filterInputs={variables.filters}
          vendorFields={this.vendorFields}
          onSearch={this.handleSearch}
          shownColumns={shownColumns}
          onColumnsChange={this.handleColumnsChange}
          orgVendorsCount={this.state.availableVendors.length}
          pathPrefix={pathPrefix}
        />
        <DefaultQueryRenderer
          query={query}
          variables={variables}
          renderSuccess={(props: VendorsPageQueryResponse) =>
            this.renderTable({
              org: props.org,
              filtered,
              sort: sortFilter,
              includedColumns,
            })
          }
          renderLoading={() =>
            this.renderTable({
              org: null,
              filtered,
              sort: sortFilter,
              includedColumns,
            })
          }
        />
      </div>
    );
  }
}

export default createFragmentContainer(
  VendorsPage,
  graphql`
    fragment VendorsPage_org on Org {
      id
      customFields(customizableType: [VENDOR]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ...VendorsHeaderBar_org
    }

    fragment VendorsPage_user on User {
      tz
    }
  `,
);
