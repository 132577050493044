/* @flow */
import sortBy from 'lodash/sortBy';

import type { RelayQuestionType, RelaySectionType } from 'components/EventRequestForm/lib/types';
import staticFields from 'components/FormLogic/lib/staticFields';
import { type ConditionField } from 'components/FormLogic/lib/types';

import type { EventRequestFormBuilderBody_requestForm } from '../__generated__/EventRequestFormBuilderBody_requestForm.graphql';

export const excludedFilters = [
  'DATE',
  'LOCATION',
  'DESCRIPTION',
  'NOTE',
  'EXPENSE',
  'ATTACHMENTS',
];

const getConditionFields = (
  requestForm: EventRequestFormBuilderBody_requestForm,
  section: RelaySectionType,
  question?: RelayQuestionType,
): $ReadOnlyArray<ConditionField> => {
  const staticConditionFields = Object.keys(staticFields)
    .filter(key => key !== 'company_name')
    .map(key => ({
      fieldName: key,
      label: staticFields[key],
    }));

  const conditionFields = sortBy(
    requestForm.sections.edges.map(edge => edge.node),
    'order',
  ).reduce((arr: $ReadOnlyArray<ConditionField>, currentSection: RelaySectionType) => {
    if (section.order < currentSection.order) return arr;
    if (section.order === currentSection.order && question == null) return arr;

    const questions = sortBy(
      currentSection.questions.edges.map(edge => edge.node),
      'order',
    ).reduce((questionsArr: $ReadOnlyArray<ConditionField>, currentQuestion: RelayQuestionType) => {
      if (
        section.order === currentSection.order &&
        question != null &&
        question.order <= currentQuestion.order
      )
        return questionsArr;
      if (excludedFilters.includes(currentQuestion.mapping)) return questionsArr;

      const questionOrder = (currentSection.order + 1 + currentQuestion.order) * 10;
      return [
        ...questionsArr,
        {
          ...currentQuestion.mappingCustomField,
          fieldName:
            currentQuestion.mapping === 'PLANNED_BUDGET'
              ? `${currentQuestion.mapping}-${currentQuestion.id}`
              : currentQuestion.mapping,
          label: currentQuestion.name,
          order: questionOrder,
        },
      ];
    }, []);

    return [...arr, ...questions];
  }, []);

  return [...staticConditionFields, ...conditionFields];
};

export default getConditionFields;
