/* @flow */
import React from 'react';
import { type Match, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { isBright } from 'utils/color';
import convertDateToLocal from 'utils/date/convertDateTimeToLocal';

import AllEventsCalendarEventWindow, {
  type ClickCoordinates,
} from './AllEventsCalendarEventWindow';
import { type AllEventsCalendarEvent } from './AllEventsCalendarView';

export const MAX_EVENTS = 4;
export const MAX_SCROLL_EVENTS = 7;
export const CARD_HEIGHT = 25;
export const CARD_VERTICAL_SPACING = 5;
export const CARD_HORIZONTAL_SPACING = 10;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  position: relative;
  height: ${CARD_HEIGHT}px;
  margin: ${CARD_VERTICAL_SPACING}px -${CARD_HORIZONTAL_SPACING}px 0;
`;

const CardMaskOuter = styled.div`
  height: 100%;
  overflow: hidden;
`;

const CardMaskInner = styled.div`
  position: relative;
  height: 100%;
`;

const CardLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  margin-left: 10px;
`;

const CardArrow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border: ${CARD_HEIGHT / 2}px solid transparent;
  border-left: ${CARD_HORIZONTAL_SPACING / 2}px solid currentColor;
  border-right-width: ${CARD_HORIZONTAL_SPACING / 2}px;
  transition: 0.2s opacity;
`;

const Card = styled.div`
  width: 100%;
  padding: ${CARD_VERTICAL_SPACING}px ${CARD_HORIZONTAL_SPACING}px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.2s opacity;
`;

const MoreLink = styled.a`
  margin-top: 10px;
  font-size: 12px;
  color: #39a8da;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

class AllEventsCalendarDay extends React.Component<
  {
    day: string,
    eventSpots: $ReadOnlyArray<?AllEventsCalendarEvent>,
    expanded?: boolean,
    onExpand: (day: string) => void,
    hoveringId: ?string,
    onHover: (hoveringId: ?string) => void,
    linkEventNameToBrief?: boolean,
    match: Match,
  },
  { shownEventSlug: ?string, clickCoordinates: ?ClickCoordinates },
> {
  static defaultProps = {
    onExpand: () => {},
  };

  state = { shownEventSlug: null, clickCoordinates: null };

  handleShowEvent = (e: SyntheticMouseEvent<HTMLInputElement>, slug: ?string) => {
    this.setState({
      shownEventSlug: slug,
      clickCoordinates: { clientX: e.clientX, clientY: e.clientY },
    });
  };

  handleHideEventWindow = () => {
    this.setState({ shownEventSlug: null });
  };

  render() {
    const {
      day,
      eventSpots,
      expanded,
      onExpand,
      hoveringId,
      onHover,
      linkEventNameToBrief,
      match,
    } = this.props;

    const isShared = !!match.params.resourceToken;
    const weekDay = moment(day).day();
    const weekStart = moment(day).startOf('week');

    return (
      <Root>
        {eventSpots.slice(0, expanded === false ? MAX_EVENTS : undefined).map((event, index) => {
          if (!event) return expanded === true ? null : <CardContainer key={String(index)} />;

          const start = moment(convertDateToLocal(moment.tz(event.startDate, event.tz))).startOf(
            'day',
          );
          const end = moment(
            convertDateToLocal(moment.tz(event.endDate || event.startDate, event.tz)),
          ).startOf('day');
          const showArrow = expanded !== true && weekDay === 6 && !end.isSame(day, 'day');
          const backgroundIndent = expanded === true ? 0 : start.diff(day, 'day');
          const foregroundIndent =
            expanded === true
              ? 0
              : Math.max(-weekDay, backgroundIndent - Math.min(0, -weekStart.diff(day, 'day')));
          const backgroundWidth = expanded === true ? 0 : end.diff(start, 'day');
          const foregroundWidth =
            expanded === true
              ? 0
              : Math.min(
                  6 - moment.max(weekStart, start).day(),
                  backgroundWidth + backgroundIndent - foregroundIndent,
                );

          return (
            <CardContainer key={event.id}>
              <CardMaskOuter
                style={{ marginRight: showArrow ? CARD_HORIZONTAL_SPACING : undefined }}
              >
                <CardMaskInner
                  style={{ marginRight: showArrow ? -CARD_HORIZONTAL_SPACING : undefined }}
                >
                  <CardLayer
                    style={{
                      width: `calc(${(backgroundWidth + 1) * 100}% - ${
                        CARD_HORIZONTAL_SPACING * 2
                      }px)`,
                      left: `${backgroundIndent * 100}%`,
                    }}
                  >
                    <Card
                      onMouseEnter={() => onHover(event && event.id)}
                      onMouseLeave={() => onHover(null)}
                      onClick={e => {
                        this.handleShowEvent(e, event ? event.slug : null);
                      }}
                      style={{
                        opacity: hoveringId === event.id ? 0.8 : 1,
                        backgroundColor: event.team.primaryColor,
                      }}
                    />
                  </CardLayer>

                  <CardLayer
                    style={{
                      width: `calc(${(foregroundWidth + 1) * 100}% - ${
                        CARD_HORIZONTAL_SPACING * 2
                      }px)`,
                      left: `${foregroundIndent * 100}%`,
                      color: isBright(event.team.primaryColor) ? '#000' : '#fff',
                      pointerEvents: 'none',
                    }}
                  >
                    <Card>{event.name}</Card>
                  </CardLayer>
                </CardMaskInner>
              </CardMaskOuter>

              {showArrow && (
                <CardArrow
                  style={{
                    opacity: hoveringId === event.id ? 0.8 : 1,
                    color: event.team.primaryColor,
                  }}
                />
              )}
            </CardContainer>
          );
        })}

        {eventSpots.length > MAX_EVENTS && expanded === false && (
          <MoreLink onClick={() => onExpand(day)}>
            {eventSpots.slice(MAX_EVENTS).filter(Boolean).length} more
          </MoreLink>
        )}
        {this.state.shownEventSlug && (
          <AllEventsCalendarEventWindow
            isShared={isShared}
            clickCoordinates={this.state.clickCoordinates}
            eventSlug={this.state.shownEventSlug}
            onHide={this.handleHideEventWindow}
            linkEventNameToBrief={linkEventNameToBrief}
          />
        )}
      </Root>
    );
  }
}

export default withRouter(AllEventsCalendarDay);
