/* @flow */
import React from 'react';
import styled from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import Tag from 'components/Contacts/Tag';

import CompanyIcon from './CompanyIcon';

import type { CompanySearchRow_company } from './__generated__/CompanySearchRow_company.graphql';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  line-height: 1.4;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
`;

const Main = styled.div`
  flex: 1 1 auto;
`;

const Name = styled.div`
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const StyledTag = styled(Tag)`
  margin-left: 7px;
`;

const SyncIcon = styled.i`
  position: absolute;
  right: 0;
  margin-right: 8px;
  font-size: 12px;
  color: ${props => props.theme.primaryActionColor};
`;

class CompanySearchRow extends React.PureComponent<{ company: CompanySearchRow_company }> {
  render() {
    const { salesforceId, name } = this.props.company;
    return (
      <Row>
        <CompanyIcon />
        <Main>
          <Name>
            {name}
            <StyledTag color="#4FC186" compact active>
              Company
            </StyledTag>
            {salesforceId && <SyncIcon className="fa fa-fw fa-refresh" />}
          </Name>
        </Main>
      </Row>
    );
  }
}

export default createFragmentContainer(
  CompanySearchRow,
  graphql`
    fragment CompanySearchRow_company on Company {
      name
      salesforceId
    }
  `,
);
