/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import type { SchedulerType } from '../AllEventsViewActions';
import EditViewWindowContent from './EditViewWindowContent';

import type { SavedEventListEditWindowQueryResponse } from './__generated__/SavedEventListEditWindowQuery.graphql';

const query = graphql`
  query SavedEventListEditWindowQuery($eventListId: ID!) {
    eventList: node(id: $eventListId) {
      ... on EventList {
        name
        viewerCanUpdate
        exportScheduler {
          frequency
          scheduledOn
          recipients {
            edges {
              node {
                id
                firstName
                lastName
                avatar
                email
              }
            }
          }
        }
      }
    }
  }
`;

export default class SavedEventListEditWindow extends React.PureComponent<{
  editedViewId: string,
  onHide: () => void,
  onUpdate: (name: string, scheduler: ?SchedulerType, hasChanges: boolean) => void,
  viewerCanUpdate: boolean,
}> {
  render() {
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ eventListId: this.props.editedViewId }}
        renderSuccess={(response: SavedEventListEditWindowQueryResponse) => {
          if (!response.eventList || !response.eventList.name) return null;
          return (
            <EditViewWindowContent
              exportScheduler={response.eventList.exportScheduler}
              viewerCanUpdateEventList={response.eventList.viewerCanUpdate}
              name={response.eventList.name}
              onHide={this.props.onHide}
              onUpdate={this.props.onUpdate}
              viewerCanUpdate={this.props.viewerCanUpdate}
            />
          );
        }}
        renderLoading={() => null}
      />
    );
  }
}
