/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TextField from 'components/material/TextField';

import InlineEditableCell from './InlineEditableCell';

const Root = styled.div`
  label {
    input {
      padding-right: 35px;
      text-align: right;
    }
  }
`;

export default class InlineNumberField extends React.PureComponent<
  {|
    value: ?number,
    onChange: (value: ?number) => void,
    placeholder?: string,
    getError?: ?(value: ?number) => ?string,
    updateColumnWidth?: () => void,
  |},
  { value: ?number, error: ?string },
> {
  state = { value: this.props.value, error: null };

  handleSave = () => {
    if (this.state.value !== this.props.value) {
      this.props.onChange(this.state.value);
    }
  };

  handleCancel = () => {
    this.setState(state => (state.value !== this.props.value ? { value: this.props.value } : null));
  };

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const filteredNumber = event.currentTarget.value.replace(/\D/g, '');
    this.setState({ value: filteredNumber === '' ? null : Number(filteredNumber), error: null });
  };

  handleValidate = (): boolean => {
    const { getError, updateColumnWidth } = this.props;
    const error: ?string = getError != null ? getError(this.state.value) : null;
    this.setState(state => (state.error === error ? null : { error }));
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  render() {
    return (
      <Root>
        <InlineEditableCell
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          onValidate={this.handleValidate}
          error={this.state.error}
          rightAligned
        >
          {({ onBlur, onKeyDown, editing }) => {
            // This <div> handles the 0 case.
            if (!editing) return this.props.value != null ? <div>{this.props.value}</div> : null;

            return (
              <TextField
                autoFocus
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={this.handleChange}
                value={this.state.value != null ? String(this.state.value) : ''}
                placeholder={this.props.placeholder || 'Ex: 1000'}
              />
            );
          }}
        </InlineEditableCell>
      </Root>
    );
  }
}
