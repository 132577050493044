/* @flow */

import { type MutationInput as UpdateMutationInput } from 'graph/mutations/eventBudgetCategory/updateEventBudgetCategory';

import CategoryActionCell from './CategoryActionCell';
import CategoryActualAmountCell from './CategoryActualAmountCell';
import CategoryActualAmountFooterCell from './CategoryActualAmountFooterCell';
import CategoryActualOfBudgetedCell from './CategoryActualOfBudgetedCell';
import CategoryActualOfBudgetedFooterCell from './CategoryActualOfBudgetedFooterCell';
import CategoryBudgetedAmountCell from './CategoryBudgetedAmountCell';
import CategoryBudgetedAmountFooterCell from './CategoryBudgetedAmountFooterCell';
import CategoryNameCell from './CategoryNameCell';
import CategoryPaidAmountCell from './CategoryPaidAmountCell';
import CategoryPaidAmountFooterCell from './CategoryPaidAmountFooterCell';
import CategoryPaidOfActualCell from './CategoryPaidOfActualCell';
import CategoryPaidOfActualFooterCell from './CategoryPaidOfActualFooterCell';

export type CategoryFooterCellPropsType<Event: {} = {}, Org: {} = {}> = {|
  org: Org,
  event: Event,
|};

export type CategoryCellPropsType<CategoryEdge: {}, Event: {} = {}, Org: {} = {}> = {|
  org: Org,
  event: Event,
  categoryEdge: ?CategoryEdge,
  categoryEdgeMock: ?CategoryEdge,
  adding: boolean,
  isAddingActive: boolean,
  rootElementRef: { current: ?HTMLElement },
  hasUncategorized: boolean,
  changeAdding: ({ categoryId?: string, budgetedAmount?: number }) => void,
  removeAdding: () => void,
  updateCategoryEdge: (
    $Exact<$Diff<UpdateMutationInput, { categoryId: string, eventId: string }>>,
    ?{ eventTotalBudgetedAmount: number },
  ) => Promise<mixed>,
  removeCategoryEdge: () => Promise<mixed>,
  changeCategoryEdge: (newCategoryId: string) => Promise<mixed>,
|};

const getColumnsConfig = ({ adding }: { adding: boolean }) => [
  {
    title: `Expense Category${adding ? ' *' : ''}`,
    component: CategoryNameCell,
    footerComponent: () => 'Total',
    grow: 1,
  },
  {
    title: `Planned${adding ? ' *' : ''}`,
    component: CategoryBudgetedAmountCell,
    footerComponent: CategoryBudgetedAmountFooterCell,
    align: 'right',
    minWidth: 147,
  },
  {
    title: 'Actual',
    component: CategoryActualAmountCell,
    footerComponent: CategoryActualAmountFooterCell,
    align: 'right',
    width: 147,
  },
  {
    title: 'Planned / Actual',
    component: CategoryActualOfBudgetedCell,
    footerComponent: CategoryActualOfBudgetedFooterCell,
    align: 'right',
    width: 152,
  },
  {
    title: 'Paid',
    component: CategoryPaidAmountCell,
    footerComponent: CategoryPaidAmountFooterCell,
    align: 'right',
    width: 147,
  },
  {
    title: 'Paid / Actual',
    component: CategoryPaidOfActualCell,
    footerComponent: CategoryPaidOfActualFooterCell,
    align: 'right',
    width: 130,
  },
  {
    title: '',
    component: CategoryActionCell,
    type: 'action',
  },
];

export default getColumnsConfig;
