/* @flow */
import React from 'react';

import COUNTRIES from 'config/countries.json';
import US_STATES from 'config/us_states.json';

import { type FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';

import EditableLinkField from 'components/material/EditableLinkField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';

import { type VendorDefaultFields } from './VendorFormFields';

type Props = {
  vendor: VendorDefaultFields,
  // eslint-disable-next-line
  viewerCanUpdate: boolean,
  handleUpdate: (changes: $Shape<VendorDefaultFields>) => void,
  field: FieldType,
  // eslint-disable-next-line
  currency?: string,
  errors: { [string]: string },
};

const countries = COUNTRIES.map(country => ({ label: country, value: country }));
const usStates = Object.keys(US_STATES).map(k => ({
  label: US_STATES[k],
  value: US_STATES[k],
}));

const suggestedFields = {
  title: ({ vendor, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={vendor.title}
      onBlur={e => {
        if (vendor.title !== e.currentTarget.value) {
          handleUpdate({ title: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.title}
      disabled={!viewerCanUpdate}
    />
  ),
  first_name: ({ vendor, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={vendor.firstName}
      onBlur={e => {
        if (vendor.firstName !== e.currentTarget.value) {
          handleUpdate({ firstName: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.first_name}
      disabled={!viewerCanUpdate}
    />
  ),
  last_name: ({ vendor, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={vendor.lastName}
      onBlur={e => {
        if (vendor.lastName !== e.currentTarget.value) {
          handleUpdate({ lastName: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.last_name}
      disabled={!viewerCanUpdate}
    />
  ),
  street: ({ vendor, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={vendor.street}
      onBlur={e => {
        if (vendor.street !== e.currentTarget.value) {
          handleUpdate({ street: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.street}
      disabled={!viewerCanUpdate}
    />
  ),
  city: ({ vendor, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={vendor.city}
      onBlur={e => {
        if (vendor.city !== e.currentTarget.value) {
          handleUpdate({ city: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.city}
      disabled={!viewerCanUpdate}
    />
  ),
  description: ({ vendor, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={vendor.description}
      onBlur={e => {
        if (vendor.description !== e.currentTarget.value) {
          handleUpdate({ description: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.description}
      disabled={!viewerCanUpdate}
    />
  ),
  zip: ({ vendor, handleUpdate, field, errors, viewerCanUpdate }: Props) => (
    <TextField
      defaultValue={vendor.zip}
      onBlur={e => {
        if (vendor.zip !== e.currentTarget.value) {
          handleUpdate({ zip: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      required={field.required}
      error={errors.zip}
      disabled={!viewerCanUpdate}
    />
  ),
  website: ({ vendor, handleUpdate, field, viewerCanUpdate, errors }: Props) => (
    <EditableLinkField
      defaultValue={vendor.website}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== vendor.website) {
          e.currentTarget.value = value;
          handleUpdate({ website: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.website}
    />
  ),
  twitter: ({ vendor, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={vendor.twitter}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== vendor.twitter) {
          e.currentTarget.value = value;
          handleUpdate({ twitter: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.twitter}
    />
  ),
  linkedin: ({ vendor, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={vendor.linkedin}
      onBlur={e => {
        const value = enforceHttpPrefix(e.currentTarget.value.trim());
        if (value !== vendor.linkedin) {
          e.currentTarget.value = value;
          handleUpdate({ linkedin: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.linkedin}
    />
  ),
  email: ({ vendor, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={vendor.email}
      onBlur={e => {
        if (vendor.email !== e.currentTarget.value) {
          handleUpdate({ email: e.currentTarget.value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.email}
    />
  ),
  phone1: ({ vendor, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={vendor.phone1}
      onBlur={e => {
        const value = e.currentTarget.value.trim();
        if (vendor.phone1 !== value) {
          handleUpdate({ phone1: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.phone1}
      to={`tel:${vendor.phone1 || ''}`}
    />
  ),
  company_phone: ({ vendor, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={vendor.companyPhone}
      onBlur={e => {
        const value = e.currentTarget.value.trim();
        if (vendor.companyPhone !== value) {
          handleUpdate({ companyPhone: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.company_phone}
      to={`tel:${vendor.companyPhone || ''}`}
    />
  ),
  phone2: ({ vendor, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <EditableLinkField
      defaultValue={vendor.phone2}
      onBlur={e => {
        const value = e.currentTarget.value.trim();
        if (vendor.phone2 !== value) {
          handleUpdate({ phone2: value });
        }
      }}
      label={field.label}
      name={field.fieldName || ''}
      readOnly={!viewerCanUpdate}
      required={field.required}
      error={errors.phone2}
      to={`tel:${vendor.phone2 || ''}`}
    />
  ),
  state: ({ vendor, handleUpdate, viewerCanUpdate, field, errors }: Props) =>
    vendor.country === 'United States' ? (
      <SelectField
        onChange={state => {
          if (state !== vendor.state) {
            handleUpdate({ state });
          }
        }}
        label={field.label}
        value={vendor.state}
        options={usStates}
        searchable
        clearable
        disabled={!viewerCanUpdate}
        required={field.required}
        error={errors.state}
      />
    ) : (
      <TextField
        defaultValue={vendor.state}
        onBlur={e => {
          if (vendor.state !== e.currentTarget.value) {
            handleUpdate({ state: e.currentTarget.value });
          }
        }}
        label={field.label}
        name={field.fieldName || ''}
        required={field.required}
        error={errors.state}
        disabled={!viewerCanUpdate}
      />
    ),
  country: ({ vendor, handleUpdate, viewerCanUpdate, field, errors }: Props) => (
    <SelectField
      label={field.label}
      value={vendor.country}
      onChange={country => {
        if (country !== vendor.country) {
          handleUpdate({ country });
        }
      }}
      options={countries}
      searchable
      clearable
      disabled={!viewerCanUpdate}
      required={field.required}
      error={errors.country}
    />
  ),
};

export default suggestedFields;
