/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import downloadCSV from 'utils/requests/downloadCSV';
import downloadPDF from 'utils/requests/downloadPDF';
import generateExportable from 'utils/requests/exportable/generateExportable';
import getProcessingStatus from 'utils/requests/exportable/getProcessingStatus';
import requestEmailDelivery from 'utils/requests/exportable/requestEmailDelivery';
import showErrorPopup from 'utils/showErrorPopup';

import SuccessImage from 'images/successfulExport.svg';
import Button from 'components/material/Button';
import ProgressBar from 'components/material/ProgressBar';
import Window, { WindowClose, WindowContent } from 'components/material/Window';

import exportTypes from './exportTypes';
import SendViaEmailOption from './SendViaEmailOption';

const StyledWindowContent = styled(WindowContent)`
  padding: 20px 25px 15px 25px;
`;

const StyledWindowClose = styled(WindowClose)`
  padding: 0;
  font-size: 36px;
  line-height: 20px;
`;

const Message = styled.div`
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: #4a5665;
  ${props =>
    props.centered &&
    css`
      text-align: center;
    `};
`;

const Instructions = styled.div`
  margin-top: 11px;
  font-size: 14px;
  line-height: normal;
  white-space: normal;
  color: #4a5665;
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: 10px;
  > div {
    background-color: #26d17c;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  ${props =>
    props.centered &&
    css`
      justify-content: center;
    `};
  margin-top: 15px;
  > button:first-child {
    margin-right: 22px;
  }
`;

const Success = styled.div`
  padding: 11px 0 27px 0;
  text-align: center;
  > img {
    margin-bottom: 13px;
  }
`;

type Exportable = $Keys<typeof exportTypes>;

export default class DownloadOverlayWithEmail extends React.Component<
  {
    downloadLink?: string,
    processUUID?: string,
    fileName: string,
    onHide: () => void,
    email?: ?string,
    exportable: Exportable,
    onDownload?: () => void,
  },
  {
    progress: ?number,
    processUUID: ?string,
    showEmailInput: boolean,
    completeMessage: ?string,
    downloading: boolean,
    fileUrl: ?string,
  },
> {
  timer: ?IntervalID;

  constructor(props: $PropertyType<DownloadOverlayWithEmail, 'props'>) {
    super(props);
    this.state = {
      progress: 0,
      processUUID: props.processUUID,
      showEmailInput: false,
      completeMessage: null,
      downloading: false,
      fileUrl: null,
    };
    if (props.processUUID == null && props.downloadLink != null) {
      const authorizeBy = props.exportable === 'admin' ? 'supportAccessToken' : 'user';
      generateExportable(props.downloadLink, authorizeBy)
        .catch(err => {
          showErrorPopup(err);
          this.props.onHide();
        })
        .then(processUUID => {
          this.setState({ processUUID });
        });
    }
  }

  componentDidMount() {
    this.timer = setInterval(this.progress, 2000);
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  stopTimer = () => {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  };

  progress = () => {
    const progress = this.state.progress;
    if (progress != null) {
      if (progress < 50) {
        const diff = Math.random() * 7;
        this.setState({ progress: progress + diff });
      } else if (this.state.progress !== 95) {
        const diff = Math.random() * 3;
        this.setState({ progress: Math.min(progress + diff, 95) });
      }
      this.checkStatus();
    }
  };

  checkStatus = () => {
    if (this.state.processUUID != null) {
      const { exportable, onHide } = this.props;
      const authorizeBy = exportable === 'admin' ? 'supportAccessToken' : 'user';
      getProcessingStatus(this.state.processUUID, exportable, authorizeBy)
        .catch(() => {
          showErrorPopup({
            message: `There was an error while processing ${exportTypes[
              exportable
            ].type.toLowerCase()} file.`,
          });
          onHide();
        })
        .then(result => {
          if (result != null && result.status === 'Processed') {
            this.stopTimer();
            this.setState({ progress: 100, fileUrl: result.fileUrl });
          }
        });
    }
  };

  handleEmailBtnClick = () => {
    if (this.props.email == null) {
      this.setState({ showEmailInput: true });
    } else {
      this.handleEmailConfirm(this.props.email);
    }
  };

  handleEmailCancel = () => {
    this.setState({ showEmailInput: false });
  };

  handleEmailConfirm = (email: string) => {
    if (this.state.processUUID != null) {
      const { exportable, fileName } = this.props;
      const authorizeBy = exportable === 'admin' ? 'supportAccessToken' : 'user';
      requestEmailDelivery(this.state.processUUID, email, exportable, fileName, authorizeBy);
      this.completeDownload(
        `The ${exportTypes[exportable].type.toLowerCase()} will be sent to your email.`,
      );
    }
  };

  getExportableDownloadMethod = () => {
    const file = exportTypes[this.props.exportable].file;
    switch (file) {
      case 'csv':
        return downloadCSV;
      case 'pdf':
        return downloadPDF;
      default:
        console.log(`please add appropriate download method for ${file} type`);
        return downloadCSV;
    }
  };

  handleDownload = () => {
    if (this.state.downloading) {
      return;
    }
    const { exportable, fileName, onHide } = this.props;
    this.setState({ downloading: true });

    const exportableDownload = this.getExportableDownloadMethod();

    if (this.state.fileUrl != null) {
      exportableDownload(this.state.fileUrl, fileName)
        .catch(() => {
          showErrorPopup({
            message: `There was an error while downloading ${exportTypes[
              exportable
            ].type.toLowerCase()} file.`,
          });
          onHide();
          this.setState({ downloading: false });
        })
        .then(() => {
          this.completeDownload(
            `The ${exportTypes[exportable].type.toLowerCase()} was successfully downloaded!`,
          );
          this.setState({ downloading: false });
        });
    }
  };

  completeDownload = (completeMessage: string) => {
    if (this.props.onDownload) {
      this.props.onDownload();
    }

    this.stopTimer();
    this.setState({ completeMessage });
    setTimeout(() => {
      this.props.onHide();
    }, 2000);
  };

  render() {
    return (
      <Window size="small" onHide={() => {}}>
        <StyledWindowContent>
          {this.state.completeMessage === null ? (
            <React.Fragment>
              <StyledWindowClose onClick={this.props.onHide} />
              {this.state.progress !== null && (
                <React.Fragment>
                  <Message>Processing download</Message>
                  <StyledProgressBar value={this.state.progress} />
                  <Instructions>
                    Please wait while we process your download or get it via email.
                  </Instructions>
                </React.Fragment>
              )}
              {this.state.showEmailInput ? (
                <SendViaEmailOption
                  onConfirm={this.handleEmailConfirm}
                  onCancel={this.handleEmailCancel}
                />
              ) : (
                <Buttons centered>
                  <Button
                    primary
                    label="Get via Email"
                    disabled={this.state.processUUID == null}
                    onClick={this.handleEmailBtnClick}
                  />
                  <Button
                    primary
                    label={`Download ${exportTypes[this.props.exportable].type}`}
                    disabled={this.state.progress !== 100}
                    onClick={this.handleDownload}
                    loading={this.state.downloading}
                  />
                </Buttons>
              )}
            </React.Fragment>
          ) : (
            <Success>
              <SuccessImage />
              <Message centered>{this.state.completeMessage}</Message>
            </Success>
          )}
        </StyledWindowContent>
      </Window>
    );
  }
}
