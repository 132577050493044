/* @flow */
import type { FieldType } from 'utils/customization/types';
import {
  booleanToStringParam,
  numberRangeToStringParam,
  stringArrayToStringParam,
  stringToStringParam,
} from 'utils/routing/stringifyQueryParamValues';

export default function getCustomFieldFilterConverterByKind(filter: FieldType) {
  switch (filter.kind) {
    case 'DATE':
      return { [filter.id]: stringToStringParam };
    case 'BOOLEAN':
      return { [filter.id]: booleanToStringParam };
    case 'NUMBER':
    case 'CURRENCY':
      return { [filter.id]: numberRangeToStringParam };
    default:
      return { [filter.id]: stringArrayToStringParam };
  }
}
