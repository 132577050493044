/**
 * @flow
 * @relayHash 03c6653162823eed98d4633e0c09a65c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChangeAdminInput = {
  clientMutationId?: ?string,
  userId: string,
  admin: boolean,
  fromWindow: string,
};
export type changeAdminMutationVariables = {|
  input: ChangeAdminInput
|};
export type changeAdminMutationResponse = {|
  +changeAdmin: ?{|
    +user: {|
      +id: string,
      +admin: boolean,
      +hasTeamAccess: boolean,
      +viewerCanRemove: boolean,
    |},
    +org: {|
      +subscription: {|
        +fatmLimit: ?number,
        +fatmCount: number,
      |}
    |},
  |}
|};
export type changeAdminMutation = {|
  variables: changeAdminMutationVariables,
  response: changeAdminMutationResponse,
|};
*/


/*
mutation changeAdminMutation(
  $input: ChangeAdminInput!
) {
  changeAdmin(input: $input) {
    user {
      id
      admin
      hasTeamAccess
      viewerCanRemove
    }
    org {
      subscription {
        fatmLimit
        fatmCount
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ChangeAdminInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ChangeAdminInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "admin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasTeamAccess",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanRemove",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fatmLimit",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fatmCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "changeAdminMutation",
  "id": null,
  "text": "mutation changeAdminMutation(\n  $input: ChangeAdminInput!\n) {\n  changeAdmin(input: $input) {\n    user {\n      id\n      admin\n      hasTeamAccess\n      viewerCanRemove\n    }\n    org {\n      subscription {\n        fatmLimit\n        fatmCount\n        id\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "changeAdminMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "changeAdmin",
        "storageKey": null,
        "args": v1,
        "concreteType": "ChangeAdminPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subscription",
                "storageKey": null,
                "args": null,
                "concreteType": "Subscription",
                "plural": false,
                "selections": [
                  v4,
                  v5
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "changeAdminMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "changeAdmin",
        "storageKey": null,
        "args": v1,
        "concreteType": "ChangeAdminPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "subscription",
                "storageKey": null,
                "args": null,
                "concreteType": "Subscription",
                "plural": false,
                "selections": [
                  v4,
                  v5,
                  v2
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc6c5152237f370ea1731f5973bb6d79';
module.exports = node;
