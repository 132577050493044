/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatTimeRange from 'utils/date/formatTimeRange';

import DescriptionRichText from '../../components/DescriptionRichText';
import HeadingField from '../../components/HeadingField';
import ParticipantsContainer from '../../components/ParticipantsContainer';
import UserContainer from '../../components/UserContainer';
import UserContainerInList from '../../components/UserContainerInList';
import UserField from '../../components/UserField';

import { type BriefScheduleItem_event } from './__generated__/BriefScheduleItem_event.graphql';
import { type BriefScheduleItem_scheduleItem } from './__generated__/BriefScheduleItem_scheduleItem.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 550px) {
    flex-wrap: wrap;
  }
`;

const SchedulTimeContainer = styled.div`
  flex: 0 0 200px;
`;

const SchedulTime = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #337fb2;
`;

const Description = styled(DescriptionRichText)`
  margin-top: 10px;
  padding-bottom: 0;
`;

const BriefScheduleItem = (props: {
  scheduleItem: BriefScheduleItem_scheduleItem,
  event: BriefScheduleItem_event,
}) => {
  const {
    scheduleItem: { title, location, allDay, startTime, endTime, participants, description },
    event: { tz: eventTimeZone },
    event,
  } = props;
  const startDate = startTime && moment.tz(startTime, eventTimeZone);
  const endDate = endTime && moment.tz(endTime, eventTimeZone);
  const date = allDay ? 'All Day' : formatTimeRange(startDate, endDate);
  const participantsItems = participants == null ? [] : participants.edges.map(({ node }) => node);
  return (
    <React.Fragment>
      <Container>
        <SchedulTimeContainer>
          <SchedulTime>{date}</SchedulTime>
        </SchedulTimeContainer>
        <div>
          <HeadingField mainText={title} secondaryText={location} />
          {description && (
            <Description
              name="Description"
              description={description}
              contentDataAvailable={description}
            />
          )}
          {participantsItems.length > 0 && (
            <ParticipantsContainer>
              <UserContainer>
                {participantsItems.map(participant => (
                  <UserContainerInList key={participant.id}>
                    <UserField event={event} user={participant} avatarRadius={20} />
                  </UserContainerInList>
                ))}
              </UserContainer>
            </ParticipantsContainer>
          )}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default createFragmentContainer(BriefScheduleItem, {
  event: graphql`
    fragment BriefScheduleItem_event on Event {
      tz
      ...UserField_event
    }
  `,
  scheduleItem: graphql`
    fragment BriefScheduleItem_scheduleItem on ScheduleItem {
      title
      location @include(if: $includeScheduleLocation)
      allDay
      startTime
      endTime
      description @include(if: $includeScheduleDescription)
      participants @include(if: $includeScheduleParticipants) {
        edges {
          node {
            id
            firstName
            lastName
            email
            phone
            officePhone
            title
            company
            ...BriefUserAvatar_user
          }
        }
      }
    }
  `,
});
