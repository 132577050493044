/* @flow */
import React from 'react';
import type { RouterHistory } from 'react-router-dom';

import type { FieldType } from 'utils/customization/types';

import getContactFilterConverters from 'components/Contacts/lib/getContactFilterConverters';
import type { ParsedContactsFilters } from 'components/Contacts/lib/parseContactsFilters';
import renderContactFilter from 'components/Contacts/lib/renderContactFilter';
import AdvancedFilter from 'components/material/Filters/Advanced/AdvancedFilter';
import { type FilterChangeType } from 'components/material/Filters/Advanced/FilterItems';

export default class ContactsFilters extends React.Component<{
  history: RouterHistory,
  filters: ParsedContactsFilters,
  filterOptions: $ReadOnlyArray<FieldType>,
  leadStatuses: $ReadOnlyArray<{| value: string, label: string |}>,
}> {
  filterRenderer = (
    field: FieldType,
    filters: ParsedContactsFilters,
    onFilterChange: FilterChangeType,
  ) => {
    return renderContactFilter(field, filters, this.props.leadStatuses, onFilterChange);
  };

  render() {
    const { filters, history, filterOptions } = this.props;

    return (
      <AdvancedFilter
        history={history}
        filters={filters}
        filterOptions={filterOptions}
        converterFn={getContactFilterConverters}
        filterRenderer={this.filterRenderer}
      />
    );
  }
}
