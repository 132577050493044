/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type PageStyling_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Design_registrationForm$ref: FragmentReference;
export type Design_registrationForm = {|
  +$fragmentRefs: PageStyling_registrationForm$ref,
  +$refType: Design_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Design_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "PageStyling_registrationForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'cb603cab11675c29a1aa152b63ed23aa';
module.exports = node;
