/* @flow */
import React from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone';

import formatDate from 'utils/date/formatDate';

import Button from 'components/material/Button';
import Overlay from 'components/material/Overlay';

import RangeCalendar from '../RangeCalendar';
import TimePicker from '../TimePicker';
import { type DatesConfig } from './index';
import normalizeDatesConfig from './normalizeDatesConfig';

const StyledOverlay = styled(Overlay)`
  width: 550px;
  margin-top: 3px;
  @media (max-width: 550px) {
    width: 260px;
  }
`;

const OverlayBorder = styled.div`
  height: 1px;
  background: #efefef;
`;

const OverlayFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid #efefef;
  font-size: 13px;
`;

const OverlayFooterButtons = styled.div`
  flex: 1 1 auto;
  text-align: right;
`;

const OverlayFocus = styled.div`
  outline: none;
`;

const ClearLink = styled.a`
  color: #f25d60;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #d84548;
  }
`;

const TimePickerRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 3px 0 10px;
  font-size: 13px;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const TimePickerColumn = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: baseline;
  min-width: 0;
  padding: 0 15px;
  &:first-child {
    justify-content: flex-end;
  }
`;

const TimePickerContainer = styled.div`
  width: 77px;
  margin: -8px 0;
`;

const DateLabel = styled.div`
  margin: 0 10px;
  font-weight: 500;
  color: #3a4c54;
`;

export default class DateTimeRangeOverlay extends React.PureComponent<{
  container: ?React.Component<any> | HTMLElement,
  target: ?React.Component<any>,
  show: boolean,
  onHide: () => void,
  ...DatesConfig,
  tz: string,
  onChange: (datesConfig: DatesConfig) => void,
  datesOnly?: boolean,
  disableHideOnClickOut?: boolean,
  forceRightEdge?: boolean,
  keepInViewport?: boolean,
  overlayRef?: $Call<React.createRef<any>>,
}> {
  handleHide = debounce(() => {
    this.props.onHide();
  }, 50);

  handleChange = (datesConfig: $Shape<DatesConfig>) => {
    this.props.onChange(
      normalizeDatesConfig({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        hideStartTime: this.props.hideStartTime,
        hideEndTime: this.props.hideEndTime,
        ...datesConfig,
      }),
    );
  };

  handleChangeDates = (startDate: ?string, endDate: ?string) => {
    const rangeChanged = startDate !== this.props.startDate;

    this.handleChange({
      startDate,
      endDate,
      hideStartTime: rangeChanged || this.props.hideStartTime,
      hideEndTime: rangeChanged || this.props.hideEndTime,
    });
  };

  handleChangeStartTime = (time: ?string) => {
    this.handleChange({
      startDate: time || moment(this.props.startDate).tz(this.props.tz).startOf('day').format(),
      hideStartTime: !time,
    });
  };

  handleChangeEndTime = (time: ?string) => {
    this.handleChange({
      endDate: time || moment(this.props.endDate).tz(this.props.tz).startOf('day').format(),
      hideEndTime: !time,
    });
  };

  handleRemoveDate = () => {
    this.props.onChange({
      startDate: null,
      endDate: null,
      hideStartTime: true,
      hideEndTime: true,
    });

    this.handleHide();
  };

  handleClickApply = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    this.handleHide();
  };

  render() {
    const startDate = this.props.startDate ? moment.tz(this.props.startDate, this.props.tz) : null;
    const endDate = this.props.endDate ? moment.tz(this.props.endDate, this.props.tz) : null;
    const isSameDay = startDate && (!endDate || startDate.isSame(endDate, 'date'));

    return (
      <StyledOverlay
        container={this.props.container}
        target={this.props.target}
        show={this.props.show}
        onHide={this.props.disableHideOnClickOut ? null : this.handleHide}
        forceRightEdge={this.props.forceRightEdge}
        keepInViewport={this.props.keepInViewport}
        overlayRef={this.props.overlayRef}
      >
        <OverlayFocus tabIndex={-1}>
          <RangeCalendar
            tz={this.props.tz}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            onChange={this.handleChangeDates}
          />

          {!this.props.datesOnly && startDate && (
            <React.Fragment>
              <OverlayBorder />

              <TimePickerRow>
                <TimePickerColumn>
                  <DateLabel>{formatDate(startDate)}</DateLabel>

                  <TimePickerContainer>
                    <TimePicker
                      tz={this.props.tz}
                      date={this.props.startDate || ''}
                      time={this.props.hideStartTime ? null : this.props.startDate}
                      onChange={this.handleChangeStartTime}
                      initialTime={
                        endDate && !this.props.hideEndTime
                          ? endDate
                              .clone()
                              .set({
                                year: startDate.year(),
                                month: startDate.month(),
                                date: startDate.date(),
                              })
                              .add(isSameDay ? -30 : 0, 'minute')
                              .format()
                          : null
                      }
                    />
                  </TimePickerContainer>
                </TimePickerColumn>

                <TimePickerColumn>
                  {endDate && !isSameDay && <DateLabel>{formatDate(endDate)}</DateLabel>}

                  <TimePickerContainer>
                    <TimePicker
                      tz={this.props.tz}
                      date={this.props.endDate || this.props.startDate || ''}
                      time={this.props.hideEndTime ? null : this.props.endDate}
                      onChange={this.handleChangeEndTime}
                      initialTime={
                        !this.props.hideStartTime
                          ? startDate
                              .clone()
                              .set({
                                year: (endDate || startDate).year(),
                                month: (endDate || startDate).month(),
                                date: (endDate || startDate).date(),
                              })
                              .add(isSameDay ? 30 : 0, 'minute')
                              .format()
                          : null
                      }
                    />
                  </TimePickerContainer>
                </TimePickerColumn>
              </TimePickerRow>
            </React.Fragment>
          )}

          <OverlayFooter>
            {(startDate || endDate) && (
              <ClearLink onClick={this.handleRemoveDate}>Remove</ClearLink>
            )}

            <OverlayFooterButtons>
              <Button label="Apply" primary onClick={this.handleClickApply} />
            </OverlayFooterButtons>
          </OverlayFooter>
        </OverlayFocus>
      </StyledOverlay>
    );
  }
}
