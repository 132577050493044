/* eslint-disable */

/*
 * This file is copied from a fork of alt-utils that removes React.createClass for React 15.6+
 * compatibility.  There was no package published for the fork so we're just copying it here.
 * Source: https://github.com/miguelespinoza/alt-utils
 */

import React from 'react';
import createReactClass from 'create-react-class';
const isFunction = x => typeof x === 'function';

export function eachObject(f, o) {
  o.forEach((from) => {
    Object.keys(Object(from)).forEach((key) => {
      f(key, from[key]);
    });
  });
}

export function assign(target, ...source) {
  eachObject((key, value) => target[key] = value, source);
  return target;
}

function connectToStores(Spec, Component = Spec) {
  // Check for required static methods.
  if (!isFunction(Spec.getStores)) {
    throw new Error('connectToStores() expects the wrapped component to have a static getStores() method');
  }
  if (!isFunction(Spec.getPropsFromStores)) {
    throw new Error('connectToStores() expects the wrapped component to have a static getPropsFromStores() method');
  }

  if (typeof Spec.storeDidChange === 'undefined') {
    var storeDidChange = (...args) => {}; // no-op
  } else if (!isFunction(Spec.storeDidChange)) {
    throw new Error('connectToStores() expects the storeDidChange() to be a function');
  } else {
    var storeDidChange = Spec.storeDidChange;
  }

  const StoreConnection = createReactClass({
    displayName: `Stateful${Component.displayName || Component.name || 'Container'}`,

    statics: {
      getDerivedStateFromProps(nextProps) {
        return Spec.getPropsFromStores(nextProps);
      },
    },

    getInitialState() {
      return Spec.getPropsFromStores(this.props, this.context);
    },

    componentDidMount() {
      const stores = Spec.getStores(this.props, this.context);
      this.storeListeners = stores.map((store) => {
        return store.listen(this.onChange);
      });
      if (Spec.componentDidConnect) {
        Spec.componentDidConnect(this.props, this.context);
      }
    },

    componentWillUnmount() {
      this.storeListeners.forEach(unlisten => unlisten());
    },

    onChange() {
      this.setState(Spec.getPropsFromStores(this.props, this.context));
      storeDidChange(this.state);
    },

    render() {
      return React.createElement(
        Component,
        assign({}, this.props, this.state),
      );
    },
  });
  if (Component.contextTypes) {
    StoreConnection.contextTypes = Component.contextTypes;
  }

  return StoreConnection;
}

export default connectToStores;
