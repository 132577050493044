/* @flow */

import AttendedContactsTotal from './AttendedContactsTotal';
import BoothDimensions from './BoothDimensions';
import BoothNumber from './BoothNumber';
import BriefWeb from './BriefWeb';
import BudgetActual from './BudgetActual';
import BudgetPaid from './BudgetPaid';
import BudgetPlanned from './BudgetPlanned';
import EventActions from './EventActions';
import EventContactsCount from './EventContactsCount';
import EventCreatedAt from './EventCreatedAt';
import EventCreator from './EventCreator';
import EventEndDate from './EventEndDate';
import EventFormat from './EventFormat';
import EventId from './EventId';
import EventLead from './EventLead';
import EventName from './EventName';
import EventProgress from './EventProgress';
import EventStaff from './EventStaff';
import EventStartDate from './EventStartDate';
import EventStatus from './EventStatus';
import EventUpdatedAt from './EventUpdatedAt';
import EventUpdater from './EventUpdater';
import EventVenueCity from './EventVenueCity';
import EventVenueCountry from './EventVenueCountry';
import EventVenueName from './EventVenueName';
import EventVenueState from './EventVenueState';
import EventVenueStreet from './EventVenueStreet';
import EventVenueZip from './EventVenueZip';
import EventVirtualLocation from './EventVirtualLocation';
import EventWebsite from './EventWebsite';
import MarketoId from './MarketoId';
import RegisteredContactsTotal from './RegisteredContactsTotal';
import EventRequestsFormName from './requests/EventRequestsFormName';
import EventRequestsRequestedBy from './requests/EventRequestsRequestedBy';
import EventRequestsRequestedDate from './requests/EventRequestsRequestedDate';
import EventRequestsReviewers from './requests/EventRequestsReviewers';
import EventRequestsStatus from './requests/EventRequestsStatus';
import SalesforceId from './SalesforceId';
import SalesforceLastSync from './SalesforceLastSync';
import SalesforceOpportunitiesAmount from './SalesforceOpportunitiesAmount';
import SalesforceOpportunitiesNumber from './SalesforceOpportunitiesNumber';
import SalesforceSyncStatus from './SalesforceSyncStatus';
import TeamName from './TeamName';

export {
  AttendedContactsTotal,
  BoothDimensions,
  BoothNumber,
  BriefWeb,
  BudgetActual,
  BudgetPaid,
  BudgetPlanned,
  EventActions,
  EventCreatedAt,
  EventCreator,
  EventEndDate,
  EventFormat,
  EventId,
  EventLead,
  EventName,
  EventProgress,
  EventRequestsFormName,
  EventRequestsRequestedBy,
  EventRequestsRequestedDate,
  EventRequestsReviewers,
  EventRequestsStatus,
  EventStaff,
  EventStartDate,
  EventStatus,
  EventUpdatedAt,
  EventUpdater,
  EventVenueCity,
  EventVenueCountry,
  EventVenueName,
  EventVenueState,
  EventVenueStreet,
  EventVenueZip,
  EventVirtualLocation,
  EventWebsite,
  MarketoId,
  RegisteredContactsTotal,
  SalesforceId,
  SalesforceLastSync,
  SalesforceOpportunitiesAmount,
  SalesforceOpportunitiesNumber,
  SalesforceSyncStatus,
  TeamName,
};

export const defaultTableComponents = {
  name: EventName,
  team: TeamName,
  start_date: EventStartDate,
  end_date: EventEndDate,
  venue_name: EventVenueName,
  venue_street: EventVenueStreet,
  venue_city: EventVenueCity,
  venue_state: EventVenueState,
  venue_zip: EventVenueZip,
  venue_country: EventVenueCountry,
  virtual_location: EventVirtualLocation,
  event_format: EventFormat,
  status: EventStatus,
  progress: EventProgress,
  website: EventWebsite,
  lead: EventLead,
  event_staff: EventStaff,
  booth: BoothNumber,
  booth_dimensions: BoothDimensions,
  planned_total_cost: BudgetPlanned,
  actual_total_cost: BudgetActual,
  paid_total_cost: BudgetPaid,
  sync_status: SalesforceSyncStatus,
  last_sync: SalesforceLastSync,
  salesforce_id: SalesforceId,
  opportunities_number: SalesforceOpportunitiesNumber,
  opportunities_amount: SalesforceOpportunitiesAmount,
  marketo_id: MarketoId,
  request_form: EventRequestsFormName,
  requested_by: EventRequestsRequestedBy,
  requested_date: EventRequestsRequestedDate,
  request_status: EventRequestsStatus,
  request_reviewers: EventRequestsReviewers,
  id: EventId,
  updater: EventUpdater,
  updated_at: EventUpdatedAt,
  created_at: EventCreatedAt,
  creator: EventCreator,
  brief_web: BriefWeb,
  contacts_count: EventContactsCount,
  registered_contacts_total: RegisteredContactsTotal,
  attended_contacts_total: AttendedContactsTotal,
};
