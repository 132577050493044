/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { BriefSharingGraphValue } from 'config/eventBriefSharingOptions';

import updateBriefTemplate from 'graph/mutations/briefTemplate/updateBriefTemplate';
import showModernMutationError from 'graph/utils/showModernMutationError';

import SharingOptions from 'components/Briefs/SharingOptions';

import type { TemplateSharing_org } from './__generated__/TemplateSharing_org.graphql';

class TemplateSharing extends React.PureComponent<{
  org: TemplateSharing_org,
}> {
  handleSharingOptionChange = (accessibility: BriefSharingGraphValue) => {
    updateBriefTemplate(this.props.org.briefTemplate.id, { accessibility }).catch(
      showModernMutationError,
    );
  };

  render() {
    return (
      <SharingOptions
        orgName={this.props.org.name}
        value={this.props.org.briefTemplate.accessibility}
        samlEnabled={this.props.org.hasSamlProvider}
        onChange={this.handleSharingOptionChange}
      />
    );
  }
}

export default createFragmentContainer(
  TemplateSharing,
  graphql`
    fragment TemplateSharing_org on Org {
      name
      hasSamlProvider
      briefTemplate {
        id
        accessibility
      }
    }
  `,
);
