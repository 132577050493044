/* @flow */
import React from 'react';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';

import UsersGroup from 'components/material/UsersGroup';

import type { RequestSubmissionFormReviewers_requestSubmission } from './__generated__/RequestSubmissionFormReviewers_requestSubmission.graphql';

const StyledUsersGroup = styled(UsersGroup)`
  margin: -6px 0;
`;

type Props = {
  requestSubmission: RequestSubmissionFormReviewers_requestSubmission,
};

const RequestSubmissionFormReviewers = ({ requestSubmission }: Props) => {
  const users = requestSubmission.reviewers.edges.map(({ node }) => node);

  return <StyledUsersGroup users={users} />;
};

export default createFragmentContainer(
  RequestSubmissionFormReviewers,
  graphql`
    fragment RequestSubmissionFormReviewers_requestSubmission on EventRequestSubmission {
      reviewers {
        edges {
          node {
            ...UsersGroup_users
          }
        }
      }
    }
  `,
);
