/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';
import enforceHttpPrefix from 'utils/enforceHttpPrefix';
import getLinkError from 'utils/getLinkError';

import type { ContactFieldInput } from 'graph/mutations/contact/updateContact';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import type { ContactLinkedin_contact } from './__generated__/ContactLinkedin_contact.graphql';

class ContactLinkedin extends React.PureComponent<{
  contact: ContactLinkedin_contact,
  fieldSettings: FieldType,
  updateColumnWidth: () => void,
  onUpdate: (changedProps: $Shape<ContactFieldInput>) => Promise<void>,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleUpdate = (value: ?string) => {
    const linkValue = value ? enforceHttpPrefix(value.trim()) : null;
    if (!this.getError(linkValue) && linkValue !== this.props.contact.linkedin) {
      this.props.onUpdate({ linkedin: linkValue });
      this.props.updateColumnWidth();
    }
  };

  getError = (value: ?string): ?string => {
    return getLinkError(value, this.props.fieldSettings.required);
  };

  render() {
    const { contact, updateColumnWidth, fieldSettings } = this.props;
    return (
      <InlineTextField
        placeholder="Ex: https://www.example.com"
        value={contact.linkedin || ''}
        onChange={this.handleUpdate}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        disabled={fieldSettings.restrictChangingValue}
      >
        {contact.linkedin && (
          <InlineExpandableText>
            <a
              onMouseDown={this.handleMouseDown}
              href={contact.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contact.linkedin}
            </a>
          </InlineExpandableText>
        )}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  ContactLinkedin,
  graphql`
    fragment ContactLinkedin_contact on Contact {
      linkedin
    }
  `,
);
