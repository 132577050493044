/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';

import { stringifyLocation } from 'utils/Location';
import type { Location } from 'utils/locations/locationTypes';

import updateEvent from 'graph/mutations/event/updateEvent';
import createLocation from 'graph/mutations/location/createLocation';
import showModernMutationError from 'graph/utils/showModernMutationError';

import LocationAutocomplete from 'components/material/LocationPicker/LocationAutocomplete';
import LocationForm from 'components/material/LocationPicker/LocationForm';
import Overlay from 'components/material/Overlay';

import { type PhysicalLocationOverlay_event } from './__generated__/PhysicalLocationOverlay_event.graphql';

const StyledOverlay = styled(Overlay)`
  width: 100%;
  min-width: 440px;
  padding: 25px 30px;
`;

const LinksRow = styled.div`
  display: flex;

  &:not(:empty) {
    margin-top: 20px;
  }
`;

const Link = styled.a`
  font-size: 12px;
  color: #3ba9da;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const RemoveLink = styled(Link)`
  margin-left: auto;
  color: #f38183;
`;

class PhysicalLocationOverlay extends React.PureComponent<
  {
    show: boolean,
    target: any,
    container: any,
    event: PhysicalLocationOverlay_event,
    onHide: () => void,
  },
  {
    showForm: boolean,
    locationCityError: boolean,
  },
> {
  state = {
    showForm: false,
    locationCityError: false,
  };

  handleShowForm = () => {
    this.setState({ showForm: true });
  };

  handleHideForm = () => {
    this.setState({ showForm: false });
  };

  handleChangeLocations = async (primaryLocation: Location) => {
    if ((primaryLocation && primaryLocation.google_placeid) || !primaryLocation) {
      // Close overlay immediately if location is removed or autocomplete location is selected
      this.props.onHide();
    }

    try {
      const location =
        primaryLocation && (await createLocation(this.props.event.id, primaryLocation));

      const response = await updateEvent(
        this.props.event.id,
        {
          primaryLocationId: location ? location.id : null,
        },
        'header',
      );

      if (location) {
        this.props.onHide();
      }

      return response.updateEvent && response.updateEvent.event;
    } catch (error) {
      showModernMutationError(error);

      return null;
    }
  };

  handleRemove = () => {
    this.handleChange();
  };

  handleSelectLocation = (primaryLocation: Location) => {
    const locationCityError = !!primaryLocation && !primaryLocation.city;

    this.setState(state =>
      state.locationCityError === locationCityError ? null : { locationCityError },
    );

    const locationChanged = !isEqual(primaryLocation, this.props.event.primaryLocation);

    if (locationCityError || !locationChanged) return Promise.resolve();

    return this.handleChange((primaryLocation: Location));
  };

  handleChange = async (primaryLocation: Location) => {
    if ((primaryLocation && primaryLocation.google_placeid) || !primaryLocation) {
      // Close overlay immediately if location is removed or autocomplete location is selected
      this.props.onHide();
    }

    try {
      const location =
        primaryLocation && (await createLocation(this.props.event.id, primaryLocation));

      const response = await updateEvent(
        this.props.event.id,
        {
          primaryLocationId: location ? location.id : null,
        },
        'header',
      );

      if (location) {
        this.props.onHide();
      }

      return response.updateEvent && response.updateEvent.event;
    } catch (error) {
      showModernMutationError(error);

      return null;
    }
  };

  renderLocationForm = () => {
    const { event } = this.props;

    if (this.state.showForm) {
      return (
        <LocationForm
          defaultLocation={event.primaryLocation}
          onReset={this.handleHideForm}
          onSave={this.handleSelectLocation}
          mapControlSettings={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
          }}
        />
      );
    }

    return (
      <>
        <LocationAutocomplete
          defaultValue={stringifyLocation(event.primaryLocation)}
          label="Event Address"
          onSelectLocation={this.handleSelectLocation}
          error={this.state.locationCityError ? 'Please specify city for the location' : null}
          clearable
        />
        <LinksRow>
          <Link onClick={this.handleShowForm}>Edit Address Manually</Link>
          {event.primaryLocation && (
            <RemoveLink onClick={this.handleRemove}>Remove Location</RemoveLink>
          )}
        </LinksRow>
      </>
    );
  };

  render() {
    return (
      <StyledOverlay
        show={this.props.show}
        target={this.props.target}
        onHide={this.props.onHide}
        container={this.props.container}
        placement="auto"
      >
        {this.renderLocationForm()}
      </StyledOverlay>
    );
  }
}

export default createFragmentContainer(
  PhysicalLocationOverlay,
  graphql`
    fragment PhysicalLocationOverlay_event on Event {
      id
      name
      startDate
      startDateAllDay
      endDate
      endDateAllDay
      tz
      primaryLocation {
        id
        name
        address1
        address2
        city
        state
        country
        postal
        website
        note
      }
    }
  `,
);
