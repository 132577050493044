/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventDatesComponent_registrationForm$ref: FragmentReference;
export type EventDatesComponent_registrationForm = {|
  +eventStartDate: any,
  +eventEndDate: ?any,
  +startDateAllDay: ?boolean,
  +endDateAllDay: ?boolean,
  +tz: string,
  +org: ?{|
    +settings: {|
      +tz: string
    |}
  |},
  +$refType: EventDatesComponent_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tz",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventDatesComponent_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "eventStartDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "eventEndDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "startDateAllDay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endDateAllDay",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "org",
      "storageKey": null,
      "args": null,
      "concreteType": "Org",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "settings",
          "storageKey": null,
          "args": null,
          "concreteType": "OrgSettings",
          "plural": false,
          "selections": [
            v0
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '99f7420d085be103e1345881b467b47b';
module.exports = node;
