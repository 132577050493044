/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatTime from 'utils/date/formatTime';

import AutocompleteInput, { ClearIcon } from 'components/material/AutocompleteInput';

export const TimeRow = styled.div`
  padding: 5px 10px;
  text-align: right;
  white-space: nowrap;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const StyledAutocompleteInput = styled(AutocompleteInput)`
  ${ClearIcon} {
    top: 22px;
  }
`;

export default class TimePicker extends React.PureComponent<{
  tz: string,
  label?: string,
  date: string,
  time: ?string,
  onChange: (time: ?string) => void,
  disabled?: boolean,
  onFocus?: () => void,
  onBlur?: () => void,
  initialTime?: ?string,
  className?: string,
  autoFocus?: boolean,
  clearable?: boolean,
  error?: ?string,
  footerContent?: React.Node,
  defaultInterval: number,
}> {
  overlayRef = React.createRef();

  static defaultProps = {
    clearable: true,
    defaultInterval: 30,
  };

  timeOptions = (query: string): $ReadOnlyArray<moment> => {
    let interval = this.props.defaultInterval;

    if (/^\d{1,2}:\d{2}/.test(query)) interval = 1;
    else if (/^\d{1,2}:\d/.test(query)) interval = 5;
    else if (/^\d{1,2}:/.test(query)) interval = 15;

    const options = [];

    const startOfDay = moment(this.props.date).tz(this.props.tz).startOf('day');
    const nextDay = startOfDay.clone().add(1, 'day');

    for (let day = startOfDay; +day < +nextDay; day = day.clone().add(interval, 'minutes')) {
      options.push(day);
    }

    return options;
  };

  initialFocus = () => {
    const options = this.timeOptions('');

    const currentTime = this.props.time ? moment(this.props.time).tz(this.props.tz) : null;
    const initialTime =
      currentTime ||
      (this.props.initialTime
        ? moment(this.props.initialTime).tz(this.props.tz)
        : moment(this.props.date).tz(this.props.tz).startOf('day').hours(8));

    const focusIndex = options.findIndex((option, index) =>
      initialTime.isBetween(option, options[index + 1], null, '[)'),
    );

    if (focusIndex === -1) return 0;

    return focusIndex;
  };

  handleSelectTime = (time: ?moment) => {
    if (time) {
      const date = moment(this.props.date).tz(this.props.tz).startOf('day');

      date.hours(time.hours());
      date.minutes(time.minutes());

      this.props.onChange(date.format());
    } else {
      this.props.onChange(null);
    }
  };

  handleFilterTimeOptions = (query: string) => {
    const normalizedQuery = query
      .toLowerCase()
      .replace(/\s+/g, '')
      .replace(/^(\d{1,2})\./, '$1:')
      .replace(/^(\d{1,2})([^:0-9]+)/, '$1:00$2');

    return this.timeOptions(normalizedQuery).filter(time =>
      this.formatTimeOption(time).replace(/\s+/g, '').startsWith(normalizedQuery),
    );
  };

  renderTimeOption = (time: moment) => <TimeRow>{this.formatTimeOption(time)}</TimeRow>;

  formatTimeOption = (time: moment) => formatTime(time, { hideTz: true });

  render() {
    const defaultQuery = this.props.time
      ? this.formatTimeOption(moment(this.props.time).tz(this.props.tz))
      : '';

    return (
      <StyledAutocompleteInput
        className={this.props.className}
        defaultQuery={defaultQuery}
        onFilterOptions={this.handleFilterTimeOptions}
        onSelect={this.handleSelectTime}
        renderOption={this.renderTimeOption}
        renderOptionString={this.formatTimeOption}
        showOptionsOnEmpty
        placeholder="hh:mm"
        resetOnBlur
        clearable={this.props.clearable}
        overlayMaxHeight={this.props.footerContent == null ? 135 : 166}
        label={this.props.label}
        disabled={this.props.disabled}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        initialFocus={this.initialFocus()}
        autoFocus={this.props.autoFocus}
        footerContent={this.props.footerContent}
        error={this.props.error}
      />
    );
  }
}
