/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactFormBuilder_contactForm$ref = any;
export type Alignment = "CENTER" | "LEFT" | "RIGHT";
export type Measurement = "PERCENTAGE" | "PX";
import type { FragmentReference } from "relay-runtime";
declare export opaque type FormComponent_componentProps$ref: FragmentReference;
export type FormComponent_componentProps = {|
  +formComponent: ?{|
    +fontFamily: ?string,
    +fontStyle: ?string,
    +color: ?string,
    +buttonCopy: ?string,
    +buttonColor: ?string,
    +buttonTextColor: ?string,
    +width: number,
    +widthMeasurement: Measurement,
    +alignment: Alignment,
    +contactForm: {|
      +$fragmentRefs: ContactFormBuilder_contactForm$ref
    |},
  |},
  +$refType: FormComponent_componentProps$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "FormComponent_componentProps",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "formComponent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationFormComponent",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fontFamily",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fontStyle",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "color",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "buttonCopy",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "buttonColor",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "buttonTextColor",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "width",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "widthMeasurement",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "alignment",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contactForm",
          "storageKey": null,
          "args": null,
          "concreteType": "ContactForm",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "ContactFormBuilder_contactForm",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b5cdcea6ec3e3217c8e91cd3ad4e5172';
module.exports = node;
