/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CategoryActionCell_event$ref = any;
type CategoryActualAmountFooterCell_event$ref = any;
type CategoryActualOfBudgetedFooterCell_event$ref = any;
type CategoryBudgetedAmountFooterCell_event$ref = any;
type CategoryNameCell_event$ref = any;
type CategoryPaidAmountFooterCell_event$ref = any;
type CategoryPaidOfActualFooterCell_event$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoriesTable_event$ref: FragmentReference;
export type CategoriesTable_event = {|
  +id: string,
  +totalBudgetedAmount: number,
  +totalActualAmount: number,
  +totalPaidAmount: number,
  +$fragmentRefs: CategoryNameCell_event$ref & CategoryActionCell_event$ref & CategoryBudgetedAmountFooterCell_event$ref & CategoryActualAmountFooterCell_event$ref & CategoryActualOfBudgetedFooterCell_event$ref & CategoryPaidAmountFooterCell_event$ref & CategoryPaidOfActualFooterCell_event$ref,
  +$refType: CategoriesTable_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CategoriesTable_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalBudgetedAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalActualAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalPaidAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryNameCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryActionCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryBudgetedAmountFooterCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryActualAmountFooterCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryActualOfBudgetedFooterCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryPaidAmountFooterCell_event",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryPaidOfActualFooterCell_event",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '4459371f37a8b88656a285d2f3d15abc';
module.exports = node;
