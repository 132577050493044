/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type Match, type RouterHistory, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { type OrgEventViewWindow } from 'utils/analytics/events/updatedOrgEventView';
import type { FieldType } from 'utils/customization/types';
import replaceQueryParams from 'utils/routing/replaceQueryParams';

import AllEventsSearchSuggestion from 'components/AllEvents/AllEventsSearchSuggestion';
import {
  type filtersForExportType,
  type ParsedAllEventsFilters,
} from 'components/AllEvents/lib/parseAllEventsFilters';
import ColumnFilters from 'components/material/ColumnFilters';
import type { ColumnConfiguration, ColumnGroupConfiguration } from 'components/material/table';
import Search from 'components/Search';

import headerTitles from '../../lib/headerTitles';
import AllEventsActionButtons from './AllEventsActionButtons';
import AllEventsFilters from './AllEventsFilters';
import AllEventsMassUpdatePanel from './AllEventsMassUpdatePanel';
import AllEventsViewActions from './AllEventsViewActions';
import AllEventsViewModes from './AllEventsViewModes';

import { type AllEventsHeaderBar_eventList } from './__generated__/AllEventsHeaderBar_eventList.graphql';
import { type AllEventsHeaderBar_org } from './__generated__/AllEventsHeaderBar_org.graphql';
import { type AllEventsHeaderBar_user } from './__generated__/AllEventsHeaderBar_user.graphql';

const Container = styled.div`
  padding-top: 15px;
`;

const StyledSearch = styled(Search)`
  margin-left: 10px;
`;

const SearchWrapper = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  min-width: 239px;
  background: #fff;
`;

const RowItem = styled.div`
  min-height: 31px;
`;
const RowItem1 = styled(RowItem)``;
const RowItem2 = styled(RowItem)``;
const RowItem3 = styled(RowItem)`
  flex-grow: 1;
  > div {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    transition-duration: 0.3s;
  }
`;
const RowItem4 = styled(RowItem)``;

const TopRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &:first-of-type {
    margin-bottom: 21px;
  }
`;

const BottomRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 13px;
  .sidebar-shown & {
    @media (max-width: 1400px) {
      ${RowItem3} {
        > div {
          left: 62%;
        }
      }
    }

    @media (max-width: 1280px) {
      ${RowItem1} {
        order: 1;
        width: 50%;
        margin-bottom: 10px;
      }
      ${RowItem2} {
        order: 3;
        width: 50%;
        ${StyledSearch} {
          margin-left: 0;
        }
      }
      ${RowItem3} {
        order: 2;
        width: 50%;
        margin-bottom: 10px;
        flex-grow: unset;
        > div {
          position: unset;
          margin-top: 4px;
          transform: none;
          div:nth-of-type(3) {
            > div {
              margin-right: 0;
            }
          }
        }
      }
      ${RowItem4} {
        order: 4;
        align-self: flex-end;
        width: ${props => (props.showUpdateEvent ? '50%' : '100%')};
      }
    }
  }
  @media (max-width: 1160px) {
    ${RowItem3} {
      > div {
        left: 62%;
      }
    }
  }

  @media (max-width: 1040px) {
    ${RowItem1} {
      order: 1;
      width: 50%;
      margin-bottom: 10px;
    }
    ${RowItem2} {
      order: 3;
      width: 50%;
      ${StyledSearch} {
        margin-left: 0;
      }
    }
    ${RowItem3} {
      order: 2;
      width: 50%;
      margin-bottom: 10px;
      flex-grow: unset;
      > div {
        position: unset;
        margin-top: 4px;
        transform: none;
        div:nth-of-type(3) {
          > div {
            margin-right: 0;
            padding-right: 0;
          }
        }
      }
    }
    ${RowItem4} {
      order: 4;
      align-self: flex-end;
      width: ${props => (props.showUpdateEvent ? '50%' : '100%')};
    }
  }

  @media (max-width: 450px) {
    ${RowItem3} {
      > div {
        svg {
          display: none;
        }
        div {
          margin-right: 3px;
        }
      }
    }
    ${RowItem2} {
      ${SearchWrapper} {
        position: ${props => props.searchFocused && 'absolute'};
        min-width: ${props => (props.searchFocused ? '100%' : '171px')};
      }
      ${StyledSearch} {
        ${props =>
          props.searchFocused &&
          css`
            max-width: unset;
          `}
        input {
          padding-right: ${props => (props.searchFocused ? '123px' : '22px')};
        }
        div:nth-of-type(2) {
          display: ${props => (props.searchFocused ? 'flex' : 'none')};
        }
      }
    }
  }
`;

const Title = styled.div`
  margin-top: 10px;
  margin-right: auto;
  font-size: 20px;
  font-weight: bold;
  color: #000;
`;

const StyledAllEventsViewActions = styled(AllEventsViewActions)`
  margin-top: 10px;
`;

const TableActions = styled.div`
  display: flex;
  flex: 0 auto;
`;

class AllEventsHeaderBar extends React.Component<
  {
    org: AllEventsHeaderBar_org,
    user: AllEventsHeaderBar_user,
    eventList: ?AllEventsHeaderBar_eventList,
    match: Match,
    history: RouterHistory,
    location: Location,
    filters: ParsedAllEventsFilters,
    eventFields: $ReadOnlyArray<FieldType>,
    csvExportVariables: filtersForExportType,
    pathPrefix: string,
    shownColumns: $ReadOnlyArray<string>,
    viewMode: 'calendar' | 'table' | 'map',
    fromWindow: OrgEventViewWindow,
    className?: string,
    allColumns?: ColumnConfiguration,
    onColumnsChange?: ($ReadOnlyArray<string>) => void,
    currentSelectedEvents?: $ReadOnlyArray<string>,
    onUpdateTableColumnWidths: () => void,
    groups: ColumnGroupConfiguration,
    eventsCount: number,
  },
  {
    searchFocused: boolean,
  },
> {
  state = {
    searchFocused: false,
  };

  searchContainerRef = React.createRef();

  componentDidMount() {
    window.addEventListener('mousedown', this.handleSearchUnFocus);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleSearchUnFocus);
  }

  handleEventsSearch = (search: string) => {
    replaceQueryParams(this.props.history, { search: search || null });
  };

  handleSearchFocus = () => {
    this.setState({ searchFocused: true });
  };

  handleSearchUnFocus = (e: SyntheticEvent<HTMLDivElement>) => {
    if (
      this.searchContainerRef &&
      this.searchContainerRef.current &&
      !this.searchContainerRef.current.contains(e.target)
    ) {
      this.setState({ searchFocused: false });
    }
  };

  render() {
    const {
      filters,
      eventFields,
      groups,
      viewMode,
      match,
      pathPrefix,
      history,
      location,
      org,
      user,
      eventList,
      allColumns,
      shownColumns,
      onColumnsChange,
      fromWindow,
      csvExportVariables,
      className,
      currentSelectedEvents,
      onUpdateTableColumnWidths,
    } = this.props;

    const searchObject = filters.search;
    const title =
      (eventList && eventList.name) ||
      (match.params.list_id && headerTitles[match.params.list_id]) ||
      headerTitles.all_events;

    return (
      <DocumentTitle title={title}>
        <Container className={className}>
          <TopRow>
            <Title>{title}</Title>
            <StyledAllEventsViewActions
              org={org}
              user={user}
              eventList={eventList}
              csvExportVariables={csvExportVariables}
              eventFields={eventFields}
              shownColumns={shownColumns}
              filters={filters}
              history={history}
              location={location}
              match={match}
            />
          </TopRow>
          <BottomRow
            searchFocused={this.state.searchFocused}
            showUpdateEvent={currentSelectedEvents == null || currentSelectedEvents.length === 0}
            showExactPhraseToggle={searchObject && searchObject.search.length > 0}
          >
            {currentSelectedEvents == null || currentSelectedEvents.length === 0 ? (
              <RowItem1 className="search-container">
                <AllEventsActionButtons
                  history={history}
                  org={org}
                  fromWindow={
                    filters.date && filters.date.key === 'upcoming'
                      ? 'upcoming events'
                      : 'all events'
                  }
                />
              </RowItem1>
            ) : (
              <RowItem1>
                <AllEventsMassUpdatePanel
                  eventFields={eventFields}
                  selectedEvents={currentSelectedEvents}
                  onUpdateTableColumnWidths={onUpdateTableColumnWidths}
                />
              </RowItem1>
            )}
            {currentSelectedEvents == null || currentSelectedEvents.length === 0 ? (
              <RowItem2>
                <SearchWrapper ref={this.searchContainerRef}>
                  <StyledSearch
                    onSearch={this.handleEventsSearch}
                    onFocus={this.handleSearchFocus}
                    search={searchObject && searchObject.search}
                    exactSearch={searchObject && searchObject.exactMatch}
                    placeholder="Search events"
                    suggestionComponent={props => {
                      return this.props.eventsCount === 0 ? (
                        <AllEventsSearchSuggestion {...props} />
                      ) : null;
                    }}
                  />
                </SearchWrapper>
              </RowItem2>
            ) : null}
            <RowItem3>
              <AllEventsViewModes
                pathPrefix={pathPrefix}
                viewMode={viewMode}
                history={history}
                fromWindow={fromWindow}
              />
            </RowItem3>
            <RowItem4>
              <TableActions>
                <AllEventsFilters
                  history={history}
                  filters={filters}
                  filterOptions={eventFields}
                  groups={groups}
                  viewMode={viewMode}
                  mobileBreakpoint={540}
                />
                {shownColumns && allColumns && onColumnsChange && (
                  <ColumnFilters
                    filters={allColumns}
                    groups={groups}
                    shownFilters={shownColumns}
                    onFilterChange={onColumnsChange}
                    mobileBreakpoint={540}
                  />
                )}
              </TableActions>
            </RowItem4>
          </BottomRow>
        </Container>
      </DocumentTitle>
    );
  }
}

export default createFragmentContainer(withRouter(AllEventsHeaderBar), {
  org: graphql`
    fragment AllEventsHeaderBar_org on Org {
      ...AllEventsActionButtons_org
      ...AllEventsViewActions_org
    }
  `,
  user: graphql`
    fragment AllEventsHeaderBar_user on User {
      ...AllEventsViewActions_user
    }
  `,
  eventList: graphql`
    fragment AllEventsHeaderBar_eventList on EventList {
      name
      ...AllEventsViewActions_eventList
    }
  `,
});
