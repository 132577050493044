/* @flow */

import type { RawDraftContentState } from 'draft-js';

type User = {
  slug: string,
  name: string,
};

export default function convertFromString(
  rawString: string,
  users: Array<User>,
): RawDraftContentState {
  const slugs = [];

  const blocks = rawString.split('\n').map(line => {
    const entityRanges = [];
    let delta = 0;
    const populatedLine = line.replace(/(^|\s)@(\S+)/g, (full, fwdSp, slug, index) => {
      const user = users.find(u => u.slug === slug);

      if (!user) return full;

      entityRanges.push({
        key: slugs.length,
        length: user.name.length,
        offset: index + delta + (index === 0 ? 0 : 1),
      });
      slugs.push(slug);
      delta += user.name.length - slug.length - 1;

      return `${fwdSp}${user.name}`;
    });

    return {
      data: {},
      depth: 0,
      inlineStyleRanges: [],
      entityRanges,
      text: populatedLine,
      type: 'unstyled',
    };
  });

  const entityMap = slugs.map(slug => ({
    type: 'MENTION',
    mutability: 'IMMUTABLE',
    data: { slug },
  }));

  return { blocks, entityMap };
}
