/* @flow */

import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 25px;
`;

const ParticipantsContainer = (props: { children: React.Node }) => {
  return <Container>{props.children}</Container>;
};
export default ParticipantsContainer;
