/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

const mutation = graphql`
  mutation addTaskVendorMutation($input: AddDeliverableVendorInput!) {
    addDeliverableVendor(input: $input) {
      vendorEdge {
        __typename
        node {
          id
          name
        }
      }
    }
  }
`;

export default function addTaskVendor(taskId: string, vendorId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableId: taskId,
        vendorId,
      },
    },
    updater: addRecordToConnection({
      parentId: taskId,
      edgeName: 'vendorEdge',
      connections: [{ field: 'vendors' }],
    }),
  });
}
