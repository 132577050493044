/* @flow */
import React from 'react';
import styled from 'styled-components';

import CalendarIcon from 'images/calendarIcon.svg';

import DateTimeInput from '../DateTimeInput';
import DateTimeOverlay from './DateTimeOverlay';

const Root = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
`;

const Icon = styled.div`
  position: absolute;
  right: 0;
  top: 20px;
  width: 13px;
  height: 13px;
  color: ${props => (props.showOverlay ? '#3ba9da' : '#96a2ab')};
`;

export const InputWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
`;

export type DateConfig = {|
  date: ?string,
  hideTime: boolean,
|};

export default class DateTimePicker extends React.PureComponent<
  {
    ...DateConfig,
    className?: string,
    tz: string,
    label?: string,
    placeholder?: string,
    dateOnly?: boolean,
    showIcon?: boolean,
    onChange: (dateConfig: DateConfig) => void,
    disabled?: boolean,
    autoFocus?: boolean,
    onFocus?: () => void,
    onBlur?: () => void,
    overlayContainer?: ?HTMLElement,
    required?: boolean,
    clearable?: ?boolean,
    error?: ?string,
    forceRightEdge?: boolean,
  },
  { showOverlay: boolean },
> {
  state = {
    showOverlay: false,
  };

  inputRef = React.createRef();

  targetRef = React.createRef();

  overlayRef = React.createRef();

  containerRef = React.createRef();

  hidingInProcess: boolean = false;

  handleShowOverlay = () => {
    this.setState({ showOverlay: true });

    if (this.props.onFocus) this.props.onFocus();
  };

  handleHideOverlay = () => {
    if (!this.hidingInProcess) {
      this.setState({ showOverlay: false }, () => {
        this.hidingInProcess = false;
        if (this.props.onBlur) this.props.onBlur();
      });
    }
    this.hidingInProcess = true;
  };

  handleBlur = (e: SyntheticEvent<HTMLDivElement>) => {
    const relatedTarget: ?Node = (e: any).relatedTarget || document.activeElement;
    const overlay = this.overlayRef.current;

    if (
      relatedTarget &&
      overlay &&
      (e.currentTarget.contains(relatedTarget) || overlay.contains(relatedTarget))
    ) {
      return;
    }

    this.handleHideOverlay();
  };

  handleChangeInput = (dateConfig: DateConfig) => {
    if (!this.props.clearable && !dateConfig.date) return;

    this.props.onChange(dateConfig);
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') {
      this.handleHideOverlay();
    }
  };

  render() {
    return (
      <Root className={this.props.className} ref={this.containerRef} onBlur={this.handleBlur}>
        <InputWrapper ref={this.targetRef}>
          <DateTimeInput
            tz={this.props.tz}
            date={this.props.date}
            hideTime={this.props.hideTime}
            label={this.props.label}
            placeholder={this.props.placeholder}
            dateOnly={this.props.dateOnly}
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
            onFocus={this.handleShowOverlay}
            onChange={this.handleChangeInput}
            onKeyDown={this.handleKeyDown}
            inputRef={this.inputRef}
            error={this.props.error}
            required={this.props.required}
            clearable={this.props.clearable}
          />
          {this.props.showIcon && (
            <Icon showOverlay={this.state.showOverlay}>
              <CalendarIcon />
            </Icon>
          )}
        </InputWrapper>

        <DateTimeOverlay
          container={this.props.overlayContainer || this.containerRef.current}
          target={this.targetRef.current}
          show={this.state.showOverlay}
          onHide={this.handleHideOverlay}
          date={this.props.date}
          hideTime={this.props.hideTime}
          tz={this.props.tz}
          onChange={this.props.onChange}
          dateOnly={this.props.dateOnly}
          disableHideOnClickOut
          clearable={this.props.clearable}
          overlayRef={this.overlayRef}
          forceRightEdge={this.props.forceRightEdge}
        />
      </Root>
    );
  }
}
