/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Loader from 'components/Loader';

const BaseRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: ${props => props.theme.secondaryRowColor};
  font-size: 13px;
`;

const LoadingRow = styled(BaseRow)`
  justify-content: center;
`;

const LoadAllRow = styled(BaseRow)`
  justify-content: flex-end;
`;

const ShowContainer = styled.span`
  padding-right: 10px;
`;

const LoadAllSection = styled.div`
  color: ${props => props.theme.secondaryActionDarkerColor};
  a {
    cursor: pointer;
  }
`;

const LoadExtraLink = styled.a`
  padding: 0 8px;
  color: ${props => props.theme.primaryActionColor};
  &:hover {
    text-decoration: underline;
  }
`;

export default class LoadMoreRow extends React.PureComponent<{
  className?: ?string,
  totalCount: ?number,
  currentCount: ?number,
  loading: boolean,
  onLoadMore: number => void,
  entityName: string,
}> {
  loadMore = (amount: number) => {
    if (!this.props.loading) {
      this.props.onLoadMore(amount);
    }
  };

  handleLoad25More = () => {
    this.loadMore(25);
  };

  handleLoad50More = () => {
    this.loadMore(50);
  };

  handleLoad100More = () => {
    this.loadMore(100);
  };

  render() {
    const { totalCount, currentCount, loading, className, entityName } = this.props;

    if (loading) {
      return (
        <LoadingRow className={className}>
          <Loader size={20} />
        </LoadingRow>
      );
    }

    return (
      <LoadAllRow className={className}>
        {totalCount != null && currentCount != null && (
          <LoadAllSection disabled={loading}>
            <span>
              <ShowContainer>
                Showing {currentCount}/{totalCount} {entityName}
              </ShowContainer>
              {totalCount - currentCount > 0 && (
                <span>
                  |<LoadExtraLink onClick={this.handleLoad25More}>25</LoadExtraLink>
                </span>
              )}
              {totalCount - currentCount > 25 && (
                <span>
                  |<LoadExtraLink onClick={this.handleLoad50More}>50</LoadExtraLink>
                </span>
              )}
              {totalCount - currentCount > 50 && (
                <span>
                  |<LoadExtraLink onClick={this.handleLoad100More}>100</LoadExtraLink>
                </span>
              )}
            </span>
          </LoadAllSection>
        )}
      </LoadAllRow>
    );
  }
}
