/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { Content } from 'components/page/Content';

import RoiContainer from './RoiContainer';

const query = graphql`
  query RoiQuery($eventSlug: String!) {
    event(slug: $eventSlug) {
      ...RoiContainer_event
    }
    org {
      ...RoiContainer_org
    }
  }
`;

export default class Roi extends React.Component<{
  match: Match,
  history: RouterHistory,
}> {
  render() {
    const { match, history } = this.props;
    return (
      <Content>
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug: match.params.event_slug }}
          renderSuccess={props => (
            <RoiContainer event={props.event} org={props.org} history={history} />
          )}
        />
      </Content>
    );
  }
}
