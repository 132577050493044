/**
 * @flow
 * @relayHash 049f91609346fe71a9ccc31e6eef475a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContactsTablePagination_totalCountEvent$ref = any;
export type ContactSaveMethod = "api" | "g2w" | "import" | "manual" | "past_g2w" | "past_zoom" | "registration" | "zapier" | "zoom";
export type ContactSort = "ATTENDANCE_DURATION" | "ATTENDANCE_JOIN_TIME" | "ATTENDANCE_LEAVE_TIME" | "ATTENDANCE_STATUS_ID" | "CITY" | "COMPANY_ID" | "CONTACT_TYPE_ID" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CREATED_METHOD" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "EMAIL_OPT_IN" | "EVENTS" | "HOT_LEAD" | "LAST_SYNCED" | "LINKEDIN" | "MARKETO_ID" | "MEMBERSHIP_LAST_SYNCED" | "MEMBERSHIP_SYNC_STATUS" | "NAME" | "OWNER_ID" | "PHONE1" | "PHONE2" | "REGISTERED_AT" | "REGISTRATION_STATUS_ID" | "REQUEST_SUBMISSIONS" | "SALESFORCE_ID" | "SALESFORCE_LEAD_STATUS_ID" | "SALESFORCE_SYNC_AS" | "STATE" | "STREET" | "SYNC_STATUS" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "UPDATED_METHOD" | "WEBSITE" | "ZIP";
export type ContactSyncOption = "contact" | "lead";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type RemoveContactAssignmentInput = {
  clientMutationId?: ?string,
  contactIds: $ReadOnlyArray<string>,
  eventId: string,
  fromWindow: string,
};
export type ContactFilters = {
  query?: ?string,
  search?: ?Search,
  contactIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  updatedMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactTypeIds?: ?$ReadOnlyArray<string>,
  ownerIds?: ?$ReadOnlyArray<string>,
  senderIds?: ?$ReadOnlyArray<string>,
  salesforceSyncOptions?: ?$ReadOnlyArray<ContactSyncOption>,
  leadStatusIds?: ?$ReadOnlyArray<string>,
  sentAt?: ?DateTimeRange,
  emailOptIn?: ?boolean,
  hotLead?: ?boolean,
  registeredAt?: ?DateTimeRange,
  attendanceStatusIds?: ?$ReadOnlyArray<string>,
  registrationStatusIds?: ?$ReadOnlyArray<string>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  membershipSyncStatuses?: ?$ReadOnlyArray<SyncState>,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?ContactSort,
  direction?: ?Direction,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type removeContactAssignmentMutationVariables = {|
  input: RemoveContactAssignmentInput,
  filters?: ?ContactFilters,
  hasFilters: boolean,
|};
export type removeContactAssignmentMutationResponse = {|
  +removeContactAssignment: ?{|
    +eventContactEdges?: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +events: {|
          +totalCount: number
        |},
      |}
    |}>,
    +event?: {|
      +eventContacts: {|
        +totalCount: number
      |},
      +contactRegistrationsTotal: number,
      +$fragmentRefs: ContactsTablePagination_totalCountEvent$ref,
    |},
    +removedContactIds: $ReadOnlyArray<string>,
    +removedEventId: string,
  |}
|};
export type removeContactAssignmentMutation = {|
  variables: removeContactAssignmentMutationVariables,
  response: removeContactAssignmentMutationResponse,
|};
*/


/*
mutation removeContactAssignmentMutation(
  $input: RemoveContactAssignmentInput!
  $filters: ContactFilters
  $hasFilters: Boolean!
) {
  removeContactAssignment(input: $input) {
    eventContactEdges @skip(if: $hasFilters) {
      node {
        id
        events {
          totalCount
        }
      }
    }
    event @include(if: $hasFilters) {
      eventContacts {
        totalCount
      }
      contactRegistrationsTotal
      ...ContactsTablePagination_totalCountEvent
      id
    }
    removedContactIds
    removedEventId
  }
}

fragment ContactsTablePagination_totalCountEvent on Event {
  contactsCount: eventContacts(filters: $filters) {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveContactAssignmentInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "ContactFilters",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hasFilters",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemoveContactAssignmentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedContactIds",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedEventId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v6 = {
  "kind": "Condition",
  "passingValue": false,
  "condition": "hasFilters",
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventContactEdges",
      "storageKey": null,
      "args": null,
      "concreteType": "EventContactEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Contact",
          "plural": false,
          "selections": [
            v4,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "events",
              "storageKey": null,
              "args": null,
              "concreteType": "EventRequiredConnection",
              "plural": false,
              "selections": v5
            }
          ]
        }
      ]
    }
  ]
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "eventContacts",
  "storageKey": null,
  "args": null,
  "concreteType": "EventContactConnection",
  "plural": false,
  "selections": v5
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactRegistrationsTotal",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeContactAssignmentMutation",
  "id": null,
  "text": "mutation removeContactAssignmentMutation(\n  $input: RemoveContactAssignmentInput!\n  $filters: ContactFilters\n  $hasFilters: Boolean!\n) {\n  removeContactAssignment(input: $input) {\n    eventContactEdges @skip(if: $hasFilters) {\n      node {\n        id\n        events {\n          totalCount\n        }\n      }\n    }\n    event @include(if: $hasFilters) {\n      eventContacts {\n        totalCount\n      }\n      contactRegistrationsTotal\n      ...ContactsTablePagination_totalCountEvent\n      id\n    }\n    removedContactIds\n    removedEventId\n  }\n}\n\nfragment ContactsTablePagination_totalCountEvent on Event {\n  contactsCount: eventContacts(filters: $filters) {\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeContactAssignmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeContactAssignment",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveContactAssignmentPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          v6,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "hasFilters",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v7,
                  v8,
                  {
                    "kind": "FragmentSpread",
                    "name": "ContactsTablePagination_totalCountEvent",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removeContactAssignmentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removeContactAssignment",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemoveContactAssignmentPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          v6,
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "hasFilters",
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v7,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": "contactsCount",
                    "name": "eventContacts",
                    "storageKey": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "filters",
                        "variableName": "filters",
                        "type": "ContactFilters"
                      }
                    ],
                    "concreteType": "EventContactConnection",
                    "plural": false,
                    "selections": v5
                  },
                  v4
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '58ac806b1d52c3f674be223def7c09d5';
module.exports = node;
