/* @flow */
import styled, { css } from 'styled-components';
/**
 * Use this when columns overflowed content with ellipsis needs to be expanded.
 * When extending add a height attribute equal to
 * the line height of the content to avoid blinking and width (if not flex)
 * IMPORTANT: To affect only table cell excluding header pass `header` prop
 */
const BaseColumnHoverExpandable = styled.div`
  min-width: 0;
  padding-right: 10px;
  ${props =>
    !props.header &&
    css`
      height: 20px;
      & > :first-child {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & > :hover {
        overflow: visible;
        overflow-wrap: break-word;
        white-space: normal;
        background-color: ${props.rowSelected
          ? props.theme.selectedRowColor
          : props.theme.primaryRowColor};
        position: relative;
        z-index: 4;
      }
    `};
  ${props =>
    props.width &&
    css`
      flex: ${props.grow || 0} 0 ${props.width}px;
    `};
`;

export default BaseColumnHoverExpandable;
