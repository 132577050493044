/* @flow */

const customFieldsOptions = {
  TEXT: { types: ['string', 'textarea'] },
  TEXTAREA: { types: ['textarea'] },
  LINK: { types: ['url'] },
  NUMBER: { types: ['int', 'double', 'currency'] },
  CURRENCY: { types: ['currency', 'int', 'double'] },
  DATE: { types: ['date', 'datetime'] },
  BOOLEAN: { types: ['boolean'] },
  USER_SELECT: { types: ['reference'] },
  USER_MULTISELECT: { types: [] },
  SELECT: { types: ['string', 'multipicklist', 'picklist'] },
  MULTISELECT: { types: ['string', 'multipicklist', 'picklist'] },
  DEFAULT: { types: [] },
};

export default customFieldsOptions;
