/* @flow */
import type { FilePickerFile, CircaFile } from './types';

function normalizeFileStackFile(file: FilePickerFile): CircaFile {
  return {
    filename: file.filename,
    filesize: file.size,
    fileurl: file.url,
    filetype: file.mimetype,
  };
}

function fixGoogleDriveFile(file: FilePickerFile): FilePickerFile {
  if (file.mimetype.startsWith('application/vnd')) {
    return {
      ...file,
      filename: file.filename.replace(/(\.(docx|xlsx|pptx))$/, ''),
      mimetype: 'link/googledrive',
      url: `https://drive.google.com/open?id=${file.originalPath.split('/').slice(-1)[0]}`,
    };
  }
  return file;
}

function fixOutlookFiles(file: FilePickerFile): FilePickerFile {
  if (file.mimetype === undefined) {
    return { ...file, mimetype: 'application/vnd.ms-outlook' };
  }
  return file;
}

export const convertNativeFile = (file: File): CircaFile => {
  return {
    filename: file.name,
    filesize: file.size,
    fileurl: '',
    filetype: file.type,
  };
};

export default function convertFileStackFile(file: FilePickerFile): CircaFile {
  if (file.source === 'googledrive') {
    return normalizeFileStackFile(fixGoogleDriveFile(file));
  }

  if (
    (file.filename.endsWith('.msg') || file.filename.endsWith('.oft')) &&
    file.mimetype === undefined
  ) {
    return normalizeFileStackFile(fixOutlookFiles(file));
  }

  return normalizeFileStackFile(file);
}
