/* @flow */
import React from 'react';
import styled from 'styled-components';

import { Plus } from 'images';

import { type FieldOption } from '../types';
import FieldOptionRow from './FieldOptionRow';

const Container = styled.div`
  margin-top: 20px;
`;

const AddLink = styled.a`
  display: inline-block;
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.08;
  color: ${props => props.theme.primaryActionColor};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
  > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

const PlusIcon = styled(Plus)`
  width: 12px;
  height: 12px;
  margin-right: 5px;
`;

export default class FieldOptions extends React.Component<{
  options: $ReadOnlyArray<FieldOption>,
  singleSelect?: boolean,
  restrictManagingOptions: ?boolean,
  onOptionAdd: () => void,
  onOptionUpdate: (id: string, value: string, enterPressed: boolean) => void,
  onOptionRemove: (id: string) => void,
}> {
  render() {
    const {
      options,
      singleSelect,
      restrictManagingOptions,
      onOptionRemove,
      onOptionUpdate,
      onOptionAdd,
    } = this.props;
    return (
      <Container>
        {options.map(option => (
          <FieldOptionRow
            restrictManagingOptions={restrictManagingOptions || false}
            hasFocus={option.name === ''}
            singleSelect={singleSelect}
            key={option.id}
            option={option}
            onRemove={options.length > 2 ? onOptionRemove : undefined}
            onUpdate={onOptionUpdate}
          />
        ))}
        {!restrictManagingOptions && (
          <AddLink onClick={onOptionAdd}>
            <PlusIcon />
            <span>Add Option</span>
          </AddLink>
        )}
      </Container>
    );
  }
}
