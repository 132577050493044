/* @flow */
import { graphql } from 'react-relay';

import type { CircaFile } from 'utils/uploading/types';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { createEventAttachmentMutationResponse } from './__generated__/createEventAttachmentMutation.graphql';

const mutation = graphql`
  mutation createEventAttachmentMutation($input: AddAttachmentToEventInput!) {
    addAttachmentToEvent(input: $input) {
      event {
        id
      }
      attachmentEdge {
        __typename
        node {
          id
          fileurl
          filename
          filetype
          filesize
        }
      }
    }
  }
`;

export default function createEventAttachment(eventId: string, file: CircaFile) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        eventId,
        ...file,
      },
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'attachmentEdge',
      connections: [{ field: 'attachments' }],
    }),
  }).then(
    (response: createEventAttachmentMutationResponse) =>
      response.addAttachmentToEvent && response.addAttachmentToEvent.attachmentEdge.node.id,
  );
}
