/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/material/Button';
import Overlay from 'components/material/Overlay';

const StyledOverlay = styled(Overlay)`
  padding: 10px;
`;

const Warning = styled.div`
  color: ${props => props.theme.rowSecondaryTextColor};
  text-align: center;
  margin-bottom: 10px;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
`;

export default class DeleteOverlay extends React.PureComponent<{
  show: boolean,
  target: any,
  container: any,
  onHide: () => void,
  onDelete: () => void,
}> {
  handleDelete = () => {
    this.props.onDelete();
    this.props.onHide();
  };

  render() {
    return (
      <StyledOverlay
        show={this.props.show}
        target={this.props.target}
        onHide={this.props.onHide}
        container={this.props.container}
      >
        <Warning>Are you sure you want to delete selected tasks?</Warning>
        <FullWidthButton label="Delete" negative onClick={this.handleDelete} />
      </StyledOverlay>
    );
  }
}
