/* @flow */

import { graphql } from 'react-relay';
import omit from 'lodash/omit';

import { type CircaFile } from 'utils/uploading/types';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import addEventNoteAttachment from './addEventNoteAttachment';

import type { createEventNoteMutationResponse } from './__generated__/createEventNoteMutation.graphql';

const mutation = graphql`
  mutation createEventNoteMutation($input: CreateEventNoteInput!) {
    createEventNote(input: $input) {
      eventNotesEdge {
        __typename
        node {
          id
          dbId
          name
          content
          updatedAt
          createdAt
          creator {
            firstName
            lastName
            email
          }
          updater {
            firstName
            lastName
            email
            ...MaterialAvatar_user
          }
          attachments {
            edges {
              node {
                id
                fileurl
                filename
                filetype
                filesize
              }
            }
          }
        }
      }
    }
  }
`;

export default function createEventNote(
  eventId: string,
  name: string,
  content: string,
  attachments: $ReadOnlyArray<CircaFile>,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        name,
        content,
        eventId,
      },
    },
    updater: addRecordToConnection({
      parentId: eventId,
      edgeName: 'eventNotesEdge',
      connections: [{ field: 'eventNotes' }],
    }),
  }).then((response: createEventNoteMutationResponse) => {
    if (response.createEventNote) {
      const eventNoteNode = response.createEventNote.eventNotesEdge.node;
      attachments.forEach(attachment => {
        addEventNoteAttachment(eventNoteNode.id, omit(attachment, ['id']));
      });
      return eventNoteNode;
    }
    throw new Error('No EventNote Id');
  });
}
