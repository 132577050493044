/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import MaterialAvatar from 'components/material/MaterialAvatar';
import UserSelectInput from 'components/material/UserSelectInput';
import { type User } from 'components/UserSelect';

import InlineEditableCell from './InlineEditableCell';

import type { UserFilterItemQueryResponse } from './__generated__/InlineUserSelectFieldQuery.graphql';

const Container = styled.div`
  width: 100%;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin: -6px -18px -6px -10px;
`;

const StyledAvatar = styled(MaterialAvatar)`
  margin-right: 10px;
`;

const TextWrapper = styled.div`
  margin-left: 0;
`;

const query = graphql`
  query InlineUserSelectFieldQuery(
    $query: String!
    $userIds: [ID!]!
    $hasTeamAccess: Boolean!
    $includeEvent: Boolean!
    $eventId: ID!
  ) {
    query {
      ...UserSelectInputContainer_query
    }
  }
`;

export default class InlineUserSelectField extends React.Component<
  {
    activeUsers: $ReadOnlyArray<User>,
    placeholder?: string,
    onSelect: (selectedUser: ?User) => void,
    onUnselect?: (selectedUser: User) => void,
    getError?: (user?: User) => ?string,
    updateColumnWidth?: () => void,
    searchPlaceholder?: string,
    hasTeamAccess?: boolean,
    children?: React.Node,
    isMultiselect?: boolean,
    clearable?: boolean,
    eventId?: string,
    disabled?: boolean,
    onInviteClick?: () => void,
    hideSelected?: boolean,
    hideLockIcon?: boolean,
  },
  {
    query: string,
    error: ?string,
  },
> {
  state = {
    query: '',
    error: null,
  };

  forceBlur: boolean = false;

  componentDidUpdate(prevProps: $PropertyType<InlineUserSelectField, 'props'>) {
    const { clearable, activeUsers } = this.props;
    if (
      this.state.error &&
      !clearable &&
      activeUsers.length > 0 &&
      prevProps.activeUsers.length === 0
    ) {
      this.forceBlur = true;
    }
  }

  handleClear = () => {
    this.props.onSelect(null);
  };

  handleChange = (selectedUser: User) => {
    const { isMultiselect, onUnselect, onSelect, activeUsers } = this.props;
    if (!isMultiselect || !onUnselect || !activeUsers.find(user => user.id === selectedUser.id)) {
      onSelect(selectedUser);
      this.forceBlur = false;
      return;
    }
    if (this.handleValidate(selectedUser)) {
      onUnselect(selectedUser);
    }
  };

  handleValidate = (value?: User): boolean => {
    const { getError, updateColumnWidth } = this.props;
    const error: ?string = getError != null ? getError(value) : null;
    this.setState(state => (state.error === error ? null : { error }));
    if (error && updateColumnWidth) {
      updateColumnWidth();
    }
    return !error;
  };

  renderUserSelect = (
    userQuery: ?string,
    activeUsers: $ReadOnlyArray<User>,
    onBlur: ?(user?: User) => void,
  ): React.Node => {
    if (this.forceBlur && onBlur) {
      onBlur();
      this.forceBlur = false;
    }
    return (
      <UserSelectInput
        query={userQuery}
        activeUsers={activeUsers}
        name={this.props.placeholder}
        isMultiselect={this.props.isMultiselect}
        onChange={selectedUser => {
          if (onBlur && !this.props.isMultiselect) onBlur(selectedUser);
          this.handleChange(selectedUser);
        }}
        label={this.props.placeholder}
        header={this.props.children}
        searchPlaceholder={this.props.searchPlaceholder}
        onHideOverlay={() => {
          if (onBlur) onBlur();
        }}
        onInviteClick={this.props.onInviteClick}
        hideSelected={this.props.hideSelected}
      />
    );
  };

  renderReadView = () => {
    const { children, activeUsers } = this.props;
    if (children != null) return children;
    if (activeUsers != null && activeUsers.length > 0) {
      return (
        <UserContainer>
          <StyledAvatar user={activeUsers[0]} radius={13} />
          <TextWrapper>{fullNameOfUser(activeUsers[0])}</TextWrapper>
        </UserContainer>
      );
    }
    return null;
  };

  render() {
    const {
      activeUsers,
      placeholder,
      clearable,
      eventId,
      hasTeamAccess,
      disabled,
      hideLockIcon,
    } = this.props;

    return (
      <InlineEditableCell
        placeholder={placeholder}
        onClear={clearable && activeUsers.length !== 0 ? this.handleClear : null}
        onValidate={this.handleValidate}
        error={this.state.error}
        disabled={disabled}
        hideLockIcon={hideLockIcon}
      >
        {({ onBlur, editing }) => {
          if (!editing) {
            return this.renderReadView();
          }

          return (
            <Container>
              <DefaultQueryRenderer
                query={query}
                variables={{
                  query: this.state.query,
                  userIds: (activeUsers || []).map(user => user.id),
                  hasTeamAccess: !!hasTeamAccess,
                  includeEvent: !!eventId,
                  eventId: eventId || '',
                }}
                renderSuccess={(response: UserFilterItemQueryResponse) =>
                  this.renderUserSelect(response.query, activeUsers, onBlur)
                }
                renderLoading={() => this.renderUserSelect(null, activeUsers, onBlur)}
              />
            </Container>
          );
        }}
      </InlineEditableCell>
    );
  }
}
