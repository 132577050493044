/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import SelectField from 'components/material/SelectField';

import type { TeamSelectField_teams } from './__generated__/TeamSelectField_teams.graphql';

export type TeamAllocationType = {
  ...$Diff<$ElementType<TeamSelectField_teams, 0>, { +$refType: any }>,
  percent: number,
  viewerCanUpdate: ?boolean,
};

class TeamSelectField extends React.Component<{
  teams: TeamSelectField_teams,
  onAddTeam: (team: TeamAllocationType) => void,
}> {
  handleAddTeam = (teamId: ?string) => {
    const currentTeam = this.props.teams.find(team => team.id === teamId);
    if (currentTeam != null) {
      this.props.onAddTeam({ ...currentTeam, percent: 0, viewerCanUpdate: true });
    }
  };

  render() {
    const options = this.props.teams.map(team => ({ label: team.name, value: team.id }));

    return (
      <SelectField
        autoFocus
        options={options}
        searchable
        placeholder="Add Team"
        onChange={this.handleAddTeam}
      />
    );
  }
}

export default createFragmentContainer(
  TeamSelectField,
  graphql`
    fragment TeamSelectField_teams on Team @relay(plural: true) {
      id
      name
    }
  `,
);
