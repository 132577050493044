/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type HeaderSection_template$ref = any;
type Section_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Sections_org$ref: FragmentReference;
export type Sections_org = {|
  +briefTemplate: {|
    +sections: $ReadOnlyArray<{|
      +name: string,
      +order: number,
      +enabled: boolean,
    |}>,
    +$fragmentRefs: HeaderSection_template$ref,
  |},
  +$fragmentRefs: Section_org$ref,
  +$refType: Sections_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Sections_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "briefTemplate",
      "storageKey": null,
      "args": null,
      "concreteType": "BriefTemplate",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "sections",
          "storageKey": null,
          "args": null,
          "concreteType": "SortableSection",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "order",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "enabled",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "HeaderSection_template",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "Section_org",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '538aa82400713b9574436afd42d08e9b';
module.exports = node;
