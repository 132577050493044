/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateTextComponent, {
  type updateTextComponentPropertyType,
} from 'graph/mutations/registration/updateTextComponent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import type { SelectedComponent } from '../../RegistrationCreateContent';
import { defaultBackgroundColor } from '../../registrationFormDefaults';
import PropertyRow from '../components/PropertyRow';
import RegistrationColorSelector from '../components/RegistrationColorSelector';
import { Section } from '.';
import { BlockingLayer, PaddingComponent } from './components';

import { type TextComponent_org } from './__generated__/TextComponent_org.graphql';
import { type TextComponent_selectedPageComponent } from './__generated__/TextComponent_selectedPageComponent.graphql';

const Container = styled.div`
  position: relative;
`;

class TextComponent extends React.PureComponent<{
  org: TextComponent_org,
  selectedPageComponent: TextComponent_selectedPageComponent,
  onChangeComponentProperty: (updatedProps: SelectedComponent) => void,
}> {
  handleChangeBackgroundColor = (backgroundColor: string) => {
    this.updateTextComponent({ backgroundColor });
  };

  handleSavePadding = (padding: string) => {
    this.updateTextComponent({ padding });
  };

  updateTextComponent = (properties: updateTextComponentPropertyType) => {
    if (!this.props.selectedPageComponent.textComponent) return;

    updateTextComponent({
      ...properties,
      id: this.props.selectedPageComponent.textComponent.id,
    }).catch(showModernMutationError);
  };

  render() {
    const {
      org,
      onChangeComponentProperty,
      selectedPageComponent: { id, textComponent },
    } = this.props;

    if (!textComponent) return null;

    return (
      <Container>
        {id === 'new' && <BlockingLayer />}
        <Section>
          <PropertyRow label="Background color">
            <RegistrationColorSelector
              orgSettings={org.settings}
              currentColor={textComponent.backgroundColor || defaultBackgroundColor}
              onChangeColor={this.handleChangeBackgroundColor}
              alignTo="bottom-right"
              includeTransparent
            />
          </PropertyRow>

          <PropertyRow label="Padding">
            <PaddingComponent
              paddingString={textComponent.padding}
              onSave={this.handleSavePadding}
              onChange={onChangeComponentProperty}
              forceFocus
            />
          </PropertyRow>
        </Section>
      </Container>
    );
  }
}

export default createFragmentContainer(TextComponent, {
  org: graphql`
    fragment TextComponent_org on Org {
      id
      settings {
        ...RegistrationColorSelector_orgSettings
      }
    }
  `,
  selectedPageComponent: graphql`
    fragment TextComponent_selectedPageComponent on RegistrationPageComponent {
      id
      kind
      textComponent {
        id
        padding
        backgroundColor
      }
    }
  `,
});
