/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactEventActionsColumn_contactEvent$ref: FragmentReference;
export type ContactEventActionsColumn_contactEvent = {|
  +node: {|
    +id: string
  |},
  +$refType: ContactEventActionsColumn_contactEvent$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactEventActionsColumn_contactEvent",
  "type": "ContactEventEdge",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'fbd7941f753291a4d25ded090e0f5d02';
module.exports = node;
