/* @flow */
import React from 'react';
import { createPortal } from 'react-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import removePayment from 'graph/mutations/payment/removePayment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ConfirmationWindow from 'components/ConfirmationWindow';
import ShareDropdown from 'components/ShareDropdown';

import { type PaymentCellPropsType } from './index';

import { type PaymentActionCell_event } from './__generated__/PaymentActionCell_event.graphql';
import { type PaymentActionCell_payment } from './__generated__/PaymentActionCell_payment.graphql';

const Root = styled.div`
  margin: 0 -5px 0 -24px;
`;

class PaymentActionCell extends React.PureComponent<
  PaymentCellPropsType<PaymentActionCell_payment, PaymentActionCell_event>,
  { showConfirmation: boolean },
> {
  state = { showConfirmation: false };

  handleRemovePayment = () => {
    removePayment({
      paymentId: this.props.payment.id,
      expenseId: this.props.payment.expense.id,
      eventId: this.props.event.id,
    }).catch(showModernMutationError);
  };

  handleShowConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    if (!this.props.payment.viewerCanDelete) return null;

    return (
      <Root>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={[
            {
              label: 'Delete',
              icon: 'trash',
              onClick: this.handleShowConfirmation,
            },
          ]}
          overlayContainer={document.body}
        />

        {this.state.showConfirmation &&
          document.body &&
          createPortal(
            <ConfirmationWindow
              message="Deleting this Payment is irreversible."
              onConfirm={this.handleRemovePayment}
              onHide={this.handleHideConfirmation}
            />,
            document.body,
          )}
      </Root>
    );
  }
}

export default createFragmentContainer(
  PaymentActionCell,
  graphql`
    fragment PaymentActionCell_payment on Payment {
      id
      viewerCanDelete

      expense {
        id
      }
    }

    fragment PaymentActionCell_event on Event {
      id
    }
  `,
);
