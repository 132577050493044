/* @flow */
import styled, { css } from 'styled-components';
import { StyledTrashIcon } from './TrashColumn';
import BaseColumnSticky from './BaseColumnSticky';
import BaseColumnHoverExpandable from './BaseColumnHoverExpandable';

const TableRow = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  color: ${props => props.theme.rowPrimaryTextColor};
  border-top: 1px solid ${props => props.theme.primaryRowColor};
  ${props =>
    props.selected
      ? css`
          background-color: ${props.theme.selectedRowColor};
        `
      : css`
          background-color: ${props.theme.secondaryRowColor};
          &:hover {
            background-color: ${props.theme.primaryRowColor};
            ${BaseColumnSticky}, ${BaseColumnHoverExpandable} {
              background-color: ${props.theme.primaryRowColor};
            }
          }
        `};

  &:hover ${StyledTrashIcon} {
    visibility: visible;
  }
`;

export default TableRow;
