/* @flow */
import moment from 'moment-timezone';

import formatDate from './formatDate';
import formatTime from './formatTime';

export default function formatDateTime(date: moment, hideTime: ?boolean = false): string {
  if (hideTime) {
    return formatDate(date);
  }
  return `${formatDate(date)} at ${formatTime(date)}`.trim();
}
