/**
 * @flow
 * @relayHash ecfdf32fa683dc37cce91f11b63ee900
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BudgetContainer_me$ref = any;
type BudgetContainer_org$ref = any;
export type BudgetQueryVariables = {||};
export type BudgetQueryResponse = {|
  +org: {|
    +$fragmentRefs: BudgetContainer_org$ref
  |},
  +me: {|
    +$fragmentRefs: BudgetContainer_me$ref
  |},
|};
export type BudgetQuery = {|
  variables: BudgetQueryVariables,
  response: BudgetQueryResponse,
|};
*/


/*
query BudgetQuery {
  org {
    ...BudgetContainer_org
    id
  }
  me {
    ...BudgetContainer_me
    id
  }
}

fragment BudgetContainer_org on Org {
  id
  viewerCanChangeCurrency
  settings {
    currency
    id
    fiscalYearStart
  }
  budgetCategories {
    edges {
      node {
        id
        name
        description
      }
    }
  }
  budgetPeriods {
    edges {
      node {
        year
        id
      }
    }
  }
  ...BudgetSetup_org
}

fragment BudgetContainer_me on User {
  ...BudgetSetup_user
}

fragment BudgetSetup_user on User {
  teams {
    edges {
      node {
        ...TeamBudgeting_teams
        id
      }
    }
  }
}

fragment TeamBudgeting_teams on Team {
  id
  name
}

fragment BudgetSetup_org on Org {
  budgetPeriods {
    edges {
      node {
        id
        period
        year
        ...TeamBudgeting_budgetPeriod
      }
    }
  }
  ...FiscalYearPanel_org
  ...TeamBudgeting_org
}

fragment TeamBudgeting_budgetPeriod on BudgetPeriod {
  id
  period
  startDate
  endDate
  teams {
    edges {
      amount
      node {
        id
        name
      }
    }
  }
}

fragment FiscalYearPanel_org on Org {
  id
  settings {
    fiscalYearStart
    id
  }
  budgetPeriods {
    edges {
      node {
        period
        year
        id
      }
    }
  }
}

fragment TeamBudgeting_org on Org {
  id
  settings {
    currency
    ...TeamRow_settings
    id
  }
}

fragment TeamRow_settings on OrgSettings {
  currency
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "node",
  "storageKey": null,
  "args": null,
  "concreteType": "Team",
  "plural": false,
  "selections": [
    v0,
    v1
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BudgetQuery",
  "id": null,
  "text": "query BudgetQuery {\n  org {\n    ...BudgetContainer_org\n    id\n  }\n  me {\n    ...BudgetContainer_me\n    id\n  }\n}\n\nfragment BudgetContainer_org on Org {\n  id\n  viewerCanChangeCurrency\n  settings {\n    currency\n    id\n    fiscalYearStart\n  }\n  budgetCategories {\n    edges {\n      node {\n        id\n        name\n        description\n      }\n    }\n  }\n  budgetPeriods {\n    edges {\n      node {\n        year\n        id\n      }\n    }\n  }\n  ...BudgetSetup_org\n}\n\nfragment BudgetContainer_me on User {\n  ...BudgetSetup_user\n}\n\nfragment BudgetSetup_user on User {\n  teams {\n    edges {\n      node {\n        ...TeamBudgeting_teams\n        id\n      }\n    }\n  }\n}\n\nfragment TeamBudgeting_teams on Team {\n  id\n  name\n}\n\nfragment BudgetSetup_org on Org {\n  budgetPeriods {\n    edges {\n      node {\n        id\n        period\n        year\n        ...TeamBudgeting_budgetPeriod\n      }\n    }\n  }\n  ...FiscalYearPanel_org\n  ...TeamBudgeting_org\n}\n\nfragment TeamBudgeting_budgetPeriod on BudgetPeriod {\n  id\n  period\n  startDate\n  endDate\n  teams {\n    edges {\n      amount\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment FiscalYearPanel_org on Org {\n  id\n  settings {\n    fiscalYearStart\n    id\n  }\n  budgetPeriods {\n    edges {\n      node {\n        period\n        year\n        id\n      }\n    }\n  }\n}\n\nfragment TeamBudgeting_org on Org {\n  id\n  settings {\n    currency\n    ...TeamRow_settings\n    id\n  }\n}\n\nfragment TeamRow_settings on OrgSettings {\n  currency\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BudgetQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BudgetContainer_org",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BudgetContainer_me",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BudgetQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanChangeCurrency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "OrgSettings",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currency",
                "args": null,
                "storageKey": null
              },
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fiscalYearStart",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetCategories",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetCategoryRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BudgetCategoryRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BudgetCategory",
                    "plural": false,
                    "selections": [
                      v0,
                      v1,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetPeriods",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetPeriodRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BudgetPeriodRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BudgetPeriod",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "year",
                        "args": null,
                        "storageKey": null
                      },
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "period",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "startDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "teams",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BudgetPeriodTeamConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BudgetPeriodTeamEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "amount",
                                "args": null,
                                "storageKey": null
                              },
                              v2
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teams",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TeamRequiredEdge",
                "plural": true,
                "selections": [
                  v2
                ]
              }
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4344eeed58096e1ce61872bbd1f5b849';
module.exports = node;
