/**
 * @flow
 * @relayHash c2068413c73ba0a2d30aaca4e5f65fa0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCompanyInput = {
  clientMutationId?: ?string,
  companyId: string,
  name?: ?string,
  phone?: ?string,
  website?: ?string,
  twitter?: ?string,
  linkedin?: ?string,
  description?: ?string,
  country?: ?string,
  state?: ?string,
  city?: ?string,
  zip?: ?string,
  street?: ?string,
  fromWindow?: ?string,
  eventId?: ?string,
};
export type updateCompanyMutationVariables = {|
  input: UpdateCompanyInput
|};
export type updateCompanyMutationResponse = {|
  +updateCompany: ?{|
    +company: ?{|
      +id: string,
      +name: string,
      +website: ?string,
      +phone: ?string,
      +twitter: ?string,
      +linkedin: ?string,
      +description: ?string,
      +country: ?string,
      +state: ?string,
      +city: ?string,
      +zip: ?string,
      +street: ?string,
    |}
  |}
|};
export type updateCompanyMutation = {|
  variables: updateCompanyMutationVariables,
  response: updateCompanyMutationResponse,
|};
*/


/*
mutation updateCompanyMutation(
  $input: UpdateCompanyInput!
) {
  updateCompany(input: $input) {
    company {
      id
      name
      website
      phone
      twitter
      linkedin
      description
      country
      state
      city
      zip
      street
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCompanyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateCompany",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateCompanyInput!"
      }
    ],
    "concreteType": "UpdateCompanyPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": null,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "website",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "phone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "twitter",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "linkedin",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "country",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "city",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "zip",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "street",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateCompanyMutation",
  "id": null,
  "text": "mutation updateCompanyMutation(\n  $input: UpdateCompanyInput!\n) {\n  updateCompany(input: $input) {\n    company {\n      id\n      name\n      website\n      phone\n      twitter\n      linkedin\n      description\n      country\n      state\n      city\n      zip\n      street\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateCompanyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "updateCompanyMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0f2788ceafa161178fa311ade3d46a0';
module.exports = node;
