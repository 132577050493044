/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventsFilterContainer_query$ref: FragmentReference;
export type EventsFilterContainer_query = {|
  +org: {|
    +events: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +id: string,
          +name: string,
        |}
      |}>
    |}
  |},
  +selectedEvents: $ReadOnlyArray<?{|
    +id: string,
    +name?: string,
  |}>,
  +$refType: EventsFilterContainer_query$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "EventsFilterContainer_query",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "query",
      "type": "String"
    },
    {
      "kind": "RootArgument",
      "name": "listType",
      "type": "EventListEnum"
    },
    {
      "kind": "RootArgument",
      "name": "eventIds",
      "type": "[ID!]!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "org",
      "storageKey": null,
      "args": null,
      "concreteType": "Org",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "events",
          "storageKey": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10,
              "type": "Int"
            },
            {
              "kind": "Variable",
              "name": "listType",
              "variableName": "listType",
              "type": "EventListEnum"
            },
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "query",
              "type": "String"
            }
          ],
          "concreteType": "EventRequiredConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "EventRequiredEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Event",
                  "plural": false,
                  "selections": [
                    v0,
                    v1
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "selectedEvents",
      "name": "nodes",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "eventIds",
          "type": "[ID!]!"
        }
      ],
      "concreteType": null,
      "plural": true,
      "selections": [
        v0,
        {
          "kind": "InlineFragment",
          "type": "Event",
          "selections": [
            v1
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3632f05cc51356923b9c48bbf2f3ac3b';
module.exports = node;
