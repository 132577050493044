/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Match, withRouter } from 'react-router-dom';

import GoogleMapsWrapper from 'components/GoogleMapsWrapper';
import type { MapMarkerType } from 'components/GoogleMapsWrapper/googleMapsWrapperTypes';
import AllEventsMapMarkerInfo from 'views/Main/Dashboard/AllEvents/AllEventsMap/AllEventsMapMarkerInfo';
import AllEventsMapMarkersInfo from 'views/Main/Dashboard/AllEvents/AllEventsMap/AllEventsMapMarkersInfo';

import type { AllEventsMapShareContainer_eventList } from './__generated__/AllEventsMapShareContainer_eventList.graphql';

class AllEventsMapShareContainer extends React.Component<{
  match: Match,
  eventList: AllEventsMapShareContainer_eventList,
}> {
  getLocationMarkers(): $ReadOnlyArray<MapMarkerType> {
    const events = this.props.eventList.events;
    return events.edges.reduce(
      (
        result: $ReadOnlyArray<MapMarkerType>,
        edge: $ElementType<$PropertyType<AllEventsMapShareContainer_eventList, 'events'>, 0>,
      ) => {
        const event = edge.node;
        const primaryLocation = event.primaryLocation;

        if (primaryLocation && primaryLocation.lat != null && primaryLocation.lng != null) {
          return [
            ...result,
            {
              keyId: event.id,
              slug: event.slug,
              lat: primaryLocation.lat,
              lng: primaryLocation.lng,
            },
          ];
        }
        return result;
      },
      [],
    );
  }

  render() {
    const { match } = this.props;
    const customProp = {
      resourceToken: match.params.resourceToken || '',
      orgSlug: match.params.orgName,
    };

    return (
      <GoogleMapsWrapper
        defaultZoom={8}
        containerElementHeight={650}
        windowTopOffsetMobile={260}
        windowTopOffsetDesktop={324}
        mapElement={<div style={{ height: '100%' }} />}
        markers={this.getLocationMarkers()}
        infoBoxOptions={{
          infoBoxView: AllEventsMapMarkerInfo,
          infoBoxOffset: -140,
          customProp,
        }}
        fixedInfoBoxOptions={{
          fixedInfoBoxView: AllEventsMapMarkersInfo,
          customProp,
        }}
      />
    );
  }
}

export default createFragmentContainer(
  withRouter(AllEventsMapShareContainer),
  graphql`
    fragment AllEventsMapShareContainer_eventList on EventListInterface {
      events(first: $count, filters: $filters) {
        edges {
          node {
            id
            slug
            primaryLocation {
              lat
              lng
            }
          }
        }
      }
    }
  `,
);
