/**
 * @flow
 * @relayHash 9ace2e28aa9d6e0f8a921c12ee9b9782
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddAttachmentInput = {
  clientMutationId?: ?string,
  paymentId: string,
  filename: string,
  fileurl: string,
  filetype: string,
  filesize?: ?number,
};
export type addAttachmentToPaymentMutationVariables = {|
  input: AddAttachmentInput
|};
export type addAttachmentToPaymentMutationResponse = {|
  +addAttachmentToPayment: ?{|
    +attachmentEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +fileurl: ?string,
        +filename: ?string,
        +filetype: ?string,
        +filesize: ?number,
        +viewerCanDelete: boolean,
      |},
    |}
  |}
|};
export type addAttachmentToPaymentMutation = {|
  variables: addAttachmentToPaymentMutationVariables,
  response: addAttachmentToPaymentMutationResponse,
|};
*/


/*
mutation addAttachmentToPaymentMutation(
  $input: AddAttachmentInput!
) {
  addAttachmentToPayment(input: $input) {
    attachmentEdge {
      __typename
      node {
        id
        fileurl
        filename
        filetype
        filesize
        viewerCanDelete
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddAttachmentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addAttachmentToPayment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddAttachmentInput!"
      }
    ],
    "concreteType": "AddAttachmentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "attachmentEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "AttachmentRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Attachment",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fileurl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filename",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filetype",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filesize",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "viewerCanDelete",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addAttachmentToPaymentMutation",
  "id": null,
  "text": "mutation addAttachmentToPaymentMutation(\n  $input: AddAttachmentInput!\n) {\n  addAttachmentToPayment(input: $input) {\n    attachmentEdge {\n      __typename\n      node {\n        id\n        fileurl\n        filename\n        filetype\n        filesize\n        viewerCanDelete\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addAttachmentToPaymentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "addAttachmentToPaymentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04ac000a4ebe81b9f159394eee0a13e7';
module.exports = node;
