/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type BriefHeaderActionsBar_me$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BriefContainer_me$ref: FragmentReference;
export type BriefContainer_me = {|
  +$fragmentRefs: BriefHeaderActionsBar_me$ref,
  +$refType: BriefContainer_me$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "BriefContainer_me",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BriefHeaderActionsBar_me",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7f0e88e5657ccb1feb6480e446799630';
module.exports = node;
