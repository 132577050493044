/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import FilterItemHeader from 'components/material/Filters/Advanced/FilterItemHeader';

import UserContactFilterContainer from './UserContactFilterContainer';

import type { UserContactFilterItemQueryResponse } from './__generated__/UserContactFilterItemQuery.graphql';

const Container = styled.div`
  flex: 1 1 auto;
  padding: 0 0 0 10px;
`;

const query = graphql`
  query UserContactFilterItemQuery(
    $query: String!
    $contactFilters: ContactFilters!
    $userIds: [ID!]!
    $contactIds: [ID!]!
  ) {
    query {
      ...UserContactFilterContainer_query
    }
  }
`;

export default class UserContactFilterItem extends React.Component<
  {
    userFilterName: string,
    contactFilterName: string,
    userIds: ?$ReadOnlyArray<string>,
    contactIds: ?$ReadOnlyArray<string>,
    label: string,
    onChange: (name: string, values: ?$ReadOnlyArray<string>) => void,
    expanded?: boolean,
    searchPlaceholder?: string,
  },
  {
    query: string,
  },
> {
  state = {
    query: '',
  };

  handleFilterClear = () => {
    this.props.onChange('requesterUserIds', null);
    this.props.onChange('requesterContactIds', null);
  };

  handleUserChange = (userIds: ?$ReadOnlyArray<string>, queryVal: string) => {
    this.setState({ query: queryVal });
    this.props.onChange(this.props.userFilterName, userIds);
  };

  handleContactChange = (contactIds: ?$ReadOnlyArray<string>, queryVal: string) => {
    this.setState({ query: queryVal });
    this.props.onChange(this.props.contactFilterName, contactIds);
  };

  render() {
    const userIds = this.props.userIds || [];
    const contactIds = this.props.contactIds || [];
    return (
      <FilterItemHeader
        label={this.props.label}
        count={(userIds.length || 0) + (contactIds.length || 0)}
        onClear={this.handleFilterClear}
        expanded={this.props.expanded}
      >
        <Container>
          <DefaultQueryRenderer
            query={query}
            variables={{
              query: this.state.query,
              contactFilters: { query: this.state.query },
              userIds,
              contactIds,
            }}
            renderSuccess={(response: UserContactFilterItemQueryResponse) => (
              <UserContactFilterContainer
                query={response.query}
                userIds={userIds}
                contactIds={contactIds}
                onUserChange={this.handleUserChange}
                onContactChange={this.handleContactChange}
                searchPlaceholder={this.props.searchPlaceholder}
              />
            )}
            renderLoading={() => (
              <UserContactFilterContainer
                query={null}
                userIds={userIds}
                contactIds={contactIds}
                onUserChange={this.handleUserChange}
                onContactChange={this.handleContactChange}
              />
            )}
          />
        </Container>
      </FilterItemHeader>
    );
  }
}
