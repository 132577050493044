/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import currentOrigin from 'utils/currentOrigin';
import { stringifyLocation } from 'utils/Location';

import AddToCalendarIcon from 'images/addToCalendar.svg';
import BriefIcon from 'images/brief.svg';
import RequestIcon from 'images/request.svg';
import ExternalCalendarExport from 'components/ExternalCalendarExport';
import Tooltip from 'components/material/Tooltip';

import type { EventHeaderActions_event } from './__generated__/EventHeaderStatus_event.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 13px;
  color: #4a5665;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    background: #e9e4f5;
  }

  ${props =>
    props.disabled &&
    css`
      padding: 0 10px;
      opacity: 0.6;
      cursor: initial;
      pointer-events: none;
    `}

  svg {
    margin-right: 10px;
  }
`;

const StyledBriefIcon = styled(BriefIcon)`
  width: 15px;
  height: 18px;
`;

const StyledRequestIcon = styled(RequestIcon)`
  width: 20px;
  height: 20px;
  margin-bottom: -2px;
`;

class EventHeaderActions extends React.Component<
  {
    event: EventHeaderActions_event,
  },
  {
    showCalendarExportWindow: boolean,
  },
> {
  state = { showCalendarExportWindow: false };

  handleViewBrief = () => {
    window.open(`/events/${this.props.event.slug}/brief`, '_blank');
  };

  handleViewEventRequest = () => {
    window.open(`/dashboard/event_requests/${this.props.event.eventRequestSubmission.id}`);
  };

  handleEventCalendarAdd = () => this.setState({ showCalendarExportWindow: true });

  handleHideAddCalendarWindow = () => this.setState({ showCalendarExportWindow: false });

  renderCalendarExportWindow = () => {
    const {
      name,
      slug,
      token,
      primaryLocation,
      startDate,
      endDate,
      startDateAllDay,
      endDateAllDay,
      tz,
      description,
      website,
    } = this.props.event;
    const icsUrl = `${currentOrigin()}/v1/events/${token}/ical`;
    const googleCalendarProps = {
      name,
      location: stringifyLocation(primaryLocation),
      dates: {
        startDate,
        endDate,
        allDay: startDateAllDay || endDateAllDay,
        tz,
      },
      details: `
          ${name}
          ${currentOrigin()}/events/${slug}
          ${description || ''}
          ${website || ''}
        `,
    };

    return (
      <ExternalCalendarExport
        listTitle={name}
        icsUrl={icsUrl}
        googleCalendarProps={googleCalendarProps}
        showDownloadOption
        onHide={this.handleHideAddCalendarWindow}
      />
    );
  };

  render() {
    const {
      event,
      event: { eventRequestSubmission: eventRequest },
    } = this.props;

    return (
      <Container>
        <Tooltip
          label={event.startDate ? null : 'This event needs a date first'}
          placement="bottom"
        >
          <ActionButton onClick={this.handleEventCalendarAdd} disabled={!event.startDate}>
            <AddToCalendarIcon />
            Add to calendar
          </ActionButton>
          {this.state.showCalendarExportWindow && this.renderCalendarExportWindow()}
        </Tooltip>
        {(event.viewerCanViewBrief || event.viewerCanUpdate) && (
          <ActionButton onClick={this.handleViewBrief}>
            <StyledBriefIcon />
            View Brief
          </ActionButton>
        )}
        {eventRequest && eventRequest.viewerCanUpdate && (
          <ActionButton onClick={this.handleViewEventRequest}>
            <StyledRequestIcon />
            View Request
          </ActionButton>
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(
  EventHeaderActions,
  graphql`
    fragment EventHeaderActions_event on Event {
      id
      slug
      viewerCanViewBrief
      viewerCanUpdate
      startDate
      endDate
      startDateAllDay
      endDateAllDay
      tz
      token
      name
      description
      website
      primaryLocation {
        city
        state
        name
        country
      }
      eventRequestSubmission {
        id
        viewerCanUpdate
      }
    }
  `,
);
