/**
 * @flow
 * @relayHash c99297c298ad6636644c2a562fbdc49a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
type UserSearchRow_user$ref = any;
export type UserSearchQueryVariables = {|
  eventId: string,
  query: string,
|};
export type UserSearchQueryResponse = {|
  +event: ?{|
    +viewerCanAddStaff?: boolean,
    +staffers?: {|
      +edges: $ReadOnlyArray<{|
        +node: {|
          +user: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +avatar: ?string,
            +email: string,
            +$fragmentRefs: UserSearchRow_user$ref & MaterialAvatar_user$ref,
          |}
        |}
      |}>
    |},
    +team?: {|
      +users: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +id: string,
            +firstName: string,
            +lastName: string,
            +avatar: ?string,
            +email: string,
            +$fragmentRefs: UserSearchRow_user$ref & MaterialAvatar_user$ref,
          |}
        |}>
      |}
    |},
  |}
|};
export type UserSearchQuery = {|
  variables: UserSearchQueryVariables,
  response: UserSearchQueryResponse,
|};
*/


/*
query UserSearchQuery(
  $eventId: ID!
  $query: String!
) {
  event: node(id: $eventId) {
    __typename
    ... on Event {
      viewerCanAddStaff
      staffers(first: 3, query: $query) {
        edges {
          node {
            user {
              id
              firstName
              lastName
              avatar
              email
              ...UserSearchRow_user
              ...MaterialAvatar_user
            }
            id
          }
        }
      }
      team {
        users(first: 3, query: $query) {
          edges {
            node {
              id
              firstName
              lastName
              avatar
              email
              ...UserSearchRow_user
              ...MaterialAvatar_user
            }
          }
        }
        id
      }
    }
    id
  }
}

fragment UserSearchRow_user on User {
  firstName
  lastName
  email
  ...MaterialAvatar_user
}

fragment MaterialAvatar_user on Assignable {
  id
  firstName
  lastName
  email
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "eventId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanAddStaff",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query",
    "type": "String"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v9 = [
  v4,
  v5,
  v6,
  v7,
  v8,
  {
    "kind": "FragmentSpread",
    "name": "UserSearchRow_user",
    "args": null
  },
  {
    "kind": "FragmentSpread",
    "name": "MaterialAvatar_user",
    "args": null
  }
],
v10 = [
  v4,
  v5,
  v6,
  v7,
  v8
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserSearchQuery",
  "id": null,
  "text": "query UserSearchQuery(\n  $eventId: ID!\n  $query: String!\n) {\n  event: node(id: $eventId) {\n    __typename\n    ... on Event {\n      viewerCanAddStaff\n      staffers(first: 3, query: $query) {\n        edges {\n          node {\n            user {\n              id\n              firstName\n              lastName\n              avatar\n              email\n              ...UserSearchRow_user\n              ...MaterialAvatar_user\n            }\n            id\n          }\n        }\n      }\n      team {\n        users(first: 3, query: $query) {\n          edges {\n            node {\n              id\n              firstName\n              lastName\n              avatar\n              email\n              ...UserSearchRow_user\n              ...MaterialAvatar_user\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment UserSearchRow_user on User {\n  firstName\n  lastName\n  email\n  ...MaterialAvatar_user\n}\n\nfragment MaterialAvatar_user on Assignable {\n  id\n  firstName\n  lastName\n  email\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "event",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "type": "Event",
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "staffers",
                "storageKey": null,
                "args": v3,
                "concreteType": "StaffMembershipRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffMembershipRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembership",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": v9
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "users",
                    "storageKey": null,
                    "args": v3,
                    "concreteType": "UserRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": v9
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "event",
        "name": "node",
        "storageKey": null,
        "args": v1,
        "concreteType": null,
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          v4,
          {
            "kind": "InlineFragment",
            "type": "Event",
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "staffers",
                "storageKey": null,
                "args": v3,
                "concreteType": "StaffMembershipRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffMembershipRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembership",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": v10
                          },
                          v4
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "users",
                    "storageKey": null,
                    "args": v3,
                    "concreteType": "UserRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": v10
                          }
                        ]
                      }
                    ]
                  },
                  v4
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab6585dc9cce372aad5348dd008e9a6f';
module.exports = node;
