/* @flow */
import React from 'react';
import styled from 'styled-components';
import Clipboard from 'clipboard';

import DuplicateIcon from 'images/duplicate.svg';
import EditIcon from 'images/edit.svg';
import EmbedIcon from 'images/events/embed.svg';
import EmbedWindow from 'components/EmbedWindow';
import Tooltip from 'components/material/Tooltip';

const Root = styled.div`
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0 20px 0 40px;
  text-transform: uppercase;
  background: #285dff;
`;

const Title = styled.div`
  margin-right: auto;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
`;

const Action = styled.button`
  height: 32px;
  margin-left: 15px;
  padding: 0 20px;
  border: solid 1px #edf0fc;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #f5f5f5;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.24);
  }
  svg {
    margin-right: 10px;
  }
`;

const StyledAction = styled(Action)`
  display: flex;
  align-items: center;
  svg {
    margin: 2px 10px 0 0;
  }
`;

const StyledText = styled.div`
  margin-top: 2px;
`;

const StyledEmbedWindow = styled(EmbedWindow)`
  margin-top: 65px;
  margin-right: 115px;
`;

export default class Header extends React.PureComponent<
  {
    url: string,
    onToggleSidebar: () => void,
    isShared: boolean,
  },
  { showEmbedWindow: boolean, copied: boolean },
> {
  state = { showEmbedWindow: false, copied: false };

  copyRef = React.createRef();

  clipboard = null;

  componentDidMount() {
    if (this.props.isShared) {
      this.clipboard = new Clipboard(this.copyRef.current, {
        text: () => this.props.url,
      }).on('success', () => {
        this.setState({ copied: true });
      });
    }
  }

  componentWillUnmount() {
    if (this.clipboard) this.clipboard.destroy();
  }

  handleShowEmbedWindow = () => {
    this.setState({ showEmbedWindow: true });
  };

  handleMouseLeave = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 300);
  };

  handleHideEmbedWindow = () => {
    this.setState({ showEmbedWindow: false });
  };

  render() {
    const { url, isShared, onToggleSidebar } = this.props;
    const { showEmbedWindow } = this.state;

    return (
      <>
        <Root>
          <Title>Preview Mode</Title>

          {isShared && (
            <>
              <Tooltip label={this.state.copied ? 'Copied' : ''} placement="bottom">
                <Action ref={this.copyRef} onMouseLeave={this.handleMouseLeave}>
                  <DuplicateIcon />
                  Copy Link
                </Action>
              </Tooltip>

              <StyledAction onClick={this.handleShowEmbedWindow}>
                <EmbedIcon />
                <StyledText>Embed</StyledText>
              </StyledAction>
            </>
          )}

          <Action onClick={onToggleSidebar}>
            <EditIcon />
            Edit Style
          </Action>
        </Root>

        {showEmbedWindow && <StyledEmbedWindow url={url} onHide={this.handleHideEmbedWindow} />}
      </>
    );
  }
}
