/* @flow */

const leftPad = (number: number) => String(number).padStart(2, '0');

const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = duration % 60;

  return `${leftPad(hours)}:${leftPad(minutes)}:${leftPad(seconds)}`;
};

export default formatDuration;
