/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import CustomizableFieldsList from 'components/FieldSettings/CustomizableFieldsList';
import { type Field } from 'components/FieldSettings/types';

import { type StaffFieldsSettingsQueryResponse } from './__generated__/StaffFieldsSettingsQuery.graphql';

const query = graphql`
  query StaffFieldsSettingsQuery(
    $customizableType: [CustomizableType!]!
    $includeDisabled: Boolean
  ) {
    org {
      id
      customFields(customizableType: $customizableType, includeDisabled: $includeDisabled) {
        edges {
          node {
            id
            kind
            label
            fieldName
            required
            enabled
            order
            showInCreateForm
            showInMobileForm
            options {
              edges {
                node {
                  id
                  name
                  editable
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  padding: 30px 34px 100px 30px;
`;

const defaultFields: $ReadOnlyArray<Field> = [
  {
    id: 'name',
    kind: 'TEXT',
    label: 'Name',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
  {
    id: 'email',
    kind: 'TEXT',
    label: 'Email',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
  {
    id: 'access_level',
    kind: 'TEXT',
    label: 'Access Level',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
  {
    id: 'based',
    kind: 'TEXT',
    label: 'Based',
    fieldName: null,
    required: true,
    showInCreateForm: true,
    showInMobileForm: true,
    enabled: true,
    order: -1,
    options: [],
    isDefault: true,
    isSuggested: false,
  },
];

const StaffFieldsSettings = (props: { title: string }) => {
  const filters = {
    customizableType: ['STAFFMEMBERSHIP'],
    includeDisabled: true,
  };

  return (
    <DocumentTitle title={props.title}>
      <Container>
        <DefaultQueryRenderer
          query={query}
          variables={filters}
          renderSuccess={(response: StaffFieldsSettingsQueryResponse) => {
            const customFields = sortBy(
              response.org.customFields.edges.map(edge => {
                const { options, ...rest } = edge.node;
                return {
                  ...rest,
                  isDefault: false,
                  isSuggested: false,
                  canDisable: true,
                  options: (options && options.edges.map(optionEdge => optionEdge.node)) || [],
                };
              }),
              'order',
            );
            return (
              <CustomizableFieldsList
                orgId={response.org.id}
                customizableType="STAFFMEMBERSHIP"
                filters={filters}
                fields={[...defaultFields, ...customFields]}
              />
            );
          }}
        />
      </Container>
    </DocumentTitle>
  );
};

export default StaffFieldsSettings;
