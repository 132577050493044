/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import IMAGES from 'config/shared_backgrounds.json';

import pickFiles from 'utils/uploading/pickFiles';
import showErrorPopup from 'utils/showErrorPopup';

import Window, {
  WindowTitle,
  WindowClose,
  WindowHeader,
  WindowContent,
} from 'components/material/Window';
import Button from 'components/material/Button';

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SelectTitle = styled.h4`
  font-weight: 400;
  color: #626161;
  margin: 10px 5px;
`;

const ImageItem = styled.div`
  cursor: pointer;
  display: block;
  height: 85px;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  border: 1px solid #dfdede;
  border-radius: 3px;
`;

const ImageItemWrapper = styled.div`
  width: 25%;
  padding: 5px;
  text-align: center;
  ${props =>
    props.selected &&
    css`
      ${ImageItem} {
        border: 2px solid #29cc71;
        position: relative;
        &:after {
          border-radius: 2px;
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(41, 204, 113, 0.5);
          background-image: linear-gradient(to bottom, rgba(9, 15, 23, 0.5), transparent);
        }
      }
    `};
`;

const RemoveCustom = styled.a`
  font-size: 12px;
  color: #3baadd;
  cursor: pointer;
`;

const UploadContainer = styled.div`
  padding: 40px 0;
  > a {
    font-size: 13px;
    color: #8d9aa3;
    cursor: pointer;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

const CancelButton = styled(Button)`
  padding: 6px 16px 6px 16px;
`;

const SubmitButton = styled(Button)`
  margin-left: 15px;
`;

export default class BgImageSelectWindow extends React.Component<
  {
    value: ?string,
    onSave: (?string) => void,
    onHide: () => void,
  },
  {
    selected: ?string,
    custom: ?string,
  },
> {
  state = {
    selected: this.props.value,
    custom: !IMAGES.some(img => img === this.props.value) ? this.props.value : null,
  };

  handleSelect = (selected: string) => {
    this.setState({ selected });
  };

  handleRemove = () => {
    const { selected } = this.state;
    this.setState({
      selected: !IMAGES.some(img => img === selected) ? selected : IMAGES[0],
      custom: null,
    });
  };

  handleSave = () => {
    this.props.onSave(this.state.selected);
    this.props.onHide();
  };

  handleUpload = () => {
    const options = {
      maxFiles: 1,
      accept: 'image/*',
      imageMin: [1280, 1024],
    };
    pickFiles(options)
      .then(files => {
        this.setState({
          custom: files[0].fileurl,
          selected: files[0].fileurl,
        });
      })
      .catch(showErrorPopup);
  };

  render() {
    const { custom, selected } = this.state;
    return (
      <Window onHide={this.props.onHide} size="large">
        <WindowHeader>
          <WindowTitle>Change Background</WindowTitle>
          <WindowClose onClick={this.props.onHide} />
        </WindowHeader>
        <WindowContent>
          <SelectTitle>Select Background</SelectTitle>
          <ImagesContainer>
            {IMAGES.map(img => (
              <ImageItemWrapper selected={selected === img} key={img}>
                <ImageItem backgroundImage={img} onClick={() => this.handleSelect(img)} />
              </ImageItemWrapper>
            ))}
            {custom && (
              <ImageItemWrapper selected={!IMAGES.some(img => img === selected)}>
                <ImageItem backgroundImage={custom} onClick={() => this.handleSelect(custom)} />
                <RemoveCustom onClick={this.handleRemove}>Remove</RemoveCustom>
              </ImageItemWrapper>
            )}
          </ImagesContainer>
          <UploadContainer>
            <a onClick={this.handleUpload}>+ Upload Background (recommended size: 1280 x 1024px)</a>
          </UploadContainer>
          <Footer>
            <CancelButton label="Cancel" minimal onClick={this.props.onHide} />
            <SubmitButton label="Save" primary onClick={this.handleSave} />
          </Footer>
        </WindowContent>
      </Window>
    );
  }
}
