/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import NoRegistration from 'images/registration/noRegistration.svg';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import RegistrationSummary from './RegistrationSummary';

import type { RegistrationResults_event } from './__generated__/RegistrationResults_event.graphql';
import type { RegistrationResults_org } from './__generated__/RegistrationResults_org.graphql';
import type { RegistrationResultsQueryResponse } from './__generated__/RegistrationResultsQuery.graphql';

const Container = styled.div`
  margin-top: 18px;
  max-width: 815px;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 39px;
  border: solid 1px #dadada;
  border-radius: 8px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

const EmptyText = styled.div`
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  color: #3e4859;
`;

const TopContainer = styled.div`
  height: 160px;
`;

const query = graphql`
  query RegistrationResultsQuery($eventSlug: String!, $filters: ContactFilters!) {
    event(slug: $eventSlug) {
      ...RegistrationSummary_event
      contactRegistrationsTotal
    }
  }
`;

class RegistrationResults extends React.Component<{
  org: RegistrationResults_org,
  event: RegistrationResults_event,
}> {
  render() {
    const { org, event } = this.props;

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          eventSlug: event.slug,
          filters: {
            registrationStatusIds: org.registeredOption.id,
          },
        }}
        renderSuccess={({ event: responseEvent }: RegistrationResultsQueryResponse) => {
          if (!responseEvent) {
            return null;
          }
          return (
            <Container>
              {responseEvent.contactRegistrationsTotal > 0 ? (
                <>
                  <TopContainer>
                    {responseEvent && <RegistrationSummary event={responseEvent} />}
                  </TopContainer>
                </>
              ) : (
                <EmptyContainer>
                  <NoRegistration />
                  <EmptyText>No registered contacts yet</EmptyText>
                </EmptyContainer>
              )}
            </Container>
          );
        }}
      />
    );
  }
}

export default createFragmentContainer(RegistrationResults, {
  event: graphql`
    fragment RegistrationResults_event on Event {
      slug
      registrationForm {
        publishedAt
      }
    }
  `,
  org: graphql`
    fragment RegistrationResults_org on Org {
      registeredOption: fieldDefaultOption(
        customizableType: EVENTCONTACT
        fieldName: "registration_status_id"
        optionName: "Registered"
      ) {
        id
      }
    }
  `,
});
