/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import eventBriefSharingOptions, {
  type BriefSharingGraphValue,
} from 'config/eventBriefSharingOptions';

import escapeHtml from 'utils/string/escapeHtml';
import sanitizeHtml from 'utils/string/sanitizeHtml';

import EmbedButton from 'components/EmbedButton';
import Radio from 'components/material/Radio';

const StyledRadio = styled(Radio)`
  flex: 1 1 auto;
  &:last-of-type {
    margin-bottom: 3px;
  }
  color: ${props =>
    props.checked ? props.theme.darkestTextColor : props.theme.infoMenuHoverColor};
  > div:first-of-type {
    ${props =>
      props.checked
        ? css`
            color: ${props.theme.highlightColor};
          `
        : css`
            color: ${props.theme.iron};
          `};
  }
  > div:last-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }
`;

const Description = styled.div`
  margin: 5px 0 0 22px;
  font-size: 13px;
  line-height: 16px;
  color: ${props => (props.checked ? 'rgb(74, 86, 101)' : 'rgba(74, 86, 101, 0.54)')};
  > a {
    color: ${props => props.theme.primaryActionColor};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Option = styled.div`
  flex: 1 1 auto;
`;

export default class SharingOptions extends React.PureComponent<{
  orgName: string,
  value: BriefSharingGraphValue,
  samlEnabled?: boolean,
  shareLink?: string,
  onChange: (option: BriefSharingGraphValue) => void,
  className?: string,
}> {
  render() {
    const selectedOption = this.props.value;
    return (
      <div className={this.props.className}>
        {eventBriefSharingOptions.map(option => (
          <Row key={option.value}>
            <Option>
              <StyledRadio
                label={option.label.replace(/%s/g, this.props.orgName)}
                checked={option.value === selectedOption}
                onChange={this.props.onChange}
                value={option.value}
                disabled={!this.props.samlEnabled && option.value === 'SSO'}
              />
              <Description
                checked={option.value === selectedOption}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    option.description.replace(/%s/g, escapeHtml(this.props.orgName)),
                  ),
                }}
              />
            </Option>
            {option.value === 'PUBLIC' &&
              selectedOption === option.value &&
              this.props.shareLink && (
                <EmbedButton link={this.props.shareLink} size={27} tooltipPlacement="left" />
              )}
          </Row>
        ))}
      </div>
    );
  }
}
