/* @flow */
import React from 'react';
import styled from 'styled-components';

const Column = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -30px;
  width: 30px;
  height: 45px;
  color: ${props => props.theme.secondaryActionColor};

  &:hover {
    color: ${props => props.theme.primaryActionColor};
  }

  &:active {
    color: ${props => props.theme.primaryActionDarkerColor};
  }
`;

export const StyledTrashIcon = styled.i`
  visibility: hidden;
`;

/**
 * Floats to the right of the table row.  There is only space for one.
 * No need to also add to the SortableHeader since its absoluately positioned.
 */
export default class TrashColumn extends React.Component<{
  onClick: (e: SyntheticEvent<HTMLDivElement>) => void,
}> {
  render() {
    return (
      <Column onClick={this.props.onClick}>
        <StyledTrashIcon className="fa fa-trash" />
      </Column>
    );
  }
}
