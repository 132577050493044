/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import SearchableList from 'components/material/SearchableList';

const OptionRow = styled.div`
  padding: 7px;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.rowPrimaryTextColor};
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  ${props => props.focused && `background: ${props.theme.hoverRowColor};`};
`;

const OptionLabel = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  flex: 1 1 auto;
`;

type Option<Value> = {
  label: string,
  value: Value,
};

/**
 * Simple SearchableList specifically for options with plain string labels
 */
export default class SearchableStringList<Value> extends React.Component<{
  value: ?Value,
  options: $ReadOnlyArray<Option<Value>>,
  onSelect: (Value) => void,
}> {
  handleSelect: (Option<Value>) => void = (option) => {
    this.props.onSelect(option.value);
  }

  handleRenderItem: (
    option: Option<Value>,
    focused: boolean,
  ) => React.Element<React.ElementType> = (option, focused) => {
    return (
      <OptionRow key={option.label} focused={focused}>
        <OptionLabel>
          {option.label}
        </OptionLabel>
      </OptionRow>
    );
  };

  render() {
    const unselectedOptions = this.props.options.filter(opt => opt.value !== this.props.value);

    return (
      <SearchableList
        options={unselectedOptions}
        queryAttrs={['label']}
        renderItem={this.handleRenderItem}
        onSelect={this.handleSelect}
        disableSearch={this.props.options.length < 10}
      />
    );
  }
}
