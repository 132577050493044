/* @flow */
import * as React from 'react';

export type SharedFormThemeType = $Shape<{
  textFont: string,
  textColor: string,
  textStyle: string,
  backgroundColor: string,
  buttonColor?: string,
  buttonTextColor?: string,
}>;

const SharedFormThemeContext = React.createContext<SharedFormThemeType>({});

export const withSharedFormTheme = <Props: { sharedFormTheme: SharedFormThemeType }>(
  Component: React.ComponentType<Props> | string,
): React.ComponentType<$Diff<Props, { sharedFormTheme?: SharedFormThemeType }>> => (
  props: Props,
) => (
  <SharedFormThemeContext.Consumer>
    {sharedFormTheme => <Component {...props} sharedFormTheme={sharedFormTheme} />}
  </SharedFormThemeContext.Consumer>
);

export default SharedFormThemeContext;
