/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ColumnComponent_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RowComponent_registrationForm$ref: FragmentReference;
export type RowComponent_registrationForm = {|
  +id: string,
  +$fragmentRefs: ColumnComponent_registrationForm$ref,
  +$refType: RowComponent_registrationForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RowComponent_registrationForm",
  "type": "RegistrationForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ColumnComponent_registrationForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7ec774dd40f455979dbc086e38583da4';
module.exports = node;
