/* @flow */
import enqueueCall from './enqueueCall';

const reset = () => {
  enqueueCall(() => {
    window.analytics.reset();
  });
};

export default reset;
