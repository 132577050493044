/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';
import parseTypedQueryString, { stringParamToSort } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';

import { type CreateContactFromWindow } from 'graph/mutations/contact/createContact';
import addContactAssignments from 'graph/mutations/event/addContactAssignments';
import showModernMutationError from 'graph/utils/showModernMutationError';

import NoResult from 'images/noResult.svg';
import Button from 'components/budget/Button';
import EmptyView from 'components/budget/EmptyView';
import AddToEventsWindow from 'components/Contacts/AddToEventsWindow';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import type { Sort } from 'components/material/SortableHeader';

import ContactEventsList from './ContactEventsList';

import { type ContactEvents_contact } from './__generated__/ContactEvents_contact.graphql';
import type { ContactEventsQueryResponse } from './__generated__/ContactEventsQuery.graphql';

const Container = styled.div`
  margin: 25px 0;
`;

const StyledButton = styled(Button)`
  margin-bottom: 20px;
`;

const query = graphql`
  query ContactEventsQuery($contactId: ID!, $sort: EventContactSort, $direction: Direction!) {
    contact: node(id: $contactId) {
      ... on Contact {
        ...ContactEventsList_contact
        contactEvents(first: 1000, sort: $sort, direction: $direction)
          @connection(key: "ContactEventsList_contactEvents", filters: []) {
          edges {
            ...ContactEventsList_contactEvents
            node {
              id
            }
          }
        }
      }
    }
    org {
      ...ContactEventsList_org
    }
  }
`;

class ContactEvents extends React.Component<
  {
    contact: ContactEvents_contact,
    fromWindow: CreateContactFromWindow,
    eventsCount: number,
    location: Location,
    history: History,
  },
  {
    windowShown: boolean,
  },
> {
  state = { windowShown: false };

  handleSort = (sort: Sort) => {
    replaceSortQueryParam(this.props.history, sort, 'eventSort');
  };

  handleAddingToEventsShow = () => {
    this.setState({ windowShown: true });
  };

  handleAddingToEventsHide = () => {
    this.setState({ windowShown: false });
  };

  handleAddToEventsSave = (eventIds: $ReadOnlyArray<string>) => {
    addContactAssignments(
      eventIds,
      [{ contactId: this.props.contact.id }],
      this.props.fromWindow,
    ).catch(showModernMutationError);
    this.handleAddingToEventsHide();
  };

  render() {
    const { contact, location, history, eventsCount } = this.props;

    const filters = parseTypedQueryString(location.search, {
      eventSort: stringParamToSort,
    });
    const sort = filters.eventSort || {
      key: 'START_DATE',
      asc: true,
    };
    return (
      <Container>
        <StyledButton onClick={this.handleAddingToEventsShow} loading={this.state.windowShown}>
          Add to Event
        </StyledButton>
        {this.state.windowShown && (
          <AddToEventsWindow
            contactName={fullNameOfUser(contact)}
            onCancel={this.handleAddingToEventsHide}
            onSave={this.handleAddToEventsSave}
          />
        )}
        {eventsCount > 0 ? (
          <DefaultQueryRenderer
            query={query}
            variables={{
              contactId: contact.id,
              sort: sort.key,
              direction: sort.asc ? 'ASC' : 'DESC',
            }}
            renderSuccess={(response: ContactEventsQueryResponse) => {
              if (!response.contact) {
                return null;
              }
              const contactEvents = response.contact.contactEvents
                ? response.contact.contactEvents.edges
                : null;
              return (
                <ContactEventsList
                  contact={response.contact}
                  contactEvents={contactEvents}
                  org={response.org}
                  sort={sort}
                  onChangeSort={this.handleSort}
                  history={history}
                  location={location}
                />
              );
            }}
          />
        ) : (
          <EmptyView message="Add the first event for this contact." icon={<NoResult />} />
        )}
      </Container>
    );
  }
}

export default createFragmentContainer(ContactEvents, {
  contact: graphql`
    fragment ContactEvents_contact on Contact {
      id
      firstName
      lastName
    }
  `,
});
