/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import sortBy from 'lodash/sortBy';

import personalizationDefaults from 'config/personalizationDefaults';

import { createMoment, renderDateRange } from 'utils/Date';
import insertDriveFile from 'utils/export/insertDriveFile';
import { getFontFamily } from 'utils/fonts';
import { stringifyLocation } from 'utils/Location';
import isValidWebsite from 'utils/validators/isValidWebsite';

import SharedScheduleList from './SharedScheduleList';

class GoogleDocsSchedule extends React.Component<{
  onFinished: Object => void,
  schedule: Object,
  event: Object,
  member: Object,
}> {
  componentDidMount() {
    this.insertGoogleDocFile();
  }

  insertGoogleDocFile = () => {
    const { schedule, event, onFinished } = this.props;
    const title = `${event.name} - ${schedule.name}`;
    const html = this.generateDocument(title);
    const blob = new Blob([html], { type: 'text/html' });
    insertDriveFile(blob, title)
      .then(alternateLink => {
        onFinished({ success: true, alternateLink });
      })
      .catch(err => {
        onFinished({ success: false, err });
      });
  };

  generateDocument = title => {
    const target = document.querySelector('.shared-schedule-wrapper');
    const html = target ? target.innerHTML : '';

    return `<html xmlns='http://www.w3.org/TR/REC-html40'>
              <head><title>${title}</title>
                <style><!--
                  @page, body, html { mso-page-orientation: Landscape; }
                  table, table tr, table tr td { border: 0; }
                  td { page-break-inside:avoid; padding: 5px 5px 5px 3px; }
                  div.Section1 { page:Section1; }
                  .logo-image { width:110px; }
                --></style>
              </head>
              <body>
                <div class=Section1>
                  ${html}
                </div>
              </body>
            </html>`;
  };

  render() {
    const { schedule, event } = this.props;
    const team = event.team;
    const scheduleDays = schedule.scheduleDays.edges.map(x => x.node);

    return (
      <div className="shared-schedule-wrapper">
        <div style={{ fontFamily: getFontFamily(team.font) }}>
          <table width="100%">
            <tbody width="100%">
              <tr>
                <td width="80%">
                  <h2>
                    <strong>{schedule.name}</strong>
                  </h2>
                  <h1 style={{ color: team.primaryColor }}>{event.name}</h1>
                  <br />
                  {event.start_date && (
                    <div>
                      <strong>
                        <span>Date & Time - </span>
                      </strong>
                      {renderDateRange({
                        startDate: event.startDate,
                        endDate: event.endDate,
                        startDateAllDay: event.startDateAllDay,
                        endDateAllDay: event.endDateAllDay,
                        tz: event.tz,
                      })}
                    </div>
                  )}
                  {(event.primaryLocation ||
                    event.virtualLocation ||
                    event.zoomLocation ||
                    event.g2wLocation) && (
                    <div>
                      <strong>
                        <span>Location - </span>
                      </strong>
                      {event.primaryLocation && stringifyLocation(event.primaryLocation)}
                      {event.zoomLocation ||
                      event.g2wLocation ||
                      (event.virtualLocation && isValidWebsite(event.virtualLocation)) ? (
                        <a
                          href={
                            (event.zoomLocation && event.zoomLocation.joinUrl) ||
                            (event.g2wLocation && event.g2wLocation.registrationUrl) ||
                            event.virtualLocation
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={e => e.stopPropagation()}
                          style={{ color: '#3baadd' }}
                        >
                          {(event.zoomLocation && event.zoomLocation.joinUrl) ||
                            (event.g2wLocation && event.g2wLocation.registrationUrl) ||
                            event.virtualLocation}
                        </a>
                      ) : (
                        event.virtualLocation
                      )}
                    </div>
                  )}
                </td>
                <td width="20%">
                  <img
                    src={team.logo || personalizationDefaults.logo}
                    alt="logo"
                    className="logo-image"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <br />
          {sortBy(scheduleDays, 'date').map(day => {
            const items = day.scheduleItems;
            if (items.length === 0) return null;

            return (
              <div key={day.id}>
                <div style={{ color: team.primaryColor }}>
                  {createMoment(event.tz)(day.date).renderDateTime(true)}
                  {day.title ? ` - ${day.title}` : ''}
                </div>
                <SharedScheduleList
                  member={this.props.member}
                  key={day.id}
                  scheduleDay={day}
                  items={items}
                  tz={event.tz}
                  color={team.secondaryColor}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(GoogleDocsSchedule, {
  event: graphql`
    fragment GoogleDocsSchedule_event on Event {
      team {
        logo
        primaryColor
        secondaryColor
        font
      }
      name
      startDate
      endDate
      startDateAllDay
      endDateAllDay
      tz
      website
      virtualLocation
      primaryLocation {
        id
        city
        name
        country
        state
      }
      zoomLocation {
        joinUrl
      }
      g2wLocation {
        registrationUrl
      }
    }
  `,
  schedule: graphql`
    fragment GoogleDocsSchedule_schedule on Schedule {
      id
      name
      scheduleDays {
        edges {
          node {
            id
            title
            date
            scheduleItems {
              edges {
                node {
                  scheduleParticipants {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
            ...SharedScheduleList_scheduleDay
          }
        }
      }
    }
  `,
});
