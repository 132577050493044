/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import MaterialSelectField, { Error, Required } from 'components/material/SelectField';

import SelectFieldWrapper from './components/SelectFieldWrapper';

export const StyledSelectField = styled(MaterialSelectField)`
  min-height: 40px;
  margin-top: 10px;
  padding-left: 8px;
  border-radius: 4px;
  border: solid 1px #dadada;
  background: #fff;
  outline: none;
  font-size: 15px;
  line-height: 1.4;
  transition: 0.2s border-color;
  color: #3e4859;
  &:hover {
    border: solid 1px #dadada;
  }
  &:focus {
    border-color: #3ba9da;
  }
  > div {
    padding: 8px 0;
    border: none;
  }
  > div ~ div {
    transform: translate3d(5px, 41px, 0px) !important;
  }
  ${Required} {
    display: none;
  }
  ${Error} {
    display: none;
  }
`;

export default class SelectField extends React.Component<
  {
    label: string,
    required: boolean,
    options: $ReadOnlyArray<{ label: string, value: string }>,
    onChange: (value: ?string) => void,
    onChangeOtherInput?: (value: ?string) => void,
    values: $ReadOnlyArray<string>,
    disabled?: boolean,
    error: ?string,
  },
  {
    selectedOption: string,
  },
> {
  state = {
    selectedOption: this.props.values.length > 0 ? this.props.values[0] : '',
  };

  handleOptionChange = (value: ?string) => {
    if (this.props.disabled) return;

    this.props.onChange(value);

    this.setState({ selectedOption: value && value !== 'none' ? value : '' });
  };

  render() {
    const { options, values, required, label, error, disabled, onChangeOtherInput } = this.props;

    return (
      <SelectFieldWrapper
        label={label}
        error={error}
        required={required}
        values={values}
        onChangeOtherInput={onChangeOtherInput}
      >
        <StyledSelectField
          value={this.state.selectedOption}
          onChange={this.handleOptionChange}
          options={options.map(option => ({ ...option }))}
          disabled={disabled}
          required={required}
          error={error}
          clearable
          searchable
        />
      </SelectFieldWrapper>
    );
  }
}
