/* @flow */
import * as React from 'react';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';

import markerClusterIcon from 'images/marker-clusterer1.png';
import markerClusterIconSelected from 'images/marker-clusterer1-selected.png';

import type { InfoBoxOptionsType, MapMarkerType } from './googleMapsWrapperTypes';
import MarkerWrapper from './MarkerWrapper';

export default class MarkersWrapper extends React.PureComponent<
  {
    markers: $ReadOnlyArray<MapMarkerType>,
    clustered?: boolean,
    handleOnToggle: (markerId: string) => void,
    toggledBoxId: string,
    fixedInfoBoxIsOpen: boolean,
    handleClusteredClicked: (
      markers: $ReadOnlyArray<string>,
      position: { lat: number, lng: number },
    ) => void,
    infoBoxOptions?: {
      infoBoxView?: React.ComponentType<any>,
      infoBoxOffset?: number,
      customProp?: InfoBoxOptionsType,
    },
  },
  {
    zoomOnClick: boolean,
  },
> {
  state = {
    zoomOnClick: true,
  };

  handleClick = (markerClusterer: MarkerClusterer) => {
    const map = markerClusterer.getMap();
    const zoom = map.zoom;
    const markers = markerClusterer.getMarkers();

    const bounds = map.getBounds().toJSON();

    if (zoom > 16) {
      this.props.handleClusteredClicked(
        markers.map(marker => marker.keyId),
        {
          lat: bounds.north,
          lng: bounds.west,
        },
      );
      this.setState({ zoomOnClick: false });
    } else {
      this.setState({ zoomOnClick: true });
    }
  };

  render() {
    const clustered = this.props.clustered;
    // Know issues with the retina icons see: https://code.google.com/archive/p/google-maps-utility-library-v3/issues/335
    const clusterIconStyle = {
      url: this.props.fixedInfoBoxIsOpen ? markerClusterIconSelected : markerClusterIcon,
      height: 24,
      width: 24,
      textColor: '#ffffff',
      textSize: '14',
      fontFamily: 'Roboto',
      fontWeight: '500',
    };

    const markers = this.props.markers.map(marker => (
      <MarkerWrapper
        infoBoxOptions={{
          ...this.props.infoBoxOptions,
          isOpen: this.props.toggledBoxId === marker.keyId,
        }}
        handleOnToggle={this.props.handleOnToggle}
        openBoxMarkerId={this.props.toggledBoxId}
        key={marker.keyId}
        {...marker}
      />
    ));

    return clustered ? (
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
        styles={[clusterIconStyle]}
        onClick={this.handleClick}
        zoomOnClick={this.state.zoomOnClick}
      >
        {markers}
      </MarkerClusterer>
    ) : (
      <div>{markers}</div>
    );
  }
}
