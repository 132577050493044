/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import updateEdgeInConnection from 'graph/updaters/updateEdgeInConnection';

import {
  type updateEventBudgetCategoryMutationVariables,
  type updateEventBudgetCategoryMutationResponse,
} from './__generated__/updateEventBudgetCategoryMutation.graphql';

export type MutationInput = $PropertyType<updateEventBudgetCategoryMutationVariables, 'input'>;

export type OptimisticResponse = {|
  +updateEventBudgetCategory: $Shape<{
    ...$NonMaybeType<
      $PropertyType<updateEventBudgetCategoryMutationResponse, 'updateEventBudgetCategory'>,
    >,
  }>,
|};

const mutation = graphql`
  mutation updateEventBudgetCategoryMutation($input: UpdateEventBudgetCategoryInput!) {
    updateEventBudgetCategory(input: $input) {
      eventBudgetCategoryEdge {
        budgetedAmount
        node {
          id
        }
      }
      event {
        id
        totalBudgetedAmount
      }
    }
  }
`;

const updateEventBudgetCategory = (
  input: MutationInput,
  optimistic?: { eventTotalBudgetedAmount: number },
): Promise<updateEventBudgetCategoryMutationResponse> => {
  const optimisticResponse: OptimisticResponse = {
    updateEventBudgetCategory: {
      eventBudgetCategoryEdge: {
        budgetedAmount: input.budgetedAmount,
        node: {
          id: input.categoryId,
        },
      },
      event: optimistic && {
        id: input.eventId,
        totalBudgetedAmount: optimistic.eventTotalBudgetedAmount,
      },
    },
  };

  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
    optimisticResponse,
    updater: updateEdgeInConnection({
      parentId: input.eventId,
      edgeName: 'eventBudgetCategoryEdge',
      connections: [{ field: 'budgetCategories' }],
    }),
  });
};

export default updateEventBudgetCategory;
