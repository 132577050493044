/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import showErrorPopup from 'utils/showErrorPopup';

import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import NoResult from 'images/noResult.svg';
import NoRegistration from 'images/registration/noRegistration.svg';
import EmptyView from 'components/budget/EmptyView';
import Table, { type ColumnType } from 'components/budget/Table';
import TablePagination from 'components/budget/Table/TablePagination';
import { type ColumnConfiguration } from 'components/material/table';
import type { OpportunityCellPropsType } from 'components/Opportunities/OpportunitiesTableColumnSettings';

import type { OpportunitiesTablePagination_event } from './__generated__/OpportunitiesTablePagination_event.graphql';
import type { OpportunitiesTablePagination_org } from './__generated__/OpportunitiesTablePagination_org.graphql';
import type { OpportunitiesTablePagination_totalCountEvent } from './__generated__/OpportunitiesTablePagination_totalCountEvent.graphql';

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 39px;
  border: solid 1px #dadada;
  border-radius: 8px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

const EmptyText = styled.div`
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  color: #3e4859;
`;

type Props = {
  event: ?OpportunitiesTablePagination_event,
  org: ?OpportunitiesTablePagination_org,
  totalCountEvent: ?OpportunitiesTablePagination_totalCountEvent,
  relay: RelayPaginationProp,
  shownColumns: ColumnConfiguration,
  filtered: boolean,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  tz: string,
  includedColumns: { [string]: boolean },
};

type State = {
  refetching: boolean,
};

class OpportunitiesTablePagination extends React.Component<Props, State> {
  state = {
    refetching: false,
  };

  paginationDisposable: ?Disposable;

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  cellProps = (
    opportunity,
    _group,
    column?: ColumnType<any, any>,
  ): OpportunityCellPropsType<
    ?OpportunitiesTablePagination_org,
    {|
      +id: string,
      +$fragmentRefs?: any,
    |},
  > => {
    const { event, org, tz } = this.props;

    return {
      opportunity,
      customizable: opportunity,
      org,
      eventSlug: event ? event.slug : '',
      fieldSettings: column != null ? column.fieldSettings : null,
      readOnly: true,
      fromTab: 'event_contacts_opportunities',
      tz,
    };
  };

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(
      amount,
      err => {
        this.setState({ refetching: false });
        if (err) {
          showErrorPopup(err);
        }
      },
      // for the rows which have nil value by sort its puts nulls last and caching
      // so next pagination count doesn't work properly cause the rows with the nulls are cached
      { force: true },
    );
  };

  keyExtractor = (opportunity: {| +id: string, +$fragmentRefs?: any |}) => {
    return opportunity.id;
  };

  render() {
    const { event, shownColumns, filtered } = this.props;

    const optimisticOpportunitiesEdges = event ? event.opportunities.edges.filter(Boolean) : [];

    return (
      <>
        {(!event || optimisticOpportunitiesEdges.length !== 0) && (
          <Table
            data={event != null ? optimisticOpportunitiesEdges.map(({ node }) => node) : null}
            columns={shownColumns}
            sort={this.props.sort}
            onChangeSort={this.props.onChangeSort}
            cellProps={this.cellProps}
            keyExtractor={this.keyExtractor}
          />
        )}

        {event &&
          optimisticOpportunitiesEdges.length === 0 &&
          (filtered ? (
            <EmptyView message="No opportunities match filters" icon={<NoResult />} />
          ) : (
            <EmptyContainer>
              <NoRegistration />
              <EmptyText>No opportunities yet</EmptyText>
            </EmptyContainer>
          ))}
        {event && optimisticOpportunitiesEdges.length !== 0 && (
          <TablePagination
            onLoadMore={this.handleLoadMore}
            loading={this.state.refetching}
            currentCount={event ? optimisticOpportunitiesEdges.length : null}
            totalCount={
              this.props.totalCountEvent
                ? this.props.totalCountEvent.opportunitiesCount.totalCount
                : null
            }
            entityName="Opportunity"
          />
        )}
      </>
    );
  }
}

export default createPaginationContainer(
  OpportunitiesTablePagination,
  {
    event: graphql`
      fragment OpportunitiesTablePagination_event on Event {
        id
        slug
        opportunities(first: $count, after: $cursor, filters: $filters)
          @connection(key: "OpportunitiesTablePagination_opportunities", filters: []) {
          edges {
            node {
              id
              ...OpportunityAmount_opportunity @include(if: $includeAmount)
              ...OpportunityCompany_opportunity @include(if: $includeCompanyId)
              ...OpportunityStatus_opportunity @include(if: $includeStatus)
              ...OpportunityDescription_opportunity @include(if: $includeDescription)
              ...OpportunitySalesforceId_opportunity @include(if: $includeSalesforceId)
              ...OpportunityName_opportunity @include(if: $includeName)
              ...OpportunityOwner_opportunity @include(if: $includeOwnerId)
              ...OpportunityAssociatedContacts_opportunity @include(if: $includeAssociatedContacts)
              ...CustomizableText_customizable @include(if: $includeCustomizableText)
              ...CustomizableTextarea_customizable @include(if: $includeCustomizableTextarea)
              ...CustomizableLink_customizable @include(if: $includeCustomizableLink)
              ...CustomizableDate_customizable @include(if: $includeCustomizableDate)
              ...CustomizableBoolean_customizable @include(if: $includeCustomizableBoolean)
              ...CustomizableNumber_customizable @include(if: $includeCustomizableNumber)
              ...CustomizableCurrency_customizable @include(if: $includeCustomizableCurrency)
              ...CustomizableSelect_customizable @include(if: $includeCustomizableSelect)
              ...CustomizableMultiselect_customizable @include(if: $includeCustomizableMultiselect)
              ...CustomizableUserSelect_customizable @include(if: $includeCustomizableUserSelect)
              ...CustomizableUserMultiselect_customizable
                @include(if: $includeCustomizableUserMultiselect)
            }
          }
        }
      }
    `,
    org: graphql`
      fragment OpportunitiesTablePagination_org on Org {
        ...OpportunitySalesforceId_org
        ...OpportunityAmount_org @include(if: $includeAmount)
        ...CustomizableCurrency_org @include(if: $includeCustomizableCurrency)
      }
    `,
    totalCountEvent: graphql`
      fragment OpportunitiesTablePagination_totalCountEvent on Event {
        opportunitiesCount: opportunities(filters: $filters) {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.event && props.event.opportunities;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        ...props.includedColumns,
        eventSlug: props.event.slug,
        count,
        cursor,
      };
    },
    query: graphql`
      query OpportunitiesTablePaginationQuery(
        $count: Int!
        $cursor: String
        $eventSlug: String!
        $filters: OpportunityFilters!
        $includeAmount: Boolean!
        $includeCompanyId: Boolean!
        $includeStatus: Boolean!
        $includeDescription: Boolean!
        $includeSalesforceId: Boolean!
        $includeOwnerId: Boolean!
        $includeAssociatedContacts: Boolean!
        $includeName: Boolean!
        $includeCustomizableText: Boolean!
        $includeCustomizableTextarea: Boolean!
        $includeCustomizableLink: Boolean!
        $includeCustomizableDate: Boolean!
        $includeCustomizableBoolean: Boolean!
        $includeCustomizableNumber: Boolean!
        $includeCustomizableCurrency: Boolean!
        $includeCustomizableSelect: Boolean!
        $includeCustomizableMultiselect: Boolean!
        $includeCustomizableUserSelect: Boolean!
        $includeCustomizableUserMultiselect: Boolean!
      ) {
        event(slug: $eventSlug) {
          ...OpportunitiesTablePagination_event
        }
      }
    `,
  },
);
