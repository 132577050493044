/* @flow */

export default function downloadFile(
  fileContent: string | Blob,
  name: string,
  strMimeType: string,
) {
  const mimeType = strMimeType || 'application/octet-stream';
  const blob = new Blob([fileContent], { type: mimeType });
  if (window.navigator.msSaveBlob) {
    // IE hack
    window.navigator.msSaveBlob(blob, name);
  } else {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob, { type: mimeType });
    a.download = name;
    a.target = '_blank';
    if (document.body) {
      document.body.appendChild(a);
    }
    a.click();
    if (a.parentNode) {
      a.parentNode.removeChild(a);
    }
  }
}
