/* @flow */
import React from 'react';
import moment from 'moment-timezone';
import { graphql, createFragmentContainer } from 'react-relay';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { ContactLastSynced_contact } from './__generated__/ContactLastSynced_contact.graphql';

class ContactLastSynced extends React.PureComponent<{
  contact: ContactLastSynced_contact,
  tz: string,
}> {
  getLastSyncTime = () => {
    return this.props.contact.syncStatus.lastSuccessAt
      ? moment.tz(this.props.contact.syncStatus.lastSuccessAt, this.props.tz)
      : null;
  };

  render() {
    const syncTime = this.getLastSyncTime();
    if (syncTime == null) {
      return null;
    }

    return <DateTimeTooltip date={syncTime} placement="right" />;
  }
}

export default createFragmentContainer(
  ContactLastSynced,
  graphql`
    fragment ContactLastSynced_contact on Contact {
      syncStatus {
        lastSuccessAt
      }
    }
  `,
);
