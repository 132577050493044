/* @flow */
import moment from 'moment-timezone';

export const dateTimeFormat = 'MM/DD/YYYY h:mm a';

export const dateFormat = 'MM/DD/YYYY';

export default function formatDateTimeISO(date: moment, hideTime: ?boolean = false): string {
  return date.format(hideTime ? dateFormat : dateTimeFormat);
}
