/* @flow */
import './styles.styl';

import * as React from 'react';

// eslint-disable-next-line import/prefer-default-export
export class Container extends React.Component<{
  children: React.Node,
}> {
  render() {
    return <div className="site-content">{this.props.children}</div>;
  }
}
