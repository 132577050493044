/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/material/Button';
import TextField from 'components/material/TextField';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import type {
  FrequencyType,
  ScheduledOnType,
} from 'components/ScheduledReporting/ScheduledReportingForm';
import ScheduledReportingForm, {
  type SchedulerStateType,
} from 'components/ScheduledReporting/ScheduledReportingForm';
import { type User } from 'components/UserSelect';

import BudgetReportingSchedulerFields from './BudgetReportingSchedulerFields';
import { type SchedulerType } from './index';

const EmailFieldsContainer = styled.div`
  padding: 0 20px 0 5px;
`;

const WindowTitleContainer = styled.div`
  flex: 1 1 auto;
  text-align: center;
`;

const WindowSubTitle = styled.div`
  margin-top: 9px;
  font-size: 13px;
  color: #4a5665;
`;

const StyledWindowContent = styled(WindowContent)`
  padding: 0 23px 20px 34px;
`;

const StyledWindowHeader = styled(WindowHeader)`
  padding: 20px 30px 14px;
  @media (${props => props.theme.mobileOnly}) {
    height: auto;
  }
`;

const StyledWindowTitle = styled(WindowTitle)`
  font-size: 14px;
`;

const TextFieldWrapper = styled.div`
  padding: 0 16px 0 5px;
`;

const StyledTextField = styled(TextField)`
  input {
    padding: 7px 14px 6px 14px;
    border: solid 1px #c3e0ec;
    border-radius: 4px;
    font-size: 13px;
    transition: border 0.3s;
    &:hover {
      border: solid 1px #c3e0ec;
    }
    &:focus {
      border-color: #3ba9da;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 49px;
`;

const CancelButton = styled(Button)`
  padding: 4px 16px 4px 0;
  font-weight: 400;
  color: #828b93;
`;

const SaveButton = styled(Button)`
  margin-left: auto;
  padding: 4px 22px;
`;

const SaveNewButton = styled(Button)`
  padding: 4px 22px;
  ${props =>
    props.hasSavedView
      ? css`
          margin-left: 15px;
        `
      : css`
          margin-left: auto;
        `};
`;

export default class SaveViewWindow extends React.PureComponent<
  {
    savedViewId: ?string,
    savedViewName: string,
    onHide: () => void,
    onSave: (name: string, scheduler: ?SchedulerType, savedViewId: string) => void,
    onSaveNew: (name: string, scheduler: ?SchedulerType) => void,
    viewerCanUpdate?: ?boolean,
  },
  {
    name: string,
    scheduler: ?SchedulerStateType,
    budgetReportingSchedulerFields: ?{ +includePayments: boolean, +includeExpenses: boolean },
    errors: $ReadOnlyArray<string>,
  },
> {
  state = {
    name: this.props.savedViewName,
    scheduler: null,
    budgetReportingSchedulerFields: null,
    errors: [],
  };

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  handleNameChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    if (this.state.errors.includes('name') && name.trim()) {
      this.setState(prevState => ({
        errors: prevState.errors.filter(error => error !== 'name'),
      }));
    }
    this.setState({ name });
  };

  handleFrequencyChange = (frequency: ?FrequencyType | 'never') => {
    if (this.state.scheduler && this.state.scheduler.frequency === frequency) return;

    if (frequency === 'never' || !frequency) {
      this.setState({ scheduler: null, budgetReportingSchedulerFields: null });

      if (this.state.errors.includes('recipients')) {
        this.setState(prevState => ({
          errors: prevState.errors.filter(error => error !== 'recipients'),
        }));
      }

      return;
    }

    const scheduledOnDefaultValue = frequency === 'monthly' ? 'start_of_month' : 'Monday';

    this.setState(previousState => ({
      scheduler: {
        ...previousState.scheduler,
        frequency: ((frequency: any): FrequencyType),
        scheduledOn: scheduledOnDefaultValue,
        recipients: previousState.scheduler ? previousState.scheduler.recipients : [],
      },
      budgetReportingSchedulerFields: {
        includeExpenses: previousState.budgetReportingSchedulerFields
          ? previousState.budgetReportingSchedulerFields.includeExpenses
          : false,
        includePayments: previousState.budgetReportingSchedulerFields
          ? previousState.budgetReportingSchedulerFields.includePayments
          : false,
      },
    }));
  };

  handleScheduledOnChange = (scheduledOn: ?ScheduledOnType) => {
    if (this.state.scheduler && this.state.scheduler.scheduledOn === scheduledOn) return;

    this.setState(previousState => ({
      scheduler: {
        ...previousState.scheduler,
        scheduledOn: ((scheduledOn: any): ScheduledOnType),
      },
    }));
  };

  handleChangeUserMultiSelectFilterValue = (users: $ReadOnlyArray<User>) => {
    if (this.state.errors.includes('recipients') && users.length > 0) {
      this.setState(prevState => ({
        errors: prevState.errors.filter(error => error !== 'recipients'),
      }));
    }

    this.setState(previousState => ({
      scheduler: {
        ...previousState.scheduler,
        recipients: users,
      },
    }));
  };

  handleIncludeExpensesChange = () => {
    this.setState(previousState => ({
      budgetReportingSchedulerFields: {
        ...previousState.budgetReportingSchedulerFields,
        includeExpenses: previousState.budgetReportingSchedulerFields
          ? !previousState.budgetReportingSchedulerFields.includeExpenses
          : true,
      },
    }));
  };

  handleIncludePaymentsChange = () => {
    this.setState(previousState => ({
      budgetReportingSchedulerFields: {
        ...previousState.budgetReportingSchedulerFields,
        includePayments: previousState.budgetReportingSchedulerFields
          ? !previousState.budgetReportingSchedulerFields.includePayments
          : true,
      },
    }));
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      this.state.name &&
      !this.props.savedViewId &&
      !this.props.viewerCanUpdate
    ) {
      this.handleSaveNew();
    }
  };

  handleOverwrite = () => {
    const { scheduler, name, budgetReportingSchedulerFields } = this.state;

    const error = this.handleValidate();
    if (!error) {
      const newScheduler =
        scheduler && budgetReportingSchedulerFields
          ? {
              frequency: scheduler.frequency,
              scheduledOn: scheduler.scheduledOn,
              includeExpenses: budgetReportingSchedulerFields.includeExpenses,
              includePayments: budgetReportingSchedulerFields.includePayments,
              recipientIds: scheduler.recipients.map(({ id }) => id),
            }
          : null;

      if (this.props.savedViewId) {
        this.props.onSave(name, newScheduler, this.props.savedViewId);
      }
    }
  };

  handleSaveNew = () => {
    const { scheduler, name, budgetReportingSchedulerFields } = this.state;

    const error = this.handleValidate();
    if (!error) {
      const newScheduler =
        scheduler && budgetReportingSchedulerFields
          ? {
              frequency: scheduler.frequency,
              scheduledOn: scheduler.scheduledOn,
              includeExpenses: budgetReportingSchedulerFields.includeExpenses,
              includePayments: budgetReportingSchedulerFields.includePayments,
              recipientIds: scheduler.recipients.map(({ id }) => id),
            }
          : null;

      this.props.onSaveNew(name, newScheduler);
    }
  };

  handleValidate = (): boolean => {
    const { scheduler, name, errors } = this.state;

    if (!name) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'name'] }));
    }

    if (scheduler && scheduler.recipients && scheduler.recipients.length === 0) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'recipients'] }));
    }

    return (
      errors.length !== 0 ||
      (scheduler && scheduler.recipients && scheduler.recipients.length === 0) ||
      !name
    );
  };

  render() {
    return (
      <Window size={436} onHide={this.props.onHide}>
        <StyledWindowHeader>
          <WindowTitleContainer>
            <StyledWindowTitle>Save view</StyledWindowTitle>
            <WindowSubTitle>Save this view with the current grouping and filters</WindowSubTitle>
          </WindowTitleContainer>
          <WindowClose onClick={this.props.onHide} />
        </StyledWindowHeader>
        <StyledWindowContent>
          <TextFieldWrapper>
            <StyledTextField
              placeholder="Saved View Name"
              value={this.state.name}
              onChange={this.handleNameChange}
              onKeyDown={this.handleKeyDown}
              error={this.state.errors.includes('name') ? 'Required' : ''}
              autoFocus
            />
          </TextFieldWrapper>
          <EmailFieldsContainer>
            <ScheduledReportingForm
              scheduler={this.state.scheduler}
              errors={this.state.errors}
              handleFrequencyChange={this.handleFrequencyChange}
              handleScheduledOnChange={this.handleScheduledOnChange}
              handleChangeUserMultiSelectFilterValue={this.handleChangeUserMultiSelectFilterValue}
              hasTeamAccess
            />
            <BudgetReportingSchedulerFields
              budgetReportingSchedulerFields={this.state.budgetReportingSchedulerFields}
              handleIncludeExpensesChange={this.handleIncludeExpensesChange}
              handleIncludePaymentsChange={this.handleIncludePaymentsChange}
            />
          </EmailFieldsContainer>
          <ActionContainer>
            <CancelButton label="Cancel" minimal onClick={this.props.onHide} />
            {this.props.savedViewId && this.props.viewerCanUpdate && (
              <SaveButton primary label="Overwrite" onClick={this.handleOverwrite} />
            )}
            <SaveNewButton
              primary
              label="Save as new"
              onClick={this.handleSaveNew}
              hasSavedView={this.props.savedViewId != null}
            />
          </ActionContainer>
        </StyledWindowContent>
      </Window>
    );
  }
}
