/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationFormSubmissionContent_org$ref = any;
type RegistrationResults_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExistingRegistrationForm_org$ref: FragmentReference;
export type ExistingRegistrationForm_org = {|
  +id: string,
  +slug: string,
  +settings: {|
    +subdomain: ?string
  |},
  +registeredOption: {|
    +id: string
  |},
  +$fragmentRefs: RegistrationFormSubmissionContent_org$ref & RegistrationResults_org$ref,
  +$refType: ExistingRegistrationForm_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ExistingRegistrationForm_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "subdomain",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "registeredOption",
      "name": "fieldDefaultOption",
      "storageKey": "fieldDefaultOption(customizableType:\"EVENTCONTACT\",fieldName:\"registration_status_id\",optionName:\"Registered\")",
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": "EVENTCONTACT",
          "type": "CustomizableType!"
        },
        {
          "kind": "Literal",
          "name": "fieldName",
          "value": "registration_status_id",
          "type": "String!"
        },
        {
          "kind": "Literal",
          "name": "optionName",
          "value": "Registered",
          "type": "String!"
        }
      ],
      "concreteType": "CustomFieldOptionType",
      "plural": false,
      "selections": [
        v0
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationFormSubmissionContent_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RegistrationResults_org",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f0c4f96478a83f872a1efa06eba601a';
module.exports = node;
