/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import ResizeIcon from 'images/resizeIcon.svg';

const Container = styled.div`
  display: flex;
  width: 194px;
  height: 40px;
  margin-top: 10px;
  margin-left: 1px;
  padding: 8px 10px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
`;

const StyledTextField = styled.input`
  display: block;
  flex: 1 1 auto;
  width: 66px;
  height: 24px;
  margin-right: 16px;
  padding: 3px 10px;
  color: ${props => props.theme.darkestTextColor};
  font-size: 13px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 22px;
  height: 22px;
  flex: 1 1 auto;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  cursor: pointer;
  > svg {
    transform: scale(${props => props.iconScale});
  }
  ${props =>
    props.active &&
    css`
      box-shadow: none;
      border: 1px solid ${props.theme.primaryActionColor};
      svg rect {
        fill: ${props.theme.primaryActionColor};
      }
    `};
`;

const icons = {
  small: {
    size: 200,
    scale: 0.5,
  },
  medium: {
    size: 350,
    scale: 0.75,
  },
  large: {
    size: 500,
    scale: 1,
  },
};

export default class ResizeToolBar extends React.Component<
  {
    initialWidth: number,
    onSetWidth: number => void,
  },
  {
    width: string,
  },
> {
  state = { width: `${this.props.initialWidth}px` };

  isNumber = (num: string): boolean => {
    return /^\d+$/.test(num);
  };

  handleWidthChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const width = e.currentTarget.value.replace(/px/gi, '').trim();
    if (this.isNumber(width)) {
      this.setState({ width: e.currentTarget.value });
    }
  };

  handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const width = this.state.width.replace(/px/gi, '').trim();
    const limitedWidth = Math.min(+width, 900);
    this.handleWidthSet(limitedWidth);
  };

  handleWidthSet = (width: number) => {
    this.props.onSetWidth(width);
    this.setState({ width: `${width}px` });
  };

  handleInputClick = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  render() {
    return (
      <Container>
        <form onSubmit={this.handleSubmit}>
          <StyledTextField
            contentEditable
            name="width"
            onChange={this.handleWidthChange}
            value={this.state.width}
            onClick={this.handleInputClick}
          />
        </form>
        {Object.keys(icons).map(icon => {
          const currentIcon = icons[icon];
          const isActive = +this.state.width.replace(/px/gi, '').trim() === currentIcon.size;
          return (
            <Button
              key={icon}
              iconScale={currentIcon.scale}
              onClick={() => this.handleWidthSet(currentIcon.size)}
              active={isActive}
            >
              <ResizeIcon />
            </Button>
          );
        })}
      </Container>
    );
  }
}
