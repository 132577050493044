/**
 * @flow
 * @relayHash 382e27aa718668dc11ba2998d92685db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateEventBudgetCategoryInput = {
  clientMutationId?: ?string,
  categoryId: string,
  eventId: string,
  budgetedAmount: number,
};
export type updateEventBudgetCategoryMutationVariables = {|
  input: UpdateEventBudgetCategoryInput
|};
export type updateEventBudgetCategoryMutationResponse = {|
  +updateEventBudgetCategory: ?{|
    +eventBudgetCategoryEdge: {|
      +budgetedAmount: number,
      +node: {|
        +id: string
      |},
    |},
    +event: {|
      +id: string,
      +totalBudgetedAmount: number,
    |},
  |}
|};
export type updateEventBudgetCategoryMutation = {|
  variables: updateEventBudgetCategoryMutationVariables,
  response: updateEventBudgetCategoryMutationResponse,
|};
*/


/*
mutation updateEventBudgetCategoryMutation(
  $input: UpdateEventBudgetCategoryInput!
) {
  updateEventBudgetCategory(input: $input) {
    eventBudgetCategoryEdge {
      budgetedAmount
      node {
        id
      }
    }
    event {
      id
      totalBudgetedAmount
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEventBudgetCategoryInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateEventBudgetCategory",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateEventBudgetCategoryInput!"
      }
    ],
    "concreteType": "UpdateEventBudgetCategoryPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventBudgetCategoryEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "EventBudgetCategoryEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "budgetedAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetCategory",
            "plural": false,
            "selections": [
              v1
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalBudgetedAmount",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateEventBudgetCategoryMutation",
  "id": null,
  "text": "mutation updateEventBudgetCategoryMutation(\n  $input: UpdateEventBudgetCategoryInput!\n) {\n  updateEventBudgetCategory(input: $input) {\n    eventBudgetCategoryEdge {\n      budgetedAmount\n      node {\n        id\n      }\n    }\n    event {\n      id\n      totalBudgetedAmount\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateEventBudgetCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "updateEventBudgetCategoryMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a862c273022554eec8438f98d3bf1d91';
module.exports = node;
