/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, type Match, type RouterHistory, Route } from 'react-router-dom';
import styled, { css } from 'styled-components';
import sortBy from 'lodash/sortBy';
import qs from 'qs';

import fonts from 'config/fonts';
import personalizationDefaults from 'config/personalizationDefaults';

import FeatureAccessContext from 'contexts/FeatureAccess';

import { isBright } from 'utils/color';
import normalizeCustomFields from 'utils/customization/normalizeCustomFields';
import type { FieldType } from 'utils/customization/types';
import { getFontFamily, loadFonts } from 'utils/fonts';

import getEventFields, { ColsGroups } from 'components/AllEvents/lib/getEventFields';
import parseAllEventsFilters from 'components/AllEvents/lib/parseAllEventsFilters';

import AllEventsCalendarSharePageContainer from './AllEventsCalendarSharePageContainer';
import AllEventsListSharePageContainer from './AllEventsListSharePageContainer';
import AllEventsMapSharePageContainer from './AllEventsMapSharePageContainer';

import { type AllEventsShareContainer_eventList } from './__generated__/AllEventsShareContainer_eventList.graphql';
import { type AllEventsShareContainer_org } from './__generated__/AllEventsShareContainer_org.graphql';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background-color: ${props => props.bgColor};
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
`;

const Header = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  margin: 10px 20px 15px;
`;

const ListNameWrapperContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-self: center;
  align-items: center;
  margin-top: 10px;
  ${props =>
    props.center &&
    css`
      justify-content: center;
    `};
`;

const ListNameWrapper = styled.div`
  align-self: center;
  font-family: ${props => props.font};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  color: ${props => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Logo = styled.img`
  flex: 1 1 auto;
  align-self: center;
  max-width: 150px;
  max-height: 75px;
  margin-top: 10px;
  margin-right: 20px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin: 0 20px 20px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #dfe1e5;
  border-radius: 6px;
  background-color: #ffffff;
`;

export type SharedEventListType = {
  id: string,
  name: string,
  token: string,
  linkEventNameToBrief?: ?boolean,
  listType?: ?string,
  beforeDate?: ?string,
  logo: ?string,
  font: ?string,
  titleColor: ?string,
  backgroundColor: ?string,
  backgroundUrl: ?string,
  shownColumns: $ReadOnlyArray<string>,
  actionsRequestForms: $ReadOnlyArray<{ name: string, shareLink: string }>,
  sort?: ?string,
  direction?: ?string,
};

class AllEventsShareContainer extends React.Component<{
  className?: string,
  eventList: ?AllEventsShareContainer_eventList,
  org: AllEventsShareContainer_org,
  userEmail: ?string,
  history: RouterHistory,
  location: Location,
  match: Match,
  userTimezone: string,
  isPreviewing?: boolean,
}> {
  eventFields: $ReadOnlyArray<FieldType> = getEventFields(
    normalizeCustomFields(this.props.org.customFields.edges.map(({ node }) => node)),
    this.props.org,
    this.context.legacyFeatures,
  );

  componentDidMount() {
    const { org, isPreviewing } = this.props;
    if (isPreviewing) {
      loadFonts(fonts.map(font => font.value));
      return;
    }

    const finalFont =
      this.buildEventList().font || org.settings.font || personalizationDefaults.font;
    loadFonts([finalFont]);
  }

  titleColor = () => {
    const { org } = this.props;
    const eventList = this.buildEventList();

    if (eventList.titleColor) {
      return eventList.titleColor;
    }

    if (isBright(eventList.backgroundColor || org.settings.primaryColor)) {
      return '#000';
    }

    return '#fff';
  };

  getShownColumns = (
    shownColumnsHash: $ReadOnlyArray<string> | { [number]: string },
  ): $ReadOnlyArray<string> => {
    const eventList = this.props.eventList;

    if (eventList) {
      return eventList.savedListColumns.edges.map(({ node }) =>
        node.customField ? node.customField.id : node.columnName || '',
      );
    }

    // If hash has more then 21 values of current type (shownColumns) qs return
    // { [number]: string } object instead of array string
    return Array.isArray(shownColumnsHash)
      ? shownColumnsHash
      : Object.keys(shownColumnsHash).map(key => shownColumnsHash[Number(key)]);
  };

  getSortFieldName = () => {
    const { eventList } = this.props;
    if (!eventList) {
      return null;
    }

    if (eventList.customSort) {
      return eventList.customSort.id;
    }

    return eventList.sort;
  };

  buildEventList = (): SharedEventListType => {
    const { eventList, location } = this.props;
    const hash = qs.parse(location.hash.slice(1));

    return {
      id: eventList ? eventList.id : hash.id,
      name: eventList ? eventList.name : hash.name,
      token: eventList ? eventList.token : '',
      logo: eventList ? eventList.logo : hash.logo,
      font: eventList ? eventList.font : hash.font,
      sort: this.getSortFieldName(),
      direction: eventList ? eventList.direction : null,
      titleColor: eventList ? eventList.titleColor : hash.titleColor,
      backgroundColor: eventList ? eventList.backgroundColor : hash.backgroundColor,
      backgroundUrl: eventList ? eventList.backgroundUrl : hash.backgroundUrl,
      linkEventNameToBrief: eventList
        ? eventList.linkEventNameToBrief
        : hash.linkEventNameToBrief === 'true',
      shownColumns: this.getShownColumns(hash.shownColumns),
      actionsRequestForms: eventList
        ? eventList.actionsRequestForms.edges.map(({ node }) => ({
            name: node.name,
            shareLink: node.shareLink || '',
          }))
        : hash.actionsRequestForms || [],
    };
  };

  static contextType = FeatureAccessContext;

  render() {
    const { org, userEmail, location, match } = this.props;
    const filters = parseAllEventsFilters(
      location.search,
      org.settings.fiscalYearStart,
      this.eventFields,
    );

    const customFieldSections = sortBy(org.customFieldSections.edges, edge => edge.node.order).map(
      ({ node }) => ({
        label: node.name,
        id: node.id,
      }),
    );
    const groups = ColsGroups(
      {
        salesforceEnabled: !!org.salesforceAccount,
        marketoEnabled: !!org.marketoAccount,
        customFieldSections,
      },
      this.context.legacyFeatures,
    );
    const eventList = this.buildEventList();
    const font = getFontFamily(eventList.font || org.settings.font || personalizationDefaults.font);

    return (
      <Container
        className={this.props.className}
        bgImage={eventList.backgroundUrl}
        bgColor={eventList.backgroundColor || org.settings.primaryColor}
      >
        <Header>
          {eventList.logo && (
            <LogoWrapper>
              <Logo src={eventList.logo} />
            </LogoWrapper>
          )}
          <ListNameWrapperContainer center={!eventList.logo}>
            <ListNameWrapper font={font} color={this.titleColor()}>
              {eventList.name}
            </ListNameWrapper>
          </ListNameWrapperContainer>
        </Header>
        <Content>
          <Route
            path={match.path}
            exact
            render={props => (
              <AllEventsListSharePageContainer
                {...props}
                eventFields={this.eventFields}
                groups={groups}
                eventList={eventList}
                org={org}
                userEmail={userEmail}
                filters={filters}
                pathPrefix={match.url}
              />
            )}
          />
          <Route
            path={`${match.path}/map`}
            exact
            render={props => (
              <AllEventsMapSharePageContainer
                {...props}
                eventFields={this.eventFields}
                groups={groups}
                filters={filters}
                eventList={eventList}
                org={org}
                userEmail={userEmail}
                pathPrefix={match.url}
              />
            )}
          />
          <Route
            path={`${match.path}/calendar`}
            exact
            render={props => (
              <AllEventsCalendarSharePageContainer
                {...props}
                eventFields={this.eventFields}
                groups={groups}
                filters={filters}
                eventList={eventList}
                org={org}
                userEmail={userEmail}
                pathPrefix={match.url}
              />
            )}
          />
        </Content>
      </Container>
    );
  }
}

export default createFragmentContainer(AllEventsShareContainer, {
  eventList: graphql`
    fragment AllEventsShareContainer_eventList on EventList {
      id
      name
      token
      linkEventNameToBrief
      logo
      font
      titleColor
      backgroundColor
      backgroundUrl
      listType
      beforeDate
      sort
      customSort {
        id
      }
      direction
      savedListColumns {
        edges {
          node {
            columnName
            customField {
              id
            }
          }
        }
      }
      actionsRequestForms {
        edges {
          node {
            name
            shareLink
          }
        }
      }
    }
  `,
  org: graphql`
    fragment AllEventsShareContainer_org on Org {
      settings {
        font
        primaryColor
        fiscalYearStart
      }
      salesforceAccount {
        id
      }
      marketoAccount {
        id
      }
      customFieldSections(sectionCustomizableType: [EVENT]) {
        edges {
          node {
            id
            name
            order
          }
        }
      }
      customFields(customizableType: [EVENT]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            required
            restrictChangingValue: mappedToSalesforce(pull: true)
            section {
              id
              order
            }
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      ...AllEventsListShareHeaderBar_org
    }
  `,
});
