/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import FiscalYearPanel from './FiscalYearPanel';
import QuarterPanel from './QuarterPanel';
import TeamBudgeting from './TeamBudgeting';

import { type BudgetSetup_org } from './__generated__/BudgetSetup_org.graphql';
import { type BudgetSetup_user } from './__generated__/BudgetSetup_user.graphql';

class BudgetSetup extends React.Component<{
  period: string,
  org: BudgetSetup_org,
  user: BudgetSetup_user,
}> {
  render() {
    const {
      org,
      org: { budgetPeriods },
      user: { teams },
      period,
    } = this.props;
    const periodsInfo = budgetPeriods.edges.map(({ node }) => node);
    // In backend period like "Q1 2019" in url "Q1_2019"
    const currentPeriod = periodsInfo.find(node => node.period === period.replace('_', ' '));
    return (
      <React.Fragment>
        <FiscalYearPanel selectedPeriod={period} org={org} />
        <QuarterPanel year={parseInt(period.split('_')[1], 10)} />
        <TeamBudgeting
          budgetPeriod={currentPeriod || null}
          org={org}
          period={period}
          teams={teams.edges.map(edge => edge.node)}
        />
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(BudgetSetup, {
  org: graphql`
    fragment BudgetSetup_org on Org {
      budgetPeriods {
        edges {
          node {
            id
            period
            year
            ...TeamBudgeting_budgetPeriod
          }
        }
      }
      ...FiscalYearPanel_org
      ...TeamBudgeting_org
    }
  `,
  user: graphql`
    fragment BudgetSetup_user on User {
      teams {
        edges {
          node {
            ...TeamBudgeting_teams
          }
        }
      }
    }
  `,
});
