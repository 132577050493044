/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';

import formatCost from 'utils/number/formatCost';

import { type CellPropsType } from 'components/budget/Table';
import InlineCostField from 'components/budget/Table/InlineCostField';
import InlineEditableCell from 'components/budget/Table/InlineEditableCell';

import { type VendorExpenseCellPropsType } from './index';

import { type VendorExpenseAmount_expense } from './__generated__/VendorExpenseAmount_expense.graphql';
import { type VendorExpenseAmount_org } from './__generated__/VendorExpenseAmount_org.graphql';

const VendorExpenseAmount = ({
  expense,
  org,
  updateExpense,
  updateColumnWidth,
}: CellPropsType<
  VendorExpenseCellPropsType<VendorExpenseAmount_expense, VendorExpenseAmount_org>,
>) => {
  if (!expense.viewerCanUpdateAmount) {
    return (
      <InlineEditableCell>
        {({ editing }) => {
          if (editing) {
            return (
              <Redirect
                push
                to={{
                  pathname: `/events/${expense.event.slug}/budget/${expense.id}`,
                  hash: 'payments',
                }}
              />
            );
          }

          return formatCost(expense.actualAmount, org.settings.currency);
        }}
      </InlineEditableCell>
    );
  }

  return (
    <InlineCostField
      value={expense.actualAmount}
      currency={org.settings.currency}
      onChange={actualAmount => {
        updateExpense({
          actualAmount: actualAmount == null ? expense.actualAmount : actualAmount,
        });

        updateColumnWidth();
      }}
    />
  );
};

export default createFragmentContainer(
  VendorExpenseAmount,
  graphql`
    fragment VendorExpenseAmount_expense on Expense {
      id
      actualAmount
      viewerCanUpdateAmount
      event {
        slug
      }
    }

    fragment VendorExpenseAmount_org on Org {
      settings {
        currency
      }
    }
  `,
);
