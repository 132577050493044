import './styles.styl';
import './panel.styl';

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import AttachmentItem from './AttachmentItem';
import AttachmentPreview from './AttachmentPreview';
import AttachmentsFolder from './AttachmentsFolder';

export default class Attachments extends React.Component {
  static propTypes = {
    disableDelete: PropTypes.bool,
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        deliverable: PropTypes.shape({
          folder: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
        expense: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      }),
    ).isRequired,
    event_id: PropTypes.number.isRequired,
    event_relay_id: PropTypes.string,
    event_slug: PropTypes.string,
    className: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    briefAttachmentIds: PropTypes.array,
    canUpdateBrief: PropTypes.bool,
  };

  state = {
    preview: null,
  };

  componentDidMount() {
    const attachmentMatch = window.location.hash.match(/attachment-([0-9]+)/);
    if (attachmentMatch) {
      const attachmentId = +attachmentMatch[1];
      const attachment = this.props.attachments.find(x => x.id === attachmentId);
      if (attachment) {
        this.state.preview = attachmentId;
      }
    }
  }

  showPreview = attachment => {
    this.setState({ preview: attachment && attachment.id });

    if (attachment) {
      window.location.hash = `attachment-${attachment.id}`;
    } else {
      window.location.hash = '';
    }
  };

  renderAttachments = attachments => {
    const { onRemove, event_id, event_slug, event_relay_id } = this.props;
    return attachments.map(attachment => {
      return (
        attachment &&
        attachment.fileurl && (
          <AttachmentItem
            attachment={attachment}
            key={attachment.id}
            onRemove={() => onRemove(attachment)}
            onPreview={() => this.showPreview(attachment)}
            event_id={event_id}
            event_relay_id={event_relay_id}
            event_slug={event_slug}
            disableDelete={this.props.disableDelete}
            canUpdateBrief={this.props.canUpdateBrief}
            includedInBrief={
              this.props.briefAttachmentIds && this.props.briefAttachmentIds.includes(attachment.id)
            }
          />
        )
      );
    });
  };

  render() {
    const { attachments, className, onRemove, event_id, event_relay_id } = this.props;
    const deliverableAttachments = attachments.filter(a => a.deliverable);
    const expenseAttachments = attachments.filter(a => a.expense || a.payment);
    const eventNoteAttachments = attachments.filter(a => a.eventNote);
    const eventFloorPlanAttachments = attachments.filter(a => a.eventFloorPlan);
    const pureAttachments = attachments.filter(
      a =>
        !a.deliverable &&
        !a.attendee &&
        !a.expense &&
        !a.payment &&
        !a.eventNote &&
        !a.eventFloorPlan,
    );
    const folders = sortBy(
      deliverableAttachments.reduce((arr, at) => {
        if (at.deliverable.folder && arr.every(f => f.id !== at.deliverable.folder.id)) {
          return [...arr, at.deliverable.folder];
        }
        return arr;
      }, []),
      f => f.name.toLowerCase(),
    );
    const eventNotes = uniqBy(
      eventNoteAttachments.filter(({ id }) => id).map(({ eventNote }) => eventNote),
      'id',
    );

    const foldersAttachments = folders.reduce((obj, f) => {
      const folderAttachments = deliverableAttachments.filter(
        at => at.deliverable.folder && at.deliverable.folder.id === f.id,
      );
      Object.assign(obj, { [f.id]: folderAttachments });
      return obj;
    }, {});
    const rootDeliverableAttachments = deliverableAttachments.filter(at => !at.deliverable.folder);

    const previewingAttachments = [
      ...folders.reduce((arr, f) => [...arr, ...foldersAttachments[f.id]], []),
      ...rootDeliverableAttachments,
      ...pureAttachments,
      ...expenseAttachments,
      ...eventNoteAttachments,
      ...eventFloorPlanAttachments,
    ].filter(x => !x.loading && !(x.filetype || '').startsWith('link/'));
    return (
      <div className="attachments-list-container">
        <div className={classNames('attachments-list', className)}>
          {folders.map(folder => {
            return (
              <AttachmentsFolder key={folder.id} folder={folder}>
                {this.renderAttachments(foldersAttachments[folder.id])}
              </AttachmentsFolder>
            );
          })}
          {expenseAttachments.length > 0 && (
            <AttachmentsFolder folder={{ name: 'Budget' }}>
              {this.renderAttachments(expenseAttachments)}
            </AttachmentsFolder>
          )}
          {eventNotes.length > 0 && (
            <AttachmentsFolder folder={{ name: 'Notes' }}>
              {eventNotes.map(eventNote => (
                <AttachmentsFolder key={eventNote.id} folder={{ name: eventNote.name }}>
                  {this.renderAttachments(eventNote.attachments.edges.map(({ node }) => node))}
                </AttachmentsFolder>
              ))}
            </AttachmentsFolder>
          )}
          {eventFloorPlanAttachments.length > 0 &&
            eventFloorPlanAttachments[eventFloorPlanAttachments.length - 1].fileurl && (
              <AttachmentsFolder folder={{ name: 'Floor Plan' }}>
                {this.renderAttachments(eventFloorPlanAttachments)}
              </AttachmentsFolder>
            )}
          {this.renderAttachments(rootDeliverableAttachments)}
          {this.renderAttachments(pureAttachments)}
        </div>

        <AttachmentPreview
          attachments={previewingAttachments}
          current={this.state.preview}
          onSet={this.showPreview}
          onRemove={onRemove}
          event_id={event_id}
          event_relay_id={event_relay_id}
          disableDelete={this.props.disableDelete}
          canUpdateBrief={this.props.canUpdateBrief}
          includedInBrief={
            this.props.briefAttachmentIds &&
            this.props.briefAttachmentIds.includes(this.state.preview)
          }
        />
      </div>
    );
  }
}
