/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import templates from 'config/event_templates.json';
import eventTemplateOptions from 'config/eventTemplateOptions';

import Button from 'components/material/Button';
import CheckBox from 'components/material/CheckBox';
import SelectField from 'components/material/SelectField';
import TemplateSearch, { type TemplateType } from 'components/TemplateSearch';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelectField = styled(SelectField)`
  margin-bottom: 10px;
`;

const TemplateSearchContainer = styled.div`
  margin-bottom: 10px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
`;

const Label = styled.div`
  font-weight: 500;
  margin-left: 10px;
  font-size: 13px;
  color: ${props => props.theme.rowSecondaryTextColor};
  cursor: default;
`;

const LabelWarning = styled.div`
  font-size: 11px;
  font-weight: 400;
  font-style: italic;
  color: ${props => props.theme.mutedTextColor};
  margin-top: 3px;
  line-height: 1.3;
`;

export default class ImportOverlay extends React.PureComponent<
  {
    hasStartDate: boolean,
    onImport: ({
      sourceEvent: number,
      copyDueDates: boolean,
      folders: Array<number>,
    }) => Promise<any>,
    onHide: () => void,
  },
  {
    templateType: ?number,
    templateId: ?number,
    previousEvent: ?TemplateType,
    templateFolder: number,
    copyDueDates: boolean,
    loading: boolean,
  },
> {
  state = {
    templateType: null,
    templateId: null,
    previousEvent: null,
    templateFolder: 0,
    copyDueDates: false,
    loading: false,
  };

  handleTypeChange = (templateType: ?number) => {
    this.setState({
      templateType,
      templateId: null,
      previousEvent: null,
      templateFolder: 0,
      copyDueDates: false,
    });
  };

  handleTemplateEventChange = (templateId: ?number) => {
    this.setState({ templateId, templateFolder: 0 });
  };

  handlePreviousEventChange = (template: ?TemplateType) => {
    this.setState({
      templateId: template && template.dbId,
      previousEvent: template,
      copyDueDates: false,
    });
  };

  handleTemplateFolderChange = (templateFolder: ?number) => {
    this.setState({ templateFolder: +templateFolder, copyDueDates: false });
  };

  handleToggleDueDateCheckbox = () => {
    this.setState(state => ({ copyDueDates: !state.copyDueDates }));
  };

  handleImportClick = () => {
    if (this.state.loading) return;

    this.setState({ loading: true });

    const folders: Array<number> =
      this.state.templateFolder != null && this.state.templateFolder !== 0
        ? [this.state.templateFolder]
        : [];

    this.props
      .onImport({
        sourceEvent: +this.state.templateId,
        copyDueDates: this.state.copyDueDates,
        folders,
      })
      .then(() => this.props.onHide());
  };

  render() {
    const {
      previousEvent,
      templateId,
      templateType,
      templateFolder,
      copyDueDates,
      loading,
    } = this.state;

    const foldersOptions = previousEvent
      ? [
          { value: 0, label: 'All Tasks & Sections' },
          ...sortBy(
            previousEvent.folders.edges
              .map(edge => edge.node)
              .map(folder => ({ value: folder.dbId, label: folder.name })),
            option => option.label.toLowerCase(),
          ),
        ]
      : null;
    const noop = () => {};
    const dueDateEnabled = !!(previousEvent && previousEvent.startDate && this.props.hasStartDate);

    const buttonEnabled = templateId != null;

    return (
      <Container>
        <StyledSelectField
          options={eventTemplateOptions}
          placeholder="Select Template Type"
          value={templateType}
          onChange={this.handleTypeChange}
        />
        {templateType === 0 ? (
          <TemplateSearchContainer>
            <TemplateSearch
              placeholder="Choose Event"
              event={previousEvent}
              onSelect={this.handlePreviousEventChange}
              includeFolders
              searchable
            />
          </TemplateSearchContainer>
        ) : (
          <StyledSelectField
            options={templates.map(([id, name]) => ({ value: id, label: name }))}
            placeholder="Choose Checklist"
            value={templateId}
            onChange={this.handleTemplateEventChange}
            searchable
          />
        )}

        {foldersOptions && (
          <StyledSelectField
            options={foldersOptions}
            placeholder="Choose Section"
            value={templateFolder}
            onChange={this.handleTemplateFolderChange}
          />
        )}

        {templateType === 0 && templateFolder != null && (
          <CheckboxContainer onClick={dueDateEnabled ? this.handleToggleDueDateCheckbox : noop}>
            <CheckBox checked={copyDueDates} onChange={noop} />
            <Label>
              Set due dates based on event start date
              {!dueDateEnabled && (
                <LabelWarning>
                  {!this.props.hasStartDate
                    ? "The event you're working on doesn't have a start date, which is necessary to anchor the timeline."
                    : "The event you selected doesn't have a start date, which is necessary to anchor the timeline."}
                </LabelWarning>
              )}
            </Label>
          </CheckboxContainer>
        )}

        <Button
          label="Import Checklist"
          primary
          disabled={!buttonEnabled}
          loading={loading}
          onClick={this.handleImportClick}
        />
      </Container>
    );
  }
}
