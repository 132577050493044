/* @flow */
import sortBy from 'lodash/sortBy';

import { type Field } from 'components/FieldSettings/types';

import eventDefaultFields from './eventDefaultFields';

const getStandardizedFields = (fields: $ReadOnlyArray<Field>): $ReadOnlyArray<Field> => {
  return [
    ...eventDefaultFields,
    ...sortBy(
      fields.map(field => ({
        ...field,
        isDefault: false,
        isSuggested: !!field.fieldName,
        canDisable: true,
      })),
      'order',
    ),
  ];
};

export default getStandardizedFields;
