/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import DependencyInput from 'components/Tasks/Dependencies/DependencyInput';
import DependencyRow from 'components/Tasks/Dependencies/DependencyRow';

import TaskSection from './TaskSection';

import type { TaskDependencies_task } from './__generated__/TaskDependencies_task.graphql';

const Header = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.rowPrimaryTextColor};
  margin: 8px 15px 3px 15px;
`;

class TaskDependencies extends React.PureComponent<
  {
    task: TaskDependencies_task,
    onAddDependency: (taskId: string) => void,
    onAddDependent: (taskId: string) => void,
    onRemoveDependency: (taskId: string) => void,
    onRemoveDependent: (taskId: string) => void,
    history: {
      push: string => void,
    },
    match: {
      params: {
        event_slug: string,
      },
    },
  },
  {
    adding: boolean,
  },
> {
  state = {
    adding:
      this.props.task.dependencies.edges.length === 0 &&
      this.props.task.dependents.edges.length === 0,
  };

  handleAdd = () => {
    this.setState({ adding: true });
  };

  handleAddEnd = () => {
    this.setState({ adding: false });
  };

  handleSelect = (type: 'dependent' | 'dependency', taskId: string) => {
    if (type === 'dependency') {
      this.props.onAddDependency(taskId);
    } else if (type === 'dependent') {
      this.props.onAddDependent(taskId);
    }

    this.handleAddEnd();
  };

  handleRowClick = (taskSlug: string) => {
    this.props.history.push(`/events/${this.props.match.params.event_slug}/tasks/${taskSlug}`);
  };

  render() {
    const dependencies = this.props.task.dependencies.edges.map(edge => edge.node);
    const dependents = this.props.task.dependents.edges.map(edge => edge.node);

    return (
      <TaskSection
        title="Dependencies"
        onAdd={this.handleAdd}
        disableAdd={!this.props.task.viewerCanUpdateDependencies}
      >
        {this.state.adding && (
          <DependencyInput
            eventSlug={this.props.task.event.slug}
            excludeTasks={[
              this.props.task.id,
              ...dependencies.map(t => t.id),
              ...dependents.map(t => t.id),
            ]}
            timezone={this.props.task.event.tz}
            onSelect={this.handleSelect}
            onCancel={this.handleAddEnd}
          />
        )}
        {dependencies.length > 0 && <Header>blocked by</Header>}
        {dependencies.map(dependency => (
          <DependencyRow
            key={dependency.id}
            dependency={dependency}
            disabled={!this.props.task.viewerCanUpdateDependencies}
            onRemove={this.props.onRemoveDependency}
            onClick={this.handleRowClick}
          />
        ))}
        {dependents.length > 0 && <Header>blocks</Header>}
        {dependents.map(dependent => (
          <DependencyRow
            key={dependent.id}
            dependency={dependent}
            disabled={!this.props.task.viewerCanUpdateDependencies}
            onRemove={this.props.onRemoveDependent}
            onClick={this.handleRowClick}
          />
        ))}
      </TaskSection>
    );
  }
}

export default createFragmentContainer(
  withRouter(TaskDependencies),
  graphql`
    fragment TaskDependencies_task on Deliverable {
      id
      event {
        slug
        tz
      }
      viewerCanUpdateDependencies
      dependencies {
        edges {
          node {
            id
            ...DependencyRow_dependency
          }
        }
      }
      dependents {
        edges {
          node {
            id
            ...DependencyRow_dependency
          }
        }
      }
    }
  `,
);
