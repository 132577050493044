/* @flow */
import * as React from 'react';

import EditableQuestionExpense from '../EditableQuestion/EditableQuestionExpense';
import EditableQuestionFileOptions from '../EditableQuestion/EditableQuestionFileOptions';
import EditableQuestionPlannedBudgetTotalOptions from '../EditableQuestion/EditableQuestionPlannedBudgetTotalOptions';
import EditableQuestionTextareaOptions from '../EditableQuestion/EditableQuestionTextareaOptions';
import EditableQuestionUserSelectOptions from '../EditableQuestion/EditableQuestionUserSelectOptions';
import mappingKinds from './mappingKinds';
import { type QuestionType } from './types';

const mappingOptionsComponents: $ObjMap<
  typeof mappingKinds,
  () => ?React.ComponentType<{
    question: QuestionType,
    onChangeQuestion: ($Exact<{ ...QuestionType }>) => void,
  }>,
> = {
  NAME: null,
  LOCATION: null,
  DATE: null,
  WEBSITE: null,
  DESCRIPTION: EditableQuestionTextareaOptions,
  NOTE: EditableQuestionTextareaOptions,
  ATTACHMENTS: EditableQuestionFileOptions,
  BOOTH: null,
  BOOTH_DIMENSIONS: null,
  FLOOR_PLAN: EditableQuestionTextareaOptions,
  LEADER: EditableQuestionUserSelectOptions,
  EXPENSE: EditableQuestionExpense,
  BUDGETED_AMOUNT: null,
  PLANNED_BUDGET: EditableQuestionPlannedBudgetTotalOptions,
};

export default mappingOptionsComponents;
