/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import formatCost from 'utils/number/formatCost';
import formatPercentage from 'utils/number/formatPercentage';

import updateEvent from 'graph/mutations/event/updateEvent';
import showModernMutationError from 'graph/utils/showModernMutationError';

import CostField from 'components/material/CostField';

import { type BudgetStatsOverview_event } from './__generated__/BudgetStatsOverview_event.graphql';
import { type BudgetStatsOverview_org } from './__generated__/BudgetStatsOverview_org.graphql';

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StatCell = styled.div`
  flex-shrink: 0;
  width: 160px;
  padding: 5px 5px 5px 30px;

  &:not(:last-child) {
    border-right: 1px solid #d8d8d8;
  }
`;

const StatLabel = styled.div`
  margin-bottom: 2px;
  font-size: 13px;
  color: #677386;
`;

const StatValue = styled.div`
  font-size: 16px;
  color: #3e4859;
  ${props =>
    props.positive &&
    css`
      color: #29cc71;
    `} ${props =>
    props.negative &&
    css`
      color: #f25d60;
    `};
`;

const CostFieldWrapper = styled.div`
  label {
    margin: -1px -6px;

    input {
      padding: 0 5px;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      background: #fff;

      &:hover {
        border-color: #d8d8d8;
      }

      &:focus {
        border-color: #39a8da;
      }
    }
  }
`;

class BudgetStatsOverview extends React.PureComponent<
  { event: BudgetStatsOverview_event, org: BudgetStatsOverview_org },
  { budgetedAmount: ?number },
> {
  state = {
    budgetedAmount: null,
  };

  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    } else if (event.key === 'Escape') {
      const target = event.currentTarget;

      this.setState(
        state => (state.budgetedAmount != null ? { budgetedAmount: null } : null),
        () => {
          target.blur();
        },
      );
    }
  };

  handleChange = (event: SyntheticEvent<HTMLInputElement>, cost: ?number) => {
    this.setState({ budgetedAmount: cost || 0 });
  };

  handleBlur = () => {
    this.setState(state => (state.budgetedAmount != null ? { budgetedAmount: null } : null));

    if (this.state.budgetedAmount == null) {
      return;
    }

    updateEvent(this.props.event.id, { budgetedAmount: this.state.budgetedAmount }).catch(
      showModernMutationError,
    );
  };

  render() {
    const { event, org } = this.props;

    return (
      <Root>
        <StatCell>
          <StatLabel>Planned</StatLabel>
          <StatValue>
            {event.budgetCategories.edges.length > 0 ? (
              formatCost(event.totalBudgetedAmount, org.settings.currency)
            ) : (
              <CostFieldWrapper>
                <CostField
                  value={
                    this.state.budgetedAmount != null
                      ? this.state.budgetedAmount
                      : event.totalBudgetedAmount
                  }
                  currency={org.settings.currency}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  maxValue={2147483647}
                />
              </CostFieldWrapper>
            )}
          </StatValue>
        </StatCell>

        <StatCell>
          <StatLabel>Actual </StatLabel>
          <StatValue
            positive={event.totalBudgetedAmount > event.totalActualAmount}
            negative={event.totalBudgetedAmount < event.totalActualAmount}
          >
            {formatCost(event.totalActualAmount, org.settings.currency)}
          </StatValue>
        </StatCell>

        <StatCell>
          <StatLabel>Actual / Planned</StatLabel>
          <StatValue>
            {formatPercentage(event.totalActualAmount / event.totalBudgetedAmount) || '-'}
          </StatValue>
        </StatCell>

        <StatCell>
          <StatLabel>Paid</StatLabel>
          <StatValue>{formatCost(event.totalPaidAmount, org.settings.currency)}</StatValue>
        </StatCell>

        <StatCell>
          <StatLabel>Paid / Actual</StatLabel>
          <StatValue>
            {formatPercentage(event.totalPaidAmount / event.totalActualAmount) || '-'}
          </StatValue>
        </StatCell>
      </Root>
    );
  }
}

export default createFragmentContainer(
  BudgetStatsOverview,
  graphql`
    fragment BudgetStatsOverview_event on Event {
      id
      totalBudgetedAmount
      totalActualAmount
      totalPaidAmount

      budgetCategories {
        edges {
          budgetedAmount
        }
      }
    }

    fragment BudgetStatsOverview_org on Org {
      settings {
        currency
      }
    }
  `,
);
