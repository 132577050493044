/* @flow */
import React from 'react';
import styled from 'styled-components';

import CheckBox from 'components/material/CheckBox';

const CheckLabel = styled.div`
  margin-left: 7px;
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 8px;
  color: ${props => props.theme.darkestTextColor};
`;

const ErrorLine = styled.div`
  color: ${props => props.theme.negativeActionColor};
  font-size: 11px;
`;

const handleLinkClick = e => {
  e.stopPropagation();
};

const tosLabel = (
  <CheckLabel>
    I agree to the{' '}
    <a
      href="https://www.simplecirca.com/terms-of-service"
      target="_blank"
      rel="noreferrer noopener"
      onClick={handleLinkClick}
    >
      Terms of Service
    </a>
    .
  </CheckLabel>
);

const privacyLabel = (
  <CheckLabel>
    I agree to the{' '}
    <a
      href="https://www.simplecirca.com/privacy"
      target="_blank"
      rel="noreferrer noopener"
      onClick={handleLinkClick}
    >
      Privacy Policy
    </a>
    .
  </CheckLabel>
);

type AgreementValues = {
  acceptedTOS: boolean,
  acceptedPrivacyPolicy: boolean,
};

export type AgreementValuesReturn = {
  acceptedTOS?: boolean,
  acceptedPrivacyPolicy?: boolean,
};

export default class AcceptAgreementsCheckBoxes extends React.PureComponent<{
  values: AgreementValues,
  onChange: AgreementValuesReturn => void,
  error: boolean,
}> {
  handleChangeTOS = (val: boolean) => {
    this.props.onChange({ acceptedTOS: val });
  };

  handleChangePrivacyPolicy = (val: boolean) => {
    this.props.onChange({ acceptedPrivacyPolicy: val });
  };

  render() {
    return (
      <div>
        <StyledCheckBox
          checked={this.props.values.acceptedTOS}
          onChange={this.handleChangeTOS}
          labelRenderer={() => tosLabel}
        />
        <StyledCheckBox
          checked={this.props.values.acceptedPrivacyPolicy}
          onChange={this.handleChangePrivacyPolicy}
          labelRenderer={() => privacyLabel}
        />
        {this.props.error && <ErrorLine>Please accept the terms and privacy policy.</ErrorLine>}
      </div>
    );
  }
}
