/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CompanyContacts_company$ref = any;
type CompanyProfile_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CompanyPageRoutes_company$ref: FragmentReference;
export type CompanyPageRoutes_company = {|
  +id: string,
  +$fragmentRefs: CompanyProfile_company$ref & CompanyContacts_company$ref,
  +$refType: CompanyPageRoutes_company$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CompanyPageRoutes_company",
  "type": "Company",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompanyProfile_company",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CompanyContacts_company",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a9bc61bbbe9bbc27910cfb3abf6b4cc1';
module.exports = node;
