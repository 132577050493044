/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import TAG_COLORS from 'config/tag_colors.json';

import Overlay from 'components/material/Overlay';
import SearchableList from 'components/material/SearchableList';

import type { TagsOverlay_tags } from './__generated__/TagsOverlay_tags.graphql';

const StyledOverlay = styled(Overlay)`
  width: 190px;
`;

const TagRow = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  &:first-of-type {
    padding-top: 10px;
  }
  ${props => props.focused && `background: ${props.theme.hoverRowColor};`};
`;

const Tag = styled.div`
  color: ${props => props.theme.tagTextColor};
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  padding: 3px 12px;
  display: flex;
  background: ${props => TAG_COLORS[props.color]};
`;

const TagName = styled.div`
  flex: 1 1 auto;
  margin-left: 5px;
`;

class TagsOverlay extends React.PureComponent<{
  show: boolean,
  target: any,
  container: any,
  onHide: () => void,
  tags: TagsOverlay_tags,
  activeTags: Array<string>,
  onSelect: (string, isActive: boolean) => void,
  className?: string,
  hideActive?: boolean,
  forceRightEdge?: boolean,
}> {
  handleSelect = (tag: $ElementType<TagsOverlay_tags, 0>) => {
    const isActive = this.props.activeTags.includes(tag.id);

    this.props.onSelect(tag.id, isActive);
  };

  handleRenderItem = (tag: $ElementType<TagsOverlay_tags, 0>, focused) => {
    const isActive = this.props.activeTags.includes(tag.id);

    return (
      <TagRow key={tag.id} focused={focused}>
        <Tag color={tag.color}>
          <TagName>{tag.name}</TagName>
          {isActive && <i className="fa fa-fw fa-check" />}
        </Tag>
      </TagRow>
    );
  };

  render() {
    const tags = this.props.hideActive
      ? this.props.tags.filter(tag => !this.props.activeTags.includes(tag.id))
      : this.props.tags;

    return (
      <StyledOverlay
        show={this.props.show}
        target={this.props.target}
        onHide={this.props.onHide}
        container={this.props.container}
        className={this.props.className}
        forceRightEdge={this.props.forceRightEdge}
      >
        <SearchableList
          options={tags}
          queryAttrs={['name']}
          renderItem={this.handleRenderItem}
          onSelect={this.handleSelect}
          maxOptions={10}
        />
      </StyledOverlay>
    );
  }
}

export default createFragmentContainer(
  TagsOverlay,
  graphql`
    fragment TagsOverlay_tags on Tag @relay(plural: true) {
      id
      color
      name
    }
  `,
);
