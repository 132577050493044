/* @flow */
import type { FieldType } from 'utils/customization/types';

export default function getRequestInboxFilterFields(): $ReadOnlyArray<FieldType> {
  return [
    {
      id: 'requestFormIds',
      label: 'Request Form',
      fieldName: 'requestFormIds',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'senderIds',
      label: 'Submitted By',
      fieldName: 'senderIds',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'requestedDate',
      label: 'Submitted Date',
      fieldName: 'requestedDate',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'statuses',
      label: 'Request Status',
      fieldName: 'statuses',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
    {
      id: 'requestReviewers',
      label: 'Request Reviewers',
      fieldName: 'requestReviewers',
      order: 0,
      kind: 'DEFAULT',
      required: false,
      options: [],
    },
  ];
}
