/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type TaskStatus = "COMPLETED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TasksCalendarTask_task$ref: FragmentReference;
export type TasksCalendarTask_task = {|
  +id: string,
  +status: TaskStatus,
  +name: string,
  +slug: string,
  +dueDate: ?any,
  +dueDateAllDay: ?boolean,
  +parent: ?{|
    +slug: string
  |},
  +$refType: TasksCalendarTask_task$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TasksCalendarTask_task",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueDateAllDay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parent",
      "storageKey": null,
      "args": null,
      "concreteType": "Deliverable",
      "plural": false,
      "selections": [
        v0
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '95af19246d0516593aeb3d6b9ff5fd15';
module.exports = node;
