/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import RequestSubmissionEventName from 'components/EventRequestForm/RequestSubmissionList/columns/RequestSubmissionEventName';

import type { EventRequestsItemEventName_eventRequestSubmission } from './__generated__/EventRequestsItemEventName_eventRequestSubmission.graphql';

class EventRequestsItemEventName extends React.PureComponent<{
  eventRequestSubmission: EventRequestsItemEventName_eventRequestSubmission,
  onColumnLoad?: (value: string, str: string, extraSpace?: number) => void,
  name: string,
}> {
  componentDidMount() {
    const { name, eventRequestSubmission, onColumnLoad } = this.props;
    if (onColumnLoad) {
      onColumnLoad(name, eventRequestSubmission.event.name, 20);
    }
  }

  render() {
    return <RequestSubmissionEventName requestSubmission={this.props.eventRequestSubmission} />;
  }
}

export default createFragmentContainer(
  EventRequestsItemEventName,
  graphql`
    fragment EventRequestsItemEventName_eventRequestSubmission on EventRequestSubmission {
      ...RequestSubmissionEventName_requestSubmission
      event {
        name
      }
    }
  `,
);
