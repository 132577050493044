/**
 * @flow
 * @relayHash 6400e162cbf81d0c48fb9b29a7e22ed6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CRM_event$ref = any;
export type SalesforceSyncStatusInfoQueryVariables = {|
  eventSlug: string
|};
export type SalesforceSyncStatusInfoQueryResponse = {|
  +event: ?{|
    +$fragmentRefs: CRM_event$ref
  |}
|};
export type SalesforceSyncStatusInfoQuery = {|
  variables: SalesforceSyncStatusInfoQueryVariables,
  response: SalesforceSyncStatusInfoQueryResponse,
|};
*/


/*
query SalesforceSyncStatusInfoQuery(
  $eventSlug: String!
) {
  event(slug: $eventSlug) {
    ...CRM_event
    id
  }
}

fragment CRM_event on Event {
  id
  ...SalesforceSyncStatusInfo_event
  ...EventCRMSyncedContacts_event
  salesforceCampaign {
    id
    ...SalesforceCampaignInfo_campaign
  }
  marketoProgram {
    marketoId
    name
    url
    id
  }
}

fragment SalesforceSyncStatusInfo_event on Event {
  id
  tz
  pullStatus {
    lastSuccessAt
    state
    errorMessage
  }
  pushStatus {
    lastSuccessAt
    state
    errorMessage
  }
}

fragment EventCRMSyncedContacts_event on Event {
  id
  slug
  allContacts: eventContacts {
    totalCount
  }
  syncedContacts: eventContacts(filters: {membershipSyncStatuses: [OK]}) {
    totalCount
  }
  disabledContacts: eventContacts(filters: {membershipSyncStatuses: [DISABLED]}) {
    totalCount
  }
  failedContacts: eventContacts(filters: {membershipSyncStatuses: [FAILING]}) {
    totalCount
  }
  pendingContacts: eventContacts(filters: {membershipSyncStatuses: [PENDING]}) {
    totalCount
  }
}

fragment SalesforceCampaignInfo_campaign on SalesforceCampaign {
  name
  campaignSfdcid
  campaignUrl
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastSuccessAt",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "state",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "errorMessage",
    "args": null,
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SalesforceSyncStatusInfoQuery",
  "id": null,
  "text": "query SalesforceSyncStatusInfoQuery(\n  $eventSlug: String!\n) {\n  event(slug: $eventSlug) {\n    ...CRM_event\n    id\n  }\n}\n\nfragment CRM_event on Event {\n  id\n  ...SalesforceSyncStatusInfo_event\n  ...EventCRMSyncedContacts_event\n  salesforceCampaign {\n    id\n    ...SalesforceCampaignInfo_campaign\n  }\n  marketoProgram {\n    marketoId\n    name\n    url\n    id\n  }\n}\n\nfragment SalesforceSyncStatusInfo_event on Event {\n  id\n  tz\n  pullStatus {\n    lastSuccessAt\n    state\n    errorMessage\n  }\n  pushStatus {\n    lastSuccessAt\n    state\n    errorMessage\n  }\n}\n\nfragment EventCRMSyncedContacts_event on Event {\n  id\n  slug\n  allContacts: eventContacts {\n    totalCount\n  }\n  syncedContacts: eventContacts(filters: {membershipSyncStatuses: [OK]}) {\n    totalCount\n  }\n  disabledContacts: eventContacts(filters: {membershipSyncStatuses: [DISABLED]}) {\n    totalCount\n  }\n  failedContacts: eventContacts(filters: {membershipSyncStatuses: [FAILING]}) {\n    totalCount\n  }\n  pendingContacts: eventContacts(filters: {membershipSyncStatuses: [PENDING]}) {\n    totalCount\n  }\n}\n\nfragment SalesforceCampaignInfo_campaign on SalesforceCampaign {\n  name\n  campaignSfdcid\n  campaignUrl\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SalesforceSyncStatusInfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CRM_event",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SalesforceSyncStatusInfoQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tz",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pullStatus",
            "storageKey": null,
            "args": null,
            "concreteType": "SyncStatus",
            "plural": false,
            "selections": v3
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pushStatus",
            "storageKey": null,
            "args": null,
            "concreteType": "SyncStatus",
            "plural": false,
            "selections": v3
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "allContacts",
            "name": "eventContacts",
            "storageKey": null,
            "args": null,
            "concreteType": "EventContactConnection",
            "plural": false,
            "selections": v4
          },
          {
            "kind": "LinkedField",
            "alias": "syncedContacts",
            "name": "eventContacts",
            "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"OK\"]})",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "membershipSyncStatuses": [
                    "OK"
                  ]
                },
                "type": "ContactFilters"
              }
            ],
            "concreteType": "EventContactConnection",
            "plural": false,
            "selections": v4
          },
          {
            "kind": "LinkedField",
            "alias": "disabledContacts",
            "name": "eventContacts",
            "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"DISABLED\"]})",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "membershipSyncStatuses": [
                    "DISABLED"
                  ]
                },
                "type": "ContactFilters"
              }
            ],
            "concreteType": "EventContactConnection",
            "plural": false,
            "selections": v4
          },
          {
            "kind": "LinkedField",
            "alias": "failedContacts",
            "name": "eventContacts",
            "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"FAILING\"]})",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "membershipSyncStatuses": [
                    "FAILING"
                  ]
                },
                "type": "ContactFilters"
              }
            ],
            "concreteType": "EventContactConnection",
            "plural": false,
            "selections": v4
          },
          {
            "kind": "LinkedField",
            "alias": "pendingContacts",
            "name": "eventContacts",
            "storageKey": "eventContacts(filters:{\"membershipSyncStatuses\":[\"PENDING\"]})",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "membershipSyncStatuses": [
                    "PENDING"
                  ]
                },
                "type": "ContactFilters"
              }
            ],
            "concreteType": "EventContactConnection",
            "plural": false,
            "selections": v4
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceCampaign",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceCampaign",
            "plural": false,
            "selections": [
              v2,
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "campaignSfdcid",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "campaignUrl",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "marketoProgram",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketoProgram",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "marketoId",
                "args": null,
                "storageKey": null
              },
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78951cc4361b36020e310f8fe9104dd7';
module.exports = node;
