/* @flow */
import { graphql } from 'react-relay';

import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  RemoveUserFromTeamMutationResponse,
  RemoveUserFromTeamMutationVariables,
} from './__generated__/removeUserFromTeamMutation.graphql';

const mutation = graphql`
  mutation removeUserFromTeamMutation($input: RemoveUserFromTeamInput!) {
    removeUserFromTeam(input: $input) {
      clientMutationId
      removedUserId
      removedTeamId
      team {
        ...TeamMembersTablePagination_totalCountTeam
      }
      userEventMembershipEdges {
        node {
          id
          ...EventAccessLevel_staffedEvent
          ...EventDate_staffedEvent
          ...EventDateAdded_staffedEvent
          ...EventLocation_staffedEvent
          ...EventName_staffedEvent
          ...EventOnsite_staffedEvent
          ...EventActionsColumn_staffedEvent
        }
      }
      user {
        hasTeamAccess
      }
      org {
        subscription {
          fatmLimit
          fatmCount
        }
      }
    }
  }
`;

export default function removeUserFromTeam(
  teamId: string,
  userId: string,
): Promise<RemoveUserFromTeamMutationResponse> {
  const variables: RemoveUserFromTeamMutationVariables = {
    input: {
      teamId,
      userId,
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        parentId: teamId,
        connections: [{ key: 'TeamMembersTablePagination_members' }],
        deletedIdField: 'removedUserId',
      }),
      removeRecordFromConnection({
        parentId: userId,
        connections: [{ key: 'UserTeams_memberships' }, { field: 'memberships' }],
        deletedIdField: 'removedTeamId',
      }),
    ),
  });
}
