/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import syncStatuses from 'config/syncStatuses.json';
import ErrorIcon from 'components/ErrorIcon';

import type { ContactSyncStatus_contact } from './__generated__/ContactSyncStatus_contact.graphql';

class ContactSyncStatus extends React.PureComponent<{
  contact: ContactSyncStatus_contact,
}> {
  getSyncStatus = () => {
    return syncStatuses[this.props.contact.syncStatus.state];
  };

  render() {
    return (
      <React.Fragment>
        {this.getSyncStatus()}
        {this.props.contact.syncStatus.errorMessage && (
          <ErrorIcon message={this.props.contact.syncStatus.errorMessage} />
        )}
      </React.Fragment>
    );
  }
}

export default createFragmentContainer(
  ContactSyncStatus,
  graphql`
    fragment ContactSyncStatus_contact on Contact {
      syncStatus {
        state
        errorMessage
      }
    }
  `,
);
