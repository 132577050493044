/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import SyncStatusIndicator from 'components/integrations/SyncStatusIndicator';

import type { CampaignSyncStatus_account } from './__generated__/CampaignSyncStatus_account.graphql';

const Container = styled.div`
  display: flex;
`;

const StyledIndicator = styled(SyncStatusIndicator)`
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0;
  }
`;

class CampaignSyncStatus extends React.Component<{
  account: CampaignSyncStatus_account,
}> {
  render() {
    return (
      <Container>
        <StyledIndicator status={this.props.account.campaignSyncStatus} label="Campaigns" />
      </Container>
    );
  }
}

export default createFragmentContainer(
  CampaignSyncStatus,
  graphql`
    fragment CampaignSyncStatus_account on SalesforceAccount {
      campaignSyncStatus {
        ...SyncStatusIndicator_status
      }
    }
  `,
);
