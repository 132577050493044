import React from 'react';
import PropTypes from 'prop-types';

import fullNameOfUser from 'utils/fullNameOfUser';

import InviteWindow, { type User } from 'components/InviteWindow';
import UserSelectionOverlay from 'components/material/UserSelectionOverlay';

export default class Name extends React.Component {
  static propTypes = {
    value: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }),
    ).isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        avatar: PropTypes.string,
      }),
    ).isRequired,
    eventId: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  state = {
    overlayShown: false,
    showInviteWindow: false,
  };

  handleOverlayShow = () => {
    if (!this.props.disabled) {
      this.setState({ overlayShown: true });
    }
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  handleInviteClick = () => {
    this.setState({ showInviteWindow: true });
  };

  handleHideInviteWindow = (users?: ?$ReadOnlyArray<User>) => {
    if (users != null) {
      users.forEach(user => {
        this.props.onAdd(user.id);
      });
    }
    this.setState({ showInviteWindow: false });
  };

  handleSelect = (userId, isActive) => {
    if (isActive) {
      this.props.onRemove(userId);
    } else {
      this.props.onAdd(userId);
    }
  };

  render() {
    const { value, users, eventId, disabled } = this.props;

    return (
      <div className="schedule-list-row-participants">
        {value.length === 0 ? (
          !disabled && (
            <div className="schedule-list-row-empty">
              <a onClick={this.handleOverlayShow}>
                <i className="fa fa-fw fa-user" /> Participant
              </a>
            </div>
          )
        ) : (
          <ul onClick={!disabled ? () => this.handleOverlayShow() : undefined}>
            <i className="fa fa-fw fa-user" />
            {value.map(user => (
              <li key={user.id}>
                {fullNameOfUser(user)}
              </li>
            ))}
          </ul>
        )}
        <UserSelectionOverlay
          show={this.state.overlayShown}
          onHide={this.handleOverlayHide}
          target={this}
          container={this}
          users={users}
          selectedUserIds={this.props.value.map(u => u.id)}
          onSelect={this.handleSelect}
          onInviteClick={this.handleInviteClick}
          forceRightEdge
        />
        {this.state.showInviteWindow && (
          <InviteWindow
            onHide={this.handleHideInviteWindow}
            eventId={eventId}
            fromWindow="schedule"
          />
        )}
      </div>
    );
  }
}
