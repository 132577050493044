/* @flow */
import { type Location } from 'react-router-dom';

import TeamDateAdded from './TeamDateAdded';
import TeamMembershipActionsColumn from './TeamMembershipActionsColumn';
import TeamName from './TeamName';

export type MembershipCellPropsType<MembershipEdge: {}> = {|
  membershipEdge: MembershipEdge,
  userId: string,
  currentUserId: string,
  location: Location,
|};

const columns = [
  {
    title: 'Team Name',
    sortKey: 'TEAM_NAME',
    component: TeamName,
    grow: 1,
  },
  {
    title: 'Added to Team',
    sortKey: 'TEAM_DATE_ADDED',
    component: TeamDateAdded,
    width: 150,
  },
  {
    title: '',
    component: TeamMembershipActionsColumn,
    type: 'action',
  },
];

export default columns;
