/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import fullNameOfUser from 'utils/fullNameOfUser';

import {
  type CustomizableResponse,
  type UpdateCustomFieldValueInput,
} from 'graph/mutations/custom_field/updateCustomFieldValue';

import InlineUserSelectField from 'components/budget/Table/InlineUserSelectField';
import LockIcon from 'components/LockIcon';
import MaterialAvatar from 'components/material/MaterialAvatar';
import { type User } from 'components/UserSelect';

import type { CustomizableUserSelect_customizable } from './__generated__/CustomizableUserSelect_customizable.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 17px;
  margin: -6px -18px -6px -10px;
`;

const TextWrapper = styled.div`
  margin-left: 5px;
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 16px;
`;

class CustomizableUserSelect extends React.Component<{
  customizable: CustomizableUserSelect_customizable,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
  onUpdateCustomField?: (
    customizable: CustomizableResponse,
    customField: UpdateCustomFieldValueInput,
  ) => Promise<void>,
}> {
  getValue = (): ?User => {
    const { customizable, fieldSettings } = this.props;
    const customUserSelectField = customizable.customUserSelectFields.find(
      userField => userField.customField.id === fieldSettings.id,
    );
    return customUserSelectField ? customUserSelectField.user : null;
  };

  handleSave = (user: ?User) => {
    const { onUpdateCustomField, updateColumnWidth, customizable, fieldSettings } = this.props;
    // since there is no way to remove value from inlineUserSelect from column
    // if there will be the way to remove inlineUserSelect the this.getError should be changed accordingly
    if (this.getError(user)) {
      return;
    }

    if ((user || {}).id !== (this.getValue() || {}).id && onUpdateCustomField) {
      onUpdateCustomField(customizable, {
        customFieldId: fieldSettings.id,
        userValue: (user || {}).id,
      });
      updateColumnWidth();
    }
  };

  getError = (user: ?User): ?string => {
    if (this.props.fieldSettings.required && this.getValue() == null && user == null) {
      return 'Required';
    }
    return null;
  };

  render() {
    const { readOnly, fieldSettings, customizable, updateColumnWidth } = this.props;

    if (!customizable) {
      return null;
    }

    const activeUser = this.getValue();
    if (readOnly || fieldSettings.restrictChangingValue) {
      return (
        <>
          {activeUser && (
            <Container>
              <MaterialAvatar radius={12} user={activeUser} />
              <TextWrapper>{fullNameOfUser(activeUser)}</TextWrapper>
            </Container>
          )}
          {!readOnly && fieldSettings.restrictChangingValue && (
            <StyledLockIcon label="Salesforce" />
          )}
        </>
      );
    }
    return (
      <InlineUserSelectField
        placeholder={fieldSettings.label}
        activeUsers={activeUser ? [activeUser] : []}
        onSelect={this.handleSave}
        clearable={!fieldSettings.required}
        getError={this.getError}
        updateColumnWidth={updateColumnWidth}
        // eslint-disable-next-line no-underscore-dangle
        eventId={customizable.__typename === 'Event' ? customizable.id : undefined}
      />
    );
  }
}

export default createFragmentContainer(
  CustomizableUserSelect,
  graphql`
    fragment CustomizableUserSelect_customizable on CustomizableInterface {
      id
      __typename
      customUserSelectFields {
        customField {
          id
        }
        user {
          id
          firstName
          lastName
          email
          avatar
          ...MaterialAvatar_user
        }
      }
    }
  `,
);
