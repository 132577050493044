/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import lodashOmit from 'lodash/omit';

import updateEventRequestForm from 'graph/mutations/eventRequest/updateEventRequestForm';

import { type FormDetailsType } from 'components/EventRequestForm/lib/types';
import type { Participant } from 'components/Participants';

import type { updateEventRequestFormMutationResponse } from '../../graph/mutations/eventRequest/__generated__/updateEventRequestFormMutation.graphql';
import EventRequestFormBuilderLogic from './EventRequestFormBuilderLogic';
import EventRequestFormBuilderPreview from './EventRequestFormBuilderPreview';
import EventRequestFormBuilderQuestionnaire from './EventRequestFormBuilderQuestionnaire';
import EventRequestFormBuilderSettings from './EventRequestFormBuilderSettings';

import type { EventRequestFormBuilderBody_org } from './__generated__/EventRequestFormBuilderBody_org.graphql';
import type { EventRequestFormBuilderBody_requestForm } from './__generated__/EventRequestFormBuilderBody_requestForm.graphql';

const Body = styled.div`
  flex: 1 1 0%;
  width: 770px;
  margin: 0 auto;
  padding-bottom: 85px;
  counter-reset: question-counter;
`;

class EventRequestFormBuilderBody extends React.PureComponent<
  {
    org: EventRequestFormBuilderBody_org,
    requestForm: EventRequestFormBuilderBody_requestForm,
    activeStepIndex: number,
    scrollElement: ?HTMLDivElement,
    onNextStep: () => void,
    onPrevStep: () => void,
  },
  {
    requestForm: FormDetailsType,
    reviewers: $ReadOnlyArray<Participant>,
    hasChanges: boolean,
  },
> {
  constructor(props) {
    super(props);

    const { requestForm } = this.props;

    const sections = requestForm.sections.edges.map(
      ({ node: { questions, rules: sectionRules, __typename, ...restOfSectionNode } }) => ({
        ...restOfSectionNode,
        questions: questions.edges.map(
          ({
            node: {
              rules: questionRules,
              selectOptions,
              selectUsers,
              budgetCategories,
              __typename: questionTypename,
              ...restOfQuestionNode
            },
          }) => ({
            ...restOfQuestionNode,
            selectOptions: selectOptions.edges.map(edge => edge.node),
            selectUsers: selectUsers.edges.map(edge => edge.node),
            allowRequesterToChooseBudgetCategory: restOfQuestionNode.budgetCategory == null,
            budgetCategories: budgetCategories.edges.map(edge => edge.node),
            sectionId: restOfSectionNode.id,
          }),
        ),
      }),
    );
    const declineReasonIds: $ReadOnlyArray<string> = requestForm.declineReasons.edges.map(
      edge => edge.node.id,
    );
    const reviewers = requestForm.reviewers.edges.map(({ node }) => node);
    const reviewerIds = reviewers.map(r => r.id);
    this.state = {
      hasChanges: false,
      requestForm: {
        id: requestForm.id,
        name: requestForm.name,
        description: requestForm.description || '',
        logo: requestForm.logo,
        accessibility: requestForm.accessibility,
        submitMessage: requestForm.submitMessage,
        submitEmailTemplate: requestForm.submitEmailTemplate,
        approveEmailTemplate: requestForm.approveEmailTemplate,
        declineEmailTemplate: requestForm.declineEmailTemplate,
        returnEmailTemplate: requestForm.returnEmailTemplate,
        isDraft: requestForm.isDraft,
        sections,
        declineReasonIds,
        reviewerIds,
        notifyReviewers: requestForm.notifyReviewers,
        showInEventsDashboard: requestForm.showInEventsDashboard,
        eventTemplate: requestForm.eventTemplate,
        copyTimeline: requestForm.copyTimeline,
      },
      reviewers,
    };
  }

  updateHasChanges = hasChanges => this.setState({ hasChanges });

  handleReviewersChange = reviewers => {
    this.setState({ reviewers });
  };

  handleChangeFormDetails = (details: $Shape<FormDetailsType>, callback?: () => void) => {
    this.setState(
      state => ({
        requestForm: {
          ...state.requestForm,
          ...details,
        },
      }),
      callback,
    );
  };

  updateFormInState = requestForm => {
    this.setState(state => {
      const savedSections = requestForm.sections.edges.map(edge => edge.node);

      return {
        requestForm: {
          ...state.requestForm,
          id: requestForm.id,
          sections: state.requestForm.sections.map(section => {
            const savedSection = savedSections.find(item => item.order === section.order);

            if (savedSection) {
              const savedQuestions = savedSection.questions.edges.map(edge => edge.node);

              return {
                ...section,
                id: savedSection.id,
                questions: section.questions.map(question => {
                  const savedQuestion = savedQuestions.find(item => item.order === question.order);

                  return savedQuestion
                    ? { ...question, id: savedQuestion.id, sectionId: savedSection.id }
                    : question;
                }),
              };
            }

            return section;
          }),
        },
      };
    });
  };

  saveForm = (): Promise<updateEventRequestFormMutationResponse> => {
    return updateEventRequestForm(
      this.props.requestForm.id,
      lodashOmit(this.state.requestForm, 'id'),
    ).then(response => {
      if (response.updateEventRequestForm) {
        this.updateFormInState(response.updateEventRequestForm.requestForm);
      }
      return response;
    });
  };

  render() {
    return (
      <Body>
        {this.props.activeStepIndex === 0 && (
          <EventRequestFormBuilderQuestionnaire
            isSubmitted={!!(this.props.requestForm && this.props.requestForm.lastSubmittedAt)}
            onNextStep={this.props.onNextStep}
            saveForm={this.saveForm}
            onHasChanges={this.updateHasChanges}
            hasChanges={this.state.hasChanges}
            org={this.props.org}
            editingRequestForm={this.state.requestForm}
            requestForm={this.props.requestForm}
            onChangeFormDetails={this.handleChangeFormDetails}
            scrollElement={this.props.scrollElement}
          />
        )}
        {this.props.activeStepIndex === 1 && (
          <EventRequestFormBuilderLogic
            org={this.props.org}
            requestForm={this.props.requestForm}
            onNextStep={this.props.onNextStep}
            onPrevStep={this.props.onPrevStep}
          />
        )}
        {this.props.activeStepIndex === 2 && (
          <EventRequestFormBuilderSettings
            saveForm={this.saveForm}
            onNextStep={this.props.onNextStep}
            onPrevStep={this.props.onPrevStep}
            org={this.props.org}
            requestForm={this.state.requestForm}
            reviewers={this.state.reviewers}
            onChangeFormDetails={this.handleChangeFormDetails}
            onReviewersChange={this.handleReviewersChange}
          />
        )}
        {this.props.activeStepIndex === 3 && (
          <EventRequestFormBuilderPreview
            saveForm={this.saveForm}
            onPrevStep={this.props.onPrevStep}
            org={this.props.org}
            editingRequestForm={this.state.requestForm}
            requestForm={this.props.requestForm}
            requestFormId={this.props.requestForm && this.props.requestForm.id}
          />
        )}
      </Body>
    );
  }
}

export default createFragmentContainer(
  EventRequestFormBuilderBody,
  graphql`
    fragment EventRequestFormBuilderBody_org on Org {
      ...EventRequestFormBuilderQuestionnaire_org
      ...EventRequestFormBuilderLogic_org
      ...EventRequestFormBuilderSettings_org
      ...EventRequestFormBuilderPreview_org
    }

    fragment EventRequestFormBuilderBody_requestForm on EventRequestForm {
      ...EventRequestFormBuilderPreview_requestForm
      id
      name
      description
      logo
      accessibility
      notifyReviewers
      showInEventsDashboard
      submitMessage
      submitEmailTemplate
      approveEmailTemplate
      declineEmailTemplate
      returnEmailTemplate
      isDraft
      lastSubmittedAt
      eventTemplate {
        id
        name
        startDate
        team {
          name
          id
        }
      }
      copyTimeline
      sections {
        edges {
          node {
            id
            __typename
            title
            helpText
            description
            order
            questions {
              edges {
                node {
                  id
                  __typename
                  order
                  mapping
                  budgetCategory {
                    id
                    name
                  }
                  budgetCategories {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  mappingCustomField {
                    id
                    label
                    kind
                    customizableType
                    options {
                      edges {
                        node {
                          id
                          name
                          editable
                        }
                      }
                    }
                  }
                  name
                  description
                  required
                  textMinLength
                  textMaxLength
                  fileExtensions
                  expenseName
                  selectShowOtherOption
                  maxSelectionSize
                  selectOptions {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  selectUsers {
                    edges {
                      node {
                        id
                        firstName
                        lastName
                        email
                        ...QuestionUserSelectOptionRow_user
                      }
                    }
                  }
                  rules {
                    edges {
                      node {
                        id
                        action
                        order
                        customSavedTextFilters {
                          edges {
                            node {
                              id
                              order
                              fieldName
                              values
                              operator
                              customField {
                                id
                              }
                            }
                          }
                        }
                        customSavedTextareaFilters {
                          edges {
                            node {
                              id
                              order
                              fieldName
                              values
                              operator
                              customField {
                                id
                              }
                            }
                          }
                        }
                        customSavedLinkFilters {
                          edges {
                            node {
                              id
                              order
                              values
                              operator
                              fieldName
                              customField {
                                id
                              }
                            }
                          }
                        }
                        customSavedNumberFilters {
                          edges {
                            node {
                              id
                              order
                              minValue
                              maxValue
                              customField {
                                id
                              }
                            }
                          }
                        }
                        customSavedCurrencyFilters {
                          edges {
                            node {
                              id
                              order
                              minValue
                              maxValue
                              fieldName
                              customField {
                                id
                              }
                            }
                          }
                        }
                        customSavedDateFilters {
                          edges {
                            node {
                              id
                              order
                              minValue
                              maxValue
                              customField {
                                id
                              }
                            }
                          }
                        }
                        customSavedBooleanFilters {
                          edges {
                            node {
                              id
                              value
                              order
                              customField {
                                id
                              }
                            }
                          }
                        }
                        customSavedUserMultiselectFilters {
                          edges {
                            node {
                              id
                              order
                              fieldName
                              operator
                              customField {
                                id
                              }
                              options {
                                edges {
                                  node {
                                    user {
                                      id
                                      email
                                      firstName
                                      lastName
                                      ...MaterialAvatar_user
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        customSavedMultiselectFilters {
                          edges {
                            node {
                              id
                              order
                              operator
                              options {
                                edges {
                                  node {
                                    option {
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                              customField {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            rules {
              edges {
                node {
                  id
                  action
                  order
                  customSavedTextFilters {
                    edges {
                      node {
                        id
                        order
                        fieldName
                        values
                        operator
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedTextareaFilters {
                    edges {
                      node {
                        id
                        order
                        fieldName
                        values
                        operator
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedLinkFilters {
                    edges {
                      node {
                        id
                        order
                        values
                        operator
                        fieldName
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedNumberFilters {
                    edges {
                      node {
                        id
                        order
                        minValue
                        maxValue
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedCurrencyFilters {
                    edges {
                      node {
                        id
                        order
                        minValue
                        maxValue
                        fieldName
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedDateFilters {
                    edges {
                      node {
                        id
                        order
                        minValue
                        maxValue
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedBooleanFilters {
                    edges {
                      node {
                        id
                        value
                        order
                        customField {
                          id
                        }
                      }
                    }
                  }
                  customSavedUserMultiselectFilters {
                    edges {
                      node {
                        id
                        order
                        fieldName
                        operator
                        customField {
                          id
                        }
                        options {
                          edges {
                            node {
                              user {
                                id
                                email
                                firstName
                                lastName
                                ...MaterialAvatar_user
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  customSavedMultiselectFilters {
                    edges {
                      node {
                        id
                        order
                        operator
                        options {
                          edges {
                            node {
                              option {
                                id
                                name
                              }
                            }
                          }
                        }
                        customField {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      declineReasons {
        edges {
          node {
            id
          }
        }
      }
      reviewers {
        edges {
          node {
            id
            firstName
            lastName
            avatar
            email
          }
        }
      }
    }
  `,
);
