/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import intersection from 'lodash/intersection';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';

import personalizationDefaults from 'config/personalizationDefaults';

import isValidEmail from 'utils/validators/isValidEmail';

import showModernMutationError from 'graph/utils/showModernMutationError';

import DefaultButton from 'components/budget/Button';
import FormHeader from 'components/EventRequestForm/FormHeader';
import FormSection from 'components/EventRequestForm/FormSection';
import generateBlankQuestionValue from 'components/EventRequestForm/lib/generateBlankQuestionValue';
import mappingCustomValueValidators from 'components/EventRequestForm/lib/mappingCustomValueValidators';
import mappingValueValidators from 'components/EventRequestForm/lib/mappingValueValidators';
import type {
  QuestionMappingCustomFieldType,
  QuestionMappingType,
  QuestionType,
  QuestionValueInputType,
  QuestionValueType,
  RequesterInfoType,
  SectionType,
} from 'components/EventRequestForm/lib/types';
import Question from 'components/EventRequestForm/Question';
import RequesterInfo from 'components/EventRequestForm/RequesterInfo';
import EventRequestFormContainer from 'views/EventRequestFormBuilder/components/EventRequestFormContainer';
import SectionNumber from 'views/EventRequestFormBuilder/components/SectionNumber';

import isQuestionRequired from './lib/isQuestionRequired';
import isRuleableHidden from './lib/isRuleableHidden';

import type { RequestForm_me } from './__generated__/RequestForm_me.graphql';
import type { RequestForm_org } from './__generated__/RequestForm_org.graphql';
import type { RequestForm_requestForm } from './__generated__/RequestForm_requestForm.graphql';
import type { RequestForm_requestSubmission } from './__generated__/RequestForm_requestSubmission.graphql';
import type { RequestForm_ssoUser } from './__generated__/RequestForm_ssoUser.graphql';

export type RelaySectionType = $PropertyType<
  $ElementType<$PropertyType<$PropertyType<RequestForm_requestForm, 'sections'>, 'edges'>, 0>,
  'node',
>;

export type RelayQuestionType = $PropertyType<
  $ElementType<$PropertyType<$PropertyType<RelaySectionType, 'questions'>, 'edges'>, 0>,
  'node',
>;

export type RuleType = $PropertyType<
  $ElementType<$PropertyType<$PropertyType<RelaySectionType, 'rules'>, 'edges'>, 0>,
  'node',
>;

export type QuestionValueWithMapping = {
  ...QuestionValueType,
  fieldName: ?QuestionMappingType,
  customField: ?QuestionMappingCustomFieldType,
};

const RequestFormContainer = styled.div`
  counter-reset: question-counter section-counter;
`;

const Container = styled(EventRequestFormContainer)`
  position: relative;
  margin-bottom: 20px;
  padding: 33px 0;
  @media (${props => props.theme.mobileOnly}) {
    padding-top: 40px;
  }
`;

const HeaderContainer = styled(EventRequestFormContainer)`
  margin-bottom: 20px;
  padding: 20px 26px;
`;

const FormSectionContainer = styled.div`
  > div {
    margin: 0 50px 15px 80px;
  }
  @media (${props => props.theme.mobileOnly}) {
    > div {
      margin: 0;
    }
  }
`;

const StyledFormHeader = styled(FormHeader)`
  padding: 20px 30px 0;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 20px;
  }
`;

const Divider = styled.div`
  height: 1px;
  margin: 22px -10px;
  background: #eaebec;
  @media (${props => props.theme.mobileOnly}) {
    height: 0;
  }
`;

const SubmitButtonContainer = styled.div`
  text-align: center;
`;

const SubmitButton = styled(DefaultButton)`
  width: 220px;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  transition: 0.2s;
`;

const StyledSectionNumber = styled(SectionNumber)`
  position: absolute;
  counter-increment: section-counter;
  &:before {
    content: counter(section-counter);
  }
  @media (${props => props.theme.mobileOnly}) {
    top: 10px;
  }
`;

class RequestForm extends React.PureComponent<
  {
    org: RequestForm_org,
    me: ?RequestForm_me,
    ssoUser: ?RequestForm_ssoUser,
    requestSubmission: ?RequestForm_requestSubmission,
    requestForm: RequestForm_requestForm,
    onSubmit?: ({
      requesterInfo: RequesterInfoType,
      questionValues: $ReadOnlyArray<QuestionValueType>,
    }) => Promise<any>,
    submitToken: ?string,
    tz: string,
  },
  {
    loading: boolean,
    shouldValidate: boolean,
    requesterInfo: RequesterInfoType,
    questionValues: $ReadOnlyArray<QuestionValueType>,
    errors: { [string]: ?string },
  },
> {
  state = {
    loading: false,
    shouldValidate: false,
    requesterInfo: {
      firstName:
        (this.props.me && this.props.me.firstName) ||
        (this.props.ssoUser && this.props.ssoUser.firstName) ||
        '',
      lastName:
        (this.props.me && this.props.me.lastName) ||
        (this.props.ssoUser && this.props.ssoUser.lastName) ||
        '',
      email:
        (this.props.me && this.props.me.email) ||
        (this.props.ssoUser && this.props.ssoUser.email) ||
        '',
    },
    questionValues: this.props.requestSubmission
      ? this.props.requestSubmission.questionValues.map(
          ({ dateValue, filesValue, locationValue, ...value }) => {
            const question = this.flatQuestions().find(q => q.id === value.questionId);

            return {
              ...value,
              selectIdsValue: question
                ? intersection(
                    question.selectOptions.edges.map(edge => edge.node.id),
                    value.selectIdsValue,
                  )
                : value.selectIdsValue,
              dateValue: dateValue && { ...dateValue },
              locationValue: locationValue && { ...locationValue },
              filesValue: filesValue.map(file => ({ ...file })),
            };
          },
        )
      : [],
    errors: {},
  };

  hiddenQuestions: { [string]: boolean } = {};

  updateHiddenQuestionCache = () => {
    const requesterInfo = this.state.requesterInfo;
    const { tz, requestForm } = this.props;
    const questionValues = this.questionValuesWithMapping();
    this.hiddenQuestions = requestForm.sections.edges
      .map(edge => edge.node)
      .reduce((questionsCache, sectionNode) => {
        if (isRuleableHidden(sectionNode, requesterInfo, questionValues, tz)) {
          return {
            ...questionsCache,
            ...sectionNode.questions.edges.reduce(
              (sectionCache, questionEdge) => ({ ...sectionCache, [questionEdge.node.id]: true }),
              {},
            ),
          };
        }

        return {
          ...questionsCache,
          ...sectionNode.questions.edges.reduce(
            (sectionCache, questionEdge) => ({
              ...sectionCache,
              [questionEdge.node.id]: isRuleableHidden(
                questionEdge.node,
                requesterInfo,
                questionValues,
                tz,
              ),
            }),
            {},
          ),
        };
      }, {});
  };

  isQuestionHidden = (questionId: string): boolean => {
    // The cache is empty. Update it before checking
    if (Object.keys(this.hiddenQuestions).length === 0) {
      this.updateHiddenQuestionCache();
    }

    return this.hiddenQuestions[questionId];
  };

  handleSubmit = () => {
    const { submitToken, onSubmit } = this.props;
    const { loading, questionValues, requesterInfo } = this.state;

    if (!onSubmit || loading) return;

    const errors = this.validate();
    const errorExists = Object.values(errors).some(error => error != null);

    this.setState({ errors });

    if (errorExists) return;

    this.setState({ loading: true });

    const [shownQuestionValues, hiddenQuestionValues] = partition(
      [...questionValues],
      questionValue => !this.isQuestionHidden(questionValue.questionId),
    );

    onSubmit({
      requesterInfo,
      questionValues: [
        ...shownQuestionValues,
        ...(submitToken
          ? hiddenQuestionValues.map(question => generateBlankQuestionValue(question.questionId))
          : []),
      ],
    }).catch(error => {
      showModernMutationError(error);

      this.setState({ loading: false });
    });
  };

  handleShouldValidate = (shouldValidate: boolean) => {
    this.setState({ shouldValidate });
  };

  validateRequesterInfo = (key: 'firstName' | 'lastName' | 'email', value: string) => {
    if (!value.trim()) return 'Required';

    if (key === 'email' && !isValidEmail(value)) return 'Invalid email address';

    return null;
  };

  validate = () => {
    const requesterInfo = this.state.requesterInfo;
    const tz = this.props.tz;
    const questionValues = this.questionValuesWithMapping();

    const errors: { [string]: ?string } = this.props.requestForm.sections.edges
      .map(edge => edge.node)
      .reduce((sectionsObj, section) => {
        if (isRuleableHidden(section, requesterInfo, questionValues, tz)) {
          return {
            ...sectionsObj,
            ...section.questions.edges.reduce(
              (questionsObj, questionEdge) => ({ [questionEdge.node.id]: null }),
              {},
            ),
          };
        }

        return {
          ...sectionsObj,
          ...section.questions.edges
            .map(edge => edge.node)
            .reduce((questionsObj, question) => {
              if (this.isQuestionHidden(question.id)) {
                return { ...questionsObj, [question.id]: null };
              }

              const validator = question.mappingCustomField
                ? mappingCustomValueValidators[question.mappingCustomField.kind]
                : question.mapping && mappingValueValidators[question.mapping];
              const value =
                this.state.questionValues.find(
                  questionValue => questionValue.questionId === question.id,
                ) || generateBlankQuestionValue(question.id);

              const {
                selectOptions,
                selectUsers,
                budgetCategories,
                ...restOfQuestionNode
              } = question;

              return {
                ...questionsObj,
                [question.id]:
                  validator &&
                  validator(
                    {
                      ...restOfQuestionNode,
                      required: isQuestionRequired(question, requesterInfo, questionValues, tz),
                      selectOptions: selectOptions.edges.map(edge => edge.node),
                      selectUsers: selectUsers.edges.map(edge => edge.node),
                      budgetCategory: question.budgetCategory,
                      budgetCategories: budgetCategories.edges.map(edge => edge.node),
                      sectionId: section.id,
                    },
                    value,
                  ),
              };
            }, {}),
        };
      }, {});

    if (!this.props.requestSubmission) {
      Object.keys(this.state.requesterInfo).forEach(key => {
        const error = this.validateRequesterInfo(key, this.state.requesterInfo[key]);

        if (error) {
          errors[key] = error;
        }
      });
    }

    return errors;
  };

  handleChangeQuestionValue = (question: QuestionType, value: QuestionValueInputType) => {
    const questionValue = {
      ...generateBlankQuestionValue(question.id),
      ...this.state.questionValues.find(existingValue => existingValue.questionId === question.id),
      ...value,
    };
    const validator = question.mappingCustomField
      ? mappingCustomValueValidators[question.mappingCustomField.kind]
      : question.mapping && mappingValueValidators[question.mapping];
    this.hiddenQuestions = {};

    this.setState(state => ({
      questionValues: [
        ...state.questionValues.filter(({ questionId }) => questionId !== question.id),
        questionValue,
      ],
      errors: {
        ...state.errors,
        [question.id]: state.shouldValidate && validator && validator(question, questionValue),
      },
    }));
  };

  handleChangeRequesterInfo = (requesterInfo: {|
    firstName?: string,
    lastName?: string,
    email?: string,
  |}) => {
    this.hiddenQuestions = {};
    this.setState(state => ({
      requesterInfo: { ...state.requesterInfo, ...requesterInfo },
      errors: {
        ...state.errors,
        ...Object.keys(requesterInfo).reduce(
          (errors, key) => ({
            ...errors,
            [key]: this.validateRequesterInfo(key, requesterInfo[key] || ''),
          }),
          {},
        ),
      },
    }));
  };

  questionValuesWithMapping = (): $ReadOnlyArray<QuestionValueWithMapping> => {
    return this.state.questionValues.reduce((values, questionValue) => {
      const question = this.flatQuestions().find(q => q.id === questionValue.questionId);
      if (question == null) return values;

      return [
        ...values,
        {
          ...questionValue,
          fieldName: question.mapping,
          customField: question.mappingCustomField,
        },
      ];
    }, []);
  };

  filteredQuestions = (section: RelaySectionType): $ReadOnlyArray<QuestionType> => {
    const requesterInfo = this.state.requesterInfo;
    const { onSubmit, tz } = this.props;
    const viewMode = !onSubmit;
    const questionValues = this.questionValuesWithMapping();

    return sortBy(
      section.questions.edges.map(edge => edge.node),
      'order',
    ).reduce((questions, questionNode) => {
      if (!viewMode && this.isQuestionHidden(questionNode.id)) {
        return questions;
      }

      const {
        selectOptions,
        selectUsers,
        budgetCategories,
        rules,
        ...restOfQuestionNode
      } = questionNode;

      return [
        ...questions,
        {
          ...restOfQuestionNode,
          required: isQuestionRequired(questionNode, requesterInfo, questionValues, tz),
          selectOptions: selectOptions.edges.map(edge => edge.node),
          selectUsers: selectUsers.edges.map(edge => edge.node),
          sectionId: section.id,
          budgetCategoryId: questionNode.budgetCategory ? questionNode.budgetCategory.id : null,
          budgetCategories: budgetCategories.edges.map(edge => edge.node),
        },
      ];
    }, []);
  };

  filteredSections = (): $ReadOnlyArray<SectionType> => {
    const requesterInfo = this.state.requesterInfo;
    const { requestForm, onSubmit, tz } = this.props;
    const viewMode = !onSubmit;
    const questionValues = this.questionValuesWithMapping();

    return sortBy(
      requestForm.sections.edges.map(edge => edge.node),
      'order',
    ).reduce((sections, sectionNode) => {
      const isHidden =
        !viewMode && isRuleableHidden(sectionNode, requesterInfo, questionValues, tz);
      if (isHidden) return sections;

      const questions = this.filteredQuestions(sectionNode);
      const hasContent = Boolean(
        questions.length > 0 ||
          sectionNode.title ||
          sectionNode.helpText ||
          (sectionNode.description && sectionNode.description.trim()),
      );
      if (!hasContent) return sections;

      return [
        ...sections,
        {
          id: sectionNode.id,
          title: sectionNode.title,
          helpText: sectionNode.helpText,
          description: sectionNode.description,
          order: sectionNode.order,
          questions,
        },
      ];
    }, []);
  };

  flatQuestions() {
    return this.props.requestForm.sections.edges
      .map(edge => edge.node)
      .reduce(
        (questions, section) => [...questions, ...section.questions.edges.map(edge => edge.node)],
        [],
      );
  }

  render() {
    const { org, requestForm, requestSubmission, me, tz } = this.props;
    const { requesterInfo, questionValues, errors } = this.state;
    const filteredSections = this.filteredSections();
    const viewMode = !this.props.onSubmit;

    const usedDefaultBudgetCategories = this.flatQuestions()
      .filter(question => !this.isQuestionHidden(question.id) && question.budgetCategory)
      .map(question => (question.budgetCategory ? question.budgetCategory.id : null))
      .filter(Boolean);
    const usedSelectedBudgetCategories = questionValues
      .filter(
        answerValue =>
          answerValue.budgetCategoryId && !this.isQuestionHidden(answerValue.questionId),
      )
      .map(answerValue => answerValue.budgetCategoryId)
      .filter(Boolean);

    return (
      <RequestFormContainer>
        <HeaderContainer>
          <StyledFormHeader
            logo={requestForm.logo || org.settings.logo || personalizationDefaults.logoExpanded}
            name={requestForm.name}
            description={requestForm.description || ''}
          />
          {!requestSubmission && !me && (
            <>
              <Divider />
              <RequesterInfo
                {...requesterInfo}
                errors={errors}
                onChange={this.handleChangeRequesterInfo}
                ssoUser={!!this.props.ssoUser}
                readOnly={viewMode}
              />
            </>
          )}
        </HeaderContainer>

        {filteredSections.map(section => (
          <Container key={section.id}>
            <StyledSectionNumber>/{filteredSections.length}</StyledSectionNumber>
            <FormSectionContainer>
              <FormSection
                title={section.title}
                helpText={section.helpText}
                description={section.description}
                isPreview
              />
            </FormSectionContainer>
            {section.questions.map((question: QuestionType) => (
              <Question
                key={question.id}
                question={question}
                currency={org.settings.currency}
                tz={tz}
                value={
                  questionValues.find(questionValue => questionValue.questionId === question.id) ||
                  generateBlankQuestionValue(question.id)
                }
                onChangeValue={this.handleChangeQuestionValue}
                handleShouldValidate={this.handleShouldValidate}
                readOnly={viewMode}
                errors={errors}
                usedBudgetCategoeryIds={[
                  ...usedDefaultBudgetCategories,
                  ...usedSelectedBudgetCategories,
                ]}
              />
            ))}
          </Container>
        ))}
        <SubmitButtonContainer>
          <SubmitButton onClick={this.handleSubmit} disabled={viewMode}>
            {this.state.loading ? <i className="fa fa-circle-o-notch fa-spin" /> : 'Submit'}
          </SubmitButton>
        </SubmitButtonContainer>
      </RequestFormContainer>
    );
  }
}

export default createFragmentContainer(
  RequestForm,
  graphql`
    fragment RequestForm_org on Org {
      settings {
        logo
        currency
      }
    }

    fragment RequestForm_me on User {
      firstName
      lastName
      email
    }

    fragment RequestForm_ssoUser on SSOUser {
      firstName
      lastName
      email
    }

    fragment RequestForm_requestForm on EventRequestForm {
      id
      logo
      name
      description
      sections {
        edges {
          node {
            id
            title
            helpText
            description
            order
            questions {
              edges {
                node {
                  id
                  order
                  mapping
                  mappingCustomField {
                    id
                    label
                    kind
                  }
                  name
                  description
                  required
                  textMinLength
                  textMaxLength
                  fileExtensions
                  expenseName
                  selectShowOtherOption
                  maxSelectionSize
                  budgetCategory {
                    id
                    name
                  }
                  budgetCategories {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  selectOptions {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  selectUsers {
                    edges {
                      node {
                        id
                        firstName
                        lastName
                        email
                        ...QuestionUserSelectOptionRow_user
                      }
                    }
                  }
                  rules {
                    edges {
                      node {
                        id
                        action
                        order
                        customSavedTextFilters {
                          edges {
                            node {
                              id
                              order
                              fieldName
                              values
                              operator
                              customField {
                                id
                                fieldName
                                kind
                                customizableType
                              }
                            }
                          }
                        }
                        customSavedTextareaFilters {
                          edges {
                            node {
                              id
                              order
                              fieldName
                              values
                              operator
                              customField {
                                id
                                fieldName
                                kind
                                customizableType
                              }
                            }
                          }
                        }
                        customSavedLinkFilters {
                          edges {
                            node {
                              id
                              order
                              values
                              operator
                              fieldName
                              customField {
                                id
                                kind
                                fieldName
                                customizableType
                              }
                            }
                          }
                        }
                        customSavedNumberFilters {
                          edges {
                            node {
                              id
                              order
                              minValue
                              maxValue
                              customField {
                                id
                                kind
                                fieldName
                                customizableType
                              }
                            }
                          }
                        }
                        customSavedCurrencyFilters {
                          edges {
                            node {
                              id
                              order
                              minValue
                              maxValue
                              fieldName
                              customField {
                                id
                                kind
                                fieldName
                                customizableType
                              }
                            }
                          }
                        }
                        customSavedDateFilters {
                          edges {
                            node {
                              id
                              order
                              minValue
                              maxValue
                              customField {
                                id
                                kind
                                fieldName
                                customizableType
                              }
                            }
                          }
                        }
                        customSavedBooleanFilters {
                          edges {
                            node {
                              id
                              value
                              order
                              customField {
                                id
                                kind
                                fieldName
                                customizableType
                              }
                            }
                          }
                        }
                        customSavedUserMultiselectFilters {
                          edges {
                            node {
                              id
                              order
                              operator
                              fieldName
                              customField {
                                id
                                kind
                                fieldName
                                customizableType
                              }
                              options {
                                edges {
                                  node {
                                    user {
                                      id
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        customSavedMultiselectFilters {
                          edges {
                            node {
                              id
                              order
                              operator
                              customField {
                                id
                                kind
                                fieldName
                                customizableType
                              }
                              options {
                                edges {
                                  node {
                                    option {
                                      id
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            rules {
              edges {
                node {
                  id
                  action
                  order
                  customSavedTextFilters {
                    edges {
                      node {
                        id
                        order
                        fieldName
                        values
                        operator
                        customField {
                          id
                          fieldName
                          kind
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedTextareaFilters {
                    edges {
                      node {
                        id
                        order
                        fieldName
                        values
                        operator
                        customField {
                          id
                          fieldName
                          kind
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedLinkFilters {
                    edges {
                      node {
                        id
                        order
                        values
                        operator
                        fieldName
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedNumberFilters {
                    edges {
                      node {
                        id
                        order
                        minValue
                        maxValue
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedCurrencyFilters {
                    edges {
                      node {
                        id
                        order
                        minValue
                        maxValue
                        fieldName
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedDateFilters {
                    edges {
                      node {
                        id
                        order
                        minValue
                        maxValue
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedBooleanFilters {
                    edges {
                      node {
                        id
                        value
                        order
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                      }
                    }
                  }
                  customSavedUserMultiselectFilters {
                    edges {
                      node {
                        id
                        order
                        operator
                        fieldName
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                        options {
                          edges {
                            node {
                              user {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  customSavedMultiselectFilters {
                    edges {
                      node {
                        id
                        order
                        operator
                        customField {
                          id
                          kind
                          fieldName
                          customizableType
                        }
                        options {
                          edges {
                            node {
                              option {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    fragment RequestForm_requestSubmission on EventRequestSubmission {
      questionValues {
        questionId
        budgetCategoryId
        textValue
        textareaValue
        numberValue
        dateValue {
          startDate
          endDate
          startDateAllDay
          endDateAllDay
        }
        booleanValue
        selectIdsValue
        filesValue {
          id
          filename
          filetype
          fileurl
          filesize
        }
        locationValue {
          name
          country
          city
          state
          address1
          address2
          postal
          lat
          lng
        }
      }
    }
  `,
);
