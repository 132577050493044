/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { FieldType } from 'utils/customization/types';

import updateEventFloorPlan from 'graph/mutations/event_floor_plan/updateEventFloorPlan';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineExpandableText from 'components/budget/Table/InlineExpandableText';
import InlineTextField from 'components/budget/Table/InlineTextField';

import type { BoothDimensions_event } from './__generated__/BoothDimensions_event.graphql';

class BoothDimensions extends React.PureComponent<{
  event: BoothDimensions_event,
  fieldSettings: FieldType,
  readOnly?: boolean,
  updateColumnWidth: () => void,
}> {
  handleUpdate = (value: ?string) => {
    const { event, updateColumnWidth } = this.props;
    if (value === event.eventFloorPlan.boothDimensions) {
      return;
    }
    updateEventFloorPlan(event.eventFloorPlan.id, { boothDimensions: value }).catch(
      showModernMutationError,
    );
    updateColumnWidth();
  };

  render() {
    const { fieldSettings, event, readOnly } = this.props;
    const boothDimensions = event.eventFloorPlan.boothDimensions;
    if (readOnly) {
      return <InlineExpandableText>{boothDimensions}</InlineExpandableText>;
    }
    return (
      <InlineTextField
        placeholder={fieldSettings.label}
        value={boothDimensions || ''}
        onChange={this.handleUpdate}
      >
        {boothDimensions && <InlineExpandableText>{boothDimensions}</InlineExpandableText>}
      </InlineTextField>
    );
  }
}

export default createFragmentContainer(
  BoothDimensions,
  graphql`
    fragment BoothDimensions_event on Event {
      eventFloorPlan {
        id
        boothDimensions
      }
    }
  `,
);
