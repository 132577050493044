/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  flex-shrink: 0;
  color: ${props => props.theme.mutedTextColor};
  margin-left: 8px;
`;

const Row = styled.div`
  display: flex;
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 13px;
  padding: 3px 5px 3px 10px;
  cursor: default;
`;

const Name = styled.div`
  flex: 1 1 auto;
  width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default class AttachmentUploadingRow extends React.PureComponent<{
  attachment: {
    filename: string,
    filetype: string,
  },
}> {
  render() {
    return (
      <Row>
        <Name>{this.props.attachment.filename}</Name>
        <Icon className="fa fa-fw fa-circle-o-notch fa-spin" />
      </Row>
    );
  }
}
