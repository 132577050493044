/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { OpportunitySalesforceId_opportunity } from './__generated__/OpportunitySalesforceId_opportunity.graphql';
import type { OpportunitySalesforceId_org } from './__generated__/OpportunitySalesforceId_org.graphql';

class OpportunitySalesforceId extends React.Component<{
  org: OpportunitySalesforceId_org,
  opportunity: OpportunitySalesforceId_opportunity,
}> {
  render() {
    const { opportunity, org } = this.props;
    if (opportunity.salesforceId == null || org.salesforceAccount == null) {
      return null;
    }

    return (
      <a
        href={`https://${org.salesforceAccount.host}/${opportunity.salesforceId}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {opportunity.salesforceId}
      </a>
    );
  }
}

export default createFragmentContainer(
  OpportunitySalesforceId,
  graphql`
    fragment OpportunitySalesforceId_org on Org {
      salesforceAccount {
        host
      }
    }
    fragment OpportunitySalesforceId_opportunity on SalesforceOpportunityType {
      salesforceId
    }
  `,
);
