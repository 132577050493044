/* @flow */
import type { RecordSourceSelectorProxy, SelectorData } from 'react-relay';

function mergeUpdaters(
  ...updaters: $ReadOnlyArray<(RecordSourceSelectorProxy, SelectorData) => void>
): (RecordSourceSelectorProxy, SelectorData) => void {
  return (store: RecordSourceSelectorProxy, data: SelectorData) => {
    updaters.forEach(updater => updater(store, data));
  };
}

export default mergeUpdaters;
