/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';

import {
  stringArrayToStringParam,
  stringToStringParam,
} from 'utils/routing/stringifyQueryParamValues';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import AdvancedFilterWrapper from 'components/material/Filters/Advanced/AdvancedFilterWrapper';
import FiltersContent from 'components/material/Filters/Advanced/FiltersContent';

import getRequestInboxFilterFields from './lib/getRequestInboxFilterFields';
import type { ParsedEventRequestsItemFilters } from './lib/parseEventRequestsItemFilters';
import renderRequestInboxFilter from './lib/renderRequestInboxFilter';

import type { EventRequestsFiltersQueryResponse } from './__generated__/EventRequestsFiltersQuery.graphql';

const query = graphql`
  query EventRequestsFiltersQuery {
    org {
      eventRequestForms {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export default class EventRequestsFilters extends React.Component<{
  history: RouterHistory,
  filters: ParsedEventRequestsItemFilters,
}> {
  converterFn = () => {
    return {
      requesterUserIds: stringArrayToStringParam,
      requesterContactIds: stringArrayToStringParam,
      requestedDate: stringToStringParam,
      statuses: stringArrayToStringParam,
      requestFormIds: stringArrayToStringParam,
      requestReviewers: stringArrayToStringParam,
    };
  };

  render() {
    const { filters, history } = this.props;

    const { requesterUserIds, requesterContactIds, ...otherFilters } = filters;
    const requesterFilters = [...(requesterUserIds || []), ...(requesterContactIds || [])];
    const count =
      Object.keys(otherFilters).filter(x => !['sort'].includes(x) && otherFilters[x] != null)
        .length + (requesterFilters.length > 0 ? 1 : 0);

    return (
      <AdvancedFilterWrapper count={count} filters={filters}>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(props: EventRequestsFiltersQueryResponse) => (
            <FiltersContent
              history={history}
              filters={filters}
              filterOptions={getRequestInboxFilterFields()}
              filterRenderer={(...args) =>
                renderRequestInboxFilter(props.org.eventRequestForms, ...args)
              }
              converterFn={this.converterFn}
            />
          )}
        />
      </AdvancedFilterWrapper>
    );
  }
}
