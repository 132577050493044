/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import type { FieldType } from 'utils/customization/types';
import { type CustomFieldInput } from 'utils/customization/types';

import massUpdateContacts from 'graph/mutations/contact/massUpdateContacts';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button, { MinimalButton } from 'components/budget/Button';
import LockIcon from 'components/LockIcon';
import SelectField from 'components/material/SelectField';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import CompanySearch from 'components/Participants/CompanySearch';
import UserSelect, { type User } from 'components/UserSelect';

import { type AvailableContactsType } from './ContactsMassUpdatePanel';
import { type ContactType } from './ContactWindow/ContactTypeSelector';

type CompanyType = ?{|
  id: string,
  name: string,
  salesforceId?: ?string,
|};

const ContactsCountContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

const Row = styled.div`
  position: relative;
  margin-top: 20px;
`;

const CompanySearchRow = styled.div`
  margin-top: 20px;
`;

const Footer = styled.div`
  margin-top: 40px;
  text-align: right;

  > *:last-child {
    margin-left: 20px;
  }
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 2px;
  top: 10px;
`;

export default class ContactsUpdateWindow extends React.PureComponent<
  {
    eventId?: ?string,
    onHide: () => void,
    onUpdateTableColumnWidths: () => void,
    selectedContacts: AvailableContactsType,
    contactFields: $ReadOnlyArray<FieldType>,
  },
  {
    company: ?CompanyType,
    owner: ?User,
    registrationStatusId: ?string,
    attendanceStatusId: ?string,
    selectFields: $ReadOnlyArray<CustomFieldInput>,
    userSelectFields: $ReadOnlyArray<{ customFieldId: string, user: ?User }>,
  },
> {
  state = {
    company: null,
    owner: null,
    selectFields: [],
    userSelectFields: [],
    registrationStatusId: null,
    attendanceStatusId: null,
  };

  handleSave = () => {
    massUpdateContacts({
      eventId: this.props.eventId,
      contactIds: this.props.selectedContacts.map(contact => contact.id),
      company: this.state.company || null,
      ownerId: this.state.owner ? this.state.owner.id : null,
      registrationStatusId: this.state.registrationStatusId,
      attendanceStatusId: this.state.attendanceStatusId,
      customFieldValues: [
        ...this.state.selectFields,
        ...this.state.userSelectFields.map(select => {
          return {
            customFieldId: select.customFieldId,
            userValue: select.user ? select.user.id : null,
          };
        }),
      ],
    })
      .then(() => {
        this.props.onHide();
        this.props.onUpdateTableColumnWidths();
      })
      .catch(err => {
        showModernMutationError(err);
      });
  };

  handleUpdateCompany = (company: ?CompanyType, contactType?: ContactType) => {
    if (
      contactType === 'companies' &&
      (!company || !this.state.company || company.id !== this.state.company.id)
    ) {
      this.setState({ company });
    }
  };

  handleUpdateOwner = (owner: ?User) => {
    this.setState({ owner });
  };

  handleUpdateAttendaceStatus = (attendanceStatusId: ?string) => {
    this.setState({ attendanceStatusId });
  };

  handleUpdateRegistrationStatus = (registrationStatusId: ?string) => {
    this.setState({ registrationStatusId });
  };

  handleUpdateSelectField = (customFieldId: string, selectValue: ?string) => {
    if (selectValue != null) {
      this.setState(state => ({
        selectFields: [
          ...state.selectFields.filter(item => item.customFieldId !== customFieldId),
          { customFieldId, selectValue: selectValue != null ? selectValue : '' },
        ],
      }));
    } else {
      this.setState(state => ({
        selectFields: state.selectFields.filter(item => item.customFieldId !== customFieldId),
      }));
    }
  };

  handleUpdateUserSelectField = (customFieldId: string, user: ?User) => {
    if (user != null) {
      this.setState(state => ({
        userSelectFields: [
          ...state.userSelectFields.filter(item => item.customFieldId !== customFieldId),
          { customFieldId, user },
        ],
      }));
    } else {
      this.setState(state => ({
        userSelectFields: state.userSelectFields.filter(
          item => item.customFieldId !== customFieldId,
        ),
      }));
    }
  };

  render() {
    const { onHide, selectedContacts, contactFields, eventId } = this.props;
    const company = this.state.company;

    return (
      <Window onHide={onHide} size={438}>
        <WindowHeader>
          <WindowTitle>
            Update Info
            <ContactsCountContainer>
              {`${selectedContacts.length} ${
                selectedContacts.length === 1 ? ' contact' : ' contacts'
              } selected`}
            </ContactsCountContainer>
          </WindowTitle>
          <WindowClose onClick={onHide} />
        </WindowHeader>
        <WindowContent>
          {contactFields
            .filter(
              field =>
                field.fieldName === 'owner_id' ||
                field.fieldName === 'company_id' ||
                field.fieldName === 'registration_status_id' ||
                field.fieldName === 'attendance_status_id' ||
                field.kind === 'SELECT' ||
                field.kind === 'USER_SELECT',
            )
            .map(field => {
              const allowChangingValue = selectedContacts.every(
                contact =>
                  contact.salesforceSyncAs != null &&
                  field[
                    `restrictChangingValueFor${contact.salesforceSyncAs.replace(/^(.)|\s+(.)/g, c =>
                      c.toUpperCase(),
                    )}`
                  ] === false,
              );
              if (field.fieldName === 'owner_id') {
                return (
                  <Row key={field.id}>
                    <UserSelect
                      label={field.label}
                      user={this.state.owner}
                      onSelect={this.handleUpdateOwner}
                      clearable
                      disabled={!allowChangingValue}
                    />
                    {!allowChangingValue && <StyledLockIcon kind={field.kind} label="Salesforce" />}
                  </Row>
                );
              }
              if (field.fieldName === 'company_id') {
                return (
                  <CompanySearchRow key={field.id}>
                    <CompanySearch
                      fromWindow={eventId ? 'event contacts' : 'org contacts'}
                      label={field.label}
                      name={field.fieldName || ''}
                      onSelect={this.handleUpdateCompany}
                      company={company ? { id: company.id, name: company.name || '' } : null}
                      salesforceId={company && company.salesforceId}
                      clearable
                      stateful
                    />
                  </CompanySearchRow>
                );
              }
              if (field.fieldName === 'registration_status_id') {
                return (
                  <Row key={field.id}>
                    <SelectField
                      label={field.label}
                      value={this.state.registrationStatusId}
                      onChange={this.handleUpdateRegistrationStatus}
                      options={field.options}
                      clearable={!field.required}
                    />
                  </Row>
                );
              }
              if (field.fieldName === 'attendance_status_id') {
                return (
                  <Row key={field.id}>
                    <SelectField
                      label={field.label}
                      value={this.state.attendanceStatusId}
                      onChange={this.handleUpdateAttendaceStatus}
                      options={field.options}
                      clearable={!field.required}
                    />
                  </Row>
                );
              }
              if (field.kind === 'SELECT') {
                const selectField = this.state.selectFields.find(
                  customSelect => customSelect.customFieldId === field.id,
                );
                return (
                  <Row key={field.id}>
                    <SelectField
                      label={field.label}
                      value={selectField ? selectField.selectValue : null}
                      onChange={value => {
                        this.handleUpdateSelectField(field.id, value);
                      }}
                      options={field.options}
                      disabled={!allowChangingValue}
                      searchable
                      clearable
                    />
                    {!allowChangingValue && <StyledLockIcon kind={field.kind} label="Salesforce" />}
                  </Row>
                );
              }

              const userSelectField = this.state.userSelectFields.find(
                customUserSelect => customUserSelect.customFieldId === field.id,
              );
              return (
                <Row key={field.id}>
                  <UserSelect
                    label={field.label}
                    user={userSelectField ? userSelectField.user : null}
                    onSelect={selectUser => {
                      this.handleUpdateUserSelectField(field.id, selectUser);
                    }}
                    clearable
                    disabled={!allowChangingValue}
                  />
                  {!allowChangingValue && <StyledLockIcon kind={field.kind} label="Salesforce" />}
                </Row>
              );
            })}

          <Footer>
            <MinimalButton label="Cancel" onClick={onHide} />
            <Button onClick={this.handleSave}>Update</Button>
          </Footer>
        </WindowContent>
      </Window>
    );
  }
}
