/**
 * @flow
 * @relayHash 648c4305d071b9c88e0400e8277782a8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamBudgeting_budgetPeriod$ref = any;
export type CreateBudgetPeriodInput = {
  clientMutationId?: ?string,
  year: number,
};
export type createBudgetPeriodMutationVariables = {|
  input: CreateBudgetPeriodInput
|};
export type createBudgetPeriodMutationResponse = {|
  +createBudgetPeriod: ?{|
    +budgetPeriodEdge: {|
      +__typename: string,
      +node: ?{|
        +id: string,
        +period: string,
        +year: number,
        +$fragmentRefs: TeamBudgeting_budgetPeriod$ref,
      |},
    |}
  |}
|};
export type createBudgetPeriodMutation = {|
  variables: createBudgetPeriodMutationVariables,
  response: createBudgetPeriodMutationResponse,
|};
*/


/*
mutation createBudgetPeriodMutation(
  $input: CreateBudgetPeriodInput!
) {
  createBudgetPeriod(input: $input) {
    budgetPeriodEdge {
      __typename
      node {
        id
        period
        year
        ...TeamBudgeting_budgetPeriod
      }
    }
  }
}

fragment TeamBudgeting_budgetPeriod on BudgetPeriod {
  id
  period
  startDate
  endDate
  teams {
    edges {
      amount
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateBudgetPeriodInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateBudgetPeriodInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "period",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "year",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createBudgetPeriodMutation",
  "id": null,
  "text": "mutation createBudgetPeriodMutation(\n  $input: CreateBudgetPeriodInput!\n) {\n  createBudgetPeriod(input: $input) {\n    budgetPeriodEdge {\n      __typename\n      node {\n        id\n        period\n        year\n        ...TeamBudgeting_budgetPeriod\n      }\n    }\n  }\n}\n\nfragment TeamBudgeting_budgetPeriod on BudgetPeriod {\n  id\n  period\n  startDate\n  endDate\n  teams {\n    edges {\n      amount\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createBudgetPeriodMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createBudgetPeriod",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateBudgetPeriodPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetPeriodEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetPeriodEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "BudgetPeriod",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "FragmentSpread",
                    "name": "TeamBudgeting_budgetPeriod",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createBudgetPeriodMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createBudgetPeriod",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateBudgetPeriodPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "budgetPeriodEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "BudgetPeriodEdge",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "BudgetPeriod",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "teams",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BudgetPeriodTeamConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BudgetPeriodTeamEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "amount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Team",
                            "plural": false,
                            "selections": [
                              v3,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3a7b5b3440ab6819868d30f0f16e5def';
module.exports = node;
