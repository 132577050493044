/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { createMoment } from 'utils/Date';

import Button from 'components/material/Button';
import SelectField from 'components/material/SelectField';

const StyledSelect = styled(SelectField)`
  width: 150px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  @media (${props => props.theme.mobileOnly}) {
    padding: 0 10px;
    flex-wrap: wrap;
    ${StyledSelect} {
      width: 100%;
      margin-top: 10px;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-right: 5px;
`;

export default class TasksCalendarHeader extends React.PureComponent<{
  date: Date,
  onChange: moment => void,
  tz: string,
}> {
  handleSubtractMonth = () => {
    console.log('called add month');
    const currentMonth = createMoment(this.props.tz)(this.props.date);
    const previousMonth = currentMonth.clone().subtract(1, 'months');
    this.props.onChange(previousMonth);
  };

  handleAddMonth = () => {
    const currentMonth = createMoment(this.props.tz)(this.props.date);
    this.props.onChange(currentMonth.add(1, 'month'));
  };

  handleCurrentMonth = () => {
    this.props.onChange(createMoment(this.props.tz)());
  };

  handleMonthChange = (month: ?number) => {
    this.props.onChange(createMoment(this.props.tz)(month));
  };

  render() {
    const { date } = this.props;
    const month = createMoment(this.props.tz)(date).startOf('month');
    const now = createMoment(this.props.tz)();

    const startDate = createMoment(this.props.tz)(date).subtract(5, 'months');
    const options = new Array(12).fill(null).map((_, i) => {
      const selectedMonth = startDate.clone().add(i, 'month').startOf('month');

      return {
        label: selectedMonth.format('MMMM YYYY'),
        value: selectedMonth.valueOf(),
      };
    });

    return (
      <Container>
        <StyledButton
          label="Today"
          onClick={this.handleCurrentMonth}
          disabled={month.isSame(now, 'month')}
          outline
        />
        <StyledButton icon="caret-left" onClick={this.handleSubtractMonth} outline />
        <StyledButton icon="caret-right" onClick={this.handleAddMonth} outline />
        <StyledSelect value={month.valueOf()} onChange={this.handleMonthChange} options={options} />
      </Container>
    );
  }
}
