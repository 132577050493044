/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { flatMap, sortBy } from 'lodash';

import { MinimalButton } from 'components/budget/Button';
import FilterSelectorRow, {
  type FilterItem,
  getFieldName,
} from 'components/material/Filters/FilterSelectorRow';

import getFilterItemValueForSave from '../lib/getFilterItemValueForSave';
import validateFilterItem from '../lib/validateFilterItem';

import type { ScheduledEmailFilters_eventEmailTemplate } from './__generated__/ScheduledEmailFilters_eventEmailTemplate.graphql';
import type { ScheduledEmailFilters_org } from './__generated__/ScheduledEmailFilters_org.graphql';

const Header = styled.div`
  margin: 10px 0;
`;

const FiltersContainer = styled.div`
  margin-bottom: 20px;
  padding-left: 15px;
`;

const EmptyFilters = styled.div`
  margin-bottom: 10px;
  padding: 15px 10px 15px 20px;
  border: 1px dashed ${props => props.theme.mutedTextColor};
  border-radius: 6px;
  text-align: center;
  color: ${props => props.theme.mutedTextColor};
`;

export const staticFields = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  company_name: 'Company - Name',
};

const AddFilterButton = styled(MinimalButton)`
  color: #3ba9da;
`;

export const excludedFilters = ['description', 'twitter', 'linkedin', 'zip', 'street'];

class ScheduledEmailFilters extends React.Component<
  {
    org: ScheduledEmailFilters_org,
    eventEmailTemplate: ?ScheduledEmailFilters_eventEmailTemplate,
    filtersRef: { current: ?() => $ReadOnlyArray<FilterItem> },
    tz: string,
  },
  {
    filters: $ReadOnlyArray<FilterItem>,
    hiddenFilters: $ReadOnlyArray<FilterItem>,
  },
> {
  state = {
    filters: this.getSavedFilterData(),
    hiddenFilters: [],
  };

  componentDidMount() {
    this.props.filtersRef.current = this.handleSaveFilters;
  }

  getFilterFields() {
    const { org } = this.props;

    const staticFilters: any = Object.keys(staticFields)
      .filter(key => key !== 'company_name')
      .map(key => ({
        fieldName: key,
        label: staticFields[key],
      }));

    const allFilterFields = org.customFields.edges.map(edge => edge.node);

    const contactFields = sortBy(
      allFilterFields.filter(field => field.customizableType === 'CONTACT'),
      'order',
    );

    const eventContactFields = sortBy(
      allFilterFields.filter(field => field.customizableType === 'EVENTCONTACT'),
      'order',
    );

    const companyFields = sortBy(
      allFilterFields.filter(field => field.customizableType === 'COMPANY'),
      'order',
    ).map(field => ({ ...field, label: `Company - ${field.label}` }));

    // $FlowFixMe https://github.com/flow-typed/flow-typed/issues/2463
    const contactWithCompanyFields = flatMap(contactFields, field => {
      if (field.fieldName === 'company_id') {
        return [{ fieldName: 'company_name', label: staticFields.company_name }, ...companyFields];
      }
      return [field];
    });

    return [...staticFilters, ...contactWithCompanyFields, ...eventContactFields].filter(
      filterField => {
        if (filterField.fieldName && excludedFilters.includes(filterField.fieldName)) {
          return false;
        }

        return true;
      },
    );
  }

  getSavedFilterData() {
    const { org, eventEmailTemplate } = this.props;

    if (!eventEmailTemplate) {
      const filterFields = this.getFilterFields();

      const regField = filterFields.find(
        filterField => filterField.fieldName === 'registration_status_id',
      );

      if (regField == null) {
        return [];
      }

      // If registration status is enabled option always available, just some flow checks
      const defaultOption = regField.options
        ? regField.options.edges
            .map(edge => edge.node)
            .find(option => option.name === 'Registered' && !option.editable)
        : null;

      return [
        {
          customField: regField,
          optionIds: defaultOption ? [defaultOption.id] : [],
          operator: 'equal',
          errors: {},
        },
      ];
    }

    return sortBy(
      [
        ...eventEmailTemplate.customSavedTextFilters.edges.map(edge => edge.node),
        ...eventEmailTemplate.customSavedTextareaFilters.edges.map(edge => edge.node),
        ...eventEmailTemplate.customSavedLinkFilters.edges.map(edge => edge.node),
        ...eventEmailTemplate.customSavedNumberFilters.edges.map(edge => edge.node),
        ...eventEmailTemplate.customSavedCurrencyFilters.edges.map(edge => edge.node),
        ...eventEmailTemplate.customSavedBooleanFilters.edges.map(edge => edge.node),
        ...eventEmailTemplate.customSavedMultiselectFilters.edges.map(edge => edge.node),
        ...eventEmailTemplate.customSavedDateFilters.edges.map(edge => edge.node),
        ...eventEmailTemplate.customSavedUserMultiselectFilters.edges.map(edge => edge.node),
      ].map((filter: any) => {
        const customFieldNode = filter.customField
          ? org.customFields.edges.find(
              ({ node: customField }) =>
                filter.customField && customField.id === filter.customField.id,
            )
          : null;
        return {
          ...filter,
          errors: {},
          customField: customFieldNode ? customFieldNode.node : null,
          optionIds: filter.options
            ? filter.options.edges
                .map(edge => (edge.node && edge.node.option ? edge.node.option.id : null))
                .filter(Boolean)
            : [],
          users: filter.options
            ? filter.options.edges.map(edge => edge.node.user).filter(Boolean)
            : [],
        };
      }),
      'order',
    );
  }

  handleAddNewFilter = () => {
    this.setState(prevState => ({
      filters: [...prevState.filters, { errors: {} }],
    }));
  };

  handleUpdate = (filterItem, oldFilterItem, index) => {
    this.setState(prevState => {
      const appliedFilters = [
        ...prevState.filters.slice(0, index),
        filterItem,
        ...prevState.filters.slice(index + 1),
      ];

      const fixedHiddenFilters = prevState.hiddenFilters.filter(
        item =>
          (!filterItem.customField && item.fieldName !== filterItem.fieldName) ||
          (filterItem.customField &&
            (!item.customField || item.customField.id !== filterItem.customField.id)),
      );

      return {
        filters: appliedFilters,
        hiddenFilters:
          !oldFilterItem || !oldFilterItem.id
            ? fixedHiddenFilters
            : [...fixedHiddenFilters, oldFilterItem],
      };
    });
  };

  handleRemove = (index: number) => {
    const filterItem = this.state.filters[index];

    this.setState(prevState => ({
      filters: prevState.filters.filter((_, currentIndex) => currentIndex !== index),
      hiddenFilters: filterItem.id
        ? [...prevState.hiddenFilters, filterItem]
        : prevState.hiddenFilters,
    }));
  };

  handleSaveFilters = () => {
    this.setState(prevState => ({
      filters: prevState.filters.map(filterItem => ({
        ...filterItem,
        errors: validateFilterItem(filterItem),
      })),
    }));

    const filtersToSave = this.state.filters.map((filterItem, index) => {
      return {
        order: index + 1,
        operator: filterItem.operator,
        customFieldId: filterItem.customField ? filterItem.customField.id : null,
        fieldName: getFieldName(filterItem),
        errors: validateFilterItem(filterItem),
        ...getFilterItemValueForSave(filterItem),
      };
    });

    const filtersToRemove = this.state.hiddenFilters.map(filterItem => ({
      customFieldId: filterItem.customField ? filterItem.customField.id : null,
      fieldName: getFieldName(filterItem),
      errors: {},
      textParam: null,
      textareaParam: null,
      linkParam: null,
      numberParam: null,
      dateParam: null,
      booleanParam: null,
      optionIds: null,
    }));

    return [...filtersToSave, ...filtersToRemove];
  };

  render() {
    const usedFilters = this.state.filters;
    const filterFields = this.getFilterFields();

    return (
      <>
        <Header>Filter email recipients</Header>
        {usedFilters.length === 0 && (
          <EmptyFilters>
            There are no filters applied. Everyone in the event is going to receive this email.
          </EmptyFilters>
        )}
        {usedFilters.length > 0 && (
          <FiltersContainer>
            {usedFilters.map((filterItem, index) => {
              return (
                <FilterSelectorRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  usedFilters={this.state.filters}
                  filterFields={filterFields}
                  filterItem={filterItem}
                  onUpdate={(item, oldItem) => this.handleUpdate(item, oldItem, index)}
                  onRemove={() => this.handleRemove(index)}
                  tz={this.props.tz}
                  conditionStartLabel="Where"
                />
              );
            })}
          </FiltersContainer>
        )}
        {usedFilters.length < filterFields.length && (
          <AddFilterButton onClick={this.handleAddNewFilter} label="+ Add a Filter" />
        )}
      </>
    );
  }
}

export default createFragmentContainer(ScheduledEmailFilters, {
  org: graphql`
    fragment ScheduledEmailFilters_org on Org {
      customFields(customizableType: [CONTACT, COMPANY, EVENTCONTACT]) {
        edges {
          node {
            id
            label
            fieldName
            customizableType
            order
            kind
            options {
              edges {
                node {
                  id
                  name
                  editable
                }
              }
            }
          }
        }
      }
    }
  `,
  eventEmailTemplate: graphql`
    fragment ScheduledEmailFilters_eventEmailTemplate on EventEmailTemplate {
      id
      customSavedTextFilters {
        edges {
          node {
            id
            order
            fieldName
            values
            operator
            customField {
              id
            }
          }
        }
      }
      customSavedTextareaFilters {
        edges {
          node {
            id
            order
            fieldName
            values
            operator
            customField {
              id
            }
          }
        }
      }
      customSavedLinkFilters {
        edges {
          node {
            id
            order
            values
            operator
            customField {
              id
            }
          }
        }
      }
      customSavedNumberFilters {
        edges {
          node {
            id
            order
            minValue
            maxValue
            customField {
              id
            }
          }
        }
      }
      customSavedCurrencyFilters {
        edges {
          node {
            id
            order
            minValue
            maxValue
            customField {
              id
            }
          }
        }
      }
      customSavedDateFilters {
        edges {
          node {
            id
            order
            minValue
            maxValue
            customField {
              id
            }
          }
        }
      }
      customSavedBooleanFilters {
        edges {
          node {
            id
            value
            order
            customField {
              id
            }
          }
        }
      }
      customSavedUserMultiselectFilters {
        edges {
          node {
            id
            order
            operator
            customField {
              id
            }
            options {
              edges {
                node {
                  user {
                    id
                    email
                    firstName
                    lastName
                    ...MaterialAvatar_user
                  }
                }
              }
            }
          }
        }
      }
      customSavedMultiselectFilters {
        edges {
          node {
            id
            order
            operator
            options {
              edges {
                node {
                  option {
                    id
                  }
                }
              }
            }
            customField {
              id
            }
          }
        }
      }
    }
  `,
});
