import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import SchedulesTimelineItem from './SchedulesTimelineItem';

import { createMoment } from 'utils/Date';

class SchedulesTimelineColumn extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        start_time: PropTypes.string.isRequired,
        end_time: PropTypes.string,
        all_day: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    time_zone: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    start_time: PropTypes.string,
    all_day_count: PropTypes.number.isRequired,
    hour_height: PropTypes.number.isRequired,
  };
  render() {
    const {
      items, time_zone, color, start_time, all_day_count, hour_height,
    } = this.props;
    const moment = createMoment(time_zone);
    const startTimeMoment = moment(start_time);

    let depth = 0;
    return (
      <div>
        <div style={{ height: `${all_day_count * (hour_height / 2)}px` }}>
          {items.filter(item => item.all_day).map(item => (
            <SchedulesTimelineItem
              styles={{
                height: `${hour_height / 2}px`,
                position: 'relative',
                borderColor: color,
              }}
              key={item.id}
              item={item}
              time_zone={time_zone}
            />
          ))}
        </div>
        <div style={{ position: 'relative' }}>
          {items.filter(item => !item.all_day).map((item, index, array) => {
            const start = moment(item.start_time);
            const end = moment(item.end_time || item.start_time);
            const heightDiff = (end.getTime() - start.getTime()) / 60;
            const topDiff = (start.getTime() - startTimeMoment.getTime()) / 60;

            const prev = array[index - 1];
            if (prev) {
              if (start.getTime() < moment(prev.end_time || prev.start_time).getTime()) {
                depth += 1;
              } else {
                depth = 0;
              }
            }

            return (
              <SchedulesTimelineItem
                styles={{
                  height: `${heightDiff * hour_height}px`,
                  top: `${topDiff * hour_height}px`,
                  borderColor: color,
                  left: `${depth * 15}%`,
                }}
                key={item.id}
                item={item}
                time_zone={time_zone}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(
  SchedulesTimelineColumn,
  graphql`
    fragment SchedulesTimelineColumn_items on ScheduleItem @relay(plural: true) {
      id
      start_time
      end_time
      all_day
      ...SchedulesTimelineItem_item
    }
  `,
);
