/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type QuestionUserSelectOptionRow_user$ref = any;
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
export type EventRequestQuestionFileExtension = "CSV" | "DOC" | "JPG" | "PDF" | "PNG" | "XLSX";
export type EventRequestQuestionMapping = "ATTACHMENTS" | "BOOTH" | "BOOTH_DIMENSIONS" | "BUDGETED_AMOUNT" | "DATE" | "DESCRIPTION" | "EXPENSE" | "FLOOR_PLAN" | "LEADER" | "LOCATION" | "NAME" | "NOTE" | "PLANNED_BUDGET" | "WEBSITE";
export type FormRuleAction = "HIDE" | "HIDE_BY_DEFAULT" | "MAKE_OPTIONAL" | "MAKE_REQUIRED" | "SHOW";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RequestForm_requestForm$ref: FragmentReference;
export type RequestForm_requestForm = {|
  +id: string,
  +logo: ?string,
  +name: string,
  +description: ?string,
  +sections: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +title: ?string,
        +helpText: ?string,
        +description: ?string,
        +order: number,
        +questions: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +order: number,
              +mapping: ?EventRequestQuestionMapping,
              +mappingCustomField: ?{|
                +id: string,
                +label: string,
                +kind: CustomFieldKindType,
              |},
              +name: string,
              +description: ?string,
              +required: boolean,
              +textMinLength: ?number,
              +textMaxLength: ?number,
              +fileExtensions: $ReadOnlyArray<EventRequestQuestionFileExtension>,
              +expenseName: ?string,
              +selectShowOtherOption: ?boolean,
              +maxSelectionSize: ?number,
              +budgetCategory: ?{|
                +id: string,
                +name: string,
              |},
              +budgetCategories: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +name: string,
                  |}
                |}>
              |},
              +selectOptions: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +name: string,
                  |}
                |}>
              |},
              +selectUsers: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +firstName: string,
                    +lastName: string,
                    +email: string,
                    +$fragmentRefs: QuestionUserSelectOptionRow_user$ref,
                  |}
                |}>
              |},
              +rules: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +action: FormRuleAction,
                    +order: number,
                    +customSavedTextFilters: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +id: string,
                          +order: number,
                          +fieldName: ?string,
                          +values: ?$ReadOnlyArray<string>,
                          +operator: Operator,
                          +customField: ?{|
                            +id: string,
                            +fieldName: ?string,
                            +kind: CustomFieldKindType,
                            +customizableType: CustomizableType,
                          |},
                        |}
                      |}>
                    |},
                    +customSavedTextareaFilters: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +id: string,
                          +order: number,
                          +fieldName: ?string,
                          +values: ?$ReadOnlyArray<string>,
                          +operator: Operator,
                          +customField: ?{|
                            +id: string,
                            +fieldName: ?string,
                            +kind: CustomFieldKindType,
                            +customizableType: CustomizableType,
                          |},
                        |}
                      |}>
                    |},
                    +customSavedLinkFilters: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +id: string,
                          +order: number,
                          +values: ?$ReadOnlyArray<string>,
                          +operator: Operator,
                          +fieldName: ?string,
                          +customField: ?{|
                            +id: string,
                            +kind: CustomFieldKindType,
                            +fieldName: ?string,
                            +customizableType: CustomizableType,
                          |},
                        |}
                      |}>
                    |},
                    +customSavedNumberFilters: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +id: string,
                          +order: number,
                          +minValue: ?number,
                          +maxValue: ?number,
                          +customField: {|
                            +id: string,
                            +kind: CustomFieldKindType,
                            +fieldName: ?string,
                            +customizableType: CustomizableType,
                          |},
                        |}
                      |}>
                    |},
                    +customSavedCurrencyFilters: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +id: string,
                          +order: number,
                          +minValue: ?number,
                          +maxValue: ?number,
                          +fieldName: ?string,
                          +customField: ?{|
                            +id: string,
                            +kind: CustomFieldKindType,
                            +fieldName: ?string,
                            +customizableType: CustomizableType,
                          |},
                        |}
                      |}>
                    |},
                    +customSavedDateFilters: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +id: string,
                          +order: number,
                          +minValue: ?any,
                          +maxValue: ?any,
                          +customField: {|
                            +id: string,
                            +kind: CustomFieldKindType,
                            +fieldName: ?string,
                            +customizableType: CustomizableType,
                          |},
                        |}
                      |}>
                    |},
                    +customSavedBooleanFilters: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +id: string,
                          +value: boolean,
                          +order: number,
                          +customField: {|
                            +id: string,
                            +kind: CustomFieldKindType,
                            +fieldName: ?string,
                            +customizableType: CustomizableType,
                          |},
                        |}
                      |}>
                    |},
                    +customSavedUserMultiselectFilters: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +id: string,
                          +order: number,
                          +operator: Operator,
                          +fieldName: ?string,
                          +customField: ?{|
                            +id: string,
                            +kind: CustomFieldKindType,
                            +fieldName: ?string,
                            +customizableType: CustomizableType,
                          |},
                          +options: {|
                            +edges: $ReadOnlyArray<{|
                              +node: {|
                                +user: {|
                                  +id: string
                                |}
                              |}
                            |}>
                          |},
                        |}
                      |}>
                    |},
                    +customSavedMultiselectFilters: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +id: string,
                          +order: number,
                          +operator: Operator,
                          +customField: {|
                            +id: string,
                            +kind: CustomFieldKindType,
                            +fieldName: ?string,
                            +customizableType: CustomizableType,
                          |},
                          +options: {|
                            +edges: $ReadOnlyArray<{|
                              +node: {|
                                +option: {|
                                  +id: string
                                |}
                              |}
                            |}>
                          |},
                        |}
                      |}>
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
        +rules: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +action: FormRuleAction,
              +order: number,
              +customSavedTextFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +order: number,
                    +fieldName: ?string,
                    +values: ?$ReadOnlyArray<string>,
                    +operator: Operator,
                    +customField: ?{|
                      +id: string,
                      +fieldName: ?string,
                      +kind: CustomFieldKindType,
                      +customizableType: CustomizableType,
                    |},
                  |}
                |}>
              |},
              +customSavedTextareaFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +order: number,
                    +fieldName: ?string,
                    +values: ?$ReadOnlyArray<string>,
                    +operator: Operator,
                    +customField: ?{|
                      +id: string,
                      +fieldName: ?string,
                      +kind: CustomFieldKindType,
                      +customizableType: CustomizableType,
                    |},
                  |}
                |}>
              |},
              +customSavedLinkFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +order: number,
                    +values: ?$ReadOnlyArray<string>,
                    +operator: Operator,
                    +fieldName: ?string,
                    +customField: ?{|
                      +id: string,
                      +kind: CustomFieldKindType,
                      +fieldName: ?string,
                      +customizableType: CustomizableType,
                    |},
                  |}
                |}>
              |},
              +customSavedNumberFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +order: number,
                    +minValue: ?number,
                    +maxValue: ?number,
                    +customField: {|
                      +id: string,
                      +kind: CustomFieldKindType,
                      +fieldName: ?string,
                      +customizableType: CustomizableType,
                    |},
                  |}
                |}>
              |},
              +customSavedCurrencyFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +order: number,
                    +minValue: ?number,
                    +maxValue: ?number,
                    +fieldName: ?string,
                    +customField: ?{|
                      +id: string,
                      +kind: CustomFieldKindType,
                      +fieldName: ?string,
                      +customizableType: CustomizableType,
                    |},
                  |}
                |}>
              |},
              +customSavedDateFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +order: number,
                    +minValue: ?any,
                    +maxValue: ?any,
                    +customField: {|
                      +id: string,
                      +kind: CustomFieldKindType,
                      +fieldName: ?string,
                      +customizableType: CustomizableType,
                    |},
                  |}
                |}>
              |},
              +customSavedBooleanFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +value: boolean,
                    +order: number,
                    +customField: {|
                      +id: string,
                      +kind: CustomFieldKindType,
                      +fieldName: ?string,
                      +customizableType: CustomizableType,
                    |},
                  |}
                |}>
              |},
              +customSavedUserMultiselectFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +order: number,
                    +operator: Operator,
                    +fieldName: ?string,
                    +customField: ?{|
                      +id: string,
                      +kind: CustomFieldKindType,
                      +fieldName: ?string,
                      +customizableType: CustomizableType,
                    |},
                    +options: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +user: {|
                            +id: string
                          |}
                        |}
                      |}>
                    |},
                  |}
                |}>
              |},
              +customSavedMultiselectFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +order: number,
                    +operator: Operator,
                    +customField: {|
                      +id: string,
                      +kind: CustomFieldKindType,
                      +fieldName: ?string,
                      +customizableType: CustomizableType,
                    |},
                    +options: {|
                      +edges: $ReadOnlyArray<{|
                        +node: {|
                          +option: {|
                            +id: string
                          |}
                        |}
                      |}>
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: RequestForm_requestForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "kind",
  "args": null,
  "storageKey": null
},
v5 = [
  v0,
  v1
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "values",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "operator",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "customizableType",
  "args": null,
  "storageKey": null
},
v10 = [
  v0,
  v3,
  v6,
  v7,
  v8,
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "customField",
    "storageKey": null,
    "args": null,
    "concreteType": "CustomFieldType",
    "plural": false,
    "selections": [
      v0,
      v6,
      v4,
      v9
    ]
  }
],
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": [
    v0,
    v4,
    v6,
    v9
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "minValue",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "maxValue",
  "args": null,
  "storageKey": null
},
v14 = [
  v0,
  v3,
  v12,
  v13,
  v11
],
v15 = [
  v0
],
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "rules",
  "storageKey": null,
  "args": null,
  "concreteType": "FormRuleRequiredConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "FormRuleRequiredEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "FormRule",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "action",
              "args": null,
              "storageKey": null
            },
            v3,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customSavedTextFilters",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedTextFilterTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedTextFilterTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedTextFilterType",
                      "plural": false,
                      "selections": v10
                    }
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customSavedTextareaFilters",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedTextareaFilterTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedTextareaFilterTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedTextareaFilterType",
                      "plural": false,
                      "selections": v10
                    }
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customSavedLinkFilters",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedLinkFilterTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedLinkFilterTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedLinkFilterType",
                      "plural": false,
                      "selections": [
                        v0,
                        v3,
                        v7,
                        v8,
                        v6,
                        v11
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customSavedNumberFilters",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedNumberFilterTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedNumberFilterTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedNumberFilterType",
                      "plural": false,
                      "selections": v14
                    }
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customSavedCurrencyFilters",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedCurrencyFilterTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedCurrencyFilterTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedCurrencyFilterType",
                      "plural": false,
                      "selections": [
                        v0,
                        v3,
                        v12,
                        v13,
                        v6,
                        v11
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customSavedDateFilters",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedDateFilterTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedDateFilterTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedDateFilterType",
                      "plural": false,
                      "selections": v14
                    }
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customSavedBooleanFilters",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedBooleanFilterTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedBooleanFilterTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedBooleanFilterType",
                      "plural": false,
                      "selections": [
                        v0,
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "value",
                          "args": null,
                          "storageKey": null
                        },
                        v3,
                        v11
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customSavedUserMultiselectFilters",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedUserMultiselectFilterType",
                      "plural": false,
                      "selections": [
                        v0,
                        v3,
                        v8,
                        v6,
                        v11,
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "options",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredConnection",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "edges",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedUserMultiselectFilterOptionTypeRequiredEdge",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "node",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedUserMultiselectFilterOptionType",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "user",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "User",
                                      "plural": false,
                                      "selections": v15
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customSavedMultiselectFilters",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomSavedMultiselectFilterTypeRequiredConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomSavedMultiselectFilterTypeRequiredEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomSavedMultiselectFilterType",
                      "plural": false,
                      "selections": [
                        v0,
                        v3,
                        v8,
                        v11,
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "options",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredConnection",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "edges",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedMultiselectFilterOptionTypeRequiredEdge",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "node",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedMultiselectFilterOptionType",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "option",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomFieldOptionType",
                                      "plural": false,
                                      "selections": v15
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "RequestForm_requestForm",
  "type": "EventRequestForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logo",
      "args": null,
      "storageKey": null
    },
    v1,
    v2,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sections",
      "storageKey": null,
      "args": null,
      "concreteType": "EventRequestSectionRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "EventRequestSectionRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "EventRequestSection",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "title",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "helpText",
                  "args": null,
                  "storageKey": null
                },
                v2,
                v3,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "questions",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "EventRequestQuestionRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "EventRequestQuestionRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "EventRequestQuestion",
                          "plural": false,
                          "selections": [
                            v0,
                            v3,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "mapping",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "mappingCustomField",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomFieldType",
                              "plural": false,
                              "selections": [
                                v0,
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "label",
                                  "args": null,
                                  "storageKey": null
                                },
                                v4
                              ]
                            },
                            v1,
                            v2,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "required",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "textMinLength",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "textMaxLength",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "fileExtensions",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "expenseName",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "selectShowOtherOption",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "maxSelectionSize",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "budgetCategory",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "BudgetCategory",
                              "plural": false,
                              "selections": v5
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "budgetCategories",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "BudgetCategoryRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "BudgetCategoryRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "BudgetCategory",
                                      "plural": false,
                                      "selections": v5
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "selectOptions",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomFieldOptionTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomFieldOptionTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomFieldOptionType",
                                      "plural": false,
                                      "selections": v5
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "selectUsers",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "UserRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "UserRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "User",
                                      "plural": false,
                                      "selections": [
                                        v0,
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "firstName",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "lastName",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "email",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "FragmentSpread",
                                          "name": "QuestionUserSelectOptionRow_user",
                                          "args": null
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            },
                            v16
                          ]
                        }
                      ]
                    }
                  ]
                },
                v16
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b3ae465f1f906e5b7f3b5027ca4931b1';
module.exports = node;
