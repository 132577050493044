/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import DescriptionRichText from '../../components/DescriptionRichText';

import { type EventInfoDescription_event } from './__generated__/EventInfoDescription_event.graphql';

const EventInfoDescription = (props: { event: EventInfoDescription_event, name: string }) => {
  const {
    name,
    event: { description },
  } = props;
  return (
    <DescriptionRichText name={name} description={description} contentDataAvailable={description} />
  );
};

export default createFragmentContainer(
  EventInfoDescription,
  graphql`
    fragment EventInfoDescription_event on Event {
      description
    }
  `,
);
