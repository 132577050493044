/* @flow */

import getFiscalYear from './getFiscalYear';
import getQuarterNumber from './getQuarterNumber';

export default function getBudgetQuarterString(fiscalYearStart: number): string {
  const currentFiscalYear = getFiscalYear(0, fiscalYearStart).end.year();
  const currentQuarter = getQuarterNumber(fiscalYearStart);
  return `Q${currentQuarter}_${currentFiscalYear}`;
}
