/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CategoryActualAmountCell_org$ref = any;
type CategoryActualAmountFooterCell_org$ref = any;
type CategoryBudgetedAmountCell_org$ref = any;
type CategoryBudgetedAmountFooterCell_org$ref = any;
type CategoryNameCell_org$ref = any;
type CategoryPaidAmountCell_org$ref = any;
type CategoryPaidAmountFooterCell_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoriesTable_org$ref: FragmentReference;
export type CategoriesTable_org = {|
  +budgetCategories: {|
    +totalCount: number
  |},
  +$fragmentRefs: CategoryNameCell_org$ref & CategoryBudgetedAmountCell_org$ref & CategoryActualAmountCell_org$ref & CategoryPaidAmountCell_org$ref & CategoryBudgetedAmountFooterCell_org$ref & CategoryActualAmountFooterCell_org$ref & CategoryPaidAmountFooterCell_org$ref,
  +$refType: CategoriesTable_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "CategoriesTable_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "CategoryNameCell_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryBudgetedAmountCell_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryActualAmountCell_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryPaidAmountCell_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryBudgetedAmountFooterCell_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryActualAmountFooterCell_org",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "CategoryPaidAmountFooterCell_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "budgetCategories",
      "storageKey": null,
      "args": null,
      "concreteType": "BudgetCategoryRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalCount",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f4d73320731e0ddf27623ea7aad35b38';
module.exports = node;
