/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import { type SortParam } from 'utils/routing/parseTypedQueryString';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import PaymentsTableRenderer from './PaymentsTableRenderer';

import { type PaymentsTableQueryResponse } from './__generated__/PaymentsTableQuery.graphql';

const query = graphql`
  query PaymentsTableQuery(
    $eventSlug: String!
    $query: String
    $sort: PaymentSort
    $direction: Direction
  ) {
    event(slug: $eventSlug) {
      ...PaymentsTableRenderer_event

      payments(first: 1000, query: $query, sort: $sort, direction: $direction)
        @connection(key: "PaymentsTable_payments", filters: []) {
        edges {
          node {
            ...PaymentsTableRenderer_payments
          }
        }
      }
    }

    org {
      ...PaymentsTableRenderer_org
    }
  }
`;

export default class PaymentsTable extends React.Component<{
  eventSlug: string,
  search: string,
  sort: SortParam,
  onChangeSort: (sort: SortParam) => void,
  onChangeDataCount: (count: number) => void,
}> {
  static defaultProps = {
    sort: { key: 'AMOUNT', asc: true },
  };

  renderTable = (response?: PaymentsTableQueryResponse) => {
    const { event, org } = response || {};

    return (
      <PaymentsTableRenderer
        payments={event ? event.payments.edges.map(edge => edge.node) : null}
        event={event || null}
        org={org || null}
        sort={this.props.sort}
        onChangeSort={this.props.onChangeSort}
        onChangeDataCount={this.props.onChangeDataCount}
      />
    );
  };

  render() {
    const { eventSlug, sort, search } = this.props;

    return (
      <DefaultQueryRenderer
        query={query}
        variables={{
          eventSlug,
          sort: sort.key,
          direction: sort.asc ? 'ASC' : 'DESC',
          query: search,
        }}
        renderSuccess={this.renderTable}
        renderLoading={this.renderTable}
      />
    );
  }
}
