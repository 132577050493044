/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ColorSelector_orgSettings$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventListDesign_orgSettings$ref: FragmentReference;
export type EventListDesign_orgSettings = {|
  +logo: ?string,
  +font: string,
  +primaryColor: string,
  +$fragmentRefs: ColorSelector_orgSettings$ref,
  +$refType: EventListDesign_orgSettings$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventListDesign_orgSettings",
  "type": "OrgSettings",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "logo",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "font",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "primaryColor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ColorSelector_orgSettings",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bb456fa02606606ec38b681efcde9e65';
module.exports = node;
