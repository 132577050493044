/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import FeatureAccessContext from 'contexts/FeatureAccess';

import { initFullStory } from 'utils/analytics/fullstory';

import Overlay from 'components/material/Overlay';
import SearchBox, { SearchIcon, SearchTextInput } from 'components/Search';

import SearchOverlay from './SearchOverlay';

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin: 11px 49px 0 0;
  outline: none;
  color: #3e4859;
  @media (max-width: 680px) {
    margin-right: 20px;
  }
`;

const StyledSearchBox = styled(SearchBox)`
  max-width: 470px;
  ${SearchTextInput} {
    background-color: white;
  }
  ${SearchIcon} {
    font-size: 15px;
  }
`;

const StyledOverlay = styled(Overlay)`
  display: flex;
  width: 100%;
  max-width: 470px;
  min-width: 340px;
  margin-top: 3px;
  margin-bottom: 50px;
  font-size: 13px;
  @media (max-width: 490px) {
    left: unset !important;
    right: 0 !important;
  }

  @media (max-width: 440px) {
    position: fixed !important;
    top: 45px !important;
    transform: unset !important;
    height: 100%;
  }
`;

const OverlayFocus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 150px;
  outline: none;
`;

class Search extends React.PureComponent<
  { analyticsEnabled: boolean },
  { query: string, overlayShown: boolean },
> {
  state = {
    query: '',
    overlayShown: false,
  };

  containerRef = React.createRef();

  overlayRef = React.createRef();

  inputRef = React.createRef();

  handleSearch = (query: string) => {
    this.setState({ query });
  };

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });

    if (this.props.analyticsEnabled) {
      initFullStory(window.location.pathname, true);
    }
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
  };

  handleBlur = (e: SyntheticEvent<HTMLDivElement>) => {
    const container = this.containerRef.current;
    const overlay = this.overlayRef.current;
    const relatedTarget: ?Node = (e: any).relatedTarget || document.activeElement;

    if (
      container &&
      overlay &&
      relatedTarget &&
      (container.contains(relatedTarget) || overlay.contains(relatedTarget))
    ) {
      return;
    }

    this.setState({ overlayShown: false });
  };

  static contextType = FeatureAccessContext;

  render() {
    const { query, overlayShown } = this.state;
    const includeContacts = this.context.legacyFeatures;
    const searchPlaceholder = includeContacts
      ? 'Search events, contacts and notes'
      : 'Search events and notes';
    return (
      <Container ref={this.containerRef} onBlur={this.handleBlur}>
        <StyledSearchBox
          search={query}
          onSearch={this.handleSearch}
          onFocus={this.handleOverlayShow}
          placeholder={searchPlaceholder}
          ref={this.inputRef}
        />
        <StyledOverlay
          show={overlayShown}
          target={this.inputRef.current}
          container={this.containerRef.current}
        >
          <OverlayFocus tabIndex={-1} ref={this.overlayRef}>
            <SearchOverlay
              onClose={this.handleOverlayHide}
              query={query}
              includeContacts={includeContacts}
            />
          </OverlayFocus>
        </StyledOverlay>
      </Container>
    );
  }
}

export default Search;
