/* @flow */
import styled from 'styled-components';

const ScheduleItemContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #cbcbcb;
  &:last-child {
    border-bottom: 0;
  }
  @media (max-width: 480px) {
    padding: 0 16px;
  }
`;

export default ScheduleItemContainer;
