/**
 * @flow
 * @relayHash 6fd91ccf9f71e6aee88966ad8a93ddaf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateContactFormBccEmailInput = {
  clientMutationId?: ?string,
  formId: string,
  email: string,
};
export type createContactFormBccEmailMutationVariables = {|
  input: CreateContactFormBccEmailInput
|};
export type createContactFormBccEmailMutationResponse = {|
  +createContactFormBccEmail: ?{|
    +contactFormBccEmailsEdge: {|
      +node: {|
        +id: string,
        +email: string,
      |}
    |},
    +registrationForm: {|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type createContactFormBccEmailMutation = {|
  variables: createContactFormBccEmailMutationVariables,
  response: createContactFormBccEmailMutationResponse,
|};
*/


/*
mutation createContactFormBccEmailMutation(
  $input: CreateContactFormBccEmailInput!
) {
  createContactFormBccEmail(input: $input) {
    contactFormBccEmailsEdge {
      node {
        id
        email
      }
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateContactFormBccEmailInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createContactFormBccEmail",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateContactFormBccEmailInput!"
      }
    ],
    "concreteType": "CreateContactFormBccEmailPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contactFormBccEmailsEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "ContactFormBccEmailRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactFormBccEmail",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "registrationForm",
        "storageKey": null,
        "args": null,
        "concreteType": "RegistrationForm",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasChanges",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createContactFormBccEmailMutation",
  "id": null,
  "text": "mutation createContactFormBccEmailMutation(\n  $input: CreateContactFormBccEmailInput!\n) {\n  createContactFormBccEmail(input: $input) {\n    contactFormBccEmailsEdge {\n      node {\n        id\n        email\n      }\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createContactFormBccEmailMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "createContactFormBccEmailMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '84d5055636d2c54eed6d1acca2486fe9';
module.exports = node;
