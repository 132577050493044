/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import CheckBox from 'components/material/CheckBox';

import type { Filter } from './columnFilterTypes';

export const StyledCheckBox = styled(CheckBox)`
  padding: 7px 0 7px 20px;
  font-size: 14px;
  line-height: 1;
  color: ${props => props.theme.oxfordBlue};
  > div:first-of-type {
    color: ${props => (props.checked ? props.theme.highlightColor : '#cdd1d4')};
  }
  &:last-of-type {
    margin-bottom: 1px;
  }
  &:hover {
    background-color: ${props => props.theme.hoverRowColor};
  }
`;

export default class ColumnFiltersItems extends React.PureComponent<{
  filters: $ReadOnlyArray<Filter>,
  shownFilters: $ReadOnlyArray<string>,
  onChange: (value: string, checked: boolean) => void,
  className?: string,
}> {
  render() {
    const { filters, shownFilters, onChange, className } = this.props;
    return filters.map(filter => {
      const checked = shownFilters.includes(filter.value);
      return (
        <StyledCheckBox
          className={className}
          label={filter.label}
          key={filter.groupId != null ? `${filter.groupId}-${filter.value}` : filter.value}
          checked={checked}
          disabled={filter.disabled === true || (shownFilters.length === 1 && checked)}
          onChange={() => onChange(filter.value, checked)}
        />
      );
    });
  }
}
