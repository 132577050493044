/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Roboto-Slab', 'Roboto', sans-serif;
  color: #34455a;

  a {
    color: #00abdf;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const MessageIcon = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 11px;
  height: 11px;
  margin: 4px 13px 4px 4px;
  border-radius: 50%;
  background: ${props => props.color};

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 50%;
    border: 3px solid ${props => props.color};
    opacity: 0.33;
  }
`;

const VARIANTS = {
  warning: '#ffb579',
  error: '#ff7980',
};

const IntegrationMessage = ({
  children,
  variant,
  className,
}: {
  children: React.Node,
  variant: $Keys<typeof VARIANTS>,
  className?: string,
}) => (
  <Message className={className}>
    <MessageIcon color={VARIANTS[variant]} />

    <span>{children}</span>
  </Message>
);

IntegrationMessage.defaultProps = {
  variant: 'error',
};

export default IntegrationMessage;
