/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import { sortBy } from 'lodash';

import saveFormLogic from 'graph/mutations/saveFormLogic';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button, { MinimalButton } from 'components/budget/Button';
import getFilterItemValueForSave from 'components/ContactForm/lib/getFilterItemValueForSave';
import validateFilterItem from 'components/ContactForm/lib/validateFilterItem';
import type { RelayQuestionType, RelaySectionType } from 'components/EventRequestForm/lib/types';
import EmptyFilters from 'components/FormLogic/EmptyFilters';
import type { RuleType } from 'components/FormLogic/lib/types';
import RuleEditor from 'components/FormLogic/RuleEditor';
import ViewMode from 'components/FormLogic/ViewMode';
import { getFieldName } from 'components/material/Filters/FilterSelectorRow';

import getConditionFields from '../lib/getConditionFields';

import type { EventRequestFormBuilderBody_requestForm } from '../__generated__/EventRequestFormBuilderBody_requestForm.graphql';
import type { SectionOrFieldLogic_org } from './__generated__/SectionOrFieldLogic_org.graphql';

const EditPenIcon = styled.i`
  display: none;
  color: #71c1e5;
`;

export const Block = styled.div`
  padding: 10px;
  color: ${props => props.theme.rowPrimaryTextColor};
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  ${props =>
    !props.editing &&
    !props.readOnly &&
    css`
      cursor: pointer;
      &:hover {
        ${EditPenIcon} {
          display: block;
        }
      }
    `}
`;

const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
`;

const BlockHeaderLeft = styled.div`
  ${props =>
    props.required &&
    css`
      :before {
        content: '*';
        margin-right: 4px;
        color: #519cf9;
      }
    `}
`;
const BlockHeaderRight = styled.div`
  cursor: pointer;
`;

const HorizontalLine = styled.hr`
  display: block;
  height: 1px;
  margin: 25px -60px 10px;
  border-top: 1px solid #d8d8d8;
`;

const Row = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;
`;

const RulesWrapper = styled.div`
  margin: 20px 0 20px 0;
`;

const CancelButton = styled(MinimalButton)`
  margin-top: 24px;
  margin-left: auto;
  font-size: 14px;
  font-weight: 400;
`;

const SaveButton = styled(Button)`
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
`;

class SectionOrFieldLogic extends React.Component<
  {
    requestForm: EventRequestFormBuilderBody_requestForm,
    section: RelaySectionType,
    question?: RelayQuestionType,
    org: SectionOrFieldLogic_org,
  },
  { rules: $ReadOnlyArray<RuleType>, editing: boolean, saving: boolean },
> {
  state = {
    rules: this.getSavedRules(),
    editing: false,
    saving: false,
  };

  getSavedRules() {
    return sortBy(
      this.ruleable().rules.edges.map(edge => edge.node),
      'order',
    ).map(rule => {
      return {
        id: rule.id,
        order: rule.order,
        action: rule.action,
        conditions: this.getSavedConditions(rule),
        hiddenConditions: [],
      };
    });
  }

  getSavedConditions(rule) {
    const { org } = this.props;

    if (!rule) {
      return [];
    }

    return sortBy(
      [
        ...rule.customSavedTextFilters.edges,
        ...rule.customSavedTextareaFilters.edges,
        ...rule.customSavedLinkFilters.edges,
        ...rule.customSavedNumberFilters.edges,
        ...rule.customSavedCurrencyFilters.edges,
        ...rule.customSavedBooleanFilters.edges,
        ...rule.customSavedMultiselectFilters.edges,
        ...rule.customSavedDateFilters.edges,
        ...rule.customSavedUserMultiselectFilters.edges,
      ].map((filterEdge: any) => {
        const filter = filterEdge.node;
        const customFieldNode = filter.customField
          ? org.customFields.edges.find(
              ({ node: customField }) =>
                filter.customField && customField.id === filter.customField.id,
            )
          : null;
        return {
          ...filter,
          errors: {},
          customField: customFieldNode ? customFieldNode.node : null,
          optionIds: filter.options
            ? filter.options.edges
                .map(edge => (edge.node && edge.node.option ? edge.node.option.id : null))
                .filter(Boolean)
            : [],
          users: filter.options
            ? filter.options.edges.map(edge => edge.node.user).filter(Boolean)
            : [],
        };
      }),
      'order',
    );
  }

  handleStartEditing = () => {
    this.setState(prevState => ({
      editing: true,
      rules: [
        ...prevState.rules,
        prevState.rules.length === 0
          ? {
              order: prevState.rules.length,
              conditions: [],
              hiddenConditions: [],
            }
          : null,
      ].filter(Boolean),
    }));
  };

  handleAddNewRule = () => {
    this.setState(prevState => ({
      rules: [
        ...prevState.rules,
        {
          order: prevState.rules.length,
          conditions: [],
          hiddenConditions: [],
        },
      ],
    }));
  };

  handleUpdateRule = (rule, index) => {
    this.setState(prevState => {
      return {
        rules: [...prevState.rules.slice(0, index), rule, ...prevState.rules.slice(index + 1)],
      };
    });
  };

  handleRemoveRule = (index: number) => {
    this.setState(prevState => {
      const remainingRules = prevState.rules.filter((_, i) => i !== index);
      return {
        rules:
          remainingRules.length === 0
            ? [
                {
                  order: prevState.rules.length,
                  conditions: [],
                  hiddenConditions: [],
                },
              ]
            : remainingRules,
      };
    });
  };

  handleCancel = () => {
    const rules = this.getSavedRules();
    this.setState({
      editing: false,
      rules:
        rules.length === 0
          ? [
              {
                order: rules.length,
                conditions: [],
                hiddenConditions: [],
              },
            ]
          : rules,
    });
  };

  handleSave = () => {
    const rules = this.state.rules;

    const validatedRules = rules.map(rule => ({
      ...rule,
      conditions: rule.conditions.map(condition => ({
        ...condition,
        errors: validateFilterItem(condition, { field: 'Question is required' }),
      })),
    }));

    if (
      validatedRules.some(
        rule =>
          rule.action !== 'HIDE_BY_DEFAULT' &&
          rule.conditions.filter(item => item.errors.field || item.errors.value).length > 0,
      )
    ) {
      this.setState({ rules: validatedRules });
      return;
    }

    const rulesWithActions = rules.filter(rule => rule.action);

    const rulesToSave = rulesWithActions.map((rule, index) => {
      const filtersToSave = rule.conditions.map((filterItem, orderIndex) => {
        return {
          order: orderIndex + 1,
          operator: filterItem.operator,
          customFieldId: filterItem.customField ? filterItem.customField.id : null,
          fieldName: getFieldName(filterItem),
          ...getFilterItemValueForSave(filterItem),
        };
      });

      const filtersToRemove = rule.hiddenConditions.map(filterItem => ({
        customFieldId: filterItem.customField ? filterItem.customField.id : null,
        fieldName: getFieldName(filterItem),
        textParam: null,
        textareaParam: null,
        linkParam: null,
        numberParam: null,
        dateParam: null,
        booleanParam: null,
        optionIds: null,
      }));

      return {
        ruleId: rule.id || null,
        order: index,
        action: rule.action,
        conditions: [...filtersToSave, ...filtersToRemove],
      };
    });

    this.setState({ saving: true, rules: rulesWithActions });
    saveFormLogic({ ruleableId: this.ruleable().id, rules: rulesToSave })
      .then(() => {
        this.setState({ editing: false, saving: false });
      })
      .catch(showModernMutationError);
  };

  ruleable(): RelayQuestionType | RelaySectionType {
    return this.props.question || this.props.section;
  }

  renderSectionViewMode() {
    const { requestForm, section, org } = this.props;
    // The first section contains the event name required field
    if (section.order === 1) {
      return (
        <>
          <EmptyFilters muted>
            This section contains a required question and can’t have logical dependencies.
          </EmptyFilters>
          {section.questions.edges.length > 1 && <HorizontalLine />}
        </>
      );
    }

    if (section.questions.edges.length === 0) {
      return (
        <EmptyFilters muted>
          This section does not have any questions and can’t have logical dependencies.
        </EmptyFilters>
      );
    }

    return (
      <>
        <ViewMode
          conditionFields={getConditionFields(requestForm, section)}
          rules={section.rules}
          emptyMessage="This section does not have any logical dependencies. Click to add."
          tz={org.settings.tz}
          onStartEdit={this.handleStartEditing}
        />
        <HorizontalLine />
      </>
    );
  }

  renderQuestionViewMode() {
    const { requestForm, section, question, org } = this.props;
    if (question == null) return null;

    if (question.mapping === 'NAME') {
      return (
        <EmptyFilters muted>
          This question is required and can’t have logical dependencies.
        </EmptyFilters>
      );
    }

    return (
      <ViewMode
        conditionFields={getConditionFields(requestForm, section, question)}
        rules={question.rules}
        tz={org.settings.tz}
        onStartEdit={this.handleStartEditing}
      />
    );
  }

  render() {
    const ruleable = this.ruleable();
    const { requestForm, section, question, org } = this.props;
    const { rules, editing, saving } = this.state;
    const readOnly =
      (question == null && (section.order === 1 || section.questions.edges.length === 0)) ||
      (question != null && question.mapping === 'NAME');

    return (
      <Block
        key={ruleable.id}
        editing={editing}
        onClick={!editing && !readOnly ? this.handleStartEditing : undefined}
        readOnly={readOnly}
      >
        <BlockHeader>
          <BlockHeaderLeft required={question && question.required}>
            {(question && question.name) || section.title || `Section #${section.order} logic`}
          </BlockHeaderLeft>
          <BlockHeaderRight>
            <EditPenIcon className="fa fa-fw fa-pencil" />
          </BlockHeaderRight>
        </BlockHeader>
        {!editing &&
          (question == null ? this.renderSectionViewMode() : this.renderQuestionViewMode())}
        {editing && (
          <>
            <RulesWrapper>
              {rules.map((rule, index) => {
                return (
                  <React.Fragment
                    key={`${rule.order}-${rule.id || 'UNSAVED'}-${rule.action || 'default'}`}
                  >
                    {index > 0 && <>Or</>}
                    <RuleEditor
                      tz={org.settings.tz}
                      rule={rule}
                      org={org}
                      onUpdate={updatedRule => this.handleUpdateRule(updatedRule, index)}
                      onRemove={() => this.handleRemoveRule(index)}
                      conditionFields={getConditionFields(requestForm, section, question)}
                      onlyHideShow={question == null}
                    />
                  </React.Fragment>
                );
              })}
            </RulesWrapper>
            <MinimalButton onClick={this.handleAddNewRule} label="+ Add rule" />
            <Row>
              <CancelButton label="Cancel" onClick={this.handleCancel} disabled={saving} />
              <SaveButton onClick={this.handleSave} loading={saving}>
                Save
              </SaveButton>
            </Row>
          </>
        )}
      </Block>
    );
  }
}

export default createFragmentContainer(SectionOrFieldLogic, {
  org: graphql`
    fragment SectionOrFieldLogic_org on Org {
      settings {
        tz
      }
      customFields(customizableType: [EVENT]) {
        edges {
          node {
            id
            label
            fieldName
            order
            kind
            options {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
});
