/* @flow */
import React from 'react';
import { graphql } from 'react-relay';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import ExportedSavedViewTooltipContent from 'components/ScheduledReporting/ExportedSavedViewTooltipContent';

import type { SavedViewTooltipQueryResponse } from './__generated__/SavedViewTooltipQuery.graphql';

const query = graphql`
  query SavedViewTooltipQuery($budgetReportingViewId: ID!) {
    budgetReportingView: node(id: $budgetReportingViewId) {
      ... on BudgetReportingView {
        exportScheduler {
          frequency
          scheduledOn
          recipients {
            edges {
              node {
                id
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`;

export default class SavedViewTooltip extends React.PureComponent<{
  editedViewId: ?string,
}> {
  render() {
    if (!this.props.editedViewId) return null;
    return (
      <DefaultQueryRenderer
        query={query}
        variables={{ budgetReportingViewId: this.props.editedViewId }}
        renderLoading={() => null}
        renderSuccess={(response: SavedViewTooltipQueryResponse) => {
          if (!response.budgetReportingView || !response.budgetReportingView.exportScheduler)
            return null;
          return (
            <ExportedSavedViewTooltipContent
              frequency={response.budgetReportingView.exportScheduler.frequency}
              scheduledOn={response.budgetReportingView.exportScheduler.scheduledOn}
              recipients={response.budgetReportingView.exportScheduler.recipients.edges.map(
                ({ node }) => node,
              )}
            />
          );
        }}
      />
    );
  }
}
