/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import type { CompanyContactsCount_company } from './__generated__/CompanyContactsCount_company.graphql';

type Props = {
  company: CompanyContactsCount_company,
};

const CompanyContactsCount = ({ company }: Props) => {
  return company ? company.contacts.totalCount : null;
};

export default createFragmentContainer(
  CompanyContactsCount,
  graphql`
    fragment CompanyContactsCount_company on Company {
      contacts {
        totalCount
      }
    }
  `,
);
