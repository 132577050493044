/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpenseActualAmountFooterCell_event$ref: FragmentReference;
export type ExpenseActualAmountFooterCell_event = {|
  +totalActualAmount: number,
  +$refType: ExpenseActualAmountFooterCell_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ExpenseActualAmountFooterCell_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalActualAmount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2380857f2f177a5fdbc3f7cb440d0082';
module.exports = node;
