/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import addRecordToConnection from 'graph/updaters/addRecordToConnection';

const mutation = graphql`
  mutation addTaskContactMutation($input: AddDeliverableContactInput!) {
    addDeliverableContact(input: $input) {
      contactEdge {
        __typename
        node {
          id
          firstName
          lastName
          email
          salesforceSyncAs
          slug
        }
      }
    }
  }
`;

export default function addTaskContact(taskId: string, contactId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableId: taskId,
        contactId,
      },
    },
    updater: addRecordToConnection({
      parentId: taskId,
      edgeName: 'contactEdge',
      connections: [{ field: 'contacts' }],
    }),
  });
}
