/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ContactPageRoutes_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactPage_user$ref: FragmentReference;
export type ContactPage_user = {|
  +$fragmentRefs: ContactPageRoutes_user$ref,
  +$refType: ContactPage_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactPage_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ContactPageRoutes_user",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9641cc4b3a35570c4d099669f2e40015';
module.exports = node;
