/* @flow */
import { graphql } from 'react-relay';
import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';
import removeRecordFromStore from 'graph/updaters/removeRecordFromStore';

import type {
  removeScheduleMutationVariables,
  removeScheduleMutationResponse,
} from './__generated__/removeScheduleMutation.graphql';

const mutation = graphql`
  mutation removeScheduleMutation($input: RemoveScheduleFromEventInput!) {
    removeScheduleFromEvent(input: $input) {
      removedScheduleId
    }
  }
`;

export default function removeSchedule(
  eventId: string,
  scheduleId: string,
): Promise<removeScheduleMutationResponse> {
  const variables: removeScheduleMutationVariables = {
    input: {
      scheduleId,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    updater: mergeUpdaters(
      removeRecordFromConnection({
        deletedIdField: 'removedScheduleId',
        parentId: eventId,
        connections: [{ field: 'schedules' }],
      }),
      removeRecordFromStore({ deletedIdField: 'removedScheduleId' }),
    ),
  });
}
