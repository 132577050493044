import React from 'react';
import styled from 'styled-components';

import Label from './BriefTaskLabel';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
`;

const DateTime = styled.div`
  font-size: 14px;
  color: ${props => props.theme.riverBed};
`;

const DueDate = (props: { date: string }) => {
  return (
    <Container>
      <Label>DUE DATE</Label>
      <DateTime>{props.date}</DateTime>
    </Container>
  );
};
export default DueDate;
