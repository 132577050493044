/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location, Link } from 'react-router-dom';

import type { TeamName_membershipEdge } from './__generated__/TeamName_membershipEdge.graphql';

const stopPropagation = (e: SyntheticMouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

type Props = {
  membershipEdge: TeamName_membershipEdge,
  location: Location,
};

const TeamName = ({ membershipEdge, location }: Props) => {
  if (membershipEdge.node.viewerCanUpdate) {
    return (
      <Link
        to={{
          pathname: `/workspace/teams/${membershipEdge.node.slug}/members`,
          state: {
            prevLabel: 'Account Settings',
            prevUrl: location.pathname,
          },
        }}
        onClick={stopPropagation}
      >
        {membershipEdge.node.name}
      </Link>
    );
  }

  return membershipEdge.node.name;
};

export default createFragmentContainer(
  TeamName,
  graphql`
    fragment TeamName_membershipEdge on MembershipTeamEdge {
      node {
        slug
        name
        viewerCanUpdate
      }
    }
  `,
);
