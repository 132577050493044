/**
 * @flow
 * @relayHash ecd6c961a3b123c6272c05bfca70aacc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddEventNoteAttachmentInput = {
  clientMutationId?: ?string,
  eventNoteId: string,
  filename: string,
  fileurl: string,
  filetype: string,
  filesize?: ?number,
};
export type addEventNoteAttachmentMutationVariables = {|
  input: AddEventNoteAttachmentInput
|};
export type addEventNoteAttachmentMutationResponse = {|
  +addEventNoteAttachment: ?{|
    +attachmentEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +fileurl: ?string,
        +filename: ?string,
        +filetype: ?string,
        +filesize: ?number,
      |},
    |}
  |}
|};
export type addEventNoteAttachmentMutation = {|
  variables: addEventNoteAttachmentMutationVariables,
  response: addEventNoteAttachmentMutationResponse,
|};
*/


/*
mutation addEventNoteAttachmentMutation(
  $input: AddEventNoteAttachmentInput!
) {
  addEventNoteAttachment(input: $input) {
    attachmentEdge {
      __typename
      node {
        id
        fileurl
        filename
        filetype
        filesize
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddEventNoteAttachmentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addEventNoteAttachment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddEventNoteAttachmentInput!"
      }
    ],
    "concreteType": "AddEventNoteAttachmentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "attachmentEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "AttachmentRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Attachment",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fileurl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filename",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filetype",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filesize",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addEventNoteAttachmentMutation",
  "id": null,
  "text": "mutation addEventNoteAttachmentMutation(\n  $input: AddEventNoteAttachmentInput!\n) {\n  addEventNoteAttachment(input: $input) {\n    attachmentEdge {\n      __typename\n      node {\n        id\n        fileurl\n        filename\n        filetype\n        filesize\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addEventNoteAttachmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "addEventNoteAttachmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '166ffc564fb68d80f40d62310ce5ec32';
module.exports = node;
