/* @flow */

import mappingKinds from './mappingKinds';

const mappingLabels = (): $ObjMap<typeof mappingKinds, () => string> => ({
  NAME: 'Event Name',
  LOCATION: 'Location',
  DATE: 'Dates',
  WEBSITE: 'Website',
  DESCRIPTION: 'Description',
  NOTE: 'Notes',
  ATTACHMENTS: 'Attachments',
  BOOTH: 'Booth',
  BOOTH_DIMENSIONS: 'Booth Dimensions',
  FLOOR_PLAN: 'Floor Plan',
  LEADER: 'Leader',
  EXPENSE: 'Expense',
  PLANNED_BUDGET: 'Planned Budget',
  BUDGETED_AMOUNT: 'Planned Total',
});

export default mappingLabels;
