/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import updateSalesforceSettings from 'graph/mutations/salesforce/updateSalesforceSettings';
import showModernMutationError from 'graph/utils/showModernMutationError';

import SelectField from 'components/material/SelectField';

import type { DefaultLeadSource_org } from './__generated__/DefaultLeadSource_org.graphql';

const Container = styled.div`
  max-width: 250px;
  margin: 10px 20px;
`;

class DefaultLeadSource extends React.PureComponent<{
  org: DefaultLeadSource_org,
}> {
  handleTagChange = (defaultLeadSource: ?string) => {
    updateSalesforceSettings({ defaultLeadSource }).catch(showModernMutationError);
  };

  render() {
    const { salesforceAccount } = this.props.org;
    const options = salesforceAccount
      ? salesforceAccount.leadSourcePickList.map(item => ({
          label: item,
          value: item,
        }))
      : [];

    if (options.length === 0) return null;

    return (
      <Container>
        <SelectField
          label="Default Lead Source"
          value={salesforceAccount && salesforceAccount.defaultLeadSource}
          disabled={!salesforceAccount}
          onChange={this.handleTagChange}
          options={options}
          clearable
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  DefaultLeadSource,
  graphql`
    fragment DefaultLeadSource_org on Org {
      salesforceAccount {
        leadSourcePickList
        defaultLeadSource
      }
    }
  `,
);
