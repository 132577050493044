/**
 * @flow
 * @relayHash b1b374fde7b5ce872629d6bdc4a4a3d5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RegistrationSummary_event$ref = any;
export type ContactSaveMethod = "api" | "g2w" | "import" | "manual" | "past_g2w" | "past_zoom" | "registration" | "zapier" | "zoom";
export type ContactSort = "ATTENDANCE_DURATION" | "ATTENDANCE_JOIN_TIME" | "ATTENDANCE_LEAVE_TIME" | "ATTENDANCE_STATUS_ID" | "CITY" | "COMPANY_ID" | "CONTACT_TYPE_ID" | "COUNTRY" | "CREATED_AT" | "CREATED_BY" | "CREATED_METHOD" | "CUSTOM" | "DESCRIPTION" | "EMAIL" | "EMAIL_OPT_IN" | "EVENTS" | "HOT_LEAD" | "LAST_SYNCED" | "LINKEDIN" | "MARKETO_ID" | "MEMBERSHIP_LAST_SYNCED" | "MEMBERSHIP_SYNC_STATUS" | "NAME" | "OWNER_ID" | "PHONE1" | "PHONE2" | "REGISTERED_AT" | "REGISTRATION_STATUS_ID" | "REQUEST_SUBMISSIONS" | "SALESFORCE_ID" | "SALESFORCE_LEAD_STATUS_ID" | "SALESFORCE_SYNC_AS" | "STATE" | "STREET" | "SYNC_STATUS" | "TITLE" | "TWITTER" | "UPDATED_AT" | "UPDATED_BY" | "UPDATED_METHOD" | "WEBSITE" | "ZIP";
export type ContactSyncOption = "contact" | "lead";
export type Direction = "ASC" | "DESC";
export type Operator = "equal" | "in" | "is_not_null" | "is_null" | "not_equal" | "not_in" | "text_ends_with" | "text_in" | "text_not_in" | "text_starts_with";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
export type ContactFilters = {
  query?: ?string,
  search?: ?Search,
  contactIds?: ?$ReadOnlyArray<string>,
  creatorIds?: ?$ReadOnlyArray<string>,
  updaterIds?: ?$ReadOnlyArray<string>,
  countries?: ?$ReadOnlyArray<string>,
  states?: ?$ReadOnlyArray<string>,
  cities?: ?$ReadOnlyArray<string>,
  createdMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  updatedMethods?: ?$ReadOnlyArray<ContactSaveMethod>,
  createdAt?: ?DateTimeRange,
  updatedAt?: ?DateTimeRange,
  contactTypeIds?: ?$ReadOnlyArray<string>,
  ownerIds?: ?$ReadOnlyArray<string>,
  senderIds?: ?$ReadOnlyArray<string>,
  salesforceSyncOptions?: ?$ReadOnlyArray<ContactSyncOption>,
  leadStatusIds?: ?$ReadOnlyArray<string>,
  sentAt?: ?DateTimeRange,
  emailOptIn?: ?boolean,
  hotLead?: ?boolean,
  registeredAt?: ?DateTimeRange,
  attendanceStatusIds?: ?$ReadOnlyArray<string>,
  registrationStatusIds?: ?$ReadOnlyArray<string>,
  syncStatuses?: ?$ReadOnlyArray<SyncState>,
  membershipSyncStatuses?: ?$ReadOnlyArray<SyncState>,
  customFilters?: ?$ReadOnlyArray<?FilterInput>,
  customFieldSortId?: ?string,
  sort?: ?ContactSort,
  direction?: ?Direction,
};
export type Search = {
  search: string,
  exactMatch: boolean,
};
export type DateTimeRange = {
  key?: ?string,
  start: any,
  end: any,
};
export type FilterInput = {
  order?: ?number,
  operator?: ?Operator,
  customFieldId?: ?string,
  fieldName?: ?string,
  dateParam?: ?DateTimeRange,
  optionIds?: ?$ReadOnlyArray<string>,
  textParam?: ?$ReadOnlyArray<string>,
  textareaParam?: ?$ReadOnlyArray<string>,
  linkParam?: ?$ReadOnlyArray<string>,
  booleanParam?: ?boolean,
  numberParam?: ?NumberRangeInput,
};
export type NumberRangeInput = {
  min?: ?number,
  max?: ?number,
};
export type RegistrationResultsQueryVariables = {|
  eventSlug: string,
  filters: ContactFilters,
|};
export type RegistrationResultsQueryResponse = {|
  +event: ?{|
    +contactRegistrationsTotal: number,
    +$fragmentRefs: RegistrationSummary_event$ref,
  |}
|};
export type RegistrationResultsQuery = {|
  variables: RegistrationResultsQueryVariables,
  response: RegistrationResultsQueryResponse,
|};
*/


/*
query RegistrationResultsQuery(
  $eventSlug: String!
  $filters: ContactFilters!
) {
  event(slug: $eventSlug) {
    ...RegistrationSummary_event
    contactRegistrationsTotal
    id
  }
}

fragment RegistrationSummary_event on Event {
  contactRegistrationsCountData(filters: $filters) {
    registrationCount
    registrationDate
  }
  attendedTotal(filters: $filters)
  zoomLocation {
    id
  }
  g2wLocation {
    id
  }
  ...RegistrationTotals_event
}

fragment RegistrationTotals_event on Event {
  averageAttendedTime
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "ContactFilters!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactRegistrationsTotal",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters",
    "type": "ContactFilters"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  v4
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RegistrationResultsQuery",
  "id": null,
  "text": "query RegistrationResultsQuery(\n  $eventSlug: String!\n  $filters: ContactFilters!\n) {\n  event(slug: $eventSlug) {\n    ...RegistrationSummary_event\n    contactRegistrationsTotal\n    id\n  }\n}\n\nfragment RegistrationSummary_event on Event {\n  contactRegistrationsCountData(filters: $filters) {\n    registrationCount\n    registrationDate\n  }\n  attendedTotal(filters: $filters)\n  zoomLocation {\n    id\n  }\n  g2wLocation {\n    id\n  }\n  ...RegistrationTotals_event\n}\n\nfragment RegistrationTotals_event on Event {\n  averageAttendedTime\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RegistrationResultsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RegistrationSummary_event",
            "args": null
          },
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RegistrationResultsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactRegistrationsCountData",
            "storageKey": null,
            "args": v3,
            "concreteType": "RegistrationCount",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "registrationCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "registrationDate",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "attendedTotal",
            "args": v3,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "zoomLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "ZoomLocation",
            "plural": false,
            "selections": v5
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "g2wLocation",
            "storageKey": null,
            "args": null,
            "concreteType": "G2WLocation",
            "plural": false,
            "selections": v5
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "averageAttendedTime",
            "args": null,
            "storageKey": null
          },
          v2,
          v4
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b20f9b76cd3b43384ddfe4157ee5290d';
module.exports = node;
