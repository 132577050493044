/* @flow */

import React from 'react';
import styled, { css } from 'styled-components';

import RichText from 'components/RichText';
import { Label } from 'components/material/TextField';

const Container = styled.div`
  position: relative;
`;

const StyledLabel = styled(Label)`
  ${props =>
    props.focused &&
    css`
      color: ${props.theme.primaryActionColor};
    `};
`;

const StyledRichText = styled(RichText)`
  padding-top: 8px;
`;

export default class SweetRichText extends React.PureComponent<
  {
    description?: string,
    label: string,
    onSave: (description: string) => void,
    shouldGetFocus?: boolean,
    className?: string,
    borderColor?: string,
    stickyPanel?: boolean,
    hideActionButtons?: boolean,
  },
  {
    editing: boolean,
    showRichText: boolean,
  },
> {
  state = { editing: false, showRichText: true };

  handleStartEditing = () => {
    this.setState({ editing: true });
  };

  stopEditing = () => {
    this.setState({ editing: false });
    this.emptyValue();
  };

  emptyValue = () => {
    this.setState({ showRichText: false }, () =>
      setTimeout(() => this.setState({ showRichText: true }), 0),
    );
  };

  handleSave = (description: ?string) => {
    this.setState({ editing: false });
    if (this.props.description == null) {
      this.emptyValue();
    }
    this.props.onSave(description || '');
  };

  render() {
    const {
      label,
      description,
      shouldGetFocus,
      className,
      stickyPanel,
      hideActionButtons,
      borderColor,
    } = this.props;
    const { editing, showRichText } = this.state;
    const isCollapsed = editing || description || undefined;
    return (
      <Container className={className}>
        <StyledLabel data-collapsed={isCollapsed} focused={editing}>
          {label}
        </StyledLabel>
        {showRichText && (
          <StyledRichText
            defaultValue={description}
            onSave={this.handleSave}
            shouldGetFocus={shouldGetFocus}
            onStartEditing={this.handleStartEditing}
            hideActionButtons={hideActionButtons}
            stickyPanel={stickyPanel}
            borderColor={borderColor}
            onCancel={this.stopEditing}
            sweetEditor
          />
        )}
      </Container>
    );
  }
}
