/* @flow */
import downloadFile from '../downloadFile';

export default function downloadPDF(url: string, filename: string): Promise<void> {
  return fetch(url).then(response => {
    if (response.status >= 400) {
      return response.json().then(json => Promise.reject(new Error(json.errors)));
    }
    return response.blob().then(contents => {
      downloadFile(contents, filename, 'application/pdf');
    });
  });
}
