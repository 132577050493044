/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import ExternalLinkIcon from 'images/external.svg';
import Divider from 'components/Sidebar/Divider';
import Footer from 'components/Sidebar/Footer';
import Header from 'components/Sidebar/Header';
import MenuItem from 'components/Sidebar/MenuItem';

import type { VendorsMenu_org } from './__generated__/VendorsMenu_org.graphql';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  svg {
    margin-left: 8px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  svg {
    margin-left: 8px;
  }
  &:hover {
    svg {
      path:not(:first-of-type) {
        fill: #000;
      }
    }
  }
`;

const Title = styled.div`
  margin-bottom: 25px;
  margin-left: 9px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
`;

type Props = { org: VendorsMenu_org, pathPrefix: string };

const VendorsMenu = ({ org, pathPrefix }: Props) => {
  const { viewerCanUpdate } = org;
  return (
    <Menu>
      <Header>
        <Title>Vendors</Title>
        <MenuItem to={pathPrefix}>Vendors</MenuItem>
      </Header>
      {viewerCanUpdate && (
        <>
          <Divider />
          <Footer>
            <StyledMenuItem to="/settings/vendors" isActive={() => false}>
              Vendor settings
              <ExternalLinkIcon />
            </StyledMenuItem>
          </Footer>
        </>
      )}
    </Menu>
  );
};

export default createFragmentContainer(
  VendorsMenu,
  graphql`
    fragment VendorsMenu_org on Org {
      viewerCanUpdate
    }
  `,
);
