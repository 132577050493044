/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type CustomizableType } from 'components/FieldSettings/types';

import type {
  reorderCustomFieldsMutationResponse,
  reorderCustomFieldsMutationVariables,
} from './__generated__/reorderCustomFieldsMutation.graphql';

const mutation = graphql`
  mutation reorderCustomFieldsMutation($input: ReorderCustomFieldsInput!) {
    reorderCustomFields(input: $input) {
      updatedFields {
        id
        order
        section {
          id
        }
      }
    }
  }
`;

export default function reorderCustomFields(
  customizableType: CustomizableType,
  fieldsOrder: $ReadOnlyArray<{
    id: string,
    order: number,
    sectionId?: ?string,
  }>,
): Promise<reorderCustomFieldsMutationResponse> {
  const variables: reorderCustomFieldsMutationVariables = {
    input: { customizableType, fieldsOrder },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      reorderCustomFields: {
        updatedFields: fieldsOrder.map(field => ({
          id: field.id,
          order: field.order,
          ...(field.sectionId ? { section: { id: field.sectionId } } : null),
        })),
      },
    },
  });
}
