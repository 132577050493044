/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import formatCost from 'utils/number/formatCost';

import Restricted from '../../components/Restricted';
import SimpleBriefField from '../../components/SimpleBriefField';

import { type EventInfoPlannedBudget_event } from './__generated__/EventInfoPlannedBudget_event.graphql';
import { type EventInfoPlannedBudget_org } from './__generated__/EventInfoPlannedBudget_org.graphql';

const EventInfoPlannedBudget = (props: {
  name: string,
  event: EventInfoPlannedBudget_event,
  org: EventInfoPlannedBudget_org,
}) => {
  const {
    name,
    event: { viewerCanViewBudget, totalBudgetedAmount },
    org: {
      settings: { currency },
    },
  } = props;

  return (
    totalBudgetedAmount > 0 && (
      <SimpleBriefField
        label={name}
        contentDataAvailable={totalBudgetedAmount}
        data={viewerCanViewBudget ? formatCost(totalBudgetedAmount, currency) : <Restricted />}
      />
    )
  );
};

export default createFragmentContainer(EventInfoPlannedBudget, {
  event: graphql`
    fragment EventInfoPlannedBudget_event on Event {
      viewerCanViewBudget
      totalBudgetedAmount
    }
  `,
  org: graphql`
    fragment EventInfoPlannedBudget_org on Org {
      settings {
        currency
      }
    }
  `,
});
