/* @flow */
import * as React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import { type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

import type { TaskStatuses } from 'config/taskStatuses';

import { type SortParam } from 'utils/routing/parseTypedQueryString';
import replaceSortQueryParam from 'utils/routing/replaceSortQueryParam';
import showErrorPopup from 'utils/showErrorPopup';

import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import NoResult from 'images/noResult.svg';
import EmptyView from 'components/budget/EmptyView';
import InfiniteScrollListener from 'components/InfiniteScrollListener';
import InfiniteScrollLoading from 'components/InfiniteScrollLoading';
import Loader from 'components/Loader';
import Checkbox from 'components/material/CheckBox';
import { Content } from 'components/page/Content';
import ScrollbarSizes from 'components/ScrollbarSizes';
import TaskRow, { type TaskRowType } from 'components/Tasks/TaskRow';
import TasksListHeader from 'components/Tasks/TasksListHeader';

import { type TasksDueDateViewContent_event } from './__generated__/TasksDueDateViewContent_event.graphql';
import { type TasksDueDateViewContent_org } from './__generated__/TasksDueDateViewContent_org.graphql';
import { type TasksDueDateViewContent_totalCountEvent } from './__generated__/TasksDueDateViewContent_totalCountEvent.graphql';

const StyledCheckbox = styled(Checkbox)`
  flex: 1 1 auto;
  margin-left: 15px;
  ${props => props.hidden && 'visibility: hidden;'};
`;

const StyledContent = styled(Content)`
  &:hover {
    ${StyledCheckbox} {
      visibility: visible !important;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  @media (${props => props.theme.mobileOnly}) {
    display: none;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

class TasksDueDateViewContent extends React.Component<
  {
    event: ?TasksDueDateViewContent_event,
    org: TasksDueDateViewContent_org,
    totalCountEvent: ?TasksDueDateViewContent_totalCountEvent,
    history: RouterHistory,
    relay: RelayPaginationProp,
    onSelectedTasksChange: (tasks: Array<TaskRowType>) => void,
    selectedTasks: Array<TaskRowType>,
    onTaskUpdate: (string, Object) => void,
    onTaskStatusUpdate: (task: TaskRowType, status: TaskStatuses) => void,
    onTaskRemove: string => void,
    onTaskAssign: (string, string) => void,
    onTaskUnassign: (string, string) => void,
    onTaskAddTag: (string, string) => void,
    onTaskRemoveTag: (string, string) => void,
    onGetTaskLink: string => string,
    onInviteClick: (taskId: string) => void,
    newTasks: Array<string>,
    sort: SortParam,
    loading?: boolean,
    filtered: boolean,
  },
  {
    refetching: boolean,
    vScrollbarSize: number,
    hScrollbarSize: number,
    deliverables: Array<TaskRowType>,
  },
> {
  state = {
    refetching: false,
    vScrollbarSize: 0,
    hScrollbarSize: 0,
    deliverables: this.props.event
      ? this.props.event.deliverables.edges.map(edge => edge.node)
      : [],
  };

  static getDerivedStateFromProps(
    nextProps: $PropertyType<TasksDueDateViewContent, 'props'>,
    prevState: $PropertyType<TasksDueDateViewContent, 'state'>,
  ) {
    if (
      nextProps.event &&
      !isEqual(
        nextProps.event.deliverables.edges.map(edge => edge.node),
        prevState.deliverables,
      )
    ) {
      nextProps.onSelectedTasksChange(
        nextProps.event.deliverables.edges
          .filter(edge =>
            nextProps.selectedTasks.some(selectedTask => selectedTask.id === edge.node.id),
          )
          .map(edge => edge.node),
      );
      return {
        deliverables: nextProps.event
          ? nextProps.event.deliverables.edges.map(edge => edge.node)
          : [],
      };
    }

    return null;
  }

  scrollContainer = document.querySelector('.site-content');

  paginationDisposable: ?Disposable;

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  handleTaskSelect = (task: TaskRowType, shiftKey: boolean) => {
    const { selectedTasks, onSelectedTasksChange } = this.props;
    if (selectedTasks.some(selectedTask => selectedTask.id === task.id)) {
      onSelectedTasksChange(selectedTasks.filter(selectedTask => selectedTask.id !== task.id));
      return;
    }
    if (shiftKey) {
      const lastSelected = selectedTasks.slice(-1)[0];
      if (lastSelected) {
        const tasks = this.tasksRange(lastSelected.id, task.id);

        onSelectedTasksChange(uniq([...selectedTasks, ...tasks]));
        return;
      }
    }
    onSelectedTasksChange([...selectedTasks, task]);
  };

  handleCheckboxChange = (checked: boolean) => {
    this.props.onSelectedTasksChange(
      checked && this.props.event ? this.props.event.deliverables.edges.map(edge => edge.node) : [],
    );
  };

  handleSortChange = (sort: SortParam) => {
    replaceSortQueryParam(this.props.history, sort);
  };

  handleLoadMore = (amount: number) => {
    const { event, relay } = this.props;
    if (!event) {
      return;
    }
    if (this.state.refetching || !event.deliverables.pageInfo.hasNextPage) {
      return;
    }
    this.setState({ refetching: true });

    this.paginationDisposable = relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  handleScrollToBottom = () => {
    this.handleLoadMore(50);
  };

  handleScrollbarSizesChange = (sizes: { vScrollbarSize: number, hScrollbarSize: number }) => {
    this.setState(sizes);
  };

  tasksRange(fromId: string, toId: string) {
    const tasks = this.props.event
      ? this.props.event.deliverables.edges.map(edge => edge.node)
      : [];
    let fromIndex = tasks.findIndex(task => task.id === fromId);
    let toIndex = tasks.findIndex(task => task.id === toId);

    if (fromIndex > toIndex) [fromIndex, toIndex] = [toIndex, fromIndex];

    return tasks.slice(fromIndex, toIndex + 1);
  }

  render() {
    const {
      org,
      event,
      totalCountEvent,
      loading,
      selectedTasks,
      onTaskUpdate,
      onTaskStatusUpdate,
      onTaskRemove,
      onTaskAssign,
      onTaskUnassign,
      onTaskAddTag,
      onTaskRemoveTag,
      onGetTaskLink,
      onInviteClick,
      newTasks,
      filtered,
    } = this.props;
    const tasks = event ? event.deliverables.edges.map(edge => edge.node) : [];
    const tasksCount = tasks.length;

    const scrollContainer = document.querySelector('.site-content');

    const allTasksCount = totalCountEvent ? totalCountEvent.tasksCount.totalCount : 0;

    return (
      <StyledContent>
        {(loading || tasksCount > 0) && (
          <Header>
            <StyledCheckbox
              checked={selectedTasks.length > 0}
              hidden={selectedTasks.length === 0}
              indeterminate={selectedTasks.length < tasks.length}
              onChange={this.handleCheckboxChange}
            />
            <TasksListHeader
              sort={this.props.sort}
              onSortChange={this.handleSortChange}
              showFolder
            />
          </Header>
        )}

        {loading && (
          <LoaderContainer>
            <Loader size={30} />
          </LoaderContainer>
        )}

        {!loading && filtered && tasksCount === 0 && (
          <EmptyView message="No tasks match filters" icon={<NoResult />} />
        )}

        {tasks.map(task => (
          <TaskRow
            key={task.id}
            task={task}
            folders={event ? event.folders.edges.map(edge => edge.node) : []}
            org={org}
            event={event}
            standalone
            selected={selectedTasks.some(selectedTask => selectedTask.id === task.id)}
            onSelect={this.handleTaskSelect}
            onUpdate={onTaskUpdate}
            onUpdateStatus={onTaskStatusUpdate}
            onRemove={onTaskRemove}
            onAssign={onTaskAssign}
            onUnassign={onTaskUnassign}
            onAddTag={onTaskAddTag}
            onRemoveTag={onTaskRemoveTag}
            onGetLink={onGetTaskLink}
            onInviteClick={onInviteClick}
            new={newTasks.includes(task.id)}
            showFolder
          />
        ))}
        {scrollContainer && (
          <>
            <InfiniteScrollListener
              scrollContainer={scrollContainer}
              onScrollToBottom={this.handleScrollToBottom}
              totalCount={allTasksCount}
              pageSize={50}
            />
            <InfiniteScrollLoading loading={this.state.refetching} />
            <ScrollbarSizes
              shouldUpdate={tasksCount}
              scrollContainer={scrollContainer}
              onScrollbarSizesChange={this.handleScrollbarSizesChange}
              vScrollbarSize={this.state.vScrollbarSize}
              hScrollbarSize={this.state.hScrollbarSize}
            />
          </>
        )}
      </StyledContent>
    );
  }
}
export default createPaginationContainer(
  TasksDueDateViewContent,
  {
    event: graphql`
      fragment TasksDueDateViewContent_event on Event {
        ...TaskRow_event
        folders {
          edges {
            node {
              id
              name
              order
              viewerCanReorder
              ...TasksFolder_folder
            }
          }
        }
        deliverables(first: $count, after: $cursor, filters: $filters, includeSubtasks: true)
          @connection(key: "TasksDueDateViewContent_deliverables", filters: []) {
          edges {
            node {
              id
              name
              status
              viewerCanUpdateStatus
              viewerCanUpdateDueDate
              viewerCanAssign
              viewerCanReorder
              viewerCanUpdateTags
              viewerCanDelete
              folderId
              assignees {
                edges {
                  node {
                    id
                    firstName
                    lastName
                    email
                  }
                }
              }
              parent {
                id
              }
              tags {
                edges {
                  node {
                    id
                  }
                }
              }
              ...TaskRow_task
              ...TasksFolder_tasks
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    `,
    org: graphql`
      fragment TasksDueDateViewContent_org on Org {
        ...TaskRow_org
      }
    `,
    totalCountEvent: graphql`
      fragment TasksDueDateViewContent_totalCountEvent on Event {
        tasksCount: deliverables {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.event && props.event.deliverables;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
      };
    },
    query: graphql`
      query TasksDueDateViewContentQuery(
        $eventSlug: String!
        $filters: DeliverableFilters
        $count: Int!
        $cursor: String
      ) {
        event(slug: $eventSlug) {
          ...TasksDueDateViewContent_event
        }
      }
    `,
  },
);
