/* @flow */
import { createFragmentContainer, graphql } from 'react-relay';

import formatPercentage from 'utils/number/formatPercentage';

import { type CategoryCellPropsType } from './index';

import { type CategoryPaidOfActualCell_categoryEdge } from './__generated__/CategoryPaidOfActualCell_categoryEdge.graphql';

const CategoryPaidOfActualCell = ({
  categoryEdge,
  categoryEdgeMock,
  adding,
}: CategoryCellPropsType<CategoryPaidOfActualCell_categoryEdge>) => {
  if (adding) return '-';

  const { paidAmount, actualAmount } = categoryEdge || categoryEdgeMock || {};

  return formatPercentage(paidAmount / actualAmount) || '-';
};

export default createFragmentContainer(
  CategoryPaidOfActualCell,
  graphql`
    fragment CategoryPaidOfActualCell_categoryEdge on EventBudgetCategoryEdge {
      actualAmount
      paidAmount
    }
  `,
);
