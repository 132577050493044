/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import isValidWebsite from 'utils/validators/isValidWebsite';

import { type CellPropsType } from 'components/budget/Table';

import { type EventCellPropsType } from './index';

import type { EventVirtualLocation_contactEvent } from './__generated__/EventVirtualLocation_contactEvent.graphql';

const StyledLink = styled.a`
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

const LocationText = styled.div`
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EventVirtualLocation = ({
  contactEvent,
}: CellPropsType<EventCellPropsType<EventVirtualLocation_contactEvent>>) => {
  const virtualLocation =
    (contactEvent.node.zoomLocation && contactEvent.node.zoomLocation.joinUrl) ||
    (contactEvent.node.g2wLocation && contactEvent.node.g2wLocation.registrationUrl) ||
    contactEvent.node.virtualLocation;
  return virtualLocation && isValidWebsite(virtualLocation) ? (
    <StyledLink target="_blank" href={virtualLocation} title={virtualLocation}>
      {virtualLocation}
    </StyledLink>
  ) : (
    <LocationText>{virtualLocation}</LocationText>
  );
};

export default createFragmentContainer(
  EventVirtualLocation,
  graphql`
    fragment EventVirtualLocation_contactEvent on ContactEventEdge {
      node {
        virtualLocation
        zoomLocation {
          joinUrl
        }
        g2wLocation {
          registrationUrl
        }
      }
    }
  `,
);
