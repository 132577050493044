/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VendorStreet_vendor$ref: FragmentReference;
export type VendorStreet_vendor = {|
  +street: ?string,
  +$refType: VendorStreet_vendor$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VendorStreet_vendor",
  "type": "Vendor",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "street",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b373d43f287f459cbe3cf695994aeab1';
module.exports = node;
