/* @flow */

import { type FieldType } from './types';

const fieldTypeOptions: $ReadOnlyArray<{ label: string, value: FieldType }> = [
  {
    label: 'Text Input',
    value: 'TEXT',
  },
  {
    label: 'Text Area',
    value: 'TEXTAREA',
  },
  {
    label: 'Link',
    value: 'LINK',
  },
  {
    label: 'Number',
    value: 'NUMBER',
  },
  {
    label: 'Amount',
    value: 'CURRENCY',
  },
  {
    label: 'Date',
    value: 'DATE',
  },
  {
    label: 'Boolean',
    value: 'BOOLEAN',
  },
  {
    label: 'Member Select',
    value: 'USER_SELECT',
  },
  {
    label: 'Member Multiselect',
    value: 'USER_MULTISELECT',
  },
  {
    label: 'Single Select',
    value: 'SELECT',
  },
  {
    label: 'Multiselect',
    value: 'MULTISELECT',
  },
];

export default fieldTypeOptions;
