/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type { TasksFromWindow } from './createTasks';

const mutation = graphql`
  mutation assignTasksMutation($input: AssignDeliverablesInput!) {
    assignDeliverables(input: $input) {
      deliverables {
        id
        following
        name
        assignees {
          edges {
            node {
              id
              firstName
              lastName
              avatar
              email
            }
          }
        }
      }
    }
  }
`;

export default function assignTasks(
  taskIds: Array<string>,
  userId: string,
  fromWindow: TasksFromWindow,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableIds: taskIds,
        userId,
        fromWindow,
      },
    },
  });
}
