/* @flow */
import React from 'react';

import { Content } from 'components/page/Content';

import CustomFieldSettingsQuery from './CustomFieldSettingsQuery';

export default class AttendanceSettings extends React.Component<{}> {
  render() {
    const filters = {
      customizableType: ['EVENTCONTACT'],
      includeDisabled: true,
      includeRestrictManagingOptions: false,
    };

    return (
      <Content>
        <CustomFieldSettingsQuery filters={filters} customizableType="EVENTCONTACT" />
      </Content>
    );
  }
}
