/**
 * @flow
 * @relayHash 6a7ff9b556ca7cdc61f6769931ddc5b0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RegistrationFormTemplate_query$ref = any;
export type RegistrationFormTemplateRefetchQueryVariables = {||};
export type RegistrationFormTemplateRefetchQueryResponse = {|
  +query: {|
    +$fragmentRefs: RegistrationFormTemplate_query$ref
  |}
|};
export type RegistrationFormTemplateRefetchQuery = {|
  variables: RegistrationFormTemplateRefetchQueryVariables,
  response: RegistrationFormTemplateRefetchQueryResponse,
|};
*/


/*
query RegistrationFormTemplateRefetchQuery {
  query {
    ...RegistrationFormTemplate_query
  }
}

fragment RegistrationFormTemplate_query on Query {
  org {
    id
    registrationForm {
      eventName
      updatedAt
      id
    }
    settings {
      tz
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RegistrationFormTemplateRefetchQuery",
  "id": null,
  "text": "query RegistrationFormTemplateRefetchQuery {\n  query {\n    ...RegistrationFormTemplate_query\n  }\n}\n\nfragment RegistrationFormTemplate_query on Query {\n  org {\n    id\n    registrationForm {\n      eventName\n      updatedAt\n      id\n    }\n    settings {\n      tz\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RegistrationFormTemplateRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RegistrationFormTemplate_query",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RegistrationFormTemplateRefetchQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "query",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "org",
            "storageKey": null,
            "args": null,
            "concreteType": "Org",
            "plural": false,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "registrationForm",
                "storageKey": null,
                "args": null,
                "concreteType": "RegistrationForm",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "eventName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "updatedAt",
                    "args": null,
                    "storageKey": null
                  },
                  v0
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "settings",
                "storageKey": null,
                "args": null,
                "concreteType": "OrgSettings",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "tz",
                    "args": null,
                    "storageKey": null
                  },
                  v0
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c10723caebb327eccfaf281b4c24b3c6';
module.exports = node;
