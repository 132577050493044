/* @flow */
import type { LocationShape } from 'react-router-dom';

export type HomepageGraphQLValue =
  | 'UPCOMING_EVENTS'
  | 'EVENTS'
  | 'MY_EVENTS'
  | 'MY_TASKS'
  | 'ALL_TASKS';

type HomePage = {
  key: HomepageGraphQLValue,
  location: LocationShape,
  label: string,
};

export const homepages = (userId: string): $ReadOnlyArray<HomePage> => {
  return [
    {
      key: 'UPCOMING_EVENTS',
      location: {
        pathname: '/dashboard/upcoming_events',
        search: 'date=upcoming&requestStatuses=N_A,APPROVED',
      },
      label: 'Upcoming events',
    },
    {
      key: 'EVENTS',
      location: { pathname: '/dashboard/all_events', search: 'requestStatuses=N_A,APPROVED' },
      label: 'All events',
    },
    {
      key: 'MY_EVENTS',
      location: {
        pathname: '/dashboard/my_events',
        search: `date=upcoming&requestStatuses=N_A,APPROVED&staffIds=${userId}`,
      },
      label: 'My events',
    },
    { key: 'ALL_TASKS', location: { pathname: '/dashboard/all_tasks' }, label: 'All tasks' },
    { key: 'MY_TASKS', location: { pathname: '/dashboard/my_tasks' }, label: 'My tasks' },
  ];
};

export default (key: string, userId: string): LocationShape => {
  const homepage = homepages(userId).find(x => x.key === key.toUpperCase());
  return homepage ? homepage.location : { pathname: `/dashboard/${key}` };
};
