/* @flow */
import React from 'react';
import styled from 'styled-components';
import Clipboard from 'clipboard';
import moment from 'moment-timezone';

import formatDateTimeRange from 'utils/date/formatDateTimeRange';

import { type G2WLocationType } from '.';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px;
  background: rgba(0, 0, 0, 0.7);
  transform: translateX(70%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
`;

const Root = styled.div`
  position: relative;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    ${Overlay} {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
`;

const CardLink = styled.div`
  display: block;
  padding: 15px;
  color: #fff;
  background: linear-gradient(to bottom, #5b92f4, #3d9ad9);

  [href] {
    &:hover {
      background: linear-gradient(to top, #5b92f4, #3d9ad9);
    }
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const Date = styled.div`
  margin-top: 3px;
  font-size: 13px;
`;

const OverlayLink = styled.a`
  margin: 3px 0;
  font-size: 12px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }

  svg,
  i {
    margin-right: 3px;
  }

  svg {
    path {
      fill: currentColor;
    }
  }
`;

export default class G2WLocationCard extends React.PureComponent<{
  g2wLocation: G2WLocationType,
}> {
  copyLinkRef = React.createRef();

  clipboard = null;

  componentDidMount() {
    this.setupClipboard();
  }

  componentDidUpdate() {
    if (this.copyLinkRef.current == null) {
      this.destroyClipboard();
    } else {
      this.setupClipboard();
    }
  }

  componentWillUnmount() {
    this.destroyClipboard();
  }

  setupClipboard = () => {
    if (this.copyLinkRef.current != null) {
      this.clipboard = new Clipboard(this.copyLinkRef.current, {
        text: () => this.props.g2wLocation.registrationUrl,
      });
    }
  };

  destroyClipboard = () => {
    if (this.clipboard) {
      this.clipboard.destroy();
      this.clipboard = null;
    }
  };

  render() {
    const g2wLocation = this.props.g2wLocation;

    return (
      <Root>
        <CardLink
          href={g2wLocation.g2wUrl || undefined}
          target="_blank"
          rel="noreferrer noopener"
          as={g2wLocation.g2wUrl ? 'a' : 'div'}
        >
          <Title>{g2wLocation.subject}</Title>

          <Date>
            {(g2wLocation.times || [])
              .map(({ startTime, endTime }) =>
                formatDateTimeRange({
                  startDate: moment(startTime).tz(g2wLocation.timezone),
                  endDate: moment(endTime).tz(g2wLocation.timezone),
                }),
              )
              .join('; ') || 'Recurring'}
          </Date>
        </CardLink>

        {g2wLocation.registrationUrl && (
          <Overlay>
            <OverlayLink ref={this.copyLinkRef}>
              <i className="fa fa-fw fa-clone" /> Copy Registration Link
            </OverlayLink>

            <OverlayLink
              href={g2wLocation.registrationUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              <i className="fa fa-fw fa-external-link" /> Open Registration Link
            </OverlayLink>
          </Overlay>
        )}
      </Root>
    );
  }
}
