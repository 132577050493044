/* @flow */
import * as React from 'react';

import EditableQuestionMultiselectOptions from '../EditableQuestion/EditableQuestionMultiselectOptions';
import EditableQuestionSelectOptions from '../EditableQuestion/EditableQuestionSelectOptions';
import EditableQuestionUserSelectOptions from '../EditableQuestion/EditableQuestionUserSelectOptions';
import mappingCustomKinds from './mappingCustomKinds';
import { type QuestionType } from './types';

const mappingCustomOptionsComponents: $ObjMap<
  typeof mappingCustomKinds,
  () => ?React.ComponentType<{
    question: QuestionType,
    onChangeQuestion: ($Exact<{ ...QuestionType }>) => void,
  }>,
> = {
  DEFAULT: null,
  TEXT: () => null,
  TEXTAREA: () => null,
  LINK: () => null,
  NUMBER: () => null,
  CURRENCY: () => null,
  DATE: () => null,
  BOOLEAN: () => null,
  USER_SELECT: EditableQuestionUserSelectOptions,
  USER_MULTISELECT: EditableQuestionUserSelectOptions,
  SELECT: EditableQuestionSelectOptions,
  MULTISELECT: EditableQuestionMultiselectOptions,
};

export default mappingCustomOptionsComponents;
