/* @flow */
import React from 'react';
import styled, { css } from 'styled-components';
import { createFragmentContainer, graphql } from 'react-relay';

import MaterialAvatar from 'components/material/MaterialAvatar';
import CheckBox from 'components/SharedForm/CheckBox';
import Radio from 'components/SharedForm/Radio';

import { type EditableQuestionSelectOptionRowForContact_user } from './__generated__/EditableQuestionSelectOptionRowForContact_user.graphql';

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: default;
`;

const OptionLabel = styled.div`
  flex: 1 1 auto;
  margin-left: ${props => (props.isUser ? '12px' : '20px')};
  font-size: 13px;
  color: ${props => (props.active ? '#4a5665' : 'rgba(74, 86, 101, 0.54)')};
`;

const OptionAction = styled.div`
  line-height: 20px;
  font-size: 20px;
  cursor: pointer;
  color: #d8d8d8;
  &:hover {
    color: #aaa;
  }
`;

const RemoveIcon = styled.div`
  transform: rotate(45deg);
  font-size: 30px;
  &:after {
    content: '+';
  }
`;

const ShowHideIcon = styled.div`
  > i:last-child {
    display: none;
  }
  &:hover {
    i:first-child {
      display: none;
    }
    i:last-child {
      display: inline-block;
    }
  }
`;

const StyledMaterialAvatar = styled(MaterialAvatar)`
  margin-left: 10px;
  ${props =>
    !props.active &&
    css`
      opacity: 0.54;
    `};
`;

class EditableQuestionSelectOptionRowForContact extends React.PureComponent<{
  type: 'checkbox' | 'radio',
  active: boolean,
  option: {
    value: string,
    label: string,
  },
  user: ?EditableQuestionSelectOptionRowForContact_user,
  onRemove?: (label: string) => void,
  onShow: (id: string) => void,
  onHide: (id: string) => void,
}> {
  handleRemove = () => {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.option.label);
    }
  };

  handleShow = () => {
    this.props.onShow(this.props.option.value);
  };

  handleHide = () => {
    this.props.onHide(this.props.option.value);
  };

  render() {
    const { type, active, option, user } = this.props;
    return (
      <OptionRow>
        {type === 'checkbox' ? (
          <CheckBox checked={false} disabled compact />
        ) : (
          <Radio checked={false} disabled compact />
        )}
        {user && <StyledMaterialAvatar user={user} radius={11.5} active={active} />}
        <OptionLabel active={active} isUser={user}>
          {option.label}
        </OptionLabel>
        <OptionAction>
          {this.props.onRemove && <RemoveIcon onClick={this.handleRemove} />}
          {!active && (
            <ShowHideIcon onClick={this.handleShow}>
              <i className="fa fa-fw fa-eye-slash" />
              <i className="fa fa-fw fa-eye" />
            </ShowHideIcon>
          )}
          {active && (
            <ShowHideIcon onClick={this.handleHide}>
              <i className="fa fa-fw fa-eye" />
              <i className="fa fa-fw fa-eye-slash" />
            </ShowHideIcon>
          )}
        </OptionAction>
      </OptionRow>
    );
  }
}

export default createFragmentContainer(
  EditableQuestionSelectOptionRowForContact,
  graphql`
    fragment EditableQuestionSelectOptionRowForContact_user on User {
      id
      ...MaterialAvatar_user
    }
  `,
);
