/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.color || '#3e4859'};
`;

const BoldText = (props: { color?: string, children: React.Node }) => {
  const { color, children } = props;
  return <Container color={color}>{children}</Container>;
};

export default BoldText;
