/* @flow */
import { graphql } from 'react-relay';

const query = graphql`
  query queryEventsImportWindowQuery {
    org {
      id
      name
      salesforceAccount {
        id
      }
      users {
        edges {
          node {
            firstName
            lastName
            email
          }
        }
      }
      customFields(customizableType: [EVENT]) {
        edges {
          node {
            id
            label
            fieldName
            kind
            order
            section {
              order
            }
          }
        }
      }
    }
  }
`;

export default query;
