/* @flow */
import { omit } from 'lodash';

import getCustomFieldPlainValue from 'utils/customization/getCustomFieldPlainValue';
import type { CustomFieldsType } from 'utils/customization/types';

import type { ContactFormSubmission_contactForm } from 'components/ContactForm/ContactFormSubmission/__generated__/ContactFormSubmission_contactForm.graphql';
import validateCondition from 'components/FormLogic/lib/validateCondition';
import { type FilterItem, getFieldName } from 'components/material/Filters/FilterSelectorRow';
import { type CompanyDefaultFields } from 'views/Main/AllContacts/Company/tabs/CompanyProfile/CompanyFormFields';

import getDefaultFieldValue from '../getDefaultFieldValue';
import { type ExtendedContactDefaultFields } from '../suggestedFields';
import { type QuestionType } from '../types';

export type ContactFormFieldsValueType = {
  defaultFieldValues: $Shape<ExtendedContactDefaultFields>,
  companyDefaultFieldValues: $Shape<CompanyDefaultFields>,
  customFieldValues: CustomFieldsType,
};

export type ContactFormFieldType = $PropertyType<
  $ElementType<
    $PropertyType<$PropertyType<ContactFormSubmission_contactForm, 'contactFormFields'>, 'edges'>,
    0,
  >,
  'node',
>;

type RuleType = $PropertyType<
  $ElementType<
    $PropertyType<$NonMaybeType<$PropertyType<ContactFormFieldType, 'rules'>>, 'edges'>,
    0,
  >,
  'node',
>;

const getSavedConditions = rule => {
  return [
    ...rule.customSavedTextFilters.edges.map(edge => edge.node),
    ...rule.customSavedTextareaFilters.edges.map(edge => edge.node),
    ...rule.customSavedLinkFilters.edges.map(edge => edge.node),
    ...rule.customSavedNumberFilters.edges.map(edge => edge.node),
    ...rule.customSavedCurrencyFilters.edges.map(edge => edge.node),
    ...rule.customSavedBooleanFilters.edges.map(edge => edge.node),
    ...rule.customSavedMultiselectFilters.edges.map(({ node: filter }) => ({
      ...filter,
      optionIds: filter.options.edges
        .map(edge => (edge.node && edge.node.option ? edge.node.option.id : null))
        .filter(Boolean),
    })),
    ...rule.customSavedDateFilters.edges.map(edge => edge.node),
    ...rule.customSavedUserMultiselectFilters.edges.map(({ node: filter }) => ({
      ...filter,
      users: filter.options.edges.map(edge => edge.node.user).filter(Boolean),
    })),
  ];
};

const getContactFormFieldValue = (
  condition: FilterItem,
  contactFormFieldValues: ContactFormFieldsValueType,
) => {
  const {
    defaultFieldValues,
    companyDefaultFieldValues,
    customFieldValues,
  } = contactFormFieldValues;
  const fieldName = getFieldName(condition);

  if (fieldName) {
    return getDefaultFieldValue(
      { defaultFieldValues, companyDefaultFieldValues },
      ((condition: any): QuestionType),
    );
  }

  return getCustomFieldPlainValue(
    { ...omit(condition.customField, 'required') },
    customFieldValues,
  );
};

const validateRulesAndConditions = (
  rules: $ReadOnlyArray<RuleType>,
  contactFormValues: ContactFormFieldsValueType,
  tz: string,
) => {
  return rules.reduce((makeRequired, rule) => {
    // return if just one rule is true
    if (makeRequired) {
      return true;
    }

    return getSavedConditions(rule).reduce((conditionCheck, condition) => {
      const castCondition = ((condition: any): FilterItem);
      // If one condition is false just return it
      if (conditionCheck === false) {
        return false;
      }

      const values = getContactFormFieldValue(castCondition, contactFormValues);

      return validateCondition(castCondition, values, tz);
    }, true);
  }, false);
};

export default validateRulesAndConditions;
