/* @flow */
import 'draft-js/dist/Draft.css';

import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  AtomicBlockUtils,
  ContentBlock,
  convertToRaw,
  Editor,
  EditorState,
  genKey,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';

import { upload } from 'utils/Attachments';
import draftContentFromMarkdown from 'utils/draft/draftContentFromMarkdown';
import draftContentToMarkdown from 'utils/draft/draftContentToMarkdown';
import type { CircaFile } from 'utils/uploading/types';

import { DropZone } from 'components/DropZone';

import decorator from './decorator';
import Image, { PREVIEW_ICON } from './Image';
import RichTextControls from './RichTextControls';
import RichTextRenderer from './RichTextRenderer';
import determineImage from './utils/determineImage';

export const Actions = styled.div`
  visibility: hidden;
  position: absolute;
  right: 10px;
  top: 8px;
`;

export const Container = styled.div`
  position: relative;
  &:hover {
    ${Actions} {
      visibility: visible;
    }
  }
`;

export const EditorContainer = styled.div`
  box-shadow: 0 1px 0 ${props => props.borderColor || props.theme.borderColor};
  transition: box-shadow 0.3s;
  color: ${props => props.theme.rowPrimaryTextColor};
  line-height: 1.8;
  overflow: auto;
  outline: none;
  ${props =>
    props.focused
      ? `
    box-shadow: 0 1px 0 ${props.theme.primaryActionColor};
    `
      : `
    &:hover {
      box-shadow: 0 1px 0 ${props.theme.secondaryActionDarkerColor};
    }
  `};
  .DraftEditor-root {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
  }
  .public-DraftEditorPlaceholder-root {
    flex: 1 1 auto;
    color: ${props => props.theme.labelColor};
  }
  .DraftEditor-editorContainer {
    flex: 1 1 auto;
    overflow: auto;
    padding: 1px 0;
    figure {
      outline: none;
    }
    ol {
      padding-left: 15px;
    }
  }
  ${props =>
    props.viewMode &&
    css`
      border-radius: 3px;
      font-size: 13px;
      padding: 8px 10px;
      margin-left: -10px;
      padding-right: 55px;
      box-shadow: none;
      &:hover {
        box-shadow: none;
        background: ${props.theme.primaryRowColor};
      }
    `};
  ${props =>
    props.viewMode &&
    !props.disabled &&
    css`
      cursor: pointer;
    `};
`;

export const EditorPanel = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
`;

export const ContentWrapper = styled.div``;

const ManageButtons = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
`;

const IconButton = styled.i`
  ${props =>
    props.primary
      ? `color: ${props.theme.primaryActionColor};`
      : `color: ${
          props.active ? props.theme.secondaryActionDarkerColor : props.theme.secondaryActionColor
        };`};
  font-size: 16px;
  cursor: pointer;
  margin-left: 6px;
  &:hover {
    color: ${props =>
      props.primary
        ? props.theme.primaryActionDarkerColor
        : props.theme.secondaryActionDarkerColor};
  }
  &:focus {
    outline: none;
  }
`;

const Action = styled.i`
  color: ${props => props.theme.secondaryActionColor};
  font-size: 14px;
  margin-left: 3px;
  &:hover {
    color: ${props => props.theme.secondaryActionDarkerColor};
  }
`;

const shortcuts = {
  '1.': 'ordered-list-item',
  '-': 'unordered-list-item',
};

type HandledStatus = 'handled' | 'not-handled';
export type SupportInlineImage = {
  onImageAdd?: (file: CircaFile) => void,
  onImagePreview: (previewId: string) => void,
  setImageUploadingStatus?: (text: string, isError?: boolean) => void,
  noUploadPreview?: boolean,
};

export default class RichText extends React.PureComponent<
  {
    className?: string,
    defaultValue?: ?string,
    onCancel?: (?string) => void,
    onSave?: (?string, ?$ReadOnlyArray<string>) => void,
    onRemove?: () => Promise<any>,
    autoFocus?: boolean,
    viewMode?: boolean,
    onStartEditing?: ?() => void,
    placeholder?: string,
    disabled?: boolean,
    onBlur?: (content: string) => void,
    onChange?: string => void,
    formRelatedField?: string,
    supportInlineImage?: ?SupportInlineImage,
    hideActionButtons?: boolean,
    shouldGetFocus?: boolean,
    stickyPanel?: boolean,
    sweetEditor?: boolean,
    borderColor?: string,
  },
  {
    focused: boolean,
    editing: boolean,
    editorState: EditorState,
    emojiOverlayShown: boolean,
    query: string,
    replacementRanges: { start: number, end: number },
    emojiButtonTriggered: boolean,
    imageUploading: boolean,
    disableEditor: boolean,
    focusedEntity: string,
    lastSavedValue: string,
  },
> {
  state = {
    editorState: EditorState.createWithContent(this.initialContentState(), decorator),
    focused: false,
    editing: false,
    emojiOverlayShown: false,
    query: '',
    replacementRanges: { start: 0, end: 0 },
    emojiButtonTriggered: false,
    imageUploading: false,
    disableEditor: false,
    focusedEntity: '',
    lastSavedValue: this.props.defaultValue || '',
  };

  reloadPopup: ?boolean = false;

  editor: Editor;

  disableFocus: ?boolean;

  richControls: ?RichTextControls;

  dropZone: ?HTMLElement;

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleNotebeforeunload);
    if (this.props.autoFocus && this.editor != null) {
      this.editor.focus();
    }
  }

  componentDidUpdate(
    prevProps: $PropertyType<RichText, 'props'>,
    prevState: $PropertyType<RichText, 'state'>,
  ) {
    if (
      (prevProps.viewMode && !this.props.viewMode && this.props.autoFocus) ||
      (!prevProps.shouldGetFocus && this.props.shouldGetFocus)
    ) {
      this.editor.focus();
      if (this.props.onStartEditing) {
        this.props.onStartEditing();
      }
      this.handleFocus();
    }
    if (prevState.disableEditor && !this.state.disableEditor) {
      this.forceRerenderImage();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleNotebeforeunload);
  }

  handleNotebeforeunload = (e: Event) => {
    const content = this.state.editorState.getCurrentContent();
    if (this.state.lastSavedValue.trim() !== draftContentToMarkdown(content).trim()) {
      // $FlowFixMe
      e.returnValue = 'Changes you made may not be saved.';
      this.reloadPopup = true;
    }
  };

  handleImageBlured = () => {
    this.setState({ disableEditor: false });
  };

  forceRerenderImage = (focusedEntity: string = '') => {
    // This is turn around on draft api to call imageBlockRenderer
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const newEditorState = EditorState.push(
      EditorState.forceSelection(editorState, editorState.getSelection()),
      contentState,
      'change-block-data',
    );
    this.setState({ focusedEntity });
    this.handleChange(newEditorState);
  };

  handleChange = (editorState: EditorState) => {
    this.handleEmojiOverlayShow(editorState);
    this.reloadPopup = false;
    this.setState({ editorState, imageUploading: false });
    const content = draftContentToMarkdown(editorState.getCurrentContent());
    if (this.props.onChange != null) {
      this.props.onChange(content);
    }
  };

  handleFocus = () => {
    this.setState({ focused: true, editing: true });
  };

  isRelatedField = (clickedElement: EventTarget) => {
    return (
      clickedElement instanceof HTMLElement && clickedElement.id === this.props.formRelatedField
    );
  };

  isEditorRelatedElement = (clickedElement: EventTarget) => {
    if (!(clickedElement instanceof HTMLElement)) {
      return false;
    }
    const elementName = clickedElement.getAttribute('name');
    return (
      ['richCancelButton', PREVIEW_ICON].includes(clickedElement.id) ||
      (elementName && ['link', 'emoji', 'linkName'].includes(elementName))
    );
  };

  handleBlur = ({ relatedTarget }: SyntheticFocusEvent<HTMLElement>) => {
    this.setState({ focused: false });
    const content = draftContentToMarkdown(this.state.editorState.getCurrentContent());
    if (this.isRelatedField(relatedTarget) && this.props.onBlur != null) {
      this.props.onBlur(content);
      this.setState({ editing: false });
      return;
    }
    if (
      this.state.imageUploading ||
      this.isEditorRelatedElement(relatedTarget) ||
      determineImage(relatedTarget) ||
      this.reloadPopup
    ) {
      return;
    }
    this.handleSave();
  };

  handleClick = (e: SyntheticMouseEvent<HTMLAnchorElement | HTMLInputElement>) => {
    const isImageClicked = determineImage(e.target);
    if (!isImageClicked && this.state.disableEditor) {
      this.setState({ editing: true, focused: true });
    }
    this.setState({ disableEditor: isImageClicked });

    let el = e.target;
    do {
      if (
        el instanceof HTMLElement &&
        typeof el.tagName === 'string' &&
        el.tagName.toLowerCase() === 'a'
      ) {
        e.stopPropagation();
        e.preventDefault();
        if (el instanceof HTMLAnchorElement && el.href != null) {
          window.open(el.href);
        }
        return;
      }
      el = el instanceof HTMLElement && el.parentNode != null ? el.parentNode : null;
    } while (el != null);

    if (
      !this.props.disabled &&
      (this.props.viewMode || this.props.sweetEditor) &&
      this.props.onStartEditing
    ) {
      this.props.onStartEditing();
      this.setState({ editing: true, focused: true });
    }

    const clickingOnInput = e.currentTarget.type === 'text';
    if ((!this.state.focused && !clickingOnInput && !this.disableFocus) || !isImageClicked) {
      this.editor.focus();
    }
  };

  checkAndApplyList = (editorState: EditorState): HandledStatus => {
    let selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      return 'not-handled';
    }

    const content = editorState.getCurrentContent();
    const block = content.getBlockMap().get(selection.getStartKey());
    const startOffset = selection.getStartOffset();

    const matchShortcut = Object.keys(shortcuts).reduce((isMatch, nextShortcut) => {
      if (
        startOffset === nextShortcut.length &&
        nextShortcut === block.getText().substr(0, nextShortcut.length) &&
        block.getType() !== shortcuts[nextShortcut]
      ) {
        return nextShortcut;
      }
      return isMatch;
    }, '');

    if (matchShortcut === '') {
      return 'not-handled';
    }

    selection = selection.set('anchorOffset', 0);
    selection = selection.set('focusOffset', matchShortcut.length);
    const newContent = Modifier.replaceText(content, selection, '');
    const newEditorState = EditorState.push(editorState, newContent, 'backspace-character');
    this.handleChange(RichUtils.toggleBlockType(newEditorState, shortcuts[matchShortcut]));
    return 'handled';
  };

  handleEmojiTrigger = (editorState: EditorState): HandledStatus => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed() || this.state.emojiOverlayShown) {
      return 'not-handled';
    }
    const startOffset = selection.getStartOffset();

    this.setState({
      emojiOverlayShown: true,
      query: '',
      replacementRanges: { start: startOffset, end: startOffset + 1 },
    });
    return 'not-handled';
  };

  triggerEmojiButton = () => {
    this.setState({ emojiOverlayShown: true, emojiButtonTriggered: true });
  };

  handleTriggerFocus = () => {
    this.editor.focus();
  };

  handleEmojiOverlayHide = () => {
    this.setState({
      emojiOverlayShown: false,
      query: '',
      emojiButtonTriggered: false,
    });
    this.handleTriggerFocus();
  };

  handleEmojiOverlayShow = (editorState: EditorState) => {
    const selection = editorState.getSelection();
    if (
      !selection.isCollapsed() ||
      !this.state.emojiOverlayShown ||
      this.state.emojiButtonTriggered
    ) {
      return;
    }

    const content = editorState.getCurrentContent();
    const block = content.getBlockMap().get(selection.getStartKey());
    const startOffset = selection.getStartOffset();
    const text = block.getText();

    let currentIndex = startOffset;
    let currentChar = 'initial';

    // Finding start of emoji query
    // from current pointer position go back until find emoji shortcut :
    // Or hide emoji overlay if reach start of word (space charachter) or reach start of line
    while (currentChar !== ':') {
      if (!currentChar || !currentChar.trim() || currentIndex === 0) {
        this.handleEmojiOverlayHide();
        return;
      }
      currentIndex -= 1;
      currentChar = text.substr(currentIndex, 1);
    }

    const queryStart = currentIndex + 1;
    currentIndex = startOffset;
    currentChar = 'initial';

    // Finding end of emoji query
    // from current pointer position go to right until reach word end
    while (currentChar && currentChar.trim()) {
      currentChar = text.substr(currentIndex, 1);
      currentIndex += 1;
    }

    const queryEnd = currentIndex - 1;
    const query = text.substring(queryStart, queryEnd);
    const replacementRanges = { start: queryStart - 1, end: queryEnd };
    this.setState({ query, replacementRanges });
  };

  handleBeforeInput = (character: string, editorState: EditorState): HandledStatus => {
    if (character === ' ') {
      return this.checkAndApplyList(editorState);
    }

    if (character === ':') {
      return this.handleEmojiTrigger(editorState);
    }

    return 'not-handled';
  };

  keyBindingFn = (e: SyntheticKeyboardEvent<>): string => {
    if (e.keyCode === 75 && KeyBindingUtil.hasCommandModifier(e)) {
      return 'to-link';
    }
    return getDefaultKeyBinding(e);
  };

  removeBlockWithKey = (editorState: EditorState, blockKey: string) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const newBlockMap = blockMap.filter(block => block.getKey() !== blockKey);

    const newContentState = contentState.merge({
      blockMap: newBlockMap,
    });

    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const blockBeforeKey = contentState.getBlockBefore(startKey).getKey();
    const newSelection = SelectionState.createEmpty().merge({
      ...selection.toJS(),
      anchorKey: blockBeforeKey,
      focusKey: blockBeforeKey,
    });

    return EditorState.forceSelection(EditorState.push(editorState, newContentState), newSelection);
  };

  getCommandAppliedState = (command: string) => {
    const editorState = this.state.editorState;
    if (command === 'backspace') {
      const contentState = editorState.getCurrentContent();

      const selection = editorState.getSelection();
      const blockKey = selection.getStartKey();
      const block = contentState.getBlockForKey(blockKey);
      const selectionEntityKey = block.getEntityAt(0);
      if (selectionEntityKey && contentState.getEntity(selectionEntityKey).getType() === 'IMAGE') {
        return this.removeBlockWithKey(editorState, blockKey);
      }
    }
    return RichUtils.handleKeyCommand(editorState, command);
  };

  getSelectedBlockElement = () => {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return null;
    let node = selection.getRangeAt(0).startContainer;
    do {
      if (node.getAttribute && node.getAttribute('data-block') === 'true') {
        return node;
      }
      node = node.parentNode;
    } while (node != null);
    return null;
  };

  positionScroll = () => {
    const currentNode = this.getSelectedBlockElement();
    if (this.dropZone && currentNode) {
      if (currentNode.offsetTop > this.dropZone.offsetHeight - 50) {
        this.dropZone.scrollTop = currentNode.offsetTop;
      }
    }
  };

  handleKeyCommand = (command: string): HandledStatus => {
    this.positionScroll();
    const newState = this.getCommandAppliedState(command);
    if (newState) {
      this.handleChange(newState);
      return 'handled';
    }

    if (command === 'to-link' && this.richControls && this.richControls.linkButton) {
      this.richControls.linkButton.click();
      return 'handled';
    }

    return 'not-handled';
  };

  handleSave = () => {
    const content = this.state.editorState.getCurrentContent();
    const contentState = this.state.editorState.getCurrentContent();
    const { entityMap } = convertToRaw(contentState);
    const currentImages = Object.keys(entityMap)
      .filter(key => entityMap[key].type === 'IMAGE')
      .map(key => entityMap[key].data.url);
    const { onSave } = this.props;
    if (onSave) {
      onSave(draftContentToMarkdown(content), currentImages);
      this.setState({ lastSavedValue: draftContentToMarkdown(content) });
    }

    this.disableFocus = true;
    this.setState({ editing: false }, () => {
      this.disableFocus = false;
    });

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  handleRemove = (e: SyntheticEvent<>) => {
    e.stopPropagation();
    if (this.props.onRemove) {
      this.props.onRemove().then(() => {
        this.setState(state => ({
          editing: false,
          editorState: EditorState.push(state.editorState, this.initialContentState()),
        }));
      });
    } else if (this.props.onSave) {
      this.props.onSave(null, []);
    }
  };

  handleCancel = () => {
    // if we don't disable it, the state doesn't get reset ¯\_(ツ)_/¯
    this.disableFocus = true;
    setTimeout(() => {
      this.setState(
        state => ({
          editing: false,
          editorState: EditorState.push(state.editorState, this.initialContentState()),
        }),
        () => {
          this.disableFocus = false;
          if (this.props.onCancel) {
            this.props.onCancel();
          }
        },
      );
    }, 0);
  };

  insertBlock = (direction: string, editorState: EditorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(selection.getStartKey());

    const blockMap = contentState.getBlockMap();
    // Split the blocks
    const blocksBefore = blockMap.toSeq().takeUntil(v => {
      return v === currentBlock;
    });
    const blocksAfter = blockMap
      .toSeq()
      .skipUntil(v => {
        return v === currentBlock;
      })
      .rest();
    const newBlockKey = genKey();
    const newBlocks =
      direction === 'before'
        ? [
            [
              newBlockKey,
              new ContentBlock({
                key: newBlockKey,
                type: 'unstyled',
                text: '',
              }),
            ],
            [currentBlock.getKey(), currentBlock],
          ]
        : [
            [currentBlock.getKey(), currentBlock],
            [
              newBlockKey,
              new ContentBlock({
                key: newBlockKey,
                type: 'unstyled',
                text: '',
              }),
            ],
          ];
    const newBlockMap = blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();
    const newContentState = contentState.merge({
      blockMap: newBlockMap,
      selectionBefore: selection,
      selectionAfter: selection,
    });
    const newSelectionState = SelectionState.createEmpty(newBlockKey);

    return EditorState.push(
      EditorState.forceSelection(
        EditorState.push(editorState, newContentState, 'insert-fragment'),
        newSelectionState,
      ),
      newContentState,
      'insert-fragment',
    );
  };

  getPreparedEditorState = () => {
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();

    const selection = editorState.getSelection();
    const block = contentState.getBlockForKey(selection.getStartKey());
    const selectionEntityKey = block.getEntityAt(0);
    if (selectionEntityKey && contentState.getEntity(selectionEntityKey).getType() === 'IMAGE') {
      return this.insertBlock('after', editorState);
    }
    return editorState;
  };

  insertImage = (url: string, fileName: string) => {
    const editorState = this.getPreparedEditorState();
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', {
      url,
      fileName,
      width: 350,
      focused: true,
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    this.setState({ focusedEntity: entityKey });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };

  isImage = (type: string) => {
    return type.split('/')[0] === 'image';
  };

  showInformativeStatusChange = (text: string) => {
    const supportInlineImage = this.props.supportInlineImage;
    if (supportInlineImage != null && supportInlineImage.setImageUploadingStatus != null) {
      supportInlineImage.setImageUploadingStatus(text);
    }
  };

  showNoImageWarning = (name: string = '') => {
    const supportInlineImage = this.props.supportInlineImage;
    if (supportInlineImage != null && supportInlineImage.setImageUploadingStatus != null) {
      const text = `The file "${name}" is not an image`;
      supportInlineImage.setImageUploadingStatus(text, true);
      setTimeout(() => this.showInformativeStatusChange(''), 5000);
    }
  };

  handleImageUpload = (file: CircaFile, isLast: boolean = true) => {
    if (!this.isImage(file.filetype)) {
      this.showNoImageWarning(file.filename);
      return;
    }
    const newEditorState = this.insertImage(file.fileurl, file.filename);
    this.setState({ editorState: newEditorState, disableEditor: true });
    this.editor.focus();
    const supportInlineImage = this.props.supportInlineImage;
    if (supportInlineImage != null && supportInlineImage.onImageAdd != null) {
      supportInlineImage.onImageAdd(file);
    }

    if (isLast) {
      this.handleSave();
    }
  };

  handleFileDrop = (files: $ReadOnlyArray<{ type: string, name: string }>) => {
    let uploadingText = `Uploading `;
    let isWarning = false;
    let filesCount = files.length;
    files.forEach(file => {
      if (this.isImage(file.type)) {
        uploadingText = `${uploadingText} "${file.name}",`;
        upload(file).then(uploadedFile => {
          filesCount -= 1;
          this.handleImageUpload(uploadedFile, filesCount === 0);
          this.showInformativeStatusChange('');
        });
      } else {
        this.showNoImageWarning(file.name);
        isWarning = true;
        filesCount -= 1;
        if (filesCount === 0) {
          this.handleSave();
        }
      }
    });
    if (!isWarning) {
      uploadingText = `${uploadingText.slice(0, uploadingText.length - 1)}`;
      this.showInformativeStatusChange(uploadingText);
    }
  };

  handleImagePreview = (url: string) => {
    if (this.props.supportInlineImage != null) {
      this.props.supportInlineImage.onImagePreview(url);
    }
  };

  handleResizeData = (contentBlock: ContentBlock) => (width: string) => {
    const entityKey = contentBlock.getEntityAt(0);
    if (entityKey) {
      const editorState = this.state.editorState;
      const contentState = editorState.getCurrentContent();
      const newContentState = contentState.mergeEntityData(entityKey, { width });
      const newEditorState = EditorState.push(
        EditorState.forceSelection(editorState, editorState.getSelection()),
        newContentState,
        'change-block-data',
      );
      this.handleChange(newEditorState);
    }
  };

  handleImageClick = (contentBlock: ContentBlock) => () => {
    const entityKey = contentBlock.getEntityAt(0);
    if (entityKey) {
      this.forceRerenderImage(entityKey);
    }
  };

  onImageUploadOpen = () => {
    this.setState({ imageUploading: true });
  };

  imageBlockRenderer = (contentBlock: ContentBlock) => {
    if (contentBlock.getType() === 'atomic') {
      const entityKey = contentBlock.getEntityAt(0);
      const contentState = this.state.editorState.getCurrentContent();
      const imageData = entityKey ? contentState.getEntity(entityKey).data : {};
      return {
        component: Image,
        editable: false,
        props: {
          viewMode: !this.state.editing,
          disabled: this.props.disabled,
          imageData,
          focused: this.state.focusedEntity === entityKey,
          onImageClick: this.handleImageClick(contentBlock),
          onResize: this.handleResizeData(contentBlock),
          onImagePreview: this.handleImagePreview,
          onImageBlured: this.handleImageBlured,
        },
      };
    }
    return null;
  };

  renderRichText = () => {
    const component = (
      <RichTextRenderer>
        <EditorContainer
          focused={this.state.focused}
          viewMode={this.props.viewMode}
          disabled={this.props.disabled}
          borderColor={this.props.borderColor}
        >
          <Editor
            editorState={this.state.editorState}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            handleBeforeInput={this.handleBeforeInput}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.keyBindingFn}
            blockRendererFn={this.imageBlockRenderer}
            ref={el => {
              this.editor = el;
            }}
            readOnly={this.props.viewMode || this.state.disableEditor}
            placeholder={this.props.placeholder}
            stripPastedStyles
          />
        </EditorContainer>
      </RichTextRenderer>
    );
    if (this.props.supportInlineImage != null && !this.props.disabled) {
      return (
        <ContentWrapper
          ref={el => {
            this.dropZone = el;
          }}
        >
          <DropZone
            noPreview={this.props.supportInlineImage.noUploadPreview}
            onDrop={this.handleFileDrop}
          >
            {component}
          </DropZone>
        </ContentWrapper>
      );
    }
    return component;
  };

  initialContentState() {
    return draftContentFromMarkdown(this.props.defaultValue || '');
  }

  render() {
    return (
      <Container onClick={this.handleClick} className={this.props.className}>
        {this.renderRichText()}
        {(this.props.stickyPanel || (this.state.editing && !this.props.viewMode)) && (
          <EditorPanel>
            <RichTextControls
              ref={el => {
                this.richControls = el;
              }}
              editorState={this.state.editorState}
              onChange={this.handleChange}
              query={this.state.query}
              target={this.editor}
              replacementRanges={this.state.replacementRanges}
              emojiOverlayShown={this.state.emojiOverlayShown}
              triggerEmojiButton={this.triggerEmojiButton}
              onEmojiOverlayHide={this.handleEmojiOverlayHide}
              emojiButtonTriggered={this.state.emojiButtonTriggered}
              onTriggerFocus={this.handleTriggerFocus}
              onImageUpload={this.handleImageUpload}
              onImageUploadOpen={this.onImageUploadOpen}
              supportInlineImage={!!this.props.supportInlineImage}
            />
            {!this.props.hideActionButtons && (
              <ManageButtons>
                <IconButton
                  id="richCancelButton"
                  tabIndex={0}
                  className="fa fa-fw fa-times"
                  onClick={this.handleCancel}
                />
                <IconButton
                  id="richSaveButton"
                  tabIndex={0}
                  className="fa fa-fw fa-check"
                  onClick={this.handleSave}
                  primary
                />
              </ManageButtons>
            )}
          </EditorPanel>
        )}

        {!this.props.disabled && this.props.viewMode && this.props.onStartEditing && (
          <Actions>
            <Action className="fa fa-fw fa-pencil" />
            {this.props.defaultValue !== '' && (
              <Action className="fa fa-fw fa-trash" onClick={this.handleRemove} />
            )}
          </Actions>
        )}
      </Container>
    );
  }
}
