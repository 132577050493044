/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import CostField from 'components/material/CostField';
import { Error, Icon, StyledTextInput } from 'components/material/TextField';

import { type TeamRow_settings } from './__generated__/TeamRow_settings.graphql';

const Container = styled.div`
  display: flex;
  border-top: 1px solid #eaebec;
`;

const StyledCostField = styled(CostField)`
  width: 200px;
  margin: 0 -6px 0;
  ${StyledTextInput} {
    padding: 6px 11px 4px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    &:focus {
      border: 1px solid ${props => props.theme.primaryActionColor};
    }
  }
  ${Icon} {
    left: 11px;
    color: #4a5665;
  }
  ${Error} {
    position: relative;
  }
`;

export const Column = styled.div`
  display: flex;
  flex: 1 0 0px;
  align-items: center;
  padding: 8px 19px;
  font-size: 13px;
  color: #4a5665;
  &:first-child {
    flex: 0 355px;
  }
`;

class TeamRow extends React.Component<
  {
    id: string,
    amount: number,
    name: string,
    period: string,
    settings: TeamRow_settings,
    onBudgetChange: (id: string, amount: number) => void,
  },
  { value: number, period: string },
> {
  state = {
    value: this.props.amount || 0,
    period: this.props.period,
  };

  static getDerivedStateFromProps(
    nextProps: $PropertyType<TeamRow, 'props'>,
    prevState: $PropertyType<TeamRow, 'state'>,
  ) {
    if (prevState.period === nextProps.period) {
      return null;
    }

    return { value: nextProps.amount, period: nextProps.period };
  }

  handleBlur = (e: SyntheticEvent<HTMLInputElement>, budgetAmount: ?number) => {
    if (this.props.amount !== budgetAmount) {
      this.props.onBudgetChange(this.props.id, budgetAmount || 0);
    }
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>, budgetAmount: ?number) => {
    this.setState({ value: budgetAmount || 0 });
  };

  render() {
    return (
      <Container>
        <Column>{this.props.name}</Column>
        <Column>
          <StyledCostField
            name="actualCost"
            label=""
            value={this.state.value}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            currency={this.props.settings.currency}
            maxValue={1000000000}
          />
        </Column>
      </Container>
    );
  }
}

export default createFragmentContainer(
  TeamRow,
  graphql`
    fragment TeamRow_settings on OrgSettings {
      currency
    }
  `,
);
