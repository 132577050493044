/* @flow */

const mappingCustomKinds = {
  DEFAULT: '',
  TEXT: 'Text Input',
  TEXTAREA: 'Text Area',
  LINK: 'URL',
  NUMBER: 'Number',
  CURRENCY: 'Amount',
  DATE: 'Date',
  BOOLEAN: 'Boolean',
  USER_SELECT: 'User Select',
  USER_MULTISELECT: 'User Multiselect',
  SELECT: 'Select',
  MULTISELECT: 'Multiselect',
};

export default mappingCustomKinds;
