/**
 * @flow
 * @relayHash b90be7eb9a77de06b47fe937f1225604
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddContactFormFieldInput = {
  clientMutationId?: ?string,
  contactFormId: string,
  fieldName?: ?string,
  customFieldId?: ?string,
  label: string,
  required: boolean,
  showOtherOption: boolean,
  order: number,
  helpText?: ?string,
  contactFormFieldOptionIds?: ?$ReadOnlyArray<string>,
  contactFormFieldUserOptionIds?: ?$ReadOnlyArray<string>,
  defaultFont?: ?string,
};
export type addContactFormFieldMutationVariables = {|
  input: AddContactFormFieldInput
|};
export type addContactFormFieldMutationResponse = {|
  +addContactFormField: ?{|
    +contactFormFieldEdges: ?$ReadOnlyArray<{|
      +node: {|
        +id: string,
        +label: string,
        +fieldName: ?string,
        +required: boolean,
        +helpText: ?string,
        +order: number,
        +customField: ?{|
          +id: string
        |},
        +contactFormFieldOptions: ?{|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +customFieldOption: {|
                +id: string
              |}
            |}
          |}>
        |},
        +contactFormFieldUserOptions: ?{|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +user: {|
                +id: string
              |}
            |}
          |}>
        |},
      |}
    |}>,
    +registrationForm: ?{|
      +id: string,
      +hasChanges: boolean,
    |},
  |}
|};
export type addContactFormFieldMutation = {|
  variables: addContactFormFieldMutationVariables,
  response: addContactFormFieldMutationResponse,
|};
*/


/*
mutation addContactFormFieldMutation(
  $input: AddContactFormFieldInput!
) {
  addContactFormField(input: $input) {
    contactFormFieldEdges {
      node {
        id
        label
        fieldName
        required
        helpText
        order
        customField {
          id
        }
        contactFormFieldOptions {
          edges {
            node {
              customFieldOption {
                id
              }
              id
            }
          }
        }
        contactFormFieldUserOptions {
          edges {
            node {
              user {
                id
              }
              id
            }
          }
        }
      }
    }
    registrationForm {
      id
      hasChanges
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddContactFormFieldInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "AddContactFormFieldInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "required",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "helpText",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v8 = [
  v2
],
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": v8
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customFieldOption",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldOptionType",
  "plural": false,
  "selections": v8
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": v8
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "registrationForm",
  "storageKey": null,
  "args": null,
  "concreteType": "RegistrationForm",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasChanges",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "addContactFormFieldMutation",
  "id": null,
  "text": "mutation addContactFormFieldMutation(\n  $input: AddContactFormFieldInput!\n) {\n  addContactFormField(input: $input) {\n    contactFormFieldEdges {\n      node {\n        id\n        label\n        fieldName\n        required\n        helpText\n        order\n        customField {\n          id\n        }\n        contactFormFieldOptions {\n          edges {\n            node {\n              customFieldOption {\n                id\n              }\n              id\n            }\n          }\n        }\n        contactFormFieldUserOptions {\n          edges {\n            node {\n              user {\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n    registrationForm {\n      id\n      hasChanges\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "addContactFormFieldMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addContactFormField",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddContactFormFieldPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactFormFieldEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactFormFieldRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactFormField",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v9,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contactFormFieldOptions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldOptionRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldOptionRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContactFormFieldOption",
                            "plural": false,
                            "selections": [
                              v10
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contactFormFieldUserOptions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldUserOptionRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldUserOptionRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContactFormFieldUserOption",
                            "plural": false,
                            "selections": [
                              v11
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "addContactFormFieldMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addContactFormField",
        "storageKey": null,
        "args": v1,
        "concreteType": "AddContactFormFieldPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contactFormFieldEdges",
            "storageKey": null,
            "args": null,
            "concreteType": "ContactFormFieldRequiredEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactFormField",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  v9,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contactFormFieldOptions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldOptionRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldOptionRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContactFormFieldOption",
                            "plural": false,
                            "selections": [
                              v10,
                              v2
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contactFormFieldUserOptions",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContactFormFieldUserOptionRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContactFormFieldUserOptionRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContactFormFieldUserOption",
                            "plural": false,
                            "selections": [
                              v11,
                              v2
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0b39fa3a7894dc52e82a86b6f2ede2b5';
module.exports = node;
