/* @flow */
import styled, { css } from 'styled-components';

import generateFontFromProps from 'views/Main/Event/Registration/RegistrationCreate/RegistrationPageContent/generateFontFromProps';

import { withSharedFormTheme } from './lib/SharedFormThemeContext';

const Label = withSharedFormTheme(styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  font: ${props =>
    props.sharedFormTheme
      ? generateFontFromProps({
          fontFamily: props.sharedFormTheme.textFont,
          fontStyle: props.sharedFormTheme.textStyle,
        })
      : ''};
  font-size: 16px;
  color: ${props => props.sharedFormTheme.textColor || 'rgba(74, 86, 101, 0.87)'};
  ${props =>
    props.required &&
    css`
      &:after {
        position: absolute;
        left: -12px;
        top: 0;
        content: '*';
        margin-right: 4px;
        color: ${props.hasError ? '#f25d60' : '#519cf9'};
      }
    `};
  @media (${props => props.theme.mobileOnly}) {
    font-size: 15px;
  }
`);

const Header = styled.span`
  word-break: break-word;
`;

const Error = styled.div`
  position: absolute;
  font-size: 11px;
  margin-top: 2px;
  color: ${props => props.theme.negativeActionColor};
`;

const Required = styled.span`
  margin-left: 3px;
  margin-right: 3px;
  font-size: 11px;
  vertical-align: top;
`;

export { Label as default, Error, Header, Required };
