/* @flow */
import React from 'react';
import styled from 'styled-components';

import SectionHeader from '../components/SectionHeader';
import SectionContent from '../components/SectionContent';
import NoteItem, { type NoteType } from './NoteItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default class NotesBrief extends React.Component<{
  notes: $ReadOnlyArray<NoteType>,
  primaryColor: string,
  tz: string,
}> {
  render() {
    const { primaryColor, notes, tz } = this.props;
    return (
      <React.Fragment>
        <SectionHeader name="NOTES" primaryColor={primaryColor} anchor="notes" />
        <SectionContent>
          <Container>
            {notes.map(n => (
              <NoteItem key={n.id} note={n} tz={tz} />
            ))}
          </Container>
        </SectionContent>
      </React.Fragment>
    );
  }
}
