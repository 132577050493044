/* @flow */
import { graphql, createFragmentContainer } from 'react-relay';

import type { EventId_event } from './__generated__/EventId_event.graphql';

const EventId = (props: { event: EventId_event }) => {
  return props.event.uniqueId;
};

export default createFragmentContainer(
  EventId,
  graphql`
    fragment EventId_event on Event {
      uniqueId
    }
  `,
);
