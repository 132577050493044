/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type TaskRowAssignees_assignees$ref = any;
export type TaskStatus = "COMPLETED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SubtaskRow_subtask$ref: FragmentReference;
export type SubtaskRow_subtask = {|
  +id: string,
  +name: string,
  +status: TaskStatus,
  +order: number,
  +dueDate: ?any,
  +dueDateAllDay: ?boolean,
  +viewerCanUpdateDetails: boolean,
  +viewerCanUpdateStatus: boolean,
  +viewerCanUpdateDueDate: boolean,
  +viewerCanAssign: boolean,
  +viewerCanDelete: boolean,
  +assignees: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: TaskRowAssignees_assignees$ref
      |}
    |}>
  |},
  +$refType: SubtaskRow_subtask$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SubtaskRow_subtask",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "order",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueDateAllDay",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateDetails",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateDueDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanAssign",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanDelete",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "assignees",
      "storageKey": null,
      "args": null,
      "concreteType": "UserRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "TaskRowAssignees_assignees",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7a9b9332d00090df1443f812c37762b6';
module.exports = node;
