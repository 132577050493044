/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { History, Location } from 'react-router';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import tshirtSizes, { type TshirtSizeType } from 'config/tshirtSizes';
import inviteStatuses from 'config/userInviteStatuses';

import fullNameOfUser from 'utils/fullNameOfUser';
import isValidEmail from 'utils/validators/isValidEmail';

import changeAdmin from 'graph/mutations/access/changeAdmin';
import updateUser, { type UpdateUserFromWindow } from 'graph/mutations/user/updateUser';
import showModernMutationError from 'graph/utils/showModernMutationError';

import BillingLimitWindow from 'components/billing/BillingLimitWindow';
import Breadcrumb from 'components/Breadcrumb';
import DateTimeTooltip from 'components/material/DateTimeTooltip';
import EditableLinkField from 'components/material/EditableLinkField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import ShareDropdown from 'components/ShareDropdown';

import UserAvatar from './UserAvatar';
import UserEvents from './UserEvents';
import UserRemovalWindow from './UserRemovalWindow';
import UserRequests from './UserRequests';
import UserTeams from './UserTeams';

import type { UserView_me } from './__generated__/UserView_me.graphql';
import type { UserView_org } from './__generated__/UserView_org.graphql';
import type { UserView_user } from './__generated__/UserView_user.graphql';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 33px;
  border-bottom: 1px solid #eaebec;
`;

const ActionsDropdown = styled(ShareDropdown)`
  border-left: none;
  > i {
    font-size: 20px;
  }
`;

export const UserViewContainer = styled.div`
  display: flex;
  max-width: 800px;
  padding: 30px 33px;
  ${props =>
    !props.standalonePage &&
    css`
      padding-left: 43px;
    `};
  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`;

export const MembershipsContainer = styled.div`
  padding: 24px 28px 45px 33px;
  ${props =>
    !props.standalonePage &&
    css`
      padding-left: 43px;
    `};
`;

const InfoColumn = styled.div`
  flex: 0 0 100px;
  margin-left: 75px;
  @media (max-width: 500px) {
    margin-left: 0;
    margin-bottom: 25px;
    padding-left: 8px;
  }
`;

const DetailsColumn = styled.div`
  flex: 1 1 506px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
  padding-left: 8px;
  flex: 1 1 auto;
  @media (max-width: 760px) {
    display: block;
  }
  .sidebar-shown & {
    @media (max-width: 1050px) {
      display: block;
    }
  }
`;

const FieldColumn = styled.div`
  flex: 1 1 100%;

  &:not(:last-child) {
    margin-right: 35px;
    @media (max-width: 760px) {
      margin-right: 0;
    }
    .sidebar-shown & {
      @media (max-width: 1050px) {
        margin-right: 0;
      }
    }
  }
`;

const AdminLabel = styled.span`
  padding: 2px 8px;
  border-radius: 11px;
  background-color: #56b5df;
  text-align: center;
  font-size: 11px;
  line-height: 8px;
  color: white;
`;

const UserInfoRow = styled.div`
  margin-top: 18px;
  line-height: 1;
`;

const InfoRowSpan = styled.span`
  white-space: nowrap;
  color: #4a5665;
`;

const InfoRowLabel = styled.span`
  display: block;
  margin-bottom: 6px;
  color: ${props => props.theme.labelColor};
  font-size: 12px;
`;

type UserFormFields = {|
  +firstName?: ?string,
  +lastName?: ?string,
  +company?: ?string,
  +title?: ?string,
  +email?: ?string,
  +phone?: ?string,
  +officePhone?: ?string,
  +tshirtSize?: ?TshirtSizeType,
  +bio?: ?string,
  +avatar?: ?string,
|};

class UserView extends React.PureComponent<
  {
    org: UserView_org,
    user: UserView_user,
    me: UserView_me,
    location: Location,
    history: History,
    standalonePage?: boolean,
    className?: string,
  },
  {
    errors: { [key: string]: string },
    userForm: UserFormFields,
    deleteConfirmationShown: boolean,
    locationInfo: Object,
    prevPageExists: boolean,
    billingError: boolean,
  },
> {
  static defaultProps = {
    standalonePage: true,
  };

  state = {
    errors: {},
    userForm: UserView.setupUserFormFields(this.props.user),
    deleteConfirmationShown: false,
    locationInfo: this.props.location.state,
    prevPageExists: this.prevPageExists(),
    billingError: false,
  };

  static setupUserFormFields = (user: UserView_user): UserFormFields => ({
    firstName: user.firstName,
    lastName: user.lastName,
    company: user.company,
    title: user.title,
    email: user.email,
    phone: user.phone,
    officePhone: user.officePhone,
    tshirtSize: user.tshirtSize,
    bio: user.bio,
    avatar: user.avatar,
  });

  getReturnUrl = (): string => {
    const state = this.state.locationInfo;
    if (state && state.prevPage === true) {
      // TODO: remove all these uneccessary data and just give prevPath, search and prevLabel
      if (state.teamSlug) {
        return `/workspace/teams/${state.teamSlug}/members`;
      }
      if (state.eventSlug) {
        return `/events/${state.eventSlug}/staff`;
      }
      if (state.prevPath) {
        return state.prevPath;
      }
    }
    return '/workspace/members';
  };

  getBreadCrumbLabel = (): UpdateUserFromWindow => {
    const state = this.state.locationInfo;
    if (state && state.prevPage === true) {
      // TODO: remove all these uneccessary data and just give prevPath, search and prevLabel
      if (state.teamSlug) {
        return 'Team Members';
      }
      if (state.eventSlug) {
        return 'Event Staff';
      }
      if (state.prevPageLabel) {
        return state.prevPageLabel;
      }
    }
    return 'Workspace Members';
  };

  handleGoBack = () => {
    const state = this.props.location.state;
    this.props.history.replace({
      pathname: this.getReturnUrl(),
      search: state && state.search,
    });
  };

  handleBreadCrumbClick = () => {
    const state = this.state.locationInfo;
    this.props.history.push({
      pathname: this.getReturnUrl(),
      search: state && state.search,
    });
  };

  handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.state.userForm[e.currentTarget.name] !== e.currentTarget.value) {
      this.handleUpdate(e.currentTarget.name, e.currentTarget.value, e.currentTarget);
    }
  };

  handleTshirtSelect = (size: ?string) => {
    this.handleUpdate('tshirtSize', size);
  };

  handleAvatarChange = (avatar: string) => {
    this.handleUpdate('avatar', avatar);
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  handleUpdate = (
    property: string,
    value: any,
    targetField?: HTMLInputElement | HTMLTextAreaElement,
  ) => {
    if (this.state.userForm[property] === value) {
      return;
    }
    const inputField = targetField;
    const formUser = Object.assign({}, this.state.userForm, { [property]: value });

    const userForm = {
      firstName: (formUser.firstName || '').trim(),
      lastName: (formUser.lastName || '').trim(),
      company: (formUser.company || '').trim(),
      title: (formUser.title || '').trim(),
      email: (formUser.email || '').trim(),
      phone: (formUser.phone || '').trim(),
      officePhone: (formUser.officePhone || '').trim(),
      tshirtSize: formUser.tshirtSize,
      bio: (formUser.bio || '').trim(),
      avatar: formUser.avatar || '',
    };

    const errors = {};
    if (!userForm.firstName) {
      errors.firstName = 'First Name is required';
    }
    if (!userForm.lastName) {
      errors.lastName = 'Last Name is required';
    }
    if (userForm.email && !isValidEmail(userForm.email)) errors.email = 'Email is not valid';

    this.setState(state => ({ errors, userForm: { ...state.userForm, ...formUser } }));
    if (Object.keys(errors).length === 0) {
      if (inputField) {
        inputField.value = userForm[property];
      }
      updateUser(
        this.props.user.id,
        { [property]: value, firstName: userForm.firstName, lastName: userForm.lastName },
        this.getBreadCrumbLabel(),
      ).catch(showModernMutationError);
    }
  };

  handleConfirmationShow = () => {
    this.setState({ deleteConfirmationShown: true });
  };

  handleRemoveModalHide = () => {
    this.setState({ deleteConfirmationShown: false });
  };

  handleToggleAdmin = () => {
    const {
      user,
      org: { subscription },
    } = this.props;
    // Making use admin when subscription has limits and user has no team level access
    if (
      !user.hasTeamAccess &&
      subscription.fatmLimit != null &&
      subscription.fatmLimit - subscription.fatmCount === 0
    ) {
      this.setState({ billingError: true });
    } else {
      changeAdmin(user.id, !user.admin, this.getBreadCrumbLabel()).catch(showModernMutationError);
    }
  };

  handleHideBillingError = () => {
    this.setState({ billingError: false });
  };

  prevPageExists(): boolean {
    const state = this.props.location.state;
    if (state && state.prevPage === true) {
      if (state.teamSlug) {
        return this.props.user.memberships.edges.some(({ node }) => node.slug === state.teamSlug);
      }
      if (state.eventSlug) {
        return this.props.user.staffedEvents.edges.some(
          ({ node }) => node.event.slug === state.eventSlug,
        );
      }
    }
    return true;
  }

  render() {
    const { me, org, user, standalonePage, history, location, className } = this.props;
    const userForm = this.state.userForm;
    const fullName = fullNameOfUser(userForm);
    const subscription = org.subscription;

    const actionOptions = [
      user.viewerCanUpdateAdmin
        ? {
            label: `Make ${(user.admin && 'regular Member') || 'Admin'}`,
            icon: 'user',
            onClick: this.handleToggleAdmin,
          }
        : null,
      user.viewerCanRemove
        ? {
            label: 'Remove from Workspace',
            icon: 'times',
            onClick: this.handleConfirmationShow,
          }
        : null,
    ].filter(Boolean);

    return (
      <div className={className}>
        {standalonePage && (
          <HeaderContainer>
            <Breadcrumb
              path={[
                {
                  label: this.getBreadCrumbLabel(),
                  onClick: this.state.prevPageExists ? this.handleBreadCrumbClick : undefined,
                },
                { label: fullName },
              ]}
            />
            {actionOptions.length > 0 && <ActionsDropdown options={actionOptions} />}
            {this.state.deleteConfirmationShown && (
              <UserRemovalWindow
                orgId={org.id}
                userId={user.id}
                onHide={this.handleRemoveModalHide}
                onRemoveCompleted={this.handleGoBack}
                fromWindow={this.getBreadCrumbLabel()}
              />
            )}
            {this.state.billingError && subscription.fatmLimit != null && (
              <BillingLimitWindow
                limit={subscription.fatmLimit}
                used={subscription.fatmCount}
                onHide={this.handleHideBillingError}
                fromWindow="user profile page"
              />
            )}
          </HeaderContainer>
        )}
        <UserViewContainer standalonePage={standalonePage}>
          <DetailsColumn>
            <Row>
              <FieldColumn>
                <TextField
                  label="First Name"
                  defaultValue={userForm.firstName}
                  autoFocus
                  name="firstName"
                  error={this.state.errors.firstName}
                  onBlur={this.handleInputChange}
                  onKeyDown={this.handleKeyDown}
                  disabled={!user.viewerCanUpdate}
                />
              </FieldColumn>
              <FieldColumn>
                <TextField
                  label="Last Name"
                  defaultValue={userForm.lastName}
                  name="lastName"
                  error={this.state.errors.lastName}
                  onBlur={this.handleInputChange}
                  onKeyDown={this.handleKeyDown}
                  disabled={!user.viewerCanUpdate}
                />
              </FieldColumn>
            </Row>
            <Row>
              <FieldColumn>
                <TextField
                  label="Company"
                  defaultValue={userForm.company}
                  name="company"
                  onBlur={this.handleInputChange}
                  onKeyDown={this.handleKeyDown}
                  disabled={!user.viewerCanUpdate}
                />
              </FieldColumn>
              <FieldColumn>
                <TextField
                  label="Title"
                  defaultValue={userForm.title}
                  name="title"
                  onBlur={this.handleInputChange}
                  onKeyDown={this.handleKeyDown}
                  disabled={!user.viewerCanUpdate}
                />
              </FieldColumn>
            </Row>
            <Row>
              <FieldColumn>
                <EditableLinkField
                  label="Email"
                  defaultValue={userForm.email}
                  name="email"
                  error={this.state.errors.email}
                  onBlur={this.handleInputChange}
                  readOnly={!user.viewerCanUpdateEmail}
                  to={`mailto:${userForm.email || ''}`}
                />
              </FieldColumn>
              <FieldColumn>
                <EditableLinkField
                  label="Mobile Phone"
                  defaultValue={userForm.phone}
                  name="phone"
                  onBlur={this.handleInputChange}
                  readOnly={!user.viewerCanUpdate}
                  to={`tel:${userForm.phone || ''}`}
                />
              </FieldColumn>
            </Row>
            <Row>
              <FieldColumn>
                <SelectField
                  label="T-Shirt Size"
                  value={userForm.tshirtSize}
                  onChange={this.handleTshirtSelect}
                  options={Object.keys(tshirtSizes).map(key => ({
                    label: tshirtSizes[key],
                    value: key,
                  }))}
                  disabled={!user.viewerCanUpdate}
                />
              </FieldColumn>
              <FieldColumn>
                <EditableLinkField
                  label="Office Phone"
                  defaultValue={userForm.officePhone}
                  name="officePhone"
                  onBlur={this.handleInputChange}
                  readOnly={!user.viewerCanUpdate}
                  to={`tel:${userForm.officePhone || ''}`}
                />
              </FieldColumn>
            </Row>
            <Row>
              <FieldColumn>
                <TextField
                  label="Bio"
                  multiline
                  defaultValue={userForm.bio}
                  name="bio"
                  onBlur={this.handleInputChange}
                  disabled={!user.viewerCanUpdate}
                />
              </FieldColumn>
            </Row>
          </DetailsColumn>
          <InfoColumn>
            <UserAvatar
              user={user}
              onSave={this.handleAvatarChange}
              disabled={!user.viewerCanUpdate}
            />
            {user.admin && <AdminLabel>Admin</AdminLabel>}
            <UserInfoRow>
              <InfoRowLabel>Status</InfoRowLabel>
              <InfoRowSpan>{inviteStatuses[user.inviteStatus]}</InfoRowSpan>
            </UserInfoRow>
            {user.lastSeenAt && (
              <UserInfoRow>
                <InfoRowLabel>Last Activity</InfoRowLabel>
                <InfoRowSpan>
                  <DateTimeTooltip date={moment.tz(user.lastSeenAt, me.tz)} />
                </InfoRowSpan>
              </UserInfoRow>
            )}
          </InfoColumn>
        </UserViewContainer>
        <MembershipsContainer standalonePage={standalonePage}>
          <UserTeams org={org} user={user} me={me} location={location} history={history} />
          <UserEvents org={org} user={user} me={me} location={location} history={history} />
          <UserRequests userId={user.id} location={location} history={history} />
        </MembershipsContainer>
      </div>
    );
  }
}

export default createFragmentContainer(UserView, {
  user: graphql`
    fragment UserView_user on User {
      id
      firstName
      lastName
      company
      title
      email
      phone
      officePhone
      tshirtSize
      bio
      avatar
      admin
      hasTeamAccess
      inviteStatus
      lastSeenAt
      viewerCanUpdate
      viewerCanUpdateAdmin
      viewerCanUpdateEmail
      viewerCanRemove
      ...UserAvatar_user
      ...UserEvents_user
      ...UserTeams_user
      memberships {
        edges {
          node {
            slug
          }
        }
      }
      staffedEvents {
        edges {
          node {
            event {
              slug
            }
          }
        }
      }
    }
  `,
  me: graphql`
    fragment UserView_me on User {
      id
      tz
      admin
      ...UserEvents_me
      ...UserTeams_me
    }
  `,
  org: graphql`
    fragment UserView_org on Org {
      id
      subscription {
        fatmLimit
        fatmCount
      }
      ...UserEvents_org
      ...UserTeams_org
    }
  `,
});
