/* @flow */
import * as React from 'react';
import { parse } from 'qs';

type InputProps = { [string]: any };
type OutputProps = {
  queryParams: {
    [string]: ?string,
  } | void,
};
const withQueryParams = <Props: InputProps>(
  Component: React.ComponentType<Props>,
): React.ComponentType<$Diff<Props, OutputProps>> => {
  return (props: Props) => {
    if (props.location == null) {
      console.error(
        new Error(
          'The component using `withQueryParams` must be passed the location.search prop from react-router.',
        ),
      );
      return null;
    }
    const queryParams = parse(
      typeof props.location.search === 'string' ? props.location.search : '',
      {
        ignoreQueryPrefix: true,
      },
    );
    return <Component {...props} queryParams={queryParams} />;
  };
};

export default withQueryParams;
