/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import flatten from 'lodash/flatten';

import type { CircaFile } from 'utils/uploading/types';

import addExpenseAttachment from 'graph/mutations/expense/addExpenseAttachment';
import updateExpense from 'graph/mutations/expense/updateExpense';
import removeAttachment from 'graph/mutations/removeAttachment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import VendorField from 'components/budget/VendorField';
import SelectField from 'components/material/SelectField';
import TextField from 'components/material/TextField';
import Tooltip from 'components/material/Tooltip';

import ExpenseAttachments from './ExpenseAttachments';
import ExpenseFinanceField from './ExpenseFinanceField';
import ExpenseFormHeader from './ExpenseFormHeader';

import type { ExpenseForm_expense } from './__generated__/ExpenseForm_expense.graphql';
import type { ExpenseForm_org } from './__generated__/ExpenseForm_org.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 10px 0 28px;
`;

const Row = styled.div`
  position: relative;
  padding: 7px 0;
  margin-top: 18px;
`;

const TaskField = styled.div`
  position: relative;
  > :first-child > div:first-child > div {
    padding-right: 25px;
  }
`;

const Icon = styled.i`
  cursor: pointer;
  color: #cdd1d4;
  &:hover {
    color: #96a2ab;
  }
`;

const IconContainer = styled.i`
  position: absolute;
  right: 40px;
  top: 9px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  max-width: 560px;
  &:first-child {
    margin: 0 72px 0 0;
  }
`;

const StyledTextField = styled(TextField)`
  width: 400px;
`;

const ManageLink = styled.div`
  color: #3ba9da;
`;

class ExpenseForm extends React.Component<
  {
    expense: ExpenseForm_expense,
    org: ExpenseForm_org,
  },
  {
    errors: $ReadOnlyArray<string>,
  },
> {
  state = {
    errors: [],
  };

  handleNameChange = (name: string) => {
    if (name.trim()) {
      if (name !== this.props.expense.name) {
        updateExpense(this.props.expense.id, { name });
      }
      if (this.state.errors.includes('name')) {
        this.setState(prevState => ({
          errors: prevState.errors.filter(error => error !== 'name'),
        }));
      }
    } else if (!this.state.errors.includes('name')) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'name'] }));
    }
  };

  handleNameBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    this.handleNameChange(e.currentTarget.value);
  };

  handleAmountErrorSet = (amount: number) => {
    if (amount == null && !this.state.errors.includes('cost')) {
      this.setState(prevState => ({ errors: [...prevState.errors, 'cost'] }));
    }
  };

  handleActualAmountChange = (actualAmount: number) => {
    if (actualAmount == null) {
      if (!this.state.errors.includes('cost')) {
        this.setState(prevState => ({ errors: [...prevState.errors, 'cost'] }));
      }
    } else {
      if (this.props.expense.actualAmount !== actualAmount) {
        updateExpense(this.props.expense.id, {
          actualAmount,
        });
      }
      if (this.state.errors.includes('cost')) {
        this.setState(prevState => ({
          errors: prevState.errors.filter(error => error !== 'cost'),
        }));
      }
    }
  };

  handleNoteBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    updateExpense(this.props.expense.id, { note: e.currentTarget.value });
  };

  handleBudgetCategoryChange = (categoryId: ?string) => {
    updateExpense(this.props.expense.id, { categoryId });
  };

  handleVendorChange = (vendorId: ?string) => {
    updateExpense(this.props.expense.id, { vendorId });
  };

  handleAttachmentAdd = (attachment: CircaFile) => {
    addExpenseAttachment(this.props.expense.id, attachment).catch(showModernMutationError);
  };

  handleAttachmentRemove = (attachmentId: string) => {
    const paymentIds = this.props.expense.payments.edges.map(edge => edge.node.id);
    removeAttachment(this.props.expense.event.id, attachmentId, [
      this.props.expense.id,
      ...paymentIds,
    ]).catch(showModernMutationError);
  };

  handleTaskChange = (taskId: ?string) => {
    if (taskId === 'create') {
      window.open(`/events/${this.props.expense.event.slug}/tasks`);

      return;
    }
    const task =
      taskId === null
        ? null
        : this.props.expense.event.deliverables.edges
            .map(({ node }) => node)
            .find(t => t.id === taskId);
    updateExpense(this.props.expense.id, { deliverableId: task && task.id });
  };

  handleTaskPreview = () => {
    if (this.props.expense.deliverable != null) {
      window.open(
        `/events/${this.props.expense.event.slug}/tasks/${this.props.expense.deliverable.slug}`,
        '_blank',
      );
    }
  };

  render() {
    const categories = this.props.org.budgetCategories.edges.map(edge => edge.node);
    const paymentsAttachments = flatten(
      this.props.expense.payments.edges.map(edge =>
        edge.node.attachments.edges.map(attachmentEdge => attachmentEdge.node),
      ),
    );
    const expenseAttachments = this.props.expense.attachments
      ? this.props.expense.attachments.edges.map(edge => edge.node)
      : [];
    const briefAttachmentIds = this.props.expense.event.briefAttachments.edges.map(
      ({ node }) => node.id,
    );
    const briefData = {
      briefAttachmentIds,
      eventId: this.props.expense.event.id,
      canUpdateBrief: this.props.expense.event.viewerCanUpdate,
    };
    const taskOptions = [
      ...this.props.expense.event.deliverables.edges.map(({ node }) => ({
        label: node.name,
        value: node.id,
      })),
      {
        label: 'Create Task',
        value: 'create',
        displayLabel: <ManageLink>+ Create Task</ManageLink>,
      },
    ];
    return (
      <Container>
        <ExpenseFormHeader expense={this.props.expense} />
        <FormWrapper>
          <Column>
            <Row>
              <StyledTextField
                label="Expense"
                defaultValue={this.props.expense.name || ''}
                name="name"
                onBlur={this.handleNameBlur}
                autoFocus={!this.props.expense.name}
                error={this.state.errors.includes('name') ? 'Required' : ''}
                required
              />
            </Row>
            <Row>
              <SelectField
                label="Expense Category"
                value={this.props.expense.budgetCategory && this.props.expense.budgetCategory.id}
                onChange={this.handleBudgetCategoryChange}
                options={categories.map(category => ({
                  label: category.name,
                  value: category.id,
                  info: category.description,
                }))}
                clearable
              />
            </Row>
            <Row>
              <VendorField
                eventId={this.props.expense.event.id}
                vendorId={this.props.expense.vendor && this.props.expense.vendor.id}
                onSelect={this.handleVendorChange}
                label="Vendor"
                clearable
              />
            </Row>
            <Row>
              <TaskField>
                <SelectField
                  label="Association with Task"
                  value={this.props.expense.deliverable && this.props.expense.deliverable.id}
                  onChange={this.handleTaskChange}
                  clearable
                  options={taskOptions}
                />
                {this.props.expense.deliverable && (
                  <IconContainer>
                    <Tooltip label="View Task" placement="top">
                      <Icon className="fa fa-fw fa-eye" onClick={this.handleTaskPreview} />
                    </Tooltip>
                  </IconContainer>
                )}
              </TaskField>
            </Row>
            <Row id="note">
              <StyledTextField
                label="Note"
                defaultValue={this.props.expense.note || ''}
                name="note"
                onBlur={this.handleNoteBlur}
                multiline
              />
            </Row>
          </Column>
          <Column>
            <Row>
              <ExpenseFinanceField
                org={this.props.org}
                expense={this.props.expense}
                amount={this.props.expense.actualAmount}
                paid={this.props.expense.paidAmount}
                error={this.state.errors}
                onActualAmountChange={this.handleActualAmountChange}
                onAmountErrorSet={this.handleAmountErrorSet}
                errors={this.state.errors}
                disabled={!this.props.expense.viewerCanUpdateAmount}
              />
            </Row>
          </Column>
        </FormWrapper>
        <ExpenseAttachments
          briefData={briefData}
          attachments={[...expenseAttachments, ...paymentsAttachments]}
          handleAttachmentAdd={this.handleAttachmentAdd}
          handleAttachmentRemove={this.handleAttachmentRemove}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(ExpenseForm, {
  expense: graphql`
    fragment ExpenseForm_expense on Expense {
      ...ExpenseFormHeader_expense
      id
      name
      note
      paidAmount
      actualAmount
      viewerCanUpdateAmount
      vendor {
        id
      }
      budgetCategory {
        id
        name
      }
      attachments {
        edges {
          node {
            id
            fileurl
            filetype
            filename
          }
        }
      }
      ...ExpenseFinanceField_expense
      payments {
        edges {
          node {
            id
            attachments {
              edges {
                node {
                  id
                  fileurl
                  filetype
                  filename
                }
              }
            }
          }
        }
      }
      deliverable {
        name
        id
        slug
      }
      event {
        id
        slug
        viewerCanUpdate
        briefAttachments {
          edges {
            node {
              id
            }
          }
        }
        deliverables {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  `,
  org: graphql`
    fragment ExpenseForm_org on Org {
      ...ExpenseFinanceField_org
      budgetCategories {
        edges {
          node {
            name
            description
            id
          }
        }
      }
    }
  `,
});
