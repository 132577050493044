/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import removeRecordFromConnection from 'graph/updaters/removeRecordFromConnection';

const mutation = graphql`
  mutation removeTaskDependencyMutation($input: RemoveDeliverableDependencyInput!) {
    removeDeliverableDependency(input: $input) {
      dependent {
        id
      }
      dependency {
        id
      }
      removedDependencyId
      removedDependentId
    }
  }
`;

export default function removeTaskDependency(dependentId: string, dependencyId: string) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        dependentId,
        dependencyId,
      },
    },
    optimisticResponse: {
      removeDeliverableDependency: {
        dependent: {
          id: dependentId,
        },
        removedDependencyId: dependencyId,
      },
    },
    updater: mergeUpdaters(
      removeRecordFromConnection({
        parentId: dependentId,
        connections: [{ field: 'dependencies' }],
        deletedIdField: 'removedDependencyId',
      }),
      removeRecordFromConnection({
        parentId: dependencyId,
        connections: [{ field: 'dependents' }],
        deletedIdField: 'removedDependentId',
      }),
    ),
  });
}
