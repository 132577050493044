/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import mergeUpdaters from 'graph/updaters/mergeUpdaters';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type { CreateComponentPropertiesType } from './createEventDatesComponent';

import type {
  createRowComponentFieldsMutationResponse,
  createRowComponentFieldsMutationVariables,
} from './__generated__/createRowComponentFieldsMutation.graphql';

const mutation = graphql`
  mutation createRowComponentFieldsMutation($input: CreateRowComponentInput!) {
    createRegistrationFormRowComponent(input: $input) {
      pageComponentEdge {
        __typename
        node {
          id
          order
          kind
          columnChild {
            id
          }
          ...RowComponent_componentProps
        }
      }
      columnComponentEdges {
        __typename
        node {
          id
          order
          kind
          columnChild {
            id
          }
          ...ColumnComponent_componentProps
        }
      }
      updatedPageComponents {
        id
        order
        parent {
          id
        }
        childPageComponents {
          edges {
            node {
              id
              kind
              order
              columnChild {
                id
              }
              ...ColumnComponent_componentProps
            }
          }
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function createRowComponent(
  properties: CreateComponentPropertiesType,
): Promise<string> {
  const { formId, newComponentOrder, pageComponentsOrder, parentProps } = properties;
  const variables: createRowComponentFieldsMutationVariables = {
    input: {
      formId,
      newComponentOrder,
      pageComponentsOrder,
      parentId: parentProps ? parentProps.parentId : undefined,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      createRegistrationFormRowComponent: {
        updatedPageComponents: pageComponentsOrder.map(pageComponent => ({
          id: pageComponent.id,
          order: pageComponent.order,
          parent: undefined,
        })),
        pageComponentEdge: {
          node: {
            id: 'new',
            order: newComponentOrder,
            kind: 'ROW',
            rowComponent: {
              cellSpacing: 50,
              padding: '5 2 2 2',
            },
            childPageComponents: {
              edges: [0, 1].map(edgeNumber => ({
                node: {
                  id: `newColumn${edgeNumber}`,
                  kind: 'COLUMN',
                  order: edgeNumber,
                  columnChild: undefined,
                  columnComponent: {
                    id: `newChild${edgeNumber}`,
                    width: 50,
                    padding: '0 0 0 0',
                  },
                },
              })),
            },
          },
        },
        columnComponentEdges: [0, 1].map(edgeNumber => ({
          node: {
            id: `newColumn${edgeNumber}`,
            kind: 'COLUMN',
            order: edgeNumber,
            columnChild: undefined,
            columnComponent: {
              id: `newChild${edgeNumber}`,
              width: 50,
              padding: '0 0 0 0',
            },
          },
        })),
      },
    },
    updater: mergeUpdaters(
      addRecordToConnection({
        parentId: formId,
        edgeName: 'pageComponentEdge',
        connections: [{ field: 'pageComponents' }],
      }),
      addRecordToConnection({
        parentId: formId,
        edgeName: 'columnComponentEdges',
        connections: [{ field: 'pageComponents' }],
      }),
    ),
  }).then((response: createRowComponentFieldsMutationResponse) => {
    if (
      response.createRegistrationFormRowComponent &&
      response.createRegistrationFormRowComponent.pageComponentEdge.node
    ) {
      return response.createRegistrationFormRowComponent.pageComponentEdge.node.id;
    }
    throw new Error('No item id');
  });
}
