/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import BriefField from '../../components/BriefField';

import { type EventInfoSalesforceId_event } from './__generated__/EventInfoSalesforceId_event.graphql';

const Link = styled.a`
  color: ${props => props.color};
`;

const EventInfoSalesforceId = (props: {
  event: EventInfoSalesforceId_event,
  name: string,
  primaryColor: string,
}) => {
  const {
    name,
    primaryColor,
    event: { salesforceCampaign },
  } = props;
  if (!salesforceCampaign) {
    return null;
  }
  const stopPropagation = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const { campaignUrl, campaignSfdcid } = salesforceCampaign;
  return (
    <BriefField label={name} contentDataAvailable={campaignSfdcid}>
      <Link
        href={campaignUrl}
        rel="noopener noreferrer"
        target="_blank"
        color={primaryColor}
        onClick={stopPropagation}
      >
        {campaignSfdcid}
      </Link>
    </BriefField>
  );
};

export default createFragmentContainer(
  EventInfoSalesforceId,
  graphql`
    fragment EventInfoSalesforceId_event on Event {
      salesforceCampaign {
        campaignSfdcid
        campaignUrl
      }
    }
  `,
);
