/* @flow */
import React from 'react';
import { type Location, type RouterHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';
import UserStore from 'stores/UserStore';

import getEnvironment from 'utils/getEnvironment';
import getHomepageUrl from 'utils/getHomepageUrl';
import withQueryParams from 'utils/routing/withQueryParams';
import showErrorPopup from 'utils/showErrorPopup';
import isValidEmail from 'utils/validators/isValidEmail';

import AuthButton from 'components/Auth/AuthButton';
import ErrorBlock from 'components/Auth/AuthErrorBlock';
import AuthFooter from 'components/Auth/AuthFooter';
import GoogleButton from 'components/Auth/AuthGoogleButton';
import AuthOptionDivider from 'components/Auth/AuthOptionDivider';
import AuthWindow from 'components/Auth/AuthWindow';
import TextField, { Label } from 'components/material/TextField';

import checkSAMLEnabled from '../lib/checkSAMLEnabled';

const StyledButton = styled(AuthButton)`
  margin-top: 40px;
  padding: 10px 14px;
  border-radius: 52px;
  background-color: #5e5bff;

  &:hover {
    background-color: #403ec9;
  }
`;

const StyledTextField = styled(TextField)`
  margin: 16px 0 24px 0;

  input {
    &:focus {
      border-bottom-color: #5e5bff;

      ~ ${Label} {
        color: #5e5bff;
      }
    }
  }
`;

const SSOMessage = styled.div`
  margin-bottom: 10px;
  color: #333;
`;

const StyledParagraph = styled.p`
  a {
    color: #5554c3;
  }
`;

class Login extends React.PureComponent<
  {
    queryParams: {
      error?: string,
      next?: string,
      email?: string,
      password?: string,
    },
    history: RouterHistory,
    location: Location,
  },
  {
    email: string,
    password: string,
    invalidEmail: boolean,
    invalidPassword: boolean,
    loading: boolean,
    error: ?string,
    emailLocked: boolean,
    redirectingToSAML: boolean,
  },
> {
  state = {
    email: this.props.queryParams.email || '',
    password: (getEnvironment() !== 'production' && this.props.queryParams.password) || '',
    invalidEmail: false,
    invalidPassword: false,
    loading: false,
    error: null,
    emailLocked: false,
    redirectingToSAML: false,
  };

  componentDidMount() {
    if (this.props.queryParams.error) {
      const error = this.props.queryParams.error;
      this.showError(error);
      this.props.history.replace('/signin');
    } else if (this.props.location.pathname.split('/').pop() === 'confirmed') {
      this.showError('Account confirmed. Please Sign In to continue.');
      this.props.history.replace('/signin');
    }
  }

  handleSAMLLogin = () => {
    const domain = this.state.email.toLowerCase().split('@').pop();
    window.location.href = `/saml/init?domain=${domain}`;
  };

  handleLogin = e => {
    e.preventDefault();

    const { email, password } = this.state;
    const {
      queryParams: { next },
      history,
    } = this.props;

    this.setState(
      {
        invalidEmail: !isValidEmail(email),
        invalidPassword: password.length < 8,
      },
      () => {
        if (!this.state.invalidEmail && !this.state.invalidPassword) {
          this.setState({ loading: true });
          UserStore.login(email, password, response => {
            if (response && response.access_token) {
              // Redirect user to custom org subdomain when logging in from app.circa.co
              if (response.domain_to_redirect != null) {
                window.location = `${response.domain_to_redirect}${next || ''}`;
                return;
              }

              if (response.onboarding_step >= 0 && response.onboarding_step < 6) {
                window.location = '/onboarding';
              } else if (next) {
                window.location = next;
              } else {
                this.props.history.replace(
                  getHomepageUrl(
                    response.homepage_list_id == null ? response.homepage : response.homepage_list_id,
                    response.relay_id,
                  )
                );
              }
            } else {
              this.setState({
                error: "We weren't able to log you in with that email and password.",
                loading: false,
              });
            }
          });
        }
      },
    );
  };

  handleErrorDismiss = () => {
    this.setState({ error: null });
  };

  handleLockEmail = e => {
    e.preventDefault();

    if (!isValidEmail(this.state.email)) {
      this.setState({ invalidEmail: true });
      return;
    }

    this.setState({ loading: true, invalidEmail: false });
    this.props.history.replace({
      search: qs.stringify({ ...this.props.queryParams, email: this.state.email }),
    });
    checkSAMLEnabled(this.state.email)
      .then(({ enabled }) => {
        if (enabled) {
          this.setState({ redirectingToSAML: true });
          this.handleSAMLLogin();
        } else {
          this.setState({ emailLocked: true, loading: false });
        }
      })
      .catch(error => {
        showErrorPopup(error);
        this.setState({ loading: false });
      });
  };

  handleChangeEmail = e => {
    this.setState({ emailLocked: false, email: e.currentTarget.value });
  };

  handleChangePassword = e => {
    this.setState({ password: e.currentTarget.value });
  };

  showError = (error: string) => {
    this.setState({ error });
  };

  render() {
    const { next } = this.props.queryParams;

    return (
      <AuthWindow title="Sign In to your account">
        <GoogleButton
          next={next}
          onError={this.showError}
          label="Sign In with Google"
          disallowSignup
        />
        <AuthOptionDivider />
        {this.state.error && (
          <ErrorBlock text={this.state.error} onDismiss={this.handleErrorDismiss} />
        )}
        <form
          onSubmit={this.state.emailLocked ? this.handleLogin : this.handleLockEmail}
          noValidate
        >
          <StyledTextField
            value={this.state.email}
            onChange={this.handleChangeEmail}
            error={this.state.invalidEmail ? 'Please enter valid email address' : ''}
            type="email"
            name="email"
            label="Email"
            autoFocus
            disabled={this.state.loading}
          />
          {this.state.redirectingToSAML ? (
            <SSOMessage>Opening your single sign on provider...</SSOMessage>
          ) : null}
          {this.state.emailLocked ? (
            <>
              <StyledTextField
                value={this.state.password}
                onChange={this.handleChangePassword}
                error={
                  this.state.invalidPassword ? 'Password must be at least 8 characters long' : ''
                }
                type="password"
                label="Password"
                name="password"
                autoFocus
              />
              <StyledButton type="submit" label="Sign In" primary loading={this.state.loading} />
            </>
          ) : (
            <StyledButton
              type="button"
              label="Continue"
              primary
              loading={this.state.loading}
              onClick={this.handleLockEmail}
            />
          )}
        </form>
        <AuthFooter>
          <StyledParagraph>
            Forgot password? <Link to="/reset">Reset password</Link>
          </StyledParagraph>
        </AuthFooter>
      </AuthWindow>
    );
  }
}

export default withQueryParams(Login);
