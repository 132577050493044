/**
 * @flow
 * @relayHash f7ef7d7a6415b762cafef50f700a79fc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AttachmentsContainer_event$ref = any;
export type AttachmentsContainerRefetchQueryVariables = {|
  eventSlug: string,
  query?: ?string,
|};
export type AttachmentsContainerRefetchQueryResponse = {|
  +event: ?{|
    +id: string,
    +$fragmentRefs: AttachmentsContainer_event$ref,
  |}
|};
export type AttachmentsContainerRefetchQuery = {|
  variables: AttachmentsContainerRefetchQueryVariables,
  response: AttachmentsContainerRefetchQueryResponse,
|};
*/


/*
query AttachmentsContainerRefetchQuery(
  $eventSlug: String!
  $query: String
) {
  event(slug: $eventSlug) {
    id
    ...AttachmentsContainer_event
  }
}

fragment AttachmentsContainer_event on Event {
  slug
  allAttachments(query: $query) {
    edges {
      node {
        id
        filename
        fileurl
        filetype
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "eventSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "query",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "eventSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AttachmentsContainerRefetchQuery",
  "id": null,
  "text": "query AttachmentsContainerRefetchQuery(\n  $eventSlug: String!\n  $query: String\n) {\n  event(slug: $eventSlug) {\n    id\n    ...AttachmentsContainer_event\n  }\n}\n\nfragment AttachmentsContainer_event on Event {\n  slug\n  allAttachments(query: $query) {\n    edges {\n      node {\n        id\n        filename\n        fileurl\n        filetype\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AttachmentsContainerRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "FragmentSpread",
            "name": "AttachmentsContainer_event",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AttachmentsContainerRefetchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": v1,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allAttachments",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "query",
                "variableName": "query",
                "type": "String"
              }
            ],
            "concreteType": "AttachmentRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AttachmentRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Attachment",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "filename",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileurl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "filetype",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5cb527cb5e1b970c24d59232b5e53a76';
module.exports = node;
