/**
 * @flow
 * @relayHash 3bc7b415d91163f09931699b42c1ced6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomizableType = "COMPANY" | "CONTACT" | "EVENT" | "EVENTCONTACT" | "SALESFORCEOPPORTUNITY" | "STAFFMEMBERSHIP" | "VENDOR";
export type ReorderCustomFieldsInput = {
  clientMutationId?: ?string,
  customizableType: CustomizableType,
  fieldsOrder: $ReadOnlyArray<FieldOrderInput>,
};
export type FieldOrderInput = {
  id: string,
  order: number,
  sectionId?: ?string,
};
export type reorderCustomFieldsMutationVariables = {|
  input: ReorderCustomFieldsInput
|};
export type reorderCustomFieldsMutationResponse = {|
  +reorderCustomFields: ?{|
    +updatedFields: $ReadOnlyArray<{|
      +id: string,
      +order: number,
      +section: ?{|
        +id: string
      |},
    |}>
  |}
|};
export type reorderCustomFieldsMutation = {|
  variables: reorderCustomFieldsMutationVariables,
  response: reorderCustomFieldsMutationResponse,
|};
*/


/*
mutation reorderCustomFieldsMutation(
  $input: ReorderCustomFieldsInput!
) {
  reorderCustomFields(input: $input) {
    updatedFields {
      id
      order
      section {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReorderCustomFieldsInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reorderCustomFields",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ReorderCustomFieldsInput!"
      }
    ],
    "concreteType": "ReorderCustomFieldsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatedFields",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomFieldType",
        "plural": true,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "order",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "section",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomFieldSectionType",
            "plural": false,
            "selections": [
              v1
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "reorderCustomFieldsMutation",
  "id": null,
  "text": "mutation reorderCustomFieldsMutation(\n  $input: ReorderCustomFieldsInput!\n) {\n  reorderCustomFields(input: $input) {\n    updatedFields {\n      id\n      order\n      section {\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "reorderCustomFieldsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "reorderCustomFieldsMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '31cab1efbf955d2ef7c2a43024ae25cb';
module.exports = node;
