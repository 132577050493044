/* @flow */
import React from 'react';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import Tooltip from 'components/material/Tooltip';

const Container = styled.div`
  margin-left: 5px;
  margin-right: 1px; /* to always show tooltip on hover during transition */
  flex: 0 0 18px;
`;

const Icon = styled.i`
  font-size: 13px;
  color: ${props => props.iconColor || '#4069e1'};
  visibility: visible;
`;

type User = {
  +firstName: string,
  +lastName: ?string,
  +email: string,
  +id: string,
};
export default class ExportedSavedViewTooltipContent extends React.PureComponent<{
  frequency: string,
  scheduledOn: string,
  recipients: $ReadOnlyArray<User>,
  iconColor?: string,
}> {
  tooltipLabel = (frequency: string, scheduledOn: string, recipients: $ReadOnlyArray<User>) => {
    const usersList = recipients.map(user => fullNameOfUser(user)).join(', ');
    const MonthlyScheduledTime = scheduledOn === 'end_of_month' ? 'end' : 'beginning';

    return `Email report is scheduled to be sent ${frequency === 'weekly' ? 'every' : 'at the'} ${
      frequency === 'weekly' ? scheduledOn : MonthlyScheduledTime
    } ${frequency === 'monthly' ? 'of every month' : ''} to: \n ${usersList}`;
  };

  render() {
    const { frequency, scheduledOn, recipients, iconColor } = this.props;
    return (
      <Container>
        <Tooltip label={this.tooltipLabel(frequency, scheduledOn, recipients)} placement="right">
          <Icon iconColor={iconColor} className="fa fa-fw fa-clock-o" />
        </Tooltip>
      </Container>
    );
  }
}
