/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import EventRequestsItemFormReviewers from 'views/Main/Dashboard/EventRequests/columns/EventRequestsItemFormReviewers';

import type { EventRequestsReviewers_event } from './__generated__/EventRequestsReviewers_event.graphql';

const StyledEventRequestsItemFormReviewers = styled(EventRequestsItemFormReviewers)`
  padding-right: 17px;
  margin: -6px -18px -6px -10px;
`;
const EventRequestsReviewers = (props: { event: EventRequestsReviewers_event }) => {
  const eventRequestSubmission = props.event.eventRequestSubmission;
  if (!eventRequestSubmission) {
    return null;
  }

  return <StyledEventRequestsItemFormReviewers eventRequestSubmission={eventRequestSubmission} />;
};

export default createFragmentContainer(
  EventRequestsReviewers,
  graphql`
    fragment EventRequestsReviewers_event on Event {
      eventRequestSubmission {
        ...EventRequestsItemFormReviewers_eventRequestSubmission
      }
    }
  `,
);
