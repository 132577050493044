/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { Location } from 'react-router-dom';
import styled from 'styled-components';

import assignTasks from 'graph/mutations/task/assignTasks';
import unassignTasks from 'graph/mutations/task/unassignTasks';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineUserSelectField from 'components/budget/Table/InlineUserSelectField';
import UsersGroup from 'components/material/UsersGroup';
import { type User } from 'components/UserSelect';

import type { TasksFiltersInputType } from './index';

import type { DeliverableAssignees_deliverable } from './__generated__/DeliverableAssignees_deliverable.graphql';
import type { DeliverableAssignees_me } from './__generated__/DeliverableAssignees_me.graphql';

const StyledUsersGroup = styled(UsersGroup)`
  padding-right: 17px;
  margin: -6px -18px -6px -10px;
`;

class DeliverableAssignees extends React.PureComponent<{
  deliverable: DeliverableAssignees_deliverable,
  me?: ?DeliverableAssignees_me,
  location: Location,
  filters: TasksFiltersInputType,
}> {
  handleTaskAssign = (user: ?User) => {
    if (user) {
      assignTasks(
        [this.props.deliverable.id],
        user.id,
        this.props.location.pathname.includes('all_tasks') ? 'all tasks' : 'my tasks',
      ).catch(showModernMutationError);
    }
  };

  handleTaskUnassign = (user: User) => {
    unassignTasks(
      [this.props.deliverable.id],
      user.id,
      this.props.location.pathname.includes('all_tasks') ? 'all tasks' : 'my tasks',
      this.props.filters,
      this.props.me != null ? this.props.me.id : null,
    ).catch(showModernMutationError);
  };

  render() {
    const users = this.props.deliverable.assignees.edges.map(({ node }) => node);
    if (!this.props.deliverable.viewerCanAssign) {
      return <StyledUsersGroup users={users} maxCount={4} avatarRadius={13} />;
    }

    return (
      <InlineUserSelectField
        placeholder="Assign"
        activeUsers={users}
        onSelect={this.handleTaskAssign}
        onUnselect={this.handleTaskUnassign}
        eventId={this.props.deliverable.event.id}
        isMultiselect
      >
        {users.length > 0 && <StyledUsersGroup users={users} maxCount={4} avatarRadius={13} />}
      </InlineUserSelectField>
    );
  }
}

export default createFragmentContainer(DeliverableAssignees, {
  deliverable: graphql`
    fragment DeliverableAssignees_deliverable on Deliverable {
      id
      viewerCanAssign
      event {
        id
      }
      assignees {
        edges {
          node {
            id
            firstName
            lastName
            email
            avatar
            ...MaterialAvatar_user
            ...UsersGroup_users
          }
        }
      }
    }
  `,
  me: graphql`
    fragment DeliverableAssignees_me on User {
      id
    }
  `,
});
