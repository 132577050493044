/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import EventRequestFormContainer from './components/EventRequestFormContainer';
import SectionNumber from './components/SectionNumber';
import SectionOrFieldLogic from './components/SectionOrFieldLogic';
import EventRequestFormBuilderFooter from './EventRequestFormBuilderFooter';

import type { EventRequestFormBuilderBody_org } from './__generated__/EventRequestFormBuilderBody_org.graphql';
import type { EventRequestFormBuilderBody_requestForm } from './__generated__/EventRequestFormBuilderBody_requestForm.graphql';

const Container = styled(EventRequestFormContainer)`
  position: relative;
  margin-bottom: 20px;
  padding: 35px 60px;
  @media (${props => props.theme.mobileOnly}) {
    padding-top: 40px;
  }
`;

const StyledSectionNumber = styled(SectionNumber)`
  position: absolute;
  top: 20px;
  left: 0;
  @media (${props => props.theme.mobileOnly}) {
    top: 10px;
  }
`;

class EventRequestFormBuilderLogic extends React.Component<
  {
    org: EventRequestFormBuilderBody_org,
    requestForm: EventRequestFormBuilderBody_requestForm,
    onNextStep: () => void,
    onPrevStep: () => void,
  },
  {
    errors: boolean,
  },
> {
  state = {
    errors: false,
  };

  handleNext = () => {
    window.requestAnimationFrame(() => {
      this.props.onNextStep();
    });
  };

  handleBack = () => {
    window.requestAnimationFrame(() => {
      this.props.onPrevStep();
    });
  };

  render() {
    const requestForm = this.props.requestForm;
    const sections = requestForm.sections.edges.map(edge => edge.node);
    return (
      <>
        {sortBy(sections, 'order').map((section, index) => (
          <Container key={section.id}>
            <StyledSectionNumber>
              {index + 1}/{sections.length}
            </StyledSectionNumber>
            <SectionOrFieldLogic
              key={section.id}
              requestForm={this.props.requestForm}
              section={section}
              org={this.props.org}
            />
            {sortBy(
              section.questions.edges.map(edge => edge.node),
              'order',
            ).map(question => (
              <SectionOrFieldLogic
                key={question.id}
                requestForm={this.props.requestForm}
                section={section}
                question={question}
                org={this.props.org}
              />
            ))}
          </Container>
        ))}
        <EventRequestFormBuilderFooter
          activeStepIndex={1}
          onNextStep={this.handleNext}
          onPrevStep={this.handleBack}
          errors={this.state.errors ? ['Please correct the errors in form logic'] : []}
          loading={false}
        />
      </>
    );
  }
}

export default createFragmentContainer(
  EventRequestFormBuilderLogic,
  graphql`
    fragment EventRequestFormBuilderLogic_org on Org {
      ...SectionOrFieldLogic_org
    }
  `,
);
