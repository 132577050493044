/* @flow */
import ContactActionCell from './ContactActionCell';
import ContactAttendanceDuration from './ContactAttendanceDuration';
import ContactAttendanceJoinTime from './ContactAttendanceJoinTime';
import ContactAttendanceLeaveTime from './ContactAttendanceLeaveTime';
import ContactAttendanceStatus from './ContactAttendanceStatus';
import ContactCity from './ContactCity';
import ContactCompany from './ContactCompany';
import ContactCountry from './ContactCountry';
import ContactCreatedAt from './ContactCreatedAt';
import ContactCreatedMethod from './ContactCreatedMethod';
import ContactCreator from './ContactCreator';
import ContactDescription from './ContactDescription';
import ContactEmail from './ContactEmail';
import ContactEmailOptIn from './ContactEmailOptIn';
import ContactEventsCount from './ContactEventsCount';
import ContactHotLead from './ContactHotLead';
import ContactLastSynced from './ContactLastSynced';
import ContactLinkedin from './ContactLinkedin';
import ContactMarketoId from './ContactMarketoId';
import ContactMembershipLastSynced from './ContactMembershipLastSynced';
import ContactMembershipSyncStatus from './ContactMembershipSyncStatus';
import ContactName from './ContactName';
import ContactOwner from './ContactOwner';
import ContactPersonType from './ContactPersonType';
import ContactPhone from './ContactPhone';
import ContactRegistrationDate from './ContactRegistrationDate';
import ContactRegistrationStatus from './ContactRegistrationStatus';
import ContactRequestSubmissionsCount from './ContactRequestSubmissionsCount';
import ContactSalesforceId from './ContactSalesforceId';
import ContactSalesforceLeadStatus from './ContactSalesforceLeadStatus';
import ContactSalesforceSyncAs from './ContactSalesforceSyncAs';
import ContactState from './ContactState';
import ContactStreet from './ContactStreet';
import ContactSyncStatus from './ContactSyncStatus';
import ContactTitle from './ContactTitle';
import ContactTwitter from './ContactTwitter';
import ContactUpdatedAt from './ContactUpdatedAt';
import ContactUpdatedMethod from './ContactUpdatedMethod';
import ContactUpdater from './ContactUpdater';
import ContactWebsite from './ContactWebsite';
import ContactZip from './ContactZip';

export {
  ContactActionCell,
  ContactAttendanceDuration,
  ContactAttendanceJoinTime,
  ContactAttendanceLeaveTime,
  ContactAttendanceStatus,
  ContactCity,
  ContactCompany,
  ContactCountry,
  ContactCreatedAt,
  ContactCreatedMethod,
  ContactCreator,
  ContactDescription,
  ContactEmail,
  ContactEmailOptIn,
  ContactEventsCount,
  ContactHotLead,
  ContactLastSynced,
  ContactLinkedin,
  ContactMarketoId,
  ContactMembershipLastSynced,
  ContactMembershipSyncStatus,
  ContactName,
  ContactOwner,
  ContactPersonType,
  ContactPhone,
  ContactRegistrationDate,
  ContactRegistrationStatus,
  ContactRequestSubmissionsCount,
  ContactSalesforceId,
  ContactSalesforceLeadStatus,
  ContactSalesforceSyncAs,
  ContactState,
  ContactStreet,
  ContactSyncStatus,
  ContactTitle,
  ContactTwitter,
  ContactUpdatedAt,
  ContactUpdatedMethod,
  ContactUpdater,
  ContactWebsite,
  ContactZip,
};

export const defaultTableComponents = {
  name: ContactName,
  salesforce_id: ContactSalesforceId,
  marketo_id: ContactMarketoId,
  email: ContactEmail,
  events: ContactEventsCount,
  request_submissions: ContactRequestSubmissionsCount,
  salesforce_sync_as: ContactSalesforceSyncAs,
  salesforce_lead_status_id: ContactSalesforceLeadStatus,
  sync_status: ContactSyncStatus,
  last_synced: ContactLastSynced,
  membership_sync_status: ContactMembershipSyncStatus,
  membership_last_synced: ContactMembershipLastSynced,
  contact_type_id: ContactPersonType,
  owner_id: ContactOwner,
  street: ContactStreet,
  company_id: ContactCompany,
  zip: ContactZip,
  title: ContactTitle,
  city: ContactCity,
  country: ContactCountry,
  state: ContactState,
  twitter: ContactTwitter,
  linkedin: ContactLinkedin,
  description: ContactDescription,
  phone1: ContactPhone,
  phone2: ContactPhone,
  website: ContactWebsite,
  hot_lead: ContactHotLead,
  email_opt_in: ContactEmailOptIn,
  registered_at: ContactRegistrationDate,
  registration_status_id: ContactRegistrationStatus,
  attendance_status_id: ContactAttendanceStatus,
  attendance_join_time: ContactAttendanceJoinTime,
  attendance_leave_time: ContactAttendanceLeaveTime,
  attendance_duration: ContactAttendanceDuration,
  updated_at: ContactUpdatedAt,
  updated_by: ContactUpdater,
  updated_method: ContactUpdatedMethod,
  created_at: ContactCreatedAt,
  created_by: ContactCreator,
  created_method: ContactCreatedMethod,
};
