/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import TaskAddButton from './TaskAddButton';

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const Header = styled.div`
  position: relative;
  background: ${props => props.theme.primaryRowColor};
  padding: 4px 15px 4px 35px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 13px;
  color: ${props => props.theme.rowPrimaryTextColor};
`;

const Icon = styled.i`
  color: ${props => props.theme.rowSecondaryTextColor};
  font-size: 20px;
  position: absolute;
  top: -2px;
  left: 6px;
  cursor: pointer;
`;

const StyledAddButton = styled(TaskAddButton)`
  position: absolute;
  top: 2px;
  right: 7px;
`;

const Content = styled.div`
  margin-top: 10px;
`;

export default class TaskSection extends React.PureComponent<
  {
    title: string,
    children: React.Node,
    onAdd?: () => void,
    addButtonRef?: () => void,
    disableAdd?: boolean,
  },
  {
    open: boolean,
  },
> {
  state = { open: true };

  handleOpenToggle = () =>
    this.setState(prevState => ({
      open: !prevState.open,
    }));

  handleAddClick = () => {
    if (!this.state.open) {
      this.setState({ open: true });
    }

    if (this.props.onAdd) {
      this.props.onAdd();
    }
  };

  render() {
    return (
      <Container>
        <Header>
          <Icon
            className={`fa fa-fw fa-angle-${this.state.open ? 'down' : 'right'}`}
            onClick={this.handleOpenToggle}
          />
          {this.props.title}
          {!this.props.disableAdd && (
            <StyledAddButton onClick={this.handleAddClick} ref={this.props.addButtonRef} />
          )}
        </Header>
        {this.state.open && <Content>{this.props.children}</Content>}
      </Container>
    );
  }
}
