/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TextComponent_componentProps$ref: FragmentReference;
export type TextComponent_componentProps = {|
  +id: string,
  +textComponent: ?{|
    +id: string,
    +value: string,
    +padding: string,
    +backgroundColor: ?string,
  |},
  +$refType: TextComponent_componentProps$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TextComponent_componentProps",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "textComponent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationTextComponent",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "padding",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "backgroundColor",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '162574f43b6595005e228d0c0dc6645c';
module.exports = node;
