/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { OpportunityDescription_opportunity } from './__generated__/OpportunityDescription_opportunity.graphql';

class OpportunityDescription extends React.Component<{
  opportunity: OpportunityDescription_opportunity,
}> {
  render() {
    return this.props.opportunity.description;
  }
}

export default createFragmentContainer(
  OpportunityDescription,
  graphql`
    fragment OpportunityDescription_opportunity on SalesforceOpportunityType {
      description
    }
  `,
);
