/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import OrgStyle from './OrgStyle';

import type { OrgStyleQueryResponse } from './__generated__/OrgStyleQuery.graphql';

const Container = styled.div`
  padding: 0 25px 24px;
`;

const query = graphql`
  query OrgStyleQuery {
    org {
      ...OrgStyle_org
    }
  }
`;

export default class OrgStyleRoot extends React.PureComponent<{}> {
  render() {
    return (
      <Container>
        <DefaultQueryRenderer
          query={query}
          renderSuccess={(response: OrgStyleQueryResponse) => <OrgStyle org={response.org} />}
        />
      </Container>
    );
  }
}
