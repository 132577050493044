/* @flow */
import { type SalesforceSyncTypes } from 'config/salesforceSyncOptions';

import isValidEmail from 'utils/validators/isValidEmail';

export type SalesforceContactType = {
  +salesforceSyncAs: ?SalesforceSyncTypes,
  +firstName: ?string,
  +lastName: ?string,
  +email: ?string,
  +company: ?{
    +salesforceId: ?string,
  },
};

const canSyncToSalesforce = (
  contact: SalesforceContactType,
): {
  syncEnabled: boolean,
  missingFields: $ReadOnlyArray<string>,
  invalidFields: $ReadOnlyArray<string>,
} => {
  const firstNameValid = contact.firstName && contact.firstName.trim();
  const lastNameValid = contact.lastName && contact.lastName.trim();
  const companyValid = contact.company || contact.salesforceSyncAs === 'contact';
  const accountValid =
    contact.salesforceSyncAs === 'contact'
      ? !contact.company || contact.company.salesforceId
      : true;
  const emailValid = contact.email && isValidEmail(contact.email);

  const syncEnabled = !!(
    firstNameValid &&
    lastNameValid &&
    companyValid &&
    emailValid &&
    accountValid
  );

  const missingFields = [
    firstNameValid ? '' : 'First Name',
    lastNameValid ? '' : 'Last Name',
    companyValid ? '' : 'Company',
    emailValid ? '' : 'Email',
  ].filter(Boolean);
  const invalidFields = [accountValid ? '' : 'Account'].filter(Boolean);

  return { syncEnabled, missingFields, invalidFields };
};

export default canSyncToSalesforce;
