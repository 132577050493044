/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { EventRequestsItemDeclineReason_eventRequestSubmission } from './__generated__/EventRequestsItemDeclineReason_eventRequestSubmission.graphql';

class EventRequestsItemDeclineReason extends React.PureComponent<{
  eventRequestSubmission: EventRequestsItemDeclineReason_eventRequestSubmission,
  onColumnLoad?: (value: string, str: string, extraSpace?: number) => void,
  name: string,
}> {
  componentDidMount() {
    const { name, eventRequestSubmission, onColumnLoad } = this.props;
    if (onColumnLoad) {
      onColumnLoad(
        name,
        eventRequestSubmission.declineReason ? eventRequestSubmission.declineReason.name : '',
      );
    }
  }

  render() {
    const { eventRequestSubmission } = this.props;
    return (
      <div>
        {eventRequestSubmission.declineReason ? eventRequestSubmission.declineReason.name : ''}
      </div>
    );
  }
}

export default createFragmentContainer(
  EventRequestsItemDeclineReason,
  graphql`
    fragment EventRequestsItemDeclineReason_eventRequestSubmission on EventRequestSubmission {
      declineReason {
        name
      }
    }
  `,
);
