/* @flow */
import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import showErrorPopup from 'utils/showErrorPopup';

import type { Disposable, RelayPaginationProp } from 'graph/types/RelayPaginationProp';

import LoadMoreRow from 'components/LoadMoreRow';
import type { ColumnConfiguration } from 'components/material/table';
import NoResultsMessage from 'components/NoResultsMessage';
import ScrollbarSizes from 'components/ScrollbarSizes';

import ImportLogsTableRow from './ImportLogsTableRow';

import type { ImportLogsTableList_org } from './__generated__/ImportLogsTableList_org.graphql';
import type { ImportLogsTableList_totalCountOrg } from './__generated__/ImportLogsTableList_totalCountOrg.graphql';

const List = styled.div`
  min-width: ${props => props.minWidth}px;
  padding-bottom: 40px;
`;

const StyledLoadMoreRow = styled(LoadMoreRow)`
  position: absolute;
  z-index: 8;
  right: ${props => props.vScrollbarSize}px;
  bottom: ${props => props.hScrollbarSize}px;
  padding: 0 15px;
  border-left: 1px solid ${props => props.theme.primaryRowColor};
  border-top: 1px solid ${props => props.theme.primaryRowColor};
`;

class ImportLogsTableList extends React.PureComponent<
  {
    org: ImportLogsTableList_org,
    totalCountOrg: ImportLogsTableList_totalCountOrg,
    relay: RelayPaginationProp,
    minWidth: number,
    vScrollbarSize: number,
    hScrollbarSize: number,
    onScrollbarSizesChange: ({ vScrollbarSize: number, hScrollbarSize: number }) => void,
    scrollContainer: HTMLElement,
    columns: ColumnConfiguration,
    tz: string,
    revertConfirmationMessage: string,
  },
  {
    refetching: boolean,
  },
> {
  state = {
    refetching: false,
  };

  paginationDisposable: ?Disposable;

  componentWillUnmount() {
    if (this.paginationDisposable) {
      this.paginationDisposable.dispose();
      this.paginationDisposable = null;
    }
  }

  handleLoadMore = amount => {
    this.setState({ refetching: true });

    this.paginationDisposable = this.props.relay.loadMore(amount, err => {
      this.setState({ refetching: false });
      if (err) {
        showErrorPopup(err);
      }
    });
  };

  render() {
    const { org, minWidth, totalCountOrg } = this.props;
    const importLogs = org.importLogs.edges.map(edge => edge.node);
    const totalCount = totalCountOrg.importsCount.totalCount;

    return (
      <List minWidth={minWidth}>
        {importLogs.map(importLog => (
          <ImportLogsTableRow
            key={importLog.id}
            importLog={importLog}
            columns={this.props.columns}
            tz={this.props.tz}
            orgId={org.id}
            revertConfirmationMessage={this.props.revertConfirmationMessage}
          />
        ))}

        {importLogs.length === 0 && (
          <NoResultsMessage iconName="cloud-upload" message="No imports yet" />
        )}

        <StyledLoadMoreRow
          onLoadMore={this.handleLoadMore}
          loading={this.state.refetching}
          totalCount={Math.min(importLogs.length, totalCount)}
          hasNextPage={org.importLogs.pageInfo.hasNextPage}
          vScrollbarSize={this.props.vScrollbarSize}
          hScrollbarSize={this.props.hScrollbarSize}
        />

        <ScrollbarSizes
          shouldUpdate={org.importLogs.edges.length}
          scrollContainer={this.props.scrollContainer}
          onScrollbarSizesChange={this.props.onScrollbarSizesChange}
          vScrollbarSize={this.props.vScrollbarSize}
          hScrollbarSize={this.props.hScrollbarSize}
        />
      </List>
    );
  }
}

export default createPaginationContainer(
  ImportLogsTableList,
  {
    org: graphql`
      fragment ImportLogsTableList_org on Org {
        id
        importLogs(first: $count, after: $cursor, type: $type, sort: $sort, direction: $direction)
          @connection(key: "ImportLogsTableList_importLogs", filters: []) {
          edges {
            node {
              id
              ...ImportLogsTableRow_importLog
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `,
    totalCountOrg: graphql`
      fragment ImportLogsTableList_totalCountOrg on Org {
        importsCount: importLogs(type: $type) {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.org && props.org.importLogs;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        ...fragmentVariables,
        count,
        cursor,
      };
    },
    query: graphql`
      query ImportLogsTableListPaginationQuery(
        $count: Int!
        $cursor: String
        $type: ImportType!
        $sort: ImportLogSort
        $direction: Direction!
      ) {
        org {
          ...ImportLogsTableList_org
        }
      }
    `,
  },
);
