/* @flow */
import sortBy from 'lodash/sortBy';

import customFieldsOptions from './customFieldsOptions';
import type { GroupField } from './types';

import { type SalesforceMapping_org } from '../__generated__/SalesforceMapping_org.graphql';

const eventFields = (org: SalesforceMapping_org): $ReadOnlyArray<GroupField> => {
  const eventCustomFields = sortBy(
    org.eventCustomFields ? org.eventCustomFields.edges.map(edge => edge.node) : [],
    field => (field.section ? field.section.order : 0),
    field => field.order,
  );

  return [
    {
      title: '',
      fields: [
        {
          label: 'Team Name',
          name: 'team_name',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: false,
        },
        {
          label: 'Event Name',
          name: 'name',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: false,
        },
        {
          label: 'Status',
          name: 'state',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: true,
          nillable: false,
        },
        {
          label: 'Start Date',
          name: 'start_date',
          types: ['date'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'End Date',
          name: 'end_date',
          types: ['date'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'Website',
          name: 'website',
          types: ['url'],
          toSalesforce: true,
          fromSalesforce: true,
          nillable: true,
        },
        {
          label: 'Description',
          name: 'description',
          types: ['textarea'],
          toSalesforce: true,
          fromSalesforce: true,
          nillable: true,
          longText: true,
        },
        {
          label: 'Venue Name',
          name: 'primary_location_name',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'Street Address',
          name: 'primary_location_address',
          types: ['string', 'textarea'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'City',
          name: 'primary_location_city',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'State/District',
          name: 'primary_location_state',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'Zip/Postal Code',
          name: 'primary_location_zip',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'Country',
          name: 'primary_location_country',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'Booth',
          name: 'booth',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'Booth Dimensions',
          name: 'booth_dimensions',
          types: ['string'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        {
          label: 'Floor Plan',
          name: 'floor_plan',
          types: ['textarea'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: true,
        },
        ...eventCustomFields
          .filter(field => field.kind !== 'USER_MULTISELECT')
          .map(field => ({
            label: field.label,
            name: field.fieldName || 'custom',
            toSalesforce: true,
            fromSalesforce: true,
            nillable: !field.required && field.kind !== 'BOOLEAN',
            customFieldId: field.id,
            ...customFieldsOptions[field.kind],
          })),
        {
          label: 'Planned Total',
          name: 'total_planned_cost',
          types: ['currency', 'int', 'double'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: false,
        },
        {
          label: 'Actual Total',
          name: 'total_actual_cost',
          types: ['currency', 'int', 'double'],
          toSalesforce: true,
          fromSalesforce: false,
          nillable: false,
        },
      ],
    },
  ];
};

export default eventFields;
