/**
 * @flow
 * @relayHash d019b3884e8893a5b7bfa4aaaaa7b3f3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConfigureMarketoChannelMappingInput = {
  clientMutationId?: ?string,
  channelId: string,
  progressionStatus: string,
  customFieldOptionId?: ?string,
};
export type configureMarketoChannelMappingMutationVariables = {|
  input: ConfigureMarketoChannelMappingInput
|};
export type configureMarketoChannelMappingMutationResponse = {|
  +configureMarketoChannelMapping: ?{|
    +marketoChannelMappingEdge: ?{|
      +node: {|
        +id: string,
        +channelId: string,
        +progressionStatus: string,
        +customFieldOption: {|
          +id: string
        |},
      |}
    |},
    +removedMarketoChannelMappingId: ?string,
  |}
|};
export type configureMarketoChannelMappingMutation = {|
  variables: configureMarketoChannelMappingMutationVariables,
  response: configureMarketoChannelMappingMutationResponse,
|};
*/


/*
mutation configureMarketoChannelMappingMutation(
  $input: ConfigureMarketoChannelMappingInput!
) {
  configureMarketoChannelMapping(input: $input) {
    marketoChannelMappingEdge {
      node {
        id
        channelId
        progressionStatus
        customFieldOption {
          id
        }
      }
    }
    removedMarketoChannelMappingId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ConfigureMarketoChannelMappingInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "configureMarketoChannelMapping",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ConfigureMarketoChannelMappingInput!"
      }
    ],
    "concreteType": "ConfigureMarketoChannelMappingPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "marketoChannelMappingEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "MarketoChannelMappingRequiredEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "MarketoChannelMapping",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "channelId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "progressionStatus",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "customFieldOption",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldOptionType",
                "plural": false,
                "selections": [
                  v1
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedMarketoChannelMappingId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "configureMarketoChannelMappingMutation",
  "id": null,
  "text": "mutation configureMarketoChannelMappingMutation(\n  $input: ConfigureMarketoChannelMappingInput!\n) {\n  configureMarketoChannelMapping(input: $input) {\n    marketoChannelMappingEdge {\n      node {\n        id\n        channelId\n        progressionStatus\n        customFieldOption {\n          id\n        }\n      }\n    }\n    removedMarketoChannelMappingId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "configureMarketoChannelMappingMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "configureMarketoChannelMappingMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b41df2c865368dee54a723871190697a';
module.exports = node;
