/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { Content } from 'components/page/Content';

import CustomFieldSettingsQuery from './CustomFieldSettingsQuery';

import type { PeopleSettings_org } from './__generated__/PeopleSettings_org.graphql';

class PeopleSettings extends React.Component<{
  org: PeopleSettings_org,
}> {
  render() {
    const salesforceAccount = this.props.org.salesforceAccount;
    const syncEnabled = salesforceAccount == null ? false : salesforceAccount.contactsSyncEnabled;
    const filters = {
      customizableType: ['CONTACT'],
      includeDisabled: true,
      includeRestrictManagingOptions: true,
    };
    return (
      <Content>
        <CustomFieldSettingsQuery
          filters={filters}
          customizableType="CONTACT"
          syncEnabled={syncEnabled}
        />
      </Content>
    );
  }
}

export default createFragmentContainer(
  PeopleSettings,
  graphql`
    fragment PeopleSettings_org on Org {
      salesforceAccount {
        contactsSyncEnabled
      }
    }
  `,
);
