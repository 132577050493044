/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import RequestSubmissionRequestedDate_requestSubmission from './__generated__/RequestSubmissionRequestedDate_requestSubmission.graphql';

type Props = {
  requestSubmission: RequestSubmissionRequestedDate_requestSubmission,
  tz: string,
};

const RequestSubmissionRequestedDate = ({ requestSubmission, tz }: Props) => {
  const { createdAt } = requestSubmission;

  return <DateTimeTooltip date={moment.tz(createdAt, tz)} />;
};

export default createFragmentContainer(
  RequestSubmissionRequestedDate,
  graphql`
    fragment RequestSubmissionRequestedDate_requestSubmission on EventRequestSubmission {
      createdAt
    }
  `,
);
