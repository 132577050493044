/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventVirtualLocation_event$ref: FragmentReference;
export type EventVirtualLocation_event = {|
  +virtualLocation: ?string,
  +zoomLocation: ?{|
    +joinUrl: string
  |},
  +g2wLocation: ?{|
    +registrationUrl: string
  |},
  +$refType: EventVirtualLocation_event$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventVirtualLocation_event",
  "type": "Event",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "virtualLocation",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "zoomLocation",
      "storageKey": null,
      "args": null,
      "concreteType": "ZoomLocation",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "joinUrl",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "g2wLocation",
      "storageKey": null,
      "args": null,
      "concreteType": "G2WLocation",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "registrationUrl",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8af19f0b1b8707c44052cac5736d5a86';
module.exports = node;
