/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import BriefViewSharedContext from '../BriefViewSharedContext';

export const Container = styled.div`
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
  color: ${props => props.theme.rowPrimaryTextColor};
  ${props =>
    props.isShared &&
    css`
      @media (max-width: 905px) {
        font-size: 15px;
      }
    `};
`;

export const BlockName = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.riverBed};
`;

export const ItemName = (props: { children: React.Node, className?: string }) => {
  const { className, children } = props;
  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <Container isShared={sharedView} className={className}>
          {children}
        </Container>
      )}
    </BriefViewSharedContext.Consumer>
  );
};
