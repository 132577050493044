/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MarketoProgramPicker_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CRM_org$ref: FragmentReference;
export type CRM_org = {|
  +id: string,
  +salesforceAccount: ?{|
    +contactsSyncEnabled: boolean
  |},
  +marketoAccount: ?{|
    +id: string
  |},
  +settings: {|
    +currency: string
  |},
  +$fragmentRefs: MarketoProgramPicker_org$ref,
  +$refType: CRM_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CRM_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "FragmentSpread",
      "name": "MarketoProgramPicker_org",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "salesforceAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "SalesforceAccount",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "contactsSyncEnabled",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "marketoAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "MarketoAccount",
      "plural": false,
      "selections": [
        v0
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currency",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac315808023521e108ecf40f7072506f';
module.exports = node;
