/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MaterialAvatar_user$ref = any;
export type ContactSyncOption = "contact" | "lead";
export type SyncState = "DISABLED" | "FAILING" | "OK" | "PENDING";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactSearchRow_contact$ref: FragmentReference;
export type ContactSearchRow_contact = {|
  +firstName: string,
  +lastName: string,
  +email: string,
  +salesforceSyncAs: ?ContactSyncOption,
  +title: ?string,
  +syncStatus: {|
    +state: SyncState
  |},
  +company: ?{|
    +name: string,
    +salesforceId: ?string,
  |},
  +$fragmentRefs: MaterialAvatar_user$ref,
  +$refType: ContactSearchRow_contact$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ContactSearchRow_contact",
  "type": "Contact",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "salesforceSyncAs",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "syncStatus",
      "storageKey": null,
      "args": null,
      "concreteType": "SyncStatus",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "company",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "salesforceId",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "MaterialAvatar_user",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '92256a2a312fcc41b6598655591a8309';
module.exports = node;
