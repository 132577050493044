/**
 * @flow
 * @relayHash fda5c72601ae945025feaaa534804af0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemovePaymentInput = {
  clientMutationId?: ?string,
  paymentId: string,
};
export type removePaymentMutationVariables = {|
  input: RemovePaymentInput
|};
export type removePaymentMutationResponse = {|
  +removePayment: ?{|
    +expense: ?{|
      +actualAmount: number,
      +paidAmount: number,
      +viewerCanUpdateAmount: boolean,
      +event: {|
        +totalActualAmount: number,
        +totalPaidAmount: number,
      |},
      +payments: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +viewerCanDelete: boolean
          |}
        |}>
      |},
    |},
    +removedPaymentId: string,
  |}
|};
export type removePaymentMutation = {|
  variables: removePaymentMutationVariables,
  response: removePaymentMutationResponse,
|};
*/


/*
mutation removePaymentMutation(
  $input: RemovePaymentInput!
) {
  removePayment(input: $input) {
    expense {
      actualAmount
      paidAmount
      viewerCanUpdateAmount
      event {
        totalActualAmount
        totalPaidAmount
        id
      }
      payments {
        edges {
          node {
            viewerCanDelete
            id
          }
        }
      }
      id
    }
    removedPaymentId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemovePaymentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RemovePaymentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actualAmount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "paidAmount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdateAmount",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalActualAmount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalPaidAmount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanDelete",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "removedPaymentId",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removePaymentMutation",
  "id": null,
  "text": "mutation removePaymentMutation(\n  $input: RemovePaymentInput!\n) {\n  removePayment(input: $input) {\n    expense {\n      actualAmount\n      paidAmount\n      viewerCanUpdateAmount\n      event {\n        totalActualAmount\n        totalPaidAmount\n        id\n      }\n      payments {\n        edges {\n          node {\n            viewerCanDelete\n            id\n          }\n        }\n      }\n      id\n    }\n    removedPaymentId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removePaymentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removePayment",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemovePaymentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "expense",
            "storageKey": null,
            "args": null,
            "concreteType": "Expense",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v5,
                  v6
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "payments",
                "storageKey": null,
                "args": null,
                "concreteType": "PaymentRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PaymentRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Payment",
                        "plural": false,
                        "selections": [
                          v7
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v8
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "removePaymentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "removePayment",
        "storageKey": null,
        "args": v1,
        "concreteType": "RemovePaymentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "expense",
            "storageKey": null,
            "args": null,
            "concreteType": "Expense",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v5,
                  v6,
                  v9
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "payments",
                "storageKey": null,
                "args": null,
                "concreteType": "PaymentRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PaymentRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Payment",
                        "plural": false,
                        "selections": [
                          v7,
                          v9
                        ]
                      }
                    ]
                  }
                ]
              },
              v9
            ]
          },
          v8
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'abb10e7e71833c54c3a56ab84dc70dec';
module.exports = node;
