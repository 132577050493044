/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import updateExpense from 'graph/mutations/expense/updateExpense';

import ExpenseItem from 'components/budget/ExpenseItem';
import ExpenseWindow from 'components/budget/ExpenseWindow';

import ExpensesOverlay from '../overlays/ExpensesOverlay';
import TaskSection from './TaskSection';

import type { TaskExpenses_task } from './__generated__/TaskExpenses_task.graphql';

class TaskExpenses extends React.PureComponent<
  {
    task: TaskExpenses_task,
    onRemove: string => void,
    onToggleExpenseModal: (expenseModalShown: boolean) => void,
    onToggleAddExpenseForm: (addExpenseForm: boolean) => void,
  },
  {
    overlayShown: boolean,
    windowShown: boolean,
  },
> {
  state = {
    overlayShown: false,
    windowShown: false,
  };

  addButton: any = null;

  componentDidMount() {
    if (this.props.task.expenses.edges.length === 0) {
      this.handleOverlayShow();
    }
  }

  handleOverlayShow = () => {
    this.setState({ overlayShown: true });
    this.props.onToggleExpenseModal(true);
  };

  handleOverlayHide = () => {
    this.setState({ overlayShown: false });
    this.props.onToggleExpenseModal(false);
  };

  handleExpenseRemove = (expenseId: string) => {
    this.props.onRemove(expenseId);
  };

  handleAddClick = () => {
    this.setState({ windowShown: true });
    this.props.onToggleAddExpenseForm(true);
  };

  handleWindowHide = () => {
    this.setState({ windowShown: false });
    this.props.onToggleAddExpenseForm(false);
  };

  handleExpenseSelect = expenseId => {
    updateExpense(expenseId, { deliverableId: this.props.task.id });
  };

  render() {
    const expenses = this.props.task.expenses.edges.map(edge => edge.node);
    const eventExpenses = this.props.task.event.expenses.edges
      .map(edge => edge.node)
      .filter(expense => !expense.deliverable);
    const currency = this.props.task.event.team.org.settings.currency;

    return (
      <TaskSection
        title="Expenses"
        onAdd={this.handleOverlayShow}
        addButtonRef={el => {
          this.addButton = el;
        }}
        disableAdd={!this.props.task.event.viewerCanViewBudget}
      >
        {this.state.windowShown && (
          <ExpenseWindow
            event={this.props.task.event}
            org={this.props.task.event.team.org}
            onHide={this.handleWindowHide}
            taskId={this.props.task.id}
          />
        )}
        <ExpensesOverlay
          target={this.addButton}
          container={this}
          onHide={this.handleOverlayHide}
          show={this.state.overlayShown}
          expenses={eventExpenses}
          onAdd={this.handleAddClick}
          onSelect={this.handleExpenseSelect}
          currency={currency}
        />
        {expenses.map(expense => (
          <ExpenseItem
            expense={expense}
            key={expense.id}
            timezone={this.props.task.event.tz}
            eventSlug={this.props.task.event.slug}
            onRemove={this.handleExpenseRemove}
            currency={currency}
          />
        ))}
      </TaskSection>
    );
  }
}

export default createFragmentContainer(
  TaskExpenses,
  graphql`
    fragment TaskExpenses_task on Deliverable {
      id
      slug
      name
      expenses {
        edges {
          node {
            id
            ...ExpenseItem_expense
          }
        }
      }
      event {
        id
        slug
        tz
        viewerCanViewBudget
        ...ExpenseWindow_event
        expenses {
          edges {
            node {
              ...ExpensesOverlay_expenses
              deliverable {
                id
              }
            }
          }
        }
        team {
          id
          org {
            ...ExpenseWindow_org
            settings {
              currency
            }
          }
        }
      }
    }
  `,
);
