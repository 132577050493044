/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment-timezone';

import {
  activityFrequencyOptions,
  activityScopeOptions,
  assignmentFrequencyOptions,
} from 'config/emailSubscriptionOptions';

import FeatureAccessContext from 'contexts/FeatureAccess';

import updateEmailSubscription from 'graph/mutations/user/updateEmailSubscription';
import showModernMutationError from 'graph/utils/showModernMutationError';

import SelectField from 'components/material/SelectField';
import {
  SettingContainer,
  SettingContent,
  SettingHeader,
  SettingItemDetails,
  SettingItemExplanation,
  SettingItemLabel,
  SettingSection,
  SettingSelectFieldContainer,
  SettingsItemInput,
  SettingsItemInputLabel,
} from 'components/Settings';

import type { EmailSubscriptions_user } from './__generated__/EmailSubscriptions_user.graphql';

type eventScopeType = $PropertyType<
  $PropertyType<EmailSubscriptions_user, 'emailSubscription'>,
  'eventScope',
>;

const Container = styled.div`
  padding: 0 33px;
`;

class EmailSubscriptions extends React.PureComponent<{ user: EmailSubscriptions_user }> {
  handleChangeEventScope = (eventScope: ?eventScopeType) => {
    if (eventScope == null) return;
    updateEmailSubscription({
      userId: this.props.user.id,
      ...this.props.user.emailSubscription,
      eventScope,
    }).catch(showModernMutationError);
  };

  handleChangeActivityFrequency = (activitySummary: ?number) => {
    if (activitySummary == null) return;
    updateEmailSubscription({
      userId: this.props.user.id,
      ...this.props.user.emailSubscription,
      activitySummary,
    }).catch(showModernMutationError);
  };

  handleChangeAssignmentFrequency = (assignmentNotifications: ?number) => {
    if (assignmentNotifications == null) return;
    updateEmailSubscription({
      userId: this.props.user.id,
      ...this.props.user.emailSubscription,
      assignmentNotifications,
    }).catch(showModernMutationError);
  };

  handleToggleCheckbox = (
    key:
      | 'activityNotifications'
      | 'mentionNotifications'
      | 'postEventEngagementReport'
      | 'contactRegistrationNotifications'
      | 'reminders'
      | 'contactAttendanceNotifications'
      | 'addEventStaffNotifications',
  ) => () => {
    updateEmailSubscription({
      userId: this.props.user.id,
      ...this.props.user.emailSubscription,
      [key]: !this.props.user.emailSubscription[key],
    }).catch(showModernMutationError);
  };

  userTzAbbreviation() {
    const user = this.props.user;
    const zoneName = moment.tz(user.tz).zoneName();
    return zoneName.startsWith('+') || zoneName.startsWith('-') ? `GTM ${zoneName}` : zoneName;
  }

  activityScopeExplanationMessage() {
    if (this.props.user.emailSubscription.eventScope === 'MY_EVENTS') {
      return "Only events where I'm on the Staff";
    }
    return "Events in Teams where I'm a Member";
  }

  activityFrequencyExplanationMessage() {
    const zoneAbbr = this.userTzAbbreviation();
    switch (this.props.user.emailSubscription.activitySummary) {
      case 1:
        return `Sent every day at 7 AM in your time zone (${zoneAbbr})`;
      case 2:
        return `Sent every Monday at 7 AM in your time zone (${zoneAbbr})`;
      case 0:
      default:
        return 'Do not send me an activity summary';
    }
  }

  assignmentFrequencyExplanationMessage() {
    const zoneAbbr = this.userTzAbbreviation();
    switch (this.props.user.emailSubscription.assignmentNotifications) {
      case 1:
        return 'Sent 3 minutes after each new assignment';
      case 2:
        return `Sent every day at 7 AM in your time zone (${zoneAbbr})`;
      case 3:
        return `Sent every Monday at 7 AM in your time zone (${zoneAbbr})`;
      case 0:
      default:
        return 'Do not send me any new assignment notifications';
    }
  }

  static contextType = FeatureAccessContext;

  render() {
    const { emailSubscription } = this.props.user;

    return (
      <DocumentTitle title="Profile Settings">
        <Container>
          <SettingContainer>
            <SettingSection>
              <SettingItemExplanation>
                <a href="https://help.circa.co/email" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-question-circle" /> Visit the Support Center for more info on
                  managing email settings
                </a>
              </SettingItemExplanation>
            </SettingSection>

            <SettingSection>
              <SettingHeader>ACTIVITY SUMMARY</SettingHeader>
              <SettingContent>
                <SettingItemDetails>
                  Receive a summary of event activity and your upcoming tasks
                  <SettingItemExplanation>
                    You can batch them for daily or weekly delivery
                  </SettingItemExplanation>
                </SettingItemDetails>
              </SettingContent>
              <SettingContent>
                <SettingItemLabel />
                <SettingItemDetails inline>
                  <SettingSelectFieldContainer>
                    <SelectField
                      value={emailSubscription.eventScope}
                      onChange={this.handleChangeEventScope}
                      options={activityScopeOptions}
                    />
                  </SettingSelectFieldContainer>
                  <SettingItemExplanation>
                    {this.activityScopeExplanationMessage()}
                  </SettingItemExplanation>
                </SettingItemDetails>
              </SettingContent>
              <SettingContent>
                <SettingItemLabel />
                <SettingItemDetails inline>
                  <SettingSelectFieldContainer>
                    <SelectField
                      value={emailSubscription.activitySummary}
                      onChange={this.handleChangeActivityFrequency}
                      options={activityFrequencyOptions}
                    />
                  </SettingSelectFieldContainer>
                  <SettingItemExplanation>
                    {this.activityFrequencyExplanationMessage()}
                  </SettingItemExplanation>
                </SettingItemDetails>
              </SettingContent>
            </SettingSection>
            <SettingSection>
              <SettingHeader>YOUR EVENTS</SettingHeader>
              <SettingContent>
                <SettingItemLabel>Added to Staff</SettingItemLabel>
                <SettingItemDetails>
                  <SettingsItemInputLabel>
                    <SettingsItemInput
                      type="checkbox"
                      onChange={this.handleToggleCheckbox('addEventStaffNotifications')}
                      checked={emailSubscription.addEventStaffNotifications}
                    />
                    Receive an email when you’re added as an Event Staffer
                  </SettingsItemInputLabel>
                </SettingItemDetails>
              </SettingContent>
            </SettingSection>
            <SettingSection>
              <SettingHeader>YOUR TASKS</SettingHeader>
              <SettingContent>
                <SettingItemLabel>New Assignments</SettingItemLabel>
                <SettingItemDetails>
                  Receive a notification when you&apos;re assigned a new Task or Subtask
                  <SettingItemExplanation>
                    You can batch them for daily or weekly delivery options
                  </SettingItemExplanation>
                </SettingItemDetails>
              </SettingContent>
              <SettingContent>
                <SettingItemLabel />
                <SettingItemDetails inline>
                  <SettingSelectFieldContainer>
                    <SelectField
                      value={emailSubscription.assignmentNotifications}
                      onChange={this.handleChangeAssignmentFrequency}
                      options={assignmentFrequencyOptions}
                    />
                  </SettingSelectFieldContainer>
                  <SettingItemExplanation>
                    {this.assignmentFrequencyExplanationMessage()}
                  </SettingItemExplanation>
                </SettingItemDetails>
              </SettingContent>
              <SettingContent>
                <SettingItemLabel>Reminders</SettingItemLabel>
                <SettingItemDetails>
                  <SettingsItemInputLabel>
                    <SettingsItemInput
                      type="checkbox"
                      onChange={this.handleToggleCheckbox('reminders')}
                      checked={emailSubscription.reminders}
                    />
                    Receive gentle reminders for each of your upcoming and overdue Assignments
                  </SettingsItemInputLabel>
                  <SettingItemExplanation>
                    Sent at 7 AM in your time zone at 3 and 1 days before the Task is due
                    <br /> Also sent 2 days after if overdue
                  </SettingItemExplanation>
                </SettingItemDetails>
              </SettingContent>
            </SettingSection>
            <SettingSection>
              <SettingHeader>COMMENTS</SettingHeader>
              <SettingContent>
                <SettingItemLabel>@ Mentions</SettingItemLabel>
                <SettingItemDetails>
                  <SettingsItemInputLabel>
                    <SettingsItemInput
                      type="checkbox"
                      onChange={this.handleToggleCheckbox('mentionNotifications')}
                      checked={emailSubscription.mentionNotifications}
                    />
                    Receive a notification when someone @ mentions you in a comment on a task or
                    request
                  </SettingsItemInputLabel>
                  <SettingItemExplanation>
                    Sent 2 minutes after you&apos;re mentioned
                  </SettingItemExplanation>
                </SettingItemDetails>
              </SettingContent>
              <SettingContent>
                <SettingItemLabel>Followed Tasks</SettingItemLabel>
                <SettingItemDetails>
                  <SettingsItemInputLabel>
                    <SettingsItemInput
                      type="checkbox"
                      onChange={this.handleToggleCheckbox('activityNotifications')}
                      checked={emailSubscription.activityNotifications}
                    />
                    Receive a notification when someone leaves a comment on a task that you&apos;re
                    following
                  </SettingsItemInputLabel>
                  <SettingItemExplanation>Sent 2 minutes after the comment</SettingItemExplanation>
                </SettingItemDetails>
              </SettingContent>
            </SettingSection>
            {this.context.legacyFeatures && (
              <SettingSection>
                <SettingHeader>ATTENDEE ENGAGEMENT</SettingHeader>
                <SettingContent>
                  <SettingItemLabel>Post Event Report</SettingItemLabel>
                  <SettingItemDetails>
                    <SettingsItemInputLabel>
                      <SettingsItemInput
                        type="checkbox"
                        onChange={this.handleToggleCheckbox('postEventEngagementReport')}
                        checked={emailSubscription.postEventEngagementReport}
                      />
                      Receive a summary of contact engagement information after an event has ended
                    </SettingsItemInputLabel>
                  </SettingItemDetails>
                </SettingContent>
                <SettingContent>
                  <SettingItemLabel>Contact Registration</SettingItemLabel>
                  <SettingItemDetails>
                    <SettingsItemInputLabel>
                      <SettingsItemInput
                        type="checkbox"
                        onChange={this.handleToggleCheckbox('contactRegistrationNotifications')}
                        checked={emailSubscription.contactRegistrationNotifications}
                      />
                      Receive an email whenever one of your contacts (you are the assigned Owner)
                      registers for an event
                    </SettingsItemInputLabel>
                  </SettingItemDetails>
                </SettingContent>
                <SettingContent>
                  <SettingItemLabel>Contact Check-In</SettingItemLabel>
                  <SettingItemDetails>
                    <SettingsItemInputLabel>
                      <SettingsItemInput
                        type="checkbox"
                        onChange={this.handleToggleCheckbox('contactAttendanceNotifications')}
                        checked={emailSubscription.contactAttendanceNotifications}
                      />
                      Receive an email when one of your Contacts checks in to an event or webinar
                    </SettingsItemInputLabel>
                  </SettingItemDetails>
                </SettingContent>
              </SettingSection>
            )}
          </SettingContainer>
        </Container>
      </DocumentTitle>
    );
  }
}

export default createFragmentContainer(
  EmailSubscriptions,
  graphql`
    fragment EmailSubscriptions_user on User {
      id
      tz
      emailSubscription {
        activitySummary
        eventScope
        assignmentNotifications
        activityNotifications
        mentionNotifications
        reminders
        postEventEngagementReport
        contactRegistrationNotifications
        contactAttendanceNotifications
        addEventStaffNotifications
      }
    }
  `,
);
