/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment-timezone';

import DateTimeTooltip from 'components/material/DateTimeTooltip';

import type { EventRequestFormsCreatedColumn_requestForm } from './__generated__/EventRequestFormsCreatedColumn_requestForm.graphql';

class EventRequestFormsCreatedColumn extends React.PureComponent<{
  tz: string,
  requestForm: EventRequestFormsCreatedColumn_requestForm,
}> {
  render() {
    return <DateTimeTooltip date={moment.tz(this.props.requestForm.createdAt, this.props.tz)} />;
  }
}

export default createFragmentContainer(
  EventRequestFormsCreatedColumn,
  graphql`
    fragment EventRequestFormsCreatedColumn_requestForm on EventRequestForm {
      createdAt
    }
  `,
);
