/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import { type massUpdateEventsMutationVariables } from './__generated__/massUpdateEventsMutation.graphql';

const mutation = graphql`
  mutation massUpdateEventsMutation($input: MassUpdateEventsInput!) {
    massUpdateEvents(input: $input) {
      eventEdges {
        node {
          ...EventLead_event
          ...EventStatus_event
          ...EventUpdater_event
          ...EventUpdatedAt_event
          ...CustomizableSelect_customizable
          ...CustomizableUserSelect_customizable
        }
      }
    }
  }
`;

export default function massUpdateEvents(
  input: $Exact<$PropertyType<massUpdateEventsMutationVariables, 'input'>>,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input,
    },
  });
}
