/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import eventStates, { type EventStateGraphValue } from 'config/eventStates';

import type { FieldType } from 'utils/customization/types';
import { type CustomFieldInput } from 'utils/customization/types';

import massUpdateEvents from 'graph/mutations/event/massUpdateEvents';
import showModernMutationError from 'graph/utils/showModernMutationError';

import Button, { MinimalButton } from 'components/budget/Button';
import OrgLeadSearch, { type UserType } from 'components/Lead/OrgLeadSearch';
import SelectField from 'components/material/SelectField';
import Window, {
  WindowClose,
  WindowContent,
  WindowHeader,
  WindowTitle,
} from 'components/material/Window';
import UserSelect, { type User } from 'components/UserSelect';

const EventsCountContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

const Row = styled.div`
  margin-top: 20px;
`;

const Footer = styled.div`
  margin-top: 40px;
  text-align: right;

  > *:last-child {
    margin-left: 20px;
  }
`;

export default class AllEventsUpdateWindow extends React.PureComponent<
  {
    eventFields: $ReadOnlyArray<FieldType>,
    selectedEvents: $ReadOnlyArray<string>,
    onHide: () => void,
    onUpdateTableColumnWidths: () => void,
  },
  {
    lead: ?UserType,
    status: ?EventStateGraphValue,
    selectFields: $ReadOnlyArray<CustomFieldInput>,
    userSelectFields: $ReadOnlyArray<{ customFieldId: string, user: ?User }>,
    loading: boolean,
  },
> {
  state = {
    lead: null,
    status: null,
    selectFields: [],
    userSelectFields: [],
    loading: false,
  };

  handleSave = () => {
    this.setState({ loading: true });

    massUpdateEvents({
      eventIds: this.props.selectedEvents,
      status: this.state.status,
      leadId: this.state.lead ? this.state.lead.id : null,
      customFieldValues: [
        ...this.state.selectFields,
        ...this.state.userSelectFields.map(select => ({
          customFieldId: select.customFieldId,
          userValue: select.user ? select.user.id : null,
        })),
      ],
    })
      .then(() => {
        this.props.onHide();
        this.props.onUpdateTableColumnWidths();
      })
      .catch(error => {
        this.setState({ loading: false });

        showModernMutationError(error);
      });
  };

  handleUpdateStatusField = (status: ?EventStateGraphValue) => {
    this.setState({ status });
  };

  handleUpdateLeadField = (lead: ?UserType) => {
    this.setState({ lead });
  };

  handleUpdateSelectField = (customFieldId: string, selectValue: ?string) => {
    if (selectValue != null) {
      this.setState(state => ({
        selectFields: [
          ...state.selectFields.filter(item => item.customFieldId !== customFieldId),
          { customFieldId, selectValue: selectValue != null ? selectValue : '' },
        ],
      }));
    } else {
      this.setState(state => ({
        selectFields: state.selectFields.filter(item => item.customFieldId !== customFieldId),
      }));
    }
  };

  handleUpdateUserSelectField = (customFieldId: string, user: ?User) => {
    if (user != null) {
      this.setState(state => ({
        userSelectFields: [
          ...state.userSelectFields.filter(item => item.customFieldId !== customFieldId),
          { customFieldId, user },
        ],
      }));
    } else {
      this.setState(state => ({
        userSelectFields: state.userSelectFields.filter(
          item => item.customFieldId !== customFieldId,
        ),
      }));
    }
  };

  render() {
    const { onHide, selectedEvents, eventFields } = this.props;

    return (
      <Window onHide={onHide} size={438}>
        <WindowHeader>
          <WindowTitle>
            Update Info
            <EventsCountContainer>
              {`${selectedEvents.length} ${
                selectedEvents.length === 1 ? ' event' : ' events'
              } selected`}
            </EventsCountContainer>
          </WindowTitle>
          <WindowClose onClick={onHide} />
        </WindowHeader>
        <WindowContent>
          {eventFields
            .filter(
              field =>
                field.fieldName === 'status' ||
                field.fieldName === 'lead' ||
                field.kind === 'SELECT' ||
                field.kind === 'USER_SELECT',
            )
            .map(field => {
              if (field.fieldName === 'status') {
                return (
                  <Row>
                    <SelectField
                      label="Status"
                      value={this.state.status}
                      onChange={this.handleUpdateStatusField}
                      options={eventStates.map(s => ({ value: s.graphValue, label: s.label }))}
                      clearable
                    />
                  </Row>
                );
              }

              if (field.fieldName === 'lead') {
                return (
                  <Row>
                    <OrgLeadSearch onSelect={this.handleUpdateLeadField} clearable />
                  </Row>
                );
              }

              if (field.kind === 'SELECT') {
                const selectField = this.state.selectFields.find(
                  customSelect => customSelect.customFieldId === field.id,
                );
                return (
                  <Row key={field.id}>
                    <SelectField
                      label={field.label}
                      value={selectField ? selectField.selectValue : null}
                      onChange={value => {
                        this.handleUpdateSelectField(field.id, value);
                      }}
                      options={field.options}
                      searchable
                      clearable
                    />
                  </Row>
                );
              }

              const userSelectField = this.state.userSelectFields.find(
                customUserSelect => customUserSelect.customFieldId === field.id,
              );
              return (
                <Row key={field.id}>
                  <UserSelect
                    label={field.label}
                    user={userSelectField ? userSelectField.user : null}
                    onSelect={selectUser => {
                      this.handleUpdateUserSelectField(field.id, selectUser);
                    }}
                    clearable
                  />
                </Row>
              );
            })}

          <Footer>
            <MinimalButton label="Cancel" onClick={onHide} />
            <Button onClick={this.handleSave} loading={this.state.loading}>
              Update
            </Button>
          </Footer>
        </WindowContent>
      </Window>
    );
  }
}
