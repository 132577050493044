/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ColumnComponent_componentProps$ref = any;
export type RegistrationPageComponentKind = "COLUMN" | "DIVIDER" | "EMBED" | "EVENT_DATES" | "EVENT_NAME" | "FORM" | "IMAGE" | "ROW" | "TEXT" | "VIDEO";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RowComponent_componentProps$ref: FragmentReference;
export type RowComponent_componentProps = {|
  +id: string,
  +childPageComponents: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +kind: RegistrationPageComponentKind,
        +order: number,
        +columnChild: ?{|
          +id: string
        |},
        +$fragmentRefs: ColumnComponent_componentProps$ref,
      |}
    |}>
  |},
  +rowComponent: ?{|
    +id: string,
    +cellSpacing: ?number,
    +padding: string,
    +backgroundColor: ?string,
  |},
  +$refType: RowComponent_componentProps$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RowComponent_componentProps",
  "type": "RegistrationPageComponent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "childPageComponents",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationPageComponentRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RegistrationPageComponentRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "RegistrationPageComponent",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "columnChild",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RegistrationPageComponent",
                  "plural": false,
                  "selections": [
                    v0
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ColumnComponent_componentProps",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "rowComponent",
      "storageKey": null,
      "args": null,
      "concreteType": "RegistrationRowComponent",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cellSpacing",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "padding",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "backgroundColor",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd93aae095334af8b1f4c11db428a06c3';
module.exports = node;
