/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import removeContactFromCompany from 'graph/mutations/company/removeContactFromCompany';
import showModernMutationError from 'graph/utils/showModernMutationError';

import ShareDropdown from 'components/ShareDropdown';

import type { CompanyContactActionsColumn_company } from './__generated__/CompanyContactActionsColumn_company.graphql';
import type { CompanyContactActionsColumn_contact } from './__generated__/CompanyContactActionsColumn_contact.graphql';

const Root = styled.div`
  margin: 0 -5px 0 -24px;
`;

class CompanyContactEventActionsColumn extends React.Component<{
  contact: CompanyContactActionsColumn_contact,
  company: CompanyContactActionsColumn_company,
}> {
  stopPropagation = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  handleContactRemove = () => {
    removeContactFromCompany({
      contactId: this.props.contact.id,
      companyId: this.props.company.id,
    }).catch(showModernMutationError);
  };

  render() {
    return (
      <Root onClick={this.stopPropagation}>
        <ShareDropdown
          noBorder
          hoverColor="#4db1dd"
          options={[
            {
              label: 'Remove from Company',
              icon: 'trash',
              onClick: this.handleContactRemove,
            },
          ]}
          overlayContainer={document.body}
        />
      </Root>
    );
  }
}

export default createFragmentContainer(CompanyContactEventActionsColumn, {
  contact: graphql`
    fragment CompanyContactActionsColumn_contact on Contact {
      id
    }
  `,
  company: graphql`
    fragment CompanyContactActionsColumn_company on Company {
      id
    }
  `,
});
