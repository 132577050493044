/* @flow */
import React from 'react';
import { type Location, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

import ContactWindow from 'components/Contacts/ContactWindow';
import { type ContactType } from 'components/Contacts/ContactWindow/ContactTypeSelector';

const Container = styled.div`
  position: absolute;
  top: 35px;
  padding: 8px 10px;
  font-size: 13px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
`;

const StyledLink = styled.a`
  color: #3aa8da;
  cursor: pointer;
`;

const StyledText = styled.div`
  color: #3e4859;
`;

class SearchSuggestionBar extends React.Component<
  {
    location: Location,
    eventId: string,
    type: ContactType,
    showSearchSuggestionBar: boolean,
    handleToggleSuggestionComponent?: () => void,
  },
  { addingContact: boolean },
> {
  state = { addingContact: false };

  handleWindowShow = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({ addingContact: true });
    if (this.props.handleToggleSuggestionComponent != null) {
      this.props.handleToggleSuggestionComponent();
    }
  };

  handleWindowClose = () => {
    this.setState({ addingContact: false });
    if (this.props.handleToggleSuggestionComponent != null) {
      this.props.handleToggleSuggestionComponent();
    }
  };

  handleOpenOrgContacts = () => {
    const { type, location } = this.props;
    window.open(`/contacts/${type === 'contacts' ? 'people' : type}${location.search}`, '_blank');
  };

  render() {
    const { eventId, type, showSearchSuggestionBar } = this.props;
    return (
      <React.Fragment>
        {showSearchSuggestionBar && (
          <Container>
            <StyledText>Don&apos;t see who you&apos;re looking for?</StyledText>
            <div>
              <StyledLink onMouseDown={this.handleOpenOrgContacts}>
                {`Search Workspace ${capitalize(type)} `}
              </StyledLink>
              or{' '}
              <StyledLink onMouseDown={this.handleWindowShow}>
                {`Create New ${type === 'contacts' ? 'Contact' : 'Company'}`}
              </StyledLink>
            </div>
          </Container>
        )}
        {this.state.addingContact && (
          <ContactWindow
            onHide={this.handleWindowClose}
            eventId={eventId}
            defaultActiveType={type}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(SearchSuggestionBar);
