/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  CreateOrUpdateSalesforceMappingInput,
  createSalesforceMappingMutationResponse,
  createSalesforceMappingMutationVariables,
  SalesforceKindType,
} from './__generated__/createSalesforceMappingMutation.graphql';

export type SalesforceMappingInputType = CreateOrUpdateSalesforceMappingInput;

const mutation = graphql`
  mutation createSalesforceMappingMutation($input: CreateOrUpdateSalesforceMappingInput!) {
    createOrUpdateSalesforceMapping(input: $input) {
      salesforceMappingEdge {
        node {
          circaField
          salesforceField
          toSalesforce
          fromSalesforce
          customField {
            id
          }
        }
      }
    }
  }
`;

export default function createSalesforceMapping(
  salesforceAccountId: string,
  kinds: $ReadOnlyArray<SalesforceKindType>,
  input: CreateOrUpdateSalesforceMappingInput,
): Promise<createSalesforceMappingMutationResponse> {
  const variables: createSalesforceMappingMutationVariables = {
    input,
  };
  const optimisticResponse: createSalesforceMappingMutationResponse = {
    createOrUpdateSalesforceMapping: {
      salesforceMappingEdge: {
        node: {
          circaField: input.circaField,
          salesforceField: input.salesforceField,
          toSalesforce: input.toSalesforce,
          fromSalesforce: input.fromSalesforce,
          customField: input.customFieldId
            ? {
                id: input.customFieldId,
              }
            : null,
        },
      },
    },
  };

  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse,
    updater: addRecordToConnection({
      parentId: salesforceAccountId,
      edgeName: 'salesforceMappingEdge',
      connections: [{ field: 'salesforceMappings', args: { kinds } }],
    }),
  });
}
