/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import mappingCustomValueFormatters from 'components/EventRequestForm/lib/mappingCustomValueFormatters';
import mappingValueFormatters from 'components/EventRequestForm/lib/mappingValueFormatters';

import type { SubmissionReviewFormValue_org } from './__generated__/SubmissionReviewFormValue_org.graphql';
import type { SubmissionReviewFormValue_question } from './__generated__/SubmissionReviewFormValue_question.graphql';
import type { SubmissionReviewFormValue_submission } from './__generated__/SubmissionReviewFormValue_submission.graphql';

const Container = styled.div`
  font-weight: 500;
  color: #4a5665;
  white-space: pre-line;
  a {
    color: #3ba9da;
    &:hover {
      text-decoration: underline;
    }
  }
`;

class SubmissionReviewFormValue extends React.PureComponent<{
  org: SubmissionReviewFormValue_org,
  submission: SubmissionReviewFormValue_submission,
  question: SubmissionReviewFormValue_question,
  currency: string,
}> {
  formatAnswer() {
    const {
      org,
      submission,
      question: { selectOptions, selectUsers, budgetCategories, ...restOfQuestion },
      currency,
    } = this.props;
    const { mapping, mappingCustomField } = restOfQuestion;
    const { event } = submission;
    const tz = event.tz;

    const normalizedQuestion = {
      ...restOfQuestion,
      selectOptions: selectOptions.edges.map(edge => edge.node),
      selectUsers: selectUsers.edges.map(edge => edge.node),
      budgetCategories: org.budgetCategories.edges.map(edge => edge.node),
    };
    const value = submission.questionValues
      .map(({ dateValue, filesValue, locationValue, ...restOfValue }) => ({
        ...restOfValue,
        dateValue: dateValue && { ...dateValue },
        locationValue: locationValue && { ...locationValue },
        filesValue: filesValue.map(file => ({ ...file })),
      }))
      .find(questionValue => questionValue.questionId === normalizedQuestion.id);
    const formatter = mappingCustomField
      ? mappingCustomValueFormatters[mappingCustomField.kind]
      : mapping && mappingValueFormatters[mapping];

    return formatter && value ? formatter(normalizedQuestion, value, { tz, currency }) : null;
  }

  render() {
    return <Container>{this.formatAnswer() || '-'}</Container>;
  }
}

export default createFragmentContainer(
  SubmissionReviewFormValue,
  graphql`
    fragment SubmissionReviewFormValue_org on Org {
      budgetCategories {
        edges {
          node {
            id
            name
          }
        }
      }
    }

    fragment SubmissionReviewFormValue_submission on EventRequestSubmission {
      id
      status
      event {
        tz
      }
      questionValues {
        questionId
        budgetCategoryId
        textValue
        textareaValue
        numberValue
        dateValue {
          startDate
          endDate
          startDateAllDay
          endDateAllDay
        }
        booleanValue
        selectIdsValue
        filesValue {
          id
          filename
          filetype
          fileurl
          filesize
        }
        locationValue {
          name
          country
          city
          state
          address1
          address2
          postal
          lat
          lng
        }
      }
    }

    fragment SubmissionReviewFormValue_question on EventRequestQuestion {
      id
      order
      mapping
      mappingCustomField {
        id
        label
        kind
      }
      name
      description
      required
      textMinLength
      textMaxLength
      fileExtensions
      expenseName
      selectShowOtherOption
      maxSelectionSize
      budgetCategory {
        id
        name
      }
      budgetCategories {
        edges {
          node {
            id
            name
          }
        }
      }
      selectOptions {
        edges {
          node {
            id
            name
          }
        }
      }
      selectUsers {
        edges {
          node {
            id
            firstName
            lastName
            email
            ...MaterialAvatar_user
          }
        }
      }
    }
  `,
);
