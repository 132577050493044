/* @flow */
import { getCookie } from 'utils/Cookies';

import downloadFile from '../downloadFile';

export default function downloadICS(url: string, filename: string): Promise<void> {
  return fetch(url, {
    headers: {
      Authorization: getCookie('user'),
    },
  }).then(response => {
    if (response.status >= 400) {
      return response.json().then(json => Promise.reject(new Error(json.errors)));
    }
    return response.text().then(contents => {
      downloadFile(contents, filename, 'text/plain');
    });
  });
}
