/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import omit from 'lodash/omit';

import type { FieldType } from 'utils/customization/types';

import getEventFilterConverters from 'components/AllEvents/lib/getEventFilterConverters';
import type { ParsedAllEventsFilters } from 'components/AllEvents/lib/parseAllEventsFilters';
import renderEventFilter from 'components/AllEvents/lib/renderEventFilter';
import type { FilterGroup } from 'components/material/ColumnFilters/columnFilterTypes';
import AdvancedFilter from 'components/material/Filters/Advanced/AdvancedFilter';
import { type FilterChangeType } from 'components/material/Filters/Advanced/FilterItems';

import { type AllEventsShareFilters_org } from './__generated__/AllEventsShareFilters_org.graphql';

class AllEventsShareFilters extends React.Component<{
  org: AllEventsShareFilters_org,
  shownColumns: $ReadOnlyArray<string>,
  history: RouterHistory,
  filters: ParsedAllEventsFilters,
  filterOptions: $ReadOnlyArray<FieldType>,
  groups: $ReadOnlyArray<FilterGroup>,
  viewMode: 'calendar' | 'list' | 'map',
  mobileBreakpoint?: number,
  isPreviewing: boolean,
  handleEmptyFilterApply?: () => void,
}> {
  filterRenderer = (
    field: FieldType,
    filters: ParsedAllEventsFilters,
    onFilterChange: FilterChangeType,
  ) => {
    const { org, viewMode, isPreviewing } = this.props;
    const fiscalYearStart = org.settings.fiscalYearStart;

    return renderEventFilter(field, filters, onFilterChange, {
      teams: org.teams.edges,
      eventRequestForms: org.eventRequestForms.edges,
      skipDateFilters: viewMode === 'calendar',
      fiscalYearStart,
      showNameFilter: isPreviewing,
    });
  };

  getFilteredOptions = () => {
    const { viewMode, filterOptions, shownColumns, isPreviewing } = this.props;

    const shownFilterOptions = isPreviewing
      ? filterOptions
      : filterOptions.filter(
          option =>
            ['start_date', 'end_date'].includes(option.id) ||
            shownColumns.some(column => [option.id, option.fieldName].includes(column)),
        );

    return viewMode === 'calendar'
      ? shownFilterOptions.filter(option => !['name', 'start_date', 'end_date'].includes(option.id))
      : shownFilterOptions;
  };

  render() {
    const {
      viewMode,
      filters,
      history,
      groups,
      mobileBreakpoint,
      isPreviewing,
      handleEmptyFilterApply,
    } = this.props;

    const shownFilters = omit(filters, viewMode === 'calendar' ? ['date', 'month'] : []);

    const { requesterUserIds, requesterContactIds, ...otherFilters } = shownFilters;
    const requesterFilters = [...(requesterUserIds || []), ...(requesterContactIds || [])];

    const count =
      Object.keys(otherFilters).filter(
        x =>
          ![
            'sort',
            'week',
            ...(viewMode === 'calendar' ? ['date'] : []),
            ...(isPreviewing ? ['search'] : ['queries']),
          ].includes(x) && otherFilters[x] != null,
      ).length + (requesterFilters.length > 0 ? 1 : 0);

    return (
      <AdvancedFilter
        count={count}
        history={history}
        filters={shownFilters}
        filterOptions={this.getFilteredOptions()}
        groups={groups}
        mobileBreakpoint={mobileBreakpoint}
        converterFn={getEventFilterConverters}
        filterRenderer={this.filterRenderer}
        onEmptyFilterApply={handleEmptyFilterApply}
      />
    );
  }
}

export default createFragmentContainer(
  AllEventsShareFilters,
  graphql`
    fragment AllEventsShareFilters_org on Org {
      settings {
        fiscalYearStart
      }
      teams {
        edges {
          node {
            # IF YOU CHANGE ANY OF THESE FIELDS, ALSO CHANGE graph/mutations/team/createTeam.js
            id
            name
          }
        }
      }
      eventRequestForms {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
);
