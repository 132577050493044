/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Search from 'components/Search';
import ContactContainer from './ContactsContainer';

import { type BriefContacts_event } from './__generated__/BriefContacts_event.graphql';
import { type BriefContactsQueryResponse } from './__generated__/BriefContactsQuery.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const StyledSearch = styled(Search)`
  max-width: 100%;
  margin-bottom: 20px;
`;

const query = graphql`
  query BriefContactsQuery($eventSlug: String!, $contactFilters: ContactFilters!) {
    event(slug: $eventSlug) {
      ...ContactsContainer_event
    }
  }
`;

class BriefContacts extends React.PureComponent<
  {
    event: BriefContacts_event,
    selectedContacts: $ReadOnlyArray<string>,
    containerRef: (el: ContactContainer) => void,
    onAddContact: (contactId: string) => void,
    onRemoveContact: (contactId: string) => void,
    onAllContactCheckToggle: (
      isPrevAllCheck: boolean,
      searchedContactIds: $ReadOnlyArray<string>,
    ) => void,
  },
  {
    searchQuery: string,
  },
> {
  state = { searchQuery: '' };

  handleContactSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  handleContactToggle = (contactId: string, isPrevChecked: boolean) => {
    if (isPrevChecked) {
      this.props.onRemoveContact(contactId);
    } else {
      this.props.onAddContact(contactId);
    }
  };

  render() {
    const {
      event: { slug },
      selectedContacts,
      onAllContactCheckToggle,
      containerRef,
    } = this.props;
    return (
      <Container>
        <StyledSearch
          onSearch={this.handleContactSearch}
          search={this.state.searchQuery}
          placeholder="Search Contacts"
        />
        <DefaultQueryRenderer
          query={query}
          variables={{ eventSlug: slug, contactFilters: { query: this.state.searchQuery } }}
          renderSuccess={(props: BriefContactsQueryResponse) => {
            if (props.event) {
              return (
                <ContactContainer
                  componentRef={containerRef}
                  event={props.event}
                  selectedContacts={selectedContacts}
                  searchQuery={this.state.searchQuery}
                  onAllContactCheckToggle={onAllContactCheckToggle}
                  handleContactToggle={this.handleContactToggle}
                />
              );
            }
            return null;
          }}
        />
      </Container>
    );
  }
}

export default createFragmentContainer(
  BriefContacts,
  graphql`
    fragment BriefContacts_event on Event {
      slug
      id
    }
  `,
);
