/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type EventInfoActualBudget_org$ref = any;
type EventInfoAmountOfOpportunities_org$ref = any;
type EventInfoCustomCurrency_org$ref = any;
type EventInfoPlannedBudget_org$ref = any;
type EventInfoSyncStatus_org$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EventInfoBrief_org$ref: FragmentReference;
export type EventInfoBrief_org = {|
  +$fragmentRefs: EventInfoPlannedBudget_org$ref & EventInfoActualBudget_org$ref & EventInfoSyncStatus_org$ref & EventInfoAmountOfOpportunities_org$ref & EventInfoCustomCurrency_org$ref,
  +$refType: EventInfoBrief_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EventInfoBrief_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "includeEventPlannedCost",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventActualCost",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventSyncStatus",
      "type": "Boolean"
    },
    {
      "kind": "RootArgument",
      "name": "includeEventAmountAllOpportunities",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EventInfoCustomCurrency_org",
      "args": null
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventPlannedCost",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoPlannedBudget_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventActualCost",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoActualBudget_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventSyncStatus",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoSyncStatus_org",
          "args": null
        }
      ]
    },
    {
      "kind": "Condition",
      "passingValue": true,
      "condition": "includeEventAmountAllOpportunities",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "EventInfoAmountOfOpportunities_org",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '426f9790d8d33d7b4e78840b0f4e314b';
module.exports = node;
