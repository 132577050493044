/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import CostField from 'components/material/CostField';
import TextField from 'components/material/TextField';

import ErrorMessage from '../form/ErrorMessage';
import HelperText from '../form/HelperText';
import inputBoxStyled from '../form/inputBoxStyled';
import type { QuestionType, QuestionValueInputType, QuestionValueType } from '../lib/types';
import QuestionHeader from './QuestionHeader';

const StyledTextInput = styled(TextField)`
  input {
    ${props => inputBoxStyled(props)};
  }
`;

const StyledCostField = styled(CostField)`
  input {
    ${props => inputBoxStyled(props)};
  }
`;

const Row = styled.div`
  display: flex;
  > {
    flex: 1 1 auto;
    ${props =>
      props.hasTwoChildren &&
      css`
        &:first-child {
          margin-right: 40px;
        }
      `};
  }
  @media (${props => props.theme.mobileOnly}) {
    display: block;
    > :first-child {
      margin-right: 0;
    }
  }
`;

const StyledQuestionHeader = styled(QuestionHeader)`
  > span {
    font-size: 12px;
  }
  label {
    margin-top: -5px;
  }
`;

export default class QuestionExpense extends React.PureComponent<{
  question: QuestionType,
  value: QuestionValueType,
  onChangeValue: QuestionValueInputType => void,
  readOnly: boolean,
  currency: string,
  error: ?string,
}> {
  handleNameChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChangeValue({ textValue: e.currentTarget.value || null });
  };

  handleCostChange = (e: SyntheticEvent<HTMLInputElement>, parsedCost: ?number) => {
    this.props.onChangeValue({ numberValue: parsedCost });
  };

  render() {
    const { question, value, currency, readOnly, error } = this.props;

    return (
      <React.Fragment>
        {question.description && <HelperText>{question.description}</HelperText>}
        <Row hasTwoChildren={!question.expenseName}>
          {!question.expenseName && (
            <StyledQuestionHeader label="Expense Name">
              <StyledTextInput
                value={value.textValue || ''}
                onChange={this.handleNameChange}
                error={error ? ' ' : null}
                disabled={readOnly}
              />
            </StyledQuestionHeader>
          )}

          <StyledQuestionHeader label={question.expenseName || 'Amount'}>
            <StyledCostField
              currency={currency}
              defaultValue={value.numberValue}
              onChange={this.handleCostChange}
              error={error ? ' ' : null}
              disabled={readOnly}
            />
          </StyledQuestionHeader>
        </Row>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </React.Fragment>
    );
  }
}
