/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import type { ContactSalesforceLeadStatus_contact } from './__generated__/ContactSalesforceLeadStatus_contact.graphql';

class ContactSalesforceLeadStatus extends React.PureComponent<{
  contact: ContactSalesforceLeadStatus_contact,
}> {
  render() {
    const salesforceLeadStatus = this.props.contact.salesforceLeadStatus;
    return salesforceLeadStatus ? salesforceLeadStatus.name : null;
  }
}

export default createFragmentContainer(
  ContactSalesforceLeadStatus,
  graphql`
    fragment ContactSalesforceLeadStatus_contact on Contact {
      salesforceLeadStatus {
        name
      }
    }
  `,
);
