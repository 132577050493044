/* @flow */
import getEnvironment from 'utils/getEnvironment';

const appDomainMap = {
  production: 'app.circa.co',
  staging: 'app.circa.dev',
  test: undefined,
  development: undefined,
  lvh: 'localhost.lvh.me', // Mocking custom subdomain support on localhost
};

export default function appDomain(): string {
  const envString = getEnvironment();

  if (envString === 'development') {
    if (window.location.hostname.includes('localhost.lvh.me')) {
      return appDomainMap.lvh;
    }
    // Ngrok
    if (window.location.hostname !== 'localhost') {
      return window.location.hostname;
    }
  }
  // Review app
  if (envString === 'staging' && window.location.hostname.includes('herokuapp.com')) {
    return window.location.hostname;
  }
  return appDomainMap[envString] || '';
}
