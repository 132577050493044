/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';

import formatDateTime from 'utils/date/formatDateTime';

import BriefViewSharedContext from '../../BriefViewSharedContext';
import { ItemName } from '../../components/NameFields';
import DueDate from './DueDate';
import InfoContainer from './InfoContainer';
import TaskItemAssignee from './TaskItemAssignee';
import TaskStatus from './TaskStatus';

import { type SubtaskItem_subtask } from './__generated__/SubtaskItem_subtask.graphql';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px 10px 15px 0;
  border-bottom: 1px solid #d3d5d7;
  &:last-child {
    border-bottom-color: transparent;
    padding-bottom: 8px;
  }
  @media (max-width: 480px) {
    margin: 0 -16px;
    padding-left: 16px;
  }
`;

const Content = styled.div`
  margin-right: 10px;
  flex: 1 auto;
`;

const StyledItemName = styled(ItemName)`
  font-size: 15px;
`;

const Assignees = styled.div`
  flex: 0 auto;
  max-width: 240px;
  margin-right: 200px;
  padding-top: 10px;
  ${props =>
    props.isShared &&
    css`
      @media (max-width: 780px) {
        display: flex;
        flex-wrap: wrap;
        flex: 1 auto;
        max-width: 100%;
        margin-top: 10px;
        margin-right: 0;
      }
    `};
`;

const renderName = (eventSlug: string, name: ?string, slug: ?string) => {
  const component = name && <StyledItemName>{name}</StyledItemName>;
  if (!slug) {
    return component;
  }
  const pathToTask = `/events/${eventSlug}/tasks/${slug}`;

  return (
    <a href={pathToTask} rel="noopener noreferrer" target="_blank">
      {component}
    </a>
  );
};

const SubtaskItem = (props: {
  subtask: SubtaskItem_subtask,
  timezone: string,
  eventSlug: string,
  slug: ?string,
}) => {
  const {
    eventSlug,
    slug,
    timezone,
    subtask: { name, status, dueDate, dueDateAllDay, assignees },
  } = props;
  const taskAssignees = assignees && assignees.edges.map(({ node }) => node);
  const date = dueDate ? formatDateTime(moment(dueDate).tz(timezone), dueDateAllDay) : null;

  return (
    <Container>
      <Content>
        {renderName(eventSlug, name, slug)}
        <InfoContainer>
          {date && <DueDate date={date} />}
          <TaskStatus completed={status === 'COMPLETED'} />
        </InfoContainer>
      </Content>
      {taskAssignees && taskAssignees.length > 0 && (
        <BriefViewSharedContext.Consumer>
          {({ sharedView }) => (
            <Assignees isShared={sharedView}>
              {taskAssignees.map(ta => (
                <TaskItemAssignee key={ta.id} user={ta} />
              ))}
            </Assignees>
          )}
        </BriefViewSharedContext.Consumer>
      )}
    </Container>
  );
};
export default createFragmentContainer(
  SubtaskItem,
  graphql`
    fragment SubtaskItem_subtask on Deliverable {
      name @include(if: $includeTaskTitle)
      status @include(if: $includeTaskStatus)
      dueDate @include(if: $includeTaskDueDate)
      dueDateAllDay @include(if: $includeTaskDueDate)
      assignees @include(if: $includeTaskAssignees) {
        edges {
          node {
            id
            ...TaskItemAssignee_user
          }
        }
      }
    }
  `,
);
