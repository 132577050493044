/**
 * @flow
 * @relayHash db5e114e22456c12e10517dd04c7873a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Payment_payment$ref = any;
export type PaymentStatus = "APPROVED" | "PAID" | "PENDING" | "UNPAID";
export type UpdatePaymentInput = {
  clientMutationId?: ?string,
  paymentId: string,
  actualDate?: ?string,
  dueDate?: ?string,
  amount?: ?number,
  paymentMethod?: ?string,
  paymentType?: ?string,
  poNumber?: ?string,
  reference?: ?string,
  status?: ?PaymentStatus,
  note?: ?string,
};
export type updatePaymentMutationVariables = {|
  input: UpdatePaymentInput
|};
export type updatePaymentMutationResponse = {|
  +updatePayment: ?{|
    +expense: ?{|
      +id: string,
      +actualAmount: number,
      +paidAmount: number,
      +event: {|
        +totalActualAmount: number,
        +totalPaidAmount: number,
      |},
    |},
    +payment: ?{|
      +id: string,
      +$fragmentRefs: Payment_payment$ref,
    |},
  |}
|};
export type updatePaymentMutation = {|
  variables: updatePaymentMutationVariables,
  response: updatePaymentMutationResponse,
|};
*/


/*
mutation updatePaymentMutation(
  $input: UpdatePaymentInput!
) {
  updatePayment(input: $input) {
    expense {
      id
      actualAmount
      paidAmount
      event {
        totalActualAmount
        totalPaidAmount
        id
      }
    }
    payment {
      id
      ...Payment_payment
    }
  }
}

fragment Payment_payment on Payment {
  id
  actualDate
  dueDate
  amount
  paymentMethod
  paymentType
  poNumber
  reference
  status
  note
  viewerCanDelete
  attachments {
    edges {
      node {
        id
        fileurl
        filetype
        filename
        ...AttachmentPreview_attachments
      }
    }
  }
  expense {
    actualAmount
    id
  }
}

fragment AttachmentPreview_attachments on Attachment {
  id
  filename
  fileurl
  filetype
  viewerCanDelete
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdatePaymentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdatePaymentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actualAmount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "paidAmount",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalActualAmount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalPaidAmount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanDelete",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updatePaymentMutation",
  "id": null,
  "text": "mutation updatePaymentMutation(\n  $input: UpdatePaymentInput!\n) {\n  updatePayment(input: $input) {\n    expense {\n      id\n      actualAmount\n      paidAmount\n      event {\n        totalActualAmount\n        totalPaidAmount\n        id\n      }\n    }\n    payment {\n      id\n      ...Payment_payment\n    }\n  }\n}\n\nfragment Payment_payment on Payment {\n  id\n  actualDate\n  dueDate\n  amount\n  paymentMethod\n  paymentType\n  poNumber\n  reference\n  status\n  note\n  viewerCanDelete\n  attachments {\n    edges {\n      node {\n        id\n        fileurl\n        filetype\n        filename\n        ...AttachmentPreview_attachments\n      }\n    }\n  }\n  expense {\n    actualAmount\n    id\n  }\n}\n\nfragment AttachmentPreview_attachments on Attachment {\n  id\n  filename\n  fileurl\n  filetype\n  viewerCanDelete\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updatePaymentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatePayment",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdatePaymentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "expense",
            "storageKey": null,
            "args": null,
            "concreteType": "Expense",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v5,
                  v6
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "payment",
            "storageKey": null,
            "args": null,
            "concreteType": "Payment",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "FragmentSpread",
                "name": "Payment_payment",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updatePaymentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updatePayment",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdatePaymentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "expense",
            "storageKey": null,
            "args": null,
            "concreteType": "Expense",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "event",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  v5,
                  v6,
                  v2
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "payment",
            "storageKey": null,
            "args": null,
            "concreteType": "Payment",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "actualDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dueDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "amount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "paymentMethod",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "paymentType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "poNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "reference",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "note",
                "args": null,
                "storageKey": null
              },
              v7,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "attachments",
                "storageKey": null,
                "args": null,
                "concreteType": "AttachmentRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AttachmentRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Attachment",
                        "plural": false,
                        "selections": [
                          v2,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fileurl",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "filetype",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "filename",
                            "args": null,
                            "storageKey": null
                          },
                          v7
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "expense",
                "storageKey": null,
                "args": null,
                "concreteType": "Expense",
                "plural": false,
                "selections": [
                  v3,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '86711c14da748a9468aaef8bb6ab695b';
module.exports = node;
