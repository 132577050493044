/* @flow */
import type { ExtendedContactDefaultFields } from './suggestedFields';

const contactDefaultFields: ExtendedContactDefaultFields = {
  firstName: '',
  lastName: '',
  email: '',
  title: null,
  phone1: null,
  phone2: null,
  website: null,
  twitter: null,
  linkedin: null,
  description: null,
  country: null,
  state: null,
  city: null,
  zip: null,
  street: null,
  hotLead: false,
  emailOptIn: false,
  contactTypeId: null,
  owner: null,
  contactTypeOther: null,
};

const companyDefaultFields = {
  name: '',
  phone: null,
  website: null,
  twitter: null,
  linkedin: null,
  description: null,
  country: null,
  state: null,
  city: null,
  zip: null,
  street: null,
};

const defaultCustomFieldValues = {
  customTextFields: [],
  customTextareaFields: [],
  customLinkFields: [],
  customDateFields: [],
  customNumberFields: [],
  customCurrencyFields: [],
  customBooleanFields: [],
  customUserSelectFields: [],
  customUserMultiselectFields: [],
  customSelectFields: [],
  customMultiselectFields: [],
};

export { companyDefaultFields, contactDefaultFields, defaultCustomFieldValues };
