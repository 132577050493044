/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import sanitizeHtml from 'utils/string/sanitizeHtml';

import ThankYou from 'images/registration/thankYou.svg';
import { StyledContent } from 'components/TinyRichText';

const Container = styled.div`
  width: 100%;
  max-width: 912px;
  margin: 0 auto;
  padding: 144px 80px;
  border-radius: 5px;
  box-shadow: 0 11px 6px -10px rgba(0, 0, 0, 0.1);
  border: solid 1px #dadada;
  background-color: #ffffff;
  text-align: initial;
`;

const ThankYouWrapper = styled.div`
  text-align: center;
`;

const StyledThankYou = styled(ThankYou)`
  width: 136px;
  height: 84px;
  margin-bottom: 30px;
`;

const Success = styled.div`
  font-size: 18px;
  line-height: 1.39;
  text-align: center;
  color: rgb(62, 72, 89);
  @media (${props => props.theme.mobileOnly}) {
    margin: 0 -40px 30px -40px;
  }
`;

type Props = {
  submitMessage: string,
};

const RegistrationFormPostSubmissionView = ({ submitMessage }: Props) => {
  return (
    <Container>
      <ThankYouWrapper>
        <StyledThankYou />
      </ThankYouWrapper>
      <Success>
        <StyledContent
          style={{ marginRight: 20, minHeight: 60 }}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(submitMessage) }}
        />
      </Success>
    </Container>
  );
};

export default RegistrationFormPostSubmissionView;
