/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import Desktop from 'images/registration/desktop.svg';
import Mobile from 'images/registration/mobile.svg';
import Tablet from 'images/registration/tablet.svg';
import Frame from 'components/Frame';

import RegistrationFormSubmissionContent from '../RegistrationFormSubmission/RegistrationFormSubmissionContent';

import { type RegistrationPreview_org } from './__generated__/RegistrationPreview_org.graphql';
import { type RegistrationPreview_registrationForm } from './__generated__/RegistrationPreview_registrationForm.graphql';

const Header = styled.div`
  padding: 22px 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  background: #373951;
  color: #fff;
`;

const Devices = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
`;

const DeviceLabel = styled.div`
  margin-top: 10px;
  font-family: 'Roboto-Slab', 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: rgba(56, 62, 86, 0.7);
`;

const Device = styled.div`
  position: relative;
  width: 100px;
  margin: 0 5px;
  padding: 25px 0 10px;
  text-align: center;

  &:hover {
    ${DeviceLabel} {
      color: #373951;
    }

    svg {
      opacity: 1;
    }
  }

  ${props =>
    props.active
      ? css`
          cursor: default;

          ${DeviceLabel} {
            color: #373951;
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 5px;
            border-radius: 3px;
            background: #3ba9da;
          }
        `
      : css`
          cursor: pointer;
          svg {
            opacity: 0.7;
          }
        `}
`;

const IframeContainer = styled.div`
  align-self: center;
  margin: 50px 0;
  border: 30px solid #eef0ff;
  border-radius: 11px;
  ${props =>
    props.noBorder &&
    css`
      border: none;
    `}
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 1px solid #bfc4e8;
`;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const breakPoints = [
  { label: 'Desktop', icon: <Desktop />, width: '100%', height: 768 },
  { label: 'Tablet', icon: <Tablet />, width: 768, height: 1024 },
  { label: 'Mobile', icon: <Mobile />, width: 375, height: 667 },
];

class RegistrationPreview extends React.Component<
  {
    registrationForm: RegistrationPreview_registrationForm,
    org: RegistrationPreview_org,
  },
  { breakpoint: number },
> {
  state = {
    breakpoint: 0,
  };

  iframeRef = React.createRef();

  componentDidMount() {
    setTimeout(() => this.forceUpdate(), 100);
  }

  render() {
    const { registrationForm, org } = this.props;
    const { breakpoint } = this.state;

    return (
      <Root>
        <Header>Preview Mode</Header>

        <Devices>
          {breakPoints.map((device, index) => (
            <Device
              key={device.label}
              active={index === breakpoint}
              onClick={() => this.setState({ breakpoint: index })}
            >
              {device.icon} <DeviceLabel>{device.label}</DeviceLabel>
            </Device>
          ))}
        </Devices>

        <IframeContainer
          style={{ width: breakPoints[breakpoint].width, height: breakPoints[breakpoint].height }}
          noBorder={breakPoints[breakpoint].label === 'Desktop'}
        >
          <Iframe ref={this.iframeRef}>
            {this.iframeRef.current && (
              <Frame window={this.iframeRef.current.contentWindow}>
                <RegistrationFormSubmissionContent
                  registrationForm={registrationForm}
                  org={org}
                  viewMode
                  preview
                />
              </Frame>
            )}
          </Iframe>
        </IframeContainer>
      </Root>
    );
  }
}

export default createFragmentContainer(RegistrationPreview, {
  registrationForm: graphql`
    fragment RegistrationPreview_registrationForm on RegistrationForm {
      ...RegistrationFormSubmissionContent_registrationForm
    }
  `,
  org: graphql`
    fragment RegistrationPreview_org on Org {
      ...RegistrationFormSubmissionContent_org
    }
  `,
});
