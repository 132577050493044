/* @flow */
import React from 'react';
import { graphql } from 'react-relay';
import styled from 'styled-components';

import syncCampaigns from 'graph/mutations/salesforce/syncCampaigns';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Overlay from 'components/material/Overlay';

import ConnectCampaignPanelSearch from './ConnectCampaignPanelSearch';

import type { ConnectCampaignPanelQueryResponse } from './__generated__/ConnectCampaignPanelQuery.graphql';

const query = graphql`
  query ConnectCampaignPanelQuery($eventId: ID!) {
    event: node(id: $eventId) {
      ...ConnectCampaignPanelSearch_event
    }
  }
`;

const StyledOverlay = styled(Overlay)`
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
`;

export default class ConnectCampaignPanel extends React.PureComponent<{
  onHide: () => void,
  eventId: string,
  rightEdge?: boolean,
  container: ?HTMLElement,
  target: ?HTMLElement,
  show: boolean,
}> {
  componentDidMount() {
    syncCampaigns();
  }

  render() {
    return (
      <StyledOverlay
        container={this.props.container}
        target={this.props.target}
        show={this.props.show}
        onHide={this.props.onHide}
        forceRightEdge={this.props.rightEdge}
      >
        <DefaultQueryRenderer
          query={query}
          variables={{ eventId: this.props.eventId }}
          renderSuccess={(response: ConnectCampaignPanelQueryResponse) =>
            response.event && (
              <ConnectCampaignPanelSearch event={response.event} onClose={this.props.onHide} />
            )
          }
        />
      </StyledOverlay>
    );
  }
}
