/* @flow */
import React from 'react';

import FilterItemHeader from './../FilterItemHeader';
import RadioFilterBody, { type Option } from './RadioFilterBody';

export default class RadioFilterItem<Name, Value> extends React.Component<{
  options: $ReadOnlyArray<Option<Value>>,
  activeValue: ?Value,
  label: string,
  name: Name,
  onChange: (Name, ?Value) => void,
  expanded?: boolean,
  maxOptions?: number,
}> {
  handleFilterClear = () => {
    this.props.onChange(this.props.name, null);
  };

  handleFilterChange: Value => void = value => {
    this.props.onChange(this.props.name, value);
  };

  render() {
    return (
      <FilterItemHeader
        label={this.props.label}
        count={this.props.activeValue != null ? 1 : 0}
        onClear={this.handleFilterClear}
        expanded={this.props.expanded}
      >
        <RadioFilterBody
          maxOptions={this.props.maxOptions}
          options={this.props.options}
          onChange={this.handleFilterChange}
          activeValue={this.props.activeValue}
        />
      </FilterItemHeader>
    );
  }
}
