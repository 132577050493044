/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { type EventFormatType, EVENT_FORMAT_TYPES } from 'views/Main/Event/window/EventForm';

import type { EventFormat_event } from './__generated__/EventFormat_event.graphql';

const Container = styled.div`
  display: flex;
`;

const LocationTypeText = styled.div`
  flex: 1 1 0%;
`;

type Props = {
  event: EventFormat_event,
};

const getEventFormat = (event: EventFormat_event): ?EventFormatType => {
  if (event.virtualLocation || event.zoomLocation || event.g2wLocation) {
    if (event.primaryLocation) {
      return 'hybrid';
    }
    return 'virtual';
  }
  if (event.primaryLocation) return 'physical';
  return null;
};

const EventFormat = ({ event }: Props) => {
  const eventFormat = getEventFormat(event);

  if (!eventFormat) {
    return null;
  }

  return (
    <Container>
      <LocationTypeText>{EVENT_FORMAT_TYPES[eventFormat]}</LocationTypeText>
    </Container>
  );
};

export default createFragmentContainer(
  EventFormat,
  graphql`
    fragment EventFormat_event on Event {
      virtualLocation
      zoomLocation {
        id
      }
      g2wLocation {
        id
      }
      primaryLocation {
        id
      }
    }
  `,
);
