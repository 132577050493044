/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';
import type { TasksFromWindow } from './createTasks';

const mutation = graphql`
  mutation removeTasksTagMutation($input: RemoveDeliverablesTagInput!) {
    removeDeliverablesTag(input: $input) {
      deliverables {
        id
        tags {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export default function removeTasksTag(
  taskIds: Array<string>,
  tagId: string,
  fromWindow: TasksFromWindow,
) {
  return commitModernMutation({
    mutation,
    variables: {
      input: {
        deliverableIds: taskIds,
        tagId,
        fromWindow,
      },
    },
  });
}
