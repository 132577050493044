/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type CustomFieldKindType = "BOOLEAN" | "CURRENCY" | "DATE" | "DEFAULT" | "LINK" | "MULTISELECT" | "NUMBER" | "SELECT" | "TEXT" | "TEXTAREA" | "USER_MULTISELECT" | "USER_SELECT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomSection_org$ref: FragmentReference;
export type CustomSection_org = {|
  +id: string,
  +settings: {|
    +id: string,
    +currency: string,
  |},
  +customFieldSections: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +order: number,
      |}
    |}>
  |},
  +customFields: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +kind: CustomFieldKindType,
        +order: number,
        +label: string,
        +required: boolean,
        +fieldName: ?string,
        +section: ?{|
          +id: string,
          +order: number,
        |},
        +options: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +name: string,
            |}
          |}>
        |},
        +restrictChangingValue: boolean,
        +mappedToSalesforce: boolean,
        +viewerCanUpdate: boolean,
      |}
    |}>
  |},
  +$refType: CustomSection_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  "EVENT"
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "order",
  "args": null,
  "storageKey": null
},
v3 = [
  v0,
  v2
];
return {
  "kind": "Fragment",
  "name": "CustomSection_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "sectionId",
      "type": "ID"
    }
  ],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "OrgSettings",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currency",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFieldSections",
      "storageKey": "customFieldSections(sectionCustomizableType:[\"EVENT\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "sectionCustomizableType",
          "value": v1,
          "type": "[SectionCustomizableType!]!"
        }
      ],
      "concreteType": "CustomFieldSectionTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldSectionTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldSectionType",
              "plural": false,
              "selections": v3
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customFields",
      "storageKey": null,
      "args": [
        {
          "kind": "Literal",
          "name": "customizableType",
          "value": v1,
          "type": "[CustomizableType!]!"
        },
        {
          "kind": "Variable",
          "name": "sectionId",
          "variableName": "sectionId",
          "type": "ID"
        }
      ],
      "concreteType": "CustomFieldTypeRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomFieldTypeRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CustomFieldType",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "kind",
                  "args": null,
                  "storageKey": null
                },
                v2,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "label",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "required",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fieldName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "section",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldSectionType",
                  "plural": false,
                  "selections": v3
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "options",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CustomFieldOptionTypeRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CustomFieldOptionTypeRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CustomFieldOptionType",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "name",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": "restrictChangingValue",
                  "name": "mappedToSalesforce",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "pull",
                      "value": true,
                      "type": "Boolean"
                    }
                  ],
                  "storageKey": "mappedToSalesforce(pull:true)"
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "mappedToSalesforce",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "viewerCanUpdate",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '551379106e14b140dd2eed16f61020aa';
module.exports = node;
