/* @flow */
import * as React from 'react';
import SelectField from 'components/material/SelectField';
import styled from 'styled-components';

import Button from 'components/material/Button';
import showModernMutationError from 'graph/utils/showModernMutationError';
import TransferEventPreviewWarning from './TransferEventPreviewWarning';

import transferEvent from './mutations/transferEvent';

import { type TransferTeamSection_me } from '../../__generated__/TransferTeamSection_me.graphql';
import { type TransferTeamSection_event } from '../../__generated__/TransferTeamSection_event.graphql';

const Container = styled.div`
  padding: 20px 20px 0 20px;
  flex: 1 1 auto;
`;

const Footer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
`;

const SubmitButton = styled(Button)``;

class TransferEventForm extends React.Component<
  {
    teams: $PropertyType<TransferTeamSection_me, 'teams'>,
    event: TransferTeamSection_event,
  },
  {
    selectedTeamId: ?string,
    loading: boolean,
  },
> {
  constructor() {
    super();
    this.state = {
      selectedTeamId: null,
      loading: false,
    };
  }

  handleSelectTeam = (teamId: ?string) => {
    const selectedTeamId = !teamId ? null : teamId.toString();
    this.setState({ selectedTeamId });
  };

  handleTransferEvent = () => {
    if (this.state.selectedTeamId) {
      const selectedTeamId = this.state.selectedTeamId;
      this.setState({ loading: true });
      transferEvent(this.props.event.id, selectedTeamId)
        .then(() => {
          window.location.reload();
        })
        .catch(err => {
          this.setState({ loading: false });
          showModernMutationError(err);
        });
    }
  };

  render() {
    const { teams, event } = this.props;
    const options = teams.edges
      .filter(({ node }) => node.viewerCanCreateEvents && node.id !== event.team.id)
      .map(({ node }) => ({
        label: node.name,
        value: node.id,
      }));

    return (
      <Container>
        <SelectField
          options={options}
          onChange={this.handleSelectTeam}
          value={this.state.selectedTeamId}
          placeholder="Select New Team"
        />
        {this.state.selectedTeamId != null && <TransferEventPreviewWarning />}
        <Footer>
          <SubmitButton
            primary
            disabled={!this.state.selectedTeamId}
            onClick={this.handleTransferEvent}
            loading={this.state.loading}
            label={`Yes, Transfer Event "${event.name}"`}
          />
        </Footer>
      </Container>
    );
  }
}

export default TransferEventForm;
