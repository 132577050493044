/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 13px;
  font-weight: 500;
  height: 38px;
  color: ${props => props.theme.headerRowColor};
  background: ${props =>
    props.light ? props.theme.secondaryRowColor : props.theme.primaryRowColor};
`;

const Sortable = styled.div`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: ${props => props.theme.headerRowHoverColor};
  }
  i {
    font-size: 12px;
    color: ${props => props.theme.secondaryActionColor};
  }
`;

type Column = {
  key?: string,
  value?: string,
  label: string,
  headerLabel?: string,
  component: React.ElementType,
  width?: number,
  grow?: number,
};

export type Sort = {
  key: string,
  asc: boolean,
};

export default class SortableHeader extends React.PureComponent<{
  cols: Array<Column>,
  sort?: Sort,
  onChange?: Sort => void,
  light?: boolean,
  className?: string,
}> {
  handleClick = (key: string) => {
    if (!this.props.onChange) {
      return;
    }
    if (this.props.sort && this.props.sort.key === key) {
      this.props.onChange({ key, asc: !this.props.sort.asc });
    } else {
      this.props.onChange({ key, asc: true });
    }
  };

  renderIcon(key: string) {
    if (this.props.sort && this.props.sort.key === key) {
      if (this.props.sort.asc) return <i className="fa fa-fw fa-caret-down" />;
      return <i className="fa fa-fw fa-caret-up" />;
    }
    return <i className="fa fa-fw fa-sort" />;
  }

  render() {
    return (
      <Header light={this.props.light} className={this.props.className}>
        {this.props.cols.map(col => (
          <col.component
            key={col.key || col.value || col.label}
            width={col.width}
            grow={col.grow}
            header
          >
            {col.key ? (
              <Sortable onClick={() => this.handleClick(String(col.key))}>
                {col.headerLabel || col.label} {this.renderIcon(col.key)}
              </Sortable>
            ) : (
              col.headerLabel || col.label
            )}
          </col.component>
        ))}
      </Header>
    );
  }
}
