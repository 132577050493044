/* @flow */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { Location, RouterHistory } from 'react-router';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import { Content } from 'components/page/Content';

import EventRequestFormsHeader from './EventRequestFormsHeader';
import EventRequestFormsTable from './EventRequestFormsTable';
import parseEventRequestFormsFilters from './lib/parseEventRequestFormsFilters';

import type { EventRequestFormsQueryResponse } from './__generated__/EventRequestFormsQuery.graphql';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  height: 100%;
`;

const Table = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  border: 1px solid ${props => props.theme.primaryRowColor};
`;

const TableScroll = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
`;

const query = graphql`
  query EventRequestFormsQuery(
    $count: Int!
    $cursor: String
    $sort: EventRequestFormSort
    $direction: Direction!
  ) {
    org {
      ...EventRequestFormsTable_org
    }
    totalCountOrg: org {
      ...EventRequestFormsTable_totalCountOrg
    }
    me {
      tz
    }
  }
`;

export default class EventRequestForms extends React.Component<{
  location: Location,
  history: RouterHistory,
  title: string,
}> {
  scrollContainer: ?HTMLElement;

  render() {
    const filters = parseEventRequestFormsFilters(this.props.location.search);

    const variables = {
      count: 25,
      sort: filters.sort.key,
      direction: filters.sort.asc ? 'ASC' : 'DESC',
    };

    const currentUrl = `${this.props.location.pathname}${this.props.location.search}`;

    return (
      <DocumentTitle title={this.props.title}>
        <Content>
          <Page>
            <EventRequestFormsHeader history={this.props.history} currentUrl={currentUrl} />

            <Table>
              <TableScroll
                ref={el => {
                  this.scrollContainer = el;
                }}
              >
                <DefaultQueryRenderer
                  query={query}
                  variables={variables}
                  renderSuccess={(response: EventRequestFormsQueryResponse) => (
                    <EventRequestFormsTable
                      tz={response.me.tz}
                      org={response.org}
                      totalCountOrg={response.totalCountOrg}
                      filters={filters}
                      scrollContainer={this.scrollContainer}
                    />
                  )}
                />
              </TableScroll>
            </Table>
          </Page>
        </Content>
      </DocumentTitle>
    );
  }
}
