/* @flow */
import React from 'react';

import FilterItemHeader from './../FilterItemHeader';

import TextFilterBody from './TextFilterBody';

export default class TextFilterItem extends React.Component<{
  activeValues: ?$ReadOnlyArray<string>,
  label: string,
  name: string,
  onChange: (string, ?$ReadOnlyArray<string>) => void,
  expanded?: boolean,
}> {
  handleFilterClear = () => {
    this.props.onChange(this.props.name, []);
  };

  render() {
    return (
      <FilterItemHeader
        label={this.props.label}
        count={this.props.activeValues ? this.props.activeValues.length : 0}
        onClear={this.handleFilterClear}
        expanded={this.props.expanded}
      >
        <TextFilterBody
          name={this.props.name}
          onChange={this.props.onChange}
          activeValues={this.props.activeValues}
        />
      </FilterItemHeader>
    );
  }
}
