/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { type Location } from 'react-router-dom';
import styled from 'styled-components';

import fullNameOfUser from 'utils/fullNameOfUser';

import MaterialAvatar from 'components/material/MaterialAvatar';
import { TableLink } from 'components/material/table';

import type { TeamMemberFullName_member } from './__generated__/TeamMemberFullName_member.graphql';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(MaterialAvatar)`
  margin-right: 12px;
`;

const Badge = styled.div`
  margin-left: 10px;
  padding: 5px 10px 6px 10px;
  border-radius: 11px;
  font-size: 12px;
  line-height: 0.67;
  letter-spacing: normal;
  color: #ffffff;
  background-color: #56b5df;
`;

class TeamMemberFullName extends React.PureComponent<{
  member: TeamMemberFullName_member,
  teamSlug: string,
  location: Location,
  onColumnLoad: (str: string, extraSpace: number) => void,
}> {
  componentDidMount() {
    this.props.onColumnLoad(fullNameOfUser(this.props.member.node), 160);
  }

  stopPropagation = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  render() {
    const user = this.props.member.node;
    return (
      <Container>
        <StyledAvatar radius={13} user={user} />
        <TableLink
          to={{
            pathname: `/workspace/members/${user.id}`,
            state: {
              prevPage: true,
              teamSlug: this.props.teamSlug,
              search: this.props.location.search,
            },
          }}
          onClick={this.stopPropagation}
        >
          {fullNameOfUser(user)}
        </TableLink>
        {user.admin && <Badge>Admin</Badge>}
      </Container>
    );
  }
}

export default createFragmentContainer(
  TeamMemberFullName,
  graphql`
    fragment TeamMemberFullName_member on TeamMemberEdge {
      node {
        id
        firstName
        lastName
        email
        admin
        ...MaterialAvatar_user
      }
    }
  `,
);
