/* @flow */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import matchSorter from 'match-sorter';

import timezones from 'config/timezones';

import stringifyTimeZone from 'utils/date/stringifyTimeZone';

import TextField from 'components/material/TextField';
import SelectField from 'components/material/SelectField';
import DateTimePicker, { type DateConfig } from 'components/date/DateTimePicker';
import TimePicker from 'components/date/TimePicker';
import Button from 'components/material/Button';

import { type ZoomLocationKindType, ZOOM_LOCATION_KINDS } from '.';
import zoomTimezones from './lib/zoomTimezones';

const Form = styled.form``;

const Row = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const DateWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const InputWrapper = styled.div`
  width: 94px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  &:first-child {
    margin-left: auto;
  }

  button {
    width: 135px;
    margin-left: 10px;
    font-size: 13px;
    font-weight: 500;
  }
`;

const zoomDefaultTimezone = (defaultTimezone: string) => {
  const zoomTimezone = zoomTimezones.find(tz => tz.name === defaultTimezone);

  if (zoomTimezone) return defaultTimezone;

  const timezone = timezones.find(tz => tz.name === defaultTimezone);
  const zoomTimezonesByOffset = zoomTimezones.filter(
    tz => tz.offset === (timezone ? timezone.offset : 0),
  );

  const filteredTimezones = matchSorter(
    zoomTimezonesByOffset,
    (timezone ? timezone.name : defaultTimezone)
      .split('/')
      .slice(-1)[0]
      .split('_')
      .join(' '),
    { threshold: matchSorter.rankings.NO_MATCH, keys: ['label'] },
  );

  return filteredTimezones.length > 0 ? filteredTimezones[0].name : 'UTC';
};

export type ZoomLocationInputType = {|
  topic: string,
  startTime: string,
  duration: number,
  timezone: string,
|};

export default class ZoomLocationForm extends React.PureComponent<
  {
    kind: ZoomLocationKindType,
    defaultTopic: string,
    defaultTimezone: string,
    defaultDate: ?string,
    onCancel: () => void,
    onCreate: ZoomLocationInputType => Promise<any>,
  },
  {
    ...ZoomLocationInputType,
    topicError: boolean,
    dateError: boolean,
    loading: boolean,
  },
> {
  state = {
    topic: this.props.defaultTopic,
    startTime:
      !this.props.defaultDate || moment(this.props.defaultDate).isBefore()
        ? moment()
            .tz(this.props.defaultTimezone)
            .startOf('day')
            .add(1, 'day')
            .format()
        : this.props.defaultDate,
    duration: 60,
    timezone: zoomDefaultTimezone(this.props.defaultTimezone),
    topicError: false,
    dateError: false,
    loading: false,
  };

  handleChangeTopic = (event: SyntheticEvent<HTMLInputElement>) => {
    const topic = event.currentTarget.value;

    this.setState(state => {
      const changes = { topic };

      return { ...changes, ...this.handleValidate({ ...state, ...changes }) };
    });
  };

  handleChangeDate = ({ date }: DateConfig) => {
    this.setState(state => {
      if (!date) return null;

      const changes = { startTime: date };

      return { ...changes, ...this.handleValidate({ ...state, ...changes }) };
    });
  };

  handleChangeTime = (time: ?string) => {
    this.setState(state => {
      if (!time) return null;

      const changes = { startTime: time };

      return { ...changes, ...this.handleValidate({ ...state, ...changes }) };
    });
  };

  handleChangeHour = (hour: ?number) => {
    this.setState(state => {
      if (hour == null) return null;

      const changes = { duration: hour * 60 + (state.duration % 60) };

      return { ...changes, ...this.handleValidate({ ...state, ...changes }) };
    });
  };

  handleChangeMinute = (minute: ?number) => {
    this.setState(state => {
      if (minute == null) return null;

      const changes = { duration: Math.floor(state.duration / 60) * 60 + minute };

      return { ...changes, ...this.handleValidate({ ...state, ...changes }) };
    });
  };

  handleChangeTimezone = (timezone: ?string) => {
    this.setState(state => {
      if (!timezone) return null;

      const changes = {
        timezone,
        startTime: moment
          .tz(
            moment(state.startTime)
              .tz(state.timezone)
              .toArray(),
            timezone,
          )
          .format(),
      };

      return { ...changes, ...this.handleValidate({ ...state, ...changes }) };
    });
  };

  handleValidate = (state: $PropertyType<ZoomLocationForm, 'state'>) => ({
    topicError: !state.topic.trim(),
    dateError: moment(state.startTime)
      .add(state.duration, 'minute')
      .isBefore(),
  });

  handleCreate = (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    const { topic, startTime, duration, timezone } = this.state;
    const errors = this.handleValidate(this.state);

    this.setState(errors);

    if (Object.values(errors).some(error => error)) return;

    this.setState({ loading: true });

    this.props.onCreate({ topic, startTime, duration, timezone }).then(() => {
      this.setState({ loading: false });
    });
  };

  render() {
    const { kind, onCancel } = this.props;
    const { topic, startTime, duration, timezone, topicError, dateError, loading } = this.state;

    return (
      <Form>
        <Row>
          <TextField
            label={`${ZOOM_LOCATION_KINDS[kind]} Title`}
            value={topic}
            autoFocus
            onChange={this.handleChangeTopic}
            error={topicError ? 'Required' : null}
          />
        </Row>

        <Row>
          <DateWrapper>
            <DateTimePicker
              label="When"
              date={startTime}
              hideTime
              dateOnly
              onChange={this.handleChangeDate}
              tz={timezone}
              clearable={false}
              error={dateError ? 'Must be in future' : null}
            />
          </DateWrapper>

          <InputWrapper>
            <TimePicker
              date={startTime}
              time={startTime}
              onChange={this.handleChangeTime}
              tz={timezone}
              clearable={false}
            />
          </InputWrapper>
        </Row>

        <Row>
          <InputWrapper>
            <SelectField
              label="Duration"
              value={Math.floor(duration / 60)}
              options={Array.from({ length: 25 }).map((_, index) => ({
                value: index,
                label: `${index} hour${index === 1 ? '' : 's'}`,
              }))}
              onChange={this.handleChangeHour}
              searchable
              overlayMaxHeight={135}
            />
          </InputWrapper>

          <InputWrapper>
            <SelectField
              label=" "
              value={duration % 60}
              options={Array.from({ length: 4 }).map((_, index) => ({
                value: index * 15,
                label: `${index * 15} minutes`,
              }))}
              onChange={this.handleChangeMinute}
              searchable
              overlayMaxHeight={135}
            />
          </InputWrapper>
        </Row>

        <Row>
          <SelectField
            label="Time Zone"
            value={timezone}
            options={zoomTimezones.map(tz => ({
              value: tz.name,
              label: stringifyTimeZone(tz),
            }))}
            onChange={this.handleChangeTimezone}
            searchable
          />
        </Row>

        <Row>
          <ButtonWrapper>
            <Button label="Cancel" minimal onClick={onCancel} />
          </ButtonWrapper>

          <ButtonWrapper>
            <Button
              type="submit"
              label={`Create ${ZOOM_LOCATION_KINDS[kind]}`}
              primary
              onClick={this.handleCreate}
              loading={loading}
            />
          </ButtonWrapper>
        </Row>
      </Form>
    );
  }
}
