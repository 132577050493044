/* @flow */
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment-timezone';

import formatDateTimeRange from 'utils/date/formatDateTimeRange';

import SearchResultContainer from './components/SearchResultContainer';

import type { EventSearchResult_event } from './__generated__/EventSearchResult_event.graphql';

const EventName = styled.div`
  color: #3e4859;
`;

const EventDetails = styled.div`
  font-size: 11px;
  color: rgba(62, 72, 89, 0.76);

  > span {
    margin: 0 5px;
  }
`;

type Props = { event: EventSearchResult_event };

const EventSearchResult = ({
  event: { slug, name, startDate, startDateAllDay, endDate, endDateAllDay, tz, location },
}: Props) => {
  const dateString =
    startDate &&
    formatDateTimeRange({
      startDate: startDate ? moment(startDate).tz(tz) : null,
      endDate: endDate ? moment(endDate).tz(tz) : null,
      startDateAllDay,
      endDateAllDay,
      tz,
    });
  const completeDetailsAvailable = dateString && location;
  return (
    <SearchResultContainer path={`/events/${slug}`}>
      <EventName>{name}</EventName>
      <EventDetails>
        {dateString}
        {completeDetailsAvailable && <span>|</span>}
        {location}
      </EventDetails>
    </SearchResultContainer>
  );
};

export default createFragmentContainer(
  EventSearchResult,
  graphql`
    fragment EventSearchResult_event on EventSearchResult {
      id
      name
      slug
      startDate
      startDateAllDay
      endDate
      endDateAllDay
      tz
      location
    }
  `,
);
