/**
 * @flow
 * @relayHash 81f0a086efe165eac203963603fa6b2c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Payment_payment$ref = any;
export type PaymentStatus = "APPROVED" | "PAID" | "PENDING" | "UNPAID";
export type CreatePaymentInput = {
  clientMutationId?: ?string,
  expenseId: string,
  actualDate?: ?string,
  dueDate?: ?string,
  amount: number,
  paymentMethod?: ?string,
  paymentType: string,
  poNumber?: ?string,
  reference?: ?string,
  status: PaymentStatus,
  note?: ?string,
};
export type createPaymentMutationVariables = {|
  input: CreatePaymentInput
|};
export type createPaymentMutationResponse = {|
  +createPayment: ?{|
    +expense: ?{|
      +id: string,
      +actualAmount: number,
      +paidAmount: number,
      +viewerCanUpdateAmount: boolean,
      +payments: {|
        +edges: $ReadOnlyArray<{|
          +node: {|
            +viewerCanDelete: boolean
          |}
        |}>
      |},
      +event: {|
        +id: string,
        +totalActualAmount: number,
        +totalPaidAmount: number,
      |},
    |},
    +paymentEdge: {|
      +__typename: string,
      +node: {|
        +id: string,
        +$fragmentRefs: Payment_payment$ref,
      |},
    |},
  |}
|};
export type createPaymentMutation = {|
  variables: createPaymentMutationVariables,
  response: createPaymentMutationResponse,
|};
*/


/*
mutation createPaymentMutation(
  $input: CreatePaymentInput!
) {
  createPayment(input: $input) {
    expense {
      id
      actualAmount
      paidAmount
      viewerCanUpdateAmount
      payments {
        edges {
          node {
            viewerCanDelete
            id
          }
        }
      }
      event {
        id
        totalActualAmount
        totalPaidAmount
      }
    }
    paymentEdge {
      __typename
      node {
        id
        ...Payment_payment
      }
    }
  }
}

fragment Payment_payment on Payment {
  id
  actualDate
  dueDate
  amount
  paymentMethod
  paymentType
  poNumber
  reference
  status
  note
  viewerCanDelete
  attachments {
    edges {
      node {
        id
        fileurl
        filetype
        filename
        ...AttachmentPreview_attachments
      }
    }
  }
  expense {
    actualAmount
    id
  }
}

fragment AttachmentPreview_attachments on Attachment {
  id
  filename
  fileurl
  filetype
  viewerCanDelete
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePaymentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreatePaymentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actualAmount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "paidAmount",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanUpdateAmount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewerCanDelete",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "event",
  "storageKey": null,
  "args": null,
  "concreteType": "Event",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalActualAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalPaidAmount",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "createPaymentMutation",
  "id": null,
  "text": "mutation createPaymentMutation(\n  $input: CreatePaymentInput!\n) {\n  createPayment(input: $input) {\n    expense {\n      id\n      actualAmount\n      paidAmount\n      viewerCanUpdateAmount\n      payments {\n        edges {\n          node {\n            viewerCanDelete\n            id\n          }\n        }\n      }\n      event {\n        id\n        totalActualAmount\n        totalPaidAmount\n      }\n    }\n    paymentEdge {\n      __typename\n      node {\n        id\n        ...Payment_payment\n      }\n    }\n  }\n}\n\nfragment Payment_payment on Payment {\n  id\n  actualDate\n  dueDate\n  amount\n  paymentMethod\n  paymentType\n  poNumber\n  reference\n  status\n  note\n  viewerCanDelete\n  attachments {\n    edges {\n      node {\n        id\n        fileurl\n        filetype\n        filename\n        ...AttachmentPreview_attachments\n      }\n    }\n  }\n  expense {\n    actualAmount\n    id\n  }\n}\n\nfragment AttachmentPreview_attachments on Attachment {\n  id\n  filename\n  fileurl\n  filetype\n  viewerCanDelete\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "createPaymentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createPayment",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreatePaymentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "expense",
            "storageKey": null,
            "args": null,
            "concreteType": "Expense",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "payments",
                "storageKey": null,
                "args": null,
                "concreteType": "PaymentRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PaymentRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Payment",
                        "plural": false,
                        "selections": [
                          v6
                        ]
                      }
                    ]
                  }
                ]
              },
              v7
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "paymentEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "PaymentRequiredEdge",
            "plural": false,
            "selections": [
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Payment",
                "plural": false,
                "selections": [
                  v2,
                  {
                    "kind": "FragmentSpread",
                    "name": "Payment_payment",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "createPaymentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createPayment",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreatePaymentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "expense",
            "storageKey": null,
            "args": null,
            "concreteType": "Expense",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "payments",
                "storageKey": null,
                "args": null,
                "concreteType": "PaymentRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PaymentRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Payment",
                        "plural": false,
                        "selections": [
                          v6,
                          v2
                        ]
                      }
                    ]
                  }
                ]
              },
              v7
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "paymentEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "PaymentRequiredEdge",
            "plural": false,
            "selections": [
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Payment",
                "plural": false,
                "selections": [
                  v2,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "actualDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dueDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "amount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "paymentMethod",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "paymentType",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "poNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "reference",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "note",
                    "args": null,
                    "storageKey": null
                  },
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "attachments",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AttachmentRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AttachmentRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Attachment",
                            "plural": false,
                            "selections": [
                              v2,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "fileurl",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "filetype",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "filename",
                                "args": null,
                                "storageKey": null
                              },
                              v6
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "expense",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Expense",
                    "plural": false,
                    "selections": [
                      v3,
                      v2
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f253c370fe4e3848c3fae7a05b19727';
module.exports = node;
