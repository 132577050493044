/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import generateFontFromProps from 'views/Main/Event/Registration/RegistrationCreate/RegistrationPageContent/generateFontFromProps';

import { Error } from './FieldComponents';
import { withSharedFormTheme } from './lib/SharedFormThemeContext';

const Box = styled.div`
  width: ${props => (props.compact ? 15 : 17)}px;
  height: ${props => (props.compact ? 15 : 17)}px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid;
  cursor: pointer;
  text-align: center;
  line-height: ${props => (props.compact ? 9 : 12)}px;
  user-select: none;
  i {
    opacity: 0;
    font-size: ${props => (props.compact ? 8 : 10)}px;
    transition: 0.2s;
    transform: scale(0.5);
  }
  & ~ span,
  & ~ div {
    margin-left: ${props => (props.compact ? 8 : 15)}px;
  }
`;

const Label = withSharedFormTheme(styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  color: ${props => props.sharedFormTheme.textColor || 'rgba(74, 86, 101, 0.87)'};
  font: ${props =>
    props.sharedFormTheme
      ? generateFontFromProps({
          fontFamily: props.sharedFormTheme.textFont,
          fontStyle: props.sharedFormTheme.textStyle,
        })
      : ''};
  font-size: 15px;
  ${props =>
    props.required &&
    css`
      &:after {
        position: absolute;
        left: -12px;
        top: 0;
        content: '*';
        margin-right: 4px;
        color: ${props.hasError ? '#f25d60' : '#519cf9'};
      }
    `};
  input {
    display: none;
    & ~ ${Box} {
      color: ${props => (props.error ? props.theme.negativeActionColor : '#cdd1d4')};
    }
    &:checked {
      & ~ ${Box} {
        color: ${props => (props.error ? props.theme.negativeActionColor : '#29cc71')};
        i {
          opacity: 1;
          transform: scale(1);
        }
      }
      & ~ span,
      & ~ div {
        color: #3e4859;
      }
    }
  }
`);

export default class CheckBox extends React.PureComponent<{
  label?: string,
  checked: boolean,
  disabled?: boolean,
  labelRenderer?: ?(disabled?: boolean) => React.Node,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onClick?: (e: SyntheticMouseEvent<HTMLDivElement>) => void,
  compact?: boolean,
  error?: ?string | ?boolean,
  required?: boolean,
  className?: string,
}> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (this.props.onChange && !this.props.disabled) {
      this.props.onChange(e);
    }
  };

  render() {
    const {
      checked,
      label,
      labelRenderer,
      disabled,
      compact,
      onClick,
      className,
      required,
    } = this.props;
    return (
      <div>
        <Label className={className} error={this.props.error} required={required}>
          <input
            type="checkbox"
            checked={checked}
            onChange={this.handleChange}
            disabled={disabled}
          />
          <Box compact={compact} onClick={onClick}>
            <i className="fa fa-fw fa-check" />
          </Box>
          {labelRenderer ? (
            <div onClick={onClick}>{labelRenderer(disabled)}</div>
          ) : (
            label && <span onClick={onClick}>{label}</span>
          )}
        </Label>
        {this.props.error && <Error>{this.props.error}</Error>}
      </div>
    );
  }
}
