/**
 * @flow
 * @relayHash c3517ed0574d4884868fe3624f98f28c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaskComments_task$ref = any;
export type TaskCommentsRootQueryVariables = {|
  taskSlug: string,
  count: number,
  cursor?: ?string,
|};
export type TaskCommentsRootQueryResponse = {|
  +task: ?{|
    +$fragmentRefs: TaskComments_task$ref
  |}
|};
export type TaskCommentsRootQuery = {|
  variables: TaskCommentsRootQueryVariables,
  response: TaskCommentsRootQueryResponse,
|};
*/


/*
query TaskCommentsRootQuery(
  $taskSlug: String!
  $count: Int!
  $cursor: String
) {
  task: deliverable(slug: $taskSlug) {
    ...TaskComments_task
    id
  }
}

fragment TaskComments_task on Deliverable {
  id
  slug
  comments(first: $count, after: $cursor) {
    edges {
      node {
        id
        ...CommentItem_comment
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      startCursor
      endCursor
    }
  }
  event {
    tz
    team {
      users {
        edges {
          node {
            id
            ...CommentInput_users
            ...CommentItem_users
          }
        }
      }
      id
    }
    staffers {
      edges {
        node {
          id
          user {
            id
            ...CommentInput_users
            ...CommentItem_users
          }
        }
      }
    }
    id
  }
}

fragment CommentItem_comment on Comment {
  id
  content
  postedAt
  systemGenerated
  user {
    id
    slug
    firstName
    lastName
    avatar
  }
}

fragment CommentInput_users on User {
  id
  slug
  firstName
  lastName
  avatar
}

fragment CommentItem_users on User {
  id
  slug
  firstName
  lastName
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "taskSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "taskSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  }
],
v5 = [
  v2,
  v3,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "firstName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "avatar",
    "args": null,
    "storageKey": null
  }
],
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": v5
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TaskCommentsRootQuery",
  "id": null,
  "text": "query TaskCommentsRootQuery(\n  $taskSlug: String!\n  $count: Int!\n  $cursor: String\n) {\n  task: deliverable(slug: $taskSlug) {\n    ...TaskComments_task\n    id\n  }\n}\n\nfragment TaskComments_task on Deliverable {\n  id\n  slug\n  comments(first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        ...CommentItem_comment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n  event {\n    tz\n    team {\n      users {\n        edges {\n          node {\n            id\n            ...CommentInput_users\n            ...CommentItem_users\n          }\n        }\n      }\n      id\n    }\n    staffers {\n      edges {\n        node {\n          id\n          user {\n            id\n            ...CommentInput_users\n            ...CommentItem_users\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment CommentItem_comment on Comment {\n  id\n  content\n  postedAt\n  systemGenerated\n  user {\n    id\n    slug\n    firstName\n    lastName\n    avatar\n  }\n}\n\nfragment CommentInput_users on User {\n  id\n  slug\n  firstName\n  lastName\n  avatar\n}\n\nfragment CommentItem_users on User {\n  id\n  slug\n  firstName\n  lastName\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TaskCommentsRootQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "task",
        "name": "deliverable",
        "storageKey": null,
        "args": v1,
        "concreteType": "Deliverable",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TaskComments_task",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TaskCommentsRootQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "task",
        "name": "deliverable",
        "storageKey": null,
        "args": v1,
        "concreteType": "Deliverable",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comments",
            "storageKey": null,
            "args": v4,
            "concreteType": "CommentRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CommentRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Comment",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "content",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "postedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "systemGenerated",
                        "args": null,
                        "storageKey": null
                      },
                      v6,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "comments",
            "args": v4,
            "handle": "connection",
            "key": "TaskComments_comments",
            "filters": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "event",
            "storageKey": null,
            "args": null,
            "concreteType": "Event",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "tz",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "users",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserRequiredConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserRequiredEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": v5
                          }
                        ]
                      }
                    ]
                  },
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "staffers",
                "storageKey": null,
                "args": null,
                "concreteType": "StaffMembershipRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StaffMembershipRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StaffMembership",
                        "plural": false,
                        "selections": [
                          v2,
                          v6
                        ]
                      }
                    ]
                  }
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fbcf4bbfa6833e02c55db37652f9f30a';
module.exports = node;
