/* @flow */
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';

import type { CompanySalesforceId_company } from './__generated__/CompanySalesforceId_company.graphql';

class CompanySalesforceId extends React.PureComponent<{
  company: CompanySalesforceId_company,
  salesforceHost: ?string,
}> {
  handleMouseDown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
  };

  getSalesforceId = () => {
    const company = this.props.company;
    return company && company.salesforceId ? company.salesforceId : null;
  };

  render() {
    const salesforceId = this.getSalesforceId();
    if (salesforceId == null || this.props.salesforceHost == null) {
      return null;
    }
    return (
      <a
        onMouseDown={this.handleMouseDown}
        href={`https://${this.props.salesforceHost}/${salesforceId}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {salesforceId}
      </a>
    );
  }
}

export default createFragmentContainer(
  CompanySalesforceId,
  graphql`
    fragment CompanySalesforceId_company on Company {
      salesforceId
    }
  `,
);
