/* @flow */
import React from 'react';
import styled from 'styled-components';
import flatMap from 'lodash/flatMap';

import formatCost from 'utils/number/formatCost';

import Tooltip from 'components/material/Tooltip';

const AXIS_COUNT = 5;
const CHART_HEIGHT = 125;
const COLUMN_MIN_WIDTH = 50;
const COLUMN_VERTICAL_LABEL_HEIGHT = 150;
const MULTIPLES = flatMap(([...Array(9).keys()]: any), index =>
  [1, 1.2, 1.25, 1.4, 1.5, 1.6, 1.75, 1.8].map(num => num + index),
);
const COLORS = ['#a4cf70', '#d3d3d3', '#1b258c', '#5face9'];
const LABELS = ['Budgeted', 'Planned', 'Actual', 'Paid'];
const MAX_COUNT_BEFORE_LABEL_COLLAPSE = 7;

const Scroll = styled.div`
  display: flex;
  overflow-y: auto;
  padding: 20px 0 0;
`;

const Axis = styled.div`
  position: relative; /* for IE */
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  flex-shrink: 0;
  display: flex;
  padding-right: 20px;
  border-right: 1px solid #dfe1e5;
  background: #fff;
`;

const AxisNumbers = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: ${CHART_HEIGHT}px;
  padding-right: 10px;
  background: #fff;
`;

const AxisNumber = styled.div`
  text-align: right;
  font-size: 13px;
  color: #3e4859;

  &:first-child {
    margin-bottom: -${100 / (AXIS_COUNT - 1) / 2}%;
  }

  &:last-child {
    margin-top: -${100 / (AXIS_COUNT - 1) / 2}%;
  }
`;

const AxisLines = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${CHART_HEIGHT}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AxisLine = styled.div`
  height: 1px;
  background: #dfe1e5;
`;

const Group = styled.div`
  position: relative;
  flex: 1 0;
`;

const Columns = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1 0;
  min-width: 0;
`;

const BorderLine = styled.div`
  position: sticky;
  right: 0;
  flex-shrink: 0;
  width: 1px;
  background: #dfe1e5;
`;

const Bars = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: ${CHART_HEIGHT}px;
`;

const Bar = styled(Tooltip)`
  width: 15%;
`;

const ColumnLabel = styled(Tooltip)`
  position: relative;
  display: flex;
  width: 100%;
  height: ${COLUMN_VERTICAL_LABEL_HEIGHT}px;
  padding: 5px 12px;
  overflow: hidden;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  font-size: 12px;
  line-height: 1.1;
  color: rgba(62, 72, 89, 0.85);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 12px;
    margin-right: -12px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.5), #fff);
  }
`;

const ColumnLabelCentered = styled.div`
  margin: 0 auto;
`;

const GroupLabel = styled(Tooltip)`
  margin-top: 15px;
  padding: 0 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 500;
  color: #3e4859;
`;

const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 15px;
`;

const LegendLabel = styled.div`
  margin-right: 45px;
  font-size: 13px;
  line-height: 1;
  color: #3e4859;

  &:last-child {
    margin-right: 30px;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 10px;
    vertical-align: middle;
    background: ${props => props.color};
  }

  @media (max-width: 600px) {
    margin-right: 10px;
    &:before {
      margin-right: 5px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${CHART_HEIGHT}px;
  font-size: 14px;
  color: #677386;
`;

const Root = styled.div`
  ${Bar} {
    &:first-child {
      margin-left: ${props => (props.allBarsShown ? 15 : 20)}%;
    }

    &:last-child {
      margin-right: ${props => (props.allBarsShown ? 15 : 20)}%;
    }
  }
`;

/* eslint-disable react/no-array-index-key */
export default class Chart extends React.PureComponent<{
  data: $ReadOnlyArray<{
    label: string,
    budgetedAmount?: ?number,
    plannedAmount?: ?number,
    actualAmount?: number,
    paidAmount?: number,
    data?: $ReadOnlyArray<{
      label: string,
      budgetedAmount?: ?number,
      plannedAmount?: ?number,
      actualAmount: number,
      paidAmount: number,
    }>,
  }>,
  currency: string,
}> {
  calculateMaxAmount = (): number =>
    this.props.data.reduce(
      (max, item) =>
        Math.max(
          max,
          ...(item.data
            ? [
                item.data.reduce(
                  (nestedMax, nestedItem) =>
                    Math.max(
                      nestedMax,
                      nestedItem.budgetedAmount || 0,
                      nestedItem.plannedAmount || 0,
                      nestedItem.actualAmount,
                      nestedItem.paidAmount,
                    ),
                  0,
                ),
              ]
            : [
                item.budgetedAmount || 0,
                item.plannedAmount || 0,
                item.actualAmount || 0,
                item.paidAmount || 0,
              ]),
        ),
      0,
    );

  calculateRoundedAxisNumbers = (maxAmount: number): $ReadOnlyArray<number> => {
    const magnitude =
      10 ** Math.floor(Math.log(maxAmount / (AXIS_COUNT - 1)) / Math.LN10 + 0.000000001);

    const base = Math.round(
      (MULTIPLES.find(multiple => multiple * magnitude >= maxAmount / (AXIS_COUNT - 1)) || 0) *
        magnitude,
    );

    return [...Array(AXIS_COUNT).keys()].map(index => index * base);
  };

  calculateColumnsTotalCount = () =>
    this.props.data.reduce((sum, item) => sum + (item.data ? item.data.length : 1), 0);

  hasAnyValue = (value: 'budgetedAmount' | 'plannedAmount' | 'actualAmount' | 'paidAmount') =>
    this.props.data.some(item =>
      item.data ? item.data.some(nestedItem => nestedItem[value] != null) : item[value] != null,
    );

  shouldShowTooltip = (element: HTMLElement) => element.scrollWidth > element.offsetWidth;

  renderAxisLines = () => (
    <AxisLines>
      {[...Array(AXIS_COUNT).keys()].map(index => (
        <AxisLine key={index} />
      ))}
    </AxisLines>
  );

  renderBar = (index: number, amount: ?number, axisMaxNumber: number) => {
    if (amount == null) return null;

    const percentage = (amount / axisMaxNumber) * 100;

    return (
      <Bar
        style={{
          backgroundColor: COLORS[index],
          height: `${Math.max(percentage, amount > 0 ? 1 : 0)}%`,
        }}
        label={`${LABELS[index]}: ${formatCost(amount, this.props.currency)}`}
      />
    );
  };

  render() {
    const { data, currency } = this.props;
    const maxAmount = this.calculateMaxAmount();
    const axisNumbers = this.calculateRoundedAxisNumbers(maxAmount);
    const axisMaxNumber = axisNumbers.slice(-1)[0];
    const colsTotalCount = this.calculateColumnsTotalCount();

    if (maxAmount === 0) {
      return <Placeholder>Nothing to show</Placeholder>;
    }

    const showBudgeted = this.hasAnyValue('budgetedAmount');
    const showPlanned = this.hasAnyValue('plannedAmount');
    const showActual = this.hasAnyValue('actualAmount');
    const showPaid = this.hasAnyValue('paidAmount');
    const allBarsShown = showBudgeted && showPlanned && showActual && showPaid;

    return (
      <Root allBarsShown={allBarsShown}>
        <Legend>
          {showBudgeted && <LegendLabel color={COLORS[0]}>{LABELS[0]}</LegendLabel>}
          {showPlanned && <LegendLabel color={COLORS[1]}>{LABELS[1]}</LegendLabel>}
          {showActual && <LegendLabel color={COLORS[2]}>{LABELS[2]}</LegendLabel>}
          {showPaid && <LegendLabel color={COLORS[3]}>{LABELS[3]}</LegendLabel>}
        </Legend>

        <Scroll>
          <Axis>
            {this.renderAxisLines()}

            <AxisNumbers>
              {axisNumbers.map((num, index) => (
                <AxisNumber key={index}>{formatCost(num, currency)}</AxisNumber>
              ))}
            </AxisNumbers>
          </Axis>

          {data.map((item, groupIndex) => {
            const columns = item.data || [item];

            if (columns.length === 0) return null;

            return (
              <React.Fragment key={groupIndex}>
                <Group
                  style={{
                    minWidth: columns.length * COLUMN_MIN_WIDTH,
                    flexGrow: columns.length / colsTotalCount,
                  }}
                >
                  {this.renderAxisLines()}

                  <Columns>
                    {columns.map((column, index) => (
                      <Column key={index}>
                        <Bars>
                          {this.renderBar(0, column.budgetedAmount, axisMaxNumber)}
                          {this.renderBar(1, column.plannedAmount, axisMaxNumber)}
                          {this.renderBar(2, column.actualAmount, axisMaxNumber)}
                          {this.renderBar(3, column.paidAmount, axisMaxNumber)}
                        </Bars>
                        {(item !== column || colsTotalCount > MAX_COUNT_BEFORE_LABEL_COLLAPSE) && (
                          <ColumnLabel
                            label={column.label}
                            shouldShowTooltip={this.shouldShowTooltip}
                          >
                            <ColumnLabelCentered>{column.label}</ColumnLabelCentered>
                          </ColumnLabel>
                        )}
                      </Column>
                    ))}
                  </Columns>

                  {(item.data || colsTotalCount <= MAX_COUNT_BEFORE_LABEL_COLLAPSE) && (
                    <GroupLabel label={item.label} shouldShowTooltip={this.shouldShowTooltip}>
                      {item.label}
                    </GroupLabel>
                  )}
                </Group>

                <BorderLine />
              </React.Fragment>
            );
          })}
        </Scroll>
      </Root>
    );
  }
}
