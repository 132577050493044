/* @flow */

export type StaffAccessLevel = 'FULL' | 'LIMITED' | 'NONE';

type StaffAccessLevelInfo = {|
  +label: string,
  +value: StaffAccessLevel,
|};

export const staffAccessLevelDetails: $ReadOnlyArray<StaffAccessLevelInfo> = [
  {
    label: 'Full',
    value: 'FULL',
  },
  {
    label: 'Limited',
    value: 'LIMITED',
  },
  {
    label: 'None',
    value: 'NONE',
  },
];

const staffAccessLevels: $ReadOnlyArray<StaffAccessLevel> = staffAccessLevelDetails.map(
  details => details.value,
);

export default staffAccessLevels;
