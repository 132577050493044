/**
 * @flow
 * @relayHash 0d906bca66c4f457ccbac0e2c136d69a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrgContactsPage_org$ref = any;
export type OrgContactsQueryVariables = {||};
export type OrgContactsQueryResponse = {|
  +org: {|
    +id: string,
    +salesforceAccount: ?{|
      +contactsSyncEnabled: boolean
    |},
    +marketoAccount: ?{|
      +id: string
    |},
    +$fragmentRefs: OrgContactsPage_org$ref,
  |},
  +me: {|
    +tz: string,
    +email: string,
  |},
|};
export type OrgContactsQuery = {|
  variables: OrgContactsQueryVariables,
  response: OrgContactsQueryResponse,
|};
*/


/*
query OrgContactsQuery {
  org {
    id
    ...OrgContactsPage_org
    salesforceAccount {
      contactsSyncEnabled
      id
    }
    marketoAccount {
      id
    }
  }
  me {
    tz
    email
    id
  }
}

fragment OrgContactsPage_org on Org {
  ...OrgContactsHeaderBar_org
  id
  customFields(customizableType: [CONTACT]) {
    edges {
      node {
        id
        label
        fieldName
        order
        kind
        required
        customizableType
        restrictChangingValueForLead: mappedToSalesforce(pull: true, kind: LEAD)
        restrictChangingValueForContact: mappedToSalesforce(pull: true, kind: CONTACT)
        options {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}

fragment OrgContactsHeaderBar_org on Org {
  id
  name
  viewerCanCreateContacts
  viewerCanRemoveContacts
  salesforceAccount {
    leadStatuses {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contactsSyncEnabled",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "marketoAccount",
  "storageKey": null,
  "args": null,
  "concreteType": "MarketoAccount",
  "plural": false,
  "selections": [
    v0
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tz",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  v0,
  v5
],
v7 = {
  "kind": "Literal",
  "name": "pull",
  "value": true,
  "type": "Boolean"
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "OrgContactsQuery",
  "id": null,
  "text": "query OrgContactsQuery {\n  org {\n    id\n    ...OrgContactsPage_org\n    salesforceAccount {\n      contactsSyncEnabled\n      id\n    }\n    marketoAccount {\n      id\n    }\n  }\n  me {\n    tz\n    email\n    id\n  }\n}\n\nfragment OrgContactsPage_org on Org {\n  ...OrgContactsHeaderBar_org\n  id\n  customFields(customizableType: [CONTACT]) {\n    edges {\n      node {\n        id\n        label\n        fieldName\n        order\n        kind\n        required\n        customizableType\n        restrictChangingValueForLead: mappedToSalesforce(pull: true, kind: LEAD)\n        restrictChangingValueForContact: mappedToSalesforce(pull: true, kind: CONTACT)\n        options {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment OrgContactsHeaderBar_org on Org {\n  id\n  name\n  viewerCanCreateContacts\n  viewerCanRemoveContacts\n  salesforceAccount {\n    leadStatuses {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OrgContactsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "FragmentSpread",
            "name": "OrgContactsPage_org",
            "args": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              v1
            ]
          },
          v2
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v3,
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrgContactsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          v0,
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanCreateContacts",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanRemoveContacts",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "salesforceAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "SalesforceAccount",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "leadStatuses",
                "storageKey": null,
                "args": null,
                "concreteType": "SalesforceLeadStatusTypeRequiredConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SalesforceLeadStatusTypeRequiredEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SalesforceLeadStatusType",
                        "plural": false,
                        "selections": v6
                      }
                    ]
                  }
                ]
              },
              v0,
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customFields",
            "storageKey": "customFields(customizableType:[\"CONTACT\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "customizableType",
                "value": [
                  "CONTACT"
                ],
                "type": "[CustomizableType!]!"
              }
            ],
            "concreteType": "CustomFieldTypeRequiredConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CustomFieldTypeRequiredEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CustomFieldType",
                    "plural": false,
                    "selections": [
                      v0,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fieldName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "order",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "kind",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "required",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "customizableType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": "restrictChangingValueForLead",
                        "name": "mappedToSalesforce",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "kind",
                            "value": "LEAD",
                            "type": "SalesforceKindType"
                          },
                          v7
                        ],
                        "storageKey": "mappedToSalesforce(kind:\"LEAD\",pull:true)"
                      },
                      {
                        "kind": "ScalarField",
                        "alias": "restrictChangingValueForContact",
                        "name": "mappedToSalesforce",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "kind",
                            "value": "CONTACT",
                            "type": "SalesforceKindType"
                          },
                          v7
                        ],
                        "storageKey": "mappedToSalesforce(kind:\"CONTACT\",pull:true)"
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "options",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CustomFieldOptionTypeRequiredConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CustomFieldOptionTypeRequiredEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CustomFieldOptionType",
                                "plural": false,
                                "selections": v6
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v2
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          v3,
          v4,
          v0
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6fdf7f0faa4ce61b3e2cf9b93a0f70c0';
module.exports = node;
