/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContactFormBuilder_contactForm$ref: FragmentReference;
export type ContactFormBuilder_contactForm = {|
  +id: string,
  +enableCompanyFields: boolean,
  +contactFormFields: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +__typename: string,
        +label: string,
        +fieldName: ?string,
        +required: boolean,
        +order: number,
        +helpText: ?string,
        +customField: ?{|
          +id: string
        |},
        +rules: {|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +customSavedTextFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +fieldName: ?string,
                    +customField: ?{|
                      +id: string
                    |},
                  |}
                |}>
              |},
              +customSavedTextareaFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +fieldName: ?string,
                    +customField: ?{|
                      +id: string
                    |},
                  |}
                |}>
              |},
              +customSavedLinkFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +fieldName: ?string,
                    +customField: ?{|
                      +id: string
                    |},
                  |}
                |}>
              |},
              +customSavedNumberFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +customField: {|
                      +id: string
                    |},
                  |}
                |}>
              |},
              +customSavedCurrencyFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +customField: ?{|
                      +id: string
                    |},
                  |}
                |}>
              |},
              +customSavedDateFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +customField: {|
                      +id: string
                    |},
                  |}
                |}>
              |},
              +customSavedBooleanFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +customField: {|
                      +id: string
                    |},
                  |}
                |}>
              |},
              +customSavedUserMultiselectFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +fieldName: ?string,
                    +customField: ?{|
                      +id: string
                    |},
                  |}
                |}>
              |},
              +customSavedMultiselectFilters: {|
                +edges: $ReadOnlyArray<{|
                  +node: {|
                    +id: string,
                    +customField: {|
                      +id: string
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
        +showOtherOption: boolean,
        +contactFormFieldOptions: ?{|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +customFieldOption: {|
                +id: string
              |}
            |}
          |}>
        |},
        +contactFormFieldUserOptions: ?{|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +user: {|
                +id: string
              |}
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: ContactFormBuilder_contactForm$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fieldName",
  "args": null,
  "storageKey": null
},
v2 = [
  v0
],
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customField",
  "storageKey": null,
  "args": null,
  "concreteType": "CustomFieldType",
  "plural": false,
  "selections": v2
},
v4 = [
  v0,
  v1,
  v3
],
v5 = [
  v0,
  v3
];
return {
  "kind": "Fragment",
  "name": "ContactFormBuilder_contactForm",
  "type": "ContactForm",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "enableCompanyFields",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contactFormFields",
      "storageKey": null,
      "args": null,
      "concreteType": "ContactFormFieldRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ContactFormFieldRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ContactFormField",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "label",
                  "args": null,
                  "storageKey": null
                },
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "required",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "order",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "helpText",
                  "args": null,
                  "storageKey": null
                },
                v3,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "rules",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "FormRuleRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "FormRuleRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "FormRule",
                          "plural": false,
                          "selections": [
                            v0,
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customSavedTextFilters",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedTextFilterTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedTextFilterTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedTextFilterType",
                                      "plural": false,
                                      "selections": v4
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customSavedTextareaFilters",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedTextareaFilterTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedTextareaFilterTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedTextareaFilterType",
                                      "plural": false,
                                      "selections": v4
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customSavedLinkFilters",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedLinkFilterTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedLinkFilterTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedLinkFilterType",
                                      "plural": false,
                                      "selections": v4
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customSavedNumberFilters",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedNumberFilterTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedNumberFilterTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedNumberFilterType",
                                      "plural": false,
                                      "selections": v5
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customSavedCurrencyFilters",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedCurrencyFilterTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedCurrencyFilterTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedCurrencyFilterType",
                                      "plural": false,
                                      "selections": v5
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customSavedDateFilters",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedDateFilterTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedDateFilterTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedDateFilterType",
                                      "plural": false,
                                      "selections": v5
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customSavedBooleanFilters",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedBooleanFilterTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedBooleanFilterTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedBooleanFilterType",
                                      "plural": false,
                                      "selections": v5
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customSavedUserMultiselectFilters",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedUserMultiselectFilterTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedUserMultiselectFilterType",
                                      "plural": false,
                                      "selections": v4
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customSavedMultiselectFilters",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomSavedMultiselectFilterTypeRequiredConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "CustomSavedMultiselectFilterTypeRequiredEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "CustomSavedMultiselectFilterType",
                                      "plural": false,
                                      "selections": v5
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "showOtherOption",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contactFormFieldOptions",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContactFormFieldOptionRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ContactFormFieldOptionRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ContactFormFieldOption",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "customFieldOption",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "CustomFieldOptionType",
                              "plural": false,
                              "selections": v2
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contactFormFieldUserOptions",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContactFormFieldUserOptionRequiredConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ContactFormFieldUserOptionRequiredEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ContactFormFieldUserOption",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "user",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "User",
                              "plural": false,
                              "selections": v2
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ecc81c10b9f2ed6487c9fd6a4480a403';
module.exports = node;
