/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import CircaLogo from 'images/circaLogo.svg';

const Logo = styled.a`
  width: 150px;
  margin-bottom: 40px;
`;

const Window = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  max-width: 605px;
  padding: 48px 85px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  @media (${props => props.theme.mobileOnly}) {
    padding: 15px 20px;
    box-shadow: none;
  }
`;

const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 38px;
  font-weight: 700;
  color: ${props => props.theme.headerRowColor};

  > span > span {
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  }

  @media (${props => props.theme.mobileOnly}) {
    font-size: 24px;
  }
`;

export default class AuthWindow extends React.PureComponent<{
  className?: ?string,
  title?: string | React.Node,
  children: React.Node,
  hideLogo?: boolean,
}> {
  render() {
    return (
      <Window className={this.props.className}>
        {!this.props.hideLogo && (
          <Logo href="http://www.simplecirca.com" target="_blank" rel="noopener noreferrer">
            <CircaLogo />
          </Logo>
        )}
        {this.props.title != null && <Title>{this.props.title}</Title>}
        <div>{this.props.children}</div>
      </Window>
    );
  }
}
