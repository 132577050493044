/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import omit from 'lodash/omit';
import qs from 'qs';

import withQueryParams from 'utils/routing/withQueryParams';

import getRelayEnvironment from 'graph/utils/getRelayEnvironment';

import ErrorView from 'components/ErrorView';
import Loader from 'components/Loader';
import Window from 'components/material/Window';

import TaskWindowContent from './TaskWindowContent';

import { type TaskWindow_org } from './__generated__/TaskWindow_org.graphql';

const query = graphql`
  query TaskWindowQuery($taskSlug: String!) {
    deliverable(slug: $taskSlug) {
      ...TaskWindowContent_task
    }
    me {
      id
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

class TaskWindow extends React.PureComponent<{
  match: Match,
  history: RouterHistory,
  queryParams: { view?: ?string },
  org: TaskWindow_org,
}> {
  navigate = (path: string) => {
    const q = qs.stringify(omit(this.props.queryParams, ['view']));

    this.props.history.push(`${path}${q ? `?${q}` : ''}`);
  };

  handleHide = () => {
    const viewMode = this.props.queryParams.view;
    this.navigate(
      this.props.match.params.event_slug
        ? `/events/${this.props.match.params.event_slug}/tasks${viewMode ? `/${viewMode}` : ''}`
        : '/tasks',
    );
  };

  render() {
    const { org, match } = this.props;
    return (
      <Window size="xlarge" onHide={this.handleHide}>
        <QueryRenderer
          environment={getRelayEnvironment()}
          query={query}
          variables={{ taskSlug: match.params.taskSlug }}
          render={response => {
            if (response.error) {
              return <ErrorView error={response.error} retry={response.retry} />;
            }
            if (response.props) {
              return (
                <TaskWindowContent
                  match={match}
                  task={response.props.deliverable}
                  userId={response.props.me.id}
                  org={org}
                  onWindowHide={this.handleHide}
                />
              );
            }
            return (
              <LoaderContainer>
                <Loader size={30} />
              </LoaderContainer>
            );
          }}
        />
      </Window>
    );
  }
}

export default createFragmentContainer(
  withQueryParams(TaskWindow),
  graphql`
    fragment TaskWindow_org on Org {
      ...TaskWindowContent_org
    }
  `,
);
