/* @flow */

const registrationFormStatuses = {
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  NOT_AVAILABLE: 'N/A',
};

export type RegistrationFormStatusType = $Keys<typeof registrationFormStatuses>;

export default registrationFormStatuses;
