/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type RegistrationPreview_org$ref = any;
type RegistrationPreview_registrationForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RegistrationHeader_org$ref: FragmentReference;
export type RegistrationHeader_org = {|
  +syncedToIbmWm: boolean,
  +registrationForm?: {|
    +id: string,
    +$fragmentRefs: RegistrationPreview_registrationForm$ref,
  |},
  +$fragmentRefs: RegistrationPreview_org$ref,
  +$refType: RegistrationHeader_org$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RegistrationHeader_org",
  "type": "Org",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isEventRegForm",
      "type": "Boolean"
    }
  ],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "syncedToIbmWm",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "Condition",
      "passingValue": false,
      "condition": "isEventRegForm",
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "registrationForm",
          "storageKey": null,
          "args": null,
          "concreteType": "RegistrationForm",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "FragmentSpread",
              "name": "RegistrationPreview_registrationForm",
              "args": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "RegistrationPreview_org",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f7d1cd8a755c9a9e62685293ce21a1ca';
module.exports = node;
