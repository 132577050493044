/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskVendors_task$ref: FragmentReference;
export type TaskVendors_task = {|
  +viewerCanUpdateContact: boolean,
  +slug: string,
  +vendors: {|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +event: {|
    +id: string,
    +slug: string,
  |},
  +$refType: TaskVendors_task$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TaskVendors_task",
  "type": "Deliverable",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "viewerCanUpdateContact",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vendors",
      "storageKey": null,
      "args": null,
      "concreteType": "VendorRequiredConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "VendorRequiredEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Vendor",
              "plural": false,
              "selections": [
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "event",
      "storageKey": null,
      "args": null,
      "concreteType": "Event",
      "plural": false,
      "selections": [
        v1,
        v0
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '64792016755fc5d3a6e1fe62e32f19db';
module.exports = node;
