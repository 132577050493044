/* @flow */
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { graphql } from 'react-relay';
import type { Location, Match } from 'react-router-dom';
import styled, { createGlobalStyle, css } from 'styled-components';
import qs from 'qs';

import fonts from 'config/fonts';
import publicRuleNames from 'config/publicRuleNames';

import { loadFonts } from 'utils/fonts';
import isInIframe from 'utils/isInIframe';
import appOrigin from 'utils/url/appOrigin';

import type { RegistrationFormDefaultValues } from 'components/ContactForm/lib/types';
import DefaultQueryRenderer from 'components/DefaultQueryRenderer';
import Loader from 'components/Loader';

import validateColorParams from '../lib/validateColorParams';
import makeUIPropsInsensitive from '../makeUIPropsInsensitive';
import registrationUIDefaultProps, {
  type RegistrationUIPropsType,
} from '../RegistrationUIDefaultProps';
import NotFound from './NotFound';
import RegistrationFormSubmissionContent, {
  type DefaultFormValuesType,
} from './RegistrationFormSubmissionContent';

import type { RegistrationFormSubmissionResponse } from './__generated__/RegistrationFormSubmissionQuery.graphql';

const GlobalStyle = createGlobalStyle`
  ${props =>
    props.isEmbed &&
    css`
      :root {
        margin: 0;
        background-color: transparent !important;
      }
    `}
`;

const Container = styled.main`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: #fafafa;

  ${props =>
    props.isEmbed
      ? css`
          padding-top: 0;
          background-color: transparent;
        `
      : css`
          @media (${props.theme.mobileOnly}) {
            background-color: #fff;
          }
        `};
`;

const Content = styled.div`
  position: relative;
  height: 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 10px 0;
`;

const query = graphql`
  query RegistrationFormSubmissionQuery(
    $publicRuleName: String!
    $resourceToken: String!
    $orgSlug: String
    $wmSSOToken: String
    $includeRules: Boolean!
  ) {
    publicRule(
      publicRuleName: $publicRuleName
      resourceToken: $resourceToken
      orgSlug: $orgSlug
      wmSSOToken: $wmSSOToken
    )
    registrationForm(resourceToken: $resourceToken) {
      eventName
      ...RegistrationFormSubmissionContent_registrationForm
    }
    org {
      ...RegistrationFormSubmissionContent_org
    }
  }
`;

const getQueryParams = (search: string): { [string]: string } => {
  return qs.parse(search, { ignoreQueryPrefix: true });
};

export default class RegistrationFormSubmission extends React.Component<
  {
    match: Match,
    location: Location,
  },
  {
    email?: string,
    redirectUrl?: string,
    lockEmail: boolean,
    ssoError: boolean,
    formShown: boolean,
    defaultFormValues: ?RegistrationFormDefaultValues,
    defaultFormHiddenValues: ?DefaultFormValuesType,
  },
> {
  state = {
    email: undefined,
    lockEmail: false,
    redirectUrl: undefined,
    ssoError: false,
    formShown: getQueryParams(this.props.location.search).wmSSOToken == null,
    defaultFormValues: null,
    defaultFormHiddenValues: null,
  };

  componentDidMount() {
    const {
      location,
      match: {
        params: { resourceToken, orgName },
      },
    } = this.props;
    const queryParams = getQueryParams(location.search);
    loadFonts(fonts.map(font => font.value));
    if (queryParams.wmSSOToken == null) {
      this.setState({ formShown: true });
      return;
    }

    fetch(`${appOrigin()}/integrations/ibm-wm/identify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        org_slug: orgName,
        resource_token: resourceToken,
        allow_update: queryParams.allowUpdate,
        sso_token: queryParams.wmSSOToken,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status !== 'ok') {
          this.setState({ ssoError: true });
          return;
        }

        if (data.first_attempt === true || queryParams.allowUpdate === 'true') {
          this.setState({
            formShown: true,
            email: data.email,
            lockEmail: data.lock_email,
            redirectUrl: data.redirect_url,
            defaultFormHiddenValues: data.defaultHiddenValues,
            defaultFormValues: data.defaultFormValues,
          });
          return;
        }
        window.location.href = `${data.redirect_url}&isRegistered=true`;
      })
      .catch(() => {
        this.setState({ ssoError: true });
      });
  }

  renderContent = () => {
    const {
      ssoError,
      formShown,
      email,
      lockEmail,
      redirectUrl,
      defaultFormValues,
      defaultFormHiddenValues,
    } = this.state;

    if (ssoError) {
      return <NotFound />;
    }

    const { resourceToken, orgName } = this.props.match.params;
    const parsedQueryString: RegistrationUIPropsType = validateColorParams(
      qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      }),
    );
    const UIProps = makeUIPropsInsensitive({
      ...registrationUIDefaultProps,
      ...parsedQueryString,
    });
    const wmSSOToken = getQueryParams(this.props.location.search).wmSSOToken;
    const privacyPolicyURL = getQueryParams(this.props.location.search).privacyPolicyURL;

    if (formShown) {
      return (
        <DefaultQueryRenderer
          query={query}
          public
          variables={{
            publicRuleName: publicRuleNames.REGISTRATION_FORM,
            resourceToken,
            orgSlug: orgName,
            wmSSOToken,
            includeRules: true,
          }}
          renderSuccess={({ registrationForm, org }: RegistrationFormSubmissionResponse) => {
            return (
              <DocumentTitle title={registrationForm.eventName}>
                <RegistrationFormSubmissionContent
                  registrationForm={registrationForm}
                  org={org}
                  orgSlug={orgName}
                  resourceToken={resourceToken}
                  email={email}
                  lockEmail={lockEmail}
                  redirectUrl={redirectUrl}
                  wmSSOToken={wmSSOToken}
                  privacyPolicyURL={privacyPolicyURL}
                  defaultFormHiddenValues={defaultFormHiddenValues}
                  defaultFormValues={defaultFormValues}
                  location={this.props.location}
                />
              </DocumentTitle>
            );
          }}
          renderError={() => <NotFound />}
          renderLoading={() => {
            return (
              <LoaderContainer>
                <Loader color={UIProps.loaderColor} />
              </LoaderContainer>
            );
          }}
        />
      );
    }

    return (
      <LoaderContainer>
        <Loader color={UIProps.loaderColor} />
      </LoaderContainer>
    );
  };

  render() {
    const isEmbed = isInIframe();

    return (
      <Container isEmbed={isEmbed}>
        <GlobalStyle isEmbed={isEmbed} />
        <Content>{this.renderContent()}</Content>
      </Container>
    );
  }
}
