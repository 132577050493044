/* @flow */
import styled, { css } from 'styled-components';

const TableCell = styled.div`
  position: relative;
  flex: ${props => (props.grow != null ? props.grow : 0.3)} 0;
  padding: 13px ${props => (props.align === 'right' ? 35 : 20)}px 13px 20px;
  border-right: ${props => (props.noBorder ? 0 : 1)}px solid #dfe1e5;
  text-align: ${props => props.align || 'left'};
  white-space: nowrap;
  font-size: 13px;
  line-height: 1.08;
  background: #fff;
  color: #3e4859;

  &:first-child {
    position: sticky;
    left: 0;
    z-index: ${props => props.stickyZIndex};
    border-right: 0;
    background-clip: padding-box;
    border-left: 1px solid #dfe1e5;
  }

  ${props =>
    props.leftBorder &&
    css`
      border-left: 1px solid #dfe1e5;
    `}

  ${props =>
    props.fixedLeft &&
    css`
      position: sticky;
      left: ${props.fixedLeft}px;
      z-index: ${props.stickyZIndex};
      border-right: 0;
      border-left: 1px solid #dfe1e5;
      background-clip: padding-box;
    `}

  ${props =>
    props.header &&
    css`
      font-weight: 500;
    `} 
    
  ${props =>
    props.footer &&
    css`
      font-size: 14px;
      font-weight: 500;
    `};

  ${props =>
    props.type === 'action' &&
    css`
      position: static;
      flex: 0 0;
      padding: 0;
    `};

  ${props =>
    props.grouped &&
    css`
      font-weight: 500;
      background: #f4f5f7;

      &:first-child {
        ${props.color &&
        css`
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: ${props.stickyZIndex + 1};
            width: 5px;
            height: 100%;
            background: ${props.color};
          }
        `};
      }
    `};
`;

export default TableCell;
