/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import personalizationDefaults from 'config/personalizationDefaults';

import EditableContainer from '../form/EditableContainer';
import FormHeader from '../FormHeader';
import EditableFormHeader from './EditableFormHeader';

const Container = styled.div`
  > div {
    padding: 10px 24px;
  }
`;

export default class EditableFormHeaderContainer extends React.PureComponent<
  {
    logo: ?string,
    orgLogo: ?string,
    orgName: string,
    name: string,
    description: string,
    onChangeFormDetails: (
      $Shape<{
        name: string,
        description: string,
        logo: ?string,
      }>,
    ) => void,
    onChangeEditing: (editing: boolean) => void,
    onHasChanges: (hasChanges: boolean) => void,
  },
  {
    name: string,
    description: string,
    logo: ?string,
    nameError: boolean,
  },
> {
  constructor(props: $PropertyType<EditableFormHeaderContainer, 'props'>) {
    super(props);
    this.state = {
      name: props.name || `${this.props.orgName} Event Request`,
      description: props.name
        ? props.description
        : `Please complete this brief form to request a ${this.props.orgName} event.`,
      logo: props.logo,
      nameError: false,
    };
    if (!props.name) {
      props.onChangeFormDetails({
        name: this.state.name,
        description: this.state.description,
        logo: props.logo,
      });
    }
  }

  handleSave = () => {
    this.setState(state => ({ nameError: !state.name.trim() }));

    const nameError = !this.state.name.trim();

    if (!nameError) {
      this.props.onChangeFormDetails({
        name: this.state.name,
        description: this.state.description,
        logo: this.state.logo,
      });
    }

    return !nameError;
  };

  handleChangeName = (name: string) => {
    this.props.onHasChanges(name !== this.props.name);
    this.setState({ name, nameError: !name.trim() });
  };

  handleChangeDescription = (description: string) => {
    this.props.onHasChanges(description !== this.props.description);
    this.setState({ description });
  };

  handleChangeLogo = (logo: ?string) => {
    this.setState({ logo });
    if (logo !== this.props.logo) {
      this.props.onHasChanges(true);
      this.props.onChangeFormDetails({
        logo: this.state.logo,
      });
      this.props.onChangeEditing(!this.state.name.trim());
    }
  };

  render() {
    const logo = this.state.logo || this.props.orgLogo || personalizationDefaults.logoExpanded;
    return (
      <Container>
        <EditableContainer
          defaultPreviewing={!!this.props.name.trim()}
          onSave={this.handleSave}
          onChangeEditing={this.props.onChangeEditing}
          showIteration={false}
          editContent={
            <EditableFormHeader
              nameError={this.state.nameError}
              name={this.state.name}
              description={this.state.description}
              logo={logo}
              orgLogo={this.props.orgLogo}
              onChangeName={this.handleChangeName}
              onChangeDescription={this.handleChangeDescription}
              onChangeLogo={this.handleChangeLogo}
            />
          }
          previewContent={
            <FormHeader name={this.props.name} description={this.props.description} logo={logo} />
          }
        />
      </Container>
    );
  }
}
