/**
 * @flow
 * @relayHash bff8f5caaea964e49eeaaa970cbf1035
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReorderBriefTemplateEventInfoFieldsInput = {
  clientMutationId?: ?string,
  eventInfoFields?: ?$ReadOnlyArray<SortableSectionInput>,
};
export type SortableSectionInput = {
  id?: ?string,
  name?: ?string,
  order: number,
  enabled?: ?boolean,
};
export type reorderBriefTemplateEventInfoFieldsMutationVariables = {|
  input: ReorderBriefTemplateEventInfoFieldsInput
|};
export type reorderBriefTemplateEventInfoFieldsMutationResponse = {|
  +reorderBriefTemplateEventInfoFields: ?{|
    +eventInfoFields: $ReadOnlyArray<{|
      +id: string,
      +order: number,
      +customField: ?{|
        +id: string
      |},
    |}>
  |}
|};
export type reorderBriefTemplateEventInfoFieldsMutation = {|
  variables: reorderBriefTemplateEventInfoFieldsMutationVariables,
  response: reorderBriefTemplateEventInfoFieldsMutationResponse,
|};
*/


/*
mutation reorderBriefTemplateEventInfoFieldsMutation(
  $input: ReorderBriefTemplateEventInfoFieldsInput!
) {
  reorderBriefTemplateEventInfoFields(input: $input) {
    eventInfoFields {
      id
      order
      customField {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ReorderBriefTemplateEventInfoFieldsInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reorderBriefTemplateEventInfoFields",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ReorderBriefTemplateEventInfoFieldsInput!"
      }
    ],
    "concreteType": "ReorderBriefTemplateEventInfoFieldsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventInfoFields",
        "storageKey": null,
        "args": null,
        "concreteType": "BriefTemplateEventInfoField",
        "plural": true,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "order",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customField",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomFieldType",
            "plural": false,
            "selections": [
              v1
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "reorderBriefTemplateEventInfoFieldsMutation",
  "id": null,
  "text": "mutation reorderBriefTemplateEventInfoFieldsMutation(\n  $input: ReorderBriefTemplateEventInfoFieldsInput!\n) {\n  reorderBriefTemplateEventInfoFields(input: $input) {\n    eventInfoFields {\n      id\n      order\n      customField {\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "reorderBriefTemplateEventInfoFieldsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "reorderBriefTemplateEventInfoFieldsMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '74843b1373829f280b72f68e7c9f23c5';
module.exports = node;
