/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import EditableContainer from '../form/EditableContainer';
import FormSection from '../FormSection';
import type { SectionType } from '../lib/types';
import EditableSectionHeaderForm from './EditableSectionHeaderForm';

const StyledEditableContainer = styled(EditableContainer)`
  margin: 10px 12px;
  padding: 10px 40px;
`;

export default class EditableSectionHeader extends React.PureComponent<
  {
    onChangeSection: (section: SectionType) => void,
    onChangeEditing: (editing: boolean) => void,
    onHasChanges: (hasChanges: boolean) => void,
    section: SectionType,
  },
  {
    title: ?string,
    helpText: ?string,
    description: ?string,
  },
> {
  state = {
    title: this.props.section.title,
    helpText: this.props.section.helpText,
    description: this.props.section.description,
  };

  handleSave = () => {
    this.props.onChangeSection({
      ...this.props.section,
      title: this.state.title,
      helpText: this.state.helpText,
      description: this.state.description,
    });
    return true;
  };

  handleChangeTitle = (title: string) => {
    this.props.onHasChanges(title !== this.props.section.title);
    this.setState({ title });
  };

  handleChangeHelpText = (helpText: string) => {
    this.props.onHasChanges(helpText !== this.props.section.helpText);
    this.setState({ helpText });
  };

  handleChangeDescription = (description: string) => {
    this.props.onHasChanges(description !== this.props.section.description);
    this.setState({ description });
  };

  render() {
    const { section, onChangeEditing } = this.props;

    return (
      <StyledEditableContainer
        defaultPreviewing={!section.id.startsWith('section_') || section.order === 1}
        onSave={this.handleSave}
        onChangeEditing={onChangeEditing}
        editContent={
          <EditableSectionHeaderForm
            title={this.state.title}
            helpText={this.state.helpText}
            description={this.state.description}
            onChangeTitle={this.handleChangeTitle}
            onChangeHelpText={this.handleChangeHelpText}
            onChangeDescription={this.handleChangeDescription}
          />
        }
        previewContent={
          <FormSection
            title={this.state.title}
            helpText={this.state.helpText}
            description={this.state.description}
          />
        }
      />
    );
  }
}
