/* @flow */
import styled from 'styled-components';

import { BaseColumn } from 'components/material/table';

const TaskRowFolderColumn = styled(BaseColumn)`
  width: 180px;
  margin-right: 10px;
  color: ${props => (props.header ? 'inherit' : props.theme.rowSecondaryTextColor)};
`;

export default TaskRowFolderColumn;
