/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  disconnectMarketoAccountMutationResponse,
  disconnectMarketoAccountMutationVariables,
} from './__generated__/disconnectMarketoAccountMutation.graphql';

const mutation = graphql`
  mutation disconnectMarketoAccountMutation($input: DisconnectMarketoAccountInput!) {
    disconnectMarketoAccount(input: $input) {
      updatedOrg {
        id
        marketoAccount {
          id
        }
      }
    }
  }
`;

export default function disconnectMarketoAccount(): Promise<disconnectMarketoAccountMutationResponse> {
  const variables: disconnectMarketoAccountMutationVariables = {
    input: {},
  };
  return commitModernMutation({
    mutation,
    variables,
  });
}
