/**
 * @flow
 * @relayHash d3ce1837e40df5cc0ab25a40b3540d76
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StaffAccessLevel = "FULL" | "LIMITED" | "NONE";
export type UserStatus = "CONFIRMED" | "INVITED" | "NOT_INVITED";
export type UpdateStafferAccessLevelInput = {
  clientMutationId?: ?string,
  eventId: string,
  userId: string,
  accessLevel: StaffAccessLevel,
};
export type updateStafferAccessLevelMutationVariables = {|
  input: UpdateStafferAccessLevelInput
|};
export type updateStafferAccessLevelMutationResponse = {|
  +updateStafferAccessLevel: ?{|
    +staffMembership: {|
      +id: string,
      +accessLevel: StaffAccessLevel,
      +viewerCanUpdateAccessLevel: boolean,
      +viewerCanRemove: boolean,
      +user: {|
        +id: string,
        +inviteStatus: UserStatus,
      |},
    |}
  |}
|};
export type updateStafferAccessLevelMutation = {|
  variables: updateStafferAccessLevelMutationVariables,
  response: updateStafferAccessLevelMutationResponse,
|};
*/


/*
mutation updateStafferAccessLevelMutation(
  $input: UpdateStafferAccessLevelInput!
) {
  updateStafferAccessLevel(input: $input) {
    staffMembership {
      id
      accessLevel
      viewerCanUpdateAccessLevel
      viewerCanRemove
      user {
        id
        inviteStatus
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateStafferAccessLevelInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateStafferAccessLevel",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateStafferAccessLevelInput!"
      }
    ],
    "concreteType": "UpdateStafferAccessLevelPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "staffMembership",
        "storageKey": null,
        "args": null,
        "concreteType": "StaffMembership",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "accessLevel",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanUpdateAccessLevel",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "viewerCanRemove",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "inviteStatus",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "updateStafferAccessLevelMutation",
  "id": null,
  "text": "mutation updateStafferAccessLevelMutation(\n  $input: UpdateStafferAccessLevelInput!\n) {\n  updateStafferAccessLevel(input: $input) {\n    staffMembership {\n      id\n      accessLevel\n      viewerCanUpdateAccessLevel\n      viewerCanRemove\n      user {\n        id\n        inviteStatus\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "updateStafferAccessLevelMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "updateStafferAccessLevelMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f2934d30c89df3bd6f81f774d063c9a';
module.exports = node;
