/* @flow */
import React from 'react';
import { type RouterHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import forgotPassword from 'utils/authentication/forgotPassword';
import withQueryParams from 'utils/routing/withQueryParams';
import isValidEmail from 'utils/validators/isValidEmail';

import AuthButton from 'components/Auth/AuthButton';
import ErrorBlock from 'components/Auth/AuthErrorBlock';
import AuthFooter from 'components/Auth/AuthFooter';
import AuthWindow from 'components/Auth/AuthWindow';
import TextField, { Label } from 'components/material/TextField';

const StyledTextField = styled(TextField)`
  margin: 16px 0 24px 0;

  input {
    &:focus {
      border-bottom-color: #5e5bff;

      ~ ${Label} {
        color: #5e5bff;
      }
    }
  }
`;

const StyledButton = styled(AuthButton)`
  margin-top: 40px;
  padding: 10px 14px;
  border-radius: 52px;
  background-color: #5e5bff;

  &:hover {
    background-color: #403ec9;
  }
`;

const BrandedAuthButton = styled(AuthButton)`
  margin-top: 40px;
  padding: 10px 14px;
  border-radius: 52px;
  background-color: #5e5bff;

  &:hover {
    background-color: #403ec9;
  }
`;

const StyledParagraph = styled.p`
  a {
    color: #5554c3;
  }
`;

class PasswordReset extends React.Component<
  {
    queryParams: { error?: ?string },
    history: RouterHistory,
  },
  {
    serverError: ?string,
    emailError: boolean,
    successMessage: ?string,
    loading: boolean,
  },
> {
  state = {
    serverError: this.props.queryParams.error,
    emailError: false,
    loading: false,
    successMessage: null,
  };

  componentDidMount() {
    const error = this.props.queryParams.error;
    if (error) {
      this.props.history.replace('/reset');
    }
  }

  handlePasswordReset = e => {
    if (e) e.preventDefault();

    const email = e.target.elements.email.value;

    if (!isValidEmail(email)) {
      this.setState({ emailError: true });
      return;
    }

    this.setState({ emailError: false, loading: true });

    forgotPassword(email)
      .then((message: string) => {
        this.setState({ successMessage: message });
      })
      .catch(err => {
        this.setState({ serverError: err.message });
      })
      .then(() => {
        this.setState({ loading: false });
      });
  };

  handleErrorDismiss = () => {
    this.setState({ serverError: null });
  };

  handleConfirmationButtonClick = () => {
    this.props.history.push('/');
  };

  render() {
    if (this.state.successMessage) {
      return (
        <AuthWindow title="Reset password">
          <p>{this.state.successMessage}</p>
          <StyledButton onClick={this.handleConfirmationButtonClick} label="OK" primary />
        </AuthWindow>
      );
    }

    return (
      <AuthWindow title="Reset password">
        {this.state.serverError && (
          <ErrorBlock text={this.state.serverError} onDismiss={this.handleErrorDismiss} />
        )}
        <p>
          Provide the email you’re using to sign in to Circa and we’ll send password reset
          instructions there:
        </p>
        <form onSubmit={this.handlePasswordReset} noValidate>
          <StyledTextField
            type="email"
            error={this.state.emailError ? 'Email is not valid' : null}
            label="Your email"
            autoFocus
            name="email"
          />
          <BrandedAuthButton
            type="submit"
            loading={this.state.loading}
            label="Reset Password"
            primary
          />
        </form>
        <AuthFooter>
          <StyledParagraph>
            Remember it after all? <Link to="/">Sign In</Link>
          </StyledParagraph>
        </AuthFooter>
      </AuthWindow>
    );
  }
}

export default withQueryParams(PasswordReset);
