/* @flow */

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled, { css } from 'styled-components';

import MaterialAvatar from 'components/material/MaterialAvatar';

import BriefViewSharedContext from '../BriefViewSharedContext';

import { type BriefUserAvatar_user } from './__generated__/BriefUserAvatar_user.graphql';

type StyledProps = {
  avatar: ?string,
  radius: number,
};

const StyledMaterialAvatar = styled(MaterialAvatar)`
  margin-right: 15px;
  border: ${(props: StyledProps) => (props.avatar != null ? '0' : '2')}px solid #979797;
  background-color: transparent !important;
  line-height: ${(props: StyledProps) => props.radius * 2 - (props.avatar != null ? 0 : 4)}px;
  font-size: ${(props: StyledProps) => (props.radius > 22 ? 18 : 15)}px;
  color: #979797;

  ${props =>
    props.isShared &&
    css`
      @media (max-width: 370px) {
        margin-bottom: 5px;
      }
    `};
`;

const BriefUserAvatar = (props: {
  user: BriefUserAvatar_user,
  radius: number,
  className?: string,
}) => {
  const { user, radius, className } = props;
  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <StyledMaterialAvatar
          user={user}
          radius={radius}
          avatar={user.avatar}
          className={className}
          isShared={sharedView}
        />
      )}
    </BriefViewSharedContext.Consumer>
  );
};

export default createFragmentContainer(
  BriefUserAvatar,
  graphql`
    fragment BriefUserAvatar_user on Assignable {
      avatar
      ...MaterialAvatar_user
    }
  `,
);
