/* @flow */
import type { RecordSourceSelectorProxy, SelectorData, RecordProxy } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import getMutationRoot from './lib/getMutationRoot';
import getConnection, { type Connection } from './lib/getConnection';

type Config = {
  deletedIdField: string,
  connections: $ReadOnlyArray<Connection>,
  parentId?: string,
  parentIdField?: string,
};

function removeRecordFromConnection({
  deletedIdField,
  connections,
  parentId,
  parentIdField,
}: Config) {
  return (store: RecordSourceSelectorProxy, data: SelectorData) => {
    const root: ?RecordProxy = getMutationRoot(store, data);

    if (!root) throw new Error('Root field missing in mutation');

    const deletedId = root.getValue(deletedIdField);

    if (!deletedId) throw new Error(`"${deletedIdField}" is not present in mutation response`);

    const parentIdFieldId = parentIdField ? root.getValue(parentIdField) : null;
    const parentIds = Array.isArray(parentIdFieldId)
      ? parentIdFieldId
      : [parentIdFieldId, parentId].filter(Boolean);
    const parentRecords = parentIds.map(id => store.get(id)).filter(Boolean);

    if (parentRecords.length === 0) return;

    parentRecords.forEach(parentRecord => {
      connections.forEach(conConfig => {
        const connection = getConnection(parentRecord, conConfig);
        if (connection) {
          (Array.isArray(deletedId) ? deletedId : [deletedId]).forEach(id =>
            ConnectionHandler.deleteNode(connection, id),
          );
        }
      });
    });
  };
}

export default removeRecordFromConnection;
