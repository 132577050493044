/**
 * @flow
 * @relayHash 764aea439d548472a863a9dfc55ee388
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveDeliverableCompanyInput = {
  clientMutationId?: ?string,
  deliverableId: string,
  companyId: string,
};
export type removeTaskCompanyMutationVariables = {|
  input: RemoveDeliverableCompanyInput
|};
export type removeTaskCompanyMutationResponse = {|
  +removeDeliverableCompany: ?{|
    +removedCompanyId: string
  |}
|};
export type removeTaskCompanyMutation = {|
  variables: removeTaskCompanyMutationVariables,
  response: removeTaskCompanyMutationResponse,
|};
*/


/*
mutation removeTaskCompanyMutation(
  $input: RemoveDeliverableCompanyInput!
) {
  removeDeliverableCompany(input: $input) {
    removedCompanyId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveDeliverableCompanyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeDeliverableCompany",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveDeliverableCompanyInput!"
      }
    ],
    "concreteType": "RemoveDeliverableCompanyPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedCompanyId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeTaskCompanyMutation",
  "id": null,
  "text": "mutation removeTaskCompanyMutation(\n  $input: RemoveDeliverableCompanyInput!\n) {\n  removeDeliverableCompany(input: $input) {\n    removedCompanyId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeTaskCompanyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeTaskCompanyMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f821e776f23e6bcf575454e1cb14fbb2';
module.exports = node;
