/* @flow */
import React from 'react';

import { type StepPageComponentType } from 'components/integrations/IntegrationSetupStepper';

import MarketoAuth from '../MarketoAuth';
import MarketoChannelMappings from '../MarketoChannelMappings';
import MarketoLeadMappings from '../MarketoLeadMappings';

const components: StepPageComponentType[] = [
  {
    label: 'Authentication',
    stepTitle: 'Connect your Marketo Account',
    path: 'auth',
    component: MarketoAuth,
  },
  {
    label: 'Leads',
    stepTitle: 'Map Marketo Lead fields with Circa Contacts fields',
    path: 'mapping/lead',
    component: <P: {}>(props: P) => <MarketoLeadMappings {...props} resourceType="CONTACT" />,
  },
  {
    label: 'Company',
    stepTitle: 'Map Marketo Company fields with Circa Company fields',
    path: 'mapping/company',
    component: <P: {}>(props: P) => <MarketoLeadMappings {...props} resourceType="COMPANY" />,
  },
  {
    label: 'Attendance',
    stepTitle: 'Map Marketo Program Member fields with Circa Contact Attendance fields',
    path: 'mapping/event-contact',
    component: <P: {}>(props: P) => <MarketoLeadMappings {...props} resourceType="EVENTCONTACT" />,
  },
  {
    label: 'Attendance Status',
    stepTitle: 'Map Marketo Channels Statuses to Circa Event Attendance Statuses',
    path: 'mapping/attendance',
    component: MarketoChannelMappings,
  },
];

export default components;
