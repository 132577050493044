/* @flow */
import * as React from 'react';
import { graphql } from 'react-relay';
import type { History, Location, Match } from 'react-router';
import styled from 'styled-components';

import DefaultQueryRenderer from 'components/DefaultQueryRenderer';

import Content from '../../layout';
import VendorPage from './VendorPage';

import type { VendorQueryResponse } from './__generated__/VendorQuery.graphql';

const query = graphql`
  query VendorQuery($vendorId: ID!, $includeEvent: Boolean!, $eventSlug: String) {
    org {
      ...VendorPage_org
    }
    vendor: node(id: $vendorId) {
      id
      ...VendorPage_vendor
    }
    ... @include(if: $includeEvent) {
      event(slug: $eventSlug) {
        ...VendorPage_event
        id
      }
    }
    user: me {
      ...VendorPage_user
      id
    }
  }
`;

const StyledContent = styled(Content)`
  padding: 10px 0 0;
`;

export default class VendorRoot extends React.Component<{
  match: Match,
  history: History,
  location: Location,
}> {
  render() {
    return (
      <StyledContent>
        <DefaultQueryRenderer
          query={query}
          variables={{
            vendorId: this.props.match.params.vendorId,
            eventSlug: this.props.match.params.event_slug,
            includeEvent: this.props.match.params.event_slug != null,
          }}
          renderSuccess={(props: VendorQueryResponse) =>
            props.vendor &&
            props.user && (
              <VendorPage
                org={props.org}
                event={props.event}
                vendor={props.vendor}
                user={props.user}
                location={this.props.location}
                history={this.props.history}
                match={this.props.match}
              />
            )
          }
        />
      </StyledContent>
    );
  }
}
