/**
 * @flow
 * @relayHash a222ef6ae268a805f5945b7ade46d4f0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppRouterQueryVariables = {||};
export type AppRouterQueryResponse = {|
  +org: {|
    +subscription: {|
      +featureAccess: {|
        +legacyFeatures: boolean
      |}
    |}
  |}
|};
export type AppRouterQuery = {|
  variables: AppRouterQueryVariables,
  response: AppRouterQueryResponse,
|};
*/


/*
query AppRouterQuery {
  org {
    subscription {
      featureAccess {
        legacyFeatures
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "featureAccess",
  "storageKey": null,
  "args": null,
  "concreteType": "FeatureAccess",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "legacyFeatures",
      "args": null,
      "storageKey": null
    }
  ]
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AppRouterQuery",
  "id": null,
  "text": "query AppRouterQuery {\n  org {\n    subscription {\n      featureAccess {\n        legacyFeatures\n      }\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AppRouterQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v0
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AppRouterQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "org",
        "storageKey": null,
        "args": null,
        "concreteType": "Org",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subscription",
            "storageKey": null,
            "args": null,
            "concreteType": "Subscription",
            "plural": false,
            "selections": [
              v0,
              v1
            ]
          },
          v1
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8238551a21fca31ca1d1d5c0ad633034';
module.exports = node;
