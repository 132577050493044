/* @flow */
import * as React from 'react';
import styled, { css } from 'styled-components';

import BriefViewSharedContext from '../BriefViewSharedContext';

const Container = styled.div`
  max-width: 390px;
  margin-right: auto;
  padding-bottom: 25px;
  ${props =>
    props.bordered
      ? css`
          flex: 0 0 390px;
          padding-top: 20px;
          border: 1px solid #cbcbcb;
        `
      : css`
          flex: 1 370px;
        `};
  ${props =>
    props.isShared &&
    css`
      @media (max-width: 865px) {
        flex: 0 0 390px;
        ${!props.bordered &&
          css`
            max-width: 100%;
            padding-right: 0;
          `};
      }
      @media (max-width: 480px) {
        flex: 1 320px;
        padding: 0 0 20px 0;
      }
    `};
`;

const UserContainerInList = (props: { children: React.Node, bordered?: ?boolean }) => {
  const { children, bordered } = props;
  return (
    <BriefViewSharedContext.Consumer>
      {({ sharedView }) => (
        <Container isShared={sharedView} bordered={bordered}>
          {children}
        </Container>
      )}
    </BriefViewSharedContext.Consumer>
  );
};
export default UserContainerInList;
