/* @flow */
import { graphql } from 'react-relay';

import addRecordToConnection from 'graph/updaters/addRecordToConnection';
import commitModernMutation from 'graph/utils/commitModernMutation';

import { type ComponentInColumnPropsType } from 'views/Main/Event/Registration/RegistrationCreate/RegistrationCreateContent';

import { type PageComponentOrderType } from './reorderRegistrationFormPageComponents';

export type CreateComponentPropertiesType = {
  formId: string,
  newComponentOrder: number,
  pageComponentsOrder: $ReadOnlyArray<PageComponentOrderType>,
  parentProps?: ?ComponentInColumnPropsType,
  fontFamily?: ?string,
};

import type {
  createEventDatesComponentFieldsMutationResponse,
  createEventDatesComponentFieldsMutationVariables,
} from './__generated__/createEventDatesComponentFieldsMutation.graphql';

const mutation = graphql`
  mutation createEventDatesComponentFieldsMutation($input: CreateEventDatesComponentInput!) {
    createRegistrationFormEventDatesComponent(input: $input) {
      pageComponentEdge {
        __typename
        node {
          id
          order
          kind
          parent {
            id
          }
          ...EventDatesComponent_componentProps
        }
      }
      updatedPageComponents {
        id
        order
        parent {
          id
        }
        childPageComponents {
          edges {
            node {
              id
              kind
              order
              columnChild {
                id
                order
                kind
                ...EventDatesComponent_componentProps
              }
              ...ColumnComponent_componentProps
            }
          }
        }
      }
      registrationForm {
        id
        hasChanges
      }
    }
  }
`;

export default function createEventDatesComponent(
  properties: CreateComponentPropertiesType,
): Promise<string> {
  const { formId, newComponentOrder, pageComponentsOrder, parentProps, fontFamily } = properties;
  const variables: createEventDatesComponentFieldsMutationVariables = {
    input: {
      formId,
      fontFamily,
      newComponentOrder,
      pageComponentsOrder,
      parentId: parentProps ? parentProps.parentId : undefined,
    },
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      createRegistrationFormEventDatesComponent: {
        updatedPageComponents: pageComponentsOrder.map(pageComponent => ({
          id: pageComponent.id,
          order: pageComponent.order,
          parent: undefined,
          ...(parentProps && parentProps.parentRowId === pageComponent.id
            ? {
                childPageComponents: {
                  edges: parentProps.rowChildPageComponents.edges.map(edge => {
                    if (!parentProps) {
                      return edge;
                    }
                    return edge.node.id !== parentProps.parentId
                      ? edge
                      : {
                          node: {
                            id: parentProps.parentId,
                            kind: 'COLUMN',
                            columnChild: {
                              order: newComponentOrder,
                              kind: 'EVENT_DATES',
                              eventDatesComponent: {
                                padding: '0 0 0 0',
                                alignment: 'center',
                                fontFamily,
                              },
                            },
                          },
                        };
                  }),
                },
              }
            : {}),
        })),
        pageComponentEdge: {
          node: {
            id: 'new',
            order: newComponentOrder,
            kind: 'EVENT_DATES',
            parent: parentProps ? { id: parentProps.parentId } : undefined,
            eventDatesComponent: {
              alignment: 'center',
              padding: '0 0 0 0',
              fontFamily,
            },
          },
        },
      },
    },
    updater: addRecordToConnection({
      parentId: formId,
      edgeName: 'pageComponentEdge',
      connections: [{ field: 'pageComponents' }],
    }),
  }).then((response: createEventDatesComponentFieldsMutationResponse) => {
    if (
      response.createRegistrationFormEventDatesComponent &&
      response.createRegistrationFormEventDatesComponent.pageComponentEdge.node
    ) {
      return response.createRegistrationFormEventDatesComponent.pageComponentEdge.node.id;
    }
    throw new Error('No item id');
  });
}
