/* @flow */
import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { type CircaFile } from 'utils/uploading/types';

import addEventFloorPlanAttachment from 'graph/mutations/event_floor_plan/addEventFloorPlanAttachment';
import updateEventFloorPlan from 'graph/mutations/event_floor_plan/updateEventFloorPlan';
import removeAttachment from 'graph/mutations/removeAttachment';
import showModernMutationError from 'graph/utils/showModernMutationError';

import InlineRichText, {
  type FocusWithTab,
  type SupportImage,
} from 'components/material/InlineRichText';
import TextField from 'components/material/TextField';

import type { Booth_event } from './__generated__/FloorPlan_event.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  position: relative;
  flex: 0 1 110px;
  margin: 0 25px 0 0;
  @media (${props => props.theme.mobileOnly}) {
    margin: 0 0 22px 0;
    flex: 1 1 auto;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 42px;
  @media (${props => props.theme.mobileOnly}) {
    flex-direction: column;
  }
`;

const BoothTextField = styled(TextField)`
  margin: 0;
`;

const DimContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: baseline;
  margin-right: 25px;
`;

const DimTextField = styled(TextField)`
  flex: 0 1 220px;
`;

const FloorPlanContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  max-width: 900px;
  width: 100%;
`;

class FloorPlan extends React.Component<
  {
    event: Booth_event,
  },
  {
    focusWithTab: FocusWithTab,
    booth: ?string,
    dimensions: ?string,
  },
> {
  supportImage: SupportImage;

  timeout: TimeoutID;

  constructor(props: $PropertyType<FloorPlan, 'props'>) {
    super(props);
    const {
      event: {
        eventFloorPlan: { booth, boothDimensions: dimensions },
      },
    } = this.props;
    this.state = {
      focusWithTab: { focused: false, shouldGetFocus: false },
      booth: booth || '',
      dimensions: dimensions || '',
    };
    this.supportImage = this.constructSupportImageProp();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleBlur = () => {
    this.updateFloorPlan();
  };

  updateFloorPlan = (floorPlanChanged?: ?string) => {
    const {
      event: {
        eventFloorPlan: { id, floorPlan },
      },
    } = this.props;
    const fields = {
      booth: this.state.booth,
      boothDimensions: this.state.dimensions,
      floorPlan: floorPlanChanged != null ? floorPlanChanged : floorPlan,
    };

    updateEventFloorPlan(id, fields).catch(showModernMutationError);
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  handleDimChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ dimensions: e.currentTarget.value });
  };

  handleBoothChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ booth: e.currentTarget.value });
  };

  handleDimKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
    if (e.keyCode === 9) {
      // Tab key code
      this.setState(
        prevState => ({ focusWithTab: { ...prevState.focusWithTab, focused: true } }),
        () => {
          this.timeout = setTimeout(
            () =>
              this.setState(prevState => ({
                focusWithTab: { ...prevState.focusWithTab, shouldGetFocus: true },
              })),
            0,
          );
        },
      );
    }
  };

  handleStartEditing = () => {
    this.setState({ focusWithTab: { focused: true, shouldGetFocus: true } });
  };

  handleFloorPlanChange = (floorPlan: ?string) => {
    const plan = floorPlan ? floorPlan.trimRight() : '';
    this.setState({ focusWithTab: { focused: false, shouldGetFocus: false } });
    this.updateFloorPlan(plan);
  };

  handleImageAdd = (file: CircaFile) => {
    addEventFloorPlanAttachment(this.props.event.eventFloorPlan.id, file).catch(
      showModernMutationError,
    );
  };

  handleRemoveImages = (removableAttachments: $ReadOnlyArray<CircaFile>) => {
    removableAttachments.forEach(ra => {
      const attachment = this.props.event.eventFloorPlan.attachments.edges.find(
        ({ node }) => ra.fileurl === node.fileurl,
      );
      if (attachment != null) {
        removeAttachment(this.props.event.id, attachment.node.id, [
          this.props.event.eventFloorPlan.id,
        ]).catch(showModernMutationError);
      }
    });
  };

  constructSupportImageProp = (): SupportImage => {
    const images = this.props.event.eventFloorPlan.attachments.edges.map(({ node }) => ({
      ...node,
      id: node.fileurl,
    }));
    return {
      images,
      onImageAdd: this.handleImageAdd,
      noUploadPreview: true,
      onRemoveImages: this.handleRemoveImages,
      onStartEditing: this.handleStartEditing,
    };
  };

  render() {
    const {
      event: {
        viewerCanUpdate,
        eventFloorPlan: { floorPlan },
      },
    } = this.props;
    return (
      <Container>
        <Row>
          <ItemContainer>
            <BoothTextField
              label="Booth #"
              name="booth"
              value={this.state.booth || ''}
              onBlur={this.handleBlur}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleBoothChange}
              disabled={!viewerCanUpdate}
            />
          </ItemContainer>
        </Row>
        <Row>
          <DimContainer>
            <DimTextField
              value={this.state.dimensions || ''}
              label="Dimensions"
              onBlur={this.handleBlur}
              onKeyDown={this.handleDimKeyDown}
              onChange={this.handleDimChange}
              disabled={!viewerCanUpdate}
            />
          </DimContainer>
        </Row>
        <Row>
          <FloorPlanContainer>
            <InlineRichText
              value={floorPlan}
              label="Floor plan"
              disabled={!viewerCanUpdate}
              onChange={this.handleFloorPlanChange}
              focusWithTab={this.state.focusWithTab}
              supportImage={this.supportImage}
            />
          </FloorPlanContainer>
        </Row>
      </Container>
    );
  }
}

export default createFragmentContainer(
  FloorPlan,
  graphql`
    fragment FloorPlan_event on Event {
      id
      dbId
      eventFloorPlan {
        id
        booth
        boothDimensions
        floorPlan
        attachments {
          edges {
            node {
              id
              fileurl
              filename
              filetype
              filesize
            }
          }
        }
      }
      viewerCanUpdate
    }
  `,
);
