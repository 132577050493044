/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type AttachmentPreview_attachments$ref = any;
type RelayAttachmentRow_attachment$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RelayAttachments_attachments$ref: FragmentReference;
export type RelayAttachments_attachments = $ReadOnlyArray<{|
  +id: string,
  +filetype: ?string,
  +$fragmentRefs: RelayAttachmentRow_attachment$ref & AttachmentPreview_attachments$ref,
  +$refType: RelayAttachments_attachments$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "RelayAttachments_attachments",
  "type": "Attachment",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "filetype",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RelayAttachmentRow_attachment",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AttachmentPreview_attachments",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c386d82935dc691f6227b91825c08941';
module.exports = node;
