/**
 * @flow
 * @relayHash c690f0531e4469e39ccf4a540cb1309e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveItemFromScheduleDayInput = {
  clientMutationId?: ?string,
  scheduleItemId: string,
};
export type removeScheduleItemMutationVariables = {|
  input: RemoveItemFromScheduleDayInput
|};
export type removeScheduleItemMutationResponse = {|
  +removeItemFromScheduleDay: ?{|
    +removedItemId: string
  |}
|};
export type removeScheduleItemMutation = {|
  variables: removeScheduleItemMutationVariables,
  response: removeScheduleItemMutationResponse,
|};
*/


/*
mutation removeScheduleItemMutation(
  $input: RemoveItemFromScheduleDayInput!
) {
  removeItemFromScheduleDay(input: $input) {
    removedItemId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveItemFromScheduleDayInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeItemFromScheduleDay",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RemoveItemFromScheduleDayInput!"
      }
    ],
    "concreteType": "RemoveItemFromScheduleDayPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "removedItemId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "removeScheduleItemMutation",
  "id": null,
  "text": "mutation removeScheduleItemMutation(\n  $input: RemoveItemFromScheduleDayInput!\n) {\n  removeItemFromScheduleDay(input: $input) {\n    removedItemId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "removeScheduleItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "removeScheduleItemMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b261891896f26cef255559a50b802a25';
module.exports = node;
