/* @flow */
import { graphql } from 'react-relay';

import commitModernMutation from 'graph/utils/commitModernMutation';

import type {
  UpdateRegistrationFormInput,
  updateRegistrationFormMutationResponse,
} from './__generated__/updateRegistrationFormMutation.graphql';

export type updateRegistrationPropertyType = $Shape<UpdateRegistrationFormInput>;

const mutation = graphql`
  mutation updateRegistrationFormMutation(
    $input: UpdateRegistrationFormInput!
    $changingEventName: Boolean!
    $changingEventStartDate: Boolean!
    $changingEventEndDate: Boolean!
    $changingStartDateAllDay: Boolean!
    $changingEndDateAllDay: Boolean!
    $changingBackgroundColor: Boolean!
    $changingBackgroundUrl: Boolean!
    $changingTz: Boolean!
    $changingWidth: Boolean!
    $changingWidthMeasurement: Boolean!
    $changingSendSubmitEmail: Boolean!
    $changingSubmitMessage: Boolean!
    $changingAutoApproval: Boolean!
    $changingSubmitEmailSubject: Boolean!
    $changingSubmitEmailTemplate: Boolean!
    $changingSenderAddress: Boolean!
    $changingSendCalendarInvite: Boolean!
    $changingCompanyEmailsOnly: Boolean!
    $changingZoomLocation: Boolean!
    $changingG2wLocation: Boolean!
    $includeEvent: Boolean!
  ) {
    updateRegistrationForm(input: $input) {
      registrationForm {
        id
        hasChanges
        eventName @include(if: $changingEventName)
        eventStartDate @include(if: $changingEventStartDate)
        eventEndDate @include(if: $changingEventEndDate)
        startDateAllDay @include(if: $changingStartDateAllDay)
        endDateAllDay @include(if: $changingEndDateAllDay)
        backgroundColor @include(if: $changingBackgroundColor)
        backgroundUrl @include(if: $changingBackgroundUrl)
        tz @include(if: $changingTz)
        width @include(if: $changingWidth)
        widthMeasurement @include(if: $changingWidthMeasurement)
        submitMessage @include(if: $changingSubmitMessage)
        autoApproval @include(if: $changingAutoApproval)
        sendSubmitEmail @include(if: $changingSendSubmitEmail)
        submitEmailSubject @include(if: $changingSubmitEmailSubject)
        submitEmailTemplate @include(if: $changingSubmitEmailTemplate)
        senderAddress @include(if: $changingSenderAddress)
        sendCalendarInvite @include(if: $changingSendCalendarInvite)
        companyEmailsOnly @include(if: $changingCompanyEmailsOnly)
      }
      event @include(if: $includeEvent) {
        virtualLocation
        zoomLocation @include(if: $changingZoomLocation) {
          id
          kind
          zoomUser {
            id
            zoomId
            firstName
            lastName
            email
            freePlan
          }
          zoomId
          joinUrl
          zoomUrl
          topic
          startTime
          duration
          timezone
        }
        g2wLocation @include(if: $changingG2wLocation) {
          id
          g2wKey
          subject
          times {
            startTime
            endTime
          }
          timezone
          registrationUrl
          g2wUrl
          g2wUser {
            id
            g2wKey
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

const timezoneRelated = ['eventStartDate', 'eventEndDate'];

export default function updateRegistrationForm(properties: updateRegistrationPropertyType) {
  const {
    formId,
    eventName,
    eventStartDate,
    eventEndDate,
    startDateAllDay,
    endDateAllDay,
    backgroundColor,
    backgroundUrl,
    tz,
    width,
    widthMeasurement,
    sendSubmitEmail,
    submitMessage,
    autoApproval,
    submitEmailSubject,
    submitEmailTemplate,
    senderAddress,
    sendCalendarInvite,
    companyEmailsOnly,
    zoomLocation,
    g2wLocation,
  } = properties;
  const variables = {
    input: {
      ...properties,
    },
    includeEvent: zoomLocation !== undefined || g2wLocation !== undefined,
    ...[
      'eventName',
      'eventStartDate',
      'eventEndDate',
      'startDateAllDay',
      'endDateAllDay',
      'backgroundColor',
      'backgroundUrl',
      'tz',
      'width',
      'widthMeasurement',
      'sendSubmitEmail',
      'submitMessage',
      'autoApproval',
      'submitEmailSubject',
      'submitEmailTemplate',
      'senderAddress',
      'sendCalendarInvite',
      'companyEmailsOnly',
      'zoomLocation',
      'g2wLocation',
    ]
      .map(key => ({
        [`changing${key[0].toUpperCase()}${key.slice(1)}`]:
          properties[key] !== undefined || (timezoneRelated.includes(key) && tz !== undefined),
      }))
      .reduce((acc, item) => ({ ...acc, ...item }), {}),
  };
  return commitModernMutation({
    mutation,
    variables,
    optimisticResponse: {
      updateRegistrationForm: {
        registrationForm: {
          id: formId,
          eventName,
          eventStartDate,
          eventEndDate,
          startDateAllDay,
          endDateAllDay,
          backgroundColor,
          backgroundUrl,
          tz,
          width,
          widthMeasurement,
          submitMessage,
          autoApproval,
          sendSubmitEmail,
          submitEmailSubject,
          submitEmailTemplate,
          senderAddress,
          sendCalendarInvite,
          companyEmailsOnly,
          zoomLocation,
          g2wLocation,
        },
      },
    },
  }).then((response: updateRegistrationFormMutationResponse) => {
    if (!response.updateRegistrationForm) {
      throw new Error('Missing mutation response');
    }

    return response.updateRegistrationForm.registrationForm;
  });
}
